import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { blockedTimeSlotChooseKeyword, resetDoctors } from "../actions/blockedTimeSlotActions";
import { BlockedKeywordsSelect } from "../components/widgets/BlockedKeywordsSelect";

const enhancer = connect(
  ({ blockedTimeSlot: { keywords } }) => ({
    keywords,
  }),
  { resetDoctors, blockedTimeSlotChooseKeyword },
);

export const BlockedKeywordsSelectWrapper = enhancer(
  class DoctorsSelectWrapperView extends React.Component {
    static propTypes = {
      keywords: PropTypes.array,

      onSelect: PropTypes.func,

      disabled: PropTypes.bool,

      resetDoctors: PropTypes.func,
      blockedTimeSlotChooseKeyword: PropTypes.func,
    };

    componentDidMount() {
      const { resetDoctors, isReset = true } = this.props;

      if (isReset) {
        resetDoctors();
      }
    }

    render() {
      const { onSelect, blockedTimeSlotChooseKeyword, ...props } = this.props;

      return (
        <BlockedKeywordsSelect
          {...props}
          onSelect={(value) => {
            if (onSelect) {
              onSelect(value);

              return;
            }

            blockedTimeSlotChooseKeyword(value);
          }}
        />
      );
    }
  },
);
