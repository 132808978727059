import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import Utils from "../../helpers/Utils";
import { ViewTypes } from "../../constants/Constants";

const enhancer = connect(
  ({ session: { company } }) => ({
    company,
  }),
  null,
);
export default enhancer(
  class CalendarRedLine extends React.PureComponent {
    static propTypes = {
      company: PropTypes.object,
      startTime: PropTypes.string,
    };

    constructor(props) {
      super(props);
      this.state = {
        time: 0,
      };
    }

    componentDidMount() {
      this.thisSetInterval();
    }

    thisSetInterval = () => {
      setInterval(() => {
        this.setState({ time: this.state.time + 1 });
      }, 60000);
    };

    render() {
      const { company } = this.props;

      const rowView = localStorage.getItem("rowView");

      let columnHeight = 40;

      if (rowView === ViewTypes.compact) columnHeight = 20;

      let utcOffset;

      if (company) {
        utcOffset = _.toFinite(_.get(company, "timezone.description", Date.now())) / 60 / 1000;
      } else {
        utcOffset = moment().utcOffset();
      }

      const momentDate = Utils.calcDateWithTimeZone(null, utcOffset);
      const timeWithTimezone = +momentDate;
      const startOfDay = +momentDate.startOf("day");

      const timeInMinutes = timeWithTimezone - startOfDay;

      const top =
        (columnHeight / 15) *
        (timeInMinutes / (60 * 1000) - Utils.timeStringToInt(this.props.startTime));

      return <div className="calendar-red-line" style={{ top }} />;
    }
  },
);
