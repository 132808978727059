import React from "react";
import moment from "moment";

import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import MedicationHistoryItemForm from "./MedicationHistoryItemForm";

import * as medicationActions from "../../actions/medicationActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import DeleteImage from "../../assets/images/appointment/delete_2x.png";

export default class MedicationHistoryModal extends React.Component {
  componentDidMount() {
    this.props.dispatch(medicationActions.getHistoryList());
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.dispatch(medicationActions.loadHistoryList());
    }
  };

  render() {
    const { historyList, newHistoryList } = this.props.medication;

    const $newItems = newHistoryList.map((item, index) => {
      return (
        <MedicationHistoryItemForm
          key={index}
          lastIndex={newHistoryList.length - 1}
          index={index}
          form={"medicationHistoryItem" + index}
          dispatch={this.props.dispatch}
        />
      );
    });

    const $rows = historyList.map((item, index) => (
      <div className="default-table-row" key={index}>
        <div
          className="default-table-col col-0"
          onClick={() =>
            this.props.dispatch(
              simpleModalActions.openSimpleModal({
                body: "Are you sure you what to delete this log?",
                footer: (
                  <div>
                    <button
                      className="btn btn-default"
                      onClick={() => this.props.dispatch(simpleModalActions.closeSimpleModal())}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(simpleModalActions.closeSimpleModal());
                        this.props.dispatch(medicationActions.deleteHistory(item.id));
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ),
              }),
            )
          }
        >
          <img
            src={DeleteImage}
            alt=""
            width={20}
            height={20}
            className="icon-btn icon-delete icon-small"
          />
        </div>
        <div className="default-table-col col-1">
          {moment(item.createdDate).format("MMM DD, YYYY, h:mm a")}
        </div>
        <div className="default-table-col col-2">{item.dosage}</div>
        <div className="default-table-col col-3">
          <span>{item.comment}</span>
        </div>
      </div>
    ));

    return (
      <Modal
        size="large"
        show={true}
        onHide={() => this.props.dispatch(medicationActions.closeHistoryModal())}
        keyboard={false}
        title={
          <div>
            <button
              className="btn btn-link pull-right"
              onClick={() => this.props.dispatch(medicationActions.addNewHistory())}
            >
              Add New Entry
            </button>
            Medication Intake Log
          </div>
        }
        actions={
          <div>
            <Button
              onClick={() => {
                this.props.dispatch(medicationActions.closeHistoryModal());
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.dispatch(medicationActions.saveHistory());
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="default-table medication-history-modal-table">
          <div className="default-table-header">
            <div className="default-table-col col-1">Date/Time</div>
            <div className="default-table-col col-2">Dosage Given</div>
            <div className="default-table-col col-3">Comment</div>
          </div>
          <div className="default-table-body" onScroll={this.loadList}>
            {$newItems}
            {$rows}
          </div>
        </div>
      </Modal>
    );
  }
}
