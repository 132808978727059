import _ from "lodash";
import React from "react";
import FlexBox from "../../../ui/FlexBox";
import { DownloadImageButton } from "./DownloadImageButton";
import folderImage from "../../../../assets/images/folder-icon.png";
import imagePlaceholderImage from "../../../../assets/images/image_placeholder.png";
import pdfPlaceholderfolderImage from "../../../../assets/images/pdf_placeholder.png";
import { bool } from "prop-types";

/*
 * type: string (folder | file) \
 * url? string (only type file) --> item: { type: string, url: string, id: number }
 * selected: boolean
 * onSelect: (id: number, state: boolean) => void
 * onClick: (id: number) = void
 */

const FILE_TYPE = "file";
const FOLDER_TYPE = "folder";

export default class DocsItem extends React.Component {
  static propTypes = {
    isImage: bool,
  };

  imageSrc(item) {
    const url = _.get(item, "url", "");

    if (item?.type === FOLDER_TYPE) {
      return folderImage;
    }

    if (!url) {
      return imagePlaceholderImage;
    }

    if (url.indexOf("pdf?") >= 0) {
      return pdfPlaceholderfolderImage;
    }

    return url;
  }

  isRemoteImage(item) {
    const url = _.get(item, "url", "");

    return Boolean(url);
  }

  render() {
    const {
      item,
      isBack,
      isMoving,
      selected,
      onSelect,
      openFile,
      openFolder,
      isImage,
    } = this.props;

    const isFile = item?.type === FILE_TYPE;
    const isFolder = item?.type === FOLDER_TYPE;
    const questionTypeImage = isFolder && isImage;

    const fileName = item.name || "";
    let fileType = "";
    let fileTypeCode = item?.fileTypeCode;
    let notAnotherFileType = false;
    if (!isFolder) {
      fileType = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
      notAnotherFileType =
        fileType === "pdf" ||
        fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "png" ||
        fileTypeCode === "PATIENT_ATTACHMENT_CHART_IMAGE";
    }
    return (
      <FlexBox
        flex="none"
        direction="row"
        style={{
          padding: !isBack ? "12px" : 0,
          borderBottom: !isBack ? "1px solid #dddddd" : "transparent",
        }}
      >
        <FlexBox
          flex={true}
          direction="row"
          title={isFile ? "Open Image" : isBack ? "Up" : "Open Folder"}
          onClick={() => {
            if (!notAnotherFileType && !isFolder) {
              const _link = document.createElement("a");
              _link.href = item.url;
              _link.target = "_blank";
              _link.click();
            } else if (isFolder) {
              openFolder();
            } else {
              openFile();
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {isBack && (
            <div
              style={{
                display: "flex",
                paddingLeft: "12px",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              <div
                style={{
                  marginRight: "1rem",
                  transform: "rotate(90deg)",
                }}
              >
                ❮{" "}
              </div>
              <div>Up</div>
            </div>
          )}

          {!isBack && (
            <FlexBox align="center" justify="center">
              {!notAnotherFileType && !isFolder ? (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    border: "1px solid gray",
                  }}
                >
                  {"." + fileType}
                </div>
              ) : (
                <img
                  alt=""
                  width={32}
                  height={32}
                  src={this.imageSrc(item)}
                  style={
                    isFile
                      ? {
                          border: "1px solid #cccccc",
                        }
                      : {}
                  }
                />
              )}
            </FlexBox>
          )}

          {!isBack && (
            <FlexBox flex={true} align="center" style={{ padding: "0 12px" }}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </span>
            </FlexBox>
          )}
        </FlexBox>

        {Boolean(!isBack && !isMoving && !questionTypeImage) && (
          <FlexBox align="center" justify="center" style={{ padding: "0 12px" }}>
            <input
              type={isImage ? "radio" : "checkbox"}
              style={{ margin: 0 }}
              checked={selected}
              onChange={onSelect}
            />
          </FlexBox>
        )}

        {Boolean(!isBack && !isMoving) && (
          <FlexBox
            align="center"
            justify="center"
            style={{ width: "20px" }}
            onClick={(event) => event.stopPropagation()}
          >
            {isFolder && (
              <svg viewBox="0 0 8 13" width={20} height={20}>
                <polyline fill="none" stroke="#cccccc" strokeWidth={2} points="1,1 7,6.5 1,12" />
              </svg>
            )}

            {Boolean(isFile && this.isRemoteImage(item)) && (
              <DownloadImageButton image={this.imageSrc(item)} imageName={item.name || ""} />
            )}
          </FlexBox>
        )}
      </FlexBox>
    );
  }
}
