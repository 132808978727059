import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Component = styled.div`
  display: flex;

  & > .plus-button {
    display: flex;
    font-size: 24px;
    cursor: pointer;
    padding: 0 16px;
    align-items: center;
  }

  & > .content-button {
    flex: 1;
    display: flex;
    padding: 10px 12px;
    align-items: center;
    border: 1px solid #ddd;
  }

  &:not(:last-child) > .content-button {
    border-bottom: none;
  }
`;

PatientFormsAddModalItem.propTypes = {
  title: PropTypes.string,
  onPlusClick: PropTypes.func,
  onContentClick: PropTypes.func,
};

export function PatientFormsAddModalItem({ title, onPlusClick, onContentClick }) {
  return (
    <Component>
      <div role="button" onClick={onPlusClick} className="plus-button">
        +
      </div>
      <div role="button" onClick={onContentClick} className="content-button">
        {title}
      </div>
    </Component>
  );
}
