import React, { useEffect, useState } from "react";
import { Sidebar } from "../../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  handleMovementType,
  perioExamHandleItem,
  perioExamItemHideModal,
  perioExamTempClearItem,
  perioExamTempItem,
} from "../../../actions/patientActions";
import styled from "styled-components";
import { TopRowBackgroundIcon } from "./TopRowBackgroundIcon";
import { LowerRowBackgroundIcon } from "./LowerRowBackgroundIcon";
import { obtainImagePath } from "../../../utils/ImageUtils";
import { getTeethImage } from "../../../constants/perio-exam";
import PerioExamLinearChartMain from "./PerioExamLinerChartMain";
import PerioExamMode from "./PerioExamMode";
import PerioExamChartCalculator from "./PerioExamChartCalculator";
import { Modal } from "../../ui/Modal";
import arrowIcon from "../../../assets/images/arrows/down_arrow.png";
import { Button } from "../../ui/Button";
import Switch from "@mui/material/Switch";
import dotIcon from "../../../assets/images/dot.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilledImage from "../../widgets/FilledImage";

const RightSideModal = ({ visible, onHide, numericKey, teeth }) => {
  const [quicemode, setQuiceMode] = useState(false);
  const [teethAction, setTeethAction] = useState(0);
  const [movementOpen, setMovement] = useState(false);
  const { patient } = useSelector((store) => store);
  const dispatch = useDispatch();
  useEffect(() => {
    let obj = teeth?.perioExamItem;
    obj && dispatch(perioExamTempItem(obj));
  }, [teeth?.perioExamItem]);

  useEffect(() => {
    const element = (patient.perioExamItem.perioExamList || []).find(
      (item) => item?.tooth?.name == teeth?.item?.image && item?.toothSide?.code == teeth.side,
    );
    element && dispatch(perioExamTempItem(element));
  }, [teethAction]);
  const leftTeeth = () => {
    const { teethList, item } = teeth;
    const index = (teethList || []).findIndex((itm) => itm.image == item.image);
    index - 1 &&
      teethList[index - 1] &&
      dispatch(perioExamHandleItem({ item: teethList[index - 1] }));
    setTeethAction(teethAction + 1);
  };
  const rightTeeth = () => {
    const { teethList, item } = teeth;
    const index = (teethList || []).findIndex((itm) => itm.image == item.image);
    index + 1 &&
      teethList[index + 1] &&
      dispatch(perioExamHandleItem({ item: teethList[index + 1] }));
    setTeethAction(teethAction + 1);
  };
  let numeric = null;
  const normalDetails = teeth?.item.normal && teeth?.item.normal.details;
  if (normalDetails) numeric = normalDetails[numericKey];
  return (
    <Sidebar show={visible} onHide={() => onHide()}>
      <Header addClass="mt-1">
        <div
          onClick={() => {
            dispatch(perioExamItemHideModal());
            dispatch(perioExamTempClearItem());
          }}
          style={{ cursor: "pointer" }}
        >
          ❮ Back
        </div>
        <div>Tooth: {numeric}</div>
        <div onClick={() => setQuiceMode(!quicemode)} style={{ cursor: "pointer" }}>
          Quick mode
        </div>
      </Header>
      <TeethWrapper>
        <ArrowBtn onClick={leftTeeth}>
          <img style={{ transform: "rotate(90deg)" }} src={arrowIcon} alt="arrow" />
        </ArrowBtn>
        <Teeth>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: -10,
              right: 200,
              display: "flex",
              flexDirection: "column",
              top: teeth?.row == "top" ? -60 : 60,
            }}
          >
            <LowerRowBackgroundIcon />
            <TopRowBackgroundIcon />
          </div>
          <div
            style={{ width: 80, height: 150 }}
            className={`d-flex justify-content-center align-items-${
              teeth?.row === "top" ? "start" : "end"
            }`}
          >
            <PerioExamLinearChartMain item={patient.perioExamTempItem} top={teeth?.row === "top"} />
            {teeth?.item?.image ? (
              <img
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                src={obtainImagePath(getTeethImage(teeth?.item?.image))}
              />
            ) : (
              <FilledImage
                color={"rgb(255, 11, 101)"}
                src={require("../../../assets/images/conditions/absent_tooth@2x.png").default}
                className="zoom-2x image-40"
                style={{ height: "100%" }}
              />
            )}
          </div>
        </Teeth>
        <ArrowBtn onClick={rightTeeth}>
          <img style={{ transform: "rotate(-90deg)" }} src={arrowIcon} alt="arrow" />
        </ArrowBtn>
      </TeethWrapper>
      {quicemode ? (
        <PerioExamChartCalculator item={patient.perioExamTempItem} />
      ) : (
        <PerioExamMode item={patient.perioExamTempItem} />
      )}
      <BtnMovement onClick={() => setMovement(true)}>
        <span>Movement</span>
        <span style={{ transform: "rotate(90deg)" }}>❮</span>
      </BtnMovement>
      <Modal
        size={"md"}
        show={movementOpen}
        onHide={() => setMovement(false)}
        keyboard={false}
        actions={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={true}
                  onChange={(event) => {
                    // setMgdStatus(event.target.checked);
                    // onChangeForm("mucogingivalDefectStatus", event.target.checked);
                  }}
                />
              }
              label={"Use selected as default options"}
            />
            <div>
              <Button onClick={() => setMovement(false)}>Close</Button>
              <Button onClick={() => setMovement(false)}>Done</Button>
            </div>
          </div>
        }
      >
        <MovementContainer>
          <h3>Movement</h3>
          <div style={{ width: "100%" }}>
            {[
              { id: "TYPE_1", name: "1-32 B Then 1-32L" },
              { id: "TYPE_2", name: "B, L Each tooth" },
              { id: "TYPE_3", name: "BL 1-16, BL32-17" },
            ].map((item) => (
              <MovementItem
                key={item.id}
                style={{ background: patient.perioExamMovementType == item.id && "#e7e7e7" }}
                onClick={() => dispatch(handleMovementType(item.id))}
              >
                {item.name} {patient.perioExamMovementType == item.id && <DotIcon src={dotIcon} />}
              </MovementItem>
            ))}
          </div>
        </MovementContainer>
      </Modal>
    </Sidebar>
  );
};

const Header = styled.div`
  display: flex;
  background: #b1bf35;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  color: #fff;
  font-size: 18px;
`;

const TeethWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 30px;
  border-bottom: 2px solid #7c7c7c;
  padding-bottom: 30px;
`;
const Teeth = styled.div`
  display: flex;
  justifyc-content: center;
  //height: 140px;
  //min-widthidth: 80px;
  //max-width: 80px;
  z-index: 10;
  //padding-top: 12px;
  //padding: 20px 40px;
  position: relative;
  align-items: baseline;
  overflow: hidden;
  border: 1px solid blue;
  padding: 30px 60px;
  margin: 0px 30px;
`;
const ArrowBtn = styled.button`
  width: 35px;
  height: 35px;
  border: 1px solid #646464;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
`;
const BtnMovement = styled.button`
  height: 60px;
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  font-size: 16px;
  margin: 0px 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`;

const MovementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MovementItem = styled.div`
  width: 100%;
  text-align: center;
  border: 1px solid;
  font-size: 16px;
  padding: 6px 0px;
  margin-bottom: 3px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  &:active {
    background: #cccccc;
  }
`;

const DotIcon = styled.img`
  position: absolute;
  right: 20px;
`;
export default RightSideModal;
