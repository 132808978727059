import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";

const enhancer = connect(
  ({ chartSideTransaction }) => ({
    confirmDeleteModalVisible: chartSideTransaction.confirmDeleteModalVisible,
  }),
  bindActions({ chartSideTransactionActions }),
);
export default enhancer(
  class ChartTransactionConfirmDeleteModal extends React.Component {
    render() {
      return (
        <Modal
          size="small"
          show={this.props.confirmDeleteModalVisible}
          onHide={this.props.chartSideTransactionActions.closeConfirmDeleteModal}
          keyboard={false}
          actions={
            <div>
              <Button
                bsStyle="danger"
                onClick={() => {
                  this.props.chartSideTransactionActions.deleteItem();
                  this.props.chartSideTransactionActions.closeConfirmDeleteModal();
                }}
              >
                Delete
              </Button>
              <Button onClick={this.props.chartSideTransactionActions.closeConfirmDeleteModal}>
                Cancel
              </Button>
            </div>
          }
        >
          <div>Are you sure you want to delete this transaction? This action cannot be undone.</div>
        </Modal>
      );
    }
  },
);
