import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs";

import PropTypes from "prop-types";
import Show from "../../../widgets/Show";

import ChartPrescriptionListItem from "./ChartPrescriptionListItem";
import ChartSideSearch from "../ChartSideSearch";

import prescriptionSortFields from "../../../../constants/chart-sidebar/prescriptionSortFields";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(
  () => ({}),
  bindActions({
    chartSidePrescriptionActions,
    closeSimpleModal,
    openSimpleModal,
  }),
);

export default enhancer(
  class ChartPrescriptionList extends React.Component {
    static propTypes = {
      openSimpleModal: PropTypes.func,
      closeSimpleModal: PropTypes.func,
      chartSidePrescriptionActions: PropTypes.object,
      chartSidePrescription: PropTypes.object,
    };

    componentDidMount() {
      this.props.chartSidePrescriptionActions.initList();
    }

    openItem = (item) => {
      return () => {
        // document.location.href = item.pdfUrl;
        this.props.chartSidePrescriptionActions.openPDF(item); //todo http-header
      };
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartSidePrescriptionActions.loadList();
      }
    };

    select = (event) => {
      this.props.chartSidePrescriptionActions.selectSortField(event.target.value);
    };

    onSearch = (event) => {
      this.props.chartSidePrescriptionActions.setSearchValue(event.target.value);
    };

    removeItem = (item) => {
      // console.log('delete------')
      this.props.chartSidePrescriptionActions.removePrescription(item);
      this.props.closeSimpleModal();
    };

    render() {
      const { searchValue, list, fetching, sortField } = this.props.chartSidePrescription;

      const $list = list.map((item, index) => (
        <ChartPrescriptionListItem
          key={index}
          item={item}
          openItem={this.openItem(item)}
          removeItem={() => {
            this.props.openSimpleModal({
              body: "Are you sure to delete this prescription?",
              footer: (
                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={this.props.closeSimpleModal}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.removeItem(item)}
                  >
                    Yes
                  </button>
                </div>
              ),
            });
          }}
        />
      ));

      const $options = prescriptionSortFields.map((item, index) => (
        <option key={index} value={item.code}>
          {item.name}
        </option>
      ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader
            title="Prescriptions"
            onBackClick={this.props.chartSidePrescriptionActions.popStack}
          />
          <div className="chart-side-body">
            <ChartSideSearch value={searchValue} onChange={this.onSearch} />
            <div className="chart-clinical-note-select">
              <select onChange={this.select} value={sortField} className="form-control">
                {$options}
              </select>
            </div>
            <div className="body">
              <div className="chart-side-body-wrapper" onScroll={this.loadList}>
                {$list}
                <Show if={fetching}>
                  <ReactSpinner />
                </Show>
              </div>
            </div>
            <div className="all-codes">
              <button
                onClick={this.props.chartSidePrescriptionActions.openFavourite}
                className="btn btn-primary btn-block"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
