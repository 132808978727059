export const FOLLOW_UP_SELECT_DOCTORS = "FOLLOW_UP_SELECT_DOCTORS";
export const FOLLOW_UP_SELECT_CLINIC = "FOLLOW_UP_SELECT_CLINIC";
export const FOLLOW_UP_SET_FROM_DATE = "FOLLOW_UP_SET_FROM_DATE";
export const FOLLOW_UP_SET_TO_DATE = "FOLLOW_UP_SET_TO_DATE";
export const FOLLOW_UP_SET_TYPE_FILTER = "FOLLOW_UP_SET_TYPE_FILTER";
export const FOLLOW_UP_INIT_DOCTORS = "FOLLOW_UP_INIT_DOCTORS";
export const FOLLOW_UP_SET_CHART_NUMBER = "FOLLOW_UP_SET_CHART_NUMBER";

export const FOLLOW_UP_GET_LIST_START = "FOLLOW_UP_GET_LIST_START";
export const FOLLOW_UP_GET_LIST_SUCCESS = "FOLLOW_UP_GET_LIST_SUCCESS";
export const FOLLOW_UP_GET_LIST_ERROR = "FOLLOW_UP_GET_LIST_ERROR";

export const FOLLOW_UP_GET_COUNT_START = "FOLLOW_UP_GET_COUNT_START";
export const FOLLOW_UP_GET_COUNT_SUCCESS = "FOLLOW_UP_GET_COUNT_SUCCESS";
export const FOLLOW_UP_GET_COUNT_ERROR = "FOLLOW_UP_GET_COUNT_ERROR";

export const FOLLOW_UP_GET_TYPE_LIST_START = "FOLLOW_UP_GET_TYPE_LIST_START";
export const FOLLOW_UP_GET_TYPE_LIST_SUCCESS = "FOLLOW_UP_GET_TYPE_LIST_SUCCESS";
export const FOLLOW_UP_GET_TYPE_LIST_ERROR = "FOLLOW_UP_GET_TYPE_LIST_ERROR";

export const FOLLOW_UP_REPEAT_ITEM_START = "FOLLOW_UP_REPEAT_ITEM_START";
export const FOLLOW_UP_REPEAT_ITEM_SUCCESS = "FOLLOW_UP_REPEAT_ITEM_SUCCESS";
export const FOLLOW_UP_REPEAT_ITEM_ERROR = "FOLLOW_UP_REPEAT_ITEM_ERROR";

export const FOLLOW_UP_REPEAT_LIST_START = "FOLLOW_UP/FOLLOW_UP_REPEAT_LIST_START";
export const FOLLOW_UP_REPEAT_LIST_SUCCESS = "FOLLOW_UP/FOLLOW_UP_REPEAT_LIST_SUCCESS";
export const FOLLOW_UP_REPEAT_LIST_ERROR = "FOLLOW_UP/FOLLOW_UP_REPEAT_LIST_ERROR";

export const FOLLOW_UP_REPEAT_DELETE_ITEM_START = "FOLLOW_UP/FOLLOW_UP_REPEAT_DELETE_ITEM_START";
export const FOLLOW_UP_REPEAT_DELETE_ITEM_SUCCESS =
  "FOLLOW_UP/FOLLOW_UP_REPEAT_DELETE_ITEM_SUCCESS";
export const FOLLOW_UP_REPEAT_DELETE_ITEM_ERROR = "FOLLOW_UP/FOLLOW_UP_REPEAT_DELETE_ITEM_ERROR";

export const FOLLOW_UP_SAVE_ITEM_START = "FOLLOW_UP_SAVE_ITEM_START";
export const FOLLOW_UP_SAVE_ITEM_SUCCESS = "FOLLOW_UP_SAVE_ITEM_SUCCESS";
export const FOLLOW_UP_SAVE_ITEM_ERROR = "FOLLOW_UP_SAVE_ITEM_ERROR";

export const FOLLOW_UP_REVIEW_ITEM_START = "FOLLOW_UP_REVIEW_ITEM_START";
export const FOLLOW_UP_REVIEW_ITEM_SUCCESS = "FOLLOW_UP_REVIEW_ITEM_SUCCESS";
export const FOLLOW_UP_REVIEW_ITEM_ERROR = "FOLLOW_UP_REVIEW_ITEM_ERROR";

export const FOLLOW_UP_REOPEN_ITEM_START = "FOLLOW_UP_REOPEN_ITEM_START";
export const FOLLOW_UP_REOPEN_ITEM_SUCCESS = "FOLLOW_UP_REOPEN_ITEM_SUCCESS";
export const FOLLOW_UP_REOPEN_ITEM_ERROR = "FOLLOW_UP_REOPEN_ITEM_ERROR";

export const FOLLOW_UP_TOGGLE_COMPLETE = "FOLLOW_UP_TOGGLE_COMPLETE";
export const FOLLOW_UP_TOGGLE_RESPONDED = "FOLLOW_UP_TOGGLE_RESPONDED";
export const FOLLOW_UP_TOGGLE_EDIT_FORM = "FOLLOW_UP_TOGGLE_EDIT_FORM";
export const FOLLOW_UP_CLOSE_EDIT_FORM = "FOLLOW_UP_CLOSE_EDIT_FORM";

export const FOLLOW_UP_SET_DATA = "FOLLOW_UP_SET_DATA";
export const FOLLOW_UP_SET_ACTIVE_TAB = "FOLLOW_UP_SET_ACTIVE_TAB";
