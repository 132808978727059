import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  container: { height: "120px", display: "flex" },
  line: { borderTopColor: theme.palette.common.black },
  lastContent: { borderLeft: "none !important", flexDirection: "column" },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #000000",
  },
  reviewed: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 50,
    marginBottom: 10,
  },
}));

interface Props {
  readonly signature?: string;
  readonly doctor: DentalPointProps;
  readonly lineHide?: any;
}

export function DoctorInfo({ doctor, signature, lineHide }: Props) {
  const classes = useStyles();
  return (
    <div>
      {!lineHide && <hr className={classes.line} />}

      <div className={classes.container}>
        <div className={classes.content}>
          User signature:
          {!!signature && (
            <img alt="signature" src={`data:image/jpg;base64, ${signature}`} height="75px" />
          )}
        </div>
        <div className={cx(classes.content, classes.lastContent)}>
          <div>
            User name: <strong>{doctor?.name}</strong>
          </div>
          <div>
            License: <strong>{doctor?.code}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}
