import React from "react";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import FlexBox from "../../../ui/FlexBox";

export default function ChartSideListCode(props) {
  const { item } = props;
  const { openItem, copyItem } = props;

  return (
    <button
      className="btn btn-default btn-block chart-new-clinical-note-item"
      onClick={openItem(item)}
    >
      <FlexBox gutter={8}>
        <FlexBox flex={true}>
          <FlexBox flex={true} gutter={8} direction="column">
            <FlexBox>{item.name}</FlexBox>
            <FlexBox>
              <FlexBox flex={true} gutter={8}>
                <FlexBox flex={true}>
                  by
                  {item.doctor && item.doctor.name}
                </FlexBox>
                <FlexBox>
                  {Utils.parseDateFormat(
                    Utils.calcDateWithTimeZone(item.createdDate),
                    DATE_FORMAT_LEGACY,
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox
          align="center"
          justify="center"
          style={{ padding: "12px" }}
          onClick={(event) => {
            event.stopPropagation();

            if (copyItem) {
              copyItem();
            }
          }}
        >
          <div className="clinical-note-item-copy" />
        </FlexBox>
      </FlexBox>
    </button>
  );
}
