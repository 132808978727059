import React from "react";
import FilledImage from "../../widgets/FilledImage";

export default function condition(props) {
  const { property, color, toothImage } = props;
  if (!property) return null;

  const src = require(`../../../assets/images/conditions/short_root_canal/short_root_canal_${toothImage}@2x.png`)
    .default;

  return (
    <div className="condition-wrap tooth-image-condition">
      <FilledImage src={src} color={color} className="zoom-2x" />
    </div>
  );
}
