import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import bindActions from "../../../helpers/bindActions";
import Show from "../../widgets/Show";
import { withRouter } from "react-router";
import { Routes } from "../../../constants/Routes";
import { SelectPicker } from "../../select-picker/SelectPicker";

const enhancer = connect(
  ({ patientRegister }) => ({ patientRegister }),
  bindActions({ patientRegisterActions }),
);
class PrintModal extends PureComponent {
  static propTypes = {
    patientRegister: PropTypes.object,
    claimsDocTypeList: PropTypes.array,
    patientRegisterActions: PropTypes.object,
    claimRequestTypeCode: PropTypes.string,
    selectedItemId: PropTypes.number,
    setModalVisible: PropTypes.func,
    view: PropTypes.bool,
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      docType: "",
      mouseDown: false,
    };
  }

  componentDidUpdate() {
    if (this.canvas) {
      this.context = this.canvas.getContext("2d");
      this.context.lineWidth = 1;
      this.context.strokeStyle = "#000";
      this.context.lineJoin = this.context.lineCap = "round";

      this.bb = this.canvas.getBoundingClientRect();

      const img = new Image();
      img.onload = () => {
        this.context.drawImage(img, 0, 0);
      };
      img.src = this.props.patientRegister.signature;
    }
  }

  mouseDown = (e) => {
    if (!this.state.mouseDown) this.setState({ mouseDown: true });

    this.context.moveTo(
      (e.clientX || e.touches[0].clientX) - this.bb.left,
      (e.clientY || e.touches[0].clientY) - this.bb.top,
    );
  };

  mouseUp = () => {
    this.setState({ mouseDown: false });
  };

  mouseMove = (e) => {
    if (this.state.mouseDown) {
      // prevent IOS scroll when drawing
      if (e.touches) e.preventDefault();

      if (
        (e.clientX || e.touches[0].clientX) > 0 &&
        (e.clientY || e.touches[0].clientY) - this.bb.top < 200
      ) {
        this.context.lineTo(
          (e.clientX || e.touches[0].clientX) - this.bb.left,
          (e.clientY || e.touches[0].clientY) - this.bb.top,
        );

        this.context.stroke();
      }
    }
  };

  clearCanvas = () => {
    // eslint-disable-next-line no-self-assign
    this.canvas.width = this.canvas.width;
  };

  done = () => {
    this.props.patientRegisterActions.addSignature(this.canvas.toDataURL());
  };

  handleSelectDocType = (docType) => {
    this.setState({ docType });
  };

  handleSubmit = () => {
    const {
      claimRequestTypeCode,
      selectedItemId,
      setModalVisible,
      patientRegisterActions,
    } = this.props;
    const data = {
      id: selectedItemId,
      claimRequestTypeCode,
      documentTypeCode: this.state.docType,
    };
    patientRegisterActions.printClaim(data).then((response) => {
      if (response) {
        patientRegisterActions.savePdf(response);
        this.props.history.push(Routes.PatientClaimsApprovalsPdfView);
      }
    });
    setModalVisible();
    this.clearFields();
  };

  clearFields = () => {
    this.props.patientRegisterActions.addSignature("");
    this.setState({ docType: "" });
  };

  render() {
    const {
      props: {
        setModalVisible,
        show,
        view,
        claimsDocTypeList,
        patientRegister: { signature },
      },
      state: { docType },
    } = this;
    const options = claimsDocTypeList.length
      ? claimsDocTypeList.map((status) => {
          return { label: status.name, value: status.code };
        })
      : [];
    const readyToSubmit = (view || (signature && signature.length !== 0)) && docType;

    return (
      <Modal
        show={show}
        size="sm"
        animation={false}
        onHide={setModalVisible}
        title={<div>Please select pdf template and sign</div>}
        actions={
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                setModalVisible();
                this.clearFields();
              }}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSubmit}
              type="button"
              className="btn btn-primary"
              onClick={this.handleSubmit}
            >
              {view ? "View" : "Print"}
            </button>
          </div>
        }
      >
        <div className="d-flex flex-column align-items-center">
          <div style={{ width: "100%" }}>
            <div>PDF template:</div>
            <div
              className="form-group"
              style={{
                width: "100%",
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                placeholder="choose template"
                options={options}
                value={{ value: docType }}
                onChange={(x) => this.handleSelectDocType(x.value)}
              />
            </div>
            <Show if={!view && !signature.length}>
              <div
                style={{
                  border: "1px solid rgb(229, 229, 229)",
                  marginTop: "20px",
                }}
                className="d-flex flex-column align-items-center"
              >
                <h4>Please sign below:</h4>
                <div
                  style={{
                    borderTop: "1px solid #e5e5e5",
                    borderBottom: "1px solid #e5e5e5",
                  }}
                >
                  <canvas
                    ref={(c) => (this.canvas = c)}
                    width={260}
                    height={200}
                    onMouseDown={this.mouseDown}
                    onTouchStart={this.mouseDown}
                    onMouseUp={this.mouseUp}
                    onTouchEnd={this.mouseUp}
                    onMouseMove={this.mouseMove}
                    onTouchMove={this.mouseMove}
                  />
                </div>
                <div className="mt-3 d-flex justify-content-end" style={{ width: "100%" }}>
                  <Button onClick={this.clearCanvas} className="mr-3 mb-3">
                    Re-try
                  </Button>
                  <Button bsStyle="primary" className="mr-3 mb-3" onClick={this.done}>
                    Done
                  </Button>
                </div>
              </div>
            </Show>
            <Show if={!view && signature.length}>
              <img src={signature} alt="signature" />
            </Show>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(enhancer(PrintModal));
