import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ReactSpinner from "react-spinjs";

import Show from "../../../widgets/Show";
import ChartPrescriptionListItemCode from "./ChartPrescriptionListItemCode";
import ChartSideSearch from "../ChartSideSearch";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionTemplatesAdd extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
      chartSidePrescription: PropTypes.object,
    };

    componentDidMount() {
      this.props.chartSidePrescriptionActions.initTemplates();
    }

    openItem = (item) => {
      return () => {
        this.props.chartSidePrescriptionActions.addItem(item.id, 3);
      };
    };

    onSearch = (event) => {
      this.props.chartSidePrescriptionActions.setSearchValueTemplates(event.target.value);
    };

    toggleFavourite = (item) => {
      const favourites = JSON.parse(localStorage.getItem("PrescriptionFavourites"));

      if (!favourites[item.id]) {
        favourites[item.id] = item;
      }

      favourites[item.id].selected = !favourites[item.id].selected;

      localStorage.setItem("PrescriptionFavourites", JSON.stringify(favourites));

      this.forceUpdate();
    };

    render() {
      const { searchValueTemplates, templateList, fetching } = this.props.chartSidePrescription;

      const sortTemplate = _.sortBy(templateList, (item) => item.name);

      const $list = sortTemplate.map((item, index) => (
        <ChartPrescriptionListItemCode
          toggleFavourite={(item) => this.toggleFavourite(item)}
          key={index}
          item={item}
          openItem={this.openItem(item)}
        />
      ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader onBackClick={this.props.chartSidePrescriptionActions.popStack} />
          <div className="chart-side-body">
            <div className="title">Select From Templates</div>
            <ChartSideSearch value={searchValueTemplates} onChange={this.onSearch} />
            <div className="body">
              {$list}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
            <div className="all-codes">
              <button
                onClick={this.props.chartSidePrescriptionActions.addEmptyFromTemplates}
                className="btn btn-primary btn-block"
              >
                Add Blank
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
