import React, { useEffect } from "react";
import { connect } from "react-redux";

import Show from "../widgets/Show";
import bindActions from "../../helpers/bindActions";
import * as blockedTimeSlotActions from "../../actions/blockedTimeSlotActions";
import ReloadImage from "../../assets/images/blocked-time-slot-list-reload.png";
import { Formik, Form } from "formik";
import { DateField } from "../form/DateField";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { SelectField } from "../form/SelectField";

const enhancer = connect(null, bindActions({ blockedTimeSlotActions }));

const BlockedTimeSlotPanelHeader = (props) => {
  useEffect(() => {
    props.blockedTimeSlotActions.blockedTimeSlotChooseDoctor("all");
  }, []);

  const { handleSubmit, blockedFilterKey, blockedDoctors, initialValues } = props;
  const doctorOptions = (blockedDoctors || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));
  doctorOptions.unshift({ value: null, label: "All" });
  return (
    <div className="row blocked-time-slot-panel-header b-b">
      <div className="col-sm-8">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) =>
            handleSubmit(props.blockedTimeSlotActions.blockedTimeSlotGetList(values))
          }
        >
          {({ setFieldValue }) => (
            <Form className="form-inline blocked-timeslot-form d-flex align-items-start">
              <div className="form-group">
                <label>From</label>
                <DateField
                  name="startDate"
                  dateFormat={DATE_FORMAT_LEGACY}
                  className="small-datepicker"
                />
              </div>
              <div className="form-group">
                <label>To</label>
                <DateField
                  name="endDate"
                  dateFormat={DATE_FORMAT_LEGACY}
                  className="small-datepicker"
                />
              </div>
              <div className="form-group" style={{ width: "200px" }}>
                <SelectField
                  name="doctorIds"
                  fieldProps={{ style: { width: "100%" } }}
                  isMulti={true}
                  isSearchable={true}
                  options={doctorOptions}
                  onChange={(v) => {
                    setFieldValue("doctorIds", v);
                  }}
                />
              </div>
              <button className="btn btn-default ml-3" type="submit">
                <img alt="" src={ReloadImage} className="blocked-time-slot-list-reload" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="col-sm-4">
        <div className="input-group pull-right">
          <input
            type="text"
            className="form-control"
            value={blockedFilterKey}
            onChange={props.blockedTimeSlotActions.blockedTimeSlotSetFilterKey}
          />
          <span className="input-group-btn">
            <span className="btn-group">
              <button
                onClick={props.blockedTimeSlotActions.blockedTimeSlotGetList}
                className="btn btn-default"
                type="button"
              >
                Go!
              </button>
              <Show if={blockedFilterKey !== ""}>
                <button
                  onClick={props.blockedTimeSlotActions.blockedTimeSlotClearFilterKey}
                  className="btn btn-danger"
                  type="button"
                >
                  Reset Form
                </button>
              </Show>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default enhancer(BlockedTimeSlotPanelHeader);
