import React from "react";
import cx from "classnames";
import RNTimePicker, { TimePickerProps as RNTimePickerProps } from "rc-time-picker";

export type TimePickerProps = RNTimePickerProps;

export function TimePicker(props: TimePickerProps) {
  return (
    <RNTimePicker
      use12Hours={true}
      showSecond={false}
      {...props}
      className={cx("time-picker", props.className)}
    />
  );
}
