import _ from "lodash";
import VitalApi from "../api-legacy/VitalApi";

import * as ActionType from "../actionTypes/chartSideVitalActionTypes";
import { startLoader, stopLoader, showMessage } from "./loaderActions";
import { hideSideBar } from "./chartActions";
import Utils from "../helpers/Utils";
import { vitalStackCodes } from "../constants/chart-sidebar/vitalStackCodes";
import questionTypeCodes from "../constants/chart-sidebar/questionTypeCodes";

import { getNote } from "../helpers/clinicNote";

export function initSideVital() {
  return {
    type: ActionType.INIT_SIDE_VITAL,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_SORT_FIELD_SIDE_VITAL,
      code,
    });

    dispatch(getOwnedList());
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_VITAL_SEARCH_CHANGE,
    value,
  };
}

function pushStack(stackItem) {
  return {
    type: ActionType.PUSH_VITAL_STACK,
    stackItem,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSideVital;

    if (stack.length == 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_VITAL_STACK,
      });
    }
  };
}

export function openList() {
  return pushStack({
    code: vitalStackCodes.list,
  });
}

export function openItem(item, type) {
  return pushStack({
    code: vitalStackCodes.item,
    item,
    type,
  });
}

export function getOwnedList(query) {
  return (dispatch, getState) => {
    const {
      chart: { patient, patientKey },
      chartSideVital: { sortField },
    } = getState();

    const data = {
      patientKey: patientKey || (query && query.patientKey) || patient.patientKey,
      sortField,
      limit: 25,
    };

    dispatch(getVitalOwnedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadOwnedList() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSideVital: { sortField, ownedList, ownedListTotalCount },
    } = getState();

    if (ownedListTotalCount > ownedList.length) {
      const data = {
        patientKey: patient.patientKey,
        sortField,
        limit: 25,
        start: ownedList.length,
      };

      dispatch(getVitalOwnedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function getTemplateList() {
  return (dispatch, getState) => {
    const {
      chartSideVital: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const categoryId = stackItem.categoryId;

    const data = {
      categoryId,
    };

    dispatch(getVitalTemplateList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function getItem() {
  return (dispatch, getState) => {
    const {
      chartSideVital: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const id = stackItem.item.id;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(getVitalItem(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteItem() {
  return (dispatch, getState) => {
    const {
      chartSideVital: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const id = stackItem.item.id;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(deleteVitalItem(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getEmpty() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chartSideVital: { stack },
      chart,
    } = state;

    const stackItem = _.last(stack);
    const templateId = stackItem.item.id;

    const data = {
      templateId,
      patientKey: chart.patient.patientKey,
    };

    dispatch(startLoader());

    dispatch(getVitalEmpty(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function copyItem(item) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(saveVitalItem(item))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getOwnedList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveItem(name) {
  return (dispatch, getState) => {
    const {
      session: { toothNumericType, clinic },
      chartSideVital: { item, stack },
    } = getState();

    dispatch(startLoader());

    const stackItem = _.last(stack);

    if (stackItem.code == vitalStackCodes.item) {
      item.content = getNote(item, toothNumericType, true, clinic);

      if (name) {
        item.name = name;
      }

      item.templateQuestionsAndAnswers.map((question) => {
        if (
          question.questionType.code == questionTypeCodes.DATE_PICKER &&
          question.answers &&
          question.answers[0]
        ) {
          question.answers[0] = +question.answers[0] + Utils.getTimezoneDifference();
        }
      });
    }

    dispatch(saveVitalItem(item))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function selectQuestion(index) {
  return {
    type: ActionType.VITAL_SELECT_QUESTION,
    index: +index,
  };
}

export function prevQuestion() {
  return {
    type: ActionType.VITAL_PREV_QUESTION,
  };
}

export function nextQuestion() {
  return {
    type: ActionType.VITAL_NEXT_QUESTION,
  };
}

export function changeQuestionTextarea(value, questionId) {
  return {
    type: ActionType.VITAL_CHANGE_QUESTION_TEXTAREA,
    value,
    questionId,
  };
}

export function changeListOfAnswers(value, questionId) {
  return {
    type: ActionType.VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS,
    value,
    questionId,
  };
}

export function changeListOfAnswersMultiple(value, questionId) {
  return {
    type: ActionType.VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE,
    value,
    questionId,
  };
}

export function changeDatePicker(date, questionId) {
  return {
    type: ActionType.VITAL_CHANGE_DATE_PICKER,
    date,
    questionId,
  };
}

export function changeSurface(value, questionId) {
  return {
    type: ActionType.VITAL_CHANGE_SURFACE,
    value,
    questionId,
  };
}

export function selectTooth(value, questionId) {
  return {
    type: ActionType.VITAL_SELECT_TOOTH,
    value,
    questionId,
  };
}

export function setName(value) {
  return {
    type: ActionType.VITAL_SET_NAME,
    value,
  };
}

export function setTextareaBody(value) {
  return {
    type: ActionType.VITAL_SET_TEXTAREA_BODY,
    value,
  };
}

export function openDeleteModal(value, questionId) {
  return {
    type: ActionType.VITAL_OPEN_DELETE_MODAL,
    value,
    questionId,
  };
}

export function closeDeleteModal(value, questionId) {
  return {
    type: ActionType.VITAL_CLOSE_DELETE_MODAL,
    value,
    questionId,
  };
}

export function initEdit() {
  return {
    type: ActionType.VITAL_INIT_EDIT,
  };
}

export function changeCreatedDate(momentDate) {
  return (dispatch, getState) => {
    const {
      chartSideVital: { item },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: item.id,
      createdDate: +momentDate + Utils.getTimezoneDifference(),
    };

    dispatch(changeVitalCreatedDate(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getVitalOwnedList(data) {
  return {
    api: VitalApi.getOwnedList,
    types: [
      ActionType.GET_VITAL_OWNED_LIST_START,
      ActionType.GET_VITAL_OWNED_LIST_SUCCESS,
      ActionType.GET_VITAL_OWNED_LIST_ERROR,
    ],
    data,
  };
}

function getVitalTemplateList(data) {
  return {
    api: VitalApi.template.getList,
    types: [
      ActionType.GET_VITAL_TEMPLATE_LIST_START,
      ActionType.GET_VITAL_TEMPLATE_LIST_SUCCESS,
      ActionType.GET_VITAL_TEMPLATE_LIST_ERROR,
    ],
    data,
  };
}

function getVitalItem(data) {
  return {
    api: VitalApi.getItem,
    types: [
      ActionType.GET_VITAL_ITEM_START,
      ActionType.GET_VITAL_ITEM_SUCCESS,
      ActionType.GET_VITAL_ITEM_ERROR,
    ],
    data,
  };
}

function saveVitalItem(data) {
  return {
    api: VitalApi.saveItem,
    types: [
      ActionType.SAVE_VITAL_ITEM_START,
      ActionType.SAVE_VITAL_ITEM_SUCCESS,
      ActionType.SAVE_VITAL_ITEM_ERROR,
    ],
    data,
  };
}

function deleteVitalItem(data) {
  return {
    api: VitalApi.deleteItem,
    types: [
      ActionType.DELETE_VITAL_ITEM_START,
      ActionType.DELETE_VITAL_ITEM_SUCCESS,
      ActionType.DELETE_VITAL_ITEM_ERROR,
    ],
    data,
  };
}

function getVitalEmpty(data) {
  return {
    api: VitalApi.getEmpty,
    types: [
      ActionType.GET_VITAL_EMPTY_START,
      ActionType.GET_VITAL_EMPTY_SUCCESS,
      ActionType.GET_VITAL_EMPTY_ERROR,
    ],
    data,
  };
}

function changeVitalCreatedDate(data) {
  return {
    api: VitalApi.changeCreatedDate,
    types: [
      ActionType.CHANGE_VITAL_CREATED_DATE_START,
      ActionType.CHANGE_VITAL_CREATED_DATE_SUCCESS,
      ActionType.CHANGE_VITAL_CREATED_DATE_ERROR,
    ],
    data,
  };
}
