import React from "react";
import { Field, reduxForm } from "redux-form";

const form = reduxForm({
  form: "chartPostOperation",
});

class ChartPostOperationForm extends React.Component {
  printElem = () => {
    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write("<html><head><title>" + document.title + "</title>");
    mywindow.document.write("</head><body >");
    mywindow.document.write(this.refs.content.value.split("\n").join("<br />"));
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className="chart-post-op-form">
        <Field
          name="title"
          component="input"
          type="text"
          className="form-control chart-post-op-name"
        />

        <label htmlFor="chart-post-op-content">Write your note below:</label>
        <Field
          name="content"
          ref="content"
          id="chart-post-op-content"
          component="textarea"
          className="form-control chart-post-op-content"
        />

        <div className="chart-post-op-buttons">
          <button type="submit" className="btn btn-primary btn-lg">
            Done
          </button>
          {/* <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={this.props.chartSideSeriesActions.emailPostOperation}
          >
            Email to Patient
          </button> */}
          <button type="button" className="btn btn-primary btn-lg" onClick={this.printElem}>
            Print
          </button>
        </div>
      </form>
    );
  }
}

export default form(ChartPostOperationForm);
