import createReducer from "../helpers/createReducer";

const defaultState = {
  russian: {
    patientDetailsForm: {
      photo: "Фотография",
      generalInfo: "Общая информация",
      firstName: "Имя",
      lastName: "Фамилия",
      middleName: "Среднее имя",
      nationality: "Национальность",
      gender: "Пол",
      birthDate: "Дата рождения",
      maritalStatus: "Семейное положение",
      studentStatus: "Студенческий статус",
      weight: "Вес",
      referral: "Referral",
      additionalInformation: "Дополнительная информация",
      addressAndContacts: "Адрес и контакты",
      postCode: "Индекс",
      street: "Улица",
      street2: "Улица 2",
      country: "Страна",
      state: "Штат",
      city: "Город",
      workplace: "Место работы",
      homePhone: "Домашний телефон",
      workPhone: "Рабочий телефон",
      mobilePhone: "Мобильный телефон",
      email: "Email",
      idDocuments: "ID Documents",
      socialSecurityNumber: "Social Security Number",
      driverLicenseNumber: "Driver`s License Number",
      socialInsuranceNumber: "Social Insurance Number",
      passportNumber: "Passport Number",
      nationalIdNumber: "National ID Number",
      internalFileNumber: "Internal File Number",
      emergencyContactPerson: "Emergency Contact Person",
      relationship: "Отношения",
      patientSignature: "Подпись пациента",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
  english: {
    patientDetailsForm: {
      photo: "Photo",
      generalInfo: "Patient Demographic Details",
      firstName: "First Name",
      lastName: "Last Name",
      middleName: "Middle Name",
      nationality: "Nationality",
      gender: "Gender",
      birthDate: "Birth Date",
      maritalStatus: "Marital Status",
      studentStatus: "Student Status",
      weight: "Weight",
      referral: "Referral",
      additionalInformation: "Additional Information",
      addressAndContacts: "Address & Contacts",
      postCode: "Post Code",
      street: "Street",
      street2: "Street 2",
      country: "Country",
      state: "State",
      city: "City",
      workplace: "Workplace",
      homePhone: "Home Phone",
      workPhone: "Work Phone",
      mobilePhone: "Mobile Phone",
      email: "Email",
      idDocuments: "ID Documents",
      socialSecurityNumber: "Social Security Number",
      driverLicenseNumber: "Driver`s License Number",
      socialInsuranceNumber: "Social Insurance Number",
      passportNumber: "Passport Number",
      nationalIdNumber: "National ID Number",
      internalFileNumber: "Internal File Number",
      emergencyContactPerson: "Emergency Contact Person",
      relationship: "Relationship",
      patientSignature: "Patient Signature",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
  arabic: {
    patientDetailsForm: {
      photo: "صورة",
      generalInfo: "المعلومات العامة",
      firstName: "الإسم الأول",
      lastName: "إسم العائلة",
      middleName: "إسم الأب",
      nationality: "الجنسية",
      gender: "الجنس",
      birthDate: "تاريخ الميلاد",
      maritalStatus: "الحالة الإجتماعية",
      studentStatus: "طالب",
      weight: "الوزن",
      referral: "الإحالة",
      additionalInformation: "المعلومات الإضافية",
      addressAndContacts: "العنوان",
      postCode: "صندوق البريد",
      street: "الشارع",
      street2: "الشارع",
      country: "البلد",
      state: "الولاية| الإمارة",
      city: "المدينة",
      workplace: "Workplace",
      homePhone: "هاتف المنزل",
      workPhone: "هاتف العمل",
      mobilePhone: "هاتف المحمول",
      email: "البريد الإلكتروني",
      idDocuments: "بطاقات التعريف",
      socialSecurityNumber: "رقم الضمان الإجتماعي",
      driverLicenseNumber: "رقم رخصة القيادة",
      socialInsuranceNumber: "رقم التأمين الإجتماعي",
      passportNumber: "رقم جواز السفر",
      nationalIdNumber: "رقم الهوية",
      internalFileNumber: "رقم ملف خاص",
      emergencyContactPerson: "للإتصال في حالة الطوارئ",
      relationship: "صلة القرابة",
      patientSignature: "التوقيع",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
  german: {
    patientDetailsForm: {
      photo: "Foto",
      generalInfo: "Allgemeine Information",
      firstName: "Vorname",
      lastName: "Familienname, Nachname",
      middleName: "Zweiter Vorname",
      nationality: "Staatsangehörigkeit",
      gender: "Geschlecht",
      birthDate: "Geburtsdatum",
      maritalStatus: "Familienstand",
      studentStatus: "Studenten-Status",
      weight: "Gewicht",
      referral: "Verweisung",
      additionalInformation: "zusätzliche Information",
      addressAndContacts: "Adresse und Kontakte",
      postCode: "Postleitzahl",
      street: "Straße",
      street2: "Straße 2",
      country: "Land",
      state: "Zustand",
      city: "Stadt",
      workplace: "Workplace",
      homePhone: "Festnetztelefon",
      workPhone: "Arbeitshandy",
      mobilePhone: "Mobiltelefon",
      email: "Email",
      idDocuments: "ID-Dokumente",
      socialSecurityNumber: "Sozialversicherungsnummer",
      driverLicenseNumber: "Führerscheinnummer",
      socialInsuranceNumber: "Sozialversicherungsnummer",
      passportNumber: "Ausweisnummer",
      nationalIdNumber: "nationale ID-Nummer",
      internalFileNumber: "Interne Dateinummer",
      emergencyContactPerson: "Notfallkontaktperson",
      relationship: "Beziehung",
      patientSignature: "Unterschrift des Patienten",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
  slovak: {
    patientDetailsForm: {
      photo: "Fotografia",
      generalInfo: "Všeobecné informácie",
      firstName: "Krstné meno",
      lastName: "Priezvisko",
      middleName: "Stredné meno",
      nationality: "Národnosť",
      gender: "Pohlavie",
      birthDate: "Dátum narodenia",
      maritalStatus: "Rodinný stav",
      studentStatus: "Študentský stav",
      weight: "Váha",
      referral: "Ako ste sa o nás dozvedeli",
      additionalInformation: "Ďalšie informácie",
      addressAndContacts: "Adresa a kontakt",
      postCode: "PSČ",
      street: "Ulica",
      street2: "Ulica 2",
      country: "Okres",
      state: "Štát",
      city: "Mesto",
      workplace: "Workplace",
      homePhone: "Domáci telefón",
      workPhone: "Pracovný telefón",
      mobilePhone: "Mobilný telefón",
      email: "Email",
      idDocuments: "Identifikácia",
      socialSecurityNumber: "Rodné číslo",
      driverLicenseNumber: "Číslo vodičského preukazu",
      socialInsuranceNumber: "Číslo občianskeho preukazu",
      passportNumber: "Číslo pasu",
      nationalIdNumber: "Národné identifikačné číslo",
      internalFileNumber: "Interné číslo súboru",
      emergencyContactPerson: "Kontaktná osoba v núdzových situáciách",
      relationship: "Vzťah",
      patientSignature: "Podpis pacienta",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
  french: {
    patientDetailsForm: {
      photo: "Photo",
      generalInfo: "Informations générales",
      firstName: "Prénom",
      lastName: "Nom de famille",
      middleName: "Deuxième nom",
      nationality: "Nationalité",
      gender: "Le genre",
      birthDate: "Date de naissance",
      maritalStatus: "État civil",
      studentStatus: "Statut d'étudiant",
      weight: "Poids",
      referral: "Référence",
      additionalInformation: "Information additionnelle",
      addressAndContacts: "Adresse et Contacts",
      postCode: "Code postal",
      street: "rue",
      street2: "rue 2",
      country: "Pays",
      state: "Etat",
      city: "Ville",
      workplace: "Lieu de travail",
      homePhone: "Téléphone fixe",
      workPhone: "Téléphone de travail",
      mobilePhone: "Téléphone portable",
      email: "Email",
      idDocuments: "Documents d'identité",
      socialSecurityNumber: "Numéro de sécurité sociale",
      driverLicenseNumber: "Numéro de permis de conduire",
      socialInsuranceNumber: "Numéro d'assurance sociale",
      passportNumber: "Numéro de passeport",
      nationalIdNumber: "numéro national d'identité",
      internalFileNumber: "Numéro de fichier interne",
      emergencyContactPerson: "Personne à contacter en cas d'urgence",
      relationship: "Relation",
      patientSignature: "Signature du patient",
      primaryCareProvider: "Primary Care Provider",
      primaryCareProviderRelationShip: "Primary Care Provider Relationship",
      providerRole: "Provider Role",
      assignDoctor: "Assign doctor",
      roleBegin: "Role Begin",
      roleEnd: "Role End",
    },
  },
};

export default createReducer(defaultState, []);
