import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import { Routes } from "../../../constants/Routes";
import bindActions from "../../../helpers/bindActions";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import PatientMedicalAlertsForm from "./PatientMedicalAlertsForm";
import PatientDetailsSignature from "./../PatientDetailsSignature";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import medicalFillInFormItemTypes from "../../../constants/chart-sidebar/medicalFillInFormItemTypes";

const enhancer = connect(
  ({ patientRegister, session }) => ({ patientRegister, session }),
  bindActions({ patientRegisterActions }),
);
class PatientMedicalAlertsEdit extends React.Component {
  UNSAFE_componentWillMount() {
    const query = parseQuery(this.props.location.search);

    if (query.register) {
      if (_.get(this.props.session, "patientSettings.medicalAlertsSkipped")) {
        return this.props.history.push(
          createUrl(Routes.PatientMedicationAlertsEdit, {
            query: {
              patientKey: query.patientKey,
              register: "true",
            },
          }),
        );
      }
    }
    this.props.patientRegisterActions.getMedicalAlertsEdit(query.patientKey);
  }

  render() {
    const { patientMedicalAlerts } = this.props.patientRegister;

    let initialValues = {};
    let initialElements = [];

    const query = parseQuery(this.props.location.search);

    if (!_.isEmpty(patientMedicalAlerts)) {
      let { alerts = [] } = patientMedicalAlerts;

      alerts.forEach((element) => {
        let currentState = element.selected;
        let currentNote = element.note || "";
        let noteName = "note" + element.id;
        let switchName = "switch" + element.id;

        if (query.register || (query.temporary && _.isUndefined(currentState))) {
          currentState = false;
        }

        initialValues[switchName] = currentState;
        initialValues[noteName] = currentNote;

        let alertObject = {
          title: element.description || element.description2 || "",
          switchName: "switch" + element.id,
          switchState: currentState,
          noteName: noteName,
          currentNote: currentNote,
          type: element.type.code,
          subType: element?.subType?.name,
          code: element.code,
        };

        if (element.smokingStatus && element.smokingStatus.code) {
          alertObject.smokingStatus = element.smokingStatus;
          initialValues.smokingStatus = {
            code: element.smokingStatus.code,
            label: element.smokingStatus.name,
          };
          initialValues.smokingFrequency = element.smokingFrequency;
          initialValues.startSmokingAge = element.startSmokingAge;
          initialValues.stopSmokingAge = element.stopSmokingAge;
          initialValues.switchName = currentState;
        }

        if (element.smokingDevice && element.smokingDevice.code) {
          alertObject.smokingDevice = element.smokingDevice;
          initialValues.smokingDevice = {
            code: element.smokingDevice.code,
            label: element.smokingDevice.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.smokingFrequencyType && element.smokingFrequencyType.code) {
          alertObject.smokingFrequencyType = element.smokingFrequencyType;
          initialValues.smokingFrequencyType = {
            code: element.smokingFrequencyType.code,
            label: element.smokingFrequencyType.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.alcoholDrinkingStatus && element.alcoholDrinkingStatus.code) {
          alertObject.alcoholDrinkingStatus = element.alcoholDrinkingStatus;
          initialValues.alcoholDrinkingStatus = {
            code: element.alcoholDrinkingStatus.code,
            label: element.alcoholDrinkingStatus.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.substanceAbuseStatus && element.substanceAbuseStatus.code) {
          alertObject.substanceAbuseStatus = element.substanceAbuseStatus;
          initialValues.substanceAbuseStatus = {
            code: element.substanceAbuseStatus.code,
            label: element.substanceAbuseStatus.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.educationLevel && element.educationLevel.code) {
          alertObject.educationLevel = element.educationLevel;
          initialValues.educationLevel = {
            code: element.educationLevel.code,
            label: element.educationLevel.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.employmentStatus && element.employmentStatus.code) {
          alertObject.employmentStatus = element.employmentStatus;
          initialValues.employmentStatus = {
            code: element.employmentStatus.code,
            label: element.employmentStatus.name,
          };
          initialValues.switchName = currentState;
        }

        if (element.familyHistoryType && element.familyHistoryType.code) {
          alertObject.familyHistoryType = element.familyHistoryType;
          initialValues.familyHistoryType = {
            code: element.familyHistoryType.code,
            label: element.familyHistoryType.name,
          };
          initialValues.switchName = currentState;
        }

        initialElements.push(alertObject);
      });
    }

    initialValues.temporary = query.temporary;
    initialValues.register = query.register;

    return (
      <PatientMedicalAlertsForm
        medicalForms={this.props.medicalForms}
        onBackClick={() => this.props.history.goBack()}
        location={this.props.location}
        initialValues={initialValues}
        initialElements={initialElements}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        clinic={this.props.session.clinic}
        patientRegister={this.props.patientRegister}
        stackItem={this.props.stackItem}
        medicalFillInFormItemTypes={medicalFillInFormItemTypes}
      >
        <Show if={this.props.patientRegister.signatureModalVisible}>
          <PatientDetailsSignature patientRegister={this.props.patientRegister} />
        </Show>
      </PatientMedicalAlertsForm>
    );
  }
}

export default enhancer(PatientMedicalAlertsEdit);
