import _ from "lodash";
import React from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import bindActions from "../../../helpers/bindActions";
import FindOpenSlotSidebarForm from "./FindOpenSlotSidebarForm";
import FindOpenSlotSidebarList from "./FindOpenSlotSidebarList";
import FindOpenSlotSidebarHeader from "./FindOpenSlotSidebarHeader";
import * as calendarActions from "../../../actions/calendarActions";
import * as appointmentActions from "../../../actions/appointmentActions";
import {
  getCategories,
  getDoctors,
  getSubCategories,
  setOpenSlotDoctors,
} from "../../../actions/appointmentActions";
import FindOpenSlotSidebarAdvancedForm from "./FindOpenSlotSidebarAdvancedForm";
import { Modal } from "../../ui/Modal";

const enhancer = connect(
  ({ calendar: { reserved, item } }) => ({ reserved, item }),
  bindActions({
    calendarActions,
    getDoctors,
    getCategories,
    getSubCategories,
    setOpenSlotDoctors,
    appointmentActions,
  }),
);

class FindOpenSlotSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formSearchMode: "custom",

      category: "",
      subCategory: "",
    };
  }

  componentDidMount() {
    const { getCategories, reserved, calendarActions } = this.props;
    if (reserved) {
      calendarActions.switchOpenSlotReserved();
    }

    getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      doctors,
      getDoctors,
      getCategories,
      getSubCategories,
      setOpenSlotDoctors,
      appointmentActions,
      findOpenSlotToDate: nextFindOpenSlotToDate,
      findOpenSlotFromDate: nextFindOpenSlotFromDate,
      blockedTimeSlot: { keywords: nextKeywords = [] },
    } = this.props;

    const {
      findOpenSlotToDate: prevFindOpenSlotToDate,
      findOpenSlotFromDate: prevFindOpenSlotFromDate,
      blockedTimeSlot: { keywords: prevKeywords = [] },
    } = prevProps;

    const { category: prevCategory, subCategory: prevSubCategory } = prevState;
    const { category: nextCategory, subCategory: nextSubCategory } = this.state;

    const nextKeywordsString = nextKeywords
      .filter(({ active }) => active)
      .map(({ id }) => id)
      .join(",");

    if (!_.isEqual(prevKeywords, nextKeywords)) {
      appointmentActions.getDoctors(nextSubCategory || nextCategory, nextKeywordsString);
    }

    if (
      prevFindOpenSlotToDate === nextFindOpenSlotToDate &&
      prevFindOpenSlotFromDate === nextFindOpenSlotFromDate
    ) {
      if (!nextCategory) {
        setOpenSlotDoctors(doctors);
      }

      if (!prevCategory && nextCategory) {
        setOpenSlotDoctors([]);
      }
    }

    if (nextCategory && prevCategory !== nextCategory) {
      getCategories(nextCategory);
    }

    if (nextSubCategory && prevSubCategory !== nextSubCategory) {
      getDoctors(nextSubCategory, nextKeywordsString);
      getSubCategories(nextSubCategory);
    }
  }

  render() {
    const { formSearchMode } = this.state;
    const {
      reserved,
      doctors,
      item,
      searchMode,
      dentalPoint,
      openSlotWeekDays,
      findOpenSlotToDate,
      openSlotUserToTime,
      findOpenSlotFromDate,
      openSlotUserFromTime,
      findOpenSlotSidebarVisible,
    } = this.props;

    const initialValues = { ...item };
    const weekDays = (openSlotWeekDays || "").split(",");

    _.forEach(weekDays, (item) => {
      initialValues[_.lowerCase(item.substring(0, 3))] = true;
    });

    return (
      <Modal
        dialogClassName="find-open-slot-sidebar"
        animation={false}
        bodyProps={{ style: { padding: 0, margin: 0 } }}
        show={findOpenSlotSidebarVisible}
        onHide={this.props.calendarActions.findOpenSlotSidebarHide}
      >
        <FindOpenSlotSidebarHeader searchMode={searchMode} formSearchMode={formSearchMode} />

        <Show if={searchMode}>
          <div
            style={{
              flex: "1 1 0%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                className={cx("btn flex-shrink-1 flex-grow-1", {
                  "btn-primary": formSearchMode === "custom",
                  "btn-default": formSearchMode === "advanced",
                })}
                style={{ margin: "6px" }}
                onClick={() => {
                  this.setState({ formSearchMode: "custom" });
                  if (reserved) {
                    calendarActions.switchOpenSlotReserved();
                  }
                }}
              >
                Search Appointment
              </button>
              <button
                className={cx("btn flex-shrink-1 flex-grow-1", {
                  "btn-primary": formSearchMode === "advanced",
                  "btn-default": formSearchMode === "custom",
                })}
                style={{ margin: "6px" }}
                onClick={() => {
                  this.setState({ formSearchMode: "advanced" });
                  if (reserved) {
                    calendarActions.switchOpenSlotReserved();
                  }
                }}
              >
                Advanced Search
              </button>
            </div>

            <div style={{ flex: "1 1 0%", display: "flex" }}>
              {formSearchMode === "custom" && (
                <FindOpenSlotSidebarForm
                  findOpenSlotFromDate={findOpenSlotFromDate}
                  findOpenSlotToDate={findOpenSlotToDate}
                  openSlotUserFromTime={openSlotUserFromTime}
                  initialValues={initialValues}
                  openSlotUserToTime={openSlotUserToTime}
                  doctors={doctors}
                  dentalPoint={dentalPoint}
                />
              )}

              {formSearchMode === "advanced" && (
                <FindOpenSlotSidebarAdvancedForm
                  doctors={doctors}
                  fields={this.state}
                  findOpenSlotFromDate={findOpenSlotFromDate}
                  findOpenSlotToDate={findOpenSlotToDate}
                  openSlotUserFromTime={openSlotUserFromTime}
                  initialValues={initialValues}
                  openSlotUserToTime={openSlotUserToTime}
                  dentalPoint={dentalPoint}
                  onChange={(type, value) => this.setState({ [type]: value })}
                />
              )}
            </div>
          </div>
        </Show>
        <Show if={!searchMode}>
          <FindOpenSlotSidebarList
            patientKey={item?.patientKey}
            doctors={doctors}
            categories={this.state}
            dentalPoint={dentalPoint}
            formSearchMode={formSearchMode}
            appointmentId={item?.appointmentId}
          />
        </Show>
      </Modal>
    );
  }
}

export default enhancer(FindOpenSlotSidebar);
