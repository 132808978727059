import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Utils from "../../helpers/Utils";
import arrow from "../../assets/images/back.png";
import Show from "./Show";
import AppointmentApi from "../../api-legacy/AppointmentApi";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";

const useStyles = makeStyles(() => ({
  wrapper: {
    background: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100vh",
    zIndex: 2000,
    top: 0,
    left: 0,
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    background: "#fff",
    padding: "10px 30px",
    borderRadius: 6,
    width: "90%",
    height: "auto",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
    border: "1px solid #999999",
    position: "relative",
  },
  arrowBtn: {
    marginLeft: 25,
    cursor: "pointer",
  },
  xBtn: {
    position: "absolute",
    top: -5,
    right: 10,
    cursor: "pointer",
  },
  table: {
    border: "1px solid #ccc",
    display: "flex",
    flexDirection: "column",
  },
  tableHead: {
    display: "flex",
    height: 60,
    borderBottom: "1px solid #ccc",
    "&>:nth-child(1)": {
      width: "5%",
      height: "100%",
    },
    "&>:nth-child(2)": {
      borderLeft: "1px solid #ccc",
    },
  },
  tHeadTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "95%",
    height: "100%",
  },
  row: {
    display: "flex",
    height: 120,
    position: "relative",
  },
  day: {
    width: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #ccc",

    "&>:nth-child(1)": {
      fontSize: 22,
      color: "#343434",
      fontWeight: 600,
    },
    "&>:nth-child(2)": {
      color: "#BEC945",
      fontSize: 12,
    },
    "&>:nth-child(3)": {
      color: "#788027",
      padding: "4px 6px",
      fontWeight: 600,
      fontSize: 12,
      borderRadius: 5,
    },
  },
  slotsWrapper: {
    width: "95%",
    top: 0,
    left: "5%",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    maxHeight: 220,
  },
  slotsScroll: {
    width: "100%",
    height: "75%",
    maxHeight: 200,
    // lineHeight: 100,
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: 3,
      background: "#EFF4FA",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#BEC844",
      borderRadius: 10,
      width: 3,
    },
  },
  slots: {
    height: "75%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    overflow: "hidden",
  },
  showBtn: {
    color: "#4578c5",
    textAlign: "center",
    cursor: "pointer",
    // border: "1px solid red",
    marginBottom: 10,
    "&:hover": {
      color: "#113857",
    },
  },
  slotItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    flex: 1,
    height: 30,
    cursor: "pointer",
    "& div": {
      color: "#4578c5",
      marginBottom: 25,
    },
    "&:hover>div": {
      color: "#113857",
    },
  },
}));

const TimeSlotList = ({ options, closeModal, elements, item }) => {
  const classes = useStyles();
  const [day, setDay] = useState(0);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const date = Utils.calcDateWithTimeZone(elements.date.value).add(day >= 0 ? day : 0, "days");
  const date2 = Utils.calcDateWithTimeZone(elements.date.value).add(day >= 0 ? 1 + day : 1, "days");
  const date3 = Utils.calcDateWithTimeZone(elements.date.value).add(day >= 0 ? 2 + day : 2, "days");
  const date4 = Utils.calcDateWithTimeZone(elements.date.value).add(day >= 0 ? 3 + day : 3, "days");
  const today = Utils.calcDateWithTimeZone(new Date())._d.toString().split(" ")[2];

  const transfer = {
    machineId: elements.machinesSB.selectedId !== -100 ? elements.machinesSB.selectedId : -1,
    appointmentId: item && item.id,
    duration: elements.lengthSB.selectedId,
    dentalPointId: elements.dentalPointSB.selected.id,
    dentistId: elements.dentistSB.selected.id,
  };

  useEffect(() => {
    callApiList(
      Utils.calcDateWithTimeZone(elements.date.value),
      Utils.calcDateWithTimeZone(elements.date.value).add(1, "days"),
      Utils.calcDateWithTimeZone(elements.date.value).add(2, "days"),
      Utils.calcDateWithTimeZone(elements.date.value).add(3, "days"),
    );
  }, [elements]);

  useEffect(() => {
    if (day > 0) {
      callApiList(
        Utils.calcDateWithTimeZone(elements.date.value).add(day, "days"),
        Utils.calcDateWithTimeZone(elements.date.value).add(day + 1, "days"),
        Utils.calcDateWithTimeZone(elements.date.value).add(day + 2, "days"),
        Utils.calcDateWithTimeZone(elements.date.value).add(day + 3, "days"),
      );
    }
  }, [day]);

  const callApiList = (day1, day2, day3, day4) => {
    if (transfer.dentistId && transfer.dentalPointId) {
      AppointmentApi.getFreePoints({
        ...transfer,
        ...timePeriod(day1),
      }).then((res) => {
        setOptions1(res.data.data);
      });
      AppointmentApi.getFreePoints({
        ...transfer,
        ...timePeriod(day2),
      }).then((res) => {
        setOptions2(res.data.data);
      });
      AppointmentApi.getFreePoints({
        ...transfer,
        ...timePeriod(day3),
      }).then((res) => {
        setOptions3(res.data.data);
      });
      AppointmentApi.getFreePoints({
        ...transfer,
        ...timePeriod(day4),
      }).then((res) => {
        setOptions4(res.data.data);
      });
    }
  };

  const slotListFormatter = (arr) => {
    return arr.map((item) => {
      const start = Utils.calcDateWithTimeZone(item.startTime);
      const end = Utils.calcDateWithTimeZone(item.endTime);

      return {
        id: start.valueOf(),
        name: `${Utils.parseDateFormat(start)} - ${Utils.parseDateFormat(end)}${
          item.reserved ? ` ${item.reservedName}` : ""
        }`,
      };
    });
  };

  const timePeriod = (time) => {
    return {
      periodStart: getCompanyStartOfDay(time, false, true),
      periodEnd: getCompanyEndOfDay(getCompanyStartOfDay(time, false, true)),
    };
  };

  const slots = [
    {
      id: 1,
      day: date._d.toString().split(" ")[2],
      weekDay: date._d.toString().split(" ")[0],
      month: date._d.toString().split(" ")[1],
      options: slotListFormatter(options1) || [],
      date: date._d,
    },
    {
      id: 2,
      day: date2._d.toString().split(" ")[2],
      weekDay: date2._d.toString().split(" ")[0],
      month: date2._d.toString().split(" ")[1],
      options: slotListFormatter(options2) || [],
      date: date2._d,
    },
    {
      id: 3,
      day: date3._d.toString().split(" ")[2],
      weekDay: date3._d.toString().split(" ")[0],
      month: date3._d.toString().split(" ")[1],
      options: slotListFormatter(options3) || [],
      date: date3._d,
    },
    {
      id: 4,
      day: date4._d.toString().split(" ")[2],
      weekDay: date4._d.toString().split(" ")[0],
      month: date4._d.toString().split(" ")[1],
      options: slotListFormatter(options4) || [],
      date: date4._d,
    },
  ];

  const selectItem = (value, optionss, day) => {
    options.handler({ target: { value: value.id } }, optionss);
    options.selectedId = value.id;
    options.selected = value;
    elements.freeTimesSB.options = optionss;
    elements.date.value = day;
    closeModal();
  };

  return (
    <div className={classes.wrapper} onClick={closeModal}>
      <div
        className={classes.root}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img
          src={arrow}
          className={classes.arrowBtn}
          style={{ transform: "rotate(90deg)", marginBottom: 7 }}
          onClick={() => {
            setDay(day - 4);
            // prevDays();
          }}
        />

        <span className={classes.xBtn} onClick={closeModal} style={{ fontSize: 30 }}>
          ×
        </span>
        <div className={classes.table}>
          <div className={classes.tableHead}>
            <div></div>
            <div className={classes.tHeadTitle}>
              <span>{elements.dentistSB.selected?.name}</span>
              <span style={{ color: "#BEC945" }}>Dentist</span>
            </div>
          </div>
          {slots.map((item) => (
            <DaySlotItem key={item.id} item={item} today={today} selectItem={selectItem} />
          ))}
        </div>
        <img
          src={arrow}
          className={classes.arrowBtn}
          style={{ transform: "rotate(-90deg)", marginTop: 7 }}
          onClick={() => {
            setDay(day + 4);
            // nextDays();
          }}
        />
      </div>
    </div>
  );
};

export default TimeSlotList;

const DaySlotItem = ({ item, selectItem, today }) => {
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();
  const tempOptions = item?.options;

  return (
    <div key={item.id} className={classes.row}>
      <div className={classes.day}>
        <span>{item.day}</span>
        <span>{item.month}</span>
        <span
          style={{
            background: today == item.day && "#eee",
          }}
        >
          {today == item.day ? "Today" : item.weekDay}
        </span>
      </div>
      <div
        className={classes.slotsWrapper}
        style={{
          position: showMore && "absolute",
          boxShadow: showMore && "0px 0px 4px 1px rgba(0,0,0,0.25)",
          height: showMore && "auto",
          zIndex: showMore && 100,
          background: showMore && "#ececec",
          border: showMore && "none",
        }}
      >
        <div className={classes.slotsScroll}>
          <div
            className={classes.slots}
            style={{ height: showMore && 80, marginTop: !showMore && 20 }}
          >
            {item?.options?.slice(0, 10).map((itemm) => (
              <div
                key={itemm.id}
                className={classes.slotItem}
                onClick={() => selectItem(itemm, tempOptions, item.date)}
              >
                <div>{itemm.name.split("-")[0]}</div>
                <div>{itemm.name.split("-")[1]}</div>
              </div>
            ))}
          </div>
          <Show if={showMore && item?.options && item?.options.length > 10}>
            {item?.options &&
              item?.options.map((x, index) => {
                if (index % 10 == 0 && index != 0) {
                  return (
                    <div key={index} className={classes.slots} style={{ height: showMore && 80 }}>
                      {item?.options &&
                        item?.options?.slice(index, index + 10).map((item) => (
                          <div
                            key={item.id}
                            className={classes.slotItem}
                            onClick={() => selectItem(item, tempOptions, new Date(x.id))}
                          >
                            <div>{item.name.split("-")[0]}</div>
                            <div>{item.name.split("-")[1]}</div>
                          </div>
                        ))}
                    </div>
                  );
                }
              })}
          </Show>
        </div>

        <Show if={item?.options && item?.options.length > 10}>
          <p
            className={classes.showBtn}
            style={{ background: showMore && "#cbcbcb" }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Hide" : "Show more slots"}
          </p>
        </Show>
      </div>
    </div>
  );
};
