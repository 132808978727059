import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Back from "../widgets/Back";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";

import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import bindActions from "../../helpers/bindActions";
import * as loaderActions from "../../actions/loaderActions";
import * as appointmentActions from "../../actions/appointmentActions";

const enhancer = connect(
  ({ appointment: { appointmentType } }) => ({ appointmentType }),
  bindActions({ appointmentActions, loaderActions }),
);
class AppointmentType extends React.Component {
  render() {
    let $colors = Utils.objectToArray(Colors.getTypeColors(), (item) => {
      return (
        <Link
          key={item.code}
          to={createUrl(Routes.SettingsAppointmentTypeColor, {
            params: { colorCode: item.code },
          })}
          className="list-group-item"
        >
          <div className="pull-right">❯</div>
          <div className="color" style={{ backgroundColor: item.color.color }} />
          {item.name}
        </Link>
      );
    });

    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment type colors</div>
        </div>
        <div className="body">
          <div className="list-group">{$colors}</div>
        </div>
      </div>
    );
  }
}
export default enhancer(AppointmentType);
