import _ from "lodash";
import FolderApi from "../api-legacy/FolderApi";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import PatientApi from "../api-legacy/PatientApi";

export const FOLDER_GET_LIST_START = "FOLDER/FOLDER_GET_LIST_START";
export const FOLDER_GET_LIST_SUCCESS = "FOLDER/FOLDER_GET_LIST_SUCCESS";
export const FOLDER_GET_LIST_ERROR = "FOLDER/FOLDER_GET_LIST_ERROR";

export const FOLDER_MOVE_ITEMS_START = "FOLDER/FOLDER_MOVE_ITEMS_START";
export const FOLDER_MOVE_ITEMS_SUCCESS = "FOLDER/FOLDER_MOVE_ITEMS_SUCCESS";
export const FOLDER_MOVE_ITEMS_ERROR = "FOLDER/FOLDER_MOVE_ITEMS_ERROR";

export const FOLDER_DELETE_ITEMS_START = "FOLDER/FOLDER_DELETE_ITEMS_START";
export const FOLDER_DELETE_ITEMS_SUCCESS = "FOLDER/FOLDER_DELETE_ITEMS_SUCCESS";
export const FOLDER_DELETE_ITEMS_ERROR = "FOLDER/FOLDER_DELETE_ITEMS_ERROR";

export const FOLDER_GET_FILES_LIST_START = "FOLDER/FOLDER_GET_FILES_LIST_START";
export const FOLDER_GET_FILES_LIST_SUCCESS = "FOLDER/FOLDER_GET_FILES_LIST_SUCCESS";
export const FOLDER_GET_FILES_LIST_ERROR = "FOLDER/FOLDER_GET_FILES_LIST_ERROR";

export const FOLDER_UPLOAD_FILES_START = "FOLDER/FOLDER_UPLOAD_FILES_START";
export const FOLDER_UPLOAD_FILES_SUCCESS = "FOLDER/FOLDER_UPLOAD_FILES_SUCCESS";
export const FOLDER_UPLOAD_FILES_ERROR = "FOLDER/FOLDER_UPLOAD_FILES_ERROR";

export const CREATE_FOLDER_START = "FOLDER/CREATE_FOLDER_START";
export const CREATE_FOLDER_SUCCESS = "FOLDER/CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_ERROR = "FOLDER/CREATE_FOLDER_ERROR";

export const FOLDER_FETCHING_LIST = "FOLDER/FOLDER_FETCHING_LIST";

export const FOLDER_SHOW_CREATE_DIALOG = "FOLDER/FOLDER_SHOW_CREATE_DIALOG";
export const FOLDER_HIDE_CREATE_DIALOG = "FOLDER/FOLDER_HIDE_CREATE_DIALOG";

export const FOLDER_OPEN_FOLDER = "FOLDER/FOLDER_OPEN_FOLDER";
export const FOLDER_CLOSE_FOLDER = "FOLDER/FOLDER_CLOSE_FOLDER";

export const FOLDER_INIT_FOLDER = "FOLDER/FOLDER_INIT_FOLDER";

export const FOLDER_SELECT_ITEM = "FOLDER/FOLDER_SELECT_ITEM";

export const FOLDER_CHANGE_MOVING_MODE = "FOLDER/FOLDER_CHANGE_MOVING_MODE";

export const FOLDER_UNSELECTED_ITEMS = "FOLDER/FOLDER_UNSELECTED_ITEMS";

export const FOLDER_OPEN_IMAGE = "FOLDER/FOLDER_OPEN_IMAGE";

export const FOLDER_RESET = "FOLDER/FOLDER_RESET";

export const IMAGE_TYPE_QUESTION_TOGGLE = "IMAGE_TYPE_QUESTION_TOGGLE";

export const FILE_SELECT_ITEM = "FILE_SELECT_ITEM";

export function imageTypeQuestionToggle(_bool) {
  return {
    type: IMAGE_TYPE_QUESTION_TOGGLE,
    _bool,
  };
}

export function openImage(imageId) {
  return {
    type: FOLDER_OPEN_IMAGE,
    imageId,
  };
}

export function resetFolder() {
  return {
    type: FOLDER_RESET,
  };
}

function unselectedItems() {
  return {
    type: FOLDER_UNSELECTED_ITEMS,
  };
}

export function selectItem(itemType, itemId) {
  return {
    type: FOLDER_SELECT_ITEM,
    itemId,
    itemType,
  };
}

export function selectFile(file) {
  return {
    type: FILE_SELECT_ITEM,
    file,
  };
}

export function changeMovingMode(mode) {
  return {
    type: FOLDER_CHANGE_MOVING_MODE,
    mode,
  };
}

export function moveItems({ patientKey, targetFolderId }, isClosed) {
  return (dispatch, getState) => {
    const { folder } = getState();

    dispatch(fetchingFolderList(true));

    const data = {
      patientKey,
      targetFolderId,
      fileIds: folder.selectedFiles,
      folderIds: folder.selectedFolders,
    };

    return dispatch(moveItemsApi(data))
      .then(() => {
        dispatch(changeMovingMode(false));

        if (isClosed) {
          dispatch(
            closeFolder({
              patientKey,
            }),
          );
        } else {
          dispatch(
            openFolder({
              patientKey,
              folderId: targetFolderId,
            }),
          );
        }

        dispatch(unselectedItems());
      })
      .catch((error) => {
        dispatch(changeMovingMode(false));
        dispatch(fetchingFolderList(false));
        showMessage(error);
      });
  };
}

export function initFolders() {
  return {
    type: FOLDER_INIT_FOLDER,
  };
}

export function deleteItems(patientKey) {
  return (dispatch, getState) => {
    const { folder } = getState();

    dispatch(fetchingFolderList(true));

    const data = {
      patientKey,
      fileIds: folder.selectedFiles,
      folderIds: folder.selectedFolders,
    };

    return dispatch(deleteItemsApi(data))
      .then(() => {
        dispatch(fetchingFolderList(false));

        dispatch(
          getFolderList({
            patientKey,
            folderId: _.last(folder.folderStack) || null,
          }),
        );

        dispatch(unselectedItems());
      })
      .catch((error) => {
        dispatch(fetchingFolderList(false));
        dispatch(showMessage(error));
      });
  };
}

export function openFolder(params) {
  return (dispatch) => {
    dispatch({
      type: FOLDER_OPEN_FOLDER,
      folderId: params.folderId,
    });

    dispatch(getFolderList(params));
  };
}

export function closeFolder(params) {
  return (dispatch, getState) => {
    dispatch({
      type: FOLDER_CLOSE_FOLDER,
    });

    const { folder } = getState();

    dispatch(
      getFolderList({
        ...params,
        folderId: _.last(folder.folderStack) || null,
      }),
    );
  };
}

export function showCreateDialog() {
  return {
    type: FOLDER_SHOW_CREATE_DIALOG,
  };
}

export function hideCreateDialog() {
  return {
    type: FOLDER_HIDE_CREATE_DIALOG,
  };
}

export function uploadFiles({ patientKey, files, folderId }) {
  return (dispatch) => {
    if (!files.length) {
      showMessage("No Files");
      return;
    }

    dispatch(fetchingFolderList(true));
    const promises = [];
    [...files].forEach((file) => {
      const data = new FormData();
      const fileExtension = file.name.slice(
        (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1,
      );
      data.append("file", file, file.name);
      if (file?.type === "image/jpeg" || file?.type === "image/jpg" || file?.type === "image/png") {
        data.append("typeCode", "PATIENT_ATTACHMENT_CHART_IMAGE");
      } else if (file?.type === "application/pdf") {
        data.append("typeCode", "PATIENT_ATTACHMENT_CHART_PDF");
      } else {
        if (fileExtension === "asc") data.append("typeCode", "PATIENT_ATTACHMENT_CHART_FILE");
      }

      data.append("patientKey", patientKey);
      data.append("description", file.name);

      if (folderId) {
        data.append("folderId", folderId);
      }
      if (file?.type || fileExtension === "asc") promises.push(dispatch(uploadFilesApi(data)));
    });

    return Promise.all(promises)
      .then(() => {
        dispatch(
          getFolderList({
            patientKey,
            folderId,
          }),
        );
      })
      .catch((error) => {
        dispatch(fetchingFolderList(false));
        dispatch(showMessage(error));
      });
  };
}

export function getFolderList(params /* folderId: number; patientKey: string; */) {
  return function (dispatch) {
    dispatch(fetchingFolderList(true));

    const data = {
      ...params,

      start: 0,
      limit: 50,
    };

    const promises = [dispatch(getListApi(data)), dispatch(getFilesApi(data))];

    return Promise.all(promises)
      .then(() => {
        dispatch(fetchingFolderList(false));
      })
      .catch((error) => {
        dispatch(fetchingFolderList(false));

        showMessage(error);
      });
  };
}

export function loadFilesList(params) {
  return (dispatch, getState) => {
    const {
      folder: { folderStack, filesList, folderList },
    } = getState();
    const currentFolder = _.last(folderStack) || 0;

    if (filesList[`${currentFolder}TotalCount`] > (filesList[currentFolder] || []).length) {
      dispatch(fetchingFolderList(true));
      const start = (filesList[currentFolder] && (filesList[currentFolder] || []).length) || 0;

      const data = {
        ...params,
        start: start,
        limit: 50,
      };
      dispatch(getFilesApi(data))
        .then(() => {
          dispatch(fetchingFolderList(false));
        })
        .catch((error) => {
          dispatch(fetchingFolderList(false));

          showMessage(error);
        });
    }

    if (folderList[`${currentFolder}TotalCount`] > (folderList[currentFolder] || []).length) {
      dispatch(fetchingFolderList(true));
      const start = (folderList[currentFolder] && (folderList[currentFolder] || []).length) || 0;

      const data = {
        ...params,
        start: start,
        limit: 50,
      };
      dispatch(getListApi(data))
        .then(() => {
          dispatch(fetchingFolderList(false));
        })
        .catch((error) => {
          dispatch(fetchingFolderList(false));

          showMessage(error);
        });
    }
  };
}

/*
 * name: string;
 * parentId?: number;
 * patientKey: string;
 */

export function createFolder(data) {
  return (dispatch, getState) => {
    const { folder } = getState();

    dispatch(startLoader());

    return dispatch(createFolderApi(data))
      .then(() => {
        dispatch(stopLoader());

        dispatch(hideCreateDialog());

        dispatch(
          getFolderList({
            patientKey: data.patientKey,
            folderId: _.last(folder.folderStack) || null,
          }),
        );
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function fetchingFolderList(fetching) {
  return {
    type: FOLDER_FETCHING_LIST,
    fetching,
  };
}

function getListApi(data) {
  return {
    request: data,
    api: FolderApi.getList,
    types: [FOLDER_GET_LIST_START, FOLDER_GET_LIST_SUCCESS, FOLDER_GET_LIST_ERROR],
    data,
  };
}

function getFilesApi(data) {
  return {
    request: data,
    api: PatientApi.getFiles,
    types: [
      FOLDER_GET_FILES_LIST_START,
      FOLDER_GET_FILES_LIST_SUCCESS,
      FOLDER_GET_FILES_LIST_ERROR,
    ],
    data,
  };
}

function uploadFilesApi(data) {
  return {
    request: data,
    api: PatientApi.uploadDocs,
    types: [FOLDER_UPLOAD_FILES_START, FOLDER_UPLOAD_FILES_SUCCESS, FOLDER_UPLOAD_FILES_ERROR],
    data,
  };
}

function createFolderApi(data) {
  return {
    request: data,
    api: FolderApi.createFolder,
    types: [CREATE_FOLDER_START, CREATE_FOLDER_SUCCESS, CREATE_FOLDER_ERROR],
    data,
  };
}

function moveItemsApi(data) {
  return {
    request: data,
    api: FolderApi.moveItems,
    types: [FOLDER_MOVE_ITEMS_START, FOLDER_MOVE_ITEMS_SUCCESS, FOLDER_MOVE_ITEMS_ERROR],
    data,
  };
}

function deleteItemsApi(data) {
  return {
    request: data,
    api: FolderApi.deleteItems,
    types: [FOLDER_DELETE_ITEMS_START, FOLDER_DELETE_ITEMS_SUCCESS, FOLDER_DELETE_ITEMS_ERROR],
    data,
  };
}
