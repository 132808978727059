import React from "react";

const MedicalFormsAddendumItem = ({ chartSideMedicalFillInForm: { addendumItem } }) => {
  return (
    <div className="medical-fill-in-form-question">
      <div className="medical-fill-in-form-question__title">{addendumItem.title}</div>
      <div className="medical-fill-in-form-question__body">{addendumItem.content}</div>
      <div className="medical-fill-in-form-question__body"></div>
      <div className="medical-fill-in-form-question__actions"></div>
    </div>
  );
};

export default MedicalFormsAddendumItem;
