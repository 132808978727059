import React from "react";
import { connect } from "react-redux";

import Back from "../widgets/Back";

import { PERMISSIONS } from "../../constants/Constants";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";

const enhancer = connect(
  ({ patientRegister, session: { permissions } }) => ({
    patientRegister,
    permissions,
    canEdit: permissions.indexOf("EDIT_PATIENT_DETAIL") >= 0,
  }),
  bindActions({ patientRegisterActions }),
);
class PatientProfile extends React.Component {
  componentDidMount() {
    const query = parseQuery(this.props.location.search);
    this.props.patientRegisterActions.getDetailsInfo(query.patientKey);
  }

  openPatientDetailsView = () => {
    const query = parseQuery(this.props.location.search);
    this.props.history.push(
      createUrl(Routes.PatientDetailsEdit, {
        query: {
          patientKey: query.patientKey,
        },
      }),
    );
  };

  render() {
    const { patientInfo } = this.props.patientRegister;
    const { permissions, canEdit } = this.props;

    return (
      <div className="patient-contacts">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" onClick={() => this.props.history.goBack()} />
          {canEdit && (
            <button className="btn btn-link pull-right" onClick={this.openPatientDetailsView}>
              EDIT
            </button>
          )}
          <div className="title">
            Patient Details{" "}
            <strong>
              Chart:#
              {patientInfo.chartNumber}
            </strong>
          </div>
        </div>
        <div className="patient-contacts-body">
          <div className="head-title">Contact Details</div>
          <div className="content-box">
            <div className="content-item">
              <div className="item">
                <div className="title">Post Code:</div>
                <div className="body">
                  {(patientInfo.address && patientInfo.address.postcode) || "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Street:</div>
                <div className="body">
                  {(patientInfo.address && patientInfo.address.line1) || "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Street2:</div>
                <div className="body">
                  {(patientInfo.address && patientInfo.address.line2) || "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">City:</div>
                <div className="body">
                  {(patientInfo.address &&
                    patientInfo.address.town &&
                    patientInfo.address.town.name) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">State:</div>
                <div className="body">
                  {(patientInfo.address &&
                    patientInfo.address.state &&
                    patientInfo.address.state.name) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Country:</div>
                <div className="body">
                  {(patientInfo.address &&
                    patientInfo.address.country &&
                    patientInfo.address.country.name) ||
                    "N/A"}
                </div>
              </div>
            </div>
            <div className="content-item content-end content-right">
              <div className="item">
                <div className="title">Home Phone:</div>
                <div className="body">
                  {permissions && permissions.indexOf(PERMISSIONS.VIEW_PATIENT_CONTACT_DETAILS) >= 0
                    ? patientInfo.homePhoneNumber || "N/A"
                    : "+()"}
                </div>
              </div>
              <div className="item">
                <div className="title">Work Phone:</div>
                <div className="body">
                  {permissions && permissions.indexOf(PERMISSIONS.VIEW_PATIENT_CONTACT_DETAILS) >= 0
                    ? patientInfo.workPhoneNumber || "N/A"
                    : "+()"}
                </div>
              </div>
              <div className="item">
                <div className="title">Mobile Phone:</div>
                <div className="body">
                  {permissions && permissions.indexOf(PERMISSIONS.VIEW_PATIENT_CONTACT_DETAILS) >= 0
                    ? patientInfo.mobilePhoneNumber || "N/A"
                    : "+()"}
                </div>
              </div>
              <div className="item">
                <div className="title">Email:</div>
                <div className="body">{patientInfo.email || "N/A"}</div>
              </div>
            </div>
          </div>
          <div className="head-title">Emergency Contact Person</div>
          <div className="content-box">
            <div className="content-item">
              <div className="item">
                <div className="title">Last Name:</div>
                <div className="body">
                  {(patientInfo.emergencyContacts &&
                    patientInfo.emergencyContacts[0] &&
                    patientInfo.emergencyContacts[0].lastName) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">First Name:</div>
                <div className="body">
                  {(patientInfo.emergencyContacts &&
                    patientInfo.emergencyContacts[0] &&
                    patientInfo.emergencyContacts[0].firstName) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Relationship:</div>
                <div className="body">
                  {(patientInfo.emergencyContacts &&
                    patientInfo.emergencyContacts[0] &&
                    patientInfo.emergencyContacts[0].relationship) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Home Phone:</div>
                <div className="body">
                  {(patientInfo.emergencyContacts &&
                    patientInfo.emergencyContacts[0] &&
                    patientInfo.emergencyContacts[0].homePhoneNumber) ||
                    "N/A"}
                </div>
              </div>
              <div className="item">
                <div className="title">Mobile Phone:</div>
                <div className="body">
                  {(patientInfo.emergencyContacts &&
                    patientInfo.emergencyContacts[0] &&
                    patientInfo.emergencyContacts[0].mobilePhoneNumber) ||
                    "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(PatientProfile);
