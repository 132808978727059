import _ from "lodash";
import React from "react";
import { SidebarFavoritesList } from "./SidebarFavoritesList";

import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import { SidebarContentContainer } from "./SidebarContentContainer";
import { SidebarContentActions } from "./SidebarContentActions";
import ChartImageSelector from "../chart/side-bar/ChartImageSelector";
import { SidebarList } from "./SidebarList";
import { SidebarItem } from "./SidebarItem";

export function SidebarMixed({
  onSort,
  viewTab,
  onSearch,
  openItem,
  loadList,
  stack = [],
  favourites,
  searchValue,
  onActionClick,
  toggleFavorite,
  chartSideSeries,
  showImageSelector,
  isItem,
  getMixedCodes,
  getList,
  popStack,
  initItem,
  backFromItem,
  pushStack,
  changeTypeTab,
  getFavorites,
  onRequestClose,
  addRequestedTreament,
  changeActiveTooth,
  toothNumericType,
  item,
  member,
}) {
  const {
    favouritesIcons,
    activeFavouriteCode,
    imageSelectorVisible,
    activeFavouriteSeriesType,
  } = chartSideSeries;

  const isAllCodes = _.last(stack) === SidebarStackTypes.AllCodes;

  const type = _.isUndefined(item.system) ? SidebarTypes.Treatments : SidebarTypes.Diagnosis;

  return (
    <SidebarContentContainer
      onSearch={(v) => {
        onSearch(v);
        if (isAllCodes) {
          getMixedCodes();
        }
      }}
      isItem={isItem}
      searchValue={searchValue}
      title={isAllCodes ? "Mixed" : _.isEmpty(stack) ? "Favorite Mixed" : null}
    >
      {isItem && (
        <SidebarItem
          item={item}
          member={member}
          getList={getList}
          initItem={(id) => initItem(id, type)}
          popStack={popStack}
          pushStack={(s) => {
            pushStack(s);
            getList(SidebarTypes.Diagnosis);
          }}
          isMixed={true}
          backFromItem={backFromItem}
          changeTypeTab={changeTypeTab}
          onRequestClose={onRequestClose}
          typeTab={type}
          chartSideSeries={chartSideSeries}
          toothNumericType={toothNumericType}
          changeActiveTooth={changeActiveTooth}
          addRequestedTreament={addRequestedTreament}
        />
      )}

      {_.isEmpty(stack) && (
        <SidebarFavoritesList
          onSort={onSort}
          viewTab={viewTab}
          openItem={openItem}
          getFavorites={getFavorites}
          favoritesList={favourites}
          typeTab={SidebarTypes.Mixed}
          toggleFavourite={toggleFavorite}
          chartSideSeries={chartSideSeries}
          showImageSelector={showImageSelector}
        />
      )}

      {isAllCodes && (
        <SidebarList
          viewTab={viewTab}
          loadList={loadList}
          openItem={openItem}
          typeTab={SidebarTypes.Mixed}
          toggleFavorite={toggleFavorite}
          chartSideSeries={chartSideSeries}
        />
      )}

      <SidebarContentActions>
        {_.isEmpty(stack) && (
          <button
            type="button"
            onClick={() => onActionClick(SidebarStackTypes.AllCodes)}
            className="btn btn-primary btn-block m-0"
          >
            All codes
          </button>
        )}
      </SidebarContentActions>

      <ChartImageSelector
        seriesType={SidebarTypes.Mixed}
        favouritesIcons={favouritesIcons}
        activeFavouriteCode={activeFavouriteCode}
        imageSelectorVisible={imageSelectorVisible}
        activeFavouriteSeriesType={activeFavouriteSeriesType}
      />
    </SidebarContentContainer>
  );
}
