import _, { toFinite } from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import {
  DATE_FORMAT_LEGACY,
  TreatmentGrouppingTypes as treatmentGroupping,
} from "../../../../constants/Constants";
import Show from "../../../widgets/Show";

import bindActions from "../../../../helpers/bindActions";
import * as chartActions from "../../../../actions/chartActions";
import { getCompanyFormatDate } from "../../../../helpers/DateUtils";
import Utils from "../../../../helpers/Utils";

const enhancer = connect(({ session }) => ({ session }), bindActions({ chartActions }));
export default enhancer(
  class SelectTreatmentToCompleteTreatmentList extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        filter: "treatment_session_asc",
        openedGroups: {},
      };
    }
    generateList = (treatmentList) => {
      const {
        chart: { selectTreatmentToCompleteListTotalCount, selectTreatmentToCompleteSelectedItems },
        session,
      } = this.props;

      const showFee = session.permissions.indexOf("VIEW_DENTAL_TRANSACTION_ITEM_FEE") >= 0;

      let list = [];

      _.forEach(treatmentList, (item, index) => {
        let parts = _.get(item, "parts");

        if (!_.isEmpty(parts)) {
          list.push(
            <div className="select-treatment-list-row" key={index}>
              <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
              <div className="item-treatment-code">
                {item.treatmentItem && item.treatmentItem.code}
              </div>
              <div className="item-tooth">{item.tooth && item.tooth.name}</div>
              <div className="item-session">{item.treatmentSession || 0}</div>
              <div className="item-description" style={{ minWidth: 100 }}>
                {item.treatmentItem && item.treatmentItem.description}
              </div>
              {showFee && (
                <div className="item-fee">
                  {item?.discount?.code === "AMOUNT"
                    ? item.price - toFinite(item?.discount?.value)
                    : item.price - (item.price * toFinite(item?.discount?.value)) / 100}
                </div>
              )}
              <div className="item-approval">
                {(item.primaryPayerShare && parseFloat(item.primaryPayerShare).toFixed(2)) ||
                  "0.00"}
              </div>
              <div className="item-approval">
                {(item.secondaryPayerShare && parseFloat(item.secondaryPayerShare).toFixed(2)) ||
                  "0.00"}
              </div>
              <div className="item-approval">
                {(item.patientShare && parseFloat(item.patientShare).toFixed(2)) || "0.00"}
              </div>
              <div className="item-principal-diagnosis">
                {item.diagnosisItem && item.diagnosisItem.code}
              </div>
              <div className="item-approval">
                {item.approvalStatus && item.approvalStatus.name
                  ? item.approvalStatus.name
                  : item.holdPreApproval
                  ? "Hold"
                  : ""}
              </div>
              <div className="item-expiry-date" style={{ color: "#FF0000" }}>
                {item.priorApprovalExpireDate
                  ? Utils.parseDateFormat(
                      Utils.calcDateWithTimeZone(item.priorApprovalExpireDate),
                      DATE_FORMAT_LEGACY,
                    )
                  : ""}
              </div>
              <div className="item-approval">
                {item.approval2Status && item.approval2Status.name
                  ? item.approval2Status.name
                  : item.holdPreApproval
                  ? "Hold"
                  : ""}
              </div>
              <div className="item-posted-by">
                {item.createdDentist && item.createdDentist.name}
              </div>
              <div className="item-checkbox" />
            </div>,
          );

          _.forEach(parts, (subitem, subindex) => {
            let selectable = _.get(subitem, "status.name") !== "Completed";

            if (selectable) {
              let treatmentCodeNumber = _.get(subitem, "number", "");
              let treatmentCode =
                _.get(item, "treatmentItem.code", "") + " (v." + treatmentCodeNumber + ")";

              list.push(
                <div
                  className="select-treatment-list-row"
                  key={index + ":" + subindex}
                  onClick={() => {
                    this.toggleSelectedItem(subitem.key);
                  }}
                >
                  <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
                  <div className="item-treatment-code">{treatmentCode}</div>
                  <div className="item-tooth">{item.tooth && item.tooth.name}</div>
                  <div className="item-session">{item.treatmentSession || 0}</div>
                  <div
                    className="item-description"
                    style={{ minWidth: 100 }}
                  >{`Note: ${subitem.comment}`}</div>
                  {showFee && (
                    <div className="item-fee">
                      {item?.discount?.code === "AMOUNT"
                        ? item.price - toFinite(item?.discount?.value)
                        : item.price - (item.price * toFinite(item?.discount?.value)) / 100}
                    </div>
                  )}
                  <div className="item-approval">
                    {(item.primaryPayerShare && parseFloat(item.primaryPayerShare).toFixed(2)) ||
                      "0.00"}
                  </div>
                  <div className="item-approval">
                    {(item.secondaryPayerShare &&
                      parseFloat(item.secondaryPayerShare).toFixed(2)) ||
                      "0.00"}
                  </div>
                  <div className="item-approval">
                    {(item.patientShare && parseFloat(item.patientShare).toFixed(2)) || "0.00"}
                  </div>
                  <div className="item-principal-diagnosis">&nbsp;</div>
                  <div className="item-approval">&nbsp;</div>
                  <div className="item-expiry-date">&nbsp;</div>
                  <div className="item-approval">&nbsp;</div>
                  <div className="item-posted-by">
                    {item.createdDentist && item.createdDentist.name}
                  </div>
                  <div className="item-checkbox">
                    <input
                      className="scaled-2"
                      type="checkbox"
                      disabled={selectTreatmentToCompleteListTotalCount === 0}
                      checked={selectTreatmentToCompleteSelectedItems[subitem.key]}
                      readOnly={true}
                    />
                  </div>
                </div>,
              );
            } else {
              let treatmentCodeNumber = _.get(subitem, "number", "");
              let treatmentCode =
                _.get(item, "treatmentItem.code", "") + " (v." + treatmentCodeNumber + ")";

              list.push(
                <div className="select-treatment-list-row" key={index + ":" + subindex}>
                  <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
                  <div className="item-treatment-code">{treatmentCode}</div>
                  <div className="item-tooth">{item.tooth && item.tooth.name}</div>
                  <div className="item-session">{item.treatmentSession || 0}</div>
                  <div className="item-description" style={{ minWidth: 100 }}>
                    &nbsp;
                  </div>
                  {showFee && (
                    <div className="item-fee">
                      {item?.discount?.code === "AMOUNT"
                        ? item.price - toFinite(item?.discount?.value)
                        : item.price - (item.price * toFinite(item?.discount?.value)) / 100}
                    </div>
                  )}
                  <div className="item-approval">
                    {(item.primaryPayerShare && parseFloat(item.primaryPayerShare).toFixed(2)) ||
                      "0.00"}
                  </div>
                  <div className="item-approval">
                    {(item.secondaryPayerShare &&
                      parseFloat(item.secondaryPayerShare).toFixed(2)) ||
                      "0.00"}
                  </div>
                  <div className="item-approval">
                    {(item.patientShare && parseFloat(item.patientShare).toFixed(2)) || "0.00"}
                  </div>
                  <div className="item-principal-diagnosis">&nbsp;</div>
                  <div className="item-approval">&nbsp;</div>
                  <div className="item-expiry-date">&nbsp;</div>
                  <div className="item-approval">&nbsp;</div>
                  <div className="item-posted-by">
                    {item.createdDentist && item.createdDentist.name}
                  </div>
                  <div className="item-checkbox" />
                </div>,
              );
            }
          });
        } else {
          list.push(
            <div className="select-treatment-list-row" key={index}>
              <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
              <div className="item-treatment-code">
                {item.treatmentItem && item.treatmentItem.code}
              </div>
              <div className="item-tooth">{item.tooth && item.tooth.name}</div>
              <div className="item-session">{item.treatmentSession || 0}</div>
              <div className="item-description" style={{ minWidth: 100 }}>
                {item.treatmentItem && item.treatmentItem.description}
              </div>
              {showFee && (
                <div className="item-fee">
                  {item?.discount?.code === "AMOUNT"
                    ? item.price - toFinite(item?.discount?.value)
                    : item.price - (item.price * toFinite(item?.discount?.value)) / 100}
                </div>
              )}
              <div className="item-approval">
                {(item.primaryPayerShare && parseFloat(item.primaryPayerShare).toFixed(2)) ||
                  "0.00"}
              </div>
              <div className="item-approval">
                {(item.secondaryPayerShare && parseFloat(item.secondaryPayerShare).toFixed(2)) ||
                  "0.00"}
              </div>
              <div className="item-approval">
                {(item.patientShare && parseFloat(item.patientShare).toFixed(2)) || "0.00"}
              </div>
              <div className="item-principal-diagnosis">
                {item.diagnosisItem && item.diagnosisItem.code}
              </div>
              <div className="item-approval">
                {item.approvalStatus && item.approvalStatus.name
                  ? item.approvalStatus.name
                  : item.holdPreApproval
                  ? "Hold"
                  : ""}
              </div>
              <div className="item-expiry-date" style={{ color: "#FF0000" }}>
                {item.priorApprovalExpireDate
                  ? Utils.parseDateFormat(
                      Utils.calcDateWithTimeZone(item.priorApprovalExpireDate),
                      DATE_FORMAT_LEGACY,
                    )
                  : ""}
              </div>
              <div className="item-approval">
                {item.approval2Status && item.approval2Status.name
                  ? item.approval2Status.name
                  : item.holdPreApproval
                  ? "Hold"
                  : ""}
              </div>
              <div className="item-posted-by">
                {item.createdDentist && item.createdDentist.name}
              </div>
              <div className="item-checkbox">
                <input
                  className="scaled-2"
                  type="checkbox"
                  disabled={selectTreatmentToCompleteListTotalCount === 0}
                  onChange={() => this.toggleSelectedItem(item.key)}
                  checked={selectTreatmentToCompleteSelectedItems[item.key]}
                />
              </div>
            </div>,
          );
        }
      });

      return list;
    };

    UNSAFE_componentWillMount() {
      const { chart } = this.props;
      this.props.chartActions.selectTreatmentToCompleteGetList(chart.transactionsToCompleteFilter);
    }

    toggleSelectedItem = (transactionKey) => {
      this.props.chartActions.selectTreatmentToCompleteToggleSelectedItem(transactionKey);
    };

    toggleSelectedItems = (transactionKeys) => {
      this.props.chartActions.selectTreatmentToCompleteToggleSelectedItems(transactionKeys);
    };

    componentDidUpdate(prevProps) {
      const { chart } = this.props;

      if (chart.transactionsToCompleteFilter !== prevProps.chart.transactionsToCompleteFilter) {
        this.props.chartActions.selectTreatmentToCompleteGetList(
          chart.transactionsToCompleteFilter,
        );
      }

      if (
        chart.selectTreatmentToCompleteList.length &&
        chart.selectTreatmentToCompleteList !== prevProps.chart.selectTreatmentToCompleteList
      ) {
        const listBody = document.getElementById("select-treatment-list-table");
        const listBodyWidth = listBody.scrollWidth;
        if (listBody.scrollLeft !== listBodyWidth) {
          listBody.scrollTo(listBodyWidth, 0);
        }
      }
    }

    render() {
      const {
        clinicId,
        session,
        chart: {
          selectTreatmentToCompleteList,
          selectTreatmentToCompleteListTotalCount,
          selectTreatmentToCompleteSelectedItems,
          selectTreatmentToCompleteListFetching,
        },
      } = this.props;

      const showFee = session.permissions.indexOf("VIEW_DENTAL_TRANSACTION_ITEM_FEE") >= 0;
      const withGroupping = localStorage.getItem("treatmentGroupping");

      const filtredList = selectTreatmentToCompleteList.filter((x) => {
        const id = _.get(x, "clinic.id");

        return id === clinicId || clinicId === "all";
      });

      const checkedAll =
        _.filter(selectTreatmentToCompleteSelectedItems, (item) => item).length ===
        filtredList.length;
      let list = [];

      const treatmentList =
        withGroupping === treatmentGroupping.byProcedure
          ? filtredList.reduce((groups, item) => {
              const procedureItem = _.get(item, "procedureItem");
              const procedureDate = _.get(item, "procedureKey");
              const createdDate = _.get(item, "createdDate");
              const procedureItemTooth = _.get(item, "tooth.name");
              const price = _.get(item, "price");
              const discount = _.get(item, "discount.value");

              if (procedureItem) {
                const current = _.get(groups, `item-${procedureDate}`, {
                  list: [],
                  price: 0,
                });
                const list = _.get(current, "list", []);

                list.push(item);

                groups[`item-${procedureDate}`] = {
                  ...current,
                  procedure: procedureItem,
                  createdDate,
                  type: "procedure",
                  list,
                  tooth: procedureItemTooth,
                  price: current.price + _.toFinite(price) - toFinite(discount),
                };
              } else {
                const current = _.get(groups, "none", { list: [], price: 0 });
                const list = _.get(current, "list", []);

                list.push(item);

                groups.none = {
                  ...current,
                  list,
                  type: "procedure",
                  tooth: procedureItemTooth,
                  price: current.price + _.toFinite(price) - toFinite(discount),
                };
              }

              return groups;
            }, {})
          : withGroupping === treatmentGroupping.byTreatmentCode
          ? filtredList.reduce((groups, item) => {
              const treatmentItem = _.get(item, "treatmentItem");
              const createdDate = _.get(item, "createdDate");
              const treatmentItemTooth = _.get(item, "tooth.name");
              const treatmentDescription = _.get(treatmentItem, "description");
              const price = _.get(item, "price");
              const discount = _.get(item, "discount.value");

              if (treatmentItem) {
                const treatmentCode = _.get(treatmentItem, "code");
                const current = _.get(groups, `item-${treatmentCode}`, {
                  list: [],
                  price: 0,
                });
                const list = _.get(current, "list", []);

                list.push(item);

                groups[`item-${treatmentCode}`] = {
                  ...current,
                  procedure: treatmentItem,
                  createdDate,
                  list,
                  description: treatmentDescription,
                  type: "treatmentCode",
                  tooth: treatmentItemTooth,
                  price: current.price + _.toFinite(price) - toFinite(discount),
                };
              } else {
                const current = _.get(groups, "none", { list: [], price: 0 });
                const list = _.get(current, "list", []);

                list.push(item);

                groups.none = {
                  ...current,
                  list,
                  description: treatmentDescription,
                  type: "treatmentCode",
                  tooth: treatmentItemTooth,
                  price: current.price + _.toFinite(price) - toFinite(discount),
                };
              }

              return groups;
            }, {})
          : filtredList;
      if (withGroupping !== treatmentGroupping.none) {
        _.forEach(treatmentList, (item, key) => {
          const procedureList = _.get(item, "list");
          const createdDate = _.get(item, "createdDate");
          const procedure = _.get(item, "procedure");
          const description = _.get(item, "description");
          const price = _.get(item, "price");
          const tooth = _.get(item, "tooth");
          const type = _.get(item, "type");
          const groupKeys = [];
          const selectedGroup = (procedureList || []).reduce((acc, item) => {
            groupKeys.push(item.key);

            return acc && selectTreatmentToCompleteSelectedItems[item.key];
          }, true);

          list.push(
            <div className="select-treatment-list-group-row">
              <div
                className="select-treatment-list-row group-title"
                onClick={() =>
                  this.setState((state) => ({
                    openedGroups: {
                      ...state.openedGroups,
                      [key]: !state.openedGroups[key],
                    },
                  }))
                }
              >
                <div className="item-date-posted">{getCompanyFormatDate(createdDate)}</div>
                <div className="item-treatment-code">
                  {`${
                    type === "treatmentCode"
                      ? procedure
                        ? procedure.name
                        : "Without TreatmentCode"
                      : ""
                  } (${_.size(procedureList)})`}
                </div>
                <div className="item-tooth">{tooth}</div>
                <div className="item-session" />
                <div className="item-description" style={{ minWidth: 100 }}>
                  {type === "procedure"
                    ? procedure
                      ? procedure.name
                      : "Non Procedure"
                    : type === "treatmentCode"
                    ? description
                    : ""}
                </div>
                <div className="item-fee">{price.toFixed(2)}</div>
                <div className="item-approval" />
                <div className="item-approval" />
                <div className="item-approval" />
                <div className="item-principal-diagnosis" />
                <div className="item-approval" />
                <div className="item-expiry-date" />
                <div className="item-approval" />
                <div className="item-posted-by" />
                <div className="item-checkbox">
                  <input
                    className="scaled-2"
                    type="checkbox"
                    disabled={selectTreatmentToCompleteListTotalCount === 0}
                    checked={selectedGroup}
                    onClick={(e) => {
                      e.stopPropagation();

                      this.toggleSelectedItems(groupKeys);
                    }}
                    readOnly={true}
                  />
                </div>
              </div>
              {this.state.openedGroups[key] && this.generateList(procedureList)}
            </div>,
          );
        });
      } else {
        list.push(this.generateList(treatmentList));
      }

      return (
        <div className="select-treatment-list-table" id="select-treatment-list-table">
          <div className="select-treatment-list-header">
            <div
              className="item-date-posted"
              onClick={() => {
                if (this.state.filter === "create_date") {
                  this.setState({ filter: "create_date_desc" });
                } else {
                  this.setState({ filter: "create_date" });
                }
              }}
            >
              Date posted
            </div>
            <div
              className="item-treatment-code"
              onClick={() => {
                if (this.state.filter === "code") {
                  this.setState({ filter: "code_desc" });
                } else {
                  this.setState({ filter: "code" });
                }
              }}
            >
              Treatment Code
            </div>
            <div
              className="item-tooth"
              onClick={() => {
                if (this.state.filter === "tooth") {
                  this.setState({ filter: "tooth_desc" });
                } else {
                  this.setState({ filter: "tooth" });
                }
              }}
            >
              Tooth
            </div>
            <div
              className="item-session"
              onClick={() => {
                if (this.state.filter === "treatment_session") {
                  this.setState({ filter: "treatment_session_desc" });
                } else {
                  this.setState({ filter: "treatment_session" });
                }
              }}
            >
              Session
            </div>
            <div className="item-description" style={{ minWidth: 100 }}>
              Description
            </div>
            {/*<div style={{ flex: "0 0 170px" }}>PriceList</div>*/}
            {showFee && (
              <div
                className="item-fee"
                onClick={() => {
                  if (this.state.filter === "price") {
                    this.setState({ filter: "price_desc" });
                  } else {
                    this.setState({ filter: "price" });
                  }
                }}
              >
                Fee
              </div>
            )}
            <div className="item-approval">Payer Share 1</div>
            <div className="item-approval">Payer Share 2</div>
            <div className="item-approval">Patient Share</div>
            <div className="item-principal-diagnosis">Principal diagnosis</div>
            <div className="item-approval">Approval.1</div>
            <div className="item-expiry-date">Expire Date.1</div>
            <div className="item-approval">Approval.2</div>
            <div
              className="item-posted-by"
              onClick={() => {
                if (this.state.filter === "creator") {
                  this.setState({ filter: "creator_desc" });
                } else {
                  this.setState({ filter: "creator" });
                }
              }}
            >
              Posted by
            </div>
            <div className="item-checkbox" style={{ marginRight: "5px" }}>
              <input
                className="scaled-2"
                type="checkbox"
                disabled={selectTreatmentToCompleteListTotalCount === 0}
                checked={checkedAll}
                onChange={() =>
                  this.props.chartActions.selectTreatmentToCompleteToggleSelectAll(clinicId)
                }
              />
            </div>
          </div>
          <div className="select-treatment-list-body">
            {list}
            <Show if={selectTreatmentToCompleteListFetching}>
              <div className="select-treatment-list-row">
                <ReactSpinner />
              </div>
            </Show>
          </div>
        </div>
      );
    }
  },
);
