import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

import bindActions from "../../../../helpers/bindActions";
import * as chartActions from "../../../../actions/chartActions";
import { createUrl } from "../../../../utils/UrlUtils";
import { Routes } from "../../../../constants/Routes";
import { withRouter } from "react-router";

const enhancer = connect(null, bindActions({ chartActions }));
const form = reduxForm({
  form: "selectedTreatmentToComplete",
});
class SelectTreatmentToCompleteNoteModal extends React.Component {
  render() {
    const {
      chart: { selectTreatmentToCompleteNoteModalVisible },
      isPlanning,
    } = this.props;

    return (
      <Modal
        size="sm"
        keyboard={false}
        onHide={this.props.chartActions.selectTreatmentToCompleteNoteModalHide}
        show={selectTreatmentToCompleteNoteModalVisible}
        className="select-treatment-to-complete-note-modal"
        title={<div className="text-center">Note to Cashier</div>}
        actions={
          <div className="m-3">
            <Button
              onClick={() => {
                this.props.chartActions
                  .selectTreatmentToCompleteSetComplete(false, isPlanning || null)
                  .then(() => {
                    this.props.history.push(
                      createUrl(Routes.DashboardCalendar, {
                        query: {
                          saveSettings: true,
                        },
                      }),
                    );
                  });
              }}
              type="button"
              className="btn btn-default"
            >
              Skip
            </Button>
            <Button
              onClick={() => {
                this.props.chartActions
                  .selectTreatmentToCompleteSetComplete(true, isPlanning || null)
                  .then(() => {
                    this.props.history.push(
                      createUrl(Routes.DashboardCalendar, {
                        query: {
                          saveSettings: true,
                        },
                      }),
                    );
                  });
              }}
              type="button"
              className="btn btn-default"
            >
              Done
            </Button>
            <Button
              onClick={() => {
                this.props.chartActions.selectTreatmentToCompleteNoteModalHide();
              }}
              type="button"
              className="btn btn-default"
            >
              Cancel
            </Button>
          </div>
        }
      >
        <Field component="textarea" className="select-treatment-textarea" name="noteArea" />
      </Modal>
    );
  }
}
export default form(enhancer(withRouter(SelectTreatmentToCompleteNoteModal)));
