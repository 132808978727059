import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import React, { useCallback, useState } from "react";

import { Button } from "../ui/Button";
import CustomPopover from "./CustomPopover";
import { TextField } from "../form/TextField";
import { PhoneNumberField } from "../form/PhoneNumberField";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import FlexBox, { FlexBoxAlign, FlexBoxDirection } from "../ui/FlexBox";
import {
  searchFirstContactPatientListApi,
  searchPatient,
  additionalChargesFieldToggle,
} from "../../actions/appointmentActions";
import { update } from "immupdate";
import Utils from "../../helpers/Utils";
import { FormControl } from "react-bootstrap";

enum FocusedType {
  Phone = "phone",
  LastName = "last-name",
  FirstName = "first-name",
  EmiratesId = "emirates-id",
  ChartNumber = "chart-number",
  PassportNumber = "passport-number",
  Email = "email",
  InsuranceNumber = "insurance-number",
}

interface Props {
  readonly initialValues: any;
  readonly showAddForm: () => void;
  readonly fromFirstContact: boolean;
  readonly onShowNewScreen: () => void;
  readonly choosePatient: (item: any) => void;
  readonly updatePatientFormData: (values: any) => void;
}

export function SearchPatientForm({
  showAddForm,
  initialValues,
  choosePatient,
  onShowNewScreen,
  fromFirstContact,
  updatePatientFormData,
}: Props) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [focused, setFocused] = useState<FocusedType | undefined>();

  const searchPatientHandler = useCallback(
    (values) => {
      dispatch({ type: "searchPatients" });

      dispatch(startLoader());

      dispatch(searchPatient(update({ isForAppointment: true }, values)))
        .then((res) => {
          setList(res.list || []);
          setShowPopover(true);
          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    },
    [dispatch],
  );

  const searchFirstContactHandler = useCallback(
    (values) => {
      dispatch({ type: "searchPatients" });

      dispatch(startLoader());

      dispatch(searchFirstContactPatientListApi(update({ isForAppointment: true }, values)))
        .then((res = []) => {
          setList(res);
          setShowPopover(true);
          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    },
    [dispatch],
  );

  const submitHandler = useCallback(
    (values) => {
      setShowPopover(false);

      if (focused === FocusedType.ChartNumber) {
        searchPatientHandler({ patientChartNumber: values.chartNumber });
      } else if (focused === FocusedType.Phone) {
        const phoneNumber = `+${values?.phoneNumber.countryCode.value}(${values?.phoneNumber.townCode})${values?.phoneNumber.number}`;

        searchFirstContactHandler({ phoneNumber });
      } else if (focused === FocusedType.FirstName) {
        searchFirstContactHandler({ firstName: values.firstName });
      } else if (focused === FocusedType.LastName) {
        searchFirstContactHandler({ lastName: values.lastName });
      } else if (focused === FocusedType.Email) {
        searchFirstContactHandler({ email: values.email });
      } else if (focused === FocusedType.EmiratesId) {
        searchPatientHandler({ documentNationalIdNumber: values.emiratesId });
      } else if (focused === FocusedType.PassportNumber) {
        searchPatientHandler({ documentPasswordNumber: values.documentPasswordNumber });
      } else if (focused === FocusedType.InsuranceNumber) {
        searchPatientHandler({ patientCardNumber: values.patientCardNumber });
      }
    },
    [focused, searchFirstContactHandler, searchPatientHandler],
  );

  const addPatientHandler = useCallback(() => {
    if (!onShowNewScreen) {
      showAddForm();
    } else {
      onShowNewScreen();
    }
    dispatch(additionalChargesFieldToggle());
    setShowPopover(false);
  }, [onShowNewScreen, showAddForm]);

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      {({ handleSubmit, values, setFieldValue }) => {
        const phoneNumber = `+${values?.phoneNumber.countryCode.value}(${values?.phoneNumber.townCode})${values?.phoneNumber.number}`;

        return (
          <>
            <FlexBox
              containerElement={
                <Form
                  onBlur={() =>
                    updatePatientFormData({
                      phoneEnd: values?.phoneNumber.countryCode.value,
                      phoneMiddle: values?.phoneNumber.townCode,
                      mobilePhoneNumber: phoneNumber,
                      patientChartNumber: values?.chartNumber,
                      firstName: values?.firstName,
                      lastName: values?.lastName,
                      documentNationalIdNumber: values?.emiratesId,
                      email: values?.email,
                    })
                  }
                  onChange={() =>
                    updatePatientFormData({
                      phoneEnd: values?.phoneNumber.countryCode.value,
                      phoneMiddle: values?.phoneNumber.townCode,
                      mobilePhoneNumber: phoneNumber,
                      patientChartNumber: values?.chartNumber,
                      firstName: values?.firstName,
                      lastName: values?.lastName,
                      documentNationalIdNumber: values?.emiratesId,
                      email: values?.email,
                    })
                  }
                  onKeyPress={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                />
              }
              direction={FlexBoxDirection.Column}
            >
              <div className="d-flex flex-column mb-2">
                <PhoneNumberField
                  size="lg"
                  name="phoneNumber"
                  label="Phone number"
                  onFocus={() => setFocused(FocusedType.Phone)}
                />
              </div>

              {!fromFirstContact && (
                <>
                  <div className="d-flex flex-column mb-2">
                    <TextField
                      size="lg"
                      name="chartNumber"
                      label="Chart number"
                      onFocus={() => setFocused(FocusedType.ChartNumber)}
                    />
                  </div>
                </>
              )}

              <FlexBox gutter={8} className="mb-1" style={{ zIndex: 8 }}>
                <FlexBox flex={true}>
                  <TextField
                    size="lg"
                    name="firstName"
                    label="First name"
                    onFocus={() => setFocused(FocusedType.FirstName)}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <TextField
                    size="lg"
                    name="lastName"
                    label="Last name"
                    onFocus={() => setFocused(FocusedType.LastName)}
                  />
                </FlexBox>
              </FlexBox>
              <div className="d-flex flex-column mb-2">
                <TextField
                  size="lg"
                  name="email"
                  label="Email"
                  onFocus={() => setFocused(FocusedType.Email)}
                />
              </div>
              <div className="d-flex flex-column mb-2">
                <label htmlFor="emiratesId" className="form-label">
                  National ID
                </label>
                <FormControl
                  autoComplete="off"
                  name="emiratesId"
                  id="emiratesId"
                  className="form-control input-lg"
                  style={{ width: "100%" }}
                  value={values.emiratesId}
                  onChange={({ target: { value } }) =>
                    setFieldValue("emiratesId", Utils.nationalIdNormalize(value))
                  }
                  onFocus={() => setFocused(FocusedType.EmiratesId)}
                />
              </div>
              <div className="d-flex flex-column mb-2">
                <TextField
                  size="lg"
                  name="documentPasswordNumber"
                  label="Passport number"
                  onFocus={() => setFocused(FocusedType.PassportNumber)}
                />
              </div>
              <div className="d-flex flex-column mb-2">
                <TextField
                  size="lg"
                  name="patientCardNumber"
                  label="Insurance number (primary and secondary)"
                  onFocus={() => setFocused(FocusedType.InsuranceNumber)}
                />
              </div>

              {!fromFirstContact && (
                <>
                  <FlexBox gutter={8} direction={FlexBoxDirection.Column} align={FlexBoxAlign.End}>
                    <FlexBox direction={FlexBoxDirection.Column}>
                      <Button onClick={() => handleSubmit()} type="button" size="lg">
                        Search
                      </Button>
                    </FlexBox>
                  </FlexBox>
                </>
              )}
            </FlexBox>

            {!fromFirstContact && (
              <CustomPopover
                patientList={list}
                show={showPopover}
                choosePatient={choosePatient}
                onShowNewScreen={onShowNewScreen}
                onAddPatientClick={addPatientHandler}
                onHideClick={() => setShowPopover(false)}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}
