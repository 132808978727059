import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import ChartListItem from "../ChartListItem";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionCategory extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
      chartSidePrescription: PropTypes.object,
    };

    openItem = (item) => {
      this.props.chartSidePrescriptionActions.openTemplates(item.id);
    };

    render() {
      const { categoryList } = this.props.chartSidePrescription;

      const sortCategory = _.sortBy(categoryList, (item) => item.name.toLowerCase());

      const $list = sortCategory.map((item, index) => (
        <ChartListItem key={index} item={item} openItem={() => this.openItem(item)} />
      ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader onBackClick={this.props.chartSidePrescriptionActions.popStack} />
          <div className="chart-side-body">
            <div className="body">
              <div className="chart-clinical-note-category-title">Select Category</div>
              {$list}
            </div>
            <div className="all-codes">
              <button
                onClick={this.props.chartSidePrescriptionActions.openEmpty}
                className="btn btn-primary btn-block"
              >
                Write New
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
