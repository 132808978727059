import React from "react";

import { SvgIcon } from "../../widgets/SvgIcon";

export function TopRowBackgroundIcon({ width, height }) {
  return (
    <SvgIcon width={width ? width : 1280} height={height ? height : 150}>
      <line x1="0" y1="5" x2="1280" y2="5" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="10" x2="1280" y2="10" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="15" x2="1280" y2="15" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="20" x2="1280" y2="20" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="25" x2="1280" y2="25" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="30" x2="1280" y2="30" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="35" x2="1280" y2="35" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="40" x2="1280" y2="40" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="45" x2="1280" y2="45" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="50" x2="1280" y2="50" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="55" x2="1280" y2="55" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="60" x2="1280" y2="60" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="65" x2="1280" y2="65" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="70" x2="1280" y2="70" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="75" x2="1280" y2="75" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="80" x2="1280" y2="80" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="85" x2="1280" y2="85" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="90" x2="1280" y2="90" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line x1="0" y1="95" x2="1280" y2="95" stroke="#0000ff" strokeWidth="2" strokeOpacity="0.5" />
      <line
        x1="0"
        y1="100"
        x2="1280"
        y2="100"
        stroke="#0000ff"
        strokeWidth="2"
        strokeOpacity="0.5"
      />
    </SvgIcon>
  );
}
