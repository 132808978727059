import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { arrayMove, SortableContainer } from "react-sortable-hoc";

import FlexBox from "../ui/FlexBox";
import DataList from "../widgets/DataList";
import { ViewTypes } from "./ViewButtonGroup";
import { SidebarTypes } from "./TypeButtonGroup";
import ChartSideFavouritesGrid from "../chart/side-bar/ChartSideFavouritesGrid";
import ChartSideFavouritesList from "../chart/side-bar/ChartSideFavouritesList";

const enhancer = connect(({ session: { treatmentDescriptionType } }) => ({
  treatmentDescriptionType,
}));

export const SidebarFavoritesList = enhancer(
  class SidebarFavoritesList extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedItems: [],
      };
    }

    switchSelectedItems(id) {
      const { selectedItems } = this.state;

      const itemIndex = selectedItems.findIndex((x) => x === id);

      if (itemIndex >= 0) {
        selectedItems.splice(itemIndex, 1);
      } else {
        selectedItems.push(id);
      }

      this.setState({ selectedItems });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
      const { chartSideSeries, typeTab, favoritesList, onSort } = this.props;
      const { searchValue = "" } = chartSideSeries;

      if (typeTab === SidebarTypes.Mixed) {
        return;
      }

      if (searchValue) {
        return;
      }

      const favourites = JSON.parse(localStorage.getItem("favourites"));

      const list = (favoritesList || [])
        .slice()
        .filter((item) => item.code.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);

      const newArray = arrayMove(list, oldIndex, newIndex);

      newArray.forEach((item, index) => {
        favourites[typeTab][item.code].index = index;
      });

      localStorage.setItem("favourites", JSON.stringify(favourites));

      onSort(newArray);
    };

    componentDidMount() {
      const { getFavorites } = this.props;

      if (getFavorites) {
        getFavorites();
      }
    }

    render() {
      const {
        viewTab,
        typeTab,
        openItem,
        isMultiSelect,
        favoritesList,
        chartSideSeries,
        toggleFavourite,
        showImageSelector,
        treatmentDescriptionType,
      } = this.props;
      const { selectedItems } = this.state;
      const { searchValue = "", favouritesIcons, fetchingFavorites } = chartSideSeries;

      const favourites = JSON.parse(localStorage.getItem("favourites"));

      const favorites = !fetchingFavorites
        ? (favoritesList || [])
            .slice()
            .filter((item) => {
              let active =
                favourites[typeTab][item.code] && favourites[typeTab][item.code]?.selected;
              const searched = item.code.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
              const searched2 =
                item.description.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;

              if (typeTab === SidebarTypes.Mixed && item.$seriesType) {
                active =
                  favourites[item.$seriesType][item.code] &&
                  favourites[item.$seriesType][item.code]?.selected;
              }

              return active && searched ? searched : active && searched2 ? searched2 : false;
            })
            .sort((item1, item2) => {
              let endSeriesType1 = typeTab === SidebarTypes.Mixed ? item1.$seriesType : typeTab;
              let endSeriesType2 = typeTab === SidebarTypes.Mixed ? item2.$seriesType : typeTab;
              const item1index = +favourites[endSeriesType1][item1.code]?.index;
              const item2index = +favourites[endSeriesType2][item2.code]?.index;

              if (isNaN(item1index) && isNaN(item2index)) return 0;
              if (isNaN(item1index)) return 1;
              if (isNaN(item2index)) return -1;

              return item1index - item2index;
            })
        : [];

      const list = favorites.map((item, index) => {
        if (viewTab === ViewTypes.Grid) {
          return (
            <ChartSideFavouritesGrid
              key={index}
              item={item}
              index={index}
              seriesType={typeTab}
              favourites={favourites}
              isMultiSelect={isMultiSelect}
              favouritesIcons={favouritesIcons}
              showImageSelector={showImageSelector}
              isSelectItem={selectedItems.indexOf(item.id) >= 0}
              openItem={() => (isMultiSelect ? this.switchSelectedItems(item.id) : openItem(item))}
            />
          );
        }

        if (viewTab === ViewTypes.List) {
          return (
            <ChartSideFavouritesList
              key={index}
              item={item}
              index={index}
              isMultiSelect={isMultiSelect}
              toggleFavourite={toggleFavourite}
              isSelectItem={selectedItems.indexOf(item.id) >= 0}
              treatmentDescriptionType={treatmentDescriptionType}
              openItem={() => (isMultiSelect ? this.switchSelectedItems(item.id) : openItem(item))}
            />
          );
        }
      });

      const listClassName = "list-group" + (viewTab === ViewTypes.Grid ? " list-group-grid" : "");

      let categoriesBtnStyle = {
        width: "49%",
      };
      let allCodesBtnStyle = {
        width: "49%",
      };

      if (typeTab !== SidebarTypes.Treatments && typeTab !== SidebarTypes.Existing) {
        categoriesBtnStyle.display = "none";
        allCodesBtnStyle.width = "100%";
      }

      if (!fetchingFavorites && _.isEmpty(favorites)) {
        return (
          <div style={{ display: "flex", flexDirection: "column", flex: "1 1 0%" }}>
            <span className="text-center" style={{ marginTop: "12px" }}>
              You don`t have any favorites, please click on All Codes
            </span>
          </div>
        );
      }

      const SortableList = SortableContainer(() => {
        return (
          <DataList fetching={fetchingFavorites} className={listClassName}>
            {list}
          </DataList>
        );
      });

      return (
        <FlexBox flex={true} direction="column">
          <SortableList
            useDragHandle={true}
            hideSortableGhost={false}
            onSortEnd={this.onSortEnd}
            axis={viewTab === ViewTypes.Grid ? "xy" : "y"}
          />

          {isMultiSelect && (
            <button
              type="button"
              style={{
                width: "90%",
                height: "40px",
                margin: "12px",
                alignSelf: "center",
              }}
              disabled={selectedItems.length === 0}
              className="btn btn-success"
              onClick={() =>
                openItem((favorites || []).filter(({ id }) => selectedItems.indexOf(id) >= 0))
              }
            >
              Add Selected
            </button>
          )}
        </FlexBox>
      );
    }
  },
);
