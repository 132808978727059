import React from "react";
import cx from "classnames";
import { capitalize } from "lodash";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "../form/TextField";
import { SelectField } from "../form/SelectField";
import { WeeklyOptions } from "./BlockedTimeSlotWeeklyForm";
import { RadioField } from "../form/RadioField";

const useStyles = makeStyles({
  everyOf: { width: "10%" },
});

export enum MonthlyTypes {
  Day = "day",
  The = "the",
}

export enum WeekNumberTypes {
  None = "-- --",
  Last = "LAST",
  First = "FIRST",
  Third = "THIRD",
  Second = "SECOND",
  Fourth = "FOURTH",
}

export const WeekNumberOptions = [
  {
    value: undefined,
    label: WeekNumberTypes.None,
  },
  {
    value: WeekNumberTypes.First,
    label: capitalize(WeekNumberTypes.First),
  },
  {
    value: WeekNumberTypes.Second,
    label: capitalize(WeekNumberTypes.Second),
  },
  {
    value: WeekNumberTypes.Third,
    label: capitalize(WeekNumberTypes.Third),
  },
  {
    value: WeekNumberTypes.Fourth,
    label: capitalize(WeekNumberTypes.Fourth),
  },
  {
    value: WeekNumberTypes.Last,
    label: capitalize(WeekNumberTypes.Last),
  },
];

export function BlockedTimeSlotMonthlyForm() {
  const classes = useStyles();

  const { values } = useFormikContext<any>();

  const isTypeDay = values.monthlyType === MonthlyTypes.Day;
  const isTypeThe = values.monthlyType === MonthlyTypes.The;

  return (
    <div className="d-flex flex-column">
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">
          <RadioField name="monthlyType" radioValue={MonthlyTypes.Day} label="Day:" />
        </div>
        <div className="col-sm-3">
          <TextField type="number" name="monthDayNumber" disabled={!isTypeDay} />
        </div>
        <div className="col-sm-2 text-center">of every</div>
        <div className="col-sm-2">
          <TextField type="number" name="repeatCount" disabled={!isTypeDay} />
        </div>
        <div className="col-sm-4">Month(s)</div>
      </div>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">
          <RadioField name="monthlyType" radioValue={MonthlyTypes.The} label="The:" />
        </div>
        <div className="col-sm-3">
          <SelectField
            placeholder="-- --"
            name="yearWeekNumber"
            isDisabled={!isTypeThe}
            options={WeekNumberOptions}
          />
        </div>
        <div className="col-sm-3">
          <SelectField
            name="yearWeekDay"
            placeholder="-- --"
            options={WeeklyOptions}
            isDisabled={!isTypeThe}
          />
        </div>
        <div className={cx("col-sm-2 text-center", classes.everyOf)}>of every</div>
        <div className="col-sm-2">
          <TextField type="number" name="repeatCount" disabled={!isTypeThe} />
        </div>
        <div className="col-sm-1">Month(s)</div>
      </div>
    </div>
  );
}
