import { get } from "lodash";
import React, { useMemo } from "react";

import { PhoneNumberInput, PhoneNumberInputProps } from "./PhoneNumberInput";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { clinicSelector, companySelector } from "../../reducers/sessionReducer";

export type PhoneNumberInputWrapperProps = Omit<PhoneNumberInputProps, "companyCountryName">;

export function PhoneNumberWrapper({ townCodeProps, ...props }: PhoneNumberInputWrapperProps) {
  // @ts-ignore
  const clinic = useShallowEqualSelector(clinicSelector);
  // @ts-ignore
  const company = useShallowEqualSelector(companySelector);

  const disabledTown = useMemo(() => company?.id === 181 || company?.id === 301, [company?.id]);
  const companyName = useMemo(() => get(clinic, "address.country.name", "United Arab Emirates"), [
    clinic,
  ]);

  return (
    <PhoneNumberInput
      {...props}
      companyCountryName={companyName}
      townCodeProps={{
        disabled: disabledTown,
        ...townCodeProps,
      }}
    />
  );
}
