import _ from "lodash";
import React from "react";
import Show from "../widgets/Show";
import FilledImage from "../widgets/FilledImage";
import moment from "moment";
import ChartTransactionChooseDeleteModal from "../chart/side-bar/transaction/ChartTransactionChooseDeleteModal";
import ChartTransactionConfirmDeleteModal from "../chart/side-bar/transaction/ChartTransactionConfirmDeleteModal";
import ChartTransactionAccessDeleteModal from "../chart/side-bar/transaction/ChartTransactionAccessDeleteModal";
import editImage from "../../assets/images/diagnosis_edit-icon.png";
import saveImage from "../../assets/images/appointment/save.png";
import { DatePicker } from "../ui/DatePicker";

export class SidebarTransactionView extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      timerId: 0,
      discountType: "",
      discountValue: "",
    };
  }

  noteChanged = (e) => {
    const { chartSideTransaction, updateNote } = this.props;

    if (this.state.timerId) {
      clearTimeout(this.state.timerId);
    }

    const value = e.target.value;
    const key = chartSideTransaction.transactionItem.key;
    const timerId = setTimeout(() => {
      updateNote(key, value);
    }, 500);

    this.setState({ timerId });
  };

  componentDidMount() {
    const discountType = _.get(
      this.props,
      "chartSideTransaction.transactionItem.discount.code",
      "",
    );
    const discountValue = _.get(
      this.props,
      "chartSideTransaction.transactionItem.discount.value",
      "0",
    );

    this.setState({
      discountType: discountType,
      discountValue: discountValue,
    });
  }

  updateDiscount = () => {
    const { discountType, discountValue } = this.state;
    const { chartSideTransaction, updateTransactionDiscount } = this.props;

    const { transactionItem } = chartSideTransaction;

    updateTransactionDiscount({
      keys: [transactionItem.key],
      discount: {
        code: discountType,
        value: discountValue,
      },
    });
  };

  render() {
    const {
      setTreatmentPlan,
      setTreatmentSession,
      changeCompletedDate,
      chartSideTransaction,
    } = this.props;

    const { transactionItem, transactionType, accessDeleteModalVisible } = chartSideTransaction;

    const $changeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));

    const seriesItem = transactionType.diagnosis
      ? transactionItem.diagnosisItem
      : transactionItem.treatmentItem;

    return (
      <div className="chart-clinical-note">
        <div className="chart-side-body chart-transaction-view-body">
          <div className="posted" style={{ flexShrink: 0 }}>
            Posted by {transactionItem.createdDentist && transactionItem.createdDentist.name}
          </div>

          <Show if={!transactionType.completedDate && !transactionType.hideData}>
            <div className="completed-date" style={{ flexShrink: 0 }}>
              Created date:
              <br />
              {moment(transactionItem.createdDate).format("DD/MM/YYYY")}
            </div>
          </Show>
          <Show if={transactionType.completedDate && !transactionType.hideData}>
            <div className="completed-date" style={{ flexShrink: 0 }}>
              <label className="pull-right">
                <FilledImage src={editImage} color="#bec844" />
                <DatePicker
                  id="created-date"
                  popoverAttachment="bottom right"
                  selected={moment(transactionItem.completedDate)}
                  onChange={changeCompletedDate}
                />
              </label>
              Completed date:
              <br />
              {moment(transactionItem.completedDate).format("DD/MM/YYYY")}
            </div>
          </Show>

          <div className="description" style={{ flexShrink: 0, maxHeight: "inherit" }}>
            <strong>
              Description
              <br />
              {seriesItem && seriesItem.description}
            </strong>
          </div>

          <Show if={!transactionType.hideData}>
            <div className="fee" style={{ flexShrink: 0 }}>
              Fee
              <br />
              <strong>{transactionItem.price}</strong>
            </div>
          </Show>

          <div className="area" style={{ flexShrink: 0 }}>
            <div>{_.get(transactionItem, "area.name")}</div>
            <div>{transactionItem.areaDetails && transactionItem.areaDetails[0].name}</div>
          </div>

          <div className="note" style={{ flexShrink: 0 }}>
            <div className="note-title">Note</div>
            <div className="note-body">
              <textarea
                rows="4"
                className="form-control"
                defaultValue={transactionItem.note}
                onChange={this.noteChanged}
              />
            </div>
          </div>

          <Show if={!transactionType.hideData}>
            <div className="change" style={{ marginBottom: "8px", flexShrink: 0 }}>
              <div className="col-sm-6">
                <label htmlFor="treatmentPlan" className="control-label">
                  Discount type
                </label>
                <select
                  name="treatmentPlan"
                  id="treatmentPlan"
                  className="form-control"
                  value={this.state.discountType}
                  onChange={({ target }) => this.setState({ discountType: target.value })}
                >
                  <option value="" />
                  <option value="AMOUNT">Amount</option>
                  <option value="PERCENTAGE">Percentage</option>
                </select>
              </div>
              <div
                className="col-sm-6"
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                }}
              >
                <div style={{ padding: 0, flex: "1 1 0%" }}>
                  <label htmlFor="treatmentSession" className="control-label">
                    Value
                  </label>
                  <input
                    type="number"
                    name="treatmentSession"
                    id="treatmentSession"
                    className="form-control"
                    onChange={({ target }) => {
                      const { value } = target;
                      const { discountType } = this.state;
                      const { chartSideTransaction } = this.props;
                      const { transactionItem } = chartSideTransaction;
                      const { price } = transactionItem;

                      if (_.toNumber(value) >= 0) {
                        if (
                          (discountType === "AMOUNT" && _.toNumber(value) <= price) ||
                          (discountType === "PERCENTAGE" && _.toNumber(value) <= 100)
                        ) {
                          this.setState({ discountValue: value });
                        }
                      }
                    }}
                    value={this.state.discountValue}
                  />
                </div>
                <div style={{ padding: 0, marginLeft: "8px" }}>
                  <button
                    className="btn btn-primary"
                    title="Save discount"
                    onClick={this.updateDiscount}
                    style={{
                      padding: "4px 8px",
                    }}
                  >
                    <FilledImage color="#fff" className="zoom-2x" src={saveImage} />
                  </button>
                </div>
              </div>
            </div>
          </Show>

          <Show if={!transactionType.hideData}>
            <div className="change" style={{ flexShrink: 0, marginBottom: "12px" }}>
              <div className="col-sm-6">
                <label htmlFor="treatmentPlan" className="control-label">
                  Treatment plan
                </label>
                <select
                  name="treatmentPlan"
                  id="treatmentPlan"
                  className="form-control"
                  value={transactionItem.treatmentPlan}
                  onChange={setTreatmentPlan}
                >
                  <option value="" />
                  {$changeOptions}
                </select>
              </div>
              <div className="col-sm-6">
                <label htmlFor="treatmentSession" className="control-label">
                  Treatment Session
                </label>
                <select
                  name="treatmentSession"
                  id="treatmentSession"
                  className="form-control"
                  value={transactionItem.treatmentSession}
                  onChange={setTreatmentSession}
                >
                  <option value="" />
                  {$changeOptions}
                </select>
              </div>
            </div>
          </Show>
        </div>
        <ChartTransactionChooseDeleteModal />
        <ChartTransactionConfirmDeleteModal />
        <ChartTransactionAccessDeleteModal accessDeleteModalVisible={accessDeleteModalVisible} />
      </div>
    );
  }
}
