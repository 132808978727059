import React from "react";
import _ from "lodash";
import Show from "../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { getCompanyFormatFullDate } from "../../../helpers/DateUtils";

const AppointmentRemindersLogs = (props) => {
  const { remindersLogQuery, classes } = props;

  const generateRemindersList = () => {
    const $array = (remindersLogQuery.data || []).map((item, index) => {
      const type = _.get(item, "category.name");
      const template = _.get(item, "templateName");
      const sentTime = _.get(item, "sentTime");

      return (
        <div
          key={index}
          className="d-flex"
          style={{ border: "1px solid #e5e5e5", borderTop: "none" }}
        >
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
            {type}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
            {template}
          </span>
          <span className="p-1" style={{ flex: 1 }}>
            {getCompanyFormatFullDate(sentTime)}
          </span>
        </div>
      );
    });

    return (
      <div>
        <div className="d-flex" style={{ border: "1px solid #e5e5e5" }}>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
            Template type:{" "}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
            Template:{" "}
          </span>
          <span className="p-1" style={{ flex: 1 }}>
            Sent time:{" "}
          </span>
        </div>
        <div>{$array}</div>
      </div>
    );
  };

  return (
    <div className={classes.content}>
      {generateRemindersList()}
      <Show if={remindersLogQuery.isFetching}>
        <ReactSpinner />
      </Show>
    </div>
  );
};

export default AppointmentRemindersLogs;
