import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { FormCheck } from "react-bootstrap";
import ReactSpinner from "react-spinjs-fix";

import Show from "../widgets/Show";

import bindActions from "../../helpers/bindActions";
import * as appointmentActions from "../../actions/appointmentActions";
import { TreatmentGrouppingTypes as treatmentGroupping } from "../../constants/Constants";
import { sortTreatments } from "../../helpers/TreatmentUtils";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
const enhancer = connect(null, bindActions({ appointmentActions }));

class AppointmentRelatedModalThis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openedGroups: {},
    };
  }

  componentDidMount() {
    this.props.appointmentActions.initThis();
    const query = parseQuery(this.props.location.search);
    const { item } = this.props;

    if (item && item?.id) {
      if (query && !_.get(query, "patientKey")) {
        this.props.history.replace(
          createUrl(this.props.location.pathname, {
            query: {
              ...query,
              patientKey: _.get(item, "patient.patientKey"),
            },
          }),
        );
      }
    }
  }

  componentWillUnmount() {
    const query = parseQuery(this.props.location.search);
    const { item } = this.props;

    if (item && item?.id) {
      if (query && _.get(query, "patientKey")) {
        this.props.history.replace(
          createUrl(this.props.location.pathname, {
            query: {
              ...query,
              patientKey: undefined,
            },
          }),
        );
      }
    }
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.appointmentActions.loadThis();
    }
  };

  toggleThis = (key) => {
    return () => {
      this.props.appointmentActions.toggleThis(key);
    };
  };

  toggleThisAll = () => {
    return () => {
      this.props.appointmentActions.toggleThisAll(true);
    };
  };

  generateList = (list, { thisSelected, relatedTab, doctorId }) => {
    return list.sort(sortTreatments).map((item, index) => {
      return (
        <div
          className="this-table-row flex-shrink-0"
          key={index}
          onClick={this.toggleThis(relatedTab === "second" ? item.id : item.key)}
        >
          <div className="default-table-col col-1 d-flex align-items-center">
            <div className="middler">
              {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="default-table-col col-2 d-flex align-items-center">
            <div className="middler">{_.get(item.treatmentItem, "code")}</div>
          </div>
          <div className="default-table-col col-3 d-flex align-items-center">
            <div className="middler">{_.get(item.treatmentItem, "description")}</div>
          </div>
          <Show if={relatedTab !== "second"}>
            <div className="default-table-col col-4 d-flex align-items-center">
              <div className="middler">{_.get(item, "tooth.name")}</div>
            </div>
          </Show>
          <div className="default-table-col col-5 d-flex align-items-center">
            <div className="middler">{_.toFinite(item.price).toFixed(2)}</div>
          </div>
          <div className="default-table-col col-6 d-flex align-items-center">
            <div className="middler">{_.get(item.status, "name")}</div>
          </div>
          <Show if={relatedTab === "second"}>
            <div
              className="default-table-col col-6 d-flex align-items-center"
              style={{ flex: "0 0 280px" }}
            >
              <button
                className="btn btn-primary"
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.appointmentActions.convertRequestToPlanned(
                    [item.id],
                    "PLANNED",
                    doctorId,
                  );
                }}
              >
                Add to planned
              </button>
              <button
                style={{ marginLeft: "8px" }}
                className="btn btn-info"
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.appointmentActions.convertRequestToPlanned(
                    [item.id],
                    "COMPLETED",
                    doctorId,
                  );
                }}
              >
                Add to completed
              </button>
            </div>
          </Show>
          <div className="default-table-col col-7 d-flex align-items-center">
            <FormCheck
              checked={thisSelected[relatedTab === "second" ? item.id : item.key]}
              readOnly={true}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const { doctorId } = this.props;
    const {
      codesForThis,
      thisSelected,
      fetchingRelated,
      relatedTab,
      requestedList,
      toggleThisAllChecked,
    } = this.props.appointment;
    const sortedRequestedList = requestedList.sort(sortTreatments);

    const sortedCodesForThis = codesForThis.sort(sortTreatments);

    const $list = [];
    const options = { thisSelected, relatedTab, doctorId };

    if (relatedTab === "second") {
      const withGrouping = localStorage.getItem("treatmentGroupping");

      const groupedList =
        withGrouping === treatmentGroupping.byProcedure
          ? sortedRequestedList.reduce((groups, item) => {
              const procedureItem = _.get(item, "procedureItem");
              const procedureDate = _.get(item, "procedureKey");
              const createdDate = _.get(item, "createdDate");
              const procedureItemTooth = _.get(item, "tooth.name");
              const price = _.get(item, "price");

              if (procedureItem) {
                const current = _.get(groups, `item-${procedureDate}`, {
                  list: [],
                  price: 0,
                });
                const list = _.get(current, "list", []);

                list.push(item);

                groups[`item-${procedureDate}`] = {
                  ...current,
                  procedure: procedureItem,
                  createdDate,
                  type: "procedure",
                  list,
                  tooth: procedureItemTooth,
                  price: current.price + _.toFinite(price),
                };
              } else {
                const current = _.get(groups, "none", { list: [], price: 0 });
                const list = _.get(current, "list", []);

                list.push(item);

                groups.none = {
                  ...current,
                  list,
                  type: "procedure",
                  tooth: procedureItemTooth,
                  price: current.price + _.toFinite(price),
                };
              }

              return groups;
            }, {})
          : sortedRequestedList;

      if (withGrouping === treatmentGroupping.byProcedure) {
        _.forEach(groupedList, (item, key) => {
          const procedureList = _.get(item, "list");
          const procedure = _.get(item, "procedure");

          $list.push(
            <div key={key} className="flex-shrink-0">
              <div
                className="d-flex"
                style={{ userSelect: "none" }}
                onClick={() =>
                  this.setState((state) => ({
                    openedGroups: {
                      ...state.openedGroups,
                      [key]: !state.openedGroups[key],
                    },
                  }))
                }
              >
                <div
                  className="d-flex flex-grow-1 flex-shrink-1"
                  style={{
                    height: "50px",
                    color: "#ffffff",
                    cursor: "pointer",
                    paddingLeft: "12px",
                    alignItems: "center",
                    backgroundColor: "#049dd8",
                    borderBottom: "1px solid #ffffff",
                  }}
                >
                  {procedure ? procedure.name : "Without Procedure"}
                </div>
              </div>
              {this.state.openedGroups[key] && this.generateList(procedureList, options)}
            </div>,
          );
        });
      } else {
        $list.push(this.generateList(groupedList, options));
      }
    } else {
      sortedCodesForThis.forEach((item, index) => {
        $list.push(
          <div
            className="default-table-row"
            key={index}
            onClick={this.toggleThis(relatedTab === "second" ? item.id : item.key)}
          >
            <div className="default-table-col col-1">
              <div className="middler">
                {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="default-table-col col-2">
              <div className="middler">{_.get(item.treatmentItem, "code")}</div>
            </div>
            <div className="default-table-col col-3">
              <div className="middler">{_.get(item.treatmentItem, "description")}</div>
            </div>
            <Show if={relatedTab !== "second"}>
              <div className="default-table-col col-4">
                <div className="middler">{_.get(item, "tooth.name")}</div>
              </div>
            </Show>
            <div className="default-table-col col-5">
              <div className="middler">{_.toFinite(item.price).toFixed(2)}</div>
            </div>
            <div className="default-table-col col-6">
              <div className="middler">{_.get(item.status, "name")}</div>
            </div>
            <Show if={relatedTab === "second"}>
              <div className="default-table-col col-6" style={{ flex: "0 0 280px" }}>
                <button
                  className="btn btn-primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.appointmentActions.convertRequestToPlanned(
                      [item.id],
                      "PLANNED",
                      doctorId,
                    );
                  }}
                >
                  Add to planned
                </button>
                <button
                  style={{ marginLeft: "8px" }}
                  className="btn btn-info"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.appointmentActions.convertRequestToPlanned(
                      [item.id],
                      "COMPLETED",
                      doctorId,
                    );
                  }}
                >
                  Add to completed
                </button>
              </div>
            </Show>
            <div className="default-table-col col-7">
              <FormCheck
                checked={thisSelected[relatedTab === "second" ? item.id : item.key]}
                readOnly={true}
              />
            </div>
          </div>,
        );
      });
    }

    return (
      <div className="default-table related-this-table">
        <div className="default-table-header">
          <div className="default-table-col col-1">Date Posted</div>
          <div className="default-table-col col-2">Treatment code</div>
          <div className="default-table-col col-3">Description</div>
          <Show if={relatedTab !== "second"}>
            <div className="default-table-col col-4">Tooth</div>
          </Show>
          <div className="default-table-col col-5">Fee</div>
          <div className="default-table-col col-6">Status</div>
          <Show if={relatedTab === "second"}>
            <div className="default-table-col col-6" style={{ flex: "0 0 280px" }} />
          </Show>
          <div
            className="default-table-col col-7"
            style={{ marginTop: "3px" }}
            onClick={() => this.props.appointmentActions.toggleThisAll(!toggleThisAllChecked)}
          >
            <Show if={relatedTab === "second" && requestedList?.length > 0}>
              <FormCheck readOnly={true} checked={toggleThisAllChecked} />
            </Show>
          </div>
        </div>
        <div className="default-table-body" onScroll={this.loadList}>
          {$list}
        </div>
        <Show if={fetchingRelated}>
          <ReactSpinner />
        </Show>
      </div>
    );
  }
}

export default enhancer(AppointmentRelatedModalThis);
