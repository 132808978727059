import cx from "classnames";
import React, { useEffect, useState } from "react";

import FilledImage from "../../widgets/FilledImage";

export default function ConditionCrown({ toothImage, color, name, surface }) {
  const [image, setImage] = useState("");

  useEffect(() => {
    const s = surface ? "upper" : "lower";

    import(
      `../../../assets/images/conditions/${name}/${name}_${s}_${toothImage}@2x.png`
    ).then((x) => setImage(x.default));
  }, [name, surface, toothImage]);

  return (
    <div
      style={{ height: "100%" }}
      className={cx("ConditionCrown-wrap", {
        "tooth-image-Condition": !surface,
        "tooth-image-Condition-center": surface,
      })}
    >
      <FilledImage src={image} color={color} className="zoom-2x" />
    </div>
  );
}
