import _ from "lodash";
import RemindersApi from "../api-legacy/RemindersApi";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import * as ActionType from "../actionTypes/remindersActionTypes";
import AppointmentApi from "../api-legacy/AppointmentApi";
import Utils from "../helpers/Utils";
import { getPatientLanguages } from "./patientRegisterActions";

export function appointmentSendPush() {
  return (dispatch, getState) => {
    const {
      calendar: { pushNotificationAppointments },
    } = getState();

    dispatch(startLoader());

    let id = null;

    _.forEach(pushNotificationAppointments, (x, idx) => {
      if (x) {
        id = idx;
      }
    });

    return dispatch(
      appointmentSendPushApi({
        appointmentId: id,
        ids: Object.keys(pushNotificationAppointments).join(","),
      }),
    )
      .then(() => {
        dispatch(stopLoader("Notification has been sent"));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function resetReminders() {
  return {
    type: ActionType.REMINDER_RESET,
  };
}

export function initDentistList() {
  return (dispatch, getState) => {
    const data = {
      filterKey: getState().reminders.searchDentist,
    };

    return dispatch(getDentistListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function getCategoryList(data) {
  return {
    api: AppointmentApi.category.getChoose,
    types: [
      ActionType.REMINDERS_GET_CATEGORY_LIST_START,
      ActionType.REMINDERS_GET_CATEGORY_LIST_SUCCESS,
      ActionType.REMINDERS_GET_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

export function setCalendarFilter(values) {
  return {
    type: ActionType.REMINDERS_SET_CALENDAR_PATIENT_FILTER,
    values,
  };
}

export function setDoctorFilter(values) {
  return {
    type: ActionType.REMINDERS_SET_DOCTOR_FILTER,
    values,
  };
}

export function selectStatusFilter(values) {
  return {
    type: ActionType.REMINDERS_APPOINTMENT_FILTER_SELECT,
    values,
  };
}

export function selectClinicFilter(id) {
  return {
    type: ActionType.REMINDERS_APPOINTMENT_CLINIC_FILTER_SELECT,
    id,
  };
}

export function selectAllCalendarPatient(mode) {
  return {
    type: ActionType.REMINDERS_SELECT_ALL_CALENDAR_PATIENT,
    mode,
  };
}

export function initCalendarTempPatientList(types = [], doctors = [], statuses = [], clinicId) {
  return (dispatch, getState) => {
    const {
      calendar: { date },
    } = getState();

    const data = {
      start: 0,
      limit: 200,
      clinicId,
      periodStart: Utils.getStartDay(date),
      periodEnd: Utils.getEndDay(date),
      doctorIds: doctors.join(","),
      categoryIds: types.join(","),
      statusCodes: statuses.join(","),
    };
    return dispatch(getTempPatients(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function loadCalendarTempPatientList(types = [], doctors = [], statuses = [], clinicId) {
  return (dispatch, getState) => {
    const {
      calendar: { date },
      reminders: {
        calendarPatientList,
        calendarPatientListTotalCount,
        fetchingCalendarPatientList,
      },
    } = getState();

    if (fetchingCalendarPatientList) return;

    if (calendarPatientListTotalCount > calendarPatientList.length) {
      const data = {
        start: calendarPatientList.length,
        limit: 200,
        clinicId,
        periodStart: Utils.getStartDay(date),
        periodEnd: Utils.getEndDay(date),
        doctorIds: doctors.join(","),
        categoryIds: types.join(","),
        statusCodes: statuses.join(","),
      };
      return dispatch(getTempPatients(data)).catch((error) => {
        dispatch(showMessage(error));
      });
    }
  };
}

function getTempPatients(data) {
  return {
    api: RemindersApi.getCalendarPatientList,
    types: [
      ActionType.REMINDERS_GET_CALENDAR_PATIENT_LIST_START,
      ActionType.REMINDERS_GET_CALENDAR_PATIENT_LIST_SUCCESS,
      ActionType.REMINDERS_GET_CALENDAR_PATIENT_LIST_ERROR,
    ],
    data,
  };
}

export function initPatientList() {
  return (dispatch, getState) => {
    const { searchPatient } = getState().reminders;

    const data = {
      name: searchPatient,
    };

    return dispatch(getPatientListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function initPatient() {
  return (dispatch, getState) => {
    const { appointment } = getState();
    const patient = appointment?.appointment?.patient || appointment.patient;
    return dispatch(getPatientApi(patient));
  };
}

export function loadPatientList() {
  return (dispatch, getState) => {
    const {
      patientList = [],
      patientListTotalCount,
      searchPatient,
      fetchingPatientList,
    } = getState().reminders;

    if (patientList.length === patientListTotalCount || fetchingPatientList) {
      return;
    }

    const data = {
      name: searchPatient,
      start: patientList.length,
      limit: 25,
    };

    return dispatch(getPatientListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function initAutomaticList() {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 25,
      type: "Automatic",
    };

    return dispatch(getAutomaticListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function loadAutomaticList() {
  return (dispatch, getState) => {
    const { automaticList, automaticListTotalCount, fetchingAutomaticList } = getState().reminders;

    if (automaticList.length === automaticListTotalCount || fetchingAutomaticList) {
      return;
    }

    const data = {
      start: automaticList.length,
      limit: 25,
      type: "Automatic",
    };

    return dispatch(getAutomaticListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function initManualList() {
  return (dispatch, getState) => {
    const data = {
      start: 0,
      limit: 25,
      type: "Manual",
    };

    const {
      auth: { members },
    } = getState();

    dispatch({
      type: ActionType.REMINDERS_COPY_DOCTORS,
      members,
    });
    dispatch(getPatientLanguages());

    return dispatch(getManualListApi(data))
      .then(() => {
        dispatch(getCategoryList());
      })
      .catch((error) => {
        dispatch(showMessage(error));
      });
  };
}

export function loadManualList() {
  return (dispatch, getState) => {
    const { manualList, manualListTotalCount, fetchingManualList } = getState().reminders;

    if (manualList.length === manualListTotalCount || fetchingManualList) return;

    const data = {
      start: manualList.length,
      limit: 25,
      type: "Manual",
    };

    return dispatch(getManualListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function remindersModalShow() {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_MODAL_SHOW,
    });

    return dispatch(getTemplatesListApi()).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function toggleAutomaticEditMode() {
  return {
    type: ActionType.REMINDERS_TOGGLE_AUTOMATIC_EDIT_MODE,
  };
}

export function deleteReminders() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const data = {
      ids: getState().reminders.automaticListSelected,
    };

    return dispatch(deleteRemindersApi(data))
      .then(() => {
        dispatch(initAutomaticList());
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function toggleAutomaticListSelected(id) {
  return {
    type: ActionType.REMINDERS_TOGGLE_AUTOMATIC_LIST_SELECTED,
    id,
  };
}

export function remindersModalHide() {
  return {
    type: ActionType.REMINDERS_MODAL_HIDE,
  };
}

export function balanceModalToggle() {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_BALANCE_MODAL_TOGGLE,
    });
  };
}

export function toggleDentistShow() {
  return {
    type: ActionType.REMINDERS_TOGGLE_DENTIST_MODAL_SHOW,
  };
}

export function togglePatientShow() {
  return {
    type: ActionType.REMINDERS_TOGGLE_PATIENT_MODAL_SHOW,
  };
}

export function setPatientMode(mode) {
  return {
    type: ActionType.REMINDERS_SET_PATIENT_MODE,
    mode,
  };
}

export function setNotificationType(notificationType) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SET_NOTIFICATION_TYPE,
      notificationType,
    });
    if (notificationType === "SMS_NOTIFICATION") {
      dispatch(getTemplatesListApi()).catch((error) => {
        dispatch(showMessage(error));
      });
    } else if (notificationType === "PATIENT_APP") {
      dispatch(getPatientAppTemplatesListApi()).catch((error) => {
        dispatch(showMessage(error));
      });
    } else if (notificationType === "WHATS_APP_NOTIFICATION") {
      dispatch(getPatientWhatsAppTemplatesListApi()).catch((error) => {
        dispatch(showMessage(error));
      });
    } else {
      dispatch(getEmailTemplatesListApi()).catch((error) => {
        dispatch(showMessage(error));
      });
    }
  };
}

export function togglePatientHide() {
  return {
    type: ActionType.REMINDERS_TOGGLE_PATIENT_MODAL_HIDE,
  };
}

export function toggleDentistHide() {
  return {
    type: ActionType.REMINDERS_TOGGLE_DENTIST_MODAL_HIDE,
  };
}

export function toggleDentist(id) {
  return {
    type: ActionType.REMINDERS_TOGGLE_DENTIST,
    id,
  };
}

export function toggleAppoinments(id) {
  return {
    type: ActionType.REMINDERS_TOGGLE_APPOINTMENTS,
    id,
  };
}

export function togglePatient(temp, id, patientFromAppointmentView, companyPatientId) {
  return {
    type: ActionType.REMINDERS_TOGGLE_PATIENT,
    temp,
    id,
    patientFromAppointmentView,
    companyPatientId,
  };
}

export function searchDentist(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SEARCH_DENTIST,
      value: event.target.value,
    });

    dispatch(initDentistList());
  };
}

export function searchDentistSelected(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SEARCH_DENTIST_SELECTED,
      value: event.target.value,
    });
  };
}

export function searchCalendarPatient(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SEARCH_CALENDAR_PATIENT,
      value: event.target.value,
    });
  };
}

export function searchPatient(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SEARCH_PATIENT,
      value: event.target.value,
    });

    dispatch(initPatientList());
  };
}

export function searchPatientSelected(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMINDERS_SEARCH_PATIENT_SELECTED,
      value: event.target.value,
    });
  };
}

export function setDentistMode(data) {
  return {
    type: ActionType.REMINDERS_SET_DENTIST_MODE,
    data,
  };
}

export function setRemindersMode(data) {
  return {
    type: ActionType.SET_REMINDERS_MODE,
    data,
  };
}

export function saveAutomatic() {
  return (dispatch, getState) => {
    const {
      reminders: { toggleDentistSelected, templatesList },
      form: { remindersAutomatic },
    } = getState();

    if (_.isEmpty(remindersAutomatic.values) || !remindersAutomatic.values.templates) {
      dispatch(showMessage("Please Choose Template(s)"));
      return;
    }
    if (_.isEmpty(toggleDentistSelected)) {
      return dispatch(showMessage(`Please Choose ${localStorage.getItem("doctorLabel")}`));
    }

    dispatch(startLoader());
    let template = {};

    if (remindersAutomatic.values && remindersAutomatic.values.templates) {
      template = _.find(templatesList, ["id", remindersAutomatic.values.templates]);
    }

    const data = {
      runBefore: (remindersAutomatic.values && remindersAutomatic.values.sendBefore) || 1,
      recipients: _.values(toggleDentistSelected),
      runningType: "Automatic",
      recipientType: "EMPLOYEE",
      template,
    };

    return dispatch(saveAutomaticApi(data))
      .then(() => {
        dispatch(initAutomaticList());
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function saveManual(appointmentId) {
  return (dispatch, getState) => {
    let {
      calendar: { date, clinicId },
      patientRegister: { languages },
      reminders: {
        toggleDentistSelected,
        togglePatientSelected,
        togglePatientSelectedFullData,
        togglePatientMode,
        recipientType,
        notificationType,
        templatesList,
        patientList,
        calendarPatientList,
        remindersModalVisible,
      },
      form: { remindersManual },
    } = getState();

    if (!patientList.length) {
      //from appointment view
      patientList = [patientList];
    }

    if (_.isEmpty(remindersManual.values) || !remindersManual.values.templates) {
      dispatch(showMessage("Please Choose Template(s)"));
      return;
    }
    if (_.isEmpty(toggleDentistSelected) && recipientType === "EMPLOYEE") {
      return dispatch(showMessage(`Please Choose ${localStorage.getItem("doctorLabel")}`));
    }
    if (_.isEmpty(togglePatientSelected) && recipientType !== "EMPLOYEE") {
      return dispatch(showMessage("Please Choose Patient(s)"));
    }

    dispatch(startLoader());

    let template = {};

    if (remindersManual.values && remindersManual.values.templates) {
      template = _.find(templatesList, ["id", remindersManual.values.templates]);
    }
    let language = {};

    if (remindersManual.values && remindersManual.values.language !== -1) {
      language = _.find(languages, ["id", remindersManual.values.language]);
    }

    if (remindersManual.values && remindersManual.values.language === -1) {
      language = null;
    }

    const patients = {};
    _.forEach(togglePatientSelected, (x, idx) => {
      _.forEach(togglePatientSelectedFullData, (z) => {
        if (z.id === x.id || z.companyPatientId === x.id) {
          patients[idx] = {
            ...x,
            code: z.description === "tempUser" ? z.code : z.chartNumber,
            id: z.companyPatientId,
          };
        } else if (z.appointmentId === x.appointmentId) {
          patients[idx] = {
            ...x,
            companyPatientId: z.companyPatientId,
          };
        }
      });
      _.forEach(calendarPatientList, (z) => {
        if (z.code === x.code && !patients[z.code]) {
          patients[idx] = {
            ...x,
            code: z.description === "tempUser" ? z.code.split("_")[0] : z.code,
          };
        }
      });
    });
    let recipient =
      recipientType === "EMPLOYEE"
        ? _.values(toggleDentistSelected)
        : _.values(patients).map((item) => {
            if (togglePatientMode === "calendar") {
              return { id: item.appointmentId };
            } else {
              return { id: item.id || item.companyTempPatientId };
            }
          });
    if (appointmentId) {
      recipient = [{ id: appointmentId }];
    }
    const data = {
      clinicId,
      runBefore: 1,
      recipients: recipient,
      runningType: "Manual",
      recipientType: recipientType === "PATIENT" ? "APPOINTMENT_PATIENT" : recipientType,
      notificationType,
      template,
      language,
      selectedDate: Utils.getStartDay(date),
    };
    return dispatch(saveManualApi(data))
      .then(() => {
        if (remindersModalVisible) {
          dispatch(remindersModalHide());
        }
        dispatch(initManualList());
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function checkBalance() {
  return (dispatch) => {
    return dispatch(checkBalanceApi())
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function setRecipientType(recipientType) {
  return {
    type: ActionType.REMINDERS_SET_RECIPIENT_TYPE,
    recipientType,
  };
}

function getAutomaticListApi(data) {
  return {
    api: RemindersApi.getRemindersList,
    types: [
      ActionType.REMINDERS_GET_AUTOMATIC_LIST_START,
      ActionType.REMINDERS_GET_AUTOMATIC_LIST_SUCCESS,
      ActionType.REMINDERS_GET_AUTOMATIC_LIST_ERROR,
    ],
    data,
  };
}

function checkBalanceApi() {
  return {
    api: RemindersApi.checkBalance,
    types: [
      ActionType.REMINDERS_CHECK_BALANCE_START,
      ActionType.REMINDERS_CHECK_BALANCE_SUCCESS,
      ActionType.REMINDERS_CHECK_BALANCE_ERROR,
    ],
  };
}

function getDentistListApi(data) {
  return {
    api: RemindersApi.getDentistList,
    types: [
      ActionType.REMINDERS_GET_DENTIST_START,
      ActionType.REMINDERS_GET_DENTIST_SUCCESS,
      ActionType.REMINDERS_GET_DENTIST_ERROR,
    ],
    data,
  };
}

function deleteRemindersApi(data) {
  return {
    api: RemindersApi.deleteReminder,
    types: [
      ActionType.REMINDERS_DELETE_SELECTED_START,
      ActionType.REMINDERS_DELETE_SELECTED_SUCCESS,
      ActionType.REMINDERS_DELETE_SELECTED_ERROR,
    ],
    data,
  };
}

function getManualListApi(data) {
  return {
    api: RemindersApi.getRemindersList,
    types: [
      ActionType.REMINDERS_GET_MANUAL_LIST_START,
      ActionType.REMINDERS_GET_MANUAL_LIST_SUCCESS,
      ActionType.REMINDERS_GET_MANUAL_LIST_ERROR,
    ],
    data,
  };
}

function saveAutomaticApi(data) {
  return {
    api: RemindersApi.addReminder,
    types: [
      ActionType.REMINDERS_SAVE_AUTOMATIC_START,
      ActionType.REMINDERS_SAVE_AUTOMATIC_SUCCESS,
      ActionType.REMINDERS_SAVE_AUTOMATIC_ERROR,
    ],
    data,
  };
}

function getTemplatesListApi() {
  return {
    api: RemindersApi.getTemplatesList,
    types: [
      ActionType.REMINDERS_GET_TEMPLATES_LIST_START,
      ActionType.REMINDERS_GET_TEMPLATES_LIST_SUCCESS,
      ActionType.REMINDERS_GET_TEMPLATES_LIST_ERROR,
    ],
  };
}

function getEmailTemplatesListApi() {
  return {
    api: RemindersApi.getEmailTemplatesList,
    types: [
      ActionType.REMINDERS_GET_EMAIL_TEMPLATES_LIST_START,
      ActionType.REMINDERS_GET_EMAIL_TEMPLATES_LIST_SUCCESS,
      ActionType.REMINDERS_GET_EMAIL_TEMPLATES_LIST_ERROR,
    ],
  };
}

function getPatientAppTemplatesListApi() {
  return {
    api: RemindersApi.getPatientAppTemplatesList,
    types: [
      ActionType.REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_START,
      ActionType.REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_SUCCESS,
      ActionType.REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_ERROR,
    ],
  };
}

function getPatientWhatsAppTemplatesListApi() {
  return {
    api: RemindersApi.getPatientWhatsAppTemplatesList,
    types: [
      ActionType.REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_START,
      ActionType.REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_SUCCESS,
      ActionType.REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_ERROR,
    ],
  };
}

function getPatientListApi(data) {
  return {
    api: RemindersApi.getPatientList,
    types: [
      ActionType.REMINDERS_GET_PATIENT_LIST_START,
      ActionType.REMINDERS_GET_PATIENT_LIST_SUCCESS,
      ActionType.REMINDERS_GET_PATIENT_LIST_ERROR,
    ],
    data,
  };
}

function getPatientApi(data) {
  return {
    type: ActionType.REMINDERS_GET_PATIENT_SUCCESS,
    data,
  };
}

function saveManualApi(data) {
  return {
    api: RemindersApi.addReminder,
    types: [
      ActionType.REMINDERS_SAVE_MANUAL_START,
      ActionType.REMINDERS_SAVE_MANUAL_SUCCESS,
      ActionType.REMINDERS_SAVE_MANUAL_ERROR,
    ],
    data,
  };
}

function appointmentSendPushApi(data) {
  return {
    api: RemindersApi.sendPush,
    types: [
      ActionType.REMINDERS_APPOINTMENT_SEND_PUSH_START,
      ActionType.REMINDERS_APPOINTMENT_SEND_PUSH_SUCCESS,
      ActionType.REMINDERS_APPOINTMENT_SEND_PUSH_ERROR,
    ],
    data,
  };
}
