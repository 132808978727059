import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import DataList from "../../../widgets/DataList";
import ChartSideSearch from "../ChartSideSearch";
import { parseQuery } from "../../../../utils/UrlUtils";
import bindActions from "../../../../helpers/bindActions";
import ChartFillInFormCategoryItem from "../ChartListItem";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";
import ChartClinicalNoteCategoryGroupItem from "../ChartClinicalNoteCategoryGroupItem";
import fillInFormItemTypes from "../../../../constants/chart-sidebar/fillInFormItemTypes";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";

const enhancer = connect(
  ({ session, chartSideFillInForm: { templateGroupList } }) => ({
    fillInFormTemplateId: _.get(session, "miscSettings.patientChartFillInFormTemplate.id"),
    templateGroupList,
  }),
  bindActions({ chartSideFillInFormActions }),
);

class ChartFillInFormList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  UNSAFE_componentWillMount() {
    this.props.chartSideFillInFormActions.getTemplateList();
  }

  componentDidMount() {
    const { location, fillInFormTemplateId } = this.props;

    const query = parseQuery(location.search);

    const isFrequency = Boolean(_.get(query, "isFrequency"));

    if (isFrequency && fillInFormTemplateId > 0) {
      this.openItem({ id: fillInFormTemplateId });
    }
  }

  openItem = (item) => {
    this.props.chartSideFillInFormActions.openItem(item, fillInFormItemTypes.new);
  };

  onSearch = (event) => {
    this.props.chartSideFillInFormActions.setSearchValue(event.target.value);
  };

  getList() {
    const { groupId } = this.state;
    const { chartSideFillInForm } = this.props;
    const { templateList, searchValue = "" } = chartSideFillInForm;

    const filteredList = templateList.filter(
      (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );

    if (groupId) {
      return filteredList
        .filter(({ group }) => group && group.id === groupId)
        .map((item) => (
          <ChartFillInFormCategoryItem
            item={item}
            key={item.id}
            style={{ background: "none" }}
            openItem={() => this.openItem(item)}
          />
        ));
    }

    return _.uniqWith(
      filteredList.map((item) => {
        if (item.group && item.group.id) {
          return item.group;
        }

        return item;
      }),
      _.isEqual,
    )
      .sort((a, b) => {
        const aHasGroup = !a.typeId && !a.speciality && !a.templateBody;
        const bHasGroup = !b.typeId && !b.speciality && !b.templateBody;

        return (aHasGroup === bHasGroup ? 0 : aHasGroup ? -1 : 1) || a.id - b.id;
      })
      .map((item, idx) => {
        const hasGroup = !item.typeId && !item.speciality && !item.templateBody;

        if (hasGroup) {
          return (
            <ChartClinicalNoteCategoryGroupItem
              title={item.name}
              key={`group-${item.id}-${idx}`}
              onClick={() =>
                this.setState({
                  groupId: item.id,
                  groupName: item.name,
                })
              }
            />
          );
        }

        return (
          <ChartFillInFormCategoryItem
            item={item}
            key={`item-${item.id}`}
            style={{ background: "none" }}
            openItem={() => this.openItem(item)}
          />
        );
      });
  }

  render() {
    const { groupName, groupId } = this.state;
    const { fetching, searchValue } = this.props.chartSideFillInForm;

    return (
      <div className="chart-clinical-note">
        <SidebarHeader
          title="Add Fill In Form"
          onBackClick={() => {
            this.props.chartSideFillInFormActions.setFillInFormAddendumId(0);
            if (groupId) {
              this.setState({
                groupId: undefined,
                groupName: undefined,
              });
            } else {
              this.props.chartSideFillInFormActions.popStack();
            }
          }}
        />
        <div className="chart-side-body">
          <ChartSideSearch value={searchValue} onChange={this.onSearch} />
          <div className="body">
            <div className="chart-clinical-note-category-title">
              {groupName || "Ready Templates"}
            </div>
            <DataList
              fetching={fetching}
              onScroll={() => this.props.chartSideFillInFormActions.loadTemplateList()}
            >
              {this.getList()}
            </DataList>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartFillInFormList);
