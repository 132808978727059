import { httpClient } from "./BaseApi";

const MEDICAL_FILL_IN_FORM_ITEM_URL = "api/fill-in-form/item.json";
const MEDICAL_FILL_IN_FORM_GROUP_LIST_URL = "api/fill-in-form/group/choose.json";
const MEDICAL_FILL_IN_FORM_CHANGE_DATE_URL = "api/fill-in-form/item/change-date.json";
const MEDICAL_FILL_IN_FORM_REVIEW = "api/fill-in-form/item/review.json";

const MEDICAL_FILL_IN_FORM_TAB_LIST_URL = "api/medical-form/template/type/choose.json";
const MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_URL = "api/medical-form/settings/list.json";
const MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_URL = "api/medical-form/template/list.json";
const MEDICAL_FILL_IN_FORM_CONSULTATION_RESULT_ITEM_URL =
  "api/medical-form/consultation/result/item.json";
const MEDICAL_FILL_IN_FORM_CONSULTATION_ITEM_URL = "api/medical-form/consultation/item.json";
const MEDICAL_FILL_IN_FORM_CONSULTATION_LIST_URL = "api/medical-form/consultation/list.json";
const MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE =
  "api/medical-form/consultation/initial-examination/approve.json";
const MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM =
  "api/medical-form/consultation/initial-examination/item.json";

const MEDICAL_FILL_IN_FORM_TREATMENT_LIST_URL = "api/dental-transaction/list-for-complete.json";
const MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_URL = "api/dental-transaction/list-for-complete.json";

const MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_URL =
  "api/dental-transaction/item/update-diagnosis-claim-type.json";
const MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ON_SET_URL =
  "api/dental-transaction/item/update-diagnosis-year-of-on-set.json";
const MEDICAL_FILL_IN_FORM_EMPTY_URL = "api/medical-form/consultation/empty.json";

const MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_lIST_URL = "api/v2/vital/transaction/list.json";
const MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_URL = "api/v2/vital/transaction/empty.json";
const MEDICAL_FILL_IN_FORM_ADDENDUM_URL = "api/medical-form/consultation/addendum/item.json";
const MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_URL = "api/medical-form/consultation/addendum/list.json";

const MEDICAL_FILL_IN_FORM_QUESTIONS = "api/medical-form/questions/list.json";

const MedicalFillInFormApi = {
  getMedicalFormQuestionsList: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_QUESTIONS, { params }),
  getMedicalFillInFormTabList: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_TAB_LIST_URL, { params }),
  updateDiagnosisClaimType: (data) =>
    httpClient.put(MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_URL, { data }),
  updateDiagnosisYearOfOnSet: (data) =>
    httpClient.put(MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ON_SET_URL, { data }),
  getMedicalFillInFormHardcodedTemplateList: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_URL, { params }),
  getMedicalFillInFormTemplateList: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_URL, { params }),
  getMedicalFillInFormTemplateQuestions: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_CONSULTATION_RESULT_ITEM_URL, { params }),
  getItem: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_ITEM_URL, { params }),
  getConsultationTemplateList: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_CONSULTATION_ITEM_URL, { params }),
  getConsultationTemplateItem: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_CONSULTATION_RESULT_ITEM_URL, { params }),
  onSaveMedicalFillInFormItem: (data) =>
    httpClient.post(MEDICAL_FILL_IN_FORM_CONSULTATION_RESULT_ITEM_URL, { data }),
  getOwnedList: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_CONSULTATION_LIST_URL, { params }),
  getMedicalFillInFormEmpty: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_EMPTY_URL, { params }),
  deleteItem: (params) => httpClient.delete(MEDICAL_FILL_IN_FORM_ITEM_URL, { params }),
  getGroupList: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_GROUP_LIST_URL, { params }),
  changeCreatedDate: (data) => httpClient.put(MEDICAL_FILL_IN_FORM_CHANGE_DATE_URL, { data }),
  reviewFillInForm: (data) => httpClient.put(MEDICAL_FILL_IN_FORM_REVIEW, { data }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(MEDICAL_FILL_IN_FORM_ITEM_URL, { data })
      : httpClient.post(MEDICAL_FILL_IN_FORM_ITEM_URL, { data }),
  saveItemAddendum: (data) => httpClient.post(MEDICAL_FILL_IN_FORM_ADDENDUM_URL, { data }),
  getAddendumList: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_URL, { params }),
  template: {
    getList: (params) => httpClient.get(MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_URL, { params }),
  },
  getMedicalFillInFormTreatmentListApi: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_TREATMENT_LIST_URL, { params }),
  getMedicalFillInFormDiagnosisListApi: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_URL, { params }),
  getMedicalFillInFormVitalsListApi: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_lIST_URL, { params }),
  getMedicalFillInFormVitalTransactionApi: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_URL, { params }),
  approveConsultationInitialExaminationApi: (data) =>
    httpClient.put(MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE, { data }),
  saveConsultationInitialExaminationItemApi: (data) =>
    httpClient.put(MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM, { data }),
  getConsultationInitialExaminationItemApi: (params) =>
    httpClient.get(MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM, { params }),
};

export default MedicalFillInFormApi;
