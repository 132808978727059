import _ from "lodash";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import RecallListForm from "./RecallListForm";
import { getRecallList } from "../../actions/recallListActions";
import { getCategoryList } from "../../actions/appointmentActions";
import {
  setMissedCancelledListActiveTab,
  missedCancelledNotInterestedAppointment,
  missedCancelledAppointmentSetType,
} from "../../actions/waitingListActions";
import { useFormik } from "formik";
import PatientInfoSideBar from "../patient/PatientInfoSideBar";
import { getDentist } from "../../actions/calendarActions";
import LogoImage from "../../assets/images/clinic_logo.png";
import { openPatientInfoSideBar } from "../../actions/patientActions";
import { getClinics } from "../../actions/authActions";
import Utils from "../../helpers/Utils";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { PatientItem } from "../patient/PatientItem";
import { SelectPicker } from "../select-picker/SelectPicker";
import Back from "../widgets/Back";

const enhancer = connect(
  ({
    recallList,
    auth: { members },
    session: {
      clinic: { id },
    },
    appointment: { categoryList },
    waitingList: { missedCancelledListActiveTab },
  }) => {
    return {
      recallList,
      categoryList,
      members,
      clinicId: id,
      missedCancelledListActiveTab,
    };
  },
  {
    getRecallList,
    setMissedCancelledListActiveTab,
    getCategoryList,
    getDentist,
    getClinics,
    openPatientInfoSideBar,
    missedCancelledNotInterestedAppointment,
    missedCancelledAppointmentSetType,
  },
);
const RecallList = (props) => {
  const [clinicId, setClinicId] = useState(props.clinicId);
  const [tbodyHeight, setTbodyHeight] = useState(300);
  const [tdWidth, setTdWidth] = useState(0);

  useEffect(() => {
    const { getRecallList, getCategoryList, clinicId, getClinics } = props;
    getCategoryList({ clinicId });
    getClinics();
    setTbodyHeight(document.body.offsetHeight - 300);
    setTdWidth(document.body.offsetWidth / 9);

    getRecallList();
  }, []);

  const handleSubmit = (values, start) => {
    const { getRecallList, missedCancelledListActiveTab } = props;

    switch (missedCancelledListActiveTab) {
      case "missed":
        values.noAnswer = false;
        values.willCall = false;
        values.requestedReschedule = false;
        values.notInterested = false;
        break;
      case "requestedReschedule":
        values.requestedReschedule = true;
        values.noAnswer = false;
        values.willCall = false;
        values.notInterested = false;
        break;
      case "noAnswer":
        values.noAnswer = true;
        values.requestedReschedule = false;
        values.willCall = false;
        values.notInterested = false;
        break;
      case "willCall":
        values.willCall = true;
        values.noAnswer = false;
        values.requestedReschedule = false;
        values.notInterested = false;
        break;
      case "notInterested":
        values.notInterested = true;
        values.willCall = false;
        values.noAnswer = false;
        values.requestedReschedule = false;
        break;
      default:
        break;
    }
    getRecallList(values, start);
  };

  const setItemStatus = (status, values, appointment) => {
    const {
      getRecallList,
      missedCancelledNotInterestedAppointment,
      missedCancelledAppointmentSetType,
      missedCancelledListActiveTab,
    } = props;
    let request;
    let data;
    if (status === "notInterested") {
      data = { ...appointment, notInterested: true };
      request = missedCancelledNotInterestedAppointment;
    } else {
      data = { id: appointment.id, [status]: true };
      request = missedCancelledAppointmentSetType;
    }
    request(data).then(() => {
      switch (missedCancelledListActiveTab) {
        case "missed":
          values.noAnswer = false;
          values.willCall = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "requestedReschedule":
          values.requestedReschedule = true;
          values.noAnswer = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "noAnswer":
          values.noAnswer = true;
          values.requestedReschedule = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "willCall":
          values.willCall = true;
          values.noAnswer = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "notInterested":
          values.notInterested = true;
          values.willCall = false;
          values.noAnswer = false;
          values.requestedReschedule = false;
          break;
        default:
          break;
      }
      getRecallList(values, 0);
    });
  };

  const {
    members,
    recallList: { fromDate, toDate, list = [] },
    categoryList,
    setMissedCancelledListActiveTab,
    openPatientInfoSideBar,
  } = props;
  let initialValues = {
    periodStart: fromDate,
    periodEnd: toDate,
    categoryIds: [],
    clinic: clinicId,
  };

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values, 0);
    },
  });

  const actionOptions = [
    { value: "notInterested", label: "Not interested" },
    { value: "willCall", label: "Will call" },
    { value: "noAnswer", label: "No answer" },
    { value: "requestedReschedule", label: "Requested reschedule" },
  ];

  return (
    <div className="waiting-list-page">
      <PatientInfoSideBar handleSubmit={form.handleSubmit} />
      <div className="default-page-title clearfix text-center">
        <Back className="pull-left" />
        <img src={LogoImage} alt="logo" className="header-logo" />
      </div>
      <form onSubmit={form.handleSubmit}>
        <RecallListForm
          setActiveTab={(activeTab) => {
            setMissedCancelledListActiveTab(activeTab);
            handleSubmit(form.values, 0);
          }}
          onChangeClinic={({ value }) => {
            setClinicId(value);
            props.getDentist(value);
          }}
          handleSubmit={() => handleSubmit(form.values, 0)}
          values={form.values}
          setFieldValue={form.setFieldValue}
          categoryList={categoryList}
          fromDate={fromDate}
          toDate={toDate}
          members={members}
        />
      </form>
      <div style={{ height: "75%", overflow: "hidden" }}>
        <table className="table bring-earlier-table missed-cancelled-table">
          <thead>
            <tr style={{ borderTop: "1px solid #ccc" }}>
              <th style={{ width: `${tdWidth - 1}px` }}>Patient</th>
              <th style={{ width: `${tdWidth - 1}px` }}>Chart number</th>
              <th style={{ width: `${tdWidth - 1}px` }}>Doctor</th>
              <th style={{ width: `${tdWidth - 1}px` }}>Appointment date</th>
              <th style={{ width: `${tdWidth - 1}px` }}>Appointment type</th>
              <th style={{ width: `${tdWidth - 1}px` }}>Actions</th>
            </tr>
          </thead>
          <tbody
            className="table-hidden-scrollbar"
            // onScroll={(e) => this.loadList(e, values)}
            style={{ height: `${tbodyHeight}px` }}
          >
            {list.map((x) => {
              const doctor = _.get(x, "dentist.name");
              const apptDate = Utils.parseDateFormat(moment(x.date), DATE_FORMAT_LEGACY);
              const apptType = x.category.name || "";
              const chartNumber = _.get(x, "patient.chartNumber");

              const patientLastName = _.get(x, "patient.lastName");
              const patientFirstName = _.get(x, "patient.firstName");
              const patientName = [patientFirstName, patientLastName].join(" ");
              const notInterested = x.notInterested;
              const willCall = x.willCall;
              const noAnswer = x.noAnswer;
              const requestedReschedule = x.requestedReschedule;
              return (
                <tr key={x.id} className="missed-cancelled-table-cell">
                  <td style={{ width: `${tdWidth}px` }} onClick={() => openPatientInfoSideBar(x)}>
                    <PatientItem
                      size={40}
                      photo={!_.isEmpty(x.patient.photo) && x.patient.photo.url}
                      name={patientName}
                    />
                  </td>
                  <td style={{ width: `${tdWidth}px` }} onClick={() => openPatientInfoSideBar(x)}>
                    <div>{chartNumber}</div>
                  </td>
                  <td style={{ width: `${tdWidth}px` }} onClick={() => openPatientInfoSideBar(x)}>
                    <div>{doctor}</div>
                  </td>
                  <td style={{ width: `${tdWidth}px` }} onClick={() => openPatientInfoSideBar(x)}>
                    <div>{apptDate}</div>
                  </td>
                  <td style={{ width: `${tdWidth}px` }} onClick={() => openPatientInfoSideBar(x)}>
                    <div>{apptType}</div>
                  </td>
                  <td style={{ width: `${tdWidth}px` }}>
                    <div
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SelectPicker
                        options={actionOptions}
                        onChange={(item) => setItemStatus(item.value, form.values, x)}
                        onFocus={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        value={{
                          value:
                            (notInterested && "notInterested") ||
                            (willCall && "willCall") ||
                            (noAnswer && "noAnswer") ||
                            (requestedReschedule && "requestedReschedule"),
                        }}
                      />
                      {/*<Button bsStyle="primary" onClick={() => this.onChecked(x, true)}>*/}
                      {/*  Not Interested*/}
                      {/*</Button>*/}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default enhancer(RecallList);
