export const SIDE_DOCS_GET_FILES_START = "SIDE_DOCS_GET_FILES_START";
export const SIDE_DOCS_GET_FILES_SUCCESS = "SIDE_DOCS_GET_FILES_SUCCESS";
export const SIDE_DOCS_GET_FILES_ERROR = "SIDE_DOCS_GET_FILES_ERROR";

export const SIDE_DOCS_DELETE_FILES_START = "SIDE_DOCS_DELETE_FILES_START";
export const SIDE_DOCS_DELETE_FILES_SUCCESS = "SIDE_DOCS_DELETE_FILES_SUCCESS";
export const SIDE_DOCS_DELETE_FILES_ERROR = "SIDE_DOCS_DELETE_FILES_ERROR";

export const SIDE_DOCS_OPEN_DELETE_MODAL = "SIDE_DOCS_OPEN_DELETE_MODAL";
export const SIDE_DOCS_CLOSE_DELETE_MODAL = "SIDE_DOCS_CLOSE_DELETE_MODAL";

export const SIDE_DOCS_TOGGLE_IMAGE = "SIDE_DOCS_TOGGLE_IMAGE";

export const SIDE_DOCS_FULL_MODE = "SIDE_DOCS_FULL_MODE";
export const SIDE_DOCS_SMALL_MODE = "SIDE_DOCS_SMALL_MODE";

export const SIDE_DOCS_PREV_INDEX = "SIDE_DOCS_PREV_INDEX";
export const SIDE_DOCS_NEXT_INDEX = "SIDE_DOCS_NEXT_INDEX";
