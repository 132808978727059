import React from "react";
import { connect } from "react-redux";

import BackButton from "../widgets/BackButton";
import Show from "../widgets/Show";
import ChartSideSearch from "../chart/side-bar/ChartSideSearch";

import Utils from "../../helpers/Utils";
import bindActions from "../../helpers/bindActions";
import * as patientViewActions from "../../actions/patientViewActions";
import { Modal } from "../ui/Modal";

const enhancer = connect(null, bindActions({ patientViewActions }));
class PatientViewClinicianModal extends React.Component {
  back = () => {
    if (this.props.clinicianAddMode) {
      this.props.patientViewActions.toggleClinicianAddMode();
    } else {
      this.props.patientViewActions.closeClinicianModal();
    }
  };

  toggleClinician = (id) => {
    return () => {
      this.props.patientViewActions.toggleClinician(id);
    };
  };

  onSearch = (event) => {
    this.props.patientViewActions.setClinicianSearchValue(event.target.value);
  };

  render() {
    const {
      clinicianModalVisible,
      clinicians,
      clinicianAddMode,
      clinicianAll,
      selectedClinician,
      clinicianSearchValue,
    } = this.props.patientView;

    if (!clinicianModalVisible) {
      return null;
    }

    let $list = [];

    if (!clinicianAddMode) {
      $list = clinicians.map((item, index) => (
        <div key={index} className="clinician-owned-item">
          {item.name}
        </div>
      ));
    } else {
      $list = clinicianAll.map((item, index) => {
        return (
          <div key={index} className="clinician-all-item" onClick={this.toggleClinician(item.id)}>
            {/*<MemberImage photoUrl={item.photoUrl}/>*/}
            <div className="name">{Utils.getFullName(item)}</div>
            <Show if={selectedClinician[item.id]}>
              <div className="arrow">✔</div>
            </Show>
          </div>
        );
      });
    }

    return (
      <Modal
        bodyProps={{ style: { padding: 0 } }}
        show={true}
        onHide={this.back}
        animation={false}
        keyboard={false}
        dialogClassName="chart-side-bar"
      >
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header">
            <div className="clearfix">
              <div className="col-sm-4">
                <BackButton className="btn-sm" text="Back" onClick={this.back} />
              </div>
              <div className="col-sm-8">
                <Show if={!clinicianAddMode}>
                  <div className="chart-clinical-note-title">Assigned Clinician</div>
                </Show>

                <Show if={clinicianAddMode}>
                  <button
                    className="btn btn-link pull-right"
                    type="button"
                    onClick={this.props.patientViewActions.saveClinician}
                  >
                    SAVE
                  </button>
                </Show>
              </div>
            </div>
          </div>
          <div className="chart-side-body">
            <Show if={clinicianAddMode}>
              <div className="title">Select Clinician</div>
            </Show>

            <Show if={clinicianAddMode}>
              <ChartSideSearch value={clinicianSearchValue} onChange={this.onSearch} />
            </Show>

            <div className="body">{$list}</div>

            <Show if={!clinicianAddMode}>
              <div className="all-codes">
                <button
                  onClick={this.props.patientViewActions.toggleClinicianAddMode}
                  className="btn btn-primary btn-block"
                >
                  Add
                  {clinicians.length && "/Edit"} Clinician
                </button>
              </div>
            </Show>
          </div>
        </div>
      </Modal>
    );
  }
}
export default enhancer(PatientViewClinicianModal);
