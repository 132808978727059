import React from "react";
import { string, func } from "prop-types";
import { Dropzone } from "../../ui/Dropzone";

UploadImageCell.propTypes = {
  url: string,
  onDrop: func,
  onUploadClick: func,
};

export function UploadImageCell({ url, onDrop, onUploadClick }) {
  return (
    <div>
      {url && (
        <img
          alt=""
          role="button"
          src="/images/calendar-bottom-menu/switch_view.png"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onUploadClick({
              body: <img alt="" src={url} style={{ maxWidth: "100%" }} />,
              bsSize: "large",
            });
          }}
        />
      )}
      &nbsp;
      <Dropzone
        multiple={false}
        onDrop={(files) => onDrop(files)}
        onClick={(e) => e.stopPropagation()}
        style={{ display: "inline-block" }}
        className="chart-transaction-dropzone"
      >
        <img alt="" style={{ height: "19px" }} src="/images/transaction/upload.png" />
      </Dropzone>
    </div>
  );
}
