import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as chartGeneralActions from "../../../actions/chartGeneralActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";
import deleteImage from "../../../assets/images/delete.png";
import plusImage from "../../../assets/images/appointment/plus_grey.png";

const enhancer = connect(null, bindActions({ chartGeneralActions, chartSideSeriesActions }));

class ChartStickyNote extends React.Component {
  componentWillUnmount() {
    this.props.chartGeneralActions.saveSticky();
  }

  deleteSticky = (item, index) => {
    return () => {
      const {
        chartGeneral: { stickyLists, activeStickyIndex },
      } = this.props;
      const newStickyItem = {
        list: stickyLists[activeStickyIndex].list.slice(),
        categoryId: stickyLists[activeStickyIndex].categoryId,
      };

      newStickyItem.list.splice(index, 1);

      this.props.chartGeneralActions.setStickyItem(newStickyItem, activeStickyIndex);

      if (item.id) {
        this.props.chartGeneralActions.deleteSticky(item.id, newStickyItem.categoryId);
      }
    };
  };

  render() {
    const {
      chartGeneral: { fetchingSticky, stickyLists, activeStickyIndex },
    } = this.props;

    let $list = (stickyLists[activeStickyIndex].list || []).map((item, index) => {
      return (
        <div key={index} className="chart-general-goal-item clearfix">
          <div className="delete goal-sidebar" onClick={this.deleteSticky(item, index)}>
            <img src={deleteImage} alt="" />
          </div>
          <div className="goal-checkbox goal-sidebar">
            <div className="green-circle" />
          </div>
          <div className="text">
            <input
              type="text"
              value={item.notes}
              ref={"goal" + index}
              onChange={(e) => this.props.chartGeneralActions.setStickyNote(e.target.value, index)}
            />
          </div>
        </div>
      );
    });

    return (
      <div className="chart-clinical-note">
        <div className="sidebar-header chart-side-header">
          <div className="clearfix">
            <div className="col-sm-4 col-sm-offset-4 text-center">
              <div className="chart-clinical-note-title">Goals</div>
            </div>
            <div className="col-sm-4 text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={this.props.chartSideSeriesActions.popSeriesStack}
              >
                DONE
              </button>
            </div>
          </div>
        </div>
        <div className="chart-side-body">
          <div className="body">
            {$list}
            <div
              className="chart-general-goal-item clearfix"
              onClick={this.props.chartGeneralActions.pushSticky}
            >
              <div className="goal-checkbox goal-plus-sidebar">
                <img src={plusImage} alt="" />
              </div>
              <div className="text" />
            </div>
            <Show if={fetchingSticky}>
              <ReactSpinner />
            </Show>
          </div>
          <div className="all-codes">
            <button
              onClick={this.props.chartGeneralActions.deleteStickyList}
              className="btn btn-primary btn-block"
            >
              Delete All
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartStickyNote);
