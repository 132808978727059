export const medicalFillInFormStackCodes = {
  entry: "entry",
  owned: "owned",
  ownedList: "ownedList",
  list: "list",
  item: "item",
  textarea: "textarea",
  consentForms: "consentForms",
  medicalAlerts: "medicalAlerts",
  treatment: "treatment",
  drugTreatment: "drugTreatment",
  treatmentComplete: "treatmentComplete",
  diagnosis: "diagnosis",
  provisionalDiagnosis: "provisionalDiagnosis",
  prescription: "prescription",
  order: "order",
  postOp: "postOp",
  vitals: "vitals",
  vitalsCreateForm: "vitalCreateForm",
  initialExamination: "initialExamination",
  labOrder: "labOrder",
  radiologyOrder: "radiologyOrder",
};

export const defaultStackItem = {
  code: medicalFillInFormStackCodes.entry,
};
