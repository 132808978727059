import _ from "lodash";
import React from "react";
import moment from "moment";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";

import { connect } from "react-redux";
import { TextField } from "../../form/TextField";
import { DateField } from "../../form/DateField";
import { SelectField } from "../../form/SelectField";
import bindActions from "../../../helpers/bindActions";
import DeleteImage from "../../../assets/images/profile/x.png";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { MedicationHintsAutocompleteField } from "../../form/MedicationHintsAutocompleteField";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientMedicationAlertsTable extends React.Component {
  render() {
    const { alerts } = this.props;
    const { patientMedicationAlerts } = this.props.patientRegister;

    let rows = [];

    if (!_.isEmpty(patientMedicationAlerts)) {
      const { units } = patientMedicationAlerts;

      if (alerts) {
        alerts.forEach((element, index) => {
          if (this.props.editMode) {
            rows.push(
              <tr key={index}>
                <td>{moment(element.modifiedDate).format(DATE_FORMAT_LEGACY)}</td>
                <td>
                  <MedicationHintsAutocompleteField name={`alerts.${index}.medicationName`} />
                </td>
                <td>
                  <TextField name={`alerts.${index}.dosage`} type="number" placeholder="500" />
                </td>
                <td>
                  <SelectField
                    name={`alerts.${index}.unit`}
                    placeholder="MILLIGRAM"
                    options={units}
                    valueKey="name"
                    formatLabel={(x) => x.name}
                    formatOptionLabel={(x) => x.name}
                  />
                </td>
                <td>
                  <TextField name={`alerts.${index}.purpose`} placeholder="Arthritis" />
                </td>
                <td>
                  <DateField name={`alerts.${index}.startDate`} />
                </td>
                <td>
                  <DateField name={`alerts.${index}.endDate`} />
                </td>
                <td>
                  <TextField name={`alerts.${index}.frequency`} />
                </td>
                <td>
                  <div
                    className="medication-delete"
                    onClick={() => {
                      this.props.onRemove(index);
                    }}
                  >
                    <img src={DeleteImage} alt="" width={14} height={14} />
                  </div>
                </td>
              </tr>,
            );
          } else {
            rows.push(
              <tr key={index}>
                <td className="date-field">
                  {moment(element.modifiedDate).format(DATE_FORMAT_LEGACY)}
                </td>
                <td className="medication-field">{element.medicationName || ""}</td>
                <td className="dosage-field">{element.dosage || ""}</td>
                <td className="unit-field">{(element.unit && element.unit.code) || ""}</td>
                <td className="purpose-field">{element.purpose || ""}</td>
                <td className="date-field">
                  {element.startDate ? moment(element.startDate).format(DATE_FORMAT_LEGACY) : null}
                </td>
                <td className="date-field">
                  {element.endDate ? moment(element.endDate).format(DATE_FORMAT_LEGACY) : null}
                </td>
                <td className="date-field">{element.frequency || ""}</td>
              </tr>,
            );
          }
        });
      }
    }

    return (
      <table className="patient-medication-alerts-table">
        <thead>
          <tr className="medication-table-caption">
            <th className="date-field">Date Modified</th>
            <th className="medication-field">Medication</th>
            <th className="dosage-field">Dosage</th>
            <th className="unit-field" />
            <th className="purpose-field">Purpose/Comments</th>
            <th className="date-field">Start Date</th>
            <th className="date-field">End Date</th>
            <th className="dosage-field">Frequency</th>
            <th className="delete-field" />
          </tr>
        </thead>
        <tbody>
          {rows}
          <tr>
            <td colSpan="9" className="medication-add-another">
              {this.props.children}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
export default enhancer(PatientMedicationAlertsTable);
