import React from "react";
import FilledImage from "../../widgets/FilledImage";

export default function ChartToothImageBase(props) {
  const { imageNumber, statusCode, toothColor, implantType } = props;

  let src;
  let src0;
  let img;
  let img0;

  if (!imageNumber) {
    img = null;
  } else if (statusCode === "TOOTHSTATUS_NORMAL") {
    src = require(`../../../assets/images/teeth/${imageNumber}_1@2x.png`).default;
    img = <img src={src} className="zoom-2x image-40" style={{ height: "100%" }} alt="" />;
  } else if (statusCode === "TOOTHSTATUS_IMPLANT") {
    if (implantType === "PLANNED") {
      src = require(`../../../assets/images/conditions/implants/red/implants_${imageNumber}@2x_red.png`)
        .default;
    } else if (implantType === "COMPLETED") {
      src = require(`../../../assets/images/conditions/implants/green/implants_${imageNumber}@2x_green.png`)
        .default;
    } else if (implantType === "DIAGNOSIS") {
      src = require(`../../../assets/images/conditions/implants/yellow/implants_${imageNumber}@2x_yellow.png`)
        .default;
    } else if (implantType === "EXISTING") {
      src = require(`../../../assets/images/conditions/implants/blue/implants_${imageNumber}@2x_blue.png`)
        .default;
    } else {
      src = require(`../../../assets/images/conditions/implants/implants_${imageNumber}@2x.png`)
        .default;
    }
    img = <img src={src} className="zoom-2x image-40" style={{ height: "100%" }} alt="" />;
  } else if (statusCode === "TOOTHSTATUS_ABSENT") {
    src = require("../../../assets/images/conditions/absent_tooth@2x.png").default;
    img = (
      <FilledImage
        color={toothColor}
        src={src}
        className="zoom-2x image-40"
        style={{ height: "100%" }}
      />
    );
  } else if (statusCode === "TOOTHSTATUS_BRIDGE") {
    src = require(`../../../assets/images/conditions/bridge/${imageNumber}_1@2x.png`).default;
    img = (
      <FilledImage
        color={toothColor}
        src={src}
        className="zoom-2x image-40"
        style={{ height: "100%" }}
      />
    );

    src0 = require(`../../../assets/images/teeth/${imageNumber}_1@2x.png`).default;
    img0 = <img src={src0} className="zoom-2x image-40" style={{ height: "100%" }} alt="" />;
  } else if (statusCode === "TOOTH_CONDITION_RESIDUAL_ROOT") {
    src = require(`../../../assets/images/conditions/resedual-root/resedual-root_${imageNumber}@2x.png`)
      .default;
    img = <img src={src} className="zoom-2x image-40" style={{ height: "100%" }} alt="" />;
  } else if (statusCode === "TOOTH_CONDITION_DESTROYED_CROWN") {
    src = require(`../../../assets/images/conditions/destroyed/destroyed_${imageNumber}@2x.png`)
      .default;
    img = <img src={src} className="zoom-2x image-40" style={{ height: "100%" }} alt="" />;
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="tooth-under" style={{ height: "100%" }}>
        {img0}
      </div>
      <div className="tooth-above" style={{ height: "100%" }}>
        {img}
      </div>
    </div>
  );
}
