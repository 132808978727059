import React from "react";

import MemberImage from "../widgets/MemberImage";

export default function SelectedMember({ member }) {
  if (!member || !member.id) {
    return <div className="selected-member" />;
  }

  return (
    <div className="selected-member">
      <div className="info">
        <div className="name">{member.firstName || null}</div>
        <div className="surname">{member.lastName || null}</div>
        <div className="speciality">{member.staffPosition || null}</div>
      </div>
      <div className="image">
        <MemberImage photoUrl={member.photoUrl} />
      </div>
    </div>
  );
}
