import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { TimePicker } from "../ui/TimePicker";

const TimePickerWrapper = ({ input, className }) => (
  <TimePicker onChange={input.onChange} defaultValue={input.value} className={className} />
);

TimePickerWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

TimePickerField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export function TimePickerField(props) {
  return <Field {...props} component={TimePickerWrapper} />;
}
