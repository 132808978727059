export const SIDE_PRESCRIPTION_INIT = "SIDE_PRESCRIPTION_INIT";

export const SIDE_PRESCRIPTION_PUSH_STACK = "SIDE_PRESCRIPTION_PUSH_STACK";
export const SIDE_PRESCRIPTION_POP_STACK = "SIDE_PRESCRIPTION_POP_STACK";

export const SIDE_PRESCRIPTION_GET_LIST_START = "SIDE_PRESCRIPTION_GET_LIST_START";
export const SIDE_PRESCRIPTION_GET_LIST_SUCCESS = "SIDE_PRESCRIPTION_GET_LIST_SUCCESS";
export const SIDE_PRESCRIPTION_GET_LIST_ERROR = "SIDE_PRESCRIPTION_GET_LIST_ERROR";

export const SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_START =
  "SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_START";
export const SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_SUCCESS =
  "SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_SUCCESS";
export const SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_ERROR =
  "SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_ERROR";

export const SIDE_PRESCRIPTION_GET_TEMPLATES_START = "SIDE_PRESCRIPTION_GET_TEMPLATES_START";
export const SIDE_PRESCRIPTION_GET_TEMPLATES_SUCCESS = "SIDE_PRESCRIPTION_GET_TEMPLATES_SUCCESS";
export const SIDE_PRESCRIPTION_GET_TEMPLATES_ERROR = "SIDE_PRESCRIPTION_GET_TEMPLATES_ERROR";

export const SIDE_PRESCRIPTION_GET_CATEGORY_LIST_START =
  "SIDE_PRESCRIPTION_GET_CATEGORY_LIST_START";
export const SIDE_PRESCRIPTION_GET_CATEGORY_LIST_SUCCESS =
  "SIDE_PRESCRIPTION_GET_CATEGORY_LIST_SUCCESS";
export const SIDE_PRESCRIPTION_GET_CATEGORY_LIST_ERROR =
  "SIDE_PRESCRIPTION_GET_CATEGORY_LIST_ERROR";

export const SIDE_PRESCRIPTION_GET_FORM_DATA_START = "SIDE_PRESCRIPTION_GET_FORM_DATA_START";
export const SIDE_PRESCRIPTION_GET_FORM_DATA_SUCCESS = "SIDE_PRESCRIPTION_GET_FORM_DATA_SUCCESS";
export const SIDE_PRESCRIPTION_GET_FORM_DATA_ERROR = "SIDE_PRESCRIPTION_GET_FORM_DATA_ERROR";

export const SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_START =
  "SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_START";
export const SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_SUCCESS =
  "SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_SUCCESS";
export const SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_ERROR =
  "SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_ERROR";

export const SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_START =
  "SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_START";
export const SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_SUCCESS =
  "SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_SUCCESS";
export const SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_ERROR =
  "SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_ERROR";

export const SIDE_PRESCRIPTION_SAVE_ITEM_START = "SIDE_PRESCRIPTION_SAVE_ITEM_START";
export const SIDE_PRESCRIPTION_SAVE_ITEM_SUCCESS = "SIDE_PRESCRIPTION_SAVE_ITEM_SUCCESS";
export const SIDE_PRESCRIPTION_SAVE_ITEM_ERROR = "SIDE_PRESCRIPTION_SAVE_ITEM_ERROR";

export const SIDE_PRESCRIPTION_SEND_TO_EMAIL_START = "SIDE_PRESCRIPTION_SEND_TO_EMAIL_START";
export const SIDE_PRESCRIPTION_SEND_TO_EMAIL_SUCCESS = "SIDE_PRESCRIPTION_SEND_TO_EMAIL_SUCCESS";
export const SIDE_PRESCRIPTION_SEND_TO_EMAIL_ERROR = "SIDE_PRESCRIPTION_SEND_TO_EMAIL_ERROR";

export const SIDE_PRESCRIPTION_SELECT_SORT_FIELD = "SIDE_PRESCRIPTION_SELECT_SORT_FIELD";

export const SIDE_PRESCRIPTION_SEARCH_CHANGE = "SIDE_PRESCRIPTION_SEARCH_CHANGE";
export const SIDE_PRESCRIPTION_SEARCH_TEMPLATES_CHANGE =
  "SIDE_PRESCRIPTION_SEARCH_TEMPLATES_CHANGE";

export const SIDE_PRESCRIPTION_ADD_PRESCRIPTION = "SIDE_PRESCRIPTION_ADD_PRESCRIPTION";

export const SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION = "SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION";

export const SIDE_PRESCRIPTION_SYNC_FORM = "SIDE_PRESCRIPTION_SYNC_FORM";

export const SIDE_PRESCRIPTION_DELETE = "SIDE_PRESCRIPTION_DELETE";

export const SIDE_PRESCRIPTION_DIAGNOSIS_SEARCH_CHANGE =
  "SIDE_PRESCRIPTION_DIAGNOSIS_SEARCH_CHANGE";

export const SIDE_PRESCRIPTION_TOGGLE_DIAGNOSIS = "SIDE_PRESCRIPTION_TOGGLE_DIAGNOSIS";
export const SIDE_PRESCRIPTION_SELECT_DIAGNOSIS = "SIDE_PRESCRIPTION_SELECT_DIAGNOSIS";

export const SIDE_PRESCRIPTION_SET_PRIMARY = "SIDE_PRESCRIPTION_SET_PRIMARY";

export const SIDE_PRESCRIPTION_GET_GROUP_LIST_START = "SIDE_PRESCRIPTION_GET_GROUP_LIST_START";
export const SIDE_PRESCRIPTION_GET_GROUP_LIST_SUCCESS = "SIDE_PRESCRIPTION_GET_GROUP_LIST_SUCCESS";
export const SIDE_PRESCRIPTION_GET_GROUP_LIST_ERROR = "SIDE_PRESCRIPTION_GET_GROUP_LIST_ERROR";

export const SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_START =
  "SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_START";
export const SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_SUCCESS =
  "SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_SUCCESS";
export const SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_ERROR =
  "SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_ERROR";
export const SELECT_MEDICATION = "SELECT_MEDICATION";
export const SIDE_PRESCRIPTION_DELETE_ITEM_START = "SIDE_PRESCRIPTION_DELETE_ITEM_START";
export const SIDE_PRESCRIPTION_DELETE_ITEM_SUCCESS = "SIDE_PRESCRIPTION_DELETE_ITEM_SUCCESS";
export const SIDE_PRESCRIPTION_DELETE_ITEM_ERROR = "SIDE_PRESCRIPTION_DELETE_ITEM_ERROR";
