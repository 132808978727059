import { useField } from "formik";
import React, { useMemo } from "react";

import { FieldContainer, FieldContainerProps } from "./FieldContainer";
import {
  DentalPointsSelectWrapper,
  DentalPointsSelectWrapperProps,
} from "../doctor/DentalPointsSelectWrapper";

interface Props extends DentalPointsSelectWrapperProps {
  readonly name: string;
  readonly label?: string;
  readonly fieldProps?: Omit<FieldContainerProps, "label" | "children">;
}

export function DentalPointsSelectField({ name, label, fieldProps, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <DentalPointsSelectWrapper
        {...props}
        {...field}
        onChange={(x, e) => {
          helpers.setValue(x);

          if (props.onChange) {
            props.onChange(x, e);
          }
        }}
      />
    </FieldContainer>
  );
}
