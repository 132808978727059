import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, FormCheck, Row } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import { Button } from "../ui/Button";

import FlexBox from "../ui/FlexBox";
import MedicationDrugForm from "./MedicationDrugForm";
import MedicationCreateDrugForm from "./MedicationCreateDrugForm";
import {
  addDrugs,
  closeDrugModal,
  createItem,
  getDrugList,
  loadDrugList,
  submitDrugForm,
  toggleDrug,
} from "../../actions/medicationActions";
import CustomTooltip from "../CustomTooltip";

const enhancer = connect(({ session: { permissions } }) => ({ permissions }), {
  addDrugs,
  toggleDrug,
  createItem,
  getDrugList,
  loadDrugList,
  closeDrugModal,
  submitDrugForm,
});

const MedicationDrugModal = (props) => {
  const [tab, setTab] = useState("selected");
  // const [tab, setTab] = useState(props.medicalForms ? "selected" : "manually");
  const {
    addDrugs,
    medication,
    createItem,
    toggleDrug,
    patientKey,
    medicalForms,
    closeDrugModal,
    submitDrugForm,
    prescriptionMode,
    permissions,
  } = props;

  let screenZoomSize = Math.round(window.devicePixelRatio * 50);

  const tableWidthZoom = {
    maxHeight:
      screenZoomSize >= 125
        ? "23vh"
        : screenZoomSize >= 110
        ? "35vh"
        : screenZoomSize >= 100
        ? "45vh"
        : "50vh",
    minHeight:
      screenZoomSize >= 125
        ? "23vh"
        : screenZoomSize >= 110
        ? "35vh"
        : screenZoomSize >= 100
        ? "45vh"
        : "50vh",
  };

  useEffect(() => {
    const { getDrugList } = props;
    getDrugList();
  }, []);

  const loadList = ({ target }) => {
    const { loadDrugList } = props;

    const scrollBottom = target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight;

    if (scrollBottom) {
      loadDrugList();
    }
  };

  const textEndDots = (text, maxLimit) => {
    return (text || "").length > maxLimit ? text.substring(0, maxLimit - 3) + "..." : text;
  };

  const addManualPermission = permissions.indexOf("ADD_MEDICATION_MANUALLY") >= 0;

  const $rows = medication.drugList.map((item) => (
    <div
      key={item.id}
      className="default-table-row"
      onClick={() => {
        prescriptionMode ? toggleDrug(item.id, item, prescriptionMode) : toggleDrug(item.id, item);
      }}
    >
      <div className="default-table-col col-1">{item.code}</div>
      <div className="default-table-col col-2" style={{ overflow: "initial" }}>
        <CustomTooltip title={item.name}>
          <div style={{ width: "100%", height: 40 }}>{textEndDots(item.name, 40)}</div>
        </CustomTooltip>
      </div>
      <div className="default-table-col col-8">{_.get(item, "category.name")}</div>
      <div
        className="default-table-col col-4"
        style={{ overflow: "initial" }}
        // title={item.genericName}
      >
        <CustomTooltip title={item.genericName}>
          <div style={{ width: "100%", height: 40 }}>{textEndDots(item.genericName, 50)}</div>
        </CustomTooltip>
      </div>
      <div className="default-table-col col-1" style={{ overflow: "initial" }}>
        <CustomTooltip title={item.strength}>
          <div style={{ width: "100%", height: 40 }}>{textEndDots(item.strength, 20)}</div>
        </CustomTooltip>
      </div>
      <div className="default-table-col col-1" style={{ overflow: "initial" }}>
        <CustomTooltip title={item.dosageForm}>
          <div style={{ width: "100%", height: 40 }}>{textEndDots(item.dosageForm, 20)}</div>
        </CustomTooltip>
      </div>
      <div className="default-table-col col-6" style={{ overflow: "initial" }}>
        <CustomTooltip title={item?.packageSize}>
          <div style={{ width: "100%", height: 40 }}>{textEndDots(item?.packageSize, 20)}</div>
        </CustomTooltip>
      </div>
      <div className="default-table-col col-8">
        <FormCheck checked={medication.checkedDrugMap[item.id]} readOnly={true} />
      </div>
    </div>
  ));

  return (
    <Modal
      size="lg"
      className="select-treatment-to-complete-modal"
      onHide={closeDrugModal}
      show={medication.drugModalVisible}
      title={<div>Medication List</div>}
      scrollable={true}
      actions={
        <div style={{ zIndex: 999999999999999 }}>
          <Button
            onClick={() => {
              if (tab === "selected") {
                addDrugs(true);
              }
              closeDrugModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type={tab === "selected" ? "submit" : "button"}
            onClick={() => {
              if (tab === "selected") {
                addDrugs(false);
                closeDrugModal();
              } else {
                createItem(patientKey);
              }
            }}
          >
            Add
          </Button>
        </div>
      }
    >
      <Row style={{ overflow: "hidden" }}>
        <Col sm={15} style={{ paddingBottom: "10px" }}>
          <div className="d-flex mr-3 ml-3">
            {addManualPermission && (
              <Button
                buttonType={tab === "manually" ? "primary" : "secondary"}
                fluid={true}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => setTab("manually")}
              >
                Add manually
              </Button>
            )}
            <Button
              buttonType={tab === "selected" ? "primary" : "secondary"}
              fluid={true}
              onClick={() => setTab("selected")}
              style={
                addManualPermission ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {}
              }
            >
              Select medicine
            </Button>
          </div>
        </Col>

        <Col sm={16} className="mr-3 ml-3 " style={{ overflow: "hidden" }}>
          {tab === "manually" && <MedicationCreateDrugForm formData={medication.formData} />}

          {tab === "selected" && (
            <FlexBox direction="column">
              <FlexBox>
                <MedicationDrugForm onSubmit={submitDrugForm} />
              </FlexBox>

              <FlexBox>
                <div className="default-table medication-drug-modal-table">
                  <div className="default-table-header">
                    <div className="default-table-col col-1">Code</div>
                    <div className="default-table-col col-2">Medication Name</div>
                    <div className="default-table-col col-8">Category</div>
                    <div className="default-table-col col-4">Generic Name</div>
                    <div className="default-table-col col-1">Strength</div>
                    <div className="default-table-col col-1">Dosage form</div>
                    <div className="default-table-col col-6">Package size</div>
                    <div className="default-table-col col-8">&nbsp;</div>
                  </div>
                  <div className="default-table-body" style={tableWidthZoom} onScroll={loadList}>
                    {$rows}
                  </div>
                </div>
              </FlexBox>
            </FlexBox>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default enhancer(MedicationDrugModal);
