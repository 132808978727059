import _ from "lodash";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      filter: "",
      popupVisible: false,
    };
  }

  handleClick = (e) => {
    _.get(e, "stopPropagation") && e.stopPropagation();
    const { onClose, disabled } = this.props;
    const { popupVisible } = this.state;

    if (!disabled) {
      if (!popupVisible) {
        document.addEventListener("click", this.handleOutsideClick, false);
      } else {
        if (onClose) {
          onClose();
        }

        document.removeEventListener("click", this.handleOutsideClick, false);
      }

      this.setState({
        filter: "",
        popupVisible: !popupVisible,
      });
    }
  };

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  render() {
    const {
      title,
      disabled,
      onSelect,
      className,
      items = [],
      marginTop,
      searchable,
      listClassName,
      wrapperClassName,
      listItemClassName,
      hideBySelect = true,
    } = this.props;
    const { popupVisible } = this.state;

    const list = items.filter(
      ({ title = "" }) => title.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0,
    );

    return (
      <div
        style={
          searchable && popupVisible && !_.isUndefined(marginTop)
            ? _.isBoolean(marginTop)
              ? { marginTop: "16px" }
              : { marginTop }
            : {}
        }
        className={cx("dropdown-button-wrapper", wrapperClassName)}
        ref={(node) => {
          this.node = node;
        }}
      >
        {Boolean(!searchable || !popupVisible) && (
          <button
            className={cx("d-flex align-items-center dropdown-button", className, {
              disabled: disabled,
            })}
            type="button"
            onClick={this.handleClick}
          >
            <span className="d-flex flex-shrink-1 flex-grow-1 title">{title}</span>

            <span className="caret ml-1" />
          </button>
        )}

        {Boolean(searchable && popupVisible) && (
          <div style={{ height: "34px" }}>
            <input
              value={this.state.filter}
              onChange={({ target }) => this.setState({ filter: target.value })}
              autoFocus={true}
              style={{
                borderRadius: "4px",
                border: "1px solid #ccc",
                height: "100%",
                width: "100%",
                padding: "0 6px",
              }}
              className={cx("d-flex dropdown-button", "form-group")}
            />
          </div>
        )}

        {popupVisible && (
          <div className={cx("dropdown-button-list", listClassName)}>
            {list.length === 0 && <div className="dropdown-button-list-item empty-item">Empty</div>}

            {list.length > 0 &&
              list.map((x, idx) => {
                const title = _.isString(x) ? x : x.title;

                return (
                  <div
                    className={cx("dropdown-button-list-item", listItemClassName, {
                      active: x.selected,
                      divider: x.divider,
                    })}
                    key={idx}
                    role="button"
                    onClick={() => {
                      onSelect(x);

                      if (!hideBySelect) {
                        this.handleClick();
                      }
                    }}
                  >
                    {title}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

DropdownButton.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,

  searchable: PropTypes.bool,

  items: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.string,
      PropTypes.shape({
        divider: PropTypes.bool,
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        value: PropTypes.any.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ]),
  ),

  title: PropTypes.string,

  disabled: PropTypes.bool,
  hideBySelect: PropTypes.bool,

  className: PropTypes.string,
  listClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
};

export { DropdownButton };
