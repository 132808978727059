import React from "react";

import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";
import RemindersMainButton from "./RemindersMainButton";
import RemindersTogglePatientAll from "./RemindersTogglePatientAll";
import RemindersToggleCalendarPatient from "./RemindersToggleCalendarPatient";
import RemindersTogglePatientSelected from "./RemindersTogglePatientSelected";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersTogglePatient extends React.Component {
  render() {
    const {
      togglePatientVisible,
      togglePatientMode,
      togglePatientSelected,
      searchPatient,
      fetchingPatientList,
      searchPatientSelected,
      patientList,
      searchCalendarPatient,
      calendarPatientList,
      fetchingCalendarPatientList,
      appointmentTypes,
      doctorSelected,
      calendarFilterValue,
      calendarColumns,
      remindersFilterDropdownVisible,
      remindersFilterDoctorDropdownVisible,
    } = this.props;

    return (
      <Modal
        size="lg"
        className="reminders-choose-patient-modal"
        show={togglePatientVisible}
        title={<div>Choose patient</div>}
        onHide={this.props.remindersActions.togglePatientHide}
        actions={
          <Button className="btn" onClick={this.props.remindersActions.togglePatientHide}>
            Close
          </Button>
        }
      >
        <div>
          <div className="btn-group btn-group-justified mb-1">
            <RemindersMainButton
              onClick={() => {
                this.props.remindersActions.setPatientMode("all");
              }}
              text="All"
              active={togglePatientMode === "all"}
            />
            <RemindersMainButton
              onClick={() => {
                this.props.remindersActions.setPatientMode("calendar");
              }}
              text="On the calendar"
              active={togglePatientMode === "calendar"}
            />
            <RemindersMainButton
              onClick={() => {
                this.props.remindersActions.setPatientMode("selected");
              }}
              text="Selected"
              active={togglePatientMode === "selected"}
            />
          </div>
          <Show if={togglePatientMode === "all"}>
            <RemindersTogglePatientAll
              patientList={patientList}
              fetchingPatientList={fetchingPatientList}
              searchPatient={searchPatient}
              togglePatientSelected={togglePatientSelected}
            />
          </Show>
          <Show if={togglePatientMode === "calendar"}>
            <RemindersToggleCalendarPatient
              remindersFilterDropdownVisible={remindersFilterDropdownVisible}
              remindersFilterDoctorDropdownVisible={remindersFilterDoctorDropdownVisible}
              calendarColumns={calendarColumns}
              appointmentTypes={appointmentTypes}
              doctorSelected={doctorSelected}
              calendarFilterValue={calendarFilterValue}
              togglePatientMode={togglePatientMode}
              calendarPatientList={calendarPatientList}
              togglePatientSelected={togglePatientSelected}
              fetchingCalendarPatientList={fetchingCalendarPatientList}
              searchCalendarPatient={searchCalendarPatient}
            />
          </Show>
          <Show if={togglePatientMode === "selected"}>
            <RemindersTogglePatientSelected
              searchPatient={searchPatient}
              togglePatientSelected={togglePatientSelected}
              searchPatientSelected={searchPatientSelected}
            />
          </Show>
        </div>
      </Modal>
    );
  }
}
export default enhancer(RemindersTogglePatient);
