import React from "react";
import PropTypes from "prop-types";
import activeImage from "../../../../assets/images/chart/favorite_active.png";
import inActiveImage from "../../../../assets/images/chart/favorite.png";

export default function ChartPrescriptionListItemCode(props) {
  const { item, openItem, toggleFavourite } = props;

  const favourites = JSON.parse(localStorage.getItem("PrescriptionFavourites"));

  let favourite = false;
  if (favourites[item.id]) {
    favourite = favourites[item.id].selected;
  }

  const src = favourite ? activeImage : inActiveImage;

  return (
    <div className="list-group-item list-group-item-code">
      <div className="separator">
        <div className="description" onClick={() => openItem(item)}>
          {item.name}
        </div>
      </div>
      <div className="favourite" onClick={() => toggleFavourite(item)}>
        <img alt="" src={src} />
      </div>
    </div>
  );
}
ChartPrescriptionListItemCode.propTypes = {
  item: PropTypes.object,
  openItem: PropTypes.func,
  toggleFavourite: PropTypes.func,
};
