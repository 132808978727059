import React from "react";
import { ButtonGroup } from "../ui/ButtonGroup";
import _ from "lodash";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";

export const SidebarTypes = {
  View: "view",
  Mixed: "mixed",
  Grouped: "procedure",
  Existing: "existing",
  Diagnosis: "diagnosis",
  Treatments: "treatment",
  Transactions: "transactions",
};

export function TypeButtonGroup({ active, onClick, inAppointment, medicalForms, orderStack }) {
  const orderStackItem = _.last(orderStack);
  const isProvisional = orderStackItem?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

  return (
    <div className="chart-treatments-sidebar-groups" style={{ flexShrink: 0 }}>
      <ButtonGroup
        active={active}
        buttons={
          isProvisional
            ? [
                {
                  label: "Diagnosis",
                  value: SidebarTypes.Diagnosis,
                },
              ]
            : [
                {
                  label: "TRX",
                  value: SidebarTypes.Treatments,
                },
                ...(medicalForms
                  ? []
                  : !inAppointment
                  ? [
                      {
                        label: "Diag",
                        value: SidebarTypes.Diagnosis,
                      },
                      {
                        label: "Ex.",
                        value: SidebarTypes.Existing,
                      },
                      {
                        label: "Mix",
                        value: SidebarTypes.Mixed,
                      },
                    ]
                  : []),
                {
                  label: "Pckgs|Groups",
                  value: SidebarTypes.Grouped,
                },
              ]
        }
        onClick={onClick}
      />
    </div>
  );
}
