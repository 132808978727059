import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Utils from "../../../helpers/Utils";
import MemberImage from "../../widgets/MemberImage";
import FlexBox from "../../ui/FlexBox";
import ConditionContactImage from "../../../assets/images/chart/condition_contact-icon.png";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import PatientApi from "../../../api-legacy/PatientApi";

export default function ChartPatient(props) {
  const { patient, count } = props;
  const gender = _.get(patient, "gender.name");
  const [patientInsurance, setPatientInsurance] = useState([]);

  useEffect(() => {
    patient?.patientKey &&
      PatientApi.insurance
        .getList({ patientKey: patient?.patientKey })
        .then((res) => setPatientInsurance(res?.data?.data?.list))
        .catch((err) => console.log(err));
  }, [patient]);
  return (
    <FlexBox
      className="patients-details"
      containerElement={
        <Link
          to={createUrl(Routes.PatientView, {
            params: {
              patientKey: patient.patientKey,
            },
          })}
        />
      }
    >
      <FlexBox className="pr-2">
        <MemberImage size={50} photoUrl={patient.photo && patient.photo.url} />
      </FlexBox>
      <FlexBox flex={true} className="info" direction="column">
        <FlexBox className="name" justify={"space-between"}>
          {Utils.getFullName(patient)} <span>{patient?.vipPatient && "VIP"}</span>
        </FlexBox>
        <FlexBox>
          <FlexBox style={{ marginRight: "12px" }}>{patient.chartNumber}</FlexBox>
          {gender && (
            <FlexBox style={{ marginBottom: 6 }}>
              ({gender}) (age: {Utils.getAge(patient?.dateOfBirth)}) (
              {(patientInsurance || []).find((item) => item?.type?.code === "PRIMARY")
                ?.insurancePlan?.name || "n/a"}
              )
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox style={{ position: "relative" }}>
          <FlexBox flex={true}>Follow Up</FlexBox>
          <FlexBox
            style={{
              fontSize: "22px",
              position: "absolute",
              right: "56px",
              bottom: 0,
            }}
          >
            {count > 0 && count}
          </FlexBox>
          <FlexBox
            style={{
              position: "absolute",
              right: "17px",
              bottom: "4px",
            }}
          >
            <img src={ConditionContactImage} className="img-responsive" alt="" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
