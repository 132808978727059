export const BLOCKED_TIME_SLOT_GET_LIST_START = "BLOCKED_TIME_SLOT_GET_LIST_START";
export const BLOCKED_TIME_SLOT_GET_LIST_SUCCESS = "BLOCKED_TIME_SLOT_GET_LIST_SUCCESS";
export const BLOCKED_TIME_SLOT_GET_LIST_ERROR = "BLOCKED_TIME_SLOT_GET_LIST_ERROR";

export const BLOCKED_TIME_SLOT_SET_MODE = "BLOCKED_TIME_SLOT_SET_MODE";

export const BLOCKED_TIME_SLOT_SET_EDIT_INDEX = "BLOCKED_TIME_SLOT_SET_EDIT_INDEX";

export const BLOCKED_TIME_SLOT_RESET_DOCTORS = "BLOCKED_TIME_SLOT_RESET_DOCTORS";

export const BLOCKED_TIME_SLOT_SET_CUSTOM_PATTERN = "BLOCKED_TIME_SLOT_SET_CUSTOM_PATTERN";

export const BLOCKED_TIME_SLOT_GET_DENTIST_START = "BLOCKED_TIME_SLOT_GET_DENTIST_START";
export const BLOCKED_TIME_SLOT_GET_DENTIST_SUCCESS = "BLOCKED_TIME_SLOT_GET_DENTIST_SUCCESS";
export const BLOCKED_TIME_SLOT_GET_DENTIST_ERROR = "BLOCKED_TIME_SLOT_GET_DENTIST_ERROR";

export const BLOCKED_TIME_SLOT_CHOOSE_DOCTOR = "BLOCKED_TIME_SLOT_CHOOSE_DOCTOR";

export const BLOCKED_TIME_SLOT_SET_FROM_TIME = "BLOCKED_TIME_SLOT_SET_FROM_TIME";
export const BLOCKED_TIME_SLOT_SET_TO_TIME = "BLOCKED_TIME_SLOT_SET_TO_TIME";

export const BLOCKED_TIME_SLOT_SET_FROM_DATE = "BLOCKED_TIME_SLOT_SET_FROM_DATE";
export const BLOCKED_TIME_SLOT_SET_TO_DATE = "BLOCKED_TIME_SLOT_SET_TO_DATE";

export const BLOCKED_TIME_SLOT_INIT = "BLOCKED_TIME_SLOT_INIT";
export const BLOCKED_TIME_SLOT_ITEM_INIT = "BLOCKED_TIME_SLOT_ITEM_INIT";

export const BLOCKED_TIME_SLOT_GET_WEEK_DAYS_START = "BLOCKED_TIME_SLOT_GET_WEEK_DAYS_START";
export const BLOCKED_TIME_SLOT_GET_WEEK_DAYS_SUCCESS = "BLOCKED_TIME_SLOT_GET_WEEK_DAYS_SUCCESS";
export const BLOCKED_TIME_SLOT_GET_WEEK_DAYS_ERROR = "BLOCKED_TIME_SLOT_GET_WEEK_DAYS_ERROR";

export const BLOCKED_TIME_SLOT_ADD_ITEM_START = "BLOCKED_TIME_SLOT_ADD_ITEM_START";
export const BLOCKED_TIME_SLOT_ADD_ITEM_SUCCESS = "BLOCKED_TIME_SLOT_ADD_ITEM_SUCCESS";
export const BLOCKED_TIME_SLOT_ADD_ITEM_ERROR = "BLOCKED_TIME_SLOT_ADD_ITEM_ERROR";

export const BLOCKED_TIME_SLOT_GET_ITEM_START = "BLOCKED_TIME_SLOT_GET_ITEM_START";
export const BLOCKED_TIME_SLOT_GET_ITEM_SUCCESS = "BLOCKED_TIME_SLOT_GET_ITEM_SUCCESS";
export const BLOCKED_TIME_SLOT_GET_ITEM_ERROR = "BLOCKED_TIME_SLOT_GET_ITEM_ERROR";

export const BLOCKED_TIME_SLOT_EDIT_ITEM_START = "BLOCKED_TIME_SLOT_EDIT_ITEM_START";
export const BLOCKED_TIME_SLOT_EDIT_ITEM_SUCCESS = "BLOCKED_TIME_SLOT_EDIT_ITEM_SUCCESS";
export const BLOCKED_TIME_SLOT_EDIT_ITEM_ERROR = "BLOCKED_TIME_SLOT_EDIT_ITEM_ERROR";

export const BLOCKED_TIME_SLOT_DELETE_ITEM_START = "BLOCKED_TIME_SLOT_DELETE_ITEM_START";
export const BLOCKED_TIME_SLOT_DELETE_ITEM_SUCCESS = "BLOCKED_TIME_SLOT_DELETE_ITEM_SUCCESS";
export const BLOCKED_TIME_SLOT_DELETE_ITEM_ERROR = "BLOCKED_TIME_SLOT_DELETE_ITEM_ERROR";

export const BLOCKED_TIME_SLOT_GET_DENTAL_POINT_START = "BLOCKED_TIME_SLOT_GET_DENTAL_POINT_START";
export const BLOCKED_TIME_SLOT_GET_DENTAL_POINT_SUCCESS =
  "BLOCKED_TIME_SLOT_GET_DENTAL_POINT_SUCCESS";
export const BLOCKED_TIME_SLOT_GET_DENTAL_POINT_ERROR = "BLOCKED_TIME_SLOT_GET_DENTAL_POINT_ERROR";

export const BLOCKED_TIME_SLOT_SET_FILTER_KEY = "BLOCKED_TIME_SLOT_SET_FILTER_KEY";

export const BLOCKED_TIME_SLOT_CLEAR_FILTER_KEY = "BLOCKED_TIME_SLOT_CLEAR_FILTER_KEY";

export const BLOCKED_TIME_SLOT_SET_DOCTORS_EDIT = "BLOCKED_TIME_SLOT_SET_DOCTORS_EDIT";

export const BLOCKED_TIME_SLOT_CONFIRM_MODAL_SHOW = "BLOCKED_TIME_SLOT_CONFIRM_MODAL_SHOW";
export const BLOCKED_TIME_SLOT_CONFIRM_MODAL_HIDE = "BLOCKED_TIME_SLOT_CONFIRM_MODAL_HIDE";

export const BLOCKED_TIME_SLOT_FORM_DATA_START = "BLOCKED_TIME_SLOT_FORM_DATA_START";
export const BLOCKED_TIME_SLOT_FORM_DATA_SUCCESS = "BLOCKED_TIME_SLOT_FORM_DATA_SUCCESS";
export const BLOCKED_TIME_SLOT_FORM_DATA_ERROR = "BLOCKED_TIME_SLOT_FORM_DATA_ERROR";

export const BLOCKED_TIME_SLOT_REMOVE_UNTIL_DATE = "BLOCKED_TIME_SLOT_REMOVE_UNTIL_DATE";

export const BLOCKED_TIME_SLOT_SET_UNTIL_DATE = "BLOCKED_TIME_SLOT_SET_UNTIL_DATE";

export const BLOCKED_TIME_SLOT_ADD_EXCLUDED_DATE = "BLOCKED_TIME_SLOT_ADD_EXCLUDED_DATE";
export const BLOCKED_TIME_SLOT_REMOVE_EXCLUDED_DATE = "BLOCKED_TIME_SLOT_REMOVE_EXCLUDED_DATE";

export const GET_MEMBERS_KEYWORDS_START = "GET_MEMBERS_KEYWORDS_START";
export const GET_MEMBERS_KEYWORDS_SUCCESS = "GET_MEMBERS_KEYWORDS_SUCCESS";
export const GET_MEMBERS_KEYWORDS_ERROR = "GET_MEMBERS_KEYWORDS_ERROR";

export const GET_BLOCKED_TEMPLATES_LIST_START = "GET_BLOCKED_TEMPLATES_LIST_START";
export const GET_BLOCKED_TEMPLATES_LIST_SUCCESS = "GET_BLOCKED_TEMPLATES_LIST_SUCCESS";
export const GET_BLOCKED_TEMPLATES_LIST_ERROR = "GET_BLOCKED_TEMPLATES_LIST_ERROR";

export const GET_BLOCKED_TEMPLATES_ITEM_START = "GET_BLOCKED_TEMPLATES_ITEM_START";
export const GET_BLOCKED_TEMPLATES_ITEM_SUCCESS = "GET_BLOCKED_TEMPLATES_ITEM_SUCCESS";
export const GET_BLOCKED_TEMPLATES_ITEM_ERROR = "GET_BLOCKED_TEMPLATES_ITEM_ERROR";

export const MEMBERS_KEYWORDS_SELECT = "MEMBERS_KEYWORDS_SELECT";

export const GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_START =
  "GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_START";
export const GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_SUCCESS =
  "GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_SUCCESS";
export const GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_ERROR =
  "GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_ERROR";

export const SET_BLOCKED_TIME_SLOT_COLORS = "SET_BLOCKED_TIME_SLOT_COLORS";
