import React, { useEffect, useState } from "react";
import { copyToClipboardAppointment } from "../../actions/calendarActions";
import { useDispatch } from "react-redux";
import Show from "../widgets/Show";

const CalendarColumnContextMenu = ({
  contextMenuDetails,
  setContextMenuDetails,
  createAppointment,
  createBlockedTimeSlot,
  moveToWaitingPanelHandler,
}) => {
  const [menuHeight, setMenuHeight] = useState(0);
  const {
    visible,
    top,
    left,
    innerWidth,
    innerHeight,
    clientX,
    clientY,
    point,
    mode,
    parentEvent,
    paste,
    createAppointmentOnPoint,
    editBlockedTimeSlot,
  } = contextMenuDetails;
  const menuWidth = 200;
  const right = innerWidth < left + menuWidth;
  const bottom = innerHeight < top + menuHeight;
  const dispatch = useDispatch();
  useEffect(() => {
    if (visible) {
      const elementHeight = document.getElementById("context-menu").clientHeight;
      setMenuHeight(elementHeight);
    }
  }, [visible]);

  const copyMoveAppointment = (point, copyMode) => {
    dispatch(copyToClipboardAppointment(point, copyMode));
  };

  if (visible)
    return (
      <div
        id="context-menu"
        style={{
          display: "flex",
          alignItems: "stretch",
          flexDirection: "column",
          width: `${menuWidth}px`,
          height: "auto",
          position: "absolute",
          backgroundColor: "#000000",
          zIndex: 100000,
          top: bottom ? "unset" : top,
          left: right ? "unset" : left,
          right: right ? `calc(${-innerWidth}px - ${-clientX}px)` : "unset",
          bottom: bottom ? `calc(${-innerHeight}px - ${-clientY}px)` : "unset",
          boxShadow: "rgb(0 0 0 / 13%) 0px 0px 5px",
          padding: "1rem",
        }}
      >
        {typeof editBlockedTimeSlot === "function" ? (
          <div
            className="context-menu-item"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setContextMenuDetails({ visible: false });
              editBlockedTimeSlot();
            }}
          >
            Delete Blocked Time Slot
          </div>
        ) : (
          <>
            <div
              className="context-menu-item"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (typeof createAppointmentOnPoint === "function") {
                  createAppointmentOnPoint();
                } else {
                  createAppointment(parentEvent);
                }
                setContextMenuDetails({ visible: false });
              }}
            >
              Create Appointment
            </div>
            {point && (
              <>
                <div
                  className="context-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyMoveAppointment(point, "copy");
                    setContextMenuDetails({ visible: false, mode: "copy" });
                  }}
                >
                  Copy Appointment
                </div>
                <div
                  className="context-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyMoveAppointment(point, "move");
                    setContextMenuDetails({ visible: false, mode: "move" });
                  }}
                >
                  Move Appointment
                </div>
                <div
                  className="context-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    moveToWaitingPanelHandler(point);
                    setContextMenuDetails({ visible: false, mode: "move" });
                  }}
                >
                  Move to Waiting panel
                </div>
              </>
            )}
            <Show if={mode === "copy" || mode === "move"}>
              <div
                className="context-menu-item"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  paste(parentEvent);
                  setContextMenuDetails({ visible: false });
                }}
              >
                Paste Appointment
              </div>
            </Show>
            <div
              className="context-menu-item"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                createBlockedTimeSlot(parentEvent);
                setContextMenuDetails({ visible: false });
              }}
            >
              Create Blocked Time Slot
            </div>
          </>
        )}

        <div
          className="context-menu-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenuDetails({ visible: false });
          }}
        >
          Cancel
        </div>
      </div>
    );
  return null;
};

export default CalendarColumnContextMenu;
