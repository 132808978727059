import _ from "lodash";
import InsuranceApi from "../api-legacy/InsuranceApi";
import * as ActionType from "../actionTypes/insuranceActionTypes";

import { showMessage, startLoader, stopLoader } from "./loaderActions";

export function getDeductibleTypes() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getDeductibleTypesApi())
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function showEditDialog(showEditDialog) {
  return {
    type: ActionType.INSURANCE_SHOW_EDIT_DIALOG,
    showEditDialog,
  };
}

export function hideEditDialog() {
  return {
    type: ActionType.INSURANCE_HIDE_EDIT_DIALOG,
  };
}

export function saveInsurancePlanItems() {
  return (dispatch, getState) => {
    const {
      form: { EditInsurancePlanForm },
      insurance: { insurancePlanItems = {} },
    } = getState();

    const values = _.get(EditInsurancePlanForm, "values");

    dispatch(startLoader());

    let data = insurancePlanItems;

    if (values) {
      data = {
        ...data,
        ...values,
      };
    }

    return dispatch(
      saveInsuranceItemApi({
        ...data,
        planRows: (data.planRows || []).map(({ coPay, coverage, ...item }) => ({
          ...item,
          deductibleType: { id: item?.deductibleType?.value, name: item?.deductibleType?.label },
          coPay: coPay && coPay !== "" ? _.toFinite(coPay) : undefined,
          coverage: coverage && coverage !== "" ? _.toFinite(coverage) : undefined,
        })),
      }),
    )
      .then(() => {
        dispatch(stopLoader());
        dispatch(hideEditDialog());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function switchActivateInsurance(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(switchActivateInsuranceApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getInsuranceList(data));
        dispatch(getInsurancePlanItem({ companyPatientInsuranceId: data.id }));
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function getInsurancePlanItem(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getInsuranceItemApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function insurancePhotoSidebarPrevImage() {
  return (dispatch, getState) => {
    const {
      insurance: { photoSidebarImageIndex },
    } = getState();

    let index = photoSidebarImageIndex.slice(5);

    if (index > 1) index--;
    else return;

    return dispatch({
      type: ActionType.INSURANCE_PHOTO_SIDEBAR_SET_IMAGE,
      index: `image${index}`,
    });
  };
}

export function insurancePhotoSidebarNextImage() {
  return (dispatch, getState) => {
    const {
      insurance: { photoSidebarImageIndex },
    } = getState();

    let index = photoSidebarImageIndex.slice(5);

    if (index < 3) index++;
    else return;

    return dispatch({
      type: ActionType.INSURANCE_PHOTO_SIDEBAR_SET_IMAGE,
      index: `image${index}`,
    });
  };
}

export function insurancePhotoSidebarShow(index) {
  return {
    type: ActionType.INSURANCE_PHOTO_SIDEBAR_SHOW,
    index,
  };
}

export function insurancePhotoSidebarHide() {
  return {
    type: ActionType.INSURANCE_PHOTO_SIDEBAR_HIDE,
  };
}

export function initInsuranceForm(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.INIT_INSURANCE_FORM_FIELDS,
    });

    dispatch(startLoader());
    const requests = [];
    requests.push(dispatch(getCompanyFn()));
    requests.push(dispatch(getRelationFn()));
    requests.push(dispatch(getInsuranceAliasList()));
    if (data) {
      requests.push(dispatch(getCompanyPlanFn(data)));
      requests.push(dispatch(getBrokerFn(data)));
    }
    return Promise.all(requests)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

//----------------------
export function uploadPhoto(files) {
  return (dispatch, getState) => {
    const {
      patientView,
      patient: {
        patientDetails: { patientKey },
      },
    } = getState();

    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file, file.name);
      data.append("patientKey", patientKey || patientView.patientKey);
    });

    dispatch(startLoader());

    dispatch(uploadPhotoFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function hideChoosePhotoModal() {
  return {
    type: ActionType.INSURANCE_IMAGE_HIDE_CHOOSE_PHOTO_MODAL,
  };
}
export function showChoosePhotoModal(form) {
  return {
    type: ActionType.INSURANCE_IMAGE_SHOW_CHOOSE_PHOTO_MODAL,
    form,
  };
}
export function deleteImage(image) {
  return {
    type: ActionType.INSURANCE_IMAGE_DELETE_IMAGE,
    image,
  };
}
export function selectImage(image) {
  return {
    type: ActionType.INSURANCE_IMAGE_SELECT_IMAGE,
    image,
  };
}
//-----------------------------------
export function getInsuranceList(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.GET_INSURANCE_LIST,
    });
    dispatch(startLoader());

    // const {patient} = getState();

    return dispatch(getInsuranceListFn(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getReImbursementList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getReImbursementList(data) {
  return function (dispatch) {
    return dispatch(getReImbursementListFn(data)).catch((error) => dispatch(showMessage(error)));
  };
}
export function getPaymentOptions(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.GET_PATIENT_PAYMENT_OPTIONS,
    });

    return dispatch(paymentInsuranceFn(data));
  };
}
export function setInsuranceList(data) {
  return {
    type: ActionType.SET_INSURANCE_LIST,
    data,
  };
}

// / --------------------
export function getInsuranceItem(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.GET_INSURANCE_ITEM,
    });
    dispatch(startLoader());

    return dispatch(getInsuranceItemFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getOtherFieldsBySelectedCompany(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.INSURANCE_FORM_FIELDS_BY_COMPANY,
    });

    dispatch(startLoader());

    return Promise.all([
      dispatch(getCompanyPlanFn(data)),
      dispatch(getBrokerFn(data)),
      dispatch(getSelfPayerApi(data)),
    ])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getCompanyPlan(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.GET_INSURANCE_COMPANY_PLAN,
    });

    dispatch(startLoader());

    return dispatch(getCompanyPlanFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function saveInsuranceItem(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.SAVE_INSURANCE_ITEM,
    });

    dispatch(startLoader());

    return dispatch(saveInsuranceItemFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(stopLoader(error));

        throw new Error(error);
      });
  };
}
export function deleteInsuranceItem(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.SAVE_INSURANCE_ITEM,
    });

    dispatch(startLoader());

    return dispatch(deleteInsuranceItemFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function searchByCardNumber(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.SEARCH_BY_CARD_NUMBER,
    });

    dispatch(startLoader());

    return dispatch(searchByCardNumberFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function markAsPrimary(data) {
  return function (dispatch) {
    dispatch({
      type: ActionType.MARK_AS_PRIMARY_INSURANCE,
    });

    dispatch(startLoader());

    return dispatch(markAsPrimaryFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getInsuranceAliasList(data) {
  return {
    api: InsuranceApi.getInsuranceAliasList,
    types: [
      ActionType.GET_INSURANCE_ALIAS_LIST_START,
      ActionType.GET_INSURANCE_ALIAS_LIST_SUCCESS,
      ActionType.GET_INSURANCE_ALIAS_LIST_ERROR,
    ],
    data,
  };
}

function uploadPhotoFn(data) {
  return {
    api: InsuranceApi.uploadPhoto,
    types: [
      ActionType.INSURANCE_UPLOAD_PHOTO_START,
      ActionType.INSURANCE_UPLOAD_PHOTO_SUCCESS,
      ActionType.INSURANCE_UPLOAD_PHOTO_ERROR,
    ],
    data,
  };
}

function getSelfPayerApi(data) {
  return {
    api: InsuranceApi.getFields.getSelfPayer,
    types: [
      ActionType.INSURANCE_GET_SELF_PAYER_START,
      ActionType.INSURANCE_GET_SELF_PAYER_SUCCESS,
      ActionType.INSURANCE_GET_SELF_PAYER_ERROR,
    ],
    data,
  };
}

function getInsuranceListFn(data) {
  return {
    api: InsuranceApi.getList,
    types: [
      ActionType.GET_INSURANCE_LIST_START,
      ActionType.GET_INSURANCE_LIST_SUCCESS,
      ActionType.GET_INSURANCE_LIST_ERROR,
    ],
    data,
  };
}

function getReImbursementListFn(data) {
  return {
    api: InsuranceApi.getReImbursementList,
    types: [
      ActionType.GET_RE_IMBURSEMENT_LIST_START,
      ActionType.GET_RE_IMBURSEMENT_LIST_SUCCESS,
      ActionType.GET_RE_IMBURSEMENT_LIST_ERROR,
    ],
    data,
  };
}

function paymentInsuranceFn(data) {
  return {
    api: InsuranceApi.paymentOptions,
    types: [
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_START,
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_SUCCESS,
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_ERROR,
    ],
    data,
  };
}
function getInsuranceItemFn(data) {
  return {
    api: InsuranceApi.getItem,
    types: [
      ActionType.GET_INSURANCE_ITEM_START,
      ActionType.GET_INSURANCE_ITEM_SUCCESS,
      ActionType.GET_INSURANCE_ITEM_ERROR,
    ],
    data,
  };
}
function saveInsuranceItemFn(data) {
  return {
    api: InsuranceApi.saveItem,
    types: [
      ActionType.SAVE_INSURANCE_ITEM_START,
      ActionType.SAVE_INSURANCE_ITEM_SUCCESS,
      ActionType.SAVE_INSURANCE_ITEM_ERROR,
    ],
    data,
  };
}

function deleteInsuranceItemFn(data) {
  return {
    api: InsuranceApi.deleteItem,
    types: [
      ActionType.DELETE_INSURANCE_ITEM_START,
      ActionType.DELETE_INSURANCE_ITEM_SUCCESS,
      ActionType.DELETE_INSURANCE_ITEM_ERROR,
    ],
    data,
  };
}

function getCompanyFn(data) {
  return {
    api: InsuranceApi.getFields.getCompany,
    types: [
      ActionType.GET_INSURANCE_COMPANY_START,
      ActionType.GET_INSURANCE_COMPANY_SUCCESS,
      ActionType.GET_INSURANCE_COMPANY_ERROR,
    ],
    data,
  };
}
function getCompanyPlanFn(data) {
  return {
    api: InsuranceApi.getFields.getCompanyPlan,
    types: [
      ActionType.GET_INSURANCE_COMPANY_PLAN_START,
      ActionType.GET_INSURANCE_COMPANY_PLAN_SUCCESS,
      ActionType.GET_INSURANCE_COMPANY_PLAN_ERROR,
    ],
    data,
  };
}
function getRelationFn(data) {
  return {
    api: InsuranceApi.getFields.getRelation,
    types: [
      ActionType.GET_INSURANCE_RELATION_START,
      ActionType.GET_INSURANCE_RELATION_SUCCESS,
      ActionType.GET_INSURANCE_RELATION_ERROR,
    ],
    data,
  };
}
function getBrokerFn(data) {
  return {
    api: InsuranceApi.getFields.getBroker,
    types: [
      ActionType.GET_INSURANCE_BROKER_START,
      ActionType.GET_INSURANCE_BROKER_SUCCESS,
      ActionType.GET_INSURANCE_BROKER_ERROR,
    ],
    data,
  };
}
function searchByCardNumberFn(data) {
  return {
    api: InsuranceApi.getFields.getSeachByCardNumber,
    types: [
      ActionType.SEARCH_BY_CARD_NUMBER_START,
      ActionType.SEARCH_BY_CARD_NUMBER_SUCCESS,
      ActionType.SEARCH_BY_CARD_NUMBER_ERROR,
    ],
    data,
  };
}
function markAsPrimaryFn(data) {
  return {
    api: InsuranceApi.markAsPrimary,
    types: [
      ActionType.MARK_AS_PRIMARY_INSURANCE_START,
      ActionType.MARK_AS_PRIMARY_INSURANCE_SUCCESS,
      ActionType.MARK_AS_PRIMARY_INSURANCE_ERROR,
    ],
    data,
  };
}

function getDeductibleTypesApi(data) {
  return {
    api: InsuranceApi.getDeductibleTypes,
    types: [
      ActionType.INSURANCE_GET_DEDUCTIBLE_TYPES_START,
      ActionType.INSURANCE_GET_DEDUCTIBLE_TYPES_SUCCESS,
      ActionType.INSURANCE_GET_DEDUCTIBLE_TYPES_ERROR,
    ],
    data,
  };
}

function getInsuranceItemApi(data) {
  return {
    api: InsuranceApi.getInsuranceItem,
    types: [
      ActionType.INSURANCE_GET_ITEM_START,
      ActionType.INSURANCE_GET_ITEM_SUCCESS,
      ActionType.INSURANCE_GET_ITEM_ERROR,
    ],
    data,
  };
}

function saveInsuranceItemApi(data) {
  return {
    api: InsuranceApi.saveInsuranceItem,
    types: [
      ActionType.INSURANCE_SAVE_ITEM_START,
      ActionType.INSURANCE_SAVE_ITEM_SUCCESS,
      ActionType.INSURANCE_SAVE_ITEM_ERROR,
    ],
    data,
  };
}

function switchActivateInsuranceApi(data) {
  return {
    api: InsuranceApi.switchActivateInsurance,
    types: [
      ActionType.INSURANCE_SWITCH_ACTIVATE_START,
      ActionType.INSURANCE_SWITCH_ACTIVATE_SUCCESS,
      ActionType.INSURANCE_SWITCH_ACTIVATE_ERROR,
    ],
    data,
  };
}
