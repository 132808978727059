import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { applyMiddleware, combineReducers, createStore } from "redux";

import reducers from "./reducers";
import apiMiddleware from "../middlewares/apiMiddleware";
import formReducerPlugin from "../helpers/formReducerPlugin";
import loggerMiddleware from "../middlewares/loggerMiddleware";
import { IS_DEV } from "../constants/AppConstants";

export default createStore(
  combineReducers({
    ...reducers,

    form: formReducer.plugin(formReducerPlugin),
  }),
  applyMiddleware(
    ...[apiMiddleware, thunkMiddleware, IS_DEV ? loggerMiddleware : undefined].filter(Boolean),
  ),
);
