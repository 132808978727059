import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { update } from "immupdate";
import { connect } from "react-redux";
import { isArray, isEqual, toFinite } from "lodash";

import { AppLayout } from "../app-layout/AppLayout";
import { BringEarlierTable } from "./BringEarlierTable";
import { selectDoctor } from "../../actions/authActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import  BringEarlierFilterForm  from "./BringEarlierFilterForm";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { getEarlierAppointmentListApi } from "../../actions/appointmentActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";
import { Routes } from "../../constants/Routes";

const enhancer = connect(({ auth }) => ({
  doctorIds: auth.doctors.filter((x) => x.active).map((x) => x.id),
}));

export const BringEarlier = enhancer(
  class BringEarlier extends React.PureComponent {
    propTypes = {
      dispatch: PropTypes.func,
      history: PropTypes.object,
      location: PropTypes.object,
      doctorIds: PropTypes.arrayOf(PropTypes.number),
    };

    constructor(props) {
      super(props);

      const query = parseQuery(props.location.search);

      const toDate = toFinite(query.toDate);
      const fromDate = toFinite(query.fromDate);

      this.state = {
        list: [],
        totalCount: 0,
        filter: {
          toDate: toDate > 0 ? moment(toDate) : moment(),
          fromDate: fromDate > 0 ? moment(fromDate) : moment(),
        },
      };
    }

    componentDidMount() {
      const { dispatch, location } = this.props;

      const query = parseQuery(location.search);

      const doctorIds = query.doctorIds || [];

      if (isArray(doctorIds)) {
        doctorIds.forEach((x) => {
          dispatch(selectDoctor(toFinite(x)));
        });
      } else {
        dispatch(selectDoctor(toFinite(doctorIds)));
      }

      this.getList();
    }

    componentDidUpdate(prevProps) {
      const { filter } = this.state;

      const { doctorIds: prevDoctorIds } = prevProps;
      const { history, location, doctorIds: nextDoctorIds } = this.props;

      if (!isEqual(prevDoctorIds, nextDoctorIds)) {
        history.replace(
          createUrl(location.pathname, {
            query: {
              doctorIds: nextDoctorIds,
              toDate: getCompanyEndOfDay(filter.toDate),
              fromDate: getCompanyStartOfDay(filter.fromDate),
            },
          }),
        );

        this.getList({
          ...filter,
          doctorIds: nextDoctorIds,
        });
      }
    }

    getList = (newFilter, load) => {
      const { list = [], filter } = this.state;
      const { dispatch, location } = this.props;

      const query = parseQuery(location.search);

      dispatch(startLoader());

      const doctorIds =
        newFilter && newFilter.doctorIds ? newFilter.doctorIds : query.doctorIds || [];
      const toDate = newFilter && newFilter.toDate ? newFilter.toDate : filter.toDate;
      const fromDate = newFilter && newFilter.fromDate ? newFilter.fromDate : filter.fromDate;

      dispatch(
        getEarlierAppointmentListApi({
          limit: 20,
          start: load ? list.length : 0,
          periodEnd: getCompanyEndOfDay(toDate),
          periodStart: getCompanyStartOfDay(fromDate),
          doctorIds: isArray(doctorIds) ? doctorIds.join(",") : doctorIds,
        }),
      )
        .then((response) => {
          this.setState((prev) =>
            update(prev, {
              totalCount: toFinite(response.totalCount),
              list: load ? prev.list.concat(response.list || []) : response.list,
            }),
          );
          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    };

    goBack = () => {
      const { history } = this.props;

      history.goBack();
    };

    onMove = (id) => {
      const { history } = this.props;

      history.push(
        createUrl(Routes.DashboardAppointment, {
          params: { id },
          query: { disableEarlier: true, mode: "earlier" },
        }),
      );
    };

    onChangeFilter = (x) => {
      const { history, location, doctorIds } = this.props;

      history.replace(
        createUrl(location.pathname, {
          query: {
            doctorIds,
            toDate: getCompanyEndOfDay(x.toDate),
            fromDate: getCompanyStartOfDay(x.fromDate),
          },
        }),
      );

      this.setState({ filter: x });
      this.getList(x);
    };

    onScroll = () => {
      const { totalCount, list = [] } = this.state;

      if (list.length < totalCount) {
        this.getList(undefined, true);
      }
    };

    render() {
      const { list = [], filter } = this.state;
      const { history, location } = this.props;

      return (
        <AppLayout
          scrollable={true}
          contentClassName="p-0"
          onBackClick={() => this.goBack()}
          onScrollEnd={() => this.onScroll()}
          rightComponent={
            <BringEarlierFilterForm initialValues={filter} onChange={this.onChangeFilter} />
          }
        >
          <BringEarlierTable
            list={list}
            onMoveClick={this.onMove}
            history={history}
            location={location}
          />
        </AppLayout>
      );
    }
  },
);
