import React from "react";
import { Field } from "redux-form";

import { FieldContainer } from "../../form/FieldContainer";
import { PhoneNumberWrapper } from "../../ui/PhoneNumberWrapper";

const PhoneInput = ({ input, label, style }) => (
  <FieldContainer label={label} style={style}>
    <PhoneNumberWrapper {...input} />
  </FieldContainer>
);

export function ReduxPhoneNumber({ label, style, ...props }) {
  return <Field {...props} label={label} style={style} component={PhoneInput} />;
}
