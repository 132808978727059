import React from "react";
import { connect } from "react-redux";

import Back from "../../widgets/Back";
import ChartPostOperationForm from "./ChartPostOperationForm";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartPostOperation extends React.Component {
  componentDidMount() {
    this.props.chartSideSeriesActions.initPostOperation();
  }

  render() {
    const { postOperation } = this.props.chartSideSeries;
    const { chartNumber } = this.props.patient;

    const initialValues = {
      title: postOperation.template && postOperation.template.name,
      content: postOperation.content && postOperation.content.split("<br />").join("\n"),
    };

    return (
      <div className="default-page">
        <div className="default-page-title">
          <Back
            className="pull-left"
            onClick={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}
          />
          <div className="pull-right title">CHART #{chartNumber}</div>
          <div className="title text-center">CREATE POST OP INSTRUCTIONS</div>
        </div>
        <div className="default-page-body">
          <ChartPostOperationForm
            onSubmit={this.props.chartSideSeriesActions.submitPostOperation}
            initialValues={initialValues}
            enableReinitialize={true}
            keepDirtyOnReinitialize={true}
            chartSideSeriesActions={this.props.chartSideSeriesActions}
          />
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOperation);
