import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";

// import RemindersMainButton from "./RemindersMainButton";
import RemindersAutomaticMode from "./RemindersAutomaticMode";
import RemindersManualMode from "./RemindersManualMode";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import { openSimpleModal, closeSimpleModal } from "../../actions/simpleModalActions";
import { getAppointmentStatusList } from "../../actions/appointmentActions";
import InfoModal from "../ui/InfoModal";

const enhancer = connect(({ reminders, appointment, calendar }) => {
  return {
    reminders,
    appointment,
    calendar,
  };
}, bindActions({ remindersActions, getAppointmentStatusList, openSimpleModal, closeSimpleModal }));
class Reminders extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.remindersActions.resetReminders();
  }

  componentDidMount() {
    this.props.getAppointmentStatusList();
    this.checkBalance();
  }

  checkBalance = () => {
    this.props.remindersActions.checkBalance();
  };

  render() {
    const {
      automaticListSelected,
      automaticEditMode,
      automaticList,

      fetchingAutomaticList,
      manualList,
      recipientType,
      fetchingManualList,

      toggleDentistVisible,
      toggleDentistMode,
      toggleDentistSelected,
      searchDentist,
      searchDentistSelected,
      fetchingDentistList,
      dentistList,

      togglePatientVisible,
      togglePatientMode,
      togglePatientSelected,
      searchPatient,
      fetchingPatientList,
      patientList,
      searchCalendarPatient,
      searchPatientSelected,
      calendarPatientList,
      fetchingCalendarPatientList,
      lastMode,
      notificationType,

      remindersMode,
      templatesList,
      templateLabel,
      remindersModalVisible,
      calendarFilterValue,
      categoryList,
      remindersFilterDropdownVisible,
      remindersFilterDoctorDropdownVisible,
      doctorSelected,
      balance,
      showBalance,
    } = this.props.reminders;

    const {
      calendar: { columns },
    } = this.props;

    let btnDeleteTitle = "Delete";
    let btnDeleteClassName = "btn btn-danger";
    let btnDeleteDisabled = false;

    if (_.isEmpty(automaticListSelected)) {
      btnDeleteClassName += " disabled";
      btnDeleteDisabled = true;
    } else {
      btnDeleteTitle += ` (${automaticListSelected.length})`;
    }
    return (
      <Modal
        className="reminders-modal"
        onHide={this.props.remindersActions.remindersModalHide}
        show={remindersModalVisible}
        title={
          <div className="row">
            <div className="col-sm-4 text-left">{`Your balance: ${balance || 0}`}</div>
            <div className="col-sm-4">Reminders</div>
            <div className="col-sm-4" />
          </div>
        }
        actions={
          <div>
            <Show if={remindersMode === "automatic" && automaticEditMode}>
              <div className="btn-group-modal">
                <Button
                  className="btn btn-primary"
                  onClick={this.props.remindersActions.toggleAutomaticEditMode}
                >
                  Cancel
                </Button>
                <Button
                  className={btnDeleteClassName}
                  disabled={btnDeleteDisabled}
                  onClick={this.props.remindersActions.deleteReminders}
                >
                  {btnDeleteTitle}
                </Button>
              </div>
            </Show>
            <Show if={remindersMode === "automatic" && !automaticEditMode}>
              <div className="btn-group-modal">
                <Button
                  className="btn btn-primary"
                  onClick={this.props.remindersActions.toggleAutomaticEditMode}
                >
                  Edit
                </Button>
              </div>
            </Show>
            <Button
              className="btn btn-primary btn-close"
              onClick={this.props.remindersActions.remindersModalHide}
            >
              Close
            </Button>
          </div>
        }
      >
        {/*<div className="btn-group btn-group-justified">*/}
        {/*<RemindersMainButton*/}
        {/*  onClick={() => {*/}
        {/*    this.props.remindersActions.setRemindersMode("automatic");*/}
        {/*  }}*/}
        {/*  text="Automatic"*/}
        {/*  active={remindersMode == "automatic"}*/}
        {/*/>*/}
        {/*<RemindersMainButton*/}
        {/*  onClick={() => {*/}
        {/*    this.props.remindersActions.setRemindersMode("manual");*/}
        {/*  }}*/}
        {/*  text="Manual"*/}
        {/*  active={remindersMode == "manual"}*/}
        {/*/>*/}
        {/*</div>*/}
        <InfoModal
          onHide={this.props.remindersActions.balanceModalToggle}
          show={showBalance}
          info={`Your balance: ${balance || 0}`}
        />
        <Show if={remindersMode === "automatic"}>
          <RemindersAutomaticMode
            automaticListSelected={automaticListSelected}
            automaticEditMode={automaticEditMode}
            automaticList={automaticList}
            fetchingAutomaticList={fetchingAutomaticList}
            toggleDentistVisible={toggleDentistVisible}
            toggleDentistMode={toggleDentistMode}
            toggleDentistSelected={toggleDentistSelected}
            searchDentist={searchDentist}
            searchDentistSelected={searchDentistSelected}
            dentistList={dentistList}
            fetchingDentistList={fetchingDentistList}
            templates={templatesList}
          />
        </Show>
        <Show if={remindersMode === "manual"}>
          <RemindersManualMode
            lastMode={lastMode}
            notificationType={notificationType}
            remindersFilterDropdownVisible={remindersFilterDropdownVisible}
            remindersFilterDoctorDropdownVisible={remindersFilterDoctorDropdownVisible}
            calendarColumns={columns}
            calendarFilterValue={calendarFilterValue}
            appointmentTypes={categoryList}
            doctorSelected={doctorSelected}
            manualList={manualList}
            recipientType={recipientType}
            fetchingManualList={fetchingManualList}
            toggleDentistVisible={toggleDentistVisible}
            toggleDentistMode={toggleDentistMode}
            toggleDentistSelected={toggleDentistSelected}
            searchDentist={searchDentist}
            searchDentistSelected={searchDentistSelected}
            dentistList={dentistList}
            fetchingDentistList={fetchingDentistList}
            togglePatientVisible={togglePatientVisible}
            togglePatientMode={togglePatientMode}
            togglePatientSelected={togglePatientSelected}
            searchPatient={searchPatient}
            searchPatientSelected={searchPatientSelected}
            searchCalendarPatient={searchCalendarPatient}
            fetchingPatientList={fetchingPatientList}
            patientList={patientList}
            calendarPatientList={calendarPatientList}
            fetchingCalendarPatientList={fetchingCalendarPatientList}
            templates={templatesList}
            templateLabel={templateLabel}
          />
        </Show>
      </Modal>
    );
  }
}
export default enhancer(Reminders);
