import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import _, { last } from "lodash";

import Show from "../../../widgets/Show";
import Utils from "../../../../helpers/Utils";
import FormValue from "./form-components/FormValue";
import FormCanvas from "./form-components/FormCanvas";
import FormTextArea from "./form-components/FormTextArea";
import FormRadioList from "./form-components/FormRadioList";
import FormDatePicker from "./form-components/FormDatePicker";
import FormPatientDocs from "./form-components/FormPatientDocs";
import MedicalFillInFormOverlay from "./MedicalFillInFormOverlay";
import FormCheckboxList from "./form-components/FormCheckboxList";
import FormGenerateFormula from "./form-components/FormGenerateFormula";
import FormCheckboxListWithComment from "./form-components/FormCheckboxListWithComment";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT, TIME_FORMAT } from "../../../../constants/Constants";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";
import {
  getConsultationTemplateList,
  getMedicalFillInFormTemplateQuestions,
  openItem,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import { openSimpleModal } from "../../../../actions/simpleModalActions";
import { SET_CONSULTATION_ID } from "../../../../actionTypes/medicalFillInFormActionTypes";
import MedicalFillInFormQuestionModal from "./MedicalFillInFormQuestionModal";

const NA = "N/A";

const MedicalFillInFormInitialExaminationItem = ({
  setInitialExaminationForm,
  initialExaminationForm,
  chartSideMedicalFillInForm,
  editMode,
  images,
  clinicName,
  consultation = null,
  onSaveItem,
  permissions,
  patientKey,
}) => {
  const {
    initialExaminationConsultation,
    stack,
    activeTab,
    empty,
    initialExaminationItem,
    questionsModalVisible,
  } = chartSideMedicalFillInForm;

  const [overlay, setOverlay] = useState({ visible: false });
  const [canvasImage, setCanvasImage] = useState("");
  const [patientImage, setPatientImage] = useState("");
  const dispatch = useDispatch();

  const lastStack = last(stack);

  useEffect(() => {
    setInitialExaminationForm(initialExaminationConsultation);
  }, [activeTab]);

  useEffect(() => {
    if (initialExaminationConsultation.template) {
      initialExaminationConsultation.template.templateBody = getTemplate(
        initialExaminationConsultation.template?.templateBody,
      );
      setInitialExaminationForm(initialExaminationConsultation);
    }
  }, [initialExaminationConsultation.template]);

  const getTemplate = (template = "") => {
    if (!template) return null;
    const reqExp = /\[\[[^\]]*]]/g;
    const { patient, member } = empty;
    return template.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      if (code === "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code === "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code === "Patient chart number") {
        return patient && patient.chartNumber;
      }
      if (code === "Patient age") {
        return patient && patient.age;
      }
      if (code === "Patient name") {
        return patient && patient.fullName;
      }
      if (code === "Patient nationality") {
        return _.get(patient, "nationality.name", "N/A");
      }
      if (code === "Patient primary insurance image") {
        for (const form in images) {
          for (const image in images[form]) {
            const { url } = images[form][image];

            if (url) {
              return `<img style="width: 100%; max-width: 300px" src=${url} alt="img"/>`;
            }
          }
        }
      }
      if (code === "Patient date of birth") {
        const dateFormat = localStorage.getItem("company-date-format");

        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(patient && patient.dateOfBirth),
          dateFormat,
        );
      }
      if (code === "Dentist Name") {
        return member && member.name;
      }
      if (code === "Check-in date time") {
        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(empty.checkedInDate),
          FULL_DATE_FORMAT,
        );
      }
      if (code === "Doctor license id") {
        return member && member.code;
      }
      if (code === "Patient last name") {
        return patient && patient.lastName;
      }
      if (code === "Patient first name") {
        return patient && patient.firstName;
      }
      if (code === "Patient primary card number") {
        return empty.primaryInsuranceCardNumber;
      }
      if (code === "Patient secondary card number") {
        return empty.secondaryInsuranceCardNumber;
      }
      if (code === "Patient gender") {
        return patient && patient.gender && patient.gender.name;
      }
      if (code === "Patient driver's licence number") {
        return patient && patient.documentDriverLicenseNumber;
      }
      if (code === "Patient social security number") {
        return patient && patient.documentSocialSecurityNumber;
      }
      if (code === "Patient social insurance number") {
        return patient && patient.documentSocialInsuranceNumber;
      }
      if (code === "Patient national id number") {
        return patient && patient.documentNationalIdNumber;
      }
      if (code === "Patient passport number") {
        return patient && patient.documentPasswordNumber;
      }
      if (code === "Clinic name") {
        return clinicName;
      }
      if (code === "Patient mobile number") {
        return patient && patient.mobilePhoneNumber;
      }
      return "N/A";
    });
  };

  useEffect(() => {
    if (consultation?.questions.length) {
      setInitialExaminationForm(consultation);
    }
  }, [consultation?.questions]);

  useEffect(() => {
    if (document.querySelector("span[data-index]")) {
      const elements = document.querySelectorAll("span[data-index]");
      elements.forEach((element) => {
        const question = initialExaminationForm.questions.find(
          (i) => Number(i.id) === Number(element.dataset.index),
        );
        if (question) {
          if (question.typeCode === questionTypeCodes.CANVAS) {
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setCanvasImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="canvas"
                id="canvasId"
                className="canvas-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.IMAGE) {
            if (!(question?.answerFile || question?.answerPhoto?.url)) {
              element.innerHTML = NA;
              return;
            }
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setPatientImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="mouth"
                id="mouthId"
                className="mouth-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.FORMULA) {
            element.innerHTML = Utils.calculateStringToNumber(question?.answer) || NA;
            return;
          }
          if (question?.options?.length) {
            const { options } = question;
            const value = options
              .map((option) => {
                let answer = "";
                if (option.selected) {
                  answer = `${option.otherValue || (!option.other ? option.title : "")} <br>`;
                  if (option.comment) {
                    answer = answer + `${option.comment} <br>`;
                  }
                }
                return answer;
              })
              .join("");
            element.innerHTML = value || NA;
          } else {
            element.innerHTML = question?.answer || question?.defaultAnswer || NA;
          }
        }
      });
    }
  }, [initialExaminationForm.questions]);
  const getField = (questionTypeCode, questionId, visible, canAddComment = false) => {
    if (
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA
    ) {
      return (
        <FormTextArea
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    }
    if (questionTypeCode === questionTypeCodes.VALUE)
      return (
        <FormValue
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.DATE_PICKER)
      return (
        <FormDatePicker
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.RADIO_LIST)
      return (
        <FormRadioList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && !canAddComment)
      return (
        <FormCheckboxList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && canAddComment)
      return (
        <FormCheckboxListWithComment
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.CANVAS)
      return (
        <FormCanvas
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
          canvasImage={canvasImage}
        />
      );
    if (questionTypeCode === questionTypeCodes.FORMULA)
      return (
        <FormGenerateFormula
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
        />
      );
    if (questionTypeCode === questionTypeCodes.IMAGE)
      return (
        <FormPatientDocs
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setInitialExaminationForm}
          form={initialExaminationForm}
          patientImage={patientImage}
          questionTypeCode={questionTypeCode}
          questionTypeCodes={questionTypeCodes}
        />
      );
  };
  const setTemplateData = (event) => {
    if (!editMode) return;
    if (event.target.dataset?.index) {
      const id = event.target.dataset.index;
      const question = initialExaminationConsultation.questions.find(
        (item) => Number(item.id) === Number(id),
      );
      setOverlay({
        visible: true,
        innerWidth: event.view.innerWidth,
        innerHeight: event.view.innerHeight,
        top: event.clientY,
        left: event.clientX,
        question: question.question || "",
        typeCode: question.typeCode,
        currentQuestionId: question.id,
        component: getField(question.typeCode, question.id, true, question.canAddComment),
      });
    }
  };
  const imageUrlToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const isEditAvailable = (item) => {
    const less24hours = Date.now() - item.createdDate < 24 * 60 * 60 * 1000;
    const less48hours = Date.now() - item.createdDate < 48 * 60 * 60 * 1000;

    const editAnyTimePermission = permissions.indexOf("MEDICAL_CONSULTATION_EDIT_ANY_TIME") >= 0;
    const less24HourPermission =
      permissions.indexOf("MEDICAL_CONSULTATION_EDIT_WITHIN_24_HOURS") >= 0;
    const less48HourPermission =
      permissions.indexOf("MEDICAL_CONSULTATION_EDIT_WITHIN_48_HOURS") >= 0;
    return (
      editAnyTimePermission ||
      (less48HourPermission && less48hours) ||
      (less24HourPermission && less24hours)
    );
  };

  const initialExaminationViewEditModeHandler = () => {
    const { initialExaminationItem } = chartSideMedicalFillInForm;
    if (!isEditAvailable(initialExaminationItem)) {
      return dispatch(openSimpleModal({ body: "permission denied" }));
    }
    dispatch({ type: SET_CONSULTATION_ID, consultationId: initialExaminationItem.consultationId });
    dispatch(
      getConsultationTemplateList({
        consultationId: initialExaminationItem.consultationId,
        patientKey,
      }),
    ).then((response) => {
      const item = response[0];
      dispatch(openItem(item, medicalFillInFormItemTypes.edit));
      const transfer = {
        patientKey,
        consultationId: item.consultationId,
        templateId: item?.template.id,
      };
      dispatch(getMedicalFillInFormTemplateQuestions(transfer));
    });
  };

  if (initialExaminationForm.template) {
    const { template } = initialExaminationForm;
    return (
      <div className="medical-fill-in-form-question">
        <MedicalFillInFormOverlay
          overlay={overlay}
          onClose={() => setOverlay({ visible: false })}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div className="medical-fill-in-form-question__title">{template.name}</div>
          <Show if={!editMode && initialExaminationItem?.consultationId}>
            <button
              className="btn btn-primary"
              onClick={initialExaminationViewEditModeHandler}
              style={{ padding: "6px 20px" }}
            >
              Edit
            </button>
          </Show>
        </div>

        <div
          className="medical-fill-in-form-question__body"
          dangerouslySetInnerHTML={{ __html: template.templateBody }}
          onClick={(event) => setTemplateData(event)}
        />
        <div className="medical-fill-in-form-question__actions">
          <Show if={editMode}>
            <button className="btn btn-primary" onClick={onSaveItem}>
              {lastStack?.type === medicalFillInFormItemTypes.new ? "Save" : "Update"}
            </button>
          </Show>
        </div>

        <Show if={questionsModalVisible}>
          <MedicalFillInFormQuestionModal
            form={initialExaminationForm}
            questionId={overlay?.currentQuestionId}
          />
        </Show>
      </div>
    );
  }
  return null;
};

export default MedicalFillInFormInitialExaminationItem;
