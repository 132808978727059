import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartProcedureConfirmModal extends React.Component {
  render() {
    return (
      <Modal
        size="small"
        show={this.props.chartSideSeries.procedureConfirmModalVisible}
        onHide={this.props.chartSideSeriesActions.closeProcedureConfirmModal}
        keyboard={false}
        actions={
          <div>
            <Button onClick={this.props.chartSideSeriesActions.closeProcedureConfirmModal}>
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.props.chartSideSeriesActions.getProcedure();
                this.props.chartSideSeriesActions.closeProcedureConfirmModal();
              }}
            >
              Ok
            </Button>
          </div>
        }
      >
        <div>Post treatments under this procedure?</div>
      </Modal>
    );
  }
}

export default enhancer(ChartProcedureConfirmModal);
