export const REMINDERS_MODAL_SHOW = "REMINDERS_MODAL_SHOW";
export const REMINDERS_MODAL_HIDE = "REMINDERS_MODAL_HIDE";

export const REMINDERS_TOGGLE_DENTIST_MODAL_SHOW = "REMINDERS_TOGGLE_DENTIST_MODAL_SHOW";
export const REMINDERS_TOGGLE_DENTIST_MODAL_HIDE = "REMINDERS_TOGGLE_DENTIST_MODAL_HIDE";

export const SET_REMINDERS_MODE = "SET_REMINDERS_MODE";
export const REMINDERS_TOGGLE_AUTOMATIC_LIST_SELECTED = "REMINDERS_TOGGLE_AUTOMATIC_LIST_SELECTED";

export const REMINDERS_SET_RECIPIENT_TYPE = "REMINDERS_SET_RECIPIENT_TYPE";

export const REMINDERS_TOGGLE_PATIENT_MODAL_SHOW = "REMINDERS_TOGGLE_PATIENT_MODAL_SHOW";
export const REMINDERS_TOGGLE_PATIENT_MODAL_HIDE = "REMINDERS_TOGGLE_PATIENT_MODAL_HIDE";

export const REMINDERS_SET_PATIENT_MODE = "REMINDERS_SET_PATIENT_MODE";

export const REMINDERS_GET_CALENDAR_PATIENT_LIST_START =
  "REMINDERS_GET_CALENDAR_PATIENT_LIST_START";
export const REMINDERS_GET_CALENDAR_PATIENT_LIST_SUCCESS =
  "REMINDERS_GET_CALENDAR_PATIENT_LIST_SUCCESS";
export const REMINDERS_GET_CALENDAR_PATIENT_LIST_ERROR =
  "REMINDERS_GET_CALENDAR_PATIENT_LIST_ERROR";

export const REMINDERS_TOGGLE_PATIENT = "REMINDERS_TOGGLE_PATIENT";

export const REMINDERS_SEARCH_PATIENT = "REMINDERS_SEARCH_PATIENT";
export const REMINDERS_SEARCH_PATIENT_SELECTED = "REMINDERS_SEARCH_PATIENT_SELECTED";

export const REMINDERS_GET_PATIENT_LIST_START = "REMINDERS_GET_PATIENT_LIST_START";
export const REMINDERS_GET_PATIENT_LIST_SUCCESS = "REMINDERS_GET_PATIENT_LIST_SUCCESS";
export const REMINDERS_GET_PATIENT_LIST_ERROR = "REMINDERS_GET_PATIENT_LIST_ERROR;";

export const REMINDERS_GET_PATIENT_START = "REMINDERS_GET_PATIENT_START";
export const REMINDERS_GET_PATIENT_SUCCESS = "REMINDERS_GET_PATIENT_SUCCESS";
export const REMINDERS_GET_PATIENT_ERROR = "REMINDERS_GET_PATIENT_ERROR";

export const REMINDERS_DELETE_SELECTED_START = "REMINDERS_DELETE_SELECTED_START";
export const REMINDERS_DELETE_SELECTED_SUCCESS = "REMINDERS_DELETE_SELECTED_SUCCESS";
export const REMINDERS_DELETE_SELECTED_ERROR = "REMINDERS_DELETE_SELECTED_ERROR";

export const REMINDERS_SAVE_AUTOMATIC_START = "REMINDERS_SAVE_AUTOMATIC_START";
export const REMINDERS_SAVE_AUTOMATIC_SUCCESS = "REMINDERS_SAVE_AUTOMATIC_SUCCESS";
export const REMINDERS_SAVE_AUTOMATIC_ERROR = "REMINDERS_SAVE_AUTOMATIC_ERROR";

export const REMINDERS_SAVE_MANUAL_START = "REMINDERS_SAVE_MANUAL_START";
export const REMINDERS_SAVE_MANUAL_SUCCESS = "REMINDERS_SAVE_MANUAL_SUCCESS";
export const REMINDERS_SAVE_MANUAL_ERROR = "REMINDERS_SAVE_MANUAL_ERROR";

export const REMINDERS_TOGGLE_AUTOMATIC_EDIT_MODE = "REMINDERS_TOGGLE_AUTOMATIC_EDIT_MODE";

export const REMINDERS_SET_DENTIST_MODE = "REMINDERS_SET_DENTIST_MODE";

export const REMINDERS_TOGGLE_DENTIST = "REMINDERS_TOGGLE_DENTIST";
export const REMINDERS_SEARCH_DENTIST = "REMINDERS_SEARCH_DENTIST";
export const REMINDERS_SEARCH_DENTIST_SELECTED = "REMINDERS_SEARCH_DENTIST_SELECTED";
export const REMINDERS_SEARCH_CALENDAR_PATIENT = "REMINDERS_SEARCH_CALENDAR_PATIENT";

export const REMINDERS_GET_AUTOMATIC_LIST_START = "REMINDERS_GET_AUTOMATIC_LIST_START";
export const REMINDERS_GET_AUTOMATIC_LIST_SUCCESS = "REMINDERS_GET_AUTOMATIC_LIST_SUCCESS";
export const REMINDERS_GET_AUTOMATIC_LIST_ERROR = "REMINDERS_GET_AUTOMATIC_LIST_ERROR";

export const REMINDERS_GET_MANUAL_LIST_START = "REMINDERS_GET_MANUAL_LIST_START";
export const REMINDERS_GET_MANUAL_LIST_SUCCESS = "REMINDERS_GET_MANUAL_LIST_SUCCESS";
export const REMINDERS_GET_MANUAL_LIST_ERROR = "REMINDERS_GET_MANUAL_LIST_ERROR";

export const REMINDERS_GET_TEMPLATES_LIST_START = "REMINDERS_GET_TEMPLATES_LIST_START";
export const REMINDERS_GET_TEMPLATES_LIST_SUCCESS = "REMINDERS_GET_TEMPLATES_LIST_SUCCESS";
export const REMINDERS_GET_TEMPLATES_LIST_ERROR = "REMINDERS_GET_TEMPLATES_LIST_ERROR";
export const REMINDERS_GET_EMAIL_TEMPLATES_LIST_START = "REMINDERS_GET_EMAIL_TEMPLATES_LIST_START";
export const REMINDERS_GET_EMAIL_TEMPLATES_LIST_SUCCESS =
  "REMINDERS_GET_EMAIL_TEMPLATES_LIST_SUCCESS";
export const REMINDERS_GET_EMAIL_TEMPLATES_LIST_ERROR = "REMINDERS_GET_EMAIL_TEMPLATES_LIST_ERROR";
export const REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_START =
  "REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_START";
export const REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_SUCCESS =
  "REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_SUCCESS";
export const REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_ERROR =
  "REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_ERROR";

export const REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_START =
  "REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_START";
export const REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_SUCCESS =
  "REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_SUCCESS";
export const REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_ERROR =
  "REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_ERROR";

export const REMINDERS_GET_DENTIST_START = "REMINDERS_GET_DENTIST_START";
export const REMINDERS_GET_DENTIST_SUCCESS = "REMINDERS_GET_DENTIST_SUCCESS";
export const REMINDERS_GET_DENTIST_ERROR = "REMINDERS_GET_DENTIST_ERROR";

export const REMINDERS_SELECT_ALL_CALENDAR_PATIENT = "REMINDERS_SELECT_ALL_CALENDAR_PATIENT";

export const REMINDERS_SET_CALENDAR_PATIENT_FILTER = "REMINDERS_SET_CALENDAR_PATIENT_FILTER";

export const REMINDERS_GET_CATEGORY_LIST_START = "REMINDERS_GET_CATEGORY_LIST_START";
export const REMINDERS_GET_CATEGORY_LIST_SUCCESS = "REMINDERS_GET_CATEGORY_LIST_SUCCESS";
export const REMINDERS_GET_CATEGORY_LIST_ERROR = "REMINDERS_GET_CATEGORY_LIST_ERROR";

export const REMINDERS_TOGGLE_APPOINTMENTS = "REMINDERS_TOGGLE_APPOINTMENTS";

export const REMINDERS_SET_DOCTOR_FILTER = "REMINDERS_SET_DOCTOR_FILTER";
export const REMINDERS_COPY_DOCTORS = "REMINDERS_COPY_DOCTORS";

export const REMINDERS_APPOINTMENT_FILTER_SELECT = "REMINDERS_APPOINTMENT_FILTER_SELECT";

export const REMINDER_RESET = "REMINDERS_RESET";

export const REMINDERS_APPOINTMENT_CLINIC_FILTER_SELECT =
  "REMINDERS_APPOINTMENT_CLINIC_FILTER_SELECT";

export const REMINDERS_APPOINTMENT_SEND_PUSH_START = "REMINDERS_APPOINTMENT_SEND_PUSH_START";
export const REMINDERS_APPOINTMENT_SEND_PUSH_SUCCESS = "REMINDERS_APPOINTMENT_SEND_PUSH_SUCCESS";
export const REMINDERS_APPOINTMENT_SEND_PUSH_ERROR = "REMINDERS_APPOINTMENT_SEND_PUSH_ERROR";
export const REMINDERS_CHECK_BALANCE_START = "REMINDERS_CHECK_BALANCE_START";
export const REMINDERS_CHECK_BALANCE_SUCCESS = "REMINDERS_CHECK_BALANCE_SUCCESS";
export const REMINDERS_CHECK_BALANCE_ERROR = "REMINDERS_CHECK_BALANCE_ERROR";
export const REMINDERS_SET_NOTIFICATION_TYPE = "REMINDERS_SET_NOTIFICATION_TYPE";
export const REMINDERS_BALANCE_MODAL_TOGGLE = "REMINDERS_BALANCE_MODAL_TOGGLE";
