import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import BackButton from "../../../widgets/BackButton";
import ChartPlanPDFForm from "./ChartPlanPDFForm";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePlanActions from "../../../../actions/chartSidePlanActions";
import EmbedButton from "../../../widgets/EmbedButton";
import logoImage from "../../../../assets/images/clinic_logo.png";

const enhancer = connect(null, bindActions({ chartSidePlanActions }));

class ChartPlanPDF extends React.Component {
  send = () => {
    this.props.chartSidePlanActions.sendEmail();
  };

  render() {
    const { chartSidePlan } = this.props;
    const { stack } = chartSidePlan;

    const stackItem = _.last(stack);

    const initialValues = {
      name: stackItem.item.name,
    };

    return (
      <div className="clinical-note-item">
        <div className="clinical-note-item-view-title">
          <button onClick={this.send} className="btn btn-link pull-right">
            <strong>SEND TO EMAIL</strong>
          </button>
          <BackButton
            className="btn-sm"
            text="Back"
            onClick={this.props.chartSidePlanActions.popStack}
          />
          <img alt="" className="logo" src={logoImage} />
        </div>
        <ChartPlanPDFForm
          initialValues={initialValues}
          onSubmit={this.props.chartSidePlanActions.changeName}
        />
        <div className="clinical-note-item-view-body">
          <EmbedButton src={stackItem.item.pdfUrl} />
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPlanPDF);
