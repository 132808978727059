import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";

import ChartSideSearch from "../ChartSideSearch";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import * as loaderActions from "../../../../actions/loaderActions";
import JSUtils from "../../../../helpers/JSUtils";
import { ButtonGroup } from "@mui/material";
import { getAppointmentTransactions } from "../../../../actions/transactionActions";
import { parseQuery } from "../../../../utils/UrlUtils";
import { useLocation } from "react-router-dom";

const enhancer = connect(
  ({ transaction: { startVisitTransaction, transaction }, chart: { appointment } }) => ({
    startVisitTransaction,
    transaction,
    appointment,
  }),
  bindActions({
    chartSidePrescriptionActions,
    loaderActions,
    getAppointmentTransactions,
  }),
);

const ChartPrescriptionDiagnosis = (props) => {
  const [diagnosisTab, setDiagnosisTab] = useState("ALL");
  const [searchKey, setSearchKey] = useState("");
  const location = useLocation();
  const query = parseQuery(location.search);
  const appointmentId = props.appointment?.id;
  useEffect(() => {
    props.chartSidePrescriptionActions.initDiagnosisList();
    props.getAppointmentTransactions(
      true,
      true,
      {
        diagnosisCompleted: true,
        diagnosisPlanned: true,
        startVisit: true,
      },
      query.patientKey,
      appointmentId,
    );
  }, [diagnosisTab]);

  const loadDiagnosisList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      props.chartSidePrescriptionActions.loadDiagnosisList();
    }
  };

  const onSearch = (event) => {
    setSearchKey(event.target.value);
    props.chartSidePrescriptionActions.setDiagnosisSearchValue(event.target.value);
  };

  const selectDiagnosis = (event) => {
    props.chartSidePrescriptionActions.selectDiagnosis(parseInt(event.target.value));
  };

  const toggleDiagnosis = (id) => {
    return () => {
      props.chartSidePrescriptionActions.toggleDiagnosis(id);
    };
  };

  const submit = () => {
    if (!props.chartSidePrescription.primaryDiagnosis) {
      return props.loaderActions.showMessage("Please, choose one primary diagnosis");
    }

    // props.chartSidePrescriptionActions.setPrimary(this.props.chartSidePrescription.primaryDiagnosis);

    if (typeof props.onSubmit === "function") {
      props.onSubmit();
    } else {
      props.chartSidePrescriptionActions.openNameModal();
    }
  };
  const {
    diagnosisList,
    primaryDiagnosis,
    secondaryDiagnosis,
    searchValueDiagnosis,
    fetchingDiagnosis,
  } = props.chartSidePrescription;
  // const { diagnosisTab } = state;
  const list =
    diagnosisTab === "PLANNED"
      ? (appointmentId ? props.startVisitTransaction.list : [] || [])
          .filter((item) => {
            return (
              item.diagnosisItem &&
              appointmentId === item.createdAppointmentId &&
              item.status.code === "DentalTransactionStatus_COMPLETED"
            );
          })
          .filter((item) =>
            (item?.diagnosisItem?.description.toLowerCase() || "").includes(
              searchKey.toLowerCase(),
            ),
          )
      : diagnosisList;
  const $diagnosis = list.map((item, index) => {
    item = diagnosisTab === "PLANNED" ? item?.diagnosisItem : item;
    let $arrow = null;
    let chechked = false;

    if (primaryDiagnosis === item.id) {
      chechked = true;
    }

    if (JSUtils.hasOwn(secondaryDiagnosis, item.id)) {
      $arrow = <div>✔</div>;
    }

    return (
      <div className="diagnosis-table-row" key={index}>
        <div className="diagnosis-table-col-1">
          <label>
            <input
              type="checkbox"
              name="primary"
              value={item.id}
              checked={chechked}
              onClick={selectDiagnosis}
            />
          </label>
        </div>
        <div className="diagnosis-table-col-2" onClick={toggleDiagnosis(item.id)}>
          {$arrow}
        </div>
        <div className="diagnosis-table-col-3">{item.code}</div>
        <div className="diagnosis-table-col-4">{item.description}</div>
      </div>
    );
  });

  return (
    <div className="clinical-note-item">
      <div className="clinical-note-item-view-title chart-prescription-edit-title">
        <button onClick={submit} className="btn btn-link pull-right">
          ✔ &nbsp; Next
        </button>
        <BackButton
          className="btn-sm"
          text="Back"
          onClick={() => {
            typeof props.onBack === "function"
              ? props.onBack()
              : props.chartSidePrescriptionActions.popStack();
          }}
        />
        <div className="name">Select diagnosis:</div>
      </div>
      <div className="clinical-note-item-view-body chart-prescription-edit">
        <div className="chart-side-body">
          <div style={{ margin: "15px 0px" }}>
            <ButtonGroup aria-label="claims tab" className="d-flex align-items-center">
              <button
                onClick={() => setDiagnosisTab("ALL")}
                style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                className={`btn btn-block m-0 ${
                  diagnosisTab === "ALL" ? "btn-primary" : "btn-default"
                }`}
              >
                All diagnosis
              </button>
              <button
                onClick={() => setDiagnosisTab("PLANNED")}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                className={`btn btn-block m-0 ${
                  diagnosisTab === "PLANNED" ? "btn-primary" : "btn-default"
                }`}
              >
                Visit Diagnosis
              </button>
            </ButtonGroup>
          </div>

          <ChartSideSearch value={searchValueDiagnosis} onChange={onSearch} />
          <div className="diagnosis-table">
            <div className="diagnosis-table-header">
              <div className="diagnosis-table-col-1">Primary</div>
              <div className="diagnosis-table-col-2">Secondary</div>
              <div className="diagnosis-table-col-3">Diagnosis code</div>
              <div className="diagnosis-table-col-4">Diagnosis name</div>
            </div>
            <form className="diagnosis-table-body" onScroll={loadDiagnosisList}>
              {$diagnosis}
            </form>
            <Show if={fetchingDiagnosis}>
              <ReactSpinner />
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};

export default enhancer(ChartPrescriptionDiagnosis);
