import _ from "lodash";
import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";

import * as SeriesUtils from "../../../helpers/chartSideSeriesUtils";
import SelectToothSurface from "../../widgets/svg-shapes/SelectToothSurface";
import { SidebarTypes } from "../../chart-treatments-sidebar/TypeButtonGroup";

SurfacesSlider.propTypes = {
  item: PropTypes.object,
  teeth: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  typeTab: PropTypes.oneOf([SidebarTypes]),
  numericKey: PropTypes.number,
  activeTooth: PropTypes.number,
  seriesActions: PropTypes.any,
  changeActiveTooth: PropTypes.func,
  sortedSurfaceTransactionList: PropTypes.array,
};

export default function SurfacesSlider({
  teeth,
  title,
  isEdit,
  typeTab,
  numericKey,
  activeTooth,
  seriesActions,
  changeActiveTooth,
  item: treatmentItem,
  sortedSurfaceTransactionList,
}) {
  const toothList = [];
  const teethDetailsList = SeriesUtils.getHalfSortedTeeh(teeth);

  const newList = [];

  const list = teethDetailsList.filter((x) => !x.supernumerary);
  const firstList = list.slice(0, Math.floor(list.length / 2));
  const secondList = list.slice(Math.floor(list.length / 2)).reverse();

  list.forEach((x, idx, arr) => {
    const startIndex = idx * 4;
    const endIndex = startIndex + 4;

    if (startIndex < arr.length / 2) {
      newList.push([
        ...firstList.slice(startIndex, endIndex),
        ...secondList.slice(startIndex, endIndex),
      ]);
    }
  });

  let initialSlide = 0;

  newList.forEach((x = [], idx) => {
    toothList.push(
      /* eslint-disable-next-line */
      <div key={idx} data-index={idx}>
        {x.map((item, index) => {
          let isSelected = false;

          if (SeriesUtils.isEqualsActiveTooth(item, Number(activeTooth))) {
            isSelected = true;
            initialSlide = idx;
          }

          const currentTransaction = _.get(sortedSurfaceTransactionList, `${item.id}`, {});

          return (
            <SelectToothSurface
              isEdit={isEdit}
              typeTab={typeTab}
              key={`${item.code}-${item.id}`}
              onClick={() => {
                seriesActions.changeTransactionByKey(idx + index, "transactionForm", null, typeTab);
                changeActiveTooth(SeriesUtils.getActiveTooth(item));
              }}
              activeTooth={activeTooth}
              item={treatmentItem}
              numericKey={numericKey}
              isSelected={isSelected}
              changeActiveTooth={() => changeActiveTooth(SeriesUtils.getActiveTooth(item))}
              addTransaction={{
                ...currentTransaction,
                editable: isSelected ? true : currentTransaction.editable,
              }}
              className={isSelected ? "active" : ""}
              tooth={item}
            />
          );
        })}
      </div>,
    );
  });

  const settings = {
    speed: 500,
    dots: false,
    initialSlide,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-wrap surface-slider">
      <h4 className="title">{title}</h4>
      <Slider {...settings}>{toothList}</Slider>
    </div>
  );
}
