import _ from "lodash";
import React from "react";

import { SidebarTooth } from "./SidebarTooth";

export function SidebarTeeth({ teeth, numericKey, onClick, addTransaction }) {
  const up = [];
  const down = [];

  _.forEach(teeth, (tooth, toothPositionCode) => {
    const index = toothPositionCode.slice(1);

    if (index <= 16) {
      up[index - 1] = tooth;
    } else {
      down[32 - index] = tooth;
    }
  });

  const upRow = up.map((tooth, index) => (
    <SidebarTooth
      key={index}
      tooth={tooth}
      onClick={onClick}
      orientation="normal"
      numericKey={numericKey}
      addTransaction={addTransaction}
    />
  ));

  const downRow = down.map((tooth, index) => (
    <SidebarTooth
      key={index}
      tooth={tooth}
      onClick={onClick}
      numericKey={numericKey}
      addTransaction={addTransaction}
    />
  ));

  return (
    <div className="teeth-view sidebar-teeth-view">
      <div className="up">{upRow}</div>
      <div className="down">{downRow}</div>
    </div>
  );
}
