import * as ActionType from "../actionTypes/repeatAppointmentActionTypes";
import { startLoader, stopLoader } from "./loaderActions";
import MemberApi from "../api-legacy/MemberApi";
import AppointmentApi from "../api-legacy/AppointmentApi";
import FollowUpApi from "../api-legacy/FollowUpApi";
import Utils from "../helpers/Utils";
import * as chartActions from "./chartActions";

export const getDoctors = () => (dispatch) => {
  dispatch(startLoader());

  return dispatch(getDoctorsApi())
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export function selectFollowType(item) {
  return {
    type: ActionType.REPEAT_FOLLOW_TYPE_SELECT,
    item,
  };
}

export const getTrxRooms = (dentistId) => (dispatch) => {
  dispatch(startLoader());

  return dispatch(getTrxRoomsApi({ dentistId }))
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export function setNote(value) {
  return {
    type: ActionType.REPEAT_FOLLOW_SET_NOTE,
    value,
  };
}

export function setNote_02(value) {
  return {
    type: ActionType.REPEAT_FOLLOW_SET_NOTE_02,
    value,
  };
}

export const initTrxRooms = () => ({
  type: ActionType.REPEAT_APPT_INIT_TRX_ROOMS,
});

export const submitAnotherDoctor = () => (dispatch, getState) => {
  dispatch(startLoader());

  const {
    form: {
      repeatAppointmentAnotherDoctor: { values = {} },
    },
    chart: {
      patient: { patientKey },
    },
  } = getState();

  const transfer = {
    note: values.note,
    doctor: {
      id: values.doctor,
    },
    dentalPoint: {
      id: values.trxRoom,
    },
    patientKey,
    date: Utils.getStartDay() + 12 * 60 * 60 * 1000,
  };

  dispatch(CreateAppointmentApi(transfer))
    .then(() => {
      dispatch(chartActions.hideSideBar());
      dispatch(chartActions.repeatAppointmentModalHide());
      dispatch(stopLoader("Appointment successfully created"));
    })
    .catch((error) => dispatch(stopLoader(error)));
};

export function createFollowUpAppointment(data) {
  return (dispatch, getState) => {
    const {
      session: { clinic },
    } = getState();

    dispatch(startLoader());
    data.clinicId = clinic.id;
    return dispatch(CreateTrxPlanningApi(data))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function addNote() {
  return (dispatch, getState) => {
    const {
      followUp: { item },
      session: { member },
      repeatAppointment: { followNote },
    } = getState();
    const data = {
      creator: { id: member.id },
      followUpId: item.id,
      note: followNote,
    };
    const dataForList = {
      followUpId: item.id,
      start: 0,
      limit: 50,
    };
    return dispatch(AddNoteApi(data))
      .then(() => {
        dispatch({ type: ActionType.SET_FOLLOW_NOTE, followNote: "" });
        dispatch(stopLoader());
        dispatch(getNoteList(dataForList));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteNote(data) {
  return (dispatch) => {
    return dispatch(DeleteNoteApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getNoteList(data) {
  return (dispatch) => {
    data.sortField = "create_date_asc";
    return dispatch(GetNoteListApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setFollowNote(followNote) {
  return (dispatch) => {
    dispatch({ type: ActionType.SET_FOLLOW_NOTE, followNote });
  };
}

export const getTypes = () => (dispatch, getState) => {
  const {
    calendar: { clinicId },
  } = getState();

  dispatch(startLoader());

  return dispatch(getTypesApi({ clinicId }))
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const submitTrxPlanning = () => (dispatch, getState) => {
  dispatch(startLoader());

  const {
    form: {
      repeatAppointmentTrxPlanning: { values = {} },
    },
    chart: {
      patient: { patientKey },
    },
  } = getState();

  const transfer = {
    note: values.note,
    note2: values.note2,
    doctor: {
      id: values.doctor,
    },
    type: {
      id: values.type,
    },
    patientKey,
  };

  dispatch(CreateTrxPlanningApi(transfer))
    .then(() => dispatch(stopLoader("Successfully created")))
    .catch((error) => dispatch(stopLoader(error)));
};

const getDoctorsApi = (data) => ({
  api: MemberApi.getDentistWithPoints,
  types: [
    ActionType.REPEAT_APPT_GET_DOCTORS_START,
    ActionType.REPEAT_APPT_GET_DOCTORS_SUCCESS,
    ActionType.REPEAT_APPT_GET_DOCTORS_ERROR,
  ],
  data,
});

const getTrxRoomsApi = (data) => ({
  api: AppointmentApi.getDentalPoint,
  types: [
    ActionType.REPEAT_APPT_GET_TRX_ROOMS_START,
    ActionType.REPEAT_APPT_GET_TRX_ROOMS_SUCCESS,
    ActionType.REPEAT_APPT_GET_TRX_ROOMS_ERROR,
  ],
  data,
});

const CreateAppointmentApi = (data) => ({
  api: FollowUpApi.createAppointment,
  types: [
    ActionType.REPEAT_APPT_CREATE_APPT_START,
    ActionType.REPEAT_APPT_CREATE_APPT_SUCCESS,
    ActionType.REPEAT_APPT_CREATE_APPT_ERROR,
  ],
  data,
});

const getTypesApi = (data) => ({
  api: FollowUpApi.getTypes,
  types: [
    ActionType.REPEAT_APPT_GET_TYPES_START,
    ActionType.REPEAT_APPT_GET_TYPES_SUCCESS,
    ActionType.REPEAT_APPT_GET_TYPES_ERROR,
  ],
  data,
});

const CreateTrxPlanningApi = (data) => ({
  api: FollowUpApi.createTransaction,
  types: [
    ActionType.REPEAT_APPT_CREATE_TRANSACTION_START,
    ActionType.REPEAT_APPT_CREATE_TRANSACTION_SUCCESS,
    ActionType.REPEAT_APPT_CREATE_TRANSACTION_ERROR,
  ],
  data,
});
const AddNoteApi = (data) => ({
  api: FollowUpApi.addNote,
  types: [
    ActionType.REPEAT_FOLLOW_ADD_NOTE_START,
    ActionType.REPEAT_FOLLOW_ADD_NOTE_SUCCESS,
    ActionType.REPEAT_FOLLOW_ADD_NOTE_ERROR,
  ],
  data,
});
const DeleteNoteApi = (data) => ({
  api: FollowUpApi.deleteNote,
  types: [
    ActionType.REPEAT_FOLLOW_DELETE_NOTE_START,
    ActionType.REPEAT_FOLLOW_DELETE_NOTE_SUCCESS,
    ActionType.REPEAT_FOLLOW_DELETE_NOTE_ERROR,
  ],
  data,
});
const GetNoteListApi = (data) => ({
  api: FollowUpApi.getNoteList,
  types: [
    ActionType.REPEAT_FOLLOW_GET_NOTE_LIST_START,
    ActionType.REPEAT_FOLLOW_GET_NOTE_LIST_SUCCESS,
    ActionType.REPEAT_FOLLOW_GET_NOTE_LIST_ERROR,
  ],
  data,
});
