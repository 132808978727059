import React, { useEffect, useState } from "react";
import FormTextArea from "./form-components/FormTextArea";
import FormValue from "./form-components/FormValue";
import FormDatePicker from "./form-components/FormDatePicker";
import FormRadioList from "./form-components/FormRadioList";
import FormCheckboxList from "./form-components/FormCheckboxList";
import MedicalFillInFormOverlay from "./MedicalFillInFormOverlay";
import Show from "../../../widgets/Show";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";
import _, { last } from "lodash";
import ReactDOMServer from "react-dom/server";
import FormCanvas from "./form-components/FormCanvas";
import FormGenerateFormula from "./form-components/FormGenerateFormula";
import FormPatientDocs from "./form-components/FormPatientDocs";
import Utils from "../../../../helpers/Utils";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT, TIME_FORMAT } from "../../../../constants/Constants";
import FormCheckboxListWithComment from "./form-components/FormCheckboxListWithComment";
import $ from "jquery";
import MedicalFillInFormQuestionModal from "./MedicalFillInFormQuestionModal";
import { useLocation } from "react-router";
import { parseQuery } from "../../../../utils/UrlUtils";
const NA = "N/A";

const MedicalFIllInFormItem = ({
  setForm,
  form,
  chartSideMedicalFillInForm,
  editMode,
  images,
  clinicName,
  consultation = null,
  onSaveItem,
}) => {
  const location = useLocation();
  const allParams = parseQuery(location.search);

  const isEditMode = allParams.medicalType !== "view";

  const { item, stack, activeTab, empty, questionsModalVisible } = chartSideMedicalFillInForm;

  const [overlay, setOverlay] = useState({ visible: false });
  const [canvasImage, setCanvasImage] = useState("");
  const [patientImage, setPatientImage] = useState("");
  const lastStack = last(stack);

  useEffect(() => {
    setForm(item);
  }, [activeTab]);

  useEffect(() => {
    if (item.template) {
      const newItem = { ...item };
      newItem.template.templateBody = getTemplate(item.template?.templateBody);
      setForm(newItem);
    }
  }, [item.template]);

  const setTemplateData = (event) => {
    // debugger;
    if (!editMode) return;

    if (event.target.dataset?.index) {
      if (event.target.value && event.target.value.indexOf("pick-list") !== -1) {
        const id = Number(event.target.dataset.index || 0);
        setForm({
          ...form,
          questions: form.questions.map((question) => {
            if (question.id === Number(event.target.value.replace(/^\D+/g, "") || 0)) {
              question.options = question.options.map((answer) => {
                if (answer.id === id) {
                  answer.selected = !answer.selected;
                  if (answer.selected) {
                    document.getElementById(event.target.id).classList.remove("btn-default");
                    document.getElementById(event.target.id).classList.add("btn-success");
                  } else {
                    document.getElementById(event.target.id).classList.remove("btn-success");
                    document.getElementById(event.target.id).classList.add("btn-default");
                    // document.getElementById(`${question.id}-${answer.id}`).remove()
                    // $(`#${event.target.id} + div`).remove();
                  }
                }
                return answer;
              });
            }
            return question;
          }),
        });
        return;
      }
      const id = event.target.dataset.index;
      const question = item.questions.find((item) => Number(item.id) === Number(id));
      setOverlay({
        visible: true,
        innerWidth: event.view.innerWidth,
        innerHeight: event.view.innerHeight,
        top: event.clientY,
        left: event.clientX,
        question: question.question || "",
        typeCode: question.typeCode,
        currentQuestionId: question.id,
        component: getField(question.typeCode, question.id, true, question.canAddComment),
      });
    }
  };

  useEffect(() => {
    let timeOutForClosing = null;

    const container = document.querySelector(".medical-fill-in-form-question");

    if (container) {
      const handleClick = (e) => {
        if (e.target.classList.contains("pl-comment-toggler-badge-plus")) {
          const questionId = Number(e.target.dataset.questionId);
          const answerId = Number(e.target.dataset.answerId);
          const isLeftOpening = e.target.dataset.isLeftOpening === "true";

          const commentBox = $(`#${questionId}-${answerId} + .pl-comment-popup-box`);
          if (commentBox.length) {
            commentBox.remove();
            return;
          }

          $(`#${questionId}-${answerId}`).after(`
            <div class="pl-comment-popup-box" style="position: absolute; top: calc(100% + 6px); ${
              isLeftOpening ? "right" : "left"
            }: 0px;z-index: 9;background: white;padding: 5px 10px;border-radius: 5px;box-shadow: 0 0 8px #00000050;min-width: 250px;">
              <div class="d-flex justify-content-between">
                <span style="display: block; margin-bottom: 5px; font-size: 14px">Remarks</span>
                <span class="close-popup" data-question-id="${questionId}" data-answer-id="${answerId}" style="display: block; padding: 5px; margin-bottom: 5px; font-size: 14px; cursor: pointer; text-align: right;">&times;</span>
              </div> 
              <p
                style="width: 230px;font-size: 14px;height: 100px;overflow: auto;"
                contenteditable=${isEditMode ? "true" : "false"}
                class="form-control pick-list-comment pl-comment-${questionId}-${answerId}"
                id="${questionId}-${answerId}"
              ></p>
              <div style="border-top: 1px solid #00000030; display: flex; justify-content: flex-end; padding-top: 5px;">
                <button class="btn btn-sm btn-success close-popup-button" data-question-id="${questionId}" data-answer-id="${answerId}">${
            isEditMode ? "Save" : "Ok"
          }</button>
              </div>
            </div>
          `);
          return;
        }

        if (e.target.classList.contains("pl-comment-toggler-badge")) {
          const questionId = Number(e.target.dataset.questionId);
          const answerId = Number(e.target.dataset.answerId);
          const isLeftOpening = e.target.dataset.isLeftOpening === "true";

          const hiddenComment = document.querySelector(
            `.pl-hidden-comment-${questionId}-${answerId}`,
          );

          const commentBox = $(`#${questionId}-${answerId} + .pl-comment-popup-box`);
          if (commentBox.length) {
            commentBox.remove();
            return;
          }

          $(`#${questionId}-${answerId}`).after(`
            <div class="pl-comment-popup-box" style="position: absolute; top: calc(100% + 6px); ${
              isLeftOpening ? "right" : "left"
            }: 0px;z-index: 9;background: white;padding: 5px 10px;border-radius: 5px;box-shadow: 0 0 8px #00000050;min-width: 250px;">
              <div class="d-flex justify-content-between">
                <span style="display: block; margin-bottom: 5px; font-size: 14px">Remarks</span>
                <span class="close-popup" data-question-id="${questionId}" data-answer-id="${answerId}" style="display: block; padding: 5px; margin-bottom: 5px; font-size: 14px; cursor: pointer; text-align: right;">&times;</span>
              </div> 
              <p
                style="width: 230px;font-size: 14px;height: 100px;overflow: auto;"
                contenteditable=${isEditMode ? "true" : "false"}
                class="form-control pick-list-comment pl-comment-${questionId}-${answerId}"
                id="${questionId}-${answerId}"
              >${hiddenComment.value || ""}</p>
              <div style="border-top: 1px solid #00000030; display: flex; justify-content: flex-end; padding-top: 5px;">
                <button class="btn btn-sm btn-success close-popup-button" data-question-id="${questionId}" data-answer-id="${answerId}">${
            isEditMode ? "Save" : "Ok"
          }</button>
              </div>
            </div>
          `);
          return;
        }

        if (e.target.classList.contains("close-popup-button")) {
          const questionId = Number(e.target.dataset.questionId);
          const answerId = Number(e.target.dataset.answerId);

          if (!isEditMode) {
            const popupContainer = $(`#${questionId}-${answerId} + div`);
            popupContainer.remove();
          }

          const comment = document.querySelector(`.pl-comment-${questionId}-${answerId}`);
          const hiddenComment = document.querySelector(
            `.pl-hidden-comment-${questionId}-${answerId}`,
          );

          hiddenComment.value = comment?.innerText || "";

          if (
            comment?.innerText !== "" &&
            comment?.innerText !== " " &&
            comment?.innerText !== undefined &&
            comment?.innerText !== "\n"
          ) {
            $(`.pl-comment-badge-${questionId}-${answerId}`).remove();
            $(`#${questionId}-${answerId}`).append(`<div
                                data-question-id="${questionId}"
                                data-answer-id="${answerId}"
                                data-comment="${comment?.innerText}"
                                onmouseover="this.style.height='16px'; this.style.width='16px';" 
                                onmouseout="this.style.height='12px'; this.style.width='12px';"
                                class="pl-comment-toggler-badge pl-comment-badge-${questionId}-${answerId}" style="z-index: 8;
                                    position: absolute;
                                    top: -4px;
                                    right: -4px;
                                    height: 12px;
                                    width: 12px;
                                    background: #ff2600c8;
                                    border-radius: 50%;
                                    font-size: 11px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    line-height: 10px;">1</div>`);
          } else {
            $(`.pl-comment-badge-${questionId}-${answerId}`).remove();
            $(`#${questionId}-${answerId}`).append(`<div 
                                      data-question-id="${questionId}"
                                      data-answer-id="${answerId}"
                                      data-comment=""
                                      onmouseover="this.style.height='16px'; this.style.width='16px';" 
                                      onmouseout="this.style.height='12px'; this.style.width='12px';" 
                                      class="pl-comment-toggler-badge-plus pl-comment-badge-${questionId}-${answerId}" style="z-index: 8;
                                        position: absolute;
                                        top: -4px;
                                        right: -4px;
                                        height: 12px;
                                        width: 12px;
                                        background: #cbcbcbc7;
                                        color: #000;
                                        border-radius: 50%;
                                        font-size: 10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        line-height: 10px;">&plus;</div>`);
          }

          const popupContainer = $(`#${questionId}-${answerId} + div`);
          timeOutForClosing = setTimeout(() => {
            popupContainer.remove();
          }, 100);
        }

        if (e.target.classList.contains("close-popup")) {
          const questionId = Number(e.target.dataset.questionId);
          const answerId = Number(e.target.dataset.answerId);

          const popupContainer = $(`#${questionId}-${answerId} + div`);
          popupContainer.remove();

          // // Reset the button style
          // const button = document.querySelector(`button[data-index="${answerId}"]`);
          // if (button) {
          //   button.classList.remove('btn-success');
          //   button.classList.add('btn-default');
          // }

          // // Update the form state
          // setForm((prevForm) => ({
          //   ...prevForm,
          //   questions: prevForm.questions.map((question) => {
          //     if (question.id === questionId) {
          //       return {
          //         ...question,
          //         options: question.options.map((answer) =>
          //           answer.id === answerId ? { ...answer, selected: false } : answer
          //         ),
          //       };
          //     }
          //     return question;
          //   }),
          // }));
        }
      };

      container.addEventListener("click", handleClick);

      return () => {
        clearTimeout(timeOutForClosing);
        container.removeEventListener("click", handleClick);
      };
    }
  }, [form]);

  const getTemplate = (template = "") => {
    if (!template) return null;

    const reqExp = /\[\[[^\]]*]]/g;
    const { patient, member } = empty;

    return template.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      let pickListQuestion = false;

      template.replace(/PICKLIST/g, (m) => {
        if (m === "PICKLIST") pickListQuestion = true;
      });

      if (pickListQuestion) {
        const currentQuestion = (item.questions || []).find((item) => item.code === code);

        if (!currentQuestion) return "";

        const columns = Number(currentQuestion.typeCode.replace(/^\D+/g, "") || 0);
        let gridColumns = "";

        for (let i = 0; i < columns; i++) {
          gridColumns += "auto ";
        }

        function isInSeries(num) {
          const min = 6;
          const max = 210;
          const validLastDigits = [6, 7, 8, 9, 10];

          return num >= min && num <= max && validLastDigits.includes(num % 10);
        }

        let result = `<div style="text-decoration: none; display: inline-grid; gap: 10px; padding: 10px; grid-template-columns: ${gridColumns}">
            ${currentQuestion?.options?.map((option, index) => {
              const indexNumber = index + 1;
              const isLeftOpeningCommentBox = isInSeries(indexNumber);
              return `<div style="position: relative">
                      <input type="hidden" value="${
                        option.comment || ""
                      }" class="hidden-pick-list-comment pl-hidden-comment-${currentQuestion.id}-${
                option.id
              }" data-qna-id="${currentQuestion.id}-${option.id}" />
                      <button 
                        style="height: 100%;padding: 1px 4px; min-width: 80px; width: 100px; white-space: normal; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;"
                        title="${option.title}"
                        data-index="${option.id}"
                        id="${currentQuestion.id}-${option.id}"
                        value="pick-list-${currentQuestion.id}"
                        class="pick-list-button btn btn-sm ${
                          option.selected ? "btn-success" : "btn-default"
                        }">
                        ${option.title}
                        ${
                          !currentQuestion.canAddComment
                            ? ""
                            : option?.comment !== "" &&
                              option?.comment !== " " &&
                              option?.comment !== undefined &&
                              option?.comment !== "\n"
                            ? `<div
                                data-question-id="${currentQuestion.id}"
                                data-answer-id="${option.id}"
                                data-comment="${option.comment}"
                                data-is-left-opening="${isLeftOpeningCommentBox && columns > 5}"
                                onmouseover="this.style.height='16px'; this.style.width='16px';" 
                                onmouseout="this.style.height='12px'; this.style.width='12px';"
                                class="pl-comment-toggler-badge pl-comment-badge-${
                                  currentQuestion.id
                                }-${option.id}" style="z-index: 8;
                                    position: absolute;
                                    top: -4px;
                                    right: -4px;
                                    height: 12px;
                                    width: 12px;
                                    background: #ff2600c8;
                                    border-radius: 50%;
                                    font-size: 11px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    line-height: 10px;">1</div>`
                            : `<div 
                                      data-question-id="${currentQuestion.id}"
                                      data-answer-id="${option.id}"
                                      data-comment="${option.comment}"
                                      data-is-left-opening="${
                                        isLeftOpeningCommentBox && columns > 5
                                      }"
                                      onmouseover="this.style.height='16px'; this.style.width='16px';" 
                                      onmouseout="this.style.height='12px'; this.style.width='12px';" 
                                      class="pl-comment-toggler-badge-plus pl-comment-badge-${
                                        currentQuestion.id
                                      }-${option.id}" style="z-index: 8;
                                        position: absolute;
                                        top: -4px;
                                        right: -4px;
                                        height: 12px;
                                        width: 12px;
                                        background: #cbcbcbc7;
                                        color: #000;
                                        border-radius: 50%;
                                        font-size: 10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        line-height: 10px;">&plus;</div>`
                        }
                      </button>
                    </div>`;
            })}
          </div>`;
        return result.replaceAll(",", "");
      }
      if (code === "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code === "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code === "Patient chart number") {
        return patient && patient.chartNumber;
      }
      if (code === "Patient age") {
        return patient && patient.age;
      }
      if (code === "Patient name") {
        return patient && patient.fullName;
      }
      if (code === "Patient nationality") {
        return _.get(patient, "nationality.name", "N/A");
      }
      if (code === "Patient primary insurance image") {
        for (const form in images) {
          for (const image in images[form]) {
            const { url } = images[form][image];

            if (url) {
              return `<img style="width: 100%; max-width: 300px" src=${url} alt="" />`;
            }
          }
        }
      }
      if (code === "Patient date of birth") {
        const dateFormat = localStorage.getItem("company-date-format");

        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(patient && patient.dateOfBirth),
          dateFormat,
        );
      }
      if (code === "Dentist Name") {
        return member && member.name;
      }
      if (code === "Check-in date time") {
        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(empty.checkedInDate),
          FULL_DATE_FORMAT,
        );
      }
      if (code === "Doctor license id") {
        return member && member.code;
      }
      if (code === "Patient last name") {
        return patient && patient.lastName;
      }
      if (code === "Patient first name") {
        return patient && patient.firstName;
      }
      if (code === "Patient primary card number") {
        return empty.primaryInsuranceCardNumber;
      }
      if (code === "Patient secondary card number") {
        return empty.secondaryInsuranceCardNumber;
      }
      if (code === "Patient gender") {
        return patient && patient.gender && patient.gender.name;
      }
      if (code === "Patient driver's licence number") {
        return patient && patient.documentDriverLicenseNumber;
      }
      if (code === "Patient social security number") {
        return patient && patient.documentSocialSecurityNumber;
      }
      if (code === "Patient social insurance number") {
        return patient && patient.documentSocialInsuranceNumber;
      }
      if (code === "Patient national id number") {
        return patient && patient.documentNationalIdNumber;
      }
      if (code === "Patient passport number") {
        return patient && patient.documentPasswordNumber;
      }
      if (code === "Clinic name") {
        return clinicName;
      }
      if (code === "Patient mobile number") {
        return patient && patient.mobilePhoneNumber;
      }
      return "N/A";
    });
  };

  useEffect(() => {
    if (consultation?.questions.length) {
      setForm(consultation);
    }
  }, [consultation?.questions]);

  useEffect(() => {
    if (document.querySelector("span[data-index]")) {
      const elements = document.querySelectorAll("span[data-index]");
      elements.forEach((element) => {
        const question = form.questions.find((i) => Number(i.id) === Number(element.dataset.index));
        if (question) {
          if (question.typeCode === questionTypeCodes.CANVAS) {
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setCanvasImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="canvas"
                id="canvasId"
                className="canvas-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.IMAGE) {
            if (!(question?.answerFile || question?.answerPhoto?.url)) {
              element.innerHTML = NA;
              return;
            }
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setPatientImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="mouth"
                id="mouthId"
                className="mouth-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.FORMULA) {
            element.innerHTML = Utils.calculateStringToNumber(question?.answer) || NA;
            return;
          }
          if (question?.options?.length) {
            if (question.typeCode.replace(/[^a-z]/gi, "") === questionTypeCodes.PICKLIST) {
              // const commentBoxes = document.querySelectorAll(".pick-list-comment");
              //
              // for (let i = 0; i < commentBoxes.length; i++) {
              //   commentBoxes[i].addEventListener("click", function () {
              //     insertAfter(question.id, answer.id);
              //   });
              // }
              return "";
            }
            const { options } = question;
            const value = options
              .map((option) => {
                let answer = "";
                if (option.selected) {
                  answer = `${option.otherValue || (!option.other ? option.title : "")} <br>`;
                  if (option.comment) {
                    answer = answer + `${option.comment} <br>`;
                  }
                }
                return answer;
              })
              .join("");
            element.innerHTML = value || NA;
          } else {
            element.innerHTML = question?.answer || question?.defaultAnswer || NA;
          }
        }
      });
    }
  }, [form.questions]);

  const getField = (questionTypeCode, questionId, visible, canAddComment = false) => {
    if (
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA
    ) {
      return (
        <FormTextArea
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    }
    if (questionTypeCode === questionTypeCodes.VALUE)
      return (
        <FormValue
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.DATE_PICKER)
      return (
        <FormDatePicker
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.RADIO_LIST)
      return (
        <FormRadioList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && !canAddComment)
      return (
        <FormCheckboxList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    // if (questionTypeCode.replace(/[^a-z]/gi, "") === questionTypeCodes.PICKLIST)
    //   return (
    //     <FormPickList
    //       setOverlay={setOverlay}
    //       visible={visible}
    //       editMode={editMode}
    //       questionId={questionId}
    //       setForm={setForm}
    //       form={form}
    //     />
    //   );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && canAddComment)
      return (
        <FormCheckboxListWithComment
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.CANVAS)
      return (
        <FormCanvas
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
          canvasImage={canvasImage}
        />
      );
    if (questionTypeCode === questionTypeCodes.FORMULA)
      return (
        <FormGenerateFormula
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.IMAGE)
      return (
        <FormPatientDocs
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
          patientImage={patientImage}
          questionTypeCode={questionTypeCode}
          questionTypeCodes={questionTypeCodes}
        />
      );
  };

  const imageUrlToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  if (form.template) {
    const { template } = form;
    return (
      <div className="medical-fill-in-form-question">
        <MedicalFillInFormOverlay
          overlay={overlay}
          onClose={() => setOverlay({ visible: false })}
        />
        <div className="medical-fill-in-form-question__title">{template.name}</div>
        <div
          className="medical-fill-in-form-question__body"
          style={{ overflowX: "unset" }}
          dangerouslySetInnerHTML={{ __html: template.templateBody }}
          onClick={(event) => setTemplateData(event)}
        />
        <div className="medical-fill-in-form-question__body"></div>
        <div className="medical-fill-in-form-question__actions">
          <Show if={editMode}>
            <button className="btn btn-primary" onClick={onSaveItem}>
              {lastStack?.type === medicalFillInFormItemTypes.new ? "Save" : "Update"}
            </button>
          </Show>
        </div>

        <Show if={questionsModalVisible}>
          <MedicalFillInFormQuestionModal form={form} questionId={overlay?.currentQuestionId} />
        </Show>
      </div>
    );
  }
  return null;
};

export default MedicalFIllInFormItem;
