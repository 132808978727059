import _ from "lodash";
import React from "react";
import { SidebarFavoritesList } from "./SidebarFavoritesList";

import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import { SidebarContentContainer } from "./SidebarContentContainer";
import { SidebarContentActions } from "./SidebarContentActions";
import ChartImageSelector from "../chart/side-bar/ChartImageSelector";
import { SidebarCategoriesList } from "./SidebarCategoriesList";
import { SidebarCategoryChildList } from "./SidebarCategoryChildList";
import { SidebarList } from "./SidebarList";
import { SidebarItem } from "./SidebarItem";
import { SidebarTreatmentDiagnosis } from "./SidebarTreatmentDiagnosis";

export function SidebarExisting({
  onSort,
  viewTab,
  onSearch,
  loadList,
  openItem,
  stack = [],
  favourites,
  isItem,
  getList,
  popStack,
  initItem,
  pushStack,
  changeTypeTab,
  onRequestClose,
  addRequestedTreament,
  changeActiveTooth,
  toothNumericType,
  item,
  member,
  isQuickMode,
  searchValue,
  openCategory,
  getFavorites,
  onActionClick,
  backFromItem,
  openFavorites,
  addDiagnosis,
  toggleFavorite,
  loadCategoryList,
  chartSideSeries,
  showImageSelector,
  getSidebarExistingList,
  categoryChildStack,
}) {
  const {
    favouritesIcons,
    activeFavouriteCode,
    imageSelectorVisible,
    sidebarCategoryList = [],
    activeFavouriteSeriesType,
  } = chartSideSeries;

  const existingList = sidebarCategoryList.filter((x) => x.useInExisting);

  const isAllCodes = _.last(stack) === SidebarStackTypes.AllCodes;
  const isFavorites = _.last(stack) === SidebarStackTypes.Favorites;
  const isDiagnosis = _.last(stack) === SidebarStackTypes.Diagnosis;
  const isSpeciality = _.last(stack) === SidebarStackTypes.Speciality;
  const isSpecialityChild = _.last(stack) === SidebarStackTypes.SpecialityChild;
  const isDiagnosisFavorites = _.last(stack) === SidebarStackTypes.DiagnosisFavorites;

  const categoryChildIndex = (isSpecialityChild || isSpeciality) && _.last(categoryChildStack);
  const categoryChild =
    _.isNumber(categoryChildIndex) && categoryChildIndex >= 0
      ? existingList[categoryChildIndex]
      : null;
  const categoryChildId = _.get(categoryChild, "id");
  const categoryChildTitle = _.get(categoryChild, "name");
  const categoryChildList = _.get(categoryChild, "children", []);

  return (
    <SidebarContentContainer
      onSearch={(v) => {
        onSearch(v);
        if (isAllCodes) {
          getSidebarExistingList(categoryChildId);
        }
      }}
      searchValue={searchValue}
      isItem={isItem || isSpecialityChild}
      title={
        isSpeciality || (!isQuickMode && _.isEmpty(stack))
          ? "Existing By Speciality"
          : isAllCodes
          ? "Existing"
          : isFavorites || (isQuickMode && _.isEmpty(stack))
          ? "Favorite Existing"
          : isSpecialityChild && categoryChildTitle
          ? categoryChildTitle
          : null
      }
    >
      {isItem && (
        <SidebarItem
          item={item}
          member={member}
          getList={getList}
          isExisting={true}
          backFromItem={backFromItem}
          initItem={(id) => initItem(id, SidebarTypes.Treatments)}
          popStack={popStack}
          pushStack={(s) => {
            pushStack(s);
            getList(SidebarTypes.Diagnosis);
          }}
          changeTypeTab={changeTypeTab}
          onRequestClose={onRequestClose}
          typeTab={SidebarTypes.Existing}
          chartSideSeries={chartSideSeries}
          toothNumericType={toothNumericType}
          changeActiveTooth={changeActiveTooth}
          addRequestedTreament={addRequestedTreament}
        />
      )}

      {Boolean(isFavorites || (isQuickMode && _.isEmpty(stack))) && (
        <SidebarFavoritesList
          onSort={onSort}
          viewTab={viewTab}
          openItem={openItem}
          favoritesList={favourites}
          getFavorites={getFavorites}
          toggleFavourite={toggleFavorite}
          typeTab={SidebarTypes.Existing}
          chartSideSeries={chartSideSeries}
          showImageSelector={showImageSelector}
        />
      )}

      {Boolean(isSpeciality || (_.isEmpty(stack) && !isQuickMode)) && (
        <SidebarCategoriesList
          viewTab={viewTab}
          isQuickMode={isQuickMode}
          searchValue={searchValue}
          loadList={loadCategoryList}
          specialitiesList={existingList}
          typeTab={SidebarTypes.Existing}
          favouritesIcons={favouritesIcons}
          showImageSelector={showImageSelector}
          openFavorites={() => openFavorites(SidebarStackTypes.Favorites)}
          openCategory={(index, isGroup, id) => openCategory(index, isGroup, id || categoryChildId)}
        />
      )}

      {isSpecialityChild && (
        <SidebarCategoryChildList
          list={categoryChildList}
          openCategory={(index, isGroup, id) => openCategory(index, isGroup, id || categoryChildId)}
        />
      )}

      {isAllCodes && (
        <SidebarList
          getList={getList}
          viewTab={viewTab}
          openItem={openItem}
          typeTab={SidebarTypes.Existing}
          toggleFavorite={toggleFavorite}
          chartSideSeries={chartSideSeries}
          categoryChildStack={categoryChildStack}
          loadList={() => loadList(categoryChildId)}
        />
      )}

      {Boolean(isDiagnosis || isDiagnosisFavorites) && (
        <SidebarTreatmentDiagnosis
          stack={stack}
          viewTab={viewTab}
          openItem={(i) => {
            addDiagnosis(i);
            popStack();

            if (isDiagnosis) {
              popStack();
            }
          }}
          typeTab={SidebarTypes.Diagnosis}
          toggleFavorite={toggleFavorite}
          chartSideSeries={chartSideSeries}
          loadList={() => loadList(categoryChildId)}
          onSort={onSort}
          favourites={favourites}
          getFavorites={getFavorites}
          showImageSelector={showImageSelector}
          onActionClick={(action) => {
            pushStack(action);
            getList(SidebarTypes.Diagnosis);
          }}
        />
      )}

      <SidebarContentActions>
        {Boolean(_.isEmpty(stack) && isQuickMode) && (
          <button
            type="button"
            onClick={() => onActionClick(SidebarStackTypes.Speciality)}
            className="btn btn-primary btn-block m-0"
          >
            Categories
          </button>
        )}

        {Boolean(_.isEmpty(stack) || isSpeciality) && (
          <button
            type="button"
            onClick={() => onActionClick(SidebarStackTypes.AllCodes)}
            style={!_.isEmpty(stack) || !isQuickMode ? { maxWidth: "90%" } : {}}
            className="btn btn-primary btn-block m-0"
          >
            All codes
          </button>
        )}
      </SidebarContentActions>

      <ChartImageSelector
        favouritesIcons={favouritesIcons}
        seriesType={SidebarTypes.Existing}
        activeFavouriteCode={activeFavouriteCode}
        imageSelectorVisible={imageSelectorVisible}
        activeFavouriteSeriesType={activeFavouriteSeriesType}
      />
    </SidebarContentContainer>
  );
}
