import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import PatientClaimsApprovalsPdfView from "../components/patient/claims-approvals/PatientClaimsApprovalsPdfView";
import { Routes } from "../constants/Routes";
import PatientFormsItem from "../components/patient/PatientFormsItem";
import PatientFormsEdit from "../components/patient/PatientFormsEdit";
import PatientAlertsHistory from "../components/patient/alerts/PatientAlertsHistory";
import PatientList from "../components/patient/PatientList";
import { PatientTerminal } from "../components/patient-terminal/PatientTerminal";
import PatientView from "../components/patient/PatientView";
import PatientMobile from "../components/patient/PatientMobile";
import PatientAddAnother from "../components/patient/PatientAddAnother";
import PatientChooseAccount from "../components/patient/PatientChooseAccount";
import { PatientAlerts } from "../components/patient/PatientAlerts";
import PatientContacts from "../components/patient/PatientContacts";
import PatientProfile from "../components/patient/PatientProfile";
import PatientFormsList from "../components/patient/PatientFormsList";
import PatientDetailsEdit from "../components/patient/PatientDetailsEdit";
import PatientAppointmentsList from "../components/patient/PatientAppointmentsList";
import PatientMedicalAlertsEdit from "../components/patient/alerts/PatientMedicalAlertsEdit";
import PatientClaimsApprovals from "../components/patient/claims-approvals";
import PatientMedicationAlertsEdit from "../components/patient/alerts/PatientMedicationAlertsEdit";

export default function PatientContainer() {
  return (
    <Switch>
      <Route
        component={PatientClaimsApprovalsPdfView}
        path={Routes.PatientClaimsApprovalsPdfView}
      />
      <Route component={PatientFormsItem} path={Routes.PatientFormsItem} />
      <Route component={PatientFormsEdit} path={Routes.PatientFormsEdit} />
      <Route component={PatientAlertsHistory} path={Routes.PatientAlertsHistory} />
      <Route component={PatientList} path={Routes.PatientList} />
      <Route component={PatientTerminal} path={Routes.PatientTerminal} />
      <Route component={PatientView} path={Routes.PatientView} />
      <Route component={PatientMobile} path={Routes.PatientMobile} />
      <Route component={PatientAddAnother} path={Routes.PatientAddAnother} />
      <Route component={PatientChooseAccount} path={Routes.PatientChooseAccount} />
      <Route component={PatientAlerts} path={Routes.PatientAlerts} />
      <Route component={PatientContacts} path={Routes.PatientContacts} />
      <Route component={PatientProfile} path={Routes.PatientProfile} />
      <Route component={PatientFormsList} path={Routes.PatientFormsList} />
      <Route component={PatientDetailsEdit} path={Routes.PatientDetailsEdit} />
      <Route component={PatientAppointmentsList} path={Routes.PatientAppointmentsList} />
      <Route component={PatientMedicalAlertsEdit} path={Routes.PatientMedicalAlertsEdit} />
      <Route component={PatientClaimsApprovals} path={Routes.PatientClaimsApprovals} />
      <Route component={PatientMedicationAlertsEdit} path={Routes.PatientMedicationAlertsEdit} />
    </Switch>
  );
}
