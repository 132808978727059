import _ from "lodash";
import React from "react";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";

import { connect } from "react-redux";
import {
  insurancePhotoSidebarHide,
  insurancePhotoSidebarNextImage,
  insurancePhotoSidebarPrevImage,
} from "../../actions/insuranceActions";
import closeImage from "../../assets/images/close-circle.png";
import PrevImage from "../../assets/images/docs/UIBarButtonItemArrowOutlineLeft.png";
import NextImage from "../../assets/images/docs/UIBarButtonItemArrowOutlineRight.png";

const enhancer = connect(null, {
  insurancePhotoSidebarHide,
  insurancePhotoSidebarPrevImage,
  insurancePhotoSidebarNextImage,
});
class InsuranceShowPhotoSidebar extends React.Component {
  render() {
    const { images, imageIndex, show } = this.props;

    const imageNumber = imageIndex.slice(5);

    let url = "";

    if (!_.isEmpty(images)) {
      url = _.get(images[imageIndex], "url", "");
    }

    return (
      <Modal
        show={show}
        onHide={this.props.insurancePhotoSidebarHide}
        className="insurance-photo-sidebar"
        title={
          <div>
            <button
              className="btn btn-link pull-left"
              onClick={this.props.insurancePhotoSidebarHide}
            >
              ❮ &nbsp; BACK
            </button>
            <div>{imageNumber} of 3</div>
          </div>
        }
        actions={
          <div>
            <div className="col-sm-6 text-right">
              <div onClick={this.props.insurancePhotoSidebarPrevImage} className="button-prev">
                <img src={PrevImage} alt="" />
              </div>
            </div>
            <div className="col-sm-6 text-left">
              <div onClick={this.props.insurancePhotoSidebarNextImage} className="button-next">
                <img src={NextImage} alt="" />
              </div>
            </div>
          </div>
        }
      >
        <div>
          <Show if={url}>
            <div className="image contain">
              <img src={url} alt="" className="img-responsive" />
            </div>
          </Show>
          <Show if={!url}>
            <div className="image">
              <img src={closeImage} alt="" className="img-responsive" />
            </div>
          </Show>
        </div>
      </Modal>
    );
  }
}
export default enhancer(InsuranceShowPhotoSidebar);
