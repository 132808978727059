import { httpClient } from "./BaseApi";

const PROCEDURE_ITEM_URL = "api/treatment/procedure/item.json";
const PROCEDURE_LIST_URL = "api/treatment/procedure/list.json";

const PROCEDURE_NEED_SIGN_LIST_URL = "api/treatment/procedure/need-sign/list.json";

const ProcedureApi = {
  postItem: (data) => httpClient.post(PROCEDURE_ITEM_URL, { data }),
  getList: (params) => httpClient.get(PROCEDURE_LIST_URL, { params }),
  getItem: (params) => httpClient.get(PROCEDURE_ITEM_URL, { params }),
  needSignIn: (params) => httpClient.get(PROCEDURE_NEED_SIGN_LIST_URL, { params }),
  getSignInList: (params) => httpClient.get(PROCEDURE_NEED_SIGN_LIST_URL, { params }),
};

export default ProcedureApi;
