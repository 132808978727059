import _ from "lodash";
import React, { useMemo } from "react";
import MemberImage from "../widgets/MemberImage";
import AvatarImage from "../../assets/images/default_avatar.png";
import { getCompanyFormatDate } from "../../helpers/DateUtils";
import { useSelector } from "react-redux";
import { ViewTypes } from "../../constants/Constants";
import moment from "moment";

export default function CalendarTitles({
  columns,
  members,
  allowAppointmentMultiBooking,
  calendarWeaklyMode,
}) {
  const points = useSelector((state) => state.calendar.doctorsPoints);
  const columnView = localStorage.getItem("columnView");
  const pointToDoc = (elem) => {
    for (const doctor in points) {
      for (const point in points[doctor].dentalPoints) {
        if (point == elem.dentalPointId) {
          return points[doctor].id;
        }
      }
    }
    return 0;
  };

  const avatar = (column) => {
    if (column.title?.id) {
      return members[column.title.id]?.photoUrl ? members[column.title.id]?.photoUrl : AvatarImage;
    } else if (pointToDoc(column) && members[pointToDoc(column)]?.photoUrl) {
      return members[pointToDoc(column)].photoUrl;
    }
    return AvatarImage;
  };
  const name = (column) => {
    if (column.title.dentistName) {
      return column.title.dentistName;
    } else {
      return members[pointToDoc(column)].firstName + " " + members[pointToDoc(column)].lastName;
    }
  };

  const multiBookingTitle = (column) => {
    let title = "";
    if (allowAppointmentMultiBooking && !calendarWeaklyMode) {
      columns
        .filter((item) => item.dentistId === column.dentistId)
        .forEach((item, index, rooms) => {
          title = title + item.title.title + (index + 1 !== rooms.length ? ", " : "");
        });
      return title;
    }
    return _.get(column, "title.point") ? _.get(column, "title.point") : column.title.title;
  };

  const days = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const filteredColumns = [];
  columns.forEach((item) => {
    if (!filteredColumns.find((i) => i.dentistId === item.dentistId)) filteredColumns.push(item);
  });

  const $titles = (allowAppointmentMultiBooking && !calendarWeaklyMode
    ? filteredColumns
    : columns
  ).map((column, index) => {
    // console.log("id------", avatar(column));
    return (
      <div key={index} className={`calendar-title-item ${columnView}`}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {avatar(column) && (
            <div>
              {/*<img src={avatar(column)} />*/}
              <MemberImage
                size={40}
                style={{
                  marginRight: "6px",
                }}
                photoUrl={avatar(column)}
              />
            </div>
          )}
          <div
            style={{
              flex: 1,
              display: "flex",
              // alignItems: columnView==='comfort'?'center':null,
              justifyContent: columnView === "comfort" ? "space-between" : "center",
              flexDirection: columnView === "compact" ? "column" : null,
              flexWrap: columnView === "compact" ? "wrap" : null,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                }}
                className="doctor"
              >
                {name(column)}
              </div>
              {/*{_.get(column, "title.point") && (*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      display: "flex",*/}
              {/*      flex: 1,*/}
              {/*      alignItems: "center",*/}
              {/*    }}*/}
              {/*    className="point"*/}
              {/*  >*/}
              {/*    {_.get(column, "title.point")}*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            {column?.date && columnView === "comfort" && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: columnView === "comfort" ? "flex-end" : null,
                  alignItems: "center",
                  // paddingRight: 12,
                }}
                className="point"
              >
                {days[moment(column.date).isoWeekday()] + ` ` + getCompanyFormatDate(column.date)}
              </div>
            )}

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: columnView === "comfort" ? "flex-end" : null,
                alignItems: "center",
                // marginLeft: 8,
              }}
              className="point"
            >
              {multiBookingTitle(column)}
            </div>
            {column?.date && columnView === "compact" && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: columnView === "comfort" ? "flex-end" : null,
                  alignItems: "center",
                  // paddingRight: 12,
                }}
                className="point"
              >
                {days[moment(column.date).isoWeekday()] + ` ` + getCompanyFormatDate(column.date)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  const width = useMemo(() => {
    if (columnView === "compact") {
      return columns.length * 200;
    }

    return (
      (allowAppointmentMultiBooking && !calendarWeaklyMode ? filteredColumns : columns).length * 400
    );
  }, [
    columnView,
    (allowAppointmentMultiBooking && !calendarWeaklyMode ? filteredColumns : columns).length,
  ]);

  return (
    <div className="calendar-title" style={{ width }}>
      <div className="calendar-title-wrap">{$titles}</div>
    </div>
  );
}
