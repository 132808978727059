import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChartPlanList from "./ChartPlanList";
import ChartPlanPDF from "./ChartPlanPDF";
import ChartPlanListFor from "./ChartPlanListFor";

import { planStackCodes } from "../../../../constants/chart-sidebar/planStackCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePlanActions from "../../../../actions/chartSidePlanActions";

const enhancer = connect(null, bindActions({ chartSidePlanActions }));

class ChartPlan extends React.Component {
  componentDidMount() {
    this.props.chartSidePlanActions.initPlan();
  }

  render() {
    const { chartSidePlan } = this.props;
    const { stack } = chartSidePlan;

    const stackItem = _.last(stack);

    if (stackItem.code === planStackCodes.list) {
      return <ChartPlanList chartSidePlan={chartSidePlan} />;
    }

    if (stackItem.code === planStackCodes.pdf) {
      return <ChartPlanPDF chartSidePlan={chartSidePlan} />;
    }

    if (stackItem.code === planStackCodes.forList) {
      return <ChartPlanListFor chartSidePlan={chartSidePlan} />;
    }
  }
}

export default enhancer(ChartPlan);
