import { httpClient } from "./BaseApi";

const ADVANCED_PAYMENT_LIST_URL = "api/billing/advanced/payment/request/list.json";
const ADVANCED_PAYMENT_ITEM_URL = "api/billing/advanced/payment/request/item.json";
const METHOD_LIST_URL = "api/billing/payment_method/choose.json";
const WALLET_LIST_URL = "api/billing/wallet/group/choose.json";
const OTHER_LIST_URL = "api/billing/other-payment-methods/choose.json";

const AdvancedPaymentApi = {
  getList: (params) => httpClient.get(ADVANCED_PAYMENT_LIST_URL, { params }),
  saveItem: (data) => httpClient.post(ADVANCED_PAYMENT_ITEM_URL, { data }),
  getMethodList: () => httpClient.get(METHOD_LIST_URL),
  getWalletList: (params) => httpClient.get(WALLET_LIST_URL, { params }),
  getOtherList: () => httpClient.get(OTHER_LIST_URL),
};

export default AdvancedPaymentApi;
