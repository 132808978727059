import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Back from "../widgets/Back";
import { Routes } from "../../constants/Routes";
import { getClinicColors } from "../../actions/appointmentActions";

export default function Appointment() {
  const dispatch = useDispatch();

  return (
    <div className="settings-body">
      <div className="title" style={{ display: "flex" }}>
        <Back style={{ position: "relative" }} />
        <div className="name" style={{ flex: "1 1 0%" }}>
          Appointment settings
        </div>
        <button
          type="button"
          style={{ position: "relative" }}
          onClick={() => dispatch(getClinicColors())}
          className="btn btn-link"
        >
          Get Clinic Colors
        </button>
      </div>
      <div className="body">
        <div className="list-group">
          <Link to={Routes.SettingsAppointmentStatus} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment status colors
          </Link>
          <Link to={Routes.SettingsAppointmentType} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment type colors
          </Link>
          <Link to={Routes.SettingsAppointmentDoctor} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment {localStorage.getItem("doctorLabel")} colors
          </Link>
          <Link to={Routes.SettingsAppointmentColorType} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment color type
          </Link>
          <Link to={Routes.SettingsAppointmentCalendar} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment calendar view
          </Link>
          <Link to={Routes.SettingsTimeView} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment check in time view
          </Link>
          <Link to={Routes.SettingsAppointmentNote} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment note view
          </Link>
          <Link to={Routes.SettingsAppointmentHideButtons} className="list-group-item">
            <div className="pull-right">❯</div>
            Appointment hide buttons
          </Link>
          <Link to={Routes.SettingsBlockedTimeSlot} className="list-group-item">
            <div className="pull-right">❯</div>
            Blocked Time Slot Colors
          </Link>
        </div>
      </div>
    </div>
  );
}
