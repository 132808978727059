import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";

import moment from "moment";
import FlexBox from "../../ui/FlexBox";
import Show from "../../widgets/Show";
import DataList from "../../widgets/DataList";
import {
  getPatientDetails,
  getPerioExamList,
  perioExamStartItem,
  perioExamVoidItem,
} from "../../../actions/patientActions";
import { Button } from "../../ui/Button";
import Utils from "../../../helpers/Utils";
import { Routes } from "../../../constants/Routes";
import ChartPatient from "../top-panel/ChartPatient";
import { AppLayout } from "../../app-layout/AppLayout";
import { getTeeth } from "../../../actions/chartActions";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import { getFolderList } from "../../../actions/folderActions";
import { showMessage, startLoader, stopLoader } from "../../../actions/loaderActions";
import { closeSimpleModal, openSimpleModal } from "../../../actions/simpleModalActions";

export const PerioExam = () => {
  const store = useSelector((store) => store);
  const {
    patient: { patientDetails, perioExamList },
    chart: { teeth },
    session: { permissions },
  } = store;

  const location = useLocation();
  const history = useHistory();
  const query = parseQuery(location.search);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("active");

  useEffect(() => {
    dispatch(getPatientDetails({ patientKey: query.patientKey }));
    dispatch(getTeeth({ patientKey: query.patientKey }));
    dispatch(getFolderList({ patientKey: query.patientKey }));

    return () => {
      dispatch({ type: "FOLDER_RESET" });
    };
  }, []);

  useEffect(() => {
    dispatch(getPerioExamList({ patientKey: query.patientKey, voided: activeTab === "void" }));
  }, [activeTab]);

  const up = [];
  const down = [];

  Utils.objectForEach(teeth, (tooth, toothPositionCode) => {
    const index = toothPositionCode.slice(1);

    if (index <= 16) {
      up[index - 1] = tooth;
    } else {
      down[32 - index] = tooth;
    }
  });

  const canEdit = (item) => {
    const less24hours = Date.now() - item < 24 * 60 * 60 * 1000;
    const editPermission = permissions.indexOf("EDIT_PERIO_EXAM_LIST") >= 0;
    const edit24HoursPermission = permissions.indexOf("EDIT_PERIO_EXAM_LIST_WITHIN_24_HOURS") >= 0;

    return editPermission || (less24hours && edit24HoursPermission);
  };

  const startButtonHandle = (isNew) => {
    if (isNew === 0 && perioExamList[0] && !canEdit(perioExamList[0])) {
      dispatch(showMessage("Permission denied"));
    } else {
      dispatch(startLoader());
      dispatch(perioExamStartItem({ isNew: isNew, patientKey: query.patientKey }))
        .then(
          (res) =>
            res &&
            history.push(
              createUrl(Routes.DashboardPerioExamView, {
                query: {
                  patientKey: query.patientKey,
                },
              }),
            ),
        )
        .catch((err) => stopLoader(err))
        .finally(() => dispatch(stopLoader()));
    }
  };

  const warnModalHandler = (item) => {
    return dispatch(
      openSimpleModal({
        body: "Do you want to void this exam ?",
        footer: (
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger ml-3"
              onClick={() => {
                dispatch(closeSimpleModal());
              }}
            >
              No
            </button>
            <button
              className="btn btn-success ml-3"
              onClick={() => {
                dispatch(
                  perioExamVoidItem({
                    patientKey: query.patientKey,
                    historyDate: item?.historyDate,
                    voided: true,
                  }),
                );
                dispatch(closeSimpleModal());
              }}
            >
              Yes
            </button>
          </div>
        ),
      }),
    );
  };
  return (
    <AppLayout
      backButtonTitle="Patient Perio Exam"
      headerClassName="perio-exam-header"
      title={
        <FlexBox
          justify="center"
          className="perio-exam-chart-info"
          style={{
            minWidth: "260px",
            flexDirection: "column",
          }}
          flex={true}
        >
          <ChartPatient patient={patientDetails} />
        </FlexBox>
      }
    >
      <FlexBox container={8} className="perio-exam">
        <FlexBox gutter={8} direction="column" flex={true} className="chart-page">
          <FlexBox flex={true} className="perio-exam-body">
            <FlexBox gutter={8} flex={true}>
              <FlexBox direction={"column"} className="data-list-container p-0">
                <FlexBox flex={false} className="perio-tab">
                  <ButtonGroup aria-label="perio tabs" className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setActiveTab("active")}
                      className={`btn ${
                        activeTab === "active" ? "btn-default" : "btn-primary"
                      } btn-block m-0`}
                    >
                      Active exams
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab("void")}
                      className={`btn ${
                        activeTab === "void" ? "btn-default" : "btn-primary"
                      } btn-block m-0`}
                    >
                      Voided exams
                    </button>
                  </ButtonGroup>
                </FlexBox>

                <DataList style={{ paddingTop: "1px" }}>
                  {perioExamList.map((item, idx) => (
                    <FlexBox key={idx} className="perio-exam-data-list-item">
                      <FlexBox
                        flex={true}
                        justify="space-between"
                        align="center"
                        className="perio-exam-date"
                        onClick={() => {
                          history.push(
                            createUrl(Routes.DashboardPerioExamView, {
                              query: {
                                date: item?.historyDate,
                                patientKey: query.patientKey,
                                voided: item?.voided,
                              },
                            }),
                          );
                        }}
                      >
                        <FlexBox flex={false}>{idx + 1}</FlexBox>
                        {moment(item?.historyDate).format("DD/MM/YYYY")}
                      </FlexBox>
                      {activeTab === "active" && (
                        <FlexBox flex={false}>
                          <Button className="ml-2" onClick={() => warnModalHandler(item)}>
                            Void
                          </Button>
                        </FlexBox>
                      )}
                    </FlexBox>
                  ))}
                </DataList>
                <Show if={activeTab === "active"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "20px",
                    }}
                  >
                    <Button style={{ width: "40%" }} onClick={() => startButtonHandle(1)}>
                      Start exam
                    </Button>
                    <Button style={{ width: "40%" }} onClick={() => startButtonHandle(0)}>
                      Edit exam
                    </Button>
                  </div>
                </Show>
              </FlexBox>
              <FlexBox flex={true} />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AppLayout>
  );
};

// export default PerioExam1;
