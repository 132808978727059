import { httpClient } from "./BaseApi";

const GET_TYPE_AHEAD_URL = "api/consent-form/typeahead.json";
const GET_CATEGORY_LIST_URL = "api/consent-form/category/choose.json";

const ConsentFormApi = {
  getTypeAhead: (params) => httpClient.get(GET_TYPE_AHEAD_URL, { params }),
  getCategoryList: (params) => httpClient.get(GET_CATEGORY_LIST_URL, { params }),
};

export default ConsentFormApi;
