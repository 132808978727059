import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactSpinner from "react-spinjs-fix";

import Show from "../../widgets/Show";
import PrintModal from "./PrintModal";
import { DatePicker } from "../../ui/DatePicker";
import bindActions from "../../../helpers/bindActions";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../../helpers/DateUtils";
import printImage from "../../../assets/images/print_icon_black.png";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DoctorSelectWrapper } from "../../doctor/DoctorSelectWrapper";

const enhancer = connect(
  ({
    patientRegister: {
      claimsList,
      claimsStatusList,
      claimsDocTypeList,
      claimsListTotalCount,
      fetchingClaims,
    },
  }) => ({
    claimsList,
    claimsStatusList,
    claimsDocTypeList,
    claimsListTotalCount,
    fetchingClaims,
  }),
  bindActions({ patientRegisterActions }),
);
class PatientClaimsList extends Component {
  static propTypes = {
    claimsList: PropTypes.array,
    claimsStatusList: PropTypes.array,
    claimsDocTypeList: PropTypes.array,
    fetchingClaims: PropTypes.bool,
    patientKey: PropTypes.string,
    patientRegisterActions: PropTypes.object,
    clinic: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchForm: {
        statusCode: "UnSent",
        startDate: null,
        endDate: null,
      },
      showModal: false,
      view: false,
      selectedItemId: "",
      item: {},
    };
  }

  UNSAFE_componentWillMount() {
    const requestData = {
      patientKey: this.props.patientKey,
      statusCode: this.state.searchForm.statusCode,
      doctorId: this.state.searchForm.memberId,
      patientChartNumber: this.state.searchForm.chartNumber,
      limit: 25,
    };
    if (this.props.clinic?.value) {
      requestData.clinicId = this.props.clinic?.value;
    }
    const {
      patientRegisterActions: { getPatientClaimsList },
    } = this.props;
    getPatientClaimsList(requestData, true);
  }

  handleChangeFormField = (event, field) => {
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        [field]: event,
      },
    });
  };

  filterSubmit = () => {
    const { searchForm } = this.state;
    const requestData = {
      patientKey: this.props.patientKey,
      ...searchForm,
      clinicId: this.props.clinic?.value,
      limit: 25,
    };
    requestData.startDate = getCompanyStartOfDay(searchForm.startDate) || null;
    requestData.endDate = getCompanyEndOfDay(searchForm.endDate) || null;
    const {
      patientRegisterActions: { getPatientClaimsList },
    } = this.props;
    getPatientClaimsList(requestData);
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;
    const {
      props: {
        patientRegisterActions: { loadPatientClaimsList },
        patientKey,
      },
      state: { searchForm },
    } = this;
    const requestData = {
      patientKey,
      ...searchForm,
    };
    requestData.startDate = getCompanyStartOfDay(searchForm.startDate) || null;
    requestData.endDate = getCompanyEndOfDay(searchForm.endDate) || null;

    if (scrollBottom) {
      loadPatientClaimsList(requestData);
    }
  };

  setModalVisible = (item, view = false) => {
    this.setState({
      showModal: !this.state.showModal,
      selectedItemId: (item && item.id) || null,
      item: item || {},
      view,
    });
  };

  render() {
    const { claimsList, claimsStatusList, claimsDocTypeList, fetchingClaims } = this.props;
    const { searchForm, selectedItemId, showModal, view, item } = this.state;
    const cols = 6;
    const list = (claimsList || []).map((item) => {
      return (
        <div className="appointments-list-row" key={item.id}>
          <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>{item.id || ""}</div>
          <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>
            {moment(item.createdDate).format(DATE_FORMAT_LEGACY) || ""}
          </div>
          <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>
            {item.payerName || ""}
          </div>
          <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>
            {item.dentistName || ""}
          </div>
          <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>{item.net || ""}</div>
          <div
            style={{
              whiteSpace: "nowrap",
              width: `${100 / cols}%`,
              position: "relative",
            }}
          >
            {item.status.name || ""}
            <div style={{ position: "absolute", right: 0, top: "18%" }}>
              <div
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  marginRight: "1rem",
                }}
                role="button"
                onClick={() => this.setModalVisible(item)}
              >
                <img width={20} height={20} alt="print" className="icon" src={printImage} />
              </div>
            </div>
          </div>
        </div>
      );
    });
    const FilterOptions = claimsStatusList.length
      ? claimsStatusList.map((status) => {
          return { label: status.name, value: status.code };
        })
      : [];
    return (
      <div>
        <div
          className="mt-3"
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          <div className="row">
            <div className="col-sm-10">
              <div className="row">
                <div className="col-sm-4">
                  <div className="claims-filter-field">
                    <div className="mr-3">Doctors:</div>
                    <div
                      className="form-group"
                      style={{
                        margin: 0,
                        lineHeight: "30px",
                        textAlign: "initial",
                      }}
                    >
                      <DoctorSelectWrapper
                        placeholder="Doctors"
                        allLabel="All"
                        allValue="all"
                        isSearchable={true}
                        isDisabled={false}
                        // value={{ value: searchForm.doctorId }}
                        onChange={(x) => {
                          // handleChangeFormField(x.value || "all", "doctorId")
                          this.handleChangeFormField(x.value == "all" ? 0 : x.value, "doctorId");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="claims-filter-field">
                    <div>Chart number:</div>
                    <input
                      className="form-control"
                      placeholder=""
                      value={searchForm.patientChartNumber}
                      onChange={({ target }) => {
                        // handleChangeFormField(target.value, "patientChartNumber")
                        this.handleChangeFormField(target.value, "patientChartNumber");
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="claims-filter-field">
                    <div className="mr-3">Filter by status:</div>
                    <div
                      className="form-group"
                      style={{
                        margin: 0,
                        lineHeight: "30px",
                        textAlign: "initial",
                      }}
                    >
                      <SelectPicker
                        placeholder="Filter by status"
                        value={{ value: searchForm.statusCode }}
                        options={FilterOptions}
                        onChange={({ value }) => {
                          this.handleChangeFormField(value, "statusCode");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="claims-filter-field">
                    <div>Start Date:</div>
                    <DatePicker
                      className="form-control"
                      wrapperClassName="w-100"
                      placeholder="Start Date"
                      selected={searchForm.startDate}
                      onChange={(event) => this.handleChangeFormField(event, "startDate")}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="claims-filter-field">
                    <div>End Date:</div>
                    <DatePicker
                      className="form-control"
                      wrapperClassName="w-100"
                      placeholder="End Date"
                      selected={searchForm.endDate}
                      onChange={(event) => this.handleChangeFormField(event, "endDate")}
                    />
                  </div>
                </div>
                <div className="col-sm-4" style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-primary btn-block"
                    style={{ marginTop: "20px" }}
                    onClick={this.filterSubmit}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="claims-approvals-list" style={{ height: "91%" }}>
          <div className="appointments-list-table" style={{ height: "100%" }}>
            <div className="appointments-list-header">
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>Claim id</div>
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>Created date</div>
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>Payer id</div>
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>Clinician</div>
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>NET</div>
              <div style={{ whiteSpace: "nowrap", width: `${100 / cols}%` }}>Status</div>
            </div>
            <div
              className="appointments-list-body"
              style={{ height: "100%", overflowY: "scroll" }}
              onScroll={this.loadList}
            >
              {list}
              <Show if={fetchingClaims}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
          <PrintModal
            show={showModal}
            setModalVisible={this.setModalVisible}
            selectedItemId={selectedItemId}
            view={view}
            item={item}
            claimsDocTypeList={claimsDocTypeList}
            claimRequestTypeCode="CLAIM_REQUEST"
          />
        </div>
      </div>
    );
  }
}

export default enhancer(PatientClaimsList);
