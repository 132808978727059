import _, { last, toFinite } from "lodash";
import moment from "moment";

import Utils from "../helpers/Utils";
import createReducer from "../helpers/createReducer";

import {
  APPOINTMENT_NOTE_MODAL_HIDE,
  APPOINTMENT_NOTE_MODAL_SHOW,
  CALENDAR_CHOOSE_MASTER_TYPES,
  CALENDAR_CLEAR_SELECTED_DOCTORS,
  CALENDAR_CURRENT_FREEPOINT_INDEX,
  CALENDAR_DOCTORS_RESET,
  CALENDAR_DOCTORS_SELECT,
  CALENDAR_RESET_COLUMNS,
  CALENDAR_SAVE_SELECTED_DOCTORS,
  CALENDAR_SEARCH_APPOINTMENT_ERROR,
  CALENDAR_SEARCH_APPOINTMENT_START,
  CALENDAR_SEARCH_APPOINTMENT_SUCCESS,
  CALENDAR_SEARCH_OLD_APPOINTMENT_ERROR,
  CALENDAR_SEARCH_OLD_APPOINTMENT_START,
  CALENDAR_SEARCH_OLD_APPOINTMENT_SUCCESS,
  CALENDAR_SEARCH_ALL_APPOINTMENT_START,
  CALENDAR_SEARCH_ALL_APPOINTMENT_SUCCESS,
  CALENDAR_SEARCH_ALL_APPOINTMENT_ERROR,
  CALENDAR_SELECT_CLINIC_ID,
  CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_HIDE,
  CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_SHOW,
  CALENDAR_SEND_DETAILS_TO_EMAIL_SUCCESS,
  CALENDAR_SET_COLUMN_VIEW_TYPE,
  CHOOSE_CALENDAR_DOCTOR,
  CHOOSE_CALENDAR_POINT,
  CHOOSE_DOCTORS_POINT,
  CLEAR_MULTI_DENTIST_CALENDAR,
  CLEAR_PUSH_NOTIFICATION_SWITCH_APPOINTMENT,
  FIND_APPOINTMENT_ADD_SEaRCH_VALUES,
  FIND_APPOINTMENT_SIDEBAR_HIDE,
  FIND_APPOINTMENT_SIDEBAR_SHOW,
  FIND_OPEN_SLOT_SET_FROM_DATE,
  FIND_OPEN_SLOT_SET_TO_DATE,
  FIND_OPEN_SLOT_SET_WEEK_DAYS,
  FIND_OPEN_SLOT_SIDEBAR_HIDE,
  FIND_OPEN_SLOT_SIDEBAR_SHOW,
  GET_CALENDAR_DOCTORS_LIST_SUCCESS,
  GET_CALENDAR_LATE_APPOINTMENTS_SUCCESS,
  GET_CALENDAR_MASTER_TYPE_SUCCESS,
  GET_CALENDAR_PRINT_DETAILS_START,
  GET_CALENDAR_PRINT_DETAILS_SUCCESS,
  GET_CALENDAR_START,
  GET_CALENDAR_SUCCESS,
  GET_DENTAL_POINT_SUCCESS,
  GET_DENTIST_CALENDAR_START,
  GET_DENTIST_CALENDAR_SUCCESS,
  GET_DENTIST_WITH_POINTS_SUCCESS,
  GET_DOCTOR_LOCATION_SUCCESS,
  GET_DOCTOR_POINTS_SUCCESS,
  GET_MULTI_DENTIST_CALENDAR_SUCCESS,
  INIT_CALENDAR,
  OPEN_SLOT_SEARCH_FREE_POINTS_SUCCESS,
  OPEN_SLOT_SEARCH_MODE_TOGGLE,
  OPEN_SLOT_SET_DOCTOR_ID,
  OPEN_SLOT_SET_FROM_TIME,
  OPEN_SLOT_SET_FROM_TIME_VALUE,
  OPEN_SLOT_SET_TO_TIME,
  OPEN_SLOT_SET_TO_TIME_VALUE,
  OPEN_SLOT_SET_WEEK_DAYS,
  RESET_CALENDAR_STATE,
  SEARCH_APPOINTMENT_DATA_OBJECT,
  SET_ACTIVE_DOCTOR_ID,
  SET_CALENDAR_DATE,
  SET_CALENDAR_VIEW_MODE,
  SET_CALENDAR_WEAKLY_MODE,
  SWITCH_OPEN_SLOT_RESERVED,
  SWITCH_PUSH_NOTIFICATION_SWITCH_APPOINTMENT,
  SET_CLINIC_NOTES_EXIST,
  GET_ON_DRAG_APPOINTMENTS_SUCCESS,
} from "../actions/calendarActions";
import { STATUS_TYPES } from "../constants/StatusTypes";
import { getCompanyFormatDate, stringTimeToMilliseconds } from "../helpers/DateUtils";

const defaultState = {
  reserved: false,
  multiDentist: [],
  activePoints: {},
  appointmentNoteModalVisible: false,
  appointmentNoteModal: "",
  columns: [],
  workTime: {
    startTime: "08:00",
    endTime: "18:00",
    startTimeForNewPatient: "10:00",
    endTimeForNewPatient: "17:00",
  },
  calendarDoctors: {},
  currentFreepointIndex: -1,
  doctors: {},
  points: {},
  pushNotificationAppointments: {},
  doctorsPoints: {},
  date: moment().startOf("day"),
  printDetails: "",
  sendToEmailModalVisible: false,
  findOpenSlotSidebarVisible: false,
  findOpenSlotFromDate: null,
  findOpenSlotToDate: null,
  findOpenSlotWeekDays: {},
  searchAppointmentFetching: false,
  searchAppointmentTotalCount: 0,
  searchOldAppointmentFetching: false,
  searchOldAppointmentTotalCount: 0,
  searchAllAppointmentFetching: false,
  searchAllAppointmentTotalCount: 0,
  searchAppointmentDataObject: {},
  dentalPoint: [],
  freePoints: {},
  doctorId: 0,
  openSlotFromTime: "",
  openSlotToTime: "",
  searchMode: true,
  openSlotUserFromTime: "",
  openSlotUserToTime: "",
  openSlotWeekDays: "",

  searchValues: {},

  findAppointmentSidebarVisible: false,
  appointments: [],
  appointmentsOld: [],
  appointmentsAll: [],
  masterTypes: [],
  lateAppointments: [],
  totalBeingSeen: 0,
  totalCheckedIn: 0,
  totalAssessment: 0,

  activeDoctorId: 0,
  isActiveDoctor: false,
  resetColumns: false,
  clinicId: 0,
  calendarWeaklyMode: localStorage.getItem("appointmentsWeekView")
    ? JSON.parse(localStorage.getItem("appointmentsWeekView"))
    : false,
  calendarViewMode: "room",
  clinicNotesExist: -1,

  selectedDoctors: [{}],
  item: {},
  dentalPointsIds: localStorage.getItem("dentalPointsId")
    ? JSON.parse(localStorage.getItem("dentalPointsId"))
    : [],
  onDragAppointmentsList: [],
  appointmentDndCount: 0,
};

function formatWorkTime(workTime) {
  const startWorkTime = _.get(workTime, "startTime", "08:00");
  const endWorkTime = _.get(workTime, "endTime", "19:00");

  /* eslint-disable-next-line */
  localStorage.setItem("CalendarStartWorkTime", stringTimeToMilliseconds(startWorkTime));
  /* eslint-disable-next-line */
  localStorage.setItem("CalendarEndWorkTime", stringTimeToMilliseconds(endWorkTime));
}

const reducers = {
  [GET_ON_DRAG_APPOINTMENTS_SUCCESS](state, { data }) {
    const allAppointments = data.points
      .reduce((acc, item) => {
        if (item.list) {
          acc.push(...item.list);
        }
        return acc;
      }, [])
      .filter((item) => Boolean(item.inBoxDragged));

    return {
      ...state,
      onDragAppointmentsList: allAppointments,
      appointmentDndCount: allAppointments.length,
    };
  },
  [CLEAR_PUSH_NOTIFICATION_SWITCH_APPOINTMENT](state) {
    return {
      ...state,
      pushNotificationAppointments: {},
    };
  },
  [SWITCH_PUSH_NOTIFICATION_SWITCH_APPOINTMENT](state, { id }) {
    return {
      ...state,
      pushNotificationAppointments: {
        ...state.pushNotificationAppointments,
        [id]: !state.pushNotificationAppointments[id],
      },
    };
  },
  [FIND_APPOINTMENT_ADD_SEaRCH_VALUES](state, { values }) {
    return {
      ...state,
      searchValues: values,
    };
  },
  [CALENDAR_CLEAR_SELECTED_DOCTORS](state) {
    return {
      ...state,
      selectedDoctors: [{}],
      dentalPointsIds: [],
      activePoints: {},
    };
  },
  [CALENDAR_SAVE_SELECTED_DOCTORS](state, { values, dentalPointsIds }) {
    return {
      ...state,
      selectedDoctors: values,
      dentalPointsIds: dentalPointsIds,
    };
  },
  [SWITCH_OPEN_SLOT_RESERVED](state, { def }) {
    const { reserved } = state;

    return {
      ...state,
      reserved: _.isBoolean(def) ? def : !reserved,
    };
  },
  [CALENDAR_DOCTORS_RESET](state) {
    return {
      ...state,

      calendarDoctors: _.map(state.calendarDoctors, (x) => ({
        ...x,
        active: false,
      })).reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      ),
    };
  },
  [CALENDAR_DOCTORS_SELECT](state, { id = "" }) {
    const { calendarDoctors = [] } = state;

    const ids = id.split(",");
    const doctorId = toFinite(last(ids));

    if (doctorId > 0) {
      return {
        ...state,
        calendarDoctors: _.map(calendarDoctors, (x) => {
          return {
            ...x,
            active: x.id === doctorId ? !x.active : x.active,
          };
        }).reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item,
          };
        }, {}),
      };
    }

    return {
      ...state,

      calendarDoctors: _.map(calendarDoctors, (x) => ({ ...x, active: false })).reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      ),
    };
  },
  [GET_CALENDAR_DOCTORS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      calendarDoctors: data
        .filter((x) => x.id > 0)
        .reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: {
              ...item,
              active: false,
            },
          }),
          {},
        ),
    };
  },
  [SET_CALENDAR_VIEW_MODE](state, { mode }) {
    return {
      ...state,
      calendarViewMode: mode,
    };
  },
  [SET_CLINIC_NOTES_EXIST](state, { clinicNotesExist }) {
    return {
      ...state,
      clinicNotesExist,
    };
  },
  [SET_CALENDAR_WEAKLY_MODE](state, { mode }) {
    const temp = localStorage.getItem("appointmentsWeekView");
    return {
      ...state,
      calendarWeaklyMode: temp ? JSON.parse(temp) : mode,
    };
  },
  [CALENDAR_CURRENT_FREEPOINT_INDEX](state, { id, clear }) {
    if (id === -1) {
      return {
        ...state,
        currentFreepointIndex: id,
      };
    }

    if (clear) {
      return {
        ...state,
        freePoints: [],
      };
    }

    return {
      ...state,
      currentFreepointIndex: id,
    };
  },
  [CALENDAR_SELECT_CLINIC_ID](state, { id }) {
    return {
      ...state,
      clinicId: id,
    };
  },
  [CALENDAR_RESET_COLUMNS](state) {
    return {
      ...state,
      resetColumns: true,
    };
  },
  [CALENDAR_SET_COLUMN_VIEW_TYPE](state, { isActiveDoctor }) {
    return {
      ...state,
      isActiveDoctor,
    };
  },
  [SET_ACTIVE_DOCTOR_ID](state, { id }) {
    return {
      ...state,
      activeDoctorId: id,
    };
  },
  [GET_CALENDAR_LATE_APPOINTMENTS_SUCCESS](state, { data }) {
    const points = _.get(data, "points", []);
    const lateAppointments = points
      .reduce((acc, { list }) => (!_.isEmpty(list) ? acc.concat(list) : acc), [])
      .filter(({ status }) => {
        const appointmentStatus = _.get(status, "code");

        return (
          appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN ||
          appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN ||
          appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT ||
          appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
          appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2
        );
      });

    let totalBeingSeen = 0;
    let totalCheckedIn = 0;
    let totalAssessment = 0;

    lateAppointments.forEach(({ status }) => {
      const appointmentStatus = _.get(status, "code");

      totalBeingSeen =
        appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN
          ? totalBeingSeen + 1
          : totalBeingSeen;
      totalCheckedIn =
        appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN
          ? totalCheckedIn + 1
          : totalCheckedIn;
      totalAssessment =
        appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT
          ? totalAssessment + 1
          : totalAssessment;
    });

    return {
      ...state,

      lateAppointments: lateAppointments.filter((x) => {
        const appointmentStatus = _.get(x, "status.code");

        const nowTime = Date.now();
        const startTime = _.get(x, "startTime", 0);

        return (
          appointmentStatus !== STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN &&
          nowTime - startTime > 0
        );
      }),

      totalBeingSeen,
      totalCheckedIn,
      totalAssessment,
    };
  },
  [GET_CALENDAR_MASTER_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      masterTypes: data || [],
    };
  },
  [CALENDAR_CHOOSE_MASTER_TYPES](state, { id }) {
    let masterTypes = [...state.masterTypes];

    if (id) {
      const typeIndex = _.findIndex(masterTypes, (item) => item.id === id);

      if (typeIndex + 1) {
        masterTypes[typeIndex].active = !masterTypes[typeIndex].active;
      }
    } else {
      masterTypes = masterTypes.map((item) => ({
        ...item,
        active: false,
      }));
    }

    return {
      ...state,
      masterTypes,
    };
  },
  [GET_DOCTOR_POINTS_SUCCESS](state, { data }) {
    const activePoints = {};

    _.forEach(state.doctorsPoints, (doctor) => {
      _.forEach(doctor.dentalPoints, (point, id) => {
        activePoints[id] = point.active;
      });
    });

    const doctorsPoints = {};

    _.forEach(data, ({ id, name, dentalPoints }) => {
      const doctor = (doctorsPoints[id] = {
        id,
        name,
        dentalPoints: {},
      });

      _.forEach(dentalPoints, ({ id, name }) => {
        doctor.dentalPoints[id] = {
          id,
          name,
          active: Boolean(activePoints[id]),
        };
      });
    });

    return { ...state, doctorsPoints };
  },
  [CHOOSE_DOCTORS_POINT](state, { doctorId, dentalPointId }) {
    let doctorsPoints;
    const selectedAll = doctorId == "0";

    if (selectedAll) {
      doctorsPoints = _.mapValues(state.doctorsPoints, (doctor) => {
        const newDoctor = { ...doctor };

        newDoctor.dentalPoints = _.mapValues(doctor.dentalPoints, (point) => {
          const newPoint = { ...point };
          newPoint.active = false;

          return newPoint;
        });

        return newDoctor;
      });
    } else {
      doctorsPoints = { ...state.doctorsPoints };

      doctorsPoints[doctorId] = { ...doctorsPoints[doctorId] };

      doctorsPoints[doctorId].dentalPoints[dentalPointId] = {
        ...doctorsPoints[doctorId].dentalPoints[dentalPointId],
        active: !doctorsPoints[doctorId].dentalPoints[dentalPointId].active,
      };
    }

    return { ...state, doctorsPoints };
  },
  [SEARCH_APPOINTMENT_DATA_OBJECT](state, { data }) {
    return {
      ...state,
      searchAppointmentDataObject: data,
    };
  },
  [OPEN_SLOT_SET_WEEK_DAYS](state, { weekDays }) {
    return {
      ...state,
      openSlotWeekDays: weekDays,
    };
  },
  [OPEN_SLOT_SET_FROM_TIME](state, { time }) {
    return {
      ...state,
      openSlotUserFromTime: time,
    };
  },
  [OPEN_SLOT_SET_TO_TIME](state, { time }) {
    return {
      ...state,
      openSlotUserToTime: time,
    };
  },
  [INIT_CALENDAR](state) {
    return {
      ...state,
      doctors: {},
      // date: moment().startOf("day"),
    };
  },
  [OPEN_SLOT_SET_FROM_TIME_VALUE](state, { value }) {
    return {
      ...state,
      openSlotFromTime: value,
    };
  },
  [OPEN_SLOT_SET_TO_TIME_VALUE](state, { value }) {
    return {
      ...state,
      openSlotToTime: value,
    };
  },
  [APPOINTMENT_NOTE_MODAL_SHOW](state, { data }) {
    const { patientKey, note } = data;

    return {
      ...state,
      appointmentNoteModalPatientKey: patientKey,
      appointmentNoteModalVisible: true,
      appointmentNoteModal: note,
    };
  },
  [APPOINTMENT_NOTE_MODAL_HIDE](state) {
    return {
      ...state,
      appointmentNoteModalVisible: false,
    };
  },
  [OPEN_SLOT_SET_DOCTOR_ID](state, { data }) {
    return {
      ...state,
      doctorId: data,
    };
  },
  [OPEN_SLOT_SEARCH_MODE_TOGGLE](state) {
    return {
      ...state,
      searchMode: !state.searchMode,
    };
  },
  [OPEN_SLOT_SEARCH_FREE_POINTS_SUCCESS](state, { data = [] }) {
    const { reserved, freePoints = [] } = state;

    const lastData = freePoints.slice();

    const newData = reserved ? data.filter((point) => point.reserved) : data;

    if (state.currentFreepointIndex === -1) {
      return {
        ...state,
        freePoints: newData,
      };
    }

    const list = newData.map((x) => ({
      ...x,
      id: state.currentFreepointIndex,
    }));

    return {
      ...state,
      freePoints: lastData.concat(list),
    };
  },
  [CALENDAR_SEARCH_APPOINTMENT_START](state) {
    return {
      ...state,
      searchAppointmentFetching: true,
    };
  },
  [GET_MULTI_DENTIST_CALENDAR_SUCCESS](state, { data = { points: [] } }) {
    const { multiDentist } = state;

    return {
      ...state,
      multiDentist: multiDentist.concat(data.points),
    };
  },
  [CLEAR_MULTI_DENTIST_CALENDAR](state) {
    return {
      ...state,
      multiDentist: [],
    };
  },
  [CALENDAR_SEARCH_APPOINTMENT_SUCCESS](state, { data, request }) {
    return {
      ...state,
      appointments: request.start ? state.appointments.concat(data.list) : data.list || [],
      searchAppointmentTotalCount: data.totalCount || 0,
      searchAppointmentFetching: false,
    };
  },
  [CALENDAR_SEARCH_APPOINTMENT_ERROR](state) {
    return {
      ...state,
      searchAppointmentFetching: false,
    };
  },
  [CALENDAR_SEARCH_OLD_APPOINTMENT_START](state) {
    return {
      ...state,
      searchOldAppointmentFetching: true,
    };
  },
  [CALENDAR_SEARCH_OLD_APPOINTMENT_SUCCESS](state, { data, request }) {
    return {
      ...state,
      appointmentsOld: request.start ? state.appointmentsOld.concat(data.list) : data.list || [],
      searchOldAppointmentTotalCount: data.totalCount || 0,
      searchOldAppointmentFetching: false,
    };
  },
  [CALENDAR_SEARCH_OLD_APPOINTMENT_ERROR](state) {
    return {
      ...state,
      searchOldAppointmentFetching: false,
    };
  },
  [CALENDAR_SEARCH_ALL_APPOINTMENT_START](state) {
    return {
      ...state,
      searchAllAppointmentFetching: true,
    };
  },
  [CALENDAR_SEARCH_ALL_APPOINTMENT_SUCCESS](state, { data, request }) {
    return {
      ...state,
      appointmentsAll: request.start ? state.appointmentsAll.concat(data.list) : data.list || [],
      searchAllAppointmentTotalCount: data.totalCount || 0,
      searchAllAppointmentFetching: false,
    };
  },
  [CALENDAR_SEARCH_ALL_APPOINTMENT_ERROR](state) {
    return {
      ...state,
      searchAllAppointmentFetching: false,
    };
  },
  [GET_DENTAL_POINT_SUCCESS](state, { data }) {
    return {
      ...state,
      dentalPoint: data,
    };
  },
  [FIND_OPEN_SLOT_SET_TO_DATE](state, { data }) {
    return {
      ...state,
      findOpenSlotToDate: data,
    };
  },
  [FIND_OPEN_SLOT_SET_FROM_DATE](state, { data }) {
    return {
      ...state,
      findOpenSlotFromDate: data,
    };
  },
  [FIND_OPEN_SLOT_SET_WEEK_DAYS](state, { weekDay }) {
    return {
      ...state,
      findOpenSlotWeekDays: {
        ...state.findOpenSlotWeekDays,
        ...weekDay,
      },
    };
  },
  [FIND_OPEN_SLOT_SIDEBAR_SHOW](state, { data }) {
    return {
      ...state,
      findOpenSlotSidebarVisible: true,
      findOpenSlotFromDate: null,
      findOpenSlotToDate: null,
      dentalPoint: [],
      freePoints: {},
      item: data,
      openSlotFromTime: "",
      openSlotToTime: "",
      searchMode: true,
    };
  },
  [FIND_OPEN_SLOT_SIDEBAR_HIDE](state) {
    return {
      ...state,
      findOpenSlotSidebarVisible: false,
    };
  },
  [FIND_APPOINTMENT_SIDEBAR_SHOW](state) {
    return {
      ...state,
      findAppointmentSidebarVisible: true,
      appointments: [],
      searchMode: true,
      findOpenSlotFromDate: null,
      findOpenSlotToDate: null,
    };
  },
  [FIND_APPOINTMENT_SIDEBAR_HIDE](state) {
    return {
      ...state,
      findAppointmentSidebarVisible: false,
    };
  },
  [CALENDAR_SEND_DETAILS_TO_EMAIL_SUCCESS](state) {
    return {
      ...state,
      sendToEmailModalVisible: false,
    };
  },
  [CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_SHOW](state) {
    return {
      ...state,
      sendToEmailModalVisible: true,
    };
  },
  [CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_HIDE](state) {
    return {
      ...state,
      sendToEmailModalVisible: false,
    };
  },
  [GET_CALENDAR_PRINT_DETAILS_SUCCESS](state, { data }) {
    return {
      ...state,
      printDetails: data,
    };
  },
  [GET_CALENDAR_PRINT_DETAILS_START](state) {
    return {
      ...state,
      printDetails: "",
    };
  },
  [GET_CALENDAR_START](state) {
    const { resetColumns } = state;

    if (resetColumns) {
      return {
        ...state,
        columns: [],
        resetColumns: false,
      };
    }

    return state;
  },
  [GET_CALENDAR_SUCCESS](state, action) {
    const movedElementFromPanel = document.querySelector(".hold");

    const {
      data: { points, workTime, blockedTimeSlots },
      request: { time },
    } = action;
    formatWorkTime(workTime);
    let columns = [];

    // not used variable and function
    // let activeCount = 0;
    // _.forEach(state.points, (doctor) => {
    //   _.forEach(doctor.dentalPoints, (point) => {
    //     if (point.active) {
    //       activeCount++;
    //     }
    //   });
    // });

    // not used function
    // const tempFunc = () => {
    //   if (blockedTimeSlots?.length > 0) {
    //     return blockedTimeSlots;
    //   } else {
    //     return points[1]?.blockedTimeSlots;
    //   }
    // };

    let currentDay = moment(time)
      .utcOffset(Utils.getCompanyTimeZone() / 3600000)
      .day();

    if (currentDay === 0) {
      currentDay = 7;
    }

    const blockedMap = {};
    const blockedMapPoints = {};

    blockedTimeSlots.forEach((item) => {
      if (item.member) {
        const memberId = item.member.id;

        if (!blockedMap[memberId]) {
          blockedMap[memberId] = [];
        }

        if (
          _.isEmpty(item.weekDays) ||
          _.findIndex(item.weekDays, (weekDay) => weekDay.id === currentDay) !== -1
        ) {
          blockedMap[memberId].push(item);
        }
      }

      if (item.point) {
        const pointId = item.point.id;

        if (!blockedMapPoints[pointId]) {
          blockedMapPoints[pointId] = [];
        }

        if (
          _.isEmpty(item.weekDays) ||
          _.findIndex(item.weekDays, (weekDay) => weekDay.id === currentDay) !== -1
        ) {
          blockedMapPoints[pointId].push(item);
        }
      }
    });
    if (blockedTimeSlots.length > 0) {
      points.forEach((point) => {
        point.blockedTimeSlots = (blockedMap[_.get(point, "dentist.id")] || []).concat(
          blockedMapPoints[_.get(point, "dentalPoint.id")] || [],
        );
      });
    }

    columns = points.map(pointColumnModel);
    // if (activeCount === 0) {
    // } else if (activeCount >= 1) {
    //   columns = points
    //     .filter((point) => _.get(state.activePoints, _.get(point, "dentalPoint.id"), false))
    //     .map(pointColumnModel);
    // }

    if (movedElementFromPanel && movedElementFromPanel.classList.contains("hide-appointment")) {
      movedElementFromPanel.classList.remove("hide-appointment");
      movedElementFromPanel.classList.remove("hold");
    }

    return { ...state, columns, workTime };

    function pointColumnModel(point) {
      let answer;
      if (_.get(point, "dentalPoint.code") === "PUBLIC") {
        answer = {
          list: point.list || [],
          title: {
            dentistName: `Public Point (${_.get(point, "dentalPoint.name")})`,
            title: "Public",
          },
          dentistId: _.get(point, "dentist.id", 0),
          blockedTimeSlots: point?.blockedTimeSlots,
          isPublic: true,
          date: point?.date ? point?.date : null,
        };
      } else {
        answer = {
          list: point.list || [],
          title: {
            dentistName: _.get(point, "dentist.name", ""),
            title: _.get(point, "dentalPoint.name", ""),
            id: _.get(point, "dentist.id"),
          },
          dentistId: _.get(point, "dentist.id", 0),
          blockedTimeSlots: point?.blockedTimeSlots,
          date: point?.date ? point?.date : null,
        };
      }

      if (point.dentalPoint) {
        answer.dentalPointId = point.dentalPoint.id;
      }

      return answer;
    }
  },
  [GET_DENTIST_CALENDAR_START](state) {
    const { isActiveDoctor } = state;

    if (isActiveDoctor) {
      return {
        ...state,
        columns: [],
        resetColumns: false,
      };
    }

    return state;
  },
  [GET_DENTIST_CALENDAR_SUCCESS](state, action) {
    const { calendarDoctorView } = action;
    const { multiDentist = [], workTime } = state;
    let columns = [];
    let newWorkTime = {};
    const tempColumns = multiDentist.length > 0 ? Array(multiDentist.length - 1).fill([]) : [];

    if (calendarDoctorView) {
      const monday = _.head(multiDentist);

      newWorkTime = workTime;

      const weekColumns = multiDentist.map(({ list = [], dentist }) => ({
        list,
        dentist,
      }));

      weekColumns.forEach((column, idx) => {
        const tempColumn = [];

        column.list
          .sort((a, b) => {
            const aTime = _.get(a, "startTime", 0) + _.get(a, "duration", 0);
            const bTime = _.get(b, "startTime", 0) + _.get(b, "duration", 0);

            if (aTime > bTime) {
              return 1;
            }

            if (aTime < bTime) {
              return -1;
            }

            return 0;
          })
          .forEach((item, index) => {
            if (index === 0) {
              tempColumn.push([item]);
            } else {
              const prevIndex = tempColumn.length - 1;
              const prevItem = _.last(_.last(tempColumn));

              const nextStartTime = _.get(item, "startTime", 0);

              const prevStartTime = _.get(prevItem, "startTime", 0);
              const prevDuration = _.get(prevItem, "duration", 0);

              if (prevStartTime <= nextStartTime && nextStartTime <= prevStartTime + prevDuration) {
                tempColumn[prevIndex].push(item);
              } else {
                tempColumn.push([item]);
              }
            }
          });

        tempColumns[idx] = {
          ...column,
          list: tempColumn.filter((x) => !_.isEmpty(x)),
        };
      });

      tempColumns.forEach(({ list, dentist }) => {
        columns.push({
          list,
          title: {
            dentistName: _.get(dentist, "name", ""),
            title: getCompanyFormatDate(monday.date),
            id: _.get(dentist, "id"),
          },
          dentistId: _.get(dentist, "id"),
          firstDay: monday.date,
        });
      });
    } else {
      const {
        data: { points, workTime: x },
        request: { periodStart: firstDay },
      } = action;

      newWorkTime = x;

      formatWorkTime(x);

      let firstWeekDay = moment(firstDay)
        .utcOffset(Utils.getCompanyTimeZone() / 3600000)
        .day();

      if (firstWeekDay === 0) {
        firstWeekDay = 7;
      }

      columns = points.map((point) => {
        const dentistName = _.get(point, "dentist.name");
        const dentalPointName = _.get(point, "dentalPoint.name", "");
        const isPublicPoint = _.get(point, "dentalPoint.code") === "PUBLIC";

        return {
          list: point.list || [],
          title: {
            dentistName: isPublicPoint ? dentalPointName : dentistName || dentalPointName,
            title: Utils.parseDateFormat(Utils.calcDateWithTimeZone(point.date), "DD/MM/YYYY dd"),
            id: _.get(point, "dentist.id"),
            point: isPublicPoint ? dentistName || "Public Point" : dentalPointName,
          },
          isPublic: isPublicPoint,
          dentistId: (point.dentist && point.dentist.id) || 0,
          date: point.date,
          blockedTimeSlots: (point.blockedTimeSlots || []).map((x) => ({
            ...x,
            date: point.date,
          })),
          dentalPointId: (point.dentalPoint && point.dentalPoint.id) || 0,
        };
      });
    }

    return { ...state, columns, workTime: newWorkTime };
  },
  [GET_DENTIST_WITH_POINTS_SUCCESS](state, { data }) {
    const activeDoctors = {};
    Utils.objectForEach(state.doctors, (doctor, id) => {
      if (doctor.active) activeDoctors[id] = doctor;
    });

    const answer = {};
    data.forEach((item) => {
      answer[item.id] = item;
      if (activeDoctors.hasOwnProperty(item.id)) item.active = true;
    });

    return { ...state, doctors: answer };
  },
  [GET_DOCTOR_LOCATION_SUCCESS](state, { data }) {
    /* eslint-disable-next-line */
    const activePoints = JSON.parse(localStorage.getItem("calendarPoints"));

    const points = {};

    _.forEach(data, ({ id, name, dentalPoints }) => {
      const doctor = (points[id] = {
        id,
        name,
        dentalPoints: {},
      });

      _.forEach(dentalPoints, (x) => {
        doctor.dentalPoints[x.id] = {
          id: x.id,
          name: x.name,
          active: Boolean(activePoints[x.id]),
          doctorId: id,
        };
      });
    });

    return { ...state, points, activePoints };
  },
  [SET_CALENDAR_DATE](state, { date }) {
    return { ...state, date };
  },
  [CHOOSE_CALENDAR_DOCTOR](state, { id }) {
    const doctors = { ...state.doctors };

    if (+id) {
      doctors[id] = { ...doctors[id], active: !doctors[id].active };
    } else {
      Utils.objectForEach(doctors, (doctor, id) => {
        doctors[id] = { ...doctor, active: false };
      });
    }

    return { ...state, doctors };
  },
  [CHOOSE_CALENDAR_POINT](state, { doctorId, dentalPointId }) {
    let points;
    const selectedAll = doctorId == "0";
    if (selectedAll) {
      points = _.mapValues(state.points, (doctor) => {
        const newDoctor = { ...doctor };

        newDoctor.dentalPoints = _.mapValues(doctor.dentalPoints, (point) => {
          const newPoint = { ...point };
          newPoint.active = false;
          newPoint.doctorId = doctor.id;

          return newPoint;
        });

        return newDoctor;
      });
      /* eslint-disable-next-line */
      localStorage.setItem("calendarPoints", "{}");
    } else {
      /* eslint-disable-next-line */
      const calendarPoints = JSON.parse(localStorage.getItem("calendarPoints"));
      points = { ...state.points };

      points[doctorId] = { ...points[doctorId] };

      const active = !points[doctorId].dentalPoints[dentalPointId]?.active;
      calendarPoints[dentalPointId] = active;
      /* eslint-disable-next-line */
      localStorage.setItem("calendarPoints", JSON.stringify(calendarPoints));
      points[doctorId].dentalPoints[dentalPointId] = {
        ...points[doctorId].dentalPoints[dentalPointId],
        active,
      };
    }

    /* eslint-disable-next-line */
    const activePoints = JSON.parse(localStorage.getItem("calendarPoints"));

    return { ...state, points, activePoints };
  },
  [RESET_CALENDAR_STATE]() {
    return {
      ...defaultState,
    };
  },
};
/** ==================
 * Selectors
 * ================== */
export const calendarDateSelector = ({ calendar }) => calendar.date;

export const calendarClinicIdSelector = ({ calendar }) => calendar.clinicId;

export const clinicWorkTimeSelector = ({ calendar }) => calendar.workTime;

export default createReducer(defaultState, reducers);
