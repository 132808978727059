export const MEDICATION_GET_LIST_START = "MEDICATION_GET_LIST_START";
export const MEDICATION_GET_LIST_SUCCESS = "MEDICATION_GET_LIST_SUCCESS";
export const MEDICATION_GET_LIST_ERROR = "MEDICATION_GET_LIST_ERROR";

export const MEDICATION_GET_DRUG_LIST_START = "MEDICATION_GET_DRUG_LIST_START";
export const MEDICATION_GET_DRUG_LIST_SUCCESS = "MEDICATION_GET_DRUG_LIST_SUCCESS";
export const MEDICATION_GET_DRUG_LIST_ERROR = "MEDICATION_GET_DRUG_LIST_ERROR";

export const MEDICATION_GET_FORM_DATA_START = "MEDICATION_GET_FORM_DATA_START";
export const MEDICATION_GET_FORM_DATA_SUCCESS = "MEDICATION_GET_FORM_DATA_SUCCESS";
export const MEDICATION_GET_FORM_DATA_ERROR = "MEDICATION_GET_FORM_DATA_ERROR";

export const MEDICATION_BATCH_SAVE_START = "MEDICATION_BATCH_SAVE_START";
export const MEDICATION_BATCH_SAVE_SUCCESS = "MEDICATION_BATCH_SAVE_SUCCESS";
export const MEDICATION_BATCH_SAVE_ERROR = "MEDICATION_BATCH_SAVE_ERROR";

export const MEDICATION_HISTORY_GET_LIST_START = "MEDICATION_HISTORY_GET_LIST_START";
export const MEDICATION_HISTORY_GET_LIST_SUCCESS = "MEDICATION_HISTORY_GET_LIST_SUCCESS";
export const MEDICATION_HISTORY_GET_LIST_ERROR = "MEDICATION_HISTORY_GET_LIST_ERROR";

export const MEDICATION_HISTORY_BATCH_SAVE_START = "MEDICATION_HISTORY_BATCH_SAVE_START";
export const MEDICATION_HISTORY_BATCH_SAVE_SUCCESS = "MEDICATION_HISTORY_BATCH_SAVE_SUCCESS";
export const MEDICATION_HISTORY_BATCH_SAVE_ERROR = "MEDICATION_HISTORY_BATCH_SAVE_ERROR";

export const MEDICATION_BATCH_DELETE_START = "MEDICATION_BATCH_DELETE_START";
export const MEDICATION_BATCH_DELETE_SUCCESS = "MEDICATION_BATCH_DELETE_SUCCESS";
export const MEDICATION_BATCH_DELETE_ERROR = "MEDICATION_BATCH_DELETE_ERROR";

export const VOIDED_MEDICATION_RESTORE_ITEM_START = "VOIDED_MEDICATION_RESTORE_ITEM_START";
export const VOIDED_MEDICATION_RESTORE_ITEM_SUCCESS = "VOIDED_MEDICATION_RESTORE_ITEM_SUCCESS";
export const VOIDED_MEDICATION_RESTORE_ITEM_ERROR = "VOIDED_MEDICATION_RESTORE_ITEM_ERROR";

export const MEDICATION_HISTORY_DELETE_ITEM_START = "MEDICATION_HISTORY_DELETE_ITEM_START";
export const MEDICATION_HISTORY_DELETE_ITEM_SUCCESS = "MEDICATION_HISTORY_DELETE_ITEM_SUCCESS";
export const MEDICATION_HISTORY_DELETE_ITEM_ERROR = "MEDICATION_HISTORY_DELETE_ITEM_ERROR";

export const MEDICATION_INIT = "MEDICATION_INIT";
export const MEDICATION_OPEN_DRUG_MODAL = "MEDICATION_OPEN_DRUG_MODAL";
export const MEDICATION_CLOSE_DRUG_MODAL = "MEDICATION_CLOSE_DRUG_MODAL";
export const MEDICATION_TOGGLE_DRUG = "MEDICATION_TOGGLE_DRUG";
export const MEDICATION_SUBMIT_DRUG_FORM = "MEDICATION_SUBMIT_DRUG_FORM";
export const MEDICATION_ADD_DRUGS = "MEDICATION_ADD_DRUGS";
export const MEDICATION_OPEN_HISTORY_MODAL = "MEDICATION_OPEN_HISTORY_MODAL";
export const MEDICATION_CLOSE_HISTORY_MODAL = "MEDICATION_CLOSE_HISTORY_MODAL";
export const MEDICATION_ADD_NEW_HISTORY = "MEDICATION_ADD_NEW_HISTORY";
export const MEDICATION_CHECK_ALL = "MEDICATION_CHECK_ALL";
export const MEDICATION_CHECK_ITEM = "MEDICATION_CHECK_ITEM";
export const MEDICATION_CHECK_TODAY_ALL = "MEDICATION_CHECK_TODAY_ALL";
export const MEDICATION_CHECK_OLD_ALL = "MEDICATION_CHECK_OLD_ALL";
export const MEDICATION_CHECK_TODAY_ITEM = "MEDICATION_CHECK_TODAY_ITEM";
export const MEDICATION_CHECK_OLD_ITEM = "MEDICATION_CHECK_OLD_ITEM";
export const CLEAR_MEDICATION_CHECKED_ITEM = "CLEAR_MEDICATION_CHECKED_ITEM";
export const MEDICATION_POP_NEW_ITEM = "MEDICATION_POP_NEW_ITEM";
export const MEDICATION_CLEAR_CHECK = "MEDICATION_CLEAR_CHECK";
export const MEDICATION_POP_HISTORY = "MEDICATION_POP_HISTORY";
export const MEDICATION_DUPLICATE = "MEDICATION_DUPLICATE";

export const MEDICATION_CREATE_PDF_START = "MEDICATION_CREATE_PDF_START";
export const MEDICATION_CREATE_PDF_SUCCESS = "MEDICATION_CREATE_PDF_SUCCESS";
export const MEDICATION_CREATE_PDF_ERROR = "MEDICATION_CREATE_PDF_ERROR";

export const MEDICATION_HINTS_START = "MEDICATION_HINTS_START";
export const MEDICATION_HINTS_SUCCESS = "MEDICATION_HINTS_SUCCESS";
export const MEDICATION_HINTS_ERROR = "MEDICATION_HINTS_ERROR";

export const MEDICATION_CREATE_ITEM_START = "MEDICATION_CREATE_ITEM_START";
export const MEDICATION_CREATE_ITEM_SUCCESS = "MEDICATION_CREATE_ITEM_SUCCESS";
export const MEDICATION_CREATE_ITEM_ERROR = "MEDICATION_CREATE_ITEM_ERROR";

export const MEDICATION_TOGGLE_PDF_MODAL = "MEDICATION_TOGGLE_PDF_MODAL";

export const GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_START =
  "GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_START";
export const GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_SUCCESS =
  "GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_SUCCESS";
export const GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_ERROR =
  "GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_ERROR";
export const MEDICATION_SEND_TO_ERX_START = "MEDICATION_SEND_TO_ERX_START";
export const MEDICATION_SEND_TO_ERX_SUCCESS = "MEDICATION_SEND_TO_ERX_SUCCESS";
export const MEDICATION_SEND_TO_ERX_ERROR = "MEDICATION_SEND_TO_ERX_ERROR";
export const MEDICATION_CLEAR_NEW_LIST = "MEDICATION_CLEAR_NEW_LIST";
