import React, { useEffect, useState } from "react";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";
import { useDispatch } from "react-redux";
import { imageTypeQuestionToggle } from "../../../../actions/folderActions";
import { questionModalVisibleHandler } from "../../../../actions/chartSideMedicalFillInFormActions";
import Show from "../../../widgets/Show";

const MedicalFillInFormOverlay = ({ overlay }) => {
  const dispatch = useDispatch();
  const [overlayHeight, setOverlayHeight] = useState(0);
  let { visible, top, left, innerWidth, innerHeight, question, typeCode, component } = overlay;
  const overlayWidth = 400;
  const right = innerWidth < left + overlayWidth;
  const bottom = innerHeight < top + overlayHeight;
  const isCanvas = typeCode === questionTypeCodes.CANVAS;
  const isImage = typeCode === questionTypeCodes.IMAGE;
  const isTextArea =
    typeCode === questionTypeCodes.TEXT_AREA || typeCode === questionTypeCodes.TEXT;

  if (isCanvas) {
    top = 0;
    left = 0;
  }

  useEffect(() => {
    if (visible) {
      const elementHeight = document.getElementById("medical-overlay").clientHeight;
      setOverlayHeight(elementHeight);
    }

    if (isImage) {
      dispatch(imageTypeQuestionToggle(true));
    }
  }, [visible]);

  if (visible)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          minHeight: "100vh",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: isImage ? "unset" : "rgba(255, 255, 255, 0.6)",
          zIndex: 9,
        }}
      >
        <div
          id="medical-overlay"
          style={{
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column",
            borderRadius: 5,
            width: isCanvas ? "100vw" : isImage ? "50%" : isTextArea ? "35%" : `${overlayWidth}px`,
            height: isCanvas ? "100vh" : isImage ? "100vh" : "auto",
            position: "absolute",
            backgroundColor: "#ffffff",
            zIndex: 100000,
            top: isImage ? 0 : bottom ? "unset" : top,
            left: isImage ? "unset" : right ? "unset" : left,
            right: isImage ? 0 : right ? 0 : "unset",
            bottom: bottom ? 0 : "unset",
            boxShadow: isImage
              ? "-7px 0px 10px 0px rgba(0,0,0,0.75)"
              : "rgb(0 0 0 / 13%) 0px 0px 5px",
            padding: 0,
          }}
        >
          {!isImage && (
            <div className="p-2 pb-0 d-flex align-items-center justify-content-between">
              {question}
              <Show if={isTextArea}>
                <button
                  className="btn btn-primary"
                  onClick={() => dispatch(questionModalVisibleHandler())}
                >
                  Default answers
                </button>
              </Show>
            </div>
          )}
          <div className="pt-1 p-2">
            {component}
          </div>
        </div>
      </div>
    );
  return null;
};

export default MedicalFillInFormOverlay;
