import React from "react";
import { connect } from "react-redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { Autocomplete } from "../components/ui/Autocomplete";
import { fetchTreatmentCodes } from "../actions/chartSideSeriesActions";

const enhancer = connect(
  ({ chartSideSeries: { treatmentCodes, fetchingTreatmentCodes } }) => ({
    treatmentCodes,
    fetchingTreatmentCodes,
  }),
  { fetchTreatmentCodes },
);

export const TreatmentCodesAutocompleteWrapper = enhancer(
  class TreatmentCodesAutocompleteWrapperView extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        value: "",
        searchAPIDebounced: AwesomeDebouncePromise(props.fetchTreatmentCodes, 500),
      };
    }

    onChangeHandler = (text) => {
      const { onSelect } = this.props;
      const { searchAPIDebounced } = this.state;

      searchAPIDebounced(text);
      this.setState({ value: text });

      if (text === "") {
        onSelect("");
      }
    };

    render() {
      const { value } = this.state;
      const {
        onSelect,
        placeholder,
        inputClassName,
        treatmentCodes,
        fetchingTreatmentCodes,
      } = this.props;

      return (
        <Autocomplete
          value={value}
          onSelect={onSelect}
          options={treatmentCodes}
          placeholder={placeholder}
          inputClassName={inputClassName}
          onChange={this.onChangeHandler}
          fetching={fetchingTreatmentCodes}
        />
      );
    }
  },
);
