import React from "react";
import { connect } from "react-redux";

import Back from "../../widgets/Back";

import ChartLabOrderForm from "./ChartLabOrderForm";

import Utils from "../../../helpers/Utils";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartPostOperation extends React.Component {
  componentDidMount() {
    this.props.chartSideSeriesActions.initLabOrder();
  }

  render() {
    const { chartSideSeries, member, patient } = this.props;

    return (
      <div className="default-page">
        <div className="default-page-title">
          <Back
            className="pull-left"
            onClick={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}
          />
          <div className="title text-center">CREATE LAB ORDER</div>
        </div>
        <div className="default-page-body">
          <div className="lab-order-info-row">
            <strong>Patient:</strong> {Utils.getFullName(patient)}
            <div className="pull-right">
              <strong>{localStorage.getItem("doctorLabel")}:</strong> {Utils.getFullName(member)}
            </div>
          </div>
          <div className="lab-order-info-row">
            <strong>Age:</strong> {patient.age}
            <div className="pull-right">
              <strong>ID:</strong> {member.id}
            </div>
          </div>
          <div className="lab-order-info-row">
            <strong>Gender:</strong> {patient.gender.name}
          </div>
          <ChartLabOrderForm
            chartSideSeries={chartSideSeries}
            onSubmit={this.props.chartSideSeriesActions.saveLabOrder}
          />
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOperation);
