import _ from "lodash";
import React from "react";

import PatientApi from "../api-legacy/PatientApi";
import AppointmentApi from "../api-legacy/AppointmentApi";
import TransactionApi from "../api-legacy/TransactionApi";
import ClinicalNoteApi from "../api-legacy/ClinicalNoteApi";

import ToothGeneration from "../constants/teeth/ToothGeneration";
import MixedAdultTooth from "../constants/teeth/MixedAdultTooth";
import { SelectValues } from "../constants/RepeatAppointmentDataTypes";

import Utils from "../helpers/Utils";

import { closeModal, showMessage, startLoader, stopLoader } from "./loaderActions";
import * as chartSideSeriesActions from "./chartSideSeriesActions";
import * as authActions from "./authActions";
import * as chartSideClinicalNoteActions from "./chartSideClinicalNoteActions";
import * as chartSidePrescriptionActions from "./chartSidePrescriptionActions";
import * as transactionActions from "./transactionActions";
import { closeSimpleModal, openSimpleModal } from "./simpleModalActions";

import * as ActionType from "../actionTypes/chartRepeatAppointmentActionTypes";
import viewTypes from "../constants/chart-sidebar/viewTypes";
import clinicNoteItemTypes from "../constants/chart-sidebar/clinicNoteItemTypes";
import CompanyApi from "../api-legacy/CompanyApi";
import TreatmentApi from "../api-legacy/TreatmentApi";
import { resetFolder } from "./folderActions";
import { getMedicalFillInFormTreatmentList } from "./chartSideMedicalFillInFormActions";
import AdvancedPaymentApi from "../api-legacy/AdvancedPaymentApi";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";

export const GET_CHART_TEETH_START = "GET_CHART_TEETH_START";
export const GET_CHART_TEETH_SUCCESS = "GET_CHART_TEETH_SUCCESS";
export const GET_CHART_TEETH_ERROR = "GET_CHART_TEETH_ERROR";

export const GET_CHART_PATIENT_START = "GET_CHART_PATIENT_START";
export const GET_CHART_PATIENT_SUCCESS = "GET_CHART_PATIENT_SUCCESS";
export const GET_CHART_PATIENT_ERROR = "GET_CHART_PATIENT_ERROR";

export const GET_CHART_APPOINTMENT_START = "GET_CHART_APPOINTMENT_START";
export const GET_CHART_APPOINTMENT_SUCCESS = "GET_CHART_APPOINTMENT_SUCCESS";
export const GET_CHART_APPOINTMENT_ERROR = "GET_CHART_APPOINTMENT_ERROR";

export const GET_CHART_FOR_STAFF_START = "GET_CHART_FOR_STAFF_START";
export const GET_CHART_FOR_STAFF_SUCCESS = "GET_CHART_FOR_STAFF_SUCCESS";
export const GET_CHART_FOR_STAFF_ERROR = "GET_CHART_FOR_STAFF_ERROR";

export const GET_CHART_PATIENT_BALANCE_START = "GET_CHART_PATIENT_BALANCE_START";
export const GET_CHART_PATIENT_BALANCE_SUCCESS = "GET_CHART_PATIENT_BALANCE_SUCCESS";
export const GET_CHART_PATIENT_BALANCE_ERROR = "GET_CHART_PATIENT_BALANCE_ERROR";

export const GET_CHART_PATIENT_BILLING_START = "GET_CHART_PATIENT_BILLING_START";
export const GET_CHART_PATIENT_BILLING_SUCCESS = "GET_CHART_PATIENT_BILLING_SUCCESS";
export const GET_CHART_PATIENT_BILLING_ERROR = "GET_CHART_PATIENT_BILLING_ERROR";

export const GET_CHART_PATIENT_BALANCE_MODAL_HIDE = "GET_CHART_PATIENT_BALANCE_MODAL_HIDE";
export const GET_CHART_PATIENT_BALANCE_MODAL_SHOW = "GET_CHART_PATIENT_BALANCE_MODAL_SHOW";

export const CHART_SHOW_TOOLTIP = "CHART_SHOW_TOOLTIP";
export const CHART_HIDE_TOOLTIP = "CHART_HIDE_TOOLTIP";

export const CHART_SHOW_SIDE_BAR = "CHART_SHOW_SIDE_BAR";
export const CHART_SHOW_SIDE_BAR_TRANSACTION = "CHART_SHOW_SIDE_BAR_TRANSACTION";
export const CHART_HIDE_SIDE_BAR = "CHART_HIDE_SIDE_BAR";

export const CHART_HIDE_SIDE_BAR_TRANSACTION = "CHART_HIDE_SIDE_BAR_TRANSACTION";

export const ADD_DIAGNOSIS_FOR_COMPLETE = "ADD_DIAGNOSIS_FOR_COMPLETE";

export const INIT_CHART = "INIT_CHART";

export const CHANGE_TOOTH_START = "CHANGE_TOOTH_START";
export const CHANGE_TOOTH_SUCCESS = "CHANGE_TOOTH_SUCCESS";
export const CHANGE_TOOTH_ERROR = "CHANGE_TOOTH_ERROR";

export const CHART_UPLOAD_PHOTO_START = "CHART_UPLOAD_PHOTO_START";
export const CHART_UPLOAD_PHOTO_SUCCESS = "CHART_UPLOAD_PHOTO_SUCCESS";
export const CHART_UPLOAD_PHOTO_ERROR = "CHART_UPLOAD_PHOTO_ERROR";

export const ADD_TOOTH_START = "ADD_TOOTH_START";
export const ADD_TOOTH_SUCCESS = "ADD_TOOTH_SUCCESS";
export const ADD_TOOTH_ERROR = "ADD_TOOTH_ERROR";

export const DELETE_TOOTH_START = "DELETE_TOOTH_START";
export const DELETE_TOOTH_SUCCESS = "DELETE_TOOTH_SUCCESS";
export const DELETE_TOOTH_ERROR = "DELETE_TOOTH_ERROR";

export const CHART_STOP_DEALS_START = "CHART_STOP_DEALS_START";
export const CHART_STOP_DEALS_SUCCESS = "CHART_STOP_DEALS_SUCCESS";
export const CHART_STOP_DEALS_ERROR = "CHART_STOP_DEALS_ERROR";

export const CHART_STOP_DEAL_LIST_START = "CHART_STOP_DEAL_LIST_START";
export const CHART_STOP_DEAL_LIST_SUCCESS = "CHART_STOP_DEAL_LIST_SUCCESS";
export const CHART_STOP_DEAL_LIST_ERROR = "CHART_STOP_DEAL_LIST_ERROR";

export const CHANGE_ACTIVE_TOOTH = "CHANGE_ACTIVE_TOOTH";

export const CHART_HIDE_ALERTS = "CHART_HIDE_ALERTS";
export const CHART_SHOW_ALERTS = "CHART_SHOW_ALERTS";

export const CHART_VOIDED_SHOW_MODAL = "CHART_VOIDED_SHOW_MODAL";
export const CHART_VOIDED_HIDE_MODAL = "CHART_VOIDED_HIDE_MODAL";

export const CHART_SWITCH_FOLDER_MODAL = "CHART_SWITCH_FOLDER_MODAL";

export const CHART_CLEAR_LIST = "CHART_CLEAR_LIST";

export const CHART_SET_POST_PLAN = "CHART_SET_POST_PLAN";

export const CUSTOMIZE_PACKAEGE_MODAL_SHOW = "CUSTOMIZE_PACKAEGE_MODAL_SHOW";
export const CUSTOMIZE_PACKAEGE_MODAL_HIDE = "CUSTOMIZE_PACKAEGE_MODAL_HIDE";

export const SELECT_TREATMENT_TO_COMPLETE_TOGGLE_MENU = "SELECT_TREATMENT_TO_COMPLETE_TOGGLE_MENU";

export const UPDATE_CHART_BILLING_BALANCE_VALUE = "UPDATE_CHART_BILLING_BALANCE_VALUE";
// ========================================================

export const SELECT_TREATMENTS_TO_COMPLETE_MODAL_SHOW = "SELECT_TREATMENTS_TO_COMPLETE_MODAL_SHOW";
export const SELECT_TREATMENTS_TO_COMPLETE_MODAL_HIDE = "SELECT_TREATMENTS_TO_COMPLETE_MODAL_HIDE";
export const SET_TREATMENTS_TO_COMPLETE_VISIT_TYPE = "SET_TREATMENTS_TO_COMPLETE_VISIT_TYPE";

export const SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_START =
  "SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_START";
export const SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_SUCCESS =
  "SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_SUCCESS";
export const SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_ERROR =
  "SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_ERROR";

export const SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_START =
  "SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_START";
export const SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_SUCCESS =
  "SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_SUCCESS";
export const SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_ERROR =
  "SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_ERROR";

export const SELECT_TREATMENTS_TO_COMPLETE_TOGGLE_SELECT_ALL =
  "SELECT_TREATMENTS_TO_COMPLETE_TOGGLE_SELECT_ALL";

export const SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM =
  "SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM";
export const SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM_CLEAR =
  "SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM_CLEAR";
export const SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEMS =
  "SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEMS";

export const SELECT_TREATMENT_TO_COMPLETE_SET_FINISH_MODE =
  "SELECT_TREATMENT_TO_COMPLETE_SET_FINISH_MODE";

export const SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_SHOW =
  "SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_SHOW";
export const SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_HIDE =
  "SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_HIDE";

// ===========================================================================

export const CHART_VOIDED_TREATMENTS_LIST_START = "CHART_VOIDED_TREATMENTS_LIST_START";
export const CHART_VOIDED_TREATMENTS_LIST_SUCCESS = "CHART_VOIDED_TREATMENTS_LIST_SUCCESS";
export const CHART_VOIDED_TREATMENTS_LIST_ERROR = "CHART_VOIDED_TREATMENTS_LIST_ERROR";

export const RESTORE_VOIDED_TREATMENTS_START = "RESTORE_VOIDED_TREATMENTS_START";
export const RESTORE_VOIDED_TREATMENTS_SUCCESS = "RESTORE_VOIDED_TREATMENTS_SUCCESS";
export const RESTORE_VOIDED_TREATMENTS_ERROR = "RESTORE_VOIDED_TREATMENTS_ERROR";

export const CHART_AUDIT_ACTIONS_START = "CHART_AUDIT_ACTIONS_START";
export const CHART_AUDIT_ACTIONS_SUCCESS = "CHART_AUDIT_ACTIONS_SUCCESS";
export const CHART_AUDIT_ACTIONS_ERROR = "CHART_AUDIT_ACTIONS_ERROR";

export const VOIDED_TREATMENTS_TOGGLE_SELECT_ALL = "VOIDED_TREATMENTS_TOGGLE_SELECT_ALL";
export const VOIDED_TREATMENTS_TOGGLE_SELECT_KEY = "VOIDED_TREATMENTS_TOGGLE_SELECT_KEY";

export const CHART_SHOW_CHOOSE_TOOLTIP = "CHART_SHOW_CHOOSE_TOOLTIP";
export const CHART_HIDE_CHOOSE_TOOLTIP = "CHART_HIDE_CHOOSE_TOOLTIP";

export const CHART_INIT_COMPLETE_MODAL_DOCTORS = "CHART_INIT_COMPLETE_MODAL_DOCTORS";
export const CHART_COMPLETE_MODAL_DOCTOR_SELECT = "CHART_COMPLETE_MODAL_DOCTOR_SELECT";

export const CHART_CHANGE_EYE = "CHART_CHANGE_EYE";

export const TRANSACTION_INIT_CHART = "TRANSACTION_INIT_CHART";

export const CHART_SHOW_REQUEST_LAB_ORDER_MODAL = "CHART_SHOW_REQUEST_LAB_ORDER_MODAL";
export const CHART_HIDE_REQUEST_LAB_ORDER_MODAL = "CHART_HIDE_REQUEST_LAB_ORDER_MODAL";

export const CHART_GET_PATIENT_GENDER_START = "CHART_GET_PATIENT_GENDER_START";
export const CHART_GET_PATIENT_GENDER_SUCCESS = "CHART_GET_PATIENT_GENDER_SUCCESS";
export const CHART_GET_PATIENT_GENDER_ERROR = "CHART_GET_PATIENT_GENDER_ERROR";
export const SET_SIDE_BAR_TAB_TYPE = "SET_SIDE_BAR_TAB_TYPE";
export const SHOW_PASSWORD_MODAL_FOR_HISTORY = "SHOW_PASSWORD_MODAL_FOR_HISTORY";
export const SHOW_PATIENT_HISTORY_MODAL = "SHOW_PATIENT_HISTORY_MODAL";
export const GET_PATIENT_HISTORY_DATA_START = "GET_PATIENT_HISTORY_DATA_START";
export const GET_PATIENT_HISTORY_DATA_SUCCESS = "GET_PATIENT_HISTORY_DATA_SUCCESS";
export const GET_PATIENT_HISTORY_DATA_ERROR = "GET_PATIENT_HISTORY_DATA_ERROR";

export const CHART_TREATMENT_PRICE_LIST_START = "CHART_TREATMENT_PRICE_LIST_START";
export const CHART_TREATMENT_PRICE_LIST_SUCCESS = "CHART_TREATMENT_PRICE_LIST_SUCCESS";
export const CHART_TREATMENT_PRICE_LIST_ERROR = "CHART_TREATMENT_PRICE_LIST_ERROR";

export const CHART_DIAGNOSIS_SET_PRIMARY_START = "CHART_DIAGNOSIS_SET_PRIMARY_START";
export const CHART_DIAGNOSIS_SET_PRIMARY_SUCCESS = "CHART_DIAGNOSIS_SET_PRIMARY_SUCCESS";
export const CHART_DIAGNOSIS_SET_PRIMARY_ERROR = "CHART_DIAGNOSIS_SET_PRIMARY_ERROR";

export const OPEN_PATIENT_DOCS_SIDEBAR = "OPEN_PATIENT_DOCS_SIDEBAR";
export const CLOSE_PATIENT_DOCS_SIDEBAR = "CLOSE_PATIENT_DOCS_SIDEBAR";
//advanced payments
export const SET_ADVANCED_PAYMENT_MODAL_VISIBLE = "SET_ADVANCED_PAYMENT_MODAL_VISIBLE";
export const SET_ADVANCED_PAYMENT_CONTENT = "SET_ADVANCED_PAYMENT_CONTENT";

export const GET_ADVANCED_PAYMENT_LIST_START = "GET_ADVANCED_PAYMENT_LIST_START";
export const GET_ADVANCED_PAYMENT_LIST_SUCCESS = "GET_ADVANCED_PAYMENT_LIST_SUCCESS";
export const GET_ADVANCED_PAYMENT_LIST_ERROR = "GET_ADVANCED_PAYMENT_LIST_ERROR";

export const SAVE_ADVANCED_PAYMENT_ITEM_START = "SAVE_ADVANCED_PAYMENT_ITEM_START";
export const SAVE_ADVANCED_PAYMENT_ITEM_SUCCESS = "SAVE_ADVANCED_PAYMENT_ITEM_SUCCESS";
export const SAVE_ADVANCED_PAYMENT_ITEM_ERROR = "SAVE_ADVANCED_PAYMENT_ITEM_ERROR";

export const GET_ADVANCED_PAYMENT_METHOD_LIST_START = "GET_ADVANCED_PAYMENT_METHOD_LIST_START";
export const GET_ADVANCED_PAYMENT_METHOD_LIST_SUCCESS = "GET_ADVANCED_PAYMENT_METHOD_LIST_SUCCESS";
export const GET_ADVANCED_PAYMENT_METHOD_LIST_ERROR = "GET_ADVANCED_PAYMENT_METHOD_LIST_ERROR";

export const GET_ADVANCED_PAYMENT_WALLET_LIST_START = "GET_ADVANCED_PAYMENT_WALLET_LIST_START";
export const GET_ADVANCED_PAYMENT_WALLET_LIST_SUCCESS = "GET_ADVANCED_PAYMENT_WALLET_LIST_SUCCESS";
export const GET_ADVANCED_PAYMENT_WALLET_LIST_ERROR = "GET_ADVANCED_PAYMENT_WALLET_LIST_ERROR";

export const GET_ADVANCED_PAYMENT_OTHER_LIST_START = "GET_ADVANCED_PAYMENT_OTHER_LIST_START";
export const GET_ADVANCED_PAYMENT_OTHER_LIST_SUCCESS = "GET_ADVANCED_PAYMENT_OTHER_LIST_SUCCESS";
export const GET_ADVANCED_PAYMENT_OTHER_LIST_ERROR = "GET_ADVANCED_PAYMENT_OTHER_LIST_ERROR";

export const GET_PATIENT_LABEL_PDF_START = "GET_PATIENT_LABEL_PDF_START";
export const GET_PATIENT_LABEL_PDF_SUCCESS = "GET_PATIENT_LABEL_PDF_SUCCESS";
export const GET_PATIENT_LABEL_PDF_ERROR = "GET_PATIENT_LABEL_PDF_ERROR";
export const SET_PATIENT_LABEL_MODAL_VISIBLE = "SET_PATIENT_LABEL_MODAL_VISIBLE";

export const UPDATE_COMPLETE_TREATMENTS_LIST_FILTER = "UPDATE_COMPLETE_TREATMENTS_LIST_FILTER";

export function updateTransactionsToCompleteFilter(_filter) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_COMPLETE_TREATMENTS_LIST_FILTER,
      _filter,
    });
}

export function updateChartBillingBalanceValue() {
  return (dispatch) =>
    dispatch({
      type: UPDATE_CHART_BILLING_BALANCE_VALUE,
    });
}

export function customizePackageModalShow() {
  return (dispatch) =>
    dispatch({
      type: CUSTOMIZE_PACKAEGE_MODAL_SHOW,
    });
}

export function customizePackageModalHide() {
  return (dispatch) =>
    dispatch({
      type: CUSTOMIZE_PACKAEGE_MODAL_HIDE,
    });
}

export function setAdvancedPaymentModalVisible(advancedPaymentModalVisible) {
  return (dispatch) =>
    dispatch({
      type: SET_ADVANCED_PAYMENT_MODAL_VISIBLE,
      advancedPaymentModalVisible,
    });
}
export function setAdvancedPaymentContent(advancedPaymentContentType) {
  return (dispatch) =>
    dispatch({
      type: SET_ADVANCED_PAYMENT_CONTENT,
      advancedPaymentContentType,
    });
}
export function initAdvancedPaymentList() {
  return (dispatch, getState) => {
    const { clinic } = getState().session;
    return dispatch({
      api: AdvancedPaymentApi.getList,
      types: [
        GET_ADVANCED_PAYMENT_LIST_START,
        GET_ADVANCED_PAYMENT_LIST_SUCCESS,
        GET_ADVANCED_PAYMENT_LIST_ERROR,
      ],
      data: {
        clinicId: clinic.id,
        start: 0,
        limit: 25,
        startDate: getCompanyStartOfDay(),
        endDate: getCompanyEndOfDay(),
      },
    });
  };
}
export function getAdvancedPaymentMethodList() {
  return (dispatch) =>
    dispatch({
      api: AdvancedPaymentApi.getMethodList,
      types: [
        GET_ADVANCED_PAYMENT_METHOD_LIST_START,
        GET_ADVANCED_PAYMENT_METHOD_LIST_SUCCESS,
        GET_ADVANCED_PAYMENT_METHOD_LIST_ERROR,
      ],
    });
}
export function getAdvancedPaymentWalletList(data) {
  return (dispatch) =>
    dispatch({
      api: AdvancedPaymentApi.getWalletList,
      types: [
        GET_ADVANCED_PAYMENT_WALLET_LIST_START,
        GET_ADVANCED_PAYMENT_WALLET_LIST_SUCCESS,
        GET_ADVANCED_PAYMENT_WALLET_LIST_ERROR,
      ],
      data,
    });
}
export function getAdvancedPaymentOtherList(data) {
  return (dispatch) =>
    dispatch({
      api: AdvancedPaymentApi.getOtherList,
      types: [
        GET_ADVANCED_PAYMENT_OTHER_LIST_START,
        GET_ADVANCED_PAYMENT_OTHER_LIST_SUCCESS,
        GET_ADVANCED_PAYMENT_OTHER_LIST_ERROR,
      ],
      data,
    });
}
export function saveAdvancedPaymentItem(data) {
  return (dispatch) =>
    dispatch({
      api: AdvancedPaymentApi.saveItem,
      types: [
        SAVE_ADVANCED_PAYMENT_ITEM_START,
        SAVE_ADVANCED_PAYMENT_ITEM_SUCCESS,
        SAVE_ADVANCED_PAYMENT_ITEM_ERROR,
      ],
      data,
    });
}

export function getPatientGenderList() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientGenderApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function openPatientDocsSideBar() {
  return (dispatch, getState) => {
    const {
      folder: { imageTypeQuestion },
    } = getState();

    dispatch({ type: OPEN_PATIENT_DOCS_SIDEBAR, imageTypeQuestion });
    // dispatch(initFolders());
  };
}
export function closePatientDocsSideBar() {
  return (dispatch) => {
    dispatch({ type: CLOSE_PATIENT_DOCS_SIDEBAR });
    dispatch(resetFolder());
  };
}

export function showRequestLabOrderModal() {
  return {
    type: CHART_SHOW_REQUEST_LAB_ORDER_MODAL,
  };
}

export function hideRequestLabOrderModal() {
  return {
    type: CHART_HIDE_REQUEST_LAB_ORDER_MODAL,
  };
}

export function setPostPlan(isPostPlan) {
  return {
    type: CHART_SET_POST_PLAN,
    isPostPlan,
  };
}

export function setSideBarTabType(sideBarTabType) {
  return {
    type: SET_SIDE_BAR_TAB_TYPE,
    sideBarTabType,
  };
}

export function showPasswordModalForHistory(bool) {
  return {
    type: SHOW_PASSWORD_MODAL_FOR_HISTORY,
    bool,
  };
}

export function showPatientHistoryModal(bool) {
  return {
    type: SHOW_PATIENT_HISTORY_MODAL,
    bool,
  };
}

export function handleSubmitPasswordForHistory(data) {
  return (dispatch) => {
    return dispatch(getPatientHistoryApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function stopDeals(procedureKeys) {
  return (dispatch, getState) => {
    const {
      chart: { patient, appointment },
    } = getState();

    const appointmentId = _.get(appointment, "id");
    const patientKey = _.get(patient, "patientKey");

    if (_.isEmpty(procedureKeys)) {
      return dispatch(showMessage("Please select procedure"));
    }

    if (!appointmentId) {
      return dispatch(showMessage("Please select appointment id"));
    }

    if (!patientKey) {
      return dispatch(showMessage("Please select patient key"));
    }

    const data = {
      patientKey,
      appointmentId,
      procedureKeys,
    };

    dispatch(startLoader());

    return dispatch(stopDealsApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getStopDealsList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function clearList() {
  return (dispatch) =>
    dispatch({
      type: CHART_CLEAR_LIST,
    });
}

export function stopDealsLoadAll(data) {
  return (dispatch, getState) => {
    const {
      chart: { selectTreatmentToCompleteListTotalCount, selectTreatmentToCompleteList },
    } = getState();

    if (selectTreatmentToCompleteListTotalCount > selectTreatmentToCompleteList.length) {
      return dispatch(
        stopDealListApi({
          ...data,

          start: selectTreatmentToCompleteList.length,
        }),
      )
        .then(() => dispatch(stopDealsLoadAll(data)))
        .catch((error) => {
          dispatch(showMessage(error));
        });
    }
  };
}

export function getStopDealsList() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    const patientKey = _.get(patient, "patientKey");

    const data = {
      patientKey,

      start: 0,
      limit: 100,
      treatmentPlanned: true,
    };

    return dispatch(stopDealListApi(data))
      .then(() => dispatch(stopDealsLoadAll(data)))
      .catch((error) => {
        dispatch(showMessage(error));
      });
  };
}

export function switchFolderModal() {
  return {
    type: CHART_SWITCH_FOLDER_MODAL,
  };
}

export function getClinics() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(authActions.getClinics())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getVoidedTreatmentsGetList(patientKey) {
  return (dispatch) => {
    const data = {
      limit: 15,
      patientKey,
      start: 0,
      treatmentVoided: true,
    };

    return dispatch(getVoidedTreatmentsListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function getVoidedTreatmentsLoadList() {
  return (dispatch, getState) => {
    const {
      chart: {
        voidedTreatmentsListFetching,
        voidedTreatmentsList,
        voidedTreatmentsListTotalCount,
        appointment: {
          patient: { patientKey },
        },
      },
    } = getState();

    if (
      voidedTreatmentsList.length === voidedTreatmentsListTotalCount ||
      voidedTreatmentsListFetching
    )
      return;

    const data = {
      limit: 15,
      patientKey,
      start: voidedTreatmentsList.length,
      treatmentVoided: true,
    };

    return dispatch(getVoidedTreatmentsListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

function getVoidedTreatmentsListApi(data) {
  return {
    api: AppointmentApi.getVoidedTreatmentsList,
    types: [
      CHART_VOIDED_TREATMENTS_LIST_START,
      CHART_VOIDED_TREATMENTS_LIST_SUCCESS,
      CHART_VOIDED_TREATMENTS_LIST_ERROR,
    ],
    data,
  };
}

function getPatientHistoryApi(data) {
  return {
    api: PatientApi.getPatientHistory,
    types: [
      GET_PATIENT_HISTORY_DATA_START,
      GET_PATIENT_HISTORY_DATA_SUCCESS,
      GET_PATIENT_HISTORY_DATA_ERROR,
    ],
    data,
  };
}

export function chartVoidedShowModal() {
  return {
    type: CHART_VOIDED_SHOW_MODAL,
  };
}

export function chartVoidedHideModal() {
  return {
    type: CHART_VOIDED_HIDE_MODAL,
  };
}

function restoreVoidedTreatmentsApi(data) {
  return {
    api: AppointmentApi.restoreVoidedTreatments,
    types: [
      RESTORE_VOIDED_TREATMENTS_START,
      RESTORE_VOIDED_TREATMENTS_SUCCESS,
      RESTORE_VOIDED_TREATMENTS_ERROR,
    ],
    data,
  };
}

export function restoreVoidedTreatments(patientKey) {
  return (dispatch, getState) => {
    const {
      chart: { voidedTreatmentsSelectedItems },
    } = getState();

    const keys = [];

    _.forEach(voidedTreatmentsSelectedItems, (item, key) => {
      if (item) keys.push(key);
    });

    if (_.isEmpty(voidedTreatmentsSelectedItems))
      return showMessage("Select Treatments to Restore");

    const data = {
      keys,
    };

    dispatch(startLoader());

    return dispatch(restoreVoidedTreatmentsApi(data))
      .then(() => {
        dispatch(getVoidedTreatmentsGetList(patientKey));
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentRepeatModalShow(index) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW,
    index,
  };
}

export function repeatAppointmentRepeatModalHide() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE,
  };
}

export function repeatAppointmentSaveAfter() {
  return (dispatch, getState) => {
    const {
      form: {
        repeatAppointment: { values },
      },
      chart: {
        repeatAppointmentSelectedDate,
        appointment: {
          id,
          dentist,
          patient: { patientKey },
        },
      },
    } = getState();

    if (_.isEmpty(values) || !values.mainSelect || !values.mainItem) return;

    dispatch(startLoader());

    const data = {
      period: values.mainItem,
      dentistId: _.get(dentist, "id"),
      appointmentId: id,
      status: {
        code: "DentistRuleStatus_Inactive",
        id: 1,
        name: "DentistRuleStatus_Inactive",
      },
      periodUnit: _.find(SelectValues, ["code", values.mainSelect]),
      startDate: getUnixDate(repeatAppointmentSelectedDate),
      patientKey,
    };

    return dispatch(repeatAppointmentSaveItemAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(repeatAppointmentGetList());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function getUnixTime(momentDate) {
  const startDate = new Date(momentDate);

  return startDate.getMilliseconds() - startDate.getTimezoneOffset() * 60000;
}

function getUnixDate(momentDate) {
  return momentDate.valueOf() + getUnixTime(momentDate);
}

export function repeatAppointmentSaveSelectedDate() {
  return (dispatch, getState) => {
    const {
      appointment: {
        repeatAppointmentSelectedDate,
        appointment: {
          id,
          dentist,
          patient: { patientKey },
        },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      dentistId: _.get(dentist, "id"),
      appointmentId: id,
      startDate: Utils.getStartDay(repeatAppointmentSelectedDate),
      patientKey,
    };

    dispatch(repeatAppointmentConfirmModalHide());

    return dispatch(repeatAppointmentSaveItemApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(repeatAppointmentModalHide());
        dispatch(showMessage("Appointment successfully created"));
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentSelectDate(value) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SELECT_DATE,
    value,
  };
}

export function repeatAppointmentSetFollowType(mode) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SET_FOLLOW_TYPE,
    mode,
  };
}

export function repeatAppointmentModalShow() {
  return {
    type: ActionType.PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_SHOW,
  };
}

export function repeatAppointmentModalHide() {
  return {
    type: ActionType.PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_HIDE,
  };
}

export const setPrimaryDiagnosis = (data) => (dispatch) =>
  dispatch({
    api: TransactionApi.setPrimaryDiagnosis,
    types: [
      CHART_DIAGNOSIS_SET_PRIMARY_START,
      CHART_DIAGNOSIS_SET_PRIMARY_SUCCESS,
      CHART_DIAGNOSIS_SET_PRIMARY_ERROR,
    ],
    data,
  });

export function repeatAppointmentGetList() {
  return (dispatch, getState) => {
    const {
      chart: { appointment },
      followUp: { patientKey: followPatientKey, doctorId },
    } = getState();

    dispatch(startLoader());

    const data = {
      patientKey: _.get(appointment, "patient.patientKey") || followPatientKey,
      dentistId: _.get(appointment, "dentist.id") || doctorId,
    };

    return dispatch(repeatAppointmentGetListApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentConfirmModalShow() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW,
  };
}

export function repeatAppointmentRepeatModalSelectDate(value) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE,
    value,
  };
}

export function repeatAppointmentSetRepeatModalMode(mode) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE,
    mode,
  };
}

export function repeatAppointmentUpdateAfter(index, id) {
  return (dispatch, getState) => {
    const {
      form: {
        repeatAppointment: { values },
      },
      appointment: {
        appointment,
        repeatAppointmentList,
        repeatAppointmentRepeatModalMode,
        repeatAppointmentRepeatModalDate,
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: _.get(repeatAppointmentList[index], "id"),
      patientKey: repeatAppointmentList[index].patientKey,
      limitCount: repeatAppointmentList[index].limitCount,
      endDate: repeatAppointmentList[index].endDate,
      period: repeatAppointmentList[index].period,
      dentistId: repeatAppointmentList[index].dentistId,
      appointmentId: _.get(appointment, "id"),
      startDate: getUnixDate(repeatAppointmentRepeatModalDate),
      periodUnit: repeatAppointmentList[index].periodUnit,
    };

    if (repeatAppointmentRepeatModalMode === "until") {
      data.endDate = getUnixDate(repeatAppointmentRepeatModalDate);
      data.endType = {
        id: 1,
        name: "RecurrenceEnd_By_EndDate",
        code: "RecurrenceEnd_By_EndDate",
      };
    } else {
      data.limitCount = values.occurrenceItem;
      data.endType = {
        id: 2,
        name: "RecurrenceEnd_By_CountLimit",
        code: "RecurrenceEnd_By_CountLimit",
      };
    }

    return dispatch(repeatAppointmentSaveItemAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE,
        });
        dispatch(repeatAppointmentGetList());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY,
          id,
        });
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentConfirmModalHide() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE,
  };
}

export function repeatAppointmentSetDeleteId(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REPEAT_APPOINTMENT_SET_DELETE_ID,
      id,
    });
    dispatch({
      type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW,
    });
  };
}

export function repeatAppointmentDeleteAfter(id) {
  return (dispatch, getState) => {
    const {
      appointment: { repeatAppointmentDeleteId },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: id || repeatAppointmentDeleteId,
    };

    return dispatch(repeatAppointmentDeleteAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY,
          id: id || repeatAppointmentDeleteId,
        });
        dispatch(repeatAppointmentGetList());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function repeatAppointmentDeleteAfterApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentDeleteItem,
    types: [
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_START,
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_ERROR,
    ],
    data,
  };
}

function repeatAppointmentSaveItemAfterApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentSaveItemAfter,
    types: [
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR,
    ],
    data,
  };
}

function repeatAppointmentGetListApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentGetList,
    types: [
      ActionType.REPEAT_APPOINTMENT_GET_LIST_START,
      ActionType.REPEAT_APPOINTMENT_GET_LIST_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_GET_LIST_ERROR,
    ],
    data,
  };
}

function repeatAppointmentSaveItemApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentSaveItem,
    types: [
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_START,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_ERROR,
    ],
    data,
  };
}

// ===========================================================================
export function selectTreatmentToCompleteNoteModalHide() {
  return {
    type: SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_HIDE,
  };
}

export function selectTreatmentToCompleteNoteModalShow(onlyCompleteTransactions) {
  return (dispatch, getState) => {
    const {
      chart: { selectTreatmentToCompleteModalMode, selectTreatmentToCompleteSelectedItems },
      session,
    } = getState();

    const checkedAll = _.filter(selectTreatmentToCompleteSelectedItems, (item) => item).length;

    if (selectTreatmentToCompleteModalMode !== "finish" && checkedAll === 0) {
      dispatch(showMessage("Choose at least one transaction"));

      return;
    }

    dispatch(startLoader());

    const showCashierNote = _.get(
      session,
      "alertNotifications.ALERT_CASHIER_NOTE_ON_FINISH_VISIT.selected",
      true,
    );

    return dispatch({
      api: ClinicalNoteApi.completeTransaction,
      types: [
        "CHART_GET_COMPLETE_TRANSACTION_START",
        "CHART_GET_COMPLETE_TRANSACTION_SUCCESS",
        "CHART_GET_COMPLETE_TRANSACTION_ERROR",
      ],
      data: {},
    })
      .then((response) => {
        dispatch(stopLoader());

        if (showCashierNote && !session.miscSettings.useTransactionNotesAsCashierNotes) {
          dispatch({ type: SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_SHOW });
        }
        if (response) {
          dispatch(showSideBar(viewTypes.clinicalNote));
          dispatch(chartSideClinicalNoteActions.replaceItem(response, clinicNoteItemTypes.new));
        } else {
          if (!showCashierNote || session.miscSettings.useTransactionNotesAsCashierNotes) {
            return dispatch(selectTreatmentToCompleteSetComplete(false, onlyCompleteTransactions));
          }
        }
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function voidedTreatmentsToggleSelectedKey(key) {
  return {
    type: VOIDED_TREATMENTS_TOGGLE_SELECT_KEY,
    key,
  };
}

export function printPatientLabel(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());
    return dispatch({
      api: PatientApi.printPatientLabel,
      types: [
        GET_PATIENT_LABEL_PDF_START,
        GET_PATIENT_LABEL_PDF_SUCCESS,
        GET_PATIENT_LABEL_PDF_ERROR,
      ],
      data: { patientKey },
    })
      .then((response) => {
        dispatch(stopLoader());
        dispatch(setPatientLabelModalVisible(true));
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setPatientLabelModalVisible(patientLabelModalVisible) {
  return (dispatch) => {
    if (!patientLabelModalVisible) {
      dispatch(clearPatientLabel());
    }
    return dispatch({
      type: SET_PATIENT_LABEL_MODAL_VISIBLE,
      patientLabelModalVisible,
    });
  };
}

export function clearPatientLabel() {
  return (dispatch) =>
    dispatch({
      type: GET_PATIENT_LABEL_PDF_ERROR,
    });
}

export function selectTreatmentToCompleteToggleSelectedItem(treatmentKey, clear) {
  return {
    type: SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM,
    treatmentKey,
    clear,
  };
}

export function selectTreatmentToCompleteToggleSelectedItemClear() {
  return {
    type: SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM_CLEAR,
  };
}

export function selectTreatmentToCompleteToggleSelectedItems(treatmentKeys) {
  return {
    type: SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEMS,
    treatmentKeys,
  };
}

export function selectTreatmentToCompleteSetFinishMode(mode) {
  return {
    type: SELECT_TREATMENT_TO_COMPLETE_SET_FINISH_MODE,
    mode,
  };
}
export function selectTreatmentToCompleteToggleSelectAll(clinicId) {
  return {
    type: SELECT_TREATMENTS_TO_COMPLETE_TOGGLE_SELECT_ALL,
    clinicId,
  };
}

export function selectTreatmentToCompleteModalShow(mode) {
  return {
    type: SELECT_TREATMENTS_TO_COMPLETE_MODAL_SHOW,
    mode,
  };
}

export function setTreatmentToCompleteVisitType(mode) {
  return {
    type: SET_TREATMENTS_TO_COMPLETE_VISIT_TYPE,
    mode,
  };
}

export function selectTreatmentToCompleteModalHide() {
  return {
    type: SELECT_TREATMENTS_TO_COMPLETE_MODAL_HIDE,
  };
}

export function selectTreatmentToCompleteSetComplete(addNote) {
  return (dispatch, getState) => {
    const {
      chart: {
        selectTreatmentToCompleteSelectedItems,
        selectTreatmentToCompleteModalMode,
        appointment: { id },
        patient: { patientKey },
        isPostPlan,
      },
      session: { miscSettings },
      form: { selectedTreatmentToComplete, selectTreatmentToCompleteDentist },
      transaction: { transactionSearchParameters },
    } = getState();

    const transactionKeys = [];

    _.forEach(selectTreatmentToCompleteSelectedItems, (value, key) => {
      if (value) {
        transactionKeys.push(key);
      }
    });

    // dispatch(selectTreatmentToCompleteNoteModalHide());
    dispatch(startLoader());

    dispatch(chartSideSeriesActions.showDocumentsAssignedModal("multiple_treatment"));

    const data = {
      transactionKeys,
      currentAppointmentId: id,
      dentistToCashierNote:
        (addNote &&
          ((selectedTreatmentToComplete &&
            selectedTreatmentToComplete.values &&
            selectedTreatmentToComplete.values.noteArea) ||
            "")) ||
        "",
      onlyCompleteTransactions:
        isPostPlan &&
        _.toFinite(id) > 0 &&
        _.get(miscSettings, "postTreatmentWithoutCompleteAppointment", false)
          ? true
          : null,
    };

    if (selectTreatmentToCompleteDentist?.values) {
      data.closedDentistId = selectTreatmentToCompleteDentist.values.closedDentistId;
    }
    return dispatch(selectTreatmentToCompleteSetCompleteApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(selectTreatmentToCompleteModalHide());
        if (selectTreatmentToCompleteModalMode === "finish") {
          return { response: true, finish: true };
        } else {
          dispatch(getTeeth({ patientKey }));
          transactionSearchParameters.clear();
          dispatch(transactionActions.getAppointmentTransactions(true));
          dispatch(selectTreatmentToCompleteLoadAll());
          dispatch(getMedicalFillInFormTreatmentList());

          return { response: true };
        }
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function voidedTreatmentsToggleSelectAll() {
  return {
    type: VOIDED_TREATMENTS_TOGGLE_SELECT_ALL,
  };
}

export function selectTreatmentToCompleteLoadList() {
  return (dispatch, getState) => {
    const {
      chart: {
        selectTreatmentToCompleteModalMode,
        selectTreatmentToCompleteModalFinishMode,
        selectTreatmentToCompleteListFetching,
        selectTreatmentToCompleteListTotalCount,
        selectTreatmentToCompleteList,
        appointment: {
          // id,
          patient: { patientKey },
        },
      },
      auth: { doctors },
    } = getState();

    if (
      selectTreatmentToCompleteList.length === selectTreatmentToCompleteListTotalCount ||
      selectTreatmentToCompleteListFetching
    ) {
      return;
    }

    let completeType = "TREATMENT";

    if (
      selectTreatmentToCompleteModalMode === "finish" &&
      selectTreatmentToCompleteModalFinishMode === "diagnosis"
    ) {
      completeType = "DIAGNOSIS";
    }

    const data = {
      // appointmentId: id,
      limit: 25,
      patientKey,
      sortField: "date_desc",
      start: selectTreatmentToCompleteList.length,
      status: "DentalTransactionStatus_PLANNED",
      type: completeType,
    };

    data.doctorIds =
      doctors
        .filter((x) => x.active)
        .map((x) => x.id)
        .join(",") || undefined;

    return dispatch(selectTreatmentToCompleteGetListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function selectTreatmentToCompleteLoadAll(sortType) {
  return (dispatch, getState) => {
    const {
      chart: {
        selectTreatmentToCompleteModalFinishMode,
        selectTreatmentToCompleteListTotalCount,
        selectTreatmentToCompleteList,
        appointment: { patient: appointmentPatient },
        patient,
      },
      // appointment: { appointment },
      auth: { doctors },
    } = getState();

    if (selectTreatmentToCompleteListTotalCount > selectTreatmentToCompleteList.length) {
      let completeType = "TREATMENT";

      if (selectTreatmentToCompleteModalFinishMode === "diagnosis") {
        completeType = "DIAGNOSIS";
      }

      // const appointmentId = id || _.get(appointment, "id");
      const chartPatientKey = _.get(patient, "patientKey");
      const appointmentPatientKey = _.get(appointmentPatient, "patientKey");

      const data = {
        // appointmentId,
        limit: 100,
        patientKey: appointmentPatientKey || chartPatientKey,
        sortField: sortType || "date_desc",
        start: selectTreatmentToCompleteList.length,
        status: "DentalTransactionStatus_PLANNED",
        type: completeType,
      };

      data.doctorIds =
        doctors
          .filter((x) => x.active)
          .map((x) => x.id)
          .join(",") || undefined;

      return dispatch(selectTreatmentToCompleteGetListApi(data))
        .then(() => dispatch(selectTreatmentToCompleteLoadAll()))
        .catch((error) => {
          dispatch(showMessage(error));
        });
    }
  };
}

export function selectTreatmentToCompleteGetList(sortType) {
  return (dispatch, getState) => {
    const {
      chart: {
        selectTreatmentToCompleteModalMode,
        selectTreatmentToCompleteModalFinishMode,
        appointment: { patient: appointmentPatient },
        patient,
      },
      // appointment: { appointment },
      auth: { doctors },
    } = getState();

    let completeType = "TREATMENT";
    // if (!sortType) {
    //   completeType = "DIAGNOSIS";
    // }

    if (
      selectTreatmentToCompleteModalMode === "finish" &&
      selectTreatmentToCompleteModalFinishMode === "diagnosis"
    ) {
      completeType = "DIAGNOSIS";
    }

    // const appointmentId = id || _.get(appointment, "id");
    const chartPatientKey = _.get(patient, "patientKey");
    const appointmentPatientKey = _.get(appointmentPatient, "patientKey");

    const data = {
      // appointmentId,
      limit: 100,
      sortField: sortType || "date_desc",
      start: 0,
      status: "DentalTransactionStatus_PLANNED",
      type: completeType,
      patientKey: appointmentPatientKey || chartPatientKey,
    };

    data.doctorIds =
      doctors
        .filter((x) => x.active)
        .map((x) => x.id)
        .join(",") || undefined;

    return dispatch(selectTreatmentToCompleteGetListApi(data))
      .then(() => dispatch(selectTreatmentToCompleteLoadAll(sortType)))
      .catch((error) => {
        dispatch(showMessage(error));
      });
  };
}

function selectTreatmentToCompleteGetListApi(data) {
  return {
    api: TransactionApi.getList.forComplete,
    types: [
      SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_START,
      SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_SUCCESS,
      SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_ERROR,
    ],
    data,
  };
}

export function selectTreatmentToCompleteSetCompleteApi(data) {
  return {
    api: TransactionApi.completeTransaction,
    types: [
      SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_START,
      SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_SUCCESS,
      SELECT_TREATMENTS_TO_COMPLETE_COMPLETE_TRANSACTION_ERROR,
    ],
    data,
  };
}

// ========================================================

export function initChart({ patientKey, appointmentId, hideAlerts }) {
  return function (dispatch, getState) {
    const { session } = getState();

    dispatch({
      type: INIT_CHART,
    });

    dispatch({
      type: TRANSACTION_INIT_CHART,
    });

    dispatch(startLoader());

    const promises = [
      dispatch(getTeeth({ patientKey })),
      dispatch(getPatient({ patientKey })),
      // dispatch(chartSideSeriesActions.getTreatmentPacket()),
      dispatch(chartSideSeriesActions.getSpecialities()),
      dispatch(chartSideSeriesActions.getFavouritesIcons()),
      dispatch(chartSideClinicalNoteActions.getClinicalNoteCategory()),
      dispatch(chartSidePrescriptionActions.getCategoryList()),
      dispatch(chartSidePrescriptionActions.getFormData()),
      dispatch(getForStaff({ patientKey })),
      dispatch(transactionActions.getDoctors()),
      dispatch({
        api: PatientApi.paymentOptions,
        types: [
          "CHART_GET_PAYMENT_OPTIONS_START",
          "CHART_GET_PAYMENT_OPTIONS_SUCCESS",
          "CHART_GET_PAYMENT_OPTIONS_ERROR",
        ],
        data: { patientKey },
      }),
      dispatch({
        api: PatientApi.reminder.getReminder,
        types: [
          "CHART_GET_PATIENT_REMINDER_START",
          "CHART_GET_PATIENT_REMINDER_SUCCESS",
          "CHART_GET_PATIENT_REMINDER_ERROR",
        ],
        data: { patientKey },
      }),
    ];

    if (appointmentId) {
      promises.push(dispatch(getAppointment({ id: appointmentId })));
    }

    return Promise.all(promises)
      .then((response) => {
        dispatch(stopLoader());

        const patientKey = _.get(_.size(response) > 1 ? response[1] : {}, "patientKey");

        if (patientKey) {
          dispatch(auditActions(patientKey));
        }

        const forStaff = response[7];
        const insurance = response[9];
        const companyPatientReminder = response[10] || {};

        if (
          !hideAlerts &&
          (!_.isEmpty(forStaff.medicalAlerts.alerts) ||
            !_.isEmpty(forStaff.medicationAlerts.alerts))
        ) {
          dispatch(showAlerts());
        }

        const modals = [];

        const $footerButton = (
          <button
            className="btn btn-primary"
            onClick={() => {
              if (modals.length) {
                dispatch(closeSimpleModal());
                modals.shift();
                openModals();
              } else {
                dispatch(closeModal());
              }
            }}
          >
            Ok
          </button>
        );

        const showNoInsurance = _.get(
          session,
          "alertNotifications.ALERT_PATIENT_HAS_NO_INSURANCE.selected",
          true,
        );
        if (insurance.insuranceCompanyCount === 0 && showNoInsurance) {
          modals.push({
            body: "Patient has no insurance",
            footer: $footerButton,
          });
        }

        (insurance.insuranceCoverageLimitReachedItems || []).forEach((item) => {
          const name = item.code === "SECONDARY" ? "Secondary" : "Primary";

          modals.push({
            body: `${name} insurance limited reached`,
            footer: $footerButton,
          });
        });

        if (insurance.primaryInsuranceCardExpired) {
          modals.push({
            body: "Primary insurance card expired",
            footer: $footerButton,
            size: "md",
            modalType: "warning",
          });
        }

        if (insurance.secondaryInsuranceCardExpired) {
          modals.push({
            body: "Secondary insurance card expired",
            footer: $footerButton,
          });
        }

        if (
          companyPatientReminder.doctorReminderActive &&
          !_.isEmpty(companyPatientReminder.doctorReminder)
        ) {
          modals.push({
            body: companyPatientReminder.doctorReminder,
            footer: $footerButton,
          });
        }

        openModals();
        function openModals() {
          if (modals.length) {
            dispatch(openSimpleModal(modals[0]));
          }
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function chartBalanceModalShow() {
  return {
    type: GET_CHART_PATIENT_BALANCE_MODAL_SHOW,
  };
}

export function chartBalanceModalHide() {
  return {
    type: GET_CHART_PATIENT_BALANCE_MODAL_HIDE,
  };
}

export function getPatientBalance(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    Promise.all([
      dispatch(getPatientBalanceApi({ patientKey })),
      dispatch(getPatientBillingBalanceApi({ patientKey })),
    ])
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function getPatientOutstandingAndAdvancedBalance() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chart: {
        appointment: { patient: appointmentPatient },
        patient,
      },
    } = getState();

    const chartPatientKey = _.get(patient, "patientKey");
    const appointmentPatientKey = _.get(appointmentPatient, "patientKey");
    const patientKey = appointmentPatientKey || chartPatientKey;

    Promise.all([
      dispatch(initAdvancedPaymentList()),
      dispatch(getPatientBillingBalanceApi({ patientKey })),
    ])
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function showTooltip(activeTooth, activeToothSupernumeraryPresent) {
  return {
    type: CHART_SHOW_TOOLTIP,
    activeTooth,
    activeToothSupernumeraryPresent,
  };
}

export function hideTooltip() {
  return {
    type: CHART_HIDE_TOOLTIP,
  };
}

export function showSideBar(viewType, options) {
  return {
    type: CHART_SHOW_SIDE_BAR,
    viewType,
    ...options,
  };
}

export function showSideTransaction(transactionType, transactionItem) {
  return {
    type: CHART_SHOW_SIDE_BAR_TRANSACTION,
    transactionType,
    transactionItem,
  };
}

export function hideSideTransaction() {
  return {
    type: CHART_HIDE_SIDE_BAR_TRANSACTION,
  };
}

export function setAddDiagnosisForComplete() {
  return (dispatch) => {
    dispatch({
      type: ADD_DIAGNOSIS_FOR_COMPLETE,
    });
  };
}

export function hideSideBar(saveTooth) {
  return (dispatch, getState) => {
    const {
      chart: { addDiagnosisForComplete },
    } = getState();
    let diagnosisParams = null;
    if (addDiagnosisForComplete) {
      diagnosisParams = {
        diagnosisCompleted: false,
        diagnosisPlanned: true,
        startVisit: true,
      };
      setAddDiagnosisForComplete();
    }
    return Promise.all([
      dispatch({
        type: CHART_HIDE_SIDE_BAR,
        saveTooth,
      }),
      dispatch(transactionActions.getAppointmentTransactions(true, null, diagnosisParams)),
    ]);
  };
}

export function changeToPrimary(toothPosition) {
  return (dispatch, getState) => {
    dispatch({
      type: "changeToPrimary",
      toothPosition,
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const toothPositionCode = `p${toothPosition}`;
    const toothGenerationCode = ToothGeneration.Child;

    dispatch(startLoader());

    const transfer = {
      patientKey,
      toothPositionCode,
      toothGenerationCode,
    };

    dispatch(changeTooth(transfer))
      .then(() => dispatch(getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeToPermanent(toothPosition) {
  return (dispatch, getState) => {
    dispatch({
      type: "changeToPermanent",
      toothPosition,
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const toothPositionCode = `p${toothPosition}`;
    const toothGenerationCode = ToothGeneration.Adult;

    dispatch(startLoader());

    const transfer = {
      patientKey,
      toothPositionCode,
      toothGenerationCode,
    };

    dispatch(changeTooth(transfer))
      .then(() => dispatch(getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeAllToPrimary() {
  return (dispatch, getState) => {
    dispatch({
      type: "changeAllToPrimary",
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const toothGenerationCode = ToothGeneration.Child;

    dispatch(startLoader());

    const promises = [];
    for (let i = 1; i <= 32; i++) {
      promises.push(
        dispatch(
          changeTooth({
            patientKey,
            toothPositionCode: `p${i}`,
            toothGenerationCode,
          }),
        ).catch((e) => e), // for finally
      );
    }

    Promise.all(promises)
      .then(() => {
        dispatch(getTeeth({ patientKey }));
      })
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeAllToPermanent() {
  return (dispatch, getState) => {
    dispatch({
      type: "changeAllToPermanent",
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const toothGenerationCode = ToothGeneration.Adult;

    dispatch(startLoader());

    const promises = [];
    for (let i = 1; i <= 32; i++) {
      promises.push(
        dispatch(
          changeTooth({
            patientKey,
            toothPositionCode: `p${i}`,
            toothGenerationCode,
          }),
        ).catch((e) => e), // for finally
      );
    }

    Promise.all(promises)
      .then(() => dispatch(getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function mixedDentition() {
  return (dispatch, getState) => {
    dispatch({
      type: "mixedDentition",
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    dispatch(startLoader());

    const promises = [];
    for (let i = 1; i <= 32; i++) {
      promises.push(
        dispatch(
          changeTooth({
            patientKey,
            toothPositionCode: `p${i}`,
            toothGenerationCode:
              MixedAdultTooth.indexOf(i) !== -1 ? ToothGeneration.Adult : ToothGeneration.Child,
          }),
        ).catch((e) => e), // for finally
      );
    }

    Promise.all(promises)
      .then(() => dispatch(getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeSupernumerary(toothPosition, activeToothSupernumeraryPresent) {
  return (dispatch, getState) => {
    dispatch({
      type: "addSupernumerary",
    });

    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    dispatch(startLoader());

    const toothCode = `${ToothGeneration.Supernumerary}_p${toothPosition}`;
    const transfer = {
      patientKey,
      toothCode,
    };

    const method = activeToothSupernumeraryPresent ? deleteTooth : addTooth;

    dispatch(method(transfer))
      .then(() => dispatch(getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function auditActions(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    const data = {
      patient: {
        patientKey,
      },
      actionType: {
        code: "PATIENT_FILE_ACCESS_ACTION",
      },
    };

    return dispatch(auditActionsApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function uploadDocs(files) {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      form: {
        chartDocsUploadImage: { values },
      },
    } = getState();

    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file, file.name);
      if (file?.type === "image/jpeg" || file?.type === "image/jpg" || file?.type === "image/png") {
        data.append("typeCode", "PATIENT_ATTACHMENT_CHART_IMAGE");
      } else if (file?.type === "application/pdf") {
        data.append("typeCode", "PATIENT_ATTACHMENT_CHART_PDF");
      } else {
        data.append("typeCode", "PATIENT_ATTACHMENT_CHART_FILE");
      }
    });

    data.append("patientKey", patientKey);
    data.append("description", _.get(values, "description") || "");

    dispatch(startLoader());

    dispatch({
      api: PatientApi.uploadDocs,
      types: [CHART_UPLOAD_PHOTO_START, CHART_UPLOAD_PHOTO_SUCCESS, CHART_UPLOAD_PHOTO_ERROR],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function activeTooth(activeTooth) {
  return {
    type: CHANGE_ACTIVE_TOOTH,
    activeTooth,
  };
}

export function showAlerts() {
  return {
    type: CHART_SHOW_ALERTS,
  };
}

export function hideAlerts() {
  return {
    type: CHART_HIDE_ALERTS,
  };
}

export function getPatientBalanceApi(data) {
  return {
    api: PatientApi.getPatientBalance,
    types: [
      GET_CHART_PATIENT_BALANCE_START,
      GET_CHART_PATIENT_BALANCE_SUCCESS,
      GET_CHART_PATIENT_BALANCE_ERROR,
    ],
    data,
  };
}

export function getPatientBillingBalanceApi(data) {
  return {
    api: PatientApi.getPatientBillingBalance,
    types: [
      GET_CHART_PATIENT_BILLING_START,
      GET_CHART_PATIENT_BILLING_SUCCESS,
      GET_CHART_PATIENT_BILLING_ERROR,
    ],
    data,
  };
}

export function changeActiveTooth(activeTooth) {
  return {
    type: CHANGE_ACTIVE_TOOTH,
    activeTooth,
  };
}

export function showChooseTooltip(toothPosition) {
  return {
    type: CHART_SHOW_CHOOSE_TOOLTIP,
    toothPosition,
  };
}

export function hideChooseTooltip() {
  return {
    type: CHART_HIDE_CHOOSE_TOOLTIP,
  };
}

export function completeModalDoctorSelect(id) {
  return {
    type: CHART_COMPLETE_MODAL_DOCTOR_SELECT,
    id,
  };
}

export function changeEye(key) {
  return {
    type: CHART_CHANGE_EYE,
    key,
  };
}

export function getTeeth(data) {
  return {
    api: PatientApi.toothCondition.getLastList,
    types: [GET_CHART_TEETH_START, GET_CHART_TEETH_SUCCESS, GET_CHART_TEETH_ERROR],
    data,
  };
}

function getForStaff(data) {
  return {
    api: PatientApi.getForStaff,
    types: [GET_CHART_FOR_STAFF_START, GET_CHART_FOR_STAFF_SUCCESS, GET_CHART_FOR_STAFF_ERROR],
    data,
  };
}

function changeTooth(data) {
  return {
    api: PatientApi.toothCondition.changeTooth,
    types: [CHANGE_TOOTH_START, CHANGE_TOOTH_SUCCESS, CHANGE_TOOTH_ERROR],
    data,
  };
}

function addTooth(data) {
  return {
    api: PatientApi.toothCondition.addTooth,
    types: [ADD_TOOTH_START, ADD_TOOTH_SUCCESS, ADD_TOOTH_ERROR],
    data,
  };
}

function deleteTooth(data) {
  return {
    api: PatientApi.toothCondition.deleteTooth,
    types: [DELETE_TOOTH_START, DELETE_TOOTH_SUCCESS, DELETE_TOOTH_ERROR],
    data,
  };
}

export function getPatient(data) {
  return {
    api: PatientApi.getDetailsInfo,
    types: [GET_CHART_PATIENT_START, GET_CHART_PATIENT_SUCCESS, GET_CHART_PATIENT_ERROR],
    data,
  };
}

function getAppointment(data) {
  return {
    api: AppointmentApi.getItem,
    types: [
      GET_CHART_APPOINTMENT_START,
      GET_CHART_APPOINTMENT_SUCCESS,
      GET_CHART_APPOINTMENT_ERROR,
    ],
    data,
  };
}

function stopDealsApi(data) {
  return {
    api: AppointmentApi.stopDeals,
    types: [CHART_STOP_DEALS_START, CHART_STOP_DEALS_SUCCESS, CHART_STOP_DEALS_ERROR],
    data,
  };
}

function stopDealListApi(data) {
  return {
    api: AppointmentApi.stopDealList,
    types: [CHART_STOP_DEAL_LIST_START, CHART_STOP_DEAL_LIST_SUCCESS, CHART_STOP_DEAL_LIST_ERROR],
    data,
  };
}

function auditActionsApi(data) {
  return {
    api: CompanyApi.auditActions,
    types: [CHART_AUDIT_ACTIONS_START, CHART_AUDIT_ACTIONS_SUCCESS, CHART_AUDIT_ACTIONS_ERROR],
    data,
  };
}

function getPatientGenderApi(data) {
  return {
    api: PatientApi.getGender,
    types: [
      CHART_GET_PATIENT_GENDER_START,
      CHART_GET_PATIENT_GENDER_SUCCESS,
      CHART_GET_PATIENT_GENDER_ERROR,
    ],
    data,
  };
}

export function getTreatmentPriceListApi(data) {
  return {
    api: TreatmentApi.getPriceList,
    types: [
      CHART_TREATMENT_PRICE_LIST_START,
      CHART_TREATMENT_PRICE_LIST_SUCCESS,
      CHART_TREATMENT_PRICE_LIST_ERROR,
    ],
    data,
  };
}

export function selectTreatmentToCompleteToggleMenu() {
  return (dispatch) => {
    dispatch({ type: SELECT_TREATMENT_TO_COMPLETE_TOGGLE_MENU });
  };
}
