import _ from "lodash";
import fp from "lodash/fp";

import JSUtils from "./JSUtils";
import seriesTypes from "../constants/chart-sidebar/seriesTypes";
import ToothDetailsType from "../constants/teeth/ToothDetailsType";

export function getSeriesType(seriesObject) {
  const seriesType = seriesObject.seriesType;
  const stackItem = fp.last(seriesObject.seriesStack);
  const hasItem = Boolean(stackItem && stackItem.item);
  return hasItem && seriesType === "mixed" ? stackItem.item.$seriesType : seriesType;
}
export function selectCategories(chartSideSeries) {
  const {
    seriesType,
    diagnosisSpecialities,
    treatmentSpecialities,
    procedureSpecialities,
  } = chartSideSeries;

  switch (seriesType) {
    case seriesTypes.treatment.code:
      return treatmentSpecialities;
    case seriesTypes.diagnosis.code:
      return diagnosisSpecialities;
    case seriesTypes.existing.code:
      return treatmentSpecialities;
    case seriesTypes.procedure.code:
      return procedureSpecialities;
  }
}

export function getPriceItemNew(list, hasPatientInsurance, price, priceItem) {
  let selected = null;
  let selected2 = null;

  const hasPrice = price && parseInt(price);

  if (list && list.length) {
    for (const item of list) {
      if (hasPrice) {
        if (price === item.price) {
          if (priceItem) {
            if (priceItem.id === item.id) {
              selected2 = item;
              break;
            }
          } else {
            selected2 = item;
            break;
          }
        }
      }
      if (hasPatientInsurance && item.priceSource.code === "TRANSACTION_PRICE_SOURCE_INSURANCE") {
        selected = item;
      } else if (
        !selected &&
        item.priceSource &&
        item.priceSource.code === "TRANSACTION_PRICE_SOURCE_COMPANY" &&
        item.defaultPrice
      ) {
        selected = item;
      } else if (
        !selected &&
        item.priceSource &&
        item.priceSource.code === "TRANSACTION_PRICE_SOURCE_COMPANY"
      ) {
        selected = item;
      }
    }

    selected = selected2 || selected;
  }

  return selected;
}

export function getPriceItem(list, hasPatientInsurance) {
  if (list && list.length > 0) {
    for (const item of list) {
      const priceCode = _.get(item, "priceSource.code");

      if (hasPatientInsurance && priceCode === "TRANSACTION_PRICE_SOURCE_INSURANCE") {
        return item;
      }

      if (item.defaultPrice) {
        return item;
      }
    }
  }

  return null;
}

export function getHalfSortedTeeh(teeth) {
  const list = [];
  JSUtils.getSortedList(teeth).forEach((postion) => {
    const item = teeth[postion];
    if (item) {
      const details = item.normal && item.normal.details;

      if (details) {
        list.push(details);
      }

      if (item.supernumerary && item.supernumerary.details) {
        list.push({
          ...item.supernumerary.details,
          supernumerary: true,
        });
      }
    }
  });

  return list;
}
export function getPriceList(list, custom) {
  if (list && list.length) {
    let index = list.length;
    for (let i = 0; i < index; i++) {
      if (list[i].name === "Custom") {
        index = i;
      }
    }
    if (list.length > 0) {
      list[index] = custom;
    }
  }
  return list;
}

export function getToothByCode(toothCode) {
  if (toothCode) {
    const toothCodes = Object.keys(ToothDetailsType);
    const ToothTypes = Object.create(ToothDetailsType);

    for (const toothName of toothCodes) {
      if (ToothTypes[toothName] && toothCode == ToothTypes[toothName].code) {
        const toothItem = ToothTypes[toothName];
        return { id: toothItem.code, code: toothItem.id };
      }
    }
  }
  return null;
}

export function getToothByActiveTooth(activeTooth, teeth) {
  for (const toothPosition in teeth) {
    if (teeth[toothPosition].supernumerary || teeth[toothPosition].normal) {
      const supernumDetails =
        (teeth[toothPosition].supernumerary && teeth[toothPosition].supernumerary.details) ||
        teeth[toothPosition].normal.details;

      if (supernumDetails && supernumDetails.position.slice(1) == activeTooth) {
        return { id: supernumDetails.code, code: supernumDetails.id };
      }

      const details = teeth[toothPosition].normal && teeth[toothPosition].normal.details;

      if (details && details.position.slice(1) == activeTooth) {
        return { id: details.code, code: details.id };
      }
    }
  }

  return null;
}

export function getToothDetailsList(teeth) {
  const list = [];
  JSUtils.getSortedList(teeth).forEach((postion) => {
    const item = teeth[postion];
    if (item) {
      let details = item.normal && item.normal.details;
      if (details) {
        list.push(details);
      }
      details = item.supernumerary && item.supernumerary.details;
      if (details) {
        list.push(details);
      }
    }
  });
  return list;
}

export function isEqualsActiveTooth(details, activeTooth) {
  if (details) {
    return _.toFinite(getActiveTooth(details)) === activeTooth;
  }
  return false;
}
export function getActiveTooth(details) {
  if (details) {
    if (_.isEqual("Supernumerary", _.get(details, "generation"))) {
      return _.get(details, "numeric1");
    }

    return _.get(details, "position", "").slice(1);
  }

  return false;
}

export function isNotAllowedTooth(item, toothId) {
  return (
    !_.isEmpty(item.allowedTeeth) &&
    !_.find(item.allowedTeeth, (allowedTooth) => allowedTooth.id === toothId)
  );
}
