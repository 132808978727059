import _ from "lodash";

import createReducer from "../helpers/createReducer";

import {
  FOLDER_GET_LIST_SUCCESS,
  FOLDER_HIDE_CREATE_DIALOG,
  FOLDER_SHOW_CREATE_DIALOG,
  FOLDER_FETCHING_LIST,
  FOLDER_OPEN_FOLDER,
  FOLDER_CLOSE_FOLDER,
  FOLDER_GET_FILES_LIST_SUCCESS,
  FOLDER_INIT_FOLDER,
  FOLDER_SELECT_ITEM,
  FOLDER_DELETE_ITEMS_SUCCESS,
  FOLDER_CHANGE_MOVING_MODE,
  FOLDER_UNSELECTED_ITEMS,
  FOLDER_OPEN_IMAGE,
  FOLDER_RESET,
  CREATE_FOLDER_SUCCESS,
  IMAGE_TYPE_QUESTION_TOGGLE,
  FILE_SELECT_ITEM,
} from "../actions/folderActions";

const defaultState = {
  folderList: {},
  filesList: {},
  folderStack: [],
  fetchingFolders: false,
  createFolderDialogVisible: false,
  selectedFiles: [],
  selectedFolders: [],
  isMoving: false,
  openedImage: 0,
  folderItem: {},
  imageTypeQuestion: false,
  selectedFile: {},
  perioChartFolder: 0,
};

const resetFolder = {
  openedImage: 0,
  isMoving: false,
  folderStack: [],
  selectedFiles: [],
  selectedFolders: [],
  selectedFile: {},
  createFolderDialogVisible: false,
  perioChartFolder: null,
};

const reducers = {
  [FILE_SELECT_ITEM](state, { file }) {
    return {
      ...state,
      selectedFile: file,
    };
  },
  [IMAGE_TYPE_QUESTION_TOGGLE](state, { _bool }) {
    return {
      ...state,
      imageTypeQuestion: _bool,
    };
  },
  [CREATE_FOLDER_SUCCESS](state, { data }) {
    return {
      ...state,
      folderItem: data,
    };
  },
  [FOLDER_SHOW_CREATE_DIALOG](state) {
    return {
      ...state,
      createFolderDialogVisible: true,
    };
  },
  [FOLDER_HIDE_CREATE_DIALOG](state) {
    return {
      ...state,
      createFolderDialogVisible: false,
    };
  },
  [FOLDER_GET_LIST_SUCCESS](state, { data, request }) {
    const currentFolder = _.last(state.folderStack) || 0;
    const list = (data.list || []).map((x) => ({
      id: x.id,
      name: x.name,
      type: "folder",
    }));
    if (request.start) {
      return {
        ...state,
        folderList: {
          [currentFolder]: state.folderList[currentFolder].concat(list),
          [`${currentFolder}TotalCount`]: data.totalCount,
        },
      };
    }
    const isPerioFolderAvailable = (list || []).findIndex((item) =>
      item?.name?.toLowerCase()?.replace(/\s+/g, "_").includes("perio_chart"),
    );

    return {
      ...state,
      folderList: {
        [currentFolder]: list,
        [`${currentFolder}TotalCount`]: data.totalCount,
      },
      perioChartFolder: isPerioFolderAvailable,
    };
  },
  [FOLDER_FETCHING_LIST](state, { fetching }) {
    return {
      ...state,
      fetchingFolders: fetching,
    };
  },
  [FOLDER_OPEN_FOLDER](state, { folderId }) {
    return {
      ...state,
      folderStack: [...state.folderStack, folderId],
    };
  },
  [FOLDER_CLOSE_FOLDER](state) {
    const folderStack = state.folderStack;

    folderStack.pop();

    return {
      ...state,
      folderStack,
    };
  },
  [FOLDER_GET_FILES_LIST_SUCCESS](state, { data, request }) {
    const currentFolder = _.last(state.folderStack) || 0;
    const list = (data.list || []).map((x) => ({
      type: "file",
      name: x.description,
      createdDate: x.createdDate,
      id: _.get(x, "photo.id"),
      url: _.get(x, "photo.url") || null,
      key: _.get(x, "photo.key"),
      fileTypeCode: _.get(x, "type.code", ""),
    }));
    if (request.start) {
      return {
        ...state,
        filesList: {
          [currentFolder]: state.filesList[currentFolder].concat(list),
          [`${currentFolder}TotalCount`]: data.totalCount,
        },
      };
    }
    return {
      ...state,
      filesList: {
        [currentFolder]: list,
        [`${currentFolder}TotalCount`]: data.totalCount,
      },
    };
  },
  [FOLDER_INIT_FOLDER](state) {
    return {
      ...state,
      ...resetFolder,
    };
  },
  [FOLDER_SELECT_ITEM](state, { itemType, itemId }) {
    let selectedFolders = [...state.selectedFolders];
    let selectedFiles = [...state.selectedFiles];

    if (itemType === "folder") {
      if (_.includes(state.selectedFolders, itemId)) {
        selectedFolders = _.filter(state.selectedFolders, (x) => x !== itemId);
      } else {
        selectedFolders.push(itemId);
      }
    } else if (_.includes(state.selectedFiles, itemId)) {
      selectedFiles = _.filter(state.selectedFiles, (x) => x !== itemId);
    } else if (state.imageTypeQuestion) {
      selectedFiles = [itemId];
    } else {
      selectedFiles.push(itemId);
    }

    return {
      ...state,
      selectedFiles,
      selectedFolders,
    };
  },
  [FOLDER_DELETE_ITEMS_SUCCESS](state) {
    return {
      ...state,
      selectedFiles: [],
      selectedFolders: [],
    };
  },
  [FOLDER_CHANGE_MOVING_MODE](state, { mode }) {
    return {
      ...state,
      isMoving: mode,
    };
  },
  [FOLDER_UNSELECTED_ITEMS](state) {
    return {
      ...state,
      selectedFiles: [],
      selectedFolders: [],
    };
  },
  [FOLDER_OPEN_IMAGE](state, { imageId }) {
    return {
      ...state,
      openedImage: imageId,
    };
  },
  [FOLDER_RESET](state) {
    return {
      ...state,
      ...resetFolder,
      folderItem: {},
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const folderSelector = ({ folder }) => folder;

export default createReducer(defaultState, reducers);
