import React from "react";
import _ from "lodash";
import Back from "../widgets/Back";

import changelog from "../../changelog.json";
import { ChangelogItem } from "./ChangelogItem";
import LogoImage from "../../assets/images/clinic_logo.png";

export default class Version extends React.Component {
  render() {
    const version = _.last(changelog.list).version || "";
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Software version</div>
        </div>
        <div className="body">
          <div className="well">
            <img src={LogoImage} alt="" />
            <br />
            Version {version}
            <br />
            2014 - {new Date().getFullYear()}, Dok32 a Product of I.T. Flakes LLC
            <br />
            All Rights Reserved.
          </div>
          <div className="d-flex flex-column">
            <span className="mb-1" style={{ fontSize: "16px" }}>
              Changelog
            </span>
            <div className="d-flex flex-column">
              {changelog.list.map((x, idx, arr) => {
                const isLast = idx + 1 === arr.length;

                return <ChangelogItem isLast={isLast} key={x.version} data={x} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
