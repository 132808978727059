import _ from "lodash";
import React from "react";
import DataList from "../widgets/DataList";

export function SidebarCategoryChildList({ list, openCategory }) {
  return (
    <DataList className="list-group">
      {list.length === 0 && <span className="text-center">Empty list</span>}

      {list.length > 0 &&
        list.map((x, idx) => {
          const isGroup = _.size(x.children) > 0;

          return (
            <button
              key={idx}
              className="list-group-item"
              onClick={() => openCategory(idx, isGroup, x.id)}
            >
              {x.name}
            </button>
          );
        })}
    </DataList>
  );
}
