import $ from "jquery";
import React from "react";

import { SELECTED, TEETH_TYPES } from "../../../constants/Constants";
import ToothForMouthImage from "../../../assets/images/teeth/tooth_for_mouth_small.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

export default class SelectMouthSides extends React.Component {
  selectItem = (e) => {
    const { typeTab, isEdit, addTransaction, seriesActions } = this.props;

    if (isEdit) {
      return;
    }

    let $elem = $(e.target);
    const selectedId = $elem.attr("id") || "";

    addTransaction.tooth = null;

    const key = selectedId.replace(/Btn/g, "");

    let type = TEETH_TYPES.MouthType[key];

    if (type) {
      seriesActions.changeTransactionByKey(type, "areaDetails", null, typeTab);
    }
  };

  toggleActiveClassName = (name) => {
    const { addTransaction } = this.props;

    let findItem;

    if (addTransaction && addTransaction.areaDetails) {
      findItem = addTransaction.areaDetails.filter((item) => name === item.code);
    }

    return findItem && findItem.length > 0 ? SELECTED : "";
  };

  render() {
    const { className } = this.props;

    return (
      <div className={`mouth-shape-wrap ${className}`}>
        <div className="d-flex align-items-center justify-content-center">
          <div className="layer-selectors">
            <div
              id="upperBtnSelector"
              style={{
                width: "263.80786px",
                height: "98.408142px",
              }}
              className={`rect-selector ${this.toggleActiveClassName("Upper")}`}
            >
              <div
                onClick={this.selectItem}
                style={{
                  width: "263.80786px",
                  height: "98.408142px",
                }}
                id="UpperBtn"
                className="rect"
              >
                <span>Upper</span>
              </div>
            </div>
            <div
              id="lowerBtnSelector"
              style={{
                width: "263.80786px",
                height: "98.408142px",
              }}
              className={`rect-selector ${this.toggleActiveClassName("Lower")}`}
            >
              <div
                onClick={this.selectItem}
                style={{
                  width: "263.80786px",
                  height: "98.408142px",
                }}
                id="LowerBtn"
                className="rect"
              >
                <span>Lower</span>
              </div>
            </div>
            <img
              alt=""
              id="image4004"
              width="109.28758"
              height="184.62175"
              className="teeth-image"
              src={obtainImagePath(ToothForMouthImage)}
            />
          </div>
        </div>
      </div>
    );
  }
}
