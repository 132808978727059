import _ from "lodash";
import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSideSeriesActionTypes";

import seriesModes from "../constants/chart-sidebar/seriesModes";
import seriesTypes from "../constants/chart-sidebar/seriesTypes";
import { defaultStackItem, seriesStackCodes } from "../constants/chart-sidebar/seriesStackCodes";
import Utils from "../helpers/Utils";
import JSUtils from "../helpers/JSUtils";
import * as SeriesUtils from "../helpers/chartSideSeriesUtils";
import treatmentAreas from "../constants/chart-sidebar/treatmentAreas";
import { SidebarTypes } from "../components/chart-treatments-sidebar/TypeButtonGroup";
import { GET_PATIENT_PAYMENT_OPTIONS_SUCCESS } from "../actionTypes/patientActionTypes";

export const defaultState = {
  seriesMode: seriesModes.list.code,
  seriesType: seriesTypes.treatment.code,
  seriesStack: [{}],
  newSeriesType: SidebarTypes.Treatments,
  favouritesIcons: {},
  sidebarCategoryList: [],
  sidebarCategoryListTotalCount: 0,
  treatmentSpecialities: [],
  diagnosisSpecialities: [],
  procedureSpecialities: [],
  list: [],
  listTotalCount: 0,
  categoryList: [],
  subCategoryList: [],
  subCategoryListTotalCount: 0,
  fetchingCategoryList: false,
  fetchingSubCategoryList: false,
  favourites: [],
  mListTreat: [],
  isMixedCodes: false,
  mListTreatTotalCount: 0,
  mListTreatFetching: false,
  mListDiag: [],
  mListDiagTotalCount: 0,
  mListDiagFetching: false,
  fetchingFavourites: false,
  fetchingList: false,
  fetchingItem: false,
  imageSelectorVisible: false,
  activeFavouriteCode: 0,
  activeFavouriteSeriesType: "",
  searchValue: "",
  groupId: "",
  addTransaction: initChartSideObject(),
  surfaceTransactionList: [],
  sortedSurfaceTransactionList: {},
  oldFormIndex: 0,
  transactionStatus: {
    dentalTransactionKeys: [],
  },
  hasInsurance: false,
  item: {},
  transactionItem: null,
  transactionForm: {
    treatmentItem: null,
    area: null,
    group: null,
    quantity: 1,
    unit: 1,
    diagnosisItem: "",
    diagnosisItem2: "",
    diagnosisItems: [],
    selectedDiagnosisKey: "",
    problem: false,
    diagnosisClaimType: null,
    diagnosisYearOfOnSet: null,
  },
  fetchingTreatmentCodes: false,
  diagnosisItems: [],
  addedDiagnosisItems: false,
  removedDiagnosis: {
    diagnosisItem: false,
    diagnosisItem2: false,
  },
  removedDiagnosisItems: [],
  customPrice: initCustomPrice(),
  mixidCodes: [],

  procedureConfirmModalVisible: false,
  activeProcedureId: 0,

  postProcedure: {
    array: [],
    currentIndex: 0,
    postArray: [],
  },

  documents: [],
  documentsAssignedModalVisible: false,
  documentsAssignedTreatment: {},

  documentsAssignedInsideModalVisible: false,
  document: {},

  postOperation: {},

  labOrderFormData: {
    externalLabList: [],
    templateList: [],
    toothList: [],
  },
  labOrderAttachment: [],
  labOrder: {},

  fetchingFavorites: false,

  diagnosisCodes: [],
  treatmentCodes: [],

  fetchingExistingFavorites: false,
  fetchingDiagnosisFavorites: false,

  hasItemInsurance: false,

  hideTabBar: false,
  fetchingProcedureItem: false,
  procedurePriceItem: {},
  packageGroupItemDetails: {},
  treatmentGroupList: [],
  treatmentGroupListFetching: false,
  treatmentGroupListTotalCount: 0,
  labAndRadiologyTreatmentItem: {},
};

/* eslint-disable-next-line */
if (localStorage.getItem("treatmentSideBarView") == "grid") {
  defaultState.seriesMode = seriesModes.grid.code;
}

const reducers = {
  [ActionTypes.CLEAR_DIAGNOSIS_ITEMS](state) {
    return {
      ...state,
      diagnosisItems: [],
      addedDiagnosisItems: false,
      transactionForm: {
        ...state.transactionForm,
        diagnosisItems: [],
      },
    };
  },
  [ActionTypes.CLEAR_SEARCH_VALUES](state) {
    return {
      ...state,
      searchValue: "",
      groupId: "",
    };
  },
  [ActionTypes.CLEAR_ADDED_ITEMS](state) {
    return {
      ...state,
      addedDiagnosisItems: false,
    };
  },
  [ActionTypes.CHART_GET_ALL_DIAGNOSIS_SUCCESS](state, { data, request }) {
    const codes = (data.list || []).map((x) => ({
      value: x.code,
      label: x.code,
    }));

    if (request.start) {
      return {
        ...state,
        diagnosisCodes: state.diagnosisCodes.concat(codes),
      };
    }

    return {
      ...state,
      diagnosisCodes: codes,
    };
  },
  [ActionTypes.CHART_GET_ALL_TREATMENTS_SUCCESS](state, { data }) {
    const codes = (data.list || []).map((x) => x.code);

    return {
      ...state,
      fetchingTreatmentCodes: false,
      treatmentCodes: codes,
    };
  },
  [ActionTypes.CHART_GET_ALL_TREATMENTS_START](state) {
    return {
      ...state,
      fetchingTreatmentCodes: true,
    };
  },
  [ActionTypes.CHART_GET_ALL_TREATMENTS_ERROR](state) {
    return {
      ...state,
      fetchingTreatmentCodes: false,
    };
  },
  [ActionTypes.CHART_GET_CATEGORY_LIST_START](state) {
    return {
      ...state,
      categoryList: [],
      fetchingCategoryList: true,
    };
  },
  [ActionTypes.CHART_GET_CATEGORY_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      fetchingCategoryList: false,
      categoryList: data.list || [],
    };
  },
  [ActionTypes.CHART_GET_CATEGORY_LIST_ERROR](state) {
    return {
      ...state,
      fetchingCategoryList: false,
    };
  },
  [ActionTypes.CHART_GET_PROCEDURE_LIST_START](state, { data }) {
    return {
      ...state,
      subCategoryList: data.start === 0 ? [] : [...state.subCategoryList],
      fetchingSubCategoryList: true,
    };
  },
  [ActionTypes.CHART_GET_PROCEDURE_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      subCategoryList: request.start ? state.subCategoryList.concat(data.list) : data.list || [],
      subCategoryListTotalCount: data.totalCount,
      fetchingSubCategoryList: false,
    };
  },
  [ActionTypes.CHART_GET_PROCEDURE_LIST_ERROR](state) {
    return {
      ...state,
      fetchingSubCategoryList: false,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_ADD_POST_PROCEDURE_ITEMS](state, { payload }) {
    return {
      ...state,
      postProcedure: {
        ...state.postProcedure,
        ...payload,
      },
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_CLEAR_TRANSACTION_ITEM](state) {
    return {
      ...state,
      transactionItem: null,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_CLEAR_MIXED_CODES](state) {
    return {
      ...state,
      mixidCodes: [],
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_IS_MIXED_CODES](state, { isMixed }) {
    return {
      ...state,
      mixidCodes: [],
      isMixedCodes: isMixed,
    };
  },
  [ActionTypes.INIT_SIDE_SERIES](state, { transaction }) {
    let defaultItem = defaultStackItem;
    /* eslint-disable-next-line */
    if (localStorage.getItem("quickMode") !== "true") {
      defaultItem = {
        code: seriesStackCodes.category,
      };
    }

    const seriesStack = transaction ? [{ code: seriesStackCodes.transaction }] : [defaultItem];

    return {
      ...state,
      seriesStack,
      searchValue: "",
    };
  },
  [ActionTypes.CHANGE_SIDE_BAR_SERIES_MODE](state, { seriesMode }) {
    return {
      ...state,
      seriesMode,
    };
  },
  [ActionTypes.CHANGE_SIDE_BAR_SERIES_TYPE](state, { seriesType }) {
    return {
      ...state,
      seriesType,
      searchValue: "",
    };
  },
  [ActionTypes.PUSH_SERIES_STACK](state, { seriesStackItem }) {
    const seriesStack = state.seriesStack.slice();
    seriesStack.push(seriesStackItem);
    return {
      ...state,
      seriesStack,
      searchValue: "",
    };
  },
  [ActionTypes.POP_SERIES_STACK](state) {
    const seriesStack = state.seriesStack.slice();
    seriesStack.pop();
    return {
      ...state,
      seriesStack,
      searchValue: "",
    };
  },
  [ActionTypes.GET_TREATMENT_PACKET_START](state) {
    return {
      ...state,
      fetchingList: true,
    };
  },
  [ActionTypes.GET_TREATMENT_PACKET_SUCCESS](state, { data, request }) {
    if (request && request.packetId) {
      if (request.start) {
        return {
          ...state,
          fetchingList: false,
          list: state.list.concat(data.list),
          listTotalCount: data.totalCount,
        };
      }

      if (request.filterKey !== state.searchValue) return state;
      return {
        ...state,
        fetchingList: false,
        list: data.list || [],
        listTotalCount: data.totalCount,
      };
    }
    return {
      ...state,
      fetchingList: false,
      sidebarCategoryList: data.list || [],
      treatmentSpecialities: data.list || [],
    };
  },
  [ActionTypes.GET_TREATMENT_PACKET_ERROR](state) {
    return {
      ...state,
      fetchingList: false,
    };
  },
  [ActionTypes.GET_SIDEBAR_CATEGORY_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        sidebarCategoryList: state.sidebarCategoryList.concat(data.list),
      };
    }

    return {
      ...state,
      sidebarCategoryList: data.list || [],
      sidebarCategoryListTotalCount: _.toFinite(data.totalCount),
    };
  },
  [ActionTypes.GET_SPECIALITIES_SUCCESS](state, { data }) {
    return {
      ...state,
      procedureSpecialities: data || [],
    };
  },
  [ActionTypes.GET_FAVOURITES_ICONS_SUCCESS](state, { data }) {
    const favouritesIcons = {};

    data.forEach((icon) => {
      favouritesIcons[icon.id] = icon;
    });

    return {
      ...state,
      favouritesIcons,
    };
  },
  [ActionTypes.GET_TREATMENT_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_TREATMENT_MIXID_LIST_START](state) {
    return {
      ...state,
      fetchingList: true,
    };
  },
  [ActionTypes.GET_TREATMENT_MIXID_LIST_ERROR](state) {
    return {
      ...state,
      fetchingList: false,
    };
  },
  [ActionTypes.GET_TREATMENT_MIXID_LIST_SUCCESS](state, { data, request }) {
    _.forEach(data.list || [], (item) => {
      item.$seriesType = seriesTypes.existing.code;
    });

    if (request.start) {
      return {
        ...state,
        fetchingList: false,
        mListTreat: state.mListTreat.concat(data.list || []),
        mixidCodes: state.mixidCodes.concat(data.list || []),
        mListTreatTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;

    return {
      ...state,
      fetchingList: false,
      mListTreat: data.list || [],
      mixidCodes:
        state.mixidCodes.length > 0 ? state.mixidCodes.concat(data.list) : data.list || [],
      mListTreatTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_DIAGNOSIS_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_DIAGNOSIS_MIXID_LIST_START](state) {
    return {
      ...state,
      fetchingList: true,
    };
  },
  [ActionTypes.GET_DIAGNOSIS_MIXID_LIST_ERROR](state) {
    return {
      ...state,
      fetchingList: false,
    };
  },
  [ActionTypes.GET_DIAGNOSIS_MIXID_LIST_SUCCESS](state, { data, request }) {
    _.forEach(data.list || [], (item) => {
      item.$seriesType = seriesTypes.diagnosis.code;
    });

    if (request.start) {
      return {
        ...state,
        fetchingList: false,
        mListDiag: state.mListDiag.concat(data.list),
        mixidCodes: state.mixidCodes.concat(data.list || []),
        mListDiagTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      fetchingList: false,
      mListDiag: data.list || [],
      mixidCodes:
        state.mixidCodes.length > 0 ? state.mixidCodes.concat(data.list) : data.list || [],
      mListDiagTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_PROCEDURE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (state.seriesType !== "procedure") {
      if (request.filterKey !== state.searchValue) return state;
    }
    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_TYPE_AHEAD_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_SIDE_LIST](state) {
    return {
      ...state,
      list: [],
      listTotalCount: 0,
      fetchingList: true,
    };
  },
  [ActionTypes.HIDE_TAB_BAR](state, { bool }) {
    return {
      ...state,
      hideTabBar: bool,
    };
  },
  [ActionTypes.LOAD_SIDE_LIST](state) {
    return {
      ...state,
      fetchingList: true,
    };
  },
  [ActionTypes.GET_SIDE_LIST_END](state) {
    return {
      ...state,
      fetchingList: false,
    };
  },
  [ActionTypes.GET_FAVOURITE_TREATMENTS_START](state) {
    return {
      ...state,
      fetchingFavorites: true,
    };
  },
  [ActionTypes.CHART_CLEAR_FAVORITES](state) {
    return {
      ...state,
      favourites: [],
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_DIAGNOSIS_START](state) {
    return {
      ...state,
      fetchingFavorites: true,
      fetchingDiagnosisFavorites: true,
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_DIAGNOSIS_SUCCESS](state, { data = [] }) {
    const list = data.map((x) => ({
      ...x,
      $seriesType: SidebarTypes.Diagnosis,
    }));

    return {
      ...state,

      fetchingDiagnosisFavorites: false,
      favourites: state.favourites.concat(list),
      fetchingFavorites: state.fetchingExistingFavorites,
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_DIAGNOSIS_ERROR](state) {
    return {
      ...state,

      fetchingDiagnosisFavorites: false,
      fetchingFavorites: state.fetchingExistingFavorites,
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_EXISTING_START](state) {
    return {
      ...state,
      fetchingFavorites: true,
      fetchingExistingFavorites: true,
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_EXISTING_SUCCESS](state, { data = [] }) {
    const list = data.map((x) => ({
      ...x,
      $seriesType: SidebarTypes.Existing,
    }));

    return {
      ...state,

      fetchingExistingFavorites: false,
      favourites: state.favourites.concat(list),
      fetchingFavorites: state.fetchingDiagnosisFavorites,
    };
  },
  [ActionTypes.GET_FAVORITE_MIXED_EXISTING_ERROR](state) {
    return {
      ...state,

      fetchingExistingFavorites: false,
      fetchingFavorites: state.fetchingDiagnosisFavorites,
    };
  },
  [ActionTypes.GET_FAVOURITE_TREATMENTS_SUCCESS](state, { data }) {
    return {
      ...state,
      fetchingFavorites: false,
      favourites: data || [],
    };
  },
  [ActionTypes.GET_FAVOURITE_TREATMENTS_ERROR](state) {
    return {
      ...state,
      fetchingFavorites: false,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_SORT_FAVOURITES](state, { items }) {
    return {
      ...state,
      favourites: items,
    };
  },
  [ActionTypes.GET_FAVOURITE_DIAGNOSIS_START](state) {
    return {
      ...state,
      fetchingFavorites: true,
    };
  },
  [ActionTypes.GET_FAVOURITE_DIAGNOSIS_SUCCESS](state, { data }) {
    return {
      ...state,
      favourites: data || [],
      fetchingFavorites: false,
    };
  },
  [ActionTypes.GET_FAVOURITE_DIAGNOSIS_ERROR](state) {
    return {
      ...state,
      fetchingFavorites: false,
    };
  },
  [ActionTypes.GET_FAVOURITES](state) {
    return {
      ...state,
      favourites: [],
      fetchingFavourites: true,
    };
  },
  [ActionTypes.GET_FAVOURITES_END](state) {
    return {
      ...state,
      fetchingFavourites: false,
    };
  },
  [ActionTypes.SHOW_IMAGE_SELECTOR](state, { code, itemSeriesType }) {
    return {
      ...state,
      imageSelectorVisible: true,
      activeFavouriteCode: code,
      activeFavouriteSeriesType: itemSeriesType,
    };
  },
  [ActionTypes.HIDE_IMAGE_SELECTOR](state) {
    return {
      ...state,
      imageSelectorVisible: false,
      activeFavouriteCode: 0,
      activeFavouriteSeriesType: "",
    };
  },
  [ActionTypes.CHART_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.CHART_SEARCH_GROUP_CHANGE](state, { value }) {
    return {
      ...state,
      groupId: value,
    };
  },
  [ActionTypes.REMOVE_DIAGNOSIS_ITEM](state, { key }) {
    if (state.transactionForm[key]) {
      const removedDiagnosis = state.removedDiagnosis;
      removedDiagnosis[key] = !removedDiagnosis[key];
      return {
        ...state,
        removedDiagnosis,
      };
    }
    return {
      ...state,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_START](state) {
    return {
      ...state,
      item: {},
      fetchingItem: true,
      transactionForm: {},
      addTransaction: initChartSideObject(),
      removedDiagnosis: {
        diagnosisItem: false,
        diagnosisItem2: false,
      },
      customPrice: initCustomPrice(),
    };
  },
  [ActionTypes.CHART_ADD_SELECTED_TEETH](state, { activeTooth, tooth, hasPatientInsurance }) {
    const { addTransaction } = state;

    if (!_.isUndefined(activeTooth)) {
      addTransaction.selectedArea = [`${activeTooth}`];
    }

    if (!_.isUndefined(tooth)) {
      addTransaction.selectedTeeth = [tooth];
    }

    const data = {};

    if (!_.isUndefined(hasPatientInsurance)) {
      data.hasInsurance = hasPatientInsurance;
    }

    return {
      ...state,
      addTransaction,
      ...data,
    };
  },
  [GET_PATIENT_PAYMENT_OPTIONS_SUCCESS](state, { data }) {
    return {
      ...state,
      hasInsurance: data.insuranceCompanyCount > 0,
    };
  },
  [ActionTypes.CHART_SET_SERIES_TYPE](state, { seriesType }) {
    return {
      ...state,
      newSeriesType: seriesType,
    };
  },
  [ActionTypes.CHART_CLEAR_ITEM](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_SUCCESS](state, { data }) {
    const {
      addTransaction,
      hasInsurance,
      newSeriesType: seriesType,
      diagnosisItems,
      addedDiagnosisItems,
    } = state;
    const rest = {};
    const rest2 = { diagnosisItems };
    let notInsurance = false;

    if (data && data.item) {
      rest.area = data.item.area;
      rest.group = data.item.group;
      rest.quantity = 1;
      rest.unit =
        (state.transactionItem && state.transactionItem.unit) || _.get(data, "item.unitBox", 1);

      notInsurance = data.notBillInsurance || data.notBillInsurance2;

      addTransaction.notBillInsurance = data.notBillInsurance;
      addTransaction.notBillInsurance = data.notBillInsurance2;

      addTransaction.notBillInsurance3 = data.notBillInsurance;
      addTransaction.notBillInsurance4 = data.notBillInsurance2;

      if (seriesType === SidebarTypes.Treatments) {
        rest.diagnosisItem = data.item.principalDiagnose || "";
        rest.diagnosisItem2 = data.item.secondaryDiagnose || "";

        if (!addedDiagnosisItems) {
          rest.diagnosisItems = _.get(data, "item.diagnosisItems", []);
          rest2.diagnosisItems = _.get(data, "item.diagnosisItems", []);
        }

        rest.treatmentItem = { id: data.item.id };
      } else if (seriesType === SidebarTypes.Diagnosis) {
        rest.diagnosisItem = { id: data.item.id };
      } else {
        rest.treatmentItem = { id: data.item.id };
      }

      if (seriesType !== SidebarTypes.Diagnosis) {
        const selectedPriceList = data.priceList1
          ? data.priceList1.map((priceItem) => ({
              ...priceItem,
              active: false,
            }))
          : data.priceList2
          ? data.priceList2.map((priceItem) => ({
              ...priceItem,
              active: false,
            }))
          : [];

        addTransaction.price = selectedPriceList.find((x) => x.defaultPrice);

        addTransaction.sendBillInsurance = data.sendBillInsurance;
        addTransaction.sendBillInsurance2 = data.sendBillInsurance2;

        addTransaction.priceList = SeriesUtils.getPriceList(
          selectedPriceList,
          state.customPrice.item,
        ); // TODO choose one from list

        const areaCode = _.get(data, "item.area.code");

        if (
          areaCode === treatmentAreas.Tooth.code ||
          areaCode === treatmentAreas.Surface.code ||
          areaCode === treatmentAreas.Mouth.code ||
          areaCode === treatmentAreas.General.code ||
          areaCode === treatmentAreas.Sextant.code ||
          areaCode === treatmentAreas.Quadrant.code
        ) {
          addTransaction.price = SeriesUtils.getPriceItemNew(
            addTransaction.priceList,
            hasInsurance,
          );
        }
      }

      addTransaction.treatmentCode = {
        code: data.item.code,
        description: data.item.description,
        description2: data.item.description2,
        id: data.item.id,
      };
    }

    return {
      ...state,
      ...rest2,
      item: data,
      fetchingItem: false,
      addTransaction,
      transactionForm: {
        ...state.transactionForm,
        ...rest,
      },
      hasNotItemInsurance: notInsurance,
    };
  },
  [ActionTypes.ADD_CHART_ITEM](state, { item }) {
    return {
      ...state,
      item,
    };
  },
  [ActionTypes.CHANGE_TRANSACTION_ITEM_FORM](state, { value, key }) {
    const { diagnosisItems } = state;

    const rest = {};
    const rest2 = { diagnosisItems };

    const transactionForm = state.transactionForm;

    let removedDiagnosis = state.removedDiagnosis;

    const values = diagnosisItems;

    rest.diagnosisItems = [...values];

    switch (key) {
      case "diagnosisClaimType":
        rest.diagnosisClaimType = value;
        break;
      case "diagnosisYearOfOnSet":
        rest.diagnosisYearOfOnSet = value;
        break;
      case "problem":
        rest.problem = value;
        break;
      case "quantity":
        rest.quantity = value;
        break;
      case "unit":
        rest.unit = value;
        break;
      case "diagnosisItem":
        rest.diagnosisItem = value;
        removedDiagnosis = { ...removedDiagnosis, diagnosisItem: false };
        break;
      case "diagnosisItem2":
        rest.diagnosisItem2 = value;
        removedDiagnosis = { ...removedDiagnosis, diagnosisItem2: false };
        break;
      case "selectedDiagnosisKey":
        rest.selectedDiagnosisKey = value;
        break;
      case "editDiagnoseItem":
        rest.editingDiagnose = value;
        break;

      case "removeDiagnosisItem":
        delete rest.diagnosisItems.splice(value, 1);
        delete rest2.diagnosisItems.splice(value, 1);

        break;
      case "changeDiagnosis":
        rest2.addedDiagnosisItems = true;

        if (transactionForm.editingDiagnose) {
          const editValueId = _.get(transactionForm, "editingDiagnose.id");

          const valueIndex = values.findIndex((x) => x.id === editValueId);

          if (valueIndex >= 0) {
            rest2.diagnosisItems.splice(valueIndex, 1);
          }

          rest.editingDiagnose = undefined;
        } else {
          if (_.isArray(value)) {
            value.forEach((item) => {
              const valueId = _.get(item, "id");
              const currentValue = values.filter((x) => x.id === valueId);

              if (_.size(currentValue) === 0) {
                values.push(item);
              }
            });

            rest2.diagnosisItems = values;
          } else {
            const valueId = _.get(value, "id");
            const currentValue = values.filter((x) => x.id === valueId);

            if (_.size(currentValue) === 0) {
              values.push(value);
            }

            rest2.diagnosisItems = values;
          }
        }

        if (transactionForm.selectedDiagnosisKey) {
          rest[transactionForm.selectedDiagnosisKey] = value;
        }
        break;
    }

    return {
      ...state,
      ...rest2,
      transactionForm: {
        ...state.transactionForm,
        ...rest,
      },
      removedDiagnosis,
      hideTabBar: false,
    };
  },
  [ActionTypes.CHANGE_FROM_FOR_SIDE_BAR_ITEM_BY_KEY](
    state,
    {
      value,
      key,
      hasPatientInsurance,
      callback,
      seriesType,
      isSurface,
      activeTooth,
      supernumeraryTooth,
    },
  ) {
    let { customPrice, item, addTransaction, oldFormIndex } = state;
    const surfaceTransactionList = [...state.surfaceTransactionList];

    const rest = {};
    let len = 1;

    switch (key) {
      case "transactionForm":
        surfaceTransactionList[oldFormIndex] = addTransaction;
        addTransaction = surfaceTransactionList[value];

        oldFormIndex = value;

        const { areaDetails } = addTransaction;

        if (areaDetails && areaDetails.length > 1) {
          len = areaDetails.length;
        }

        rest.treatmentCode =
          item.multiCode && item.multiCode[`treatmentCode${len}`]
            ? item.multiCode[`treatmentCode${len}`]
            : {
                code: item.item.code,
                description: item.item.description,
                description2: item.item.description2,
                id: item.item.id,
              };

        const priceList = SeriesUtils.getPriceList(item[`priceList${len}`], customPrice.item);

        const priceItem = addTransaction.price;
        const price = priceItem ? priceItem.price : 0;

        rest.price = SeriesUtils.getPriceItemNew(priceList, hasPatientInsurance, price, priceItem);

        rest.priceList = priceList;
        break;
      case "areaDetails":
        let isChangedArea = false;
        let areaDetail = _.get(addTransaction, "areaDetails", []);

        let maxCodeCount = 0;

        if (item.multiCode) {
          for (let i = 0; i < item.multiCode.maxCodeCount; i++) {
            if (item[`priceList${i}`] && item[`priceList${i}`].length) {
              maxCodeCount++;
            }
          }
        } else if (seriesType === SidebarTypes.Diagnosis) {
          maxCodeCount = 7;
        } else {
          maxCodeCount = 1;
        }

        let findIndex = -1;

        const findItem = _.filter(areaDetail, (areaItem, index) => {
          if (areaItem.code == value.code) {
            findIndex = index;
          }
          return findIndex != -1;
        });

        if (value && !findItem.length) {
          if (maxCodeCount === 1) {
            areaDetail = [];
          }

          if (areaDetail.length < maxCodeCount) {
            areaDetail.push(value);
            isChangedArea = true;
          }
        } else {
          areaDetail.splice(findIndex, 1);
          isChangedArea = true;
        }

        if (isChangedArea && item.item) {
          const codeName = item.item.area.code;

          rest.selectedArea = Utils.makeSurfaceArea(areaDetail, codeName);

          rest.areaDetails = areaDetail;

          if (areaDetail && areaDetail.length > 1) {
            len = areaDetail.length;
          }

          rest.treatmentCode =
            item.multiCode && item.multiCode[`treatmentCode${len}`]
              ? item.multiCode[`treatmentCode${len}`]
              : {
                  code: item.item.code,
                  description: item.item.description,
                  description2: item.item.description2,
                  id: item.item.id,
                };

          const priceList = SeriesUtils.getPriceList(item[`priceList${len}`], customPrice.item);

          const priceItem = addTransaction.price;
          const price = priceItem ? priceItem.price : 0;

          rest.price = SeriesUtils.getPriceItemNew(
            priceList,
            hasPatientInsurance,
            price,
            priceItem,
          );

          rest.priceList = priceList;

          rest.tooth = _.get(
            state.surfaceTransactionList.find((x) => {
              const toothCode = _.get(x, "tooth.code");

              return toothCode === `Adult_p${activeTooth}` || toothCode === `Child_p${activeTooth}`;
            }),
            "tooth",
            null,
          );

          if (isSurface && supernumeraryTooth) {
            rest.tooth = supernumeraryTooth;
          }

          if (callback) {
            callback(isChangedArea);
          }
        }

        if (isSurface) {
          const index = surfaceTransactionList.findIndex(
            (x) =>
              Number(SeriesUtils.getActiveTooth(_.get(x, "tooth.details"))) === Number(activeTooth),
          );

          if (!_.isEmpty(surfaceTransactionList[index])) {
            surfaceTransactionList[index] = {
              ...surfaceTransactionList[index],
              ...rest,
              ...(supernumeraryTooth ? { tooth: supernumeraryTooth } : {}),
            };
          }
        }
        break;
      case "priceById":
        addTransaction.priceList.map((priceItem) => {
          if (priceItem.id === value) {
            rest.price = priceItem;

            rest.sendBillInsurance = !item.notBillInsurance;
          }
        });

        const index = surfaceTransactionList.findIndex(
          (x) =>
            Number(SeriesUtils.getActiveTooth(_.get(x, "tooth.details"))) === Number(activeTooth),
        );

        if (!_.isEmpty(surfaceTransactionList[index])) {
          surfaceTransactionList[index] = {
            ...surfaceTransactionList[index],
            ...rest,
          };
        }

        break;
      case "price":
        rest.price = value;

        break;
      case "toothPosition":
        rest.toothPosition = value;

        break;
      case "initialSendBillInsurance":
        rest.sendBillInsurance = value;

        break;
      case "initialSendBillInsurance2":
        rest.sendBillInsurance = value;

        break;
      case "sendBillInsurance":
        rest.sendBillInsurance = !addTransaction.sendBillInsurance;

        break;
      case "sendBillInsurance2":
        rest.sendBillInsurance2 = !addTransaction.sendBillInsurance2;

        break;
      case "editable":
        if (!isSurface) {
          rest.editable = !addTransaction.editable;

          surfaceTransactionList[oldFormIndex] = {
            ...addTransaction,
            ...rest,
          };
        } else {
          const index = surfaceTransactionList.findIndex(
            (x) =>
              Number(SeriesUtils.getActiveTooth(_.get(x, "tooth.details"))) === Number(activeTooth),
          );

          if (!_.isEmpty(surfaceTransactionList[index])) {
            surfaceTransactionList[index] = {
              ...surfaceTransactionList[index],
              editable: !surfaceTransactionList[index].editable,
            };
          }
        }

        break;
      default:
        break;
    }

    const sortedSurfaceTransactionList = {};

    surfaceTransactionList.forEach((x) => {
      const toothId = _.get(x, "tooth.code");

      sortedSurfaceTransactionList[toothId] = x;
    });

    const activeToothndex = surfaceTransactionList.findIndex(
      (x) => Number(SeriesUtils.getActiveTooth(_.get(x, "tooth.details"))) === Number(activeTooth),
    );

    return {
      ...state,
      surfaceTransactionList,
      sortedSurfaceTransactionList,
      oldFormIndex,
      addTransaction:
        isSurface && !supernumeraryTooth
          ? {
              ...surfaceTransactionList[activeToothndex],
              ...rest,
            }
          : {
              ...addTransaction,
              ...rest,
            },
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_ERROR](state) {
    return {
      ...state,
      fetchingItem: false,
    };
  },
  [ActionTypes.OPEN_CHART_SIDE_TRANSACTION_ITEM](state, { transactionItem }) {
    return {
      ...state,
      transactionItem,
    };
  },
  [ActionTypes.CHANGE_FROM_FOR_SIDE_BAR_ITEM](state, { data, isUptade }) {
    return {
      ...state,
      addTransaction: isUptade
        ? {
            ...state.addTransaction,
            ...data,
          }
        : data,
    };
  },
  [ActionTypes.CHANGE_FROM_FOR_SIDE_BAR_ITEM_FORM](state, { data, isUptade }) {
    return {
      ...state,
      transactionForm: isUptade
        ? {
            ...state.transactionForm,
            ...data,
          }
        : data,
    };
  },
  [ActionTypes.SELECT_TOOTH_ON_TEETH_SLIDER](state, { toothDetails, areaItem }) {
    const { addTransaction } = state;
    const index = addTransaction.selectedArea.indexOf(areaItem);
    if (index === -1) {
      // add new one
      addTransaction.selectedArea.push(areaItem);

      if (toothDetails) {
        addTransaction.selectedTeeth.push({
          code: toothDetails.id,
          id: toothDetails.code,
          name: areaItem,
        });
      }
    } else {
      // remove existed one
      addTransaction.selectedArea.splice(index, 1);
      addTransaction.selectedTeeth.splice(index, 1);
    }

    // const areaDetail = addTransaction.areaDetails;
    //
    // let len = 1;

    // if (areaDetail && areaDetail.length > 1) {
    //   len = areaDetail.length;
    //   len = item[`priceList${len}`] ? len : 1;
    // }

    // const priceList = SeriesUtils.getPriceList(
    //   item[`priceList${len}`],
    //   customPrice.item,
    // );

    // const priceItem = addTransaction.price;
    // const price = priceItem ? priceItem.price : 0;

    // if (!price) {
    // addTransaction.price = SeriesUtils.getPriceItem(
    //   priceList,
    //   hasPatientInsurance,
    //   price,
    //   priceItem,
    // );

    return {
      ...state,
      addTransaction,
    };
  },
  [ActionTypes.CHANGE_CUSTOM_PRICE](state, { price, activeTooth }) {
    const rest = {};
    const addTransaction = state.addTransaction;
    const item = {
      ...state.customPrice.item,
      price,
    };
    rest.price = item;
    rest.sendBillInsurance = false;
    rest.sendBillInsurance2 = false;
    rest.priceList = SeriesUtils.getPriceList(addTransaction.priceList, item);

    const { surfaceTransactionList } = state;

    const index = surfaceTransactionList.findIndex(
      (x) => Number(SeriesUtils.getActiveTooth(_.get(x, "tooth.details"))) === Number(activeTooth),
    );

    if (!_.isEmpty(surfaceTransactionList[index])) {
      surfaceTransactionList[index] = {
        ...surfaceTransactionList[index],
        ...rest,
      };
    }

    return {
      ...state,
      addTransaction: {
        ...state.addTransaction,
        ...rest,
      },
      customPrice: {
        visible: false,
        item,
      },
    };
  },
  [ActionTypes.TOGGLE_CUSTOM_PRICE](state, { visible }) {
    return {
      ...state,
      customPrice: {
        ...state.customPrice,
        visible,
      },
    };
  },
  [ActionTypes.BATCH_ITEMS_FOR_SIDE_BAR_ITEM_SUCCESS](state, { data }) {
    const transactionStatus = state.transactionStatus;

    transactionStatus.dentalTransactionKeys = [];

    if (data && data.length) {
      data.map((obj) => {
        if ((obj.key && obj.sendBillInsurance) || obj.sendBillInsurance2) {
          transactionStatus.dentalTransactionKeys.push(obj.key);
        }
      });
    }

    transactionStatus.list = data;

    return { ...state, transactionStatus, diagnosisItems: [] };
  },
  [ActionTypes.SET_SURFACE_SLIDER_ITEMS_ARRAY](state, { teeth, activeTooth }) {
    const surfaceTransactionList = [];
    let { addTransaction } = state;

    let editable = false;
    let activeItemIndex = -1;

    const toothList = JSUtils.getSortedList(teeth);

    for (let i = 0; i < toothList.length; i++) {
      const tooth = teeth[toothList[i]];
      let toothObj;
      let toothDetails = tooth && tooth.normal && tooth.normal.details;
      let item;

      if (toothDetails) {
        toothObj = {
          code: toothDetails.id,
          id: toothDetails.code,
        };
        item = initChartSideObject(toothObj, addTransaction.treatmentCode);
        item.toothPosition = toothList[i];
        item.priceList = addTransaction.priceList;
        item.selectedTeeth = addTransaction.selectedTeeth;

        item.tooth = {
          ...item.tooth,
          details: toothDetails,
        };

        if (SeriesUtils.isEqualsActiveTooth(toothDetails, activeTooth)) {
          activeItemIndex = surfaceTransactionList.length;
          editable = true;
        } else {
          editable = false;
        }

        surfaceTransactionList.push({
          ...item,
          editable,
        });
      }

      toothDetails = tooth && tooth.supernumerary && tooth.supernumerary.details;

      if (toothDetails) {
        // for the extra supernumerary
        toothObj = {
          code: toothDetails.id,
          id: toothDetails.code,
        };
        item = initChartSideObject(toothObj, addTransaction.treatmentCode);
        item.toothPosition = toothDetails.position;
        item.priceList = addTransaction.priceList;
        item.selectedTeeth = addTransaction.selectedTeeth;

        item.tooth = {
          ...item.tooth,
          details: toothDetails,
        };

        if (SeriesUtils.isEqualsActiveTooth(toothDetails, activeTooth)) {
          editable = true;
          activeItemIndex = i;
        } else {
          editable = false;
        }
      }
    }

    const { price: transactionPrice } = addTransaction;

    const newTransaction = {
      ...(addTransaction.price
        ? {
            sendBillInsurance: addTransaction.price.needPreApproval,
            sendBillInsurance2: addTransaction.price.needPreApproval2,
          }
        : {}),
      ...surfaceTransactionList[activeItemIndex],
      price: transactionPrice,
      editable: true,
    };

    const oldFormIndex = activeItemIndex;

    const sortedSurfaceTransactionList = {};

    surfaceTransactionList.forEach((x) => {
      const toothId = _.get(x, "tooth.code");

      sortedSurfaceTransactionList[toothId] = x;
    });

    return {
      ...state,
      addTransaction: {
        ...addTransaction,
        ...newTransaction,
        price: transactionPrice,
      },
      surfaceTransactionList,
      sortedSurfaceTransactionList,
      oldFormIndex,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_ADD_ACTIVE_PROCEDURE_ID](state, { id }) {
    return {
      ...state,
      activeProcedureId: id,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_OPEN_PROCEDURE_CONFIRM_MODAL](state, { id }) {
    return {
      ...state,
      procedureConfirmModalVisible: true,
      activeProcedureId: id,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_CLOSE_PROCEDURE_CONFIRM_MODAL](state) {
    return {
      ...state,
      procedureConfirmModalVisible: false,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_OPEN_DOCUMENTS_ASSIGNED_MODAL](state, { documents }) {
    return {
      ...state,
      documentsAssignedModalVisible: true,
      documents,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_CLOSE_DOCUMENTS_ASSIGNED_MODAL](state) {
    return {
      ...state,
      documentsAssignedModalVisible: false,
      documents: [],
    };
  },
  [ActionTypes.DOCUMENTS_ASSIGNED_INSIDE_MODAL_OPEN](state, { document, treatment }) {
    return {
      ...state,
      documentsAssignedInsideModalVisible: true,
      document,
      documentsAssignedTreatment: treatment,
    };
  },
  [ActionTypes.DOCUMENTS_ASSIGNED_INSIDE_MODAL_CLOSE](state) {
    return {
      ...state,
      documentsAssignedInsideModalVisible: false,
    };
  },
  [ActionTypes.DOCUMENTS_ASSIGNED_MODAL_TOGGLE_DOCUMENT](state, { index1, index2 }) {
    const documents = state.documents.slice();
    documents[index1].documents[index2].active = !documents[index1].documents[index2].active;

    return {
      ...state,
      documents,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_SUCCESS](state, { data }) {
    return {
      ...state,
      postOperation: data,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      labOrderFormData: data,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_START](state) {
    return {
      ...state,
      labOrderAttachment: [],
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_SUCCESS](state, { data }) {
    const labOrderAttachment = state.labOrderAttachment.slice();
    labOrderAttachment.unshift(data);

    return {
      ...state,
      labOrderAttachment,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_LAB_ORDER_DELETE_ATTACHMENT_START](state, { index }) {
    const labOrderAttachment = state.labOrderAttachment.slice();
    labOrderAttachment.splice(index, 1);

    return {
      ...state,
      labOrderAttachment,
    };
  },
  [ActionTypes.CHART_SIDE_SERIES_SAVE_LAB_ORDER_SUCCESS](state, { data }) {
    return {
      ...state,
      labOrder: data,
    };
  },
  [ActionTypes.FETCHING_PROCEDURE_ITEM](state, { _bool }) {
    return {
      ...state,
      fetchingProcedureItem: _bool,
    };
  },
  [ActionTypes.GET_TREATMENT_CODE_ITEM](state, { data }) {
    return {
      ...state,
      procedurePriceItem: { ...data },
    };
  },
  [ActionTypes.GET_TREATMENT_ITEM_PRICE_SUCCESS](state, { data }) {
    let defaultPrice = (data || []).find((x) => x.defaultPrice);
    const { procedurePriceItem } = state;
    procedurePriceItem.currentObj.treatmentName = `${procedurePriceItem.item.name} ${procedurePriceItem.item.description}`;
    procedurePriceItem.currentObj.quantity = 1;
    procedurePriceItem.currentObj.unit = 1;
    procedurePriceItem.currentObj.price = defaultPrice?.price;
    procedurePriceItem.currentObj.systemPrice = Utils.formatPrice(
      defaultPrice?.price,
      defaultPrice?.price,
    );
    procedurePriceItem.currentObj.systemPriceTotals = Utils.formatPrice(
      defaultPrice?.price,
      defaultPrice?.price,
    );
    procedurePriceItem.currentObj.id = procedurePriceItem?.item?.id;
    procedurePriceItem.currentObj.code = procedurePriceItem?.item?.code;
    return {
      ...state,
      procedurePriceItem,
    };
  },
  [ActionTypes.GET_TREATMENT_PROCEDURE_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      packageGroupItemDetails: data,
    };
  },
  [ActionTypes.CHART_SAVE_PROCEDURE_ITEM_SUCCESS](state) {
    return {
      ...state,
      packageGroupItemDetails: {},
    };
  },
  [ActionTypes.GET_TREATMENT_GROUP_LIST_START](state) {
    return {
      ...state,
      treatmentGroupListFetching: true,
    };
  },
  [ActionTypes.GET_TREATMENT_GROUP_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      treatmentGroupListFetching: false,
      treatmentGroupList: request.start
        ? state.treatmentGroupList.concat(data?.list || [])
        : data?.list || [],
      treatmentGroupListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_TREATMENT_GROUP_LIST_ERROR](state) {
    return {
      ...state,
      treatmentGroupListFetching: false,
      treatmentGroupList: [],
      treatmentGroupListTotalCount: 0,
    };
  },
  [ActionTypes.GET_TREATMENT_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      labAndRadiologyTreatmentItem: data,
    };
  },
};
function initCustomPrice() {
  return {
    visible: false,
    item: {
      priceSource: { code: "TRANSACTION_PRICE_SOURCE_COMPANY" },
      price: "0",
      name: "Custom",
      id: 0,
    },
  };
}
export default createReducer(defaultState, reducers);

function initChartSideObject(tooth, treatmentCode) {
  return {
    treatmentCode: treatmentCode || null,
    areaDetails: [],
    tooth: tooth || null,
    toothPosition: null,
    selectedArea: [],
    selectedTeeth: [],
    priceSource: null,
    price: null,
    priceList: [],
    key: null,
    selectedDiagnosisIndex: null,
    sendBillInsurance: true,
    sendBillInsurance2: true,
    editable: false,
  };
}
