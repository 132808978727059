import React from "react";
import { capitalize } from "lodash";
import { useFormikContext } from "formik";

import { TextField } from "../form/TextField";
import { RadioField } from "../form/RadioField";
import { SelectField } from "../form/SelectField";
import { WeeklyOptions } from "./BlockedTimeSlotWeeklyForm";

export enum YearlyTypes {
  Every = "every",
  The = "the",
}

export enum YearMonthsTypes {
  January = "JANUARY",
  February = "FEBRUARY",
  March = "MARCH",
  April = "APRIL",
  May = "MAY",
  June = "JUNE",
  July = "JULY",
  August = "AUGUST",
  September = "SEPTEMBER",
  October = "OCTOBER",
  November = "NOVEMBER",
  December = "DECEMBER",
}

export const WeekNumberOptions = [
  {
    value: YearMonthsTypes.January,
    label: capitalize(YearMonthsTypes.January),
  },
  {
    value: YearMonthsTypes.February,
    label: capitalize(YearMonthsTypes.February),
  },
  {
    value: YearMonthsTypes.March,
    label: capitalize(YearMonthsTypes.March),
  },
  {
    value: YearMonthsTypes.April,
    label: capitalize(YearMonthsTypes.April),
  },
  {
    value: YearMonthsTypes.May,
    label: capitalize(YearMonthsTypes.May),
  },
  {
    value: YearMonthsTypes.June,
    label: capitalize(YearMonthsTypes.June),
  },
  {
    value: YearMonthsTypes.July,
    label: capitalize(YearMonthsTypes.July),
  },
  {
    value: YearMonthsTypes.August,
    label: capitalize(YearMonthsTypes.August),
  },
  {
    value: YearMonthsTypes.September,
    label: capitalize(YearMonthsTypes.September),
  },
  {
    value: YearMonthsTypes.October,
    label: capitalize(YearMonthsTypes.October),
  },
  {
    value: YearMonthsTypes.November,
    label: capitalize(YearMonthsTypes.November),
  },
  {
    value: YearMonthsTypes.December,
    label: capitalize(YearMonthsTypes.December),
  },
];

export function BlockedTimeSlotYearlyForm() {
  const { values } = useFormikContext<any>();

  const isTypeEvery = values.yearlyType === YearlyTypes.Every;
  const isTypeThe = values.yearlyType === YearlyTypes.The;

  return (
    <div className="d-flex flex-column">
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">
          <RadioField name="yearlyType" radioValue={YearlyTypes.Every} label="Every:" />
        </div>
        <div className="col-sm-3">
          <SelectField options={WeekNumberOptions} name="yearMonth" isDisabled={!isTypeEvery} />
        </div>
        <div className="col-sm-3">
          <TextField type="number" name="repeatCount" disabled={!isTypeEvery} />
        </div>
        <div className="col-sm-4" />
      </div>

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">
          <RadioField name="yearlyType" radioValue={YearlyTypes.The} label="The:" />
        </div>
        <div className="col-sm-3">
          <SelectField
            placeholder="-- --"
            name="yearWeekNumber"
            isDisabled={!isTypeThe}
            options={WeekNumberOptions}
          />
        </div>
        <div className="col-sm-3">
          <SelectField
            name="yearWeekDay"
            placeholder="-- --"
            options={WeeklyOptions}
            isDisabled={!isTypeThe}
          />
        </div>
        <div className="col-sm-1 text-center">of</div>
        <div className="col-sm-3">
          <SelectField options={WeekNumberOptions} name="yearMonth" isDisabled={!isTypeThe} />
        </div>
      </div>
    </div>
  );
}
