import React from "react";

import moment from "moment";

import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import RepeatAppointmentFollowTypeSelect from "./RepeatAppointmentFollowTypeSelect";
import RepeatAppointmentConfirmModal from "./RepeatAppointmentConfirmModal";
import RepeatAppointmentFollowAfter from "./RepeatAppointmentFollowAfter";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as appointmentActions from "../../../actions/appointmentActions";
import Show from "../../widgets/Show";
import { Sidebar } from "../../sidebar/Sidebar";
import { DatePicker } from "../../ui/DatePicker";
import { SidebarHeader } from "../../sidebar/SidebarHeader";
import CheckImage from "../../../assets/images/call-list/check_2x.png";

const enhancer = connect(null, bindActions({ appointmentActions }));

class RepeatAppointmentSidebar extends React.Component {
  componentDidMount() {
    this.props.appointmentActions.repeatAppointmentGetList();
  }

  handleCalendarChange = (value, typeNow) => {
    if (typeNow) {
      this.props.appointmentActions.repeatAppointmentSelectDate(value);
    }
  };

  render() {
    const {
      show,
      repeatAppointmentSelectedDate,
      repeatAppointmentMode,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentList,
      repeatAppointment,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalIndex,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentDeleteId,
      withPickDate = true,
    } = this.props;

    const followTypeNow = repeatAppointmentMode === "now";

    return (
      <>
        <Sidebar show={show} onHide={this.props.appointmentActions.repeatAppointmentModalHide}>
          <SidebarHeader
            title="FOLLOW UP"
            onBackClick={this.props.appointmentActions.repeatAppointmentModalHide}
            rightComponent={
              <button
                className="btn btn-link pull-right btn-chk"
                type="button"
                disabled={!followTypeNow}
                onClick={() => {
                  if (followTypeNow) {
                    this.props.onSubmit(followTypeNow);
                  }
                }}
              >
                <img alt="" width="15px" src={CheckImage} />
              </button>
            }
          />

          <Show if={withPickDate}>
            <div>
              <RepeatAppointmentFollowTypeSelect
                label="PICK A DATE FOR FOLLOW UP"
                mode="now"
                selected={followTypeNow}
              />
              <div className="repeat-appointment-main-block">
                <DatePicker
                  inline={true}
                  onChange={(value) => {
                    this.handleCalendarChange(value, followTypeNow);
                  }}
                  selected={repeatAppointmentSelectedDate}
                />
              </div>
              <div className="repeat-appointment-line-title">
                <span className="repeat-appointment-text">OR</span>
              </div>
            </div>
          </Show>
          <RepeatAppointmentFollowTypeSelect
            label="FOLLOW UP AFTER"
            mode="after"
            selected={!followTypeNow}
          />
          <RepeatAppointmentFollowAfter
            repeatAppointmentList={repeatAppointmentList}
            repeatAppointment={repeatAppointment}
            initialValues={{ occurrenceItem: 1 }}
            onClickAdd={this.props.onSaveFollowAfter}
            onRepeatClick={this.props.onRepeatClick}
            onDeleteClick={this.props.onDeleteClick}
            onClickUpdate={this.props.onClickUpdate}
            repeatAppointmentClickedRepeatArray={repeatAppointmentClickedRepeatArray}
            repeatAppointmentRepeatModalIndex={repeatAppointmentRepeatModalIndex}
            repeatAppointmentRepeatModalDate={repeatAppointmentRepeatModalDate}
            repeatAppointmentRepeatModalMode={repeatAppointmentRepeatModalMode}
            repeatAppointmentRepeatModalVisible={repeatAppointmentRepeatModalVisible}
            repeatAppointmentConfirmModalVisible={repeatAppointmentConfirmModalVisible}
            repeatAppointmentDeleteId={repeatAppointmentDeleteId}
            disabled={followTypeNow}
          />
        </Sidebar>
        <RepeatAppointmentConfirmModal
          text={
            "Appointment will be scheduled for " +
            moment(repeatAppointmentSelectedDate).format(DATE_FORMAT_LEGACY)
          }
          handleClose={this.props.appointmentActions.repeatAppointmentConfirmModalHide}
          handleOK={this.props.handleOK}
          show={repeatAppointmentConfirmModalVisible && followTypeNow}
        />
      </>
    );
  }
}

export default enhancer(RepeatAppointmentSidebar);
