import React from "react";
import { Modal } from "../../ui/Modal";
import { withRouter } from "react-router";

import PatientSelectAppointmentSidebarSelect from "./PatientSelectAppointmentSidebarSelect";
import PatientSelectAppointmentSidebarList from "./PatientSelectAppointmentSidebarList";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { DatePicker } from "../../ui/DatePicker";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientSelectAppointmentSidebar extends React.Component {
  componentDidMount() {
    this.props.patientRegisterActions.patientSelectsAppointmentSidebarInit();
  }

  onCreateNew = () => {
    this.props.history.push(
      createUrl(Routes.DashboardAppointment, {
        params: {
          id: 0,
        },
        query: {
          patientKey: this.props.patientKey,
        },
      }),
    );
    this.props.patientRegisterActions.patientSelectAppointmentSidebarHide();
  };

  render() {
    const { patientRegister } = this.props;

    const dateMode = patientRegister.patientSelectAppointmentSidebarMode === "date";

    return (
      <Modal
        className="patient-select-appointment-sidebar"
        animation={false}
        show={patientRegister.patientSelectAppointmentSidebarVisible}
        onHide={this.props.patientRegisterActions.patientSelectAppointmentSidebarHide}
        title={
          <div>
            <button
              className="btn btn-link pull-left"
              onClick={this.props.patientRegisterActions.patientSelectAppointmentSidebarHide}
            >
              ❮ &nbsp; BACK
            </button>
            <button className="btn btn-link pull-right" onClick={this.onCreateNew}>
              CREATE NEW
            </button>
          </div>
        }
      >
        <div>
          <PatientSelectAppointmentSidebarSelect
            selected={dateMode}
            label="Choose date"
            mode="date"
          >
            <div className="patient-select-appointment-sidebar-choose-date pull-right">
              <DatePicker
                disabled={!dateMode}
                selected={this.props.patientRegister.patientSelectAppointmentSidebarDate}
                onChange={this.props.patientRegisterActions.patientSelectAppointmentSidebarSetDate}
              />
            </div>
          </PatientSelectAppointmentSidebarSelect>
          <PatientSelectAppointmentSidebarSelect
            selected={!dateMode}
            label="Most Recent"
            mode="recent"
          />
          <PatientSelectAppointmentSidebarList
            hideSideBar={this.props.patientRegisterActions.patientSelectAppointmentSidebarHide}
            list={patientRegister.patientSelectAppointmentSidebarList}
          />
        </div>
      </Modal>
    );
  }
}
export default withRouter(enhancer(PatientSelectAppointmentSidebar));
