import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import FlexBox from "../ui/FlexBox";

export function MedicationFormItem({ name, label, inputType = "text" }) {
  return (
    <FlexBox gutter={8} align="center" className="form-group col-sm-4">
      <FlexBox>
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      </FlexBox>

      <FlexBox flex={true}>
        <Field id={name} name={name} type={inputType} component="input" className="form-control" />
      </FlexBox>
    </FlexBox>
  );
}

MedicationFormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.string,
};

MedicationFormItem.defaultProps = {
  inputType: "text",
};
