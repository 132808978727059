import React from "react";

export default function condition(props) {
  const { property, up } = props;
  const getImageType = (property) => {
    if (property.code === "TOOTHSHIFT_INTRUDED") {
      return up ? "arrow_up" : "arrow_down";
    }
    if (property.code === "TOOTHSHIFT_EXTRUDED") {
      return up ? "arrow_down" : "arrow_up";
    }
    return "";
  };
  if (!property || property.code === "TOOTHSHIFT_NONE") return null;
  const src = require(`../../../assets/images/conditions/arrows/${getImageType(property)}@2x.png`)
    .default;
  let className = "ConditionCrown-wrap";

  if (!up) {
    className += " tooth-image-ConditionCrown-bottom";
  }

  return (
    <div className={className}>
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
