import React from "react";

export default function condition(props) {
  const { property } = props;
  const getImageType = (property) => {
    if (property.code === "TOOTHSHIFT_MESIALIZED") {
      return "arrow_right_condition";
    }
    if (property.code === "TOOTHSHIFT_DISTALIZED") {
      return "arrow_left_condition";
    }
    return "";
  };
  if (!property || property.code === "TOOTHSHIFT_NONE") return null;

  const src = require(`../../../assets/images/conditions/arrows/${getImageType(property)}@2x.png`)
    .default;

  return (
    <div className="condition-wrap tooth-image-condition-bottom">
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
