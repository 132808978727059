import React from "react";

export function SidebarContentActions({ children, style }) {
  return (
    <div
      className="mb-2 d-flex justify-content-around flex-shrink-0 sidebar-changed-content-actions"
      style={style}
    >
      {children}
    </div>
  );
}
