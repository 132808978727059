import React from "react";
import { Button, Modal } from "react-bootstrap";

import Utils from "../../helpers/Utils";
import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import MemberImage from "../widgets/MemberImage";
import whatsappImage from "../../assets/images/whatsapp3.png";
import { getCompanyFormatFullDate } from "../../helpers/DateUtils";
import { WhatsAppChattingInterface } from "./WhatsAppChattingInterface";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/loaderActions";
const WhatsAppChattingUsersTable = (props) => {
  const {
    list,
    chatSidebarVisible,
    openWhatsAppChat,
    hideWhatsAppChat,
    // setChattingPatientList,
    archivePatientChatting,
    history,
    location,
  } = props;
  const dispatch = useDispatch();
  const { getFullName } = Utils;

  const viewPatientRelatedPage = (patientKey, pageType) => {
    const pageTypeUrls = {
      profile: `${Routes.PatientView}/${patientKey}`,
      schedule: `${Routes.PatientAppointmentsList}?patientKey=${patientKey}`,
    };

    if (!patientKey) {
      dispatch(showMessage("Patient not found"));
      return;
    }

    switch (pageType) {
      case "chart":
        history.push(
          createUrl(Routes.DashboardChart, {
            params: { type: "Planning" },
            query: { patientKey },
          }),
        );
        break;
      case "appointment":
        history.push(
          createUrl(Routes.DashboardAppointment, {
            params: { id: 0 },
            query: { patientKey },
          }),
        );
        break;
      default:
        history.push(
          createUrl(pageTypeUrls[pageType], {
            params: { patientKey },
          }),
        );
    }
  };

  const archivePatientChattingHandler = (patientKey, index) => {
    const newList = list.slice();
    newList.splice(index, 1);
    // setChattingPatientList(newList);
    archivePatientChatting({ patientKey });
  };

  const $rows = list.map((chattingItem, index) => (
    <tr key={chattingItem?.lastReplyDate}>
      <td className="t-data-patient">
        <div
          className="chatting-patient-profile"
          onClick={() => openWhatsAppChat(chattingItem?.patient)}
        >
          <MemberImage size={50} />
          <p className="chatting-patient-name">{getFullName(chattingItem.patient)}</p>
        </div>
      </td>
      <td>
        <div onClick={() => openWhatsAppChat(chattingItem?.patient)}>
          <img className="whatsapp-icon-vector" src={whatsappImage} alt="Whatsapp-icon" />
        </div>
      </td>
      <td className="t-data-patient">{chattingItem.patient?.mobilePhoneNumber}</td>
      <td className="t-data-patient">{chattingItem.patient?.chartNumber}</td>
      <td className="t-data-patient">{getCompanyFormatFullDate(chattingItem.lastReplyDate)}</td>
      <td className="t-data-patient buttons">
        <Button
          bssize="small"
          bsstyle="success"
          onClick={() => viewPatientRelatedPage(chattingItem.patient?.patientKey, "profile")}
        >
          Patient profile
        </Button>
        <Button
          bssize="small"
          bsstyle="success"
          onClick={() => viewPatientRelatedPage(chattingItem.patient?.patientKey, "chart")}
        >
          Patient chart
        </Button>
        <Button
          bssize="small"
          bsstyle="success"
          onClick={() => viewPatientRelatedPage(chattingItem.patient?.patientKey, "appointment")}
        >
          BOOK APPT
        </Button>
        <Button
          bssize="small"
          bsstyle="default"
          onClick={() => archivePatientChattingHandler(chattingItem.patient?.patientKey, index)}
        >
          Archive
        </Button>
      </td>
    </tr>
  ));

  return (
    <div className="whatsapp-chatting-wrap">
      <div className="whatsapp-chatting-table">
        <p className="whatsupp-message-text">Messages with patients</p>
        <table className="whatsapp-chatting-users-table">
          <thead>
            <tr className="chatting-table-caption">
              <th>Patient</th>
              <th style={{ width: "50px" }} />
              <th>Phone number</th>
              <th>Chart #</th>
              <th>Last reply date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{$rows}</tbody>
        </table>
      </div>
      <Modal
        show={chatSidebarVisible}
        animation={false}
        onHide={hideWhatsAppChat}
        dialogClassName="whatsapp-chatting-modal chart-side-bar"
      >
        <WhatsAppChattingInterface location={location} hideWhatsAppChat={hideWhatsAppChat} />
      </Modal>
    </div>
  );
};

export default WhatsAppChattingUsersTable;
