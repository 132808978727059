import React, { useState } from "react";
import styled from "styled-components";

const CustomTooltip = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setOpen(true)}
        // onMouseMove={() => setOpen(false)}
        onMouseLeave={() => setOpen(false)}
        onBlur={() => setOpen(false)}
      >
        {children}
        {open && <Wrapper>{title}</Wrapper>}
      </div>
    </>
  );
};

const Wrapper = styled.p`
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #b1bf35;
  color: white;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  line-height: 20px;
  font-size: 12px;
  z-index: 9999999999;
`;
export default CustomTooltip;
