import React from "react";

import AddPatientForm from "./AddPatientFormLegacy";
import { AppointmentPatientViewWrapper } from "../appointment-patient/AppointmentPatientViewWrapper";

export default function LeftSide(props) {
  const {
    patient,
    history,
    location,
    elements,
    showSideBar,
    choosePatient,
    canViewContacts,
    appointmentItem,
    fromFirstContact,
    openPatientChart,
    createPhoneNumber,
    eligibilityIdPayer,
    setEligibilityIdPayer,
    eligibilityStatus,
    setEligibilityStatus,
    openPatientProfile,
    temporaryPatientData,
    updatePatientFormData,
    onShowEligibilityDetails,
    patientLabelModalVisible,
    patientLabel,
    chartBillingBalanceValue,
    onShowInsuranceForm,
  } = props;
  if (patient) {
    updatePatientFormData(patient);
  }

  return (
    <div
      className={patient ? "left-side d-flex flex-column" : "left-side grey-bg"}
      style={{ position: "static" }}
    >
      {!patient ? (
        <AddPatientForm
          choosePatient={choosePatient}
          fromFirstContact={fromFirstContact}
          createPhoneNumber={createPhoneNumber}
          temporaryPatientData={temporaryPatientData}
          updatePatientFormData={updatePatientFormData}
        />
      ) : (
        <AppointmentPatientViewWrapper
          history={history}
          checkEligibilityVisible={props.checkEligibilityVisible}
          location={location}
          showSideBar={showSideBar}
          canViewContacts={canViewContacts}
          appointmentItem={appointmentItem}
          elements={elements.patientProfile}
          openPatientChart={openPatientChart}
          onProfileClick={openPatientProfile}
          onShowEligibilityDetails={onShowEligibilityDetails}
          eligibilityIdPayer={eligibilityIdPayer}
          setEligibilityIdPayer={setEligibilityIdPayer}
          eligibilityStatus={eligibilityStatus}
          setEligibilityStatus={setEligibilityStatus}
          patientLabelModalVisible={patientLabelModalVisible}
          patientLabel={patientLabel}
          chartBillingBalanceValue={chartBillingBalanceValue}
          onShowInsuranceForm={onShowInsuranceForm}
        />
      )}
    </div>
  );
}
