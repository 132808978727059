import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartDermatologyActionTypes";

export const defaultState = {
  areas: {},
};

const reducers = {
  [ActionTypes.CHART_DERMATOLOGY_TOGGLE_AREA](state, { index }) {
    return {
      ...state,
      areas: {
        ...state.areas,
        [index]: !state.areas[index],
      },
    };
  },
};

export default createReducer(defaultState, reducers);
