import { httpClient } from "./BaseApi";

const POST_OP_ITEM_URL = "api/patient/postOperationInstruction/item.json";
const POST_OP_LIST_URL = "api/patient/postOperationInstruction/list.json";
const POST_OP_SEND_TO_EMAIL_URL = "api/patient/postOperationInstruction/send-to-email.json";

const POST_OP_TEMPLATE_ITEM_URL = "api/patient/postOperationInstruction/template/item.json";
const POST_OP_TEMPLATE_LIST_URL = "api/patient/postOperationInstruction/template/list.json";

const PostOpApi = {
  saveItem: (data) => httpClient.post(POST_OP_ITEM_URL, { data }),
  getItem: (params) => httpClient.get(POST_OP_ITEM_URL, { params }),
  getOwnedList: (params) => httpClient.get(POST_OP_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(POST_OP_ITEM_URL, { params }),
  sendToEmail: (params) => httpClient.get(POST_OP_SEND_TO_EMAIL_URL, { params }),
  template: {
    getList: (params) => httpClient.get(POST_OP_TEMPLATE_LIST_URL, { params }),
    getItem: (params) => httpClient.get(POST_OP_TEMPLATE_ITEM_URL, { params }),
  },
};

export default PostOpApi;
