/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Back from "../widgets/Back";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";

import bindActions from "../../helpers/bindActions";
import * as blockedTimeSlotActions from "../../actions/blockedTimeSlotActions";
import * as loaderActions from "../../actions/loaderActions";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { defaultColors } from "../../constants/defaults";

const enhancer = connect(
  ({ appointment: { blockedTimeSlotType } }) => ({
    blockedTimeSlotType,
  }),
  bindActions({ blockedTimeSlotActions, loaderActions }),
);

class BlockedTimeSlotColors extends React.Component {
  componentDidMount() {
    const btsTypes = JSON.parse(localStorage.getItem("blockedTimeSlotType"));
    this.props.loaderActions.startLoader();

    this.props.blockedTimeSlotActions
      .getBtsTypesColorApi()
      .then((response) => {
        response.forEach((item) => {
          if (!btsTypes[item.code]) {
            btsTypes[item.code] = {
              code: item.code,
              name: item.name,
              color: defaultColors.Silver,
            };
          }
        });

        localStorage.setItem("blockedTimeSlotType", JSON.stringify(btsTypes));
        this.props.blockedTimeSlotActions.setBlockedTimeSlotTypeColors(btsTypes);
        this.props.loaderActions.stopLoader();

        this.forceUpdate();
      })
      .catch((error) => this.props.loaderActions.stopLoader(error));
  }

  render() {
    let $colors = Utils.objectToArray(Colors.getBTStypeColors(), (item) => {
      return (
        <Link
          key={item.code}
          className="list-group-item"
          to={createUrl(Routes.SettingsBlockedTimeSlotColor, {
            params: { colorCode: item.code },
          })}
        >
          <div className="pull-right">❯</div>
          <div className="color" style={{ backgroundColor: item.color.color }} />
          {item.name}
        </Link>
      );
    });

    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Blocked Time Slot colors</div>
        </div>
        <div className="body">
          <div className="list-group">{$colors}</div>
        </div>
      </div>
    );
  }
}
export default enhancer(BlockedTimeSlotColors);
