import _ from "lodash";
import React from "react";
import moment from "moment";
import { withRouter } from "react-router";

import { TIME_FORMAT } from "../../../constants/Constants";

import Utils from "../../../helpers/Utils";
import Colors from "../../../helpers/Colors";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";

class PatientSelectAppointmentSidebarListItem extends React.Component {
  onChartVisit = (appointmentId, patientKey) => {
    this.props.history.push(
      createUrl(Routes.DashboardChart, {
        params: {
          type: "Visit",
        },
        query: {
          patientKey,
          appointmentId: appointmentId,
          isDentistOwner: true,
        },
      }),
    );
    this.props.hideSideBar();
  };

  render() {
    const { item } = this.props;

    const startTime = +_.get(item, "startTime", moment().valueOf());
    const duration = +_.get(item, "duration", 0);
    const statusName = _.get(item, "status.name", "");
    const statusCode = _.get(item, "status.code", "");
    const statusColor = _.get(Colors.getStatusColor(statusCode), "color.color", "#fff");
    const categoryName = _.get(item, "category.name", "");
    const patientChartNumber = _.get(item, "patient.chartNumber", "");
    const appointmentId = _.get(item, "id", 0);
    const patientKey = _.get(item, "patient.patientKey", "");

    const patientName = Utils.getFullName(item && item.patient);
    const patientFullName = patientName + " " + patientChartNumber;
    const visitTime =
      moment(startTime).format(TIME_FORMAT) +
      " - " +
      moment(startTime + duration).format(TIME_FORMAT);

    return (
      <div
        className="patient-select-appointment-sidebar-list-item"
        onClick={() => {
          this.onChartVisit(appointmentId, patientKey);
        }}
      >
        <div className="list-item-info">
          <span className="item-info-full-name">{patientFullName}</span>
          <span className="item-info-type">{categoryName}</span>
        </div>
        <div className="list-item-status" style={{ backgroundColor: statusColor }}>
          <span className="item-status-time">{visitTime}</span>
          <span className="item-status-text">{statusName}</span>
        </div>
      </div>
    );
  }
}
export default withRouter(PatientSelectAppointmentSidebarListItem);
