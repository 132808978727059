import React from "react";

import Back from "../widgets/Back";

import { ColorTypes } from "../../constants/Constants";

export default class AppointmentColorType extends React.Component {
  setView = (type) => {
    localStorage.setItem("appointmentColorType", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment color type</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView(ColorTypes.type)} className="list-group-item">
              {Boolean(localStorage.getItem("appointmentColorType") === ColorTypes.type) && (
                <div className="pull-right">&#10004;</div>
              )}
              Appointment Type
            </span>
            <span onClick={() => this.setView(ColorTypes.doctor)} className="list-group-item">
              {Boolean(localStorage.getItem("appointmentColorType") === ColorTypes.doctor) && (
                <div className="pull-right">&#10004;</div>
              )}
              Appointment {localStorage.getItem("doctorLabel")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
