import React from "react";

import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";
import RemindersMainButton from "./RemindersMainButton";
import RemindersToggleDentistAll from "./RemindersToggleDentistAll";
import RemindersToggleDentistSelected from "./RemindersToggleDentistSelected";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersToggleDentist extends React.Component {
  render() {
    const {
      toggleDentistVisible,
      toggleDentistMode,
      toggleDentistSelected,
      fetchingDentistList,
      searchDentist,
      dentistList,
      searchDentistSelected,
    } = this.props;

    return (
      <Modal
        className="reminders-choose-dentist-modal"
        show={toggleDentistVisible}
        onHide={this.props.remindersActions.toggleDentistHide}
        title={<div>Choose {localStorage.getItem("doctorLabel")}</div>}
        actions={
          <Button className="btn" onClick={this.props.remindersActions.toggleDentistHide}>
            Close
          </Button>
        }
      >
        <div className="btn-group btn-group-justified">
          <RemindersMainButton
            onClick={() => {
              this.props.remindersActions.setDentistMode("all");
            }}
            text="All"
            active={toggleDentistMode === "all"}
          />
          <RemindersMainButton
            onClick={() => {
              this.props.remindersActions.setDentistMode("selected");
            }}
            text="Selected"
            active={toggleDentistMode === "selected"}
          />
        </div>
        <Show if={toggleDentistMode === "all"}>
          <RemindersToggleDentistAll
            toggleDentistSelected={toggleDentistSelected}
            searchDentist={searchDentist}
            dentistList={dentistList}
            fetchingDentistList={fetchingDentistList}
          />
        </Show>
        <Show if={toggleDentistMode === "selected"}>
          <RemindersToggleDentistSelected
            searchDentist={searchDentist}
            toggleDentistSelected={toggleDentistSelected}
            fetchingDentistList={fetchingDentistList}
            searchDentistSelected={searchDentistSelected}
          />
        </Show>
      </Modal>
    );
  }
}
export default enhancer(RemindersToggleDentist);
