import _ from "lodash";
import moment from "moment";
import { showMessage, startLoader, stopLoader } from "./loaderActions";

import BlockedTimeSlotApi from "../api-legacy/BlockedTimeSlotApi";
import MemberApi from "../api-legacy/MemberApi";

import * as ActionType from "../actionTypes/blockedTimeSlotActionTypes";
import Utils from "../helpers/Utils";
import AppointmentApi from "../api-legacy/AppointmentApi";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import { getCalendarData, updateCalendar } from "./calendarActions";

export function getMemberKeywords() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getMemberKeywordsApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getFormData() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getFormDataApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setUntilDate(date) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_UNTIL_DATE,
    date,
  };
}

export function removeUntilDate() {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_REMOVE_UNTIL_DATE,
  };
}

export function addExcludedDate(date) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_ADD_EXCLUDED_DATE,
    date,
  };
}

export function removeExcludedDate(index) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_REMOVE_EXCLUDED_DATE,
    index,
  };
}

export function createBlockedTimeSlotFromTemplate(data) {
  return (dispatch) => {
    dispatch(stopLoader());

    return dispatch(blockedTimeSlotAddItemApi(data))
      .then((response) => {
        dispatch(stopLoader());
        dispatch(updateCalendar());
        return response;
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function getBlockedTemplatesList() {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 25,
    };

    return dispatch(blockedTimeSlotTemplatesListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function getBlockedTemplatesItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(blockedTimeSlotTemplatesItemApi({ id }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadBlockedTemplatesList() {
  return (dispatch, getState) => {
    const {
      blockedTimeSlot: { templateList, templateListFetching, templateListTotalCount },
    } = getState();

    if (templateList.length === templateListTotalCount || templateListFetching) {
      return;
    }

    const data = {
      start: templateList.length,
      limit: 25,
    };

    return dispatch(blockedTimeSlotTemplatesListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function setCustomPattern(pattern) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_CUSTOM_PATTERN,
    pattern,
  };
}

export function blockedTimeSlotConfirmModalShow(deleteId) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_CONFIRM_MODAL_SHOW,
    deleteId,
  };
}

export function resetDoctors() {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_RESET_DOCTORS,
  };
}

export function blockedTimeSlotConfirmModalHide() {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_CONFIRM_MODAL_HIDE,
  };
}

export function blockedTimeSlotSetDoctorsEdit(doctors) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_DOCTORS_EDIT,
    doctors,
  };
}

export function getDentalPoint() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getDentalPointApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function blockedTimeSlotAddItem() {
  return (dispatch, getState) => {
    const {
      form: {
        blockedTimeSlot: { values },
      },
      blockedTimeSlot: {
        blockedFromTime,
        blockedToTime,
        blockedFromDate,
        blockedToDate,
        blockedMode,
        blockedEditId,
        customPattern,
        untilDate: uDate,
        excludedDates: eDates,
      },
    } = getState();

    const fromDate = getCompanyStartOfDay(blockedFromDate);
    const toDate = getCompanyEndOfDay(blockedToDate);
    const excludedDates = eDates ? eDates.map((x) => getCompanyStartOfDay(x)).filter(Boolean) : [];

    const fromTime = moment.isMoment(blockedFromTime)
      ? blockedFromTime.valueOf() - blockedFromTime.startOf("day").valueOf()
      : blockedFromTime;
    const toTime = moment.isMoment(blockedToTime)
      ? blockedToTime.valueOf() - blockedToTime.startOf("day").valueOf()
      : blockedToTime;

    if (!values || !values.fieldName || values.fieldName === "") {
      return dispatch(showMessage("Name is required"));
    }
    if (!values || !values.fieldReason || values.fieldReason === "") {
      return dispatch(showMessage("Reason is required"));
    }

    const doctors = (values.members || []).map((x) => x.value);

    dispatch(startLoader());

    const weekDays = values.fieldWeekDays || [];

    let data = {};

    if (
      values.recurrenceType === "DAILY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        repeatCount: values.repeatCount,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),
      };
    }

    if (
      values.recurrenceType === "DAILY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        repeatCount: values.repeatCount,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),
      };
    }

    if (
      values.recurrenceType === "WEEKLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        weekDays: weekDays.map((code) => ({ code })),
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),
      };
    }

    if (
      values.recurrenceType === "WEEKLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        weekDays: weekDays.map((code) => ({ code })),
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),
      };
    }

    if (
      !customPattern &&
      values.recurrenceType === "MONTHLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: false,
        repeatCount: values.repeatCount,
        monthDayNumber: values.monthDayNumber,
      };
    }

    if (
      !customPattern &&
      values.recurrenceType === "MONTHLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: false,
        repeatCount: values.repeatCount,
        monthDayNumber: values.monthDayNumber,
      };
    }

    if (
      customPattern &&
      values.recurrenceType === "MONTHLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: true,
        repeatCount: values.repeatCount,
        yearWeekDay:
          values.yearWeekDay && values.yearWeekDay !== "-- --"
            ? {
                code: values.yearWeekDay,
              }
            : undefined,
        yearWeekNumber:
          values.yearWeekNumber && values.yearWeekNumber !== "-- --"
            ? {
                code: values.yearWeekNumber,
              }
            : undefined,
      };
    }

    if (
      customPattern &&
      values.recurrenceType === "MONTHLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: true,
        repeatCount: values.repeatCount,
        yearWeekDay:
          values.yearWeekDay && values.yearWeekDay !== "-- --"
            ? {
                code: values.yearWeekDay,
              }
            : undefined,
        yearWeekNumber:
          values.yearWeekNumber && values.yearWeekNumber !== "-- --"
            ? {
                code: values.yearWeekNumber,
              }
            : undefined,
      };
    }

    if (
      !customPattern &&
      values.recurrenceType === "YEARLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: false,
        repeatCount: values.repeatCount,
        yearMonth:
          values.yearMonth && values.yearMonth !== "-- --"
            ? {
                code: values.yearMonth,
              }
            : undefined,
      };
    }

    if (
      !customPattern &&
      values.recurrenceType === "YEARLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: false,
        repeatCount: values.repeatCount,
        yearMonth:
          values.yearMonth && values.yearMonth !== "-- --"
            ? {
                code: values.yearMonth,
              }
            : undefined,
      };
    }

    if (
      customPattern &&
      values.recurrenceType === "YEARLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_EndDate"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        endDate: toDate,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: true,
        repeatCount: values.repeatCount,
        yearMonth:
          values.yearMonth && values.yearMonth !== "-- --"
            ? {
                code: values.yearMonth,
              }
            : undefined,
        yearWeekDay:
          values.yearWeekDay && values.yearWeekDay !== "-- --"
            ? {
                code: values.yearWeekDay,
              }
            : undefined,
        yearWeekNumber:
          values.yearWeekNumber && values.yearWeekNumber !== "-- --"
            ? {
                code: values.yearWeekNumber,
              }
            : undefined,
      };
    }

    if (
      customPattern &&
      values.recurrenceType === "YEARLY" &&
      values.recurrenceEndType === "RecurrenceEnd_By_CountLimit"
    ) {
      data = {
        name: values.fieldName,
        members: doctors,
        reason: values.fieldReason,
        clinicId: values.clinicId,
        startDate: fromDate,
        startTime: fromTime,
        endTime: toTime,
        occurrences: values.occurrences,
        reserved: values.fieldReserved,
        recurrenceType: {
          code: values.recurrenceType,
        },
        recurrenceEndType: {
          code: values.recurrenceEndType,
        },
        points: (values.points || []).map((id) => ({ id })),

        customPattern: true,
        repeatCount: values.repeatCount,
        yearMonth:
          values.yearMonth && values.yearMonth !== "-- --"
            ? {
                code: values.yearMonth,
              }
            : undefined,
        yearWeekDay:
          values.yearWeekDay && values.yearWeekDay !== "-- --"
            ? {
                code: values.yearWeekDay,
              }
            : undefined,
        yearWeekNumber:
          values.yearWeekNumber && values.yearWeekNumber !== "-- --"
            ? {
                code: values.yearWeekNumber,
              }
            : undefined,
      };
    }

    if (uDate) {
      data.untilDate = uDate;
    }

    if (!_.isEmpty(excludedDates)) {
      data.excludedDates = excludedDates;
    }

    if (blockedMode === "edit") {
      return dispatch(blockedTimeSlotEditItemApi({ ...data, id: _.toFinite(blockedEditId) }))
        .then((response) => {
          dispatch(stopLoader());
          return response;
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    }
    return dispatch(blockedTimeSlotAddItemApi(data))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function blockedTimeSlotDeleteItem(transfer) {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
      blockedTimeSlot: { blockedDeleteId },
    } = getState();

    if (!transfer) {
      dispatch(blockedTimeSlotConfirmModalHide());
    }

    dispatch(startLoader());

    const data = {
      id: blockedDeleteId,
    };

    return dispatch(blockedTimeSlotDeleteItemApi(!transfer ? data : transfer))
      .then(() => {
        if (transfer) {
          dispatch(getCalendarData(false, clinicId));
        } else {
          dispatch(blockedTimeSlotGetList());
        }

        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function blockedTimeSlotSetMode(mode) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_MODE,
    mode,
  };
}

export function blockedTimeSlotChooseDoctor(id) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_CHOOSE_DOCTOR,
    id,
  };
}

export function blockedTimeSlotChooseKeyword(id) {
  return {
    type: ActionType.MEMBERS_KEYWORDS_SELECT,
    id,
  };
}

export function blockedTimeSlotSetFromDate(date) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_FROM_DATE,
    date,
  };
}

export function blockedTimeSlotSetToDate(date) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_TO_DATE,
    date,
  };
}

export function blockedTimeSlotSetFilterKey(e) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_FILTER_KEY,
    filterKey: e.target.value,
  };
}

export function blockedTimeSlotSetFromTime(time) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_FROM_TIME,
    time,
  };
}

export function blockedTimeSlotSetToTime(time) {
  return {
    type: ActionType.BLOCKED_TIME_SLOT_SET_TO_TIME,
    time,
  };
}

export function blockedTimeSlotLoadList() {
  return (dispatch, getState) => {
    const {
      blockedTimeSlot: {
        blockedListFetching,
        blockedListTotalCount,
        blockedList,
        blockedFilterKey,
      },
      form: { BlockedTimeSlotFilterForm },
    } = getState();

    if (blockedList.length === blockedListTotalCount || blockedListFetching) {
      return;
    }

    const values = BlockedTimeSlotFilterForm && BlockedTimeSlotFilterForm.values;

    const data = {
      start: blockedList.length,
      limit: 25,
      filterKey: blockedFilterKey,
      startDate: Utils.toCompanyTimezone((values && values.startDate) || moment()),
      endDate:
        Utils.toCompanyTimezone((values && values.endDate) || moment()) +
        24 * 60 * 60 * 1000 -
        1000,
    };

    return dispatch(blockedTimeSlotGetListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function blockedTimeSlotClearFilterKey() {
  return (dispatch) => {
    dispatch({ type: ActionType.BLOCKED_TIME_SLOT_CLEAR_FILTER_KEY });
    dispatch(blockedTimeSlotGetList());
  };
}

export function blockedTimeSlotGetList(values) {
  return (dispatch, getState) => {
    const {
      blockedTimeSlot: { blockedFilterKey },
    } = getState();

    const data = {
      start: 0,
      limit: 25,
      filterKey: blockedFilterKey,
      startDate: getCompanyStartOfDay(values && values.startDate),
      endDate: getCompanyEndOfDay(values && values.endDate),
      doctorIds: values && (values.doctorIds || []).map((item) => item.value).join(","),
    };
    return dispatch(blockedTimeSlotGetListApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function blockedTimeSlotSetEditIndex(index) {
  return (dispatch) => {
    dispatch({
      type: ActionType.BLOCKED_TIME_SLOT_SET_EDIT_INDEX,
      index,
    });

    dispatch(blockedTimeSlotSetMode("edit"));
  };
}

export function initBlockedTimeSlot() {
  return (dispatch) => {
    dispatch(startLoader());
    dispatch(resetDoctors());
    dispatch({ type: ActionType.BLOCKED_TIME_SLOT_INIT });

    Promise.all([dispatch(blockedTimeSlotGetDentistApi())])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDoctors(clinicId) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(blockedTimeSlotGetDentistApi({ clinicId }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initBlockedTimeSlotItem(id) {
  return (dispatch) => {
    dispatch(startLoader());
    dispatch(resetDoctors());
    dispatch({ type: ActionType.BLOCKED_TIME_SLOT_ITEM_INIT, id });

    Promise.all([
      dispatch(blockedTimeSlotGetDentistApi()),
      // dispatch(getDentalPoint()),
      dispatch(getWeekDays()),
    ])
      .then(() => {
        if (+id) {
          return dispatch({
            api: BlockedTimeSlotApi.getItem,
            types: [
              ActionType.BLOCKED_TIME_SLOT_GET_ITEM_START,
              ActionType.BLOCKED_TIME_SLOT_GET_ITEM_SUCCESS,
              ActionType.BLOCKED_TIME_SLOT_GET_ITEM_ERROR,
            ],
            data: { id },
          });
        }
      })
      .then(() => {
        if (+id) {
          dispatch(blockedTimeSlotSetEditIndex(id));
        } else {
          dispatch(blockedTimeSlotSetMode("new"));
        }
      })
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function setBlockedTimeSlotTypeColors(colors) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_BLOCKED_TIME_SLOT_COLORS,
      colors,
    });
  };
}

function blockedTimeSlotAddItemApi(data) {
  return {
    api: BlockedTimeSlotApi.addItem,
    types: [
      ActionType.BLOCKED_TIME_SLOT_ADD_ITEM_START,
      ActionType.BLOCKED_TIME_SLOT_ADD_ITEM_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_ADD_ITEM_ERROR,
    ],
    data,
  };
}

function getWeekDays(data) {
  return {
    api: MemberApi.getDentistWithPoints,
    types: [
      ActionType.BLOCKED_TIME_SLOT_GET_WEEK_DAYS_START,
      ActionType.BLOCKED_TIME_SLOT_GET_WEEK_DAYS_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_GET_WEEK_DAYS_ERROR,
    ],
    data,
  };
}

function blockedTimeSlotEditItemApi(data) {
  return {
    api: BlockedTimeSlotApi.editItem,
    types: [
      ActionType.BLOCKED_TIME_SLOT_EDIT_ITEM_START,
      ActionType.BLOCKED_TIME_SLOT_EDIT_ITEM_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_EDIT_ITEM_ERROR,
    ],
    data,
  };
}

function blockedTimeSlotDeleteItemApi(data) {
  return {
    api: BlockedTimeSlotApi.deleteItem,
    types: [
      ActionType.BLOCKED_TIME_SLOT_DELETE_ITEM_START,
      ActionType.BLOCKED_TIME_SLOT_DELETE_ITEM_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_DELETE_ITEM_ERROR,
    ],
    data,
  };
}

export function blockedTimeSlotGetDentistApi(data) {
  return {
    api: MemberApi.getDentistWithPoints,
    types: [
      ActionType.BLOCKED_TIME_SLOT_GET_DENTIST_START,
      ActionType.BLOCKED_TIME_SLOT_GET_DENTIST_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_GET_DENTIST_ERROR,
    ],
    data,
  };
}

function blockedTimeSlotTemplatesListApi(data) {
  return {
    api: BlockedTimeSlotApi.getTemplateList,
    types: [
      ActionType.GET_BLOCKED_TEMPLATES_LIST_START,
      ActionType.GET_BLOCKED_TEMPLATES_LIST_SUCCESS,
      ActionType.GET_BLOCKED_TEMPLATES_LIST_ERROR,
    ],
    data,
  };
}

function blockedTimeSlotTemplatesItemApi(data) {
  return {
    api: BlockedTimeSlotApi.getTemplateList,
    types: [
      ActionType.GET_BLOCKED_TEMPLATES_ITEM_START,
      ActionType.GET_BLOCKED_TEMPLATES_ITEM_SUCCESS,
      ActionType.GET_BLOCKED_TEMPLATES_ITEM_ERROR,
    ],
    data,
  };
}

function blockedTimeSlotGetListApi(data) {
  return {
    api: BlockedTimeSlotApi.getList,
    types: [
      ActionType.BLOCKED_TIME_SLOT_GET_LIST_START,
      ActionType.BLOCKED_TIME_SLOT_GET_LIST_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_GET_LIST_ERROR,
    ],
    data,
  };
}

function getDentalPointApi(data) {
  return {
    api: AppointmentApi.getDentalPoint,
    types: [
      ActionType.BLOCKED_TIME_SLOT_GET_DENTAL_POINT_START,
      ActionType.BLOCKED_TIME_SLOT_GET_DENTAL_POINT_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_GET_DENTAL_POINT_ERROR,
    ],
    data,
  };
}

function getFormDataApi(data) {
  return {
    api: BlockedTimeSlotApi.getFormData,
    types: [
      ActionType.BLOCKED_TIME_SLOT_FORM_DATA_START,
      ActionType.BLOCKED_TIME_SLOT_FORM_DATA_SUCCESS,
      ActionType.BLOCKED_TIME_SLOT_FORM_DATA_ERROR,
    ],
    data,
  };
}

function getMemberKeywordsApi(data) {
  return {
    api: MemberApi.getKeywords,
    types: [
      ActionType.GET_MEMBERS_KEYWORDS_START,
      ActionType.GET_MEMBERS_KEYWORDS_SUCCESS,
      ActionType.GET_MEMBERS_KEYWORDS_ERROR,
    ],
    data,
  };
}

export function getBtsTypesColorApi(data) {
  return {
    api: BlockedTimeSlotApi.getBtsTypeColors,
    types: [
      ActionType.GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_START,
      ActionType.GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_SUCCESS,
      ActionType.GET_BLOCKED_TIME_SLOT_TYPES_COLOR_LIST_ERROR,
    ],
    data,
  };
}
