import _ from "lodash";
import React from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";

import * as medicationActions from "../../actions/medicationActions";
import { FormCheck } from "react-bootstrap";
import DeleteImage from "../../assets/images/appointment/delete_2x.png";

const enhancer = reduxForm();
class MedicationItemForm extends React.Component {
  onFirstCellClick = (id, memberId) => {
    if (id) {
      if (this.props.medicalForms) {
        if (this.props.todayList) {
          this.props.dispatch(medicationActions.checkTodayItem(id, memberId));
        } else {
          this.props.dispatch(medicationActions.checkOldItem(id, memberId));
        }
        return;
      }
      this.props.dispatch(medicationActions.checkItem(id, memberId));
    } else if (this.props.lastNewIndex === this.props.index) {
      this.props.dispatch(medicationActions.popNewItem());
    }
  };

  render() {
    const {
      item,
      lastNewIndex,
      index,
      medication: { formData, checkedMap, checkedMapToday, checkedMapOld },
      memberId,
      initialValues,
    } = this.props;

    const $route = (formData.routList || []).map((item, index) => (
      <option key={index} value={item.id}>
        {item.name}
      </option>
    ));

    const $frequency = (formData.routFrequencyList || []).map((item, index) => (
      <option key={index} value={item.id}>
        {item.name}
      </option>
    ));

    const checked =
      this.props.medicalForms && this.props.todayList
        ? checkedMapToday
        : this.props.medicalForms && !this.props.todayList
        ? checkedMapOld
        : checkedMap;

    return (
      <div className="default-table-row">
        <div
          className="default-table-col col-0 d-flex align-items-center"
          onClick={() => {
            if (item.id) {
              this.onFirstCellClick(item.id, memberId);
            }
          }}
        >
          {!!item.id && <FormCheck checked={checked[item.id]} readOnly={true} />}
          {lastNewIndex === index && (
            <img
              src={DeleteImage}
              alt={""}
              width={20}
              height={20}
              className="icon-btn icon-delete icon-small"
            />
          )}
        </div>
        <div
          className="default-table-col col-1 d-flex align-items-center"
          title={
            item.medication && item.medication.name ? item.medication.name : item.medicationName
          }
        >
          <span>
            {item.medication && item.medication.name ? item.medication.name : item.medicationName}
          </span>
        </div>
        <div
          className="default-table-col col-1 d-flex align-items-center"
          title={item?.genericName || initialValues?.genericName}
        >
          <span>{item?.genericName || initialValues?.genericName}</span>
        </div>
        <div className="default-table-col col-9 col-amount d-flex align-items-center">
          <Field
            component="input"
            type="text"
            name="amount"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-3 col-strength d-flex align-items-center">
          <Field
            component="input"
            type="text"
            name="strength"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-3 d-flex align-items-center">
          <Field
            component="input"
            type="text"
            name="dosageForm"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-4 d-flex align-items-center">
          <Field className="form-control" name="route" disabled={item.voided} component="select">
            <option />
            {$route}
          </Field>
        </div>
        <div className="default-table-col col-5 d-flex align-items-center">
          <Field
            className="form-control"
            name="frequency"
            disabled={item.voided}
            component="select"
          >
            <option />
            {$frequency}
          </Field>
        </div>
        <div className="default-table-col col-9 d-flex align-items-center">
          <Field
            component="input"
            type="text"
            name="dispense"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-9 input-number-arrows-none d-flex align-items-center">
          <Field
            component="input"
            type="number"
            name="refills"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-3 d-flex align-items-center">
          <Field
            component="textarea"
            name="instructions"
            disabled={item.voided}
            className="form-control"
          />
        </div>
        <div className="default-table-col col-10 d-flex align-items-center">
          {item.id && (
            <button
              className="btn btn-link"
              onClick={() => this.props.dispatch(medicationActions.openHistoryModal(item.id))}
            >
              Log
            </button>
          )}
        </div>
        <div className="default-table-col col-4 d-flex align-items-center">
          {_.get(item, "doctor.name", "")}
        </div>
        <div className="default-table-col col-11 d-flex align-items-center">
          {moment(item.createdDate).format("MMM DD, YYYY")}
        </div>
      </div>
    );
  }
}
export default enhancer(MedicationItemForm);
