import React from "react";
import moment from "moment/moment";
import Back from "../../../widgets/Back";
import { DownloadImageButton } from "./DownloadImageButton";
import { Modal } from "../../../ui/Modal";

export class DocsViewModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { list = [] } = props;

    const activeIndex = list.findIndex((x) => x.id === props.imageId);

    this.state = { activeIndex, total: list.length };
  }

  nextImage = () => {
    const { total, activeIndex } = this.state;

    if (total - 1 > activeIndex) {
      this.setState({ activeIndex: activeIndex + 1 });
    }
  };

  prevImage = () => {
    const { activeIndex } = this.state;

    if (activeIndex > 0) {
      this.setState({ activeIndex: activeIndex - 1 });
    }
  };

  render() {
    const { list = [], show } = this.props;
    const { total, activeIndex } = this.state;

    const item = list[activeIndex];

    return (
      <Modal show={show} onHide={this.props.onRequestClose} fullScreen={true}>
        <div className="d-flex flex-column docs-view-component" style={{ zIndex: 100000000 }}>
          <div className="docs-view-header">
            <Back className="back-button" onClick={this.props.onRequestClose} />

            <DownloadImageButton
              fill="#ffffff"
              image={item.url}
              imageName={item.name || ""}
              className="mr-3"
              loaderColor="#ffffff"
            />
          </div>
          <div className="d-flex flex-column flex-shrink-1 flex-grow-1 docs-view-container">
            {item.url.indexOf("pdf?") >= 0 ? (
              <embed className="docs-view-embed" src={item.url} />
            ) : (
              <div className="docs-view-image" style={{ backgroundImage: `url(${item.url})` }} />
            )}

            <div className="d-flex align-items-center justify-content-center text-white docs-view-footer">
              <span>
                {item.description}
                &nbsp; Upload date: &nbsp;
                {moment(item.createdDate).format("DD/MM/YYYY")}
              </span>
            </div>

            {activeIndex > 0 && (
              <div
                role="button"
                onClick={() => this.prevImage()}
                className="d-flex align-items-center justify-content-center docs-view-button left-button"
              >
                <span>{"<"}</span>
              </div>
            )}

            {total - 1 > activeIndex && (
              <div
                role="button"
                onClick={() => this.nextImage()}
                className="d-flex align-items-center justify-content-center docs-view-button right-button"
              >
                <span>{">"}</span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
