const viewTypes = {
  series: "series",
  clinicalNote: "clinicalNote",
  prescription: "prescription",
  transaction: "transaction",
  treatmentsPlan: "treatmentsPlan",
  docsView: "docsView",
  sticky: "sticky",
  goals: "goals",
  fillInForm: "fillInForm",
  medicalFillInForm: "medicalFillInForm",
  vital: "vital",
  postOp: "postOp",
  referralNextVisit: "referralNextVisit",
  order: "order",
  patientTags: "patientTags",
};

export default viewTypes;
