import MainApi from "../api-legacy/MainApi";
import MemberApi from "../api-legacy/MemberApi";
import ClinicApi from "../api-legacy/ClinicApi";

import { startLoader, stopLoader, showMessage } from "./loaderActions";
import { getSession } from "./sessionActions";
import { TreatmentGrouppingTypes } from "../constants/Constants";

export const GET_DEVICE_INFO_START = "GET_DEVICE_INFO_START";
export const GET_DEVICE_INFO_SUCCESS = "GET_DEVICE_INFO_SUCCESS";
export const GET_DEVICE_INFO_ERROR = "GET_DEVICE_INFO_ERROR";

export const GET_TOKEN_START = "GET_TOKEN_START";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const APP_RESET = "APP_RESET";

export const SET_TOKEN = "SET_TOKEN";

export const GET_MEMBERS_START = "GET_MEMBERS_START";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_ERROR = "GET_MEMBERS_ERROR";

export const GET_CLINICS_START = "GET_CLINICS_START";
export const GET_CLINICS_SUCCESS = "GET_CLINICS_SUCCESS";
export const GET_CLINICS_ERROR = "GET_CLINICS_ERROR";

export const SET_DEVICE_CLINIC_START = "SET_DEVICE_CLINIC_START";
export const SET_DEVICE_CLINIC_SUCCESS = "SET_DEVICE_CLINIC_SUCCESS";
export const SET_DEVICE_CLINIC_ERROR = "SET_DEVICE_CLINIC_ERROR";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const INIT_APP_START = "INIT_APP_START";
export const INIT_APP_SUCCESS = "INIT_APP_SUCCESS";
export const INIT_APP_ERROR = "INIT_APP_ERROR";

export const CHOOSE_MEMBER = "CHOOSE_MEMBER";
export const LOGGED_IN = "LOGGED_IN";
export const ADMIN_LOGGED_IN = "ADMIN_LOGGED_IN";

export const AUTH_DOCTOR_RESET = "AUTH_DOCTOR_RESET";
export const AUTH_DOCTOR_SELECT = "AUTH_DOCTOR_SELECT";

export const AUTH_DELETE_DEVICE_START = "AUTH_DELETE_DEVICE_START";
export const AUTH_DELETE_DEVICE_SUCCESS = "AUTH_DELETE_DEVICE_SUCCESS";
export const AUTH_DELETE_DEVICE_ERROR = "AUTH_DELETE_DEVICE_ERROR";

export const AUTH_DELETE_DEVICE_CONFIRM_MODAL_SHOW = "AUTH_DELETE_DEVICE_CONFIRM_MODAL_SHOW";
export const AUTH_DELETE_DEVICE_CONFIRM_MODAL_HIDE = "AUTH_DELETE_DEVICE_CONFIRM_MODAL_HIDE";

export const GET_USER_INFO = "GET_USER_INFO";
export const SET_CHOSEN_CLINIC = "SET_CHOSEN_CLINIC";

export function resetDoctors() {
  return {
    type: AUTH_DOCTOR_RESET,
  };
}

export function selectDoctor(id) {
  return {
    type: AUTH_DOCTOR_SELECT,
    id,
  };
}

export function deleteDeviceConfirmModalShow() {
  return {
    type: AUTH_DELETE_DEVICE_CONFIRM_MODAL_SHOW,
  };
}

export function updateToken(token) {
  return {
    type: UPDATE_TOKEN,
    token,
  };
}

export function appReset() {
  return {
    type: APP_RESET,
  };
}

export function clearToken() {
  return {
    type: CLEAR_TOKEN,
  };
}

export function deleteDeviceConfirmModalHide() {
  return {
    type: AUTH_DELETE_DEVICE_CONFIRM_MODAL_HIDE,
  };
}

export function deleteDevice() {
  return (dispatch, getState) => {
    const {
      token: { access_token },
    } = getState().auth;

    dispatch(deleteDeviceConfirmModalHide());
    dispatch(startLoader());

    return dispatch(deleteDeviceApi({ token: access_token }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(initApp());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
        dispatch(initApp());
      });
  };
}

function deleteDeviceApi(data) {
  return {
    api: ClinicApi.deleteDevice,
    types: [AUTH_DELETE_DEVICE_START, AUTH_DELETE_DEVICE_SUCCESS, AUTH_DELETE_DEVICE_ERROR],
    data,
  };
}

export function initApp() {
  return function (dispatch, getState) {
    dispatch({ type: INIT_APP_START });
    dispatch(startLoader());

    return dispatch(getDeviceInfoApi())
      .then(() => {
        const { loggedIn } = getState().auth;

        // if (IS_DEV) {
        //   if (loggedIn) {
        //     return Promise.all([
        //       dispatch(getSession()),
        //       dispatch(getMembers()),
        //     ]);
        //   }
        // }
        if (loggedIn) {
          return Promise.all([dispatch(getSession()), dispatch(getMembers())]);
        }
      })
      .then(() => {
        dispatch(stopLoader());
        dispatch({ type: INIT_APP_SUCCESS });
      })
      .catch((error) => {
        dispatch(stopLoader(error));
        dispatch({ type: INIT_APP_ERROR });
      });
  };
}

export function initMemberLogin() {
  return function (dispatch) {
    dispatch({
      type: "initMemberLogin",
    });

    dispatch(startLoader());

    return dispatch(getTokenApi({ work_mode: "patient_kiosk" }))
      .then(() => Promise.all([dispatch(getSession()), dispatch(getMembers())]))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function login(password) {
  return function (dispatch, getState) {
    dispatch({
      type: "login",
    });

    const { members, chosenMemberId } = getState().auth;
    if (!chosenMemberId) return dispatch(showMessage("Choose Member"));
    dispatch(startLoader());

    const transfer = {
      work_mode: "default",
      username: members[chosenMemberId].email,
      password,
    };

    return dispatch(getTokenApi(transfer))
      .then((response) => {
        dispatch(getSession()).then((respons) => {
          localStorage.setItem(
            "treatmentGroupping",
            respons.treatmentGroupingType?.code || TreatmentGrouppingTypes.none,
          );
        });
        dispatch(stopLoader());
        dispatch(loggedIn(true));

        return response;
      })
      .catch((error) => {
        dispatch(
          stopLoader(
            error == "Invalid Password or Username\\n" ? "rrrr" : error || "Unknown error",
          ),
        );
      });
  };
}

export function initChooseClinic() {
  return function (dispatch) {
    dispatch({
      type: "initChooseClinic",
    });

    dispatch(startLoader());

    return dispatch(getClinics())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function chooseClinic(data) {
  return function (dispatch) {
    dispatch({
      type: "chooseClinic",
    });

    dispatch(startLoader());

    return dispatch(setDeviceClinic(data))
      .then(() => dispatch(getDeviceInfoApi()))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function adminLogin(data) {
  return function (dispatch) {
    dispatch({
      type: "adminLogin",
    });
    dispatch(startLoader());
    return dispatch(getTokenApi(data))
      .then(() => dispatch(getDeviceInfoApi()))
      .then(() => Promise.all([dispatch(getSession()), dispatch(adminLoggedIn())]))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function forgotPassword() {
  return function (dispatch, getState) {
    dispatch({
      type: "resetPassword",
    });

    const {
      auth: { members, chosenMemberId },
      session: { company },
    } = getState();

    if (!chosenMemberId) return dispatch(showMessage("Choose Member"));
    dispatch(startLoader());

    const transfer = {
      id: members[chosenMemberId].id,
      companyId: company && company.id,
    };

    return dispatch(resetPassword(transfer))
      .then(() => {
        dispatch(stopLoader("Email sent"));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function chooseMember(id) {
  return {
    type: CHOOSE_MEMBER,
    chosenMemberId: id,
  };
}

export function loggedIn(boolean) {
  return {
    type: LOGGED_IN,
    loggedIn: boolean,
  };
}

function adminLoggedIn() {
  return {
    type: ADMIN_LOGGED_IN,
  };
}

export function getTokenApi(data) {
  return {
    api: MainApi.getToken,
    types: [GET_TOKEN_START, GET_TOKEN_SUCCESS, GET_TOKEN_ERROR],
    data,
  };
}

export function getMembers(data) {
  return {
    api: MemberApi.getChoose,
    types: [GET_MEMBERS_START, GET_MEMBERS_SUCCESS, GET_MEMBERS_ERROR],
    data,
  };
}

function getDeviceInfoApi(data) {
  return {
    api: MainApi.getDeviceInfo,
    types: [GET_DEVICE_INFO_START, GET_DEVICE_INFO_SUCCESS, GET_DEVICE_INFO_ERROR],
    data,
  };
}

export function getClinics(data) {
  return {
    api: ClinicApi.getClinics,
    types: [GET_CLINICS_START, GET_CLINICS_SUCCESS, GET_CLINICS_ERROR],
    data,
  };
}

export function setChosenClinic(chosenClinic) {
  return (dispatch) => dispatch({ type: SET_CHOSEN_CLINIC, chosenClinic });
}

function setDeviceClinic(data) {
  return {
    api: MainApi.setDeviceClinic,
    types: [SET_DEVICE_CLINIC_START, SET_DEVICE_CLINIC_SUCCESS, SET_DEVICE_CLINIC_ERROR],
    data,
  };
}

function resetPassword(data) {
  return {
    api: MemberApi.resetPassword,
    types: [RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR],
    data,
  };
}

export function logoutApi(data) {
  return {
    api: MainApi.logout,
    types: [LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_ERROR],
    data,
  };
}
