import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal } from "../../ui/Modal";

import Show from "../../widgets/Show";
import ChartTransactionPlanModalNew from "./ChartTransactionPlanModalNew";
import ChartTransactionPlanModalSelected from "./ChartTransactionPlanModalSelected";

import bindActions from "../../../helpers/bindActions";
import * as transactionActions from "../../../actions/transactionActions";
import refreshImage from "../../../assets/images/calendar-bottom-menu/refresh.png";

const enhancer = connect(
  ({ calendar: { doctors } }) => ({ doctors }),
  bindActions({ transactionActions }),
);
export default enhancer(
  class ChartTransactionPlanModal extends React.Component {
    static propTypes = {
      transactionActions: PropTypes.object,
      transaction: PropTypes.object,
      doctors: PropTypes.array,
    };
    UNSAFE_componentWillMount() {
      this.props.transactionActions.initPlanModal();
    }

    render() {
      const { cellChangeItem, changeAssignedDoctor } = this.props;
      const {
        doctors,
        planModalVisible,
        planModalAddMode,
        planSelected,
        planNewSelected,
      } = this.props.transaction;

      const $title = planModalAddMode ? "Add treatments into plan" : "My Treatments Plan";

      let $body = null;

      if (!planModalAddMode) {
        $body = (
          <ChartTransactionPlanModalSelected
            doctors={doctors}
            cellChangeItem={cellChangeItem}
            transaction={this.props.transaction}
            changeAssignedDoctor={changeAssignedDoctor}
          />
        );
      } else {
        $body = (
          <ChartTransactionPlanModalNew
            doctors={doctors}
            cellChangeItem={cellChangeItem}
            transaction={this.props.transaction}
            changeAssignedDoctor={changeAssignedDoctor}
          />
        );
      }

      return (
        <Modal
          animation={true}
          show={planModalVisible}
          onHide={this.props.transactionActions.closePlanModal}
          title={<div>{$title}</div>}
          actions={
            <div>
              <Show if={!planModalAddMode && _.filter(planSelected, (item) => item).length}>
                <button
                  className="btn btn-lg pull-left"
                  onClick={this.props.transactionActions.removeSelected}
                >
                  Remove selected
                </button>
              </Show>
              <Show if={!planModalAddMode}>
                <div className="pull-right">
                  <button
                    className="btn btn-lg"
                    onClick={this.props.transactionActions.closePlanModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-lg btn-success"
                    onClick={this.props.transactionActions.toggleAddMode}
                  >
                    Add Codes to plan
                  </button>
                </div>
              </Show>

              <div className="pull-left">
                <button
                  className="btn btn-lg btn-primary center-block"
                  onClick={this.props.transactionActions.initPlan}
                >
                  <img alt="refresh" src={refreshImage} />
                </button>
              </div>

              <Show if={planModalAddMode && _.filter(planNewSelected, (item) => item).length}>
                <button
                  className="btn btn-lg pull-center"
                  onClick={this.props.transactionActions.unSelectPlan}
                >
                  Unselect
                </button>
              </Show>
              <Show if={planModalAddMode}>
                <div className="pull-right">
                  <button
                    className="btn btn-lg"
                    onClick={this.props.transactionActions.toggleAddMode}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-lg btn-success"
                    onClick={this.props.transactionActions.addSelected}
                  >
                    Add selected
                  </button>
                </div>
              </Show>
            </div>
          }
          keyboard={false}
          dialogClassName="chart-finish-modal chart-transaction-plan"
        >
          <div style={{ overflow: "auto" }}>{$body}</div>
        </Modal>
      );
    }
  },
);
