import React from "react";
import FilledImage from "../../widgets/FilledImage";

import TeethImage11 from "../../../assets/images/teeth/11_2@2x.png";
import TeethImage12 from "../../../assets/images/teeth/12_2@2x.png";
import TeethImage13 from "../../../assets/images/teeth/13_2@2x.png";
import TeethImage14 from "../../../assets/images/teeth/14_2@2x.png";
import TeethImage15 from "../../../assets/images/teeth/15_2@2x.png";
import TeethImage16 from "../../../assets/images/teeth/16_2@2x.png";
import TeethImage17 from "../../../assets/images/teeth/17_2@2x.png";
import TeethImage18 from "../../../assets/images/teeth/18_2@2x.png";
import TeethImage21 from "../../../assets/images/teeth/21_2@2x.png";
import TeethImage22 from "../../../assets/images/teeth/22_2@2x.png";
import TeethImage23 from "../../../assets/images/teeth/23_2@2x.png";
import TeethImage24 from "../../../assets/images/teeth/24_2@2x.png";
import TeethImage25 from "../../../assets/images/teeth/25_2@2x.png";
import TeethImage26 from "../../../assets/images/teeth/26_2@2x.png";
import TeethImage27 from "../../../assets/images/teeth/27_2@2x.png";
import TeethImage28 from "../../../assets/images/teeth/28_2@2x.png";
import TeethImage31 from "../../../assets/images/teeth/31_2@2x.png";
import TeethImage32 from "../../../assets/images/teeth/32_2@2x.png";
import TeethImage33 from "../../../assets/images/teeth/33_2@2x.png";
import TeethImage34 from "../../../assets/images/teeth/34_2@2x.png";
import TeethImage35 from "../../../assets/images/teeth/35_2@2x.png";
import TeethImage36 from "../../../assets/images/teeth/36_2@2x.png";
import TeethImage37 from "../../../assets/images/teeth/37_2@2x.png";
import TeethImage38 from "../../../assets/images/teeth/38_2@2x.png";
import TeethImage41 from "../../../assets/images/teeth/41_2@2x.png";
import TeethImage42 from "../../../assets/images/teeth/42_2@2x.png";
import TeethImage43 from "../../../assets/images/teeth/43_2@2x.png";
import TeethImage44 from "../../../assets/images/teeth/44_2@2x.png";
import TeethImage45 from "../../../assets/images/teeth/45_2@2x.png";
import TeethImage46 from "../../../assets/images/teeth/46_2@2x.png";
import TeethImage47 from "../../../assets/images/teeth/47_2@2x.png";
import TeethImage48 from "../../../assets/images/teeth/48_2@2x.png";
import BridgeImage11 from "../../../assets/images/conditions/bridge/11_2@2x.png";
import BridgeImage12 from "../../../assets/images/conditions/bridge/12_2@2x.png";
import BridgeImage13 from "../../../assets/images/conditions/bridge/13_2@2x.png";
import BridgeImage14 from "../../../assets/images/conditions/bridge/14_2@2x.png";
import BridgeImage15 from "../../../assets/images/conditions/bridge/15_2@2x.png";
import BridgeImage16 from "../../../assets/images/conditions/bridge/16_2@2x.png";
import BridgeImage17 from "../../../assets/images/conditions/bridge/17_2@2x.png";
import BridgeImage18 from "../../../assets/images/conditions/bridge/18_2@2x.png";
import BridgeImage21 from "../../../assets/images/conditions/bridge/21_2@2x.png";
import BridgeImage22 from "../../../assets/images/conditions/bridge/22_2@2x.png";
import BridgeImage23 from "../../../assets/images/conditions/bridge/23_2@2x.png";
import BridgeImage24 from "../../../assets/images/conditions/bridge/24_2@2x.png";
import BridgeImage25 from "../../../assets/images/conditions/bridge/25_2@2x.png";
import BridgeImage26 from "../../../assets/images/conditions/bridge/26_2@2x.png";
import BridgeImage27 from "../../../assets/images/conditions/bridge/27_2@2x.png";
import BridgeImage28 from "../../../assets/images/conditions/bridge/28_2@2x.png";
import BridgeImage31 from "../../../assets/images/conditions/bridge/31_2@2x.png";
import BridgeImage32 from "../../../assets/images/conditions/bridge/32_2@2x.png";
import BridgeImage33 from "../../../assets/images/conditions/bridge/33_2@2x.png";
import BridgeImage34 from "../../../assets/images/conditions/bridge/34_2@2x.png";
import BridgeImage35 from "../../../assets/images/conditions/bridge/35_2@2x.png";
import BridgeImage36 from "../../../assets/images/conditions/bridge/36_2@2x.png";
import BridgeImage37 from "../../../assets/images/conditions/bridge/37_2@2x.png";
import BridgeImage38 from "../../../assets/images/conditions/bridge/38_2@2x.png";
import BridgeImage41 from "../../../assets/images/conditions/bridge/41_2@2x.png";
import BridgeImage42 from "../../../assets/images/conditions/bridge/42_2@2x.png";
import BridgeImage43 from "../../../assets/images/conditions/bridge/43_2@2x.png";
import BridgeImage44 from "../../../assets/images/conditions/bridge/44_2@2x.png";
import BridgeImage45 from "../../../assets/images/conditions/bridge/45_2@2x.png";
import BridgeImage46 from "../../../assets/images/conditions/bridge/46_2@2x.png";
import BridgeImage47 from "../../../assets/images/conditions/bridge/47_2@2x.png";
import BridgeImage48 from "../../../assets/images/conditions/bridge/48_2@2x.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

export default function ChartSurfaceImageBase(props) {
  const { imageNumber, statusCode, toothColor } = props;
  const getTeethImage = (imageNumber) => {
    switch (imageNumber) {
      case 11:
        return TeethImage11;
      case 12:
        return TeethImage12;
      case 13:
        return TeethImage13;
      case 14:
        return TeethImage14;
      case 15:
        return TeethImage15;
      case 16:
        return TeethImage16;
      case 17:
        return TeethImage17;
      case 18:
        return TeethImage18;
      case 21:
        return TeethImage21;
      case 22:
        return TeethImage22;
      case 23:
        return TeethImage23;
      case 24:
        return TeethImage24;
      case 25:
        return TeethImage25;
      case 26:
        return TeethImage26;
      case 27:
        return TeethImage27;
      case 28:
        return TeethImage28;
      case 31:
        return TeethImage31;
      case 32:
        return TeethImage32;
      case 33:
        return TeethImage33;
      case 34:
        return TeethImage34;
      case 35:
        return TeethImage35;
      case 36:
        return TeethImage36;
      case 37:
        return TeethImage37;
      case 38:
        return TeethImage38;
      case 41:
        return TeethImage41;
      case 42:
        return TeethImage42;
      case 43:
        return TeethImage43;
      case 44:
        return TeethImage44;
      case 45:
        return TeethImage45;
      case 46:
        return TeethImage46;
      case 47:
        return TeethImage47;
      case 48:
        return TeethImage48;
      default:
        return "";
    }
  };
  const getBridgeImage = (imageNumber) => {
    switch (imageNumber) {
      case 11:
        return BridgeImage11;
      case 12:
        return BridgeImage12;
      case 13:
        return BridgeImage13;
      case 14:
        return BridgeImage14;
      case 15:
        return BridgeImage15;
      case 16:
        return BridgeImage16;
      case 17:
        return BridgeImage17;
      case 18:
        return BridgeImage18;
      case 21:
        return BridgeImage21;
      case 22:
        return BridgeImage22;
      case 23:
        return BridgeImage23;
      case 24:
        return BridgeImage24;
      case 25:
        return BridgeImage25;
      case 26:
        return BridgeImage26;
      case 27:
        return BridgeImage27;
      case 28:
        return BridgeImage28;
      case 31:
        return BridgeImage31;
      case 32:
        return BridgeImage32;
      case 33:
        return BridgeImage33;
      case 34:
        return BridgeImage34;
      case 35:
        return BridgeImage35;
      case 36:
        return BridgeImage36;
      case 37:
        return BridgeImage37;
      case 38:
        return BridgeImage38;
      case 41:
        return BridgeImage41;
      case 42:
        return BridgeImage42;
      case 43:
        return BridgeImage43;
      case 44:
        return BridgeImage44;
      case 45:
        return BridgeImage45;
      case 46:
        return BridgeImage46;
      case 47:
        return BridgeImage47;
      case 48:
        return BridgeImage48;
      default:
        return "";
    }
  };

  let img;
  if (!imageNumber || statusCode === "TOOTHSTATUS_ABSENT" || statusCode === "TOOTHSTATUS_IMPACT") {
    img = null;
  } else if (statusCode === "TOOTHSTATUS_BRIDGE" || statusCode === "TOOTHSTATUS_BRIDGE_ABSENT") {
    const src = getBridgeImage(imageNumber);
    img = (
      <FilledImage color={toothColor} src={src} className="zoom-2x" style={{ height: "100%" }} />
    );
  } else {
    const src = obtainImagePath(getTeethImage(imageNumber));
    img = <img src={src} className="zoom-2x" style={{ height: "100%" }} alt="" />;
  }

  return <div style={{ height: "100%" }}>{img}</div>;
}
