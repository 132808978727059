import { httpClient } from "./BaseApi";

const PROCEDURE_ITEM_URL = "api/treatment/procedure/item.json";
const PROCEDURE_LIST_URL = "api/treatment/procedure/list.json";

const PROCEDURE_REQUEST_ITEM_URL = "api/treatment/procedure/request/item.json";
const PROCEDURE_CATEGORY_LIST_URL = "api/treatment/procedure/category/list.json";

const TREATMENT_PACKET_LIST_URL = "api/treatment/packet/list.json";

const TREATMENT_CODE_ITEM_URL = "api/treatment/code/item.json";
const TREATMENT_CODE_LIST_URL = "api/treatment/code/list.json";
const TREATMENT_CODE_PRICE_LIST_URL = "api/treatment/code/price-list.json";
const TREATMENT_CODE_NEED_SIGN_LIST_URL = "api/treatment/code/need-sign/list.json";
const TREATMENT_CODE_CHOOSE_BY_CODES_URL = "api/treatment/code/choose-by-codes.json";
const TREATMENT_TYPEAHEAD_URL = "api/treatment/group/typeahead.json";
const TREATMENT_GROUP_LIST_URL = "api/treatment/group/list.json";

const TreatmentApi = {
  getItem: (params) => httpClient.get(TREATMENT_CODE_ITEM_URL, { params }),
  getProcedureItem: (params) => httpClient.get(PROCEDURE_ITEM_URL, { params }),
  getList: (params) => httpClient.get(TREATMENT_CODE_LIST_URL, { params }),
  saveProcedureItem: (data) => httpClient.post(PROCEDURE_ITEM_URL, { data }),
  getProcedureList: (params) => httpClient.get(PROCEDURE_LIST_URL, { params }),
  getPriceList: (params) => httpClient.get(TREATMENT_CODE_PRICE_LIST_URL, { params }),
  getCategoryList: (params) => httpClient.get(PROCEDURE_CATEGORY_LIST_URL, { params }),
  getTreatmentTypeahead: (params) => httpClient.get(TREATMENT_TYPEAHEAD_URL, { params }),
  getSignInList: (data) => httpClient.post(TREATMENT_CODE_NEED_SIGN_LIST_URL, { data }),
  getChooseByCode: (data) => httpClient.post(TREATMENT_CODE_CHOOSE_BY_CODES_URL, { data }),
  saveProcedureRequestItem: (data) => httpClient.post(PROCEDURE_REQUEST_ITEM_URL, { data }),
  packet: {
    getList: (params) => httpClient.get(TREATMENT_PACKET_LIST_URL, { params }),
  },
  getTreatmentGroupList: (params) => httpClient.get(TREATMENT_GROUP_LIST_URL, { params }),
};

export default TreatmentApi;
