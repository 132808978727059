import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";

import bindActions from "../../../helpers/bindActions";
import * as chartGeneralActions from "../../../actions/chartGeneralActions";
import * as chartActions from "../../../actions/chartActions";
import moment from "moment";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import checkedImage from "../../../assets/images/appointment/checked.png";
import uncheckedImage from "../../../assets/images/appointment/unchecked.png";
import appointmentImage from "../../../assets/images/profile/dashboard_appointments.png";

const enhancer = connect(
  ({ session }) => ({
    fillInFormTemplateId: _.get(session, "miscSettings.patientChartFillInFormTemplate.id"),
  }),
  bindActions({ chartGeneralActions, chartActions }),
);

class ChartGeneral extends React.Component {
  componentDidMount() {
    this.props.chartGeneralActions.initGeneral(this.props.query);
  }

  addService = () => {
    this.props.chartActions.showSideBar(viewTypes.series);
  };

  render() {
    const {
      location,
      chartSidePostOp,
      chartSideFillInForm,
      fillInFormTemplateId,
      chartSideClinicalNote,
      chartGeneral: { goalList, stickyLists },
    } = this.props;
    const { ownedList } = chartSidePostOp;

    const clinicalNote = chartSideClinicalNote.ownedList[0] || {};

    const $goals = goalList.map((item, index) => {
      const checkboxSrc = item.selected ? checkedImage : uncheckedImage;

      return (
        <div key={index} className="chart-general-goal-item clearfix">
          <div className="goal-checkbox">
            <img alt="" src={checkboxSrc} className="zoom-2x" />
          </div>
          <div className="text">{item.notes}</div>
        </div>
      );
    });

    const $sticky = stickyLists.map(({ categoryId, list }, index) => {
      const $list = (list || []).map((item, index) => (
        <div key={index} className="chart-general-goal-item clearfix">
          <div className="goal-checkbox">
            <div className="white-circle" />
          </div>
          <div className="text">{item.notes}</div>
        </div>
      ));

      return (
        <div
          key={index}
          className="sticky col"
          onClick={() =>
            this.props.chartActions.showSideBar(viewTypes.sticky, {
              categoryId,
              index,
            })
          }
        >
          <div className="body">{$list}</div>
          <div className="footer">
            <div className="text">Sticky Note</div>
            <div className="icon">
              <img src={appointmentImage} alt="" />
            </div>
          </div>
        </div>
      );
    });

    const $fillInForms = chartSideFillInForm.ownedList.map((item, index) => (
      <div className="fill-in-form-item" key={index}>
        <div className="date">
          {moment(item.createdDate || item.modifiedDate).format("DD/MM/YYYY")}
        </div>
        <div className="name">{item.name}</div>
      </div>
    ));

    const $frequencyFillInForms = chartSideFillInForm.frequencyList.map((item, index) => (
      <div className="fill-in-form-item" key={index}>
        <div className="name" style={{ textAlign: "left" }}>
          {item.name}
        </div>
      </div>
    ));

    const query = parseQuery(location.search);

    return (
      <div className="chart-general" style={{ overflowY: "auto" }}>
        <div className="grid">
          <div
            className="clinical-note col"
            onClick={() => this.props.chartActions.showSideBar(viewTypes.clinicalNote)}
          >
            <div className="body">
              <div className="title">{clinicalNote.name}</div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: clinicalNote.note }}
              />
            </div>
            <div className="footer">
              <div className="text">Clinical note</div>
              <div className="icon">
                <img src={appointmentImage} alt="" />
              </div>
            </div>
          </div>
          <div className="reports col">
            <div className="body" />
            <div className="footer">
              <div className="text">Reports and Statistics</div>
              <div className="icon">
                <img src={appointmentImage} alt="" />
              </div>
            </div>
          </div>
          <div
            className="goals col"
            onClick={() => this.props.chartActions.showSideBar(viewTypes.goals)}
          >
            <div className="body">{$goals}</div>
            <div className="footer">
              <div className="text">Goals</div>
              <div className="icon">
                <img src={appointmentImage} alt="" />
              </div>
            </div>
          </div>

          <div
            className="sticky col"
            style={{ backgroundColor: "#5981d3" }}
            onClick={() => this.props.chartActions.showSideBar(viewTypes.postOp)}
          >
            <div className="body">
              {ownedList.map(({ name, id }) => (
                <div key={id} className="name text-ellipsis">
                  {name}
                </div>
              ))}
            </div>
            <div className="footer">
              <div className="text">Post Operation</div>
              <div className="icon">
                <img src={appointmentImage} alt="" />
              </div>
            </div>
          </div>

          <div
            className="fill-in-forms col"
            onClick={() => {
              this.props.history.replace(
                createUrl(Routes.DashboardChart, {
                  params: { type: "View" },
                  query: { ...query, isFrequency: true },
                }),
              );
              this.props.chartActions.showSideBar(viewTypes.fillInForm);
            }}
          >
            <div className="title">Frequent Fill In Forms</div>
            <div className="body">{$frequencyFillInForms}</div>

            {fillInFormTemplateId && (
              <div className="footer">
                <div className="button">
                  <button
                    className="btn btn-block btn-primary btn-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.history.replace(
                        createUrl(Routes.DashboardChart, {
                          params: { type: "View" },
                          query: { ...query, isFrequency: true },
                        }),
                      );
                      this.props.chartActions.showSideBar(viewTypes.fillInForm, {
                        isNew: true,
                      });
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>

          {$sticky}

          <div
            className="fill-in-forms col"
            onClick={() => this.props.chartActions.showSideBar(viewTypes.fillInForm)}
          >
            <div className="title">Fill In Forms</div>
            <div className="body">{$fillInForms}</div>
            <div className="footer">
              <div className="button">
                <button
                  className="btn btn-block btn-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.chartActions.showSideBar(viewTypes.fillInForm, {
                      isNew: true,
                    });
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="chart-general-add">
          <button className="btn btn-primary btn-block btn-lg" onClick={this.addService}>
            Add Service
          </button>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartGeneral);
