import PatientApi from "../api-legacy/PatientApi";

import * as ActionType from "../actionTypes/patientListActionTypes";
import { startLoader, stopLoader } from "./loaderActions";

export function initList(params) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(openSearch());

    dispatch(
      getPatientList({
        ...params,
        start: 0,
        limit: 25,
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(startLoader(error));
      });
  };
}

export function loadList(params) {
  return (dispatch, getState) => {
    const {
      patientList: { list, listTotalCount, loading },
    } = getState();
    if (list.length === listTotalCount || loading) return;

    dispatch(startLoader());

    dispatch(
      getPatientList({
        ...params,
        start: list.length,
        limit: 25,
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(startLoader(error));
      });
  };
}

export function openSearch() {
  return {
    type: ActionType.PATIENT_LIST_SHOW_SIDEBAR,
  };
}

export function sortListByChartNumber() {
  return {
    type: ActionType.SORT_LIST_BY_CHART_NUMBER,
  };
}

export function sortListByName() {
  return {
    type: ActionType.SORT_LIST_BY_NAME,
  };
}

export function hideSearch() {
  return {
    type: ActionType.PATIENT_LIST_HIDE_SIDEBAR,
  };
}

export function changePhoneCode(value) {
  return {
    type: ActionType.PATIENT_LIST_CHANGE_PHONE_CODE,
    value,
  };
}

function getPatientList(data) {
  return {
    api: PatientApi.getList,
    types: [
      ActionType.PATIENT_LIST_GET_LIST_START,
      ActionType.PATIENT_LIST_GET_LIST_SUCCESS,
      ActionType.PATIENT_LIST_GET_LIST_ERROR,
    ],
    data,
  };
}
