import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/waitingListActionTypes";
import { STATUS_TYPES } from "../constants/StatusTypes";
import { SET_MISSED_CANCELLED_LIST_ACTIVE_TAB } from "../actions/appointmentActions";

const defaultState = {
  list: [],
  fromDate: moment(),
  toDate: moment(),
  missedCancelledListDate: moment(),
  cancelledList: [],
  cancelledCenterList: [],
  missedCancelledList: [],
  missedCancelledListTotal: null,
  missedCancelledFetching: false,
  periodStart: moment(),
  periodEnd: moment(),
  futureTypeCode: { value: -1, label: "Please select" },
  statusCodes: [
    { value: STATUS_TYPES.APPOINTMENT_STATUS_MISSED, label: "Client (Missed)" },
    { value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED, label: "Center (Missed)" },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      label: "Cancelled by patient",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      label: "Cancelled by centre",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      label: "Released (Non-Payment)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      label: "Client(Late Cancellation)",
    },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
    //   label: "Missed rescheduled",
    // },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
    //   label: "Cancelled Rescheduled",
    // },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
    //   label: "Voided",
    // },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      label: "Clinician (Cancelled)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      label: "Center (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      label: "Clinician (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
      label: "Clinician (No Show)",
    },
  ],
  missedCancelledFormValues: {},
  missedCancelledListActiveTab: "missed",
  requestedWaitingList: [],
  requestedWaitingFatching: false,
  requestedWaitingTotal: 0,
  requestedScheduledList: [],
  requestedScheduledTotal: 0,
  requestedScheduledFatching: false,
  requestedWaitingInfoShow: [],
  requestedDeleteConfirmModalVisible: 0,
  doctor: "",
  apptTypes: [],
  apptType: 0,
  doctors: {},
  bookedAppointmentStartDate: moment(),
  bookedAppointmentEndDate: moment(),
  isFilterByCreatedDate: false,
};

const reducers = {
  [ActionType.CHANGE_BOOKED_APPOINTMENT_DATE](state, { values }) {
    return {
      ...state,
      bookedAppointmentStartDate: values.startDate,
      bookedAppointmentEndDate: values.endDate,
      isFilterByCreatedDate: true,
    };
  },
  [ActionType.CHANGE_BOOKED_APPOINTMENT_DATE_TO_DEFAULT](state) {
    return {
      ...state,
      bookedAppointmentStartDate: moment(),
      bookedAppointmentEndDate: moment(),
      isFilterByCreatedDate: false,
    };
  },
  [ActionType.WAITING_LIST_INIT_DOCTORS](state, { doctors }) {
    return {
      ...state,
      doctors,
    };
  },
  [SET_MISSED_CANCELLED_LIST_ACTIVE_TAB](state, { activeTab }) {
    return {
      ...state,
      missedCancelledListActiveTab: activeTab,
    };
  },
  [ActionType.WAITING_LIST_SELECT_DOCTORS](state, { id }) {
    const doctors = JSON.parse(JSON.stringify(state.doctors));

    if (id) {
      doctors[id].active = !doctors[id].active;
    } else {
      _.forEach(doctors, (item) => {
        item.active = false;
      });
    }

    return {
      ...state,
      doctors,
    };
  },
  [ActionType.REQUESTED_DELETE_CONFIRM_MODAL_SHOW](state, { id }) {
    return {
      ...state,
      requestedDeleteConfirmModalVisible: id,
    };
  },
  [ActionType.REQUESTED_DELETE_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      requestedDeleteConfirmModalVisible: 0,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_WAITING_LIST_INFO](state, { id }) {
    return {
      ...state,
      requestedWaitingInfoShow: id,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_START](state) {
    return {
      ...state,
      requestedWaitingFatching: true,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      requestedWaitingFatching: false,
      requestedWaitingList: request.start
        ? state.requestedWaitingList.concat(data.list)
        : data.list || [],
      requestedWaitingTotal: data.totalCount || 0,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_ERROR](state) {
    return {
      ...state,
      requestedWaitingFatching: false,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_START](state) {
    return {
      ...state,
      requestedScheduledFatching: true,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      requestedScheduledFatching: false,
      requestedScheduledList: request.start
        ? state.requestedScheduledList.concat(data.list)
        : data.list || [],
      requestedScheduledTotal: data.totalCount || 0,
    };
  },
  [ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_ERROR](state) {
    return {
      ...state,
      requestedScheduledFatching: false,
    };
  },
  [ActionType.SET_MISSED_CANCELLED_LIST_DATE](state, { value }) {
    return {
      ...state,
      missedCancelledListDate: value,
    };
  },
  [ActionType.GET_MISSED_CANCELLED_LIST_START](state) {
    return {
      ...state,
      missedCancelledFetching: true,
    };
  },
  [ActionType.GET_MISSED_CANCELLED_LIST_ERROR](state) {
    return {
      ...state,
      missedCancelledFetching: false,
    };
  },
  [ActionType.SET_MISSED_CANCELLED_LIST_FORM_VALUES](state, { values }) {
    return {
      ...state,
      missedCancelledFormValues: values,
      // missedCancelledList: [],
      // missedCancelledListTotal: null,
    };
  },
  [ActionType.GET_MISSED_CANCELLED_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      missedCancelledFetching: false,
      missedCancelledList: request.start
        ? state.missedCancelledList.concat(data.list || [])
        : data.list || [],
      missedCancelledListTotal: data.totalCount || null,
    };
  },
  [ActionType.GET_WAITING_LIST_START](state) {
    return {
      ...state,
      list: [],
    };
  },
  [ActionType.GET_WAITING_LIST_SUCCESS](state, { data }) {
    return { ...state, list: data };
  },
  [ActionType.GET_WAITING_LIST_ERROR](state) {
    return {
      ...state,
      list: [],
    };
  },
  [ActionType.SET_WAITING_LIST_FROM_DATE](state, fromDate) {
    return {
      ...state,
      fromDate: fromDate.date,
    };
  },
  [ActionType.SET_WAITING_LIST_TO_DATE](state, toDate) {
    return {
      ...state,
      toDate: toDate.date,
    };
  },
  [ActionType.WAITING_LIST_SET_DOCTOR](state, { doctor }) {
    return {
      ...state,
      doctor,
    };
  },
  [ActionType.WAITING_LIST_SET_APPT_TYPE](state, { apptType }) {
    return {
      ...state,
      apptType,
    };
  },
  [ActionType.WAITING_LIST_GET_APPT_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      apptTypes: data || [],
    };
  },
};

export default createReducer(defaultState, reducers);
