import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Form, FormCheckProps } from "react-bootstrap";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& > .form-check-input": { marginTop: "-1px", marginRight: "4px" },

    "& > .form-check-label": { marginBottom: 0, cursor: "pointer", userSelect: "none" },
  },
}));

export type CheckboxProps = FormCheckProps;

export function Checkbox({ className, ...props }: CheckboxProps) {
  const classes = useStyles();

  return <Form.Check className={cx(classes.root, className)} {...props} />;
}
