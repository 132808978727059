import React from "react";
import { Field, reduxForm } from "redux-form";

import FlexBox from "../../ui/FlexBox";
import PatientFormResult from "./PatientFormResult";
import { ReduxPhoneNumber } from "../../widgets/redux/ReduxPhoneNumber";

const enhancer = reduxForm({ form: "TemporaryPatientForm" });

function TemporaryPatientForm({
  addPatient,
  handleSubmit,
  selectPatient,
  temporaryResult,
  temporaryPatient,
  searchTemporaryPatientResultHide,
}) {
  return (
    <div className="temporary-patient">
      <form name="TemporaryPatientForm" className="temporary-form" onSubmit={handleSubmit}>
        <FlexBox container={24} direction="column">
          <ReduxPhoneNumber
            label="Phone Number"
            name="phoneNumber"
            className="form-control input-lg Code"
            countryCodeClassName="select-input-lg"
          />
          <FlexBox gutter={8} flex={true} className="form-group">
            <FlexBox direction="column" flex={true}>
              <label>Email</label>
              <Field
                component="input"
                type="email"
                name="email"
                className="form-control input-lg"
              />
            </FlexBox>
          </FlexBox>
          <FlexBox gutter={8} flex={true}>
            <FlexBox>
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </form>

      <PatientFormResult
        addPatient={addPatient}
        selectPatient={selectPatient}
        temporaryResult={temporaryResult}
        temporaryPatient={temporaryPatient}
        hideResult={searchTemporaryPatientResultHide}
      />
    </div>
  );
}

export default enhancer(TemporaryPatientForm);
