export const GET_STATUS_LIST_START = "GET_STATUS_LIST_START";
export const GET_STATUS_LIST_SUCCESS = "GET_STATUS_LIST_SUCCESS";
export const GET_STATUS_LIST_ERROR = "GET_STATUS_LIST_ERROR";

export const APPOINTMENT_STATUS_CHANGE_START = "APPOINTMENT_STATUS_CHANGE_START";
export const APPOINTMENT_STATUS_CHANGE_SUCCESS = "APPOINTMENT_STATUS_CHANGE_SUCCESS";
export const APPOINTMENT_STATUS_CHANGE_ERROR = "APPOINTMENT_STATUS_CHANGE_ERROR";

export const SET_OPEN_SLOT_DOCTORS = "SET_OPEN_SLOT_DOCTORS";

export const OPEN_TIME_SLOT_CHOOSE_DOCTOR = "OPEN_TIME_SLOT_CHOOSE_DOCTOR";

export const OPEN_TIME_SLOT_RESET_DOCTORS = "OPEN_TIME_SLOT_RESET_DOCTORS";

export const GET_COUNT_BY_STATUS_START = "GET_COUNT_BY_STATUS_START";
export const GET_COUNT_BY_STATUS_SUCCESS = "GET_COUNT_BY_STATUS_SUCCESS";
export const GET_COUNT_BY_STATUS_ERROR = "GET_COUNT_BY_STATUS_ERROR";

export const GET_CATEGORY_LIST_START = "GET_CATEGORY_LIST_START";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";

export const GET_DENTAL_POINT_START = "GET_DENTAL_POINT_START";
export const GET_DENTAL_POINT_SUCCESS = "GET_DENTAL_POINT_SUCCESS";
export const GET_DENTAL_POINT_ERROR = "GET_DENTAL_POINT_ERROR";

export const GET_CHOOSE_DENTIST_START = "GET_CHOOSE_DENTIST_START";
export const GET_CHOOSE_DENTIST_SUCCESS = "GET_CHOOSE_DENTIST_SUCCESS";
export const GET_CHOOSE_DENTIST_ERROR = "GET_CHOOSE_DENTIST_ERROR";

export const GET_PATIENT_GENDER_START = "GET_PATIENT_GENDER_START";
export const GET_PATIENT_GENDER_SUCCESS = "GET_PATIENT_GENDER_SUCCESS";
export const GET_PATIENT_GENDER_ERROR = "GET_PATIENT_GENDER_ERROR";

export const CHANGE_APPOINTMENT_ITEM = "CHANGE_APPOINTMENT_ITEM";
export const INIT_APPOINTMENT = "INIT_APPOINTMENT";
export const INIT_APPOINTMENT_FINISHED = "INIT_APPOINTMENT_FINISHED";

export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENT_FINISHED = "GET_APPOINTMENT_FINISHED";
export const GET_APPOINTMENT_START = "GET_APPOINTMENT_START";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_ERROR = "GET_APPOINTMENT_ERROR";

export const CREATE_APPOINTMENT_START = "CREATE_APPOINTMENT_START";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_ERROR = "CREATE_APPOINTMENT_ERROR";

export const SEARCH_PATIENT_LIST_START = "SEARCH_PATIENT_LIST_START";
export const SEARCH_PATIENT_LIST_SUCCESS = "SEARCH_PATIENT_LIST_SUCCESS";
export const SEARCH_PATIENT_LIST_ERROR = "SEARCH_PATIENT_LIST_ERROR";

export const GET_FREE_POINTS_START = "GET_FREE_POINTS_START";
export const GET_FREE_POINTS_SUCCESS = "GET_FREE_POINTS_SUCCESS";
export const GET_FREE_POINTS_ERROR = "GET_FREE_POINTS_ERROR";

export const GET_MULTIPLE_DOCTOR_FREE_POINTS_START = "GET_MULTIPLE_DOCTOR_FREE_POINTS_START";
export const GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS = "GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS";
export const GET_MULTIPLE_DOCTOR_FREE_POINTS_ERROR = "GET_MULTIPLE_DOCTOR_FREE_POINTS_ERROR";

export const APPOINTMENT_CASH_PRICE_START = "APPOINTMENT_CASH_PRICE_START";
export const APPOINTMENT_CASH_PRICE_SUCCESS = "APPOINTMENT_CASH_PRICE_SUCCESS";
export const APPOINTMENT_CASH_PRICE_ERROR = "APPOINTMENT_CASH_PRICE_ERROR";

export const APPOINTMENT_GET_RELATED_LIST_START = "APPOINTMENT_GET_RELATED_LIST_START";
export const APPOINTMENT_GET_RELATED_LIST_SUCCESS = "APPOINTMENT_GET_RELATED_LIST_SUCCESS";
export const APPOINTMENT_GET_RELATED_LIST_ERROR = "APPOINTMENT_GET_RELATED_LIST_ERROR";

export const APPOINTMENT_RELATED_ADD_START = "APPOINTMENT_RELATED_ADD_START";
export const APPOINTMENT_RELATED_ADD_SUCCESS = "APPOINTMENT_RELATED_ADD_SUCCESS";
export const APPOINTMENT_RELATED_ADD_ERROR = "APPOINTMENT_RELATED_ADD_ERROR";

export const APPOINTMENT_RELATED_REMOVE_START = "APPOINTMENT_RELATED_REMOVE_START";
export const APPOINTMENT_RELATED_REMOVE_SUCCESS = "APPOINTMENT_RELATED_REMOVE_SUCCESS";
export const APPOINTMENT_RELATED_REMOVE_ERROR = "APPOINTMENT_RELATED_REMOVE_ERROR";

export const APPOINTMENT_GET_HISTORY_START = "APPOINTMENT_GET_HISTORY_START";
export const APPOINTMENT_GET_HISTORY_SUCCESS = "APPOINTMENT_GET_HISTORY_SUCCESS";
export const APPOINTMENT_GET_HISTORY_ERROR = "APPOINTMENT_GET_HISTORY_ERROR";

export const CHOOSE_PATIENT_ITEM = "CHOOSE_PATIENT_ITEM";

export const APPOINTMENT_REASON_MODAL_OPEN = "APPOINTMENT_REASON_MODAL_OPEN";
export const APPOINTMENT_REASON_MODAL_CLOSE = "APPOINTMENT_REASON_MODAL_CLOSE";

export const TOGGLE_SWITCHER_STATUS = "CHANGE_SWITCHER_STATUS";
export const TOGGLE_DISABLED_SWITCHER_STATUS = "TOGGLE_DISABLED_SWITCHER_STATUS";

export const APPOINTMENT_CHOOSE_PRICE = "APPOINTMENT_CHOOSE_PRICE";

export const APPOINTMENT_INIT_RELATED_MODAL = "APPOINTMENT_INIT_RELATED_MODAL";
export const APPOINTMENT_SHOW_CODES_FOR_THIS_MODAL = "APPOINTMENT_SHOW_CODES_FOR_THIS_MODAL";
export const APPOINTMENT_CLOSE_CODES_FOR_THIS_MODAL = "APPOINTMENT_CLOSE_CODES_FOR_THIS_MODAL";

export const APPOINTMENT_TOGGLE_THAT_MODE = "APPOINTMENT_TOGGLE_THAT_MODE";
export const APPOINTMENT_UNSELECT_THAT = "APPOINTMENT_UNSELECT_THAT";

export const APPOINTMENT_TOGGLE_THIS = "APPOINTMENT_TOGGLE_THIS";
export const APPOINTMENT_TOGGLE_THAT = "APPOINTMENT_TOGGLE_THAT";
export const APPOINTMENT_TOGGLE_THIS_ALL = "APPOINTMENT_TOGGLE_THIS_ALL";

export const REPEAT_APPOINTMENT_MODAL_SHOW = "REPEAT_APPOINTMENT_MODAL_SHOW";
export const REPEAT_APPOINTMENT_MODAL_HIDE = "REPEAT_APPOINTMENT_MODAL_HIDE";

export const REPEAT_APPOINTMENT_SELECT_DATE = "REPEAT_APPOINTMENT_SELECT_DATE";

export const REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE =
  "REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE";

export const REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY = "REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY";
export const REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY = "REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY";

export const REPEAT_APPOINTMENT_SET_DELETE_ID = "REPEAT_APPOINTMENT_SET_DELETE_ID";

export const REPEAT_APPOINTMENT_GET_LIST_START = "REPEAT_APPOINTMENT_GET_LIST_START";
export const REPEAT_APPOINTMENT_GET_LIST_SUCCESS = "REPEAT_APPOINTMENT_GET_LIST_SUCCESS";
export const REPEAT_APPOINTMENT_GET_LIST_ERROR = "REPEAT_APPOINTMENT_GET_LIST_ERROR";

export const REPEAT_APPOINTMENT_SAVE_ITEM_START = "REPEAT_APPOINTMENT_SAVE_ITEM_START";
export const REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS = "REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS";
export const REPEAT_APPOINTMENT_SAVE_ITEM_ERROR = "REPEAT_APPOINTMENT_SAVE_ITEM_ERROR";

export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START = "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START";
export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS =
  "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS";
export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR = "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR";

export const REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW = "REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW";
export const REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE = "REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE";

export const REPEAT_APPOINTMENT_SET_FOLLOW_TYPE = "REPEAT_APPOINTMENT_SET_FOLLOW_TYPE";

export const REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE = "REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE";

export const REPEAT_APPOINTMENT_DELETE_AFTER_START = "REPEAT_APPOINTMENT_DELETE_AFTER_START";
export const REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS = "REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS";
export const REPEAT_APPOINTMENT_DELETE_AFTER_ERROR = "REPEAT_APPOINTMENT_DELETE_AFTER_ERROR";

export const REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW = "REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW";
export const REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE = "REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE";

export const PATIENT_VIEW_GET_NOTES_START = "PATIENT_VIEW_GET_NOTES_START";
export const PATIENT_VIEW_GET_NOTES_SUCCESS = "PATIENT_VIEW_GET_NOTES_SUCCESS";
export const PATIENT_VIEW_GET_NOTES_ERROR = "PATIENT_VIEW_GET_NOTES_ERROR";

export const APPOINTMENT_PATIENT_NOTES_MODAL_SHOW = "APPOINTMENT_PATIENT_NOTES_MODAL_SHOW";
export const APPOINTMENT_PATIENT_NOTES_MODAL_HIDE = "APPOINTMENT_PATIENT_NOTES_MODAL_HIDE";

export const APPOINTMENT_SELECT_BIRTH_DATE = "APPOINTMENT_SELECT_BIRTH_DATE";
export const CHANGE_PATIENT_FORM_DATA = "CHANGE_PATIENT_FORM_DATA";

export const APPOINTMENT_CHANGE_NURSERY = "APPOINTMENT_CHANGE_NURSERY";

export const DELETE_APPOINTMENT_MODAL_SHOW = "DELETE_APPOINTMENT_MODAL_SHOW";
export const DELETE_APPOINTMENT_MODAL_HIDE = "DELETE_APPOINTMENT_MODAL_HIDE";

export const SHOW_SIDE_BAR = "SHOW_SIDE_BAR";
export const HIDE_SIDE_BAR = "HIDE_SIDE_BAR";

export const CONSULTATION_NOTES_SIDEBAR_SHOW = "CONSULTATION_NOTES_SIDEBAR_SHOW";
export const CONSULTATION_NOTES_SIDEBAR_HIDE = "CONSULTATION_NOTES_SIDEBAR_HIDE";

export const GET_CONSULTATION_NOTES_LIST_START = "GET_CONSULTATION_NOTES_LIST_START";
export const GET_CONSULTATION_NOTES_LIST_SUCCESS = "GET_CONSULTATION_NOTES_LIST_SUCCESS";
export const GET_CONSULTATION_NOTES_LIST_ERROR = "GET_CONSULTATION_NOTES_LIST_ERROR";

export const GET_CONSULTATION_NOTES_ITEM_START = "GET_CONSULTATION_NOTES_ITEM_START";
export const GET_CONSULTATION_NOTES_ITEM_SUCCESS = "GET_CONSULTATION_NOTES_ITEM_SUCCESS";
export const GET_CONSULTATION_NOTES_ITEM_ERROR = "GET_CONSULTATION_NOTES_ITEM_ERROR";

export const DELETE_CONSULTATION_NOTES_ITEM_START = "DELETE_CONSULTATION_NOTES_ITEM_START";
export const DELETE_CONSULTATION_NOTES_ITEM_SUCCESS = "DELETE_CONSULTATION_NOTES_ITEM_SUCCESS";
export const DELETE_CONSULTATION_NOTES_ITEM_ERROR = "DELETE_CONSULTATION_NOTES_ITEM_ERROR";

export const SAVE_CONSULTATION_NOTES_ITEM_START = "SAVE_CONSULTATION_NOTES_ITEM_START";
export const SAVE_CONSULTATION_NOTES_ITEM_SUCCESS = "SAVE_CONSULTATION_NOTES_ITEM_SUCCESS";
export const SAVE_CONSULTATION_NOTES_ITEM_ERROR = "SAVE_CONSULTATION_NOTES_ITEM_ERROR";

export const EDIT_CONSULTATION_NOTES_ITEM_START = "EDIT_CONSULTATION_NOTES_ITEM_START";
export const EDIT_CONSULTATION_NOTES_ITEM_SUCCESS = "EDIT_CONSULTATION_NOTES_ITEM_SUCCESS";
export const EDIT_CONSULTATION_NOTES_ITEM_ERROR = "EDIT_CONSULTATION_NOTES_ITEM_ERROR";

export const RESET_CONSULTATION_NOTES_ITEM = "RESET_CONSULTATION_NOTES_ITEM";

export const CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_SHOW =
  "CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_SHOW";
export const CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_HIDE =
  "CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_HIDE";

export const GET_MASTER_TYPE_START = "GET_MASTER_TYPE_START";
export const GET_MASTER_TYPE_SUCCESS = "GET_MASTER_TYPE_SUCCESS";
export const GET_MASTER_TYPE_ERROR = "GET_MASTER_TYPE_ERROR";

export const SET_CONSULTATION_NOTE = "SET_CONSULTATION_NOTE";

export const SEARCH_TEMPORARY_PATIENT_START = "SEARCH_TEMPORARY_PATIENT_START";
export const SEARCH_TEMPORARY_PATIENT_SUCCESS = "SEARCH_TEMPORARY_PATIENT_SUCCESS";
export const SEARCH_TEMPORARY_PATIENT_ERROR = "SEARCH_TEMPORARY_PATIENT_ERROR";

export const SEARCH_TEMPORARY_PATIENT_RESULT_SHOW = "SEARCH_TEMPORARY_PATIENT_RESULT_SHOW";
export const SEARCH_TEMPORARY_PATIENT_RESULT_HIDE = "SEARCH_TEMPORARY_PATIENT_RESULT_HIDE";

export const CHOOSE_TEMPORARY_PATIENT = "CHOOSE_TEMPORARY_PATIENT";
export const ADD_NEW_TEMPORARY_PATIENT = "ADD_NEW_TEMPORARY_PATIENT";

export const INIT_FIRST_CONTACT = "INIT_FIRST_CONTACT";

export const GET_REASON_LIST_START = "GET_REASON_LIST_START";
export const GET_REASON_LIST_SUCCESS = "GET_REASON_LIST_SUCCESS";
export const GET_REASON_LIST_ERROR = "GET_REASON_LIST_ERROR";

export const OPEN_REASON_MODAL = "OPEN_REASON_MODAL";
export const CLOSE_REASON_MODAL = "CLOSE_REASON_MODAL";

export const SAVE_CALL_REASON_START = "SAVE_CALL_REASON_START";
export const SAVE_CALL_REASON_SUCCESS = "SAVE_CALL_REASON_SUCCESS";
export const SAVE_CALL_REASON_ERROR = "SAVE_CALL_REASON_ERROR";

export const APPOINTMENT_MOVE = "APPOINTMENT_MOVE";

export const CHANGE_APPOINTMENT_RELATED_TAB = "CHANGE_APPOINTMENT_RELATED_TAB";

export const APPOINTMENT_TOGGLE_SIDEBAR = "APPOINTMENT_TOGGLE_SIDEBAR";

export const APPOINTMENT_ADD_REQUESTED_TREATMENT = "APPOINTMENT_ADD_REQUESTED_TREATMENT";
export const APPOINTMENT_REMOVE_REQUESTED_TREATMENT = "APPOINTMENT_REMOVE_REQUESTED_TREATMENT";

export const APPOINTMENT_GET_REQUEST_LIST_START = "APPOINTMENT_GET_REQUEST_LIST_START";
export const APPOINTMENT_GET_REQUEST_LIST_SUCCESS = "APPOINTMENT_GET_REQUEST_LIST_SUCCESS";
export const APPOINTMENT_GET_REQUEST_LIST_ERROR = "APPOINTMENT_GET_REQUEST_LIST_ERROR";

export const APPOINTMENT_GET_REQUEST_ITEM_START = "APPOINTMENT_GET_REQUEST_ITEM_START";
export const APPOINTMENT_GET_REQUEST_ITEM_SUCCESS = "APPOINTMENT_GET_REQUEST_ITEM_SUCCESS";
export const APPOINTMENT_GET_REQUEST_ITEM_ERROR = "APPOINTMENT_GET_REQUEST_ITEM_ERROR";

export const APPOINTMENT_CREATE_REQUEST_ITEM_START = "APPOINTMENT_CREATE_REQUEST_ITEM_START";
export const APPOINTMENT_CREATE_REQUEST_ITEM_SUCCESS = "APPOINTMENT_CREATE_REQUEST_ITEM_SUCCESS";
export const APPOINTMENT_CREATE_REQUEST_ITEM_ERROR = "APPOINTMENT_CREATE_REQUEST_ITEM_ERROR";

export const APPOINTMENT_UPDATE_REQUEST_ITEM_START = "APPOINTMENT_UPDATE_REQUEST_ITEM_START";
export const APPOINTMENT_UPDATE_REQUEST_ITEM_SUCCESS = "APPOINTMENT_UPDATE_REQUEST_ITEM_SUCCESS";
export const APPOINTMENT_UPDATE_REQUEST_ITEM_ERROR = "APPOINTMENT_UPDATE_REQUEST_ITEM_ERROR";

export const APPOINTMENT_DELETE_REQUEST_ITEM_START = "APPOINTMENT_DELETE_REQUEST_ITEM_START";
export const APPOINTMENT_DELETE_REQUEST_ITEM_SUCCESS = "APPOINTMENT_DELETE_REQUEST_ITEM_SUCCESS";
export const APPOINTMENT_DELETE_REQUEST_ITEM_ERROR = "APPOINTMENT_DELETE_REQUEST_ITEM_ERROR";

export const APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_START =
  "APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_START";
export const APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_SUCCESS =
  "APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_SUCCESS";
export const APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_ERROR =
  "APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_ERROR";

export const APPOINTMENT_DIAGNOSIS_CODE_LIST_START = "APPOINTMENT_DIAGNOSIS_CODE_LIST_START";
export const APPOINTMENT_DIAGNOSIS_CODE_LIST_SUCCESS = "APPOINTMENT_DIAGNOSIS_CODE_LIST_SUCCESS";
export const APPOINTMENT_DIAGNOSIS_CODE_LIST_ERROR = "APPOINTMENT_DIAGNOSIS_CODE_LIST_ERROR";

export const APPOINTMENT_TREATMENT_CODE_LIST_START = "APPOINTMENT_TREATMENT_CODE_LIST_START";
export const APPOINTMENT_TREATMENT_CODE_LIST_SUCCESS = "APPOINTMENT_TREATMENT_CODE_LIST_SUCCESS";
export const APPOINTMENT_TREATMENT_CODE_LIST_ERROR = "APPOINTMENT_TREATMENT_CODE_LIST_ERROR";

export const EARLIER_APPOINTMENT_LIST_START = "EARLIER_APPOINTMENT_LIST_START";
export const EARLIER_APPOINTMENT_LIST_SUCCESS = "EARLIER_APPOINTMENT_LIST_SUCCESS";
export const EARLIER_APPOINTMENT_LIST_ERROR = "EARLIER_APPOINTMENT_LIST_ERROR";

export const PATIENT_GET_DETAILS_INFO_START = "PATIENT_GET_DETAILS_INFO_START";
export const PATIENT_GET_DETAILS_INFO_SUCCESS = "PATIENT_GET_DETAILS_INFO_SUCCESS";
export const PATIENT_GET_DETAILS_INFO_ERROR = "PATIENT_GET_DETAILS_INFO_ERROR";

export const CLEAR_APPOINTMENT = "CLEAR_APPOINTMENT";
export const SET_APPOINTMENT_PATIENT_EMIRATES_ID = "SET_APPOINTMENT_PATIENT_EMIRATES_ID";
export const SET_APPOINTMENT_ADDITIONAL_DOCTORS = "SET_APPOINTMENT_ADDITIONAL_DOCTORS";
export const SET_APPOINTMENT_TREATMENTS_PLAN_VALUES = "SET_APPOINTMENT_TREATMENTS_PLAN_VALUES";

export const SET_APPOINTMENT_GUARDIAN_INFORMED_START = "SET_APPOINTMENT_GUARDIAN_INFORMED_START";
export const SET_APPOINTMENT_GUARDIAN_INFORMED_SUCCESS =
  "SET_APPOINTMENT_GUARDIAN_INFORMED_SUCCESS";
export const SET_APPOINTMENT_GUARDIAN_INFORMED_ERROR = "SET_APPOINTMENT_GUARDIAN_INFORMED_ERROR";

export const GET_APPOINTMENT_MEETING_TYPE_START = "GET_APPOINTMENT_MEETING_TYPE_START";
export const GET_APPOINTMENT_MEETING_TYPE_SUCCESS = "GET_APPOINTMENT_MEETING_TYPE_SUCCESS";
export const GET_APPOINTMENT_MEETING_TYPE_ERROR = "GET_APPOINTMENT_MEETING_TYPE_ERROR";

export const GET_APPOINTMENT_PAYMENT_TYPE_START = "GET_APPOINTMENT_PAYMENT_TYPE_START";
export const GET_APPOINTMENT_PAYMENT_TYPE_SUCCESS = "GET_APPOINTMENT_PAYMENT_TYPE_SUCCESS";
export const GET_APPOINTMENT_PAYMENT_TYPE_ERROR = "GET_APPOINTMENT_PAYMENT_TYPE_ERROR";

export const GET_APPOINTMENT_REMINDER_LOG_START = "GET_APPOINTMENT_REMINDER_LOG_START";
export const GET_APPOINTMENT_REMINDER_LOG_SUCCESS = "GET_APPOINTMENT_REMINDER_LOG_SUCCESS";
export const GET_APPOINTMENT_REMINDER_LOG_ERROR = "GET_APPOINTMENT_REMINDER_LOG_ERROR";

export const SET_REQUESTED_APPOINTMENT_ITEM = "SET_REQUESTED_APPOINTMENT_ITEM";
export const SET_REQUESTED_APPOINTMENT_ITEM_PATIENT = "SET_REQUESTED_APPOINTMENT_ITEM_PATIENT";

export const UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_START =
  "UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_START";
export const UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_SUCCESS =
  "UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_SUCCESS";
export const UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_ERROR =
  "UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_ERROR";

export const UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_START =
  "UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_START";
export const UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_SUCCESS =
  "UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_SUCCESS";
export const UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_ERROR =
  "UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_ERROR";

export const UPDATE_APPOINTMENT_ITEM_LOCATION_START = "UPDATE_APPOINTMENT_ITEM_LOCATION_START";
export const UPDATE_APPOINTMENT_ITEM_LOCATION_SUCCESS = "UPDATE_APPOINTMENT_ITEM_LOCATION_SUCCESS";
export const UPDATE_APPOINTMENT_ITEM_LOCATION_ERROR = "UPDATE_APPOINTMENT_ITEM_LOCATION_ERROR";

export const SET_DELETE_APPOINTMENT_DELETE_OPTIONS = "UPDATE_APPOINTMENT_ITEM_LOCATION_ERROR";
export const SET_MISSED_APPOINTMENT_OPTIONS = "SET_MISSED_APPOINTMENT_OPTIONS";
export const SET_COMPANY_TEMP_PATIENT_ID = "SET_COMPANY_TEMP_PATIENT_ID";

export const GET_APPOINTMENT_PLANNED_TREATMENT_LIST_START =
  "GET_APPOINTMENT_PLANNED_TREATMENT_LIST_START";
export const GET_APPOINTMENT_PLANNED_TREATMENT_LIST_SUCCESS =
  "GET_APPOINTMENT_PLANNED_TREATMENT_LIST_SUCCESS";
export const GET_APPOINTMENT_PLANNED_TREATMENT_LIST_ERROR =
  "GET_APPOINTMENT_PLANNED_TREATMENT_LIST_ERROR";

export const SET_APPOINTMENT_PLANNED_TREATMENT_MODAL_VISIBLE =
  "SET_APPOINTMENT_PLANNED_TREATMENT_MODAL_VISIBLE";

export const TOGGLE_PLANNED_TREATMENT = "TOGGLE_PLANNED_TREATMENT";
export const TOGGLE_PLANNED_TREATMENT_ALL = "TOGGLE_PLANNED_TREATMENT_ALL";
export const SAVE_CONSUMED_CANCELLED_TREATMENTS_START = "SAVE_CONSUMED_CANCELLED_TREATMENTS_START";
export const SAVE_CONSUMED_CANCELLED_TREATMENTS_SUCCESS =
  "SAVE_CONSUMED_CANCELLED_TREATMENTS_SUCCESS";
export const SAVE_CONSUMED_CANCELLED_TREATMENTS_ERROR = "SAVE_CONSUMED_CANCELLED_TREATMENTS_ERROR";

export const SAVE_DRAG_APPOINTMENT_START = "SAVE_DRAG_APPOINTMENT_START";
export const SAVE_DRAG_APPOINTMENT_SUCCESS = "SAVE_DRAG_APPOINTMENT_SUCCESS";
export const SAVE_DRAG_APPOINTMENT_ERROR = "SAVE_DRAG_APPOINTMENT_ERROR";
