import React, { useState } from "react";
import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import ChartSideSearch from "../chart/side-bar/ChartSideSearch";
import { PatientFormsAddModalItem } from "./PatientFormsAddModalItem";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { Modal } from "../ui/Modal";
import { SelectPicker } from "../select-picker/SelectPicker";
import DataList from "../widgets/DataList";
import PatientFormsAddModalCategoryItem from "./PatientFormsAddModalCategoryItem";

const enhancer = connect(null, bindActions({ patientRegisterActions }));

class PatientFormsDocFieldsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: "",
      searchValue: "",
    };
  }

  componentDidMount() {
    this.props.patientRegisterActions.getAddConsentFormList();
    this.props.patientRegisterActions.getConsentFormCategoryList();
  }

  loadList = (e) => {
    const { categoryId } = this.state;

    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.patientRegisterActions.loadAddConsentFormList(categoryId);
    }
  };

  onSearch = (event) => {
    this.props.patientRegisterActions.setSearchValueList(event.target.value);
    this.setState({ searchValue: event.target.value });
  };

  addConsentForm = (item, closeAfter) => {
    this.props.patientRegisterActions.addConsentForm(item, this.props.patientKey, closeAfter);
  };

  changeCategory = (id) => {
    const { patientRegister, patientRegisterActions } = this.props;

    this.setState({ categoryId: id });

    patientRegisterActions.setSearchValueList(patientRegister.searchValueAddConsentForm, id);
  };

  render() {
    const { categoryId, searchValue } = this.state;
    const { patientRegister, patientRegisterActions } = this.props;

    const categoryList = patientRegister.consentFormCategoryList.map((x) => ({
      value: x.id,
      label: x.name,
    }));

    return (
      <Modal
        bodyProps={{ style: { padding: 0 } }}
        show={true}
        animation={false}
        keyboard={false}
        onHide={patientRegisterActions.closeAddModal}
        dialogClassName="chart-side-bar"
      >
        <div className="chart-series">
          <div className="chart-side-header">
            <div className="clearfix">
              <div className="col-sm-4">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={patientRegisterActions.closeAddModal}
                >
                  ❮ BACK
                </button>
              </div>
              <div className="col-sm-8">
                <div className="chart-clinical-note-title" style={{ lineHeight: "34px" }}>
                  CONSENT FORMS
                </div>
              </div>
            </div>
          </div>
          <div
            className="chart-side-body"
            onScroll={() => patientRegisterActions.loadAddConsentFormList(categoryId)}
          >
            <ChartSideSearch
              value={patientRegister.searchValueAddConsentForm}
              onChange={this.onSearch}
            />

            {/*<div style={{ margin: "4px", marginTop: 0 }}>*/}
            {/*  <SelectPicker*/}
            {/*    disabled={patientRegister.fetchingConsentFormCategoryList}*/}
            {/*    options={[...[{ value: "", label: "No category" }], ...categoryList]}*/}
            {/*    onChange={(x) => this.changeCategory(x.value)}*/}
            {/*    value={{ value: categoryId }}*/}
            {/*  />*/}
            {/*</div>*/}

            {searchValue.length > 0 ? (
              <DataList
                fetching={patientRegister.fetchingAddConsentForm}
                // onScroll={() => patientRegisterActions.loadAddConsentFormList(categoryId)}
              >
                {patientRegister.addConsentFormList.map((x) => (
                  <PatientFormsAddModalItem
                    key={x.id}
                    title={x.name}
                    onContentClick={() => this.addConsentForm(x)}
                    onPlusClick={() => this.addConsentForm(x, false)}
                  />
                ))}
              </DataList>
            ) : (
              <div style={{ marginRight: 10 }}>
                {patientRegister.consentFormCategoryList.map((item, index) => (
                  <PatientFormsAddModalCategoryItem
                    key={index}
                    item={item}
                    consentFormList={patientRegister.addConsentFormList}
                    fetchingAddConsentForm={patientRegister.fetchingAddConsentForm}
                    categoryId={categoryId}
                    patientRegisterActions={patientRegisterActions}
                    addConsentForm={this.addConsentForm}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default enhancer(PatientFormsDocFieldsModal);
