import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import bindActions from "../../../helpers/bindActions";
import * as chartSideDocsActions from "../../../actions/chartSideDocsActions";

const enhancer = connect(null, bindActions({ chartSideDocsActions }));

class ChartDocsConfirmDeleteModal extends React.PureComponent {
  render() {
    return (
      <Modal
        size="small"
        show={this.props.confirmDeleteModalVisible}
        onHide={this.props.chartSideDocsActions.closeDeleteModal}
        keyboard={false}
        actions={
          <div>
            <Button
              bsStyle="danger"
              onClick={() => {
                this.props.chartSideDocsActions.deleteFiles();
                this.props.chartSideDocsActions.closeDeleteModal();
              }}
            >
              Delete
            </Button>
            <Button onClick={this.props.chartSideDocsActions.closeDeleteModal}>Cancel</Button>
          </div>
        }
      >
        <div>Do you really want to delete selected images? The process is undone.</div>
      </Modal>
    );
  }
}

export default enhancer(ChartDocsConfirmDeleteModal);
