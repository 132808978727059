import _, { noop } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Modal } from "../ui/Modal";

import BackButton from "../widgets/BackButton";
import { hideRequestLabOrderModal } from "../../actions/chartActions";
import { RequestLabOrderForm } from "./RequestLabOrderForm";
import DataList from "../widgets/DataList";

const enhancer = connect(
  ({ chart: { patient, patientGenderList = [], showRequestLabOrderModal } }) => ({
    showRequestLabOrderModal,
    patientGenderList: patientGenderList.map((x) => ({
      value: x.id,
      label: x.name,
    })),
    initialValues: {
      gender: _.get(patient, "gender.id"),
      name: _.get(patient, "fullName", ""),
      chartNumber: _.get(patient, "chartNumber", ""),
    },
  }),
  { hideRequestLabOrderModal },
);

export const RequestLabOrder = enhancer(
  ({ initialValues, patientGenderList, showRequestLabOrderModal, hideRequestLabOrderModal }) => {
    return (
      <Modal
        dialogClassName="chart-side-bar"
        animation={false}
        show={showRequestLabOrderModal}
        onHide={hideRequestLabOrderModal}
      >
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header d-flex">
            <BackButton text="Back" className="btn-sm" onClick={hideRequestLabOrderModal} />
            <div
              style={{ color: "#ffffff" }}
              className="d-flex flex-grow-1 flex-shrink-1 align-items-center justify-content-center"
            >
              Request Lab Order
            </div>
            <BackButton
              text="Back"
              style={{ opacity: 0 }}
              className="btn-sm"
              onClick={hideRequestLabOrderModal}
            />
          </div>
          <DataList className="chart-side-body">
            <RequestLabOrderForm
              onSubmit={noop}
              patientGenderList={patientGenderList}
              initialValues={initialValues}
            />
          </DataList>
        </div>
      </Modal>
    );
  },
);
