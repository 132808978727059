import React from "react";
import { connect } from "react-redux";

import { initChooseClinic, chooseClinic, adminLogin } from "../../actions/authActions";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import BackGroundImage from "../../assets/images/select_clinic_bg_image.png";
import FormBackGroundImage from "../../assets/images/clinic_bg.png";
import { obtainImagePath } from "../../utils/ImageUtils";

const enhancer = connect(
  ({ auth: { clinics, baseUrl, user } }) => {
    return { clinics, baseUrl, user };
  },
  { initChooseClinic, chooseClinic, adminLogin },
);
export default withRouter(
  enhancer(
    class ChooseClinic extends React.Component {
      componentDidMount() {
        const host = localStorage.getItem("host");
        if (this.props?.baseUrl && this.props.baseUrl + "/" != host) {
          localStorage.setItem("host", this.props.baseUrl);
          this.props
            .adminLogin(this.props.user)
            .then((res) => res && this.props.initChooseClinic());
        } else {
          this.props.initChooseClinic();
        }
      }

      handleSubmit = (event) => {
        event.preventDefault();

        const transfer = {
          clinic: { id: event.target.clinic.value },
          name: event.target.deviceName.value,
        };

        this.props.chooseClinic(transfer).then((response) => {
          if (response && response.data) {
            this.props.history.push(Routes.AuthMemberLogin);
          }
        });
      };

      render() {
        const $options = this.props.clinics.map((clinic) => (
          <option key={clinic.id} value={clinic.id}>
            {clinic.name}
          </option>
        ));

        return (
          <div
            className="choose-clinic"
            style={{ backgroundImage: `url(${obtainImagePath(BackGroundImage)})` }}
          >
            <div className="choose-clinic-title">Welcome</div>
            <form
              onSubmit={this.handleSubmit}
              style={{ backgroundImage: `url(${FormBackGroundImage})` }}
            >
              <div className="title">please, assign this device to one of your clinic</div>
              <div className="inputs">
                <div className="form-group form-group-lg">
                  <select name="clinic" required={true} className="form-control">
                    <option>Please Select</option>
                    {$options}
                  </select>
                </div>
                <div className="form-group form-group-lg">
                  <label htmlFor="deviceName" className="control-label">
                    Device name:
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    id="deviceName"
                    type="text"
                    name="deviceName"
                    placeholder="Device Name"
                  />
                </div>
                <div className="button">
                  <button type="submit" className="btn btn-lg btn-block btn-success">
                    Done
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      }
    },
  ),
);
