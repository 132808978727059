import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import { connect } from "react-redux";

import { setPopupActionByKey, setPopupParams } from "../../actions/popupActions";
import { PopupType } from "../../constants/Constants";

const enhancer = connect(
  ({ popup }) => {
    return {
      popup,
    };
  },
  { setPopupActionByKey, setPopupParams },
);
class LargeModal extends React.Component {
  hideMethod = () => {
    this.props.setPopupActionByKey(PopupType.Large, "visible", false);
  };

  render() {
    const { popup, children } = this.props;
    const large = popup.large;
    // eslint-disable-next-line no-unused-vars

    if (!large || !large.self || !large.self.visible) {
      return null;
    }

    let $buttons = [];
    if (large && large.self && large.self.visible) {
      if (large.buttons && large.buttons.length) {
        large.buttons.map((button) => {
          if (button.visible) {
            $buttons.push(
              <Button onClick={button.handler} className="btn btn-lg btn-success">
                {button.value}
              </Button>,
            );
          }
        });
      }

      return (
        <Modal
          dialogClassName="select-treatment-modal"
          show={true}
          onHide={this.hideMethod}
          animation={true}
          keyboard={false}
          title={<div>Select treatments to submit for approval</div>}
          actions={
            <div>
              <button className="btn btn-lg btn-default" onClick={this.hideMethod}>
                Cancel
              </button>
              {$buttons}
            </div>
          }
        >
          <div>{children}</div>
        </Modal>
      );
    }
    return null;
  }
}
export default enhancer(LargeModal);
