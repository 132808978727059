import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import FlexBox from "../ui/FlexBox";
import BackButton from "../widgets/BackButton";
import MemberImage from "../widgets/MemberImage";
import { getPatientTerminalDetails } from "../../actions/patientViewActions";
import { getAppointmentById, statusChangeOfAppointment } from "../../actions/appointmentActions";
import { TerminalButton } from "./TerminalButton";
import Utils from "../../helpers/Utils";

import { STATUS_TYPES } from "../../constants/StatusTypes";
import { Routes } from "../../constants/Routes";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { TerminalCard } from "./TerminalCard";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../../constants/Constants";
import moment from "moment";
import LogoImage from "../../assets/images/clinic_logo.png";
import MyProfile from "../../assets/images/profile/my_profile@2x.png";
import TakeSurvey from "../../assets/images/profile/take_survey@2x.png";
import complaintImage from "../../assets/images/profile/write_complaint@2x.png";
import feedbackImage from "../../assets/images/profile/write_feedback@2x.png";

const NA = "N/A";

const enhancer = connect(
  (state) => {
    const list = _.get(state, "patientView.patientTerminalDetails.appointments.list", []);
    const appointmentList = {};

    list.forEach((item) => {
      appointmentList[_.get(item, "status.code", "withoutStatus")] = {
        ...appointmentList[_.get(item, "status.code", "withoutStatus")],
        [item.id]: item,
      };
    });

    return {
      appointmentList,
      patientDetails: _.get(state, "patientView.patientTerminalDetails"),
    };
  },
  {
    getPatientTerminalDetails,
    getAppointmentById,
    statusChangeOfAppointment,
  },
);

export const PatientTerminal = enhancer(
  class PatientTerminal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showList: false,
      };
    }

    componentDidMount() {
      const {
        match: { params },
        getAppointmentById,
        location,
        getPatientTerminalDetails,
      } = this.props;

      const query = parseQuery(location.search);

      if (params.patientKey) {
        getPatientTerminalDetails({ patientKey: params.patientKey });
      }

      if (query.appointmentId) {
        getAppointmentById(query.getAppointmentById);
      }
    }

    render() {
      const {
        patientDetails,
        history,
        match: { params },
        appointmentList,
        statusChangeOfAppointment,
      } = this.props;
      let appointment = {};

      if (
        !_.isEmpty(appointmentList[STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED]) ||
        !_.isEmpty(appointmentList[STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2])
      ) {
        const currentTime = new Date();
        const confirmedTime = Utils.toCompanyTimezone(currentTime.getTime());

        _.forEach(_.get(appointmentList, STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED, {}), (item) => {
          if (
            confirmedTime - _.get(item, "startTime", 0) <= 30 * 60 * 1000 ||
            confirmedTime - _.get(item, "startTime", 0) >= 30 * 60 * 1000
          ) {
            appointment = item;
          }
        });
        _.forEach(
          _.get(appointmentList, STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2, {}),
          (item) => {
            if (
              confirmedTime - _.get(item, "startTime", 0) <= 30 * 60 * 1000 ||
              confirmedTime - _.get(item, "startTime", 0) >= 30 * 60 * 1000
            ) {
              appointment = item;
            }
          },
        );
      }

      return (
        <FlexBox flex={true} container={16} className="patient-terminal-root">
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox className="header">
              <BackButton
                className="btn-lg"
                text="DNDCalendar"
                onClick={() => history.push(Routes.DashboardCalendar)}
              />
              <img alt="" src={LogoImage} />
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox gutter={8} flex={true}>
                <FlexBox className="left-column">
                  <FlexBox gutter={8} flex={true} direction="column">
                    <FlexBox flex={true}>
                      <FlexBox flex={true} className="patient-card" direction="column">
                        <FlexBox gutter={8} flex={true} direction="column">
                          <FlexBox flex={true} justify="center" align="center">
                            <MemberImage photoUrl={_.get(patientDetails, "photo.url")} />
                          </FlexBox>

                          <FlexBox className="button-container" direction="column">
                            <TerminalButton
                              title="My Profile"
                              imageUrl={MyProfile}
                              onClick={() =>
                                history.push(
                                  createUrl(Routes.PatientProfile, {
                                    params: { patientKey: params.patientKey },
                                  }),
                                )
                              }
                            />
                          </FlexBox>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FlexBox gutter={8} flex={true} direction="column">
                        <FlexBox flex={true}>
                          <FlexBox className="card-forms" flex={true} direction="column">
                            <FlexBox className="button-container" direction="column" flex={true}>
                              <TerminalButton title="Forms To Sign" imageUrl={TakeSurvey} />
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>

                        <FlexBox flex={true}>
                          <FlexBox className="card-feedback" flex={true} direction="column">
                            <FlexBox className="button-container" direction="column" flex={true}>
                              <TerminalButton title="Write Feedback" imageUrl={feedbackImage} />
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>

                        <FlexBox flex={true}>
                          <FlexBox className="card-complaint" flex={true} direction="column">
                            <FlexBox className="button-container" direction="column" flex={true}>
                              <TerminalButton title="Write Complaint" imageUrl={complaintImage} />
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true} direction="column">
                    <FlexBox flex={true}>
                      <TerminalCard
                        className="card-details"
                        onActionClick={() =>
                          history.push(
                            createUrl(Routes.PatientProfile, {
                              query: {
                                patientKey: params.patientKey,
                              },
                            }),
                          )
                        }
                      >
                        {!_.isEmpty(patientDetails) && (
                          <FlexBox
                            gutter={8}
                            flex={true}
                            direction="column"
                            className="card-content"
                          >
                            <FlexBox className="details-name">
                              {_.get(patientDetails, "patientDetails.fullName", NA)}
                            </FlexBox>
                            <FlexBox>Mobile Number:</FlexBox>
                            <FlexBox className="details-value">
                              {_.get(patientDetails, "patientDetails.mobilePhoneNumber", NA)}
                            </FlexBox>
                            <FlexBox>Weight:</FlexBox>
                            <FlexBox className="details-value">
                              {_.get(patientDetails, "patientDetails.weight", NA)}
                            </FlexBox>
                            <FlexBox>Emergency Contact Person</FlexBox>
                            <FlexBox className="details-value">
                              <FlexBox gutter={8} flex={true} direction="column">
                                {_.get(patientDetails, "patientDetails.emergencyContacts")
                                  ? _.get(
                                      patientDetails,
                                      "patientDetails.emergencyContacts",
                                      [],
                                    ).map((item, idx) => (
                                      <FlexBox key={idx}>
                                        {idx + 1}.{" "}
                                        {_.get(item, "lastName", "") +
                                          " " +
                                          _.get(item, "firstName", "")}
                                      </FlexBox>
                                    ))
                                  : NA}
                              </FlexBox>
                            </FlexBox>
                            <FlexBox>Balance:</FlexBox>
                            <FlexBox className="details-value">
                              {_.get(patientDetails, "patientDetails.openingBalance", NA)}
                            </FlexBox>
                            <FlexBox>Head of Household:</FlexBox>
                            <FlexBox className="details-value">
                              {_.get(patientDetails, "patientDetails.patientHouseHold")
                                ? _.get(
                                    patientDetails,
                                    "patientDetails.patientHouseHold.lastName",
                                    "",
                                  ) +
                                  " " +
                                  _.get(
                                    patientDetails,
                                    "patientDetails.patientHouseHold.firstName",
                                    "",
                                  )
                                : NA}
                            </FlexBox>
                          </FlexBox>
                        )}
                      </TerminalCard>
                    </FlexBox>

                    <FlexBox flex={true}>
                      <TerminalCard
                        title="Patient Alerts"
                        className="card-patient-alerts"
                        onActionClick={() =>
                          history.push(
                            createUrl(Routes.PatientMedicalAlertsEdit, {
                              query: {
                                patientKey: params.patientKey,
                              },
                            }),
                          )
                        }
                      >
                        {_.get(patientDetails, "medicalAlerts.alerts", []).map((item, idx) => (
                          <FlexBox flex="none" key={idx}>
                            {`${_.get(item, "medicalName", "")} ${_.get(item, "note", "")}`}
                          </FlexBox>
                        ))}
                      </TerminalCard>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true} direction="column">
                    <FlexBox flex={true}>
                      <TerminalCard
                        actionTitle="Check In"
                        className="card-appointment"
                        actionClassName="btn-danger text-upper"
                        onActionClick={
                          !this.state.showList && !_.isEmpty(appointment)
                            ? () => {
                                if (
                                  _.get(appointment, "status.code") ===
                                    STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
                                  _.get(appointment, "status.code") ===
                                    STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2
                                ) {
                                  statusChangeOfAppointment(
                                    {
                                      id: _.get(appointment, "id"),
                                      patientKey: _.get(appointment, "patient.patientKey"),
                                      status: STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
                                    },
                                    true,
                                  ).then((response) => {
                                    if (response && response.data) {
                                      this.props.history.push(Routes.DashboardCalendar);
                                    }
                                  });
                                } else if (
                                  _.get(appointment, "status.code") ===
                                  STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN
                                ) {
                                  this.setState({ showList: true });
                                }
                              }
                            : _.noop()
                        }
                      >
                        {!this.state.showList &&
                        !_.isEmpty(appointmentList) &&
                        !_.isEmpty(appointment) ? (
                          <FlexBox flex={true}>
                            <FlexBox gutter={8} flex={true} direction="column">
                              <FlexBox className="appointment-title">Appointment With:</FlexBox>
                              <FlexBox className="appointment-item">
                                {_.get(appointment, "dentist.name", NA)}
                              </FlexBox>
                              <FlexBox className="appointment-item">
                                Date:{" "}
                                {_.get(appointment, "date")
                                  ? moment(_.get(appointment, "date")).format(DATE_FORMAT_LEGACY)
                                  : NA}
                              </FlexBox>
                              <FlexBox className="appointment-item">
                                Time:{" "}
                                {_.get(appointment, "date")
                                  ? moment(_.get(appointment, "date")).format(TIME_FORMAT)
                                  : NA}
                              </FlexBox>
                              <FlexBox className="appointment-item">
                                Room: {_.get(appointment, "dentalPoint.name", NA)}
                              </FlexBox>
                            </FlexBox>
                          </FlexBox>
                        ) : (
                          _.map(appointmentList, (item, idx) => (
                            <FlexBox flex="none" key={idx}>
                              {Utils.calcDateWithTimeZone(item.startTime).format(
                                "DD/MM/YYYY, hh:mm a",
                              )}
                            </FlexBox>
                          ))
                        )}
                      </TerminalCard>
                    </FlexBox>

                    <FlexBox flex={true}>
                      <TerminalCard
                        title="Medication Alerts"
                        className="card-medication-alerts"
                        onActionClick={() =>
                          history.push(
                            createUrl(Routes.PatientMedicationAlertsEdit, {
                              query: {
                                patientKey: params.patientKey,
                              },
                            }),
                          )
                        }
                      >
                        {_.get(patientDetails, "medicationAlerts.alerts", []).map((item, idx) => (
                          <FlexBox flex="none" key={idx}>
                            {`${_.get(item, "medicationName", "")} - ${_.get(
                              item,
                              "dosage",
                              "",
                            )} - ${_.get(item, "unit.name", "")}`}
                          </FlexBox>
                        ))}
                      </TerminalCard>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      );
    }
  },
);
