import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { ButtonGroup } from "react-bootstrap";
import Back from "../../widgets/Back";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import bindActions from "../../../helpers/bindActions";
import PatientClaimsList from "./PatientClaimsList";
import PatientApprovalsList from "./PatientApprovalsList";
import logoImg from "../../../assets/images/clinic_logo.png";
import { parseQuery } from "../../../utils/UrlUtils";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { useClinicListQuery } from "../../../queries/clinic/useClinicListQuery";

const enhancer = connect(
  ({ patientRegister: { claimsApprovalsActiveTab }, session: { clinic: currentClinic } }) => ({
    claimsApprovalsActiveTab,
    currentClinic,
  }),
  bindActions({ patientRegisterActions }),
);
const PatientClaimsApprovals = ({
  location,
  history,
  claimsApprovalsActiveTab,
  patientRegisterActions,
  currentClinic,
}) => {
  const [clinic, setClinic] = useState({ value: currentClinic.id, label: currentClinic.name });
  const patientChart = (location && parseQuery(location.search)) || false;
  const { changeClaimsApprovalsTab } = patientRegisterActions;
  const clinicQuery = useClinicListQuery();

  const list = useMemo(() => {
    const clinicList = clinicQuery.data || [];
    const newList = [];
    return newList.concat(clinicList.map((v) => ({ value: v.id, label: v.name })));
  }, [clinicQuery.data]);

  return (
    <div className="patient-appointments-list" style={{ height: "100%" }}>
      <div className="default-page-title clearfix text-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          <Back className="" />
          <img src={logoImg} alt="logo" className="" style={{ height: "34px" }} />
          <div className="claims-filter-field d-flex align-items-center ">
            <div className="mr-1">Clinic:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                width: 150,
                textAlign: "initial",
              }}
            >
              <SelectPicker
                placeholder="Clinic"
                value={{ value: clinic?.value }}
                options={list}
                style={{ width: 150 }}
                onChange={(value) => {
                  setClinic(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "15px" }}>
        <ButtonGroup aria-label="claims tab" className="d-flex align-items-center">
          <button
            onClick={() => changeClaimsApprovalsTab("approvals")}
            className={`btn btn-block m-0 ${
              claimsApprovalsActiveTab === "approvals" ? "btn-primary" : "btn-default"
            }`}
          >
            Approvals
          </button>
          {patientChart && (
            <button
              onClick={() => changeClaimsApprovalsTab("claims")}
              className={`btn btn-block m-0 ${
                claimsApprovalsActiveTab === "claims" ? "btn-primary" : "btn-default"
              }`}
            >
              Claims
            </button>
          )}
        </ButtonGroup>
      </div>
      {claimsApprovalsActiveTab === "claims" ? (
        <PatientClaimsList patientKey={patientChart && patientChart.patientKey} clinic={clinic} />
      ) : (
        <PatientApprovalsList
          patientKey={patientChart && patientChart.patientKey}
          history={history}
          clinic={clinic}
        />
      )}
    </div>
  );
};

export default enhancer(PatientClaimsApprovals);
