import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "../../constants/Routes";
import MemberImage from "../widgets/MemberImage";
import { createUrl } from "../../utils/UrlUtils";

export function WaitingListCell({ element, onNoteClick }) {
  const appointmentsName = `${element.patient.firstName} ${element.patient.lastName}`;
  const appointmentsDoctor = element.dentist.name;
  const appointmentsPhoto = !_.isEmpty(element.patient.photo) && element.patient.photo.url;
  let appointmentsListWaiting = "";
  let appointmentsSource = "waiting-list-cell-body";

  if (
    !_.isEmpty(element.createdSource) &&
    (element.createdSource === "FROM_DENTAL_CHART" ||
      element.createdSource === "FROM_REQUEST_APPOINTMENT")
  ) {
    appointmentsSource += " waiting-list-requested";
  } else if (!_.isEmpty(element.createdSource) && element.createdSource === "FROM_REFERRAL") {
    appointmentsSource += " waiting-list-referral";
    appointmentsListWaiting = "link-referral";
  } else {
    appointmentsSource += " waiting-list-waiting";
    appointmentsListWaiting = "link-waiting";
  }

  return (
    <div className="waiting-list-cell">
      <Link
        to={createUrl(Routes.DashboardAppointment, {
          params: {
            id: element.id,
          },
          query: {
            goBack: true,
          },
        })}
        key={element.id}
        className={appointmentsListWaiting}
      >
        <div className={appointmentsSource}>
          <div className="member-container">
            <MemberImage photoUrl={appointmentsPhoto} size={70} />
          </div>
          <span className="waiting-list-name">{appointmentsName}</span>
          <span className="waiting-list-doctor">{appointmentsDoctor}</span>
          {element.note && (
            <span
              className="waiting-list-cell-note"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onNoteClick(element.note);
              }}
            >
              Note
            </span>
          )}
        </div>
      </Link>
    </div>
  );
}
