import React from "react";
import ReactSpinner from "react-spinjs-fix";
import moment from "moment";

import Show from "../../widgets/Show";
import Back from "../../widgets/Back";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";

const enhancer = connect(
  ({
    patientRegister: {
      patientAlertsHistoryList,
      patientAlertsHistoryMode,
      patientAlertsHistoryUrl,
    },
  }) => ({
    patientAlertsHistoryList,
    patientAlertsHistoryMode,
    patientAlertsHistoryUrl,
  }),
  bindActions({ patientRegisterActions }),
);
class PatientAlertsHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEmbed: false,
    };
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.patientRegisterActions.loadPatientAlertsHistory();
    }
  };

  setUrl = (index) => {
    this.setState({ showEmbed: false });
    this.props.patientRegisterActions.setPatientAlertsHistoryUrl(index);
  };

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showEmbed: true });
    }, 0);
  }

  render() {
    const {
      patientAlertsHistoryList,
      patientAlertsHistoryMode,
      patientAlertsHistoryUrl,
    } = this.props;

    const title =
      (patientAlertsHistoryMode === "MEDICAL_ALERTS" && "Patient Medical Alerts") ||
      "Patient Medication Alerts";

    const dateList = patientAlertsHistoryList.map((item, index) => {
      let className = "patient-alerts-button-history";

      className += (item.selected && " selected") || "";

      return (
        <div
          key={index}
          className={className}
          onClick={() => {
            this.setUrl(index);
          }}
        >
          <span>{index + 1}</span>
          {moment(item.pdfCreatedDate).format(DATE_FORMAT_LEGACY)}
        </div>
      );
    });

    return (
      <div className="patient-alerts-view-history">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" />
          <div className="title alerts-history-title">{title}</div>
        </div>
        <div className="patient-alerts-view-history-body">
          <div className="patient-alerts-view-history-left-side" onScroll={this.loadList}>
            {dateList}
          </div>
          <div className="patient-alerts-view-history-right-side">
            <Show if={this.state.showEmbed}>
              <embed src={patientAlertsHistoryUrl} />
            </Show>
            <ReactSpinner />
          </div>
        </div>
      </div>
    );
  }
}
export default enhancer(PatientAlertsHistory);
