export const defaultColors = {
  Gold: {
    color: "rgb(254, 204, 51)",
    name: "Gold",
    code: "Gold",
  },
  Green: {
    color: "rgb(51, 204, 153)",
    name: "Green",
    code: "Green",
  },
  Blue: {
    color: "rgb(4, 204, 255)",
    name: "Blue",
    code: "Blue",
  },
  Navy: {
    color: "rgb(2, 102, 254)",
    name: "Navy",
    code: "Navy",
  },
  Magenta: {
    color: "rgb(255, 153, 254)",
    name: "Magenta",
    code: "Magenta",
  },
  Red: {
    color: "rgb(255, 11, 101)",
    name: "Red",
    code: "Red",
  },
  Crimson: {
    color: "rgb(196, 111, 123)",
    name: "Crimson",
    code: "Crimson",
  },
  Khaki: {
    color: "rgb(204, 204, 153)",
    name: "Khaki",
    code: "Khaki",
  },
  Gray: {
    color: "rgb(204, 204, 204)",
    name: "Gray",
    code: "Gray",
  },
  Yellow: {
    color: "rgb(255, 255, 0)",
    name: "Yellow",
    code: "Yellow",
  },
  Amber: {
    color: "#c9a845",
    name: "Amber",
    code: "Amber",
  },
  DesertSand: {
    color: "#e2d2ae",
    name: "DesertSand",
    code: "DesertSand",
  },
  Silver: {
    color: "#cdcdcd",
    name: "Silver",
    code: "Silver",
  },
};

export const defaultStatusColors = {
  APPOINTMENT_STATUS_UNCONFIRMED: {
    color: defaultColors.Amber,
    code: "APPOINTMENT_STATUS_UNCONFIRMED",
    name: "Unconfirmed",
  },
  APPOINTMENT_STATUS_IN_WAITING: {
    color: defaultColors.Blue,
    code: "APPOINTMENT_STATUS_IN_WAITING",
    name: "Waiting list",
  },
  APPOINTMENT_STATUS_CONFIRMED: {
    color: defaultColors.DesertSand,
    code: "APPOINTMENT_STATUS_CONFIRMED",
    name: "Confirmed",
  },
  APPOINTMENT_STATUS_CHECKED_IN: {
    color: defaultColors.Navy,
    code: "APPOINTMENT_STATUS_CHECKED_IN",
    name: "Checked In",
  },
  APPOINTMENT_STATUS_BEING_SEEN: {
    color: defaultColors.Magenta,
    code: "APPOINTMENT_STATUS_BEING_SEEN",
    name: "Being Seen",
  },
  APPOINTMENT_STATUS_WILL_CALL: {
    color: defaultColors.Khaki,
    code: "APPOINTMENT_STATUS_WILL_CALL",
    name: "Will call",
  },
  APPOINTMENT_STATUS_COMPLETE: {
    color: defaultColors.Gray,
    code: "APPOINTMENT_STATUS_COMPLETE",
    name: "Complete",
  },
  APPOINTMENT_STATUS_CANCELLED: {
    color: defaultColors.Red,
    code: "APPOINTMENT_STATUS_CANCELLED",
    name: "Cancelled",
  },
  APPOINTMENT_STATUS_MISSED: {
    color: defaultColors.Red,
    code: "APPOINTMENT_STATUS_MISSED",
    name: "Missed",
  },
  APPOINTMENT_STATUS_RESCHEDULED: {
    color: defaultColors.Green,
    code: "APPOINTMENT_STATUS_RESCHEDULED",
    name: "Rescheduled",
  },
  APPOINTMENT_STATUS_PARTIALLY_PAYED: {
    color: defaultColors.Green,
    code: "APPOINTMENT_STATUS_PARTIALLY_PAYED",
    name: "Partially Payed",
  },
};

export const defaultTypeColors = {
  General: {
    code: "General",
    name: "General",
    color: defaultColors.Green,
  },
  Consultation: {
    code: "Consultation",
    name: "Consultation",
    color: defaultColors.Green,
  },
  "Ortho New": {
    code: "Ortho New",
    name: "Ortho New",
    color: defaultColors.Green,
  },
  "Ortho Existing": {
    code: "Ortho Existing",
    name: "Ortho Existing",
    color: defaultColors.Green,
  },
  Endo: {
    code: "Endo",
    name: "Endo",
    color: defaultColors.Green,
  },
  Cleaning: {
    code: "Cleaning",
    name: "Cleaning",
    color: defaultColors.Green,
  },
  "Crowns & Bridges": {
    code: "Crowns & Bridges",
    name: "Crowns & Bridges",
    color: defaultColors.Green,
  },
  Implants: {
    code: "Implants",
    name: "Implants",
    color: defaultColors.Green,
  },
  "Second Opinion": {
    code: "Second Opinion",
    name: "Second Opinion",
    color: defaultColors.Green,
  },
  Surgery: {
    code: "Surgery",
    name: "Surgery",
    color: defaultColors.Green,
  },
  "Preventive Check up": {
    code: "Preventive Check up",
    name: "Preventive Check up",
    color: defaultColors.Green,
  },
  Emergency: {
    code: "Emergency",
    name: "Emergency",
    color: defaultColors.Green,
  },
  "additional cat": {
    code: "additional cat",
    name: "additional cat",
    color: defaultColors.Green,
  },
};

export const defaultBTStypeColors = {
  BLOCKED_TIMESLOT_TYPE_BREAK: {
    code: "BLOCKED_TIMESLOT_TYPE_BREAK",
    name: "Break",
    color: defaultColors.Silver,
  },
  BLOCKED_TIMESLOT_TYPE_MEETING: {
    code: "BLOCKED_TIMESLOT_TYPE_MEETING",
    name: "Meeting",
    color: defaultColors.Silver,
  },
  BLOCKED_TIMESLOT_TYPE_INTERVIEW: {
    code: "BLOCKED_TIMESLOT_TYPE_INTERVIEW",
    name: "Interview",
    color: defaultColors.Silver,
  },
  BLOCKED_TIMESLOT_TYPE_TRAINING: {
    code: "BLOCKED_TIMESLOT_TYPE_TRAINING",
    name: "Training",
    color: defaultColors.Silver,
  },
  BLOCKED_TIMESLOT_TYPE_ONLINE: {
    code: "BLOCKED_TIMESLOT_TYPE_ONLINE",
    name: "Online",
    color: defaultColors.Silver,
  },
};

export const other = {
  code: "other",
  name: "other",
  color: "rgb(51, 204, 153)",
};
