import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";

import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import { AppLayout } from "../app-layout/AppLayout";
import alertImage from "../../assets/images/profile/alert.png";
import medAlertsImage from "../../assets/images/profile/med_alerts.png";

export function PatientAlerts() {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <AppLayout withLogo={true} contentClassName="patient-view" onBackClick={() => history.goBack()}>
      <div className="patient-alerts-page">
        <div
          className="patient-alerts-page-alerts"
          onClick={() =>
            history.push(
              createUrl(Routes.PatientMedicalAlertsEdit, {
                query: { patientKey: match.params.patientKey },
              }),
            )
          }
        >
          <img src={alertImage} alt="" />
          Patient Alerts
        </div>
        <div
          className="patient-alerts-page-medications"
          onClick={() =>
            history.push(
              createUrl(Routes.PatientMedicationAlertsEdit, {
                query: { patientKey: match.params.patientKey },
              }),
            )
          }
        >
          <img src={medAlertsImage} alt="" />
          Current Medications List
        </div>
      </div>
    </AppLayout>
  );
}
