import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Show from "../../widgets/Show";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";

import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";
import fillInFormImage from "../../../assets/images/appointment/fillInForms.png";
import goalsImage from "../../../assets/images/appointment/goals_history@2x.png";
import balanceImage from "../../../assets/images/appointment/dollar@2x.png";
import mouthTrxImage from "../../../assets/images/chart/mouth@2x.png";
import insuranceImage from "../../../assets/images/chart/insurance_icon@2x.png";
import clinicalNoteImage from "../../../assets/images/chart/ClinicalNotes@2x.png";
import medicalFormImage from "../../../assets/images/chart/medical-form-icon.png";
import calendarImage from "../../../assets/images/chart/CalendarAppointments@2x.png";
import alertImage from "../../../assets/images/chart/alert_icon@2x.png";
import HistoryImage from "../../../assets/images/appointment/history@2x.png";
import { ReactComponent as HashtagIcon } from "../../../assets/images/chart/hashtag.svg";

import { Routes } from "../../../constants/Routes";
import { createUrl } from "../../../utils/UrlUtils";
import { obtainImagePath } from "../../../utils/ImageUtils";
import { onChangeNoteTab } from "../../../actions/chartSideClinicalNoteActions";
import { medicalFillInFormShowSideBar } from "../../../actions/chartSideMedicalFillInFormActions";
import {
  clearAppointment,
  getAppointmentEligibilityDetails,
} from "../../../actions/appointmentActions";

const enhancer = connect(
  null,
  bindActions({
    chartActions,
    onChangeNoteTab,
    medicalFillInFormShowSideBar,
    getAppointmentEligibilityDetails,
    clearAppointment,
  }),
);

const ChartLinks = (props) => {
  const [eligibilityStatus, setEligibilityStatus] = useState("");
  const { appointment } = props;

  useEffect(() => {
    return () => {
      props.clearAppointment();
    };
  });

  if (appointment?.id && appointment?.eligibilityIdPayer) {
    props
      .getAppointmentEligibilityDetails({
        appointmentId: appointment.id,
        eligibilityIdPayer: Number((appointment.eligibilityIdPayer || "").replace(/[^\d]/g, "")),
      })
      .then((data) => {
        if (data.data) setEligibilityStatus(data.data.eligibilityCheck.result ? "yes" : "no");
      });
  }

  return (
    <div className="links">
      <div className="link" title="Alert" onClick={props.chartActions.showAlerts}>
        <img src={alertImage} alt="" />
      </div>
      <Link
        to={createUrl(Routes.DashboardCalendar, { query: { saveSettings: true } })}
        // onClick={() => }
        title="DNDCalendar"
        className="link"
      >
        <img src={calendarImage} alt="" />
      </Link>
      <div
        className="link"
        title="Clinical Note"
        onClick={() => {
          props.onChangeNoteTab({
            code: "yourNotes",
            title: "My Notes",
          });
          props.chartActions.showSideBar(viewTypes.clinicalNote);
        }}
      >
        <img src={clinicalNoteImage} alt="" />
      </div>
      <Link
        to={createUrl(Routes.DashboardInsurance, {
          params: { patientKey: props.patient.patientKey },
        })}
        title="Insurance"
        className="link"
      >
        <img src={insuranceImage} alt="" />
      </Link>
      <Show if={props.mouthLinkVisible}>
        <div
          className="link"
          title="Mouth Trx"
          onClick={() => {
            props.chartActions.showSideBar(viewTypes.series);
          }}
        >
          <img src={mouthTrxImage} alt="" />
        </div>
      </Show>
      <div
        className="link"
        title="Balance"
        onClick={() => {
          props.chartActions.getPatientBalance(props.patient.patientKey);
        }}
      >
        <img src={balanceImage} alt="" />
      </div>
      <div
        className="link"
        title="Goals (care plan)"
        onClick={() => {
          props.chartActions.showSideBar(viewTypes.goals);
        }}
      >
        <img src={obtainImagePath(goalsImage)} alt="" />
      </div>
      <div
        className="link"
        title="Fill in forms"
        onClick={() => {
          props.chartActions.showSideBar(viewTypes.fillInForm);
        }}
      >
        <img alt="" src={fillInFormImage} />
      </div>
      <div
        className="link"
        title="Medical forms"
        onClick={() => {
          props.medicalFillInFormShowSideBar();
        }}
      >
        <img alt="" src={obtainImagePath(medicalFormImage)} />
      </div>
      <div
        className="link"
        title="Referral, Next Visit"
        onClick={() => {
          props.chartActions.showSideBar(viewTypes.referralNextVisit);
        }}
      >
        <img alt="" src={obtainImagePath(HistoryImage)} />
      </div>
      <div
        className="link patient-tag"
        title="Patient tag"
        onClick={() => {
          props.chartActions.showSideBar(viewTypes.patientTags);
        }}
      >
        <HashtagIcon style={{ fill: "#BFB800", width: "30px" }} />
      </div>
      <div className="chart-eligible-info">
        {eligibilityStatus === "yes" && <h1 className="chart-eligible-info__eligible">ELIGIBLE</h1>}
        {eligibilityStatus === "no" && (
          <h1 className="chart-eligible-info__not-eligible">NOT ELIGIBLE</h1>
        )}
      </div>
      <div className="divider" />
      <div
        className="link"
        title="Request history"
        onClick={() => props.chartActions.showPasswordModalForHistory(true)} // need changes
      >
        <div className="requested-by-patient">HIE</div>
      </div>
    </div>
  );
};

export default enhancer(ChartLinks);
