export const INIT_SIDE_VITAL = "INIT_SIDE_VITAL";
export const VITAL_INIT_EDIT = "VITAL_INIT_EDIT";
export const VITAL_INIT_TEXTAREA = "VITAL_INIT_TEXTAREA";

export const SELECT_SORT_FIELD_SIDE_VITAL = "SELECT_SORT_FIELD_SIDE_VITAL";

export const CHART_VITAL_SEARCH_CHANGE = "CHART_VITAL_SEARCH_CHANGE";

export const POP_VITAL_STACK = "POP_VITAL_STACK";
export const PUSH_VITAL_STACK = "PUSH_VITAL_STACK";

export const GET_VITAL_OWNED_LIST_START = "GET_VITAL_OWNED_LIST_START";
export const GET_VITAL_OWNED_LIST_SUCCESS = "GET_VITAL_OWNED_LIST_SUCCESS";
export const GET_VITAL_OWNED_LIST_ERROR = "GET_VITAL_OWNED_LIST_ERROR";

export const GET_VITAL_TEMPLATE_LIST_START = "GET_VITAL_TEMPLATE_LIST_START";
export const GET_VITAL_TEMPLATE_LIST_SUCCESS = "GET_VITAL_TEMPLATE_LIST_SUCCESS";
export const GET_VITAL_TEMPLATE_LIST_ERROR = "GET_VITAL_TEMPLATE_LIST_ERROR";

export const GET_VITAL_ITEM_START = "GET_VITAL_ITEM_START";
export const GET_VITAL_ITEM_SUCCESS = "GET_VITAL_ITEM_SUCCESS";
export const GET_VITAL_ITEM_ERROR = "GET_VITAL_ITEM_ERROR";

export const SAVE_VITAL_ITEM_START = "SAVE_VITAL_ITEM_START";
export const SAVE_VITAL_ITEM_SUCCESS = "SAVE_VITAL_ITEM_SUCCESS";
export const SAVE_VITAL_ITEM_ERROR = "SAVE_VITAL_ITEM_ERROR";

export const GET_VITAL_EMPTY_START = "GET_VITAL_EMPTY_START";
export const GET_VITAL_EMPTY_SUCCESS = "GET_VITAL_EMPTY_SUCCESS";
export const GET_VITAL_EMPTY_ERROR = "GET_VITAL_EMPTY_ERROR";

export const DELETE_VITAL_ITEM_START = "DELETE_VITAL_ITEM_START";
export const DELETE_VITAL_ITEM_SUCCESS = "DELETE_VITAL_ITEM_SUCCESS";
export const DELETE_VITAL_ITEM_ERROR = "DELETE_VITAL_ITEM_ERROR";

export const CHANGE_VITAL_CREATED_DATE_START = "CHANGE_VITAL_CREATED_DATE_START";
export const CHANGE_VITAL_CREATED_DATE_SUCCESS = "CHANGE_VITAL_CREATED_DATE_SUCCESS";
export const CHANGE_VITAL_CREATED_DATE_ERROR = "CHANGE_VITAL_CREATED_DATE_ERROR";

export const VITAL_PREV_QUESTION = "VITAL_PREV_QUESTION";
export const VITAL_NEXT_QUESTION = "VITAL_NEXT_QUESTION";
export const VITAL_SELECT_QUESTION = "VITAL_SELECT_QUESTION";

export const VITAL_CHANGE_QUESTION_TEXTAREA = "VITAL_CHANGE_QUESTION_TEXTAREA";
export const VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS = "VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS";
export const VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE =
  "VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE";
export const VITAL_CHANGE_DATE_PICKER = "VITAL_CHANGE_DATE_PICKER";
export const VITAL_CHANGE_SURFACE = "VITAL_CHANGE_SURFACE";
export const VITAL_SELECT_TOOTH = "VITAL_SELECT_TOOTH";
export const VITAL_SET_NAME = "VITAL_SET_NAME";
export const VITAL_SET_TEXTAREA_BODY = "VITAL_SET_TEXTAREA_BODY";

export const VITAL_OPEN_DELETE_MODAL = "VITAL_OPEN_DELETE_MODAL";
export const VITAL_CLOSE_DELETE_MODAL = "VITAL_CLOSE_DELETE_MODAL";
