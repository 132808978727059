import _, { toFinite } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

import { Modal } from "../../ui/Modal";
import Utils from "../../../helpers/Utils";
import { Checkbox } from "../../ui/Checkbox";
import { TextInput } from "../../ui/TextInput";
import {
  changeCustomPrice,
  changeTransactionByKey,
  toggleCustomPrice,
} from "../../../actions/chartSideSeriesActions";

const enhancer = connect(
  ({ chartSideSeries, patient, session: { permissions }, chart: { activeTooth } }) => {
    return {
      chartSideSeries,
      patient,
      permissions,
      activeTooth,
    };
  },
  {
    toggleCustomPrice,
    changeCustomPrice,
    changeTransactionByKey,
  },
);

class TopCalculationBoxPriceSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      customPriceValue: "",
    };
  }

  render() {
    const { showMenu, customPriceValue } = this.state;
    const {
      changeTransactionByKey,
      changeCustomPrice,
      chartSideSeries,
      toggleCustomPrice,
      activeTooth,
    } = this.props;
    const customPrice = chartSideSeries.customPrice;
    const addTransaction = chartSideSeries.addTransaction;
    let activePrice = null;
    let list;

    list = addTransaction.priceList;

    if (this.props.permissions.indexOf("VIEW_TREATMENT_CUSTOM_PRICE") < 0) {
      list = addTransaction.priceList.filter((x) => _.get(x, "name") !== "Custom");
    }

    let active = false;
    const $priceList = Utils.objectToArray(list, (item, id) => {
      active = addTransaction.price && addTransaction.price.name === item.name;

      if (active) {
        activePrice = item;
      }
      if (item.id === 0) {
        return (
          <Dropdown.Item key={id} eventKey={item.id} as="div">
            <div
              onClick={(e) => toggleCustomPrice(true, e)}
              className={active ? "dropdown-price-text active" : "dropdown-price-text"}
            >
              {item.name}
              <span className="price">{item.price}</span>
            </div>
          </Dropdown.Item>
        );
      } else {
        return (
          <Dropdown.Item key={id} eventKey={item.id} as="div">
            <div className={active ? "dropdown-price-text active" : "dropdown-price-text"}>
              {item.name}
              <span className="price">{item.price}</span>
            </div>
          </Dropdown.Item>
        );
      }
    });
    let title = "Fee: ";
    if (activePrice) {
      title += `${addTransaction.price.price} (${activePrice.name})`;
    }

    const checkBox = {};

    checkBox.checked = addTransaction.notBillInsurance3 || !addTransaction.sendBillInsurance;

    if (
      addTransaction.notBillInsurance3 ||
      (activePrice && activePrice.hasOwnProperty("needPreApproval") && !activePrice.needPreApproval)
    ) {
      checkBox.disabled = "disabled";
      checkBox.checked = true;
    }

    const checkBox2 = {};

    checkBox2.checked = addTransaction.notBillInsurance4 || !addTransaction.sendBillInsurance2;

    if (
      addTransaction.notBillInsurance4 ||
      (activePrice &&
        activePrice.hasOwnProperty("needPreApproval2") &&
        !activePrice.needPreApproval2)
    ) {
      checkBox2.disabled = "disabled";
      checkBox2.checked = true;
    }

    return (
      <div className="price-box">
        <Dropdown
          onSelect={(id) => {
            changeTransactionByKey(toFinite(id), "priceById", null, this.props.typeTab);
          }}
          show={showMenu}
          onToggle={(x) => this.setState({ showMenu: x })}
        >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {title}
          </Dropdown.Toggle>

          <Dropdown.Menu show={showMenu} id="price">
            <Checkbox
              id="sendBillInsurance"
              onChange={() =>
                changeTransactionByKey(null, "sendBillInsurance", null, this.props.typeTab)
              }
              label="Don't bill primary insurance"
              {...checkBox}
            />

            <Checkbox
              id="sendBillInsurance2"
              onChange={() =>
                changeTransactionByKey(null, "sendBillInsurance2", null, this.props.typeTab)
              }
              label="Don't bill secondary insurance"
              {...checkBox2}
            />
            <Dropdown.Divider />
            {$priceList}
            <Dropdown.Item eventKey={customPrice.id}>
              <div className="select-custom-price">
                {customPrice.name}
                <span className="price">{customPrice.price}</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          size="small"
          show={customPrice.visible}
          onHide={(e) => {
            e && e.preventDefault();
            toggleCustomPrice(false, e);
          }}
          actions={
            <div>
              <button
                className="btn btn-default"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  toggleCustomPrice(false, e);
                }}
              >
                Close
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  changeCustomPrice(customPriceValue, activeTooth);
                }}
              >
                OK
              </button>
            </div>
          }
          keyboard={false}
        >
          <div>
            <TextInput
              value={customPriceValue}
              style={{ margin: "20px 0" }}
              ref={(node) => {
                this.customPrice = node;
              }}
              onChange={({ target }) => this.setState({ customPriceValue: target.value })}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default enhancer(TopCalculationBoxPriceSelect);
