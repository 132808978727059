import _ from "lodash";
import moment from "moment";

import Utils from "../helpers/Utils";
import createReducer from "../helpers/createReducer";

import { CHART_SHOW_SIDE_BAR } from "../actions/chartActions";
import * as ActionTypes from "../actionTypes/chartSideVitalActionTypes";

import { defaultStackItem, vitalStackCodes } from "../constants/chart-sidebar/vitalStackCodes";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../constants/Constants";

export const defaultState = {
  stack: [defaultStackItem],
  sortField: "owner_name",
  fetching: false,
  ownedList: [],
  category: [],
  templateList: [],
  searchValue: "",
  item: {
    name: "",
    content: "",
  },
  currentQuestionIndex: 0,
  deleteModalVisible: false,
  onAppointment: false,
  isNew: false,
};

const reducers = {
  [CHART_SHOW_SIDE_BAR](state, { isNew }) {
    return {
      ...state,
      isNew,
    };
  },
  [ActionTypes.INIT_SIDE_VITAL](state, { onAppointment }) {
    return {
      ...state,
      stack: state.isNew ? [{ code: vitalStackCodes.list }] : [defaultStackItem],
      searchValue: "",
      currentQuestionIndex: 0,
      onAppointment,
    };
  },
  [ActionTypes.SELECT_SORT_FIELD_SIDE_VITAL](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.CHART_VITAL_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.PUSH_VITAL_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.POP_VITAL_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.GET_VITAL_OWNED_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_VITAL_OWNED_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        ownedList: state.ownedList.concat(data.list),
        ownedListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetching: false,
      ownedList: data.list || [],
      ownedListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_VITAL_OWNED_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_VITAL_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      templateList: [],
      fetching: true,
    };
  },
  [ActionTypes.GET_VITAL_TEMPLATE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      templateList: data.list || [],
      fetching: false,
    };
  },
  [ActionTypes.GET_VITAL_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_VITAL_ITEM_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_VITAL_ITEM_SUCCESS](state, { data }) {
    const writtenNote = !data.template.name;
    const drawenNote = data.typeCode == "NOTE_TYPE_IMAGE";

    if (!writtenNote && !drawenNote) {
      const orderedQuestions = [];

      const reqExp = /\[\[[^\]]*]]/g;
      data.template.code.replace(reqExp, (match) => {
        const code = match.slice(2, -2);
        if (code == "Current date") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
        }
        if (code == "Current time") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
        }
        if (code == "Patient chart number") {
          return data.patient.chartNumber;
        }
        if (code == "Patient name") {
          return data.patient.fullName;
        }
        if (code == "Dentist Name") {
          return data.doctor.name;
        }
        if (code === "Patient nationality") {
          return _.get(data, "patient.nationality.name", "N/A");
        }
        if (code === "Patient date of birth") {
          const dateFormat = localStorage.getItem("company-date-format");

          return Utils.parseDateFormat(
            Utils.calcDateWithTimeZone(data.patient && data.patient.dateOfBirth),
            dateFormat,
          );
        }

        if (code === "Doctor license id") {
          return data.owner && data.owner.code;
        }

        const question = _.get(data, "templateQuestionsAndAnswers", []).find(
          (question) => question.code == code,
        );

        if (question) orderedQuestions.push(question);

        return match;
      });

      data.templateQuestionsAndAnswers = orderedQuestions;
    }

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.GET_VITAL_EMPTY_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_VITAL_EMPTY_SUCCESS](state, { data }) {
    const orderedQuestions = [];

    const reqExp = /\[\[[^\]]*]]/g;
    data.content = data.template.code.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      if (code == "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code == "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code == "Patient chart number") {
        return data.patient && data.patient.chartNumber;
      }
      if (code == "Patient name") {
        return data.patient && data.patient.fullName;
      }
      if (code == "Dentist Name") {
        return data.doctor.name;
      }
      if (code === "Patient nationality") {
        return _.get(data, "patient.nationality.name", "N/A");
      }
      if (code === "Patient date of birth") {
        const dateFormat = localStorage.getItem("company-date-format");

        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(data.patient && data.patient.dateOfBirth),
          dateFormat,
        );
      }
      if (code === "Doctor license id") {
        return data.owner && data.owner.code;
      }

      let question = _.get(data, "templateQuestionsAndAnswers", []).find(
        (question) => question.code == code,
      );

      if (!question) {
        question = {
          id: 0,
          code: "",
          name: "",
          question: "",
          questionType: "",
          answers: [],
        };
      }

      orderedQuestions.push(question);

      return match;
    });

    data.templateQuestionsAndAnswers = orderedQuestions;

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.VITAL_PREV_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: state.currentQuestionIndex - 1,
    };
  },
  [ActionTypes.VITAL_NEXT_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: +state.currentQuestionIndex + 1,
    };
  },
  [ActionTypes.VITAL_SELECT_QUESTION](state, { index }) {
    return {
      ...state,
      currentQuestionIndex: index,
    };
  },
  [ActionTypes.VITAL_CHANGE_QUESTION_TEXTAREA](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.VITAL_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      let index;
      if ((index = question.answers.indexOf(value)) == -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.VITAL_CHANGE_DATE_PICKER](state, { date, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [moment()];

      question.answers[0] = date;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.VITAL_CHANGE_SURFACE](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      let index;
      if ((index = question.answers.indexOf(value)) == -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.VITAL_SELECT_TOOTH](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      let index;
      if ((index = question.answers.indexOf(value)) == -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CHANGE_VITAL_CREATED_DATE_SUCCESS](state, { data: { createdDate } }) {
    return {
      ...state,
      item: {
        ...state.item,
        createdDate,
      },
    };
  },
  [ActionTypes.VITAL_OPEN_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: true,
    };
  },
  [ActionTypes.VITAL_CLOSE_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: false,
    };
  },
  [ActionTypes.VITAL_INIT_EDIT](state) {
    return {
      ...state,
      currentQuestionIndex: 0,
    };
  },
  [ActionTypes.VITAL_SET_NAME](state, { value }) {
    return {
      ...state,
      item: {
        ...state.item,
        name: value,
      },
    };
  },
};

export default createReducer(defaultState, reducers);
