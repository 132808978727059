import { toFinite } from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import { createUrl } from "../../utils/UrlUtils";
import { DoctorProps } from "../../dto/MemberDTO";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

interface Query {
  readonly id: string;
}

export function useBlockedTimeSlotItemQuery(query: Query): UseQueryResult<DoctorProps[], Error> {
  return useQuery(
    ["blocked-time-slot", query.id],
    () => httpClient.get(createUrl(ApiRoutes.BlockedTimeSlotItem, { query })).then(formatResponse),
    { enabled: toFinite(query.id) > 0 },
  );
}
