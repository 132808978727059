const treatmentAreas = {
  Surface: { id: 1, code: "SURFACE", multiCodeCount: 5 },
  Tooth: { id: 2, code: "TOOTH", multiCodeCount: 0 },
  Root: { id: 3, code: "ROOT", multiCodeCount: 0 },
  Mouth: { id: 4, code: "MOUTH", multiCodeCount: 3 },
  Quadrant: { id: 5, code: "QUADRANT", multiCodeCount: 4 },
  Sextant: { id: 6, code: "SEXTANT", multiCodeCount: 6 },
  Body: { id: 7, code: "BODY", multiCodeCount: 5 },
  Eye: { id: 8, code: "EYE", multiCodeCount: 5 },
  General: { id: 9, code: "GENERAL", multiCodeCount: 5 },
};

export default treatmentAreas;
