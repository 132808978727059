import _ from "lodash";
import moment from "moment";
import Utils from "../helpers/Utils";
import createReducer from "../helpers/createReducer";
import {
  CALL_LIST_DOCTOR_SELECT,
  CALL_LIST_GET_STATUS_LIST_SUCCESS,
  CALL_LIST_INIT_DOCTORS,
  CALL_LIST_SET_CLINIC_ID,
  CALL_LIST_SET_MACHINE,
  CALL_LIST_SET_PLANNED_MODAL_VISIBLE,
  CALL_LIST_SET_STATUS,
  CHANGE_STATUS_SUCCESS,
  GET_CALL_LIST_SUCCESS,
  NO_RESPONSE,
  SET_CALL_LIST_DATE,
  SET_CALL_LIST_DOCTOR,
  SET_CALL_LIST_OTHER_STATUS,
} from "../actions/callListActions";
import { STATUS_TYPES } from "../constants/StatusTypes";

const defaultState = {
  columns: [],
  doctor: "",
  machineId: 0,
  date: moment().startOf("day"),
  statusList: [],
  status: STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
  doctors: {},
  clinicId: 0,
  plannedItem: {},
  otherStatusId: "",
};

const reducers = {
  [CALL_LIST_SET_CLINIC_ID](state, { clinicId }) {
    return {
      ...state,
      clinicId,
    };
  },
  [CALL_LIST_SET_PLANNED_MODAL_VISIBLE](state, { plannedItem }) {
    return {
      ...state,
      plannedItem,
    };
  },
  [CALL_LIST_SET_MACHINE](state, { machineId }) {
    return {
      ...state,
      machineId,
    };
  },
  [GET_CALL_LIST_SUCCESS](state, { data: { points = [] } }) {
    const companyStartOfDay = Utils.getStartOfDate(state.date);

    const columns = Utils.filterCallList(points.map(pointColumnModel), companyStartOfDay);

    return { ...state, columns };

    function pointColumnModel(pointColumn) {
      const list = pointColumn.list || [];

      return {
        list,
        title: {
          dentistName: pointColumn.dentist.name,
          title: pointColumn.dentalPoint.name,
        },
      };
    }
  },
  [NO_RESPONSE](state, { id }) {
    const companyStartOfDay = Utils.getStartOfDate(state.date);

    const callListNoResponse = JSON.parse(
      /* eslint-disable-next-line */
      localStorage.getItem("callListNoResponse") || "{}",
    );
    if (!callListNoResponse.hasOwnProperty(companyStartOfDay)) {
      callListNoResponse[companyStartOfDay] = {};
    }

    callListNoResponse[companyStartOfDay][id] = {
      time: Date.now(),
    };

    /* eslint-disable-next-line */
    localStorage.setItem("callListNoResponse", JSON.stringify(callListNoResponse));

    const columns = Utils.filterCallList(state.columns, companyStartOfDay);

    return { ...state, columns };
  },
  [CHANGE_STATUS_SUCCESS](state, { data: { id } }) {
    const { columns: oldColumns } = state;

    const columns = oldColumns.map((pointColumn) => {
      let list = [];

      if (Array.isArray(pointColumn.list)) {
        list = pointColumn.list.filter((point) => point.id !== id);
      }

      return { ...pointColumn, list };
    });

    return { ...state, columns };
  },
  [SET_CALL_LIST_DATE](state, { date }) {
    return { ...state, date };
  },
  [SET_CALL_LIST_DOCTOR](state, { doctor }) {
    return { ...state, doctor };
  },
  [SET_CALL_LIST_OTHER_STATUS](state, { status }) {
    return { ...state, otherStatusId: status };
  },
  [CALL_LIST_SET_STATUS](state, { status }) {
    return { ...state, status };
  },
  [CALL_LIST_GET_STATUS_LIST_SUCCESS](state, { data }) {
    const statusList = (_.isArray(data) ? data : []).filter((item) => {
      switch (item.code) {
        case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
          return true;
      }

      return false;
    });

    return {
      ...state,
      statusList,
    };
  },
  [CALL_LIST_INIT_DOCTORS](state, { doctors }) {
    return {
      ...state,
      doctors,
    };
  },
  [CALL_LIST_DOCTOR_SELECT](state, { id }) {
    const doctors = JSON.parse(JSON.stringify(state.doctors));

    if (id) {
      doctors[id].active = !doctors[id].active;
    } else {
      _.forEach(doctors, (item) => {
        item.active = false;
      });
    }

    return {
      ...state,
      doctors,
    };
  },
};

export default createReducer(defaultState, reducers);
