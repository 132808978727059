import React, { Component } from "react";
import { connect } from "react-redux";
import ReferralNextVisitTable from "./ReferralNextVisitTable";
import Back from "../widgets/Back";
import ReferralNextVisitForm from "./ReferralNextVisitForm";
import { initList, setInitialValues } from "../../actions/referralActions";
import LogoImage from "../../assets/images/clinic_logo.png";
import _ from "lodash";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";
import { parseQuery } from "../../utils/UrlUtils";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { getCategoryList } from "../../actions/appointmentActions";
import { showMessage } from "../../actions/loaderActions";
import PatientInfoSideBar from "../patient/PatientInfoSideBar";

const enhancer = connect(
  ({
    referral: { searchInitialValues, referralNextVisitListActiveTab },
    calendar: { clinicId },
    session: { permissions, member },
  }) => ({
    searchInitialValues,
    clinicId,
    permissions,
    member,
    referralNextVisitListActiveTab,
  }),
);
class ReferralNextVisitList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tbodyHeight: 300,
      tdWidth: 0,
    };
  }

  statusCode = (referralType) => {
    return [STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING].join(",");
    // if (referralType.value && referralType.value === -1) {
    //   return [
    //     STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2,
    //     STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER,
    //     STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF,
    //   ].join(",");
    // }
    // if (referralType.value === "NEXT_VISIT" || referralType.value === "REFERRAL") {
    //   return [
    //     STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
    //     STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE,
    //     STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED,
    //     STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2,
    //     STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER,
    //     STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF,
    //   ].join(",");
    // }
  };

  componentDidMount() {
    const { dispatch, searchInitialValues, location, clinicId, permissions, member } = this.props;
    const viewOwnReports = permissions.indexOf("VIEW_ONLY_OWN_REPORT") >= 0;
    const query = parseQuery(location.search);
    const patientChartNumber = query.chartNumber || null;
    const cancelledStatues = [
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
    ].join(", ");
    // const periodEnd = moment().add(2, "months");
    // dispatch(setInitialValues({ periodEnd }));
    // searchInitialValues.periodEnd = periodEnd;

    if (patientChartNumber) {
      dispatch(setInitialValues({ patientChartNumber }));
      searchInitialValues.patientChartNumber = patientChartNumber;
    } else {
      dispatch(setInitialValues({ patientChartNumber: "" }));
      searchInitialValues.patientChartNumber = "";
    }
    let referredToId =
      _.get(searchInitialValues, "referredToId.value") > 0
        ? _.get(searchInitialValues, "referredToId.value")
        : null;
    const referredFromId =
      _.get(searchInitialValues, "referredFromId.value") > 0
        ? _.get(searchInitialValues, "referredFromId.value")
        : null;
    const doctorIds =
      _.get(searchInitialValues, "doctorIds.value") > 0
        ? _.get(searchInitialValues, "doctorIds.value")
        : null;
    const referralTypeCode =
      typeof _.get(searchInitialValues, "referralTypeCode.value") === "string"
        ? _.get(searchInitialValues, "referralTypeCode.value")
        : null;
    const statusCodes = searchInitialValues.showCanceled
      ? cancelledStatues
      : this.statusCode(searchInitialValues.referralTypeCode);

    if (viewOwnReports) {
      dispatch(setInitialValues({ referredToId: { value: member.id, label: member.name } }));
      referredToId = member.id;
    }
    dispatch(setInitialValues({ statusCodes }));
    dispatch(getCategoryList({ clinicId }));
    dispatch(
      initList({
        ...searchInitialValues,
        periodStart: getCompanyStartOfDay(searchInitialValues.periodStart.valueOf()),
        periodEnd: getCompanyEndOfDay(searchInitialValues.periodEnd.valueOf()),
        categoryIds: searchInitialValues.categoryIds?.value,
        referralTypeCode,
        referredToId,
        referredFromId,
        doctorIds,
        statusCodes,
        referral: true,
        showNoAnswer: false,
        showWillCall: false,
        notInterested: false,
      }),
    );
    this.setState({
      tbodyHeight: document.body.offsetHeight - 300,
      tdWidth: document.body.offsetWidth / 11,
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setInitialValues({ patientChartNumber: "" }));
  }

  handleSubmit = (values) => {
    const { dispatch, referralNextVisitListActiveTab } = this.props;
    values.showNoAnswer = referralNextVisitListActiveTab === "noAnswer";
    values.showWillCall = referralNextVisitListActiveTab === "willCall";
    values.notInterested = referralNextVisitListActiveTab === "notInterested";

    const data = {
      ...values,
      referral: true,
    };
    if (values.showAll && !values.patientChartNumber) {
      dispatch(showMessage("Please fill patient chart number to continue"));
      return;
    }
    const cancelledStatues = [
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      // STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
    ].join(", ");
    const allStatuses = [
      STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
      STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
      STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2,
      STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
      STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE,
      STATUS_TYPES.APPOINTMENT_STATUS_WILL_CALL,
      STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN,
      STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER,
      STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF,
      STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN,
      STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_PARTIALLY_PAYED,
    ].join(", ");
    data.referredToId =
      _.get(values, "referredToId.value") > 0 ? _.get(values, "referredToId.value") : null;
    data.referredFromId =
      _.get(values, "referredFromId.value") > 0 ? _.get(values, "referredFromId.value") : null;
    data.doctorIds = _.get(values, "doctorIds.value") > 0 ? _.get(values, "doctorIds.value") : null;
    data.referralTypeCode =
      typeof _.get(values, "referralTypeCode.value") === "string"
        ? _.get(values, "referralTypeCode.value")
        : null;
    data.periodStart = getCompanyStartOfDay(values.periodStart.valueOf());
    data.periodEnd = getCompanyEndOfDay(values.periodEnd.valueOf());
    data.categoryIds = data.categoryIds?.value;
    data.clinicId = data.clinicId.value || values.clinicId.value;
    data.statusCodes = values.showCanceled
      ? cancelledStatues
      : values.showAll
      ? allStatuses
      : this.statusCode(values.referralTypeCode);
    dispatch(setInitialValues({ statusCodes: data.statusCodes }));
    dispatch(initList(data));
  };

  render() {
    const { location, referralNextVisitListActiveTab } = this.props;
    const query = parseQuery(location.search);
    const patientChartNumber = query.chartNumber || null;
    const { tbodyHeight, tdWidth } = this.state;

    return (
      <div className="waiting-list-page p-0">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" />
          <img src={LogoImage} alt="logo" className="header-logo" />
        </div>
        <PatientInfoSideBar initList={this.handleSubmit} />

        <ReferralNextVisitForm
          handleSubmit={this.handleSubmit}
          patientChartNumber={patientChartNumber}
          activeTab={referralNextVisitListActiveTab}
        />
        <div style={{ height: "72%" }}>
          <ReferralNextVisitTable
            getList={this.handleSubmit}
            tbodyHeight={tbodyHeight}
            tdWidth={tdWidth}
          />
        </div>
      </div>
    );
  }
}

export default enhancer(ReferralNextVisitList);
