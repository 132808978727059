import PatientApi from "../api-legacy/PatientApi";

import * as ActionType from "../actionTypes/chartSideDocsActionTypes";

import { startLoader, stopLoader } from "./loaderActions";

export function initList(onAppointment) {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      appointment,
    } = getState();

    const data = {
      patientKey,
      limit: 25,
    };

    if (onAppointment) {
      data.patientKey =
        (appointment.appointment &&
          appointment.appointment.patient &&
          appointment.appointment.patient.patientKey) ||
        (appointment.patient && appointment.patient.patientKey);
    }

    dispatch(startLoader());

    dispatch(getFiles(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function toggleImage(item) {
  return {
    type: ActionType.SIDE_DOCS_TOGGLE_IMAGE,
    item,
  };
}

export function openDeleteModal() {
  return {
    type: ActionType.SIDE_DOCS_OPEN_DELETE_MODAL,
  };
}

export function closeDeleteModal() {
  return {
    type: ActionType.SIDE_DOCS_CLOSE_DELETE_MODAL,
  };
}

export function fullMode(index) {
  return {
    type: ActionType.SIDE_DOCS_FULL_MODE,
    index,
  };
}

export function smallMode() {
  return {
    type: ActionType.SIDE_DOCS_SMALL_MODE,
  };
}

export function prevIndex() {
  return {
    type: ActionType.SIDE_DOCS_PREV_INDEX,
  };
}

export function nextIndex() {
  return {
    type: ActionType.SIDE_DOCS_NEXT_INDEX,
  };
}

function getFiles(data) {
  return {
    api: PatientApi.getFiles,
    types: [
      ActionType.SIDE_DOCS_GET_FILES_START,
      ActionType.SIDE_DOCS_GET_FILES_SUCCESS,
      ActionType.SIDE_DOCS_GET_FILES_ERROR,
    ],
    data,
  };
}

export function deleteFiles() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSideDocs: { ids },
    } = getState();

    const data = {
      patientKey,
      ids: Object.keys(ids),
    };

    dispatch(startLoader());

    dispatch({
      api: PatientApi.deleteFiles,
      types: [
        ActionType.SIDE_DOCS_DELETE_FILES_START,
        ActionType.SIDE_DOCS_DELETE_FILES_SUCCESS,
        ActionType.SIDE_DOCS_DELETE_FILES_ERROR,
      ],
      data,
    })
      .then(() => {
        dispatch(stopLoader());
        dispatch(initList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}
