import React, { useEffect, useState } from "react";

import Back from "../widgets/Back";

import { TreatmentGrouppingTypes } from "../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getSession } from "../../actions/sessionActions";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import MainApi from "../../api-legacy/MainApi";

const TreatmentGroupping = () => {
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const {
    session: { treatmentGroupingType },
  } = store;
  const [value, setValue] = useState(0);
  const [treatmentGroupType, setTreatmentGroupType] = useState();

  useEffect(() => {
    dispatch(startLoader());

    dispatch(getSession())
      .then((response) => {
        setTreatmentGroupType(response?.treatmentGroupingType?.code);
      })
      .finally(() => dispatch(stopLoader()));
  }, []);

  const setView = (type) => {
    // localStorage.setItem("treatmentGroupping", type || treatmentGroupingType.code);
    dispatch(startLoader());

    MainApi.changeTreatmentGroupingType({ code: type })
      .then((res) => {
        dispatch(getSession()).then((response) => {
          setTreatmentGroupType(response?.treatmentGroupingType?.code);
          localStorage.setItem("treatmentGroupping", response?.treatmentGroupingType.code || type);
        });
      })
      .finally(() => dispatch(stopLoader()));
    setValue(value + 1);
  };

  return (
    <div className="settings-body">
      <div className="title">
        <Back />
        <div className="name">Transaction Gropping</div>
      </div>
      <div className="body">
        <div className="list-group">
          <span onClick={() => setView(TreatmentGrouppingTypes.none)} className="list-group-item">
            {Boolean(treatmentGroupType === TreatmentGrouppingTypes.none) && (
              <div className="pull-right">&#10004;</div>
            )}
            No Grouping
          </span>
          <span
            onClick={() => setView(TreatmentGrouppingTypes.byProcedure)}
            className="list-group-item"
          >
            {Boolean(treatmentGroupType === TreatmentGrouppingTypes.byProcedure) && (
              <div className="pull-right">&#10004;</div>
            )}
            Group By Procedure
          </span>
          <span
            onClick={() => setView(TreatmentGrouppingTypes.byTreatmentCode)}
            className="list-group-item"
          >
            {Boolean(treatmentGroupType === TreatmentGrouppingTypes.byTreatmentCode) && (
              <div className="pull-right">&#10004;</div>
            )}
            Group By Treatment
          </span>
        </div>
      </div>
    </div>
  );
};

export default TreatmentGroupping;
