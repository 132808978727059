import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import _, { last } from "lodash";
import { parseQuery } from "../../../../utils/UrlUtils";
import ChartFillInFormCategoryItem from "../ChartListItem";
import DataList from "../../../widgets/DataList";
import {
  openItem,
  loadMedicalFillInFormTemplateList,
  getMedicalFillInFormTemplateQuestions,
  approveConsultationInitialExamination,
  getMedicalFormConsultationInitialExaminationItem,
} from "../../../../actions/chartSideMedicalFillInFormActions";

import { useShallowEqualSelector } from "../../../../hooks/useShallowEqualSelector";
import ChartClinicalNoteCategoryGroupItem from "../ChartClinicalNoteCategoryGroupItem";
import { chosenMemberIdSelector, membersSelector } from "../../../../reducers/authReducer";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";

const MedicalFillInFormInitialExaminationList = (props) => {
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const dispatch = useDispatch();
  const query = parseQuery(props.location?.search);
  const {
    chartSideMedicalFillInForm,
    initialExaminationRequireApproval,
    initialExaminationItem,
    onSaveItem,
  } = props;
  const members = useShallowEqualSelector(membersSelector);
  const chosenMemberId = useShallowEqualSelector(chosenMemberIdSelector);
  const member = useMemo(() => members[chosenMemberId], [chosenMemberId, members]);

  useEffect(() => {
    dispatch(getMedicalFormConsultationInitialExaminationItem());
  }, []);

  const warnModalHandler = () => {
    return dispatch(
      openSimpleModal({
        body: "Do you want to save your changes?",
        footer: (
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger ml-3"
              onClick={() => {
                dispatch(closeSimpleModal());
              }}
            >
              No
            </button>
            <button
              className="btn btn-success ml-3"
              onClick={() => {
                onSaveItem();
                dispatch(closeSimpleModal());
              }}
            >
              Yes
            </button>
          </div>
        ),
      }),
    );
  };
  const openItemHandler = (item) => {
    const { stack, dataToFill, isUserLeavingWithoutSaving } = chartSideMedicalFillInForm;
    const _isUserLeavingWithoutSaving = !!dataToFill && isUserLeavingWithoutSaving;
    const lastStack = last(stack);

    if (
      (lastStack?.type === medicalFillInFormItemTypes.edit ||
        lastStack?.type === medicalFillInFormItemTypes.new) &&
      _isUserLeavingWithoutSaving
    ) {
      return warnModalHandler();
    }

    const stackType =
      lastStack?.type === medicalFillInFormItemTypes.edit
        ? medicalFillInFormItemTypes.edit
        : lastStack?.type === medicalFillInFormItemTypes.view
        ? medicalFillInFormItemTypes.view
        : medicalFillInFormItemTypes.new;
    dispatch(openItem(item, stackType));

    const transfer = {
      patientKey: query.patientKey,
      consultationId: initialExaminationItem.consultationId,
      templateId: item.id,
    };
    dispatch(getMedicalFillInFormTemplateQuestions(transfer));
  };

  const getList = () => {
    const { chartSideMedicalFillInForm } = props;
    const { templateList, searchValue = "" } = chartSideMedicalFillInForm;

    const filteredList = templateList.filter(
      (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );

    if (groupId) {
      return filteredList
        .filter(({ group }) => group && group.id === groupId)
        .map((item) => (
          <ChartFillInFormCategoryItem
            item={item}
            key={item.id}
            style={{ background: "none" }}
            openItem={() => openItemHandler(item)}
          />
        ));
    }

    return _.uniqWith(
      filteredList.map((item) => {
        if (item.group && item.group.id) {
          return item.group;
        }

        return item;
      }),
      _.isEqual,
    ).map((item, idx) => {
      const hasGroup = !item.typeId && !item.speciality && !item.templateBody;

      if (hasGroup) {
        return (
          <ChartClinicalNoteCategoryGroupItem
            title={item.name}
            key={`group-${item.id}-${idx}`}
            onClick={() => {
              setGroupId(item.id);
              setGroupName(item.name);
            }}
          />
        );
      }

      return (
        <ChartFillInFormCategoryItem
          item={item}
          key={`item-${item.id}`}
          style={{
            background: "none",
            boxShadow:
              item.id === chartSideMedicalFillInForm.item?.template?.id
                ? "inset 0 3px 5px rgb(0 0 0 / 13%)"
                : "none",
          }}
          openItem={() => openItemHandler(item)}
        />
      );
    });
  };

  const { fetching, activeTab } = props.chartSideMedicalFillInForm;
  return (
    <div className="chart-clinical-note" style={{ height: "100%" }}>
      <div className="chart-side-body">
        <div className={`${activeTab.isInitialExamination ? "body justify-content-end" : "body"}`}>
          <div className="chart-clinical-note-category-title">{groupName || "Ready Templates"}</div>
          <DataList
            fetching={fetching}
            style={{ overflowY: "auto", height: "100%" }}
            onScroll={() => dispatch(loadMedicalFillInFormTemplateList())}
          >
            {getList()}
          </DataList>

          {initialExaminationRequireApproval && !_.get(initialExaminationItem, "approved", true) && (
            <div className="mb-2 d-flex justify-content-start flex-shrink-0">
              <button
                type="button"
                className="btn btn-primary btn-block m-0"
                onClick={() => dispatch(approveConsultationInitialExamination())}
              >
                Approve
              </button>
            </div>
          )}
          {_.get(initialExaminationItem, "approved", false) && activeTab.isInitialExamination && (
            <div className="mb-2 d-flex justify-content-start flex-shrink-0">
              <span
                className="d-flex align-items-center font-weight-bold"
                style={{ fontSize: "15px" }}
              >
                Approved by: {member && `${member.firstName} ${member.lastName}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicalFillInFormInitialExaminationList;
