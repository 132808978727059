import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs";

import Show from "../../../widgets/Show";
import DataList from "../../../widgets/DataList";
import ChartSideSearch from "../ChartSideSearch";
import ChartClinicalNoteCategoryItem from "../ChartListItem";

import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(null, bindActions({ chartSideClinicalNoteActions }));

export default enhancer(
  class ChartClinicalNoteList extends React.Component {
    componentDidMount() {
      this.props.chartSideClinicalNoteActions.getTemplateList();
    }

    openItem = (item) => {
      return () => {
        this.props.chartSideClinicalNoteActions.openItem(item, clinicNoteItemTypes.new);
      };
    };

    onSearch = (event) => {
      this.props.chartSideClinicalNoteActions.setSearchValue(event.target.value);
    };

    render() {
      const { templateList, fetching, searchValue } = this.props.chartSideClinicalNote;

      const sortList = _.sortBy(templateList, (item) => item.name);

      const $list = sortList
        .filter((item) => item.name.indexOf(searchValue) !== -1)
        .map((item, index) => (
          <ChartClinicalNoteCategoryItem key={index} item={item} openItem={this.openItem(item)} />
        ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader
            onBackClick={this.props.chartSideClinicalNoteActions.popStack}
            title="Add Clinical Notes"
          />
          <div className="chart-side-body">
            <ChartSideSearch value={searchValue} onChange={this.onSearch} />
            <div className="body">
              <div className="chart-clinical-note-category-title">Ready Templates</div>
              <DataList>{$list}</DataList>
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
        </div>
      );
    }
  },
);
