export const CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_START =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_START";
export const CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_SUCCESS =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_SUCCESS";
export const CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_ERROR =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_ERROR";

export const CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_START =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_START";
export const CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_SUCCESS =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_SUCCESS";
export const CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_ERROR =
  "CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_ERROR";

export const CHART_SIDE_TRANSACTION_DELETE_ITEM_START = "CHART_SIDE_TRANSACTION_DELETE_ITEM_START";
export const CHART_SIDE_TRANSACTION_DELETE_ITEM_SUCCESS =
  "CHART_SIDE_TRANSACTION_DELETE_ITEM_SUCCESS";
export const CHART_SIDE_TRANSACTION_DELETE_ITEM_ERROR = "CHART_SIDE_TRANSACTION_DELETE_ITEM_ERROR";

export const CHART_SIDE_TRANSACTION_CHANGE_DATE_START = "CHART_SIDE_TRANSACTION_CHANGE_DATE_START";
export const CHART_SIDE_TRANSACTION_CHANGE_DATE_SUCCESS =
  "CHART_SIDE_TRANSACTION_CHANGE_DATE_SUCCESS";
export const CHART_SIDE_TRANSACTION_CHANGE_DATE_ERROR = "CHART_SIDE_TRANSACTION_CHANGE_DATE_ERROR";

export const CHART_SIDE_TRANSACTION_VOID_ITEM_START = "CHART_SIDE_TRANSACTION_VOID_ITEM_START";
export const CHART_SIDE_TRANSACTION_VOID_ITEM_SUCCESS = "CHART_SIDE_TRANSACTION_VOID_ITEM_SUCCESS";
export const CHART_SIDE_TRANSACTION_VOID_ITEM_ERROR = "CHART_SIDE_TRANSACTION_VOID_ITEM_ERROR";

export const CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_START =
  "CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_START";
export const CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_SUCCESS =
  "CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_SUCCESS";
export const CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_ERROR =
  "CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_ERROR";

export const CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_START =
  "CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_START";
export const CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_SUCCESS =
  "CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_SUCCESS";
export const CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_ERROR =
  "CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_ERROR";

export const CHART_SIDE_TRANSACTION_SEND_APPROVE_START =
  "CHART_SIDE_TRANSACTION_SEND_APPROVE_START";
export const CHART_SIDE_TRANSACTION_SEND_APPROVE_SUCCESS =
  "CHART_SIDE_TRANSACTION_SEND_APPROVE_SUCCESS";
export const CHART_SIDE_TRANSACTION_SEND_APPROVE_ERROR =
  "CHART_SIDE_TRANSACTION_SEND_APPROVE_ERROR";

export const CHART_SIDE_TRANSACTION_OPEN_CHOOSE_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_OPEN_CHOOSE_DELETE_MODAL";
export const CHART_SIDE_TRANSACTION_CLOSE_CHOOSE_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_CLOSE_CHOOSE_DELETE_MODAL";

export const CHART_SIDE_TRANSACTION_OPEN_ACCESS_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_OPEN_ACCESS_DELETE_MODAL";
export const CHART_SIDE_TRANSACTION_CLOSE_ACCESS_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_CLOSE_ACCESS_DELETE_MODAL";

export const CHART_SIDE_TRANSACTION_OPEN_CONFIRM_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_OPEN_CONFIRM_DELETE_MODAL";
export const CHART_SIDE_TRANSACTION_CLOSE_CONFIRM_DELETE_MODAL =
  "CHART_SIDE_TRANSACTION_CLOSE_CONFIRM_DELETE_MODAL";

export const CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM =
  "CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM";

export const CHART_SIDE_TRANSACTION_TOGGLE_APPROVE = "CHART_SIDE_TRANSACTION_TOGGLE_APPROVE";
export const CHART_SIDE_TRANSACTION_TOGGLE_ALL_APPROVE =
  "CHART_SIDE_TRANSACTION_TOGGLE_ALL_APPROVE";
export const CHART_SIDE_TRANSACTION_INIT_APPROVE = "CHART_SIDE_TRANSACTION_INIT_APPROVE";

export const CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_START =
  "CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_START";
export const CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_SUCCESS =
  "CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_SUCCESS";
export const CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_ERROR =
  "CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_ERROR";
