import _, { toFinite } from "lodash";
import React from "react";
import ReactSpinner from "react-spinjs-fix";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Show from "../../../widgets/Show";
import { getCompanyFormatDate } from "../../../../helpers/DateUtils";
import Utils from "../../../../helpers/Utils";
import { DiscountCellWrapper } from "../../transactions/DiscountCellWrapper";
import { DeleteCellWrapper } from "../../transactions/DeleteCellWrapper";

const MedicalFillInFormTreatmentList = (props) => {
  const generateList = (treatmentList) => {
    let list = [];

    _.forEach(treatmentList, (item, index) => {
      list.push(
        <div className="medical-fill-in-form-treatment__list__table__body__row" key={index}>
          <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
          <div className="item-treatment-code">{item.treatmentItem && item.treatmentItem.code}</div>
          <div className="item-tooth">{item.tooth && item.tooth.name}</div>
          <div className="item-session">{item.treatmentSession || 0}</div>
          <div className="item-description">
            {item.treatmentItem && item.treatmentItem.description}
          </div>
          <div className="item-fee">
            {item?.discount?.code === "AMOUNT"
              ? item.price - toFinite(item?.discount?.value)
              : item.price - (item.price * toFinite(item?.discount?.value)) / 100}
          </div>
          <div className="item-discount">
            <DiscountCellWrapper
              className="medical-forms"
              transactionKey={item.key}
              value={item.discount}
            />
          </div>
          <div className="item-principal-diagnosis">
            {item.diagnosisItem && item.diagnosisItem.code}
          </div>
          <div className="item-approval">
            {item.approvalStatus && item.approvalStatus.name
              ? item.approvalStatus.name
              : item.holdPreApproval
              ? "Hold"
              : ""}
          </div>
          <div className="item-expiry-date" style={{ color: "#FF0000" }}>
            {item.priorApprovalExpireDate
              ? Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.priorApprovalExpireDate),
                  DATE_FORMAT_LEGACY,
                )
              : ""}
          </div>
          <div className="item-approval">
            {item.approval2Status && item.approval2Status.name
              ? item.approval2Status.name
              : item.holdPreApproval
              ? "Hold"
              : ""}
          </div>
          <div className="item-posted-by">{item.createdDentist && item.createdDentist.name}</div>
          <div className="item-tooth">
            <DeleteCellWrapper item={item} disabled={!deletePermission} />
          </div>
        </div>,
      );
    });

    return list;
  };

  const { clinicId, treatmentList, fetching, permissions } = props;

  const filteredList = treatmentList.filter((x) => {
    const id = _.get(x, "clinic.id");

    return id === clinicId || clinicId === "all";
  });

  const deletePermission =
    permissions.indexOf("REMOVE_TREATMENT_PLANNED_DENTAL_TRANSACTION_ITEM") >= 0;
  return (
    <div className="medical-fill-in-form-treatment__list">
      <div className="medical-fill-in-form-treatment__list__table">
        <div className="medical-fill-in-form-treatment__list__table__header">
          <div className="item-date-posted">Date posted</div>
          <div className="item-treatment-code">Treatment Code</div>
          <div className="item-tooth">Tooth</div>
          <div className="item-session">Session</div>
          <div className="item-description">Description</div>
          <div className="item-fee">Fee</div>
          <div className="item-discount">Discount</div>
          <div className="item-principal-diagnosis">Principal diagnosis</div>
          <div className="item-approval">Approval.1</div>
          <div className="item-expiry-date">Expire Date.1</div>
          <div className="item-approval">Approval.2</div>
          <div className="item-posted-by">Posted by</div>
          <div className="item-tooth" />
        </div>
        <div className="medical-fill-in-form-treatment__list__table__body">
          <Show if={!fetching}>{generateList(filteredList)}</Show>
          <Show if={fetching}>
            <div
              style={{ position: "relative" }}
              className="medical-fill-in-form-treatment__list__table__body__row"
            >
              <ReactSpinner />
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
};

export default MedicalFillInFormTreatmentList;
