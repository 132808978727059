import React from "react";
import FilledImage from "../../widgets/FilledImage";
import DownImage from "../../../assets/images/conditions/veneer/veneer_down@2x.png";
import UpImage from "../../../assets/images/conditions/veneer/veneer_up@2x.png";

export default function condition(props) {
  const { property, up, color } = props;

  if (!property) return null;
  const src = up ? DownImage : UpImage;

  return (
    <div className="condition-wrap tooth-image-condition-bottom">
      <FilledImage src={src} color={color} className="zoom-2x" />
    </div>
  );
}
