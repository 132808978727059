export enum Routes {
  Auth = "/auth",
  AuthMemberLogin = "/auth/member-login",
  AuthChooseClinic = "/auth/choose-clinic",
  AuthRegisterDeviceLogin = "/auth/register-device-login",

  Dashboard = "/dashboard",
  DashboardStub = "/dashboard/stub",
  DashboardTestLC = "/dashboard/test-lc",
  DashboardProfile = "/dashboard/profile",
  DashboardChart = "/dashboard/chart/:type",
  DashboardCalendar = "/dashboard/calendar",
  DashboardCallList = "/dashboard/call-list",
  DashboardFollowUp = "/dashboard/follow-up",
  DashboardReminders = "/dashboard/reminders",
  DashboardPerioExam = "/dashboard/perio-exam",
  DashboardRecallList = "/dashboard/recall-list",
  DashboardProfileEdit = "/dashboard/profile-edit",
  DashboardWaitingList = "/dashboard/waiting-list",
  DashboardBringEarlier = "/dashboard/bring-earlier",
  DashboardChooseClinic = "/dashboard/choose-clinic",
  DashboardAppointment = "/dashboard/appointment/:id",
  DashboardPerioExamView = "/dashboard/perio-exam-view",
  DashboardMedicationList = "/dashboard/medication-list",
  DashboardInsurance = "/dashboard/insurance/:patientKey",
  DashboardBlockedTimeSlot = "/dashboard/blocked-time-slot",
  DashboardWhatsappChatting = "/dashboard/whatsapp-chatting",
  DashboardLateAppointments = "/dashboard/late-appointments",
  DashboardMissedCancelledList = "/dashboard/missed-cancelled-list",
  DashboardBlockedTimeSlotItem = "/dashboard/blocked-time-slot-item",
  DashboardReferralNextVisitList = "/dashboard/referral-next-visit-list",
  DashboardReferralNextVisit = "/dashboard/referral-next-visit",
  DashboardProfileEditSignature = "/dashboard/profile-edit-signature",
  DashboardDoctorProductionReport = "/dashboard/doctor-production-report",
  DashboardCalendarPrintDetails = "/dashboard/calendar-print-details/:type",
  DashboardRequestedAppointmentsList = "/dashboard/requested-appointments-list",

  Settings = "/settings",
  SettingsAbout = "/settings/about",
  SettingsVersion = "/settings/version",
  SettingsPostedBy = "/settings/posted-by",
  SettingsTimeView = "/settings/time-view",
  SettingsQuickMode = "/settings/quick-mode",
  SettingsAppointment = "/settings/appointment",
  SettingsAppointmentType = "/settings/appointment-type",
  SettingsAppointmentNote = "/settings/appointment-note",
  SettingsAppointmentStatus = "/settings/appointment-status",
  SettingsBlockedTimeSlot = "/settings/blocked-time-slot",
  SettingsAppointmentDoctor = "/settings/appointment-doctor",
  SettingsTreatmentGroupping = "/settings/treatment-groupping",
  SettingsXRayFolder = "/settings/x-ray-folder",
  SettingsTransactionGroupBy = "/settings/transaction-group-by",
  SettingsAppointmentCalendar = "/settings/appointment-calendar",
  SettingsAppointmentColorType = "/settings/appointment-color-type",
  SettingsTreatmentSideBarView = "/settings/treatment-side-bar-view",
  SettingsAppointmentHideButtons = "/settings/appointment-hide-buttons",
  SettingsAppointmentCalendarRow = "/settings/appointment-calendar-row",
  SettingsAppointmentCalendarColumn = "/settings/appointment-calendar-column",
  SettingsAppointmentTypeOther = "/settings/appointment-type-other/:colorCode",
  SettingsAppointmentTypeColor = "/settings/appointment-type-color/:colorCode",
  SettingsAppointmentDoctorOther = "/settings/appointment-other-color/:doctorId",
  SettingsAppointmentDoctorColor = "/settings/appointment-doctor-color/:doctorId",
  SettingsAppointmentStatusOther = "/settings/appointment-status-other/:colorCode",
  SettingsAppointmentStatusColor = "/settings/appointment-status-color/:colorCode",
  SettingsBlockedTimeSlotColor = "/settings/blocked-time-slot-color/:colorCode",
  SettingsBlockedTimeSlotColorOther = "/settings/blocked-time-slot-other/:colorCode",

  Patient = "/patient",
  PatientList = "/patient/list",
  PatientMobile = "/patient/mobile",
  PatientProfile = "/patient/profile",
  PatientContacts = "/patient/contacts",
  PatientFormsList = "/patient/forms-list",
  PatientFormsItem = "/patient/forms-item",
  NewPatientScreen = "/patient/new-screen",
  PatientFormsEdit = "/patient/forms-edit",
  PatientView = "/patient/view/:patientKey",
  PatientAddAnother = "/patient/add-another",
  PatientDetailsEdit = "/patient/details-edit",
  PatientAlerts = "/patient/alerts/:patientKey",
  PatientChooseAccount = "/patient/choose-account",
  PatientAlertsHistory = "/patient/alerts-history",
  PatientTerminal = "/patient/terminal/:patientKey",
  PatientClaimsApprovals = "/patient/claims-approvals",
  PatientAppointmentsList = "/patient/appointments-list",
  PatientMedicalAlertsEdit = "/patient/medical-alerts-edit",
  PatientMedicationAlertsEdit = "/patient/medication-alerts-edit",
  PatientClaimsApprovalsPdfView = "/patient/claims-approvals-pdf-view",
}
