import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";

import Show from "../../../widgets/Show";
import bindActions from "../../../../helpers/bindActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";
import ChartFillInFormOwnedItem from "./ChartFillInFormOwnedItem";
import { createUrl, parseQuery } from "../../../../utils/UrlUtils";
import fillInFormItemTypes from "../../../../constants/chart-sidebar/fillInFormItemTypes";
import FillInFormSortFields from "../../../../constants/chart-sidebar/fillInFormSortFields";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import { ButtonGroup } from "react-bootstrap";
import {
  onChangeFillInFormTab,
  getFrequentlyFillInFormList,
  getOwnedList,
  openItem,
  loadOwnedList,
  selectSortField,
  resetFillInFormFor,
  openList,
  openTextarea,
  setFillInFormAddendumId,
  copyNoteItem,
  popStack,
} from "../../../../actions/chartSideFillInFormActions";
import { Modal } from "../../../ui/Modal";
import GoalApi from "../../../../api-legacy/FillInFormApi";
const enhancer = connect(
  ({ chartSideFillInForm: { fillInFormActiveTab } }) => ({ fillInFormActiveTab }),
  {
    // chartSideFillInFormActions,
    onChangeFillInFormTab,
    getFrequentlyFillInFormList,
    getOwnedList,
    openItem,
    loadOwnedList,
    selectSortField,
    resetFillInFormFor,
    openList,
    openTextarea,
    setFillInFormAddendumId,
    copyNoteItem,
    popStack,
  },
);

class ChartFillInFormOwned extends React.Component {
  constructor(props) {
    super(props);
    this.state = { handleItem: null, restore: null, openModal: false };
  }
  isFrequency = () => {
    const query = parseQuery(this.props.location.search);

    return Boolean(_.get(query, "isFrequency"));
  };

  resetFrequency = () => {
    const { history, location } = this.props;

    const query = parseQuery(location.search);

    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          isFrequency: undefined,
        },
      }),
    );
  };

  componentDidMount() {
    const query = parseQuery(this.props.location.search);

    const patientKey = _.get(query, "patientKey") || this.props.patientKey;

    if (this.isFrequency()) {
      this.props.getFrequentlyFillInFormList({
        patientKey,
      });
    } else {
      this.props.getOwnedList({ patientKey });
    }
  }

  openItem = (item) => {
    return () => {
      this.props.openItem(item, fillInFormItemTypes.view);
    };
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.loadOwnedList({ patientKey: this.props.patientKey });
    }
  };

  select = (event) => {
    this.props.selectSortField(event.target.value);
  };

  openTextarea = () => {
    this.props.resetFillInFormFor();
    this.props.openList();
    this.props.openTextarea(fillInFormItemTypes.new);
  };

  openAddendum = (id) => {
    this.props.setFillInFormAddendumId(id);
    this.openTextarea();
  };
  setActiveTab = (activeTab) => this.props.onChangeFillInFormTab(activeTab);

  render() {
    const { ownedList, fetching, sortField, frequencyList } = this.props.chartSideFillInForm;
    const { fillInFormActiveTab } = this.props;
    const targetList = this.isFrequency() ? frequencyList : ownedList;

    let $list = targetList.map((item, index) => (
      <ChartFillInFormOwnedItem
        key={index}
        item={item}
        openItem={this.openItem}
        handleItem={(item, restore) => {
          this.setState({ handleItem: item, restore: restore, openModal: true });
        }}
        addendumItem={() => {
          this.openAddendum(item.id);
        }}
        onReferenceClick={(id) => {
          this.props.openItem({ id }, fillInFormItemTypes.view);
        }}
        copyItem={() => this.props.copyNoteItem(item.id)}
      />
    ));

    const $options = FillInFormSortFields.map((item, index) => (
      <option key={index} value={item.code}>
        {item.name}
      </option>
    ));

    return (
      <div className="chart-clinical-note">
        <div className="sidebar-header chart-side-header" style={{ paddingTop: 0 }}>
          <SidebarHeader
            title="Fill In Form"
            onBackClick={() => {
              this.props.setFillInFormAddendumId(0);
              if (this.props.fromAppointment) {
                this.props.hideSideBar();
              } else {
                this.resetFrequency();
                this.props.popStack();
              }
            }}
          />
          <div style={{ marginTop: "15px", padding: "0 5px" }}>
            <ButtonGroup aria-label="note tabs" className="d-flex align-items-center">
              <button
                type="button"
                onClick={() =>
                  this.setActiveTab({
                    code: "fillInForms",
                    title: "All Fill in forms",
                  })
                }
                className={`btn ${
                  fillInFormActiveTab.code !== "fillInForms" ? "btn-primary" : "btn-default"
                } btn-block m-0`}
              >
                Fill in forms
              </button>
              {/*<button*/}
              {/*  type="button"*/}
              {/*  // onClick={() =>*/}
              {/*  //     this.setActiveTab({*/}
              {/*  //       code: "clinicalNotes",*/}
              {/*  //       title: "All notes",*/}
              {/*  //     })*/}
              {/*  // }*/}
              {/*  className={`btn ${*/}
              {/*    clinicalNoteActiveTab.code !== "clinicalNotes" ? "btn-primary" : "btn-default"*/}
              {/*  } btn-block m-0`}*/}
              {/*>*/}
              {/*  All notes*/}
              {/*</button>*/}
              <button
                type="button"
                onClick={() =>
                  this.setActiveTab({
                    code: "voidedFillInForms",
                    title: "Voided Notes",
                  })
                }
                className={`btn ${
                  fillInFormActiveTab.code !== "voidedFillInForms" ? "btn-primary" : "btn-default"
                } btn-block m-0`}
              >
                Voided forms
              </button>
            </ButtonGroup>
          </div>
        </div>
        <div className="chart-side-body">
          <div className="chart-clinical-note-select">
            <select onChange={this.select} value={sortField} className="form-control">
              {$options}
            </select>
          </div>
          <div className="body" style={{ overflowY: "auto" }} onScroll={this.loadList}>
            {$list}
            <Show if={fetching}>
              <ReactSpinner />
            </Show>
          </div>
          <div className="all-codes">
            <button onClick={this.props.openList} className="btn btn-primary btn-block">
              Add
            </button>
          </div>
        </div>
        <Modal
          show={this.state.openModal}
          animation={false}
          size={"sm"}
          keyboard={false}
          onHide={() => this.setState({ openModal: false })}
          actions={
            <div>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.setState({ openModal: false })}
              >
                Cancel
              </button>
              <button
                type="button"
                className={this.state.restore ? "btn btn-primary" : "btn btn-danger"}
                onClick={() => {
                  const id = this.state.handleItem?.id;
                  !this.state.restore
                    ? GoalApi.deleteItem({ id }).then((res) => {
                        this.setState({ openModal: false });
                        this.props.getOwnedList();
                      })
                    : GoalApi.restoreItem({ id }).then((res) => {
                        this.setState({ openModal: false });
                        this.props.getOwnedList();
                      });
                  // this.state.restore
                  // ? this.props.noteRestoreItem(deleteId)
                  // : this.props.noteDeleteItem(deleteId)
                }}
              >
                {this.state.restore ? "Restore" : "Delete"}
              </button>
            </div>
          }
        >
          <div>Are you sure you want to {this.state.restore ? "restore" : "void"} this note?</div>
        </Modal>
      </div>
    );
  }
}

export default enhancer(ChartFillInFormOwned);
