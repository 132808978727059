import _ from "lodash";

import MemberApi from "../api-legacy/MemberApi";
import PatientApi from "../api-legacy/PatientApi";
import AddressApi from "../api-legacy/AddressApi";
import AppointmentApi from "../api-legacy/AppointmentApi";
import ConsentFormApi from "../api-legacy/ConsentFormApi";

import * as ActionType from "../actionTypes/patientRegisterActionTypes";

import { showMessage, startLoader, stopLoader } from "./loaderActions";

import Utils from "../helpers/Utils";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import { openSimpleModal } from "./simpleModalActions";
import { STATUS_TYPES } from "../constants/StatusTypes";

export function setValuesForProviderValidation(values) {
  return {
    type: ActionType.SET_VALUES_FOR_CARE_PROVIDER_VALIDATION,
    values,
  };
}

export function deletePatientFormsItem(id, patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(deletePatientFormsItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getPatientFormsList(patientKey)).catch((error) => showMessage(error));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function clearPatientDetails() {
  return (dispatch) => {
    return dispatch({
      type: ActionType.PATIENT_REGISTER_CLEAR_DETAILS,
    });
  };
}

export function getConsentFormCategoryList() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getConsentFormCategoryListApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPatientClaimsList(data, init) {
  return (dispatch) => {
    dispatch(startLoader());
    const requests = [];

    requests.push(dispatch(getPatientClaimsListApi(data)));
    if (init) {
      requests.push(dispatch(getPatientClaimsStatusListApi()));
      requests.push(dispatch(getPatientClaimsDocTypeListApi()));
    }

    return Promise.all(requests)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function printClaim(data) {
  return (dispatch, getState) => {
    dispatch(startLoader());
    const {
      patientRegister: { signature },
    } = getState();
    data.patientSignature = signature.slice(22);
    data.note = "";
    data.file = "pdf";
    data.insideData = true;
    return dispatch(printClaimApi(data))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveApprovalsClaimsItemNote(data) {
  return (dispatch, getState) => {
    dispatch(startLoader());
    const {
      patientRegister: { claimItemNote },
    } = getState();
    data.additionalComments = claimItemNote;
    return dispatch(saveClaimItemNoteApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setApprovalsClaimsItemNote(note) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_APPROVALS_CLAIMS_ITEM_NOTE,
      note,
    });
  };
}

export function loadPatientClaimsList(data) {
  return (dispatch, getState) => {
    const {
      patientRegister: { claimsList, claimsListTotalCount, fetchingClaims },
    } = getState();

    if (claimsList.length === claimsListTotalCount || fetchingClaims) {
      return;
    }

    const params = {
      ...data,
      start: claimsList.length,
      limit: 25,
    };

    return dispatch(getPatientClaimsListApi(params)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function getPatientApprovalsList(data, init) {
  return (dispatch) => {
    dispatch(startLoader());
    const requests = [];

    requests.push(dispatch(getPatientApprovalsListApi(data)));
    if (init) {
      requests.push(dispatch(getPatientApprovalsStatusListApi()));
      requests.push(dispatch(getPatientApprovalsDocTypeListApi()));
      requests.push(dispatch(getPatientClaimedStatusListApi()));
    }

    return Promise.all(requests)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadPatientApprovalsList(data) {
  return (dispatch, getState) => {
    const {
      patientRegister: { approvalsList, approvalsListTotalCount, fetchingApprovals },
    } = getState();

    if (approvalsList.length === approvalsListTotalCount || fetchingApprovals) {
      return;
    }

    const params = {
      ...data,
      start: approvalsList.length,
      limit: 25,
    };

    return dispatch(getPatientApprovalsListApi(params)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function patientSelectAppointmentSidebarSetDate(date) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_DATE,
      date,
    });

    dispatch(patientSelectsAppointmentSidebarGetList());
  };
}

export function patientSelectAppointmentSidebarRecentGet() {
  return (dispatch, getState) => {
    const {
      patientView: { patientKey },
      session: {
        member: { id },
      },
    } = getState();

    dispatch(startLoader());

    const statusCodes = [
      "APPOINTMENT_STATUS_BEING_SEEN",
      "APPOINTMENT_STATUS_COMPLETE",
      "APPOINTMENT_STATUS_UNCONFIRMED",
      "APPOINTMENT_STATUS_RESCHEDULED",
      "APPOINTMENT_STATUS_CONFIRMED",
      "APPOINTMENT_STATUS_CHECKED_IN",
      "APPOINTMENT_STATUS_PARTIALLY_PAYED",
    ];

    const data = {
      patientKey,
      dentistId: id,
      statusCodes: _.join(statusCodes, ","),
    };

    return dispatch(getPatientRecentAppointmentsApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function getPatientRecentAppointmentsApi(data) {
  return {
    api: PatientApi.getAppointmentRecentList,
    types: [
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_START,
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_SUCCESS,
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_ERROR,
    ],
    data,
  };
}

export function patientSelectsAppointmentSidebarGetList() {
  return (dispatch, getState) => {
    const {
      patientView: { patientKey },
      patientRegister: { patientSelectAppointmentSidebarDate },
      session: {
        member: { id },
      },
    } = getState();

    const data = {
      patientKey,
      dentistId: id,
      periodStart: Utils.getStartDay(patientSelectAppointmentSidebarDate),
      periodEnd: Utils.getEndDay(patientSelectAppointmentSidebarDate),
    };

    dispatch(startLoader());

    return dispatch(getPatientLastAppointmentsApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(startLoader(error));
      });
  };
}

export function patientSelectsAppointmentSidebarInit() {
  return (dispatch) => {
    dispatch({ type: ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_INIT });
    dispatch(patientSelectsAppointmentSidebarGetList());
  };
}

function getPatientLastAppointmentsApi(data) {
  return {
    api: PatientApi.getAppointmentCalendarList,
    types: [
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_START,
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_SUCCESS,
      ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_ERROR,
    ],
    data,
  };
}

export function patientSelectAppointmentSidebarSetMode(mode) {
  return (dispatch, getState) => {
    if (getState().patientRegister.patientSelectAppointmentSidebarMode !== "date") {
      dispatch(patientSelectsAppointmentSidebarGetList());
    } else {
      dispatch(patientSelectAppointmentSidebarRecentGet());
    }

    dispatch({
      type: ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_MODE,
      mode,
    });
  };
}

export function patientSelectAppointmentSidebarShow() {
  return {
    type: ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SHOW,
  };
}

export function patientSelectAppointmentSidebarHide() {
  return {
    type: ActionType.PATIENT_SELECT_APPOINTMENT_SIDEBAR_HIDE,
  };
}

export function getHistory() {
  return (dispatch, getState) => {
    const {
      patientRegister: { appointmentsReasonModalId },
    } = getState();

    dispatch(startLoader());

    return dispatch(getHistoryApi({ id: appointmentsReasonModalId }))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function getHistoryApi(data) {
  return {
    api: AppointmentApi.getHistory,
    types: [
      ActionType.PATIENT_APPOINTMENT_GET_HISTORY_START,
      ActionType.PATIENT_APPOINTMENT_GET_HISTORY_SUCCESS,
      ActionType.PATIENT_APPOINTMENT_GET_HISTORY_ERROR,
    ],
    data,
  };
}

export function patientChooseActionModalShow() {
  return {
    type: ActionType.PATIENT_CHOOSE_ACTION_MODAL_SHOW,
  };
}

export function patientChooseActionModalHide() {
  return {
    type: ActionType.PATIENT_CHOOSE_ACTION_MODAL_HIDE,
  };
}

export function appointmentsReasonModalShow(id, mode, index) {
  return {
    type: ActionType.APPOINTMENTS_REASON_MODAL_SHOW,
    id,
    mode,
    index,
  };
}

export function appointmentsReasonModalHide() {
  return {
    type: ActionType.APPOINTMENTS_REASON_MODAL_HIDE,
  };
}

export function setPatientAlertsHistoryUrl(index) {
  return {
    type: ActionType.SET_PATIENT_ALERTS_HISTORY_URL,
    index,
  };
}

export function loadPatientAlertsHistory() {
  return (dispatch, getState) => {
    const {
      patientView: { patientKey },
      patientRegister: {
        patientAlertsHistoryMode,
        patientAlertsHistoryListTotalCount,
        patientAlertsHistoryList,
        patientAlertsHistoryListFetching,
      },
    } = getState();

    if (
      patientAlertsHistoryList.length === patientAlertsHistoryListTotalCount ||
      patientAlertsHistoryListFetching
    ) {
      return;
    }

    const data = {
      patientKey,
      start: patientAlertsHistoryList.length,
      limit: 25,
      signatureType: patientAlertsHistoryMode,
    };

    return dispatch(getPatientAlertsHistoryApi(data)).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function getPatientAlertsHistory(mode) {
  return (dispatch, getState) => {
    dispatch({ type: ActionType.INIT_PATIENT_ALERTS_HISTORY });
    dispatch({ type: ActionType.SET_PATIENT_ALERTS_HISTORY_MODE, mode });

    const {
      patientView: { patientKey },
    } = getState();

    const data = {
      patientKey,
      start: 0,
      limit: 25,
      signatureType: mode,
    };
    dispatch(startLoader());

    return dispatch(getPatientAlertsHistoryApi(data))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function getPatientAlertsHistoryApi(data) {
  return {
    api: PatientApi.getPatientAlertsHistory,
    types: [
      ActionType.GET_PATIENT_ALERTS_HISTORY_START,
      ActionType.GET_PATIENT_ALERTS_HISTORY_SUCCESS,
      ActionType.GET_PATIENT_ALERTS_HISTORY_ERROR,
    ],
    data,
  };
}

export function validate({ phoneNumber, dateOfBirthDay }) {
  return (dispatch) => {
    const number = _.get(phoneNumber, "number");
    const townCode = _.get(phoneNumber, "townCode");
    const countryCode = _.get(phoneNumber, "countryCode.value");

    if (!phoneNumber || !number) {
      return dispatch(showMessage("Please, fill mobile number"));
    }

    dispatch(startLoader());

    const transfer = {
      phoneNumber: `${countryCode}-${townCode}-${number}`,
    };

    if (dateOfBirthDay) {
      transfer.dateOfBirth = getCompanyStartOfDay(dateOfBirthDay);
    }

    transfer.register = true;

    return dispatch(validateRegister(transfer))
      .then((response) => {
        dispatch(stopLoader());

        return { response, transfer };
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDetailsInfo(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(getPatientDetails({ patientKey }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPatientAppointmentsList(patientKey, statusCodes) {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 25,
      patientKey,
      statusCodes,
      sortField: "start_date_desc",
    };

    const promises = [];

    promises.push(dispatch(getPatientAppointmentsListApi(data)));
    promises.push(dispatch(getPatientDetails({ patientKey })));

    Promise.all(promises).catch((error) => {
      showMessage(error);
    });
  };
}

export function loadPatientAppointmentsList(patientKey, statusCodes) {
  return (dispatch, getState) => {
    const {
      appointmentsList,
      fetchingAppointmentsList,
      appointmentsListTotalCount,
    } = getState().patientRegister;

    if (appointmentsListTotalCount === appointmentsList.length || fetchingAppointmentsList) return;

    const data = {
      limit: 25,
      patientKey,
      statusCodes,
      sortField: "start_date_desc",
      start: appointmentsList.length,
    };

    dispatch(getPatientAppointmentsListApi(data)).catch((error) => {
      showMessage(error);
    });
  };
}

function getAppointmentUpcomingTotal(data) {
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.GET_APPOINTMENT_UPCOMING_TOTAL_LIST_START,
      ActionType.GET_APPOINTMENT_UPCOMING_TOTAL_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_UPCOMING_TOTAL_LIST_ERROR,
    ],
    data,
  };
}

function getAppointmentMissedTotal(data) {
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.GET_APPOINTMENT_MISSED_TOTAL_LIST_START,
      ActionType.GET_APPOINTMENT_MISSED_TOTAL_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_MISSED_TOTAL_LIST_ERROR,
    ],
    data,
  };
}

function getAppointmentCompleteTotal(data) {
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.GET_APPOINTMENT_COMPLETE_TOTAL_LIST_START,
      ActionType.GET_APPOINTMENT_COMPLETE_TOTAL_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_COMPLETE_TOTAL_LIST_ERROR,
    ],
    data,
  };
}

function getAppointmentWithoutLateCancelledTotal(data) {
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_START,
      ActionType.GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_ERROR,
    ],
    data,
  };
}

function getAppointmentAllTotal(data) {
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.GET_APPOINTMENT_ALL_TOTAL_LIST_START,
      ActionType.GET_APPOINTMENT_ALL_TOTAL_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_ALL_TOTAL_LIST_ERROR,
    ],
    data,
  };
}

export function loadTotalCancelledMissedCompletedReleasedAppTList(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    const upcomingTotal = Object.values(STATUS_TYPES)
      .filter(
        (i) =>
          i.indexOf("STATUS_CONFIRMED") >= 0 ||
          i.indexOf("STATUS_CONFIRMED2") >= 0 ||
          i.indexOf("STATUS_CONFIRMED_W") >= 0 ||
          i.indexOf("STATUS_UNCONFIRMED") >= 0 ||
          i.indexOf("STATUS_RESCHEDULED") >= 0,
      )
      ?.map((c) => c)
      .join(",");
    const onlyCancelledTotal = Object.values(STATUS_TYPES)
      .filter(
        (i) =>
          i.indexOf("CANCELLED") >= 0 &&
          !i.includes("CANCELLED_LATE") &&
          !i.includes("STATUS_CANCELLED_NO_PAYMENT"),
      )
      ?.map((c) => c)
      .join(",");
    const missedTotal = Object.values(STATUS_TYPES)
      .filter(
        (i) =>
          (i.indexOf("MISSED") >= 0 || i.indexOf("CANCELLED_LATE") >= 0) &&
          !i.includes("STATUS_CANCELLED_NO_PAYMENT"),
      )
      ?.map((c) => c)
      .join(",");
    const completeTotal = Object.values(STATUS_TYPES)
      .filter(
        (i) =>
          i.indexOf("COMPLETE") >= 0 ||
          i.indexOf("CHECKED_IN") >= 0 ||
          i.indexOf("BEING_SEEN") >= 0,
      )
      ?.map((c) => c)
      .join(",");
    const releaseTotal = Object.values(STATUS_TYPES)
      .filter(
        (i) =>
          // i.indexOf("COMPLETE") >= 0 ||
          // i.indexOf("CHECKED_IN") >= 0 ||
          // i.indexOf("BEING_SEEN") >= 0 ||
          // i.indexOf("CANCELLED") >= 0 ||
          // i.indexOf("MISSED") >= 0 ||
          // i.indexOf("CANCELLED_LATE") >= 0 ||
          // i.indexOf("STATUS_CONFIRMED") >= 0 ||
          // i.indexOf("STATUS_UNCONFIRMED") >= 0 ||
          // i.indexOf("STATUS_RESCHEDULED") >= 0 ||
          i.indexOf("STATUS_CANCELLED_NO_PAYMENT") >= 0,
      )
      ?.map((c) => c)
      .join(",");
    const transfer = {
      limit: 1,
      patientKey,
      sortField: "start_date_desc",
      start: 0,
    };

    Promise.all([
      dispatch(
        getAppointmentUpcomingTotal({
          ...transfer,
          statusCodes: upcomingTotal,
        }),
      ),
      dispatch(
        getAppointmentMissedTotal({
          ...transfer,
          statusCodes: missedTotal,
        }),
      ),

      dispatch(
        getAppointmentCompleteTotal({
          ...transfer,
          statusCodes: completeTotal,
        }),
      ),
      dispatch(
        getAppointmentWithoutLateCancelledTotal({
          ...transfer,
          statusCodes: onlyCancelledTotal,
        }),
      ),
      dispatch(
        getAppointmentAllTotal({
          ...transfer,
          statusCodes: releaseTotal,
        }),
      ),
    ])
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPatientFormsList(patientKey) {
  return (dispatch, getState) => {
    const {
      auth: { chosenClinic },
    } = getState();
    const data = {
      clinicId: chosenClinic,
      patientKey,
      start: 0,
      limit: 25,
    };

    return dispatch(getPatientFormsListApi(data)).catch((error) => showMessage(error));
  };
}

export function loadPatientFormsList(patientKey) {
  return (dispatch, getState) => {
    const {
      patientRegister: { formsList, fetchingFormsList, formsListTotalCount },
      auth: { chosenClinic },
    } = getState();

    if (formsListTotalCount === formsList.length || fetchingFormsList) return;

    const data = {
      clinicId: chosenClinic,
      patientKey,
      start: formsList.length,
      limit: 25,
    };

    dispatch(getPatientFormsListApi(data)).catch((error) => {
      showMessage(error);
    });
  };
}

export function patientDetailsEdit() {
  return {
    type: ActionType.PATIENT_DETAILS_SET_EDIT,
  };
}

export function patientMedicalAlertsEdit() {
  return {
    type: ActionType.PATIENT_MEDICAL_ALERTS_SET_EDIT,
  };
}

export function patientMedicalAlertsView() {
  return {
    type: ActionType.PATIENT_MEDICAL_ALERTS_SET_VIEW,
  };
}

export function patientMedicationAddAlert() {
  return {
    type: ActionType.PATIENT_MEDICATION_ADD_ALERT,
  };
}

export function patientMedicationDeleteAlert(index) {
  return {
    type: ActionType.PATIENT_MEDICATION_DELETE_ALERT,
    index,
  };
}

export function patientMedicationAlertsEdit() {
  return {
    type: ActionType.PATIENT_MEDICATION_ALERTS_SET_EDIT,
  };
}

export function getMedicationAlertsEdit(patientKey) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_INIT,
    });

    dispatch(startLoader());

    const promises = [];

    promises.push(dispatch(getMedicationAlertsEditFn({ patientKey })));
    promises.push(dispatch(getPersonalDetails({ patientKey })));

    Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMedicalAlertsEdit(patientKey) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_INIT,
    });

    dispatch(startLoader());

    const promises = [];

    promises.push(dispatch(getMedicalAlertsEditFn({ patientKey })));
    promises.push(dispatch(getMedicalAlertsCategoriesFn({ patientKey })));
    promises.push(dispatch(getMedicalAlertsSubCategoriesFn({ patientKey })));
    promises.push(dispatch(getPersonalDetails({ patientKey })));

    Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveMedicationAlerts() {
  return (dispatch, getState) => {
    const {
      patientRegister: {
        patientMedicationAlerts: {
          units,
          medicationAlerts: { alerts },
          patient: { patientKey },
        },
      },
      form: {
        patientMedicationAlertsEdit: { values },
      },
    } = getState();

    const data = {
      alerts: [],
      patientKey,
    };

    _.forEach(alerts, (item, index) => {
      data.alerts.push({
        dosage: values[`dosage${index}`],
        medicationName: values[`name${index}`],
        purpose: values[`purpose${index}`],
        unit: _.find(units, ["description", values[`unit${index}`]]),
        endDate: getCompanyEndOfDay(values[`endDate${index}`]),
        startDate: getCompanyStartOfDay(values[`startDate${index}`]),
        frequency: values[`frequency${index}`],
      });
    });

    dispatch(startLoader());

    return dispatch(saveMedicationAlertsFn(data))
      .then(() => {
        dispatch(stopLoader());

        return values.temporary;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveMedicalAlerts() {
  return (dispatch, getState) => {
    const {
      patientRegister: {
        signature,
        patientMedicalAlerts,
        personalDetails: {
          patient: { patientKey },
        },
      },
      form: {
        patientMedicalAlertsEdit: { values },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      alerts: [],
      patientKey,
      signature: (signature || "").slice(22),
    };
    // const smokingFields = ["smokingFrequency", "startSmokingAge", "stopSmokingAge"];

    (patientMedicalAlerts.alerts || []).forEach((element) => {
      const currentObject = { ...element };
      const currentNote = `note${element.id}`;
      const switchValue = values[`switch${element.id}`];
      currentObject.medicalName = element.medicalName || "";
      currentObject.type = element.type;
      currentObject.diseaseCode = element.code;

      if (!_.isUndefined(switchValue)) {
        currentObject.selected = switchValue;
      }

      if (values[currentNote] && values[currentNote] !== "") {
        currentObject.note = values[currentNote];
      }

      /*  if (element.subType && element.subType?.code === "SMOKING") {
        smokingFields.forEach((field) => {
          if (values[field]) {
            currentObject[field] = Number(values[field]);
          }
        });

        if (values.smokingStatus && values.smokingStatus.code) {
          currentObject.smokingStatus = values.smokingStatus;
        }

        if (values.smokingDevice && values.smokingDevice.code) {
          currentObject.smokingDevice = values.smokingDevice;
        }

        if (values.smokingFrequencyType && values.smokingFrequencyType.code) {
          currentObject.smokingFrequencyType = values.smokingFrequencyType;
        }
      }

      if (element.subType && element.subType?.code === "ALCOHOL") {
        if (values.alcoholDrinkingStatus && values.alcoholDrinkingStatus.code) {
          currentObject.alcoholDrinkingStatus = values.alcoholDrinkingStatus;
        }
      }

      if (element.subType && element.subType?.code === "SUBSTANCE") {
        if (values.substanceAbuseStatus && values.substanceAbuseStatus.code) {
          currentObject.substanceAbuseStatus = values.substanceAbuseStatus;
        }
      }

      if (element.subType && element.subType?.code === "EDUCATION") {
        if (values.educationLevel && values.educationLevel.code) {
          currentObject.educationLevel = values.educationLevel;
        }
      }

      if (element.subType && element.subType?.code === "EMPLOYMENT") {
        if (values.employmentStatus && values.employmentStatus.code) {
          currentObject.employmentStatus = values.employmentStatus;
        }
      }

      if (element.subType && element.subType?.code === "FAMILY_HISTORY") {
        if (values.familyMember && values.familyMember.code) {
          currentObject.familyMember = values.familyMember;
        }
      }*/

      data.alerts.push(currentObject);
    });

    return dispatch(saveMedicalAlertsFn(data))
      .then(() => {
        dispatch(stopLoader());
        return { values, patientKey };
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDetailsEdit(patientKey, companyTempPatientId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_INIT,
    });

    dispatch(startLoader());

    const promises = [
      dispatch(getNationality()),
      dispatch(getProfession()),
      dispatch(getGender()),
      dispatch(getTitles()),
      dispatch(getOccupationList()),
      dispatch(getPatientLanguages()),
      dispatch(getMaritalStatus()),
      dispatch(getCountry()),
      dispatch(getReferralTypes()),
      dispatch(getPcpRelationshipType()),
      dispatch(getPcpRoleTypeFn()),
      dispatch(getPersonalDetails({ patientKey })),
    ];

    if (companyTempPatientId) {
      promises.push(dispatch(getTempPatientApi({ companyTempPatientId })));
    }

    Promise.all(promises)
      .then((results) => {
        const promises = [];

        if (patientKey) {
          const { address } = results[results.length - 1].patient;

          if (address && address.country && address.country.id) {
            promises.push(dispatch(getState({ countryId: address.country.id })));

            if (address.state && address.state.id) {
              promises.push(
                dispatch(
                  getCity({
                    countryId: address.country.id,
                    stateId: address.state.id,
                  }),
                ),
              );
            }
          }
        }

        return Promise.all(promises);
      })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function addAnother() {
  return {
    type: ActionType.PATIENT_REGISTER_ADD_ANOTHER,
  };
}

export function deleteAnother() {
  return {
    type: ActionType.PATIENT_REGISTER_DELETE_ANOTHER,
  };
}

export function selectHousehold() {
  return (dispatch, getState) => {
    const { listHousehold, activeHousehold } = getState().patientRegister;

    if (!activeHousehold) return;

    const household = listHousehold.find((item) => item.id == activeHousehold);

    dispatch({
      type: ActionType.PATIENT_REGISTER_SELECT_HOUSEHOLD,
      household,
    });

    dispatch(closeHouseHold());
  };
}

export function chooseHousehold(id) {
  return {
    type: ActionType.PATIENT_REGISTER_CHOOSE_HOUSEHOLD,
    id,
  };
}
export function setCardReaderInfoModalVisibility() {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_OPEN_CLOSE_CARD_READER_INFO,
    });
  };
}

export function searchHouseHold(event) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_SEARCH_HOUSEHOLD,
      value: event.target.value,
    });

    dispatch(initPatientList());
  };
}

export function initPatientList() {
  return (dispatch, getState) => {
    const { searchHousehold } = getState().patientRegister;

    dispatch(
      getPatientList({
        name: searchHousehold,
        start: 0,
        limit: 25,
      }),
    ).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function loadPatientList() {
  return (dispatch, getState) => {
    const { listHousehold, listHouseholdTotalCount, searchHousehold } = getState().patientRegister;
    if (listHousehold.length === listHouseholdTotalCount) return;

    dispatch(
      getPatientList({
        name: searchHousehold,
        start: listHousehold.length,
        limit: 25,
      }),
    ).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function addSignature(base64) {
  return {
    type: ActionType.PATIENT_REGISTER_ADD_SIGNATURE,
    base64,
  };
}

export function openSignature() {
  return {
    type: ActionType.PATIENT_REGISTER_OPEN_SIGNATURE,
  };
}

export function closeSignature() {
  return {
    type: ActionType.PATIENT_REGISTER_CLOSE_SIGNATURE,
  };
}

export function openHouseHold() {
  return {
    type: ActionType.PATIENT_REGISTER_OPEN_HOUSEHOLD,
  };
}

export function closeHouseHold() {
  return {
    type: ActionType.PATIENT_REGISTER_CLOSE_HOUSEHOLD,
  };
}

export function removeHouseHold() {
  return {
    type: ActionType.PATIENT_REGISTER_REMOVE_HOUSEHOLD,
  };
}

export function chooseCountry(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_CHOOSE_COUNTRY,
    });

    dispatch(getState({ countryId: value }));
  };
}

export function chooseState(stateId, countryId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_REGISTER_CHOOSE_STATE,
    });

    return dispatch(
      getCity({
        countryId,
        stateId,
      }),
    );
  };
}

export function savePdf(file) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_CLAIMS_APPROVALS_SAVE_PDF,
      file,
    });
  };
}

export function showReferralDescription(boolean) {
  return {
    type: ActionType.PATIENT_FORMS_SHOW_REFERRAL_DESCRIPTION_MODAL,
    boolean,
  };
}

export function openDocFieldsModal() {
  return {
    type: ActionType.PATIENT_FORMS_OPEN_DOC_FIELDS_MODAL,
  };
}

export function closeDocFieldsModal() {
  return {
    type: ActionType.PATIENT_FORMS_CLOSE_DOC_FIELDS_MODAL,
  };
}

export function docFieldsOpenEditMode(editType) {
  return {
    type: ActionType.DOC_FIELDS_OPEN_EDIT_MODE,
    editType,
  };
}

export function docFieldsCloseEditMode(member) {
  return (dispatch) => {
    dispatch({
      type: ActionType.DOC_FIELDS_CLOSE_EDIT_MODE,
      member,
    });

    if (member) {
      dispatch(startLoader());

      const data = {
        ids: [member.id],
      };

      dispatch({
        api: MemberApi.checkSignature,
        types: [
          ActionType.DOC_FIELDS_GET_SIGNATURE_START,
          ActionType.DOC_FIELDS_GET_SIGNATURE_SUCCESS,
          ActionType.DOC_FIELDS_GET_SIGNATURE_ERROR,
        ],
        data,
      })
        .then(() => dispatch(stopLoader()))
        .catch((error) => dispatch(stopLoader(error)));
    }
  };
}

export function docFieldsSelectMember(id) {
  return {
    type: ActionType.DOC_FIELDS_SELECT_MEMBER,
    id,
  };
}

export function getPatientFormsEdit(query) {
  return (dispatch) => {
    dispatch(startLoader());

    const transfer = {};
    transfer.patientKey = query.patientKey;
    transfer.id = query.templateId;

    const promises = [
      dispatch({
        api: PatientApi.signInForms.getFilledItem,
        types: [
          ActionType.PATIENT_GET_FORM_EDIT_START,
          ActionType.PATIENT_GET_FORM_EDIT_SUCCESS,
          ActionType.PATIENT_GET_FORM_EDIT_ERROR,
        ],
        data: transfer,
      }),
      dispatch({
        api: MemberApi.getChoose,
        types: [
          ActionType.DOC_FIELDS_GET_MEMBERS_START,
          ActionType.DOC_FIELDS_GET_MEMBERS_SUCCESS,
          ActionType.DOC_FIELDS_GET_MEMBERS_ERROR,
        ],
        data: {},
      }),
    ];

    if (query.id) {
      promises.push(
        dispatch({
          api: PatientApi.getItem,
          types: [
            ActionType.PATIENT_GET_FORM_EDIT_ITEM_START,
            ActionType.PATIENT_GET_FORM_EDIT_ITEM_SUCCESS,
            ActionType.PATIENT_GET_FORM_EDIT_ITEM_ERROR,
          ],
          data: {
            patientKey: query.patientKey,
            id: query.id,
          },
        }),
      );
    }

    Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function submitPatientFormsEdit(query) {
  return (dispatch, getState) => {
    const { patientRegister } = getState();
    const { formsItem, signature } = patientRegister;

    const data = formsItem;
    data.patientKey = query.patientKey;
    data.id = query.id;
    const promise = new Promise(() => false);

    if (formsItem.hasDentistSignatureField) {
      if (!(patientRegister.dentist && patientRegister.dentist.code)) {
        dispatch(
          openSimpleModal({
            body: `${localStorage.getItem("doctorLabel")} signature required`,
          }),
        );
        return promise;
      }
      data.dentist = {
        id: patientRegister.dentist.id,
        code: patientRegister.dentist.code,
      };
    }
    if (formsItem.hasGuardianSignatureField) {
      if (!(patientRegister.guardian && patientRegister.guardian.code)) {
        dispatch(
          openSimpleModal({
            body: "guardian signature required",
          }),
        );
        return promise;
      }
      data.guardian = {
        id: patientRegister.guardian.id,
        code: patientRegister.guardian.code,
      };
    }
    if (formsItem.hasHygienistSignatureField) {
      if (!(patientRegister.hygienist && patientRegister.hygienist.code)) {
        dispatch(
          openSimpleModal({
            body: "hygienist signature required",
          }),
        );
        return promise;
      }
      data.hygienist = {
        id: patientRegister.hygienist.id,
        code: patientRegister.hygienist.code,
      };
    }
    if (formsItem.hasNurseSignatureField) {
      if (!(patientRegister.nurse && patientRegister.nurse.code)) {
        dispatch(
          openSimpleModal({
            body: `${localStorage.getItem("nurseLabel")} signature required`,
          }),
        );
        return promise;
      }
      data.nurse = {
        id: patientRegister.nurse.id,
        code: patientRegister.nurse.code,
      };
    }
    if (!signature) {
      dispatch(
        openSimpleModal({
          body: "patient signature required",
        }),
      );
      return promise;
    }
    data.patientSignature = signature.slice(22);

    if (formsItem.signContent.indexOf("${text_area_1}") !== -1) {
      data.textArea1 = {
        code: patientRegister.textArea1,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_2}") !== -1) {
      data.textArea2 = {
        code: patientRegister.textArea2,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_3}") !== -1) {
      data.textArea3 = {
        code: patientRegister.textArea3,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_4}") !== -1) {
      data.textArea4 = {
        code: patientRegister.textArea4,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_5}") !== -1) {
      data.textArea5 = {
        code: patientRegister.textArea5,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_6}") !== -1) {
      data.textArea6 = {
        code: patientRegister.textArea6,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_7}") !== -1) {
      data.textArea7 = {
        code: patientRegister.textArea7,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_8}") !== -1) {
      data.textArea8 = {
        code: patientRegister.textArea8,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_9}") !== -1) {
      data.textArea9 = {
        code: patientRegister.textArea9,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_10}") !== -1) {
      data.textArea10 = {
        code: patientRegister.textArea10,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_11}") !== -1) {
      data.textArea11 = {
        code: patientRegister.textArea11,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_12}") !== -1) {
      data.textArea12 = {
        code: patientRegister.textArea12,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_13}") !== -1) {
      data.textArea13 = {
        code: patientRegister.textArea13,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_14}") !== -1) {
      data.textArea14 = {
        code: patientRegister.textArea14,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_15}") !== -1) {
      data.textArea15 = {
        code: patientRegister.textArea15,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_16}") !== -1) {
      data.textArea16 = {
        code: patientRegister.textArea16,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_17}") !== -1) {
      data.textArea17 = {
        code: patientRegister.textArea17,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_18}") !== -1) {
      data.textArea18 = {
        code: patientRegister.textArea18,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_19}") !== -1) {
      data.textArea19 = {
        code: patientRegister.textArea19,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_20}") !== -1) {
      data.textArea20 = {
        code: patientRegister.textArea20,
      };
    }
    if (formsItem.signContent.indexOf("${image_1}") !== -1) {
      data.image1 = patientRegister.image1;
    }

    dispatch(startLoader());

    const api = data.id ? PatientApi.signConsentForm : PatientApi.registerSignConsentForm;
    return dispatch({
      api,
      types: [
        ActionType.PATIENT_FORMS_SIGN_START,
        ActionType.PATIENT_FORMS_SIGN_SUCCESS,
        ActionType.PATIENT_FORMS_SIGN_ERROR,
      ],
      data,
    })
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function savePatientFormsEdit(query) {
  return (dispatch, getState) => {
    const { patientRegister } = getState();
    const { formsItem, signature } = patientRegister;

    const data = formsItem;
    data.patientKey = query.patientKey;
    data.id = query.id;

    if (formsItem.hasDentistSignatureField) {
      if (patientRegister.dentist && patientRegister.dentist.code) {
        data.dentist = {
          id: patientRegister.dentist.id,
          code: patientRegister.dentist.code,
        };
      }
    }
    if (formsItem.hasGuardianSignatureField) {
      if (patientRegister.guardian && patientRegister.guardian.code) {
        data.guardian = {
          id: patientRegister.guardian.id,
          code: patientRegister.guardian.code,
        };
      }
    }
    if (formsItem.hasHygienistSignatureField) {
      if (patientRegister.hygienist && patientRegister.hygienist.code) {
        data.hygienist = {
          id: patientRegister.hygienist.id,
          code: patientRegister.hygienist.code,
        };
      }
    }
    if (formsItem.hasNurseSignatureField) {
      if (patientRegister.nurse && patientRegister.nurse.code) {
        data.nurse = {
          id: patientRegister.nurse.id,
          code: patientRegister.nurse.code,
        };
      }
    }
    if (signature) {
      data.patientSignature = signature.slice(22);
    }

    if (formsItem.signContent.indexOf("${text_area_1}") !== -1) {
      data.textArea1 = {
        code: patientRegister.textArea1,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_2}") !== -1) {
      data.textArea2 = {
        code: patientRegister.textArea2,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_3}") !== -1) {
      data.textArea3 = {
        code: patientRegister.textArea3,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_4}") !== -1) {
      data.textArea4 = {
        code: patientRegister.textArea4,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_5}") !== -1) {
      data.textArea5 = {
        code: patientRegister.textArea5,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_6}") !== -1) {
      data.textArea6 = {
        code: patientRegister.textArea6,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_7}") !== -1) {
      data.textArea7 = {
        code: patientRegister.textArea7,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_8}") !== -1) {
      data.textArea8 = {
        code: patientRegister.textArea8,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_9}") !== -1) {
      data.textArea9 = {
        code: patientRegister.textArea9,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_10}") !== -1) {
      data.textArea10 = {
        code: patientRegister.textArea10,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_11}") !== -1) {
      data.textArea11 = {
        code: patientRegister.textArea11,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_12}") !== -1) {
      data.textArea12 = {
        code: patientRegister.textArea12,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_13}") !== -1) {
      data.textArea13 = {
        code: patientRegister.textArea13,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_14}") !== -1) {
      data.textArea14 = {
        code: patientRegister.textArea14,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_15}") !== -1) {
      data.textArea15 = {
        code: patientRegister.textArea15,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_16}") !== -1) {
      data.textArea16 = {
        code: patientRegister.textArea16,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_17}") !== -1) {
      data.textArea17 = {
        code: patientRegister.textArea17,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_18}") !== -1) {
      data.textArea18 = {
        code: patientRegister.textArea18,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_19}") !== -1) {
      data.textArea19 = {
        code: patientRegister.textArea19,
      };
    }
    if (formsItem.signContent.indexOf("${text_area_20}") !== -1) {
      data.textArea20 = {
        code: patientRegister.textArea20,
      };
    }
    if (formsItem.signContent.indexOf("${image_1}") !== -1) {
      data.image1 = patientRegister.image1;
    }

    dispatch(startLoader());

    return dispatch({
      api: PatientApi.saveItem,
      types: [
        ActionType.PATIENT_FORMS_SIGN_START,
        ActionType.PATIENT_FORMS_SIGN_SUCCESS,
        ActionType.PATIENT_FORMS_SIGN_ERROR,
      ],
      data,
    })
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function guardianSignModalShow() {
  return {
    type: ActionType.PATIENT_FORMS_GUARDIAN_SIGNATURE_SHOW,
  };
}

export function guardianSignModalHide() {
  return {
    type: ActionType.PATIENT_FORMS_GUARDIAN_SIGNATURE_HIDE,
  };
}

export function guardianAddSignature(signature) {
  return {
    type: ActionType.PATIENT_FORMS_SIGN_GUARDIAN,
    signature,
  };
}

export function docFieldsSetSearchValue(value) {
  return {
    type: ActionType.DOC_FIELDS_SET_SEARCH_VALUE,
    value,
  };
}

export function docFieldsChangeTextArea(field, value) {
  return {
    type: ActionType.DOC_FIELDS_CHANGE_TEXT_AREA,
    value,
    field,
  };
}

export function openAddModal(value) {
  return {
    type: ActionType.PATIENT_FORMS_OPEN_ADD_MODAL,
    value,
  };
}

export function closeAddModal(value) {
  return {
    type: ActionType.PATIENT_FORMS_CLOSE_ADD_MODAL,
    value,
  };
}

export function getAddConsentFormList(categoryId = null) {
  return (dispatch, getState) => {
    const {
      patientRegister: { searchValueAddConsentForm },
      auth: { chosenClinic },
    } = getState();
    dispatch(
      getAddConsentForm({
        filterKey: searchValueAddConsentForm,
        limit: 25,
        categoryId,
        clinicId: chosenClinic,
      }),
    ).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadAddConsentFormList(categoryId = null) {
  return (dispatch, getState) => {
    const {
      searchValueAddConsentForm,
      addConsentFormList,
      addConsentFormListTotalCount,
      fetchingAddConsentForm,
    } = getState().patientRegister;

    if (addConsentFormList.length == addConsentFormListTotalCount) return;
    if (fetchingAddConsentForm) return;

    dispatch(
      getAddConsentForm({
        start: addConsentFormList.length,
        filterKey: searchValueAddConsentForm,
        limit: 25,
        categoryId,
      }),
    ).catch((error) => dispatch(showMessage(error)));
  };
}

export function setSearchValueList(value, categoryId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.ADD_CONSENT_FORM_SEARCH_CHANGE,
      value,
    });

    dispatch(getAddConsentFormList(categoryId));
  };
}

export function deletePatientImage() {
  return (dispatch) => {
    dispatch({
      type: ActionType.DELETE_PATIENT_IMAGE,
    });
  };
}

export function addConsentForm(templateItem, patientKey, closeAfter = true) {
  return (dispatch, getState) => {
    const {
      appointment: { appointment },
    } = getState();
    const appointmentBeingSeen =
      appointment?.status?.code == STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN
        ? { appointmentId: appointment?.id }
        : {};
    dispatch(startLoader());
    dispatch({
      api: PatientApi.signInForms.saveItem,
      types: [
        ActionType.ADD_CONSENT_FORM_START,
        ActionType.ADD_CONSENT_FORM_SUCCESS,
        ActionType.ADD_CONSENT_FORM_ERROR,
      ],
      data: {
        ...appointmentBeingSeen,
        templateItem,
        patientKey,
      },
    })
      .then(() => {
        if (closeAfter) {
          dispatch(closeAddModal());
        }
        dispatch(stopLoader());
        dispatch(getPatientFormsList(patientKey));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getAddConsentForm(data) {
  return {
    api: ConsentFormApi.getTypeAhead,
    types: [
      ActionType.ADD_CONSENT_FORM_GET_LIST_START,
      ActionType.ADD_CONSENT_FORM_GET_LIST_SUCCESS,
      ActionType.ADD_CONSENT_FORM_GET_LIST_ERROR,
    ],
    data,
  };
}

function getConsentFormCategoryListApi(data) {
  return {
    api: ConsentFormApi.getCategoryList,
    types: [
      ActionType.CONSENT_FORM_GET_CATEGORY_LIST_START,
      ActionType.CONSENT_FORM_GET_CATEGORY_LIST_SUCCESS,
      ActionType.CONSENT_FORM_GET_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

function getPatientClaimsListApi(data) {
  return {
    api: PatientApi.claims.getList,
    types: [
      ActionType.CLAIMS_GET_LIST_START,
      ActionType.CLAIMS_GET_LIST_SUCCESS,
      ActionType.CLAIMS_GET_LIST_ERROR,
    ],
    data,
  };
}

function getPatientClaimsStatusListApi() {
  return {
    api: PatientApi.claims.getStatusList,
    types: [
      ActionType.CLAIMS_GET_STATUS_LIST_START,
      ActionType.CLAIMS_GET_STATUS_LIST_SUCCESS,
      ActionType.CLAIMS_GET_STATUS_LIST_ERROR,
    ],
  };
}

function getPatientClaimsDocTypeListApi() {
  return {
    api: PatientApi.claims.getDocTypeList,
    types: [
      ActionType.CLAIMS_GET_DOC_TYPE_LIST_START,
      ActionType.CLAIMS_GET_DOC_TYPE_LIST_SUCCESS,
      ActionType.CLAIMS_GET_DOC_TYPE_LIST_ERROR,
    ],
  };
}

function getPatientClaimedStatusListApi() {
  return {
    api: PatientApi.approvals.getClaimedStatusList,
    types: [
      ActionType.CLAIMS_GET_CLAIMED_STATUS_LIST_START,
      ActionType.CLAIMS_GET_CLAIMED_STATUS_LIST_SUCCESS,
      ActionType.CLAIMS_GET_CLAIMED_STATUS_LIST_ERROR,
    ],
  };
}

function getPatientApprovalsListApi(data) {
  return {
    api: PatientApi.approvals.getList,
    types: [
      ActionType.APPROVALS_GET_LIST_START,
      ActionType.APPROVALS_GET_LIST_SUCCESS,
      ActionType.APPROVALS_GET_LIST_ERROR,
    ],
    data,
  };
}

function getPatientApprovalsStatusListApi() {
  return {
    api: PatientApi.approvals.getStatusList,
    types: [
      ActionType.APPROVALS_GET_STATUS_LIST_START,
      ActionType.APPROVALS_GET_STATUS_LIST_SUCCESS,
      ActionType.APPROVALS_GET_STATUS_LIST_ERROR,
    ],
  };
}

function getPatientApprovalsDocTypeListApi() {
  return {
    api: PatientApi.approvals.getDocTypeList,
    types: [
      ActionType.APPROVALS_GET_DOC_TYPE_LIST_START,
      ActionType.APPROVALS_GET_DOC_TYPE_LIST_SUCCESS,
      ActionType.APPROVALS_GET_DOC_TYPE_LIST_ERROR,
    ],
  };
}

function printClaimApi(data) {
  return {
    api: PatientApi.claims.printClaim,
    types: [
      ActionType.PRINT_CLAIM_START,
      ActionType.PRINT_CLAIM_SUCCESS,
      ActionType.PRINT_CLAIM_ERROR,
    ],
    data,
  };
}

export function saveClaimItemNoteApi(data) {
  return {
    api: PatientApi.approvals.saveNote,
    types: [
      ActionType.SAVE_CLAIM_ITEM_NOTE_START,
      ActionType.SAVE_CLAIM_ITEM_NOTE_SUCCESS,
      ActionType.SAVE_CLAIM_ITEM_NOTE_ERROR,
    ],
    data,
  };
}

function getPatientList(data) {
  return {
    api: PatientApi.getList,
    types: [
      ActionType.PATIENT_REGISTER_GET_PATIENT_LIST_START,
      ActionType.PATIENT_REGISTER_GET_PATIENT_LIST_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_PATIENT_LIST_ERROR,
    ],
    data,
  };
}

export function uploadPhoto(files) {
  return (dispatch) => {
    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file, file.name);
    });

    dispatch(startLoader());

    dispatch({
      api: PatientApi.uploadPhoto,
      types: [
        ActionType.PATIENT_REGISTER_UPLOAD_PHOTO_START,
        ActionType.PATIENT_REGISTER_UPLOAD_PHOTO_SUCCESS,
        ActionType.PATIENT_REGISTER_UPLOAD_PHOTO_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getMedicalAlertsEditFn(data) {
  return {
    api: PatientApi.alerts.getMedicalAlerts,
    types: [
      ActionType.PATIENT_GET_MEDICAL_ALERTS_START,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_SUCCESS,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_ERROR,
    ],
    data,
  };
}
function getMedicalAlertsCategoriesFn(data) {
  return {
    api: PatientApi.alerts.getMedicalAlertsCategories,
    types: [
      ActionType.PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_START,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_SUCCESS,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_ERROR,
    ],
    data,
  };
}

function getMedicationAlertsEditFn(data) {
  return {
    api: PatientApi.alerts.getMedicationAlerts,
    types: [
      ActionType.PATIENT_GET_MEDICATION_ALERTS_START,
      ActionType.PATIENT_GET_MEDICATION_ALERTS_SUCCESS,
      ActionType.PATIENT_GET_MEDICATION_ALERTS_ERROR,
    ],
    data,
  };
}

function saveMedicalAlertsFn(data) {
  return {
    api: PatientApi.alerts.saveMedicalAlerts,
    types: [
      ActionType.PATIENT_MEDICAL_ALERTS_SAVE_START,
      ActionType.PATIENT_MEDICAL_ALERTS_SAVE_SUCCESS,
      ActionType.PATIENT_MEDICAL_ALERTS_SAVE_ERROR,
    ],
    data,
  };
}

export function saveMedicationAlertsFn(data) {
  return {
    api: PatientApi.alerts.saveMedicationAlerts,
    types: [
      ActionType.PATIENT_MEDICATION_ALERTS_SAVE_START,
      ActionType.PATIENT_MEDICATION_ALERTS_SAVE_SUCCESS,
      ActionType.PATIENT_MEDICATION_ALERTS_SAVE_ERROR,
    ],
    data,
  };
}

function getCity(data) {
  return {
    api: AddressApi.getCity,
    types: [
      ActionType.PATIENT_REGISTER_GET_CITY_START,
      ActionType.PATIENT_REGISTER_GET_CITY_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_CITY_ERROR,
    ],
    data,
  };
}

function getState(data) {
  return {
    api: AddressApi.getState,
    types: [
      ActionType.PATIENT_REGISTER_GET_STATE_START,
      ActionType.PATIENT_REGISTER_GET_STATE_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_STATE_ERROR,
    ],
    data,
  };
}

function getCountry(data) {
  return {
    api: AddressApi.getCountry,
    types: [
      ActionType.PATIENT_REGISTER_GET_COUNTRY_START,
      ActionType.PATIENT_REGISTER_GET_COUNTRY_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_COUNTRY_ERROR,
    ],
    data,
  };
}

function getReferralTypes(data) {
  return {
    api: PatientApi.getReferralTypes,
    types: [
      ActionType.PATIENT_REGISTER_GET_REFERRAL_TYPES_START,
      ActionType.PATIENT_REGISTER_GET_REFERRAL_TYPES_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_REFERRAL_TYPES_ERROR,
    ],
    data,
  };
}

function getNationality(data) {
  return {
    api: PatientApi.getNationality,
    types: [
      ActionType.PATIENT_REGISTER_GET_NATIONALITY_START,
      ActionType.PATIENT_REGISTER_GET_NATIONALITY_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_NATIONALITY_ERROR,
    ],
    data,
  };
}
function getProfession(data) {
  return {
    api: PatientApi.getProfession,
    types: [
      ActionType.PATIENT_REGISTER_GET_PROFESSION_START,
      ActionType.PATIENT_REGISTER_GET_PROFESSION_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_PROFESSION_ERROR,
    ],
    data,
  };
}

function getGender(data) {
  return {
    api: PatientApi.getGender,
    types: [
      ActionType.PATIENT_REGISTER_GET_GENDER_START,
      ActionType.PATIENT_REGISTER_GET_GENDER_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_GENDER_ERROR,
    ],
    data,
  };
}

function getTitles(data) {
  return {
    api: PatientApi.getTitles,
    types: [
      ActionType.PATIENT_REGISTER_GET_TITLES_START,
      ActionType.PATIENT_REGISTER_GET_TITLES_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_TITLES_ERROR,
    ],
    data,
  };
}

function getOccupationList(data) {
  return {
    api: PatientApi.getOccupationListApi,
    types: [
      ActionType.PATIENT_REGISTER_GET_OCCUPATION_START,
      ActionType.PATIENT_REGISTER_GET_OCCUPATION_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_OCCUPATION_ERROR,
    ],
    data,
  };
}

function getMaritalStatus(data) {
  return {
    api: PatientApi.getMaritalStatus,
    types: [
      ActionType.PATIENT_REGISTER_GET_MARITAL_STATUS_START,
      ActionType.PATIENT_REGISTER_GET_MARITAL_STATUS_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_MARITAL_STATUS_ERROR,
    ],
    data,
  };
}

function validateRegister(data) {
  return {
    api: PatientApi.validateRegister,
    types: [
      ActionType.PATIENT_REGISTER_VALIDATE_START,
      ActionType.PATIENT_REGISTER_VALIDATE_SUCCESS,
      ActionType.PATIENT_REGISTER_VALIDATE_ERROR,
    ],
    data,
  };
}

function getPersonalDetails(data) {
  return {
    api: PatientApi.getPersonalDetails,
    types: [
      ActionType.PATIENT_REGISTER_GET_PATIENT_DETAILS_START,
      ActionType.PATIENT_REGISTER_GET_PATIENT_DETAILS_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_PATIENT_DETAILS_ERROR,
    ],
    data,
  };
}

export function getTempPatient(data) {
  return (dispatch) => dispatch(getTempPatientApi(data));
}

function getTempPatientApi(data) {
  return {
    api: PatientApi.getTempProfile,
    types: [
      ActionType.PATIENT_REGISTER_GET_TEMP_PATIENT_START,
      ActionType.PATIENT_REGISTER_GET_TEMP_PATIENT_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_TEMP_PATIENT_ERROR,
    ],
    data,
  };
}

function getPatientDetails(data) {
  return {
    api: PatientApi.getDetailsInfo,
    types: [
      ActionType.PATIENT_REGISTER_GET_DETAILS_INFO_START,
      ActionType.PATIENT_REGISTER_GET_DETAILS_INFO_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_DETAILS_INFO_ERROR,
    ],
    data,
  };
}

export function getPatientLanguages() {
  return {
    api: PatientApi.getLanguages,
    types: [
      ActionType.PATIENT_REGISTER_GET_LANGUAGES_START,
      ActionType.PATIENT_REGISTER_GET_LANGUAGES_SUCCESS,
      ActionType.PATIENT_REGISTER_GET_LANGUAGES_ERROR,
    ],
  };
}

export function getPatientAppointmentsListApi(data) {
  data.sortField = "start_date_desc";
  return {
    api: PatientApi.getPatientAppointmentList,
    types: [
      ActionType.PATIENT_GET_APPOINTMENT_LIST_START,
      ActionType.PATIENT_GET_APPOINTMENT_LIST_SUCCESS,
      ActionType.PATIENT_GET_APPOINTMENT_LIST_ERROR,
    ],
    data,
  };
}

function getPatientFormsListApi(data) {
  return {
    api: PatientApi.signInForms.getList,
    types: [
      ActionType.PATIENT_GET_FORM_LIST_START,
      ActionType.PATIENT_GET_FORM_LIST_SUCCESS,
      ActionType.PATIENT_GET_FORM_LIST_ERROR,
    ],
    data,
  };
}

function deletePatientFormsItemApi(data) {
  return {
    api: PatientApi.signInForms.deleteItem,
    types: [
      ActionType.PATIENT_DELETE_FORM_ITEM_START,
      ActionType.PATIENT_DELETE_FORM_ITEM_SUCCESS,
      ActionType.PATIENT_DELETE_FORM_ITEM_ERROR,
    ],
    data,
  };
}

export function getPatientFormsItem(data) {
  return {
    api: PatientApi.signInForms.getItem,
    types: [
      ActionType.PATIENT_GET_FORM_ITEM_START,
      ActionType.PATIENT_GET_FORM_ITEM_SUCCESS,
      ActionType.PATIENT_GET_FORM_ITEM_ERROR,
    ],
    data,
  };
}

export function changeClaimsApprovalsTab(activeTab) {
  return (dispatch) => {
    dispatch({
      type: ActionType.PATIENT_CLAIMS_APPROVALS_CHANGE_TAB,
      activeTab,
    });
  };
}

export function handleApprovalsFilterItem(data) {
  return {
    type: ActionType.APPROVALS_FILTER_ITEMS,
    data,
  };
}

function getMedicalAlertsSubCategoriesFn(data) {
  return {
    api: PatientApi.alerts.getMedicalAlertsSubCategories,
    types: [
      ActionType.PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_START,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_SUCCESS,
      ActionType.PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_ERROR,
    ],
    data,
  };
}

function getPcpRelationshipType(data) {
  return {
    api: PatientApi.getPcpRelationshipType,
    types: [
      ActionType.PATIENT_PCP_RELATIONSHIP_TYPE_START,
      ActionType.PATIENT_PCP_RELATIONSHIP_TYPE_SUCCESS,
      ActionType.PATIENT_PCP_RELATIONSHIP_TYPE_ERROR,
    ],
    data,
  };
}

function getPcpRoleTypeFn(data) {
  return {
    api: PatientApi.getPcpRoleType,
    types: [
      ActionType.PATIENT_PCP_ROLE_TYPE_START,
      ActionType.PATIENT_PCP_ROLE_TYPE_SUCCESS,
      ActionType.PATIENT_PCP_ROLE_TYPE_ERROR,
    ],
    data,
  };
}
