import { DateTime } from "luxon";
import React, { useMemo } from "react";

import { Chip, ChipProps } from "../ui/Chip";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

interface Props extends Omit<ChipProps, "text"> {
  readonly date: Date;
}

export function BlockedTimeSlotChip({ date, ...props }: Props) {
  const formattedDate = useMemo(() => DateTime.fromJSDate(date).toFormat(DATE_FORMAT_LEGACY), [
    date,
  ]);

  return <Chip {...props} text={formattedDate} />;
}
