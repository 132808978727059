import _ from "lodash";
import React from "react";
import SelectToothSurface from "../../widgets/svg-shapes/SelectToothSurface";

export function SingleSurfacesSlider({
  title,
  tooth,
  isEdit,
  typeTab,
  numericKey,
  addTransaction,
}) {
  return (
    <div className="slider-wrap surface-slider">
      <h4 className="title">{title}</h4>
      <SelectToothSurface
        isSingle={true}
        isEdit={isEdit}
        isSelected={true}
        typeTab={typeTab}
        numericKey={numericKey}
        addTransaction={addTransaction}
        tooth={_.get(tooth, "supernumerary.details")}
      />
    </div>
  );
}
