import React, { useEffect } from "react";
import { Switch, useHistory } from "react-router";
import { Route, useLocation } from "react-router-dom";

import { Routes } from "../constants/Routes";
import Calendar from "../pages/dashboard/CalendarPage";
import Dashboard from "../components/dashboard/Dashboard";
import { memberSelector } from "../reducers/sessionReducer";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";
import InsuranceForm from "../components/insurance/InsuranceForm";
import { LateAppointmentsPage } from "../pages/dashboard/LateAppointmentsPage";
import { BringEarlier } from "../components/bring-earlier/BringEarlier";
import FollowUp from "../components/follow-up/FollowUp";
import RequestedAppointmentsList from "../components/requested-appointments-list/RequestedAppointmentsList";
import CallList from "../components/call-list/CallList";
import WaitingList from "../components/waiting-list/WaitingList";
import RecallList from "../components/recall-list/RecallList";
import MissedCancelledList from "../components/missed-cancelled-list/MissedCancelledList";
import BlockedTimeSlot from "../components/blocked-time-slot/BlockedTimeSlot";
import CalendarPrintDetails from "../components/calendar/calendarPrintDetails/CalendarPrintDetails";
import AppointmentForm from "../components/appointment-form/AppointmentForm";
import ChartPage from "../pages/dashboard/ChartPage";
import DoctorProductionReport from "../components/dashboard/DoctorProductionReport";
import { PerioExam } from "../components/chart/perio-exam/PerioExam";
import MedicationList from "../components/medication/MedicationList";
import Reminders from "../components/reminders/Reminders";
import { BlockedTimeSlotItem } from "../components/blocked-time-slot/BlockedTimeSlotItem";
import ChooseClinic from "../components/choose-clinic/ChooseClinic";
import NewPatientScreen from "../components/appointment-form/first-contact/NewPatientScreen";
import PerioExamView from "../components/chart/perio-exam/PerioExamView";
import Profile from "../components/dashboard/Profile";
import ProfileEdit from "../components/dashboard/ProfileEdit";
import ProfileEditSignature from "../components/dashboard/ProfileEditSignature";
import TestLC from "../components/test/TestLC";
import Stub from "../components/main/Stub";
import ReferralNextVisitList from "../components/referral-next-visit/ReferralNextVisitList";
import WhatsAppChatting from "../components/whatsapp-chatting/WhatsAppChatting";

export default function DashboardContainer() {
  const history = useHistory();
  const location = useLocation();

  // @ts-ignore
  const member = useShallowEqualSelector(memberSelector);

  useEffect(() => {
    if (
      member?.code === "Idle" &&
      location.pathname !== Routes.DashboardProfile &&
      location.pathname !== Routes.Settings
    ) {
      if (location.pathname === Routes.DashboardProfileEdit) {
        history.replace(Routes.DashboardProfileEdit);
        return;
      }
      if (location.pathname === Routes.DashboardProfileEditSignature) {
        history.replace(Routes.DashboardProfileEditSignature);
        return;
      }
      history.replace(Routes.DashboardProfile);
    }
  }, [history, location.pathname, member?.code]);

  return (
    <Switch>
      <Route component={Dashboard} path={Routes.Dashboard} exact={true} />
      <Route component={Calendar} path={Routes.DashboardCalendar} />
      <Route component={FollowUp} path={Routes.DashboardFollowUp} />
      <Route component={CallList} path={Routes.DashboardCallList} />
      <Route component={WaitingList} path={Routes.DashboardWaitingList} />
      <Route component={WhatsAppChatting} path={Routes.DashboardWhatsappChatting} />
      <Route component={BlockedTimeSlot} path={Routes.DashboardBlockedTimeSlot} />
      <Route component={CalendarPrintDetails} path={Routes.DashboardCalendarPrintDetails} />
      <Route component={RecallList} path={Routes.DashboardRecallList} />
      <Route component={MissedCancelledList} path={Routes.DashboardMissedCancelledList} />
      <Route component={InsuranceForm} path={Routes.DashboardInsurance} />
      <Route component={BringEarlier} path={Routes.DashboardBringEarlier} />
      <Route component={LateAppointmentsPage} path={Routes.DashboardLateAppointments} />
      <Route component={AppointmentForm} path={Routes.DashboardAppointment} />
      <Route
        component={RequestedAppointmentsList}
        path={Routes.DashboardRequestedAppointmentsList}
      />

      <Route component={Stub} path={Routes.DashboardStub} />
      <Route component={TestLC} path={Routes.DashboardTestLC} />
      <Route component={ChooseClinic} path={Routes.DashboardChooseClinic} />
      <Route component={ProfileEditSignature} path={Routes.DashboardProfileEditSignature} />
      <Route component={Profile} path={Routes.DashboardProfile} />
      <Route component={ProfileEdit} path={Routes.DashboardProfileEdit} />
      <Route component={PerioExamView} path={Routes.DashboardPerioExamView} />
      <Route component={Reminders} path={Routes.DashboardReminders} />
      <Route component={BlockedTimeSlotItem} path={Routes.DashboardBlockedTimeSlotItem} />
      <Route component={NewPatientScreen} path={Routes.NewPatientScreen} />

      <Route component={DoctorProductionReport} path={Routes.DashboardDoctorProductionReport} />
      <Route component={ChartPage} path={Routes.DashboardChart} />
      <Route component={PerioExam} path={Routes.DashboardPerioExam} />
      <Route component={MedicationList} path={Routes.DashboardMedicationList} />
      <Route component={ReferralNextVisitList} path={Routes.DashboardReferralNextVisitList} />

      {/*<Redirect to={Routes.Dashboard} from="/" exact={true} />*/}
      {/*<Redirect to={Routes.Dashboard} from={Routes.Auth} exact={false} />*/}
    </Switch>
  );
}
