import React from "react";
import { useDispatch } from "react-redux";

import ConsultationNotesSidebar from "./ConsultationNotesSidebar";
import { useShallowEqualSelector } from "../../../hooks/useShallowEqualSelector";
import { consultationNotesHide, resetConsultationItem } from "../../../actions/appointmentActions";
import {
  appointmentSelector,
  consultationNotesSidebarVisibleSelector,
} from "../../../reducers/appointmentReducer";

export function ConsultationNotesSidebarWrapper({ patientKey }) {
  const dispatch = useDispatch();

  const appointment = useShallowEqualSelector(appointmentSelector);
  const consultationNotesSidebarVisible = useShallowEqualSelector(
    consultationNotesSidebarVisibleSelector,
  );

  return (
    <ConsultationNotesSidebar
      patientKey={patientKey}
      appointment={appointment}
      show={consultationNotesSidebarVisible}
      onHide={() => {
        dispatch(consultationNotesHide());
        dispatch(resetConsultationItem());
      }}
    />
  );
}
