import React from "react";
import { noop, toFinite } from "lodash";

import { SelectPicker } from "../select-picker/SelectPicker";
import { PLEASE_SELECT_ITEM } from "../../constants/Constants";

export default class CustomSelectBox extends React.Component {
  constructor(props) {
    super(props);

    this.className = "select-dropdown";
    this.selectBox = {
      selected: PLEASE_SELECT_ITEM,
      options: [],
      selectedId: PLEASE_SELECT_ITEM.id,
      required: false,
      disabled: false,
    };
    this.onFocus = noop;
    this.maskValue = null;
  }

  componentDidMount() {
    if (this.selectBox.options.length) {
      this.maskValue = this.selectBox.selected && this.selectBox.selected.code;
    }
  }

  render() {
    const { selectBox, searchable = false } = this.props;
    this.selectBox = selectBox;

    let hasMask = this.props.hasMask;
    const resultArr = selectBox.options.filter(
      (item) => selectBox.selected && selectBox.selected.id === item.id,
    );
    if (!resultArr.length) {
      hasMask = true;
      this.maskValue = selectBox.selected && selectBox.selected.name;
    } else {
      this.maskValue = selectBox.selected && selectBox.selected.code;
    }
    hasMask = this.maskValue && hasMask;
    if (!this.maskValue) this.maskValue = "";

    const parentClass = `${hasMask || this.props.hasMask ? "has-mask" : ""}`;
    this.className = this.props.className || this.className;
    this.onFocus = this.props.onFocus || this.onFocus;
    return (
      <div className={parentClass}>
        <SelectPicker
          size="lg"
          isSearchable={searchable}
          className={this.className}
          onChange={({ value }) => {
            selectBox.handler({ target: { value } }, selectBox);
          }}
          onFocus={this.onFocus}
          options={selectBox.options.map(({ id, name }) => ({
            label: name,
            value: toFinite(id),
          }))}
          required={selectBox.required}
          isDisabled={selectBox.disabled ? "disabled" : ""}
          value={{ value: toFinite(selectBox.selectedId) }}
        />
      </div>
    );
  }
}
