import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import ChartSideBar from "../appointment-form/ChartSideBar";
import { hideSideBar } from "../../actions/appointmentActions";
import { clinicSelector } from "../../reducers/sessionReducer";
import { currentMemberSelector } from "../../reducers/authReducer";
import { chartViewTypeSelector } from "../../reducers/chartReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { appointmentSideBarVisibleSelector } from "../../reducers/appointmentReducer";
import { chartSideFillInFormSelector } from "../../reducers/chartSideFillInFormReducer";

interface Props {
  readonly patientKey: string;
}

export function ChartSideBarWrapper({ patientKey }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const member = useShallowEqualSelector(currentMemberSelector);
  const clinic = useShallowEqualSelector(clinicSelector as any);
  const viewType = useShallowEqualSelector(chartViewTypeSelector as any);
  const show = useShallowEqualSelector(appointmentSideBarVisibleSelector as any);
  const chartSideFillInForm = useShallowEqualSelector(chartSideFillInFormSelector as any);

  return (
    <ChartSideBar
      clinic={clinic}
      member={member}
      history={history}
      location={location}
      viewType={viewType}
      sideBarVisible={show}
      fromAppointment={true}
      patientKey={patientKey}
      hideSideBar={() => dispatch(hideSideBar())}
      chartSideFillInForm={chartSideFillInForm}
    />
  );
}
