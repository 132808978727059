import React from "react";
import Show from "./Show";

export default class EmbedButton extends React.Component {
  render() {
    const {
      src = "",
      embedStyle,
      show = true,
      printButtonTitle = "Print",
      downloadButtonTitle = "Download",
    } = this.props;

    return (
      <Show if={show}>
        {window.openExternal && src ? (
          <div className="open-external-button-container">
            <button className="btn btn-primary" onClick={() => window.openExternal(src)}>
              {printButtonTitle}
            </button>
            {/* eslint-disable-next-line no-restricted-globals */}
            <button className="btn btn-primary" onClick={() => (location.href = src)}>
              {downloadButtonTitle}
            </button>
          </div>
        ) : (
          <embed style={embedStyle} src={src} type="" />
        )}
      </Show>
    );
  }
}
