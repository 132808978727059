const seriesMode = {
  grid: {
    code: "grid",
    name: "Grid",
  },
  list: {
    code: "list",
    name: "List",
  },
};

export default seriesMode;
