import React from "react";
import { makeStyles } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";
import cx from "classnames";

const useStyles = makeStyles<Theme>((theme) => ({
  title: { color: theme.palette.common.white },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.error.main,
    borderRadius: theme.typography.pxToRem(4),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
  activeRoot: { backgroundColor: theme.palette.primary.main },
}));

interface Props {
  readonly active: boolean;
}

export function EligibilityStatus({ active }: Props) {
  const classes = useStyles();

  const title = active ? "Eligible" : "Not eligible";

  return (
    <div className={cx(classes.root, { [classes.activeRoot]: active })}>
      <span className={classes.title}>{title}</span>
    </div>
  );
}
