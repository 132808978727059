export default function getSurfaces(toothPosition, isSurface) {
  let facial;

  let facial5;

  let lingual;

  let lingual5;

  let buccal;

  let buccal5;

  let oclusal;

  let incisal;

  let mesial;

  let distal;

  if (isSurface) {
    if (toothPosition >= 1 && toothPosition <= 5) {
      buccal = "2";
      mesial = "3";
      distal = "1";
      oclusal = "5";
      lingual = "4";
    } else if (toothPosition >= 6 && toothPosition <= 11) {
      facial = "2";
      if (toothPosition <= 8) {
        mesial = "3";
        distal = "1";
      } else {
        distal = "3";
        mesial = "1";
      }
      incisal = "5";
      lingual = "4";
    } else if (toothPosition >= 12 && toothPosition <= 16) {
      buccal = "2";
      mesial = "1";
      distal = "3";
      oclusal = "5";
      lingual = "4";
    } else if (toothPosition >= 28 && toothPosition <= 32) {
      lingual = "2";
      mesial = "3";
      distal = "1";
      oclusal = "5";
      buccal = "4";
    } else if (toothPosition >= 22 && toothPosition <= 27) {
      lingual = "2";
      if (toothPosition >= 25) {
        mesial = "3";
        distal = "1";
      } else {
        mesial = "1";
        distal = "3";
      }
      incisal = "5";
      facial = "4";
    } else if (toothPosition >= 17 && toothPosition <= 21) {
      lingual = "2";
      distal = "3";
      mesial = "1";
      oclusal = "5";
      buccal = "4";
    }
  } else if (toothPosition >= 1 && toothPosition <= 5) {
    buccal = "5";
    mesial = "3";
    distal = "1";
    oclusal = "4";
    buccal5 = "2";
  } else if (toothPosition >= 6 && toothPosition <= 11) {
    facial = "5";
    if (toothPosition <= 8) {
      mesial = "3";
      distal = "1";
    } else {
      distal = "3";
      mesial = "1";
    }
    incisal = "4";
    facial5 = "2";
  } else if (toothPosition >= 12 && toothPosition <= 16) {
    buccal = "5";
    mesial = "1";
    distal = "3";
    oclusal = "4";
    buccal5 = "2";
  } else if (toothPosition >= 28 && toothPosition <= 32) {
    buccal = "5";
    mesial = "3";
    distal = "1";
    oclusal = "2";
    buccal5 = "4";
  } else if (toothPosition >= 22 && toothPosition <= 27) {
    facial = "5";
    if (toothPosition >= 25) {
      mesial = "3";
      distal = "1";
    } else {
      mesial = "1";
      distal = "3";
    }
    incisal = "2";
    facial5 = "4";
  } else if (toothPosition >= 17 && toothPosition <= 21) {
    buccal = "5";
    distal = "3";
    mesial = "1";
    oclusal = "2";
    buccal5 = "4";
  }

  return {
    facial,
    facial5,
    lingual,
    lingual5,
    buccal,
    buccal5,
    oclusal,
    incisal,
    mesial,
    distal,
  };
}
