import _ from "lodash";
import React from "react";
import { reset } from "redux-form";

import MemberApi from "../api-legacy/MemberApi";
import PatientApi from "../api-legacy/PatientApi";
import LabOrderApi from "../api-legacy/LabOrderApi";
import TransactionApi from "../api-legacy/TransactionApi";
import AppointmentApi from "../api-legacy/AppointmentApi";

import { closeModal, showMessage, startLoader, stopLoader } from "./loaderActions";
import { closeSimpleModal, openSimpleModal } from "./simpleModalActions";

import { createPatientNote, getPatientTerminalDetails } from "./patientViewActions";
import { SelectValues } from "../constants/RepeatAppointmentDataTypes";

import Utils from "../helpers/Utils";

import * as ActionType from "../actionTypes/appointmantActionTypes";
import { STATUS_TYPES } from "../constants/StatusTypes";
import { getTeeth } from "./chartActions";
import { createTransactionRequest } from "./chartSideSeriesActions";
import { SidebarTypes } from "../components/chart-treatments-sidebar/TypeButtonGroup";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import moment from "moment";
import { getDoctorLocations } from "./calendarActions";
import {
  GET_MULTIPLE_DOCTOR_FREE_POINTS_ERROR,
  GET_MULTIPLE_DOCTOR_FREE_POINTS_START,
  GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS,
  SET_APPOINTMENT_ADDITIONAL_DOCTORS,
  SET_APPOINTMENT_TREATMENTS_PLAN_VALUES,
  SET_DELETE_APPOINTMENT_DELETE_OPTIONS,
  SET_MISSED_APPOINTMENT_OPTIONS,
} from "../actionTypes/appointmantActionTypes";
import { formatPhoneToObject } from "../utils/FormatUtils";
import { httpClient } from "../api-legacy/BaseApi";
import { ApiRoutes } from "../constants/ApiRoutes";

export const APPOINTMENT_GET_OTHER_STATUS_START = "APPOINTMENT_GET_OTHER_STATUS_START";
export const APPOINTMENT_GET_OTHER_STATUS_SUCCESS = "APPOINTMENT_GET_OTHER_STATUS_SUCCESS";
export const APPOINTMENT_GET_OTHER_STATUS_ERROR = "APPOINTMENT_GET_OTHER_STATUS_ERROR";

export const APPOINTMENT_GET_APPROVAL_STATUS_START = "APPOINTMENT_GET_APPROVAL_STATUS_START";
export const APPOINTMENT_GET_APPROVAL_STATUS_SUCCESS = "APPOINTMENT_GET_APPROVAL_STATUS_SUCCESS";
export const APPOINTMENT_GET_APPROVAL_STATUS_ERROR = "APPOINTMENT_GET_APPROVAL_STATUS_ERROR";

export const APPOINTMENT_GET_LAB_STATUS_START = "APPOINTMENT_GET_LAB_STATUS_START";
export const APPOINTMENT_GET_LAB_STATUS_SUCCESS = "APPOINTMENT_GET_LAB_STATUS_SUCCESS";
export const APPOINTMENT_GET_LAB_STATUS_ERROR = "APPOINTMENT_GET_LAB_STATUS_ERROR";

export const APPOINTMENT_SET_ELIGIBILITY_ID = "APPOINTMENT_SET_ELIGIBILITY_ID";
export const APPOINTMENT_SET_ELIGIBILITY_DETAILS_START =
  "APPOINTMENT_SET_ELIGIBILITY_DETAILS_START";
export const APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS =
  "APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS";
export const APPOINTMENT_SET_ELIGIBILITY_DETAILS_ERROR =
  "APPOINTMENT_SET_ELIGIBILITY_DETAILS_ERROR";
export const APPOINTMENT_CHANGE_ELIGIBILITY_PENDING = "APPOINTMENT_CHANGE_ELIGIBILITY_PENDING";

export const APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_START =
  "APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_START";
export const APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_SUCCESS =
  "APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_SUCCESS";
export const APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_ERROR =
  "APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_ERROR";

export const APPOINTMENT_GET_CATEGORIES_START = "APPOINTMENT_GET_CATEGORIES_START";
export const APPOINTMENT_GET_CATEGORIES_SUCCESS = "APPOINTMENT_GET_CATEGORIES_SUCCESS";
export const APPOINTMENT_GET_CATEGORIES_ERROR = "APPOINTMENT_GET_CATEGORIES_ERROR";

export const APPOINTMENT_GET_SUB_CATEGORIES_START = "APPOINTMENT_GET_SUB_CATEGORIES_START";
export const APPOINTMENT_GET_SUB_CATEGORIES_SUCCESS = "APPOINTMENT_GET_SUB_CATEGORIES_SUCCESS";
export const APPOINTMENT_GET_SUB_CATEGORIES_ERROR = "APPOINTMENT_GET_SUB_CATEGORIES_ERROR";

export const APPOINTMENT_GET_DOCTORS_START = "APPOINTMENT_GET_DOCTORS_START";
export const APPOINTMENT_GET_DOCTORS_SUCCESS = "APPOINTMENT_GET_DOCTORS_SUCCESS";
export const APPOINTMENT_GET_DOCTORS_ERROR = "APPOINTMENT_GET_DOCTORS_ERROR";

export const APPOINTMENT_GET_MACHINES_START = "APPOINTMENT_GET_MACHINES_START";
export const APPOINTMENT_GET_MACHINES_SUCCESS = "APPOINTMENT_GET_MACHINES_SUCCESS";
export const APPOINTMENT_GET_MACHINES_ERROR = "APPOINTMENT_GET_MACHINES_ERROR";

export const APPOINTMENT_GET_ADVANCED_PAYMENTS_START = "APPOINTMENT_GET_ADVANCED_PAYMENTS_START";
export const APPOINTMENT_GET_ADVANCED_PAYMENTS_SUCCESS =
  "APPOINTMENT_GET_ADVANCED_PAYMENTS_SUCCESS";
export const APPOINTMENT_GET_ADVANCED_PAYMENTS_ERROR = "APPOINTMENT_GET_ADVANCED_PAYMENTS_ERROR";

export const APPOINTMENT_GET_CLINIC_COLORS_START = "APPOINTMENT_GET_CLINIC_COLORS_START";
export const APPOINTMENT_GET_CLINIC_COLORS_SUCCESS = "APPOINTMENT_GET_CLINIC_COLORS_SUCCESS";
export const APPOINTMENT_GET_CLINIC_COLORS_ERROR = "APPOINTMENT_GET_CLINIC_COLORS_ERROR";

export const APPOINTMENT_GET_LOCATIONS_START = "APPOINTMENT_GET_LOCATIONS_START";
export const APPOINTMENT_GET_LOCATIONS_SUCCESS = "APPOINTMENT_GET_LOCATIONS_SUCCESS";
export const APPOINTMENT_GET_LOCATIONS_ERROR = "APPOINTMENT_GET_LOCATIONS_ERROR";

export const SWITCH_CLINICAL_NOTE_DIAGNOSIS_CODE = "SWITCH_CLINICAL_NOTE_DIAGNOSIS_CODE";
export const SWITCH_CLINICAL_NOTE_TREATMENT_CODE = "SWITCH_CLINICAL_NOTE_TREATMENT_CODE";
export const SET_APPOINTMENT_STATUS_COLORS = "SET_APPOINTMENT_STATUS_COLORS";
export const SET_APPOINTMENT_TYPE_COLORS = "SET_APPOINTMENT_TYPE_COLORS";

export const NOT_INTERESTED_APPOINTMENT_START = "NOT_INTERESTED_APPOINTMENT_START";
export const NOT_INTERESTED_APPOINTMENT_SUCCESS = "NOT_INTERESTED_APPOINTMENT_SUCCESS";
export const NOT_INTERESTED_APPOINTMENT_ERROR = "NOT_INTERESTED_APPOINTMENT_ERROR";

export const MISSED_CANCELLED_APPOINTMENT_SET_TYPE_START =
  "MISSED_CANCELLED_APPOINTMENT_SET_TYPE_START";
export const MISSED_CANCELLED_APPOINTMENT_SET_TYPE_SUCCESS =
  "MISSED_CANCELLED_APPOINTMENT_SET_TYPE_SUCCESS";
export const MISSED_CANCELLED_APPOINTMENT_SET_TYPE_ERROR =
  "MISSED_CANCELLED_APPOINTMENT_SET_TYPE_ERROR";

export const SET_MISSED_CANCELLED_LIST_ACTIVE_TAB = "SET_MISSED_CANCELLED_LIST_ACTIVE_TAB";

export const GET_ONLY_BLOCKED_TIME_SLOT_COLORS = "GET_ONLY_BLOCKED_TIME_SLOT_COLORS";

export const GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_START =
  "GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_START";
export const GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_SUCCESS =
  "GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_SUCCESS";
export const GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_ERROR =
  "GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_ERROR";

export const SET_APPOINTMENT_ADDITIONAL_PRICE_START = "SET_APPOINTMENT_ADDITIONAL_PRICE_START";
export const SET_APPOINTMENT_ADDITIONAL_PRICE_SUCCESS = "SET_APPOINTMENT_ADDITIONAL_PRICE_SUCCESS";
export const SET_APPOINTMENT_ADDITIONAL_PRICE_ERROR = "SET_APPOINTMENT_ADDITIONAL_PRICE_ERROR";

export const ADDITIONAL_CHARGES_FIELD_TOGGLE = "ADDITIONAL_CHARGES_FIELD_TOGGLE";

export const SET_ON_DRAG_APPOINTMENT_DATA = "SET_ON_DRAG_APPOINTMENT_DATA";

const excludeStatuses = [
  STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
  STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
  STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
  STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
  STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
  STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
  STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
  STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
  STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
  STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED,
];

export function moveAppointmentToWaitingPanel(data) {
  return (dispatch) => {
    dispatch({
      type: SET_ON_DRAG_APPOINTMENT_DATA,
    });
    dispatch(startLoader());
    return dispatch(saveOnDragAppointmentApi(data))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function additionalChargesFieldToggle() {
  return (dispatch) => {
    dispatch({
      type: ADDITIONAL_CHARGES_FIELD_TOGGLE,
    });
  };
}
export function getOnlyBtsColors() {
  return (dispatch) => {
    dispatch({
      type: GET_ONLY_BLOCKED_TIME_SLOT_COLORS,
    });
    dispatch(startLoader());

    return dispatch(getOnlyBlockedTimeSlotColorsApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setAppointmentAdditionalPrice(data) {
  return (dispatch) => {
    dispatch(startLoader());
    return dispatch(setAppointmentAdditionalPriceApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setDeleteAppointmentOptions(data) {
  return (dispatch) => {
    dispatch({
      type: SET_DELETE_APPOINTMENT_DELETE_OPTIONS,
      data,
    });
  };
}

export function setMissedAppointmentOptions(data) {
  return (dispatch) => {
    dispatch({
      type: SET_MISSED_APPOINTMENT_OPTIONS,
      data,
    });
  };
}

export function setAppointmentEligibilityId(appointmentId, id) {
  return {
    id,
    appointmentId,
    type: APPOINTMENT_SET_ELIGIBILITY_ID,
  };
}

export function setAppointmentAdditionalDoctors(additionalDoctors) {
  return {
    additionalDoctors,
    type: SET_APPOINTMENT_ADDITIONAL_DOCTORS,
  };
}

export function setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues) {
  return {
    appointmentTreatmentsPlanValues,
    type: SET_APPOINTMENT_TREATMENTS_PLAN_VALUES,
  };
}

export function getEligibilityData(values) {
  return (dispatch, getState) => {
    const { appointment, patient } = getState().appointment;
    const insurance = getState().insurance;
    const phone = formatPhoneToObject(patient?.mobilePhoneNumber);

    const primaryInsurance = (insurance.list || []).find((x) => x?.type?.code === "PRIMARY");

    const emiratesId = (patient?.documentNationalIdNumber || "").replace(/\D/g, "");

    const referralClinicianLicense =
      values.consultationCategory?.value === 3 ? appointment?.dentist?.code : undefined;

    const ultrasoundCategoryId =
      values.ultrasoundCategory?.value > 0 ? values.ultrasoundCategory?.value : undefined;
    const consultationCategoryId =
      values.consultationCategory?.value > 0 ? values.consultationCategory?.value : undefined;

    const data = {
      emiratesId,
      ultrasoundCategoryId,
      consultationCategoryId,
      referralClinicianLicense,
      isPOD: values.isPOD,
      PODId: values.PODId,
      cardNumber: primaryInsurance?.cardNumber,
      countryCode: phone.countryCode.value,
      clinicianLicense: appointment?.dentist?.code,
      companyLicenseId: primaryInsurance?.insuranceCompanyDetails?.senderId,
      mobileNumber: `${phone.townCode}${phone.number}`,
      serviceCategoryId: values.serviceCategory?.value,
      serviceSubCategoryId: values.serviceSubCategory?.value,
      payerId: _.toFinite(primaryInsurance?.insuranceCompanyDetails?.openjetId), //102,
    };
    return httpClient
      .post(ApiRoutes.GetEligibilityDetails, { data })
      .then(({ data }) => data.data)
      .then((data) => {
        if (data?.id) {
          dispatch(setAppointmentEligibilityId(appointment?.id, data?.id));

          dispatch(changeAppointmentEligibilityPending(appointment?.id, true));
        } else {
          const message = _.isArray(data) ? data.join(" \n") : "";
          return Promise.reject(message);
        }
      });
    // return eligibilityMutation.mutateAsync(body)
  };
}

// export const submitHandler = (values) => {
// const body: EligibilityBodyProps = {
//   emiratesId: "784198620214209",
//   ultrasoundCategoryId,
//   consultationCategoryId,
//   referralClinicianLicense,
//   cardNumber: "7327439", //primaryInsurance?.cardNumber,
//   countryCode: "971", //phone.countryCode.value,
//   clinicianLicense: "D5162", // appointment?.dentist?.code,
//   companyLicenseId: "MF1773", //primaryInsurance?.insuranceCompanyDetails?.senderId,
//   mobileNumber: "504121051", //`${phone.townCode}${phone.number}`,
//   serviceCategoryId: values.serviceCategory?.value,
//   serviceSubCategoryId: values.serviceSubCategory?.value,
//   payerId: 102,
// };
// };

export function getAppointmentEligibilityDetails(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getAppointmentEligibilityDetailsApi(data))
      .then((data) => {
        dispatch(stopLoader());
        return data;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getAppointmentPlannedTreatment(data) {
  return (dispatch) => {
    return dispatch(getAppointmentPlannedTreatmentApi(data))
      .then((data) => {
        return data;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function onSaveConsumedCancelledTreatments(data) {
  return (dispatch) => {
    dispatch(startLoader());
    return dispatch(onSaveConsumedCancelledTreatmentsApi(data))
      .then((data) => {
        dispatch(stopLoader());
        return data;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setAppointmentEligibilityDetails(appointmentId, details) {
  return {
    details,
    appointmentId,
    type: APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS,
  };
}

export function changeAppointmentEligibilityPending(appointmentId, isPending) {
  return {
    isPending,
    appointmentId,
    type: APPOINTMENT_CHANGE_ELIGIBILITY_PENDING,
  };
}

export function getEarlierAppointmentList(data) {
  return (dispatch) => {
    dispatch(startLoader());

    const query = {
      limit: 20,
      start: data.start || 0,
      periodEnd: data.toDate,
      doctorIds: data.doctorIds,
      periodStart: data.fromDate,
    };

    return dispatch(getEarlierAppointmentListApi(query))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function switchClinicalNoteTreatmentCode(questionId, item) {
  return {
    type: SWITCH_CLINICAL_NOTE_TREATMENT_CODE,
    item,
    questionId,
  };
}

export function switchClinicalNoteDiagnosisCode(questionId, item) {
  return {
    type: SWITCH_CLINICAL_NOTE_DIAGNOSIS_CODE,
    item,
    questionId,
  };
}

export function getDiagnosisCodeList(appointmentId) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getDiagnosisCodeListApi({ appointmentId }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTreatmentCodeList(appointmentId) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getTreatmentCodeListApi({ appointmentId }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getLocations() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getLocationsApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getAppointmentStatusList() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getStatusList())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function resetDoctors() {
  return {
    type: ActionType.OPEN_TIME_SLOT_RESET_DOCTORS,
  };
}

export function setOpenSlotDoctors(doctors) {
  return {
    type: ActionType.SET_OPEN_SLOT_DOCTORS,
    doctors,
  };
}

export function openTimeSlotChooseDoctor(id) {
  return {
    type: ActionType.OPEN_TIME_SLOT_CHOOSE_DOCTOR,
    id,
  };
}

export function getCountByStatus(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getCountByStatusApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getClinicColors() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getClinicColorsApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const setAppointmentStatusColors = (colors) => (dispatch) =>
  dispatch({ type: SET_APPOINTMENT_STATUS_COLORS, colors });

export const setAppointmentTypeColors = (colors) => (dispatch) =>
  dispatch({ type: SET_APPOINTMENT_TYPE_COLORS, colors });

export function getAdvancedPayments(patientKey) {
  return (dispatch, getState) => {
    const { chart, appointment } = getState();

    const chartPatientKey = _.get(chart, "patient.patientKey");
    const appointmentPatientKey = _.get(appointment, "appointment.patient.patientKey");

    if (appointmentPatientKey || chartPatientKey || patientKey)
      return dispatch(
        getAdvancedPaymentsApi({
          patientKey: appointmentPatientKey || chartPatientKey || patientKey,
        }),
      ).catch((error) => dispatch(showMessage(error)));
  };
}

function getAdvancedPaymentsApi(data, params) {
  return {
    api: AppointmentApi.advancedPayments,
    types: [
      APPOINTMENT_GET_ADVANCED_PAYMENTS_START,
      APPOINTMENT_GET_ADVANCED_PAYMENTS_SUCCESS,
      APPOINTMENT_GET_ADVANCED_PAYMENTS_ERROR,
    ],
    params,
    data,
  };
}

export function checkOtherDoctor({ patientKey, date, doctorId }) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const data = {
      patientKey,
      excludeDoctorIds: doctorId,
      periodEnd: getCompanyEndOfDay(date),
      periodStart: getCompanyStartOfDay(date),
    };

    const {
      session: { company },
    } = getState();

    let utcOffset;

    if (company) {
      utcOffset = _.toFinite(_.get(company, "timezone.description", Date.now())) / 60 / 1000;
    } else {
      utcOffset = moment().utcOffset();
    }
    return dispatch(checkOtherDoctorApi(data))
      .then(({ list = [] }) => {
        const filteredList = list.filter((x) => !excludeStatuses.includes(_.get(x, "status.code")));
        if (filteredList.length > 0) {
          const point = { ...filteredList[0] };
          const startDate = Utils.calcDateWithTimeZone(point.startTime, utcOffset);
          const hours = startDate.hour();
          const minutes = startDate.minute();

          const duration = point.duration / 1000 / 60;

          const startTime = hours * 60 + minutes;
          const endTime = startTime + duration;

          const time = Utils.intToDayPartStr(startTime) + " - " + Utils.intToDayPartStr(endTime);

          dispatch(
            openSimpleModal({
              body: (
                <div>
                  This patient has another appointment with:
                  <p style={{ marginBottom: 0 }}> {`${_.get(filteredList, "0.dentist.name")}`}</p>
                  <p>at: {time}</p>
                </div>
              ),
              footer: (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(closeSimpleModal());
                  }}
                >
                  Ok
                </button>
              ),
            }),
          );
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initRequestList() {
  return (dispatch, getState) => {
    const {
      appointment: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      start: 0,
      limit: 100,
      patientKey,
      type: "TREATMENT",
      status: "PLANNED",
    };
    return dispatch(getRequestListApi(data))
      .then(() => {
        dispatch(getRequestList(data));
      })
      .catch((error) => {
        dispatch(showMessage(error));
      });
  };
}

export function getRequestList(prevData) {
  return (dispatch, getState) => {
    const {
      appointment: { requestedList, requestedListTotalCount },
    } = getState();

    if (requestedListTotalCount > requestedList.length) {
      const data = {
        ...prevData,

        start: requestedList.length,
      };

      return dispatch(getRequestListApi(data))
        .then(() => {
          dispatch(getRequestList(data));
        })
        .catch((error) => {
          dispatch(showMessage(error));
        });
    }
  };
}

export function addRequestedTreament(item) {
  return (dispatch, getState) => {
    const data = item || createTransactionRequest(getState, SidebarTypes.Treatments);

    dispatch(startLoader());

    return dispatch(createRequestItemApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(initRequestList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTooth(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getTeeth({ patientKey }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(startLoader(error)));
  };
}

export function convertRequestToPlanned(ids, type, dentistId) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const { appointment, session } = getState();

    const closedDentistId = dentistId || _.get(session, "member.id");
    const appointmentId = _.get(appointment, "appointment.id");

    const data = {
      ids,
      appointmentId,
      closedDentistId,
      dentistToCashierNote: "",
      status: {
        code:
          type === "PLANNED"
            ? "DentalTransactionStatus_PLANNED"
            : "DentalTransactionStatus_COMPLETED",
      },
    };

    return dispatch(convertRequestToPlannedApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(initRequestList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function convertSelectedRequestToPlanned(type, dentistId) {
  return (dispatch, getState) => {
    const {
      appointment: { thisSelected },
    } = getState();

    const promises = [];

    _.forEach(thisSelected, (x, idx) => {
      if (x) {
        promises.push(dispatch(convertRequestToPlanned(idx, type, dentistId, false)));
      }
    });

    return Promise.all(promises)
      .then(() => dispatch(initRequestList()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function toggleAppointmentSidebar() {
  return {
    type: ActionType.APPOINTMENT_TOGGLE_SIDEBAR,
  };
}

export function changeAppointmentRelatedTab(tab) {
  return {
    type: ActionType.CHANGE_APPOINTMENT_RELATED_TAB,
    tab,
  };
}

export function searchTemporaryPatientResultShow() {
  return {
    type: ActionType.SEARCH_TEMPORARY_PATIENT_RESULT_SHOW,
  };
}

export function searchTemporaryPatientResultHide() {
  return {
    type: ActionType.SEARCH_TEMPORARY_PATIENT_RESULT_HIDE,
  };
}

export function resetConsultationItem() {
  return {
    type: ActionType.RESET_CONSULTATION_NOTES_ITEM,
  };
}

export function consultationNotesConfirmDeleteModalShow(id) {
  return {
    type: ActionType.CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_SHOW,
    id,
  };
}

export function consultationNotesConfirmDeleteModalHide() {
  return {
    type: ActionType.CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_HIDE,
  };
}

export function consultationNotesShow() {
  return {
    type: ActionType.CONSULTATION_NOTES_SIDEBAR_SHOW,
  };
}

export function consultationNotesHide() {
  return {
    type: ActionType.CONSULTATION_NOTES_SIDEBAR_HIDE,
  };
}

export function searchTemporaryPatient() {
  return (dispatch, getState) => {
    const {
      form: {
        TemporaryPatientForm: { values },
      },
    } = getState();

    const data = {};

    if (!values) {
      return dispatch(showMessage("Enter Phone Number or First Name and Last Name"));
    }

    if (values.email) data.email = values.email;

    const phoneNumber = _.get(values, "phoneNumber.number");
    const phoneTownCode = _.get(values, "phoneNumber.townCode");
    const phoneCountryCode = _.get(values, "phoneNumber.countryCode.value");

    if (phoneCountryCode && phoneTownCode && phoneNumber) {
      data.phoneNumber = `+${phoneCountryCode}(${phoneTownCode})${phoneNumber}`;
    }

    if (values.firstName && values.lastName) {
      data.firstName = values.firstName;
      data.lastName = values.lastName;
    }

    if (!data.firstName && !data.phoneNumber) {
      return dispatch(showMessage("Enter Phone Number or First Name and Last Name"));
    }

    dispatch(startLoader());

    return dispatch(searchTemporaryPatientApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(searchTemporaryPatientResultShow());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function searchTemporaryPatientApi(data) {
  return {
    api: AppointmentApi.searchFirstContact,
    types: [
      ActionType.SEARCH_TEMPORARY_PATIENT_START,
      ActionType.SEARCH_TEMPORARY_PATIENT_SUCCESS,
      ActionType.SEARCH_TEMPORARY_PATIENT_ERROR,
    ],
    data,
  };
}

export function setConsultationNote({ target }) {
  return {
    type: ActionType.SET_CONSULTATION_NOTE,
    value: target.value,
  };
}

export function getConsultationNotesList(initialData) {
  return (dispatch, getState) => {
    const {
      patient: {
        patientDetails: { patientKey },
      },
      patientView,
    } = getState();

    const data = {
      start: 0,
      limit: 15,
    };

    if ((initialData && initialData.patientKey) || patientKey || patientView.patientKey) {
      data.patientKey =
        (initialData && initialData.patientKey) || patientKey || patientView.patientKey;
    } else if (
      (initialData && initialData.companyTempPatientId) ||
      patientView.companyTempPatientId
    ) {
      data.companyTempPatientId =
        (initialData && initialData.companyTempPatientId) || patientView.companyTempPatientId;
    }

    return dispatch(getConsultationNotesListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function getConsultationNotesItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getConsultationNotesItemApi({ id }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getConsultationNotesItemApi(data) {
  return {
    api: AppointmentApi.consultationNotes.getItem,
    types: [
      ActionType.GET_CONSULTATION_NOTES_ITEM_START,
      ActionType.GET_CONSULTATION_NOTES_ITEM_SUCCESS,
      ActionType.GET_CONSULTATION_NOTES_ITEM_ERROR,
    ],
    data,
  };
}

export function deleteConsultationNotesItem(id) {
  return (dispatch, getState) => {
    const {
      patient: {
        patientDetails: { patientKey },
      },
      patientView,
    } = getState();

    dispatch(consultationNotesConfirmDeleteModalHide());
    dispatch(startLoader());

    return dispatch(deleteConsultationNotesItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(
          getConsultationNotesList({
            patientKey: patientKey || patientView.patientKey,
          }),
        );
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function deleteConsultationNotesItemApi(data) {
  return {
    api: AppointmentApi.consultationNotes.deleteItem,
    types: [
      ActionType.DELETE_CONSULTATION_NOTES_ITEM_START,
      ActionType.DELETE_CONSULTATION_NOTES_ITEM_SUCCESS,
      ActionType.DELETE_CONSULTATION_NOTES_ITEM_ERROR,
    ],
    data,
  };
}

export function loadConsultationNotesList() {
  return (dispatch, getState) => {
    const {
      patient: {
        patientDetails: { patientKey },
      },
      appointment: { consultationNotesCount, consultationNotesList, consultationNotesFetching },
      patientView,
    } = getState();

    if (consultationNotesList.length === consultationNotesCount || consultationNotesFetching)
      return;

    const data = {
      start: consultationNotesList.length,
      limit: 15,
    };

    if (patientKey || patientView.patientKey) {
      data.patientKey = patientKey || patientView.patientKey;
    } else if (patientView.companyTempPatientId) {
      data.companyTempPatientId = patientView.companyTempPatientId;
    }

    return dispatch(getConsultationNotesListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function saveConsultationNotesItem() {
  return (dispatch, getState) => {
    const {
      patient: {
        patientDetails: { patientKey },
      },
      form: {
        consultationNotes: { values },
      },
      appointment: { consultationNotesItem, item },
      patientView,
    } = getState();

    if (_.isEmpty(values)) return;

    dispatch(startLoader());

    let saveData;
    let editData;

    if (_.isEmpty(consultationNotesItem)) {
      saveData = {
        name: values.name,
        note: values.note,
      };

      if (patientKey || patientView.patientKey) {
        saveData.patientKey = patientKey || patientView.patientKey;
      } else if (item && item.patient && item.patient.companyTempPatientId) {
        saveData.companyTempPatientId = item.patient.companyTempPatientId;
      }
    } else {
      editData = {
        ...consultationNotesItem,
        name: values.name,
        note: values.note,
      };

      if (patientKey || patientView.patientKey) {
        editData.patientKey = patientKey || patientView.patientKey;
      } else if (item.patient && item && item.patient.companyTempPatientId) {
        editData.companyTempPatientId = item.patient.companyTempPatientId;
      }
    }

    const action = _.isEmpty(consultationNotesItem)
      ? dispatch(saveConsultationNotesItemApi(saveData))
      : dispatch(editConsultationNotesItemApi(editData));

    return action
      .then(() => {
        dispatch(
          getConsultationNotesList({
            patientKey: patientKey || patientView.patientKey,
          }),
        );
        dispatch(reset("consultationNotes"));
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function editConsultationNotesItemApi(data) {
  return {
    api: AppointmentApi.consultationNotes.editItem,
    types: [
      ActionType.EDIT_CONSULTATION_NOTES_ITEM_START,
      ActionType.EDIT_CONSULTATION_NOTES_ITEM_SUCCESS,
      ActionType.EDIT_CONSULTATION_NOTES_ITEM_ERROR,
    ],
    data,
  };
}

function saveConsultationNotesItemApi(data) {
  return {
    api: AppointmentApi.consultationNotes.saveItem,
    types: [
      ActionType.SAVE_CONSULTATION_NOTES_ITEM_START,
      ActionType.SAVE_CONSULTATION_NOTES_ITEM_SUCCESS,
      ActionType.SAVE_CONSULTATION_NOTES_ITEM_ERROR,
    ],
    data,
  };
}

function getConsultationNotesListApi(data) {
  return {
    api: AppointmentApi.consultationNotes.getList,
    types: [
      ActionType.GET_CONSULTATION_NOTES_LIST_START,
      ActionType.GET_CONSULTATION_NOTES_LIST_SUCCESS,
      ActionType.GET_CONSULTATION_NOTES_LIST_ERROR,
    ],
    data,
  };
}

export function deleteAppointmentModalShow() {
  return {
    type: ActionType.DELETE_APPOINTMENT_MODAL_SHOW,
  };
}

export function deleteAppointmentModalHide() {
  return {
    type: ActionType.DELETE_APPOINTMENT_MODAL_HIDE,
  };
}

export function patientNotesModalShow() {
  return {
    type: ActionType.APPOINTMENT_PATIENT_NOTES_MODAL_SHOW,
  };
}

export function patientSelectBirthDate(date) {
  return {
    type: ActionType.APPOINTMENT_SELECT_BIRTH_DATE,
    date,
  };
}

export function changePatientFormData(data) {
  return {
    type: ActionType.CHANGE_PATIENT_FORM_DATA,
    data,
  };
}

export function setTemporaryCompanyId(companyTempPatientId) {
  return {
    type: ActionType.SET_COMPANY_TEMP_PATIENT_ID,
    companyTempPatientId,
  };
}

export function patientNotesModalHide() {
  return {
    type: ActionType.APPOINTMENT_PATIENT_NOTES_MODAL_HIDE,
  };
}

export function getPatientNotes(data) {
  return {
    api: PatientApi.note.getList,
    types: [
      ActionType.PATIENT_VIEW_GET_NOTES_START,
      ActionType.PATIENT_VIEW_GET_NOTES_SUCCESS,
      ActionType.PATIENT_VIEW_GET_NOTES_ERROR,
    ],
    data,
  };
}

export function repeatAppointmentRepeatModalShow(index) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW,
    index,
  };
}

export function repeatAppointmentRepeatModalHide() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE,
  };
}

export function repeatAppointmentDeleteAfter(id) {
  return (dispatch, getState) => {
    const {
      appointment: { repeatAppointmentDeleteId },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: id || repeatAppointmentDeleteId,
    };

    return dispatch(repeatAppointmentDeleteAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY,
          id: id || repeatAppointmentDeleteId,
        });
        dispatch(repeatAppointmentGetList());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function repeatAppointmentDeleteAfterApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentDeleteItem,
    types: [
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_START,
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_DELETE_AFTER_ERROR,
    ],
    data,
  };
}

function getUnixTime(momentDate) {
  const startDate = new Date(momentDate);

  return startDate.getMilliseconds() - startDate.getTimezoneOffset() * 60000;
}

function getUnixDate(momentDate) {
  return momentDate.valueOf() + getUnixTime(momentDate);
}

export function repeatAppointmentUpdateAfter(index, id) {
  return (dispatch, getState) => {
    const {
      form: {
        repeatAppointment: { values },
      },
      appointment: {
        appointment,
        repeatAppointmentList,
        repeatAppointmentRepeatModalMode,
        repeatAppointmentRepeatModalDate,
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: _.get(repeatAppointmentList[index], "id"),
      patientKey: repeatAppointmentList[index].patientKey,
      limitCount: repeatAppointmentList[index].limitCount,
      endDate: repeatAppointmentList[index].endDate,
      period: repeatAppointmentList[index].period,
      timeFromStartDate: getUnixTime(repeatAppointmentRepeatModalDate),
      dentistId: repeatAppointmentList[index].dentistId,
      appointmentId: _.get(appointment, "id"),
      createdSource: {
        code: "FROM_APPOINTMENT_DETAILS",
      },
      startDate: getUnixDate(repeatAppointmentRepeatModalDate),
      periodUnit: repeatAppointmentList[index].periodUnit,
    };

    if (repeatAppointmentRepeatModalMode === "until") {
      data.endDate = getUnixDate(repeatAppointmentRepeatModalDate);
      data.endType = {
        id: 1,
        name: "RecurrenceEnd_By_EndDate",
        code: "RecurrenceEnd_By_EndDate",
      };
    } else {
      data.limitCount = values.occurrenceItem;
      data.endType = {
        id: 2,
        name: "RecurrenceEnd_By_CountLimit",
        code: "RecurrenceEnd_By_CountLimit",
      };
    }

    return dispatch(repeatAppointmentSaveItemAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE,
        });
        dispatch(repeatAppointmentGetList());
        dispatch({
          type: ActionType.REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY,
          id,
        });
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentSaveAfter() {
  return (dispatch, getState) => {
    const {
      form: {
        repeatAppointment: { values },
      },
      appointment: {
        repeatAppointmentSelectedDate,
        appointment: {
          id,
          dentist,
          patient: { patientKey },
        },
      },
    } = getState();

    if (_.isEmpty(values) || !values.mainSelect || !values.mainItem) return;

    dispatch(startLoader());

    const data = {
      timeFromStartDate: getUnixTime(repeatAppointmentSelectedDate),
      period: values.mainItem,
      dentistId: _.get(dentist, "id"),
      appointmentId: id,
      status: {
        code: "DentistRuleStatus_Inactive",
        id: 1,
        name: "DentistRuleStatus_Inactive",
      },
      periodUnit: _.find(SelectValues, ["code", values.mainSelect]),
      startDate: getUnixDate(repeatAppointmentSelectedDate),
      createdSource: {
        code: "FROM_APPOINTMENT_DETAILS",
      },
      patientKey,
    };

    return dispatch(repeatAppointmentSaveItemAfterApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(repeatAppointmentGetList());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function repeatAppointmentSetRepeatModalMode(mode) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE,
    mode,
  };
}

export function repeatAppointmentSetFollowType(mode) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SET_FOLLOW_TYPE,
    mode,
  };
}

export function repeatAppointmentConfirmModalShow() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW,
  };
}

export function repeatAppointmentConfirmModalHide() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE,
  };
}

export function repeatAppointmentSetDeleteId(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REPEAT_APPOINTMENT_SET_DELETE_ID,
      id,
    });
    dispatch({
      type: ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW,
    });
  };
}

export function repeatAppointmentSaveSelectedDate(exactSameTime) {
  return (dispatch, getState) => {
    const {
      appointment: {
        repeatAppointmentSelectedDate,
        appointment: {
          startTime,
          id,
          dentist,
          patient: { patientKey },
        },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      timeFromStartDate: (startTime % (24 * 60 * 60 * 1000)) + Utils.getCompanyTimeZone(),
      dentistId: _.get(dentist, "id"),
      appointmentId: id,
      startDate: Utils.getStartDay(repeatAppointmentSelectedDate),
      createdSource: {
        code: "FROM_APPOINTMENT_DETAILS",
      },
      patientKey,
      exactSameTime,
    };

    dispatch(repeatAppointmentConfirmModalHide());

    return dispatch(repeatAppointmentSaveItemApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(repeatAppointmentModalHide());
        dispatch(showMessage("Appointment successfully created"));
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function repeatAppointmentSaveItemApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentSaveItem,
    types: [
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_START,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_ERROR,
    ],
    data,
  };
}

function repeatAppointmentSaveItemAfterApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentSaveItemAfter,
    types: [
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR,
    ],
    data,
  };
}

export function repeatAppointmentGetList() {
  return (dispatch, getState) => {
    const { followUp, appointment } = getState();

    dispatch(startLoader());

    const followUpDoctorId = _.get(followUp, "item.doctor.id");
    const followUpPatientKey = _.get(followUp, "item.patientKey");
    const appointmentPatientKey = _.get(appointment, "appointment.patient.patientKey");
    const appointmentDoctorId = _.get(appointment, "appointment.dentist.id");

    const data = {
      patientKey: appointmentPatientKey || followUpPatientKey,
      dentistId: appointmentDoctorId || followUpDoctorId,
    };
    if (data.patientKey && data.dentistId) {
      return dispatch(repeatAppointmentGetListApi(data))
        .then(() => {
          dispatch(stopLoader());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    }
  };
}

function repeatAppointmentGetListApi(data) {
  return {
    api: AppointmentApi.repeatAppointmentGetList,
    types: [
      ActionType.REPEAT_APPOINTMENT_GET_LIST_START,
      ActionType.REPEAT_APPOINTMENT_GET_LIST_SUCCESS,
      ActionType.REPEAT_APPOINTMENT_GET_LIST_ERROR,
    ],
    data,
  };
}

export function repeatAppointmentRepeatModalSelectDate(value) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE,
    value,
  };
}

export function repeatAppointmentSelectDate(value) {
  return {
    type: ActionType.REPEAT_APPOINTMENT_SELECT_DATE,
    value,
  };
}

export function repeatAppointmentModalShow() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_MODAL_SHOW,
  };
}

export function repeatAppointmentModalHide() {
  return {
    type: ActionType.REPEAT_APPOINTMENT_MODAL_HIDE,
  };
}

export function setRequestedAppointmentItem(item) {
  return {
    type: ActionType.SET_REQUESTED_APPOINTMENT_ITEM,
    item,
  };
}

export function setRequestedAppointmentItemPatient(patient) {
  return {
    type: ActionType.SET_REQUESTED_APPOINTMENT_ITEM_PATIENT,
    patient,
  };
}

export function getAppointmentMeetingType() {
  return (dispatch) => {
    return dispatch({
      types: [
        ActionType.GET_APPOINTMENT_MEETING_TYPE_START,
        ActionType.GET_APPOINTMENT_MEETING_TYPE_SUCCESS,
        ActionType.GET_APPOINTMENT_MEETING_TYPE_ERROR,
      ],
      api: AppointmentApi.getAppointmentMeetingType,
    });
  };
}

export function getAppointmentPaymentType() {
  return (dispatch) => {
    return dispatch({
      types: [
        ActionType.GET_APPOINTMENT_PAYMENT_TYPE_START,
        ActionType.GET_APPOINTMENT_PAYMENT_TYPE_SUCCESS,
        ActionType.GET_APPOINTMENT_PAYMENT_TYPE_ERROR,
      ],
      api: AppointmentApi.getAppointmentPaymentType,
    });
  };
}

export function getAppointmentRemindersLog(data) {
  return (dispatch) => {
    return dispatch({
      types: [
        ActionType.GET_APPOINTMENT_REMINDER_LOG_START,
        ActionType.GET_APPOINTMENT_REMINDER_LOG_SUCCESS,
        ActionType.GET_APPOINTMENT_REMINDER_LOG_ERROR,
      ],
      api: AppointmentApi.getAppointmentRemindersLog,
      data,
    });
  };
}

export function initAppointmentRight() {
  return function (dispatch, getState) {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch({
      type: "initAppointmentRight",
    });

    dispatch(startLoader());
    return Promise.all([
      dispatch(getStatusList({ clinicId })),
      dispatch(getAppointmentMeetingType()),
      dispatch(getAppointmentPaymentType()),
      dispatch(getMasterTypeApi({ clinicId })),
      dispatch(getCategoryList({ clinicId })),
      dispatch(getChooseDentist({ clinicId })),
      dispatch(getDoctorsApi({ clinicId })),
      dispatch(getDoctorLocations({ publicPoint: true, clinicId: clinicId })),
    ])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDoctorsLocations() {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();
    const fn = {
      api: MemberApi.getDoctorLocations,
      types: [
        GET_MULTIPLE_DOCTOR_FREE_POINTS_START,
        GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS,
        GET_MULTIPLE_DOCTOR_FREE_POINTS_ERROR,
      ],
      data: { clinicId },
    };
    return dispatch(fn);
  };
}

export function getAppointmentById(id) {
  return function (dispatch, getState) {
    dispatch({
      type: ActionType.GET_APPOINTMENT,
      fetchingItem: false,
    });
    const {
      session: {
        miscSettings: { additionalDoctorsAppointmentEnabled },
      },
    } = getState();

    dispatch(startLoader());
    const transfer = { id };
    if (additionalDoctorsAppointmentEnabled) {
      transfer.includeLinkedItems = true;
    }

    return dispatch(getAppointmentFn(transfer))
      .then((response) => {
        dispatch(stopLoader());
        if (response.patient.patientKey) {
          dispatch(
            getPatientNotes({
              patientKey: response.patient.patientKey,
              start: 0,
              limit: 25,
            }),
          );
        } else if (response.patient.companyTempPatientId) {
          dispatch({
            type: "INIT_PATIENT_VIEW",
            companyTempPatientId: response.patient.companyTempPatientId,
          });
        }

        dispatch({
          type: ActionType.GET_APPOINTMENT,
          fetchingItem: true,
        });
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openCallListPatientNote(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientNotes({ patientKey, start: 0, limit: 25 }))
      .then(() => dispatch(stopLoader()))
      .then(() => dispatch(patientNotesModalShow()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function clearAppointment() {
  return (dispatch) => {
    dispatch({ type: ActionType.CLEAR_APPOINTMENT });
  };
}

export function setAppointmentPatientEmiratesId(emiratesId) {
  return (dispatch) => {
    dispatch({ type: ActionType.SET_APPOINTMENT_PATIENT_EMIRATES_ID, emiratesId });
  };
}

export function initAppoinmemt(id, fromFirstContact) {
  return function (dispatch) {
    dispatch({
      type: ActionType.INIT_APPOINTMENT,
      fetchingInit: false,
      fromFirstContact,
    });

    dispatch(startLoader());

    const promises = [dispatch(initAppointmentRight())];

    if (id) {
      promises.push(dispatch(getAppointmentById(id)));
    }

    return Promise.all(promises)
      .then(() => {
        dispatch(stopLoader());

        dispatch({
          type: ActionType.INIT_APPOINTMENT,
          fetchingInit: true,
          fromFirstContact,
        });
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeItem(item, isUpdate) {
  return function (dispatch) {
    dispatch({
      type: ActionType.CHANGE_APPOINTMENT_ITEM,
      item,
      isUpdate,
    });
  };
}

export function moveAppointment() {
  return {
    type: ActionType.APPOINTMENT_MOVE,
  };
}

export function saveAppointment(data, hideLoader) {
  return function (dispatch, getState) {
    const {
      session: {
        miscSettings: { additionalDoctorsAppointmentEnabled },
      },
    } = getState();
    dispatch({
      type: "saveAppointment",
    });
    if (!hideLoader) {
      dispatch(startLoader());
    }
    const linkedItems = (data.linkedItems || []).filter((item) => item.dentist.id).length;
    const appointments = (data.appointments || []).filter((item) => item.dentist.id).length;
    if (appointments) {
      return dispatch(saveAppointmentsPlanApi(data))
        .then((response) => {
          if (!hideLoader) {
            dispatch(stopLoader());
          }
          return response;
        })
        .catch((error) => {
          if (!hideLoader) {
            dispatch(stopLoader(error));
          }

          return new Error(error);
        });
    }
    return dispatch(
      (additionalDoctorsAppointmentEnabled && data.moved && linkedItems) ||
        (additionalDoctorsAppointmentEnabled && !data.id && linkedItems)
        ? saveAppointmentBatchItemsApi(data)
        : saveAppointmentApi(data),
    )
      .then((response) => {
        if (!hideLoader) {
          dispatch(stopLoader());
        }
        return response;
      })
      .catch((error) => {
        if (!hideLoader) {
          dispatch(stopLoader(error));
        }

        return new Error(error);
      });
  };
}

export function notInterestedAppointment(data) {
  return (dispatch) =>
    dispatch({
      api: AppointmentApi.setNotInterestedAppointment,
      types: [
        NOT_INTERESTED_APPOINTMENT_START,
        NOT_INTERESTED_APPOINTMENT_SUCCESS,
        NOT_INTERESTED_APPOINTMENT_ERROR,
      ],
      data,
    });
}

export function saveReferralAppointment(data) {
  return (dispatch) => {
    dispatch({
      type: "saveAppointment",
    });

    const promises = [];
    data.forEach((form) => {
      promises.push(dispatch(saveAppointmentApi(form)));
    });
    dispatch(startLoader());

    return Promise.all(promises)
      .then((result) => {
        dispatch(stopLoader());
        return result;
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function statusChangeOfAppointment(data) {
  return (dispatch) => {
    dispatch({
      type: "statusChangeOfAppointment",
    });

    dispatch(startLoader());
    return dispatch(changeStatus(data))
      .then((response) => {
        dispatch(stopLoader());
        _.get(response, "patient.patientKey") &&
          dispatch(
            getPatientTerminalDetails({
              patientKey: _.get(response, "patient.patientKey"),
            }),
          );
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getFreePoints(data) {
  return function (dispatch, getState) {
    const {
      calendar: { clinicId },
    } = getState();
    dispatch({
      type: "getFreePoints",
    });

    dispatch(startLoader());
    return dispatch(getFreePointsApi({ ...data, clinicId }))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMultipleDoctorFreePoints(data) {
  return function (dispatch, getState) {
    const {
      calendar: { clinicId },
    } = getState();
    dispatch({
      type: "getFreePoints",
    });
    if ((data.dentistWithDentalPoints || []).length > 0) {
      data.dentistId = undefined;
      data.dentalPointId = undefined;
    }
    dispatch(startLoader());
    return dispatch(getMultipleDoctorFreePointsApi({ ...data, clinicId }))
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPatientGenderList() {
  return function (dispatch) {
    dispatch({
      type: "getPatientGenderList",
    });
    dispatch(startLoader());

    return dispatch(getPatientGender())
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setGuardianInformed(guardianInformed) {
  return function (dispatch, getState) {
    const { appointment } = getState().appointment;
    dispatch(startLoader());

    return dispatch({
      api: AppointmentApi.updateGuardianInformed,
      types: [
        ActionType.SET_APPOINTMENT_GUARDIAN_INFORMED_START,
        ActionType.SET_APPOINTMENT_GUARDIAN_INFORMED_SUCCESS,
        ActionType.SET_APPOINTMENT_GUARDIAN_INFORMED_ERROR,
      ],
      data: {
        id: appointment.id,
        guardianInformed,
      },
    })
      .then((response) => {
        dispatch(stopLoader());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function updatePaymentType(paymentStatus) {
  return function (dispatch, getState) {
    const { appointment } = getState().appointment;
    dispatch(startLoader());

    return dispatch({
      api: AppointmentApi.updatePaymentType,
      types: [
        ActionType.UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_START,
        ActionType.UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_SUCCESS,
        ActionType.UPDATE_APPOINTMENT_ITEM_PAYMENT_TYPE_ERROR,
      ],
      data: {
        id: appointment.id,
        paymentStatus,
      },
    })
      .then((response) => {
        dispatch(stopLoader("Successfully updated"));
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function updateMeetingType(meetingType) {
  return function (dispatch, getState) {
    const { appointment } = getState().appointment;
    dispatch(startLoader());

    return dispatch({
      api: AppointmentApi.updateMeetingType,
      types: [
        ActionType.UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_START,
        ActionType.UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_SUCCESS,
        ActionType.UPDATE_APPOINTMENT_ITEM_MEETING_TYPE_ERROR,
      ],
      data: {
        id: appointment.id,
        meetingType,
      },
    })
      .then((response) => {
        dispatch(stopLoader("Successfully updated"));
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function updateLocation(location) {
  return function (dispatch, getState) {
    const { appointment } = getState().appointment;
    dispatch(startLoader());

    return dispatch({
      api: AppointmentApi.updateLocation,
      types: [
        ActionType.UPDATE_APPOINTMENT_ITEM_LOCATION_START,
        ActionType.UPDATE_APPOINTMENT_ITEM_LOCATION_SUCCESS,
        ActionType.UPDATE_APPOINTMENT_ITEM_LOCATION_ERROR,
      ],
      data: {
        id: appointment.id,
        location,
      },
    })
      .then((response) => {
        dispatch(stopLoader("Successfully updated"));
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function showSideBar() {
  return {
    type: ActionType.SHOW_SIDE_BAR,
  };
}

export function hideSideBar() {
  return {
    type: ActionType.HIDE_SIDE_BAR,
  };
}

export function searchPatients(data) {
  return function (dispatch) {
    dispatch({
      type: "searchPatients",
    });

    dispatch(startLoader());
    return dispatch(searchPatient(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function searchFirstContactPatientsList(data) {
  return function (dispatch) {
    dispatch({
      type: "searchPatients",
    });

    dispatch(startLoader());
    return dispatch(searchFirstContactPatientListApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDentalPoint(data) {
  return function (dispatch, getState) {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch({
      type: "getDentalPoint",
    });
    if (!data.hideLoader) {
      dispatch(startLoader());
    }
    return dispatch(getDentalPointApi({ ...data, clinicId }))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveCallReason() {
  return (dispatch, getState) => {
    const {
      form: { SaveAndCloseModal, TemporaryPatientForm },
    } = getState();

    const patientValues = TemporaryPatientForm.values;
    const reasonValues = SaveAndCloseModal;

    let mobilePhoneNumber;

    if (!_.isEmpty(patientValues.country) && patientValues.phone) {
      mobilePhoneNumber = `+${patientValues.country.value} (${patientValues.town}) ${patientValues.phone}`;
    } else {
      dispatch(showMessage("Phone number required"));
      return false;
    }

    const data = {
      mobilePhoneNumber,
      email: (patientValues && patientValues.email) || "none@none.com",
      firstName: patientValues && patientValues.firstName,
      lastName: patientValues && patientValues.lastName,
      createReasonType: {
        code: reasonValues && reasonValues.createReasonType,
      },
      description: reasonValues && reasonValues.description,
    };

    dispatch(startLoader());

    return dispatch(saveCallReasonApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(closeReasonListModal());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function saveCallReasonApi(data) {
  return {
    api: AppointmentApi.saveReason,
    types: [
      ActionType.SAVE_CALL_REASON_START,
      ActionType.SAVE_CALL_REASON_SUCCESS,
      ActionType.SAVE_CALL_REASON_ERROR,
    ],
    data,
  };
}

export function newTemporaryPatient(patient) {
  return (dispatch, getState) => {
    const {
      form: {
        TemporaryPatientForm: { values },
      },
    } = getState();

    const data = {};

    if (patient) {
      dispatch({
        type: ActionType.CHANGE_APPOINTMENT_ITEM,
        item: patient,
      });
    } else {
      const phone = _.get(values, "phoneNumber.number", "");
      const town = _.get(values, "phoneNumber.townCode", "");
      const country = _.get(values, "phoneNumber.countryCode.value", "");

      if (country && town && phone) {
        data.mobilePhoneNumber = {
          country,
          town,
          phone,
        };
      }

      if (values.firstName && values.lastName) {
        data.firstName = values.firstName;
        data.lastName = values.lastName;
      }

      if (values.email) data.email = values.email;
    }

    return dispatch({
      type: ActionType.ADD_NEW_TEMPORARY_PATIENT,
      data,
    });
  };
}

export function closeReasonListModal() {
  return {
    type: ActionType.CLOSE_REASON_MODAL,
  };
}

export function openReasonListModal() {
  return {
    type: ActionType.OPEN_REASON_MODAL,
  };
}

export function initFirstContact() {
  return (dispatch) => {
    const promises = [
      dispatch({
        type: ActionType.INIT_FIRST_CONTACT,
      }),
      dispatch(getReasonListApi()),
    ];

    dispatch(startLoader());

    return Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getReasonListApi(data) {
  return {
    api: AppointmentApi.getReasonList,
    types: [
      ActionType.GET_REASON_LIST_START,
      ActionType.GET_REASON_LIST_SUCCESS,
      ActionType.GET_REASON_LIST_ERROR,
    ],
    data,
  };
}

export function choosePatientNewScreen(patient) {
  return function (dispatch) {
    dispatch({
      type: "choosePatient",
    });
    const promises = [];

    if (patient.companyTempPatientId) {
      dispatch({
        type: ActionType.CHANGE_APPOINTMENT_ITEM,
        item: patient,
      });
    }

    promises.push(
      dispatch({
        type: ActionType.CHOOSE_TEMPORARY_PATIENT,
        patient,
      }),
    );

    return Promise.all(promises);
  };
}

export function getMasterType() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getMasterTypeApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMasterTypeApi(data) {
  return {
    api: AppointmentApi.getMasterType,
    types: [
      ActionType.GET_MASTER_TYPE_START,
      ActionType.GET_MASTER_TYPE_SUCCESS,
      ActionType.GET_MASTER_TYPE_ERROR,
    ],
    data,
  };
}

export function choosePatient(patient) {
  return function (dispatch) {
    dispatch({
      type: "choosePatient",
    });
    return dispatch({
      type: ActionType.CHOOSE_PATIENT_ITEM,
      patient,
    });
  };
}

export function openReasonModal(reasonStatus) {
  return {
    type: ActionType.APPOINTMENT_REASON_MODAL_OPEN,
    reasonStatus,
  };
}

export function closeReasonModal() {
  return {
    type: ActionType.APPOINTMENT_REASON_MODAL_CLOSE,
  };
}

export function togglePriceStatus(checked) {
  return {
    type: ActionType.TOGGLE_SWITCHER_STATUS,
    checked,
  };
}

export function selectPrice(value) {
  return (dispatch, getState) => {
    const {
      appointment: { appointment },
    } = getState();
    if (_.get(appointment, "id")) {
      dispatch(
        cashPrice({
          id: _.get(appointment, "id"),
          cashTreatmentPriceId: +value,
        }),
      ).catch((error) => dispatch(showMessage(error)));
    }

    dispatch({
      type: ActionType.APPOINTMENT_CHOOSE_PRICE,
      value,
    });
  };
}

export function togglePriceDisabledStatus(disabled) {
  return {
    type: ActionType.TOGGLE_DISABLED_SWITCHER_STATUS,
    disabled,
  };
}

export function showModals(patientKey, date, doctorId, startTime) {
  return (dispatch, getState) => {
    const data = { patientKey };

    const { session, appointment } = getState();
    let utcOffset;

    if (session.company) {
      utcOffset =
        _.toFinite(_.get(session, "company.timezone.description", Date.now())) / 60 / 1000;
    } else {
      utcOffset = moment().utcOffset();
    }

    Promise.all([
      dispatch({
        api: LabOrderApi.getDocumentList,
        types: [
          "GET_APPOINTMENT_LAB_ORDER_DOCUMENT_LIST_START",
          "GET_APPOINTMENT_LAB_ORDER_DOCUMENT_LIST_SUCCESS",
          "GET_APPOINTMENT_LAB_ORDER_DOCUMENT_LIST_ERROR",
        ],
        data,
      }),
      dispatch({
        api: PatientApi.paymentOptions,
        types: [
          "GET_APPOINTMENT_PAYMENT_OPTIONS_START",
          "GET_APPOINTMENT_PAYMENT_OPTIONS_SUCCESS",
          "GET_APPOINTMENT_PAYMENT_OPTIONS_ERROR",
        ],
        data,
      }),
      dispatch({
        api: AppointmentApi.getCanceledMissedCount,
        types: [
          "GET_APPOINTMENT_CANCELED_MISSED_COUNT_START",
          "GET_APPOINTMENT_CANCELED_MISSED_COUNT_SUCCESS",
          "GET_APPOINTMENT_CANCELED_MISSED_COUNT_ERROR",
        ],
        data: {
          patientKey,
          dentistId: doctorId,
          lastStartTime: startTime,
        },
      }),
      dispatch({
        api: PatientApi.reminder.getReminder,
        types: [
          "APPOINTMENT_GET_PATIENT_REMINDER_START",
          "APPOINTMENT_GET_PATIENT_REMINDER_SUCCESS",
          "APPOINTMENT_GET_PATIENT_REMINDER_ERROR",
        ],
        data: { patientKey },
      }),
      dispatch({
        api: AppointmentApi.getList,
        types: [
          "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_START",
          "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_SUCCESS",
          "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_ERROR",
        ],
        data: {
          start: 0,
          limit: 25,
          patientKey,
          periodStart: getCompanyStartOfDay(date),
          periodEnd: getCompanyEndOfDay(date),
          excludeDoctorIds: doctorId > 0 ? doctorId : undefined,
        },
      }),
      dispatch(getPatientDetailsOnAppointment({ patientKey })),
    ])
      .then((response) => {
        const modals = [];

        const $footerButton = (
          <button
            className="btn btn-primary"
            onClick={() => {
              if (modals.length) {
                dispatch(closeSimpleModal());
                modals.shift();
                openModals();
              } else {
                dispatch(closeModal());
              }
            }}
          >
            Ok
          </button>
        );

        if (response[0].totalCount) {
          modals.push({
            header: "Info",
            body: `Patient has opened lab orders: ${response[0].totalCount}`,
            footer: $footerButton,
          });
        }

        const showNoInsurance = _.get(
          session,
          "alertNotifications.ALERT_PATIENT_HAS_NO_INSURANCE.selected",
          true,
        );
        if (response[1].insuranceCompanyCount === 0 && showNoInsurance) {
          modals.push({
            body: "Patient has no insurance",
            footer: $footerButton,
          });
        }

        const birthday = moment(
          _.get(appointment, "appointment.patient.dateOfBirth") ||
            _.get(appointment, "item.patient.dateOfBirth"),
        );
        const canShowAlert = _.get(
          session,
          "alertNotifications.ALERT_PATIENT_BIRTHDAY.selected",
          true,
        );

        if (canShowAlert && birthday) {
          const thisDay = birthday.get("date") === moment().get("date");
          const thisMonth = birthday.get("month") === moment().get("month");

          if (thisDay && thisMonth) {
            const firstName =
              _.get(appointment, "appointment.patient.firstName") ||
              _.get(appointment, "item.patient.firstName");

            modals.push({
              body: `Its 'patient ${firstName} birthday today`,
              footer: $footerButton,
            });
          }
        }

        (response[1].insuranceCoverageLimitReachedItems || []).forEach((item) => {
          const name = item.code === "SECONDARY" ? "Secondary" : "Primary";

          modals.push({
            body: `${name} insurance limited reached`,
            footer: $footerButton,
          });
        });

        if (response[1].primaryInsuranceCardExpired) {
          modals.push({
            body: "Primary insurance card expired",
            footer: $footerButton,
            size: "md",
            modalType: "warning",
          });
        }

        if (response[1].secondaryInsuranceCardExpired) {
          modals.push({
            body: "Secondary insurance card expired",
            footer: $footerButton,
          });
        }

        const showMissedCanceled = _.get(
          session,
          "alertNotifications.ALERT_PATIENT_CANCELLED_MISSED_COUNT.selected",
          true,
        );
        if (
          showMissedCanceled &&
          (response[2].cancelledCount ||
            response[2].missedCount ||
            response[2].lastAppointmentStatus)
        ) {
          modals.push({
            header: "Patient's attendance records for the past 365 days",
            body: (
              <div>
                {(response[2].missedCount && (
                  <div>
                    Missed appointments:
                    {response[2].missedCount}
                  </div>
                )) ||
                  null}
                {(response[2].cancelledCount && (
                  <div>
                    Canceled appointments:
                    {response[2].cancelledCount}
                  </div>
                )) ||
                  null}
                {(response[2].lastAppointmentStatus && (
                  <div>
                    Last appointment status with this Doctor is:{" "}
                    {response[2].lastAppointmentStatus.name}
                  </div>
                )) ||
                  null}
              </div>
            ),
            footer: $footerButton,
          });
        }

        if (
          response[3] &&
          response[3].receptionReminderActive &&
          !_.isEmpty(response[3].receptionReminder)
        ) {
          modals.push({
            body: response[3].receptionReminder,
            footer: $footerButton,
          });
        }

        if (response[4] && response[4].list && response[4].list.length > 0) {
          let showPopup = true;
          const point = { ...response[4].list[0] };
          const startDate = Utils.calcDateWithTimeZone(point.startTime, utcOffset);
          const hours = startDate.hour();
          const minutes = startDate.minute();

          const duration = point.duration / 1000 / 60;

          const startTime = hours * 60 + minutes;
          const endTime = startTime + duration;

          const time = Utils.intToDayPartStr(startTime) + " - " + Utils.intToDayPartStr(endTime);
          response[4].list.forEach((x) => {
            if (_.includes(excludeStatuses, _.get(x, "status.code"))) {
              showPopup = false;
            }
          });

          if (showPopup) {
            modals.push({
              body: (
                <div>
                  This patient has another appointment with:{" "}
                  <p style={{ marginBottom: 0 }}>{`${point.dentist.name}`}</p>
                  <p>at: {time}</p>
                </div>
              ),
              footer: $footerButton,
            });
          }
        }

        openModals();

        function openModals() {
          // debugger;
          if (modals.length) {
            dispatch(openSimpleModal(modals[0]));
            dispatch(stopLoader());
          }
        }
      })
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function initRelated(appointmentId, patientKey) {
  return function (dispatch) {
    const data = {
      appointmentId,
      patientKey,
      limit: 25,
    };

    return dispatch(getRelatedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function initRelatedModal() {
  return {
    type: ActionType.APPOINTMENT_INIT_RELATED_MODAL,
  };
}

export function toggleThatMode() {
  return {
    type: ActionType.APPOINTMENT_TOGGLE_THAT_MODE,
  };
}

export function unselectThat() {
  return {
    type: ActionType.APPOINTMENT_UNSELECT_THAT,
  };
}

export function removeThis() {
  return function (dispatch, getState) {
    const {
      appointment: { thisSelected, appointment, relatedTab },
    } = getState();

    if (relatedTab === "second" && _.isEmpty(thisSelected)) {
      return;
    }

    if (relatedTab === "second") {
      _.forEach(thisSelected, (x, id) => {
        if (x) {
          dispatch(toggleThis(id));
          dispatch(toggleThisAll(false));

          return dispatch(deleteRequestItemApi({ id }))
            .then(() => {
              dispatch(removeThis());
            })
            .catch((error) => showMessage(error));
        }
      });

      return dispatch(initRequestList());
    }

    const dentalTransactionKeys = _(thisSelected)
      .pickBy((boolean) => boolean)
      .keys()
      .value();

    if (_.isEmpty(dentalTransactionKeys)) {
      return dispatch(showMessage("Please, choose at least one transaction"));
    }

    const data = {
      dentalTransactionKeys,
      appointmentId: _.get(appointment, "id"),
    };

    dispatch(removeRelated(data))
      .then(() => dispatch(initThis()))
      .catch((error) => showMessage(error));
  };
}

export function addThat() {
  return function (dispatch, getState) {
    const {
      appointment: { thatSelected, appointment },
    } = getState();

    const dentalTransactionKeys = _(thatSelected)
      .pickBy((boolean) => boolean)
      .keys()
      .value();

    if (_.isEmpty(dentalTransactionKeys)) {
      return dispatch(showMessage("Please, choose at least one transaction"));
    }
    const data = {
      dentalTransactionKeys,
      appointmentId: _.get(appointment, "id"),
    };

    dispatch(addRelated(data))
      .then(() => dispatch(toggleThatMode()))
      .catch((error) => showMessage(error));
  };
}

export function showCodesForThisModal() {
  return {
    type: ActionType.APPOINTMENT_SHOW_CODES_FOR_THIS_MODAL,
  };
}

export function closeCodesForThisModal() {
  return {
    type: ActionType.APPOINTMENT_CLOSE_CODES_FOR_THIS_MODAL,
  };
}

export function toggleThis(key) {
  return {
    type: ActionType.APPOINTMENT_TOGGLE_THIS,
    key,
  };
}

export function toggleThisAll(isSelected) {
  return {
    type: ActionType.APPOINTMENT_TOGGLE_THIS_ALL,
    isSelected,
  };
}

export function toggleThat(key) {
  return {
    type: ActionType.APPOINTMENT_TOGGLE_THAT,
    key,
  };
}

export function initThis() {
  return (dispatch, getState) => {
    const {
      appointment: {
        patient: { patientKey },
        appointment,
      },
    } = getState();

    const data = {
      patientKey,
      appointmentId: _.get(appointment, "id"),
      start: 0,
      limit: 25,
    };

    dispatch(getRelatedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function getCategories(parentId) {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch(startLoader());

    const data = {
      clinicId,
    };

    if (parentId) {
      data.parentId = parentId;
    }

    return dispatch(getCategoriesApi(data))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getOtherStatus() {
  return (dispatch) => {
    return dispatch(getOtherStatusApi())
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getApprovalStatus() {
  return (dispatch) => {
    return dispatch(getApprovalStatusApi()).then((response) => {
      return response;
    });
  };
}

export function getLabStatus() {
  return (dispatch) => {
    return dispatch(getLabStatusApi()).then((response) => {
      return response;
    });
  };
}

export function getSubCategories(parentId) {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch(startLoader());

    const data = {
      clinicId,
    };

    if (parentId) {
      data.parentId = parentId;
    }

    return dispatch(getSubCategoriesApi(data))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMachines(dentalPointId) {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch(startLoader());

    return dispatch(getMachinesApi({ dentalPointId, clinicId }))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDoctors(treatmentGroupId, keywordIds, id) {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();
    dispatch(startLoader());

    const data = {
      clinicId: id || clinicId,
    };

    if (treatmentGroupId) {
      data.treatmentGroupId = treatmentGroupId;
    }

    if (keywordIds) {
      data.keywordIds = keywordIds;
    }

    return dispatch(getDoctorsApi(data))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadThis() {
  return (dispatch, getState) => {
    const {
      appointment: {
        patient: { patientKey },
        appointment,
        codesForThis,
        codesForThisTotalCount,
        fetchingRelated,
      },
    } = getState();
    if (fetchingRelated) return;

    if (codesForThisTotalCount > codesForThis.length) {
      const data = {
        patientKey,
        appointmentId: _.get(appointment, "id"),
        limit: 25,
        start: codesForThis.length,
      };

      dispatch(getRelatedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function initThat() {
  return (dispatch, getState) => {
    const {
      appointment: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
      start: 0,
      limit: 25,
    };

    dispatch(getRelatedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadThat() {
  return (dispatch, getState) => {
    const {
      appointment: {
        patient: { patientKey },
        codesForThat,
        codesForThatTotalCount,
        fetchingRelated,
      },
    } = getState();
    if (fetchingRelated) return;

    if (codesForThatTotalCount > codesForThat.length) {
      const data = {
        patientKey,
        limit: 25,
        start: codesForThat.length,
      };

      dispatch(getRelatedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function changeNursery({ value }) {
  return {
    type: ActionType.APPOINTMENT_CHANGE_NURSERY,
    value,
  };
}

export function addNote({ title, body }, patientKey) {
  return (dispatch) => {
    if (!body) {
      return dispatch(showMessage("Note required"));
    }

    dispatch(startLoader());
    const limit = 25;

    dispatch(
      createPatientNote({
        patientKey,
        name: title,
        note: body,
      }),
    )
      .then(() => dispatch(getPatientNotes({ patientKey, limit })))
      .then(() => dispatch(reset("patientViewModalAdd")))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getHistory(data) {
  return {
    api: AppointmentApi.getHistory,
    types: [
      ActionType.APPOINTMENT_GET_HISTORY_START,
      ActionType.APPOINTMENT_GET_HISTORY_SUCCESS,
      ActionType.APPOINTMENT_GET_HISTORY_ERROR,
    ],
    data,
  };
}

function getRelatedList(data) {
  return {
    api: TransactionApi.related.getList,
    types: [
      ActionType.APPOINTMENT_GET_RELATED_LIST_START,
      ActionType.APPOINTMENT_GET_RELATED_LIST_SUCCESS,
      ActionType.APPOINTMENT_GET_RELATED_LIST_ERROR,
    ],
    data,
  };
}

function addRelated(data) {
  return {
    api: TransactionApi.related.add,
    types: [
      ActionType.APPOINTMENT_RELATED_ADD_START,
      ActionType.APPOINTMENT_RELATED_ADD_SUCCESS,
      ActionType.APPOINTMENT_RELATED_ADD_ERROR,
    ],
    data,
  };
}

function removeRelated(data) {
  return {
    api: TransactionApi.related.remove,
    types: [
      ActionType.APPOINTMENT_RELATED_REMOVE_START,
      ActionType.APPOINTMENT_RELATED_REMOVE_SUCCESS,
      ActionType.APPOINTMENT_RELATED_REMOVE_ERROR,
    ],
    data,
  };
}

function getAppointmentFn(data) {
  return {
    api: AppointmentApi.getItem,
    types: [
      ActionType.GET_APPOINTMENT_START,
      ActionType.GET_APPOINTMENT_SUCCESS,
      ActionType.GET_APPOINTMENT_ERROR,
    ],
    data,
  };
}

function cashPrice(data) {
  return {
    api: AppointmentApi.cashPrice,
    types: [
      ActionType.APPOINTMENT_CASH_PRICE_START,
      ActionType.APPOINTMENT_CASH_PRICE_SUCCESS,
      ActionType.APPOINTMENT_CASH_PRICE_ERROR,
    ],
    data,
  };
}

export function saveAppointmentApi(data) {
  return {
    api: AppointmentApi.saveAppointment,
    types: [
      ActionType.CREATE_APPOINTMENT_START,
      ActionType.CREATE_APPOINTMENT_SUCCESS,
      ActionType.CREATE_APPOINTMENT_ERROR,
    ],
    data,
  };
}

export function saveAppointmentBatchItemsApi(data) {
  return {
    api: AppointmentApi.saveAppointmentBatchItems,
    types: [
      ActionType.CREATE_APPOINTMENT_START,
      ActionType.CREATE_APPOINTMENT_SUCCESS,
      ActionType.CREATE_APPOINTMENT_ERROR,
    ],
    data,
  };
}

export function saveAppointmentsPlanApi(data) {
  return {
    api: AppointmentApi.saveAppointmentsPlan,
    types: [
      ActionType.CREATE_APPOINTMENT_START,
      ActionType.CREATE_APPOINTMENT_SUCCESS,
      ActionType.CREATE_APPOINTMENT_ERROR,
    ],
    data,
  };
}

function getCategoriesApi(data) {
  return {
    api: AppointmentApi.getCategories,
    types: [
      APPOINTMENT_GET_CATEGORIES_START,
      APPOINTMENT_GET_CATEGORIES_SUCCESS,
      APPOINTMENT_GET_CATEGORIES_ERROR,
    ],
    data,
  };
}

function getOtherStatusApi() {
  return {
    api: AppointmentApi.getOtherStatus,
    types: [
      APPOINTMENT_GET_OTHER_STATUS_START,
      APPOINTMENT_GET_OTHER_STATUS_SUCCESS,
      APPOINTMENT_GET_OTHER_STATUS_ERROR,
    ],
  };
}

function getApprovalStatusApi() {
  return {
    api: AppointmentApi.getApprovalStatus,
    types: [
      APPOINTMENT_GET_APPROVAL_STATUS_START,
      APPOINTMENT_GET_APPROVAL_STATUS_SUCCESS,
      APPOINTMENT_GET_APPROVAL_STATUS_ERROR,
    ],
  };
}

function getLabStatusApi() {
  return {
    api: AppointmentApi.getLabStatus,
    types: [
      APPOINTMENT_GET_LAB_STATUS_START,
      APPOINTMENT_GET_LAB_STATUS_SUCCESS,
      APPOINTMENT_GET_LAB_STATUS_ERROR,
    ],
  };
}

function getSubCategoriesApi(data) {
  return {
    api: AppointmentApi.getCategories,
    types: [
      APPOINTMENT_GET_SUB_CATEGORIES_START,
      APPOINTMENT_GET_SUB_CATEGORIES_SUCCESS,
      APPOINTMENT_GET_SUB_CATEGORIES_ERROR,
    ],
    data,
  };
}

export function getDoctorsApi(data) {
  return {
    api: AppointmentApi.getDoctors,
    types: [
      APPOINTMENT_GET_DOCTORS_START,
      APPOINTMENT_GET_DOCTORS_SUCCESS,
      APPOINTMENT_GET_DOCTORS_ERROR,
    ],
    data,
  };
}

function getMachinesApi(data) {
  return {
    api: AppointmentApi.getMachines,
    types: [
      APPOINTMENT_GET_MACHINES_START,
      APPOINTMENT_GET_MACHINES_SUCCESS,
      APPOINTMENT_GET_MACHINES_ERROR,
    ],
    data,
  };
}

function changeStatus(data) {
  return {
    api: AppointmentApi.status.changeStatus,
    types: [
      ActionType.APPOINTMENT_STATUS_CHANGE_START,
      ActionType.APPOINTMENT_STATUS_CHANGE_SUCCESS,
      ActionType.APPOINTMENT_STATUS_CHANGE_ERROR,
    ],
    data,
  };
}

export function searchPatient(data) {
  let api = PatientApi.searchPatients;

  return {
    api,
    types: [
      ActionType.SEARCH_PATIENT_LIST_START,
      ActionType.SEARCH_PATIENT_LIST_SUCCESS,
      ActionType.SEARCH_PATIENT_LIST_ERROR,
    ],
    data,
  };
}

export function searchFirstContactPatientListApi(data) {
  let api = PatientApi.getFirstContactPatientList;

  return {
    api,
    types: [
      ActionType.SEARCH_PATIENT_LIST_START,
      ActionType.SEARCH_PATIENT_LIST_SUCCESS,
      ActionType.SEARCH_PATIENT_LIST_ERROR,
    ],
    data,
  };
}

export function getStatusList(data) {
  return {
    api: AppointmentApi.status.getChoose,
    types: [
      ActionType.GET_STATUS_LIST_START,
      ActionType.GET_STATUS_LIST_SUCCESS,
      ActionType.GET_STATUS_LIST_ERROR,
    ],
    data,
  };
}

export function getCategoryList(data) {
  return {
    api: AppointmentApi.category.getChoose,
    types: [
      ActionType.GET_CATEGORY_LIST_START,
      ActionType.GET_CATEGORY_LIST_SUCCESS,
      ActionType.GET_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

function getFreePointsApi(data) {
  return {
    api: AppointmentApi.getFreePoints,
    types: [
      ActionType.GET_FREE_POINTS_START,
      ActionType.GET_FREE_POINTS_SUCCESS,
      ActionType.GET_FREE_POINTS_ERROR,
    ],
    data,
  };
}

function getMultipleDoctorFreePointsApi(data) {
  return {
    api: AppointmentApi.getMultipleDoctorFreePoints,
    types: [
      ActionType.GET_MULTIPLE_DOCTOR_FREE_POINTS_START,
      ActionType.GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS,
      ActionType.GET_MULTIPLE_DOCTOR_FREE_POINTS_ERROR,
    ],
    data,
  };
}

function getChooseDentist(data) {
  return {
    api: MemberApi.getChooseDentist,
    types: [
      ActionType.GET_CHOOSE_DENTIST_START,
      ActionType.GET_CHOOSE_DENTIST_SUCCESS,
      ActionType.GET_CHOOSE_DENTIST_ERROR,
    ],
    data,
  };
}

function getDentalPointApi(data) {
  return {
    api: AppointmentApi.getDentalPoint,
    types: [
      ActionType.GET_DENTAL_POINT_START,
      ActionType.GET_DENTAL_POINT_SUCCESS,
      ActionType.GET_DENTAL_POINT_ERROR,
    ],
    data,
  };
}

function getPatientGender(data) {
  return {
    api: PatientApi.getGender,
    types: [
      ActionType.GET_PATIENT_GENDER_START,
      ActionType.GET_PATIENT_GENDER_SUCCESS,
      ActionType.GET_PATIENT_GENDER_ERROR,
    ],
    data,
  };
}

function getRequestListApi(data) {
  return {
    api: AppointmentApi.getRequestList,
    types: [
      ActionType.APPOINTMENT_GET_REQUEST_LIST_START,
      ActionType.APPOINTMENT_GET_REQUEST_LIST_SUCCESS,
      ActionType.APPOINTMENT_GET_REQUEST_LIST_ERROR,
    ],
    data,
  };
}

function createRequestItemApi(data) {
  return {
    api: AppointmentApi.createRequestItem,
    types: [
      ActionType.APPOINTMENT_CREATE_REQUEST_ITEM_START,
      ActionType.APPOINTMENT_CREATE_REQUEST_ITEM_SUCCESS,
      ActionType.APPOINTMENT_CREATE_REQUEST_ITEM_ERROR,
    ],
    data,
  };
}

function deleteRequestItemApi(data) {
  return {
    api: AppointmentApi.deleteRequestItem,
    types: [
      ActionType.APPOINTMENT_DELETE_REQUEST_ITEM_START,
      ActionType.APPOINTMENT_DELETE_REQUEST_ITEM_SUCCESS,
      ActionType.APPOINTMENT_DELETE_REQUEST_ITEM_ERROR,
    ],
    data,
  };
}

function convertRequestToPlannedApi(data) {
  return {
    api: AppointmentApi.convertRequestToPlanned,
    types: [
      ActionType.APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_START,
      ActionType.APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_SUCCESS,
      ActionType.APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_ERROR,
    ],
    data,
  };
}

function checkOtherDoctorApi(data) {
  return {
    api: AppointmentApi.getList,
    types: [
      "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_START",
      "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_SUCCESS",
      "APPOINTMENT_GET_LIST_FOR_OTHER_DOCTORS_ERROR",
    ],
    data,
  };
}

function getClinicColorsApi(data) {
  return {
    api: AppointmentApi.getColors,
    types: [
      APPOINTMENT_GET_CLINIC_COLORS_START,
      APPOINTMENT_GET_CLINIC_COLORS_SUCCESS,
      APPOINTMENT_GET_CLINIC_COLORS_ERROR,
    ],
    data,
  };
}

function getCountByStatusApi(data) {
  return {
    api: AppointmentApi.getCountByStatus,
    types: [
      ActionType.GET_COUNT_BY_STATUS_START,
      ActionType.GET_COUNT_BY_STATUS_SUCCESS,
      ActionType.GET_COUNT_BY_STATUS_ERROR,
    ],
    data,
  };
}

function getLocationsApi(data) {
  return {
    api: AppointmentApi.getLocations,
    types: [
      APPOINTMENT_GET_LOCATIONS_START,
      APPOINTMENT_GET_LOCATIONS_SUCCESS,
      APPOINTMENT_GET_LOCATIONS_ERROR,
    ],
    data,
  };
}

function getDiagnosisCodeListApi(data) {
  return {
    api: AppointmentApi.getDiagnosisCodeList,
    types: [
      ActionType.APPOINTMENT_DIAGNOSIS_CODE_LIST_START,
      ActionType.APPOINTMENT_DIAGNOSIS_CODE_LIST_SUCCESS,
      ActionType.APPOINTMENT_DIAGNOSIS_CODE_LIST_ERROR,
    ],
    data,
  };
}
function getAppointmentEligibilityDetailsApi(data) {
  return {
    api: AppointmentApi.getAppointmentEligibilityDetails,
    types: [
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_START,
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS,
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_ERROR,
    ],
    data,
  };
}
export function updateAppointmentEligibilityIdPayer(data) {
  return {
    api: AppointmentApi.updateAppointmentEligibilityIdPayer,
    types: [
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_START,
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS,
      APPOINTMENT_SET_ELIGIBILITY_DETAILS_ERROR,
    ],
    data,
  };
}

export function updateAppointmentEligibilityStatus(data) {
  return {
    api: AppointmentApi.updateAppointmentEligibilityStatus,
    types: [
      APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_START,
      APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_SUCCESS,
      APPOINTMENT_UPDATE_ELIGIBILITY_STATUS_ERROR,
    ],
    data,
  };
}

function getTreatmentCodeListApi(data) {
  return {
    api: AppointmentApi.getTreatmentCodeList,
    types: [
      ActionType.APPOINTMENT_TREATMENT_CODE_LIST_START,
      ActionType.APPOINTMENT_TREATMENT_CODE_LIST_SUCCESS,
      ActionType.APPOINTMENT_TREATMENT_CODE_LIST_ERROR,
    ],
    data,
  };
}

export function getEarlierAppointmentListApi(data) {
  return {
    api: AppointmentApi.getEarlierAppointments,
    types: [
      ActionType.EARLIER_APPOINTMENT_LIST_START,
      ActionType.EARLIER_APPOINTMENT_LIST_SUCCESS,
      ActionType.EARLIER_APPOINTMENT_LIST_ERROR,
    ],
    data,
  };
}

export function getAppointmentPlannedTreatmentApi(data) {
  return {
    api: TransactionApi.getList.forAppointment,
    types: [
      ActionType.GET_APPOINTMENT_PLANNED_TREATMENT_LIST_START,
      ActionType.GET_APPOINTMENT_PLANNED_TREATMENT_LIST_SUCCESS,
      ActionType.GET_APPOINTMENT_PLANNED_TREATMENT_LIST_ERROR,
    ],
    data,
  };
}

export function onSaveConsumedCancelledTreatmentsApi(data) {
  return {
    api: TransactionApi.updateCancelledConsumedTransactions,
    types: [
      ActionType.SAVE_CONSUMED_CANCELLED_TREATMENTS_START,
      ActionType.SAVE_CONSUMED_CANCELLED_TREATMENTS_SUCCESS,
      ActionType.SAVE_CONSUMED_CANCELLED_TREATMENTS_ERROR,
    ],
    data,
  };
}

export function setAppointmentPlannedTreatmentModalVisible(visible) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_APPOINTMENT_PLANNED_TREATMENT_MODAL_VISIBLE,
      visible,
    });
  };
}

export function toggleAppointmentPlannedTreatment(key) {
  return (dispatch) => {
    dispatch({
      type: ActionType.TOGGLE_PLANNED_TREATMENT,
      key,
    });
  };
}

export function toggleAppointmentPlannedTreatmentAll(isSelected) {
  return (dispatch) => {
    dispatch({
      type: ActionType.TOGGLE_PLANNED_TREATMENT_ALL,
      isSelected,
    });
  };
}

// export function getPatientDetailsData() {
//   return (dispatch, getState) => {
//     const {
//       appointment: {
//         patient: { patientKey }
//       },
//     } = getState();
//
//     dispatch(startLoader());
//
//     dispatch(getPatientDetailsOnAppointment({ patientKey }))
//         .then(() => dispatch(stopLoader()))
//         .catch((error) => dispatch(stopLoader(error)));
//   };
// }

export function getPatientDetailsOnAppointment(data) {
  return {
    api: PatientApi.getPersonalDetails,
    types: [
      ActionType.PATIENT_GET_DETAILS_INFO_START,
      ActionType.PATIENT_GET_DETAILS_INFO_SUCCESS,
      ActionType.PATIENT_GET_DETAILS_INFO_ERROR,
    ],
    data,
  };
}

export function getOnlyBlockedTimeSlotColorsApi(data) {
  return {
    api: AppointmentApi.getColors,
    types: [
      GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_START,
      GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_SUCCESS,
      GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_ERROR,
    ],
    data,
  };
}
export function setAppointmentAdditionalPriceApi(data) {
  return {
    api: AppointmentApi.setAppointmentAdditionalPrice,
    types: [
      SET_APPOINTMENT_ADDITIONAL_PRICE_START,
      SET_APPOINTMENT_ADDITIONAL_PRICE_SUCCESS,
      SET_APPOINTMENT_ADDITIONAL_PRICE_ERROR,
    ],
    data,
  };
}

export function saveOnDragAppointmentApi(data) {
  return {
    api: AppointmentApi.saveOnDragAppointment,
    types: [
      ActionType.SAVE_DRAG_APPOINTMENT_START,
      ActionType.SAVE_DRAG_APPOINTMENT_SUCCESS,
      ActionType.SAVE_DRAG_APPOINTMENT_ERROR,
    ],
    data,
  };
}
