import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import FlexBox from "../ui/FlexBox";
import { MedicationFormItem } from "./MedicationFormItem";

const enhancer = reduxForm({
  form: "medicationDrug",
});

MedicationDrugForm.propTypes = {
  handleSubmit: PropTypes.func,
};

function MedicationDrugForm({ handleSubmit }) {
  return (
    <FlexBox
      flex={true}
      direction="column"
      className="form-inline medication-drug-form"
      containerElement={<form onSubmit={handleSubmit} />}
    >
      <div className="row">
        <MedicationFormItem label="Code:" name="code" />
        <MedicationFormItem label="Medication Name:" name="name" />
        <MedicationFormItem label="Category:" name="categoryName" />
      </div>

      <div className="row">
        <MedicationFormItem label="Generic Name:" name="genericName" />
        <MedicationFormItem label="Strength:" name="strength" />
        <MedicationFormItem label="Dosage Form:" name="dosageForm" />
      </div>

      <div className="row">
        <MedicationFormItem label="Package Size:" name="packageSize" />

        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
    </FlexBox>
  );
}

export default enhancer(MedicationDrugForm);
