import React from "react";
import { Link } from "react-router-dom";

import Back from "../widgets/Back";
import { Routes } from "../../constants/Routes";

export default class About extends React.Component {
  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">About</div>
        </div>
        <div className="body">
          <div className="list-group">
            <Link to={Routes.SettingsVersion} className="list-group-item">
              <div className="pull-right">❯</div>
              Software version
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
