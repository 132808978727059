// region <Drop down>
export const PLEASE_SELECT = "Please Select";
export const PLEASE_SELECT_ITEM = {
  id: "",
  name: PLEASE_SELECT,
};
export const AppointmentScreenTypes = {
  SimpleScreen: "SIMPLE_SCREEN",
  AdvancedScreen: "ADVANCED_SCREEN",
};

export const NOT_AVAILABLE = "N/A";
export const CUSTOM_PRICE = {
  id: "customPrice",
  name: "Custom",
  price: 0,
  defaultPrice: false,
};

export const ViewTypes = {
  compact: "compact",
  comfort: "comfort",
};

export const TimeViews = {
  time: "time",
  name: "name",
};

export const ColorTypes = {
  type: "type",
  doctor: "doctor",
};

export const SidebarViewTypes = {
  list: "list",
  grid: "grid",
};

export const PostedByTypes = {
  all: "all",
  own: "own",
};

export const TreatmentGrouppingTypes = {
  none: "NO_GROUPING",
  byDate: "GROUP_BY_DATE",
  byProcedure: "GROUP_BY_PROCEDURE",
  byTreatmentCode: "GROUP_BY_TREATMENT",
  // none: "no-groupping",
  // byDate: "group-by-date",
  // byProcedure: "group-by-procedure",
  // byTreatmentCode: "group-by-treatment-code",
};

// endregion
// region <Date & Time>
export const UNIT_DAY = 24;
export const UNIT_TIME = 60;
export const SECOND_MILLISECONDS = 1000;
export const MINUTE_MILLISECONDS = UNIT_TIME * SECOND_MILLISECONDS; // 60*1000
export const HOUR_MILLISECONDS = UNIT_TIME * MINUTE_MILLISECONDS; // 60*60*1000
export const DAY_MILLISECONDS = UNIT_DAY * HOUR_MILLISECONDS; // 24*60*60*1000

export const MINUTE_FOR_VISIBLE = 3 * 60 * MINUTE_MILLISECONDS; // Todo this time for show Check-in button in Appointment Form
// endregion

// region <layout Height>
export const TOP_CLOCK_PANEL_HEIGHT = 20;
export const CALENDAR_TITLE_PANEL_HEIGHT = 50;
export const DEFAULT_HEADER_HEIGHT = 50;
// endregion

// region <Date Formats>
export const DATE_FORMAT = "dd/MM/YYYY";

export const DATE_FORMAT_LEGACY = "DD/MM/YYYY";
export const DATE_FORMAT_LEGACY_WITH_FULL = "DD/MM/YYYY HH:mm:ss";
export const DATE_FORMAT_XRAY = "YYYY/MM/DD";
export const TIME_FORMAT = "hh:mm a";
export const FULL_DATE_FORMAT = "DD/MM/YYYY, hh:mm a";
// endregion
// region <Loader>
export const SELECT_BOX_SPIN_CONFIG = {
  scale: 0.5,
  left: "90%",
  zIndex: 10,
};
// endregion

// region <Appointment child-parent actions>
export const FORM_ACTIONS = {
  DELETE: "DELETE_ACTION",
  SAVE: "SAVE_ACTION",
};
// endregion

// region <API Constans>
export const JSON_FILES = {
  CALENDAR: "calendar.json",
  CALL: "call.json",
  CHOOSE: "choose.json",
  COMPLETE: "complete.json",
  CHOOSE_BY_CODES: "choose-by-codes.json",
  TYPE_AHEAD: "typeahead.json",
  STATUS: "status.json",
  FREE_POINT: "freePoint.json",
  ITEM: "item.json",
  ITEMS: "items.json",
  BATCH_ITEM: "batch-items.json",
  LIST: "list.json",
  LIST_FOR_DENTIST: "list-for-dentist.json",
  LIST_FOR_APPOINTMENT: "list-for-appointment.json",
  LIST_FOR_TREATMENTS_PLAN: "list-for-treatments-plan.json",
  LIST_FOR_COMPLETE: "list-for-complete.json",
  CHANGE_DATE: "change-date.json",
  CHANGE_TREATMENT_PLAN: "change-treatment-plan.json",
  CHANGE_TREATMENT_SESSION: "change-treatment-session.json",
  CHANGE_UNIT: "change-unit.json",
  CHANGE_ASSIGNED_DOCTOR: "change-assigned-doctor.json",
};
// endregion

export const WEEK_DAYS = {
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
};

// region <Alert messages>
export const MSG_IS_CANCEL_APPOINTMENT = "Do you really want to cancel this appointment?";
export const MSG_WILL_MISSED_APPOINTMENT =
  "Do you really want to change the appointment to missed?";
export const MSG_PATIENT_IS_NOT_REGISTERED = "Patient is not registered yet.";
export const MSG_DONT_HAVE_PERMISSION = "You don't have permission for this.";
export const CHOOSE_POPUP = {
  ChooseAction: "Choose Action:",
  /* eslint-disable-next-line */
  AnotherDentist: `Appointment of another ${localStorage.getItem("doctorLabel")}`,
  StartVisit: "Pt.Chart - Start visit",
  FirstVisit: "Pt.Chart - First visit",
  ContinueVisit: "Pt.Chart - Continue visit",
  Edit: "Pt.Chart - Edit",
  JustView: "Pt.Chart - Just view",
  Cancel: "Cancel",
  OK: "OK",
  Close: "Close",
};
// endregion

// region <Transaction params>
export const LIMIT = 20;

// endregion

// region <Chart types>
export const DENTAL_CHART_TYPE = "DENTAL_CHART";
export const DERMATOLOGY_CHART_TYPE = "DERMATOLOGY_CHART";
export const OPHTHALMOLOGY_CHART_TYPE = "OPHTHALMOLOGY_CHART";
export const GENERAL_MEDICINE_CHART_TYPE = "GENERAL_MEDICINE_CHART";
// endregion

// region <Permissions>
export const PERMISSIONS = {
  VIEW_FIRST_CONTACT_VIEW: "VIEW_FIRST_CONTACT_VIEW",
  VIEW_PATIENT_DENTALCHART: "VIEW_PATIENT_DENTALCHART",
  VIEW_PATIENT_CONTACT_DETAILS: "VIEW_PATIENT_CONTACT_DETAILS",
  ADD_PLANNED_DENTAL_TRANSACTION_ITEM_WITHOUT_APPOINTMENT:
    "ADD_PLANNED_DENTAL_TRANSACTION_ITEM_WITHOUT_APPOINTMENT",
};
// endregion

// region <DOM element classes>
export const SELECTED = "selected";
export const ACTIVE = "active";
// endregion

// region <Mouth types>

export const TEETH_TYPES = {
  MouthType: {
    Upper: { id: 1, code: "Upper" },
    Lower: { id: 2, code: "Lower" },
  },
  SextantType: {
    Sextant1: { id: 1, code: "Sextant1" },
    Sextant2: { id: 2, code: "Sextant2" },
    Sextant3: { id: 3, code: "Sextant3" },
    Sextant4: { id: 4, code: "Sextant4" },
    Sextant5: { id: 5, code: "Sextant5" },
    Sextant6: { id: 6, code: "Sextant6" },
  },
  QuadrantType: {
    UpperRight: { id: 1, code: "UpperRight" },
    UpperLeft: { id: 2, code: "UpperLeft" },
    LowerLeft: { id: 3, code: "LowerLeft" },
    LowerRight: { id: 4, code: "LowerRight" },
  },
  SurfaceType: {
    Facial: {
      id: 1,
      code: "F",
      name: "F",
      description: "Facial",
    },
    Mesial: {
      id: 2,
      code: "M",
      name: "M",
      description: "Mesial",
    },
    Lingual: {
      id: 3,
      code: "L",
      name: "L",
      description: "Lingual",
    },
    Distal: {
      id: 4,
      code: "D",
      name: "D",
      description: "Distal",
    },
    Oclusal: {
      id: 5,
      code: "O",
      name: "O",
      description: "Oclusal",
    },
    Incisal: {
      id: 6,
      code: "I",
      name: "I",
      description: "Incisal",
    },
    Buccal: {
      id: 7,
      code: "B",
      name: "B",
      description: "Buccal",
    },
    Facial5: {
      id: 8,
      code: "F5",
      name: "F5",
      description: "Facial5",
    },
    Lingual5: {
      id: 9,
      code: "L5",
      name: "L5",
      description: "Lingual5",
    }, // top
    Buccal5: {
      id: 10,
      code: "B5",
      name: "B5",
      description: "Buccal5",
    }, // bottom
  },
};
// endregion

// region <UI Global Popup Type>
export const PopupType = {
  Small: "small",
  Large: "large",
};
// endregion

// region <Storage keys>
export const CURRENT_MEMBER = "currentMember";
// endregion

export const IS_DEV = process.env.NODE_ENV !== "production";
export let IS_MOBILE = false;
export let IS_DESKTOP = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  IS_MOBILE = true;
}
if (/Electron/i.test(navigator.userAgent)) {
  IS_DESKTOP = true;
}

export const MedicalFormsScreenTypes = {
  MEDICAL_ALERTS: "_ST_MEDICAL_ALERTS",
  DIAGNOSIS: "_ST_DIAGNOSIS",
  TREATMENTS: "_ST_TREATMENTS",
  DRUG_TREATMENTS: "_ST_DRUG_TREATMENTS",
  PRESCRIPTION: "_ST_PRESCRIPTION",
  VITALS: "_ST_VITALS",
  ORDERS_TAB: "_ST_ORDERS_TAB",
  DOCUMENTS_AND_IMAGES: "_ST_DOCUMENTS_AND_IMAGES",
  CONSENT_FORMS: "_ST_CONSENT_FORMS",
  POST_OP_INSTRUCTIONS: "_ST_POST_OP_INSTRUCTIONS",
  INITIAL_ASSESSMENT: "_ST_INITIAL_ASSESSMENT",
  PROVISIONAL_DIAGNOSIS: "_ST_PROVISIONAL_DIAGNOSIS",
};

export const completeAppointmentByUserTypes = {
  LOGGED_USER: "LOGGED_USER",
  APPOINTMENT_USER: "APPOINTMENT_USER",
};
