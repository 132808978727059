import React from "react";

import Back from "../widgets/Back";

import { PostedByTypes } from "../../constants/Constants";

export default class PostedBy extends React.Component {
  setView = (type) => {
    localStorage.setItem("postedBy", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Posted by</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView(PostedByTypes.all)} className="list-group-item">
              {Boolean(localStorage.getItem("postedBy") === PostedByTypes.all) && (
                <div className="pull-right">&#10004;</div>
              )}
              All
            </span>
            <span onClick={() => this.setView(PostedByTypes.own)} className="list-group-item">
              {Boolean(localStorage.getItem("postedBy") === PostedByTypes.own) && (
                <div className="pull-right">&#10004;</div>
              )}
              Own
            </span>
          </div>
        </div>
      </div>
    );
  }
}
