import _ from "lodash";
import React from "react";
import cx from "classnames";

import Tap from "../widgets/Tap";
import DataList from "../widgets/DataList";
import { ViewTypes } from "./ViewButtonGroup";
import { SidebarTypes } from "./TypeButtonGroup";
import fav2Image from "../../assets/images/chart/fav-icon2.png";

export function SidebarCategoriesList({
  typeTab,
  loadList,
  viewTab,
  isQuickMode,
  openCategory,
  openFavorites,
  favouritesIcons,
  searchValue = "",
  specialitiesList,
  showImageSelector,
}) {
  const categories = searchValue
    ? specialitiesList.filter(
        ({ name = "" }) => _.toLower(name).indexOf(_.toLower(searchValue)) !== -1,
      )
    : specialitiesList;

  const parseFavourites = JSON.parse(localStorage.getItem("favourites"));
  const favorites = parseFavourites[typeTab];

  const categoriesList = categories.map((category, index) => {
    const isGroup = _.size(category.children) > 0;

    if (viewTab === ViewTypes.Grid) {
      let src;
      const imageId = favorites[category.id] && favorites[category.id].imageId;

      if (imageId && favouritesIcons[imageId]) {
        src = favouritesIcons[imageId].publicUrl;
      } else {
        src = fav2Image;
      }

      return (
        <Tap
          key={index}
          tag="button"
          className="list-group-item list-group-item-grid"
          short={() => openCategory(index, isGroup, !isGroup ? category.id : undefined)}
          long={() => showImageSelector(category.id)}
        >
          <div className="favourite-icon" style={{ padding: 0 }}>
            <img alt="" src={src} />
          </div>
          <div className="code">{category.name}</div>
        </Tap>
      );
    }

    if (viewTab === ViewTypes.List) {
      return (
        <div
          key={index}
          className="list-group-item-category"
          onClick={() => openCategory(index, isGroup, !isGroup ? category.id : undefined)}
        >
          <div className="name">{category.name}</div>
          <div className="arrow">❯</div>
        </div>
      );
    }
  });

  const favoritesList = isQuickMode || typeTab === SidebarTypes.Grouped ? null : viewTab;
  ViewTypes.Grid ? (
    <div onClick={openFavorites} className="list-group-item list-group-item-grid">
      <div className="favourite-icon" style={{ padding: 0 }}>
        <img src={fav2Image} />
      </div>
      <div className="code">Favourite ({_.size(favorites)})</div>
    </div>
  ) : (
    <div onClick={openFavorites} className="list-group-item-category">
      <div className="name">Favourite ({_.size(favorites)})</div>
      <div className="arrow">❯</div>
    </div>
  );

  return (
    <DataList
      className={cx("list-group", {
        "list-group-grid": viewTab === ViewTypes.Grid,
      })}
      onScroll={loadList}
    >
      {favoritesList}
      {categoriesList}
    </DataList>
  );
}
