import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import { Button } from "react-bootstrap";

import { chartBalanceModalHide } from "../../../actions/chartActions";
import { getAdvancedPayments } from "../../../actions/appointmentActions";
import { Modal } from "../../ui/Modal";
import Show from "../../widgets/Show";
import Utils from "../../../helpers/Utils";

const enhancer = connect(
  ({ appointment: { advancedPayments, fetchingAdvancedPayments }, session: { miscSettings } }) => ({
    advancedPayments,
    fetchingAdvancedPayments,
    miscSettings,
  }),
  { chartBalanceModalHide, getAdvancedPayments },
);

const ChartBalanceModal = (props) => {
  const [activeTab, setActiveTab] = useState(
    props.miscSettings?.customDentalAccountingActivated ? "billing" : "balance",
  );
  useEffect(() => {
    const { getAdvancedPayments, patientKey } = props;

    getAdvancedPayments(patientKey);
  }, []);
  const {
    show,
    chartBalanceValue,
    chartBillingBalanceValue,
    advancedPayments = [],
    chartBalanceModalHide,
    fetchingAdvancedPayments,
    miscSettings: { customDentalAccountingActivated },
  } = props;

  return (
    <Modal
      show={show}
      size="sm"
      className="chart-balance-modal"
      keyboard={false}
      onHide={chartBalanceModalHide}
      actions={
        <Button className="btn btn-primary" onClick={chartBalanceModalHide}>
          OK
        </Button>
      }
    >
      <h4 className="text-center">
        <strong>Info</strong>
      </h4>
      <div className="btn-group" style={{ display: "block" }}>
        {!customDentalAccountingActivated && (
          <button
            type="button"
            onClick={() => setActiveTab("balance")}
            style={{ width: "50%" }}
            className={`btn ${activeTab === "balance" ? "btn-primary" : "btn-default"}`}
          >
            Balance Old
          </button>
        )}
        <button
          type="button"
          onClick={() => setActiveTab("billing")}
          style={{ width: customDentalAccountingActivated ? "100%" : "50%" }}
          className={`btn ${activeTab === "billing" ? "btn-primary" : "btn-default"}`}
        >
          Balance new
        </button>
      </div>
      <Show if={activeTab === "balance"}>
        <h5 className="text-center">Current balance : {Utils.fractionPrice(chartBalanceValue)}</h5>
      </Show>
      <Show if={activeTab === "billing"}>
        <h5 className="text-center">
          Wallet Balance : {Utils.fractionPrice(chartBillingBalanceValue?.walletBalance)}
        </h5>
        <h5 className="text-center">
          Outstanding Balance : {Utils.fractionPrice(chartBillingBalanceValue?.outstandingBalance)}
        </h5>
      </Show>
      <hr />
      <div className="text-center" style={{ fontWeight: "bold", marginTop: "-8px" }}>
        Advance payments
      </div>
      <table width="100%">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Amount (AED)</th>
            <th className="text-center">Paid to</th>
            <th className="text-center">Reference</th>
            <th className="text-center">Product</th>
          </tr>
        </thead>

        <tbody>
          {fetchingAdvancedPayments && (
            <tr>
              <td
                colSpan={5}
                className="text-center"
                style={{
                  height: "24px",
                  position: "relative",
                }}
              >
                <ReactSpinner config={{ scale: "0.5" }} />
              </td>
            </tr>
          )}

          {!fetchingAdvancedPayments &&
            advancedPayments.map((x, idx) => (
              <tr key={idx}>
                <td className="text-center">{idx + 1}</td>
                <td style={{ padding: "0 12px" }}>{x.amount}</td>
                <td style={{ padding: "0 12px" }}>{x.account}</td>
                <td style={{ padding: "0 12px" }}>{x.reference}</td>
                <td style={{ padding: "0 12px" }}>{x.product}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Modal>
  );
};
export default enhancer(ChartBalanceModal);
