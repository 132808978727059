import React, { ReactNode } from "react";
import { Modal, ModalProps as CustomModalProps } from "./Modal";
import { Button } from "./Button";

interface ModalProps extends CustomModalProps {
  readonly info?: ReactNode;
}

function InfoModal({
  info,
  footer,
  size = "sm",
  type,
  ...props
}: Omit<ModalProps, "children" | "actions">) {
  const isWarning = type == "warning" ? true : false;
  return (
    <Modal
      contentClassName={`simple-modal-${type}`}
      size={size}
      {...props}
      actions={footer || <Button onClick={props.onHide}>OK</Button>}
    >
      <span
        style={{
          color: isWarning ? "white" : "#000",
          fontSize: isWarning ? 24 : 14,
          fontWeight: isWarning ? 900 : "normal",
          textAlign: isWarning ? "center" : "left",
        }}
      >
        {info}
      </span>
    </Modal>
  );
}
export default InfoModal;
