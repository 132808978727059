import _ from "lodash";
import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { EditInsurancePlanDialog } from "./EditInsurancePlanDialog";
import { showEditDialog, hideEditDialog } from "../../actions/insuranceActions";

const enhancer = connect(({ insurance: { showEditDialog } }) => ({ open: showEditDialog }), {
  showEditDialog,
  hideEditDialog,
});

export const CustomInsurancePlanButton = enhancer(function CustomInsurancePlanButton({
  open,
  planId,
  formName,
  patientKey,
  showEditDialog,
  hideEditDialog,
  insuranceCompany,
  insurancePlanRowExist,
  insuranceCompanyDetails,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "-10px",
        marginLeft: "8px",
      }}
    >
      <span style={{ textAlign: "center", color: "#ffffff" }}>
        [{insurancePlanRowExist ? "Active" : "Inactive"}]
      </span>

      <button
        style={{
          width: "90px",
          height: "45px",
          whiteSpace: "pre-wrap",
          lineHeight: 1,
          marginTop: "4px",
        }}
        type="button"
        className={cx("btn btn-success", {
          disabled: !planId || !patientKey,
        })}
        onClick={() => (planId && patientKey ? showEditDialog(formName) : _.noop())}
      >
        Custom Coverage
      </button>

      {open === formName && (
        <EditInsurancePlanDialog
          open={true}
          planId={planId}
          patientKey={patientKey}
          onRequestClose={hideEditDialog}
          insuranceCompany={insuranceCompany}
          insurancePlanRowExist={insurancePlanRowExist}
          insuranceCompanyDetails={insuranceCompanyDetails}
        />
      )}
    </div>
  );
});
