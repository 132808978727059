import React, { useEffect } from "react";
import _ from "lodash";
import BackButton from "../../../widgets/BackButton";
import {
  getMedicalFillInFormHardcodedTemplateList,
  disableAllTabsExpectInitialExamination,
  getMedicalFillInFormTemplateList,
  popStack,
  setActiveTab,
  updateStack,
  getMedicalFormConsultationInitialExaminationItemOnly,
  dataToFillHandler,
  getAddendumList,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import { ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import {
  CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS,
  RESET_MEDICAL_FILL_IN_FORM_FOR,
} from "../../../../actionTypes/medicalFillInFormActionTypes";
import {
  setAddDiagnosisForComplete,
  setSideBarTabType,
  showSideBar,
} from "../../../../actions/chartActions";
import viewTypes from "../../../../constants/chart-sidebar/viewTypes";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";
import Show from "../../../widgets/Show";
import { pushOrderStack } from "../../../../actions/orderActions";

const MedicalFillInFormHeader = ({
  initialExaminationRequireApproval,
  chartSideMedicalFillInForm,
  stackItem,
  templateTabList,
  activeTab,
  onSaveItem,
  form,
}) => {
  const dispatch = useDispatch();
  const {
    consultationItems,
    consultationId,
    initialExaminationItem,
    ownedList,
    isUserLeavingWithoutSaving,
    dataToFill,
    initialExaminationConsultation,
    isInitialExaminationTabDisable,
  } = chartSideMedicalFillInForm;

  const _isUserLeavingWithoutSaving = !!dataToFill && isUserLeavingWithoutSaving;

  // useEffect(() => {
  //   if (templateTabList.length) {
  //     dispatch(setActiveTab(templateTabList[0]));
  //     templateTabList.push({
  //       description: "addendum",
  //       id: Date.now() + 1,
  //       name: "Addendums",
  //     });
  //     templateTabList.push({
  //       description: "treatment",
  //       id: Date.now(),
  //       name: "DISCHARGE/FINISH",
  //     });
  //   }
  // }, [templateTabList]);

  // useEffect(() => {
  //   if (consultationId) {
  //     templateTabList.push({
  //       description: "addendum",
  //       id: Date.now() + 1,
  //       name: "Addendums",
  //     });
  //   }
  // }, [consultationId]);

  useEffect(() => {
    if (activeTab.id && !activeTab.isInitialExamination) {
      dispatch(getMedicalFillInFormTemplateList());
      // dispatch(getMedicalFillInFormTemplateList());
      dispatch(getMedicalFillInFormHardcodedTemplateList());
    }
    if (
      activeTab.isInitialExamination ||
      (activeTab.isInitialExamination && stackItem?.type === medicalFillInFormItemTypes.edit)
    ) {
      dispatch(getMedicalFillInFormTemplateList());
    }

    if (
      activeTab.isInitialExamination &&
      (stackItem?.type === medicalFillInFormItemTypes.edit ||
        stackItem?.type === medicalFillInFormItemTypes.new ||
        stackItem.code === medicalFillInFormStackCodes.entry) &&
      _.isEmpty(initialExaminationItem)
    ) {
      dispatch(getMedicalFormConsultationInitialExaminationItemOnly());
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      stackItem.code === medicalFillInFormStackCodes.item &&
      stackItem?.type === medicalFillInFormItemTypes.edit &&
      consultationItems.length
    ) {
      const item = consultationItems[0];
      const forSelect = templateTabList.find((i) => i.id === item.template.templateTypeId);
      dispatch(setActiveTab(forSelect));
      dispatch(getMedicalFillInFormTemplateList());
      dispatch(getMedicalFillInFormHardcodedTemplateList());
    }
  }, [consultationItems]);

  const addTreatment = stackItem.code === medicalFillInFormStackCodes.treatment;
  const addDrugTreatment = stackItem.code === medicalFillInFormStackCodes.drugTreatment;
  const addDiagnosis = stackItem.code === medicalFillInFormStackCodes.diagnosis;
  const provisionalDiagnosis = stackItem.code === medicalFillInFormStackCodes.provisionalDiagnosis;

  const onBack = () => {
    if (stackItem.code === medicalFillInFormStackCodes.entry) {
      return dispatch(
        openSimpleModal({
          body: "Do you want to save your changes?",
          footer: (
            <div className="d-flex align-items-center justify-content-end">
              <button className="btn btn-default ml-3" onClick={() => dispatch(closeSimpleModal())}>
                cancel
              </button>
              <button
                className="btn btn-danger ml-3"
                onClick={() => {
                  dispatch(popStack());
                  dispatch(closeSimpleModal());
                  dispatch({ type: RESET_MEDICAL_FILL_IN_FORM_FOR });
                  dispatch({ type: CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
                }}
              >
                no
              </button>
              <button
                className="btn btn-success ml-3"
                onClick={() => {
                  if (form.questions.length || initialExaminationConsultation.questions.length) {
                    onSaveItem();
                  }
                  dispatch({ type: RESET_MEDICAL_FILL_IN_FORM_FOR });
                  dispatch({ type: CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
                  dispatch(closeSimpleModal());
                  dispatch(popStack());
                }}
              >
                yes
              </button>
            </div>
          ),
        }),
      );
    }
    dispatch(updateStack({ code: medicalFillInFormStackCodes.entry }));
    dispatch(dataToFillHandler(""));
  };
  const addTreatmentOrDiagnosisHandler = () => {
    if (addTreatment) {
      dispatch(
        showSideBar(viewTypes.series, {
          activeTooth: 0,
        }),
      );
      dispatch(setSideBarTabType("treatment"));
    }
    if (addDrugTreatment) {
      dispatch(
        showSideBar(viewTypes.series, {
          activeTooth: 0,
          medicalForms: true,
        }),
      );
      dispatch(setSideBarTabType("treatment"));
    }
    if (addDiagnosis) {
      dispatch(setAddDiagnosisForComplete());
      dispatch(
        showSideBar(viewTypes.series, {
          activeTooth: 0,
        }),
      );
      dispatch(setSideBarTabType("diagnosis"));
    }
    if (provisionalDiagnosis) {
      dispatch(setAddDiagnosisForComplete());
      dispatch(
        showSideBar(viewTypes.series, {
          activeTooth: 0,
        }),
      );
      dispatch(
        pushOrderStack({
          code: medicalFillInFormStackCodes.provisionalDiagnosis,
          type: medicalFillInFormItemTypes.new,
        }),
      );
      dispatch(setSideBarTabType("diagnosis"));
    }
  };

  const switchTabHandler = (tab) => {
    if (
      !_.get(initialExaminationItem, "approved", true) &&
      initialExaminationRequireApproval &&
      !tab.isInitialExamination &&
      !_.isEmpty(ownedList)
    ) {
      return dispatch(
        openSimpleModal({
          body: "Please approve initial examination!",
          footer: (
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-success ml-3"
                onClick={() => {
                  dispatch(closeSimpleModal());
                  dispatch(disableAllTabsExpectInitialExamination(true));
                }}
              >
                Ok
              </button>
            </div>
          ),
        }),
      );
    } else if (
      (stackItem?.type === medicalFillInFormItemTypes.edit ||
        stackItem?.type === medicalFillInFormItemTypes.new) &&
      _isUserLeavingWithoutSaving
    ) {
      return dispatch(
        openSimpleModal({
          body: "Do you want to save your changes?",
          footer: (
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-danger ml-3"
                onClick={() => {
                  dispatch(closeSimpleModal());
                }}
              >
                No
              </button>
              <button
                className="btn btn-success ml-3"
                onClick={() => {
                  onSaveItem();
                  dispatch(closeSimpleModal());
                }}
              >
                Yes
              </button>
            </div>
          ),
        }),
      );
    } else {
      dispatch(setActiveTab(tab));
    }
  };

  return (
    <div className="sidebar-header chart-side-header">
      <div className="clearfix mb-1">
        <div className="col-sm-2">
          <BackButton className="btn-sm" text="Back" onClick={() => onBack()} />
        </div>
        <div className="col-sm-8">
          <div className="chart-clinical-note-title" style={{ textAlign: "center" }}>
            Medical Fill In Form
          </div>
        </div>
        <div className="col-sm-2">
          <Show
            if={
              stackItem?.type === medicalFillInFormItemTypes.edit ||
              stackItem?.type === medicalFillInFormItemTypes.new
            }
          >
            {addTreatment && (
              <button
                type="button"
                className="btn btn-default btn-block"
                onClick={addTreatmentOrDiagnosisHandler}
              >
                Add Treatment
              </button>
            )}
            {addDrugTreatment && (
              <button
                type="button"
                className="btn btn-default btn-block"
                onClick={addTreatmentOrDiagnosisHandler}
              >
                Add Drug Treatment
              </button>
            )}
            {(addDiagnosis || provisionalDiagnosis) && (
              <button
                type="button"
                className="btn btn-default btn-block"
                onClick={addTreatmentOrDiagnosisHandler}
              >
                Add Diagnosis
              </button>
            )}
          </Show>
        </div>
      </div>
      <div
        style={{
          padding: "0 5px",
          visibility: "visible",
        }}
        className="d-flex justify-content-end"
      >
        <ButtonGroup
          aria-label="note tabs"
          className="d-flex align-items-center"
          style={{ overflow: "auto" }}
        >
          {(templateTabList || []).map((tab) => (
            <button
              key={tab.id || tab?.code}
              type="button"
              onClick={() => {
                if (tab.name === "DISCHARGE/FINISH") {
                  dispatch(updateStack({ code: medicalFillInFormStackCodes.treatmentComplete }));
                }
                if (tab.name === "Addendums") {
                  dispatch(getAddendumList(consultationId));
                }
                switchTabHandler(tab);
              }}
              disabled={isInitialExaminationTabDisable && tab.isInitialExamination}
              className={`btn ${
                tab.name === "DISCHARGE/FINISH"
                  ? "btn-danger"
                  : activeTab.id === tab.id
                  ? "btn-primary"
                  : "btn-default"
              }`}
              style={{ minWidth: "150px" }}
            >
              {tab.name}
            </button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default MedicalFillInFormHeader;
