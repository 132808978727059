import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import CallListForm from "./CallListForm";
import CallListTitles from "./CallListTitles";
import CallListBody from "./CallListBody";
import {
  checkOtherDoctor,
  getMachines,
  openCallListPatientNote,
  statusChangeOfAppointment,
} from "../../actions/appointmentActions";
import ChartSideBar from "../chart/side-bar/ChartSideBar";

import {
  changeStatus,
  closeInsuranceModal,
  getStatusList,
  noResponse,
  setClinicId,
  setDoctor,
  setMachine,
  setStatus,
  updateCallListData,
  setPlannedModalVisible,
} from "../../actions/callListActions";
import { hideSideBar, showSideBar } from "../../actions/chartActions";
import { getClinics, getMembers, resetDoctors } from "../../actions/authActions";
import { setPatientKey } from "../../actions/chartSideClinicalNoteActions";
import { getPatientNotes, loadInsuranceNotes, showModal } from "../../actions/patientViewActions";
import Utils from "../../helpers/Utils";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { withRouter } from "react-router";
import BackGroundImage from "../../assets/images/clinic_bg_image.jpg";
import PatientViewModal from "../patient/PatientViewModal";
import Show from "../widgets/Show";
import PatientViewEditNoteModal from "../patient/PatientViewEditNoteModal";
import { AppointmentPlannedModalWrapper } from "../appointment-form/AppointmentPlannedModalWrapper";
import { getTransactionsForAppointment } from "../../actions/transactionActions";

const enhancer = connect(
  ({
    auth: { members, chosenMemberId, clinics },
    chartSideSeries,
    appointment,
    appointment: { machinesList },
    chartSideClinicalNote,
    chart: { sideBarVisible, viewType },
    callList: { columns, doctor, statusList, status, doctors, machineId, clinicId, plannedItem },
    patientView,
    session: { clinic },
  }) => {
    return {
      clinicId,
      clinics,
      machinesList,
      machineId,
      sideBarVisible,
      chartSideClinicalNote,
      viewType,
      member: members[chosenMemberId],
      appointment,
      columns,
      members,
      doctor,
      statusList,
      status,
      doctors,
      patientView,
      clinic,
      chartSideSeries,
      plannedItem,
    };
  },
  {
    resetDoctors,
    getMembers,
    getClinics,
    setClinicId,
    updateCallListData,
    getMachines,
    changeStatus,
    noResponse,
    setMachine,
    openCallListPatientNote,
    hideSideBar,
    showSideBar,
    setPatientKey,
    setDoctor,
    getStatusList,
    setStatus,
    showModal,
    loadInsuranceNotes,
    closeInsuranceModal,
    statusChangeOfAppointment,
    getPatientNotes,
    setPlannedModalVisible,
    getTransactionsForAppointment,
    checkOtherDoctor,
  },
);

class CallList extends React.PureComponent {
  componentDidMount() {
    const {
      clinic,
      getMembers,
      getClinics,
      getMachines,
      setClinicId,
      getStatusList,
      closeInsuranceModal,
      updateCallListData,
    } = this.props;

    const clinicId = _.get(clinic, "id");

    setClinicId(clinicId);

    getClinics();
    getMachines();
    getMembers({ clinicId });
    closeInsuranceModal();
    updateCallListData();
    getStatusList();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateCallListData();
  };

  render() {
    const {
      clinicId,
      setClinicId,
      clinics = [],
      machinesList,
      columns = [],
      getMembers,
      resetDoctors,
      appointmentNoteModal,
      history,
      chartSideSeries,
      plannedItem,
      setPlannedModalVisible,
      getTransactionsForAppointment,
      updateCallListData,
      checkOtherDoctor,
    } = this.props;

    const columnWidth = 440;
    return (
      <div
        className="call-list-page"
        style={{
          backgroundImage: `url(${BackGroundImage})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="call-list-menu">
          <CallListForm
            clinicId={clinicId}
            machinesList={machinesList}
            machineId={this.props.machineId}
            setMachine={this.props.setMachine}
            onSubmit={this.handleSubmit}
            members={this.props.members}
            doctor={this.props.doctor}
            doctors={this.props.doctors}
            status={this.props.status}
            onChangeClinic={(id) => {
              setClinicId(id);
              resetDoctors();
              getMembers({ clinicId: id });
            }}
            clinics={clinics.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
            statusList={this.props.statusList}
            setDoctor={(event) => {
              this.props.setDoctor(event.target.value);
            }}
            setStatus={(event) => {
              this.props.setStatus(event.target.value);
            }}
          />
          <div onClick={history.goBack} className="btn btn-link">
            {"<"} Back
          </div>
        </div>
        <div className="call-list">
          <CallListTitles columns={columns} columnWidth={columnWidth} />
          <CallListBody
            columns={columns}
            columnWidth={columnWidth}
            checkOtherDoctor={checkOtherDoctor}
            changeStatus={this.props.changeStatus}
            setPatientKey={this.props.setPatientKey}
            showSideBar={this.props.showSideBar}
            noResponse={(id, patientKey) => {
              this.props
                .statusChangeOfAppointment({
                  id: id,
                  patientKey,
                  comment: "No Answer",
                  status: STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER,
                  callTime: Utils.calcDateWithTimeZone().valueOf(),
                })
                .then(() => {
                  updateCallListData();
                });
            }}
            appointmentNoteModalShow={this.props.appointmentNoteModalShow}
            openCallListPatientNote={this.props.getPatientNotes}
            openInsuranceNote={this.props.showModal}
            patientView={this.props.patientView}
            loadInsuranceNotes={this.props.loadInsuranceNotes}
            appointmentNoteModal={appointmentNoteModal}
            setPlannedModalVisible={setPlannedModalVisible}
          />
        </div>

        <ChartSideBar
          onCallList={true}
          chartSideSeries={chartSideSeries}
          sideBarVisible={this.props.sideBarVisible}
          viewType={this.props.viewType}
          chartSideClinicalNote={this.props.chartSideClinicalNote}
          hideSideBar={this.props.hideSideBar}
          member={this.props.member}
          clinic={this.props.clinic}
        />

        <Show if={this.props.patientView.modalPatientKey}>
          <PatientViewModal
            patientKey={this.props.patientView.modalPatientKey}
            modalVisible={this.props.patientView.modalVisible}
            modalType={this.props.patientView.modalType}
            notes={this.props.patientView.notes}
            insuranceNotes={this.props.patientView.insuranceNotes}
          />
        </Show>

        <Show if={this.props.patientView.modalPatientKey}>
          <PatientViewEditNoteModal
            patientKey={this.props.patientView.modalPatientKey}
            noteEditVisible={this.props.patientView.noteEditVisible}
            activeNote={this.props.patientView.activeNote}
            modalType={this.props.patientView.modalType}
          />
        </Show>
        <Show if={plannedItem && plannedItem.plannedModalVisible}>
          <AppointmentPlannedModalWrapper
            show={plannedItem && plannedItem.plannedModalVisible}
            patientKey={_.get(plannedItem, "patient.patientKey")}
            onClose={() => setPlannedModalVisible({})}
            getList={getTransactionsForAppointment}
            appointmentData={{ ...plannedItem }}
          />
        </Show>
      </div>
    );
  }
}

export default withRouter(enhancer(CallList));
