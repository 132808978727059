import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import ChartClinicalNoteItemDeleteModal from "./ChartClinicalNoteItemDeleteModal";

import Utils from "../../../../helpers/Utils";
import bindActions from "../../../../helpers/bindActions";

import { FULL_DATE_FORMAT } from "../../../../constants/Constants";
import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";

import * as simpleModalActions from "../../../../actions/simpleModalActions";
import { openSimpleModal } from "../../../../actions/simpleModalActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
import { getCompanyFormatFullDate } from "../../../../helpers/DateUtils";
import { DoctorInfo } from "../../../doctor/DoctorInfo";
import { AppLayout } from "../../../app-layout/AppLayout";

const isEditAvailable = (item, permissions) => {
  const less24hours = Date.now() - item.createdDate < 24 * 60 * 60 * 1000;
  const less48hours = Date.now() - item.createdDate < 48 * 60 * 60 * 1000;
  const less72hours = Date.now() - item.createdDate < 72 * 60 * 60 * 1000;

  const editPermission = permissions.indexOf("EDIT_CLINIC_NOTE_ITEM") >= 0;
  const editAllPermission = permissions.indexOf("EDIT_CLINIC_NOTE_ITEM_ALL") >= 0;
  const less24HourPermission = permissions.indexOf("EDIT_CLINIC_NOTE_ITEM_WITHIN_24_HOURS") >= 0;
  const less48HourPermission = permissions.indexOf("EDIT_CLINIC_NOTE_ITEM_WITHIN_48_HOURS") >= 0;
  const less72HourPermission = permissions.indexOf("EDIT_CLINIC_NOTE_ITEM_WITHIN_72_HOURS") >= 0;
  return !(
    editAllPermission ||
    editPermission ||
    (less48HourPermission && less48hours) ||
    (less24HourPermission && less24hours) ||
    (less72HourPermission && less72hours)
  );
};
const enhancer = connect(
  ({ chartSideClinicalNote: { clinicalNoteActiveTab } }) => ({
    clinicalNoteActiveTab,
  }),
  bindActions({ chartSideClinicalNoteActions, simpleModalActions }),
);

export default enhancer(
  class ChartClinicalNoteItemView extends React.Component {
    componentDidMount() {
      this.props.chartSideClinicalNoteActions.getItem();
    }

    editNote = () => {
      const { stack, item } = this.props.chartSideClinicalNote;
      const asAddendum = item.referenceClinicNoteId;
      if (asAddendum && this.props.permissions.indexOf("EDIT_CLINIC_NOTE_ITEM_ADDENDUM") === -1) {
        return this.props.simpleModalActions.openSimpleModal({
          body: "permission denied to edit reference note",
        });
      }

      if (isEditAvailable(item, this.props.permissions)) {
        return this.props.simpleModalActions.openSimpleModal({
          body: "permission denied",
        });
      }
      const stackItem = _.last(stack);
      const { id } = stackItem.item;

      const data = {
        id,
      };

      const writtenNote = !(item.noteTemplate && item.noteTemplate.name);

      if (writtenNote) {
        this.props.chartSideClinicalNoteActions.openTextarea(clinicNoteItemTypes.edit);
      } else {
        this.props.chartSideClinicalNoteActions.openItem(data, clinicNoteItemTypes.edit);
      }
    };

    isDeleteAvailable = () => {
      const { permissions } = this.props;
      const deleteAllPermission = permissions.indexOf("REMOVE_CLINIC_NOTE_ITEM_ALL") >= 0;
      const deletePermission = permissions.indexOf("REMOVE_CLINIC_NOTE_ITEM") >= 0;
      return !(deleteAllPermission || deletePermission);
    };

    deleteNote = () => {
      if (this.isDeleteAvailable()) {
        return openSimpleModal({
          body: "permission denied",
        });
      }
      this.props.chartSideClinicalNoteActions.openDeleteModal();
    };

    render() {
      const {
        item,
        deleteModalVisible,
        referenceList,
        clinicalNoteActiveTab,
      } = this.props.chartSideClinicalNote;

      const drawenNote = item.typeCode === "NOTE_TYPE_IMAGE";
      const deletedNote = clinicalNoteActiveTab.code === "voidedNotes";
      const asAddendum = item.referenceClinicNoteId;

      return (
        <>
          <AppLayout
            headerClassName="clinical-note-item-view-title"
            onBackClick={this.props.chartSideClinicalNoteActions.popStack}
            title={<div className="chart-number">#{item.patient && item.patient.chartNumber}</div>}
            rightComponent={<div className="name">{item.name}</div>}
          >
            <div className="chart-clinical-note">
              <div className="clinical-note-item">
                <div className="clinical-note-item-view-body">
                  <div className="clinical-note-item-view-details">
                    <div>
                      Patient: <strong>{item.patient && item.patient.fullName}</strong>
                      &nbsp; Chart: <strong>#{item.patient && item.patient.chartNumber}</strong>
                    </div>
                    <div>
                      Created: &nbsp;
                      <strong>
                        {Utils.parseDateFormat(
                          Utils.calcDateWithTimeZone(item.createdDate),
                          FULL_DATE_FORMAT,
                        )}
                      </strong>
                    </div>
                    <div>
                      Age: <strong>{_.get(item, "patient.age")}</strong>
                    </div>
                    <div>
                      [Electronic Stamp] Name: <strong>{item.owner && item.owner.name}</strong>{" "}
                      License: <strong>{_.get(item, "owner.code")}</strong>
                    </div>
                  </div>
                  <div className="clinical-note-item-view-note">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.note }}
                      style={{ overflow: "auto" }}
                    />

                    {!!item.owner && (
                      <DoctorInfo doctor={item.owner} signature={item.doctorSignature} />
                    )}

                    {referenceList.map((x) => (
                      <div key={x.id} className="d-flex flex-column mb-3">
                        <span className="font-weight-bold">{`[Addendum] ${
                          x.name
                        } ${getCompanyFormatFullDate(x.createdDate)}`}</span>
                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: x.note }} />
                        <hr style={{ borderTopColor: "#000000", width: "100%" }} />
                      </div>
                    ))}
                  </div>
                </div>
                {!deletedNote && (
                  <div className="clinical-note-item-view-footer">
                    <div className="clearfix">
                      <div className="col-sm-4">
                        <button
                          disabled={drawenNote}
                          onClick={this.editNote}
                          className="btn btn-primary btn-lg btn-block"
                        >
                          {asAddendum ? "Edit Reference Note" : "Edit Note"}
                        </button>
                      </div>
                      <div className="col-sm-4 col-sm-offset-4">
                        <button
                          onClick={this.deleteNote}
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AppLayout>
          <ChartClinicalNoteItemDeleteModal
            deleteModalVisible={deleteModalVisible}
            closeDeleteModal={() => this.props.chartSideClinicalNoteActions.closeDeleteModal()}
            deleteItem={this.props.chartSideClinicalNoteActions.deleteItem}
          />
        </>
      );
    }
  },
);
