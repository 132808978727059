import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Utils from "../../helpers/Utils";
import bindActions from "../../helpers/bindActions";
import * as chartActions from "../../actions/chartActions";
import { Modal } from "../ui/Modal";

const enhancer = connect(null, bindActions({ chartActions }));

export default enhancer(function ChartAlerts({
  patient,
  chartActions,
  alertsVisible,
  onMedicalEditClick,
  forStaff,
}) {
  const $medicalAlerts = _.get(forStaff, "medicalAlerts.alerts", []).map((item, index) => (
    <div className="chart-alerts-item" key={index}>
      {item.medicalName} {item.note}
    </div>
  ));
  const $medicationAlerts = _.get(forStaff, "medicationAlerts.alerts", []).map((item, index) => (
    <div className="chart-alerts-item" key={index}>
      {Utils.getMedication(item)}
    </div>
  ));

  return (
    <Modal className="chart-alerts-modal p-0" show={alertsVisible} onHide={chartActions.hideAlerts}>
      <div className="modal-header" style={{ display: "flex" }}>
        <div className="modal-title" style={{ flex: 1 }}>
          Alerts for {Utils.getFullName(patient)}
        </div>

        <div
          style={{
            width: "36px",
            height: "36px",
            border: "1px solid #ffffff",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "4px",
            paddingLeft: "2px",
            cursor: "pointer",
          }}
          role="button"
          onClick={chartActions.hideAlerts}
        >
          <span style={{ fontSize: "20px" }}>X</span>
        </div>
      </div>
      <div className="chart-alerts-title">
        <span>Medical alerts</span>

        <div onClick={onMedicalEditClick} role="button">
          Edit
        </div>
      </div>
      <div className="modal-body-content">{$medicalAlerts}</div>
      <div className="chart-alerts-title">Current Medications List</div>
      <div className="modal-body-content">{$medicationAlerts}</div>
    </Modal>
  );
});
