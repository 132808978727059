import React from "react";
import { connect } from "react-redux";
import { bool, func, number, string } from "prop-types";

import Utils from "../../../helpers/Utils";
import { DatePicker } from "../../ui/DatePicker";
import FilledImage from "../../widgets/FilledImage";
import CalendarImage from "../../../assets/images/calendar.png";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import { changeCompletedDate } from "../../../actions/chartSideTransactionActions";

const enhancer = connect();

export const CompletedDateCellWrapper = enhancer(
  class CompletedDateCellWrapper extends React.PureComponent {
    static propTypes = {
      date: number,
      dispatch: func,
      disabled: bool,
      transactionKey: string,
    };

    constructor(props) {
      super(props);

      this.state = {
        date: props.date,
      };
    }

    componentDidMount() {
      const { date } = this.props;

      this.setState({ date });
    }

    changeDate = (x) => {
      const { dispatch, transactionKey } = this.props;

      dispatch(changeCompletedDate(x, transactionKey)).then(() => this.setState({ date: x }));
    };

    render() {
      const { date } = this.state;
      const { disabled } = this.props;

      const formattedDate = date
        ? Utils.parseDateFormat(Utils.calcDateWithTimeZone(date), DATE_FORMAT_LEGACY)
        : "";

      return (
        <div className="transaction-date-cell">
          <span className="cell-title">{formattedDate}</span>
          <div role="button" className="cell-button" onClick={(event) => event.stopPropagation()}>
            <DatePicker
              selected={date}
              className="cell-input"
              onChange={this.changeDate}
              disabled={disabled}
            />
            <FilledImage zoom={76} color="#ffffff" src={CalendarImage} className="cell-icon" />
          </div>
        </div>
      );
    }
  },
);
