import React from "react";
import { Modal } from "../../../ui/Modal";

import { connect } from "react-redux";

import {
  deletePlanItem,
  deletePlanItemConfirmHide,
} from "../../../../actions/chartSidePlanActions";

const enhancer = connect(null, { deletePlanItemConfirmHide, deletePlanItem });

class ConfirmDeletePlanItemModal extends React.Component {
  render() {
    const { deletePlanId } = this.props;
    return (
      <Modal
        show={deletePlanId}
        animation={false}
        size="sm"
        actions={
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.deletePlanItemConfirmHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.props.deletePlanItem(deletePlanId)}
            >
              Delete
            </button>
          </div>
        }
        onHide={this.props.deletePlanItemConfirmHide}
        keyboard={false}
      >
        <div>Are you sure you want to delete note?</div>
      </Modal>
    );
  }
}

export default enhancer(ConfirmDeletePlanItemModal);
