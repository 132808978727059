import _ from "lodash";
import MemberApi from "../api-legacy/MemberApi";
import TransactionApi from "../api-legacy/TransactionApi";
import ClaimApi from "../api-legacy/ClaimApi";

import * as ActionTypes from "../actionTypes/transactionActionTypes";

import { showMessage, startLoader, stopLoader } from "./loaderActions";
import ClinicApi from "../api-legacy/ClinicApi";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import DiagnosisApi from "../api-legacy/DiagnosisApi";

// region <Bottom Layout actions of Chart>
export const GET_APPOINTMENT_TRANSACTIONS_START = "GET_APPOINTMENT_TRANSACTIONS_START";
export const GET_APPOINTMENT_TRANSACTIONS_SUCCESS = "GET_APPOINTMENT_TRANSACTIONS_SUCCESS";
export const GET_APPOINTMENT_TRANSACTIONS_ERROR = "GET_APPOINTMENT_TRANSACTIONS_ERROR";

export const GET_DENTIST_TRANSACTIONS_START = "GET_DENTIST_TRANSACTIONS_START";
export const GET_DENTIST_TRANSACTIONS_SUCCESS = "GET_DENTIST_TRANSACTIONS_SUCCESS";
export const GET_DENTIST_TRANSACTIONS_ERROR = "GET_DENTIST_TRANSACTIONS_ERROR";

export const GET_TRANSACTION_LIST_FOR_COMPLETE_START = "GET_TRANSACTION_LIST_FOR_COMPLETE_START";
export const GET_TRANSACTION_LIST_FOR_COMPLETE_SUCCESS =
  "GET_TRANSACTION_LIST_FOR_COMPLETE_SUCCESS";
export const GET_TRANSACTION_LIST_FOR_COMPLETE_ERROR = "GET_TRANSACTION_LIST_FOR_COMPLETE_ERROR";

export const PUT_DENTAL_TRANSACTION_ITEM_START = "PUT_DENTAL_TRANSACTION_ITEM_START";
export const PUT_DENTAL_TRANSACTION_ITEM_SUCCESS = "PUT_DENTAL_TRANSACTION_ITEM_SUCCESS";
export const PUT_DENTAL_TRANSACTION_ITEM_ERROR = "PUT_DENTAL_TRANSACTION_ITEM_ERROR";

export const SET_TRANSACTION_DATA = "SET_TRANSACTION_DATA";
export const UPDATE_TRANSACTION_LIST = "UPDATE_TRANSACTION_LIST";
export const SET_TRANSACTION_SEARCH_PARAMETERS = "SET_TRANSACTION_SEARCH_PARAMETERS";
export const RESET_TRANSACTION_SEARCH_PARAMETERS = "RESET_TRANSACTION_SEARCH_PARAMETERS";

export const CHANGE_TRANSACTION_TABLE_APPROVAL_FILTER = "CHANGE_TRANSACTION_TABLE_APPROVAL_FILTER";

export const GET_DIAGNOSIS_TYPE_LIST_START = "GET_DIAGNOSIS_TYPE_LIST_START";
export const GET_DIAGNOSIS_TYPE_LIST_SUCCESS = "GET_DIAGNOSIS_TYPE_LIST_SUCCESS";
export const GET_DIAGNOSIS_TYPE_LIST_ERROR = "GET_DIAGNOSIS_TYPE_LIST_ERROR";

export const GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START =
  "GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START";
export const GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS =
  "GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS";
export const GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_ERROR =
  "GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_ERROR";

export function getCurrencies() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getCurrenciesApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeTreatmentPlan(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(changeTreatmentPlanApi(data))
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeTreatmentNote(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(changeTreatmentNoteApi(data))
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeTreatmentSession(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(changeTreatmentSessionApi(data))
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeUnit(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(
      changeUnitApi({
        key: data.key,
        unit: _.toNumber(data.unit),
      }),
    )
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeIntervalUnit(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(
      changeIntervalUnitApi({
        key: data.key,
        intervalUnit: data.intervalUnit,
      }),
    )
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeIntervalAmount(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(
      changeIntervalAmountApi({
        key: data.key,
        intervalAmount: _.toNumber(data.intervalAmount),
      }),
    )
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeDurationUnit(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(
      changeDurationUnitApi({
        key: data.key,
        durationUnit: data.durationUnit,
      }),
    )
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeDurationAmount(data, update = true) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(
      changeDurationAmountApi({
        key: data.key,
        durationAmount: _.toNumber(data.durationAmount),
      }),
    )
      .then(() => {
        if (update) {
          dispatch(getAppointmentTransactions(true));
        }
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeApprovalFilter(data) {
  return {
    type: CHANGE_TRANSACTION_TABLE_APPROVAL_FILTER,
    data,
  };
}

export function getPlannedTransactions(patientKey, appointmentId, treatmentCompleted) {
  return (dispatch) => {
    dispatch(startLoader());

    const data = {
      start: 0,
      limit: 50,
      patientKey,
      appointmentId,
      treatmentPlanned: true,
      treatmentCompleted: treatmentCompleted.treatmentCompleted ? true : null,
    };

    if (!data.appointmentId) {
      return;
    }

    return dispatch(getTransactionsForAppointment(data))
      .then(() => dispatch(stopLoader()))
      .catch((e) => dispatch(stopLoader(e)));
  };
}

export function loadPlannedTransactions(patientKey, appointmentId) {
  return (dispatch, getState) => {
    const {
      transaction: {
        transaction: { list = [], totalCount = 0 },
        fetchingTransactionList,
      },
    } = getState();

    if (list.length >= totalCount || fetchingTransactionList) return;

    const data = {
      start: list.length,
      limit: 10,
      patientKey,
      appointmentId,
      treatmentPlanned: true,
    };

    if (!data.appointmentId) {
      return;
    }

    return dispatch(getTransactionsForAppointment(data));
  };
}

export function getAppointmentTransactions(
  hideLoader,
  isReset,
  diagnosis = {
    diagnosisCompleted: false,
    diagnosisPlanned: false,
    startVisit: false,
  },
  keyPatient,
  appointmentId,
) {
  return function (dispatch, getState) {
    dispatch({
      type: "getAppointmentTransactions",
    });
    if (!hideLoader) dispatch(startLoader());

    if (isReset) {
      dispatch(resetSearchParameters());
    }
    const {
      transaction: {
        transactionSearchParameters,
        doctorFilter,
        toothFilter,
        codeFilter,
        clinicFilter,
        endDateFilter,
        startDateFilter,
      },
      appointment,
      patient: { appointment: patientAppt },
    } = getState();

    const patientKey =
      _.get(appointment, "patient.patientKey") ||
      keyPatient ||
      _.get(patientAppt, "patient.patientKey");

    const data = {
      ...transactionSearchParameters.getQuery(),
      limit: null,
      sortField: transactionSearchParameters?.sortField || "created_date",
      createdDatePeriodEnd: endDateFilter ? getCompanyEndOfDay(endDateFilter) : null,
      createdDatePeriodStart: endDateFilter ? getCompanyStartOfDay(startDateFilter) : null,
    };

    if (!data.patientKey) {
      data.patientKey = patientKey;
    }

    if (!_.isEmpty(doctorFilter)) {
      data.doctorIds = doctorFilter.map((option) => option.value).join(",");
    }

    if (!_.isEmpty(toothFilter)) {
      data.toothCodes = toothFilter.map((option) => option.value).join(",");
    }

    if (diagnosis && diagnosis.diagnosisCompleted) {
      data.diagnosisCompleted = diagnosis.diagnosisCompleted;
    }

    if (diagnosis && diagnosis.diagnosisPlanned) {
      data.diagnosisPlanned = diagnosis.diagnosisPlanned;
    }
    if (diagnosis && diagnosis.startVisit) {
      data.startVisit = diagnosis.startVisit;
    }

    // if (!_.isEmpty(codeFilter)) {
    data.codes = codeFilter; //.map(option => option.value).join(",");
    // }

    if (!_.isEmpty(clinicFilter) && clinicFilter.value !== "all") {
      data.clinicId = clinicFilter.value;
    }

    return dispatch(
      data.appointmentId || appointmentId
        ? getTransactionsForAppointment(data)
        : getTransactionsForDentist(data),
    )
      .then((x) => {
        if (!hideLoader) {
          dispatch(stopLoader());
        }

        return x;
      })
      .catch((error) => {
        if (!hideLoader) {
          dispatch(stopLoader(error));
        }
      });
  };
}

export function getAppointmentProvisionalDiagnosis(hideLoader, _patientKey) {
  return function (dispatch, getState) {
    dispatch({
      type: "getAppointmentProvisionalDiagnosis",
    });

    const {
      transaction: {
        transactionSearchParameters,
        doctorFilter,
        toothFilter,
        codeFilter,
        clinicFilter,
        endDateFilter,
        startDateFilter,
      },
      // appointment,
      patient: { appointment: patientAppt },
      chart: { appointment },
    } = getState();

    const patientKey =
      _.get(appointment, "patient.patientKey") ||
      _patientKey ||
      _.get(patientAppt, "patient.patientKey");

    const data = {
      ...transactionSearchParameters.getQuery(),
      treatmentPlanned: null,
      treatmentCompleted: null,
      diagnosisPlanned: null,
      diagnosisCompleted: null,
      appointmentId: appointment?.id,
      diagnosisProvisional: true,
      limit: null,
      sortField: "created_date_desc",
      codes: codeFilter,
      createdDatePeriodEnd: endDateFilter ? getCompanyEndOfDay(endDateFilter) : null,
      createdDatePeriodStart: endDateFilter ? getCompanyStartOfDay(startDateFilter) : null,
    };

    if (!data.patientKey) {
      data.patientKey = patientKey;
    }

    if (!_.isEmpty(doctorFilter)) {
      data.doctorIds = doctorFilter.map((option) => option.value).join(",");
    }

    if (!_.isEmpty(toothFilter)) {
      data.toothCodes = toothFilter.map((option) => option.value).join(",");
    }

    if (!_.isEmpty(clinicFilter) && clinicFilter.value !== "all") {
      data.clinicId = clinicFilter.value;
    }

    return dispatch(
      data.appointmentId
        ? getProvisionalTransactionsForAppointment(data)
        : getProvisionalTransactionsForDentist(data),
    )
      .then((x) => {
        if (!hideLoader) {
          dispatch(stopLoader());
        }

        return x;
      })
      .catch((error) => {
        if (!hideLoader) {
          dispatch(stopLoader(error));
        }
      });
  };
}

export function getListForComplete(data, hideLoader) {
  return (dispatch, getState) => {
    dispatch({
      type: "getListForComplete",
    });
    if (!hideLoader) dispatch(startLoader());

    const { appointment, patient } = getState().chart;
    const request = data;

    request.patientKey = patient.patientKey;
    request.appointmentId = appointment.id;

    return dispatch(getListForCompleteFn(request))
      .then(() => {
        if (!hideLoader) dispatch(stopLoader());
      })
      .catch((error) => {
        if (!hideLoader) dispatch(stopLoader(error));
      });
  };
}

export function changeTransactionItem(data) {
  return (dispatch) => {
    dispatch({
      type: "changeTransactionItem",
    });

    return dispatch(changeTransactionItemApi(data));
  };
}
export function setTransactionData(data) {
  return {
    type: SET_TRANSACTION_DATA,
    data,
  };
}
export function updateTransactionList(data) {
  return {
    type: UPDATE_TRANSACTION_LIST,
    data,
  };
}
export function setSearchParameters(data) {
  return {
    type: SET_TRANSACTION_SEARCH_PARAMETERS,
    data,
  };
}

export function resetSearchParameters() {
  return {
    type: RESET_TRANSACTION_SEARCH_PARAMETERS,
  };
}

export function getTransactionsForAppointment(data) {
  return {
    request: data,
    api: TransactionApi.getList.forAppointment,
    types: [
      GET_APPOINTMENT_TRANSACTIONS_START,
      GET_APPOINTMENT_TRANSACTIONS_SUCCESS,
      GET_APPOINTMENT_TRANSACTIONS_ERROR,
    ],
    data,
  };
}

export function getProvisionalTransactionsForAppointment(data) {
  return {
    request: data,
    api: TransactionApi.getList.forAppointment,
    types: [
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START,
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS,
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_ERROR,
    ],
    data,
  };
}

export function getDiagnosisTypeList() {
  return (dispatch) => {
    return dispatch(getDiagnosisTypeListFn());
  };
}

function getDiagnosisTypeListFn() {
  return {
    api: DiagnosisApi.getDiagnosisTypeList,
    types: [
      GET_DIAGNOSIS_TYPE_LIST_START,
      GET_DIAGNOSIS_TYPE_LIST_SUCCESS,
      GET_DIAGNOSIS_TYPE_LIST_ERROR,
    ],
  };
}

function getProvisionalTransactionsForDentist(data) {
  return {
    request: data,
    api: TransactionApi.getList.forDentist,
    types: [
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START,
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS,
      GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_ERROR,
    ],
    data,
  };
}
function getTransactionsForDentist(data) {
  return {
    request: data,
    api: TransactionApi.getList.forDentist,
    types: [
      GET_DENTIST_TRANSACTIONS_START,
      GET_DENTIST_TRANSACTIONS_SUCCESS,
      GET_DENTIST_TRANSACTIONS_ERROR,
    ],
    data,
  };
}
function getListForCompleteFn(data) {
  return {
    request: data,
    api: TransactionApi.getList.forComplete,
    types: [
      GET_TRANSACTION_LIST_FOR_COMPLETE_START,
      GET_TRANSACTION_LIST_FOR_COMPLETE_SUCCESS,
      GET_TRANSACTION_LIST_FOR_COMPLETE_ERROR,
    ],
    data,
  };
}
function changeTransactionItemApi(data) {
  return {
    api: TransactionApi.item.changeTransaction,
    types: [
      PUT_DENTAL_TRANSACTION_ITEM_START,
      PUT_DENTAL_TRANSACTION_ITEM_SUCCESS,
      PUT_DENTAL_TRANSACTION_ITEM_ERROR,
    ],
    data,
  };
}
// endregion

// region <Bottom Menu & POPUP Transactions>
export const PUT_HOLD_PRE_APPROVAL_TRANSACTION_START = "PUT_HOLD_PRE_APPROVAL_TRANSACTION_START";
export const PUT_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS =
  "PUT_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS";
export const PUT_HOLD_PRE_APPROVAL_TRANSACTION_ERROR = "PUT_HOLD_PRE_APPROVAL_TRANSACTION_ERROR";

export const POST_HOLD_PRE_APPROVAL_TRANSACTION_START = "POST_HOLD_PRE_APPROVAL_TRANSACTION_START";
export const POST_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS =
  "POST_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS";
export const POST_HOLD_PRE_APPROVAL_TRANSACTION_ERROR = "POST_HOLD_PRE_APPROVAL_TRANSACTION_ERROR";

export const METHOD_POST_SEND_TO_PRE_APPROVAL_START = "METHOD_POST_SEND_TO_PRE_APPROVAL_START";
export const METHOD_POST_SEND_TO_PRE_APPROVAL_SUCCESS = "METHOD_POST_SEND_TO_PRE_APPROVAL_SUCCESS";
export const METHOD_POST_SEND_TO_PRE_APPROVAL_ERROR = "METHOD_POST_SEND_TO_PRE_APPROVAL_ERROR";

export const METHOD_POST_TRANSACTIONS_COMPLETE_START = "METHOD_POST_TRANSACTIONS_COMPLETE_START";
export const METHOD_POST_TRANSACTIONS_COMPLETE_SUCCESS =
  "METHOD_POST_TRANSACTIONS_COMPLETE_SUCCESS";
export const METHOD_POST_TRANSACTIONS_COMPLETE_ERROR = "METHOD_POST_TRANSACTIONS_COMPLETE_ERROR";

export const SET_COMPLETE_POPUP_SEARCH_PARAMETERS = "SET_COMPLETE_POPUP_SEARCH_PARAMETERS";
export const CHANGE_TRANSACTION_KEYS_BY_CHECKED = "CHANGE_TRANSACTION_KEYS_BY_CHECKED";
export const SELECT_ALL_CHECKBOXES_IN_COMPLETE_POPUP = "SELECT_ALL_CHECKBOXES_IN_COMPLETE_POPUP";

export function setHoldPreApproval(keys) {
  return function (dispatch) {
    dispatch({
      type: "setHoldPreApproval",
    });
    dispatch(startLoader());

    return dispatch(setHoldPreApprovalFn({ keys }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getHoldPreApproval(data) {
  return function (dispatch) {
    dispatch({
      type: "getHoldPreApproval",
    });
    dispatch(startLoader());

    return dispatch(getHoldPreApprovalFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
function setHoldPreApprovalFn(data) {
  return {
    api: TransactionApi.holdPreApproval.setTransaction,
    types: [
      PUT_HOLD_PRE_APPROVAL_TRANSACTION_START,
      PUT_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS,
      PUT_HOLD_PRE_APPROVAL_TRANSACTION_ERROR,
    ],
    data,
  };
}
function getHoldPreApprovalFn(data) {
  return {
    api: TransactionApi.holdPreApproval.getTransaction,
    types: [
      POST_HOLD_PRE_APPROVAL_TRANSACTION_START,
      POST_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS,
      POST_HOLD_PRE_APPROVAL_TRANSACTION_ERROR,
    ],
    data,
  };
}
export function setCompletePopupSearchParameters(data) {
  return {
    type: SET_COMPLETE_POPUP_SEARCH_PARAMETERS,
    data,
  };
}
export function addKeysWithCheckbox(key) {
  return {
    type: CHANGE_TRANSACTION_KEYS_BY_CHECKED,
    key,
  };
}

export function selectAllCheckboxCompletePopup(isClearAll) {
  return {
    type: SELECT_ALL_CHECKBOXES_IN_COMPLETE_POPUP,
    isClearAll,
  };
}

export function sendToPreApproval(create) {
  return function (dispatch, getState) {
    dispatch({
      type: "sendToPreApproval",
    });
    dispatch(startLoader());

    const {
      chart: { patient, appointment },
      chartSideSeries,
    } = getState();

    const request = {};

    request.status = { code: create || "Later" };
    request.patientKey = patient.patientKey;
    request.appointmentId = appointment.id;
    request.diagnosis = chartSideSeries.transactionForm.diagnosisItems.filter(Boolean);

    request.dentalTransactions = chartSideSeries.transactionStatus.dentalTransactionKeys;

    return dispatch(sendToPreApprovalFn(request))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function completeTransaction(dentistToCashierNote) {
  return function (dispatch, getState) {
    dispatch({
      type: "completeTransaction",
    });
    dispatch(startLoader());
    const {
      chart: { appointment },
      transaction,
    } = getState();
    const request = {
      dentistToCashierNote,
    };
    request.currentAppointmentId = appointment.id;

    request.transactionKeys = transaction.completePopup.selectedKeys;
    return dispatch(completeTransactionFn(request))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function sendToPreApprovalFn(data) {
  return {
    api: ClaimApi.sendToPreApproval,
    types: [
      METHOD_POST_SEND_TO_PRE_APPROVAL_START,
      METHOD_POST_SEND_TO_PRE_APPROVAL_SUCCESS,
      METHOD_POST_SEND_TO_PRE_APPROVAL_ERROR,
    ],
    data,
  };
}

function completeTransactionFn(data) {
  return {
    api: TransactionApi.completeTransaction,
    types: [
      METHOD_POST_TRANSACTIONS_COMPLETE_START,
      METHOD_POST_TRANSACTIONS_COMPLETE_SUCCESS,
      METHOD_POST_TRANSACTIONS_COMPLETE_ERROR,
    ],
    data,
  };
}
// endregion

export function openPlanModal() {
  return {
    type: ActionTypes.CHART_TRANSACTION_OPEN_PLAN_MODAL,
  };
}

export function closePlanModal() {
  return {
    type: ActionTypes.CHART_TRANSACTION_CLOSE_PLAN_MODAL,
  };
}

export function toggleAddMode() {
  return {
    type: ActionTypes.CHART_TRANSACTION_TOGGLE_ADD_MODE,
  };
}

export function initPlanModal() {
  return {
    type: ActionTypes.CHART_TRANSACTION_INIT_PLAN_MODAL,
  };
}

export function initPlan() {
  return function (dispatch, getState) {
    const {
      chart: { patient },
      session: { member },
    } = getState();
    const request = {
      memberId: member.id,
      patientKey: patient.patientKey,
      start: 0,
      limit: 25,
    };

    return dispatch(getPlaListApi(request))
      .then(() => dispatch(loadPlan()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

function loadPlan() {
  return function (dispatch, getState) {
    const {
      chart: { patient },
      session: { member },
      transaction: { planList, planListTotalCount },
    } = getState();

    if (planList.length < planListTotalCount) {
      const request = {
        memberId: member.id,
        patientKey: patient.patientKey,
        start: planList.length,
        limit: 25,
      };

      return dispatch(getPlaListApi(request)).then(() => dispatch(loadPlan()));
    }
  };
}

export function initPlanNew() {
  return function (dispatch, getState) {
    const {
      chart: { patient },
      session: { member },
    } = getState();
    const request = {
      excludeMemberId: member.id,
      patientKey: patient.patientKey,
      start: 0,
      limit: 25,
    };

    return dispatch(getPlaListApi(request))
      .then(() => dispatch(loadPlanNew()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

function loadPlanNew() {
  return function (dispatch, getState) {
    const {
      chart: { patient },
      session: { member },
      transaction: { planNewList, planNewListTotalCount },
    } = getState();

    if (planNewList.length < planNewListTotalCount) {
      const request = {
        excludeMemberId: member.id,
        patientKey: patient.patientKey,
        start: planNewList.length,
        limit: 25,
      };

      return dispatch(getPlaListApi(request)).then(() => dispatch(loadPlan()));
    }
  };
}

export function togglePlan(key) {
  return {
    type: ActionTypes.CHART_TRANSACTION_TOGGLE_PLAN,
    key,
  };
}

export function togglePlanNew(key) {
  return {
    type: ActionTypes.CHART_TRANSACTION_TOGGLE_PLAN_NEW,
    key,
  };
}

export function toggleAllPlanNew() {
  return {
    type: ActionTypes.CHART_TRANSACTION_TOGGLE_ALL_PLAN_NEW,
  };
}

export function toggleAllPlan() {
  return {
    type: ActionTypes.CHART_TRANSACTION_TOGGLE_ALL_PLAN,
  };
}

export function unSelectPlan() {
  return {
    type: ActionTypes.CHART_TRANSACTION_UNSELECT_PLAN,
  };
}

export function removeSelected() {
  return function (dispatch, getState) {
    const {
      session: { member },
      transaction: { planSelected },
    } = getState();

    const keys = _(planSelected)
      .pickBy((boolean) => boolean)
      .keys()
      .value();

    const request = {
      memberId: member.id,
      keys,
    };

    dispatch(startLoader());
    return dispatch(removeSelectedApi(request))
      .then(() => dispatch(initPlan()))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function addSelected() {
  return function (dispatch, getState) {
    const {
      session: { member },
      transaction: { planNewSelected },
    } = getState();

    const keys = _(planNewSelected)
      .pickBy((boolean) => boolean)
      .keys()
      .value();

    const request = {
      memberId: member.id,
      keys,
    };

    dispatch(startLoader());
    return dispatch(addSelectedApi(request))
      .then(() => dispatch(toggleAddMode()))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function doctorFilterChange(data, isChartOrMedicalFormComplete = false) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TRANSACTION_DOCTOR_FILTER,
      data,
    });

    if (!isChartOrMedicalFormComplete) {
      return dispatch(getAppointmentTransactions(true, true));
    }
  };
}

export function toothFilterChange(data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TRANSACTION_TOOTH_FILTER,
      data,
    });

    return dispatch(getAppointmentTransactions(true, true));
  };
}

export function codeFilterChange(data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TRANSACTION_CODES_FILTER,
      data,
    });

    return dispatch(getAppointmentTransactions(true, true));
  };
}

export function startDateFilterChange(date) {
  return {
    type: ActionTypes.SET_START_DATE_FILTER,
    date,
  };
}

export function endDateFilterChange(date) {
  return {
    type: ActionTypes.SET_END_DATE_FILTER,
    date,
  };
}

export function clinicFilterChange(data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TRANSACTION_CLINIC_FILTER,
      data,
    });

    return dispatch(getAppointmentTransactions(true, true));
  };
}

export function uploadTransactionImage(files, key) {
  return (dispatch, getState) => {
    const {
      transaction: { transactionSearchParameters },
    } = getState();

    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file, file.name);
    });

    data.append("key", key);
    data.append("description", "");

    dispatch(startLoader());
    dispatch({
      api: TransactionApi.uploadTransactionImage,
      types: [
        ActionTypes.CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_START,
        ActionTypes.CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_SUCCESS,
        ActionTypes.CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_ERROR,
      ],
      data,
    })
      .then(() => {
        transactionSearchParameters.clear();
        dispatch(getAppointmentTransactions(true));
      })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDoctors(data) {
  return {
    api: MemberApi.getDentistWithPoints,
    types: [
      ActionTypes.CHART_TRANSACTION_GET_DOCTORS_START,
      ActionTypes.CHART_TRANSACTION_GET_DOCTORS_SUCCESS,
      ActionTypes.CHART_TRANSACTION_GET_DOCTORS_ERROR,
    ],
    data,
  };
}

function getPlaListApi(data) {
  return {
    api: TransactionApi.plan.getList,
    types: [
      ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_START,
      ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_SUCCESS,
      ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_ERROR,
    ],
    data,
  };
}

function removeSelectedApi(data) {
  return {
    api: TransactionApi.plan.removeSelected,
    types: [
      ActionTypes.CHART_TRANSACTION_REMOVE_SELECTED_START,
      ActionTypes.CHART_TRANSACTION_REMOVE_SELECTED_SUCCESS,
      ActionTypes.CHART_TRANSACTION_REMOVE_SELECTED_ERROR,
    ],
    data,
  };
}

function addSelectedApi(data) {
  return {
    api: TransactionApi.plan.addSelected,
    types: [
      ActionTypes.CHART_TRANSACTION_ADD_SELECTED_START,
      ActionTypes.CHART_TRANSACTION_ADD_SELECTED_SUCCESS,
      ActionTypes.CHART_TRANSACTION_ADD_SELECTED_ERROR,
    ],
    data,
  };
}

function changeTreatmentPlanApi(data) {
  return {
    api: TransactionApi.changeTreatmentPlan,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_ERROR,
    ],
    data,
  };
}

function changeTreatmentSessionApi(data) {
  return {
    api: TransactionApi.changeTreatmentSession,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_ERROR,
    ],
    data,
  };
}

function changeTreatmentNoteApi(data) {
  return {
    api: TransactionApi.item.updateNote,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_ERROR,
    ],
    data,
  };
}

function changeUnitApi(data) {
  return {
    api: TransactionApi.changeUnit,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_UNIT_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_UNIT_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_UNIT_ERROR,
    ],
    data,
  };
}

function changeIntervalUnitApi(data) {
  return {
    api: TransactionApi.changeIntervalUnit,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_ERROR,
    ],
    data,
  };
}

function changeIntervalAmountApi(data) {
  return {
    api: TransactionApi.changeIntervalAmount,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_ERROR,
    ],
    data,
  };
}

function changeDurationUnitApi(data) {
  return {
    api: TransactionApi.changeDurationUnit,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_UNIT_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_UNIT_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_UNIT_ERROR,
    ],
    data,
  };
}

function changeDurationAmountApi(data) {
  return {
    api: TransactionApi.changeDurationAmount,
    types: [
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_START,
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_SUCCESS,
      ActionTypes.CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_ERROR,
    ],
    data,
  };
}

function getCurrenciesApi(data) {
  return {
    api: ClinicApi.getCurrencies,
    types: [
      ActionTypes.CHART_TRANSACTION_GET_CURRENCIES_START,
      ActionTypes.CHART_TRANSACTION_GET_CURRENCIES_SUCCESS,
      ActionTypes.CHART_TRANSACTION_GET_CURRENCIES_ERROR,
    ],
    data,
  };
}

export function updatePriceApi(data) {
  return {
    api: TransactionApi.updatePrice,
    types: [
      ActionTypes.CHART_TRANSACTION_UPDATE_PRICE_START,
      ActionTypes.CHART_TRANSACTION_UPDATE_PRICE_SUCCESS,
      ActionTypes.CHART_TRANSACTION_UPDATE_PRICE_ERROR,
    ],
    data,
  };
}
