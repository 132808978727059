import React from "react";
import { connect } from "react-redux";
import Back from "../../../widgets/Back";
import EmbedButton from "../../../widgets/EmbedButton";
import ReactSpinner from "react-spinjs-fix";

const enhancer = connect(
  ({ chart, chartSideMedicalFillInForm, chartSideOrder }) => ({
    chart,
    chartSideMedicalFillInForm,
    chartSideOrder,
  }),
  null,
);

const MedicalFormOrderView = ({ chart, sidebarVisibleHandler, pdfUrl }) => {
  const { patient } = chart;

  let patientData = patient.firstName || "";
  const lastName = patient.lastName || "";
  const chartNumber = patient.chartNumber || "";

  patientData += lastName !== "" ? " " + lastName : "";
  patientData += patientData !== "" && chartNumber !== "" ? ", " : "";
  patientData += chartNumber !== "" ? "#" + chartNumber : "";

  return (
    <div className="patient-appointments-list">
      <div className="default-page-title clearfix text-center">
        <Back className="pull-left" onClick={sidebarVisibleHandler} />
        <div className="btn-user-name pull-right">{patientData}</div>
      </div>
      <div className="order-pdf-view">
        <EmbedButton src={pdfUrl} show={pdfUrl} />
        <ReactSpinner />
      </div>
    </div>
  );
};
export default enhancer(MedicalFormOrderView);
