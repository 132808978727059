import { httpClient } from "./BaseApi";

const UPDATE_TRANSACTION_DISCOUNT_URL = "api/dental-transaction/update-discount.json";

const TRANSACTION_ITEM_URL = "api/dental-transaction/item.json";
const TRANSACTION_VOID_URL = "api/dental-transaction/void.json";
const TRANSACTION_UPLOAD_URL = "api/dental-transaction/upload.json";
const TRANSACTION_COMPLETE_URL = "api/dental-transaction/complete.json";
const TRANSACTION_BATCH_ITEMS_URL = "api/dental-transaction/batch-items.json";
const TRANSACTION_UPDATE_PRICE_URL = "api/dental-transaction/update-price.json";
const TRANSACTION_LIST_FOR_DENTIST_URL = "api/dental-transaction/list-for-dentist.json";
const TRANSACTION_LIST_BREAK_TO_PARTS_URL = "api/dental-transaction/break-to-parts.json";
const TRANSACTION_LIST_FOR_COMPLETE_URL = "api/dental-transaction/list-for-complete.json";
const TRANSACTION_LIST_FOR_APPOINTMENT_URL = "api/dental-transaction/list-for-appointment.json";
const TRANSACTION_LIST_FOR_TREATMENTS_PLAN_URL =
  "api/dental-transaction/list-for-treatments-plan.json";

const TRANSACTION_TREATMENTS_PLAN_LIST_URL = "api/dental-transaction/treatments-plan/list.json";
const TRANSACTION_TREATMENTS_PLAN_ITEM_URL = "api/dental-transaction/treatments-plan/item.json";
const TRANSACTION_TREATMENTS_PLAN_SEND_TO_EMAIL_URL =
  "api/dental-transaction/treatments-plan/send-to-email.json";

const TRANSACTION_ITEM_CHANGE_DATE_URL = "api/dental-transaction/item/change-date.json";
const TRANSACTION_ITEM_UPDATE_NOTE_URL = "api/dental-transaction/item/update-note.json";
const TRANSACTION_ITEM_CHANGE_UNIT_URL = "api/dental-transaction/item/change-unit.json";
const TRANSACTION_ITEM_CHANGE_INTERVAL_UNIT_URL =
  "api/dental-transaction/item/change-interval-unit.json";
const TRANSACTION_ITEM_CHANGE_INTERVAL_AMOUNT_URL =
  "api/dental-transaction/item/change-interval-amount.json";
const TRANSACTION_ITEM_CHANGE_DURATION_UNIT_URL =
  "api/dental-transaction/item/change-duration-unit.json";
const TRANSACTION_ITEM_CHANGE_DURATION_AMOUNT_URL =
  "api/dental-transaction/item/change-duration-amount.json";
const TRANSACTION_ITEM_CHANGE_TREATMENT_PLAN_URL =
  "api/dental-transaction/item/change-treatment-plan.json";
const TRANSACTION_ITEM_CHANGE_TREATMENT_SESSION_URL =
  "api/dental-transaction/item/change-treatment-session.json";

const TRANSACTION_HOLD_PRE_APPROVAL_ITEMS_URL =
  "api/dental-transaction/hold-pre-approval/items.json";

const TRANSACTION_DOCTOR_PLANNED_ADD_URL = "api/dental-transaction/doctor-planned/add.json";
const TRANSACTION_DOCTOR_PLANNED_LIST_URL = "api/dental-transaction/doctor-planned/list.json";
const TRANSACTION_DOCTOR_PLANNED_REMOVE_URL = "api/dental-transaction/doctor-planned/remove.json";

const TRANSACTION_RELATED_APPOINTMENT_ADD_URL =
  "api/dental-transaction/related-appointment/add.json";
const TRANSACTION_RELATED_APPOINTMENT_REMOVE_URL =
  "api/dental-transaction/related-appointment/remove.json";
const TRANSACTION_LIST_FOR_RELATED_APPOINTMENT_URL =
  "api/dental-transaction/list-for-related-appointment.json";
const TRANSACTION_PRIMARY_DIAGNOSIS_URL =
  "api/dental-transaction/item/update-diagnosis-claim-type.json";

const UPDATE_TREATMENT_CONSUMED_CANCELLED_URL = "api/dental-transaction/cancelled-consumed.json";

const TransactionApi = {
  updatePrice: (data) => httpClient.put(TRANSACTION_UPDATE_PRICE_URL, { data }),
  updateCancelledConsumedTransactions: (data) =>
    httpClient.put(UPDATE_TREATMENT_CONSUMED_CANCELLED_URL, { data }),
  setPrimaryDiagnosis: (data) => httpClient.put(TRANSACTION_PRIMARY_DIAGNOSIS_URL, { data }),
  voidItem: (params) => httpClient.delete(TRANSACTION_VOID_URL, { params }),
  deleteItem: (params) => httpClient.delete(TRANSACTION_ITEM_URL, { params }),
  changeUnit: (data) => httpClient.put(TRANSACTION_ITEM_CHANGE_UNIT_URL, { data }),
  changeIntervalUnit: (data) => httpClient.put(TRANSACTION_ITEM_CHANGE_INTERVAL_UNIT_URL, { data }),
  changeIntervalAmount: (data) =>
    httpClient.put(TRANSACTION_ITEM_CHANGE_INTERVAL_AMOUNT_URL, { data }),
  changeDurationUnit: (data) => httpClient.put(TRANSACTION_ITEM_CHANGE_DURATION_UNIT_URL, { data }),
  changeDurationAmount: (data) =>
    httpClient.put(TRANSACTION_ITEM_CHANGE_DURATION_AMOUNT_URL, { data }),
  completeTransaction: (data) => httpClient.post(TRANSACTION_COMPLETE_URL, { data }),
  uploadTransactionImage: (data) => httpClient.post(TRANSACTION_UPLOAD_URL, { data }),
  updateDiscount: (data) => httpClient.put(UPDATE_TRANSACTION_DISCOUNT_URL, { data }),
  updateBreakToVisit: (data) => httpClient.put(TRANSACTION_LIST_BREAK_TO_PARTS_URL, { data }),
  editTreatmentPlan: (data) => httpClient.put(TRANSACTION_TREATMENTS_PLAN_ITEM_URL, { data }),
  createBreakToVisit: (data) => httpClient.post(TRANSACTION_LIST_BREAK_TO_PARTS_URL, { data }),
  saveTreatmentPlan: (data) => httpClient.post(TRANSACTION_TREATMENTS_PLAN_ITEM_URL, { data }),
  sendToEmail: (params) =>
    httpClient.get(TRANSACTION_TREATMENTS_PLAN_SEND_TO_EMAIL_URL, { params }),
  getTreatmentPlanList: (params) =>
    httpClient.get(TRANSACTION_TREATMENTS_PLAN_LIST_URL, { params }),
  changeTreatmentPlan: (data) =>
    httpClient.put(TRANSACTION_ITEM_CHANGE_TREATMENT_PLAN_URL, { data }),
  deleteTreatmentPlanItem: (params) =>
    httpClient.delete(TRANSACTION_TREATMENTS_PLAN_ITEM_URL, { params }),
  getListForTreatmentPlan: (params) =>
    httpClient.get(TRANSACTION_LIST_FOR_TREATMENTS_PLAN_URL, { params }),
  changeTreatmentSession: (data) =>
    httpClient.put(TRANSACTION_ITEM_CHANGE_TREATMENT_SESSION_URL, { data }),
  getList: {
    forDentist: (params) => httpClient.get(TRANSACTION_LIST_FOR_DENTIST_URL, { params }),
    forComplete: (params) => httpClient.get(TRANSACTION_LIST_FOR_COMPLETE_URL, { params }),
    forAppointment: (params) => httpClient.get(TRANSACTION_LIST_FOR_APPOINTMENT_URL, { params }),
    forTreatments: (params) => httpClient.get(TRANSACTION_LIST_FOR_TREATMENTS_PLAN_URL, { params }),
  },
  item: {
    changeDate: (data) => httpClient.put(TRANSACTION_ITEM_CHANGE_DATE_URL, { data }),
    updateNote: (data) => httpClient.put(TRANSACTION_ITEM_UPDATE_NOTE_URL, { data }),
    setTreatmentPlan: (data) =>
      httpClient.put(TRANSACTION_ITEM_CHANGE_TREATMENT_PLAN_URL, { data }),
    setTreatmentSession: (data) =>
      httpClient.put(TRANSACTION_ITEM_CHANGE_TREATMENT_SESSION_URL, { data }),
    changeTransaction: ({ jsonFile, ...data }) =>
      httpClient.put(`api/dental-transaction/item/${jsonFile}`, {
        data,
      }),
  },
  batchItem: {
    create: (data) => httpClient.post(TRANSACTION_BATCH_ITEMS_URL, { data }),
  },
  holdPreApproval: {
    setTransaction: (data) => httpClient.put(TRANSACTION_HOLD_PRE_APPROVAL_ITEMS_URL, { data }),
    getTransaction: (data) => httpClient.post(TRANSACTION_HOLD_PRE_APPROVAL_ITEMS_URL, { data }),
  },
  plan: {
    addSelected: (data) => httpClient.put(TRANSACTION_DOCTOR_PLANNED_ADD_URL, { data }),
    getList: (params) => httpClient.get(TRANSACTION_DOCTOR_PLANNED_LIST_URL, { params }),
    removeSelected: (data) => httpClient.put(TRANSACTION_DOCTOR_PLANNED_REMOVE_URL, { data }),
  },
  related: {
    add: (data) => httpClient.put(TRANSACTION_RELATED_APPOINTMENT_ADD_URL, { data }),
    remove: (data) => httpClient.put(TRANSACTION_RELATED_APPOINTMENT_REMOVE_URL, { data }),
    getList: (params) => httpClient.get(TRANSACTION_LIST_FOR_RELATED_APPOINTMENT_URL, { params }),
  },
};

export default TransactionApi;
