import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { uploadFiles } from "../../actions/folderActions";

let xrayInterval;
let uploading = false;
const XRayForm = ({ patient }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    xrayInterval = setInterval(() => {
      getXRayImage();
    }, 5000);
    // window.parent.window.createPatientFileToDevice(
    //   `patientimport.txt`,
    //   `${patient.lastName};${patient.firstName};01.01.1976;${patient.chartNumber}`,
    // );
    return () => {
      clearInterval(xrayInterval);
    };
  }, []);

  const getXRayImage = () => {
    if (uploading || localStorage.getItem("syncXray") === "false") return;
    const fileName = `${patient.chartNumber}.jpg`;
    const filePath = localStorage.getItem("fileUrl");
    const xrayImage = window.parent.window.readLocalFile(filePath + fileName);

    const dataURLtoFile = (dataUrl, filename) => {
      let arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    };

    if (xrayImage) {
      const b64 = Buffer.from(xrayImage).toString("base64");

      dispatch(
        uploadFiles({
          patientKey: patient.patientKey,
          files: [dataURLtoFile("data:image/jpeg;base64," + b64, fileName)],
          folderId: 0,
        }),
      ).then(() => {
        window.parent.window.removeFileFromDevice(`${patient.chartNumber}.jpg`);
        uploading = false;
      });
    }
  };

  return <div style={{ position: "absolute", opacity: 0 }} />;
};

export default XRayForm;
