import React from "react";

import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

export default function ChartClinicalNoteItemDeleteModal({
  deleteItem,
  closeDeleteModal,
  deleteModalVisible,
}) {
  return (
    <Modal
      size="small"
      show={deleteModalVisible}
      onHide={closeDeleteModal}
      actions={
        <div>
          <Button onClick={closeDeleteModal}>Close</Button>
          <Button onClick={deleteItem}>Ok</Button>
        </div>
      }
      keyboard={false}
    >
      <div>Are you sure you want to delete this note? This action cannot be undone.</div>
    </Modal>
  );
}
