import React from "react";
import Utils from "../../helpers/Utils";
import { connect } from "react-redux";
import Show from "../widgets/Show";

const enhancer = connect(({ session: { permissions } }) => ({ permissions }));
const PatientFormCaption = (props) => {
  const { patient, formId, permissions } = props;
  const viewContactDetails = permissions.indexOf("VIEW_PATIENT_CONTACT_DETAILS") !== -1;
  return (
    <div>
      <div className="text-center">
        <strong>Form ID: {formId}</strong>
      </div>
      <div className="main-info">
        <div>
          <div>
            <strong>Chart:</strong> {patient?.chartNumber}
          </div>
          <div>
            <strong>Name:</strong> {Utils.getFullName(patient)}
          </div>
          <div>
            <strong>Age:</strong> {patient?.age}
          </div>
        </div>
        <div className="text-right">
          <div>
            <Show if={viewContactDetails}>
              <strong>Email:</strong> {patient?.email}
            </Show>
          </div>
          <div>
            <Show if={viewContactDetails}>
              <strong>Phone:</strong> {patient?.mobilePhoneNumber}
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};
export default enhancer(PatientFormCaption);
