import { useField } from "formik";
import React, { useMemo } from "react";

import { Checkbox, CheckboxProps } from "../ui/Checkbox";
import { FieldContainer, FieldContainerProps } from "./FieldContainer";

interface Props extends CheckboxProps {
  readonly name: string;
  readonly label?: string;
  readonly fieldProps?: Omit<FieldContainerProps, "label" | "children">;
}

export function CheckboxField({ name, label, fieldProps, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <Checkbox
        {...props}
        {...field}
        checked={field.value}
        onChange={(x) => {
          helpers.setValue(!field.value);

          if (props.onChange) {
            props.onChange(x);
          }
        }}
      />
    </FieldContainer>
  );
}
