import React from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import Tap from "../../widgets/Tap";
import seriesTypes from "../../../constants/chart-sidebar/seriesTypes";
import favouriteImage from "../../../assets/images/chart/fav-icon3.png";

export default SortableElement(ChartSideFavouritesGrid);

function ChartSideFavouritesGrid({
  item,
  favourites,
  favouritesIcons,
  seriesType,
  openItem,
  showImageSelector,
  isSelectItem,
  isMultiSelect,
}) {
  let src;
  const endSeriesType = seriesType === seriesTypes.mixed.code ? item.$seriesType : seriesType;

  const imageId = favourites[endSeriesType][item.code]?.imageId;

  if (imageId && favouritesIcons[imageId]) {
    src = <img alt="" src={favouritesIcons[imageId].publicUrl} />;
  } else if (item.icon && item.icon.url) {
    src = <img alt="" src={item.icon.url} />;
  } else {
    src = (
      <img
        alt=""
        style={{
          width: "40px",
        }}
        src={favouriteImage}
      />
    );
  }

  const DragHandle = SortableHandle(() => <div className="drag-handle">::</div>);

  return (
    <Tap
      tag="button"
      className="list-group-item list-group-item-grid"
      short={() => openItem(item)}
      long={() => showImageSelector(item.code, item.$seriesType)}
    >
      <div className="favourite-icon">{src}</div>
      <div className="code">{item.code}</div>

      <DragHandle />

      {isMultiSelect && (
        <div style={{ position: "absolute", top: 0, left: "4px" }}>
          <input type="checkbox" checked={isSelectItem} />
        </div>
      )}
    </Tap>
  );
}
