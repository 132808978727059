import React from "react";
import { Button, FormControl } from "react-bootstrap";
import { Modal } from "../ui/Modal";

export default class OtherIPDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hostValue: "",
      incorrectHost: "",
    };
  }

  render() {
    return (
      <Modal
        className="settings-password-dialog"
        show={this.props.show}
        onHide={this.props.onRequestClose}
        keyboard={false}
        title={<div>Enter a valid IP-address (host) with dental app</div>}
        actions={
          <div>
            <Button type="button" onClick={this.props.onRequestClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();

                if (this.state.hostValue !== "") {
                  let host = this.state.hostValue;

                  if (host.indexOf("http") !== 0) {
                    host = `http://${host}`;
                  }

                  if (host[host.length - 1] !== "/") {
                    host += "/";
                  }

                  this.props.onSubmit(host);
                  this.props.onRequestClose();
                } else {
                  this.setState({
                    ...this.state,
                    incorrectHost: "Enter IP-address(host)",
                  });
                }
              }}
            >
              OK
            </Button>
          </div>
        }
      >
        <form>
          <FormControl
            value={this.state.hostValue}
            onChange={(e) =>
              this.setState({
                hostValue: e.target.value,
                incorrectHost: "",
              })
            }
          />
        </form>
      </Modal>
    );
  }
}
