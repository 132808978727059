import React, { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import idCardInfoImage from "../../assets/images/chart/id-card-info.jpg";
import { Modal } from "../ui/Modal";

interface Props {
  readonly show: boolean;
  readonly onHide: () => void;
}
// @ts-ignore
// eslint-disable-next-line react/display-name
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
function PatientCardReaderInfoModal({ show, onHide }: Props) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={<h4>Follow the steps below</h4>}
      actions={
        <button onClick={onHide} type="button" className="btn btn-default">
          Close
        </button>
      }
      animation={false}
      keyboard={false}
    >
      <div className="p-3">
        <p className="d-flex">
          1: If you don't have the "Java" on your device, please click here to
          <Dropdown
            className="ml-2"
            id="dropdown-list"
            style={{
              border: "1px solid #ccc",
              padding: "0 3px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-list" className="item lists">
              download
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://app.dok32.com/jdkfordownload/jdk-8u271-windows-i586.exe"
                >
                  windows x32
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://app.dok32.com/jdkfordownload/jdk-8u202-windows-x64.exe"
                >
                  windows x64
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://app.dok32.com/jdkfordownload/jdk-8u271-macosx-x64.dmg"
                >
                  macos x64
                </a>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </p>
        <p>2: Install the jdk015.0.1.exe</p>
        <p>3: Click on Check IDCard reader</p>
        <p>4: Always make sure to run "Dok32-EMI.jnlp"</p>
        <p>5: Make sure "ToolkitAgent" is running in your device</p>
        <img src={idCardInfoImage} alt="id card info" />
        <br />
        <br />
        <p className="mt-3">You are done</p>
      </div>
    </Modal>
  );
}

export default PatientCardReaderInfoModal;
