import { noop } from "lodash";
import Collapse from "rc-collapse";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";

import { AppLayout } from "../../../app-layout/AppLayout";
import { ChartPrescriptionsArrayComponent } from "./ChartPrescriptionsArrayComponent";
import {
  deletePrescription,
  getMedicationsByCategory,
  openDiagnosisModal,
  openFavouriteAdd,
  popPrescriptionStack,
  syncForm,
} from "../../../../actions/chartSidePrescriptionActions";
import moment from "moment";
import { MEDICATION_CLEAR_NEW_LIST } from "../../../../actionTypes/medicationActionTypes";

interface Props {
  readonly prescriptions: any[];
  readonly formData: Record<string, any>;
  readonly medicationLists: Record<string, any>;
}

export function ChartPrescriptionEdit({ prescriptions = [], medicationLists, formData }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: MEDICATION_CLEAR_NEW_LIST });
  }, []);

  const initialValues = useMemo(
    () => ({
      prescriptions: prescriptions.map((item) => ({
        name: item.name,
        amount: item.amount || 1,
        refills: item.refills || 0,
        dispense: item.dispense || 1,
        instructions: item.instructions,
        route: item.rout && { value: item.rout.id },
        dosageForm: item.dosageForm ? item.dosageForm : "",
        drug: item.medication && { value: item.medication.id },
        strengthValue: item.strength || item.strengthValue || "",
        duration: item.duration || "",
        drugGeneric: item.medication && { value: item.medication.id },
        generic: item.genericSubstitution ? "generic" : "written",
        frequency: item.routFrequency && { value: item.routFrequency.id },
        drugCategory: item.medicationCategory && { value: item.medicationCategory.id },
        expirationDate: item.expirationDate ? moment(item.expirationDate) : moment().add(7, "day"),
        dispenseMedicationUnit: item.dispenseMedicationUnit
          ? { value: item.dispenseMedicationUnit.id }
          : null,
      })),
    }),
    [prescriptions],
  );
  return (
    <Formik onSubmit={noop} initialValues={initialValues} enableReinitialize={true}>
      {({ values, resetForm, setFieldValue }) => {
        return (
          <AppLayout
            titleClassName="name"
            title="Post Prescription"
            className="clinical-note-item"
            onBackClick={() => dispatch(popPrescriptionStack())}
            contentClassName="clinical-note-item-view-body chart-prescription-edit"
            headerClassName="clinical-note-item-view-title chart-prescription-edit-title"
            rightComponent={
              <>
                <button
                  className="btn btn-link pull-right"
                  onClick={() => dispatch(openFavouriteAdd(values.prescriptions))}
                >
                  Add
                </button>
                <button
                  className="btn btn-link pull-right"
                  onClick={() => {
                    dispatch(syncForm(values.prescriptions));
                    dispatch(openDiagnosisModal());
                  }}
                >
                  ✔ &nbsp; Next
                </button>
              </>
            }
          >
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2">
                <Collapse style={{ overflow: "visible" }} accordion={true}>
                  <FieldArray
                    name="prescriptions"
                    render={(helpers) => (
                      <ChartPrescriptionsArrayComponent
                        resetForm={resetForm}
                        formData={formData}
                        medicationLists={medicationLists}
                        onChangeDrugCategory={(id) =>
                          dispatch(getMedicationsByCategory({ categoryId: id }))
                        }
                        onDeleteClick={(index) => {
                          dispatch(deletePrescription(index, values.prescriptions));
                          helpers.remove(index);
                        }}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                </Collapse>
              </div>
            </div>
          </AppLayout>
        );
      }}
    </Formik>
  );
}
