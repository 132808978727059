import React, { useMemo } from "react";

import { useClinicListQuery } from "../../queries/clinic/useClinicListQuery";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";

export interface ClinicSelectWrapperProps extends Omit<SelectPickerProps, "options"> {
  readonly allLabel?: string;
}

export function ClinicSelectWrapper({ allLabel, ...props }: ClinicSelectWrapperProps) {
  const clinicQuery = useClinicListQuery();

  const list = useMemo(() => {
    const clinicList = clinicQuery.data || [];

    const newList: any = allLabel ? [{ label: allLabel }] : [];

    return newList.concat(clinicList.map((v) => ({ value: v.id, label: v.name })));
  }, [allLabel, clinicQuery.data]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isMulti={false}
      closeMenuOnSelect={true}
      isLoading={clinicQuery.isLoading}
    />
  );
}
