const planSortFields = [
  {
    code: "created_date",
    name: "Date (asc)",
  },
  {
    code: "created_date_desc",
    name: "Date (desc)",
  },
];

export default planSortFields;
