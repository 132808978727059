import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import { Routes } from "../../../constants/Routes";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import PatientDetailsSignature from "./../PatientDetailsSignature";
import PatientMedicationAlertsForm from "./PatientMedicationAlertsForm";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../../helpers/DateUtils";
import {
  getMedicationAlertsEdit,
  saveMedicationAlertsFn,
} from "../../../actions/patientRegisterActions";

const enhancer = connect(({ patientRegister, session }) => ({ patientRegister, session }));
class PatientMedicationAlertsEdit extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    const query = parseQuery(this.props.location.search);

    if (query.register) {
      if (_.get(this.props.session, "patientSettings.medicationAlertsSkipped")) {
        return this.props.history.push(
          createUrl(Routes.PatientView, { params: { patientKey: query.patientKey } }),
        );
      }
    }

    dispatch(getMedicationAlertsEdit(query.patientKey));
  }

  render() {
    const { dispatch } = this.props;
    const query = parseQuery(this.props.location.search);
    const {
      patientMedicationAlerts: { medicationAlerts },
    } = this.props.patientRegister;

    const redirectHandler = () => {
      if (query.temporary) {
        this.props.history.push(
          createUrl(Routes.PatientTerminal, { params: { patientKey: query.patientKey } }),
        );
      } else {
        this.props.history.push(
          createUrl(Routes.PatientView, { params: { patientKey: query.patientKey } }),
        );
      }
    };

    if (!medicationAlerts) {
      return null;
    }

    return (
      <>
        <PatientMedicationAlertsForm
          alerts={medicationAlerts.alerts}
          onBackClick={() => this.props.history.goBack()}
          history={this.props.history}
          location={this.props.location}
          enableReinitialize={true}
          onSubmit={(x) => {
            dispatch(startLoader());

            const alerts = (x.alerts || []).map((alert) => ({
              ...alert,
              endDate: getCompanyEndOfDay(alert.endDate),
              startDate: getCompanyStartOfDay(alert.startDate),
            }));

            dispatch(
              saveMedicationAlertsFn({
                alerts,
                patientKey: query.patientKey,
              }),
            )
              .then(() => {
                dispatch(stopLoader());

                redirectHandler();
              })
              .catch((x) => dispatch(stopLoader(x)));
          }}
          keepDirtyOnReinitialize={true}
          clinic={this.props.session.clinic}
          patientRegister={this.props.patientRegister}
        />

        <Show if={this.props.patientRegister.signatureModalVisible}>
          <PatientDetailsSignature patientRegister={this.props.patientRegister} />
        </Show>
      </>
    );
  }
}

export default enhancer(PatientMedicationAlertsEdit);
