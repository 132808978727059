import React, { useState } from "react";
import { Button, FormControl } from "react-bootstrap";

import Show from "../widgets/Show";
import { Modal } from "../ui/Modal";

interface Props {
  readonly show: boolean;
  readonly openIp: () => void;
  readonly onClose?: () => void;
}

export function SettingsPasswordDialog({ show, onClose, openIp }: Props) {
  const [passwordValue, setPasswordValue] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();

    if (passwordValue === "denti32-adminpass") {
      openIp();
    } else if (passwordValue === "") {
      setIncorrectPassword("Enter Password");
    } else {
      setIncorrectPassword("Password is incorrect");
    }
  };

  return (
    <Modal
      show={show}
      keyboard={false}
      title="Enter Password"
      className="settings-password-dialog"
      actions={
        <>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" onClick={submitHandler}>
            OK
          </Button>
        </>
      }
    >
      <form>
        <FormControl
          type="password"
          value={passwordValue}
          onChange={({ target }) => {
            setPasswordValue(target.value);
            setIncorrectPassword("");
          }}
        />
        <Show if={incorrectPassword}>
          <span className="settinds-password-dialog-error">{incorrectPassword}</span>
        </Show>
      </form>
    </Modal>
  );
}
