import React from "react";
import _, { toFinite } from "lodash";

import { Button } from "react-bootstrap";
import Utils from "../../../../helpers/Utils";
import { makeStyles } from "@material-ui/styles";
import MemberImage from "../../../widgets/MemberImage";
import { getCompanyFormatDate } from "../../../../helpers/DateUtils";

const useStyles = makeStyles(() => ({
  container: {
    paddingRight: "12px",
  },
}));

const SelectedTreatmentToCompletePatientInfo = ({ chart, session, chartActions }) => {
  const { patient, chartBillingBalanceValue, advancedPaymentList } = chart;
  const { permissions } = session;

  const classes = useStyles();

  const fullName = [patient.firstName, patient.middleName, patient.lastName]
    .filter(Boolean)
    .join(" ");
  const isTitleEmpty = Object.values(patient.title || {}).filter(Boolean).length === 0;
  const _title = isTitleEmpty ? fullName : `${patient.title.name} ${fullName}`;
  const canViewContacts = permissions.includes("VIEW_PATIENT_CONTACT_DETAILS");

  const mobilePhoneNumber =
    (_.get(patient, "mobilePhoneNumber") &&
      !patient.mobilePhoneNumber.includes("undefined") &&
      patient.mobilePhoneNumber.length >= 9 &&
      patient.mobilePhoneNumber) ||
    "";
  const homePhoneNumber =
    (_.get(patient, "homePhoneNumber") &&
      !patient.homePhoneNumber.includes("undefined") &&
      patient.homePhoneNumber.length >= 9 &&
      patient.homePhoneNumber) ||
    "";

  const workPhoneNumber =
    (_.get(patient, "workPhoneNumber") &&
      !patient.workPhoneNumber.includes("undefined") &&
      patient.workPhoneNumber.length >= 9 &&
      patient.workPhoneNumber) ||
    "";
  const _advancedPaymentList = (advancedPaymentList || []).filter(
    (x) => x.patientKey === _.get(patient, "patientKey"),
  );

  const totalAmountOfAdvancedPayment = _.reduce(
    _advancedPaymentList,
    (acc, value) => {
      return acc + toFinite(value ? value.amount : 0);
    },
    0,
  );

  return (
    <div className={classes.container}>
      <h3 className="text-center">{_title}</h3>
      <div className="d-flex">
        <MemberImage
          photoUrl={patient.photo && patient.photo.url}
          style={{ marginRight: "1rem", minWidth: "107px" }}
        />

        <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
          <div className="d-flex">
            <span className="mr-1">Chart No:</span>
            <b className="font-weight-bold">{patient.chartNumber}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">Age:</span>
            <b className="font-weight-bold">{Utils.getAge(patient.dateOfBirth)}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">DOB:</span>
            <b className="font-weight-bold">{getCompanyFormatDate(patient.dateOfBirth)}</b>
          </div>
          {canViewContacts && (
            <>
              <div className="d-flex">
                <span
                  className="mr-1"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Mobile:
                </span>
                <b className="font-weight-bold" title={mobilePhoneNumber}>
                  {mobilePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Home:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={homePhoneNumber}
                >
                  {homePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Work:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={workPhoneNumber}
                >
                  {workPhoneNumber}
                </b>
              </div>
            </>
          )}
          <div className="d-flex">
            <span className="mr-1" style={{ width: "70px" }}>
              Email:
            </span>
            <b
              className="font-weight-bold"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              title={patient.email}
            >
              {patient.email}
            </b>
          </div>
        </div>
      </div>

      <div
        className="pt-2 pb-4 mt-3 pl-2 d-flex flex-column align-items-start"
        style={{ borderTop: "3px solid #bec844", borderBottom: "3px solid #bec844" }}
      >
        <h5 className="text-center mt-1 mb-1">
          Outstanding Balance :{" "}
          <b className="text-black">
            {Utils.formatPrice(chartBillingBalanceValue?.outstandingBalance)}
          </b>
        </h5>
        <h5 className="text-center mt-1 mb-2">
          Advanced Payments :{" "}
          <b className="text-black">{Utils.formatPrice(totalAmountOfAdvancedPayment)}</b>
        </h5>

        <Button
          onClick={() => chartActions.setAdvancedPaymentModalVisible(true)}
          type="button"
          className="btn btn-default"
        >
          Request for adv.payment
        </Button>
      </div>
    </div>
  );
};

export default SelectedTreatmentToCompletePatientInfo;
