import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import { Modal } from "../ui/Modal";
import Show from "../widgets/Show";
import SignerComponent from "../SignerComponent";
import {
  AutoKeyAddANSIData,
  ClearTablet,
  GetSigImageB64,
  IsSigWebInstalled,
  NumberOfTabletPoints,
  SetDisplayXSize,
  SetDisplayYSize,
  SetEncryptionMode,
  SetImagePenWidth,
  SetImageXSize,
  SetImageYSize,
  SetJustifyMode,
  SetKeyString,
  SetSigCompressionMode,
  SetTabletState,
} from "../../SigWebTablet";

let tmr;
let interval;
const enhancer = connect(null, bindActions({ patientRegisterActions, simpleModalActions }));
class PatientDetailsSignature extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      signatureTimer: 0,
    };
  }
  mouseKeyDown = false;

  setContext = () => {
    this.context = this.canvas.getContext("2d");
    this.context.lineWidth = 1;
    this.context.strokeStyle = "#000";
    this.context.lineJoin = this.context.lineCap = "round";
  };

  componentDidMount() {
    if (this.canvas) {
      this.setContext();

      this.bb = this.canvas.getBoundingClientRect();

      const img = new Image();

      img.onload = () => {
        this.context.drawImage(img, 0, 0);
      };
      img.src = this.props.patientRegister.signature;
    }
  }

  componentDidUpdate() {
    if (this.canvas) {
      this.setContext();
    }
  }

  // componentWillUnmount() {
  //   this.setState({ signatureTimer: 0 });
  //   clearInterval(interval);
  //   SetTabletState(0, tmr);
  // }

  mouseDown = (e) => {
    if (!this.mouseKeyDown) {
      this.mouseKeyDown = true;
    }

    this.context.moveTo(
      (e.clientX || e.touches[0].clientX) - this.bb.left,
      (e.clientY || e.touches[0].clientY) - this.bb.top,
    );
  };

  mouseUp = () => {
    this.mouseKeyDown = false;
  };

  mouseMove = (e) => {
    if (this.mouseKeyDown) {
      // prevent IOS scroll when drawing
      if (e.touches) {
        e.preventDefault();
      }

      if (
        (e.clientX || e.touches[0].clientX) > 0 &&
        (e.clientY || e.touches[0].clientY) - this.bb.top < 400
      ) {
        this.context.lineTo(
          (e.clientX || e.touches[0].clientX) - this.bb.left,
          (e.clientY || e.touches[0].clientY) - this.bb.top,
        );

        this.context.stroke();
      }
    }
  };

  clearCanvas = () => {
    if (this.context && this.canvas) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.beginPath();
    }
  };

  done = () => {
    const isEmpty = (ctx) => {
      const data = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;
      return !Array.prototype.some.call(data, function (p) {
        return p > 0;
      });
    };
    const empty = isEmpty(this.context);
    if (typeof this.props.addSignature === "function") {
      if (empty) {
        return this.props.simpleModalActions.openSimpleModal({ body: "please sign" });
      }
      this.props.addSignature(this.canvas.toDataURL());
    } else {
      if (empty) {
        return this.props.simpleModalActions.openSimpleModal({ body: "please sign" });
      }
      this.props.patientRegisterActions.addSignature(this.canvas.toDataURL());
    }

    if (typeof this.props.closeSignature === "function") {
      this.props.closeSignature();
    } else {
      this.props.patientRegisterActions.closeSignature();
    }
  };

  handleSignerStart = () => {
    if (IsSigWebInstalled()) {
      this.setTimer();
      ClearTablet();
      const ctx = document.getElementById("cnv").getContext("2d");
      SetDisplayXSize(500);
      SetDisplayYSize(100);
      SetTabletState(0, tmr);
      SetJustifyMode(0);
      ClearTablet();
      SetKeyString("0000000000000000");
      SetEncryptionMode(0);
      if (tmr == null) {
        tmr = SetTabletState(1, ctx, 50);
      } else {
        SetTabletState(0, tmr);
        tmr = null;
        tmr = SetTabletState(1, ctx, 50);
      }
    } else {
      this.props.simpleModalActions.openSimpleModal({
        body:
          "Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC.",
      });
    }
  };

  handleSignerDone = () => {
    if (Number(NumberOfTabletPoints()) === 0) {
      this.props.simpleModalActions.openSimpleModal({ body: "Please sign before continuing" });
    } else {
      SetTabletState(0, tmr);
      let CryptoData = "";
      CryptoData = "This represents sample data the signer reads and is agreeing to when signing.";
      CryptoData = CryptoData + "Concatenate all this data into a single variable.";
      AutoKeyAddANSIData(CryptoData);
      SetEncryptionMode(2);
      SetSigCompressionMode(1);
      SetImageXSize(500);
      SetImageYSize(100);
      SetImagePenWidth(5);
      GetSigImageB64(this.handleSaveSignerImage);
      typeof this.props.closeSignature === "function"
        ? this.props.closeSignature()
        : this.props.patientRegisterActions.closeSignature();
    }
  };

  handleSaveSignerImage = (image) => {
    this.props.patientRegisterActions.addSignature(`data:image/png;base64,${image}`);
  };

  setActiveTab = (activeTab) => {
    this.clearCanvas();
    this.setState({ activeTab });
  };

  setTimer = () => {
    interval = setInterval(() => {
      this.setState({ signatureTimer: this.state.signatureTimer + 1 });
    }, 1000);
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
    if (activeTab === "1") {
      this.setState({ signatureTimer: 0 });
      clearInterval(interval);
      SetTabletState(0, tmr);
    }
  };

  render() {
    if (60 - this.state.signatureTimer === 0) {
      this.setState({ signatureTimer: 0 });
      clearInterval(interval);
      SetTabletState(0, tmr);
    }
    const { patientRegister } = this.props;
    return (
      <Modal
        size="xs"
        animation={false}
        bodyProps={{ className: "p-0" }}
        show={this.props.show || patientRegister.signatureModalVisible}
        onHide={
          typeof this.props.closeSignature === "function"
            ? this.props.closeSignature
            : this.props.patientRegisterActions.closeSignature
        }
        title="Sign below:"
        actions={
          <>
            <Show if={this.state.activeTab === "1"}>
              <Button
                onClick={
                  typeof this.props.closeSignature === "function"
                    ? this.props.closeSignature
                    : this.props.patientRegisterActions.closeSignature
                }
              >
                Close
              </Button>
              <Button onClick={this.clearCanvas}>Re-try</Button>
              <Button onClick={this.done}>Done</Button>
            </Show>
            <Show if={this.state.activeTab === "2"}>
              <Button
                onClick={() => {
                  ClearTablet();
                  SetTabletState(0, tmr);
                  typeof this.props.closeSignature === "function"
                    ? this.props.closeSignature()
                    : this.props.patientRegisterActions.closeSignature();
                }}
              >
                Close
              </Button>
              <Button disabled={this.state.signatureTimer !== 0} onClick={this.handleSignerStart}>
                {this.state.signatureTimer !== 0 ? (
                  <span>
                    00:
                    {this.state.signatureTimer === 0
                      ? "00"
                      : this.state.signatureTimer > 50
                      ? "0" + (60 - this.state.signatureTimer)
                      : 60 - this.state.signatureTimer}
                  </span>
                ) : (
                  "Re-try"
                )}
              </Button>
              <Button
                onClick={() => {
                  ClearTablet();
                  if (this.context && this.canvas) {
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.context.beginPath();
                  }
                }}
              >
                Clear
              </Button>
              <Button onClick={this.handleSignerDone}>Done</Button>
            </Show>
          </>
        }
      >
        <div className="btn-group w-100">
          <div
            style={{ width: `50%` }}
            className={`btn ${this.state.activeTab === "1" ? "btn-success" : "btn-default"}`}
            onClick={() => this.setActiveTab("1")}
          >
            Manual Signature
          </div>
          <div
            style={{ width: `50%` }}
            className={`btn ${this.state.activeTab === "2" ? "btn-success" : "btn-default"}`}
            // onClick={() => this.setActiveTab("2")}
          >
            Signature Pad
          </div>
        </div>
        <Show if={this.state.activeTab === "1"}>
          <canvas
            id="cnv"
            ref={(c) => (this.canvas = c)}
            width={600}
            height={400}
            onMouseDown={this.mouseDown}
            onTouchStart={this.mouseDown}
            onMouseUp={this.mouseUp}
            onTouchEnd={this.mouseUp}
            onMouseMove={this.mouseMove}
            onTouchMove={this.mouseMove}
          />
        </Show>
        <Show if={this.state.activeTab === "2"}>
          <SignerComponent
            ref={(c) => (this.canvas = c)}
            handleSignerStart={this.handleSignerStart}
          />
        </Show>
      </Modal>
    );
  }
}
export default enhancer(PatientDetailsSignature);
