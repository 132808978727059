import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../ui/Modal";
import { Field, reduxForm } from "redux-form";

import bindActions from "../../../helpers/bindActions";
import * as appointmentActions from "../../../actions/appointmentActions";
import * as SelectTypes from "../../../constants/RepeatAppointmentDataTypes";
import RepeatAppointmentRepeatModalSelect from "./RepeatAppointmentRepeatModalSelect";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";

const enhancer = connect(null, bindActions({ appointmentActions }));
const form = reduxForm({
  form: "repeatAppointment",
});

class RepeatAppointmentRepeatModal extends React.PureComponent {
  render() {
    const {
      show,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentList,
      index,
    } = this.props;

    const OccurrencesOptions = SelectTypes.OccurrencesOptions.map((item) => ({
      label: item.label,
      value: item.value,
    }));

    const untilMode = repeatAppointmentRepeatModalMode === "until";

    return (
      <Modal
        show={show}
        className="repeat-appointment-repeat-modal-dialog"
        onHide={this.props.appointmentActions.repeatAppointmentRepeatModalHide}
        size="lg"
        keyboard={false}
      >
        <div className="repeat-appointment-repeat-modal-main">
          <h4 className="text-center">RECURRENCE END PERIOD</h4>
          <button
            className="btn btn-link repeat-appointment-repeat-modal-close"
            onClick={this.props.appointmentActions.repeatAppointmentRepeatModalHide}
          >
            X
          </button>
          <div className="repeat-appointment-repeat-modal-body">
            <div className="row">
              <div className="col-sm-3">
                <RepeatAppointmentRepeatModalSelect
                  mode="until"
                  label="UNTIL"
                  selected={untilMode}
                  onClick={this.props.appointmentActions.repeatAppointmentSetRepeatModalMode}
                />
              </div>
              <div className="col-sm-5 repeat-appointment-datepicker">
                <DatePicker
                  className="form-control"
                  disabled={!untilMode}
                  onChange={this.props.appointmentActions.repeatAppointmentRepeatModalSelectDate}
                  selected={repeatAppointmentRepeatModalDate}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <RepeatAppointmentRepeatModalSelect
                  mode="after"
                  label="AFTER"
                  selected={!untilMode}
                  onClick={this.props.appointmentActions.repeatAppointmentSetRepeatModalMode}
                />
              </div>
              <div className="col-sm-5">
                <Field
                  component={({ input }) => (
                    <SelectPicker
                      options={OccurrencesOptions}
                      disabled={untilMode}
                      value={{ value: input.value }}
                      onChange={(x) => input.onChange(x.value)}
                    />
                  )}
                  name="occurrenceItem"
                  id="occurrenceItem"
                />
              </div>
              <div className="col-sm-4 repeat-appointment-occurrences">OCCURRENCES</div>
            </div>
          </div>
          <div className="modal-buttons">
            <button
              className="btn btn-link repeat-appointment-repeat-modal-cancel"
              onClick={this.props.appointmentActions.repeatAppointmentRepeatModalHide}
            >
              CANCEL
            </button>
            <button
              className="btn btn-link repeat-appointment-repeat-modal-save"
              onClick={() => {
                this.props.onClickUpdate(index, repeatAppointmentList[index].id);
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default enhancer(form(RepeatAppointmentRepeatModal));
