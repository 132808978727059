import React, { useRef, useEffect } from "react";
import Show from "../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { useSelector, useDispatch } from "react-redux";

import { getTagsList, togglePatientTag, savePatientTags } from "../../../actions/patientActions";
import checkedImage from "../../../assets/images/appointment/checked.png";
import uncheckedImage from "../../../assets/images/appointment/unchecked.png";
import { popSeriesStack } from "../../../actions/chartSideSeriesActions";

const PatientTags = () => {
  const patient = useSelector((state) => state.patient);
  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  useEffect(() => {
    dispatch(getTagsList());
  }, []);

  return (
    <div className="chart-clinical-note">
      <div className="sidebar-header chart-side-header">
        <div className="clearfix">
          <div className="col-sm-5 col-sm-offset-3 text-center">
            <div className="chart-clinical-note-title" style={{ marginLeft: "18px" }}>
              Patient tags
            </div>
          </div>
          <div className="col-sm-4 text-right">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                dispatch(savePatientTags()).then(() => dispatch(popSeriesStack()));
              }}
            >
              DONE
            </button>
          </div>
        </div>
      </div>
      <div className="chart-side-body">
        <div className="body p-1">
          {(patient.tagsList || []).map((item, index) => {
            const checkboxSrc = item.selected ? checkedImage : uncheckedImage;

            return (
              <div key={index} className="chart-general-goal-item clearfix mb-1">
                <div className="goal-checkbox" onClick={() => dispatch(togglePatientTag(index))}>
                  <img alt="" src={checkboxSrc} className="zoom-2x" />
                </div>
                <div className="text" style={{ marginLeft: "2px" }}>
                  <input
                    type="text"
                    name="name"
                    value={item.name}
                    readOnly={true}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                </div>
              </div>
            );
          })}
          <Show if={patient.fetchingTagsList}>
            <ReactSpinner />
          </Show>
        </div>
      </div>
    </div>
  );
};

export default PatientTags;
