import React, { useEffect, useState } from "react";
import {
  setSelectDiagnosisModalVisible,
  setSelectTreatmentModalVisible,
} from "../../../../actions/chartSideFillInFormActions";
import {
  getTreatmentDiagnosisListForNote,
  setTreatmentDiagnosisSelecting,
} from "../../../../actions/chartSideClinicalNoteActions";
import { Modal } from "../../../ui/Modal";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { connect, useDispatch } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import { Table } from "react-bootstrap";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import moment from "moment";
import { Button, ButtonType } from "../../../ui/Button";

const enhancer = connect(
  ({
    chartSideClinicalNote: {
      treatmentDiagnosisList,
      treatmentDiagnosisListFetching,
      treatmentDiagnosisSelecting,
    },
  }) => ({
    treatmentDiagnosisList,
    treatmentDiagnosisListFetching,
    treatmentDiagnosisSelecting,
  }),
  null,
);
const ChartFillInFormSelectTreatmentDiagnosisModal = ({
  onChange,
  treatmentDiagnosisList,
  treatmentDiagnosisListFetching,
  textAreaValue,
  type,
}) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    dispatch(getTreatmentDiagnosisListForNote({ load: false, start: 0, type }));
  }, []);

  const onSave = () => {
    if (selectedItems.length) {
      const value = selectedItems.map(
        (item) =>
          `${item[`${type}Item`]?.description}${
            item.tooth?.name ? ` tooth ${item.tooth?.name}` : ""
          }${
            item.areaDetails && item.areaDetails.length
              ? ` area ${(item.areaDetails || []).map((i) => i.name).join(", ")}`
              : ""
          }`,
      );
      onChange(`${textAreaValue}\n${value.join("\n")}`);
    }
    dispatch(
      type === "treatment" ? setSelectTreatmentModalVisible() : setSelectDiagnosisModalVisible(),
    );
  };

  const onToggle = (key, checked) => {
    let _selectedItems = selectedItems;
    if (checked) {
      const item = treatmentDiagnosisList.find((item) => item.key === key);
      _selectedItems.push(item);
    } else {
      _selectedItems = _selectedItems.filter((item) => item.key !== key);
    }
    setSelectedItems(_selectedItems);
    dispatch(setTreatmentDiagnosisSelecting());
  };

  return (
    <Modal
      title={
        <div className="d-flex align-items-center justify-content-center">
          <div>Select {type}</div>
        </div>
      }
      actions={
        <>
          <Button
            buttonType={ButtonType.Secondary}
            onClick={() =>
              dispatch(
                type === "treatment"
                  ? setSelectTreatmentModalVisible()
                  : setSelectDiagnosisModalVisible(),
              )
            }
          >
            Close
          </Button>
          <Button buttonType={ButtonType.Primary} onClick={onSave}>
            Add selected
          </Button>
        </>
      }
      show={true}
      size="lg"
    >
      {treatmentDiagnosisListFetching && <ReactSpinner />}
      <div style={{ maxHeight: "70vh", overflowX: "hidden", overflowY: "auto" }}>
        <Table striped={true} bordered={true} hover={true}>
          <thead>
            <tr>
              <th> </th>
              <th>Completed date</th>
              <th>Completed doctor</th>
              <th>Code</th>
              <th>Description</th>
              <th>Tooth</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {(treatmentDiagnosisList || []).map((item) => (
              <tr key={item.id}>
                <td>
                  <InputLabel key={item.key}>
                    <Checkbox
                      value={item.key}
                      checked={!!selectedItems.find((i) => i.key === item.key)?.key}
                      onChange={({ target: { value, checked } }) => onToggle(value, checked)}
                      inputProps={{ "aria-label": "" }}
                      sx={{
                        color: "#bec844",
                        "&.Mui-checked": {
                          color: "#bec844",
                        },
                      }}
                    />
                  </InputLabel>
                </td>
                <td>
                  {item.completedDate ? moment(item.completedDate).format(DATE_FORMAT_LEGACY) : "-"}
                </td>
                <td>{item.createdDentist?.name}</td>
                <td>{item[`${type}Item`]?.code}</td>
                <td>{item[`${type}Item`]?.description}</td>
                <td>{item.tooth?.name || "-"}</td>
                <td>{(item.areaDetails || []).map((i) => i.name).join(", ") || "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default enhancer(ChartFillInFormSelectTreatmentDiagnosisModal);
