import _ from "lodash";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { DOCTORS_MODE, CHART_COMPLETE } from "../../../widgets/DoctorsSelect";
import { DoctorsSelectWrapper } from "../../../../wrappers/DoctorsSelectWrapper";

import Utils from "../../../../helpers/Utils";
import { selectTreatmentToCompleteGetList } from "../../../../actions/chartActions";
import { SelectPicker } from "../../../select-picker/SelectPicker";

const enhancer = compose(
  connect(null, { selectTreatmentToCompleteGetList }),
  reduxForm({
    form: "selectTreatmentToCompleteDentistFormFilter",
  }),
);

export default enhancer(function SelectTreatmentToCompleteDentistFormFilter({ members }) {
  const $memberOptions = _.map(members, (item) => ({
    label: Utils.getFullName(item),
    value: item.id,
  }));

  return (
    <div className="form-horizontal flex-shrink-0">
      <div className="form-group m-0">
        <div className="d-flex align-items-center flex-column justify-content-start">
          <div className="ml-1 w-100">
            <label
              className="control-label pl-2 mt-2"
              style={{ marginRight: "5px", fontSize: "14px", marginBottom: "5px" }}
            >
              Filter Service Planned by:
            </label>
          </div>
          <div className="form-horizontal-doctors-select">
            <DoctorsSelectWrapper
              mode={DOCTORS_MODE}
              completeMode={CHART_COMPLETE}
              hideBySelect={true}
              allDoctorsTitle="All"
            />
          </div>
          <div>
            <label
              htmlFor="closedDentistId"
              className="control-label mt-2 pl-2"
              style={{ textAlign: "left", padding: 0, fontSize: "14px", marginBottom: "5px" }}
            >
              Change value, to complete a Service/Treatment by another{" "}
              {localStorage.getItem("doctorLabel")}:
            </label>
          </div>
          <div className="w-100 pl-2">
            <Field
              id="closedDentistId"
              name="closedDentistId"
              component={({ input }) => (
                <SelectPicker
                  options={$memberOptions}
                  className="form-horizontal-doctors-select"
                  value={{ value: input.value }}
                  onChange={(x) => input.onChange(x.value)}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
