import { httpClient } from "./BaseApi";

const GET_CLINICS_URL = "api/clinic/choose.json";
const GET_CLINIC_DEVICE_URL = "api/clinic/device/item.json";
const GET_CLINIC_CURRENCIES_URL = "api/clinic/currencies.json";

const ClinicApi = {
  getClinics: () => httpClient.get(GET_CLINICS_URL),
  deleteDevice: () => httpClient.delete(GET_CLINIC_DEVICE_URL),
  getCurrencies: () => httpClient.get(GET_CLINIC_CURRENCIES_URL),
};

export default ClinicApi;
