import React from "react";
// import CKEditor from "react-ckeditor-component";

// import CKEditorConfig from "../../../constants/CKEditorConfig";

export default function ReduxCKEditor(props) {
  const {
    input: { value, onChange },
  } = props;

  const extractBodyText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const extractImageSrc = (htmlContent) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const img = div.querySelector("img");
    return img ? img.src : null;
  };
  const imageSrc = extractImageSrc(value);

  return (
    <>
      {imageSrc && (
        <div id="imgDiv">
          <img src={imageSrc} alt="img" />
        </div>
      )}
      <textarea
        name="note"
        id="clinical-note-textarea-body"
        className="form-control post-ops-textarea"
        rows="5"
        cols="33"
        value={extractBodyText(value)}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
}
