import React from "react";
import { Field, reduxForm } from "redux-form";

import Back from "../widgets/Back";
import { DatePicker } from "../ui/DatePicker";
import { DoctorSelectWrapper } from "../doctor/DoctorSelectWrapper";
import moment from "moment";

const form = reduxForm({ form: "BlockedTimeSlotFilterForm" });
class DoctorProductionReportForm extends React.Component {
  render() {
    const {
      onBackClick,
      dashboard: { dataTypes, viewTypes },
    } = this.props;

    const $patientOptions = dataTypes.map((item) => (
      <option value={item.code} key={item.code}>
        {item.name}
      </option>
    ));

    const $viewTypeOptions = viewTypes.map((item) => (
      <option value={item.code} key={item.code}>
        {item.name}
      </option>
    ));

    return (
      <form
        className="form-inline doctor-production-form d-flex"
        onSubmit={this.props.handleSubmit}
      >
        <Back className="btn-back-doctor-production" onClick={onBackClick} />
        <div className="d-flex flex-column mr-2">
          <label htmlFor="startDate">From</label>
          <Field
            id="startDate"
            name="startDate"
            className="form-control"
            dateFormat="dd/MM/yyyy"
            component={(x) => (
              <DatePicker selected={x.input.value} onChange={(v) => x.input.onChange(moment(v))} />
            )}
          />
        </div>
        <div className="d-flex flex-column mr-2">
          <label htmlFor="endDate">To</label>
          <Field
            id="endDate"
            name="endDate"
            className="form-control"
            dateFormat="dd/MM/yyyy"
            component={(x) => (
              <DatePicker selected={x.input.value} onChange={(v) => x.input.onChange(moment(v))} />
            )}
          />
        </div>
        <div className="d-flex flex-column mr-2" style={{ width: "200px" }}>
          <label htmlFor="doctorId">{localStorage.getItem("doctorLabel")}</label>
          <Field
            id="doctorId"
            name="doctorId"
            component={(x) => (
              <DoctorSelectWrapper
                value={{ value: x.input.value }}
                onChange={(v) => x.input.onChange(v.value)}
              />
            )}
          />
        </div>
        <div className="d-flex flex-column mr-2">
          <label htmlFor="dataTypeCode">Patient</label>
          <Field id="dataTypeCode" name="dataTypeCode" className="form-control" component="select">
            {$patientOptions}
          </Field>
        </div>
        <div className="d-flex flex-column mr-2">
          <label htmlFor="viewTypeCode">View Type</label>
          <Field id="viewTypeCode" name="viewTypeCode" className="form-control" component="select">
            {$viewTypeOptions}
          </Field>
        </div>

        <div className="d-flex align-items-end mr-2">
          <label>
            <Field name="shortList" component="input" type="checkbox" />
            Short List
          </label>
        </div>
        <div className="d-flex flex-column mr-2">
          <label htmlFor="password">Password</label>
          <Field
            id="password"
            name="password"
            className="form-control"
            component="input"
            type="password"
          />
        </div>

        <div className="d-flex align-items-end" style={{ paddingBottom: "2px" }}>
          <button className="btn btn-default" type="submit">
            Update
          </button>
        </div>
      </form>
    );
  }
}
export default form(DoctorProductionReportForm);
