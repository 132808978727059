import { STATUS_TYPES } from "../constants/StatusTypes";

const AppointmentUtils = {
  isEdit(appointment) {
    return appointment && appointment.id && appointment.id != "0";
  },
  isMissed(statusCode) {
    return (
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW
    );
  },
  isComplete(statusCode) {
    return statusCode === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE;
  },
  isCanceled(statusCode) {
    return (
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
      statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED
    );
  },
  isMissedOrComplete(statusCode) {
    return this.isMissed(statusCode) || this.isComplete(statusCode);
  },
  isMissedOrConfirmedOrComplete(statusCode) {
    let isTrue = this.isMissedOrComplete(statusCode);
    switch (statusCode) {
      case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
        isTrue = true;
        break;
    }
    return isTrue;
  },
  isMissedOrConfirmedOrCompleteOrCancelled(statusCode) {
    let isTrue = this.isMissedOrConfirmedOrComplete(statusCode);
    switch (statusCode) {
      case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
      case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
      case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
      case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
      case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
        isTrue = true;
        break;
    }
    return isTrue;
  },
  isMissedOrConfirmedOrCompleteOrCancelledOrCheckedInOrBeingSeen(statusCode) {
    let isTrue = this.isMissedOrConfirmedOrCompleteOrCancelled(statusCode);
    switch (statusCode) {
      case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
      case STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN:
        isTrue = true;
        break;
    }
    return isTrue;
  },
  isMissedOrConfirmedOrCompleteOrCancelledOrBeingSeen(statusCode) {
    let isTrue = this.isMissedOrConfirmedOrCompleteOrCancelled(statusCode);
    switch (statusCode) {
      case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
      case STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN:
        isTrue = true;
        break;
    }
    return isTrue;
  },
};

export default AppointmentUtils;
