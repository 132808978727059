import { httpClient } from "./BaseApi";

const LAB_ORDER_DOCUMENT_LIST_URL = "api/lab-order/document/list.json";
const LAB_ORDER_DOCUMENT_ITEM_URL = "api/lab-order/document/item.json";
const LAB_ORDER_DOCUMENT_FORM_DATA_URL = "api/lab-order/document/form-data.json";
const LAB_ORDER_DOCUMENT_UPLOAD_FILE_URL = "api/lab-order/document/upload-file.json";

const LabOrderApi = {
  save: (data) => httpClient.post(LAB_ORDER_DOCUMENT_ITEM_URL, { data }),
  upload: (data) => httpClient.post(LAB_ORDER_DOCUMENT_UPLOAD_FILE_URL, { data }),
  getFormData: (params) => httpClient.get(LAB_ORDER_DOCUMENT_FORM_DATA_URL, { params }),
  getDocumentList: (data) =>
    httpClient.get(LAB_ORDER_DOCUMENT_LIST_URL, {
      params: { ...data, statusCodes: "SEND,NEW,RECEIVED,IN_PROGRESS,RESEND" },
    }),
};

export default LabOrderApi;
