import { Options, toJpeg } from "html-to-image";
import { IS_ELECTRON } from "../constants/AppConstants";

interface OptionsProps extends Options {
  readonly fileName?: string;
}

export function downloadElementAsJpeg(id: string, options?: OptionsProps): Promise<string> {
  const element = document.querySelector(`.${id}`) as HTMLElement;

  if (!element) {
    return Promise.reject("Element not found");
  }
  return toJpeg(element, {
    width: element.scrollWidth,
    height: element.scrollHeight,
    ...options,
  }).then(function (dataUrl) {
    if (id === "perio-exam") return dataUrl;

    const link = document.createElement("a");

    link.download = `${options?.fileName || "template"}.jpeg`;
    link.href = dataUrl;
    link.click();

    return dataUrl;
  });
}

export function obtainImagePath(image: string) {
  if (IS_ELECTRON && image) {
    return image.replace(/^\/static/, "static");
  }

  return image;
}

export function dataUrlToFile(dataUrl: string, fileName: string): File {
  const byteString = atob(dataUrl.split(",")[1]);
  const mimeType = dataUrl.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], fileName, {
    type: mimeType,
    lastModified: Date.now(),
  });
}
