import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/styles";

import MemberImage from "../widgets/MemberImage";

const useStyles = makeStyles({
  root: {
    userSelect: "none",
    display: "flex",
    // flexDirection: "column",
    alignItems: "flexStart",
    textAlign: "center",
    color: "white",
    height: "40px",
    padding: "4px 10px 0",
    cursor: "pointer",

    "&:hover": {
      background: "gray",
      textDecoration: "none",
    },
  },
  icon: {
    height: "20px",
    verticalAlign: "top",
  },
  text: {
    fontSize: "10px",
    lineHeight: "15px",
  },
  container: { flex: 1 },
});

interface Props {
  readonly member?: any;
  readonly children: string;
  readonly onClick: (event: any) => void;
}

const DropdownTopMenuToggle = forwardRef<HTMLDivElement, Props>(
  ({ onClick, member, children, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        role="button"
        className={classes.root}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <div className="d-flex align-items-center">
          <div className={classes.text}>{member?.firstName}</div>
          <div className={classes.text}>{member?.lastName}</div>
        </div>
        <MemberImage photoUrl={member?.photoUrl} style={{ marginLeft: "0.8rem" }} size={30} />
      </div>
    );
  },
);

DropdownTopMenuToggle.displayName = "DropdownTopMenuToggle";

export { DropdownTopMenuToggle };

// float: left;
// color: white;
// font-size: 10px;
// line-height: 12px;
// text-align: right;
// height: 40px;
// cursor: pointer;
// padding: 5px 45px 0 10px;
// &:hover {
//   background: gray;
// }
//
// > .name {
//   padding-top: 3px;
// }
//
// > .member-image {
//   float: right;
//   margin-right: -35px;
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   overflow: hidden;
// }
