import $ from "jquery";
import React from "react";

import { SELECTED, TEETH_TYPES } from "../../../constants/Constants";
import ToothForSextantImage from "../../../assets/images/teeth/tooth_for_sextant.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

export default class SelectSextantSides extends React.Component {
  selectItem = (e) => {
    const { typeTab, isEdit, addTransaction, seriesActions } = this.props;

    if (isEdit) {
      return;
    }

    let $elem = $(e.target);
    const selectedId = $elem.attr("id");

    addTransaction.tooth = null;

    const key = selectedId.replace(/Btn/g, "");

    let type = TEETH_TYPES.SextantType[key];

    if (type) {
      seriesActions.changeTransactionByKey(type, "areaDetails", null, typeTab);
    }
  };

  toggleActiveClassName = (name) => {
    const { addTransaction } = this.props;

    let findItem;

    if (addTransaction && addTransaction.areaDetails) {
      findItem = addTransaction.areaDetails.filter((item) => name == item.code);
    }

    return findItem && findItem.length > 0 ? SELECTED : "";
  };

  render() {
    const { className } = this.props;

    return (
      <div className={`mouth-shape-wrap ${className}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="220">
          <g id="layer2" className="layer-selectors">
            <path
              d="M 20.561025,104.7659 4.1295743,104.61176 3.0780479,103.27494 C -0.0860673,99.252465 1.8642537,81.713758 7.0144097,67.875656 12.040652,54.370525 21.304558,39.816303 32.12497,28.425214 c 3.704815,-3.900215 5.846117,-5.459244 7.498192,-5.459244 0.461462,0 2.048314,1.126128 3.526372,2.502505 2.248251,2.093602 20.860151,22.536738 26.638989,29.260051 2.649389,3.082371 2.371899,4.380127 -1.786684,8.356445 -4.868716,4.655298 -9.293357,10.766031 -12.315435,17.008507 -3.332497,6.883696 -4.313426,10.748661 -4.688802,18.474495 l -0.307151,6.322127 -6.848961,0.015 c -3.766929,0.008 -14.243154,-0.0544 -23.280465,-0.13916 z"
              id="Sextant1BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant1")}`}
            />
            <path
              d="M 59.48675,37.773558 C 45.625502,22.16196 44.339109,20.563622 44.339109,18.952973 c 0,-1.145888 4.229393,-5.591966 8.136514,-8.553342 4.047161,-3.0675237 12.588869,-7.1874166 17.98796,-8.6760491 3.589698,-0.98975403 5.523372,-1.14948237 13.915615,-1.14948237 11.442245,0 14.71273,0.67831597 23.398792,4.85309027 5.90338,2.8373036 10.44467,6.0536342 14.16835,10.0345412 2.59064,2.76962 2.87045,3.595936 1.85818,5.487478 -0.63037,1.177837 -28.268731,32.499931 -28.677667,32.499931 -0.124704,0 -1.586378,-0.770506 -3.248093,-1.712238 -2.753652,-1.560495 -3.418265,-1.712241 -7.499562,-1.712241 -4.081296,0 -4.745909,0.151746 -7.499561,1.712241 -1.661716,0.941732 -3.12339,1.712238 -3.248093,1.712238 -0.124704,0 -6.489867,-7.054013 -14.144794,-15.675582 z"
              id="Sextant2BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant2")}`}
            />
            <path
              d="m 128.07432,190.19008 c -1.15421,-0.83608 -2.55298,-2.11146 -3.10844,-2.83421 -0.55544,-0.72272 -6.35399,-7.21031 -12.88568,-14.41686 -12.757294,-14.07544 -13.676114,-15.19573 -13.676114,-16.67476 0,-0.53167 1.732154,-2.60174 3.849224,-4.60015 4.6947,-4.43158 9.11829,-10.47438 12.20853,-16.67731 3.33409,-6.69229 4.53645,-11.39081 4.70914,-18.40202 0.0793,-3.21506 0.28687,-6.07675 0.46151,-6.35933 0.20174,-0.32637 8.73564,-0.43742 23.39427,-0.30443 l 23.07668,0.20936 1.14536,1.25948 c 1.62326,1.78507 2.03483,6.14273 1.27685,13.5198 -2.09066,20.34841 -11.30752,39.81012 -27.71511,58.52127 -4.31932,4.92575 -8.26407,8.27929 -9.7389,8.27929 -0.4944,0 -1.84316,-0.68406 -2.99732,-1.52013 z"
              id="Sextant4BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant4")}`}
            />
            <path
              d="m 73.862196,213.02417 c -9.92383,-1.87743 -20.497943,-7.54134 -26.943083,-14.4317 -2.590647,-2.76962 -2.870452,-3.59595 -1.858123,-5.48748 0.630315,-1.17784 28.268673,-32.49994 28.677607,-32.49994 0.124704,0 1.586378,0.77051 3.248095,1.71224 2.753652,1.5605 3.418265,1.71225 7.499562,1.71225 4.081295,0 4.745908,-0.15175 7.49956,-1.71225 1.661716,-0.94173 3.12339,-1.71224 3.248094,-1.71224 0.408935,0 28.047292,31.3221 28.677612,32.49994 1.01233,1.89153 0.73252,2.71786 -1.85812,5.48748 -5.48556,5.86449 -15.07184,11.41609 -23.651531,13.697 -5.507356,1.46412 -18.610369,1.85643 -24.539673,0.7347 z"
              id="Sextant5BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant5")}`}
            />
            <path
              d="m 35.722171,189.47195 c -1.314894,-1.004 -4.217063,-3.90822 -6.449245,-6.45382 C 12.865342,164.30698 3.6484821,144.84527 1.55781,124.49686 0.79984069,117.11979 1.2114106,112.76212 2.8346674,110.97706 l 1.1453571,-1.25948 23.0766815,-0.20936 c 14.658623,-0.13299 23.192536,-0.022 23.394265,0.30443 0.174648,0.28257 0.382329,3.14427 0.461514,6.35933 0.1727,7.01121 1.375061,11.70973 4.709138,18.40202 3.090253,6.20293 7.513839,12.24572 12.208539,16.67729 2.117066,1.99843 3.849222,4.0685 3.849222,4.60017 0,1.47722 -0.912229,2.59072 -13.676113,16.6929 -6.5317,7.2165 -12.530442,13.89633 -13.330612,14.84405 -1.613299,1.91082 -4.565676,3.90901 -5.775677,3.90901 -0.431221,0 -1.859862,-0.82146 -3.174811,-1.82547 z"
              id="Sextant6BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant6")}`}
            />
            <path
              d="m 119.21867,104.90857 c -0.17465,-0.28258 -0.38233,-3.14428 -0.46152,-6.359341 -0.1727,-7.01121 -1.37506,-11.709729 -4.70913,-18.402023 -3.09025,-6.202926 -7.51384,-12.245713 -12.20854,-16.677298 -2.117065,-1.99841 -3.849224,-4.068483 -3.849224,-4.600162 0,-1.471505 0.891683,-2.563604 13.670114,-16.74302 14.48819,-16.076589 16.41723,-18.038164 18.1724,-18.478684 1.96754,-0.493826 4.83436,1.64931 10.07571,7.532231 16.61586,18.649867 26.09123,38.486677 28.20335,59.04408 0.75797,7.37707 0.34639,11.734747 -1.27686,13.519797 l -1.14535,1.25948 -23.07669,0.20937 c -14.65862,0.13299 -23.19252,0.022 -23.39426,-0.30443 z"
              id="Sextant3BtnSelector"
              className={`rect-selector ${this.toggleActiveClassName("Sextant3")}`}
            />
          </g>
          <g id="layer5" className="layer-text">
            <text x="81.540306" y="10.713456" id="text4579">
              <tspan x="81.540306" y="10.713456" id="tspan4581">
                2
              </tspan>
            </text>
            <text x="10.758756" y="93.181816" id="text4583">
              <tspan x="10.758756" y="93.181816" id="tspan4585">
                1
              </tspan>
            </text>
            <text x="11.290953" y="125.64555" id="text4587">
              <tspan x="11.290953" y="125.64555" id="tspan4589">
                6
              </tspan>
            </text>
            <text x="81.421509" y="209.02077" id="text4587-4">
              <tspan x="81.421509" y="209.02077" id="tspan4589-8">
                5
              </tspan>
            </text>
            <text x="156.93082" y="122.70678" id="text4587-4-0">
              <tspan x="156.93082" y="122.70678" id="tspan4589-8-7">
                4
              </tspan>
            </text>
            <text x="157.23407" y="94.069893" id="text4587-4-0-8">
              <tspan x="157.23407" y="94.069893" id="tspan4589-8-7-2">
                3
              </tspan>
            </text>
          </g>
          <g id="layer4" className="layer-image">
            <image
              xlinkHref={obtainImagePath(ToothForSextantImage)}
              x="23.811792"
              y="12.060904"
              width="121.17395"
              height="183.34145"
              id="image3151"
              className="teeth-image"
            />
          </g>
          <g id="layer3" className="layer-buttons">
            <path
              onClick={this.selectItem}
              d="M 20.60489,104.77786 4.1734392,104.62372 3.1219128,103.2869 C -0.04220233,99.264426 1.9081187,81.725718 7.0582747,67.887615 12.084517,54.382485 21.348423,39.828262 32.168835,28.437174 c 3.704814,-3.900215 5.846116,-5.459245 7.498191,-5.459245 0.461462,0 2.048314,1.126128 3.526373,2.502506 2.248251,2.093601 20.860155,22.536738 26.638994,29.26005 2.64939,3.08237 2.3719,4.380128 -1.786684,8.356445 -4.868716,4.655298 -9.293357,10.766032 -12.315434,17.008508 -3.332502,6.883696 -4.313432,10.748659 -4.688809,18.474495 l -0.307149,6.322127 -6.848961,0.015 c -3.76693,0.008 -14.243156,-0.0544 -23.280466,-0.13916 z"
              id="Sextant1Btn"
              className="rect"
            />
            <path
              onClick={this.selectItem}
              d="M 59.530621,37.785518 C 45.669367,22.173919 44.382974,20.575582 44.382974,18.964934 c 0,-1.145891 4.229392,-5.591968 8.136515,-8.553343 4.047165,-3.0675244 12.588875,-7.1874173 17.987964,-8.6760497 3.589699,-0.98975408 5.523371,-1.14948238 13.915615,-1.14948238 11.442245,0 14.712729,0.67831588 23.398802,4.85309018 5.90338,2.8373037 10.44466,6.0536339 14.16833,10.0345409 2.59065,2.769621 2.87046,3.595937 1.85819,5.487479 -0.63038,1.177837 -28.268731,32.499929 -28.677668,32.499929 -0.124703,0 -1.586377,-0.770505 -3.248093,-1.712237 -2.753651,-1.560494 -3.418264,-1.71224 -7.499561,-1.71224 -4.081296,0 -4.745908,0.151746 -7.499561,1.71224 -1.661715,0.941732 -3.123389,1.712237 -3.248093,1.712237 -0.124703,0 -6.489866,-7.054012 -14.144793,-15.67558 z"
              id="Sextant2Btn"
              className="rect"
            />
            <path
              onClick={this.selectItem}
              d="m 128.11819,190.20204 c -1.15421,-0.83608 -2.55297,-2.11147 -3.10843,-2.8342 -0.55545,-0.72273 -6.35399,-7.21033 -12.88569,-14.41687 -12.757297,-14.07544 -13.676113,-15.19573 -13.676113,-16.67475 0,-0.53168 1.732163,-2.60176 3.849223,-4.60017 4.6947,-4.43158 9.11829,-10.47438 12.20853,-16.67729 3.33408,-6.69231 4.53645,-11.39081 4.70914,-18.40204 0.0793,-3.21505 0.28687,-6.07674 0.46152,-6.35932 0.20174,-0.32637 8.73564,-0.43742 23.39426,-0.30443 l 23.07669,0.20936 1.14535,1.25948 c 1.62325,1.78507 2.03483,6.14273 1.27686,13.51981 -2.09068,20.34839 -11.30754,39.81012 -27.71512,58.52126 -4.31933,4.92575 -8.26406,8.2793 -9.73891,8.2793 -0.49439,0 -1.84316,-0.68407 -2.99731,-1.52014 z"
              id="Sextant4Btn"
              className="rect"
            />
            <path
              onClick={this.selectItem}
              d="m 73.906065,213.03613 c -9.92383,-1.87742 -20.497947,-7.54133 -26.943086,-14.4317 -2.590647,-2.76963 -2.870454,-3.59594 -1.858124,-5.48749 0.630316,-1.17783 28.268677,-32.49993 28.677613,-32.49993 0.124704,0 1.586378,0.77051 3.248094,1.71224 2.753652,1.56049 3.418265,1.71225 7.499561,1.71225 4.081297,0 4.74591,-0.15176 7.49956,-1.71225 1.661718,-0.94173 3.123391,-1.71224 3.248095,-1.71224 0.408935,0 28.047292,31.3221 28.677602,32.49993 1.01233,1.89155 0.73252,2.71786 -1.85812,5.48749 -5.48554,5.86449 -15.07183,11.41608 -23.651521,13.697 -5.507355,1.46413 -18.610369,1.85643 -24.539674,0.7347 z"
              id="Sextant5Btn"
              className="rect"
            />
            <path
              onClick={this.selectItem}
              d="m 35.766037,189.48391 c -1.314896,-1.00399 -4.217064,-3.90823 -6.449246,-6.45383 C 12.909207,164.31894 3.692347,144.85722 1.6016751,124.50883 0.84370577,117.13175 1.2552756,112.77408 2.8785323,110.98902 l 1.1453572,-1.25948 23.0766825,-0.20937 c 14.658623,-0.13299 23.192534,-0.022 23.394263,0.30444 0.174649,0.28257 0.38233,3.14427 0.461514,6.35932 0.172701,7.01121 1.375062,11.70973 4.709144,18.40203 3.090252,6.20292 7.513838,12.24572 12.208538,16.6773 2.117068,1.99841 3.849223,4.06849 3.849223,4.60016 0,1.47722 -0.912229,2.59071 -13.676113,16.69289 -6.531705,7.21652 -12.530447,13.89634 -13.330616,14.84406 -1.6133,1.91082 -4.565677,3.90902 -5.775678,3.90902 -0.43122,0 -1.859861,-0.82147 -3.17481,-1.82548 z"
              id="Sextant6Btn"
              className="rect"
            />
            <path
              onClick={this.selectItem}
              d="m 119.26253,104.92052 c -0.17464,-0.28257 -0.38232,-3.14427 -0.46151,-6.359331 -0.1727,-7.01121 -1.37506,-11.709729 -4.70913,-18.402023 -3.09026,-6.202927 -7.51384,-12.245713 -12.20854,-16.677298 -2.117069,-1.99841 -3.849223,-4.068484 -3.849223,-4.600163 0,-1.471505 0.891682,-2.563603 13.670103,-16.74302 14.48819,-16.076589 16.41722,-18.038163 18.17241,-18.478683 1.96755,-0.493826 4.83437,1.649308 10.07571,7.53223 16.61585,18.649867 26.09123,38.486677 28.20335,59.04408 0.75796,7.377069 0.3464,11.734748 -1.27685,13.519798 l -1.14537,1.25948 -23.07667,0.20937 c -14.65863,0.13299 -23.19255,0.0219 -23.39428,-0.30444 z"
              id="Sextant3Btn"
              className="rect"
            />
          </g>
        </svg>
      </div>
    );
  }
}
