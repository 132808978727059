import React, { useEffect, useState } from "react";
import _ from "lodash";
import ReactSpinner from "react-spinjs-fix";
import { useSelector, useDispatch } from "react-redux";

import DocsItem from "../../docs/DocsItem";
import Show from "../../../../widgets/Show";
import Back from "../../../../widgets/Back";
import FlexBox from "../../../../ui/FlexBox";
import { useQuery } from "../../../../../hooks/useQuery";
import {
  getFolderList,
  openFolder,
  moveItems,
  uploadFiles,
  openImage,
  selectItem,
  closeFolder,
  loadFilesList,
  selectFile,
  imageTypeQuestionToggle,
} from "../../../../../actions/folderActions";
import { Modal } from "../../../../ui/Modal";
import uploadImage from "../../../../../assets/images/upload-icon.png";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";

const FormPatientDocs = ({
  form,
  setForm,
  questionId,
  editMode,
  visible,
  setOverlay,
  patientImage,
  questionTypeCode,
  questionTypeCodes,
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const isImage = questionTypeCode === questionTypeCodes.IMAGE;
  const folder = useSelector((store) => store.folder);

  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(visible || false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const item = form.questions.find((item) => item.id === questionId);

  useEffect(() => {
    if (item?.id) {
      setValue(item?.answerFile || item?.answerPhoto?.url || item?.answerPhoto?.url);
    }
  }, [item]);

  useEffect(() => {
    const { patientKey } = query;
    dispatch(
      getFolderList({
        patientKey,
        folderId: _.last(folder.folderStack) || null,
      }),
    );
  }, []);

  if (!show) {
    return (
      <img
        id="mouthId"
        alt="mouth"
        className="medical-fill-in-form-answer"
        src={value}
        onClick={() => setShow(editMode)}
      />
    );
  }

  const currentFolder = _.last(folder.folderStack) || 0;
  const folderList = folder.folderList[currentFolder] || [];
  const filesList = folder.filesList[currentFolder] || [];

  let list = [];

  if (folder.isMoving) {
    list = folderList.filter((x) => !_.includes(folder.selectedFolders, x.id));
  } else {
    list = [...folderList, ...filesList];
  }

  const loadList = (event) => {
    const { patientKey } = query;
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight;

    if (scrollBottom) {
      dispatch(
        loadFilesList({
          patientKey,
          folderId: _.last(folder.folderStack) || null,
        }),
      );
    }
  };

  const backHandler = () => {
    setOverlay({ visible: false });
    setShow(false);
    dispatch(imageTypeQuestionToggle(false));
  };

  const openFileHandler = (id) => {
    dispatch(openImage(id));
  };

  const onSelectMoveFolder = (targetFolderId, isClosed) => {
    dispatch(
      moveItems(
        {
          patientKey: query.patientKey,
          targetFolderId,
        },
        isClosed,
      ),
    );
  };

  const onUpload = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;
    const newFiles = [];
    [...files].forEach((originalFile) => {
      const fileExtension = originalFile.name.slice(
        (Math.max(0, originalFile.name.lastIndexOf(".")) || Infinity) + 1,
      );
      const newFile = new File(
        [originalFile],
        today === fileName ? originalFile.name : fileName + "." + fileExtension,
        {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        },
      );
      newFiles.push(newFile);
    });
    dispatch(
      uploadFiles({
        patientKey: query.patientKey,
        files: newFiles,
        folderId: _.last(folder.folderStack) || null,
      }),
    );
    setFiles([]);
    setFileName("");
    setShowEditNameModal(false);
  };

  const renameFiles = (files) => {
    let _today = new Date();
    const dd = String(_today.getDate()).padStart(2, "0");
    const mm = String(_today.getMonth() + 1).padStart(2, "0");
    const yyyy = _today.getFullYear();

    _today = dd + "-" + mm + "-" + yyyy;
    setShowEditNameModal(true);
    setFiles(files);
    setFileName(_today);
  };

  const saveHandler = () => {
    setShow(false);
    setOverlay({ visible: false });
    dispatch(imageTypeQuestionToggle(false));
    const { selectedFile } = folder;

    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.answerFile = selectedFile.url;
          question.answerPhoto = {
            id: selectedFile.id,
            key: selectedFile.key,
            url: selectedFile.url,
          };
          dispatch(dataToFillHandler(question.answerPhoto));
        }
        return question;
      }),
    });
  };

  return (
    <>
      <Show if={showEditNameModal}>
        <Modal
          title="Edit file name"
          show={true}
          actions={
            <div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setShowEditNameModal(false);
                  setFileName("");
                }}
              >
                cancel
              </button>
              <button type="button" className="btn btn-default" onClick={() => onUpload()}>
                ok
              </button>
            </div>
          }
        >
          <input
            className="form-control input-sm mb-3"
            type="text"
            value={fileName}
            onChange={({ target: { value } }) => setFileName(value)}
          />
        </Modal>
      </Show>
      <div className="chart-clinical-note">
        <div
          className="sidebar-header chart-side-header"
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {currentFolder > 0 ? (
              <DocsItem
                isBack={true}
                item={{
                  type: "folder",
                }}
                openFolder={() =>
                  folder.isMoving
                    ? onSelectMoveFolder(
                        folder.folderStack.length >= 2
                          ? folder.folderStack[folder.folderStack.length - 2]
                          : null,
                        true,
                      )
                    : dispatch(closeFolder({ patientKey: query.patientKey }))
                }
              />
            ) : (
              <Back onClick={backHandler} />
            )}
          </div>

          <Show if={folder.fetchingFolders}>
            <ReactSpinner />
          </Show>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <div
              title="Upload"
              className="clinic-note patient-note"
              style={{
                cursor: "pointer",
                marginRight: "12px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img alt="" src={uploadImage} width={32} height={32} />
              <input
                style={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  width: "32px",
                  height: "32px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                type="file"
                tabIndex={-1}
                multiple={true}
                name="uploadFile"
                onChange={({ target: { files } }) => renameFiles(files)}
              />
            </div>
          </div>
        </div>
        <div className="chart-side-body" onScroll={loadList}>
          {list.map((x, idx) => (
            <DocsItem
              item={x}
              key={idx}
              isImage={isImage}
              openFile={() => openFileHandler(x.id)}
              isMoving={folder.isMoving}
              openFolder={() =>
                folder.isMoving
                  ? onSelectMoveFolder(x.id)
                  : dispatch(
                      openFolder({
                        folderId: x.id,
                        patientKey: query.patientKey,
                      }),
                    )
              }
              onSelect={() => {
                dispatch(selectItem(x.type, x.id));
                dispatch(selectFile(x));
              }}
              selected={_.includes(
                x?.type === "folder" ? folder.selectedFolders : folder.selectedFiles,
                x.id,
              )}
            />
          ))}
        </div>
        {Boolean(!_.isEmpty(folder.selectedFiles) || !_.isEmpty(folder.selectedFolders)) && (
          <FlexBox container={16}>
            <FlexBox flex={true} direction="row" gutter={8}>
              <FlexBox flex={true}>
                <button
                  style={{ width: "100%" }}
                  className="btn btn-success btn-lg"
                  onClick={backHandler}
                >
                  Cancel
                </button>
              </FlexBox>

              <FlexBox flex={true}>
                <button
                  style={{ width: "100%" }}
                  className="btn btn-success btn-lg"
                  onClick={saveHandler}
                >
                  Save
                </button>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </div>
    </>
  );
};

export default FormPatientDocs;
