import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import * as dashboardActions from "../../actions/dashboardActions";
import { Routes } from "../../constants/Routes";
import { parseQuery } from "../../utils/UrlUtils";

const enhancer = connect(({ dashboard }) => ({ dashboard }), null);
class ProfileEdit extends React.Component {
  componentDidMount() {
    const query = parseQuery(this.props.location.search);
    this.props.dispatch(
      dashboardActions.initProfileEdit((query && query.prevPath) || Routes.DashboardProfile),
    );
  }

  render() {
    return (
      <div className="settings-page">
        <div className="settings-bar">
          <div className="settings-bar-title">
            <Link to={this.props.dashboard.prevPath} className="btn btn-link">
              ❮ &nbsp; BACK
            </Link>
            <div className="name" onClick={this.clickTitle}>
              Profile
            </div>
          </div>
          <div className="list-group">
            <Link to={Routes.DashboardProfileEditSignature} className="list-group-item">
              Signature
            </Link>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
export default enhancer(ProfileEdit);
