import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { FULL_DATE_FORMAT } from "../../constants/Constants";
import RemindersToggleDentist from "./RemindersToggleDentist";
import RemindersTogglePatient from "./RemindersTogglePatient";
import RemindersManualModeForm from "./RemindersManualModeForm";
import ReactSpinner from "react-spinjs-fix";

import Show from "../widgets/Show";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersManualMode extends React.Component {
  componentDidMount() {
    this.props.remindersActions.initManualList();
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.remindersActions.loadManualList();
    }
  };

  render() {
    const {
      templates,
      toggleDentistVisible,
      toggleDentistMode,
      toggleDentistSelected,
      searchDentistSelected,
      searchDentist,
      fetchingDentistList,
      dentistList,
      manualList,
      lastMode,
      recipientType,
      notificationType,
      templateLabel,
      fetchingManualList,
      togglePatientVisible,
      togglePatientMode,
      togglePatientSelected,
      searchPatient,
      searchPatientSelected,
      fetchingPatientList,
      patientList,
      calendarPatientList,
      searchCalendarPatient,
      fetchingCalendarPatientList,
      appointmentTypes,
      calendarFilterValue,
      calendarColumns,
      remindersFilterDropdownVisible,
      remindersFilterDoctorDropdownVisible,
      doctorSelected,
    } = this.props;

    const list = [];

    if (!_.isEmpty(manualList)) {
      _.forEach(manualList, (element, index) => {
        let recipients = "";

        if (element.recipients && element.recipients.length) {
          if (element.recipients.length > 1) {
            recipients = element.recipients.length;

            if (element.recipientType && element.recipientType === "EMPLOYEE") {
              recipients += ` ${localStorage.getItem("doctorLabel")}`;
            } else {
              recipients += " Patient";
            }
          } else {
            recipients = element.recipients[0].name;
          }
        }

        list.push(
          <tr key={index}>
            <td className="col-xs-5">{(element.template && element.template.name) || ""}</td>
            <td className="col-xs-2">{recipients}</td>
            <td className="col-xs-2">
              {element.runBefore && element.runBefore === 1
                ? "1 day"
                : element.runBefore > 0
                ? `${element.runBefore} days`
                : "0 day"}
            </td>
            <td className="col-xs-3">{moment(element.createdDate).format(FULL_DATE_FORMAT)}</td>
          </tr>,
        );
      });
    }

    return (
      <div className="reminders-box reminders-manual-mode">
        <RemindersManualModeForm
          togglePatientMode={togglePatientMode}
          lastMode={lastMode}
          templates={templates}
          toggleDentistSelected={toggleDentistSelected}
          togglePatientSelected={togglePatientSelected}
          recipientType={recipientType}
          notificationType={notificationType}
          templateLabel={templateLabel}
        />

        <div className="col-sm-12 reminders-manual-box">
          <table className="table table-reminders">
            <thead>
              <tr>
                <th className="col-xs-5">Template</th>
                <th className="col-xs-2">Receiver</th>
                <th className="col-xs-2">Send before</th>
                <th className="col-xs-3">Date sent</th>
              </tr>
            </thead>
            <tbody onScroll={this.loadList}>
              {list}
              <Show if={fetchingManualList}>
                <tr className="tr-spinner">
                  <td>
                    <ReactSpinner />
                  </td>
                </tr>
              </Show>
            </tbody>
          </table>
        </div>
        <div className="clearfix" />
        <Show if={toggleDentistVisible}>
          <RemindersToggleDentist
            toggleDentistVisible={toggleDentistVisible}
            toggleDentistMode={toggleDentistMode}
            toggleDentistSelected={toggleDentistSelected}
            searchDentistSelected={searchDentistSelected}
            searchDentist={searchDentist}
            dentistList={dentistList}
            fetchingDentistList={fetchingDentistList}
          />
        </Show>
        <Show if={togglePatientVisible}>
          <RemindersTogglePatient
            remindersFilterDropdownVisible={remindersFilterDropdownVisible}
            remindersFilterDoctorDropdownVisible={remindersFilterDoctorDropdownVisible}
            calendarColumns={calendarColumns}
            calendarFilterValue={calendarFilterValue}
            appointmentTypes={appointmentTypes}
            doctorSelected={doctorSelected}
            searchPatientSelected={searchPatientSelected}
            togglePatientVisible={togglePatientVisible}
            togglePatientMode={togglePatientMode}
            togglePatientSelected={togglePatientSelected}
            searchPatient={searchPatient}
            fetchingPatientList={fetchingPatientList}
            patientList={patientList}
            searchCalendarPatient={searchCalendarPatient}
            fetchingCalendarPatientList={fetchingCalendarPatientList}
            calendarPatientList={calendarPatientList}
          />
        </Show>
      </div>
    );
  }
}
export default enhancer(RemindersManualMode);
