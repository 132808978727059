import { isArray } from "lodash";
import React, { useMemo } from "react";
import { calendarClinicIdSelector } from "../../reducers/calendarReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";
import { useDoctorsListQuery } from "../../queries/member/useDoctorsListQuery";
import { useSelector } from "react-redux";

export interface DoctorSelectWrapperProps extends Omit<SelectPickerProps, "options"> {
  readonly allLabel?: string;
  readonly allValue?: any;
  readonly customClinicId?: number;
}

export function DoctorSelectWrapper({
  allLabel,
  allValue,
  customClinicId,
  onlyDoctors,
  ...props
}: DoctorSelectWrapperProps) {
  // @ts-ignore
  const clinicId = useShallowEqualSelector(calendarClinicIdSelector);
  // @ts-ignore
  const members = useSelector((store) => store?.auth.members);
  const doctorsQuery = useDoctorsListQuery({
    clinicId: customClinicId || clinicId,
    publicPoint: true,
  });

  const list = useMemo(() => {
    const doctorsList = doctorsQuery.data || [];
    const newList = allLabel ? [{ label: allLabel, value: allValue }] : [];
    return newList.concat(
      doctorsList
        .filter(
          (item) =>
            members[item.id] && members[item.id]?.status.id != 1 && members[item.id]?.doctor,
        )
        .map((v) => ({ value: v.id, label: v.name })),
    );
  }, [allLabel, doctorsQuery.data]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isLoading={doctorsQuery.isLoading}
      formatMultiValue={(x) => {
        if (isArray(x)) {
          return `Doctors (${x.length})`;
        }

        return x.label;
      }}
    />
  );
}
