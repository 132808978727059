import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Back from "../widgets/Back";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import moment from "moment";
import logoImage from "../../assets/images/clinic_logo.png";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import BackGroundImage from "../../assets/images/patients_new.jpg";
import AddFormBackGroundImage from "../../assets/images/patient/bg_add_patients.png";
import { obtainImagePath } from "../../utils/ImageUtils";
import { formatPhoneToObject } from "../../utils/FormatUtils";

const enhancer = connect(
  ({ patientRegister }) => ({ patientRegister }),
  bindActions({ patientRegisterActions }),
);
export default enhancer(
  class PatientAddAnother extends React.Component {
    chooseAccount = (patient) => {
      if (patient.patientKey) {
        this.props.history.push(
          createUrl(Routes.PatientView, { params: { patientKey: patient.patientKey } }),
        );
      } else {
        const mobilePhoneArray = formatPhoneToObject(patient.mobilePhoneNumber);

        const query = {
          register: true,
          ...patient,
          gender: patient.gender && patient.gender.id,
          phoneNumber: `${mobilePhoneArray.countryCode.value}-${mobilePhoneArray.townCode}-${mobilePhoneArray.number}`,
        };

        this.props.history.push(
          createUrl(Routes.PatientDetailsEdit, {
            query,
          }),
        );
      }
    };

    addAnother = () => {
      const mobilePhoneNumber = this.props.patientRegister.registeredPatients[0].mobilePhoneNumber;

      this.chooseAccount({
        mobilePhoneNumber,
      });

      this.props.patientRegisterActions.patientDetailsEdit();
    };

    render() {
      const { registeredPatients } = this.props.patientRegister;

      const $list = registeredPatients.map((patient, index) => (
        <div
          className="patient-choose-account-item"
          key={index}
          onClick={() => this.chooseAccount(patient)}
        >
          <strong>Date of birth:</strong> {moment(patient.dateOfBirth).format("DD/MM/YYYY")}
        </div>
      ));

      return (
        <div
          className="patient-mobile"
          style={{ backgroundImage: `url(${obtainImagePath(BackGroundImage)})` }}
        >
          <div className="patient-list-title clearfix">
            <Back className="pull-left" />
            <img alt="" src={logoImage} className="logo" />
          </div>
          <div className="patient-mobile-body">
            <div
              className="patient-mobile-body-form"
              style={{ backgroundImage: `url(${AddFormBackGroundImage})` }}
            >
              <div className="title">Choose you account</div>
              <div className="patient-choose-account-title">
                <strong>Mobile number:</strong>
                &nbsp;
                {_.isArray(registeredPatients) &&
                  registeredPatients.length > 0 &&
                  registeredPatients[0].mobilePhoneNumber}
              </div>
              <div className="choose-list-title">Registered Patients</div>
              <div className="choose-list">{$list}</div>
              <div className="form-group">
                <div className="col-sm-10 col-sm-offset-1">
                  <button
                    type="button"
                    onClick={this.addAnother}
                    className="btn btn-success btn-block btn-lg"
                  >
                    Add as a new patient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  },
);
