import React from "react";
import Back from "../widgets/Back";

export default function Header(props) {
  const { children, addClass, className, hideBack, text } = props;
  const wrapClassName = className || `main-header ${addClass || ""}`;
  const backBtnText = text || "Back";
  return (
    <div className={wrapClassName} style={{ zIndex: 100 }}>
      {hideBack ? null : (
        <Back onClick={props.onClick} className="btn-lg pull-left" text={backBtnText} />
      )}
      {children}
    </div>
  );
}
