import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Back from "../widgets/Back";

import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { defaultColors } from "../../constants/defaults";

import bindActions from "../../helpers/bindActions";
import * as appointmentActions from "../../actions/appointmentActions";
import * as loaderActions from "../../actions/loaderActions";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";

const enhancer = connect(
  ({ appointment: { appointmentStatus } }) => ({
    appointmentStatus,
  }),
  bindActions({ appointmentActions, loaderActions }),
);
class AppointmentStatus extends React.Component {
  componentDidMount() {
    let colors = JSON.parse(localStorage.getItem("appointmentStatus"));

    this.props.loaderActions.startLoader();

    this.props.appointmentActions
      .getStatusList()
      .then((response) => {
        response.forEach((item) => {
          if (!colors[item.code]) {
            colors[item.code] = {
              code: item.code,
              name: item.name,
              color: defaultColors.Green,
            };
          }
        });

        localStorage.setItem("appointmentStatus", JSON.stringify(colors));
        this.props.appointmentActions.setAppointmentStatusColors(colors);
        this.props.loaderActions.stopLoader();

        this.forceUpdate();
      })
      .catch((error) => this.props.loaderActions.stopLoader(error));
  }

  render() {
    let $colors = Utils.objectToArray(Colors.getStatusColors(), (item) => {
      return (
        <Link
          key={item.code}
          className="list-group-item"
          to={createUrl(Routes.SettingsAppointmentStatusColor, {
            params: { colorCode: item.code },
          })}
        >
          <div className="pull-right">❯</div>
          <div className="color" style={{ backgroundColor: item.color.color }} />
          {item.name}
        </Link>
      );
    });

    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment status colors</div>
        </div>
        <div className="body">
          <div className="list-group">{$colors}</div>
        </div>
      </div>
    );
  }
}
export default enhancer(AppointmentStatus);
