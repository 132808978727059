import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs";

import Show from "../../../widgets/Show";

import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
import BackButton from "../../../widgets/BackButton";

const enhancer = connect(
  ({ calendar: { doctorsPoints } }) => ({
    doctorsPoints,
  }),
  bindActions({ chartSideClinicalNoteActions }),
);
export default enhancer(
  class ChartClinicalNotePrint extends React.Component {
    componentDidMount() {
      this.props.chartSideClinicalNoteActions.getOwnedList();
    }

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartSideClinicalNoteActions.loadOwnedList();
      }
    };

    render() {
      const { ownedList, fetching } = this.props.chartSideClinicalNote;

      const doctors = [];

      _.forEach(this.props.doctorsPoints, (doctor) => {
        _.forEach(doctor.dentalPoints, (point) => {
          if (point.active) {
            doctors.push(doctor.id);
          }
        });
      });

      const $list = [];

      ownedList.forEach((item, index) => {
        const id = _.get(item, "owner.id", 0);

        if (_.includes(doctors, id) || _.isEmpty(doctors)) {
          $list.push(
            <div>
              <div>
                <strong>{item.name}</strong>
              </div>
              <div>
                <strong>by {item.owner && item.owner.name}</strong>
              </div>
              <div>
                <strong>
                  {Utils.parseDateFormat(
                    Utils.calcDateWithTimeZone(item.createdDate),
                    DATE_FORMAT_LEGACY,
                  )}
                </strong>
              </div>
              <br />
              <div key={index} dangerouslySetInnerHTML={{ __html: item.note }} />
              <hr style={{ borderColor: "#333" }} />
            </div>,
          );
        }
      });

      return (
        <div className="chart-clinical-note">
          <div className="clinical-note-item">
            <div className="clinical-note-item-view-title">
              <BackButton
                className="btn-sm"
                onClick={this.props.chartSideClinicalNoteActions.popStack}
              />
              <div className="name">Clinical Notes</div>
            </div>
            <div className="clinical-note-item-view-body" onScroll={this.loadList}>
              <div className="clinical-note-item-view-note">
                {$list}
                <Show if={fetching}>
                  <ReactSpinner />
                </Show>
              </div>
            </div>
          </div>
        </div>
      );
    }
  },
);
