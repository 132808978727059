import React from "react";
import AddFormBackGroundImage from "../../assets/images/patient/bg_add_patients.png";
import { PhoneNumberField } from "../form/PhoneNumberField";
import { Formik, Form } from "formik";
import { DateField } from "../form/DateField";

const PatientMobileForm = ({ onSubmit }) => {
  return (
    <Formik initialValues={{ phoneNumber: null, dateOfBirthDay: null }} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          className="form-horizontal patient-mobile-body-form"
          style={{ backgroundImage: `url(${AddFormBackGroundImage})` }}
        >
          <div className="title">ENTER PATIENT'S MOBILE NUMBER</div>

          <div className="form-group" style={{ padding: "0 45px", marginBottom: "1px" }}>
            <PhoneNumberField name="phoneNumber" label="" />
          </div>

          <div className="form-group" style={{ marginBottom: "1px" }}>
            <div className="col-sm-10 col-sm-offset-1 d-flex flex-column">
              <label>Date of Birth</label>
              <DateField name="dateOfBirthDay" className="control-lg" />
            </div>
          </div>

          <div className="form-group">
            <div className="col-sm-10 col-sm-offset-1">
              <button
                type="submit"
                className="btn btn-success btn-block btn-lg"
                disabled={isSubmitting}
              >
                Validate
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PatientMobileForm;
