import React, { useEffect, useState } from "react";
import FilledImage from "../../../../widgets/FilledImage";
import callListImage from "../../../../../assets/images/call-list/check_2x.png";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { useDispatch } from "react-redux";

const FormRadioList = ({ form, setForm, questionId, editMode, visible, setOverlay }) => {
  const item = form.questions.find((item) => item.id === questionId);
  const [otherValue, setOtherValue] = useState("");
  const [otherValueSelected, setOtherValueSelected] = useState(false);
  const [show, setShow] = useState(visible || false);
  const [shouldRender, setShouldRender] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.id) {
      item.options.forEach((answer) => {
        if (answer.otherValue) {
          setOtherValue(answer.otherValue);
          setOtherValueSelected(!!answer.otherValue);
        }
      });
    }
  }, [item]);

  const onSave = (selectedId) => {
    setShouldRender(!shouldRender);
    dispatch(dataToFillHandler(selectedId));
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.options = question.options.map((answer) => {
            answer.selected = answer.id === selectedId;
            return answer;
          });
        }
        return question;
      }),
    });
  };

  const onUpdateForm = () => {
    setOverlay({ visible: false });
    setShow(false);
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.options = question.options.map((answer) => {
            if (answer.other) {
              answer.otherValue = otherValue;
              if (otherValue && otherValue.trim().length > 0)
                dispatch(dataToFillHandler(otherValue));
            }
            return answer;
          });
        }
        return question;
      }),
    });
  };

  const onSaveComment = (selectedId, comment) => {
    setShouldRender(!shouldRender);
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.options = question.options.map((answer) => {
            if (answer.id === selectedId) {
              answer.comment = comment;
              if (comment.trim().length > 0) dispatch(dataToFillHandler(comment));
            }
            return answer;
          });
        }
        return question;
      }),
    });
  };

  const $values = (item?.options || []).map((answer, index) => {
    let $commentBox = null;

    if (answer.selected) {
      $commentBox = (
        <div className="w-100 pb-2">
          <span>additional comments</span>
          <textarea
            rows={2}
            maxLength={1000}
            className="form-control"
            style={{
              width: "100%",
            }}
            value={answer.comment}
            onChange={(e) => onSaveComment(answer.id, e.target.value)}
          />
          <span>{(answer.comment || "").length}/1000</span>
        </div>
      );
    }
    return (
      <>
        <button
          key={index}
          onClick={() => {
            if (answer.other) {
              setOtherValue("");
              setOtherValueSelected(!otherValueSelected);
            }
            onSave(answer.id);
          }}
          className={answer.selected ? "btn btn-primary" : "btn btn-default"}
          style={{ whiteSpace: "wrap", width: "31.5%" }}
        >
          {answer.title}
        </button>
        {$commentBox}
      </>
    );
  });

  if (!show) {
    const selected = (item?.options || []).find((i) => i.selected);
    return !selected ? (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {"N/A"}
      </p>
    ) : (
      <>
        {(item?.options || []).map((item, index) => {
          if (item.selected) {
            return (
              <p
                className="medical-fill-in-form-answer"
                key={index}
                onClick={() => setShow(editMode)}
              >
                {item.otherValue || item.title}
              </p>
            );
          }
          return null;
        })}
      </>
    );
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex pb-1" style={{ flexWrap: "wrap", gap: "8px", borderBottom: "1px solid #eeeeee", }}>
        {$values}
        {otherValueSelected && (
          <div className="w-100">
            <textarea
              rows={5}
              maxLength={1000}
              className="form-control w-100"
              value={otherValue}
              onChange={(e) => setOtherValue(e.target.value)}
            />
            <span>{otherValue.length}/1000</span>
          </div>
        )}
      </div>
      <button className="btn btn-primary mt-2" onClick={onUpdateForm}>
        Ok
      </button>
    </div>
  );
};

export default FormRadioList;
