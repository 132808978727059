import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { saveConsultationNotesItem } from "../../../actions/appointmentActions";

const enhancer = compose(
  connect(null, { saveConsultationNotesItem }),
  reduxForm({ form: "consultationNotes", enableReinitialize: true }),
);

function consultationNotesForm({ handleSubmit, saveConsultationNotesItem }) {
  return (
    <form
      id="consultationNotes"
      className="consultation-notes-form"
      onSubmit={handleSubmit(saveConsultationNotesItem)}
    >
      <Field
        component="input"
        className="form-control"
        placeholder="Name"
        required={true}
        name="name"
      />
      <Field
        component="textarea"
        className="form-control field-consultation-note"
        rows="5"
        required={true}
        name="note"
      />
      <button
        type="button"
        onClick={saveConsultationNotesItem}
        className="btn btn-primary full-width"
      >
        Add
      </button>
    </form>
  );
}

export default enhancer(consultationNotesForm);
