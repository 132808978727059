import _ from "lodash";
import TransactionApi from "../api-legacy/TransactionApi";

import * as ActionType from "../actionTypes/chartSidePlanActionTypes";

import { showMessage, startLoader, stopLoader } from "./loaderActions";
import { hideSideBar } from "./chartActions";

import { planStackCodes } from "../constants/chart-sidebar/planStackCodes";

export function deletePlanItemConfirmShow(id) {
  return {
    type: ActionType.DELETE_PLAN_ITEM_CONFIRM_MODAL_SHOW,
    id,
  };
}

export function deletePlanItem(id) {
  return (dispatch) => {
    dispatch(deletePlanItemConfirmHide());
    dispatch(startLoader());

    return dispatch(deletePlanItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(initList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function deletePlanItemApi(data) {
  return {
    api: TransactionApi.deleteTreatmentPlanItem,
    types: [
      ActionType.DELETE_PLAN_ITEM_START,
      ActionType.DELETE_PLAN_ITEM_SUCCESS,
      ActionType.DELETE_PLAN_ITEM_ERROR,
    ],
    data,
  };
}

export function deletePlanItemConfirmHide() {
  return {
    type: ActionType.DELETE_PLAN_ITEM_CONFIRM_MODAL_HIDE,
  };
}

export function initPlan() {
  return {
    type: ActionType.SIDE_PLAN_INIT,
  };
}

export function initFor() {
  return {
    type: ActionType.SIDE_PLAN_INIT_FOR,
  };
}

function pushStack(stackItem) {
  return {
    type: ActionType.SIDE_PLAN_PUSH_STACK,
    stackItem,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSidePlan;

    if (stack.length == 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.SIDE_PLAN_POP_STACK,
      });
    }
  };
}

export function initList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePlan: { sortField, searchValue },
    } = getState();

    const data = {
      filterKey: searchValue,
      patientKey,
      sortField,
      limit: 25,
    };

    dispatch(getTreatmentPlanList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadAll() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment,
      },
      chartSidePlan: { listFor, listForTotalCount, searchValueFor, sorting },
    } = getState();

    if (listForTotalCount > listFor.length) {
      const data = {
        limit: 100,
        patientKey,
        type: "TREATMENT",
        sortField: sorting,
        start: listFor.length,
        treatmentPlanned: true,
        treatmentCompleted: true,
        filterKey: searchValueFor,
        appointmentId: appointment.id,
      };

      dispatch(getListForTreatmentPlan(data))
        .then(() => dispatch(loadAll()))
        .catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function loadList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment,
      },
      chartSidePlan: { listFor, listForTotalCount, searchValueFor, sorting },
    } = getState();

    if (listForTotalCount > listFor.length) {
      const data = {
        limit: 25,
        patientKey,
        type: "TREATMENT",
        sortField: sorting,
        start: listFor.length,
        treatmentPlanned: true,
        treatmentCompleted: true,
        filterKey: searchValueFor,
        appointmentId: appointment.id,
      };

      dispatch(getListForTreatmentPlan(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function initListFor() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment,
      },
      chartSidePlan: { searchValueFor, sorting },
    } = getState();

    const data = {
      limit: 25,
      patientKey,
      sortField: sorting,
      treatmentPlanned: true,
      treatmentCompleted: true,
      filterKey: searchValueFor,
      appointmentId: appointment.id,
    };

    dispatch(getListForTreatmentPlan(data))
      .then(() => dispatch(loadAll()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function loadListFor() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment,
      },
      chartSidePlan: { listFor, listForTotalCount, searchValueFor, sorting },
    } = getState();

    if (listForTotalCount > listFor.length) {
      const data = {
        patientKey,
        appointmentId: appointment.id,
        filterKey: searchValueFor,
        limit: 25,
        start: listFor.length,
        sortField: sorting,
      };

      dispatch(getListForTreatmentPlan(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function setSorting(type, select = false) {
  return (dispatch, getState) => {
    const {
      chartSidePlan: { sorting },
    } = getState();

    let newSorting;

    switch (type) {
      case "date":
        if (sorting === "date_asc") {
          newSorting = "date_desc";
        } else {
          newSorting = "date_asc";
        }
        break;
      case "tooth":
        if (sorting === "tooth_asc") {
          newSorting = "tooth_desc";
        } else {
          newSorting = "tooth_asc";
        }
        break;
      case "code":
        if (sorting === "code_asc") {
          newSorting = "code_desc";
        } else {
          newSorting = "code_asc";
        }
        break;
    }

    dispatch({
      type: ActionType.SIDE_PLAN_CHANGE_SORTING,
      sorting: select ? type : newSorting,
    });

    dispatch(initListFor());
  };
}

export function toggleListFor(item) {
  return {
    type: ActionType.SIDE_PLAN_TOGGLE_LIST_FOR,
    item,
  };
}

export function toggleGroupListFor(items, doSelect) {
  return {
    type: ActionType.SIDE_PLAN_TOGGLE_GROUP_LIST_FOR,
    items,
    doSelect,
  };
}

export function toggleAllGroupListFor(status) {
  return {
    type: ActionType.SIDE_PLAN_TOGGLE_ALL_GROUP_LIST_FOR,
    status,
  };
}

export function clearAllGroupListFor() {
  return {
    type: ActionType.SIDE_PLAN_CLEAR_ALL_GROUP_LIST_FOR,
  };
}

export function addAllFor() {
  return {
    type: ActionType.SIDE_PLAN_ADD_ALL_FOR,
  };
}

export function removeAllFor() {
  return {
    type: ActionType.SIDE_PLAN_REMOVE_ALL_FOR,
  };
}

export function disableSelectedMode() {
  return {
    type: ActionType.SIDE_PLAN_DISABLE_SELECTED_MODE,
  };
}

export function enableSelectedMode() {
  return {
    type: ActionType.SIDE_PLAN_ENABLE_SELECTED_MODE,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PLAN_SELECT_SORT_FIELD,
      code,
    });

    dispatch(initList());
  };
}

export function setSearchValue(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PLAN_SEARCH_CHANGE,
      value,
    });

    dispatch(initList());
  };
}

export function setSearchValueFor(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PLAN_SEARCH_FOR_CHANGE,
      value,
    });

    dispatch(initListFor());
  };
}

export function discountAmountChange(event) {
  return {
    type: ActionType.SIDE_PLAN_DISCOUNT_AMOUNT_CHANGE,
    value: event.target.value,
  };
}

export function discountPercentageChange(event) {
  return {
    type: ActionType.SIDE_PLAN_DISCOUNT_PERCENTAGE_CHANGE,
    value: event.target.value,
  };
}

export function doneFor() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment,
      },
      chartSidePlan: { selectedFor },
      form: { chartPlanForName },
    } = getState();

    const dentalTransactionKeys = Object.values(selectedFor)
      .filter(Boolean)
      .map((item) => item.key);

    const data = {
      patientKey,
      appointmentId: appointment.id,
      dentalTransactionKeys,
    };

    if (chartPlanForName.values) {
      data.name = chartPlanForName.values.name;
      data.notes = chartPlanForName.values.notes;

      if (chartPlanForName.values.groupingType !== "WITHOUT") {
        data.groupingType = chartPlanForName.values.groupingType;
      }

      if (chartPlanForName.values.useInvoiceTemplate === "estimate-sl") {
        data.invoiceLanguage = "sk";
      }

      if (chartPlanForName.values.useInvoiceTemplate === "estimate-fr") {
        data.invoiceLanguage = "fr";
      }

      if (chartPlanForName.values.useInvoiceTemplate === "true") {
        data.invoiceLanguage = "";
        data.useInvoiceTemplate = true;
      }

      if (chartPlanForName.values.useInvoiceTemplate === "FR") {
        data.invoiceLanguage = "fr";
        data.useInvoiceTemplate = true;
      }

      if (chartPlanForName.values.useInvoiceTemplate === "SL") {
        data.invoiceLanguage = "sk";
        data.useInvoiceTemplate = true;
      }

      if (chartPlanForName.values.currencyId) {
        data.currencyId = chartPlanForName.values.currencyId;
      }

      if (_.toFinite(chartPlanForName.values.additionalDiscountAmount) > 0) {
        data.additionalDiscountAmount = _.toFinite(
          chartPlanForName.values.additionalDiscountAmount,
        );
      }

      const discountType = _.get(chartPlanForName, "values.discountType");
      const discountValue = _.toFinite(_.get(chartPlanForName, "values.discountValue"));

      if (discountType && discountValue) {
        data.discount = {
          value: discountValue,
          code: discountType,
        };
      }
    }

    dispatch(startLoader());
    dispatch({
      api: TransactionApi.saveTreatmentPlan,
      types: [
        ActionType.SIDE_PLAN_SAVE_TREATMENT_PLAN_START,
        ActionType.SIDE_PLAN_SAVE_TREATMENT_PLAN_SUCCESS,
        ActionType.SIDE_PLAN_SAVE_TREATMENT_PLAN_ERROR,
      ],
      data,
    })
      .then(() => {
        dispatch(popStack());
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeName(values) {
  return (dispatch, getState) => {
    const { chartSidePlan } = getState();
    const { stack } = chartSidePlan;

    const stackItem = _.last(stack);

    const data = {
      id: stackItem.item.id,
      name: values.name,
    };

    dispatch(startLoader());
    dispatch({
      api: TransactionApi.editTreatmentPlan,
      types: [
        ActionType.SIDE_PLAN_EDIT_TREATMENT_PLAN_START,
        ActionType.SIDE_PLAN_EDIT_TREATMENT_PLAN_SUCCESS,
        ActionType.SIDE_PLAN_EDIT_TREATMENT_PLAN_ERROR,
      ],
      data,
    })
      .then(() => {
        dispatch(stopLoader("Done"));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function sendEmail() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chartSidePlan: { stack },
    } = getState();

    const stackItem = _.last(stack);

    const transfer = {
      treatmentsPlanId: stackItem.item.id,
    };

    dispatch(sendToEmail(transfer))
      .then(() => {
        dispatch(stopLoader("Email has been sent"));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openPDF(item) {
  return pushStack({
    code: planStackCodes.pdf,
    item,
  });
}

export function openForList(item) {
  return pushStack({
    code: planStackCodes.forList,
    item,
  });
}

function sendToEmail(data) {
  return {
    api: TransactionApi.sendToEmail,
    types: [
      ActionType.SIDE_PLAN_SEND_TO_EMAIL_START,
      ActionType.SIDE_PLAN_SEND_TO_EMAIL_SUCCESS,
      ActionType.SIDE_PLAN_SEND_TO_EMAIL_ERROR,
    ],
    data,
  };
}

function getTreatmentPlanList(data) {
  return {
    api: TransactionApi.getTreatmentPlanList,
    types: [
      ActionType.SIDE_PLAN_GET_LIST_START,
      ActionType.SIDE_PLAN_GET_LIST_SUCCESS,
      ActionType.SIDE_PLAN_GET_LIST_ERROR,
    ],
    data,
  };
}
function getListForTreatmentPlan(data) {
  return {
    api: TransactionApi.getListForTreatmentPlan,
    types: [
      ActionType.SIDE_PLAN_GET_LIST_FOR_TREATMENT_START,
      ActionType.SIDE_PLAN_GET_LIST_FOR_TREATMENT_SUCCESS,
      ActionType.SIDE_PLAN_GET_LIST_FOR_TREATMENT_ERROR,
    ],
    data,
  };
}
