import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Show from "../../../widgets/Show";
import Utils from "../../../../helpers/Utils";
import bindActions from "../../../../helpers/bindActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT } from "../../../../constants/Constants";
import ChartFillInFormItemDeleteModal from "./ChartFillInFormItemDeleteModal";
import fillInFormItemTypes from "../../../../constants/chart-sidebar/fillInFormItemTypes";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import * as simpleModalActions from "../../../../actions/simpleModalActions";
import { DoctorInfo } from "../../../doctor/DoctorInfo";
import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";
import { getCompanyFormatFullDate } from "../../../../helpers/DateUtils";
import ChartFillInFormReviewModal from "./ChartFillInFormReviewModal";
import GoalApi from "../../../../api-legacy/FillInFormApi";
import moment from "moment";

const enhancer = connect(
  ({ session }) => ({ permissions: session.permissions }),
  bindActions({ chartSideFillInFormActions, simpleModalActions }),
);

const ChartFillInFormItemView = ({
  chartSideFillInFormActions,
  chartSideFillInForm,
  simpleModalActions,
  member,
  permissions,
}) => {
  const { item, deleteModalVisible, referenceList } = chartSideFillInForm;
  const drawenNote = item.typeCode === "NOTE_TYPE_IMAGE";

  // const canEdit = Boolean(item.template && item.template.code);

  const [reviewIsOpen, setReviewIsOpen] = useState(false);

  useEffect(() => {
    chartSideFillInFormActions.getItem();
  }, []);

  const editNote = () => {
    const { stack, item } = chartSideFillInForm;

    const stackItem = _.last(stack);
    const id = stackItem.item.id;

    const data = {
      id,
    };

    const writtenNote = !(item.template && item.template.name);

    if (writtenNote) {
      chartSideFillInFormActions.openTextarea(clinicNoteItemTypes.edit);
    } else {
      chartSideFillInFormActions.openItem(data, fillInFormItemTypes.edit);
    }
  };

  const getFillInformReview = () => {
    // eslint-disable-next-line no-undef
    GoalApi.reviewFillInForm({ id: chartSideFillInForm.item.id }).then((res) => {
      res && chartSideFillInFormActions.getItem();
    });
  };
  const adminDelete =
    permissions.findIndex((item) => item === "REMOVE_FILL_IN_FORM_DOCUMENT_ALL") >= 0;
  const authorDelete =
    permissions.findIndex((item) => item === "REMOVE_FILL_IN_FORM_DOCUMENT") >= 0;

  const deletePermission = adminDelete
    ? true
    : authorDelete
    ? item.doctor && item.doctor.id === member.id
    : !adminDelete && !authorDelete;

  const isEditAvailable = (item) => {
    const less24hours = Date.now() - item.createdDate < 24 * 60 * 60 * 1000;
    const less48hours = Date.now() - item.createdDate < 48 * 60 * 60 * 1000;
    const less72hours = Date.now() - item.createdDate < 72 * 60 * 60 * 1000;

    const allEditAnyTime = permissions.includes("EDIT_FILL_IN_FORM_DOCUMENT_ALL");
    const editAnyTime = permissions.includes("EDIT_FILL_IN_FORM_DOCUMENT");
    const less24HourPermission = permissions.includes("EDIT_FILL_IN_FORM_DOCUMENT_WITHIN_24_HOURS");
    const less48HourPermission = permissions.includes("EDIT_FILL_IN_FORM_DOCUMENT_WITHIN_48_HOURS");
    const less72HourPermission = permissions.includes("EDIT_FILL_IN_FORM_DOCUMENT_WITHIN_72_HOURS");
    const createdDoctor = item.doctor && item.doctor.id === member.id;
    return (
      allEditAnyTime ||
      editAnyTime ||
      (createdDoctor && less48HourPermission && less48hours) ||
      (createdDoctor && less24HourPermission && less24hours) ||
      (createdDoctor && less72HourPermission && less72hours)
    );
  };
  return (
    <div className="chart-clinical-note">
      <div className="clinical-note-item">
        <SidebarHeader
          title={item.name}
          onBackClick={() => chartSideFillInFormActions.popStack()}
          rightComponent={
            <span className="text-white mr-2">#{item.patient && item.patient.chartNumber}</span>
          }
        />
        <div className="clinical-note-item-view-body">
          <div className="clinical-note-item-view-details">
            <div>
              Patient: <strong>{item.patient && item.patient.fullName}</strong>
              &nbsp; Chart: <strong>#{item.patient && item.patient.chartNumber}</strong>
            </div>
            <div>
              Created: &nbsp;
              <strong>
                {Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.createdDate),
                  DATE_FORMAT_LEGACY,
                )}
              </strong>
            </div>
            <div>
              Age: <strong>{_.get(item, "patient.age")}</strong>
            </div>
            <div>
              Name: &nbsp;
              <strong>{item.doctor && item.doctor.name}</strong>
            </div>
          </div>

          <div className="clinical-note-item-view-note">
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
            {!!item.doctor && <DoctorInfo doctor={item.doctor} signature={item.doctorSignature} />}
            {!!item.reviewer && (
              <>
                <div className="d-flex justify-content-around mt-4 mb-1">
                  <div>
                    Reviewed By: <strong>{item.doctor?.name}</strong>
                  </div>
                  <div>
                    Reviewed On:{" "}
                    <strong>{moment(item.reviewedDate).format(FULL_DATE_FORMAT)}</strong>
                  </div>
                </div>

                <DoctorInfo
                  doctor={item.reviewer}
                  signature={item.reviewerSignature}
                  lineHide={true}
                />
              </>
            )}
            {referenceList.map((x) => (
              <div key={x.id} className="d-flex flex-column mb-3">
                <span className="font-weight-bold">{`[Addendum] ${
                  x.name
                } ${getCompanyFormatFullDate(x.createdDate)}`}</span>
                <div className="mt-1" dangerouslySetInnerHTML={{ __html: x.content }} />
                <hr style={{ borderTopColor: "#000000", width: "100%" }} />
              </div>
            ))}
          </div>
        </div>
        <div className="clinical-note-item-view-footer">
          <div className="clearfix">
            <div className="col-sm-4">
              <button
                disabled={drawenNote}
                onClick={() => {
                  if (isEditAvailable(item)) {
                    return editNote();
                  }
                  simpleModalActions.openSimpleModal({
                    body: "permission denied",
                  });
                }}
                className="btn btn-primary btn-lg btn-block"
              >
                Edit
              </button>
            </div>
            <div className="col-sm-4">
              <button
                onClick={() => setReviewIsOpen(true)}
                className="btn btn-primary btn-lg btn-block"
              >
                Review
              </button>
            </div>
            <div className="col-sm-4">
              <Show if={deletePermission}>
                <button
                  onClick={chartSideFillInFormActions.openDeleteModal}
                  className="btn btn-primary btn-lg btn-block"
                >
                  Delete
                </button>
              </Show>
            </div>
          </div>
        </div>
      </div>
      <ChartFillInFormItemDeleteModal
        deleteModalVisible={deleteModalVisible}
        closeDeleteModal={chartSideFillInFormActions.closeDeleteModal}
        deleteItem={chartSideFillInFormActions.deleteItem}
      />
      <ChartFillInFormReviewModal
        closeReviewModal={() => setReviewIsOpen(false)}
        reviewModalVisible={reviewIsOpen}
        fillInFormReview={getFillInformReview}
      />
    </div>
  );
};

export default enhancer(ChartFillInFormItemView);
