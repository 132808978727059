import React from "react";

import Show from "../../widgets/Show";

import ChartAppointment from "./ChartAppointment";
import ChartPatient from "./ChartPatient";
import ChartLinks from "./ChartLinks";
import ChartBalanceModal from "./ChartBalanceModal";

export default function ChartInfo(props) {
  const {
    patient,
    appointment,
    type,
    chartBalanceModalVisible,
    chartBalanceValue,
    mouthLinkVisible,
    showSideBar,
    completeAppointmentsCount,
  } = props;

  return (
    <div className="chart-info">
      <ChartPatient patient={patient} count={completeAppointmentsCount} />
      <ChartLinks
        patient={patient}
        showSideBar={showSideBar}
        mouthLinkVisible={mouthLinkVisible}
        appointment={appointment}
      />
      <Show if={type !== "Planning"}>
        <ChartAppointment appointment={appointment} type={type} />
      </Show>
      <Show if={chartBalanceModalVisible}>
        <ChartBalanceModal
          show={chartBalanceModalVisible}
          chartBalanceValue={chartBalanceValue}
          patientKey={patient.patientKey}
        />
      </Show>
    </div>
  );
}
