import cx from "classnames";
import { toFinite } from "lodash";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import { Theme } from "../../dto/AppDTO";
import { formatDate } from "../../utils/DateUtils";
import { EligibilityStatus } from "./EligibilityStatus";
import { EligibilityInfoText } from "./EligibilityInfoText";
import { EligibilityInfoWithLink } from "./EligibilityInfoWithLink";
import { EligibilityForm, EligibilityFormProps } from "./EligibilityForm";
import { Button, ButtonType } from "../ui/Button";

const useStyles = makeStyles<Theme>((theme) => ({
  row: { display: "flex", fontWeight: 700 },
  blockTitle: { textDecoration: "underline" },
  secondRow: { marginTop: theme.typography.pxToRem(4) },
  firstRow: { marginBottom: theme.typography.pxToRem(4) },
  leftBlock: { marginRight: theme.typography.pxToRem(4) },
  rightBlock: { marginLeft: theme.typography.pxToRem(4) },
  rowWithSpace: { marginBottom: theme.typography.pxToRem(16) },
  error: { alignSelf: "center", margin: `${theme.typography.pxToRem(16)} 0` },
  loader: { alignSelf: "center", margin: `${theme.typography.pxToRem(16)} 0` },
  benefitBlock: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.typography.pxToRem(4),
  },
  block: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(4),
    border: `1px solid ${theme.palette.grey["200"]}`,
  },
  actions: {
    borderTop: `1px solid ${theme.palette.grey["200"]}`,
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(8),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export interface EligibilityDetailsProps extends EligibilityFormProps {
  readonly show: boolean;
  readonly onHide: () => void;
  readonly isPending: boolean;
  readonly onSubmit: (EligibilityPreFormValuesProps) => any;
  readonly eligibility?: EligibilityProps;
}

export function EligibilityDetails({
  onSubmit,
  onHide,
  isPending,
  eligibility,
  ...props
}: EligibilityDetailsProps) {
  const classes = useStyles();

  const [showForm, setShowForm] = useState(true);
  const [showData, setShowData] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const answerMembers =
    eligibility?.eligibilityCheck.eligibilityCheckAnswer.eligibilityCheckAnswerMembers || [];

  const answerMember = answerMembers.length > 0 ? answerMembers[0] : undefined;
  const answerMember2 = answerMembers.length > 0 ? answerMembers[1] : undefined;

  const hasBenefits = toFinite((answerMember?.eligibilityCheckAnswerBenefits || []).length) > 0;
  const hasBenefits2 = toFinite((answerMember2?.eligibilityCheckAnswerBenefits || []).length) > 0;
  const submitHandler = (values: EligibilityPreFormValuesProps) =>
    onSubmit(values)
      .then(() => setShowData(true))
      .finally(() => setShowForm(false))
      .catch((error) => {
        setErrorMessage(error);
        setShowError(true);
      });

  useEffect(() => {
    if (!props.show) {
      setShowForm(true);
      setShowError(false);
      setShowData(false);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show && eligibility) {
      setShowForm(false);
      setShowData(true);
    }
  }, [eligibility, props.show]);
  useEffect(() => {
    if ((errorMessage || "").indexOf("/eligibility/requestdetails/") >= 0 && !!eligibility) {
      setShowForm(false);
      setShowData(true);
    }
  }, [errorMessage]);
  const checkAgainHandler = () => {
    setShowForm(true);
    setShowError(false);
    setShowData(false);
  };
  if (eligibility?.error) {
    setErrorMessage(eligibility.error);
    setShowError(true);
  }
  return (
    <div {...props}>
      {isPending && <span className={classes.error}>Pending...</span>}

      {!isPending && (
        <>
          {showForm && <EligibilityForm onSubmit={submitHandler} />}

          {showError && !eligibility && (
            <span className={classes.error}>
              {errorMessage || "Insurance company is not supported"}
            </span>
          )}

          {!eligibility && showData && (
            <span className={classes.error}>Currently this service is busy, please try again</span>
          )}

          {!!eligibility && showData && (
            <>
              <div className={cx(classes.row, classes.firstRow)}>
                <div className={cx(classes.block, classes.leftBlock)}>
                  <EligibilityInfoText
                    className={classes.rowWithSpace}
                    title="Eligibility Status:"
                    value={<EligibilityStatus active={!!eligibility.eligibilityCheck.result} />}
                  />
                  <EligibilityInfoText
                    className={classes.rowWithSpace}
                    title="Payer ID:"
                    value={eligibility.eligibilityCheck.eligibilityCheckAnswer.idPayer}
                  />
                  <EligibilityInfoText
                    className={classes.rowWithSpace}
                    title="Eligibility ID:"
                    value={"eELIG-" + eligibility.eligibilityCheck.eligibilityId}
                  />
                  <EligibilityInfoText
                    title="Service Category:"
                    value={eligibility.eligibilityCheck.serviceCategory.description}
                  />
                  <EligibilityInfoText
                    title="Clinician:"
                    value={eligibility.eligibilityCheck.clinician.fullName}
                  />
                  <EligibilityInfoText
                    title="Eligibility Date:"
                    value={formatDate(
                      eligibility.eligibilityCheck.eligibilityCheckAnswer?.authorizationEndDate,
                    )}
                  />
                  <EligibilityInfoText
                    title="Ordered On:"
                    value={formatDate(eligibility.eligibilityCheck.transactionDate)}
                  />
                </div>

                {activeTab === 0 ? (
                  <div className={cx(classes.block, classes.rightBlock)}>
                    <EligibilityInfoText title="Policy Details & Benefits:" />
                    <span>----------------------------------------</span>
                    <EligibilityInfoText
                      title="Card Network:"
                      value={answerMember?.cardNetwork || ""}
                    />
                    <EligibilityInfoText title="Policy:" value={answerMember?.policyName || ""} />
                    <EligibilityInfoText
                      title="Coverage Effective Date:"
                      value={formatDate(answerMember?.startDate)}
                    />
                    <EligibilityInfoText
                      title="Coverage Expire Date:"
                      value={formatDate(answerMember?.expiryDate)}
                    />
                  </div>
                ) : (
                  <div className={cx(classes.block, classes.rightBlock)}>
                    <EligibilityInfoText title="Policy Details & Benefits:" />
                    <span>----------------------------------------</span>
                    <EligibilityInfoText
                      title="Card Network:"
                      value={answerMember2?.cardNetwork || ""}
                    />
                    <EligibilityInfoText title="Policy:" value={answerMember2?.policyName || ""} />
                    <EligibilityInfoText
                      title="Coverage Effective Date:"
                      value={formatDate(answerMember2?.startDate)}
                    />
                    <EligibilityInfoText
                      title="Coverage Expire Date:"
                      value={formatDate(answerMember2?.expiryDate)}
                    />
                  </div>
                )}
              </div>
              <div className={cx(classes.row, classes.secondRow)}>
                <div className={cx(classes.block, classes.leftBlock)}>
                  <div className="btn-group">
                    <button
                      type="button"
                      style={{ width: "50%" }}
                      className={`btn btn-${activeTab === 0 ? "success" : "default"}`}
                      onClick={() => setActiveTab(0)}
                    >
                      Primary Card
                    </button>
                    <button
                      type="button"
                      style={{ width: "50%" }}
                      className={`btn btn-${activeTab === 1 ? "success" : "default"}`}
                      onClick={() => setActiveTab(1)}
                    >
                      Secondary Card
                    </button>
                  </div>
                  {activeTab === 0 ? (
                    <>
                      <EligibilityInfoText className={classes.blockTitle} title="Card Details:" />
                      <EligibilityInfoText
                        title="Card Number:"
                        value={answerMember?.cardNumber || ""}
                      />
                      <EligibilityInfoText
                        className={cx({ [classes.rowWithSpace]: hasBenefits })}
                        title="Package Name:"
                        value={answerMember?.packageName || ""}
                      />
                      {hasBenefits && (
                        <>
                          <EligibilityInfoText className={classes.blockTitle} title="Benefits:" />
                          {(answerMember?.eligibilityCheckAnswerBenefits || []).map((x, i) => (
                            <div key={i} className={classes.benefitBlock}>
                              <EligibilityInfoText title="Room Type:" value={x.code} />
                              <EligibilityInfoText title="Benefit:" value={x.benefitValue} />
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <EligibilityInfoText className={classes.blockTitle} title="Card Details:" />
                      <EligibilityInfoText
                        title="Card Number:"
                        value={answerMember2?.cardNumber || ""}
                      />
                      <EligibilityInfoText
                        className={cx({ [classes.rowWithSpace]: hasBenefits2 })}
                        title="Package Name:"
                        value={answerMember2?.packageName || ""}
                      />
                      {hasBenefits2 && (
                        <>
                          <EligibilityInfoText className={classes.blockTitle} title="Benefits:" />
                          {(answerMember2?.eligibilityCheckAnswerBenefits || []).map((x, i) => (
                            <div key={i} className={classes.benefitBlock}>
                              <EligibilityInfoText title="Room Type:" value={x.code} />
                              <EligibilityInfoText title="Benefit:" value={x.benefitValue} />
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={cx(classes.block, classes.rightBlock)}>
                  <EligibilityInfoWithLink
                    answerMember={activeTab === 0 ? answerMember : answerMember2}
                    classes={classes}
                    hasBenefits={activeTab === 0 ? hasBenefits : hasBenefits2}
                    link="#"
                    title="Schedule of Benefits:"
                  />
                  <EligibilityInfoWithLink
                    answerMember={activeTab === 0 ? answerMember : answerMember2}
                    classes={classes}
                    hasBenefits={activeTab === 0 ? hasBenefits : hasBenefits2}
                    link="#"
                    title="General Exclusions:"
                  />
                  <EligibilityInfoWithLink
                    answerMember={activeTab === 0 ? answerMember : answerMember2}
                    classes={classes}
                    hasBenefits={false}
                    link="#"
                    title="Abbreviations:"
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div className={classes.actions}>
        {(!showForm || (!eligibility && showData)) && (
          <Button className="mr-2" buttonType={ButtonType.Primary} onClick={checkAgainHandler}>
            Check again
          </Button>
        )}
        <Button buttonType={ButtonType.Primary} onClick={onHide}>
          Close
        </Button>
      </div>
    </div>
  );
}
