import React from "react";
import _ from "lodash";
import Show from "../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { getCompanyFormatFullDate } from "../../../helpers/DateUtils";

const AppointmentActionsLogs = (props) => {
  const { actionsLogQuery, item, appointment, classes } = props;

  const generateList = () => {
    const array = actionsLogQuery.data || [];

    array.push({
      modifiedMember: _.get(appointment, "creator"),
      modifiedDate: _.get(item, "createdDate"),
      status: {
        name: "Created",
      },
    });

    const list = array.map((item, index) => {
      const apptStatus = _.get(item, "status.name");
      const moved = _.get(item, "moved");
      const modifiedDate = _.get(item, "modifiedDate");
      const modifiedMemberName = _.get(item, "modifiedMember.name");
      const beforeStartTime = _.get(item, "beforeStartTime");
      const afterStartTime = _.get(item, "afterStartTime");

      return (
        <div
          key={index}
          className="d-flex"
          style={{ border: "1px solid #e5e5e5", borderTop: "none" }}
        >
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            {moved ? "Moved" : apptStatus}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            {modifiedDate ? getCompanyFormatFullDate(modifiedDate) : null}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            {modifiedMemberName}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            {beforeStartTime ? getCompanyFormatFullDate(beforeStartTime) : null}
          </span>
          <span className="p-1" style={{ flex: 1 }}>
            {afterStartTime ? getCompanyFormatFullDate(afterStartTime) : null}
          </span>
        </div>
      );
    });

    return (
      <div className="content-body">
        <div className="d-flex" style={{ border: "1px solid #e5e5e5" }}>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            Action Name:{" "}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            Action Date - Time:{" "}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            Action User:{" "}
          </span>
          <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 1 }}>
            Before Action Date - Time:{" "}
          </span>
          <span className="p-1" style={{ flex: 1 }}>
            After Action Date - Time:{" "}
          </span>
        </div>
        <div>{list}</div>
      </div>
    );
  };

  return (
    <div className={classes.content}>
      {generateList()}
      <Show if={actionsLogQuery.isFetching}>
        <ReactSpinner />
      </Show>
    </div>
  );
};

export default AppointmentActionsLogs;
