import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";

import PatientMedicalAlertsElement from "./PatientMedicalAlertsElement";
import PatientFormCaption from "../PatientFormCaption";

import Back from "../../widgets/Back";
import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { withRouter } from "react-router";
import { Panel } from "rc-collapse";

const valueSelector = getFormValues("patientMedicalAlertsEdit");

const formEnhancer = reduxForm({
  form: "patientMedicalAlertsEdit",
});
const enhancer = connect(
  (state) => ({
    values: valueSelector(state),
  }),
  bindActions({ patientRegisterActions }),
);
class PatientMedicalAlertsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  saveMedicalAlerts = () => {
    return this.props.patientRegisterActions.saveMedicalAlerts().then(({ patientKey }) => {
      const query = parseQuery(this.props.location.search);
      if (this.props.medicalForms) {
        this.props.patientRegisterActions.patientMedicalAlertsView();
        return;
      }
      if (query.temporary) {
        this.props.history.push(
          createUrl(Routes.PatientMedicationAlertsEdit, {
            query: {
              patientKey,
              temporary: "true",
            },
          }),
        );
      } else if (query.register) {
        this.props.history.push(
          createUrl(Routes.PatientMedicationAlertsEdit, {
            query: {
              patientKey,
              register: "true",
            },
          }),
        );
      } else {
        this.props.history.push(
          createUrl(Routes.PatientView, { params: { patientKey: patientKey } }),
        );
      }
    });
  };
  render() {
    const query = parseQuery(this.props.location.search);
    const {
      handleSubmit,
      clinic,
      patientRegister,
      initialElements,
      onBackClick,
      medicalForms,
      stackItem,
      medicalFillInFormItemTypes,
    } = this.props;
    const {
      personalDetails: { patient },
      patientMedicalAlerts,
      patientMedicalAlertsTypes,
      signature,
    } = patientRegister;
    const { active } = this.state;

    let collapses = [];
    const isTemp = _.get(query, "temporary");
    const editMode = query.register || isTemp ? true : patientRegister.patientMedicalAlertsEditMode;
    if (!_.isEmpty(patientMedicalAlerts)) {
      patientMedicalAlertsTypes.forEach((type, index) => {
        const alerts = initialElements
          .filter((element) => element.type === type.code)
          .map((element, idx) => (
            <PatientMedicalAlertsElement
              values={this.props.values}
              key={idx}
              editMode={editMode}
              element={element}
              index={idx}
            />
          ));
        collapses.push(
          <Panel
            key={index}
            prefixCls="rc-collapse"
            isActive={active === index}
            onItemClick={() => this.setState({ active: active === index ? -1 : index })}
            header={
              <div className="d-flex">
                <h3 className="alerts-row d-flex align-items-center justify-content-between">
                  {type.name}
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bolder",
                      transition: "all 500ms linear",
                      transform: active === index ? "rotate(90deg)" : "rotate(0deg)",
                    }}
                  >
                    {">"}
                  </span>
                </h3>
              </div>
            }
          >
            {alerts}
          </Panel>,
        );
      });
    }

    return (
      <form
        onSubmit={handleSubmit(this.saveMedicalAlerts)}
        className="default-page patient-medical-alerts-edit"
      >
        <div className="default-page-title clearfix text-center">
          <Show if={!medicalForms}>
            <Back className="pull-left" onClick={onBackClick} />
          </Show>
          <Show if={editMode}>
            <button type="submit" className="btn btn-link pull-right">
              Submit
            </button>
          </Show>
          <Show if={!editMode && query?.medicalType !== medicalFillInFormItemTypes.view}>
            <button
              onClick={this.props.patientRegisterActions.patientMedicalAlertsEdit}
              className="btn btn-link pull-right"
            >
              Edit
            </button>
          </Show>
          <div className="title">Welcome to {clinic.name}</div>
        </div>
        <div className="default-page-body patient-details-edit-body form-horizontal">
          <PatientFormCaption patient={patient} formId="Patient Medical Alerts" />
          <div className="patient-medical-alerts-edit-box">{collapses}</div>
          <Show if={editMode}>
            <div>
              <div className="patient-medical-alerts-please-sign">Please sign</div>
              <div className="line-title">
                <span className="text">PATIENT SIGNATURE</span>
              </div>
              <div
                className="patient-details-edit-signature"
                onClick={this.props.patientRegisterActions.openSignature}
              >
                {signature ? <img alt="signature" src={signature} /> : "Tap to sign"}
              </div>
            </div>
          </Show>
          <div className="patient-details-edit-box medical-bottom">
            <div className="col-sm-12 mb-3 mt-4">
              <Show if={!editMode && query?.medicalType !== medicalFillInFormItemTypes.view}>
                <button
                  className="btn btn-block btn-lg btn-primary"
                  onClick={this.props.patientRegisterActions.patientMedicalAlertsEdit}
                >
                  Edit
                </button>
              </Show>
              <Show if={editMode}>
                <button type="submit" className="btn btn-block btn-lg btn-primary">
                  Submit
                </button>
              </Show>
            </div>
            <div
              style={{ bottom: 0 }}
              className="patient-alerts-history-button"
              onClick={() => {
                this.props.patientRegisterActions
                  .getPatientAlertsHistory("MEDICAL_ALERTS")
                  .then((response) => {
                    if (response) this.props.history.push(Routes.PatientAlertsHistory);
                  });
              }}
            >
              View edit history
            </div>
          </div>
        </div>
        {this.props.children}
      </form>
    );
  }
}

export default withRouter(formEnhancer(enhancer(PatientMedicalAlertsForm)));
