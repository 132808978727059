import React from "react";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import DotImage from "../../../assets/images/dot.png";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientSelectAppointmentSidebarSelect extends React.Component {
  render() {
    const { selected, label, mode } = this.props;

    let typeClassName = "repeat-appointment-follow-type-select";

    typeClassName += (selected && " follow-type-select") || "";

    return (
      <div className="repeat-appointment-follow-type-select-block select-appointment-sidebar">
        <div
          onClick={() => {
            this.props.patientRegisterActions.patientSelectAppointmentSidebarSetMode(mode);
          }}
        >
          <div className={typeClassName}>
            {selected && <img src={DotImage} className="img-responsive" alt="" />}
          </div>
          <label className="repeat-modal-label select-appointment-sidebar-label">{label}</label>
        </div>
        {this.props.children}
      </div>
    );
  }
}
export default enhancer(PatientSelectAppointmentSidebarSelect);
