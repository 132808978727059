import DashboardApi from "../api-legacy/DashboardApi";
import NewsApi from "../api-legacy/NewsApi";

import { startLoader, stopLoader } from "./loaderActions";

import * as ActionTypes from "../actionTypes/dashboardActionTypes";
import MemberApi from "../api-legacy/MemberApi";

export const GET_DASHBOARD_DATA_START = "GET_DASHBOARD_DATA_START";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";

export const GET_NEWS_LIST_START = "GET_NEWS_LIST_START";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_ERROR = "GET_NEWS_LIST_ERROR";

export const GET_DOCTOR_PRODUCTION_FORM_DATA_START = "GET_DOCTOR_PRODUCTION_FORM_DATA_START";
export const GET_DOCTOR_PRODUCTION_FORM_DATA_SUCCESS = "GET_DOCTOR_PRODUCTION_FORM_DATA_SUCCESS";
export const GET_DOCTOR_PRODUCTION_FORM_DATA_ERROR = "GET_DOCTOR_PRODUCTION_FORM_DATA_ERROR";

export const GET_DOCTOR_PRODUCTION_REPORT_START = "GET_DOCTOR_PRODUCTION_REPORT_START";
export const GET_DOCTOR_PRODUCTION_REPORT_SUCCESS = "GET_DOCTOR_PRODUCTION_REPORT_SUCCESS";
export const GET_DOCTOR_PRODUCTION_REPORT_ERROR = "GET_DOCTOR_PRODUCTION_REPORT_ERROR";

export const DASHBOARD_GET_DENTIST_START = "DASHBOARD_GET_DENTIST_START";
export const DASHBOARD_GET_DENTIST_SUCCESS = "DASHBOARD_GET_DENTIST_SUCCESS";
export const DASHBOARD_GET_DENTIST_ERROR = "DASHBOARD_GET_DENTIST_ERROR";

export function initDashboard() {
  return function (dispatch) {
    dispatch({
      type: "initDashboard",
    });

    dispatch(startLoader());

    return Promise.all([dispatch(getDashboardData()), dispatch(getNewsList())])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initDoctorProduction() {
  return function (dispatch) {
    dispatch({
      type: "initDoctorProduction",
    });

    dispatch(startLoader());

    return Promise.all([dispatch(getDoctorProductionFormData())])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getDoctorProduction() {
  return function (dispatch, getState) {
    dispatch(startLoader());

    const {
      form: { BlockedTimeSlotFilterForm },
    } = getState();

    const { startDate, endDate, ...values } = BlockedTimeSlotFilterForm.values;

    const data = JSON.parse(JSON.stringify(values));

    data.startDate = +values.startDate;
    data.endDate = +values.endDate;

    return dispatch(
      getDoctorProductionReport({
        ...values,
        endDate: +endDate,
        startDate: +startDate,
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const initProfile = () => (dispatch) => {
  dispatch(startLoader());

  return dispatch(getDentist())
    .then(() => {
      dispatch(stopLoader());
    })
    .catch((error) => dispatch(stopLoader(error)));
};

export const initProfileEdit = (prevPath) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DASHBOARD_INIT_PROFILE_EDIT,
    prevPath,
  });

  const {
    session: {
      member: { id },
    },
  } = getState();

  dispatch(startLoader());

  return dispatch(getProfile({ id }))
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const addSignature = (signature) => (dispatch, getState) => {
  const {
    session: {
      member: { id },
    },
  } = getState();

  dispatch(startLoader());

  return dispatch(
    saveSignature({
      memberId: id,
      signature,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

function getDoctorProductionFormData(data) {
  return {
    api: DashboardApi.doctorProduction.getFormData,
    types: [
      GET_DOCTOR_PRODUCTION_FORM_DATA_START,
      GET_DOCTOR_PRODUCTION_FORM_DATA_SUCCESS,
      GET_DOCTOR_PRODUCTION_FORM_DATA_ERROR,
    ],
    data,
  };
}

function getDoctorProductionReport(data) {
  return {
    api: DashboardApi.doctorProduction.getReport,
    types: [
      GET_DOCTOR_PRODUCTION_REPORT_START,
      GET_DOCTOR_PRODUCTION_REPORT_SUCCESS,
      GET_DOCTOR_PRODUCTION_REPORT_ERROR,
    ],
    data,
  };
}

function getDashboardData(data) {
  return {
    api: DashboardApi.getDashboardData,
    types: [GET_DASHBOARD_DATA_START, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_DATA_ERROR],
    data,
  };
}

function getNewsList(data) {
  return {
    api: NewsApi.getList,
    types: [GET_NEWS_LIST_START, GET_NEWS_LIST_SUCCESS, GET_NEWS_LIST_ERROR],
    data,
  };
}

function getDentist(data) {
  return {
    api: DashboardApi.getDentist,
    types: [
      DASHBOARD_GET_DENTIST_START,
      DASHBOARD_GET_DENTIST_SUCCESS,
      DASHBOARD_GET_DENTIST_ERROR,
    ],
    data,
  };
}

function getProfile(data) {
  return {
    api: DashboardApi.getProfile,
    types: [
      ActionTypes.DASHBOARD_GET_PROFILE_START,
      ActionTypes.DASHBOARD_GET_PROFILE_SUCCESS,
      ActionTypes.DASHBOARD_GET_PROFILE_ERROR,
    ],
    data,
  };
}

function saveSignature(data) {
  return {
    api: MemberApi.signature.save,
    types: [
      ActionTypes.DASHBOARD_SAVE_SIGNATURE_START,
      ActionTypes.DASHBOARD_SAVE_SIGNATURE_SUCCESS,
      ActionTypes.DASHBOARD_SAVE_SIGNATURE_ERROR,
    ],
    data,
  };
}
