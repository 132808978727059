import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ChartPrescriptionListItemCode from "./ChartPrescriptionListItemCode";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionFavoriteList extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
    };

    componentDidMount() {
      this.props.chartSidePrescriptionActions.initList();
    }

    openItem = (item) => {
      // document.location.href = item.pdfUrl;
      // this.props.chartSidePrescriptionActions.openPDF(item); //todo http-header
      this.props.chartSidePrescriptionActions.addItem(item.id, 1);
    };

    toggleFavourite = (item) => {
      const favourites = JSON.parse(localStorage.getItem("PrescriptionFavourites"));

      if (!favourites[item.id]) {
        favourites[item.id] = item;
      }

      favourites[item.id].selected = !favourites[item.id].selected;

      localStorage.setItem("PrescriptionFavourites", JSON.stringify(favourites));

      this.forceUpdate();
    };

    render() {
      const list = JSON.parse(localStorage.getItem("PrescriptionFavourites"));

      const $list = [];

      _.forEach(list, (item, index) => {
        if (item && item.selected) {
          $list.push(
            <ChartPrescriptionListItemCode
              key={index}
              item={item}
              toggleFavourite={(item) => this.toggleFavourite(item)}
              openItem={() => this.openItem(item)}
            />,
          );
        }
      });

      return (
        <div className="chart-clinical-note">
          <SidebarHeader
            onBackClick={this.props.chartSidePrescriptionActions.popStack}
            title="Favourite Prescriptions"
          />
          <div className="chart-side-body">
            <div className="body">{$list}</div>
            <div className="group-buttons">
              <button
                onClick={this.props.chartSidePrescriptionActions.openCategoryAdd}
                className="btn btn-primary btn-block"
              >
                Category
              </button>
              <button
                onClick={this.props.chartSidePrescriptionActions.addEmpty}
                className="btn btn-primary btn-block"
              >
                Add Blank
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
