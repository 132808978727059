import AppointmentApi from "../api-legacy/AppointmentApi";
import MemberApi from "../api-legacy/MemberApi";
import { startLoader, stopLoader } from "./loaderActions";
import { hideSideBar } from "./chartActions";

export const SET_INITIAL_VALUES = "SET_INITIAL_VALUES";
export const SET_VISIT_TYPE = "SET_VISIT_TYPE";
export const OPEN_CHART = "OPEN_CHART";
export const GET_LIST_START = "GET_LIST_START";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_ERROR = "GET_LIST_ERROR";
export const INIT_LIST_START = "INIT_LIST_START";
export const INIT_LIST_SUCCESS = "INIT_LIST_SUCCESS";
export const INIT_LIST_ERROR = "INIT_LIST_ERROR";
export const SET_REFERRAL_MODAL_VISIBLE = "SET_REFERRAL_MODAL_VISIBLE";
export const INIT_SIDE_REFERRAL_NEXT_VISIT = "INIT_SIDE_REFERRAL_NEXT_VISIT";
export const POP_REFERRAL_NEXT_VISIT_STACK = "POP_REFERRAL_NEXT_VISIT_STACK";
export const SET_REFERRAL_ACTIVE_TAB = "SET_REFERRAL_ACTIVE_TAB";
export const CLEAN_UP_LIST_START = "CLEAN_UP_LIST_START";
export const CLEAN_UP_LIST_ERROR = "CLEAN_UP_LIST_ERROR";
export const CLEAN_UP_LIST_SUCCESS = "CLEAN_UP_LIST_SUCCESS";
export const SET_REFERRAL_NEXT_VISIT_LIST_ACTIVE_TAB = "SET_REFERRAL_NEXT_VISIT_LIST_ACTIVE_TAB";

export const SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_START =
  "SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_START";
export const SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_SUCCESS =
  "SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_SUCCESS";
export const SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_ERROR =
  "SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_ERROR";

export const OPEN_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT =
  "OPEN_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT";

export const CLOSE_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT =
  "CLOSE_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT";

export const setReferralNextVisitListActiveTab = (activeTab) => (dispatch) =>
  dispatch({ type: SET_REFERRAL_NEXT_VISIT_LIST_ACTIVE_TAB, activeTab });

export const SKIP_NEXT_VISIT_START = "SKIP_NEXT_VISIT_START";
export const SKIP_NEXT_VISIT_ERROR = "SKIP_NEXT_VISIT_ERROR";
export const SKIP_NEXT_VISIT_SUCCESS = "SKIP_NEXT_VISIT_SUCCESS";

export const SET_SKIP_NEXT_VISIT_STATUS = "SET_SKIP_NEXT_VISIT_STATUS";
export const GET_WHEN_VALUES_START = "GET_WHEN_VALUES_START";
export const GET_WHEN_VALUES_ERROR = "GET_WHEN_VALUES_ERROR";
export const GET_WHEN_VALUES_SUCCESS = "GET_WHEN_VALUES_SUCCESS";

export const GET_REFERRED_TO_DOCTORS_LIST_START = "GET_REFERRED_TO_DOCTORS_LIST_START";
export const GET_REFERRED_TO_DOCTORS_LIST_SUCCESS = "GET_REFERRED_TO_DOCTORS_LIST_SUCCESS";
export const GET_REFERRED_TO_DOCTORS_LIST_ERROR = "GET_REFERRED_TO_DOCTORS_LIST_ERROR";

export function getReferralDoctors(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getReferralDoctorsApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function referralListApi(data) {
  return {
    types: [GET_LIST_START, GET_LIST_SUCCESS, GET_LIST_ERROR],
    data,
    api: AppointmentApi.getList,
  };
}

function skipNextVisitApi(data) {
  return {
    types: [SKIP_NEXT_VISIT_START, SKIP_NEXT_VISIT_ERROR, SKIP_NEXT_VISIT_SUCCESS],
    api: AppointmentApi.getSkipNextVisitResult,
    data,
  };
}

export function getWhenValuesApi(data) {
  return {
    types: [GET_WHEN_VALUES_START, GET_WHEN_VALUES_ERROR, GET_WHEN_VALUES_SUCCESS],
    api: AppointmentApi.getAppointmentWhenValuesList,
    data,
  };
}

export function getSkipNextVisit(data) {
  return (dispatch) =>
    dispatch(skipNextVisitApi(data))
      .then((data) => dispatch(setSkipNextVisit(data)))
      .catch(() => dispatch(setSkipNextVisit(false)));
}

export function setSkipNextVisit(canSkip) {
  return (dispatch) => dispatch({ type: SET_SKIP_NEXT_VISIT_STATUS, canSkip });
}

function referralInitListApi(data) {
  return {
    types: [INIT_LIST_START, INIT_LIST_SUCCESS, INIT_LIST_ERROR],
    data,
    api: AppointmentApi.getList,
  };
}

function cleanUpReferralNextVisitListApi(data) {
  return {
    types: [CLEAN_UP_LIST_START, CLEAN_UP_LIST_ERROR, CLEAN_UP_LIST_SUCCESS],
    data,
    api: AppointmentApi.cleanUpReferralNextVisitList,
  };
}

export function setInitialValues(value) {
  return (dispatch) => {
    dispatch({
      type: SET_INITIAL_VALUES,
      value,
    });
  };
}

export function setReferralActiveTab(activeTab) {
  return (dispatch) => {
    dispatch({
      type: SET_REFERRAL_ACTIVE_TAB,
      activeTab,
    });
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSidePostOp;

    if (stack.length === 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: POP_REFERRAL_NEXT_VISIT_STACK,
      });
    }
  };
}

export function initSideReferralNextVisit() {
  return (dispatch) => {
    dispatch({
      type: INIT_SIDE_REFERRAL_NEXT_VISIT,
    });
  };
}

export function setVisitType(visitType) {
  return (dispatch) => {
    dispatch({
      type: SET_VISIT_TYPE,
      visitType,
    });
  };
}

export function referralOpenChart(bool) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CHART,
      bool,
    });
  };
}

export function setReferralModalVisible(state) {
  return (dispatch) => {
    dispatch({
      type: SET_REFERRAL_MODAL_VISIBLE,
      state,
    });
  };
}

export function initList(data) {
  return (dispatch) => {
    const transfer = {
      ...data,
      start: 0,
      limit: 25,
      chartNumber: data.patientChartNumber,
      referral: true,
      referralType: { code: "NEXT_VISIT" },
      referralTypeCodes: data.referralTypeCode ? data.referralTypeCode : "REFERRAL, NEXT_VISIT",
      // statusCodes: data.referralTypeCode !== -1 ? "APPOINTMENT_STATUS_IN_WAITING" : null,
    };
    dispatch(startLoader());
    return dispatch(referralInitListApi(transfer))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function cleanUpReferralNextVisitList(data, futureTypeCode) {
  return (dispatch) => {
    const transfer = {
      ...data,
      futureTypeCode,
    };
    dispatch(startLoader());
    return dispatch(cleanUpReferralNextVisitListApi(transfer))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getList(data) {
  return (dispatch, getState) => {
    const {
      referral: { list, referralListTotalCount, loading },
    } = getState();

    if (list.length === referralListTotalCount || loading) {
      return;
    }
    const transfer = {
      ...data,
      start: list.length,
      limit: 25,
      chartNumber: data.patientChartNumber,
      referralTypeCodes: data.referralTypeCode ? data.referralTypeCode : "REFERRAL, NEXT_VISIT",
    };
    dispatch(startLoader());
    return dispatch(referralListApi(transfer))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const referralNextVisitAppointmentSetType = (data) => (dispatch) => {
  let api;
  if (data.willCall) {
    api = AppointmentApi.setWillCall;
  }
  if (data.noAnswer) {
    api = AppointmentApi.setNoAnswer;
  }

  return dispatch({
    api,
    types: [
      SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_START,
      SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_SUCCESS,
      SET_REFERRAL_NEXT_VISIT_APPOINTMENT_SET_TYPE_ERROR,
    ],
    data,
  });
};

function getReferralDoctorsApi(data) {
  return {
    api: MemberApi.getChoose,
    types: [
      GET_REFERRED_TO_DOCTORS_LIST_START,
      GET_REFERRED_TO_DOCTORS_LIST_SUCCESS,
      GET_REFERRED_TO_DOCTORS_LIST_ERROR,
    ],
    data,
  };
}
