import React from "react";

import { Button } from "../ui/Button";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { appointmentEligibilityDetailsSelector } from "../../reducers/appointmentReducer";
import { useDispatch } from "react-redux";
import { openSimpleModal } from "../../actions/simpleModalActions";

interface Props {
  readonly appointment: any;
  readonly onClick: () => void;
}

export function ViewEligibilityButtonWrapper({ appointment, onClick }: Props) {
  const dispatch = useDispatch();

  const eligibilityDetails = useShallowEqualSelector(
    appointmentEligibilityDetailsSelector(appointment?.id) as any,
  );

  if (appointment?.id) {
    return (
      <Button
        onClick={() => {
          if (!eligibilityDetails) {
            dispatch(
              openSimpleModal({ body: "No data found. Please, request Check Eligibility." }),
            );
          } else {
            onClick();
          }
        }}
      >
        View Eligibility
      </Button>
    );
  }

  return null;
}
