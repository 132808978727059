import React, { useEffect, useState } from "react";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { useDispatch } from "react-redux";

const FormValue = ({ form, setForm, questionId, editMode, visible, setOverlay }) => {
  const item = form.questions.find((item) => item.id === questionId);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [show, setShow] = useState(visible || false);

  useEffect(() => {
    if (item?.id) {
      setValue(item?.answer || item?.defaultAnswer);
    }
  }, [item]);
  const onSave = () => {
    setShow(false);
    setOverlay({ visible: false });
    dispatch(dataToFillHandler(value));
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.answer = value;
        }
        return question;
      }),
    });
  };
  if (!show) {
    return (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {value || "N/A"}
      </p>
    );
  }
  return (
    <div className="d-flex flex-column">
      <input
        type="number"
        className="flex-none"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <button className="btn btn-primary" onClick={onSave}>
        Ok
      </button>
    </div>
  );
};

export default FormValue;
