import React, { useEffect, useState } from "react";
import Show from "../../../../widgets/Show";
import Utils from "../../../../../helpers/Utils";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { useDispatch } from "react-redux";

const FormGenerateFormula = ({ form, setForm, questionId, editMode, visible, setOverlay }) => {
  const [show, setShow] = useState(visible || false);
  const [item, setItem] = useState(form.questions.find((item) => item.id === questionId));
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.id) {
      setValue(item?.answer || "");
    }
  }, [item]);

  const onSave = () => {
    setShow(false);
    setOverlay({ visible: false });
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question = item;
          if (item?.answer && item?.answer?.replace(/\+/g, "") !== "0") {
            dispatch(dataToFillHandler(item.answer));
          } else {
            dispatch(dataToFillHandler(""));
          }
        }
        return question;
      }),
    });
  };

  const onChangeAnswer = (code, index, value) => {
    const reqExp = /\[\[[^\]]*]]/g;
    const data = { ...item };
    let idx = 0;
    data.answer = data.formula.replace(reqExp, (match) => {
      const _code = match.slice(2, -2);
      if (code === _code && index === idx) {
        data.formulaQuestions = data.formulaQuestions.map((x) => {
          if (x.code === code) {
            x.answer = value;
          }
          return x;
        });
        idx++;
        return value;
      }
      let answer = "";
      data.formulaQuestions.forEach((x) => {
        if (x.code !== code) {
          answer = x.answer || 0;
        }
      });
      idx++;
      return answer;
    });
    setItem(data);
  };

  if (!show) {
    return (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {Utils.calculateStringToNumber(value) || "N/A"}
      </p>
    );
  }

  return (
    <div className="d-flex flex-column">
      <Show if={item?.formulaQuestions}>
        {(item.formulaQuestions || []).map((x, index) => (
          <div className="w-100 d-flex flex-column" key={index}>
            <p>{x.question}</p>
            <input
              type="number"
              className="form-control mb-2"
              value={x.answer}
              onChange={({ target: { value } }) => onChangeAnswer(x.code, index, value)}
            />
          </div>
        ))}
      </Show>
      <div className="w-100">
        <h4>Total: {Utils.calculateStringToNumber(item?.answer)}</h4>
      </div>
      <button className="btn btn-primary" onClick={() => onSave()}>
        Ok
      </button>
    </div>
  );
};

export default FormGenerateFormula;
