export function lineWrap(text = "", length = 5) {
  const words = text.split(" ");
  const result = [];

  let temp = "";

  for (let i = 0; i < words.length; i++) {
    if ((i + 1) % length === 0) {
      result.push(temp + words[i] + " ");
      temp = "";
    } else if (i === words.length - 1) {
      result.push(temp + words[i]);
    } else {
      temp += words[i] + " ";
    }
  }

  return result.join("<br />");
}
