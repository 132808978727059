import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const enhancer = connect(({ session }) => {
  return {
    session,
  };
}, null);
class Stub extends React.Component {
  render() {
    return (
      <div className="container">
        <br />
        <br />
        <div className="jumbotron">
          <h2>On the go...</h2>
          <p>This is an electron app created for dok32</p>
          <p>Device Name: {this.props.session.device && this.props.session.device.name}</p>
          <p>
            <button className="btn btn-primary" type="button" onClick={this.props.history.goBack}>
              Go Back
            </button>
          </p>
        </div>
      </div>
    );
  }
}
export default withRouter(enhancer(Stub));
