// @ts-ignore
import React, { forwardRef, ReactNode } from "react";
import { useCreateTempPatientMutation } from "../../queries/patient/useCreateTempPatientMutation";
import { useSavePatientDetailsMutation } from "../../queries/patient/useSavePatientDetailsMutation";
import { showMessage, startLoader, stopLoader } from "../../actions/loaderActions";
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { appointmentAppointmentSelector } from "../../reducers/appointmentReducer";
import { patientRegisterSelector } from "../../reducers/patientRegisterReducer";
import { createPatientValues, createTempPatientValues } from "../../helpers/PatientHelpers";
import { Form, Formik } from "formik";
import Utils from "../../helpers/Utils";

interface Props {
  readonly initialValues: any;
  readonly isTemp: boolean;
  readonly tempId2: number;
  readonly onSuccess: (response) => void;
  readonly children: (props) => ReactNode;
}

// eslint-disable-next-line react/display-name
export const PatientDetailsCustomForm = forwardRef(
  ({ initialValues, isTemp, tempId2, children, onSuccess }: Props, ref) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const appointment = useShallowEqualSelector(appointmentAppointmentSelector as any);
    const patientRegister = useShallowEqualSelector(patientRegisterSelector as any);
    const patientDetailsEditValues = useShallowEqualSelector(
      ({ form }: any) => form.patientDetailsEdit?.values,
    );

    const patientMutation = useSavePatientDetailsMutation();
    const tempPatientMutation = useCreateTempPatientMutation();
    const {
      personalDetails: { mandatoryFields = [] },
    } = patientRegister;

    return (
      <Formik
        // @ts-ignore
        innerRef={ref}
        enableReinitialize={true}
        initialValues={initialValues}
        // @ts-ignores
        onSubmit={(values, { resetForm }) => {
          if (isTemp) {
            dispatch(startLoader());
            const data = createTempPatientValues({ values, patientRegister } as any);

            tempPatientMutation
              .mutateAsync(data)
              .then(({ data }: any) => {
                if (data.status === "error") {
                  dispatch(stopLoader(data.message));
                } else {
                  onSuccess({ response: data.data, appointment });
                  resetForm();
                  dispatch(stopLoader());
                }
              })
              .catch((e) => dispatch(stopLoader(e)));
          } else {
            const data = createPatientValues({
              values,
              patientRegister,
              tempId2,
              professionState: state,
            } as any);

            if (
              Utils.checkMandatoryFields(values, mandatoryFields, (message) =>
                dispatch(showMessage(message)),
              ) === true
            ) {
              dispatch(startLoader());
              patientMutation
                .mutateAsync(data)
                .then(({ data }: any) => {
                  if (data.status === "error") {
                    dispatch(stopLoader(data.message));
                  } else {
                    onSuccess({
                      response: data.data,
                      appointment,
                      patientKey: data.data.patientKey,
                    });
                    resetForm();
                    dispatch(stopLoader());
                  }
                })
                .catch((e) => dispatch(stopLoader(e)));
            }
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <Form onSubmit={handleSubmit} className="default-page patient-details-edit">
              {children({ values, setFieldValue })}
            </Form>
          );
        }}
      </Formik>
    );
  },
);
