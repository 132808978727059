import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import Back from "../widgets/Back";

import { defaultColors, other } from "../../constants/defaults";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";

export default class AppointmentTypeColor extends React.Component {
  selectColor = (item) => {
    return () => {
      Colors.selectAppointmentType(_.get(this, "props.match.params.colorCode"), item);
      this.forceUpdate();
    };
  };

  render() {
    const colorCode = _.get(this, "props.match.params.colorCode");
    const activeType = Colors.getTypeColor(colorCode) || {};

    let $colors = Utils.objectToArray(defaultColors, (item) => {
      const checkActive = activeType.color && activeType.color.code === item.code ? " active" : "";

      return (
        <button key={item.code} onClick={this.selectColor(item)} className="list-group-item">
          <div className={"pull-right check" + checkActive}>✔</div>
          <div className="color" style={{ backgroundColor: item.color }} />
          {item.name}
        </button>
      );
    });

    let listOther = other;
    let otherActive = "";

    if (activeType.color && activeType.color.code === other.code) {
      otherActive = " active";
      listOther = activeType.color;
    }

    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">{activeType.name}</div>
        </div>
        <div className="body">
          <div className="list-group">
            {$colors}
            <Link
              to={createUrl(Routes.SettingsAppointmentTypeOther, {
                params: {
                  colorCode,
                },
              })}
              className="list-group-item"
            >
              <div className={"pull-right check" + otherActive}>✔</div>
              <div className="color" style={{ backgroundColor: listOther.color }} />
              {listOther.name}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
