import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { defaultColors } from "../../constants/defaults";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import FilledImage from "../../components/widgets/FilledImage";
import { AppLayout } from "../../components/app-layout/AppLayout";
import { getCalendarLateAppointments } from "../../actions/calendarActions";
import { formatCompanyDate, getCompanyUTCOffset } from "../../helpers/DateUtils";
import RefreshImage from "../../assets/images/calendar-bottom-menu/refresh@2x.png";

const enhancer = connect(
  ({ calendar: { lateAppointments, totalBeingSeen, totalCheckedIn, totalAssessment } }) => ({
    lateAppointments,
    totalBeingSeen,
    totalCheckedIn,
    totalAssessment,
  }),
  { getCalendarLateAppointments },
);

const NA = "N/A";

function getDiffTime(time) {
  return Date.now() - time - getCompanyUTCOffset(true) - 60 * 60 * 1000;
}

export const LateAppointmentsPage = enhancer(
  class LateAppointmentsContainer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        updateTimer: null,
      };
    }

    componentDidMount() {
      const { getCalendarLateAppointments } = this.props;

      getCalendarLateAppointments();

      this.setState({
        updateTimer: setInterval(getCalendarLateAppointments, 2 * 60 * 60 * 1000),
      });
    }

    componentWillUnmount() {
      const { updateTimer } = this.state;

      if (updateTimer) {
        clearInterval(updateTimer);

        this.setState({
          updateTimer: null,
        });
      }
    }

    updateList = () => {
      const { getCalendarLateAppointments } = this.props;

      getCalendarLateAppointments().then(() => {
        this.forceUpdate();
      });
    };

    render() {
      const { totalBeingSeen, totalCheckedIn, totalAssessment, lateAppointments } = this.props;
      const list = lateAppointments.map((x, idx) => {
        const status = _.get(x, "status.name", NA);
        const statusCode = _.get(x, "status.code", NA);
        const chartNumber = _.get(x, "patient.chartNumber", NA);
        const dentalPoint = _.get(x, "dentalPoint.name", NA);
        const doctor = _.get(x, "dentist.name", NA);
        const startTime = _.get(x, "startTime");
        const modifiedDate = _.get(x, "modifiedDate");
        const checkedInDate = _.get(x, "checkedInDate");

        const appointmentTime = startTime ? formatCompanyDate(startTime, "HH:mm") : NA;
        const checkedInTime = checkedInDate ? formatCompanyDate(checkedInDate, "HH:mm") : NA;
        const assessmentTime =
          modifiedDate && statusCode === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT
            ? formatCompanyDate(modifiedDate, "HH:mm")
            : NA;

        const lateAppointmentDate = startTime ? getDiffTime(startTime) : 0;

        const lateCheckInDate = checkedInDate ? getDiffTime(checkedInDate) : 0;

        const lateAssessmentDate =
          modifiedDate && statusCode === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT
            ? getDiffTime(modifiedDate)
            : 0;

        const time = lateAssessmentDate || lateCheckInDate || lateAppointmentDate;

        const lateTime = time > 0 ? moment(time).format("HH:mm") : NA;

        // 900000 - 15 mins
        // 1800000 - 30 mins

        const isRed = time >= 1800000;
        const isGreen = time <= 900000;
        const isOrange = time > 900000 && time < 1800000;

        return (
          <div key={idx} className="late-appointment-table-row">
            <div className="late-appointment-cell late-appointment-id-cell">{idx + 1}</div>
            <div className="late-appointment-cell" style={{ justifyContent: "flex-start" }}>
              {chartNumber}
            </div>
            <div className="late-appointment-cell" style={{ justifyContent: "flex-start" }}>
              {dentalPoint}
            </div>
            <div className="late-appointment-cell" style={{ justifyContent: "flex-start" }}>
              {doctor}
            </div>
            <div
              className="late-appointment-cell"
              style={{ justifyContent: "flex-start", minWidth: "140px" }}
            >
              {status}
            </div>
            <div className="late-appointment-cell" style={{ minWidth: "140px" }}>
              {appointmentTime}
            </div>
            <div style={{ minWidth: "180px" }} className="late-appointment-cell">
              {checkedInTime}
            </div>
            <div style={{ minWidth: "180px" }} className="late-appointment-cell">
              {assessmentTime}
            </div>
            <div className="late-appointment-cell late-appointment-time-cell">{lateTime}</div>
            <div className="late-appointment-cell">
              <span
                style={{
                  color: isRed
                    ? defaultColors.Red.color
                    : isOrange
                    ? defaultColors.Yellow.color
                    : isGreen
                    ? defaultColors.Green.color
                    : "black",
                }}
              >
                {Boolean(
                  statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
                    statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2,
                ) && "Late Checkin"}
                {Boolean(
                  statusCode === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT ||
                    statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
                ) && "Late Start Visit"}
              </span>
            </div>
          </div>
        );
      });

      return (
        <AppLayout
          withLogo={true}
          rightComponent={
            <div
              role="button"
              title="Refresh"
              onClick={this.updateList}
              style={{ cursor: "pointer" }}
            >
              <FilledImage
                src={RefreshImage}
                color="#BEC844"
                zoom={70}
                className="no-response-zoom"
              />
            </div>
          }
        >
          <div className="late-appointment-info-container">
            <div className="late-appointment-info-row">
              <div className="late-appointment-info-cell">
                Total Appointments [Being Seen]:{" "}
                <span className="late-appointment-info-count">{totalBeingSeen}</span>
              </div>
              <div className="late-appointment-info-cell">
                Total Appointments [Assessment]:{" "}
                <span className="late-appointment-info-count">{totalAssessment}</span>
              </div>
            </div>
            <div className="late-appointment-info-row">
              <div className="late-appointment-info-cell">
                Total Appointments [Checked In]:{" "}
                <span className="late-appointment-info-count">{totalCheckedIn}</span>
              </div>
              <div className="late-appointment-info-cell" />
            </div>
          </div>

          <div className="late-appointment-table-header">
            <div className="late-appointment-cell late-appointment-header-cell late-appointment-id-cell">
              SN
            </div>
            <div className="late-appointment-cell late-appointment-header-cell">Chart Number</div>
            <div className="late-appointment-cell late-appointment-header-cell">Room</div>
            <div className="late-appointment-cell late-appointment-header-cell">Doctor</div>
            <div
              className="late-appointment-cell late-appointment-header-cell"
              style={{ minWidth: "140px" }}
            >
              Status
            </div>
            <div
              className="late-appointment-cell late-appointment-header-cell"
              style={{ minWidth: "140px" }}
            >
              Appointment Time
            </div>
            <div
              style={{ minWidth: "180px" }}
              className="late-appointment-cell late-appointment-header-cell"
            >
              Appointment Checked In
            </div>
            <div
              style={{ minWidth: "180px" }}
              className="late-appointment-cell late-appointment-header-cell"
            >
              Appointment Assessment
            </div>
            <div className="late-appointment-cell late-appointment-header-cell late-appointment-time-cell">
              LATE
            </div>
            <div className="late-appointment-cell late-appointment-header-cell">Late Status</div>
          </div>

          <div className="late-appointment-table-body">{list}</div>
        </AppLayout>
      );
    }
  },
);
