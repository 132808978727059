import React from "react";
import { connect } from "react-redux";

import DataList from "../../../widgets/DataList";
import ChartClinicalNoteCategoryItem from "../ChartListItem";

import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";

import {
  openList,
  openTextarea,
  popStack,
  resetClinicalNoteFor,
} from "../../../../actions/chartSideClinicalNoteActions";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(() => ({}), { openList, openTextarea, popStack, resetClinicalNoteFor });
export default enhancer(
  class ChartClinicalNoteOwned extends React.Component {
    openItem = (item) => {
      return () => {
        this.props.openList(item.id);
      };
    };

    openTextarea = () => {
      this.props.resetClinicalNoteFor();
      this.props.openTextarea(clinicNoteItemTypes.new);
    };

    render() {
      const { category } = this.props.chartSideClinicalNote;

      const $list = category.map((item, index) => (
        <ChartClinicalNoteCategoryItem key={index} item={item} openItem={this.openItem(item)} />
      ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader onBackClick={this.props.popStack} title="Add Clinical Notes" />
          <div className="chart-side-body">
            <div className="body">
              <div className="chart-clinical-note-category-title">Select from templates</div>
              <DataList>{$list}</DataList>
            </div>
            <div className="all-codes">
              <button onClick={this.openTextarea} className="btn btn-primary btn-block">
                Write New
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
