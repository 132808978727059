export const INIT_SIDE_CLINICAL_NOTE = "INIT_SIDE_CLINICAL_NOTE";
export const CHART_CLINICAL_NOTE_SET_PATIENT_KEY = "CHART_CLINICAL_NOTE_SET_PATIENT_KEY";
export const CLINICAL_NOTE_INIT_EDIT = "CLINICAL_NOTE_INIT_EDIT";
export const CLINICAL_NOTE_INIT_TEXTAREA = "CLINICAL_NOTE_INIT_TEXTAREA";

export const CLINICAL_NOTE_DOCTOR_ID = "CLINICAL_NOTE_DOCTOR_ID";

export const SELECT_SORT_FIELD_SIDE_CLINICAL_NOTE = "SELECT_SORT_FIELD_SIDE_CLINICAL_NOTE";
export const SELECT_CLINIC_FIELD_SIDE_CLINICAL_NOTE = "SELECT_CLINIC_FIELD_SIDE_CLINICAL_NOTE";
export const SIDE_CLINICAL_NOTE_FULL_SCREEN = "SIDE_CLINICAL_NOTE_FULL_SCREEN";

export const CHART_CLINICAL_NOTE_SEARCH_CHANGE = "CHART_CLINICAL_NOTE_SEARCH_CHANGE";

export const POP_CLINICAL_NOTE_STACK = "POP_CLINICAL_NOTE_STACK";
export const PUSH_CLINICAL_NOTE_STACK = "PUSH_CLINICAL_NOTE_STACK";
export const REPLACE_CLINICAL_NOTE_STACK = "REPLACE_CLINICAL_NOTE_STACK";

export const GET_CLINICAL_NOTE_OWNED_LIST_START = "GET_CLINICAL_NOTE_OWNED_LIST_START";
export const GET_CLINICAL_NOTE_OWNED_LIST_SUCCESS = "GET_CLINICAL_NOTE_OWNED_LIST_SUCCESS";
export const GET_CLINICAL_NOTE_OWNED_LIST_ERROR = "GET_CLINICAL_NOTE_OWNED_LIST_ERROR";

export const GET_REFERENCE_CLINICAL_NOTE_LIST_START = "GET_REFERENCE_CLINICAL_NOTE_LIST_START";
export const GET_REFERENCE_CLINICAL_NOTE_LIST_SUCCESS = "GET_REFERENCE_CLINICAL_NOTE_LIST_SUCCESS";
export const GET_REFERENCE_CLINICAL_NOTE_LIST_ERROR = "GET_REFERENCE_CLINICAL_NOTE_LIST_ERROR";

export const CLINICAL_NOTE_SET_ADDENDUM_ID = "CLINICAL_NOTE_SET_ADDENDUM_ID";

export const GET_CLINICAL_NOTE_CATEGORY_START = "GET_CLINICAL_NOTE_CATEGORY_START";
export const GET_CLINICAL_NOTE_CATEGORY_SUCCESS = "GET_CLINICAL_NOTE_CATEGORY_SUCCESS";
export const GET_CLINICAL_NOTE_CATEGORY_ERROR = "GET_CLINICAL_NOTE_CATEGORY_ERROR";

export const GET_CLINICAL_NOTE_TEMPLATE_LIST_START = "GET_CLINICAL_NOTE_TEMPLATE_LIST_START";
export const GET_CLINICAL_NOTE_TEMPLATE_LIST_SUCCESS = "GET_CLINICAL_NOTE_TEMPLATE_LIST_SUCCESS";
export const GET_CLINICAL_NOTE_TEMPLATE_LIST_ERROR = "GET_CLINICAL_NOTE_TEMPLATE_LIST_ERROR";

export const GET_CLINICAL_NOTE_ITEM_START = "GET_CLINICAL_NOTE_ITEM_START";
export const GET_CLINICAL_NOTE_ITEM_SUCCESS = "GET_CLINICAL_NOTE_ITEM_SUCCESS";
export const GET_CLINICAL_NOTE_ITEM_ERROR = "GET_CLINICAL_NOTE_ITEM_ERROR";

export const SAVE_CLINICAL_NOTE_ITEM_START = "SAVE_CLINICAL_NOTE_ITEM_START";
export const SAVE_CLINICAL_NOTE_ITEM_SUCCESS = "SAVE_CLINICAL_NOTE_ITEM_SUCCESS";
export const SAVE_CLINICAL_NOTE_ITEM_ERROR = "SAVE_CLINICAL_NOTE_ITEM_ERROR";

export const SAVE_CLINICAL_NOTE_ITEM_HIE_START = "SAVE_CLINICAL_NOTE_ITEM_HIE_START";
export const SAVE_CLINICAL_NOTE_ITEM_HIE_SUCCESS = "SAVE_CLINICAL_NOTE_ITEM_HIE_SUCCESS";
export const SAVE_CLINICAL_NOTE_ITEM_HIE_ERROR = "SAVE_CLINICAL_NOTE_ITEM_HIE_ERROR";

export const GET_CLINICAL_NOTE_EMPTY_START = "GET_CLINICAL_NOTE_EMPTY_START";
export const GET_CLINICAL_NOTE_EMPTY_SUCCESS = "GET_CLINICAL_NOTE_EMPTY_SUCCESS";
export const GET_CLINICAL_NOTE_EMPTY_ERROR = "GET_CLINICAL_NOTE_EMPTY_ERROR";

export const DELETE_CLINICAL_NOTE_ITEM_START = "DELETE_CLINICAL_NOTE_ITEM_START";
export const DELETE_CLINICAL_NOTE_ITEM_SUCCESS = "DELETE_CLINICAL_NOTE_ITEM_SUCCESS";
export const DELETE_CLINICAL_NOTE_ITEM_ERROR = "DELETE_CLINICAL_NOTE_ITEM_ERROR";

export const CHANGE_CLINICAL_NOTE_CREATED_DATE_START = "CHANGE_CLINICAL_NOTE_CREATED_DATE_START";
export const CHANGE_CLINICAL_NOTE_CREATED_DATE_SUCCESS =
  "CHANGE_CLINICAL_NOTE_CREATED_DATE_SUCCESS";
export const CHANGE_CLINICAL_NOTE_CREATED_DATE_ERROR = "CHANGE_CLINICAL_NOTE_CREATED_DATE_ERROR";

export const CLINICAL_NOTE_PREV_QUESTION = "CLINICAL_NOTE_PREV_QUESTION";
export const CLINICAL_NOTE_NEXT_QUESTION = "CLINICAL_NOTE_NEXT_QUESTION";

export const CLINICAL_NOTE_CHANGE_QUESTION_TEXTAREA = "CLINICAL_NOTE_CHANGE_QUESTION_TEXTAREA";
export const CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS =
  "CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS";
export const CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE =
  "CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE";
export const CLINICAL_NOTE_CHANGE_DATE_PICKER = "CLINICAL_NOTE_CHANGE_DATE_PICKER";
export const CLINICAL_NOTE_CHANGE_SURFACE = "CLINICAL_NOTE_CHANGE_SURFACE";
export const CLINICAL_NOTE_SELECT_TOOTH = "CLINICAL_NOTE_SELECT_TOOTH";
export const CLINICAL_NOTE_SET_NAME = "CLINICAL_NOTE_SET_NAME";
export const CLINICAL_NOTE_SET_TEXTAREA_BODY = "CLINICAL_NOTE_SET_TEXTAREA_BODY";

export const CLINICAL_NOTE_OPEN_DELETE_MODAL = "CLINICAL_NOTE_OPEN_DELETE_MODAL";
export const CLINICAL_NOTE_CLOSE_DELETE_MODAL = "CLINICAL_NOTE_CLOSE_DELETE_MODAL";

export const NOTE_DELETE_CONFIRM_MODAL_SHOW = "NOTE_DELETE_CONFIRM_MODAL_SHOW";
export const NOTE_DELETE_CONFIRM_MODAL_HIDE = "NOTE_DELETE_CONFIRM_MODAL_HIDE";

export const NODE_DELETE_ITEM_START = "NODE_DELETE_ITEM_START";
export const NODE_DELETE_ITEM_SUCCESS = "NODE_DELETE_ITEM_SUCCESS";
export const NODE_DELETE_ITEM_ERROR = "NODE_DELETE_ITEM_ERROR";

export const CLINICAL_NOTE_MULTI_QUESTIONS_OTHER_VALUE =
  "CLINICAL_NOTE_MULTI_QUESTIONS_OTHER_VALUE";

export const SET_CLINICAL_NOTE_END_FILTER_DATE = "SET_CLINICAL_NOTE_END_FILTER_DATE";
export const SET_CLINICAL_NOTE_START_FILTER_DATE = "SET_CLINICAL_NOTE_START_FILTER_DATE";
export const CLINICAL_NOTE_CHANGE_TAB = "CLINICAL_NOTE_CHANGE_TAB";

export const RESET_CLINICAL_NOTE_FOR = "RESET_CLINICAL_NOTE_FOR";

export const CHART_CLINICAL_NOTE_SET_QUESTIONS_MODAL_VISIBLE =
  "CHART_CLINICAL_NOTE_SET_QUESTIONS_MODAL_VISIBLE";
export const CHART_CLINICAL_NOTE_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE =
  "CHART_CLINICAL_NOTE_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE";
export const CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_MODAL_VISIBLE =
  "CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_MODAL_VISIBLE";
export const CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_EXISTING_MODAL_VISIBLE =
  "CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_EXISTING_MODAL_VISIBLE";

export const CHANGE_LAST_MODIFIED_DATE_FROM = "CHANGE_LAST_MODIFIED_DATE_FROM";
export const CHANGE_LAST_MODIFIED_DATE_TO = "CHANGE_LAST_MODIFIED_DATE_TO";
export const CLEAR_MULTI_QUESTIONS = "CLEAR_MULTI_QUESTIONS";

export const GET_CLINICAL_NOTE_QUESTION_LIST_START = "GET_CLINICAL_NOTE_QUESTION_LIST_START";
export const GET_CLINICAL_NOTE_QUESTION_LIST_SUCCESS = "GET_CLINICAL_NOTE_QUESTION_LIST_SUCCESS";
export const GET_CLINICAL_NOTE_QUESTION_LIST_ERROR = "GET_CLINICAL_NOTE_QUESTION_LIST_ERROR";

export const GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_START =
  "GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_START";
export const GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_SUCCESS =
  "GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_SUCCESS";
export const GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_ERROR =
  "GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_ERROR";

export const CHART_CLINICAL_NOTE_SET_TREATMENT_DIAGNOSIS_SELECTING =
  "CHART_CLINICAL_NOTE_SET_TREATMENT_DIAGNOSIS_SELECTING";

export const CHART_CLINICAL_NOTE_SET_SET_APPOINTMENT_TIME =
  "CHART_CLINICAL_NOTE_SET_SET_APPOINTMENT_TIME";

export const CHART_CLINICAL_NOTE_APPOINTMENT_VISIT_TIME =
  "CHART_CLINICAL_NOTE__APPOINTMENT_VISIT_TIME";

export const CLINICAL_NOTE_SELECT_QUESTION = "CLINICAL_NOTE_SELECT_QUESTION";
