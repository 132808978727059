import React from "react";
import { connect } from "react-redux";

import Back from "../widgets/Back";
import PatientMobileForm from "./PatientMobileForm";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import logoImage from "../../assets/images/clinic_logo.png";
import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import BackGroundImage from "../../assets/images/patients_new.jpg";
import { obtainImagePath } from "../../utils/ImageUtils";

const enhancer = connect(
  ({ session: { company } }) => ({ company }),
  bindActions({ patientRegisterActions }),
);
const PatientMobile = (props) => {
  const submit = (values) => {
    return props.patientRegisterActions.validate(values).then(({ response, transfer }) => {
      if (response.length === 0) {
        return props.history.push(
          createUrl(Routes.PatientDetailsEdit, {
            query: transfer,
          }),
        );
      }
      if (response.length === 1) {
        return props.history.push(Routes.PatientAddAnother);
      }
      return props.history.push(Routes.PatientChooseAccount);
    });
  };
  return (
    <div
      className="patient-mobile"
      style={{ backgroundImage: `url(${obtainImagePath(BackGroundImage)})` }}
    >
      <div className="patient-list-title clearfix">
        <Back className="pull-left" onClick={() => props.history.goBack()} />
        <img alt="" src={logoImage} className="logo" />
      </div>
      <div className="patient-mobile-body">
        <PatientMobileForm onSubmit={submit} />
      </div>
    </div>
  );
};
export default enhancer(PatientMobile);
