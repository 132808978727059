const transactionTypes = {
  trxPlan: {
    code: "trxPlan",
    buttonId: 1,
    edit: true,
  },
  planned: {
    code: "planned",
    buttonId: 3,
    edit: true,
  },
  completed: {
    code: "completed",
    buttonId: 4,
    completedDate: true,
    edit: true,
  },
  diagnosis: {
    code: "diagnosis",
    buttonId: 5,
    hideDiagnosis: true,
    hideData: true,
    diagnosis: true,
  },
  existing: {
    code: "existing",
    buttonId: 6,
    hideDiagnosis: true,
    completedDate: true,
  },
  products: {
    code: "products",
    buttonId: 2,
  },
};

export default transactionTypes;
