import React from "react";
import cx from "classnames";

export interface ChipProps {
  readonly text: string;
  readonly className?: string;
  readonly onDelete?: () => void;
}

export function Chip({ text, onDelete, className }: ChipProps) {
  return (
    <div
      role="button"
      onClick={onDelete}
      className={cx(className)}
      style={{
        border: "1px solid #cccccc",
        borderRadius: "4px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        padding: "4px 8px",
        alignItems: "center",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <span style={{ flex: 1, marginRight: "4px" }}>{text}</span>

      {!!onDelete && <div>×</div>}
    </div>
  );
}
