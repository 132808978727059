import _ from "lodash";
import React from "react";
import Show from "../../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { connect } from "react-redux";
import bindActions from "../../../../helpers/bindActions";
import * as chartActions from "../../../../actions/chartActions";
import { getCompanyFormatDate } from "../../../../helpers/DateUtils";
import { Button } from "react-bootstrap";

const enhancer = connect(({ session }) => ({ session }), bindActions({ chartActions }));
export default enhancer(
  class PackageDealsList extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        openedGroups: {},
      };
    }

    componentDidMount() {
      this.props.chartActions.getStopDealsList();
    }

    generateList = (procedureList) => {
      let list = [];

      _.forEach(procedureList, (item, index) => {
        const status = _.get(item, "status.code");

        list.push(
          <div
            className="select-treatment-list-row"
            key={index}
            style={{
              backgroundColor:
                status === "DentalTransactionStatus_PLANNED"
                  ? "#f9bfc2"
                  : status === "DentalTransactionStatus_COMPLETED"
                  ? "#b9fb94"
                  : "none",
            }}
          >
            <div className="item-date-posted">{getCompanyFormatDate(item.createdDate)}</div>
            <div className="item-treatment-code">
              {item.treatmentItem && item.treatmentItem.code}
            </div>
            <div className="item-description">{item.treatmentItem && item.treatmentItem.code}</div>
            <div className="item-tooth" />
            <div className="item-posted-by">{item.area.name}</div>
            <div className="item-posted-by">{item.price}</div>
            <div className="item-posted-by" />
          </div>,
        );
      });

      return list;
    };

    render() {
      const {
        clinicId,
        chart: { selectTreatmentToCompleteList, selectTreatmentToCompleteListFetching },
      } = this.props;

      const list = [];

      const filtredList = selectTreatmentToCompleteList.filter((x) => {
        const id = _.get(x, "clinic.id");

        return id === clinicId || clinicId === "all";
      });

      const procedureList = filtredList.reduce((groups, item) => {
        const procedureItem = _.get(item, "procedureItem");
        const procedureDate = _.get(item, "procedureKey");
        const createdDate = _.get(item, "createdDate");
        const procedureItemTooth = _.get(item, "tooth.name");
        const price = _.get(item, "price");

        if (procedureItem) {
          const current = _.get(groups, `item-${procedureDate}`, {
            list: [],
            price: 0,
          });
          const list = _.get(current, "list", []);

          list.push(item);

          groups[`item-${procedureDate}`] = {
            ...current,
            procedure: procedureItem,
            createdDate,
            type: "procedure",
            list,
            tooth: procedureItemTooth,
            price: current.price + _.toFinite(price),
          };
        } else {
          const current = _.get(groups, "none", { list: [], price: 0 });
          const list = _.get(current, "list", []);

          list.push(item);

          groups.none = {
            ...current,
            list,
            type: "procedure",
            tooth: procedureItemTooth,
            price: current.price + _.toFinite(price),
          };
        }

        return groups;
      }, {});

      _.forEach(procedureList, (item, key) => {
        const procedureList = _.get(item, "list");
        const createdDate = _.get(item, "createdDate");
        const procedure = _.get(item, "procedure");
        const price = _.get(item, "price");

        const procedureKeys = procedureList.map((x) => x.procedureKey);

        list.push(
          <div className="select-treatment-list-group-row">
            <div
              className="select-treatment-list-row group-title"
              onClick={() =>
                this.setState((state) => ({
                  openedGroups: {
                    ...state.openedGroups,
                    [key]: !state.openedGroups[key],
                  },
                }))
              }
            >
              <div className="item-date-posted">{getCompanyFormatDate(createdDate)}</div>
              <div className="item-treatment-code" />
              <div className="item-description">{procedure ? procedure.name : "Non procedure"}</div>
              <div className="item-tooth">{_.size(procedureList)}</div>
              <div className="item-posted-by" />
              <div className="item-posted-by">{price.toFixed(2)}</div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                className="item-posted-by"
              >
                <Button
                  onClick={() => this.props.chartActions.stopDeals(procedureKeys)}
                  type="button"
                  className="btn btn-primary"
                >
                  Stop
                </Button>
              </div>
            </div>
            {this.state.openedGroups[key] && this.generateList(procedureList)}
          </div>,
        );
      });

      return (
        <div className="select-treatment-list-table">
          <div className="select-treatment-list-header">
            <div className="item-date-posted">Date posted</div>
            <div className="item-treatment-code">Procedure Code</div>
            <div className="item-description">Procedure Name</div>
            <div className="item-tooth">Qty</div>
            <div className="item-posted-by">Area</div>
            <div className="item-posted-by">Total Price</div>
            <div className="item-posted-by">{/*Button*/}</div>
          </div>
          <div className="select-treatment-list-body">
            {list}
            <Show if={selectTreatmentToCompleteListFetching}>
              <div className="select-treatment-list-row">
                <ReactSpinner />
              </div>
            </Show>
          </div>
        </div>
      );
    }
  },
);
