export const INIT_SIDE_MEDICAL_FILL_IN_FORM = "INIT_SIDE_MEDICAL_FILL_IN_FORM";
export const MEDICAL_FILL_IN_FORM_INIT_EDIT = "MEDICAL_FILL_IN_FORM_INIT_EDIT";

export const SELECT_SORT_FIELD_SIDE_MEDICAL_FILL_IN_FORM =
  "SELECT_SORT_FIELD_SIDE_MEDICAL_FILL_IN_FORM";

export const CHART_MEDICAL_FILL_IN_FORM_SEARCH_CHANGE = "CHART_MEDICAL_FILL_IN_FORM_SEARCH_CHANGE";

export const POP_MEDICAL_FILL_IN_FORM_STACK = "POP_MEDICAL_FILL_IN_FORM_STACK";
export const PUSH_MEDICAL_FILL_IN_FORM_STACK = "PUSH_MEDICAL_FILL_IN_FORM_STACK";
export const CLEAR_MEDICAL_FILL_IN_FORM_STACK = "CLEAR_MEDICAL_FILL_IN_FORM_STACK";
export const UPDATE_MEDICAL_FILL_IN_FORM_STACK = "UPDATE_MEDICAL_FILL_IN_FORM_STACK";

export const GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_ERROR";

export const GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_START =
  "GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_START";
export const GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_SUCCESS =
  "GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_SUCCESS";
export const GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_ERROR =
  "GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_ERROR";

export const RESET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST = "RESET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST";

export const GET_MEDICAL_FILL_IN_FORM_ITEM_START = "GET_MEDICAL_FILL_IN_FORM_ITEM_START";
export const GET_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS = "GET_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_ITEM_ERROR = "GET_MEDICAL_FILL_IN_FORM_ITEM_ERROR";

export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_START = "SAVE_MEDICAL_FILL_IN_FORM_ITEM_START";
export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS = "SAVE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS";
export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_ERROR = "SAVE_MEDICAL_FILL_IN_FORM_ITEM_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_EMPTY_START = "GET_MEDICAL_FILL_IN_FORM_EMPTY_START";
export const GET_MEDICAL_FILL_IN_FORM_EMPTY_SUCCESS = "GET_MEDICAL_FILL_IN_FORM_EMPTY_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_EMPTY_ERROR = "GET_MEDICAL_FILL_IN_FORM_EMPTY_ERROR";

export const DELETE_MEDICAL_FILL_IN_FORM_ITEM_START = "DELETE_MEDICAL_FILL_IN_FORM_ITEM_START";
export const DELETE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS = "DELETE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS";
export const DELETE_MEDICAL_FILL_IN_FORM_ITEM_ERROR = "DELETE_MEDICAL_FILL_IN_FORM_ITEM_ERROR";

export const CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_START =
  "CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_START";
export const CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_SUCCESS =
  "CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_SUCCESS";
export const CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_ERROR =
  "CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_ERROR";

export const MEDICAL_FILL_IN_FORM_PREV_QUESTION = "MEDICAL_FILL_IN_FORM_PREV_QUESTION";
export const MEDICAL_FILL_IN_FORM_NEXT_QUESTION = "MEDICAL_FILL_IN_FORM_NEXT_QUESTION";
export const MEDICAL_FILL_IN_FORM_SELECT_QUESTION = "MEDICAL_FILL_IN_FORM_SELECT_QUESTION";

export const MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA =
  "MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA";
export const MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS =
  "MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS";
export const MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE =
  "MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE";
export const MEDICAL_FILL_IN_FORM_CHANGE_DATE_PICKER = "MEDICAL_FILL_IN_FORM_CHANGE_DATE_PICKER";
export const MEDICAL_FILL_IN_FORM_CHANGE_SURFACE = "MEDICAL_FILL_IN_FORM_CHANGE_SURFACE";
export const MEDICAL_FILL_IN_FORM_SELECT_TOOTH = "MEDICAL_FILL_IN_FORM_SELECT_TOOTH";
export const MEDICAL_FILL_IN_FORM_SET_NAME = "MEDICAL_FILL_IN_FORM_SET_NAME";
export const MEDICAL_FILL_IN_FORM_SET_TEXTAREA_BODY = "MEDICAL_FILL_IN_FORM_SET_TEXTAREA_BODY";

export const MEDICAL_FILL_IN_FORM_OPEN_DELETE_MODAL = "MEDICAL_FILL_IN_FORM_OPEN_DELETE_MODAL";
export const MEDICAL_FILL_IN_FORM_CLOSE_DELETE_MODAL = "MEDICAL_FILL_IN_FORM_CLOSE_DELETE_MODAL";

export const MEDICAL_FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE =
  "MEDICAL_FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE";

export const MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_CANVAS =
  "MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_CANVAS";

export const MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_START =
  "MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_START";
export const MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_SUCCESS =
  "MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_SUCCESS";
export const MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_ERROR =
  "MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_ERROR";

export const MEDICAL_FILL_IN_FORM_SET_ADDENDUM_ID = "MEDICAL_FILL_IN_FORM_SET_ADDENDUM_ID";
export const MEDICAL_FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE =
  "MEDICAL_FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE";
export const MEDICAL_FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE =
  "MEDICAL_FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE";
export const MEDICAL_FILL_IN_FORM_INIT_TEXTAREA = "MEDICAL_FILL_IN_FORM_INIT_TEXTAREA";
export const MEDICAL_FILL_IN_FORM_FULL_SCREEN = "MEDICAL_FILL_IN_FORM_FULL_SCREEN";
export const RESET_MEDICAL_FILL_IN_FORM_FOR = "RESET_MEDICAL_FILL_IN_FORM_FOR";
export const GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_START =
  "GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_START";
export const GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_SUCCESS =
  "GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_SUCCESS";
export const GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_ERROR =
  "GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_ERROR";
export const GET_MEDICAL_FILL_IN_FORM_TAB_LIST_START = "GET_MEDICAL_FILL_IN_FORM_TAB_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_TAB_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_TAB_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_TAB_LIST_ERROR = "GET_MEDICAL_FILL_IN_FORM_TAB_LIST_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_START =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_START";
export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_ERROR";

export const GET_CONSULTATION_TEMPLATE_LIST_START = "GET_CONSULTATION_TEMPLATE_LIST_START";
export const GET_CONSULTATION_TEMPLATE_LIST_SUCCESS = "GET_CONSULTATION_TEMPLATE_LIST_SUCCESS";
export const GET_CONSULTATION_TEMPLATE_LIST_ERROR = "GET_CONSULTATION_TEMPLATE_LIST_ERROR";

export const GET_CONSULTATION_TEMPLATE_ITEM_START = "GET_CONSULTATION_TEMPLATE_ITEM_START";
export const GET_CONSULTATION_TEMPLATE_ITEM_SUCCESS = "GET_CONSULTATION_TEMPLATE_ITEM_SUCCESS";
export const GET_CONSULTATION_TEMPLATE_ITEM_ERROR = "GET_CONSULTATION_TEMPLATE_ITEM_ERROR";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_CONSULTATION_ID = "SET_CONSULTATION_ID";
export const CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS = "CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS";
export const RESET_MEDICAL_FILL_IN_FORM_CONSULTATION_ID =
  "RESET_MEDICAL_FILL_IN_FORM_CONSULTATION_ID";
export const MEDICAL_FILL_IN_FORM_SHOW_SIDE_BAR = "MEDICAL_FILL_IN_FORM_SHOW_SIDE_BAR";
export const MEDICAL_FILL_IN_FORM_HIDE_SIDE_BAR = "MEDICAL_FILL_IN_FORM_HIDE_SIDE_BAR";

export const MEDICAL_FILL_IN_FORM_TOGGLE_MENU = "MEDICAL_FILL_IN_FORM_TOGGLE_MENU";

export const GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_ERROR";

export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_START =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_START";
export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_SUCCESS =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_SUCCESS";
export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_ERROR =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_ERROR";

export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_START =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_START";
export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_SUCCESS =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_SUCCESS";
export const UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_ERROR =
  "UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_ERROR";

export const SET_CONSENT_FORM_VIEW_DETAILS = "SET_CONSENT_FORM_VIEW_DETAILS";

export const GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_START =
  "GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_START";
export const GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_ERROR";

export const DISABLE_ALL_TABS_EXPECT_INITIAL_EXAMINATION_TOGGLE =
  "DISABLE_ALL_TABS_EXPECT_INITIAL_EXAMINATION_TOGGLE";
export const MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_START =
  "MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_START";
export const MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_SUCCESS =
  "MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_SUCCESS";
export const MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_ERROR =
  "MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START =
  "GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START";
export const GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS";
export const GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR";

export const SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START =
  "SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START";
export const SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS =
  "SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS";
export const SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR =
  "SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR";

export const GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START =
  "GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START";
export const GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_SUCCESS =
  "GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_SUCCESS";
export const GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START_ERROR =
  "GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START_ERROR";

export const SET_DATA_TO_FILL = "SET_DATA_TO_FILL";
export const IS_INITIAL_EXAMINATION_DISABLE_TOGGLE = "IS_INITIAL_EXAMINATION_DISABLE_TOGGLE";
export const OPEN_TEXT_AREA = "OPEN_TEXT_AREA";

export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_START =
  "SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_START";

export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_SUCCESS =
  "SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_SUCCESS";

export const SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_ERROR =
  "SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_ERROR";

export const GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_START";

export const GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_SUCCESS";

export const GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_ERROR";

export const SET_ADDENDUM_ITEM = "SET_ADDENDUM_ITEM";

export const GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_START =
  "GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_START";
export const GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_SUCCESS =
  "GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_SUCCESS";

export const GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_ERROR =
  "GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_ERROR";

export const MEDICAL_FILL_IN_FORM_QUESTIONS_MODAL_VISIBLE =
  "MEDICAL_FILL_IN_FORM_QUESTIONS_MODAL_VISIBLE";

export const MEDICAL_FILL_IN_FORM_TEXT_AREA_VALUE = "MEDICAL_FILL_IN_FORM_TEXT_AREA_VALUE";

export const SET_MEDICAL_FILL_IN_FORM_VITAL_VALUE_TO_EDIT =
  "SET_MEDICAL_FILL_IN_FORM_VITAL_VALUE_TO_EDIT";

export const CLEAR_MEDICAL_FILL_IN_FORM_VITAL_VALUES = "CLEAR_MEDICAL_FILL_IN_FORM_VITAL_VALUES";
export const MEDICAL_FILL_IN_FORM_MEDICAL_TYPE_FILTER = "MEDICAL_FILL_IN_FORM_MEDICAL_TYPE_FILTER";
