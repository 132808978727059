import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import PatientDetailsForm from "./PatientDetailsForm";
import PatientDetailsHousehold from "./PatientDetailsHousehold";
import PatientDetailsSignature from "./PatientDetailsSignature";

import Utils from "../../helpers/Utils";

import Show from "../widgets/Show";
import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { parseQuery } from "../../utils/UrlUtils";
import { formatPhoneToObject } from "../../utils/FormatUtils";
import { getAppointmentById } from "../../actions/appointmentActions";

const enhancer = connect(
  ({ patientRegister, session }) => ({ patientRegister, session }),
  bindActions({ patientRegisterActions, getAppointmentById }),
);
class PatientDetailsEdit extends React.Component {
  UNSAFE_componentWillMount() {
    const query = parseQuery(this.props.location.search);
    this.props.patientRegisterActions.getDetailsEdit(query.patientKey, query.companyTempPatientId);
    if (query.appointmentId) {
      this.props.getAppointmentById(query.appointmentId);
    }
  }

  render() {
    const query = parseQuery(this.props.location.search);

    const {
      personalDetails: { patient, customFieldSettings = [] },
      patientDetails,
      pcpRoleTypes,
    } = this.props.patientRegister;

    let initialValues;

    const companyId = _.get(this.props, "session.company.id");

    const customFieldValues = _.get(patient, "customFieldValues", []);

    const genderId = _.toFinite(_.get(query, "gender"));
    const defaultProviderRole = (pcpRoleTypes || []).find(
      (item) => item.code.toUpperCase() === "PP",
    );

    if (query.patientKey) {
      initialValues = {
        patientKey: query.patientKey,
        lastName: patient.lastName,
        firstName: patient.firstName,
        middleName: patient.middleName,
        nationality: patient.nationality && patient.nationality.id,
        gender: patient.gender && patient.gender.id,
        smsLanguage: patient.smsLanguage ? patient.smsLanguage.id : -1,
        birthDate: patient.dateOfBirth && moment(Utils.toComputerTimezone(patient.dateOfBirth)),
        maritalStatus: patient.maritalStatus && patient.maritalStatus.id,
        studentStatus: patient.student ? 1 : 0,
        vipPatient: patient.vipPatient || false,
        weight: patient.weight,
        weightUnit: 1,
        workplace: patient.workPlace,
        occupation: patient.occupation,
        referral: patient.referral,
        referralType: patient.referralType && patient.referralType.id,
        title: patient.title,
        postCode: patient.address && patient.address.postcode,
        street: patient.address && patient.address.line1,
        street2: patient.address && patient.address.line2,
        country: patient.address && patient.address.country && patient.address.country.id,
        state: patient.address && patient.address.state && patient.address.state.id,
        city: patient.address &&
          patient.address.town && {
            label: patient.address.town.name,
            value: patient.address.town.id,
          },

        // TODO: not yet ready for production
        relationshipType: patient.primaryCareProvider &&
          patient.primaryCareProvider.relationshipType &&
          patient.primaryCareProvider.relationshipType.code && {
            label: patient.primaryCareProvider.relationshipType.name,
            value: patient.primaryCareProvider.relationshipType.code,
          },
        providerRoleType:
          (patient.primaryCareProvider &&
            patient.primaryCareProvider.providerRoleType &&
            patient.primaryCareProvider.providerRoleType.code && {
              label: patient.primaryCareProvider.providerRoleType.name,
              value: patient.primaryCareProvider.providerRoleType.code,
            }) ||
          (defaultProviderRole &&
            defaultProviderRole.code && {
              label: defaultProviderRole.name,
              value: defaultProviderRole.code,
            }),
        rolePerson: patient.primaryCareProvider &&
          patient.primaryCareProvider.rolePerson &&
          patient.primaryCareProvider.rolePerson.id && {
            label: patient.primaryCareProvider.rolePerson.name,
            id: patient.primaryCareProvider.rolePerson.id,
          },
        roleBeginDate:
          patient.primaryCareProvider &&
          patient.primaryCareProvider.roleBeginDate &&
          moment(Utils.calcDateWithTimeZone(patient.primaryCareProvider.roleBeginDate)),
        roleEndDate:
          patient.primaryCareProvider &&
          patient.primaryCareProvider.roleEndDate &&
          moment(Utils.calcDateWithTimeZone(patient.primaryCareProvider.roleEndDate)),

        customFieldSettings: [],

        homePhone: formatPhoneToObject(patient.homePhoneNumber),
        workPhone: formatPhoneToObject(patient.workPhoneNumber),
        mobilePhone: formatPhoneToObject(patient.mobilePhoneNumber),

        additionalInformation: _.get(patient, "additionalInformation"),

        email: patient.email,

        socialSecurity: patient.documentSocialSecurityNumber,
        driversLicence: patient.documentDriverLicenseNumber,
        socialInsurance: patient.documentSocialInsuranceNumber,
        passport: patient.documentPasswordNumber,
        nationalId: patient.documentNationalIdNumber,
        nationalIdNotAvailable: patient.nationalIdNotAvailable,
        internalFileNumber: patient.internalFileNumber,
        profissionType: patient?.profissionType?.id || null,

        emergencyLastName:
          patient.emergencyContacts &&
          patient.emergencyContacts[0] &&
          patient.emergencyContacts[0].lastName,
        emergencyFirstName:
          patient.emergencyContacts &&
          patient.emergencyContacts[0] &&
          patient.emergencyContacts[0].firstName,
        emergencyRelationship:
          patient.emergencyContacts &&
          patient.emergencyContacts[0] &&
          patient.emergencyContacts[0].relationship,
        emergencyHomePhone:
          patient.emergencyContacts &&
          patient.emergencyContacts[0] &&
          patient.emergencyContacts[0].homePhoneNumber,
        emergencyMobilePhone:
          patient.emergencyContacts &&
          patient.emergencyContacts[0] &&
          patient.emergencyContacts[0].mobilePhoneNumber,

        emergencyLastName2:
          patient.emergencyContacts &&
          patient.emergencyContacts[1] &&
          patient.emergencyContacts[1].lastName,
        emergencyFirstName2:
          patient.emergencyContacts &&
          patient.emergencyContacts[1] &&
          patient.emergencyContacts[1].firstName,
        emergencyRelationship2:
          patient.emergencyContacts &&
          patient.emergencyContacts[1] &&
          patient.emergencyContacts[1].relationship,
        emergencyHomePhone2:
          patient.emergencyContacts &&
          patient.emergencyContacts[1] &&
          patient.emergencyContacts[1].homePhoneNumber,
        emergencyMobilePhone2:
          patient.emergencyContacts &&
          patient.emergencyContacts[1] &&
          patient.emergencyContacts[1].mobilePhoneNumber,
      };
      if (customFieldSettings.length) {
        const fields = {};
        customFieldSettings.forEach((item) => {
          fields[item.code] = item;
          fields[item.code].value =
            customFieldValues.find((x) => item.code === x.code)?.value || "";
        });
        initialValues.customFieldSettings.push(fields);
      }

      if (patient.referralType && patient.referralType.code === "REFERRAL_TYPE_OTHER") {
        initialValues.referralType = 0;
        initialValues.referralTypeDescription = patient.referralType.description;
      }
    } else if (query.companyTempPatientId) {
      initialValues = {
        lastName: patientDetails.lastName,
        firstName: patientDetails.firstName,
        email: patientDetails.email,
        mobilePhone: Utils.toPhoneObject(patientDetails.mobilePhoneNumber),
      };
    } else {
      initialValues = {
        email: query.email,
        lastName: query.lastName,
        firstName: query.firstName,
        gender: genderId ? { value: genderId, label: genderId === 1 ? "Male" : "Female" } : null,
        birthDate: query.dateOfBirth && moment(Utils.toComputerTimezone(query.dateOfBirth)),
        mobilePhone: Utils.registerToPhoneObject(query.phoneNumber),
        studentStatus: 0,
        vipPatient: false,
        weightUnit: 1,
        smsLanguage: -1,
        weight: companyId === 163 ? "NOT REQUIRED" : "",
        workplace: companyId === 163 ? "NOT REQUIRED" : "",
        occupation: companyId === 163 ? "NOT REQUIRED" : "",
        postCode: companyId === 163 ? "NOT REQUIRED" : "",
        street: companyId === 163 ? "NOT REQUIRED" : "",
        street2: companyId === 163 ? "NOT REQUIRED" : "",
        socialSecurity: companyId === 163 ? "NOT REQUIRED" : "",
        driversLicense: companyId === 163 ? "NOT REQUIRED" : "",
        socialInsurance: companyId === 163 ? "NOT REQUIRED" : "",
        passport: companyId === 163 ? "NOT REQUIRED" : "",
        internalFileNumber: companyId === 163 ? "NOT REQUIRED" : "",

        emergencyRelationship: companyId === 163 ? "NOT REQUIRED" : "",
        emergencyRelationship2: companyId === 163 ? "NOT REQUIRED" : "",

        customFieldSettings: [],
      };
      if (customFieldSettings.length) {
        const fields = {};
        customFieldSettings.forEach((item) => {
          fields[item.code] = item;
          fields[item.code].value = "";
        });
        initialValues.customFieldSettings.push(fields);
      }
    }

    const companyTempPatientId = _.get(query, "companyTempPatientId");
    const companyTempPatientId2 = _.get(query, "tempId");

    return (
      <PatientDetailsForm
        initialValues={initialValues}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        clinic={this.props.session.clinic}
        companyId={_.get(this.props, "session.company.id")}
        patientRegister={this.props.patientRegister}
        tempId={companyTempPatientId}
        tempId2={companyTempPatientId2}
        isTemp={Boolean(companyTempPatientId)}
      >
        <Show if={this.props.patientRegister.houseHoldModalVisible}>
          <PatientDetailsHousehold patientRegister={this.props.patientRegister} />
        </Show>
        <Show if={this.props.patientRegister.signatureModalVisible}>
          <PatientDetailsSignature patientRegister={this.props.patientRegister} />
        </Show>
      </PatientDetailsForm>
    );
  }
}
export default enhancer(PatientDetailsEdit);
