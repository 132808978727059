import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChartFillInFormOwned from "./ChartFillInFormOwned";
import ChartFillInFormList from "./ChartFillInFormList";
import ChartFillInFormItemView from "./ChartFillInFormItemView";
import ChartFillInFormItemEdit from "./ChartFillInFormItemEdit";

import fillInFormItemTypes from "../../../../constants/chart-sidebar/fillInFormItemTypes";
import { fillInFormStackCodes } from "../../../../constants/chart-sidebar/fillInFormStackCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import ChartFillInFormTextarea from "./ChartFillInFormTextarea";

const enhancer = connect(null, bindActions({ chartSideFillInFormActions }));

class ChartFillInForm extends React.Component {
  componentDidMount() {
    this.props.chartSideFillInFormActions.getGroupList();
    this.props.chartSideFillInFormActions.initSideFillInForm();
  }

  render() {
    const {
      chartSideFillInForm,
      member,
      fromAppointment,
      hideSideBar,
      clinic,
      location,
      history,
      patientKey,
    } = this.props;
    const { stack } = chartSideFillInForm;

    const stackItem = _.last(stack);

    if (fromAppointment) {
      if (stackItem.code === fillInFormStackCodes.owned) {
        return (
          <ChartFillInFormOwned
            location={location}
            patientKey={patientKey}
            hideSideBar={hideSideBar}
            fromAppointment={fromAppointment}
            chartSideFillInForm={chartSideFillInForm}
          />
        );
      }
    } else {
      if (stackItem.code === fillInFormStackCodes.owned) {
        return (
          <ChartFillInFormOwned
            history={history}
            location={location}
            patientKey={patientKey}
            chartSideFillInForm={chartSideFillInForm}
          />
        );
      }
    }

    if (stackItem.code === fillInFormStackCodes.list) {
      return (
        <ChartFillInFormList
          history={history}
          patientKey={patientKey}
          location={location}
          chartSideFillInForm={chartSideFillInForm}
        />
      );
    }

    if (stackItem.code === fillInFormStackCodes.item) {
      if (stackItem?.type === fillInFormItemTypes.view) {
        return (
          <ChartFillInFormItemView member={member} chartSideFillInForm={chartSideFillInForm} />
        );
      }

      if (stackItem?.type === fillInFormItemTypes.edit) {
        return (
          <ChartFillInFormItemEdit
            edit={true}
            location={location}
            chartSideFillInForm={chartSideFillInForm}
            clinic={clinic}
            history={history}
            patientKey={patientKey}
          />
        );
      }

      if (stackItem?.type === fillInFormItemTypes.new) {
        return (
          <ChartFillInFormItemEdit
            chartSideFillInForm={chartSideFillInForm}
            clinic={clinic}
            history={history}
            location={location}
            patientKey={patientKey}
          />
        );
      }
    }
    if (stackItem.code === fillInFormStackCodes.textarea) {
      if (stackItem?.type === fillInFormItemTypes.edit) {
        return <ChartFillInFormTextarea edit={true} chartSideFillInForm={chartSideFillInForm} />;
      }

      if (stackItem?.type === fillInFormItemTypes.new) {
        return <ChartFillInFormTextarea chartSideFillInForm={chartSideFillInForm} />;
      }
    }
  }
}

export default enhancer(ChartFillInForm);
