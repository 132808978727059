import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import FlexBox from "../../ui/FlexBox";
import Header from "../../layout/DefaultPageHeader";
import SaveAndCloseModal from "./SaveAndCloseModal";
import TemporaryPatientForm from "./TemporaryPatientForm";

import { MASTER_TYPES } from "../../../constants/MasterTypes";

import {
  choosePatientNewScreen,
  closeReasonListModal,
  getMasterType,
  initFirstContact,
  newTemporaryPatient,
  openReasonListModal,
  saveCallReason,
  searchTemporaryPatient,
  searchTemporaryPatientResultHide,
  setConsultationNote,
} from "../../../actions/appointmentActions";
import { withRouter } from "react-router";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";

const enhancer = connect(
  ({
    appointment: {
      masterTypes,
      temporaryResult,
      consultationNote,
      temporaryPatient,
      temporaryPatientNew,
      temporaryReasonList,
      temporaryReasonShow,
    },
    session: { company },
  }) => ({
    company,
    masterTypes,
    temporaryResult,
    consultationNote,
    temporaryPatient,
    temporaryPatientNew,
    temporaryReasonList,
    temporaryReasonShow,
  }),
  {
    getMasterType,
    initFirstContact,
    newTemporaryPatient,
    setConsultationNote,
    choosePatientNewScreen,
    searchTemporaryPatient,
    searchTemporaryPatientResultHide,
    openReasonListModal,
    closeReasonListModal,
    saveCallReason,
  },
);

class NewPatientScreen extends React.PureComponent {
  componentDidMount() {
    this.props.initFirstContact();
    this.props.getMasterType();
  }

  phoneConsultHandler = () => {
    const { masterTypes } = this.props;
    const type =
      _.filter(masterTypes, (item) => item.code === MASTER_TYPES.PHONE_CONSULTATION) || [];

    this.props.newTemporaryPatient();

    this.props.history.push(
      createUrl(Routes.DashboardAppointment, {
        params: {
          id: 0,
        },
        query: {
          masterType: (type.length > 0 && type[0].id) || "",
          fromFirstContact: true,
        },
      }),
    );
  };

  newAppointmentHandler = () => {
    const { masterTypes } = this.props;
    const type = _.filter(masterTypes, (item) => item.code === MASTER_TYPES.NEW) || [];

    this.props.newTemporaryPatient();

    this.props.history.push(
      createUrl(Routes.DashboardAppointment, {
        params: {
          id: 0,
        },
        query: {
          masterType: (type.length > 0 && type[0].id) || "",
          fromFirstContact: true,
        },
      }),
    );
  };

  selectPatient = (patient) => {
    // if(patient.patientKey) {
    this.props.choosePatientNewScreen(patient);
    if (patient.companyTempPatientId) {
      this.props.history.push(
        createUrl(Routes.DashboardAppointment, {
          params: {
            id: 0,
          },
          query: {
            fromFirstContact: true,
          },
        }),
      );
    } else if (patient.patientKey) {
      this.props.history.push(
        createUrl(Routes.DashboardAppointment, {
          params: {
            id: 0,
          },
          query: {
            patientKey: patient.patientKey,
          },
        }),
      );
    }
    // } else if(patient.companyTempPatientId) {
    //   this.props.newTemporaryPatient(patient);
    // }
  };

  render() {
    return (
      <div className="new-patient-screen">
        <Header addClass="calendar-header mt-1" />
        <div className="new-screen-body">
          <div className="new-screen-left">
            <TemporaryPatientForm
              addPatient={this.props.newTemporaryPatient}
              onSubmit={this.props.searchTemporaryPatient}
              temporaryResult={this.props.temporaryResult}
              temporaryPatient={this.props.temporaryPatient}
              selectPatient={this.selectPatient}
              searchTemporaryPatientResultHide={this.props.searchTemporaryPatientResultHide}
            />
          </div>
          <div className="new-screen-right">
            {this.props.temporaryPatientNew && (
              <FlexBox flex={true} container={8} direction="column" className="margin-top-8">
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <label>
                      <span>Client Concern:</span>
                    </label>
                    <textarea
                      rows={6}
                      name="appointmentNotes"
                      value={this.props.consultationNote}
                      onChange={this.props.setConsultationNote}
                      className="form-control input-lg"
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={8} flex={true} className="margin-top-10">
                  <FlexBox>
                    <button className="btn btn-primary" onClick={this.phoneConsultHandler}>
                      Phone Consult
                    </button>
                  </FlexBox>
                  <FlexBox>
                    <button className="btn btn-primary" onClick={this.newAppointmentHandler}>
                      New Appointment
                    </button>
                  </FlexBox>
                  <FlexBox>
                    <button className="btn btn-primary" onClick={this.props.openReasonListModal}>
                      Save & close
                    </button>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </div>
        </div>
        <SaveAndCloseModal
          show={this.props.temporaryReasonShow}
          onSubmit={() => {
            this.props.saveCallReason.then((response) => {
              if (response && response.data) {
                this.props.history.push(Routes.DashboardCalendar);
              }
            });
          }}
          reasonList={this.props.temporaryReasonList}
          closeModal={this.props.closeReasonListModal}
        />
      </div>
    );
  }
}

export default withRouter(enhancer(NewPatientScreen));
