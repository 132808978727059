import WaitingListApi from "../api-legacy/WaitingListApi";
import * as ActionType from "../actionTypes/recallListActionTypes";

import { startLoader, stopLoader } from "./loaderActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import { SET_MISSED_CANCELLED_LIST_FORM_VALUES } from "../actionTypes/waitingListActionTypes";

export function getRecallList(values, start) {
  return function (dispatch, getState) {
    const {
      auth: { doctors },
      recallList: { fromDate, toDate, listTotal, list, loading },
    } = getState();
    if ((!start === 0 && list.length === listTotal) || loading) {
      return;
    }

    dispatch(startLoader());

    const data = {
      createdSourceCode: "FROM_FOLLOW_UP",
      limit: 50,
      withTotalCount: true,
      doctorIds:
        doctors
          .filter((x) => x.active)
          .map((x) => x.id)
          .join(",") || undefined,
    };
    if (values) {
      data.clinicId = values.clinicId;
      data.noAnswer = values.noAnswer;
      data.willCall = values.willCall;
      data.requestedReschedule = values.requestedReschedule;
      data.notInterested = values.notInterested;
      data.periodStart = getCompanyStartOfDay(values.periodStart);
      data.periodEnd = getCompanyEndOfDay(values.periodEnd);
      data.start = start === 0 ? start : list.length;

      data.categoryIds = values.categoryIds.map((v) => v.value).join(",") || undefined;
      dispatch({
        type: SET_MISSED_CANCELLED_LIST_FORM_VALUES,
        values,
      });
    } else {
      const {
        waitingList: { missedCancelledFormValues },
      } = getState();
      data.clinicId = missedCancelledFormValues?.clinic?.value;
      data.periodStart = getCompanyStartOfDay(fromDate);
      data.periodEnd = getCompanyEndOfDay(toDate);
      data.start = list.length;
      data.doctorIds =
        (missedCancelledFormValues.doctorIds || [])
          .filter((x) => x.value !== 0)
          .map((x) => x.value)
          .join(",") || undefined;
    }

    dispatch(getRecallListApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function setFromDate(data) {
  return {
    type: ActionType.SET_RECALL_LIST_FROM_DATE,
    date: data,
  };
}

export function setToDate(data) {
  return {
    type: ActionType.SET_RECALL_LIST_TO_DATE,
    date: data,
  };
}

function getRecallListApi(data) {
  return {
    api: WaitingListApi.getList,
    types: [
      ActionType.GET_RECALL_LIST_START,
      ActionType.GET_RECALL_LIST_SUCCESS,
      ActionType.GET_RECALL_LIST_ERROR,
    ],
    data,
  };
}
