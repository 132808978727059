import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChartClinicalNoteOwned from "./ChartClinicalNoteOwned";
import ChartClinicalNoteCategory from "./ChartClinicalNoteCategory";
import ChartClinicalNoteList from "./ChartClinicalNoteList";
import ChartClinicalNoteItemView from "./ChartClinicalNoteItemView";
import ChartClinicalNoteItemEdit from "./ChartClinicalNoteItemEdit";
import ChartClinicalNoteTextarea from "./ChartClinicalNoteTextarea";
import ChartClinicalNotePrint from "./ChartClinicalNotePrint";

import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";
import { ClinicalNoteStackCodes } from "../../../../constants/chart-sidebar/clinicalNoteStackCodes";

import {
  initSideClinicalNote,
  setAddendumId,
} from "../../../../actions/chartSideClinicalNoteActions";

const enhancer = connect(
  ({ appointment: { appointment } }) => ({
    appointmentId: _.get(appointment, "id"),
  }),
  { initSideClinicalNote, setAddendumId },
);

export default enhancer(
  class ChartClinicalNote extends React.Component {
    componentDidMount() {
      if (this.props.chartSideSeries && this.props.chartSideSeries.documentsAssignedModalVisible)
        return;

      if (this.props.chart && this.props.chart.selectTreatmentToCompleteNoteModalVisible) return;
      if (this.props.chart && this.props.chart.selectTreatmentToCompleteModalVisible) return;

      this.props.initSideClinicalNote(this.props.onAppointment, this.props.onCallList);

      this.props.setAddendumId(0);
    }

    render() {
      const {
        chartSideClinicalNote,
        toothNumericType,
        teeth,
        member,
        permissions,
        chart,
        clinic,
        viewType,
      } = this.props;
      const { stack } = chartSideClinicalNote;

      const stackItem = _.last(stack);

      if (stackItem.code === ClinicalNoteStackCodes.owned) {
        return <ChartClinicalNoteOwned chartSideClinicalNote={chartSideClinicalNote} />;
      }

      if (stackItem.code === ClinicalNoteStackCodes.category) {
        return <ChartClinicalNoteCategory chartSideClinicalNote={chartSideClinicalNote} />;
      }

      if (stackItem.code === ClinicalNoteStackCodes.list) {
        return <ChartClinicalNoteList chartSideClinicalNote={chartSideClinicalNote} />;
      }

      if (stackItem.code === ClinicalNoteStackCodes.item) {
        if (stackItem?.type === clinicNoteItemTypes.view) {
          return (
            <ChartClinicalNoteItemView
              member={member}
              chartSideClinicalNote={chartSideClinicalNote}
              permissions={permissions}
            />
          );
        }

        if (stackItem?.type === clinicNoteItemTypes.edit) {
          return (
            <ChartClinicalNoteItemEdit
              edit={true}
              appointmentId={_.get(chart, "appointment.id", this.props.appointmentId)}
              teeth={teeth}
              chartSideClinicalNote={chartSideClinicalNote}
              toothNumericType={toothNumericType}
              clinic={clinic}
            />
          );
        }

        if (stackItem?.type === clinicNoteItemTypes.new) {
          return (
            <ChartClinicalNoteItemEdit
              teeth={teeth}
              appointmentId={_.get(chart, "appointment.id", this.props.appointmentId)}
              chartSideClinicalNote={chartSideClinicalNote}
              toothNumericType={toothNumericType}
              selectTreatmentToCompleteModalVisible={
                chart && chart.selectTreatmentToCompleteModalVisible
              }
              clinic={clinic}
            />
          );
        }
      }

      if (stackItem.code === ClinicalNoteStackCodes.textarea) {
        if (stackItem?.type === clinicNoteItemTypes.view) {
          return (
            <ChartClinicalNoteItemView
              member={member}
              chartSideClinicalNote={chartSideClinicalNote}
              permissions={permissions}
            />
          );
        }

        if (stackItem?.type === clinicNoteItemTypes.edit) {
          return (
            <ChartClinicalNoteTextarea
              edit={true}
              chartSideClinicalNote={chartSideClinicalNote}
              toothNumericType={toothNumericType}
              stackItem={stackItem}
              viewType={viewType}
            />
          );
        }

        if (stackItem?.type === clinicNoteItemTypes.new) {
          return (
            <ChartClinicalNoteTextarea
              chartSideClinicalNote={chartSideClinicalNote}
              toothNumericType={toothNumericType}
              stackItem={stackItem}
              viewType={viewType}
            />
          );
        }
      }

      if (stackItem.code === ClinicalNoteStackCodes.print) {
        return <ChartClinicalNotePrint chartSideClinicalNote={chartSideClinicalNote} />;
      }
    }
  },
);
