import React from "react";

export default function ChartSupernumeraryNumber(props) {
  const { supernumerary, statusCode, numericKey } = props;

  let supernumeraryNumber = null;
  if (supernumerary) {
    supernumeraryNumber = supernumerary.details[numericKey];
  }
  if (statusCode === "TOOTHSTATUS_ABSENT") {
    supernumeraryNumber = null;
  }

  return <div className="supernumerary">{supernumeraryNumber}</div>;
}
