import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";

import Header from "./../layout/DefaultPageHeader";
import Show from "./../widgets/Show";
import bindActions from "../../helpers/bindActions";

import RequestedAppointmentsListForm from "./RequestedAppointmentsListForm";
import RequestedAppointmentsListCell from "./RequestedAppointmentsListCell";
import FindOpenSlotSidebar from "../calendar/find-open-slot-sidebar/FindOpenSlotSidebar";
import RequestedDeleteConfirmModal from "./RequestedDeleteConfirmModal";

import * as waitingListActions from "../../actions/waitingListActions";
import * as calendarActions from "../../actions/calendarActions";
import { setRequestedAppointmentItem } from "../../actions/appointmentActions";
import { withRouter } from "react-router";

const enhancer = connect(({ waitingList, calendar, blockedTimeSlot }) => {
  return {
    waitingList,
    blockedTimeSlot,
    calendar,
  };
}, bindActions({ waitingListActions, calendarActions, setRequestedAppointmentItem }));
class RequestedAppointmentsList extends React.Component {
  componentDidMount() {
    this.props.calendarActions.getDentistWithPoints();
    this.props.waitingListActions.getRequestedAppointments();
  }

  componentWillUnmount() {
    this.props.waitingListActions.bookedAppointmentDefaultStateHandler();
  }

  infoShow = (id) => {
    if (this.props.waitingList.requestedWaitingInfoShow === id) {
      this.props.waitingListActions.requestedInfoShow(0);
    } else {
      this.props.waitingListActions.requestedInfoShow(id);
    }
  };

  loadWaitingList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.waitingListActions.loadRequestedAppointmentWaitingList();
    }
  };

  loadScheduledList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.waitingListActions.loadRequestedAppointmentScheduledList();
    }
  };

  render() {
    const {
      waitingList,
      waitingListActions,
      calendar,
      calendarActions,
      blockedTimeSlot,
      history,
      setRequestedAppointmentItem,
    } = this.props;

    return (
      <div className="waiting-list-page">
        <Header addClass="waiting-list-header mt-0" text="Requested Appointments">
          <RequestedAppointmentsListForm />
        </Header>
        <div className="requested-list-title">
          <div>Booked</div>
          <div>Waiting</div>
        </div>
        <div className="requested-list-body">
          <div className="scheduled-list" onScroll={this.loadScheduledList}>
            {_.map(waitingList.requestedScheduledList, (item, index) => (
              <RequestedAppointmentsListCell
                key={index}
                item={item}
                history={history}
                setRequestedAppointmentItem={setRequestedAppointmentItem}
                onSetFromDateOpenSlot={calendarActions.findOpenSlotSidebarSetFromDate}
                onSetToDateOpenSlot={calendarActions.findOpenSlotSidebarSetToDate}
                onSetFromTimeOpenSlot={calendarActions.setOpenSlotFromTime}
                onSetToTimeOpenSlot={calendarActions.setOpenSlotToTime}
                onShowOpenSlot={calendarActions.findOpenSlotSidebarShow}
                infoId={waitingList.requestedWaitingInfoShow}
                setWeedDaysOpenSlot={calendarActions.setWeedDaysOpenSlot}
                onShowDeleteConfirm={waitingListActions.requestedDeleteConfirmModalShow}
                setToTime={calendarActions.setToTime}
                setFromTime={calendarActions.setFromTime}
                onShowInfo={this.infoShow}
                type="scheduled"
              />
            ))}
            <Show if={waitingList.requestedScheduledFatching}>
              <div className="requested-spinner">
                <ReactSpinner />
              </div>
            </Show>
          </div>
          <div className="waiting-list" onScroll={this.loadWaitingList}>
            {_.map(waitingList.requestedWaitingList, (item, index) => (
              <RequestedAppointmentsListCell
                key={index}
                item={item}
                history={history}
                setRequestedAppointmentItem={setRequestedAppointmentItem}
                onSetFromDateOpenSlot={calendarActions.findOpenSlotSidebarSetFromDate}
                onSetToDateOpenSlot={calendarActions.findOpenSlotSidebarSetToDate}
                onSetFromTimeOpenSlot={calendarActions.setOpenSlotFromTime}
                onSetToTimeOpenSlot={calendarActions.setOpenSlotToTime}
                onShowOpenSlot={calendarActions.findOpenSlotSidebarShow}
                infoId={waitingList.requestedWaitingInfoShow}
                setWeedDaysOpenSlot={calendarActions.setWeedDaysOpenSlot}
                onShowDeleteConfirm={waitingListActions.requestedDeleteConfirmModalShow}
                setToTime={calendarActions.setToTime}
                setFromTime={calendarActions.setFromTime}
                onShowInfo={this.infoShow}
                type="waiting"
              />
            ))}
            <Show if={waitingList.requestedWaitingFatching}>
              <div className="requested-spinner">
                <ReactSpinner />
              </div>
            </Show>
          </div>
        </div>
        <FindOpenSlotSidebar
          findOpenSlotSidebarVisible={calendar.findOpenSlotSidebarVisible}
          findOpenSlotFromDate={calendar.findOpenSlotFromDate}
          findOpenSlotToDate={calendar.findOpenSlotToDate}
          doctors={calendar.doctors}
          dentalPoint={calendar.dentalPoint}
          openSlotWeekDays={calendar.openSlotWeekDays}
          openSlotUserFromTime={calendar.openSlotUserFromTime}
          openSlotUserToTime={calendar.openSlotUserToTime}
          freePoints={calendar.freePoints}
          searchMode={calendar.searchMode}
          openSlotFromRequested={true}
          doctorId={calendar.doctorId}
          blockedTimeSlot={blockedTimeSlot}
        />
        <RequestedDeleteConfirmModal
          deleteId={waitingList.requestedDeleteConfirmModalVisible}
          show={Boolean(waitingList.requestedDeleteConfirmModalVisible)}
        />
      </div>
    );
  }
}
export default withRouter(enhancer(RequestedAppointmentsList));
