import React, { useEffect, useState } from "react";
import { Modal } from "../../ui/Modal";
import { useDispatch } from "react-redux";
import { saveClaimItemNoteApi } from "../../../actions/patientRegisterActions";

const NoteModal = ({ setNoteModalVisible, show, item, id, getList }) => {
  const [data, setData] = useState({ notes: "", additionalComments: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    setData({
      notes: item.notes || "",
      additionalComments: item.additionalComments || "",
    });
  }, [item]);

  const handleSubmit = () => {
    dispatch(
      saveClaimItemNoteApi({
        id,
        ...data,
      }),
    ).then(() => {
      setNoteModalVisible(false);
      setData({ notes: "", additionalComments: "" });
      getList();
    });
  };
  const readyToSubmit = data.notes.length > 0 || data.additionalComments.length > 0;
  return (
    <Modal
      show={show}
      animation={false}
      onHide={() => setNoteModalVisible(false)}
      title=" "
      actions={
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              setNoteModalVisible(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSubmit}
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      }
    >
      <div className="mb-1">Internal Notes:</div>
      <textarea
        className="form-control mb-3"
        rows="5"
        value={data.notes}
        onChange={(event) =>
          setData({
            ...data,
            notes: event.target.value,
          })
        }
      />
      <div className="mb-1">Additional Comments:</div>
      <textarea
        className="form-control mb-3"
        rows="5"
        value={data.additionalComments}
        onChange={(event) =>
          setData({
            ...data,
            additionalComments: event.target.value,
          })
        }
      />
    </Modal>
  );
};

export default NoteModal;
