import React from "react";

import Utils from "../../helpers/Utils";

const PatientFamilyMembersTable = ({ list, chooseFamilyMember }) => {
  return (
    <table className="family-members-table">
      <tbody>
        {list.map((item) => (
          <tr className="cursor-pointer" key={item.id} onClick={() => chooseFamilyMember(item)}>
            <td className="name-field">
              <span className="label" style={{ color: "#888" }}>
                Name:{" "}
              </span>
              <br />
              {[Utils.getFullName(item), !item.hidden ? "(Active)" : "(Inactive)"].join(" ")}
            </td>
            <td className="text-right">
              <span className="label" style={{ color: "#888" }}>
                Age:
              </span>
              <br />
              {Utils.getAge(item.dateOfBirth)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default PatientFamilyMembersTable;
