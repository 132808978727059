import React from "react";
import cx from "classnames";
import FilledImage from "../widgets/FilledImage";

import NotesImage from "../../assets/images/appointment/notes.png";
import VitalsImage from "../../assets/images/appointment/Vitals@2x.png";
import DollarImage from "../../assets/images/appointment/dollar@2x.png";
import HistoryImage from "../../assets/images/appointment/history.png";
import IncommingImage from "../../assets/images/appointment/incomming.png";
import DocsImage from "../../assets/images/calendar-bottom-menu/images.png";
import ClinicNoteImage from "../../assets/images/chart/ClinicalNotes@2x.png";
import FillInFormsImage from "../../assets/images/appointment/fillInForms.png";
import ConsentFormImage from "../../assets/images/appointment/consent_form.png";
import PlannedIconImage from "../../assets/images/appointment/PlannedIcon@2x.png";
import NextVisitIcon from "../../assets/images/next-visit-icon.png";
import PrintIcon from "../../assets/images/print-icon.png";
import { IconButton } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: "flex", flexWrap: "wrap" },
}));

export interface AppointmentPatientControlButtonsProps {
  readonly className?: string;
  readonly isTemporary: boolean;

  readonly onDocsClick: () => void;
  readonly onVitalClick: () => void;
  readonly onReferralClick: () => void;
  readonly onPrintLabelClick: () => void;
  readonly onFillInFormClick: () => void;
  readonly onConsentFormClick: () => void;
  readonly onPlannedItemsClick: () => void;
  readonly onClinicalNotesClick: () => void;
  readonly onReceptionNotesClick: () => void;
  readonly onPatientBalanceClick: () => void;
  readonly onConsultationNotesClick: () => void;
  readonly onAppointmentHistoryClick: () => void;
}

export function AppointmentPatientControlButtons({
  className,
  isTemporary,
  onDocsClick,
  onVitalClick,
  onReferralClick,
  onPrintLabelClick,
  onFillInFormClick,
  onConsentFormClick,
  onPlannedItemsClick,
  onClinicalNotesClick,
  onReceptionNotesClick,
  onPatientBalanceClick,
  onConsultationNotesClick,
  onAppointmentHistoryClick,
}: AppointmentPatientControlButtonsProps) {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const showButtons = localStorage.getItem("appointmentHideButtons") === "false";

  return (
    <div className={cx(classes.root, className)}>
      {!isTemporary && (
        <IconButton title="Clinical Notes" onClick={onClinicalNotesClick}>
          <img alt={""} src={ClinicNoteImage} className="zoom-2x" />
        </IconButton>
      )}
      {!isTemporary && (
        <IconButton title="Reception Notes" onClick={onReceptionNotesClick}>
          <img alt={""} src={NotesImage} className="zoom-2x" />
        </IconButton>
      )}

      {!isTemporary && (
        <IconButton title="Patient Balance" onClick={onPatientBalanceClick}>
          <img alt={""} src={DollarImage} className="zoom-2x" />
        </IconButton>
      )}

      {!isTemporary && showButtons && (
        <IconButton title="Consent Form" onClick={onConsentFormClick}>
          <img alt={""} src={ConsentFormImage} className="zoom-2x" />
        </IconButton>
      )}

      {!isTemporary && showButtons && (
        <IconButton title="Add Fill In Form" onClick={onFillInFormClick}>
          <img alt={""} src={FillInFormsImage} className="zoom-2x" />
        </IconButton>
      )}

      {showButtons && (
        <IconButton title="Consultation Notes" onClick={onConsultationNotesClick}>
          <img alt={""} src={IncommingImage} className="zoom-2x" />
        </IconButton>
      )}

      {!isTemporary && (
        <IconButton
          style={{ padding: "6px", paddingTop: "8px" }}
          title="Docs"
          onClick={onDocsClick}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "41px",
              height: "41px",
              marginBottom: "2px",
              borderRadius: "20.5px",
              border: "2px solid #BFB800",
            }}
          >
            <FilledImage src={DocsImage} color={theme.palette.primary.main} />
          </div>
        </IconButton>
      )}

      {!isTemporary && showButtons && (
        <IconButton title="Vital" onClick={onVitalClick}>
          <img alt={""} src={VitalsImage} className="zoom-2x" />
        </IconButton>
      )}
      {/*plann*/}
      {!isTemporary && (
        <IconButton title="Planned Items" onClick={onPlannedItemsClick}>
          <img alt={""} src={PlannedIconImage} className="zoom-2x" />
        </IconButton>
      )}

      <IconButton title="Appointment History" onClick={onAppointmentHistoryClick}>
        <img alt={""} src={HistoryImage} className="zoom-2x" />
      </IconButton>

      <IconButton onClick={onReferralClick} title="Referral, Next visit">
        <FilledImage src={NextVisitIcon} zoom={50} color="#BFB800" />
      </IconButton>
      {!isTemporary && (
        <IconButton onClick={onPrintLabelClick} title="Print Label">
          <FilledImage src={PrintIcon} zoom={50} color="#BFB800" />
        </IconButton>
      )}
    </div>
  );
}
