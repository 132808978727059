import React, { useEffect } from "react";
import { createUrl, parseQuery } from "../../../../utils/UrlUtils";
import _ from "lodash";
import FillInFormSortFields from "../../../../constants/chart-sidebar/fillInFormSortFields";
import Show from "../../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { useDispatch } from "react-redux";
import {
  getFrequentlyMedicalFillInFormList,
  getOwnedList,
  openItem,
  loadOwnedList,
  // openList,
  openTextarea,
  setMedicalFillInFormAddendumId,
  copyNoteItem,
  selectSortField,
  getConsultationTemplateList,
  getMedicalFillInFormTemplateQuestions,
  setActiveTab,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import MedicalFillInFormOwnedItem from "./MedicalFillInFormOwnedItem";
import { SET_CONSULTATION_ID } from "../../../../actionTypes/medicalFillInFormActionTypes";
import { openSimpleModal } from "../../../../actions/simpleModalActions";

const MedicalFillInFormOwnedList = (props) => {
  const dispatch = useDispatch();
  const query = parseQuery(props.location.search);
  const patientKey = _.get(query, "patientKey") || props.patientKey;
  const {
    permissions,
    chartSideMedicalFillInForm: { templateTabList },
  } = props;

  const isFrequency = () => {
    const query = parseQuery(props.location.search);

    return Boolean(_.get(query, "isFrequency"));
  };

  /*  const resetFrequency = () => {
    const { history, location } = props;

    const query = parseQuery(location.search);

    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          isFrequency: undefined,
        },
      }),
    );
  };*/

  useEffect(() => {
    if (isFrequency()) {
      dispatch(
        getFrequentlyMedicalFillInFormList({
          patientKey,
        }),
      );
    } else {
      dispatch(getOwnedList({ patientKey }));
    }
  }, []);

  const openItemHandler = (item) => {
    const viewPermission = permissions.indexOf("VIEW_CONSULTATION_NOTE_ITEM") !== -1;
    const { history, location } = props;
    if (!viewPermission) {
      return dispatch(openSimpleModal({ body: "permission denied" }));
    }
    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          medicalType: medicalFillInFormItemTypes.view,
        },
      }),
    );
    dispatch(openItem(item, medicalFillInFormItemTypes.view));
    dispatch({ type: SET_CONSULTATION_ID, consultationId: item.consultationId });
    dispatch(getConsultationTemplateList({ consultationId: item.consultationId, patientKey }));
  };

  const isEditAvailable = (item) => {
    const less24hours = Date.now() - item.createdDate < 24 * 60 * 60 * 1000;
    const less48hours = Date.now() - item.createdDate < 48 * 60 * 60 * 1000;

    const editAnyTimePermission = permissions.indexOf("MEDICAL_CONSULTATION_EDIT_ANY_TIME") >= 0;
    const less24HourPermission =
      permissions.indexOf("MEDICAL_CONSULTATION_EDIT_WITHIN_24_HOURS") >= 0;
    const less48HourPermission =
      permissions.indexOf("MEDICAL_CONSULTATION_EDIT_WITHIN_48_HOURS") >= 0;
    return (
      editAnyTimePermission ||
      (less48HourPermission && less48hours) ||
      (less24HourPermission && less24hours)
    );
  };

  const onEditHandler = (item) => {
    if (!isEditAvailable(item)) {
      return dispatch(openSimpleModal({ body: "Permission denied" }));
    }
    const { history, location } = props;
    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          medicalType: medicalFillInFormItemTypes.edit,
        },
      }),
    );
    dispatch({ type: SET_CONSULTATION_ID, consultationId: item.consultationId });
    dispatch(getConsultationTemplateList({ consultationId: item.consultationId, patientKey })).then(
      (response) => {
        const item = response[0];
        if (item?.initialExamination) {
          dispatch(setActiveTab(templateTabList[0]));
        }
        dispatch(openItem(item, medicalFillInFormItemTypes.edit));
        const transfer = {
          patientKey: query.patientKey,
          consultationId: item.consultationId,
          templateId: item?.template.id,
        };
        dispatch(getMedicalFillInFormTemplateQuestions(transfer));
      },
    );
  };

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      dispatch(loadOwnedList({ patientKey: props.patientKey }));
    }
  };

  const select = (event) => {
    dispatch(selectSortField(event.target.value));
  };

  const openTextareaHandler = () => {
    // dispatch(openList());
    // dispatch(openTextarea(medicalFillInFormItemTypes.new));
    dispatch(openTextarea(true));
  };

  const openAddendum = (id) => {
    dispatch(setMedicalFillInFormAddendumId(id));
    openTextareaHandler();
  };

  const {
    ownedList,
    fetching,
    sortField,
    frequencyList,
    consultationId,
    historicalListCount,
  } = props.chartSideMedicalFillInForm;

  const targetList = isFrequency() ? frequencyList : ownedList;

  let $list = targetList.map((item, index) => (
    <MedicalFillInFormOwnedItem
      key={index}
      item={item}
      consultationId={consultationId}
      openItem={openItemHandler}
      editItem={onEditHandler}
      openAddendumItem={() => {
        openAddendum(item.consultationId);
      }}
      onReferenceClick={(id) => {
        dispatch(openItem({ id }, medicalFillInFormItemTypes.view));
      }}
      copyItem={() => dispatch(copyNoteItem(item.id))}
    />
  ));

  const $options = FillInFormSortFields.map((item, index) => (
    <option key={index} value={item.code}>
      {item.name}
    </option>
  ));

  return (
    <div className="chart-clinical-note" style={{ height: "100%" }}>
      <div className="chart-side-body">
        <div className="chart-clinical-note-select pl-0 pr-0">
          <select onChange={select} value={sortField} className="form-control">
            {$options}
          </select>
        </div>
        <div
          className="body dental-data-list"
          style={{ overflowY: "auto", height: "100%" }}
          onScroll={loadList}
        >
          {$list}
          <Show if={historicalListCount === 0 && _.isEmpty($list)}>
            <span className="text-center font-weight-bold mt-1">No historical consultations</span>
          </Show>
          <Show if={fetching}>
            <ReactSpinner />
          </Show>
        </div>
      </div>
    </div>
  );
};

export default MedicalFillInFormOwnedList;
