import React from "react";
import FlexBox from "../ui/FlexBox";

export function TerminalButton(props) {
  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      className="card-button"
      onClick={props.onClick}
    >
      <FlexBox flex={true} justify="center" align="flex-end">
        <div className="button-icon">
          <img src={props.imageUrl} className="img-responsive" alt="" />
        </div>
      </FlexBox>
      <FlexBox flex={true} justify="center">
        <span className="button-title">{props.title}</span>
      </FlexBox>

      {props.count && (
        <FlexBox className="button-count" align="center" justify="center">
          {props.count}
        </FlexBox>
      )}
    </FlexBox>
  );
}
