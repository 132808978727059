import React, { useEffect, useState } from "react";
import { Button, ButtonType } from "../ui/Button";
import _ from "lodash";

const AppointmentLengthButtons = ({ lengthSB }) => {
  const size = 48;
  const { options, selectedId, handler, disabled } = lengthSB;
  const buttonsLength = options.length;

  const [position, setPosition] = useState(0);
  const [margin, setMargin] = useState(8);
  const [plusButtonVisible, setPlusButtonVisible] = useState(true);

  useEffect(() => {
    const appointmentLengthEl = document.getElementById("appointment-length");
    const wrapperWidth = appointmentLengthEl.offsetWidth;
    const buttonQtyInWrapper = (wrapperWidth - (wrapperWidth % size)) / size;
    const totalMargin = wrapperWidth - buttonQtyInWrapper * size;
    setMargin(Math.ceil(totalMargin / buttonQtyInWrapper));
  }, []);

  const plusButtonChange = () => {
    setPosition(position - 1);
    const appointmentLengthEl = document.getElementById("appointment-length");
    const appointmentLengthButtonsEl = document.getElementById("appointment-length__buttons");
    const wrapperRect = appointmentLengthEl.getBoundingClientRect();
    const buttonsRect = appointmentLengthButtonsEl.getBoundingClientRect();
    if (Math.floor(wrapperRect.right) >= Math.floor(buttonsRect.right - (size + margin)))
      setPlusButtonVisible(false);
  };

  const minusButtonChange = () => {
    setPlusButtonVisible(true);
    setPosition(position + 1);
  };

  const generateLengthButtons = () => {
    if (options.length) {
      return options.map(({ id, name }) => (
        <Button
          key={id}
          type="button"
          disabled={disabled}
          style={{ width: `${size}px`, height: `${size}px`, marginRight: `${margin}px` }}
          onClick={() => handler(_.toFinite(id))}
          buttonType={id === selectedId ? ButtonType.Primary : ButtonType.Secondary}
          className="d-flex align-items-center justify-content-center mb-1"
        >
          {name}
        </Button>
      ));
    }
  };

  return (
    <div
      style={{
        height: `${size}px`,
      }}
      className="appointment-length"
      id="appointment-length"
    >
      <Button
        onClick={minusButtonChange}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          display: position === 0 ? "none" : "flex",
        }}
        className="appointment-length__minus"
      >
        -
      </Button>
      <div
        className="appointment-length__buttons"
        id="appointment-length__buttons"
        style={{ width: `${buttonsLength * (size + margin)}px`, left: position * (size + margin) }}
      >
        {generateLengthButtons()}
      </div>
      <Button
        onClick={plusButtonChange}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          display: !plusButtonVisible ? "none" : "flex",
        }}
        className="appointment-length__plus"
      >
        +
      </Button>
    </div>
  );
};

export default AppointmentLengthButtons;
