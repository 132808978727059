import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Modal } from "../ui/Modal";
import Utils from "../../helpers/Utils";
import { createUrl } from "../../utils/UrlUtils";
import { PatientItem } from "../patient/PatientItem";
import { SelectPicker } from "../select-picker/SelectPicker";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { openPatientInfoSideBar } from "../../actions/patientActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";
import { getList, referralNextVisitAppointmentSetType } from "../../actions/referralActions";
import { notInterestedAppointment, saveAppointmentApi } from "../../actions/appointmentActions";

const enhancer = connect(
  ({ referral: { list, searchInitialValues, referralNextVisitListActiveTab } }) => ({
    list,
    searchInitialValues,
    referralNextVisitListActiveTab,
  }),
);
const ReferralNextVisitTable = (props) => {
  const [showNoteField, setShowNoteField] = useState(0);
  const [rowNote, setRowNote] = useState("");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [patientItem, setPatientItem] = useState({});
  const { tdWidth } = props;

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      const { searchInitialValues, referralNextVisitListActiveTab } = props;
      const data = {
        ...searchInitialValues,
        referral: true,
      };
      data.showNoAnswer = referralNextVisitListActiveTab === "noAnswer";
      data.showWillCall = referralNextVisitListActiveTab === "willCall";
      data.notInterested = referralNextVisitListActiveTab === "notInterested";

      data.referralTypeCode =
        typeof _.get(searchInitialValues, "referralTypeCode.value") === "string"
          ? _.get(searchInitialValues, "referralTypeCode.value")
          : null;
      data.referredToId =
        _.get(searchInitialValues, "referredToId.value") > 0
          ? _.get(searchInitialValues, "referredToId.value")
          : null;
      data.referredFromId =
        _.get(searchInitialValues, "referredFromId.value") > 0
          ? _.get(searchInitialValues, "referredFromId.value")
          : null;
      data.doctorIds =
        _.get(searchInitialValues, "doctorIds.value") > 0
          ? _.get(searchInitialValues, "doctorIds.value")
          : null;
      data.categoryIds = searchInitialValues.categoryIds?.value;
      data.periodStart = getCompanyStartOfDay(searchInitialValues.periodStart);
      data.periodEnd = getCompanyEndOfDay(searchInitialValues.periodEnd);
      dispatch(getList(data));
    }
  };

  const saveNote = (item) => {
    item.doctorNote = rowNote;
    dispatch(saveAppointmentApi(item)).then(() => props.getList(props.searchInitialValues));
    setShowNoteField(0);
    setRowNote("");
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setPatientItem({});
  };

  const submitHandler = () => {
    setShowModal(false);
    dispatch(notInterestedAppointment(patientItem))
      .then(() => props.getList(props.searchInitialValues))
      .then(() => setPatientItem({}));
  };

  const actionOptions = [
    { value: "notInterested", label: "Not interested" },
    { value: "willCall", label: "Will call" },
    { value: "noAnswer", label: "No answer" },
  ];

  const setItemStatus = (status, appointment) => {
    const { referralNextVisitListActiveTab, searchInitialValues: values } = props;
    let request;
    let data;

    if (status === "notInterested") {
      data = { ...appointment, notInterested: true };
      request = notInterestedAppointment;
    } else {
      data = { id: appointment.id, [status]: true };
      request = referralNextVisitAppointmentSetType;
    }
    dispatch(request(data)).then(() => {
      history.replace(
        createUrl(location.pathname, {
          query: {
            periodStart: getCompanyStartOfDay(values.periodStart),
            periodEnd: getCompanyEndOfDay(values.periodEnd),
          },
        }),
      );

      values.showNoAnswer = referralNextVisitListActiveTab === "noAnswer";
      values.showWillCall = referralNextVisitListActiveTab === "willCall";
      values.notInterested = referralNextVisitListActiveTab === "notInterested";

      props.getList(props.searchInitialValues);
    });
  };

  const { list, searchInitialValues } = props;
  return (
    <div onScroll={loadList} style={{ height: "100%", overflow: "auto" }}>
      <table className="table table-hover bring-earlier-table">
        <thead>
          <tr
            style={{
              borderTop: "1px solid rgb(204, 204, 204)",
            }}
          >
            <th>Created date</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Referral type</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Patient chart number</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Patient</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Referred from</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Doctor</th>
            {searchInitialValues.showAll && (
              <th style={{ width: `${tdWidth - 1}px` }}>Appointment status</th>
            )}
            <th style={{ width: `${tdWidth - 1}px` }}>Type</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Appointment Notes</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Doctor Notes</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Date</th>
            <th style={{ width: `${tdWidth - 1}px` }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(list || []).map((x) => {
            const doctor = _.get(x, "dentist.name");
            const referralType = _.get(x, "referralType.name");
            const referredFrom = _.get(x, "referredFrom.name");
            const createdDate = moment(_.get(x, "createdDate")).format(DATE_FORMAT_LEGACY);
            const date = moment(_.get(x, "date")).format(DATE_FORMAT_LEGACY);
            const patientName = Utils.getFullName(x.patient);
            const patientChartNumber = _.get(x, "patient.chartNumber");
            const category = _.get(x, "category.name", "-");
            const status = _.get(x, "status.name", "-");
            const note = _.get(x, "note", "-");
            const doctorNote = _.get(x, "doctorNote", "-");
            // const patientKey = _.get(x, "patient.patientKey");
            const notInterested = _.get(x, "notInterested");
            const willCall = _.get(x, "willCall");
            const noAnswer = _.get(x, "noAnswer");

            return (
              <tr
                key={x.id}
                style={{ backgroundColor: "#ff6666", color: "#ffffff", cursor: "pointer" }}
              >
                <td
                  style={{ verticalAlign: "middle", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  {createdDate}
                </td>
                <td
                  style={{ verticalAlign: "middle", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  {referralType}
                </td>
                <td
                  style={{ width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{patientChartNumber}</span>
                </td>
                <td
                  style={{ width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <PatientItem photo={_.get(x, "patient.photo.url")} size={40} name={patientName} />
                </td>
                <td
                  style={{ verticalAlign: "middle", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{referredFrom}</span>
                </td>
                <td
                  style={{ verticalAlign: "middle", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{doctor}</span>
                </td>
                {searchInitialValues.showAll && (
                  <td
                    style={{ width: `${tdWidth}px` }}
                    onClick={() => dispatch(openPatientInfoSideBar(x))}
                  >
                    <span>{status}</span>
                  </td>
                )}
                <td
                  title={status}
                  style={{ cursor: "help", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{category}</span>
                </td>
                <td
                  style={{ width: `${tdWidth}px` }}
                  title={note}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{note}</span>
                </td>
                <td
                  style={{ width: `${tdWidth}px` }}
                  onClick={() => {
                    setShowNoteField(x.id);
                    setRowNote(doctorNote);
                  }}
                >
                  {showNoteField === x.id ? (
                    <textarea
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "#000000",
                      }}
                      value={rowNote}
                      onChange={({ target: { value } }) => setRowNote(value)}
                      onBlur={() => saveNote(x)}
                    />
                  ) : (
                    <span>{doctorNote}</span>
                  )}
                </td>
                <td
                  style={{ verticalAlign: "middle", width: `${tdWidth}px` }}
                  onClick={() => dispatch(openPatientInfoSideBar(x))}
                >
                  <span>{date}</span>
                </td>
                <td
                  style={{
                    width: `${tdWidth}px`,
                  }}
                >
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SelectPicker
                      options={actionOptions}
                      onChange={(item) => setItemStatus(item.value, x)}
                      onFocus={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      value={{
                        value:
                          (notInterested && "notInterested") ||
                          (willCall && "willCall") ||
                          (noAnswer && "noAnswer"),
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        size="small"
        className="missed-cancelled-table-modal"
        show={showModal}
        keyboard={false}
        onHide={closeModalHandler}
        actions={
          <div>
            <Button bsStyle="danger" onClick={closeModalHandler}>
              Close
            </Button>

            <Button bsStyle="primary" onClick={submitHandler}>
              OK
            </Button>
          </div>
        }
      >
        <p>Are you sure ? This item will be hidden</p>
      </Modal>
    </div>
  );
};

export default enhancer(ReferralNextVisitTable);
