import React from "react";
import { Modal } from "../../ui/Modal";

import { connect } from "react-redux";

import {
  consultationNotesConfirmDeleteModalHide,
  deleteConsultationNotesItem,
} from "../../../actions/appointmentActions";

const enhancer = connect(null, {
  deleteConsultationNotesItem,
  consultationNotesConfirmDeleteModalHide,
});

class ConsultationNotesConfirmDeleteModal extends React.PureComponent {
  render() {
    const { deleteId } = this.props;
    return (
      <Modal
        show={deleteId}
        animation={false}
        size="sm"
        onHide={this.props.consultationNotesConfirmDeleteModalHide}
        actions={
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.consultationNotesConfirmDeleteModalHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.props.deleteConsultationNotesItem(deleteId)}
            >
              Delete
            </button>
          </div>
        }
        keyboard={false}
      >
        <div>Are you sure you want to delete note?</div>
      </Modal>
    );
  }
}

export default enhancer(ConsultationNotesConfirmDeleteModal);
