import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import React, { isValidElement, ReactNode } from "react";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles<Theme>((theme) => ({
  value: { color: theme.palette.info.main },
  root: { display: "flex", alignItems: "center" },
  container: { marginLeft: theme.typography.pxToRem(4) },
}));

export interface EligibilityInfoTextProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "children"
  > {
  readonly title: string;
  readonly value?: string | ReactNode;
}

export function EligibilityInfoText({
  title,
  value,
  className,
  ...props
}: EligibilityInfoTextProps) {
  const classes = useStyles();

  return (
    <div {...props} className={cx(classes.root, className)}>
      {title}

      {!!value && (
        <div className={classes.container}>
          {!isValidElement(value) && <span className={classes.value}>{value}</span>}
          {isValidElement(value) && value}
        </div>
      )}
    </div>
  );
}
