import { httpClient } from "./BaseApi";

const REMINDERS_LIST_URL = "api/sms/recurrence/list.json";
const REMINDERS_ITEM_URL = "api/reminder/item.json";
const REMINDERS_DELETE_URL = "api/sms/recurrence/delete.json";

const REMINDERS_TEMPLATE_LIST_URL = "api/sms/recurrence/template/list.json";
const REMINDERS_EMAIL_TEMPLATE_LIST_URL = "api/email/template/list.json";
const REMINDERS_PATIENT_APP_TEMPLATE_LIST_URL = "api/app/notification/template/list.json";
const REMINDERS_PATIENT_WHATS_APP_TEMPLATE_LIST_URL =
  "api/whatsapp/notification/template/list.json";

const APPOINTMENT_PATIENT_CHOOSE_URL = "api/clinic/appointment/list.json";

const PATIENT_LIST_URL = "api/patient/list.json";

const MEMBER_TYPE_AHEAD_URL = "api/member/typeAhead.json";

const SEND_PUSH_URL = "/api/clinic/appointment/send-push.json";

const REMINDERS_CHECK_BALANCE_URL = "api/company/sms/balance.json";

const RemindersApi = {
  sendPush: (data) => httpClient.put(SEND_PUSH_URL, { data }),
  addReminder: (data) => httpClient.post(REMINDERS_ITEM_URL, { data }),
  getPatientList: (params) => httpClient.get(PATIENT_LIST_URL, { params }),
  deleteReminder: (data) => httpClient.post(REMINDERS_DELETE_URL, { data }),
  getRemindersList: (params) => httpClient.get(REMINDERS_LIST_URL, { params }),
  getDentistList: (params) => httpClient.get(MEMBER_TYPE_AHEAD_URL, { params }),
  checkBalance: () => httpClient.get(REMINDERS_CHECK_BALANCE_URL),
  getTemplatesList: (params) => httpClient.get(REMINDERS_TEMPLATE_LIST_URL, { params }),
  getEmailTemplatesList: (params) => httpClient.get(REMINDERS_EMAIL_TEMPLATE_LIST_URL, { params }),
  getPatientAppTemplatesList: (params) =>
    httpClient.get(REMINDERS_PATIENT_APP_TEMPLATE_LIST_URL, { params }),
  getPatientWhatsAppTemplatesList: (params) =>
    httpClient.get(REMINDERS_PATIENT_WHATS_APP_TEMPLATE_LIST_URL, { params }),
  getCalendarPatientList: (params) => httpClient.get(APPOINTMENT_PATIENT_CHOOSE_URL, { params }),
};

export default RemindersApi;
