import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Utils from "../../../helpers/Utils";
import Colors from "../../../helpers/Colors";
import DataList from "../../widgets/DataList";
import bindActions from "../../../helpers/bindActions";
import { defaultColors } from "../../../constants/defaults";
import * as calendarActions from "../../../actions/calendarActions";
import { getCompanyFormatDate, getCompanyFormatTime } from "../../../helpers/DateUtils";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { ClinicSelectWrapper } from "../../clinic/ClinicSelectWrapper";

const enhancer = connect(({ session }) => ({ session }), bindActions({ calendarActions }));

class FindAppointmentSidebarList extends React.Component {
  constructor(props) {
    super(props);

    const clinicId = _.get(props, "session.clinic.id", "all");
    const clinicName = _.get(props, "session.clinic.name", "All Clinics");

    this.state = {
      clinicFilter: {
        value: clinicId,
        label: clinicName,
      },
    };
  }

  loadAppointments = (event) => {
    this.props.calendarActions.loadAppointments(
      this.state.clinicFilter.value,
      this.props.activeTab,
    );
  };

  render() {
    const { clinicFilter } = this.state;
    const { appointments, fetching, onChangeClinic } = this.props;

    const appointmentList = appointments
      .filter((x) => {
        const clinicId = _.get(x, "clinicId");
        const filterId = _.get(clinicFilter, "value");

        return filterId === "all" || clinicId === filterId || _.isUndefined(clinicId);
      })
      .map((point, index) => {
        const startDate = new Date(point.startTime);
        const date = getCompanyFormatDate(point.date);

        const duration = point.duration;

        const startTime = startDate.valueOf();
        const endTime = startTime + duration;

        const time = getCompanyFormatTime(startTime) + " - " + getCompanyFormatTime(endTime);

        const pointStatusColor = point.status && Colors.getStatusColor(point.status.code);
        const pointTypeColor = Colors.getAppointmentColor(point);

        const name = Utils.getFullName(point.patient);
        const appointmentType = Utils.getApptType(point.category);

        return (
          <Link
            key={index}
            to={createUrl(Routes.DashboardAppointment, {
              params: {
                id: point.id,
              },
            })}
            className="find-appointments-cell"
          >
            <div
              className="left"
              style={{
                flexDirection: "row",
                backgroundColor:
                  (pointTypeColor && pointTypeColor.color.color) || defaultColors.Blue.color,
              }}
            >
              <span className="d-flex align-items-center">{date}</span>
              <span className="d-flex align-items-center">{Boolean(startTime) && time}</span>
              <span className="d-flex align-items-center">#{point.patient.chartNumber}</span>
              <span className="d-flex align-items-center">{name}</span>
              <span className="d-flex align-items-center">{appointmentType}</span>
              <span className="d-flex align-items-center">
                {point.dentist && point.dentist.name}
              </span>
            </div>
            <div
              className="right"
              style={{
                backgroundColor:
                  (pointStatusColor && pointStatusColor.color.color) || defaultColors.Green.color,
              }}
            >
              <div className="bottom">{point.status && point.status.name}</div>
            </div>
          </Link>
        );
      });

    return (
      <DataList
        fetching={fetching}
        className="appointment-list"
        header={
          <ClinicSelectWrapper
            value={clinicFilter}
            onChange={(v) => {
              onChangeClinic(v);
              this.setState({ clinicFilter: v });
            }}
          />
        }
        onScroll={this.loadAppointments}
      >
        {appointmentList}
      </DataList>
    );
  }
}

export default enhancer(FindAppointmentSidebarList);
