import React from "react";

import MedicalFillInForm from "./MedicalFillInForm";
import { Sidebar } from "../../../sidebar/Sidebar";

export default function MedicalFillInFormSideBar({
  visible,
  onHide,
  member,
  clinic,
  location,
  history,
  patientKey,
}) {
  return (
    <Sidebar
      fullScreen={true}
      show={visible}
      backdrop={"static"}
      keyboard={false}
      shouldCloseOnEsc={false}
      onHide={onHide}
      dialogClassName="chart-side-bar"
    >
      <MedicalFillInForm
        member={member}
        location={location}
        clinic={clinic}
        history={history}
        patientKey={patientKey}
      />
    </Sidebar>
  );
}
