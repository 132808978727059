import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";
import formDays from "../../../constants/open-slot-sidebar/formDays";
import lengthOptions from "../../../constants/open-slot-sidebar/lengthList";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";
import { TimePickerField } from "../../forms/TimePickerField";
import { Button, ButtonType } from "../../ui/Button";
import moment from "moment";

const enhancer = connect(
  ({ calendar: { reserved, findOpenSlotWeekDays } }) => ({ reserved, findOpenSlotWeekDays }),
  bindActions({ calendarActions }),
);
const form = reduxForm({
  form: "findOpenSlotSidebar",
});

class FindOpenSlotSidebarForm extends React.PureComponent {
  componentDidMount() {
    const { initialValues, calendarActions } = this.props;
    const weekDay = moment(initialValues.findOpenSlotFromDate).format("ddd");
    calendarActions.findOpenSlotSetWeekDays((weekDay || "").toLowerCase() || "mon", true);
    if (initialValues.doctorId) {
      calendarActions.getDentalPoint({ value: initialValues.doctorId });
      calendarActions.findOpenSlotSidebarSetFromDate(initialValues.fromTime);
      calendarActions.findOpenSlotSidebarSetToDate(initialValues.fromTime);
    }
  }

  render() {
    const {
      findOpenSlotFromDate,
      findOpenSlotToDate,
      findOpenSlotWeekDays,
      doctors,
      dentalPoint,
      reserved,
      calendarActions,
    } = this.props;

    let days = [];
    let doctorsList = [];
    let roomList = [];

    _.forEach(doctors, (element) => {
      doctorsList.push({
        label: element.name,
        value: element.id,
      });
    });

    if (!_.isEmpty(dentalPoint)) {
      roomList = dentalPoint.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    _.forEach(formDays, (element) => {
      const selected = findOpenSlotWeekDays[element.name];
      days.push(
        <Button
          key={element.name}
          type="button"
          onClick={() => calendarActions.findOpenSlotSetWeekDays(element.name, !selected)}
          buttonType={selected ? ButtonType.Primary : ButtonType.Secondary}
          className="d-flex align-items-center justify-content-center mb-1 mr-1"
        >
          {(element.label || "").toUpperCase()}
        </Button>,
      );
    });

    return (
      <form className="open-slot-form clearfix list-group">
        <div className="col-sm-12">
          <label htmlFor="doctorId" className="control-label">
            {localStorage.getItem("doctorLabel")}
          </label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={doctorsList}
                value={{ value: input.value }}
                onChange={(x) => {
                  input.onChange(x.value);
                  calendarActions.getDentalPoint(x);
                }}
              />
            )}
            name="doctorId"
            id="doctorId"
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="trxRoom" className="control-label">
            Trx. Room
          </label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={roomList}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="trxRoom"
            id="trxRoom"
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="lengthList" className="control-label">
            Length
          </label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={lengthOptions}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="length"
            id="length"
          />
        </div>

        <div className="col-sm-12" style={{ marginTop: "20px" }}>
          <span>
            <strong>For More results, fill the below optional fields</strong>
          </span>
          <hr
            style={{
              marginTop: "6px",
              borderTop: "4px solid #bbc852",
            }}
          />
        </div>

        <div>
          <input
            id="reserved"
            type="checkbox"
            value={reserved}
            onChange={() => calendarActions.switchOpenSlotReserved()}
            style={{ margin: "0 8px 0 15px" }}
          />
          <label htmlFor="reserved">Show only reserved</label>
        </div>

        <div className="col-sm-12">
          <label htmlFor="fromDate" className="control-label">
            From Date
          </label>
          <DatePicker
            selected={findOpenSlotFromDate}
            id="fromDate"
            name="fromDate"
            onChange={calendarActions.findOpenSlotSidebarSetFromDate}
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="toDate" className="control-label">
            To Date
          </label>
          <DatePicker
            selected={findOpenSlotToDate}
            id="toDate"
            name="toDate"
            onChange={calendarActions.findOpenSlotSidebarSetToDate}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="fromTime" className="control-label">
            From Time
          </label>
          <TimePickerField name="fromTime" className="full-size" />
        </div>
        <div className="col-sm-6">
          <label htmlFor="toTime" className="control-label">
            To Time
          </label>

          <TimePickerField name="toTime" className="full-size" />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "28px",
            justifyContent: "center",
          }}
          className="col-sm-12 open-slot-form-days"
        >
          {days}
        </div>
      </form>
    );
  }
}

export default enhancer(form(FindOpenSlotSidebarForm));
