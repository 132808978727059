import React from "react";
import ChartToothImageBase from "./ChartToothImageBase";

export default function ChartToothImage(props) {
  return (
    <div className="tooth-image">
      <ChartToothImageBase {...props} />
    </div>
  );
}
