import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { IS_MOBILE } from "../../constants/Constants";
import { LIVE_HOST, settings } from "../../constants/host";
import PassImage from "../../assets/images/login/pass_icon.png";
import EmailImage from "../../assets/images/login/email_icon.png";
import CloudImage from "../../assets/images/login/cloud_icon.png";
import { adminLogin, clearToken, getUserInfo } from "../../actions/authActions";
import ClinicLogoImage from "../../assets/images/clinic_logo@2x.png";
import { Routes } from "../../constants/Routes";
import { withRouter } from "react-router";
import BackGroundImage from "../../assets/images/register-device/register_device_bg.jpg";
import FormBackGroundImage from "../../assets/images/clinic_bg.png";
import { obtainImagePath } from "../../utils/ImageUtils";

const enhancer = connect(({ auth }) => ({ auth }), { adminLogin, clearToken });

class RegisterDeviceLogin extends React.Component {
  handleSubmit = (event) => {
    event.preventDefault();

    const { clearToken, adminLogin } = this.props;

    let transfer = {
      username: event.target.email.value,
      password: event.target.password.value,
      work_mode: "default",
    };

    const host = event.target.host.value || "";
    const lastHostLetter = _.last(host.split(""));
    const newHost = lastHostLetter === "/" ? host : host + "/";

    settings.host = newHost;

    localStorage.setItem("host", newHost);

    clearToken();

    if (IS_MOBILE) {
      localStorage.setItem("loggedIn", false);
    }

    localStorage.setItem("loggedIn", false);
    adminLogin(transfer).then((response) => {
      if (response && response.data) {
        this.props.history.push(Routes.DashboardChooseClinic);
      }
    });
  };

  render() {
    return (
      <div
        className="register-device-login"
        style={{
          backgroundImage: `url(${obtainImagePath(BackGroundImage)})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div className="logo">
          <img src={ClinicLogoImage} alt="" />
        </div>
        <form
          onSubmit={this.handleSubmit}
          style={{ backgroundImage: `url(${FormBackGroundImage})` }}
        >
          <div className="title">sign in to your account</div>
          <div className="sub-title">Please use only an admin account to start using the app</div>
          <div className="inputs">
            <div className="input-group input-group-lg">
              <span className="input-group-addon">
                <img alt="" src={EmailImage} />
              </span>
              <input className="form-control" type="email" name="email" placeholder="Email" />
            </div>
            <div className="input-group input-group-lg">
              <span className="input-group-addon" style={{ borderRadius: "0px" }}>
                <img alt="" src={PassImage} />
              </span>
              <input
                className="form-control"
                style={{ borderRadius: "0px" }}
                type="password"
                name="password"
                placeholder="Password"
              />
            </div>
            <div className="input-group input-group-lg">
              <span className="input-group-addon">
                <img alt="" src={CloudImage} style={{ paddingRight: "1px" }} />
              </span>
              <input
                className="form-control"
                name="host"
                placeholder="Host"
                defaultValue={localStorage.getItem("host") || LIVE_HOST}
              />
            </div>
          </div>

          <div className="sign-in-button">
            <button type="submit" className="btn btn-lg btn-block btn-success">
              Connect to my account
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(enhancer(RegisterDeviceLogin));
