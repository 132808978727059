import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";

import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";

const enhancer = connect(null, bindActions({ chartActions }));
export default enhancer(
  class VoidedTreatmentsDialogList extends React.Component {
    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartActions.getVoidedTreatmentsLoadList();
      }
    };

    render() {
      const { list, totalCount, selectedKeys, fetching } = this.props;

      let rows = [];
      const checkedAll = _.filter(selectedKeys, (item) => item).length === list.length;
      _.forEach(list, (item, index) => {
        rows.push(
          <div
            className="select-treatment-list-row"
            key={index}
            onClick={() => this.props.chartActions.voidedTreatmentsToggleSelectedKey(item.key)}
          >
            <div className="item-code">{item.treatmentItem && item.treatmentItem.code}</div>
            <div className="item-description">
              {item.treatmentItem && item.treatmentItem.description}
            </div>
            <div className="item-tooth">{item.tooth && item.tooth.name}</div>
            <div className="item-area">{item.area && item.area.name}</div>
            <div className="item-treatment-code">
              {item.diagnosisItem && item.diagnosisItem.code}
            </div>
            <div className="item-fee">{item.price}</div>
            <div className="item-posted-by">{item.createdDentist && item.createdDentist.name}</div>
            <div className="item-date-posted">
              {moment(item.createdDate).format(DATE_FORMAT_LEGACY)}
            </div>
            <div className="item-date-completed">
              {_.get(item, "completedDate")
                ? moment(_.get(item, "completedDate")).format(DATE_FORMAT_LEGACY)
                : ""}
            </div>
            <div className="item-checkbox">
              <input
                type="checkbox"
                disabled={totalCount === 0}
                checked={selectedKeys[item.key]}
                readOnly={true}
              />
            </div>
          </div>,
        );
      });

      return (
        <div className="select-treatment-list-table">
          <div className="select-treatment-list-header">
            <div className="item-code">Code</div>
            <div className="item-description">Description</div>
            <div className="item-tooth">Tooth</div>
            <div className="item-area">Area</div>
            <div className="item-treatment-code">Diagnosis Code</div>
            <div className="item-fee">Fee</div>
            <div className="item-posted-by">{localStorage.getItem("doctorLabel")} Name</div>
            <div className="item-date-posted">Created Date</div>
            <div className="item-date-completed">Completed Date</div>
            <div className="item-checkbox">
              <input
                type="checkbox"
                disabled={totalCount === 0}
                checked={checkedAll}
                onChange={this.props.chartActions.voidedTreatmentsToggleSelectAll}
              />
            </div>
          </div>
          <div className="select-treatment-list-body" onScroll={this.loadList}>
            {rows}
            <Show if={fetching}>
              <div className="select-treatment-list-row">
                <ReactSpinner />
              </div>
            </Show>
          </div>
        </div>
      );
    }
  },
);
