import React from "react";

export default function ChartTransactionFooter(props) {
  const { elements } = props;

  const isVisibleApproveBtn = elements.approveBtn.visible;
  const isVisibleAddProductBtn = elements.addProductBtn.visible;
  const isVisiblePlanBtn = elements.planBtn.visible;
  const isPlannedBtn = elements.leftButtons.kPlannedTreatmentBtn.selected;
  const isCompletedBtn = elements.leftButtons.kCompletedTreatmentBtn.selected;

  const isPlannedVoid = props.permissions.indexOf("VOID_PLANNED_DENTAL_TRANSACTION_ITEM") !== -1;
  const isCompletedVoid =
    props.permissions.indexOf("VOID_COMPLETED_DENTAL_TRANSACTION_ITEM") !== -1;

  const showPlan = isVisibleApproveBtn || (isVisibleAddProductBtn && isVisiblePlanBtn);
  const showApprove = isVisibleApproveBtn || isPlannedBtn || isCompletedBtn;
  const showVoided =
    (!isVisibleApproveBtn && !isVisibleAddProductBtn && isPlannedBtn && isPlannedVoid) ||
    (isCompletedBtn && isCompletedVoid);
  const showPckgs = isPlannedBtn || isCompletedBtn;

  return (
    <div className="d-flex flex-column">
      {showPlan && (
        <button
          className="btn btn-success btn-lg"
          style={{
            borderBottom: "1px solid white",
            borderRadius: 0,
          }}
          onClick={elements.planBtn.handler}
        >
          {elements.planBtn.value}
        </button>
      )}

      {isVisibleAddProductBtn && (
        <button
          style={{
            borderBottom: "1px solid white",
            borderRadius: 0,
          }}
          className="btn btn-success btn-lg"
          onClick={elements.addProductBtn.handler}
        >
          {elements.addProductBtn.value}
        </button>
      )}

      {showApprove && (
        <button
          style={{
            borderBottom: "1px solid white",
            borderRadius: 0,
          }}
          className="btn btn-success btn-lg"
          onClick={() => elements.approveBtn.handler(true)}
        >
          {elements.approveBtn.value}
        </button>
      )}

      {showVoided && (
        <button
          style={{
            borderBottom: "1px solid white",
            borderRadius: 0,
          }}
          className="btn btn-success btn-lg"
          onClick={elements.voidedBtn.handler}
        >
          {elements.voidedBtn.value}
        </button>
      )}

      {showPckgs && (
        <button
          style={{
            borderBottom: "1px solid white",
            borderRadius: 0,
          }}
          className="btn btn-success btn-lg"
          onClick={props.stopClick}
        >
          Pckgs|Deals
        </button>
      )}
    </div>
  );
}
