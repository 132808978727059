import React, { useEffect, useState } from "react";
import { FieldArray, Form } from "formik";
import { get, truncate, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Utils from "../../helpers/Utils";
import { TextInput } from "../ui/TextInput";
import { Button, ButtonType } from "../ui/Button";
import { createUrl } from "../../utils/UrlUtils";
import plusIcon from "../../assets/images/patient/add.png";
import { setSearchValue } from "../../actions/chartSideSeriesActions";
import { getTreatmentItemPrice } from "../../actions/chartSideSeriesActions";

const SidebarProcedureFormChild = ({
  setValues,
  setFieldValue,
  values,
  errors,
  touched,
  systemPriceTotal,
  history,
  location,
  _parseQuery,
  customizePackageModalHide,
}) => {
  const dispatch = useDispatch();
  const { patient } = useSelector((store) => store.chart);
  const [searchResults, setSearchResults] = useState([]);
  const [treatmentsList, setTreatmentsList] = useState([]);
  const { list, searchValue } = useSelector((store) => store.chartSideSeries);

  useEffect(() => {
    setTreatmentsList(list);
    if (isEmpty(searchValue)) {
      setTreatmentsList([]);
      setSearchResults([]);
    }
  }, [list, searchValue]);

  const findIndexById = (array, id) => {
    return array.findIndex((item) => item.treatmentId === id);
  };

  const handleCalculatePackagePrices = (values, setValues) => {
    if ((values.desiredPackageAmount || 0) > 0) {
      const updatedValues = { ...values };
      const totalPrice = values.treatments.reduce(
        (acc, item) => acc + (item.price * item.quantity || 1),
        0,
      );

      updatedValues.treatments.forEach((treatment) => {
        const quantity = treatment.quantity || 1;

        const priceWeight =
          (Number(
            treatment.systemPriceTotals
              .toString()
              .replace(",", "")
              .replace(/\.?0*$/, ""),
          ) /
            totalPrice) *
          100;
        const _packagePrice =
          (Number(priceWeight.toFixed(2)) * 0.01 * values.desiredPackageAmount) / quantity;
        treatment.packagePriceInt = Number.isInteger(_packagePrice)
          ? Number(_packagePrice)
          : +Number(_packagePrice.toFixed(2));
      });

      const totalPackagePrice = updatedValues.treatments.reduce((sum, item) => {
        const itemTotalPrice = (item.packagePriceInt || 0) * (item.quantity || 1);
        sum = Number(sum) + Number(itemTotalPrice);
        return Number.isInteger(sum) ? Number(sum) : Number(sum).toFixed(2);
      }, 0);
      updatedValues.totalPackagePrice = Math.round(totalPackagePrice);
      setValues(updatedValues);
    }
  };

  const searchTreatmentDebounce = (searchTerm, index) => {
    dispatch(setSearchValue(searchTerm));

    setSearchResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[index] = updatedResults;
      return updatedResults;
    });
  };

  const closeHandler = () => {
    dispatch(setSearchValue(""));
    history.replace(createUrl(location.pathname, { query: { ..._parseQuery, id: undefined } }));
    customizePackageModalHide();
  };
  return (
    <Form>
      <FieldArray name="treatments">
        {({ push, remove }) => (
          <div className="procedure-treatment-codes pl-0 pr-0">
            {values?.treatments?.length > 0 && (
              <div className="row ml-0 mr-0">
                <div className="col-sm-3 fw-700 pl-1">Treatment Code-Description</div>
                <div className="col-sm-1 fw-700">Quantity</div>
                <div className="col-sm-1 fw-700">Unit</div>
                <div className="col-sm-2 fw-700">System Price</div>
                <div className="col-sm-2 fw-700">System Price Totals</div>
                <div className="col-sm-2 fw-700">Package Price</div>
              </div>
            )}
            <div className="dynamic-package-scrollbar">
              {(values.treatments || []).map((item, index) => {
                return (
                  <div key={index} className="row ml-0 mr-0 mb-2 treatments-wr">
                    <div className="pl-1 pr-1 pt-1 pb-1 treatments-item-head">
                      <p style={{ fontSize: "14px", fontWeight: 700 }} className="mb-0">
                        {index + 1}
                      </p>
                    </div>
                    <div className="col-sm-3 pl-4 pr-2">
                      <TextInput
                        value={values.treatments[index].treatmentName}
                        htmlFor={`treatments.${index}.treatmentName`}
                        name={`treatments.${index}.treatmentName`}
                        className={
                          errors[`treatmentNameError${index}`] &&
                          get(touched, `treatments[${index}].treatmentName`)
                            ? "field-error"
                            : ""
                        }
                        onChange={({ target }) => {
                          const searchTerm = target.value;
                          setFieldValue(`treatments.${index}.treatmentName`, searchTerm);
                          searchTreatmentDebounce(searchTerm, index);
                        }}
                      />
                    </div>
                    <div className="col-sm-1 pl-1 pr-2">
                      <TextInput
                        value={values.treatments[index].quantity}
                        type="number"
                        htmlFor={`treatments.${index}.quantity`}
                        name={`treatments.${index}.quantity`}
                        min={1}
                        className={
                          errors[`quantityError${index}`] &&
                          get(touched, `treatments[${index}].quantity`)
                            ? "field-error"
                            : ""
                        }
                        onChange={({ target }) => {
                          const newQuantity = parseInt(target.value);

                          const systemPrice = Number(
                            values.treatments[index].systemPrice.replace(",", "") || 0,
                          );
                          const newSystemPriceTotals = isNaN(newQuantity)
                            ? 0
                            : newQuantity * systemPrice;

                          setFieldValue(`treatments.${index}.quantity`, newQuantity);
                          setFieldValue(
                            `treatments.${index}.systemPriceTotals`,
                            Utils.formatPrice(newSystemPriceTotals),
                          );
                        }}
                      />
                    </div>
                    <div className="col-sm-1 pl-1 pr-1">
                      <TextInput
                        defaultValue={values.treatments[index].unit}
                        type="number"
                        htmlFor={`treatments.${index}.unit`}
                        name={`treatments.${index}.unit`}
                        min={1}
                        step="0.25"
                        className={
                          errors[`unitError${index}`] && get(touched, `treatments[${index}].unit`)
                            ? "field-error"
                            : ""
                        }
                        onChange={({ target }) => {
                          const searchTerm = target.value;
                          setFieldValue(`treatments.${index}.unit`, searchTerm);
                        }}
                      />
                    </div>
                    <div className="col-sm-2 pl-1 pr-1">
                      <TextInput
                        defaultValue={values.treatments[index].systemPrice}
                        type="text"
                        htmlFor={`treatments.${index}.systemPrice`}
                        name={`treatments.${index}.systemPrice`}
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-sm-2 pl-1 pr-1">
                      <TextInput
                        value={values.treatments[index].systemPriceTotals}
                        type="text"
                        htmlFor={`treatments.${index}.systemPriceTotals`}
                        name={`treatments.${index}.systemPriceTotals`}
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-2 pl-1 pr-1">
                      <TextInput
                        value={values.treatments[index].packagePriceInt}
                        type="text"
                        htmlFor={`treatments.${index}.packagePriceInt`}
                        name={`treatments.${index}.packagePriceInt`}
                        onChange={({ target }) => {
                          const searchTerm = target.value
                            .replace(/[^\d.]/g, "")
                            .replace(/\./, "x")
                            .replace(/\./g, "")
                            .replace(/x/, ".");
                          setFieldValue(`treatments.${index}.packagePriceInt`, searchTerm);
                          setFieldValue(`treatments.${index}.packagePrice`, searchTerm);
                        }}
                        onBlur={() =>
                          setFieldValue(
                            `treatments.${index}.packagePriceInt`,
                            Utils.formatPrice(
                              values.treatments[index].packagePriceInt,
                              values.treatments[index].packagePrice,
                            ),
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-1 pl-1 pr-1">
                      <button
                        type="button"
                        onClick={() => remove(findIndexById(values.treatments, item.treatmentId))}
                        className="remove-treatement-btn"
                      >
                        Remove
                      </button>
                    </div>
                    {searchResults[index] && (treatmentsList || []).length > 0 && (
                      <ul className="list-group-treatments">
                        {treatmentsList?.map((item) => (
                          <li
                            key={item.id}
                            className="treatments-item"
                            onClick={() => {
                              setSearchResults([]);
                              dispatch(
                                getTreatmentItemPrice({
                                  item,
                                  patient,
                                  currentObj: values.treatments[index],
                                  newFieldId: values.treatments[index]?.treatmentId,
                                }),
                              );
                            }}
                          >
                            {truncate(`${item.name}-${item.description}`, { length: 110 })}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="row ml-0 mr-0 d-flex align-items-center pl-1 pr-1">
              <div className="col-sm-12 add-treatment-item-btn">
                <button
                  type="button"
                  onClick={() =>
                    push({
                      treatmentName: "",
                      quantity: "",
                      unit: "",
                      systemPrice: "",
                      systemPriceTotals: "",
                      packagePrice: "",
                      treatmentId: Utils.generateUUID(),
                    })
                  }
                >
                  <span>Add new</span>
                  <img alt="" src={plusIcon} />
                </button>
              </div>
            </div>
            <div className="row ml-0 mr-0 mb-3 d-flex align-items-center">
              <div className="col-sm-7 d-flex align-items-end pl-0">
                <div className="col-sm-8 pl-1 pr-1">
                  <TextInput
                    value={values.desiredPackageAmount}
                    type="number"
                    htmlFor="desiredPackageAmount"
                    name="desiredPackageAmount"
                    label="Enter Desired Package Amount:"
                    onChange={({ target }) => {
                      const searchTerm = target.value;
                      setFieldValue("desiredPackageAmount", searchTerm);
                    }}
                  />
                </div>
                <div className="col-sm-4 pl-1 pr-1">
                  <Button onClick={() => handleCalculatePackagePrices(values, setValues)}>
                    Calculate Package Prices
                  </Button>
                </div>
              </div>
              <div
                className="col-sm-2 d-flex align-items-center justify-content-start"
                style={{ fontSize: "14px", fontWeight: 700 }}
              >
                <div>Totals:</div>
                <div className="pl-3">{Utils.formatPrice(systemPriceTotal, systemPriceTotal)}</div>
              </div>
              <div
                className="col-sm-3"
                style={{ fontSize: "14px", fontWeight: 700, color: "#007bff" }}
              >
                Package Price Totals:{" "}
                {Utils.formatPrice(values.totalPackagePrice, values.totalPackagePrice)}
              </div>
            </div>
          </div>
        )}
      </FieldArray>
      <div className="d-flex justify-content-end p-2" style={{ borderTop: "1px solid #e5e5e5" }}>
        <Button
          className="mr-1"
          type="button"
          buttonType={ButtonType.Secondary}
          onClick={() => {
            closeHandler();
            setFieldValue("treatments", []);
          }}
        >
          Close
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </Form>
  );
};

export default SidebarProcedureFormChild;
