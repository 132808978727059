import React from "react";
import { useHistory } from "react-router";

export default function Back(props) {
  const history = useHistory();

  let className = "btn btn-link";

  if (props.className) {
    className += " " + props.className;
  }

  const onClick = () => {
    if (props.onClick && typeof props.onClick == "function") {
      props.onClick();
    } else {
      history.goBack();
    }
  };

  return (
    <button className={className} style={props.style} onClick={onClick} type="button">
      ❮ &nbsp;
      {props.title || "BACK"}
    </button>
  );
}
