import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MemberImage from "../widgets/MemberImage";

const PatientItemLinkComponent = styled.div`
  display: flex;
  color: #ffffff;

  & > .photo-container {
    width: 70px;
    height: 70px;
    margin-right: 12px;

    & > .member-image {
      height: 100%;
    }
  }

  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .doctor-name {
      align-self: flex-end;
    }
  }
`;

PatientItem.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  size: PropTypes.number,
};

export function PatientItem({ name, photo, size }) {
  return (
    <PatientItemLinkComponent>
      <div
        className="photo-container"
        style={{
          width: size,
          height: size,
        }}
      >
        <MemberImage size={size} photoUrl={photo} />
      </div>
      <div className="content">
        <span className="patient-name">{name}</span>
      </div>
    </PatientItemLinkComponent>
  );
}
