import React from "react";

export function SvgIcon({ width, height, viewBox, children }) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || `0 0 ${width} ${height}`}
    >
      {children}
    </svg>
  );
}
