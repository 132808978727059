import { isArray } from "lodash";
import React, { useMemo } from "react";

import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";
import { useDentalPointsListQuery } from "../../queries/member/useDentalPointsListQuery";

export interface DentalPointsSelectWrapperProps extends Omit<SelectPickerProps, "options"> {
  readonly allLabel?: string;
  readonly customClinicId?: number;
}

export function DentalPointsSelectWrapper({
  allLabel,
  customClinicId,
  ...props
}: DentalPointsSelectWrapperProps) {
  const dentalPointsQuery = useDentalPointsListQuery();

  const list = useMemo(() => {
    const pointsList = dentalPointsQuery.data || [];
    const newList = allLabel ? [{ label: allLabel }] : [];

    return newList.concat(pointsList.map((v) => ({ value: v.id, label: v.name })));
  }, [allLabel, dentalPointsQuery.data]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isLoading={dentalPointsQuery.isLoading}
      formatMultiValue={(x) => {
        if (isArray(x)) {
          return `Points (${x.length})`;
        }

        return x.label;
      }}
    />
  );
}
