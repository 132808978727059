import React, { useCallback, useEffect, useState } from "react";
import { isArray } from "lodash";
import { useDispatch } from "react-redux";

import { getMasterTypeApi } from "../../actions/calendarActions";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";

export type MasterTypeSelectWrapperProps = Omit<SelectPickerProps, "options">;

export function MasterTypeSelectWrapper(props: MasterTypeSelectWrapperProps) {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTypesHandler = useCallback(() => {
    setLoading(true);

    dispatch(getMasterTypeApi())
      .then((x: any = []) => {
        const newList = [{ label: "All" }];

        setList(newList.concat(x.map((v) => ({ value: v.id, label: v.name }))) as any);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    getTypesHandler();
  }, [getTypesHandler]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isLoading={loading}
      formatMultiValue={(x) => {
        if (isArray(x)) {
          return `Master Types (${x.length})`;
        }

        return x.label;
      }}
    />
  );
}
