import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";
import viewTypes from "../../../constants/chart-sidebar/viewTypes";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";
import clinicNoteItemTypes from "../../../constants/chart-sidebar/clinicNoteItemTypes";
import * as chartSideClinicalNoteActions from "../../../actions/chartSideClinicalNoteActions";
import { Modal } from "../../ui/Modal";
import { Button } from "../../ui/Button";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";

const enhancer = connect(
  null,
  bindActions({
    chartSideSeriesActions,
    chartActions,
    chartSideClinicalNoteActions,
  }),
);

class ChartDocumentsAssignedModal extends React.Component {
  openDocument = (document, index1, index2, treatment) => {
    return () => {
      this.props.chartSideSeriesActions.toggleDocument(index1, index2);

      if (document.active) {
        switch (document.templateType.code) {
          case "CONSENT_FORM":
            this.props.history.push(
              createUrl(Routes.PatientFormsEdit, {
                query: {
                  patientKey: this.props.patientKey,
                  templateId: document.templateItem.id,
                  doctorSign: true,
                },
              }),
            );
            break;
          case "CLINIC_NOTE":
            this.props.chartActions.showSideBar(viewTypes.clinicalNote);
            this.props.chartSideClinicalNoteActions.replaceItem(
              document.templateItem,
              clinicNoteItemTypes.new,
            );
            break;
          case "POST_OPERATION":
          case "LAB_ORDER":
            this.props.chartSideSeriesActions.openDocumentsAssignedInsideModal(document, treatment);
            break;
        }
      }
    };
  };

  render() {
    const { documents, documentsAssignedModalVisible } = this.props.chartSideSeries;

    if (!documentsAssignedModalVisible) {
      return null;
    }

    let $documents = [];
    documents.forEach((item, index1) => {
      if (!item.documents) return;

      $documents.push(
        <tr key={index1}>
          <td colSpan="3" style={{ backgroundColor: "#ccc" }}>
            <strong>
              {item.treatmentCode.code
                ? item.treatmentCode.code + " " + item.treatmentCode.description
                : item.treatmentCode.name}
            </strong>
          </td>
        </tr>,
      );

      item.documents.forEach((document, index2) => {
        let $arrow = <div className="arrow" />;

        if (document.active) {
          $arrow = <div className="arrow">&#10004;</div>;
        }

        $documents.push(
          <tr
            key={`${index1}-${index2}`}
            onClick={this.openDocument(document, index1, index2, item.treatmentCode)}
            style={{ cursor: "pointer" }}
          >
            <td>{$arrow}</td>
            <td>{document.templateItem.name}</td>
            <td className="text-center">{document.templateType.name}</td>
          </tr>,
        );
      });
    });

    return (
      <Modal
        title="Documents assigned to this treatment"
        size="lg"
        show={true}
        keyboard={false}
        actions={
          <div>
            <Button
              buttonType="secondary"
              onClick={() => {
                this.props.chartSideSeriesActions.closeDocumentsAssignedModal();
                // this.props.chartActions.showSideBar(viewTypes.series, {
                //   activeTooth: this.props.activeTooth,
                // });
              }}
            >
              Skip
            </Button>
            <Button
              onClick={() => {
                this.props.chartSideSeriesActions.closeDocumentsAssignedModal();
                // this.props.chartActions.showSideBar(viewTypes.series, {
                //   activeTooth: this.props.activeTooth,
                // });
              }}
            >
              Done
            </Button>
          </div>
        }
      >
        <table className="table documents-assigned-table">
          <tbody>
            <tr>
              <th />
              <th>Document Name</th>
              <th className="text-center">Type</th>
            </tr>
            {$documents}
          </tbody>
        </table>
      </Modal>
    );
  }
}

export default withRouter(enhancer(ChartDocumentsAssignedModal));
