import _ from "lodash";
import React from "react";

import { Field, reduxForm } from "redux-form";

import * as SelectTypes from "../../../constants/RepeatAppointmentDataTypes";

import RepeatAppointmentRepeatModal from "./RepeatAppointmentRepeatModal";
import RepeatAppointmentConfirmModal from "./RepeatAppointmentConfirmModal";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as appointmentActions from "../../../actions/appointmentActions";
import { SelectPicker } from "../../select-picker/SelectPicker";
import DeleteImage from "../../../assets/images/appointment/btn-delete.png";
import BtnRepeatImage from "../../../assets/images/appointment/btn-repeat.png";
import BtnRepeatGreenImage from "../../../assets/images/appointment/btn-repeat-green.png";

const enhancer = connect(null, bindActions({ appointmentActions }));
const form = reduxForm({
  form: "repeatAppointment",
});

class RepeatAppointmentFollowAfter extends React.PureComponent {
  render() {
    const {
      disabled,
      repeatAppointmentList,
      repeatAppointment,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalIndex,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentDeleteId,
    } = this.props;

    let selectOptions = SelectTypes.SelectValues.map((item) => ({
      value: item.code,
      label: item.name,
    }));

    let itemsOptions = [];

    if (repeatAppointment && repeatAppointment.values && repeatAppointment.values.mainSelect) {
      itemsOptions = SelectTypes.DataTypes[repeatAppointment.values.mainSelect].map((item) => ({
        value: item.value,
        label: item.label,
      }));
    }

    let list = [];

    if (!_.isEmpty(repeatAppointmentList)) {
      list = repeatAppointmentList.map((item, index) => {
        let btnRepeatSelectClassName = "btn btn-repeat-repeat";
        let repeatClickType = "repeat";
        let src = BtnRepeatImage;

        if (repeatAppointmentClickedRepeatArray.indexOf(item.id) !== -1) {
          repeatClickType = "delete";
          btnRepeatSelectClassName += " btn-repeat-repeat-selected";
          src = BtnRepeatGreenImage;
        }

        return (
          <div className="clearfix" key={index}>
            <div className="col-sm-2">
              <button
                type="button"
                disabled={disabled}
                onClick={() => {
                  this.props.onRepeatClick(index, repeatClickType, item.id);
                }}
                className={btnRepeatSelectClassName}
              >
                <img src={src} height={32} alt="" />
              </button>
            </div>
            <div className="col-sm-3">
              <div className="repeat-appointment-item-after">
                <div className="Select-placeholder">{item.period}</div>
                <span className="Select-arrow-zone">
                  <span className="Select-arrow" />
                </span>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="repeat-appointment-item-after">
                <div className="Select-placeholder">{item.periodUnit && item.periodUnit.name}</div>
                <span className="Select-arrow-zone">
                  <span className="Select-arrow" />
                </span>
              </div>
            </div>
            <div className="col-sm-2">
              <button
                type="button"
                disabled={disabled}
                onClick={() => {
                  this.props.onRepeatClick(index, "delete", item.id);
                }}
                className="btn btn-repeat-delete"
              >
                <img src={DeleteImage} className="img-responsive" alt="" />
              </button>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="repeat-appointment-main-block">
        <div className="repeat-appointment-list clearfix">{list}</div>
        <div className="repeat-appointment-after-form">
          <div className="col-sm-4">
            <Field
              component={(x) => (
                <SelectPicker
                  size="lg"
                  value={{ value: x.input.value }}
                  onChange={(v) => x.input.onChange(v.value)}
                  disabled={disabled}
                  options={itemsOptions}
                  placeholder="--"
                />
              )}
              name="mainItem"
              id="mainItem"
            />
          </div>
          <div className="col-sm-8">
            <Field
              component={(x) => (
                <SelectPicker
                  size="lg"
                  value={{ value: x.input.value }}
                  onChange={(v) => x.input.onChange(v.value)}
                  placeholder="Choose period"
                  options={selectOptions}
                  disabled={disabled}
                />
              )}
              name="mainSelect"
              id="mainSelect"
            />
          </div>
          <div className="col-sm-12">
            <button
              type="button"
              disabled={disabled}
              onClick={this.props.onClickAdd}
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </div>
        <RepeatAppointmentRepeatModal
          onClickUpdate={this.props.onClickUpdate}
          show={repeatAppointmentRepeatModalVisible}
          repeatAppointmentRepeatModalMode={repeatAppointmentRepeatModalMode}
          repeatAppointmentRepeatModalDate={repeatAppointmentRepeatModalDate}
          repeatAppointmentList={repeatAppointmentList}
          index={repeatAppointmentRepeatModalIndex}
        />
        <RepeatAppointmentConfirmModal
          text="Are you sure you want to delete this entry? This action cannot be undone."
          handleClose={this.props.appointmentActions.repeatAppointmentConfirmModalHide}
          handleOK={() => {
            this.props.onDeleteClick(repeatAppointmentDeleteId);
          }}
          show={repeatAppointmentConfirmModalVisible && !disabled}
        />
      </div>
    );
  }
}

export default enhancer(form(RepeatAppointmentFollowAfter));
