import createReducer from "../helpers/createReducer";
import { SET_POPUP, SET_POPUP_ACTION_BY_KEY, SET_POPUP_PARAMS } from "../actions/popupActions";
import Element from "../models/Element";

const defaultState = {
  small: {
    self: null,
    title: new Element(""),
    buttons: [new Element("Ok")],
  },
  large: {
    self: null,
    title: new Element(""),
    buttons: [new Element("Ok")],
  },
  preventClosing: false,
};

const reducers = {
  [SET_POPUP_PARAMS](state, { popupType, value, visible, titleValue, buttons, preventClosing }) {
    state[popupType].self = new Element(value);
    state[popupType].self.visible = visible;
    state[popupType].title = new Element(titleValue);
    if (buttons) {
      state[popupType].buttons = buttons;
    }
    state.preventClosing = preventClosing || false;
    return { ...state };
  },
  [SET_POPUP_ACTION_BY_KEY](state, { popupType, key, value }) {
    switch (key) {
      case "visible":
        state[popupType].self.visible = value;
        break;
    }

    return {
      ...state,
      preventClosing: false,
    };
  },
  [SET_POPUP](state, { popupType, popup }) {
    state[popupType] = popup;
    return { ...state };
  },
};

/** ==================
 * Selectors
 * ================== */
export const popupSmallSelector = ({ popup }) => popup.small || {};
export const popupLargeSelector = ({ popup }) => popup.large || {};
export const popupPreventClosingSelector = ({ popup }) => popup.preventClosing;

export default createReducer(defaultState, reducers);
