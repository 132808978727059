import createReducer from "../helpers/createReducer";

import {
  GET_DASHBOARD_DATA_SUCCESS,
  GET_NEWS_LIST_SUCCESS,
  GET_DOCTOR_PRODUCTION_FORM_DATA_SUCCESS,
  GET_DOCTOR_PRODUCTION_REPORT_SUCCESS,
  DASHBOARD_GET_DENTIST_SUCCESS,
} from "../actions/dashboardActions";
import * as ActionTypes from "../actionTypes/dashboardActionTypes";
import { Routes } from "../constants/Routes";

const defaultState = {
  appointmentForTodayCount: 0,
  appointmentInWaitingCount: 0,
  patientAllCount: 0,
  patientNewForTodayCount: 0,
  news: [],
  dataTypes: [],
  viewTypes: [],
  report: {
    items: [],
  },
  doctorDetails: {},
  prevPath: Routes.DashboardProfile,
  profile: {},
};

const reducers = {
  [GET_DASHBOARD_DATA_SUCCESS](state, { data }) {
    return { ...state, ...data };
  },
  [GET_DOCTOR_PRODUCTION_FORM_DATA_SUCCESS](state, { data }) {
    return { ...state, ...data };
  },
  [GET_DOCTOR_PRODUCTION_REPORT_SUCCESS](state, { data }) {
    return { ...state, report: data };
  },
  [GET_NEWS_LIST_SUCCESS](state, { data }) {
    return { ...state, news: data.list || [] };
  },
  [DASHBOARD_GET_DENTIST_SUCCESS](state, { data }) {
    return {
      ...state,
      doctorDetails: data,
    };
  },
  [ActionTypes.DASHBOARD_INIT_PROFILE_EDIT](state, { prevPath }) {
    return {
      ...state,
      prevPath,
    };
  },
  [ActionTypes.DASHBOARD_GET_PROFILE_SUCCESS](state, { data }) {
    return {
      ...state,
      profile: data,
    };
  },
  [ActionTypes.DASHBOARD_SAVE_SIGNATURE_SUCCESS](state, { request }) {
    return {
      ...state,
      profile: {
        ...state.profile,
        signature: request.signature,
      },
    };
  },
};

export default createReducer(defaultState, reducers);
