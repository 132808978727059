import _ from "lodash";
import React from "react";

import InsuranceShowPhotoSidebar from "./InsuranceShowPhotoSidebar";

import { FULL_DATE_FORMAT, NOT_AVAILABLE, PLEASE_SELECT } from "../../constants/Constants";
import InputField from "../widgets/InputField";
import ChoosePhotoModal from "./ChoosePhotoModal";

import Utils from "../../helpers/Utils";
import { CustomInsurancePlanButton } from "./CustomInsurancePlanButton";
import CustomSelectBox from "../widgets/CustomSelectBox";
import { DatePicker } from "../ui/DatePicker";

export default class PrimaryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDatePicker: false,
    };
  }
  render() {
    const {
      elements,
      saveHandler,
      photoHandler,
      showModal,
      selectedForm,
      container,
      photoSidebarVisible,
      photoSidebarHandler,
      photoSidebarImageIndex,
      item,
      header,
      patientDetails: { chartNumber = "" },
      extraClass,
    } = this.props;
    const isEditMode = header && header.editBtn.selected;

    const formName = elements.form.name;
    const formImages = container[formName];
    const image = [];

    if (!_.isEmpty(formImages)) {
      _.forEach(formImages, (value, key) => {
        if (!_.isEmpty(formImages[key])) {
          image.push(
            <div
              onClick={() => {
                photoSidebarHandler(key);
              }}
              key={key}
              className="form-image"
            >
              <img src={formImages[key].url} className="img-responsive" alt="" />
            </div>,
          );
        }
      });
    }

    return elements.form.visible ? (
      <div>
        <form
          className={`insurance-form-item col-sm-offset-2 ${elements.form.className} ${extraClass}`}
          onSubmit={(e) => {
            saveHandler(e, elements.form.name);
          }}
        >
          <div className="row">
            <div className="col-sm-10">
              <h3 className="form-title">
                {elements.form.value}, chart: {chartNumber}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5" style={{ zIndex: 20 }}>
              <label>
                <span className="label">{elements.relationSB.name}</span>

                <InputField element={elements.relationSB} />
              </label>
            </div>

            <div className="col-sm-5">
              <label>
                <span className="label">{elements.groupNumber.name}</span>

                <InputField
                  element={elements.groupNumber}
                  onChange={elements.groupNumber.handler}
                />
              </label>
            </div>

            <div className="col-sm-5" style={{ zIndex: 15 }}>
              <label>
                <span className="label">{`${elements.insuranceAliasSB.name}`}</span>
                {isEditMode ? (
                  <CustomSelectBox searchable={true} selectBox={elements.insuranceAliasSB} />
                ) : (
                  <span className="item-value">
                    {elements.insuranceAliasSB.value || NOT_AVAILABLE}
                  </span>
                )}
              </label>
            </div>

            <div className="col-sm-5">
              <label className="col-field">
                <span className="label">{elements.subscriberID.name}</span>
                <InputField
                  element={elements.subscriberID}
                  onChange={elements.subscriberID.handler}
                />
              </label>
              <label className="col-field small-field">
                <span className="label">{elements.subscriberName.name}</span>
                <InputField
                  element={elements.subscriberName}
                  onChange={elements.subscriberName.handler}
                />
              </label>
            </div>

            <div className="col-sm-5" style={{ zIndex: 10 }}>
              <label>
                <span className="label">{`RECEIVER ID | ${elements.insuranceCompanySB.name}`}</span>
                {isEditMode ? (
                  <CustomSelectBox searchable={true} selectBox={elements.insuranceCompanySB} />
                ) : (
                  <span className="item-value">
                    {elements.insuranceCompanySB.value || NOT_AVAILABLE}
                  </span>
                )}
              </label>
            </div>

            <div className="col-sm-5">
              <label>
                {/* elements.maxUsageAmount.name */}
                <span className="label">Dental Usage Limit</span>
                <InputField
                  element={elements.maxUsageAmount}
                  onChange={elements.maxUsageAmount.handler}
                />
              </label>
            </div>

            <div style={{ display: "flex" }} className="col-sm-5">
              <label style={{ flex: "1 1 0%" }}>
                <span className="label">{elements.insurancePlanSB.name}</span>
                {isEditMode ? (
                  <CustomSelectBox searchable={true} selectBox={elements.insurancePlanSB} />
                ) : (
                  <span className="item-value">
                    {elements.insurancePlanSB.value || NOT_AVAILABLE}
                  </span>
                )}
              </label>

              <CustomInsurancePlanButton
                formName={formName}
                planId={_.get(item, "id")}
                patientKey={_.get(item, "patientKey")}
                insuranceCompany={_.get(item, "insuranceCompany.name")}
                insurancePlanRowExist={_.get(item, "insurancePlanRowExist")}
                insuranceCompanyDetails={_.get(item, "insuranceCompanyDetails.name")}
              />
            </div>

            <div className="col-sm-5">
              <label className="col-field small-field">
                <span className="label">{elements.division.name}</span>

                <InputField element={elements.division} onChange={elements.division.handler} />
              </label>
              <label className="col-field small-field">
                <span className="label">{elements.dependentCode.name}</span>
                <InputField
                  element={elements.dependentCode}
                  onChange={elements.dependentCode.handler}
                />
              </label>
            </div>

            <div className={`col-sm-5 ${elements.cardNumber.className}`}>
              <label>
                <span className="label">{elements.cardNumber.name}</span>

                <InputField element={elements.cardNumber} onChange={elements.cardNumber.handler} />
                <InputField
                  className="btn-success btn-card-number"
                  element={elements.searchDepCodeBtn}
                  onChange={elements.searchDepCodeBtn.handler}
                />
              </label>
            </div>

            <div className="col-sm-5">
              <label>
                <span className="label">{elements.expiryDate.name}</span>

                {isEditMode && (
                  <DatePicker
                    wrapperClassName="w-100"
                    // open={this.state.openDatePicker}
                    // shouldCloseOnSelect={true}
                    placeholderText={PLEASE_SELECT}
                    className="form-control input-lg"
                    selected={elements.expiryDate.value}
                    disabled={elements.expiryDate.disabled}
                    // onFocus={() => {
                    //   this.setState({
                    //     openDatePicker: true,
                    //   });
                    // }}
                    onChange={(e) => {
                      elements.expiryDate.handler(e, elements.expiryDate);
                      // this.setState({
                      //   openDatePicker: false,
                      // });
                    }}
                  />
                )}

                {!isEditMode && (
                  <span className="item-value">
                    {_.get(elements, "expiryDate.value") &&
                      Utils.parseDateFormat(
                        Utils.calcDateWithTimeZone(_.get(elements, "expiryDate.value")),
                        FULL_DATE_FORMAT,
                      )}
                  </span>
                )}
              </label>
            </div>

            <div className="col-sm-5">
              <label>
                <span className="label">{elements.cardSequence.name}</span>
                <InputField
                  element={elements.cardSequence}
                  onChange={elements.cardSequence.handler}
                />
              </label>
            </div>
            <div className="col-sm-5">
              <label>
                <span className="label">{`PAYER ID | ${elements.brokerSB.name}`}</span>
                {isEditMode ? (
                  <CustomSelectBox searchable={true} selectBox={elements.brokerSB} />
                ) : (
                  <span className="item-value">{elements.brokerSB.value || NOT_AVAILABLE}</span>
                )}
                {/*<InputField element={elements.brokerSB} />*/}
              </label>
            </div>
            <div className={`col-sm-5 ${elements.discount.className}`}>
              <label>
                <span className="label">{elements.discount.name}</span>
                {isEditMode ? (
                  <InputField
                    type="number"
                    element={elements.discount}
                    onChange={elements.discount.handler}
                  />
                ) : (
                  <span className="item-value">{elements.discount.value || NOT_AVAILABLE}</span>
                )}
              </label>
            </div>
            <div className="col-sm-5">
              <label>
                <span className="label">{elements.reImbursementSB.name}</span>
                {isEditMode ? (
                  <CustomSelectBox searchable={true} selectBox={elements.reImbursementSB} />
                ) : (
                  <span className="item-value">
                    {elements.reImbursementSB.value || NOT_AVAILABLE}
                  </span>
                )}
              </label>
            </div>

            {!isEditMode && (
              <div>
                <div className="col-sm-5">
                  <label className="col-field">
                    <span className="label">Created date</span>
                    <span className="item-value">
                      {_.get(item, "createdDate") &&
                        Utils.parseDateFormat(
                          Utils.calcDateWithTimeZone(_.get(item, "createdDate")),
                          FULL_DATE_FORMAT,
                        )}
                    </span>
                  </label>
                  <label className="col-field">
                    <span className="label">Last modified date</span>
                    <span className="item-value">
                      {_.get(item, "modifiedDate") &&
                        Utils.parseDateFormat(
                          Utils.calcDateWithTimeZone(_.get(item, "modifiedDate")),
                          FULL_DATE_FORMAT,
                        )}
                    </span>
                  </label>
                </div>
                <div className="col-sm-5" />
                <div className="col-sm-5">
                  <label className="col-field" style={{ width: "100%" }}>
                    <span className="label">Description</span>
                    <div
                      className="item-value"
                      style={{
                        maxHeight: "124px",
                        overflowY: "auto",
                        borderRadius: "5px",
                      }}
                    >
                      {_.get(item, "insurancePlanNotes") ? _.get(item, "insurancePlanNotes") : ""}
                    </div>
                  </label>
                </div>
              </div>
            )}
            {elements.images.visible && image.length > 0 && (
              <div className="col-sm-10">
                <label>
                  <span className="label">Images:</span>
                  <div className="form-images">{image}</div>
                </label>
              </div>
            )}
          </div>
          <div className="row button-wrap">
            <div className="col-sm-10">
              {elements.makeAsPrimaryBtn && (
                <InputField
                  type="button"
                  className="btn-success"
                  element={elements.makeAsPrimaryBtn}
                />
              )}
              <InputField type="button" className="btn-danger" element={elements.deleteBtn} />
              <InputField type="submit" className="btn-success" element={elements.saveBtn} />
              {elements.addAnotherBtn && (
                <InputField
                  type="button"
                  className="btn-success"
                  element={elements.addAnotherBtn}
                />
              )}
              <InputField
                type="button"
                className="btn-photo"
                onClick={(e) => {
                  photoHandler(e, elements.form.name);
                }}
                element={elements.photoBtn}
              />
            </div>
          </div>
        </form>
        <ChoosePhotoModal show={showModal} selectedForm={selectedForm} container={container} />
        <InsuranceShowPhotoSidebar
          images={formImages}
          imageIndex={photoSidebarImageIndex}
          show={photoSidebarVisible}
        />
      </div>
    ) : null;
  }
}
