import uuid from "node-uuid";
import Utils from "./helpers/Utils";
import seriesTypes from "./constants/chart-sidebar/seriesTypes";
import { defaultStatusColors, defaultTypeColors, defaultBTStypeColors } from "./constants/defaults";
import { PostedByTypes, TreatmentGrouppingTypes } from "./constants/Constants";
import { CURRENT_COUNTRY_NAME, CURRENT_COUNTRY_PHONE_CODE } from "./constants/PhoneConstants";
import { LIVE_HOST } from "./constants/host";
import { TransactionGroupByTypes } from "./components/settings/TransactionGroupBy";

/* eslint-disable */

// callListNoResponse
const callListNoResponse = JSON.parse(localStorage.getItem("callListNoResponse") || "{}");
Utils.objectForEach(callListNoResponse, (map, date) => {
  if (date < Date.now()) {
    delete callListNoResponse[date];
  }
});

localStorage.setItem("callListNoResponse", JSON.stringify(callListNoResponse));

if (!localStorage.getItem("timezone")) {
  localStorage.setItem("timezone", -new Date().getTimezoneOffset() * 60 * 1000);
}

if (!localStorage.getItem("appointmentColorType")) {
  localStorage.setItem("appointmentColorType", "type");
}

if (!localStorage.getItem("postedBy")) {
  localStorage.setItem("postedBy", PostedByTypes.all);
}

if (!localStorage.getItem("columnView")) {
  localStorage.setItem("columnView", "comfort");
}

if (!localStorage.getItem("rowView")) {
  localStorage.setItem("rowView", "comfort");
}

if (!localStorage.getItem("timeView")) {
  localStorage.setItem("timeView", "time");
}

if (!localStorage.getItem("treatmentSideBarView")) {
  localStorage.setItem("treatmentSideBarView", "list");
}

if (!localStorage.getItem("quickMode")) {
  localStorage.setItem("quickMode", "true");
}

if (!localStorage.getItem("d_token")) {
  localStorage.setItem("d_token", `APP${uuid.v4()}`);
}

// Consultaion Notes favourites
if (!localStorage.getItem("ConsultaionFavourites")) {
  localStorage.setItem("ConsultaionFavourites", "{}");
}

if (!localStorage.getItem("PrescriptionFavourites")) {
  localStorage.setItem("PrescriptionFavourites", "{}");
}

// favourites codes
if (!localStorage.getItem("favourites")) {
  localStorage.setItem("favourites", "{}");
}

const favourites = JSON.parse(localStorage.getItem("favourites"));

Utils.objectForEach(seriesTypes, (seriesType) => {
  if (!favourites[seriesType.code]) favourites[seriesType.code] = {};

  const codesToDelete = [];
  _.forEach(favourites[seriesType.code], (item, code) => {
    if (!item.selected) {
      codesToDelete.push(code);
    }
  });

  codesToDelete.forEach((code) => {
    delete favourites[seriesType.code][code];
  });
});
localStorage.setItem("favourites", JSON.stringify(favourites));

// Settings colors
if (!localStorage.getItem("appointmentStatus")) {
  localStorage.setItem("appointmentStatus", JSON.stringify(defaultStatusColors));
}
if (!localStorage.getItem("appointmentType")) {
  localStorage.setItem("appointmentType", JSON.stringify(defaultTypeColors));
}
if (!localStorage.getItem("blockedTimeSlotType")) {
  localStorage.setItem("blockedTimeSlotType", JSON.stringify(defaultBTStypeColors));
}
if (!localStorage.getItem("appointmentDoctor")) {
  localStorage.setItem("appointmentDoctor", "{}");
}

if (!localStorage.getItem("favouritesClinicalNote")) {
  localStorage.setItem("favouritesClinicalNote", "{}");
}

if (!localStorage.getItem("calendarPoints")) {
  localStorage.setItem("calendarPoints", "{}");
}

// misc settings
if (!localStorage.getItem("doctorLabel")) {
  localStorage.setItem("doctorLabel", "Doctor");
}

if (!localStorage.getItem("nurseLabel")) {
  localStorage.setItem("nurseLabel", "Nurse");
}

if (!localStorage.getItem("phoneCode")) {
  localStorage.setItem("phoneCode", CURRENT_COUNTRY_PHONE_CODE);
}

if (!localStorage.getItem("countryName")) {
  localStorage.setItem("countryName", CURRENT_COUNTRY_NAME);
}

if (!localStorage.getItem("appointmentHideButtons")) {
  localStorage.setItem("appointmentHideButtons", "false");
}

if (!localStorage.getItem("showFullNote")) {
  localStorage.setItem("showFullNote", "true");
}

if (!localStorage.getItem("host")) {
  localStorage.setItem("host", LIVE_HOST);
}

if (!localStorage.getItem("transactionGroupBy")) {
  localStorage.setItem("transactionGroupBy", TransactionGroupByTypes.date);
}

if (!localStorage.getItem("transactionsToCompleteGroupBy")) {
  localStorage.setItem("transactionsToCompleteGroupBy", TransactionGroupByTypes.date)
}

if (!localStorage.getItem("treatmentGroupping")) {
  localStorage.setItem("treatmentGroupping", TreatmentGrouppingTypes.none);
}
