import _ from "lodash";
import React from "react";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import Utils from "../../../helpers/Utils";
import DeleteImage from "../../../assets/images/chart/delete.png";

export default function ConsultationNotesItem(props) {
  return (
    <div className="consultation-note-item" onClick={() => props.openItem(props.item.id)}>
      <div className="consultation-data">
        <span className="consultation-data-name">{props.item.name || ""}</span>
        <span>
          by
          {_.get(props, "item.owner.name") || ""}
        </span>
        <span>
          {Utils.parseDateFormat(
            Utils.calcDateWithTimeZone(props.item.createdDate),
            DATE_FORMAT_LEGACY,
          )}
        </span>
      </div>
      <div className="consultation-control">
        <div className="control-delete">
          <div
            title="Delete Note"
            className="delete"
            onClick={(e) => props.deleteItem(e, props.item.id)}
          >
            <img src={DeleteImage} width={17} height={17} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
