import _ from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "./../widgets/Show";

import { connect } from "react-redux";
import {
  deleteImage,
  hideChoosePhotoModal,
  selectImage,
  uploadPhoto,
} from "../../actions/insuranceActions";
import bindActions from "../../helpers/bindActions";
import { Dropzone } from "../ui/Dropzone";
import addPhotoImage from "../../assets/images/insurance/add_photo@2x.png";
import noPhotoImage from "../../assets/images/insurance/no_photo@2x.png";
import DeleteImage from "../../assets/images/insurance/delete_photo.png";

const enhancer = connect(
  null,
  bindActions({
    hideChoosePhotoModal,
    deleteImage,
    selectImage,
    uploadPhoto,
  }),
);
class ChoosePhotoModal extends React.Component {
  deleteHandler = (id) => {
    this.props.deleteImage(id);
  };

  dropHandler = (files, id = "") => {
    this.props.selectImage(id);
    this.props.uploadPhoto(files);
  };

  render() {
    const { selectedForm, show, container } = this.props;

    if (show) {
      const formName = selectedForm; //=== 'form1' && 'form2' || 'form1';
      var formImages = container[formName];

      const modalButtons = [
        <Button key="1" className="btn-primary" onClick={this.props.hideChoosePhotoModal}>
          Close
        </Button>,
      ];
      const modalImages = [];
      let keys = ["image1", "image2", "image3"];

      if (_.isEmpty(formImages)) {
        formImages = {
          image1: {},
          image2: {},
          image3: {},
        };
      }

      keys.forEach((element, index, array) => {
        modalImages.push(
          <div key={element} className="insurance-image">
            <Show if={!_.isEmpty(formImages[element])}>
              <div
                className="delete-button"
                onClick={() => {
                  this.deleteHandler(element);
                }}
              >
                <img src={DeleteImage} alt="" width={20} height={20} />
              </div>
            </Show>
            <Show if={!_.isEmpty(formImages[element])}>
              <Dropzone
                className="dropzone"
                onDrop={(files) => {
                  this.dropHandler(files, element);
                }}
                multiple={false}
              >
                <div className="image-block">
                  <img
                    width={120}
                    height={120}
                    src={formImages[element] && formImages[element].url}
                    className="img-responsive"
                    alt=""
                  />
                </div>
              </Dropzone>
            </Show>
            <Show
              if={
                _.isEmpty(formImages[element]) &&
                (index == 0 || !_.isEmpty(formImages[array[index - 1]]))
              }
            >
              <Dropzone
                className="dropzone"
                onDrop={(files) => {
                  this.dropHandler(files);
                }}
                multiple={false}
              >
                <div className="image-block">
                  <img
                    width={120}
                    height={120}
                    src={addPhotoImage}
                    alt=""
                    className="img-responsive"
                  />
                </div>
              </Dropzone>
            </Show>
            <Show
              if={
                index != 0 &&
                _.isEmpty(formImages[element]) &&
                _.isEmpty(formImages[array[index - 1]])
              }
            >
              <div className="image-block">
                <img
                  width={120}
                  height={120}
                  src={noPhotoImage}
                  className="img-responsive"
                  alt=""
                />
              </div>
            </Show>
          </div>,
        );
      });

      return (
        <Modal
          className="choose-photo-modal"
          show={show}
          keyboard={false}
          onHide={this.props.hideChoosePhotoModal}
          actions={
            <Button type="button" onClick={this.props.hideChoosePhotoModal}>
              Close
            </Button>
          }
        >
          <div>
            <div className="insurance-list-photo">
              {modalImages}
              <div className="clearbox" />
            </div>
            {modalButtons}
          </div>
        </Modal>
      );
    }

    return null;
  }
}
export default enhancer(ChoosePhotoModal);
