import MainApi from "../api-legacy/MainApi";

export const GET_SESSION_START = "GET_SESSION_START";
export const GET_SESSION_ERROR = "GET_SESSION_ERROR";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";

export const GET_SELECT_PICKER_ASYNC_OPTIONS_START = "GET_SELECT_PICKER_ASYNC_OPTIONS_START";
export const GET_SELECT_PICKER_ASYNC_OPTIONS_ERROR = "GET_SELECT_PICKER_ASYNC_OPTIONS_ERROR";
export const GET_SELECT_PICKER_ASYNC_OPTIONS_SUCCESS = "GET_SELECT_PICKER_ASYNC_OPTIONS_SUCCESS";

export function getSession(data) {
  return {
    api: MainApi.getSessionData,
    types: [GET_SESSION_START, GET_SESSION_SUCCESS, GET_SESSION_ERROR],
    data,
  };
}

export const getSelectPickerAsyncOptions = (data) => (dispatch) =>
  dispatch({
    api: MainApi.getSelectPickerAsyncOptions,
    types: [
      GET_SELECT_PICKER_ASYNC_OPTIONS_START,
      GET_SELECT_PICKER_ASYNC_OPTIONS_SUCCESS,
      GET_SELECT_PICKER_ASYNC_OPTIONS_ERROR,
    ],
    data,
  });
