import React from "react";
import { Field, reduxForm } from "redux-form";
import { SelectPicker } from "../select-picker/SelectPicker";

const enhancer = reduxForm({
  enableReinitialize: true,
  form: "RequestLabOrderForm",
});

export const RequestLabOrderForm = enhancer(({ handleSubmit, patientGenderList }) => {
  return (
    <form
      onSubmit={handleSubmit}
      name="RequestLabOrderForm"
      className="chart-clinical-note"
      style={{ width: "100%", marginTop: "12px" }}
    >
      <div className="form-group">
        <div className="col-sm-12">
          <label className="control-label" htmlFor="chartNumber">
            Patient Chart:
          </label>
          <Field
            type="text"
            id="chartNumber"
            component="input"
            name="chartNumber"
            autoComplete="off"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label className="control-label" htmlFor="name">
            Patient Name:
          </label>
          <Field
            id="name"
            type="text"
            name="name"
            component="input"
            autoComplete="off"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label htmlFor="gender">Gender:</label>
          <Field
            id="gender"
            name="gender"
            component={({ input }) => (
              <SelectPicker
                options={patientGenderList}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label className="control-label" htmlFor="orderId">
            Order ID:
          </label>
          <Field
            id="orderId"
            type="text"
            name="orderId"
            component="input"
            autoComplete="off"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label htmlFor="notes">Doctor Notes:</label>
          <Field id="notes" name="notes" component="textarea" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label htmlFor="treatmentGroup">Treatment Group:</label>
          <Field
            id="treatmentGroup"
            name="treatmentGroup"
            component={({ input }) => (
              <SelectPicker
                options={[]}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12">
          <label htmlFor="treatmentCode">Treatment Code & Description:</label>
          <Field
            id="treatmentCode"
            name="treatmentCode"
            component={({ input }) => (
              <SelectPicker
                options={[]}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
});

RequestLabOrderForm.propTypes = {};
