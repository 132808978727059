import React from "react";
import { connect } from "react-redux";
import { TextInput } from "../../ui/TextInput";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { withRouter } from "react-router";

const enhancer = connect(({ chart, session: { clinic, member }, auth: { doctors } }) => ({
  chart,
  clinic,
  member,
  doctors,
}));
const AdvancedPaymentForm = (props) => {
  const { form, doctors, setShowList } = props;
  const doctorOptions = doctors
    .filter((item) => item.status.name === "Activated" && item.subType?.code === "DOCTOR")
    .map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }));

  return (
    <div>
      <form onSubmit={form.handleSubmit}>
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-success" onClick={() => setShowList(true)}>
            Add copay
          </button>
        </div>
        <div className="form-group">
          <label className="d-flex m-0 flex-column">
            Amount:
            <TextInput
              type="number"
              onChange={({ target }) => form.setFieldValue("amount", target.value)}
              value={form.values.amount}
            />
          </label>
        </div>
        <div className="form-group">
          <label className="d-flex m-0 flex-column">
            Requested by:
            <SelectPicker
              options={doctorOptions}
              onChange={(x) => form.setFieldValue("initiatorId", x)}
              value={form.values.initiatorId}
            />
          </label>
        </div>
        <div className="form-group">
          <label className="d-flex m-0 flex-column">
            Note:
            <textarea
              className="form-control"
              onChange={({ target: { value } }) => form.setFieldValue("description", value)}
              value={form.values.description}
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default enhancer(withRouter(AdvancedPaymentForm));
