import _ from "lodash";
import React from "react";
import { SidebarFavoritesList } from "./SidebarFavoritesList";

import { SidebarList } from "./SidebarList";
import { SidebarItem } from "./SidebarItem";
import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import { SidebarCategoriesList } from "./SidebarCategoriesList";
import { SidebarContentActions } from "./SidebarContentActions";
import { SidebarContentContainer } from "./SidebarContentContainer";
import ChartImageSelector from "../chart/side-bar/ChartImageSelector";
import { SidebarCategoryChildList } from "./SidebarCategoryChildList";
import { SidebarTreatmentDiagnosis } from "./SidebarTreatmentDiagnosis";

export class SidebarTreatments extends React.PureComponent {
  render() {
    const {
      item,
      getList,
      isItem,
      onSort,
      member,
      viewTab,
      onSearch,
      loadList,
      openItem,
      initItem,
      stack = [],
      favourites,
      isQuickMode,
      searchValue,
      openCategory,
      getFavorites,
      addDiagnosis,
      toothNumericType,
      onActionClick,
      openFavorites,
      toggleFavorite,
      chartSideSeries,
      loadCategoryList,
      showImageSelector,
      categoryChildStack,
      onRequestClose,
      changeActiveTooth,
      backFromItem,
      popStack,
      pushStack,
      inAppointment,
      changeTypeTab,
      addRequestedTreament,
      getSidebarDiagnosisList,
      getSidebarTreatmentList,
    } = this.props;

    const {
      favouritesIcons,
      activeFavouriteCode,
      imageSelectorVisible,
      sidebarCategoryList = [],
      activeFavouriteSeriesType,
    } = chartSideSeries;

    const isAllCodes = _.last(stack) === SidebarStackTypes.AllCodes;
    const isFavorites = _.last(stack) === SidebarStackTypes.Favorites;
    const isDiagnosis = _.last(stack) === SidebarStackTypes.Diagnosis;
    const isSpeciality = _.last(stack) === SidebarStackTypes.Speciality;
    const isDiagnosisFavorites = _.last(stack) === SidebarStackTypes.DiagnosisFavorites;
    const isSpecialityChild = _.last(stack) === SidebarStackTypes.SpecialityChild;
    const isAllCodesSpecialityChild =
      stack.length > 1 ? stack[stack.length - 2] === SidebarStackTypes.SpecialityChild : false;

    const categoryChildIndex = (isSpecialityChild || isSpeciality) && _.last(categoryChildStack);
    const categoryChild =
      _.isNumber(categoryChildIndex) && categoryChildIndex >= 0
        ? sidebarCategoryList[categoryChildIndex]
        : null;
    const categoryChildId = _.get(categoryChild, "id");
    const categoryChildTitle = _.get(categoryChild, "name");
    const categoryChildList = _.get(categoryChild, "children", []);

    return (
      <SidebarContentContainer
        onSearch={(v) => {
          onSearch(v);
          if (isAllCodes) {
            getSidebarTreatmentList(categoryChildId);
          }

          if (isDiagnosis) {
            getSidebarDiagnosisList();
          }
        }}
        searchValue={searchValue}
        isItem={isItem || isSpecialityChild}
        title={
          isSpeciality || (!isQuickMode && _.isEmpty(stack))
            ? "Treatment By Speciality"
            : isAllCodes
            ? "Treatment"
            : isFavorites || (isQuickMode && _.isEmpty(stack))
            ? "Favorite Treatment"
            : isSpecialityChild && categoryChildTitle
            ? categoryChildTitle
            : null
        }
      >
        {isItem && (
          <SidebarItem
            item={item}
            stack={stack}
            member={member}
            popStack={popStack}
            pushStack={pushStack}
            backFromItem={backFromItem}
            inAppointment={inAppointment}
            changeTypeTab={changeTypeTab}
            onRequestClose={onRequestClose}
            typeTab={SidebarTypes.Treatments}
            chartSideSeries={chartSideSeries}
            toothNumericType={toothNumericType}
            changeActiveTooth={changeActiveTooth}
            addRequestedTreament={addRequestedTreament}
            initItem={(id) => initItem(id, SidebarTypes.Treatments)}
          />
        )}

        {Boolean(isFavorites || (isQuickMode && _.isEmpty(stack))) && (
          <SidebarFavoritesList
            onSort={onSort}
            viewTab={viewTab}
            openItem={openItem}
            favoritesList={favourites}
            getFavorites={getFavorites}
            toggleFavourite={toggleFavorite}
            typeTab={SidebarTypes.Treatments}
            chartSideSeries={chartSideSeries}
            showImageSelector={showImageSelector}
          />
        )}

        {Boolean(isSpeciality || (_.isEmpty(stack) && !isQuickMode)) && (
          <SidebarCategoriesList
            viewTab={viewTab}
            loadList={loadCategoryList}
            isQuickMode={isQuickMode}
            searchValue={searchValue}
            typeTab={SidebarTypes.Treatments}
            favouritesIcons={favouritesIcons}
            showImageSelector={showImageSelector}
            specialitiesList={sidebarCategoryList}
            openFavorites={() => openFavorites(SidebarStackTypes.Favorites)}
            openCategory={(index, isGroup, id) =>
              openCategory(index, isGroup, id || categoryChildId)
            }
          />
        )}

        {isSpecialityChild && (
          <SidebarCategoryChildList
            list={categoryChildList}
            openCategory={(index, isGroup, id) =>
              openCategory(index, isGroup, id || categoryChildId)
            }
          />
        )}

        {isAllCodes && (
          <SidebarList
            getList={getList}
            viewTab={viewTab}
            openItem={openItem}
            toggleFavorite={toggleFavorite}
            typeTab={SidebarTypes.Treatments}
            chartSideSeries={chartSideSeries}
            categoryChildStack={categoryChildStack}
            loadList={() =>
              loadList(isAllCodesSpecialityChild ? _.last(categoryChildStack) : categoryChildId)
            }
          />
        )}

        {Boolean(isDiagnosis || isDiagnosisFavorites) && (
          <SidebarTreatmentDiagnosis
            stack={stack}
            viewTab={viewTab}
            openItem={(i) => {
              addDiagnosis(i);
              popStack();

              if (isDiagnosis) {
                popStack();
              }
            }}
            typeTab={SidebarTypes.Diagnosis}
            toggleFavorite={toggleFavorite}
            chartSideSeries={chartSideSeries}
            loadList={() => loadList(categoryChildId)}
            onSort={onSort}
            favourites={favourites}
            getFavorites={getFavorites}
            showImageSelector={showImageSelector}
            onActionClick={(action) => {
              pushStack(action);
              getList(SidebarTypes.Diagnosis);
            }}
          />
        )}

        <SidebarContentActions>
          {Boolean(_.isEmpty(stack) && isQuickMode) && (
            <button
              type="button"
              onClick={() => onActionClick(SidebarStackTypes.Speciality)}
              className="btn btn-primary btn-block m-0"
            >
              Categories
            </button>
          )}

          {Boolean(_.isEmpty(stack) || isSpeciality) && (
            <button
              type="button"
              onClick={() => onActionClick(SidebarStackTypes.AllCodes)}
              style={!_.isEmpty(stack) || !isQuickMode ? { maxWidth: "90%" } : {}}
              className="btn btn-primary btn-block m-0"
            >
              All codes
            </button>
          )}
        </SidebarContentActions>

        <ChartImageSelector
          favouritesIcons={favouritesIcons}
          seriesType={SidebarTypes.Treatments}
          activeFavouriteCode={activeFavouriteCode}
          imageSelectorVisible={imageSelectorVisible}
          activeFavouriteSeriesType={activeFavouriteSeriesType}
        />
      </SidebarContentContainer>
    );
  }
}
