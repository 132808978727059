import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSidePostOpActionTypes";

import { defaultStackItem } from "../constants/chart-sidebar/postOpStackCodes";

export const defaultState = {
  stack: [defaultStackItem],
  sortField: "owner_name",
  searchValue: "",
  fetching: false,
  ownedList: [],
  ownedListTotalCount: 0,
  templateList: [],
  item: {
    name: "",
    content: "",
  },
};

const reducers = {
  [ActionTypes.INIT_SIDE_POST_OP](state) {
    return {
      ...state,
      searchValue: "",
      stack: [defaultStackItem],
    };
  },
  [ActionTypes.SELECT_SORT_FIELD_SIDE_POST_OP](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.PUSH_POST_OP_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.POP_POST_OP_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.GET_POST_OP_OWNED_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_POST_OP_OWNED_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        ownedList: state.ownedList.concat(data.list),
        ownedListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetching: false,
      ownedList: data.list || [],
      ownedListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_POST_OP_OWNED_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_POST_OP_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_POST_OP_TEMPLATE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        templateList: state.templateList.concat(data.list),
        templateListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      templateList: data.list || [],
      templateListTotalCount: data.totalCount,
      fetching: false,
    };
  },
  [ActionTypes.GET_POST_OP_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_POST_OP_ITEM_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_POST_OP_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.GET_POST_OP_EMPTY_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_POST_OP_EMPTY_SUCCESS](state, { data }) {
    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.CHART_POST_OP_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
};

export default createReducer(defaultState, reducers);
