import React, { useEffect, useState } from "react";
import DownArrow from "../../assets/images/arrows/down_arrow.png";
import DataList from "../widgets/DataList";
import { PatientFormsAddModalItem } from "./PatientFormsAddModalItem";
import ConsentFormApi from "../../api-legacy/ConsentFormApi";

const PatientFormsAddModalCategoryItem = ({
  item,
  consentFormList,
  fetchingAddConsentForm,
  categoryId,
  addConsentForm,
  patientRegisterActions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [consentFormLists, setConsentFormList] = useState(consentFormList || []);

  useEffect(() => {
    ConsentFormApi.getTypeAhead({ categoryId: item.id, limit: 25, filterKey: "" }).then((res) =>
      setConsentFormList(res.data.data.list),
    );
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "5px",
          border: "1px solid #CCCCCC",
          borderRadius: 1,
          padding: "6px 14px",
        }}
        onClick={() => {
          patientRegisterActions.getAddConsentFormList(item.id);
          return (
            (consentFormLists || []).filter((itemm) => itemm.parentId == item.id).length > 0 &&
            setIsOpen(!isOpen)
          );
        }}
      >
        {item.name}
        {(consentFormLists || []).filter((itemm) => itemm.parentId == item.id).length > 0 && (
          <img
            src={DownArrow}
            style={{ width: 18, transform: isOpen ? "rotate(180deg)" : null, transition: "0.3s" }}
          />
        )}
      </div>
      {isOpen && (
        <DataList
          fetching={fetchingAddConsentForm}
          onScroll={() => patientRegisterActions.loadAddConsentFormList(categoryId)}
        >
          {(consentFormList.length > 25 ? consentFormList : consentFormLists)
            .filter((itemm) => itemm.parentId == item.id)
            .map((x) => (
              <PatientFormsAddModalItem
                key={x.id}
                title={x.name}
                onContentClick={() => addConsentForm(x)}
                onPlusClick={() => addConsentForm(x, false)}
              />
            ))}
        </DataList>
      )}
    </>
  );
};

export default PatientFormsAddModalCategoryItem;
