import _ from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";
import { FULL_DATE_FORMAT } from "../../constants/Constants";
import Utils from "../../helpers/Utils";
import { STATUS_TYPES } from "../../constants/StatusTypes";

import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientAppointmentsListReasonModal extends React.Component {
  componentDidMount() {
    this.props.patientRegisterActions.getHistory();
  }

  render() {
    const { show, appointmentsReasonModalData, mode, index, appointmentsList } = this.props;

    let list = [];

    if (mode === "log") {
      let creatorName = _.get(appointmentsList[index], "creator.name");
      let createdDate = Utils.parseDateFormat(
        Utils.calcDateWithTimeZone(_.get(appointmentsList[index], "createdDate")),
        FULL_DATE_FORMAT,
      );

      list.push(
        <p key="1">
          Created User: <strong>{creatorName}</strong>
        </p>,
      );
      list.push(
        <p key="2">
          Created Date: <strong>{createdDate}</strong>
        </p>,
      );

      if (!_.isEmpty(appointmentsReasonModalData)) {
        let confirmedUser;
        let confirmedDate;

        let checkedInUser;
        let checkedInDate;

        let completedUser;
        let completedDate;

        _.forEach(appointmentsReasonModalData, (item) => {
          if (item.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED) {
            confirmedUser = _.get(item, "modifiedMember.name");
            confirmedDate = item.modifiedDate
              ? Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.modifiedDate),
                  FULL_DATE_FORMAT,
                )
              : null;
          }

          if (item.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN) {
            checkedInUser = _.get(item, "modifiedMember.name");
            checkedInDate = item.modifiedDate
              ? Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.modifiedDate),
                  FULL_DATE_FORMAT,
                )
              : null;
          }

          if (item.status.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE) {
            completedUser = _.get(item, "modifiedMember.name");
            completedDate = item.modifiedDate
              ? Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.modifiedDate),
                  FULL_DATE_FORMAT,
                )
              : null;
          }
        });

        list.push(
          <Show key="3" if={confirmedUser}>
            <p>
              Confirmed User: <strong>{confirmedUser}</strong>
            </p>
          </Show>,
        );
        list.push(
          <Show key="4" if={confirmedDate}>
            <p>
              Confirmed Date: <strong>{confirmedDate}</strong>
            </p>
          </Show>,
        );
        list.push(
          <Show key="5" if={checkedInUser}>
            <p>
              Checkedin User: <strong>{checkedInUser}</strong>
            </p>
          </Show>,
        );
        list.push(
          <Show key="6" if={checkedInDate}>
            <p>
              Checkedin Date: <strong>{checkedInDate}</strong>
            </p>
          </Show>,
        );
        list.push(
          <Show key="7" if={completedUser}>
            <p>
              Completed User: <strong>{completedUser}</strong>
            </p>
          </Show>,
        );
        list.push(
          <Show key="8" if={completedDate}>
            <p>
              Completed Date: <strong>{completedDate}</strong>
            </p>
          </Show>,
        );
      }
    } else {
      const appointmen = appointmentsList[index];
      list.push(
        <div className="row" key="9">
          <div className="col-sm-12">{appointmen.note}</div>
        </div>,
      );
    }

    return (
      <Modal
        show={show}
        title={<div>Appointment {mode === "log" ? "logs" : "notes"}</div>}
        onHide={this.props.patientRegisterActions.appointmentsReasonModalHide}
        actions={
          <Button
            type="button"
            onClick={this.props.patientRegisterActions.appointmentsReasonModalHide}
          >
            Close
          </Button>
        }
        keyboard={false}
      >
        <div>{list}</div>
      </Modal>
    );
  }
}
export default enhancer(PatientAppointmentsListReasonModal);
