const transactionsSortFields = [
  {
    value: "create_date_desc",
    name: "Planned date ⬇︎",
  },
  {
    value: "create_date",
    name: "Planned date ⬆︎",
  },
  {
    value: "treatment_session_desc",
    name: "Session ⬇︎",
  },
  {
    value: "treatment_session",
    name: "Session ⬆︎",
  },
  {
    value: "tooth_desc",
    name: "Tooth ⬇︎",
  },
  {
    value: "tooth",
    name: "Tooth ⬆︎",
  },
];

export default transactionsSortFields;
