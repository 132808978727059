import React, { useEffect } from "react";
import { Switch, useHistory } from "react-router";
import { Route, useLocation } from "react-router-dom";

import { Routes } from "../constants/Routes";
import MemberLogin from "../components/member-login/MemberLogin";
import ChooseClinic from "../components/choose-clinic/ChooseClinic";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";
import RegisterDeviceLogin from "../components/register-device/RegisterDeviceLogin";
import { adminLoggedInSelector, deviceExistSelector } from "../reducers/authReducer";

export function AuthContainer() {
  const history = useHistory();
  const location = useLocation();

  const deviceExist = useShallowEqualSelector(deviceExistSelector);
  const adminLoggedIn = useShallowEqualSelector(adminLoggedInSelector);

  useEffect(() => {
    if (
      (deviceExist === null || deviceExist === false) &&
      adminLoggedIn === false &&
      location.pathname !== Routes.Auth
    ) {
      history.replace(Routes.Auth);
    } else if (deviceExist === true && location.pathname !== Routes.AuthMemberLogin) {
      history.replace(Routes.AuthMemberLogin);
    } else if (
      deviceExist === false &&
      adminLoggedIn === true &&
      location.pathname !== Routes.AuthChooseClinic
    ) {
      history.replace(Routes.AuthChooseClinic);
    }
  }, [adminLoggedIn, deviceExist, history, location.pathname]);

  return (
    <Switch>
      <Route component={RegisterDeviceLogin} path={Routes.Auth} exact={true} />
      <Route component={MemberLogin} path={Routes.AuthMemberLogin} />
      <Route component={ChooseClinic} path={Routes.AuthChooseClinic} />

      {/*<Redirect to={Routes.Auth} from="/" exact={true} />*/}
    </Switch>
  );
}
