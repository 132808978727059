import React from "react";
import { Modal } from "../ui/Modal";

import { connect } from "react-redux";

import {
  requestedDeleteItem,
  requestedDeleteConfirmModalHide,
} from "../../actions/waitingListActions";

const enhancer = connect(null, { requestedDeleteItem, requestedDeleteConfirmModalHide });
class RequestedDeleteConfirmModal extends React.Component {
  render() {
    const { show, deleteId } = this.props;

    return (
      <Modal
        show={show}
        animation={false}
        size="sm"
        keyboard={false}
        onHide={this.props.requestedDeleteConfirmModalHide}
        actions={
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.requestedDeleteConfirmModalHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.props.requestedDeleteItem(deleteId)}
            >
              Delete
            </button>
          </div>
        }
      >
        <div>
          Are you sure you want to delete request appointment? This action cannot be undone.
        </div>
      </Modal>
    );
  }
}
export default enhancer(RequestedDeleteConfirmModal);
