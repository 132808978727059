import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { Button } from "../ui/Button";
import { DatePicker } from "../ui/DatePicker";
import { TextField } from "../form/TextField";
import { Routes } from "../../constants/Routes";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { SelectPicker } from "../select-picker/SelectPicker";
import { useClinicListQuery } from "../../queries/clinic/useClinicListQuery";
import { getCompanyStartOfDay, getCompanyEndOfDay } from "../../helpers/DateUtils";

const enhancer = connect(
  ({
    chart: {
      patient: { patientKey },
    },
    auth: { clinics, doctors },
    session: { clinic, member },
  }) => ({ clinics, patientKey, clinic, member, doctors }),
);

const PatientWhMessagesForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const clinicQuery = useClinicListQuery();

  const clinicList = useMemo(() => {
    const list = clinicQuery.data || [];
    const allClinics = [
      {
        label: "All Clinics",
        value: "all",
      },
    ];

    return allClinics.concat(list.map((v) => ({ value: v.id, label: v.name })));
  }, [clinicQuery.data]);

  const handleSubmit = (values) => {
    const transfer = {
      chartNumber: values.chartNumber || null,
      startTime: getCompanyStartOfDay(values.startTime),
      endTime: getCompanyEndOfDay(values.endTime),
    };
    if (!_.isEmpty(values.clinic) && values.clinic.value !== "all") {
      transfer.clinicId = values.clinic.value;
    }

    history.push(
      createUrl(Routes.DashboardWhatsappChatting, {
        query: {
          ...parseQuery(location.search),
          ...transfer,
        },
      }),
    );
  };

  return (
    <div className="search-form-wrapper">
      <Formik
        initialValues={{
          clinic: { value: props.clinic.id, label: props.clinic.name },
          chartNumber: "",
          startTime: moment(),
          endTime: moment(),
        }}
        enableReinitialize={false}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, setFieldValue }) => (
          <Form className="search-form">
            <div style={{ display: "flex", padding: "0 15px" }}>
              <div className="form-group" style={{ width: "200px" }}>
                <SelectPicker
                  isMulti={false}
                  placeholder="Filter by clinic"
                  options={clinicList}
                  value={values.clinic}
                  onChange={(option) => setFieldValue("clinic", option)}
                />
              </div>
              <div className="form-group" style={{ width: "200px" }}>
                <TextField name="chartNumber" placeholder="Patient Chart Number" />
              </div>
            </div>

            <div style={{ display: "flex", minWidth: "450px" }}>
              <div className="form-group flex-datepicker" style={{ width: "200px" }}>
                <label>From:&nbsp;</label>
                <DatePicker
                  name="startTime"
                  selected={values.startTime}
                  fieldProps={{ style: { width: "100%" } }}
                  onChange={(v) => {
                    setFieldValue("startTime", v);
                  }}
                />
              </div>

              <div className="form-group flex-datepicker" style={{ width: "200px" }}>
                <label>To:</label>
                <DatePicker
                  name="endTime"
                  selected={values.endTime}
                  fieldProps={{ style: { width: "100%" } }}
                  onChange={(v) => {
                    setFieldValue("endTime", v);
                  }}
                />
              </div>
            </div>

            <Button type="submit" variant="primary">
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default enhancer(PatientWhMessagesForm);
