export const WHATSAPP_CHATTING_SET_PATIENT_LIST = "WHATSAPP_CHATTING_SET_PATIENT_LIST";

export const WHATSAPP_CHATTING_PATIENT_LIST_GET_START = "WHATSAPP_CHATTING_PATIENT_LIST_GET_START";
export const WHATSAPP_CHATTING_PATIENT_LIST_GET_SUCCESS = "WHATSAPP_CHATTING_PATIENT_LIST_GET_SUCCESS";
export const WHATSAPP_CHATTING_PATIENT_LIST_GET_ERROR = "WHATSAPP_CHATTING_PATIENT_LIST_GET_ERROR";

export const WHATSAPP_CHATTING_PATIENT_ARCHIVE_START = "WHATSAPP_CHATTING_PATIENT_ARCHIVE_START";
export const WHATSAPP_CHATTING_PATIENT_ARCHIVE_SUCCESS = "WHATSAPP_CHATTING_PATIENT_ARCHIVE_SUCCESS";
export const WHATSAPP_CHATTING_PATIENT_ARCHIVE_ERROR = "WHATSAPP_CHATTING_PATIENT_ARCHIVE_ERROR";

export const WHATSAPP_CHATTING_PATIENT_LIST_SHOW_SIDEBAR = "WHATSAPP_CHATTING_PATIENT_LIST_SHOW_SIDEBAR";
export const WHATSAPP_CHATTING_PATIENT_LIST_HIDE_SIDEBAR = "WHATSAPP_CHATTING_PATIENT_LIST_HIDE_SIDEBAR";