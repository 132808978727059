import qs from "qs";
import { pathParams } from "path-params";

export interface CreateUrlOptionsProps<Q, P> {
  readonly query?: Q;
  readonly params?: P;
}

export function createUrl<Q, P>(path: string, options: CreateUrlOptionsProps<Q, P>): string {
  const query = qs.stringify(options?.query);
  const url = [path, query].filter(Boolean).join("?");

  if (options?.params) {
    return pathParams(url, options.params);
  }

  return url;
}

export function parseQuery<T>(search: string): T {
  return (qs.parse(search.replace("?", "")) || {}) as any;
}
