import { useQuery, UseQueryResult } from "react-query";

import { createUrl } from "../../utils/UrlUtils";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

type DataProps = { data: EligibilityProps; errors: string[] };
let requestCount = 0;

export function useEligibilityDetailsQuery(
  eligibilityId: number | undefined,
  appointmentId: number | undefined,
  isPending: boolean,
  onSuccess: (data: DataProps) => void,
): UseQueryResult<DataProps, Error> {
  return useQuery({
    queryKey: ["app", "eligibility", eligibilityId, "details"],
    queryFn: () => {
      const url = createUrl(ApiRoutes.GetEligibilityDetails, {
        query: { eligibilityId, appointmentId },
      });
      requestCount++;
      if (requestCount >= 20) {
        requestCount = 0;
        return [
          "You have reached the maximum amount of requests. Please wait until the process ends.",
        ];
      }
      return httpClient.get(url).then(({ data }) => data.data);
    },
    enabled: !!eligibilityId && isPending,
    refetchInterval: 5000,
    onSuccess,
  });
}
