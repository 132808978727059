import { httpClient } from "./BaseApi";

const WAITING_LIST_LIST_URL = "api/clinic/appointment/special-list/waiting.json";
const WAITING_LIST_MISSED_URL = "api/clinic/appointment/special-list/missed.json";
const WAITING_LIST_CANCELLED_URL = "api/clinic/appointment/special-list/cancelled.json";

const REQUESTED_APPOINTMENT_LIST_URL = "api/clinic/request/appointment/list.json";
const REQUESTED_APPOINTMENT_ITEM_URL = "api/clinic/request/appointment/item.json";

const WaitingListApi = {
  getList: (params) => httpClient.get(WAITING_LIST_LIST_URL, { params }),
  getMissedList: (params) => httpClient.get(WAITING_LIST_MISSED_URL, { params }),
  getCancelledList: (params) => httpClient.get(WAITING_LIST_CANCELLED_URL, { params }),
  requestedAppointments: {
    getList: (params) => httpClient.get(REQUESTED_APPOINTMENT_LIST_URL, { params }),
    deleteItem: (params) => httpClient.delete(REQUESTED_APPOINTMENT_ITEM_URL, { params }),
  },
};

export default WaitingListApi;
