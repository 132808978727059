import React from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";

import * as medicationActions from "../../actions/medicationActions";
import DeleteImage from "../../assets/images/appointment/delete_2x.png";

const form = reduxForm();
class MedicationHistoryItemForm extends React.Component {
  render() {
    return (
      <div className="default-table-row">
        <div
          className="default-table-col col-0"
          onClick={() =>
            this.props.lastIndex === this.props.index &&
            this.props.dispatch(medicationActions.popHistory())
          }
        >
          {this.props.lastIndex === this.props.index && (
            <img
              src={DeleteImage}
              alt={""}
              width={20}
              height={20}
              className="icon-btn icon-delete icon-small"
            />
          )}
        </div>
        <div className="default-table-col col-1">{moment().format("MMM DD, YYYY, h:mm a")}</div>
        <div className="default-table-col col-2">
          <Field component="input" type="text" name="dosage" className="form-control" />
        </div>
        <div className="default-table-col col-3">
          <Field component="textarea" name="comment" className="form-control" />
        </div>
      </div>
    );
  }
}
export default form(MedicationHistoryItemForm);
