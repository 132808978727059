import React from "react";
import { connect } from "react-redux";

import Back from "../widgets/Back";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import EmbedButton from "../widgets/EmbedButton";
import logoImage from "../../assets/images/clinic_logo.png";
import { parseQuery } from "../../utils/UrlUtils";

const enhancer = connect(
  ({ patientRegister }) => ({ patientRegister }),
  bindActions({ patientRegisterActions }),
);
class PatientFormsItem extends React.Component {
  componentDidMount() {
    const query = parseQuery(this.props.location.search);

    this.props.patientRegisterActions.getPatientFormsItem(query);
  }

  render() {
    const { formsItem, patientInfo } = this.props.patientRegister;

    let patientData = patientInfo.firstName || "";
    const lastName = patientInfo.lastName || "";
    const chartNumber = patientInfo.chartNumber || "";

    patientData += lastName != "" ? " " + lastName : "";
    patientData += patientData != "" && chartNumber != "" ? ", " : "";
    patientData += chartNumber != "" ? "#" + chartNumber : "";

    return (
      <div className="patient-appointments-list">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" />
          <div className="btn-user-name pull-right">{patientData}</div>
          <img src={logoImage} alt="" className="header-logo" />
        </div>

        <EmbedButton
          src={formsItem.signContent}
          show={formsItem.signContent}
          embedStyle={{ height: "100%", flex: 1 }}
        />
      </div>
    );
  }
}
export default enhancer(PatientFormsItem);
