import { httpClient } from "./BaseApi";

const WHATSAPP_CHATTING_PATIENTS_LIST = "api/patient/whatsapp/list.json";
const WHATSAPP_CHATTING_PATIENT_MESSAGES = "api/patient/whatsapp/messages.json";
const WHATSAPP_CHATTING_PATIENT_SEND_MESSAGE = "api/patient/whatsapp/chat/item.json";
const WHATSAPP_CHATTING_PATIENT_ARCHIVE = "api/patient/whatsapp/archive.json";

const WhatsAppChattingApi = {
    getList: (params) => httpClient.get(WHATSAPP_CHATTING_PATIENTS_LIST, { params }),
    getPatientWhatsappMessages: (params) => httpClient.get(WHATSAPP_CHATTING_PATIENT_MESSAGES, { params }),
    sendPatientWhatsappMessages: (data) => httpClient.post(WHATSAPP_CHATTING_PATIENT_SEND_MESSAGE, { data }),
    archivePatientChatting: (data) => httpClient.put(WHATSAPP_CHATTING_PATIENT_ARCHIVE, { data }),
}

export default WhatsAppChattingApi;
