import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import FilledImage from "../widgets/FilledImage";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import MenuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersToggleDentistSelected extends React.Component {
  render() {
    const { toggleDentistSelected, searchDentistSelected } = this.props;

    let list = [];
    let dentistCollection = _.values(toggleDentistSelected);

    const toggleDentistSelectedAfterFilter = _.filter(dentistCollection, (element) => {
      if (_.lowerCase(element.name).indexOf(searchDentistSelected) !== -1) return true;
    });

    _.forEach(toggleDentistSelectedAfterFilter, (value, index) => {
      list.push(
        <div
          className="list-group-item"
          key={index}
          onClick={() => {
            this.props.remindersActions.toggleDentist(value.id);
          }}
        >
          <div className="text">{value.name}</div>
          <div className="arrow">✔</div>
        </div>,
      );
    });

    return (
      <div>
        <div className="input-group search">
          <span className="input-group-addon">
            <FilledImage color={"#aaa"} src={MenuImage} className="zoom-2-5x" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="search"
            value={searchDentistSelected}
            onChange={this.props.remindersActions.searchDentistSelected}
          />
        </div>
        <div className="list-group">{list}</div>
      </div>
    );
  }
}
export default enhancer(RemindersToggleDentistSelected);
