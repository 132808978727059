import React from "react";
import cx from "classnames";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";

import { Button } from "../ui/Button";
import { SelectField } from "../form/SelectField";
import { CheckboxField } from "../form/CheckboxField";
import { TextField } from "../form/TextField";

const SERVICE_CATEGORY_OPTIONS = [
  { value: 0, label: "Please select" },
  { value: 1, label: "New Consultation" },
  { value: 2, label: "Follow-up Consultation" },
  { value: 3, label: "Diagnostic Testing" },
  { value: 4, label: "Physiotherapy" },
  { value: 5, label: "Dental Services" },
  { value: 6, label: "Pharmacy" },
  { value: 7, label: "Homecare" },
  { value: 8, label: "Rehabilitation" },
  { value: 9, label: "In Patient" },
  { value: 10, label: "Daycare" },
  { value: 11, label: "Ultrasound" },
];

const CONSULTATION_CATEGORY_OPTIONS = [
  { value: 0, label: "Please select" },
  { value: 1, label: "Emergency" },
  { value: 2, label: "Screening" },
  { value: 3, label: "Referral" },
  { value: 4, label: "Elective" },
  { value: 5, label: "Vaccination" },
  { value: 6, label: "Oncology" },
];

const ULTRASOUND_CATEGORY_OPTIONS = [
  { value: 0, label: "Please select" },
  { value: 9, label: "First Trimester" },
  { value: 10, label: "Second Trimester" },
  { value: 11, label: "Third Trimester" },
];

const SERVICE_SUB_CATEGORY_OPTIONS = [
  { value: 0, label: "Please select" },
  { value: 1, label: "Capitation Program" },
  { value: 2, label: "Routine FFS" },
  { value: 3, label: "Advanced FFS" },
];

const INITIAL_VALUES: EligibilityPreFormValuesProps = {
  serviceCategory: { value: 0, label: "Please select" },
  ultrasoundCategory: { value: 0, label: "Please select" },
  consultationCategory: { value: 0, label: "Please select" },
  serviceSubCategory: { value: 0, label: "Please select" },
  isPOD: false,
  PODId: "",
};

export interface EligibilityFormProps {
  readonly onSubmit: (values: EligibilityPreFormValuesProps) => Promise<void>;
}

export function EligibilityForm({ onSubmit }: EligibilityFormProps) {
  return (
    <Formik onSubmit={onSubmit} initialValues={INITIAL_VALUES}>
      {({ values, isSubmitting, handleSubmit }) => {
        const showUltrasoundCategory = values.serviceCategory.value === 11;
        const showConsultationCategory = values.serviceCategory.value === 1;
        const showServiceSubCategory = values.serviceCategory.value === 5;

        const serviceNotSelected = values.serviceCategory.value === 0;
        const ultrasoundNotSelected =
          showUltrasoundCategory && values.ultrasoundCategory.value === 0;
        const consultationNotSelected =
          showConsultationCategory && values.consultationCategory.value === 0;
        const serviceSubCategoryNotSelected =
          showServiceSubCategory && values.serviceSubCategory.value === 0;
        const podIdEmpty = values.isPOD && !values.PODId;

        const submitDisabled =
          ultrasoundNotSelected ||
          consultationNotSelected ||
          serviceNotSelected ||
          serviceSubCategoryNotSelected ||
          isSubmitting ||
          podIdEmpty;

        return (
          <Form className="d-flex flex-column">
            <div className="d-flex mb-2">
              <div className="d-flex flex-column mr-1" style={{ flex: 1 }}>
                <SelectField
                  isDisabled={isSubmitting}
                  label="Service category"
                  name="serviceCategory"
                  options={SERVICE_CATEGORY_OPTIONS}
                />
              </div>

              {showConsultationCategory && (
                <div className="d-flex flex-column ml-1" style={{ flex: 1 }}>
                  <SelectField
                    isDisabled={isSubmitting}
                    label="Consultation category"
                    name="consultationCategory"
                    options={CONSULTATION_CATEGORY_OPTIONS}
                  />
                </div>
              )}

              {showUltrasoundCategory && (
                <div className="d-flex flex-column ml-1" style={{ flex: 1 }}>
                  <SelectField
                    isDisabled={isSubmitting}
                    label="Ultrasound category"
                    name="ultrasoundCategory"
                    options={ULTRASOUND_CATEGORY_OPTIONS}
                  />
                </div>
              )}

              {showServiceSubCategory && (
                <div className="d-flex flex-column ml-1" style={{ flex: 1 }}>
                  <SelectField
                    isDisabled={isSubmitting}
                    label="Service subcategory"
                    name="serviceSubCategory"
                    options={SERVICE_SUB_CATEGORY_OPTIONS}
                  />
                </div>
              )}

              {!showConsultationCategory && !showUltrasoundCategory && <div style={{ flex: 1 }} />}

              <div style={{ flex: 1 }} />
            </div>
            <div className="d-flex mb-2">
              <div className="d-flex flex-column mr-1" style={{ flex: 1 }}>
                <CheckboxField disabled={isSubmitting} label="POD" name="isPOD" />
              </div>
              <div className="d-flex flex-column ml-1" style={{ flex: 1 }}>
                <TextField disabled={isSubmitting} label="POD-ID" name="PODId" type="number" />
              </div>
              <div style={{ flex: 1 }} />
            </div>
            <div>
              <Button type="button" disabled={submitDisabled} onClick={() => handleSubmit()}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ paddingTop: "4px" }} className={cx({ "mr-1": isSubmitting })}>
                    Check
                  </span>

                  {isSubmitting && <CircularProgress size="0.9rem" style={{ color: "white" }} />}
                </div>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
