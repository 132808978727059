import moment from "moment";
import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/recallListActionTypes";
import { STATUS_TYPES } from "../constants/StatusTypes";

const defaultState = {
  list: [],
  loading: false,
  listTotal: 0,
  fromDate: moment(),
  toDate: moment(),
  statusCodes: [
    { value: STATUS_TYPES.APPOINTMENT_STATUS_MISSED, label: "Client (Missed)" },
    { value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED, label: "Center (Missed)" },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      label: "Cancelled by patient",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      label: "Cancelled by centre",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      label: "Released (Non-Payment)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      label: "Client(Late Cancellation)",
    },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
    //   label: "Missed rescheduled",
    // },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
    //   label: "Cancelled Rescheduled",
    // },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
    //   label: "Voided",
    // },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      label: "Clinician (Cancelled)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      label: "Center (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      label: "Clinician (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
      label: "Clinician (No Show)",
    },
  ],
};

const reducers = {
  [ActionType.GET_RECALL_LIST_START](state) {
    return {
      ...state,
      list: [],
      loading: true,
    };
  },
  [ActionType.GET_RECALL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      list: data.list || [],
      loading: false,
      listTotal: data?.totalCount,
    };
  },
  [ActionType.GET_RECALL_LIST_ERROR](state) {
    return {
      ...state,
      list: [],
      loading: false,
      listTotal: 0,
    };
  },
  [ActionType.SET_RECALL_LIST_FROM_DATE](state, { date }) {
    return {
      ...state,
      fromDate: date,
    };
  },
  [ActionType.SET_RECALL_LIST_TO_DATE](state, { date }) {
    return {
      ...state,
      toDate: date,
    };
  },
};

export default createReducer(defaultState, reducers);
