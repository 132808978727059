import React from "react";
import { PerioExamValues } from "./PerioExamValues";
import PerioExamLinearChart from "./PerioExamLinearChart";
import { TopRowBackgroundIcon } from "./TopRowBackgroundIcon";

import { obtainImagePath } from "../../../utils/ImageUtils";
import { getTeethImage } from "../../../constants/perio-exam";
import { useDispatch, useSelector } from "react-redux";
import { perioExamHandleItem } from "../../../actions/patientActions";
import { useLocation } from "react-router";
import { parseQuery } from "../../../utils/UrlUtils";
import ChartToothNumber from "../teeth/ChartToothNumber";
import FilledImage from "../../widgets/FilledImage";

export function PerioExamTeethRow({ teethList = [], numericKey, ...props }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = parseQuery(location.search);

  const {
    patient: { perioExamItemHandle, perioExamItem },
  } = useSelector((store) => store);

  const activeItem = (item) =>
    perioExamItemHandle?.item?.image == item.image &&
    perioExamItemHandle?.item?.code == item.code &&
    perioExamItemHandle?.side == props.side &&
    perioExamItemHandle?.row == props.row;

  const getItemProp = (itemm) => {
    return (perioExamItem.perioExamList || []).find(
      (item) =>
        item?.tooth?.id == itemm?.normal.toothConditions?.tooth?.id &&
        item?.toothSide?.code == props.side,
    );
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="line-title-container"
          style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <div>
            <div
              className="row-line-title-main"
              style={{
                marginTop: 40,
                color: "#8181e4",
                fontSize: 20,
                textTransform: "uppercase",
                transform: "rotate(-90deg)",
              }}
            >
              {props.side === "Front_Side" ? "Buccal" : "Lingual"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
              textAlign: "center",
            }}
          >
            <span className="row-line-title">PD</span>
            <span className="row-line-title">GM</span>
            <span className="row-line-title">CA</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginBottom: 20,
          }}
        >
          {teethList.map((item, idx) => (
            <div key={idx} style={{ width: 80 }}>
              <div style={{ textAlign: "center", background: "#eee" }}>
                <ChartToothNumber
                  numericKey={numericKey}
                  normalDetails={item.normal && item.normal.details}
                  missedTooth={item.normal && item.normal?.missedTooth}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "180px",
                  minWidth: "80px",
                  maxWidth: "80px",
                  paddingTop: "12px",
                  position: "relative",
                  alignItems: "baseline",
                  overflow: "hidden",
                  border: activeItem(item) ? "1px solid blue" : null,
                  background: activeItem(item) ? "rgba(0,0,0,0.2)" : null,
                }}
                onClick={() =>
                  !query.date &&
                  dispatch(
                    perioExamHandleItem({
                      item: item,
                      teethList,
                      side: props.side,
                      row: props.row,
                      perioExamItem: getItemProp(item),
                    }),
                  )
                }
              >
                <PerioExamLinearChart item={getItemProp(item)} top={props.row === "top"} />

                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    bottom: props.row == "top" ? 25 : null,
                    top: props.row == "lower" ? 65 : null,
                  }}
                >
                  <TopRowBackgroundIcon />
                </div>
                <div
                  style={{ width: 80, height: 130, position: "relative", zIndex: 2 }}
                  className={`d-flex justify-content-center align-items-${
                    props?.row === "top" ? "start" : "end"
                  }`}
                >
                  {item.image ? (
                    <img
                      alt=""
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                      src={obtainImagePath(getTeethImage(item.image))}
                    />
                  ) : (
                    <FilledImage
                      color={"rgb(255, 11, 101)"}
                      src={require("../../../assets/images/conditions/absent_tooth@2x.png").default}
                      className="zoom-2x image-40"
                      style={{ height: "100%" }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  borderTop: "1px solid",
                  borderLeft: idx == 0 ? "1px solid" : null,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    borderBottom: "1px solid",
                    borderRight: "1px solid",
                  }}
                >
                  <PerioExamValues
                    distal={getItemProp(item)?.probingDepthDistal || 0}
                    central={getItemProp(item)?.probingDepthCentral || 0}
                    mesial={getItemProp(item)?.probingDepthMesial || 0}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    borderBottom: "1px solid",
                    borderRight: "1px solid",
                  }}
                >
                  <PerioExamValues
                    distal={getItemProp(item)?.gingivalMarginDistal || 0}
                    central={getItemProp(item)?.gingivalMarginCentral || 0}
                    mesial={getItemProp(item)?.gingivalMarginMesial || 0}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    borderBottom: "1px solid",
                    borderRight: "1px solid",
                  }}
                >
                  <PerioExamValues
                    color={false}
                    distal={getItemProp(item)?.clinicalAttachmentDistal || 0}
                    central={getItemProp(item)?.clinicalAttachmentCentral || 0}
                    mesial={getItemProp(item)?.clinicalAttachmentMesial || 0}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
