import _ from "lodash";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import {
  doctorSelect,
  onTypeSelect,
  setFromDate,
  setToDate,
  toggleComplete,
  changeChartNumber,
  clinicSelect,
} from "../../actions/followUpActions";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { DatePicker } from "../ui/DatePicker";
import { DoctorSelectField } from "../form/DoctorSelectField";
import { Form, Formik } from "formik";
import { SelectPicker } from "../select-picker/SelectPicker";
import { ClinicSelectField } from "../form/ClinicSelectField";
import { getCompanyStartOfDay } from "../../helpers/DateUtils";
import moment from "moment";

const enhancer = connect(null, {
  doctorSelect,
  clinicSelect,
  onTypeSelect,
  setFromDate,
  setToDate,
  toggleComplete,
  changeChartNumber,
});

export default enhancer(function FollowUpForm({
  member,
  toDate,
  fromDate,
  onSubmit,
  typeList,
  chartNumber,
  doctorSelect,
  onTypeSelect,
  setFromDate,
  setToDate,
  clinicSelect,
  changeChartNumber,
  showResponded,
}) {
  const history = useHistory();
  const session = useSelector((state) => state.session);
  const typeOptions = (typeList || []).map((item) => ({ value: item.id, label: item.name }));

  const isFollowUp = (session.permissions || []).find((item) => item === "VIEW_FOLLOW_UP_ALL");
  let initialDoctor = !isFollowUp ? [{ value: session.member.id, label: session.member.name }] : [];
  let initialFromDate = fromDate;
  if (showResponded) {
    initialDoctor = [{ value: session.member.id, label: session.member.name }];
    initialFromDate = getCompanyStartOfDay(moment().subtract(1, "months"));
  } else {
    initialDoctor = [{ value: session.member.id, label: session.member.name }];
    doctorSelect([{ value: session.member.id, label: session.member.name }]);
  }
  return (
    <Formik
      initialValues={{
        toDate,
        fromDate: initialFromDate,
        doctorIds: initialDoctor,
        typeIds: [],
        chartNumber,
      }}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className="d-flex align-items-center justify-content-end w-100">
            <div
              className="ml-2 mr-1"
              style={{
                minWidth: "250px",
              }}
            >
              <ClinicSelectField
                name="clinic"
                isMulti={false}
                defaultValue={{ label: session.clinic.name, value: session.clinic.id }}
                // closeMenuOnSelect={true}
                placeholder="Filter by clinic"
                onChange={(v) => {
                  setFieldValue("clinicId", v.value);
                  clinicSelect(v.value);
                }}
              />
            </div>
            <div style={{ display: "inline-block", marginRight: "5px", width: "200px" }}>
              <input
                name="chartNumber"
                className="form-control"
                placeholder="Patient chart number"
                autoComplete="off"
                onChange={({ target: { value } }) => {
                  setFieldValue("chartNumber", value);
                  changeChartNumber(value);
                }}
              />
            </div>
            <div style={{ display: "inline-block", marginRight: "5px", width: "200px" }}>
              <DoctorSelectField
                name="doctorIds"
                isMulti={true}
                isSearchable={true}
                allLabel="All Doctors"
                customClinicId={values.clinic && values.clinic.value}
                isDisabled={!isFollowUp}
                onChange={(v) => {
                  setFieldValue("doctorIds", v);
                  doctorSelect(v);
                }}
              />
            </div>
            <div className="small-waiting-list-appt-type" style={{ width: "165px" }}>
              <SelectPicker
                name="typeIds"
                isMulti={true}
                options={typeOptions}
                value={values.typeIds}
                onChange={(x) => {
                  setFieldValue("typeIds", x);
                  onTypeSelect(x);
                }}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 8px",
              }}
            >
              <label style={{ marginBottom: 0 }} className="mr-2">
                From
              </label>
              <DatePicker
                name="fromDate"
                dateFormat={DATE_FORMAT_LEGACY}
                wrapperClassName="small-datepicker"
                selected={values.fromDate}
                onChange={(...args) => {
                  setFieldValue("fromDate", ...args);
                  setFromDate(...args);
                }}
              />
            </div>
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center", margin: "0 8px" }}
            >
              <label style={{ marginBottom: 0 }} className="mr-2">
                To
              </label>
              <DatePicker
                name="toDate"
                dateFormat={DATE_FORMAT_LEGACY}
                wrapperClassName="small-datepicker"
                selected={values.toDate}
                onChange={(...args) => {
                  setFieldValue("toDate", ...args);
                  setToDate(...args);
                }}
              />
            </div>
            <button type="submit" className="btn btn-success">
              Search
            </button>
            <button
              className="btn btn-link"
              type="button"
              onClick={() =>
                history.push(
                  createUrl(Routes.DashboardAppointment, {
                    params: { id: 0 },
                    query: {
                      mode: "follow",
                      memberId: _.get(member, "id"),
                    },
                  }),
                )
              }
            >
              Add new
            </button>
          </Form>
        );
      }}
    </Formik>
  );
});
