import _ from "lodash";
import React from "react";
import { Field, reduxForm } from "redux-form";

import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import sendBeforeOptions from "../../constants/reminders/sendBefore";
import * as remindersActions from "../../actions/remindersActions";
import { SelectPicker } from "../select-picker/SelectPicker";

const enhancer = connect(null, bindActions({ remindersActions }));
const form = reduxForm({
  form: "remindersAutomatic",
});
class RemindersAutomaticModeForm extends React.Component {
  render() {
    const { templates, toggleDentistSelected, handleSubmit } = this.props;

    let templatesOptions;
    let selectedDentistCaption;

    if (!_.isEmpty(toggleDentistSelected)) {
      if (_.size(toggleDentistSelected) > 1) {
        selectedDentistCaption = _.size(toggleDentistSelected) + " dentists";
      } else {
        let selectedId = _.keys(toggleDentistSelected)[0];
        selectedDentistCaption = toggleDentistSelected[selectedId].name;
      }
    } else {
      selectedDentistCaption = "Select " + localStorage.getItem("doctorLabel");
    }

    if (templates) {
      templatesOptions = templates.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    return (
      <form
        onSubmit={handleSubmit(this.props.remindersActions.saveAutomatic)}
        className="form-horizontal"
        role="form"
      >
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="templates" className="col-sm-4 control-label">
                Choose template
              </label>
              <div className="col-sm-8">
                <Field
                  component={({ input }) => (
                    <SelectPicker
                      options={templatesOptions}
                      value={{ value: input.value }}
                      onChange={(x) => input.onChange(x.value)}
                    />
                  )}
                  name="templates"
                  id="templates"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">Send to</label>
              <div className="col-sm-8">
                <button
                  type="button"
                  className="btn btn-default btn-select-dentist"
                  onClick={this.props.remindersActions.toggleDentistShow}
                >
                  {selectedDentistCaption}
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="sendBefore" className="col-sm-4 control-label">
                Send before
              </label>
              <div className="col-sm-8">
                <Field
                  component={({ input }) => (
                    <SelectPicker
                      options={sendBeforeOptions}
                      value={{ value: input.value }}
                      onChange={(x) => input.onChange(x.value)}
                    />
                  )}
                  name="sendBefore"
                  id="sendBefore"
                />
              </div>
            </div>
            <div className="form-group form-group-submit-rule">
              <button type="submit" className="btn btn-submit-rule btn-primary">
                Add reminder rule
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default enhancer(form(RemindersAutomaticModeForm));
