import React, { Component } from "react";
import { connect } from "react-redux";
import logoImage from "../../../assets/images/clinic_logo.png";
import Back from "../../widgets/Back";

const enhancer = connect(({ patientRegister: { claimsApprovalsPdf } }) => ({
  claimsApprovalsPdf,
}));
export default enhancer(
  class PatientClaimsApprovalsPdfView extends Component {
    render() {
      return (
        <div className="clinical-note-item m-0" style={{ position: "relative" }}>
          <div className="clinical-note-item-view-title">
            <Back className="pull-left" />
            <img alt="" className="logo" src={logoImage} />
          </div>
          <div className="clinical-note-item-view-body">
            <embed
              style={{}}
              src={`data:application/pdf;base64,${this.props.claimsApprovalsPdf}`}
              ref="approvals-claims-pdf"
              type=""
            />
          </div>
        </div>
      );
    }
  },
);
