export const SIDE_PLAN_INIT = "SIDE_PLAN_INIT";

export const SIDE_PLAN_PUSH_STACK = "SIDE_PLAN_PUSH_STACK";
export const SIDE_PLAN_POP_STACK = "SIDE_PLAN_POP_STACK";

export const SIDE_PLAN_GET_LIST_START = "SIDE_PLAN_GET_LIST_START";
export const SIDE_PLAN_GET_LIST_SUCCESS = "SIDE_PLAN_GET_LIST_SUCCESS";
export const SIDE_PLAN_GET_LIST_ERROR = "SIDE_PLAN_GET_LIST_ERROR";

export const SIDE_PLAN_GET_LIST_FOR_TREATMENT_START = "SIDE_PLAN_GET_LIST_FOR_TREATMENT_START";
export const SIDE_PLAN_GET_LIST_FOR_TREATMENT_SUCCESS = "SIDE_PLAN_GET_LIST_FOR_TREATMENT_SUCCESS";
export const SIDE_PLAN_GET_LIST_FOR_TREATMENT_ERROR = "SIDE_PLAN_GET_LIST_FOR_TREATMENT_ERROR";

export const SIDE_PLAN_SEND_TO_EMAIL_START = "SIDE_PLAN_SEND_TO_EMAIL_START";
export const SIDE_PLAN_SEND_TO_EMAIL_SUCCESS = "SIDE_PLAN_SEND_TO_EMAIL_SUCCESS";
export const SIDE_PLAN_SEND_TO_EMAIL_ERROR = "SIDE_PLAN_SEND_TO_EMAIL_ERROR";

export const SIDE_PLAN_SAVE_TREATMENT_PLAN_START = "SIDE_PLAN_SAVE_TREATMENT_PLAN_START";
export const SIDE_PLAN_SAVE_TREATMENT_PLAN_SUCCESS = "SIDE_PLAN_SAVE_TREATMENT_PLAN_SUCCESS";
export const SIDE_PLAN_SAVE_TREATMENT_PLAN_ERROR = "SIDE_PLAN_SAVE_TREATMENT_PLAN_ERROR";

export const SIDE_PLAN_SELECT_SORT_FIELD = "SIDE_PLAN_SELECT_SORT_FIELD";
export const SIDE_PLAN_CHANGE_SORTING = "SIDE_PLAN_CHANGE_SORTING";

export const SIDE_PLAN_SEARCH_CHANGE = "SIDE_PLAN_SEARCH_CHANGE";
export const SIDE_PLAN_SEARCH_FOR_CHANGE = "SIDE_PLAN_SEARCH_FOR_CHANGE";

export const SIDE_PLAN_TOGGLE_LIST_FOR = "SIDE_PLAN_TOGGLE_LIST_FOR";

export const SIDE_PLAN_ADD_ALL_FOR = "SIDE_PLAN_ADD_ALL_FOR";
export const SIDE_PLAN_REMOVE_ALL_FOR = "SIDE_PLAN_REMOVE_ALL_FOR";

export const SIDE_PLAN_DISABLE_SELECTED_MODE = "SIDE_PLAN_DISABLE_SELECTED_MODE";
export const SIDE_PLAN_ENABLE_SELECTED_MODE = "SIDE_PLAN_ENABLE_SELECTED_MODE";

export const SIDE_PLAN_INIT_FOR = "SIDE_PLAN_INIT_FOR";

export const DELETE_PLAN_ITEM_CONFIRM_MODAL_SHOW = "DELETE_PLAN_ITEM_CONFIRM_MODAL_SHOW";
export const DELETE_PLAN_ITEM_CONFIRM_MODAL_HIDE = "DELETE_PLAN_ITEM_CONFIRM_MODAL_HIDE";

export const DELETE_PLAN_ITEM_START = "DELETE_PLAN_ITEM_START";
export const DELETE_PLAN_ITEM_SUCCESS = "DELETE_PLAN_ITEM_SUCCESS";
export const DELETE_PLAN_ITEM_ERROR = "DELETE_PLAN_ITEM_ERROR";

export const SIDE_PLAN_EDIT_TREATMENT_PLAN_START = "SIDE_PLAN_EDIT_TREATMENT_PLAN_START";
export const SIDE_PLAN_EDIT_TREATMENT_PLAN_SUCCESS = "SIDE_PLAN_EDIT_TREATMENT_PLAN_SUCCESS";
export const SIDE_PLAN_EDIT_TREATMENT_PLAN_ERROR = "SIDE_PLAN_EDIT_TREATMENT_PLAN_ERROR";

export const SIDE_PLAN_DISCOUNT_AMOUNT_CHANGE = "SIDE_PLAN_DISCOUNT_AMOUNT_CHANGE";
export const SIDE_PLAN_DISCOUNT_PERCENTAGE_CHANGE = "SIDE_PLAN_DISCOUNT_PERCENTAGE_CHANGE";

export const SIDE_PLAN_TOGGLE_GROUP_LIST_FOR = "SIDE_PLAN_TOGGLE_GROUP_LIST_FOR";

export const SIDE_PLAN_TOGGLE_ALL_GROUP_LIST_FOR = "SIDE_PLAN_TOGGLE_ALL_GROUP_LIST_FOR";
export const SIDE_PLAN_CLEAR_ALL_GROUP_LIST_FOR = "SIDE_PLAN_CLEAR_ALL_GROUP_LIST_FOR";
