import React from "react";
import PropsTypes from "prop-types";
import { connect } from "react-redux";
import { updatePriceApi, updateTransactionList } from "../../../actions/transactionActions";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { getTreatmentPriceListApi } from "../../../actions/chartActions";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

const enhancer = connect();

export const PriceListSelectWrapper = enhancer(
  class PriceListSelectWrapper extends React.PureComponent {
    static propTypes = {
      item: PropsTypes.object,
      patientKey: PropsTypes.string,
      dispatch: PropsTypes.func,
      isCustomPrice: PropsTypes.bool,
      treatmentId: PropsTypes.number,
      transactionKey: PropsTypes.string,
    };

    constructor(props) {
      super(props);

      this.state = {
        priceList: [],
      };
    }

    updateTransaction = (data) => {
      const { dispatch } = this.props;

      dispatch(updateTransactionList(data));
    };

    componentDidUpdate(prevProps) {
      const { isCustomPrice: prevIsCustomPrice } = prevProps;
      const {
        item,
        dispatch,
        patientKey,
        treatmentId,
        transactionKey,
        isCustomPrice: nextIsCustomPrice,
      } = this.props;

      if (!nextIsCustomPrice && prevIsCustomPrice !== nextIsCustomPrice && item.prevPrice) {
        dispatch(startLoader());
        const data = {
          key: transactionKey,
          price: item.prevPrice,
          sendBillInsurance: true,
          sendBillInsurance2: true,
          treatmentPriceRowId:
            item.prevTreatmentPrice && item.prevTreatmentPrice.id
              ? item.prevTreatmentPrice.id
              : undefined,
        };

        dispatch(updatePriceApi(data))
          .then(() => {
            dispatch(stopLoader());

            this.updateTransaction(data);

            this.setState({ value: undefined });
          })
          .catch((e) => dispatch(stopLoader(e)));
      } else if (nextIsCustomPrice && prevIsCustomPrice !== nextIsCustomPrice) {
        dispatch(startLoader());

        dispatch(
          getTreatmentPriceListApi({
            patientKey,
            id: treatmentId,
          }),
        )
          .then((x = []) => {
            const defaultPrice = x.find((v) => v.defaultPrice) || {};

            this.setState({
              value: {
                value: defaultPrice.id,
                label: defaultPrice.name,
                price: defaultPrice.price,
              },
              priceList: x.map((price) => ({
                value: price.id,
                label: price.name,
                price: price.price,
              })),
            });

            if (defaultPrice) {
              const data = {
                needCashClaim: true,
                key: transactionKey,
                price: defaultPrice.price,
                treatmentPriceRowId: item?.treatmentPriceRowId,
                sendBillInsurance: false,
                sendBillInsurance2: false,
              };
              dispatch(updatePriceApi(data))
                .then(() => {
                  dispatch(stopLoader());

                  this.updateTransaction({
                    ...data,
                    prevPrice: item.price,
                    prevTreatmentPrice: item.treatmentPrice,
                  });
                })
                .catch((e) => dispatch(stopLoader(e)));
            } else {
              dispatch(stopLoader());
            }
          })
          .catch((e) => dispatch(stopLoader(e)));
      }
    }

    getPriceList = () => {
      const { dispatch, patientKey, treatmentId } = this.props;

      return dispatch(
        getTreatmentPriceListApi({
          patientKey,
          id: treatmentId,
        }),
      );
    };

    onChangePrice = (x) => {
      const { transactionKey, dispatch } = this.props;

      dispatch(startLoader());

      const data = {
        price: x.price,
        key: transactionKey,
        treatmentPriceRowId: x.value,
      };

      dispatch(updatePriceApi(data))
        .then(() => {
          dispatch(stopLoader());

          this.updateTransaction(data);
        })
        .catch((e) => dispatch(stopLoader(e)));
    };

    componentDidMount() {
      const { item } = this.props;
      if (item.treatmentPrice)
        this.setState({
          value: {
            value: item.treatmentPrice.id,
            label: item.treatmentPrice.name,
          },
        });
    }

    render() {
      const { isCustomPrice } = this.props;
      const { priceList = [], open, value } = this.state;

      return (
        <div role="button" onClick={(e) => e.stopPropagation()}>
          <button
            disabled={!isCustomPrice}
            className="btn btn-primary"
            style={{
              marginTop: "-6px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            onClick={() => this.setState({ open: true })}
          >
            {value ? value.label : "select"}
          </button>

          <Modal
            size="small"
            animation={false}
            show={open}
            onHide={() => this.setState({ open: false })}
            className="d-flex"
            dialogClassName="d-flex align-items-center"
            style={{ display: "flex" }}
            title={<div className="mt-2 ml-2">Select price list</div>}
            actions={
              <div>
                <Button
                  disabled={!value}
                  bsStyle="success"
                  onClick={() => {
                    this.onChangePrice(value);
                    this.setState({ open: false });
                  }}
                >
                  Select
                </Button>
                <Button onClick={() => this.setState({ open: false })}>Cancel</Button>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                height: "250px",
                width: "250px",
              }}
            >
              {priceList.map((x, idx) => (
                <div
                  key={idx}
                  role="button"
                  className="d-flex p-2"
                  style={{ borderBottom: "1px solid #cccccc" }}
                  onClick={() => this.setState({ value: x })}
                >
                  <div className="flex-grow-1 flex-shrink-1">
                    <span style={value && value.value === x.value ? { color: "#b1bb37" } : {}}>
                      {x.label}
                    </span>
                  </div>
                  <span>{x.price}</span>
                </div>
              ))}
            </div>
          </Modal>
        </div>
      );
    }
  },
);
