import React from "react";

import Back from "../widgets/Back";

export default class AppointmentNote extends React.Component {
  setView = (type) => {
    localStorage.setItem("showFullNote", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment note view</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView("false")} className="list-group-item">
              {Boolean(localStorage.getItem("showFullNote") === "false") && (
                <div className="pull-right">&#10004;</div>
              )}
              In modal
            </span>
            <span onClick={() => this.setView("true")} className="list-group-item">
              {Boolean(localStorage.getItem("showFullNote") === "true") && (
                <div className="pull-right">&#10004;</div>
              )}
              Inline view
            </span>
          </div>
        </div>
      </div>
    );
  }
}
