import React from "react";
import { connect } from "react-redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { Autocomplete } from "../components/ui/Autocomplete";
import { getMedicationHints } from "../actions/medicationActions";

const enhancer = connect(
  ({ medication: { fetchingHintList, hintList } }) => ({
    fetchingHintList,
    hintList: hintList.map(({ name }) => name),
  }),
  { getMedicationHints },
);

export const MedicationHintsAutocompleteWrapper = enhancer(
  class MedicationHintsAutocompleteWrapperView extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        searchAPIDebounced: AwesomeDebouncePromise(props.getMedicationHints, 500),
      };
    }

    onChangeHandler = (text) => {
      const { input } = this.props;
      const { searchAPIDebounced } = this.state;

      input.onChange(text);
      searchAPIDebounced(text);
    };

    render() {
      const {
        input,
        hintList,
        placeholder,
        inputClassName,
        fetchingHintList,
        name,
        onSelect,
      } = this.props;

      return (
        <Autocomplete
          name={name}
          onBlur={input.onBlur}
          options={hintList}
          value={input.value}
          onSelect={onSelect}
          placeholder={placeholder}
          fetching={fetchingHintList}
          inputClassName={inputClassName}
          onChange={this.onChangeHandler}
        />
      );
    }
  },
);
