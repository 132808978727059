import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/medicalFillInFormActionTypes";
import {
  defaultStackItem,
  medicalFillInFormStackCodes,
} from "../constants/chart-sidebar/medicalFIllInFormStackCodes";
import { CHART_SHOW_SIDE_BAR } from "../actions/chartActions";
import _ from "lodash";
import Utils from "../helpers/Utils";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../constants/Constants";
import moment from "moment";

export const defaultState = {
  medicalFillInFormSideBarVisible: false,
  medicalFillInFormMenuVisible: true,
  templateGroupList: [],
  stack: [defaultStackItem],
  sortField: "created_date_desc",
  fetching: false,
  templateTabList: [],
  consultationItems: [],
  consultationId: null,
  activeTab: {},
  ownedList: [],
  referenceList: [],
  category: [],
  templateList: [],
  hardCodedTemplateList: [],
  searchValue: "",
  item: {
    name: "",
    questions: [],
  },
  fullScreen: false,
  frequencyList: [],
  currentQuestionIndex: 0,
  deleteModalVisible: false,
  onAppointment: false,
  isNew: false,
  multiQuestionOtherValue: {},
  medicalFillInFormAddendumId: 0,
  treatmentList: [],
  treatmentListFetching: false,
  patientVitalTransactionFetching: false,
  diagnosisList: [],
  addendumList: [],
  addendumItem: {},
  diagnosisListFetching: false,
  consentFormViewData: {},
  empty: {},
  patientVitalsTransactionsList: [],
  patientVitalTransaction: {},
  vitalsInitialValues: {
    visitId: "",
    opIp: "OP",
    stage: {},
    BMI: "",
  },
  vitalsTotalCount: 0,
  initialExaminationItem: {},
  initialExaminationConsultation: {
    name: "",
    questions: [],
  },
  isInitialExaminationTabDisable: false,
  dataToFill: "",
  isUserLeavingWithoutSaving: true,
  historicalListCount: null,
  openTextArea: false,
  questionList: [],
  questionListFetching: false,
  questionListTotalCount: 0,
  questionsModalVisible: false,
  formTextAreaValue: "",
  medicalTypeFilter: "new",
};

const reducers = {
  [ActionTypes.MEDICAL_FILL_IN_FORM_MEDICAL_TYPE_FILTER](state, { mode }) {
    return {
      ...state,
      medicalTypeFilter: mode,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_TEXT_AREA_VALUE](state, { data }) {
    return {
      ...state,
      formTextAreaValue: data,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_QUESTIONS_MODAL_VISIBLE](state) {
    return {
      ...state,
      questionsModalVisible: !state.questionsModalVisible,
      questionList: !state.questionsModalVisible ? [] : state.questionList,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_START](state) {
    return {
      ...state,
      questionListFetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_SUCCESS](state, { data, request }) {
    const list = (data.list || [])
      .filter((question) => Boolean(question.showInFilling))
      .map((item) => {
        return {
          ...item,
          defaultAnswer: item.defaultAnswer ? item.defaultAnswer : [],
        };
      });

    return {
      ...state,
      questionList: request.start ? state.questionList.concat(list) : list || [],
      questionListTotalCount: data.totalCount,
      questionListFetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_ERROR](state) {
    return {
      ...state,
      questionListFetching: false,
    };
  },
  [ActionTypes.SET_DATA_TO_FILL](state, { data }) {
    return {
      ...state,
      dataToFill: data,
    };
  },

  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_START](state) {
    return {
      ...state,
      addendumList: [],
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_ERROR](state) {
    return {
      ...state,
      addendumList: [],
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      addendumList: data.list || [],
    };
  },
  [ActionTypes.OPEN_TEXT_AREA](state, { openTextArea }) {
    return {
      ...state,
      openTextArea,
    };
  },
  [ActionTypes.SET_ADDENDUM_ITEM](state, { addendumItem }) {
    return {
      ...state,
      addendumItem,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_SET_ADDENDUM_ID](state, { id }) {
    return {
      ...state,
      medicalFillInFormAddendumId: id,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_START](state) {
    return {
      ...state,
      treatmentListFetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      treatmentListFetching: false,
      treatmentList: data.list || [],
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_ERROR](state) {
    return {
      ...state,
      treatmentListFetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_START](state) {
    return {
      ...state,
      diagnosisListFetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      diagnosisListFetching: false,
      diagnosisList: data.list || [],
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_ERROR](state) {
    return {
      ...state,
      diagnosisListFetching: false,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_FULL_SCREEN](state, { fullScreen }) {
    return {
      ...state,
      fullScreen,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_SHOW_SIDE_BAR](state) {
    return {
      ...state,
      medicalFillInFormSideBarVisible: true,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_HIDE_SIDE_BAR](state) {
    return {
      ...state,
      medicalFillInFormSideBarVisible: false,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_TOGGLE_MENU](state) {
    return {
      ...state,
      medicalFillInFormMenuVisible: !state.medicalFillInFormMenuVisible,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TAB_LIST_SUCCESS](state, { data }) {
    const newData = [
      {
        name: "Initial Examination",
        code: "INITIAL_EXAMINATION",
      },
      ...data,
      {
        description: "addendum",
        id: Date.now() + 1,
        name: "Addendums",
      },
      {
        description: "treatment",
        id: Date.now(),
        name: "DISCHARGE/FINISH",
      },
    ];

    if (!_.isEmpty(newData)) {
      newData.forEach((item) => {
        item.isInitialExamination =
          (item.code && item.code.indexOf("INITIAL_EXAMINATION") >= 0) || false;
      });
    }

    return {
      ...state,
      templateTabList: newData,
    };
  },
  [ActionTypes.GET_CONSULTATION_TEMPLATE_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      consultationItems: [...state.consultationItems, data],
    };
  },
  [ActionTypes.GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      initialExaminationConsultation: {
        ...state.initialExaminationConsultation,
        ...data,
        questions: data.questions || [],
      },
    };
  },
  [ActionTypes.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS](state) {
    return {
      ...state,
      consultationItems: [],
    };
  },
  [ActionTypes.SET_ACTIVE_TAB](state, { activeTab }) {
    return {
      ...state,
      activeTab,
      item: {
        name: "",
        questions: [],
        template: null,
      },
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_SUCCESS](state, { data, request }) {
    if (data.initialExaminationItem || state.activeTab.isInitialExamination) {
      return {
        ...state,
        initialExaminationConsultation: {
          ...state.initialExaminationConsultation,
          ...data,
          questions: data.questions || [],
        },
        fetching: false,
        consultationId: data.consultationId || request.consultationId,
        isUserLeavingWithoutSaving: true,
      };
    }

    return {
      ...state,
      item: {
        ...state.item,
        ...data,
        questions: data.questions || [],
      },
      fetching: false,
      consultationId: data.consultationId || request.consultationId,
      isUserLeavingWithoutSaving: true,
    };
  },
  [ActionTypes.SAVE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS](state, { data, request }) {
    return {
      ...state,
      consultationId: data.consultationId || request.consultationId,
      isUserLeavingWithoutSaving: false,
      dataToFill: "",
    };
  },
  [ActionTypes.SET_CONSULTATION_ID](state, { consultationId }) {
    return {
      ...state,
      consultationId,
    };
  },
  [ActionTypes.RESET_MEDICAL_FILL_IN_FORM_FOR](state) {
    return {
      ...state,
      addendumList: [],
      addendumItem: {},
      item: {
        name: "",
        questions: [],
      },
      empty: {},
      templateGroupList: [],
      templateTabList: [],
      consultationId: null,
      consultationItems: [],
      activeTab: {},
      ownedList: [],
      referenceList: [],
      category: [],
      templateList: [],
      hardCodedTemplateList: [],
      treatmentList: [],
      diagnosisList: [],
      initialExaminationItem: {},
      disableTabs: false,
      initialExaminationConsultation: {
        name: "",
        questions: [],
      },
      isUserLeavingWithoutSaving: true,
      dataToFill: "",
      historicalListCount: null,
      medicalTypeFilter: "new",
    };
  },
  [ActionTypes.RESET_MEDICAL_FILL_IN_FORM_CONSULTATION_ID](state) {
    return {
      ...state,
      consultationId: null,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_INIT_TEXTAREA](state, { patient }) {
    return {
      ...state,
      item: {
        name: "",
        note: "",
        patient,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      templateGroupList: [
        { label: "All groups", value: "" },
        ...data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      ],
    };
  },
  [ActionTypes.CLEAR_MEDICAL_FILL_IN_FORM_STACK](state) {
    return {
      ...state,
      stack: [defaultStackItem],
    };
  },
  [ActionTypes.UPDATE_MEDICAL_FILL_IN_FORM_STACK](state, { stack }) {
    return {
      ...state,
      stack: [stack],
    };
  },
  [ActionTypes.GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      frequencyList: data.list || [],
      historicalListCount: (data.list || []).length,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE](state, { id, data }) {
    const { multiQuestionOtherValue } = state;

    multiQuestionOtherValue[id] = data;

    return {
      ...state,
      multiQuestionOtherValue,
    };
  },
  [CHART_SHOW_SIDE_BAR](state, { isNew }) {
    return {
      ...state,
      isNew,
    };
  },
  [ActionTypes.INIT_SIDE_MEDICAL_FILL_IN_FORM](state, { onAppointment }) {
    return {
      ...state,
      stack: state.isNew ? [{ code: medicalFillInFormStackCodes.list }] : [defaultStackItem],
      searchValue: "",
      currentQuestionIndex: 0,
      onAppointment,
    };
  },
  [ActionTypes.SELECT_SORT_FIELD_SIDE_MEDICAL_FILL_IN_FORM](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.CHART_MEDICAL_FILL_IN_FORM_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.PUSH_MEDICAL_FILL_IN_FORM_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.POP_MEDICAL_FILL_IN_FORM_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        ownedList: state.ownedList.concat(data.list),
        ownedListTotalCount: data.totalCount,
        historicalListCount: (data.list || []).length,
      };
    }

    return {
      ...state,
      fetching: false,
      ownedList: data.list || [],
      ownedListTotalCount: data.totalCount,
      historicalListCount: (data.list || []).length,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.RESET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST](state) {
    return {
      ...state,
      templateListCount: 0,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        templateList: state.templateList.concat(data.list || []),
        templateListCount: data.totalCount,
      };
    }

    return {
      ...state,
      templateList: data.list || [],
      templateListCount: data.totalCount,
      fetching: false,
      item: {
        name: "",
        questions: [],
      },
    };
  },
  [ActionTypes.IS_INITIAL_EXAMINATION_DISABLE_TOGGLE](state, { _bool }) {
    return {
      ...state,
      isInitialExaminationTabDisable: _bool,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        hardCodedTemplateList: state.hardCodedTemplateList.concat(data.list || []),
        hardCodedTemplateListCount: data.totalCount,
      };
    }

    return {
      ...state,
      hardCodedTemplateList: data.list || [],
      hardCodedTemplateListCount: data.totalCount,
      fetching: false,
      item: {
        name: "",
        questions: [],
      },
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_ITEM_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      referenceList: _.get(data, "list", []),
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS](state, { data }) {
    const writtenNote = !data.template?.name;
    const drawenNote = data.typeCode === "NOTE_TYPE_IMAGE";
    if (!writtenNote && !drawenNote) {
      const orderedQuestions = [];

      const reqExp = /\[\[[^\]]*]]/g;

      if (data.template.code) {
        data.template.code.replace(reqExp, (match) => {
          const code = match.slice(2, -2);
          if (code == "Current date") {
            return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
          }
          if (code == "Current time") {
            return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
          }
          if (code == "Patient age") {
            return data.patient.age;
          }
          if (code == "Patient chart number") {
            return data.patient.chartNumber;
          }
          if (code == "Patient name") {
            return data.patient.fullName;
          }
          if (code == "Dentist Name") {
            return data.doctor.name;
          }
          if (code === "Patient nationality") {
            return _.get(data, "patient.nationality.name", "N/A");
          }
          if (code === "Patient date of birth") {
            const dateFormat = localStorage.getItem("company-date-format");

            return Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(data.patient && data.patient.dateOfBirth),
              dateFormat,
            );
          }
          if (code === "Doctor license id") {
            return data.owner && data.owner.code;
          }

          const question = _.get(data, "templateQuestionsAndAnswers", []).find(
            (question) => question.code == code,
          );

          if (question) orderedQuestions.push(question);

          return match;
        });
      }

      data.templateQuestionsAndAnswers = orderedQuestions;
    }

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_EMPTY_START](state) {
    return {
      ...state,
      empty: {},
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_EMPTY_SUCCESS](state, { data }) {
    return {
      ...state,
      empty: data,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_PREV_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: state.currentQuestionIndex - 1,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_NEXT_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: +state.currentQuestionIndex + 1,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_SELECT_QUESTION](state, { index }) {
    return {
      ...state,
      currentQuestionIndex: index,
    };
  },
  [ActionTypes.SET_CONSENT_FORM_VIEW_DETAILS](state, { data }) {
    return {
      ...state,
      consentFormViewData: data,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA](state, { value, questionId }) {
    const templateQuestionsAndAnswers = (state.item.templateQuestionsAndAnswers || []).map(
      (question) => {
        if (question.id !== questionId) return question;

        if (!question.answers) question.answers = [];

        question.answers[0] = value;

        return {
          ...question,
        };
      },
    );

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_CANVAS](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE](
    state,
    { value, questionId },
  ) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_DATE_PICKER](state, { date, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [moment()];

      question.answers[0] = date;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CHANGE_SURFACE](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_SELECT_TOOTH](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_SUCCESS](state, { data: { createdDate } }) {
    return {
      ...state,
      item: {
        ...state.item,
        createdDate,
      },
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_OPEN_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: true,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_CLOSE_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: false,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_INIT_EDIT](state) {
    return {
      ...state,
      currentQuestionIndex: 0,
    };
  },
  [ActionTypes.MEDICAL_FILL_IN_FORM_SET_NAME](state, { value }) {
    return {
      ...state,
      item: {
        ...state.item,
        name: value,
      },
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_START](state) {
    return {
      ...state,
      patientVitalTransactionFetching: true,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      patientVitalsTransactionsList: request.start
        ? state.patientVitalsTransactionsList.concat(data.list || [])
        : data.list || [],
      vitalsTotalCount: data.totalCount,
      patientVitalTransactionFetching: false,
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_ERROR](state) {
    return {
      ...state,
      patientVitalTransactionFetching: false,
      vitalsTotalCount: 0,
      patientVitalsTransactionsList: [],
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_SUCCESS](state, { data }) {
    return {
      ...state,
      patientVitalTransaction: data,
      vitalsInitialValues: {
        ...state.vitalsInitialValues,
        opIp: "OP",
        stage: {},
        BMI: "",
        doctor: {},
        visitId: data.visitId,
      },
    };
  },
  [ActionTypes.SET_MEDICAL_FILL_IN_FORM_VITAL_VALUE_TO_EDIT](state, { values }) {
    const { patientVitalTransaction } = state;
    const prevTransactionList = patientVitalTransaction?.transactions?.slice();
    const currentObj = (values || []).find(
      (item) => (item?.vitalName || "").toUpperCase() === "BMI",
    );

    if (!currentObj?.value) {
      return {
        ...state,
        vitalsInitialValues: {
          ...state.vitalsInitialValues,
          visitId: currentObj.visitId,
          doctor: currentObj?.doctor?.id
            ? { value: currentObj.doctor.id, label: currentObj.doctor.name }
            : {},
          stage: currentObj?.stage?.id
            ? { value: currentObj.stage.id, label: currentObj.stage.name }
            : {},
        },

        patientVitalTransaction: {
          patient: patientVitalTransaction.patient,
          stages: patientVitalTransaction.stages,
          transactions: values,
          prevTransactions: prevTransactionList,
        },
      };
    }

    const updatedValues = {
      ...state.vitalsInitialValues,
      BMI: currentObj.value ? Utils.calculateBMICategory(Number(currentObj.value).toFixed(2)) : "",
      visitId: currentObj.visitId,
      doctor: currentObj.doctor,
    };

    if (currentObj?.doctor?.id) {
      updatedValues.doctor = { value: currentObj.doctor.id, label: currentObj.doctor.name };
    }

    if (currentObj.stage && currentObj.stage.id) {
      updatedValues.stage = { value: currentObj.stage.id, label: currentObj.stage.name };
    } else {
      updatedValues.stage = {};
    }

    return {
      ...state,
      vitalsInitialValues: {
        ...state.vitalsInitialValues,
        ...updatedValues,
      },

      patientVitalTransaction: {
        patient: patientVitalTransaction.patient,
        stages: patientVitalTransaction.stages,
        transactions: values,
        prevTransactions: prevTransactionList,
      },
    };
  },
  [ActionTypes.CLEAR_MEDICAL_FILL_IN_FORM_VITAL_VALUES](state) {
    return {
      ...state,
      patientVitalTransaction: {},
      vitalsInitialValues: {
        visitId: "",
        opIp: "OP",
        stage: {},
        BMI: "",
      },
    };
  },
  [ActionTypes.GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS](
    state,
    { data },
  ) {
    return {
      ...state,
      initialExaminationItem: data || {},
      fetching: false,
      isUserLeavingWithoutSaving: false,
      dataToFill: "",
    };
  },
  [ActionTypes.SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START](state) {
    return {
      ...state,
      initialExaminationItem: {},
    };
  },
  [ActionTypes.SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS](
    state,
    { data, request },
  ) {
    return {
      ...state,
      consultationId: data.consultationId || request.consultationId,
    };
  },
};
export default createReducer(defaultState, reducers);
