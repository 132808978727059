import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import Back from "../../widgets/Back";
import EmbedButton from "../../widgets/EmbedButton";
import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";
import CalendarPrintDetailsEmailModal from "./CalendarPrintDetailsEmailModal";

const enhancer = connect(({ calendar: { printDetails, sendToEmailModalVisible } }) => {
  return {
    printDetails,
    sendToEmailModalVisible,
  };
}, bindActions({ calendarActions }));

class CalendarPrintDetails extends React.Component {
  componentDidMount() {
    if (this.props.match.params.type) {
      this.props.calendarActions.getCalendarPrintDetails(this.props.match.params.type);
    }
  }

  sendToEmailModalShow(printMode) {
    if (printMode) {
      this.props.calendarActions.sendDetailsToEmailModalShow();
    }
  }

  render() {
    const { printDetails, sendToEmailModalVisible } = this.props;

    let disabledPrintMode =
      printDetails && _.lowerCase(printDetails) !== "appointments does not exists";

    return (
      <div className="calendar-print-details">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" title="CANCEL" />
          <button
            disabled={!disabledPrintMode}
            className="btn btn-link pull-right"
            onClick={() => {
              this.sendToEmailModalShow(disabledPrintMode);
            }}
          >
            SEND TO EMAIL
          </button>
        </div>

        <EmbedButton show={disabledPrintMode} src={printDetails} />

        <Show if={sendToEmailModalVisible && disabledPrintMode}>
          <CalendarPrintDetailsEmailModal
            url={printDetails}
            sendToEmailModalVisible={sendToEmailModalVisible}
          />
        </Show>
      </div>
    );
  }
}

export default enhancer(CalendarPrintDetails);
