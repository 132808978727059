import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import VoidedTreatmentsDialogList from "./VoidedTreatmentsDialogList";

import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";

const enhancer = connect(null, bindActions({ chartActions }));
export default enhancer(
  class VoidedTreatmentDialog extends React.Component {
    componentDidMount() {
      this.props.chartActions.getVoidedTreatmentsGetList(this.props.patientKey);
    }

    render() {
      const {
        chartActions,
        show,
        voidedTreatmentsList,
        voidedTreatmentsListFetching,
        voidedTreatmentsSelectedItems,
      } = this.props;
      return (
        <Modal
          size="lg"
          show={show}
          className="select-treatment-to-complete-modal"
          keyboard={false}
          onHide={chartActions.chartVoidedHideModal}
          title={<div>Voided Treatments</div>}
          actions={
            <div>
              <Button
                onClick={chartActions.chartVoidedHideModal}
                type="button"
                className="btn btn-default"
              >
                Close
              </Button>
              <Button
                onClick={() => chartActions.restoreVoidedTreatments(this.props.patientKey)}
                type="button"
                className="btn btn-primary"
              >
                Restore
              </Button>
            </div>
          }
        >
          <VoidedTreatmentsDialogList
            list={voidedTreatmentsList}
            selectedKeys={voidedTreatmentsSelectedItems}
            fetching={voidedTreatmentsListFetching}
          />
        </Modal>
      );
    }
  },
);
