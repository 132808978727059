export const GET_PATIENT_GENDER_START = "GET_PATIENT_GENDER_START";
export const GET_PATIENT_GENDER_SUCCESS = "GET_PATIENT_GENDER_SUCCESS";
export const GET_PATIENT_GENDER_ERROR = "GET_PATIENT_GENDER_ERROR";

export const GET_PATIENT_PRICE_LIST_START = "GET_PATIENT_PRICE_LIST_START";
export const GET_PATIENT_PRICE_LIST_SUCCESS = "GET_PATIENT_PRICE_LIST_SUCCESS";
export const GET_PATIENT_PRICE_LIST_ERROR = "GET_PATIENT_PRICE_LIST_ERROR";

export const SEARCH_PATIENT_LIST_START = "SEARCH_PATIENT_LIST_START";
export const SEARCH_PATIENT_LIST_SUCCESS = "SEARCH_PATIENT_LIST_SUCCESS";
export const SEARCH_PATIENT_LIST_ERROR = "SEARCH_PATIENT_LIST_ERROR";

export const GET_PATIENT_DETAILS_START = "GET_PATIENT_DETAILS_START";
export const GET_PATIENT_DETAILS_SUCCESS = "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_ERROR = "GET_PATIENT_DETAILS_ERROR";

export const SET_INSURANCE_LIST = "SET_INSURANCE_LIST";

export const GET_INSURANCE_LIST_START = "GET_INSURANCE_LIST_START";
export const GET_INSURANCE_LIST_SUCCESS = "GET_INSURANCE_LIST_SUCCESS";
export const GET_INSURANCE_LIST_ERROR = "GET_INSURANCE_LIST_ERROR";

export const GET_PATIENT_PAYMENT_OPTIONS_START = "GET_PATIENT_PAYMENT_OPTIONS_START";
export const GET_PATIENT_PAYMENT_OPTIONS_SUCCESS = "GET_PATIENT_PAYMENT_OPTIONS_SUCCESS";
export const GET_PATIENT_PAYMENT_OPTIONS_ERROR = "GET_PATIENT_PAYMENT_OPTIONS_ERROR";

export const GET_INSURANCE_ITEM_START = "GET_INSURANCE_ITEM_START";
export const GET_INSURANCE_ITEM_SUCCESS = "GET_INSURANCE_ITEM_SUCCESS";
export const GET_INSURANCE_ITEM_ERROR = "GET_INSURANCE_ITEM_ERROR";

export const SAVE_INSURANCE_ITEM_START = "SAVE_INSURANCE_ITEM_START ";
export const SAVE_INSURANCE_ITEM_SUCCESS = "SAVE_INSURANCE_ITEM_SUCCESS";
export const SAVE_INSURANCE_ITEM_ERROR = "SAVE_INSURANCE_ITEM_ERROR";

export const DELETE_INSURANCE_ITEM_START = "DELETE_INSURANCE_ITEM_START ";
export const DELETE_INSURANCE_ITEM_SUCCESS = "DELETE_INSURANCE_ITEM_SUCCESS";
export const DELETE_INSURANCE_ITEM_ERROR = "DELETE_INSURANCE_ITEM_ERROR";

export const GET_INSURANCE_COMPANY_START = "GET_INSURANCE_COMPANY_START";
export const GET_INSURANCE_COMPANY_SUCCESS = "GET_INSURANCE_COMPANY_SUCCESS";
export const GET_INSURANCE_COMPANY_ERROR = "GET_INSURANCE_COMPANY_ERROR";

export const GET_INSURANCE_COMPANY_PLAN_START = "GET_INSURANCE_COMPANY_PLAN_START";
export const GET_INSURANCE_COMPANY_PLAN_SUCCESS = "GET_INSURANCE_COMPANY_PLAN_SUCCESS";
export const GET_INSURANCE_COMPANY_PLAN_ERROR = "GET_INSURANCE_COMPANY_PLAN_ERROR";

export const GET_INSURANCE_RELATION_START = "GET_INSURANCE_RELATION_START";
export const GET_INSURANCE_RELATION_SUCCESS = "GET_INSURANCE_RELATION_SUCCESS";
export const GET_INSURANCE_RELATION_ERROR = "GET_INSURANCE_RELATION_ERROR";

export const GET_INSURANCE_BROKER_START = "GET_INSURANCE_BROKER_START";
export const GET_INSURANCE_BROKER_SUCCESS = "GET_INSURANCE_BROKER_SUCCESS";
export const GET_INSURANCE_BROKER_ERROR = "GET_INSURANCE_BROKER_ERROR";

export const SEARCH_BY_CARD_NUMBER_START = "SEARCH_BY_CARD_NUMBER_START";
export const SEARCH_BY_CARD_NUMBER_SUCCESS = "SEARCH_BY_CARD_NUMBER_SUCCESS";
export const SEARCH_BY_CARD_NUMBER_ERROR = "SEARCH_BY_CARD_NUMBER_ERROR";

export const MARK_AS_PRIMARY_INSURANCE_START = "MARK_AS_PRIMARY_INSURANCE_START";
export const MARK_AS_PRIMARY_INSURANCE_SUCCESS = "MARK_AS_PRIMARY_INSURANCE_SUCCESS";
export const MARK_AS_PRIMARY_INSURANCE_ERROR = "MARK_AS_PRIMARY_INSURANCE_ERROR";

export const PATIENT_PERIO_EXAM_LIST_START = "PATIENT_PERIO_EXAM_LIST_START";
export const PATIENT_PERIO_EXAM_LIST_SUCECSS = "PATIENT_PERIO_EXAM_LIST_SUCCESS";
export const PATIENT_PERIO_EXAM_LIST_ERROR = "PATIENT_PERIO_EXAM_LIST_ERROR";

export const PATIENT_PERIO_EXAM_ITEM_START = "PATIENT_PERIO_EXAM_ITEM_START";
export const PATIENT_PERIO_EXAM_ITEM_SUCECSS = "PATIENT_PERIO_EXAM_ITEM_SUCCESS";
export const PATIENT_PERIO_EXAM_ITEM_ERROR = "PATIENT_PERIO_EXAM_ITEM_ERROR";

export const PATIENT_PERIO_EXAM_START_ITEM_START = "PATIENT_PERIO_EXAM_START_ITEM_START";
export const PATIENT_PERIO_EXAM_START_ITEM_SUCECSS = "PATIENT_PERIO_EXAM_START_ITEM_SUCECSS";
export const PATIENT_PERIO_EXAM_START_ITEM_ERROR = "PATIENT_PERIO_EXAM_START_ITEM_ERROR";

export const PERIO_EXAM_HANDLE_ITEM = "PERIO_EXAM_HANDLE_ITEM";
export const PERIO_EXAM_ITEM_HIDE_MODAL = "PERIO_EXAM_ITEM_HIDE_MODAL";
export const PERIO_EXAM_TEMP_ITEM = "PERIO_EXAM_TEMP_ITEM";
export const PERIO_EXAM_TEMP_CLEAR_ITEM = "PERIO_EXAM_TEMP_CLEAR_ITEM";
export const PERIO_EXAM_ITEM_TEMP_LIST = "PERIO_EXAM_ITEM_TEMP_LIST";

export const PERIO_EXAM_MOVEMENT_TYPE = "PERIO_EXAM_MOVEMENT_TYPE";

export const OPEN_PATIENT_INFO_SIDE_BAR = "OPEN_PATIENT_INFO_SIDE_BAR";
export const CLOSE_PATIENT_INFO_SIDE_BAR = "CLOSE_PATIENT_INFO_SIDE_BAR";

export const TOGGLE_PATIENT_TAG_ITEM = "TOGGLE_PATIENT_TAG_ITEM";
export const SET_PATIENT_TAGS_LIST = "SET_PATIENT_TAGS_LIST";
export const SET_PATIENT_TAG_ITEM_VALUE = "SET_PATIENT_TAG_ITEM_VALUE";

export const GET_PATIENT_TAGS_LIST_START = "GET_PATIENT_TAGS_LIST_START";
export const GET_PATIENT_TAGS_LIST_SUCCESS = "GET_PATIENT_TAGS_LIST_SUCCESS";
export const GET_PATIENT_TAGS_LIST_ERROR = "GET_PATIENT_TAGS_LIST_ERROR";

export const SAVE_PATIENT_TAGS_LIST_START = "SAVE_PATIENT_TAGS_LIST_START";
export const SAVE_PATIENT_TAGS_LIST_SUCCESS = "SAVE_PATIENT_TAGS_LIST_SUCCESS";
export const SAVE_PATIENT_TAGS_LIST_ERROR = "SAVE_PATIENT_TAGS_LIST_ERROR";

export const GET_PATIENT_ASSIGNED_TAGS_START = "GET_PATIENT_ASSIGNED_TAGS_START";
export const GET_PATIENT_ASSIGNED_TAGS_SUCCESS = "GET_PATIENT_ASSIGNED_TAGS_SUCCESS";
export const GET_PATIENT_ASSIGNED_TAGS_ERROR = "GET_PATIENT_ASSIGNED_TAGS_ERROR";

export const PERIO_EXAM_VOID_ITEM_START = "PERIO_EXAM_VOID_ITEM_START";
export const PERIO_EXAM_VOID_ITEM_SUCCESS = "PERIO_EXAM_VOID_ITEM_SUCCESS";
export const PERIO_EXAM_VOID_ITEM_ERROR = "PERIO_EXAM_VOID_ITEM_ERROR";
