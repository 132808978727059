import React from "react";
import { Modal } from "../../../ui/Modal";

const MedicalFillInFormVitalTooltip = ({ tooltipVisible, onClose, values, setFieldValue }) => {
  const _fieldName = `${tooltipVisible?.fieldName}_comment`;

  return (
    <Modal
      show={tooltipVisible.visible}
      keyboard={false}
      animation={false}
      contentClassName="chart-tooltip"
      backdropClassName="chart-tooltip-modal-backdrop"
      dialogClassName="chart-tooltip-modal"
    >
      <div
        className="chart-tooltip-body d-flex flex-column p-2"
        style={{
          width: 300,
          top: tooltipVisible.top,
          left: tooltipVisible.left - 50,
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <textarea
          rows={2}
          maxLength={100}
          className="form-control"
          style={{
            width: "100%",
            minHeight: "80px",
          }}
          value={values[_fieldName] || tooltipVisible.comment}
          onChange={(e) => setFieldValue(_fieldName, e.target.value)}
        />
        <span className="text-left mt-1">
          {(values[_fieldName] || tooltipVisible.comment || "").length}/100
        </span>
        <div className="btn btn-primary" onClick={onClose}>
          ok
        </div>
      </div>
    </Modal>
  );
};
export default MedicalFillInFormVitalTooltip;
