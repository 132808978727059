import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import { AppLayout } from "../app-layout/AppLayout";
import BlockedTimeSlotViewMode from "./BlockedTimeSlotViewMode";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { blockedTimeSlotSelector } from "../../reducers/blockedTimeSlotReducer";
import {
  getDentalPoint,
  getFormData,
  initBlockedTimeSlot,
} from "../../actions/blockedTimeSlotActions";

export default function BlockedTimeSlot({ history }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormData());
    dispatch(getDentalPoint());
    dispatch(initBlockedTimeSlot());
  }, [dispatch]);

  const blockedTimeSlot = useShallowEqualSelector(blockedTimeSlotSelector);

  return (
    <AppLayout
      withLogo={true}
      contentClassName="blocked-time-slot flex-shrink-1 flex-grow-1"
      onBackClick={() => history.push(Routes.DashboardCalendar)}
      rightComponent={
        <button
          type="button"
          className="btn btn-link"
          onClick={() =>
            history.push(createUrl(Routes.DashboardBlockedTimeSlotItem, { query: { id: 0 } }))
          }
        >
          Add New
        </button>
      }
    >
      <div className="blocked-time-slot-body">
        <div className="blocked-time-slot-container">
          <BlockedTimeSlotViewMode blockedTimeSlot={blockedTimeSlot} />
        </div>
      </div>
    </AppLayout>
  );
}
