import React, { useEffect } from "react";
import { GetSigWebVersion, IsSigWebInstalled } from "../SigWebTablet";
import { useDispatch } from "react-redux";
import { openSimpleModal } from "../actions/simpleModalActions";

const SignerComponent = ({ ref, handleSignerStart }) => {
  let resetIsSupported = false;

  const dispatch = useDispatch();
  useEffect(() => {
    handleSignerStart();
  }, []);

  window.onload = function () {
    if (IsSigWebInstalled()) {
      resetIsSupported = GetResetSupported();
      if (!resetIsSupported) {
        let sigweb_link = document.createElement("a");
        sigweb_link.href = "https://www.topazsystems.com/software/sigweb.exe";
        sigweb_link.innerHTML = "https://www.topazsystems.com/software/sigweb.exe";

        let note = document.getElementById("sigWebVrsnNote");
        note.innerHTML = "There is a newer version of SigWeb available here: ";
        note.appendChild(sigweb_link);
      }
    } else {
      dispatch(
        openSimpleModal({
          body:
            "Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC.",
        }),
      );
    }
  };

  const GetResetSupported = () => {
    const minSigWebVersionResetSupport = "1.6.4.0";

    if (isOlderSigWebVersionInstalled(minSigWebVersionResetSupport)) {
      dispatch(
        openSimpleModal({
          body: "Old SigWeb version installed.",
        }),
      );
      return false;
    }
    return true;
  };

  const isOlderSigWebVersionInstalled = (cmprVer) => {
    const sigWebVer = GetSigWebVersion();
    if (sigWebVer !== "") {
      return isOlderVersion(cmprVer, sigWebVer);
    } else {
      return false;
    }
  };

  const isOlderVersion = (oldVer, newVer) => {
    const oldParts = oldVer.split(".");
    const newParts = newVer.split(".");
    for (let i = 0; i < newParts.length; i++) {
      const a = parseInt(newParts[i]) || 0;
      const b = parseInt(oldParts[i]) || 0;
      if (a < b) return true;
      if (a > b) return false;
    }
    return false;
  };

  return <canvas ref={ref} id="cnv" width={600} height={400} />;
};

export default SignerComponent;
