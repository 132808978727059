import React from "react";
import { connect } from "react-redux";

import Header from "./../layout/DefaultPageHeader";
import FollowUpForm from "./FollowUpForm";
import FollowUpCell from "./FollowUpCell";
import FollowUpEditFormModal from "./FollowUpEditFormModal";

import * as followUpActions from "../../actions/followUpActions";
import RepeatAppointmentSidebar from "../appointment-form/repeat-appointment/RepeatAppointmentSidebar";
import Show from "../widgets/Show";
import {
  repeatAppointmentModalShow,
  repeatAppointmentConfirmModalShow,
  repeatAppointmentSetFollowType,
  repeatAppointmentRepeatModalShow,
  repeatAppointmentSetDeleteId,
  repeatAppointmentConfirmModalHide,
  repeatAppointmentModalHide,
} from "../../actions/appointmentActions";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import { hideSideBar } from "../../actions/chartActions";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import ChartSideBar from "../chart/side-bar/ChartSideBar";
import { parseQuery } from "../../utils/UrlUtils";
import { loadList } from "../../actions/followUpActions";

const enhancer = connect(
  ({
    form: { repeatAppointment },
    session: { member },
    followUp,
    chartSideClinicalNote,
    chartSideVital,
    chartSideDocs,
    chart: { sideBarVisible, patientKey, viewType },
    appointment: {
      appointment,
      repeatAppointmentRepeatModalIndex,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentList,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentSelectedDate,
      repeatAppointmentMode,
      repeatAppointmentModalVisible,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentDeleteId,
    },
  }) => ({
    member,
    followUp,
    repeatAppointment,
    chartSideClinicalNote,
    chartSideVital,
    chartSideDocs,
    sideBarVisible,
    appointment,
    viewType,
    patientKey,
    repeatAppointmentRepeatModalIndex,
    repeatAppointmentRepeatModalDate,
    repeatAppointmentRepeatModalMode,
    repeatAppointmentRepeatModalVisible,
    repeatAppointmentList,
    repeatAppointmentConfirmModalVisible,
    repeatAppointmentSelectedDate,
    repeatAppointmentMode,
    repeatAppointmentModalVisible,
    repeatAppointmentClickedRepeatArray,
    repeatAppointmentDeleteId,
  }),
);
class FollowUp extends React.Component {
  handleSubmit = (values) => {
    this.props.dispatch(followUpActions.getList(values));
  };

  componentDidMount() {
    const { location } = this.props;
    const query = parseQuery(location.search);
    if (query.responded) {
      this.props.dispatch(followUpActions.toggleResponded(true));
    }
    this.props.dispatch(followUpActions.getTypes()).then(() => {
      this.props.dispatch(followUpActions.getListByTab());
    });
  }

  componentWillUnmount() {
    this.props.dispatch(followUpActions.toggleResponded(false));
  }

  componentDidUpdate(prevProps) {
    const {
      repeatAppointmentModalVisible: prevModal,
      followUp: { item: prevItem, activeTab: prevActiveTab },
    } = prevProps;
    const {
      repeatAppointmentModalVisible: nextModal,
      followUp: { item: nextItem, activeTab: nextActiveTab, typeList },
    } = this.props;

    if (prevActiveTab !== nextActiveTab && typeList.length) {
      this.props.dispatch(followUpActions.getListByTab());
    }

    if (prevItem.id !== nextItem.id && nextItem.id) {
      this.props.dispatch(followUpActions.getRulesList(nextItem.id));
    }

    if (!prevModal && nextModal) {
      this.props.dispatch(repeatAppointmentSetFollowType("after"));
    }
  }

  handleRepeatClick = (index, repeatClickType, id) => {
    if (repeatClickType === "repeat") this.props.dispatch(repeatAppointmentRepeatModalShow(index));
    else {
      this.props.dispatch(repeatAppointmentSetDeleteId(id));
      this.props.dispatch(repeatAppointmentConfirmModalShow());
    }
  };

  handleDeleteClick = (id) => {
    this.props.dispatch(repeatAppointmentConfirmModalHide());
    this.props.dispatch(followUpActions.deleteItem(id));
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.dispatch(followUpActions.loadList());
    }
  };

  render() {
    const {
      followUp: {
        fromDate,
        toDate,
        chartNumber,
        list,
        repeatList,
        typeList,
        doctors,
        showComplete,
        showResponded,
        showEditForm,
        activeTab,
      },
      history,
    } = this.props;
    const $list = list.map((item) => (
      <FollowUpCell key={item.id} item={item} dispatch={this.props.dispatch} />
    ));
    return (
      <div className="waiting-list-page">
        <Header
          onClick={() => history.push(Routes.DashboardCalendar)}
          addClass="waiting-list-header mt-1"
          text="FOLLOW UP"
        >
          <FollowUpForm
            member={this.props.member}
            onSubmit={this.handleSubmit}
            fromDate={fromDate}
            toDate={toDate}
            chartNumber={chartNumber}
            doctors={doctors}
            typeList={typeList}
            showComplete={showComplete}
            showResponded={showResponded}
          />
        </Header>
        <div className="m-2">
          <button
            style={{ width: "50%" }}
            className={`btn ${activeTab === "query" ? "btn-success" : "btn-default"}`}
            onClick={() => this.props.dispatch(followUpActions.setFollowTabType("query"))}
          >
            Doctor Query Form
          </button>
          <button
            style={{ width: "50%" }}
            className={`btn ${activeTab === "misc" ? "btn-success" : "btn-default"}`}
            onClick={() => this.props.dispatch(followUpActions.setFollowTabType("misc"))}
          >
            Misc. Followups
          </button>
          <button
            style={{ width: "20%" }}
            className={`btn ${!showResponded ? "btn-success" : "btn-default"} mt-2 mr-2`}
            onClick={() => {
              this.props.dispatch(followUpActions.toggleResponded(false));
              this.props.dispatch(followUpActions.getListByTab());
            }}
          >
            Show all
          </button>
          <button
            style={{ width: "20%" }}
            className={`btn ${showResponded ? "btn-success" : "btn-default"} mt-2 mr-2`}
            onClick={() => {
              this.props.dispatch(followUpActions.toggleResponded(true));
              this.props.dispatch(followUpActions.getListByTab());
            }}
          >
            Show unread
          </button>
          <button
            style={{ width: "20%" }}
            className={`btn ${showComplete ? "btn-success" : "btn-default"} mt-2`}
            type="button"
            onClick={() => {
              this.props.dispatch(followUpActions.toggleComplete());
              this.props.dispatch(followUpActions.getListByTab());
            }}
          >
            {showComplete ? "Hide Complete" : "Show complete"}
          </button>
        </div>
        <div className="waiting-list-header-wrapper">
          <div className="follow-up-header header">
            <div className="follow-up-cell follow-up-cell-1">&nbsp;</div>
            <div className="follow-up-cell follow-up-cell-5">Doctor</div>
            <div className="follow-up-cell follow-up-cell-5">Created By</div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>Patient</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>Chart Number</div>
            </div>
            <div className="follow-up-cell follow-up-cell-3">
              <div>Type</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>Created Date</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>Last Replay Date</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">Last Replay By</div>
            <div className="follow-up-cell follow-up-cell-4">
              <div>Action</div>
            </div>
          </div>
        </div>
        <div className="waiting-list-body" onScroll={this.loadList}>
          {$list}
        </div>

        {showEditForm && (
          <FollowUpEditFormModal
            dispatch={this.props.dispatch}
            followUp={this.props.followUp}
            onRepeatClick={() => this.props.dispatch(repeatAppointmentModalShow())}
          />
        )}

        <Show if={this.props.repeatAppointmentModalVisible}>
          <RepeatAppointmentSidebar
            withPickDate={false}
            onRepeatClick={this.handleRepeatClick}
            onDeleteClick={this.handleDeleteClick}
            onClickUpdate={(x) => {
              this.props.dispatch(followUpActions.repeatItem2(x));
              this.props.dispatch(repeatAppointmentModalHide());
              this.props.dispatch(followUpActions.closeEditForm());
            }}
            handleOK={(same) => this.props.dispatch(followUpActions.repeatItem(same))}
            onSubmit={() => this.props.dispatch(repeatAppointmentConfirmModalShow())}
            show={this.props.repeatAppointmentModalVisible}
            repeatAppointmentSelectedDate={this.props.repeatAppointmentSelectedDate}
            repeatAppointmentMode={this.props.repeatAppointmentMode}
            repeatAppointmentConfirmModalVisible={this.props.repeatAppointmentConfirmModalVisible}
            repeatAppointmentList={repeatList}
            repeatAppointment={this.props.repeatAppointment}
            onSaveFollowAfter={() =>
              this.props.dispatch(followUpActions.repeatAppointmentSaveAfter())
            }
            repeatAppointmentRepeatModalVisible={this.props.repeatAppointmentRepeatModalVisible}
            repeatAppointmentRepeatModalMode={this.props.repeatAppointmentRepeatModalMode}
            repeatAppointmentRepeatModalDate={this.props.repeatAppointmentRepeatModalDate}
            repeatAppointmentRepeatModalIndex={this.props.repeatAppointmentRepeatModalIndex}
            repeatAppointmentClickedRepeatArray={this.props.repeatAppointmentClickedRepeatArray}
            repeatAppointmentDeleteId={this.props.repeatAppointmentDeleteId}
          />
        </Show>
        {this.props.viewType === viewTypes.clinicalNote && (
          <ChartSideBar
            clinicalNotesSideBarViewType={this.props.session?.clinicalNotesSideBarViewType}
            onAppointment={true}
            location={this.props.location}
            history={this.props.history}
            sideBarVisible={this.props.sideBarVisible}
            teeth={this.props.teeth}
            member={this.props.member}
            viewType={this.props.viewType}
            chartSideClinicalNote={this.props.chartSideClinicalNote}
            chartSideVital={this.props.chartSideVital}
            toothNumericType={this.props.session?.toothNumericType}
            hideSideBar={this.props.dispatch(hideSideBar)}
            permissions={this.props.session?.permissions}
            chartSideDocs={this.props.chartSideDocs}
            clinic={this.props.session?.clinic}
            patientKey={this.props?.patientKey}
          />
        )}
      </div>
    );
  }
}
export default withRouter(enhancer(FollowUp));
