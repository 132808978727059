import { UseMutationResult, useMutation } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

export function useDeletePatientFamilyMemberMutation(): UseMutationResult<unknown, Error, any> {
  return useMutation((query) =>
    httpClient.delete(ApiRoutes.patientFamilyMemberItem, { params: query }).then(formatResponse),
  );
}
