import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { update } from "immupdate";

export class ChangelogItem extends React.PureComponent {
  propTypes = {
    isLast: PropTypes.bool,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    const { open } = this.state;
    const { data, isLast, className } = this.props;

    return (
      <div
        role="button"
        style={{ userSelect: "none" }}
        className={cx("d-flex flex-column well", { "mb-3": !isLast }, className)}
        onClick={() => this.setState((prev) => update(prev, { open: !prev.open }))}
      >
        <div className="d-flex justify-content-between">
          <span style={{ fontSize: "18px" }}>{data.version}</span>
          <span style={{ fontSize: "18px", color: "#cccccc" }}>{data.date}</span>
        </div>

        {open && (
          <ul className="pl-4 text-left mt-1">
            {data.changes.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
