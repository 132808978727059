import React from "react";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

export default class RepeatAppointmentConfirmModal extends React.Component {
  render() {
    const { text, show, handleClose, handleOK } = this.props;

    return (
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        actions={
          <>
            <Button
              type="button"
              className="btn-primary btn-block"
              onClick={() => {
                handleOK(false);
              }}
            >
              at available time
            </Button>
            <Button
              type="button"
              className="btn-primary btn-block"
              onClick={() => {
                handleOK(true);
              }}
            >
              at same time
            </Button>
            <Button type="button" className="btn-block" onClick={handleClose}>
              Cancel
            </Button>
          </>
        }
      >
        {text}
      </Modal>
    );
  }
}
