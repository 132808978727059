import React from "react";
import ArrowRightImage from "../../../assets/images/arrows/arrow_right.png";

export default function ChartClinicalNoteCategoryItem({ item, style, openItem }) {
  return (
    <button
      style={style}
      onClick={openItem}
      className="btn btn-default btn-block chart-clinical-note-category-item d-flex align-items-center justify-content-between"
    >
      {item.name}
      <img src={ArrowRightImage} width={15} height={20} alt="" />
    </button>
  );
}
