const lengthOptions = [
  { value: 300000, label: "5" },
  { value: 600000, label: "10" },
  { value: 900000, label: "15" },
  { value: 1200000, label: "20" },
  { value: 1500000, label: "25" },
  { value: 1800000, label: "30" },
  { value: 2100000, label: "35" },
  { value: 2400000, label: "40" },
  { value: 2700000, label: "45" },
  { value: 3000000, label: "50" },
  { value: 3300000, label: "55" },
  { value: 3600000, label: "60" },
  { value: 3900000, label: "65" },
  { value: 4200000, label: "70" },
  { value: 4500000, label: "75" },
  { value: 4800000, label: "80" },
  { value: 5100000, label: "85" },
  { value: 5400000, label: "90" },
  { value: 5700000, label: "95" },
  { value: 6000000, label: "100" },
  { value: 6300000, label: "105" },
  { value: 6600000, label: "110" },
  { value: 6900000, label: "115" },
  { value: 7200000, label: "120" },
  { value: 7500000, label: "125" },
  { value: 7800000, label: "130" },
  { value: 8100000, label: "135" },
  { value: 8400000, label: "140" },
  { value: 8700000, label: "145" },
  { value: 9000000, label: "150" },
  { value: 9300000, label: "155" },
  { value: 9600000, label: "160" },
  { value: 9900000, label: "165" },
  { value: 10200000, label: "170" },
  { value: 10500000, label: "175" },
  { value: 10800000, label: "180" },
  { value: 11100000, label: "185" },
  { value: 11400000, label: "190" },
  { value: 11700000, label: "195" },
  { value: 12000000, label: "200" },
  { value: 12300000, label: "205" },
  { value: 12600000, label: "210" },
  { value: 12900000, label: "215" },
  { value: 13200000, label: "220" },
  { value: 13500000, label: "225" },
  { value: 13800000, label: "230" },
  { value: 14100000, label: "235" },
  { value: 14400000, label: "240" },
  { value: 14700000, label: "245" },
  { value: 15000000, label: "250" },
  { value: 15300000, label: "255" },
  { value: 15600000, label: "260" },
  { value: 15900000, label: "265" },
  { value: 16200000, label: "270" },
  { value: 16500000, label: "275" },
  { value: 16800000, label: "280" },
  { value: 17100000, label: "285" },
  { value: 17400000, label: "290" },
  { value: 17700000, label: "295" },
  { value: 18000000, label: "300" },
];

export default lengthOptions;
