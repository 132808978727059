import _ from "lodash";

import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSideTransactionActionTypes";
import {
  CHART_HIDE_SIDE_BAR_TRANSACTION,
  CHART_SHOW_SIDE_BAR_TRANSACTION,
} from "../actions/chartActions";

export const defaultState = {
  transactionItem: {},
  transactionType: {},
  chooseDeleteModalVisible: false,
  confirmDeleteModalVisible: false,
  accessDeleteModalVisible: false,

  approveList: [],
  approveListTotalCount: 0,
  selectedApprove: {},
  fetchingApprove: false,

  changeCompletedDateError: false,
  changeCompletedDateFetching: false,
};

const reducers = {
  [CHART_HIDE_SIDE_BAR_TRANSACTION](state) {
    return {
      ...state,
      transactionItem: {},
    };
  },
  [CHART_SHOW_SIDE_BAR_TRANSACTION](state, { transactionType, transactionItem }) {
    let parts = transactionItem.parts;

    if (_.isEmpty(parts)) {
      const firstPrice = (transactionItem.price / 2).toFixed(2);
      const secondPrice = (transactionItem.price - +firstPrice).toFixed(2);

      parts = [
        {
          price: firstPrice,
          number: 1,
          comment: "",
        },
        {
          price: secondPrice,
          number: 2,
          comment: "",
        },
      ];
    }

    transactionItem = {
      ...transactionItem,
      parts,
    };

    return {
      ...state,
      transactionType,
      transactionItem,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_SUCCESS](state, { data }) {
    const { treatmentPlan } = data;

    return {
      ...state,
      transactionItem: {
        ...state.transactionItem,
        treatmentPlan,
      },
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_SUCCESS](state, { data }) {
    const { treatmentSession } = data;

    return {
      ...state,
      transactionItem: {
        ...state.transactionItem,
        treatmentSession,
      },
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_OPEN_CHOOSE_DELETE_MODAL](state) {
    return {
      ...state,
      chooseDeleteModalVisible: true,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CLOSE_CHOOSE_DELETE_MODAL](state) {
    return {
      ...state,
      chooseDeleteModalVisible: false,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_OPEN_ACCESS_DELETE_MODAL](state) {
    return {
      ...state,
      accessDeleteModalVisible: true,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CLOSE_ACCESS_DELETE_MODAL](state) {
    return {
      ...state,
      accessDeleteModalVisible: false,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_OPEN_CONFIRM_DELETE_MODAL](state) {
    return {
      ...state,
      confirmDeleteModalVisible: true,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CLOSE_CONFIRM_DELETE_MODAL](state) {
    return {
      ...state,
      confirmDeleteModalVisible: false,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM](state, { transactionItem }) {
    return {
      ...state,
      transactionItem,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_START](state) {
    return {
      ...state,
      fetchingApprove: true,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetchingApprove: false,
        approveList: state.approveList.concat(data.list),
        approveListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetchingApprove: false,
      approveList: data.list || [],
      approveListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_ERROR](state) {
    return {
      ...state,
      fetchingApprove: false,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_TOGGLE_APPROVE](state, { key }) {
    const selectedApprove = { ...state.selectedApprove };

    selectedApprove[key] = !selectedApprove[key];

    return {
      ...state,
      selectedApprove,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_TOGGLE_ALL_APPROVE](state) {
    const checkedAll =
      _.filter(state.selectedApprove, (item) => item).length || state.approveList.length;

    const selectedApprove = {};

    if (!checkedAll) {
      state.approveList.forEach((item) => (selectedApprove[item.key] = true));
    }

    return {
      ...state,
      selectedApprove,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_INIT_APPROVE](state) {
    return {
      ...state,
      approveList: [],
      approveListTotalCount: 0,
      selectedApprove: {},
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CHANGE_DATE_START](state) {
    return {
      ...state,
      changeCompletedDateError: false,
      changeCompletedDateFetching: true,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CHANGE_DATE_ERROR](state) {
    return {
      ...state,
      changeCompletedDateError: true,
      changeCompletedDateFetching: false,
    };
  },
  [ActionTypes.CHART_SIDE_TRANSACTION_CHANGE_DATE_SUCCESS](state, { data: { completedDate } }) {
    return {
      ...state,
      changeCompletedDateFetching: false,
      transactionItem: {
        ...state.transactionItem,
        completedDate,
      },
    };
  },
};

export default createReducer(defaultState, reducers);
