import React from "react";
import PropTypes from "prop-types";

ChangeToCash.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export function ChangeToCash({ value, onChange }) {
  return (
    <div role="button" className="change-to-cash-wrapper" onClick={(e) => e.stopPropagation()}>
      <input checked={value} value={value} type="checkbox" onChange={() => onChange(!value)} />
    </div>
  );
}
