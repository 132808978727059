import React, { useState } from "react";
import { useHistory } from "react-router";
import { ChromePicker } from "react-color";
import { useRouteMatch } from "react-router-dom";

import Back from "../widgets/Back";
import Colors from "../../helpers/Colors";
import { defaultColors, other } from "../../constants/defaults";

export default function AppointmentStatusColor() {
  const history = useHistory();
  const match = useRouteMatch();

  const [background, setBackground] = useState(defaultColors.Green.color);

  const activeStatus = Colors.getStatusColor(match.params.colorCode);

  const save = () => {
    let item = JSON.parse(JSON.stringify(other));
    item.color = background;

    Colors.selectAppointmentStatus(match.params.colorCode, item);
    history.goBack();
  };

  const handleChangeComplete = (color) => {
    setBackground(color.hex);
  };

  return (
    <div className="settings-body">
      <div className="title">
        <Back />
        <button onClick={save} className="btn btn-link btn-color-right" type="button">
          Save
        </button>
        <div className="name">{activeStatus.name}</div>
      </div>
      <div className="body">
        <ChromePicker color={background} onChangeComplete={handleChangeComplete} />
      </div>
    </div>
  );
}
