import React from "react";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";
import DotImage from "../../../assets/images/dot.png";

const enhancer = connect(null, bindActions({ chartActions }));

class RepeatAppointmentRepeatModalSelect extends React.PureComponent {
  render() {
    const { selected, label, mode } = this.props;

    let typeClassName = "repeat-appointment-follow-type-select";

    typeClassName += (selected && " follow-type-select") || "";

    return (
      <div
        className="repeat-appointment-follow-type-select-block"
        onClick={() => {
          this.props.chartActions.repeatAppointmentSetRepeatModalMode(mode);
        }}
      >
        <div className={typeClassName}>
          {selected && <img src={DotImage} className="img-responsive" alt="" />}
        </div>
        <label className="repeat-modal-label">{label}</label>
      </div>
    );
  }
}

export default enhancer(RepeatAppointmentRepeatModalSelect);
