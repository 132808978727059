import { Panel } from "rc-collapse";
import React, { useState } from "react";
import { useFormikContext } from "formik";

import Show from "../../../widgets/Show";
import ChartPrescriptionEditForm from "./ChartPrescriptionEditForm";

interface Props {
  readonly formData: any;
  readonly medicationLists: Record<string, any>;
  readonly onDeleteClick: (index: number) => void;
  readonly onChangeDrugCategory: (id: number) => void;
  readonly resetForm: () => void;
  readonly setFieldValue: (nodes: string, p: any[] | undefined) => void;
}

export function ChartPrescriptionsArrayComponent({
  formData,
  onDeleteClick,
  medicationLists = [],
  onChangeDrugCategory,
  resetForm,
  setFieldValue,
}: Props) {
  const formikContext = useFormikContext<{ prescriptions: any[] }>();

  const [active, setActive] = useState<number>(-1);

  return (
    <>
      {formikContext.values.prescriptions.map((x, idx) => {
        const medicationList = x.drugCategory?.value ? medicationLists[x.drugCategory?.value] : [];

        return (
          <Panel
            key={idx}
            prefixCls="rc-collapse"
            isActive={active === idx}
            onItemClick={() => setActive((a) => (a >= 0 && a === idx ? -1 : idx))}
            header={
              <div className="panel-header pl-2 flex-grow-1 flex-shrink-1 d-flex align-items-center">
                <Show if={formikContext.values.prescriptions.length > 1}>
                  <div className="panel-header-title flex-shrink-1 flex-grow-1">{x.name}</div>
                  <button
                    type="button"
                    className="btn btn-primary pull-right"
                    onClick={() => onDeleteClick(idx)}
                  >
                    delete
                  </button>
                </Show>
              </div>
            }
          >
            <ChartPrescriptionEditForm
              index={idx}
              formData={formData}
              medicationList={medicationList}
              onChangeDrugCategory={onChangeDrugCategory}
              onChangeDosageForm={(value) =>
                formikContext.setFieldValue(`prescriptions.${idx}.dosageForm`, value)
              }
              // @ts-ignore
              setFieldValue={formikContext.setFieldValue}
              values={formikContext.values.prescriptions}
            />
          </Panel>
        );
      })}
    </>
  );
}
