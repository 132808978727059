import React, { useEffect } from "react";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  getMedicalFillInFormDiagnosisList,
  updateDiagnosisClaimType,
  updateDiagnosisYearOfOnset,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import MedicalFormProvisionalDiagnosisList from "./MedicalFormProvisionalDiagnosisList";

import {
  getDiagnosisTypeList,
  getAppointmentProvisionalDiagnosis,
} from "../../../../actions/transactionActions";
import { clearStack } from "../../../../actions/orderActions";

const MedicalFillInFormProvisionalDiagnosis = ({
  chart,
  clinicId,
  diagnosisList,
  fetching,
  permissions,
  diagnosisClaimTypeList,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppointmentProvisionalDiagnosis(true));
    dispatch(getDiagnosisTypeList());
  }, [chart.sideBarVisible]);

  useEffect(() => {
    return () => {
      dispatch(clearStack());
    };
  }, []);

  const todayDiagnosis = (diagnosisList?.list || []).filter((item) =>
    moment().isSame(item.createdDate, "day"),
  );
  const oldDiagnosis = (diagnosisList?.list || []).filter(
    (item) => !moment().isSame(item.createdDate, "day"),
  );

  return (
    <div className="medical-fill-in-form-treatment">
      <MedicalFormProvisionalDiagnosisList
        updateDiagnosisClaimType={(data) =>
          dispatch(updateDiagnosisClaimType(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        updateDiagnosisYearOfOnset={(data) =>
          dispatch(updateDiagnosisYearOfOnset(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        diagnosisClaimTypeList={diagnosisClaimTypeList}
        clinicId={clinicId}
        fetching={fetching}
        permissions={permissions}
        diagnosisList={todayDiagnosis}
      />
      <MedicalFormProvisionalDiagnosisList
        updateDiagnosisClaimType={(data) =>
          dispatch(updateDiagnosisClaimType(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        updateDiagnosisYearOfOnset={(data) =>
          dispatch(updateDiagnosisYearOfOnset(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        diagnosisClaimTypeList={diagnosisClaimTypeList}
        clinicId={clinicId}
        fetching={fetching}
        permissions={permissions}
        diagnosisList={oldDiagnosis}
      />
    </div>
  );
};

export default MedicalFillInFormProvisionalDiagnosis;
