import axios from "axios";
import { assign, defaults, get, isFinite, isString, toPlainObject } from "lodash";

import { store } from "../index";
import { updateToken } from "../actions/authActions";
import { DefaultData, GET_TOKEN_URL } from "../api-legacy/MainApi";

export const defaultHeaders = {
  api_version: "1.0",
  Accept: "application/json",
};

export class DentalApi {
  refreshToken(refresh_token) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          baseURL: localStorage.getItem("host"),
          url: GET_TOKEN_URL,
          params: assign(
            {},
            {
              refresh_token,
              grant_type: "refresh_token",
              client_id: DefaultData.client_id,
            },
          ),
          method: "POST",
          headers: assign({}, defaultHeaders, {
            "content-type": "application/x-www-form-urlencoded",
          }),
        })
        .then(({ data }) => {
          store.dispatch(
            updateToken({
              ...data,
              expires_in: Date.now() + data.expires_in * 1000,
            }),
          );

          resolve({ data });
        })
        .catch((error) => reject(error));
    });
  }

  async checkToken() {
    const state = store.getState();

    const { access_token, refresh_token, expires_in } = get(state, "auth.token", {});

    if (expires_in * 1000 < Date.now() && refresh_token) {
      const { data } = await this.refreshToken(refresh_token);

      return data.access_token;
    }

    return Boolean(access_token) && access_token !== "null" ? access_token : undefined;
  }

  get(url, options) {
    return this.request(assign({ url, method: "GET" }, options));
  }

  post(url, options) {
    return this.request(assign({ url, method: "POST" }, options));
  }

  put(url, options) {
    return this.request(assign({ url, method: "PUT" }, options));
  }

  delete(url, options) {
    return this.request(assign({ url, method: "DELETE" }, options));
  }

  async request(options) {
    const request = defaults(options, {
      token: await this.checkToken(),
      /* eslint-disable-next-line */
      baseURL: localStorage.getItem("host"),
    });

    const headers = assign({}, defaultHeaders, toPlainObject(request.headers));

    const config = {
      headers,

      method: request.method,

      timeout: 300000,
      url: request.url,
      baseURL: request.baseURL,

      data: request.data,
      params: request.params,
    };

    if (Boolean(request.token) && !isString(config.headers.Authorization)) {
      config.headers.Authorization = `Bearer ${request.token}`;
    }

    if (isFinite(request.timeout)) {
      config.timeout = request.timeout;
    } else if (request.method === "GET") {
      config.timeout = 10 * 60 * 1000;
    }

    return axios.request(config).catch((error) => {
      const errorType = get(error, "response.data.error");
      const state = store.getState();

      const { refresh_token } = get(state, "auth.token", {});

      if (errorType === "invalid_token") {
        this.refreshToken(refresh_token).then(({ data }) => {
          const token = get(data, "access_token");

          if (token) {
            this.request({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
              },
            });
          }
        });

        throw new Error("invalid_token");
      }
      if (errorType === "unauthorized") {
        throw new Error(get(error, "response.data.error_description") || "Wrong password");
      }

      throw new Error(error);
    });
  }
}
