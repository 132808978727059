import { startLoader, stopLoader } from "./loaderActions";

import * as ActionType from "../actionTypes/whatsAppChattingTypes";
import WhatsAppChattingApi from "../api-legacy/WhatsappChatting";

export function initPatientsList(params) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(
      getChattingPatientList({
        ...params,
        start: 0,
        limit: 25,
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(stopLoader(error));
      })
  }
}

// export function archiveWhChatting(patientKey) {
//   return (dispatch) => {
//     dispatch(startLoader());

//     dispatch(
//       archiveWhChattingPatient(patientKey),
//     )
//       .then(() => dispatch(stopLoader()))
//       .catch((error) => {
//         dispatch(startLoader(error));
//       })
//   }
// }

export function archiveWhChatting(patientKey) {
  return (dispatch) => {
    const data = patientKey;

    const fn = {
      api: WhatsAppChattingApi.archivePatientChatting,
      types: [
        ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_START,
        ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_SUCCESS,
        ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_ERROR,
      ],
      data,
    }

    return dispatch(fn)
      .then(() => dispatch(initPatientsList()))
      .catch((error) => {
        dispatch(stopLoader(error));
      })
  };
}

export function getPatientsListWhChats(params) {
  return (dispatch) => {
    const data = params || {};

    const fn = {
      api: WhatsAppChattingApi.getList,
      types: [
        ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_START,
        ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_SUCCESS,
        ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_ERROR,
      ],
      data,
    }

    return dispatch(fn)
  };
}

export function setChattingPatientsList(patientsList) {
  return {
    type: ActionType.WHATSAPP_CHATTING_SET_PATIENT_LIST,
    list: patientsList,
  };
}

export function openWhatsAppChat(patient) {
  return {
    type: ActionType.WHATSAPP_CHATTING_PATIENT_LIST_SHOW_SIDEBAR,
    patient,
  }
}

export function hideWhatsAppChat() {
  return {
    type: ActionType.WHATSAPP_CHATTING_PATIENT_LIST_HIDE_SIDEBAR,
  }
}

function getChattingPatientList(data) {
  return {
    api: WhatsAppChattingApi.getList,
    types: [
      ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_START,
      ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_SUCCESS,
      ActionType.WHATSAPP_CHATTING_PATIENT_LIST_GET_ERROR,
    ],
    data,
  };
}


function archiveWhChattingPatient(data) {
  return {
    api: WhatsAppChattingApi.archivePatientChatting,
    types: [
      ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_START,
      ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_SUCCESS,
      ActionType.WHATSAPP_CHATTING_PATIENT_ARCHIVE_ERROR,
    ],
    data,
  };
}
