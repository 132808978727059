import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { clearStack, orderTabs, setActiveTab } from "../../actions/orderActions";
import ChartOrderHeader from "./ChartOrderHeader";
import Show from "../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { last } from "lodash";
import { orderStackCodes } from "../../constants/chart-sidebar/orderStackCodes";
import LabOrderList from "./lab-order/LabOrderList";
import LabOrderCreate from "./lab-order/LabOrderCreate";
import RadiologyOrderList from "./radiology-order/RadiologyOrderList";
import RadiologyOrderCreate from "./radiology-order/RadiologyOrderCreate";
import orderItemTypes from "../../constants/chart-sidebar/orderItemTypes";

const enhancer = connect(({ chartSideOrder }) => ({ chartSideOrder }));
const ChartOrder = ({ chartSideOrder, medicalForms }) => {
  useEffect(() => {
    dispatch(setActiveTab(orderTabs[0]));
    return () => {
      dispatch(clearStack());
      // dispatch(setActiveTab(orderTabs[0]));
    };
  }, []);
  const dispatch = useDispatch();
  const { activeTab, labOrderFetching, radiologyOrderFetching, stack } = chartSideOrder;
  const stackItem = last(stack);
  let $body = null;
  if (activeTab.code === "LAB_ORDER") {
    if (stackItem.code === orderStackCodes.list) {
      $body = <LabOrderList chartSideOrder={chartSideOrder} />;
    } else if (stackItem.code === orderStackCodes.item) {
      $body = (
        <LabOrderCreate
          editMode={stackItem?.type === orderItemTypes.edit}
          chartSideOrder={chartSideOrder}
        />
      );
    }
  }
  if (activeTab.code === "RADIOLOGY_ORDER") {
    if (stackItem.code === orderStackCodes.list) {
      $body = <RadiologyOrderList chartSideOrder={chartSideOrder} />;
    } else if (stackItem.code === orderStackCodes.item) {
      $body = (
        <RadiologyOrderCreate
          editMode={stackItem?.type === orderItemTypes.edit}
          chartSideOrder={chartSideOrder}
        />
      );
    }
  }
  return (
    <div className="order-modal" style={{ margin: medicalForms ? "0 -1rem" : 0 }}>
      <ChartOrderHeader activeTab={activeTab} medicalForms={medicalForms} />
      <div className="order-modal__body">
        <Show if={labOrderFetching || radiologyOrderFetching}>
          <ReactSpinner />
        </Show>
        {$body}
      </div>
    </div>
  );
};

export default enhancer(ChartOrder);
