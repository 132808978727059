import { openSimpleModal } from "../actions/simpleModalActions";
import { Toolkit } from "../eidatoolkit";
import { IS_DEV } from "../constants/Constants";

let IsNfc = false;
let readerClass = null;
let ToolkitOB = null;
let javaService = "";
let thisDispatch = null;
let IsSam = {
  sam_secure_messaging: false,
};
let options = {
  jnlp_address: javaService + "ToolkitWebSample/Dok32-EMI.jnlp",
  debugEnabled: true,
  agent_tls_enabled: false,
  toolkitConfig: "",
};
options.toolkitConfig += "vg_url = http://vg-pre-prod.ica.gov.ae/ValidationGatewayService \n";
options.toolkitConfig += "log_level = 'INFO' \n";
options.toolkitConfig += "read_publicdata_offline = true \n";

const onOpenHandlerCB = (response, error) => {
  // hideLoader();
  if (error === null) {
    /**
     * call the list reader function and pass listReaderCB to be executed
     * after the response is received from server
     */
    if (IsSam.sam_secure_messaging) {
      ToolkitOB.getReaderWithEmiratesId(listReaderCB);
    } else {
      ToolkitOB.listReaders(listReaderCB);
    }
  } else {
    ToolkitOB = null;
  }
};

const closeHandlerCB = () => {
  ToolkitOB = null;
  readerClass = null;
};

const clearToolkitOB = () => {
  ToolkitOB = null;
};

const listReaderCB = (response, error) => {
  if (error !== null) {
    thisDispatch(
      openSimpleModal({
        body: error.message || error.description,
      }),
    );
    // alert(error.message || error.description);
    ToolkitOB = null;
  } else {
    // eslint-disable-next-line no-unused-vars
    let readerList = response;
    if (IsSam.sam_secure_messaging) {
      readerClass = readerList;
    } else {
      if (readerList && 0 < readerList.length) {
        readerClass = readerList[0];
      } else {
        return "No readers found";
      }
    }
    // displayProgress('Connecting to reader ...');
    /**
     * call the connect reader function and pass connectReaderCB to be executed
     * after the response is received from server
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let ret = readerClass.connect(connectReaderCB);
    // if ('' !== ret) {
    //   /* disable all buttons till request is processed */
    //   changeButtonState(true);
    // }
  }
};

const connectReaderCB = (response, error) => {
  if (null !== error) {
    // thisChangeBtnText("Refresh page");
    thisDispatch(
      openSimpleModal({
        body: `${error.code} : ${error.message}. click on refresh page button again`,
      }),
    );
    // alert(`${error.code} : ${error.message} then click on refresh page button`);
    ToolkitOB = null;
    return;
  }
  readerClass.getInterfaceType(getInterfaceCB);
};

const getInterfaceCB = (response, error) => {
  if (null !== error) {
    thisDispatch(
      openSimpleModal({
        body: error.code + " : " + error.message,
      }),
    );
    // alert(error.code + " : " + error.message);
    ToolkitOB = null;
    return;
  }
  IsNfc = response === 2;
};

const errorHandlerCB = (err) => {
  readerClass = null;
  ToolkitOB = null;
  if (null !== err) {
    // hideLoader();
    thisDispatch(
      openSimpleModal({
        body: "errorHandler ERROR : " + err,
      }),
    );
    // alert("errorHandler ERROR : " + err);
  }
};

const Initialize = (loaderCallback, changeBtnText, setReaderState) => {
  try {
    if (ToolkitOB !== null) {
      loaderCallback(false);
      setReaderState(false);
      return "WebSocket is already active...";
    }
    /*  if
     provide the call backs */
    ToolkitOB = new Toolkit(
      onOpenHandlerCB /* reference to onOpen call back function */,
      closeHandlerCB /* reference to onClose call back function */,
      errorHandlerCB /* reference to onError call back function */,
      {
        ...options,
        debugEnabled: IS_DEV,
        loaderCallback,
        changeBtnText,
        setReaderState,
        thisDispatch,
        clearToolkitOB,
      } /* options */,
    );
    // loaderCallback(false);
    // changeBtnText("Read public data");
  } catch (e) {
    loaderCallback(false);
    thisDispatch(
      openSimpleModal({
        body: "Webcomponent Initialization Failed, Details: " + e,
      }),
    );
    // alert("Webcomponent Initialization Failed, Details: " + e);
  }
};

const generateRandomString = (length) => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const DisplayPublicData = (
  publicDataCallback,
  loaderCallback,
  changeBtnText,
  dispatch,
  cardRead,
  setReaderState,
) => {
  thisDispatch = dispatch;
  if (cardRead) {
    readerClass = null;
    ToolkitOB = null;
  }
  if (null === readerClass || undefined === readerClass) {
    // eslint-disable-next-line no-console
    console.log("ERROR : Reader is not initiaized.");
    loaderCallback(true);
    Initialize(loaderCallback, changeBtnText, setReaderState);
    return;
  }
  /*  disable all buttons till request is processed */
  const randomStr = generateRandomString(40);
  const requestId = btoa(randomStr);
  let address = true;
  if (IsNfc) {
    address = false;
  }

  readerClass.readPublicData(
    requestId,
    true,
    true,
    true,
    true,
    address,
    publicDataCallback,
    loaderCallback,
  );
};
