export const INIT_INSURANCE_FORM_FIELDS = "INIT_INSURANCE_FORM_FIELDS";
export const INSURANCE_FORM_FIELDS_BY_COMPANY = "INSURANCE_FORM_FIELDS_BY_COMPANY";
export const SET_INSURANCE_LIST = "SET_INSURANCE_LIST";

export const GET_INSURANCE_LIST = "GET_INSURANCE_LIST";
export const GET_INSURANCE_LIST_START = "GET_INSURANCE_LIST_START";
export const GET_INSURANCE_LIST_SUCCESS = "GET_INSURANCE_LIST_SUCCESS";
export const GET_INSURANCE_LIST_ERROR = "GET_INSURANCE_LIST_ERROR";

export const INSURANCE_IMAGE_HIDE_CHOOSE_PHOTO_MODAL = "INSURANCE_IMAGE_HIDE_CHOOSE_PHOTO_MODAL";
export const INSURANCE_IMAGE_SHOW_CHOOSE_PHOTO_MODAL = "INSURANCE_IMAGE_SHOW_CHOOSE_PHOTO_MODAL";
export const INSURANCE_IMAGE_SELECT_IMAGE = "INSURANCE_IMAGE_SELECT_IMAGE";
export const INSURANCE_IMAGE_DELETE_IMAGE = "INSURANCE_IMAGE_DELETE_IMAGE";

export const GET_PATIENT_PAYMENT_OPTIONS = "GET_PATIENT_PAYMENT_OPTIONS";
export const GET_PATIENT_PAYMENT_OPTIONS_START = "GET_PATIENT_PAYMENT_OPTIONS_START";
export const GET_PATIENT_PAYMENT_OPTIONS_SUCCESS = "GET_PATIENT_PAYMENT_OPTIONS_SUCCESS";
export const GET_PATIENT_PAYMENT_OPTIONS_ERROR = "GET_PATIENT_PAYMENT_OPTIONS_ERROR";

export const GET_INSURANCE_ITEM = "GET_INSURANCE_ITEM";
export const GET_INSURANCE_ITEM_START = "GET_INSURANCE_ITEM_START";
export const GET_INSURANCE_ITEM_SUCCESS = "GET_INSURANCE_ITEM_SUCCESS";
export const GET_INSURANCE_ITEM_ERROR = "GET_INSURANCE_ITEM_ERROR";

export const SAVE_INSURANCE_ITEM = "SAVE_INSURANCE_ITEM";
export const SAVE_INSURANCE_ITEM_START = "SAVE_INSURANCE_ITEM_START";
export const SAVE_INSURANCE_ITEM_SUCCESS = "SAVE_INSURANCE_ITEM_SUCCESS";
export const SAVE_INSURANCE_ITEM_ERROR = "SAVE_INSURANCE_ITEM_ERROR";

export const DELETE_INSURANCE_ITEM = "DELETE_INSURANCE_ITEM";
export const DELETE_INSURANCE_ITEM_START = "DELETE_INSURANCE_ITEM_START";
export const DELETE_INSURANCE_ITEM_SUCCESS = "DELETE_INSURANCE_ITEM_SUCCESS";
export const DELETE_INSURANCE_ITEM_ERROR = "DELETE_INSURANCE_ITEM_ERROR";

export const GET_INSURANCE_COMPANY = "GET_INSURANCE_COMPANY";
export const GET_INSURANCE_COMPANY_START = "GET_INSURANCE_COMPANY_START";
export const GET_INSURANCE_COMPANY_SUCCESS = "GET_INSURANCE_COMPANY_SUCCESS";
export const GET_INSURANCE_COMPANY_ERROR = "GET_INSURANCE_COMPANY_ERROR";

export const GET_INSURANCE_COMPANY_PLAN = "GET_INSURANCE_COMPANY_PLAN";
export const GET_INSURANCE_COMPANY_PLAN_START = "GET_INSURANCE_COMPANY_PLAN_START";
export const GET_INSURANCE_COMPANY_PLAN_SUCCESS = "GET_INSURANCE_COMPANY_PLAN_SUCCESS";
export const GET_INSURANCE_COMPANY_PLAN_ERROR = "GET_INSURANCE_COMPANY_PLAN_ERROR";

export const GET_INSURANCE_RELATION_START = "GET_INSURANCE_RELATION_START";
export const GET_INSURANCE_RELATION_SUCCESS = "GET_INSURANCE_RELATION_SUCCESS";
export const GET_INSURANCE_RELATION_ERROR = "GET_INSURANCE_RELATION_ERROR";

export const GET_INSURANCE_BROKER_START = "GET_INSURANCE_BROKER_START";
export const GET_INSURANCE_BROKER_SUCCESS = "GET_INSURANCE_BROKER_SUCCESS";
export const GET_INSURANCE_BROKER_ERROR = "GET_INSURANCE_BROKER_ERROR";

export const SEARCH_BY_CARD_NUMBER = "SEARCH_BY_CARD_NUMBER";
export const SEARCH_BY_CARD_NUMBER_START = "SEARCH_BY_CARD_NUMBER_START";
export const SEARCH_BY_CARD_NUMBER_SUCCESS = "SEARCH_BY_CARD_NUMBER_SUCCESS";
export const SEARCH_BY_CARD_NUMBER_ERROR = "SEARCH_BY_CARD_NUMBER_ERROR";

export const MARK_AS_PRIMARY_INSURANCE = "MARK_AS_PRIMARY_INSURANCE";
export const MARK_AS_PRIMARY_INSURANCE_START = "MARK_AS_PRIMARY_INSURANCE_START";
export const MARK_AS_PRIMARY_INSURANCE_SUCCESS = "MARK_AS_PRIMARY_INSURANCE_SUCCESS";
export const MARK_AS_PRIMARY_INSURANCE_ERROR = "MARK_AS_PRIMARY_INSURANCE_ERROR";

export const INSURANCE_UPLOAD_PHOTO_START = "INSURANCE_UPLOAD_PHOTO_START";
export const INSURANCE_UPLOAD_PHOTO_SUCCESS = "INSURANCE_UPLOAD_PHOTO_SUCCESS";
export const INSURANCE_UPLOAD_PHOTO_ERROR = "INSURANCE_UPLOAD_PHOTO_ERROR";

export const INSURANCE_PHOTO_SIDEBAR_SHOW = "INSURANCE_PHOTO_SIDEBAR_SHOW";
export const INSURANCE_PHOTO_SIDEBAR_HIDE = "INSURANCE_PHOTO_SIDEBAR_HIDE";

export const INSURANCE_PHOTO_SIDEBAR_SET_IMAGE = "INSURANCE_PHOTO_SIDEBAR_SET_IMAGE";

export const INSURANCE_GET_SELF_PAYER_START = "INSURANCE_GET_SELF_PAYER_START";
export const INSURANCE_GET_SELF_PAYER_SUCCESS = "INSURANCE_GET_SELF_PAYER_SUCCESS";
export const INSURANCE_GET_SELF_PAYER_ERROR = "INSURANCE_GET_SELF_PAYER_ERROR";

export const INSURANCE_GET_DEDUCTIBLE_TYPES_START = "INSURANCE_GET_DEDUCTIBLE_TYPES_START";
export const INSURANCE_GET_DEDUCTIBLE_TYPES_SUCCESS = "INSURANCE_GET_DEDUCTIBLE_TYPES_SUCCESS";
export const INSURANCE_GET_DEDUCTIBLE_TYPES_ERROR = "INSURANCE_GET_DEDUCTIBLE_TYPES_ERROR";

export const INSURANCE_GET_ITEM_START = "INSURANCE_GET_ITEM_START";
export const INSURANCE_GET_ITEM_SUCCESS = "INSURANCE_GET_ITEM_SUCCESS";
export const INSURANCE_GET_ITEM_ERROR = "INSURANCE_GET_ITEM_ERROR";

export const INSURANCE_SAVE_ITEM_START = "INSURANCE_SAVE_ITEM_START";
export const INSURANCE_SAVE_ITEM_SUCCESS = "INSURANCE_SAVE_ITEM_SUCCESS";
export const INSURANCE_SAVE_ITEM_ERROR = "INSURANCE_SAVE_ITEM_ERROR";

export const INSURANCE_SWITCH_ACTIVATE_START = "INSURANCE_SWITCH_ACTIVATE_START";
export const INSURANCE_SWITCH_ACTIVATE_SUCCESS = "INSURANCE_SWITCH_ACTIVATE_SUCCESS";
export const INSURANCE_SWITCH_ACTIVATE_ERROR = "INSURANCE_SWITCH_ACTIVATE_ERROR";

export const INSURANCE_SHOW_EDIT_DIALOG = "INSURANCE_SHOW_EDIT_DIALOG";
export const INSURANCE_HIDE_EDIT_DIALOG = "INSURANCE_HIDE_EDIT_DIALOG";

export const GET_INSURANCE_ALIAS_LIST_START = "GET_INSURANCE_ALIAS_LIST_START";
export const GET_INSURANCE_ALIAS_LIST_SUCCESS = "GET_INSURANCE_ALIAS_LIST_SUCCESS";
export const GET_INSURANCE_ALIAS_LIST_ERROR = "GET_INSURANCE_ALIAS_LIST_ERROR";

export const GET_RE_IMBURSEMENT_LIST_START = "GET_RE_IMBURSEMENT_LIST_START";
export const GET_RE_IMBURSEMENT_LIST_SUCCESS = "GET_RE_IMBURSEMENT_LIST_SUCCESS";
export const GET_RE_IMBURSEMENT_LIST_ERROR = "GET_RE_IMBURSEMENT_LIST_ERROR";
