import React, { useEffect } from "react";

import { formatPhoneToObject } from "../../utils/FormatUtils";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { useEligibilityMutation } from "../../queries/eligibility/useEligibilityMutation";
import { useEligibilityDetailsQuery } from "../../queries/eligibility/useEligibilityDetailsQuery";
import { EligibilityDetails, EligibilityDetailsProps } from "./EligibilityDetails";
import {
  appointmentEligibilityDetailsSelector,
  appointmentEligibilityIdSelector,
  appointmentEligibilityPendingSelector,
} from "../../reducers/appointmentReducer";
import { useDispatch } from "react-redux";
import {
  changeAppointmentEligibilityPending,
  getEligibilityData,
  setAppointmentEligibilityDetails,
  setAppointmentEligibilityId,
  updateAppointmentEligibilityIdPayer,
} from "../../actions/appointmentActions";

import _ from "lodash";

interface Props
  extends Omit<
    EligibilityDetailsProps,
    "loading" | "eligibility" | "show" | "isPending" | "onSubmit"
  > {
  readonly appointment: any;
  readonly show: boolean;
  readonly onHide: () => void;
  readonly patient: PatientProps;
  readonly insuranceList: Insurance.Props[];
  readonly setEligibilityStatus: (status: { eligible: boolean | null; id: number }) => void;
}

export function EligibilityDetailsModalWrapper({
  patient,
  show,
  onHide,
  appointment,
  insuranceList,
  setEligibilityStatus,
  ...props
}: Props) {
  const dispatch = useDispatch();

  // const eligibilityId = useShallowEqualSelector(
  //   appointmentEligibilityIdSelector(appointment?.id) as any,
  // );
  // const appointmentId = appointment?.id;

  const eligibilityDetails = useShallowEqualSelector(
    appointmentEligibilityDetailsSelector(appointment?.id) as any,
  );
  const eligibilityPending = useShallowEqualSelector(
    appointmentEligibilityPendingSelector(appointment?.id) as any,
  );

  // const eligibilityDetailsSuccessHandler = (data) => {
  //   if (data.data) {
  //     dispatch(setAppointmentEligibilityDetails(appointment?.id, data.data));
  //
  //     dispatch(changeAppointmentEligibilityPending(appointment?.id, false));
  //   } else {
  //     const error = _.isArray(data) ? data.join(" \n") : "";
  //     if (error && error !== "No record found") {
  //       dispatch(setAppointmentEligibilityDetails(appointment?.id, { eligibilityDetails: error }));
  //
  //       dispatch(changeAppointmentEligibilityPending(appointment?.id, false));
  //     }
  //   }
  // };

  // const eligibilityMutation = useEligibilityMutation();

  // useEligibilityDetailsQuery(
  //   eligibilityId,
  //   appointmentId,
  //   eligibilityPending,
  //   eligibilityDetailsSuccessHandler,
  // );
  // useEffect(() => {
  //   //kochirish kerak
  //   if (eligibilityDetails?.eligibilityCheck) {
  //     setEligibilityStatus({
  //       eligible: eligibilityDetails.eligibilityCheck?.result,
  //       id: appointment.id,
  //     });
  //     dispatch(
  //       updateAppointmentEligibilityIdPayer({
  //         id: appointment?.id,
  //         eligibilityIdPayer:
  //           eligibilityDetails.eligibilityCheck.eligibilityCheckAnswer?.eligibilityCheckAnswerId,
  //       }),
  //     );
  //   }
  // }, [eligibilityDetails]);

  const submitHandler = (values: EligibilityPreFormValuesProps) => {
    return dispatch(getEligibilityData(values));
  };

  return (
    <EligibilityDetails
      {...props}
      show={show}
      onHide={onHide}
      onSubmit={submitHandler}
      isPending={eligibilityPending}
      eligibility={eligibilityDetails}
    />
  );
}
