import _ from "lodash";
import React from "react";

import ChartDocsView from "./ChartDocsView";
import ChartClinicalNote from "./clinical-note/ChartClinicalNote";
import ChartFillInForm from "./fill-in-from/ChartFillInForm";
import ChartVital from "./vital/ChartVital";
import ChartPrescription from "./prescription/ChartPrescription";
import ChartPlan from "./plan/ChartPlan";
import ChartGoals from "../general/ChartGoals";
import ChartStickyNote from "../general/ChartStickyNote";
import ChartPostOp from "./postOp/ChartPostOp";
import ChartReferralNextVisit from "./referral-next-visit/ChartReferralNextVisit";
import PatientTags from "../patient/PatientTags";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";
import clinicNoteItemTypes from "../../../constants/chart-sidebar/clinicNoteItemTypes";
import { ClinicalNoteStackCodes } from "../../../constants/chart-sidebar/clinicalNoteStackCodes";
import { Sidebar } from "../../sidebar/Sidebar";
import { prescriptionStackCodes } from "../../../constants/chart-sidebar/prescriptionStackCodes";
import { planStackCodes } from "../../../constants/chart-sidebar/planStackCodes";
import { postOpStackCodes } from "../../../constants/chart-sidebar/postOpStackCodes";
import { fillInFormStackCodes } from "../../../constants/chart-sidebar/fillInFormStackCodes";
import { useDispatch } from "react-redux";
import { setFillInFormAddendumId } from "../../../actions/chartSideFillInFormActions";
import { vitalStackCodes } from "../../../constants/chart-sidebar/vitalStackCodes";
import ChartOrder from "../../order/ChartOrder";

export default function ChartSideBar(props) {
  const {
    chartSideSeries,
    chartSideClinicalNote,
    chartSidePrescription,
    chartSidePlan,
    chartSideFillInForm,
    chartSideVital,
    chart,
    sideBarVisible,
    hideSideBar,
    viewType,
    toothNumericType,
    teeth,
    member,
    onAppointment,
    onCallList,
    chartSideDocs,
    chartGeneral,
    chartSidePostOp,
    permissions,
    points,
    doctorDropdownVisible,
    clinicalNotesSideBarViewType,
    clinic,
    location,
    history,
    patientKey,
    position,
  } = props;
  const dispatch = useDispatch();
  let $body = null;
  let className = "chart-side-bar";
  switch (viewType) {
    case viewTypes.clinicalNote:
      $body = (
        <ChartClinicalNote
          chartSideSeries={chartSideSeries}
          chart={chart}
          points={points}
          doctorDropdownVisible={doctorDropdownVisible}
          onAppointment={onAppointment}
          onCallList={onCallList}
          teeth={teeth}
          member={member}
          chartSideClinicalNote={chartSideClinicalNote}
          toothNumericType={toothNumericType}
          permissions={permissions}
          clinic={clinic}
          viewType={viewType}
          // stackItem={_.last(chartSideClinicalNote.stack)}
        />
      );

      const { stack } = chartSideClinicalNote;
      const stackItem = _.last(stack);

      if (clinicalNotesSideBarViewType && clinicalNotesSideBarViewType.code === "HORIZONTAL") {
        if (stackItem.code === ClinicalNoteStackCodes.textarea) {
          if (
            stackItem?.type === clinicNoteItemTypes.edit ||
            stackItem?.type === clinicNoteItemTypes.new
          ) {
            if (chartSideClinicalNote.fullScreen) {
              className += " full-screen";
            } else {
              className += " bottom-bar";
            }
          }
        }
      }
      break;
    case viewTypes.prescription:
      $body = <ChartPrescription chartSidePrescription={chartSidePrescription} />;
      break;
    case viewTypes.treatmentsPlan:
      $body = <ChartPlan chartSidePlan={chartSidePlan} />;
      break;
    case viewTypes.docsView:
      $body = <ChartDocsView onAppointment={onAppointment} chartSideDocs={chartSideDocs} />;
      break;
    case viewTypes.fillInForm:
      $body = (
        <ChartFillInForm
          member={member}
          location={location}
          chartSideFillInForm={chartSideFillInForm}
          clinic={clinic}
          history={history}
          patientKey={patientKey}
        />
      );
      break;
    case viewTypes.vital:
      $body = (
        <ChartVital
          hideSideBar={hideSideBar}
          fromAppointment={onAppointment}
          member={member}
          chartSideVital={chartSideVital}
          clinic={clinic}
        />
      );
      break;
    case viewTypes.goals:
      $body = <ChartGoals chartGeneral={chartGeneral} />;
      break;
    case viewTypes.sticky:
      $body = <ChartStickyNote chartGeneral={chartGeneral} />;
      break;
    case viewTypes.postOp:
      $body = (
        <ChartPostOp chartSidePostOp={chartSidePostOp} history={history} location={location} />
      );
      break;
    case viewTypes.referralNextVisit:
      $body = <ChartReferralNextVisit />;
      break;
    case viewTypes.order:
      $body = <ChartOrder />;
      break;
    case viewTypes.patientTags:
      $body = <PatientTags />;
      break;
  }

  const prescriptionStack = _.get(chartSidePrescription, "stack");
  const chartSidePlanStack = _.get(chartSidePlan, "stack");
  const chartSidePostOpStack = _.get(chartSidePostOp, "stack");
  const fillInFormStack = _.get(chartSideFillInForm, "stack");
  const clinicalNoteStack = _.get(chartSideClinicalNote, "stack");
  const vitalStack = _.get(chartSideVital, "stack");

  const chartSidePlanStackItem = _.last(chartSidePlanStack);
  const chartSidePostOpStackItem = _.last(chartSidePostOpStack);
  const prescriptionStackItem = _.last(prescriptionStack);
  const fillInFormStackItem = _.last(fillInFormStack);
  const clinicalNoteStackItem = _.last(clinicalNoteStack);
  const vitalStackItem = _.last(vitalStack);

  const prescriptionStackItemCode = _.get(prescriptionStackItem, "code");
  const chartSidePostOpStackItemCode = _.get(chartSidePostOpStackItem, "code");
  const chartSidePlanStackItemCode = _.get(chartSidePlanStackItem, "code");
  const fillInFormStackItemCode = _.get(fillInFormStackItem, "code");
  const clinicalNoteStackItemCode = _.get(clinicalNoteStackItem, "code");
  const vitalStackItemCode = _.get(vitalStackItem, "code");
  const isFullSidebar =
    prescriptionStackItemCode === prescriptionStackCodes.edit ||
    prescriptionStackItemCode === prescriptionStackCodes.view ||
    prescriptionStackItemCode === prescriptionStackCodes.diagnosis ||
    prescriptionStackItemCode === prescriptionStackCodes.name ||
    (viewType === viewTypes.postOp &&
      chartSidePostOpStackItemCode !== postOpStackCodes.list &&
      chartSidePostOpStackItemCode !== postOpStackCodes.owned) ||
    (viewType === viewTypes.treatmentsPlan && chartSidePlanStackItemCode !== planStackCodes.list) ||
    viewType === viewTypes.medicalFillInForm ||
    fillInFormStackItemCode === fillInFormStackCodes.item ||
    clinicalNoteStackItemCode === ClinicalNoteStackCodes.item ||
    vitalStackItemCode === vitalStackCodes.item ||
    clinicalNoteStackItemCode === ClinicalNoteStackCodes.print ||
    viewType === viewTypes.order;
  const isBiggerSidebar = viewType === viewTypes.referralNextVisit;
  const { stack } = chartSideClinicalNote;
  const stackItem = _.last(stack);

  return (
    <Sidebar
      position={position}
      fullScreen={isFullSidebar}
      show={sideBarVisible && viewType !== viewTypes.transaction && viewType !== viewTypes.series}
      backdrop={
        (viewType === viewTypes.clinicalNote && stackItem?.type === clinicNoteItemTypes.edit) ||
        stackItem?.type === clinicNoteItemTypes.new ||
        viewType === viewTypes.medicalFillInForm
          ? "static"
          : true
      }
      keyboard={false}
      shouldCloseOnEsc={false}
      onHide={() => {
        dispatch(setFillInFormAddendumId(0));
        hideSideBar();
      }}
      dialogClassName={`${className} ${isBiggerSidebar ? "bigger-sidebar" : ""}`}
    >
      {$body}
    </Sidebar>
  );
}
