import React from "react";

export default function condition(props) {
  const { property, toothImage } = props;
  if (!property) return null;

  const src = require(`../../../assets/images/conditions/post/post_${toothImage}@2x.png`).default;

  return (
    <div className="condition-wrap tooth-image-condition">
      <img alt="" src={src} className="zoom-2x" />
    </div>
  );
}
