import { useMutation, UseMutationResult } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

export function useCreatePatientFamilyMemberMutation(): UseMutationResult<unknown, Error> {
  return useMutation((data) =>
    httpClient.post(ApiRoutes.patientFamilyMemberItem, { data }).then(formatResponse),
  );
}
