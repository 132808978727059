import React from "react";
import _ from "lodash";
import cx from "classnames";
import { Field } from "redux-form";
import Utils from "../../../helpers/Utils";
// import PatientMedicalAlertSubCategories from "./PatientMedicalAlertSubCategories";
import ReduxMedicalAlertNameSwitch from "../../widgets/redux/ReduxMedicalAlertNameSwitch";

export const PatientMedicalAlertsElement = ({ editMode, index, element, values }) => {
  let switchElement;
  let noteElement;

  if (editMode) {
    switchElement = (
      <Field
        defaultChecked={element.switchState}
        checked={values[`switch${index}`]}
        component={ReduxMedicalAlertNameSwitch}
        name={element.switchName}
        title={index + 1 + ". " + element.title}
      />
    );
    noteElement = (
      <Field component="input" type="text" name={element.noteName} className="form-control" />
    );
  } else {
    switchElement = (
      <SelectedInformer selected={element.switchState} title={index + 1 + ". " + element.title} />
    );
    noteElement = <span>{Utils.getNoteText(element)}</span>;
  }

  return (
    <div
      key={index}
      className="alerts-row mb-2"
      style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
    >
      <div className="medical-alerts-box box-label">
        <div className="medical-alerts-box">{switchElement}</div>
      </div>
      <div className="medical-alerts-box box-description" style={{ overflowX: "unset" }}>
        {noteElement}
      </div>
    </div>
  );
};

export default PatientMedicalAlertsElement;

const SelectedInformer = ({ selected, title }) => {
  return (
    <button
      type="button"
      className={cx("btn btn-sm btn-default", {
        "btn-primary": _.isBoolean(selected) && selected,
      })}
    >
      {title}
    </button>
  );
};
