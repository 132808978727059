import _ from "lodash";

import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/medicationActionTypes";
import { MEDICATION_CLEAR_NEW_LIST } from "../actionTypes/medicationActionTypes";

const defaultState = {
  list: [],
  totalCount: 0,
  drugModalVisible: false,
  drugList: [],
  drugTotalCount: 0,
  checkedDrugMap: {},
  filter: {},
  formData: {},
  newList: [],
  historyModalVisible: false,
  activeHistoryId: 0,
  historyList: [],
  historyTotalCount: 0,
  newHistoryList: [],
  checkedMap: {},
  checkedMapToday: {},
  checkedMapOld: {},
  selectedItems: [],
  selectedTodayItems: [],
  selectedOldItems: [],
  pdfModalVisible: false,
  sendToERX: false,
  hintList: [],
  diagnosisList: [],
  fetchingHintList: false,
};

const reducers = {
  [ActionType.GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_SUCCESS](state, { data }) {
    return {
      ...state,
      diagnosisList: data.list,
    };
  },
  [MEDICATION_CLEAR_NEW_LIST](state) {
    return {
      ...state,
      newList: [],
    };
  },
  [ActionType.MEDICATION_HINTS_START](state) {
    return {
      ...state,
      fetchingHintList: true,
    };
  },
  [ActionType.MEDICATION_HINTS_SUCCESS](state, { data }) {
    return {
      ...state,
      fetchingHintList: false,
      hintList: data || [],
    };
  },
  [ActionType.MEDICATION_HINTS_ERROR](state) {
    return {
      ...state,
      fetchingHintList: false,
    };
  },
  [ActionType.MEDICATION_INIT](state) {
    return {
      ...state,
      newList: [],
    };
  },
  [ActionType.MEDICATION_GET_LIST_SUCCESS](state, { data, request }) {
    if (request && request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        totalCount: data.totalCount,
      };
    }

    return {
      ...state,
      list: data.list || [],
      totalCount: data.totalCount,
    };
  },
  [ActionType.MEDICATION_GET_DRUG_LIST_SUCCESS](state, { data, request }) {
    if (request && request.start) {
      return {
        ...state,
        drugList: state.drugList.concat(data.list),
        drugTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      drugList: data.list || [],
      drugTotalCount: data.totalCount,
    };
  },
  [ActionType.MEDICATION_OPEN_DRUG_MODAL](state) {
    return {
      ...state,
      drugModalVisible: true,
    };
  },
  [ActionType.MEDICATION_CLOSE_DRUG_MODAL](state) {
    return {
      ...state,
      drugModalVisible: false,
    };
  },
  [ActionType.MEDICATION_TOGGLE_DRUG](state, { id, item, isPrescription }) {
    const checkedDrugMap = JSON.parse(JSON.stringify(state.checkedDrugMap));

    // eslint-disable-next-line no-prototype-builtins
    if (checkedDrugMap.hasOwnProperty(id)) {
      delete checkedDrugMap[id];
    } else {
      if (isPrescription) {
        for (const key in checkedDrugMap) {
          delete checkedDrugMap[key];
        }
        checkedDrugMap[id] = item;
      } else checkedDrugMap[id] = item;
    }

    return {
      ...state,
      checkedDrugMap,
    };
  },
  [ActionType.MEDICATION_SUBMIT_DRUG_FORM](state, { filter }) {
    return {
      ...state,
      filter,
    };
  },
  [ActionType.MEDICATION_ADD_DRUGS](state, { onlyClear }) {
    let newList = (state.newList || []).slice();

    if (!onlyClear) {
      const newItems = Object.values(state.checkedDrugMap).map((item) => ({
        medication: item,
      }));
      newList = [...newList, ...newItems];
    }

    return {
      ...state,
      newList,
      checkedDrugMap: {},
    };
  },
  [ActionType.MEDICATION_GET_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      formData: data,
    };
  },
  [ActionType.MEDICATION_OPEN_HISTORY_MODAL](state, { id }) {
    return {
      ...state,
      historyModalVisible: true,
      activeHistoryId: id,
      newHistoryList: [],
    };
  },
  [ActionType.MEDICATION_CLOSE_HISTORY_MODAL](state) {
    return {
      ...state,
      historyModalVisible: false,
      activeHistoryId: 0,
    };
  },
  [ActionType.MEDICATION_HISTORY_GET_LIST_SUCCESS](state, { data, request }) {
    if (request && request.start) {
      return {
        ...state,
        historyList: state.historyList.concat(data.list),
        historyTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      historyList: data.list || [],
      historyTotalCount: data.totalCount,
    };
  },
  [ActionType.MEDICATION_ADD_NEW_HISTORY](state) {
    const newHistoryList = JSON.parse(JSON.stringify(state.newHistoryList));

    newHistoryList.unshift({});

    return {
      ...state,
      newHistoryList,
    };
  },
  [ActionType.MEDICATION_CHECK_ALL](state, { clearImportant }) {
    const checkedMap = {};
    const selectedItems = [...state.selectedItems];

    if (!clearImportant && Object.keys(state.checkedMap).length !== state.list.length) {
      state.list.forEach((item) => {
        selectedItems.push(item.id);
        checkedMap[item.id] = true;
      });
    }

    return {
      ...state,
      checkedMap,
      selectedItems,
    };
  },
  [ActionType.MEDICATION_CHECK_TODAY_ALL](state, { clearImportant, list }) {
    const checkedMapToday = {};
    const selectedTodayItems = [...state.selectedTodayItems];

    if (!clearImportant && Object.keys(state.checkedMapToday).length !== list.length) {
      list.forEach((item) => {
        selectedTodayItems.push(item.id);
        checkedMapToday[item.id] = true;
      });
    }

    return {
      ...state,
      checkedMapToday,
      selectedTodayItems,
    };
  },
  [ActionType.MEDICATION_CHECK_OLD_ALL](state, { clearImportant, list }) {
    const checkedMapOld = {};
    const selectedOldItems = [...state.selectedOldItems];

    if (!clearImportant && Object.keys(state.checkedMapOld).length !== list.length) {
      list.forEach((item) => {
        selectedOldItems.push(item.id);
        checkedMapOld[item.id] = true;
      });
    }
    return {
      ...state,
      checkedMapOld,
      selectedOldItems,
    };
  },
  [ActionType.MEDICATION_CHECK_OLD_ITEM](state, { id }) {
    const checkedMapOld = JSON.parse(JSON.stringify(state.checkedMapOld));
    const selectedOldItems = [...state.selectedOldItems];

    if (checkedMapOld[id]) {
      delete checkedMapOld[id];
      _.remove(selectedOldItems, (x) => x === id);
    } else {
      checkedMapOld[id] = true;
      selectedOldItems.push(id);
    }

    return {
      ...state,
      checkedMapOld,
      selectedOldItems,
    };
  },
  [ActionType.MEDICATION_CHECK_ITEM](state, { id }) {
    const checkedMap = JSON.parse(JSON.stringify(state.checkedMap));
    const selectedItems = [...state.selectedItems];

    if (checkedMap[id]) {
      delete checkedMap[id];
      _.remove(selectedItems, (x) => x === id);
    } else {
      checkedMap[id] = true;
      selectedItems.push(id);
    }

    return {
      ...state,
      checkedMap,
      selectedItems,
    };
  },
  [ActionType.MEDICATION_CHECK_TODAY_ITEM](state, { id }) {
    const checkedMapToday = JSON.parse(JSON.stringify(state.checkedMapToday));
    const selectedTodayItems = [...state.selectedTodayItems];

    if (checkedMapToday[id]) {
      delete checkedMapToday[id];
      _.remove(selectedTodayItems, (x) => x === id);
    } else {
      checkedMapToday[id] = true;
      selectedTodayItems.push(id);
    }

    return {
      ...state,
      checkedMapToday,
      selectedTodayItems,
    };
  },
  [ActionType.CLEAR_MEDICATION_CHECKED_ITEM](state) {
    return { ...state, checkedMap: {}, checkedMapToday: {}, checkedMapOld: {} };
  },
  [ActionType.MEDICATION_POP_NEW_ITEM](state) {
    const newList = JSON.parse(JSON.stringify(state.newList));

    newList.pop();

    return {
      ...state,
      newList,
    };
  },
  [ActionType.MEDICATION_CLEAR_CHECK](state) {
    return {
      ...state,
      checkedMap: {},
      checkedMapToday: {},
      checkedMapOld: {},
      selectedItems: [],
      selectedTodayItems: [],
      selectedOldItems: [],
    };
  },
  [ActionType.MEDICATION_TOGGLE_PDF_MODAL](state, { pdfModalVisible, sendToERX }) {
    return {
      ...state,
      pdfModalVisible,
      sendToERX,
    };
  },
  [ActionType.MEDICATION_POP_HISTORY](state) {
    const newHistoryList = JSON.parse(JSON.stringify(state.newHistoryList));

    newHistoryList.pop();

    return {
      ...state,
      newHistoryList,
    };
  },
  [ActionType.MEDICATION_DUPLICATE](state, { itemsForDuplicate }) {
    const newList = itemsForDuplicate.concat(state.newList);

    return {
      ...state,
      newList,
    };
  },
};

export default createReducer(defaultState, reducers);
