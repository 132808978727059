import _ from "lodash";
import React, { Component } from "react";
import { Field, FieldArray } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

import Back from "../widgets/Back";
import Show from "../widgets/Show";
import MemberImage from "../widgets/MemberImage";
import PatientFormCaption from "./PatientFormCaption";
import { RadioField } from "../form/RadioField";

import { PatientCustomInput } from "./PatientCustomInput";
import LanguageSelectBox from "../widgets/LanguageSelectBox";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { DisplayPublicData } from "../../helpers/toolkitUtils";
import { openSimpleModal } from "../../actions/simpleModalActions";
import { Dropzone } from "../ui/Dropzone";
import { statusChangeOfAppointment } from "../../actions/appointmentActions";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { withRouter } from "react-router";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { SelectPicker } from "../select-picker/SelectPicker";
import { PatientDetailsCustomForm } from "./PatientDetailsCustomForm";
import Utils from "../../helpers/Utils";
import PatientCardReaderInfoModal from "./PatientCardReaderInfoModal";
import { PhoneNumberField } from "../form/PhoneNumberField";
import { DateField } from "../form/DateField";
import { getCompanyStartOfDay } from "../../helpers/DateUtils";
import { IS_ELECTRON } from "../../constants/AppConstants";

const CustomFieldSettingsComponent = ({
  form: { values, setFieldValue },
  patientDetailsEditMode,
}) => {
  const fields = [];
  (values.customFieldSettings || []).forEach((form, index) => {
    for (let [key, property] of Object.entries(form)) {
      fields.push(
        <div className="" key={property.id}>
          <div className="col-sm-4 col-lg-3 mb-3">
            <label htmlFor="socialSecurity" className="control-label">
              {property.title}
            </label>
            <Show if={patientDetailsEditMode}>
              <PatientCustomInput
                name={`customFieldSettings.${index}.${key}`}
                value={property?.value}
                setFieldValue={(name, value) => setFieldValue(name, { ...property, value })}
                options={property.values}
                inputType={_.get(property, "type.code")}
              />
            </Show>
            <Show if={!patientDetailsEditMode}>
              <span className="readElement">{_.get(property, "value")}</span>
            </Show>
          </div>
        </div>,
      );
    }
  });
  return <div className="row">{fields}</div>;
};

const enhancer = connect(
  ({
    language,
    app,
    appointment,
    session: {
      miscSettings: { validateDuplicateNationalIdEnabled },
    },
    auth,
  }) => ({
    languageValues: language[app.language].patientDetailsForm,
    appointment,
    auth,
    validateDuplicateNationalIdEnabled,
  }),
);

class PatientDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      cardReaderBtnText: "Check card reader",
      stateFromIdCard: "",
      cardRead: false,
      checkIn: false,
      cardReaderInfoModalVisible: false,
    };
    this.formRef = React.createRef();
  }

  onDrop = (files) => {
    this.props.dispatch(patientRegisterActions.uploadPhoto(files));
  };

  changeReferral = (option) => {
    this.props.dispatch(patientRegisterActions.showReferralDescription(option.value === 0));
  };

  publicDataCallback = (response, error) => {
    if (error === null) {
      this.displayData(response);
      this.loader(false);
      this.changeBtnText("Check card reader");
    } else {
      this.setState({ cardRead: true });
      const shouldInsertCard = error.message.indexOf("removed");
      this.props.dispatch(
        openSimpleModal({
          body: `${error.message} ${
            shouldInsertCard > -1 ? "click on refresh page button again" : ""
          }`,
        }),
      );
      this.loader(false);
    }
  };

  parseXml = (xml) => {
    const parser = new DOMParser();
    return parser.parseFromString(xml, "text/xml");
  };

  displayData = async (response) => {
    const { chooseCountry, patientDetailsEdit } = patientRegisterActions;
    const {
      dispatch,
      patientRegister: { nationality, country },
    } = this.props;
    const xmlDoc = this.parseXml(response.xmlString);
    const fullNameEnglish = xmlDoc
      .getElementsByTagName("FullNameEnglish")[0]
      .childNodes[0].nodeValue.split(",")
      .map((x) => x.trim())
      .filter((i) => i);
    const passport = xmlDoc.getElementsByTagName("PassportNumber")[0].childNodes[0].nodeValue;
    const nationalId = xmlDoc.getElementsByTagName("IDNumber")[0].childNodes[0].nodeValue;
    const photo = `data:image/bmp;base64,${response.cardHolderPhoto}`;
    const gender = xmlDoc.getElementsByTagName("Gender")[0].childNodes[0].nodeValue === "M" ? 1 : 2;
    const _nationality = xmlDoc.getElementsByTagName("NationalityEnglish")[0].childNodes[0]
      .nodeValue;
    const nationalityCode =
      xmlDoc.getElementsByTagName("NationalityCode")[0].childNodes[0] &&
      xmlDoc.getElementsByTagName("NationalityCode")[0].childNodes[0].nodeValue;
    const birthDate = xmlDoc.getElementsByTagName("DateOfBirth")[0].childNodes[0].nodeValue;
    const maritalStatus =
      xmlDoc.getElementsByTagName("MaritalStatusCode")[0].childNodes[0] &&
      xmlDoc.getElementsByTagName("MaritalStatusCode")[0].childNodes[0].nodeValue;
    const filteredNationalityCode =
      nationalityCode &&
      nationality.find((na) => na.code === nationalityCode) &&
      nationality.find((na) => na.code === nationalityCode).id;
    const filteredNational = nationality.find(
      (na) =>
        (na.description && na.description.toLowerCase().indexOf(_nationality.toLowerCase()) >= 0) ||
        na.name.toLowerCase().indexOf(_nationality.toLowerCase().slice(0, 5)) >= 0,
    ).id;
    let phoneNumber = xmlDoc.getElementsByTagName("MobilePhoneNumber")[0].childNodes[0].nodeValue;
    const _state =
      xmlDoc.getElementsByTagName("EmiratesDescEnglish")[0].childNodes[0] &&
      xmlDoc.getElementsByTagName("EmiratesDescEnglish")[0].childNodes[0].nodeValue;
    const _city =
      xmlDoc.getElementsByTagName("CityDescEnglish")[0].childNodes[0] &&
      xmlDoc.getElementsByTagName("CityDescEnglish")[0].childNodes[0].nodeValue;
    const postCode =
      xmlDoc.getElementsByTagName("POBOX")[0].childNodes[0] &&
      xmlDoc.getElementsByTagName("POBOX")[0].childNodes[0].nodeValue;
    const homePhone = {};
    if (phoneNumber) {
      homePhone.countryCode = {
        label: "United Arab Emirates",
        value: "971",
      };
      phoneNumber = phoneNumber.replace(/^\D+/g, "");
      if (phoneNumber.length) {
        homePhone.townCode = phoneNumber.slice(-9, -7);
        homePhone.number = phoneNumber.slice(-7);
      }
    }

    function makeId(length) {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    const randomImageName = makeId(20);

    function dataURLtoFile(dataUrl, filename) {
      let arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    const _country = country.find((item) => item.id === 3);
    dispatch(chooseCountry(_country.id));

    const file = dataURLtoFile(photo, `${randomImageName}.jpg`);
    await this.onDrop([file]);
    this.formRef.current.setValues({
      ...this.formRef.current.values,
      firstName: fullNameEnglish[0],
      middleName: "",
      lastName: (fullNameEnglish.slice(1) || []).join(" "),
      nationality: filteredNationalityCode
        ? filteredNationalityCode
        : filteredNational
        ? filteredNational
        : null,
      gender,
      passport,
      nationalId: Utils.nationalIdNormalize(nationalId),
      maritalStatus: parseInt(maritalStatus) === 2 ? 1 : 2,
      birthDate: birthDate ? getCompanyStartOfDay(birthDate.split("/").reverse().join("-")) : null,
      homePhone,
      country: 3,
      postCode,
      street: _city,
    });
    dispatch(patientDetailsEdit());
    this.setState({
      stateFromIdCard: _state,
      cardRead: true,
    });
  };

  componentDidUpdate(prevProps) {
    const { stateFromIdCard } = this.state;
    const { chooseState } = patientRegisterActions;
    const {
      dispatch,
      patientRegister: { state },
    } = this.props;
    const filteredState = state.find(
      (item) =>
        stateFromIdCard && item.name.toLowerCase().indexOf(stateFromIdCard.toLowerCase()) !== -1,
    );
    if (
      prevProps.patientRegister.state.length !== this.props.patientRegister.state.length &&
      _.get(filteredState, "id")
    ) {
      if (stateFromIdCard) {
        dispatch(chooseState(filteredState.id));
        this.setState({ stateFromIdCard: "" });
      }
    }
  }

  clearPatientDetails = () => {
    const { dispatch } = this.props;
    const { clearPatientDetails } = patientRegisterActions;
    dispatch(clearPatientDetails());
  };

  componentWillUnmount() {
    this.setState({
      stateFromIdCard: "",
    });
    this.clearPatientDetails();
  }

  loader = (bool) => this.setState({ loader: bool });

  setReaderState = (bool) => this.setState({ cardRead: bool });

  changeBtnText = (text) => {
    this.setState({ cardReaderBtnText: text });
  };

  setCheckIn = () => this.setState({ checkIn: true });

  deletePatientImage = () => {
    this.props.dispatch(patientRegisterActions.deletePatientImage());
  };

  render() {
    const {
      patientRegister,
      initialValues,
      isTemp,
      tempId,
      tempId2,
      languageValues,
      appointment,
      auth,
      // companyId,
    } = this.props;
    const { cardReaderInfoModalVisible } = this.state;
    const { appointment: _appointment } = appointment;
    const { doctors } = auth;
    const {
      personalDetails: { patient },
      patientDetails,
      nationality,
      gender,
      titles,
      occupationList,
      maritalStatus,
      country,
      state,
      profession,
      languages,
      city,
      referralTypes,
      patientDetailsEditMode,
      showReferralDescription,
      pcpRelationshipType,
      pcpRoleTypes,
      primaryCareProviderValidationFields,
    } = patientRegister;

    const { clinic } = this.props;

    const doctorsMap = {};
    const pcpRelationshipTypeMap = {};
    const pcpRoleTypesMap = {};

    const nationalityMap = {};
    const professionMap = {};
    const genderMap = {};
    const occupationMap = {};
    const languageMap = {};
    const maritalStatusMap = {};
    const countryMap = {};
    const stateMap = {};
    const cityMap = {};
    const referralMap = {};

    const isDefaultProviderRole =
      this.props.initialValues.providerRoleType &&
      this.props.initialValues.providerRoleType.value === "PP" &&
      this.props.initialValues.providerRoleType.label === "Primary Care Provider [This is default]";

    const areOtherFieldsEmpty =
      !this.props.initialValues.relationshipType &&
      !this.props.initialValues.rolePerson &&
      !this.props.initialValues.roleBeginDate &&
      !this.props.initialValues.roleEndDate;

    const doctorsOptions = (doctors || [])
      .filter((i) => i.doctor)
      .map((item) => {
        doctorsMap[item.id] = item.firstName;

        return {
          value: item.id,
          label: Utils.getFullName(item),
        };
      });

    const pcpRoleTypeOptions = pcpRoleTypes.map((item) => {
      pcpRoleTypesMap[item.id] = item.name;

      return {
        value: item.code,
        label: item.name,
      };
    });
    const relationshipTypeValue = _.get(initialValues, "relationshipType.value", "");
    const pcpRelationshipTypeOptions = pcpRelationshipType
      .map((item) => {
        pcpRelationshipTypeMap[item.id] = item.name;

        if (isDefaultProviderRole && areOtherFieldsEmpty) {
          if (item.code === "PCP_Active" || item.code === "PCP_Pending") {
            return {
              value: item.code,
              label: item.name,
            };
          }
          return null;
        }

        return {
          value: item.code,
          label: item.name,
          isDisabled: item.code === "PCP_Pending" && relationshipTypeValue === "PCP_Active",
        };
      })
      .filter((item) => {
        if (!item) return false;

        if (!(isDefaultProviderRole && areOtherFieldsEmpty)) {
          if (relationshipTypeValue !== "PCP_Active" && item.value === "PCP_Pending") {
            return false;
          }
        }

        return true;
      });
    const nationalityOptions = nationality.map((item) => {
      nationalityMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });
    const professionOptions = profession.map((item) => {
      professionMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    const genderOptions = gender.map((item) => {
      genderMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    const titleOptions = titles.map((item) => {
      genderMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
        code: item.code,
      };
    });
    const occupationOptions = (occupationList?.data || []).map((item) => {
      occupationMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
        // code: item.code,
      };
    });

    const languageOptions = languages.map((item) => {
      languageMap[item.id] = item.name;

      return {
        value: item.id,
        label: `${item.name}${item.code ? `, (${item.code})` : ""}`,
      };
    });

    const maritalStatusOptions = maritalStatus.map((item) => {
      maritalStatusMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    const studentStatusOptions = [
      { value: 0, label: "NO" },
      { value: 1, label: "YES" },
    ];

    const vipStatusOptions = [
      { value: false, label: "NO" },
      { value: true, label: "YES" },
    ];

    const weightUnitOptions = [
      { value: 1, label: "KG", checked: "checked" },
      { value: 2.20462262, label: "LB" },
    ];

    const countryOptions = country.map((item) => {
      countryMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    const stateOptions = state.map((item) => {
      stateMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });
    const cityOptions = city.map((item) => {
      cityMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    const referralOptions = referralTypes.map((item) => {
      referralMap[item.id] = item.name;

      return {
        value: item.id,
        label: item.name,
      };
    });

    // referralOptions.push({
    //   value: 0,
    //   label: "Other",
    // });

    const secondEmergency = patient.emergencyContacts && patient.emergencyContacts[1];
    const { cardReaderBtnText } = this.state;
    const { location } = this.props;

    const query = parseQuery(location.search);

    if (
      (!patientDetailsEditMode && _.get(query, "register")) ||
      (!patientDetailsEditMode && _.get(query, "patientKey"))
    ) {
      this.props.dispatch(patientRegisterActions.patientDetailsEdit());
    }
    return (
      <>
        <PatientCardReaderInfoModal
          onHide={() => this.setState({ cardReaderInfoModalVisible: !cardReaderInfoModalVisible })}
          show={this.state.cardReaderInfoModalVisible}
        />
        <PatientDetailsCustomForm
          ref={this.formRef}
          initialValues={initialValues}
          isTemp={isTemp}
          tempId2={tempId2}
          onSuccess={({ response, appointment, patientKey }) => {
            const query = parseQuery(this.props.location.search);
            if (query && query.register) {
              if (query && query.temporary && this.state.checkIn) {
                if (IS_ELECTRON) {
                  window.parent.window.createPatientFileToDevice(
                    `patientimport.txt`,
                    `${response.lastName};${response.firstName};01.01.1976;${response.chartNumber}`,
                  );
                }
                this.props
                  .dispatch(
                    statusChangeOfAppointment({
                      id: _.get(appointment, "id"),
                      patientKey: response.patientKey,
                      status: STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
                    }),
                  )
                  .then(() => {
                    this.props.history.push(Routes.DashboardCalendar);
                  });
              } else {
                if (IS_ELECTRON) {
                  window.parent.window.createPatientFileToDevice(
                    `patientimport.txt`,
                    `${response.lastName};${response.firstName};01.01.1976;${response.chartNumber}`,
                  );
                }
                this.props.history.push(
                  createUrl(Routes.PatientMedicalAlertsEdit, {
                    query: {
                      patientKey: response.patientKey,
                      register: "true",
                    },
                  }),
                );
              }
            } else if (isTemp) {
              if (tempId) {
                this.props.dispatch(
                  patientRegisterActions.getTempPatient({
                    companyTempPatientId: tempId,
                  }),
                );
              }

              this.props.history.push(
                createUrl(Routes.PatientView, {
                  query: {
                    companyTempPatientId: patientRegister.patientDetails.id,
                  },
                }),
              );
            } else {
              if (IS_ELECTRON) {
                // SurName;FirstName;01.01.1976;file No
                window.parent.window.createPatientFileToDevice(
                  `patientimport.txt`,
                  `${response.lastName};${response.firstName};01.01.1976;${response.chartNumber}`,
                );
              }
              this.props.history.push(
                createUrl(Routes.PatientView, {
                  params: { patientKey: patientKey || response.patientKey },
                }),
              );
            }
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <>
                <div className="default-page-title clearfix text-center">
                  <Back
                    className="pull-left"
                    onClick={() => {
                      if (query.companyTempPatientId) {
                        this.props.history.push(
                          createUrl(Routes.PatientView, {
                            params: { patientKey: "temp" },
                            query,
                          }),
                        );
                      } else {
                        this.props.history.goBack();
                      }
                    }}
                  />

                  <div className="pull-right">
                    <Show if={patientDetailsEditMode}>
                      <div
                        title="card reader info"
                        className="pull-left mr-3"
                        onClick={() => this.setState({ cardReaderInfoModalVisible: true })}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="info-circle"
                          className="svg-inline--fa fa-info-circle fa-w-16 patient-register-info-btn"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                        </svg>
                      </div>
                    </Show>
                    <Show if={patientDetailsEditMode}>
                      <button
                        type="button"
                        style={{ position: "relative" }}
                        className={`btn ${
                          cardReaderBtnText === "Read public data" ? "btn-info" : "btn-default"
                        } pull-left mr-3`}
                        onClick={() => {
                          DisplayPublicData(
                            this.publicDataCallback,
                            this.loader,
                            this.changeBtnText,
                            this.props.dispatch,
                            this.state.cardRead,
                            this.setReaderState,
                          );
                        }}
                      >
                        {cardReaderBtnText}
                        {this.state.loader && <div className="sm-custom-spinner" />}
                      </button>
                    </Show>
                    <div className="pull-left">
                      <LanguageSelectBox />
                    </div>

                    <Show if={patientDetailsEditMode}>
                      <button type="submit" className="btn btn-link pull-right">
                        {_appointment && _appointment.patient.companyTempPatientId
                          ? "Submit & Check in"
                          : "Submit"}
                      </button>
                    </Show>
                    <Show if={!patientDetailsEditMode}>
                      <button
                        onClick={() =>
                          this.props.dispatch(patientRegisterActions.patientDetailsEdit())
                        }
                        className="btn btn-link pull-right"
                      >
                        Edit
                      </button>
                    </Show>
                  </div>

                  <div className="title">Welcome to {clinic.name}</div>
                </div>
                <div className="default-page-body patient-details-edit-body form-horizontal">
                  {!isTemp && (
                    <PatientFormCaption patient={patient} formId="Patient Personal Details" />
                  )}
                  <div className="line-title">
                    <span className="text">{languageValues.generalInfo}</span>
                  </div>
                  <div className="row">
                    <div className="col-sm-9">
                      <div className="patient-details-edit-box">
                        <div className="">
                          <div className="row mb-3">
                            <div className="col-sm-3">
                              <label htmlFor="gender" className="control-label">
                                Title
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  component={({ field, form }) => {
                                    return (
                                      <SelectPicker
                                        options={titleOptions}
                                        value={{
                                          value: field.value?.id ? field.value.id : field.value,
                                        }}
                                        onChange={(x) => form.setFieldValue(field.name, x.value)}
                                      />
                                    );
                                  }}
                                  name="title"
                                  id="title"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {genderMap[initialValues.title] || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-3">
                              <label htmlFor="firstName" className="control-label">
                                {languageValues.firstName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="firstName"
                                  id="firstName"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">{initialValues.firstName || ""}</span>
                              </Show>
                            </div>

                            {!isTemp && (
                              <div className="col-sm-3">
                                <label htmlFor="middleName" className="control-label">
                                  {languageValues.middleName}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="middleName"
                                    id="middleName"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.middleName || ""}
                                  </span>
                                </Show>
                              </div>
                            )}

                            <div className="col-sm-3">
                              <label htmlFor="lastName" className="control-label">
                                {languageValues.lastName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="lastName"
                                  id="lastName"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">{initialValues.lastName || ""}</span>
                              </Show>
                            </div>
                            {isTemp && (
                              <>
                                <div className="col-sm-4">
                                  <Show if={!patientDetailsEditMode}>
                                    <label className="control-label">
                                      {languageValues.mobilePhone}
                                    </label>
                                  </Show>
                                  <Show if={patientDetailsEditMode}>
                                    <PhoneNumberField
                                      size="sm"
                                      name="mobilePhone"
                                      fieldProps={{ style: { margin: 0 } }}
                                      className="form-control"
                                      labelClassName="control-label"
                                      label="Mobile Phone - هاتف المحمول"
                                    />
                                  </Show>
                                  <Show if={!patientDetailsEditMode}>
                                    <span className="readElement">
                                      {patient.mobilePhoneNumber ||
                                        patientDetails.mobilePhoneNumber ||
                                        `+${_.get(
                                          initialValues,
                                          "mobilePhone.countryCode.value",
                                          "",
                                        )} (${_.get(
                                          initialValues,
                                          "mobilePhone.townCode",
                                          "",
                                        )}) ${_.get(initialValues, "mobilePhone.number", "")}`}
                                    </span>
                                  </Show>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row mb-3">
                            {!isTemp && (
                              <div className="col-sm-4">
                                <label htmlFor="nationality" className="control-label">
                                  {languageValues.nationality}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    component={({ field, form }) => (
                                      <SelectPicker
                                        isSearchable={true}
                                        options={nationalityOptions}
                                        value={{ value: field.value }}
                                        onChange={(x) => form.setFieldValue(field.name, x.value)}
                                      />
                                    )}
                                    name="nationality"
                                    id="nationality"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {nationalityMap[initialValues.nationality] || ""}
                                  </span>
                                </Show>
                              </div>
                            )}
                            {!isTemp && (
                              <div className="col-sm-4">
                                <div className="row m-0">
                                  <div className="col-sm-6">
                                    <label htmlFor="birthDate" className="control-label">
                                      {languageValues.birthDate}
                                    </label>
                                    <Show if={patientDetailsEditMode}>
                                      <DateField name="birthDate" id="birthDate" />
                                    </Show>
                                    <Show if={!patientDetailsEditMode}>
                                      <span className="readElement">
                                        {moment(initialValues.birthDate).format(DATE_FORMAT_LEGACY)}
                                      </span>
                                    </Show>
                                  </div>
                                  <div className="col-sm-6">
                                    <label htmlFor="gender" className="control-label">
                                      {languageValues.gender}
                                    </label>
                                    <Show if={patientDetailsEditMode}>
                                      <Field
                                        component={({ field, form }) => (
                                          <SelectPicker
                                            options={genderOptions}
                                            value={{ value: field.value }}
                                            onChange={(x) =>
                                              form.setFieldValue(field.name, x.value)
                                            }
                                          />
                                        )}
                                        name="gender"
                                        id="gender"
                                      />
                                    </Show>
                                    <Show if={!patientDetailsEditMode}>
                                      <span className="readElement">
                                        {genderMap[initialValues.gender] || ""}
                                      </span>
                                    </Show>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!isTemp && (
                              <div className="col-sm-4">
                                <label htmlFor="maritalStatus" className="control-label">
                                  {languageValues.maritalStatus}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    component={({ field, form }) => (
                                      <SelectPicker
                                        options={maritalStatusOptions}
                                        value={{ value: field.value }}
                                        onChange={(x) => form.setFieldValue(field.name, x.value)}
                                      />
                                    )}
                                    name="maritalStatus"
                                    id="maritalStatus"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {maritalStatusMap[initialValues.maritalStatus] || ""}
                                  </span>
                                </Show>
                              </div>
                            )}
                          </div>
                          {!isTemp && (
                            <div className="row mb-3">
                              <div className="col-sm-2">
                                <label
                                  htmlFor="vipPatient"
                                  className="control-label"
                                  style={{ textAlign: "left" }}
                                >
                                  VIP Patient
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <div className="d-flex">
                                    {(vipStatusOptions || []).map((radio) => (
                                      <RadioField
                                        name="vipPatient"
                                        key={radio.value}
                                        label={radio.label}
                                        radioValue={radio.value}
                                        className="mr-2"
                                      />
                                    ))}
                                  </div>
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {(vipStatusOptions[initialValues.vipPatient] &&
                                      vipStatusOptions[initialValues.vipPatient].label) ||
                                      ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-2">
                                <label
                                  htmlFor="studentStatus"
                                  className="control-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {languageValues.studentStatus}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <div className="d-flex">
                                    {(studentStatusOptions || []).map((radio) => (
                                      <RadioField
                                        name="studentStatus"
                                        key={radio.value}
                                        label={radio.label}
                                        radioValue={radio.value}
                                        className="mr-2"
                                      />
                                    ))}
                                  </div>
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {(studentStatusOptions[initialValues.studentStatus] &&
                                      studentStatusOptions[initialValues.studentStatus].label) ||
                                      ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-2">
                                <label htmlFor="weight" className="control-label">
                                  {languageValues.weight}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="weight"
                                    id="weight"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">{initialValues.weight || "0"}</span>
                                </Show>
                              </div>
                              <div className="col-sm-2">
                                <label htmlFor="weightUnit" className="control-label">
                                  &nbsp;
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <div className="d-flex">
                                    {(weightUnitOptions || []).map((radio) => (
                                      <RadioField
                                        name="weightUnit"
                                        key={radio.value}
                                        label={radio.label}
                                        radioValue={radio.value}
                                        className="mr-2"
                                      />
                                    ))}
                                  </div>
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {(weightUnitOptions[initialValues.weightUnit] &&
                                      weightUnitOptions[initialValues.weightUnit].label) ||
                                      ""}
                                  </span>
                                </Show>
                              </div>
                            </div>
                          )}

                          {!isTemp && (
                            <div className="row mb-3">
                              <div className="col-sm-4">
                                <label htmlFor="workplace" className="control-label">
                                  {languageValues.profession || "Profession"}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    component={({ field, form }) => (
                                      <SelectPicker
                                        isSearchable={true}
                                        options={professionOptions}
                                        value={{ value: field.value }}
                                        onChange={(x) => form.setFieldValue(field.name, x.value)}
                                      />
                                    )}
                                    name="profissionType"
                                    id="profissionType"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {professionMap[initialValues.profissionType?.value] || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4">
                                <label htmlFor="workplace" className="control-label">
                                  {languageValues.workplace}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="workplace"
                                    id="workplace"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.workplace || ""}
                                  </span>
                                </Show>
                              </div>

                              <div className="col-sm-4">
                                <label htmlFor="workplace" className="control-label">
                                  {languageValues.occupation || "Occupation"}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    component={({ field, form }) => {
                                      return (
                                        <SelectPicker
                                          options={occupationOptions}
                                          isSearchable={true}
                                          value={{
                                            value: field.value?.id ? +field.value.id : +field.value,
                                          }}
                                          onChange={(x) => form.setFieldValue(field.name, x.value)}
                                        />
                                      );
                                    }}
                                    name="occupation"
                                    id="occupation"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {occupationMap[initialValues.occupation] || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 mt-3">
                                <label htmlFor="referral" className="control-label">
                                  {languageValues.referral}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    component={({ field, form }) => (
                                      <SelectPicker
                                        isSearchable={true}
                                        options={referralOptions}
                                        value={{ value: field.value }}
                                        onChange={(x) => {
                                          form.setFieldValue(field.name, x.value);
                                          this.changeReferral(x.value);
                                        }}
                                      />
                                    )}
                                    name="referralType"
                                    id="referralType"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.referralType !== 0
                                      ? referralMap[initialValues.referralType] || ""
                                      : initialValues.referralTypeDescription}
                                  </span>
                                </Show>
                              </div>
                              <Show
                                if={patientDetailsEditMode && showReferralDescription && !isTemp}
                              >
                                <div className="col-sm-4">
                                  <label htmlFor="street" className="control-label">
                                    Other
                                  </label>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="referralTypeDescription"
                                    id="referralTypeDescription"
                                  />
                                </div>
                              </Show>
                              <div className="col-sm-4 mt-3">
                                <label htmlFor="street" className="control-label">
                                  {languageValues.additionalInformation}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="textarea"
                                    className="form-control"
                                    name="additionalInformation"
                                    id="additionalInformation"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.additionalInformation || ""}
                                  </span>
                                </Show>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3" style={{ paddingTop: "43px" }}>
                      {!isTemp && (
                        <div
                          className="patient-details-edit-photo m-0"
                          style={{ position: "relative" }}
                        >
                          <Show if={patientDetailsEditMode}>
                            <Dropzone className="dropzone" onDrop={this.onDrop} multiple={false}>
                              <MemberImage
                                size={190}
                                photoUrl={patient.photo && patient.photo.url}
                              />
                            </Dropzone>
                          </Show>
                          <Show if={!patientDetailsEditMode}>
                            <MemberImage size={190} photoUrl={patient.photo && patient.photo.url} />
                          </Show>
                          {patientDetailsEditMode && patient.photo && patient.photo.url && (
                            <button
                              type="button"
                              className="btn btn-danger m-1"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={this.deletePatientImage}
                            >
                              x
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="line-title">
                    <span className="text">{languageValues.addressAndContacts}</span>
                  </div>
                  <div className="patient-details-edit-box">
                    {!isTemp && (
                      <div className="">
                        <div className="row mb-3">
                          <div className="col-sm-4 col-lg-3">
                            <Show if={!patientDetailsEditMode}>
                              <label className="control-label">{languageValues.mobilePhone}</label>
                            </Show>
                            <Show if={patientDetailsEditMode}>
                              <PhoneNumberField
                                size="sm"
                                name="mobilePhone"
                                fieldProps={{ style: { margin: 0 } }}
                                className="form-control"
                                labelClassName="control-label"
                                label="Mobile Phone - هاتف المحمول"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {patient.mobilePhoneNumber ||
                                  patientDetails.mobilePhoneNumber ||
                                  `+${_.get(
                                    initialValues,
                                    "mobilePhone.countryCode.value",
                                    "",
                                  )} (${_.get(initialValues, "mobilePhone.townCode", "")}) ${_.get(
                                    initialValues,
                                    "mobilePhone.number",
                                    "",
                                  )}`}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <Show if={!patientDetailsEditMode}>
                              <label className="control-label">{languageValues.workPhone}</label>
                            </Show>
                            <Show if={patientDetailsEditMode}>
                              <PhoneNumberField
                                size="sm"
                                name="workPhone"
                                className="form-control"
                                fieldProps={{ style: { margin: 0 } }}
                                labelClassName="control-label"
                                label="Work Phone - هاتف العمل"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{patient.workPhoneNumber || ""}</span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <Show if={!patientDetailsEditMode}>
                              <label className="control-label">{languageValues.homePhone}</label>
                            </Show>
                            <Show if={patientDetailsEditMode}>
                              <PhoneNumberField
                                size="sm"
                                name="homePhone"
                                fieldProps={{ style: { margin: 0 } }}
                                className="form-control"
                                labelClassName="control-label"
                                label="Home Phone - هاتف المنزل"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{patient.homePhoneNumber || ""}</span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="email" className="control-label">
                              {languageValues.email}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                type="email"
                                component="input"
                                className="form-control"
                                name="email"
                                id="email"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{initialValues.email || ""}</span>
                            </Show>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="country" className="control-label">
                              {languageValues.country}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => (
                                  <SelectPicker
                                    isSearchable={true}
                                    options={countryOptions}
                                    value={{ value: field.value }}
                                    onChange={(x) => {
                                      form.setFieldValue(field.name, x.value);
                                      this.props.dispatch(
                                        patientRegisterActions.chooseCountry(x.value),
                                      );
                                    }}
                                  />
                                )}
                                name="country"
                                id="country"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {countryMap[initialValues.country] || ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="state" className="control-label">
                              {languageValues.state}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => (
                                  <SelectPicker
                                    isSearchable={true}
                                    options={stateOptions}
                                    value={{ value: field.value }}
                                    onChange={(x) => {
                                      form.setFieldValue(field.name, x.value);
                                      this.props.dispatch(
                                        patientRegisterActions.chooseState(
                                          x.value,
                                          form?.values?.country,
                                        ),
                                      );
                                    }}
                                  />
                                )}
                                name="state"
                                id="state"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {stateMap[initialValues.state] || ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="city" className="control-label">
                              {languageValues.city}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => (
                                  <SelectPicker
                                    isSearchable={true}
                                    options={cityOptions}
                                    value={{
                                      value: field.value?.value ? field.value?.value : field.value,
                                    }}
                                    onChange={(x) => form.setFieldValue(field.name, x.value)}
                                  />
                                )}
                                name="city"
                                id="city"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {cityMap[initialValues.city?.value] ||
                                  (initialValues.city && initialValues.city?.label) ||
                                  ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="smsLanguage" className="control-label">
                              {languageValues.smsLanguage || "SMS Language"}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => (
                                  <SelectPicker
                                    options={languageOptions}
                                    value={{ value: field.value }}
                                    onChange={(x) => form.setFieldValue(field.name, x.value)}
                                  />
                                )}
                                name="smsLanguage"
                                id="smsLanguage"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {languageMap[initialValues.smsLanguage] || ""}
                              </span>
                            </Show>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="street" className="control-label">
                              {languageValues.street}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                type="text"
                                component="input"
                                className="form-control"
                                name="street"
                                id="street"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{initialValues.street || ""}</span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="street2" className="control-label">
                              {languageValues.street2}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                type="text"
                                component="input"
                                className="form-control"
                                name="street2"
                                id="street2"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{initialValues.street2 || ""}</span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="postCode" className="control-label">
                              {languageValues.postCode}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                type="text"
                                component="input"
                                className="form-control"
                                name="postCode"
                                id="postCode"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">{initialValues.postCode || ""}</span>
                            </Show>
                          </div>
                        </div>
                      </div>
                    )}
                    {isTemp && (
                      <div className="col-sm-4 col-lg-3 mb-3">
                        <label htmlFor="email" className="control-label">
                          {languageValues.email}
                        </label>
                        <Show if={patientDetailsEditMode}>
                          <Field
                            type="text"
                            component="input"
                            className="form-control"
                            name="email"
                            id="email"
                          />
                        </Show>
                        <Show if={!patientDetailsEditMode}>
                          <span className="readElement">{initialValues.email || ""}</span>
                        </Show>
                      </div>
                    )}
                  </div>

                  {!isTemp && (
                    <div>
                      {/*<Show if={companyId !== 93}>*/}
                      <div>
                        <div className="line-title">
                          <span className="text">{languageValues.idDocuments}</span>
                        </div>
                        <div className="patient-details-edit-box">
                          <div className="">
                            <div className="row mb-3">
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="nationalId" className="control-label">
                                  {languageValues.nationalIdNumber}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="nationalId"
                                    id="nationalId"
                                    disabled={values?.nationalIdNotAvailable}
                                    value={values?.nationalId}
                                    onChange={({ target: { value } }) =>
                                      setFieldValue("nationalId", Utils.nationalIdNormalize(value))
                                    }
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.nationalId || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="nationalIdNotAvailable" className="control-label">
                                  National ID Not Available
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <input
                                    value={values?.nationalIdNotAvailable}
                                    type="checkbox"
                                    checked={values?.nationalIdNotAvailable}
                                    onChange={({ target }) => {
                                      setFieldValue("nationalIdNotAvailable", target.checked);
                                      if (target.checked) {
                                        setFieldValue(
                                          "nationalId",
                                          Utils.nationalIdNormalize("111111111111111"),
                                        );
                                      } else {
                                        setFieldValue("nationalId", "");
                                      }
                                    }}
                                  />
                                </Show>
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="socialSecurity" className="control-label">
                                  {languageValues.socialSecurityNumber}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="socialSecurity"
                                    id="socialSecurity"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.socialSecurity || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="driversLicence" className="control-label">
                                  {languageValues.driverLicenseNumber}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="driversLicence"
                                    id="driversLicence"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.driversLicence || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="socialInsurance" className="control-label">
                                  {languageValues.socialInsuranceNumber}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="socialInsurance"
                                    id="socialInsurance"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.socialInsurance || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 col-lg-3">
                                <label htmlFor="passport" className="control-label">
                                  {languageValues.passportNumber}
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="passport"
                                    id="passport"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.passport || ""}
                                  </span>
                                </Show>
                              </div>
                              <div className="col-sm-4 col-lg-3 mt-3">
                                <label htmlFor="internalFileNumber" className="control-label">
                                  {languageValues.internalFileNumber}
                                  خاص
                                </label>
                                <Show if={patientDetailsEditMode}>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="internalFileNumber"
                                    id="internalFileNumber"
                                  />
                                </Show>
                                <Show if={!patientDetailsEditMode}>
                                  <span className="readElement">
                                    {initialValues.internalFileNumber || ""}
                                  </span>
                                </Show>
                              </div>
                            </div>
                          </div>
                          <FieldArray
                            name="customFieldSettings"
                            component={(props) => (
                              <CustomFieldSettingsComponent
                                {...props}
                                patientDetailsEditMode={patientDetailsEditMode}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/*</Show>*/}
                      <div className="line-title">
                        <span className="text">{languageValues.emergencyContactPerson}</span>
                      </div>
                      <div className="patient-details-edit-box">
                        <div className="">
                          <div className="row mb-3">
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyFirstName" className="control-label">
                                {languageValues.firstName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyFirstName"
                                  id="emergencyFirstName"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyFirstName || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyLastName" className="control-label">
                                {languageValues.lastName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyLastName"
                                  id="emergencyLastName"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyLastName || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyRelationship" className="control-label">
                                {languageValues.relationship}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyRelationship"
                                  id="emergencyRelationship"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyRelationship || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyHomePhone" className="control-label">
                                {languageValues.homePhone}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyHomePhone"
                                  id="emergencyHomePhone"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyHomePhone || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyMobilePhone" className="control-label">
                                {languageValues.mobilePhone}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyMobilePhone"
                                  id="emergencyMobilePhone"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyMobilePhone || ""}
                                </span>
                              </Show>
                            </div>
                            <Show if={patientDetailsEditMode}>
                              <Show if={!secondEmergency}>
                                <div className="col-sm-4 col-lg-3">
                                  <button
                                    type="button"
                                    style={{ height: "34px", marginTop: "20px", padding: "6px" }}
                                    className="btn btn-block btn-lg patient-details-edit-btn"
                                    onClick={() =>
                                      this.props.dispatch(patientRegisterActions.addAnother())
                                    }
                                  >
                                    Add Another
                                  </button>
                                </div>
                              </Show>
                            </Show>
                            <Show if={patientDetailsEditMode}>
                              <Show if={secondEmergency}>
                                <div className="col-sm-4 col-lg-3">
                                  <button
                                    type="button"
                                    style={{ height: "34px", marginTop: "20px" }}
                                    className="btn btn-block btn-lg btn-danger"
                                    onClick={() =>
                                      this.props.dispatch(patientRegisterActions.deleteAnother())
                                    }
                                  >
                                    Delete Another
                                  </button>
                                </div>
                              </Show>
                            </Show>
                          </div>
                        </div>
                        <Show if={secondEmergency}>
                          <div className="row mb-3">
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyFirstName2" className="control-label">
                                {languageValues.firstName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyFirstName2"
                                  id="emergencyFirstName2"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyFirstName2 || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyLastName2" className="control-label">
                                {languageValues.lastName}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyLastName2"
                                  id="emergencyLastName2"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyLastName2 || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyRelationship2" className="control-label">
                                {languageValues.relationship}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyRelationship2"
                                  id="emergencyRelationship2"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyRelationship2 || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyHomePhone2" className="control-label">
                                {languageValues.homePhone}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyHomePhone2"
                                  id="emergencyHomePhone2"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyHomePhone2 || ""}
                                </span>
                              </Show>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <label htmlFor="emergencyMobilePhone2" className="control-label">
                                {languageValues.mobilePhone}
                              </label>
                              <Show if={patientDetailsEditMode}>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="emergencyMobilePhone2"
                                  id="emergencyMobilePhone2"
                                />
                              </Show>
                              <Show if={!patientDetailsEditMode}>
                                <span className="readElement">
                                  {initialValues.emergencyMobilePhone2 || ""}
                                </span>
                              </Show>
                            </div>
                          </div>
                        </Show>
                      </div>
                      <div className="line-title">
                        <span className="text">{languageValues.primaryCareProvider}</span>
                      </div>
                      <div className="patient-details-edit-box">
                        <div className="row mb-3">
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="relationshipType" className="control-label">
                              {languageValues.primaryCareProviderRelationShip}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => {
                                  return (
                                    <SelectPicker
                                      options={pcpRelationshipTypeOptions}
                                      isSearchable={true}
                                      value={{
                                        value: field.value?.value ? field.value.value : field.value,
                                      }}
                                      onChange={(option) =>
                                        form.setFieldValue("relationshipType", {
                                          label: option.label,
                                          value: option.value,
                                        })
                                      }
                                    />
                                  );
                                }}
                                name="relationshipType"
                                id="relationshipType"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {initialValues?.relationshipType?.label || ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="providerRoleType" className="control-label">
                              {languageValues.providerRole}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => {
                                  return (
                                    <SelectPicker
                                      options={pcpRoleTypeOptions}
                                      isSearchable={true}
                                      value={{
                                        value: field.value?.value ? field.value.value : field.value,
                                      }}
                                      onChange={(option) =>
                                        form.setFieldValue("providerRoleType", {
                                          label: option.label,
                                          value: option.value,
                                        })
                                      }
                                    />
                                  );
                                }}
                                name="providerRoleType"
                                id="providerRoleType"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {initialValues?.providerRoleType?.label || ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3">
                            <label htmlFor="rolePerson" className="control-label">
                              {languageValues.assignDoctor}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <Field
                                component={({ field, form }) => {
                                  return (
                                    <SelectPicker
                                      options={doctorsOptions}
                                      isSearchable={true}
                                      isDisabled={
                                        primaryCareProviderValidationFields.notAllowChangeDoctorField
                                      }
                                      value={{
                                        value: field.value?.id ? +field.value.id : +field.id,
                                      }}
                                      onChange={(option) =>
                                        form.setFieldValue("rolePerson", {
                                          label: option.label,
                                          id: option.value,
                                        })
                                      }
                                    />
                                  );
                                }}
                                name="rolePerson"
                                id="rolePerson"
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {initialValues?.rolePerson?.label || ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3 patient_details-role_date">
                            <label htmlFor="roleBeginDate" className="control-label">
                              {languageValues.roleBegin}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <DateField
                                name="roleBeginDate"
                                id="roleBeginDate"
                                placeholder="Select"
                                maxDate={new Date()}
                                disabled={
                                  primaryCareProviderValidationFields.notAllowChangeRoleBeginField
                                }
                                className={
                                  primaryCareProviderValidationFields.notAllowChangeRoleBeginField
                                    ? "patient_role_date"
                                    : ""
                                }
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {initialValues.roleBeginDate
                                  ? moment(initialValues.roleBeginDate).format(DATE_FORMAT_LEGACY)
                                  : ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3 patient_details-role_date">
                            <label htmlFor="roleEndDate" className="control-label">
                              {languageValues.roleEnd}
                            </label>
                            <Show if={patientDetailsEditMode}>
                              <DateField
                                name="roleEndDate"
                                id="roleEndDate"
                                placeholder="Select"
                                maxDate={new Date()}
                                disabled={!primaryCareProviderValidationFields.shouldUnlockRoleEnd}
                                className={
                                  !primaryCareProviderValidationFields.shouldUnlockRoleEnd
                                    ? "patient_role_date"
                                    : ""
                                }
                              />
                            </Show>
                            <Show if={!patientDetailsEditMode}>
                              <span className="readElement">
                                {initialValues.roleEndDate
                                  ? moment(initialValues.roleEndDate).format(DATE_FORMAT_LEGACY)
                                  : ""}
                              </span>
                            </Show>
                          </div>
                          <div className="col-sm-4 col-lg-3 patient_details-role_date">
                            <button
                              type="button"
                              style={{ height: "34px", marginTop: "20px", padding: "6px" }}
                              className={`btn btn-block btn-lg ${
                                primaryCareProviderValidationFields.isValidateBtnClicked
                                  ? "btn-danger"
                                  : "patient-details-edit-btn"
                              }`}
                              onClick={() =>
                                this.props.dispatch(
                                  patientRegisterActions.setValuesForProviderValidation(values),
                                )
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  )}

                  <div className="patient-details-edit-box">
                    <div className="row">
                      <div className="col-sm-12">
                        <Show if={!patientDetailsEditMode}>
                          <div
                            className="btn btn-block btn-lg btn-primary"
                            onClick={() =>
                              this.props.dispatch(patientRegisterActions.patientDetailsEdit())
                            }
                          >
                            Edit
                          </div>
                        </Show>
                        <Show if={patientDetailsEditMode}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Show if={_appointment && _appointment.patient.companyTempPatientId}>
                              <button
                                type="submit"
                                className="btn btn-block btn-lg btn-danger"
                                style={{
                                  width: "30%",
                                  marginTop: "5px",
                                }}
                              >
                                SUBMIT ONLY [Don't check-in]
                              </button>
                            </Show>
                            <button
                              type="submit"
                              className="btn btn-block btn-lg btn-primary"
                              style={
                                _appointment && _appointment.patient.companyTempPatientId
                                  ? {
                                      width: "68%",
                                    }
                                  : {}
                              }
                              onClick={() => this.setCheckIn()}
                            >
                              {_appointment && _appointment.patient.companyTempPatientId
                                ? "SUBMIT & CHECK IN APPOINTMENT"
                                : "Submit"}
                            </button>
                          </div>
                        </Show>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                {this.props.children}
              </>
            );
          }}
        </PatientDetailsCustomForm>
      </>
    );
  }
}

export default withRouter(enhancer(PatientDetailsForm));
