import React from "react";

export default function PopoverWidget(props) {
  const { children, show, closePopover } = props;
  return show ? (
    <div className="popover-box">
      <div className="custom-popover popover fade right in" style={{ display: "block" }}>
        <div className="arrow" style={{ top: "15px" }} />
        {children}
      </div>
      <div className="popover-bg" style={{ display: "block" }} onClick={closePopover} />
    </div>
  ) : null;
}
