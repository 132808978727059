import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import ReactSpinner from "react-spinjs-fix";

import MemberImage from "../widgets/MemberImage";
import Show from "../widgets/Show";
import FilledImage from "../widgets/FilledImage";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import menuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientDetailsEdit extends React.Component {
  componentDidMount() {
    this.props.patientRegisterActions.initPatientList();
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.patientRegisterActions.loadPatientList();
    }
  };

  chooseHousehold = (id) => {
    return () => {
      this.props.patientRegisterActions.chooseHousehold(id);
    };
  };

  render() {
    const {
      listHousehold,
      houseHoldModalVisible,
      activeHousehold,
      fetchingListHousehold,
      searchHousehold,
    } = this.props.patientRegister;

    const $list = listHousehold.map((item, index) => (
      <div className="list-group-item" key={index} onClick={this.chooseHousehold(item.id)}>
        <MemberImage photoUrl={item.photoUrl} />
        <div className="text">{item.fullname}</div>
        <Show if={activeHousehold === item.id}>
          <div className="arrow">✔</div>
        </Show>
      </div>
    ));

    return (
      <Modal
        size="small"
        onHide={this.props.patientRegisterActions.closeHouseHold}
        show={houseHoldModalVisible}
        keyboard={false}
        title={<div>Please select Patient</div>}
        actions={
          <div>
            <Button onClick={this.props.patientRegisterActions.closeHouseHold}>Close</Button>
            <Button bsStyle="primary" onClick={this.props.patientRegisterActions.selectHousehold}>
              Done
            </Button>
          </div>
        }
      >
        <div className="patient-details-household">
          <div className="input-group search">
            <span className="input-group-addon">
              <FilledImage color={"#aaa"} src={menuImage} className="zoom-2-5x" />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="search"
              value={searchHousehold}
              onChange={this.props.patientRegisterActions.searchHouseHold}
            />
          </div>
          <div className="list-group" onScroll={this.loadList}>
            {$list}
            <Show if={fetchingListHousehold}>
              <ReactSpinner />
            </Show>
          </div>
        </div>
      </Modal>
    );
  }
}
export default enhancer(PatientDetailsEdit);
