import React from "react";

import MemberImage from "../widgets/MemberImage";

export default class MemberItem extends React.Component {
  onClick = () => {
    this.props.chooseMember(this.props.member.id);
  };

  render() {
    return (
      <div className="member-item" onClick={this.onClick}>
        <MemberImage photoUrl={this.props.member.photoUrl} />
      </div>
    );
  }
}
