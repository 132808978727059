import React from "react";

import PatientSelectAppointmentSidebarListItem from "./PatientSelectAppointmentSidebarListItem";

export default class PatientSelectAppointmentSidebarList extends React.Component {
  render() {
    const { list } = this.props;

    const apptList = list.map((item, index) => (
      <PatientSelectAppointmentSidebarListItem
        key={index}
        hideSideBar={this.props.hideSideBar}
        item={item}
      />
    ));

    return <div className="patient-appointment-list">{apptList}</div>;
  }
}
