import _ from "lodash";
import { PHONE_CODE_LIST } from "../constants/PhoneConstants";

const companyPhoneCode = localStorage.getItem("phoneCode");

export function findPhoneByCode(code) {
  return PHONE_CODE_LIST.find(({ phoneCode }) => phoneCode === code);
}

export function getPhoneCode(phone) {
  const phoneCode = _.get(phone, "countryCode.value");

  return _.toString(phoneCode);
}

export function getPhoneCodeObject(phone) {
  return _.get(phone, "countryCode");
}

export function getPhoneTown(phone) {
  const phoneTown = _.get(phone, "townCode");

  return _.toString(phoneTown);
}

export function getPhoneNumber(phone) {
  const phoneNumber = _.get(phone, "number");

  return _.toString(phoneNumber);
}

export function phoneToString(phone) {
  const phoneCode = getPhoneCode(phone) || companyPhoneCode;
  const phoneTown = getPhoneTown(phone);
  const phoneNumber = getPhoneNumber(phone);

  return `${phoneCode}-${phoneTown}-${phoneNumber}`;
}

export function phoneToObject(phone) {
  const phoneNumber = phone.split("-");
  const phoneCode = findPhoneByCode(phoneNumber[0] || companyPhoneCode);

  return {
    countryCode: {
      label: _.get(phoneCode, "name"),
      value: _.get(phoneCode, "phoneCode"),
    },
    townCode: phoneNumber[1] || "",
    number: phoneNumber[2] || "",
  };
}
