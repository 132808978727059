import { useLocation } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Route, Switch, useHistory } from "react-router";

import { THEME } from "./AppContainer";
import App from "../components/main/App";
import { Routes } from "../constants/Routes";
import { AuthContainer } from "./AuthContainer";
import Page404 from "../components/main/Page404";
import { AppFallback } from "../components/main/AppFallback";
import { PrivateRoute } from "../components/routing/PrivateRoute";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";
import { deviceExistSelector, loggedInSelector } from "../reducers/authReducer";

const PatientContainer = React.lazy(() => import("./PatientContainer"));
const SettingsContainer = React.lazy(() => import("./SettingsContainer"));
const DashboardContainer = React.lazy(() => import("./DashboardContainer"));

export function RootContainer() {
  const history = useHistory();
  const location = useLocation();

  const loggedIn = useShallowEqualSelector(loggedInSelector);
  const deviceExist = useShallowEqualSelector(deviceExistSelector);

  useEffect(() => {
    if (location.pathname === "/") {
      if (!deviceExist || !loggedIn) {
        history.replace(Routes.Auth);
      } else {
        history.replace(Routes.Dashboard);
      }
    } else if (
      deviceExist &&
      loggedIn &&
      location.pathname.indexOf(Routes.Patient) !== 0 &&
      location.pathname.indexOf(Routes.Dashboard) !== 0 &&
      location.pathname.indexOf(Routes.Settings) !== 0
    ) {
      history.replace(Routes.Dashboard);
    }
  }, [loggedIn, deviceExist, history, location.pathname]);

  return (
    <ThemeProvider theme={THEME}>
      <App>
        <Suspense fallback={<AppFallback />}>
          <Switch>
            <PrivateRoute path={Routes.Patient} component={PatientContainer} />
            <PrivateRoute path={Routes.Dashboard} component={DashboardContainer} />
            <PrivateRoute path={Routes.Settings} component={SettingsContainer} />

            <Route path={Routes.Auth} component={AuthContainer} />

            <Route component={Page404} />
          </Switch>
        </Suspense>
      </App>
    </ThemeProvider>
  );
}
