import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import Back from "../../../widgets/Back";

import { getPatientFormsItem } from "../../../../actions/patientRegisterActions";
import EmbedButton from "../../../widgets/EmbedButton";
import { popStack } from "../../../../actions/chartSideMedicalFillInFormActions";

const enhancer = connect(
  ({ patientRegister, chartSideMedicalFillInForm }) => ({
    patientRegister,
    chartSideMedicalFillInForm,
  }),
  null,
);
const MedicalFillInFormConsentFormView = ({ patientRegister, chartSideMedicalFillInForm }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { consentFormViewData } = chartSideMedicalFillInForm;

    dispatch(getPatientFormsItem(consentFormViewData));
  }, []);

  const { formsItem, patientInfo } = patientRegister;

  let patientData = patientInfo.firstName || "";
  const lastName = patientInfo.lastName || "";
  const chartNumber = patientInfo.chartNumber || "";

  patientData += lastName !== "" ? " " + lastName : "";
  patientData += patientData !== "" && chartNumber !== "" ? ", " : "";
  patientData += chartNumber !== "" ? "#" + chartNumber : "";

  return (
    <div className="patient-appointments-list">
      <div className="default-page-title clearfix text-center">
        <Back className="pull-left" onClick={() => dispatch(popStack())} />
        <div className="btn-user-name pull-right">{patientData}</div>
      </div>

      <EmbedButton
        src={formsItem.signContent}
        show={formsItem.signContent}
        embedStyle={{ height: "100%", flex: 1 }}
      />
    </div>
  );
};
export default enhancer(MedicalFillInFormConsentFormView);
