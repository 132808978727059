import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import FilledImage from "../widgets/FilledImage";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import MenuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersChooseDentistAll extends React.Component {
  componentDidMount() {
    this.props.remindersActions.initDentistList();
  }

  render() {
    const { toggleDentistSelected, fetchingDentistList, searchDentist, dentistList } = this.props;

    let list = [];

    _.forEach(dentistList, (value, index) => {
      list.push(
        <div
          className="list-group-item"
          key={index}
          onClick={() => {
            this.props.remindersActions.toggleDentist(value.id);
          }}
        >
          <div className="text">{value.name}</div>
          <Show if={!_.isEmpty(toggleDentistSelected[value.id])}>
            <div className="arrow">✔</div>
          </Show>
        </div>,
      );
    });

    return (
      <div>
        <div className="input-group search">
          <span className="input-group-addon">
            <FilledImage color={"#aaa"} src={MenuImage} className="zoom-2-5x" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="search"
            value={searchDentist}
            onChange={this.props.remindersActions.searchDentist}
          />
        </div>
        <div className="list-group">
          {list}
          <Show if={fetchingDentistList}>
            <ReactSpinner />
          </Show>
        </div>
      </div>
    );
  }
}
export default enhancer(RemindersChooseDentistAll);
