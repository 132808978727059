import _ from "lodash";

import moment from "moment";
import WaitingListApi from "../api-legacy/WaitingListApi";
import * as ActionType from "../actionTypes/waitingListActionTypes";
import * as calendarActions from "./calendarActions";

import Utils from "../helpers/Utils";
import { startLoader, stopLoader, showMessage } from "./loaderActions";
import * as remindersActions from "./remindersActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import AppointmentApi from "../api-legacy/AppointmentApi";
import {
  MISSED_CANCELLED_APPOINTMENT_SET_TYPE_ERROR,
  MISSED_CANCELLED_APPOINTMENT_SET_TYPE_START,
  MISSED_CANCELLED_APPOINTMENT_SET_TYPE_SUCCESS,
  NOT_INTERESTED_APPOINTMENT_ERROR,
  NOT_INTERESTED_APPOINTMENT_START,
  NOT_INTERESTED_APPOINTMENT_SUCCESS,
  SET_MISSED_CANCELLED_LIST_ACTIVE_TAB,
} from "./appointmentActions";

export function changeBookedAppointmentHandler(values) {
  return {
    type: ActionType.CHANGE_BOOKED_APPOINTMENT_DATE,
    values,
  };
}

export function bookedAppointmentDefaultStateHandler() {
  return {
    type: ActionType.CHANGE_BOOKED_APPOINTMENT_DATE_TO_DEFAULT,
  };
}

export function requestedDeleteConfirmModalShow(id) {
  return {
    type: ActionType.REQUESTED_DELETE_CONFIRM_MODAL_SHOW,
    id,
  };
}

export function requestedDeleteConfirmModalHide() {
  return {
    type: ActionType.REQUESTED_DELETE_CONFIRM_MODAL_HIDE,
  };
}

export function requestedDeleteItem(id) {
  return function (dispatch, getState) {
    const {
      waitingList: { bookedAppointmentStartDate: startDate, bookedAppointmentEndDate: endDate },
    } = getState();

    dispatch(startLoader());

    return dispatch(requestedDeleteItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(requestedDeleteConfirmModalHide());
        dispatch(getRequestedAppointments({ startDate, endDate }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getWaitingList(values) {
  return function (dispatch, getState) {
    const {
      waitingList: { fromDate, toDate },
    } = getState();

    const data = {
      excludeCreatedSourceCode: "FROM_FOLLOW_UP",
      start: 0,
      limit: 50,
      categoryIds: values && (values.categoryIds || []).map((item) => item.value).join(","),
      periodEnd: Utils.getEndDay(toDate),
      periodStart: Utils.getStartDay(fromDate),
      doctorIds:
        values &&
        (values.doctorIds || [])
          .filter((item) => item.value)
          .map((item) => item.value)
          .join(","),
    };

    dispatch(startLoader());

    return dispatch(getWaitingListFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getAppointmentType() {
  return function (dispatch, getState) {
    const {
      reminders: { categoryList },
    } = getState();

    if (_.isEmpty(categoryList)) {
      dispatch(startLoader());

      return dispatch(remindersActions.getCategoryList())
        .then(() => dispatch(stopLoader()))
        .catch((error) => dispatch(stopLoader(error)));
    }
  };
}

export function getMissedCancelledList(values, start) {
  return (dispatch, getState) => {
    const {
      waitingList: { missedCancelledList, missedCancelledFetching, missedCancelledListTotal },
    } = getState();
    if (
      (!start === 0 && missedCancelledList.length === missedCancelledListTotal) ||
      missedCancelledFetching
    ) {
      return;
    }
    const data = {
      // futureTypeCode: values.futureTypeCode.value !== -1 ? values.futureTypeCode.value : null,
      limit: 100,
      // notInterested: false,
      doctorIds:
        (values.doctorIds || [])
          .filter((x) => x.value !== 0)
          .map((x) => x.value)
          .join(",") || undefined,
    };
    if (values) {
      if (!values.statusCodes.length) {
        dispatch(showMessage("status is required"));
        return;
      }
      data.clinicId = values.clinicId;
      data.noAnswer = values.noAnswer;
      data.willCall = values.willCall;
      data.requestedReschedule = values.requestedReschedule;
      data.notInterested = values.notInterested;
      data.periodStart = getCompanyStartOfDay(values.periodStart);
      data.periodEnd = getCompanyEndOfDay(values.periodEnd);
      data.start = start === 0 ? start : missedCancelledList.length;

      data.statusCodes = values.statusCodes.map((v) => v.value).join(",") || undefined;
      data.categoryIds = values.categoryIds.map((v) => v.value).join(",") || undefined;
      dispatch({
        type: ActionType.SET_MISSED_CANCELLED_LIST_FORM_VALUES,
        values,
      });
    } else {
      const {
        waitingList: { missedCancelledFormValues },
      } = getState();
      data.clinicId = missedCancelledFormValues?.clinic?.value;
      data.periodStart = getCompanyStartOfDay(missedCancelledFormValues.periodStart);
      data.periodEnd = getCompanyEndOfDay(missedCancelledFormValues.periodEnd);
      data.start = missedCancelledList.length;
      data.doctorIds =
        (missedCancelledFormValues.doctorIds || [])
          .filter((x) => x.value !== 0)
          .map((x) => x.value)
          .join(",") || undefined;

      data.statusCodes =
        missedCancelledFormValues.statusCodes.map((v) => v.value).join(",") || undefined;
    }

    return dispatch(getMissedCancelledListApi(data))
      .then((response) => {
        if (response && response.data) {
          return data;
        }
      })
      .catch((error) => dispatch(showMessage(error)));
  };
}

export const cleanUpMissedCancelledList = (values, futureTypeCode) => {
  return (dispatch, getState) => {
    const {
      waitingList: { missedCancelledList },
    } = getState();
    const data = {
      futureTypeCode,
      doctorIds:
        (values.doctorIds || [])
          .filter((x) => x.value !== 0)
          .map((x) => x.value)
          .join(",") || undefined,
    };
    if (values) {
      if (!values.statusCodes.length) {
        dispatch(showMessage("status is required"));
        return;
      }
      data.clinicId = values.clinicId;
      data.periodStart = getCompanyStartOfDay(values.periodStart);
      data.periodEnd = getCompanyEndOfDay(values.periodEnd);

      data.statusCodes = values.statusCodes.map((v) => v.value).join(",") || undefined;
      dispatch({
        type: ActionType.SET_MISSED_CANCELLED_LIST_FORM_VALUES,
        values,
      });
    } else {
      const {
        waitingList: { missedCancelledFormValues },
      } = getState();
      data.clinicId = missedCancelledFormValues.clinicId;
      data.periodStart = getCompanyStartOfDay(missedCancelledFormValues.periodStart);
      data.periodEnd = getCompanyEndOfDay(missedCancelledFormValues.periodEnd);
      data.start = missedCancelledList.length;
      data.doctorIds =
        (missedCancelledFormValues.doctorIds || [])
          .filter((x) => x.value !== 0)
          .map((x) => x.value)
          .join(",") || undefined;

      data.statusCodes =
        missedCancelledFormValues.statusCodes.map((v) => v.value).join(",") || undefined;
    }

    return dispatch(cleanUpMissedCancelledListApi(data))
      .then((response) => {
        if (response && response.data) {
          return data;
        }
      })
      .catch((error) => dispatch(showMessage(error)));
  };
};

export function missedCancelledListInit(data) {
  return (dispatch, getState) => {
    const {
      waitingList: { periodStart, periodEnd, statusCodes, missedCancelledFormValues },
    } = getState();
    dispatch(startLoader());
    const transfer = {
      ...data,
      periodStart: getCompanyStartOfDay(periodStart),
      periodEnd: getCompanyEndOfDay(periodEnd),
      start: 0,
      limit: 25,
      // futureTypeCode: futureTypeCode.value !== -1 ? futureTypeCode.value : null,
      statusCodes: statusCodes.map((c) => c.value).join(",") || undefined,
      // notInterested: false,
    };
    transfer.doctorIds =
      (missedCancelledFormValues.doctorIds || [])
        .filter((x) => x.value !== 0)
        .map((x) => x.value)
        .join(",") || undefined;
    transfer.clinicId = missedCancelledFormValues?.clinic?.value;
    if (missedCancelledFormValues.statusCodes) {
      transfer.statusCodes =
        missedCancelledFormValues.statusCodes.map((c) => c.value).join(",") || undefined;
    }

    if (data.periodEnd) transfer.periodEnd = data.periodEnd;
    if (data.periodStart) transfer.periodStart = data.periodStart;

    return dispatch(getMissedCancelledListApi(transfer))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setFromDate(data) {
  return {
    type: ActionType.SET_WAITING_LIST_FROM_DATE,
    date: data,
  };
}
export function setToDate(data) {
  return {
    type: ActionType.SET_WAITING_LIST_TO_DATE,
    date: data,
  };
}

export function setDoctor(doctor) {
  return {
    type: ActionType.WAITING_LIST_SET_DOCTOR,
    doctor,
  };
}

export function requestedInfoShow(id) {
  return {
    type: ActionType.REQUESTED_APPOINTMENTS_WAITING_LIST_INFO,
    id,
  };
}

export function getRequestedAppointmentWaitingList() {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 25,
      statusCode: "WAITING",
      sortField: "created_date_desc",
    };

    return dispatch(requestedAppointmentsWaitingListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function loadRequestedAppointmentWaitingList() {
  return (dispatch, getState) => {
    const {
      // form: { RequestedAppointmentsListForm },
      waitingList: { requestedWaitingList, requestedWaitingTotal, requestedWaitingFatching },
    } = getState();

    if (requestedWaitingList.length === requestedWaitingTotal || requestedWaitingFatching) {
      return;
    }

    // const periodStart =
    //   RequestedAppointmentsListForm && RequestedAppointmentsListForm.values
    //     ? Utils.getDayStart(RequestedAppointmentsListForm.values.startDate)
    //     : Utils.getDayStart(moment());
    // const periodEnd =
    //   RequestedAppointmentsListForm && RequestedAppointmentsListForm.values
    //     ? Utils.getDayEnd(RequestedAppointmentsListForm.values.endDate)
    //     : Utils.getDayEnd(moment());

    const data = {
      start: requestedWaitingList.length,
      limit: 25,
      statusCode: "WAITING",
      sortField: "created_date_desc",
    };

    return dispatch(requestedAppointmentsWaitingListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function getRequestedAppointments(values) {
  return (dispatch) =>
    Promise.all([
      dispatch(calendarActions.getDentistWithPoints()),
      dispatch(getRequestedAppointmentScheduledList(values)),
      dispatch(getRequestedAppointmentWaitingList()),
    ]).catch((error) => dispatch(showMessage(error)));
}

export function getRequestedAppointmentScheduledList(values) {
  return (dispatch, getState) => {
    const {
      waitingList: { isFilterByCreatedDate },
    } = getState();
    const periodStart = values ? +getCompanyStartOfDay(values.startDate) : +moment().startOf("day");
    const periodEnd = values ? +getCompanyEndOfDay(values.endDate) : +moment().endOf("day");

    const data = {
      start: 0,
      limit: 20,
      statusCode: "SCHEDULED",
      sortField: "created_date_desc",
    };

    if (isFilterByCreatedDate) {
      data.periodStart = periodStart;
      data.periodEnd = periodEnd;
    }

    return dispatch(requestedAppointmentsScheduledListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function loadRequestedAppointmentScheduledList() {
  return (dispatch, getState) => {
    const {
      form: { RequestedAppointmentsListForm },
      waitingList: { requestedScheduledList, requestedScheduledTotal, requestedScheduledFatching },
    } = getState();

    if (requestedScheduledList.length === requestedScheduledTotal || requestedScheduledFatching) {
      return;
    }

    const periodStart =
      RequestedAppointmentsListForm && RequestedAppointmentsListForm.values
        ? Utils.getDayStart(RequestedAppointmentsListForm.values.startDate)
        : Utils.getDayStart(moment());
    const periodEnd =
      RequestedAppointmentsListForm && RequestedAppointmentsListForm.values
        ? Utils.getDayEnd(RequestedAppointmentsListForm.values.endDate)
        : Utils.getDayEnd(moment());

    const data = {
      start: requestedScheduledList.length,
      limit: 25,
      statusCode: "SCHEDULED",
      periodStart,
      periodEnd,
    };

    return dispatch(requestedAppointmentsScheduledListApi(data)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

function requestedAppointmentsWaitingListApi(data) {
  return {
    api: WaitingListApi.requestedAppointments.getList,
    types: [
      ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_START,
      ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_SUCCESS,
      ActionType.REQUESTED_APPOINTMENTS_GET_WAITING_LIST_ERROR,
    ],
    data,
  };
}

function requestedAppointmentsScheduledListApi(data) {
  return {
    api: WaitingListApi.requestedAppointments.getList,
    types: [
      ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_START,
      ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_SUCCESS,
      ActionType.REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_ERROR,
    ],
    data,
  };
}

function requestedDeleteItemApi(data) {
  return {
    api: WaitingListApi.requestedAppointments.deleteItem,
    types: [
      ActionType.REQUESTED_APPOINTMENTS_DELETE_ITEM_START,
      ActionType.REQUESTED_APPOINTMENTS_DELETE_ITEM_SUCCESS,
      ActionType.REQUESTED_APPOINTMENTS_DELETE_ITEM_ERROR,
    ],
    data,
  };
}

function getMissedCancelledListApi(data) {
  return {
    api: AppointmentApi.getList,
    types: [
      ActionType.GET_MISSED_CANCELLED_LIST_START,
      ActionType.GET_MISSED_CANCELLED_LIST_SUCCESS,
      ActionType.GET_MISSED_CANCELLED_LIST_ERROR,
    ],
    data,
  };
}

function cleanUpMissedCancelledListApi(data) {
  return {
    api: AppointmentApi.cleanUpMissedCancelledList,
    types: [
      ActionType.CLEAN_UP_MISSED_CANCELLED_LIST_START,
      ActionType.CLEAN_UP_MISSED_CANCELLED_LIST_SUCCESS,
      ActionType.CLEAN_UP_MISSED_CANCELLED_LIST_ERROR,
    ],
    data,
  };
}

function getWaitingListFn(data) {
  return {
    api: WaitingListApi.getList,
    types: [
      ActionType.GET_WAITING_LIST_START,
      ActionType.GET_WAITING_LIST_SUCCESS,
      ActionType.GET_WAITING_LIST_ERROR,
    ],
    data,
  };
}

export function missedCancelledNotInterestedAppointment(data) {
  return (dispatch) =>
    dispatch({
      api: AppointmentApi.setNotInterestedAppointment,
      types: [
        NOT_INTERESTED_APPOINTMENT_START,
        NOT_INTERESTED_APPOINTMENT_SUCCESS,
        NOT_INTERESTED_APPOINTMENT_ERROR,
      ],
      data,
    });
}

export const missedCancelledAppointmentSetType = (data) => (dispatch) => {
  let api;
  if (data.willCall) {
    api = AppointmentApi.setWillCall;
  } else if (data.requestedReschedule) {
    api = AppointmentApi.setRequestedReschedule;
  } else {
    api = AppointmentApi.setNoAnswer;
  }
  return dispatch({
    api,
    types: [
      MISSED_CANCELLED_APPOINTMENT_SET_TYPE_START,
      MISSED_CANCELLED_APPOINTMENT_SET_TYPE_SUCCESS,
      MISSED_CANCELLED_APPOINTMENT_SET_TYPE_ERROR,
    ],
    data,
  });
};

export const setMissedCancelledListActiveTab = (activeTab) => (dispatch) =>
  dispatch({ type: SET_MISSED_CANCELLED_LIST_ACTIVE_TAB, activeTab });
