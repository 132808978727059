import React, { useEffect, useState } from "react";
import LC from "literallycanvas";
import Show from "../../../../widgets/Show";

const FormCanvas = ({ form, setForm, questionId, editMode, visible, setOverlay, canvasImage }) => {
  const item = form.questions.find((item) => item.id === questionId);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(visible || false);
  const [globalCanvas, setGlobalCanvas] = useState(null);

  useEffect(() => {
    if (item?.id) {
      setValue(item?.answerFile || item?.answerPhoto?.url || item?.questionPhoto?.url);
    }
  }, [item]);

  const onSave = (photo) => {
    setShow(false);
    setOverlay({ visible: false });
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.answerFile = photo;
        }
        return question;
      }),
    });
  };

  const onClose = () => {
    setShow(false);
    setOverlay({ visible: false });
  };

  const image = new Image();
  image.crossOrigin = "Anonymous";
  image.src = canvasImage;
  const imageWidth = image.width;
  const imageHeight = image.height;

  if (!show) {
    return (
      <img
        id="canvasId"
        alt="canvas"
        className="medical-fill-in-form-answer"
        src={value}
        onClick={() => setShow(editMode)}
      />
    );
  }

  return (
    <>
      <div
        className="d-flex flex-column align-items-end"
        style={{ alignItems: "stretch", flex: 1, overflow: "auto" }}
      >
        <div id="canvas-form" style={{ flex: 1 }}>
          <Show if={value}>
            <LC.LiterallyCanvasReactComponent
              watermarkImage={image}
              onInit={(lc) => setGlobalCanvas(lc)}
              imageURLPrefix="images/literallycanvas/img"
              imageSize={{ width: imageWidth, height: imageHeight }}
            />
          </Show>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-default mt-3 mr-3" onClick={onClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary mt-3"
          onClick={() => {
            onSave(
              globalCanvas
                .getImage({
                  scale: 1,
                  margin: { top: 0, right: 0, bottom: 0, left: 0 },
                })
                .toDataURL(),
            );
          }}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default FormCanvas;
