import { Link } from "react-router-dom";
import { Route, Switch, useHistory } from "react-router";
import React, { useEffect, useState } from "react";

import { Routes } from "../constants/Routes";
import Back from "../components/widgets/Back";
import About from "../components/settings/About";
import IpAddresses from "../components/main/ipAddresses";
import { SettingsPasswordDialog } from "../components/settings/SettingsPasswordDialog";
import Appointment from "../components/settings/Appointment";
import TimeView from "../components/settings/TimeView";
import Version from "../components/settings/Version";
import AppointmentStatus from "../components/settings/AppointmentStatus";
import BlockedTimeSlot from "../components/settings/BlockedTimeSlot";
import TreatmentGroupping from "../components/settings/TreatmentGroupping";
import AppointmentStatusColor from "../components/settings/AppointmentStatusColor";
import AppointmentStatusOther from "../components/settings/AppointmentStatusOther";
import AppointmentType from "../components/settings/AppointmentType";
import AppointmentTypeColor from "../components/settings/AppointmentTypeColor";
import AppointmentTypeOther from "../components/settings/AppointmentTypeOther";
import BlockedTimeSlotColor from "../components/settings/BlockedTimeSlotColor";
import BlockedTimeSlotOther from "../components/settings/BlockedTimeSlotOther";
import AppointmentDoctor from "../components/settings/AppointmentDoctor";
import AppointmentDoctorOther from "../components/settings/AppointmentDoctorOther";
import AppointmentColorType from "../components/settings/AppointmentColorType";
import AppointmentDoctorColor from "../components/settings/AppointmentDoctorColor";
import AppointmentCalendar from "../components/settings/AppointmentCalendar";
import AppointmentCalendarColumn from "../components/settings/AppointmentCalendarColumn";
import AppointmentCalendarRow from "../components/settings/AppointmentCalendarRow";
import TreatmentSideBarView from "../components/settings/TreatmentSideBarView";
import QuickMode from "../components/settings/QuickMode";
import PostedBy from "../components/settings/PostedBy";
import TransactionGroupBy from "../components/settings/TransactionGroupBy";
import AppointmentNote from "../components/settings/AppointmentNote";
import AppointmentHideButtons from "../components/settings/AppointmentHideButtons";
import { useDispatch } from "react-redux";
import { openSimpleModal } from "../actions/simpleModalActions";
import MachineFolder from "../components/settings/MachineFolder";
import { IS_ELECTRON } from "../constants/AppConstants";

export default function SettingsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showIpModal, setShowIpModal] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (count > 0 && count < 10) {
      timeout = setTimeout(() => setCount(0), 1000);
    }

    if (count === 10) {
      setCount(0);
      setShowIpModal(true);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [count]);

  return (
    <div className="settings-page">
      <div className="settings-bar">
        <div className="settings-bar-title">
          <Back onClick={() => history.goBack()} />
          <div className="name" onClick={() => setCount((x) => x + 1)}>
            Settings
          </div>
        </div>
        <div className="list-group">
          <Link to={Routes.Settings} className="list-group-item">
            About
          </Link>
          <Link to={Routes.SettingsAppointment} className="list-group-item">
            Appointment settings
          </Link>
          <Link to={Routes.SettingsTreatmentSideBarView} className="list-group-item">
            Treatment sidebar view settings
          </Link>
          <Link to={Routes.SettingsQuickMode} className="list-group-item">
            Quick Mode
          </Link>
          <Link to={Routes.SettingsPostedBy} className="list-group-item">
            Posted By
          </Link>
          <Link to={Routes.SettingsTransactionGroupBy} className="list-group-item">
            Transaction Group By
          </Link>
          <Link to={Routes.SettingsTreatmentGroupping} className="list-group-item">
            Treatment Groupping
          </Link>
          {IS_ELECTRON && (
            <Link to={Routes.SettingsXRayFolder} className="list-group-item">
              X-ray Folder
            </Link>
          )}
        </div>
        <SettingsPasswordDialog
          show={showIpModal}
          onClose={() => setShowIpModal(false)}
          openIp={() => {
            dispatch(
              openSimpleModal({
                body: (
                  <div className="d-flex flex-column align-center, justify-content-between">
                    <div>Available IP Address</div>
                    <IpAddresses />
                  </div>
                ),
              }),
            );
            setShowIpModal(false);
          }}
        />
      </div>
      <Switch>
        <Route exact={true} component={About} path={Routes.Settings} />
        <Route component={Appointment} path={Routes.SettingsAppointment} />
        <Route component={TimeView} path={Routes.SettingsTimeView} />
        <Route component={Version} path={Routes.SettingsVersion} />
        <Route component={AppointmentStatus} path={Routes.SettingsAppointmentStatus} />
        <Route component={BlockedTimeSlot} path={Routes.SettingsBlockedTimeSlot} />
        <Route component={TreatmentGroupping} path={Routes.SettingsTreatmentGroupping} />

        <Route component={AppointmentStatusColor} path={Routes.SettingsAppointmentStatusColor} />
        <Route component={AppointmentStatusOther} path={Routes.SettingsAppointmentStatusOther} />
        <Route component={AppointmentType} path={Routes.SettingsAppointmentType} />
        <Route component={AppointmentTypeColor} path={Routes.SettingsAppointmentTypeColor} />
        <Route component={AppointmentTypeOther} path={Routes.SettingsAppointmentTypeOther} />
        <Route component={AppointmentDoctor} path={Routes.SettingsAppointmentDoctor} />
        <Route component={AppointmentDoctorColor} path={Routes.SettingsAppointmentDoctorColor} />
        <Route component={AppointmentDoctorOther} path={Routes.SettingsAppointmentDoctorOther} />
        <Route component={AppointmentColorType} path={Routes.SettingsAppointmentColorType} />
        <Route component={AppointmentCalendar} path={Routes.SettingsAppointmentCalendar} />
        <Route
          component={AppointmentCalendarColumn}
          path={Routes.SettingsAppointmentCalendarColumn}
        />
        <Route component={AppointmentCalendarRow} path={Routes.SettingsAppointmentCalendarRow} />
        <Route component={TreatmentSideBarView} path={Routes.SettingsTreatmentSideBarView} />
        <Route component={QuickMode} path={Routes.SettingsQuickMode} />
        <Route component={PostedBy} path={Routes.SettingsPostedBy} />
        <Route component={TransactionGroupBy} path={Routes.SettingsTransactionGroupBy} />
        <Route component={AppointmentNote} path={Routes.SettingsAppointmentNote} />
        <Route component={AppointmentHideButtons} path={Routes.SettingsAppointmentHideButtons} />
        <Route component={BlockedTimeSlotColor} path={Routes.SettingsBlockedTimeSlotColor} />
        <Route component={BlockedTimeSlotOther} path={Routes.SettingsBlockedTimeSlotColorOther} />
        <Route component={MachineFolder} path={Routes.SettingsXRayFolder} />
      </Switch>
    </div>
  );
}
