import React, { useState } from "react";
import { Field } from "formik";
import { SelectPicker } from "../select-picker/SelectPicker";

const RADIO_LIST_TYPE = "RADIO_LIST";
const CHECKBOX_LIST_TYPE = "CHECKBOX_LIST";

const InputComponent = ({ value, onChange, field }) => {
  const [inputValue, setInputValue] = useState(value || "");
  return (
    <input
      type="text"
      className="form-control"
      value={inputValue}
      onBlur={() => onChange(field.name, inputValue)}
      onChange={({ target: { value } }) => setInputValue(value)}
    />
  );
};

const CheckboxComponent = ({ options, field, value: formValue = "", setFieldValue }) => {
  const value = formValue.split(",").filter((x) => Boolean(x));

  return (
    <div className="d-flex">
      {options.map((x, idx) => (
        <div key={idx} className="mr-3 d-flex align-items-center">
          <input
            value={x}
            type="checkbox"
            id={`${field.name}_${idx}`}
            checked={value.indexOf(x) >= 0}
            onChange={({ target }) => {
              if (target.checked && value.indexOf(x) === -1) {
                value.push(x);

                setFieldValue(field.name, value.join(","));
              }

              if (!target.checked && value.indexOf(x) >= 0) {
                setFieldValue(field.name, value.filter((item) => item !== x).join(","));
              }
            }}
          />
          <label className="ml-1 mb-0" htmlFor={`${field.name}_${idx}`}>
            {x}
          </label>
        </div>
      ))}
    </div>
  );
};

export const PatientCustomInput = ({ inputType, options, name, value, setFieldValue }) => {
  switch (inputType) {
    case RADIO_LIST_TYPE: {
      return (
        <Field
          name={name}
          component={() => {
            return (
              <SelectPicker
                options={options.map((x) => ({
                  label: x,
                  value: x,
                }))}
                value={{ value: value }}
                onChange={(x) => {
                  setFieldValue(name, x.value);
                }}
              />
            );
          }}
        />
      );
    }

    case CHECKBOX_LIST_TYPE: {
      return (
        <Field
          name={name}
          options={options}
          value={value}
          setFieldValue={setFieldValue}
          component={CheckboxComponent}
        />
      );
    }

    default:
      return (
        <Field
          id={name}
          name={name}
          value={value}
          onChange={(name, value) => setFieldValue(name, value)}
          component={InputComponent}
        />
      );
  }
};
