import React from "react";
import { capitalize } from "lodash";

import { SelectField } from "../form/SelectField";

export enum WeeklyTypes {
  None = "-- --",
  Monday = "MONDAY",
  Friday = "FRIDAY",
  Sunday = "SUNDAY",
  Tuesday = "TUESDAY",
  Thursday = "THURSDAY",
  Saturday = "SATURDAY",
  Wednesday = "WEDNESDAY",
}

export const WeeklyOptions = [
  {
    value: undefined,
    label: WeeklyTypes.None,
  },
  {
    value: WeeklyTypes.Monday,
    label: capitalize(WeeklyTypes.Monday),
  },
  {
    value: WeeklyTypes.Tuesday,
    label: capitalize(WeeklyTypes.Tuesday),
  },
  {
    value: WeeklyTypes.Wednesday,
    label: capitalize(WeeklyTypes.Wednesday),
  },
  {
    value: WeeklyTypes.Thursday,
    label: capitalize(WeeklyTypes.Thursday),
  },
  {
    value: WeeklyTypes.Friday,
    label: capitalize(WeeklyTypes.Friday),
  },
  {
    value: WeeklyTypes.Saturday,
    label: capitalize(WeeklyTypes.Saturday),
  },
  {
    value: WeeklyTypes.Sunday,
    label: capitalize(WeeklyTypes.Sunday),
  },
];

export function BlockedTimeSlotWeeklyForm() {
  return (
    <div className="row d-flex align-items-center mb-2">
      <div className="col-sm-2 d-flex p-0 justify-content-end">Repeats on:</div>
      <div className="col-sm-8">
        <SelectField
          type="number"
          isMulti={true}
          placeholder="-- --"
          name="weekDays"
          options={WeeklyOptions}
        />
      </div>
      <div className="col-sm-2" />
    </div>
  );
}
