import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";
import ChartPostOperation from "./ChartPostOperation";
import ChartLabOrder from "./ChartLabOrder";
import ChartLabOrderPDF from "./ChartLabOrderPDF";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartDocumentsAssignedModal extends React.PureComponent {
  render() {
    const { document, documentsAssignedInsideModalVisible } = this.props.chartSideSeries;

    let $body = null;

    if (!document.templateType) return null;

    switch (document.templateType.code) {
      case "POST_OPERATION":
        $body = (
          <ChartPostOperation
            chartSideSeries={this.props.chartSideSeries}
            patient={this.props.patient}
          />
        );
        break;
      case "LAB_ORDER":
        $body = (
          <ChartLabOrder
            chartSideSeries={this.props.chartSideSeries}
            patient={this.props.patient}
            member={this.props.member}
          />
        );
        break;
      case "CONSENT_FORM":
        $body = (
          <Button onClick={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}>
            Back
          </Button>
        );
        break;
      case "CLINIC_NOTE":
        $body = (
          <Button onClick={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}>
            Back
          </Button>
        );
        break;
      case "LAB_ORDER_PDF":
        $body = <ChartLabOrderPDF chartSideSeries={this.props.chartSideSeries} />;
        break;
    }

    return (
      <Modal
        show={documentsAssignedInsideModalVisible}
        className="documents-assigned-inside-modal"
        onHide={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}
        keyboard={false}
      >
        {$body}
      </Modal>
    );
  }
}

export default enhancer(ChartDocumentsAssignedModal);
