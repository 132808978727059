import React from "react";
import { Button } from "react-bootstrap";

import { Modal } from "../ui/Modal";

export function AppointmentNoteModal({ show, note, onClose }) {
  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={onClose}
      title={<div>Note</div>}
      actions={
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      }
    >
      <div className="appointment-note-modal-text">{note}</div>
    </Modal>
  );
}
