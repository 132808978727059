import SelectItem from "../models/SelectItem";

export const TransactionStatus = {
  PLANNED: "DentalTransactionStatus_PLANNED",
  CANCELLED: "DentalTransactionStatus_CANCELED",
  COMPLETED: "DentalTransactionStatus_COMPLETED",
  PROVISIONAL: "DentalTransactionStatus_PROVISIONAL",
};
export const TransactionStatusSelectItem = {
  PLANNED: new SelectItem(TransactionStatus.PLANNED, 1, TransactionStatus.PLANNED),
  CANCELLED: new SelectItem(TransactionStatus.CANCELLED, 2, TransactionStatus.CANCELLED),
  COMPLETED: new SelectItem(TransactionStatus.COMPLETED, 3, TransactionStatus.COMPLETED),
  PROVISIONAL: new SelectItem(TransactionStatus.PROVISIONAL, 3, TransactionStatus.PROVISIONAL),
};
export const TransactionType = {
  DIAGNOSIS: "DIAGNOSIS",
  TREATMENT: "TREATMENT",
  EXISTING: "EXISTING",
  PRODUCT: "PRODUCT",
};
export const TransactionTypeSelectItem = {
  DIAGNOSIS: new SelectItem(TransactionType.DIAGNOSIS, 1, TransactionType.DIAGNOSIS),
  TREATMENT: new SelectItem(TransactionType.TREATMENT, 2, TransactionType.TREATMENT),
  EXISTING: new SelectItem(TransactionType.EXISTING, 3, TransactionType.EXISTING),
  PRODUCT: new SelectItem(TransactionType.PRODUCT, 4, TransactionType.PRODUCT),
};

export const EDITABLE_KEYS_NAME = ["treatmentPlan", "treatmentSession", "unit"];

let k = 0.5;
const arr1 = [];
const arr2 = [];
while (k <= 10) {
  if (k - Math.round(k) == 0) {
    arr1.push(new SelectItem(k, k, k));
  }
  arr2.push(new SelectItem(k, k, k));
  k += 0.5;
}

export const EDITABLE_KEYS = ["treatmentPlan", "treatmentSession", "unit", "assignedDoctor"];
export const EDITABLE_KEYS_OPTIONS = [arr1, arr1, arr2, []];
