import { httpClient } from "./BaseApi";
import { parserCallback } from "../helpers/Parser";
import { getSelectPickerAsyncOptions } from "../actions/sessionActions";

const DefaultData = {
  d_type: "iphone",
  scope: "read,write",
  work_mode: "default",
  grant_type: "password",
  client_id: "dental-ipad-client",
};

DefaultData.d_token = localStorage.getItem("d_token");

export { DefaultData };

const LOGOUT_URL = "api/main/logout.json";
export const GET_TOKEN_URL = "/oauth/token";
const GET_DEVICE_INFO_URL = "api/main/device/info.json";
const GET_SESSION_DATA_URL = "api/main/session/data.json";
const SET_DEVICE_CLINIC_URL = "api/main/device/clinic.json";
const CHANGE_TREATMENT_GROUPING_TYPE = "api/member/treatmentGroupingType.json";

const MainApi = {
  getDeviceInfo: (
    params = {
      deviceType: DefaultData.d_type,
      deviceToken: DefaultData.d_token,
    },
  ) =>
    httpClient.get(GET_DEVICE_INFO_URL, {
      headers: { Authorization: "" },
      params,
    }),
  getToken: (data) => {
    const params = { ...DefaultData, ...data };

    return httpClient
      .post(GET_TOKEN_URL, {
        params,
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then(parserCallback);
  },
  logout: (params) => httpClient.get(LOGOUT_URL, { params }),
  getSessionData: () => httpClient.get(GET_SESSION_DATA_URL),
  setDeviceClinic: (data) => httpClient.put(SET_DEVICE_CLINIC_URL, { data }),
  getSelectPickerAsyncOptions: (params) => httpClient.get(params.url, { params }),
  changeTreatmentGroupingType: (data) => httpClient.put(CHANGE_TREATMENT_GROUPING_TYPE, { data }),
};

export default MainApi;
