import React from "react";

import { SvgIcon } from "../../widgets/SvgIcon";

export function LowerRowBackgroundIcon() {
  return (
    <SvgIcon width={1280} height={150}>
      <line strokeOpacity="0.5" x1="0" y1="53" x2="1280" y2="53" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="58" x2="1280" y2="58" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="63" x2="1280" y2="63" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="68" x2="1280" y2="68" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="73" x2="1280" y2="73" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="78" x2="1280" y2="78" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="83" x2="1280" y2="83" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="88" x2="1280" y2="88" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="93" x2="1280" y2="93" stroke="#0000ff" strokeWidth="2" />
      <line strokeOpacity="0.5" x1="0" y1="98" x2="1280" y2="98" stroke="#0000ff" strokeWidth="2" />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="103"
        x2="1280"
        y2="103"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="108"
        x2="1280"
        y2="108"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="113"
        x2="1280"
        y2="113"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="118"
        x2="1280"
        y2="118"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="123"
        x2="1280"
        y2="123"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="128"
        x2="1280"
        y2="128"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="133"
        x2="1280"
        y2="133"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="138"
        x2="1280"
        y2="138"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="143"
        x2="1280"
        y2="143"
        stroke="#0000ff"
        strokeWidth="2"
      />
      <line
        strokeOpacity="0.5"
        x1="0"
        y1="148"
        x2="1280"
        y2="148"
        stroke="#0000ff"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
