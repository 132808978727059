import React from "react";
import FilledImage from "../../widgets/FilledImage";

export default function condition({ property, color, toothImage }) {
  if (!property) {
    return null;
  }

  const src = require(`../../../assets/images/conditions/apecictomy/apecictomy_${toothImage}@2x.png`)
    .default;

  return (
    <div className="condition-wrap tooth-image-condition">
      <FilledImage src={src} color={color} className="zoom-2x" />
    </div>
  );
}
