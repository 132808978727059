import _, { isString } from "lodash";
import React, { isValidElement } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Back from "../widgets/Back";
import Show from "../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import LogoImage from "../../assets/images/clinic_logo.png";

export class AppLayout extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fetching: PropTypes.bool,
    withLogo: PropTypes.bool,
    scrollable: PropTypes.bool,
    children: PropTypes.node,
    onScrollEnd: PropTypes.func,
    onBackClick: PropTypes.func,
    rightComponent: PropTypes.element,
    backgroundImage: PropTypes.string,
    disableBackButton: PropTypes.bool,
    backButtonTitle: PropTypes.string,
    contentClassName: PropTypes.string,
    rightComponentClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    className: PropTypes.string,
  };

  onScroll = ({ target }) => {
    const { fetching, onScrollEnd, scrollable } = this.props;

    if (onScrollEnd && scrollable) {
      const scrollBottom = target.scrollTop + target.offsetHeight === target.scrollHeight;

      if (scrollBottom) {
        onScrollEnd({ fetching });
      }
    }
  };

  shouldComponentUpdate(nextProps) {
    const {
      withLogo: nextWithLogo,
      fetching: nextFetching,
      children: nextChildren,
      scrollable: nextScrollable,
      backgroundImage: nextBackgroundImage,
      disableBackButton: nextDisableBackButton,
    } = nextProps;
    const {
      withLogo: prevWithLogo,
      fetching: prevFetching,
      children: prevChildren,
      scrollable: prevScrollable,
      backgroundImage: prevBackgroundImage,
      disableBackButton: prevDisableBackButton,
    } = this.props;

    return (
      nextWithLogo !== prevWithLogo ||
      nextFetching !== prevFetching ||
      nextScrollable !== prevScrollable ||
      nextBackgroundImage !== prevBackgroundImage ||
      nextDisableBackButton !== prevDisableBackButton ||
      !_.isEqual(prevChildren, nextChildren)
    );
  }

  render() {
    const {
      title,
      children,
      withLogo,
      fetching,
      className,
      scrollable,
      onBackClick,
      titleClassName,
      rightComponent,
      backButtonTitle,
      backgroundImage,
      contentClassName,
      disableBackButton,
      headerClassName,
      rightComponentClassName,
    } = this.props;

    return (
      <div className={cx("app-layout", className)}>
        <div className={cx("app-layout-header", headerClassName)}>
          {!disableBackButton && (
            <Back
              title={backButtonTitle}
              className="app-layout-back-button btn-lg"
              onClick={onBackClick}
            />
          )}

          {Boolean(rightComponent) && (
            <div
              className={cx("app-layout-right-component", rightComponentClassName)}
              onDragEnter={(event) => {
                event.preventDefault();
                const calendarForm = document.querySelector(".calendar-form");
                calendarForm.classList.add("dnd-calendar-backdrop");
              }}
            >
              {rightComponent}
            </div>
          )}

          {!!(withLogo || title) && (
            <div className="app-layout-logo">
              {!!(withLogo && !title) && <img alt="logo" height={34} src={LogoImage} />}
              {!!title && isString(title) && <span className={titleClassName}>{title}</span>}
              {!!title && isValidElement(title) && title}
            </div>
          )}
        </div>

        <div
          className={cx(
            "app-layout-content",
            { "app-layout-scrollable": scrollable },
            contentClassName,
          )}
          onScroll={this.onScroll}
        >
          {Boolean(backgroundImage) && (
            <div
              className="app-layout-background"
              style={{ backgroundImage: `url(${backgroundImage})` }}
            />
          )}

          {backgroundImage && <div className="app-layout-background-mask" />}

          <div className="app-layout-data">{children}</div>
        </div>

        <Show if={fetching && scrollable}>
          <div className="dental-react-spinner">
            <ReactSpinner config={{ scale: "0.5" }} />
          </div>
        </Show>
      </div>
    );
  }
}
