import _ from "lodash";
import React from "react";
import { SidebarFavoritesList } from "./SidebarFavoritesList";

import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import { SidebarContentContainer } from "./SidebarContentContainer";
import { SidebarContentActions } from "./SidebarContentActions";
import ChartImageSelector from "../chart/side-bar/ChartImageSelector";
import { SidebarList } from "./SidebarList";
import { SidebarItem } from "./SidebarItem";

export function SidebarDiagnosis({
  onSort,
  item,
  member,
  viewTab,
  isItem,
  initItem,
  onSearch,
  openItem,
  popStack,
  changeTypeTab,
  toothNumericType,
  loadList,
  stack = [],
  favourites,
  inAppointment,
  changeActiveTooth,
  onRequestClose,
  searchValue,
  getFavorites,
  backFromItem,
  onActionClick,
  toggleFavorite,
  chartSideSeries,
  showImageSelector,
  getSidebarDiagnosisList,
}) {
  const {
    favouritesIcons,
    activeFavouriteCode,
    imageSelectorVisible,
    activeFavouriteSeriesType,
  } = chartSideSeries;

  const isAllCodes = _.last(stack) === SidebarStackTypes.AllCodes;
  return (
    <SidebarContentContainer
      onSearch={(v) => {
        onSearch(v);
        if (isAllCodes) {
          getSidebarDiagnosisList();
        }
      }}
      searchValue={searchValue}
      isItem={isItem}
      title={isAllCodes ? "Diagnosis" : _.isEmpty(stack) ? "Favorite Diagnosis" : null}
    >
      {_.isEmpty(stack) && (
        <SidebarFavoritesList
          onSort={onSort}
          viewTab={viewTab}
          openItem={openItem}
          favoritesList={favourites}
          getFavorites={getFavorites}
          toggleFavourite={toggleFavorite}
          typeTab={SidebarTypes.Diagnosis}
          chartSideSeries={chartSideSeries}
          showImageSelector={showImageSelector}
        />
      )}

      {isItem && (
        <SidebarItem
          item={item}
          member={member}
          backFromItem={backFromItem}
          initItem={(id) => initItem(id, SidebarTypes.Diagnosis)}
          popStack={popStack}
          inAppointment={inAppointment}
          changeTypeTab={changeTypeTab}
          onRequestClose={onRequestClose}
          typeTab={SidebarTypes.Diagnosis}
          chartSideSeries={chartSideSeries}
          toothNumericType={toothNumericType}
          changeActiveTooth={changeActiveTooth}
        />
      )}

      {isAllCodes && (
        <SidebarList
          viewTab={viewTab}
          loadList={loadList}
          openItem={openItem}
          typeTab={SidebarTypes.Diagnosis}
          toggleFavorite={toggleFavorite}
          chartSideSeries={chartSideSeries}
        />
      )}

      <SidebarContentActions>
        {_.isEmpty(stack) && (
          <button
            style={{ maxWidth: "90%" }}
            className="btn btn-primary btn-block m-0"
            onClick={() => onActionClick(SidebarStackTypes.AllCodes)}
          >
            All codes
          </button>
        )}
      </SidebarContentActions>

      <ChartImageSelector
        favouritesIcons={favouritesIcons}
        seriesType={SidebarTypes.Diagnosis}
        activeFavouriteCode={activeFavouriteCode}
        imageSelectorVisible={imageSelectorVisible}
        activeFavouriteSeriesType={activeFavouriteSeriesType}
      />
    </SidebarContentContainer>
  );
}
