export const CHART_GENERAL_GET_GOAL_LIST_START = "CHART_GENERAL_GET_GOAL_LIST_START";
export const CHART_GENERAL_GET_GOAL_LIST_SUCCESS = "CHART_GENERAL_GET_GOAL_LIST_SUCCESS";
export const CHART_GENERAL_GET_GOAL_LIST_ERROR = "CHART_GENERAL_GET_GOAL_LIST_ERROR";

export const CHART_GENERAL_SAVE_GOAL_LIST_START = "CHART_GENERAL_SAVE_GOAL_LIST_START";
export const CHART_GENERAL_SAVE_GOAL_LIST_SUCCESS = "CHART_GENERAL_SAVE_GOAL_LIST_SUCCESS";
export const CHART_GENERAL_SAVE_GOAL_LIST_ERROR = "CHART_GENERAL_SAVE_GOAL_LIST_ERROR";

export const CHART_GENERAL_GET_STICKY_CATEGORY_START = "CHART_GENERAL_GET_STICKY_CATEGORY_START";
export const CHART_GENERAL_GET_STICKY_CATEGORY_SUCCESS =
  "CHART_GENERAL_GET_STICKY_CATEGORY_SUCCESS";
export const CHART_GENERAL_GET_STICKY_CATEGORY_ERROR = "CHART_GENERAL_GET_STICKY_CATEGORY_ERROR";

export const CHART_GENERAL_GET_STICKY_LIST_START = "CHART_GENERAL_GET_STICKY_LIST_START";
export const CHART_GENERAL_GET_STICKY_LIST_SUCCESS = "CHART_GENERAL_GET_STICKY_LIST_SUCCESS";
export const CHART_GENERAL_GET_STICKY_LIST_ERROR = "CHART_GENERAL_GET_STICKY_LIST_ERROR";

export const CHART_GENERAL_TOGGLE_GOAL = "CHART_GENERAL_TOGGLE_GOAL";
export const CHART_GENERAL_PUSH_GOAL = "CHART_GENERAL_PUSH_GOAL";
export const CHART_GENERAL_SET_GOAL_LIST = "CHART_GENERAL_SET_GOAL_LIST";
export const CHART_GENERAL_SET_GOAL_NOTE = "CHART_GENERAL_SET_GOAL_NOTE";

export const CHART_GENERAL_SET_STICKY_ITEM = "CHART_GENERAL_SET_STICKY_ITEM";
export const CHART_GENERAL_SET_STICKY_NOTE = "CHART_GENERAL_SET_STICKY_NOTE";
export const CHART_GENERAL_PUSH_STICKY = "CHART_GENERAL_PUSH_STICKY";
