import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReactSpinner from "react-spinjs-fix";

import { Modal } from "../ui/Modal";
import { closeModal } from "../../actions/loaderActions";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { loadersCountSelector, loadersMessagesSelector } from "../../reducers/loaderReducer";

export default function PageLoader() {
  const dispatch = useDispatch();

  const loaders = useShallowEqualSelector(loadersCountSelector);
  const messages = useShallowEqualSelector(loadersMessagesSelector);
  if (loaders > 0) {
    return (
      <Modal size="sm" show={true} keyboard={false}>
        <div style={{ height: 70 }}>
          <ReactSpinner />
        </div>
      </Modal>
    );
  }

  if (messages.length > 0) {
    return (
      <Modal
        size="sm"
        show={true}
        keyboard={false}
        actions={<Button onClick={() => dispatch(closeModal())}>Close</Button>}
      >
        <div className="page-loader-body">
          {messages[0] === "Invalid Password or Username\n"
            ? 'Wrong password. Try again, or click "Forgot your password?" to reset it.'
            : messages.join("\n")}
        </div>
      </Modal>
    );
  }

  return null;
}
