import createReducer from "../helpers/createReducer";

import {
  PUSH_ORDER_STACK,
  CLEAR_ORDER_STACK,
  UPDATE_ORDER_STACK,
  POP_ORDER_STACK,
  SET_ACTIVE_TAB,
  GET_LAB_ORDER_LIST_START,
  GET_LAB_ORDER_LIST_SUCCESS,
  GET_LAB_ORDER_LIST_ERROR,
  GET_RADIOLOGY_ORDER_LIST_START,
  GET_RADIOLOGY_ORDER_LIST_SUCCESS,
  GET_RADIOLOGY_ORDER_LIST_ERROR,
  orderTabs,
  GET_CLINIC_MEMBERS_SUCCESS,
  GET_LAB_ORDER_FORM_DATA_SUCCESS,
  GET_LAB_ORDER_TYPES_SUCCESS,
  GET_RADIOLOGY_ORDER_FORM_DATA_SUCCESS,
  GET_LAB_ORDER_ITEM_START,
  GET_LAB_ORDER_ITEM_SUCCESS,
  GET_LAB_ORDER_ITEM_ERROR,
  GET_RADIOLOGY_ORDER_ITEM_START,
  GET_RADIOLOGY_ORDER_ITEM_SUCCESS,
  GET_RADIOLOGY_ORDER_ITEM_ERROR,
  RESET_ORDER_ITEMS_ERROR,
  SET_LAB_ORDER_FILTER_FORM,
  SET_RADIOLOGY_ORDER_FILTER_FORM,
  PUSH_LAB_RADIOLOGY_STACK,
} from "../actions/orderActions";
import { defaultStackItem } from "../constants/chart-sidebar/orderStackCodes";
export const defaultState = {
  stack: [defaultStackItem],
  fetching: false,
  activeTab: orderTabs[0],
  clinicMembers: [],
  labOrderFormData: {},
  labOrderFilterForm: {},
  labOrderList: [],
  labOrderItem: {},
  labOrderTotalCount: 0,
  labOrderFetching: false,
  radiologyOrderFormData: {},
  labOrderTypes: [],
  radiologyOrderFilterForm: {},
  radiologyOrderList: [],
  radiologyOrderItem: {},
  radiologyOrderTotalCount: 0,
  radiologyOrderFetching: false,
  orderStack: [defaultStackItem],
};

const reducers = {
  [PUSH_LAB_RADIOLOGY_STACK](state, { stackItem }) {
    const orderStack = state.orderStack.slice();
    orderStack.push(stackItem);
    return {
      ...state,
      orderStack,
    };
  },
  [CLEAR_ORDER_STACK](state) {
    return {
      ...state,
      stack: [defaultStackItem],
      orderStack: [defaultStackItem],
    };
  },
  [UPDATE_ORDER_STACK](state, { stack }) {
    return {
      ...state,
      stack: [stack],
    };
  },
  [PUSH_ORDER_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [POP_ORDER_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [SET_ACTIVE_TAB](state, { activeTab }) {
    if (activeTab.code === state.activeTab.code) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      activeTab,
      labOrderList: [],
      labOrderTotalCount: 0,
      radiologyOrderList: [],
      radiologyOrderTotalCount: 0,
    };
  },
  [GET_CLINIC_MEMBERS_SUCCESS](state, { data }) {
    return {
      ...state,
      clinicMembers: data || [],
    };
  },
  [GET_LAB_ORDER_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      labOrderFormData: data || {},
    };
  },
  [GET_LAB_ORDER_TYPES_SUCCESS](state, { data }) {
    return {
      ...state,
      labOrderTypes: data || [],
    };
  },
  [GET_RADIOLOGY_ORDER_LIST_START](state) {
    return {
      ...state,
      radiologyOrderFetching: true,
    };
  },
  [GET_RADIOLOGY_ORDER_LIST_SUCCESS](state, { request, data }) {
    if (request.start) {
      return {
        ...state,
        radiologyOrderFetching: false,
        radiologyOrderList: state.radiologyOrderList.concat(data.list),
        radiologyOrderTotalCount: data.totalCount,
      };
    }
    return {
      ...state,
      radiologyOrderFetching: false,
      radiologyOrderList: data.list || [],
      radiologyOrderTotalCount: data.totalCount,
    };
  },
  [GET_RADIOLOGY_ORDER_LIST_ERROR](state) {
    return {
      ...state,
      radiologyOrderFetching: false,
    };
  },
  [GET_RADIOLOGY_ORDER_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      radiologyOrderFormData: data || {},
    };
  },
  [GET_LAB_ORDER_LIST_START](state) {
    return {
      ...state,
      labOrderFetching: true,
    };
  },
  [GET_LAB_ORDER_LIST_SUCCESS](state, { request, data }) {
    if (request.start) {
      return {
        ...state,
        labOrderFetching: false,
        labOrderList: state.labOrderList.concat(data.list),
        labOrderTotalCount: data.totalCount,
      };
    }
    return {
      ...state,
      labOrderFetching: false,
      labOrderList: data.list || [],
      labOrderTotalCount: data.totalCount,
    };
  },
  [GET_LAB_ORDER_LIST_ERROR](state) {
    return {
      ...state,
      labOrderFetching: false,
    };
  },
  [GET_LAB_ORDER_ITEM_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [GET_LAB_ORDER_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      fetching: false,
      labOrderItem: data,
    };
  },
  [GET_LAB_ORDER_ITEM_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [GET_RADIOLOGY_ORDER_ITEM_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [GET_RADIOLOGY_ORDER_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      fetching: false,
      radiologyOrderItem: data,
    };
  },
  [GET_RADIOLOGY_ORDER_ITEM_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [RESET_ORDER_ITEMS_ERROR](state) {
    return {
      ...state,
      labOrderItem: {},
      radiologyOrderItem: {},
    };
  },
  [SET_LAB_ORDER_FILTER_FORM](state, { data }) {
    return {
      ...state,
      labOrderFilterForm: { ...state.labOrderFilterForm, ...data },
    };
  },
  [SET_RADIOLOGY_ORDER_FILTER_FORM](state, { data }) {
    return {
      ...state,
      radiologyOrderFilterForm: { ...state.radiologyOrderFilterForm, ...data },
    };
  },
};
export default createReducer(defaultState, reducers);
