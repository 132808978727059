import cx from "classnames";
import { isString } from "lodash";
import { makeStyles } from "@material-ui/styles";
import React, { isValidElement, ReactNode } from "react";

import { Theme } from "../../dto/AppDTO";

interface StyleProps {
  readonly buttonWidth: number;
  readonly isPrimaryType: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: "65px",
    display: "flex",
    backgroundColor: (props) =>
      props.isPrimaryType ? theme.palette.primary.main : theme.palette.common.white,
  },
  backButtonContainer: {
    width: (props) => `${props.buttonWidth}px`,
    display: "flex",
    alignItems: "center",
  },
  rightComponentContainer: {
    width: (props) => `${props.buttonWidth}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  backButton: {
    textDecoration: "none !important",
    color: (props) =>
      props.isPrimaryType ? theme.palette.common.white : theme.palette.primary.main,

    "&:hover": {
      color: (props) =>
        props.isPrimaryType ? theme.palette.common.white : theme.palette.primary.main,
    },
  },
  titleContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: "17px",
    textAlign: "center",
    textTransform: "uppercase",
    color: (props) =>
      props.isPrimaryType ? theme.palette.common.white : theme.palette.common.black,
  },
}));

export enum SidebarHeaderType {
  Primary = "primary",
  Secondary = "secondary",
}

interface Props {
  readonly title?: ReactNode;
  readonly className?: string;
  readonly backButtonClassName?: string;
  readonly rightComponentClassName?: string;
  readonly buttonWidth?: number;
  readonly onBackClick: () => void;
  readonly type?: SidebarHeaderType;
  readonly rightComponent?: ReactNode;
}

export function SidebarHeader({
  title,
  className,
  backButtonClassName,
  buttonWidth = 85,
  onBackClick,
  rightComponent,
  rightComponentClassName,
  type = SidebarHeaderType.Primary,
}: Props) {
  const isPrimaryType = type === SidebarHeaderType.Primary;

  const classes = useStyles({ isPrimaryType, buttonWidth });

  return (
    <div className={cx(classes.root, className)}>
      <div className={cx(classes.backButtonContainer, backButtonClassName)}>
        <button
          type="button"
          onClick={onBackClick}
          className={cx("btn btn-link", classes.backButton)}
        >
          ❮ &nbsp; BACK
        </button>
      </div>
      <div className={classes.titleContainer}>
        {isString(title) && <span className={classes.title}>{title}</span>}

        {isValidElement(title) && title}
      </div>
      <div className={cx(classes.rightComponentContainer, rightComponentClassName)}>
        {rightComponent}
      </div>
    </div>
  );
}
