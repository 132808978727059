import { useQuery, UseQueryResult } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

export function useDentalPointsListQuery(): UseQueryResult<DentalPointProps[], Error> {
  return useQuery(["member", "dental-points", "choose"], () =>
    httpClient.get(ApiRoutes.MemberDentalPointsList).then(({ data }) => data.data),
  );
}
