import { toFinite } from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import { formatResponse } from "../../utils/FormatUtils";
import { createUrl } from "../../utils/UrlUtils";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

interface Query {
  readonly id: string;
}

export function useProcedurePackageItemQuery(
  query: Query,
): UseQueryResult<ProcedurePckgItemProps[], Error> {
  return useQuery(
    ["treatment", "procedure", "item", query.id],
    () =>
      httpClient.get(createUrl(ApiRoutes.TreatmentProceduresItem, { query })).then(formatResponse),
    { enabled: toFinite(query.id) > 0 },
  );
}
