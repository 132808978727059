import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";

import BackButton from "../../../widgets/BackButton";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import EmbedButton from "../../../widgets/EmbedButton";
import logoImage from "../../../../assets/images/clinic_logo.png";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionPDF extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
      chartSidePrescription: PropTypes.object,
    };

    send = () => {
      this.props.chartSidePrescriptionActions.sendEmail();
    };

    render() {
      const { chartSidePrescription } = this.props;
      const { stack } = chartSidePrescription;

      const stackItem = _.last(stack);

      return (
        <div className="clinical-note-item">
          <div className="clinical-note-item-view-title">
            <button onClick={this.send} className="btn btn-link pull-right">
              <strong>SEND TO EMAIL</strong>
            </button>
            <BackButton
              className="btn-sm"
              text="Back"
              onClick={this.props.chartSidePrescriptionActions.popStack}
            />
            <img alt="logo" className="logo" src={logoImage} />
          </div>
          <div className="clinical-note-item-view-body">
            <EmbedButton src={stackItem.item.pdfUrl} />
          </div>
        </div>
      );
    }
  },
);
