import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";
import { Modal } from "../../../ui/Modal";
import { openSimpleModal } from "../../../../actions/simpleModalActions";

const enhancer = connect(
  ({ chartSideTransaction, session: { permissions } }) => ({
    permissions,
    chooseDeleteModalVisible: chartSideTransaction.chooseDeleteModalVisible,
    transactionItem: _.get(chartSideTransaction, "transactionItem.status.code"),
  }),
  bindActions({ chartSideTransactionActions, openSimpleModal }),
);
export default enhancer(
  class ChartTransactionChooseDeleteModal extends React.Component {
    handleVoid = () => {
      let accessDelete = false;

      if (this.props.permissions && this.props.permissions.length) {
        if (this.props.transactionItem === "DentalTransactionStatus_PLANNED") {
          if (this.props.permissions.indexOf("VOID_PLANNED_DENTAL_TRANSACTION_ITEM") > 0) {
            accessDelete = true;
          }
        }
        if (this.props.transactionItem === "DentalTransactionStatus_COMPLETED") {
          if (this.props.permissions.indexOf("VOID_COMPLETED_DENTAL_TRANSACTION_ITEM") > 0) {
            accessDelete = true;
          }
        }
      }

      if (accessDelete) {
        this.props.chartSideTransactionActions.voidItem();
        this.props.chartSideTransactionActions.closeChooseDeleteModal();
      } else {
        this.props.chartSideTransactionActions.closeChooseDeleteModal();
        this.props.chartSideTransactionActions.openAccessDeleteModal();
      }
    };

    handleDelete = () => {
      let accessDelete = false;
      if (this.props.permissions && this.props.permissions.length) {
        if (this.props.transactionItem === "DentalTransactionStatus_PLANNED") {
          if (this.props.permissions.indexOf("REMOVE_PLANNED_DENTAL_TRANSACTION_ITEM") !== -1) {
            accessDelete = true;
          }
        }
        if (this.props.transactionItem === "DentalTransactionStatus_COMPLETED") {
          if (this.props.permissions.indexOf("REMOVE_COMPLETED_DENTAL_TRANSACTION_ITEM") !== -1) {
            accessDelete = true;
          }
        }
      }
      if (!accessDelete) {
        this.props.openSimpleModal({
          body: "Permission denied",
        });
      }
      if (accessDelete) {
        this.props.chartSideTransactionActions.deleteItem();
        this.props.chartSideTransactionActions.closeChooseDeleteModal();
      } else {
        this.props.chartSideTransactionActions.closeChooseDeleteModal();
        this.props.chartSideTransactionActions.openAccessDeleteModal();
      }
    };
    render() {
      return (
        <Modal
          size="sm"
          animation={false}
          show={this.props.chooseDeleteModalVisible}
          onHide={this.props.chartSideTransactionActions.closeChooseDeleteModal}
          keyboard={false}
          className="d-flex"
          dialogClassName="d-flex align-items-center"
          title="Choose action:"
        >
          <div className="d-flex">
            <Button bsStyle="danger" className="mr-2" onClick={this.handleVoid}>
              Void
            </Button>
            {/*<Button bsStyle="danger" className="mr-2" onClick={this.handleDelete}>*/}
            {/*  Delete*/}
            {/*</Button>*/}
            <Button onClick={this.props.chartSideTransactionActions.closeChooseDeleteModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      );
    }
  },
);
