import enGbLocale from "date-fns/locale/en-GB";

import cx from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { isArray, isDate, isString } from "lodash";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

import { Theme } from "../../dto/AppDTO";
import { hexToRgba } from "../../utils/FormatUtils";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { Portal } from "react-overlays";

/* eslint-disable-next-line */
const companyDateFormat = localStorage.getItem("company-date-format") as string;

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    "& > .react-datepicker__input-container": {
      flexDirection: "column",
      display: "flex !important",
    },
  },
  root: {
    color: "black",
    height: "34px",
    padding: "2px 8px",
    borderRadius: "4px",
    border: "1px solid #cccccc !important",

    "&.control-lg": {
      height: "48px",
      padding: "8px 16px",
      borderRadius: "6px",
    },

    "&:focus": {
      borderColor: `${theme.palette.primary.main} !important`,
      boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${hexToRgba(theme.palette.primary.main, {
        opacity: 0.6,
      })}`,
    },
  },
}));

export interface DatePickerProps
  extends Omit<ReactDatePickerProps, "selected" | "placeholderText"> {
  readonly size?: "lg";
  readonly placeholder?: string;
  readonly selected?: Date | null | number;
  readonly showDay?: boolean;
}

export function DatePicker({
  size,
  selected,
  className,
  placeholder,
  showDay,
  dateFormat = companyDateFormat || DATE_FORMAT_LEGACY,
  ...props
}: DatePickerProps) {
  const classes = useStyles();
  const weekDay = moment(selected).format("ddd").toUpperCase();

  const formattedDateFormat = useMemo(() => {
    if (isString(dateFormat)) {
      return dateFormat.replace(/D/g, "d").replace(/Y/g, "y");
    }

    if (isArray(dateFormat)) {
      return dateFormat.map((x) => x.replace(/D/g, "d").replace(/Y/g, "y"));
    }

    return DATE_FORMAT_LEGACY;
  }, [dateFormat]);

  const date = useMemo(() => {
    if (moment.isMoment(selected)) {
      return selected.toDate();
    }

    if (isDate(selected)) {
      return selected;
    }

    if (selected) {
      return new Date(selected);
    }

    return null;
  }, [selected]);

  return (
    // @ts-ignore
    <ReactDatePicker
      {...props}
      selected={date}
      autoComplete="off"
      todayButton="today"
      locale={enGbLocale}
      peekNextMonth={true}
      dropdownMode="select"
      showYearDropdown={true}
      showMonthDropdown={true}
      placeholderText={placeholder}
      dateFormat={`${formattedDateFormat}${showDay ? ", " + "'" + weekDay + "'" : ""}`}
      popperContainer={CalendarContainer}
      wrapperClassName={cx(classes.wrapper, props.wrapperClassName)}
      className={cx(classes.root, { "control-lg": size === "lg" }, className)}
    />
  );
}
