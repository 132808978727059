import React from "react";

export default function condition({ property, color }) {
  if (!property) {
    return null;
  }

  return (
    <div className="condition-wrap tooth-image-condition-center">
      <div className="banding">
        <div className="banding-wrap">
          <div className="circle" style={{ borderColor: color }} />
        </div>
      </div>
    </div>
  );
}
