import React from "react";

import ChartBalanceModal from "../chart/top-panel/ChartBalanceModal";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import {
  chartBalanceModalVisibleSelector,
  chartBalanceValueSelector,
  chartBillingBalanceValueSelector,
} from "../../reducers/chartReducer";
import { appointmentPatientSelector } from "../../reducers/appointmentReducer";

export function ChartBalanceModalWrapper() {
  const patient = useShallowEqualSelector(appointmentPatientSelector as any);

  const chartBalanceValue = useShallowEqualSelector(chartBalanceValueSelector as any);
  const chartBillingBalanceValue = useShallowEqualSelector(chartBillingBalanceValueSelector as any);
  const chartBalanceModalVisible = useShallowEqualSelector(chartBalanceModalVisibleSelector as any);

  return (
    <ChartBalanceModal
      show={chartBalanceModalVisible}
      chartBalanceValue={chartBalanceValue}
      chartBillingBalanceValue={chartBillingBalanceValue}
      patientKey={patient && patient.patientKey}
    />
  );
}
