import React from "react";

import { NOT_AVAILABLE, PLEASE_SELECT } from "../../constants/Constants";
import CustomSelectBox from "./CustomSelectBox";
import { DatePicker } from "../ui/DatePicker";
import moment from "moment";

export default function InputField(props) {
  let $el = null;
  const { element, type, onChange, onClick, className, textValue } = props;
  if (element) {
    const handleClick = onClick || element.handler;
    const elClass = className || "";
    const elemType = type || element.type;
    const isCardNumber = (element?.name || "").toUpperCase().replace(/\s+/g, "_") === "CARD_NUMBER";
    if (element.visible) {
      switch (elemType) {
        case "button":
        case "submit":
          $el = (
            <button
              type={elemType || "button"}
              className={`btn btn-lg ${elClass}`}
              onClick={(e) => (handleClick ? handleClick(e, element) : null)}
              disabled={element.disabled}
            >
              {element.value}
            </button>
          );
          break;
        case "span":
          $el = <span className="item-value">{textValue || element.value || NOT_AVAILABLE}</span>;
          break;
        case "date":
          $el = (
            <DatePicker
              placeholderText={PLEASE_SELECT}
              className="form-control input-lg"
              selected={
                moment.isMoment(element.value)
                  ? moment(element.value).valueOf()
                  : element.value
                  ? element.value
                  : null
              }
              disabled={element.disabled}
              onChange={(e) => element.handler(e, element)}
            />
          );
          break;
        case "select":
          $el = <CustomSelectBox selectBox={element} />;
          break;
        default:
          $el = (
            <input
              value={isCardNumber ? (element.value || "").trim() : element.value || ""}
              className={`form-control input-lg ${elClass}`}
              type={type || element?.type || "input"}
              onChange={(e) => (onChange ? onChange(e, element) : element.handler(e, element))}
              required={element.required}
              disabled={element.disabled}
            />
          );
          break;
      }
    }
  }
  return $el;
}
