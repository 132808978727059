import React, { useEffect } from "react";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  getMedicalFillInFormDiagnosisList,
  updateDiagnosisClaimType,
  updateDiagnosisYearOfOnset,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import MedicalFillInFormDiagnosisList from "./MedicalFillInFormDiagnosisList";
import { getDiagnosisTypeList } from "../../../../actions/transactionActions";

const MedicalFillInformDiagnosis = ({
  chart,
  clinicId,
  diagnosisList,
  fetching,
  permissions,
  diagnosisClaimTypeList,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedicalFillInFormDiagnosisList());
    dispatch(getDiagnosisTypeList());
  }, [chart.sideBarVisible]);

  const todayDiagnosis = diagnosisList.filter((item) => moment().isSame(item.createdDate, "day"));
  const oldDiagnosis = diagnosisList.filter((item) => !moment().isSame(item.createdDate, "day"));

  return (
    <div className="medical-fill-in-form-treatment">
      <MedicalFillInFormDiagnosisList
        updateDiagnosisClaimType={(data) =>
          dispatch(updateDiagnosisClaimType(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        updateDiagnosisYearOfOnset={(data) =>
          dispatch(updateDiagnosisYearOfOnset(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        diagnosisClaimTypeList={diagnosisClaimTypeList}
        clinicId={clinicId}
        fetching={fetching}
        permissions={permissions}
        diagnosisList={todayDiagnosis}
      />
      <MedicalFillInFormDiagnosisList
        updateDiagnosisClaimType={(data) =>
          dispatch(updateDiagnosisClaimType(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        updateDiagnosisYearOfOnset={(data) =>
          dispatch(updateDiagnosisYearOfOnset(data)).then(() =>
            dispatch(getMedicalFillInFormDiagnosisList()),
          )
        }
        diagnosisClaimTypeList={diagnosisClaimTypeList}
        clinicId={clinicId}
        fetching={fetching}
        permissions={permissions}
        diagnosisList={oldDiagnosis}
      />
    </div>
  );
};

export default MedicalFillInformDiagnosis;
