import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import BackButton from "../../../widgets/BackButton";
import ChartVitalCategoryItem from "../ChartListItem";
import ChartSideSearch from "../ChartSideSearch";
import Show from "../../../widgets/Show";

import vitalItemTypes from "../../../../constants/chart-sidebar/vitalItemTypes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVitalList extends React.Component {
    componentDidMount() {
      this.props.chartSideVitalActions.getTemplateList();
    }

    openItem = (item) => {
      return () => {
        this.props.chartSideVitalActions.openItem(item, vitalItemTypes.new);
      };
    };

    onSearch = (event) => {
      this.props.chartSideVitalActions.setSearchValue(event.target.value);
    };

    render() {
      const { templateList, fetching, searchValue } = this.props.chartSideVital;

      let $list = templateList
        .filter((item) => item.name.indexOf(searchValue) !== -1)
        .map((item, index) => (
          <ChartVitalCategoryItem key={index} item={item} openItem={this.openItem(item)} />
        ));

      return (
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header">
            <div className="clearfix">
              <div className="col-sm-4">
                <BackButton
                  className="btn-sm"
                  text="Back"
                  onClick={this.props.chartSideVitalActions.popStack}
                />
              </div>
              <div className="col-sm-8">
                <div className="chart-clinical-note-title">Add Vital</div>
              </div>
            </div>
          </div>
          <div className="chart-side-body">
            <ChartSideSearch value={searchValue} onChange={this.onSearch} />
            <div className="body">
              <div className="chart-clinical-note-category-title">Ready Templates</div>
              {$list}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
        </div>
      );
    }
  },
);
