import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ToothDetailsType from "../../../../constants/teeth/ToothDetailsType";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../../../../constants/Constants";

import Utils from "../../../../helpers/Utils";
import moment from "moment";

import BackButton from "../../../widgets/BackButton";
import Show from "../../../widgets/Show";

import ChartVitalQuestion from "./ChartVitalQuestion";

import { getNote } from "../../../../helpers/clinicNote";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";
import ChartClinicalNoteCreatedDate from "../clinical-note/ChartClinicalNoteCreatedDate";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVitalItemEdit extends React.Component {
    componentDidMount() {
      this.props.chartSideVitalActions.initEdit();

      if (!this.props.edit) {
        this.props.chartSideVitalActions.getEmpty();
      }
    }

    save = () => {
      this.props.chartSideVitalActions.saveItem(this.refs.name && this.refs.name.value);
    };

    getNote = (item) => {
      const numericKey = "numeric2";

      const reqExp = /\[\[[^\]]*]]/g;
      const answers = [];
      const noteTemplateCode =
        (item.noteTemplate && item.noteTemplate.code) ||
        (item.template && item.template.code) ||
        "";

      noteTemplateCode.replace(reqExp, (match) => {
        const code = match.slice(2, -2);
        let index = 0;

        if (code === "Current date") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
        }
        if (code === "Current time") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
        }
        if (code === "Patient chart number") {
          return item.patient && item.patient.chartNumber;
        }
        if (code === "Patient name") {
          return item.patient && item.patient.fullName;
        }
        if (code === "Dentist Name") {
          return (item.owner && item.owner.name) || (item.doctor && item.doctor.name);
        }

        if (code === "Doctor license id") {
          return item.owner && item.owner.code;
        }

        let question = _.get(item, "templateQuestionsAndAnswers", []).find((question, index111) => {
          index = index111;
          return question.code === code;
        });

        if (!question)
          question = {
            id: 0,
            code: "",
            name: "",
            question: "",
            questionType: "",
            answers: [],
          };

        let answer;

        if (!(question.answers && question.answers.length)) {
          answer = "N/A";
        } else if (
          question.questionType.code === questionTypeCodes.LIST_OF_ANSWERS ||
          question.questionType.code === questionTypeCodes.RADIO_LIST
        ) {
          answer = question.answers[0];
        } else if (
          question.questionType.code === questionTypeCodes.FREE_FORM_ENTRY ||
          question.questionType.code === questionTypeCodes.TEXT ||
          question.questionType.code === questionTypeCodes.TEXT_AREA
        ) {
          answer = question.answers[0].replace(/(?:\r\n|\r|\n)/g, "<br />");
        } else if (
          question.questionType.code === questionTypeCodes.DATE_SELECTOR ||
          question.questionType.code === questionTypeCodes.DATE_PICKER
        ) {
          answer = Utils.parseDateFormat(
            moment.isMoment(question.answers[0])
              ? question.answers[0]
              : moment(+question.answers[0]),
            DATE_FORMAT_LEGACY,
          );
        } else if (question.questionType.code === questionTypeCodes.TEETH_SELECTION) {
          answer = question.answers
            .map((toothCode) => ToothDetailsType[toothCode][numericKey])
            .join(", ");
        } else if (question.questionType.code === questionTypeCodes.SURFACE_SELECTION) {
          answer = question.answers.join(", ");
        } else if (
          question.questionType.code === questionTypeCodes.LIST_OF_ANSWERS_MULTIPLE_CHOICE ||
          question.questionType.code === questionTypeCodes.CHECKBOX_LIST
        ) {
          answer = question.answers.join(", ");
        }

        const questionChar = question.question.slice(-1) === "?" ? "" : "?";
        answer = question.question + questionChar + " : " + answer;

        answers.push(
          <span
            key={index}
            className="template-item"
            onClick={() => this.props.chartSideVitalActions.selectQuestion(index)}
          >
            {answer}
          </span>,
        );
      });

      return answers;
    };

    clickNote = (event) => {
      if (event.target.classList.contains("fill-in-form-answer")) {
        this.props.chartSideVitalActions.selectQuestion(event.target.dataset.index);
      }
    };

    render() {
      const {
        chartSideVital: { item, currentQuestionIndex },
        edit,
        clinic,
      } = this.props;

      let firstQuestion = false;
      let lastQuestion = false;
      let noQuestions = false;

      const count =
        (item.templateQuestionsAndAnswers && item.templateQuestionsAndAnswers.length) || 0;

      if (currentQuestionIndex + 1 === count) {
        lastQuestion = true;
      }

      if (currentQuestionIndex === 0) {
        firstQuestion = true;
      }

      if (count === 0) {
        noQuestions = true;
      }

      const btnWrapStyle = {
        width: firstQuestion || noQuestions ? "100%" : "50%",
      };

      // const note = this.getNote(item);
      const note = getNote(item, {}, true, clinic);

      const currentQuestion =
        (item &&
          item.templateQuestionsAndAnswers &&
          item.templateQuestionsAndAnswers[currentQuestionIndex]) ||
        {};

      return (
        <div className="chart-clinical-note">
          <div className="clinical-note-item">
            <div className="clinical-note-item-view-title">
              <BackButton
                className="btn-sm"
                text="Back"
                onClick={this.props.chartSideVitalActions.popStack}
              />
              <div className="chart-number">#{item.patient && item.patient.chartNumber}</div>
              <div className="name">
                <Show if={!edit && item.template && item.template.name}>
                  <input
                    type="text"
                    className="form-control"
                    ref="name"
                    defaultValue={item.template && item.template.name}
                  />
                </Show>
                {edit && item.template && item.template.name}
              </div>
            </div>
            <div className="clinical-note-item-edit-body">
              <div
                className="template"
                onClick={this.clickNote}
                dangerouslySetInnerHTML={{ __html: note }}
              />
              <div className="questions">
                <div className="question">{currentQuestion.question}</div>
                <div className="body">
                  <ChartVitalQuestion question={currentQuestion} />
                </div>
                <div className="btns clearfix">
                  <Show if={!firstQuestion && !noQuestions}>
                    <div className="btn-wrap" style={btnWrapStyle}>
                      <button
                        onClick={this.props.chartSideVitalActions.prevQuestion}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Previous
                      </button>
                    </div>
                  </Show>
                  <Show if={!lastQuestion && !noQuestions}>
                    <div className="btn-wrap" style={btnWrapStyle}>
                      <button
                        onClick={this.props.chartSideVitalActions.nextQuestion}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Next
                      </button>
                    </div>
                  </Show>
                  <Show if={lastQuestion || noQuestions}>
                    <div className="btn-wrap" style={btnWrapStyle}>
                      <button onClick={this.save} className="btn btn-primary btn-lg btn-block">
                        Done
                      </button>
                    </div>
                  </Show>
                </div>
              </div>
              <Show if={edit}>
                <ChartClinicalNoteCreatedDate
                  changeCreatedDate={this.props.chartSideVitalActions.changeCreatedDate}
                  item={item}
                />
              </Show>
            </div>
          </div>
        </div>
      );
    }
  },
);
