import React from "react";
import { connect, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";
import { changeTransactionFormByKey } from "../../../actions/chartSideSeriesActions";

const enhancer = connect(({ transaction, chartSideSeries }) => ({ transaction, chartSideSeries }));
const DiagnosisClaimStatusBox = ({ transaction, chartSideSeries }) => {
  const dispatch = useDispatch();
  const { diagnosisClaimTypeList } = transaction;
  const { transactionForm } = chartSideSeries;
  const statusOptions = diagnosisClaimTypeList.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  return (
    <div className="row pb-3 pt-3">
      <div className="col-sm-4">
        <div className="mb-2">Diagnosis type</div>
        <SelectPicker
          isMulti={false}
          value={transactionForm.diagnosisClaimType}
          options={statusOptions}
          onChange={(x) => {
            dispatch(changeTransactionFormByKey(x, "diagnosisClaimType"));
          }}
        />
      </div>
      <div className="col-sm-4">
        <div className="mb-2">Year of onset</div>
        <DatePicker
          showYearPicker={true}
          dateFormat="yyyy"
          selected={transactionForm.diagnosisYearOfOnSet}
          onChange={(x) => dispatch(changeTransactionFormByKey(x, "diagnosisYearOfOnSet"))}
        />
      </div>
      <div className="col-sm-4">
        <div className="mb-2">&nbsp;</div>
        <InputLabel key={"item.key"}>
          <Checkbox
            checked={transactionForm.problem}
            onChange={({ target: { checked } }) => {
              dispatch(changeTransactionFormByKey(checked, "problem"));
            }}
            inputProps={{ "aria-label": "" }}
            sx={{
              color: "#bec844",
              "&.Mui-checked": {
                color: "#bec844",
              },
            }}
          />
          problem
        </InputLabel>
      </div>
    </div>
  );
};

export default enhancer(DiagnosisClaimStatusBox);
