import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TopMenu from "./TopMenu";
import PageLoader from "./PageLoader";
import SimpleModal from "../widgets/SimpleModal";
import { initApp } from "../../actions/authActions";
import ChooseOptionModal from "../widgets/ChooseOptionModal";
import { appLoadedSelector } from "../../reducers/authReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { Routes } from "../../constants/Routes";
import {
  changeAppointmentEligibilityPending,
  setAppointmentEligibilityDetails,
  updateAppointmentEligibilityIdPayer,
  updateAppointmentEligibilityStatus,
} from "../../actions/appointmentActions";
import _ from "lodash";
import { useEligibilityDetailsQuery } from "../../queries/eligibility/useEligibilityDetailsQuery";
import {
  appointmentAppointmentSelector,
  appointmentEligibilityDetailsSelector,
  appointmentEligibilityIdSelector,
  appointmentEligibilityPendingSelector,
} from "../../reducers/appointmentReducer";

export default function App({ children }) {
  const dispatch = useDispatch();
  const deviceExist = useSelector((state) => state.auth);
  const history = useHistory();

  const appLoaded = useShallowEqualSelector(appLoadedSelector);
  const appointment = useShallowEqualSelector(appointmentAppointmentSelector);
  const eligibilityId = useShallowEqualSelector(appointmentEligibilityIdSelector(appointment?.id));
  const appointmentId = appointment?.id;

  const eligibilityPending = useShallowEqualSelector(
    appointmentEligibilityPendingSelector(appointmentId),
  );
  const eligibilityDetails = useShallowEqualSelector(
    appointmentEligibilityDetailsSelector(appointmentId),
  );
  const eligibilityDetailsSuccessHandler = (data) => {
    if (data.data) {
      dispatch(setAppointmentEligibilityDetails(appointmentId, data.data));

      dispatch(changeAppointmentEligibilityPending(appointmentId, false));
    } else {
      const error = _.isArray(data) ? data.join(" \n") : "";
      if (error && error !== "No record found") {
        dispatch(setAppointmentEligibilityDetails(appointmentId, { eligibilityDetails: error }));

        dispatch(changeAppointmentEligibilityPending(appointmentId, false));
      }
    }
  };
  useEligibilityDetailsQuery(
    eligibilityId,
    appointmentId,
    eligibilityPending,
    eligibilityDetailsSuccessHandler,
  );
  useEffect(() => {
    if (eligibilityDetails?.eligibilityCheck) {
      dispatch(
        updateAppointmentEligibilityStatus({
          eligible: eligibilityDetails.eligibilityCheck?.result,
          id: appointment.id,
        }),
      );
      dispatch(
        updateAppointmentEligibilityIdPayer({
          id: appointment?.id,
          eligibilityIdPayer:
            eligibilityDetails.eligibilityCheck.eligibilityCheckAnswer?.eligibilityCheckAnswerId,
        }),
      );
    }
  }, [eligibilityDetails]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    );
    const isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };
    if (isMobile.any()) {
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", "width=device-width, initial-scale=0.1");
    }
    if (isTablet) {
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=0.7 , minimum-scale=0.7, maximum-scale=1.0",
      );
    }
    dispatch(initApp()).then((response) => {
      if (response && response.data) {
        if (deviceExist) {
          history.push(Routes.AuthMemberLogin);
        } else {
          history.push(Routes.AuthRegisterDeviceLogin);
        }
      }
    });
  }, [dispatch]);

  return (
    <div className="app d-flex flex-column flex-grow-1 flex-shrink-1">
      <TopMenu />
      {appLoaded && children}
      <ChooseOptionModal />
      <SimpleModal />
      <PageLoader />
    </div>
  );
}
