import PatientApi from "../api-legacy/PatientApi";
import * as ActionType from "./../actionTypes/patientActionTypes";
import _ from "lodash";

import { showMessage, startLoader, stopLoader } from "./loaderActions";
import * as ActionTypes from "../actionTypes/chartSideSeriesActionTypes";

export function perioExamVoidItem(data) {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      patient: { patientDetails },
    } = getState();

    dispatch(startLoader());

    dispatch({
      api: PatientApi.updatePerioExamVoidItem,
      types: [
        ActionType.PERIO_EXAM_VOID_ITEM_START,
        ActionType.PERIO_EXAM_VOID_ITEM_SUCCESS,
        ActionType.PERIO_EXAM_VOID_ITEM_ERROR,
      ],
      data,
    })
      .then(() => {
        dispatch(stopLoader());
        dispatch(
          getPerioExamList({ patientKey: patientKey || patientDetails?.patientKey, voided: false }),
        );
      })
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function togglePatientTag(index) {
  return {
    type: ActionType.TOGGLE_PATIENT_TAG_ITEM,
    index,
  };
}

export function setPatientTags(tagsList) {
  return {
    type: ActionType.SET_PATIENT_TAGS_LIST,
    tagsList,
  };
}

export function setPatientTagItemValue(value, index) {
  return {
    type: ActionType.SET_PATIENT_TAG_ITEM_VALUE,
    value,
    index,
  };
}

export function getTagsList() {
  return (dispatch) => {
    return dispatch(getPatientTagsListApi())
      .then(() => dispatch(getAssignedTagItems()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function getAssignedTagItems() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
    };

    dispatch(getPatientAssignedTagsListApi(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function savePatientTags() {
  return (dispatch, getState) => {
    const {
      patient: { tagsList },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    if (_.isEmpty(tagsList)) return;

    const data = {
      patientKey,
      tags: tagsList.filter((item) => Boolean(item.selected)),
    };

    dispatch(startLoader());

    return dispatch({
      api: PatientApi.saveTagsList,
      types: [
        ActionType.SAVE_PATIENT_TAGS_LIST_START,
        ActionType.SAVE_PATIENT_TAGS_LIST_SUCCESS,
        ActionType.SAVE_PATIENT_TAGS_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const openPatientInfoSideBar = (appointment) => (dispatch) =>
  dispatch({ type: ActionType.OPEN_PATIENT_INFO_SIDE_BAR, appointment });
export const closePatientInfoSideBar = () => (dispatch) =>
  dispatch({ type: ActionType.CLOSE_PATIENT_INFO_SIDE_BAR });

export function getPerioExamList(data) {
  return (dispatch) => {
    if (!data || !data.patientKey) {
      showMessage("Please provide `Patient Key`");

      return;
    }

    dispatch(startLoader());

    return dispatch(perioExamListApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPerioExamItem(data) {
  return (dispatch) => {
    if (!data || !data.historyDate) {
      showMessage("Please provide `History Date`");

      return;
    }

    dispatch(startLoader());

    return dispatch(perioExamItemApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function perioExamListApi(data) {
  return {
    api: PatientApi.getPerioExamVoidedList,
    types: [
      ActionType.PATIENT_PERIO_EXAM_LIST_START,
      ActionType.PATIENT_PERIO_EXAM_LIST_SUCECSS,
      ActionType.PATIENT_PERIO_EXAM_LIST_ERROR,
    ],
    data,
  };
}

function perioExamItemApi(data) {
  return {
    api: PatientApi.getPerioExamItem,
    types: [
      ActionType.PATIENT_PERIO_EXAM_ITEM_START,
      ActionType.PATIENT_PERIO_EXAM_ITEM_SUCECSS,
      ActionType.PATIENT_PERIO_EXAM_ITEM_ERROR,
    ],
    data,
  };
}

// region <Appointment methods>
export function searchPatients(data) {
  return function (dispatch) {
    dispatch({
      type: "searchPatients",
    });

    dispatch(startLoader());
    return dispatch(searchPatient(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getPatientDetails(data) {
  return function (dispatch, getState) {
    dispatch({
      type: "getPatientDetails",
    });
    dispatch(startLoader());
    const {
      appointment: { appointment },
    } = getState();

    let patientKey;
    if (data) {
      patientKey = data.patientKey;
      if (!patientKey && appointment && appointment.patient) {
        patientKey = appointment.patient.patientKey;
      }
    } else {
      patientKey = data.patientKey;
    }

    return dispatch(getPatientDetailsApi({ patientKey }))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getPatientGenderList() {
  return function (dispatch) {
    dispatch({
      type: "getPatientGenderList",
    });
    dispatch(startLoader());
    return dispatch(getPatientGender())
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPriceList(data) {
  return function (dispatch) {
    dispatch({
      type: "getPriceList",
    });

    dispatch(startLoader());

    return dispatch(getPriceListFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
function searchPatient(data) {
  return {
    api: PatientApi.searchPatients,
    types: [
      ActionType.SEARCH_PATIENT_LIST_START,
      ActionType.SEARCH_PATIENT_LIST_SUCCESS,
      ActionType.SEARCH_PATIENT_LIST_ERROR,
    ],
    data,
  };
}
function getPatientDetailsApi(data) {
  return {
    api: PatientApi.getDetailsInfo,
    types: [
      ActionType.GET_PATIENT_DETAILS_START,
      ActionType.GET_PATIENT_DETAILS_SUCCESS,
      ActionType.GET_PATIENT_DETAILS_ERROR,
    ],
    data,
  };
}
function getPatientGender(data) {
  return {
    api: PatientApi.getGender,
    types: [
      ActionType.GET_PATIENT_GENDER_START,
      ActionType.GET_PATIENT_GENDER_SUCCESS,
      ActionType.GET_PATIENT_GENDER_ERROR,
    ],
    data,
  };
}

function getPriceListFn(data) {
  return {
    api: PatientApi.getPriceList,
    types: [
      ActionType.GET_PATIENT_PRICE_LIST_START,
      ActionType.GET_PATIENT_PRICE_LIST_SUCCESS,
      ActionType.GET_PATIENT_PRICE_LIST_ERROR,
    ],
    data,
  };
}
// endregion

// region <Insurance methods>

export function initInsuranceForm(data) {
  return function (dispatch) {
    dispatch({
      type: "initInsuranceForm",
    });

    dispatch(startLoader());
    const requests = [];
    requests.push(dispatch(getCompanyFn()));
    requests.push(dispatch(getRelationFn()));
    if (data) {
      requests.push(dispatch(getCompanyPlanFn(data)));
      requests.push(dispatch(getBrokerFn(data)));
    }
    return Promise.all(requests)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getInsuranceList(data) {
  return function (dispatch) {
    dispatch({
      type: "getInsuranceList",
    });
    dispatch(startLoader());

    // const {patient} = getState();

    return dispatch(getInsuranceListFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getPaymentOptions(data) {
  return function (dispatch) {
    dispatch({
      type: "getPaymentOptions",
    });

    return dispatch(paymentInsuranceFn(data)).then((response) => {
      const hasPatientInsurance = _.get(response, "insuranceCompanyCount", 0) > 0;

      dispatch({
        type: ActionTypes.CHART_ADD_SELECTED_TEETH,
        hasPatientInsurance,
      });

      return response;
    });
  };
}
export function setInsuranceList(data) {
  return {
    type: ActionType.SET_INSURANCE_LIST,
    data,
  };
}

export function getOtherFieldsBySelectedCompany(data) {
  return function (dispatch) {
    dispatch({
      type: "getCompanyPlan",
    });

    dispatch(startLoader());

    return Promise.all([dispatch(getCompanyPlanFn(data)), dispatch(getBrokerFn(data))])
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function getCompanyPlan(data) {
  return function (dispatch) {
    dispatch({
      type: "getCompanyPlan",
    });

    dispatch(startLoader());

    return dispatch(getCompanyPlanFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function saveInsuranceItem(data) {
  return function (dispatch) {
    dispatch({
      type: "saveInsuranceItem",
    });

    dispatch(startLoader());

    return dispatch(saveInsuranceItemFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function deleteInsuranceItem(data) {
  return function (dispatch) {
    dispatch({
      type: "deleteInsuranceItem",
    });

    dispatch(startLoader());

    return dispatch(deleteInsuranceItemFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export function searchByCardNumber(data) {
  return function (dispatch) {
    dispatch({
      type: "searchByCardNumber",
    });

    dispatch(startLoader());

    return dispatch(searchByCardNumberFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function markAsPrimary(data) {
  return function (dispatch) {
    dispatch({
      type: "markAsPrimary",
    });

    dispatch(startLoader());

    return dispatch(markAsPrimaryFn(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getInsuranceListFn(data) {
  return {
    api: PatientApi.item.getList,
    types: [
      ActionType.GET_INSURANCE_LIST_START,
      ActionType.GET_INSURANCE_LIST_SUCCESS,
      ActionType.GET_INSURANCE_LIST_ERROR,
    ],
    data,
  };
}
export function paymentInsuranceFn(data) {
  return {
    api: PatientApi.paymentOptions,
    types: [
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_START,
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_SUCCESS,
      ActionType.GET_PATIENT_PAYMENT_OPTIONS_ERROR,
    ],
    data,
  };
}

function saveInsuranceItemFn(data) {
  return {
    api: PatientApi.item.saveItem,
    types: [
      ActionType.SAVE_INSURANCE_ITEM_START,
      ActionType.SAVE_INSURANCE_ITEM_SUCCESS,
      ActionType.SAVE_INSURANCE_ITEM_ERROR,
    ],
    data,
  };
}

function deleteInsuranceItemFn(data) {
  return {
    api: PatientApi.item.status,
    types: [
      ActionType.DELETE_INSURANCE_ITEM_START,
      ActionType.DELETE_INSURANCE_ITEM_SUCCESS,
      ActionType.DELETE_INSURANCE_ITEM_ERROR,
    ],
    data,
  };
}

function getCompanyFn(data) {
  return {
    api: PatientApi.item.getFields.getCompany,
    types: [
      ActionType.GET_INSURANCE_COMPANY_START,
      ActionType.GET_INSURANCE_COMPANY_SUCCESS,
      ActionType.GET_INSURANCE_COMPANY_ERROR,
    ],
    data,
  };
}
function getCompanyPlanFn(data) {
  return {
    api: PatientApi.item.getFields.getCompanyPlan,
    types: [
      ActionType.GET_INSURANCE_COMPANY_PLAN_START,
      ActionType.GET_INSURANCE_COMPANY_PLAN_SUCCESS,
      ActionType.GET_INSURANCE_COMPANY_PLAN_ERROR,
    ],
    data,
  };
}
function getRelationFn(data) {
  return {
    api: PatientApi.item.getFields.getRelation,
    types: [
      ActionType.GET_INSURANCE_RELATION_START,
      ActionType.GET_INSURANCE_RELATION_SUCCESS,
      ActionType.GET_INSURANCE_RELATION_ERROR,
    ],
    data,
  };
}
function getBrokerFn(data) {
  return {
    api: PatientApi.item.getFields.getBroker,
    types: [
      ActionType.GET_INSURANCE_BROKER_START,
      ActionType.GET_INSURANCE_BROKER_SUCCESS,
      ActionType.GET_INSURANCE_BROKER_ERROR,
    ],
    data,
  };
}
function searchByCardNumberFn(data) {
  return {
    api: PatientApi.item.getFields.getSeachByCardNumber,
    types: [
      ActionType.SEARCH_BY_CARD_NUMBER_START,
      ActionType.SEARCH_BY_CARD_NUMBER_SUCCESS,
      ActionType.SEARCH_BY_CARD_NUMBER_ERROR,
    ],
    data,
  };
}
function markAsPrimaryFn(data) {
  return {
    api: PatientApi.item.markAsPrimary,
    types: [
      ActionType.MARK_AS_PRIMARY_INSURANCE_START,
      ActionType.MARK_AS_PRIMARY_INSURANCE_SUCCESS,
      ActionType.MARK_AS_PRIMARY_INSURANCE_ERROR,
    ],
    data,
  };
}

export const perioExamHandleItem = (data) => {
  return {
    type: ActionType.PERIO_EXAM_HANDLE_ITEM,
    data,
  };
};
export const perioExamTempItem = (data) => {
  return {
    type: ActionType.PERIO_EXAM_TEMP_ITEM,
    data,
  };
};
export const perioExamItemListProps = (data) => {
  return {
    type: ActionType.PERIO_EXAM_ITEM_TEMP_LIST,
    data,
  };
};
export function perioExamStartItem(data) {
  return {
    api: PatientApi.startPerioExamItem,
    types: [
      ActionType.PATIENT_PERIO_EXAM_START_ITEM_START,
      ActionType.PATIENT_PERIO_EXAM_START_ITEM_SUCECSS,
      ActionType.PATIENT_PERIO_EXAM_START_ITEM_ERROR,
    ],
    data,
  };
}
export const perioExamTempClearItem = () => {
  return {
    type: ActionType.PERIO_EXAM_TEMP_CLEAR_ITEM,
  };
};

export const perioExamItemHideModal = () => {
  return {
    type: ActionType.PERIO_EXAM_ITEM_HIDE_MODAL,
  };
};

export const handleMovementType = (data) => {
  return {
    type: ActionType.PERIO_EXAM_MOVEMENT_TYPE,
    data,
  };
};

function getPatientTagsListApi(data) {
  return {
    api: PatientApi.getTagsList,
    types: [
      ActionType.GET_PATIENT_TAGS_LIST_START,
      ActionType.GET_PATIENT_TAGS_LIST_SUCCESS,
      ActionType.GET_PATIENT_TAGS_LIST_ERROR,
    ],
    data,
  };
}

function getPatientAssignedTagsListApi(data) {
  return {
    api: PatientApi.getAssignedTagsList,
    types: [
      ActionType.GET_PATIENT_ASSIGNED_TAGS_START,
      ActionType.GET_PATIENT_ASSIGNED_TAGS_SUCCESS,
      ActionType.GET_PATIENT_ASSIGNED_TAGS_ERROR,
    ],
    data,
  };
}
