import "./assets/less/vendor.less";
import "./assets/less/main.less";

import "./initLocalStorage";

import React from "react";
import { render } from "react-dom";

import store from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { AppContainer } from "./containers/AppContainer";

export { store };

render(<AppContainer />, document.getElementById("react"));

if (module.hot) {
  module.hot.accept();
}

reportWebVitals();
