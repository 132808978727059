import React from "react";
import { useHistory } from "react-router";
import BackImage from "../../assets/images/white-icon-back.png";
import FilledImage from "./FilledImage";

export default function BackButton({
  element,
  text,
  className,
  onClick,
  style,
  color = "#ffffff",
}) {
  const history = useHistory();

  let $el = null;
  if (element) {
    if (element.visible) {
      $el = (
        <button
          type="button"
          style={style}
          className={`btn btn-link btn-back  ${className}`}
          onClick={(e) => (onClick ? onClick(e) : history.goBack())}
          disabled={element.disabled}
        >
          <img src={BackImage} width={12} className="mr-1" alt="" />
          {element.value}
        </button>
      );
    }
  } else {
    $el = (
      <button
        type="button"
        style={style}
        className={`btn btn-link btn-back  ${className}`}
        onClick={(e) => (onClick ? onClick(e) : history.goBack())}
      >
        <div className="d-flex align-items-center">
          <FilledImage color={color} src={BackImage} zoom={23} className="mr-1" alt="" />
          <span>{text || "Back"}</span>
        </div>
      </button>
    );
  }

  return $el;
}
