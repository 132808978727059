import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";

const enhancer = connect(null, bindActions({ calendarActions }));

class FindOpenSlotSidebarHeader extends React.PureComponent {
  render() {
    const { searchMode, formSearchMode, calendarActions } = this.props;

    return (
      <div className="modal-header clearfix">
        <button
          className="btn btn-link"
          onClick={() => {
            if (searchMode) {
              calendarActions.findOpenSlotSidebarHide();
            } else {
              calendarActions.toggleSearchMode();
            }

            calendarActions.switchOpenSlotReserved(false);
          }}
          type="button"
        >
          ❮ &nbsp; BACK
        </button>
        <Show if={searchMode}>
          <button
            type="button"
            className="btn btn-link pull-right"
            onClick={() => calendarActions.searchFreePoint(formSearchMode)}
          >
            SEARCH
          </button>
        </Show>
      </div>
    );
  }
}

export default enhancer(FindOpenSlotSidebarHeader);
