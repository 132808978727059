import createReducer from "./createReducer";
import * as patientRegisterActionTypes from "../actionTypes/patientRegisterActionTypes";
import * as chartSidePlanActionTypes from "../actionTypes/chartSidePlanActionTypes";
import * as blockedTimeSlotActionTypes from "../actionTypes/blockedTimeSlotActionTypes";
import * as repeatAppointmentActionTypes from "../actionTypes/repeatAppointmentActionTypes";

const patientDetailsEdit = createReducer(
  {},
  {
    [patientRegisterActionTypes.PATIENT_REGISTER_CHOOSE_COUNTRY](state) {
      return {
        ...state,
        values: {
          ...state.values,
          state: 0,
          city: 0,
        },
      };
    },
    [patientRegisterActionTypes.PATIENT_REGISTER_CHOOSE_STATE](state) {
      return {
        ...state,
        values: {
          ...state.values,
          city: 0,
        },
      };
    },
  },
);

const chartPlanForName = createReducer(
  {},
  {
    [chartSidePlanActionTypes.SIDE_PLAN_DISCOUNT_AMOUNT_CHANGE](state, { value }) {
      return {
        ...state,
        values: {
          ...state.values,
          discountPercentage: undefined,
          discountAmount: value,
        },
      };
    },
    [chartSidePlanActionTypes.SIDE_PLAN_DISCOUNT_PERCENTAGE_CHANGE](state, { value }) {
      return {
        ...state,
        values: {
          ...state.values,
          discountPercentage: value,
          discountAmount: undefined,
        },
      };
    },
  },
);

const blockedTimeSlot = createReducer(
  {},
  {
    [blockedTimeSlotActionTypes.BLOCKED_TIME_SLOT_CHOOSE_DOCTOR](state) {
      return {
        ...state,
        values: {
          ...state.values,
          points: [],
        },
      };
    },
  },
);

const repeatAppointmentAnotherDoctor = createReducer(
  {},
  {
    [repeatAppointmentActionTypes.REPEAT_APPT_GET_TRX_ROOMS_SUCCESS](state) {
      return {
        ...state,
        values: {
          ...state.values,
          trxRoom: 0,
        },
      };
    },
  },
);

export default {
  patientDetailsEdit,
  chartPlanForName,
  blockedTimeSlot,
  repeatAppointmentAnotherDoctor,
};
