import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Show from "../../../widgets/Show";

import bindActions from "../../../../helpers/bindActions";
import * as chartActions from "../../../../actions/chartActions";
import { getAppointmentTransactions } from "../../../../actions/transactionActions";
import { Modal } from "../../../ui/Modal";

const enhancer = connect(
  ({ transaction }) => ({ transaction }),
  bindActions({ chartActions, getAppointmentTransactions }),
);
export default enhancer(
  class SelectTreatmentToCompleteDiagnosisList extends React.Component {
    constructor(props) {
      super(props);
      this.state = { loader: false };
    }
    componentDidMount() {
      this.props.getAppointmentTransactions(true, true, {
        diagnosisCompleted: false,
        diagnosisPlanned: true,
        startVisit: true,
      });
    }

    toggleSelectedItem = (transactionKey, _, clear) => {
      this.props.chartActions.selectTreatmentToCompleteToggleSelectedItem(transactionKey, clear);
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartActions.selectTreatmentToCompleteLoadList();
      }
    };

    getChecked = (id) => {
      const {
        chart: { selectTreatmentToCompleteList, selectTreatmentToCompleteSelectedItems },
      } = this.props;
      let checked = false;
      for (const [key, property] of Object.entries(selectTreatmentToCompleteSelectedItems)) {
        // eslint-disable-next-line no-loop-func
        selectTreatmentToCompleteList.forEach((item) => {
          if (property && item.key === key && item.id === id) {
            checked = true;
          }
        });
      }
      return checked;
    };

    setPrimaryDiagnosis = (checked, key) => {
      this.setState({ loader: true });
      const {
        startVisitTransaction: { list: startVisitList = [] },
      } = this.props.transaction;
      const transfer = {
        key,
        diagnosisClaimType: checked ? { code: "Principal" } : { code: "Secondary" },
      };
      // diagnosisClaimCodes:
      // "Principal",
      // "Secondary",
      // "Admitting",
      // "ReasonForVisit"
      startVisitList.forEach((item) => {
        if (
          item.type.code === "DIAGNOSIS" &&
          checked &&
          item.key !== key &&
          item.diagnosisClaimType?.code === "Principal"
        ) {
          this.props.chartActions.setPrimaryDiagnosis({
            key: item.key,
            diagnosisClaimType: { code: "Secondary" },
          });
          this.toggleSelectedItem(item.key, "DIAGNOSIS", "false");
        }
      });
      this.toggleSelectedItem(key, "DIAGNOSIS");
      this.props.chartActions
        .setPrimaryDiagnosis(transfer)
        .then(() => {
          this.props.getAppointmentTransactions(true, true, {
            diagnosisCompleted: false,
            diagnosisPlanned: true,
            startVisit: true,
          });
          this.setState({ loader: false });
        })
        .finally(() => {
          this.setState({ loader: false });
        });
    };

    render() {
      const {
        chart: { selectTreatmentToCompleteSelectedItems, selectTreatmentToCompleteListFetching },
        transaction: {
          startVisitTransaction: { list: transactionList },
        },
        selectTreatmentToCompleteModalMode,
      } = this.props;

      let list = [];
      transactionList &&
        transactionList.length &&
        transactionList
          .filter(
            (diagnosis) =>
              diagnosis.type.code.toLowerCase() === "diagnosis" &&
              diagnosis.status.name.toLowerCase() === "planned",
          )
          .sort((a, b) => b.createdDate - a.createdDate)
          .forEach((diagnosis) => {
            list.push(
              <div className="select-treatment-list-row" key={diagnosis.key}>
                <div className="item-date-posted">
                  {moment(diagnosis.createdDate).format(DATE_FORMAT_LEGACY)}
                </div>
                <div className="item-treatment-code">{diagnosis.diagnosisItem.code}</div>
                <div className="item-tooth">{diagnosis.tooth && diagnosis.tooth.name}</div>
                <div className="item-description">{diagnosis.diagnosisItem.description}</div>
                <div className="item-date-posted d-flex align-items-center justify-content-center">
                  <input
                    type="checkbox"
                    className="scaled-2 m-0"
                    onClick={({ target: { checked } }) => {
                      this.setPrimaryDiagnosis(checked, diagnosis.key);
                      // diagnosis?.diagnosisClaimType?.code === "Principal" &&
                      //   this.toggleSelectedItem(diagnosis.key, "DIAGNOSIS");
                    }}
                    checked={diagnosis?.diagnosisClaimType?.code === "Principal"}
                    readOnly={true}
                  />
                </div>
                <div className="item-posted-by">
                  {diagnosis.createdDentist && diagnosis.createdDentist.name}
                </div>
                <div className="item-checkbox">
                  <input
                    type="checkbox"
                    className="scaled-2"
                    onClick={() => {
                      this.toggleSelectedItem(diagnosis.key, "DIAGNOSIS");
                    }}
                    checked={
                      diagnosis?.diagnosisClaimType?.code === "Principal"
                        ? true
                        : this.getChecked(diagnosis.parentId) ||
                          selectTreatmentToCompleteSelectedItems[diagnosis.key]
                    }
                    readOnly={true}
                    // disabled={diagnosis?.diagnosisClaimType?.code === "Principal"}
                  />
                </div>
              </div>,
            );
          });

      return (
        <div
          className={`${
            selectTreatmentToCompleteModalMode === "finish" ||
            selectTreatmentToCompleteModalMode === "post"
              ? "select-treatment-list-table treatment-finish-mode"
              : "select-treatment-list-table"
          }`}
        >
          <div className="select-treatment-list-header">
            <div className="item-date-posted">Date posted</div>
            <div className="item-treatment-code">Diagnosis Code</div>
            <div className="item-tooth">Tooth</div>
            <div className="item-description">Description</div>
            <div className="item-date-posted">Principal Dx</div>

            <div className="item-posted-by">Posted by</div>
            <div className="item-checkbox" style={{ marginRight: "5px" }} />
          </div>
          <div className="select-treatment-list-body" onScroll={this.loadList}>
            {list}
            <Show if={selectTreatmentToCompleteListFetching}>
              <div className="select-treatment-list-row">
                <ReactSpinner />
              </div>
            </Show>
          </div>
          <Modal size="sm" show={this.state.loader} keyboard={false}>
            <div style={{ height: 70 }}>
              <ReactSpinner />
            </div>
          </Modal>
        </div>
      );
    }
  },
);
