import React from "react";
import { connect } from "react-redux";

import bindActions from "../../../helpers/bindActions";
import * as appointmentActions from "../../../actions/appointmentActions";
import DotImage from "../../../assets/images/dot.png";

const enhancer = connect(null, bindActions({ appointmentActions }));

function RepeatAppointmentFollowTypeSelect({ selected, label, mode, ...props }) {
  return (
    <div
      role="button"
      className="repeat-appointment-follow-type-select-block"
      onClick={() => {
        props.appointmentActions.repeatAppointmentSetFollowType(mode);
      }}
    >
      <div className={"repeat-appointment-follow-type-select"}>
        {selected && <img src={DotImage} className="img-responsive" alt="" />}
      </div>
      <label>{label}</label>
    </div>
  );
}

export default enhancer(RepeatAppointmentFollowTypeSelect);
