import React from "react";
import { connect } from "react-redux";

import Utils from "../../../../helpers/Utils";
import Show from "../../../widgets/Show";
import viewTypes from "../../../../constants/chart-sidebar/viewTypes";
import {
  showSideBar,
  setSideBarTabType,
  setAddDiagnosisForComplete,
} from "../../../../actions/chartActions";
import SelectTreatmentToCompleteTreatmentList from "./SelectTreatmentToCompleteTreatmentList";
import SelectTreatmentToCompleteDiagnosisList from "./SelectTreatmentToCompleteDiagnosisList";

const enhancer = connect(() => ({}), {
  showSideBar,
  setSideBarTabType,
  setAddDiagnosisForComplete,
});

export default enhancer(
  class SelectTreatmentToCompleteFinishMode extends React.Component {
    render() {
      const {
        chart,
        clinicId,
        showSideBar,
        setSideBarTabType,
        setAddDiagnosisForComplete,
        totalNetAmount,
        selectTreatmentToCompleteModalMode,
        totalSelectedService,
        totalDiscountGiven,
        totalPatientShare,
        updatedTotalNetAmount,
      } = this.props;

      const { selectTreatmentToCompleteModalFinishMode } = this.props.chart;
      const _totalNetAmount = updatedTotalNetAmount > 0 ? updatedTotalNetAmount : totalNetAmount;

      return (
        <div className="select-treatment-to-complete-finish-mode" style={{ height: "100%" }}>
          <Show
            if={
              selectTreatmentToCompleteModalFinishMode === "treatment" ||
              selectTreatmentToCompleteModalFinishMode === "diagnosis"
            }
          >
            <div style={{ height: "100%" }}>
              <div
                style={
                  selectTreatmentToCompleteModalMode === "finish" ||
                  selectTreatmentToCompleteModalMode === "post"
                    ? { height: "40%" }
                    : { height: "50%" }
                }
              >
                <div
                  className="btn align-items-center flex-box mt-2 mb-2 w-100"
                  style={
                    selectTreatmentToCompleteModalMode === "finish" ||
                    selectTreatmentToCompleteModalMode === "post"
                      ? {
                          width: "100%",
                          backgroundColor: "#f5f5f5",
                          color: "#000",
                          borderColor: "#000",
                        }
                      : { backgroundColor: "#bec844", color: "#fff", borderColor: "#bec844" }
                  }
                >
                  {selectTreatmentToCompleteModalMode === "finish" ||
                  selectTreatmentToCompleteModalMode === "post"
                    ? "Treatments/Services"
                    : "Treatments"}
                </div>
                <SelectTreatmentToCompleteTreatmentList clinicId={clinicId} chart={chart} />
              </div>
              <div
                style={
                  selectTreatmentToCompleteModalMode === "finish" ||
                  selectTreatmentToCompleteModalMode === "post"
                    ? { height: "60%" }
                    : { height: "50%" }
                }
              >
                <div className="d-flex">
                  <div
                    className="btn align-items-center flex-box mt-2 mb-2 w-100"
                    style={
                      selectTreatmentToCompleteModalMode === "finish" ||
                      selectTreatmentToCompleteModalMode === "post"
                        ? {
                            flexGrow: 8,
                            marginRight: "1rem",
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            borderColor: "#000",
                          }
                        : {
                            flexGrow: 8,
                            marginRight: "1rem",
                            backgroundColor: "#bec844",
                            color: "#fff",
                            borderColor: "#bec844",
                          }
                    }
                  >
                    Diagnosis
                  </div>
                  <button
                    type="button"
                    style={{ flexGrow: 4 }}
                    onClick={() => {
                      setAddDiagnosisForComplete();
                      showSideBar(viewTypes.series, {
                        activeTooth: 0,
                      });
                      setSideBarTabType("diagnosis");
                    }}
                    className="btn btn-primary align-items-center flex-box mt-2 mb-2 w-100"
                  >
                    Add diagnosis
                  </button>
                </div>
                <SelectTreatmentToCompleteDiagnosisList
                  selectTreatmentToCompleteModalMode={selectTreatmentToCompleteModalMode}
                  clinicId={clinicId}
                  chart={chart}
                />
                {(selectTreatmentToCompleteModalMode === "finish" ||
                  selectTreatmentToCompleteModalMode === "post") && (
                  <div className="d-flex flex-column align-items-end pt-2 pb-2">
                    <div
                      className="p-1"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "4px",
                        width: "265px",
                        paddingLeft: "30px",
                      }}
                    >
                      <div>
                        Total selected services:{" "}
                        <b className="text-black">
                          {Utils.formatPrice(totalSelectedService.toFixed(2))}
                        </b>
                      </div>
                      <div>
                        Total discount given:{" "}
                        <b className="text-black">
                          {Utils.formatPrice(totalDiscountGiven.toFixed(2))}
                        </b>
                      </div>
                      <div>
                        Total Net amount:{" "}
                        <b
                          className="text-black"
                          style={{ color: updatedTotalNetAmount > 0 ? "#007bff" : "#000" }}
                        >
                          {Utils.formatPrice(_totalNetAmount.toFixed(2))}
                        </b>
                      </div>
                      <div>
                        Total patient share:{" "}
                        <b className="text-black">
                          {Utils.formatPrice(totalPatientShare.toFixed(2))}
                        </b>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Show>
        </div>
      );
    }
  },
);
