export const INIT_SIDE_SERIES = "INIT_SIDE_SERIES";

export const CLEAR_ADDED_ITEMS = "CLEAR_ADDED_ITEMS";

export const CLEAR_SEARCH_VALUES = "CLEAR_SEARCH_VALUES";

export const CLEAR_DIAGNOSIS_ITEMS = "CLEAR_DIAGNOSIS_ITEMS";

export const CHANGE_SIDE_BAR_SERIES_MODE = "CHANGE_SIDE_BAR_SERIES_MODE";
export const CHANGE_SIDE_BAR_SERIES_TYPE = "CHANGE_SIDE_BAR_SERIES_TYPE";

export const PUSH_SERIES_STACK = "PUSH_SERIES_STACK";
export const POP_SERIES_STACK = "POP_SERIES_STACK";

export const GET_TREATMENT_PACKET_START = "GET_TREATMENT_PACKET_START";
export const GET_TREATMENT_PACKET_SUCCESS = "GET_TREATMENT_PACKET_SUCCESS";
export const GET_TREATMENT_PACKET_ERROR = "GET_TREATMENT_PACKET_ERROR";

export const GET_FAVOURITES_ICONS_START = "GET_FAVOURITES_ICONS_START";
export const GET_FAVOURITES_ICONS_SUCCESS = "GET_FAVOURITES_ICONS_SUCCESS";
export const GET_FAVOURITES_ICONS_ERROR = "GET_FAVOURITES_ICONS_ERROR";

export const GET_SPECIALITIES_START = "GET_SPECIALITIES_START";
export const GET_SPECIALITIES_SUCCESS = "GET_SPECIALITIES_SUCCESS";
export const GET_SPECIALITIES_ERROR = "GET_SPECIALITIES_ERROR";

export const GET_TREATMENT_LIST_START = "GET_TREATMENT_LIST_START";
export const GET_TREATMENT_LIST_SUCCESS = "GET_TREATMENT_LIST_SUCCESS";
export const GET_TREATMENT_LIST_ERROR = "GET_TREATMENT_LIST_ERROR";

export const GET_DIAGNOSIS_LIST_START = "GET_DIAGNOSIS_LIST_START";
export const GET_DIAGNOSIS_LIST_SUCCESS = "GET_DIAGNOSIS_LIST_SUCCESS";
export const GET_DIAGNOSIS_LIST_ERROR = "GET_DIAGNOSIS_LIST_ERROR";

export const GET_TREATMENT_MIXID_LIST_START = "GET_TREATMENT_MIXID_LIST_START";
export const GET_TREATMENT_MIXID_LIST_SUCCESS = "GET_TREATMENT_MIXID_LIST_SUCCESS";
export const GET_TREATMENT_MIXID_LIST_ERROR = "GET_TREATMENT_MIXID_LIST_ERROR";

export const GET_DIAGNOSIS_MIXID_LIST_START = "GET_DIAGNOSIS_MIXID_LIST_START";
export const GET_DIAGNOSIS_MIXID_LIST_SUCCESS = "GET_DIAGNOSIS_MIXID_LIST_SUCCESS";
export const GET_DIAGNOSIS_MIXID_LIST_ERROR = "GET_DIAGNOSIS_MIXID_LIST_ERROR";

export const GET_PROCEDURE_LIST_START = "GET_PROCEDURE_LIST_START";
export const GET_PROCEDURE_LIST_SUCCESS = "GET_PROCEDURE_LIST_SUCCESS";
export const GET_PROCEDURE_LIST_ERROR = "GET_PROCEDURE_LIST_ERROR";

export const GET_TYPE_AHEAD_LIST_START = "GET_TYPE_AHEAD_LIST_START";
export const GET_TYPE_AHEAD_LIST_SUCCESS = "GET_TYPE_AHEAD_LIST_SUCCESS";
export const GET_TYPE_AHEAD_LIST_ERROR = "GET_TYPE_AHEAD_LIST_ERROR";

export const GET_SIDE_LIST = "GET_SIDE_LIST";
export const LOAD_SIDE_LIST = "LOAD_SIDE_LIST";
export const GET_SIDE_LIST_END = "GET_SIDE_LIST_END";

export const GET_FAVOURITE_TREATMENTS_START = "GET_FAVOURITE_TREATMENTS_START";
export const GET_FAVOURITE_TREATMENTS_SUCCESS = "GET_FAVOURITE_TREATMENTS_SUCCESS";
export const GET_FAVOURITE_TREATMENTS_ERROR = "GET_FAVOURITE_TREATMENTS_ERROR";

export const GET_FAVOURITE_DIAGNOSIS_START = "GET_FAVOURITE_DIAGNOSIS_START";
export const GET_FAVOURITE_DIAGNOSIS_SUCCESS = "GET_FAVOURITE_DIAGNOSIS_SUCCESS";
export const GET_FAVOURITE_DIAGNOSIS_ERROR = "GET_FAVOURITE_DIAGNOSIS_ERROR";

export const GET_FAVORITE_MIXED_DIAGNOSIS_START = "GET_FAVORITE_MIXED_DIAGNOSIS_START";
export const GET_FAVORITE_MIXED_DIAGNOSIS_SUCCESS = "GET_FAVORITE_MIXED_DIAGNOSIS_SUCCESS";
export const GET_FAVORITE_MIXED_DIAGNOSIS_ERROR = "GET_FAVORITE_MIXED_DIAGNOSIS_ERROR";

export const GET_FAVORITE_MIXED_EXISTING_START = "GET_FAVORITE_MIXED_EXISTING_START";
export const GET_FAVORITE_MIXED_EXISTING_SUCCESS = "GET_FAVORITE_MIXED_EXISTING_SUCCESS";
export const GET_FAVORITE_MIXED_EXISTING_ERROR = "GET_FAVORITE_MIXED_EXISTING_ERROR";

export const CHART_CLEAR_FAVORITES = "CHART_CLEAR_FAVORITES";

export const GET_FAVOURITES = "GET_FAVOURITES";
export const GET_FAVOURITES_END = "GET_FAVOURITES_END";

export const SHOW_IMAGE_SELECTOR = "SHOW_IMAGE_SELECTOR";
export const HIDE_IMAGE_SELECTOR = "HIDE_IMAGE_SELECTOR";

export const CHART_SEARCH_CHANGE = "CHART_SEARCH_CHANGE";

export const CHART_SEARCH_GROUP_CHANGE = "CHART_SEARCH_GROUP_CHANGE";

export const CHART_HIDE_SIDE_BAR = "CHART_HIDE_SIDE_BAR";

export const CHART_SIDE_SERIES_GET_TREATMENT_ITEM_START =
  "CHART_SIDE_SERIES_GET_TREATMENT_ITEM_START";
export const CHART_SIDE_SERIES_GET_TREATMENT_ITEM_SUCCESS =
  "CHART_SIDE_SERIES_GET_TREATMENT_ITEM_SUCCESS";
export const CHART_SIDE_SERIES_GET_TREATMENT_ITEM_ERROR =
  "CHART_SIDE_SERIES_GET_TREATMENT_ITEM_ERROR";

export const SELECT_TOOTH_ON_TEETH_SLIDER = "SELECT_TOOTH_ON_TEETH_SLIDER";

export const CHANGE_FROM_FOR_SIDE_BAR_ITEM = "CHANGE_FROM_FOR_SIDE_BAR_ITEM";
export const CHANGE_FROM_FOR_SIDE_BAR_ITEM_FORM = "CHANGE_FROM_FOR_SIDE_BAR_ITEM_FORM";
export const CHANGE_FROM_FOR_SIDE_BAR_ITEM_BY_KEY = "CHANGE_FROM_FOR_SIDE_BAR_ITEM_BY_KEY";
export const SELECT_SHAPE_AREA_DETAILS = "SELECT_SHAPE_AREA_DETAILS ";
export const CHANGE_TRANSACTION_ITEM_FORM = "CHANGE_TRANSACTION_ITEM_FORM";

export const SET_SURFACE_SLIDER_ITEMS_ARRAY = "SET_SURFACE_SLIDER_ITEMS_ARRAY";

export const BATCH_ITEMS_FOR_SIDE_BAR_ITEM_START = "BATCH_ITEMS_FOR_SIDE_BAR_ITEM_START";
export const BATCH_ITEMS_FOR_SIDE_BAR_ITEM_SUCCESS = "BATCH_ITEMS_FOR_SIDE_BAR_ITEM_SUCCESS";
export const BATCH_ITEMS_FOR_SIDE_BAR_ITEM_ERROR = "BATCH_ITEMS_FOR_SIDE_BAR_ITEM_ERROR";

export const CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_START =
  "CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_START";
export const CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_SUCCESS =
  "CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_SUCCESS";
export const CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_ERROR =
  "CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_ERROR";

export const CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_START =
  "CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_START";
export const CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_SUCCESS =
  "CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_SUCCESS";
export const CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_ERROR =
  "CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_ERROR";

export const CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_START =
  "CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_START";
export const CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_SUCCESS =
  "CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_SUCCESS";
export const CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_ERROR =
  "CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_ERROR";

export const CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_START =
  "CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_START";
export const CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_SUCCESS =
  "CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_SUCCESS";
export const CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_ERROR =
  "CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_ERROR";

export const CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_START =
  "CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_START";
export const CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_SUCCESS =
  "CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_SUCCESS";
export const CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_ERROR =
  "CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_ERROR";

export const CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_START =
  "CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_START";
export const CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_SUCCESS =
  "CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_SUCCESS";
export const CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_ERROR =
  "CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_ERROR";

export const CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_START =
  "CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_START";
export const CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_SUCCESS =
  "CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_SUCCESS";
export const CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_ERROR =
  "CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_ERROR";

export const CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_START =
  "CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_START";
export const CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_SUCCESS =
  "CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_SUCCESS";
export const CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_ERROR =
  "CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_ERROR";

export const CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_START = "CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_START";
export const CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_SUCCESS =
  "CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_SUCCESS";
export const CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_ERROR = "CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_ERROR";

export const CHART_SIDE_SERIES_SAVE_LAB_ORDER_START = "CHART_SIDE_SERIES_SAVE_LAB_ORDER_START";
export const CHART_SIDE_SERIES_SAVE_LAB_ORDER_SUCCESS = "CHART_SIDE_SERIES_SAVE_LAB_ORDER_SUCCESS";
export const CHART_SIDE_SERIES_SAVE_LAB_ORDER_ERROR = "CHART_SIDE_SERIES_SAVE_LAB_ORDER_ERROR";

export const OPEN_DIAGNOSIS_LIST = "OPEN_DIAGNOSIS_LIST";
export const REMOVE_DIAGNOSIS_ITEM = "REMOVE_DIAGNOSIS_ITEM";

export const CUSTOM_PRICE_MODAL_KEY_PRESS = "CUSTOM_PRICE_MODAL_KEY_PRESS";
export const CHANGE_CUSTOM_PRICE = "CHANGE_CUSTOM_PRICE";
export const TOGGLE_CUSTOM_PRICE = "TOGGLE_CUSTOM_PRICE";

export const EDIT_TRANSACTION_ITEM = "EDIT_TRANSACTION_ITEM";
export const OPEN_CHART_SIDE_TRANSACTION_ITEM = "OPEN_CHART_SIDE_TRANSACTION_ITEM";

export const CHART_SIDE_SERIES_OPEN_PROCEDURE_CONFIRM_MODAL =
  "CHART_SIDE_SERIES_OPEN_PROCEDURE_CONFIRM_MODAL";
export const CHART_SIDE_SERIES_CLOSE_PROCEDURE_CONFIRM_MODAL =
  "CHART_SIDE_SERIES_CLOSE_PROCEDURE_CONFIRM_MODAL";

export const CHART_SIDE_SERIES_OPEN_DOCUMENTS_ASSIGNED_MODAL =
  "CHART_SIDE_SERIES_OPEN_DOCUMENTS_ASSIGNED_MODAL";
export const CHART_SIDE_SERIES_CLOSE_DOCUMENTS_ASSIGNED_MODAL =
  "CHART_SIDE_SERIES_CLOSE_DOCUMENTS_ASSIGNED_MODAL";

export const DOCUMENTS_ASSIGNED_INSIDE_MODAL_OPEN = "DOCUMENTS_ASSIGNED_INSIDE_MODAL_OPEN";
export const DOCUMENTS_ASSIGNED_INSIDE_MODAL_CLOSE = "DOCUMENTS_ASSIGNED_INSIDE_MODAL_CLOSE";

export const DOCUMENTS_ASSIGNED_MODAL_TOGGLE_DOCUMENT = "DOCUMENTS_ASSIGNED_MODAL_TOGGLE_DOCUMENT";

export const CHART_SIDE_SERIES_LAB_ORDER_DELETE_ATTACHMENT_START =
  "CHART_SIDE_SERIES_LAB_ORDER_DELETE_ATTACHMENT_START";

export const CHART_SIDE_SERIES_MIXID_CODES = "CHART_SIDE_SERIES_MIXID_CODES";

export const CHART_SIDE_SERIES_IS_MIXED_CODES = "CHART_SIDE_SERIES_IS_MIXED_CODES";
export const CHART_SIDE_SERIES_CLEAR_MIXED_CODES = "CHART_SIDE_SERIES_CLEAR_MIXED_CODES";

export const CHART_SIDE_SERIES_SORT_FAVOURITES = "CHART_SIDE_SERIES_SORT_FAVOURITES";

export const CHART_SIDE_SERIES_CLEAR_TRANSACTION_ITEM = "CHART_SIDE_SERIES_CLEAR_TRANSACTION_ITEM";

export const CHART_SIDE_SERIES_ADD_POST_PROCEDURE_ITEMS =
  "CHART_SIDE_SERIES_ADD_POST_PROCEDURE_ITEMS";

export const CHART_SIDE_SERIES_ADD_ACTIVE_PROCEDURE_ID =
  "CHART_SIDE_SERIES_ADD_ACTIVE_PROCEDURE_ID";

export const CHART_GET_CATEGORY_LIST_START = "CHART_GET_CATEGORY_LIST_START";
export const CHART_GET_CATEGORY_LIST_SUCCESS = "CHART_GET_CATEGORY_LIST_SUCCESS";
export const CHART_GET_CATEGORY_LIST_ERROR = "CHART_GET_CATEGORY_LIST_ERROR";

export const CHART_GET_PROCEDURE_LIST_START = "CHART_GET_PROCEDURE_LIST_START";
export const CHART_GET_PROCEDURE_LIST_SUCCESS = "CHART_GET_PROCEDURE_LIST_SUCCESS";
export const CHART_GET_PROCEDURE_LIST_ERROR = "CHART_GET_PROCEDURE_LIST_ERROR";

export const CHART_SAVE_PROCEDURE_ITEM_START = "CHART_SAVE_PROCEDURE_ITEM_START";
export const CHART_SAVE_PROCEDURE_ITEM_SUCCESS = "CHART_SAVE_PROCEDURE_ITEM_SUCCESS";
export const CHART_SAVE_PROCEDURE_ITEM_ERROR = "CHART_SAVE_PROCEDURE_ITEM_ERROR";

export const CHART_ADD_SELECTED_TEETH = "CHART_ADD_SELECTED_TEETH";

export const CHART_SAVE_PROCEDURE_REQUEST_ITEM_START = "CHART_SAVE_PROCEDURE_REQUEST_ITEM_START";
export const CHART_SAVE_PROCEDURE_REQUEST_ITEM_SUCCESS =
  "CHART_SAVE_PROCEDURE_REQUEST_ITEM_SUCCESS";
export const CHART_SAVE_PROCEDURE_REQUEST_ITEM_ERROR = "CHART_SAVE_PROCEDURE_REQUEST_ITEM_ERROR";

export const GET_SIDEBAR_CATEGORY_LIST_START = "GET_SIDEBAR_CATEGORY_LIST_START";
export const GET_SIDEBAR_CATEGORY_LIST_SUCCESS = "GET_SIDEBAR_CATEGORY_LIST_SUCCESS";
export const GET_SIDEBAR_CATEGORY_LIST_ERROR = "GET_SIDEBAR_CATEGORY_LIST_ERROR";

export const ADD_CHART_ITEM = "ADD_CHART_ITEM";

export const CHART_CLEAR_ITEM = "CHART_CLEAR_ITEM";

export const CHART_SET_SERIES_TYPE = "CHART_SET_SERIES_TYPE";

export const CHART_GET_ALL_DIAGNOSIS_START = "CHART_GET_ALL_DIAGNOSIS_START";
export const CHART_GET_ALL_DIAGNOSIS_SUCCESS = "CHART_GET_ALL_DIAGNOSIS_SUCCESS";
export const CHART_GET_ALL_DIAGNOSIS_ERROR = "CHART_GET_ALL_DIAGNOSIS_ERROR";

export const CHART_GET_ALL_TREATMENTS_START = "CHART_GET_ALL_TREATMENTS_START";
export const CHART_GET_ALL_TREATMENTS_SUCCESS = "CHART_GET_ALL_TREATMENTS_SUCCESS";
export const CHART_GET_ALL_TREATMENTS_ERROR = "CHART_GET_ALL_TREATMENTS_ERROR";
export const HIDE_TAB_BAR = "HIDE_TAB_BAR";

export const FETCHING_PROCEDURE_ITEM = "FETCHING_PROCEDURE_ITEM";

export const GET_TREATMENT_CODE_ITEM = "GET_TREATMENT_CODE_ITEM";
export const GET_TREATMENT_ITEM_PRICE_START = "GET_TREATMENT_CODE_ITEM_START";
export const GET_TREATMENT_ITEM_PRICE_SUCCESS = "GET_TREATMENT_CODE_ITEM_SUCCESS";
export const GET_TREATMENT_ITEM_PRICE_ERROR = "GET_TREATMENT_CODE_ITEM_ERROR";

export const GET_TREATMENT_PROCEDURE_ITEM_START = "GET_TREATMENT_PROCEDURE_ITEM_START";
export const GET_TREATMENT_PROCEDURE_ITEM_SUCCESS = "GET_TREATMENT_PROCEDURE_ITEM_SUCCESS";
export const GET_TREATMENT_PROCEDURE_ITEM_ERROR = "GET_TREATMENT_PROCEDURE_ITEM_ERROR";

export const GET_TREATMENT_GROUP_LIST_START = "GET_TREATMENT_GROUP_LIST_START";
export const GET_TREATMENT_GROUP_LIST_SUCCESS = "GET_TREATMENT_GROUP_LIST_SUCCESS";
export const GET_TREATMENT_GROUP_LIST_ERROR = "GET_TREATMENT_GROUP_LIST_ERROR";

export const GET_TREATMENT_ITEM_START = "GET_TREATMENT_ITEM_START";
export const GET_TREATMENT_ITEM_SUCCESS = "GET_TREATMENT_ITEM_SUCCESS";
export const GET_TREATMENT_ITEM_ERROR = "GET_TREATMENT_ITEM_ERROR";
