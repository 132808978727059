import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DataList from "../../widgets/DataList";
import { Sidebar } from "../../sidebar/Sidebar";
import bindActions from "../../../helpers/bindActions";
import { SidebarHeader } from "../../sidebar/SidebarHeader";
import ConsultationNotesItem from "./ConsultationNotesItem";
import ConsultationNotesForm from "./ConsultationNotesForm";
import * as appointmentActions from "../../../actions/appointmentActions";
import ConsultationNotesConfirmDeleteModal from "./ConsultationNotesConfirmDeleteModal";

const enhancer = connect(null, bindActions({ appointmentActions }));

class ConsultationNotesSidebar extends React.Component {
  componentDidUpdate(prevProps) {
    const { show: prevShow } = prevProps;
    const { show: nextShow } = this.props;

    if (prevShow !== nextShow && nextShow) {
      const { patientKey } = this.props;

      this.props.appointmentActions.getConsultationNotesList({ patientKey });
    }
  }

  deleteItem = (e, id) => {
    e.stopPropagation();

    this.props.appointmentActions.consultationNotesConfirmDeleteModalShow(id);
  };

  render() {
    const { show, appointment, appointmentActions, onHide } = this.props;

    return (
      <Sidebar
        show={show}
        onHide={this.props.item ? appointmentActions.resetConsultationItem : onHide}
        bodyProps={{ style: { padding: 0, margin: 0 } }}
      >
        <ConsultationNotesConfirmDeleteModal deleteId={appointment.consultationNotesDeleteId} />

        <SidebarHeader
          title={appointment.consultationNotesItem ? "EDIT NOTE" : "CONSULTATION NOTES"}
          onBackClick={this.props.item ? appointmentActions.resetConsultationItem : onHide}
        />

        <ConsultationNotesForm
          editId={appointment.consultationNotesEditId}
          initialValues={appointment.consultationNotesItem}
        />

        {!appointment.consultationNotesItem && (
          <DataList
            fetching={appointment.consultationNotesFetching}
            onScroll={appointmentActions.loadConsultationNotesList}
          >
            {appointment.consultationNotesList.map((item) => (
              <ConsultationNotesItem
                item={item}
                key={item.id}
                openItem={appointmentActions.getConsultationNotesItem}
                deleteItem={this.deleteItem}
              />
            ))}
          </DataList>
        )}
      </Sidebar>
    );
  }
}

export default enhancer(ConsultationNotesSidebar);
