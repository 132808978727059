import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChartPostOpOwned from "./ChartPostOpOwned";
import ChartPostOpList from "./ChartPostOpList";
import ChartPostOpItemView from "./ChartPostOpItemView";
import ChartPostOpItemEdit from "./ChartPostOpItemEdit";

import postOpItemTypes from "../../../../constants/chart-sidebar/postOpItemTypes";
import { postOpStackCodes } from "../../../../constants/chart-sidebar/postOpStackCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePostOpActions from "../../../../actions/chartSidePostOpActions";

const enhancer = connect(null, bindActions({ chartSidePostOpActions }));

class ChartPostOp extends React.Component {
  componentDidMount() {
    this.props.chartSidePostOpActions.initSidePostOp();
  }

  render() {
    const { chartSidePostOp, history, location } = this.props;
    const { stack } = chartSidePostOp;

    const stackItem = _.last(stack);

    if (stackItem.code === postOpStackCodes.owned) {
      return (
        <ChartPostOpOwned chartSidePostOp={chartSidePostOp} history={history} location={location} />
      );
    }

    if (stackItem.code === postOpStackCodes.list) {
      return <ChartPostOpList chartSidePostOp={chartSidePostOp} />;
    }

    if (stackItem.code === postOpStackCodes.item) {
      if (stackItem?.type === postOpItemTypes.view) {
        return (
          <ChartPostOpItemView
            chartSidePostOp={chartSidePostOp}
            history={history}
            location={location}
          />
        );
      }

      if (stackItem?.type === postOpItemTypes.new) {
        return <ChartPostOpItemEdit chartSidePostOp={chartSidePostOp} />;
      }
    }
  }
}

export default enhancer(ChartPostOp);
