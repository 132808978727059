import _ from "lodash";

import Utils from "../helpers/Utils";
import PatientApi from "../api-legacy/PatientApi";
import MedicationApi from "../api-legacy/MedicationApi";
import * as ActionType from "../actionTypes/medicationActionTypes";
import * as PatientMedicationApi from "../api-legacy/PatientMedicationApi";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import * as chartSidePrescriptionActionsTypes from "../actionTypes/chartSidePrescriptionActionTypes";
import TransactionApi from "../api-legacy/TransactionApi";

export function createItem(patientKey) {
  return (dispatch, getState) => {
    const {
      form: {
        medicationCreateDrug: { values = {} },
      },
    } = getState();

    const errorMessage = validate(values);

    if (errorMessage) {
      return dispatch(showMessage(errorMessage));
    }

    dispatch(startLoader());

    function validate(medication) {
      if (!medication.medicationName) {
        return "Please, enter name";
      }
      if (!medication.amount) {
        return "Please, enter amount";
      }
      if (!medication.dispense) {
        return "Please, enter dispense";
      }
      if (!medication.instructions) {
        return "Please, enter instruction";
      }
      if (!medication.rout) {
        return "Please, choose Rout";
      }
      if (!medication.routFrequency) {
        return "Please, choose Frequency";
      }
      return false;
    }

    const data = {
      patientKey,
      strength: values.strength,
      amount: _.toFinite(values.amount),
      instructions: values.instructions,
      refills: _.toFinite(values.refills),
      rout: { id: _.toFinite(values.rout) },
      dispense: _.toFinite(values.dispense),
      medicationName: values.medicationName,
      routFrequency: { id: _.toFinite(values.routFrequency) },
    };

    return dispatch(createMedicationItemApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(closeDrugModal());
        dispatch(getList({ patientKey }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const init = () => ({
  type: ActionType.MEDICATION_INIT,
});

export const getMedicationHints = (filterKey) => (dispatch) =>
  dispatch(getMedicationHintsApi({ filterKey }));

export const getList = ({ patientKey, voided }) => (dispatch) => {
  dispatch(startLoader());

  return dispatch(
    getListApi({
      patientKey,
      voided,
      limit: 25,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const sendToERX = (patientKey) => {
  return (dispatch, getState) => {
    const {
      medication: { selectedItems, selectedTodayItems, selectedOldItems, list },
      session: { member },
      chartSidePrescription: { primaryDiagnosis, secondaryDiagnosis },
    } = getState();

    const ids = _.filter(list, (item) => {
      if (selectedItems.length && _.includes(selectedItems, _.get(item, "id"))) {
        return true;
      }
      if (selectedTodayItems.length && _.includes(selectedTodayItems, _.get(item, "id"))) {
        return true;
      }
      return selectedOldItems.length && _.includes(selectedOldItems, _.get(item, "id"));
    }).map((item) => _.get(item, "id"));

    if (_.isEmpty(ids)) {
      dispatch(openPDFModal(false, false));
      dispatch(showMessage("Please select items first, you can select only own medications."));

      return Promise.reject("Please select items first, you can select only own medications.");
    }

    const secondaryDiagnosisIds = Utils.objectToArray(secondaryDiagnosis, (item, key) => key);

    dispatch(startLoader());

    return dispatch(
      sendToERXApi({
        patientKey,
        ids,
        secondaryDiagnosisIds,
        principalDiagnosisId: primaryDiagnosis,
      }),
    )
      .then((response) => {
        dispatch(stopLoader("eRX created successfully"));
        dispatch(openPDFModal(false, false));
        dispatch({ type: ActionType.MEDICATION_CLEAR_CHECK });
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
};

export function createPDF(patientKey) {
  return (dispatch, getState) => {
    const {
      medication: { selectedItems, selectedTodayItems, selectedOldItems, list },
      session: { member },
      chartSidePrescription: { primaryDiagnosis, secondaryDiagnosis },
    } = getState();

    const ids = _.filter(list, (item) => {
      if (selectedItems.length && _.includes(selectedItems, _.get(item, "id"))) {
        return true;
      }
      if (selectedTodayItems.length && _.includes(selectedTodayItems, _.get(item, "id"))) {
        return true;
      }
      if (selectedOldItems.length && _.includes(selectedOldItems, _.get(item, "id"))) {
        return true;
      }

      return false;
    }).map((item) => _.get(item, "id"));

    if (_.isEmpty(ids)) {
      dispatch(openPDFModal(false, false));
      dispatch(showMessage("Please select items first, you can select only own medications."));

      return Promise.reject("Please select items first, you can select only own medications.");
    }

    const secondaryDiagnosisIds = Utils.objectToArray(secondaryDiagnosis, (item, key) => key);

    dispatch(startLoader());

    return dispatch(
      createPDFApi({
        patientKey,
        ids,
        secondaryDiagnosisIds,
        principalDiagnosisId: primaryDiagnosis,
      }),
    )
      .then((response) => {
        dispatch(stopLoader());
        dispatch(openPDFModal(false, false));
        dispatch({ type: ActionType.MEDICATION_CLEAR_CHECK });
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const loadList = () => (dispatch, getState) => {
  const { list, totalCount } = getState().medication;

  if (totalCount <= list.length) return;

  dispatch(startLoader());

  return dispatch(
    getListApi({
      start: list.length,
      limit: 25,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const getDrugList = () => (dispatch, getState) => {
  const { filter } = getState().medication;

  dispatch(startLoader());

  return dispatch(
    getDrugListApi({
      limit: 25,
      ...filter,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const loadDrugList = () => (dispatch, getState) => {
  const { drugList, drugTotalCount, filter } = getState().medication;

  if (drugTotalCount <= drugList.length) return;

  dispatch(startLoader());

  return dispatch(
    getDrugListApi({
      start: drugList.length,
      limit: 25,
      ...filter,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const openDrugModal = () => ({
  type: ActionType.MEDICATION_OPEN_DRUG_MODAL,
});

export const closeDrugModal = () => ({
  type: ActionType.MEDICATION_CLOSE_DRUG_MODAL,
});

export const toggleDrug = (id, item, isPrescription) => ({
  type: ActionType.MEDICATION_TOGGLE_DRUG,
  id,
  item,
  isPrescription,
});

export const submitDrugForm = () => (dispatch, getState) => {
  const {
    form: {
      medicationDrug: { values },
    },
  } = getState();

  dispatch({
    type: ActionType.MEDICATION_SUBMIT_DRUG_FORM,
    filter: values,
  });

  return dispatch(getDrugList());
};

export const addDrugs = (onlyClear) => ({
  type: ActionType.MEDICATION_ADD_DRUGS,
  onlyClear,
});

export const getThisAppointmentDiagnosisForPrint = () => (dispatch, getState) => {
  const {
    session: { clinic },
    chart: { appointment },
  } = getState();
  const transfer = {
    patientKey: appointment.patient?.patientKey,
    appointmentId: appointment.id,
    start: 0,
    limit: 100,
    sortField: "created_date_desc",
    clinicId: clinic.id,
    diagnosisPlanned: true,
    diagnosisCompleted: true,
  };
  return dispatch({
    api: TransactionApi.getList.forAppointment,
    types: [
      ActionType.GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_START,
      ActionType.GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_SUCCESS,
      ActionType.GET_APPOINTMENT_DIAGNOSIS_FOR_PRINT_ERROR,
    ],
    data: transfer,
  });
};

export const save = ({ patientKey }) => (dispatch, getState) => {
  const {
    medication: { newList, list },
    form,
  } = getState();

  const newBatch = newList.map((item, index) => {
    const values = form[`medicationItem${index}`].values || {};
    return {
      medication: {
        id: +item.medication.id,
      },
      medicationName: values.medicationName || "",
      amount: values.amount,
      rout: {
        id: +values.route,
      },
      routFrequency: {
        id: +values.frequency,
      },
      dispense: values.dispense,
      dosageForm: values.dosageForm || "",
      name: values.genericName || "",
      refills: values.refills,
      instructions: values.instructions,
      strength: values.strength,
      patientKey,
    };
  });
  const batch = list
    .filter((item) => {
      const formItem = form[`medicationItem${item.id}`];
      return !_.isEqual(formItem?.initial, formItem?.values);
    })
    .map((item) => {
      const values = form[`medicationItem${item.id}`].values || {};
      return {
        id: item.id,
        medication: {
          id: item.medication ? +item.medication.id : null,
        },
        medicationName: item.medicationName || "",
        amount: values.amount,
        rout: {
          id: +values.route,
        },
        routFrequency: {
          id: +values.frequency,
        },
        dispense: values.dispense,
        refills: values.refills,
        instructions: values.instructions,
        strength: values.strength,
        dosageForm: values.dosageForm || "",
        name: values.genericName || "",
        patientKey,
      };
    });

  const _list = newBatch.concat(batch);
  const errorMessage = validate(_list);

  if (errorMessage) {
    return dispatch(showMessage(errorMessage));
  }

  function validate(medications) {
    for (let i = 0; i < medications.length; i++) {
      const medication = medications[i];
      if (!medication.amount) {
        return "Please, enter amount";
      }
      if (!medication.dispense) {
        return "Please, enter dispense";
      }
      if (!medication.instructions) {
        return "Please, enter instruction";
      }
      if (!medication.rout.id) {
        return "Please, choose Rout";
      }
      if (!medication.routFrequency.id) {
        return "Please, choose Frequency";
      }
    }
    return false;
  }

  dispatch(startLoader());

  return dispatch(
    saveApi({
      list: newBatch.concat(batch),
    }),
  )
    .then(() => dispatch(stopLoader()))
    .then(() => dispatch(init()))
    .then(() => dispatch(getList({ patientKey })))
    .catch((error) => dispatch(stopLoader(error)));
};

export const batchDelete = ({ patientKey }) => (dispatch, getState) => {
  const { checkedMap, checkedMapToday, checkedMapOld } = getState().medication;
  const checkedItemsToVoid = { ...checkedMapToday, ...checkedMapOld, ...checkedMap };

  dispatch(startLoader());

  return dispatch(
    deleteApi({
      ids: Object.keys(checkedItemsToVoid).join(","),
    }),
  )
    .then(() => dispatch(stopLoader()))
    .then(() => dispatch(getList({ patientKey, voided: false })))
    .then(() => dispatch(clearCheck()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const restoreVoidedItem = ({ patientKey }) => (dispatch, getState) => {
  const { checkedMap } = getState().medication;
  dispatch(startLoader());
  return dispatch(
    restoreVoidedItemApi({
      ids: Object.keys(checkedMap),
    }),
  )
    .then(() => dispatch(stopLoader()))
    .then(() => dispatch(getList({ patientKey, voided: true })))
    .then(() => dispatch(clearCheck()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const duplicate = () => (dispatch, getState) => {
  const { medication, form } = getState();

  const itemsForDuplicate = [];

  medication.list.forEach((medicationHistoryItem) => {
    if (medication.checkedMap.hasOwnProperty(medicationHistoryItem.id)) {
      itemsForDuplicate.push({
        medication: medicationHistoryItem.medication,
        values: form[`medicationItem${medicationHistoryItem.id}`].values,
      });
    }
  });

  dispatch({
    type: ActionType.MEDICATION_DUPLICATE,
    itemsForDuplicate,
  });

  dispatch(checkAll({ clearImportant: true }));
};

export const openHistoryModal = (id) => ({
  type: ActionType.MEDICATION_OPEN_HISTORY_MODAL,
  id,
});

export const closeHistoryModal = () => ({
  type: ActionType.MEDICATION_CLOSE_HISTORY_MODAL,
});

export const getHistoryList = () => (dispatch, getState) => {
  const { activeHistoryId } = getState().medication;

  dispatch(startLoader());

  return dispatch(
    getHistoryListApi({
      medicationId: activeHistoryId,
      limit: 25,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const loadHistoryList = () => (dispatch, getState) => {
  const { historyList, historyTotalCount } = getState().medication;

  if (historyTotalCount <= historyList.length) return;

  dispatch(startLoader());

  return dispatch(
    getHistoryListApi({
      start: historyList.length,
      limit: 25,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const addNewHistory = () => ({
  type: ActionType.MEDICATION_ADD_NEW_HISTORY,
});

export const saveHistory = () => (dispatch, getState) => {
  const {
    medication: { newHistoryList, activeHistoryId },
    form,
  } = getState();

  const batch = newHistoryList.map((item, index) => {
    const values = form[`medicationHistoryItem${index}`].values || {};

    return {
      companyPatientMedication: {
        id: activeHistoryId,
      },
      dosage: values.dosage,
      comment: values.comment,
    };
  });

  dispatch(startLoader());

  return dispatch(
    saveHistoryApi({
      list: batch,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .then(() => dispatch(closeHistoryModal()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const checkAll = ({ clearImportant }) => ({
  type: ActionType.MEDICATION_CHECK_ALL,
  clearImportant,
});

export const checkTodayAll = ({ clearImportant, list }) => ({
  type: ActionType.MEDICATION_CHECK_TODAY_ALL,
  clearImportant,
  list,
});

export const checkOldAll = ({ clearImportant, list }) => ({
  type: ActionType.MEDICATION_CHECK_OLD_ALL,
  clearImportant,
  list,
});

export const checkItem = (id) => ({
  type: ActionType.MEDICATION_CHECK_ITEM,
  id,
});

export const checkTodayItem = (id) => ({
  type: ActionType.MEDICATION_CHECK_TODAY_ITEM,
  id,
});

export const checkOldItem = (id) => ({
  type: ActionType.MEDICATION_CHECK_OLD_ITEM,
  id,
});

export const clearCheckedItem = () => ({
  type: ActionType.CLEAR_MEDICATION_CHECKED_ITEM,
});

export const popNewItem = () => ({
  type: ActionType.MEDICATION_POP_NEW_ITEM,
});

export const clearCheck = () => ({
  type: ActionType.MEDICATION_CLEAR_CHECK,
});

export const popHistory = () => ({
  type: ActionType.MEDICATION_POP_HISTORY,
});

export const deleteHistory = (id) => (dispatch) => {
  dispatch(startLoader());

  return dispatch(
    deleteHistoryApi({
      id,
    }),
  )
    .then(() => dispatch(stopLoader()))
    .then(() => dispatch(getHistoryList()))
    .catch((error) => dispatch(stopLoader(error)));
};

export const openPDFModal = (pdfModalVisible, sendToERX = false) => (dispatch) => {
  dispatch({
    type: ActionType.MEDICATION_TOGGLE_PDF_MODAL,
    pdfModalVisible,
    sendToERX,
  });

  dispatch({
    type: chartSidePrescriptionActionsTypes.SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION,
  });
};

const getListApi = (data) => ({
  api: PatientMedicationApi.getList,
  types: [
    ActionType.MEDICATION_GET_LIST_START,
    ActionType.MEDICATION_GET_LIST_SUCCESS,
    ActionType.MEDICATION_GET_LIST_ERROR,
  ],
  data: {
    ...data,
    includeDeleted: true,
  },
});
const sendToERXApi = (data) => ({
  api: PatientMedicationApi.sendToERX,
  types: [
    ActionType.MEDICATION_SEND_TO_ERX_START,
    ActionType.MEDICATION_SEND_TO_ERX_SUCCESS,
    ActionType.MEDICATION_SEND_TO_ERX_ERROR,
  ],
  data,
});

const getDrugListApi = (data) => ({
  api: MedicationApi.getList,
  types: [
    ActionType.MEDICATION_GET_DRUG_LIST_START,
    ActionType.MEDICATION_GET_DRUG_LIST_SUCCESS,
    ActionType.MEDICATION_GET_DRUG_LIST_ERROR,
  ],
  data,
});

export const getFormData = (data) => ({
  api: PatientApi.prescription.getFormData,
  types: [
    ActionType.MEDICATION_GET_FORM_DATA_START,
    ActionType.MEDICATION_GET_FORM_DATA_SUCCESS,
    ActionType.MEDICATION_GET_FORM_DATA_ERROR,
  ],
  data,
});

export const saveApi = (data) => ({
  api: PatientMedicationApi.batchSave,
  types: [
    ActionType.MEDICATION_BATCH_SAVE_START,
    ActionType.MEDICATION_BATCH_SAVE_SUCCESS,
    ActionType.MEDICATION_BATCH_SAVE_ERROR,
  ],
  data,
});

export const createPDFApi = (data) => ({
  api: PatientApi.createMedicationPDF,
  types: [
    ActionType.MEDICATION_CREATE_PDF_START,
    ActionType.MEDICATION_CREATE_PDF_SUCCESS,
    ActionType.MEDICATION_CREATE_PDF_ERROR,
  ],
  data,
});

export const deleteApi = (data) => ({
  api: PatientMedicationApi.batchDelete,
  types: [
    ActionType.VOIDED_MEDICATION_RESTORE_ITEM_START,
    ActionType.VOIDED_MEDICATION_RESTORE_ITEM_SUCCESS,
    ActionType.VOIDED_MEDICATION_RESTORE_ITEM_ERROR,
  ],
  data,
});

export const restoreVoidedItemApi = (data) => ({
  api: PatientMedicationApi.restoreVoidedItem,
  types: [
    ActionType.MEDICATION_BATCH_DELETE_START,
    ActionType.MEDICATION_BATCH_DELETE_SUCCESS,
    ActionType.MEDICATION_BATCH_DELETE_ERROR,
  ],
  data,
});

export const saveHistoryApi = (data) => ({
  api: PatientMedicationApi.history_batchSave,
  types: [
    ActionType.MEDICATION_HISTORY_BATCH_SAVE_START,
    ActionType.MEDICATION_HISTORY_BATCH_SAVE_SUCCESS,
    ActionType.MEDICATION_HISTORY_BATCH_SAVE_ERROR,
  ],
  data,
});

const getHistoryListApi = (data) => ({
  api: PatientMedicationApi.history_getList,
  types: [
    ActionType.MEDICATION_HISTORY_GET_LIST_START,
    ActionType.MEDICATION_HISTORY_GET_LIST_SUCCESS,
    ActionType.MEDICATION_HISTORY_GET_LIST_ERROR,
  ],
  data,
});

const deleteHistoryApi = (data) => ({
  api: PatientMedicationApi.history_deleteItem,
  types: [
    ActionType.MEDICATION_HISTORY_DELETE_ITEM_START,
    ActionType.MEDICATION_HISTORY_DELETE_ITEM_SUCCESS,
    ActionType.MEDICATION_HISTORY_DELETE_ITEM_ERROR,
  ],
  data,
});

const getMedicationHintsApi = (data) => ({
  api: MedicationApi.getMedicationHints,
  types: [
    ActionType.MEDICATION_HINTS_START,
    ActionType.MEDICATION_HINTS_SUCCESS,
    ActionType.MEDICATION_HINTS_ERROR,
  ],
  data,
});

const createMedicationItemApi = (data) => ({
  api: PatientMedicationApi.createItem,
  types: [
    ActionType.MEDICATION_CREATE_ITEM_START,
    ActionType.MEDICATION_CREATE_ITEM_SUCCESS,
    ActionType.MEDICATION_CREATE_ITEM_ERROR,
  ],
  data,
});
