import { get } from "lodash";
import React, { useCallback, useMemo } from "react";

import FlexBox from "./FlexBox";
import { TextInput, TextInputProps } from "./TextInput";
import { PHONE_CODE_LIST } from "../../constants/PhoneConstants";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";
import Show from "../widgets/Show";
import { useSelector } from "react-redux";

const options = PHONE_CODE_LIST.map((code) => ({
  value: code.phoneCode,
  label: `${code.name} (${code.phoneCode})`,
}));

interface CountryCodeProps {
  readonly value: string;
  readonly label: string;
}

export interface PhoneNumberValueProps {
  readonly number: string;
  readonly townCode: string;
  readonly countryCode?: CountryCodeProps;
}

export interface PhoneNumberInputProps {
  readonly size?: "lg";
  readonly onFocus?: any;
  readonly onKeyPress?: any;
  readonly companyCountryName?: string;
  readonly search?: boolean | undefined;
  readonly value: PhoneNumberValueProps;
  readonly onChange: (value: PhoneNumberValueProps) => void;
  readonly numberProps?: Omit<TextInputProps, "value" | "onChange">;
  readonly townCodeProps?: Omit<TextInputProps, "value" | "onChange">;
  readonly countryCodeProps?: Omit<SelectPickerProps, "value" | "onChange">;
}

export function PhoneNumberInput({
  size,
  value,
  onFocus,
  onChange,
  onKeyPress,
  numberProps,
  townCodeProps,
  countryCodeProps,
  companyCountryName = "",
  search,
}: PhoneNumberInputProps) {
  const countryByNameHandler = useCallback((value, name) => {
    if (value) {
      return value;
    }

    const findCountry = PHONE_CODE_LIST.find((x) => x.name === name);

    return get(findCountry, "phoneCode", "971");
  }, []);
  const {
    // @ts-ignore
    session: { miscSettings },
  } = useSelector((store) => store);
  const formattedValue: any = useMemo(
    () => ({
      number: value?.number || "",
      townCode: value?.townCode || "",
      countryCode: value?.countryCode || {
        label: value?.countryCode?.label || companyCountryName,
        value: countryByNameHandler(value?.countryCode?.value, companyCountryName),
      },
    }),
    [value?.number, value?.townCode, value?.countryCode, companyCountryName, countryByNameHandler],
  );

  const changeHandler = useCallback(
    (x: Partial<PhoneNumberValueProps>) => onChange({ ...formattedValue, ...x }),
    [formattedValue, onChange],
  );
  const emiratesTownCode =
    !get(miscSettings, "showMobileCodeField") &&
    formattedValue.countryCode.value.toString() === "971";

  return (
    // @ts-ignore
    <FlexBox gutter={8} direction="column" align="flex-start">
      {/*
            // @ts-ignore */}
      <FlexBox>
        {/*
            // @ts-ignore */}
        <FlexBox gutter={8} flex={true}>
          {/*
            // @ts-ignore */}
          <FlexBox style={{ width: "108px" }} direction="column">
            <SelectPicker
              size={size}
              isSearchable={true}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              {...countryCodeProps}
              options={options}
              formatLabel={(x) => `+${x?.value}`}
              value={formattedValue.countryCode}
              onChange={(countryCode) => changeHandler({ countryCode })}
            />
          </FlexBox>
          <Show if={!emiratesTownCode}>
            {/*
            // @ts-ignore */}
            <FlexBox style={{ width: "75px" }} direction="column">
              <TextInput
                size={size}
                type="number"
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                {...townCodeProps}
                value={formattedValue.townCode}
                onChange={({ target }) => changeHandler({ townCode: target.value })}
              />
            </FlexBox>
          </Show>
          {/*
            // @ts-ignore */}
          <FlexBox flex={true} direction="column">
            <TextInput
              size={size}
              type="number"
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              {...numberProps}
              value={
                emiratesTownCode
                  ? formattedValue.townCode + formattedValue.number
                  : formattedValue.number
              }
              onChange={({ target: { value } }) => {
                if (emiratesTownCode) {
                  changeHandler({ townCode: value.slice(0, 2), number: value.slice(2) });
                } else {
                  changeHandler({ number: value });
                }
              }}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
