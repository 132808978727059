import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Modal } from "../ui/Modal";

import {
  getDeductibleTypes,
  getInsurancePlanItem,
  saveInsurancePlanItems,
  switchActivateInsurance,
} from "../../actions/insuranceActions";
import EditInsurancePlanForm from "./EditInsurancePlanForm";
import { Switch } from "../ui/Switch";
import Back from "../widgets/Back";

const enhancer = connect(
  ({ insurance: { deductibleTypes, insurancePlanItems }, patientView: { patientDetails } }) => ({
    deductibleTypes,
    insurancePlanItems,
    patientDetails,
  }),
  {
    getDeductibleTypes,
    getInsurancePlanItem,
    switchActivateInsurance,
    saveInsurancePlanItems,
  },
);

export const EditInsurancePlanDialog = enhancer(
  class EditInsurancePlanDialog extends React.Component {
    handleSwitchActivateInsurance = () => {
      const { planId, patientKey, insurancePlanRowExist } = this.props;

      this.props.switchActivateInsurance({
        patientKey,
        id: planId,
        insurancePlanRowExist: !insurancePlanRowExist,
      });
    };

    componentDidMount() {
      this.props.getDeductibleTypes();

      if (this.props.planId) {
        this.props.getInsurancePlanItem({
          companyPatientInsuranceId: this.props.planId,
        });
      }
    }

    componentDidUpdate(prevProps) {
      const { planId: prevPlanId } = prevProps;
      const { planId: nextPlanId } = this.props;

      if (prevPlanId !== nextPlanId && nextPlanId) {
        this.props.getInsurancePlanItem({
          companyPatientInsuranceId: nextPlanId,
        });
      }
    }

    render() {
      const deductibleTypes = (this.props.deductibleTypes || []).map((item) => ({
        value: item.id,
        label: item.name,
      }));
      return (
        <Modal
          show={this.props.open}
          keyboard={false}
          className="edit-insurance-plan-dialog"
          style={{ backgroundColor: "#ffffff" }}
          onHide={this.props.onRequestClose}
          title={
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Back onClick={this.props.onRequestClose} />
              </div>

              <div
                style={{
                  display: "flex",
                  flex: "1 1 0%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  EDIT INSURANCE PLAN DETAILS{" "}
                  <strong>CHART: {_.get(this.props, "patientDetails.chartNumber")}</strong>
                </span>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    this.props.saveInsurancePlanItems();
                  }}
                >
                  SAVE & CLOSE
                </button>
              </div>
            </div>
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }} id="bodyRef">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                <strong>Insurance Company:</strong> &nbsp; {_.get(this.props, "insuranceCompany")}
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                <strong>Insurance Plan:</strong> &nbsp;{" "}
                {_.get(this.props, "insuranceCompanyDetails")}
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                <strong>Custom Insurance Plan Activated:</strong> &nbsp;{" "}
                <Switch
                  checked={this.props.insurancePlanRowExist}
                  onChange={this.handleSwitchActivateInsurance}
                />
              </span>
            </div>

            <div style={{ minHeight: "350px" }}>
              {this.props.insurancePlanRowExist && (
                <EditInsurancePlanForm
                  initialValues={this.props.insurancePlanItems}
                  deductibleTypes={deductibleTypes}
                />
              )}
            </div>
          </div>
        </Modal>
      );
    }
  },
);
