import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/patientWhMessagesActionTypes";

export const defaultState = {
  messages: [],
  totalCount: 0,
};

const reducers = {
  [ActionTypes.WHATSAPP_CHATTING_PATIENT_MESSAGES_GET_SUCCESS](state, { data }) {
    return {
      ...state,
      messages: data.list || [],
      totalCount: data.totalCount,
    }
  },
  [ActionTypes.WHATSAPP_CHATTING_RESET_PATIENT_MESSAGES](state) {
    return {
      ...state,
      messages: [],
      totalCount: 0,
    }
  }
}

export default createReducer(defaultState, reducers);