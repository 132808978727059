export const PATIENT_REGISTER_INIT = "PATIENT_REGISTER_INIT";

export const PATIENT_DETAILS_SET_EDIT = "PATIENT_DETAILS_SET_EDIT";

export const PATIENT_REGISTER_VALIDATE_START = "PATIENT_REGISTER_VALIDATE_START";
export const PATIENT_REGISTER_VALIDATE_SUCCESS = "PATIENT_REGISTER_VALIDATE_SUCCESS";
export const PATIENT_REGISTER_VALIDATE_ERROR = "PATIENT_REGISTER_VALIDATE_ERROR";

export const PATIENT_REGISTER_GET_DETAILS_INFO_START = "PATIENT_REGISTER_GET_DETAILS_INFO_START";
export const PATIENT_REGISTER_GET_DETAILS_INFO_SUCCESS =
  "PATIENT_REGISTER_GET_DETAILS_INFO_SUCCESS";
export const PATIENT_REGISTER_GET_DETAILS_INFO_ERROR = "PATIENT_REGISTER_GET_DETAILS_INFO_ERROR";

export const PATIENT_REGISTER_GET_PATIENT_DETAILS_START =
  "PATIENT_REGISTER_GET_PATIENT_DETAILS_START";
export const PATIENT_REGISTER_GET_PATIENT_DETAILS_SUCCESS =
  "PATIENT_REGISTER_GET_PATIENT_DETAILS_SUCCESS";
export const PATIENT_REGISTER_GET_PATIENT_DETAILS_ERROR =
  "PATIENT_REGISTER_GET_PATIENT_DETAILS_ERROR";

export const PATIENT_REGISTER_GET_TEMP_PATIENT_START = "PATIENT_REGISTER_GET_TEMP_PATIENT_START";
export const PATIENT_REGISTER_GET_TEMP_PATIENT_SUCCESS =
  "PATIENT_REGISTER_GET_TEMP_PATIENT_SUCCESS";
export const PATIENT_REGISTER_GET_TEMP_PATIENT_ERROR = "PATIENT_REGISTER_GET_TEMP_PATIENT_ERROR";

export const PATIENT_REGISTER_GET_NATIONALITY_START = "PATIENT_REGISTER_GET_NATIONALITY_START";
export const PATIENT_REGISTER_GET_NATIONALITY_SUCCESS = "PATIENT_REGISTER_GET_NATIONALITY_SUCCESS";
export const PATIENT_REGISTER_GET_NATIONALITY_ERROR = "PATIENT_REGISTER_GET_NATIONALITY_ERROR";

export const PATIENT_REGISTER_GET_PROFESSION_START = "PATIENT_REGISTER_GET_PROFESSION_START";
export const PATIENT_REGISTER_GET_PROFESSION_SUCCESS = "PATIENT_REGISTER_GET_PROFESSION_SUCCESS";
export const PATIENT_REGISTER_GET_PROFESSION_ERROR = "PATIENT_REGISTER_GET_PROFESSION_ERROR";

export const PATIENT_REGISTER_GET_GENDER_START = "PATIENT_REGISTER_GET_GENDER_START";
export const PATIENT_REGISTER_GET_GENDER_SUCCESS = "PATIENT_REGISTER_GET_GENDER_SUCCESS";
export const PATIENT_REGISTER_GET_GENDER_ERROR = "PATIENT_REGISTER_GET_GENDER_ERROR";

export const PATIENT_REGISTER_GET_TITLES_START = "PATIENT_REGISTER_GET_TITLES_START";
export const PATIENT_REGISTER_GET_TITLES_SUCCESS = "PATIENT_REGISTER_GET_TITLES_SUCCESS";
export const PATIENT_REGISTER_GET_TITLES_ERROR = "PATIENT_REGISTER_GET_TITLES_ERROR";

export const PATIENT_REGISTER_GET_OCCUPATION_START = "PATIENT_REGISTER_GET_OCCUPATION_START";
export const PATIENT_REGISTER_GET_OCCUPATION_SUCCESS = "PATIENT_REGISTER_GET_OCCUPATION_SUCCESS";
export const PATIENT_REGISTER_GET_OCCUPATION_ERROR = "PATIENT_REGISTER_GET_OCCUPATION_ERROR";

export const PATIENT_REGISTER_GET_MARITAL_STATUS_START =
  "PATIENT_REGISTER_GET_MARITAL_STATUS_START";
export const PATIENT_REGISTER_GET_MARITAL_STATUS_SUCCESS =
  "PATIENT_REGISTER_GET_MARITAL_STATUS_SUCCESS";
export const PATIENT_REGISTER_GET_MARITAL_STATUS_ERROR =
  "PATIENT_REGISTER_GET_MARITAL_STATUS_ERROR";

export const PATIENT_REGISTER_GET_COUNTRY_START = "PATIENT_REGISTER_GET_COUNTRY_START";
export const PATIENT_REGISTER_GET_COUNTRY_SUCCESS = "PATIENT_REGISTER_GET_COUNTRY_SUCCESS";
export const PATIENT_REGISTER_GET_COUNTRY_ERROR = "PATIENT_REGISTER_GET_COUNTRY_ERROR";

export const PATIENT_REGISTER_GET_REFERRAL_TYPES_START =
  "PATIENT_REGISTER_GET_REFERRAL_TYPES_START";
export const PATIENT_REGISTER_GET_REFERRAL_TYPES_SUCCESS =
  "PATIENT_REGISTER_GET_REFERRAL_TYPES_SUCCESS";
export const PATIENT_REGISTER_GET_REFERRAL_TYPES_ERROR =
  "PATIENT_REGISTER_GET_REFERRAL_TYPES_ERROR";

export const PATIENT_REGISTER_GET_STATE_START = "PATIENT_REGISTER_GET_STATE_START";
export const PATIENT_REGISTER_GET_STATE_SUCCESS = "PATIENT_REGISTER_GET_STATE_SUCCESS";
export const PATIENT_REGISTER_GET_STATE_ERROR = "PATIENT_REGISTER_GET_STATE_ERROR";

export const PATIENT_REGISTER_GET_CITY_START = "PATIENT_REGISTER_GET_CITY_START";
export const PATIENT_REGISTER_GET_CITY_SUCCESS = "PATIENT_REGISTER_GET_CITY_SUCCESS";
export const PATIENT_REGISTER_GET_CITY_ERROR = "PATIENT_REGISTER_GET_CITY_ERROR";

export const PATIENT_REGISTER_GET_PATIENT_LIST_START = "PATIENT_REGISTER_GET_PATIENT_LIST_START";
export const PATIENT_REGISTER_GET_PATIENT_LIST_SUCCESS =
  "PATIENT_REGISTER_GET_PATIENT_LIST_SUCCESS";
export const PATIENT_REGISTER_GET_PATIENT_LIST_ERROR = "PATIENT_REGISTER_GET_PATIENT_LIST_ERROR";

export const PATIENT_REGISTER_UPLOAD_PHOTO_START = "PATIENT_REGISTER_UPLOAD_PHOTO_START";
export const PATIENT_REGISTER_UPLOAD_PHOTO_SUCCESS = "PATIENT_REGISTER_UPLOAD_PHOTO_SUCCESS";
export const PATIENT_REGISTER_UPLOAD_PHOTO_ERROR = "PATIENT_REGISTER_UPLOAD_PHOTO_ERROR";

export const PATIENT_REGISTER_SAVE_DETAILS_INFO_START = "PATIENT_REGISTER_SAVE_DETAILS_INFO_START";
export const PATIENT_REGISTER_SAVE_DETAILS_INFO_SUCCESS =
  "PATIENT_REGISTER_SAVE_DETAILS_INFO_SUCCESS";
export const PATIENT_REGISTER_SAVE_DETAILS_INFO_ERROR = "PATIENT_REGISTER_SAVE_DETAILS_INFO_ERROR";

export const PATIENT_REGISTER_SAVE_TEMP_PATIENT_START = "PATIENT_REGISTER_SAVE_TEMP_PATIENT_START";
export const PATIENT_REGISTER_SAVE_TEMP_PATIENT_SUCCESS =
  "PATIENT_REGISTER_SAVE_TEMP_PATIENT_SUCCESS";
export const PATIENT_REGISTER_SAVE_TEMP_PATIENT_ERROR = "PATIENT_REGISTER_SAVE_TEMP_PATIENT_ERROR";

export const PATIENT_REGISTER_CHOOSE_COUNTRY = "PATIENT_REGISTER_CHOOSE_COUNTRY";
export const PATIENT_REGISTER_CHOOSE_STATE = "PATIENT_REGISTER_CHOOSE_STATE";
export const PATIENT_REGISTER_CLEAR_DETAILS = "PATIENT_REGISTER_CLEAR_DETAILS";

export const PATIENT_REGISTER_OPEN_HOUSEHOLD = "PATIENT_REGISTER_OPEN_HOUSEHOLD";
export const PATIENT_REGISTER_CLOSE_HOUSEHOLD = "PATIENT_REGISTER_CLOSE_HOUSEHOLD";
export const PATIENT_REGISTER_REMOVE_HOUSEHOLD = "PATIENT_REGISTER_REMOVE_HOUSEHOLD";

export const PATIENT_REGISTER_CHOOSE_HOUSEHOLD = "PATIENT_REGISTER_CHOOSE_HOUSEHOLD";
export const PATIENT_REGISTER_SELECT_HOUSEHOLD = "PATIENT_REGISTER_SELECT_HOUSEHOLD";
export const PATIENT_REGISTER_SEARCH_HOUSEHOLD = "PATIENT_REGISTER_SEARCH_HOUSEHOLD";

export const PATIENT_REGISTER_ADD_ANOTHER = "PATIENT_REGISTER_ADD_ANOTHER";
export const PATIENT_REGISTER_DELETE_ANOTHER = "PATIENT_REGISTER_DELETE_ANOTHER";

export const PATIENT_REGISTER_OPEN_SIGNATURE = "PATIENT_REGISTER_OPEN_SIGNATURE";
export const PATIENT_REGISTER_CLOSE_SIGNATURE = "PATIENT_REGISTER_CLOSE_SIGNATURE";
export const PATIENT_REGISTER_ADD_SIGNATURE = "PATIENT_REGISTER_ADD_SIGNATURE";

export const PATIENT_GET_MEDICAL_ALERTS_START = "PATIENT_GET_MEDICAL_ALERTS_START";
export const PATIENT_GET_MEDICAL_ALERTS_SUCCESS = "PATIENT_GET_MEDICAL_ALERTS_SUCCESS";
export const PATIENT_GET_MEDICAL_ALERTS_ERROR = "PATIENT_GET_MEDICAL_ALERTS_ERROR";

export const PATIENT_MEDICAL_ALERTS_SET_EDIT = "PATIENT_MEDICAL_ALERTS_SET_EDIT";
export const PATIENT_MEDICAL_ALERTS_SET_VIEW = "PATIENT_MEDICAL_ALERTS_SET_VIEW";
export const PATIENT_MEDICAL_ALERTS_SAVE_START = "PATIENT_MEDICAL_ALERTS_SAVE_START";
export const PATIENT_MEDICAL_ALERTS_SAVE_SUCCESS = "PATIENT_MEDICAL_ALERTS_SAVE_SUCCESS";
export const PATIENT_MEDICAL_ALERTS_SAVE_ERROR = "PATIENT_MEDICAL_ALERTS_SAVE_ERROR";

export const PATIENT_GET_MEDICATION_ALERTS_START = "PATIENT_GET_MEDICATION_ALERTS_START";
export const PATIENT_GET_MEDICATION_ALERTS_SUCCESS = "PATIENT_GET_MEDICATION_ALERTS_SUCCESS";
export const PATIENT_GET_MEDICATION_ALERTS_ERROR = "PATIENT_GET_MEDICATION_ALERTS_ERROR";

export const PATIENT_MEDICATION_ALERTS_SET_EDIT = "PATIENT_MEDICATION_ALERTS_SET_EDIT";
export const PATIENT_MEDICATION_ALERTS_SAVE_START = "PATIENT_MEDICATION_ALERTS_SAVE_START";
export const PATIENT_MEDICATION_ALERTS_SAVE_SUCCESS = "PATIENT_MEDICATION_ALERTS_SAVE_SUCCESS";
export const PATIENT_MEDICATION_ALERTS_SAVE_ERROR = "PATIENT_MEDICATION_ALERTS_SAVE_ERROR";

export const PATIENT_MEDICATION_ADD_ALERT = "PATIENT_MEDICATION_ADD_ALERT";
export const PATIENT_MEDICATION_DELETE_ALERT = "PATIENT_MEDICATION_DELETE_ALERT";

export const PATIENT_GET_APPOINTMENT_LIST_START = "PATIENT_GET_APPOINTMENT_LIST_START";
export const PATIENT_GET_APPOINTMENT_LIST_SUCCESS = "PATIENT_GET_APPOINTMENT_LIST_SUCCESS";
export const PATIENT_GET_APPOINTMENT_LIST_ERROR = "PATIENT_GET_APPOINTMENT_LIST_ERROR";

export const PATIENT_GET_FORM_LIST_START = "PATIENT_GET_FORM_LIST_START";
export const PATIENT_GET_FORM_LIST_SUCCESS = "PATIENT_GET_FORM_LIST_SUCCESS";
export const PATIENT_GET_FORM_LIST_ERROR = "PATIENT_GET_FORM_LIST_ERROR";

export const PATIENT_DELETE_FORM_ITEM_START = "PATIENT_DELETE_FORM_ITEM_START";
export const PATIENT_DELETE_FORM_ITEM_SUCCESS = "PATIENT_DELETE_FORM_ITEM_SUCCESS";
export const PATIENT_DELETE_FORM_ITEM_ERROR = "PATIENT_DELETE_FORM_ITEM_ERROR";

export const PATIENT_GET_FORM_ITEM_START = "PATIENT_GET_FORM_ITEM_START";
export const PATIENT_GET_FORM_ITEM_SUCCESS = "PATIENT_GET_FORM_ITEM_SUCCESS";
export const PATIENT_GET_FORM_ITEM_ERROR = "PATIENT_GET_FORM_ITEM_ERROR";

export const PATIENT_GET_FORM_EDIT_START = "PATIENT_GET_FORM_EDIT_START";
export const PATIENT_GET_FORM_EDIT_SUCCESS = "PATIENT_GET_FORM_EDIT_SUCCESS";
export const PATIENT_GET_FORM_EDIT_ERROR = "PATIENT_GET_FORM_EDIT_ERROR";

export const GET_PATIENT_ALERTS_HISTORY_START = "GET_PATIENT_ALERTS_HISTORY_START";
export const GET_PATIENT_ALERTS_HISTORY_SUCCESS = "GET_PATIENT_ALERTS_HISTORY_SUCCESS";
export const GET_PATIENT_ALERTS_HISTORY_ERROR = "GET_PATIENT_ALERTS_HISTORY_ERROR";

export const SET_PATIENT_ALERTS_HISTORY_MODE = "SET_PATIENT_ALERTS_HISTORY_MODE";

export const SET_PATIENT_ALERTS_HISTORY_URL = "SET_PATIENT_ALERTS_HISTORY_URL";

export const INIT_PATIENT_ALERTS_HISTORY = "INIT_PATIENT_ALERTS_HISTORY";

export const PATIENT_CHOOSE_ACTION_MODAL_SHOW = "PATIENT_CHOOSE_ACTION_MODAL_SHOW";
export const PATIENT_CHOOSE_ACTION_MODAL_HIDE = "PATIENT_CHOOSE_ACTION_MODAL_HIDE";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_SHOW = "PATIENT_SELECT_APPOINTMENT_SIDEBAR_SHOW";
export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_HIDE = "PATIENT_SELECT_APPOINTMENT_SIDEBAR_HIDE";

export const APPOINTMENTS_REASON_MODAL_SHOW = "APPOINTMENTS_REASON_MODAL_SHOW";
export const APPOINTMENTS_REASON_MODAL_HIDE = "APPOINTMENTS_REASON_MODAL_HIDE";

export const PATIENT_APPOINTMENT_GET_HISTORY_SUCCESS = "PATIENT_APPOINTMENT_GET_HISTORY_SUCCESS";
export const PATIENT_APPOINTMENT_GET_HISTORY_START = "PATIENT_APPOINTMENT_GET_HISTORY_START";
export const PATIENT_APPOINTMENT_GET_HISTORY_ERROR = "PATIENT_APPOINTMENT_GET_HISTORY_ERROR";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_MODE =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_MODE";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_DATE =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_DATE";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_INIT = "PATIENT_SELECT_APPOINTMENT_SIDEBAR_INIT";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_START =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_START";
export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_SUCCESS =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_SUCCESS";
export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_ERROR =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_ERROR";

export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_START =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_START";
export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_SUCCESS =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_SUCCESS";
export const PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_ERROR =
  "PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_ERROR";

export const DOC_FIELDS_GET_MEMBERS_START = "DOC_FIELDS_GET_MEMBERS_START";
export const DOC_FIELDS_GET_MEMBERS_SUCCESS = "DOC_FIELDS_GET_MEMBERS_SUCCESS";
export const DOC_FIELDS_GET_MEMBERS_ERROR = "DOC_FIELDS_GET_MEMBERS_ERROR";

export const DOC_FIELDS_GET_SIGNATURE_START = "DOC_FIELDS_GET_SIGNATURE_START";
export const DOC_FIELDS_GET_SIGNATURE_SUCCESS = "DOC_FIELDS_GET_SIGNATURE_SUCCESS";
export const DOC_FIELDS_GET_SIGNATURE_ERROR = "DOC_FIELDS_GET_SIGNATURE_ERROR";

export const PATIENT_FORMS_SIGN_START = "PATIENT_FORMS_SIGN_START";
export const PATIENT_FORMS_SIGN_SUCCESS = "PATIENT_FORMS_SIGN_SUCCESS";
export const PATIENT_FORMS_SIGN_ERROR = "PATIENT_FORMS_SIGN_ERROR";

export const PATIENT_FORMS_OPEN_DOC_FIELDS_MODAL = "PATIENT_FORMS_OPEN_DOC_FIELDS_MODAL";
export const PATIENT_FORMS_CLOSE_DOC_FIELDS_MODAL = "PATIENT_FORMS_CLOSE_DOC_FIELDS_MODAL";

export const DOC_FIELDS_OPEN_EDIT_MODE = "DOC_FIELDS_OPEN_EDIT_MODE";
export const DOC_FIELDS_CLOSE_EDIT_MODE = "DOC_FIELDS_CLOSE_EDIT_MODE";

export const DOC_FIELDS_SELECT_MEMBER = "DOC_FIELDS_SELECT_MEMBER";
export const DOC_FIELDS_SET_SEARCH_VALUE = "DOC_FIELDS_SET_SEARCH_VALUE";
export const DOC_FIELDS_CHANGE_TEXT_AREA = "DOC_FIELDS_CHANGE_TEXT_AREA";

export const PATIENT_FORMS_OPEN_ADD_MODAL = "PATIENT_FORMS_OPEN_ADD_MODAL";
export const PATIENT_FORMS_CLOSE_ADD_MODAL = "PATIENT_FORMS_CLOSE_ADD_MODAL";

export const ADD_CONSENT_FORM_GET_LIST_START = "ADD_CONSENT_FORM_GET_LIST_START";
export const ADD_CONSENT_FORM_GET_LIST_SUCCESS = "ADD_CONSENT_FORM_GET_LIST_SUCCESS";
export const ADD_CONSENT_FORM_GET_LIST_ERROR = "ADD_CONSENT_FORM_GET_LIST_ERROR";

export const CONSENT_FORM_GET_CATEGORY_LIST_START = "CONSENT_FORM_GET_CATEGORY_LIST_START";
export const CONSENT_FORM_GET_CATEGORY_LIST_SUCCESS = "CONSENT_FORM_GET_CATEGORY_LIST_SUCCESS";
export const CONSENT_FORM_GET_CATEGORY_LIST_ERROR = "CONSENT_FORM_GET_CATEGORY_LIST_ERROR";

export const ADD_CONSENT_FORM_START = "ADD_CONSENT_FORM_START";
export const ADD_CONSENT_FORM_SUCCESS = "ADD_CONSENT_FORM_SUCCESS";
export const ADD_CONSENT_FORM_ERROR = "ADD_CONSENT_FORM_ERROR";

export const PATIENT_GET_FORM_EDIT_ITEM_START = "PATIENT_GET_FORM_EDIT_ITEM_START";
export const PATIENT_GET_FORM_EDIT_ITEM_SUCCESS = "PATIENT_GET_FORM_EDIT_ITEM_SUCCESS";
export const PATIENT_GET_FORM_EDIT_ITEM_ERROR = "PATIENT_GET_FORM_EDIT_ITEM_ERROR";

export const ADD_CONSENT_FORM_SEARCH_CHANGE = "ADD_CONSENT_FORM_SEARCH_CHANGE";
export const PATIENT_FORMS_SHOW_REFERRAL_DESCRIPTION_MODAL =
  "PATIENT_FORMS_SHOW_REFERRAL_DESCRIPTION_MODAL";

export const PATIENT_FORMS_GUARDIAN_SIGNATURE_SHOW = "PATIENT_FORMS_GUARDIAN_SIGNATURE_SHOW";
export const PATIENT_FORMS_GUARDIAN_SIGNATURE_HIDE = "PATIENT_FORMS_GUARDIAN_SIGNATURE_HIDE";
export const PATIENT_FORMS_SIGN_GUARDIAN = "PATIENT_FORMS_SIGN_GUARDIAN";
export const PATIENT_REGISTER_OPEN_CLOSE_CARD_READER_INFO =
  "PATIENT_REGISTER_OPEN_CLOSE_CARD_READER_INFO";
export const PATIENT_REGISTER_GET_LANGUAGES_START = "PATIENT_REGISTER_GET_LANGUAGES_START";
export const PATIENT_REGISTER_GET_LANGUAGES_SUCCESS = "PATIENT_REGISTER_GET_LANGUAGES_SUCCESS";
export const PATIENT_REGISTER_GET_LANGUAGES_ERROR = "PATIENT_REGISTER_GET_LANGUAGES_ERROR";
export const CLAIMS_GET_LIST_START = "CLAIMS_GET_LIST_START";
export const CLAIMS_GET_LIST_SUCCESS = "CLAIMS_GET_LIST_SUCCESS";
export const CLAIMS_GET_LIST_ERROR = "CLAIMS_GET_LIST_ERROR";
export const CLAIMS_GET_STATUS_LIST_START = "CLAIMS_GET_STATUS_LIST_START";
export const CLAIMS_GET_STATUS_LIST_SUCCESS = "CLAIMS_GET_STATUS_LIST_SUCCESS";
export const CLAIMS_GET_STATUS_LIST_ERROR = "CLAIMS_GET_STATUS_LIST_ERROR";
export const CLAIMS_GET_DOC_TYPE_LIST_START = "CLAIMS_GET_DOC_TYPE_LIST_START";
export const CLAIMS_GET_DOC_TYPE_LIST_SUCCESS = "CLAIMS_GET_DOC_TYPE_LIST_SUCCESS";
export const CLAIMS_GET_DOC_TYPE_LIST_ERROR = "CLAIMS_GET_DOC_TYPE_LIST_ERROR";
export const PRINT_CLAIM_START = "PRINT_CLAIM_START";
export const PRINT_CLAIM_SUCCESS = "PRINT_CLAIM_SUCCESS";
export const PRINT_CLAIM_ERROR = "PRINT_CLAIM_ERROR";
export const APPROVALS_GET_LIST_START = "APPROVALS_GET_LIST_START";
export const APPROVALS_GET_LIST_SUCCESS = "APPROVALS_GET_LIST_SUCCESS";
export const APPROVALS_GET_LIST_ERROR = "APPROVALS_GET_LIST_ERROR";
export const CLAIMS_GET_CLAIMED_STATUS_LIST_START = "CLAIMS_GET_CLAIMED_STATUS_LIST_START";
export const CLAIMS_GET_CLAIMED_STATUS_LIST_SUCCESS = "CLAIMS_GET_CLAIMED_STATUS_LIST_SUCCESS";
export const CLAIMS_GET_CLAIMED_STATUS_LIST_ERROR = "CLAIMS_GET_CLAIMED_STATUS_LIST_ERROR";
export const APPROVALS_GET_STATUS_LIST_START = "APPROVALS_GET_STATUS_LIST_START";
export const APPROVALS_GET_STATUS_LIST_SUCCESS = "APPROVALS_GET_STATUS_LIST_SUCCESS";
export const APPROVALS_GET_STATUS_LIST_ERROR = "APPROVALS_GET_STATUS_LIST_ERROR";
export const APPROVALS_GET_DOC_TYPE_LIST_START = "APPROVALS_GET_DOC_TYPE_LIST_START";
export const APPROVALS_GET_DOC_TYPE_LIST_SUCCESS = "APPROVALS_GET_DOC_TYPE_LIST_SUCCESS";
export const APPROVALS_GET_DOC_TYPE_LIST_ERROR = "APPROVALS_GET_DOC_TYPE_LIST_ERROR";
export const PATIENT_CLAIMS_APPROVALS_CHANGE_TAB = "PATIENT_CLAIMS_APPROVALS_CHANGE_TAB";
export const PATIENT_CLAIMS_APPROVALS_SAVE_PDF = "PATIENT_CLAIMS_APPROVALS_SAVE_PDF";
export const APPROVALS_FILTER_ITEMS = "APPROVALS_FILTER_ITEMS";
export const APPROVALS_FILTER_ITEMS_CLEAR = "APPROVALS_FILTER_ITEMS_CLEAR";
export const SAVE_CLAIM_ITEM_NOTE_START = "SAVE_CLAIM_ITEM_NOTE_START";
export const SAVE_CLAIM_ITEM_NOTE_SUCCESS = "SAVE_CLAIM_ITEM_NOTE_SUCCESS";
export const SAVE_CLAIM_ITEM_NOTE_ERROR = "SAVE_CLAIM_ITEM_NOTE_ERROR";
export const SET_APPROVALS_CLAIMS_ITEM_NOTE = "SET_APPROVALS_CLAIMS_ITEM_NOTE";
export const DELETE_PATIENT_IMAGE = "DELETE_PATIENT_IMAGE";

export const PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_START =
  "PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_START";
export const PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_SUCCESS =
  "PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_SUCCESS";
export const PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_ERROR =
  "PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_ERROR";

export const PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_START =
  "PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_START";
export const PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_SUCCESS =
  "PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_SUCCESS";
export const PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_ERROR =
  "PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_ERROR";

export const GET_APPOINTMENT_UPCOMING_TOTAL_LIST_START =
  "GET_APPOINTMENT_UPCOMING_TOTAL_LIST_START";
export const GET_APPOINTMENT_UPCOMING_TOTAL_LIST_SUCCESS =
  "GET_APPOINTMENT_UPCOMING_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_UPCOMING_TOTAL_LIST_ERROR =
  "GET_APPOINTMENT_UPCOMING_TOTAL_LIST_ERROR";

export const GET_APPOINTMENT_MISSED_TOTAL_LIST_START = "GET_APPOINTMENT_MISSED_TOTAL_LIST_START";
export const GET_APPOINTMENT_MISSED_TOTAL_LIST_SUCCESS =
  "GET_APPOINTMENT_MISSED_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_MISSED_TOTAL_LIST_ERROR = "GET_APPOINTMENT_MISSED_TOTAL_LIST_ERROR";

export const GET_APPOINTMENT_COMPLETE_TOTAL_LIST_START =
  "GET_APPOINTMENT_COMPLETE_TOTAL_LIST_START";
export const GET_APPOINTMENT_COMPLETE_TOTAL_LIST_SUCCESS =
  "GET_APPOINTMENT_COMPLETE_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_COMPLETE_TOTAL_LIST_ERROR =
  "GET_APPOINTMENT_COMPLETE_TOTAL_LIST_ERROR";

export const GET_APPOINTMENT_LATE_TOTAL_LIST_START = "GET_APPOINTMENT_LATE_TOTAL_LIST_START";
export const GET_APPOINTMENT_LATE_TOTAL_LIST_SUCCESS = "GET_APPOINTMENT_LATE_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_LATE_TOTAL_LIST_ERROR = "GET_APPOINTMENT_LATE_TOTAL_LIST_ERROR";

export const GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_START =
  "GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_START";
export const GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_SUCCESS =
  "GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_ERROR =
  "GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_ERROR";
export const GET_APPOINTMENT_ALL_TOTAL_LIST_START = "GET_APPOINTMENT_ALL_TOTAL_LIST_START";
export const GET_APPOINTMENT_ALL_TOTAL_LIST_SUCCESS = "GET_APPOINTMENT_ALL_TOTAL_LIST_SUCCESS";
export const GET_APPOINTMENT_ALL_TOTAL_LIST_ERROR = "GET_APPOINTMENT_ALL_TOTAL_LIST_ERROR";

export const PATIENT_PCP_RELATIONSHIP_TYPE_START = "PATIENT_PCP_RELATIONSHIP_TYPE_START";
export const PATIENT_PCP_RELATIONSHIP_TYPE_SUCCESS = "PATIENT_PCP_RELATIONSHIP_TYPE_SUCCESS";
export const PATIENT_PCP_RELATIONSHIP_TYPE_ERROR = "PATIENT_PCP_RELATIONSHIP_TYPE_ERROR";

export const PATIENT_PCP_ROLE_TYPE_START = "PATIENT_PCP_ROLE_TYPE_START";
export const PATIENT_PCP_ROLE_TYPE_SUCCESS = "PATIENT_PCP_ROLE_TYPE_SUCCESS";
export const PATIENT_PCP_ROLE_TYPE_ERROR = "PATIENT_PCP_ROLE_TYPE_ERROR";

export const SET_VALUES_FOR_CARE_PROVIDER_VALIDATION = "SET_VALUES_FOR_CARE_PROVIDER_VALIDATION";
