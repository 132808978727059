import React from "react";
import { connect } from "react-redux";

import DoctorProductionReportForm from "./DoctorProductionReportForm";

import { getDoctorProduction, initDoctorProduction } from "../../actions/dashboardActions";
import moment from "moment";
import Utils from "../../helpers/Utils";

const enhancer = connect(({ dashboard }) => ({ dashboard }), null);
class DoctorProductionReport extends React.Component {
  componentDidMount() {
    this.props.dispatch(initDoctorProduction());
  }

  render() {
    const {
      history,
      dashboard,
      dashboard: { report },
    } = this.props;

    const $rows = (report.items || []).map((item, index) => {
      let date = "";
      if (item.invoiceDate) {
        const dateArray = item.invoiceDate.split("T")[0].split("-");
        date = moment()
          .year(+dateArray[0])
          .month(+dateArray[1] - 1)
          .date(+dateArray[2])
          .format("MMM DD, YYYY");
      }

      return (
        <div className="default-table-row" key={index}>
          <div className="default-table-col col-1">{date}</div>
          <div className="default-table-col col-2">{item.invoiceNumber}</div>
          <div className="default-table-col col-3">{item.chartNumber}</div>
          <div className="default-table-col col-4">{item.patient}</div>
          <div className="default-table-col col-5">{item.insuranceCompany}</div>
          <div className="default-table-col col-6">{item.provider}</div>
          <div className="default-table-col col-9">{formatNumber(item.netAmount)}</div>
          <div className="default-table-col col-10">{formatNumber(item.cashPayment)}</div>
          <div className="default-table-col col-11">{formatNumber(item.creditCardPayment)}</div>
          <div className="default-table-col col-12">{formatNumber(item.chequePayment)}</div>
          <div className="default-table-col col-13">{formatNumber(item.patientBalance)}</div>
        </div>
      );
    });

    return (
      <div className="doctor-production-page">
        <DoctorProductionReportForm
          dashboard={dashboard}
          initialValues={{
            startDate: moment(),
            endDate: moment(),
            viewTypeCode: "DETAIL",
            dataTypeCode: "ALL",
          }}
          onBackClick={() => history.goBack()}
          onSubmit={() => this.props.dispatch(getDoctorProduction())}
        />

        <div className="default-table">
          <div className="default-table-header">
            <div className="default-table-col col-1">Date</div>
            <div className="default-table-col col-2">Number</div>
            <div className="default-table-col col-3">Chart #</div>
            <div className="default-table-col col-4">Patient</div>
            <div className="default-table-col col-5">Insurance Company</div>
            <div className="default-table-col col-6">Provider</div>
            <div className="default-table-col col-9">Net Amount</div>
            <div className="default-table-col col-10">Cash</div>
            <div className="default-table-col col-11">Credit Card</div>
            <div className="default-table-col col-12">Cheque</div>
            <div className="default-table-col col-13">Patient Balance</div>
          </div>
          <div className="default-table-body">{$rows}</div>
          <div className="default-table-row" style={{ background: "#e3f0e5" }}>
            <div className="default-table-col col-1">{}</div>
            <div className="default-table-col col-2">{}</div>
            <div className="default-table-col col-3">{}</div>
            <div className="default-table-col col-4">{}</div>
            <div className="default-table-col col-5">{}</div>
            <div className="default-table-col col-6">{}</div>
            <div className="default-table-col col-9">
              {Utils.numberToCurrency(report.netAmountTotal || 0)}
            </div>
            <div className="default-table-col col-10">
              {Utils.numberToCurrency(report.cashPaymentTotal || 0)}
            </div>
            <div className="default-table-col col-11">
              {Utils.numberToCurrency(report.creditCardPaymentTotal || 0)}
            </div>
            <div className="default-table-col col-12">
              {Utils.numberToCurrency(report.chequePaymentTotal || 0)}
            </div>
            <div className="default-table-col col-13">
              {Utils.numberToCurrency(report.patientBalanceTotal || 0)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(DoctorProductionReport);

function formatNumber(number) {
  if (+number) {
    return Utils.numberToCurrency(number);
  }

  return "";
}
