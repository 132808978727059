import _ from "lodash";
import React from "react";

import Back from "../widgets/Back";
import Show from "../widgets/Show";
import { SidebarMixed } from "./SidebarMixed";
import FilledImage from "../widgets/FilledImage";
import { SideBarModal } from "../ui/SideBarModal";
import { ViewButtonGroup } from "./ViewButtonGroup";
import { SidebarExisting } from "./SidebarExisting";
import { SidebarDiagnosis } from "./SidebarDiagnosis";
import { SidebarTreatments } from "./SidebarTreatments";
import { SidebarProcedures } from "./SidebarProcedures";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { SidebarTransactionView } from "./SidebarTransactionView";
import { SidebarTypes, TypeButtonGroup } from "./TypeButtonGroup";
import ChartBreakToVisit from "../chart/side-bar/transaction/ChartBreakToVisit";
import editImage from "../../assets/images/diagnosis_edit-icon.png";
import deleteImage from "../../assets/images/delete.png";
import { connect } from "react-redux";
import {
  getFavourites,
  clearSearchValues,
  getTreatmentItem,
} from "../../actions/chartSideSeriesActions";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import medicalFillInFormItemTypes from "../../constants/chart-sidebar/medicalFillInFormItemTypes";

export const SidebarStackTypes = {
  Item: "item",
  List: "list",
  AllCodes: "all-codes",
  Favorites: "favorites",
  Diagnosis: "diagnosis",
  Speciality: "speciality",
  BreakToVisit: "break-to-visit",
  ProcedureRoot: "procedure-root",
  SpecialityChild: "speciality-child",
  ProcedureCategory: "procedure-category",
  DiagnosisFavorites: "diagnosis-favorites",
  ProcedureSubCategory: "procedure-sub-category",
  DynamicPackage: "dynamic-package",
};

class ChartTreatmentsSidebar extends React.Component {
  constructor(props) {
    super(props);

    const isQuickMode = localStorage.getItem("quickMode") === "true";

    this.state = {
      item: {},
      stack: [],
      isQuickMode,
      viewTab: "list",
      typeTab: this.props.chart.sideBarTabType,
      categoryChildStack: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { viewType, handleHideTabBar } = this.props;

    if (viewType === viewTypes.transaction) {
      this.changeTypeTab(SidebarTypes.Transactions);
    }
    localStorage.removeItem("addDiagnosisToTreatment");
    handleHideTabBar(false);
  }

  componentWillUnmount() {
    this.props.clearSearchValues();
  }

  componentDidMount() {
    const { chartSideTransaction } = this.props;

    const fromTable = _.get(chartSideTransaction, "transactionItem.fromTable");

    if (fromTable) {
      this.editTransaction();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { typeTab: prevTypeTab } = prevState;
    const { typeTab: nextTypeTab } = this.state;
    const {
      chartSideSeries: { hideTabBar },
      handleHideTabBar,
    } = this.props;

    const { stack } = this.state;
    const DiagnosisFavorites = _.last(stack) === SidebarStackTypes.DiagnosisFavorites;
    if (DiagnosisFavorites && !hideTabBar) {
      handleHideTabBar(true);
    }

    if (prevTypeTab !== nextTypeTab && nextTypeTab === SidebarTypes.Mixed) {
      this.getList(SidebarTypes.Mixed);
    }
  }

  getFavorites = (typeTab) => {
    const { typeTab: defaultTypeTab } = this.state;

    const { getFavorites } = this.props;

    getFavorites(typeTab || defaultTypeTab);
  };

  clearSearch = (tab) => {
    const {
      onSearch,
      getSidebarExistingList,
      getSidebarDiagnosisList,
      getSidebarTreatmentList,
    } = this.props;
    const { typeTab, stack } = this.state;

    const sideBarTab = tab || typeTab;

    const isAllCodes = _.last(stack) === SidebarStackTypes.AllCodes;
    const isDiagnosis = _.last(stack) === SidebarStackTypes.Diagnosis;

    if (sideBarTab !== SidebarTypes.Transactions) {
      onSearch("");

      if (sideBarTab === SidebarTypes.Treatments && isAllCodes) {
        getSidebarTreatmentList();
      }

      if (sideBarTab === SidebarTypes.Existing && isAllCodes) {
        getSidebarExistingList();
      }

      if (
        (sideBarTab === SidebarTypes.Diagnosis && isAllCodes) ||
        (sideBarTab === SidebarTypes.Treatments && isDiagnosis)
      ) {
        getSidebarDiagnosisList();
      }
    }
  };

  changeViewTab = (viewTab) => {
    this.setState({ viewTab });
  };

  popStack = () => {
    this.clearSearch();

    const { stack } = this.state;

    const lastStack = _.last(stack);

    if (
      lastStack === SidebarStackTypes.Speciality ||
      lastStack === SidebarStackTypes.SpecialityChild
    ) {
      this.popCategoryChildStack();
    }

    stack.pop();

    const nextStack = _.last(stack);

    if (nextStack === SidebarStackTypes.SpecialityChild) {
      this.popCategoryChildStack();
    }

    this.setState({ stack });
  };

  popCategoryChildStack = () => {
    const { categoryChildStack } = this.state;

    categoryChildStack.pop();

    this.setState({ categoryChildStack });
  };

  clearStack = () => {
    this.clearSearch();

    this.setState({
      categoryChildStack: [],
      stack: [],
    });
  };

  pushStack = (value) => {
    this.clearSearch();

    const { stack } = this.state;

    stack.push(value);

    this.setState({ stack });
  };

  pushCategoryChildStack = (value) => {
    const { categoryChildStack } = this.state;

    categoryChildStack.push(value);

    this.setState({ categoryChildStack });
  };

  openCategory = (index, isGroup, categoryId) => {
    const { categoryChildStack } = this.state;

    if (isGroup) {
      categoryChildStack.push(index);

      this.pushStack(SidebarStackTypes.SpecialityChild);
    } else {
      categoryChildStack.push(categoryId);

      this.pushStack(SidebarStackTypes.AllCodes);

      this.getList(SidebarStackTypes.AllCodes, categoryId);
    }

    this.setState({ categoryChildStack });
  };

  getList = (tab, categoryId) => {
    const { getMixedCodes, getList } = this.props;
    const { typeTab, stack } = this.state;

    const lastStack = _.last(stack);

    if (typeTab === SidebarTypes.Treatments && lastStack === SidebarStackTypes.Diagnosis) {
      getList(SidebarStackTypes.Diagnosis);
    } else if (tab === SidebarStackTypes.AllCodes || typeTab === SidebarTypes.Mixed) {
      if (typeTab === SidebarTypes.Mixed) {
        getMixedCodes();
      } else {
        getList(typeTab, categoryId);
      }
    }
  };

  changeTypeTab = (typeTab) => {
    this.setState({ typeTab });
    this.clearSearch(typeTab);
  };

  loadList = (categoryId) => {
    const { chartSideSeries, loadMixedCodes, loadList } = this.props;
    const { typeTab, stack } = this.state;
    const { mixidCodes } = chartSideSeries;

    const lastStack = _.last(stack);

    if (typeTab === SidebarTypes.Mixed && _.size(mixidCodes) > 0) {
      loadMixedCodes();
    } else if (lastStack === SidebarStackTypes.Diagnosis) {
      loadList(SidebarTypes.Diagnosis, categoryId);
    } else {
      loadList(typeTab, categoryId);
    }
  };

  saveBreakToVisit = () => {
    const { saveBreakToVisit } = this.props;

    saveBreakToVisit();
  };

  toggleFavorite = (code, itemSeriesType) => {
    let { typeTab } = this.state;

    const { stack } = this.state;

    const isDiagnosis = _.last(stack) === SidebarStackTypes.Diagnosis;
    const isDiagnosisFavorites = _.last(stack) === SidebarStackTypes.DiagnosisFavorites;

    if (isDiagnosis || isDiagnosisFavorites) {
      typeTab = SidebarTypes.Diagnosis;
    }

    const favourites = JSON.parse(localStorage.getItem("favourites"));

    const endSeriesType = typeTab === SidebarTypes.Mixed ? itemSeriesType : typeTab;

    if (!favourites[endSeriesType][code]) {
      favourites[endSeriesType][code] = {};
    }

    favourites[endSeriesType][code].selected = !favourites[endSeriesType][code].selected;

    if (!favourites[endSeriesType][code].selected) {
      delete favourites[endSeriesType][code];
    }

    localStorage.setItem("favourites", JSON.stringify(favourites));

    this.forceUpdate();
  };

  openItem = (item, id, tab) => {
    const { typeTab } = this.state;
    const { openItem, orderStack } = this.props;
    const orderStackItem = _.last(orderStack);
    this.setState({ item });
    if (
      (orderStackItem?.code === medicalFillInFormStackCodes.labOrder &&
        orderStackItem?.type === medicalFillInFormItemTypes.new) ||
      (orderStackItem?.code === medicalFillInFormStackCodes.radiologyOrder &&
        orderStackItem?.type === medicalFillInFormItemTypes.new)
    ) {
      return this.props.getTreatmentItem({ id: item.id });
    }
    this.pushStack(SidebarStackTypes.Item);

    if (tab === SidebarTypes.Existing || typeTab === SidebarTypes.Existing) {
      openItem(id || item.id, tab || typeTab);
    }
  };

  clearItem = () => {
    this.setState({ item: {} });
  };

  saveProcedure = async (item, count, procedureForm) => {
    const { typeTab } = this.state;
    const {
      saveProcedure,
      inAppointment,
      onRequestClose,
      addRequestedTreament,
      showPriorApprovalPopup,
      saveProcedureRequestItem,
    } = this.props;

    const list = new Array(count).fill(1);

    if (typeTab === SidebarTypes.Grouped && item.type.code === "DYNAMIC") {
      const treatments = [];
      (item.treatments || []).forEach((treatment) => {
        for (let i = 0; i < treatment.quantity; i++) {
          if (procedureForm) {
            treatment.quantity = 1;
          }
          treatments.push(treatment);
        }
      });
      if (!procedureForm) {
        item.treatments = treatments.map((item) => ({ ...item, quantity: 1 }));
      }

      try {
        if (inAppointment) {
          const procedureId = _.get(item, "id");

          await saveProcedureRequestItem(procedureId, inAppointment);

          onRequestClose();
        } else {
          const transactionKeys = await saveProcedure(item);
          if (
            !transactionKeys?.info &&
            (this.props.patientView?.insurances?.totalCount || this.props.insurance?.total)
          ) {
            await showPriorApprovalPopup(transactionKeys, item);
            onRequestClose();
          }
        }
      } catch (e) {
        //
      }
      return;
    }

    for (const num in list) {
      const last = _.toFinite(num) + 1 === list.length;

      try {
        if (inAppointment) {
          if (typeTab === SidebarTypes.Grouped) {
            const procedureId = _.get(item, "id");

            await saveProcedureRequestItem(procedureId, inAppointment);

            if (last) {
              onRequestClose();
            }
          } else {
            await addRequestedTreament();

            if (last) {
              onRequestClose();
            }
          }
        } else {
          const transactionKeys = await saveProcedure(item);
          if (
            !transactionKeys?.info &&
            (this.props.patientView?.insurances?.totalCount || this.props.insurance?.total)
          ) {
            await showPriorApprovalPopup(transactionKeys, item);

            if (last) {
              onRequestClose();
            }
          } else if (last) {
            onRequestClose();
          }
        }
      } catch (e) {
        //
      }
    }
  };

  backFromItem = () => {
    const { stack, lastTab } = this.state;
    const { chartSideSeries, onRequestClose } = this.props;

    if (lastTab === SidebarTypes.Transactions) {
      onRequestClose();

      return;
    }

    if (chartSideSeries.transactionItem) {
      this.clearStack();
    } else if (stack.indexOf(SidebarStackTypes.Speciality) >= 0) {
      this.clearStack();
      this.pushStack(SidebarStackTypes.Speciality);
    } else {
      this.popStack();
    }
  };

  editTransaction = () => {
    const {
      editTransactionItem,
      chartSideTransaction: { transactionItem, transactionType },
    } = this.props;

    const seriesItem = transactionType.diagnosis
      ? transactionItem.diagnosisItem
      : transactionItem.treatmentItem;

    this.changeTypeTab(SidebarTypes.Treatments);
    this.pushStack(SidebarStackTypes.Item);
    this.setState({ lastTab: SidebarTypes.Transactions });

    editTransactionItem(transactionItem, seriesItem);
  };

  render() {
    const { item, stack, typeTab, viewTab, isQuickMode, categoryChildStack } = this.state;
    const {
      open,
      teeth,
      chart: { sideBarTabType },
      onSort,
      member,
      viewType,
      onSearch,
      initItem,
      updateNote,
      activeTooth,
      addDiagnosis,
      getMixedCodes,
      inAppointment,
      onRequestClose,
      chartSideSeries,
      getCategoryList,
      toothNumericType,
      loadCategoryList,
      handleHideTabBar,
      setTreatmentPlan,
      showImageSelector,
      deleteTransaction,
      changeActiveTooth,
      getGroupProcedureList,
      setTreatmentSession,
      changeCompletedDate,
      clearDiagnosisItems,
      chartSideTransaction,
      addRequestedTreament,
      getSidebarExistingList,
      getSidebarDiagnosisList,
      getSidebarTreatmentList,
      updateTransactionDiscount,
      repeatAppointmentModalShow,
      history,
      location,
    } = this.props;
    const { transactionItem, transactionType } = chartSideTransaction;
    const { favourites, searchValue, hideTabBar } = chartSideSeries;

    const seriesItem = transactionType.diagnosis
      ? transactionItem.diagnosisItem
      : transactionItem.treatmentItem;

    const isItem = _.last(stack) === SidebarStackTypes.Item;
    const chartType = _.get(member, "chartType.code");
    const isDynamicPackage = _.last(stack) === SidebarStackTypes.DynamicPackage;

    const lastStack = _.last(stack);

    const isTransactionView = typeTab === SidebarTypes.Transactions;

    const isDentalChart = chartType === "DENTAL_CHART";

    const numericKey = toothNumericType?.code === "Index1" ? "numeric1" : "numeric2";

    const options = [];

    _.forEach(teeth, (tooth, toothPosition) => {
      if (tooth.normal && tooth.normal.details) {
        options.push({
          value: +toothPosition.slice(1),
          label: tooth.normal.details[numericKey],
        });
      }

      if (tooth.supernumerary && tooth.supernumerary.details) {
        options.push({
          value: +tooth.supernumerary.details[numericKey],
          label: tooth.supernumerary.details[numericKey],
        });
      }
    });

    const sortOptions = _.sortBy(options, (item) => {
      return item.value;
    });

    const $options = sortOptions.map((option) => (
      <option key={`${option.value}-${option.label}`} value={option.value}>
        Tooth {option.label}
      </option>
    ));

    const permissions = _.get(this.props, "permissions", []);
    const type = _.get(transactionItem, "type.code");
    const type2 = _.get(transactionType, "code");

    const removePlannedTreatment =
      type === "TREATMENT" &&
      type2 === "planned" &&
      permissions.indexOf("REMOVE_TREATMENT_PLANNED_DENTAL_TRANSACTION_ITEM") >= 0;
    const removePlannedDiagnosis =
      type === "DIAGNOSIS" &&
      type2 === "planned" &&
      permissions.indexOf("REMOVE_DIAGNOSIS_PLANNED_DENTAL_TRANSACTION_ITEM") >= 0;
    const removeCompleteTreatment =
      type === "TREATMENT" &&
      type2 === "complete" &&
      permissions.indexOf("REMOVE_TREATMENT_COMPLETED_DENTAL_TRANSACTION_ITEM") >= 0;
    const removeCompleteDiagnosis =
      type === "DIAGNOSIS" &&
      type2 === "complete" &&
      permissions.indexOf("REMOVE_DIAGNOSIS_COMPLETED_DENTAL_TRANSACTION_ITEM") >= 0;
    const removeCompleteExisting =
      type === "EXISTING" &&
      type2 === "complete" &&
      permissions.indexOf("REMOVE_EXISTING_COMPLETED_DENTAL_TRANSACTION_ITEM") >= 0;

    const showEdit =
      transactionType.edit &&
      (removePlannedTreatment ||
        removePlannedDiagnosis ||
        removeCompleteTreatment ||
        removeCompleteDiagnosis ||
        removeCompleteExisting);

    return (
      <SideBarModal
        open={open}
        headerComponentClassName="sidebar-header-tooth"
        orderStack={this.props.orderStack}
        headerComponent={
          isTransactionView ? (
            lastStack === SidebarStackTypes.BreakToVisit ? (
              <span style={{ color: "white" }}>BREAK TO VISIT</span>
            ) : (
              <span style={{ color: "white" }}>{seriesItem && seriesItem.code}</span>
            )
          ) : isDentalChart && !isItem ? (
            <select
              value={activeTooth}
              className="form-control input-sm"
              onChange={({ target }) => changeActiveTooth(target.value)}
            >
              <option value="0">Tooth 00</option>
              {$options}
            </select>
          ) : (
            isDentalChart && (
              <span style={{ color: "white" }}>
                TOOTH {_.get(teeth, `p${activeTooth}.normal.details.${numericKey}`, "00")}
              </span>
            )
          )
        }
        onRequestClose={() => {
          this.clearSearch();

          onRequestClose();
        }}
        rightHeaderComponentClassName="chart-treatments-sidebar-groups view-group"
        headerRightComponent={
          isTransactionView ? (
            lastStack === SidebarStackTypes.BreakToVisit ? (
              <button
                className="btn btn-link"
                type="button"
                style={{ color: "white" }}
                onClick={this.saveBreakToVisit}
              >
                DONE
              </button>
            ) : (
              <div className="buttons" style={{ display: "flex" }}>
                <Show if={showEdit}>
                  <div
                    role="button"
                    className="edit"
                    onClick={() => this.editTransaction()}
                    style={{ marginRight: "12px", cursor: "pointer" }}
                  >
                    <img alt="edit" src={editImage} />
                  </div>
                </Show>
                <div
                  role="button"
                  className="delete"
                  onClick={deleteTransaction}
                  style={{ cursor: "pointer" }}
                >
                  <FilledImage color="#fff" className="zoom-2x" src={deleteImage} />
                </div>
              </div>
            )
          ) : (
            !isItem && (
              <ViewButtonGroup
                active={viewTab}
                onClick={this.changeViewTab}
                orderStack={this.props.orderStack}
              />
            )
          )
        }
        className="chart-treatments-sidebar"
        headerLeftComponent={
          <Back
            className="chart-treatments-sidebar-back-button"
            onClick={() => {
              clearDiagnosisItems();
              handleHideTabBar(false);

              if (viewType === viewTypes.transaction) {
                if (lastStack === SidebarStackTypes.BreakToVisit) {
                  this.popStack();
                } else {
                  onRequestClose();
                }
              } else {
                if (_.isEmpty(stack)) {
                  onRequestClose();
                } else {
                  this.popStack();
                }
              }
            }}
          />
        }
      >
        {Boolean(!isItem && !isTransactionView && !hideTabBar) && (
          <TypeButtonGroup
            propsTab={sideBarTabType}
            active={typeTab}
            inAppointment={inAppointment}
            orderStack={this.props.orderStack}
            medicalForms={this.props.chart.medicalForms}
            onClick={(tab) => {
              this.clearStack();
              this.changeTypeTab(tab);
            }}
          />
        )}

        {Boolean(isTransactionView && !lastStack) && (
          <SidebarTransactionView
            updateNote={updateNote}
            permissions={permissions}
            setTreatmentPlan={setTreatmentPlan}
            setTreatmentSession={setTreatmentSession}
            changeCompletedDate={changeCompletedDate}
            chartSideTransaction={chartSideTransaction}
            updateTransactionDiscount={updateTransactionDiscount}
            repeatAppointmentModalShow={repeatAppointmentModalShow}
            openBreakToVisit={() => this.pushStack(SidebarStackTypes.BreakToVisit)}
          />
        )}

        {lastStack === SidebarStackTypes.BreakToVisit && (
          <ChartBreakToVisit chartSideTransaction={chartSideTransaction} />
        )}

        {typeTab === SidebarTypes.Treatments && (
          <SidebarTreatments
            item={item}
            stack={stack}
            member={member}
            isItem={isItem}
            onSort={onSort}
            viewTab={viewTab}
            initItem={initItem}
            onSearch={onSearch}
            backFromItem={this.backFromItem}
            favourites={favourites}
            openItem={this.openItem}
            loadList={this.loadList}
            isQuickMode={isQuickMode}
            searchValue={searchValue}
            popStack={this.popStack}
            pushStack={this.pushStack}
            addDiagnosis={addDiagnosis}
            inAppointment={inAppointment}
            changeTypeTab={this.changeTypeTab}
            loadCategoryList={loadCategoryList}
            openFavorites={this.pushStack}
            onRequestClose={onRequestClose}
            getFavorites={this.getFavorites}
            toothNumericType={toothNumericType}
            changeActiveTooth={changeActiveTooth}
            addRequestedTreament={addRequestedTreament}
            getSidebarTreatmentList={getSidebarTreatmentList}
            getSidebarDiagnosisList={getSidebarDiagnosisList}
            getList={this.getList}
            onActionClick={(tab) => {
              this.getList(tab);
              this.pushStack(tab);
            }}
            openCategory={this.openCategory}
            chartSideSeries={chartSideSeries}
            toggleFavorite={this.toggleFavorite}
            showImageSelector={showImageSelector}
            categoryChildStack={categoryChildStack}
          />
        )}

        {typeTab === SidebarTypes.Diagnosis && (
          <SidebarDiagnosis
            item={item}
            stack={stack}
            member={member}
            isItem={isItem}
            onSort={onSort}
            viewTab={viewTab}
            onSearch={onSearch}
            initItem={initItem}
            popStack={this.popStack}
            favourites={favourites}
            openItem={this.openItem}
            loadList={this.loadList}
            isQuickMode={isQuickMode}
            searchValue={searchValue}
            inAppointment={inAppointment}
            backFromItem={this.backFromItem}
            changeTypeTab={this.changeTypeTab}
            openFavorites={this.pushStack}
            onRequestClose={onRequestClose}
            getFavorites={this.getFavorites}
            toothNumericType={toothNumericType}
            changeActiveTooth={changeActiveTooth}
            getSidebarDiagnosisList={getSidebarDiagnosisList}
            onActionClick={(tab) => {
              this.getList(tab);
              this.pushStack(tab);
            }}
            openCategory={this.openCategory}
            chartSideSeries={chartSideSeries}
            toggleFavorite={this.toggleFavorite}
            showImageSelector={showImageSelector}
          />
        )}

        {typeTab === SidebarTypes.Existing && (
          <SidebarExisting
            getList={this.getList}
            addRequestedTreament={addRequestedTreament}
            getSidebarExistingList={getSidebarExistingList}
            pushStack={this.pushStack}
            item={item}
            stack={stack}
            member={member}
            isItem={isItem}
            onSort={onSort}
            backFromItem={this.backFromItem}
            addDiagnosis={addDiagnosis}
            viewTab={viewTab}
            initItem={initItem}
            onSearch={onSearch}
            favourites={favourites}
            openItem={this.openItem}
            loadList={this.loadList}
            popStack={this.popStack}
            isQuickMode={isQuickMode}
            searchValue={searchValue}
            loadCategoryList={loadCategoryList}
            changeTypeTab={this.changeTypeTab}
            openFavorites={this.pushStack}
            onRequestClose={onRequestClose}
            getFavorites={this.getFavorites}
            toothNumericType={toothNumericType}
            onActionClick={(tab) => {
              this.getList(tab);
              this.pushStack(tab);
            }}
            openCategory={this.openCategory}
            chartSideSeries={chartSideSeries}
            toggleFavorite={this.toggleFavorite}
            changeActiveTooth={changeActiveTooth}
            showImageSelector={showImageSelector}
            categoryChildStack={categoryChildStack}
          />
        )}

        {typeTab === SidebarTypes.Mixed && (
          <SidebarMixed
            item={item}
            stack={stack}
            member={member}
            isItem={isItem}
            onSort={onSort}
            viewTab={viewTab}
            backFromItem={this.backFromItem}
            initItem={initItem}
            onSearch={onSearch}
            favourites={favourites}
            openItem={this.openItem}
            loadList={this.loadList}
            popStack={this.popStack}
            isQuickMode={isQuickMode}
            searchValue={searchValue}
            getList={this.getList}
            addRequestedTreament={addRequestedTreament}
            getMixedCodes={getMixedCodes}
            changeTypeTab={this.changeTypeTab}
            openFavorites={this.pushStack}
            onRequestClose={onRequestClose}
            getFavorites={this.getFavorites}
            toothNumericType={toothNumericType}
            onActionClick={(tab) => {
              this.getList(tab);
              this.pushStack(tab);
            }}
            openCategory={this.openCategory}
            chartSideSeries={chartSideSeries}
            toggleFavorite={this.toggleFavorite}
            changeActiveTooth={changeActiveTooth}
            showImageSelector={showImageSelector}
          />
        )}

        {typeTab === SidebarTypes.Grouped && (
          <SidebarProcedures
            stack={stack}
            isItem={isItem}
            initItem={initItem}
            onSearch={onSearch}
            loadList={this.loadList}
            popStack={this.popStack}
            searchValue={searchValue}
            pushStack={this.pushStack}
            openItem={this.saveProcedure}
            backFromItem={this.backFromItem}
            chartSideSeries={chartSideSeries}
            getCategoryList={getCategoryList}
            getGroupProcedureList={getGroupProcedureList}
            history={history}
            location={location}
            isDynamicPackage={isDynamicPackage}
            getSidebarTreatmentList={getSidebarTreatmentList}
          />
        )}
      </SideBarModal>
    );
  }
}

export default connect(null, { getFavourites, clearSearchValues, getTreatmentItem })(
  ChartTreatmentsSidebar,
);
