import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";

import * as ActionType from "../actionTypes/appointmantActionTypes";
import * as patientActionType from "../actionTypes/patientActionTypes";

import { PLEASE_SELECT_ITEM } from "../constants/Constants";
import { STATUS_TYPES } from "../constants/StatusTypes";
import { PHONE_CODE_LIST } from "../constants/PhoneConstants";

import {
  APPOINTMENT_CHANGE_ELIGIBILITY_PENDING,
  APPOINTMENT_GET_ADVANCED_PAYMENTS_ERROR,
  APPOINTMENT_GET_ADVANCED_PAYMENTS_START,
  APPOINTMENT_GET_ADVANCED_PAYMENTS_SUCCESS,
  APPOINTMENT_GET_APPROVAL_STATUS_SUCCESS,
  APPOINTMENT_GET_CATEGORIES_SUCCESS,
  APPOINTMENT_GET_CLINIC_COLORS_SUCCESS,
  APPOINTMENT_GET_DOCTORS_SUCCESS,
  APPOINTMENT_GET_LAB_STATUS_SUCCESS,
  APPOINTMENT_GET_LOCATIONS_SUCCESS,
  APPOINTMENT_GET_MACHINES_SUCCESS,
  APPOINTMENT_GET_OTHER_STATUS_SUCCESS,
  APPOINTMENT_GET_SUB_CATEGORIES_SUCCESS,
  APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS,
  APPOINTMENT_SET_ELIGIBILITY_ID,
  SET_APPOINTMENT_STATUS_COLORS,
  SET_APPOINTMENT_TYPE_COLORS,
  GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_SUCCESS,
  ADDITIONAL_CHARGES_FIELD_TOGGLE,
} from "../actions/appointmentActions";
import {
  defaultBTStypeColors,
  defaultStatusColors,
  defaultTypeColors,
} from "../constants/defaults";
import { COPY_TO_CLIPBOARD_APPOINTMENT } from "../actions/calendarActions";
import {
  SET_APPOINTMENT_ADDITIONAL_DOCTORS,
  SET_APPOINTMENT_TREATMENTS_PLAN_VALUES,
  SET_REQUESTED_APPOINTMENT_ITEM,
  SET_REQUESTED_APPOINTMENT_ITEM_PATIENT,
} from "../actionTypes/appointmantActionTypes";

const defaultState = {
  eligibilityId: {},
  eligibilityDetails: {},
  eligibilityPending: {},
  locationList: [],
  statusOtherList: [],
  statusApprovalList: [],
  statusLabList: [],
  statusList: [],
  categoryList: [],
  dentalPointList: [],
  dentistList: [],
  patientGenderList: [],
  patientList: [],
  patientDetails: {},
  patientNotes: [],
  patientNotesModalVisible: false,
  patient: null,
  patientFormData: {},
  patientSelectedBirthDate: undefined,
  freePoints: [],
  createdAppointment: null,
  appointment: null,
  item: null,
  fetchingInit: false,
  fetchingItem: false,
  changedStatus: null,
  reasonModalVisible: false,
  switchers: {
    prices: {
      checked: false,
      disabled: false,
      value: 0,
      defaultValue: 0,
    },
  },

  codesForThisModalVisible: false,
  thatMode: false,

  fetchingRelated: false,
  codesForThis: [],
  codesForThisTotalCount: 0,
  codesForThisRequested: {},
  codesForThisRequestedTotalCount: 0,
  thisSelected: {},
  showSidebar: false,
  relatedTab: "second",

  requestedCodes: [],

  requestedList: [],
  requestedListTotalCount: 0,
  requestedAppointmentItem: {},
  fetchingRequestedList: false,

  codesForThat: [],
  codesForThatTotalCount: 0,
  thatSelected: {},

  repeatAppointmentModalVisible: false,
  repeatAppointmentMode: "now",
  repeatAppointmentSelectedDate: moment(),
  repeatAppointmentList: [],
  repeatAppointmentConfirmModalVisible: false,
  repeatAppointmentRepeatModalVisible: false,
  repeatAppointmentRepeatModalMode: "after",
  repeatAppointmentRepeatModalDate: moment(),
  repeatAppointmentRepeatModalIndex: 0,
  repeatAppointmentClickedRepeatArray: [],
  repeatAppointmentDeleteId: 0,
  deleteAppointmentModalVisible: false,

  nurse: 0,
  sideBarVisible: false,

  consultationNotesSidebarVisible: false,
  consultationNotesList: [],
  consultationNotesCount: 0,
  consultationNotesItem: null,
  consultationNotesFetching: false,
  consultationNotesPage: "favourites",
  consultationNotesDeleteId: 0,
  consultationNotesAddModalVisible: false,
  consultationNotesEditId: 0,
  reasonModalStatus: "",

  consultationNote: "",

  temporaryPatient: [],
  temporaryResult: false,
  temporaryPatientData: null,
  temporaryPatientNew: false,
  temporaryReasonList: [],
  temporaryReasonShow: false,

  masterTypes: [],

  categoryService: [PLEASE_SELECT_ITEM],
  subCategoryService: [PLEASE_SELECT_ITEM],
  subSubCategoryService: [PLEASE_SELECT_ITEM],

  openSlotDoctorsList: [],

  doctorsList: [PLEASE_SELECT_ITEM],
  machinesList: [],

  moved: false,

  advancedPayments: [],
  fetchingAdvancedPayments: false,
  completeAppointmentsCount: 0,

  diagnosisCodeList: [],
  treatmentCodeList: [],

  selectedDiagnosisCodeList: {},
  selectedTreatmentCodeList: {},
  patientInfo: {},
  clipBoardAppointment: null,
  copyMode: null,
  additionalDoctors: [],
  appointmentTreatmentsPlanValues: [],
  toggleThisAllChecked: false,
  appointmentMeetingTypes: [],
  appointmentPaymentStatuses: [],
  deleteAppointmentOptions: [],
  missedAppointmentOptions: [],
  disableAdditionalChargesFieldForTempPatient: true,
  movedAppointmentsForFutureDate: [],
  dndCount: 0,
  appointmentPlannedTreatmentList: [],
  appointmentPlannedTreatmentListSelected: {},
  appointmentPlannedTreatmentListSelectedAll: false,
  appointmentPlannedTreatmentModalVisible: false,
};

const reducers = {
  [ADDITIONAL_CHARGES_FIELD_TOGGLE](state) {
    return {
      ...state,
      disableAdditionalChargesFieldForTempPatient: false,
    };
  },
  [SET_REQUESTED_APPOINTMENT_ITEM](state, { item }) {
    if (!item.patient.patientKey) {
      return {
        ...state,
        requestedAppointmentItem: item,
        patient: item.patient,
      };
    }
    return {
      ...state,
      requestedAppointmentItem: item,
    };
  },
  [SET_REQUESTED_APPOINTMENT_ITEM_PATIENT](state, { patient }) {
    return {
      ...state,
      item: {
        ...state.item,
        patient,
      },
    };
  },
  [COPY_TO_CLIPBOARD_APPOINTMENT](state, { clipBoardAppointment, copyMode }) {
    return {
      ...state,
      clipBoardAppointment,
      copyMode,
      moved: copyMode === "move",
    };
  },
  [ActionType.GET_APPOINTMENT_MEETING_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentMeetingTypes: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [ActionType.GET_APPOINTMENT_PAYMENT_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentPaymentStatuses: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [SET_APPOINTMENT_ADDITIONAL_DOCTORS](state, { additionalDoctors }) {
    return {
      ...state,
      additionalDoctors,
    };
  },
  [SET_APPOINTMENT_TREATMENTS_PLAN_VALUES](state, { appointmentTreatmentsPlanValues }) {
    return {
      ...state,
      appointmentTreatmentsPlanValues,
    };
  },
  [APPOINTMENT_SET_ELIGIBILITY_ID](state, { id, appointmentId }) {
    if (!id || !appointmentId) {
      return state;
    }

    return {
      ...state,
      eligibilityId: {
        ...state.eligibilityId,
        [appointmentId]: id,
      },
    };
  },
  [APPOINTMENT_SET_ELIGIBILITY_DETAILS_SUCCESS](state, { details, data }) {
    if (!details && !data?.data) {
      return state;
    }

    return {
      ...state,
      eligibilityDetails: details || data?.data,
    };
  },
  [APPOINTMENT_CHANGE_ELIGIBILITY_PENDING](state, { isPending, appointmentId }) {
    if (!appointmentId) {
      return state;
    }

    return {
      ...state,
      eligibilityPending: {
        ...state.eligibilityPending,
        [appointmentId]: isPending,
      },
    };
  },
  [ActionType.CLEAR_APPOINTMENT](state) {
    return {
      ...state,
      appointment: null,
      item: null,
      eligibilityDetails: {},
      additionalDoctors: [],
      appointmentTreatmentsPlanValues: [],
      requestedAppointmentItem: {},
      disableAdditionalChargesFieldForTempPatient: true,
    };
  },
  [ActionType.SET_APPOINTMENT_PATIENT_EMIRATES_ID](state, { emiratesId }) {
    return {
      ...state,
      item: {
        ...state.item,
        patient: {
          ...state.item.patient,
          documentNationalIdNumber: emiratesId,
        },
      },
    };
  },
  [ActionType.APPOINTMENT_DIAGNOSIS_CODE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      diagnosisCodeList: (data && data.list) || [],
    };
  },
  [ActionType.APPOINTMENT_TREATMENT_CODE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      treatmentCodeList: (data && data.list) || [],
    };
  },
  [ActionType.PATIENT_GET_DETAILS_INFO_SUCCESS](state, { data }) {
    return {
      ...state,
      patientInfo: data,
    };
  },
  [APPOINTMENT_GET_LOCATIONS_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      locationList: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [APPOINTMENT_GET_OTHER_STATUS_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      statusOtherList: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [APPOINTMENT_GET_APPROVAL_STATUS_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      statusApprovalList: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [APPOINTMENT_GET_LAB_STATUS_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      statusLabList: [{ id: "", name: "Please select" }, ...data],
    };
  },
  [ActionType.GET_COUNT_BY_STATUS_SUCCESS](state, { data }) {
    return {
      ...state,
      completeAppointmentsCount: data,
    };
  },
  [ActionType.OPEN_TIME_SLOT_RESET_DOCTORS](state) {
    return {
      ...state,
      openSlotDoctorsList: state.openSlotDoctorsList.map((x) => ({
        ...x,
        selected: false,
      })),
    };
  },
  [ActionType.SET_OPEN_SLOT_DOCTORS](state, { doctors }) {
    return {
      ...state,
      openSlotDoctorsList: _.map(doctors, (x) => ({
        ...x,
        selected: false,
      })),
    };
  },
  [ActionType.OPEN_TIME_SLOT_CHOOSE_DOCTOR](state, { id }) {
    const { openSlotDoctorsList } = state;

    if (_.toFinite(id) > 0) {
      return {
        ...state,
        openSlotDoctorsList: openSlotDoctorsList.map((x) => ({
          ...x,
          selected: x.id === _.toFinite(id) ? !x.selected : x.selected,
        })),
      };
    }

    return {
      ...state,
      openSlotDoctorsList: openSlotDoctorsList.map((x) => ({
        ...x,
        selected: false,
      })),
    };
  },
  [APPOINTMENT_GET_CLINIC_COLORS_SUCCESS](state, { data }) {
    const { statusColors = [], categoryColors = [], blockedTimeSlotTypeColors = [] } = data;

    const statuses = {
      ...defaultStatusColors,
    };
    const types = {
      ...defaultTypeColors,
    };
    const btsTypes = {
      ...defaultBTStypeColors,
    };

    statusColors.forEach(({ code, value, name }) => {
      const current = statuses[code] || {};
      const currentColor = value
        ? { color: value }
        : statuses[code]
        ? statuses[code].color
        : {
            color: "rgb(204, 204, 204)",
            name: "Gray",
            code: "Gray",
          };

      statuses[code] = {
        ...current,

        code,
        name,

        color: currentColor,
      };
    });

    categoryColors.forEach(({ value, name }) => {
      const current = types[name] || {};
      const currentType = types[name];
      const currentColor = value
        ? { color: value }
        : types[name]
        ? types[name].color
        : {
            color: "rgb(51, 204, 153)",
            name: "Green",
            code: "Green",
          };

      if (!currentType) {
        types[name] = {
          name,

          ...current,

          color: currentColor,
        };
      } else {
        types[currentType.code] = {
          name,

          ...current,

          color: currentColor,
        };
      }
    });

    blockedTimeSlotTypeColors.forEach(({ code, value, name }) => {
      const current = btsTypes[code] || {};
      const currentColor = value
        ? { color: value }
        : btsTypes[code]
        ? btsTypes[code].color
        : {
            color: "#cdcdcd",
            name: "Silver",
            code: "Silver",
          };

      btsTypes[code] = {
        ...current,

        code,
        name,

        color: currentColor,
      };
    });

    localStorage.setItem("appointmentType", JSON.stringify(types));
    localStorage.setItem("appointmentStatus", JSON.stringify(statuses));
    localStorage.setItem("blockedTimeSlotType", JSON.stringify(btsTypes));

    return {
      ...state,
      appointmentType: types,
      appointmentStatus: statuses,
      blockedTimeSlotTypes: btsTypes,
    };
  },
  [GET_ONLY_BLOCKED_TIME_SLOT_COLORS_TYPE_SUCCESS](state, { data }) {
    const { blockedTimeSlotTypeColors = [] } = data;

    const btsTypes = {
      ...defaultBTStypeColors,
    };

    blockedTimeSlotTypeColors.forEach(({ code, value, name }) => {
      const current = btsTypes[code] || {};
      const currentColor = value
        ? { color: value }
        : btsTypes[code]
        ? btsTypes[code].color
        : {
            color: "#cdcdcd",
            name: "Silver",
            code: "Silver",
          };

      btsTypes[code] = {
        ...current,

        code,
        name,

        color: currentColor,
      };
    });

    localStorage.setItem("blockedTimeSlotType", JSON.stringify(btsTypes));

    return {
      ...state,
      blockedTimeSlotTypes: btsTypes,
    };
  },
  [SET_APPOINTMENT_STATUS_COLORS](state, { colors }) {
    return {
      ...state,
      appointmentStatus: colors,
    };
  },
  [SET_APPOINTMENT_TYPE_COLORS](state, { colors }) {
    return {
      ...state,
      appointmentType: colors,
    };
  },
  [APPOINTMENT_GET_ADVANCED_PAYMENTS_START](state) {
    return {
      ...state,
      advancedPayments: [],
      fetchingAdvancedPayments: true,
    };
  },
  [APPOINTMENT_GET_ADVANCED_PAYMENTS_SUCCESS](state, { data }) {
    return {
      ...state,
      advancedPayments: data,
      fetchingAdvancedPayments: false,
    };
  },
  [APPOINTMENT_GET_ADVANCED_PAYMENTS_ERROR](state) {
    return {
      ...state,
      fetchingAdvancedPayments: false,
    };
  },
  [ActionType.APPOINTMENT_GET_REQUEST_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      requestedList: request.start ? state.requestedList.concat(data.list) : data.list || [],
      requestedListTotalCount: data.totalCount || 0,
    };
  },
  [APPOINTMENT_GET_DOCTORS_SUCCESS](state, { data }) {
    return {
      ...state,
      openSlotDoctorsList: data || [],
      doctorsList: [PLEASE_SELECT_ITEM, ...(data || [])],
    };
  },
  [APPOINTMENT_GET_MACHINES_SUCCESS](state, { data }) {
    return {
      ...state,
      machinesList: [
        ...(data || []).map((x) => ({
          code: x.name,
          ...x,
        })),
      ],
    };
  },
  [APPOINTMENT_GET_CATEGORIES_SUCCESS](state, { data, request }) {
    const isSubCategories = _.get(request, "parentId", 0) > 0;

    const result = {};

    const dataArray = [PLEASE_SELECT_ITEM, ...(data || [])];

    if (isSubCategories) {
      result.subCategoryService = dataArray;
    } else {
      result.categoryService = dataArray;
    }

    return {
      ...state,
      ...result,
    };
  },
  [APPOINTMENT_GET_SUB_CATEGORIES_SUCCESS](state, { data }) {
    const subSubCategoryService = [PLEASE_SELECT_ITEM, ...(data || [])];

    return {
      ...state,
      subSubCategoryService,
    };
  },
  [ActionType.APPOINTMENT_ADD_REQUESTED_TREATMENT](state, { item, tooth }) {
    const appointmentId = _.get(state, "appointment.id");
    const currentList = _.get(state, `codesForThisRequested.${appointmentId}`, {});
    const treatmentKey = Date.now();

    const list = {
      ...state.codesForThisRequested,

      [appointmentId]: {
        ...currentList,

        [treatmentKey]: {
          key: treatmentKey,
          item,
          tooth,
        },
      },
    };

    return {
      ...state,

      codesForThisRequested: list,
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_SIDEBAR](state) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    };
  },
  [ActionType.CHANGE_APPOINTMENT_RELATED_TAB](state, { tab }) {
    return {
      ...state,

      relatedTab: tab,
      thisSelected: {},
    };
  },
  [ActionType.APPOINTMENT_MOVE](state) {
    return {
      ...state,
      moved: true,
    };
  },
  [ActionType.OPEN_REASON_MODAL](state) {
    return {
      ...state,
      temporaryReasonShow: true,
    };
  },
  [ActionType.CLOSE_REASON_MODAL](state) {
    return {
      ...state,
      temporaryReasonShow: false,
    };
  },
  [ActionType.GET_REASON_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      temporaryReasonList: data || [],
    };
  },
  [ActionType.INIT_FIRST_CONTACT](state) {
    return {
      ...state,
      temporaryPatient: [],
      temporaryResult: false,
      temporaryPatientData: null,
      temporaryPatientNew: false,
      temporaryReasonList: [],
      item: null,
      temporaryReasonShow: false,
      consultationNote: "",
    };
  },
  [ActionType.ADD_NEW_TEMPORARY_PATIENT](state, { data }) {
    let temporaryPatientData;

    if (data.companyTempPatientId) {
      const phone = data.mobilePhoneNumber;

      if (phone) {
        const phoneArr = phone.slice(1).split(" ");

        const pCountry = _.head(PHONE_CODE_LIST.filter((item) => item.phoneCode === phoneArr[0]));
        const pTown = phoneArr[1].slice(1).slice(0, -1);
        const pPhone = phoneArr[2];

        if (pCountry) {
          temporaryPatientData = {
            ...data,
            mobilePhoneNumber: {
              country: {
                label: pCountry.name,
                value: pCountry.phoneCode,
              },
              town: pTown,
              phone: pPhone,
            },
          };
        }
      }
    } else {
      temporaryPatientData = data;
    }

    return {
      ...state,
      temporaryPatientNew: true,
      temporaryPatientData,
    };
  },
  [ActionType.CHOOSE_TEMPORARY_PATIENT](state, { patient }) {
    return {
      ...state,
      temporaryPatientData: patient,
    };
  },
  [ActionType.SEARCH_TEMPORARY_PATIENT_RESULT_SHOW](state) {
    return {
      ...state,
      temporaryResult: true,
    };
  },
  [ActionType.SEARCH_TEMPORARY_PATIENT_RESULT_HIDE](state) {
    return {
      ...state,
      temporaryResult: false,
    };
  },
  [ActionType.SEARCH_TEMPORARY_PATIENT_SUCCESS](state, { data }) {
    return {
      ...state,
      temporaryPatient: data || [],
    };
  },
  [ActionType.SET_CONSULTATION_NOTE](state, { value }) {
    return {
      ...state,
      consultationNote: value,
    };
  },
  [ActionType.RESET_CONSULTATION_NOTES_ITEM](state) {
    return {
      ...state,
      consultationNotesItem: null,
    };
  },
  [ActionType.EDIT_CONSULTATION_NOTES_ITEM_SUCCESS](state) {
    return {
      ...state,
      consultationNotesItem: null,
    };
  },
  [ActionType.GET_MASTER_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      masterTypes: [PLEASE_SELECT_ITEM, ...data],
    };
  },
  [ActionType.GET_CONSULTATION_NOTES_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      consultationNotesItem: data || {},
    };
  },
  [ActionType.CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_SHOW](state, { id }) {
    return {
      ...state,
      consultationNotesDeleteId: id,
    };
  },
  [ActionType.CONSULTATION_NOTES_CONFIRM_DELETE_MODAL_HIDE](state) {
    return {
      ...state,
      consultationNotesDeleteId: 0,
    };
  },
  [ActionType.GET_CONSULTATION_NOTES_LIST_START](state) {
    return {
      ...state,
      consultationNotesFetching: true,
    };
  },
  [ActionType.GET_CONSULTATION_NOTES_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      consultationNotesList: request.start
        ? state.consultationNotesList.concat(data.list)
        : data.list || [],
      consultationNotesFetching: false,
      consultationNotesCount: data.totalCount || 0,
    };
  },
  [ActionType.GET_CONSULTATION_NOTES_LIST_ERROR](state) {
    return {
      ...state,
      consultationNotesFetching: false,
    };
  },
  [ActionType.CONSULTATION_NOTES_SIDEBAR_SHOW](state) {
    return {
      ...state,
      consultationNotesSidebarVisible: true,
      consultationNotesList: [],
      consultationNotesCount: 0,
      consultationNotesItem: null,
      consultationNotesFetching: false,
      consultationNotesPage: "favourites",
      consultationNotesDeleteId: 0,
      consultationNotesAddModalVisible: false,
      consultationNotesEditId: 0,
    };
  },
  [ActionType.CONSULTATION_NOTES_SIDEBAR_HIDE](state) {
    return {
      ...state,
      consultationNotesSidebarVisible: false,
    };
  },
  [ActionType.HIDE_SIDE_BAR](state) {
    return {
      ...state,
      sideBarVisible: false,
    };
  },
  [ActionType.SHOW_SIDE_BAR](state) {
    return {
      ...state,
      sideBarVisible: true,
    };
  },
  [ActionType.DELETE_APPOINTMENT_MODAL_SHOW](state) {
    return {
      ...state,
      deleteAppointmentModalVisible: true,
    };
  },
  [ActionType.DELETE_APPOINTMENT_MODAL_HIDE](state) {
    return {
      ...state,
      deleteAppointmentModalVisible: false,
    };
  },
  [ActionType.APPOINTMENT_SELECT_BIRTH_DATE](state, { date }) {
    return {
      ...state,
      patientSelectedBirthDate: date,
    };
  },
  [ActionType.APPOINTMENT_PATIENT_NOTES_MODAL_SHOW](state) {
    return {
      ...state,
      patientNotesModalVisible: true,
    };
  },
  [ActionType.APPOINTMENT_PATIENT_NOTES_MODAL_HIDE](state) {
    return {
      ...state,
      patientNotesModalVisible: false,
    };
  },
  [ActionType.PATIENT_VIEW_GET_NOTES_SUCCESS](state, { data }) {
    return {
      ...state,
      patientNotes: data.list || [],
    };
  },
  [ActionType.PATIENT_VIEW_GET_NOTES_START](state) {
    return {
      ...state,
      patientNotes: [],
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_DELETE_ID](state, { id }) {
    return {
      ...state,
      repeatAppointmentDeleteId: id,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY](state, { id }) {
    return {
      ...state,
      repeatAppointmentClickedRepeatArray: [...state.repeatAppointmentClickedRepeatArray, id],
    };
  },
  [ActionType.REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY](state, { id }) {
    const newArray = [...state.repeatAppointmentClickedRepeatArray];

    const index = newArray.indexOf(id);
    if (index !== -1) newArray.splice(index, 1);

    return {
      ...state,
      repeatAppointmentClickedRepeatArray: newArray,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE](state, { value }) {
    return {
      ...state,
      repeatAppointmentRepeatModalDate: value,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE](state, { mode }) {
    return {
      ...state,
      repeatAppointmentRepeatModalMode: mode,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW](state, { index }) {
    return {
      ...state,
      repeatAppointmentRepeatModalVisible: true,
      repeatAppointmentRepeatModalIndex: index,
      repeatAppointmentRepeatModalMode: "after",
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentRepeatModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_FOLLOW_TYPE](state, { mode }) {
    return {
      ...state,
      repeatAppointmentMode: mode,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW](state) {
    return {
      ...state,
      repeatAppointmentConfirmModalVisible: true,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentConfirmModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_GET_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      repeatAppointmentList: data.list,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SELECT_DATE](state, { value }) {
    return {
      ...state,
      repeatAppointmentSelectedDate: value,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_MODAL_SHOW](state) {
    return {
      ...state,
      repeatAppointmentModalVisible: true,
      repeatAppointmentMode: "now",
      repeatAppointmentSelectedDate: moment(),
      repeatAppointmentList: [],
      repeatAppointmentRepeatModalMode: "after",
      repeatAppointmentRepeatModalDate: moment(),
      repeatAppointmentRepeatModalIndex: 0,
      repeatAppointmentClickedRepeatArray: [],
      repeatAppointmentDeleteId: 0,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentModalVisible: false,
    };
  },
  [ActionType.GET_STATUS_LIST_SUCCESS](state, { data }) {
    return { ...state, statusList: [PLEASE_SELECT_ITEM, ...data] };
  },
  [ActionType.GET_CATEGORY_LIST_SUCCESS](state, { data }) {
    return { ...state, categoryList: [PLEASE_SELECT_ITEM, ...data] };
  },
  [ActionType.GET_DENTAL_POINT_SUCCESS](state, { data }) {
    return { ...state, dentalPointList: [PLEASE_SELECT_ITEM, ...data] };
  },
  [ActionType.GET_CHOOSE_DENTIST_SUCCESS](state, { data }) {
    return {
      ...state,
      dentistList: [PLEASE_SELECT_ITEM, ...data],
      dentalPointList: [],
      freePoints: [],
    };
  },
  [ActionType.GET_FREE_POINTS_SUCCESS](state, { data }) {
    return { ...state, freePoints: data };
  },
  [ActionType.GET_MULTIPLE_DOCTOR_FREE_POINTS_SUCCESS](state, { data }) {
    return { ...state, freePoints: data };
  },
  [ActionType.GET_PATIENT_GENDER_SUCCESS](state, { data }) {
    return { ...state, patientGenderList: data };
  },
  [ActionType.GET_APPOINTMENT](state, { fetchingItem }) {
    return { ...state, fetchingItem };
  },
  [ActionType.CHANGE_APPOINTMENT_ITEM](state, { item, isUpdate }) {
    let newItem;

    if (item.companyTempPatientId) {
      newItem = {
        patient: item,
      };
    } else {
      newItem = item;
    }

    return {
      ...state,
      item: isUpdate
        ? {
            ...state.item,
            ...newItem,
          }
        : newItem,
    };
  },
  [ActionType.INIT_APPOINTMENT](state, { fetchingInit, fromFirstContact }) {
    let checked = state.switchers.prices.checked;
    let item = null;

    if (!fetchingInit) {
      checked = false;
    }

    if (fromFirstContact) {
      if (state.item) {
        if (state.item.patient) {
          item = state.item;
        } else {
          item = { patient: state.item };
        }
      }
    } else if (fetchingInit) {
      item = state.item;
    }

    return {
      ...state,

      patient: null,
      fetchingInit,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          checked,
        },
      },
      item,
      moved: false,
      patientSelectedBirthDate: undefined,
    };
  },
  [ActionType.GET_APPOINTMENT_SUCCESS](state, { data }) {
    let disabled;
    switch (data.status.code) {
      case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
      case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
      case STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED:
      case STATUS_TYPES.APPOINTMENT_STATUS_PARTIALLY_PAYED:
        disabled = false;
        break;
      default:
        disabled = true;
        break;
    }

    return {
      ...state,
      appointment: data,
      item: data,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          value: data.cashTreatmentPriceId,
          checked: Boolean(data.cashTreatmentPriceId),
          disabled,
        },
      },
      nurse: _.get(data, "nurse.id", 0),
    };
  },
  [patientActionType.GET_PATIENT_PRICE_LIST_SUCCESS](state, { data }) {
    let defaultValue;

    (data || []).forEach((item) => {
      if (item.selected) defaultValue = item.id;
    });

    return {
      ...state,
      createdAppointment: data,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          defaultValue,
        },
      },
    };
  },
  [ActionType.APPOINTMENT_CHOOSE_PRICE](state, { value }) {
    return {
      ...state,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          value,
        },
      },
    };
  },
  [ActionType.CREATE_APPOINTMENT_SUCCESS](state, { data }) {
    return {
      ...state,
      createdAppointment: data,
      temporaryPatientData: null,
      // item: null,
    };
  },
  [ActionType.APPOINTMENT_STATUS_CHANGE_SUCCESS](state, { data }) {
    return { ...state, changedStatus: data };
  },
  [ActionType.SEARCH_PATIENT_LIST_SUCCESS](state, { data }) {
    return { ...state, patientList: data };
  },
  [ActionType.CHOOSE_PATIENT_ITEM](state, { patient }) {
    // patient.age=Utils.getAge(patient.dateOfBirth)
    return { ...state, patient };
  },
  [ActionType.APPOINTMENT_REASON_MODAL_OPEN](state, { reasonStatus }) {
    return {
      ...state,
      reasonModalVisible: true,
      reasonModalStatus: reasonStatus,
    };
  },
  [ActionType.APPOINTMENT_REASON_MODAL_CLOSE](state) {
    return { ...state, reasonModalVisible: false };
  },
  [ActionType.SET_APPOINTMENT_PLANNED_TREATMENT_MODAL_VISIBLE](state, { visible }) {
    return { ...state, appointmentPlannedTreatmentModalVisible: visible };
  },
  [ActionType.GET_APPOINTMENT_PLANNED_TREATMENT_LIST_SUCCESS](state, { data }) {
    return { ...state, appointmentPlannedTreatmentList: data.list || [] };
  },
  [ActionType.TOGGLE_PLANNED_TREATMENT](state, { key }) {
    const appointmentPlannedTreatmentListSelected = {
      ...state.appointmentPlannedTreatmentListSelected,
    };

    appointmentPlannedTreatmentListSelected[key] = !appointmentPlannedTreatmentListSelected[key];

    const _appointmentPlannedTreatmentListSelected = Object.values(
      appointmentPlannedTreatmentListSelected,
    ).filter(Boolean).length;
    return {
      ...state,
      appointmentPlannedTreatmentListSelected,
      appointmentPlannedTreatmentListSelectedAll:
        _appointmentPlannedTreatmentListSelected === state.appointmentPlannedTreatmentList.length,
    };
  },
  [ActionType.TOGGLE_PLANNED_TREATMENT_ALL](state, { isSelected }) {
    let _appointmentPlannedTreatmentListSelected = {
      ...state.appointmentPlannedTreatmentListSelected,
    };

    if (isSelected) {
      _appointmentPlannedTreatmentListSelected = state.appointmentPlannedTreatmentList.reduce(
        (accumulator, item) => {
          accumulator[item.key] = true;
          return accumulator;
        },
        {},
      );
    } else {
      _appointmentPlannedTreatmentListSelected = {};
    }

    return {
      ...state,
      appointmentPlannedTreatmentListSelected: _appointmentPlannedTreatmentListSelected,
      appointmentPlannedTreatmentListSelectedAll: isSelected,
    };
  },
  [ActionType.SAVE_CONSUMED_CANCELLED_TREATMENTS_SUCCESS](state) {
    return {
      ...state,
      appointmentPlannedTreatmentList: [],
      appointmentPlannedTreatmentListSelected: {},
      appointmentPlannedTreatmentListSelectedAll: false,
      appointmentPlannedTreatmentModalVisible: false,
    };
  },
  [ActionType.TOGGLE_SWITCHER_STATUS](state, { checked }) {
    return {
      ...state,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          checked,
        },
      },
    };
  },
  [ActionType.TOGGLE_DISABLED_SWITCHER_STATUS](state, { disabled }) {
    return {
      ...state,
      switchers: {
        ...state.switchers,
        prices: {
          ...state.switchers.prices,
          disabled,
        },
      },
    };
  },
  [ActionType.APPOINTMENT_SHOW_CODES_FOR_THIS_MODAL](state) {
    return {
      ...state,
      codesForThisModalVisible: true,
    };
  },
  [ActionType.APPOINTMENT_CLOSE_CODES_FOR_THIS_MODAL](state) {
    return {
      ...state,
      codesForThisModalVisible: false,
      relatedTab: "second",
    };
  },
  [ActionType.APPOINTMENT_INIT_RELATED_MODAL](state) {
    return {
      ...state,
      thatMode: false,
      thisSelected: {},
      thatSelected: {},
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_THAT_MODE](state) {
    return {
      ...state,
      thatMode: !state.thatMode,
    };
  },
  [ActionType.APPOINTMENT_UNSELECT_THAT](state) {
    return {
      ...state,
      thatSelected: {},
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_THIS](state, { key }) {
    const thisSelected = { ...state.thisSelected };

    thisSelected[key] = !thisSelected[key];

    const _thisToggle = Object.values(thisSelected).filter(Boolean).length;

    return {
      ...state,
      thisSelected,
      toggleThisAllChecked: _thisToggle === state.requestedList.length,
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_THIS_ALL](state, { isSelected }) {
    let _thisSelected = { ...state.thisSelected };

    if (isSelected) {
      _thisSelected = state.requestedList.reduce((accumlator, item) => {
        accumlator[item.id] = true;
        return accumlator;
      }, {});
    } else {
      _thisSelected = {};
    }

    return {
      ...state,
      thisSelected: _thisSelected,
      toggleThisAllChecked: isSelected,
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_THIS_ALL](state, { isSelected }) {
    let _thisSelected = { ...state.thisSelected };

    if (isSelected) {
      _thisSelected = state.requestedList.reduce((accumlator, item) => {
        accumlator[item.id] = true;
        return accumlator;
      }, {});
    } else {
      _thisSelected = {};
    }

    return {
      ...state,
      thisSelected: _thisSelected,
      toggleThisAllChecked: isSelected,
    };
  },
  [ActionType.APPOINTMENT_CONVERT_REQUEST_TO_PLANNED_SUCCESS](state) {
    return {
      ...state,
      thisSelected: {},
    };
  },
  [ActionType.APPOINTMENT_TOGGLE_THAT](state, { key }) {
    const thatSelected = { ...state.thatSelected };

    thatSelected[key] = !thatSelected[key];

    return {
      ...state,
      thatSelected,
    };
  },
  [ActionType.APPOINTMENT_GET_RELATED_LIST_START](state) {
    return {
      ...state,
      fetchingRelated: true,
    };
  },
  [ActionType.CHANGE_PATIENT_FORM_DATA](state, { data }) {
    return {
      ...state,
      patientFormData: {
        ...state.patientFormData,
        ...data,
      },
    };
  },
  [ActionType.APPOINTMENT_GET_RELATED_LIST_SUCCESS](state, { data, request }) {
    if (request.appointmentId) {
      if (request.start) {
        return {
          ...state,
          fetchingRelated: false,
          codesForThis: state.codesForThis.concat(data.list),
          codesForThisTotalCount: data.totalCount,
        };
      }

      return {
        ...state,
        fetchingRelated: false,
        codesForThis: data.list || [],
        codesForThisTotalCount: data.totalCount,
      };
    }

    if (request.start) {
      return {
        ...state,
        fetchingRelated: false,
        codesForThat: state.codesForThat.concat(data.list),
        codesForThatTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetchingRelated: false,
      codesForThat: data.list || [],
      codesForThatTotalCount: data.totalCount,
    };
  },
  [ActionType.APPOINTMENT_GET_RELATED_LIST_ERROR](state) {
    return {
      ...state,
      fetchingRelated: false,
    };
  },
  [ActionType.APPOINTMENT_RELATED_REMOVE_SUCCESS](state) {
    return {
      ...state,
      thisSelected: {},
    };
  },
  [ActionType.APPOINTMENT_RELATED_ADD_SUCCESS](state) {
    return {
      ...state,
      thatSelected: {},
    };
  },
  [ActionType.APPOINTMENT_CHANGE_NURSERY](state, { value }) {
    return {
      ...state,
      nurse: value,
    };
  },
  [ActionType.SET_DELETE_APPOINTMENT_DELETE_OPTIONS](state, { data }) {
    return {
      ...state,
      deleteAppointmentOptions: data,
    };
  },
  [ActionType.SET_MISSED_APPOINTMENT_OPTIONS](state, { data }) {
    return {
      ...state,
      missedAppointmentOptions: data,
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const appointmentPatientSelector = ({ appointment }) =>
  appointment.item?.patient || appointment.patient;

export const switchersSelector = ({ appointment }) => appointment.switchers;

export const patientNotesSelector = ({ appointment }) => appointment.patientNotes;

export const appointmentSelector = ({ appointment }) => appointment;
export const appointmentAppointmentSelector = ({ appointment }) => appointment.appointment;

export const appointmentItemSelector = ({ appointment }) => appointment.item;

export const appointmentSideBarVisibleSelector = ({ appointment }) => appointment.sideBarVisible;
export const consultationNotesSidebarVisibleSelector = ({ appointment }) =>
  appointment.consultationNotesSidebarVisible;

export const appointmentEligibilityIdSelector = (appointmentId) => ({ appointment }) => {
  if (appointmentId) {
    return appointment.eligibilityId[appointmentId];
  }
};
export const appointmentEligibilityDetailsSelector = (appointmentId) => ({ appointment }) => {
  if (appointmentId) {
    if (appointment.eligibilityDetails?.eligibilityCheck) return appointment.eligibilityDetails;
    return null;
  }
};
export const appointmentEligibilityPendingSelector = (appointmentId) => ({ appointment }) => {
  if (appointmentId) {
    return appointment.eligibilityPending[appointmentId];
  }
};

export default createReducer(defaultState, reducers);
