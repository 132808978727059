import _ from "lodash";
import React from "react";

import { Sidebar } from "../sidebar/Sidebar";
import ChartFillInForm from "../chart/side-bar/fill-in-from/ChartFillInForm";
import fillInFormItemTypes from "../../constants/chart-sidebar/fillInFormItemTypes";
import { fillInFormStackCodes } from "../../constants/chart-sidebar/fillInFormStackCodes";
import { useDispatch } from "react-redux";

export default function ChartSideBar(props) {
  const {
    member,
    clinic,
    history,
    location,
    patientKey,
    hideSideBar,
    sideBarVisible,
    fromAppointment,
    chartSideFillInForm,
    setFillInFormAddendumId,
  } = props;
  const dispatch = useDispatch();

  const { stack } = chartSideFillInForm;

  const stackItem = _.last(stack);
  const isTypeNew = stackItem?.type === fillInFormItemTypes.new;
  const isTypeView = stackItem?.type === fillInFormItemTypes.view;
  const isTypeEdit = stackItem?.type === fillInFormItemTypes.edit;
  const isCodeItem = stackItem.code === fillInFormStackCodes.item;

  const isFullScreen = isCodeItem && (isTypeNew || isTypeView || isTypeEdit);

  return (
    <Sidebar
      fullScreen={isFullScreen}
      dialogClassName="chart-side-bar"
      show={sideBarVisible}
      onHide={() => {
        hideSideBar();
        setFillInFormAddendumId && dispatch(setFillInFormAddendumId(0));
      }}
    >
      <ChartFillInForm
        member={member}
        clinic={clinic}
        history={history}
        location={location}
        patientKey={patientKey}
        hideSideBar={hideSideBar}
        fromAppointment={fromAppointment}
        chartSideFillInForm={chartSideFillInForm}
      />
    </Sidebar>
  );
}
