import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS } from "../../../../actionTypes/medicalFillInFormActionTypes";
import MedicalFIllInFormViewItem from "./MedicalFillInFormViewItem";

const MedicalFillInFormView = ({ history, location, chartSideMedicalFillInForm, clinicName }) => {
  const dispatch = useDispatch();
  const { consultationItems, consultationId } = chartSideMedicalFillInForm;
  useEffect(() => {
    // if (consultationId) {
    //   editNote();
    // }
    return () => {
      dispatch({ type: CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
    };
  }, []);

  if (consultationItems.length) {
    return (
      <div>
        {consultationItems
          .sort((a, b) => {
            if (a.template.id > b.template.id) return 1;
            if (a.template.id < b.template.id) return -1;
            return 0;
          })
          .map((consultation, index) => (
            <MedicalFIllInFormViewItem
              key={index}
              clinicName={clinicName}
              consultation={consultation}
              editMode={false}
              history={history}
              location={location}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
            />
          ))}
        <div className="medical-fill-in-form-question__actions">
          {/*<button className="btn btn-primary" onClick={editNote}>*/}
          {/*  edit*/}
          {/*</button>*/}
        </div>
      </div>
    );
  }
  return null;
};

export default MedicalFillInFormView;
