import React from "react";

export default function condition(props) {
  const { property, toothImage } = props;
  if (!property) return null;

  const src = require(`../../../assets/images/conditions/fracture/fracture_lower_${toothImage}_1@2x.png`)
    .default;

  return (
    <div className="condition-wrap tooth-image-condition">
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
