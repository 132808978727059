import React, { useMemo } from "react";
import { useField } from "formik";

import { FieldContainer, FieldContainerProps } from "./FieldContainer";
import {
  BlockedTimeSlotSelectwrapper,
  BTSselectWrapperProps,
} from "../blocked-time-slot/BlockedTimeSlotSelectwrapper";

interface Props extends BTSselectWrapperProps {
  readonly name: string;
  readonly label?: string;
  readonly fieldProps?: Omit<FieldContainerProps, "label" | "children">;
}

export function BtsTypeColorSelectField({ name, label, fieldProps, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <BlockedTimeSlotSelectwrapper
        {...props}
        {...field}
        onChange={(x, e) => {
          helpers.setValue(x);

          if (props.onChange) {
            props.onChange(x, e);
          }
        }}
      />
    </FieldContainer>
  );
}
