import React, { useMemo } from "react";
import { useBlockedTimeSlotTypeColor } from "../../queries/blocked-time-slot/useBlockedTimeSlotTypeColor";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";

export interface BTSselectWrapperProps extends Omit<SelectPickerProps, "options"> {
  readonly allLabel?: string;
}

interface ListItem {
  label: string;
}
export function BlockedTimeSlotSelectwrapper({ allLabel, ...props }: BTSselectWrapperProps) {
  const btsTypeQuery = useBlockedTimeSlotTypeColor();

  const list = useMemo(() => {
    const btsTypeList = btsTypeQuery.data || [];

    const newList: ListItem[] = allLabel ? [{ label: allLabel }] : [];

    return [
      ...newList,
      ...btsTypeList.map((colorType) => ({ value: colorType.id, label: colorType.name })),
    ];
  }, [allLabel, btsTypeQuery.data]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isMulti={false}
      closeMenuOnSelect={true}
      isLoading={btsTypeQuery.isLoading}
    />
  );
}
