import { useField } from "formik";
import React, { useMemo } from "react";
import { FieldContainer, FieldContainerProps } from "./FieldContainer";
import { PhoneNumberWrapper, PhoneNumberInputWrapperProps } from "../ui/PhoneNumberWrapper";

interface Props extends Omit<PhoneNumberInputWrapperProps, "value" | "onChange"> {
  readonly name: string;
  readonly label?: string;
  readonly search?: boolean | undefined;
  readonly fieldProps?: Omit<FieldContainerProps, "label">;
}

export function PhoneNumberField({ name, label, fieldProps, search, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      search={search}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <PhoneNumberWrapper
        {...props}
        search={search}
        value={field.value}
        onChange={(x) => helpers.setValue(x)}
      />
    </FieldContainer>
  );
}
