import React, { useEffect, useState } from "react";
import FormTextArea from "./form-components/FormTextArea";
import FormValue from "./form-components/FormValue";
import FormDatePicker from "./form-components/FormDatePicker";
import FormRadioList from "./form-components/FormRadioList";
import FormCheckboxList from "./form-components/FormCheckboxList";
import MedicalFillInFormOverlay from "./MedicalFillInFormOverlay";
import Show from "../../../widgets/Show";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";
import _, { last } from "lodash";
import ReactDOMServer from "react-dom/server";
import FormCanvas from "./form-components/FormCanvas";
import FormGenerateFormula from "./form-components/FormGenerateFormula";
import FormPatientDocs from "./form-components/FormPatientDocs";
import Utils from "../../../../helpers/Utils";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT, TIME_FORMAT } from "../../../../constants/Constants";
import FormCheckboxListWithComment from "./form-components/FormCheckboxListWithComment";

const NA = "N/A";

const MedicalFIllInFormViewItem = ({
  chartSideMedicalFillInForm,
  editMode,
  images,
  clinicName,
  consultation = null,
  onSaveItem,
}) => {
  const { stack, activeTab, empty } = chartSideMedicalFillInForm;
  const [overlay, setOverlay] = useState({ visible: false });
  const [canvasImage, setCanvasImage] = useState("");
  const [patientImage, setPatientImage] = useState("");
  const [form, setForm] = useState(consultation);
  const lastStack = last(stack);

  useEffect(() => {
    setForm(consultation);
  }, [activeTab]);

  useEffect(() => {
    if (consultation.template) {
      consultation.template.templateBody = getTemplate(consultation.template?.templateBody);
      setForm(consultation);
    }
  }, [consultation]);

  const getTemplate = (template = "") => {
    if (!template) return null;
    const reqExp = /\[\[[^\]]*]]/g;
    const { patient, member } = empty;
    return template.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      let pickListQuestion = false;
      template.replace(/PICKLIST/g, (m) => {
        if (m === "PICKLIST") pickListQuestion = true;
      });
      if (pickListQuestion) {
        const currentQuestion = (form.questions || []).find((item) => item.code === code);
        if (!currentQuestion) return "";
        const columns = Number(currentQuestion.typeCode.replace(/^\D+/g, "") || 0);
        let gridColumns = "";
        for (let i = 0; i < columns; i++) {
          gridColumns += "auto ";
        }
        let result = `<div style="text-decoration: none; display: inline-grid; gap: 10px; padding: 10px; grid-template-columns: ${gridColumns}">${currentQuestion?.options?.map(
          (option) => {
            return `<div><button data-index="${option.id}" id="${currentQuestion.id}-${
              option.id
            }" value="pick-list-${currentQuestion.id}" class="pick-list-button btn ${
              option.selected ? "btn-success" : "btn-default"
            }" style="padding: 1px">${option.title}</button>${
              option.comment
                ? `<span style="display: block; font-size: 14px">Remarks</span>
                      <p
                        style="font-size: 14px"
                        class="form-control pick-list-comment"
                      >${option.comment || ""}</p>`
                : ""
            }</div>`;
          },
        )}</div>`;
        return result.replaceAll(",", "");
      }
      if (code === "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code === "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code === "Patient chart number") {
        return patient && patient.chartNumber;
      }
      if (code === "Patient name") {
        return patient && patient.fullName;
      }
      if (code === "Patient nationality") {
        return _.get(patient, "nationality.name", "N/A");
      }
      if (code === "Patient primary insurance image") {
        for (const form in images) {
          for (const image in images[form]) {
            const { url } = images[form][image];

            if (url) {
              return `<img style="width: 100%; max-width: 300px" src=${url} alt="img"/>`;
            }
          }
        }
      }
      if (code === "Patient date of birth") {
        const dateFormat = localStorage.getItem("company-date-format");

        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(patient && patient.dateOfBirth),
          dateFormat,
        );
      }
      if (code === "Dentist Name") {
        return member && member.name;
      }
      if (code === "Check-in date time") {
        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(empty.checkedInDate),
          FULL_DATE_FORMAT,
        );
      }
      if (code === "Doctor license id") {
        return member && member.code;
      }
      if (code === "Patient last name") {
        return patient && patient.lastName;
      }
      if (code === "Patient first name") {
        return patient && patient.firstName;
      }
      if (code === "Patient primary card number") {
        return empty.primaryInsuranceCardNumber;
      }
      if (code === "Patient secondary card number") {
        return empty.secondaryInsuranceCardNumber;
      }
      if (code === "Patient gender") {
        return patient && patient.gender && patient.gender.name;
      }
      if (code === "Patient driver's licence number") {
        return patient && patient.documentDriverLicenseNumber;
      }
      if (code === "Patient social security number") {
        return patient && patient.documentSocialSecurityNumber;
      }
      if (code === "Patient social insurance number") {
        return patient && patient.documentSocialInsuranceNumber;
      }
      if (code === "Patient national id number") {
        return patient && patient.documentNationalIdNumber;
      }
      if (code === "Patient passport number") {
        return patient && patient.documentPasswordNumber;
      }
      if (code === "Clinic name") {
        return clinicName;
      }
      if (code === "Patient mobile number") {
        return patient && patient.mobilePhoneNumber;
      }
      return "N/A";
    });
  };

  useEffect(() => {
    if (consultation?.questions.length) {
      setForm(consultation);
    }
  }, [consultation?.questions]);

  useEffect(() => {
    if (document.querySelector("span[data-index]")) {
      const elements = document.querySelectorAll("span[data-index]");
      elements.forEach((element) => {
        const question = form.questions.find((i) => Number(i.id) === Number(element.dataset.index));
        if (question) {
          if (question.typeCode === questionTypeCodes.CANVAS) {
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setCanvasImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="canvas"
                id="canvasId"
                className="canvas-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.IMAGE) {
            imageUrlToDataURL(
              question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url,
              (dataUrl) => {
                setPatientImage(dataUrl);
              },
            );
            element.innerHTML = `${ReactDOMServer.renderToString(
              <img
                data-index={question.id}
                alt="mouth"
                id="mouthId"
                className="mouth-image"
                src={
                  question?.answerFile || question?.answerPhoto?.url || question?.questionPhoto?.url
                }
              />,
            )}`;
            return;
          }
          if (question.typeCode === questionTypeCodes.FORMULA) {
            element.innerHTML = Utils.calculateStringToNumber(question?.answer) || NA;
            return;
          }
          if (question?.options?.length) {
            if (question.typeCode.replace(/[^a-z]/gi, "") === questionTypeCodes.PICKLIST) return "";
            const { options } = question;
            const value = options
              .map((option) => {
                let answer = "";
                if (option.selected) {
                  answer = `${option.otherValue || (!option.other ? option.title : "")} <br>`;
                  if (option.comment) {
                    answer = answer + `${option.comment} <br>`;
                  }
                }
                return answer;
              })
              .join("");
            element.innerHTML = value || NA;
          } else {
            element.innerHTML = question?.answer || question?.defaultAnswer || NA;
          }
        }
      });
    }
  }, [form.questions]);
  
  const getField = (questionTypeCode, questionId, visible, canAddComment = false) => {
    if (
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA
    ) {
      return (
        <FormTextArea
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    }
    if (questionTypeCode === questionTypeCodes.VALUE)
      return (
        <FormValue
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.DATE_PICKER)
      return (
        <FormDatePicker
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.RADIO_LIST)
      return (
        <FormRadioList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && !canAddComment)
      return (
        <FormCheckboxList
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST && canAddComment)
      return (
        <FormCheckboxListWithComment
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.CANVAS)
      return (
        <FormCanvas
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
          canvasImage={canvasImage}
        />
      );
    if (questionTypeCode === questionTypeCodes.FORMULA)
      return (
        <FormGenerateFormula
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
        />
      );
    if (questionTypeCode === questionTypeCodes.IMAGE)
      return (
        <FormPatientDocs
          setOverlay={setOverlay}
          visible={visible}
          editMode={editMode}
          questionId={questionId}
          setForm={setForm}
          form={form}
          patientImage={patientImage}
          questionTypeCode={questionTypeCode}
          questionTypeCodes={questionTypeCodes}
        />
      );
  };

  const setTemplateData = (event) => {
    if (!editMode) return;
    if (event.target.dataset?.index) {
      const id = event.target.dataset.index;
      const question = consultation.questions.find((item) => Number(item.id) === Number(id));
      setOverlay({
        visible: true,
        innerWidth: event.view.innerWidth,
        innerHeight: event.view.innerHeight,
        top: event.clientY,
        left: event.clientX,
        question: question.question || "",
        typeCode: question.typeCode,
        component: getField(question.typeCode, question.id, true, question.canAddComment),
      });
    }
  };
  
  const imageUrlToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  if (form.template) {
    const { template } = form;
    const templateBody = getTemplate(template?.templateBody);
    return (
      <div className="medical-fill-in-form-question">
        <MedicalFillInFormOverlay
          overlay={overlay}
          onClose={() => setOverlay({ visible: false })}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div className="medical-fill-in-form-question__title">{template.name}</div>
        </div>
        <div
          className="medical-fill-in-form-question__body"
          dangerouslySetInnerHTML={{ __html: templateBody }}
          onClick={(event) => setTemplateData(event)}
        />
        <div className="medical-fill-in-form-question__actions">
          <Show if={editMode}>
            <button className="btn btn-primary" onClick={onSaveItem}>
              {lastStack?.type === medicalFillInFormItemTypes.new ? "Save" : "Update"}
            </button>
          </Show>
        </div>
      </div>
    );
  }
  return null;
};

export default MedicalFIllInFormViewItem;
