import React from "react";

import PopoverWidget from "./PopoverWidget";

export default class SortPicker extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false,
    };
  }

  openPopover = () => {
    this.setState({ show: true });
  };

  closePopover = () => {
    this.setState({ show: false });
  };

  selectItem = (item, index) => {
    const { element } = this.props;

    element.handler(item, index);

    this.closePopover();
  };

  render() {
    const { show } = this.state;
    const { element } = this.props;

    const $li =
      element &&
      element.options.map((item, index) => (
        <li
          key={item.id}
          onClick={() => this.selectItem(item, index)}
          className={item.className + (item.name === element.selected.name ? " selected" : "")}
        >
          {item.value}
        </li>
      ));

    return (
      <div className="sort-picker">
        <span
          role="button"
          key={element.id}
          onClick={this.openPopover}
          className="sort-btn form-control"
        >
          {element.selected && element.selected.value}︎
        </span>
        <PopoverWidget show={show} closePopover={this.closePopover}>
          <h4 className="title">Sort by:</h4>
          <ul className="list sort-options">{$li}</ul>
        </PopoverWidget>
      </div>
    );
  }
}
