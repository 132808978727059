import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartGeneralActionTypes";
import { CHART_SHOW_SIDE_BAR } from "../actions/chartActions";

export const defaultState = {
  fetchingGoal: false,
  goalList: [],
  goalListTotalCount: 0,
  stickyLists: [{}],
  fetchingSticky: false,
  activeStickyIndex: 0,
};

const reducers = {
  [ActionTypes.CHART_GENERAL_GET_GOAL_LIST_START](state) {
    return {
      ...state,
      fetchingGoal: true,
    };
  },
  [ActionTypes.CHART_GENERAL_GET_GOAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      fetchingGoal: false,
      goalList: data.list || [],
      goalListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.CHART_GENERAL_GET_GOAL_LIST_ERROR](state) {
    return {
      ...state,
      fetchingGoal: false,
    };
  },
  [ActionTypes.CHART_GENERAL_GET_STICKY_LIST_SUCCESS](
    state,
    { data: { list }, request: { index, categoryId } },
  ) {
    const stickyLists = [...state.stickyLists];

    stickyLists[index] = {
      list: list || [],
      categoryId,
    };

    return {
      ...state,
      stickyLists,
    };
  },
  [ActionTypes.CHART_GENERAL_TOGGLE_GOAL](state, { index }) {
    const newGoalItem = {
      ...state.goalList[index],
    };

    newGoalItem.selected = !newGoalItem.selected;

    const goalList = state.goalList.slice();

    goalList[index] = newGoalItem;

    return {
      ...state,
      goalList,
    };
  },
  [ActionTypes.CHART_GENERAL_SET_GOAL_NOTE](state, { index, value }) {
    const newGoalItem = {
      ...state.goalList[index],
    };

    newGoalItem.notes = value;

    const goalList = state.goalList.slice();

    goalList[index] = newGoalItem;

    return {
      ...state,
      goalList,
    };
  },
  [ActionTypes.CHART_GENERAL_SET_STICKY_NOTE](state, { index, value }) {
    const stickyLists = state.stickyLists.slice();

    const activeStickyIndex = state.activeStickyIndex;

    const newStickyItem = {
      list: stickyLists[activeStickyIndex].list.slice(),
      categoryId: stickyLists[activeStickyIndex].categoryId,
    };

    stickyLists[activeStickyIndex] = newStickyItem;

    const newStickyItemItem = {
      ...newStickyItem.list[index],
    };

    newStickyItemItem.notes = value;

    newStickyItem.list[index] = newStickyItemItem;

    return {
      ...state,
      stickyLists,
    };
  },
  [ActionTypes.CHART_GENERAL_PUSH_GOAL](state) {
    const goalList = state.goalList.slice();

    goalList.push({});

    return {
      ...state,
      goalList,
    };
  },
  [ActionTypes.CHART_GENERAL_PUSH_STICKY](state) {
    const stickyLists = state.stickyLists.slice();

    const activeStickyIndex = state.activeStickyIndex;

    const newStickyItem = {
      list: stickyLists[activeStickyIndex].list.slice(),
      categoryId: stickyLists[activeStickyIndex].categoryId,
    };

    stickyLists[activeStickyIndex] = newStickyItem;

    newStickyItem.list.push({});

    return {
      ...state,
      stickyLists,
    };
  },
  [ActionTypes.CHART_GENERAL_SET_GOAL_LIST](state, { goalList }) {
    return {
      ...state,
      goalList,
    };
  },
  [ActionTypes.CHART_GENERAL_SET_STICKY_ITEM](state, { stickyItem, activeStickyIndex }) {
    const stickyLists = state.stickyLists.slice();
    stickyLists[activeStickyIndex] = stickyItem;

    return {
      ...state,
      stickyLists,
    };
  },
  [CHART_SHOW_SIDE_BAR](state, { index }) {
    return {
      ...state,
      activeStickyIndex: index,
    };
  },
};

export default createReducer(defaultState, reducers);
