import React, { useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { get } from "lodash";
import { Modal } from "../ui/Modal";
import { useQuery } from "../../hooks/useQuery";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import SidebarProcedureFormChild from "./SidebarProcedureFormChild";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { fetchingProcedureItem } from "../../actions/chartSideSeriesActions";
import { useProcedurePackageItemQuery } from "../../queries/procedurePackage/useProcedurePackageListQuery";
import Utils from "../../helpers/Utils";
const SidebarAddNewProcedureForm = ({
  customizePackageModalVisible,
  customizePackageModalHide,
  history,
  location,
  openItem,
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const _parseQuery = parseQuery(location.search);
  const getItemQuery = useProcedurePackageItemQuery(query);
  const modalVisible = Boolean(customizePackageModalVisible && getItemQuery?.data?.id);

  useEffect(() => {
    dispatch(fetchingProcedureItem(Boolean(getItemQuery?.isFetching)));
  }, [dispatch, getItemQuery?.isFetching]);

  const initialValues = useMemo(() => {
    const treatmentsList = getItemQuery.data || [];
    let values = {
      desiredPackageAmount: 0,
      totalPackagePrice: 0,
      treatments: [],
    };

    if (treatmentsList?.id) {
      const treatments = [];
      if (treatmentsList.type.code === "DYNAMIC") {
        (treatmentsList.treatments || []).forEach((treatment) => {
          for (let i = 0; i < treatment.quantity; i++) {
            // treatment.quantity = 1;
            treatments.push(treatment);
          }
        });
        treatmentsList.treatments = treatments;
      }
      const totalPrice = (treatmentsList.treatments || []).reduce(
        (acc, item) => acc + Number(item.packagePrice || 0),
        0,
      );
      values = {
        ...treatmentsList,
        desiredPackageAmount: treatmentsList?.desiredPackageAmount,
        totalPackagePrice: Math.round(totalPrice),
        treatments: treatmentsList?.treatments?.map((item) => {
          let totalPrice = item.quantity ? item.price * item.quantity : item.price;
          return {
            ...item,
            treatmentName: `${item.name} ${item.description}`,
            quantity: item.quantity || 1,
            unit: item.unit,
            systemPrice: Utils.formatPrice(item.price, item.price),
            systemPriceTotals: Utils.formatPrice(totalPrice, totalPrice),
            packagePrice: item.packagePrice,
            packagePriceInt: Utils.formatPrice(item.packagePrice, item.packagePrice),
            treatmentId: item.id,
          };
        }),
      };
    }
    return values;
  }, [getItemQuery.data]);
  const validate = (values) => {
    const errors = {};

    if (values.treatments?.length > 0) {
      values.treatments.forEach((treatment, index) => {
        if (!treatment.treatmentName) {
          errors[`treatmentNameError${index}`] = true;
        }
        if (!treatment.quantity) {
          errors[`quantityError${index}`] = true;
        }
        if (!treatment.unit) {
          errors[`unitError${index}`] = true;
        }
        if (!treatment.systemPrice) {
          errors[`systemPriceError${index}`] = true;
        }
        if (!treatment.systemPriceTotals) {
          errors[`systemPriceTotalsError${index}`] = true;
        }
      });
    }

    return errors;
  };

  const formatValuesHandler = (values) => {
    const transfer = {
      ...values,
    };

    if ((values.treatments || []).length > 0) {
      transfer.treatments = values.treatments?.map((item) => {
        return {
          treatment: { id: get(item, "id") },
          code: get(item, "code"),
          id: get(item, "id"),
          packagePrice: Number(get(item, "packagePrice")),
          quantity: get(item, "quantity"),
          unit: Number(get(item, "unit")),
        };
      });
    }
    return transfer;
  };
  return (
    <Modal dialogClassName="sidebar-add-new-procedure-modal" show={modalVisible} className="p-0">
      <div className="chart-sidebar-add-procedure-head mb-3">
        <h4 className="ml-1 mr-2">Edit Package</h4>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validate}
        onSubmit={async (values, { setFieldValue }) => {
          history.replace(
            createUrl(location.pathname, { query: { ..._parseQuery, id: undefined } }),
          );
          dispatch(startLoader());
          try {
            dispatch(stopLoader());
            customizePackageModalHide();
            history.replace(
              createUrl(location.pathname, { query: { ..._parseQuery, id: undefined } }),
            );
            openItem(formatValuesHandler(values), 1, true);
            setFieldValue("treatments", []);
          } catch (e) {
            customizePackageModalHide();
            setFieldValue("treatments", []);
            dispatch(stopLoader(e));
          }
        }}
      >
        {({ values, errors, touched, setValues, setFieldValue }) => {
          const systemPriceTotal = (values.treatments || []).reduce(
            (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
            0,
          );

          return (
            <SidebarProcedureFormChild
              setValues={setValues}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
              systemPriceTotal={systemPriceTotal}
              history={history}
              location={location}
              _parseQuery={_parseQuery}
              customizePackageModalHide={customizePackageModalHide}
            />
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SidebarAddNewProcedureForm;
