import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "../dropdown/Dropdown";
import { Dropdown as DropDownBS } from "react-bootstrap";

import Reminders from "../reminders/Reminders";
import { Routes } from "../../constants/Routes";
import FilledImage from "../widgets/FilledImage";
import { createUrl } from "../../utils/UrlUtils";
import bindActions from "../../helpers/bindActions";
import { PushNotificationModal } from "./PushNotificationModal";
import * as calendarActions from "../../actions/calendarActions";
import * as remindersActions from "../../actions/remindersActions";
import MenuBarImage from "../../assets/images/top-menu/bottomMenuBarIcon.png";
import PrintImage from "../../assets/images/calendar-bottom-menu/print@2x.png";
import WaitingImage from "../../assets/images/calendar-bottom-menu/waiting_list@2x.png";
import NewApptImage from "../../assets/images/calendar-bottom-menu/new_appt@2x.png";
import FindApptImage from "../../assets/images/calendar-bottom-menu/find_appt@2x.png";
import FindOpenSlotImage from "../../assets/images/calendar-bottom-menu/find_open_slot@2x.png";
import MessagesImage from "../../assets/images/calendar-bottom-menu/messages@2x.png";
import ApptsTodayImage from "../../assets/images/appointments_today.png";
import RefreshImage from "../../assets/images/calendar-bottom-menu/refresh@2x.png";
import SendPushImage from "../../assets/images/send-push.png";
import { showMessage } from "../../actions/loaderActions";
import { downloadElementAsJpeg } from "../../utils/ImageUtils";

const enhancer = connect(
  ({ reminders: { remindersModalVisible }, session: { patientAppActivated, permissions } }) => ({
    remindersModalVisible,
    patientAppActivated,
    permissions,
  }),
  bindActions({ remindersActions, calendarActions, showMessage }),
);
// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick, permissions, showMessage }, ref) => {
  const printAppointmentPermission =
    permissions.findIndex((item) => item === "PRINT_APPOINTMENT_CALENDAR") >= 0;
  return (
    <div
      role="button"
      ref={ref}
      className="item"
      onClick={(e) => {
        e.preventDefault();
        !printAppointmentPermission ? showMessage("You don't have permission") : onClick(e);
      }}
    >
      {children}
    </div>
  );
});
export default enhancer(function CalendarBottomMenu({
  reload,
  history,
  calendarActions,
  remindersActions,
  patientAppActivated,
  remindersModalVisible,
  permissions,
  showMessage,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSendPush, setShowSendPush] = useState(false);
  const downloadAsImage = useCallback(() => downloadElementAsJpeg("calendar"), []);
  return (
    <div className="calendar-bottom-menu" style={showMenu ? { bottom: 0 } : {}}>
      <div className="calendar-bottom-menu-opener" onClick={() => setShowMenu((x) => !x)}>
        <img alt="" src={MenuBarImage} />
      </div>
      <div id="calendar-bottom-menu" className="black-menu">
        <div className="d-flex">
          <Link to={createUrl(Routes.DashboardAppointment, { params: { id: 0 } })} className="item">
            <img alt="" className="icon" src={NewApptImage} />
            <div className="text">New Appt</div>
          </Link>
          <div onClick={calendarActions.findAppointmentSidebarShow} className="item">
            <img alt="" className="icon" src={FindApptImage} />
            <div className="text">Find Appt</div>
          </div>
          {/*<div role="button" onClick={calendarActions.findOpenSlotSidebarShow} className="item">*/}
          {/*  <img alt="" className="icon" src={FindOpenSlotImage} />*/}
          {/*  <div className="text">Find open Slot</div>*/}
          {/*</div>*/}
          <Dropdown customToggleProps={{ icon: WaitingImage }} drop="up" title="List">
            <li>
              <Link to={Routes.DashboardReferralNextVisitList}>
                <div className="text">Referral | Next Visit</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.PatientClaimsApprovals}>
                <div className="text">Approvals & Claims</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardBringEarlier}>
                <div className="text">Bring Earlier</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardFollowUp}>
                <div className="text">Follow Up</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardRequestedAppointmentsList}>
                <div className="text">Requested Appointment</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardCallList}>
                <div className="text">Call list</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardWaitingList}>
                <div className="text">Waiting list</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardRecallList}>
                <div className="text">Recall list</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.DashboardMissedCancelledList}>
                <div className="text">Missed/Cancelled List</div>
              </Link>
            </li>
          </Dropdown>

          <div role="button" onClick={remindersActions.remindersModalShow} className="item">
            <img alt="" className="icon" src={MessagesImage} />
            <div className="text">Reminders</div>
          </div>

          {patientAppActivated && (
            <div
              onClick={() => setShowSendPush(true)}
              className="item"
              style={{ paddingTop: "2px" }}
            >
              <FilledImage zoom={40} color="#ffffff" src={SendPushImage} />
              <div className="text">Send push</div>
            </div>
          )}

          <Link to={Routes.DashboardBlockedTimeSlot} className="item">
            <svg className="icon" viewBox="0 0 24 24">
              <path
                fill="#ffffff"
                d="M0,2.77L1.28,1.5L22.5,22.72L21.23,24L19.23,22H4C2.92,22 2,21.1 2,20V4.77L0,2.77M10,4V7.68L8,5.68V4H6.32L4.32,2H20A2,2 0 0,1 22,4V19.7L20,17.7V16H18.32L16.32,14H20V10H16V13.68L14,11.68V10H12.32L10.32,8H14V4H10M16,4V8H20V4H16M16,20H17.23L16,18.77V20M4,8H5.23L4,6.77V8M10,14H11.23L10,12.77V14M14,20V16.77L13.23,16H10V20H14M8,20V16H4V20H8M8,14V10.77L7.23,10H4V14H8Z"
              />
            </svg>
            <div className="text">Blocked time slot</div>
          </Link>

          {showSendPush && (
            <PushNotificationModal show={showSendPush} onClose={() => setShowSendPush(false)} />
          )}
        </div>
        <div className="d-flex">
          <DropDownBS>
            <DropDownBS.Toggle
              as={CustomToggle}
              permissions={permissions}
              showMessage={showMessage}
              id="print-drop-down"
            >
              <img src={PrintImage} alt="" width={20} height={20} />
              <div className="text">Print</div>
            </DropDownBS.Toggle>
            <DropDownBS.Menu>
              <li>
                <Link
                  to={createUrl(Routes.DashboardCalendarPrintDetails, {
                    params: { type: "Full" },
                  })}
                >
                  <div className="text">All details</div>
                </Link>
              </li>
              <li>
                <Link
                  to={createUrl(Routes.DashboardCalendarPrintDetails, {
                    params: { type: "WithoutContact" },
                  })}
                >
                  <div className="text">With out contact details</div>
                </Link>
              </li>
              <li>
                <span role="button" onClick={downloadAsImage}>
                  <div className="text">as image</div>
                </span>
              </li>
            </DropDownBS.Menu>
          </DropDownBS>

          <div
            role="button"
            className="item"
            onClick={() => history.push(Routes.DashboardLateAppointments)}
          >
            <img alt="" width={18} src={ApptsTodayImage} />
            <div className="text">Appts today</div>
          </div>

          <div role="button" onClick={calendarActions.googleSync} className="item">
            <img alt="" className="icon" src={RefreshImage} />
            <div className="text">Google sync</div>
          </div>
          <div role="button" onClick={reload} className="item">
            <img alt="" className="icon" src={RefreshImage} />
            <div className="text">Reload</div>
          </div>
        </div>
      </div>

      {remindersModalVisible && <Reminders />}
    </div>
  );
});
