import _ from "lodash";
import React from "react";
import fp from "lodash/fp";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import TopCalculationBox from "../chart/side-bar/TopCalculationBox";
import DiagnosisBox from "../chart/side-bar/DiagnosisBox";

import * as seriesActions from "../../actions/chartSideSeriesActions";
import bindActions from "../../helpers/bindActions";
import { SidebarTypes } from "./TypeButtonGroup";
import treatmentAreas from "../../constants/chart-sidebar/treatmentAreas";
import SurfacesSlider from "../chart/side-bar/SurfacesSlider";
import TeethSlider from "../chart/side-bar/TeethSlider";
import SelectMouthSides from "../widgets/svg-shapes/SelectMouthSides";
import SelectQuadrantSides from "../widgets/svg-shapes/SelectQuadrantSides";
import SelectSextantSides from "../widgets/svg-shapes/SelectSextantSides";
import { addRequestedTreament } from "../../actions/appointmentActions";
import {
  getAppointmentTransactions,
  sendToPreApproval,
  setHoldPreApproval,
  getDiagnosisTypeList,
  getAppointmentProvisionalDiagnosis,
} from "../../actions/transactionActions";
import { setPopupActionByKey, setPopupParams } from "../../actions/popupActions";
import Element from "../../models/Element";
import { PopupType } from "../../constants/Constants";
import { SidebarTeeth } from "./SidebarTeeth";
import { SingleSurfacesSlider } from "../chart/side-bar/SingleSurfaceSlider";
import Show from "../widgets/Show";
import DiagnosisClaimStatusBox from "../chart/side-bar/DiagnosisClaimStatusBox";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";

const enhancer = connect(
  ({ chart, patient, appointment, session, chartDermatology, chartSideMedicalFillInForm }) => ({
    chart,
    patient,
    session,
    appointment,
    chartDermatology,
    chartSideMedicalFillInForm,
  }),
  bindActions({
    seriesActions,
    addRequestedTreament,
    getAppointmentTransactions,
    sendToPreApproval,
    setHoldPreApproval,
    setPopupParams,
    setPopupActionByKey,
    getDiagnosisTypeList,
    getAppointmentProvisionalDiagnosis,
  }),
);

export const SidebarItem = enhancer(
  class SidebarItemView extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showModal: false,
      };
    }

    componentDidMount() {
      const { item, initItem, getDiagnosisTypeList } = this.props;
      getDiagnosisTypeList();
      if (item) {
        initItem(item.id);
      }
    }

    componentWillUnmount() {
      const { seriesActions } = this.props;

      seriesActions.clearTransactioItem();
    }

    appendItem = () => {
      const { onRequestClose, addRequestedTreament, tabType, item, seriesActions } = this.props;

      if (tabType === SidebarTypes.Grouped) {
        addRequestedTreament(item).then(() => {
          onRequestClose();

          seriesActions.clearAddedDiagnosisItems();
        });
      } else {
        addRequestedTreament().then(() => {
          onRequestClose();

          seriesActions.clearAddedDiagnosisItems();
        });
      }
    };

    addItem = () => {
      const {
        typeTab,
        chartSideSeries,
        getAppointmentTransactions,
        seriesActions,
        patient,
        isMixed,
        popStack,
        isExisting,
        chartSideMedicalFillInForm: { stack },
        getAppointmentProvisionalDiagnosis,
      } = this.props;
      const isProvisionalDiagnosis =
        _.last(stack)?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

      seriesActions.createTransactionList(typeTab, isMixed).then(() => {
        seriesActions.clearAddedDiagnosisItems();

        if (!isMixed && !isExisting) {
          const isEdit = chartSideSeries.transactionItem;

          if (
            chartSideSeries.transactionStatus &&
            chartSideSeries.transactionStatus.list &&
            chartSideSeries.transactionStatus.list.length
          ) {
            if (!isEdit) {
              if (
                _.size(chartSideSeries.transactionStatus.dentalTransactionKeys) > 0 &&
                patient.paymentOptions.insuranceCompanyCount > 0 &&
                patient.paymentOptions.needPreApproval &&
                (chartSideSeries.addTransaction.sendBillInsurance ||
                  chartSideSeries.addTransaction.sendBillInsurance2)
              ) {
                this.showPriorApprovalPopup();
              } else {
                seriesActions.showDocumentsAssignedModal("single_treatment");
              }
            }

            isProvisionalDiagnosis
              ? getAppointmentProvisionalDiagnosis(true)
              : getAppointmentTransactions(true);

            popStack();
          }
        } else {
          if (!isMixed && !isExisting) {
            getAppointmentTransactions(true);
          }

          popStack();
        }
      });
    };

    isSupernumeraryTooth = () => {
      const {
        chart: { teeth = [], activeTooth },
        session: { toothNumericType },
      } = this.props;

      const numericType = toothNumericType.code === "Index1" ? "numeric1" : "numeric2";

      const toothList = _.filter(teeth, (x) => Boolean(x.supernumerary));

      for (let i in toothList) {
        const tooth = toothList[i];
        const toothNumber = _.get(tooth, `supernumerary.details.${numericType}`);

        if (_.toFinite(toothNumber) === activeTooth) {
          return tooth;
        }
      }

      return false;
    };

    showPriorApprovalPopup = () => {
      const {
        typeTab,
        chartSideSeries,
        setPopupParams,
        setHoldPreApproval,
        setPopupActionByKey,
        sendToPreApproval,
        seriesActions,
      } = this.props;

      if (typeTab === SidebarTypes.Treatments) {
        let laterBtn = new Element("Send for approval", 1);
        let nowBtn = new Element("Now", 2);
        let holdBtn = new Element("Hold Service", 3, "", "", "", "gray");
        let createBtn = new Element("Create another approval", 4, "", "", "", "gray");
        const handler = (e, element) => {
          switch (element.id) {
            case 1:
              sendToPreApproval();
              seriesActions.showDocumentsAssignedModal("single_treatment");
              break;
            case 3:
              setHoldPreApproval(chartSideSeries.transactionStatus.dentalTransactionKeys);
              seriesActions.showDocumentsAssignedModal("single_treatment");
              break;
            case 4:
              sendToPreApproval("New");
              seriesActions.showDocumentsAssignedModal("single_treatment");
              break;
          }
          setPopupActionByKey(PopupType.Small, "visible", false);
        };
        laterBtn.handler = handler;
        nowBtn.handler = handler;
        holdBtn.handler = handler;
        createBtn.handler = handler;
        setPopupParams(
          PopupType.Small,
          "",
          true,
          <span style={{ fontSize: "14px" }}>Send this treatment to prior-approval?</span>,
          [laterBtn, createBtn, holdBtn],
          true,
        );
      } else {
        seriesActions.showDocumentsAssignedModal("single_treatment");
      }
    };

    render() {
      const {
        chart,
        member,
        patient,
        session,
        typeTab,
        isMixed,
        pushStack,
        inAppointment,
        seriesActions,
        chartSideSeries,
        toothNumericType,
        changeActiveTooth,
      } = this.props;

      const { showModal } = this.state;

      const { item, fetchingItem, sortedSurfaceTransactionList } = chartSideSeries;

      const showFee = session.permissions.indexOf("VIEW_DENTAL_TRANSACTION_ITEM_FEE") >= 0;

      const teeth = chart.teeth;
      const addTransaction = chartSideSeries.addTransaction;
      const transactionForm = chartSideSeries.transactionForm;
      const isEdit = !!chartSideSeries.transactionItem;
      const numericKey =
        !toothNumericType || toothNumericType.code === "Index1" ? "numeric1" : "numeric2";

      let $shapeObject;

      const supernumeraryTooth = this.isSupernumeraryTooth();

      if (item.item && item.item.area && transactionForm) {
        if (!isEdit || (isEdit && (addTransaction.key || transactionForm.key))) {
          switch (item.item.area.code) {
            case treatmentAreas.Surface.code:
              $shapeObject = supernumeraryTooth ? (
                <SingleSurfacesSlider
                  teeth={teeth}
                  isEdit={isEdit}
                  typeTab={typeTab}
                  numericKey={numericKey}
                  tooth={supernumeraryTooth}
                  seriesActions={seriesActions}
                  activeTooth={chart.activeTooth}
                  addTransaction={addTransaction}
                  changeActiveTooth={changeActiveTooth}
                  title={`Select tooth number then surface to add ${typeTab}`}
                />
              ) : (
                <SurfacesSlider
                  teeth={teeth}
                  isEdit={isEdit}
                  item={item.item}
                  typeTab={typeTab}
                  numericKey={numericKey}
                  seriesActions={seriesActions}
                  activeTooth={chart.activeTooth}
                  addTransaction={addTransaction}
                  changeActiveTooth={changeActiveTooth}
                  sortedSurfaceTransactionList={sortedSurfaceTransactionList}
                  title={`Select tooth number then surface(s) to add ${typeTab}`}
                />
              );
              break;
            case treatmentAreas.Tooth.code:
              $shapeObject = (
                <SidebarTeeth
                  teeth={teeth}
                  numericKey={numericKey}
                  addTransaction={addTransaction}
                  onClick={(x, y, z) => !isEdit && seriesActions.selectTooth(x, y, z)}
                  title={fp.capitalize(typeTab) + " is being added to tooth"}
                />
              );
              break;
            case treatmentAreas.Root.code:
              $shapeObject = (
                <TeethSlider
                  teeth={teeth}
                  numericKey={numericKey}
                  addTransaction={addTransaction}
                  onClick={(x, y, z) => !isEdit && seriesActions.selectTooth(x, y, z)}
                />
              );
              break;
            case treatmentAreas.Mouth.code:
              $shapeObject = (
                <SelectMouthSides
                  teeth={teeth}
                  isEdit={isEdit}
                  typeTab={typeTab}
                  addTransaction={addTransaction}
                  seriesActions={seriesActions}
                />
              );
              break;
            case treatmentAreas.Quadrant.code:
              $shapeObject = (
                <SelectQuadrantSides
                  teeth={teeth}
                  isEdit={isEdit}
                  typeTab={typeTab}
                  addTransaction={addTransaction}
                  seriesActions={seriesActions}
                />
              );
              break;
            case treatmentAreas.Sextant.code:
              $shapeObject = (
                <SelectSextantSides
                  teeth={teeth}
                  isEdit={isEdit}
                  typeTab={typeTab}
                  addTransaction={addTransaction}
                  seriesActions={seriesActions}
                />
              );
              break;
          }
        }
      }

      if (fetchingItem || _.isEmpty(item) || _.isEmpty(item.item)) {
        return <ReactSpinner config={{ scale: "0.5" }} />;
      }

      const isDisabledAddButton =
        _.get(item, "item.area.code") === treatmentAreas.Surface.code &&
        _.reduce(
          sortedSurfaceTransactionList,
          (acc, item) => acc || (item.editable && _.isEmpty(item.selectedArea)),
          false,
        );
      return (
        <div className="item-details d-flex flex-grow-1 flex-shrink-1 flex-column">
          <TopCalculationBox
            item={item}
            isMixed={isMixed}
            showFee={showFee}
            typeTab={typeTab}
            seriesType={typeTab}
            currentMember={member}
            seriesActions={seriesActions}
            activeTooth={chart.activeTooth}
            paymentOptions={patient.paymentOptions}
            addTransaction={chartSideSeries.addTransaction}
            transactionForm={chartSideSeries.transactionForm}
          />

          <div className="shape-wrap flex-shrink-0">{$shapeObject}</div>

          {Boolean(
            (typeTab === SidebarTypes.Treatments || typeTab === SidebarTypes.Existing) && !isMixed,
          ) && <DiagnosisBox pushStack={pushStack} />}
          <div>
            <Show if={typeTab === SidebarTypes.Diagnosis}>
              <DiagnosisClaimStatusBox />
            </Show>
            <button
              type="button"
              className="btn btn-lg btn-success btn-block flex-shrink-0"
              style={{ marginTop: item?.item.area.code !== "MOUTH" && 70 }}
              onClick={() => {
                if (isDisabledAddButton) {
                  this.setState({ showModal: true });
                } else if (inAppointment) {
                  this.appendItem();
                } else {
                  this.addItem();
                }
              }}
            >
              {isEdit ? "UPDATE" : "ADD"}
            </button>
          </div>
          <Modal
            size="sm"
            show={showModal}
            onHide={() => this.setState({ showModal: false })}
            actions={
              <div>
                <Button
                  type="button"
                  className="btn-primary"
                  onClick={() => this.setState({ showModal: false })}
                >
                  OK
                </Button>
              </div>
            }
            keyboard={false}
          >
            <div>Please select surface</div>
          </Modal>
        </div>
      );
    }
  },
);
