import React from "react";
import { getAdditionalDays } from "../../utils/DateUtils";

const AppointmentAdditionalDates = ({ children, selectDate, onClose }) => {
  const daysList = getAdditionalDays();

  return (
    <div className="additional__date__container react-datepicker">
      <div className="additional__date__container__days">
        <div className="day__title">Days</div>
        {daysList.map((day, idx) => (
          <div
            className="additional__day"
            key={idx}
            onClick={() => {
              selectDate(day.value);
              onClose();
            }}
          >
            {day.title}
          </div>
        ))}
      </div>
      <div className="additional__date__container_date_picker">{children}</div>
    </div>
  );
};

export default AppointmentAdditionalDates;
