import React from "react";

import Back from "../widgets/Back";

import { SidebarViewTypes } from "../../constants/Constants";

export default class TreatmentSideBarView extends React.Component {
  setView = (type) => {
    localStorage.setItem("treatmentSideBarView", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment calendar view</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView(SidebarViewTypes.list)} className="list-group-item">
              {Boolean(localStorage.getItem("treatmentSideBarView") === SidebarViewTypes.list) && (
                <div className="pull-right">&#10004;</div>
              )}
              List View
            </span>
            <span onClick={() => this.setView(SidebarViewTypes.grid)} className="list-group-item">
              {Boolean(localStorage.getItem("treatmentSideBarView") === SidebarViewTypes.grid) && (
                <div className="pull-right">&#10004;</div>
              )}
              Grid View
            </span>
          </div>
        </div>
      </div>
    );
  }
}
