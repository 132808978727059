import Utils from "../helpers/Utils";

/* eslint-disable */
export default class SelectItem {
  constructor(name, id, code) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.description = null;
    this.selected = false;
    this.items = [];
    this.jsonObject = {
      id: 0,
      name: "",
    };
    this.that = this;
  }

  readFromJSON(json) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.code = json.code;
    this.items = json.items;
    this.jsonObject = json;
  }

  getShortRequest() {
    const { id, code } = this;
    const json = {};
    if (id) json.id = id;

    if (code) json.code = code;

    return json;
  }

  getRequest() {
    const jsonRequest = {};
    Utils.objectForEach(this.jsonObject, (value, key) => {
      jsonRequest[key] = this[key];
    });
    return jsonRequest;
  }
}
