import { httpClient } from "./BaseApi";

const CLINICAL_NOTE_LIST_URL = "api/clinical/note/list.json";
const CLINICAL_NOTE_ITEM_URL = "api/clinical/note/item.json";
const CLINICAL_NOTE_ITEM_HIE_URL = "api/clinical/note/item/send-to-hie.json";
const CLINICAL_NOTE_ITEM_URL_RESTORE = "/api/clinical/note/restore/item.json";
const CLINICAL_NOTE_EMPTY_URL = "api/clinical/note/empty.json";
const CLINICAL_NOTE_QUESTIONS = "api/clinical/note/question/list.json";

const CLINICAL_NOTE_TEMPLATE_CHOOSE_URL = "api/clinical/note/template/choose.json";
const CLINICAL_NOTE_TEMPLATE_CATEGORY_URL = "api/clinical/note/template/category/choose.json";
const CLINICAL_NOTE_TEMPLATE_COMPLETE_TRANSACTIONS_URL =
  "api/clinical/note/template/complete-transactions.json";

const CLINICAL_NOTE_ITEM_CHANGE_DATE_URL = "api/clinical/note/item/change-date.json";

const ClinicalNoteApi = {
  getItem: (params) => httpClient.get(CLINICAL_NOTE_ITEM_URL, { params }),
  getQuestionsList: (params) => httpClient.get(CLINICAL_NOTE_QUESTIONS, { params }),
  getEmpty: (params) => httpClient.get(CLINICAL_NOTE_EMPTY_URL, { params }),
  getOwnedList: (params) => httpClient.get(CLINICAL_NOTE_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(CLINICAL_NOTE_ITEM_URL, { params }),
  restoreItem: (params) => httpClient.post(CLINICAL_NOTE_ITEM_URL_RESTORE, { params }),
  changeCreatedDate: (data) => httpClient.put(CLINICAL_NOTE_ITEM_CHANGE_DATE_URL, { data }),
  completeTransaction: (params) =>
    httpClient.get(CLINICAL_NOTE_TEMPLATE_COMPLETE_TRANSACTIONS_URL, {
      params,
    }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(CLINICAL_NOTE_ITEM_URL, { data })
      : httpClient.post(CLINICAL_NOTE_ITEM_URL, { data }),
  saveItemHIE: (params) => httpClient.get(CLINICAL_NOTE_ITEM_HIE_URL, { params }),
  template: {
    getList: (params) => httpClient.get(CLINICAL_NOTE_TEMPLATE_CHOOSE_URL, { params }),
    getCategory: (params) => httpClient.get(CLINICAL_NOTE_TEMPLATE_CATEGORY_URL, { params }),
  },
};

export default ClinicalNoteApi;
