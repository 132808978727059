import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import Show from "../widgets/Show";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { defaultColors } from "../../constants/defaults";
import { TIME_FORMAT, TimeViews, ViewTypes } from "../../constants/Constants";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { withRouter } from "react-router";
import ComfortBackgroundImage from "../../assets/images/calendar-greed_large.png";
import CompactBackgroundImage from "../../assets/images/calendar-greed_slim.png";

const enhancer = connect(({ session: { company }, calendar: { activeDoctorId } }) => ({
  company,
  activeDoctorId,
}));

export default withRouter(
  enhancer(
    class CalendarSingleDoctorColumn extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          isHover: {},
        };
      }

      render() {
        const {
          cells,
          width,
          company,
          masterTypes,
          startTime: clinicStartTime,
          onNoteClick,
        } = this.props;

        const currentDate = Utils.getStartOfDate(moment().startOf("day"));
        const cellList = JSON.parse(localStorage.getItem("callListNoResponse"));
        const todayCellList = cellList[currentDate];

        const columnView = localStorage.getItem("columnView");
        const rowView = localStorage.getItem("rowView");
        const timeView = localStorage.getItem("timeView");

        let columnHeight = 40;

        if (rowView === ViewTypes.compact) columnHeight = 20;

        let types = [];

        _.forEach(masterTypes, (item) => {
          if (item.active) {
            types.push(item.id);
          }
        });

        const $cells = cells.map((point, idx, arr) => {
          let utcOffset;

          if (company) {
            utcOffset = _.toFinite(_.get(company, "timezone.description", Date.now())) / 60 / 1000;
          } else {
            utcOffset = moment().utcOffset();
          }

          const startDate = Utils.calcDateWithTimeZone(point.startTime, utcOffset);
          const hours = startDate.hour();
          const minutes = startDate.minute();

          const duration = point.duration / 1000 / 60;

          const startTime = hours * 60 + minutes;
          const endTime = startTime + duration;

          const height = (duration / 15) * columnHeight;
          const top = ((startTime - clinicStartTime) / 15) * columnHeight;

          const time = Utils.intToDayPartStr(startTime) + " - " + Utils.intToDayPartStr(endTime);

          const name = Utils.getFullName(point.patient);
          const cell_class =
            height < columnHeight
              ? "small-cell"
              : height > columnHeight
              ? "large-cell"
              : "normal-cell";

          const checkedTimeClass =
            (endTime - startTime < 30 && "checked-in-time hidden") || "checked-in-time";
          const checkedTitleClass =
            (endTime - startTime < 30 && "checked-in-title hidden") || "checked-in-title";
          const checkedTime =
            (point.checkedInDate && moment(point.checkedInDate).format(TIME_FORMAT)) || "- - : - -";

          const pointStatusColor = point.status && Colors.getStatusColor(point.status.code);
          const pointTypeColor = Colors.getAppointmentColor(point);

          const master = point.masterType && point.masterType.id;
          let enabled = types.length > 0 ? _.includes(types, master) : true;

          let showNote =
            (duration >= 30 && rowView === ViewTypes.comfort) ||
            (duration >= 60 && rowView === ViewTypes.compact);

          let showFullNote = localStorage.getItem("showFullNote") === "true";

          if (enabled) {
            return (
              <div
                key={idx}
                role="button"
                onClick={() => {
                  const $calendar_Body = this.props.getCalendarBody();

                  this.props.history.push(
                    createUrl(Routes.DashboardAppointment, {
                      params: {
                        id: point.id,
                      },
                      query: {
                        scrollLeft: $calendar_Body.scrollLeft,
                        scrollTop: $calendar_Body.scrollTop,
                      },
                    }),
                  );
                }}
                id="test"
                className={`cell ${cell_class}`}
                onMouseEnter={() =>
                  arr.length > 1
                    ? this.setState({ isHover: { [`${idx}-${idx}`]: true } })
                    : _.noop()
                }
                onMouseLeave={() =>
                  arr.length > 1
                    ? this.setState({ isHover: { [`${idx}-${idx}`]: false } })
                    : _.noop()
                }
                style={
                  arr.length === 1 || this.state.isHover[`${idx}-${idx}`]
                    ? {
                        top: top,
                        zIndex: 100,
                        height: height,
                        width: "400px",
                        left: 0,
                      }
                    : {
                        width: `${arr.length > 0 ? 400 / arr.length : 400}px`,
                        left: (arr.length > 0 ? 400 / arr.length : 400) * idx,
                        top: top,
                        height: height,
                      }
                }
              >
                <div
                  className="left"
                  style={{
                    backgroundColor:
                      arr.length === 1 || this.state.isHover[`${idx}-${idx}`]
                        ? (pointTypeColor && pointTypeColor.color.color) || defaultColors.Blue.color
                        : (pointStatusColor && pointStatusColor.color.color) ||
                          defaultColors.Green.color,
                  }}
                >
                  <div className="top">
                    {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                      <div className="name" title={name}>
                        {name}
                      </div>
                    )}

                    {(arr.length === 1 || this.state.isHover[`${idx}-${idx}`]) && (
                      <div
                        data-tip={true}
                        data-for={"tooltip" + point.id}
                        data-event="click"
                        data-event-off="mouseleave"
                        className={`number ${
                          rowView === ViewTypes.compact || columnView === ViewTypes.compact
                            ? ""
                            : "comfort"
                        }`}
                        style={{
                          backgroundColor:
                            (pointTypeColor && pointTypeColor.color.color) ||
                            defaultColors.Blue.color,
                          textDecoration: "underline",
                        }}
                      >
                        {point.patient?.chartNumber ? (
                          <span>#{point.patient?.chartNumber}</span>
                        ) : (
                          "Temp"
                        )}
                      </div>
                    )}
                  </div>

                  {showNote && showFullNote && <div className="center">{point.note}</div>}

                  <div className="bottom">
                    <div>
                      {((rowView === ViewTypes.compact && duration > 30) ||
                        (rowView === ViewTypes.comfort && duration > 15)) &&
                        point.dentalPoint &&
                        point.dentalPoint.code === "PUBLIC" &&
                        point.dentist &&
                        point.dentist.name}
                    </div>
                    <div>
                      {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                        point.category &&
                        point.category.name}
                    </div>
                    <div>
                      {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                        point.dentalPoint &&
                        point.dentalPoint.name}
                    </div>
                  </div>

                  <Show
                    if={
                      point.note &&
                      Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) &&
                      (!showNote || !showFullNote)
                    }
                  >
                    <span
                      className="note-modal-link"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onNoteClick(point.note);
                      }}
                    >
                      Note
                    </span>
                  </Show>
                </div>

                {(arr.length === 1 || this.state.isHover[`${idx}-${idx}`]) && (
                  <div
                    className="right"
                    style={{
                      width:
                        arr.length > 2 && !this.state.isHover[`${idx}-${idx}`] ? "100%" : "120px",
                      backgroundColor:
                        (pointStatusColor && pointStatusColor.color.color) ||
                        defaultColors.Green.color,
                    }}
                  >
                    {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                      <div className={checkedTimeClass}>{checkedTime}</div>
                    )}
                    {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                      <div className={checkedTitleClass}>[Checkedin]</div>
                    )}
                    {Boolean(rowView === ViewTypes.comfort || duration > 15) && (
                      <div
                        className={
                          "top" +
                          ((rowView !== ViewTypes.comfort || columnView !== ViewTypes.comfort) &&
                          timeView === TimeViews.name
                            ? " name"
                            : "")
                        }
                      >
                        {(rowView !== ViewTypes.comfort || columnView !== ViewTypes.comfort) &&
                        timeView === TimeViews.name
                          ? name
                          : time}
                      </div>
                    )}
                    <div className="bottom">
                      {todayCellList && todayCellList[point.id]
                        ? "No Answer"
                        : point.status && point.status.name}
                    </div>
                  </div>
                )}

                <ReactTooltip id={"tooltip" + point.id} type="light">
                  chart number: #{point.patient?.chartNumber}
                  <br />
                  name: {name}
                  <br />
                  type: {point.category && point.category.name}
                  <br />
                  {localStorage.getItem("doctorLabel")}: {point.dentist && point.dentist.name}
                  <br />
                  checked time: {checkedTime}
                  <br />
                  note: {point.note}
                </ReactTooltip>
              </div>
            );
          }
        });

        return (
          <div
            className={`calendar-column ${columnView}`}
            style={{
              width: width,
              backgroundImage: `url(${
                columnView === "comfort" ? ComfortBackgroundImage : CompactBackgroundImage
              })`,
            }}
          >
            {$cells}
          </div>
        );
      }
    },
  ),
);
