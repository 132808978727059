import React, { useEffect, useState } from "react";
import cx from "classnames";

import Back from "../widgets/Back";
import { Switch } from "../ui/Switch";
import PageHeader from "../layout/DefaultPageHeader";
import LogoImage from "../../assets/images/clinic_logo.png";
import SaveImage from "../../assets/images/appointment/save_2x.png";
import { ClinicSelectWrapper } from "../clinic/ClinicSelectWrapper";
import DeleteImage from "../../assets/images/appointment/delete_2x.png";
import { Checkbox } from "../ui/Checkbox";

export default function AppointmentHeader({
  elements,
  onReset,
  onBack,
  sendSmsState,
  earlierState,
  onChangeEarlierState,
  onChangeSendSmsState,
  onFilterClinicChanged,
  showFilterClinic,
  initialClinicId,
  notSendSmsForAppointmentsEnabled,
}) {
  const [sendSms, setSendSms] = useState(sendSmsState);
  useEffect(() => {
    if (!notSendSmsForAppointmentsEnabled) {
      setSendSms(true);
      onChangeSendSmsState(true);
    }
  }, []);
  return (
    <PageHeader addClass="appointment-header d-flex justify-content-between" hideBack={true}>
      <Back onClick={onBack} className="pull-left appointment-header-back" />

      <div className="logo-container d-flex align-items-center justify-content-center">
        <a href="#" className="logo logo-link">
          <img src={LogoImage} alt={""} />
        </a>
      </div>

      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-center mr-2 earlier-container">
          <Checkbox
            checked={!sendSmsState}
            disabled={!notSendSmsForAppointmentsEnabled}
            onChange={() => {
              onChangeSendSmsState(!sendSms);
              setSendSms((x) => !x);
            }}
            label="Don't Send Reminders (Sms, Whatsapp)"
          />
        </div>

        <div className="d-flex align-items-center justify-content-center mr-2 earlier-container">
          <label className="mb-0">
            <span className="label mr-2">Earlier</span>
            <Switch checked={earlierState} onChange={onChangeEarlierState} />
          </label>
        </div>

        <div style={{ width: "150px" }} className={cx({ "d-none": !showFilterClinic })}>
          <ClinicSelectWrapper
            value={{ value: initialClinicId }}
            onChange={(x) => onFilterClinicChanged(x.value)}
          />
        </div>

        {elements.resetBtn.visible ? (
          <button type="button" className="icon-btn icon-delete" onClick={onReset}>
            <img src={DeleteImage} width={32} height={32} alt="" />
          </button>
        ) : null}

        {elements.saveBtn.visible ? (
          <button type="submit" className="icon-btn icon-save">
            <img src={SaveImage} width={32} height={32} alt="" />
          </button>
        ) : null}
      </div>
    </PageHeader>
  );
}
