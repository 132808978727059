import { isArray } from "lodash";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { getCalendarDoctors, getDoctorPoints } from "../../actions/calendarActions";
import { calendarClinicIdSelector } from "../../reducers/calendarReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { SelectPicker, SelectPickerProps } from "../select-picker/SelectPicker";

export interface DoctorPointSelectWrapperProps extends Omit<SelectPickerProps, "options"> {
  readonly allLabel?: string;
  readonly customClinicId?: number;
  readonly loggedInDoctorPointsOnly?: number;
}

export function DoctorPointSelectWrapper({
  allLabel,
  customClinicId,
  loggedInDoctorPointsOnly,
  ...props
}: DoctorPointSelectWrapperProps) {
  const dispatch = useDispatch();

  // @ts-ignore
  const clinicId = useShallowEqualSelector(calendarClinicIdSelector);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDoctorsHandler = useCallback(
    (id: number) => {
      setLoading(true);

      dispatch(getCalendarDoctors());

      dispatch(getDoctorPoints({ clinicId: id, publicPoint: true }))
        .then((x: any = []) => {
          const newList = allLabel ? [{ label: allLabel }] : [];
          if (loggedInDoctorPointsOnly) {
            x = x.filter((item) => loggedInDoctorPointsOnly === item.id);
          }
          const points = x
            .reduce((acc, item) => {
              if (!item.dentalPoints) {
                return acc;
              }

              if (item.dentalPoints.length > 1) {
                const nested = item.dentalPoints.reduce(
                  (nestedAcc, nestedItem) => [
                    ...nestedAcc,

                    {
                      doctor: item,
                      point: nestedItem,
                      value: `${item.id},${nestedItem.id}`,
                      label: `${item.name} (${nestedItem.name})`,
                    },
                  ],

                  [],
                );

                return [...acc, ...nested];
              }

              return [
                ...acc,
                {
                  doctor: item,
                  point: item.dentalPoints[0],
                  value: `${item.id},${item.dentalPoints[0].id}`,
                  label: `${item.name} (${item.dentalPoints[0].name})`,
                },
              ];
            }, [])

            .sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }

              if (a.label > b.label) {
                return 1;
              }

              return 0;
            });

          setList(newList.concat(points) as any);
        })
        .finally(() => setLoading(false));
    },
    [allLabel, dispatch],
  );

  useEffect(() => {
    getDoctorsHandler(customClinicId || clinicId);
  }, [clinicId, customClinicId, getDoctorsHandler]);

  return (
    <SelectPicker
      {...props}
      options={list}
      isLoading={loading}
      formatMultiValue={(x) => {
        if (isArray(x)) {
          return `Doctors (${x.length})`;
        }

        return x.label;
      }}
    />
  );
}
