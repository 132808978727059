export const seriesStackCodes = {
  favourite: "favourite",
  category: "category",
  categoryChild: "categoryChild",
  list: "list",
  item: "item",
  diagnosis: "diagnosis",
  transaction: "transaction",
  breakToVisit: "breakToVisit",
};

export const defaultStackItem = {
  code: seriesStackCodes.favourite,
};
