import React from "react";
import { connect } from "react-redux";

import BackButton from "../../../widgets/BackButton";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePostOpActions from "../../../../actions/chartSidePostOpActions";
import ChartPostOpForm from "./ChartPostOpForm";

const enhancer = connect(null, bindActions({ chartSidePostOpActions }));

class ChartPostOpItemEdit extends React.Component {
  componentDidMount() {
    this.props.chartSidePostOpActions.getTemplate();
  }

  render() {
    const {
      chartSidePostOp: { item },
    } = this.props;

    return (
      <div className="chart-clinical-note">
        <div className="clinical-note-item">
          <div className="clinical-note-item-view-title">
            <BackButton
              className="btn-sm"
              text="Back"
              onClick={this.props.chartSidePostOpActions.popStack}
            />
            <div className="title">create post op instructions</div>
            <div className="chart-number">#{item.patient && item.patient.chartNumber}</div>
          </div>
          <div className="clinical-note-item-edit-body">
            {item.content && ( //for initialize CKEditor
              <ChartPostOpForm
                onSubmit={this.props.chartSidePostOpActions.saveItem}
                initialValues={{
                  title: item.template && item.template.name,
                  content: item.content,
                }}
                enableReinitialize={true}
                keepDirtyOnReinitialize={true}
                email={this.props.chartSidePostOpActions.emailToPatient}
                print={this.props.chartSidePostOpActions.print}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOpItemEdit);
