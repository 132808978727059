import React, { useEffect, useState } from "react";
import { SelectPicker } from "../select-picker/SelectPicker";
import { toFinite } from "lodash";
import { Modal } from "../ui/Modal";
import { connect, useDispatch } from "react-redux";
import {
  getDoctorsLocations,
  getFreePoints,
  setAppointmentAdditionalDoctors,
} from "../../actions/appointmentActions";
import moment from "moment/moment";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";
import { DatePicker } from "../ui/DatePicker";
import Utils from "../../helpers/Utils";
import Show from "../widgets/Show";

const enhancer = connect(({ appointment: { additionalDoctors } }) => ({
  additionalDoctors,
}));

const AppointmentAdditionalDoctorsModal = (props) => {
  const {
    doctorsModalVisible,
    setDoctorsModalVisible,
    elements,
    additionalDoctors,
    appointment,
    isEdit,
    moved,
  } = props;
  const initialValues = {
    date: { value: elements.date.value || moment() },
    time: { value: null, options: [] },
    doctor: { value: null },
    point: { value: null },
  };
  const [dentalPoints, setDentalPoints] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppointmentAdditionalDoctors([initialValues]));
    dispatch(getDoctorsLocations()).then((data) => setDentalPoints(data));
  }, []);

  useEffect(() => {
    if (isEdit && (appointment?.linkedItems || []).length) {
      const appointmentAdditionalDoctors = appointment.linkedItems.map((item) => {
        return {
          date: { value: moment(item.date) },
          time: { value: item.startTime, options: [] },
          doctor: { value: item.dentist.id },
          point: { value: item.dentalPoint.id },
        };
      });
      appointment.linkedItems.forEach((item, index) => {
        getFreeTimes(item.dentist.id, item.dentalPoint.id, item.date, index, item.startTime);
      });
      dispatch(setAppointmentAdditionalDoctors(appointmentAdditionalDoctors));
    }
  }, [appointment]);

  const getFreeTimes = (dentistId, dentalPointId, date, index, appointmentCurrentTime) => {
    if (date && elements.lengthSB.selectedId) {
      const periodStart = getCompanyStartOfDay(date, false, true);
      const periodEnd = getCompanyEndOfDay(periodStart);

      const transfer = {
        periodEnd,
        periodStart,
        machineId: elements.machinesSB.selectedId !== -100 ? elements.machinesSB.selectedId : -1,
        appointmentId: appointment?.id,
        duration: elements.lengthSB.selectedId,
        dentalPointId: dentalPointId,
        dentistId: dentistId,
      };
      dispatch(getFreePoints(transfer)).then((data) => {
        if (appointmentCurrentTime) {
          const endTime = appointmentCurrentTime + elements.lengthSB.selectedId;
          data.push({ startTime: appointmentCurrentTime, endTime });
        }
        let currentTime;
        if (
          elements.freeTimesSB.selectedId &&
          elements.freeTimesSB.selectedId !== appointmentCurrentTime
        ) {
          currentTime = {
            startTime: elements.freeTimesSB.selectedId,
            endTime: elements.freeTimesSB.selectedId + elements.lengthSB.selectedId,
          };
        }
        const timeOptions = data
          .sort((a, b) => a.startTime - b.startTime)
          .map((item) => {
            const start = Utils.calcDateWithTimeZone(item.startTime);
            const end = Utils.calcDateWithTimeZone(item.endTime);

            return {
              value: start.valueOf(),
              label: `${Utils.parseDateFormat(start)} - ${Utils.parseDateFormat(end)}${
                item.reserved ? ` ${item.reservedName}` : ""
              }`,
            };
          });
        additionalDoctors[index].time.value =
          appointmentCurrentTime || elements.freeTimesSB.selectedId;
        additionalDoctors[index].time.options = timeOptions;
        dispatch(setAppointmentAdditionalDoctors(additionalDoctors));
      });
    }
  };

  const validateFields = () => {
    let invalidForm = false;
    const validatedForm = additionalDoctors.map((item) => {
      if (!item.doctor.value) {
        item.doctor.invalid = true;
        invalidForm = true;
      }
      if (!item.date.value) {
        item.date.invalid = true;
        invalidForm = true;
      }
      if (!item.time.value) {
        item.time.invalid = true;
        invalidForm = true;
      }
      if (!item.point.value) {
        item.point.invalid = true;
        invalidForm = true;
      }
      return item;
    });
    if (invalidForm) {
      dispatch(setAppointmentAdditionalDoctors(validatedForm));
    } else {
      setDoctorsModalVisible(false);
    }
  };

  return (
    <Modal
      dialogClassName="appointment-additional-doctors-modal"
      show={doctorsModalVisible}
      actions={
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              setDoctorsModalVisible(false);
              if (!isEdit) {
                dispatch(setAppointmentAdditionalDoctors([initialValues]));
              }
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-success" onClick={validateFields}>
            Save
          </button>
        </div>
      }
    >
      {additionalDoctors.map((item, index) => (
        <>
          <div className="row" key={index}>
            <div className="col-sm-3">
              <label className="d-flex align-items-start flex-column">
                <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
                  Doctor:
                </span>
                <SelectPicker
                  size="lg"
                  isMulti={false}
                  isSearchable={true}
                  isDisabled={elements.dentistSB.disabled}
                  className={additionalDoctors[index].doctor.invalid ? "invalid-form-field" : ""}
                  options={elements.dentistSB.options.map(({ id, name }) => ({
                    label: name,
                    value: toFinite(id),
                  }))}
                  value={{ value: item.doctor.value }}
                  onChange={(x) => {
                    if (x.value === elements.dentistSB.selectedId) {
                      return;
                    }
                    if (additionalDoctors[index].doctor.invalid) {
                      additionalDoctors[index].doctor.invalid = false;
                    }
                    additionalDoctors[index].doctor.value = x.value;
                    additionalDoctors[index].point.value = null;
                    additionalDoctors[index].time.value = null;
                    additionalDoctors[index].date.value = moment();
                    dispatch(setAppointmentAdditionalDoctors(additionalDoctors));
                  }}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="d-flex align-items-start flex-column">
                <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
                  Trx. Room:
                </span>
                <SelectPicker
                  size="lg"
                  isMulti={false}
                  isSearchable={true}
                  isDisabled={!item.date.value || elements.dentalPointSB.disabled}
                  className={additionalDoctors[index].point.invalid ? "invalid-form-field" : ""}
                  options={(
                    dentalPoints.find((i) => i.id === item.doctor.value)?.dentalPoints || []
                  ).map(({ id, name }) => ({
                    label: name,
                    value: toFinite(id),
                  }))}
                  value={{ value: item.point.value }}
                  onChange={(x) => {
                    if (additionalDoctors[index].point.invalid) {
                      additionalDoctors[index].point.invalid = false;
                    }
                    additionalDoctors[index].point.value = x.value;
                    additionalDoctors[index].time.value = null;
                    additionalDoctors[index].time.options = [];
                    dispatch(setAppointmentAdditionalDoctors(additionalDoctors));
                  }}
                  onBlur={() => {
                    if (item.point.value)
                      getFreeTimes(item.doctor.value, item.point.value, item.date.value, index);
                  }}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="d-flex align-items-start flex-column">
                <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
                  Date:
                </span>
                <DatePicker
                  size="lg"
                  selected={item.date.value}
                  disabled={elements.date.disabled}
                  className={additionalDoctors[index].date.invalid ? "invalid-form-field" : ""}
                  onChange={(x) => {
                    if (additionalDoctors[index].date.invalid) {
                      additionalDoctors[index].date.invalid = false;
                    }
                    additionalDoctors[index].date.value = x;
                    additionalDoctors[index].time.value = null;
                    additionalDoctors[index].time.options = [];
                    dispatch(setAppointmentAdditionalDoctors(additionalDoctors));
                    if (item.point.value)
                      getFreeTimes(item.doctor.value, item.point.value, item.date.value, index);
                  }}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="d-flex align-items-start flex-column">
                <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
                  Time:
                </span>
                <SelectPicker
                  size="lg"
                  options={item.time.options}
                  value={{ value: item.time.value }}
                  isDisabled={elements.freeTimesSB.disabled}
                  className={additionalDoctors[index].time.invalid ? "invalid-form-field" : ""}
                  onChange={(x) => {
                    if (additionalDoctors[index].time.invalid) {
                      additionalDoctors[index].time.invalid = false;
                    }
                    additionalDoctors[index].time.value = x.value;
                    dispatch(setAppointmentAdditionalDoctors(additionalDoctors));
                  }}
                />
              </label>
            </div>
          </div>
          <div className="mt-3 mb-3" style={{ borderBottom: "3px solid rgb(190, 200, 68)" }} />
        </>
      ))}
      <Show if={!isEdit}>
        <div className="d-flex justify-content-end mt-3">
          {additionalDoctors.length > 1 && (
            <button
              type="button"
              className="btn btn-danger btn-rounded mr-3"
              onClick={() => {
                dispatch(
                  setAppointmentAdditionalDoctors(
                    additionalDoctors.slice(0, additionalDoctors.length - 1),
                  ),
                );
              }}
            >
              -
            </button>
          )}
          <button
            type="button"
            className="btn btn-success btn-rounded"
            onClick={() => {
              dispatch(setAppointmentAdditionalDoctors([...additionalDoctors, initialValues]));
            }}
          >
            +
          </button>
        </div>
      </Show>
    </Modal>
  );
};

export default enhancer(AppointmentAdditionalDoctorsModal);
