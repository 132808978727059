import React from "react";
import { connect } from "react-redux";
import { any, bool } from "prop-types";

import FilledImage from "../../widgets/FilledImage";
import DeleteImage from "../../../assets/images/delete.png";
import {
  deleteTransaction,
  setSidebarTransaction,
} from "../../../actions/chartSideTransactionActions";

const enhancer = connect();

const DeleteCellWrapper = enhancer(({ disabled, dispatch, item }) => {
  if (disabled) {
    return null;
  }

  return (
    <div
      role="button"
      onClick={(e) => {
        e.stopPropagation();

        dispatch(setSidebarTransaction(item));
        dispatch(deleteTransaction());
      }}
    >
      <FilledImage zoom={38} color="#d9534f" src={DeleteImage} />
    </div>
  );
});

DeleteCellWrapper.propTypes = {
  disabled: bool,
  item: any,
};

export { DeleteCellWrapper };
