import _ from "lodash";
import React from "react";

import { connect } from "react-redux";
import Utils from "../../helpers/Utils";
import moment from "moment";
import $ from "jquery";
import {
  deleteInsuranceItem,
  getCompanyPlan,
  getInsuranceItem,
  getInsuranceList,
  getReImbursementList,
  getOtherFieldsBySelectedCompany,
  initInsuranceForm,
  insurancePhotoSidebarShow,
  markAsPrimary,
  saveInsuranceItem,
  searchByCardNumber,
  showChoosePhotoModal,
} from "../../actions/insuranceActions";
import Element from "../../models/Element";

import PrimaryForm from "./PrimaryForm";

import InsuranceHeader from "./InsuranceHeader";
import { PLEASE_SELECT_ITEM } from "../../constants/Constants";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import PhotoDoneImage from "../../assets/images/insurance/done_photo.png";
import { getPatientDetails } from "../../actions/patientActions";

const elemType = {
  span: "span",
  button: "button",
  submit: "submit",
  select: "select",
  input: "input",
  number: "number",
  date: "date",
};
const FormType = {
  primary: "form1",
  secondary: "form2",
};
const enhancer = connect(
  ({
    session: { permissions, member },
    auth: { members, chosenMemberId },
    patient: { patientDetails },
    insurance: {
      item,
      list,
      total,
      fields,
      images,
      uploadImage,
      selectedForm,
      deletedImage,
      choosePhotoModalVisible,
      photoSidebarVisible,
      photoSidebarImageIndex,
    },
  }) => {
    return {
      permissions,
      member,
      members,
      chosenMemberId,
      patientDetails,
      item,
      list,
      fields,
      total,
      images,
      selectedForm,
      deletedImage,
      uploadImage,
      choosePhotoModalVisible,
      photoSidebarVisible,
      photoSidebarImageIndex,
    };
  },
  {
    initInsuranceForm,
    getInsuranceList,
    getReImbursementList,
    getCompanyPlan,
    saveInsuranceItem,
    getOtherFieldsBySelectedCompany,
    searchByCardNumber,
    markAsPrimary,
    deleteInsuranceItem,
    getInsuranceItem,
    showChoosePhotoModal,
    insurancePhotoSidebarShow,
    getPatientDetails,
  },
);
class InsuranceForm extends React.Component {
  constructor(props) {
    super(props);

    //region <Header Elements and Handlers>
    let editBtn = new Element("Edit");
    editBtn.handler = (e) => {
      this.toggleEditMode(e);
    };
    let title = new Element("Title");
    //endregion

    //region <SelectBox Elements and handlers>
    let relationSB = new Element("", 0, 0, "Relation", elemType.select);
    relationSB.parent = FormType.primary;

    let insuranceAliasSB = new Element("", 0, 0, "Network Name (Alias)", elemType.select);
    insuranceAliasSB.parent = FormType.primary;

    let reImbursementSB = new Element(
      "",
      0,
      0,
      "Select Insurance Company for Re-Imbursement",
      elemType.select,
    );
    reImbursementSB.parent = FormType.primary;

    let discount = new Element("", 0, 0, "Patient Discount %", elemType.input);
    discount.parent = FormType.primary;
    discount.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].discount.value = e.target.value;
      this.setState({ elements });
    };
    let insuranceCompanySB = new Element("", 0, 0, "Insurance company", elemType.select);
    insuranceCompanySB.parent = FormType.primary;

    let insurancePlanSB = new Element("", 0, 0, "Insurance Plan", elemType.select);
    insurancePlanSB.parent = FormType.primary;

    let brokerSB = new Element("", 0, 0, "Broker", elemType.select);
    brokerSB.parent = FormType.primary;

    let selfPayerSB = new Element("", 0, 0, "Self Payer", elemType.select);
    selfPayerSB.parent = FormType.primary;

    relationSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;

      elements[formType].relationSB.selectedId = e.target.value;
      elements[formType].relationSB.selected = Utils.getSelectedItem(elements[formType].relationSB);

      this.setState({ elements });
      this.configFieldsByRelation(elements[formType].relationSB);
    };

    reImbursementSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;

      elements[formType].reImbursementSB.selectedId = e.target.value;
      elements[formType].reImbursementSB.selected = Utils.getSelectedItem(
        elements[formType].reImbursementSB,
      );

      this.setState({ elements });
      this.configFieldsByRelation(elements[formType].reImbursementSB);
    };

    insuranceAliasSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const {
        getOtherFieldsBySelectedCompany,
        fields: { alias },
      } = this.props;
      const insuranceAliasId = e.target.value;

      const insuranceCompanyId = (alias || []).find((item) => item.id === insuranceAliasId)
        ?.insuranceCompany?.id;
      const formType = elem.parent;

      elements[formType].insuranceAliasSB.selectedId = insuranceAliasId;
      elements[formType].insuranceAliasSB.selected = Utils.getSelectedItem(
        elements[formType].insuranceAliasSB,
      );

      elements[formType].insuranceCompanySB.selectedId = insuranceCompanyId;
      elements[formType].insuranceCompanySB.selected = Utils.getSelectedItem(
        elements[formType].insuranceCompanySB,
      );

      elements[formType].insurancePlanSB.options = [];
      elements[formType].brokerSB.options = [];
      elements[formType].selfPayerSB.options = [];

      elements[formType].expiryDate.disabled = _.get(elem, "selected.selected", false);
      elements[formType].cardNumber.disabled = _.get(elem, "selected.selected", false);

      if (insuranceCompanyId) {
        getOtherFieldsBySelectedCompany({ insuranceCompanyId }).then(() => {
          this.renderSelectBoxFieldsByCompanyChange(formType);
        });
      }
      this.setState({ elements });
    };

    insuranceCompanySB.handler = (e, elem) => {
      let elements = this.state.elements;
      const { getOtherFieldsBySelectedCompany } = this.props;
      const insuranceCompanyId = e.target.value;
      const formType = elem.parent;

      elements[formType].insuranceCompanySB.selectedId = insuranceCompanyId;
      elements[formType].insuranceCompanySB.selected = Utils.getSelectedItem(
        elements[formType].insuranceCompanySB,
      );

      elements[formType].insurancePlanSB.options = [];
      elements[formType].brokerSB.options = [];
      elements[formType].selfPayerSB.options = [];

      elements[formType].expiryDate.disabled = _.get(elem, "selected.selected", false);
      elements[formType].cardNumber.disabled = _.get(elem, "selected.selected", false);

      if (insuranceCompanyId) {
        getOtherFieldsBySelectedCompany({ insuranceCompanyId }).then(() => {
          this.renderSelectBoxFieldsByCompanyChange(formType);
        });
      }
      this.setState({ elements });
    };

    insurancePlanSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;

      elements[formType].insurancePlanSB.selectedId = e.target.value;
      elements[formType].insurancePlanSB.selected = Utils.getSelectedItem(
        elements[formType].insurancePlanSB,
      );

      elements[formType].maxUsageAmount.disabled = _.get(elem, "selected.selected", false);

      this.setState({ elements });
    };
    brokerSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;
      const selectField = elements[formType].brokerSB;
      selectField.selectedId = e.target.value;

      selectField.selected = Utils.getSelectedItemById(selectField);

      elements[formType].brokerSB = selectField;
      this.setState({ elements });
    };
    selfPayerSB.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;

      elements[formType].selfPayerSB.selectedId = e.target.value;
      elements[formType].selfPayerSB.selected = Utils.getSelectedItemById(
        elements[formType].selfPayerSB,
      );

      this.setState({ elements });
    };
    relationSB.required = true;
    insuranceAliasSB.required = false;
    insuranceCompanySB.required = true;
    insurancePlanSB.required = true;

    let relationSB2 = relationSB.getClone(FormType.secondary);
    let insuranceAliasSB2 = insuranceAliasSB.getClone(FormType.secondary);
    let insuranceCompanySB2 = insuranceCompanySB.getClone(FormType.secondary);
    let insurancePlanSB2 = insurancePlanSB.getClone(FormType.secondary);
    let brokerSB2 = brokerSB.getClone(FormType.secondary);
    let selfPayerSB2 = selfPayerSB.getClone(FormType.secondary);

    //endregion

    //region <TextField Elements and Handlers>
    let cardNumber = new Element("", 0, 0, "Card Number");
    cardNumber.parent = FormType.primary;
    cardNumber.handler = (e, elem) => {
      let elements = this.state.elements;
      const formType = elem.parent;
      elements[formType].cardNumber.value = e.target.value;
      this.setState({ elements });
    };
    let searchDepCodeBtn = new Element("Search", 0, 0, null, elemType.button);
    searchDepCodeBtn.parent = FormType.primary;
    searchDepCodeBtn.visible = false;
    searchDepCodeBtn.handler = (e, elem) => {
      let formType = elem.parent;
      this.searchInsuranceByCardNumber(formType);
    };

    let division = new Element("", 0, 0, "Division");
    division.parent = FormType.primary;
    division.handler = (e, elem) => {
      let elements = this.state.elements;
      let formType = elem.parent;
      // division.value = e.target.value;
      elements[formType].division.value = e.target.value;
      this.setState({ elements });
    };

    let groupNumber = new Element("", 0, 0, "Group Number");
    groupNumber.parent = FormType.primary;
    groupNumber.handler = (e, elem) => {
      let elements = this.state.elements;
      let formType = elem.parent;

      elements[formType].groupNumber.value = e.target.value;
      this.setState({ elements });
    };

    let subscriberID = new Element("", 0, 0, "Subscriber ID");
    subscriberID.parent = FormType.primary;
    subscriberID.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;

      elements[formType].subscriberID.value = e.target.value;
      this.setState({ elements });
    };

    let subscriberName = new Element("", 0, 0, "Subscriber name");
    subscriberName.parent = FormType.primary;
    subscriberName.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].subscriberName.value = e.target.value;
      this.setState({ elements });
    };

    let maxUsageAmount = new Element("", 0, 0, "Max Usage Amount", "number");
    maxUsageAmount.parent = FormType.primary;
    maxUsageAmount.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].maxUsageAmount.value = e.target.value;
      this.setState({ elements });
    };

    let cardSequence = new Element("", 0, 0, "Policy Number / Package Name");
    cardSequence.parent = FormType.primary;
    cardSequence.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].cardSequence.value = e.target.value;
      this.setState({ elements });
    };

    let dependentCode = new Element("", 0, 0, "Dependent Code");
    dependentCode.parent = FormType.primary;
    dependentCode.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].dependentCode.value = e.target.value;
      this.setState({ elements });
    };

    let expiryDate = new Element("", 0, 0, "Expiry Date");
    expiryDate.parent = FormType.primary;
    expiryDate.handler = (e, elem) => {
      let formType = elem.parent;
      let elements = this.state.elements;
      elements[formType].expiryDate.value = e;
      this.setState({ elements });
    };

    cardNumber.required = true;
    maxUsageAmount.required = true;
    dependentCode.required = true;
    expiryDate.required = true;

    let cardNumber2 = cardNumber.getClone(FormType.secondary);
    let searchDepCodeBtn2 = searchDepCodeBtn.getClone(FormType.secondary);
    let division2 = division.getClone(FormType.secondary);
    let groupNumber2 = groupNumber.getClone(FormType.secondary);
    let subscriberID2 = subscriberID.getClone(FormType.secondary);
    let subscriberName2 = subscriberName.getClone(FormType.secondary);
    let maxUsageAmount2 = maxUsageAmount.getClone(FormType.secondary);
    let cardSequence2 = cardSequence.getClone(FormType.secondary);
    let reImbursementSB2 = reImbursementSB.getClone(FormType.secondary);
    let discount2 = discount.getClone(FormType.secondary);
    let dependentCode2 = dependentCode.getClone(FormType.secondary);
    let expiryDate2 = expiryDate.getClone(FormType.secondary);
    //endregion

    //region <Footer Button Elements and Handlers>
    let deleteBtn = new Element("Delete");
    deleteBtn.parent = FormType.primary;
    deleteBtn.handler = (e) => {
      e.preventDefault();
      const formType = deleteBtn.parent;
      const id = this.state.elements[formType].form.selectedId;
      if (id) {
        this.props
          .deleteInsuranceItem({ id })
          .then(() => {
            this.getInsuranceList();
          })
          .catch(() => {
            alert("Error with deleting insurance.");
          });
      } else {
        this.clearTextFields(formType);
      }
    };

    let saveBtn = new Element("Save");
    saveBtn.parent = FormType.primary;

    let addAnotherBtn = new Element("Add another");
    addAnotherBtn.parent = FormType.primary;
    addAnotherBtn.handler = () => {
      let elements = this.state.elements;
      const formName = FormType.secondary;
      elements[formName].form.visible = true;
      elements[formName].relationSB.options = elements[FormType.primary].relationSB.options;

      elements[formName].insuranceAliasSB.options =
        elements[FormType.primary].insuranceAliasSB.options;

      elements[formName].insuranceCompanySB.options =
        elements[FormType.primary].insuranceCompanySB.options;
      $(".insurance-form-page").animate({ scrollTop: 10000 }, "500");
      this.setState({ elements });
    };

    let photoBtn = new Element(<img src={PhotoDoneImage} width={44} height={44} alt="" />);

    let deleteBtn2 = deleteBtn.getClone(FormType.secondary);
    deleteBtn2.handler = (e) => {
      e.preventDefault();
      const formType = deleteBtn2.parent;
      const id = this.state.elements[formType].form.selectedId;
      if (id) {
        this.props
          .deleteInsuranceItem({ id })
          .then(() => {
            this.getInsuranceList();
            this.props.history.push(Routes.Dashboard);
            this.props.history.push(this.props.location);
          })
          .catch(() => {
            alert("Error with deleting insurance.");
          });
      } else {
        this.clearTextFields(formType);
      }
    };
    let saveBtn2 = saveBtn.getClone(FormType.secondary);
    let photoBtn2 = photoBtn.getClone(FormType.secondary);
    let makeAsPrimaryBtn2 = new Element("Make as Primary");
    makeAsPrimaryBtn2.parent = FormType.secondary;
    makeAsPrimaryBtn2.handler = (e) => {
      e.preventDefault();
      const id = this.state.elements.form2.form.selectedId;

      this.props.markAsPrimary({ id }).then(() => {
        this.getInsuranceList();
      });
    };

    //endregion

    const images = { visible: true };

    //region <Form Element>
    let form1 = new Element("Primary Insurance", 0, 0, FormType.primary);
    let form2 = new Element("Secondary Insurance", 0, 0, FormType.secondary);
    form2.visible = false;
    //endregion

    this.state = {
      elements: {
        header: {
          editBtn,
          title,
        },
        form1: {
          form: form1,
          relationSB,
          insuranceAliasSB,
          insuranceCompanySB,
          insurancePlanSB,
          cardNumber,
          searchDepCodeBtn,
          division,
          groupNumber,
          subscriberID,
          subscriberName,
          maxUsageAmount,
          cardSequence,
          discount,
          reImbursementSB,
          dependentCode,
          expiryDate,
          brokerSB,
          selfPayerSB,

          deleteBtn,
          saveBtn,
          addAnotherBtn,
          photoBtn,
          images,
        },
        form2: {
          form: form2,
          relationSB: relationSB2,
          insuranceAliasSB: insuranceAliasSB2,
          insuranceCompanySB: insuranceCompanySB2,
          insurancePlanSB: insurancePlanSB2,
          cardNumber: cardNumber2,
          searchDepCodeBtn: searchDepCodeBtn2,
          division: division2,
          groupNumber: groupNumber2,
          subscriberID: subscriberID2,
          subscriberName: subscriberName2,
          maxUsageAmount: maxUsageAmount2,
          cardSequence: cardSequence2,
          discount: discount2,
          reImbursementSB: reImbursementSB2,
          dependentCode: dependentCode2,
          expiryDate: expiryDate2,
          brokerSB: brokerSB2,
          selfPayerSB: selfPayerSB2,

          deleteBtn: deleteBtn2,
          saveBtn: saveBtn2,
          makeAsPrimaryBtn: makeAsPrimaryBtn2,
          photoBtn: photoBtn2,
          images,
        },
      },
      isFullPage: false,
    };
  }

  componentDidMount() {
    const patientKey =
      _.get(this.props, "match.params.patientKey") || _.get(this.props, "patient.patientKey");
    this.getInsuranceList();
    this.props.getPatientDetails({ patientKey });
  }

  getInsuranceList() {
    const { getInsuranceList, initInsuranceForm, patient } = this.props;
    const _params = this.props.match.params?.patientKey
      ? this.props.match.params
      : { patientKey: _.get(patient, "patientKey") };
    let elements = this.state.elements;

    elements.header.editBtn.selected = false;
    elements.header.editBtn.visible = true;
    this.setState({ elements });

    getInsuranceList(_params).then(() => {
      const { list, total } = this.props;
      // let elements = this.state.elements;

      if (list && list.length) {
        let item = list[0];
        let isPrimary = item?.type && item.type.id === 1;

        this.populateFieldsFromResponse(isPrimary ? FormType.primary : FormType.secondary, item);
        if (total > 1) {
          item = list[1];
          this.populateFieldsFromResponse(isPrimary ? FormType.secondary : FormType.primary, item);
          elements.form2.form.visible = true;
        }
      } else {
        elements.header.editBtn.selected = true;
        elements.header.editBtn.visible = false;
        elements.form1.addAnotherBtn.visible = true;

        initInsuranceForm().then(() => {
          this.renderFormFields(FormType.primary);
        });
      }

      this.setState({ elements });
      this.toggleEditMode();
    });
  }

  searchInsuranceByCardNumber(formType) {
    let elements = this.state.elements;
    const form = elements[formType];

    let transfer = {};
    if (form.insuranceCompanySB.selectedId)
      transfer.insuranceCompanyId = form.insuranceCompanySB.selectedId;
    if (form.insurancePlanSB.selectedId) transfer.insurancePlanId = form.insurancePlanSB.selectedId;
    if (form.cardNumber.value) transfer.cardNumber = form.cardNumber.value;

    this.props
      .searchByCardNumber(transfer)
      .then(() => {
        this.populateFieldsFromResponse(formType, this.props.item);
      })
      .catch(() => {
        alert(
          "Card you entered can not be found, you can re-enter card number or enter card details manually!",
        );
      });
  }

  populateFieldsFromResponse(formName, insuranceItem) {
    let elements = this.state.elements;
    const insuranceId = insuranceItem && insuranceItem.id;
    // elements[formName].form.visible = !!insuranceId;

    if (insuranceId) {
      const { initInsuranceForm, getCompanyPlan } = this.props;

      const insuranceCompanyId = _.get(insuranceItem, "insuranceCompany.id");
      const insuranceCompanyName = _.get(insuranceItem, "insuranceCompany.name");

      const insuranceAliasId = _.get(insuranceItem, "alias.id");
      const insuranceAliasName = _.get(insuranceItem, "alias.name");

      const imbursementId = _.get(insuranceItem, "reimbursementCompany.id");
      const imbursementName = _.get(insuranceItem, "reimbursementCompany.name");

      getCompanyPlan({ insuranceCompanyId }).then(() => {
        const {
          fields: { companyPlan, company },
        } = this.props;

        const currentPlanId = _.get(insuranceItem, "insurancePlan.id");
        const currentCompanyId = _.get(insuranceItem, "insuranceCompany.id");
        const currentPlan = companyPlan.find((item) => _.get(item, "id") === currentPlanId);
        const currentCompany = company.find((item) => _.get(item, "id") === currentCompanyId);

        elements[formName].maxUsageAmount.disabled = _.get(currentPlan, "selected", false);
        elements[formName].expiryDate.disabled = _.get(currentCompany, "selected", false);
        elements[formName].cardNumber.disabled = _.get(currentCompany, "selected", false);
      });

      elements[formName].form.selectedId = insuranceItem.id;

      let temp = insuranceItem.relation;
      elements[formName].relationSB.selectedId = temp && temp.id;
      elements[formName].relationSB.value = temp && temp.name;

      elements[formName].insuranceAliasSB.selectedId = insuranceAliasId;
      elements[formName].insuranceAliasSB.value = insuranceAliasName;

      elements[formName].reImbursementSB.selectedId = imbursementId;
      elements[formName].reImbursementSB.value = imbursementName;

      elements[formName].insuranceCompanySB.selectedId = insuranceCompanyId;
      elements[formName].insuranceCompanySB.value = insuranceCompanyName;

      temp = insuranceItem.insurancePlan;
      elements[formName].insurancePlanSB.selectedId = temp && temp.id;
      elements[formName].insurancePlanSB.value = temp && temp.name;

      temp = insuranceItem.broker;
      elements[formName].brokerSB.selectedId = temp && temp.id;
      elements[formName].brokerSB.value = temp && temp.name;

      temp = insuranceItem.selfPayer;
      elements[formName].selfPayerSB.selectedId = temp && temp.id;
      elements[formName].selfPayerSB.value = temp && temp.name;

      elements[formName].subscriberID.value = insuranceItem.subscriberId;
      elements[formName].subscriberName.value = insuranceItem.subscriberName;
      elements[formName].maxUsageAmount.value = insuranceItem.maxUsageAmount;
      elements[formName].discount.value = insuranceItem.discount;
      elements[formName].cardSequence.value = insuranceItem.cardSequence;
      elements[formName].dependentCode.value = insuranceItem.dependentCode;
      elements[formName].cardNumber.value = insuranceItem.cardNumber;
      elements[formName].division.value = insuranceItem.division;
      elements[formName].groupNumber.value = insuranceItem.groupNumber;
      elements[formName].expiryDate.value = insuranceItem.expireDate
        ? moment(new Date(insuranceItem.expireDate))
        : null;
      this.setState({ elements });
      initInsuranceForm({ insuranceCompanyId }).then(() => {
        this.renderFormFields(formName);
      });
    }
  }

  renderFormFields(formName) {
    const { fields } = this.props;
    let elements = this.state.elements;

    let tempList = fields.relation;
    elements[formName].relationSB.options = tempList || [];
    if (tempList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].relationSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].relationSB.selected = Utils.getSelectedItem(elements[formName].relationSB);

    tempList = fields.company;
    const aliasList = fields.alias;
    const imbursementList = fields.imbursementList;

    elements[formName].reImbursementSB.options = imbursementList || [];
    elements[formName].insuranceAliasSB.options = aliasList || [];
    if (aliasList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].insuranceAliasSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].insuranceAliasSB.selected = Utils.getSelectedItem(
      elements[formName].insuranceAliasSB,
    );
    if (imbursementList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].reImbursementSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].reImbursementSB.selected = Utils.getSelectedItem(
      elements[formName].reImbursementSB,
    );

    elements[formName].insuranceCompanySB.options = tempList || [];
    if (tempList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].insuranceCompanySB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].insuranceCompanySB.selected = Utils.getSelectedItem(
      elements[formName].insuranceCompanySB,
    );

    this.configFieldsByRelation(elements[formName].relationSB);

    this.renderSelectBoxFieldsByCompanyChange(formName);

    this.setState({ elements });
  }

  renderSelectBoxFieldsByCompanyChange(formName) {
    let elements = this.state.elements;
    const { fields } = this.props;

    let tempList = fields.companyPlan;
    elements[formName].insurancePlanSB.options = tempList || [];
    if (tempList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].insurancePlanSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].insurancePlanSB.selected = Utils.getSelectedItem(
      elements[formName].insurancePlanSB,
    );

    tempList = fields.broker;
    elements[formName].brokerSB.options = tempList || [];
    if (tempList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].brokerSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].brokerSB.selected = Utils.getSelectedItem(elements[formName].brokerSB);

    tempList = fields.selfPayer;
    elements[formName].selfPayerSB.options = tempList || [];
    if (tempList.indexOf(PLEASE_SELECT_ITEM) === -1) {
      elements[formName].selfPayerSB.options.unshift(PLEASE_SELECT_ITEM);
    }
    elements[formName].selfPayerSB.selected = Utils.getSelectedItem(elements[formName].selfPayerSB);
    const insuranceType =
      "Insurance" === _.get(elements[formName].insuranceCompanySB, "selected.type.code");
    if (!insuranceType) {
      elements[formName].maxUsageAmount.disableAndClear(true);
      elements[formName].expiryDate.disableAndClear(true);
      elements[formName].cardNumber.disableAndClear(true);
      elements[formName].cardNumber.required = false;
      elements[formName].expiryDate.required = false;
    }
    this.setState({ elements });
  }

  toggleEditMode(e) {
    const { list, total } = this.props;

    let elements = this.state.elements;
    const isSelected = elements.header.editBtn.selected;
    let isEditMode = isSelected;
    if (e) {
      elements.header.editBtn.selected = !isSelected;
      elements.header.editBtn.visible = isSelected;
      isEditMode = !isSelected;
    }

    let changeTypes = (formName) => {
      const selectType = isEditMode ? elemType.select : elemType.span;
      const inputType = isEditMode ? elemType.input : elemType.span;
      const numberType = isEditMode ? elemType.number : elemType.span;
      const dateType = isEditMode ? elemType.date : elemType.span;
      const visible = !isEditMode;

      elements[formName].relationSB.type = selectType;
      elements[formName].insuranceAliasSB.type = selectType;
      elements[formName].insuranceCompanySB.type = selectType;
      elements[formName].insurancePlanSB.type = selectType;
      elements[formName].brokerSB.type = selectType;
      elements[formName].selfPayerSB.type = selectType;

      elements[formName].images.visible = visible;

      elements[formName].subscriberID.type = inputType;
      elements[formName].subscriberName.type = inputType;
      elements[formName].maxUsageAmount.type = numberType;
      elements[formName].cardSequence.type = inputType;
      elements[formName].dependentCode.type = inputType;
      elements[formName].cardNumber.type = inputType;
      elements[formName].division.type = inputType;
      elements[formName].groupNumber.type = inputType;
      elements[formName].expiryDate.type = dateType;
      elements[formName].groupNumber.type = inputType;

      elements[formName].deleteBtn.visible = isEditMode;
      elements[formName].saveBtn.visible = isEditMode;
      elements[formName].photoBtn.visible = isEditMode;

      this.setState({ elements });
      this.toggleCardNumberBtn(formName);
    };
    elements[FormType.primary].addAnotherBtn.visible = isEditMode && list && total <= 1;

    if (elements[FormType.secondary].form.visible) {
      elements[FormType.secondary].makeAsPrimaryBtn.visible = !isEditMode;
    }
    this.setState({ elements });

    changeTypes(FormType.primary);
    changeTypes(FormType.secondary);
  }

  clearTextFields(formName) {
    let elements = this.state.elements;

    Utils.selectItem(elements[formName].relationSB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].insuranceAliasSB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].reImbursementSB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].insuranceCompanySB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].insurancePlanSB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].brokerSB, PLEASE_SELECT_ITEM);
    Utils.selectItem(elements[formName].selfPayerSB, PLEASE_SELECT_ITEM);

    elements[formName].subscriberID.value = "";
    elements[formName].subscriberName.value = "";
    elements[formName].maxUsageAmount.value = "";
    elements[formName].cardSequence.value = "";
    elements[formName].dependentCode.value = "";
    elements[formName].cardNumber.value = "";
    elements[formName].discount.value = "";
    elements[formName].groupNumber.value = "";
    elements[formName].expiryDate.value = null;
    // elements[formName].relationSB.selected = null;

    this.setState({ elements });
  }

  saveHandler = (e, formName) => {
    e.preventDefault();
    const { list, total, saveInsuranceItem, fields = {} } = this.props;

    let elements = this.state.elements;
    const form = elements[formName];
    const cardNumber = form.cardNumber.value;
    const division = form.division.value;
    const groupNumber = form.groupNumber.value;
    const discount = form.discount.value;

    const subscriberId = form.subscriberID.value;
    const subscriberName = form.subscriberName.value;
    const maxUsageAmount = form.maxUsageAmount.value;
    const cardSequence = form.cardSequence.value;
    const dependentCode = form.dependentCode.value;
    const expireDate = form.expiryDate.value && form.expiryDate.value.valueOf();

    const patientKey =
      this.props.match.params.patientKey || _.get(this.props, "patient.patientKey");
    let transfer = {
      cardNumber,
      division,
      groupNumber,
      subscriberId,
      subscriberName,
      maxUsageAmount,
      cardSequence,
      dependentCode,
      expireDate,
      discount,
      patientKey,
    };

    const relation = form.relationSB.selected;
    const insuranceAlias = form.insuranceAliasSB.selected;
    const reImbursement = form.reImbursementSB.selected;
    const insuranceCompany = form.insuranceCompanySB.selected;
    const insurancePlan = form.insurancePlanSB.selected;
    const broker = form.brokerSB.selected;
    const selfPayer = form.selfPayerSB.selected;

    let type = {
      id: 1,
      name: "Primary insurance",
    };
    if (formName === FormType.secondary) {
      type = {
        id: 2,
        name: "Secondary insurance",
      };
    }

    if (relation && relation.id) transfer.relation = relation;
    if (insuranceAlias && insuranceAlias.id) transfer.alias = insuranceAlias;
    if (reImbursement && reImbursement.id) transfer.reimbursementCompany = reImbursement;
    if (insuranceCompany && insuranceCompany.id) transfer.insuranceCompany = insuranceCompany;
    if (insurancePlan && insurancePlan.id) transfer.insurancePlan = insurancePlan;
    if (broker && broker.id) transfer.broker = broker;
    if (selfPayer && selfPayer.id) transfer.selfPayer = selfPayer;
    if (type && type.id) transfer.type = type;

    let insuranceItem;
    if (list && total) {
      for (let itemOfList of list) {
        if (type.id === itemOfList.type.id) {
          insuranceItem = itemOfList;
        }
      }
    }
    if (insuranceItem && insuranceItem.id) {
      transfer.id = insuranceItem.id;
    }

    let hasError = false;

    for (let key in form) {
      let value;
      if (form[key]?.type === "select") {
        value = form[key].selected && form[key].selected.id;
      } else {
        value = form[key].value;
      }
      if (form[key].required && !form[key].disabled && !value) {
        hasError = true;
        alert(form[key].name + " is required field.");
      }
    }

    let hasEmptyBrokerError = false;

    const { company = [] } = fields;

    const currentCompany = company.find(
      ({ id, brokerRequired }) => id === form.insuranceCompanySB.selectedId && brokerRequired,
    );
    if (currentCompany && !form.brokerSB.selectedId) {
      hasEmptyBrokerError = true;
    }
    // InsuranceImage
    // ????
    let dScreen = {};

    const fn = formName; //=== 'form1' && 'form2' || 'form1';

    for (let key in this.props.images[fn]) {
      if (this.props.images[fn][key] != undefined) {
        if (Object.keys(dScreen).length === 0) {
          dScreen["documentScreen"] = this.props.images[fn][key];
        } else {
          dScreen["documentScreen" + Object.keys(dScreen).length] = this.props.images[fn][key];
        }
      }
    }

    Object.assign(transfer, dScreen);
    //---------------

    if (!hasEmptyBrokerError && !hasError) {
      saveInsuranceItem(transfer).then(() => {
        const { item } = this.props;
        let elements = this.state.elements;
        elements.header.editBtn.selected = false;
        elements.header.editBtn.visible = true;

        const insuranceItem = item;
        const insuranceCompanyId =
          insuranceItem.insuranceCompany && insuranceItem.insuranceCompany.id;
        const insuranceCompanyName =
          insuranceItem.insuranceCompany && insuranceItem.insuranceCompany.name;

        elements[formName].form.selectedId = insuranceItem.id;

        let temp = insuranceItem.relation;
        elements[formName].relationSB.selectedId = temp && temp.id;
        elements[formName].relationSB.value = temp && temp.name;

        elements[formName].insuranceCompanySB.selectedId = insuranceCompanyId;
        elements[formName].insuranceCompanySB.value = insuranceCompanyName;

        temp = insuranceItem.insurancePlan;
        elements[formName].insurancePlanSB.selectedId = temp && temp.id;
        elements[formName].insurancePlanSB.value = temp && temp.name;

        temp = insuranceItem.broker;
        elements[formName].brokerSB.selectedId = temp && temp.id;
        elements[formName].brokerSB.value = temp && temp.name;

        temp = insuranceItem.selfPayer;
        elements[formName].selfPayerSB.selectedId = temp && temp.id;
        elements[formName].selfPayerSB.value = temp && temp.name;

        elements[formName].subscriberID.value = insuranceItem.subscriberId;
        elements[formName].subscriberName.value = insuranceItem.subscriberName;
        elements[formName].maxUsageAmount.value = insuranceItem.maxUsageAmount;
        elements[formName].cardSequence.value = insuranceItem.cardSequence;
        elements[formName].dependentCode.value = insuranceItem.dependentCode;
        elements[formName].cardNumber.value = insuranceItem.cardNumber;
        elements[formName].division.value = insuranceItem.division;
        elements[formName].groupNumber.value = insuranceItem.groupNumber;
        elements[formName].expiryDate.value = insuranceItem.expireDate
          ? moment(new Date(insuranceItem.expireDate))
          : null;

        this.setState({ elements });
        this.toggleEditMode();
      });
    }

    if (hasEmptyBrokerError) {
      alert("Broker is required");
    }
  };

  toggleCardNumberBtn(formType) {
    let elements = this.state.elements;
    const isSelfRelation =
      elements[formType].relationSB.selected && elements[formType].relationSB.selected.id === 1;
    const isEditMode = elements.header.editBtn.selected;
    const visible = isEditMode && !isSelfRelation;
    elements[formType].cardNumber.className = visible ? "with-search-btn" : "";
    elements[formType].searchDepCodeBtn.visible = visible;
    this.setState({ elements });
    // elements[formType].searchDepCodeBtn.width = isSelfRelation ? 380 : 280;
  }

  configFieldsByRelation(selectBox) {
    let elements = this.state.elements;
    const formType = selectBox.parent;
    const id = selectBox.selected && selectBox.selected.id;

    if (id) {
      // elements[formType].form.className = `relation-${selectBox.selected.name}`;
      const isSelfRelation = id === 1;
      elements[formType].cardNumber.disableAndClear(false);
      elements[formType].subscriberID.disableAndClear(false);
      elements[formType].subscriberName.disableAndClear(false);
      //elements[formType].maxUsageAmount.disableAndClear(false);
      elements[formType].cardSequence.disableAndClear(false);
      elements[formType].expiryDate.disableAndClear(false);
      // elements[formType].brokerSB.disableAndClear(false);

      elements[formType].division.disableAndClear(isSelfRelation);
      elements[formType].groupNumber.disableAndClear(isSelfRelation);
      elements[formType].subscriberName.disableAndClear(isSelfRelation);
      elements[formType].dependentCode.disableAndClear(isSelfRelation);
    } else {
      elements[formType].cardNumber.disableAndClear(true);
      elements[formType].subscriberID.disableAndClear(true);
      elements[formType].subscriberName.disableAndClear(true);
      //elements[formType].maxUsageAmount.disableAndClear(true);
      elements[formType].cardSequence.disableAndClear(true);
      elements[formType].expiryDate.disableAndClear(true);
      // elements[formType].brokerSB.disableAndClear(true);

      elements[formType].division.disableAndClear(true);
      elements[formType].groupNumber.disableAndClear(true);
      elements[formType].subscriberName.disableAndClear(true);
      elements[formType].dependentCode.disableAndClear(true);
    }
    this.setState({ elements });
    this.toggleCardNumberBtn(formType);
  }

  photoHandler = (e, formName) => {
    this.props.showChoosePhotoModal(formName);
  };

  photoSidebarHandler = (index) => {
    this.props.insurancePhotoSidebarShow(index);
  };

  onBackClick = () => {
    if (this.props.isSidebarMode) {
      this.props.onShowInsuranceForm();
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    let elements = this.state.elements;
    const {
      images,
      selectedForm,
      choosePhotoModalVisible,
      list,
      photoSidebarVisible,
      photoSidebarImageIndex,
      patientDetails,
    } = this.props;
    const editMode = elements.header.editBtn.selected ? "edit-mode" : "details-mode";
    const extraClass = this.props.isSidebarMode ? "sidebar-mode" : "full-page-mode";

    return (
      <div className={`insurance-form-page ${extraClass}_wrapper`}>
        <div className="insurance-wrap">
          <InsuranceHeader
            elements={elements.header}
            onBackClick={this.onBackClick}
            extraClass={extraClass}
            isSidebarMode={this.props.isSidebarMode}
          />
          <div className={`insurance-forms container ${editMode}`}>
            <PrimaryForm
              key="1"
              extraClass={extraClass}
              showModal={choosePhotoModalVisible}
              elements={elements.form1}
              header={elements.header}
              saveHandler={this.saveHandler}
              photoHandler={this.photoHandler}
              selectedForm={selectedForm}
              photoSidebarVisible={photoSidebarVisible}
              photoSidebarImageIndex={photoSidebarImageIndex}
              photoSidebarHandler={this.photoSidebarHandler}
              container={images}
              item={list && list[0]}
              patientDetails={patientDetails}
            />
            <PrimaryForm
              key="2"
              extraClass={extraClass}
              showModal={choosePhotoModalVisible}
              elements={elements.form2}
              header={elements.header}
              saveHandler={this.saveHandler}
              photoHandler={this.photoHandler}
              photoSidebarHandler={this.photoSidebarHandler}
              photoSidebarImageIndex={photoSidebarImageIndex}
              selectedForm={selectedForm}
              photoSidebarVisible={photoSidebarVisible}
              container={images}
              item={list && list[1]}
              patientDetails={patientDetails}
            />
          </div>
        </div>
      </div>
    );
  }
}

InsuranceForm.defaultProps = {
  isSidebarMode: false,
};
export default withRouter(enhancer(InsuranceForm));
