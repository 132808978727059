import React from "react";
import { useField } from "formik";

import { Radio } from "../ui/Radio";
import cx from "classnames";

interface Props {
  readonly name: string;
  readonly label?: string;
  readonly radioValue: string;
  readonly className?: string;
}

export function RadioField({ name, label, radioValue, className }: Props) {
  const [field, , helpers] = useField(name);

  return (
    <div className={cx("d-flex align-items-center", className)}>
      <Radio
        id={name}
        name={name}
        value={field.value}
        checked={field.value === radioValue}
        onClick={() => helpers.setValue(radioValue)}
      />
      <label htmlFor={name} className="ml-1 mb-0">
        {label}
      </label>
    </div>
  );
}
