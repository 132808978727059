import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import RCSwitch, { SwitchChangeEventHandler, SwitchClickEventHandler } from "rc-switch";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "50px",
    "& > .rc-switch-inner": { top: 0 },
    "&.rc-switch-checked:after": { left: "29px" },
  },
}));

export interface SwitchProps extends React.RefAttributes<HTMLButtonElement> {
  readonly title?: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly tabIndex?: number;
  readonly className?: string;
  readonly prefixCls?: string;
  readonly defaultChecked?: boolean;
  readonly style?: React.CSSProperties;
  readonly loadingIcon?: React.ReactNode;
  readonly onClick?: SwitchClickEventHandler;
  readonly checkedChildren?: React.ReactNode;
  readonly unCheckedChildren?: React.ReactNode;
  readonly onChange?: SwitchChangeEventHandler;
  readonly onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

export function Switch(props: SwitchProps) {
  const classes = useStyles();

  return (
    <RCSwitch
      checkedChildren="Yes"
      unCheckedChildren="No"
      {...props}
      className={cx(classes.root, props.className)}
    />
  );
}
