import { useField } from "formik";
import React, { useMemo } from "react";

import { TextInput, TextInputProps } from "../ui/TextInput";
import { FieldContainer, FieldContainerProps } from "./FieldContainer";

interface Props extends TextInputProps {
  readonly name: string;
  readonly label?: string;
  readonly fieldProps?: Omit<FieldContainerProps, "label" | "children">;
}

export function TextField({ name, label, fieldProps, ...props }: Props) {
  const [field, meta] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <TextInput {...props} {...field} />
    </FieldContainer>
  );
}
