import React from "react";
import { connect } from "react-redux";

import BackButton from "../widgets/BackButton";

import bindActions from "../../helpers/bindActions";
import * as patientViewActions from "../../actions/patientViewActions";
import { Modal } from "../ui/Modal";

const enhancer = connect(null, bindActions({ patientViewActions }));
class PatientViewClinicianModal extends React.Component {
  save = () => {
    this.props.patientViewActions.savePatientReminders(
      this.refs.receptionReminderActive.checked,
      this.refs.doctorReminderActive.checked,
      this.refs.receptionReminders.value,
      this.refs.doctorReminders.value,
    );
  };

  render() {
    const {
      patientReminderModalVisible,
      companyPatientReminder: {
        receptionReminderActive,
        doctorReminderActive,
        receptionReminder,
        doctorReminder,
      },
    } = this.props.patientView;

    return (
      <Modal
        bodyProps={{ style: { padding: 0 } }}
        show={patientReminderModalVisible}
        onHide={this.props.patientViewActions.patientRemindersModalHide}
        animation={false}
        keyboard={false}
        dialogClassName="chart-side-bar"
      >
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header">
            <div className="clearfix">
              <div className="col-sm-4">
                <BackButton
                  className="btn-sm"
                  text="Back"
                  onClick={this.props.patientViewActions.patientRemindersModalHide}
                />
              </div>
              <div className="col-sm-8">
                <button className="btn btn-link pull-right" type="button" onClick={this.save}>
                  SAVE
                </button>
              </div>
            </div>
          </div>
          <div className="chart-side-body">
            <div className="title">
              Reception Reminders
              <input
                type="checkbox"
                defaultChecked={receptionReminderActive}
                style={{ margin: "0 0 0 5px", verticalAlign: "middle" }}
                ref="receptionReminderActive"
              />
            </div>

            <div style={{ padding: "10px" }}>
              <textarea
                className="form-control"
                ref="receptionReminders"
                defaultValue={receptionReminder}
                rows="10"
              />
            </div>

            <div className="title">
              {localStorage.getItem("doctorLabel")} Reminders
              <input
                type="checkbox"
                defaultChecked={doctorReminderActive}
                style={{ margin: "0 0 0 5px", verticalAlign: "middle" }}
                ref="doctorReminderActive"
              />
            </div>

            <div style={{ padding: "10px" }}>
              <textarea
                className="form-control"
                ref="doctorReminders"
                defaultValue={doctorReminder}
                rows="10"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default enhancer(PatientViewClinicianModal);
