import _ from "lodash";

import createReducer from "../helpers/createReducer";
import {
  GET_SELECT_PICKER_ASYNC_OPTIONS_SUCCESS,
  GET_SESSION_SUCCESS,
} from "../actions/sessionActions";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../constants/Constants";

const defaultState = {
  SelectPickerAsyncOptions: [],
};

const reducers = {
  [GET_SESSION_SUCCESS](state, { data }) {
    const dateFormat = _.toUpper(_.get(data, "dateFormat", DATE_FORMAT_LEGACY));
    const timeFormat = _.get(data, "timeFormat", TIME_FORMAT);
    const fullDateFormat = `${dateFormat}, ${timeFormat}`;

    const phoneCode = _.get(data, "clinic.address.country.phoneCode", "971");
    const countryName = _.get(data, "clinic.address.country.name", "United Arab Emirates");

    const timezone = _.get(data, "company.timezone.description");
    const timezoneCode = _.get(data, "company.timezone.code");

    /* eslint-disable */
    localStorage.setItem("company-date-format", dateFormat);
    localStorage.setItem("company-time-format", timeFormat);
    localStorage.setItem("company-full-date-format", fullDateFormat);

    localStorage.setItem("timezone", timezone);
    localStorage.setItem("timezone-code", timezoneCode);

    localStorage.setItem("phoneCode", phoneCode);
    localStorage.setItem("countryName", countryName);
    localStorage.setItem("specialityCode", data?.speciality?.code);
    if (data.miscSettings) {
      data.miscSettings.customDentalAccountingActivated = false
    }

    if (data.miscSettings && data.miscSettings.doctorLabel) {
      /* eslint-disable-next-line */
      localStorage.setItem("doctorLabel", data.miscSettings.doctorLabel);
    }
    if (data.miscSettings && data.miscSettings.nurseLabel) {
      /* eslint-disable-next-line */
      localStorage.setItem("nurseLabel", data.miscSettings.nurseLabel);
    }

    const store = JSON.parse(JSON.stringify(data));

    if (store.alertNotifications) {
      const alertNotifications = {};
      store.alertNotifications.forEach((item) => {
        alertNotifications[item.code] = item;
      });
      store.alertNotifications = alertNotifications;
    }

    if (!store.permissions) {
      store.permissions = [];
    }

    return store;
  },
  [GET_SELECT_PICKER_ASYNC_OPTIONS_SUCCESS](state, { data }) {
    return {
      ...state,
      selectPickerAsyncOptions: data.list || [],
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const memberSelector = ({ session }) => session.member;
export const clinicSelector = ({ session }) => session.clinic;
export const companySelector = ({ session }) => session.company;
export const clickArroundModalSelector = ({ session }) => session.miscSettings?.clickArroundModal;
export const isActivePermissionSelector = (permission) => ({ session }) =>
  session.permissions.indexOf(permission) >= 0;

export default createReducer(defaultState, reducers);
