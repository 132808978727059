import React from "react";
import { SidebarSearch } from "./SidebarSearch";

export function SidebarContentContainer({ title, isItem, children, onSearch, searchValue }) {
  return (
    <div className="sidebar-changed-content">
      {title && <span className="chart-treatments-sidebar-title">{title}</span>}

      {!isItem && <SidebarSearch value={searchValue} onChange={onSearch} title={title} />}

      {children}
    </div>
  );
}
