import { useQuery, UseQueryResult } from "react-query";
import { createUrl } from "../../utils/UrlUtils";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

interface Query {
  readonly id: number;
}

export function useRemindersLogQuery(query: Query): UseQueryResult<any, Error> {
  return useQuery(
    ["appointment-reminder-history"],
    () =>
      httpClient.get(createUrl(ApiRoutes.appointmentRemindersLog, { query })).then(formatResponse),
    { enabled: false },
  );
}
