import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import _, { toFinite } from "lodash";
import Utils from "../../helpers/Utils";
import AppointmentUtils from "../../helpers/AppointmentUtils";

import Element from "../../models/Element";
import AppointmentDeleteModal from "./AppointmentDeleteModal";

import {
  AppointmentScreenTypes,
  CHOOSE_POPUP,
  FORM_ACTIONS,
  MSG_DONT_HAVE_PERMISSION,
  MSG_PATIENT_IS_NOT_REGISTERED,
  PERMISSIONS,
} from "../../constants/Constants";
import { STATUS_TYPES } from "../../constants/StatusTypes";

import AppointmentHeader from "./AppointmentHeader";
import RightSide from "./RightSide";
import LeftSide from "./LeftSide";
import AppointmentReasonModal from "./AppointmentReasonModal";
import AppointmentRelatedModal from "./AppointmentRelatedModal";
import ChartSideBar from "../chart/side-bar/ChartSideBar";

import {
  changeItem,
  checkOtherDoctor,
  choosePatient,
  closeReasonModal,
  deleteAppointmentModalShow,
  getAppointmentById,
  getCategories,
  getDoctors,
  getLocations,
  getMachines,
  getOtherStatus,
  getApprovalStatus,
  getLabStatus,
  getSubCategories,
  getTooth,
  initAppoinmemt,
  initRelated,
  openReasonModal,
  setAppointmentPlannedTreatmentModalVisible,
  saveAppointment,
  showModals,
  statusChangeOfAppointment,
  clearAppointment,
  setAppointmentPatientEmiratesId,
  setGuardianInformed,
  setRequestedAppointmentItemPatient,
  setTemporaryCompanyId,
} from "../../actions/appointmentActions";
import PatientInsuranceFormSidebar from "./PatientInsuranceFormSidebar";
import { selectClinicId } from "../../actions/calendarActions";
import { getPlannedTransactions } from "../../actions/transactionActions";
import { getPatientDetails } from "../../actions/patientActions";
import { getClinics, hideSideBar, showSideBar } from "../../actions/chartActions";
import { openSimpleModal } from "../../actions/simpleModalActions";
import { createFollowUpAppointment, getTypes } from "../../actions/repeatAppointmentActions";
import { getCompanyStartOfDay } from "../../helpers/DateUtils";
import ChartTreatmentsSidebarWrapper from "../../wrappers/ChartTreatmentsSidebarWrapper";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import AppointmentPlannedTreatmentsModal from "./AppointmentPlannedTreatmentsModal";

const enhancer = connect(
  ({
    session: {
      permissions,
      member,
      toothNumericType,
      clinicalNotesSideBarViewType,
      masterTypeEnabled,
      miscSettings,
      clinic,
      allowAppointmentMultiBooking,
    },
    auth: { members, chosenMemberId },
    calendar: { columns, doctorDropdownVisible, doctorsPoints, clinicId, calendarViewMode },
    appointment,
    appointment: {
      item,
      showSidebar,
      patientDetails,
      statusList,
      reasonModalVisible,
      appointmentPlannedTreatmentModalVisible,
      switchers,
      patientSelectedBirthDate,
      nurse,
      deleteAppointmentModalVisible,
      reasonModalStatus,
      temporaryPatientData,
      consultationNote,
      requestedAppointmentItem,
    },
    form: { SearchPatientForm },
    chart,
    chart: { teeth, sideBarVisible, viewType, chartBalanceModalVisible, chartBalanceValue },
    patient: { fetchingPatientInfo },
    callList,
    waitingList,
    recallList,
    chartSideClinicalNote,
    chartSideFillInForm,
    chartSideVital,
    chartSidePrescription,
    chartDermatology,
    chartSideTransaction,
    chartSidePlan,
    chartGeneral,
    chartSidePostOp,
    chartSideDocs,
    chartSideSeries,
    repeatAppointment,
    folder: { folderItem },
  }) => {
    return {
      folderItem,
      calendarViewMode,
      permissions,
      statusList,
      columns,
      patientDetails,
      appointment,
      item,
      member,
      clinicId,
      members,
      doctorDropdownVisible,
      doctorsPoints,
      chosenMemberId,
      chartSideFillInForm,
      reasonModalVisible,
      appointmentPlannedTreatmentModalVisible,
      teeth,
      sideBarVisible,
      chartSideClinicalNote,
      chart,
      showSidebar,
      chartSideVital,
      toothNumericType,
      viewType,
      switchers,
      chartBalanceModalVisible,
      chartBalanceValue,
      patientSelectedBirthDate,
      callList,
      chartSidePrescription,
      chartDermatology,
      chartSideTransaction,
      chartSidePlan,
      chartGeneral,
      chartSideSeries,
      chartSidePostOp,
      waitingList,
      recallList,
      nurse,
      deleteAppointmentModalVisible,
      reasonModalStatus,
      temporaryPatientData,
      consultationNote,
      clinicalNotesSideBarViewType,
      masterTypeEnabled,
      chartSideDocs,
      repeatAppointment,
      clinic,
      SearchPatientForm,
      miscSettings,
      fetchingPatientInfo,
      allowAppointmentMultiBooking,
      requestedAppointmentItem,
    };
  },
  {
    getClinics,
    setGuardianInformed,
    initAppoinmemt,
    getAppointmentById,
    changeItem,
    getPatientDetails,
    saveAppointment,
    statusChangeOfAppointment,
    getLocations,
    getSubCategories,
    getMachines,
    getDoctors,
    getCategories,
    getOtherStatus,
    getApprovalStatus,
    getLabStatus,
    getTooth,
    selectClinicId,
    openReasonModal,
    initRelated,
    closeReasonModal,
    choosePatient,
    hideSideBar,
    showSideBar,
    showModals,
    openSimpleModal,
    deleteAppointmentModalShow,
    getPlannedTransactions,
    createFollowUpAppointment,
    getTypes,
    checkOtherDoctor,
    clearAppointment,
    setAppointmentPatientEmiratesId,
    setRequestedAppointmentItemPatient,
    setTemporaryCompanyId,
    setAppointmentPlannedTreatmentModalVisible,
  },
);

class AppointmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.self = this;
    this.patient = null;
    this.appointmentItem = null;
    this.appointmentData = {
      isEdit: false,
      statusCode: STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
      rightData: null,
      patientData: null,
    };
    this.elements = {
      header: {
        saveBtn: new Element("Save"),
        resetBtn: new Element("Reset"),
      },
      patientProfile: {
        chartBtn: new Element("Patient's Chart"),
        popup: {
          cancelBtn: new Element(CHOOSE_POPUP.Cancel, 0),
          startVisitBtn: new Element(CHOOSE_POPUP.StartVisit, 1),
          continueVisitBtn: new Element(CHOOSE_POPUP.ContinueVisit, 2),
          justViewBtn: new Element(CHOOSE_POPUP.JustView, 3),
          visible: false,
          title: new Element(CHOOSE_POPUP.ChooseAction),
        },
      },
    };
    this.parentData = {
      startTime: 0,
      dentistId: null,
      dentalPointId: null,
    };
    this.actions = {};
    this.childActions = {
      onDelete: (callback, key) => {
        this.actions[key] = callback;
      },
    };
    this.statusList = [];
    this.state = {
      clinicId: props.clinicId,
      earlier: false,
      sendSms: true,
      showEligibility: false,
      showSetTempPatientEmiratesId: false,
      emiratesId: "",
      emiratesIdError: false,
      eligibilityIdPayer: "",
      eligible: undefined,
      guardianNeeded: false,
      guardianInformed: false,
      datePickerVisible: false,
      insuranceFormVisible: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      session,
      item: lastItem,
      getMachines,
      getCategories,
      getDoctors,
      clinicId: calendarClinicId,
      selectClinicId,
      requestedAppointmentItem,
    } = this.props;
    const clinicId = _.get(session, "clinic.id");
    const permissions = _.get(session, "permissions", []);
    const showClinicSwitcher = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_CLINIC_FILTER") >= 0;

    if (showClinicSwitcher && calendarClinicId === 0) {
      selectClinicId(clinicId);
    }

    const appointmentId = +this.props.match.params.id;
    this.elements.patientProfile.chartBtn.visible = !!appointmentId;

    getCategories();
    getDoctors();

    const query = parseQuery(this.props.location.search);

    this.props.initAppoinmemt(appointmentId, query.fromFirstContact).then(() => {
      const { item } = this.props;

      if (item && item.id) {
        const earlier = query.disableEarlier !== "true" && item.earlier;
        this.setState({
          earlier,
          sendSms: item.sendSms,
          eligibilityIdPayer: item.eligibilityIdPayer,
          eligible: item.eligible,
        });

        if (_.get(item, "patient.patientKey")) {
          this.props.getPlannedTransactions(_.get(item, "patient.patientKey"), _.get(item, "id"), {
            treatmentCompleted: true,
          });
          this.props.getTooth(_.get(item, "patient.patientKey"));
        }

        this.appointmentItem = item;

        if (item.patient.patientKey) {
          this.props.showModals(
            item.patient.patientKey,
            item.date,
            _.get(item, "dentist.id"),
            item.startTime,
          );
          this.props.initRelated(appointmentId, item.patient.patientKey);
        }

        this.parentData = {};
        this.parentData.startTime = item.startTime;
        if (item && item.status) {
          this.choosePatient(item.patient);

          this.appointmentData.isEdit = AppointmentUtils.isEdit(item);
          this.appointmentData.statusCode = item.status.code;

          const statusCode = item.status.code;
          this.elements.header.saveBtn.visible = true; //should be look
          this.elements.header.resetBtn.visible = false;

          if (statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE) {
            this.elements.header.saveBtn.visible = true;
          }
          if (
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW ||
            statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED
          ) {
            this.elements.header.resetBtn.visible = true;
          }
        }
      }

      if (query.mode === "earlier") {
        this.props.history.replace({
          ...this.props.location,
          query: {
            ...query,
            canMove: "true",
          },
        });
      }

      this.getAppointmentResponse();
      this.forceUpdate();
    });

    if (query) {
      const { startTime, dentist, dentalPoint, patientKey, requestAppointmentId } = query;
      this.parentData.startTime = startTime && parseInt(startTime);
      this.parentData.dentistId = dentist;
      this.parentData.dentalPointId = dentalPoint;

      if (patientKey) {
        this.choosePatient(null, patientKey);
      }

      if (requestAppointmentId && !patientKey) {
        this.props.setRequestedAppointmentItemPatient(requestedAppointmentItem.patient);
      }

      if (_.toFinite(dentalPoint) > 0 && !lastItem) {
        getMachines(dentalPoint);
      }
    }
  }

  componentDidMount() {
    this.props.getTypes();
    this.props.getClinics();
    this.props.getLocations();
    const {
      appointmentStatusOtherEnabled = false,
      appointmentApprovalStatusEnabled = false,
      appointmentLabStatusEnabled = false,
    } = this.props.miscSettings;
    if (appointmentStatusOtherEnabled) {
      this.props.getOtherStatus();
    }
    if (appointmentApprovalStatusEnabled) {
      this.props.getApprovalStatus();
    }
    if (appointmentLabStatusEnabled) {
      this.props.getLabStatus();
    }
  }

  componentDidUpdate(prevProps) {
    const { item: nextItem, getDoctors, getSubCategories, getMachines, getCategories } = this.props;
    const { item: prevItem } = prevProps;

    if ((nextItem && prevItem && nextItem.id !== prevItem.id) || (nextItem && !prevItem)) {
      const dentalPointId = _.get(nextItem, "dentalPoint.id");
      const treatmentGroupId = _.get(nextItem, "treatmentGroup.id");
      const treatmentSubGroupId = _.get(nextItem, "treatmentSubGroup.id");
      if (nextItem.guardianInformed) {
        this.setState({
          guardianInformed: nextItem.guardianInformed,
          guardianNeeded: nextItem.guardianInformed,
        });
      }

      if (treatmentGroupId) {
        getCategories(treatmentGroupId).then(() => {
          if (treatmentSubGroupId) {
            getDoctors(treatmentSubGroupId);
            getSubCategories(treatmentSubGroupId);
          }
        });
      }

      if (dentalPointId) {
        getMachines(dentalPointId);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAppointment();
  }

  openPatientChart = (event) => {
    event.preventDefault();
    const { item, permissions, getAppointmentById } = this.props;
    if (!item.patient.patientKey) {
      alert(MSG_PATIENT_IS_NOT_REGISTERED);
      return;
    }
    if (permissions && permissions.indexOf(PERMISSIONS.VIEW_PATIENT_DENTALCHART) >= 0) {
      getAppointmentById(this.props.match.params.id).then((response) => {
        this.getAppointmentResponse(response);
        this.forceUpdate();
      });
    } else {
      alert(MSG_DONT_HAVE_PERMISSION);
    }
  };

  getAppointmentResponse = () => {
    const { item } = this.props;
    this.appointmentItem = item;
    const currentMember = this.props.members[this.props.chosenMemberId] || {};
    const isDentistOwner = _.get(item, "dentist.id") === currentMember.id;
    const isAppointmentStatusCheckedIn =
      _.get(item, "status.code") === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN;
    const isAppointmentStatusAssessment =
      _.get(item, "status.code") === STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT;
    const isAppointmentBeingSeen =
      _.get(item, "status.code") === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN;
    const isAppointmentComplete =
      _.get(item, "status.code") === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE;

    this.elements.patientProfile.popup.startVisitBtn.visible = false;
    this.elements.patientProfile.popup.continueVisitBtn.visible = false;
    this.elements.patientProfile.popup.justViewBtn.visible = false;
    if (isDentistOwner) {
      this.elements.patientProfile.popup.title.value = CHOOSE_POPUP.ChooseAction;
      if (isAppointmentStatusCheckedIn || isAppointmentStatusAssessment) {
        this.elements.patientProfile.popup.startVisitBtn.visible = true;
        this.elements.patientProfile.popup.justViewBtn.visible = true;
        this.elements.patientProfile.popup.justViewBtn.value = CHOOSE_POPUP.JustView;
      } else if (isAppointmentBeingSeen) {
        this.elements.patientProfile.popup.continueVisitBtn.visible = true;
      } else if (isAppointmentComplete) {
        this.elements.patientProfile.popup.continueVisitBtn.visible = true;
        this.elements.patientProfile.popup.continueVisitBtn.value = CHOOSE_POPUP.Edit;
      } else {
        this.elements.patientProfile.popup.justViewBtn.value = CHOOSE_POPUP.JustView;
        this.elements.patientProfile.popup.justViewBtn.visible = true;
      }
    } else {
      this.elements.patientProfile.popup.title.value = `Appointment of another ${localStorage.getItem(
        "doctorLabel",
      )}`;
      this.elements.patientProfile.popup.justViewBtn.visible = true;
    }

    //popup btn handlers
    this.elements.patientProfile.popup.startVisitBtn.handler = () => {
      if (isDentistOwner && (isAppointmentStatusCheckedIn || isAppointmentStatusAssessment)) {
        this.checkAndChangeAppointmentStatusToBeingSeenAndOpenCharting();
      } else {
        this.openChartVisit(isDentistOwner);
      }
    };
    this.elements.patientProfile.popup.continueVisitBtn.handler = this.elements.patientProfile.popup.startVisitBtn.handler;
    this.elements.patientProfile.popup.justViewBtn.handler = () => {
      this.openChartVisit(isDentistOwner, true);
    };

    this.elements.patientProfile.popup.visible = true;
  };

  checkAndChangeAppointmentStatusToBeingSeenAndOpenCharting() {
    this.changeAppointmentStatus(
      STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN,
      `${localStorage.getItem("doctorLabel")} has started visit from appointment view`,
    ).then(() => {
      this.openChartVisit(true);
    });
  }

  openChartVisit(isDentistOwner, isView) {
    const { item, permissions } = this.props;

    const appointmentId =
      permissions.indexOf("ADD_PLANNED_DENTAL_TRANSACTION_ITEM_WITHOUT_APPOINTMENT") >= 0 && item
        ? item.id
        : undefined;

    if (item && item.patient) {
      const { patientKey } = item.patient;

      this.props.history.push(
        createUrl(Routes.DashboardChart, {
          params: { type: isView ? "View" : "Visit" },
          query: {
            patientKey,
            appointmentId,
            isDentistOwner: isDentistOwner,
          },
        }),
      );
    }
  }

  changeStatus = () => {
    const { statusList, statusChangeOfAppointment, item } = this.props;

    const query = parseQuery(this.props.location.search);

    if (item) {
      const patient = item && item.patient;
      const id = item && item.id;

      return (statusCode, comment, callback) => {
        const selectBox = Utils.selectByKey(
          statusList,
          "code",
          statusCode || STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
        );

        const transfer = {
          id: id,
          status: selectBox,
          callTime: Utils.calcDateWithTimeZone().valueOf(),
        };
        if (comment) {
          transfer.comment = comment;
        }
        if (patient.patientKey) {
          transfer.patientKey = patient.patientKey;
        }
        // transfer.deviceToken = //TODO should not be empty
        return statusChangeOfAppointment(transfer).then((response) => {
          if (callback) {
            callback(response);
          } else {
            this.props.history.push(
              createUrl(Routes.DashboardCalendar, {
                query: {
                  saveSettings: true,
                  scrollLeft: query.scrollLeft,
                  scrollTop: query.scrollTop,
                },
              }),
            );
          }
        });
      };
    }
  };

  changeAppointmentStatus = (statusCode, comment, callback) => {
    const { statusList, statusChangeOfAppointment, item } = this.props;
    const patient = item && item.patient;
    const id = item && item.id;

    const query = parseQuery(this.props.location.search);

    const selectBox = Utils.selectByKey(
      statusList,
      "code",
      statusCode || STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
    );

    const transfer = {
      status: selectBox,
      callTime: Utils.calcDateWithTimeZone().valueOf(),
    };
    if (id) {
      transfer.id = id;
    }
    if (comment) {
      transfer.comment = comment;
    }
    if (patient.patientKey) {
      transfer.patientKey = patient.patientKey;
    }
    // transfer.deviceToken = //TODO should not be empty
    return statusChangeOfAppointment(transfer).then((response) => {
      if (callback) {
        callback(response);
      } else {
        this.props.history.push(
          createUrl(Routes.DashboardCalendar, {
            query: {
              saveSettings: true,
              scrollLeft: query.scrollLeft,
              scrollTop: query.scrollTop,
            },
          }),
        );
      }
    });
  };

  choosePatient = (item, patientKey) => {
    const query = parseQuery(this.props.location.search);

    if (item) {
      this.props.changeItem({ patient: item }, true);
      this.patient = item;
      this.props.choosePatient(item);
    }

    if ((item && item.patientKey) || patientKey) {
      const { checkOtherDoctor, item: appointment } = this.props;

      const data = {
        patientKey: patientKey || item.patientKey,
        date: _.toFinite(query.startTime),
        doctorId: _.toFinite(query.dentist),
      };

      if (appointment) {
        data.doctorId = _.get(appointment, "dentist.id");
        data.date = _.get(appointment, "startTime", Date.now());
      }

      checkOtherDoctor(data);

      this.props
        .getPatientDetails({ patientKey: item ? item.patientKey : patientKey })
        .then((response) => {
          this.patient = response;
          this.props.changeItem({ patient: response }, true);
          this.props.choosePatient(response);

          if (response.patientKey && !appointment) {
            this.props.showModals(
              response.patientKey,
              toFinite(query.startTime),
              toFinite(query.dentist),
              toFinite(query.startTime),
            );
          }

          this.forceUpdate();
        });
    }
  };

  updateRightFormData = (data) => {
    this.appointmentData.rightData = data;
    this.forceUpdate();
  };

  updateAppointmentItem = (data) => {
    if (data && this.appointmentItem) {
      this.appointmentItem.id = data.id;
      this.elements.patientProfile.chartBtn.visible = !!this.appointmentItem.id;
    }
    this.forceUpdate();
  };

  updatePatientFormData = (data) => {
    this.appointmentData.patientData = data;
  };

  onReset = (event) => {
    event.preventDefault();
    const { item } = this.props;

    const isHasCanceledPermission = this.props.permissions.find(
      (item) => item === "CHANGE_CLINIC_APPOINTMENT_STATUS_TO_CANCELLED",
    );
    const isBeingSeen =
      this.props.appointment.appointment.status.code === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN;
    if (isBeingSeen) {
      if (isHasCanceledPermission) {
        if (
          this.appointmentData.isEdit ||
          (item && item.patient && item.patient.companyTempPatientId)
        ) {
          this.props.deleteAppointmentModalShow();
        }
        this.actions[FORM_ACTIONS.DELETE] && this.actions[FORM_ACTIONS.DELETE](event);
      } else {
        this.props.openSimpleModal({ body: "you don't have permission" });
      }
    } else {
      if (
        this.appointmentData.isEdit ||
        (item && item.patient && item.patient.companyTempPatientId)
      ) {
        this.props.deleteAppointmentModalShow();
      }
      this.actions[FORM_ACTIONS.DELETE] && this.actions[FORM_ACTIONS.DELETE](event);
    }
  };

  openPatientProfile = (e) => {
    e.preventDefault();
    const { item } = this.props;
    if (item.patient.patientKey) {
      this.props.history.push(
        createUrl(Routes.PatientView, { params: { patientKey: item.patient.patientKey } }),
      );
    } else if (item.patient.companyTempPatientId) {
      this.props.setTemporaryCompanyId(item.patient.companyTempPatientId);
      this.props.history.push(
        createUrl(Routes.PatientView, {
          params: { patientKey: "temp" },
          query: {
            companyTempPatientId: item.patient.companyTempPatientId,
          },
        }),
      );
    }
  };

  openInsuranceDetails = (e) => {
    e.preventDefault();
    const { item } = this.props;
    if (item.patient && item.patient.chartNumber) {
      this.props.history.push(Routes.DashboardInsurance);
    } else {
      alert("Patient is not registered yet");
    }
  };

  onSave = (event, checkedInBtn) => {
    event.preventDefault();

    const query = parseQuery(this.props.location.search);

    const appointmentScreenType = _.get(
      this.props,
      "clinic.appointmentScreenType.code",
      AppointmentScreenTypes.SimpleScreen,
    );

    if (query.mode === "follow") {
      const rightElements = this.appointmentData.rightData.elements;

      const patientKey = _.get(this.props, "appointment.patient.patientKey", null);
      const companyTempPatientId = _.get(
        this.props,
        "appointment.item.patient.companyTempPatientId",
        null,
      );
      const doctor = Utils.convertAsSelectItem(rightElements.dentistSB.selected);
      const dentalPoint = Utils.convertAsSelectItem(rightElements.dentalPointSB.selected);

      if (doctor) {
        const selectId = _.get(this.props, "repeatAppointment.selectType");
        const type = _.head(_.filter(this.props.repeatAppointment.types, (x) => x.id === selectId));

        this.props
          .createFollowUpAppointment({
            patientKey,
            companyTempPatientId,
            date:
              Utils.getStartDay(this.appointmentData.rightData.elements.date.value.valueOf()) +
              12 * 60 * 60 * 1000,
            dentalPoint,
            doctor,
            type,
            note: this.props.repeatAppointment.note,
            note2: this.props.repeatAppointment.note_02,
          })
          .then((response) => {
            if (response) {
              this.props.history.push(Routes.DashboardFollowUp);
            }
          });
      } else {
        return this.props.openSimpleModal({
          body: "patient required",
        });
      }
    } else {
      const { item } = this.props;

      this.appointmentData.isEdit = AppointmentUtils.isEdit(item);
      const rightElements = this.appointmentData.rightData.elements;

      if (this.appointmentData.isEdit) {
        this.appointmentData.statusCode = item.status.code;
        if (
          (this.appointmentData.isEdit &&
            this.appointmentData.statusCode !==
              this.appointmentData.rightData.elements.statusSB.selected.code) ||
          checkedInBtn
        ) {
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED
          ) {
            this.props.openReasonModal();
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_MISSED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_MISSED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_VOIDED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_VOIDED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW
          ) {
            this.props.openReasonModal(STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW);
            return;
          }
          if (
            this.appointmentData.rightData.elements.statusSB.selected.code ===
            STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED
          ) {
            this.props.setAppointmentPlannedTreatmentModalVisible(true);
            return;
          }

          if (
            STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN ||
            checkedInBtn === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN
          ) {
            const patientKey = _.get(this.props, "appointment.patient.patientKey", null);
            let status = Utils.convertAsSelectItem(rightElements.statusSB.selected);
            if (checkedInBtn) {
              const checkedInStatus = rightElements.statusSB.options.find(
                (statusSB) => statusSB.code === checkedInBtn,
              );
              status = Utils.convertAsSelectItem(checkedInStatus);
            }
            const statusOther = Utils.convertAsSelectItem(rightElements.statusOther.selected);
            const approvalStatus = Utils.convertAsSelectItem(rightElements.approvalStatus.selected);
            const labStatus = Utils.convertAsSelectItem(rightElements.labStatus.selected);
            const meetingType = Utils.convertAsSelectItem(rightElements.meetingType.selected);
            const paymentStatus = Utils.convertAsSelectItem(rightElements.paymentStatus.selected);
            const category = Utils.convertAsSelectItem(rightElements.categorySB.selected);
            const location = Utils.convertAsSelectItem(rightElements.locationSB.selected);
            const masterType = Utils.convertAsSelectItem(rightElements.masterTypeSB.selected);
            const dentist = Utils.convertAsSelectItem(rightElements.dentistSB.selected);
            const dentalPoint = Utils.convertAsSelectItem(rightElements.dentalPointSB.selected);
            const duration = rightElements.lengthSB.selectedId;
            const startTime = _.get(rightElements, "freeTimesSB.selected.id", 0);
            const date = getCompanyStartOfDay(startTime || rightElements.date.value);

            const patient = this.appointmentData.patientData;
            const { selected: machine } = rightElements.machinesSB;
            const transfer = {
              startTime: parseInt(startTime),
              duration: parseInt(duration),
              date,
              patientKey,
              status,
              statusOther,
              approvalStatus,
              labStatus,
              meetingType,
              paymentStatus,
              category,
              location,
              masterType,
              dentist,
              dentalPoint,
              patient,
              id: item.id,
              note: rightElements.note.value,
              additionalPrice:
                rightElements.additionalPrice.value && Number(rightElements.additionalPrice.value),
              doctorNote: rightElements.doctorNote.value,
              eligibilityIdPayer: this.state.eligibilityIdPayer,
              eligible: this.state.eligible,
            };

            if (machine && machine.id && machine.id !== -100) {
              transfer.machine = machine;
            }

            if (this.props.appointment.moved) transfer.moved = true;

            this.saveAppointment(transfer);
            return;
          }
        }
      }

      const { fromFirstContact } = query;
      const { temporaryPatientData } = this.props;
      if (
        !this.appointmentData.patientData ||
        this.appointmentData.patientData.mobilePhoneNumber.length < 7
      ) {
        return this.props.openSimpleModal({
          body: "phone number required",
        });
      }

      if (!this.appointmentData.patientData && !fromFirstContact) {
        return this.props.openSimpleModal({
          body: "patient required",
        });
      }
      if (!this.appointmentData.patientData || !this.appointmentData.patientData.patientKey) {
        if (
          (!this.appointmentData.patientData || !this.appointmentData.patientData.firstName) &&
          !(temporaryPatientData && temporaryPatientData.firstName)
        ) {
          return this.props.openSimpleModal({
            body: "first name required",
          });
        }

        if (
          (!this.appointmentData.patientData || !this.appointmentData.patientData.lastName) &&
          !(temporaryPatientData && temporaryPatientData.lastName)
        ) {
          return this.props.openSimpleModal({
            body: "last name required",
          });
        }

        if (
          (!this.appointmentData.patientData ||
            !this.appointmentData.patientData.mobilePhoneNumber) &&
          !(temporaryPatientData && temporaryPatientData.phoneMiddle)
        ) {
          return this.props.openSimpleModal({
            body: "phone number required",
          });
        }

        let testCountry;
        let testPhone;

        if (this.props.SearchPatientForm) {
          const phoneNumber = _.get(this.props, "SearchPatientForm.values.phoneNumber", "");

          testCountry = _.get(phoneNumber, "countryCode.value");
          testPhone = _.get(phoneNumber, "number");
        } else if (temporaryPatientData && temporaryPatientData.mobilePhoneNumber) {
          testCountry = temporaryPatientData.mobilePhoneNumber.country;
          testPhone = temporaryPatientData.mobilePhoneNumber.phone;
        } else if (
          this.appointmentData &&
          this.appointmentData.patientData &&
          this.appointmentData.patientData.mobilePhoneNumber
        ) {
          testCountry = 123;
          testPhone = 123;
        }

        if (!testCountry) {
          return this.props.openSimpleModal({
            body: "phone number required",
          });
        }
        if (!testPhone) {
          return this.props.openSimpleModal({
            body: "phone number required",
          });
        }
      }

      if ((this.appointmentData && this.appointmentData.patientData) || temporaryPatientData) {
        let hasError = false;
        const status = Utils.convertAsSelectItem(rightElements.statusSB.selected);
        const category = Utils.convertAsSelectItem(rightElements.categorySB.selected);
        const statusOther = Utils.convertAsSelectItem(rightElements.statusOther.selected);
        const approvalStatus = Utils.convertAsSelectItem(rightElements.approvalStatus.selected);
        const labStatus = Utils.convertAsSelectItem(rightElements.labStatus.selected);
        const meetingType = Utils.convertAsSelectItem(rightElements.meetingType.selected);
        const paymentStatus = Utils.convertAsSelectItem(rightElements.paymentStatus.selected);
        const location = Utils.convertAsSelectItem(rightElements.locationSB.selected);
        const masterType = Utils.convertAsSelectItem(rightElements.masterTypeSB.selected);
        const dentist = Utils.convertAsSelectItem(rightElements.dentistSB.selected);
        const dentalPoint =
          this.props.allowAppointmentMultiBooking &&
          rightElements.freeTimesSB.selected.dentalPointId
            ? { id: rightElements.freeTimesSB.selected.dentalPointId }
            : Utils.convertAsSelectItem(rightElements.dentalPointSB.selected);
        const duration = rightElements.lengthSB.selectedId;
        const startTime = _.get(rightElements, "freeTimesSB.selected.id", 0);
        const date = getCompanyStartOfDay(startTime);

        let patient = this.appointmentData.patientData;
        if (patient && this.appointmentData.patientData.genderSB && !fromFirstContact) {
          patient = {};
          hasError = !this.appointmentData.patientData.isValid;
          Utils.objectForEach(this.appointmentData.patientData, (value, key) => {
            if (key !== "genderSB" && key !== "isValid" && value) {
              patient[key] = value;
            }
          });
          patient.dateOfBirth =
            this.props.patientSelectedBirthDate && this.props.patientSelectedBirthDate.valueOf();
          patient.gender = this.appointmentData.patientData.genderSB.selected;
        }

        if (
          !startTime &&
          this.appointmentData.rightData.elements.statusSB.selected.code !==
            "APPOINTMENT_STATUS_IN_WAITING"
        ) {
          return this.props.openSimpleModal({
            body: "Wrong timeslot.",
          });
        }

        if (!Utils.isValidSelectItem(rightElements.statusSB)) {
          return this.props.openSimpleModal({
            body: "Status required",
          });
        }

        if (
          rightElements.masterTypeSB.selected.code !== "PHONE_CONSULTATION" &&
          !Utils.isValidSelectItem(rightElements.categorySB)
        ) {
          return this.props.openSimpleModal({
            body: "Type required",
          });
        }

        if (
          this.props.miscSettings.mandatoryAppointmentLocationField &&
          !Utils.isValidSelectItem(rightElements.locationSB)
        ) {
          return this.props.openSimpleModal({
            body: "Location is required",
          });
        }

        if (
          this.props.miscSettings.mandatoryAppointmentOtherStatusField &&
          !Utils.isValidSelectItem(rightElements.statusOther)
        ) {
          return this.props.openSimpleModal({
            body: "Other status is required",
          });
        }

        if (
          this.props.miscSettings.appointmentMeetingTypeMandatory &&
          !Utils.isValidSelectItem(rightElements.meetingType)
        ) {
          return this.props.openSimpleModal({
            body: "Meeting type is required",
          });
        }

        if (
          this.props.miscSettings.appointmentPaymentStatusMandatory &&
          !Utils.isValidSelectItem(rightElements.paymentStatus)
        ) {
          return this.props.openSimpleModal({
            body: "Payment status is required",
          });
        }

        if (
          this.props.miscSettings.mandatoryAppointmentMasterTypeField &&
          !Utils.isValidSelectItem(rightElements.masterTypeSB)
        ) {
          return this.props.openSimpleModal({
            body: "Master type is required",
          });
        }

        if (
          appointmentScreenType === AppointmentScreenTypes.SimpleScreen &&
          !Utils.isValidSelectItem(rightElements.dentistSB)
        ) {
          return this.props.openSimpleModal({
            body: `${localStorage.getItem("doctorLabel")} required`,
          });
        }

        if (!Utils.isValidSelectItem(rightElements.dentalPointSB)) {
          return this.props.openSimpleModal({
            body: "Trx. Room required",
          });
        }

        let patientObject = {};

        if (temporaryPatientData) {
          patientObject = {
            firstName:
              !this.appointmentData.patientData || !this.appointmentData.patientData.firstName
                ? temporaryPatientData.firstName
                : this.appointmentData.patientData.firstName,
            lastName:
              !this.appointmentData.patientData || !this.appointmentData.patientData.lastName
                ? temporaryPatientData.lastName
                : this.appointmentData.patientData.lastName,
            email: temporaryPatientData.email,
            mobilePhoneNumber: temporaryPatientData.mobilePhoneNumber,
            companyTempPatientId: temporaryPatientData.companyTempPatientId,
          };

          if (
            temporaryPatientData.mobilePhoneNumber &&
            typeof temporaryPatientData.mobilePhoneNumber !== "string"
          ) {
            patientObject.mobilePhoneNumber = `+${temporaryPatientData.mobilePhoneNumber.country.value} (${temporaryPatientData.mobilePhoneNumber.town}) ${temporaryPatientData.mobilePhoneNumber.phone}`;
          }

          if (
            !temporaryPatientData.mobilePhoneNumber ||
            _.isEmpty(temporaryPatientData.mobilePhoneNumber)
          ) {
            patientObject.mobilePhoneNumber =
              this.appointmentData.patientData &&
              this.appointmentData.patientData.mobilePhoneNumber;
          }
        } else if (patient) {
          patientObject = {
            chartNumber: patient.chartNumber,
            gender: patient.gender,
            patientKey: patient.patientKey,
            firstName: patient.firstName,
            lastName: patient.lastName,
            email: patient.email,
            mobilePhoneNumber: patient.mobilePhoneNumber,
            workPhoneNumber: patient.workPhoneNumber,
            homePhoneNumber: patient.homePhoneNumber,
            dateOfBirth: patient.dateOfBirth,
            photo: patient.photo,
            companyPatientId: patient.companyPatientId,
            companyTempPatientId: patient.companyTempPatientId,
          };
          Utils.objectForEach(patientObject, (value, key) => {
            if (!value) {
              delete patientObject[key];
            }
          });
        }

        if (!hasError) {
          const creator = rightElements.isNew
            ? this.props.member
            : rightElements.creator && {
                id: rightElements.creator.id,
                name: rightElements.creator.name
                  ? rightElements.creator.name
                  : `${rightElements.creator.firstName} ${rightElements.creator.lastName}`,
              };

          const transfer = {
            startTime: parseInt(startTime),
            duration: parseInt(duration),
            note: rightElements.note.value,
            doctorNote: rightElements.doctorNote.value,
            eligibilityIdPayer: this.state.eligibilityIdPayer,
            eligible: this.state.eligible,
            patient: patientObject,
            additionalPrice:
              rightElements.additionalPrice.value && Number(rightElements.additionalPrice.value),
          };

          if (appointmentScreenType === AppointmentScreenTypes.AdvancedScreen) {
            const { selected: machine } = rightElements.machinesSB;

            if (machine && machine.id && machine.id !== -100) {
              transfer.machine = machine;
            }
            transfer.treatmentGroup = rightElements.categoryServiceSB.selected;
            transfer.treatmentSubGroup = rightElements.subCategoryServiceSB.selected;
            transfer.treatmentSubSubGroup = rightElements.subSubCategoryServiceSB.selected;
            transfer.dentist = rightElements.doctorsSB.selected;
          }

          if (appointmentScreenType === AppointmentScreenTypes.SimpleScreen) {
            const { selected: machine } = rightElements.machinesSB;

            if (machine && machine.id && machine.id !== -100) {
              transfer.machine = machine;
            }
            if (masterType && masterType.id) transfer.masterType = masterType;
            if (dentist && dentist.id) transfer.dentist = dentist;
          }

          transfer.date = date;

          if (location) {
            transfer.location = location;
          }
          if (statusOther) {
            transfer.statusOther = statusOther;
          }
          if (approvalStatus) {
            transfer.approvalStatus = approvalStatus;
          }
          if (labStatus) {
            transfer.labStatus = labStatus;
          }
          if (meetingType) {
            transfer.meetingType = meetingType;
          }
          if (paymentStatus) {
            transfer.paymentStatus = paymentStatus;
          }

          if (status && status.id) transfer.status = status;
          if (category && category.id) transfer.category = category;
          if (dentalPoint && dentalPoint.id) transfer.dentalPoint = dentalPoint;

          if (this.appointmentData.isEdit) {
            transfer.id = item.id;
            transfer.modifiedDate = Utils.calcDateWithTimeZone().valueOf();
          } else {
            transfer.creator = creator;
            transfer.createdDate = Utils.calcDateWithTimeZone().valueOf();
          }

          if (query.fromFirstContact) {
            if (!temporaryPatientData.companyTempPatientId) {
              transfer.fromFirstContact = true;
              transfer.consultationNote = {
                note: this.props.consultationNote,
              };
            }
          }

          if (this.props.switchers.prices.checked) {
            transfer.cashTreatmentPriceId =
              this.props.switchers.prices.value || this.props.switchers.prices.defaultValue;
          }

          if (this.props.nurse) transfer.nurse = { id: this.props.nurse };

          if (this.props.appointment.moved) transfer.moved = true;

          if (status.code === "APPOINTMENT_STATUS_IN_WAITING") {
            transfer.date = getCompanyStartOfDay(startTime) + 12 * 60 * 60 * 1000;
          }

          if (
            transfer.status &&
            transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE &&
            transfer.masterType &&
            transfer.masterType.code === "PHONE_CONSULTATION"
          ) {
            this.changeAppointmentStatus("APPOINTMENT_STATUS_COMPLETE");
          } else if (
            transfer.status &&
            transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE
          ) {
            this.goToCalendar();
          } else {
            this.saveAppointment(transfer);
          }
        }
      }
    }
  };

  onRequest = (event) => {
    event.preventDefault();
    const { item } = this.props;

    const query = parseQuery(this.props.location.search);

    this.appointmentData.isEdit = AppointmentUtils.isEdit(item);

    const rightElements = this.appointmentData.rightData.elements;

    const { fromFirstContact } = query;
    const { temporaryPatientData } = this.props;

    if (!this.appointmentData.patientData && !fromFirstContact) {
      return this.props.openSimpleModal({
        body: "patient required",
      });
    }
    if (!this.appointmentData.patientData || !this.appointmentData.patientData.patientKey) {
      if (
        (!this.appointmentData.patientData || !this.appointmentData.patientData.firstName) &&
        !(temporaryPatientData && temporaryPatientData.firstName)
      ) {
        return this.props.openSimpleModal({
          body: "first name required",
        });
      }

      if (
        (!this.appointmentData.patientData || !this.appointmentData.patientData.lastName) &&
        !(temporaryPatientData && temporaryPatientData.lastName)
      ) {
        return this.props.openSimpleModal({
          body: "last name required",
        });
      }

      let phoneArr;
      let testCountry;
      let testPhone;

      if (this.appointmentData.patientData) {
        phoneArr = Utils.toPhoneArray(this.appointmentData.patientData.mobilePhoneNumber);

        testCountry = +phoneArr[0];
        testPhone = +phoneArr[2];
      } else if (temporaryPatientData && temporaryPatientData.mobilePhoneNumber) {
        testCountry = temporaryPatientData.mobilePhoneNumber.country;
        testPhone = temporaryPatientData.mobilePhoneNumber.phone;
      }

      if (!testCountry) {
        return this.props.openSimpleModal({
          body: "phone number required",
        });
      }
      if (!testPhone) {
        return this.props.openSimpleModal({
          body: "phone number required",
        });
      }
    }

    if ((this.appointmentData && this.appointmentData.patientData) || temporaryPatientData) {
      let hasError = false;

      const category = Utils.convertAsSelectItem(rightElements.categorySB.selected);
      const masterType = Utils.convertAsSelectItem(rightElements.masterTypeSB.selected);
      const dentist = Utils.convertAsSelectItem(rightElements.dentistSB.selected);
      const dentalPoint = Utils.convertAsSelectItem(rightElements.dentalPointSB.selected);
      const duration = rightElements.lengthSB.selectedId;
      const startTime = rightElements.freeTimesSB.selected && rightElements.freeTimesSB.selected.id;
      const date = Utils.getStartDay(this.appointmentData.rightData.elements.date.value.valueOf());
      // const date = startTime;
      let patient = this.appointmentData.patientData;
      if (patient && this.appointmentData.patientData.genderSB && !fromFirstContact) {
        patient = {};
        hasError = !this.appointmentData.patientData.isValid;
        Utils.objectForEach(this.appointmentData.patientData, (value, key) => {
          if (key !== "genderSB" && key !== "isValid" && value) {
            patient[key] = value;
          }
        });
        patient.dateOfBirth =
          this.props.patientSelectedBirthDate && this.props.patientSelectedBirthDate.valueOf();
        patient.gender = this.appointmentData.patientData.genderSB.selected;
      }

      if (!Utils.isValidSelectItem(rightElements.statusSB)) {
        return this.props.openSimpleModal({
          body: "Status required",
        });
      }

      if (
        rightElements.masterTypeSB.selected.code !== "PHONE_CONSULTATION" &&
        !Utils.isValidSelectItem(rightElements.categorySB)
      ) {
        return this.props.openSimpleModal({
          body: "Type required",
        });
      }

      if (
        this.props.miscSettings.mandatoryAppointmentLocationField &&
        !Utils.isValidSelectItem(rightElements.locationSB)
      ) {
        return this.props.openSimpleModal({
          body: "Location is required",
        });
      }

      if (
        this.props.miscSettings.mandatoryAppointmentOtherStatusField &&
        !Utils.isValidSelectItem(rightElements.statusOther)
      ) {
        return this.props.openSimpleModal({
          body: "Other status is required",
        });
      }

      if (
        this.props.miscSettings.appointmentMeetingTypeMandatory &&
        !Utils.isValidSelectItem(rightElements.meetingType)
      ) {
        return this.props.openSimpleModal({
          body: "Meeting type is required",
        });
      }

      if (
        this.props.miscSettings.appointmentPaymentStatusMandatory &&
        !Utils.isValidSelectItem(rightElements.paymentStatus)
      ) {
        return this.props.openSimpleModal({
          body: "Payment status is required",
        });
      }

      if (!Utils.isValidSelectItem(rightElements.dentistSB)) {
        return this.props.openSimpleModal({
          body: `${localStorage.getItem("doctorLabel")} required`,
        });
      }

      if (!Utils.isValidSelectItem(rightElements.dentalPointSB)) {
        return this.props.openSimpleModal({
          body: "Trx. Room required",
        });
      }

      let patientObject = {};

      if (temporaryPatientData) {
        patientObject = {
          firstName:
            !this.appointmentData.patientData || !this.appointmentData.patientData.firstName
              ? temporaryPatientData.firstName
              : this.appointmentData.patientData.firstName,
          lastName:
            !this.appointmentData.patientData || !this.appointmentData.patientData.lastName
              ? temporaryPatientData.lastName
              : this.appointmentData.patientData.lastName,
          email: temporaryPatientData.email,
          mobilePhoneNumber: temporaryPatientData.mobilePhoneNumber,
          companyTempPatientId: temporaryPatientData.companyTempPatientId,
        };

        if (
          temporaryPatientData.mobilePhoneNumber &&
          typeof temporaryPatientData.mobilePhoneNumber !== "string"
        ) {
          patientObject.mobilePhoneNumber = `+${temporaryPatientData.mobilePhoneNumber.country.value} (${temporaryPatientData.mobilePhoneNumber.town}) ${temporaryPatientData.mobilePhoneNumber.phone}`;
        }

        if (
          !temporaryPatientData.mobilePhoneNumber ||
          _.isEmpty(temporaryPatientData.mobilePhoneNumber)
        ) {
          patientObject.mobilePhoneNumber =
            this.appointmentData.patientData && this.appointmentData.patientData.mobilePhoneNumber;
        }
      } else if (patient) {
        patientObject = {
          chartNumber: patient.chartNumber,
          gender: patient.gender,
          patientKey: patient.patientKey,
          firstName: patient.firstName,
          lastName: patient.lastName,
          email: patient.email,
          mobilePhoneNumber: patient.mobilePhoneNumber,
          workPhoneNumber: patient.workPhoneNumber,
          homePhoneNumber: patient.homePhoneNumber,
          dateOfBirth: patient.dateOfBirth,
          photo: patient.photo,
          companyPatientId: patient.companyPatientId,
        };
        Utils.objectForEach(patientObject, (value, key) => {
          if (!value) {
            delete patientObject[key];
          }
        });
      }

      if (!hasError) {
        const creator = rightElements.creator && {
          id: rightElements.creator.id,
          name: rightElements.creator.name
            ? rightElements.creator.name
            : `${rightElements.creator.firstName} ${rightElements.creator.lastName}`,
        };

        const transfer = {
          date: parseInt(date),
          startTime: parseInt(startTime),
          duration: parseInt(duration),
          note: rightElements.note.value,
          doctorNote: rightElements.doctorNote.value,
          eligibilityIdPayer: this.state.eligibilityIdPayer,
          eligible: this.state.eligible,
          patient: patientObject,
          createSourceCode: "FROM_REQUEST_APPOINTMENT",
        };

        transfer.status = {
          code: "APPOINTMENT_STATUS_IN_WAITING",
        };

        if (dentist && dentist.id) transfer.dentist = dentist;
        if (category && category.id) transfer.category = category;
        if (masterType && masterType.id) transfer.masterType = masterType;
        if (dentalPoint && dentalPoint.id) transfer.dentalPoint = dentalPoint;

        transfer.creator = creator;
        transfer.createdDate = Utils.calcDateWithTimeZone().valueOf();

        if (query.fromFirstContact) {
          if (!temporaryPatientData.companyTempPatientId) {
            transfer.fromFirstContact = true;
            transfer.consultationNote = {
              note: this.props.consultationNote,
            };
          }
        }

        if (this.props.switchers.prices.checked) {
          transfer.cashTreatmentPriceId =
            this.props.switchers.prices.value || this.props.switchers.prices.defaultValue;
        }

        if (this.props.nurse) transfer.nurse = { id: this.props.nurse };

        if (this.props.appointment.moved) transfer.moved = true;

        this.saveAppointment(transfer);
      }
    }
  };

  saveAppointment = (transfer) => {
    const { clinicId, earlier, sendSms, guardianNeeded, guardianInformed } = this.state;
    const {
      permissions,
      miscSettings: { additionalDoctorsAppointmentEnabled },
      appointment,
    } = this.props;
    const query = parseQuery(this.props.location.search);
    const showClinicSwitcher =
      permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM_FOR_ANY_CLINIC") >= 0;
    if (showClinicSwitcher) {
      transfer.clinicId = clinicId;
    }

    if (query.requestAppointmentId) {
      transfer.requestAppointmentId = query.requestAppointmentId;
    }

    const isCurrentDay = moment().diff(transfer.startTime, "days") >= 0;

    const setEarlier = () => {
      if (
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW ||
        transfer.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED
      ) {
        return false;
      } else return earlier;
    };
    transfer.inBoxDragged = false;
    transfer.earlier = setEarlier();
    // transfer.sendSms = transfer.moved && !isCurrentDay ? false : Boolean(sendSms);
    transfer.sendSms = Boolean(sendSms);
    if (transfer.patient && transfer.patient.patientKey) {
      transfer.patient = { patientKey: transfer.patient.patientKey };
    }
    if (additionalDoctorsAppointmentEnabled) {
      // if (!dentistWithDentalPoints.length) {
      //   return this.props.openSimpleModal({ body: "Please select a room" });
      // }
      const { additionalDoctors } = appointment;
      transfer.guardianNeeded = guardianNeeded;
      transfer.guardianInformed = guardianInformed;
      transfer.linkedItems = additionalDoctors.map((item) => {
        return {
          ...transfer,
          startTime: item.time.value,
          date: Utils.getStartDay(item.date.value.valueOf()),
          dentist: { id: item.doctor.value },
          dentalPoint: { id: item.point.value },
        };
      });
      if (transfer.moved && appointment?.item?.linkedItems) {
        transfer.linkedItems = appointment.item.linkedItems.map((linkedItem) => ({
          ...linkedItem,
          startTime: transfer.startTime,
          status: transfer.status,
          category: transfer.category,
          duration: transfer.duration,
          moved: transfer.moved,
        }));
      }
    }

    this.props.saveAppointment(transfer).then((data) => {
      if (this.props.folderItem?.id) return;

      if (data.id) {
        this.goToCalendar();
      }
    });
  };

  onSavePlanAppointments = () => {
    const { appointment, clinic } = this.props;
    const { clinicId } = this.state;
    const {
      appointmentTreatmentsPlanValues,
      item: { patient },
    } = appointment;
    const rightElements = this.appointmentData.rightData.elements;
    const transfer = { clinicId: clinicId || clinic.id };
    const creator = rightElements.creator && {
      id: rightElements.creator.id,
      name: rightElements.creator.name
        ? rightElements.creator.name
        : `${rightElements.creator.firstName} ${rightElements.creator.lastName}`,
    };
    const patientObject = {
      chartNumber: patient.chartNumber,
      gender: patient.gender,
      patientKey: patient.patientKey,
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      mobilePhoneNumber: patient.mobilePhoneNumber,
      workPhoneNumber: patient.workPhoneNumber,
      homePhoneNumber: patient.homePhoneNumber,
      dateOfBirth: patient.dateOfBirth,
      photo: patient.photo,
      companyPatientId: patient.companyPatientId,
    };
    Utils.objectForEach(patientObject, (value, key) => {
      if (!value) {
        delete patientObject[key];
      }
    });
    transfer.appointments = appointmentTreatmentsPlanValues.map((item) => {
      const status = rightElements.statusSB.options.find((x) => item.status.value === x.id);
      return {
        ...transfer,
        creator,
        status,
        patient: patientObject,
        duration: rightElements.lengthSB.selectedId,
        startTime: item.time.value,
        date: Utils.getStartDay(item.date.value.valueOf()),
        dentist: { id: item.doctor.value },
        dentalPoint: { id: item.point.value },
        category: { id: item.type.value },
      };
    });
    this.props.saveAppointment(transfer).then((data) => {
      if (data.appointments && data.appointments.length) {
        this.goToCalendar();
      }
    });
  };

  goToCalendar = () => {
    const query = parseQuery(this.props.location.search);

    if (_.get(query, "goBack", false)) {
      this.props.history.goBack();
    } else {
      this.props.history.push(
        createUrl(Routes.DashboardCalendar, {
          query: {
            saveSettings: true,
            scrollLeft: query.scrollLeft,
            scrollTop: query.scrollTop,
          },
        }),
      );
    }
  };

  goBack = (redirectMode) => {
    const { history } = this.props;

    if (redirectMode === "earlier" || redirectMode === "referral") {
      history.goBack();
    } else {
      history.push(Routes.DashboardFollowUp);
    }
  };

  saveEmiratesId = () => {
    const { emiratesId } = this.state;
    const id = emiratesId.replace(/[^\d]/g, "");
    if (!id) {
      return false;
    }
    if (id.length !== 15) {
      this.setState({ emiratesIdError: true });
      return false;
    }
    this.props.setAppointmentPatientEmiratesId(id);
    this.setState({ showSetTempPatientEmiratesId: false, emiratesIdError: false });
    this.setState({ showEligibility: true });
  };

  render() {
    const {
      appointment,
      clinicalNotesSideBarViewType,
      permissions,
      masterTypeEnabled,
      chartSideDocs,
      hideSideBar,
      chart: { sideBarVisible, patientLabelModalVisible, patientLabel, chartBillingBalanceValue },
      viewType,
      params,
    } = this.props;
    const { item } = appointment;

    const typesOptions = this.props.repeatAppointment.types.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const query = parseQuery(this.props.location.search);

    const redirectMode = query.mode;

    const isFollow = redirectMode === "follow";
    const isEarlier = redirectMode === "earlier";
    const isReferral = redirectMode === "referral";
    if (query.patientKey && !this.patient && !this.props.fetchingPatientInfo) {
      this.choosePatient(null, query.patientKey);
    }

    const patient = appointment.appointment?.patient || appointment.patient;
    const checkEligibilityVisible = this.props.miscSettings.openJetEligibilityEnabled;
    const canViewContacts = permissions.includes("VIEW_PATIENT_CONTACT_DETAILS");
    return (
      <div className="appointment-form-page">
        <Modal
          size="sm"
          title="Set Emirates ID"
          actions={
            <div>
              <Button
                onClick={() => {
                  this.setState({ showSetTempPatientEmiratesId: false, emiratesId: "" });
                  this.props.setAppointmentPatientEmiratesId("");
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.saveEmiratesId}>Save</Button>
            </div>
          }
          show={this.state.showSetTempPatientEmiratesId}
          keyboard={false}
        >
          <input
            value={this.state.emiratesId}
            className="form-control"
            placeholder="emirates ID"
            onChange={({ target }) => {
              this.setState({ emiratesId: Utils.nationalIdNormalize(target.value) });
              if (target.value.length === 18) {
                this.setState({ emiratesIdError: false });
              }
            }}
          />
          {this.state.emiratesIdError ? <p style={{ color: "red" }}>invalid ID</p> : null}
        </Modal>
        <form className="appointment-form" onSubmit={this.onSave}>
          <AppointmentHeader
            onReset={this.onReset}
            notSendSmsForAppointmentsEnabled={
              this.props.miscSettings.notSendSmsForAppointmentsEnabled
            }
            earlierState={this.state.earlier}
            onChangeEarlierState={(x) => this.setState({ earlier: x })}
            sendSmsState={this.state.sendSms}
            onChangeSendSmsState={(x) => this.setState({ sendSms: x })}
            elements={this.elements.header}
            initialClinicId={this.state.clinicId}
            showFilterClinic={Number(_.get(params, "id")) === 0}
            onBack={() =>
              isEarlier || isReferral || isFollow ? this.goBack(redirectMode) : this.goToCalendar()
            }
            onFilterClinicChanged={(value) => {
              this.setState({ clinicId: value });
              this.props.getDoctors(undefined, undefined, value);
            }}
          />
          <LeftSide
            patientLabelModalVisible={patientLabelModalVisible}
            patientLabel={patientLabel}
            eligibilityIdPayer={this.state.eligibilityIdPayer}
            eligibilityStatus={this.state.eligible}
            setEligibilityStatus={(eligible) => this.setState({ eligible })}
            setEligibilityIdPayer={(eligibilityIdPayer) => this.setState({ eligibilityIdPayer })}
            onShowEligibilityDetails={() => {
              if (item.patient?.documentNationalIdNumber) {
                this.setState({ showEligibility: true });
              } else {
                this.setState({ showSetTempPatientEmiratesId: true });
              }
            }}
            onShowInsuranceForm={() => this.setState({ insuranceFormVisible: true })}
            checkEligibilityVisible={checkEligibilityVisible}
            showEligibility={this.state.showEligibility}
            history={this.props.history}
            location={this.props.location}
            elements={this.elements}
            choosePatient={this.choosePatient}
            patient={item ? item.patient : null}
            appointmentItem={item}
            canViewContacts={canViewContacts}
            openPatientChart={this.openPatientChart}
            openInsuranceDetails={this.openInsuranceDetails}
            showSideBar={this.props.showSideBar}
            openPatientProfile={this.openPatientProfile}
            updatePatientFormData={this.updatePatientFormData}
            temporaryPatientData={this.props.temporaryPatientData}
            fromFirstContact={query.fromFirstContact}
            createPhoneNumber={query.createPhoneNumber}
            chartBillingBalanceValue={chartBillingBalanceValue}
          />
          <RightSide
            item={item}
            clinicId={this.state.clinicId}
            isFollow={query.mode === "follow"}
            typesOptions={typesOptions}
            location={this.props.location}
            insuranceFormVisible={this.state.insuranceFormVisible}
            onShowInsuranceForm={() => this.setState({ insuranceFormVisible: false })}
            setEligibilityIdPayer={(eligibilityIdPayer) => this.setState({ eligibilityIdPayer })}
            setEligibilityStatus={(eligible) => this.setState({ eligible })}
            onHideEligibilityDetails={() => this.setState({ showEligibility: false })}
            showEligibility={this.state.showEligibility}
            changeAppointmentStatus={this.changeStatus()}
            changeStatus={this.changeAppointmentStatus}
            selectItem={this.selectItem}
            childActions={this.childActions}
            appointmentId={+this.props.match.params.id}
            updateRightFormData={this.updateRightFormData}
            updateAppointmentItem={this.updateAppointmentItem}
            goToCalendar={this.goToCalendar}
            parentData={this.parentData}
            permissions={permissions}
            masterTypeEnabled={masterTypeEnabled}
            onRequest={this.onRequest}
            onSave={this.onSave}
            onSavePlanAppointments={this.onSavePlanAppointments}
            guardianNeeded={this.state.guardianNeeded}
            guardianInformed={this.state.guardianInformed}
            setGuardianNeeded={(guardianNeeded) => this.setState({ guardianNeeded })}
            setGuardianInformed={(guardianInformed) =>
              this.props.setGuardianInformed(guardianInformed).then((response) => {
                this.setState({ guardianInformed: response.guardianInformed });
              })
            }
            datePickerVisible={this.state.datePickerVisible}
          />
        </form>
        <AppointmentReasonModal
          reasonModalVisible={this.props.reasonModalVisible}
          changeAppointmentStatus={this.changeAppointmentStatus}
          closeReasonModal={this.props.closeReasonModal}
          status={this.props.reasonModalStatus}
        />
        <AppointmentPlannedTreatmentsModal
          visible={this.props.appointmentPlannedTreatmentModalVisible}
          changeAppointmentStatus={this.changeAppointmentStatus}
        />
        <ChartTreatmentsSidebarWrapper
          inAppointment={true}
          onRequestClose={hideSideBar}
          open={sideBarVisible && viewType === ""}
          location={this.props.location}
          history={this.props.history}
        />
        {(this.props.viewType === viewTypes.clinicalNote ||
          this.props.viewType === viewTypes.vital) && (
          <ChartSideBar
            clinicalNotesSideBarViewType={clinicalNotesSideBarViewType}
            onAppointment={true}
            location={this.props.location}
            history={this.props.history}
            sideBarVisible={this.props.sideBarVisible}
            teeth={this.props.teeth}
            member={this.props.member}
            viewType={this.props.viewType}
            chartSideClinicalNote={this.props.chartSideClinicalNote}
            chartSideVital={this.props.chartSideVital}
            toothNumericType={this.props.toothNumericType}
            hideSideBar={this.props.hideSideBar}
            permissions={permissions}
            chartSideDocs={chartSideDocs}
            clinic={this.props.clinic}
            patientKey={patient?.patientKey}
          />
        )}
        <AppointmentRelatedModal
          location={this.props.location}
          history={this.props.history}
          item={item}
        />

        <AppointmentDeleteModal
          item={item}
          show={this.props.deleteAppointmentModalVisible}
          setAppointmentPlannedTreatmentModalVisible={
            this.props.setAppointmentPlannedTreatmentModalVisible
          }
        />
        <PatientInsuranceFormSidebar
          insuranceFormVisible={this.state.insuranceFormVisible}
          onShowInsuranceForm={() => this.setState({ insuranceFormVisible: false })}
          patient={item ? item.patient : null}
        />
      </div>
    );
  }
}

export default enhancer(AppointmentForm);
