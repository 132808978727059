import React from "react";
import { connect } from "react-redux";
import DataList from "../../widgets/DataList";
import { updatePrimaryClinician } from "../../../actions/patientViewActions";
import { Modal, ModalHeader } from "../../ui/Modal";
import approveImage from "../../../assets/images/profile/approve.png";

const enhancer = connect(
  ({ auth: { members } }) => ({
    list: Object.values(members),
  }),
  { updatePrimaryClinician },
);

class PatientPrimaryClinicianSidebar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      selected: props.currentId,
    };
  }

  render() {
    const { search, selected } = this.state;
    const { list, onRequestClose, updatePrimaryClinician } = this.props;

    return (
      <Modal
        show={true}
        animation={false}
        keyboard={false}
        onHide={onRequestClose}
        className="patient-select-appointment-sidebar"
        bodyProps={{ style: { padding: 0 } }}
      >
        <ModalHeader>
          <button className="btn btn-link pull-left" onClick={onRequestClose}>
            ❮ &nbsp; BACK
          </button>
          <button
            className="btn btn-link pull-right"
            onClick={() => {
              if (selected > 0) {
                onRequestClose();

                updatePrimaryClinician(selected);
              }
            }}
          >
            SAVE
          </button>
        </ModalHeader>
        <span style={{ marginBottom: "15px", fontWeight: "medium" }}>Choose Primary Clinician</span>

        <div
          style={{
            padding: "12px",
            backgroundColor: "#aaaaaa",
          }}
        >
          <input
            value={search}
            style={{
              flex: 1,
            }}
            className="form-control"
            onChange={({ target }) => this.setState({ search: target.value })}
          />
        </div>

        <DataList style={{ margin: "0 -15px" }}>
          {list
            .filter(
              (item) =>
                item.firstName
                  .toLocaleLowerCase()
                  .includes(this.state.search.toLocaleLowerCase()) ||
                item.lastName.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()),
            )
            .map(({ id, firstName, lastName }) => {
              return (
                <div
                  key={id}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    borderBottom: "1px solid #aaaaaa",
                    padding: "20px 0",
                    margin: "0 15px",
                  }}
                  onClick={() => this.setState({ selected: id })}
                >
                  <span style={{ flex: 1, fontWeight: 700, marginLeft: 16 }}>
                    {[firstName, lastName].join(" ")}
                  </span>

                  {selected === id && (
                    <img style={{ margin: "0 12px" }} alt="" src={approveImage} />
                  )}
                </div>
              );
            })}
        </DataList>
      </Modal>
    );
  }
}

export default enhancer(PatientPrimaryClinicianSidebar);
