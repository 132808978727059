import React from "react";
import Utils from "../../../helpers/Utils";
import surfaceCodes from "../../../constants/conditions/surfaceCodes";
import crowns from "../../../constants/conditions/crowns";
import ConditionTurn from "../conditions/ConditionTurn";
import ConditionShiftX from "../conditions/ConditionShiftX";
import ConditionShiftY from "../conditions/ConditionShiftY";
import ConditionShiftZ from "../conditions/ConditionShiftZ";
import ConditionToothAche from "../conditions/ConditionToothAche";
import ConditionMobilityGrade from "../conditions/ConditionMobilityGrade";
import ConditionFurcationGrade from "../conditions/ConditionFurcationGrade";
import ConditionCalculusGrade from "../conditions/ConditionCalculusGrade";
import ConditionVeneer from "../conditions/ConditionVeneer";
import ConditionSurfaceCode from "../conditions/ConditionSurfaceCode";
import ConditionFractureRoot from "../conditions/ConditionFractureRoot";
import ConditionFractureCrown from "../conditions/ConditionFractureCrown";
import ConditionCrown from "../conditions/ConditionCrown";
import ConditionFissureSealant from "../conditions/ConditionFissureSealant";
import ConditionBanding from "../conditions/ConditionBanding";
import ConditionOcclusalReduction from "../conditions/ConditionOcclusalReduction";
import ConditionPost from "../conditions/ConditionPost";
import ConditionPulpotomy from "../conditions/ConditionPulpotomy";
import ConditionShortRootCanal from "../conditions/ConditionShortRootCanal";
import ConditionPerforation from "../conditions/ConditionPerforation";
import ConditionInternalBl from "../conditions/ConditionInternalBleaching";
import ConditionApecictomy from "../conditions/ConditionApecictomy";
import ChartToothImageBase from "./ChartToothImageBase";
import ChartSurfaceImageBase from "./ChartSurfaceImageBase";

export default function ChartToothCondition(props) {
  const {
    condition,
    up,
    color,
    toothImage,
    surfaceStrings,
    toothImageProps,
    surfaceImageProps,
  } = props;

  const surfaceConditions = [];
  const toothConditions = [];

  surfaceCodes.forEach((name) => {
    Utils.objectForEach(condition[name], (boolean, surfaceString) => {
      if (boolean) {
        surfaceConditions.push(
          <ConditionSurfaceCode
            key={`${name}_${surfaceString}`}
            name={name}
            toothImage={toothImage}
            color={color}
            surfaceString={surfaceStrings.surface[surfaceString]}
            surface={true}
          />,
        );
        toothConditions.push(
          <ConditionSurfaceCode
            key={`${name}_${surfaceString}`}
            name={name}
            toothImage={toothImage}
            color={color}
            surfaceString={surfaceStrings.tooth[surfaceString]}
            tooth={true}
          />,
        );
      }
    });
  });

  crowns.forEach((crown) => {
    if (condition[crown.boolean]) {
      surfaceConditions.push(
        <ConditionCrown
          key={crown.name}
          toothImage={toothImage}
          color={color}
          name={crown.name}
          surface={true}
        />,
      );
      toothConditions.push(
        <ConditionCrown
          key={crown.name}
          toothImage={toothImage}
          color={color}
          name={crown.name}
          tooth={true}
        />,
      );
    }
  });

  return (
    <div className="condition">
      <div className="tooth-image condition-container">
        <div style={{ opacity: 0 }}>
          <ChartToothImageBase
            tooth={toothImageProps.tooth}
            toothColor={toothImageProps.toothColor}
            statusCode={toothImageProps.statusCode}
            implantType={toothImageProps.implantType}
            imageNumber={toothImageProps.imageNumber}
          />
        </div>
        <ConditionShiftX property={condition.shiftX} />
        <ConditionToothAche property={condition.toothAche} toothImage={toothImage} color={color} />
        <ConditionMobilityGrade property={condition.mobilityGrade} up={up} />
        <ConditionFurcationGrade property={condition.furcationGrade} />
        <ConditionCalculusGrade property={condition.calculusGrade} toothImage={toothImage} />
        {toothConditions}
        <ConditionFractureRoot property={condition.fractureRoot} toothImage={toothImage} />
        <ConditionFractureCrown
          property={condition.fractureCrown}
          toothImage={toothImage}
          tooth={true}
        />
        <ConditionOcclusalReduction property={condition.occlusalReduction} up={up} color={color} />
        {toothImage && <ConditionPost property={condition.post} toothImage={toothImage} />}
        <ConditionPulpotomy property={condition.pulpotomy} toothImage={toothImage} color={color} />
        <ConditionShortRootCanal
          property={condition.shortRootCanal}
          toothImage={toothImage}
          color={color}
        />
        <ConditionPerforation
          property={condition.perforation}
          toothImage={toothImage}
          color={color}
        />
        <ConditionInternalBl
          property={condition.internalBleaching}
          toothImage={toothImage}
          color={color}
        />
        <ConditionApecictomy
          property={condition.apecictomy}
          toothImage={toothImage}
          color={color}
        />
      </div>
      <div className="surface-image condition-container">
        <div style={{ opacity: 0 }}>
          <ChartSurfaceImageBase
            statusCode={surfaceImageProps.statusCode}
            toothColor={surfaceImageProps.toothColor}
            imageNumber={surfaceImageProps.imageNumber}
          />
        </div>
        <ConditionTurn property={condition.turn} />
        <ConditionShiftY property={condition.shiftY} up={up} />
        <ConditionShiftZ property={condition.shiftZ} up={up} />
        <ConditionVeneer property={condition.veneer} up={up} color={color} />
        {surfaceConditions}
        <ConditionFractureCrown
          property={condition.fractureCrown}
          toothImage={toothImage}
          surface={true}
        />
        <ConditionFissureSealant property={condition.fissureSealant} color={color} />
        <ConditionBanding property={condition.banding} color={color} />
      </div>
      <div className="numeric" />
    </div>
  );
}
