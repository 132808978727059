import React from "react";
import PatientItemTable from "./PatientItemTable";

export default function CustomPopover(props) {
  const { show, onAddPatientClick, onHideClick, patientList, choosePatient } = props;

  if (!show) {
    return null;
  }

  return (
    <div className="popover-box">
      <div
        className="custom-popover popover fade right in"
        style={{ display: "block", minWidth: 400 }}
      >
        <div className="arrow" style={{ top: "44px" }} />
        <h3 className="popover-title">Patient(s) assosiated with this number</h3>

        {patientList && patientList.length ? (
          <div className="popover-content">
            <PatientItemTable list={patientList} choosePatient={choosePatient} />
          </div>
        ) : null}
        <div className="popover-footer">
          <button className="btn" onClick={onAddPatientClick}>
            Add as Temporary Patient
          </button>
        </div>
      </div>
      <div className="popover-bg" style={{ display: "block" }} onClick={onHideClick} />
    </div>
  );
}
