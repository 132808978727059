import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import bindActions from "../../../helpers/bindActions";
import { phoneToString } from "../../../helpers/PhoneUtils";
import { getMachines } from "../../../actions/appointmentActions";
import * as calendarActions from "../../../actions/calendarActions";
import FindAppointmentSidebarForm from "./FindAppointmentSidebarForm";
import FindAppointmentSidebarList from "./FindAppointmentSidebarList";
import FindAppointmentSidebarHeader from "./FindAppointmentSidebarHeader";
import { Modal } from "../../ui/Modal";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { withRouter } from "react-router";

const enhancer = connect(
  ({ auth: { chosenMemberId, doctors }, appointment: { machinesList } }) => ({
    chosenMemberId,
    doctors,
    machinesList,
  }),
  bindActions({ calendarActions, getMachines }),
);

class FindAppointmentSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      searchMode: true,
      createMode: false,
      activeTab: "all",
    };
  }

  componentDidMount() {
    this.props.getMachines();
  }

  render() {
    const { searchMode, createMode, phone } = this.state;
    const {
      values,
      doctors,
      machinesList,
      appointments,
      appointmentsOld,
      appointmentsAll,
      chosenMemberId,
      findOpenSlotToDate,
      findOpenSlotFromDate,
      searchAppointmentFetching,
      searchOldAppointmentFetching,
      searchAllAppointmentFetching,
      findAppointmentSidebarVisible,
    } = this.props;

    const phoneCode = _.toFinite(_.get(values, "phoneNumber.countryCode.value"));
    const phoneNumber = _.toFinite(_.get(values, "phoneNumber.number"));

    let list = [];
    let fetching = false;
    if (this.state.activeTab === "active") {
      list = appointments;
      fetching = searchAppointmentFetching;
    } else if (this.state.activeTab === "past") {
      list = appointmentsOld;
      fetching = searchOldAppointmentFetching;
    } else {
      list = appointmentsAll;
      fetching = searchAllAppointmentFetching;
    }

    return (
      <Modal
        dialogClassName={`find-open-slot-sidebar w-${searchMode ? "40" : "60"}`}
        animation={false}
        bodyProps={{ style: { padding: 0, margin: 0 } }}
        show={findAppointmentSidebarVisible}
        onHide={this.props.calendarActions.findAppointmentSidebarHide}
      >
        <FindAppointmentSidebarHeader
          setActiveTab={(activeTab) => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          onBackClick={() => {
            if (searchMode) {
              this.props.calendarActions.findAppointmentSidebarHide();
            } else {
              this.props.calendarActions.toggleSearchMode();
              this.setState({
                searchMode: true,
                createMode: false,
              });
            }
          }}
          onSearchClick={() => {
            this.props.calendarActions.searchAppointment(null, "all");
            this.props.calendarActions.searchAppointment(null, "past");
            this.props.calendarActions.searchAppointment(null, "active");
            this.setState({
              searchMode: false,
              phone: phoneToString(values.phoneNumber),
              createMode: phoneCode > 0 && phoneNumber > 0,
            });
          }}
          onCreateClick={() => {
            this.props.history.push(
              createUrl(Routes.DashboardAppointment, {
                params: {
                  id: 0,
                },
                query: {
                  startTime: Date.now(),
                  dentist: chosenMemberId,
                  createPhoneNumber: phone,
                },
              }),
            );
            this.props.calendarActions.findAppointmentSidebarHide();
          }}
          searchMode={searchMode}
          createMode={createMode}
        />

        <Show if={searchMode}>
          <FindAppointmentSidebarForm
            doctors={doctors}
            machinesList={machinesList}
            valuesForm={values}
            initialValues={{
              useDateRange: false,
            }}
            findOpenSlotFromDate={findOpenSlotFromDate}
            findOpenSlotToDate={findOpenSlotToDate}
          />
        </Show>
        <Show if={!searchMode}>
          <FindAppointmentSidebarList
            activeTab={this.state.activeTab}
            appointments={list}
            fetching={fetching}
            onChangeClinic={({ value }) =>
              this.props.calendarActions.searchAppointment(
                value === "all" ? undefined : value,
                this.state.activeTab,
              )
            }
          />
        </Show>
      </Modal>
    );
  }
}

export default withRouter(enhancer(FindAppointmentSidebar));
