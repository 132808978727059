import React from "react";
import ArrowRightImage from "../../../assets/images/arrows/arrow_right.png";

export default function ChartClinicalNoteCategoryGroupItem({ title, onClick }) {
  return (
    <button
      onClick={onClick}
      className="btn btn-default btn-block chart-clinical-note-category-item d-flex align-items-center justify-content-between"
    >
      {title}
      <img src={ArrowRightImage} width={15} height={20} alt="" />
    </button>
  );
}
