import createReducer from "../helpers/createReducer";

import {
  ADMIN_LOGGED_IN,
  APP_RESET,
  AUTH_DELETE_DEVICE_CONFIRM_MODAL_HIDE,
  AUTH_DELETE_DEVICE_CONFIRM_MODAL_SHOW,
  AUTH_DOCTOR_RESET,
  AUTH_DOCTOR_SELECT,
  CHOOSE_MEMBER,
  CLEAR_TOKEN,
  GET_CLINICS_SUCCESS,
  GET_DEVICE_INFO_SUCCESS,
  GET_MEMBERS_SUCCESS,
  GET_TOKEN_START,
  GET_TOKEN_SUCCESS,
  INIT_APP_ERROR,
  INIT_APP_START,
  INIT_APP_SUCCESS,
  LOGGED_IN,
  LOGOUT_SUCCESS,
  SET_TOKEN,
  UPDATE_TOKEN,
  SET_CHOSEN_CLINIC,
} from "../actions/authActions";

import { CURRENT_MEMBER, IS_DEV, IS_MOBILE } from "../constants/Constants";

const defaultState = {
  deviceExist: null,
  loggedIn: false,
  adminLoggedIn: false,
  clinics: [],
  members: [],
  chosenMemberId: 0,
  token: {},
  appLoaded: null,
  deleteDeviceConfirmVisible: false,
  doctors: [],
  user: {},
  chosenClinic: 0,
  doctorsFilter: [],
};
/* eslint-disable-next-line */
defaultState.loggedIn = localStorage.getItem("loggedIn") === "true";
/* eslint-disable-next-line */
defaultState.deviceExist = localStorage.getItem("deviceExist") === "true";

/* eslint-disable-next-line */
defaultState.chosenMemberId = +localStorage.getItem("chosenMemberId");

const reducers = {
  [AUTH_DOCTOR_RESET](state) {
    return {
      ...state,

      doctors: state.doctors.map((x) => ({ ...x, active: false })),
    };
  },
  [SET_CHOSEN_CLINIC](state, { chosenClinic }) {
    return {
      ...state,
      chosenClinic,
    };
  },
  [LOGOUT_SUCCESS](state) {
    return {
      ...state,
      chosenMemberId: 0,
    };
  },
  [AUTH_DOCTOR_SELECT](state, { id }) {
    const { doctors = [] } = state;
    const selectedDoctors = doctors.map((x) => ({
      ...x,
      active: x.id === id ? !x.active : x.active,
    }));

    const filteredDoctors = (selectedDoctors || [])
      .filter((doctor) => Boolean(doctor.active))
      .map((item) => {
        return {
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        };
      });

    if (id > 0) {
      return {
        ...state,
        doctors: selectedDoctors,
        doctorsFilter: filteredDoctors,
      };
    }

    return {
      ...state,
      doctorsFilter: [],
      doctors: doctors.map((x) => ({ ...x, active: false })),
    };
  },
  [APP_RESET](state) {
    return {
      ...state,
      deviceExist: false,
      adminLoggedIn: false,
    };
  },
  [AUTH_DELETE_DEVICE_CONFIRM_MODAL_SHOW](state) {
    return {
      ...state,
      deleteDeviceConfirmVisible: true,
    };
  },
  [AUTH_DELETE_DEVICE_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      deleteDeviceConfirmVisible: false,
    };
  },
  [SET_TOKEN](state, { token }) {
    return {
      ...state,
      token,
    };
  },
  [UPDATE_TOKEN](state, { token }) {
    return {
      ...state,
      token,
    };
  },
  [CLEAR_TOKEN](state) {
    return {
      ...state,
      token: {},
    };
  },
  [GET_TOKEN_SUCCESS](state, { data: token }) {
    if (IS_DEV) {
      /* eslint-disable-next-line */
      localStorage.setItem("httpToken", JSON.stringify(token));
    }
    return {
      ...state,
      token: { ...token, expires_in: Date.now() + token.expires_in * 1000 },
    };
  },
  [GET_DEVICE_INFO_SUCCESS](state, { data }) {
    const deviceExist = Boolean(data.clinicId && data.companyId && data.id);
    const baseUrl = data.baseUrl ? data.baseUrl : "";

    /* eslint-disable-next-line */
    localStorage.setItem("deviceExist", deviceExist);

    return { ...state, deviceExist, baseUrl };
  },
  [GET_MEMBERS_SUCCESS](state, { data }) {
    const map = {};

    data.forEach((item) => {
      map[item.id] = item;
    });

    return {
      ...state,
      members: map,
      doctors: data.map((x) => ({ ...x, active: false })),
    };
  },
  [GET_CLINICS_SUCCESS](state, { data }) {
    return { ...state, clinics: data };
  },
  [CHOOSE_MEMBER](state, { chosenMemberId }) {
    /* eslint-disable-next-line */
    localStorage.setItem("chosenMemberId", chosenMemberId);

    const members = state.members;

    if (members && members.length && chosenMemberId) {
      /* eslint-disable-next-line */
      localStorage.setItem(CURRENT_MEMBER, members[chosenMemberId]);
    }

    return { ...state, chosenMemberId };
  },
  [ADMIN_LOGGED_IN](state) {
    return { ...state, adminLoggedIn: true };
  },
  [LOGGED_IN](state, { loggedIn }) {
    /* eslint-disable-next-line */
    localStorage.setItem("loggedIn", loggedIn);
    if (loggedIn) {
      return { ...state, loggedIn };
    }
    return { ...state, loggedIn, token: {} };
  },
  [INIT_APP_START](state) {
    const bool = localStorage.getItem("simpleReload");
    if (IS_MOBILE) {
      return {
        ...state,
        loggedIn: false,
        adminLoggedIn: false,
        appLoaded: false,
      };
    }
    if (IS_DEV) {
      return {
        ...state,
        adminLoggedIn: false,
        appLoaded: false,
      };
    }
    return {
      ...state,
      loggedIn: !!bool,
      adminLoggedIn: false,
      appLoaded: false,
    };
  },
  [INIT_APP_SUCCESS](state) {
    localStorage.removeItem("simpleReload");
    return { ...state, appLoaded: true };
  },
  [INIT_APP_ERROR](state) {
    localStorage.removeItem("simpleReload");
    return { ...state, appLoaded: true };
  },
  [GET_TOKEN_START](state, { data }) {
    return { ...state, user: data };
  },
};

/** ==================
 * Selectors
 * ================== */
export const deviceExistSelector = ({ auth }) => auth.deviceExist;

export const loggedInSelector = ({ auth }) => auth.loggedIn;

export const adminLoggedInSelector = ({ auth }) => auth.adminLoggedIn;

export const appLoadedSelector = ({ auth }) => auth.appLoaded;

export const chosenMemberIdSelector = ({ auth }) => auth.chosenMemberId;

export const membersSelector = ({ auth }) => auth.members;

export const currentMemberSelector = ({ auth }) => auth.members[auth.chosenMemberId];

export default createReducer(defaultState, reducers);
