import React from "react";

import Utils from "../../helpers/Utils";

export default function PatientItemTable(props) {
  const { list, choosePatient } = props;

  return (
    <table className="patient-table">
      <tbody>
        {list.map((item) => (
          <tr className="cursor-pointer" key={item.id} onClick={() => choosePatient(item)}>
            <td className="name-field">
              <span className="label">Name: </span>
              <br />
              {[Utils.getFullName(item), !item.hidden ? "(Active)" : "(Inactive)"].join(" ")}
            </td>
            <td className="text-right">
              <span className="label">Age:</span>
              <br />
              {Utils.getAge(item.dateOfBirth)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
