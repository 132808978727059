import React from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";

import store from "../store/store";
import { ThemeOptions } from "../dto/AppDTO";
import { RootContainer } from "./RootContainer";
import { IS_ELECTRON } from "../constants/AppConstants";
import { ElectronRootContainer } from "./ElectronRootContainer";
import { AppErrorBoundary } from "../components/app/AppErrorBoundary";

export const THEME: Partial<ThemeOptions> = {
  palette: {
    primary: {
      main: "#bec844",
    },
    error: {
      main: "#d9534f",
      hover: "#bd423e",
    },
    info: {
      main: "#2018f9",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
      transparent: "rgba(0, 0, 0, 0)",
    },
    grey: {
      100: "#e6e6e6",
      200: "#adadad",
    },
  },
  typography: {
    pxToRem: (value: number) => {
      if (value) {
        return `${value / 16}rem`;
      }

      return "1rem";
    },
  },
};

export function AppContainer() {
  const persistor = persistStore(store, {});

  const queryClient = new QueryClient();

  return (
    <AppErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <QueryClientProvider client={queryClient}>
              {!IS_ELECTRON && <RootContainer />}

              {IS_ELECTRON && <ElectronRootContainer />}
            </QueryClientProvider>
          </HashRouter>
        </PersistGate>
      </Provider>
    </AppErrorBoundary>
  );
}
