import { useField } from "formik";
import React, { useMemo } from "react";

import { DatePicker, DatePickerProps } from "../ui/DatePicker";
import { FieldContainer, FieldContainerProps } from "./FieldContainer";
import moment, { Moment } from "moment";

interface Props extends Omit<DatePickerProps, "onChange"> {
  readonly name: string;
  readonly label?: string;
  readonly onChange?: (date: Moment | null, event: React.SyntheticEvent<any> | undefined) => void;
  readonly fieldProps?: Omit<FieldContainerProps, "label" | "children">;
}

export function DateField({ name, label, fieldProps, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = useMemo(() => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);
  return (
    <FieldContainer
      {...fieldProps}
      label={label}
      error={Boolean(error)}
      hintText={error || fieldProps?.hintText}
    >
      <DatePicker
        {...props}
        {...field}
        selected={field.value}
        onChange={(x, e) => {
          helpers.setValue(x && !Array.isArray(x) ? moment(x) : null);

          if (props.onChange) {
            props.onChange(x && !Array.isArray(x) ? moment(x) : null, e);
          }
        }}
      />
    </FieldContainer>
  );
}
