import React from "react";
import { connect } from "react-redux";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";

import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";
import { Modal } from "../../ui/Modal";

const enhancer = connect(null, bindActions({ chartActions }));

class ChartChooseTooltip extends React.Component {
  render() {
    const {
      toothPosition: activeTooth,
      teeth,
      chooseTooltipVisible,
      toothNumericType,
    } = this.props;
    const numericKey = toothNumericType?.code === "Index1" ? "numeric1" : "numeric2";

    const tooth = teeth["p" + activeTooth];
    if (!tooth) return null;
    const toothDetails = tooth && tooth.normal ? tooth.normal.details : tooth.supernumerary.details;
    let style = {};
    let top = 20 + 70 + 10 + 225 + 1 - 124 / 2;
    if (activeTooth < 17) {
      top -= 225 / 2;
    } else {
      top += 225 / 2;
    }
    style.top = top + "px";

    const toothWidth = window.innerWidth / 16;

    if (activeTooth < 17) {
      if (activeTooth < 9) {
        style.left = activeTooth * toothWidth + 10 + "px";
      } else {
        style.right = (17 - activeTooth) * toothWidth + 10 + "px";
      }
    } else {
      if (activeTooth < 25) {
        style.right = (activeTooth - 16) * toothWidth + 10 + "px";
      } else {
        style.left = (33 - activeTooth) * toothWidth + 10 + "px";
      }
    }

    return (
      // <Modal
      //   show={chooseTooltipVisible}
      //   animation={false}
      //   keyboard={false}
      //   onHide={this.props.chartActions.hideChooseTooltip}
      //   bsClass="chart-tooltip"
      // >
      <div className="chart-tooltip-body" style={style}>
        <div className="list-group">
          <div className="list-group-item">Choose action:</div>
          {toothDetails && (
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                this.props.chartActions.hideChooseTooltip();
                this.props.chartActions.showSideBar(viewTypes.series, {
                  activeTooth: activeTooth,
                });
              }}
            >
              Show action for tooth {tooth && toothDetails[numericKey]}
            </button>
          )}
          <button
            type="button"
            className="list-group-item"
            onClick={() => {
              this.props.chartActions.hideChooseTooltip();
              this.props.chartActions.showSideBar(viewTypes.series, {
                activeTooth: +tooth.supernumerary.details[numericKey],
              });
            }}
          >
            Show action for tooth {tooth && tooth.supernumerary.details[numericKey]}
          </button>
          <button
            type="button"
            className="list-group-item"
            onClick={this.props.chartActions.hideChooseTooltip}
          >
            Cancel
          </button>
        </div>
      </div>
      // </Modal>
    );
  }
}

export default enhancer(ChartChooseTooltip);
