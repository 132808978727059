import React from "react";

import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

export default class ChartVitalItemDeleteModal extends React.Component {
  render() {
    return (
      <Modal
        size="small"
        show={this.props.deleteModalVisible}
        onHide={this.props.closeDeleteModal}
        keyboard={false}
        actions={
          <div>
            <Button onClick={this.props.closeDeleteModal}>Close</Button>
            <Button
              onClick={() => {
                this.props.deleteItem();
                this.props.closeDeleteModal();
              }}
            >
              Ok
            </Button>
          </div>
        }
      >
        <div>Are you sure you want to delete this vital? This action cannot be undone.</div>
      </Modal>
    );
  }
}
