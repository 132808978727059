import _ from "lodash";
import React from "react";

import { SidebarList } from "./SidebarList";
import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import { SidebarFavoritesList } from "./SidebarFavoritesList";
import { SidebarContentActions } from "./SidebarContentActions";

export function SidebarTreatmentDiagnosis({
  stack,
  onSort,
  viewTab,
  typeTab,
  openItem,
  loadList,
  favourites,
  getFavorites,
  onActionClick,
  toggleFavorite,
  chartSideSeries,
  showImageSelector,
}) {
  const isList = _.last(stack) === SidebarStackTypes.Diagnosis;
  const isFavorites = _.last(stack) === SidebarStackTypes.DiagnosisFavorites;
  if (isFavorites && localStorage.getItem("addDiagnosisToTreatment")) {
    getFavorites(SidebarStackTypes.Diagnosis);
    localStorage.removeItem("addDiagnosisToTreatment");
  }

  return (
    <div style={{ flex: "1 1 0%", display: "flex", flexDirection: "column" }}>
      {isFavorites && (
        <div style={{ display: "flex", flexDirection: "column", flex: "1 1 0%" }}>
          <span className="chart-treatments-sidebar-title">Favorites</span>
          <SidebarFavoritesList
            onSort={onSort}
            typeTab={typeTab}
            viewTab={viewTab}
            openItem={openItem}
            isMultiSelect={true}
            favoritesList={favourites}
            toggleFavourite={toggleFavorite}
            chartSideSeries={chartSideSeries}
            showImageSelector={showImageSelector}
            getFavorites={() => getFavorites(SidebarTypes.Diagnosis)}
          />
        </div>
      )}

      {isList && (
        <SidebarList
          stack={stack}
          viewTab={viewTab}
          typeTab={typeTab}
          openItem={openItem}
          loadList={loadList}
          isMultiSelect={true}
          toggleFavorite={toggleFavorite}
          chartSideSeries={chartSideSeries}
        />
      )}

      <SidebarContentActions>
        {isFavorites && (
          <button
            type="button"
            style={{ maxWidth: "90%" }}
            className="btn btn-primary btn-block m-0"
            onClick={() => onActionClick(SidebarStackTypes.Diagnosis)}
          >
            All codes
          </button>
        )}
      </SidebarContentActions>
    </div>
  );
}
