import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import MedicalFillInFormVitalsList from "./MedicalFillInFormVitalsList";
import { pushStack } from "../../../../actions/chartSideMedicalFillInFormActions";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import { getMedicalFillInFormVitalsList } from "../../../../actions/chartSideMedicalFillInFormActions";
import Show from "../../../widgets/Show";

const MedicalFillInFormVitals = ({ fetching, stackItem, medicalFillInFormItemTypes }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedicalFillInFormVitalsList(true));
    return () => {
      dispatch({ type: "GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_ERROR" });
    };
  }, []);

  return (
    <div className="medical-fill-in-form-vitals">
      <Show
        if={
          stackItem?.type === medicalFillInFormItemTypes.edit ||
          stackItem?.type === medicalFillInFormItemTypes.new
        }
      >
        <div className="btn-group" style={{ display: "block", height: 34 }}>
          <button
            type="button"
            style={{ width: "100%", marginBottom: 10 }}
            className={`btn btn-primary`}
            onClick={() =>
              dispatch(
                pushStack({
                  code: medicalFillInFormStackCodes.vitalsCreateForm,
                  type: medicalFillInFormItemTypes.new,
                }),
              )
            }
          >
            Add new
          </button>
        </div>
      </Show>

      <MedicalFillInFormVitalsList fetching={fetching} />
    </div>
  );
};
export default MedicalFillInFormVitals;
