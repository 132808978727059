import { isFunction } from "lodash";
import React, { ReactNode } from "react";
import { DropzoneOptions, DropzoneState, useDropzone } from "react-dropzone";

type ChildrenFunc = (options: Omit<DropzoneState, "getRootProps" | "getInputProps">) => ReactNode;

interface Props extends DropzoneOptions {
  readonly className?: string;
  readonly children: ReactNode | ChildrenFunc;
}

export function Dropzone({ className, children, ...props }: Props) {
  const { getRootProps, getInputProps, ...options } = useDropzone(props);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isFunction(children) && children(options)}
      {!isFunction(children) && children}
    </div>
  );
}
