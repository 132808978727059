import TeethImage11 from "../assets/images/teeth/11_1@2x.png";
import TeethImage12 from "../assets/images/teeth/12_1@2x.png";
import TeethImage13 from "../assets/images/teeth/13_1@2x.png";
import TeethImage14 from "../assets/images/teeth/14_1@2x.png";
import TeethImage15 from "../assets/images/teeth/15_1@2x.png";
import TeethImage16 from "../assets/images/teeth/16_1@2x.png";
import TeethImage17 from "../assets/images/teeth/17_1@2x.png";
import TeethImage18 from "../assets/images/teeth/18_1@2x.png";
import TeethImage21 from "../assets/images/teeth/21_1@2x.png";
import TeethImage22 from "../assets/images/teeth/22_1@2x.png";
import TeethImage23 from "../assets/images/teeth/23_1@2x.png";
import TeethImage24 from "../assets/images/teeth/24_1@2x.png";
import TeethImage25 from "../assets/images/teeth/25_1@2x.png";
import TeethImage26 from "../assets/images/teeth/26_1@2x.png";
import TeethImage27 from "../assets/images/teeth/27_1@2x.png";
import TeethImage28 from "../assets/images/teeth/28_1@2x.png";
import TeethImage31 from "../assets/images/teeth/31_1@2x.png";
import TeethImage32 from "../assets/images/teeth/32_1@2x.png";
import TeethImage33 from "../assets/images/teeth/33_1@2x.png";
import TeethImage34 from "../assets/images/teeth/34_1@2x.png";
import TeethImage35 from "../assets/images/teeth/35_1@2x.png";
import TeethImage36 from "../assets/images/teeth/36_1@2x.png";
import TeethImage37 from "../assets/images/teeth/37_1@2x.png";
import TeethImage38 from "../assets/images/teeth/38_1@2x.png";
import TeethImage41 from "../assets/images/teeth/41_1@2x.png";
import TeethImage42 from "../assets/images/teeth/42_1@2x.png";
import TeethImage43 from "../assets/images/teeth/43_1@2x.png";
import TeethImage44 from "../assets/images/teeth/44_1@2x.png";
import TeethImage45 from "../assets/images/teeth/45_1@2x.png";
import TeethImage46 from "../assets/images/teeth/46_1@2x.png";
import TeethImage47 from "../assets/images/teeth/47_1@2x.png";
import TeethImage48 from "../assets/images/teeth/48_1@2x.png";

export const getTeethImage = (index) => {
  switch (index) {
    case 11:
      return TeethImage11;
    case 12:
      return TeethImage12;
    case 13:
      return TeethImage13;
    case 14:
      return TeethImage14;
    case 15:
      return TeethImage15;
    case 16:
      return TeethImage16;
    case 17:
      return TeethImage17;
    case 18:
      return TeethImage18;
    case 21:
      return TeethImage21;
    case 22:
      return TeethImage22;
    case 23:
      return TeethImage23;
    case 24:
      return TeethImage24;
    case 25:
      return TeethImage25;
    case 26:
      return TeethImage26;
    case 27:
      return TeethImage27;
    case 28:
      return TeethImage28;
    case 31:
      return TeethImage31;
    case 32:
      return TeethImage32;
    case 33:
      return TeethImage33;
    case 34:
      return TeethImage34;
    case 35:
      return TeethImage35;
    case 36:
      return TeethImage36;
    case 37:
      return TeethImage37;
    case 38:
      return TeethImage38;
    case 41:
      return TeethImage41;
    case 42:
      return TeethImage42;
    case 43:
      return TeethImage43;
    case 44:
      return TeethImage44;
    case 45:
      return TeethImage45;
    case 46:
      return TeethImage46;
    case 47:
      return TeethImage47;
    case 48:
      return TeethImage48;
    default:
      return "";
  }
};

export const perioExamItemInitialState = {
  plaqueStatus: {
    id: 1,
    name: "PlaqueStatus_None",
    code: "PlaqueStatus_None",
    title: "None",
  },
  mobilityStatus: {
    id: 1,
    name: "MobilityStatus_None",
    code: "MobilityStatus_None",
    title: "None",
  },
  boneLossStatus: {
    id: 1,
    name: "BoneLossStatus_None",
    code: "BoneLossStatus_None",
    title: "None",
  },
  furcationStatus: 0,
  mucogingivalDefectStatus: false,
  probingDepthDistal: 0,
  probingDepthCentral: 0,
  probingDepthMesial: 0,
  gingivalMarginDistal: 0,
  gingivalMarginCentral: 0,
  gingivalMarginMesial: 0,
  clinicalAttachmentDistal: 0,
  clinicalAttachmentCentral: 0,
  clinicalAttachmentMesial: 0,
  bleedingDistal: false,
  bleedingCentral: false,
  bleedingMesial: false,
  suppurationDistal: false,
  suppurationCentral: false,
  suppurationMesial: false,
};

export const plaqueStatus = [
  { id: 1, name: "PlaqueStatus_None", code: "PlaqueStatus_None", title: "None" },
  { id: 2, name: "PlaqueStatus_Light", code: "PlaqueStatus_Light", title: "Light" },
  { id: 3, name: "PlaqueStatus_Moderate", code: "PlaqueStatus_Moderate", title: "Moderate" },
  { id: 4, name: "PlaqueStatus_Heavy", code: "PlaqueStatus_Heavy", title: "Heavy" },
];

export const mobilityStatus = [
  { id: 1, name: "MobilityStatus_None", code: "MobilityStatus_None", title: "None" },
  { id: 2, name: "PlaqueStatus_I", code: "PlaqueStatus_I", title: "I" },
  { id: 3, name: "PlaqueStatus_II", code: "PlaqueStatus_II", title: "II" },
  { id: 4, name: "PlaqueStatus_III", code: "PlaqueStatus_III", title: "III" },
];
export const boneLossStatus = [
  { id: 1, name: "BoneLossStatus_None", code: "BoneLossStatus_None", title: "None" },
  { id: 2, name: "PlaqueStatus_MILD", code: "PlaqueStatus_MILD", title: "MILD" },
  { id: 3, name: "PlaqueStatus_Moderate", code: "PlaqueStatus_Moderate", title: "Moderate" },
  { id: 4, name: "PlaqueStatus_Severe", code: "PlaqueStatus_Severe", title: "Severe" },
];
