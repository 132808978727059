import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Back from "../widgets/Back";

import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { defaultColors } from "../../constants/defaults";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";

const enhancer = connect(({ auth: { members } }) => ({ members }));
class AppointmentDoctor extends React.Component {
  componentDidMount() {
    let colors = Colors.getDoctorColors();

    _.forEach(this.props.members, (item) => {
      if (!colors[item.id]) {
        colors[item.id] = {
          id: item.id,
          color: defaultColors.Green,
        };
      }
    });

    localStorage.setItem("appointmentDoctor", JSON.stringify(colors));

    this.forceUpdate();
  }

  render() {
    let colors = Colors.getDoctorColors();

    let $colors = _.map(this.props.members, (item) => {
      if (!item.doctor) return null;
      return (
        <Link
          key={item.id}
          to={createUrl(Routes.SettingsAppointmentDoctorColor, {
            params: { doctorId: item.id },
          })}
          className="list-group-item"
        >
          <div className="pull-right">❯</div>
          {!!colors[item.id] && (
            <div className="color" style={{ backgroundColor: colors[item.id].color.color }} />
          )}
          {Utils.getFullName(item)}
        </Link>
      );
    });

    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment {localStorage.getItem("doctorLabel")} colors</div>
        </div>
        <div className="body">
          <div className="list-group">{$colors}</div>
        </div>
      </div>
    );
  }
}
export default enhancer(AppointmentDoctor);
