import _ from "lodash";
import FillInFormApi from "../api-legacy/FillInFormApi";

import * as ActionType from "../actionTypes/chartSideFillInFormActionTypes";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import { hideSideBar } from "./chartActions";
import Utils from "../helpers/Utils";
import { fillInFormStackCodes } from "../constants/chart-sidebar/fillInFormStackCodes";
import questionTypeCodes from "../constants/chart-sidebar/questionTypeCodes";

import { getNote } from "../helpers/clinicNote";

export function resetFillInFormFor() {
  return (dispatch) => {
    return dispatch({
      type: ActionType.RESET_FILL_IN_FORM_FOR,
    });
  };
}

export function initTextarea() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    dispatch({
      type: ActionType.FILL_IN_FORM_INIT_TEXTAREA,
      patient,
    });
  };
}

export function fullScreen(boolean) {
  return {
    type: ActionType.FILL_IN_FORM_FULL_SCREEN,
    fullScreen: boolean,
  };
}

export function submitTextareaForm(values) {
  return (dispatch, getState) => {
    const {
      chart,
      patient: { patientDetails },
      chartSideFillInForm: { item, onAppointment, fillInFormAddendumId },
      session: { member },
    } = getState();

    const { name, note, referenceFillInFormId } = values;

    if (!note) {
      return dispatch(showMessage("Note required"));
    }

    const data = {
      appointmentId: chart.appointment.id || null,
      id: item.id,
      name,
      content: note,
      referenceFillInFormId:
        referenceFillInFormId > 0
          ? referenceFillInFormId
          : fillInFormAddendumId > 0
          ? fillInFormAddendumId
          : undefined,
      owner: {
        id: member.id,
      },
      doctor: {
        id: member.id,
      },
      patient: {
        patientKey: onAppointment ? patientDetails.patientKey : chart.patient.patientKey,
      },
    };

    dispatch(startLoader());

    dispatch(saveFillInFormItem(data))
      .then(() => {
        dispatch(setFillInFormAddendumId(0));
        dispatch(stopLoader());
        dispatch(pushStack({ code: fillInFormStackCodes.owned }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openTextarea(type) {
  return pushStack({
    code: fillInFormStackCodes.textarea,
    type,
  });
}

export function setFillInFormAddendumId(id) {
  return {
    type: ActionType.FILL_IN_FORM_SET_ADDENDUM_ID,
    id,
  };
}

export function getGroupList() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getGroupListApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getReferenceNoteList(referenceFillInFormId, patientKey) {
  return (dispatch) => {
    if (!patientKey) {
      return;
    }

    dispatch(startLoader());

    dispatch(getReferenceFillInFormListApi({ referenceFillInFormId, patientKey }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setMultiQuestionOtherValue(id, data) {
  return (dispatch) =>
    dispatch({
      type: ActionType.FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE,
      data,
      id,
    });
}

export function setSelectTreatmentModalVisible() {
  return {
    type: ActionType.FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE,
  };
}

export function setSelectDiagnosisModalVisible() {
  return {
    type: ActionType.FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE,
  };
}

export function copyNoteItem(id) {
  return (dispatch, getState) => {
    dispatch(startLoader());
    const {
      session: { member },
    } = getState();
    return (
      dispatch(getFillInFormItem({ id }))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(({ id, ...item }) => {
          dispatch(
            saveFillInFormItem({
              ...item,
              doctor: member,
              createdDate: Date.now(),
            }),
          )
            .then(() => {
              dispatch(stopLoader());

              dispatch(getOwnedList());
            })
            .catch((error) => dispatch(stopLoader(error)));
        })
        .catch((error) => dispatch(stopLoader(error)))
    );
  };
}

export function initSideFillInForm() {
  return {
    type: ActionType.INIT_SIDE_FILL_IN_FORM,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_SORT_FIELD_SIDE_FILL_IN_FORM,
      code,
    });

    dispatch(getOwnedList());
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_FILL_IN_FORM_SEARCH_CHANGE,
    value,
  };
}

function pushStack(stackItem) {
  return {
    type: ActionType.PUSH_FILL_IN_FORM_STACK,
    stackItem,
  };
}

export function clearStack() {
  return {
    type: ActionType.CLEAR_FILL_IN_FORM_STACK,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSideFillInForm;

    if (stack.length === 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_FILL_IN_FORM_STACK,
      });
    }
  };
}

export function openList() {
  return pushStack({
    code: fillInFormStackCodes.list,
  });
}

export function openItem(item, type) {
  return pushStack({
    code: fillInFormStackCodes.item,
    item,
    type,
  });
}

export function getOwnedList(query) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSideFillInForm: { sortField, fillInFormActiveTab },
    } = getState();

    const data = {
      patientKey: (query && query.patientKey) || patient.patientKey,
      sortField,
      limit: 25,
    };

    if (fillInFormActiveTab.code == "fillInForms") {
      data.onlyDeleted = false;
    }
    if (fillInFormActiveTab.code == "voidedFillInForms") {
      data.onlyDeleted = true;
    }
    dispatch(getFillInFormOwnedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadOwnedList(query) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSideFillInForm: { sortField, ownedList, ownedListTotalCount },
    } = getState();

    if (ownedListTotalCount > ownedList.length) {
      const data = {
        patientKey: patient.patientKey || query.patientKey,
        sortField,
        limit: 25,
        start: ownedList.length,
      };

      dispatch(getFillInFormOwnedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function getFrequentlyFillInFormList({ patientKey } = {}) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    dispatch(startLoader());

    return dispatch(
      getFrequencyFillInFormListApi({
        patientKey: patientKey || patient.patientKey,
        templateIds: _.get(getState(), "session.miscSettings.patientChartFillInFormTemplate.id"),
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTemplateList(groupId = null) {
  return (dispatch, getState) => {
    const {
      chartSideFillInForm: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { categoryId } = stackItem;

    const data = {
      groupId,
      categoryId,
      start: 0,
      limit: 25,
    };
    dispatch(resetFillInFormFor());
    dispatch(getFillInFormTemplateList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadTemplateList(groupId = null) {
  return (dispatch, getState) => {
    const {
      chartSideFillInForm: { stack, templateList, templateListCount },
    } = getState();

    if (templateList.length >= templateListCount) {
      return;
    }

    const stackItem = _.last(stack);
    const { categoryId } = stackItem;

    const data = {
      groupId,
      categoryId,
      limit: 25,
      start: templateList.length,
    };

    dispatch(getFillInFormTemplateList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function getItem() {
  return (dispatch, getState) => {
    const {
      chartSideFillInForm: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(getFillInFormItem(data))
      .then((response) => {
        dispatch(stopLoader());
        dispatch(getReferenceNoteList(id, _.get(response, "patient.patientKey")));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteItem() {
  return (dispatch, getState) => {
    const {
      chartSideFillInForm: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(deleteFillInFormItem(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getEmpty(isFrequency) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chartSideFillInForm: { stack },
      chart,
      session,
    } = state;

    const stackItem = _.last(stack);
    const templateId = isFrequency
      ? _.get(session, "miscSettings.patientChartFillInFormTemplate.id")
      : stackItem.item.id;

    const data = {
      templateId,
      patientKey: chart.patient.patientKey,
    };

    dispatch(startLoader());

    dispatch(getFillInFormEmpty(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveItem(name, patientKey) {
  return (dispatch, getState) => {
    const {
      session: { toothNumericType, clinic },
      chartSideFillInForm: { item, stack, fillInFormAddendumId },
      chart,
    } = getState();

    dispatch(startLoader());

    const stackItem = _.last(stack);

    if (stackItem.code === fillInFormStackCodes.item) {
      // item.templateQuestionsAndAnswers.map((question) => {
      //   if (!question.answers) {
      //     question.answers = [];
      //     question.answers[0] = question.defaultAnswers[0];
      //   }
      // });

      item.content = getNote(item, toothNumericType, true, clinic, getState().insurance.images);

      if (name) {
        item.name = name;
      }

      if (fillInFormAddendumId) {
        item.referenceFillInFormId = fillInFormAddendumId;
      }
      item.appointmentId = chart?.appointment?.id || null;
      item.templateQuestionsAndAnswers.map((question) => {
        if (
          question.questionType.code === questionTypeCodes.DATE_PICKER &&
          question.answers &&
          question.answers[0]
        ) {
          question.answers[0] = +question.answers[0] + Utils.getTimezoneDifference();
        }
      });
    }

    return dispatch(saveFillInFormItem(item))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
        dispatch(getOwnedList({ patientKey }));
        dispatch(getFrequentlyFillInFormList({ patientKey }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function selectQuestion(index) {
  return {
    type: ActionType.FILL_IN_FORM_SELECT_QUESTION,
    index: +index,
  };
}

export function prevQuestion() {
  return {
    type: ActionType.FILL_IN_FORM_PREV_QUESTION,
  };
}

export function nextQuestion() {
  return {
    type: ActionType.FILL_IN_FORM_NEXT_QUESTION,
  };
}

export function changeQuestionTextarea(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA,
    value,
    questionId,
  };
}

export function changeQuestionCanvas(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_QUESTION_CANVAS,
    value,
    questionId,
  };
}

export function changeListOfAnswers(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS,
    value,
    questionId,
  };
}

export function changeListOfAnswersMultiple(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE,
    value,
    questionId,
  };
}

export function changeDatePicker(date, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_DATE_PICKER,
    date,
    questionId,
  };
}

export function changeSurface(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CHANGE_SURFACE,
    value,
    questionId,
  };
}

export function selectTooth(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_SELECT_TOOTH,
    value,
    questionId,
  };
}

export function setName(value) {
  return {
    type: ActionType.FILL_IN_FORM_SET_NAME,
    value,
  };
}

export function setTextareaBody(value) {
  return {
    type: ActionType.FILL_IN_FORM_SET_TEXTAREA_BODY,
    value,
  };
}

export function openDeleteModal(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_OPEN_DELETE_MODAL,
    value,
    questionId,
  };
}

export function closeDeleteModal(value, questionId) {
  return {
    type: ActionType.FILL_IN_FORM_CLOSE_DELETE_MODAL,
    value,
    questionId,
  };
}

export function initEdit() {
  return {
    type: ActionType.FILL_IN_FORM_INIT_EDIT,
  };
}

export function changeCreatedDate(momentDate) {
  return (dispatch, getState) => {
    const {
      chartSideFillInForm: { item },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: item.id,
      createdDate: +momentDate + Utils.getTimezoneDifference(),
    };

    dispatch(changeFillInFormCreatedDate(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getFillInFormOwnedList(data) {
  return {
    api: FillInFormApi.getOwnedList,
    types: [
      ActionType.GET_FILL_IN_FORM_OWNED_LIST_START,
      ActionType.GET_FILL_IN_FORM_OWNED_LIST_SUCCESS,
      ActionType.GET_FILL_IN_FORM_OWNED_LIST_ERROR,
    ],
    data,
  };
}

export function onChangeFillInFormTab(fillInFormActiveTab) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FILL_IN_FORM_CHANGE_TAB,
      fillInFormActiveTab,
    });
    dispatch(getOwnedList());
  };
}

function getFrequencyFillInFormListApi(data) {
  return {
    api: FillInFormApi.getOwnedList,
    types: [
      ActionType.GET_FREQUENCY_FILL_IN_FORM_LIST_START,
      ActionType.GET_FREQUENCY_FILL_IN_FORM_LIST_SUCCESS,
      ActionType.GET_FREQUENCY_FILL_IN_FORM_LIST_ERROR,
    ],
    data,
  };
}

function getFillInFormTemplateList(data) {
  return {
    api: FillInFormApi.template.getList,
    types: [
      ActionType.GET_FILL_IN_FORM_TEMPLATE_LIST_START,
      ActionType.GET_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS,
      ActionType.GET_FILL_IN_FORM_TEMPLATE_LIST_ERROR,
    ],
    data,
  };
}

function getFillInFormItem(data) {
  return {
    api: FillInFormApi.getItem,
    types: [
      ActionType.GET_FILL_IN_FORM_ITEM_START,
      ActionType.GET_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.GET_FILL_IN_FORM_ITEM_ERROR,
    ],
    data,
  };
}

function saveFillInFormItem(data) {
  return {
    api: FillInFormApi.saveItem,
    types: [
      ActionType.SAVE_FILL_IN_FORM_ITEM_START,
      ActionType.SAVE_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.SAVE_FILL_IN_FORM_ITEM_ERROR,
    ],
    data: {
      ...data,
      patient: {
        patientKey: _.get(data, "patient.patientKey"),
      },
    },
  };
}

function deleteFillInFormItem(data) {
  return {
    api: FillInFormApi.deleteItem,
    types: [
      ActionType.DELETE_FILL_IN_FORM_ITEM_START,
      ActionType.DELETE_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.DELETE_FILL_IN_FORM_ITEM_ERROR,
    ],
    data,
  };
}

function getFillInFormEmpty(data) {
  return {
    api: FillInFormApi.getEmpty,
    types: [
      ActionType.GET_FILL_IN_FORM_EMPTY_START,
      ActionType.GET_FILL_IN_FORM_EMPTY_SUCCESS,
      ActionType.GET_FILL_IN_FORM_EMPTY_ERROR,
    ],
    data,
  };
}

function changeFillInFormCreatedDate(data) {
  return {
    api: FillInFormApi.changeCreatedDate,
    types: [
      ActionType.CHANGE_FILL_IN_FORM_CREATED_DATE_START,
      ActionType.CHANGE_FILL_IN_FORM_CREATED_DATE_SUCCESS,
      ActionType.CHANGE_FILL_IN_FORM_CREATED_DATE_ERROR,
    ],
    data,
  };
}

function getGroupListApi(data) {
  return {
    api: FillInFormApi.getGroupList,
    types: [
      ActionType.FILL_IN_FORM_GET_GROUP_LIST_START,
      ActionType.FILL_IN_FORM_GET_GROUP_LIST_SUCCESS,
      ActionType.FILL_IN_FORM_GET_GROUP_LIST_ERROR,
    ],
    data,
  };
}

function getReferenceFillInFormListApi(data) {
  return {
    api: FillInFormApi.getOwnedList,
    types: [
      ActionType.GET_REFERENCE_FILL_IN_FORM_LIST_START,
      ActionType.GET_REFERENCE_FILL_IN_FORM_LIST_SUCCESS,
      ActionType.GET_REFERENCE_FILL_IN_FORM_LIST_ERROR,
    ],
    data,
  };
}
