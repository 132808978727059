import React from "react";

import { LONG_TAP } from "../../constants/settings";

export default class Tap extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      timerId: 0,
    };
  }

  onMouseDown = (clientY, target) => {
    const { long } = this.props;

    const timerId = setTimeout(() => {
      this.setState({
        timerId: 0,
      });

      if (typeof long == "function") {
        long(clientY, target);
      }
    }, LONG_TAP);

    this.setState({
      timerId,
    });
  };

  onMouseLeave = () => {
    const { timerId } = this.state;

    if (!timerId) {
      return;
    }

    clearTimeout(timerId);

    this.setState({
      timerId: 0,
    });
  };

  onMouseUp = (e) => {
    const { short } = this.props;
    const { timerId } = this.state;

    if (!timerId) {
      return;
    }

    clearTimeout(timerId);

    if (typeof short == "function") {
      short(e);
    }

    this.setState({
      timerId: 0,
    });
  };
  render() {
    const { tag, children, className } = this.props;

    const Tag = tag || "div";

    return (
      <Tag
        onMouseMove={this.props.onMouseMove}
        style={this.props.style}
        className={className}
        onMouseDown={({ clientY, target }) => {
          this.onMouseDown(clientY, target);
        }}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
      >
        {children}
      </Tag>
    );
  }
}
