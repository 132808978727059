import React from "react";

export default function ChartSideListProcedure(props) {
  const { item } = props;
  const { openItem } = props;

  return (
    <button className="list-group-item text-center" onClick={() => openItem(item)}>
      <div>{item.name}</div>
    </button>
  );
}
