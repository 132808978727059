import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import FlexBox from "../ui/FlexBox";
import { MedicationFormItem } from "./MedicationFormItem";

const enhancer = reduxForm({
  form: "medicationCreateDrug",
  initialValues: {
    amount: 1,
    dispense: 1,
    refills: 0,
  },
});

MedicationCreateDrugForm.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
};

function MedicationCreateDrugForm({ handleSubmit, formData }) {
  const $route = (formData.routList || []).map((item) => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  ));

  const $frequency = (formData.routFrequencyList || []).map((item) => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  ));

  return (
    <FlexBox
      flex={true}
      style={{ overflowX: "hidden" }}
      direction="column"
      className="form-inline medication-drug-form"
      containerElement={<form onSubmit={handleSubmit} />}
    >
      <div className="row">
        <MedicationFormItem label="Name:" name="medicationName" />
        <MedicationFormItem label="Amount:" name="amount" />
        <MedicationFormItem label="Strength:" name="strength" />
      </div>

      <div className="row">
        <FlexBox gutter={8} align="center" className="form-group col-sm-4">
          <FlexBox>
            <label htmlFor="rout" className="control-label">
              Route:
            </label>
          </FlexBox>

          <FlexBox flex={true}>
            <Field name="rout" component="select" className="form-control">
              <option />
              {$route}
            </Field>
          </FlexBox>
        </FlexBox>
        <FlexBox gutter={8} align="center" className="form-group col-sm-4">
          <FlexBox>
            <label htmlFor="routFrequency" className="control-label">
              Frequency:
            </label>
          </FlexBox>

          <FlexBox flex={true}>
            <Field name="routFrequency" component="select" className="form-control">
              <option />
              {$frequency}
            </Field>
          </FlexBox>
        </FlexBox>
        <MedicationFormItem label="Refills:" name="refills" inputType="number" />
      </div>

      <div className="row">
        <MedicationFormItem label="Dispense:" name="dispense" />
        <MedicationFormItem label="Instruction:" name="instructions" />
      </div>
    </FlexBox>
  );
}

export default enhancer(MedicationCreateDrugForm);
