import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { DatePicker } from "../ui/DatePicker";
import { setDate } from "../../actions/callListActions";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

const enhancer = connect(
  ({ callList: { date } }) => {
    return {
      date,
    };
  },
  { setDate },
);

class CallListDatePicker extends React.Component {
  onChange = (date) => {
    this.props.setDate(moment(date));
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange();
    }
  };

  render() {
    return (
      <DatePicker
        selected={this.props.date}
        onChange={this.onChange}
        dateFormat={DATE_FORMAT_LEGACY}
      />
    );
  }
}

export default enhancer(CallListDatePicker);
