import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import LC from "literallycanvas";

import FilledImage from "../../../widgets/FilledImage";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import _ from "lodash";
import callListImage from "../../../../assets/images/call-list/check_2x.png";
import { DatePicker } from "../../../ui/DatePicker";
import ChartFillInFormSelectTreatmentDiagnosisModal from "./ChartFillInFormSelectTreatmentDiagnosisModal";
import arrowImage from "../../../../assets/images/call-list/check_2x.png";

let operators;
let newValues;

const recursive = (operand) => {
  let index;
  let result;

  switch (operand) {
    case "*":
      index = operators.indexOf("*");
      result = newValues[index] * newValues[index + 1];
      break;

    case "/":
      index = operators.indexOf("/");
      result = newValues[index] / newValues[index + 1];
      break;

    case "+":
      index = operators.indexOf("+");
      result = newValues[index] + newValues[index + 1];
      break;

    default:
      index = operators.indexOf("*");
      result = newValues[index] * newValues[index + 1];
  }

  newValues.splice(index, 2, result);
  operators.splice(index, 1);
};

const enhancer = connect(
  ({ chartSideFillInForm }) => ({ chartSideFillInForm }),
  bindActions({ chartSideFillInFormActions }),
);

class ChartFillInFormQuestion extends React.Component {
  constructor(...args) {
    super(...args);

    const answers = this.props.question.answers || [];

    this.state = {
      timerId: 0,
      textarea: _.head(answers) || "",
    };
  }

  changeTextarea = (value) => {
    if (this.state.timerId) {
      clearTimeout(this.state.timerId);
    }

    this.setState({
      ...this.state,
      textarea: value,
    });
    const timerId = setTimeout(() => {
      this.props.chartSideFillInFormActions.changeQuestionTextarea(value, this.props.question.id);
    }, 200);

    this.setState({ timerId });
  };

  saveCanvas = (value) => {
    this.props.chartSideFillInFormActions.changeQuestionCanvas(value, this.props.question.id);
  };

  changeListOfAnswers = (value) => {
    return () => {
      this.props.chartSideFillInFormActions.changeListOfAnswers(value, this.props.question.id);
    };
  };

  changeListOfAnswersMultiple = (value) => {
    return () => {
      this.props.chartSideFillInFormActions.changeListOfAnswersMultiple(
        value,
        this.props.question.id,
      );
    };
  };

  changeDatePicker = (date) => {
    this.props.chartSideFillInFormActions.changeDatePicker(date, this.props.question.id);
  };

  componentDidMount() {
    const newQuestion = this.props.question.id;
    const questionTypeCode = _.get(this.props, "question.questionType.code");

    const textarea =
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA;
    const radio =
      questionTypeCode === questionTypeCodes.VALUE_RADIO_LIST ||
      questionTypeCode === questionTypeCodes.RADIO_LIST;
    const multipleAnswer = questionTypeCode === questionTypeCodes.CHECKBOX_LIST;

    const value =
      _.get(this.props, "question.answers[0]", "") ||
      _.get(this.props, "question.defaultAnswers[0]", "");

    if (newQuestion && textarea) {
      setTimeout(() => {
        this.props.chartSideFillInFormActions.changeQuestionTextarea(value, this.props.question.id);
        if (this.refs.textarea) this.refs.textarea.value = _.unescape(value);
      }, 0);
    }
    if (newQuestion && radio) {
      setTimeout(() => {
        this.props.chartSideFillInFormActions.changeListOfAnswers(value, this.props.question.id);
      }, 0);
    }

    if (multipleAnswer) {
      let otherValue = "";
      const answers = _.get(this.props.question, "answers", []);
      answers
        .filter((item) => item)
        .forEach((x) => {
          if (this.props.question.values.indexOf(x) === -1) {
            otherValue = x;
          }
        });

      setTimeout(() => {
        this.props.chartSideFillInFormActions.setMultiQuestionOtherValue(
          this.props.question.id,
          otherValue,
        );
      }, 0);
    }
  }

  componentDidUpdate(prevProps) {
    const newQuestion = this.props.question.id;
    const questionTypeCode = _.get(this.props, "question.questionType.code");

    const textarea =
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA;
    const radio =
      questionTypeCode === questionTypeCodes.VALUE_RADIO_LIST ||
      questionTypeCode === questionTypeCodes.RADIO_LIST;
    const multipleAnswer = questionTypeCode === questionTypeCodes.CHECKBOX_LIST;

    const value =
      _.get(this.props, "question.answers[0]", "") ||
      _.get(this.props, "question.defaultAnswers[0]", "");
    if (this.props.question.id !== prevProps.question.id) {
      if (newQuestion && textarea) {
        setTimeout(() => {
          this.props.chartSideFillInFormActions.changeQuestionTextarea(
            value,
            this.props.question.id,
          );
          this.refs.textarea.value = _.unescape(value);
        }, 0);
      }

      if (newQuestion && radio) {
        setTimeout(() => {
          this.props.chartSideFillInFormActions.changeListOfAnswers(value, this.props.question.id);
        }, 0);
      }
      if (multipleAnswer) {
        let otherValue = "";

        const answers = _.get(this.props.question, "answers", []);
        answers
          .filter((item) => item)
          .forEach((x) => {
            if (this.props.question.values.indexOf(x) === -1) {
              otherValue = x;
            }
          });

        setTimeout(() => {
          this.props.chartSideFillInFormActions.setMultiQuestionOtherValue(
            this.props.question.id,
            otherValue,
          );
        }, 0);
      }
    }
  }

  render() {
    const { question } = this.props;
    const questionTypeCode = (question.questionType && question.questionType.code) || "";

    if (questionTypeCode === questionTypeCodes.VALUE_RADIO_LIST) {
      const { titles, values } = question;

      const radioButtons =
        values && titles && values.length >= titles.length
          ? titles.map((x, idx) => ({
              label: x,
              value: values[idx],
            }))
          : [];

      return (
        <div>
          {radioButtons.map((x, idx) => (
            <div key={idx}>
              <input
                onChange={this.changeListOfAnswers(x.value)}
                type="radio"
                value={x.value}
                id={`${question.name}-${idx}`}
                name={question.name}
              />
              <label htmlFor={`${question.name}-${idx}`}>{x.label}</label>
            </div>
          ))}
        </div>
      );
    }

    if (questionTypeCode === questionTypeCodes.RADIO_LIST) {
      const answers = question.answers || [];
      const defaultAnswers = _.get(question, "defaultAnswers[0]", "");

      const $values = (question.values || []).map((value, index) => {
        let $arrow = null;
        const selected =
          answers.indexOf(value) !== -1 || (!answers.length && defaultAnswers === value);
        if (selected) {
          if (!answers.length && defaultAnswers === value) {
            this.changeListOfAnswers(value);
          }
          $arrow = (
            <div className="arrow pull-right">
              <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
            </div>
          );
        }
        return (
          <button key={index} onClick={this.changeListOfAnswers(value)} className="list-group-item">
            {$arrow}
            {value}
          </button>
        );
      });
      return <div className="list-group">{$values}</div>;
    }
    if (
      questionTypeCode === questionTypeCodes.TEXT ||
      questionTypeCode === questionTypeCodes.TEXT_AREA
    ) {
      return (
        <>
          {this.props.chartSideFillInForm?.selectTreatmentModalVisible && (
            <ChartFillInFormSelectTreatmentDiagnosisModal
              type="treatment"
              onChange={this.changeTextarea}
              textAreaValue={_.unescape(this.state.textarea)}
            />
          )}
          {this.props.chartSideFillInForm?.selectDiagnosisModalVisible && (
            <ChartFillInFormSelectTreatmentDiagnosisModal
              type="diagnosis"
              onChange={this.changeTextarea}
              textAreaValue={_.unescape(this.state.textarea)}
            />
          )}
          <textarea
            ref="textarea"
            value={_.unescape(this.state.textarea)}
            onChange={(event) => this.changeTextarea(_.escape(event.target.value))}
          />
        </>
      );
    }
    if (questionTypeCode === questionTypeCodes.DATE_PICKER) {
      return (
        <div className="date-picker">
          <DatePicker
            inline={true}
            selected={
              (question.answers &&
                (moment.isMoment(question.answers[0])
                  ? question.answers[0]
                  : moment(+question.answers[0]))) ||
              moment()
            }
            onChange={this.changeDatePicker}
          />
        </div>
      );
    }
    if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST) {
      const answers = question.answers || [];
      const defaultAnswers = question.defaultAnswers || [];

      const $values = (question.values || []).map((value, index) => {
        let $arrow = null;
        const selected =
          answers.indexOf(value) !== -1 ||
          (!answers.length && defaultAnswers.indexOf(value) !== -1);

        if (selected) {
          if (!answers.length && defaultAnswers.indexOf(value) !== -1) {
            this.props.chartSideFillInFormActions.changeListOfAnswersMultiple(
              value,
              this.props.question.id,
            );
          }
          $arrow = (
            <div className="arrow pull-right">
              <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
            </div>
          );
        }

        return (
          <button
            key={index}
            onClick={this.changeListOfAnswersMultiple(value)}
            className="list-group-item"
          >
            {$arrow}
            {value}
          </button>
        );
      });

      const { multiQuestionOtherValue } = this.props.chartSideFillInForm;
      const otherSelected = !_.isEmpty(multiQuestionOtherValue[this.props.question.id]);

      $values.push(
        <button
          key={$values.length}
          className="list-group-item"
          onClick={() => {
            if (!otherSelected) {
              this.props.chartSideFillInFormActions.setMultiQuestionOtherValue(
                this.props.question.id,
                " ",
              );
            }

            this.changeListOfAnswersMultiple(multiQuestionOtherValue[this.props.question.id])();

            if (otherSelected) {
              this.props.chartSideFillInFormActions.setMultiQuestionOtherValue(
                this.props.question.id,
                "",
              );
            }
          }}
        >
          {otherSelected && (
            <div className="arrow pull-right">
              <FilledImage color="#23b7e5" src={callListImage} className="zoom-2-5x" />
            </div>
          )}
          Other
        </button>,
      );

      return (
        <div className="fill-in-forms-list-group-container">
          <div className="list-group">
            {$values}

            {otherSelected && (
              <div>
                <textarea
                  rows={5}
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                  value={multiQuestionOtherValue[this.props.question.id]}
                  onChange={(e) => {
                    this.changeListOfAnswersMultiple(
                      multiQuestionOtherValue[this.props.question.id],
                    )();
                    this.props.chartSideFillInFormActions.setMultiQuestionOtherValue(
                      this.props.question.id,
                      e.target.value,
                    );
                    this.changeListOfAnswersMultiple(e.target.value)();
                  }}
                >
                  {multiQuestionOtherValue[this.props.question.id]}
                </textarea>
              </div>
            )}
          </div>
        </div>
      );
    }
    if (questionTypeCode === questionTypeCodes.VALUE) {
      return (
        <input
          type="number"
          className="flex-none"
          value={!_.isEmpty(this.props.question.answers) ? this.props.question.answers[0] : ""}
          onChange={(e) =>
            this.props.chartSideFillInFormActions.changeQuestionTextarea(
              e.target.value,
              this.props.question.id,
            )
          }
        />
      );
    }

    if (questionTypeCode === questionTypeCodes.FORMULA) {
      const { questions } = this.props;
      const regExp = /[\+\-*\/]/g;
      const { formula = "" } = question;

      const questionPairs = {};

      questions.forEach((item) => {
        if (_.get(item, "questionType.code") !== questionTypeCodes.FORMULA) {
          questionPairs[item.code] = _.toFinite(_.head(_.get(item, "answers", []))) || 0;
        }
      });

      operators = formula.match(regExp);
      const values = formula
        .replace(/[\[\]]/g, "")
        .replace(regExp, "*")
        .split("*");
      newValues = values.map((item = "") => questionPairs[item.trim()]);

      while (operators.length > 0) {
        if (_.includes(operators, "*")) {
          recursive("*");

          continue;
        }

        if (_.includes(operators, "/")) {
          recursive("/");

          continue;
        }

        if (_.includes(operators, "+")) {
          recursive("+");

          continue;
        }

        if (_.includes(operators, "-")) {
          recursive("-");
        }
      }

      return (
        <div>
          <button
            className="btn btn-primary btn-lg btn-block"
            onClick={() =>
              this.props.chartSideFillInFormActions.changeQuestionTextarea(
                _.head(newValues) || 0,
                this.props.question.id,
              )
            }
          >
            Calculate
          </button>
        </div>
      );
    }

    if (questionTypeCode === questionTypeCodes.CANVAS) {
      const imageUrl = _.get(this.props, "question.image.url");
      const answerImageUrl = _.get(this.props, "question.answers", []);
      let imageHeight = _.get(this.props, "question.image.height");
      let imageWidth = _.get(this.props, "question.image.width");

      const img = new Image();

      img.crossOrigin = "Anonymous";
      img.src = imageUrl || answerImageUrl[0];
      if (answerImageUrl[0]) {
        imageWidth = img.width;
        imageHeight = img.height;
      }
      return (
        <div>
          <style>
            {"" +
              ".literally {\n" +
              "  min-height: " +
              (438 + 31) +
              "px;\n" + //todo need from server
              "  min-width: " +
              (808 + 61) +
              "px;\n" +
              "}" +
              ""}
          </style>
          <LC.LiterallyCanvasReactComponent
            watermarkImage={img}
            onInit={(lc) => (this.lc = lc)}
            imageURLPrefix="images/literallycanvas/img"
            imageSize={{ width: imageWidth, height: imageHeight }}
          />

          <button
            className="btn btn-primary"
            onClick={() => {
              this.saveCanvas(
                this.lc
                  .getImage({
                    scale: 1,
                    margin: { top: 0, right: 0, bottom: 0, left: 0 },
                  })
                  .toDataURL(),
              );
            }}
          >
            Save
          </button>
        </div>
      );
    }

    return null;
  }
}

export default enhancer(ChartFillInFormQuestion);
