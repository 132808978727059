import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import FilledImage from "../../../widgets/FilledImage";
import ChartClinicalNoteQuestionTeethCarousel from "./ChartClinicalNoteQuestionTeethCarousel";
import ChartClinicalNoteQuestionTooth from "./ChartClinicalNoteQuestionTooth";

import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";

import Utils from "../../../../helpers/Utils";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
import * as appointmentActions from "../../../../actions/appointmentActions";
import DataList from "../../../widgets/DataList";
import arrowImage from "../../../../assets/images/call-list/check_2x.png";
import { DatePicker } from "../../../ui/DatePicker";
import ChartClinicalQuestionsModal from "./ChartClinicalQuestionsModal";
import ChartClinicalSelectTreatmentDiagnosisModal from "./ChartClinicalSelectTreatmentDiagnosisModal";
import { getCompanyFormatFullDate } from "../../../../helpers/DateUtils";
import { STATUS_TYPES } from "../../../../constants/StatusTypes";
const enhancer = connect(
  ({
    appointment: {
      diagnosisCodeList,
      treatmentCodeList,
      selectedTreatmentCodeList,
      selectedDiagnosisCodeList,
    },
    chartSideClinicalNote: { setAppointmentVisitTime },
    chart,
  }) => ({
    diagnosisCodeList,
    treatmentCodeList,
    selectedTreatmentCodeList,
    selectedDiagnosisCodeList,
    chart,
  }),
  bindActions({ appointmentActions, chartSideClinicalNoteActions }),
);

export default enhancer(
  class ChartClinicalNoteQuestion extends React.Component {
    constructor(...args) {
      super(...args);

      const answers = this.props.question.answers || [];

      this.state = {
        timerId: 0,
        otherValue: "",
        textarea: _.head(answers) || " ",
      };
    }

    changeTextarea = (value) => {
      this.setState({
        ...this.state,
        textarea: _.unescape(value),
      });
      this.props.chartSideClinicalNoteActions.changeQuestionTextarea(
        _.unescape(value),
        this.props.question.id,
      );
    };

    changeListOfAnswers = (value) => {
      return () => {
        this.props.chartSideClinicalNoteActions.changeListOfAnswers(value, this.props.question.id);
      };
    };

    changeListOfAnswersMultiple = (value) => {
      return () => {
        this.props.chartSideClinicalNoteActions.changeListOfAnswersMultiple(
          value,
          this.props.question.id,
        );
      };
    };

    changeDatePicker = (date) => {
      this.props.chartSideClinicalNoteActions.changeDatePicker(date, this.props.question.id);
    };

    toggleActiveClassName = (name) => {
      const { question } = this.props;
      const answers = question.answers || [];

      const boolean = (answers || []).find((item) => {
        return item === name;
      });

      return boolean ? "selected" : "";
    };

    changeSurface = (event) => {
      this.props.chartSideClinicalNoteActions.changeSurface(
        event.target.id,
        this.props.question.id,
      );
    };

    componentWillUnmount() {
      this.props.chartSideClinicalNoteActions.resetClinicalNoteFor();
    }

    UNSAFE_componentWillMount() {
      this.props.chartSideClinicalNoteActions.clearMultiQuestions();
      this.setState({ textarea: "" });
    }

    componentDidMount() {
      if (this.props.question.id) {
        const {
          question: {
            id,
            answers,
            values,
            questionType: { code },
          },
        } = this.props;
        const textarea =
          _.get(this.props, "question.questionType.code") === questionTypeCodes.FREE_FORM_ENTRY;
        if (id && textarea) {
          const value =
            _.get(this.props, "question.answers[0]", "") ||
            _.get(this.props, "question.defaultAnswers[0]", "-----");
          if (value) {
            this.changeTextarea(_.unescape(value));
          } else {
            this.changeTextarea(_.unescape("-----"));
          }
        }
        if (id && code === questionTypeCodes.LIST_OF_ANSWERS_MULTIPLE_CHOICE) {
          let otherValue = "";
          Array.isArray(answers) &&
            answers.forEach((x) => {
              if (values.indexOf(x) === -1) {
                otherValue = x;
              }
            });

          this.setState({ otherValue });

          if (otherValue) {
            this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(id, otherValue);
          } else {
            this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(id, "");
          }
        }
      }
    }

    componentDidUpdate(prevProps) {
      const newQuestion = this.props.question.id !== prevProps.question.id;
      const textarea =
        _.get(this.props, "question.questionType.code") === questionTypeCodes.FREE_FORM_ENTRY;

      const { question: prevQuestion } = prevProps;
      const { question: nextQuestion } = this.props;

      const nextQuestionId = _.get(nextQuestion, "id");
      const prevQuestionId = _.get(prevQuestion, "id");
      const questionTypeCode = _.get(nextQuestion, "questionType.code");
      if (
        nextQuestionId !== prevQuestionId &&
        questionTypeCode === questionTypeCodes.LIST_OF_ANSWERS_MULTIPLE_CHOICE
      ) {
        let otherValue = "";
        this.setState({ textarea: "" });

        const answers = _.get(nextQuestion, "answers", []);

        Array.isArray(answers) &&
          answers.forEach((x) => {
            if ((nextQuestion.values || []).indexOf(x) === -1) {
              otherValue = x;
            }
          });
        this.setState({ otherValue });

        if (otherValue) {
          this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(
            nextQuestionId,
            otherValue,
          );
        } else {
          this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(nextQuestionId, "");
        }
      }
      if (nextQuestionId !== prevQuestionId && newQuestion && textarea) {
        const value =
          _.get(this.props, "question.answers[0]", "") ||
          _.get(this.props, "question.defaultAnswers[0]", "-----");
        if (value) {
          this.changeTextarea(_.unescape(value));
        } else {
          this.changeTextarea(_.unescape("-----"));
        }
      }
    }

    render() {
      const {
        teeth,
        question,
        toothNumericType,
        diagnosisCodeList,
        treatmentCodeList,
        appointmentActions,
        chart,
      } = this.props;

      const questionTypeCode = (question.questionType && question.questionType.code) || "";

      if (questionTypeCode === questionTypeCodes.LIST_OF_ANSWERS) {
        const answers = question.answers || [];
        const defaultAnswers = _.get(question, "defaultAnswers[0]", "");
        const $values = (question.values || []).map((value, index) => {
          let $arrow = null;
          const selected =
            answers.indexOf(value) !== -1 || (!answers.length && defaultAnswers === value);
          if (selected) {
            if (!answers.length && defaultAnswers === value) {
              this.props.chartSideClinicalNoteActions.changeListOfAnswers(
                value,
                this.props.question.id,
              );
            }
            $arrow = (
              <div className="arrow pull-right">
                <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
              </div>
            );
          }
          return (
            <button
              key={index}
              onClick={this.changeListOfAnswers(value)}
              className="list-group-item"
            >
              {$arrow}
              {value}
            </button>
          );
        });
        return <div className="list-group">{$values}</div>;
      }
      if (questionTypeCode === questionTypeCodes.FREE_FORM_ENTRY) {
        if (this.props.chartSideClinicalNote.setAppointmentTime) {
          const { appointment } = chart;
          this.props.appointmentActions.getHistory({ id: appointment.id }).then((response) => {
            const array = response || [];
            let timeValue = "";
            const checkedInItem = array.find(
              (item) => item.status.code === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN,
            );
            const completeItem = array.find(
              (item) => item.status.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE,
            );
            timeValue = ` appointment start time: ${getCompanyFormatFullDate(
              checkedInItem.modifiedDate,
            )}`;
            completeItem &&
              (timeValue =
                timeValue +
                `, completed time: ${getCompanyFormatFullDate(completeItem.modifiedDate)}`);

            this.changeTextarea(this.state.textarea + timeValue);
          });
          this.props.chartSideClinicalNoteActions.setAppointmentTime(false);
        }
        return (
          <>
            {this.props.chartSideClinicalNote?.questionsModalVisible && (
              <ChartClinicalQuestionsModal
                onChange={this.changeTextarea}
                value={_.unescape(this.state.textarea)}
              />
            )}
            {this.props.chartSideClinicalNote?.selectTreatmentModalVisible && (
              <ChartClinicalSelectTreatmentDiagnosisModal
                type="treatment"
                onChange={this.changeTextarea}
                textAreaValue={_.unescape(this.state.textarea)}
              />
            )}
            {this.props.chartSideClinicalNote?.selectTreatmentExistingModalVisible && (
              <ChartClinicalSelectTreatmentDiagnosisModal
                type="treatment"
                existing={true}
                onChange={this.changeTextarea}
                textAreaValue={_.unescape(this.state.textarea)}
              />
            )}
            {this.props.chartSideClinicalNote?.selectDiagnosisModalVisible && (
              <ChartClinicalSelectTreatmentDiagnosisModal
                type="diagnosis"
                onChange={this.changeTextarea}
                textAreaValue={_.unescape(this.state.textarea)}
              />
            )}
            <textarea
              value={_.unescape(this.state.textarea)}
              onChange={(event) => {
                event.persist();
                this.changeTextarea(_.unescape(event.target.value));
              }}
            />
          </>
        );
      }
      if (questionTypeCode === questionTypeCodes.DATE_SELECTOR) {
        return (
          <div className="date-picker">
            <DatePicker
              inline={true}
              selected={
                (question.answers &&
                  (moment.isMoment(question.answers[0])
                    ? question.answers[0]
                    : question.answers[0] && moment(+question.answers[0]))) ||
                moment()
              }
              onChange={this.changeDatePicker}
            />
          </div>
        );
      }
      if (questionTypeCode === questionTypeCodes.TEETH_SELECTION) {
        const normalTeeth = [];
        const superTeeth = [];

        Utils.objectForEach(teeth, (tooth) => {
          if (tooth.normal.details) {
            normalTeeth.push(tooth.normal.details);
          }

          if (tooth.supernumerary) {
            superTeeth.push(tooth.supernumerary.details);
          }
        });

        const $teeth = normalTeeth
          .concat(superTeeth)
          .sort((item1, item2) => item1.code - item2.code)
          .map((item, index) => (
            <ChartClinicalNoteQuestionTooth
              key={index}
              index={index}
              toothNumericType={toothNumericType}
              question={question}
              item={item}
            />
          ));

        return (
          <ChartClinicalNoteQuestionTeethCarousel>{$teeth}</ChartClinicalNoteQuestionTeethCarousel>
        );
      }
      if (questionTypeCode === questionTypeCodes.SURFACE_SELECTION) {
        return (
          <div className="surface-shape-wrap questions">
            <svg xmlns="http://www.w3.org/2000/svg" className="surface-shape questions">
              <g id="layer2" className="layer-selectors">
                <path
                  d="m 90.964129,34.80293 -5.92271,-5.99721 9.51677,-6.20335 C 115.18202,9.1590796 133.46751,3.2803796 157.17689,2.4707696 c 17.39025,-0.59384 29.49306,1.40368 45.85146,7.5676004 9.16501,3.45342 23.7402,11.32622 30.38349,16.41165 l 2.76214,2.11442 -5.55936,5.58736 -5.55938,5.58736 -9.9643,-4.32175 c -20.23057,-8.77448 -36.542,-11.79105 -58.75763,-10.8664 -20.41568,0.84973 -35.79688,4.65963 -52.16096,12.92019 -3.62702,1.83091 -6.750041,3.32893 -6.940041,3.32893 -0.19001,0 -3.01068,-2.69874 -6.26818,-5.9972 z"
                  id="F5"
                  className={this.toggleActiveClassName("F5")}
                />
                <path
                  d="M 101.55066,89.5828 79.643209,67.65478 84.435779,63.54722 C 125.94662,27.96958 188.14552,25.85844 232.35047,58.52674 c 12.917,9.54591 14.04228,6.32866 -10.94525,31.29277 l -21.87746,21.85695 -3.22156,-2.69397 c -3.99869,-3.34385 -10.21599,-6.48763 -18.13708,-9.17102 -8.29669,-2.81063 -24.80238,-2.81965 -33.14563,-0.0181 -6.87192,2.3075 -16.73392,7.59176 -18.78834,10.06719 -0.75328,0.90764 -1.68627,1.65026 -2.07332,1.65026 -0.38704,0 -10.56207,-9.8676 -22.61117,-21.92801 z"
                  id="F"
                  className={this.toggleActiveClassName("F")}
                />
                <path
                  d="m 70.530919,231.76066 c -30.52539,-35.50814 -36.92006,-87.0715 -16.04164,-129.35166 5.00591,-10.1373 10.64633,-18.83823 16.2848,-25.12102 l 4.09116,-4.55866 22.04897,22.11509 22.048971,22.11509 -2.2883,2.52371 c -11.13303,12.27835 -15.28003,35.80699 -9.36186,53.11597 1.41095,4.12661 4.27702,10.04645 6.36905,13.1552 2.09204,3.10875 4.0059,5.97945 4.25304,6.37932 0.24714,0.39988 -9.3907,10.55786 -21.417411,22.57329 l -21.86674,21.84624 -4.12004,-4.79257 0,0 z"
                  id="D"
                  className={this.toggleActiveClassName("D")}
                />
                <path
                  d="m 154.96718,205.82654 c -25.88859,-4.91054 -44.16164,-25.56293 -44.35653,-50.13223 -0.11507,-14.50637 4.89306,-26.76908 15.10191,-36.97793 10.20885,-10.20885 22.47155,-15.21698 36.97792,-15.10191 25.92218,0.20563 47.27591,20.32819 50.34335,47.44078 0.84255,7.44723 -1.58697,18.74539 -5.85754,27.23965 -5.68284,11.30329 -18.22047,21.65473 -30.87445,25.49086 -5.88371,1.78369 -17.05592,2.85237 -21.33466,2.04078 z"
                  id="I"
                  className={this.toggleActiveClassName("I")}
                />
                <path
                  d="m 226.48198,214.50205 -21.7519,-21.78444 2.59322,-3.31457 c 7.02572,-8.98003 11.73716,-22.74378 11.7091,-34.2064 -0.0302,-12.35504 -4.97717,-27.13695 -11.81571,-35.30641 l -2.69671,-3.22155 21.86884,-21.88936 21.86884,-21.88936 3.17006,3.01382 c 4.65786,4.42831 14.56873,19.71846 19.00515,29.32053 8.90363,19.27074 12.60085,45.50135 9.45038,67.04753 -2.67054,18.26392 -11.68207,39.82399 -22.5121,53.86014 -9.25542,11.99539 -6.12067,13.17564 -30.88917,-11.62993 z"
                  id="M"
                  className={this.toggleActiveClassName("M")}
                />
                <path
                  d="m 145.02349,273.18896 c -6.71725,-1.05671 -18.81035,-4.24075 -25.41165,-6.69072 -12.49646,-4.63788 -30.843241,-15.77881 -37.259341,-22.62542 l -2.45644,-2.62126 21.884401,-21.86388 21.8844,-21.86388 3.22155,2.69672 c 8.16946,6.83853 22.95137,11.78547 35.30641,11.81571 11.22976,0.0275 25.07058,-4.6999 34.25738,-11.70074 l 3.36555,-2.56472 21.86143,21.88195 21.86144,21.88195 -5.19961,4.57688 c -14.52287,12.78354 -35.33076,22.57995 -55.75047,26.24746 -7.57385,1.36032 -30.91545,1.87602 -37.56505,0.82995 z"
                  id="L"
                  className={this.toggleActiveClassName("L")}
                />
                <path
                  d="m 88.810729,277.42058 -5.92271,5.99721 9.51677,6.20335 c 20.623831,13.44329 38.909321,19.32199 62.618701,20.1316 17.39025,0.59384 29.49306,-1.40368 45.85146,-7.5676 9.16501,-3.45342 23.7402,-11.32622 30.38349,-16.41165 l 2.76214,-2.11442 -5.55936,-5.58736 -5.55938,-5.58736 -9.9643,4.32175 c -20.23057,8.77448 -36.542,11.79105 -58.75763,10.8664 -20.41568,-0.84973 -35.79688,-4.65963 -52.16096,-12.92019 -3.627021,-1.83091 -6.750041,-3.32893 -6.940041,-3.32893 -0.19001,0 -3.01068,2.69874 -6.26818,5.9972 z"
                  id="L5"
                  className={this.toggleActiveClassName("L5")}
                />
              </g>
              <g id="layer5" className="layer-text">
                <text
                  className={this.toggleActiveClassName("F")}
                  x="151.39026"
                  y="83.731735"
                  id="text5186"
                >
                  <tspan id="tspan5188" x="151.39026" y="83.731735">
                    F
                  </tspan>
                </text>
                <text
                  className={this.toggleActiveClassName("M")}
                  x="231.42844"
                  y="163.85165"
                  id="text5186-4"
                >
                  <tspan id="tspan5188-5" x="231.42844" y="163.85165">
                    M
                  </tspan>
                </text>
                <text
                  className={this.toggleActiveClassName("D")}
                  x="68.555466"
                  y="164.84732"
                  id="text5186-4-7"
                >
                  <tspan id="tspan5188-5-0" x="68.555466" y="164.84732">
                    D
                  </tspan>
                </text>
                <text
                  className={this.toggleActiveClassName("L")}
                  x="153.10406"
                  y="245.84048"
                  id="text5186-4-7-3"
                >
                  <tspan id="tspan5188-5-0-6" x="153.10406" y="245.84048">
                    L
                  </tspan>
                </text>
                <text
                  className={this.toggleActiveClassName("I")}
                  x="157.1601"
                  y="164.85159"
                  id="text5186-4-7-6"
                >
                  <tspan id="tspan5188-5-0-0" x="157.1601" y="164.85159">
                    I
                  </tspan>
                </text>
              </g>
              <g id="layer3" className="layer-buttons">
                <path
                  onClick={this.changeSurface}
                  d="m 90.964129,34.80293 -5.92271,-5.99721 9.51677,-6.20335 C 115.18202,9.1590796 133.46751,3.2803796 157.17689,2.4707696 c 17.39025,-0.59384 29.49306,1.40368 45.85146,7.5676004 9.16501,3.45342 23.7402,11.32622 30.38349,16.41165 l 2.76214,2.11442 -5.55936,5.58736 -5.55938,5.58736 -9.9643,-4.32175 c -20.23057,-8.77448 -36.542,-11.79105 -58.75763,-10.8664 -20.41568,0.84973 -35.79688,4.65963 -52.16096,12.92019 -3.62702,1.83091 -6.750041,3.32893 -6.940041,3.32893 -0.19001,0 -3.01068,-2.69874 -6.26818,-5.9972 z"
                  id="F5"
                >
                  <title>tooltip</title>
                </path>
                <path
                  onClick={this.changeSurface}
                  d="M 101.55066,89.5828 79.643209,67.65478 84.435779,63.54722 C 125.94662,27.96958 188.14552,25.85844 232.35047,58.52674 c 12.917,9.54591 14.04228,6.32866 -10.94525,31.29277 l -21.87746,21.85695 -3.22156,-2.69397 c -3.99869,-3.34385 -10.21599,-6.48763 -18.13708,-9.17102 -8.29669,-2.81063 -24.80238,-2.81965 -33.14563,-0.0181 -6.87192,2.3075 -16.73392,7.59176 -18.78834,10.06719 -0.75328,0.90764 -1.68627,1.65026 -2.07332,1.65026 -0.38704,0 -10.56207,-9.8676 -22.61117,-21.92801 z"
                  id="F"
                >
                  <title>tooltip</title>
                </path>
                <path
                  onClick={this.changeSurface}
                  d="m 70.530919,231.76066 c -30.52539,-35.50814 -36.92006,-87.0715 -16.04164,-129.35166 5.00591,-10.1373 10.64633,-18.83823 16.2848,-25.12102 l 4.09116,-4.55866 22.04897,22.11509 22.048971,22.11509 -2.2883,2.52371 c -11.13303,12.27835 -15.28003,35.80699 -9.36186,53.11597 1.41095,4.12661 4.27702,10.04645 6.36905,13.1552 2.09204,3.10875 4.0059,5.97945 4.25304,6.37932 0.24714,0.39988 -9.3907,10.55786 -21.417411,22.57329 l -21.86674,21.84624 -4.12004,-4.79257 0,0 z"
                  id="D"
                />
                <path
                  onClick={this.changeSurface}
                  d="m 154.96718,205.82654 c -25.88859,-4.91054 -44.16164,-25.56293 -44.35653,-50.13223 -0.11507,-14.50637 4.89306,-26.76908 15.10191,-36.97793 10.20885,-10.20885 22.47155,-15.21698 36.97792,-15.10191 25.92218,0.20563 47.27591,20.32819 50.34335,47.44078 0.84255,7.44723 -1.58697,18.74539 -5.85754,27.23965 -5.68284,11.30329 -18.22047,21.65473 -30.87445,25.49086 -5.88371,1.78369 -17.05592,2.85237 -21.33466,2.04078 z"
                  id="I"
                />
                <path
                  onClick={this.changeSurface}
                  d="m 226.48198,214.50205 -21.7519,-21.78444 2.59322,-3.31457 c 7.02572,-8.98003 11.73716,-22.74378 11.7091,-34.2064 -0.0302,-12.35504 -4.97717,-27.13695 -11.81571,-35.30641 l -2.69671,-3.22155 21.86884,-21.88936 21.86884,-21.88936 3.17006,3.01382 c 4.65786,4.42831 14.56873,19.71846 19.00515,29.32053 8.90363,19.27074 12.60085,45.50135 9.45038,67.04753 -2.67054,18.26392 -11.68207,39.82399 -22.5121,53.86014 -9.25542,11.99539 -6.12067,13.17564 -30.88917,-11.62993 z"
                  id="M"
                />
                <path
                  onClick={this.changeSurface}
                  d="m 145.02349,273.18896 c -6.71725,-1.05671 -18.81035,-4.24075 -25.41165,-6.69072 -12.49646,-4.63788 -30.843241,-15.77881 -37.259341,-22.62542 l -2.45644,-2.62126 21.884401,-21.86388 21.8844,-21.86388 3.22155,2.69672 c 8.16946,6.83853 22.95137,11.78547 35.30641,11.81571 11.22976,0.0275 25.07058,-4.6999 34.25738,-11.70074 l 3.36555,-2.56472 21.86143,21.88195 21.86144,21.88195 -5.19961,4.57688 c -14.52287,12.78354 -35.33076,22.57995 -55.75047,26.24746 -7.57385,1.36032 -30.91545,1.87602 -37.56505,0.82995 z"
                  id="L"
                />
                <path
                  onClick={this.changeSurface}
                  id="L5"
                  d="m 88.810729,277.42058 -5.92271,5.99721 9.51677,6.20335 c 20.623831,13.44329 38.909321,19.32199 62.618701,20.1316 17.39025,0.59384 29.49306,-1.40368 45.85146,-7.5676 9.16501,-3.45342 23.7402,-11.32622 30.38349,-16.41165 l 2.76214,-2.11442 -5.55936,-5.58736 -5.55938,-5.58736 -9.9643,4.32175 c -20.23057,8.77448 -36.542,11.79105 -58.75763,10.8664 -20.41568,-0.84973 -35.79688,-4.65963 -52.16096,-12.92019 -3.627021,-1.83091 -6.750041,-3.32893 -6.940041,-3.32893 -0.19001,0 -3.01068,2.69874 -6.26818,5.9972 z"
                />
              </g>
            </svg>
          </div>
        );
      }
      if (questionTypeCode === questionTypeCodes.LIST_OF_ANSWERS_MULTIPLE_CHOICE) {
        const answers = question.answers || [];
        const defaultAnswers = question.defaultAnswers || [];

        const $values = (question.values || []).map((value, index) => {
          let $arrow = null;
          const selected =
            answers.indexOf(value) !== -1 ||
            (!answers.length && defaultAnswers.indexOf(value) !== -1);

          if (selected) {
            if (!answers.length && defaultAnswers.indexOf(value) !== -1) {
              this.props.chartSideClinicalNoteActions.changeListOfAnswersMultiple(
                value,
                this.props.question.id,
              );
            }
            $arrow = (
              <div className="arrow pull-right">
                <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
              </div>
            );
          }

          return (
            <button
              key={index}
              onClick={this.changeListOfAnswersMultiple(value)}
              className="list-group-item"
            >
              {$arrow}
              {value}
            </button>
          );
        });

        const { multiQuestionOtherValue } = this.props.chartSideClinicalNote;

        const otherSelected = !_.isEmpty(multiQuestionOtherValue[this.props.question.id]);

        $values.push(
          <button
            key={$values.length}
            className="list-group-item"
            onClick={() => {
              if (!otherSelected) {
                this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(
                  this.props.question.id,
                  " ",
                );
              }

              this.changeListOfAnswersMultiple(multiQuestionOtherValue[this.props.question.id])();

              if (otherSelected) {
                this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(
                  this.props.question.id,
                  "",
                );
              }
            }}
          >
            {otherSelected && (
              <div className="arrow pull-right">
                <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
              </div>
            )}
            Other
          </button>,
        );

        return (
          <div className="fill-in-forms-list-group-container">
            <div className="list-group">
              {$values}
              {otherSelected && (
                <textarea
                  rows={5}
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                  value={multiQuestionOtherValue[this.props.question.id]}
                  maxLength={200}
                  onChange={(e) => {
                    this.changeListOfAnswersMultiple(
                      multiQuestionOtherValue[this.props.question.id],
                    )();
                    this.props.chartSideClinicalNoteActions.setMultiQuestionOtherValue(
                      this.props.question.id,
                      e.target.value,
                    );
                    this.changeListOfAnswersMultiple(e.target.value)();
                  }}
                >
                  {multiQuestionOtherValue[this.props.question.id]}
                </textarea>
              )}
            </div>
          </div>
        );
      }
      if (questionTypeCode === questionTypeCodes.DIAGNOSES) {
        if (!diagnosisCodeList || diagnosisCodeList.length === 0) {
          return (
            <div
              style={{
                paddingTop: "12px",
                textAlign: "center",
              }}
            >
              Diagnosis not found
            </div>
          );
        }

        return (
          <DataList>
            {diagnosisCodeList.map((x, idx) => {
              const list = this.props.question.answers || [];
              const selected = Boolean(list.find(({ id }) => id === x.id));

              return (
                <button
                  key={idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "6px 0",
                    margin: "6px 0",
                    background: "none",
                    borderRadius: "4px",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    appointmentActions.switchClinicalNoteTreatmentCode(this.props.question.id, x)
                  }
                >
                  <div
                    style={{
                      width: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selected && (
                      <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
                    )}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderLeft: "1px solid #cccccc",
                      borderRight: "1px solid #cccccc",
                    }}
                  >
                    {x.code}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      padding: "0 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {x.description}
                  </div>
                </button>
              );
            })}
          </DataList>
        );
      }
      if (questionTypeCode === questionTypeCodes.TREATMENTS_COMPLETED) {
        if (!treatmentCodeList || treatmentCodeList.length === 0) {
          return (
            <div
              style={{
                paddingTop: "12px",
                textAlign: "center",
              }}
            >
              Completed treatment not found
            </div>
          );
        }

        return (
          <DataList>
            {treatmentCodeList.map((x, idx) => {
              const list = this.props.question.answers || [];
              const selected = Boolean(list.find(({ id }) => id === x.id));

              return (
                <button
                  key={idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "6px 0",
                    margin: "6px 0",
                    background: "none",
                    borderRadius: "4px",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    appointmentActions.switchClinicalNoteTreatmentCode(this.props.question.id, x)
                  }
                >
                  <div
                    style={{
                      width: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selected && (
                      <FilledImage color="#23b7e5" src={arrowImage} className="zoom-2-5x" />
                    )}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderLeft: "1px solid #cccccc",
                      borderRight: "1px solid #cccccc",
                    }}
                  >
                    {x.code}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      padding: "0 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {x.description}
                  </div>
                </button>
              );
            })}
          </DataList>
        );
      }

      return null;
    }
  },
);
