import { httpClient } from "./BaseApi";

const DIAGNOSIS_ITEM_URL = "api/diagnosis/code/item.json";
const DIAGNOSIS_LIST_URL = "api/diagnosis/code/list.json";
const DIAGNOSIS_TYPE_AHEAD_URL = "api/diagnosis/code/typeahead.json";
const DIAGNOSIS_CHOOSE_BY_CODES_URL = "api/diagnosis/code/choose-by-codes.json";
const DIAGNOSIS_TYPE_LIST_URL = "api/diagnosis/code/claim-type/choose.json";

const DiagnosisApi = {
  getDiagnosisTypeList: () => httpClient.get(DIAGNOSIS_TYPE_LIST_URL),
  getList: (params) => httpClient.get(DIAGNOSIS_LIST_URL, { params }),
  getItem: (params) => httpClient.get(DIAGNOSIS_ITEM_URL, { params }),
  getTypeAhead: (params) => httpClient.get(DIAGNOSIS_TYPE_AHEAD_URL, { params }),
  getChooseByCode: (data) => httpClient.post(DIAGNOSIS_CHOOSE_BY_CODES_URL, { data }),
};

export default DiagnosisApi;
