import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { updateTransactionDiscountApi } from "../../../actions/chartSideTransactionActions";
import { updateTransactionList } from "../../../actions/transactionActions";

const enhancer = connect();

export const DiscountCellWrapper = enhancer(
  class DiscountCellWrapper extends React.PureComponent {
    static propTypes = {
      value: PropTypes.object,
      dispatch: PropTypes.func,
      transactionKey: PropTypes.string,
      className: PropTypes.string,
    };

    constructor(props) {
      super(props);

      this.state = {
        active: false,
        code: props.value ? props.value.code : "AMOUNT",
        value: props.value ? props.value.value : undefined,
      };
    }

    save = () => {
      const { code, value } = this.state;
      const { dispatch, transactionKey } = this.props;

      dispatch(startLoader());

      const data = {
        discount: { code, value },
      };

      dispatch(
        updateTransactionDiscountApi({
          keys: [transactionKey],
          ...data,
        }),
      )
        .then(() => {
          dispatch(
            updateTransactionList({
              ...data,
              key: transactionKey,
            }),
          );

          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    };

    render() {
      const { code, active, value } = this.state;

      return (
        <div
          role="button"
          className={`change-to-cash-wrapper align-items-center ${this.props.className}`}
          style={{ margin: "-8px 0", marginLeft: "8px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            value={active}
            className="mt-0"
            onChange={() => this.setState((x) => ({ active: !x.active }))}
          />

          <div className="d-flex mr-2 ml-2">
            <div className="d-flex align-items-center mr-1">
              <input
                type="radio"
                checked={code === "AMOUNT"}
                className="m-0"
                id="DiscountAmount"
                disabled={!active}
                onChange={() => this.setState(() => ({ code: "AMOUNT" }))}
              />
              <label htmlFor="DiscountAmount" className="mb-0 ml-1" style={{ marginTop: "2px" }}>
                Amount
              </label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                className="m-0"
                disabled={!active}
                id="DiscountPercentage"
                checked={code === "PERCENTAGE"}
                onChange={() => this.setState(() => ({ code: "PERCENTAGE" }))}
              />
              <label
                htmlFor="DiscountPercentage"
                className="mb-0 ml-1"
                style={{ marginTop: "2px" }}
              >
                Percentage
              </label>
            </div>
          </div>
          <input
            value={value}
            disabled={!active}
            className="form-control"
            onBlur={() => this.save()}
            style={{ width: "60px" }}
            onChange={({ target }) => this.setState({ value: target.value })}
            onKeyUp={(x) => {
              if (x.key === "Enter") {
                this.save();
              }
            }}
          />
        </div>
      );
    }
  },
);
