import _ from "lodash";
import React, { Component } from "react";

import { connect } from "react-redux";

import { ListGroup, ListGroupItem, Card } from "react-bootstrap";
import {
  changeTransactionFormByKey,
  deleteDiagnosisItem,
  openDiagnosisDropdown,
} from "../../../actions/chartSideSeriesActions";
import { SidebarStackTypes } from "../../chart-treatments-sidebar/ChartTreatmentsSidebar";
import deleteImage from "../../../assets/images/call-list/delete_2x.png";

const enhancer = connect(
  ({ chartSideSeries }) => {
    return {
      chartSideSeries,
    };
  },
  {
    deleteDiagnosisItem,
    openDiagnosisDropdown,
    changeTransactionFormByKey,
  },
);

class DiagnosisBox extends Component {
  openDiagnosis = (item) => {
    const { openDiagnosisDropdown, pushStack } = this.props;
    openDiagnosisDropdown(item);

    pushStack(SidebarStackTypes.DiagnosisFavorites);
    localStorage.setItem("addDiagnosisToTreatment", true);
  };

  editDiagnosis = (item) => {
    const { changeTransactionFormByKey, pushStack } = this.props;

    changeTransactionFormByKey(item, "editDiagnoseItem");

    pushStack(SidebarStackTypes.DiagnosisFavorites);
  };

  removeDiagnosis = (id) => {
    const { changeTransactionFormByKey } = this.props;

    changeTransactionFormByKey(id, "removeDiagnosisItem");
  };

  render() {
    const { chartSideSeries } = this.props;
    const title = "Diagnosis: ";
    const { diagnosisItems } = chartSideSeries;
    const form = chartSideSeries.transactionForm;

    if (!form) return null;

    const removedDiagnosis = chartSideSeries.removedDiagnosis;

    return (
      <div className="diagnosis-wrap d-flex flex-grow-1 flex-shrink-0 flex-column">
        <Card header={title}>
          <ListGroup style={{ overflowY: "auto" }} fill={true}>
            {diagnosisItems.map((x, idx) => {
              const diagnosisCode = _.get(x, "code", "");
              const diagnosisDescription = _.get(x, "description", "");

              const title = [diagnosisCode, diagnosisDescription].filter(Boolean).join(" ");

              return (
                <ListGroupItem
                  key={idx}
                  className={`clearfix d-flex ${
                    removedDiagnosis.diagnosisItem
                      ? "deleted"
                      : form.diagnosisItem && form.diagnosisItem.description
                      ? "selected"
                      : ""
                  }`}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    title={title}
                    className="select-btn flex-shrink-1 flex-grow-1"
                  >
                    {title}
                  </div>
                  <button
                    style={{ width: "84px", position: "inherit" }}
                    className="btn btn-danger edit-btn"
                    onClick={() => this.removeDiagnosis(idx)}
                  >
                    <img width={18} src={deleteImage} alt="delete" />
                  </button>
                </ListGroupItem>
              );
            })}
            <ListGroupItem className="clearfix d-flex">
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                className="select-btn flex-grow-1 flex-shrink-1"
              />
              <button
                style={{ width: "150px", position: "inherit" }}
                className="btn btn-primary edit-btn"
                onClick={() => this.openDiagnosis("diagnosisItem2")}
              >
                Add Diagnosis
              </button>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }
}

export default enhancer(DiagnosisBox);
