const Colors = {
  selectAppointmentStatus(statusCode, color) {
    const appointmentStatus = JSON.parse(localStorage.getItem("appointmentStatus"));
    appointmentStatus[statusCode].color = color;
    localStorage.setItem("appointmentStatus", JSON.stringify(appointmentStatus));
  },
  getStatusColors() {
    return JSON.parse(localStorage.getItem("appointmentStatus"));
  },
  getStatusColor(code) {
    return this.getStatusColors()[code];
  },

  selectAppointmentDoctor(doctorId, color) {
    const appointmentDoctor = JSON.parse(localStorage.getItem("appointmentDoctor"));
    appointmentDoctor[doctorId].color = color;
    localStorage.setItem("appointmentDoctor", JSON.stringify(appointmentDoctor));
  },
  getDoctorColors() {
    return JSON.parse(localStorage.getItem("appointmentDoctor"));
  },
  getDoctorColor(id) {
    return this.getDoctorColors()[id];
  },

  selectAppointmentType(typeCode, color) {
    const appointmentType = JSON.parse(localStorage.getItem("appointmentType"));
    appointmentType[typeCode].color = color;
    localStorage.setItem("appointmentType", JSON.stringify(appointmentType));
  },
  getTypeColors() {
    return JSON.parse(localStorage.getItem("appointmentType"));
  },
  getTypeColor(code) {
    return this.getTypeColors()[code];
  },

  getAppointmentColor(point) {
    if (localStorage.getItem("appointmentColorType") === "type") {
      if (point.category) {
        return Colors.getTypeColor(point.category.name);
      }
    }
    if (localStorage.getItem("appointmentColorType") === "doctor") {
      if (point.dentist) {
        return Colors.getDoctorColor(point.dentist.id);
      }
    }
  },
  getBTStypeColors() {
    return JSON.parse(localStorage.getItem("blockedTimeSlotType"));
  },
  getBTStypeColor(code) {
    return this.getBTStypeColors()[code];
  },
  selectBTStypeColor(typeCode, color) {
    const blockedTimeSlotType = JSON.parse(localStorage.getItem("blockedTimeSlotType"));
    blockedTimeSlotType[typeCode].color = color;
    localStorage.setItem("blockedTimeSlotType", JSON.stringify(blockedTimeSlotType));
  },
};

export default Colors;
