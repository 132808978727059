import React from "react";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";

const enhancer = connect(null, bindActions({ chartActions }));

class RepeatAppointmentHeader extends React.Component {
  handleSubmit = (enabled) => {
    if (enabled) this.props.chartActions.repeatAppointmentConfirmModalShow();
  };

  render() {
    return (
      <div className="modal-header repeat-appointment-header clearfix">
        <button
          className="pull-left btn btn-link"
          onClick={this.props.chartActions.repeatAppointmentModalHide}
          type="button"
        >
          ❮ &nbsp; BACK
        </button>
        <span className="repeat-appointment-header-title">FOLLOW UP</span>
      </div>
    );
  }
}

export default enhancer(RepeatAppointmentHeader);
