import _ from "lodash";
import $ from "jquery";
import React from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Utils from "../../../helpers/Utils";

import * as SeriesUtils from "../../../helpers/chartSideSeriesUtils";
import { SELECTED, TEETH_TYPES } from "../../../constants/Constants";

import { openSimpleModal } from "../../../actions/simpleModalActions";
import { changeTransactionByKey } from "../../../actions/chartSideSeriesActions";

const enhancer = connect(
  ({ session, chart, chartSideSeries }) => {
    return { session, chart, chartSideSeries };
  },
  { changeTransactionByKey, openSimpleModal },
);

export default enhancer(
  class SelectToothSurface extends React.PureComponent {
    selectItem = (e) => {
      e.stopPropagation();

      const {
        tooth,
        isEdit,
        typeTab,
        isSingle,
        isSelected,
        addTransaction,
        changeTransactionByKey,
      } = this.props;

      if (this.isAvailableSelection() && !isEdit) {
        if (addTransaction.editable && isSelected) {
          const $elem = $(e.target);
          const selectedId = $elem.attr("id");
          const key = selectedId.replace(/Btn/g, "");

          const type = TEETH_TYPES.SurfaceType[key];

          if (type) {
            changeTransactionByKey(
              type,
              "areaDetails",
              null,
              typeTab,
              SeriesUtils.getActiveTooth(tooth),
              isSingle
                ? {
                    id: tooth.code,
                    code: tooth.id,
                    details: tooth,
                  }
                : null,
            );
          }
        }
      }
    };

    toggleActiveClassName = (name) => {
      const { addTransaction } = this.props;

      let findItem;

      if (addTransaction && addTransaction.areaDetails) {
        findItem = addTransaction.areaDetails.filter((item) => name == item.code);
      }

      return findItem && findItem.length > 0 ? SELECTED : "";
    };

    toggleEditing = (e) => {
      e.stopPropagation();

      const { tooth, changeTransactionByKey, typeTab, isEdit } = this.props;

      if (isEdit) {
        return;
      }

      if (this.isAvailableSelection()) {
        this.setActive();

        changeTransactionByKey(1, "editable", null, typeTab, SeriesUtils.getActiveTooth(tooth));
      }
    };

    setActive = () => {
      const { isEdit, changeActiveTooth, numericKey, activeTooth, addTransaction } = this.props;

      if (isEdit) {
        return;
      }

      const currentTooth = _.toFinite(_.get(addTransaction, `tooth.details.${numericKey}`));

      if (changeActiveTooth && currentTooth !== activeTooth) {
        changeActiveTooth();
      }
    };

    isAvailableSelection = () => {
      const { tooth, openSimpleModal, chartSideSeries } = this.props;

      if (SeriesUtils.isNotAllowedTooth(chartSideSeries.item.item, tooth.code)) {
        openSimpleModal({
          body: "Tooth not allowed",
        });
        return false;
      }

      return true;
    };

    render() {
      const { tooth, isSingle, className, numericKey, addTransaction } = this.props;
      let numeric = null;
      if (tooth) numeric = tooth[numericKey];

      let surfaces = Utils.getSurfaces(tooth.position);
      if (surfaces && surfaces.length) {
        return (
          <div
            role="button"
            onClick={(e) => {
              if (!isSingle) {
                e.stopPropagation();

                this.setActive();
              }
            }}
            className={cx("surface-shape-wrap", className, {
              "align-items-center without-border": isSingle,
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="surface-shape"
              viewBox="10 0 300 311"
            >
              <g id="layer2" className="layer-selectors">
                <path
                  d="m 90.964129,34.80293 -5.92271,-5.99721 9.51677,-6.20335 C 115.18202,9.1590796 133.46751,3.2803796 157.17689,2.4707696 c 17.39025,-0.59384 29.49306,1.40368 45.85146,7.5676004 9.16501,3.45342 23.7402,11.32622 30.38349,16.41165 l 2.76214,2.11442 -5.55936,5.58736 -5.55938,5.58736 -9.9643,-4.32175 c -20.23057,-8.77448 -36.542,-11.79105 -58.75763,-10.8664 -20.41568,0.84973 -35.79688,4.65963 -52.16096,12.92019 -3.62702,1.83091 -6.750041,3.32893 -6.940041,3.32893 -0.19001,0 -3.01068,-2.69874 -6.26818,-5.9972 z"
                  id={`${surfaces[0].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[0].code)}
                />
                <path
                  d="M 101.55066,89.5828 79.643209,67.65478 84.435779,63.54722 C 125.94662,27.96958 188.14552,25.85844 232.35047,58.52674 c 12.917,9.54591 14.04228,6.32866 -10.94525,31.29277 l -21.87746,21.85695 -3.22156,-2.69397 c -3.99869,-3.34385 -10.21599,-6.48763 -18.13708,-9.17102 -8.29669,-2.81063 -24.80238,-2.81965 -33.14563,-0.0181 -6.87192,2.3075 -16.73392,7.59176 -18.78834,10.06719 -0.75328,0.90764 -1.68627,1.65026 -2.07332,1.65026 -0.38704,0 -10.56207,-9.8676 -22.61117,-21.92801 z"
                  id={`${surfaces[1].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[1].code)}
                />
                <path
                  d="m 226.48198,214.50205 -21.7519,-21.78444 2.59322,-3.31457 c 7.02572,-8.98003 11.73716,-22.74378 11.7091,-34.2064 -0.0302,-12.35504 -4.97717,-27.13695 -11.81571,-35.30641 l -2.69671,-3.22155 21.86884,-21.88936 21.86884,-21.88936 3.17006,3.01382 c 4.65786,4.42831 14.56873,19.71846 19.00515,29.32053 8.90363,19.27074 12.60085,45.50135 9.45038,67.04753 -2.67054,18.26392 -11.68207,39.82399 -22.5121,53.86014 -9.25542,11.99539 -6.12067,13.17564 -30.88917,-11.62993 z"
                  id={`${surfaces[2].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[2].code)}
                />
                <path
                  d="m 154.96718,205.82654 c -25.88859,-4.91054 -44.16164,-25.56293 -44.35653,-50.13223 -0.11507,-14.50637 4.89306,-26.76908 15.10191,-36.97793 10.20885,-10.20885 22.47155,-15.21698 36.97792,-15.10191 25.92218,0.20563 47.27591,20.32819 50.34335,47.44078 0.84255,7.44723 -1.58697,18.74539 -5.85754,27.23965 -5.68284,11.30329 -18.22047,21.65473 -30.87445,25.49086 -5.88371,1.78369 -17.05592,2.85237 -21.33466,2.04078 z"
                  id={`${surfaces[3].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[3].code)}
                />
                <path
                  d="m 70.530919,231.76066 c -30.52539,-35.50814 -36.92006,-87.0715 -16.04164,-129.35166 5.00591,-10.1373 10.64633,-18.83823 16.2848,-25.12102 l 4.09116,-4.55866 22.04897,22.11509 22.048971,22.11509 -2.2883,2.52371 c -11.13303,12.27835 -15.28003,35.80699 -9.36186,53.11597 1.41095,4.12661 4.27702,10.04645 6.36905,13.1552 2.09204,3.10875 4.0059,5.97945 4.25304,6.37932 0.24714,0.39988 -9.3907,10.55786 -21.417411,22.57329 l -21.86674,21.84624 -4.12004,-4.79257 0,0 z"
                  id={`${surfaces[4].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[4].code)}
                />
                <path
                  d="m 145.02349,273.18896 c -6.71725,-1.05671 -18.81035,-4.24075 -25.41165,-6.69072 -12.49646,-4.63788 -30.843241,-15.77881 -37.259341,-22.62542 l -2.45644,-2.62126 21.884401,-21.86388 21.8844,-21.86388 3.22155,2.69672 c 8.16946,6.83853 22.95137,11.78547 35.30641,11.81571 11.22976,0.0275 25.07058,-4.6999 34.25738,-11.70074 l 3.36555,-2.56472 21.86143,21.88195 21.86144,21.88195 -5.19961,4.57688 c -14.52287,12.78354 -35.33076,22.57995 -55.75047,26.24746 -7.57385,1.36032 -30.91545,1.87602 -37.56505,0.82995 z"
                  id={`${surfaces[5].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[5].code)}
                />
                <path
                  d="m 88.810729,277.42058 -5.92271,5.99721 9.51677,6.20335 c 20.623831,13.44329 38.909321,19.32199 62.618701,20.1316 17.39025,0.59384 29.49306,-1.40368 45.85146,-7.5676 9.16501,-3.45342 23.7402,-11.32622 30.38349,-16.41165 l 2.76214,-2.11442 -5.55936,-5.58736 -5.55938,-5.58736 -9.9643,4.32175 c -20.23057,8.77448 -36.542,11.79105 -58.75763,10.8664 -20.41568,-0.84973 -35.79688,-4.65963 -52.16096,-12.92019 -3.627021,-1.83091 -6.750041,-3.32893 -6.940041,-3.32893 -0.19001,0 -3.01068,2.69874 -6.26818,5.9972 z"
                  id={`${surfaces[6].description}BtnSelector`}
                  className={this.toggleActiveClassName(surfaces[6].code)}
                />
              </g>
              <g id="layer5" className="layer-text">
                <text x="151.39026" y="83.731735" id="text5186">
                  <tspan id="tspan5188" x="151.39026" y="83.731735">
                    {surfaces[1].code}
                  </tspan>
                </text>
                <text x="231.42844" y="163.85165" id="text5186-4">
                  <tspan id="tspan5188-5" x="231.42844" y="163.85165">
                    {surfaces[2].code}
                  </tspan>
                </text>
                <text x="157.1601" y="164.85159" id="text5186-4-7-6">
                  <tspan id="tspan5188-5-0-0" x="157.1601" y="164.85159">
                    {surfaces[3].code}
                  </tspan>
                </text>
                <text x="68.555466" y="164.84732" id="text5186-4-7">
                  <tspan id="tspan5188-5-0" x="68.555466" y="164.84732">
                    {surfaces[4].code}
                  </tspan>
                </text>
                <text x="153.10406" y="245.84048" id="text5186-4-7-3">
                  <tspan id="tspan5188-5-0-6" x="153.10406" y="245.84048">
                    {surfaces[5].code}
                  </tspan>
                </text>
              </g>
              <g id="layer3" className="layer-buttons">
                <path
                  onClick={this.selectItem}
                  d="m 90.964129,34.80293 -5.92271,-5.99721 9.51677,-6.20335 C 115.18202,9.1590796 133.46751,3.2803796 157.17689,2.4707696 c 17.39025,-0.59384 29.49306,1.40368 45.85146,7.5676004 9.16501,3.45342 23.7402,11.32622 30.38349,16.41165 l 2.76214,2.11442 -5.55936,5.58736 -5.55938,5.58736 -9.9643,-4.32175 c -20.23057,-8.77448 -36.542,-11.79105 -58.75763,-10.8664 -20.41568,0.84973 -35.79688,4.65963 -52.16096,12.92019 -3.62702,1.83091 -6.750041,3.32893 -6.940041,3.32893 -0.19001,0 -3.01068,-2.69874 -6.26818,-5.9972 z"
                  id={`${surfaces[0].description}Btn`}
                >
                  <title>tooltip</title>
                </path>
                <path
                  onClick={this.selectItem}
                  d="M 101.55066,89.5828 79.643209,67.65478 84.435779,63.54722 C 125.94662,27.96958 188.14552,25.85844 232.35047,58.52674 c 12.917,9.54591 14.04228,6.32866 -10.94525,31.29277 l -21.87746,21.85695 -3.22156,-2.69397 c -3.99869,-3.34385 -10.21599,-6.48763 -18.13708,-9.17102 -8.29669,-2.81063 -24.80238,-2.81965 -33.14563,-0.0181 -6.87192,2.3075 -16.73392,7.59176 -18.78834,10.06719 -0.75328,0.90764 -1.68627,1.65026 -2.07332,1.65026 -0.38704,0 -10.56207,-9.8676 -22.61117,-21.92801 z"
                  id={`${surfaces[1].description}Btn`}
                >
                  <title>tooltip</title>
                </path>
                <path
                  onClick={this.selectItem}
                  d="m 226.48198,214.50205 -21.7519,-21.78444 2.59322,-3.31457 c 7.02572,-8.98003 11.73716,-22.74378 11.7091,-34.2064 -0.0302,-12.35504 -4.97717,-27.13695 -11.81571,-35.30641 l -2.69671,-3.22155 21.86884,-21.88936 21.86884,-21.88936 3.17006,3.01382 c 4.65786,4.42831 14.56873,19.71846 19.00515,29.32053 8.90363,19.27074 12.60085,45.50135 9.45038,67.04753 -2.67054,18.26392 -11.68207,39.82399 -22.5121,53.86014 -9.25542,11.99539 -6.12067,13.17564 -30.88917,-11.62993 z"
                  id={`${surfaces[2].description}Btn`}
                />
                <path
                  onClick={this.selectItem}
                  d="m 154.96718,205.82654 c -25.88859,-4.91054 -44.16164,-25.56293 -44.35653,-50.13223 -0.11507,-14.50637 4.89306,-26.76908 15.10191,-36.97793 10.20885,-10.20885 22.47155,-15.21698 36.97792,-15.10191 25.92218,0.20563 47.27591,20.32819 50.34335,47.44078 0.84255,7.44723 -1.58697,18.74539 -5.85754,27.23965 -5.68284,11.30329 -18.22047,21.65473 -30.87445,25.49086 -5.88371,1.78369 -17.05592,2.85237 -21.33466,2.04078 z"
                  id={`${surfaces[3].description}Btn`}
                />
                <path
                  onClick={this.selectItem}
                  d="m 70.530919,231.76066 c -30.52539,-35.50814 -36.92006,-87.0715 -16.04164,-129.35166 5.00591,-10.1373 10.64633,-18.83823 16.2848,-25.12102 l 4.09116,-4.55866 22.04897,22.11509 22.048971,22.11509 -2.2883,2.52371 c -11.13303,12.27835 -15.28003,35.80699 -9.36186,53.11597 1.41095,4.12661 4.27702,10.04645 6.36905,13.1552 2.09204,3.10875 4.0059,5.97945 4.25304,6.37932 0.24714,0.39988 -9.3907,10.55786 -21.417411,22.57329 l -21.86674,21.84624 -4.12004,-4.79257 0,0 z"
                  id={`${surfaces[4].description}Btn`}
                />
                <path
                  onClick={this.selectItem}
                  d="m 145.02349,273.18896 c -6.71725,-1.05671 -18.81035,-4.24075 -25.41165,-6.69072 -12.49646,-4.63788 -30.843241,-15.77881 -37.259341,-22.62542 l -2.45644,-2.62126 21.884401,-21.86388 21.8844,-21.86388 3.22155,2.69672 c 8.16946,6.83853 22.95137,11.78547 35.30641,11.81571 11.22976,0.0275 25.07058,-4.6999 34.25738,-11.70074 l 3.36555,-2.56472 21.86143,21.88195 21.86144,21.88195 -5.19961,4.57688 c -14.52287,12.78354 -35.33076,22.57995 -55.75047,26.24746 -7.57385,1.36032 -30.91545,1.87602 -37.56505,0.82995 z"
                  id={`${surfaces[5].description}Btn`}
                />
                <path
                  onClick={this.selectItem}
                  d="m 88.810729,277.42058 -5.92271,5.99721 9.51677,6.20335 c 20.623831,13.44329 38.909321,19.32199 62.618701,20.1316 17.39025,0.59384 29.49306,-1.40368 45.85146,-7.5676 9.16501,-3.45342 23.7402,-11.32622 30.38349,-16.41165 l 2.76214,-2.11442 -5.55936,-5.58736 -5.55938,-5.58736 -9.9643,4.32175 c -20.23057,8.77448 -36.542,11.79105 -58.75763,10.8664 -20.41568,-0.84973 -35.79688,-4.65963 -52.16096,-12.92019 -3.627021,-1.83091 -6.750041,-3.32893 -6.940041,-3.32893 -0.19001,0 -3.01068,2.69874 -6.26818,5.9972 z"
                  id={`${surfaces[6].description}Btn`}
                />
              </g>
            </svg>

            {!isSingle && (
              <div
                role="button"
                className={`btn btn-link btn-numeric ${addTransaction.editable ? "editable" : ""}`}
                onClick={this.toggleEditing}
              >
                {numeric}
              </div>
            )}
          </div>
        );
      }

      return null;
    }
  },
);
