import React from "react";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import Show from "../../../widgets/Show";
import ConfirmDeletePlanItemModal from "./ConfirmDeletePlanItemModal";
import DeleteImage from "../../../../assets/images/chart/delete.png";

function deleteTreatment(e, id, props) {
  e.stopPropagation();
  props.deletePlanItemConfirmShow(id);
}

export default function ChartPlanListItem(props) {
  const { item, deletePlanId } = props;
  const { openItem } = props;

  return (
    <div className="chart-side-prescription-list-item estimate" onClick={openItem}>
      <div className="delete" title="Delete" onClick={(e) => deleteTreatment(e, item.id, props)}>
        <img src={DeleteImage} alt="" width={17} height={17} />
      </div>
      <div className="item-title">{item.name}</div>
      <br />
      <div className="clearfix">
        <div className="pull-left">
          {Utils.parseDateFormat(Utils.calcDateWithTimeZone(item.createdDate), DATE_FORMAT_LEGACY)}
        </div>
        <div className="pull-right">
          by
          {item.creator && item.creator.name}
        </div>
      </div>
      <Show if={Boolean(deletePlanId)}>
        <ConfirmDeletePlanItemModal deletePlanId={deletePlanId} />
      </Show>
    </div>
  );
}
