import _ from "lodash";
import React from "react";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

import ToothDetailsType from "../constants/teeth/ToothDetailsType";
import questionTypeCodes from "../constants/chart-sidebar/questionTypeCodes";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT, TIME_FORMAT } from "../constants/Constants";
import Utils from "./Utils";

export function getNote(item, toothNumericType, inFillInForm, clinic, images) {
  const numericKey = toothNumericType?.code === "Index1" ? "numeric1" : "numeric2";

  const reqExp = /\[\[[^\]]*]]/g;
  const noteTemplateCode =
    (item.noteTemplate && item.noteTemplate.code) || (item.template && item.template.code) || "";

  return noteTemplateCode.replace(reqExp, (match) => {
    const code = match.slice(2, -2);
    let index = 0;

    if (code === "Current date") {
      return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
    }
    if (code === "Current time") {
      return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
    }
    if (code === "Patient chart number") {
      return item.patient && item.patient.chartNumber;
    }
    if (code === "Patient name") {
      return item.patient && item.patient.fullName;
    }
    if (code === "Patient nationality") {
      return _.get(item, "patient.nationality.name", "N/A");
    }
    if (code === "Patient primary insurance image") {
      for (const form in images) {
        for (const image in images[form]) {
          const { url } = images[form][image];

          if (url) {
            return `<img style="width: 100%; max-width: 300px" src=${url} />`;
          }
        }
      }
    }
    if (code === "Patient date of birth") {
      const dateFormat = localStorage.getItem("company-date-format");

      return Utils.parseDateFormat(
        Utils.calcDateWithTimeZone(item.patient && item.patient.dateOfBirth),
        dateFormat,
      );
    }
    if (code === "Dentist Name") {
      return (item.owner && item.owner.name) || (item.doctor && item.doctor.name);
    }
    if (code === "Check-in date time") {
      return Utils.parseDateFormat(
        Utils.calcDateWithTimeZone(item.checkedInDate),
        FULL_DATE_FORMAT,
      );
    }
    if (code === "Doctor license id") {
      return item.owner && item.owner.code;
    }
    if (code === "Patient last name") {
      return item.patient && item.patient.lastName;
    }
    if (code === "Patient first name") {
      return item.patient && item.patient.firstName;
    }
    if (code === "Patient primary card number") {
      return item.primaryInsuranceCardNumber;
    }
    if (code === "Patient secondary card number") {
      return item.secondaryInsuranceCardNumber;
    }
    if (code === "Patient gender") {
      return item.patient && item.patient.gender && item.patient.gender.name;
    }
    if (code === "Patient driver's licence number") {
      return item.patient && item.patient.documentDriverLicenseNumber;
    }
    if (code === "Patient social security number") {
      return item.patient && item.patient.documentSocialSecurityNumber;
    }
    if (code === "Patient social insurance number") {
      return item.patient && item.patient.documentSocialInsuranceNumber;
    }
    if (code === "Patient national id number") {
      return item.patient && item.patient.documentNationalIdNumber;
    }
    if (code === "Patient passport number") {
      return item.patient && item.patient.documentPasswordNumber;
    }
    if (code === "Clinic name") {
      return clinic.name;
    }
    if (code === "Patient mobile number") {
      return item.patient && item.patient.mobilePhoneNumber;
    }

    let question = _.get(item, "templateQuestionsAndAnswers", []).find((question, index111) => {
      index = index111;
      return question.code === code;
    });

    if (!question) {
      question = {
        id: 0,
        code: "",
        name: "",
        question: "",
        questionType: "",
        answers: [],
      };
    }

    let answer;

    if (!(question.answers && question.answers.length)) {
      answer = inFillInForm && code !== "Doctor stamp" ? "N/A" : match;
    } else if (
      question.questionType.code === questionTypeCodes.LIST_OF_ANSWERS ||
      question.questionType.code === questionTypeCodes.RADIO_LIST ||
      question.questionType.code === questionTypeCodes.FORMULA ||
      question.questionType.code === questionTypeCodes.VALUE ||
      question.questionType.code === questionTypeCodes.VALUE_RADIO_LIST
    ) {
      answer = question.answers[0];
    } else if (
      question.questionType.code === questionTypeCodes.FREE_FORM_ENTRY ||
      question.questionType.code === questionTypeCodes.TEXT ||
      question.questionType.code === questionTypeCodes.TEXT_AREA
    ) {
      answer = question.answers[0].replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else if (
      question.questionType.code === questionTypeCodes.DATE_SELECTOR ||
      question.questionType.code === questionTypeCodes.DATE_PICKER
    ) {
      answer = Utils.parseDateFormat(
        moment.isMoment(question.answers[0]) ? question.answers[0] : moment(+question.answers[0]),
        DATE_FORMAT_LEGACY,
      );
    } else if (question.questionType.code === questionTypeCodes.TEETH_SELECTION) {
      answer = question.answers
        .map((toothCode) => ToothDetailsType[toothCode][numericKey])
        .join(", ");
    } else if (question.questionType.code === questionTypeCodes.SURFACE_SELECTION) {
      answer = question.answers.join(", ");
    } else if (question.questionType.code === questionTypeCodes.CANVAS) {
      answer = `${ReactDOMServer.renderToString(
        <img alt="canvas" className="canvas-image" src={_.head(question.answers || [])} />,
      )}<br />`;
    } else if (
      question.questionType.code === questionTypeCodes.LIST_OF_ANSWERS_MULTIPLE_CHOICE ||
      question.questionType.code === questionTypeCodes.CHECKBOX_LIST
    ) {
      answer = question.answers.join("<br>");
    } else if (
      question.questionType.code === questionTypeCodes.TREATMENTS_COMPLETED ||
      question.questionType.code === questionTypeCodes.DIAGNOSES
    ) {
      answer = question.answers
        .map(({ code, description }) => `${code} - ${description}`)
        .join("<br />");
    }

    answer = `<span class='fill-in-form-answer' data-index='${index}'>${
      code === "Doctor stamp" ? match : answer ? `${answer}`.trim() : "N/A"
    }</span>`;

    return answer;
  });
}
