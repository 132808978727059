import _, { noop } from "lodash";
import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import FilledImage from "../widgets/FilledImage";
import { connect } from "react-redux";
import DataList from "../widgets/DataList";
import {
  clearPushNotificationAppointment,
  switchPushNotificationAppointment,
} from "../../actions/calendarActions";
import { appointmentSendPush } from "../../actions/remindersActions";
import MenuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";

const enhancer = connect(
  ({ calendar }) => ({
    selected: calendar.pushNotificationAppointments,
    appointments: (calendar.columns || []).reduce(
      (acc = [], { list = [] } = { list: [] }) => acc.concat(list),
      [],
    ),
  }),
  {
    clearPushNotificationAppointment,
    switchPushNotificationAppointment,
    appointmentSendPush,
  },
);

export const PushNotificationModal = enhancer(
  class PushNotificationModalView extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        search: "",
      };
    }

    componentDidMount() {
      const { clearPushNotificationAppointment } = this.props;

      clearPushNotificationAppointment();
    }

    render() {
      const {
        show,
        onClose,
        appointments,
        selected = {},
        appointmentSendPush,
        switchPushNotificationAppointment,
      } = this.props;
      const { search } = this.state;

      if (!show) {
        return null;
      }

      const list = appointments.filter(({ patient = {} }) => {
        const { lastName = "", firstName = "", middleName = "", chartNumber = "" } = patient;

        return (
          search === "" ||
          lastName.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          firstName.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          middleName.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          chartNumber.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      });

      return (
        <Modal
          show={show}
          size="small"
          onHide={onClose}
          title={<div>Please select appointments</div>}
          actions={
            <div>
              <Button onClick={onClose}>Close</Button>
              <Button
                bsStyle="primary"
                disabled={!Object.values(selected).reduce((acc, item) => acc || item, false)}
                onClick={() => appointmentSendPush()}
              >
                Done
              </Button>
            </div>
          }
          dialogClassName="push-notification-dialog"
          keyboard={false}
        >
          <div className="patient-details-household" style={{ height: "500px" }}>
            <div className="input-group search">
              <span className="input-group-addon">
                <FilledImage color={"#aaa"} src={MenuImage} className="zoom-2-5x" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="search"
                value={search}
                onChange={({ target }) => this.setState({ search: target.value })}
              />
            </div>
            <div className="list-group" onScroll={this.loadList}>
              <DataList fething={true}>
                {list.map(({ id, ...app }) => (
                  <button
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "40px",
                      border: "none",
                      background: "none",
                      borderBottom: "1px solid #cccccc",
                      alignItems: "center",
                      userSelect: "none",
                    }}
                    key={id}
                    onClick={() => switchPushNotificationAppointment(id)}
                  >
                    <span style={{ flex: 1, padding: "0 6px", textAlign: "left" }}>
                      {[
                        _.get(app, "patient.firstName"),
                        _.get(app, "patient.middleName"),
                        _.get(app, "patient.lastName"),
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </span>
                    <span style={{ padding: "0 6px" }}>{_.get(app, "status.name")}</span>
                    <span style={{ padding: "0 6px" }}>{_.get(app, "category.name")}</span>
                    <span style={{ padding: "0 6px" }}>{_.get(app, "dentist.name")}</span>
                    <span style={{ padding: "0 6px" }}>
                      {moment(_.get(app, "date")).format("DD/MM/YYYY")}
                    </span>
                    <div style={{ padding: "0 6px" }}>
                      <input type="checkbox" checked={selected[id]} onChange={noop} />
                    </div>
                  </button>
                ))}
              </DataList>
            </div>
          </div>
        </Modal>
      );
    }
  },
);
