import React from "react";

export default function condition(props) {
  const { property, toothImage, surface } = props;
  if (!property) return null;

  const src = require(`../../../assets/images/conditions/fracture/fracture_${
    surface ? "upper" : "lower"
  }_${toothImage}${surface ? "" : "_2"}@2x.png`).default;

  let className = "ConditionCrown-wrap";
  className += surface ? " tooth-image-ConditionCrown-center" : " tooth-image-ConditionCrown";
  return (
    <div className={className}>
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
