import React, { useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { FormControl } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Show from "../../../widgets/Show";
import Utils from "../../../../helpers/Utils";
import { DatePicker } from "../../../ui/DatePicker";
import { parseQuery } from "../../../../utils/UrlUtils";
import { SelectField } from "../../../form/SelectField";
import { getCompanyStartOfDay } from "../../../../helpers/DateUtils";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";
import { startLoader, stopLoader } from "../../../../actions/loaderActions";
import MedicalFillInFormVitalTooltip from "./MedicalFillInFormVitalTooltip";
import { pushStack } from "../../../../actions/chartSideMedicalFillInFormActions";
import { useShallowEqualSelector } from "../../../../hooks/useShallowEqualSelector";
import * as ActionTypes from "../../../../actionTypes/medicalFillInFormActionTypes";
import { chosenMemberIdSelector, membersSelector } from "../../../../reducers/authReducer";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import { useMedicalFillInFormVitalMutation } from "../../../../queries/medicalFillInFormVitals/useMedicalFillInFormVitalMutation";
import { useEditMedicalFillInFormVitalMutation } from "../../../../queries/medicalFillInFormVitals/useEditMedicalFillInFormVitalMutation";
import { useMedicalFillInFormVitalVoidMutation } from "../../../../queries/medicalFillInFormVitals/useMedicalFillInFormVitalVoidMutation";

const MedicalFillInFormVitalsForm = ({ mode, location }) => {
  const [tooltipVisible, setToolTipVisible] = useState({ visible: false });
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const {
    chartSideMedicalFillInForm: { vitalsInitialValues, patientVitalTransaction, consultationId },
    chart: { appointment },
  } = store;
  const isEditMode = mode === "edit";
  const query = parseQuery(location?.search);
  const vitalAddFormMutation = useMedicalFillInFormVitalMutation();
  const vitalEditFormMutation = useEditMedicalFillInFormVitalMutation();
  const vitalVoidFormMutation = useMedicalFillInFormVitalVoidMutation();
  const { doctors } = useSelector((store) => store.auth);
  const members = useShallowEqualSelector(membersSelector);
  const chosenMemberId = useShallowEqualSelector(chosenMemberIdSelector);
  const member = useMemo(() => members[chosenMemberId], [chosenMemberId, members]);

  const initialValues = useMemo(() => {
    if (isEditMode) {
      return {
        ...vitalsInitialValues,
        patientVitalTransaction,
      };
    }

    return {
      ...vitalsInitialValues,
      patientVitalTransaction,
      doctor: {
        value: member.id,
        label: `${member.firstName} ${member.lastName}`,
      },
    };
  }, [isEditMode]);

  const doctorsOptions = (doctors || [])
    .filter((i) => i.doctor)
    .map((item) => {
      return { value: item.id, label: Utils.getFullName(item) };
    });

  const stageOptions = (patientVitalTransaction?.stages || []).map((stage) => {
    return { value: stage.id, label: stage.name };
  });
  const validate = (values) => {
    const errors = {};

    if (!values.opIp) {
      errors["opIpError"] = true;
    }

    if (!values.visitId) {
      errors["visitIdError"] = true;
    }
    return errors;
  };

  const formatTransactionValues = ({ ...values }) => {
    const transfer = {
      visitId: values.visitId,
      stages: _.get(patientVitalTransaction, "stages"),
      patient: _.get(patientVitalTransaction, "patient"),
      opIp: values.opIp,
      appointmentId: _.get(appointment, "id", null),
      consultationId: consultationId,
      visitDate: isEditMode
        ? _.head(patientVitalTransaction.transactions).visitDate
        : new Date().valueOf(),
    };

    if (!_.isEmpty(values.doctor)) {
      transfer.doctor = {
        id: values.doctor.value,
      };
    }

    if (!_.isEmpty(values.stage)) {
      transfer.stage = {
        id: values.stage.value,
      };
    }

    transfer.transactions = (patientVitalTransaction.transactions || [])
      .filter((item) => Boolean(item?.vital?.id))
      .map((transaction) => {
        const currentValue = values[transaction.vital.name] || transaction?.value;
        const isNan = Object.is(Number(currentValue), NaN);
        if (!isEditMode && !values[transaction.vital.name]) {
          return {
            ...transaction,
          };
        }
        if (currentValue) {
          return {
            ...transaction,
            value: isNan
              ? currentValue
              : moment.isMoment(currentValue)
              ? getCompanyStartOfDay(currentValue)
              : Number(currentValue),
            comment: values[`${transaction.vital.name}_comment`]
              ? values[`${transaction.vital.name}_comment`]
              : transaction?.comment,
          };
        }
        return transaction;
      });
    return transfer;
  };

  const formatVoidValuesHandler = () => {
    const transfer = {
      ids: (patientVitalTransaction.transactions || [])
        .filter((transaction) => Boolean(transaction?.id))
        .map((item) => item?.id),
      voided: true,
      patientKey: _.get(patientVitalTransaction, "patient.patientKey") || query?.patientKey,
    };
    return dispatch(
      openSimpleModal({
        body: "Do you want to void this vital transaction ?",
        footer: (
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger ml-3"
              onClick={() => {
                dispatch(closeSimpleModal());
              }}
            >
              No
            </button>
            <button
              className="btn btn-success ml-3"
              onClick={async () => {
                dispatch(closeSimpleModal());
                dispatch(startLoader());
                try {
                  await vitalVoidFormMutation.mutateAsync(transfer);
                  dispatch(stopLoader());
                  dispatch(
                    pushStack({
                      code: medicalFillInFormStackCodes.vitals,
                      type: medicalFillInFormItemTypes.edit,
                    }),
                  );
                  dispatch({
                    type: ActionTypes.CLEAR_MEDICAL_FILL_IN_FORM_VITAL_VALUES,
                  });
                } catch (e) {
                  return dispatch(stopLoader(e));
                }
              }}
            >
              Yes
            </button>
          </div>
        ),
      }),
    );
  };

  const toolTipVisibleHandler = (event, fieldName, comment) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const elementCenterX = rect.left + rect.width / 4;
    const elementCenterY = rect.top + rect.height / 4;

    setToolTipVisible({
      visible: true,
      innerWidth: event.view.innerWidth,
      innerHeight: event.view.innerHeight,
      top: elementCenterY,
      left: elementCenterX,
      fieldName: fieldName,
      comment: comment,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const mutationFn = isEditMode ? vitalEditFormMutation : vitalAddFormMutation;
        dispatch(startLoader());
        try {
          await mutationFn.mutateAsync(formatTransactionValues(values));
          dispatch(stopLoader());
          dispatch(
            pushStack({
              code: medicalFillInFormStackCodes.vitals,
              type: medicalFillInFormItemTypes.edit,
            }),
          );
          dispatch({
            type: ActionTypes.CLEAR_MEDICAL_FILL_IN_FORM_VITAL_VALUES,
          });
        } catch (e) {
          return dispatch(stopLoader(e));
        }
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <>
            <Form
              className="d-flex flex-column bg-white"
              style={{
                border: "1px solid #c1c1c1",
                borderRadius: "5px",
                marginTop: "25px",
                overflow: "hidden",
              }}
            >
              <div className="p-1 text-center" style={{ background: "#c1c1c1", fontWeight: 600 }}>
                {isEditMode ? "Edit Vital Transactions" : "Add New Vital Transactions"}
              </div>
              <div style={{ padding: "10px" }}>
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-sm-3 d-flex p-0 justify-content-end">Doctor:</div>
                  <div className="col-sm-9">
                    <SelectField
                      name="doctor"
                      multi={false}
                      clearable={false}
                      searchable={false}
                      options={doctorsOptions}
                      value={values.doctor}
                      onChange={(x) => {
                        setFieldValue("doctor", x);
                      }}
                    />
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-sm-3 d-flex p-0 justify-content-end">Visit ID*:</div>
                  <div className="col-sm-9">
                    <FormControl
                      autoComplete="off"
                      type="number"
                      name="visitId"
                      className={
                        errors["visitIdError"] && touched["visitId"]
                          ? "form-control field-error"
                          : "form-control"
                      }
                      style={{ width: "100%" }}
                      placeholder="Visit ID"
                      value={values.visitId}
                      onChange={({ target: { value } }) => {
                        setFieldValue("visitId", Number(value));
                      }}
                    />
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-sm-3 d-flex p-0 justify-content-end">OP:</div>
                  <div className="col-sm-9">
                    <FormControl
                      autoComplete="off"
                      name="opIp"
                      className={
                        errors["opIpError"] && touched["opIp"]
                          ? "form-control field-error"
                          : "form-control"
                      }
                      style={{ width: "100%" }}
                      placeholder="OP"
                      defaultValue={values.opIp}
                      disabled={true}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-sm-3 d-flex p-0 justify-content-end">Stage:</div>
                  <div className="col-sm-9">
                    <SelectField
                      name="stage"
                      multi={false}
                      clearable={false}
                      searchable={false}
                      options={stageOptions}
                      value={values.stage}
                      onChange={(x) => {
                        setFieldValue("stage", x);
                      }}
                    />
                  </div>
                </div>
                <FieldArray name="patientVitalTransaction.transactions">
                  {() => {
                    return (
                      <>
                        {(values.patientVitalTransaction.transactions || []).map(
                          (transaction, idx) => {
                            const fieldName =
                              patientVitalTransaction.transactions[idx]?.vital?.name;
                            const fieldType = transaction?.vital?.fieldType?.code;

                            const renderInputField = (type, value, comment, onChange) => {
                              switch (type) {
                                case "FIELD_TYPE_TEXT":
                                  if (transaction?.vital?.name === "BMI") {
                                    return (
                                      <div className="d-flex align-items-center justify-content-center col-gap-2">
                                        <FormControl
                                          autoComplete="off"
                                          name="BMI"
                                          className="form-control"
                                          style={{ width: "50%" }}
                                          value={values.BMI}
                                          onChange={onChange}
                                          disabled={true}
                                        />

                                        <FormControl
                                          autoComplete="off"
                                          type="button"
                                          name="BMI_comment"
                                          onClick={(event) =>
                                            toolTipVisibleHandler(event, fieldName, comment)
                                          }
                                          placeholder="Comment"
                                          className="form-control vital-comment-filed"
                                          style={{
                                            width: "50%",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            textAlign: "start",
                                            color:
                                              values.BMI_comment || comment ? "#555555" : "#9a9a9a",
                                          }}
                                          defaultValue={
                                            values.BMI_comment || comment || "Comment..."
                                          }
                                        />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="d-flex align-items-center justify-content-center col-gap-2">
                                        <FormControl
                                          autoComplete="off"
                                          type="text"
                                          name={fieldName}
                                          className="form-control"
                                          style={{ width: "50%" }}
                                          value={values?.[fieldName]}
                                          onChange={onChange}
                                        />

                                        <FormControl
                                          autoComplete="off"
                                          placeholder="Comment"
                                          type="button"
                                          name={`${fieldName}_comment`}
                                          className="form-control"
                                          style={{
                                            width: "50%",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            textAlign: "start",
                                            color:
                                              values?.[`${fieldName}_comment`] || comment
                                                ? "#555555"
                                                : "#9a9a9a",
                                          }}
                                          defaultValue={values?.[`${fieldName}_comment`] || comment}
                                          onClick={(event) =>
                                            toolTipVisibleHandler(event, fieldName, comment)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                case "FIELD_TYPE_DIGITS":
                                  return (
                                    <div className="d-flex align-items-center justify-content-center col-gap-2">
                                      <FormControl
                                        autoComplete="off"
                                        type="number"
                                        name={fieldName}
                                        className="form-control"
                                        style={{ width: "50%" }}
                                        value={value}
                                        onChange={onChange}
                                      />

                                      <FormControl
                                        autoComplete="off"
                                        type="button"
                                        placeholder="Comment"
                                        name={`${fieldName}_comment`}
                                        className="form-control vital-comment-filed"
                                        style={{
                                          width: "50%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          textAlign: "start",
                                          color:
                                            values?.[`${fieldName}_comment`] || comment
                                              ? "#555555"
                                              : "#9a9a9a",
                                        }}
                                        onClick={(event) =>
                                          toolTipVisibleHandler(event, fieldName, comment)
                                        }
                                        defaultValue={
                                          values?.[`${fieldName}_comment`] ||
                                          comment ||
                                          "Comment..."
                                        }
                                      />
                                    </div>
                                  );
                                case "FIELD_TYPE_DATE":
                                  return (
                                    <div className="d-flex align-items-center justify-content-center col-gap-2">
                                      <div style={{ width: "50%" }}>
                                        <DatePicker
                                          selected={
                                            (value &&
                                              (moment.isMoment(value)
                                                ? moment(value).valueOf()
                                                : moment(+value))) ||
                                            null
                                          }
                                          name={fieldName}
                                          className="form-control"
                                          onChange={(x) => {
                                            values.patientVitalTransaction.transactions[
                                              idx
                                            ].value = moment(x);
                                            setFieldValue(fieldName, moment(x));
                                          }}
                                        />
                                      </div>

                                      <FormControl
                                        autoComplete="off"
                                        type="button"
                                        placeholder="Comment"
                                        name={`${fieldName}_comment`}
                                        className="form-control vital-comment-filed"
                                        style={{
                                          width: "50%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          textAlign: "start",
                                          color:
                                            values?.[`${fieldName}_comment`] || comment
                                              ? "#555555"
                                              : "#9a9a9a",
                                        }}
                                        onClick={(event) =>
                                          toolTipVisibleHandler(event, fieldName, comment)
                                        }
                                        defaultValue={
                                          values?.[`${fieldName}_comment`] ||
                                          comment ||
                                          "Comment..."
                                        }
                                      />
                                    </div>
                                  );
                                default:
                                  return null;
                              }
                            };

                            return (
                              <div className="row d-flex align-items-center mb-2" key={idx}>
                                <div className="col-sm-3 d-flex p-0 justify-content-end">
                                  {transaction.vital?.unit?.name
                                    ? `${transaction.vital?.unit?.name}:`
                                    : ""}
                                </div>
                                <div className="col-sm-9">
                                  {renderInputField(
                                    fieldType,
                                    values.patientVitalTransaction.transactions[idx].value,
                                    values.patientVitalTransaction.transactions[idx]?.comment || "",
                                    ({ target: { value } }) => {
                                      if (
                                        transaction.vital.unit.code ===
                                          "VITAL_UNIT_MEASURES_HEIGHT" ||
                                        transaction.vital.unit.code === "VITAL_UNIT_MEASURES_WEIGHT"
                                      ) {
                                        const height =
                                          transaction.vital.unit.code ===
                                          "VITAL_UNIT_MEASURES_HEIGHT"
                                            ? Number(value)
                                            : Number(values.Measures_Height);
                                        const weight =
                                          transaction.vital.unit.code ===
                                          "VITAL_UNIT_MEASURES_WEIGHT"
                                            ? Number(value)
                                            : Number(values.Measures_Weight);

                                        if (height && weight) {
                                          let bmi = (
                                            weight /
                                            ((height / 100) * (height / 100))
                                          ).toFixed(2);
                                          setFieldValue("BMI", Utils.calculateBMICategory(bmi));
                                        }
                                      }
                                      values.patientVitalTransaction.transactions[
                                        idx
                                      ].value = value;
                                      setFieldValue(fieldName, value);
                                    },
                                  )}
                                </div>
                              </div>
                            );
                          },
                        )}
                      </>
                    );
                  }}
                </FieldArray>
                <div
                  className={`btn-group d-flex align-items-center justify-content-center ${
                    isEditMode ? "col-gap-2" : ""
                  }`}
                >
                  <button
                    type="submit"
                    style={{ width: `${isEditMode ? 63 : 100}%`, borderRadius: "4px" }}
                    className={`btn btn-primary`}
                  >
                    Save
                  </button>
                  <Show if={isEditMode}>
                    <button
                      type="button"
                      style={{ width: "37%", borderRadius: "4px" }}
                      className={`btn btn-primary`}
                      onClick={formatVoidValuesHandler}
                    >
                      Void
                    </button>
                  </Show>
                </div>
              </div>
            </Form>

            <MedicalFillInFormVitalTooltip
              tooltipVisible={tooltipVisible}
              onClose={() => setToolTipVisible({ visible: false })}
              values={values}
              setFieldValue={setFieldValue}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default MedicalFillInFormVitalsForm;
