import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showMessage } from "../../../actions/loaderActions";
import AppointmentPanelSidebar from "./AppointmentPanelSidebar";
import { openSimpleModal } from "../../../actions/simpleModalActions";
import { moveAppointmentToWaitingPanel } from "../../../actions/appointmentActions";
import { onDragAppointmentsList } from "../../../actions/calendarActions";
import { copyToClipboardAppointment, getCalendarData } from "../../../actions/calendarActions";

const AppointmentPanel = ({ dndCount }) => {
  const store = useSelector((store) => store);
  const {
    session: { permissions, member, clinic },
    calendar: { appointmentDndCount },
  } = store;

  const dispatch = useDispatch();
  const [tooltipVisible, setToolTipVisible] = useState({ visible: false });

  const toolTipVisibleHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const rect = event.currentTarget.getBoundingClientRect();
    const elementCenterX = (rect.left + rect.width) / 4;
    const elementCenterY = rect.top + rect.height;

    setToolTipVisible({
      visible: true,
      innerWidth: event.view.innerWidth,
      innerHeight: event.view.innerHeight,
      top: elementCenterY,
      left: Math.floor(elementCenterX),
      width: Math.floor(rect.width),
    });
  };

  const prepareAppointmentData = (appointment, dentistRoom, member, startTime) => {
    return {
      ...appointment,
      id: appointment.id,
      dentalPoint: {
        id: dentistRoom.dentalPointId,
      },
      dentist: { id: dentistRoom.dentistId },
      creator: { id: member.id },
      startTime: startTime,
      inBoxDragged: true,
    };
  };

  const pasteAppointmentHandler = (dragData) => {
    const { appointment, columns } = dragData;
    const { dentalPoint, startTime } = appointment || {};

    if (permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
      return dispatch(
        openSimpleModal({
          body: "permission denied",
        }),
      );
    }
    const dentistRoom = (columns || []).find((room) => room.dentalPointId === dentalPoint?.id);

    if (!dentistRoom) {
      return dispatch(showMessage("Dentist room not found"));
    }

    if (appointmentDndCount === 5) {
      return dispatch(showMessage("Maximum 5 appointments can be moved to the list."));
    }

    const appointmentData = prepareAppointmentData(appointment, dentistRoom, member, startTime);

    dispatch(moveAppointmentToWaitingPanel(appointmentData)).then(() => {
      dispatch(copyToClipboardAppointment(null, null));
      dispatch(getCalendarData(false, clinic.id));
      dispatch(onDragAppointmentsList());
    });
  };

  return (
    <div
      id={"appointment-dnd-panel"}
      className="copy-past-panel"
      onDrop={(event) => {
        event.preventDefault();
        event.stopPropagation();

        const calendarForm = document.querySelector(".dnd-calendar-backdrop");
        const dragData = JSON.parse(event.dataTransfer.getData("application/json"));
        calendarForm.classList.remove("dnd-calendar-backdrop");

        pasteAppointmentHandler(dragData);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <div className="appointments-btn" role="button" onClick={toolTipVisibleHandler}>
        <span>Appointments</span>
        {dndCount > 0 && <div className="notification-chip">{dndCount}</div>}
      </div>

      <AppointmentPanelSidebar
        tooltipVisible={tooltipVisible}
        onClose={() => setToolTipVisible({ visible: false })}
      />
    </div>
  );
};

export default AppointmentPanel;
