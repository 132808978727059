import _ from "lodash";

import GoalApi from "../api-legacy/GoalApi";
import StickyNoteApi from "../api-legacy/StickyNoteApi";

import * as ActionTypes from "../actionTypes/chartGeneralActionTypes";

import { startLoader, stopLoader, showMessage } from "./loaderActions";
import * as chartSideClinicalNoteActions from "./chartSideClinicalNoteActions";
import * as chartSideFillInFormActions from "./chartSideFillInFormActions";
import * as chartSidePostOpActions from "./chartSidePostOpActions";

export function initGeneral({ patientKey }) {
  return (dispatch) => {
    dispatch(startLoader());

    const promises = [
      dispatch(chartSideClinicalNoteActions.getOwnedList({ patientKey })),
      dispatch(getGoalListApi({ patientKey })),
      dispatch(
        getStickyListApi({
          index: 0,
          patientKey,
          categoryId: 1,
        }),
      ),
      dispatch(chartSideFillInFormActions.getOwnedList({ patientKey })),
      dispatch(chartSidePostOpActions.getOwnedList({ patientKey })),
      dispatch(chartSideFillInFormActions.getFrequentlyFillInFormList({ patientKey })),
    ];

    return Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getGoalList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
    };

    dispatch(getGoalListApi(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function saveGoal() {
  return (dispatch, getState) => {
    const {
      chartGeneral: { goalList },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    if (_.isEmpty(goalList)) return;

    goalList.forEach((item) => {
      item.patient = { patientKey };
    });

    const data = {
      list: goalList,
    };

    dispatch({
      api: GoalApi.saveItems,
      types: [
        ActionTypes.CHART_GENERAL_SAVE_GOAL_LIST_START,
        ActionTypes.CHART_GENERAL_SAVE_GOAL_LIST_SUCCESS,
        ActionTypes.CHART_GENERAL_SAVE_GOAL_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(getGoalList()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function saveSticky() {
  return (dispatch, getState) => {
    const {
      chartGeneral: { stickyLists, activeStickyIndex },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const stickyItem = stickyLists[activeStickyIndex];

    if (_.isEmpty(stickyItem.list)) return;

    stickyItem.list.forEach((item) => {
      item.patient = { patientKey };
      item.category = { id: stickyItem.categoryId };
    });

    const data = {
      list: stickyItem.list,
    };

    dispatch({
      api: StickyNoteApi.saveItems,
      types: [
        "CHART_GENERAL_SAVE_STICKY_LIST_START",
        "CHART_GENERAL_SAVE_STICKY_LIST_SUCCESS",
        "CHART_GENERAL_SAVE_STICKY_LIST_ERROR",
      ],
      data,
    })
      .then(() =>
        dispatch(
          getStickyListApi({
            patientKey,
            categoryId: stickyItem.categoryId,
            index: activeStickyIndex,
          }),
        ),
      )
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function toggleGoal(index) {
  return {
    type: ActionTypes.CHART_GENERAL_TOGGLE_GOAL,
    index,
  };
}

export function setNote(value, index) {
  return {
    type: ActionTypes.CHART_GENERAL_SET_GOAL_NOTE,
    value,
    index,
  };
}

export function setStickyNote(value, index) {
  return {
    type: ActionTypes.CHART_GENERAL_SET_STICKY_NOTE,
    value,
    index,
  };
}

export function setGoal(goalList) {
  return {
    type: ActionTypes.CHART_GENERAL_SET_GOAL_LIST,
    goalList,
  };
}

export function setStickyItem(stickyItem, activeStickyIndex) {
  return {
    type: ActionTypes.CHART_GENERAL_SET_STICKY_ITEM,
    stickyItem,
    activeStickyIndex,
  };
}

export function pushGoal() {
  return {
    type: ActionTypes.CHART_GENERAL_PUSH_GOAL,
  };
}

export function pushSticky() {
  return {
    type: ActionTypes.CHART_GENERAL_PUSH_STICKY,
  };
}

export function deleteGoal(id) {
  return {
    api: GoalApi.deleteItem,
    types: [
      "CHART_GENERAL_DELETE_GOAL_START",
      "CHART_GENERAL_DELETE_GOAL_SUCCESS",
      "CHART_GENERAL_DELETE_GOAL_ERROR",
    ],
    data: { id },
  };
}

export function deleteSticky(id, categoryId) {
  return {
    api: StickyNoteApi.deleteItem,
    types: [
      "CHART_GENERAL_DELETE_STICKY_START",
      "CHART_GENERAL_DELETE_STICKY_SUCCESS",
      "CHART_GENERAL_DELETE_STICKY_ERROR",
    ],
    data: { id, categoryId },
  };
}

export function deleteGoals() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    return dispatch({
      api: GoalApi.deleteAll,
      types: [
        "CHART_GENERAL_DELETE_GOAL_ALL_START",
        "CHART_GENERAL_DELETE_GOAL_ALL_SUCCESS",
        "CHART_GENERAL_DELETE_GOAL_ALL_ERROR",
      ],
      data: { patientKey },
    })
      .then(() => dispatch(getGoalList()))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function deleteStickyList() {
  return (dispatch, getState) => {
    const {
      chartGeneral: { stickyLists, activeStickyIndex },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const categoryId = stickyLists[activeStickyIndex].categoryId;

    return dispatch({
      api: StickyNoteApi.deleteAll,
      types: [
        "CHART_GENERAL_DELETE_STICKY_ALL_START",
        "CHART_GENERAL_DELETE_STICKY_ALL_SUCCESS",
        "CHART_GENERAL_DELETE_STICKY_ALL_ERROR",
      ],
      data: { patientKey, categoryId },
    })
      .then(() =>
        dispatch(
          getStickyListApi({
            patientKey,
            categoryId,
            index: activeStickyIndex,
          }),
        ),
      )
      .catch((error) => dispatch(showMessage(error)));
  };
}

function getGoalListApi(data) {
  return {
    api: GoalApi.getList,
    types: [
      ActionTypes.CHART_GENERAL_GET_GOAL_LIST_START,
      ActionTypes.CHART_GENERAL_GET_GOAL_LIST_SUCCESS,
      ActionTypes.CHART_GENERAL_GET_GOAL_LIST_ERROR,
    ],
    data,
  };
}

function getStickyListApi(data) {
  return {
    api: StickyNoteApi.getList,
    types: [
      ActionTypes.CHART_GENERAL_GET_STICKY_LIST_START,
      ActionTypes.CHART_GENERAL_GET_STICKY_LIST_SUCCESS,
      ActionTypes.CHART_GENERAL_GET_STICKY_LIST_ERROR,
    ],
    data,
  };
}
