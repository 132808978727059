import React from "react";
import moment from "moment";
import ReactSpinner from "react-spinjs-fix";
import Show from "../../../widgets/Show";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import { DeleteCellWrapper } from "../../transactions/DeleteCellWrapper";

// import { SelectPicker } from "../../../select-picker/SelectPicker";
// import { DatePicker } from "../../../ui/DatePicker";

const MedicalFormProvisionalDiagnosisList = ({
  diagnosisList,
  fetching,
  permissions,
  // updateDiagnosisClaimType,
  // updateDiagnosisYearOfOnset,
  // diagnosisClaimTypeList,
}) => {
  let list = [];
  // const statusOptions = diagnosisClaimTypeList.map((item) => ({
  //   value: item.code,
  //   label: item.name,
  // }));

  const deletePermission =
    permissions.indexOf("REMOVE_DIAGNOSIS_PLANNED_DENTAL_TRANSACTION_ITEM") >= 0;
  diagnosisList.forEach((diagnosis) => {
    list.push(
      <div className="medical-fill-in-form-treatment__list__table__body__row" key={diagnosis.key}>
        <div className="item-date-posted">
          {moment(diagnosis.createdDate).format(DATE_FORMAT_LEGACY)}
        </div>
        <div className="item-treatment-code">{diagnosis.diagnosisItem.code}</div>
        <div className="item-tooth">{diagnosis.tooth && diagnosis.tooth.name}</div>
        <div className="item-description text-center">{diagnosis.diagnosisItem.description}</div>
        <div className="item-posted-by">
          {diagnosis.createdDentist && diagnosis.createdDentist.name}
        </div>
        {/*<div className="item-posted-by d-flex align-items-center" style={{ overflow: "visible" }}>
          <SelectPicker
            isMulti={false}
            value={{
              label: diagnosis?.diagnosisClaimType?.name,
              value: diagnosis?.diagnosisClaimType?.code,
            }}
            options={statusOptions}
            onChange={(x) => {
              updateDiagnosisClaimType({
                key: diagnosis.key,
                diagnosisClaimType: { code: x.value },
              });
            }}
          />
        </div>
        <div className="item-posted-by">
          <DatePicker
            showYearPicker={true}
            dateFormat="yyyy"
            selected={
              diagnosis?.diagnosisYearOfOnSet &&
              new Date(new Date().setFullYear(diagnosis?.diagnosisYearOfOnSet))
            }
            onChange={(x) =>
              updateDiagnosisYearOfOnset({
                key: diagnosis.key,
                diagnosisYearOfOnSet: new Date(x).getFullYear(),
              })
            }
          />
          {diagnosis?.diagnosisYearOfOnSet}
        </div>
        <div className="item-posted-by">{!diagnosis.problem ? "No" : "Yes"}</div>*/}
        <div className="item-tooth">
          <DeleteCellWrapper item={diagnosis} disabled={!deletePermission} />
        </div>
      </div>,
    );
  });

  return (
    <div className="medical-fill-in-form-treatment__list">
      <div className="medical-fill-in-form-treatment__list__table">
        <div className="medical-fill-in-form-treatment__list__table__header">
          <div className="item-date-posted">Date posted</div>
          <div className="item-treatment-code">Diagnosis Code</div>
          <div className="item-tooth">Tooth</div>
          <div className="item-description text-center">Description</div>
          <div className="item-posted-by">Posted by</div>
          {/*<div className="item-posted-by">Diagnosis type</div>*/}
          {/*<div className="item-posted-by">Year of onset</div>*/}
          {/*<div className="item-posted-by">Problem</div>*/}
          <div className="item-tooth" />
        </div>
        <div className="medical-fill-in-form-treatment__list__table__body">
          <Show if={!fetching}>{list}</Show>
          <Show if={fetching}>
            <div
              style={{ position: "relative" }}
              className="medical-fill-in-form-treatment__list__table__body__row"
            >
              <ReactSpinner />
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
};
export default MedicalFormProvisionalDiagnosisList;
