import React from "react";
import { Link } from "react-router-dom";

import Back from "../widgets/Back";
import { Routes } from "../../constants/Routes";

export default function AppointmentCalendar() {
  return (
    <div className="settings-body">
      <div className="title">
        <Back />
        <div className="name">Appointment calendar view</div>
      </div>
      <div className="body">
        <div className="list-group">
          <Link to={Routes.SettingsAppointmentCalendarColumn} className="list-group-item">
            <div className="pull-right">❯</div>
            Column Settings
          </Link>
          <Link to={Routes.SettingsAppointmentCalendarRow} className="list-group-item">
            <div className="pull-right">❯</div>
            Row Settings
          </Link>
        </div>
      </div>
    </div>
  );
}
