import React from "react";
import cx from "classnames";
import _ from "lodash";

const ReduxMedicalAlertNameSwitch = ({ input, title }) => {
  return (
    <div className="btn-group btn-group-sm">
      <button
        type="button"
        onClick={() => input.onChange(!input.value)}
        className={cx("btn btn-default", {
          "btn-primary": _.isBoolean(input.value) && input.value,
        })}
      >
        {title}
      </button>
    </div>
  );
};

export default ReduxMedicalAlertNameSwitch;
