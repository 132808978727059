import _ from "lodash";

import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSidePlanActionTypes";

import { defaultStackItem } from "../constants/chart-sidebar/planStackCodes";

export const defaultState = {
  stack: [defaultStackItem],
  searchValue: "",
  list: [],
  listTotalCount: 0,
  fetching: false,
  sortField: "created_date_desc",
  listFor: [],
  listForTotalCount: 0,
  searchValueFor: "",
  selectedFor: {},
  selectedMode: false,
  sorting: "",
  deletePlanId: 0,
};

const reducers = {
  [ActionTypes.SIDE_PLAN_TOGGLE_ALL_GROUP_LIST_FOR](state, { status }) {
    const { selectedFor, listFor } = state;

    const isSelected = _.size(selectedFor) > 0;

    return {
      ...state,
      selectedFor: isSelected
        ? {}
        : listFor.reduce((acc, item) => {
            return {
              ...acc,
              [item.key]: item.status.code === status || status === "all" ? item : undefined,
            };
          }, {}),
    };
  },
  [ActionTypes.SIDE_PLAN_CLEAR_ALL_GROUP_LIST_FOR](state) {
    return {
      ...state,
      selectedFor: {},
    };
  },
  [ActionTypes.DELETE_PLAN_ITEM_CONFIRM_MODAL_SHOW](state, { id }) {
    return {
      ...state,
      deletePlanId: id,
    };
  },
  [ActionTypes.DELETE_PLAN_ITEM_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      deletePlanId: 0,
    };
  },
  [ActionTypes.SIDE_PLAN_INIT](state) {
    return {
      ...state,
      stack: [defaultStackItem],
    };
  },
  [ActionTypes.SIDE_PLAN_INIT_FOR](state) {
    return {
      ...state,
      selectedFor: {},
      selectedMode: false,
      sorting: "",
    };
  },
  [ActionTypes.SIDE_PLAN_PUSH_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.SIDE_PLAN_POP_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.SIDE_PLAN_TOGGLE_GROUP_LIST_FOR](state, { items, doSelect }) {
    const selectedFor = {
      ...state.selectedFor,
    };

    _.forEach(items, (item) => {
      if (doSelect) {
        selectedFor[item.key] = item;
      } else {
        delete selectedFor[item.key];
      }
    });

    return {
      ...state,
      selectedFor,
    };
  },
  [ActionTypes.SIDE_PLAN_TOGGLE_LIST_FOR](state, { item }) {
    const selectedFor = {
      ...state.selectedFor,
    };

    if (selectedFor.hasOwnProperty(item.key)) {
      delete selectedFor[item.key];
    } else {
      selectedFor[item.key] = item;
    }

    return {
      ...state,
      selectedFor,
    };
  },
  [ActionTypes.SIDE_PLAN_ADD_ALL_FOR](state) {
    const selectedFor = {};

    state.listFor.forEach((item) => {
      selectedFor[item.key] = item;
    });

    return {
      ...state,
      selectedFor,
    };
  },
  [ActionTypes.SIDE_PLAN_DISABLE_SELECTED_MODE](state) {
    return {
      ...state,
      selectedMode: false,
    };
  },
  [ActionTypes.SIDE_PLAN_ENABLE_SELECTED_MODE](state) {
    return {
      ...state,
      selectedMode: true,
    };
  },
  [ActionTypes.SIDE_PLAN_REMOVE_ALL_FOR](state) {
    return {
      ...state,
      selectedFor: {},
    };
  },
  [ActionTypes.SIDE_PLAN_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.SIDE_PLAN_SEARCH_FOR_CHANGE](state, { value }) {
    return {
      ...state,
      searchValueFor: value,
    };
  },
  [ActionTypes.SIDE_PLAN_CHANGE_SORTING](state, { sorting }) {
    return {
      ...state,
      sorting,
    };
  },
  [ActionTypes.SIDE_PLAN_SELECT_SORT_FIELD](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      fetching: false,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_FOR_TREATMENT_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_FOR_TREATMENT_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        listFor: state.listFor.concat(data.list),
        listForTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValueFor) return state;
    return {
      ...state,
      fetching: false,
      listFor: data.list || [],
      listForTotalCount: data.totalCount,
    };
  },
  [ActionTypes.SIDE_PLAN_GET_LIST_FOR_TREATMENT_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
};

export default createReducer(defaultState, reducers);
