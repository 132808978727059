import React from "react";

export default function RemindersMainButton({ active, onClick, text }) {
  const className = `btn ${active ? "btn-primary" : "btn-default"}`;

  return (
    <div className="btn-group">
      <a type="button" onClick={onClick} className={className}>
        {text}
      </a>
    </div>
  );
}
