import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Modal } from "../ui/Modal";
import { Routes } from "../../constants/Routes";
import MemberImage from "../widgets/MemberImage";
import bindActions from "../../helpers/bindActions";
import { AppLayout } from "../app-layout/AppLayout";
import { PatientSearchForm } from "./PatientSearchForm";
import searchImage from "../../assets/images/search.png";
import { SelectPicker } from "../select-picker/SelectPicker";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import addPatientImage from "../../assets/images/patient/add.png";
import BackgroundImage from "../../assets/images/patients_new.jpg";
import * as patientListActions from "../../actions/patientListActions";
import { obtainImagePath } from "../../utils/ImageUtils";
import { clearAppointment } from "../../actions/appointmentActions";
import AscFilter from "../../assets/images/string-asc.png";
import DescFilter from "../../assets/images/string-desc.png";
import AscNumber from "../../assets/images/number-asc.png";
import DescNumber from "../../assets/images/number-desc.png";

const enhancer = connect(({ patientList }) => {
  return { patientList };
}, bindActions({ patientListActions, clearAppointment }));

class PatientList extends React.Component {
  constructor() {
    super();
    this.state = {
      listType: "grid",
    };
  }
  UNSAFE_componentWillMount() {
    const query = parseQuery(this.props.location.search);
    this.props.patientListActions.initList(query);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const query = parseQuery(this.props.location.search);
    const newQuery = parseQuery(newProps.location.search);

    if (!_.isEqual(query, newQuery)) {
      this.props.patientListActions.initList(newQuery);
    }
  }
  componentDidMount() {
    this.props.clearAppointment();
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      const query = parseQuery(this.props.location.search);

      this.props.patientListActions.loadList(query);
    }
  };

  selectChange = ({ value }) => {
    const query = parseQuery(this.props.location.search);

    this.props.history.replace(
      createUrl(Routes.PatientList, {
        query: {
          ...query,
          onlyNew: value !== "all" ? value : undefined,
        },
      }),
    );
  };

  openPatient = (patientKey) => {
    return () => {
      this.props.history.push(createUrl(Routes.PatientView, { params: { patientKey } }));
    };
  };

  search = (values) => {
    const query = parseQuery(this.props.location.search);

    const phone = _.get(values, "phoneNumber.number", "");
    const town = _.get(values, "phoneNumber.townCode", "");
    const country = _.get(values, "phoneNumber.countryCode.value", "");

    this.props.history.replace(
      createUrl(Routes.PatientList, {
        query: {
          ...query,

          // name: _.get(values, "name", null),
          firstName: _.get(values, "firstName", null),
          lastName: _.get(values, "lastName", null),
          email: _.get(values, "email", null),
          phoneNumber: phone && country ? `${country}${town}${phone}` : null,
          primaryCardNumber: _.get(values, "primaryCardNumber", null),
          patientChartNumber: _.get(values, "patientChartNumber", null),
          secondaryCardNumber: _.get(values, "secondaryCardNumber", null),
          documentNationalIdNumber: _.get(values, "documentNationalIdNumber", null),
        },
      }),
    );

    this.props.patientListActions.hideSearch();
  };

  openPatientMobile = () => {
    this.props.history.push(Routes.PatientMobile);
  };

  sortListByName = () => {
    this.props.patientListActions.sortListByName();
    // const query = parseQuery(this.props.location.search);
    // if (query?.sortField && query?.sortField === "name_desc") {
    //   this.props.history.replace(
    //     createUrl(Routes.PatientList, {
    //       query: { sortField: "", filter: true },
    //     }),
    //   );
    // } else {
    //   this.props.history.replace(
    //     createUrl(Routes.PatientList, {
    //       query: { sortField: "name_desc", filter: true },
    //     }),
    //   );
    // }
  };

  sortListByChartNumber = () => {
    this.props.patientListActions.sortListByChartNumber();
  };

  render() {
    const query = parseQuery(this.props.location.search);
    const { listType } = this.state;
    const sortField = query.sortField;
    let { list, sideBarVisible, sortListByChartNumber, sortListByName } = this.props.patientList;
    let newList = [];

    if (sortListByChartNumber) {
      newList = list.sort((a, b) =>
        Number(a || b) ? a.chartNumber - b.chartNumber : a.chartNumber.localeCompare(b.chartNumber),
      );
    } else {
      newList = list.sort((a, b) =>
        Number(a || b) ? b.chartNumber - a.chartNumber : b.chartNumber.localeCompare(a.chartNumber),
      );
    }

    if (sortListByName) {
      newList = list.sort((a, b) =>
        Number(a || b) ? a.firstName - b.firstName : a.firstName.localeCompare(b.firstName),
      );
    } else {
      newList = list.sort((a, b) =>
        Number(a || b) ? b.firstName - a.firstName : b.firstName.localeCompare(a.firstName),
      );
    }

    const $list = newList.map((item, index) => {
      if (listType === "list") {
        const age = new Date().getFullYear() - new Date(item.dateOfBirth).getFullYear();
        return (
          <div
            className="patient-list-item"
            key={index}
            onClick={this.openPatient(item.patientKey)}
          >
            <MemberImage photoUrl={item.photoUrl} />
            <div className="name">{item.fullname}</div>
            <div className="number">{item.chartNumber}</div>
          </div>
        );
      }
      return (
        <div
          className="patient-list-grid-item"
          key={index}
          onClick={this.openPatient(item.patientKey)}
        >
          <MemberImage photoUrl={item.photoUrl} />
          <div className="name">{item.fullname}</div>
          <div className="number">{item.chartNumber}</div>
        </div>
      );
    });

    return (
      <AppLayout
        contentClassName="patient-list"
        rightComponent={
          <>
            <div className="chart-side-header-type btn-group btn-group-sm text-center mr-3 d-flex">
              <button
                type="button"
                onClick={() => this.setState({ listType: "grid" })}
                className={`btn ${listType === "grid" ? "btn-success" : "btn-default"}`}
              >
                GRID
              </button>
              <button
                type="button"
                onClick={() => this.setState({ listType: "list" })}
                className={`btn ${listType === "list" ? "btn-success" : "btn-default"}`}
              >
                LIST
              </button>
            </div>
            <div onClick={this.sortListByChartNumber}>
              <img
                style={{ width: 35, height: 35, marginRight: 16, cursor: "pointer" }}
                src={sortListByChartNumber ? AscNumber : DescNumber}
              />
            </div>
            <div onClick={this.sortListByName}>
              <img
                style={{ width: 35, height: 35, marginRight: 16, cursor: "pointer" }}
                src={sortListByName ? AscFilter : DescFilter}
              />
            </div>
            <div style={{ width: "140px" }}>
              <SelectPicker
                onChange={this.selectChange}
                value={{ value: query.onlyNew || "all" }}
                options={[
                  { value: "all", label: "All Patients" },
                  { value: "true", label: "New Patients" },
                ]}
              />
            </div>
            <div className="patient-list-open-search" onClick={this.openPatientMobile}>
              <img alt="" src={addPatientImage} />
            </div>
            <div
              className="patient-list-open-search"
              onClick={this.props.patientListActions.openSearch}
            >
              <img alt="" src={searchImage} />
            </div>
          </>
        }
        backgroundImage={obtainImagePath(BackgroundImage)}
        withLogo={true}
        onBackClick={() => this.props.history.replace(Routes.Dashboard)}
      >
        <div
          style={listType === "list" ? { display: "block" } : {}}
          className={`patient-list-body`}
          onScroll={this.loadList}
        >
          {$list}
        </div>
        <Modal
          show={sideBarVisible}
          animation={false}
          bodyProps={{ style: { padding: 0 } }}
          onHide={this.props.patientListActions.hideSearch}
          dialogClassName="chart-side-bar patient-list-modal"
        >
          <PatientSearchForm
            onSubmit={this.search}
            initialValues={query}
            onRequestClose={this.props.patientListActions.hideSearch}
          />
        </Modal>
      </AppLayout>
    );
  }
}

export default withRouter(enhancer(PatientList));
