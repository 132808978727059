import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Utils from "../../helpers/Utils";
import {
  setInitialValues,
  cleanUpReferralNextVisitList,
  setReferralNextVisitListActiveTab,
} from "../../actions/referralActions";
import { openSimpleModal, closeSimpleModal } from "../../actions/simpleModalActions";
import { SelectField } from "../form/SelectField";
import { DateField } from "../form/DateField";
import { FormControl } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import _ from "lodash";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";

const enhancer = connect(
  ({
    referral: { searchInitialValues },
    auth: { doctors, clinics },
    appointment: { categoryList },
    session: { permissions, clinic },
  }) => ({
    doctors,
    searchInitialValues,
    categoryList,
    permissions,
    clinics,
    clinic,
  }),
  null,
);
class ReferralNextVisitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featureTypeCode: "",
    };
  }
  statusCode = () => {
    return [STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING].join(",");
  };
  cleanUpList = (values) => {
    const { handleSubmit, dispatch } = this.props;
    const data = {
      ...values,
      referral: true,
    };
    const cancelledStatues = [
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
    ].join(", ");
    const allStatuses = [
      STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_VOIDED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
      STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED,
      STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W,
      STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2,
      STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
      STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE,
      STATUS_TYPES.APPOINTMENT_STATUS_WILL_CALL,
      STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN,
      STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER,
      STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF,
      STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN,
      STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT,
      STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
      STATUS_TYPES.APPOINTMENT_STATUS_PARTIALLY_PAYED,
    ].join(", ");
    data.referredToId =
      _.get(values, "referredToId.value") > 0 ? _.get(values, "referredToId.value") : null;
    data.referredFromId =
      _.get(values, "referredFromId.value") > 0 ? _.get(values, "referredFromId.value") : null;
    data.doctorIds = _.get(values, "doctorIds.value") > 0 ? _.get(values, "doctorIds.value") : null;
    data.referralTypeCode =
      typeof _.get(values, "referralTypeCode.value") === "string"
        ? _.get(values, "referralTypeCode.value")
        : null;
    data.periodStart = getCompanyStartOfDay(values.periodStart.valueOf());
    data.periodEnd = getCompanyEndOfDay(values.periodEnd.valueOf());
    data.categoryIds = data.categoryIds?.value;
    data.clinicId = values.clinicId.value;
    data.statusCodes = values.showCanceled
      ? cancelledStatues
      : values.showAll
      ? allStatuses
      : this.statusCode(values.referralTypeCode);

    dispatch(cleanUpReferralNextVisitList(data, this.state.featureTypeCode)).then(() => {
      this.setState({ featureTypeCode: "" });
      handleSubmit(data);
      dispatch(closeSimpleModal());
    });
  };
  render() {
    const {
      searchInitialValues,
      handleSubmit: submit,
      doctors,
      categoryList,
      dispatch,
      permissions,
      activeTab,
      clinics,
      clinic,
    } = this.props;

    const viewOwnReports = permissions.indexOf("VIEW_ONLY_OWN_REPORT") >= 0;

    const doctorsOptions = (doctors || [])
      .filter((i) => i.doctor)
      .map((item) => {
        return { value: item.id, label: Utils.getFullName(item) };
      });
    const categoryOptions = (categoryList || []).map((item) => {
      return { value: item.id, label: item.name };
    });
    const clinicOptions = (clinics || []).map((item) => {
      return { value: item.id, label: item.name };
    });
    const allDoctors = { value: -1, label: "All doctors" };
    doctorsOptions.unshift(allDoctors);
    const referralTypeOptions = [
      { value: -1, label: "All" },
      { value: "REFERRAL", label: "Referral" },
      { value: "NEXT_VISIT", label: "Next visit" },
    ];
    return (
      <Formik
        initialValues={{
          ...searchInitialValues,
          clinicId: { value: clinic.id },
        }}
        enableReinitialize={true}
        onSubmit={(values) => submit(values)}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form className="p-4">
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <div className="btn-group w-100">
                    <div
                      style={{ width: `${100 / 4}%` }}
                      className={`btn ${
                        activeTab === "referralNextVisit" ? "btn-success" : "btn-default"
                      }`}
                      onClick={() => {
                        dispatch(setReferralNextVisitListActiveTab("referralNextVisit"));
                        handleSubmit(values);
                      }}
                    >
                      Referral/Next Visit
                    </div>
                    <div
                      style={{ width: `${100 / 4}%` }}
                      className={`btn ${activeTab === "noAnswer" ? "btn-success" : "btn-default"}`}
                      onClick={() => {
                        dispatch(setReferralNextVisitListActiveTab("noAnswer"));
                        handleSubmit(values);
                      }}
                    >
                      No Answer
                    </div>
                    <div
                      style={{ width: `${100 / 4}%` }}
                      className={`btn ${activeTab === "willCall" ? "btn-success" : "btn-default"}`}
                      onClick={() => {
                        dispatch(setReferralNextVisitListActiveTab("willCall"));
                        handleSubmit(values);
                      }}
                    >
                      Will Call back
                    </div>
                    <div
                      style={{ width: `${100 / 4}%` }}
                      className={`btn ${
                        activeTab === "notInterested" ? "btn-success" : "btn-default"
                      }`}
                      onClick={() => {
                        dispatch(setReferralNextVisitListActiveTab("notInterested"));
                        handleSubmit(values);
                      }}
                    >
                      Not Interested
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  {/*<div className="mb-1">Clinic:</div>*/}
                  {/*<div*/}
                  {/*  className="form-group"*/}
                  {/*  style={{*/}
                  {/*    margin: 0,*/}
                  {/*    lineHeight: "30px",*/}
                  {/*    textAlign: "initial",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <SelectField*/}
                  {/*    name="clinicId"*/}
                  {/*    multi={false}*/}
                  {/*    clearable={false}*/}
                  {/*    searchable={false}*/}
                  {/*    value={values.clinicId}*/}
                  {/*    options={clinicOptions}*/}
                  {/*    onChange={(x) => {*/}
                  {/*      setFieldValue("clinicId", x);*/}
                  {/*      dispatch(setInitialValues({ clinicId: x }));*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className="mb-1">Referral type:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <SelectField
                      name="referralTypeCode"
                      multi={false}
                      clearable={false}
                      searchable={false}
                      value={values.referralTypeCode}
                      options={referralTypeOptions}
                      onChange={(x) => {
                        setFieldValue("referralTypeCode", x);
                        dispatch(setInitialValues({ referralTypeCode: x }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">Referred From:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <SelectField
                      name="referredFromId"
                      isMulti={false}
                      isSearchable={true}
                      value={values.referredFromId}
                      options={doctorsOptions}
                      onChange={(x) => {
                        setFieldValue("referredFromId", x);
                        dispatch(setInitialValues({ referredFromId: x }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">Referred to/Next visit:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <SelectField
                      name="referredToId"
                      isMulti={false}
                      isSearchable={true}
                      isDisabled={viewOwnReports}
                      value={values.referredToId}
                      options={doctorsOptions}
                      onChange={(x) => {
                        setFieldValue("referredToId", x);
                        dispatch(setInitialValues({ referredToId: x }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">Patient chart number:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <FormControl
                      autoComplete="off"
                      name="patientChartNumber"
                      className="form-control"
                      style={{ width: "100%" }}
                      placeholder="Chart number"
                      value={values.patientChartNumber}
                      onChange={({ target: { value } }) => {
                        setFieldValue("patientChartNumber", value);
                        dispatch(setInitialValues({ patientChartNumber: value }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">Appointment type:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <SelectField
                      name="categoryIds"
                      isMulti={false}
                      isSearchable={true}
                      value={values.categoryIds}
                      options={categoryOptions}
                      onChange={(x) => {
                        setFieldValue("categoryIds", x);
                        dispatch(setInitialValues({ categoryIds: x }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">Start Date:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <DateField
                      name="periodStart"
                      fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
                      className="form-control"
                      style={{ width: "100%" }}
                      placeholder="Select date"
                      value={values.periodStart}
                      onChange={(event) => {
                        setFieldValue("periodStart", event);
                        dispatch(setInitialValues({ periodStart: event }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1">End Date:</div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <DateField
                      name="periodEnd"
                      fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
                      className="form-control"
                      style={{ width: "100%" }}
                      placeholder="Select date"
                      value={values.periodEnd}
                      onChange={(event) => {
                        setFieldValue("periodEnd", event);
                        dispatch(setInitialValues({ periodEnd: event }));
                      }}
                    />
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1" style={{ opacity: 0 }}>
                    save:
                  </div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <button
                      className="btn btn-block btn-primary"
                      type="button"
                      onClick={() => handleSubmit(values)}
                    >
                      search
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1" style={{ opacity: 0 }}>
                    save:
                  </div>
                  <div
                    className="form-group"
                    style={{
                      marginLeft: "-12px",
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <InputLabel key={"item.key"}>
                      <Checkbox
                        checked={values.showCanceled}
                        onChange={({ target: { checked } }) => {
                          setFieldValue("showCanceled", checked);
                          dispatch(setInitialValues({ showCanceled: checked }));
                          if (checked) {
                            setFieldValue("showAll", !checked);
                            dispatch(setInitialValues({ showAll: !checked }));
                          }
                        }}
                        inputProps={{ "aria-label": "" }}
                        sx={{
                          color: "#bec844",
                          "&.Mui-checked": {
                            color: "#bec844",
                          },
                        }}
                      />
                      Show canceled appointments
                    </InputLabel>
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1" style={{ opacity: 0 }}>
                    save:
                  </div>
                  {/*<div
                    className="form-group"
                    style={{
                      marginLeft: "-12px",
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <InputLabel key={"item.key"}>
                      <Checkbox
                        checked={values.showCanceled}
                        onChange={({ target: { checked } }) => {
                          setFieldValue("showCanceled", checked);
                          dispatch(setInitialValues({ showCanceled: checked }));
                          if (checked) {
                            setFieldValue("showAll", !checked);
                            dispatch(setInitialValues({ showAll: !checked }));
                          }
                        }}
                        inputProps={{ "aria-label": "" }}
                        sx={{
                          color: "#bec844",
                          "&.Mui-checked": {
                            color: "#bec844",
                          },
                        }}
                      />
                      show canceled appointments
                    </InputLabel>
                  </div>*/}
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1" style={{ opacity: 0 }}>
                    save:
                  </div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <button
                      className="btn btn-block btn-danger"
                      type="button"
                      onClick={() => {
                        this.setState({ featureTypeCode: "SAME_DOCTOR" });
                        dispatch(
                          openSimpleModal({
                            body: (
                              <div>
                                <p>
                                  - This action will clear up appointments from this list if there
                                  is any future appointment from cancellation date.
                                </p>
                                <p>- This action can't be undone!</p>
                                <p>- Do you want to proceed?</p>
                              </div>
                            ),
                            footer: (
                              <div>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    this.setState({ featureTypeCode: "" });
                                    dispatch(closeSimpleModal());
                                  }}
                                >
                                  cancel
                                </button>
                                <button
                                  className="btn btn-success"
                                  onClick={() => {
                                    const days = values.periodEnd.diff(values.periodStart, "days");
                                    if (days > 30) {
                                      return dispatch(
                                        openSimpleModal({
                                          body: "Please select a date less than a month",
                                        }),
                                      );
                                    }
                                    this.cleanUpList(values);
                                  }}
                                >
                                  yes
                                </button>
                              </div>
                            ),
                          }),
                        );
                      }}
                    >
                      Clean up list for same doctor
                    </button>
                  </div>
                </div>
                <div className="referral-next-visit-form-field col-sm-3 mb-3">
                  <div className="mb-1" style={{ opacity: 0 }}>
                    save:
                  </div>
                  <div
                    className="form-group"
                    style={{
                      margin: 0,
                      lineHeight: "30px",
                      textAlign: "initial",
                    }}
                  >
                    <button
                      className="btn btn-block btn-danger"
                      type="button"
                      onClick={() => {
                        this.setState({ featureTypeCode: "ANY_DOCTOR" });
                        dispatch(
                          openSimpleModal({
                            body: (
                              <div>
                                <p>
                                  - This action will clear up appointments from this list if there
                                  is any future appointment from cancellation date.
                                </p>
                                <p>- This action can't be undone!</p>
                                <p>- Do you want to proceed?</p>
                              </div>
                            ),
                            footer: (
                              <div>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    this.setState({ featureTypeCode: "" });
                                    dispatch(closeSimpleModal());
                                  }}
                                >
                                  cancel
                                </button>
                                <button
                                  className="btn btn-success"
                                  onClick={() => {
                                    const days = values.periodEnd.diff(values.periodStart, "days");
                                    if (days > 30) {
                                      return dispatch(
                                        openSimpleModal({
                                          body: "Please select a date less than a month",
                                        }),
                                      );
                                    }
                                    this.cleanUpList(values);
                                  }}
                                >
                                  yes
                                </button>
                              </div>
                            ),
                          }),
                        );
                      }}
                    >
                      Clean up list for any doctor
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default enhancer(ReferralNextVisitForm);
