import React, { useEffect } from "react";
import MedicalFillInFormTreatmentList from "./MedicalFillInFormTreatmentList";
import moment from "moment/moment";
import { connect, useDispatch } from "react-redux";
import { getMedicalFillInFormTreatmentList } from "../../../../actions/chartSideMedicalFillInFormActions";

const enhancer = connect(({ auth, session }) => ({ auth, session }));
const MedicalFillInformTreatment = (props) => {
  const { chart, clinicId, treatmentList, fetching, permissions } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedicalFillInFormTreatmentList());
  }, [chart.sideBarVisible]);

  const todayTreatments = treatmentList.filter((item) => moment().isSame(item.createdDate, "day"));
  const oldTreatments = treatmentList.filter((item) => !moment().isSame(item.createdDate, "day"));

  return (
    <div className="medical-fill-in-form-treatment">
      <div className="medical-fill-in-form-treatment__header">
        <div className="btn-group" style={{ display: "block" }}>
          <button type="button" style={{ width: "100%" }} className={`btn btn-primary`}>
            Services/Treatments
          </button>
        </div>
      </div>
      <MedicalFillInFormTreatmentList
        permissions={permissions}
        clinicId={clinicId}
        treatmentList={todayTreatments}
        fetching={fetching}
      />
      <MedicalFillInFormTreatmentList
        permissions={permissions}
        clinicId={clinicId}
        treatmentList={oldTreatments}
        fetching={fetching}
      />
    </div>
  );
};

export default enhancer(MedicalFillInformTreatment);
