import React from "react";

export default class ChartClinicalNoteQuestionTeethCarousel extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      offset: 0,
    };
  }

  prev = () => {
    this.setState((previousState) => {
      if (previousState.offset > 5) {
        return { offset: previousState.offset - 5 };
      }
      return { offset: 0 };
    });
  };

  next = () => {
    this.setState((previousState, currentProps) => {
      const extraRows = currentProps.children.length - 5 - previousState.offset;

      if (extraRows > 5) {
        return { offset: previousState.offset + 5 };
      }
      if (extraRows > 0) {
        return { offset: currentProps.children.length - 5 };
      }
      return previousState;
    });
  };

  selectIndex = (index) => {
    this.setState((previousState, currentProps) => {
      if (index < 3) {
        return { offset: 0 };
      }

      if (index > currentProps.children.length - 3) {
        return { offset: currentProps.children.length - 5 };
      }

      return { offset: index - 2 };
    });
  };

  render() {
    const style = {
      marginLeft: this.state.offset * -80,
      width: this.props.children.length * 80,
    };

    const extraRows = this.props.children.length - 5 > this.state.offset;
    const prevSrc = require(`../../../../assets/images/arrows/arrow_left${
      this.state.offset ? "_active" : ""
    }.png`).default;
    const nextSrc = require(`../../../../assets/images/arrows/arrow_right${
      extraRows ? "_active" : ""
    }.png`).default;

    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        selectIndex: this.selectIndex,
      }),
    );

    return (
      <div className="chart-clinical-note-teeth-carousel">
        <div className="chart-clinical-note-teeth-carousel-prev" onClick={this.prev}>
          <img src={prevSrc} alt="" />
        </div>
        <div className="chart-clinical-note-teeth-carousel-screen">
          <div className="chart-clinical-note-teeth-carousel-wrapper" style={style}>
            {childrenWithProps}
          </div>
        </div>
        <div className="chart-clinical-note-teeth-carousel-next" onClick={this.next}>
          <img src={nextSrc} alt="" />
        </div>
      </div>
    );
  }
}
