import _ from "lodash";
import moment from "moment";

import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSideFillInFormActionTypes";
import { CHART_SHOW_SIDE_BAR } from "../actions/chartActions";

import {
  defaultStackItem,
  fillInFormStackCodes,
} from "../constants/chart-sidebar/fillInFormStackCodes";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../constants/Constants";
import Utils from "../helpers/Utils";

export const defaultState = {
  templateGroupList: [],
  stack: [defaultStackItem],
  sortField: "create_date_desc",
  fetching: false,
  ownedList: [],
  referenceList: [],
  category: [],
  templateList: [],
  searchValue: "",
  item: {
    name: "",
    content: "",
    note: "",
  },
  fullScreen: false,
  frequencyList: [],
  currentQuestionIndex: 0,
  deleteModalVisible: false,
  onAppointment: false,
  isNew: false,
  multiQuestionOtherValue: {},
  fillInFormAddendumId: 0,
  selectDiagnosisModalVisible: false,
  selectTreatmentModalVisible: false,
  fillInFormActiveTab: {
    code: "fillInForms",
    title: "All Fill in forms",
  },
};

const reducers = {
  [ActionTypes.FILL_IN_FORM_FULL_SCREEN](state, { fullScreen }) {
    return {
      ...state,
      fullScreen,
    };
  },
  [ActionTypes.RESET_FILL_IN_FORM_FOR](state) {
    return {
      ...state,
      multiQuestionOtherValue: {},
      item: {
        name: "",
        note: "",
        templateQuestionsAndAnswers: [],
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_TAB](state, { fillInFormActiveTab }) {
    return {
      ...state,
      ownedList: [],
      sortField: "create_date_desc",
      fillInFormActiveTab,
    };
  },
  [ActionTypes.FILL_IN_FORM_INIT_TEXTAREA](state, { patient }) {
    return {
      ...state,
      item: {
        name: "",
        note: "",
        patient,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_SET_ADDENDUM_ID](state, { id }) {
    return {
      ...state,
      fillInFormAddendumId: id,
    };
  },
  [ActionTypes.FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE](state) {
    return {
      ...state,
      selectTreatmentModalVisible: !state.selectTreatmentModalVisible,
    };
  },
  [ActionTypes.FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE](state) {
    return {
      ...state,
      selectDiagnosisModalVisible: !state.selectDiagnosisModalVisible,
    };
  },
  [ActionTypes.FILL_IN_FORM_GET_GROUP_LIST_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      templateGroupList: [
        { label: "All groups", value: "" },
        ...data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      ],
    };
  },
  [ActionTypes.CLEAR_FILL_IN_FORM_STACK](state) {
    return {
      ...state,
      stack: [defaultStackItem],
    };
  },
  [ActionTypes.GET_FREQUENCY_FILL_IN_FORM_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      frequencyList: data.list || [],
    };
  },
  [ActionTypes.FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE](state, { id, data }) {
    const { multiQuestionOtherValue } = state;

    multiQuestionOtherValue[id] = data;

    return {
      ...state,
      multiQuestionOtherValue,
    };
  },
  [CHART_SHOW_SIDE_BAR](state, { isNew }) {
    return {
      ...state,
      isNew,
    };
  },
  [ActionTypes.INIT_SIDE_FILL_IN_FORM](state, { onAppointment }) {
    return {
      ...state,
      stack: state.isNew ? [{ code: fillInFormStackCodes.list }] : [defaultStackItem],
      searchValue: "",
      currentQuestionIndex: 0,
      onAppointment,
    };
  },
  [ActionTypes.SELECT_SORT_FIELD_SIDE_FILL_IN_FORM](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.CHART_FILL_IN_FORM_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.PUSH_FILL_IN_FORM_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.POP_FILL_IN_FORM_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_OWNED_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_OWNED_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        ownedList: state.ownedList.concat(data.list),
        ownedListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetching: false,
      ownedList: data.list || [],
      ownedListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_OWNED_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.RESET_FILL_IN_FORM_TEMPLATE_LIST](state) {
    return {
      ...state,
      templateListCount: 0,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        templateList: state.templateList.concat(data.list || []),
        templateListCount: data.totalCount,
      };
    }

    return {
      ...state,
      templateList: data.list || [],
      templateListCount: data.totalCount,
      fetching: false,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_ITEM_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_REFERENCE_FILL_IN_FORM_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      referenceList: _.get(data, "list", []),
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_ITEM_SUCCESS](state, { data }) {
    const writtenNote = !data.template?.name;
    const drawenNote = data.typeCode === "NOTE_TYPE_IMAGE";
    if (!writtenNote && !drawenNote) {
      const orderedQuestions = [];

      const reqExp = /\[\[[^\]]*]]/g;

      if (data.template.code) {
        data.template.code.replace(reqExp, (match) => {
          const code = match.slice(2, -2);
          if (code == "Current date") {
            return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
          }
          if (code == "Current time") {
            return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
          }
          if (code == "Patient chart number") {
            return data.patient.chartNumber;
          }
          if (code == "Patient name") {
            return data.patient.fullName;
          }
          if (code == "Dentist Name") {
            return data.doctor.name;
          }
          if (code === "Patient nationality") {
            return _.get(data, "patient.nationality.name", "N/A");
          }
          if (code === "Patient date of birth") {
            const dateFormat = localStorage.getItem("company-date-format");

            return Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(data.patient && data.patient.dateOfBirth),
              dateFormat,
            );
          }
          if (code === "Doctor license id") {
            return data.owner && data.owner.code;
          }

          const question = _.get(data, "templateQuestionsAndAnswers", []).find(
            (question) => question.code == code,
          );

          if (question) orderedQuestions.push(question);

          return match;
        });
      }

      data.templateQuestionsAndAnswers = orderedQuestions;
    }

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_EMPTY_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_FILL_IN_FORM_EMPTY_SUCCESS](state, { data }) {
    const orderedQuestions = [];

    const reqExp = /\[\[[^\]]*]]/g;
    data.content = data.template.code.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      if (code == "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code == "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code == "Patient chart number") {
        return data.patient && data.patient.chartNumber;
      }
      if (code == "Patient name") {
        return data.patient && data.patient.fullName;
      }
      if (code == "Dentist Name") {
        return data.doctor.name;
      }
      if (code === "Patient nationality") {
        return _.get(data, "patient.nationality.name", "N/A");
      }
      if (code === "Patient date of birth") {
        const dateFormat = localStorage.getItem("company-date-format");

        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(data.patient && data.patient.dateOfBirth),
          dateFormat,
        );
      }
      if (code === "Doctor license id") {
        return data.owner && data.owner.code;
      }

      let question = _.get(data, "templateQuestionsAndAnswers", []).find(
        (question) => question.code == code,
      );

      if (!question) {
        question = {
          id: 0,
          code: "",
          name: "",
          question: "",
          questionType: "",
          answers: [],
        };
      }

      if (_.get(question, "code") && _.get(question, "name")) {
        orderedQuestions.push(question);
      }

      return match;
    });

    data.templateQuestionsAndAnswers = orderedQuestions;

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.FILL_IN_FORM_PREV_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: state.currentQuestionIndex - 1,
    };
  },
  [ActionTypes.FILL_IN_FORM_NEXT_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: +state.currentQuestionIndex + 1,
    };
  },
  [ActionTypes.FILL_IN_FORM_SELECT_QUESTION](state, { index }) {
    return {
      ...state,
      currentQuestionIndex: index,
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA](state, { value, questionId }) {
    const templateQuestionsAndAnswers = (state.item.templateQuestionsAndAnswers || []).map(
      (question) => {
        if (question.id !== questionId) return question;

        if (!question.answers) question.answers = [];

        question.answers[0] = value;

        return {
          ...question,
        };
      },
    );

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_QUESTION_CANVAS](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [];

      question.answers[0] = value;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE](
    state,
    { value, questionId },
  ) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_DATE_PICKER](state, { date, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) return question;

      if (!question.answers) question.answers = [moment()];

      question.answers[0] = date;

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_CHANGE_SURFACE](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_SELECT_TOOTH](state, { value, questionId }) {
    const templateQuestionsAndAnswers = state.item.templateQuestionsAndAnswers.map((question) => {
      if (question.id !== questionId) {
        return question;
      }

      if (!question.answers) {
        question.answers = [];
      }

      const index = question.answers.indexOf(value);
      if (index === -1) {
        question.answers.push(value);
      } else {
        question.answers.splice(index, 1);
      }

      return {
        ...question,
      };
    });

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CHANGE_FILL_IN_FORM_CREATED_DATE_SUCCESS](state, { data: { createdDate } }) {
    return {
      ...state,
      item: {
        ...state.item,
        createdDate,
      },
    };
  },
  [ActionTypes.FILL_IN_FORM_OPEN_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: true,
    };
  },
  [ActionTypes.FILL_IN_FORM_CLOSE_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: false,
    };
  },
  [ActionTypes.FILL_IN_FORM_INIT_EDIT](state) {
    return {
      ...state,
      currentQuestionIndex: 0,
    };
  },
  [ActionTypes.FILL_IN_FORM_SET_NAME](state, { value }) {
    return {
      ...state,
      item: {
        ...state.item,
        name: value,
      },
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const chartSideFillInFormSelector = ({ chartSideFillInForm }) => chartSideFillInForm;

export default createReducer(defaultState, reducers);
