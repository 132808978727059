import React from "react";
import BackButton from "../widgets/BackButton";

export default function SideBarHeader(props) {
  const { children, addClass, className, hideBack } = props;
  const wrapClassName = className || `sidebar-header ${addClass}`;
  return (
    <div className={wrapClassName}>
      {hideBack ? null : <BackButton className="btn-sm" text="Back" onClick={props.onClick} />}
      {children}
    </div>
  );
}
