import React from "react";
import { connect } from "react-redux";

import FilledImage from "../../widgets/FilledImage";

import { defaultColors } from "../../../constants/defaults";

import {
  changeAllToPermanent,
  changeAllToPrimary,
  changeSupernumerary,
  changeToPermanent,
  changeToPrimary,
  mixedDentition,
} from "../../../actions/chartActions";
import { Modal } from "../../ui/Modal";
import BreakToVisitTickImage from "../../../assets/images/chart/breaktovisit_tick-icon@2x.png";

const enhancer = connect(null, {
  changeToPrimary,
  changeToPermanent,
  changeAllToPrimary,
  changeAllToPermanent,
  mixedDentition,
  changeSupernumerary,
});

class ChartTooltip extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      activeIndex: 0,
    };
  }

  setIndex = (activeIndex) => {
    this.setState({
      activeIndex,
    });
  };

  closeTooltip = () => {
    this.props.hideTooltip();
    this.setIndex(0);
  };

  runAction = () => {
    const {
      props: { activeTooth, activeToothSupernumeraryPresent },
      state: { activeIndex },
    } = this;

    this.closeTooltip();

    switch (activeIndex) {
      case 1:
        this.props.changeToPrimary(activeTooth);
        break;
      case 2:
        this.props.changeToPermanent(activeTooth);
        break;
      case 3:
        this.props.changeAllToPrimary();
        break;
      case 4:
        this.props.changeAllToPermanent();
        break;
      case 5:
        this.props.mixedDentition();
        break;
      case 6:
        this.props.changeSupernumerary(activeTooth, activeToothSupernumeraryPresent);

        break;
      case 7:
        // Show position history

        break;
    }
  };

  render() {
    const {
      setIndex,
      closeTooltip,
      runAction,
      props: { activeTooth, activeToothSupernumeraryPresent, tooltipVisible },
      state: { activeIndex },
    } = this;

    let style = {};
    style.top = 20 + 70 + 15 + 225 + 1 - 340 / 2 + "px";

    const toothWidth = window.innerWidth / 16;

    if (activeTooth < 17) {
      if (activeTooth < 9) {
        style.left = activeTooth * toothWidth + 10 + "px";
      } else {
        style.right = (17 - activeTooth) * toothWidth + 10 + "px";
      }
    } else {
      if (activeTooth < 25) {
        style.right = (activeTooth - 16) * toothWidth + 10 + "px";
      } else {
        style.left = (33 - activeTooth) * toothWidth + 10 + "px";
      }
    }

    return (
      <Modal
        show={tooltipVisible}
        keyboard={false}
        animation={false}
        contentClassName="chart-tooltip"
        backdropClassName="chart-tooltip-modal-backdrop"
        dialogClassName="chart-tooltip-modal"
      >
        <div className="chart-tooltip-body" style={style}>
          <div className="list-group">
            <div className="list-group-item">
              <button className="btn-link pull-left" type="button" onClick={closeTooltip}>
                Cancel
              </button>
              <button className="btn-link pull-right" type="button" onClick={runAction}>
                Save
              </button>
              <div className="chart-tooltip-title-text">Choose:</div>
            </div>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(1);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 1 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Change to Primary</span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(2);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 2 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Change to Permanent</span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(3);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 3 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Change all to Primary</span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(4);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 4 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Change all to Permanent</span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(5);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 5 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Mixed Dentition</span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(6);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 6 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">
                {activeToothSupernumeraryPresent ? "Remove " : "Add "}
                Supernumerary
              </span>
            </button>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                setIndex(7);
              }}
            >
              <FilledImage
                className="zoom-2x pull-right"
                src={BreakToVisitTickImage}
                color={activeIndex === 7 ? defaultColors.Blue.color : "transparent"}
              />
              <span className="text">Show position history</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default enhancer(ChartTooltip);
