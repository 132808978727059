import React from "react";
import BackButton from "../widgets/BackButton";

export default class Page404 extends React.Component {
  render() {
    return (
      <div className="container">
        <br />
        <br />
        <div className="jumbotron">
          <h2>Error 404</h2>
          <p>Sorry, but page not found</p>
          <div className="clearfix">
            <BackButton className="btn-lg" text="Go Back" />
          </div>
        </div>
      </div>
    );
  }
}
