import React from "react";
import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";
import { Dropzone } from "../../ui/Dropzone";
import deleteImage from "../../../assets/images/chart/deletePhoto.png";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));
const form = reduxForm({
  form: "chartLabOrder",
});

class ChartLabOrderForm extends React.Component {
  render() {
    const {
      chartSideSeries: {
        labOrderFormData: { externalLabList, templateList, toothList },
        labOrderAttachment,
      },
      handleSubmit,
    } = this.props;

    const labOptions = externalLabList.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const printoutOptions = templateList.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const toothOptions = toothList.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const $attachment = labOrderAttachment.map((item, index) => {
      return (
        <div key={index} className="lab-order-attachment-item">
          <img alt="" src={item.url} />
          <img
            alt=""
            className="lab-order-attachment-delete"
            src={deleteImage}
            onClick={() => {
              this.props.chartSideSeriesActions.deleteLabOrderAttachment(index);
            }}
          />
        </div>
      );
    });

    return (
      <form onSubmit={handleSubmit} className="chart-lab-order-form">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="lab">Lab:</label>
                </div>
                <div className="col-sm-9">
                  <Field
                    component={({ input }) => (
                      <SelectPicker
                        options={labOptions}
                        onChange={(x) => input.onChange(x.value)}
                        value={{ value: input.value }}
                      />
                    )}
                    name="lab"
                    id="lab"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="printout">Printout:</label>
                </div>
                <div className="col-sm-9">
                  <Field
                    component={({ input }) => (
                      <SelectPicker
                        options={printoutOptions}
                        value={{ value: input.value }}
                        onChange={(x) => input.onChange(x.value)}
                      />
                    )}
                    name="printout"
                    id="printout"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="tooth">Tooth:</label>
                </div>
                <div className="col-sm-9">
                  <Field
                    component={({ input }) => (
                      <SelectPicker
                        options={toothOptions}
                        value={{ value: input.value }}
                        onChange={(x) => input.onChange(x.value)}
                      />
                    )}
                    multi={true}
                    name="tooth"
                    id="tooth"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="dueDate">Due Date:</label>
                </div>
                <div className="col-sm-9">
                  <Field
                    component={({ input }) => (
                      <DatePicker onChange={(x) => input.onChange(x)} value={input.value} />
                    )}
                    name="dueDate"
                    id="dueDate"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="price">Price:</label>
                </div>
                <div className="col-sm-9">
                  <Field
                    component="input"
                    type="number"
                    name="price"
                    id="price"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="note">{localStorage.getItem("doctorLabel")} Note:</label>
              <Field component="textarea" name="note" id="note" className="form-control" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <label>Attachment:</label>
            <div className="lab-order-attachment-wrapper">
              <Dropzone
                className="dropzone"
                onDrop={this.props.chartSideSeriesActions.uploadLabOrder}
                multiple={false}
              >
                <img src="/images/chart/addPhoto.png" />
              </Dropzone>
              {$attachment}
            </div>
          </div>
          <div className="col-sm-3">
            <button
              type="submit"
              className="btn btn-primary btn-lg btn-block chart-lab-order-button"
            >
              Done
            </button>
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block chart-lab-order-button"
              onClick={this.props.chartSideSeriesActions.printLabOrder}
            >
              Print
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default enhancer(form(ChartLabOrderForm));
