import React from "react";

import { DatePicker } from "../../../ui/DatePicker";
import { TimePicker } from "../../../ui/TimePicker";
import FilledImage from "../../../widgets/FilledImage";
import DiagnosticImage from "../../../../assets/images/diagnosis_edit-icon.png";
import { getCompanyDate, getCompanyStartOfDay } from "../../../../helpers/DateUtils";
import moment from "moment";

export default class ChartClinicalNoteCreatedDate extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      timerId: 0,
    };
  }

  render() {
    const { timerId } = this.state;
    const { item, changeCreatedDate } = this.props;

    return (
      <div className="clinical-note-create-date">
        <label htmlFor="created-date">Created Date:</label>
        <div style={{ width: "187px", display: "inline-block" }}>
          <DatePicker
            id="created-date"
            size="lg"
            selected={getCompanyDate(item.createdDate)}
            onChange={(x) => {
              const startOfNextDate = getCompanyStartOfDay(x, true, true);

              const hoursCurrentDate = getCompanyDate(item.createdDate).hours() * 60 * 60 * 1000;
              const minutesCurrentDate = getCompanyDate(item.createdDate).minutes() * 60 * 1000;
              const secondsCurrentDate = getCompanyDate(item.createdDate).seconds() * 1000;
              const millisecondsCurrentDate = getCompanyDate(item.createdDate).milliseconds();

              const nextDate =
                startOfNextDate +
                hoursCurrentDate +
                minutesCurrentDate +
                secondsCurrentDate +
                millisecondsCurrentDate;

              changeCreatedDate(nextDate, false);
            }}
          />
        </div>
        <label htmlFor="created-date">
          <FilledImage src={DiagnosticImage} color="rgb(190, 200, 68)" />
        </label>
        <div style={{ marginLeft: "10px", display: "inline-block" }}>
          <TimePicker
            value={getCompanyDate(item.createdDate)}
            onChange={(value) => {
              if (timerId) {
                clearTimeout(timerId);
              }
              const timout = setTimeout(() => {
                if (value) {
                  const nextTime = moment(value).format("x");
                  changeCreatedDate(nextTime, false);
                }
              }, 500);

              this.setState({
                timerId: timout,
              });
            }}
          />
        </div>
      </div>
    );
  }
}
