import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { setFormTextAreaValue } from "../../../../../actions/chartSideMedicalFillInFormActions";

const FormTextArea = ({ questionId, form, setForm, editMode, visible, setOverlay }) => {
  const [show, setShow] = useState(visible || false);
  const [lineBreak, setLineBreak] = useState("");
  const dispatch = useDispatch();
  const item = form.questions.find((item) => item.id === questionId);
  const { formTextAreaValue } = useSelector((store) => store.chartSideMedicalFillInForm);
  // const [value, setValue] = useState("");

  useEffect(() => {
    if (item?.id) {
      // TODO: Update only the state of this component with a selected or default answer.
      dispatch(setFormTextAreaValue(item?.answer || item?.defaultAnswer));

      // setValue(item?.answer || item?.defaultAnswer);
    }
  }, [item]);

  const onSave = () => {
    setShow(false);
    setOverlay({ visible: false });
    dispatch(dataToFillHandler(formTextAreaValue || ""));
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.answer =
            lineBreak.length > 0 ? formTextAreaValue.replace(/\n/g, "<br>\n") : formTextAreaValue;
          setLineBreak("");
        }
        return question;
      }),
    });
  };

  const extractBodyText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  if (!show) {
    return (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {_.unescape(formTextAreaValue) || "N/A"}
      </p>
    );
  }
  return (
    <div className="d-flex flex-column">
      <textarea
        autoFocus={true}
        maxLength={1000}
        className="form-control w-100 mb-2"
        rows={15}
        value={extractBodyText(_.unescape(formTextAreaValue))}
        onChange={(event) => {
          dispatch(setFormTextAreaValue(_.escape(event.target.value)));
          setLineBreak(_.escape(event.target.value));
        }}
      />
      <div className="w-100">
        <span>{_.unescape(formTextAreaValue).length}/1000</span>
      </div>
      <button className="btn btn-primary mb-3" onClick={() => onSave()}>
        Ok
      </button>
    </div>
  );
};

export default FormTextArea;
