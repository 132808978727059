import React, { useEffect, useState } from "react";
import _ from "lodash";
import Show from "../widgets/Show";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Sidebar } from "../sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import FilledImage from "../widgets/FilledImage";
import { createUrl } from "../../utils/UrlUtils";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import deleteImage from "../../assets/images/delete.png";
import { initList } from "../../actions/patientListActions";
import PatientFamilyMembersTable from "./PatientFamilyMembersTable";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { getPatientMembersList } from "../../actions/patientViewActions";
import { PatientFamilyMembersAddForm } from "./PatientFamilyMembersAddForm";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import { useDeletePatientFamilyMemberMutation } from "../../queries/patient/useDeletePatientFamilyMemberMutation";
import { useCreatePatientFamilyMemberMutation } from "../../queries/patient/useCreatePatientFamilyMemberMutation";

const PatientFamilyMembersSidebar = ({ show, onHide, patientFamilyMembers, patientKey }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [formMode, setFormMode] = useState("list");
  const isSearchMode = formMode === "add";
  const { list } = useSelector((store) => store.patientList);
  const familyMemberCreateMutation = useCreatePatientFamilyMemberMutation();
  const familyMemberDeleteMutation = useDeletePatientFamilyMemberMutation();

  const handleFormMode = (mode) => {
    if (mode === "list") {
      history.replace(createUrl(location.pathname, { query: null }));
      dispatch({ type: "PATIENT_LIST_GET_LIST_ERROR" });
    }
    setFormMode(mode);
  };

  useEffect(() => {
    if (show) {
      handleFormMode("list");
      if (formMode === "add") setFormMode("list");
    }
  }, [show]);

  const deleteHandler = async (memberPatientKey) => {
    dispatch(startLoader());
    dispatch(closeSimpleModal());
    try {
      await familyMemberDeleteMutation.mutateAsync({
        patientKey,
        memberPatientKey,
      });
      dispatch(stopLoader());
      dispatch(getPatientMembersList({ patientKey }));
    } catch (e) {
      return dispatch(stopLoader(e));
    }
  };

  const chooseFamilyMemberHandler = async ({ patientKey: memberPatientKey }) => {
    dispatch(startLoader());
    try {
      await familyMemberCreateMutation.mutateAsync({ memberPatientKey, patientKey });
      handleFormMode("list");
      dispatch(stopLoader());
      dispatch(getPatientMembersList({ patientKey }));
    } catch (error) {
      return dispatch(stopLoader(error));
    }
  };

  const warnModalHandler = (memberPatientKey) => {
    return dispatch(
      openSimpleModal({
        body: "Do you want to delete this family member ?",
        footer: (
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger ml-3"
              onClick={() => {
                dispatch(closeSimpleModal());
              }}
            >
              No
            </button>
            <button
              className="btn btn-success ml-3"
              onClick={() => deleteHandler(memberPatientKey)}
            >
              Yes
            </button>
          </div>
        ),
      }),
    );
  };

  const searchPatientHandler = (values) => {
    const phone = _.get(values, "phoneNumber.number", "");
    const town = _.get(values, "phoneNumber.townCode", "");
    const country = _.get(values, "phoneNumber.countryCode.value", "");

    const updatedQuery = {
      ...query,
      firstName: _.get(values, "firstName", null),
      lastName: _.get(values, "lastName", null),
      email: _.get(values, "email", null),
      phoneNumber: phone && country ? `${country}${town}${phone}` : null,
      patientChartNumber: _.get(values, "patientChartNumber", null),
    };
    history.replace(createUrl(location.pathname, { query: updatedQuery }));

    dispatch(initList(updatedQuery));
  };

  return (
    <Sidebar
      bodyProps={{ style: { padding: 0 } }}
      show={show}
      onHide={onHide}
      dialogClassName={`chart-side-bar family-members-dialog w-${isSearchMode ? "40" : "55"}`}
    >
      <Show if={formMode === "list"}>
        <SidebarHeader title="Patient Family Members" onBackClick={onHide} />
      </Show>

      <div className="chart-clinical-note">
        <div className="chart-side-body" style={{ overflow: "hidden" }}>
          <Show if={formMode === "add"}>
            <PatientFamilyMembersAddForm
              initialValues={query}
              onSubmit={searchPatientHandler}
              setFormMode={setFormMode}
            />

            {(list || []).length > 0 ? (
              <>
                <h3 className="text-center" style={{ fontSize: "16px", marginTop: "-100px" }}>
                  Patient(s) associated with this number
                </h3>
                <div className="body family-members-body">
                  <PatientFamilyMembersTable
                    list={list}
                    chooseFamilyMember={chooseFamilyMemberHandler}
                  />
                </div>
              </>
            ) : null}
          </Show>

          <Show if={formMode === "list"}>
            <div className="body" style={{ overflowX: "auto" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>Chart Number</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Clinic</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {(patientFamilyMembers || []).map((familyMember, idx) => (
                    <tr key={idx}>
                      <td style={{ verticalAlign: "middle" }}>
                        {_.get(familyMember, "chartNumber")}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{_.get(familyMember, "fullName")}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        {_.get(familyMember, "mobilePhoneNumber")}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{_.get(familyMember, "email")}</td>
                      <td style={{ verticalAlign: "middle" }}>{_.get(familyMember, "clinic")}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div
                          className="family-member-delete cursor-pointer"
                          role="button"
                          onClick={() => warnModalHandler(familyMember?.patientKey)}
                        >
                          <FilledImage src={deleteImage} color="white" className="zoom-2-5x" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Show>
          <Show if={formMode === "list"}>
            <div className="all-codes">
              <button className="btn btn-primary btn-block" onClick={() => setFormMode("add")}>
                Add
              </button>
            </div>
          </Show>
        </div>
      </div>
    </Sidebar>
  );
};
export default PatientFamilyMembersSidebar;
