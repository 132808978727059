import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { DropdownButton } from "./DropdownButton";
import { useSelector } from "react-redux";

export const POINTS_MODE = "points";
export const DOCTORS_MODE = "doctors";
export const CHART_COMPLETE = "chart_complete";
export const MEDICAL_FORM_COMPLETE = "medical_form_complete";

DoctorsSelect.propTypes = {
  points: PropTypes.object,
  doctors: PropTypes.array,

  onSelect: PropTypes.func,

  searchable: PropTypes.bool,

  allDoctorsTitle: PropTypes.string,

  mode: PropTypes.oneOf([POINTS_MODE, DOCTORS_MODE]),

  disabled: PropTypes.bool,
  hideBySelect: PropTypes.bool,

  formatOneDoctorTitle: PropTypes.func,
  formatMoreDoctorsTitle: PropTypes.func,
};

DoctorsSelect.defaultProps = {
  mode: POINTS_MODE,
  allDoctorsTitle: "All Doctors",
};

export function DoctorsSelect({
  mode,
  points,
  onSelect,
  disabled,
  formatValue,
  formatTitle,
  searchable,
  hideBySelect,
  allDoctorsTitle,
  doctors: doctorList,
  formatOneDoctorTitle,
  formatMoreDoctorsTitle,
}) {
  let activeCount = 0;
  let activeDoctor = 0;
  let activePoint = 0;
  const members = useSelector((store) => store?.auth.members);

  const doctors = mode === DOCTORS_MODE ? doctorList : [];

  if (mode === POINTS_MODE) {
    _.forEach(points, (doctor) => {
      _.forEach(doctor.dentalPoints, (point) => {
        let isActive = false;

        if (point.active) {
          activeCount++;
          isActive = true;
          activePoint = point.name;
          activeDoctor = doctor.name;
        }

        doctors.push({
          point,
          doctor,
          isActive,
        });
      });
    });
  }

  if (mode === DOCTORS_MODE) {
    doctors.forEach((x) => {
      if (x.active) {
        activeCount++;
        activeDoctor = x.name;
      }
    });
  }
  const items = doctors
    .filter((item) => members[item.id] && members[item.id]?.status.id != 1)
    .sort((a, b) => {
      const aStr = mode === POINTS_MODE ? a.doctor.name + a.point.name : a.name;
      const bStr = mode === POINTS_MODE ? b.doctor.name + b.point.name : b.name;

      if (aStr < bStr) return -1;
      if (aStr > bStr) return 1;
      return 0;
    })
    .map((item) => {
      if (mode === DOCTORS_MODE) {
        const { id, name, active } = item;

        return {
          selected: active,
          value: id,
          title: name,
        };
      }

      const { doctor, point, isActive } = item;

      return {
        selected: isActive,
        value: _.isFunction(formatValue) ? formatValue(item) : `${doctor.id},${point.id}`,
        title: _.isFunction(formatTitle) ? formatTitle(item) : `${doctor.name} (${point.name})`,
      };
    });

  items.unshift({
    divider: true,
    selected: !activeCount,
    title: allDoctorsTitle,
    value: mode === POINTS_MODE ? "0,0" : 0,
  });

  const title = !activeCount
    ? allDoctorsTitle
    : activeCount === 1
    ? formatOneDoctorTitle(activeDoctor, activePoint)
    : formatMoreDoctorsTitle(activeCount);

  return (
    <DropdownButton
      title={title}
      items={items}
      searchable={searchable}
      disabled={disabled}
      hideBySelect={hideBySelect}
      className="btn btn-default"
      onSelect={({ value }) => onSelect(value)}
    />
  );
}
