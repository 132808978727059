import React, { useEffect, useState } from "react";
import { SelectPicker } from "../select-picker/SelectPicker";
import { toFinite } from "lodash";
import { Modal } from "../ui/Modal";
import { connect, useDispatch } from "react-redux";
import {
  getDoctorsLocations,
  getFreePoints,
  setAppointmentTreatmentsPlanValues,
} from "../../actions/appointmentActions";
import moment from "moment/moment";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../helpers/DateUtils";
import { DatePicker } from "../ui/DatePicker";
import Utils from "../../helpers/Utils";

const enhancer = connect(({ appointment: { appointmentTreatmentsPlanValues } }) => ({
  appointmentTreatmentsPlanValues,
}));

const AppointmentTreatmentsPlanModal = (props) => {
  const dispatch = useDispatch();
  const {
    appointmentTreatmentsPlanModalVisible,
    setAppointmentTreatmentsPlanModalVisible,
    elements,
    appointmentTreatmentsPlanValues,
    appointment,
  } = props;

  const initialValues = {
    date: { value: moment() },
    time: { value: null, options: [] },
    doctor: { value: null },
    point: { value: null },
    status: { value: null },
    type: { value: null },
  };
  const frequencyOptions = [
    { value: "DAILY", label: "Daily" },
    { value: "WEEKLY", label: "Weekly" },
    { value: "MONTHLY", label: "Monthly" },
  ];
  const [appointmentCount, setAppointmentCount] = useState(1);
  const [appointmentFrequency, setAppointmentFrequency] = useState(frequencyOptions[1].value);
  const [dentalPoints, setDentalPoints] = useState([]);
  const [parentForm, setParentForm] = useState(initialValues);

  useEffect(() => {
    initialValues.status.value = elements.statusSB.selectedId;
    initialValues.date.value = elements.date.value;
    dispatch(getDoctorsLocations()).then((data) => setDentalPoints(data));
    dispatch(setAppointmentTreatmentsPlanValues([initialValues]));
  }, []);

  useEffect(() => {
    if (appointmentTreatmentsPlanValues.length) {
      appointmentTreatmentsPlanValues.forEach((item, index) => {
        if (item.doctor.value && item.point.value && item.date.value) {
          getFreeTimes(item.doctor.value, item.point.value, item.date.value, index);
        }
      });
    }
  }, [appointmentTreatmentsPlanValues]);

  const onDeleteAppointmentItem = (index) => {
    const newItems = appointmentTreatmentsPlanValues.filter((_, idx) => index !== idx);
    dispatch(setAppointmentTreatmentsPlanValues(newItems));
  };

  const getFreeTimes = (dentistId, dentalPointId, date, index, appointmentCurrentTime) => {
    if (date && elements.lengthSB.selectedId) {
      const periodStart = getCompanyStartOfDay(date, false, true);
      const periodEnd = getCompanyEndOfDay(periodStart);

      const transfer = {
        periodEnd,
        periodStart,
        machineId: elements.machinesSB.selectedId !== -100 ? elements.machinesSB.selectedId : -1,
        appointmentId: appointment?.id,
        duration: elements.lengthSB.selectedId,
        dentalPointId: dentalPointId,
        dentistId: dentistId,
      };
      dispatch(getFreePoints(transfer)).then((data) => {
        if (appointmentCurrentTime) {
          const endTime = appointmentCurrentTime + elements.lengthSB.selectedId;
          data.push({ startTime: appointmentCurrentTime, endTime });
        }
        let currentTime;
        if (
          elements.freeTimesSB.selectedId &&
          elements.freeTimesSB.selectedId !== appointmentCurrentTime
        ) {
          currentTime = {
            startTime: elements.freeTimesSB.selectedId,
            endTime: elements.freeTimesSB.selectedId + elements.lengthSB.selectedId,
          };
        }
        const timeOptions = data
          .sort((a, b) => a.startTime - b.startTime)
          .map((item) => {
            const start = Utils.calcDateWithTimeZone(item.startTime);
            const end = Utils.calcDateWithTimeZone(item.endTime);

            return {
              value: start.valueOf(),
              label: `${Utils.parseDateFormat(start)} - ${Utils.parseDateFormat(end)}${
                item.reserved ? ` ${item.reservedName}` : ""
              }`,
            };
          });
        if (index === "parentForm") {
          return setParentForm({
            ...parentForm,
            time: {
              value: appointmentCurrentTime || elements.freeTimesSB.selectedId,
              options: timeOptions,
            },
          });
        }
        appointmentTreatmentsPlanValues[index].time.value =
          timeOptions.length && timeOptions[0].value;
        appointmentTreatmentsPlanValues[index].time.options = timeOptions;
        dispatch(setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues));
      });
    }
  };

  const validateFields = (event) => {
    let invalidForm = false;
    const validatedForm = appointmentTreatmentsPlanValues.map((item) => {
      if (!item.doctor.value) {
        item.doctor.invalid = true;
        invalidForm = true;
      }
      if (!item.date.value) {
        item.date.invalid = true;
        invalidForm = true;
      }
      if (!item.time.value) {
        item.time.invalid = true;
        invalidForm = true;
      }
      if (!item.point.value) {
        item.point.invalid = true;
        invalidForm = true;
      }
      if (!item.type.value) {
        item.type.invalid = true;
        invalidForm = true;
      }
      return item;
    });
    if (invalidForm) {
      dispatch(setAppointmentTreatmentsPlanValues(validatedForm));
    } else {
      props.onSave(event);
      setAppointmentTreatmentsPlanModalVisible(false);
    }
  };

  const generateAppointments = () => {
    const newValues = [];
    for (let i = 0; i < appointmentCount; i++) {
      let date = null;
      switch (appointmentFrequency) {
        case "DAILY":
          date = moment(parentForm.date.value).add(i, "days");
          break;
        case "WEEKLY":
          date = moment(parentForm.date.value).add(i, "weeks");
          break;
        case "MONTHLY":
          date = moment(parentForm.date.value).add(i, "months");
          break;
        default:
          date = moment(parentForm.date.value);
      }
      const data = {
        ...initialValues,
        date: { value: date },
        doctor: { value: parentForm.doctor.value },
        time: { value: null, options: [] },
        point: { value: parentForm.point.value },
        status: { value: parentForm.status.value },
        type: { value: parentForm.type.value },
      };
      newValues.push(data);
    }
    dispatch(setAppointmentTreatmentsPlanValues(newValues));
  };
  return (
    <Modal
      dialogClassName="appointment-additional-doctors-modal"
      show={appointmentTreatmentsPlanModalVisible}
      actions={
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              setAppointmentTreatmentsPlanModalVisible(false);
              dispatch(setAppointmentTreatmentsPlanValues([initialValues]));
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={(event) => validateFields(event)}
          >
            Save
          </button>
        </div>
      }
    >
      <div className="row mt-3 mb-3">
        <div className="col-sm-3">
          <div className="d-flex align-items-center input-lg mt-3">
            Enter Number of Appointments Requested
          </div>
        </div>
        <div className="col-sm-3 d-flex justify-content-center flex-column">
          <label className="d-flex align-items-start flex-column justify-content-end">
            <span className="label" style={{ opacity: 0 }}>
              Frequency:
            </span>
            <input
              type="number"
              onChange={({ target: { value } }) => setAppointmentCount(Number(value || 0))}
              value={appointmentCount}
              className="form-control input-lg"
            />
          </label>
        </div>
        <div className="col-sm-3">
          <label className="d-flex align-items-start flex-column justify-content-end">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Frequency:
            </span>
            <SelectPicker
              size="lg"
              options={frequencyOptions}
              value={{ value: appointmentFrequency }}
              onChange={(x) => setAppointmentFrequency(x.value)}
            />
          </label>
        </div>
        <div className="col-sm-3">
          <label className="d-flex align-items-start flex-column justify-content-end">
            <span className="label" style={{ opacity: 0 }}>
              Frequency:
            </span>
            <button className="btn btn-success input-lg btn-block" onClick={generateAppointments}>
              Generate appointments
            </button>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Appointment Type:
            </span>
            <SelectPicker
              size="lg"
              isMulti={false}
              isSearchable={true}
              options={elements.categorySB.options.map(({ id, name }) => ({
                label: name,
                value: toFinite(id),
              }))}
              value={{ value: parentForm.type.value }}
              onChange={(x) => {
                setParentForm({ ...parentForm, type: { value: x.value } });
              }}
            />
          </label>
        </div>
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Appointment Status:
            </span>
            <SelectPicker
              size="lg"
              isMulti={false}
              isSearchable={true}
              options={elements.statusSB.options.map(({ id, name }) => ({
                label: name,
                value: toFinite(id),
              }))}
              value={{ value: parentForm.status.value }}
              onChange={(x) => {
                setParentForm({ ...parentForm, status: { value: x.value } });
              }}
            />
          </label>
        </div>
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Doctor:
            </span>
            <SelectPicker
              size="lg"
              isMulti={false}
              isSearchable={true}
              options={elements.dentistSB.options.map(({ id, name }) => ({
                label: name,
                value: toFinite(id),
              }))}
              value={{ value: parentForm.doctor.value }}
              onChange={(x) => {
                setParentForm({
                  ...parentForm,
                  doctor: { value: x.value },
                  point: { value: null },
                  time: { value: null },
                  date: { value: moment() },
                });
              }}
            />
          </label>
        </div>
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Trx. Room:
            </span>
            <SelectPicker
              size="lg"
              isMulti={false}
              isSearchable={true}
              options={(
                dentalPoints.find((i) => i.id === parentForm.doctor.value)?.dentalPoints || []
              ).map(({ id, name }) => ({
                label: name,
                value: toFinite(id),
              }))}
              value={{ value: parentForm.point.value }}
              onChange={(x) => {
                setParentForm({
                  ...parentForm,
                  point: { value: x.value },
                  time: { value: null, options: [] },
                });
              }}
              onBlur={() => {
                if (parentForm.point.value)
                  getFreeTimes(
                    parentForm.doctor.value,
                    parentForm.point.value,
                    parentForm.date.value,
                    "parentForm",
                  );
              }}
            />
          </label>
        </div>
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Date:
            </span>
            <DatePicker
              size="lg"
              selected={parentForm.date.value}
              onChange={(x) => {
                setParentForm({
                  ...parentForm,
                  date: { value: x },
                  time: { value: null, options: [] },
                });
                if (parentForm.point.value)
                  getFreeTimes(
                    parentForm.doctor.value,
                    parentForm.point.value,
                    parentForm.date.value,
                    "parentForm",
                  );
              }}
            />
          </label>
        </div>
        <div className="col-sm-2">
          <label className="d-flex align-items-start flex-column">
            <span className="label" style={{ padding: 0, color: "#555", fontSize: "14px" }}>
              Time:
            </span>
            <SelectPicker
              size="lg"
              options={parentForm.time.options}
              value={{ value: parentForm.time.value }}
              onChange={(x) => {
                setParentForm({ ...parentForm, time: { ...parentForm.time, value: x.value } });
              }}
            />
          </label>
        </div>
      </div>
      <div className="mt-3 mb-3" style={{ borderBottom: "5px solid rgb(190, 200, 68)" }} />
      <div
        style={{
          height: appointmentTreatmentsPlanValues.length > 1 ? "55vh" : "auto",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className="slim-scrollbar"
      >
        {appointmentTreatmentsPlanValues.map((item, index) => {
          const lastItem =
            appointmentTreatmentsPlanValues.length === index + 1 &&
            appointmentTreatmentsPlanValues.length > 3;
          return (
            <>
              <div className="row" key={index}>
                <div className="col-sm-11">
                  <div className="row">
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Appointment Type:
                        </span>
                        <SelectPicker
                          size="lg"
                          isMulti={false}
                          isSearchable={true}
                          menuPlacement={lastItem ? "top" : "auto"}
                          maxMenuHeight={200}
                          // isDisabled={elements.dentistSB.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].type.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          options={elements.categorySB.options.map(({ id, name }) => ({
                            label: name,
                            value: toFinite(id),
                          }))}
                          value={{ value: item.type.value }}
                          onChange={(x) => {
                            if (appointmentTreatmentsPlanValues[index].type.invalid) {
                              appointmentTreatmentsPlanValues[index].type.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].type.value = x.value;
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Appointment Status:
                        </span>
                        <SelectPicker
                          size="lg"
                          isMulti={false}
                          isSearchable={true}
                          menuPlacement={lastItem ? "top" : "auto"}
                          maxMenuHeight={200}
                          // isDisabled={elements.dentistSB.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].status.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          options={elements.statusSB.options.map(({ id, name }) => ({
                            label: name,
                            value: toFinite(id),
                          }))}
                          value={{ value: item.status.value }}
                          onChange={(x) => {
                            if (appointmentTreatmentsPlanValues[index].status.invalid) {
                              appointmentTreatmentsPlanValues[index].status.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].status.value = x.value;
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Doctor:
                        </span>
                        <SelectPicker
                          size="lg"
                          isMulti={false}
                          isSearchable={true}
                          menuPlacement={lastItem ? "top" : "auto"}
                          maxMenuHeight={200}
                          // isDisabled={elements.dentistSB.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].doctor.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          options={elements.dentistSB.options.map(({ id, name }) => ({
                            label: name,
                            value: toFinite(id),
                          }))}
                          value={{ value: item.doctor.value }}
                          onChange={(x) => {
                            // if (x.value === elements.dentistSB.selectedId) {
                            //   return;
                            // }
                            if (appointmentTreatmentsPlanValues[index].doctor.invalid) {
                              appointmentTreatmentsPlanValues[index].doctor.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].doctor.value = x.value;
                            appointmentTreatmentsPlanValues[index].point.value = null;
                            appointmentTreatmentsPlanValues[index].time.value = null;
                            appointmentTreatmentsPlanValues[index].date.value = moment();
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Trx. Room:
                        </span>
                        <SelectPicker
                          size="lg"
                          isMulti={false}
                          isSearchable={true}
                          menuPlacement={lastItem ? "top" : "auto"}
                          maxMenuHeight={200}
                          // isDisabled={!item.date.value || elements.dentalPointSB.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].point.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          options={(
                            dentalPoints.find((i) => i.id === item.doctor.value)?.dentalPoints || []
                          ).map(({ id, name }) => ({
                            label: name,
                            value: toFinite(id),
                          }))}
                          value={{ value: item.point.value }}
                          onChange={(x) => {
                            if (appointmentTreatmentsPlanValues[index].point.invalid) {
                              appointmentTreatmentsPlanValues[index].point.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].point.value = x.value;
                            appointmentTreatmentsPlanValues[index].time.value = null;
                            appointmentTreatmentsPlanValues[index].time.options = [];
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                          }}
                          onBlur={() => {
                            if (item.point.value)
                              getFreeTimes(
                                item.doctor.value,
                                item.point.value,
                                item.date.value,
                                index,
                              );
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Date:
                        </span>
                        <DatePicker
                          size="lg"
                          selected={item.date.value}
                          // disabled={elements.date.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].date.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          onChange={(x) => {
                            if (appointmentTreatmentsPlanValues[index].date.invalid) {
                              appointmentTreatmentsPlanValues[index].date.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].date.value = x;
                            appointmentTreatmentsPlanValues[index].time.value = null;
                            appointmentTreatmentsPlanValues[index].time.options = [];
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                            if (item.point.value)
                              getFreeTimes(
                                item.doctor.value,
                                item.point.value,
                                item.date.value,
                                index,
                              );
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <label className="d-flex align-items-start flex-column">
                        <span
                          className="label"
                          style={{ padding: 0, color: "#555", fontSize: "14px" }}
                        >
                          Time:
                        </span>
                        <SelectPicker
                          size="lg"
                          options={item.time.options}
                          value={{ value: item.time.value }}
                          menuPlacement={lastItem ? "top" : "auto"}
                          maxMenuHeight={200}
                          // isDisabled={elements.freeTimesSB.disabled}
                          className={
                            appointmentTreatmentsPlanValues[index].time.invalid
                              ? "invalid-form-field"
                              : ""
                          }
                          onChange={(x) => {
                            if (appointmentTreatmentsPlanValues[index].time.invalid) {
                              appointmentTreatmentsPlanValues[index].time.invalid = false;
                            }
                            appointmentTreatmentsPlanValues[index].time.value = x.value;
                            dispatch(
                              setAppointmentTreatmentsPlanValues(appointmentTreatmentsPlanValues),
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1">
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-rounded mr-3"
                      style={{ marginTop: "1.5rem" }}
                      onClick={() => onDeleteAppointmentItem(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>

              <div className="mt-3 mb-3" style={{ borderBottom: "2px solid rgb(190, 200, 68)" }} />
            </>
          );
        })}
      </div>
      {/*<Show if={!isEdit}>*/}
      <div className="d-flex align-items-center justify-content-end mt-3">
        <span style={{ marginRight: 50 }}>
          Appointments: {appointmentTreatmentsPlanValues.length}
        </span>
        {/*{appointmentTreatmentsPlanValues.length > 1 && (*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-danger btn-rounded mr-3"*/}
        {/*    onClick={() => {*/}
        {/*      dispatch(*/}
        {/*        setAppointmentTreatmentsPlanValues(*/}
        {/*          appointmentTreatmentsPlanValues.slice(*/}
        {/*            0,*/}
        {/*            appointmentTreatmentsPlanValues.length - 1,*/}
        {/*          ),*/}
        {/*        ),*/}
        {/*      );*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    -*/}
        {/*  </button>*/}
        {/*)}*/}
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btn btn-success btn-rounded"*/}
        {/*  onClick={() => {*/}
        {/*    dispatch(*/}
        {/*      setAppointmentTreatmentsPlanValues([*/}
        {/*        ...appointmentTreatmentsPlanValues,*/}
        {/*        initialValues,*/}
        {/*      ]),*/}
        {/*    );*/}
        {/*  }}*/}
        {/*>*/}
        {/*  +*/}
        {/*</button>*/}
      </div>
      {/*</Show>*/}
    </Modal>
  );
};

export default enhancer(AppointmentTreatmentsPlanModal);
