import React, { useEffect } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, ButtonGroup } from "react-bootstrap";

import {
  getPatientsListWhChats,
  openWhatsAppChat,
  hideWhatsAppChat,
  archiveWhChatting,
} from "../../actions/whatsAppChattingActions";
import { Routes } from "../../constants/Routes";
import Header from "../layout/DefaultPageHeader";
import PatientsWhatsappFilterForm from "./WhatsAppForm";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import WhatsAppChattingUsersTable from "./WhatsAppChattingPatientsList";
import { resetPatientWhatsappMessages } from "../../actions/patientWhMessagesActions";

const enhancer = connect(({ whatsappChatting, dashboard, auth }) => {
  return { whatsappChatting, dashboard, auth };
});
const WhatsAppChatting = (props) => {
  const queryParams = parseQuery(props.location.search);
  const isArchived = props.history.location.search.includes("archived=true");

  useEffect(() => {
    props.dispatch(getPatientsListWhChats({ archived: isArchived, ...queryParams }));
  }, [props.location.search]);

  // useEffect(() => {
  //   props.whatsappChattingActions.initPatientsList(props.location.query);
  // }, []);

  // useEffect(() => {
  //   if (!_.isEqual(props.location.query, newProps.location.query))
  //     props.whatsappChattingActions.initPatientsList(newProps.location.query);
  // }, []);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   props.updateCalendar();
  // };

  const archiveWHChatting = (patientKey) => {
    props.dispatch(archiveWhChatting(patientKey));
  };

  const tabChange = (bool) => {
    props.history.push(
      createUrl(Routes.DashboardWhatsappChatting, {
        query: {
          ...queryParams,
          archived: bool,
        },
      }),
    );
  };

  const openChattingSidebar = (patient) => {
    props.history.push(
      createUrl(Routes.DashboardWhatsappChatting, {
        query: {
          ...parseQuery(props.location.search),
          pKey: patient.patientKey,
        },
      }),
    );
    props.dispatch(openWhatsAppChat(patient));
  };

  const hideChattingSidebar = () => {
    props.history.push(
      createUrl(Routes.DashboardWhatsappChatting, {
        query: {
          ...parseQuery(props.location.search),
          pKey: null,
        },
      }),
    );

    props.dispatch(resetPatientWhatsappMessages());
    props.dispatch(hideWhatsAppChat());
  };

  const { whatsappChattingActions } = props;

  const { whatsappChatting } = props;

  return (
    <div className="whatsapp-chatting-page">
      <Header
        addClass="whatsapp-chatting-header mt-1"
        onClick={() => props.history.push(Routes.DashboardCalendar)}
      >
        <PatientsWhatsappFilterForm
          onSubmit={() => props.dispatch(whatsappChattingActions.getPatientsListWhChats())}
        />
      </Header>
      <div className="whatsapp-chatting-users">
        <ButtonGroup className="whatsapp-chatting-tab-buttons">
          <Button
            bssize="lg"
            style={{ width: "50%" }}
            variant={isArchived ? "light" : "primary"}
            onClick={() => tabChange(false)}
          >
            All messages
          </Button>
          <Button
            bssize="lg"
            style={{ width: "50%" }}
            onClick={() => tabChange(true)}
            variant={isArchived ? "primary" : "light"}
          >
            Archived
          </Button>
        </ButtonGroup>
        <WhatsAppChattingUsersTable
          history={props.history}
          location={props.location}
          hideWhatsAppChat={hideChattingSidebar}
          openWhatsAppChat={openChattingSidebar}
          archivePatientChatting={archiveWHChatting}
          setChattingPatientList={whatsappChattingActions?.setChattingPatientsList || []}
          list={whatsappChatting.list || []}
          chatSidebarVisible={whatsappChatting.chatSidebarVisible}
        />
      </div>
    </div>
  );
};

export default withRouter(enhancer(WhatsAppChatting));
