import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import FilledImage from "../widgets/FilledImage";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import MenuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersTogglePatientSelected extends React.Component {
  render() {
    const { togglePatientSelected, searchPatientSelected } = this.props;

    let list = [];
    let patientCollection = _.values(togglePatientSelected);

    const togglePatientSelectedAfterFilter = _.filter(patientCollection, (element) => {
      if (_.lowerCase(element.name).indexOf(searchPatientSelected) !== -1) return true;
    });

    _.forEach(togglePatientSelectedAfterFilter, (value, index) => {
      let patientName;
      const isTemp = !_.isFinite(value.id);

      if (value.patient && !isTemp) {
        patientName =
          _.get(value, "patient.firstName", "") + " " + _.get(value, "patient.lastName", "");
      } else {
        patientName = value.name || `${value.firstName} ${value.lastName}`;
      }
      list.push(
        <div
          className="list-group-item"
          key={index}
          onClick={() => {
            this.props.remindersActions.togglePatient(
              isTemp,
              isTemp ? value.code : value.id,
              false,
              value.companyPatientId || value.companyTempPatientId,
            );
          }}
        >
          <div className="text">{patientName}</div>
          <div className="arrow">✔</div>
        </div>,
      );
    });

    return (
      <div>
        <div className="input-group search">
          <span className="input-group-addon">
            <FilledImage color={"#aaa"} src={MenuImage} className="zoom-2-5x" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="search"
            value={searchPatientSelected}
            onChange={this.props.remindersActions.searchPatientSelected}
          />
        </div>
        <div className="list-group">{list}</div>
      </div>
    );
  }
}
export default enhancer(RemindersTogglePatientSelected);
