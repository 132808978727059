import { httpClient } from "./BaseApi";
import { parserCallback } from "../helpers/Parser";

const MEDICATION_CREATE_PDF_URL = "api/patient/medication/create-pdf.json";

const PATIENT_LIST_URL = "api/patient/list.json";
const PATIENT_LANGUAGE_LIST_URL = "api/patient/languages/choose.json";
const PATIENT_FILES_URL = "api/patient/files.json";
const PATIENT_UPLOAD_URL = "api/patient/upload.json";
const PATIENT_BALANCE_URL = "api/patient/balance.json";
const PATIENT_BILLING_BALANCE_URL = "api/billing/patient/balance/item.json";
const PATIENT_REMINDER_URL = "api/patient/reminder.json";
const PATIENT_UPLOAD_PHOTO_URL = "api/patient/upload-photo.json";
const PATIENT_PAYMENT_OPTIONS_URL = "api/patient/payment-options.json";

const PATIENT_GENDER_CHOOSE_URL = "api/patient/gender/choose.json";
const PATIENT_TITLES_CHOOSE_URL = "api/member/title/choose.json";
const PATIENT_OCCUPATION_CHOOSE_URL = "api/patient/occupation-type/choose.json";
const PATIENT_NATIONALITY_LIST_URL = "api/patient/nationality/list.json";
const PATIENT_PROFESSION_LIST_URL = "api/patient/profission/choose.json";
const PATIENT_MERITAL_STATUS_CHOOSE_URL = "api/patient/marital-status/choose.json";
const PATIENT_PCP_RELATIONSHIP_TYPE_URL = "api/patient/pcp/relationship-type/.choose.json";
const PATIENT_PCP_ROLE_TYPE_URL = "api/patient/pcp/role-type/.choose.json";

const PATIENT_FIRST_CONTACT_ITEM_URL = "api/patient/first-contact/item.json";
const PATIENT_FIRST_CONTACT_LIST_URL = "api/patient/first-contact/list.json";

const PATIENT_SEARCH_LOGIN_URL = "api/patient/search/login.json";

const PATIENT_PRICE_LIST_URL = "api/patient/price/list.json";

const PATIENT_MEMBER_LIST_URL = "api/patient/member/list.json";

const PATIENT_REFERRAL_CHOOSE_URL = "api/patient/referral/choose.json";

const PATIENT_HISTORY_LIST_URL = "api/patient/history/list.json";

const PATIENT_DETAILS_INFO_URL = "api/patient/patient-details/info.json";

const PATIENT_DASHBOARD_FOR_TEMP_URL = "api/patient/dashboard/for-temp.json";
const PATIENT_DASHBOARD_FOR_STAFF_URL = "api/patient/dashboard/for-staff.json";

const PERIO_EXAM_ITEM_URL = "api/perio-exam/history.json";
const PERIO_EXAM_LIST_URL = "api/perio-exam/history-list.json";
const PERIO_EXAM_VOIDED_LIST_URL = "api/perio-exam/history/list.json";
const PERIO_EXAM_START_ITEM = "api/perio-exam/list.json";
const SAVE_PERIO_EXAM_ITEM = "api/perio-exam/items.json";
const UPDATE_DATE_PERIO_EXAM_ITEM = "api/perio-exam/history.json";
const PERIO_EXAM_VOID_ITEM_URL = "api/perio-exam/history/void.json";

const CLINIC_APPOINTMENT_PATIENT_LIST_URL = "api/clinic/appointment/patient/list.json";
const CLINIC_APPOINTMENT_DENTIST_LIST_URL = "api/clinic/appointment/dentist/list.json";
const CLINIC_APPOINTMENT_DENTIST_CALENDAR_LIST_URL =
  "api/clinic/appointment/dentist/calendar-list.json";

const COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL = "api/company/patient/need-sign-form/item.json";
const COMPANY_PATIENT_NEED_SIGN_FORM_LIST_URL = "api/company/patient/need-sign-form/list.json";
const COMPANY_PATIENT_NEED_SIGN_FORM_SIGN_URL = "api/company/patient/need-sign-form/sign.json";
const COMPANY_PATIENT_NEED_SIGN_FORM_FILLED_ITEM_URL =
  "api/company/patient/need-sign-form/filled-item.json";
const COMPANY_PATIENT_NEED_SIGN_FORM_REGISTER_AND_SIGN_URL =
  "api/company/patient/need-sign-form/register-and-sign.json";

const PATIENT_TOOTH_CONDITION_LAST_LIST_URL = "api/patient/tooth-condition/last-list.json";

const PATIENT_TOOTH_CONDITION_TOOTH_ADD_URL = "api/patient/tooth-condition/tooth/add.json";
const PATIENT_TOOTH_CONDITION_TOOTH_DELETE_URL = "api/patient/tooth-condition/tooth/delete.json";
const PATIENT_TOOTH_CONDITION_TOOTH_CHANGE_URL = "api/patient/tooth-condition/tooth/change.json";

const PATIENT_INSURANCE_LIST_URL = "api/patient/insurance/list.json";
const PATIENT_INSURANCE_ITEM_URL = "api/patient/insurance/item.json";
const PATIENT_INSURANCE_UPLOAD_PHOTO_URL = "api/patient/insurance/upload-photo.json";
const PATIENT_INSURANCE_MARK_AS_PRIMARY_URL = "api/patient/insurance/mark-as-primary.json";

const PATIENT_INSURANCE_TYPE_CHOOSE_URL = "api/patient/insurance/type/choose.json";
const PATIENT_INSURANCE_BROKERS_CHOOSE_URL = "api/patient/insurance/brokers/choose.json";
const PATIENT_INSURANCE_COMPANY_CHOOSE_URL = "api/patient/insurance/company/choose.json";
const PATIENT_INSURANCE_RELATION_CHOOSE_URL = "api/patient/insurance/relation/choose.json";
const PATIENT_INSURANCE_SEARCH_CARD_NUMBER_URL = "api/patient/insurance/search/card-number.json";
const PATIENT_INSURANCE_COMPANY_PLAN_CHOOSE_URL = "api/patient/insurance/company-plan/choose.json";

const PATIENT_PROFILE_NOTE_LIST_URL = "api/patient/profile/note/list.json";
const PATIENT_PROFILE_NOTE_ITEM_URL = "api/patient/profile/note/item.json";
const PATIENT_CLAIMS_URL = "/api/claim/list.json";
const PATIENT_CLAIMS_STATUS_URL = "/api/claim/pre-approval/status/choose.json";
const PATIENT_CLAIMS_DOC_TYPE_URL = "/api/claim/insurance-document-type/choose.json";
const PATIENT_PRINT_CLAIM_URL = "/api/claim/pdf-doc.json";

const PATIENT_APPROVALS_LIST_URL = "/api/claim/pre-approval/list.json";
const PATIENT_APPROVALS_ITEM_URL = "/api/claim/pre-approval/item.json";
const PATIENT_APPROVALS_STATUS_URL = "/api/claim/pre-approval/status/choose.json";
const PATIENT_APPROVALS_DOC_TYPE_URL =
  "/api/claim/pre-approval/insurance-document-type/choose.json";

const PATIENT_CLAIMED_STATUS_URL = "/api/claim/claimedStatus/choose.json";
const PATIENT_APPROVALS_NOTE_URL = "/api/claim/pre-approval/notes.json";
const PATIENT_CLAIMS_NOTE_URL = "/api/claim/notes.json";

const PATIENT_PRESCRIPTION_LIST_URL = "api/patient/prescription/list.json";
const PATIENT_PRESCRIPTION_ITEM_URL = "api/patient/prescription/item.json";
const PATIENT_PRESCRIPTION_FORM_DATA_URL = "api/patient/prescription/form-data.json";
const PATIENT_PRESCRIPTION_SEND_TO_EMAIL_URL = "api/patient/prescription/send-to-email.json";

const PATIENT_PRESCRIPTION_TEMPLATE_ITEM_URL = "api/patient/prescription/template/item.json";
const PATIENT_PRESCRIPTION_TEMPLATE_LIST_URL = "api/patient/prescription/template/list.json";
const PATIENT_PRESCRIPTION_TEMPLATE_TYPE_AHEAD_URL =
  "api/patient/prescription/template/typeahead.json";

const PATIENT_PRESCRIPTION_CATEGORY_CHOOSE_URL = "api/patient/prescription/category/choose.json";

const PATIENT_PRESCRIPTION_GROUP_LIST_URL = "api/patient/prescription/group/list.json";

const PERSONAL_DETAILS_URL = "api/patient/signature-form/personal-details/full.json";
const PATIENT_MEDICAL_ALERTS_URL = "api/patient/disease/list.json";
const PATIENT_MEDICAL_ALERTS_SAVE_URL = "api/patient/disease/full.json";
const PATIENT_MEDICAL_ALERTS_CATEGORY_URL = "api/patient/disease/type/choose.json";
const PATIENT_MEDICATION_ALERTS_URL = "api/patient/signature-form/medication-alerts/full.json";

const PATIENT_POST_OP_ITEM_URL = "api/patient/postOperationInstruction/item.json";
const PATIENT_POST_OP_SEND_TO_EMAIL_URL = "api/patient/postOperationInstruction/send-to-email.json";
const PATIENT_POST_OP_TEMPLATE_ITEM_URL = "api/patient/postOperationInstruction/template/item.json";

const PRIMARY_CLINICIAN_ITEM_URL = "api/patient/primary-clinician/item.json";
const PATIENT_HISTORY_URL = "api/patient/sso.json";
const PATIENT_LABEL_URL = "api/patient/print-label.json";
const PATIENT_ASSIGNED_TAGS_URL = "api/patient/tags.json";
const PATIENT_TAGS_CHOOSE_URL = "api/patient/tags/choose.json";

const PATIENT_MEMBERS_LIST_URL = "api/patient/family-members/list.json";

const PATIENT_MEDICAL_ALERTS_SUB_CATEGORIES_URL = "api/patient/disease/form-data.json";

const PatientApi = {
  getPcpRelationshipType: (params) => httpClient.get(PATIENT_PCP_RELATIONSHIP_TYPE_URL, { params }),
  getPcpRoleType: (params) => httpClient.get(PATIENT_PCP_ROLE_TYPE_URL, { params }),
  getPatientMembersList: (params) => httpClient.get(PATIENT_MEMBERS_LIST_URL, { params }),
  getTagsList: (params) => httpClient.get(PATIENT_TAGS_CHOOSE_URL, { params }),
  saveTagsList: (data) => httpClient.put(PATIENT_ASSIGNED_TAGS_URL, { data }),
  getAssignedTagsList: (params) => httpClient.get(PATIENT_ASSIGNED_TAGS_URL, { params }),
  getList: (params) => httpClient.get(PATIENT_LIST_URL, { params }),
  printPatientLabel: (params) => httpClient.get(PATIENT_LABEL_URL, { params }),
  getLanguages: () => httpClient.get(PATIENT_LANGUAGE_LIST_URL, {}),
  uploadDocs: (data) => httpClient.post(PATIENT_UPLOAD_URL, { data }),
  getFiles: (params) => httpClient.get(PATIENT_FILES_URL, { params }),
  getPatientHistory: (params) => httpClient.get(PATIENT_HISTORY_URL, { params }),
  searchPatients: (params) => httpClient.get(PATIENT_LIST_URL, { params }),
  deleteFiles: (params) => httpClient.delete(PATIENT_FILES_URL, { params }),
  uploadPhoto: (data) => httpClient.post(PATIENT_UPLOAD_PHOTO_URL, { data }),
  getPriceList: (params) => httpClient.get(PATIENT_PRICE_LIST_URL, { params }),
  getGender: (params) => httpClient.get(PATIENT_GENDER_CHOOSE_URL, { params }).then(parserCallback),
  getTitles: (params) => httpClient.get(PATIENT_TITLES_CHOOSE_URL, { params }).then(parserCallback),
  getOccupationListApi: (params) =>
    httpClient.get(PATIENT_OCCUPATION_CHOOSE_URL, { params }).then(parserCallback),
  getPerioExamList: (params) => httpClient.get(PERIO_EXAM_LIST_URL, { params }),
  getPerioExamVoidedList: (params) => httpClient.get(PERIO_EXAM_VOIDED_LIST_URL, { params }),
  getPerioExamItem: (params) => httpClient.get(PERIO_EXAM_ITEM_URL, { params }),
  startPerioExamItem: (params) => httpClient.get(PERIO_EXAM_START_ITEM, { params }),
  savePerioExamItem: (data) => httpClient.put(SAVE_PERIO_EXAM_ITEM, { data }),
  updateDatePerioExam: (data) => httpClient.put(UPDATE_DATE_PERIO_EXAM_ITEM, { data }),
  updatePerioExamVoidItem: (data) => httpClient.put(PERIO_EXAM_VOID_ITEM_URL, { data }),
  getPatientBalance: (params) => httpClient.get(PATIENT_BALANCE_URL, { params }),
  getPatientBillingBalance: (params) => httpClient.get(PATIENT_BILLING_BALANCE_URL, { params }),
  savePersonalDetails: (data) => httpClient.put(PERSONAL_DETAILS_URL, { data }),
  updatePrimaryClinician: (data) => httpClient.put(PRIMARY_CLINICIAN_ITEM_URL, { data }),
  getPersonalDetails: (params) => httpClient.get(PERSONAL_DETAILS_URL, { params }),
  getDetailsInfo: (params) => httpClient.get(PATIENT_DETAILS_INFO_URL, { params }),
  validateRegister: (params) => httpClient.get(PATIENT_SEARCH_LOGIN_URL, { params }),
  getProfile: (params) => httpClient.get(PATIENT_DASHBOARD_FOR_STAFF_URL, { params }),
  paymentOptions: (params) => httpClient.get(PATIENT_PAYMENT_OPTIONS_URL, { params }),
  createMedicationPDF: (data) => httpClient.post(MEDICATION_CREATE_PDF_URL, { data }),
  getForStaff: (params) => httpClient.get(PATIENT_DASHBOARD_FOR_STAFF_URL, { params }),
  getNationality: (params) => httpClient.get(PATIENT_NATIONALITY_LIST_URL, { params }),
  getProfession: (params) => httpClient.get(PATIENT_PROFESSION_LIST_URL, { params }),
  saveTempPatient: (data) => httpClient.put(PATIENT_FIRST_CONTACT_ITEM_URL, { data }),
  getFirstContactPatientList: (params) =>
    httpClient.get(PATIENT_FIRST_CONTACT_LIST_URL, { params }),
  getReferralTypes: (params) => httpClient.get(PATIENT_REFERRAL_CHOOSE_URL, { params }),
  getTempProfile: (params) => httpClient.get(PATIENT_DASHBOARD_FOR_TEMP_URL, { params }),
  getItem: (params) => httpClient.get(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { params }),
  getPatientAlertsHistory: (params) => httpClient.get(PATIENT_HISTORY_LIST_URL, { params }),
  getMaritalStatus: (params) =>
    httpClient.get(PATIENT_MERITAL_STATUS_CHOOSE_URL, { params }).then(parserCallback),
  signConsentForm: (data) => httpClient.post(COMPANY_PATIENT_NEED_SIGN_FORM_SIGN_URL, { data }),
  getAppointmentRecentList: (params) =>
    httpClient.get(CLINIC_APPOINTMENT_DENTIST_LIST_URL, { params }),
  getPatientAppointmentList: (params) =>
    httpClient.get(CLINIC_APPOINTMENT_PATIENT_LIST_URL, { params }),
  getAppointmentCalendarList: (params) =>
    httpClient.get(CLINIC_APPOINTMENT_DENTIST_CALENDAR_LIST_URL, { params }),
  registerSignConsentForm: (data) =>
    httpClient.post(COMPANY_PATIENT_NEED_SIGN_FORM_REGISTER_AND_SIGN_URL, {
      data,
    }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { data })
      : httpClient.post(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { data }),
  toothCondition: {
    addTooth: (params) => httpClient.get(PATIENT_TOOTH_CONDITION_TOOTH_ADD_URL, { params }),
    getLastList: (params) => httpClient.get(PATIENT_TOOTH_CONDITION_LAST_LIST_URL, { params }),
    changeTooth: (params) => httpClient.get(PATIENT_TOOTH_CONDITION_TOOTH_CHANGE_URL, { params }),
    deleteTooth: (params) => httpClient.get(PATIENT_TOOTH_CONDITION_TOOTH_DELETE_URL, { params }),
  },
  insurance: {
    getList: (params) => httpClient.get(PATIENT_INSURANCE_LIST_URL, { params }),
    getItem: (params) => httpClient.get(PATIENT_INSURANCE_ITEM_URL, { params }),
    deleteItem: (params) => httpClient.delete(PATIENT_INSURANCE_ITEM_URL, { params }),
    uploadPhoto: (data) => httpClient.put(PATIENT_INSURANCE_UPLOAD_PHOTO_URL, { data }),
    markAsPrimary: (data) => httpClient.put(PATIENT_INSURANCE_MARK_AS_PRIMARY_URL, { data }),
    saveItem: (data) =>
      data && data.id
        ? httpClient.put(PATIENT_INSURANCE_ITEM_URL, { data })
        : httpClient.post(PATIENT_INSURANCE_ITEM_URL, { data }),
    getFields: {
      getType: (params) => httpClient.get(PATIENT_INSURANCE_TYPE_CHOOSE_URL, { params }),
      getBroker: (params) => httpClient.get(PATIENT_INSURANCE_BROKERS_CHOOSE_URL, { params }),
      getCompany: (params) => httpClient.get(PATIENT_INSURANCE_COMPANY_CHOOSE_URL, { params }),
      getRelation: (params) => httpClient.get(PATIENT_INSURANCE_RELATION_CHOOSE_URL, { params }),
      getCompanyPlan: (params) =>
        httpClient.get(PATIENT_INSURANCE_COMPANY_PLAN_CHOOSE_URL, { params }),
      getSeachByCardNumber: (params) =>
        httpClient.get(PATIENT_INSURANCE_SEARCH_CARD_NUMBER_URL, { params }),
    },
  },
  note: {
    editItem: (data) => httpClient.put(PATIENT_PROFILE_NOTE_ITEM_URL, { data }),
    saveItem: (data) => httpClient.post(PATIENT_PROFILE_NOTE_ITEM_URL, { data }),
    getList: (params) => httpClient.get(PATIENT_PROFILE_NOTE_LIST_URL, { params }),
    deleteItem: (params) => httpClient.delete(PATIENT_PROFILE_NOTE_ITEM_URL, { params }),
  },
  claims: {
    getList: (params) => httpClient.get(PATIENT_CLAIMS_URL, { params }),
    getStatusList: () => httpClient.get(PATIENT_CLAIMS_STATUS_URL),
    getDocTypeList: () => httpClient.get(PATIENT_CLAIMS_DOC_TYPE_URL),
    printClaim: (data) => httpClient.post(PATIENT_PRINT_CLAIM_URL, { data }),
  },
  approvals: {
    getList: (params) => httpClient.get(PATIENT_APPROVALS_LIST_URL, { params }),
    getItem: (params) => httpClient.get(PATIENT_APPROVALS_ITEM_URL, { params }),
    getStatusList: () => httpClient.get(PATIENT_APPROVALS_STATUS_URL),
    getDocTypeList: () => httpClient.get(PATIENT_APPROVALS_DOC_TYPE_URL),
    getClaimedStatusList: () => httpClient.get(PATIENT_CLAIMED_STATUS_URL),
    saveNote: (data) =>
      httpClient.put(
        data.claimRequestTypeCode === "CLAIM_REQUEST"
          ? PATIENT_CLAIMS_NOTE_URL
          : PATIENT_APPROVALS_NOTE_URL,
        { data },
      ),
  },
  prescription: {
    saveItem: (data) => httpClient.post(PATIENT_PRESCRIPTION_ITEM_URL, { data }),
    getList: (params) => httpClient.get(PATIENT_PRESCRIPTION_LIST_URL, { params }),
    deleteItem: (params) => httpClient.delete(PATIENT_PRESCRIPTION_ITEM_URL, { params }),
    getFormData: (params) => httpClient.get(PATIENT_PRESCRIPTION_FORM_DATA_URL, { params }),
    sendToEmail: (params) => httpClient.get(PATIENT_PRESCRIPTION_SEND_TO_EMAIL_URL, { params }),
    getTemplatesGroup: (params) => httpClient.get(PATIENT_PRESCRIPTION_GROUP_LIST_URL, { params }),
    getCategoryList: (params) =>
      httpClient.get(PATIENT_PRESCRIPTION_CATEGORY_CHOOSE_URL, { params }),
    getTemplates: (params) =>
      httpClient.get(PATIENT_PRESCRIPTION_TEMPLATE_TYPE_AHEAD_URL, { params }),
    getTemplateDetails: (params) =>
      httpClient.get(PATIENT_PRESCRIPTION_TEMPLATE_ITEM_URL, { params }),
    getTemplatesWithGroup: (params) =>
      httpClient.get(PATIENT_PRESCRIPTION_TEMPLATE_LIST_URL, { params }),
  },
  alerts: {
    saveMedicalAlerts: (data) => httpClient.put(PATIENT_MEDICAL_ALERTS_SAVE_URL, { data }),
    getMedicalAlerts: (params) => httpClient.get(PATIENT_MEDICAL_ALERTS_URL, { params }),
    getMedicalAlertsCategories: (params) =>
      httpClient.get(PATIENT_MEDICAL_ALERTS_CATEGORY_URL, { params }),
    saveMedicationAlerts: (data) => httpClient.put(PATIENT_MEDICATION_ALERTS_URL, { data }),
    getMedicationAlerts: (params) => httpClient.get(PATIENT_MEDICATION_ALERTS_URL, { params }),
    getMedicalAlertsSubCategories: (params) =>
      httpClient.get(PATIENT_MEDICAL_ALERTS_SUB_CATEGORIES_URL, { params }),
  },
  members: {
    saveList: (data) => httpClient.post(PATIENT_MEMBER_LIST_URL, { data }),
    getList: (params) => httpClient.get(PATIENT_MEMBER_LIST_URL, { params }),
  },
  signInForms: {
    saveItem: (data) => httpClient.post(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { data }),
    deleteItem: (params) => httpClient.delete(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { params }),
    getList: (params) => httpClient.get(COMPANY_PATIENT_NEED_SIGN_FORM_LIST_URL, { params }),
    getItem: (params) => httpClient.get(COMPANY_PATIENT_NEED_SIGN_FORM_ITEM_URL, { params }),
    getFilledItem: (params) =>
      httpClient.get(COMPANY_PATIENT_NEED_SIGN_FORM_FILLED_ITEM_URL, {
        params,
      }),
  },
  postOperationInstruction: {
    saveItem: (data) => httpClient.post(PATIENT_POST_OP_ITEM_URL, { data }),
    getTemplate: (params) => httpClient.get(PATIENT_POST_OP_TEMPLATE_ITEM_URL, { params }),
    sendToEmail: (params) => httpClient.get(PATIENT_POST_OP_SEND_TO_EMAIL_URL, { params }),
  },
  reminder: {
    saveReminder: (data) => httpClient.put(PATIENT_REMINDER_URL, { data }),
    getReminder: (params) => httpClient.get(PATIENT_REMINDER_URL, { params }),
  },
};

export default PatientApi;
