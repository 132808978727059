import React from "react";

export default function CalendarWeekButtons(props) {
  return (
    <div className="calendar-week-buttons">
      <button
        className="btn btn-link"
        onClick={() => props.changeCalendarWeek("prev")}
        type="button"
      >
        ❮
      </button>
      WEEK
      <button
        className="btn btn-link"
        onClick={() => props.changeCalendarWeek("next")}
        type="button"
      >
        ❯
      </button>
    </div>
  );
}
