import createReducer from "../helpers/createReducer";
import {
  OPEN_CHART,
  SET_INITIAL_VALUES,
  SET_VISIT_TYPE,
  GET_LIST_SUCCESS,
  INIT_LIST_SUCCESS,
  SET_REFERRAL_MODAL_VISIBLE,
  GET_LIST_START,
  GET_LIST_ERROR,
  INIT_LIST_START,
  INIT_LIST_ERROR,
  INIT_SIDE_REFERRAL_NEXT_VISIT,
  POP_REFERRAL_NEXT_VISIT_STACK,
  SET_REFERRAL_ACTIVE_TAB,
  SKIP_NEXT_VISIT_START,
  SKIP_NEXT_VISIT_SUCCESS,
  SET_SKIP_NEXT_VISIT_STATUS,
  SET_REFERRAL_NEXT_VISIT_LIST_ACTIVE_TAB,
  OPEN_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT,
  CLOSE_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT,
  GET_REFERRED_TO_DOCTORS_LIST_SUCCESS,
} from "../actions/referralActions";
import { defaultStackItem } from "../constants/chart-sidebar/postOpStackCodes";
import moment from "moment";

const allDoctors = { value: -1, label: "All doctors" };
const allCategory = { value: "", label: "Please Select" };
const defaultState = {
  stack: [defaultStackItem],
  activeTab: {
    code: "REFERRAL",
    title: "All",
  },
  initialValues: {
    referralType: { value: "NEXT_VISIT", label: "Next visit" },
    referredFrom: null,
    referredTo: null,
    dentist: null,
    patient: null,
    dentalPoint: null,
    note: "",
    duration: null,
    category: null,
    date: null,
    clinicId: null,
  },
  searchInitialValues: {
    referralTypeCode: { value: -1, label: "Any" },
    referredToId: allDoctors,
    referredFromId: allDoctors,
    doctorIds: allDoctors,
    categoryIds: allCategory,
    patientChartNumber: "",
    showCanceled: false,
    periodStart: moment(),
    periodEnd: moment(),
  },
  openChart: false,
  referralModalVisible: false,
  visitType: null,
  hideAlerts: false,
  list: [],
  referralListTotalCount: 0,
  loading: false,
  referralNextVisitListActiveTab: "referralNextVisit",
  appointment: {},
  patientInfoSideBarVisible: false,
  canSkip: false,
  referralDoctors: [],
};

const reducers = {
  [GET_REFERRED_TO_DOCTORS_LIST_SUCCESS](state, { data }) {
    const doctors = (data || []).filter((i) => i.doctor);

    return {
      ...state,
      referralDoctors: doctors,
    };
  },
  [OPEN_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT](state, { appointment }) {
    return {
      ...state,
      appointment,
      patientInfoSideBarVisible: true,
    };
  },
  [CLOSE_PATIENT_INFO_SIDE_BAR_REFERRAL_NEXT_VISIT](state) {
    return {
      ...state,
      patientInfoSideBarVisible: false,
    };
  },
  [SET_REFERRAL_NEXT_VISIT_LIST_ACTIVE_TAB](state, { activeTab }) {
    return {
      ...state,
      referralNextVisitListActiveTab: activeTab,
    };
  },
  [INIT_SIDE_REFERRAL_NEXT_VISIT](state) {
    return {
      ...state,
      searchValue: "",
      stack: [defaultStackItem],
    };
  },
  [SKIP_NEXT_VISIT_START](state) {
    return {
      ...state,
      canSkip: false,
    };
  },
  [SET_SKIP_NEXT_VISIT_STATUS](state, { canSkip }) {
    return {
      ...state,
      canSkip,
    };
  },
  [POP_REFERRAL_NEXT_VISIT_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [SET_REFERRAL_ACTIVE_TAB](state, { activeTab }) {
    return {
      ...state,
      activeTab,
    };
  },
  [SET_INITIAL_VALUES](state, { value }) {
    return {
      ...state,
      searchInitialValues: { ...state.searchInitialValues, ...value },
    };
  },
  [SET_REFERRAL_MODAL_VISIBLE](state, data) {
    return {
      ...state,
      referralModalVisible: data.state,
    };
  },
  [OPEN_CHART](state, { bool }) {
    return {
      ...state,
      openChart: bool,
      hideAlerts: bool,
    };
  },
  [SET_VISIT_TYPE](state, { visitType }) {
    return {
      ...state,
      visitType,
    };
  },
  [INIT_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      list: data.list || [],
      referralListTotalCount: data.totalCount || 0,
      loading: false,
    };
  },
  [INIT_LIST_START](state) {
    return {
      ...state,
      list: [],
      referralListTotalCount: 0,
      loading: true,
    };
  },
  [INIT_LIST_ERROR](state) {
    return {
      ...state,
      loading: false,
    };
  },
  [GET_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      list: request.start ? state.list.concat(data.list) : data.list || [],
      referralListTotalCount: data.totalCount || 0,
      loading: false,
    };
  },
  [GET_LIST_START](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [GET_LIST_ERROR](state) {
    return {
      ...state,
      loading: false,
    };
  },
};

export default createReducer(defaultState, reducers);
