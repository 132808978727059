import { httpClient } from "./BaseApi";

const VITAL_LIST_URL = "api/vital/list.json";
const VITAL_ITEM_URL = "api/vital/item.json";
const VITAL_EMPTY_URL = "api/vital/empty.json";

const VITAL_ITEM_CHANGE_DATE_URL = "api/vital/item/change-date.json";

const VITAL_TEMPLATE_LIST_URL = "api/vital/template/list.json";

const GoalApi = {
  getItem: (params) => httpClient.get(VITAL_ITEM_URL, { params }),
  getEmpty: (params) => httpClient.get(VITAL_EMPTY_URL, { params }),
  getOwnedList: (params) => httpClient.get(VITAL_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(VITAL_ITEM_URL, { params }),
  changeCreatedDate: (data) => httpClient.put(VITAL_ITEM_CHANGE_DATE_URL, { data }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(VITAL_ITEM_URL, { data })
      : httpClient.post(VITAL_ITEM_URL, { data }),
  template: {
    getList: (params) => httpClient.get(VITAL_TEMPLATE_LIST_URL, { params }),
  },
};

// Test

export default GoalApi;
