import React from "react";
import { connect } from "react-redux";
import moment from "moment/moment";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";

const enhancer = connect(({ chart, transaction: { transaction } }) => ({ chart, transaction }));
const AdvancedPaymentList = (props) => {
  const {
    transaction: { list, totalCount },
    selectedList,
    setSelectedList,
  } = props;
  const rows = (list || [])
    .filter((item) => item?.type?.code !== "DIAGNOSIS")
    .map((item, index) => {
      return (
        <div className="select-treatment-list-row" key={index}>
          <div className="item-posted-by">{item.createdDentist.name}</div>
          <div className="item-date-posted">
            {moment(item.createdDate).format(DATE_FORMAT_LEGACY)}
          </div>
          <div className="item-treatment-code">{item.treatmentItem.code}</div>
          <div className="item-date-posted">{item.patientShare}</div>
          {/*patientShare*/}
          <div className="item-checkbox">
            <input
              className="scaled-2"
              type="checkbox"
              disabled={totalCount === 0}
              onChange={() => {
                let newList = [];
                if (selectedList.find((x) => x.key === item.key)) {
                  newList = selectedList.filter((x) => x.key !== item.key);
                } else {
                  newList = [...selectedList, item];
                }
                setSelectedList(newList);
              }}
              checked={selectedList[item.key]}
            />
          </div>
        </div>
      );
    });
  return (
    <div className="select-treatment-list-table">
      <div className="select-treatment-list-header">
        <div className="item-posted-by">Posted by</div>
        <div className="item-date-posted">Posted date</div>
        <div className="item-treatment-code">Code</div>
        <div className="item-date-posted">Patient share</div>
        <div className="item-checkbox">
          <input
            className="scaled-2"
            type="checkbox"
            disabled={totalCount === 0}
            // onChange={() => setSelectedList(list.length === selectedList.length ? [] : list)}
            checked={totalCount === selectedList.length}
            readOnly={true}
          />
        </div>
      </div>
      <div className="select-treatment-list-body">{rows}</div>
    </div>
  );
};

export default enhancer(AdvancedPaymentList);
