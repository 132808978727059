import React, { useEffect, useState } from "react";
import FilledImage from "../../../../widgets/FilledImage";
import callListImage from "../../../../../assets/images/call-list/check_2x.png";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { useDispatch } from "react-redux";

const FormCheckboxList = ({ form, setForm, questionId, editMode, visible, setOverlay }) => {
  const item = form.questions.find((item) => item.id === questionId);
  const [otherValue, setOtherValue] = useState("");
  const [otherValueSelected, setOtherValueSelected] = useState(false);
  const [show, setShow] = useState(visible || false);
  const [shouldRender, setShouldRender] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.id) {
      item.options.forEach((answer) => {
        if (answer.otherValue) {
          setOtherValue(answer.otherValue);
          setOtherValueSelected(!!answer.otherValue);
        }
      });
    }
  }, [item]);

  const onSave = (selectedId) => {
    setShouldRender(!shouldRender);
    dispatch(dataToFillHandler(selectedId));
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.options = question.options.map((answer) => {
            if (answer.id === selectedId) answer.selected = !answer.selected;
            return answer;
          });
        }
        return question;
      }),
    });
  };

  const onUpdateForm = () => {
    setOverlay({ visible: false });
    setShow(false);
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.options = question.options.map((answer) => {
            if (answer.other) {
              answer.otherValue = otherValue;
              if (otherValue.trim().length > 0) dispatch(dataToFillHandler(otherValue));
            }
            return answer;
          });
        }
        return question;
      }),
    });
  };

  const $values = (item?.options || []).map((answer, index) => {
    return (
      <button
        className={answer.selected ? "btn btn-primary" : "btn btn-default"}
        style={{ whiteSpace: "wrap", padding: "4px", fontSize: "13px" }}
        key={index}
        onClick={() => {
          if (answer.other) {
            setOtherValue("");
            setOtherValueSelected(!otherValueSelected);
          }
          onSave(answer.id);
        }}
      >
        {answer.title}
      </button>
    );
  });

  if (!show) {
    const selected = (item?.options || []).find((i) => i.selected);
    return !selected ? (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {"N/A"}
      </p>
    ) : (
      <>
        {(item?.options || []).map((item, index) => {
          if (item.selected) {
            return (
              <p
                key={index}
                className="medical-fill-in-form-answer"
                onClick={() => setShow(editMode)}
              >
                {item.otherValue || item.title}
              </p>
            );
          }
          return null;
        })}
      </>
    );
  }

  if (item?.options)
    return (
      <div className="d-flex flex-column">
        <div className="fill-in-forms-list-group-container w-100">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "8px",
              listStyleType: "none",
              borderBottom: "1px solid #eeeeee",
            }}
            className="pb-2 mb-2"
          >
            {$values}
            {otherValueSelected && (
              <div>
                <textarea
                  rows={5}
                  maxLength={1000}
                  className="form-control"
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                  value={otherValue}
                  onChange={(e) => setOtherValue(e.target.value)}
                />
                <span>{otherValue.length}/1000</span>
              </div>
            )}
          </div>
        </div>
        <button className="btn btn-primary" onClick={onUpdateForm}>
          Ok
        </button>
      </div>
    );
  return null;
};

export default FormCheckboxList;
