import React, { useEffect, useState } from "react";
import { Modal } from "../../ui/Modal";
import { connect, useDispatch } from "react-redux";
import {
  setAdvancedPaymentModalVisible,
  setAdvancedPaymentContent,
  saveAdvancedPaymentItem,
  initAdvancedPaymentList,
} from "../../../actions/chartActions";
import Show from "../../widgets/Show";
import AdvancedPaymentList from "./AdvancedPaymentList";
import AdvancedPaymentForm from "./AdvancedPaymentForm";
import { useFormik } from "formik";
import { withRouter } from "react-router";
import { parseQuery } from "../../../utils/UrlUtils";
import { showMessage } from "../../../actions/loaderActions";

const enhancer = connect(({ chart, session: { clinic, member } }) => ({ chart, clinic, member }));
const AdvancedPayment = (props) => {
  const [showList, setShowList] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const { advancedPaymentModalVisible, advancedPaymentContentType } = props.chart;
  const dispatch = useDispatch();
  const query = parseQuery(props.location.search);
  const { patientKey } = query;
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      patientKey: patientKey,
      initiatorId: { value: props.member.id },
    },
    onSubmit: (values) => {
      const transfer = {
        ...values,
        amount: Number(values.amount),
        initiatorId: values.initiatorId?.value,
      };
      dispatch(saveAdvancedPaymentItem(transfer))
        .then(
          () => {
            form.resetForm();
            dispatch(showMessage("Wallet request has been created"));
            dispatch(setAdvancedPaymentModalVisible(false));
            dispatch(initAdvancedPaymentList());
          },
          // dispatch(setAdvancedPaymentContent("list")),
        )
        .catch((error) => dispatch(showMessage(error)));
    },
  });

  useEffect(() => {
    if (!advancedPaymentModalVisible) {
      form.setFieldValue("amount", "");
      setSelectedList([]);
      setShowList(false);
    }
  }, [advancedPaymentModalVisible]);

  useEffect(() => {
    if (selectedList.length) {
      let result = 0;
      selectedList.forEach((item) => {
        if (item.patientShare) result = result + item.patientShare;
      });
      form.setFieldValue("amount", result);
    }
  }, [selectedList]);
  return (
    <Show if={advancedPaymentModalVisible}>
      <Modal
        size={showList ? "xl" : "sm"}
        show={advancedPaymentModalVisible}
        title={"Advanced payments"}
        // className="select-treatment-to-complete-modal"
        onHide={() => dispatch(setAdvancedPaymentModalVisible())}
        actions={
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => dispatch(setAdvancedPaymentModalVisible(false))}
            >
              Close
            </button>
            <Show if={advancedPaymentContentType === "list"}>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => dispatch(setAdvancedPaymentContent("form"))}
              >
                Add new
              </button>
            </Show>
            <Show if={advancedPaymentContentType === "form"}>
              <button type="submit" className="btn btn-success" onClick={() => form.handleSubmit()}>
                Save
              </button>
            </Show>
          </div>
        }
      >
        {advancedPaymentContentType === "form" && (
          <AdvancedPaymentForm form={form} showList={showList} setShowList={setShowList} />
        )}
        {showList && (
          <AdvancedPaymentList selectedList={selectedList} setSelectedList={setSelectedList} />
        )}
      </Modal>
    </Show>
  );
};

export default enhancer(withRouter(AdvancedPayment));
