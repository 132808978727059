import React from "react";

export default function CalendarTimes({ timeArray, calendarMinuteIncrements }) {
  const rowView = localStorage.getItem("rowView");

  const $times = timeArray.map((time, index) => (
    <div
      className={`calendar-time-item ${rowView}`}
      key={index}
      style={calendarMinuteIncrements === 30 ? { height: "80px" } : {}}
    >
      {time.name}
    </div>
  ));

  return (
    <div className="calendar-time">
      <div className="calendar-time-wrap">{$times}</div>
    </div>
  );
}
