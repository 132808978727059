import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Show from "../widgets/Show";
import FilledImage from "../widgets/FilledImage";

import patientViewModalTypes from "../../constants/patient/patientViewModalTypes";

import bindActions from "../../helpers/bindActions";
import * as patientViewActions from "../../actions/patientViewActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import editImage from "../../assets/images/diagnosis_edit-icon.png";
import deleteImage from "../../assets/images/delete.png";
import { Sidebar } from "../sidebar/Sidebar";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { PatientViewModalAddForm } from "./PatientViewModalAddForm";

const enhancer = connect(null, bindActions({ patientViewActions, simpleModalActions }));
class PatientViewModal extends React.Component {
  addNew = (values, helpers) => {
    this.props.patientViewActions.addNote(values, this.props.patientKey);
    helpers.resetForm();
  };

  openNoteEdit = (item) => {
    this.props.patientViewActions.hideModal();
    this.props.patientViewActions.openNoteEdit(item);
  };

  openNoteDelete = (id) => {
    return () => {
      const footer = (
        <div style={{ margin: "-10px -15px" }}>
          <div className="btn-group btn-group-justified">
            <div className="btn-group" onClick={this.props.simpleModalActions.closeSimpleModal}>
              <button type="button" className="btn btn-link">
                Cancel
              </button>
            </div>
            <div
              className="btn-group"
              onClick={() => {
                this.props.patientViewActions.deleteNote(id, this.props.patientKey);
                this.props.simpleModalActions.closeSimpleModal();
              }}
            >
              <button type="button" className="btn btn-link">
                Ok
              </button>
            </div>
          </div>
        </div>
      );

      this.props.simpleModalActions.openSimpleModal({
        body: "Delete this entry? This action cannot be undone.",
        footer,
      });
    };
  };

  render() {
    const { modalType, modalVisible, notes = [], insuranceNotes, withoutForm } = this.props;
    let list = [];
    let $title;

    switch (modalType) {
      case patientViewModalTypes.note:
        list = notes;
        $title = "Reception Notes";
        break;
      case patientViewModalTypes.insuranceNote:
        list = insuranceNotes;
        $title = "Insurance Notes";
        break;
    }

    const $body = list.map((item, index) => (
      <div key={index} className="note-item">
        <div className="note-item-box">
          <strong className="note-item-title">{item.name}</strong>
          <div className="note-item-body" style={{ whiteSpace: "normal" }}>
            {item.body || item.note || " "}
          </div>
          <div className="note-item-footer" style={{ flexDirection: "column" }}>
            <div style={{ display: "Flex" }}>
              <div className="owner" style={{ flex: " 1 1 0%" }}>
                Modifier: {_.get(item, "modifiedMember.name")}
              </div>
              <div className="date">{moment(item.modifiedDate).format("DD/MM/YYYY hh:mm")}</div>
            </div>
            <div style={{ display: "Flex" }}>
              <div className="owner" style={{ flex: " 1 1 0%" }}>
                Creator: {_.get(item, "owner.name")}
              </div>
              <div className="date">{moment(item.createdDate).format("DD/MM/YYYY hh:mm")}</div>
            </div>
          </div>
        </div>
        <div className="note-item-actions">
          <div className="edit cursor-pointer" onClick={() => this.openNoteEdit(item)}>
            <FilledImage src={editImage} color="white" />
          </div>
          <div className="delete cursor-pointer" onClick={this.openNoteDelete(item.id)}>
            <FilledImage src={deleteImage} color="white" className="zoom-2x" />
          </div>
        </div>
      </div>
    ));

    return (
      <Sidebar
        bodyProps={{ style: { padding: 0 } }}
        show={modalVisible}
        dialogClassName="chart-side-bar"
        onHide={this.props.patientViewActions.hideModal}
      >
        <div className="chart-clinical-note patient-view-note-modal">
          <SidebarHeader onBackClick={this.props.patientViewActions.hideModal} title={$title} />

          <div className="chart-side-body">
            <Show if={!withoutForm}>
              <PatientViewModalAddForm
                onSubmit={this.addNew}
                submitName="Add"
                initialValues={{ name: "", note: "" }}
              />
            </Show>
            <div className="body">
              <div className="note-item-wrapper">{$body}</div>
            </div>
          </div>
        </div>
      </Sidebar>
    );
  }
}

export default enhancer(PatientViewModal);
