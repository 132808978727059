// @ts-ignore
import _ from "lodash";
import { fromMomentToHHMMInMillisecond, getCompanyStartOfDay } from "./DateUtils";
import moment from "moment/moment";
interface CreatePatientValuesProps {
  readonly values: any;
  readonly patientRegister: any;
  readonly tempId2: number;
  readonly professionState: any;
}
export function createPatientValues({
  values,
  patientRegister,
  tempId2,
  professionState,
}: CreatePatientValuesProps) {
  const {
    lastName,
    firstName,
    middleName,
    nationality,
    gender,
    smsLanguage,
    birthDate,
    maritalStatus,
    studentStatus,
    vipPatient,
    weight,
    weightUnit,
    workplace,
    occupation,
    referral,
    postCode,
    street,
    street2,
    country,
    state,
    city,
    homePhone,
    workPhone,
    mobilePhone,
    socialSecurity,
    driversLicence,
    socialInsurance,
    passport,
    nationalId,
    nationalIdNotAvailable,
    referralType,
    referralTypeDescription,
    internalFileNumber,
    additionalInformation,
    patientKey,
    customFieldSettings,
    profissionType,
    title,
    relationshipType,
    providerRoleType,
    rolePerson,
    roleBeginDate,
    roleEndDate,
  } = values;

  const email = values?.email || "";
  const findProfession =
    profissionType &&
    professionState.patientRegister.profession.find((item) => item.id === profissionType);
  const homeNumber = _.get(homePhone, "number");
  const homeTownCode = _.get(homePhone, "townCode");
  const homeCountryCode = _.get(homePhone, "countryCode.value");

  const workNumber = _.get(workPhone, "number");
  const workTownCode = _.get(workPhone, "townCode");
  const workCountryCode = _.get(workPhone, "countryCode.value");

  const mobileNumber = _.get(mobilePhone, "number");
  const mobileTownCode = _.get(mobilePhone, "townCode");
  const mobileCountryCode = _.get(mobilePhone, "countryCode.value");

  if (!patientRegister.personalDetails.patient.emergencyContacts) {
    patientRegister.personalDetails.patient.emergencyContacts = [{}];
  }

  patientRegister.personalDetails.patient.emergencyContacts.forEach((item, index) => {
    patientRegister.personalDetails.patient.emergencyContacts[index] = Object.assign(
      patientRegister.personalDetails.patient.emergencyContacts[index],
      {
        lastName: values[`emergencyLastName${index ? 2 : ""}`],
        firstName: values[`emergencyFirstName${index ? 2 : ""}`],
        relationship: values[`emergencyRelationship${index ? 2 : ""}`],
        homePhoneNumber: values[`emergencyHomePhone${index ? 2 : ""}`],
        mobilePhoneNumber: values[`emergencyMobilePhone${index ? 2 : ""}`],
      },
    );
  });

  const address: any = {
    postcode: postCode,
    line1: street,
    line2: street2,
    country: {
      id: country,
    },
    state: {
      id: state,
    },
  };

  if (_.isNumber(city)) {
    address.town = {
      id: city,
    };
  } else if (city) {
    address.town = {
      name: city.label,
      id: city.value,
    };
  }

  const notTempData: any = {
    lastName,
    firstName,
    middleName,
    nationality: {
      id: nationality,
    },
    gender: patientRegister.gender.find((item) => item.id === gender),
    dateOfBirth: birthDate && birthDate.valueOf(),
    maritalStatus: {
      id: maritalStatus,
    },
    smsLanguage: {
      id: smsLanguage === -1 ? null : smsLanguage,
    },
    student: !!studentStatus,
    vipPatient,
    weight: weight * weightUnit,
    workPlace: workplace,
    occupation,
    referral,
    referralType: {
      id: referralType,
    },
    address,
    homePhoneNumber: `+${homeCountryCode}(${homeTownCode})${homeNumber}`,
    workPhoneNumber: `+${workCountryCode}(${workTownCode})${workNumber}`,
    mobilePhoneNumber: `+${mobileCountryCode}(${mobileTownCode})${mobileNumber}`,
    email,
    documentSocialSecurityNumber: socialSecurity,
    documentDriverLicenseNumber: driversLicence,
    documentSocialInsuranceNumber: socialInsurance,
    documentPasswordNumber: passport,
    documentNationalIdNumber: nationalId,
    nationalIdNotAvailable,
    internalFileNumber,
    additionalInformation,
    companyTempPatientId: tempId2 ? _.toFinite(tempId2) : null,
    customFieldValues: [],
    title: patientRegister.titles.find((item) => item.id === title),
    profissionType: findProfession ? { id: findProfession.id, code: findProfession.code } : null,

    primaryCareProvider: {
      relationshipType: relationshipType
        ? { code: relationshipType.value, name: relationshipType.label }
        : null,
      providerRoleType: providerRoleType
        ? { code: providerRoleType.value, name: providerRoleType.label }
        : null,
      rolePerson: rolePerson ? { id: rolePerson.id, name: rolePerson.label } : null,
    },
  };
  if (roleBeginDate) {
    const currenTime = fromMomentToHHMMInMillisecond(moment());
    const getCompanyDate = getCompanyStartOfDay(roleBeginDate);
    notTempData.primaryCareProvider.roleBeginDate =
      new Date(getCompanyDate).setHours(0, 0, 0) + currenTime;
  }

  if (roleEndDate) {
    const currenTime = fromMomentToHHMMInMillisecond(moment());
    const getCompanyDate = getCompanyStartOfDay(roleEndDate);
    notTempData.primaryCareProvider.roleEndDate =
      new Date(getCompanyDate).setHours(0, 0, 0) + currenTime;
  }

  (customFieldSettings || []).forEach((x) => {
    for (const key in x) {
      const field = x[key];
      notTempData.customFieldValues.push({
        code: field.code,
        patientKey,
        value: field.value,
        companyPatientId: null,
        customFieldSettings: {
          code: field.code,
          name: field.name,
          id: field.id,
          selected: field.selected,
        },
      });
    }
  });

  const transfer = { ...patientRegister.personalDetails.patient, ...notTempData };

  if (referralType === 0) {
    transfer.referralType = {
      code: "REFERRAL_TYPE_OTHER",
      description: referralTypeDescription,
    };
  }

  transfer.signature = patientRegister.signature;

  return transfer;
}

interface CreateTempPatientValuesProps {
  readonly values: any;
  readonly patientRegister: any;
  readonly tempId: number;
  readonly tempId2: number;
}

export function createTempPatientValues({ values, patientRegister }: CreateTempPatientValuesProps) {
  const {
    lastName,
    firstName,
    gender,
    mobilePhone,
    referralType,
    referralTypeDescription,
  } = values;

  const email = values?.email || "";

  const mobileNumber = _.get(mobilePhone, "number");
  const mobileTownCode = _.get(mobilePhone, "townCode");
  const mobileCountryCode = _.get(mobilePhone, "countryCode.value");

  if (!patientRegister.personalDetails.patient.emergencyContacts) {
    patientRegister.personalDetails.patient.emergencyContacts = [{}];
  }

  patientRegister.personalDetails.patient.emergencyContacts.forEach((item, index) => {
    patientRegister.personalDetails.patient.emergencyContacts[index] = Object.assign(
      patientRegister.personalDetails.patient.emergencyContacts[index],
      {
        lastName: values[`emergencyLastName${index ? 2 : ""}`],
        firstName: values[`emergencyFirstName${index ? 2 : ""}`],
        relationship: values[`emergencyRelationship${index ? 2 : ""}`],
        homePhoneNumber: values[`emergencyHomePhone${index ? 2 : ""}`],
        mobilePhoneNumber: values[`emergencyMobilePhone${index ? 2 : ""}`],
      },
    );
  });

  const patientId = _.get(patientRegister, "patientDetails.id");

  const transfer: any = {
    id: patientId,
    lastName,
    firstName,
    gender: patientRegister.gender.find((item) => item.id === gender),
    email,
    mobilePhoneNumber: `+${mobileCountryCode}(${mobileTownCode})${mobileNumber}`,
  };

  if (referralType === 0) {
    transfer.referralType = {
      code: "REFERRAL_TYPE_OTHER",
      description: referralTypeDescription,
    };
  }

  transfer.signature = patientRegister.signature;

  return transfer;
}
