import React from "react";
import FlexBox from "../ui/FlexBox";

export function TerminalCard(props) {
  return (
    <FlexBox flex={true} className={props.className}>
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox flex={true} className="card-content-container">
          <FlexBox direction="column" flex={true} gutter={8}>
            {props.title && (
              <FlexBox className="card-title" justify="center">
                Patient Alerts
              </FlexBox>
            )}

            <FlexBox flex={true}>
              <FlexBox gutter={8} direction="column" flex={true} className="card-content">
                {props.children}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        {props.onActionClick && (
          <FlexBox className="card-view-button-container" direction="column">
            <button
              onClick={props.onActionClick}
              className={`btn btn-lg ${props.actionClassName || "btn-success"}`}
            >
              {props.actionTitle || "View Details"}
            </button>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}
