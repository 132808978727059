import _ from "lodash";
import React, { useEffect } from "react";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactSpinner from "react-spinjs-fix";

import Show from "../widgets/Show";
import bindActions from "../../helpers/bindActions";
import BlockedTimeSlotPanelHeader from "./BlockedTimeSlotPanelHeader";
import * as blockedTimeSlotActions from "../../actions/blockedTimeSlotActions";
import { BlockedTimeSlotDeleteConfirmModal } from "./BlockedTimeSlotDeleteConfirmModal";
import {
  getCompanyFormatDate,
  getCompanyFormatTime,
  getCompanyUTCOffset,
} from "../../helpers/DateUtils";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { useHistory, withRouter } from "react-router";
import EditImage from "../../assets/images/pencil-box.png";
import DeleteImage from "../../assets/images/close-circle.png";
import { useDeleteBlockedTimeSlotItemMutation } from "../../queries/blocked-time-slot/useDeleteBlockedTimeSlotItemMutation";
import { startLoader, stopLoader } from "../../actions/loaderActions";

const enhancer = connect(null, bindActions({ blockedTimeSlotActions }));

const BlockedTimeSlotViewMode = ({ blockedTimeSlotActions, blockedTimeSlot }) => {
  const deleteItemMutation = useDeleteBlockedTimeSlotItemMutation();
  const deletedId = useSelector((state) => state.blockedTimeSlot.blockedDeleteId);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    blockedList,
    blockedDoctors,
    blockedFilterKey,
    blockedListFetching,
    blockedConfirmModalVisible,
  } = blockedTimeSlot;

  useEffect(() => {
    blockedTimeSlotActions.blockedTimeSlotGetList();
  }, []);

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      blockedTimeSlotActions.blockedTimeSlotLoadList();
    }
  };
  let list;

  if (!_.isEmpty(blockedList)) {
    list = blockedList.map((item, index) => {
      const startDate = getCompanyFormatDate(item.startDate - getCompanyUTCOffset());
      const endDate = getCompanyFormatDate(item.endDate - getCompanyUTCOffset());

      const startTime = getCompanyFormatTime(
        item.startDate + item.startTime - getCompanyUTCOffset(),
      );
      const endTime = getCompanyFormatTime(
        item.endDate + item.endTime + 1000 - getCompanyUTCOffset(),
      );

      const doctors = (item.members || []).map((item) => item.name).join(", ");
      const points = (item.points || []).map((item) => item.name).join(", ");
      const days = (item.weekDays || []).map((item) => item.name).join(", ");

      return (
        <div key={index} className="blocked-time-slot-table-body-row">
          <div className="col-name">{item.name}</div>
          <div className="col-doctors">{doctors}</div>
          <div className="col-doctors">{points}</div>
          <div className="col-reason">{item.reason}</div>
          <div className="col-week-days">{days}</div>
          <div className="col-time">{startTime + " - " + endTime}</div>
          <div className="col-date">{startDate + " - " + endDate}</div>
          <div className="col-action text-center">
            <div className="btn-group">
              <button
                onClick={() => {
                  history.push(
                    createUrl(Routes.DashboardBlockedTimeSlotItem, {
                      query: {
                        id: item.id,
                      },
                    }),
                  );
                }}
                className="btn btn-info btn-edit-time-slot"
              >
                <img src={EditImage} className="img-responsive" alt="" />
              </button>
              <button
                onClick={() => {
                  blockedTimeSlotActions.blockedTimeSlotConfirmModalShow(item.id);
                }}
                className="btn btn-danger btn-delete-time-slot"
              >
                <img src={DeleteImage} className="img-responsive" alt="" />
              </button>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="blocked-time-slot-panel blocked-time-slot-main-panel">
      <BlockedTimeSlotPanelHeader
        initialValues={{
          startDate: moment(),
          endDate: moment(),
        }}
        blockedDoctors={blockedDoctors}
        blockedFilterKey={blockedFilterKey}
      />
      <div className="blocked-time-slot-table">
        <div className="blocked-time-slot-table-header b-b">
          <div className="col-name">Name</div>
          <div className="col-doctors">Doctors</div>
          <div className="col-doctors">Rooms</div>
          <div className="col-reason">Reason</div>
          <div className="col-week-days">Week Days</div>
          <div className="col-time">Time</div>
          <div className="col-date">Date</div>
          <div className="col-action text-center">Action</div>
        </div>
        <div className="blocked-time-slot-table-body" onScroll={loadList}>
          {list}
          <Show if={blockedListFetching}>
            <ReactSpinner />
          </Show>
        </div>
      </div>
      <BlockedTimeSlotDeleteConfirmModal
        show={blockedConfirmModalVisible}
        isRedirect={false}
        onClose={() => blockedTimeSlotActions.blockedTimeSlotConfirmModalHide()}
        onDeleteClick={() => {
          dispatch(startLoader());
          deleteItemMutation
            .mutateAsync({ id: deletedId })
            .then(() => {
              dispatch(stopLoader());
              blockedTimeSlotActions.blockedTimeSlotConfirmModalHide();
              blockedTimeSlotActions.blockedTimeSlotGetList();
              // history.replace(Routes.DashboardCalendar);
            })
            .catch((e) => dispatch(stopLoader(e)));
        }}
      />
    </div>
  );
};

export default withRouter(enhancer(BlockedTimeSlotViewMode));
