import cx from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { Button, ButtonType } from "../ui/Button";
import MemberImage from "../widgets/MemberImage";
import { SwitchersProps } from "../../dto/AppointmentDTO";
import { AppointmentPatientChartButtons } from "./AppointmentPatientChartButtons";
import { AppointmentPatientViewModalWrapper } from "./AppointmentPatientViewModalWrapper";
import { AppointmentPlannedModalWrapper } from "../appointment-form/AppointmentPlannedModalWrapper";
import {
  AppointmentPatientControlButtons,
  AppointmentPatientControlButtonsProps,
} from "./AppointmentPatientControlButtons";
import { ViewEligibilityButtonWrapper } from "../eligibility/ViewEligibilityButtonWrapper";
import { ViewEligibilityModalWrapper } from "../eligibility/ViewEligibilityModalWrapper";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { appointmentEligibilityDetailsSelector } from "../../reducers/appointmentReducer";
import Utils from "../../helpers/Utils";
import { getCompanyFormatDate } from "../../helpers/DateUtils";
import { Modal } from "../ui/Modal";
import Show from "../widgets/Show";

interface StatusProps {
  readonly id: number;
  readonly eligible: boolean | null;
}

interface Props
  extends Omit<
    AppointmentPatientControlButtonsProps,
    "isTemporary" | "className" | "onReceptionNotesClick" | "onPlannedItemsClick"
  > {
  readonly appointment: any;
  readonly elements: any;
  readonly isPlanned: boolean;
  readonly patient: PatientProps;
  readonly canViewContacts: boolean;
  readonly eligibilityIdPayer: string;
  readonly onShowEligibilityDetails: () => void;
  readonly onShowInsuranceForm: () => void;
  readonly setEligibilityIdPayer: (value: string) => void;
  readonly updateAppointmentEligibilityStatus: (status: StatusProps) => void;

  readonly switchers: SwitchersProps;

  readonly onProfileClick: () => void;
  readonly onInsuranceClick: () => void;

  readonly onSelectPrice: (id: number) => void;
  readonly onTogglePrice: (state: boolean) => void;
  readonly checkEligibilityVisible: boolean;
  readonly eligibilityStatus: boolean | undefined;
  readonly setEligibilityStatus: (value: boolean | undefined) => void;
  readonly setPatientLabelModalVisible: (boolean) => void;
  readonly patientLabelModalVisible: boolean | undefined;
  readonly patientLabel: string;
  readonly chartBillingBalanceValue: any;
}

export function AppointmentPatientView({
  elements,
  patient,
  switchers,
  isPlanned,
  appointment,
  onTogglePrice,
  onSelectPrice,
  onProfileClick,
  canViewContacts,
  onInsuranceClick,
  eligibilityIdPayer,
  setEligibilityIdPayer,
  onShowEligibilityDetails,
  checkEligibilityVisible,
  updateAppointmentEligibilityStatus,
  eligibilityStatus,
  setEligibilityStatus,
  setPatientLabelModalVisible,
  patientLabelModalVisible,
  patientLabel,
  chartBillingBalanceValue,
  onShowInsuranceForm,
  ...props
}: Props) {
  const eligibilityDetails = !checkEligibilityVisible;
  //     useShallowEqualSelector(
  //   appointmentEligibilityDetailsSelector(appointment?.id) as any,
  // );
  const isTemporary = !patient?.patientKey;

  const [showPlanned, setShowPlanned] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showViewEligibility, setShowViewEligibility] = useState(false);

  if (!patient) {
    return null;
  }

  const name = [patient.firstName, patient.middleName, patient.lastName].filter(Boolean).join(" ");
  const isTitleEmpty = Object.values(patient.title || {}).filter(Boolean).length === 0;
  const _title = isTitleEmpty ? name : `${patient.title.name} ${name}`;

  const mobilePhoneNumber =
    (_.get(patient, "mobilePhoneNumber") &&
      !patient.mobilePhoneNumber.includes("undefined") &&
      patient.mobilePhoneNumber.length >= 9 &&
      patient.mobilePhoneNumber) ||
    "";
  const homePhoneNumber =
    (_.get(patient, "homePhoneNumber") &&
      !patient.homePhoneNumber.includes("undefined") &&
      patient.homePhoneNumber.length >= 9 &&
      patient.homePhoneNumber) ||
    "";
  const workPhoneNumber =
    (_.get(patient, "workPhoneNumber") &&
      !patient.workPhoneNumber.includes("undefined") &&
      patient.workPhoneNumber.length >= 9 &&
      patient.workPhoneNumber) ||
    "";

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className="d-flex flex-column pr-3 pl-3">
      <Show if={appointment?.patient?.vipPatient}>
        <div className="outstanding-balance">
          <b className="font-weight-bold">VIP</b>
        </div>
      </Show>
      <h3 className="text-center">{_title}</h3>

      <div className="d-flex">
        <MemberImage
          photoUrl={patient.photo && patient.photo.url}
          style={{ marginRight: "1rem", minWidth: "107px" }}
        />

        <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
          <div className="d-flex">
            <span className="mr-1">Chart No:</span>
            <b className="font-weight-bold">{patient.chartNumber}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">Age:</span>
            <b className="font-weight-bold">{Utils.getAge(patient.dateOfBirth)}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">DOB:</span>
            {/*@ts-ignore*/}
            <b className="font-weight-bold">{getCompanyFormatDate(patient.dateOfBirth)}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">Gender:</span>
            {/*@ts-ignore*/}
            <b className="font-weight-bold">{_.get(patient, "gender.name", "")}</b>
          </div>
          <div className="d-flex">
            <span className="mr-1">Nationality:</span>
            {/*@ts-ignore*/}
            <b className="font-weight-bold">{_.get(patient, "nationality.name", "")}</b>
          </div>
          {canViewContacts && (
            <>
              <div className="d-flex">
                <span
                  className="mr-1"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Mobile:
                </span>
                <b className="font-weight-bold" title={mobilePhoneNumber}>
                  {mobilePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Home:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={homePhoneNumber}
                >
                  {homePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Work:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={workPhoneNumber}
                >
                  {workPhoneNumber}
                </b>
              </div>
            </>
          )}
          <div className="d-flex">
            <span className="mr-1" style={{ width: "70px" }}>
              Email:
            </span>
            <b
              className="font-weight-bold"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              title={patient.email}
            >
              {patient.email}
            </b>
          </div>
        </div>
      </div>
      <div className="pb-3 mb-3" style={{ borderBottom: "3px solid #bec844" }}>
        <h5 className="text-center">
          Wallet Balance :{" "}
          {chartBillingBalanceValue?.walletBalance
            ? Utils.fractionPrice(chartBillingBalanceValue?.walletBalance)
            : "0.00"}
        </h5>
        <div
          className={
            chartBillingBalanceValue?.outstandingBalance > 0 ? "outstanding-balance" : "text-center"
          }
        >
          Outstanding Balance :{" "}
          {chartBillingBalanceValue?.outstandingBalance
            ? Utils.fractionPrice(chartBillingBalanceValue?.outstandingBalance)
            : "0.00"}
        </div>
      </div>

      <AppointmentPatientControlButtons
        className="mb-3"
        isTemporary={isTemporary}
        onPlannedItemsClick={() => setShowPlanned(true)}
        onReceptionNotesClick={() => setShowPatientModal(true)}
        {...props}
      />

      <div className="d-flex flex-column mb-2">
        {elements.chartBtn.visible && !isTemporary && (
          <AppointmentPatientChartButtons
            className="mb-2"
            isPlanning={isPlanned}
            showJustView={elements.popup.justViewBtn.visible}
            onJustViewClick={elements.popup.justViewBtn.handler}
            showStartVisit={elements.popup.startVisitBtn.visible}
            onStartVisitClick={elements.popup.startVisitBtn.handler}
            showContinueVisit={elements.popup.continueVisitBtn.visible}
            continueVisitButtonValue={elements.popup.continueVisitBtn.value}
            onContinueVisitClick={elements.popup.continueVisitBtn.handler}
          />
        )}

        <div className="d-flex">
          <Button
            fluid={true}
            type="button"
            onClick={onProfileClick}
            className={cx({ "mr-1": !isTemporary })}
          >
            Patient Profile
          </Button>
          {!isTemporary && (
            <Button
              fluid={true}
              className="ml-1 insurance-details-btn"
              type="button"
              onClick={onShowInsuranceForm}
            >
              Insurance Details
            </Button>
          )}
        </div>
        <EligibleComponent
          eligibilityIdPayer={eligibilityIdPayer}
          setEligibilityIdPayer={setEligibilityIdPayer}
          eligibilityStatus={eligibilityStatus}
          setEligibilityStatus={(status) => {
            setEligibilityStatus(status);
            updateAppointmentEligibilityStatus({ id: appointment.id, eligible: status });
          }}
          appointment={appointment}
        />
      </div>

      <div className="mb-2 d-flex">
        <Button
          type="button"
          className="mr-2"
          disabled={eligibilityDetails}
          onClick={() => {
            onShowEligibilityDetails();
          }}
        >
          Check Eligibility
        </Button>

        <ViewEligibilityButtonWrapper
          appointment={appointment}
          onClick={() => {
            onShowEligibilityDetails();
          }}
        />
      </div>

      <AppointmentPatientViewModalWrapper
        show={showPatientModal}
        patientKey={patient.patientKey}
        onClose={() => setShowPatientModal(false)}
      />

      <AppointmentPlannedModalWrapper
        getList={false}
        show={showPlanned}
        appointmentData={false}
        patientKey={patient.patientKey}
        onClose={() => setShowPlanned(false)}
      />

      <ViewEligibilityModalWrapper
        appointment={appointment}
        show={showViewEligibility}
        onHide={() => setShowViewEligibility(false)}
      />

      <Modal
        size="lg"
        show={patientLabelModalVisible}
        actions={
          <button className="btn btn-success" onClick={() => setPatientLabelModalVisible(false)}>
            close
          </button>
        }
        bodyProps={{
          style: {
            height: "50vh",
          },
        }}
      >
        {/*<EmbedButton show={true} src={"data:application/pdf;base64," + encodeURI(patientLabel)} />*/}
        <iframe
          src={"data:application/pdf;base64," + encodeURI(patientLabel)}
          title="Patient label"
          style={{ height: "100%" }}
        ></iframe>
      </Modal>
    </div>
  );
}
const EligibleComponent = ({
  eligibilityIdPayer,
  setEligibilityIdPayer,
  eligibilityStatus,
  setEligibilityStatus,
  appointment,
}) => {
  const [eligibilityId, setEligibilityId] = useState(eligibilityIdPayer);

  const eligibility = useShallowEqualSelector(
    appointmentEligibilityDetailsSelector(appointment?.id) as any,
  );

  useEffect(() => {
    if (eligibilityIdPayer) {
      setEligibilityId(eligibilityIdPayer);
    } else {
      setEligibilityId("");
    }
  }, [eligibilityIdPayer]);

  useEffect(() => {
    if (eligibility?.eligibilityCheck?.eligibilityId) {
      setEligibilityIdPayer("eELIG-" + eligibility?.eligibilityCheck?.eligibilityId);
      setEligibilityId(eligibility?.eligibilityCheck?.eligibilityId);
    }
  }, [eligibility?.eligibilityCheck?.eligibilityId]);
  return (
    <div>
      <div className="d-flex mt-2">
        <div style={{ width: "48%" }} className="mr-1">
          <Button type="button" className="btn-block">
            Eligibility
          </Button>
        </div>
        <div className="d-flex" style={{ width: "50%" }}>
          <Button
            type="button"
            onClick={() => setEligibilityStatus(true)}
            buttonType={eligibilityStatus === true ? ButtonType.Primary : ButtonType.Secondary}
            className="ml-1"
          >
            YES
          </Button>
          <Button
            type="button"
            onClick={() => setEligibilityStatus(false)}
            buttonType={eligibilityStatus === false ? ButtonType.Primary : ButtonType.Secondary}
            className="ml-1"
          >
            NO
          </Button>
          <Button
            type="button"
            onClick={() => setEligibilityStatus(undefined)}
            buttonType={eligibilityStatus === undefined ? ButtonType.Primary : ButtonType.Secondary}
            className="ml-1"
          >
            NA
          </Button>
        </div>
      </div>
      <input
        type="text"
        disabled={eligibility}
        placeholder="Enter Eligibility ID"
        onChange={({ target: { value } }) => setEligibilityId(value)}
        onBlur={() => setEligibilityIdPayer(eligibilityId)}
        value={eligibilityId}
        autoComplete="off"
        className="form-control input-lg mt-2"
      />
    </div>
  );
};
