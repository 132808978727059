const seriesTypes = {
  treatment: {
    name: "treatments",
    code: "treatment",
    buttonText: "TRX",
  },
  diagnosis: {
    name: "diagnosis",
    code: "diagnosis",
    buttonText: "Diag",
  },
  existing: {
    name: "existing treatments",
    code: "existing",
    buttonText: "Ex.",
  },
  mixed: {
    name: "mixed codes",
    code: "mixed",
    buttonText: "Mix",
  },
  procedure: {
    name: "procedures",
    code: "procedure",
    buttonText: "Pckgs|Groups",
  },
};

export default seriesTypes;
