import _ from "lodash";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { DOCTORS_MODE } from "../../../widgets/DoctorsSelect";
import { DoctorsSelectWrapper } from "../../../../wrappers/DoctorsSelectWrapper";

import Utils from "../../../../helpers/Utils";
import { selectTreatmentToCompleteGetList } from "../../../../actions/chartActions";
import { SelectPicker } from "../../../select-picker/SelectPicker";

const enhancer = compose(
  connect(null, { selectTreatmentToCompleteGetList }),
  reduxForm({
    form: "selectTreatmentToCompleteDentist",
  }),
);

export default enhancer(function SelectTreatmentToCompleteDentistForm({
  members,
  selectTreatmentToCompleteGetList,
}) {
  const $options = _.map(members, (item) => ({
    label: Utils.getFullName(item),
    value: item.id,
  }));

  return (
    <div className="form-horizontal flex-shrink-0">
      <div className="form-group m-0">
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <label
              htmlFor="closedDentistId"
              className="control-label"
              style={{ textAlign: "left", padding: 0, fontSize: "14px" }}
            >
              Change value, to complete a Service/Treatment by another{" "}
              {localStorage.getItem("doctorLabel")}:
            </label>
          </div>
          <div className="pull-left" style={{ width: "200px", marginLeft: "10px" }}>
            <Field
              id="closedDentistId"
              name="closedDentistId"
              component={({ input }) => (
                <SelectPicker
                  options={$options}
                  value={{ value: input.value }}
                  onChange={(x) => input.onChange(x.value)}
                />
              )}
            />
          </div>
          <div className="ml-1">
            <label className="control-label" style={{ marginRight: "5px", fontSize: "14px" }}>
              Posted by:
            </label>
          </div>
          <div className="mr-1">
            <DoctorsSelectWrapper mode={DOCTORS_MODE} hideBySelect={true} allDoctorsTitle="All" />
          </div>
          <div className="mr-3">
            <button className="btn btn-default" onClick={() => selectTreatmentToCompleteGetList()}>
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
