import React from "react";

import { Modal } from "../ui/Modal";

import ChartPrescriptionDiagnosis from "../chart/side-bar/prescription/ChartPrescriptionDiagnosis";

export default class MedicationHistoryModal extends React.Component {
  render() {
    return (
      <Modal
        size="large"
        show={this.props.show}
        dialogClassName="medication-pdf-modal"
        onHide={this.props.onHide}
      >
        <ChartPrescriptionDiagnosis
          chartSidePrescription={this.props.chartSidePrescription}
          onSubmit={this.props.onSubmitCallback}
          onBack={this.props.onHide}
        />
      </Modal>
    );
  }
}
