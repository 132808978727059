import React from "react";
import cx from "classnames";

export function ButtonGroup({ buttons = [], onClick, active }) {
  return (
    <div className="btn-group btn-group-sm text-center">
      {buttons.map(({ label, value, disabled }) => (
        <button
          type="button"
          className={cx("btn", {
            "btn-primary": active !== value,
            "btn-default": active === value,
          })}
          key={value}
          disabled={disabled}
          onClick={() => onClick(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
