import React, { useEffect } from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { initSideReferralNextVisit } from "../../../../actions/referralActions";
import { postOpStackCodes } from "../../../../constants/chart-sidebar/postOpStackCodes";
import ChartReferralNextVisitOwned from "./ChartReferralNextVisitOwned";
import { getCategoryList } from "../../../../actions/appointmentActions";

const enhancer = connect(
  ({
    referral,
    auth: { doctors },
    session: { clinic },
    chart: { patient },
    appointment: { categoryList },
  }) => ({
    referral,
    doctors,
    patient,
    clinic,
    categoryList,
  }),
);
const ChartReferralNextVisit = ({ referral, doctors, clinic, patient, categoryList }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initSideReferralNextVisit());
    dispatch(getCategoryList({ clinicId: clinic.id }));
  }, []);
  const { stack } = referral;

  const stackItem = _.last(stack);
  if (stackItem.code === postOpStackCodes.owned) {
    return (
      <ChartReferralNextVisitOwned
        referral={referral}
        doctors={doctors}
        patient={patient}
        categoryList={categoryList}
      />
    );
  }
};

export default enhancer(ChartReferralNextVisit);
