import _ from "lodash";

import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/patientListActionTypes";
import { GET_SESSION_SUCCESS } from "../actions/sessionActions";
import {
  SORT_LIST_BY_CHART_NUMBER,
  SORT_LIST_BY_NAME,
} from "../actionTypes/patientListActionTypes";

export const defaultState = {
  list: [],
  listTotalCount: 0,
  loading: false,
  sideBarVisible: true,
  phoneCode: 971,
  sortListByChartNumber: false,
  sortListByName: false,
};

const reducers = {
  [GET_SESSION_SUCCESS](state, { data }) {
    return {
      ...state,
      phoneCode: +_.get(data, "clinic.address.country.phoneCode") || 971,
    };
  },
  [SORT_LIST_BY_CHART_NUMBER](state) {
    return {
      ...state,
      sortListByChartNumber: !state.sortListByChartNumber,
    };
  },
  [SORT_LIST_BY_NAME](state) {
    return {
      ...state,
      sortListByName: !state.sortListByName,
    };
  },
  [ActionTypes.PATIENT_LIST_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
        loading: false,
      };
    }

    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
      loading: false,
    };
  },
  [ActionTypes.PATIENT_LIST_GET_LIST_START](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [ActionTypes.PATIENT_LIST_GET_LIST_ERROR](state) {
    return {
      ...state,
      list: [],
      loading: false,
    };
  },
  [ActionTypes.PATIENT_LIST_SHOW_SIDEBAR](state) {
    return {
      ...state,
      sideBarVisible: true,
    };
  },
  [ActionTypes.PATIENT_LIST_HIDE_SIDEBAR](state) {
    return {
      ...state,
      sideBarVisible: false,
    };
  },
  [ActionTypes.PATIENT_LIST_CHANGE_PHONE_CODE](state, { value }) {
    return {
      ...state,
      phoneCode: value,
    };
  },
};

export default createReducer(defaultState, reducers);
