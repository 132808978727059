import React, { useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { Modal } from "../../ui/Modal";
import Show from "../../widgets/Show";
import AppointmentActionsLogs from "./AppointmentActionsLogs";
import AppointmentRemindersLogs from "./AppointmentRemindersLogs";
import { useRemindersLogQuery } from "../../../queries/appointmentLogs/useRemindersLogQuery";
import { useAppointmentActionsLogQuery } from "../../../queries/appointmentLogs/useAppointmentActionsLogQuery";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  content: {
    maxHeight: "400px",
    overflowY: "auto",
  },
}));

const AppointmentLogsModal = (props) => {
  const {
    logsModalVisible,
    setAppointmentsLogModalVisible,
    appointmentId,
    item,
    appointment,
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("actionsLog");
  const [fetchedReminders, setFetchedReminders] = useState(false);

  const remindersLogQuery = useRemindersLogQuery({ id: appointmentId });
  const appointmentActionsLogsQuery = useAppointmentActionsLogQuery({ id: appointmentId });

  const activeTabHandler = (tab) => {
    setActiveTab(tab);
    if (tab === "remindersLog" && !fetchedReminders) {
      remindersLogQuery.refetch().then((r) => r);
      setFetchedReminders(true);
    }
  };

  return (
    <Modal
      dialogClassName="appointment-logs-modal"
      show={logsModalVisible}
      onHide={setAppointmentsLogModalVisible}
      actions={
        <button type="button" className="btn btn-success" onClick={setAppointmentsLogModalVisible}>
          Ok
        </button>
      }
    >
      <div className="modal-body mb-0">
        <div
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="d-flex mb-2">
            <Button
              className={cx("flex-grow-1 flex-shrink-1 mr-1", {
                "bg-white text-black": activeTab !== "actionsLog",
              })}
              onClick={() => activeTabHandler("actionsLog")}
            >
              Actions Log
            </Button>
            <Button
              className={cx("flex-grow-1 flex-shrink-1", {
                "bg-white text-black": activeTab !== "remindersLog",
              })}
              onClick={() => activeTabHandler("remindersLog")}
            >
              Reminders Log
            </Button>
          </div>
          <Show if={activeTab === "actionsLog"}>
            <AppointmentActionsLogs
              item={item}
              classes={classes}
              appointment={appointment}
              actionsLogQuery={appointmentActionsLogsQuery}
            />
          </Show>
          <Show if={activeTab === "remindersLog"}>
            <AppointmentRemindersLogs
              item={item}
              classes={classes}
              appointment={appointment}
              remindersLogQuery={remindersLogQuery}
            />
          </Show>
        </div>
      </div>
    </Modal>
  );
};

export default AppointmentLogsModal;
