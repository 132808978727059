import React from "react";
import Show from "../widgets/Show";

export default function CallListTitles({ columns, columnWidth }) {
  const $titles = columns.map((column, index) => (
    <div key={index} className="call-list-title-item">
      <div className="point">{column.title.title}</div>
      <div className="doctor">
        Dr.
        {column.title.dentistName}
      </div>
    </div>
  ));

  return (
    <div
      className="call-list-title"
      style={{ width: columns.length ? `${columnWidth * columns.length}px` : "100vw" }}
    >
      <Show if={(columns.list || []).length > 0}>{$titles}</Show>
    </div>
  );
}
