import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormCheck } from "react-bootstrap";

import Back from "../widgets/Back";
import { Button } from "../ui/Button";
import EmbedButton from "../widgets/EmbedButton";
import { parseQuery } from "../../utils/UrlUtils";
import MedicationPDFModal from "./MedicationPDFModal";
import MedicationItemForm from "./MedicationItemForm";
import MedicationDrugModal from "./MedicationDrugModal";
import MedicationHistoryModal from "./MedicationHistoryModal";
import * as medicationActions from "../../actions/medicationActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import { ButtonGroup } from "@mui/material";
import { setPrimary, toggleDiagnosis } from "../../actions/chartSidePrescriptionActions";
import { SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION } from "../../actionTypes/chartSidePrescriptionActionTypes";
import moment from "moment";
import { sendToERX } from "../../actions/medicationActions";
import medicalFillInFormItemTypes from "../../constants/chart-sidebar/medicalFillInFormItemTypes";
import Show from "../widgets/Show";

const MEDICATION_HISTORY = "MEDICATION_HISTORY";
const VOIDED_MEDICATION = "VOIDED_MEDICATION";

const enhancer = connect(
  ({
    medication,
    session: { member },
    chartSidePrescription,
    chart: { appointment },
    chartSideMedicalFillInForm,
  }) => ({
    medication,
    member,
    chartSidePrescription,
    appointment,
    chartSideMedicalFillInForm,
  }),
  null,
);
const MedicationList = (props) => {
  const [url, setUrl] = useState(null);
  const [medicationTab, setMedicationTab] = useState(MEDICATION_HISTORY);
  const stackItem = _.last(props.chartSideMedicalFillInForm.stack);

  useEffect(() => {
    props.dispatch(medicationActions.getThisAppointmentDiagnosisForPrint()).then((response) => {
      const list = response.list || [];
      const appointmentId = appointment.id;
      const thisAppointmentDiagnosis = list
        .filter((item) => item.createdAppointmentId === appointmentId)
        .map((item) => item.diagnosisItem.id);
      thisAppointmentDiagnosis.forEach((item, index) => {
        if (index === 0) {
          props.dispatch(setPrimary(item));
        } else {
          props.dispatch(toggleDiagnosis(item));
        }
      });
    });
    return () => {
      props.dispatch({ type: SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION });
    };
  }, []);

  useEffect(() => {
    const { location } = props;

    const query = parseQuery(location.search);

    props.dispatch(medicationActions.init());
    medicationTab === MEDICATION_HISTORY
      ? props.dispatch(medicationActions.getList({ ...query, voided: false }))
      : props.dispatch(medicationActions.getList({ ...query, voided: true }));
    props.dispatch(medicationActions.getFormData());
  }, [medicationTab]);

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      props.dispatch(medicationActions.loadList());
    }
  };

  const printHandler = () => {
    props
      .dispatch(medicationActions.createPDF(parseQuery(location.search).patientKey))
      .then((response) => {
        setUrl(_.get(response, "url"));
      });
  };

  const { location, appointment } = props;

  const {
    list,
    newList,
    historyModalVisible,
    checkedMap,
    checkedMapToday,
    checkedMapOld,
    pdfModalVisible,
    sendToERX,
  } = props.medication;

  const todayList = list.filter((item) => moment().isSame(item.createdDate, "day"));
  const otherDayList = list.filter((item) => !moment().isSame(item.createdDate, "day"));

  const $todayRows = [...newList, ...todayList].map((item, index) => {
    const initialValues = {
      amount: item.amount || 1,
      route: item.rout && item.rout.id,
      frequency: item.routFrequency && item.routFrequency.id,
      dispense: item.dispense || 1,
      refills: item.refills || 0,
      dosageForm: item.dosageForm || item.medication?.dosageForm || "",
      genericName: item.name || item.medication?.genericName || "",
      instructions: item.instructions,
      strength: item.strength || item.medication?.strength || "",
    };
    return (
      <MedicationItemForm
        key={index}
        lastNewIndex={newList.length - 1}
        index={index}
        item={item}
        memberId={_.get(props, "member.id")}
        form={`medicationItem${index}`}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        initialValues={initialValues}
        medication={props.medication}
        dispatch={props.dispatch}
        medicalForms={props.medicalForms}
        todayList={true}
      />
    );
  });

  const $rows = (props.medicalForms ? otherDayList : [...newList, ...list]).map((item, index) => {
    const initialValues = {
      amount: item.amount || 1,
      route: item.rout && item.rout.id,
      frequency: item.routFrequency && item.routFrequency.id,
      dispense: item.dispense || 1,
      refills: item.refills || 0,
      dosageForm: item.dosageForm || item.medication?.dosageForm || "",
      genericName: item.name || item.medication?.genericName || "",
      instructions: item.instructions,
      strength: item.strength || item.medication?.strength || "",
    };
    return (
      <MedicationItemForm
        // lastNewIndex={newList.length - 1}
        key={item.id}
        index={index}
        item={item}
        form={`medicationItem${item.id || index}`}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        initialValues={initialValues}
        medication={props.medication}
        dispatch={props.dispatch}
        medicalForms={props.medicalForms}
      />
    );
  });

  const allChecked = Object.keys(checkedMap).length === list.length;
  const todayAllChecked = Object.keys(checkedMapToday).length === todayList.length;
  const otherAllChecked = Object.keys(checkedMapOld).length === otherDayList.length;

  const getCheckedMap = () => {
    if (!props.medicalForms) return checkedMap;
    return _.isEmpty(checkedMapToday) ? checkedMapOld : checkedMapToday;
  };

  return (
    <div className="patient-appointments-list" style={{ height: "100%" }}>
      <div className="default-page-title clearfix text-center">
        {!props.medicalForms && <Back className="pull-left" />}
        <Show
          if={
            !props.medicalForms ||
            stackItem?.type === medicalFillInFormItemTypes.edit ||
            stackItem?.type === medicalFillInFormItemTypes.new
          }
        >
          {medicationTab === MEDICATION_HISTORY ? (
            <div className="pull-right title">
              <Button
                buttonType="link"
                onClick={() => props.dispatch(medicationActions.openPDFModal(true, true))}
              >
                Send to eRX
              </Button>
              <Button
                buttonType="link"
                onClick={() => {
                  // printHandler();
                  props.dispatch(medicationActions.openPDFModal(true));
                }}
              >
                Print
              </Button>
              <Button
                buttonType="link"
                onClick={() => {
                  if (Object.keys(getCheckedMap()).length) {
                    props.dispatch(
                      simpleModalActions.openSimpleModal({
                        body: "Are you sure you want to void selected medication(s)?",
                        footer: (
                          <div>
                            <button
                              className="btn btn-default"
                              onClick={() => props.dispatch(simpleModalActions.closeSimpleModal())}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                props.dispatch(simpleModalActions.closeSimpleModal());
                                props.dispatch(
                                  medicationActions.batchDelete(parseQuery(props.location.search)),
                                );
                              }}
                            >
                              Void
                            </button>
                          </div>
                        ),
                      }),
                    );
                  } else {
                    props.dispatch(
                      simpleModalActions.openSimpleModal({
                        body: "Please select item(s) to voided",
                      }),
                    );
                  }
                }}
              >
                Void
              </Button>
              <Button
                buttonType="link"
                onClick={() => {
                  if (Object.keys(checkedMap).length) {
                    props.dispatch(medicationActions.duplicate());
                  } else {
                    props.dispatch(
                      simpleModalActions.openSimpleModal({
                        body: "Please select item(s) to duplicate",
                      }),
                    );
                  }
                }}
              >
                Duplicate
              </Button>
              <Button
                buttonType="link"
                onClick={() =>
                  props.dispatch(medicationActions.save(parseQuery(props.location.search)))
                }
              >
                Save
              </Button>
              <Button
                buttonType="link"
                onClick={() => props.dispatch(medicationActions.openDrugModal())}
              >
                Add Drug
              </Button>
            </div>
          ) : (
            <div className="pull-right title">
              <Button
                buttonType="link"
                onClick={() =>
                  props.dispatch(medicationActions.restoreVoidedItem(props.location.search))
                }
              >
                Restore
              </Button>
            </div>
          )}
        </Show>

        <span className="title">
          {medicationTab === "MEDICATION_HISTORY" ? "Medication" : "Voided Medication"}
        </span>
        {!url && (
          <div style={{ margin: "15px 0px" }}>
            <ButtonGroup aria-label="claims tab" className="d-flex align-items-center">
              <button
                onClick={() => {
                  setMedicationTab(MEDICATION_HISTORY);
                  props.dispatch(medicationActions.clearCheckedItem());
                }}
                style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                className={`btn btn-block m-0 ${
                  medicationTab === MEDICATION_HISTORY ? "btn-primary" : "btn-default"
                }`}
              >
                Medication History
              </button>
              <button
                onClick={() => {
                  setMedicationTab(VOIDED_MEDICATION);
                  props.dispatch(medicationActions.clearCheckedItem());
                }}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                className={`btn btn-block m-0 ${
                  medicationTab === VOIDED_MEDICATION ? "btn-primary" : "btn-default"
                }`}
              >
                Voided Medication
              </button>
            </ButtonGroup>
          </div>
        )}
      </div>

      {url ? (
        <div className="medication-embed-container">
          <div>
            <button className="btn btn-success" onClick={() => setUrl(null)}>
              Close
            </button>
          </div>

          <EmbedButton src={url} />
        </div>
      ) : (
        <>
          {props.medicalForms && $todayRows.length ? (
            <div style={{ width: "100%", overflow: "auto", flex: 1 }}>
              <div className="default-table medication-list-table" onScroll={loadList}>
                <div className="default-table-header">
                  <div
                    className="default-table-col col-0"
                    onClick={() =>
                      props.dispatch(medicationActions.checkTodayAll({ list: todayList }))
                    }
                  >
                    <FormCheck checked={todayAllChecked} readOnly={true} />
                  </div>
                  <div className="default-table-col col-1">Drug Name</div>
                  <div className="default-table-col col-1">Generic Name</div>
                  <div className="default-table-col col-9 col-amount">Amount</div>
                  <div className="default-table-col col-3 col-strength">Strength</div>
                  <div className="default-table-col col-3">Dosage Form</div>
                  <div className="default-table-col col-4">Route</div>
                  <div className="default-table-col col-5">Frequency</div>
                  <div className="default-table-col col-9">Dispense</div>
                  <div className="default-table-col col-9">Refills</div>
                  <div className="default-table-col col-3">Instruction</div>
                  <div className="default-table-col col-10">Medication log</div>
                  <div className="default-table-col col-4">Doctor</div>
                  <div className="default-table-col col-11">Date created</div>
                </div>
                <div className="default-table-body">
                  <div>{$todayRows}</div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div style={{ width: "100%", overflow: "auto", flex: 1 }}>
            <div className="default-table medication-list-table" onScroll={loadList}>
              <div className="default-table-header">
                <div
                  className="default-table-col col-0"
                  onClick={() => {
                    if (props.medicalForms) {
                      props.dispatch(medicationActions.checkOldAll({ list: otherDayList }));
                      return;
                    }
                    props.dispatch(medicationActions.checkAll({ clearImportant: false }));
                  }}
                >
                  <FormCheck checked={otherAllChecked || allChecked} readOnly={true} />
                </div>
                <div className="default-table-col col-1">Drug Name</div>
                <div className="default-table-col col-1">Generic Name</div>
                <div className="default-table-col col-9 col-amount">Amount</div>
                <div className="default-table-col col-3 col-strength">Strength</div>
                <div className="default-table-col col-3">Dosage Form</div>
                <div className="default-table-col col-4">Route</div>
                <div className="default-table-col col-5">Frequency</div>
                <div className="default-table-col col-9">Dispense</div>
                <div className="default-table-col col-9">Refills</div>
                <div className="default-table-col col-3">Instruction</div>
                <div className="default-table-col col-10">Medication log</div>
                <div className="default-table-col col-4">Doctor</div>
                <div className="default-table-col col-11">Date created</div>
              </div>
              <div className="default-table-body">
                {/*<div>{$newRows}</div>*/}
                <div>{$rows}</div>
              </div>
            </div>
          </div>
        </>
      )}

      <MedicationDrugModal
        medicalForms={props.medicalForms}
        dispatch={props.dispatch}
        medication={props.medication}
        patientKey={parseQuery(location.search).patientKey}
      />
      {historyModalVisible && (
        <MedicationHistoryModal dispatch={props.dispatch} medication={props.medication} />
      )}
      <MedicationPDFModal
        dispatch={props.dispatch}
        show={pdfModalVisible}
        chartSidePrescription={props.chartSidePrescription}
        onSubmitCallback={() => {
          if (sendToERX) {
            props.dispatch(medicationActions.sendToERX(parseQuery(location.search).patientKey));
          } else {
            props
              .dispatch(medicationActions.createPDF(parseQuery(location.search).patientKey))
              .then((response) => {
                setUrl(_.get(response, "url"));
              });
          }
        }}
        onHide={() => props.dispatch(medicationActions.openPDFModal(false, false))}
      />
    </div>
  );
};

export default enhancer(MedicationList);
