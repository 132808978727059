import React, { isValidElement } from "react";
import { SingleValueProps } from "react-select";
import { makeStyles } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.common.black,
  },
}));

interface Props extends Omit<SingleValueProps<any>, "children"> {
  readonly formatLabel?: (data: any) => any;
}

export function SelectPickerSingleValue(props: Props) {
  const classes = useStyles();

  if (props.selectProps.isSearchable && props.selectProps.menuIsOpen) {
    return null;
  }

  if (
    props.selectProps.placeholder &&
    (!props.selectProps.value || !props.selectProps.value[props.selectProps.valueKey])
  ) {
    if (isValidElement(props.selectProps.placeholder)) {
      return props.selectProps.placeholder;
    } else {
      return <span className={classes.root}>{props.selectProps.placeholder}</span>;
    }
  }

  const value =
    props.data && props.data.label
      ? props.data
      : props.options.find((v) => v.value === props.data.value) || {};

  const label = props.formatLabel ? props.formatLabel(props.data) : value.label;

  return <span className={classes.root}>{label}</span>;
}
