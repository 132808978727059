import React from "react";
import { connect } from "react-redux";

import Back from "../widgets/Back";
import PatientDetailsSignature from "../patient/PatientDetailsSignature";

import * as dashboardActions from "../../actions/dashboardActions";

const enhancer = connect(({ dashboard }) => ({ dashboard }), null);
class ProfileEditSignature extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      modalVisible: false,
    };
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Signature</div>
        </div>
        <div className="body">
          <div className="text-center">
            <img
              src={
                this.props.dashboard.profile.signature &&
                "data:image/png;base64," + this.props.dashboard.profile.signature
              }
              alt=""
              style={{ width: "320px", height: "190px" }}
            />
          </div>
          <div className="text-center">
            <button className="btn btn-primary" onClick={this.openModal}>
              Tap to Sign
            </button>
          </div>
        </div>
        {this.state.modalVisible && (
          <PatientDetailsSignature
            patientRegister={{
              signature:
                this.props.dashboard.profile.signature &&
                "data:image/png;base64," + this.props.dashboard.profile.signature,
              signatureModalVisible: this.state.modalVisible,
            }}
            closeSignature={this.closeModal}
            addSignature={(base64) => {
              this.props.dispatch(dashboardActions.addSignature(base64.slice(22)));
            }}
          />
        )}
      </div>
    );
  }
}
export default enhancer(ProfileEditSignature);
