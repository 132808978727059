import { httpClient } from "./BaseApi";

const FOLDER_LIST_URL = "api/folder/list.json";
const FOLDER_ITEM_URL = "api/folder/item.json";
const FOLDER_MOVE_ITEMS_URL = "api/folder/move-items.json";
const FOLDER_DELETE_ITEMS_URL = "api/folder/delete-items.json";

const FolderApi = {
  getList: (params) => httpClient.get(FOLDER_LIST_URL, { params }),
  createFolder: (data) => httpClient.post(FOLDER_ITEM_URL, { data }),
  moveItems: (data) => httpClient.put(FOLDER_MOVE_ITEMS_URL, { data }),
  deleteItems: (data) => httpClient.put(FOLDER_DELETE_ITEMS_URL, { data }),
};

export default FolderApi;
