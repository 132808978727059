import React from "react";
import { connect } from "react-redux";

import { DatePicker } from "../ui/DatePicker";
import { getRecallList, setFromDate, setToDate } from "../../actions/recallListActions";
import { SelectPicker } from "../select-picker/SelectPicker";

const enhancer = connect(
  ({ waitingList: { missedCancelledListActiveTab }, appointment: { categoryList }, auth }) => ({
    activeTab: missedCancelledListActiveTab,
    categoryList,
    auth,
  }),
  { setFromDate, setToDate, getRecallList },
);

export default enhancer(function RecallListForm({
  categoryList,
  activeTab,
  setActiveTab,
  values,
  setFieldValue,
  auth,
}) {
  const categoryOptions = (categoryList || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const doctorsOptions = (auth.doctors || []).map((item) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.id,
  }));
  const clinicsOptions = (auth.clinics || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <div className="p-4">
      <div className="row">
        <div className="col-sm-12 mb-3">
          <div className="btn-group w-100">
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "missed" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("missed")}
            >
              Recall List
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${
                activeTab === "requestedReschedule" ? "btn-success" : "btn-default"
              }`}
              onClick={() => setActiveTab("requestedReschedule")}
            >
              Requested Reschedule (whatsapp)
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "noAnswer" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("noAnswer")}
            >
              No Answer
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "willCall" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("willCall")}
            >
              Will Call back
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "notInterested" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("notInterested")}
            >
              Not Interested
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Clinic:</div>
            <SelectPicker
              name="clinic"
              options={clinicsOptions}
              value={values.clinic}
              onChange={(v) => setFieldValue("clinic", v)}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Appointment type:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                isMulti={true}
                clearable={true}
                searchable={false}
                name="categoryIds"
                value={values.categoryIds}
                options={categoryOptions}
                onChange={(v) => setFieldValue("categoryIds", v)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Start date:</div>
            <DatePicker
              name="periodStart"
              selected={values.periodStart}
              fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
              onChange={(x) => setFieldValue("periodStart", x)}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>End date:</div>
            <DatePicker
              name="periodEnd"
              selected={values.periodEnd}
              fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
              onChange={(x) => setFieldValue("periodEnd", x)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Doctors:</div>
            <div className="form-group">
              <SelectPicker
                name="doctorIds"
                isMulti={true}
                isSearchable={true}
                allLabel="All Doctors"
                options={doctorsOptions}
                value={values.doctorIds}
                onChange={(v) => setFieldValue("doctorIds", v)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-4">
              <button
                type="submit"
                style={{ marginTop: "20px" }}
                className="btn btn-primary btn-block"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
