import React from "react";
import { connect } from "react-redux";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";

import bindActions from "../../../helpers/bindActions";
import * as chartDermatologyActions from "../../../actions/chartDermatologyActions";
import * as chartActions from "../../../actions/chartActions";
import manImage from "../../../assets/images/chart/dermatology/man_cut.png";
import womenImage from "../../../assets/images/chart/dermatology/women_cut.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

const enhancer = connect(null, bindActions({ chartDermatologyActions, chartActions }));

class ChartDermatology extends React.Component {
  addTreatment = () => {
    this.props.chartActions.showSideBar(viewTypes.series);
  };

  checkActive = (index) => {
    const { chartDermatology } = this.props;

    if (chartDermatology.areas[index]) {
      return " active";
    } else {
      return "";
    }
  };

  toggleArea = (index) => {
    return () => {
      this.props.chartDermatologyActions.toggleArea(index);
    };
  };

  render() {
    const { patient } = this.props;

    let $image = null;

    if (patient.gender && patient.gender.code === "MAN") {
      $image = <img src={obtainImagePath(manImage)} className="zoom-2x" alt="" />;
    } else {
      $image = <img src={obtainImagePath(womenImage)} className="zoom-2x" alt="" />;
    }

    return (
      <div className="chart-dermatology" style={{ overflowY: "auto" }}>
        <div className="chart-dermatology-choose">
          <div className="left-side">
            <div className={"area area-9" + this.checkActive(9)} onClick={this.toggleArea(9)}>
              Scalp
            </div>
            <div className={"area area-1" + this.checkActive(1)} onClick={this.toggleArea(1)}>
              Face
            </div>
            <div className={"area area-10" + this.checkActive(10)} onClick={this.toggleArea(10)}>
              Right Axilla
            </div>
            <div className={"area area-2" + this.checkActive(2)} onClick={this.toggleArea(2)}>
              Right Arm
            </div>
            <div className={"area area-13" + this.checkActive(13)} onClick={this.toggleArea(13)}>
              Abdomen
            </div>
            <div className={"area area-3" + this.checkActive(3)} onClick={this.toggleArea(3)}>
              Chest
            </div>
            <div className={"area area-11" + this.checkActive(11)} onClick={this.toggleArea(11)}>
              Genital
            </div>
            <div className={"area area-4" + this.checkActive(4)} onClick={this.toggleArea(4)}>
              Right Leg
            </div>
            <div className={"area area-12" + this.checkActive(12)} onClick={this.toggleArea(12)}>
              Right Foot
            </div>
          </div>
          <div className="image">{$image}</div>
          <div className="right-side">
            <div className={"area area-5" + this.checkActive(5)} onClick={this.toggleArea(5)}>
              Back
            </div>
            <div className={"area area-14" + this.checkActive(14)} onClick={this.toggleArea(14)}>
              left Axilla
            </div>
            <div className={"area area-6" + this.checkActive(6)} onClick={this.toggleArea(6)}>
              left Arm
            </div>
            <div className={"area area-7" + this.checkActive(7)} onClick={this.toggleArea(7)}>
              Lower Back
            </div>
            <div className={"area area-8" + this.checkActive(8)} onClick={this.toggleArea(8)}>
              Left Leg
            </div>
            <div className={"area area-15" + this.checkActive(15)} onClick={this.toggleArea(15)}>
              Left Foot
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary btn-block chart-dermatology-add"
          onClick={this.addTreatment}
        >
          Add Treatment
        </button>
      </div>
    );
  }
}

export default enhancer(ChartDermatology);
