import React from "react";

import Utils from "../../../helpers/Utils";

export default class ChartTimer extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      time: this.renderTime(),
    };
  }

  componentWillMount() {
    this.timer = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick = () => {
    this.setState({ time: this.renderTime() });
  };

  renderTime() {
    const now = Date.now();
    const duration = Math.round((now - this.props.startTime) / 1000);
    const seconds = duration % 60;
    const minutes = (duration - seconds) / 60;

    return Utils.toTwoNumbersString(minutes) + ":" + Utils.toTwoNumbersString(seconds);
  }

  render() {
    return <div className="timer">{this.state.time}</div>;
  }
}
