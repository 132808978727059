import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { SelectField } from "../../../form/SelectField";
import { TextField } from "../../../form/TextField";
import { RadioField } from "../../../form/RadioField";
import { DateField } from "../../../form/DateField";
import { parseQuery } from "../../../../utils/UrlUtils";
import MedicationDrugModal from "../../../medication/MedicationDrugModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PlusIcon from "../../../../assets/images/appointment/plus_grey.png";
import { openDrugModal } from "../../../../actions/medicationActions";
const ChartPrescriptionEditForm = (props) => {
  const {
    formData,
    medicationList = [],
    onChangeDrugCategory,
    index,
    onChangeDosageForm,
    setFieldValue,
    values,
  } = props;
  const dispatch = useDispatch();
  const medication = useSelector((store) => store.medication);
  const medicationItem = useSelector((store) => store.medication?.newList);
  const location = useLocation();

  const handleChange = (value, drug) => {
    const medication = medicationList.find((item) => item.id === value) || "";

    if (drug) {
      setFieldValue(`prescriptions.${index}.drugGeneric`, {
        value: medication.id,
        label: medication.genericName,
      });
    } else {
      setFieldValue(`prescriptions.${index}.drug`, {
        value: medication.id,
        label: medication.name,
      });
    }
    setFieldValue(`prescriptions.${index}.strengthValue`, medication.strength || "");
    onChangeDosageForm(medication.dosageForm);
  };

  useEffect(() => {
    if (medicationItem[index]) {
      const medication =
        values.length === 1
          ? medicationItem[medicationItem.length - 1]?.medication
          : medicationItem[index]?.medication;
      setFieldValue(`prescriptions.${index}.drugGeneric`, {
        value: medication.id,
        label: medication.genericName,
      });

      setFieldValue(`prescriptions.${index}.drug`, {
        value: medication.id,
        label: medication.name,
      });
      setFieldValue(`prescriptions.${index}.drugCategory`, {
        value: medication.category.id,
        label: medication.category.name,
      });
      setFieldValue(`prescriptions.${index}.strengthValue`, medication.strength || "");

      onChangeDosageForm(medication.dosageForm);
    }
  }, [medicationItem.length]);

  const $drugCategory = (formData.medicationCategory || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const $drugGeneric = medicationList
    .filter((item) => item.genericName)
    .map((item) => ({
      value: item.id,
      label: item.genericName,
    }));

  const $drug = medicationList.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const $route = (formData.routList || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const $frequency = (formData.routFrequencyList || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const $dispenseUnit = (formData.amountUnitList || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <div className="pt-2 pb-2">
      <div className="form-group row">
        <div className="col-sm-6 ">
          <SelectField
            label="Drug category"
            options={$drugCategory}
            isSearchable={true}
            name={`prescriptions.${index}.drugCategory`}
            onChange={(v) => onChangeDrugCategory(v.value)}
          />
        </div>
        <div className="col-sm-6 d-flex align-center" onClick={() => dispatch(openDrugModal())}>
          <SelectField
            label="Drug"
            options={$drug}
            // isSearchable={true}
            name={`prescriptions.${index}.drug`}
            onChange={(v) => handleChange(v.value, true)}
            fieldProps={{ style: { width: "94%", pointerEvents: "none" } }}
          />
          <img
            src={PlusIcon}
            onClick={() => dispatch(openDrugModal())}
            style={{ width: 20, height: 20, margin: "25px 0px 0px 10px " }}
            alt="plus-icon"
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6 d-flex align-center" onClick={() => dispatch(openDrugModal())}>
          <SelectField
            label="Drug generic name"
            options={$drugGeneric}
            // isSearchable={true}
            name={`prescriptions.${index}.drugGeneric`}
            onChange={(v) => handleChange(v.value)}
            fieldProps={{ style: { width: "94%", pointerEvents: "none" } }}
          />
          <img
            src={PlusIcon}
            onClick={() => dispatch(openDrugModal())}
            style={{ width: 20, height: 20, margin: "25px 0px 0px 10px " }}
            alt="plus-icon"
          />
        </div>
        <div className="col-sm-6">
          <TextField label="Strength" name={`prescriptions.${index}.strengthValue`} />
        </div>
      </div>
      <hr />
      <div className="form-group row">
        <div className="col-sm-6">
          <TextField label="Amount" name={`prescriptions.${index}.amount`} type="number" />
        </div>
        <div className="col-sm-6">
          <TextField
            label="Dosage From"
            name={`prescriptions.${index}.dosageForm`}
            disabled={true}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <SelectField label="Route" options={$route} name={`prescriptions.${index}.route`} />
        </div>
        <div className="col-sm-6">
          <SelectField
            label="Frequency"
            options={$frequency}
            name={`prescriptions.${index}.frequency`}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <TextField
            label="Dispense Amount"
            name={`prescriptions.${index}.dispense`}
            type="number"
          />
        </div>
        <div className="col-sm-6">
          <SelectField
            label="Dispense Form"
            options={$dispenseUnit}
            name={`prescriptions.${index}.dispenseMedicationUnit`}
          />
        </div>
      </div>
      <hr />
      <div className="form-group row">
        <div className="col-sm-6">
          <TextField label="Refills" name={`prescriptions.${index}.refills`} type="number" />
        </div>
        <div className="col-sm-6">
          <TextField label="Duration" name={`prescriptions.${index}.duration`} type="number" />
        </div>
      </div>
      <hr />
      <hr />
      <div className="d-flex">
        <RadioField
          label="Allow generic substitution"
          name={`prescriptions.${index}.generic`}
          radioValue="generic"
          className="mr-2"
        />
        <RadioField
          label="Dispense as written"
          name={`prescriptions.${index}.generic`}
          radioValue="written"
        />
      </div>
      <hr />
      <div className="form-group row">
        <div className="col-sm-6">
          <DateField
            fieldProps={{ labelPosition: "left" }}
            name={`prescriptions.${index}.expirationDate`}
            label="Expiration date"
          />
        </div>
      </div>
      <hr />
      <div className="form-group row">
        <div className="col-sm-12">
          <TextField
            label="Patient instructions"
            name={`prescriptions.${index}.instructions`}
            as="textarea"
            rows={5}
          />
        </div>
      </div>
      <MedicationDrugModal
        medicalForms={true}
        dispatch={dispatch}
        medication={medication}
        patientKey={parseQuery(location.search).patientKey}
        prescriptionMode={true}
      />
    </div>
  );
};

ChartPrescriptionEditForm.propTypes = {
  formData: PropTypes.object,
  medicationList: PropTypes.array,
  dispatch: PropTypes.object,
  change: PropTypes.object,
  onChangeDrugCategory: PropTypes.func,
  onChangeDosageForm: PropTypes.func,
  index: PropTypes.number,
};
export default ChartPrescriptionEditForm;
