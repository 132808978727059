import _ from "lodash";
import PatientApi from "../api-legacy/PatientApi";
import MedicationApi from "../api-legacy/MedicationApi";
import DiagnosisApi from "../api-legacy/DiagnosisApi";

import { prescriptionStackCodes } from "../constants/chart-sidebar/prescriptionStackCodes";
import Utils from "../helpers/Utils";

import * as ActionType from "../actionTypes/chartSidePrescriptionActionTypes";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import { hideSideBar } from "./chartActions";
import { MEDICATION_CLEAR_NEW_LIST } from "../actionTypes/medicationActionTypes";

export function getPrescriptionGroupTemplates() {
  return (dispatch, getState) => {
    const {
      chartSidePrescription: { stack },
    } = getState();

    const group = _.last(stack);

    const data = {
      start: 0,
      limit: 20,
      groupId: group.id,
    };

    return dispatch(getPrescriptionGroupTemplatesApi(data)).catch((e) => dispatch(showMessage(e)));
  };
}

export function loadPrescriptionGroupTemplates() {
  return (dispatch, getState) => {
    const {
      chartSidePrescription: {
        stack,
        fetchingGroupTemplates,
        templatesGroupTemplates,
        templatesGroupTemplatesCount,
      },
    } = getState();

    if (templatesGroupTemplates.length === templatesGroupTemplatesCount || fetchingGroupTemplates) {
      return;
    }

    const group = _.last(stack);

    const data = {
      limit: 20,
      groupId: group.id,
      start: templatesGroupTemplates.length,
    };

    return dispatch(getPrescriptionGroupTemplatesApi(data)).catch((e) => dispatch(showMessage(e)));
  };
}

export function getPrescriptionGroups() {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 20,
    };

    return dispatch(getPrescriptionGroupsApi(data)).catch((e) => dispatch(showMessage(e)));
  };
}

export function loadPrescriptionGroups() {
  return (dispatch, getState) => {
    const {
      chartSidePrescription: { fetchingGroups, templatesGroups, templatesGroupsCount },
    } = getState();

    if (templatesGroups.length === templatesGroupsCount || fetchingGroups) {
      return;
    }

    const data = {
      start: templatesGroups.length,
      limit: 20,
    };

    return dispatch(getPrescriptionGroupsApi(data)).catch((e) => dispatch(showMessage(e)));
  };
}

export function initPrescription() {
  return {
    type: ActionType.SIDE_PRESCRIPTION_INIT,
  };
}

export function selectMedication(id) {
  return (dispatch, getState) => {
    const {
      chartSidePrescription: { medicationLists },
    } = getState();
    let selectedMedication = {};
    for (const key in medicationLists) {
      selectedMedication = medicationLists[key].find((item) => item.id === id);
    }
    dispatch({
      type: ActionType.SELECT_MEDICATION,
      selectedMedication,
    });
  };
}

function pushStack(stackItem) {
  return {
    type: ActionType.SIDE_PRESCRIPTION_PUSH_STACK,
    stackItem,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSidePrescription;

    if (stack.length == 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.SIDE_PRESCRIPTION_POP_STACK,
      });
    }
  };
}

export function popPrescriptionStack() {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION,
    });

    dispatch(popStack());
  };
}

export function openFavourite() {
  return pushStack({
    code: prescriptionStackCodes.favourites,
  });
}

export function openCategory() {
  return pushStack({
    code: prescriptionStackCodes.category,
  });
}

export function openGroups() {
  return pushStack({
    code: prescriptionStackCodes.templatesGroup,
  });
}

export function openGroupsAdd(id) {
  return (dispatch) => {
    const data = {
      start: 0,
      limit: 20,
      groupId: id,
    };

    dispatch(startLoader());

    return dispatch(getPrescriptionGroupTemplatesApi(data))
      .then((response) => {
        if (response?.list?.length) {
          response?.list.forEach((item) => {
            if (item.medicationCategory ?? item.medicationCategory.id) {
              dispatch(
                getMedicationsByCategory({
                  categoryId: item.medicationCategory.id,
                }),
              );
            }
          });
        }
        dispatch(addPrescription(response?.list || []));

        dispatch(stopLoader());

        dispatch(
          pushStack({
            code: prescriptionStackCodes.edit,
          }),
        );
      })
      .catch((e) => dispatch(showMessage(e)));
  };
}

export function openFavouriteAdd(prescriptions) {
  return (dispatch) => {
    dispatch(syncForm(prescriptions));

    dispatch(
      pushStack({
        code: prescriptionStackCodes.favouritesAdd,
      }),
    );
  };
}

export function openCategoryAdd() {
  return (dispatch) => {
    dispatch(
      pushStack({
        code: prescriptionStackCodes.categoryAdd,
      }),
    );
  };
}

export function openTemplates(id) {
  return pushStack({
    code: prescriptionStackCodes.templates,
    id,
  });
}

export function openTemplatesAdd(id) {
  return pushStack({
    code: prescriptionStackCodes.templatesAdd,
    id,
  });
}

export function openPDF(item) {
  return pushStack({
    code: prescriptionStackCodes.view,
    item,
  });
}

export function openNameModal() {
  return pushStack({
    code: prescriptionStackCodes.name,
  });
}

export function openEmpty() {
  return (dispatch) => {
    dispatch(addPrescription({}));

    dispatch(
      pushStack({
        code: prescriptionStackCodes.edit,
      }),
    );
  };
}

export function addEmptyFromTemplates() {
  return (dispatch) => {
    dispatch(addPrescription({}));

    dispatch(popStack());

    dispatch(popStack());

    dispatch(popStack());
  };
}

export function addEmpty() {
  return (dispatch) => {
    dispatch(addPrescription({}));

    dispatch(popStack());
  };
}

export function addEmptyFromCategories() {
  return (dispatch) => {
    dispatch(addPrescription({}));

    dispatch(popStack());

    dispatch(popStack());
  };
}

export function openItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(getTemplateDetails({ id }))
      .then((response) => {
        dispatch(addPrescription(response));

        if (response.medicationCategory && response.medicationCategory.id) {
          dispatch(
            getMedicationsByCategory({
              categoryId: response.medicationCategory.id,
            }),
          );
        }

        dispatch(stopLoader());

        dispatch(
          pushStack({
            code: prescriptionStackCodes.edit,
          }),
        );
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function addItem(id, count) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(getTemplateDetails({ id }))
      .then((response) => {
        dispatch(addPrescription(response));

        if (response.medicationCategory && response.medicationCategory.id) {
          dispatch(
            getMedicationsByCategory({
              categoryId: response.medicationCategory.id,
            }),
          );
        }

        dispatch(stopLoader());

        while (count--) {
          dispatch(popStack());
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function addPrescription(prescription) {
  return {
    type: ActionType.SIDE_PRESCRIPTION_ADD_PRESCRIPTION,
    prescription,
  };
}

export function syncForm(prescriptions) {
  return (dispatch) => {
    const newPrescriptions = prescriptions.map((item) => {
      return {
        ...item,
        genericSubstitution: item.generic === "generic",
        rout: item.route ? { id: item.route.value } : undefined,
        medication: item.drug ? { id: item.drug.value } : undefined,
        expirationDate: item.expirationDate && item.expirationDate.valueOf(),
        routFrequency: item.frequency ? { id: item.frequency.value } : undefined,
        medicationCategory: item.drugCategory ? { id: item.drugCategory.value } : undefined,
        dispenseMedicationUnit: item.dispenseMedicationUnit
          ? { id: item.dispenseMedicationUnit.value }
          : undefined,
      };
    });

    dispatch({
      prescriptions: newPrescriptions,
      type: ActionType.SIDE_PRESCRIPTION_SYNC_FORM,
    });
  };
}

export function initList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { sortField, searchValue },
    } = getState();

    const data = {
      filterKey: searchValue,
      patientKey,
      sortField,
      limit: 25,
    };

    dispatch(getList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function initTemplates() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { stack, searchValueTemplates },
    } = getState();

    const stackItem = _.last(stack);

    const data = {
      filterKey: searchValueTemplates,
      patientKey,
      categoryId: stackItem.id,
    };

    dispatch(getTemplates(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { sortField, list, listTotalCount, searchValue },
    } = getState();

    if (listTotalCount > list.length) {
      const data = {
        filterKey: searchValue,
        patientKey,
        sortField,
        limit: 25,
        start: list.length,
      };

      dispatch(getList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_SELECT_SORT_FIELD,
      code,
    });

    dispatch(initList());
  };
}

export function removePrescription(item) {
  return (dispatch) => {
    // debugger;
    dispatch(removePrescriptionApi(item.id))
      .then((response) => {
        if (response) {
          dispatch(initList());
        }
      })
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function setSearchValue(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_SEARCH_CHANGE,
      value,
    });

    dispatch(initList());
  };
}

export function setSearchValueTemplates(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_SEARCH_TEMPLATES_CHANGE,
      value,
    });

    dispatch(initTemplates());
  };
}

export function deletePrescription(index, prescriptions) {
  return (dispatch) => {
    dispatch(syncForm(prescriptions));

    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_DELETE,
      index,
    });
  };
}

export function openDiagnosisModal() {
  return (dispatch, getState) => {
    const { prescriptions } = getState().chartSidePrescription;

    const errorMessage = validate(prescriptions);

    if (errorMessage) {
      return dispatch(showMessage(errorMessage));
    }

    dispatch(
      pushStack({
        code: prescriptionStackCodes.diagnosis,
      }),
    );
  };

  function validate(prescriptions) {
    for (let i = 0; i < prescriptions.length; i++) {
      const prescription = prescriptions[i];

      if (!prescription.medicationCategory || !prescription.medicationCategory.id) {
        return "Please, choose Drug category";
      }
      if (!prescription.medication || !prescription.medication.id) {
        return "Please, choose Drug";
      }
      if (!prescription.strengthValue) {
        return "Please, enter strength";
      }
      if (!(prescription.amount > 0)) {
        return "Please, enter amount";
      }
      if (!prescription.rout || !prescription.rout.id) {
        return "Please, choose Rout";
      }
      if (!prescription.routFrequency || !prescription.routFrequency.id) {
        return "Please, choose Frequency";
      }
      if (!(prescription.dispense >= 0)) {
        return "Please, enter dispense";
      }
      if (!prescription.dispenseMedicationUnit || !prescription.dispenseMedicationUnit.id) {
        return "Please, choose Dispense unit";
      }
    }

    return false;
  }
}

export function initDiagnosisList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { searchValueDiagnosis },
    } = getState();

    dispatch(
      getDiagnosisList({
        filterKey: searchValueDiagnosis,
        patientKey,
        limit: 50,
      }),
    ).catch((error) => {
      dispatch(showMessage(error));
    });
  };
}

export function loadDiagnosisList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { diagnosisList, diagnosisListTotalCount, searchValueDiagnosis },
    } = getState();

    if (diagnosisListTotalCount > diagnosisList.length) {
      const data = {
        filterKey: searchValueDiagnosis,
        patientKey,
        limit: 50,
        start: diagnosisList.length,
      };

      dispatch(getDiagnosisList(data)).catch((error) => {
        dispatch(showMessage(error));
      });
    }
  };
}

export function setDiagnosisSearchValue(value) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SIDE_PRESCRIPTION_DIAGNOSIS_SEARCH_CHANGE,
      value,
    });

    dispatch(initDiagnosisList());
  };
}

export function selectDiagnosis(id) {
  return {
    type: ActionType.SIDE_PRESCRIPTION_SELECT_DIAGNOSIS,
    id,
  };
}

export function toggleDiagnosis(id) {
  return {
    type: ActionType.SIDE_PRESCRIPTION_TOGGLE_DIAGNOSIS,
    id,
  };
}

export function setPrimary(id) {
  return {
    type: ActionType.SIDE_PRESCRIPTION_SET_PRIMARY,
    id,
  };
}

export function savePrescription(name) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chart: {
        patient: { patientKey },
      },
      chartSidePrescription: { prescriptions, primaryDiagnosis, secondaryDiagnosis },
    } = getState();

    const secondary = Utils.objectToArray(secondaryDiagnosis, (item, key) => ({
      id: key,
    }));

    let prescriptionTemplate;

    const formatPrescriptions = prescriptions.map(({ drugCategory, drug, ...x }) => ({
      ...x,
      genericSubstitution: x.generic === "generic",
      route: x.route ? { id: x.route.value } : undefined,
      medication: drug ? { id: drug.value } : undefined,
      frequency: x.frequency ? { id: x.frequency.value } : undefined,
      medicationCategory: drugCategory ? { id: drugCategory.value } : undefined,
      drugGeneric: x.drugGeneric ? { id: x.drugGeneric.value } : undefined,
      dispenseMedicationUnit: x.dispenseMedicationUnit
        ? { id: x.dispenseMedicationUnit.id }
        : undefined,
      expirationDate: x.expirationDate ? x.expirationDate : undefined,
    }));

    if (formatPrescriptions[0].id) {
      prescriptionTemplate = {
        id: formatPrescriptions[0].id,
      };
    }

    const transfer = {
      prescriptions: formatPrescriptions,
      principalDiagnosis: {
        id: primaryDiagnosis,
      },
      name,
      prescription: formatPrescriptions[0],
      patientKey,
      secondaryDiagnosis: secondary,
      prescriptionTemplate,
    };

    dispatch(saveItem(transfer))
      .then(() => {
        dispatch(stopLoader());

        dispatch({
          type: ActionType.SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION,
        });
        dispatch({
          type: MEDICATION_CLEAR_NEW_LIST,
        });

        dispatch(popStack());
        dispatch(popStack());
        dispatch(popStack());
        dispatch(popStack());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function sendEmail() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chartSidePrescription: { stack },
    } = getState();

    const stackItem = _.last(stack);

    const transfer = {
      prescriptionId: stackItem.item.id,
    };

    dispatch(sendToEmail(transfer))
      .then(() => {
        dispatch(stopLoader("Email has been sent"));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getPrescriptionGroupsApi(data) {
  return {
    api: PatientApi.prescription.getTemplatesGroup,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_LIST_START,
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_LIST_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_LIST_ERROR,
    ],
    data,
  };
}

function getPrescriptionGroupTemplatesApi(data) {
  return {
    api: PatientApi.prescription.getTemplatesWithGroup,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_START,
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_ERROR,
    ],
    data,
  };
}

function sendToEmail(data) {
  return {
    api: PatientApi.prescription.sendToEmail,
    types: [
      ActionType.SIDE_PRESCRIPTION_SEND_TO_EMAIL_START,
      ActionType.SIDE_PRESCRIPTION_SEND_TO_EMAIL_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_SEND_TO_EMAIL_ERROR,
    ],
    data,
  };
}

function saveItem(data) {
  return {
    api: PatientApi.prescription.saveItem,
    types: [
      ActionType.SIDE_PRESCRIPTION_SAVE_ITEM_START,
      ActionType.SIDE_PRESCRIPTION_SAVE_ITEM_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_SAVE_ITEM_ERROR,
    ],
    data,
  };
}

function getDiagnosisList(data) {
  return {
    api: DiagnosisApi.getList,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_START,
      ActionType.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_ERROR,
    ],
    data,
  };
}

function getList(data) {
  return {
    api: PatientApi.prescription.getList,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_LIST_START,
      ActionType.SIDE_PRESCRIPTION_GET_LIST_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_LIST_ERROR,
    ],
    data,
  };
}

function removePrescriptionApi(id) {
  return {
    api: PatientApi.prescription.deleteItem,
    types: [
      ActionType.SIDE_PRESCRIPTION_DELETE_ITEM_START,
      ActionType.SIDE_PRESCRIPTION_DELETE_ITEM_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_DELETE_ITEM_ERROR,
    ],
    data: {
      id,
    },
  };
}

function getTemplates(data) {
  return {
    api: PatientApi.prescription.getTemplates,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATES_START,
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATES_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATES_ERROR,
    ],
    data,
  };
}

export function getCategoryList(data) {
  return {
    api: PatientApi.prescription.getCategoryList,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_CATEGORY_LIST_START,
      ActionType.SIDE_PRESCRIPTION_GET_CATEGORY_LIST_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

export function getFormData(data) {
  return {
    api: PatientApi.prescription.getFormData,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_FORM_DATA_START,
      ActionType.SIDE_PRESCRIPTION_GET_FORM_DATA_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_FORM_DATA_ERROR,
    ],
    data,
  };
}

function getTemplateDetails(data) {
  return {
    api: PatientApi.prescription.getTemplateDetails,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_START,
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_TEMPLATE_DETAILS_ERROR,
    ],
    data,
  };
}

export function getMedicationsByCategory(data) {
  return {
    api: MedicationApi.getByCategory,
    types: [
      ActionType.SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_START,
      ActionType.SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_SUCCESS,
      ActionType.SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_ERROR,
    ],
    data,
  };
}
