import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment/moment";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import Utils from "../../../helpers/Utils";
import {
  deleteLabOrderItem,
  getLabOrderItem,
  getLabOrderList,
  pushStack,
} from "../../../actions/orderActions";
import { Button } from "../../ui/Button";
import { Modal } from "../../ui/Modal";
import { orderStackCodes } from "../../../constants/chart-sidebar/orderStackCodes";
import orderItemTypes from "../../../constants/chart-sidebar/orderItemTypes";
import LabOrderSearchForm from "./LabOrderSearchForm";
import { parseQuery } from "../../../utils/UrlUtils";
import { withRouter } from "react-router";

const LabOrderList = ({ chartSideOrder, location }) => {
  const query = parseQuery(location.search);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const { labOrderList, labOrderFilterForm: values } = chartSideOrder;
  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight;
    if (scrollBottom) {
      const transfer = {
        patientKey: query.patientKey,
        id: values.id,
        clinicId: values.clinic?.value,
        doctorId: values.doctor?.value === -1 ? null : values.doctor?.value,
        doctorStaffId: values.doctorStaff?.value === -1 ? null : values.doctorStaff?.value,
        statusCode: values.status?.value === -1 ? null : values.status?.value,
        subTypeId: values.orderType?.value === -1 ? null : values.orderType?.value,
      };
      dispatch(getLabOrderList(transfer));
    }
  };
  const deleteHandler = () => {
    setDeleteModalVisible(false);
    dispatch(deleteLabOrderItem(selectedItem));
    setSelectedId(null);
  };
  return (
    <>
      <Modal
        size="sm"
        show={deleteModalVisible}
        actions={
          <>
            <Button
              onClick={() => {
                setDeleteModalVisible(false);
                setSelectedId(null);
              }}
            >
              No
            </Button>
            <Button onClick={deleteHandler}>Yes</Button>
          </>
        }
      >
        <span>Are you sure to delete this item?</span>
      </Modal>
      <LabOrderSearchForm query={query} />
      <div onScroll={loadList} style={{ height: "100%", overflow: "auto", paddingBottom: "80px" }}>
        <table className="table table-hover bring-earlier-table">
          <thead>
            <tr
              style={{
                borderTop: "1px solid rgb(204, 204, 204)",
              }}
            >
              <th style={{ verticalAlign: "middle" }}>Id</th>
              <th style={{ verticalAlign: "middle" }}>Status</th>
              <th style={{ verticalAlign: "middle" }}>Chart#</th>
              <th style={{ verticalAlign: "middle" }}>Patient</th>
              <th style={{ verticalAlign: "middle" }}>Ordering Doctor</th>
              <th style={{ verticalAlign: "middle" }}>Modified Doctor</th>
              <th style={{ verticalAlign: "middle" }}>Lab</th>
              <th style={{ verticalAlign: "middle" }}>Report Conducted</th>
              <th style={{ verticalAlign: "middle" }}>Due Date</th>
              <th style={{ verticalAlign: "middle" }}>Created Date</th>
              <th style={{ verticalAlign: "middle" }}>Modified Date</th>
              <th style={{ verticalAlign: "middle" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(labOrderList || []).map((x) => {
              const createdDate = moment(x.createdDate).format(DATE_FORMAT_LEGACY);
              const modifiedDate = moment(x.modifiedDate).format(DATE_FORMAT_LEGACY);
              const dueDate = moment(x.dueDate).format(DATE_FORMAT_LEGACY);
              const patientName = Utils.getFullName(x.patientLab);
              const patientChartNumber = _.get(x, "patientLab.chartNumber");
              const status = _.get(x, "status.name", "-");

              return (
                <tr key={x.id} style={{ backgroundColor: "#ff6666", color: "#ffffff" }}>
                  <td style={{ verticalAlign: "middle", width: "100px" }}>{x.id}</td>
                  <td style={{ verticalAlign: "middle" }}>{status}</td>
                  <td style={{ verticalAlign: "middle" }}>{patientChartNumber}</td>
                  <td style={{ verticalAlign: "middle", minWidth: "200px" }}>{patientName}</td>
                  <td style={{ verticalAlign: "middle", minWidth: "200px" }}>{x.dentist.name}</td>
                  <td style={{ verticalAlign: "middle", minWidth: "200px" }}>
                    {x?.modifiedMember?.name || ""}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{x?.externalLab?.name}</td>
                  <td style={{ verticalAlign: "middle" }}>{x.reportConducted ? "Yes" : "No"}</td>
                  <td style={{ verticalAlign: "middle" }}>{dueDate}</td>
                  <td style={{ verticalAlign: "middle" }}>{createdDate}</td>
                  <td style={{ verticalAlign: "middle" }}>{modifiedDate}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {
                      <div className="d-flex">
                        <button
                          className="btn btn-default mr-2"
                          onClick={() => {
                            dispatch(
                              pushStack({ code: orderStackCodes.item, type: orderItemTypes.edit }),
                            );
                            dispatch(getLabOrderItem(x.id));
                          }}
                        >
                          View
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setSelectedId(x.id);
                            setDeleteModalVisible(true);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default withRouter(LabOrderList);
