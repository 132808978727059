import { httpClient } from "./BaseApi";

const BLOCKED_TIME_SLOT_ITEM_URL = "api/clinic/blockedtimeslot/item.json";
const BLOCKED_TIME_SLOT_LIST_URL = "api/clinic/blockedtimeslot/list.json";
const BLOCKED_TIME_SLOT_FORM_DATA_URL = "api/clinic/blockedtimeslot/form-data.json";
const BLOCKED_TIME_SLOT_TEMPLATE_LIST_URL = "api/clinic/blockedtimeslot/template/list.json";
const BLOCKED_TIME_SLOT_TEMPLATE_ITEM_URL = "api/clinic/blockedtimeslot/template/list.json";
const BLOCKED_TIME_SLOT_TYPE_COLORS = "/api/clinic/blockedtimeslot/types/choose.json";

const BlockedTimeSlotApi = {
  editItem: (data) => httpClient.put(BLOCKED_TIME_SLOT_ITEM_URL, { data }),
  addItem: (data) => httpClient.post(BLOCKED_TIME_SLOT_ITEM_URL, { data }),
  getList: (params) => httpClient.get(BLOCKED_TIME_SLOT_LIST_URL, { params }),
  getTemplateList: (params) => httpClient.get(BLOCKED_TIME_SLOT_TEMPLATE_LIST_URL, { params }),
  getTemplateItem: (params) => httpClient.get(BLOCKED_TIME_SLOT_TEMPLATE_ITEM_URL, { params }),
  getItem: (params) => httpClient.get(BLOCKED_TIME_SLOT_ITEM_URL, { params }),
  getFormData: (params) => httpClient.get(BLOCKED_TIME_SLOT_FORM_DATA_URL, { params }),
  deleteItem: (params) => httpClient.delete(BLOCKED_TIME_SLOT_ITEM_URL, { params }),
  getBtsTypeColors: (params) => httpClient.get(BLOCKED_TIME_SLOT_TYPE_COLORS, { params }),
};

export default BlockedTimeSlotApi;
