import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import { FormControl, FormControlProps } from "react-bootstrap";

import { Theme } from "../../dto/AppDTO";
import { hexToRgba } from "../../utils/FormatUtils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.form-control-lg": {
      height: "48px",
      padding: "8px 16px",
      borderRadius: "6px",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${hexToRgba(theme.palette.primary.main, {
        opacity: 0.6,
      })}`,
    },
  },
}));

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "size" | "value"
> &
  FormControlProps;

export function TextInput({ className, ...props }: TextInputProps) {
  const classes = useStyles();

  return <FormControl autoComplete="off" {...props} className={cx(classes.root, className)} />;
}
