import React from "react";
import AddPhotoImage from "../../assets/images/chart/addPhoto.png";
import DeleteImage from "../../assets/images/delete.png";

const BASE64_MARKER = ";base64,";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Index = reader.result.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      resolve(reader.result.substring(base64Index));
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });

export default class FilePicker extends React.Component {
  file;

  onChange = (event) => {
    getBase64(event.target.files[0]).then((base64) => this.props.onChange(base64));
  };

  render() {
    return (
      <div className={this.props.className}>
        <label>
          <img
            src={this.props.value ? `data:image/jpg;base64,${this.props.value}` : AddPhotoImage}
            alt=""
          />
          <input
            type="file"
            ref={(node) => {
              this.file = node;
            }}
            onChange={this.onChange}
            style={{ display: "none" }}
          />
        </label>
        <button
          className="btn btn-link"
          onClick={() => {
            this.props.onChange("");
            this.file.value = "";
          }}
        >
          <img alt={""} src={DeleteImage} />
        </button>
      </div>
    );
  }
}
