import React from "react";
import ReactDOM from "react-dom";

export default class TemporaryPatientForm extends React.Component {
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside.bind(this), true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this), true);
  }

  handleClickOutside(event) {
    if (this.props.temporaryResult) {
      // eslint-disable-next-line react/no-find-dom-node
      const domNode = ReactDOM.findDOMNode(this);
      if (!domNode || !domNode.contains(event.target)) {
        this.props.hideResult();
      }
    }
  }

  addPatient = () => {
    this.props.hideResult();
    this.props.addPatient();
  };

  selectPatient = (item) => {
    this.props.hideResult();
    this.props.selectPatient(item);
  };

  render() {
    const { temporaryPatient } = this.props;

    let resultClassName = "temporary-result";

    if (!this.props.temporaryResult) resultClassName += " hidden";

    return (
      <div className={resultClassName}>
        <div className="arrow" />
        <h5 className="temporary-result-title text-center">
          Patient(s) assosiated with this number
        </h5>
        <div className="temporary-result-body">
          {temporaryPatient.map((item) => (
            <div
              className="temporary-result-body-item"
              key={item.id || item.companyTempPatientId}
              onClick={() => this.selectPatient(item)}
            >
              <div className="item-name">
                <span>Name:</span>
                <span>{item.fullname}</span>
              </div>
            </div>
          ))}
        </div>
        <button onClick={this.addPatient} className="btn temporary-result-button">
          Add as Temporary Patient
        </button>
      </div>
    );
  }
}
