import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import Show from "../widgets/Show";
import bindActions from "../../helpers/bindActions";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import PatientAppointmentsListReasonModal from "./PatientAppointmentsListReasonModal";
import { getCompanyFormatDate, getCompanyFormatTime } from "../../helpers/DateUtils";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import NoteImage from "../../assets/images/notes@2x.png";
import LogImage from "../../assets/images/log-file-format.png";
import AppointmentImage from "../../assets/images/profile/appointments.png";
import { AppLayout } from "../app-layout/AppLayout";
import { Switch } from "../ui/Switch";

const enhancer = connect(
  ({ session: { member }, patientRegister }) => ({ patientRegister, member }),
  bindActions({ patientRegisterActions }),
);
class PatientAppointmentsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onlyCompleted: false,
    };
  }

  componentDidMount() {
    const { onlyCompleted } = this.state;
    const { patientRegisterActions, location } = this.props;
    const query = parseQuery(location.search);

    this.getList(onlyCompleted);

    patientRegisterActions.loadTotalCancelledMissedCompletedReleasedAppTList(
      query.patientKey || this.props.patientKey,
    );
  }

  getList = (onlyCompleted) => {
    const { patientRegisterActions, location } = this.props;

    const query = parseQuery(location.search);

    patientRegisterActions.getPatientAppointmentsList(
      query.patientKey || this.props.patientKey,
      onlyCompleted ? STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE : undefined,
    );
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      const { onlyCompleted } = this.state;
      const { patientRegisterActions, location } = this.props;

      const query = parseQuery(location.search);

      patientRegisterActions.loadPatientAppointmentsList(
        query.patientKey || this.props.patientKey,
        onlyCompleted ? STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE : undefined,
      );
    }
  };

  switchCompleted = (onlyCompleted) => {
    this.setState({ onlyCompleted });

    this.getList(onlyCompleted);
  };

  render() {
    const { onlyCompleted } = this.state;
    const {
      appointmentsList,
      fetchingAppointmentsList,
      patientInfo,
      appointmentsReasonModalVisible,
      appointmentsLogModalIndex,
      appointmentsReasonModalId,
      appointmentsReasonModalData,
      appointmentsReasonModalMode,
      appointmentUpcomingTotalCount,
      appointmentMissedTotalCount,
      appointmentCompleteTotalCount,
      appointmentAllTotalCount,
      appointmentWithoutLateCancelledTotalCount,
    } = this.props.patientRegister;

    const list = (appointmentsList || []).map((element, index) => {
      // const hasNote = Boolean(element.note);
      const communicationStatus = element.noAnswer
        ? "No answer"
        : element.notInterested
        ? "Not interested"
        : element.willCall
        ? "Will call back"
        : "";
      // noAnswer:false
      // notInterested:false
      // willCall:false
      return (
        <div className="appointments-list-row" key={element.id}>
          <div>{_.upperCase((element.category && element.category.name) || "")}</div>
          <div>{_.get(element, "clinic.name", "")}</div>
          <div>{(element.dentist && element.dentist.name) || ""}</div>
          <div>{element.date ? getCompanyFormatDate(element.date) : ""}</div>
          <div>{element.date ? getCompanyFormatTime(element.date) : ""}</div>
          <div>{(element.meetingType && element.meetingType.name) || ""}</div>
          <div>{communicationStatus}</div>
          <div>{_.upperCase(element.status.name || "")}</div>
          {/*<div className={cx("note-status", { "text-danger": !hasNote })}>*/}
          {/*  {hasNote ? "DONE" : "MISSING"}*/}
          {/*</div>*/}
          <div className="log-buttons">
            <button
              type="button"
              onClick={() => {
                this.props.patientRegisterActions.appointmentsReasonModalShow(
                  element.id,
                  "log",
                  index,
                );
              }}
              className="btn btn-info button-doctors"
              style={{ padding: 0 }}
            >
              <img src={LogImage} style={{ zoom: "50%" }} alt="" width={34} height={34} />
            </button>
            <button
              type="button"
              className="btn btn-info button-notes"
              style={{ padding: 0 }}
              onClick={() => {
                this.props.patientRegisterActions.appointmentsReasonModalShow(
                  element.id,
                  "reason",
                  index,
                );
              }}
            >
              <img src={NoteImage} style={{ zoom: "50%" }} alt="" width={34} height={34} />
            </button>
            <button
              type="button"
              className="btn btn-info button-appointment"
              style={{ padding: 0 }}
              onClick={() => {
                this.props.history.push(
                  createUrl(Routes.DashboardAppointment, { params: { id: element.id } }),
                );
              }}
            >
              <img src={AppointmentImage} style={{ zoom: "50%" }} alt="" width={34} height={34} />
            </button>
          </div>
        </div>
      );
    });

    const query = parseQuery(this.props.location.search);

    let patientData = patientInfo.firstName || "";
    const lastName = patientInfo.lastName || "";
    const chartNumber = patientInfo.chartNumber || "";

    patientData += lastName !== "" ? ` ${lastName}` : "";
    patientData += patientData !== "" && chartNumber !== "" ? ", " : "";
    patientData += chartNumber !== "" ? `#${chartNumber}` : "";

    return (
      <AppLayout
        onBackClick={() => {
          if (this.props.onBackClick) {
            return this.props.onBackClick();
          }
          if (query.appointmentForm === "true") {
            this.props.history.push(
              createUrl(Routes.DashboardAppointment, {
                params: { id: query.appointmentId },
                query: {
                  ...query,
                  patientKey: query.patientKey || this.props.patientKey,
                },
              }),
            );
          } else {
            this.props.history.goBack();
          }
        }}
        contentClassName="patient-appointments-list"
        rightComponentClassName="btn-user-name"
        withLogo={true}
        rightComponent={patientData}
      >
        <div className="complete-switcher-container d-flex">
          <label className="mt-3 flex-grow-1">
            <span className="text-black mr-2">Show completed only</span>

            <Switch checked={onlyCompleted} onChange={(x) => this.switchCompleted(x)} />
          </label>
          <div
            className="row"
            style={{
              flex: 6,
              display: "flex",
              marginTop: "1rem",
              fontWeight: "bold",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-sm-3">
              <p className="mb-0">
                Total Cancelled:{" "}
                <b className="text-black">{appointmentWithoutLateCancelledTotalCount}</b>
              </p>
              <p className="mb-0">
                Total Missed: <b className="text-black">{appointmentMissedTotalCount}</b>
              </p>
              <p className="mb-0">
                Total Complete: <b className="text-black">{appointmentCompleteTotalCount}</b>
              </p>
            </div>
            <div className="col-sm-3">
              <p className="mb-0">
                Upcoming: <b className="text-black">{appointmentUpcomingTotalCount}</b>
              </p>
              <p className="mb-0">
                Total Release: <b className="text-black">{appointmentAllTotalCount}</b>
              </p>
            </div>
          </div>
        </div>
        <div className="appointments-list-table">
          <div className="appointments-list-header">
            <div>Appointment type</div>
            <div>Clinic</div>
            <div>{localStorage.getItem("doctorLabel")}</div>
            <div>Date</div>
            <div>Time</div>
            <div>Meeting type</div>
            <div>Communication status</div>
            <div>Status</div>
            {/*<div>Clinical Notes</div>*/}
            <div>&nbsp;</div>
          </div>
          <div className="appointments-list-body" onScroll={this.loadList}>
            {list}
            <Show if={fetchingAppointmentsList}>
              <ReactSpinner />
            </Show>
          </div>
        </div>
        <Show if={appointmentsReasonModalVisible}>
          <PatientAppointmentsListReasonModal
            show={appointmentsReasonModalVisible}
            appointmentsReasonModalData={appointmentsReasonModalData}
            mode={appointmentsReasonModalMode}
            appointmentsList={appointmentsList}
            index={appointmentsLogModalIndex}
            id={appointmentsReasonModalId}
          />
        </Show>
      </AppLayout>
    );
  }
}

export default withRouter(enhancer(PatientAppointmentsList));
