import _ from "lodash";
import React from "react";

import FlexBox from "../../ui/FlexBox";
import { SvgIcon } from "../../widgets/SvgIcon";
import dropPinkImage from "../../../assets/images/chart/perio-exam/perio_exam_4_drops_pink@2x.png";
import dropRedImage from "../../../assets/images/chart/perio-exam/perio_exam_4_drops_red@2x.png";
import dropYellowImage from "../../../assets/images/chart/perio-exam/perio_exam_4_drops_yellow@2x.png";

export default function PerioExamLinearChartMain({ top, ...props }) {
  const PD_Distal = _.toFinite(_.get(props, "item.probingDepthDistal", 0));
  const GM_Distal = _.toFinite(_.get(props, "item.gingivalMarginDistal", 0));
  const CA_Distal = _.toFinite(_.get(props, "item.clinicalAttachmentDistal", 0));

  const PD_Central = _.toFinite(_.get(props, "item.probingDepthCentral", 0));
  const GM_Central = _.toFinite(_.get(props, "item.gingivalMarginCentral", 0));
  const CA_Central = _.toFinite(_.get(props, "item.clinicalAttachmentCentral", 0));

  const PD_Mesial = _.toFinite(_.get(props, "item.probingDepthMesial", 0));
  const GM_Mesial = _.toFinite(_.get(props, "item.gingivalMarginMesial", 0));
  const CA_Mesial = _.toFinite(_.get(props, "item.clinicalAttachmentMesial", 0));

  const bleedingDistal = _.get(props, "item.bleedingDistal");
  const bleedingCentral = _.get(props, "item.bleedingCentral");
  const bleedingMesial = _.get(props, "item.bleedingMesial");

  const suppurationDistal = _.get(props, "item.suppurationDistal");
  const suppurationCentral = _.get(props, "item.suppurationCentral");
  const suppurationMesial = _.get(props, "item.suppurationMesial");

  const bledingOrSuppuration =
    bleedingDistal ||
    bleedingCentral ||
    bleedingMesial ||
    suppurationDistal ||
    suppurationCentral ||
    suppurationMesial;

  const furcationStatus = _.get(props, "item.furcationStatus");

  const orangeFirstPoint = 98 - (PD_Distal + GM_Distal) * 5 + 5;
  const orangeSecondPoint = 98 - (PD_Central + GM_Central) * 5 + 5;
  const orangeThirdPoint = 98 - (PD_Mesial + GM_Mesial) * 5 + 5;

  const redFirstPoint = 98 - PD_Distal * 5 + 5;
  const redSecondPoint = 98 - PD_Central * 5 + 5;
  const redThirdPoint = 98 - PD_Mesial * 5 + 5;

  const greenFirstPoint = 98 - CA_Distal * 5 + 5;
  const greenSecondPoint = 98 - CA_Central * 5 + 5;
  const greenThirdPoint = 98 - CA_Mesial * 5 + 5;
  let furcationImage;
  if (furcationStatus) {
    furcationImage = require(`../../../assets/images/chart/perio-exam/fucation-${furcationStatus}@2x.png`)
      .default;
  }

  return (
    <>
      <div
        style={{
          left: 0,
          right: 0,
          position: "absolute",
          top: `${top ? "25px" : "5px"}`,
          display: "flex",
          transform: top ? "" : "rotate(180deg) translateY(-80px)",
        }}
      >
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="orangered"
            strokeLinejoin="round"
            d={`M0 98 H5 L10  98 L40 98  L70  98 L75 98 H80`}
          />
        </SvgIcon>
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="orangered"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 ${redFirstPoint > 98 ? 98 : redFirstPoint} L40 ${
              redSecondPoint > 98 ? 98 : redSecondPoint
            } L70 ${redThirdPoint > 98 ? 98 : redThirdPoint} L75 98 H80`}
          />
        </SvgIcon>
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="orangered"
            strokeLinejoin="round"
            d={`M0 98 H5 L10  98 L40 98  L70  98 L75 98 H80`}
          />
        </SvgIcon>
      </div>
      <div
        style={{
          left: 0,
          right: 0,
          position: "absolute",
          top: `${top ? "25px" : "5px"}`,
          display: "flex",
          transform: top ? "" : "rotate(180deg) translateY(-80px)",
        }}
      >
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="forestgreen"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 98 L40 98 L70 98 L75 98 H80`}
          />
        </SvgIcon>{" "}
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="forestgreen"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 ${greenFirstPoint > 98 ? 98 : greenFirstPoint} L40 ${
              greenSecondPoint > 98 ? 98 : greenSecondPoint
            } L70 ${greenThirdPoint > 98 ? 98 : greenThirdPoint} L75 98 H80`}
          />
        </SvgIcon>{" "}
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="forestgreen"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 98 L40 98 L70 98 L75 98 H80`}
          />
        </SvgIcon>
      </div>
      <div
        style={{
          left: 0,
          right: 0,
          position: "absolute",
          top: `${top ? "25px" : "5px"}`,
          display: "flex",
          transform: top ? "" : "rotate(180deg) translateY(-80px)",
          zIndex: 999999999999,
        }}
      >
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="darkorange"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 98 L40 98 L70 98 L75 98 H80`}
          />
        </SvgIcon>
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="darkorange"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 ${orangeFirstPoint > 98 ? 98 : orangeFirstPoint} L40 ${
              orangeSecondPoint > 98 ? 98 : orangeSecondPoint
            } L70 ${orangeThirdPoint > 98 ? 98 : orangeThirdPoint} L75 98 H80`}
          />
        </SvgIcon>
        <SvgIcon width={80} height={100}>
          <path
            fill="none"
            strokeWidth="3"
            stroke="darkorange"
            strokeLinejoin="round"
            d={`M0 98 H5 L10 98 L40 98 L70 98 L75 98 H80`}
          />
        </SvgIcon>
      </div>
      {Boolean(furcationStatus) && (
        <div
          style={{
            left: 0,
            right: 0,
            height: "52px",
            display: "flex",
            position: "absolute",
            alignItems: "baseline",
            justifyContent: "center",
            top: `${top ? "unset" : "28px"}`,
            bottom: "39px",
            transform: `${top ? "unset" : "rotateX(180deg)"}`,
          }}
        >
          <img src={furcationImage} width={28} alt="" />
        </div>
      )}
      {Boolean(bledingOrSuppuration) && (
        <div
          style={{
            left: 0,
            right: 0,
            display: "flex",
            position: "absolute",
            alignItems: "baseline",
            justifyContent: "center",
            top: `${top ? "unset" : "28px"}`,
            bottom: `${top ? 30 : 54}px`,
            transform: `${top ? "unset" : "rotateX(180deg)"}`,
            zIndex: 100000000000,
          }}
        >
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} justify="flex-end">
              {bleedingDistal && suppurationDistal ? (
                <img src={dropPinkImage} width={9} height={18} alt="" />
              ) : bleedingDistal ? (
                <img src={dropRedImage} width={9} height={18} alt="" />
              ) : suppurationDistal ? (
                <img src={dropYellowImage} width={9} height={18} alt="" />
              ) : null}
            </FlexBox>
            <FlexBox justify="center">
              {bleedingCentral && suppurationCentral ? (
                <img src={dropPinkImage} width={9} height={18} alt="" />
              ) : bleedingCentral ? (
                <img src={dropRedImage} width={9} height={18} alt="" />
              ) : suppurationCentral ? (
                <img src={dropYellowImage} width={9} height={18} alt="" />
              ) : null}
            </FlexBox>
            <FlexBox flex={true}>
              {bleedingMesial && suppurationMesial ? (
                <img src={dropPinkImage} width={9} height={18} alt="" />
              ) : bleedingMesial ? (
                <img src={dropRedImage} width={9} height={18} alt="" />
              ) : suppurationMesial ? (
                <img src={dropYellowImage} width={9} height={18} alt="" />
              ) : null}
            </FlexBox>
          </FlexBox>
        </div>
      )}
    </>
  );
}
