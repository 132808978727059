import * as ActionType from "../actionTypes/simpleModalActionTypes";

export function openSimpleModal(options) {
  return {
    type: ActionType.SIMPLE_MODAL_OPEN,
    ...options,
  };
}

export function closeSimpleModal() {
  return {
    type: ActionType.SIMPLE_MODAL_CLOSE,
  };
}
