import React, { useState, useRef, useEffect } from "react";

function MedicalFormCustomPopover({ children, content }) {
  const [isVisible, setIsVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  const togglePopover = () => {
    setIsVisible(!isVisible);

    setPlacement("right");

    // const buttonRect = buttonRef.current.getBoundingClientRect();
    // if (buttonRect.right + 200 > window.innerWidth) {
    //   setPlacement("left");
    // } else {
    //   setPlacement("right");
    // }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  return (
    <div className="med-form_popover">
      <button
        ref={buttonRef}
        className="popover-button"
        onClick={content?.trim()?.length > 0 ? togglePopover : null}
      >
        {children}
      </button>

      {isVisible && (
        <div ref={popoverRef} className={`med-form_popover_popover-content ${placement}`}>
          <div className="med-form_popover_popover-body">{content}</div>
        </div>
      )}
    </div>
  );
}

export default MedicalFormCustomPopover;
