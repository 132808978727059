import React from "react";

export default function ChartToothNumber(props) {
  const { normalDetails, numericKey, missedTooth } = props;

  let numeric = null;
  if (normalDetails) numeric = normalDetails[numericKey];
  if (missedTooth) numeric = missedTooth[numericKey];

  return <div className="numeric">{numeric}</div>;
}
