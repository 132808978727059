import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { Routes } from "../../constants/Routes";
import { Dropdown } from "../dropdown/Dropdown";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { SettingsPasswordDialog } from "../settings/SettingsPasswordDialog";
import PatientsImage from "../../assets/images/top-menu/patients@2x.png";
import { DropdownTopMenuToggle } from "../dropdown/DropdownTopMenuToggle";
import DashboardImage from "../../assets/images/top-menu/dashboard@2x.png";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { loggedIn as setLoggedIn, logoutApi } from "../../actions/authActions";
import AppointmentsImage from "../../assets/images/top-menu/appointments@2x.png";
import NotificationsImage from "../../assets/images/top-menu/notification@2x.png";
import WhatsappImage from "../../assets/images/top-menu/whatsapp1.png";
import {
  chosenMemberIdSelector,
  deviceExistSelector,
  loggedInSelector,
  membersSelector,
} from "../../reducers/authReducer";
import { resetCalendar } from "../../actions/calendarActions";
import Show from "../widgets/Show";

const enhancer = connect(
  // @ts-ignore
  ({ session: { permissions, whatsAppChatWithPatientActivated } }) => ({
    permissions,
    whatsAppChatWithPatientActivated,
  }),
  null,
);

function TopMenu(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedIn = useShallowEqualSelector(loggedInSelector);
  const members = useShallowEqualSelector(membersSelector);
  const deviceExist = useShallowEqualSelector(deviceExistSelector);
  const chosenMemberId = useShallowEqualSelector(chosenMemberIdSelector);

  const ref = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showMemberMenu, setShowMemberMenu] = useState(false);

  const canOpenMenu = useMemo(() => deviceExist && loggedIn, [deviceExist, loggedIn]);

  useOutsideClick({
    ref,
    active: showMenu,
    onClick: () => setShowMenu(false),
  });

  const member = useMemo(() => members[chosenMemberId], [chosenMemberId, members]);

  const logoutHandler = useCallback(() => {
    setShowMemberMenu(false);
    localStorage.removeItem("dentalPointsId");
    dispatch(resetCalendar());
    dispatch(startLoader());

    dispatch(logoutApi())
      .then(() => {
        dispatch(setLoggedIn(false));
        dispatch(stopLoader());
        history.push(Routes.Auth);
      })
      .catch(() => stopLoader());
  }, [dispatch, history]);

  const clearStorageHandler = useCallback(() => {
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }, []);
  return (
    <div
      ref={ref}
      role="button"
      className="top-menu"
      onClick={() => {
        if (canOpenMenu) {
          setShowMenu((x) => !x);
        }
      }}
    >
      <div style={{ position: "absolute", right: "16px" }}>
        {member && `${member.firstName} ${member.lastName}`}
      </div>
      <div className="top-menu-dots-container">
        <div className="top-menu-dots">
          <div className="top-menu-dot" />
          <div className="top-menu-dot" />
          <div className="top-menu-dot" />
        </div>
      </div>
      <div id="top-menu" className="black-menu" style={showMenu ? { top: 0 } : {}}>
        <div className="d-flex">
          <Link to={Routes.Dashboard} className="item">
            <img alt="" className="icon" src={DashboardImage} />
            <div className="text">Dashboard</div>
          </Link>
          <Show if={(props.permissions || []).indexOf("VIEW_PATIENT_LIST") >= 0}>
            <Link to={Routes.PatientList} className="item">
              <img alt="" className="icon" src={PatientsImage} />
              <div className="text">Patients</div>
            </Link>
          </Show>
          <Link to={Routes.DashboardCalendar} className="item">
            <img alt="" className="icon" src={AppointmentsImage} />
            <div className="text">Appointments</div>
          </Link>
        </div>
        <div className="d-flex">
          {props?.whatsAppChatWithPatientActivated && (
            <Link to={Routes.DashboardWhatsappChatting} className="item">
              <img className="icon" src={WhatsappImage} />
              <div className="text">Messages</div>
            </Link>
          )}
          <div className="item">
            <img alt="" className="icon" src={NotificationsImage} />
            <div className="text">Notifications</div>
          </div>
          <Dropdown
            show={showMemberMenu}
            onToggle={setShowMemberMenu}
            customToggleProps={{ member }}
            customToggle={DropdownTopMenuToggle}
          >
            <li>
              <Link to={Routes.DashboardProfile}>
                <div className="text">Profile</div>
              </Link>
            </li>
            <li>
              <Link to={Routes.Settings}>
                <div className="text">Settings</div>
              </Link>
            </li>
            <li>
              <a href="#" onClick={logoutHandler}>
                <div className="text">Log out</div>
              </a>
            </li>
          </Dropdown>
        </div>
      </div>
      <SettingsPasswordDialog
        show={showDialog}
        onClose={() => setShowDialog(false)}
        openIp={() => {
          clearStorageHandler();
          setShowDialog(false);
        }}
      />
    </div>
  );
}

export default enhancer(TopMenu);
