import { httpClient } from "./BaseApi";

const DASHBOARD_DATA_URL = "api/member/dashboard/item.json";
const GET_DENTIST_URL = "api/member/dashboard/dentist.json";
const GET_PROFILE_URL = "api/member/dashboard/profile.json";

const GET_REPORT_URL = "api/member/dashboard/doctor-production/report.json";
const GET_FORM_DATA_URL = "api/member/dashboard/doctor-production/form-data.json";

const DashboardApi = {
  getDentist: (params) => httpClient.get(GET_DENTIST_URL, { params }),
  getProfile: (params) => httpClient.get(GET_PROFILE_URL, { params }),
  getDashboardData: (params) => httpClient.get(DASHBOARD_DATA_URL, { params }),
  doctorProduction: {
    getReport: (params) => httpClient.get(GET_REPORT_URL, { params }),
    getFormData: (params) => httpClient.get(GET_FORM_DATA_URL, { params }),
  },
};

export default DashboardApi;
