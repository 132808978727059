import { isEmpty } from "lodash";

import {
  fromMomentToHHMMInMillisecond,
  getCompanyEndOfDay,
  getCompanyStartOfDay,
} from "./DateUtils";
import { YearlyTypes } from "../components/blocked-time-slot/BlockedTimeSlotYearlyForm";
import { MonthlyTypes } from "../components/blocked-time-slot/BlockedTimeSlotMonthlyForm";
import {
  RecurrenceEndType,
  RecurrenceTypes,
} from "../components/blocked-time-slot/BlockedTimeSlotForm";

export function formatBlockedTimeSlotItem(
  { points = [], members, weekDays = [], ...item },
  fullMembers,
) {
  const endDate = item.endDate ? getCompanyEndOfDay(item.endDate) : undefined;
  const untilDate = item.untilDate ? getCompanyEndOfDay(item.untilDate) : undefined;
  const startDate = item.startDate ? getCompanyStartOfDay(item.startDate) : undefined;
  const endTime = fromMomentToHHMMInMillisecond(item.endTime);
  const startTime = fromMomentToHHMMInMillisecond(item.startTime);
  const excludedDates = item.excludedDates
    ? item.excludedDates.map((x) => getCompanyStartOfDay(x)).filter(Boolean)
    : undefined;

  const allDoctorsList: any = (fullMembers || []).filter((item) => item.doctor).map(({ id }) => id);

  const membersList =
    !isEmpty(members) && members[0].value
      ? members.map(({ value }) => value)
      : members[0]?.label === "All doctors"
      ? allDoctorsList
      : undefined;

  const pointsList = !isEmpty(points) ? points.map(({ value }) => ({ id: value })) : undefined;

  const weekDaysList = !isEmpty(weekDays)
    ? weekDays.map(({ value }) => ({ code: value }))
    : undefined;
  const recurrenceType = { code: item.recurrenceType.value };
  const recurrenceEndType = { code: item.recurrenceEndType };
  const _timeSlotType = { id: item.timeSlotType.value, name: item.timeSlotType.label };

  const values: any = {
    id: item.id || null,
    endTime,
    untilDate,
    startTime,
    startDate,
    type: _timeSlotType,
    excludedDates,
    recurrenceType,
    name: item.name,
    recurrenceEndType,
    points: pointsList,
    reason: item.reason,
    members: membersList,
    reserved: item.reserved,
    clinicId: item.clinic.value,
  };

  const isRecurrenceTypeDaily = item.recurrenceType.value === RecurrenceTypes.Daily;
  const isRecurrenceTypeWeekly = item.recurrenceType.value === RecurrenceTypes.Weekly;
  const isRecurrenceTypeYearly = item.recurrenceType.value === RecurrenceTypes.Yearly;
  const isRecurrenceTypeMonthly = item.recurrenceType.value === RecurrenceTypes.Monthly;

  const isRecurrenceEndTypeEndDate = item.recurrenceEndType === RecurrenceEndType.EndDate;
  const isRecurrenceEndTypeCountLimit = item.recurrenceEndType === RecurrenceEndType.CountLimit;

  const customPattern =
    (isRecurrenceTypeMonthly && item.monthlyType === MonthlyTypes.The) ||
    (isRecurrenceTypeYearly && item.yearlyType === YearlyTypes.The);

  if (isRecurrenceTypeDaily && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      repeatCount: item.repeatCount,
    };
  }

  if (isRecurrenceTypeDaily && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      occurrences: item.occurrences,
      repeatCount: item.repeatCount,
    };
  }

  if (isRecurrenceTypeWeekly && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      weekDays: weekDaysList,
    };
  }

  if (isRecurrenceTypeWeekly && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      weekDays: weekDaysList,
      occurrences: item.occurrences,
    };
  }

  if (!customPattern && isRecurrenceTypeMonthly && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      customPattern,
      repeatCount: item.repeatCount,
      monthDayNumber: item.monthDayNumber,
    };
  }

  if (!customPattern && isRecurrenceTypeMonthly && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      customPattern,
      repeatCount: item.repeatCount,
      occurrences: item.occurrences,
      monthDayNumber: item.monthDayNumber,
    };
  }

  if (customPattern && isRecurrenceTypeMonthly && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      customPattern,
      repeatCount: item.repeatCount,
      yearWeekDay: item.yearWeekDay?.value ? { code: item.yearWeekDay.value } : undefined,
      yearWeekNumber: item.yearWeekNumber?.value ? { code: item.yearWeekNumber.value } : undefined,
    };
  }

  if (customPattern && isRecurrenceTypeMonthly && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      customPattern,
      occurrences: item.occurrences,
      repeatCount: item.repeatCount,
      yearWeekDay: item.yearWeekDay?.value ? { code: item.yearWeekDay } : undefined,
      yearWeekNumber: item.yearWeekNumber?.value ? { code: item.yearWeekNumber } : undefined,
    };
  }

  if (!customPattern && isRecurrenceTypeYearly && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      customPattern,
      repeatCount: item.repeatCount,
      yearMonth: item.yearMonth?.value ? { code: item.yearMonth.value } : undefined,
    };
  }

  if (!customPattern && isRecurrenceTypeYearly && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      customPattern,
      repeatCount: item.repeatCount,
      occurrences: item.occurrences,
      yearMonth: item.yearMonth?.value ? { code: item.yearMonth.value } : undefined,
    };
  }

  if (customPattern && isRecurrenceTypeYearly && isRecurrenceEndTypeEndDate) {
    return {
      ...values,
      endDate,
      customPattern,
      repeatCount: item.repeatCount,
      yearMonth: item.yearMonth?.value ? { code: item.yearMonth.value } : undefined,
      yearWeekDay: item.yearWeekDay?.value ? { code: item.yearWeekDay.value } : undefined,
      yearWeekNumber: item.yearWeekNumber?.value ? { code: item.yearWeekNumber.value } : undefined,
    };
  }

  if (customPattern && isRecurrenceTypeYearly && isRecurrenceEndTypeCountLimit) {
    return {
      ...values,
      customPattern,
      occurrences: item.occurrences,
      repeatCount: item.repeatCount,
      yearMonth: item.yearMonth?.value ? { code: item.yearMonth.value } : undefined,
      yearWeekDay: item.yearWeekDay?.value ? { code: item.yearWeekDay.value } : undefined,
      yearWeekNumber: item.yearWeekNumber?.value ? { code: item.yearWeekNumber.value } : undefined,
    };
  }

  return values;
}
