import React from "react";
import Utils from "../../../helpers/Utils";
import ChartTransactionFooter from "./ChartTransactionFooter";
import eyeImage from "../../../assets/images/chart/eye-icon-white@2x.png";
import eyeClosedImage from "../../../assets/images/chart/eye-closed-icon-white@2x.png";
import UnCheckedImage from "../../../assets/images/transaction/un_check_in.png";
import CheckedImage from "../../../assets/images/transaction/check_in.png";
import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as transactionActions from "../../../actions/transactionActions";

const enhancer = connect(
  ({ session, auth: { clinics } }) => ({
    session,
    clinics,
  }),
  bindActions({ transactionActions }),
);

function ChartTransactionLeftSIde(props) {
  const { transactionBtnClicked, leftButtons, eyeCheckedMap, changeEye } = props;
  const btnList = [];
  Utils.objectForEach(leftButtons, (value, key) => {
    if (value && value.order != -1 && value.value !== "Products") {
      value.name = key;
      value.className = `element ${value.value.toLowerCase()}`;
      btnList[value.order] = value;
    }
  });

  const eyeVisible = {
    "Trx Plan": false,
    Planned: true,
    Completed: true,
    Existing: true,
    Diagnosis: true,
    Products: false,
  };

  const $li = btnList.map((item) => (
    <li
      key={item.id}
      className={item.className + (item.selected ? " selected" : "")}
      style={{
        position: "relative",
      }}
      onClick={() => {
        transactionBtnClicked(item);
        // props.transactionActions.getAppointmentTransactions(false, true);
      }}
    >
      <img
        style={{
          right: "34px",
          bottom: "6px",
          position: "absolute",
        }}
        src={item.selected ? CheckedImage : UnCheckedImage}
        width={28}
        height={28}
        alt=""
      />
      {eyeVisible[item.value] && (
        <img
          src={eyeCheckedMap[item.value] ? eyeImage : eyeClosedImage}
          alt=""
          className="chart-transaction-eye zoom-2x"
          onClick={(event) => {
            event.stopPropagation();
            changeEye(item.value);
          }}
        />
      )}
      {item.value}
    </li>
  ));

  return (
    <div className="left-side">
      <ul className="list status-list">{$li}</ul>

      <ChartTransactionFooter
        elements={props.elements}
        stopClick={props.stopClick}
        permissions={props.permissions}
      />
    </div>
  );
}

export default enhancer(ChartTransactionLeftSIde);