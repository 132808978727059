import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";
import { Field, formValueSelector, reduxForm } from "redux-form";

import Show from "../../widgets/Show";
import { SelectPicker } from "../../select-picker/SelectPicker";

const valueSelector = formValueSelector("SaveAndCloseModal");

const enhancer = compose(
  reduxForm({ form: "SaveAndCloseModal" }),
  connect((state) => ({
    createReasonType: valueSelector(state, "createReasonType"),
  })),
);

function SaveAndCloseModal({ show, reasonList, closeModal, handleSubmit, createReasonType }) {
  const reasonOptions = reasonList.map((item) => ({
    label: item.name,
    value: item.code,
  }));

  return (
    <Modal
      size="small"
      className="choose-modal-save-temp"
      show={show}
      keyboard={false}
      onHide={closeModal}
      title={<div>Select Call Reason</div>}
      actions={
        <div>
          <Button form="SaveAndCloseModal" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <Button form="SaveAndCloseModal" type="submit">
            Save
          </Button>
        </div>
      }
    >
      <form
        name="SaveAndCloseModal"
        id="SaveAndCloseModal"
        className="save-and-close"
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label>Reason</label>
          <Field
            component={({ input }) => (
              <SelectPicker
                placeholder="Select Reason"
                options={reasonOptions}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="createReasonType"
            id="createReasonType"
          />
        </div>
        <Show if={createReasonType === "OTHER"}>
          <div className="form-group">
            <label>Description</label>
            <Field
              component="textarea"
              className="form-control field-consultation-note"
              rows="5"
              required={true}
              name="description"
            />
          </div>
        </Show>
      </form>
    </Modal>
  );
}

export default enhancer(SaveAndCloseModal);
