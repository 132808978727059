import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Show from "../../widgets/Show";
import Back from "../../widgets/Back";
import FilledImage from "../../widgets/FilledImage";
import ChartDocsConfirmDeleteModal from "./ChartDocsConfirmDeleteModal";

import bindActions from "../../../helpers/bindActions";
import * as chartSideDocsActions from "../../../actions/chartSideDocsActions";
import * as chartActions from "../../../actions/chartActions";
import downloadImage from "../../../assets/images/download.png";
import UIBarButtonItemGridImage from "../../../assets/images/docs/UIBarButtonItemGrid.png";
import UIBarButtonItemArrowOutlineLeftImage from "../../../assets/images/docs/UIBarButtonItemArrowOutlineLeft.png";
import UIBarButtonItemArrowOutlineRightImage from "../../../assets/images/docs/UIBarButtonItemArrowOutlineRight.png";
import DeleteImage from "../../../assets/images/delete.png";

const enhancer = connect(null, bindActions({ chartSideDocsActions, chartActions }));

class ChartDocsView extends React.Component {
  componentDidMount() {
    this.props.chartSideDocsActions.initList(this.props.onAppointment);
  }

  toggleImage = (item) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.chartSideDocsActions.toggleImage(item);
    };
  };

  fullMode = (index) => {
    return () => {
      this.props.chartSideDocsActions.fullMode(index);
    };
  };

  render() {
    const {
      chartSideDocs: { list, ids, fullMode, currentIndex, confirmDeleteModalVisible },
    } = this.props;

    const $list = list.map((item, index) => {
      if (!item.photo) return null;

      const circleUrl =
        "/images/docs/" +
        (ids.hasOwnProperty(item.photo.id)
          ? "ImageSelectedSmallOn.png"
          : "ImageSelectedSmallOff.png");

      function truncate(string) {
        if (!string) return null;

        if (string.length > 6) return string.substring(0, 6) + "...";
        else return string;
      }

      return (
        <div
          key={index}
          className="docs-item"
          onClick={this.fullMode(index)}
          style={{
            backgroundImage:
              "url(" +
              (item?.type?.code === "PATIENT_ATTACHMENT_CHART_PDF"
                ? "/images/chart/pdf.png"
                : item.photo.url) +
              ")",
          }}
        >
          <a href={item.photo.url} download={true} className="chat-docs-download-small">
            <img src={downloadImage} alt="" />
          </a>
          <img alt="" src={circleUrl} onClick={this.toggleImage(item)} className="active-circle" />
          <h4 className="docs-description">{truncate(item.description)}</h4>
        </div>
      );
    });

    const currentImage = list[currentIndex] || { photo: {}, type: {} };
    const currentCircleUrl =
      "/images/docs/" +
      (ids.hasOwnProperty(currentImage.photo.id)
        ? "ImageSelectedSmallOn@2x.png"
        : "ImageSelectedSmallOff@2x.png");

    return (
      <div className="clinical-note-item docs-view">
        <div className="clinical-note-item-view-title">
          <button
            onClick={this.props.chartSideDocsActions.openDeleteModal}
            className="btn btn-link pull-right btn-docs-delete"
          >
            <FilledImage className="zoom-2x" src={DeleteImage} color="#fff" />
          </button>
          <Back className="btn-sm pull-left" onClick={this.props.chartActions.hideSideBar} />
          <Show if={!fullMode}>
            <div className="name">Select Photos</div>
          </Show>
          <Show if={fullMode}>
            <div className="name">
              {currentIndex + 1}
              &nbsp;of&nbsp;
              {list.length}
            </div>
          </Show>
        </div>
        <Show if={!fullMode}>
          <div className="clinical-note-item-view-body">
            <div className="docs-list">{$list}</div>
          </div>
        </Show>
        <Show if={fullMode}>
          <div className="docs-body">
            <div
              className="image"
              style={{
                backgroundImage:
                  "url(" +
                  (currentImage?.type?.code === "PATIENT_ATTACHMENT_CHART_PDF"
                    ? "/images/chart/pdf.png"
                    : currentImage.photo.url) +
                  ")",
              }}
            >
              <a href={currentImage.photo.url} download={true} className="chat-docs-download">
                <img src={downloadImage} alt="" />
              </a>
              <img
                src={currentCircleUrl}
                onClick={this.toggleImage(currentImage)}
                className="active-circle"
              />
            </div>
            <div className="text">
              {currentImage.description}
              &nbsp; Upload date: &nbsp;
              {moment(currentImage.createdDate).format("DD/MM/YYYY")}
            </div>
            <div className="navigation">
              <button
                className="btn btn-link pull-left"
                type="button"
                onClick={this.props.chartSideDocsActions.smallMode}
              >
                <img src={UIBarButtonItemGridImage} alt="" />
              </button>
              <button
                className="btn btn-link arrow-left"
                type="button"
                onClick={this.props.chartSideDocsActions.prevIndex}
              >
                <img src={UIBarButtonItemArrowOutlineLeftImage} alt="" />
              </button>
              <button
                className="btn btn-link"
                type="button"
                onClick={this.props.chartSideDocsActions.nextIndex}
              >
                <img src={UIBarButtonItemArrowOutlineRightImage} alt="" />
              </button>
            </div>
          </div>
        </Show>
        <ChartDocsConfirmDeleteModal confirmDeleteModalVisible={confirmDeleteModalVisible} />
      </div>
    );
  }
}

export default enhancer(ChartDocsView);
