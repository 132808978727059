import React from "react";
import { Field, reduxForm } from "redux-form";

const form = reduxForm({
  form: "chartPlanPDFForm",
});

class ChartPlanPDFForm extends React.Component {
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className="form-horizontal"
        style={{ margin: "20px 10px 0", flexShrink: 0 }}
      >
        <div className="form-group">
          <label htmlFor="name" className="control-label col-sm-2">
            Name:
          </label>
          <div className="col-sm-8">
            <Field
              id="name"
              name="name"
              component="input"
              type="text"
              className="form-control"
              placeholder="Input Name For Estimate"
            />
          </div>
          <div className="col-sm-2">
            <button type="submit" className="btn btn-primary btn-block">
              Change
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default form(ChartPlanPDFForm);
