import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import BackButton from "../../../widgets/BackButton";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionEdit extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
    };

    submit = () => {
      this.props.chartSidePrescriptionActions.savePrescription(this.refs.form.elements.name.value);
    };

    render() {
      return (
        <div className="clinical-note-item">
          <div className="clinical-note-item-view-title chart-prescription-edit-title">
            <button onClick={this.submit} className="btn btn-link pull-right">
              ✔ &nbsp; Done
            </button>
            <BackButton
              className="btn-sm"
              text="Back"
              onClick={this.props.chartSidePrescriptionActions.popStack}
            />
            <div className="name">Enter name:</div>
          </div>
          <div className="clinical-note-item-view-body chart-prescription-edit">
            <form ref="form">
              <div className="form-group row">
                <div className="col-sm-8 col-sm-offset-2">
                  <input
                    type="text"
                    defaultValue={moment().format("DD/MM/YYYY hh:mm a")}
                    className="form-control"
                    name="name"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  },
);
