import React, { forwardRef, isValidElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { isString } from "lodash";

const useStyles = makeStyles({
  root: {
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "white",
    height: "40px",
    padding: "4px 10px 0",
    cursor: "pointer",

    "&:hover": {
      background: "gray",
      textDecoration: "none",
    },
  },
  icon: {
    height: "20px",
    verticalAlign: "top",
  },
  text: {
    fontSize: "10px",
    lineHeight: "15px",
  },
  container: { flex: 1 },
});

interface Props {
  readonly children: string;
  readonly onClick: () => void;
  readonly icon: string | ReactNode;
}

const DropdownToggle = forwardRef<HTMLDivElement, Props>(
  ({ onClick, icon, children, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div ref={ref} role="button" className={classes.root} onClick={onClick}>
        <div className={classes.container}>
          {isString(icon) && <img className={classes.icon} alt="icon" src={icon} />}
          {isValidElement(icon) && icon}
        </div>

        <span className={classes.text}>{children}</span>
      </div>
    );
  },
);

DropdownToggle.displayName = "DropdownToggle";

export { DropdownToggle };
