import { httpClient } from "./BaseApi";

const FOLLOW_UP_ITEM_URL = "api/followUp/item.json";
const FOLLOW_UP_LIST_URL = "api/followUp/list.json";
const FOLLOW_UP_COUNT_URL = "api/followUp/notification-count.json";

const FOLLOW_UP_ADD_NOTE_URL = "api/followUp/note/item.json";
const FOLLOW_UP_DELETE_NOTE_URL = "api/followUp/note/item.json";
const FOLLOW_UP_NOTE_LIST_URL = "api/followUp/note/list.json";

const FOLLOW_UP_RULE_ITEM_URL = "api/followUp/rule/item.json";
const FOLLOW_UP_RULE_LIST_URL = "api/followUp/rule/list.json";
const FOLLOW_UP_TYPE_CHOOSE_URL = "api/followUp/type/choose.json";
const FOLLOW_UP_REVIEW_ITEM = "api/claim/follow-up/review-notes.json";
const FOLLOW_UP_REOPEN_ITEM = "api/claim/follow-up/re-open.json";
const FOLLOW_UP_REFERRAL_CREATE_RECURRENCE_URL = "api/followUp/referral/create-recurrence.json";
const FOLLOW_UP_REFERRAL_CREATE_APPOINTMENT_URL = "api/followUp/referral/create-appointment.json";
const FOLLOW_UP_REFERRAL_CREATE_TRANSACTION_URL = "api/followUp/referral/create-transaction.json";

const FollowUpApi = {
  addNote: (data) => httpClient.post(FOLLOW_UP_ADD_NOTE_URL, { data }),
  deleteNote: (params) => httpClient.delete(FOLLOW_UP_DELETE_NOTE_URL, { params }),
  getNoteList: (params) => httpClient.get(FOLLOW_UP_NOTE_LIST_URL, { params }),
  getList: (params) => httpClient.get(FOLLOW_UP_LIST_URL, { params }),
  getCount: (params) => httpClient.get(FOLLOW_UP_COUNT_URL, { params }),
  getTypes: (params) => httpClient.get(FOLLOW_UP_TYPE_CHOOSE_URL, { params }),
  getRuleList: (params) => httpClient.get(FOLLOW_UP_RULE_LIST_URL, { params }),
  createRepeatRule: (data) => httpClient.post(FOLLOW_UP_RULE_ITEM_URL, { data }),
  deleteRepeatRule: (params) => httpClient.delete(FOLLOW_UP_RULE_ITEM_URL, { params }),
  repeatItem: (data) => httpClient.post(FOLLOW_UP_REFERRAL_CREATE_RECURRENCE_URL, { data }),
  createAppointment: (data) => httpClient.post(FOLLOW_UP_REFERRAL_CREATE_APPOINTMENT_URL, { data }),
  createTransaction: (data) => httpClient.post(FOLLOW_UP_REFERRAL_CREATE_TRANSACTION_URL, { data }),
  reviewItem: (data) => httpClient.post(FOLLOW_UP_REVIEW_ITEM, { data }),
  reopenItem: (data) => httpClient.put(FOLLOW_UP_REOPEN_ITEM, { data }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(FOLLOW_UP_ITEM_URL, { data })
      : httpClient.post(FOLLOW_UP_ITEM_URL, { data }),
};

export default FollowUpApi;
