import React from "react";

import { Form } from "formik";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { DoctorSelectField } from "../form/DoctorSelectField";
import { ClinicSelectField } from "../form/ClinicSelectField";
import { SelectField } from "../form/SelectField";
import { DateField } from "../form/DateField";

export default function MissedCancelledListForm(props) {
  const {
    values,
    setFieldValue,
    setFeatureTypeCode,
    activeTab,
    setActiveTab,
    categoryList,
  } = props;

  const futureTypeCodeOptions = [
    { value: -1, label: "Please select" },
    { value: "ANY_DOCTOR", label: "Any doctor" },
    { value: "SAME_DOCTOR", label: "Same doctor" },
  ];
  const statusOptions = [
    { value: STATUS_TYPES.APPOINTMENT_STATUS_MISSED, label: "Client (Missed)" },
    { value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED, label: "Center (Missed)" },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
      label: "Cancelled by patient",
    },
    // {
    //   value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED,
    //   label: "Cancelled by patient",
    // },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE,
      label: "Cancelled by centre",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT,
      label: "Released (Non-Payment)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE,
      label: "Client(Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED,
      label: "Clinician (Cancelled)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED,
      label: "Center (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED,
      label: "Clinician (Late Cancellation)",
    },
    {
      value: STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW,
      label: "Clinician (No Show)",
    },
  ];
  const categoryOptions = categoryList.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return (
    <Form className="p-4">
      <div className="row">
        <div className="col-sm-12 mb-3">
          <div className="btn-group w-100">
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "missed" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("missed")}
            >
              Missed/Cancelled List
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${
                activeTab === "requestedReschedule" ? "btn-success" : "btn-default"
              }`}
              onClick={() => setActiveTab("requestedReschedule")}
            >
              Requested Reschedule (whatsapp)
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "noAnswer" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("noAnswer")}
            >
              No Answer
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "willCall" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("willCall")}
            >
              Will Call back
            </div>
            <div
              style={{ width: `${100 / 5}%` }}
              className={`btn ${activeTab === "notInterested" ? "btn-success" : "btn-default"}`}
              onClick={() => setActiveTab("notInterested")}
            >
              Not Interested
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Clinic:</div>
            <ClinicSelectField name="clinic" onChange={props.onChangeClinic} />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Status:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectField
                isMulti={true}
                clearable={true}
                searchable={false}
                name="statusCodes"
                value={values.statusCodes}
                options={statusOptions}
                onChange={(v) => setFieldValue("statusCodes", v)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Appointment type:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectField
                isMulti={true}
                clearable={true}
                searchable={false}
                name="categoryIds"
                value={values.categoryIds}
                options={categoryOptions}
                onChange={(v) => setFieldValue("categoryIds", v)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Doctors:</div>
            <div className="form-group">
              <DoctorSelectField
                name="doctorIds"
                isMulti={true}
                isSearchable={true}
                allLabel="All Doctors"
                value={values.doctorIds}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>Start date:</div>
            <DateField
              name="periodStart"
              fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
              onChange={(x) => setFieldValue("periodStart", x)}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="claims-filter-field">
            <div>End date:</div>
            <DateField
              name="periodEnd"
              fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
              onChange={(x) => setFieldValue("periodEnd", x)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-4">
              <button
                type="submit"
                style={{ marginTop: "20px" }}
                className="btn btn-primary btn-block"
              >
                Search
              </button>
            </div>
            <div className="col-sm-4">
              <button
                type="button"
                style={{ marginTop: "20px" }}
                className="btn btn-danger btn-block"
                onClick={() => setFeatureTypeCode("SAME_DOCTOR")}
              >
                Clean up list for same doctor
              </button>
            </div>
            <div className="col-sm-4">
              <button
                type="button"
                style={{ marginTop: "20px" }}
                className="btn btn-danger btn-block"
                onClick={() => setFeatureTypeCode("ANY_DOCTOR")}
              >
                Clean up list for any doctor
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
