import React from "react";

import Back from "../widgets/Back";

export const TransactionGroupByTypes = {
  date: "create_date_desc",
  item: "tooth_asc",
};

export default class TransactionGroupBy extends React.Component {
  setView = (type) => {
    localStorage.setItem("transactionGroupBy", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Posted by</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span
              onClick={() => this.setView(TransactionGroupByTypes.date)}
              className="list-group-item"
            >
              {Boolean(
                localStorage.getItem("transactionGroupBy") === TransactionGroupByTypes.date,
              ) && <div className="pull-right">&#10004;</div>}
              Date
            </span>
            <span
              onClick={() => this.setView(TransactionGroupByTypes.item)}
              className="list-group-item"
            >
              {Boolean(
                localStorage.getItem("transactionGroupBy") === TransactionGroupByTypes.item,
              ) && <div className="pull-right">&#10004;</div>}
              Item
            </span>
          </div>
        </div>
      </div>
    );
  }
}
