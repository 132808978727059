import createReducer from "../helpers/createReducer";
import { CHANGE_APP_LANGUAGE } from "../actions/appActions";

const defaultState = {
  language: "english",
};

const reducers = {
  [CHANGE_APP_LANGUAGE](state, { language }) {
    return {
      ...state,

      language,
    };
  },
};

/** ==================
 * Selectors
 * ================== */

export default createReducer(defaultState, reducers);
