import React from "react";

import Show from "../../widgets/Show";

import ChartTransactionTable from "./ChartTransactionTable";
import ChartTransactionHeader from "./ChartTransactionHeader";
import ChartTransactionPlanModal from "./ChartTransactionPlanModal";

export default function ChartTransactionRightSide(props) {
  const {
    elements,
    onScroll,
    cellChangeItem,
    openTransactionEdit,
    transaction,
    teeth,
    onCompleteUpdate,
    changeAssignedDoctor,
  } = props;

  return (
    <div className="right-side">
      <ChartTransactionHeader
        transaction={transaction}
        elements={elements}
        mixedSortBtnVisible={elements.mixedSortBtn.visible} // for re-rendering
        sortBtnVisible={elements.sortBtn.visible} // for re-rendering
        teeth={teeth}
      />
      <ChartTransactionTable
        elements={elements}
        onCompleteUpdate={onCompleteUpdate}
        tableLoaderVisible={elements.table.loader.visible} // for re-rendering
        showHideRightBtnSelected={elements.showHideRightBtn.selected} // for re-rendering
        onScroll={onScroll}
        openTransactionEdit={openTransactionEdit}
        cellChangeItem={cellChangeItem}
      />
      <Show if={transaction.planModalVisible}>
        <ChartTransactionPlanModal
          transaction={transaction}
          cellChangeItem={cellChangeItem}
          changeAssignedDoctor={changeAssignedDoctor}
        />
      </Show>
    </div>
  );
}
