import React from "react";
import { Field, reduxForm } from "redux-form";

import ReduxCKEditor from "../../../widgets/redux/ReduxCKEditor";

const form = reduxForm({
  form: "chartPostOperation",
});

class ChartPostOperationForm extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className="chart-post-op-form">
        <Field
          name="title"
          component="input"
          type="text"
          className="form-control chart-post-op-name"
        />

        <label htmlFor="chart-post-op-content">Write your note below:</label>
        <Field
          name="content"
          id="chart-post-op-content"
          component={ReduxCKEditor}
          className="form-control"
        />

        <div className="chart-post-op-buttons">
          <button type="submit" className="btn btn-primary btn-lg">
            Done
          </button>
          {/* <button type="button" className="btn btn-primary btn-lg" onClick={this.props.email}>
            Email to Patient
          </button> */}
          <button type="button" className="btn btn-primary btn-lg" onClick={this.props.print}>
            Print
          </button>
        </div>
      </form>
    );
  }
}

export default form(ChartPostOperationForm);
