import React from "react";
import { Modal } from "../../../ui/Modal";

import { connect } from "react-redux";

import {
  noteDeleteItem,
  noteDeleteConfirmModalHide,
  noteRestoreItem,
} from "../../../../actions/chartSideClinicalNoteActions";

const enhancer = connect(null, { noteDeleteItem, noteRestoreItem, noteDeleteConfirmModalHide });
export default enhancer(
  class NoteDeleteConfirmModal extends React.Component {
    render() {
      const { deleteId, restore } = this.props;
      return (
        <Modal
          show={deleteId}
          animation={false}
          size="sm"
          keyboard={false}
          onHide={this.props.noteDeleteConfirmModalHide}
          actions={
            <div>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.props.noteDeleteConfirmModalHide}
              >
                Cancel
              </button>
              <button
                type="button"
                className={restore ? "btn btn-primary" : "btn btn-danger"}
                onClick={() =>
                  restore
                    ? this.props.noteRestoreItem(deleteId)
                    : this.props.noteDeleteItem(deleteId)
                }
              >
                {restore ? "Restore" : "Delete"}
              </button>
            </div>
          }
        >
          <div>Are you sure you want to {restore ? "restore" : "void"} this note?</div>
        </Modal>
      );
    }
  },
);
