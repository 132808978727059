import React from "react";

import { useDispatch } from "react-redux";
import { setDoctor, setFromDate, setToDate } from "../../actions/waitingListActions";

import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { Formik, Form } from "formik";
import { DoctorSelectField } from "../form/DoctorSelectField";
import { DateField } from "../form/DateField";
import { SelectField } from "../form/SelectField";

const WaitingListForm = (props) => {
  const dispatch = useDispatch();

  const { fromDate, toDate, onSubmit, categoryList } = props;
  const categoryOptions = (categoryList || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return (
    <Formik
      initialValues={{
        toDate,
        fromDate,
        doctorIds: null,
        categoryIds: null,
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ setFieldValue }) => (
        <Form className="form-inline waiting-list-header-form">
          <div
            className="row d-flex align-items-center"
            style={{ justifyContent: "flex-end", height: "100%" }}
          >
            <div className="col pr-0 mr-2">
              <div style={{ display: "inline-block", marginRight: "5px", width: "200px" }}>
                <DoctorSelectField
                  name="doctorIds"
                  fieldProps={{ style: { width: "100%" } }}
                  isMulti={true}
                  isSearchable={true}
                  allLabel="All Doctors"
                  onChange={(v) => {
                    setFieldValue("doctorIds", v);
                    dispatch(setDoctor(v));
                  }}
                />
              </div>
            </div>
            <div className="col mr-2">
              <div style={{ display: "inline-block", marginRight: "5px", width: "200px" }}>
                <SelectField
                  name="categoryIds"
                  fieldProps={{ style: { width: "100%" } }}
                  isMulti={true}
                  isSearchable={true}
                  options={categoryOptions}
                  onChange={(x) => setFieldValue("categoryIds", x)}
                />
              </div>
            </div>
            <div className="col mr-2">
              <div className="form-group">
                <label className="mb-0 mr-2">From</label>
                <DateField
                  name="fromDate"
                  dateFormat={DATE_FORMAT_LEGACY}
                  className="small-datepicker"
                  onChange={(e) => {
                    dispatch(setFromDate(e));
                    setFieldValue("fromDate", e);
                  }}
                />
              </div>
            </div>
            <div className="col mr-2">
              <div className="form-group">
                <label className="mb-0 mr-2">To</label>
                <DateField
                  name="toDate"
                  dateFormat={DATE_FORMAT_LEGACY}
                  className="small-datepicker"
                  onChange={(e) => {
                    dispatch(setToDate(e));
                    setFieldValue("toDate", e);
                  }}
                />
              </div>
            </div>
            <div className="col">
              <button type="submit" className="btn btn-default">
                Search
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default WaitingListForm;
