import _ from "lodash";
import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import Utils from "../../helpers/Utils";
import { PatientItem } from "../patient/PatientItem";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import ClinicNoteImage from "../../assets/images/chart/clincalnotes@2xwhite.png";
import { IconButton } from "@mui/material";
import PlannedIconImage from "../../assets/images/chart/PlannedIcon@2xwhite.png";

import { AppointmentPlannedModalWrapper } from "../appointment-form/AppointmentPlannedModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import ChartSideBar from "../chart/side-bar/ChartSideBar";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { showSideBar as showClinical } from "../../actions/chartActions";

BringEarlierTable.propTypes = {
  list: PropTypes.array,
  onMoveClick: PropTypes.func,
};

export function BringEarlierTable({ list = [], onMoveClick, history, location }) {
  return (
    <table className="table table-hover bring-earlier-table">
      <thead>
        <tr>
          <th>Patient</th>
          <th>Doctor</th>
          <th>Type</th>
          <th>Comment</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {list.map((x) => (
          <ListItem
            key={x.id}
            x={x}
            onMoveClick={onMoveClick}
            history={history}
            location={location}
          />
        ))}
      </tbody>
    </table>
  );
}

const ListItem = ({ x, onMoveClick, history, location }) => {
  const [showPlanned, setShowPlanned] = useState(false);
  const store = useSelector((store) => store);
  const [clinicalNode, setClinicalNode] = useState(false);
  const dispatch = useDispatch();

  const {
    chartSideClinicalNote,
    chartSideVital,
    session: { toothNumericType, permissions, member, clinic },
    chart,
    chartSideDocs,
  } = store;

  const doctor = _.get(x, "dentist.name");
  const date = Utils.parseDateFormat(moment(x.startTime), DATE_FORMAT_LEGACY);

  const patientLastName = _.get(x, "patient.lastName");
  const patientFirstName = _.get(x, "patient.firstName");
  const patientName = [patientFirstName, patientLastName].join(" ");
  const photoUrl = _.get(x, "patient.photo.url");
  const category = _.get(x, "category.name", "-");
  const note = _.get(x, "note", "-");
  return (
    <>
      <tr key={x.id} style={{ backgroundColor: "#ff6666", color: "#ffffff" }}>
        <td>
          <PatientItem size={40} photo={photoUrl} name={patientName} />
        </td>
        <td>
          <div>{doctor}</div>
        </td>
        <td>
          <div>{category}</div>
        </td>
        <td>
          <div>{note}</div>
        </td>
        <td>
          <div>
            <IconButton title="Clinical Notes">
              <img
                alt={""}
                src={ClinicNoteImage}
                className="zoom-2x"
                onClick={() => {
                  setClinicalNode(true);
                  dispatch(showClinical(viewTypes.vital, { patientKey: x?.patient.patientKey }));
                }}
              />
            </IconButton>
            <IconButton title="Planned Items">
              <img
                alt={""}
                src={PlannedIconImage}
                className="zoom-2x"
                onClick={() => setShowPlanned(true)}
              />
            </IconButton>
            <div>{date}</div>
          </div>
        </td>
        <td>
          <Button onClick={() => onMoveClick(x.id)} className="bg-primary text-white move-button">
            Move
          </Button>
        </td>
      </tr>
      {clinicalNode && (
        <ChartSideBar
          clinicalNotesSideBarViewType={{ id: 1, name: "Vertical", code: "VERTICAL" }}
          onAppointment={true}
          location={location}
          history={history}
          sideBarVisible={clinicalNode}
          member={member}
          chart={chart}
          viewType={viewTypes.clinicalNote}
          chartSideClinicalNote={chartSideClinicalNote}
          chartSideVital={chartSideVital}
          toothNumericType={toothNumericType}
          chartSideDocs={chartSideDocs}
          hideSideBar={() => setClinicalNode(false)}
          permissions={permissions}
          clinic={clinic}
          patientKey={x?.patient?.patientKey}
        />
      )}
      <AppointmentPlannedModalWrapper
        getList={false}
        show={showPlanned}
        appointmentData={false}
        patientKey={x?.patient?.patientKey}
        onClose={() => setShowPlanned(false)}
      />
    </>
  );
};
