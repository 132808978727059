import React from "react";

export default function condition(props) {
  const { property, up } = props;
  const getToothGrade = (property) => {
    if (property.code === "TOOTHGRADE_GRADE1") {
      return "1";
    } else if (property.code === "TOOTHGRADE_GRADE2") {
      return "2";
    } else if (property.code === "TOOTHGRADE_GRADE3") {
      return "3";
    } else {
      return "";
    }
  };
  if (!property) return null;

  const src = require(`../../../assets/images/conditions/mobility-grade/${getToothGrade(
    property,
  )}_${up ? "up" : "down"}@2x.png`).default;

  return (
    <div className="condition-wrap tooth-image-condition">
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
