import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactSpinner from "react-spinjs-fix";

import Show from "../../widgets/Show";
import PrintModal from "./PrintModal";
import Utils from "../../../helpers/Utils";
import { DatePicker } from "../../ui/DatePicker";
import { Routes } from "../../../constants/Routes";
import { createUrl } from "../../../utils/UrlUtils";
import bindActions from "../../../helpers/bindActions";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import EyeBlackImage from "../../../assets/images/eye-small.png";
import { choosePatientNewScreen } from "../../../actions/appointmentActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../../helpers/DateUtils";
import PrintBlackImage from "../../../assets/images/print-black-small.png";
import CalendarIcon from "../../../assets/images/calendar.png";
import SupportImage from "../../../assets/images/support_icon.png";
import NoteImage from "../../../assets/images/note-icon.png";
import { withRouter } from "react-router";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DoctorSelectWrapper } from "../../doctor/DoctorSelectWrapper";
import FilledImage from "../../widgets/FilledImage";
import { obtainImagePath } from "../../../utils/ImageUtils";
import NoteModal from "./NoteModal";
import { handleApprovalsFilterItem } from "../../../actions/patientRegisterActions";
import { useDispatch } from "react-redux";
import * as ActionTypes from "../../../actionTypes/patientRegisterActionTypes";

const enhancer = connect(
  ({
    session,
    auth: { doctors },
    patientRegister: {
      approvalsList,
      approvalsItem,
      approvalsStatusList,
      claimedStatusList,
      approvalsDocTypeList,
      approvalsListTotalCount,
      fetchingApprovals,
      approvalsFilter,
    },
  }) => ({
    approvalsList,
    approvalsItem,
    approvalsStatusList,
    approvalsDocTypeList,
    claimedStatusList,
    approvalsListTotalCount,
    fetchingApprovals,
    doctors,
    session,
    approvalsFilter,
  }),
  bindActions({
    patientRegisterActions,
    choosePatientNewScreen,
    handleApprovalsFilterItem,
  }),
);
const PatientApprovalsList = (props) => {
  const [searchForm, setSearchForm] = useState({
    statusCode: "Approved",
    futureAppointmentExist: "false",
    patientChartNumber: "",
    claimedStatusCode: "NOT_CLAIMED",
    doctorId: 0,
    startDate: null,
    endDate: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [item, setItem] = useState({});
  const dispatch = useDispatch();
  const {
    statusCode,
    futureAppointmentExist,
    patientChartNumber,
    claimedStatusCode,
    doctorId,
    startDate,
    endDate,
  } = props.approvalsFilter;

  useEffect(() => {
    const requestData = {
      statusCode: statusCode,
      claimedStatusCode: claimedStatusCode,
      limit: 25,
    };
    if (props.session.permissions.indexOf("VIEW_OWN_PRIOR_APPROVALS") >= 0) {
      requestData.doctorId = props.session.member.id || null;
    }
    if (props.patientKey) {
      requestData.patientKey = props.patientKey;
    }
    if (props.clinic?.value) {
      requestData.clinicId = props.clinic?.value;
    }
    const {
      patientRegisterActions: { getPatientApprovalsList },
    } = props;
    getPatientApprovalsList({ ...requestData, ...props.approvalsFilter }, true);

    return () => {
      dispatch({ type: ActionTypes.APPROVALS_FILTER_ITEMS_CLEAR });
    };
  }, []);

  const handleChangeFormField = (event, field) => {
    setSearchForm({
      ...searchForm,
      [field]: event == "all" ? "" : event,
    });
    props.handleApprovalsFilterItem({ [field]: event == "all" ? "" : event });
  };
  const filterSubmit = () => {
    const requestData = {
      ...props.approvalsFilter,
      clinicId: props.clinic?.value,
      limit: 25,
    };
    if (props.session.permissions.indexOf("VIEW_OWN_PRIOR_APPROVALS") >= 0) {
      requestData.doctorId = props.session.member.id || null;
    }
    if (props.patientKey) {
      requestData.patientKey = props.patientKey;
    }
    requestData.startDate = getCompanyStartOfDay(startDate) || null;
    requestData.endDate = getCompanyEndOfDay(endDate) || null;
    const {
      patientRegisterActions: { getPatientApprovalsList },
    } = props;
    getPatientApprovalsList(requestData);
  };

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;
    const {
      patientRegisterActions: { loadPatientApprovalsList },
      patientKey,
    } = props;
    const requestData = {
      patientKey,
      ...props.approvalsFilter,
    };
    if (props.session.permissions.indexOf("VIEW_OWN_PRIOR_APPROVALS") >= 0) {
      requestData.doctorId = props.session.member.id || null;
    }
    requestData.startDate = getCompanyStartOfDay(startDate) || null;
    requestData.endDate = getCompanyEndOfDay(endDate) || null;

    if (scrollBottom) {
      loadPatientApprovalsList(requestData);
    }
  };

  const setModalVisible = (item) => {
    setShowModal(!showModal);
    setSelectedItemId(item?.id || null);
    setItem(item || {});
  };

  const onSetNoteModalVisible = (item) => {
    setNoteModalVisible(!noteModalVisible);
    setSelectedItemId(item?.id || null);
    setItem(item || {});
  };

  const viewItem = (id) => {
    const { patientRegisterActions, approvalsDocTypeList } = props;
    const data = {
      id,
      claimRequestTypeCode: "PRIOR_REQUEST",
      documentTypeCode: approvalsDocTypeList.find((item) => item.code === "REQUEST_PRINT_OUT").code,
    };
    patientRegisterActions.printClaim(data).then((response) => {
      if (response) {
        patientRegisterActions.savePdf(response);
        props.history.push(Routes.PatientClaimsApprovalsPdfView);
      }
    });
  };

  const newAppointment = (patientKey, dentist) => {
    props.history.push(
      createUrl(Routes.DashboardAppointment, {
        params: { id: 0 },
        query: {
          patientKey,
          dentist,
          startDate: Date.now(),
        },
      }),
    );
  };

  const {
    approvalsList,
    approvalsStatusList,
    approvalsDocTypeList,
    claimedStatusList,
    fetchingApprovals,
    session: { permissions },
    doctors,
  } = props;
  const viewOwnPriorApprovals = permissions.indexOf("VIEW_OWN_PRIOR_APPROVALS") >= 0;
  const patientChart = props.patientKey || false;
  const cols = 4;
  const list = (approvalsList || []).map((item) => {
    return (
      <div className="appointments-list-row" key={item.id}>
        <div
          className="claims-approvals-cell-item"
          style={{
            maxWidth: "120px",
          }}
        >
          {item.id || ""}
        </div>
        <div
          className="claims-approvals-cell-item"
          style={{
            maxWidth: "120px",
          }}
        >
          {moment(item.createdDate).format(DATE_FORMAT_LEGACY) || ""}
        </div>
        <div
          className="claims-approvals-cell-item"
          style={{
            maxWidth: "120px",
            color: "red",
          }}
        >
          {item.expireDate ? moment(item.expireDate).format(DATE_FORMAT_LEGACY) : ""}
        </div>
        <div
          title={item?.patientName}
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
          }}
        >
          {item?.patientName}
        </div>
        <div
          title={item?.patientChartNumber}
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
          }}
        >
          {item?.patientChartNumber}
        </div>
        <div
          title={item?.payerName}
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
          }}
        >
          {item?.payerName}
        </div>
        <div
          title={item?.dentistName}
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
          }}
        >
          {item?.dentistName}
        </div>
        <div
          title={item?.status?.name}
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
          }}
        >
          {item?.status?.name}
        </div>
        <div
          className="claims-approvals-cell-item"
          style={{
            width: `${100 / cols}%`,
            position: "relative",
          }}
        >
          {item?.claimedStatus?.name}
        </div>
        <div
          className="claims-approvals-cell-item"
          style={{
            maxWidth: "120px",
            position: "relative",
          }}
        >
          {item.futureAppointmentDate
            ? moment(item.futureAppointmentDate).format(DATE_FORMAT_LEGACY)
            : ""}
        </div>
        <div
          className="claims-approvals-cell-item"
          style={{
            width: `calc(${100 / cols}% + 100px)`,
            position: "relative",
          }}
        >
          <div className="d-flex align-items-center">
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              role="button"
              title="Add Notes"
              onClick={() => {
                onSetNoteModalVisible(item);
              }}
            >
              <FilledImage zoom="20" src={obtainImagePath(NoteImage)} color="#000000a6" />
            </div>
            <div
              role="button"
              style={{
                display: "flex",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              onClick={() => {
                props.history.push(
                  createUrl(Routes.DashboardAppointment, {
                    params: { id: 0 },
                    query: {
                      mode: "follow",
                      dentist: item?.dentistId,
                      patientKey: item?.patientKey,
                    },
                  }),
                );
              }}
              title="Support"
            >
              <FilledImage
                src={obtainImagePath(SupportImage)}
                color="#FF4242"
                zoom="18"
                className="no-response-zoom"
              />
            </div>
            <div
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              role="button"
              title="Add Appointment"
              onClick={() => newAppointment(item.patientKey, item.dentistId)}
            >
              <FilledImage src={obtainImagePath(CalendarIcon)} color="#000000a6" />
            </div>
            <div
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              role="button"
              title="Print"
              onClick={() => setModalVisible(item)}
            >
              <img width={20} height={20} alt="print" className="icon" src={PrintBlackImage} />
            </div>
            <div
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              role="button"
              title="View"
              onClick={() => viewItem(item.id)}
            >
              <img width={20} height={24} alt="view" className="icon" src={EyeBlackImage} />
            </div>
          </div>
        </div>
      </div>
    );
  });
  const FilterOptions = approvalsStatusList.length
    ? approvalsStatusList.map((status) => {
        return { label: status.name, value: status.code };
      })
    : [];
  const claimedStatusOptions = claimedStatusList.length
    ? claimedStatusList.map((status) => {
        return { label: status.name, value: status.code };
      })
    : [];
  const doctorOptions = [{ label: "All", value: 0 }];
  if (doctors.length) {
    doctors.map((doctor) =>
      doctorOptions.push({
        label: Utils.getFullName(doctor),
        value: doctor.id,
      }),
    );
  }
  const futureAppointmentExistOptions = [
    { label: "All", value: null },
    { label: "Available", value: "true" },
    { label: "Not Available", value: "false" },
  ];
  return (
    <div>
      <div
        className="mt-3"
        style={{
          paddingRight: "15px",
          paddingLeft: "15px",
        }}
      >
        <div className="row">
          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div className="mr-3">Doctors:</div>
              <div
                className="form-group"
                style={{
                  margin: 0,
                  lineHeight: "30px",
                  textAlign: "initial",
                }}
              >
                <DoctorSelectWrapper
                  placeholder="Doctors"
                  allLabel="All"
                  allValue="all"
                  isSearchable={true}
                  isDisabled={viewOwnPriorApprovals}
                  value={{ value: doctorId }}
                  onChange={(x) => handleChangeFormField(x.value || "", "doctorId")}
                />
              </div>
            </div>
          </div>
          {!patientChart && (
            <div className="col-sm-3">
              <div className="claims-filter-field">
                <div>Chart number:</div>
                <input
                  className="form-control"
                  placeholder=""
                  value={patientChartNumber}
                  onChange={({ target }) =>
                    handleChangeFormField(target.value, "patientChartNumber")
                  }
                />
              </div>
            </div>
          )}
          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div>Start Date:</div>
              <DatePicker
                className="form-control"
                wrapperClassName="w-100"
                placeholder="Start Date"
                selected={startDate}
                onChange={(event) => handleChangeFormField(event, "startDate")}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div>End Date:</div>
              <DatePicker
                className="form-control"
                wrapperClassName="w-100"
                placeholder="End Date"
                selected={endDate}
                onChange={(event) => handleChangeFormField(event, "endDate")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div className="mr-3">Filter by status:</div>
              <div
                className="form-group"
                style={{
                  margin: 0,
                  lineHeight: "30px",
                  textAlign: "initial",
                }}
              >
                <SelectPicker
                  placeholder="Filter by status"
                  value={{ value: statusCode }}
                  options={FilterOptions}
                  onChange={({ value }) => handleChangeFormField(value, "statusCode")}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div className="mr-3">Claimed Status:</div>
              <div
                className="form-group"
                style={{
                  margin: 0,
                  lineHeight: "30px",
                  textAlign: "initial",
                }}
              >
                <SelectPicker
                  placeholder="claimed"
                  value={{ value: claimedStatusCode }}
                  options={claimedStatusOptions}
                  onChange={({ value }) => handleChangeFormField(value, "claimedStatusCode")}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="claims-filter-field">
              <div className="mr-3">Future Appointments:</div>
              <div
                className="form-group"
                style={{
                  margin: 0,
                  lineHeight: "30px",
                  textAlign: "initial",
                }}
              >
                <SelectPicker
                  placeholder="future appointments"
                  value={{ value: futureAppointmentExist }}
                  options={futureAppointmentExistOptions}
                  onChange={({ value }) => handleChangeFormField(value, "futureAppointmentExist")}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <button
              className="btn btn-primary btn-block"
              style={{ marginTop: "20px" }}
              onClick={filterSubmit}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="claims-approvals-list" style={{ height: "85%" }}>
        <div className="appointments-list-table" style={{ height: "100%" }}>
          <div className="appointments-list-header">
            <div
              className="claims-approvals-cell-item"
              style={{
                maxWidth: "120px",
              }}
            >
              Request Id
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                maxWidth: "120px",
              }}
            >
              Created Date
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                maxWidth: "120px",
              }}
            >
              Expiry Date
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Patient Name
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Patient Chart
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Insurance Company
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Clinician
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Status
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `${100 / cols}%`,
              }}
            >
              Claimed Status
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                maxWidth: "120px",
              }}
            >
              Future Appointment
            </div>
            <div
              className="claims-approvals-cell-item"
              style={{
                width: `calc(${100 / cols}% + 100px)`,
              }}
            >
              Actions
            </div>
          </div>
          <div
            className="appointments-list-body"
            style={{ height: "100%", overflowY: "scroll" }}
            onScroll={loadList}
          >
            {list}
            <Show if={fetchingApprovals}>
              <ReactSpinner />
            </Show>
          </div>
        </div>
        <NoteModal
          show={noteModalVisible}
          getList={filterSubmit}
          setNoteModalVisible={onSetNoteModalVisible}
          item={item}
          id={item.id}
        />
        <PrintModal
          show={showModal}
          setModalVisible={setModalVisible}
          selectedItemId={selectedItemId}
          claimsDocTypeList={approvalsDocTypeList}
          claimRequestTypeCode="PRIOR_REQUEST"
          item={item}
          view={false}
        />
      </div>
    </div>
  );
};

PatientApprovalsList.propTypes = {
  doctors: PropTypes.array,
  approvalsList: PropTypes.array,
  approvalsStatusList: PropTypes.array,
  approvalsDocTypeList: PropTypes.array,
  claimedStatusList: PropTypes.array,
  session: PropTypes.object,
  fetchingApprovals: PropTypes.bool,
  patientKey: PropTypes.string,
  routerActions: PropTypes.object,
  patientRegisterActions: PropTypes.object,
};

export default withRouter(enhancer(PatientApprovalsList));
