import { httpClient } from "./BaseApi";

const STICKY_NOTE_ITEM_URL = "api/sticky-note/item.json";
const STICKY_NOTE_LIST_URL = "api/sticky-note/list.json";
const STICKY_NOTE_ITEMS_URL = "api/sticky-note/items.json";

const STICKY_NOTE_CATEGORY_CHOOSE_URL = "api/sticky-note/category/choose.json";

const StickyNoteApi = {
  saveItems: (data) => httpClient.put(STICKY_NOTE_ITEMS_URL, { data }),
  getList: (params) => httpClient.get(STICKY_NOTE_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(STICKY_NOTE_ITEM_URL, { params }),
  deleteAll: (params) => httpClient.delete(STICKY_NOTE_ITEMS_URL, { params }),
  getCategory: (params) => httpClient.get(STICKY_NOTE_CATEGORY_CHOOSE_URL, { params }),
};

export default StickyNoteApi;
