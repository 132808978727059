import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/settingsActionTypes";

export const defaultState = {
  prevPath: "/Dashboard",
  settingsPasswordDialogVisible: false,
};

const reducers = {
  [ActionTypes.SETTINGS_INIT](state, { prevPath }) {
    return {
      ...state,
      prevPath,
    };
  },
  [ActionTypes.SETTINGS_PASSWORD_DIALOG_HIDE](state) {
    return {
      ...state,
      settingsPasswordDialogVisible: false,
    };
  },
  [ActionTypes.SETTINGS_PASSWORD_DIALOG_SHOW](state) {
    return {
      ...state,
      settingsPasswordDialogVisible: true,
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const settingsPasswordDialogVisibleSelector = ({ settings }) =>
  settings.settingsPasswordDialogVisible;

export default createReducer(defaultState, reducers);
