import { useQuery, UseQueryResult } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

export function useClinicListQuery(): UseQueryResult<ClinicProps[], Error> {
  return useQuery(["app", "clinic", "list"], () =>
    httpClient.get(ApiRoutes.ClinicList).then(({ data }) => data.data),
  );
}
