import React, { ElementType, ReactNode } from "react";
import { Dropdown as RBDropdown } from "react-bootstrap";
import { DropdownProps } from "react-bootstrap/Dropdown";

import { DropdownToggle } from "./DropdownToggle";

interface Props extends Omit<DropdownProps, "children"> {
  readonly title?: string;
  readonly children: ReactNode;
  readonly customToggleProps?: any;
  readonly customMenu?: ElementType<any>;
  readonly customToggle?: ElementType<any>;
}

export function Dropdown({
  children,
  customMenu,
  customToggleProps,
  customToggle = DropdownToggle,
  ...props
}: Props) {
  return (
    <RBDropdown {...props}>
      <RBDropdown.Toggle {...customToggleProps} as={customToggle} id="dropdown-custom-components">
        {props.title}
      </RBDropdown.Toggle>

      <RBDropdown.Menu as={customMenu}>{children}</RBDropdown.Menu>
    </RBDropdown>
  );
}
