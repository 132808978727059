export enum ApiRoutes {
  PatientPriceList = "api/patient/price/list.json",
  CreateTempPatient = "api/patient/first-contact/item.json",
  SavePatientDetails = "api/patient/signature-form/personal-details/full.json",

  ClinicList = "api/clinic/choose.json",

  MemberDoctorsList = "api/member/doctor/choose.json",
  MemberDentalPointsList = "api/clinic/appointment/dental-point/choose.json",

  BlockedTimeSlotItem = "api/clinic/blockedtimeslot/item.json",
  BlockedTimeSlotTypeColor = "/api/clinic/blockedtimeslot/types/choose.json",
  GetEligibilityDetails = "/api/eligibility/item.json",

  InsuranceList = "/api/patient/insurance/list.json",

  TreatmentProceduresItem = "/api/treatment/procedure/item.json",

  patientVitalTransactionItem = "/api/v2/vital/transaction/item.json",
  patientVitalTransactionVoid = "api/v2/vital/transaction/items/change-voided.json",

  patientFamilyMemberItem = "/api/patient/family-members/item.json",

  savePerioExamItem = "api/perio-exam/items.json",

  appointmentHistoryItem = "api/clinic/appointment/item/history.json",
  appointmentRemindersLog = "api/clinic/appointment/reminder/history.json",
}
