import { TransactionStatus, TransactionType } from "../constants/TransactionTypes";
import { LIMIT } from "../constants/Constants";

/* eslint-disable */
export default class TransactionSearchParameters {
  constructor() {
    this.patientKey = "";
    this.appointmentId = 0;
    this.type = TransactionType.TREATMENT;
    this.status = status || TransactionStatus.PLANNED;

    this.start = 0;
    this.limit = LIMIT;
    this.totalCount = 0;
    this.sortField = null;

    this.treatmentPlanned = false;
    this.treatmentCompleted = false;
    this.diagnosisPlanned = false;
    this.diagnosisCompleted = false;
    this.diagnosisProvisional = false;
    this.existingCompleted = false;
    this.holdPreApproval = false;
  }

  initWithPatientKey(patientKey, appointmentId, type, status, sortField) {
    // this.clear();
    if (this) {
      this.patientKey = patientKey;
      this.appointmentId = appointmentId;
      this.type = type;
      this.status = status || TransactionStatus.PLANNED;
      this.sortField = sortField;
      localStorage.getItem("transactionGroupBy") || "create_date_desc";
    }
    return this;
  }

  clear() {
    this.start = 0;
    this.limit = LIMIT;
    this.sortField = null;
  }

  clearTypeStatusParams() {
    this.type = null;
    this.status = null;
    this.existingCompleted = null;
    this.diagnosisCompleted = null;
    this.treatmentCompleted = null;
    this.treatmentPlanned = null;
    this.diagnosisPlanned = null;
  }

  getQuery() {
    const request = {};
    if (this.patientKey) {
      request.patientKey = this.patientKey;
    }
    if (this.appointmentId) {
      request.appointmentId = this.appointmentId && +this.appointmentId;
    }

    if (this.treatmentPlanned) request.treatmentPlanned = this.treatmentPlanned;
    if (this.treatmentCompleted) request.treatmentCompleted = this.treatmentCompleted;
    if (this.diagnosisPlanned) request.diagnosisPlanned = this.diagnosisPlanned;
    if (this.diagnosisCompleted) request.diagnosisCompleted = this.diagnosisCompleted;
    if (this.existingCompleted) request.existingCompleted = this.existingCompleted;
    if (this.holdPreApproval) request.holdPreApproval = this.holdPreApproval;
    if (this.diagnosisProvisional) request.diagnosisProvisional = this.diagnosisProvisional;
    
    if (this.type) request.type = this.type;
    if (this.status) request.status = this.status;
    if (Number.isInteger(this.start)) request.start = this.start;
    if (this.limit) request.limit = this.limit;
    if (this.sortField) request.sortField = this.sortField;

    return request;
  }
}
