import React from "react";
import FilledImage from "../../widgets/FilledImage";
import sImage from "../../../assets/images/conditions/s_icon@2x.png";

export default function condition(props) {
  const { property, color } = props;

  if (!property) return null;

  return (
    <div className="condition-wrap tooth-image-condition-center">
      <FilledImage src={sImage} color={color} className="zoom-2x" />
    </div>
  );
}
