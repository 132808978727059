import Cookies from "cookies-js";
import { persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";

import auth from "../reducers/authReducer";
import loader from "../reducers/loaderReducer";
import popup from "../reducers/popupReducer";
import session from "../reducers/sessionReducer";
import dashboard from "../reducers/dashboardReducer";
import calendar from "../reducers/calendarReducer";
import callList from "../reducers/callListReducer";
import chart from "../reducers/chartReducer";
import chartSideSeries from "../reducers/chartSideSeriesReducer";
import chartSideClinicalNote from "../reducers/chartSideClinicalNoteReducer";
import chartSideFillInForm from "../reducers/chartSideFillInFormReducer";
import chartSideMedicalFillInForm from "../reducers/chartSideMedicalFillInFormReducer";
import chartSideVital from "../reducers/chartSideVitalReducer";
import chartSideOrder from "../reducers/chartSideOrderReducer";
import appointment from "../reducers/appointmentReducer";
import patient from "../reducers/patientReducer";
import insurance from "../reducers/insuranceReducer";
import transaction from "../reducers/transactionReducer";
import patientView from "../reducers/patientViewReducer";
import patientList from "../reducers/patientListReducer";
import patientRegister from "../reducers/patientRegisterReducer";
import chartSidePrescription from "../reducers/chartSidePrescriptionReducer";
import chartDermatology from "../reducers/chartDermatologyReducer";
import chartSideTransaction from "../reducers/chartSideTransactionReducer";
import chartSidePlan from "../reducers/chartSidePlanReducer";
import chartSideDocs from "../reducers/chartSideDocsReducer";
import chartGeneral from "../reducers/chartGeneralReducer";
import settings from "../reducers/settingsReducer";
import waitingList from "../reducers/waitingListReducer";
import reminders from "../reducers/remindersReducer";
import simpleModal from "../reducers/simpleModalReducer";
import recallList from "../reducers/recallListReducer";
import blockedTimeSlot from "../reducers/blockedTimeSlotReducer";
import medication from "../reducers/medicationReducer";
import chartSidePostOp from "../reducers/chartSidePostOpReducer";
import repeatAppointment from "../reducers/repeatAppointmentReducer";
import followUp from "../reducers/followUpReducer";
import whatsappChatting from "../reducers/whatsAppChattingReducer";
import patientWhMessages from "../reducers/patientWhMessagesReducer";
import folder from "../reducers/folderReducer";
import language from "../reducers/languageReducer";
import app from "../reducers/appReducer";
import referral from "../reducers/referralReducer";

import { IS_DEV } from "../constants/AppConstants";

const cookieStorage = new CookieStorage(Cookies);

const authReducer = persistReducer(
  {
    key: "auth",
    whitelist: ["token", ...(IS_DEV ? ["adminLoggedIn", "loggedIn", "deviceExist"] : [])],
    storage: cookieStorage,
  },
  auth,
);

export interface AppStoreState {
  auth: any;
  popup: any;
}

const reducers = {
  auth: authReducer,
  loader,
  app,
  popup,
  language,
  session,
  dashboard,
  calendar,
  callList,
  chart,
  appointment,
  patient,
  insurance,
  chartSideSeries,
  chartSideClinicalNote,
  chartSideFillInForm,
  chartSideMedicalFillInForm,
  chartSideVital,
  chartSideOrder,
  chartSidePrescription,
  chartDermatology,
  chartSideTransaction,
  transaction,
  patientView,
  patientList,
  patientRegister,
  chartSidePlan,
  chartSideDocs,
  chartGeneral,
  settings,
  waitingList,
  reminders,
  simpleModal,
  recallList,
  blockedTimeSlot,
  medication,
  chartSidePostOp,
  repeatAppointment,
  followUp,
  folder,
  referral,
  whatsappChatting,
  patientWhMessages,
};

export default reducers;
