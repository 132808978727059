import React from "react";

import Utils from "../../../helpers/Utils";

import ChartTooth from "./ChartTooth";

export default function ChartTeeth(props) {
  const {
    toothNumericType,
    teeth,
    showTooltip,
    showChooseTooltip,
    showSideBar,
    activeTooth,
    type,
    eyeCheckedMap,
  } = props;
  const up = [];
  const down = [];

  Utils.objectForEach(teeth, (tooth, toothPositionCode) => {
    const index = toothPositionCode.slice(1);

    if (index <= 16) {
      up[index - 1] = tooth;
    } else {
      down[32 - index] = tooth;
    }
  });
  const $up = up.map((tooth, index) => (
    <ChartTooth
      key={index}
      toothPosition={index + 1}
      tooth={tooth}
      type={type}
      activeTooth={activeTooth}
      toothNumericType={toothNumericType}
      showTooltip={showTooltip}
      showChooseTooltip={showChooseTooltip}
      showSideBar={showSideBar}
      eyeCheckedMap={eyeCheckedMap}
      up={true}
    />
  ));
  const $down = down.map((tooth, index) => (
    <ChartTooth
      key={index}
      toothPosition={32 - index}
      tooth={tooth}
      activeTooth={activeTooth}
      type={type}
      toothNumericType={toothNumericType}
      showSideBar={showSideBar}
      showTooltip={showTooltip}
      showChooseTooltip={showChooseTooltip}
      eyeCheckedMap={eyeCheckedMap}
      down={true}
    />
  ));

  return (
    <div className="teeth-view d-flex flex-column" style={{ flex: 1 }}>
      <div className="up d-flex" style={{ marginBottom: "-32px", flex: 1 }}>
        {$up}
      </div>
      <div
        className="d-flex flex-column justify-content-center"
        style={{
          minHeight: "65px",
          backgroundColor: "#ededed",
        }}
      >
        <div style={{ height: "1px", backgroundColor: "white" }} />
      </div>
      <div className="down d-flex" style={{ marginTop: "-32px", flex: 1 }}>
        {$down}
      </div>
    </div>
  );
}
