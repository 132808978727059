import React from "react";
import { Field, reduxForm } from "redux-form";

import * as repeatAppointmentActions from "../../../actions/repeatAppointmentActions";
import { connect } from "react-redux";
import { SelectPicker } from "../../select-picker/SelectPicker";

const enhancer = connect(({ repeatAppointment }) => ({ repeatAppointment }));
const form = reduxForm({
  form: "repeatAppointmentAnotherDoctor",
  enableReinitialize: true,
});

class RepeatAppointmentAnotherDoctorForm extends React.Component {
  componentDidMount() {
    this.props.dispatch(repeatAppointmentActions.getDoctors());
    this.props.dispatch(repeatAppointmentActions.initTrxRooms());
  }

  render() {
    const {
      handleSubmit,
      repeatAppointment: { doctors, trxRooms },
    } = this.props;

    const doctorOptions = doctors.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const trxRoomOptions = trxRooms.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return (
      <form
        onSubmit={handleSubmit(() =>
          this.props.dispatch(repeatAppointmentActions.submitAnotherDoctor()),
        )}
      >
        <h4 className="mb-2 d-flex align-items-center justify-content-center">Add appointment</h4>
        <div className="form-group">
          <label htmlFor="doctor">Doctor:</label>
          <Field
            component={({ input }) => (
              <SelectPicker
                value={{ value: input.value }}
                onChange={(x) => {
                  input.onChange(x.value);
                  this.props.dispatch(repeatAppointmentActions.getTrxRooms(x.value));
                }}
                options={doctorOptions}
              />
            )}
            name="doctor"
            id="doctor"
          />
        </div>

        <div className="form-group">
          <label htmlFor="trxRoom">Trx Room:</label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={trxRoomOptions}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="trxRoom"
            id="trxRoom"
          />
        </div>

        <div className="form-group">
          <label htmlFor="note">Appointment notes:</label>
          <Field component="textarea" name="note" id="note" className="form-control" />
        </div>

        <div>
          <button type="submit" className="btn btn-primary btn-block">
            Add
          </button>
        </div>
      </form>
    );
  }
}

export default enhancer(form(RepeatAppointmentAnotherDoctorForm));
