import React from "react";
import FilledImage from "../../widgets/FilledImage";
import ToothAcheSensitiveImage from "../../../assets/images/conditions/tooth_ache_sensitive@2x.png";
import AcutePeriapicalImage from "../../../assets/images/conditions/acute_periapical@2x.png";
import ChronicPeriapicalImage from "../../../assets/images/conditions/chronic_periapical@2x.png";
import PulpitisImage11 from "../../../assets/images/conditions/pulpits/pulpitis_11_1@2x.png";
import PulpitisImage12 from "../../../assets/images/conditions/pulpits/pulpitis_12_1@2x.png";
import PulpitisImage13 from "../../../assets/images/conditions/pulpits/pulpitis_13_1@2x.png";
import PulpitisImage14 from "../../../assets/images/conditions/pulpits/pulpitis_14_1@2x.png";
import PulpitisImage15 from "../../../assets/images/conditions/pulpits/pulpitis_15_1@2x.png";
import PulpitisImage16 from "../../../assets/images/conditions/pulpits/pulpitis_16_1@2x.png";
import PulpitisImage17 from "../../../assets/images/conditions/pulpits/pulpitis_17_1@2x.png";
import PulpitisImage18 from "../../../assets/images/conditions/pulpits/pulpitis_18_1@2x.png";
import PulpitisImage21 from "../../../assets/images/conditions/pulpits/pulpitis_21_1@2x.png";
import PulpitisImage22 from "../../../assets/images/conditions/pulpits/pulpitis_22_1@2x.png";
import PulpitisImage23 from "../../../assets/images/conditions/pulpits/pulpitis_23_1@2x.png";
import PulpitisImage24 from "../../../assets/images/conditions/pulpits/pulpitis_24_1@2x.png";
import PulpitisImage25 from "../../../assets/images/conditions/pulpits/pulpitis_25_1@2x.png";
import PulpitisImage26 from "../../../assets/images/conditions/pulpits/pulpitis_26_1@2x.png";
import PulpitisImage27 from "../../../assets/images/conditions/pulpits/pulpitis_27_1@2x.png";
import PulpitisImage28 from "../../../assets/images/conditions/pulpits/pulpitis_28_1@2x.png";
import PulpitisImage31 from "../../../assets/images/conditions/pulpits/pulpitis_31_1@2x.png";
import PulpitisImage32 from "../../../assets/images/conditions/pulpits/pulpitis_32_1@2x.png";
import PulpitisImage33 from "../../../assets/images/conditions/pulpits/pulpitis_33_1@2x.png";
import PulpitisImage34 from "../../../assets/images/conditions/pulpits/pulpitis_34_1@2x.png";
import PulpitisImage35 from "../../../assets/images/conditions/pulpits/pulpitis_35_1@2x.png";
import PulpitisImage36 from "../../../assets/images/conditions/pulpits/pulpitis_36_1@2x.png";
import PulpitisImage37 from "../../../assets/images/conditions/pulpits/pulpitis_37_1@2x.png";
import PulpitisImage38 from "../../../assets/images/conditions/pulpits/pulpitis_38_1@2x.png";
import PulpitisImage41 from "../../../assets/images/conditions/pulpits/pulpitis_41_1@2x.png";
import PulpitisImage42 from "../../../assets/images/conditions/pulpits/pulpitis_42_1@2x.png";
import PulpitisImage43 from "../../../assets/images/conditions/pulpits/pulpitis_43_1@2x.png";
import PulpitisImage44 from "../../../assets/images/conditions/pulpits/pulpitis_44_1@2x.png";
import PulpitisImage45 from "../../../assets/images/conditions/pulpits/pulpitis_45_1@2x.png";
import PulpitisImage46 from "../../../assets/images/conditions/pulpits/pulpitis_46_1@2x.png";
import PulpitisImage47 from "../../../assets/images/conditions/pulpits/pulpitis_47_1@2x.png";
import PulpitisImage48 from "../../../assets/images/conditions/pulpits/pulpitis_48_1@2x.png";

export default function condition(props) {
  const getConditionPulpitsImage = (toothImage) => {
    switch (toothImage) {
      case 11:
        return PulpitisImage11;
      case 12:
        return PulpitisImage12;
      case 13:
        return PulpitisImage13;
      case 14:
        return PulpitisImage14;
      case 15:
        return PulpitisImage15;
      case 16:
        return PulpitisImage16;
      case 17:
        return PulpitisImage17;
      case 18:
        return PulpitisImage18;
      case 21:
        return PulpitisImage21;
      case 22:
        return PulpitisImage22;
      case 23:
        return PulpitisImage23;
      case 24:
        return PulpitisImage24;
      case 25:
        return PulpitisImage25;
      case 26:
        return PulpitisImage26;
      case 27:
        return PulpitisImage27;
      case 28:
        return PulpitisImage28;
      case 31:
        return PulpitisImage31;
      case 32:
        return PulpitisImage32;
      case 33:
        return PulpitisImage33;
      case 34:
        return PulpitisImage34;
      case 35:
        return PulpitisImage35;
      case 36:
        return PulpitisImage36;
      case 37:
        return PulpitisImage37;
      case 38:
        return PulpitisImage38;
      case 41:
        return PulpitisImage41;
      case 42:
        return PulpitisImage42;
      case 43:
        return PulpitisImage43;
      case 44:
        return PulpitisImage44;
      case 45:
        return PulpitisImage45;
      case 46:
        return PulpitisImage46;
      case 47:
        return PulpitisImage47;
      case 48:
        return PulpitisImage48;
      default:
        return "";
    }
  };
  const { property, toothImage, color } = props;
  let className = "ConditionCrown-wrap";
  let img;

  if (!property) return null;
  if (property.code === "ToothAche_Sensitive") {
    const src = ToothAcheSensitiveImage;

    img = <img src={src} className="zoom-2x" alt="" />;
    className += " tooth-image-ConditionCrown-center";
  } else if (property.code === "ToothAche_Pulpitis") {
    const src = getConditionPulpitsImage(toothImage);

    img = <FilledImage src={src} color={color} className="zoom-2x" />;
    className += " tooth-image-ConditionCrown";
  } else if (property.code === "ToothAche_Acute_Periapical") {
    const src = AcutePeriapicalImage;

    img = <img src={src} className="zoom-2x" alt="" />;
    className += " tooth-image-ConditionCrown-bottom";
  } else if (property.code === "ToothAche_Chronic_Periapical") {
    const src = ChronicPeriapicalImage;

    img = <img src={src} className="zoom-2x" alt="" />;
    className += " tooth-image-ConditionCrown-bottom";
  } else {
    return null;
  }

  return <div className={className}>{img}</div>;
}
