import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { Routes } from "../../constants/Routes";
import { PermissionTypes } from "../../dto/SessionDTO";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { AppointmentPatientView } from "./AppointmentPatientView";
import { ChartSideBarWrapper } from "../chart/ChartSideBarWrapper";
import { isActivePermissionSelector } from "../../reducers/sessionReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { ChartBalanceModalWrapper } from "../patient/ChartBalanceModalWrapper";
import { ConsultationNotesSidebarWrapper } from "../appointment-form/consultation-notes/ConsultationNotesSidebarWrapper";
import {
  getPatient,
  getPatientBalance,
  showSideBar as showClinical,
  printPatientLabel,
  setPatientLabelModalVisible,
  chartBalanceModalShow,
  updateChartBillingBalanceValue,
} from "../../actions/chartActions";
import {
  consultationNotesShow,
  getAdvancedPayments,
  selectPrice,
  showSideBar,
  togglePriceStatus,
  updateAppointmentEligibilityStatus,
} from "../../actions/appointmentActions";
import {
  appointmentItemSelector,
  appointmentPatientSelector,
  appointmentSelector,
  switchersSelector,
} from "../../reducers/appointmentReducer";
import { DocsWrapper } from "../chart/side-bar/docs/DocsWrapper";
import { initFolders } from "../../actions/folderActions";
import { setInitialValues } from "../../actions/referralActions";

interface Query {
  readonly isSchedule: string;
  readonly patientKey: string;
}

interface Props {
  readonly elements: any;
  readonly canViewContacts: boolean;

  readonly onProfileClick: () => void;
  readonly onShowEligibilityDetails: () => void;
  readonly onShowInsuranceForm: () => void;
  readonly eligibilityIdPayer: string;
  readonly setEligibilityIdPayer: (value: string) => void;
  readonly checkEligibilityVisible: boolean;
  readonly eligibilityStatus: boolean | undefined;
  readonly patientLabelModalVisible: boolean | undefined;
  readonly patientLabel: string;
  readonly setEligibilityStatus: (value: boolean | undefined) => void;
  readonly chartBillingBalanceValue: any;
}

export function AppointmentPatientViewWrapper({
  elements,
  canViewContacts,
  onProfileClick,
  eligibilityIdPayer,
  eligibilityStatus,
  setEligibilityStatus,
  setEligibilityIdPayer,
  onShowEligibilityDetails,
  checkEligibilityVisible,
  patientLabelModalVisible,
  patientLabel,
  chartBillingBalanceValue,
  onShowInsuranceForm,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const switchers = useShallowEqualSelector(switchersSelector as any);
  const appointment = useShallowEqualSelector(appointmentSelector as any);
  const patient = useShallowEqualSelector(appointmentPatientSelector as any);
  const appointmentItem = useShallowEqualSelector(appointmentItemSelector as any);
  const isPlanned = useShallowEqualSelector(
    isActivePermissionSelector(
      PermissionTypes.AddPlannedDentalTransactionItemWithoutAppointment,
    ) as any,
  );

  const [showDocsModal, setShowDocsModal] = useState(false);

  const appointmentId = location.pathname.split("/");

  const query = parseQuery<Query>(location.search);

  const patientKey = _.get(patient, "patientKey");
  useEffect(() => {
    if (patientKey) {
      dispatch(getPatient({ patientKey: patientKey || query.patientKey }));
      dispatch(getPatientBalance(_.get(patient, "patientKey")));
    } else {
      dispatch(updateChartBillingBalanceValue());
    }
  }, [dispatch, patient]);

  if (!patient && query.isSchedule === "true") {
    return null;
  }

  return (
    <>
      <AppointmentPatientView
        patient={patient}
        elements={elements}
        switchers={switchers}
        isPlanned={isPlanned}
        appointment={appointmentItem}
        canViewContacts={canViewContacts}
        eligibilityIdPayer={eligibilityIdPayer}
        eligibilityStatus={eligibilityStatus}
        setEligibilityStatus={setEligibilityStatus}
        setEligibilityIdPayer={setEligibilityIdPayer}
        onShowEligibilityDetails={onShowEligibilityDetails}
        onShowInsuranceForm={onShowInsuranceForm}
        checkEligibilityVisible={checkEligibilityVisible}
        chartBillingBalanceValue={chartBillingBalanceValue}
        updateAppointmentEligibilityStatus={(status) =>
          dispatch(updateAppointmentEligibilityStatus(status))
        }
        onDocsClick={() => {
          dispatch(initFolders());
          setShowDocsModal(true);
        }}
        onProfileClick={onProfileClick}
        onFillInFormClick={() => dispatch(showSideBar())}
        onPrintLabelClick={() => dispatch(printPatientLabel(patientKey || query.patientKey))}
        patientLabel={patientLabel}
        patientLabelModalVisible={patientLabelModalVisible}
        setPatientLabelModalVisible={(bool) => dispatch(setPatientLabelModalVisible(bool))}
        onReferralClick={() => {
          const chartNumber =
            _.get(appointment, "appointment.patient.chartNumber") ||
            _.get(appointmentItem, "patient.chartNumber");
          dispatch(setInitialValues({ chartNumber }));

          history.push(
            createUrl(Routes.DashboardReferralNextVisitList, {
              query: {
                chartNumber,
              },
            }),
          );
        }}
        onConsultationNotesClick={() => dispatch(consultationNotesShow())}
        onSelectPrice={(priceId) => dispatch(selectPrice(priceId))}
        onTogglePrice={(state) => dispatch(togglePriceStatus(state))}
        onPatientBalanceClick={() => dispatch(chartBalanceModalShow())}
        onConsentFormClick={() => {
          history.push(
            createUrl(Routes.PatientFormsList, {
              query: {
                patientKey:
                  _.get(appointment, "appointment.patient.patientKey") ||
                  _.get(appointmentItem, "patient.patientKey"),
              },
            }),
          );
        }}
        onAppointmentHistoryClick={() => {
          history.push(
            createUrl(Routes.PatientAppointmentsList, {
              query: {
                ...(parseQuery(location.search) as any),
                appointmentForm: "true",
                appointmentId: appointmentId[appointmentId.length - 1],
                patientKey:
                  _.get(appointment, "appointment.patient.patientKey") ||
                  _.get(appointmentItem, "patient.patientKey"),
              },
            }),
          );
        }}
        onVitalClick={() =>
          dispatch(showClinical(viewTypes.vital, { patientKey: _.get(patient, "patientKey") }))
        }
        onClinicalNotesClick={() =>
          dispatch(
            showClinical(viewTypes.clinicalNote, { patientKey: _.get(patient, "patientKey") }),
          )
        }
        onInsuranceClick={() =>
          history.push(
            createUrl(Routes.DashboardInsurance, {
              params: { patientKey: _.get(patient, "patientKey") },
            }),
          )
        }
      />

      <ChartSideBarWrapper patientKey={_.get(patient, "patientKey")} />

      <ChartBalanceModalWrapper />

      <ConsultationNotesSidebarWrapper patientKey={_.get(patient, "patientKey")} />

      <DocsWrapper
        showDocsModal={showDocsModal}
        onShow={() => setShowDocsModal(true)}
        onHide={() => setShowDocsModal(false)}
      />
    </>
  );
}
