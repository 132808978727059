import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getAppointmentPlannedTreatment,
  onSaveConsumedCancelledTreatments,
  setAppointmentPlannedTreatmentModalVisible,
  toggleAppointmentPlannedTreatment,
  toggleAppointmentPlannedTreatmentAll,
} from "../../actions/appointmentActions";
import { Button, FormCheck } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import { sortTreatments } from "../../helpers/TreatmentUtils";
import moment from "moment/moment";
import _ from "lodash";
import { useHistory } from "react-router";
import { STATUS_TYPES } from "../../constants/StatusTypes";

const enhancer = connect(({ appointment, session: { clinic } }) => ({ appointment, clinic }));
const AppointmentPlannedTreatmentsModal = (props) => {
  const {
    visible,
    changeAppointmentStatus,
    appointment: {
      appointmentPlannedTreatmentList,
      appointmentPlannedTreatmentListSelected,
      appointmentPlannedTreatmentListSelectedAll,
    },
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (visible) {
      const {
        appointment: {
          item: {
            id,
            patient: { patientKey },
          },
        },
        clinic,
      } = props;
      dispatch(
        getAppointmentPlannedTreatment({
          patientKey,
          appointmentId: id,
          treatmentPlanned: true,
          start: 0,
          sortField: "created_date",
          clinicId: clinic.id,
        }),
      );
    }
  }, [visible]);

  const generateList = (list) => {
    return list.sort(sortTreatments).map((item, index) => {
      return (
        <div
          className="this-table-row flex-shrink-0"
          key={index}
          onClick={() => dispatch(toggleAppointmentPlannedTreatment(item.key))}
        >
          <div className="default-table-col col-1 d-flex align-items-center">
            <div className="middler">
              {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="default-table-col col-2 d-flex align-items-center">
            <div className="middler">{_.get(item.treatmentItem, "code")}</div>
          </div>
          <div className="default-table-col col-3 d-flex align-items-center">
            <div className="middler text-ellipsis" title={_.get(item.treatmentItem, "description")}>
              {_.get(item.treatmentItem, "description")}
            </div>
          </div>
          <div className="default-table-col col-4 d-flex align-items-center">
            <div className="middler">{_.get(item, "tooth.name")}</div>
          </div>
          <div className="default-table-col col-5 d-flex align-items-center">
            <div className="middler">{_.toFinite(item.price).toFixed(2)}</div>
          </div>
          <div className="default-table-col col-6 d-flex align-items-center">
            <div className="middler">{_.get(item.status, "name")}</div>
          </div>
          <div className="default-table-col col-7 d-flex align-items-center">
            <FormCheck
              checked={appointmentPlannedTreatmentListSelected[item.key]}
              readOnly={true}
            />
          </div>
        </div>
      );
    });
  };

  let selectedArray = [];
  for (const [key, property] of Object.entries(appointmentPlannedTreatmentListSelected)) {
    // eslint-disable-next-line no-loop-func
    if (property) {
      selectedArray = [...selectedArray, key];
    } else {
      const index = selectedArray.indexOf(property);
      if (index > -1) {
        selectedArray.splice(index, 1);
      }
    }
  }

  const onSave = () => {
    dispatch(onSaveConsumedCancelledTreatments({ keys: selectedArray })).then(() => {
      changeAppointmentStatus(STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED);
      history.goBack();
    });
  };

  if (!visible) return null;

  return (
    <Modal
      size="lg"
      title={<div>Please choose planned treatments</div>}
      actions={
        <div>
          <Button
            onClick={() => {
              dispatch(setAppointmentPlannedTreatmentModalVisible(false));
            }}
          >
            Cancel
          </Button>
          <Button disabled={!selectedArray.length} onClick={onSave}>
            Save
          </Button>
        </div>
      }
      show={visible}
      onHide={() => dispatch(setAppointmentPlannedTreatmentModalVisible(false))}
      keyboard={false}
    >
      <div className="default-table related-this-table">
        <div className="default-table-header">
          <div className="default-table-col col-1">Date Posted</div>
          <div className="default-table-col col-2">Treatment code</div>
          <div className="default-table-col col-3">Description</div>
          <div className="default-table-col col-4">Tooth</div>
          <div className="default-table-col col-5">Fee</div>
          <div className="default-table-col col-6">Status</div>
          <div
            className="default-table-col col-7"
            style={{ marginTop: "3px" }}
            onClick={() =>
              dispatch(
                toggleAppointmentPlannedTreatmentAll(!appointmentPlannedTreatmentListSelectedAll),
              )
            }
          >
            <FormCheck readOnly={true} checked={appointmentPlannedTreatmentListSelectedAll} />
          </div>
        </div>
        <div className="default-table-body">{generateList(appointmentPlannedTreatmentList)}</div>
      </div>
    </Modal>
  );
};

export default enhancer(AppointmentPlannedTreatmentsModal);
