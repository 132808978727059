import _ from "lodash";
import createReducer from "../helpers/createReducer";
import * as ActionTypes from "../actionTypes/remindersActionTypes";

import { GET_STATUS_LIST_SUCCESS } from "../actionTypes/appointmantActionTypes";
import Utils from "../helpers/Utils";
import { STATUS_TYPES } from "../constants/StatusTypes";
const noneOption = { value: 0, label: "None" };
const defaultAppointmentStatuses = [
  { value: STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED },
  { value: STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W },
  { value: STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2 },
];
const defaultState = {
  // -----------------
  automaticListSelected: [],
  automaticEditMode: false,
  automaticList: [],
  automaticListTotalCount: 0,
  fetchingAutomaticList: false,
  //= =================
  manualList: [],
  manualListTotalCount: 0,
  recipientType: "EMPLOYEE",
  fetchingManualList: false,
  //= =================
  toggleDentistVisible: false,
  toggleDentistMode: "all",
  toggleDentistSelected: {},
  searchDentist: "",
  searchDentistSelected: "",
  dentistList: [],
  fetchingDentistList: false,
  //= =================
  togglePatientVisible: false,
  togglePatientMode: "all",
  togglePatientSelected: {},
  togglePatientSelectedFullData: [],
  searchPatient: "",
  searchPatientSelected: "",
  patientListTotalCount: 0,
  searchCalendarPatient: "",
  fetchingPatientList: false,
  patientList: [],
  calendarPatientList: [],
  fetchingCalendarPatientList: false,
  calendarPatientListTotalCount: 0,
  clinicId: 0,
  //------------------
  templatesList: [],
  remindersMode: "manual",
  notificationType: "SMS_NOTIFICATION",
  templateLabel: "Choose sms template",
  remindersModalVisible: false,
  calendarFilterValue: 0,
  categoryList: [],
  selectedCategories: [noneOption],
  lastMode: "all",

  doctorSelected: [],
  selectedDoctors: [noneOption],

  statusList: [],
  selectedStatuses: defaultAppointmentStatuses,

  balance: null,
  showBalance: false,
};

const reducers = {
  [ActionTypes.REMINDER_RESET](state) {
    return {
      ...defaultState,
      remindersModalVisible: state.remindersModalVisible,
    };
  },
  [ActionTypes.REMINDERS_APPOINTMENT_FILTER_SELECT](state, { values }) {
    const noneSelected = values.find((i) => i.value === 0);
    if (noneSelected || !values.length) {
      return {
        ...state,
        selectedStatuses: [noneOption],
      };
    }
    return {
      ...state,
      selectedStatuses: values,
    };
  },
  [ActionTypes.REMINDERS_APPOINTMENT_CLINIC_FILTER_SELECT](state, { id }) {
    return {
      ...state,
      clinicId: id,
    };
  },
  [GET_STATUS_LIST_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      statusList: data.map((x) => ({ ...x, active: false })),
    };
  },
  [ActionTypes.REMINDERS_GET_CATEGORY_LIST_START](state) {
    return {
      ...state,
      categoryList: [],
    };
  },
  [ActionTypes.REMINDERS_GET_CATEGORY_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      categoryList: data,
    };
  },
  [ActionTypes.REMINDERS_SET_CALENDAR_PATIENT_FILTER](state, { values }) {
    const noneSelected = values.find((i) => i.value === 0);
    if (noneSelected || !values.length) {
      return {
        ...state,
        selectedCategories: [noneOption],
      };
    }
    return {
      ...state,
      selectedCategories: values,
    };
  },
  [ActionTypes.REMINDERS_COPY_DOCTORS](state, { members }) {
    const copyMembers = JSON.parse(JSON.stringify(members));

    const doctorSelected = [];

    _.forEach(copyMembers, (item) => {
      doctorSelected.push(item);
    });

    return {
      ...state,
      doctorSelected,
    };
  },
  [ActionTypes.REMINDERS_SET_DOCTOR_FILTER](state, { values }) {
    const noneSelected = values.find((i) => i.value === 0);
    if (noneSelected || !values.length) {
      return {
        ...state,
        selectedDoctors: [noneOption],
      };
    }
    return {
      ...state,
      selectedDoctors: values,
    };
  },
  [ActionTypes.REMINDERS_SELECT_ALL_CALENDAR_PATIENT](state, { mode }) {
    let togglePatientSelected = {
      ...state.togglePatientSelected,
    };

    if (mode === "select") {
      if (!_.isEmpty(state.calendarPatientList)) {
        state.calendarPatientList.map((item) => {
          const isTemp = _.isFinite(item.companyTempPatientId);

          if (_.isEmpty(togglePatientSelected[_.get(item, "appointmentId")])) {
            // if (isTemp) {
            //   togglePatientSelected[_.get(item, "companyTempPatientId")] = {
            //     ...item,
            //     // code: _.get(item, "companyTempPatientId"),
            //   };
            // } else {
            togglePatientSelected[_.get(item, "appointmentId")] = {
              ...item,
              // id: _.get(item, "companyPatientId"),
            };
            // }
          }
        });
      }
    } else {
      togglePatientSelected = {};
    }

    return {
      ...state,
      togglePatientSelected,
    };
  },
  [ActionTypes.REMINDERS_GET_CALENDAR_PATIENT_LIST_START](state) {
    return {
      ...state,
      fetchingCalendarPatientList: true,
      togglePatientSelected: {},
    };
  },
  [ActionTypes.REMINDERS_GET_CALENDAR_PATIENT_LIST_ERROR](state) {
    return {
      ...state,
      fetchingCalendarPatientList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_CALENDAR_PATIENT_LIST_SUCCESS](state, { data, request }) {
    const calendarPatientList = [];
    _.forEach(_.get(data, "list", []), (item) => {
      const list = _.get(item, "patient", false);

      if (list) {
        _.forEach(list, (listItem) => {
          if (
            !_.find(
              calendarPatientList,
              (o) =>
                _.get(o, "patient.companyPatientId") ===
                _.get(listItem, "patient.companyPatientId"),
            )
          ) {
            calendarPatientList.push(listItem);
          }
        });
      }
    });
    if (request.start) {
      return {
        ...state,
        calendarPatientList: state.calendarPatientList.concat(data.list || []).map((i) => {
          const patientData = i.appointmentId ? i : i.patient;
          if (i.description === "tempUser") {
            return {
              ...patientData,
              appointmentId: i.appointmentId || i.id,
              status: i.status,
              dentist: i.dentist,
              // code: `${i.code}_${Math.floor(1000 + Math.random() * 9000)}`,
            };
          }
          return {
            ...patientData,
            appointmentId: i.appointmentId || i.id,
            status: i.status,
            dentist: i.dentist,
          };
        }),
        fetchingCalendarPatientList: false,
        calendarPatientListTotalCount: data?.totalCount || 0,
      };
    }
    return {
      ...state,
      calendarPatientList: (data.list || []).map((i) => {
        if (i.description === "tempUser") {
          return {
            ...i.patient,
            appointmentId: i.id,
            status: i.status,
            dentist: i.dentist,
            // code: `${i.code}_${Math.floor(1000 + Math.random() * 9000)}`,
          };
        }
        return {
          ...i.patient,
          appointmentId: i.id,
          status: i.status,
          dentist: i.dentist,
        };
      }),
      fetchingCalendarPatientList: false,
      calendarPatientListTotalCount: data?.totalCount || 0,
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_DENTIST](state, { id }) {
    const toggleDentistSelected = {
      ...state.toggleDentistSelected,
    };

    if (_.isEmpty(toggleDentistSelected[id])) {
      toggleDentistSelected[id] = _.find(state.dentistList, ["id", id]);
    } else {
      _.unset(toggleDentistSelected, id);
    }

    return {
      ...state,
      toggleDentistSelected,
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_PATIENT](
    state,
    { temp, id, patientFromAppointmentView, companyPatientId },
  ) {
    const togglePatientSelected = {
      ...state.togglePatientSelected,
    };

    const togglePatientSelectedFullData = [...state.togglePatientSelectedFullData];

    if (state.togglePatientMode === "calendar") {
      if (_.isEmpty(togglePatientSelected[id])) {
        if (temp) {
          togglePatientSelected[id] = _.find(
            state.calendarPatientList,
            (o) => _.get(o, "appointmentId") === id,
          );
        } else {
          togglePatientSelected[id] = _.find(
            state.calendarPatientList,
            (o) => _.get(o, "appointmentId") === id,
          );
        }
      } else {
        _.unset(togglePatientSelected, id);
      }
    } else if (state.togglePatientMode === "selected") {
      _.unset(togglePatientSelected, id);
    } else if (_.isEmpty(togglePatientSelected[id]) && !patientFromAppointmentView) {
      const findObject = _.find(state.patientList, ["id", id]);
      togglePatientSelected[id] = {
        id: findObject.id,
        name: findObject.fullname,
        companyPatientId,
      };
      togglePatientSelectedFullData.push(findObject);
    } else if (_.isEmpty(togglePatientSelected[id]) && patientFromAppointmentView) {
      togglePatientSelected[id] = {
        id: patientFromAppointmentView.appointmentId,
        appointmentId: patientFromAppointmentView.appointmentId,
        name: Utils.getFullName(patientFromAppointmentView),
        companyPatientId,
      };
      togglePatientSelectedFullData.push(patientFromAppointmentView);
    } else {
      _.unset(togglePatientSelected, id);
    }
    return {
      ...state,
      togglePatientSelected,
      togglePatientSelectedFullData,
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_LIST_START](state) {
    return {
      ...state,
      fetchingPatientList: true,
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      patientList: request.start ? state.patientList.concat(data.list) : data.list,
      patientListTotalCount: data.totalCount,
      fetchingPatientList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_LIST_ERROR](state) {
    return {
      ...state,
      fetchingPatientList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_SUCCESS](state, { data }) {
    return {
      ...state,
      patientList: data,
    };
  },
  [ActionTypes.REMINDERS_SAVE_AUTOMATIC_SUCCESS](state) {
    return {
      ...state,
      toggleDentistSelected: {},
    };
  },
  [ActionTypes.REMINDERS_SAVE_MANUAL_SUCCESS](state) {
    return {
      ...state,
      toggleDentistSelected: {},
      togglePatientSelected: {},
      togglePatientSelectedFullData: [],
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_PATIENT_MODAL_SHOW](state) {
    return {
      ...state,
      togglePatientVisible: true,
      searchPatient: "",
      searchPatientSelected: "",
    };
  },
  [ActionTypes.REMINDERS_SET_PATIENT_MODE](state, { mode }) {
    let togglePatientSelected = {
      ...state.togglePatientSelected,
    };

    let { lastMode } = state;

    if (mode !== "selected" && state.lastMode !== mode) {
      togglePatientSelected = {};
      lastMode = mode;
    }

    return {
      ...state,
      togglePatientMode: mode,
      searchPatient: "",
      searchPatientSelected: "",
      togglePatientSelected,
      lastMode,
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_PATIENT_MODAL_HIDE](state) {
    return {
      ...state,
      togglePatientVisible: false,
    };
  },
  [ActionTypes.REMINDERS_SET_NOTIFICATION_TYPE](state, { notificationType }) {
    return {
      ...state,
      notificationType,
    };
  },
  [ActionTypes.REMINDERS_DELETE_SELECTED_SUCCESS](state) {
    return {
      ...state,
      automaticListSelected: [],
    };
  },
  [ActionTypes.REMINDERS_SET_RECIPIENT_TYPE](state, { recipientType }) {
    return {
      ...state,
      recipientType: recipientType.toUpperCase(),
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_AUTOMATIC_LIST_SELECTED](state, { id }) {
    const automaticListSelected = [...state.automaticListSelected];

    const selectedIndex = _.indexOf(automaticListSelected, id);

    if (selectedIndex !== -1) {
      automaticListSelected.splice(selectedIndex, 1);
    } else {
      automaticListSelected.push(id);
    }

    return {
      ...state,
      automaticListSelected,
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_AUTOMATIC_EDIT_MODE](state) {
    return {
      ...state,
      automaticEditMode: !state.automaticEditMode,
      automaticListSelected: [],
    };
  },
  [ActionTypes.REMINDERS_MODAL_SHOW](state) {
    return {
      ...state,
      remindersModalVisible: true,
      remindersMode: "manual",
    };
  },
  [ActionTypes.SET_REMINDERS_MODE](state, { data }) {
    return {
      ...state,
      remindersMode: data,
      toggleDentistSelected: {},
      togglePatientSelected: {},
    };
  },
  [ActionTypes.REMINDERS_SEARCH_DENTIST](state, { value }) {
    return {
      ...state,
      searchDentist: value,
    };
  },
  [ActionTypes.REMINDERS_SEARCH_DENTIST_SELECTED](state, { value }) {
    return {
      ...state,
      searchDentistSelected: value,
    };
  },
  [ActionTypes.REMINDERS_SEARCH_PATIENT](state, { value }) {
    return {
      ...state,
      searchPatient: value,
    };
  },
  [ActionTypes.REMINDERS_SEARCH_PATIENT_SELECTED](state, { value }) {
    return {
      ...state,
      searchPatientSelected: value,
    };
  },
  [ActionTypes.REMINDERS_MODAL_HIDE](state) {
    return {
      ...state,
      remindersModalVisible: false,
    };
  },
  [ActionTypes.REMINDERS_SEARCH_CALENDAR_PATIENT](state, { value }) {
    return {
      ...state,
      searchCalendarPatient: value,
    };
  },
  [ActionTypes.REMINDERS_GET_AUTOMATIC_LIST_START](state) {
    return {
      ...state,
      fetchingAutomaticList: true,
    };
  },
  [ActionTypes.REMINDERS_GET_AUTOMATIC_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      automaticList: request.start
        ? state.automaticMode.automaticList.concat(data.list)
        : data.list,
      automaticListTotalCount: data.totalCount,
      fetchingAutomaticList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_AUTOMATIC_LIST_ERROR](state) {
    return {
      ...state,
      fetchingAutomaticList: false,
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_DENTIST_MODAL_SHOW](state) {
    return {
      ...state,
      toggleDentistVisible: true,
      toggleDentistMode: "all",
      searchDentist: "",
      searchDentistSelected: "",
    };
  },
  [ActionTypes.REMINDERS_SET_DENTIST_MODE](state, { data }) {
    return {
      ...state,
      toggleDentistMode: data,
      searchDentist: "",
      searchDentistSelected: "",
    };
  },
  [ActionTypes.REMINDERS_TOGGLE_DENTIST_MODAL_HIDE](state) {
    return {
      ...state,
      toggleDentistVisible: false,
    };
  },
  [ActionTypes.REMINDERS_GET_MANUAL_LIST_START](state) {
    return {
      ...state,
      fetchingManualList: true,
    };
  },
  [ActionTypes.REMINDERS_GET_MANUAL_LIST_ERROR](state) {
    return {
      ...state,
      fetchingManualList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_MANUAL_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      manualList: request.start ? state.manualList.concat(data.list) : data.list,
      manualListTotalCount: data.totalCount,
      fetchingManualList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_DENTIST_START](state) {
    return {
      ...state,
      fetchingDentistList: true,
    };
  },
  [ActionTypes.REMINDERS_GET_DENTIST_SUCCESS](state, { data }) {
    return {
      ...state,
      dentistList: data,
      fetchingDentistList: false,
    };
  },
  [ActionTypes.REMINDERS_CHECK_BALANCE_SUCCESS](state, { data }) {
    return {
      ...state,
      balance: data,
      // showBalance: !state.showBalance,
    };
  },
  [ActionTypes.REMINDERS_BALANCE_MODAL_TOGGLE](state) {
    return {
      ...state,
      showBalance: !state.showBalance,
    };
  },
  [ActionTypes.REMINDERS_GET_DENTIST_ERROR](state) {
    return {
      ...state,
      fetchingDentistList: false,
    };
  },
  [ActionTypes.REMINDERS_GET_TEMPLATES_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      templatesList: data,
      templateLabel: "Choose sms template",
    };
  },
  [ActionTypes.REMINDERS_GET_EMAIL_TEMPLATES_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      templatesList: data,
      templateLabel: "Choose email template",
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_APP_TEMPLATES_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      templatesList: data,
      templateLabel: "Choose app template",
    };
  },
  [ActionTypes.REMINDERS_GET_PATIENT_WHATS_APP_TEMPLATES_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      templatesList: data,
      templateLabel: "Choose WhatsApp template",
    };
  },
};

export default createReducer(defaultState, reducers);
