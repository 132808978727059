import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { updateTransactionList } from "../../../actions/transactionActions";
import { updateNoteApi } from "../../../actions/chartSideTransactionActions";

const enhancer = connect();

export const NoteCellWrapper = enhancer(
  class NoteCellWrapper extends React.PureComponent {
    static propTypes = {
      value: PropTypes.string,
      dispatch: PropTypes.func,
      transactionKey: PropTypes.string,
    };

    constructor(props) {
      super(props);

      this.state = {
        active: false,
        value: props.value,
      };
    }

    save = () => {
      const { value } = this.state;
      const { dispatch, transactionKey } = this.props;

      dispatch(startLoader());

      const data = {
        note: value,
        key: transactionKey,
      };

      dispatch(updateNoteApi(data))
        .then(() => {
          dispatch(updateTransactionList(data));

          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    };

    render() {
      const { active, value } = this.state;

      return (
        <div
          role="button"
          className="change-to-cash-wrapper align-items-center"
          style={{ margin: "-8px 0", marginLeft: "8px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            value={active}
            type="checkbox"
            className="mt-0 mr-1"
            onChange={() => this.setState((x) => ({ active: !x.active }))}
          />
          <input
            value={value}
            disabled={!active}
            className="form-control"
            onBlur={() => this.save()}
            onChange={({ target }) => this.setState({ value: target.value })}
            onKeyUp={(x) => {
              if (x.key === "Enter") {
                this.save();
              }
            }}
          />
        </div>
      );
    }
  },
);
