import React from "react";
import cx from "classnames";

export default function FilledImage(props) {
  const { src, color, className, zoom } = props;
  const style = {
    WebkitMaskImage: `url(${src})`,
    maskImage: `url(${src})`,
    backgroundColor: color,
    zoom: `${zoom}%`,
  };

  return (
    <div className={cx("fill-image", className)} style={style}>
      <img src={src} alt="" />
    </div>
  );
}
