import React from "react";
import PropTypes from "prop-types";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import deleteImage from "../../../../assets/images/chart/delete.png";

export default function ChartPrescriptionListItem(props) {
  const { item, openItem, removeItem } = props;

  return (
    <div className="chart-side-prescription-list-item" onClick={openItem}>
      <div className="item-title d-flex justify-content-between">
        {item.name}
        <span
          role="button"
          style={{
            padding: "3px",
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            removeItem();
          }}
        >
          <img
            src={deleteImage}
            alt="delete"
            style={{
              width: "17px",
              height: "17px",
            }}
          />
        </span>
      </div>
      <br />
      <div className="clearfix">
        <div className="pull-left">
          {Utils.parseDateFormat(Utils.calcDateWithTimeZone(item.createdDate), DATE_FORMAT_LEGACY)}
        </div>
        <div className="pull-right">
          by
          {item.dentist && item.dentist.name}
        </div>
      </div>
    </div>
  );
}
ChartPrescriptionListItem.propTypes = {
  item: PropTypes.object,
  openItem: PropTypes.func,
  removeItem: PropTypes.func,
};
