import React from "react";
import ChartPostOp from "../postOp/ChartPostOp";
import { connect } from "react-redux";

const enhancer = connect(({ chartSidePostOp }) => ({ chartSidePostOp }));

const MedicalFillInFormPostOp = ({ chartSidePostOp, history, location }) => {
  return (
    <div style={{ marginTop: "1.5rem" }}>
      <ChartPostOp chartSidePostOp={chartSidePostOp} history={history} location={location} />
    </div>
  );
};

export default enhancer(MedicalFillInFormPostOp);
