import React from "react";
import { TextField } from "../form/TextField";

export function BlockedTimeSlotDailyForm() {
  return (
    <div className="row d-flex align-items-center mb-2">
      <div className="col-sm-2 d-flex p-0 justify-content-end">Repeats every:</div>
      <div className="col-sm-4">
        <TextField type="number" name="repeatCount" />
      </div>
      <div className="col-sm-4">Day(s)</div>
      <div className="col-sm-2" />
    </div>
  );
}
