import React from "react";

import { defaultColors } from "../../../constants/defaults";
import { ToothConditionTypes } from "../../../constants/conditions/ToothConditionTypes";
import ChartToothImage from "./ChartToothImage";
import ChartToothNumber from "./ChartToothNumber";

export default function ToothItem(props) {
  const { numericKey, tooth, toothDetails, className, onClick } = props;
  const details = toothDetails || (tooth.normal && tooth.normal.details);
  let conditions = [];
  if (tooth.normal) {
    conditions.push(tooth.normal.toothConditions);
    if (tooth.normal.toothChangeConditions) {
      conditions = conditions.concat(tooth.normal.toothChangeConditions);
    }
  }

  let toothColor = defaultColors.Green.color;
  let toothStatusCode = "TOOTHSTATUS_NORMAL";

  conditions = conditions.sort(sortCondition);

  conditions.forEach((condition) => {
    if (condition.revert) return;

    const color = getColor(condition);
    if (condition.toothStatus) {
      toothStatusCode = condition.toothStatus.code;
      toothColor = color;
    }
    if (condition.residualRoot) {
      toothStatusCode = "TOOTH_CONDITION_RESIDUAL_ROOT";
    }
    if (condition.destroyedCrown) {
      toothStatusCode = "TOOTH_CONDITION_DESTROYED_CROWN";
    }
  });

  if (tooth.supernumerary) {
    let conditionsSuper = [tooth.supernumerary.toothConditions];
    if (tooth.supernumerary.toothChangeConditions) {
      conditionsSuper = conditionsSuper.concat(tooth.supernumerary.toothChangeConditions);
    }

    conditionsSuper.forEach((condition) => {
      if (condition.revert) {
        return;
      }
    });
  }

  return (
    <div className={`tooth ${className}`}>
      <div className="body" onClick={() => onClick(tooth, details[numericKey], details)}>
        <ChartToothImage
          imageNumber={tooth.image}
          statusCode={toothStatusCode}
          toothColor={toothColor}
        />
        <ChartToothNumber normalDetails={details} numericKey={numericKey} />
      </div>
    </div>
  );
}
function getColor(condition) {
  let type;
  let color;

  if (condition.treatment && condition.treatment.id) {
    type = ToothConditionTypes.ToothConditionTypeTreatmentPlanned;
  } else if (condition.diagnosisCode && condition.diagnosisCode.id) {
    type = ToothConditionTypes.ToothConditionTypeDiagnosis;
  } else if (condition.existingTreatment && condition.existingTreatment.id) {
    type = ToothConditionTypes.ToothConditionTypeExistingTreatment;
  } else {
    type = ToothConditionTypes.ToothConditionTypeTreatmentCompleted;
  }

  if (type == ToothConditionTypes.ToothConditionTypeDiagnosis) {
    color = defaultColors.Yellow.color;
  } else if (type == ToothConditionTypes.ToothConditionTypeTreatmentPlanned) {
    color = defaultColors.Red.color;
  } else if (type == ToothConditionTypes.ToothConditionTypeExistingTreatment) {
    color = defaultColors.Blue.color;
  } else if (type == ToothConditionTypes.ToothConditionTypeTreatmentCompleted) {
    color = defaultColors.Green.color;
  }

  return color;
}

function sortCondition(obj1, obj2) {
  if (!obj1.createdDate) obj1.createdDate = 0;
  if (!obj2.createdDate) obj2.createdDate = 0;

  if (
    (obj1.treatment && obj1.treatment.id) ||
    (obj1.existingTreatment && obj1.existingTreatment.id)
  ) {
    return obj2.diagnosisCode ? 1 : obj1.createdDate - obj2.createdDate;
  }
  if (obj1.diagnosisCode) {
    return obj2.diagnosisCode
      ? obj1.createdDate - obj2.createdDate
      : obj2.treatment || obj2.existingTreatment
      ? -1
      : obj1.createdDate - obj2.createdDate;
  }
  return obj1.createdDate - obj2.createdDate;
}
