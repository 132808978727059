import React from "react";
import Utils from "../../../../helpers/Utils";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import { useDispatch } from "react-redux";
import { setAddendumItem } from "../../../../actions/chartSideMedicalFillInFormActions";

const MedicalFormsAddendumList = ({ chartSideMedicalFillInForm: { addendumList } }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {addendumList.map((item) => (
        <div
          onClick={() => dispatch(setAddendumItem(item))}
          key={item.createdDate}
          role="button"
          className={`btn btn-default chart-clinical-note-item mb-1`}
          style={{
            position: "relative",
            paddingRight: "12px",
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <strong>{item.title}</strong>
          </div>

          <br />

          <div className="d-flex align-items-end">
            <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
              <div style={{ fontSize: "12px", color: "#333" }}>
                by &nbsp;
                {item.creator && item.creator.name}
              </div>
              <div style={{ fontSize: "12px", color: "#333" }}>
                {Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.createdDate),
                  DATE_FORMAT_LEGACY,
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MedicalFormsAddendumList;
