import React from "react";
import { isArray } from "lodash";
import { MultiValueProps } from "react-select";
import { makeStyles } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.common.black,
  },
}));

interface Props extends Omit<MultiValueProps<any>, "children"> {
  readonly value: any;
  readonly formatMultiValue?: (data: any, value: any) => any;
}

export function SelectPickerMultiValue(props: Props) {
  const classes = useStyles();

  if (props.selectProps.isSearchable && props.selectProps.menuIsOpen) {
    return null;
  }

  const value =
    props.data && props.data.label
      ? props.data
      : props.options.find((v) => v.value === props.data.value) || {};

  if (props.value && (props.value.length <= 1 || !isArray(props.value))) {
    return <span className={classes.root}>{value.label}</span>;
  }

  const index = props.value
    ? props.value.findIndex((x) => x.value === props.data.value)
    : undefined;

  if (index === 0) {
    return (
      <span className={classes.root}>
        {props.formatMultiValue
          ? props.formatMultiValue(props.value, value)
          : `Selected (${props.value.length})`}
      </span>
    );
  }

  return null;
}
