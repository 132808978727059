import React from "react";

import { Modal } from "../ui/Modal";

import * as followUpActions from "../../actions/followUpActions";
import SideBarHeader from "../layout/SideBarHeader";
import RepeatAppointmentTrxPlanningForm from "../chart/repeat-appointment/RepeatAppointmentTrxPlanningForm";

export default class RequestedDeleteConfirmModal extends React.Component {
  render() {
    const {
      followUp: { showEditForm, item },
    } = this.props;

    const initialValues = {
      note: item.note || "",
      note2: item.note2 || "",
      createdDate: item.date || null,
      creator: (item.creator && item.creator.name) || "",
    };

    if (item.doctor && item.doctor.id) {
      initialValues.doctor = item.doctor.id;
    }

    if (item?.type && item.type.id) {
      initialValues.type = item.type.id;
    }

    return (
      <Modal
        show={showEditForm}
        animation={false}
        onHide={() => this.props.dispatch(followUpActions.closeEditForm())}
        dialogClassName="follow-up-side-bar"
      >
        <SideBarHeader onClick={() => this.props.dispatch(followUpActions.closeEditForm())}>
          <div className="title">
            FOLLOW UP EDIT<div className="pull-right">#{item.patient?.chartNumber}</div>
          </div>
        </SideBarHeader>
        <div className="follow-up-side-bar-form">
          <RepeatAppointmentTrxPlanningForm
            initialValues={initialValues}
            enableReinitialize={true}
            keepDirtyOnReinitialize={true}
            onRepeatClick={this.props.onRepeatClick}
            fromList={true}
          />
        </div>
      </Modal>
    );
  }
}
