import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";
import ChartVitalOwnedItem from "./ChartVitalOwnedItem";

import VitalSortFields from "../../../../constants/chart-sidebar/vitalSortFields";
import vitalItemTypes from "../../../../constants/chart-sidebar/vitalItemTypes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVitalOwned extends React.Component {
    componentDidMount() {
      this.props.chartSideVitalActions.getOwnedList();
    }

    openItem = (item) => {
      return () => {
        this.props.chartSideVitalActions.openItem(item, vitalItemTypes.view);
      };
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartSideVitalActions.loadOwnedList();
      }
    };

    select = (event) => {
      this.props.chartSideVitalActions.selectSortField(event.target.value);
    };

    render() {
      const { ownedList, fetching, sortField } = this.props.chartSideVital;

      let $list = ownedList.map(({ id, ...item }, index) => (
        <ChartVitalOwnedItem
          key={index}
          item={{ id, ...item }}
          openItem={this.openItem}
          copyItem={() => this.props.chartSideVitalActions.copyItem(item)}
        />
      ));

      const $options = VitalSortFields.map((item, index) => (
        <option key={index} value={item.code}>
          {item.name}
        </option>
      ));

      return (
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header">
            <div className="clearfix">
              <div className="col-sm-4">
                <BackButton
                  className="btn-sm"
                  text="Back"
                  onClick={() => {
                    if (this.props.fromAppointment) {
                      this.props.hideSideBar();
                    } else {
                      this.props.chartSideVitalActions.popStack();
                    }
                  }}
                />
              </div>
              <div className="col-sm-8">
                <div className="chart-clinical-note-title">Vital</div>
              </div>
            </div>
          </div>
          <div className="chart-side-body">
            <div className="chart-clinical-note-select">
              <select onChange={this.select} value={sortField} className="form-control">
                {$options}
              </select>
            </div>
            <div style={{ padding: "0 6px" }} className="body" onScroll={this.loadList}>
              {$list}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
            <div className="all-codes">
              <button
                onClick={this.props.chartSideVitalActions.openList}
                className="btn btn-primary btn-block"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
