import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import MemberList from "./MemberList";
import SelectedMember from "./SelectedMember";
import LogoImage from "../../assets/images/clinic_logo.png";
import MemberRemoveDeviceConfirmModal from "./MemberRemoveDeviceConfirmModal";

import {
  adminLogin,
  chooseMember,
  deleteDeviceConfirmModalShow,
  forgotPassword,
  getTokenApi,
  initMemberLogin,
  login,
} from "../../actions/authActions";
import { getOnlyBtsColors } from "../../actions/appointmentActions";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import BackGroundImage from "../../assets/images/clinic_bg_image.jpg";
import FormBackGroundImage from "../../assets/images/clinic_bg.png";
import { obtainImagePath } from "../../utils/ImageUtils";

const enhancer = connect(
  ({
    session: { clinic },
    auth: { members, baseUrl, chosenMemberId, deleteDeviceConfirmVisible, user, deviceExist },
  }) => {
    return {
      clinic,
      members,
      chosenMemberId,
      deleteDeviceConfirmVisible,
      baseUrl,
      user,
      deviceExist,
    };
  },
  {
    initMemberLogin,
    login,
    chooseMember,
    forgotPassword,
    deleteDeviceConfirmModalShow,
    adminLogin,
    getTokenApi,
    getOnlyBtsColors,
  },
);
export default withRouter(
  enhancer(
    class MemberLogin extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          filter: "",
          members: props.members,
        };
      }

      handleSubmit = (event) => {
        event.preventDefault();

        this.props.login(event.target.password.value).then((response) => {
          this.props.getOnlyBtsColors();

          if (response && response.date) {
            const {
              members,
              chosenMemberId,
              history: { push },
            } = this.props;
            if (members[chosenMemberId].status.name === "Idle") {
              push(Routes.DashboardProfile);
            } else {
              push(Routes.Dashboard);
            }
          }
        });
      };

      componentDidMount() {
        if (this.props.deviceExist) this.props.initMemberLogin();
      }

      componentDidUpdate(prevProps, prevState) {
        const { members: prevMembers } = prevProps;
        const { members: nextMembers } = this.props;
        const { filter: prevFilter } = prevState;
        const { filter: nextFilter } = this.state;

        if (prevFilter !== nextFilter || !_.isEqual(prevMembers, nextMembers)) {
          const memberList = Object.values(nextMembers);

          const newMembers = memberList
            .filter(({ firstName, lastName }) => {
              return (
                nextFilter === "" ||
                [_.toLower(firstName), _.toLower(lastName)]
                  .join(" ")
                  .indexOf(_.toLower(nextFilter)) >= 0
              );
            })
            .reduce(
              (acc, item) => ({
                ...acc,
                [item.id]: item,
              }),
              {},
            );

          this.setState({ members: newMembers });
        }
      }

      forgotPassword = (event) => {
        event.preventDefault();

        this.props.forgotPassword();
      };

      chooseMember = (id) => {
        this.refs.password.focus();

        this.props.chooseMember(id);
      };

      render() {
        const { filter, members } = this.state;

        return (
          <div
            className="member-login-page"
            style={{
              backgroundImage: `url(${obtainImagePath(BackGroundImage)})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          >
            <div className="left-side">
              <div className="title">
                <div className="logo">
                  <img src={LogoImage} alt="" />
                </div>
                <div className="clinic-name">{this.props.clinic && this.props.clinic.name}</div>
              </div>
              <input
                style={{ maxWidth: "414px" }}
                value={filter}
                placeholder="Filter by name"
                className="form-control login-members-filter mb-1"
                onChange={({ target }) => this.setState({ filter: target.value })}
              />
              <MemberList members={members} chooseMember={this.chooseMember} />
              <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                  <button
                    type="button"
                    className="btn btn-primary member-log-out mt-1"
                    onClick={this.props.deleteDeviceConfirmModalShow}
                  >
                    Remove Device
                  </button>
                </div>
                <div className="col-sm-2" />
              </div>
            </div>
            <div className="right-side">
              <form
                onSubmit={this.handleSubmit}
                style={{ backgroundImage: `url(${FormBackGroundImage})` }}
              >
                <SelectedMember member={members[this.props.chosenMemberId]} />
                <div className="password">
                  <input
                    className="form-control input-lg"
                    type="password"
                    name="password"
                    ref="password"
                    placeholder="Password"
                  />
                </div>
                <div className="forgot">
                  <a href="#" onClick={this.forgotPassword}>
                    Forgot password?
                  </a>
                </div>
                <div className="sign-in-button">
                  <button type="submit" className="btn btn-lg btn-block btn-success">
                    Sign In
                  </button>
                </div>
              </form>
            </div>
            <MemberRemoveDeviceConfirmModal show={this.props.deleteDeviceConfirmVisible} />
          </div>
        );
      }
    },
  ),
);
