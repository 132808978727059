import React, { useEffect, useState } from "react";
import styled from "styled-components";

const PerioExamListStatus = ({ options, getItem, defaultItem }) => {
  const [status, setStatus] = useState(defaultItem || options[0]);
  useEffect(() => {
    setStatus(defaultItem);
  }, [defaultItem]);
  return (
    <Wrapper>
      <Container>
        {options.map((item, index) => (
          <ItemWrapper key={index}>
            <Title style={{ color: status?.code == item?.code ? "#5ba0c7" : "#000" }}>
              {item?.title}
            </Title>
            <Dot
              style={{
                background: status?.code == item?.code ? "#5ba0c7" : "#9d9d9d",
                border: status?.code == item?.code && "3px solid #0a6ba2",
              }}
              onClick={() => {
                setStatus(item);
                getItem(item);
              }}
            />
          </ItemWrapper>
        ))}
        <Line />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  padding: 16px;
`;
const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
`;
const Dot = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 20;
`;
const Line = styled.div`
  position: absolute;
  width: 92%;
  height: 3px;
  background: #9d9d9d;
  bottom: 11px;
  left: 27px;
  z-index: 10;
`;
export default PerioExamListStatus;
