import _ from "lodash";
import React from "react";
import moment from "moment";
import MemberImage from "../widgets/MemberImage";
import {
  FULL_DATE_FORMAT,
  WEEK_DAYS,
  DATE_FORMAT_LEGACY,
  TIME_FORMAT,
} from "../../constants/Constants";

import Utils from "../../helpers/Utils";
import DeleteImage from "../../assets/images/appointment/delete_2x.png";
import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";

export default function RequestedAppointmentsListCell(props) {
  const chartNumber = _.get(props, "item.patient.chartNumber");
  const fullName = `${_.get(props, "item.patient.firstName", "")} ${_.get(
    props,
    "item.patient.lastName",
    "",
  )}`;
  const email = _.get(props, "item.patient.email", "");
  const phone = _.get(props, "item.patient.mobilePhoneNumber", "");
  const createdDate = _.get(props, "item.createdDate");
  const itemId = _.get(props, "item.id");
  const weekDays = _.get(props, "item.weekDays", "").split(",");
  const fromDate = _.get(props, "item.periodStart");
  const toDate = _.get(props, "item.periodEnd");
  const fromTime = _.get(props, "item.timeStart");
  const toTime = _.get(props, "item.timeEnd");
  const waitingStartDate = _.get(props, "item.startTime");
  const dentist = _.get(props, "item.dentist.name", "");
  const category = _.get(props, "item.category.name", "");

  const waiting = props?.type === "waiting";
  const { item, history, setRequestedAppointmentItem } = props;

  const bookAppointmentHandler = () => {
    setRequestedAppointmentItem(item);
    history.push(
      createUrl(Routes.DashboardAppointment, {
        params: { id: 0 },
        query: {
          goBack: true,
          startTime: item.startTime,
          dentist: item?.dentist?.id,
          dentalPoint: item?.dentalPoint?.id,
          patientKey: item?.patient?.patientKey,
          masterType: item?.masterType?.id,
          requestAppointmentId: item.id,
        },
      }),
    );
  };

  return (
    <div>
      <div className={`requested-list-cell ${props.type}`}>
        <MemberImage photoUrl={_.get(props, "item.patient.photo")} size={80} />
        <div style={{ display: "flex", flex: 1 }}>
          <div className="requested-list-cell-data" onClick={bookAppointmentHandler}>
            <span>{fullName}</span>
            <span>{email}</span>
            <span>{phone}</span>
          </div>
          <div className="requested-list-cell-data" onClick={bookAppointmentHandler}>
            <span>{chartNumber && `Chart#: ${chartNumber}`}</span>
            <span>{category}</span>
            <span>{createdDate && moment(createdDate).format(FULL_DATE_FORMAT)}</span>
          </div>
          <div className="requested-list-cell-data" onClick={bookAppointmentHandler}>
            <span className="waiting-info-title">Prefered date:</span>

            <span className="waiting-info-title">Prefered days:</span>

            <span className="waiting-info-title">Prefered time:</span>

            {waiting && (
              <>
                <span className="waiting-info-title">Doctor:</span>
              </>
            )}
          </div>
          <div className="requested-list-cell-data" onClick={bookAppointmentHandler}>
            <span className="waiting-info-value">
              {waiting
                ? waitingStartDate
                  ? moment(waitingStartDate).format(DATE_FORMAT_LEGACY)
                  : "NA"
                : `From ${fromDate ? moment(fromDate).format(DATE_FORMAT_LEGACY) : "NA"} to ${
                    toDate ? moment(toDate).format(DATE_FORMAT_LEGACY) : "NA"
                  }`}
            </span>
            <span className="waiting-info-value">
              {_.map(weekDays, (item) => WEEK_DAYS[item]).join(", ")}
            </span>
            <span className="waiting-info-value">
              {waiting
                ? moment(waitingStartDate).format(TIME_FORMAT)
                : `From ${Utils.string24ToString12(fromTime)} to ${Utils.string24ToString12(
                    toTime,
                  )}`}
            </span>
            {waiting && (
              <>
                <span className="waiting-info-value">{dentist}</span>
              </>
            )}
          </div>
          <div
            className="requested-list-cell-data align-items-center"
            style={{ flexDirection: "row" }}
          >
            <span
              className="waiting-info-delete"
              title="Delete"
              onClick={() => props.onShowDeleteConfirm(itemId)}
            >
              <img src={DeleteImage} alt="" width={17} height={17} />
            </span>
            <button className="btn btn-success" onClick={bookAppointmentHandler}>
              Book Appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
