import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  DOCTORS_MODE,
  DoctorsSelect,
  POINTS_MODE,
  CHART_COMPLETE,
  MEDICAL_FORM_COMPLETE,
} from "../components/widgets/DoctorsSelect";
import { choosePoint } from "../actions/calendarActions";
import { doctorFilterChange } from "../actions/transactionActions";
import { resetDoctors, selectDoctor } from "../actions/authActions";
import { selectTreatmentToCompleteGetList } from "../actions/chartActions";
import { getAppointmentTransactions } from "../actions/transactionActions";

const enhancer = connect(
  ({ auth: { doctors, doctorsFilter }, calendar: { points } }, { onlyDoctors }) => ({
    points,
    doctors: doctors
      .filter((x) =>
        onlyDoctors
          ? _.get(x, "subType.code") === "DOCTOR" &&
            (_.get(x, "status.name") === "Activated" || _.get(x, "status.name") === "Idle")
          : true,
      )
      .map((x) => ({
        id: x.id,
        active: x.active,
        name: [x.firstName, x.lastName].join(" "),
      })),
    doctorsFilter,
  }),
  {
    resetDoctors,
    selectDoctor,
    choosePoint,
    selectTreatmentToCompleteGetList,
    getAppointmentTransactions,
    doctorFilterChange,
  },
);

export const DoctorsSelectWrapper = enhancer(
  class DoctorsSelectWrapperView extends React.Component {
    static propTypes = {
      points: PropTypes.object,
      doctors: PropTypes.array,
      doctorsFilter: PropTypes.array,

      onSelect: PropTypes.func,

      searchable: PropTypes.bool,

      allDoctorsTitle: PropTypes.string,

      mode: PropTypes.oneOf([POINTS_MODE, DOCTORS_MODE]),

      disabled: PropTypes.bool,
      onlyDoctors: PropTypes.bool,
      hideBySelect: PropTypes.bool,

      choosePoint: PropTypes.func,
      resetDoctors: PropTypes.func,
      selectDoctor: PropTypes.func,

      selectTreatmentToCompleteGetList: PropTypes.func,
      getAppointmentTransactions: PropTypes.func,
      doctorFilterChange: PropTypes.func,

      completeMode: PropTypes.oneOf([CHART_COMPLETE, MEDICAL_FORM_COMPLETE]),
    };

    componentDidMount() {
      const { mode, resetDoctors } = this.props;

      if (mode === DOCTORS_MODE) {
        resetDoctors();
      }
    }

    componentDidUpdate(prevProps) {
      const { doctorsFilter, mode, completeMode } = this.props;
      const filterLengthChanged = prevProps?.doctorsFilter?.length !== doctorsFilter?.length;

      if (mode === DOCTORS_MODE && completeMode === CHART_COMPLETE) {
        if (filterLengthChanged) {
          this.handleDoctorFilterChange();
        }
      }
    }

    handleDoctorFilterChange() {
      const {
        doctorsFilter,
        doctorFilterChange,
        getAppointmentTransactions,
        selectTreatmentToCompleteGetList,
      } = this.props;

      doctorFilterChange(doctorsFilter, true);
      selectTreatmentToCompleteGetList();
      getAppointmentTransactions(true, true, {
        diagnosisCompleted: false,
        diagnosisPlanned: true,
        startVisit: true,
      });
    }

    render() {
      const { mode, onSelect, choosePoint, selectDoctor, searchable, ...props } = this.props;

      return (
        <DoctorsSelect
          {...props}
          mode={mode}
          searchable={searchable}
          formatOneDoctorTitle={(doctorName, pointName) =>
            mode === POINTS_MODE ? `${doctorName} (${pointName})` : doctorName
          }
          formatMoreDoctorsTitle={(count) =>
            mode === POINTS_MODE ? `Points (${count})` : `Doctors (${count})`
          }
          onSelect={(value) => {
            if (onSelect) {
              onSelect(value);

              return;
            }

            if (mode === DOCTORS_MODE) {
              selectDoctor(value);
            } else {
              choosePoint(value);
            }
          }}
        />
      );
    }
  },
);
