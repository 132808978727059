import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSideDocsActionTypes";

export const defaultState = {
  deleteModalVisible: false,
  list: [],
  ids: {},
  fullMode: false,
  currentIndex: 0,
  confirmDeleteModalVisible: false,
};

const reducers = {
  [ActionTypes.SIDE_DOCS_GET_FILES_SUCCESS](state, { data }) {
    return {
      ...state,
      list: data.list || [],
    };
  },
  [ActionTypes.SIDE_DOCS_TOGGLE_IMAGE](state, { item }) {
    const ids = { ...state.ids };

    if (ids.hasOwnProperty(item.photo.id)) {
      delete ids[item.photo.id];
    } else {
      ids[item.photo.id] = item;
    }

    return {
      ...state,
      ids,
    };
  },
  [ActionTypes.SIDE_DOCS_OPEN_DELETE_MODAL](state) {
    return {
      ...state,
      confirmDeleteModalVisible: true,
    };
  },
  [ActionTypes.SIDE_DOCS_CLOSE_DELETE_MODAL](state) {
    return {
      ...state,
      confirmDeleteModalVisible: false,
    };
  },
  [ActionTypes.SIDE_DOCS_FULL_MODE](state, { index }) {
    return {
      ...state,
      fullMode: true,
      currentIndex: index,
    };
  },
  [ActionTypes.SIDE_DOCS_SMALL_MODE](state) {
    return {
      ...state,
      fullMode: false,
    };
  },
  [ActionTypes.SIDE_DOCS_PREV_INDEX](state) {
    let currentIndex;

    if (state.currentIndex === 0) {
      currentIndex = state.list.length - 1;
    } else {
      currentIndex = state.currentIndex - 1;
    }

    return {
      ...state,
      currentIndex,
    };
  },
  [ActionTypes.SIDE_DOCS_NEXT_INDEX](state) {
    let currentIndex;

    if (state.currentIndex === state.list.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex = state.currentIndex + 1;
    }

    return {
      ...state,
      currentIndex,
    };
  },
};

export default createReducer(defaultState, reducers);
