import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import PropTypes from "prop-types";
import ChartPrescriptionList from "./ChartPrescriptionList";
import ChartPrescriptionCategory from "./ChartPrescriptionCategory";
import ChartPrescriptionCategoryAdd from "./ChartPrescriptionCategoryAdd";
import ChartPrescriptionTemplates from "./ChartPrescriptionTemplates";
import ChartPrescriptionTemplatesAdd from "./ChartPrescriptionTemplatesAdd";
import ChartPrescriptionPDF from "./ChartPrescriptionPDF";
import { ChartPrescriptionEdit } from "./ChartPrescriptionEdit";
import ChartPrescriptionDiagnosis from "./ChartPrescriptionDiagnosis";
import ChartPrescriptionName from "./ChartPrescriptionName";
import ChartPrescriptionFavoriteList from "./ChartPrescriptionFavoriteList";
import ChartPrescriptionFavoriteListAdd from "./ChartPrescriptionFavoriteListAdd";

import { prescriptionStackCodes } from "../../../../constants/chart-sidebar/prescriptionStackCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import ChartPrescriptionGroup from "./ChartPrescriptionGroup";
import ChartPrescriptionGroupList from "./ChartPrescriptionGroupList";

const enhancer = connect(() => ({}), bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescription extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
    };

    componentDidMount() {
      this.props.chartSidePrescriptionActions.initPrescription();
    }

    render() {
      const { chartSidePrescription } = this.props;
      const { stack } = chartSidePrescription;

      const stackItem = _.last(stack);

      if (stackItem.code === prescriptionStackCodes.favourites) {
        return <ChartPrescriptionFavoriteList chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.list) {
        return <ChartPrescriptionList chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.view) {
        return <ChartPrescriptionPDF chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.category) {
        return <ChartPrescriptionCategory chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.templates) {
        return <ChartPrescriptionTemplates chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.edit) {
        return (
          <ChartPrescriptionEdit
            formData={chartSidePrescription.formData}
            prescriptions={chartSidePrescription.prescriptions}
            medicationLists={chartSidePrescription.medicationLists}
          />
        );
      }

      if (stackItem.code === prescriptionStackCodes.favouritesAdd) {
        return <ChartPrescriptionFavoriteListAdd chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.categoryAdd) {
        return <ChartPrescriptionCategoryAdd chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.templatesAdd) {
        return <ChartPrescriptionTemplatesAdd chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.templatesGroup) {
        return <ChartPrescriptionGroup chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.templatesGroupAdd) {
        return <ChartPrescriptionGroupList chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.diagnosis) {
        return <ChartPrescriptionDiagnosis chartSidePrescription={chartSidePrescription} />;
      }

      if (stackItem.code === prescriptionStackCodes.name) {
        return <ChartPrescriptionName chartSidePrescription={chartSidePrescription} />;
      }
    }
  },
);
