import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import Show from "../../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { useDispatch, useSelector } from "react-redux";
import bubleIcon from "../../../../assets/images/bubble.png";
import MedicalFormCustomPopover from "../../../MedicalFormCustomPopover";
import FilledImage from "../../../widgets/FilledImage";
import {
  getMedicalFillInFormVitalTransaction,
  pushStack,
  setMedicalFillInFormVitalValueToEdit,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import EditIcon from "../../../../assets/images/diagnosis_edit-icon.png";
import { DATE_FORMAT_LEGACY, DATE_FORMAT_LEGACY_WITH_FULL } from "../../../../constants/Constants";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";

const MedicalFillInFormVitalsList = ({ fetching }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMedicalFillInFormVitalTransaction());
  }, []);

  const { patientVitalsTransactionsList, patientVitalTransaction } = useSelector(
    (store) => store.chartSideMedicalFillInForm,
  );
  const _patientVitalsTransactionsList = () => {
    let vitals = {};

    for (let index = 0; index < patientVitalsTransactionsList.length; index++) {
      let transactionAtIndex = patientVitalsTransactionsList[index];
      let visitDate = moment(transactionAtIndex.visitDate).format(DATE_FORMAT_LEGACY_WITH_FULL);
      let vitalValues = {};
      if (!vitals[visitDate]) {
        vitals[visitDate] = vitalValues;
      }
      vitalValues = vitals[visitDate];
      (patientVitalTransaction.transactions || []).forEach((transaction) => {
        if (!vitalValues[transaction.vital.id]) {
          vitalValues[transaction.vital.id] = {};
        }
        vitalValues[transaction.vital.id] =
          transactionAtIndex.vital.id === transaction.vital.id
            ? transactionAtIndex
            : vitalValues[transaction.vital.id];
      });
    }
    return vitals;
  };

  const sortedVitalTransactions = useMemo(
    () =>
      (patientVitalTransaction.transactions || []).sort(
        (a, b) => a?.vital?.sortNumber - b?.vital?.sortNumber,
      ),
    [patientVitalTransaction.transactions],
  );

  const generateColumns = (arr) => {
    let subArray = [];
    if (arr?.length < sortedVitalTransactions?.length) {
      for (let i = 0; i < sortedVitalTransactions?.length - arr?.length; i++) {
        subArray.push({ vital: arr[i] });
      }
    }
    return [...arr, ...subArray];
  };

  const generateList = () => {
    let vitals = _patientVitalsTransactionsList();
    let vitalsKeys = Object.keys(vitals);
    return vitalsKeys.map((key, index) => {
      let vitalsKeyValues = Object.values(vitals[key]);
      const sortedList = (vitalsKeyValues || []).sort(
        (a, b) => a?.vital?.sortNumber - b?.vital?.sortNumber,
      );
      return (
        <div
          key={index}
          style={{
            minWidth: "150px",
            maxWidth: "175px",
          }}
        >
          <div
            style={{
              background: "#e6e6e6",
              minWidth: "150px",
              borderRight: "1px solid #c1c1c1",
              borderBottom: "1px solid #c1c1c1",
            }}
          >
            <p className="mb-0" style={{ padding: "8px 10px 0 10px", textAlign: "center" }}>
              {index + 1} of {vitalsKeys.length}
            </p>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ height: "70px" }}
            >
              <p className="mb-0" style={{ padding: "0 10px 8px 10px", textAlign: "center" }}>
                {key.replace(/(\d{2}\/\d{2}\/\d{4})\s\d{2}:\d{2}:\d{2}/, "$1")}
              </p>
              <div
                className="edit-icon"
                role="button"
                onClick={() => {
                  dispatch(setMedicalFillInFormVitalValueToEdit(vitalsKeyValues));
                  dispatch(
                    pushStack({
                      code: medicalFillInFormStackCodes.vitalsCreateForm,
                      type: medicalFillInFormItemTypes.edit,
                    }),
                  );
                }}
              >
                <FilledImage src={EditIcon} alt="edit" zoom={80} color="#000 " />
              </div>
            </div>
          </div>
          <p className="vitals_table_item text-center p-2 mb-0">
            {_.get(sortedList[0], "stage.name", "")}
          </p>
          <p className="vitals_table_item p-2 text-center mb-0 bg-light">
            {_.get(sortedList[0], "opIp", "")}
          </p>
          {sortedList.map((vitalItem, idx) => {
            const currentValue = Number(_.get(vitalItem, "value", "N/A"));
            const isNan = Object.is(currentValue, NaN);
            const isFieldDate = _.get(vitalItem, "vital")?.fieldType?.code === "FIELD_TYPE_DATE";
            const vitalValue = isNan
              ? _.get(vitalItem, "value", "")
              : isFieldDate
              ? moment(currentValue).format(DATE_FORMAT_LEGACY)
              : Number(_.get(vitalItem, "value")).toFixed(2);

            return (
              <div
                className={`vitals_table_item p-2 d-flex d-flex align-items-center justify-content-center ${
                  idx % 2 !== 0 ? "bg-light" : ""
                }`}
                key={idx}
              >
                <p
                  className="w-100 d-flex align-items-center justify-content-center mb-0"
                  style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                  title={vitalItem.vitalName === "BMI" ? _.get(vitalItem, "value") : ""}
                >
                  {vitalValue}
                </p>

                <MedicalFormCustomPopover content={_.get(vitalItem, "comment")}>
                  <div className="d-flex cursor-pointer align-items-center">
                    <Show if={_.get(vitalItem, "value", "")}>
                      <img
                        src={bubleIcon}
                        alt="bubleIcon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </Show>

                    <Show if={_.get(vitalItem, "comment")}>
                      <span className="comment-indicator" />
                    </Show>
                  </div>
                </MedicalFormCustomPopover>
              </div>
            );
          })}
        </div>
      );
    });
  };
  return (
    <div className="medical-fill-in-form-vitals__list">
      <span className="medical-fill-in-form-vitals__list-title">Vitals</span>
      <div className="medical-fill-in-form-vitals__table">
        <div className="vitals_table_left">
          <div
            style={{
              background: "#e6e6e6",
              borderBottom: "1px solid #c1c1c1",
              borderRight: "1px solid #c1c1c1",
            }}
          >
            <p className="mb-0" style={{ padding: "8px 10px 0 10px" }}>
              Number
            </p>
            <div
              className="d-flex align-items-start justify-content-start"
              style={{ height: "70px" }}
            >
              <p className="mb-0" style={{ padding: "8px 10px 8px 10px" }}>
                Date
              </p>
            </div>
          </div>
          <p className="vitals_table_item p-2 mb-0">Stage</p>
          <p className="vitals_table_item p-2 mb-0 bg-light">OP</p>
          {[...generateColumns(sortedVitalTransactions)].map((item, index) => (
            <p
              className={`vitals_table_item p-2 mb-0 ${index % 2 !== 0 ? "bg-light" : ""}`}
              key={index}
            >
              {_.get(item, "vital.name")}
            </p>
          ))}
        </div>
        {generateList()}
        <Show if={fetching} style={{ position: "relative" }}>
          <ReactSpinner />
        </Show>
      </div>
    </div>
  );
};
export default MedicalFillInFormVitalsList;
