import React from "react";
import PropTypes from "prop-types";
import { DropdownButton } from "./DropdownButton";

BlockedKeywordsSelect.propTypes = {
  keywords: PropTypes.array,

  onSelect: PropTypes.func,

  disabled: PropTypes.bool,
};

export function BlockedKeywordsSelect({ onSelect, disabled, keywords }) {
  let activeCount = 0;
  let activeKeyword = 0;

  keywords.forEach((x) => {
    if (x.active) {
      activeCount++;
      activeKeyword = x.name;
    }
  });

  const items = keywords.map((item) => {
    const { id, name, active } = item;

    return {
      selected: active,
      value: id,
      title: name,
    };
  });

  if (activeCount === keywords.length) {
    items.unshift({
      divider: true,
      selected: false,
      title: "None",
      value: "0",
    });
  }

  if (activeCount !== keywords.length) {
    items.unshift({
      divider: true,
      selected: false,
      title: "All",
      value: "all",
    });
  }

  const title = !activeCount
    ? "None"
    : activeCount === 1
    ? activeKeyword
    : activeCount === keywords.length
    ? "All"
    : `Keywords (${activeCount})`;

  return (
    <DropdownButton
      title={title}
      items={items}
      disabled={disabled}
      hideBySelect={true}
      className="btn btn-default"
      wrapperClassName="blocked-doctors-select"
      onSelect={({ value }) => onSelect(value)}
    />
  );
}
