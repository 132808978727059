import React from "react";
import { connect } from "react-redux";
import BackButton from "../widgets/BackButton";
import {
  getPatientWhatsAppMessagesList,
  sendPatientWhatsAppMessage,
} from "../../actions/patientWhMessagesActions";
import { getCompanyFormatFullDate } from "../../helpers/DateUtils";
import WhatsappImage from "../../assets/images/whatsapp-sender-icon.svg";

@connect(({ patientWhMessages, whatsappChatting }) => {
  return { patientWhMessages, whatsappChatting };
})
export class WhatsAppChattingInterface extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: "",
    };

    this.chatContentRef = React.createRef(); // Add a ref for the chat content
  }

  componentDidMount() {
    const { dispatch, location } = this.props;
    const patientKey = location.search.split("pKey=")[1].split("&")[0];
    dispatch(getPatientWhatsAppMessagesList({ patientKey, limit: 100 }));

    this.pollingInterval = setInterval(() => {
      dispatch(getPatientWhatsAppMessagesList({ patientKey, limit: 100 }));
    }, 60000);

    this.scrollToBottom(); // Scroll to bottom on mount
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientWhMessages.messages !== this.props.patientWhMessages.messages) {
      this.scrollToBottom(); // Scroll to bottom when new messages arrive
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  scrollToBottom = () => {
    if (this.chatContentRef.current) {
      this.chatContentRef.current.scrollTop = this.chatContentRef.current.scrollHeight;
    }
  };

  handleSubmitMessage = () => {
    this.props
      .dispatch(
        sendPatientWhatsAppMessage({
          patientKey: this.props.location.search.split("pKey=")[1],
          text: this.state.input,
        }),
      )
      .then(() => {
        this.setState({ input: "" });
        this.props.dispatch(
          getPatientWhatsAppMessagesList({
            patientKey: this.props.location.search.split("pKey=")[1],
            limit: 100,
          }),
        );
      });
  };

  handleInputChange = (e) => {
    this.setState({ input: e.target.value });
  };

  render() {
    const { patient } = this.props.whatsappChatting;
    const $chat = this.props.patientWhMessages.messages.map((message, index) => {
      const sentMessage = message.status === "sent";
      return (
        <div key={index} className="whatsapp-chatting-message-wrapper">
          <div
            key={message.date}
            className="whatsapp-chatting-message"
            style={{ marginLeft: sentMessage ? "auto" : "none" }}
          >
            <div
              className="whatsapp-chatting-message-content"
              style={{ backgroundColor: sentMessage ? "#cdd380" : "#dcdcd7" }}
            >
              <p>{message.text}</p>
            </div>
            <p
              className="whatsapp-chatting-message-date"
              style={{ textAlign: sentMessage ? "right" : "left" }}
            >
              {getCompanyFormatFullDate(message.date)}
            </p>
          </div>
        </div>
      );
    });

    return (
      <div style={{ width: "100%" }}>
        <div className="sidebar-header whatsapp-chatting-side-header">
          <div className="clearfix">
            <div className="col-sm-2 pl-1">
              <BackButton
                className="btn-sm pl-0"
                text="Back"
                onClick={this.props.hideWhatsAppChat}
              />
            </div>
            <div className="col-sm-8">
              <div className="whatsapp-note-title text-center">
                <p className="whatsapp-note-title-caption">Messages</p>
                <p className="whatsapp-note-title-chart">Chart#: {patient.chartNumber}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="whatsapp-chatting-content" ref={this.chatContentRef}>
          {$chat}
        </div>
        <div>
          <div className="whatsapp-chatting-input-wrapper">
            <input
              type="text"
              value={this.state.input}
              onChange={(e) => this.setState({ input: e.target.value })}
              placeholder="Typing..."
              className="whatsapp-chatting-input"
            />
            <img
              onClick={this.handleSubmitMessage}
              className="whatsapp-chatting-sender-icon"
              src={WhatsappImage}
              alt="whatsapp sender"
            />
          </div>
        </div>
      </div>
    );
  }
}
