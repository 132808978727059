import React from "react";
import PropTypes from "prop-types";
import Show from "../widgets/Show";

import Colors from "../../helpers/Colors";
import Utils from "../../helpers/Utils";
import { defaultColors } from "../../constants/defaults";
import viewTypes from "../../constants/chart-sidebar/viewTypes";

import patientViewModalTypes from "../../constants/patient/patientViewModalTypes";
import { withRouter } from "react-router";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import clinicalNoteImage from "../../assets/images/chart/ClinicalNotes@2x.png";
import noteImage from "../../assets/images/appointment/notes.png";
import historyImage from "../../assets/images/appointment/history.png";
import helpDeskImage from "../../assets/images/call-list/helpdesk@2x.png";
import smartphoneImage from "../../assets/images/call-list/smartphone.png";
import checkImage from "../../assets/images/call-list/check_2x.png";
import phoneImage from "../../assets/images/call-list/phone_2_2x.png";
import deleteImage from "../../assets/images/call-list/delete_2x.png";
import shapeImage from "../../assets/images/call-list/shape_2x.png";
import AvatarImage from "../../assets/images/default_avatar.png";
import PlannedIconImage from "../../assets/images/appointment/PlannedIcon@2x.png";

class CallListCell extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    patientView: PropTypes.object,
    changeStatus: PropTypes.func,
    showCanceledModal: PropTypes.func,
    noResponse: PropTypes.func,
    appointmentNoteModalShow: PropTypes.func,
    setPatientKey: PropTypes.func,
    showSideBar: PropTypes.func,
    openCallListPatientNote: PropTypes.func,
    loadInsuranceNotes: PropTypes.func,
    openInsuranceNote: PropTypes.func,
    history: PropTypes.object,
  };

  confirmed = () => {
    const transfer = {
      id: this.props.point.id,
      status: {
        code:
          this.props.point.status.code === "APPOINTMENT_STATUS_CONFIRMED"
            ? "APPOINTMENT_STATUS_CONFIRMED2"
            : "APPOINTMENT_STATUS_CONFIRMED",
      },
    };
    this.props.changeStatus(transfer);
  };

  canceled = () => {
    this.props.showCanceledModal(this.props.point.id);
  };

  noResponse = (patientKey) => {
    this.props.noResponse(this.props.point.id, patientKey);
  };

  handleClick = (e, note, patientKey) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.appointmentNoteModalShow({ patientKey, note });
  };

  render() {
    const { point, history } = this.props;
    const startDate = new Date(point.startTime);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();

    const duration = point.duration / 1000 / 60;

    const startTime = hours * 60 + minutes;
    const endTime = startTime + duration;

    const time = `${Utils.intToDayPartStr(startTime)} - ${Utils.intToDayPartStr(endTime)}`;

    const name = Utils.getFullName(point.patient);

    const url = (point.patient.photo && point.patient.photo.url) || AvatarImage;

    const imageStyle = { backgroundImage: `url(${url})` };

    const pointStatusColor = point.status && Colors.getStatusColor(point.status.code);
    const color = (pointStatusColor && pointStatusColor.color.color) || defaultColors.Green.color;

    const leftStyle = {};

    let $noResponse;

    if (point.lastNoResponseTime) {
      leftStyle.backgroundColor = color;

      $noResponse = (
        <div className="no-response">
          Last No Answer time:
          <br />
          {Utils.parseDateFormat(Utils.calcDateWithTimeZone(point.lastNoResponseTime))}
        </div>
      );
    }

    return (
      <div key={point.id} data-id={point.id} className="cell">
        <div className="patient-image" style={imageStyle} />
        <div className="left" style={leftStyle}>
          <div className="top">
            <div className="name" style={{ paddingLeft: 0 }} title={name}>
              {name}
            </div>
            <div className="number" style={leftStyle}>
              #{point.patient && point.patient.chartNumber}
            </div>
          </div>
          <div className="icon-buttons">
            <Show if={point.note}>
              <img
                src={clinicalNoteImage}
                alt=""
                title="Appointment Note"
                className="zoom-2x prevent-click"
                onClick={(e) => {
                  this.handleClick(e, point.note, point.patient.patientKey);
                }}
              />
            </Show>
            <img
              src={clinicalNoteImage}
              alt=""
              title="Clinical Notes"
              className="zoom-2x prevent-click"
              onClick={() => {
                this.props.setPatientKey(point.patient.patientKey);
                this.props.showSideBar(viewTypes.clinicalNote);
              }}
            />
            <img
              src={noteImage}
              alt=""
              title="Reception Notes"
              className="zoom-2x prevent-click"
              onClick={() => {
                this.props.openCallListPatientNote(point.patient.patientKey);
                this.props.openInsuranceNote(patientViewModalTypes.note, point.patient.patientKey);
              }}
            />
            <Show if={point.patient.patientKey}>
              <img
                src={noteImage}
                alt=""
                title="Insurance Notes"
                className="zoom-2x prevent-click"
                onClick={() => {
                  this.props.loadInsuranceNotes(point.patient.patientKey);
                  this.props.openInsuranceNote(
                    patientViewModalTypes.insuranceNote,
                    point.patient.patientKey,
                  );
                }}
              />
            </Show>
            <img
              src={historyImage}
              alt=""
              title="Appointment history"
              className="zoom-2x prevent-click"
              onClick={() => {
                history.push(
                  createUrl(Routes.PatientAppointmentsList, {
                    query: {
                      patientKey: point.patient.patientKey,
                    },
                  }),
                );
              }}
            />
            <img
              alt=""
              title="Planned Items"
              src={PlannedIconImage}
              className="zoom-2x prevent-click"
              onClick={() => {
                this.props.setPlannedModalVisible({ ...point, plannedModalVisible: true });
              }}
            />
          </div>
          <div className="bottom">
            <div className="phone">
              <img src={helpDeskImage} alt="" /> {point.patient && point.patient.workPhoneNumber}
            </div>
            <div className="phone">
              <img src={helpDeskImage} alt="" /> {point.patient && point.patient.homePhoneNumber}
            </div>
            <div className="phone">
              <img src={smartphoneImage} alt="" />{" "}
              {point.patient && point.patient.mobilePhoneNumber}
            </div>
          </div>
        </div>
        <div className="right" style={{ backgroundColor: color, paddingTop: "6px" }}>
          <div className="bottom">{point.category && point.category.name}</div>
          <div className="bottom">{point.machine && point.machine.name}</div>
          <div style={{ flex: 1, alignItems: "center" }}>{$noResponse}</div>
          <div className="top">{time}</div>
          <div className="bottom">{point.status && point.status.name}</div>
        </div>
        <div className="cell-buttons">
          <div className="confirmed" onClick={this.confirmed}>
            <img src={checkImage} alt="" />
            <div className="text">
              {(point.status.code === "APPOINTMENT_STATUS_UNCONFIRMED" && "Confirmed - D") ||
                (point.status.code === "APPOINTMENT_STATUS_CONFIRMED" && "Confirmed - Hr")}
            </div>
          </div>
          <div className="no-response" onClick={() => this.noResponse(point.patient.patientKey)}>
            <img src={phoneImage} alt="" />
            <div className="text">No Answer</div>
          </div>
          <div className="canceled" onClick={this.canceled}>
            <img src={deleteImage} alt="" />
            <div className="text">Canceled</div>
          </div>
          <div className="move-appt">
            <img src={shapeImage} alt="" />
            <div className="text">Move Appt</div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(CallListCell);
