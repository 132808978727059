import React from "react";
import { connect } from "react-redux";

import {
  changeActiveTooth,
  hideSideTransaction,
  repeatAppointmentModalShow,
} from "../actions/chartActions";

import ChartTreatmentsSidebar from "../components/chart-treatments-sidebar/ChartTreatmentsSidebar";

import {
  changeCompletedDate,
  deleteTransaction,
  saveBreakToVisit,
  setTreatmentPlan,
  setTreatmentSession,
  updateNote,
  updateTransactionDiscount,
} from "../actions/chartSideTransactionActions";

import {
  addChartItem,
  addDiagnosis,
  clearDiagnosisItems,
  createTransactionRequest,
  editTransactionItem,
  getDiagnosisFavorites,
  getExistingFavorites,
  getItem,
  getMixedFavorites,
  getMixidCodes,
  getSidebarCategoryList,
  getSidebarDiagnosisList,
  getSidebarExistingList,
  getSidebarProcedureList,
  getSidebarTreatmentList,
  getTreatmentFavorites,
  getCategoryList,
  handleHideTabBar,
  initTransactionItem,
  loadMixidCodes,
  loadSidebarCategoryList,
  loadSidebarDiagnosisList,
  loadSidebarExistingList,
  loadSidebarProcedureList,
  loadSidebarTreatmentList,
  openItem,
  saveProcedureItem,
  saveProcedureRequestItem,
  setSearchValue,
  showDocumentsAssignedModal,
  showImageSelector,
  showPriorApprovalPopup,
  sortFavourites,
  getGroupProcedureList,
} from "../actions/chartSideSeriesActions";
import { SidebarTypes } from "../components/chart-treatments-sidebar/TypeButtonGroup";
import { addRequestedTreament } from "../actions/appointmentActions";

const enhancer = connect(
  ({
    chart,
    session,
    appointment,
    chartSideSeries,
    chartDermatology,
    chartSideTransaction,
    chart: { activeTooth, teeth },
    auth: { members, chosenMemberId },
    session: { toothNumericType, permissions },
    patientView,
    insurance,
    chartSideOrder: { orderStack },
  }) => ({
    teeth,
    chart,
    session,
    appointment,
    activeTooth,
    permissions,
    chartSideSeries,
    chartDermatology,
    toothNumericType,
    chartSideTransaction,
    member: members[chosenMemberId],
    patientView,
    insurance,
    orderStack,
  }),
  {
    getItem,
    openItem,
    updateNote,
    addChartItem,
    addDiagnosis,
    getMixidCodes,
    loadMixidCodes,
    sortFavourites,
    setSearchValue,
    getCategoryList,
    saveBreakToVisit,
    handleHideTabBar,
    setTreatmentPlan,
    saveProcedureItem,
    deleteTransaction,
    showImageSelector,
    changeActiveTooth,
    getMixedFavorites,
    hideSideTransaction,
    setTreatmentSession,
    changeCompletedDate,
    clearDiagnosisItems,
    initTransactionItem,
    editTransactionItem,
    getExistingFavorites,
    addRequestedTreament,
    getGroupProcedureList,
    getTreatmentFavorites,
    getDiagnosisFavorites,
    getSidebarExistingList,
    loadSidebarCategoryList,
    getSidebarCategoryList,
    getSidebarTreatmentList,
    getSidebarDiagnosisList,
    getSidebarProcedureList,
    loadSidebarTreatmentList,
    loadSidebarDiagnosisList,
    loadSidebarExistingList,
    createTransactionRequest,
    loadSidebarProcedureList,
    saveProcedureRequestItem,
    updateTransactionDiscount,
    repeatAppointmentModalShow,
    showDocumentsAssignedModal,
    showPriorApprovalPopup,
  },
);

export default enhancer(
  class ChartTreatmentsSidebarWrapper extends React.Component {
    componentDidMount() {
      const { getSidebarCategoryList } = this.props;

      getSidebarCategoryList();
    }

    getFavorites = (type) => {
      const {
        getMixedFavorites,
        getExistingFavorites,
        getTreatmentFavorites,
        getDiagnosisFavorites,
      } = this.props;

      if (type === SidebarTypes.Treatments) {
        getTreatmentFavorites();
      }

      if (type === SidebarTypes.Diagnosis) {
        getDiagnosisFavorites();
      }

      if (type === SidebarTypes.Existing) {
        getExistingFavorites();
      }

      if (type === SidebarTypes.Mixed) {
        getMixedFavorites();
      }
    };

    getList = (type, categoryId) => {
      const {
        getSidebarExistingList,
        getSidebarTreatmentList,
        getSidebarDiagnosisList,
        chart,
      } = this.props;

      if (type === SidebarTypes.Treatments) {
        getSidebarTreatmentList(categoryId, chart.medicalForms ? 5 : null);
      }

      if (type === SidebarTypes.Diagnosis) {
        getSidebarDiagnosisList();
      }

      if (type === SidebarTypes.Existing) {
        getSidebarExistingList(categoryId);
      }
    };

    loadList = (type, categoryId) => {
      const {
        loadSidebarExistingList,
        loadSidebarTreatmentList,
        loadSidebarDiagnosisList,
      } = this.props;

      if (type === SidebarTypes.Treatments) {
        loadSidebarTreatmentList(categoryId);
      }

      if (type === SidebarTypes.Diagnosis) {
        loadSidebarDiagnosisList();
      }

      if (type === SidebarTypes.Existing) {
        loadSidebarExistingList(categoryId);
      }
    };

    render() {
      const {
        open,
        teeth,
        chart,
        member,
        session,
        getItem,
        viewType,
        updateNote,
        activeTooth,
        permissions,
        appointment,
        addDiagnosis,
        inAppointment,
        getMixidCodes,
        onRequestClose,
        sortFavourites,
        setSearchValue,
        loadMixidCodes,
        chartSideSeries,
        getCategoryList,
        handleHideTabBar,
        saveBreakToVisit,
        toothNumericType,
        chartDermatology,
        setTreatmentPlan,
        changeActiveTooth,
        deleteTransaction,
        showImageSelector,
        saveProcedureItem,
        editTransactionItem,
        setTreatmentSession,
        changeCompletedDate,
        initTransactionItem,
        hideSideTransaction,
        clearDiagnosisItems,
        chartSideTransaction,
        addRequestedTreament,
        showPriorApprovalPopup,
        getSidebarExistingList,
        getSidebarTreatmentList,
        loadSidebarCategoryList,
        getSidebarDiagnosisList,
        saveProcedureRequestItem,
        createTransactionRequest,
        updateTransactionDiscount,
        repeatAppointmentModalShow,
        location,
        history,
        patientView,
        insurance,
        orderStack,
      } = this.props;

      return open ? (
        <ChartTreatmentsSidebar
          open={open}
          chart={chart}
          teeth={teeth}
          member={member}
          session={session}
          openItem={getItem}
          viewType={viewType}
          getList={this.getList}
          updateNote={updateNote}
          onSort={sortFavourites}
          loadList={this.loadList}
          appointment={appointment}
          permissions={permissions}
          activeTooth={activeTooth}
          onSearch={setSearchValue}
          addChartItem={addChartItem}
          addDiagnosis={addDiagnosis}
          inAppointment={inAppointment}
          getMixedCodes={getMixidCodes}
          initItem={initTransactionItem}
          getCategoryList={getCategoryList}
          onRequestClose={() => {
            onRequestClose();

            hideSideTransaction();
          }}
          loadMixedCodes={loadMixidCodes}
          getFavorites={this.getFavorites}
          saveProcedure={saveProcedureItem}
          chartSideSeries={chartSideSeries}
          handleHideTabBar={handleHideTabBar}
          chartDermatology={chartDermatology}
          saveBreakToVisit={saveBreakToVisit}
          toothNumericType={toothNumericType}
          setTreatmentPlan={setTreatmentPlan}
          deleteTransaction={deleteTransaction}
          changeActiveTooth={changeActiveTooth}
          showImageSelector={showImageSelector}
          clearDiagnosisItems={clearDiagnosisItems}
          editTransactionItem={editTransactionItem}
          setTreatmentSession={setTreatmentSession}
          changeCompletedDate={changeCompletedDate}
          loadCategoryList={loadSidebarCategoryList}
          addRequestedTreament={addRequestedTreament}
          chartSideTransaction={chartSideTransaction}
          getGroupProcedureList={getGroupProcedureList}
          showPriorApprovalPopup={showPriorApprovalPopup}
          getSidebarExistingList={getSidebarExistingList}
          getSidebarDiagnosisList={getSidebarDiagnosisList}
          getSidebarTreatmentList={getSidebarTreatmentList}
          saveProcedureRequestItem={saveProcedureRequestItem}
          createTransactionRequest={createTransactionRequest}
          updateTransactionDiscount={updateTransactionDiscount}
          showDocumentsAssignedModal={showDocumentsAssignedModal}
          repeatAppointmentModalShow={repeatAppointmentModalShow}
          location={location}
          history={history}
          patientView={patientView}
          insurance={insurance}
          orderStack={orderStack}
        />
      ) : null;
    }
  },
);
