export const orderStackCodes = {
  owned: "owned",
  ownedList: "ownedList",
  list: "list",
  item: "item",
};

export const defaultStackItem = {
  code: orderStackCodes.list,
};
