import React from "react";
import { connect } from "react-redux";
import { FieldArray, Form, Formik } from "formik";

import Back from "../../widgets/Back";
import Show from "../../widgets/Show";
import { parseQuery } from "../../../utils/UrlUtils";
import bindActions from "../../../helpers/bindActions";
import PatientFormCaption from "../PatientFormCaption";
import PatientMedicationAlertsTable from "./PatientMedicationAlertsTable";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { Routes } from "../../../constants/Routes";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
export default enhancer(
  class PatientMedicationAlertsForm extends React.Component {
    getPatientAlertsHistory = () => {
      this.props.patientRegisterActions.getPatientAlertsHistory("MEDICATION_ALERTS");
      this.props.history.push(Routes.PatientAlertsHistory);
    };
    render() {
      const query = parseQuery(this.props.location.search);
      const { clinic, patientRegister, onBackClick, onSubmit, alerts } = this.props;
      const {
        personalDetails: { patient },
      } = patientRegister;
      const editMode =
        query.register || query.temporary ? true : patientRegister.patientMedicationAlertsEditMode;

      return (
        <Formik enableReinitialize={true} initialValues={{ alerts }} onSubmit={onSubmit}>
          <Form className="default-page patient-medication-alerts-edit">
            <div className="default-page-title clearfix text-center">
              <Back className="pull-left" onClick={onBackClick} />
              <Show if={editMode}>
                <button type="submit" className="btn btn-link pull-right">
                  Submit
                </button>
              </Show>
              <Show if={!editMode}>
                <button
                  type="button"
                  onClick={this.props.patientRegisterActions.patientMedicationAlertsEdit}
                  className="btn btn-link pull-right"
                >
                  Edit
                </button>
              </Show>
              <div className="title">Welcome to {clinic.name}</div>
            </div>
            <div className="default-page-body patient-details-edit-body form-horizontal">
              <PatientFormCaption patient={patient} formId="Patient Medication Alerts" />
              <FieldArray
                name="alerts"
                render={(arrayHelpers) => (
                  <div className="patient-medication-alerts-edit-box">
                    <PatientMedicationAlertsTable
                      patientRegister={this.props.patientRegister}
                      editMode={editMode}
                      onRemove={arrayHelpers.remove}
                      alerts={arrayHelpers.form.values.alerts}
                    >
                      <Show if={editMode}>
                        <div
                          className="btn btn-block btn-lg btn-primary"
                          onClick={() =>
                            arrayHelpers.push({
                              dosage: "",
                              endDate: null,
                              frequency: "",
                              medicationName: "",
                              purpose: "",
                              startDate: null,
                              unit: null,
                            })
                          }
                        >
                          Tap here to add another
                        </div>
                      </Show>
                    </PatientMedicationAlertsTable>
                  </div>
                )}
              />
              <div className=" medical-bottom">
                <div className="col-sm-12 mb-3 mt-4">
                  <Show if={!editMode}>
                    <div
                      className="btn btn-block btn-lg btn-primary"
                      onClick={this.props.patientRegisterActions.patientMedicationAlertsEdit}
                    >
                      Edit
                    </div>
                  </Show>
                  <Show if={editMode}>
                    <button type="submit" className="btn btn-block btn-lg btn-primary">
                      Submit
                    </button>
                  </Show>
                </div>
                <div
                  style={{ bottom: 0 }}
                  className="patient-alerts-history-button"
                  onClick={this.getPatientAlertsHistory}
                >
                  View edit history
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      );
    }
  },
);
