import React, { useMemo, useState } from "react";
import { parseQuery } from "../../../../utils/UrlUtils";
import _, { last } from "lodash";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import ChartFillInFormCategoryItem from "../ChartListItem";
import ChartClinicalNoteCategoryGroupItem from "../ChartClinicalNoteCategoryGroupItem";
import ChartSideSearch from "../ChartSideSearch";
import DataList from "../../../widgets/DataList";
import { useDispatch } from "react-redux";
import {
  openItem,
  setSearchValue,
  loadMedicalFillInFormTemplateList,
  getMedicalFillInFormTemplateQuestions,
  pushStack,
  approveConsultationInitialExamination,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import { MedicalFormsScreenTypes } from "../../../../constants/Constants";
import { openPatientDocsSideBar } from "../../../../actions/chartActions";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import Show from "../../../widgets/Show";
import { chosenMemberIdSelector, membersSelector } from "../../../../reducers/authReducer";
import { useShallowEqualSelector } from "../../../../hooks/useShallowEqualSelector";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";
import BackImage from "../../../../assets/images/white-icon-back.png";
import * as ActionType from "../../../../actionTypes/medicalFillInFormActionTypes";

const MedicalFillInFormList = (props) => {
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const dispatch = useDispatch();
  const query = parseQuery(props.location?.search);
  const { chartSideMedicalFillInForm, initialExaminationItem, onSaveItem } = props;
  const members = useShallowEqualSelector(membersSelector);
  const chosenMemberId = useShallowEqualSelector(chosenMemberIdSelector);
  const member = useMemo(() => members[chosenMemberId], [chosenMemberId, members]);

  const warnModalHandler = () => {
    return dispatch(
      openSimpleModal({
        body: "Do you want to save your changes?",
        footer: (
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger ml-3"
              onClick={() => {
                dispatch(closeSimpleModal());
              }}
            >
              No
            </button>
            <button
              className="btn btn-success ml-3"
              onClick={() => {
                onSaveItem();
                dispatch(closeSimpleModal());
              }}
            >
              Yes
            </button>
          </div>
        ),
      }),
    );
  };

  const openItemHandler = (item) => {
    const {
      stack,
      activeTab,
      initialExaminationItem,
      dataToFill,
      isUserLeavingWithoutSaving,
      consultationItems,
    } = chartSideMedicalFillInForm;
    const _isUserLeavingWithoutSaving = !!dataToFill && isUserLeavingWithoutSaving;
    const lastStack = last(stack);

    if (
      (lastStack?.type === medicalFillInFormItemTypes.edit ||
        lastStack?.type === medicalFillInFormItemTypes.new) &&
      _isUserLeavingWithoutSaving
    ) {
      return warnModalHandler();
    }

    if (consultationItems?.length > 0) {
      dispatch({ type: ActionType.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
    }

    const stackType =
      query?.medicalType === medicalFillInFormItemTypes.edit
        ? medicalFillInFormItemTypes.edit
        : query?.medicalType === medicalFillInFormItemTypes.view
        ? medicalFillInFormItemTypes.view
        : medicalFillInFormItemTypes.new;
    dispatch(openItem(item, stackType));

    const consultationId = activeTab.isInitialExamination
      ? initialExaminationItem?.consultationId
      : chartSideMedicalFillInForm.consultationId;
    const transfer = {
      patientKey: query.patientKey,
      consultationId,
      templateId: item.id,
    };
    dispatch(getMedicalFillInFormTemplateQuestions(transfer));
  };

  const openHardCodedItem = (item) => {
    // INITIAL_ASSESSMENT
    const view = props.showOnlyHardcodedList ? medicalFillInFormItemTypes.view : "edit";

    const { consultationItems } = chartSideMedicalFillInForm;
    if (consultationItems?.length > 0) {
      dispatch({ type: ActionType.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
    }
    switch (item.code) {
      case MedicalFormsScreenTypes.MEDICAL_ALERTS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.medicalAlerts, type: view }));
        break;
      case MedicalFormsScreenTypes.CONSENT_FORMS:
        dispatch(
          pushStack({
            code: medicalFillInFormStackCodes.consentForms,
            type: medicalFillInFormItemTypes.list,
          }),
        );
        break;
      case MedicalFormsScreenTypes.PRESCRIPTION:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.prescription, type: view }));
        break;
      case MedicalFormsScreenTypes.VITALS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.vitals, type: view }));
        break;
      case MedicalFormsScreenTypes.ORDERS_TAB:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.order, type: view }));
        break;
      case MedicalFormsScreenTypes.POST_OP_INSTRUCTIONS:
        dispatch(
          pushStack({
            code: medicalFillInFormStackCodes.postOp,
            type: view,
          }),
        );
        break;
      case MedicalFormsScreenTypes.DOCUMENTS_AND_IMAGES:
        dispatch(openPatientDocsSideBar());
        dispatch(
          pushStack({
            code: medicalFillInFormStackCodes.item,
            type: view,
          }),
        );
        break;
      case MedicalFormsScreenTypes.TREATMENTS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.treatment, type: view }));
        break;
      case MedicalFormsScreenTypes.DRUG_TREATMENTS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.drugTreatment, type: view }));
        break;
      case MedicalFormsScreenTypes.DIAGNOSIS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.diagnosis, type: view }));
        break;
      case MedicalFormsScreenTypes.PROVISIONAL_DIAGNOSIS:
        dispatch(pushStack({ code: medicalFillInFormStackCodes.provisionalDiagnosis, type: view }));
        break;
      default:
        break;
    }
  };

  const onSearch = (event) => {
    dispatch(setSearchValue(event.target.value));
  };

  const getList = () => {
    const { chartSideMedicalFillInForm } = props;
    const { templateList, searchValue = "" } = chartSideMedicalFillInForm;

    const filteredList = templateList.filter(
      (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );

    if (groupId) {
      return filteredList
        .filter(({ group }) => group && group.id === groupId)
        .map((item) => (
          <ChartFillInFormCategoryItem
            item={item}
            key={item.id}
            style={{ background: "none" }}
            openItem={() => openItemHandler(item)}
          />
        ));
    }

    return _.uniqWith(
      filteredList.map((item) => {
        if (item.group && item.group.id) {
          return item.group;
        }

        return item;
      }),
      _.isEqual,
    ).map((item, idx) => {
      const hasGroup = !item.typeId && !item.speciality && !item.templateBody;

      if (hasGroup) {
        return (
          <ChartClinicalNoteCategoryGroupItem
            title={item.name}
            key={`group-${item.id}-${idx}`}
            onClick={() => {
              setGroupId(item.id);
              setGroupName(item.name);
            }}
          />
        );
      }

      return (
        <ChartFillInFormCategoryItem
          item={item}
          key={`item-${item.id}`}
          style={{
            background: "none",
            boxShadow:
              item.id === chartSideMedicalFillInForm.item?.template?.id
                ? "inset 0 3px 5px rgb(0 0 0 / 13%)"
                : "none",
          }}
          openItem={() => openItemHandler(item)}
        />
      );
    });
  };

  const getHardcodedList = () => {
    const { chartSideMedicalFillInForm } = props;
    const { hardCodedTemplateList, searchValue = "" } = chartSideMedicalFillInForm;
    hardCodedTemplateList.unshift({
      code: "_ST_DRUG_TREATMENTS",
      description: "Drug Treatments",
      id: 11000526654654,
      name: "Drug Treatments",
    });
    const filteredList = hardCodedTemplateList.filter(
      (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );

    if (groupId) {
      return filteredList
        .filter(({ group }) => group && group.id === groupId)
        .map((item) => (
          <ChartFillInFormCategoryItem
            item={item}
            key={item.id}
            style={{ background: "none" }}
            openItem={() => openHardCodedItem(item)}
          />
        ));
    }

    return _.uniqWith(
      filteredList.map((item) => {
        if (item.group && item.group.id) {
          return item.group;
        }

        return item;
      }),
      _.isEqual,
    )
      .sort((a, b) => {
        const aHasGroup = !a.typeId && !a.speciality && !a.templateBody;
        const bHasGroup = !b.typeId && !b.speciality && !b.templateBody;

        return (aHasGroup === bHasGroup ? 0 : aHasGroup ? -1 : 1) || a.id - b.id;
      })
      .map((item, idx) => {
        const hasGroup = !item.typeId && !item.speciality && !item.templateBody;

        if (hasGroup) {
          return (
            <ChartClinicalNoteCategoryGroupItem
              title={item.name}
              key={`group-${item.id}-${idx}`}
              onClick={() => {
                openHardCodedItem(item);
              }}
            />
          );
        }

        return (
          <ChartFillInFormCategoryItem
            item={item}
            key={`item-${item.id}`}
            style={{
              background: "none",
              boxShadow:
                item.id === chartSideMedicalFillInForm.item?.template?.id
                  ? "inset 0 3px 5px rgb(0 0 0 / 13%)"
                  : "none",
            }}
            openItem={() => openHardCodedItem(item)}
          />
        );
      });
  };
  const { fetching, searchValue, activeTab, consultationItems } = props.chartSideMedicalFillInForm;

  return (
    <div className="chart-clinical-note" style={{ height: "100%" }}>
      <div className="chart-side-body">
        <Show if={!activeTab.isInitialExamination}>
          <div
            className="d-flex align-items-center justify-content-between mb-1"
            style={{ gap: "5px" }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                background: "#bbb",
                width: 25,
                height: "100%",
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                if (consultationItems?.length > 0) {
                  dispatch({ type: ActionType.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
                }
                dispatch(
                  pushStack({
                    code: medicalFillInFormStackCodes.ownedList,
                    type: medicalFillInFormItemTypes.view,
                  }),
                );
              }}
            >
              <img src={BackImage} width={15} alt="back-img" />
            </div>

            <ChartSideSearch
              value={searchValue}
              onChange={onSearch}
              className="flex-grow-1"
              style={{ borderRadius: 2 }}
            />
          </div>
        </Show>
        <div className={`${activeTab.isInitialExamination ? "body justify-content-end" : "body"}`}>
          <Show if={!props.showOnlyHardcodedList}>
            <div className="chart-clinical-note-category-title">
              {groupName || "Ready Templates"}
            </div>
            <DataList
              fetching={fetching}
              style={{ overflowY: "auto", height: "100%" }}
              onScroll={() => dispatch(loadMedicalFillInFormTemplateList())}
            >
              {getList()}
            </DataList>
          </Show>
          <Show if={!activeTab.isInitialExamination}>
            <div className="chart-clinical-note-category-title mt-1">
              {"DOK32 hardcoded screens"}
            </div>

            <DataList
              fetching={fetching}
              style={{ overflowY: "auto", height: "100%" }}
              // onScroll={() => dispatch(loadMedicalFillInFormTemplateList())}
            >
              {getHardcodedList()}
            </DataList>
          </Show>

          {!_.get(initialExaminationItem, "approved", true) && activeTab.isInitialExamination && (
            <div className="mb-2 d-flex justify-content-start flex-shrink-0">
              <button
                type="button"
                className="btn btn-primary btn-block m-0"
                onClick={() => dispatch(approveConsultationInitialExamination())}
              >
                Approve
              </button>
            </div>
          )}
          {_.get(initialExaminationItem, "approved", false) && activeTab.isInitialExamination && (
            <div className="mb-2 d-flex justify-content-start flex-shrink-0">
              <span className="d-flex align-items-center">
                Approved by: {member && `${member.firstName} ${member.lastName}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicalFillInFormList;
