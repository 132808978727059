import $ from "jquery";
import React from "react";
import { withRouter } from "react-router";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import PropTypes from "prop-types";
import CallListCell from "./CallListCell";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { AppointmentNoteModal } from "../calendar/AppointmentNoteModal";

class CallListBody extends React.Component {
  static propTypes = {
    changeStatus: PropTypes.func,
    loadInsuranceNotes: PropTypes.func,
    setPatientKey: PropTypes.func,
    showSideBar: PropTypes.func,
    openInsuranceNote: PropTypes.func,
    appointmentNoteModalShow: PropTypes.func,
    openCallListPatientNote: PropTypes.func,
    noResponse: PropTypes.func,
    patientView: PropTypes.object,
    columns: PropTypes.array,
    appointmentNoteModal: PropTypes.string,
    appointmentNoteModalVisible: PropTypes.bool,
    columnWidth: PropTypes.number,
  };
  constructor(...args) {
    super(...args);

    this.state = {
      note: "",
      showModal: false,
      showNoteModal: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleScroll() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCellClick() {}

  componentDidMount() {
    const $title = $(".call-list-title-wrap");

    this.handleScroll = function () {
      $title.css({ marginLeft: -this.scrollLeft + "px" });
    };

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    this.handleCellClick = function (e) {
      if ($(e.target).hasClass("prevent-click")) return;

      const $cell = $(this);
      let clickCount = $cell.data("clickCount") || 0;
      clickCount++;
      $cell.data("clickCount", clickCount);
      if (clickCount === 1) {
        setTimeout(() => {
          const clickCount = $cell.data("clickCount");
          var $buttons = $cell.find(".cell-buttons");
          var pointId = $cell.attr("data-id");

          if ($buttons.is(":hidden") && $buttons.parents("body").length) {
            if (clickCount === 1) {
              component.props.history.push(
                createUrl(Routes.DashboardAppointment, {
                  params: { id: pointId },
                  query: { goBack: true },
                }),
              );
            } else {
              $buttons.css({ display: "flex" }).animate({ width: "325px" });
              const { columns, checkOtherDoctor } = component.props;
              let currentPoint;
              columns.forEach((col) => {
                col.list.forEach((point) => {
                  if (point.id === Number(pointId)) currentPoint = point;
                });
              });
              if (currentPoint) {
                const { patient, date, dentist } = currentPoint;
                checkOtherDoctor({ patientKey: patient.patientKey, date, doctorId: dentist.id });
              }
            }
          } else {
            const $target = $(e.target);

            if ($target.is(".patient-image")) {
              if (clickCount === 1) {
                component.props.history.push(
                  createUrl(Routes.DashboardAppointment, {
                    params: { id: pointId },
                    query: { goBack: true },
                  }),
                );
              } else {
                $buttons.animate({ width: "0" }, () => {
                  $buttons.css({ display: "none" });
                });
              }
            }

            const $moveAppt = $buttons.find(".move-appt");
            if ($moveAppt.is($target) || $moveAppt.has($target).length !== 0) {
              component.props.history.push(
                createUrl(Routes.DashboardAppointment, { params: { id: pointId } }),
              );
            }

            const $noResponse = $buttons.find(".no-response");
            if ($noResponse.is($target) || $noResponse.has($target).length !== 0) {
              $buttons.animate({ width: "0" }, () => {
                $buttons.css({ display: "none" });
              });
            }
          }

          $cell.data("clickCount", 0);
        }, 300);
      }
    };

    $("#callListBody").on("scroll", this.handleScroll).on("click", ".cell", this.handleCellClick);
  }

  componentWillUnmount() {
    $("#callListBody").off("scroll", this.handleScroll).off("click", ".cell", this.handleCellClick);
  }

  showCanceledModal = (id) => {
    this.setState({
      showModal: true,
      canceledAppointmentId: id,
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  cancelAppointment = () => {
    if (this.textarea) {
      var transfer = {
        id: this.state.canceledAppointmentId,
        comment: this.textarea.value,
        status: {
          code: "APPOINTMENT_STATUS_CANCELLED",
        },
      };
      this.closeModal();

      this.props.changeStatus(transfer);
    }
  };

  render() {
    const { columns, columnWidth } = this.props;
    const { note, showNoteModal } = this.state;

    const $columns = columns.map((column, index) => (
      <div key={index} className="call-list-column">
        {column.list.map((point, index) => (
          <CallListCell
            key={index}
            point={point}
            loadInsuranceNotes={this.props.loadInsuranceNotes}
            changeStatus={this.props.changeStatus}
            setPatientKey={this.props.setPatientKey}
            showSideBar={this.props.showSideBar}
            noResponse={this.props.noResponse}
            patientView={this.props.patientView}
            openInsuranceNote={this.props.openInsuranceNote}
            appointmentNoteModalShow={(x) => this.setState({ showNoteModal: true, note: x.note })}
            openCallListPatientNote={this.props.openCallListPatientNote}
            setPlannedModalVisible={this.props.setPlannedModalVisible}
            showCanceledModal={this.showCanceledModal}
          />
        ))}
      </div>
    ));

    return (
      <>
        <div
          className="call-list-body"
          id="callListBody"
          style={{ width: columns.length ? `${columnWidth * columns.length}px` : "100vw" }}
        >
          {$columns}
        </div>
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          title={<div>Do you really want to cancel this appointment?</div>}
          actions={
            <div>
              <Button onClick={this.closeModal}>No</Button>
              <Button onClick={this.cancelAppointment} bsStyle="danger">
                Yes
              </Button>
            </div>
          }
          keyboard={false}
          className="call-list-modal"
        >
          <textarea
            className="form-control"
            rows="10"
            ref={(node) => {
              this.textarea = node;
            }}
          />
        </Modal>
        <AppointmentNoteModal
          note={note}
          show={showNoteModal}
          onClose={() => this.setState({ showNoteModal: false, note: "" })}
        />
      </>
    );
  }
}
export default withRouter(CallListBody);
