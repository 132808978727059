import { useQuery, UseQueryResult } from "react-query";

import { createUrl } from "../../utils/UrlUtils";
import { DoctorProps } from "../../dto/MemberDTO";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

interface Query {
  readonly clinicId: string;
  readonly keywordIds?: string;
  readonly publicPoint?: boolean;
  readonly treatmentGroupId?: string;
}

export function useDoctorsListQuery(query: Query): UseQueryResult<DoctorProps[], Error> {
  return useQuery(["member", "doctor", "choose"], () =>
    httpClient.get(createUrl(ApiRoutes.MemberDoctorsList, { query })).then(({ data }) => data.data),
  );
}
