import { httpClient } from "./BaseApi";

const GET_TOWNS_URL = "api/address/towns.json";
const GET_STATES_URL = "api/address/states.json";
const GET_COUNTRIES_URL = "api/address/countries.json";

const DashboardApi = {
  getCountry: () => httpClient.get(GET_COUNTRIES_URL),
  getCity: (params) => httpClient.get(GET_TOWNS_URL, { params }),
  getState: (params) => httpClient.get(GET_STATES_URL, { params }),
};

export default DashboardApi;
