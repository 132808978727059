import React, { useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import { MSG_IS_CANCEL_APPOINTMENT } from "../../constants/Constants";
import { parseQuery } from "../../utils/UrlUtils";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

const AppointmentReasonModal = (props) => {
  const textarea = useRef();
  const history = useHistory();
  const location = useLocation();
  const [validationError, setValidationError] = useState(null);

  const validateTextarea = () => {
    const value = textarea.current.value.trim();

    if (!value) {
      setValidationError("Please, type the reason");
      return false;
    }

    return true;
  };

  const reasonModalSubmitHandler = () => {
    if (validateTextarea()) {
      if (parseQuery(location.search).goBack) {
        props.changeAppointmentStatus(props.status, textarea.current.value, () => history.goBack());
        props.closeReasonModal();
      } else {
        props.changeAppointmentStatus(props.status, textarea.current.value);
        props.closeReasonModal();
      }
    }
  };

  const handleChange = () => {
    const value = textarea.current.value.trim();
    setValidationError(value ? null : "Please, type the reason");
  };

  return (
    <Modal
      size="small"
      title={<div>{MSG_IS_CANCEL_APPOINTMENT}</div>}
      actions={
        <div>
          <Button
            onClick={() => {
              props.closeReasonModal();
              setValidationError(null);
            }}
          >
            No
          </Button>
          <Button bsstyle="danger" onClick={reasonModalSubmitHandler}>
            Yes
          </Button>
        </div>
      }
      onExit={() => setValidationError(null)}
      show={props.reasonModalVisible}
      onHide={props.closeReasonModal}
      keyboard={false}
    >
      <textarea
        rows="10"
        className={`form-control ${validationError ? "is-invalid" : ""}`}
        ref={textarea}
        onChange={handleChange}
      />
      {validationError && (
        <div style={{ marginTop: "3px", color: "#ff4d4f" }}>{validationError}</div>
      )}
    </Modal>
  );
};

export default AppointmentReasonModal;
