import React, { useEffect } from "react";

import CallListDatePicker from "./CallListDatePicker";
import { DOCTORS_MODE } from "../widgets/DoctorsSelect";
import { DoctorsSelectWrapper } from "../../wrappers/DoctorsSelectWrapper";
import _ from "lodash";
import { SelectPicker } from "../select-picker/SelectPicker";
import { useDispatch, useSelector } from "react-redux";
import { getOtherStatus } from "../../actions/appointmentActions";
import CustomSelectBox from "../widgets/CustomSelectBox";
import Utils from "../../helpers/Utils";
import bindActions from "../../helpers/bindActions";
import { setOtherStatus } from "../../actions/callListActions";

// const enhancer = connect(({ appintment: { statusOtherList } }) => {
//   return { statusOtherList };
// }, bindActions({ getOtherStatus, setOtherStatus }));

function CallListForm({
  clinicId,
  onChangeClinic,
  clinics,
  status,
  onSubmit,
  setStatus,
  setMachine,
  machineId,
  statusList = [],
  machinesList = [],
}) {
  const $statusOptions = statusList.map((item) => (
    <option value={item.code} key={item.code}>
      {item.name}
    </option>
  ));
  const statusOtherList = useSelector((state) => state.appointment.statusOtherList);
  const otherStatusId = useSelector((state) => state.callList.otherStatusId);
  const dispatch = useDispatch();
  const firstMachine = _.head(machinesList);

  if (firstMachine && firstMachine.id === "") {
    machinesList.shift();
  }

  const machineOptions = machinesList.map(({ id, name }) => {
    return {
      value: id,
      label: name,
    };
  });

  useEffect(() => {
    dispatch(getOtherStatus());
  }, []);

  machineOptions.length && machineOptions.unshift({ value: "", label: "Madical device" });

  const otherStatus = statusOtherList.map((item) => {
    return { value: item.id, label: item.name };
  });
  return (
    <form className="form-inline d-flex" onSubmit={onSubmit}>
      <div className="form-group" style={{ minWidth: 150 }}>
        <SelectPicker
          placeholder="Other status"
          value={{ value: otherStatusId }}
          options={otherStatus}
          onChange={({ value }) => dispatch(setOtherStatus(value))}
        />
      </div>
      <div className="form-group">
        <SelectPicker
          placeholder="Filter by clinic"
          value={{ value: clinicId }}
          options={clinics}
          style={{ minWidth: "140px", marginRight: "2px" }}
          onChange={({ value }) => onChangeClinic(value)}
        />
      </div>
      <div className="form-group">
        <SelectPicker
          value={{ value: machineId || (machineOptions.length && machineOptions[0]["value"]) }}
          options={machineOptions}
          style={{ minWidth: "140px", marginRight: "2px" }}
          onChange={({ value }) => setMachine(value)}
        />
      </div>
      <div className="form-group">
        <select className="form-control" value={status} onChange={setStatus}>
          {$statusOptions}
        </select>
      </div>
      <div className="form-group">
        <DoctorsSelectWrapper
          searchable={true}
          mode={DOCTORS_MODE}
          hideBySelect={true}
          allDoctorsTitle="All"
          onlyDoctors={true}
        />
      </div>
      <div className="form-group">
        <CallListDatePicker />
      </div>
      <div className="form-group">
        <button className="btn btn-default" type="submit">
          Search
        </button>
      </div>
    </form>
  );
}

export default CallListForm;
