import _ from "lodash";
import React from "react";
import moment from "moment";

import MemberImage from "../widgets/MemberImage";
import * as followUpActions from "../../actions/followUpActions";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import { withRouter } from "react-router";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { IconButton } from "@mui/material";
import ClinicNoteImage from "../../assets/images/chart/ClinicalNotesWhite@2x.png";
import { showSideBar as showClinical } from "../../actions/chartActions";
import viewTypes from "../../constants/chart-sidebar/viewTypes";

class FollowUpCell extends React.Component {
  render() {
    const {
      item,
      history: { push },
    } = this.props;

    const photo = item?.patient?.photo?.url;
    const chartNumber = item?.patient?.chartNumber;
    return (
      <div
        style={item.responded === false ? { background: "#eeb06b" } : {}}
        className={`waiting-list-row follow-up-row${item.done ? " follow-up-row-done" : ""}`}
      >
        <div className="waiting-list-cell follow-up-cell">
          <div className="waiting-list-cell-body follow-up-cell-body">
            <div
              onClick={() => this.props.dispatch(followUpActions.toggleEditForm(item))}
              className="member-container follow-up-cell follow-up-cell-1"
              style={{ cursor: "pointer" }}
            >
              <MemberImage size={70} photoUrl={photo} />
            </div>
            <div
              onClick={() => this.props.dispatch(followUpActions.toggleEditForm(item))}
              className="info-container follow-up-cell follow-up-cell-5"
              style={{ cursor: "pointer" }}
            >
              {item.doctor.name}
            </div>
            <div
              onClick={() => this.props.dispatch(followUpActions.toggleEditForm(item))}
              className="info-container follow-up-cell follow-up-cell-5"
              style={{ cursor: "pointer" }}
            >
              {_.get(item, "creator.name", "N/A")}
            </div>
            <div
              onClick={() => this.props.dispatch(followUpActions.toggleEditForm(item))}
              className="info-container follow-up-cell follow-up-cell-5"
              style={{ cursor: "pointer" }}
            >
              <div>
                <div className="patient-name">{_.get(item, "patient.fullName")}</div>
              </div>
            </div>
            <div
              onClick={() => this.props.dispatch(followUpActions.toggleEditForm(item))}
              className="info-container follow-up-cell follow-up-cell-5"
              style={{ cursor: "pointer" }}
            >
              <div>
                <div className="patient-name">#{chartNumber}</div>
              </div>
            </div>
            <div className="follow-up-cell follow-up-cell-3">
              <div>{_.get(item, "type.name")}</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>{moment(_.get(item, "date")).format(DATE_FORMAT_LEGACY)}</div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>
                {_.get(item, "lastReplayDate")
                  ? moment(_.get(item, "lastReplayDate")).format("DD.MM.YYYY HH:mm:ss")
                  : "-"}
              </div>
            </div>
            <div className="follow-up-cell follow-up-cell-5">
              <div>{_.get(item, "lastReplayMember.name") || "-"}</div>
            </div>
            <div className="follow-up-cell follow-up-cell-4">
              {!_.isEmpty(item.patient) && (
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    push(
                      createUrl(Routes.PatientProfile, {
                        query: {
                          patientKey: item.patient.patientKey,
                        },
                      }),
                    )
                  }
                >
                  Patient Profile
                </button>
              )}

              {!_.isEmpty(item.patient) && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.dispatch(
                      followUpActions.setData({
                        patientKey: item.patient.patientKey,
                        doctorId: item.doctor.id,
                      }),
                    );
                    push(
                      createUrl(Routes.DashboardChart, {
                        params: { type: "Planning" },
                        query: {
                          patientKey: item.patient.patientKey,
                          doctorId: item.doctor.id,
                        },
                      }),
                    );
                  }}
                >
                  Patient Chart
                </button>
              )}

              {!_.isEmpty(item.patient) && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    const query = this.props.dispatch(
                      followUpActions.openAppointment({
                        doctor: item.doctor.id,
                        patientKey: item.patient.patientKey,
                      }),
                    );
                    this.props.history.push(
                      createUrl(Routes.DashboardAppointment, {
                        query,
                        params: { id: 0 },
                      }),
                    );
                  }}
                >
                  Schedule
                </button>
              )}

              {!item.done && (
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.dispatch(followUpActions.complete(item))}
                >
                  Complete
                </button>
              )}
              <IconButton
                title="Clinical Notes"
                onClick={() =>
                  this.props.dispatch(
                    showClinical(viewTypes.clinicalNote, {
                      patientKey: _.get(item, "patient.patientKey"),
                    }),
                  )
                }
              >
                <img alt={""} src={ClinicNoteImage} className="zoom-2x" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(FollowUpCell);
