import React from "react";
import FilledImage from "../../widgets/FilledImage";

export default function condition(props) {
  const surfaceCodesImages = {
    abrasion: "abrasion",
    decay: "decay",
    provisionalRestoration: "provesional_restoration",
    amalgam: "option_amalgam",
    nonMetallicRestoration: "decay",
    metallicRestoration: "metallic_restoration",
    fractureSurface: "fracture_surface",
  };
  const { name, toothImage, color, surfaceString, surface } = props;

  if (!surfaceString) return null;
  let className = "ConditionCrown-wrap";
  let src = "";
  if (name === "fractureSurface") {
    if (!surface) return null;
    src = require(`../../../assets/images/conditions/${surfaceCodesImages[name]}/${surfaceCodesImages[name]}_${surfaceString}_${toothImage}_2@2x.png`)
      .default;
  } else {
    if (!toothImage) return null;
    src = require(`../../../assets/images/conditions/${surfaceCodesImages[name]}/${
      surfaceCodesImages[name]
    }_${surface ? "upper" : "lower"}_${toothImage}_${surfaceString}@2x.png`).default;
  }

  if (surface) {
    className += " tooth-image-ConditionCrown-center";
  } else {
    className += " tooth-image-ConditionCrown";
  }

  if (name === "nonMetallicRestoration") {
    return (
      <div className={className}>
        <FilledImage src={src} color={color} className="zoom-2x" />
      </div>
    );
  }

  return (
    <div className={className}>
      <img alt="" src={src} className="zoom-2x" />
    </div>
  );
}
