import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { FormCheck } from "react-bootstrap";
import { Modal } from "../../ui/Modal";
import ReactSpinner from "react-spinjs-fix";

import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as chartSideTransactionActions from "../../../actions/chartSideTransactionActions";
import * as loaderActions from "../../../actions/loaderActions";

const enhancer = connect(null, bindActions({ loaderActions, chartSideTransactionActions }));
export default enhancer(
  class SelectTreatmentModal extends React.Component {
    componentDidMount() {
      this.props.chartSideTransactionActions.initApproveList();
    }

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartSideTransactionActions.loadApproveList();
      }
    };

    toggleApprove = (key) => {
      return () => {
        this.props.chartSideTransactionActions.toggleApprove(key);
      };
    };

    submit = () => {
      const { selectedApprove } = this.props.chartSideTransaction;

      if (!_.filter(selectedApprove, (item) => item).length) {
        return this.props.loaderActions.showMessage("Please, choose at least one transaction");
      }

      this.props.chartSideTransactionActions.submitApprove();

      this.props.element.handler(false);
    };

    render() {
      const { element } = this.props;
      const { approveList, selectedApprove, fetchingApprove } = this.props.chartSideTransaction;

      const $list = approveList.map((item, index) => (
        <div className="default-table-row" key={index} onClick={this.toggleApprove(item.key)}>
          <div className="default-table-col col-1">
            <div className="middler">
              {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="default-table-col col-2">
            <div className="middler">{item.treatmentItem && item.treatmentItem.code}</div>
          </div>
          <div className="default-table-col col-3">
            <div className="middler">{item.tooth && item.tooth.name}</div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler">{item.treatmentItem && item.treatmentItem.description}</div>
          </div>
          <div className="default-table-col col-5">
            <div className="middler">{item.price}</div>
          </div>
          <div className="default-table-col col-6">
            <div className="middler">{item.diagnosisItem && item.diagnosisItem.code}</div>
          </div>
          <div className="default-table-col col-7">
            <div className="middler">{item.createdDentist && item.createdDentist.name}</div>
          </div>
          <div className="default-table-col col-8">
            <FormCheck checked={selectedApprove[item.key]} readOnly={true} />
          </div>
        </div>
      ));

      const checkedAll = _.filter(selectedApprove, (item) => item).length === approveList.length;

      return (
        <Modal
          dialogClassName="chart-finish-modal"
          show={true}
          animation={true}
          keyboard={false}
          title={<div>Select treatments to submit for approval</div>}
          actions={
            <div>
              <button className="btn btn-lg" onClick={() => element.handler(false)}>
                Cancel
              </button>
              <button className="btn btn-lg btn-success" onClick={this.submit}>
                Submit
              </button>
            </div>
          }
        >
          <div className="default-table chart-approve-table">
            <div className="default-table-header">
              <div className="default-table-col col-1">Date posted</div>
              <div className="default-table-col col-2">Treatment code</div>
              <div className="default-table-col col-3">Tooth</div>
              <div className="default-table-col col-4">Description</div>
              <div className="default-table-col col-5">Fee</div>
              <div className="default-table-col col-6">Principal</div>
              <div className="default-table-col col-7">Posted by</div>
              <div
                className="default-table-col col-8"
                onClick={this.props.chartSideTransactionActions.toggleAllApprove}
              >
                <FormCheck checked={checkedAll} readOnly={true} />
              </div>
            </div>
            <div className="default-table-body" onScroll={this.loadList}>
              {$list}
            </div>
            <Show if={fetchingApprove}>
              <ReactSpinner />
            </Show>
          </div>
        </Modal>
      );
    }
  },
);
