import React, { useMemo } from "react";
import { useTheme } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";

interface Props {
  readonly size: number;
  readonly color: string;
}

export function CheckIcon({ size = 24, color }: Props) {
  const theme = useTheme<Theme>();

  const fill = useMemo(() => {
    if (!color) {
      return theme.palette.common.black;
    }

    return color;
  }, [color, theme.palette.common.black]);

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path fill={fill} d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
    </svg>
  );
}
