import React from "react";
import moment from "moment";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

export default class WaitingListRowHeader extends React.Component {
  render() {
    const date = moment(this.props.date).format(DATE_FORMAT_LEGACY);

    return (
      <div className="waiting-list-cell-header header">
        <div>{date}</div>
        <div>{date}</div>
        <div>{date}</div>
      </div>
    );
  }
}
