import React from "react";
import { useDispatch } from "react-redux";
import Utils from "../../../../helpers/Utils";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import { disableInitialExaminationTabToggle } from "../../../../actions/chartSideMedicalFillInFormActions";

const MedicalFillInFormOwnedItem = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const { openItem, editItem, openAddendumItem, consultationId } = props;
  return (
    <div
      role="button"
      className={`btn btn-default chart-clinical-note-item mb-1 ${
        item.consultationId === consultationId ? "medical-fill-in-form-list-item" : ""
      }`}
      style={{
        position: "relative",
        paddingRight: "12px",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <strong>{item.name}</strong>

        <div className="d-flex align-items-center">
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              openAddendumItem(item.id);
            }}
            className="mr-2 btn btn-default btn-sm"
          >
            <span className="font-weight-bold cursor-pointer">Addendum</span>
          </div>
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();

              dispatch(disableInitialExaminationTabToggle(false));
              openItem(item);
            }}
            className="mr-2 btn btn-default btn-sm"
          >
            <span className="font-weight-bold cursor-pointer">View</span>
          </div>
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();

              dispatch(disableInitialExaminationTabToggle(false));
              editItem(item);
            }}
            className="btn btn-default btn-sm"
          >
            <span className="font-weight-bold cursor-pointer">Edit</span>
          </div>
        </div>
      </div>

      <br />

      <div className="d-flex align-items-end">
        <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
          <div style={{ fontSize: "12px", color: "#333" }}>
            by &nbsp;
            {item.member && item.member.name}
          </div>
          <div style={{ fontSize: "12px", color: "#333" }}>
            {Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(item.createdDate),
              DATE_FORMAT_LEGACY,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalFillInFormOwnedItem;
