import React from "react";

import Back from "../widgets/Back";

export default class QuickMode extends React.Component {
  setView = () => {
    const type = localStorage.getItem("quickMode") === "true" ? "false" : "true";
    localStorage.setItem("quickMode", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Quick Mode</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView()} className="list-group-item">
              {Boolean(localStorage.getItem("quickMode") === "true") && (
                <div className="pull-right">&#10004;</div>
              )}
              Enable favorites shortcut
            </span>
          </div>
        </div>
      </div>
    );
  }
}
