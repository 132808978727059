import _ from "lodash";
import React, { useState } from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import favouriteActiveImage from "../../../assets/images/chart/favorite_active.png";
import favouriteInActiveImage from "../../../assets/images/chart/favorite.png";
import { connect, useDispatch } from "react-redux";
import * as SeriesUtils from "../../../helpers/chartSideSeriesUtils";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { createTransactions } from "../../../actions/chartSideSeriesActions";
import { TransactionStatusSelectItem } from "../../../constants/TransactionTypes";
import { medicalFillInFormStackCodes } from "../../../constants/chart-sidebar/medicalFIllInFormStackCodes";

const enhancer = connect(
  ({
    session: { member },
    chart: { patient, activeTooth, teeth, appointment },
    chartSideMedicalFillInForm: { stack },
  }) => ({
    member,
    patient,
    activeTooth,
    teeth,
    appointment,
    stack,
  }),
);
export default SortableElement(enhancer(ChartSideFavouritesList));

function ChartSideFavouritesList({
  item,
  openItem,
  isSelectItem,
  isMultiSelect,
  toggleFavourite,
  treatmentDescriptionType,
  member,
  patient,
  activeTooth,
  teeth,
  appointment,
  stack,
}) {
  const DragHandle = SortableHandle(() => <div className="drag-handle">::</div>);
  const [isFavourite, setIsFavourite] = useState(false);
  const isProvisionalDiagnosis =
    _.last(stack)?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

  const itemDescription =
    _.get(treatmentDescriptionType, "code") === "DESCRIPTION"
      ? item.description
      : item.description2;

  const handleToggle = () => {
    toggleFavourite(item.code, item.$seriesType);
    setIsFavourite(!isFavourite);
  };
  const dispatch = useDispatch();
  const addToPlannedDiagnosis = (item) => {
    let tooth = {};
    let toothPosition = {};
    if (activeTooth !== 0) {
      tooth = SeriesUtils.getToothByActiveTooth(activeTooth, teeth);
      if (tooth) {
        const tempArr = tooth.code.split("_");
        if (tempArr && tempArr.length) {
          const positionCode = tempArr[tempArr.length - 1];
          toothPosition = {
            id: positionCode.slice(1),
            code: positionCode,
          };
        }
      }
    }
    const appointmentId = appointment?.id;
    const diagnosis = {
      ...item,
      closedAppointmentId: appointmentId,
      createdAppointmentId: appointmentId,
      currentAppointmentId: appointmentId,
      area: { id: 4, name: "Mouth", code: "MOUTH" },
      areaDetails: [],
      assignedDoctor: member,
      createdDentist: member,
      diagnosisItem: item.id,
      diagnosisItems: null,
      diagnosisYearOfOnSet: null,
      patientKey: patient.patientKey,
      // sendBillInsurance: true,
      // sendBillInsurance2: true,
      status: isProvisionalDiagnosis
        ? TransactionStatusSelectItem.PROVISIONAL.getShortRequest()
        : TransactionStatusSelectItem.PLANNED.getShortRequest(),
      tooth,
      toothPosition,
      type: { id: 1, code: "DIAGNOSIS" },
      unitBox: 1,
    };
    dispatch(startLoader());
    dispatch(createTransactions({ dentistToCashierNote: "", list: [diagnosis] }))
      .then(() => dispatch(stopLoader("Successfully created")))
      .catch((error) => dispatch(stopLoader(error)));
  };
  const isDiagnosis = item.area;
  return (
    <button
      type="button"
      className="list-group-item list-group-item-code"
      style={
        isMultiSelect && isSelectItem ? { backgroundColor: "cornflowerblue", color: "white" } : {}
      }
    >
      {isDiagnosis && (
        <div
          onClick={() => addToPlannedDiagnosis(item)}
          style={{
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30px",
            height: "100%",
            borderRight: "1px solid #ddd",
          }}
        >
          +
        </div>
      )}
      <div role="button" className="code" onClick={openItem}>
        {item.code}
      </div>
      <DragHandle />
      <div role="button" className="separator" onClick={openItem}>
        <div className="description">{itemDescription}</div>
      </div>
      <div role="button" className="favourite" onClick={handleToggle}>
        <img alt="fav" src={isFavourite ? favouriteInActiveImage : favouriteActiveImage} />
      </div>
    </button>
  );
}
