import React from "react";
import { connect } from "react-redux";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
const ToothImageNames = {
  Adult_p1: 18,
  Adult_p2: 17,
  Adult_p3: 16,
  Adult_p4: 15,
  Adult_p5: 14,
  Adult_p6: 13,
  Adult_p7: 12,
  Adult_p8: 11,
  Adult_p9: 21,
  Adult_p10: 22,
  Adult_p11: 23,
  Adult_p12: 24,
  Adult_p13: 25,
  Adult_p14: 26,
  Adult_p15: 27,
  Adult_p16: 28,
  Adult_p17: 38,
  Adult_p18: 37,
  Adult_p19: 36,
  Adult_p20: 35,
  Adult_p21: 34,
  Adult_p22: 33,
  Adult_p23: 32,
  Adult_p24: 31,
  Adult_p25: 41,
  Adult_p26: 42,
  Adult_p27: 43,
  Adult_p28: 44,
  Adult_p29: 45,
  Adult_p30: 46,
  Adult_p31: 47,
  Adult_p32: 48,
  Child_p4: 17,
  Child_p5: 16,
  Child_p6: 13,
  Child_p7: 12,
  Child_p8: 11,
  Child_p9: 21,
  Child_p10: 22,
  Child_p11: 23,
  Child_p12: 26,
  Child_p13: 27,
  Child_p20: 37,
  Child_p21: 36,
  Child_p22: 33,
  Child_p23: 32,
  Child_p24: 31,
  Child_p25: 41,
  Child_p26: 42,
  Child_p27: 43,
  Child_p28: 46,
  Child_p29: 47,
};
const enhancer = connect(null, bindActions({ chartSideClinicalNoteActions }));
export default enhancer(
  class ChartClinicalNoteQuestionTooth extends React.Component {
    selectTooth = () => {
      const { item, question, index } = this.props;

      this.props.selectIndex(index);

      this.props.chartSideClinicalNoteActions.selectTooth(item.id, question.id);
    };

    render() {
      const { item, toothNumericType, question } = this.props;
      const answers = question.answers || [];

      const numericKey = toothNumericType?.code == "Index1" ? "numeric1" : "numeric2";

      const imageNumber = ToothImageNames[item.id];

      let img = null;

      if (imageNumber) {
        const src = require(`../../../../assets/images/teeth/${ToothImageNames[item.id]}_1@2x.png`)
          .default;
        img = <img src={src} alt="" className="" />;
      }

      const active = (answers || []).find((value) => value == item.id);
      const className = `chart-clinical-note-tooth${active ? " active" : ""}`;
      return (
        <div className={className} onClick={this.selectTooth}>
          <div className="img">{img}</div>
          <div className="key">{item[numericKey]}</div>
        </div>
      );
    }
  },
);
