import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactSpinner from "react-spinjs-fix";
import PropTypes from "prop-types";

import Back from "../widgets/Back";
import Show from "../widgets/Show";
import { Routes } from "../../constants/Routes";
import bindActions from "../../helpers/bindActions";
import PatientFormsAddModal from "./PatientFormsAddModal";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";
import alertImage from "../../assets/images/patient/form_alert.png";
import logoImage from "../../assets/images/clinic_logo.png";
import DeleteImage from "../../assets/images/delete.png";
import {
  pushStack,
  setConsentFormViewDetails,
} from "../../actions/chartSideMedicalFillInFormActions";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import medicalFillInFormItemTypes from "../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { SelectPicker } from "../select-picker/SelectPicker";
import { getClinics, setChosenClinic } from "../../actions/authActions";

const enhancer = connect(
  ({
    session: { permissions, clinic },
    auth: { clinics, chosenClinic },
    patientRegister,
    chart: { patient },
  }) => ({
    patientRegister,
    patient,
    permissions,
    chosenClinic,
    clinics,
    clinic,
  }),
  bindActions({
    patientRegisterActions,
    openSimpleModal,
    closeSimpleModal,
    pushStack,
    setConsentFormViewDetails,
    getClinics,
    setChosenClinic,
  }),
);
class PatientFormsList extends React.Component {
  static propTypes = {
    patientRegisterActions: PropTypes.object,
    location: PropTypes.object,
    routerActions: PropTypes.object,
    patientRegister: PropTypes.object,
    patient: PropTypes.object,
    permissions: PropTypes.array,
    openSimpleModal: PropTypes.func,
    closeSimpleModal: PropTypes.func,
    getClinics: PropTypes.func,
  };

  componentDidMount() {
    this.props.setChosenClinic(-1);
    const query = parseQuery(this.props.location.search);
    this.props.patientRegisterActions.getPatientFormsList(query.patientKey);
    this.props.getClinics();
  }

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;
    const query = parseQuery(this.props.location.search);

    if (scrollBottom) {
      this.props.patientRegisterActions.loadPatientFormsList(query.patientKey);
    }
  };

  openItem = (signed, id, templateId) => {
    return () => {
      const { medicalForms, setConsentFormViewDetails, pushStack } = this.props;
      const query = parseQuery(this.props.location.search);
      if (medicalForms) {
        if (signed) {
          setConsentFormViewDetails({ id, patientKey: query.patientKey });
          pushStack({
            code: medicalFillInFormStackCodes.consentForms,
            type: medicalFillInFormItemTypes.view,
          });
        } else {
          if (this.props.permissions.indexOf("EDIT_CONSENT_FORM_ITEM") === -1) {
            return this.props.openSimpleModal({
              body: "permission denied to edit item",
              footer: (
                <button className="btn btn-default" onClick={this.props.closeSimpleModal}>
                  ok
                </button>
              ),
            });
          }
          setConsentFormViewDetails({ id, templateId, patientKey: query.patientKey });
          pushStack({
            code: medicalFillInFormStackCodes.consentForms,
            type: medicalFillInFormItemTypes.edit,
          });
        }
      } else {
        if (signed) {
          this.props.history.push(
            createUrl(Routes.PatientFormsItem, {
              query: { id, patientKey: query.patientKey },
            }),
          );
        } else {
          if (this.props.permissions.indexOf("EDIT_CONSENT_FORM_ITEM") === -1) {
            return this.props.openSimpleModal({
              body: "permission denied to edit item",
              footer: (
                <button className="btn btn-default" onClick={this.props.closeSimpleModal}>
                  ok
                </button>
              ),
            });
          }
          this.props.history.push(
            createUrl(Routes.PatientFormsEdit, {
              query: { id, templateId, patientKey: query.patientKey },
            }),
          );
        }
      }
    };
  };

  render() {
    const { formsList, fetchingFormsList, addConsentFormModalVisible } = this.props.patientRegister;
    const query = parseQuery(this.props.location.search);

    const { patient, clinics, setChosenClinic, chosenClinic } = this.props;

    const clinicOptions = [{ label: "All clinics", value: -1 }];
    clinicOptions.push(...clinics.map((item) => ({ value: item.id, label: item.name })));

    const list = (formsList || []).map((element, index) => {
      return (
        <div
          className="appointments-list-row"
          key={index}
          style={{ cursor: "pointer" }}
          onClick={this.openItem(
            element.statusCode === "DocumentSignStatus_Signed",
            element.id,
            element.templateItem && element.templateItem.id,
          )}
        >
          <div className="double">
            <div className="exclamation">
              <Show if={element.statusCode !== "DocumentSignStatus_Signed"}>
                <img src={alertImage} alt="" />
              </Show>
            </div>
            {_.upperCase(element.templateItem.name || "")}
          </div>
          <div>{moment(element.createdDate).format(DATE_FORMAT_LEGACY) || ""}</div>
          <div>{moment(element.signedDate).format(DATE_FORMAT_LEGACY) || ""}</div>
          <div>{element.statusCode === "DocumentSignStatus_Signed" ? "SIGNED" : "NOT SIGNED"}</div>
          <Show if={query?.medicalType !== "view"}>
            <div
              style={{
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {element.statusCode !== "DocumentSignStatus_Signed" && (
                <div
                  role="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (this.props.permissions.indexOf("REMOVE_CONSENT_FORM_ITEM") === -1) {
                      return this.props.openSimpleModal({
                        body: "permission denied to delete item",
                        footer: (
                          <button className="btn btn-default" onClick={this.props.closeSimpleModal}>
                            ok
                          </button>
                        ),
                      });
                    }
                    this.props.patientRegisterActions.deletePatientFormsItem(
                      element.id,
                      query.patientKey,
                    );
                  }}
                  className="delete-button"
                >
                  <img src={DeleteImage} alt="" width={30} height={30} />
                </div>
              )}
            </div>
          </Show>
        </div>
      );
    });

    let patientData = patient.firstName || "";
    const lastName = patient.lastName || "";
    const chartNumber = patient.chartNumber || "";

    patientData += lastName !== "" ? ` ${lastName}` : "";
    patientData += patientData !== "" && chartNumber !== "" ? ", " : "";
    patientData += chartNumber !== "" ? `#${chartNumber}` : "";

    return (
      <div className="patient-appointments-list" style={{ height: "100%" }}>
        <div className="default-page-title clearfix text-center">
          <Show if={!this.props.medicalForms}>
            <Back className="pull-left" />
          </Show>
          <div className="btn-user-name pull-right">{patientData}</div>
          <img src={logoImage} alt="" className="header-logo" />
        </div>
        <div className="d-flex justify-content-end mt-3 mr-4">
          <div style={{ width: "400px" }}>
            <SelectPicker
              value={{ value: chosenClinic }}
              options={clinicOptions}
              onChange={(v) => {
                setChosenClinic(v.value);
                this.props.patientRegisterActions.getPatientFormsList(query.patientKey);
              }}
            />
          </div>
        </div>
        <div className="appointments-list-table" style={{ height: "100%" }}>
          <div className="appointments-list-header">
            <div className="double">Document Name</div>
            <div>Date posted</div>
            <div>Date signed</div>
            <div>Status</div>
            <div />
          </div>
          <div
            className="appointments-list-body"
            style={{ height: "100%", overflowY: "scroll" }}
            onScroll={this.loadList}
          >
            {list}
            <Show if={fetchingFormsList}>
              <ReactSpinner />
            </Show>
          </div>
          <Show if={query?.medicalType !== "view"}>
            <div
              role="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                if (this.props.permissions.indexOf("ADD_CONSENT_FORM_ITEM") === -1) {
                  return this.props.openSimpleModal({
                    body: "permission denied to add item",
                    footer: (
                      <button className="btn btn-default" onClick={this.props.closeSimpleModal}>
                        ok
                      </button>
                    ),
                  });
                }
                this.props.patientRegisterActions.openAddModal();
              }}
            >
              Add
            </div>
          </Show>
        </div>
        <Show if={addConsentFormModalVisible}>
          <PatientFormsAddModal
            patientRegister={this.props.patientRegister}
            patientKey={query.patientKey}
          />
        </Show>
      </div>
    );
  }
}
export default withRouter(enhancer(PatientFormsList));
