import React, { useMemo } from "react";

export default function ConditionCalculusGrade({ property, toothImage }) {
  const toothGrade = useMemo(() => {
    if (property && property.code === "TOOTHGRADE_GRADE1") {
      return "1";
    } else if (property && property.code === "TOOTHGRADE_GRADE2") {
      return "2";
    } else if (property && property.code === "TOOTHGRADE_GRADE3") {
      return "3";
    }
  }, [property]);

  const src = useMemo(() => {
    if (toothGrade) {
      return require(`../../../assets/images/conditions/calculus-grade/grade_${toothGrade}_${toothImage}_1@2x.png`)
        .default;
    }
  }, [toothGrade, toothImage]);

  if (!toothGrade) {
    return null;
  }

  return (
    <div className="condition-wrap tooth-image-condition">
      <img src={src} className="zoom-2x" alt="" />
    </div>
  );
}
