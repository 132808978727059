import AppointmentApi from "../api-legacy/AppointmentApi";

import { startLoader, stopLoader } from "./loaderActions";

export const SET_CALL_LIST_DATE = "SET_CALL_LIST_DATE";

export const GET_CALL_LIST_START = "GET_CALL_LIST_START";
export const GET_CALL_LIST_SUCCESS = "GET_CALL_LIST_SUCCESS";
export const GET_CALL_LIST_ERROR = "GET_CALL_LIST_ERROR";

export const CHANGE_STATUS_START = "CHANGE_STATUS_START";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";

export const CALL_LIST_GET_STATUS_LIST_START = "CALL_LIST_GET_STATUS_LIST_START";
export const CALL_LIST_GET_STATUS_LIST_SUCCESS = "CALL_LIST_GET_STATUS_LIST_SUCCESS";
export const CALL_LIST_GET_STATUS_LIST_ERROR = "CALL_LIST_GET_STATUS_LIST_ERROR";

export const NO_RESPONSE = "NO_RESPONSE";

export const SET_CALL_LIST_DOCTOR = "SET_CALL_LIST_DOCTOR";
export const SET_CALL_LIST_OTHER_STATUS = "SET_CALL_LIST_OTHER_STATUS";
export const CALL_LIST_SET_STATUS = "CALL_LIST_SET_STATUS";
export const CALL_LIST_SET_MACHINE = "CALL_LIST_SET_MACHINE";
export const CALL_LIST_INIT_DOCTORS = "CALL_LIST_INIT_DOCTORS";
export const CALL_LIST_DOCTOR_SELECT = "CALL_LIST_DOCTOR_SELECT";
export const CALL_LIST_SET_CLINIC_ID = "CALL_LIST_SET_CLINIC_ID";

export const CALL_LIST_CLOSE_MODAL_WINDOW = "CALL_LIST_CLOSE_MODAL_WINDOW";
export const CALL_LIST_SET_PLANNED_MODAL_VISIBLE = "CALL_LIST_SET_PLANNED_MODAL_VISIBLE";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";

export function setPlannedModalVisible(plannedItem) {
  return (dispatch) => {
    if (!plannedItem.plannedModalVisible) {
      dispatch({
        type: CLEAR_TRANSACTIONS,
      });
    }
    return dispatch({
      type: CALL_LIST_SET_PLANNED_MODAL_VISIBLE,
      plannedItem,
    });
  };
}

export function closeInsuranceModal() {
  return {
    type: CALL_LIST_CLOSE_MODAL_WINDOW,
  };
}

export function setClinicId(clinicId) {
  return {
    type: CALL_LIST_SET_CLINIC_ID,
    clinicId,
  };
}

export function updateCallListData() {
  return function (dispatch, getState) {
    dispatch({
      type: "updateCallListData",
    });
    // const otherStatusIds = "11000012";
    const {
      auth: { doctors = [] },
      callList: { date, status, machineId, clinicId, otherStatusId },
      session,
    } = getState();

    const computerTimeZone = date.utcOffset() * 60 * 1000;
    const companyTimeZone = +((session.company && session.company.timezone.description) || 0);

    const time = date.valueOf() + computerTimeZone - companyTimeZone;

    const doctorIds = doctors.filter((x) => x.active).map((x) => x.id);

    dispatch(startLoader());

    return dispatch(
      getCallList({
        time,
        clinicId,
        machineId,
        statusCode: status,
        doctorIds: doctorIds.join(",") || undefined,
        otherStatusIds: otherStatusId,
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeStatus(data) {
  return function (dispatch) {
    dispatch({
      type: "changeStatus",
    });

    dispatch(startLoader());

    return dispatch(putStatus(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function putStatus(data) {
  return {
    api: AppointmentApi.changeStatus,
    types: [CHANGE_STATUS_START, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_ERROR],
    data,
  };
}

export function noResponse(id) {
  return {
    type: NO_RESPONSE,
    id,
  };
}

function getCallList(data) {
  return {
    api: AppointmentApi.getCallList,
    types: [GET_CALL_LIST_START, GET_CALL_LIST_SUCCESS, GET_CALL_LIST_ERROR],
    data,
  };
}

export function getStatusList(data) {
  return {
    api: AppointmentApi.status.getChoose,
    types: [
      CALL_LIST_GET_STATUS_LIST_START,
      CALL_LIST_GET_STATUS_LIST_SUCCESS,
      CALL_LIST_GET_STATUS_LIST_ERROR,
    ],
    data,
  };
}

export function setDate(date) {
  return {
    type: SET_CALL_LIST_DATE,
    date,
  };
}

export function setDoctor(doctor) {
  return {
    type: SET_CALL_LIST_DOCTOR,
    doctor,
  };
}
export function setOtherStatus(status) {
  return {
    type: SET_CALL_LIST_OTHER_STATUS,
    status,
  };
}

export function setStatus(status) {
  return {
    type: CALL_LIST_SET_STATUS,
    status,
  };
}

export function setMachine(machineId) {
  return {
    type: CALL_LIST_SET_MACHINE,
    machineId,
  };
}
