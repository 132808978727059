import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { FormCheck } from "react-bootstrap";
import ReactSpinner from "react-spinjs-fix";

import Show from "../widgets/Show";

import bindActions from "../../helpers/bindActions";
import * as appointmentActions from "../../actions/appointmentActions";
import { sortTreatments } from "../../helpers/TreatmentUtils";

const sessionTrxValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const unitValues = [
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
];

const enhancer = connect(
  ({ auth: { doctors } }) => ({ doctors }),
  bindActions({ appointmentActions }),
);
export default enhancer(
  class AppointmentRelatedModalThat extends React.Component {
    static propTypes = {
      appointmentActions: PropTypes.object,
      appointment: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.state = {
        note: {},
      };
    }

    componentDidMount() {
      this.props.appointmentActions.initThat();
    }

    toggleThat = (key) => {
      return () => {
        this.props.appointmentActions.toggleThat(key);
      };
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.appointmentActions.loadThat();
      }
    };

    render() {
      const { codesForThat, thatSelected, fetchingRelated } = this.props.appointment;

      const $list = codesForThat.sort(sortTreatments).map((item, index) => {
        return (
          <div
            role="button"
            className="default-table-row"
            key={index}
            onClick={this.toggleThat(item.key)}
          >
            <div className="default-table-col col-1">
              <div className="middler">
                <div className="middler" role="button" onClick={(e) => e.stopPropagation()}>
                  <select
                    style={{ background: "none", border: "none" }}
                    onChange={(e) =>
                      this.props.changeTreatmentPlan(e.target.value, item.key, item.patientKey)
                    }
                  >
                    {sessionTrxValues.map((x) => (
                      <option selected={_.get(item, "treatmentPlan", 1) === x} key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="default-table-col col-2">
              <div className="middler">
                <div role="button" className="middler" onClick={(e) => e.stopPropagation()}>
                  <select
                    style={{ background: "none", border: "none" }}
                    onChange={(e) =>
                      this.props.changeTreatmentSession(e.target.value, item.key, item.patientKey)
                    }
                  >
                    {sessionTrxValues.map((x) => (
                      <option selected={_.get(item, "treatmentSession", 1) === x} key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="default-table-col col-3">
              <div className="middler">
                <div role="button" className="middler" onClick={(e) => e.stopPropagation()}>
                  <select
                    style={{ background: "none", border: "none" }}
                    onChange={(e) =>
                      this.props.changeUnit(e.target.value, item.key, item.patientKey)
                    }
                  >
                    {unitValues.map((x) => (
                      <option selected={_.get(item, "unit", 1) === x} key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="default-table-col col-3" style={{ flex: "0 0 120px" }}>
              <div role="button" className="middler" onClick={(e) => e.stopPropagation()}>
                <select
                  style={{ background: "none", border: "none" }}
                  onChange={({ target }) =>
                    this.props.changeAssignedDoctor(
                      Object.values(this.props.doctors).find((x) => `${x.id}` === target.value),
                      item.key,
                    )
                  }
                >
                  {Object.values(this.props.doctors).map((x) => (
                    <option
                      key={x.id}
                      value={x.id}
                      selected={_.get(item, "assignedDoctor.id") === x.id}
                    >
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="default-table-col col-4">
              <div className="middler">{_.get(item.treatmentItem, "code")}</div>
            </div>
            <div className="default-table-col col-5">
              <div className="middler">{_.get(item.treatmentItem, "description")}</div>
            </div>
            <div className="default-table-col col-5">
              <div role="button" className="middler" onClick={(event) => event.stopPropagation()}>
                <input
                  style={{ width: "100%" }}
                  onBlur={() =>
                    this.props.changeTreatmentNote(
                      this.state.note[index],
                      item.key,
                      item.patientKey,
                    )
                  }
                  onChange={({ target }) => {
                    this.setState((x) => ({
                      note: {
                        ...x.note,
                        [index]: target.value,
                      },
                    }));
                  }}
                  value={this.state.note[index]}
                  defaultValue={_.get(item, "note", "")}
                />
              </div>
            </div>
            <div className="default-table-col col-6">
              <div className="middler">{_.get(item.tooth, "name")}</div>
            </div>
            <div className="default-table-col col-7">
              <div className="middler">{_.get(item.area, "name")}</div>
            </div>
            <div className="default-table-col col-8">
              <div className="middler">{(+item.price).toFixed(2)}</div>
            </div>
            <div className="default-table-col col-9">
              <div className="middler">
                {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="default-table-col col-10">
              <FormCheck checked={thatSelected[item.key]} readOnly={true} />
            </div>
          </div>
        );
      });

      return (
        <div className="default-table chart-plan-table">
          <div className="default-table-header">
            <div className="default-table-col col-1">Trx Plan</div>
            <div className="default-table-col col-2">Session</div>
            <div className="default-table-col col-3">Unit</div>
            <div className="default-table-col col-3" style={{ flex: "0 0 120px" }}>
              Assigned Doctor
            </div>
            <div className="default-table-col col-4">Code</div>
            <div className="default-table-col col-5">Description</div>
            <div className="default-table-col col-5">Note</div>
            <div className="default-table-col col-6">Tooth</div>
            <div className="default-table-col col-7">Area</div>
            <div className="default-table-col col-8">Fee</div>
            <div className="default-table-col col-9">Created Date</div>
            <div className="default-table-col col-10" />
          </div>
          <div className="default-table-body" onScroll={this.loadList}>
            {$list}
          </div>
          <Show if={fetchingRelated}>
            <ReactSpinner />
          </Show>
        </div>
      );
    }
  },
);
