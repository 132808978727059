import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { withRouter } from "react-router";

import MissedCancelledListForm from "./MissedCancelledListForm";

import {
  cleanUpMissedCancelledList,
  getMissedCancelledList,
  missedCancelledListInit,
} from "../../actions/waitingListActions";
import { getDentist } from "../../actions/calendarActions";
import { openSimpleModal, closeSimpleModal } from "../../actions/simpleModalActions";
import {
  getCompanyEndOfDay,
  getCompanyStartOfDay,
  getCompanyUTCOffset,
} from "../../helpers/DateUtils";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import Utils from "../../helpers/Utils";
import { DATE_FORMAT_LEGACY, TIME_FORMAT } from "../../constants/Constants";
import { PatientItem } from "../patient/PatientItem";
import Back from "../widgets/Back";
import ReactSpinner from "react-spinjs";
import Show from "../widgets/Show";
import LogoImage from "../../assets/images/clinic_logo.png";
import { DateTime } from "luxon";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import {
  missedCancelledNotInterestedAppointment,
  missedCancelledAppointmentSetType,
  setMissedCancelledListActiveTab,
} from "../../actions/waitingListActions";
import { openPatientInfoSideBar } from "../../actions/patientActions";
import { SelectPicker } from "../select-picker/SelectPicker";
import PatientInfoSideBar from "../patient/PatientInfoSideBar";
import { getCategoryList } from "../../actions/appointmentActions";

const enhancer = connect(
  ({
    appointment: { categoryList },
    session: { clinic },
    waitingList,
    form: { MissedCancelledListForm },
    auth: { members },
  }) => {
    return {
      waitingList,
      members,
      categoryList,
      MissedCancelledListForm,
      clinicId: _.get(clinic, "id"),
    };
  },
  {
    missedCancelledListInit,
    getMissedCancelledList,
    cleanUpMissedCancelledList,
    openSimpleModal,
    closeSimpleModal,
    getDentist,
    missedCancelledNotInterestedAppointment,
    missedCancelledAppointmentSetType,
    setMissedCancelledListActiveTab,
    openPatientInfoSideBar,
    getCategoryList,
  },
);

class MissedCancelledList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clinicId: props.clinicId,
      tbodyHeight: 300,
      tdWidth: 0,
      featureTypeCode: "",
      showModal: false,
      isChecked: false,
      item: {},
    };
  }
  componentDidMount() {
    const { missedCancelledListInit, getCategoryList, location, getDentist, clinicId } = this.props;

    const query = parseQuery(location.search);

    getCategoryList({ clinicId });
    missedCancelledListInit({
      ...query,
      noAnswer: false,
      willCall: false,
      requestedReschedule: false,
      notInterested: false,
    });
    getDentist(clinicId);
    this.setState({
      tbodyHeight: document.body.offsetHeight - 300,
      tdWidth: document.body.offsetWidth / 9,
    });
  }

  handleSubmit = (values, start) => {
    const {
      getMissedCancelledList,
      history,
      location,
      waitingList: { missedCancelledListActiveTab },
    } = this.props;

    history.replace(
      createUrl(location.pathname, {
        query: {
          periodStart: getCompanyStartOfDay(values.periodStart),
          periodEnd: getCompanyEndOfDay(values.periodEnd),
        },
      }),
    );

    switch (missedCancelledListActiveTab) {
      case "missed":
        values.noAnswer = false;
        values.willCall = false;
        values.requestedReschedule = false;
        values.notInterested = false;
        break;
      case "requestedReschedule":
        values.requestedReschedule = true;
        values.noAnswer = false;
        values.willCall = false;
        values.notInterested = false;
        break;
      case "noAnswer":
        values.noAnswer = true;
        values.requestedReschedule = false;
        values.willCall = false;
        values.notInterested = false;
        break;
      case "willCall":
        values.willCall = true;
        values.noAnswer = false;
        values.requestedReschedule = false;
        values.notInterested = false;
        break;
      case "notInterested":
        values.notInterested = true;
        values.willCall = false;
        values.noAnswer = false;
        values.requestedReschedule = false;
        break;
      default:
        break;
    }
    getMissedCancelledList(values, start);
  };

  cleanUpList = (values) => {
    const {
      cleanUpMissedCancelledList,
      getMissedCancelledList,
      closeSimpleModal,
      openSimpleModal,
    } = this.props;
    const days = values.periodEnd.diff(values.periodStart, "days");
    if (days > 30) {
      openSimpleModal({
        body: "Please select a date less than a month",
      });
      return;
    }
    cleanUpMissedCancelledList(values, this.state.featureTypeCode).then(() => {
      this.setState({ featureTypeCode: "" });
      getMissedCancelledList(values, 0);
      closeSimpleModal();
    });
  };

  loadList = (e, values) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;
    if (scrollBottom) {
      const {
        getMissedCancelledList,
        waitingList: { missedCancelledListActiveTab },
      } = this.props;
      switch (missedCancelledListActiveTab) {
        case "missed":
          values.noAnswer = false;
          values.willCall = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "requestedReschedule":
          values.requestedReschedule = true;
          values.noAnswer = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "noAnswer":
          values.noAnswer = true;
          values.requestedReschedule = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "willCall":
          values.willCall = true;
          values.noAnswer = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "notInterested":
          values.notInterested = true;
          values.willCall = false;
          values.noAnswer = false;
          values.requestedReschedule = false;
          break;
        default:
          break;
      }
      getMissedCancelledList(values);
    }
  };

  setItemStatus = (status, values, appointment) => {
    const {
      getMissedCancelledList,
      history,
      location,
      missedCancelledNotInterestedAppointment,
      missedCancelledAppointmentSetType,
      waitingList: { missedCancelledListActiveTab },
    } = this.props;
    let request;
    let data;
    if (status === "notInterested") {
      data = { ...appointment, notInterested: true };
      request = missedCancelledNotInterestedAppointment;
    } else {
      data = { id: appointment.id, [status]: true };
      request = missedCancelledAppointmentSetType;
    }
    request(data).then(() => {
      history.replace(
        createUrl(location.pathname, {
          query: {
            periodStart: getCompanyStartOfDay(values.periodStart),
            periodEnd: getCompanyEndOfDay(values.periodEnd),
          },
        }),
      );

      switch (missedCancelledListActiveTab) {
        case "missed":
          values.noAnswer = false;
          values.willCall = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "requestedReschedule":
          values.requestedReschedule = true;
          values.noAnswer = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "noAnswer":
          values.noAnswer = true;
          values.requestedReschedule = false;
          values.willCall = false;
          values.notInterested = false;
          break;
        case "willCall":
          values.willCall = true;
          values.noAnswer = false;
          values.requestedReschedule = false;
          values.notInterested = false;
          break;
        case "notInterested":
          values.notInterested = true;
          values.willCall = false;
          values.noAnswer = false;
          values.requestedReschedule = false;
          break;
        default:
          break;
      }
      getMissedCancelledList(values, 0);
    });
  };

  onChecked = (item, _bool) => {
    this.setState({ item: item });
    this.setState({ showModal: _bool });
  };

  render() {
    const {
      clinicId,
      getDentist,
      waitingList: {
        missedCancelledFormValues,
        missedCancelledList,
        missedCancelledFetching,
        missedCancelledListActiveTab,
        periodStart,
        periodEnd,
        statusCodes,
      },
      location,
      openSimpleModal,
      closeSimpleModal,
      openPatientInfoSideBar,
      setMissedCancelledListActiveTab,
      categoryList,
    } = this.props;
    const { tbodyHeight, tdWidth, showModal } = this.state;
    let initialValues = {
      periodStart,
      periodEnd,
      ...missedCancelledFormValues,
      categoryIds: [],
      statusCodes,
    };
    if (missedCancelledFormValues.statusCodes) {
      initialValues.statusCodes = missedCancelledFormValues.statusCodes;
    }
    const query = parseQuery(location.search);
    if (query.periodEnd) {
      initialValues = {
        ...initialValues,
        periodEnd: moment(DateTime.fromMillis(_.toFinite(query.periodEnd)).toJSDate()),
      };
    }
    if (query.periodStart) {
      initialValues = {
        ...initialValues,
        periodStart: moment(
          DateTime.fromMillis(
            _.toFinite(_.toFinite(query.periodStart) + getCompanyUTCOffset()),
          ).toJSDate(),
        ),
      };
    }

    const actionOptions = [
      { value: "notInterested", label: "Not interested" },
      { value: "willCall", label: "Will call" },
      { value: "noAnswer", label: "No answer" },
      { value: "requestedReschedule", label: "Requested reschedule" },
    ];

    return (
      <>
        <PatientInfoSideBar />
        <Formik initialValues={initialValues} onSubmit={(values) => this.handleSubmit(values, 0)}>
          {({ values, setFieldValue, handleSubmit }) => (
            <div className="waiting-list-page p-0">
              <div className="default-page-title clearfix text-center">
                <Back className="pull-left" />
                <img src={LogoImage} alt="logo" className="header-logo" />
              </div>
              <MissedCancelledListForm
                setActiveTab={(activeTab) => {
                  setMissedCancelledListActiveTab(activeTab);
                  handleSubmit(values, 0);
                }}
                categoryList={categoryList}
                activeTab={missedCancelledListActiveTab}
                setFeatureTypeCode={(featureTypeCode) => {
                  this.setState({ featureTypeCode });
                  openSimpleModal({
                    body: (
                      <div>
                        <p>
                          - This action will clear up appointments from this list if there is any
                          future appointment from cancellation date.
                        </p>
                        <p>- This action can't be undone!</p>
                        <p>- Do you want to proceed?</p>
                      </div>
                    ),
                    footer: (
                      <div>
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            this.setState({ featureTypeCode: "" });
                            closeSimpleModal();
                          }}
                        >
                          cancel
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.cleanUpList(values);
                          }}
                        >
                          yes
                        </button>
                      </div>
                    ),
                  });
                }}
                clinicId={clinicId}
                values={values}
                setFieldValue={setFieldValue}
                onChangeClinic={({ value }) => {
                  this.setState({ clinicId: value });
                  getDentist(value);
                }}
              />
              <div style={{ height: "75%", overflow: "hidden" }}>
                <Show if={missedCancelledFetching}>
                  <ReactSpinner />
                </Show>
                <table className="table bring-earlier-table missed-cancelled-table">
                  <thead>
                    <tr style={{ borderTop: "1px solid #ccc" }}>
                      <th style={{ width: `${tdWidth - 1}px` }}>Patient</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Chart number</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Doctor</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Appointment date</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Time</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Appointment type</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Status</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Future Appointment</th>
                      <th style={{ width: `${tdWidth - 1}px` }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-hidden-scrollbar"
                    onScroll={(e) => this.loadList(e, values)}
                    style={{ height: `${tbodyHeight}px` }}
                  >
                    {missedCancelledList.map((x) => {
                      const doctor = _.get(x, "dentist.name");
                      const apptDate = Utils.parseDateFormat(moment(x.date), DATE_FORMAT_LEGACY);
                      const time = Utils.parseDateFormat(moment(x.date), TIME_FORMAT);
                      const apptType = x.category.name || "";
                      const status = x.status.name || "";
                      const futureTypeCode = x.futureTypeCode || "";
                      const chartNumber = _.get(x, "patient.chartNumber");

                      const patientLastName = _.get(x, "patient.lastName");
                      const patientFirstName = _.get(x, "patient.firstName");
                      const patientName = [patientFirstName, patientLastName].join(" ");
                      const notInterested = x.notInterested;
                      const willCall = x.willCall;
                      const noAnswer = x.noAnswer;
                      const requestedReschedule = x.requestedReschedule;
                      return (
                        <tr key={x.id} className="missed-cancelled-table-cell">
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <PatientItem
                              size={40}
                              photo={!_.isEmpty(x.patient.photo) && x.patient.photo.url}
                              name={patientName}
                            />
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{chartNumber}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{doctor}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{apptDate}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{time}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{apptType}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{status}</div>
                          </td>
                          <td
                            style={{ width: `${tdWidth}px` }}
                            onClick={() => openPatientInfoSideBar(x)}
                          >
                            <div>{futureTypeCode}</div>
                          </td>
                          <td style={{ width: `${tdWidth}px` }}>
                            <div
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <SelectPicker
                                options={actionOptions}
                                onChange={(item) => this.setItemStatus(item.value, values, x)}
                                onFocus={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                                value={{
                                  value:
                                    (notInterested && "notInterested") ||
                                    (willCall && "willCall") ||
                                    (noAnswer && "noAnswer") ||
                                    (requestedReschedule && "requestedReschedule"),
                                }}
                              />
                              {/*<Button bsStyle="primary" onClick={() => this.onChecked(x, true)}>*/}
                              {/*  Not Interested*/}
                              {/*</Button>*/}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Formik>
        <Modal
          size="small"
          className="missed-cancelled-table-modal"
          show={showModal}
          keyboard={false}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          actions={
            <div>
              <Button
                bsStyle="danger"
                onClick={() => {
                  this.onChecked({}, false);
                }}
              >
                Close
              </Button>

              {/*<Button bsStyle="primary" onClick={this.setNotInterested}>*/}
              {/*  OK*/}
              {/*</Button>*/}
            </div>
          }
        >
          <p>Are you sure ? This item will be hidden</p>
        </Modal>
      </>
    );
  }
}

export default withRouter(enhancer(MissedCancelledList));
