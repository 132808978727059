import React, { useEffect, useState } from "react";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { Button, ButtonType } from "../ui/Button";
import _ from "lodash";
import * as simpleModalActions from "../../actions/simpleModalActions";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeleteAppointmentOptions,
  setMissedAppointmentOptions,
} from "../../actions/appointmentActions";

const AppointmentStatusButtons = ({ elements }) => {
  const [canceledButtonsVisible, setCanceledButtonsVisible] = useState(false);
  const [missedButtonsVisible, setMissedButtonsVisible] = useState(false);
  const [canceledButtons, setCanceledButtons] = useState([]);
  const [missedButtons, setMissedButtons] = useState([]);
  const [canceledInActive, setCanceledInActive] = useState(false);
  const dispatch = useDispatch();
  const { options, selectedId, handler, disabled } = elements.statusSB;
  const permissions = useSelector((state) => state?.session?.permissions) || [];

  useEffect(() => {
    const canceledButtons = options.filter(
      (item) =>
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED,
    );
    const missedButtons = options.filter((item) => {
      return (
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
        item.code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW
      );
    });
    setCanceledButtons(canceledButtons);
    setMissedButtons(missedButtons);
    dispatch(setDeleteAppointmentOptions(canceledButtons));
    dispatch(setMissedAppointmentOptions(missedButtons));
  }, [options.length]);

  const canceledSelected = canceledButtons.filter((item) => item.id === selectedId);
  const missedSelected = missedButtons.filter((item) => item.id === selectedId);
  const filteredOptions = (options || []).filter(
    (value, index, self) => index === self.findIndex((x) => x.code === value.code),
  );

  // const isCheckedInStatus = (filteredOptions || []).find(
  //   (item) => item.code == STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
  // );

  const isHasCanceledPermission = permissions.find(
    (item) => item === "CHANGE_CLINIC_APPOINTMENT_STATUS_TO_CANCELLED",
  );

  const isBeingSeenStatus = (filteredOptions || []).find(
    (item) => item.code === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN,
  );
  const canceledBtnHandle = () => {
    if (isBeingSeenStatus?.id === selectedId) {
      if (isHasCanceledPermission === "CHANGE_CLINIC_APPOINTMENT_STATUS_TO_CANCELLED") {
        setCanceledButtonsVisible(true);
      } else {
        setCanceledInActive(true);
        dispatch(simpleModalActions.openSimpleModal({ body: "you don't have permission" }));
      }
    } else {
      setCanceledButtonsVisible(true);
    }
  };

  return (
    <div>
      {canceledButtonsVisible && (
        <div className="canceled-buttons-layer" onClick={() => setCanceledButtonsVisible(false)} />
      )}
      {missedButtonsVisible && (
        <div className="canceled-buttons-layer" onClick={() => setMissedButtonsVisible(false)} />
      )}
      {missedButtons.length ? (
        <div className="status-button-item">
          <Button
            type="button"
            className={`btn-lg mr-1 mb-1 canceled-btn ${
              missedSelected.length ? "canceled-btn__selected" : ""
            }`}
            buttonType={ButtonType.Primary}
            onClick={() => setMissedButtonsVisible(true)}
          >
            Missed
          </Button>
          <div
            className="canceled-buttons-wrapper"
            style={{ display: missedButtonsVisible ? "flex" : "none" }}
          >
            {(missedButtons || []).map(({ id, name }) => (
              <Button
                key={id}
                type="button"
                disabled={disabled}
                onClick={() => {
                  setMissedButtonsVisible(!missedButtonsVisible);
                  handler(_.toFinite(id));
                }}
                buttonType={id === selectedId ? ButtonType.Primary : ButtonType.Secondary}
                className={`btn-lg mr-1 mb-1 canceled-btn ${
                  id === selectedId ? "canceled-btn__selected" : ""
                }`}
              >
                {name}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
      {canceledButtons.length ? (
        <div className="status-button-item">
          <Button
            type="button"
            className={`btn-lg mr-1 mb-1 canceled-btn ${
              !canceledInActive && canceledSelected.length ? "canceled-btn__selected" : ""
            }`}
            buttonType={ButtonType.Primary}
            onClick={canceledBtnHandle}
          >
            Cancelled
          </Button>
          <div
            className="canceled-buttons-wrapper"
            style={{ display: canceledButtonsVisible ? "flex" : "none" }}
          >
            {(canceledButtons || []).map(({ id, name }) => (
              <Button
                key={id}
                type="button"
                disabled={!isBeingSeenStatus && disabled}
                onClick={() => {
                  setCanceledButtonsVisible(!canceledButtonsVisible);
                  handler(_.toFinite(id));
                }}
                buttonType={id === selectedId ? ButtonType.Primary : ButtonType.Secondary}
                className={`btn-lg mr-1 mb-1 canceled-btn ${
                  id === selectedId ? "canceled-btn__selected" : ""
                }`}
              >
                {name}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
      {(filteredOptions || []).map(({ id, name, code }) => {
        const checkedIn = code === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN;
        const missed =
          code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW;
        const cancelled =
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_RESCHEDULED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
          code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED;
        return (
          <div className="status-button-item" key={id}>
            <Button
              key={id}
              type="button"
              disabled={disabled}
              onClick={(e) => {
                checkedIn ? elements.checkInBtn.handler(e) : handler(_.toFinite(id));
              }}
              buttonType={id === selectedId ? ButtonType.Primary : ButtonType.Secondary}
              className={`btn-lg mr-1 mb-1 ${checkedIn && "checked-in-btn"} ${
                checkedIn && id === selectedId && "checked-in-btn__selected"
              }`}
              style={{ display: cancelled || missed ? "none" : "inlineFlex" }}
            >
              {name}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default AppointmentStatusButtons;
