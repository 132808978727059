import { httpClient } from "./BaseApi";

const NEED_PRE_APPROVAL_URL = "api/claim/need-pre-approval.json";
const SEND_PRE_APPROVAL_URL = "api/claim/send-to-pre-approval.json";
const HAS_PRE_APPROVAL_URL = "api/claim/has-patient-insurance.json";

const ClaimApi = {
  sendToPreApproval: (data) => httpClient.post(SEND_PRE_APPROVAL_URL, { data }),
  needPreApproval: (params) => httpClient.get(NEED_PRE_APPROVAL_URL, { params }),
  hasPatientInsurance: (params) => httpClient.get(HAS_PRE_APPROVAL_URL, { params }),
};

export default ClaimApi;
