import React from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { Modal } from "../ui/Modal";
import { PopupType } from "../../constants/Constants";
import { setPopupActionByKey } from "../../actions/popupActions";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { popupPreventClosingSelector, popupSmallSelector } from "../../reducers/popupReducer";

export default function ChooseOptionModal() {
  const dispatch = useDispatch();

  const popupSmall = useShallowEqualSelector(popupSmallSelector as any);
  const preventClosing = useShallowEqualSelector(popupPreventClosingSelector as any);

  const { buttons = [], self, title } = popupSmall;

  const buttonsCount = buttons.length;

  const hideMethod = () => {
    if (!preventClosing) {
      dispatch(setPopupActionByKey(PopupType.Small, "visible", false));
    }
  };

  if (!self) {
    return null;
  }

  return (
    <Modal
      size="sm"
      onHide={hideMethod}
      className="choose-modal"
      show={self?.visible}
      title={
        <div>
          <h3>{self?.value}</h3>
          <div>{title?.value}</div>
        </div>
      }
    >
      <div
        className={cx("d-flex p-2", {
          "two-buttons": buttonsCount === 2,
          "flex-column multi-buttons": buttonsCount > 2,
        })}
      >
        {buttons.map((button, idx) => (
          <Button
            key={idx}
            onClick={(e) => button.handler(e, button)}
            className={cx({
              "mr-1": buttonsCount === 2 && idx === 0,
              "ml-2": buttonsCount === 2 && idx === 1,
              "mb-2": buttonsCount > 2 && idx + 1 < buttonsCount,
              "bg-gray": button.color === "gray",
            })}
          >
            {button.value}
          </Button>
        ))}
      </div>
    </Modal>
  );
}
