import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Button as RBButton, ButtonProps as RBButtonProps } from "react-bootstrap";

import { Theme } from "../../dto/AppDTO";

const useStyles = makeStyles((theme: Theme) => ({
  secondaryRoot: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,

    "&:hover": {
      color: theme.palette.common.black,
      borderColor: theme.palette.grey["200"],
      backgroundColor: theme.palette.grey["100"],
    },
  },
  dangerRoot: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,

    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.hover,
    },
  },
  fluidRoot: { flex: 1 },
  linkRoot: {
    "&:active": {
      textDecoration: "underline !important",
      backgroundColor: "transparent !important",
      border: "1px solid transparent !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

export enum ButtonType {
  Link = "link",
  Danger = "danger",
  Primary = "primary",
  Secondary = "secondary",
}

interface ButtonProps extends RBButtonProps {
  readonly fluid?: boolean;
  readonly buttonType?: ButtonType;
}

export function Button({ buttonType = ButtonType.Primary, fluid, ...props }: ButtonProps) {
  const classes = useStyles();

  const isLinkType = buttonType === ButtonType.Link;
  const isDangerType = buttonType === ButtonType.Danger;
  const isSecondaryType = buttonType === ButtonType.Secondary;

  return (
    <RBButton
      {...props}
      className={cx(
        {
          "btn-link": isLinkType,
          [classes.fluidRoot]: fluid,
          [classes.linkRoot]: isLinkType,
          [classes.dangerRoot]: isDangerType,
          [classes.secondaryRoot]: isSecondaryType,
        },
        props.className,
      )}
    />
  );
}
