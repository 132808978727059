import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSidePrescriptionActionTypes";

import { defaultStackItem } from "../constants/chart-sidebar/prescriptionStackCodes";

export const defaultState = {
  stack: [defaultStackItem],
  fetching: false,
  list: [],
  listTotalCount: 0,
  templateList: [],
  templateListTotalCount: 0,
  sortField: "created_date_desc",
  searchValue: "",
  searchValueTemplates: "",
  prescriptions: [],
  formData: {},
  medicationLists: {},
  selectedMedication: {},
  diagnosisList: [],
  primaryDiagnosis: "",
  secondaryDiagnosis: {},
  searchValueDiagnosis: "",
  diagnosisListTotalCount: 0,
  fetchingGroups: false,
  templatesGroups: [],
  templatesGroupsCount: 0,
  fetchingGroupTemplates: false,
  templatesGroupTemplates: [],
  templatesGroupTemplatesCount: 0,
};

const reducers = {
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_START](state) {
    return {
      ...state,
      fetchingGroupTemplates: true,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_SUCCESS](state, { data = [], request }) {
    return {
      ...state,
      fetchingGroupTemplates: false,
      templatesGroupTemplatesCount: data.totalCount,
      templatesGroupTemplates: request.start
        ? state.templatesGroupTemplates.concat(data.list)
        : data.list,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_TEMPLATES_LIST_ERROR](state) {
    return {
      ...state,
      fetchingGroupTemplates: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_LIST_START](state) {
    return {
      ...state,
      fetchingGroups: true,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_LIST_SUCCESS](state, { data = [], request }) {
    return {
      ...state,
      fetchingGroups: false,
      templatesGroupsCount: data.totalCount,
      templatesGroups: request.start ? state.templatesGroups.concat(data.list) : data.list,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_GROUP_LIST_ERROR](state) {
    return {
      ...state,
      fetchingGroups: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_INIT](state) {
    return {
      ...state,
      stack: [defaultStackItem],
      prescriptions: [],
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_PUSH_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_POP_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_TEMPLATES_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        fetching: false,
        list: state.list.concat(data.list),
        listTotalCount: data.totalCount,
      };
    }

    if (request.filterKey !== state.searchValue) return state;
    return {
      ...state,
      fetching: false,
      list: data.list || [],
      listTotalCount: data.totalCount,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_TEMPLATES_SUCCESS](state, { data, request }) {
    if (request.filterKey !== state.searchValueTemplates) return state;

    return {
      ...state,
      fetching: false,
      templateList: data || [],
      templateListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_TEMPLATES_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SELECT_SORT_FIELD](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SEARCH_TEMPLATES_CHANGE](state, { value }) {
    return {
      ...state,
      searchValueTemplates: value,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_CATEGORY_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      categoryList: data,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_ADD_PRESCRIPTION](state, { prescription }) {
    return {
      ...state,
      prescriptions: state.prescriptions.concat(prescription),
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      formData: data,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_MEDICATION_BY_CATEGORY_SUCCESS](state, { data, request }) {
    return {
      ...state,
      medicationLists: {
        ...state.medicationLists,
        [request.categoryId]: data,
      },
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_CLEAR_PRESCRIPTION](state) {
    return {
      ...state,
      prescriptions: [],
      primaryDiagnosis: "",
      secondaryDiagnosis: {},
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SYNC_FORM](state, { prescriptions }) {
    return {
      ...state,
      prescriptions,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_DELETE](state, { index }) {
    const prescriptions = state.prescriptions.slice();

    prescriptions.splice(index, 1);

    return {
      ...state,
      prescriptions,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_START](state) {
    return {
      ...state,
      fetchingDiagnosis: true,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        diagnosisList: state.diagnosisList.concat(data.list),
        diagnosisListTotalCount: data.totalCount,
        fetchingDiagnosis: false,
      };
    }

    if (request.filterKey !== state.searchValueDiagnosis) return state;
    return {
      ...state,
      diagnosisList: data.list || [],
      diagnosisListTotalCount: data.totalCount,
      fetchingDiagnosis: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_GET_DIAGNOSIS_LIST_ERROR](state) {
    return {
      ...state,
      fetchingDiagnosis: false,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_DIAGNOSIS_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValueDiagnosis: value,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SELECT_DIAGNOSIS](state, { id }) {
    return {
      ...state,
      primaryDiagnosis: id,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_TOGGLE_DIAGNOSIS](state, { id }) {
    const secondaryDiagnosis = JSON.parse(JSON.stringify(state.secondaryDiagnosis));

    // eslint-disable-next-line no-prototype-builtins
    if (secondaryDiagnosis.hasOwnProperty(id)) {
      delete secondaryDiagnosis[id];
    } else {
      secondaryDiagnosis[id] = true;
    }

    return {
      ...state,
      secondaryDiagnosis,
    };
  },
  [ActionTypes.SIDE_PRESCRIPTION_SET_PRIMARY](state, { id }) {
    return {
      ...state,
      primaryDiagnosis: id,
    };
  },
  [ActionTypes.SELECT_MEDICATION](state, { selectedMedication }) {
    return {
      ...state,
      selectedMedication,
    };
  },
};

export default createReducer(defaultState, reducers);
