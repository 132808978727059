import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";
import MemberImage from "../widgets/MemberImage";

import ChartSideSearch from "../chart/side-bar/ChartSideSearch";
import PatientFormsDocFieldsForm from "./PatientFormsDocFieldsForm";

import Utils from "../../helpers/Utils";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientFormsDocFieldsModal extends React.Component {
  openEditMode = (type) => {
    return () => {
      this.props.patientRegisterActions.docFieldsOpenEditMode(type);
    };
  };

  selectMember = (id) => {
    return () => {
      this.props.patientRegisterActions.docFieldsSelectMember(id);
    };
  };

  saveMember = () => {
    if (this.props.patientRegister.docFieldsSelectedMember) {
      this.props.patientRegisterActions.docFieldsCloseEditMode(
        this.props.patientRegister.docFieldsMembers[
          this.props.patientRegister.docFieldsSelectedMember
        ],
      );
    } else {
      this.props.patientRegisterActions.docFieldsCloseEditMode();
    }
  };

  onSearch = (event) => {
    this.props.patientRegisterActions.docFieldsSetSearchValue(event.target.value);
  };

  changeTextArea = (type) => {
    return (event) => {
      this.props.patientRegisterActions.docFieldsChangeTextArea(type, event.target.value);
    };
  };

  changeImage = (type) => {
    return (value) => {
      this.props.patientRegisterActions.docFieldsChangeTextArea(type, value);
    };
  };

  render() {
    const { patientRegister, patientRegisterActions, doctorSign } = this.props;

    if (!patientRegister.docFieldsModalVisible) {
      return null;
    }

    let members =
      _.filter(patientRegister.docFieldsMembers, (item) => {
        return (
          item.firstName.toLowerCase().includes(patientRegister.memberListSearch.toLowerCase()) ||
          item.lastName.toLowerCase().includes(patientRegister.memberListSearch.toLowerCase())
        );
      }) || [];

    if (patientRegister.docFieldsEditType === "dentist") {
      members = members.filter(
        (x) =>
          _.get(x, "subType.code") === "DOCTOR" &&
          (_.get(x, "status.name") === "Activated" || _.get(x, "status.name") === "Idle"),
      );
    }

    if (patientRegister.docFieldsEditType === "nurse") {
      members = members.filter(
        (x) =>
          _.get(x, "subType.code") === "NURSE" &&
          (_.get(x, "status.name") === "Activated" || _.get(x, "status.name") === "Idle"),
      );
    }

    let $members = _.map(members, (item, index) => {
      let $arrow = null;

      if (patientRegister.docFieldsSelectedMember === item.id) {
        $arrow = <div className="arrow">&#10004;</div>;
      }

      return (
        <button
          type="button"
          key={index}
          onClick={this.selectMember(item.id)}
          className="list-group-item"
        >
          <MemberImage size={40} photoUrl={item.photoUrl} />
          <span className="text">{Utils.getFullName(item)}</span>
          {$arrow}
        </button>
      );
    });

    return (
      <Modal
        onHide={patientRegisterActions.docFieldsCloseEditMode}
        show={true}
        animation={false}
        keyboard={false}
        dialogClassName="chart-side-bar"
      >
        <div className="chart-series">
          <div className="chart-side-header">
            <Show if={!patientRegister.docFieldsEditMode}>
              <div className="clearfix">
                <button
                  type="button"
                  className="btn btn-link pull-right"
                  onClick={patientRegisterActions.closeDocFieldsModal}
                >
                  DONE
                </button>
              </div>
            </Show>
            <Show if={patientRegister.docFieldsEditMode}>
              <div className="clearfix">
                <button type="button" className="btn btn-link pull-right" onClick={this.saveMember}>
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn btn-link pull-left"
                  onClick={() => patientRegisterActions.docFieldsCloseEditMode()}
                >
                  ❮ BACK
                </button>
              </div>
            </Show>
          </div>
          <div className="chart-side-body">
            <Show if={!patientRegister.docFieldsEditMode}>
              <div className="doc-fields-body">
                <div className="list-group">
                  <Show if={patientRegister.formsItem.hasDentistSignatureField && !doctorSign}>
                    <button onClick={this.openEditMode("dentist")} className="list-group-item">
                      <span className="pull-right">
                        {Utils.getFullName(patientRegister["dentist"]) ||
                          (patientRegister["dentist"] && patientRegister["dentist"].name)}
                      </span>
                      {localStorage.getItem("doctorLabel")}
                    </button>
                  </Show>

                  <Show if={false && patientRegister.formsItem.hasGuardianSignatureField}>
                    <button onClick={this.openEditMode("guardian")} className="list-group-item">
                      <span className="pull-right">
                        {Utils.getFullName(patientRegister["guardian"]) ||
                          (patientRegister["guardian"] && patientRegister["guardian"].name)}
                      </span>
                      Guardian
                    </button>
                  </Show>

                  <Show if={patientRegister.formsItem.hasHygienistSignatureField}>
                    <button onClick={this.openEditMode("hygienist")} className="list-group-item">
                      <span className="pull-right">
                        {Utils.getFullName(patientRegister["hygienist"]) ||
                          (patientRegister["hygienist"] && patientRegister["hygienist"].name)}
                      </span>
                      Hygienist
                    </button>
                  </Show>

                  <Show if={patientRegister.formsItem.hasNurseSignatureField}>
                    <button onClick={this.openEditMode("nurse")} className="list-group-item">
                      <span className="pull-right">
                        {Utils.getFullName(patientRegister["nurse"]) ||
                          (patientRegister["nurse"] && patientRegister["nurse"].name)}
                      </span>
                      {localStorage.getItem("nurseLabel")}
                    </button>
                  </Show>
                </div>

                <PatientFormsDocFieldsForm
                  ref1={this.props.ref1}
                  ref2={this.props.ref2}
                  ref3={this.props.ref3}
                  ref4={this.props.ref4}
                  ref5={this.props.ref5}
                  ref6={this.props.ref6}
                  ref7={this.props.ref7}
                  ref8={this.props.ref8}
                  ref9={this.props.ref9}
                  ref10={this.props.ref10}
                  ref11={this.props.ref11}
                  ref12={this.props.ref12}
                  ref13={this.props.ref13}
                  ref14={this.props.ref14}
                  ref15={this.props.ref15}
                  ref16={this.props.ref16}
                  ref17={this.props.ref17}
                  ref18={this.props.ref18}
                  ref19={this.props.ref19}
                  ref20={this.props.ref20}
                  patientRegister={patientRegister}
                  changeTextArea={this.changeTextArea}
                  changeImage={this.changeImage}
                />
              </div>
            </Show>
            <Show if={patientRegister.docFieldsEditMode}>
              <div>
                <ChartSideSearch
                  value={patientRegister.memberListSearch}
                  onChange={this.onSearch}
                />
                <div className="list-group doc-fields-modal-list">{$members}</div>
              </div>
            </Show>
          </div>
        </div>
      </Modal>
    );
  }
}
export default enhancer(PatientFormsDocFieldsModal);
