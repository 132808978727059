import React from "react";
import { DatePicker } from "../ui/DatePicker";
import { useFormik } from "formik";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getRequestedAppointments,
  changeBookedAppointmentHandler,
} from "../../actions/waitingListActions";

function RequestedAppointmentsListForm() {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      startDate: moment(),
      endDate: moment(),
    },
    onSubmit: (values) => {
      dispatch(changeBookedAppointmentHandler(values));
      dispatch(getRequestedAppointments(values));
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form-inline d-flex align-items-center justify-content-end"
    >
      <div className="form-group">
        <label className="d-flex m-0">
          From:
          <DatePicker
            className="ml-3"
            onChange={(x) => formik.setFieldValue("startDate", x)}
            selected={formik.values.startDate}
          />
        </label>
      </div>
      <div className="form-group">
        <label className="d-flex m-0">
          To:
          <DatePicker
            className="ml-3"
            onChange={(x) => formik.setFieldValue("endDate", x)}
            selected={formik.values.endDate}
          />
        </label>
      </div>
      <button type="submit" className="btn btn-default">
        Search
      </button>
    </form>
  );
}

export default RequestedAppointmentsListForm;
