import moment from "moment";
import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/followUpActionTypes";

const defaultState = {
  list: [],
  totalCount: 0,
  fetching: false,
  fromDate: moment(),
  toDate: moment(),
  chartNumber: "",
  typeList: [],
  doctors: [],
  typeIds: [],
  doctorsIds: [],
  showComplete: false,
  showResponded: false,
  showEditForm: false,
  item: {},
  patientKey: null,
  doctorId: null,
  repeatList: [],
  activeTab: "query",
  clinicId: null,
  followUpNotificationCount: 0,
};

const reducers = {
  [ActionType.FOLLOW_UP_REPEAT_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      repeatList: data.list || [],
    };
  },
  [ActionType.FOLLOW_UP_SET_DATA](state, { data }) {
    return {
      ...state,
      patientKey: data.patientKey,
      doctorId: data.doctorId,
    };
  },
  [ActionType.FOLLOW_UP_INIT_DOCTORS](state, { doctors }) {
    return {
      ...state,
      doctors,
    };
  },
  [ActionType.FOLLOW_UP_SELECT_DOCTORS](state, { ids }) {
    return {
      ...state,
      doctorsIds: ids,
    };
  },
  [ActionType.FOLLOW_UP_SELECT_CLINIC](state, { id }) {
    return {
      ...state,
      clinicId: id,
    };
  },
  [ActionType.FOLLOW_UP_GET_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionType.FOLLOW_UP_GET_LIST_SUCCESS](state, { request, data }) {
    if (request.start) {
      return {
        ...state,
        list: state.list.concat(data.list || []),
        fetching: false,
        totalCount: data.totalCount,
      };
    }
    return {
      ...state,
      list: data.list || [],
      fetching: false,
      totalCount: data.totalCount,
    };
  },
  [ActionType.FOLLOW_UP_GET_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionType.FOLLOW_UP_GET_COUNT_SUCCESS](state, { data }) {
    return {
      ...state,
      followUpNotificationCount: data || 0,
    };
  },
  [ActionType.FOLLOW_UP_SET_FROM_DATE](state, fromDate) {
    return {
      ...state,
      fromDate: fromDate.date,
    };
  },
  [ActionType.FOLLOW_UP_SET_TO_DATE](state, toDate) {
    return {
      ...state,
      toDate: toDate.date,
    };
  },
  [ActionType.FOLLOW_UP_SET_CHART_NUMBER](state, { chartNumber }) {
    return {
      ...state,
      chartNumber,
    };
  },
  [ActionType.FOLLOW_UP_SET_TYPE_FILTER](state, { ids }) {
    return {
      ...state,
      typeIds: ids,
    };
  },
  [ActionType.FOLLOW_UP_GET_TYPE_LIST_START](state) {
    return {
      ...state,
      typeList: [],
    };
  },
  [ActionType.FOLLOW_UP_GET_TYPE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      typeList: data,
    };
  },
  [ActionType.FOLLOW_UP_TOGGLE_COMPLETE](state) {
    return {
      ...state,
      showComplete: !state.showComplete,
    };
  },
  [ActionType.FOLLOW_UP_TOGGLE_RESPONDED](state, { showResponded }) {
    return {
      ...state,
      showResponded,
    };
  },
  [ActionType.FOLLOW_UP_TOGGLE_EDIT_FORM](state, { item }) {
    return {
      ...state,
      showEditForm: !state.showEditForm,
      item,
    };
  },
  [ActionType.FOLLOW_UP_CLOSE_EDIT_FORM](state) {
    return {
      ...state,
      showEditForm: false,
    };
  },
  [ActionType.FOLLOW_UP_SET_ACTIVE_TAB](state, { activeTab }) {
    return {
      ...state,
      activeTab,
    };
  },
};

export default createReducer(defaultState, reducers);
