import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";
import ChartPostOpOwnedItem from "./ChartPostOpOwnedItem";

import PostOpSortFields from "../../../../constants/chart-sidebar/postOpSortFields";
import postOpItemTypes from "../../../../constants/chart-sidebar/postOpItemTypes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePostOpActions from "../../../../actions/chartSidePostOpActions";
import { parseQuery } from "../../../../utils/UrlUtils";

const enhancer = connect(
  ({ session }) => ({
    hasRemove:
      _.get(session, "permissions", []).indexOf(
        "REMOVE_COMPANY_PATIENT_POST_OPERATION_INSTRUCTION_ITEM",
      ) >= 0,
  }),
  bindActions({ chartSidePostOpActions }),
);

class ChartPostOpOwned extends React.Component {
  componentDidMount() {
    this.props.chartSidePostOpActions.getOwnedList();
  }

  openItem = (item) => {
    return () => {
      this.props.chartSidePostOpActions.openItem(item, postOpItemTypes.view);
    };
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.chartSidePostOpActions.loadOwnedList();
    }
  };

  select = (event) => {
    this.props.chartSidePostOpActions.selectSortField(event.target.value);
  };

  render() {
    const { hasRemove, chartSidePostOp, chartSidePostOpActions, location } = this.props;
    const { ownedList, fetching, sortField } = chartSidePostOp;
    const query = parseQuery(location?.search);

    let $list = ownedList.map((item, index) => (
      <ChartPostOpOwnedItem
        hasRemove={hasRemove}
        removeItem={chartSidePostOpActions.deletePostOp}
        key={index}
        item={item}
        openItem={this.openItem}
      />
    ));

    const $options = PostOpSortFields.map((item, index) => (
      <option key={index} value={item.code}>
        {item.name}
      </option>
    ));

    return (
      <div className="chart-clinical-note">
        <div className="sidebar-header chart-side-header">
          <div className="clearfix">
            <div className="col-sm-4">
              <BackButton
                className="btn-sm"
                text="Back"
                onClick={this.props.chartSidePostOpActions.popStack}
              />
            </div>
            <div className="col-sm-8">
              <div className="chart-clinical-note-title">Post Operationsss</div>
            </div>
          </div>
        </div>
        <div className="chart-side-body">
          <div className="chart-clinical-note-select">
            <select onChange={this.select} value={sortField} className="form-control">
              {$options}
            </select>
          </div>
          <div className="body">
            <div className="chart-clinical-note-wrapper" onScroll={this.loadList}>
              {$list}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
          <Show if={query?.medicalType !== "view"}>
            <div className="all-codes">
              <button
                onClick={this.props.chartSidePostOpActions.openList}
                className="btn btn-primary btn-block"
              >
                Add
              </button>
            </div>
          </Show>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOpOwned);
