import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import $ from "jquery";

import Back from "../widgets/Back";
import Show from "../widgets/Show";
import PatientFormSignature from "./PatientFormSignature";
import PatientDetailsSignature from "./PatientDetailsSignature";
import PatientFormsDocFieldsModal from "./PatientFormsDocFieldsModal";

import Utils from "../../helpers/Utils";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { parseQuery } from "../../utils/UrlUtils";
import BackGroundImage from "../../assets/images/clinic_bg_image.jpg";
import LogoImage from "../../assets/images/clinic_logo.png";
import { withRouter } from "react-router";
import moment from "moment";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

const enhancer = connect(
  ({ patientRegister, auth: { members, chosenMemberId } }) => ({
    patientRegister,
    member: members[chosenMemberId],
  }),
  bindActions({ patientRegisterActions }),
);
class PatientFormsEdit extends React.Component {
  componentDidMount() {
    this.props.patientRegisterActions.getPatientFormsEdit(parseQuery(this.props.location.search));
    if (parseQuery(this.props.location.search).doctorSign) {
      this.props.patientRegisterActions.docFieldsOpenEditMode("dentist");
      this.props.patientRegisterActions.docFieldsCloseEditMode(this.props.member);
    }
  }

  submit = () => {
    this.props.patientRegisterActions
      .submitPatientFormsEdit(parseQuery(this.props.location.search))
      .then((response) => {
        if (response) {
          this.props.history.goBack();
        }
      });
  };

  save = () => {
    this.props.patientRegisterActions
      .savePatientFormsEdit(parseQuery(this.props.location.search))
      .then((response) => {
        if (response) {
          this.props.history.goBack();
        }
      });
  };

  onHTMLClick = (e) => {
    const $el = $(e.target);
    if ($el.is("span.open-side-bar")) {
      this.props.patientRegisterActions.openDocFieldsModal();
      setTimeout(() => {
        const textarea = this["element" + $el.data("index")];
        if (textarea) textarea.focus();
      }, 100);
    }
  };

  render() {
    const {
      formsItem,
      fullForm,
      signatureModalVisible,
      signatureGuardianModalVisible,
      signature,
      dentist,
      guardian,
      hygienist,
      nurse,
      textArea1,
      textArea2,
      textArea3,
      textArea4,
      textArea5,
      textArea6,
      textArea7,
      textArea8,
      textArea9,
      textArea10,
      textArea11,
      textArea12,
      textArea13,
      textArea14,
      textArea15,
      textArea16,
      textArea17,
      textArea18,
      textArea19,
      textArea20,
      image1,
    } = this.props.patientRegister;

    const doctorSigned = dentist && dentist.code;
    const guardianSigned = guardian && guardian.code;
    const hygienistSigned = hygienist && hygienist.code;
    const nurseSigned = nurse && nurse.code;

    let html = fullForm.signContent || "";

    html = Utils.replaceAll(html, "${doctor_first_name}", _.get(dentist, "firstName", "") || "");
    html = Utils.replaceAll(html, "${doctor_second_name}", _.get(dentist, "lastName", "") || "");
    html = Utils.replaceAll(
      html,
      "${guardian_name}",
      (!_.isEmpty(guardian) && guardian.firstName + " " + guardian.lastName) || "",
    );
    html = Utils.replaceAll(
      html,
      "${hygienist_name_field}",
      (!_.isEmpty(hygienist) && hygienist.firstName + " " + hygienist.lastName) || "",
    );
    html = Utils.replaceAll(
      html,
      "${nurse_name_field}",
      (!_.isEmpty(nurse) && nurse.firstName + " " + nurse.lastName) || "",
    );

    html = Utils.replaceAll(
      html,
      "${text_area_1}",
      "<span class='open-side-bar' data-index='1'>" +
        Utils.replaceAll(textArea1 || "${text_area_1}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_2}",
      "<span class='open-side-bar' data-index='2'>" +
        Utils.replaceAll(textArea2 || "${text_area_2}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_3}",
      "<span class='open-side-bar' data-index='3'>" +
        Utils.replaceAll(textArea3 || "${text_area_3}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_4}",
      "<span class='open-side-bar' data-index='4'>" +
        Utils.replaceAll(textArea4 || "${text_area_4}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_5}",
      "<span class='open-side-bar' data-index='5'>" +
        Utils.replaceAll(textArea5 || "${text_area_5}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_6}",
      "<span class='open-side-bar' data-index='6'>" +
        Utils.replaceAll(textArea6 || "${text_area_6}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_7}",
      "<span class='open-side-bar' data-index='7'>" +
        Utils.replaceAll(textArea7 || "${text_area_7}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_8}",
      "<span class='open-side-bar' data-index='8'>" +
        Utils.replaceAll(textArea8 || "${text_area_8}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_9}",
      "<span class='open-side-bar' data-index='9'>" +
        Utils.replaceAll(textArea9 || "${text_area_9}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_10}",
      "<span class='open-side-bar' data-index='10'>" +
        Utils.replaceAll(textArea10 || "${text_area_10}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_11}",
      "<span class='open-side-bar' data-index='11'>" +
        Utils.replaceAll(textArea11 || "${text_area_11}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_12}",
      "<span class='open-side-bar' data-index='12'>" +
        Utils.replaceAll(textArea12 || "${text_area_12}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_13}",
      "<span class='open-side-bar' data-index='13'>" +
        Utils.replaceAll(textArea13 || "${text_area_13}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_14}",
      "<span class='open-side-bar' data-index='14'>" +
        Utils.replaceAll(textArea14 || "${text_area_14}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_15}",
      "<span class='open-side-bar' data-index='15'>" +
        Utils.replaceAll(textArea15 || "${text_area_15}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_16}",
      "<span class='open-side-bar' data-index='16'>" +
        Utils.replaceAll(textArea16 || "${text_area_16}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_17}",
      "<span class='open-side-bar' data-index='17'>" +
        Utils.replaceAll(textArea17 || "${text_area_17}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_18}",
      "<span class='open-side-bar' data-index='18'>" +
        Utils.replaceAll(textArea18 || "${text_area_18}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_19}",
      "<span class='open-side-bar' data-index='19'>" +
        Utils.replaceAll(textArea19 || "${text_area_19}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${text_area_20}",
      "<span class='open-side-bar' data-index='20'>" +
        Utils.replaceAll(textArea20 || "${text_area_20}", "\n", "<br/>") +
        "</span>",
    );
    html = Utils.replaceAll(
      html,
      "${image_1}",
      `<img style='max-width:100%;' src='data:image/jpg;base64,${image1}'/>`,
    );

    return (
      <div
        className="patient-appointments-list patient-consent-form-edit"
        style={{ backgroundImage: `url(${BackGroundImage})` }}
      >
        <div className="default-page-title clearfix text-center">
          <button
            className="btn btn-link pull-right"
            type="button"
            onClick={this.props.patientRegisterActions.openDocFieldsModal}
          >
            DOC. FIELDS
          </button>
          <Back className="pull-left" />
          <img src={LogoImage} alt="" className="header-logo" />
        </div>
        <div style={{ overflowY: "auto" }}>
          <div>
            <div
              className="patient-consent-form-edit-body"
              onClick={this.onHTMLClick}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          <div className="line-title">
            <span className="text">Patient Signature</span>
          </div>
          <PatientFormSignature editMode={true} signature={signature} />

          <Show if={formsItem.hasDentistSignatureField}>
            <div>
              <div className="line-title">
                <span className="text">{localStorage.getItem("doctorLabel")} Signature</span>
              </div>
              <div className="patient-details-edit-signature">
                {!doctorSigned ? "Please, choose " + localStorage.getItem("doctorLabel") : "Signed"}
              </div>
            </div>
          </Show>

          <Show if={formsItem.hasGuardianSignatureField}>
            <div>
              <div className="line-title">
                <span className="text">Guardian Signature</span>
              </div>
              <PatientFormSignature
                onClick={this.props.patientRegisterActions.guardianSignModalShow}
                editMode={true}
                signature={guardianSigned}
              />
            </div>
          </Show>

          <Show if={formsItem.hasHygienistSignatureField}>
            <div>
              <div className="line-title">
                <span className="text">Hygienist Signature</span>
              </div>
              <div className="patient-details-edit-signature">
                {!hygienistSigned ? "Please, choose Hygienist" : "Signed"}
              </div>
            </div>
          </Show>

          <Show if={formsItem.hasNurseSignatureField}>
            <div>
              <div className="line-title">
                <span className="text">{localStorage.getItem("nurseLabel")} Signature</span>
              </div>
              <div className="patient-details-edit-signature">
                {!nurseSigned ? "Please, choose " + localStorage.getItem("nurseLabel") : "Signed"}
              </div>
            </div>
          </Show>

          <div className="text-center">
            <button
              style={{
                position: "relative",
                width: "300px",
                borderRadius: 0,
              }}
              className="btn btn-primary btn-lg"
              type="button"
              onClick={this.submit}
            >
              save & finish
            </button>
            <button
              style={{
                position: "relative",
                width: "300px",
                borderRadius: 0,
                marginLeft: "20px",
                backgroundColor: "#ff0000",
                borderColor: "#ff0000",
              }}
              className="btn btn-primary btn-lg"
              type="button"
              onClick={this.save}
            >
              save for later
            </button>
          </div>

          <div className="text-center">
            <a
              style={{
                position: "relative",
                color: "#fff",
                textDecoration: "underline",
                lineHeight: "48px",
              }}
              href="#"
            >
              I disagree with this term
            </a>
          </div>

          <Show if={signatureModalVisible}>
            <PatientDetailsSignature patientRegister={this.props.patientRegister} />
          </Show>

          <Show if={signatureGuardianModalVisible}>
            <PatientDetailsSignature
              show={signatureGuardianModalVisible}
              patientRegister={{ signature: guardianSigned }}
              addSignature={this.props.patientRegisterActions.guardianAddSignature}
              closeSignature={this.props.patientRegisterActions.guardianSignModalHide}
            />
          </Show>

          <PatientFormsDocFieldsModal
            ref1={(element) => {
              if (element) this.element1 = element;
            }}
            ref2={(element) => {
              if (element) this.element2 = element;
            }}
            ref3={(element) => {
              if (element) this.element3 = element;
            }}
            ref4={(element) => {
              if (element) this.element4 = element;
            }}
            ref5={(element) => {
              if (element) this.element5 = element;
            }}
            ref6={(element) => {
              if (element) this.element6 = element;
            }}
            ref7={(element) => {
              if (element) this.element7 = element;
            }}
            ref8={(element) => {
              if (element) this.element8 = element;
            }}
            ref9={(element) => {
              if (element) this.element9 = element;
            }}
            ref10={(element) => {
              if (element) this.element10 = element;
            }}
            ref11={(element) => {
              if (element) this.element11 = element;
            }}
            ref12={(element) => {
              if (element) this.element12 = element;
            }}
            ref13={(element) => {
              if (element) this.element13 = element;
            }}
            ref14={(element) => {
              if (element) this.element14 = element;
            }}
            ref15={(element) => {
              if (element) this.element15 = element;
            }}
            ref16={(element) => {
              if (element) this.element16 = element;
            }}
            ref17={(element) => {
              if (element) this.element17 = element;
            }}
            ref18={(element) => {
              if (element) this.element18 = element;
            }}
            ref19={(element) => {
              if (element) this.element19 = element;
            }}
            ref20={(element) => {
              if (element) this.element20 = element;
            }}
            doctorSign={parseQuery(this.props.location.search).doctorSign}
            patientRegister={this.props.patientRegister}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(enhancer(PatientFormsEdit));
