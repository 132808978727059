import React from "react";
import cx from "classnames";
import { isArray } from "lodash";
import { makeStyles } from "@material-ui/styles";
import ReactSelect, { Props as ReactSelectProps } from "react-select";

import { Theme } from "../../dto/AppDTO";
import { hexToRgba } from "../../utils/FormatUtils";
import { SelectPickerOption } from "./SelectPickerOption";
import { SelectPickerMultiValue } from "./SelectPickerMultiValue";
import { SelectPickerSingleValue } from "./SelectPickerSingleValue";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "34px",
    color: "black",

    "&.control-lg": {
      height: "48px",

      "& .select-picker__control": {
        minHeight: "48px",

        "& > .select-picker__value-container, & > .select-picker__indicators": {
          height: "46px",
          borderRadius: "6px",
        },
      },
    },

    "& .select-picker__menu": {
      width: "auto",
      minWidth: "100%",
    },

    "& .select-picker__control": {
      cursor: "pointer",
      minHeight: "34px",

      "& > .select-picker__value-container, & > .select-picker__indicators": {
        height: "32px",
      },

      "&.select-picker__control--is-focused": {
        borderColor: `${theme.palette.primary.main} !important`,
        boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${hexToRgba(
          theme.palette.primary.main,
          {
            opacity: 0.6,
          },
        )}`,
      },
    },
  },
}));

export interface SelectPickerProps extends Omit<ReactSelectProps, "onChange"> {
  readonly size?: "lg";
  readonly formatLabel?: (x: any) => any;
  readonly formatMultiValue?: (x: any) => any;
  readonly formatOptionLabel?: (value: any) => string;
  readonly onChange?: (value: any, current?: any) => void;
}

export function SelectPicker({
  size,
  isMulti,
  isClearable = false,
  isSearchable = false,
  formatMultiValue,
  formatOptionLabel,
  closeMenuOnSelect = false,

  ...props
}: SelectPickerProps) {
  const classes = useStyles();

  return (
    <ReactSelect
      valueKey="value"
      {...props}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      hideSelectedOptions={false}
      classNamePrefix="select-picker"
      closeMenuOnSelect={closeMenuOnSelect || !isMulti}
      blurInputOnSelect={closeMenuOnSelect || !isMulti}
      className={cx(classes.root, { "control-lg": size === "lg" }, props.className)}
      onChange={(value, meta) => {
        if (meta.action === "clear" && props.onChange) {
          props.onChange([], meta);
        }
        // @ts-ignore
        else if (isMulti && (!meta.option || !meta.option.value) && props.onChange) {
          props.onChange(props.options?.slice(0, 1) as any, meta);
        } else if (isMulti && isArray(value) && value.length > 1 && props.onChange) {
          props.onChange(value.filter((x) => Boolean(x.value)) as any, meta);
        } else if (props.onChange) {
          // @ts-ignore
          props.onChange(value, meta.option);
        }
      }}
      components={{
        Option: (x) => <SelectPickerOption {...x} formatOptionLabel={formatOptionLabel} />,
        IndicatorSeparator: () => null,
        SingleValue: (x) => <SelectPickerSingleValue {...x} formatLabel={props.formatLabel} />,
        MultiValue: (x) => (
          <SelectPickerMultiValue {...x} value={props.value} formatMultiValue={formatMultiValue} />
        ),
      }}
    />
  );
}
