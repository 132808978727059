import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IconButton } from "@mui/material";

import Show from "../widgets/Show";
import Utils from "../../helpers/Utils";
import MemberImage from "../widgets/MemberImage";
import PatientViewModal from "./PatientViewModal";
import bindActions from "../../helpers/bindActions";
import { PERMISSIONS } from "../../constants/Constants";
import PatientRemindersModal from "./PatientRemindersModal";
import PatientViewEditNoteModal from "./PatientViewEditNoteModal";
import PatientViewClinicianModal from "./PatientViewClinicianModal";
import FlexBox, { FlexBoxFlex } from "../ui/FlexBox";
import * as patientViewActions from "../../actions/patientViewActions";
import { consultationNotesHide, consultationNotesShow } from "../../actions/appointmentActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import patientViewModalTypes from "../../constants/patient/patientViewModalTypes";
import PatientPrimaryClinicianSidebar from "./clinician/PatientPrimaryClinicianSidebar";
import PatientSelectAppointmentSidebar from "./plan-visit/PatientSelectAppointmentSidebar";
import PatientPlanVisitChooseActionModal from "./plan-visit/PatientPlanVisitChooseActionModal";
import ConsultationNotesSidebar from "../appointment-form/consultation-notes/ConsultationNotesSidebar";
import { getCompanyFormatDate, getCompanyFormatTime } from "../../helpers/DateUtils";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import NotesImage from "../../assets/images/profile/notes.png";
import AccountAlertImage from "../../assets/images/profile/account-alert.png";
import AppointmentsImage from "../../assets/images/profile/appointments.png";
import FormsImage from "../../assets/images/profile/forms.png";
import ContactsImage from "../../assets/images/profile/contacts.png";
import AlertsImage from "../../assets/images/profile/alerts.png";
import ProfileImage from "../../assets/images/profile/profile.png";
import ActiveArrowImage from "../../assets/images/profile/approve.png";
import InactiveArrowImage from "../../assets/images/profile/x.png";
import addPhotoImage from "../../assets/images/profile/add_photo.png";
import { AppLayout } from "../app-layout/AppLayout";
import BackgroundImage from "../../assets/images/login_bg_image.jpg";
import DocsImage from "../../assets/images/calendar-bottom-menu/images.png";
import ConsentFormImage from "../../assets/images/appointment/consent_form.png";
import FilledImage from "../widgets/FilledImage";
import { DocsWrapper } from "../chart/side-bar/docs/DocsWrapper";
import PatientFamilyMembersSidebar from "./PatientFamilyMembersSidebar";
import FamilyMembersImage from "../../assets/images/dashboard/patients.png";
import { IS_ELECTRON } from "../../constants/AppConstants";
import XRayForm from "../chart/XRayForm";

const enhancer = connect(
  ({ patientView, patientRegister, session: { permissions, company }, appointment }) => {
    return { patientView, patientRegister, permissions, appointment, company };
  },
  bindActions({
    patientViewActions,
    patientRegisterActions,
    consultationNotesShow,
    openSimpleModal,
    closeSimpleModal,
    consultationNotesHide,
  }),
);
class PatientView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showClinician: false,
      showDocsModal: false,
      showFamilyMembersSidebar: false,
    };
  }

  componentDidMount() {
    const { match, location } = this.props;

    const query = parseQuery(location.search);

    if (query.companyTempPatientId) {
      this.props.patientViewActions.initPatientView({
        companyTempPatientId:
          query.companyTempPatientId || this.props.patientView?.companyTempPatientId,
      });
    } else {
      this.props.patientViewActions.initPatientView({
        patientKey: match.params.patientKey,
      });
    }
  }

  loadAppointments = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.patientRegisterActions.loadPatientAppointmentsList(
        this.props.match.params.patientKey,
      );
    }
  };

  openChart = () => {
    this.props.patientRegisterActions.patientChooseActionModalShow();
  };

  openPatientReminders = () => {
    this.props.patientViewActions.patientRemindersModalShow();
  };

  openProfile = () => {
    this.props.history.push(
      createUrl(Routes.PatientProfile, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openContacts = () => {
    this.props.history.push(
      createUrl(Routes.PatientContacts, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openAppointmetsList = () => {
    this.props.history.push(
      createUrl(Routes.PatientAppointmentsList, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openFormsList = () => {
    this.props.history.push(
      createUrl(Routes.PatientFormsList, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openPatientDetailsEdit = () => {
    const query = parseQuery(this.props.location.search);
    this.props.history.push(
      createUrl(Routes.PatientDetailsEdit, {
        query: { ...query, companyTempPatientId: this.props.patientView?.companyTempPatientId },
      }),
    );
  };

  openInsurance = () => {
    const { patientDetails } = this.props.patientView;

    this.props.history.push(
      createUrl(Routes.DashboardInsurance, {
        params: { patientKey: patientDetails.patientKey },
      }),
    );
  };

  openAlerts = () => {
    this.props.history.push(
      createUrl(Routes.PatientAlerts, {
        params: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  showModal = (modalType) => {
    return () => this.props.patientViewActions.showModal(modalType);
  };

  openPatientDetailsView = () => {
    return this.props.history.push(
      createUrl(Routes.PatientDetailsEdit, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openPatientMedicalAlertsView = () => {
    return this.props.history.push(
      createUrl(Routes.PatientMedicalAlertsEdit, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  openPatientMedicationAlertsView = () => {
    return this.props.history.push(
      createUrl(Routes.PatientMedicationAlertsEdit, {
        query: { patientKey: this.props.match.params.patientKey },
      }),
    );
  };

  navigateHandler = (type) => {
    this.props.history.push(
      createUrl(Routes.DashboardChart, {
        params: { type },
        query: { patientKey: this.props.patientView.patientKey },
      }),
    );
  };

  render() {
    const {
      patientDetails,
      medicalAlerts,
      patientAccessModules,
      notes,
      insuranceNotes,
      modalVisible,
      modalType,
      clinicians,
      activeNote,
      noteEditVisible,
      patientFamilyMembers,
      companyTempPatientId,
      companyPatientReminder: { receptionReminder, doctorReminder },
    } = this.props.patientView;

    const { showClinician } = this.state;

    const { permissions, company } = this.props;

    const { consultationNotesSidebarVisible, consultationNotesList } = this.props.appointment;

    const { appointmentsList } = this.props.patientRegister;

    function getAge(timestamp) {
      const dateOfBirthday = moment(timestamp);

      return [Utils.getAge(dateOfBirthday), getCompanyFormatDate(dateOfBirthday)]
        .filter(Boolean)
        .join(" ");
    }

    const $familyMembers = (patientFamilyMembers || []).map((familyMember, idx) => (
      <div className="patient-view-item" key={idx}>
        {_.get(familyMember, "fullName")}
      </div>
    ));

    const $consultation = _.take(consultationNotesList, 5).map((item) => (
      <div className="item" key={item.id}>
        {item.name}
      </div>
    ));

    const $alerts = (medicalAlerts.alerts || []).map((alert, index) => (
      <div key={index}>{alert.medicalName}</div>
    ));

    const $appointments = (appointmentsList || []).map((item, index) => (
      <div key={index} className="item">
        <div className="time">
          {getCompanyFormatDate(_.get(item, "date"))} {getCompanyFormatTime(_.get(item, "date"))}
        </div>
        <div className="point">{_.get(item, "dentalPoint.name", "")}</div>
      </div>
    ));

    const $notes = (notes || []).map((item, index) => (
      <div className="patient-view-item" key={index}>
        {item.name}
      </div>
    ));

    const $insuranceNotes = (insuranceNotes || []).map((item, index) => (
      <div className="patient-view-item" key={index}>
        {item.name}
      </div>
    ));

    const $clinicians = (clinicians || []).map((item, index) => (
      <div className="patient-view-item" key={index}>
        {item.name}
      </div>
    ));

    const query = parseQuery(this.props.location.search);

    const isTemporary = Boolean(query.companyTempPatientId || companyTempPatientId);

    const availableConsultationNotes = permissions.includes("VIEW_CONSULTATION_NOTE_ITEM");
    const detailsEditAvailable = permissions.includes("EDIT_PATIENT_DETAIL");
    const planningChartPermission =
      permissions &&
      permissions.indexOf(PERMISSIONS.ADD_PLANNED_DENTAL_TRANSACTION_ITEM_WITHOUT_APPOINTMENT) >= 0;

    return (
      <AppLayout withLogo={true} contentClassName="patient-view" backgroundImage={BackgroundImage}>
        {/*{IS_ELECTRON && patientDetails.chartNumber && <XRayForm patient={patientDetails} />}*/}
        <FlexBox container={8} flex={true} direction="column" className="patient-view-flex">
          <FlexBox gutter={8} flex={FlexBoxFlex.None} style={{ maxHeight: "210px" }}>
            <FlexBox flex={FlexBoxFlex.Grow2}>
              <div className="profile">
                <div className="action">
                  <MemberImage
                    size={135}
                    photoUrl={patientDetails.photo && patientDetails.photo.url}
                    defaultSrc={addPhotoImage}
                  />
                  <Show if={!isTemporary}>
                    <div className="btn-box col-gap-2 mt-1">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.navigateHandler("View")}
                      >
                        Just View
                      </button>
                      <Show if={planningChartPermission}>
                        <button
                          className="btn btn-primary"
                          onClick={() => this.navigateHandler("Planning")}
                        >
                          Planning
                        </button>
                      </Show>
                    </div>
                  </Show>
                </div>
                <div
                  className="info"
                  onClick={!isTemporary ? this.openProfile : this.openPatientDetailsEdit}
                >
                  <div className="body d-flex">
                    <div className="d-flex flex-grow-1 flex-shrink-1 pr-1 flex-column">
                      <div>
                        <strong>{Utils.getFullName(patientDetails)}</strong>
                      </div>
                      <div>
                        Nationality: {patientDetails.nationality && patientDetails.nationality.name}
                      </div>
                      <div>{getAge(patientDetails.dateOfBirth)}</div>
                      <div>#{patientDetails.chartNumber}</div>
                      <div>{patientDetails.workPlace}</div>
                    </div>
                    <div className="d-flex flex-column flex-shrink-1 pl-1 flex-grow-1">
                      <span>Additional information:</span>
                      <span>{patientDetails.additionalInformation}</span>
                    </div>
                  </div>
                  <div className="footer">
                    <div className="text">Profile</div>
                    <div className="icon">
                      <img src={ProfileImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </FlexBox>
            <FlexBox flex={true}>
              <div
                className="contacts"
                onClick={!isTemporary ? this.openContacts : this.openPatientDetailsEdit}
              >
                <div className="body">
                  {Boolean(
                    permissions &&
                      permissions.indexOf(PERMISSIONS.VIEW_PATIENT_CONTACT_DETAILS) >= 0,
                  ) && (
                    <div>
                      <div>{patientDetails.email}</div>
                      <div>{patientDetails.mobilePhoneNumber} (M)</div>
                      <div>{patientDetails.homePhoneNumber} (H)</div>
                      <div>{patientDetails.workPhoneNumber} (W)</div>
                    </div>
                  )}
                </div>
                <div className="footer">
                  <div className="text">Contacts</div>
                  <div className="icon">
                    <img src={ContactsImage} alt="" />
                  </div>
                </div>
              </div>
            </FlexBox>
            <FlexBox flex={true}>
              <div className="alerts" onClick={!isTemporary ? this.openAlerts : null}>
                <div className="body">{$alerts}</div>
                <div className="footer">
                  <div className="text">Alerts</div>
                  <div className="icon">
                    <img src={AlertsImage} alt="" />
                  </div>
                </div>
              </div>
            </FlexBox>
          </FlexBox>
          <FlexBox gutter={8} flex={true} style={{ maxHeight: "210px" }}>
            <FlexBox flex={FlexBoxFlex.Grow2} direction="column">
              <div className="profile">
                <div
                  className="info"
                  role="button"
                  onClick={() => this.setState({ showFamilyMembersSidebar: true })}
                >
                  <div className="body">{$familyMembers}</div>
                  <div className="footer">
                    <div className="text">Family members</div>
                    <div className="icon outlet-icon">
                      <FilledImage src={FamilyMembersImage} color={"#ffffff"} zoom={80} />
                    </div>
                  </div>
                </div>
                <div
                  className="notes"
                  role="button"
                  onClick={() => this.setState({ showClinician: true })}
                >
                  <div
                    className="body"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontSize: "20px" }}>
                      {_.get(patientDetails, "primaryClinician.name")}
                    </span>
                  </div>
                  <div
                    className="footer"
                    style={{ cursor: "pointer" }}
                    // onClick={!isTemporary ? this.openFormsList : null}
                  >
                    <div className="text">Primary clinician</div>
                    <div className="icon">
                      <img src={FormsImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="consultation"
                    onClick={() => {
                      if (availableConsultationNotes) {
                        this.props.consultationNotesShow();
                      } else {
                        this.props.openSimpleModal({
                          body: "Access denied",
                          footer: (
                            <button
                              className="btn btn-primary"
                              onClick={() => this.props.closeSimpleModal()}
                            >
                              Ok
                            </button>
                          ),
                        });
                      }
                    }}
                  >
                    <div className="body">{availableConsultationNotes && $consultation}</div>
                    <div className="footer">
                      <div className="text">Consultation Notes</div>
                      <div className="icon">
                        <img src={NotesImage} alt="" />
                      </div>
                    </div>
                    {!availableConsultationNotes && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          cursor: "not-allowed",
                          position: "absolute",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      />
                    )}
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="appointments"
                    onClick={!isTemporary ? this.openAppointmetsList : null}
                  >
                    <div className="body" onScroll={this.loadAppointments}>
                      {$appointments}
                    </div>
                    <div className="footer">
                      <div className="text">Appointments</div>
                      <div className="icon">
                        <img src={AppointmentsImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div className="forms">
                    <div className="body">
                      {detailsEditAvailable && (
                        <div
                          className="form-row"
                          onClick={() => (!isTemporary ? this.openPatientDetailsView() : null)}
                        >
                          <PatientViewFormsArrow active={patientAccessModules.personalDetails} />
                          &nbsp; Patient Personal Details
                        </div>
                      )}
                      <div
                        className="form-row"
                        onClick={() => (!isTemporary ? this.openPatientMedicalAlertsView() : null)}
                      >
                        <PatientViewFormsArrow active={patientAccessModules.medicalAlerts} />
                        &nbsp; Patient Alerts
                      </div>
                      <div
                        className="form-row"
                        onClick={() =>
                          !isTemporary ? this.openPatientMedicationAlertsView() : null
                        }
                      >
                        <PatientViewFormsArrow active={patientAccessModules.medicationAlerts} />
                        &nbsp; Patient Medication Alerts
                      </div>
                    </div>
                    <div className="footer" style={{ cursor: "pointer" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                        onClick={() => this.setState({ showDocsModal: true })}
                      >
                        <div className="text">Docs | Attachments:</div>
                        <div className="outlet-icon">
                          <IconButton title="Docs" className="icon-btn-class">
                            <FilledImage src={DocsImage} color={"#ffffff"} />
                          </IconButton>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                        onClick={!isTemporary ? this.openFormsList : null}
                      >
                        <div className="text">Forms:</div>
                        <div className="icon">
                          <IconButton title="Consent Form" className="icon-btn-class">
                            <FilledImage src={ConsentFormImage} color={"#ffffff"} zoom={50} />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div onClick={!isTemporary ? this.openInsurance : null} className="insurance">
                    <div className="body" />
                    <div className="footer">
                      <div className="text">Insurance</div>
                      <div className="icon">
                        <img src={FormsImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="notes"
                    onClick={!isTemporary ? this.showModal(patientViewModalTypes.note) : null}
                  >
                    <div className="body">{$notes}</div>
                    <div className="footer">
                      <div className="text">Reception Notes</div>
                      <div className="icon">
                        <img src={NotesImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="insurance-notes"
                    onClick={
                      !isTemporary ? this.showModal(patientViewModalTypes.insuranceNote) : null
                    }
                  >
                    <div className="body">{$insuranceNotes}</div>
                    <div className="footer">
                      <div className="text">
                        {_.get(company, "id") === 181
                          ? "Patient additional info"
                          : "Insurance Notes"}
                      </div>
                      <div className="icon">
                        <img src={NotesImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="patient-reminders"
                    onClick={!isTemporary ? this.openPatientReminders : null}
                  >
                    <div className="body">
                      <div className="title">Reception Reminders</div>
                      <div className="item">{receptionReminder}</div>
                      <div className="title">{localStorage.getItem("doctorLabel")} Reminders</div>
                      <div className="item">{doctorReminder}</div>
                    </div>
                    <div className="footer">
                      <div className="text">Patient Reminders</div>
                      <div className="icon">
                        <img src={AccountAlertImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
              <FlexBox withoutHeight={true} gutter={8} flex={true} direction="column">
                <FlexBox flex={true}>
                  <div
                    className="clinician"
                    onClick={!isTemporary ? this.props.patientViewActions.showClinicianModal : null}
                  >
                    <div className="body">{$clinicians}</div>
                    <div className="footer">
                      <div className="text">Assigned Clinicians</div>
                      <div className="icon">
                        <img src={NotesImage} alt="" />
                      </div>
                    </div>
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <PatientFamilyMembersSidebar
          show={this.state.showFamilyMembersSidebar}
          onHide={() => this.setState({ showFamilyMembersSidebar: false })}
          patientKey={this.props.match.params.patientKey}
          companyTempPatientId={query.companyTempPatientId || null}
          patientFamilyMembers={patientFamilyMembers}
          location={this.props.location.search}
        />
        <PatientViewModal
          modalVisible={modalVisible}
          modalType={modalType}
          notes={notes}
          insuranceNotes={insuranceNotes}
        />
        <ConsultationNotesSidebar
          appointment={this.props.appointment}
          show={consultationNotesSidebarVisible}
          onHide={this.props.consultationNotesHide}
          patientKey={this.props.match.params.patientKey}
          companyTempPatientId={query.companyTempPatientId || null}
        />
        <PatientViewEditNoteModal
          noteEditVisible={noteEditVisible}
          activeNote={activeNote}
          modalType={modalType}
        />
        <PatientViewClinicianModal patientView={this.props.patientView} />
        <PatientPlanVisitChooseActionModal
          show={this.props.patientRegister.patientChooseActionModalVisible}
          planning={
            permissions &&
            permissions.indexOf(
              PERMISSIONS.ADD_PLANNED_DENTAL_TRANSACTION_ITEM_WITHOUT_APPOINTMENT,
            ) >= 0
          }
          patientKey={this.props.patientView.patientKey}
        />
        <Show if={this.props.patientRegister.patientSelectAppointmentSidebarVisible}>
          <PatientSelectAppointmentSidebar
            patientRegister={this.props.patientRegister}
            patientKey={this.props.patientView.patientKey}
          />
        </Show>
        <PatientRemindersModal patientView={this.props.patientView} />

        <Show if={showClinician}>
          <PatientPrimaryClinicianSidebar
            currentId={_.get(patientDetails, "primaryClinician.id")}
            onRequestClose={() => this.setState({ showClinician: false })}
          />
        </Show>
        <DocsWrapper
          showDocsModal={this.state.showDocsModal}
          onShow={() => this.setState({ showDocsModal: true })}
          onHide={() => this.setState({ showDocsModal: false })}
        />
      </AppLayout>
    );
  }
}

function PatientViewFormsArrow(props) {
  return <img alt="" src={props.active ? ActiveArrowImage : InactiveArrowImage} />;
}

export default withRouter(enhancer(PatientView));
