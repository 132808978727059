const JSUtils = {};
JSUtils.parseToNumber = (str) => +str.replace(/[^\d.-]/g, "");
JSUtils.getSortedList = (teeth) => {
  let list = [];
  if (teeth) {
    const sortFn = (a, b) => JSUtils.parseToNumber(a) - JSUtils.parseToNumber(b);

    list = Object.keys(teeth).sort(sortFn);
  }
  return list;
};
JSUtils.hasOwn = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export default JSUtils;
