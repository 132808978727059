import _ from "lodash";
import React from "react";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { SelectPicker } from "../select-picker/SelectPicker";

const selector = formValueSelector("EditInsurancePlanForm");

const SelectComponent = (props) => {
  return (
    <SelectPicker
      className="insurance-plan-select"
      value={props.input.value}
      options={props.options}
      onChange={(x) => props.input.onChange(x)}
      labelKey="name"
    />
  );
};

const TextInputComponent = (props) => (
  <input {...props.input} style={{ textAlign: "center" }} type="text" />
);

const CheckboxInputComponent = (props) => {
  const nameArr = _.get(props, "input.name", "").split(".");
  const nextValue = !props.input.value;

  return (
    <input
      {...props.input}
      checked={props.input.value}
      type="checkbox"
      onChange={() => {
        if (nameArr[1] === "needPreApproval" && nextValue) {
          props.change("EditInsurancePlanForm", `${nameArr[0]}.notBillInsurance`, false);
          props.change("EditInsurancePlanForm", `${nameArr[0]}.needCashClaim`, false);
        }
        if ((nameArr[1] === "notBillInsurance" || nameArr[1] === "needCashClaim") && nextValue) {
          props.change("EditInsurancePlanForm", `${nameArr[0]}.needPreApproval`, false);
        }

        props.change("EditInsurancePlanForm", props.input.name, nextValue);
      }}
    />
  );
};

const ArrayComponent = ({ fields, ...props }) => (
  <div
    style={{
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      flex: "1 1 0%",
    }}
  >
    <div
      style={{
        display: "flex",
        height: "40px",
        border: "1px solid #cccccc",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Treatment Group
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Coverage (%)
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Co-pay
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Deductible Type
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Require Prior-Approval
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Doesn`t require claim
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        Require cash claim
      </div>
    </div>

    {fields.map((x, idx) => (
      <div
        key={idx}
        style={{
          display: "flex",
          border: "1px solid #cccccc",
          borderTop: 0,
          height: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          {_.get(props.values[idx], "group.code")}
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field component={TextInputComponent} name={`${x}.coverage`} />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field component={TextInputComponent} name={`${x}.coPay`} />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field
            component={SelectComponent}
            options={props.deductibleTypes}
            name={`${x}.deductibleType`}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field component={CheckboxInputComponent} name={`${x}.needPreApproval`} props={props} />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field component={CheckboxInputComponent} name={`${x}.notBillInsurance`} props={props} />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Field component={CheckboxInputComponent} name={`${x}.needCashClaim`} props={props} />
        </div>
      </div>
    ))}
  </div>
);

const form = reduxForm({
  form: "EditInsurancePlanForm",
  enableReinitialize: true,
});
const enhancer = connect(
  (state) => ({
    values: selector(state, "planRows"),
  }),
  { change },
);
class EditInsurancePlanForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FieldArray
          name="planRows"
          component={ArrayComponent}
          props={{
            change: this.props.change,
            values: this.props.values,
            deductibleTypes: this.props.deductibleTypes,
          }}
        />
      </form>
    );
  }
}
export default form(enhancer(EditInsurancePlanForm));
