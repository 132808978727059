export const parserCallback = (response) => {
  if (response && response.data && response.status === 200) {
    return {
      data: {
        statusCode: 2,
        status: "success",
        data: response.data,
      },
    };
  }

  return undefined;
};
