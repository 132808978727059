import React from "react";
import { connect } from "react-redux";

import ChartClinicalNoteTextareaForm from "./ChartClinicalNoteTextareaForm";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideClinicalNoteActions from "../../../../actions/chartSideClinicalNoteActions";
import * as simpleModalActions from "../../../../actions/simpleModalActions";
import exitBlackImage from "../../../../assets/images/chart/ic_fullscreen_exit_black_24dp_1x.png";
import exitImage from "../../../../assets/images/chart/ic_fullscreen_black_24dp_1x.png";
import { SidebarHeader, SidebarHeaderType } from "../../../sidebar/SidebarHeader";

const enhancer = connect(null, bindActions({ chartSideClinicalNoteActions, simpleModalActions }));
export default enhancer(
  class ChartClinicalNoteTextarea extends React.Component {
    remainderPopUp = () => {
      this.props.simpleModalActions.openSimpleModal({
        body: "Do you want to save your changes?",
        footer: (
          <div className="text-right">
            <button
              className="btn btn-default"
              onClick={() => {
                this.props.simpleModalActions.closeSimpleModal();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                this.props.chartSideClinicalNoteActions.setAddendumId(0);
                this.props.simpleModalActions.closeSimpleModal();
                this.props.chartSideClinicalNoteActions.popStack();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.chartSideClinicalNoteActions.setAddendumId(0);
                this.props.simpleModalActions.closeSimpleModal();
                this.props.chartSideClinicalNoteActions.submitTextareaForm();
              }}
            >
              Yes
            </button>
          </div>
        ),
      });
    };

    popUpEsc = (event) => {
      const {
        simpleModalActions: { openSimpleModal, closeSimpleModal },
        chartSideClinicalNoteActions: { setAddendumId, submitTextareaForm, popStack },
      } = this.props;
      if (event.key === "Escape") {
        openSimpleModal({
          body: "Do you want to save your changes?",
          footer: (
            <div className="text-right">
              <button
                className="btn btn-default"
                onClick={() => {
                  closeSimpleModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setAddendumId(0);
                  closeSimpleModal();
                  popStack();
                }}
              >
                No
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setAddendumId(0);
                  closeSimpleModal();
                  submitTextareaForm();
                }}
              >
                Yes
              </button>
            </div>
          ),
        });
      }
    };

    componentDidMount() {
      if (!this.props.edit) {
        this.props.chartSideClinicalNoteActions.initTextarea();
      }

      document.addEventListener("keydown", this.popUpEsc);
    }
    componentWillUnmount() {
      document.removeEventListener("keydown", this.popUpEsc);
    }
    render() {
      const {
        edit,
        chartSideClinicalNote: { item, fullScreen },
      } = this.props;

      return (
        <div className="chart-clinical-note">
          <div className="clinical-note-item clinical-note-item-slime">
            <SidebarHeader
              buttonWidth={115}
              type={SidebarHeaderType.Secondary}
              rightComponent={
                <div className="d-flex align-items-center pr-2 justify-content-between">
                  <button
                    type="button"
                    title="full screen"
                    onClick={() => this.props.chartSideClinicalNoteActions.fullScreen(!fullScreen)}
                    className="pull-right btn-link"
                  >
                    <img src={fullScreen ? exitBlackImage : exitImage} alt="" />
                  </button>
                </div>
              }
              title="ADD CLINICAL NOTE"
              onBackClick={() => this.remainderPopUp()}
            />
            <div className="clinical-note-item-edit-body">
              <ChartClinicalNoteTextareaForm
                changeCreatedDate={this.props.chartSideClinicalNoteActions.changeCreatedDate}
                onSubmit={this.props.chartSideClinicalNoteActions.submitTextareaForm}
                item={item}
                edit={edit}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
