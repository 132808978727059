import { DateTime } from "luxon";
import { isString } from "lodash";

export const SHORT_DATE_FORMAT = "dd/MM/yyyy";

interface CreateDateParams {
  locale?: string;
  date?: Date;
}

interface CreateMonthParams {
  date?: Date;
  locale?: string;
}

export function formatDate(
  date: string | Date | undefined,
  format = SHORT_DATE_FORMAT,
  fallback = "",
): string {
  if (!date) {
    return fallback;
  }

  if (isString(date)) {
    return DateTime.fromISO(date).toFormat(format);
  }

  return DateTime.fromJSDate(date).toFormat(format);
}

export const formatDateHandler = (date: Date, format: string) => {
  const d = createDate({ date });

  return format
    .replace(/\bYYYY\b/, d.year.toString())
    .replace(/\bYYY\b/, d.yearShort)
    .replace(/\bWW\b/, d.week.toString().padStart(2, "0"))
    .replace(/\bW\b/, d.week.toString())
    .replace(/\bDDDD\b/, d.day)
    .replace(/\bDDD\b/, d.dayShort)
    .replace(/\bDD\b/, d.dayNumber.toString().padStart(2, "0"))
    .replace(/\bD\b/, d.dayNumber.toString())
    .replace(/\bMMMM\b/, d.month)
    .replace(/\bMMM\b/, d.monthShort)
    .replace(/\bMM\b/, d.monthNumber.toString().padStart(2, "0"))
    .replace(/\bM\b/, d.monthNumber.toString());
};

export function getYearsInterval(year: number) {
  const startYear = Math.floor(year / 10) * 10;
  return [...Array(10)].map((_, index) => startYear + index);
}

export function checkDateIsEqual(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function checkIsToday(date: Date) {
  const today = new Date();

  return checkDateIsEqual(today, date);
}

export function getWeekNumber(date: Date) {
  const firstDayOfTheYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfTheYear.getTime()) / 86400000;

  return Math.ceil((pastDaysOfYear + firstDayOfTheYear.getDay() + 1) / 7);
}

export function createDate(params?: CreateDateParams) {
  const locale = params?.locale ?? "default";
  const d = params?.date ?? new Date();
  const timestamp = d.getTime();
  const dayNumber = d.getDate();
  const dayNumberInWeek = d.getDay() + 1;
  const day = d.toLocaleDateString(locale, { weekday: "long" });
  const dayShort = d.toLocaleDateString(locale, { weekday: "short" });

  const week = getWeekNumber(d);
  const monthIndex = d.getMonth();
  const monthNumber = d.getMonth() + 1;
  const month = d.toLocaleDateString(locale, { month: "long" });
  const monthShort = d.toLocaleDateString(locale, { month: "short" });

  const year = d.getFullYear();
  const yearShort = d.toLocaleDateString(locale, { year: "2-digit" });

  return {
    date: d,
    dayNumber,
    day,
    dayNumberInWeek,
    dayShort,
    year,
    yearShort,
    month,
    monthShort,
    monthNumber,
    monthIndex,
    timestamp,
    week,
  };
}

export function getMonthNumberOfDays(
  monthIndex: number,
  yearNumber: number = new Date().getFullYear(),
) {
  return new Date(yearNumber, monthIndex + 1, 0).getDate();
}

export function createMonth(params?: CreateMonthParams) {
  const date = params?.date ?? new Date();
  const locale = params?.locale ?? "default";

  const d = createDate({ date, locale });
  const { month: monthName, year, monthNumber, monthIndex } = d;

  const getDay = (dayNumber: number) =>
    createDate({ date: new Date(year, monthIndex, dayNumber), locale });

  const createMonthDays = () => {
    const days = [];

    for (let i = 0; i <= getMonthNumberOfDays(monthIndex, year) - 1; i += 1) {
      // @ts-ignore
      days[i] = getDay(i + 1);
    }

    return days;
  };

  return {
    getDay,
    monthName,
    monthIndex,
    monthNumber,
    year,
    createMonthDays,
  };
}

export function getMonthsNames(locale: string = "default") {
  const monthsNames: {
    month: ReturnType<typeof createDate>["month"];
    monthShort: ReturnType<typeof createDate>["monthShort"];
    monthIndex: ReturnType<typeof createDate>["monthIndex"];
    date: ReturnType<typeof createDate>["date"];
  }[] = Array.from({ length: 12 });

  const d = new Date();

  monthsNames.forEach((_, i) => {
    const { month, monthIndex, monthShort, date } = createDate({
      locale,
      date: new Date(d.getFullYear(), d.getMonth() + i, 1),
    });

    monthsNames[monthIndex] = { month, monthIndex, monthShort, date };
  });

  return monthsNames;
}

export function getWeekDaysNames(firstWeekDay: number = 4, locale: string = "default") {
  const weekDaysNames: {
    day: ReturnType<typeof createDate>["day"];
    dayShort: ReturnType<typeof createDate>["dayShort"];
  }[] = Array.from({ length: 7 });

  const date = new Date();

  weekDaysNames.forEach((_, i) => {
    const { day, dayNumberInWeek, dayShort } = createDate({
      locale,
      date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + i),
    });

    weekDaysNames[dayNumberInWeek - 1] = { day, dayShort };
  });

  return [...weekDaysNames.slice(firstWeekDay - 1), ...weekDaysNames.slice(0, firstWeekDay - 1)];
}

export function getAdditionalDays() {
  const daysNumbers = [7, 15, 30, 45, 46];
  const today = new Date();

  return daysNumbers.map((day) => {
    const date = new Date(today);
    date.setUTCDate(today.getUTCDate() + day);
    return { title: day, value: date };
  });
}
