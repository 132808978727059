import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";

import ChartVitalItemDeleteModal from "./ChartVitalItemDeleteModal";

import vitalItemTypes from "../../../../constants/chart-sidebar/vitalItemTypes";

import { FULL_DATE_FORMAT } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVitalItemView extends React.Component {
    componentWillMount() {
      this.props.chartSideVitalActions.getItem();
    }

    editNote = () => {
      const { stack } = this.props.chartSideVital;

      const stackItem = _.last(stack);
      const id = stackItem.item.id;

      const data = {
        id,
      };

      this.props.chartSideVitalActions.openItem(data, vitalItemTypes.edit);
    };

    render() {
      const { item, deleteModalVisible } = this.props.chartSideVital;
      const { member } = this.props;

      const drawenNote = item.typeCode === "NOTE_TYPE_IMAGE";

      const isOwner = item.doctor && item.doctor.id === member.id;

      return (
        <div className="chart-clinical-note">
          <div className="clinical-note-item">
            <div className="clinical-note-item-view-title">
              <BackButton
                className="btn-sm"
                text="Back"
                onClick={this.props.chartSideVitalActions.popStack}
              />
              <div className="chart-number">#{item.patient && item.patient.chartNumber}</div>
              <div className="name">{item.name}</div>
            </div>
            <div className="clinical-note-item-view-body">
              <div className="clinical-note-item-view-details">
                <div>
                  Patient: <strong>{item.patient && item.patient.fullName}</strong>
                  &nbsp; Chart: <strong>#{item.patient && item.patient.chartNumber}</strong>
                </div>
                <div>
                  Created: &nbsp;
                  <strong>
                    {Utils.parseDateFormat(
                      Utils.calcDateWithTimeZone(item.createdDate),
                      FULL_DATE_FORMAT,
                    )}
                  </strong>
                </div>
                <div>
                  {localStorage.getItem("doctorLabel")} Name: &nbsp;
                  <strong>{item.doctor && item.doctor.name}</strong>
                </div>
              </div>
              <div
                className="clinical-note-item-view-note"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
            <div className="clinical-note-item-view-footer">
              <div className="clearfix">
                <div className="col-sm-4">
                  <Show if={isOwner}>
                    <button
                      disabled={drawenNote}
                      onClick={this.editNote}
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Edit
                    </button>
                  </Show>
                </div>
                <div className="col-sm-4 col-sm-offset-4">
                  <Show if={isOwner}>
                    <button
                      onClick={this.props.chartSideVitalActions.openDeleteModal}
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Delete
                    </button>
                  </Show>
                </div>
              </div>
            </div>
          </div>
          <ChartVitalItemDeleteModal
            deleteModalVisible={deleteModalVisible}
            closeDeleteModal={this.props.chartSideVitalActions.closeDeleteModal}
            deleteItem={this.props.chartSideVitalActions.deleteItem}
          />
        </div>
      );
    }
  },
);
