const ToothDetailsType = {
  Child_p4: {
    id: "Child_p4",
    code: 2,
    position: "p4",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "A",
    numeric2: "55(E)",
    numeric3: "54",
  },
  Child_p5: {
    id: "Child_p5",
    code: 3,
    position: "p5",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "B",
    numeric2: "54(D)",
    numeric3: "55",
  },
  Child_p6: {
    id: "Child_p6",
    code: 4,
    position: "p6",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "C",
    numeric2: "53(C)",
    numeric3: "56",
  },
  Child_p7: {
    id: "Child_p7",
    code: 5,
    position: "p7",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "D",
    numeric2: "52(B)",
    numeric3: "57",
  },
  Child_p8: {
    id: "Child_p8",
    code: 6,
    position: "p8",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "E",
    numeric2: "51(A)",
    numeric3: "58",
  },
  Child_p9: {
    id: "Child_p9",
    code: 7,
    position: "p9",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "F",
    numeric2: "61(A)",
    numeric3: "59",
  },
  Child_p10: {
    id: "Child_p10",
    code: 8,
    position: "p10",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "G",
    numeric2: "62(B)",
    numeric3: "60",
  },
  Child_p11: {
    id: "Child_p11",
    code: 9,
    position: "p11",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "H",
    numeric2: "63(C)",
    numeric3: "61",
  },
  Child_p12: {
    id: "Child_p12",
    code: 10,
    position: "p12",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "I",
    numeric2: "64(D)",
    numeric3: "62",
  },
  Child_p13: {
    id: "Child_p13",
    code: 11,
    position: "p13",
    generation: "Child",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "J",
    numeric2: "65(E)",
    numeric3: "63",
  },
  Child_p20: {
    id: "Child_p20",
    code: 12,
    position: "p20",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "K  ",
    numeric2: "75(E)",
    numeric3: "70",
  },
  Child_p21: {
    id: "Child_p21",
    code: 13,
    position: "p21",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "L",
    numeric2: "74(D)",
    numeric3: "71",
  },
  Child_p22: {
    id: "Child_p22",
    code: 14,
    position: "p22",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "M",
    numeric2: "73(C)",
    numeric3: "72",
  },
  Child_p23: {
    id: "Child_p23",
    code: 15,
    position: "p23",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "N",
    numeric2: "72(B)",
    numeric3: "73",
  },
  Child_p24: {
    id: "Child_p24",
    code: 16,
    position: "p24",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "O",
    numeric2: "71(A)",
    numeric3: "74",
  },
  Child_p25: {
    id: "Child_p25",
    code: 17,
    position: "p25",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "P",
    numeric2: "81(A)",
    numeric3: "75",
  },
  Child_p26: {
    id: "Child_p26",
    code: 18,
    position: "p26",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "Q",
    numeric2: "82(B)",
    numeric3: "76",
  },
  Child_p27: {
    id: "Child_p27",
    code: 19,
    position: "p27",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "R",
    numeric2: "83(C)",
    numeric3: "77",
  },
  Child_p28: {
    id: "Child_p28",
    code: 20,
    position: "p28",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "S",
    numeric2: "84(D)",
    numeric3: "78",
  },
  Child_p29: {
    id: "Child_p29",
    code: 21,
    position: "p29",
    generation: "Child",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "T",
    numeric2: "85(E)",
    numeric3: "79",
  },
  Adult_p1: {
    id: "Adult_p1",
    code: 22,
    position: "p1",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "1",
    numeric2: "18",
    numeric3: "51",
  },
  Adult_p2: {
    id: "Adult_p2",
    code: 23,
    position: "p2",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "2",
    numeric2: "17",
    numeric3: "52",
  },
  Adult_p3: {
    id: "Adult_p3",
    code: 24,
    position: "p3",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "3",
    numeric2: "16",
    numeric3: "53",
  },
  Adult_p4: {
    id: "Adult_p4",
    code: 25,
    position: "p4",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "4",
    numeric2: "15",
    numeric3: "54",
  },
  Adult_p5: {
    id: "Adult_p5",
    code: 26,
    position: "p5",
    generation: "Adult",
    rootCount: 1,
    image: 14,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "5",
    numeric2: "14",
    numeric3: "55",
  },
  Adult_p6: {
    id: "Adult_p6",
    code: 27,
    position: "p6",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "6",
    numeric2: "13",
    numeric3: "56",
  },
  Adult_p7: {
    id: "Adult_p7",
    code: 28,
    position: "p7",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "7",
    numeric2: "12",
    numeric3: "57",
  },
  Adult_p8: {
    id: "Adult_p8",
    code: 29,
    position: "p8",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "8",
    numeric2: "11",
    numeric3: "58",
  },
  Adult_p9: {
    id: "Adult_p9",
    code: 30,
    position: "p9",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "9",
    numeric2: "21",
    numeric3: "59",
  },
  Adult_p10: {
    id: "Adult_p10",
    code: 31,
    position: "p10",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "10",
    numeric2: "22",
    numeric3: "60",
  },
  Adult_p11: {
    id: "Adult_p11",
    code: 32,
    position: "p11",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "11",
    numeric2: "23",
    numeric3: "61",
  },
  Adult_p12: {
    id: "Adult_p12",
    code: 33,
    position: "p12",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "12",
    numeric2: "24",
    numeric3: "62",
  },
  Adult_p13: {
    id: "Adult_p13",
    code: 34,
    position: "p13",
    generation: "Adult",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "13",
    numeric2: "25",
    numeric3: "63",
  },
  Adult_p14: {
    id: "Adult_p14",
    code: 35,
    position: "p14",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "14",
    numeric2: "26",
    numeric3: "64",
  },
  Adult_p15: {
    id: "Adult_p15",
    code: 36,
    position: "p15",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "15",
    numeric2: "27",
    numeric3: "65",
  },
  Adult_p16: {
    id: "Adult_p16",
    code: 37,
    position: "p16",
    generation: "Adult",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "16",
    numeric2: "28",
    numeric3: "66",
  },
  Adult_p17: {
    id: "Adult_p17",
    code: 38,
    position: "p17",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "17",
    numeric2: "38",
    numeric3: "67",
  },
  Adult_p18: {
    id: "Adult_p18",
    code: 39,
    position: "p18",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "18",
    numeric2: "37",
    numeric3: "68",
  },
  Adult_p19: {
    id: "Adult_p19",
    code: 40,
    position: "p19",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "19",
    numeric2: "36",
    numeric3: "69",
  },
  Adult_p20: {
    id: "Adult_p20",
    code: 41,
    position: "p20",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "20",
    numeric2: "35",
    numeric3: "70",
  },
  Adult_p21: {
    id: "Adult_p21",
    code: 42,
    position: "p21",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "21",
    numeric2: "34",
    numeric3: "71",
  },
  Adult_p22: {
    id: "Adult_p22",
    code: 43,
    position: "p22",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "22",
    numeric2: "33",
    numeric3: "72",
  },
  Adult_p23: {
    id: "Adult_p23",
    code: 44,
    position: "p23",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "23",
    numeric2: "32",
    numeric3: "73",
  },
  Adult_p24: {
    id: "Adult_p24",
    code: 45,
    position: "p24",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "24",
    numeric2: "31",
    numeric3: "74",
  },
  Adult_p25: {
    id: "Adult_p25",
    code: 46,
    position: "p25",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "25",
    numeric2: "41",
    numeric3: "75",
  },
  Adult_p26: {
    id: "Adult_p26",
    code: 47,
    position: "p26",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "26",
    numeric2: "42",
    numeric3: "76",
  },
  Adult_p27: {
    id: "Adult_p27",
    code: 48,
    position: "p27",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "27",
    numeric2: "43",
    numeric3: "77",
  },
  Adult_p28: {
    id: "Adult_p28",
    code: 49,
    position: "p28",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "28",
    numeric2: "44",
    numeric3: "78",
  },
  Adult_p29: {
    id: "Adult_p29",
    code: 50,
    position: "p29",
    generation: "Adult",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "29",
    numeric2: "45",
    numeric3: "79",
  },
  Adult_p30: {
    id: "Adult_p30",
    code: 51,
    position: "p30",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "30",
    numeric2: "46",
    numeric3: "80",
  },
  Adult_p31: {
    id: "Adult_p31",
    code: 52,
    position: "p31",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "31",
    numeric2: "47",
    numeric3: "81",
  },
  Adult_p32: {
    id: "Adult_p32",
    code: 53,
    position: "p32",
    generation: "Adult",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "32",
    numeric2: "48",
    numeric3: "82",
  },
  Supernumerary_p1: {
    id: "Supernumerary_p1",
    code: 54,
    position: "p1",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "51",
    numeric2: "51",
    numeric3: "51",
  },
  Supernumerary_p2: {
    id: "Supernumerary_p2",
    code: 55,
    position: "p2",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "52",
    numeric2: "52",
    numeric3: "52",
  },
  Supernumerary_p3: {
    id: "Supernumerary_p3",
    code: 56,
    position: "p3",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "53",
    numeric2: "53",
    numeric3: "53",
  },
  Supernumerary_p4: {
    id: "Supernumerary_p4",
    code: 57,
    position: "p4",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant1",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "54",
    numeric2: "54",
    numeric3: "54",
  },
  Supernumerary_p5: {
    id: "Supernumerary_p5",
    code: 58,
    position: "p5",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "55",
    numeric2: "55",
    numeric3: "55",
  },
  Supernumerary_p6: {
    id: "Supernumerary_p6",
    code: 59,
    position: "p6",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "56",
    numeric2: "56",
    numeric3: "56",
  },
  Supernumerary_p7: {
    id: "Supernumerary_p7",
    code: 60,
    position: "p7",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "57",
    numeric2: "57",
    numeric3: "57",
  },
  Supernumerary_p8: {
    id: "Supernumerary_p8",
    code: 61,
    position: "p8",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperRight",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "58",
    numeric2: "58",
    numeric3: "58",
  },
  Supernumerary_p9: {
    id: "Supernumerary_p9",
    code: 62,
    position: "p9",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Central",
    numeric1: "59",
    numeric2: "59",
    numeric3: "59",
  },
  Supernumerary_p10: {
    id: "Supernumerary_p10",
    code: 63,
    position: "p10",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Lateral",
    numeric1: "60",
    numeric2: "60",
    numeric3: "60",
  },
  Supernumerary_p11: {
    id: "Supernumerary_p11",
    code: 64,
    position: "p11",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Cuspid",
    numeric1: "61",
    numeric2: "61",
    numeric3: "61",
  },
  Supernumerary_p12: {
    id: "Supernumerary_p12",
    code: 65,
    position: "p12",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant2",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "62",
    numeric2: "62",
    numeric3: "62",
  },
  Supernumerary_p13: {
    id: "Supernumerary_p13",
    code: 66,
    position: "p13",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Bicuspid",
    numeric1: "63",
    numeric2: "63",
    numeric3: "63",
  },
  Supernumerary_p14: {
    id: "Supernumerary_p14",
    code: 67,
    position: "p14",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "64",
    numeric2: "64",
    numeric3: "64",
  },
  Supernumerary_p15: {
    id: "Supernumerary_p15",
    code: 68,
    position: "p15",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "65",
    numeric2: "65",
    numeric3: "65",
  },
  Supernumerary_p16: {
    id: "Supernumerary_p16",
    code: 69,
    position: "p16",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "UpperLeft",
    sextant: "Sextant3",
    mouth: "Upper",
    toothType: "Molar",
    numeric1: "66",
    numeric2: "66",
    numeric3: "66",
  },
  Supernumerary_p17: {
    id: "Supernumerary_p17",
    code: 70,
    position: "p17",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "67",
    numeric2: "67",
    numeric3: "67",
  },
  Supernumerary_p18: {
    id: "Supernumerary_p18",
    code: 71,
    position: "p18",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "68",
    numeric2: "68",
    numeric3: "68",
  },
  Supernumerary_p19: {
    id: "Supernumerary_p19",
    code: 72,
    position: "p19",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "69",
    numeric2: "69",
    numeric3: "69",
  },
  Supernumerary_p20: {
    id: "Supernumerary_p20",
    code: 73,
    position: "p20",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant4",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "70",
    numeric2: "70",
    numeric3: "70",
  },
  Supernumerary_p21: {
    id: "Supernumerary_p21",
    code: 74,
    position: "p21",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "71",
    numeric2: "71",
    numeric3: "71",
  },
  Supernumerary_p22: {
    id: "Supernumerary_p22",
    code: 75,
    position: "p22",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "72",
    numeric2: "72",
    numeric3: "72",
  },
  Supernumerary_p23: {
    id: "Supernumerary_p23",
    code: 76,
    position: "p23",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "73",
    numeric2: "73",
    numeric3: "73",
  },
  Supernumerary_p24: {
    id: "Supernumerary_p24",
    code: 77,
    position: "p24",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerLeft",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "74",
    numeric2: "74",
    numeric3: "74",
  },
  Supernumerary_p25: {
    id: "Supernumerary_p25",
    code: 78,
    position: "p25",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Central",
    numeric1: "75",
    numeric2: "75",
    numeric3: "75",
  },
  Supernumerary_p26: {
    id: "Supernumerary_p26",
    code: 79,
    position: "p26",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Lateral",
    numeric1: "76",
    numeric2: "76",
    numeric3: "76",
  },
  Supernumerary_p27: {
    id: "Supernumerary_p27",
    code: 80,
    position: "p27",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Cuspid",
    numeric1: "77",
    numeric2: "77",
    numeric3: "77",
  },
  Supernumerary_p28: {
    id: "Supernumerary_p28",
    code: 81,
    position: "p28",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant5",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "78",
    numeric2: "78",
    numeric3: "78",
  },
  Supernumerary_p29: {
    id: "Supernumerary_p29",
    code: 82,
    position: "p29",
    generation: "Supernumerary",
    rootCount: 1,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Bicuspid",
    numeric1: "79",
    numeric2: "79",
    numeric3: "79",
  },
  Supernumerary_p30: {
    id: "Supernumerary_p30",
    code: 83,
    position: "p30",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "80",
    numeric2: "80",
    numeric3: "80",
  },
  Supernumerary_p31: {
    id: "Supernumerary_p31",
    code: 84,
    position: "p31",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "81",
    numeric2: "81",
    numeric3: "81",
  },
  Supernumerary_p32: {
    id: "Supernumerary_p32",
    code: 85,
    position: "p32",
    generation: "Supernumerary",
    rootCount: 3,
    quadrant: "LowerRight",
    sextant: "Sextant6",
    mouth: "Lower",
    toothType: "Molar",
    numeric1: "82",
    numeric2: "82",
    numeric3: "82",
  },
};

export default ToothDetailsType;
