import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { FormikProvider, useFormik } from "formik";
import {
  getClinicMembers,
  getLabOrderFormData,
  initLabOrderList,
  pushStack,
  setLabOrderFilterForm,
} from "../../../actions/orderActions";
import { SelectField } from "../../form/SelectField";
import { TextInput } from "../../ui/TextInput";
import Utils from "../../../helpers/Utils";
import { DateField } from "../../form/DateField";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../../helpers/DateUtils";
import { orderStackCodes } from "../../../constants/chart-sidebar/orderStackCodes";
import orderItemTypes from "../../../constants/chart-sidebar/orderItemTypes";
import medicalFillInFormItemTypes from "../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { medicalFillInFormStackCodes } from "../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import Show from "../../widgets/Show";
import { last } from "lodash";

const updateFilterSearch = Utils.debounce((action) => {
  action();
}, 400);

const enhancer = connect(
  ({
    chartSideOrder,
    chart: {
      patient: { patientKey },
    },
    auth: { clinics },
    session: { clinic, member },
    chartSideMedicalFillInForm: { stack },
  }) => ({ chartSideOrder, clinics, patientKey, clinic, member, stack }),
);
const LabOrderSearchForm = ({
  chartSideOrder,
  patientKey,
  clinics,
  member,
  clinic,
  stack,
  query,
}) => {
  const dispatch = useDispatch();
  const { labOrderFilterForm, labOrderFormData, clinicMembers } = chartSideOrder;
  const { statusList, orderTypesList } = labOrderFormData;
  const stackItem = last(stack);
  const form = useFormik({
    initialValues: {
      ...labOrderFilterForm,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const transfer = {
        patientKey,
        id: values.id,
        clinicId: values.clinic?.value,
        doctorId: values.doctor?.value === -1 ? null : values.doctor?.value,
        doctorStaffId: values.doctorStaff?.value === -1 ? null : values.doctorStaff?.value,
        statusCode: values.status?.value === -1 ? null : values.status?.value,
        subTypeId: values.orderType?.value === -1 ? null : values.orderType?.value,
        startDate: getCompanyStartOfDay(values.startDate),
        endDate: getCompanyEndOfDay(values.endDate),
        filterKey: values.filterKey,
        dateTypeCode: "CREATED_DATE",
      };
      dispatch(initLabOrderList(transfer));
    },
  });
  const { values } = form;

  useEffect(() => {
    dispatch(getLabOrderFormData());
    form.setValues({
      ...values,
      clinic: { value: clinic.id, label: clinic.name },
      doctor: { value: member.id, label: member.name },
      doctorStaff: { value: member.id, label: member.name },
      startDate: getCompanyStartOfDay(),
      endDate: getCompanyEndOfDay(),
    });
    dispatch(
      setLabOrderFilterForm({
        ...values,
        clinic: { value: clinic.id, label: clinic.name },
        doctor: { value: member.id, label: member.name },
        doctorStaff: { value: member.id, label: member.name },
        startDate: getCompanyStartOfDay(),
        endDate: getCompanyEndOfDay(),
      }),
    );
    return () => {
      //clear form
    };
  }, []);

  useEffect(() => {
    if (values.clinic?.value) {
      dispatch(getClinicMembers(values.clinic.value));
    }
  }, [values.clinic]);

  useEffect(() => {
    updateFilterSearch(() => {
      form.submitForm();
    });
  }, [labOrderFilterForm]);

  const createHandler = () => {
    dispatch(pushStack({ code: orderStackCodes.item, type: orderItemTypes.new }));
  };

  const pleaseSelectOption = { value: -1, label: "please select" };

  const clinicOptions = (clinics || []).map((clinic) => ({
    value: clinic.id,
    label: clinic.name,
  }));

  const memberOptions = (clinicMembers || []).map((member) => ({
    value: member.id,
    label: member.name,
  }));

  memberOptions.unshift(pleaseSelectOption);

  const statusOptions = (statusList || []).map((status) => ({
    value: status.code,
    label: status.name,
  }));

  statusOptions.unshift(pleaseSelectOption);

  const orderTypeOptions = (orderTypesList || []).map((orderType) => ({
    value: orderType.id,
    label: orderType.name,
  }));

  orderTypeOptions.unshift(pleaseSelectOption);

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit} className="p-2">
        <div className="row">
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Order Id:
              <TextInput
                name="id"
                value={values?.id}
                onChange={({ target: { value } }) => dispatch(setLabOrderFilterForm({ id: value }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Clinic-branch:
              <SelectField
                name="clinic"
                multi={false}
                clearable={false}
                searchable={false}
                options={clinicOptions}
                value={values?.clinic}
                onChange={(v) => dispatch(setLabOrderFilterForm({ clinic: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Ordering Doctor:
              <SelectField
                name="doctor"
                multi={false}
                clearable={false}
                searchable={false}
                options={memberOptions}
                value={values?.doctor}
                onChange={(v) => dispatch(setLabOrderFilterForm({ doctor: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Conducting Doctor-Staff:
              <SelectField
                name="doctorStaff"
                multi={false}
                clearable={false}
                searchable={false}
                options={memberOptions}
                value={values?.doctorStaff}
                onChange={(v) => dispatch(setLabOrderFilterForm({ doctorStaff: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Other Status:
              <SelectField
                name="status"
                multi={false}
                clearable={false}
                searchable={false}
                options={statusOptions}
                value={values?.status}
                onChange={(v) => dispatch(setLabOrderFilterForm({ status: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Order Type:
              <SelectField
                name="orderType"
                multi={false}
                clearable={false}
                searchable={false}
                options={orderTypeOptions}
                value={values?.orderType}
                onChange={(v) => dispatch(setLabOrderFilterForm({ orderType: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              From:
              <DateField
                name="startDate"
                value={values?.startDate}
                onChange={(v) => dispatch(setLabOrderFilterForm({ startDate: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              To:
              <DateField
                name="endDate"
                value={values?.endDate}
                onChange={(v) => dispatch(setLabOrderFilterForm({ endDate: v }))}
              />
            </label>
          </div>
          <div className="col-sm-2">
            <label className="w-100 mb-3">
              Search:
              <TextInput
                name="filterKey"
                value={values?.filterKey}
                onChange={({ target: { value } }) => {
                  dispatch(setLabOrderFilterForm({ filterKey: value }));
                }}
              />
            </label>
          </div>
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <Show if={query?.medicalType !== medicalFillInFormItemTypes.view}>
            <div className="col-sm-2 mb-3 d-flex flex-column align-items-end">
              &nbsp;
              <button type="button" onClick={createHandler} className="btn btn-primary btn-block">
                Create Lab order
              </button>
            </div>
          </Show>
        </div>
      </form>
    </FormikProvider>
  );
};

export default enhancer(LabOrderSearchForm);
