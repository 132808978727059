import React from "react";
import { useDispatch } from "react-redux";

import { Modal } from "../ui/Modal";
import { deleteDevice, deleteDeviceConfirmModalHide } from "../../actions/authActions";

export default function MemberRemoveDeviceConfirmModal({ show }) {
  const dispatch = useDispatch();

  if (!show) {
    return null;
  }

  return (
    <Modal
      size="sm"
      show={true}
      keyboard={false}
      animation={false}
      onHide={() => dispatch(deleteDeviceConfirmModalHide())}
      actions={
        <>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => dispatch(deleteDeviceConfirmModalHide())}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-danger" onClick={() => dispatch(deleteDevice())}>
            Delete
          </button>
        </>
      }
    >
      Are you sure you want to remove this device?
    </Modal>
  );
}
