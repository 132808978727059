import React, { useState } from "react";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import FlexBox from "../../../ui/FlexBox";
import deleteImage from "../../../../assets/images/chart/delete.png";
import copyImage from "../../../../assets/images/content-copy.png";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../../actions/loaderActions";
import { openSimpleModal } from "../../../../actions/simpleModalActions";

export default function ChartClinicalNoteOwnedItem(props) {
  const {
    item,
    copyItem,
    openItem,
    deleteItem,
    withAddendum,
    toggleFavourite,
    onAddendumClick,
    onReferenceClick,
    clinicalNoteActiveTab,
    favouritesClinicalNote,
  } = props;

  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const {
    session: { permissions },
  } = useSelector((state) => state);
  (function (imageName) {
    import(
      `../../../../assets/images/chart/favorite${imageName ? "_active" : ""}.png`
    ).then((image) => setImage(image.default));
  })(favouritesClinicalNote[item.id]);

  const deletedNote = clinicalNoteActiveTab.code === "voidedNotes";

  const canDuplicate = permissions.find((item) => item === "DUPLICATE_CLINIC_NOTE_ITEM");
  const permissionAddendum = permissions.find((item) => item === "ADD_CLINIC_NOTE_ITEM_ADDENDUM");

  return (
    <FlexBox
      container={8}
      direction="column"
      onClick={openItem}
      className="btn btn-default btn-block chart-new-clinical-note-item"
    >
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} align="center">
          <span className="font-weight-bold text-ellipsis">{item.name}</span>
        </FlexBox>
        {!deletedNote && !onReferenceClick && withAddendum && (
          <FlexBox
            align="center"
            className="font-weight-bold mr-2 btn btn-default"
            onClick={(event) => {
              event.stopPropagation();
              if (permissionAddendum) {
                onAddendumClick();
              } else {
                dispatch(showMessage("permission denied"));
              }
            }}
          >
            Addendum
          </FlexBox>
        )}
        {deletedNote && (
          <FlexBox
            align="center"
            className="font-weight-bold mr-2 btn btn-default"
            onClick={(event) => {
              event.stopPropagation();

              deleteItem(item, true);
            }}
          >
            Restore
          </FlexBox>
        )}
        {/*{!deletedNote && isDuplicate && (*/}
        <FlexBox
          align="center btn btn-default mr-2"
          onClick={(event) => {
            event.stopPropagation();
            if (canDuplicate) {
              copyItem();
            } else {
              dispatch(openSimpleModal({ body: "Permission denied" }));
            }
          }}
        >
          <div className="clinical-note-item-copy">
            <img className="img-responsive" src={copyImage} alt="" />
          </div>
        </FlexBox>
        {/*)}*/}
        {!deletedNote && (
          <FlexBox
            align="center btn btn-default"
            onClick={(event) => {
              event.stopPropagation();

              deleteItem(item);
            }}
          >
            <div className="clinical-note-item-delete">
              <img className="img-responsive" src={deleteImage} alt="" />
            </div>
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox gutter={8} flex="shrink">
        <FlexBox flex={true}>
          <FlexBox direction="column">
            <FlexBox flex={true}>
              <span className="font-weight-bold text-ellipsis clinical-note-item-subtitle">
                by
                {item.owner && item.owner.name}
              </span>
            </FlexBox>
            <FlexBox flex={true}>
              <span className="font-weight-bold text-ellipsis clinical-note-item-subtitle">
                {Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.createdDate),
                  DATE_FORMAT_LEGACY,
                )}
              </span>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {!deletedNote && onReferenceClick && (
          <FlexBox
            align="center"
            onClick={(event) => {
              event.stopPropagation();

              onReferenceClick();
            }}
            className="text-info pr-2"
          >
            Reference Note
          </FlexBox>
        )}
        {!deletedNote && (
          <FlexBox
            align="center"
            onClick={(event) => {
              event.stopPropagation();

              toggleFavourite();
            }}
          >
            {image && <img alt="" className="img-responsive" src={image} />}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}
