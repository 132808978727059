import React from "react";
import { makeStyles } from "@material-ui/styles";
import ReactSpinner from "react-spinjs-fix";

import ClinicLogoImage from "../../assets/images/clinic_logo@2x.png";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spin: {
    position: "absolute",
    top: "200px",
    right: "50%",
    light: "50%",
  },
}));

export function AppFallback() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.spin}>
        <ReactSpinner />
      </div>
      <img src={ClinicLogoImage} alt="logo" />
    </div>
  );
}
