import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/insuranceActionTypes";

const defaultState = {
  list: [],
  total: 0,
  item: [],
  status: {},
  markAsPrimary: {},
  searchResult: [],
  choosePhotoModalVisible: false,
  photoSidebarImageIndex: "image1",
  photoSidebarVisible: false,
  selectedForm: "",
  selectedImage: "",
  deletedImage: "",
  uploadImage: {},
  insurancePlanItems: {},
  showEditDialog: "",
  deductibleTypes: [],
  images: {},
  fields: {
    alias: [],
    imbursementList: [],
    company: [],
    companyPlan: [],
    relation: [],
    type: [],
    broker: [],
    selfPayer: [],
  },
  paymentOptions: {
    insuranceCompanyCount: 0,
    insuranceCoverageLimitReached: false,
  },
};

const reducers = {
  [ActionType.INSURANCE_SHOW_EDIT_DIALOG](state, { showEditDialog }) {
    return {
      ...state,
      showEditDialog,
    };
  },
  [ActionType.INSURANCE_HIDE_EDIT_DIALOG](state) {
    return {
      ...state,
      showEditDialog: "",
    };
  },
  [ActionType.INSURANCE_GET_ITEM_SUCCESS](state, { data }) {
    const values = {
      ...data,
      planRows: data.planRows?.map((item) => ({
        ...item,
        deductibleType: { value: item?.deductibleType?.id, label: item?.deductibleType?.name },
      })),
    };
    return {
      ...state,
      insurancePlanItems: values || {},
    };
  },
  [ActionType.INSURANCE_GET_DEDUCTIBLE_TYPES_SUCCESS](state, { data }) {
    return {
      ...state,
      deductibleTypes: data || [],
    };
  },
  [ActionType.INSURANCE_PHOTO_SIDEBAR_SET_IMAGE](state, { index }) {
    return {
      ...state,
      photoSidebarImageIndex: index,
    };
  },
  [ActionType.INSURANCE_PHOTO_SIDEBAR_HIDE](state) {
    return {
      ...state,
      photoSidebarVisible: false,
      photoSidebarImageIndex: "image1",
    };
  },
  [ActionType.INSURANCE_PHOTO_SIDEBAR_SHOW](state, { index }) {
    return {
      ...state,
      photoSidebarVisible: true,
      photoSidebarImageIndex: index,
    };
  },
  [ActionType.GET_INSURANCE_LIST_START](state) {
    return { ...state, list: [], total: 0 };
  },
  [ActionType.GET_INSURANCE_LIST_SUCCESS](state, { data }) {
    const { list } = data;
    const images = {};

    if (list) {
      let formName = "";

      list.forEach((element, index) => {
        formName = index == 0 ? "form1" : "form2";

        if (!images[formName]) images[formName] = {};

        images[formName].image1 = element.documentScreen ? element.documentScreen : {};
        images[formName].image2 = element.documentScreen1 ? element.documentScreen1 : {};
        images[formName].image3 = element.documentScreen2 ? element.documentScreen2 : {};
      });
    }

    return {
      ...state,
      list: data ? data.list : [],
      total: data ? data.totalCount : 0,
      images,
    };
  },
  [ActionType.SET_INSURANCE_LIST](state, { data }) {
    return { ...state, list: data };
  },
  [ActionType.DELETE_INSURANCE_ITEM_SUCCESS](state, { data }) {
    return { ...state, status: data };
  },
  [ActionType.GET_INSURANCE_ITEM_SUCCESS](state, { data }) {
    return { ...state, item: data };
  },
  [ActionType.SAVE_INSURANCE_ITEM_SUCCESS](state, { data }) {
    let index = 0;
    const newList = [...(state.list || [])];

    if (data) {
      for (const i in newList) {
        if (newList[i].id == data.id) {
          index = i;
          break;
        }
      }

      newList[index] = data;
    }
    return {
      ...state,
      item: data,
      list: newList,
    };
  },
  [ActionType.INSURANCE_GET_SELF_PAYER_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        selfPayer: data,
      },
    };
  },
  [ActionType.GET_INSURANCE_ALIAS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        alias: data.list || [],
      },
    };
  },
  [ActionType.GET_RE_IMBURSEMENT_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        imbursementList: data.list || [],
      },
    };
  },
  [ActionType.GET_INSURANCE_COMPANY_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        company: data,
      },
    };
  },
  [ActionType.GET_INSURANCE_COMPANY_PLAN_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        companyPlan: data,
      },
    };
  },
  [ActionType.GET_INSURANCE_RELATION_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        relation: data,
      },
    };
  },
  [ActionType.GET_INSURANCE_BROKER_SUCCESS](state, { data }) {
    return {
      ...state,
      fields: {
        ...state.fields,
        broker: data,
      },
    };
  },
  [ActionType.SEARCH_BY_CARD_NUMBER_SUCCESS](state, { data }) {
    return { ...state, item: data };
  },
  [ActionType.MARK_AS_PRIMARY_INSURANCE_SUCCESS](state, { data }) {
    return { ...state, markAsPrimary: data };
  },
  [ActionType.GET_PATIENT_PAYMENT_OPTIONS_SUCCESS](state, { data }) {
    return { ...state, paymentOptions: data };
  },
  [ActionType.INSURANCE_UPLOAD_PHOTO_START](state) {
    return {
      ...state,
      photo: {},
    };
  },
  [ActionType.INSURANCE_UPLOAD_PHOTO_SUCCESS](state, { data }) {
    const newState = { ...state.images };

    const formName = state.selectedForm; //= == 'form1' && 'form2' || 'form1';

    // eslint-disable-next-line no-prototype-builtins
    if (!newState.hasOwnProperty(formName)) {
      newState[formName] = {
        image1: {},
        image2: {},
        image3: {},
      };
    }

    if (state.selectedImage && state.selectedImage != "") {
      newState[formName][state.selectedImage] = data;
    } else {
      for (const key in newState[formName]) {
        // eslint-disable-next-line no-prototype-builtins
        if (!newState[formName][key] || !newState[formName][key].hasOwnProperty("url")) {
          newState[formName][key] = data;
          break;
        }
      }
    }

    return {
      ...state,
      images: newState,
      uploadImage: data,
    };
  },
  // --------------------------------------------
  [ActionType.INSURANCE_IMAGE_HIDE_CHOOSE_PHOTO_MODAL](state) {
    return {
      ...state,
      choosePhotoModalVisible: false,
    };
  },
  [ActionType.INSURANCE_IMAGE_SHOW_CHOOSE_PHOTO_MODAL](state, { form }) {
    return {
      ...state,
      choosePhotoModalVisible: true,
      selectedForm: form,
    };
  },
  [ActionType.INSURANCE_IMAGE_SELECT_IMAGE](state, { image }) {
    return {
      ...state,
      selectedImage: image,
    };
  },
  [ActionType.INSURANCE_IMAGE_DELETE_IMAGE](state, { image }) {
    const newState = { ...state.images };

    const formName = state.selectedForm; // === 'form1' && 'form2' || 'form1';

    if (image && image != "") {
      newState[formName][image] = {};
    }

    return {
      ...state,
      images: newState,
      deletedImage: image,
    };
  },
  // --------------------------------------------
};

export default createReducer(defaultState, reducers);
