import $ from "jquery";
import React from "react";

import { SELECTED, TEETH_TYPES } from "../../../constants/Constants";
import ToothForMouthImage from "../../../assets/images/teeth/tooth_for_mouth_small.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

export default class SelectQuadrantSides extends React.Component {
  selectItem = (e) => {
    const { typeTab, isEdit, addTransaction, seriesActions } = this.props;

    if (isEdit) {
      return;
    }

    let $elem = $(e.target);
    const selectedId = $elem.attr("id");

    addTransaction.tooth = null;

    const key = selectedId.replace(/Btn/g, "");

    let type = TEETH_TYPES.QuadrantType[key];

    if (type) {
      seriesActions.changeTransactionByKey(type, "areaDetails", null, typeTab);
    }
  };

  toggleActiveClassName = (name) => {
    let findItem;
    const { addTransaction } = this.props;

    if (addTransaction && addTransaction.areaDetails) {
      findItem = addTransaction.areaDetails.filter((item) => name === item.code);
    }

    return findItem && findItem.length > 0 ? SELECTED : "";
  };

  render() {
    const { className } = this.props;

    return (
      <div className={`mouth-shape-wrap ${className}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="260" height="260" id="svg3154">
          <g id="layer5" className="layer-selectors">
            <rect
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="129.8107"
              y="0.34169236"
              id="UpperLeftSelector"
              className={`rect-selector ${this.toggleActiveClassName("UpperLeft")}`}
            />
            <rect
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="129.72557"
              y="129.55074"
              id="LowerLeftSelector"
              className={`rect-selector ${this.toggleActiveClassName("LowerLeft")}`}
            />
            <rect
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="0.36225733"
              y="0.34899417"
              id="UpperRightSelector"
              className={`rect-selector ${this.toggleActiveClassName("UpperRight")}`}
            />
            <rect
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="0.27712294"
              y="129.55804"
              id="LowerRightSelector"
              className={`rect-selector ${this.toggleActiveClassName("LowerRight")}`}
            />
          </g>
          <g id="layer4" className="layer-text">
            <text x="3.402576" y="15.318153" id="text3126">
              <tspan x="3.402576" y="15.318153" id="tspan3128">
                Upper Right
              </tspan>
            </text>
            <text x="4.9097319" y="248.6411" id="text3126-3">
              <tspan x="4.9097319" y="248.6411" id="tspan3128-9">
                Lower Right
              </tspan>
            </text>
            <text x="175.88533" y="15.545599" id="text3126-4">
              <tspan x="175.88533" y="15.545599" id="tspan3128-2">
                Upper Left
              </tspan>
            </text>
            <text x="175.46922" y="248.39015" id="text3126-4-6">
              <tspan x="175.46922" y="248.39015" id="tspan3128-2-4">
                Lower Left
              </tspan>
            </text>
          </g>
          <g id="layer6" className="layer-image">
            <image
              xlinkHref={obtainImagePath(ToothForMouthImage)}
              x="58.306755"
              y="5.4951348"
              width="141.6861"
              height="239.35324"
              id="image4004"
              className="teeth-image"
            />
          </g>
          <g id="layer7" className="layer-buttons">
            <rect
              onClick={this.selectItem}
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="129.62465"
              y="0.38241616"
              id="UpperLeft"
              className="rect"
            />
            <rect
              onClick={this.selectItem}
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="129.53952"
              y="129.59146"
              id="LowerLeft"
              className="rect"
            />
            <rect
              onClick={this.selectItem}
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="0.17620791"
              y="0.38972023"
              id="UpperRight"
              className="rect"
            />
            <rect
              onClick={this.selectItem}
              width="127.48731"
              height="127.24039"
              rx="5.1019168"
              ry="5.0723753"
              x="0.091072924"
              y="129.59877"
              id="LowerRight"
              className="rect"
            />
          </g>
        </svg>
      </div>
    );
  }
}
