import _ from "lodash";
import $ from "jquery";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Show from "./../widgets/Show";
import WaitingListForm from "./WaitingListForm";
import Header from "./../layout/DefaultPageHeader";
import { WaitingListCell } from "./WaitingListCell";
import WaitingListRowHeader from "./WaitingListRowHeader";
import { doctorSelect } from "../../actions/followUpActions";
import { AppointmentNoteModal } from "../calendar/AppointmentNoteModal";
import { getAppointmentType, getWaitingList } from "../../actions/waitingListActions";

const enhancer = connect(
  ({
    auth: { members },
    waitingList: { list, fromDate, toDate, doctor, doctors },
    reminders: { categoryList, remindersFilterDropdownVisible },
  }) => {
    return {
      list,
      fromDate,
      toDate,
      doctor,
      members,
      categoryList,
      remindersFilterDropdownVisible,
      doctors,
    };
  },
  { getWaitingList, getAppointmentType, doctorSelect },
);
class WaitingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: "",
      showNoteModal: false,
    };
  }

  handleSubmit = (values) => {
    this.props.getWaitingList(values);
  };

  onScroll = (event) => {
    event.preventDefault();

    const $title = $(".waiting-list-header.header");
    $title.css({ marginLeft: -event.target.scrollLeft + "px" });
  };

  componentDidMount() {
    this.props.getWaitingList();
    this.props.getAppointmentType();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search: query },
      doctorSelect,
    } = this.props;
    const {
      location: { search: nextQuery },
    } = nextProps;

    if (
      query.doctorId !== nextQuery.doctorId &&
      query.doctorId &&
      query.doctorId.search(/^[0-9]+$/g) > -1
    ) {
      doctorSelect(_.toFinite(query.doctor));
    }
  }

  render() {
    const {
      fromDate,
      toDate,
      doctor,
      members,
      list,
      categoryList,
      remindersFilterDropdownVisible,
      doctors,
    } = this.props;
    const { showNoteModal, note } = this.state;

    const content = [];

    if (list.length > 0) {
      const appointments = _.sortBy(list, ["date"]);
      let currentDate, lastDate;

      appointments.forEach((element, index, array) => {
        lastDate = array[index - 1] ? array[index - 1].date : 0;
        currentDate = element.date;

        content.push(
          <div key={index} className="waiting-list-row">
            <Show if={currentDate !== lastDate}>
              <WaitingListRowHeader date={currentDate} />
            </Show>
            <WaitingListCell
              element={element}
              onNoteClick={(x) => this.setState({ showNoteModal: true, note: x })}
            />
          </div>,
        );
      });
    }

    return (
      <div className="waiting-list-page">
        <Header addClass="waiting-list-header" text="WAITING LIST">
          <WaitingListForm
            onSubmit={this.handleSubmit}
            fromDate={fromDate}
            toDate={toDate}
            doctor={doctor}
            doctors={doctors}
            members={members}
            categoryList={categoryList}
            remindersFilterDropdownVisible={remindersFilterDropdownVisible}
          />
        </Header>
        <div className="waiting-list-header-wrapper">
          <div className="waiting-list-header header">
            <div>{localStorage.getItem("doctorLabel")} Requested</div>
            <div>Waiting | Requested to reschedule</div>
            <div>Referral</div>
          </div>
        </div>
        <div className="waiting-list-body" onScroll={this.onScroll}>
          {content}
        </div>
        <AppointmentNoteModal
          note={note}
          show={showNoteModal}
          onClose={() => this.setState({ showNoteModal: false, note: "" })}
        />
      </div>
    );
  }
}
export default withRouter(enhancer(WaitingList));
