import { startLoader, stopLoader } from "./loaderActions";

import * as ActionType from "../actionTypes/patientWhMessagesActionTypes";
import WhatsAppChattingApi from "../api-legacy/WhatsappChatting";

export function getPatientWhatsAppMessagesList(params) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(
      getPatientWhatsAppMessages(params)
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(stopLoader(error));
      })
  }
}

export function sendPatientWhatsAppMessage(params) {
  return (dispatch) => {
    dispatch(startLoader());

    const fn = {
      api: WhatsAppChattingApi.sendPatientWhatsappMessages,
      types: [
        ActionType.WHATSAPP_CHATTING_PATIENT_SEND_MESSAGE_GET_START,
        ActionType.WHATSAPP_CHATTING_PATIENT_SEND_MESSAGE_GET_SUCCESS,
        ActionType.WHATSAPP_CHATTING_PATIENT_SEND_MESSAGE_GET_ERROR,
      ],
      data: params,
    }

    return dispatch(fn)
      .then(() => dispatch(stopLoader()))
      .catch((error) => {
        dispatch(stopLoader(error));
      })
  }
}

// export function openWhatsAppChat() {
//   return {
//     type: ActionType.WHATSAPP_CHATTING_PATIENT_LIST_SHOW_SIDEBAR,
//   }
// }

export function resetPatientWhatsappMessages() {
  return {
    type: ActionType.WHATSAPP_CHATTING_RESET_PATIENT_MESSAGES,
  }
}

function getPatientWhatsAppMessages(data) {
  return {
    api: WhatsAppChattingApi.getPatientWhatsappMessages,
    types: [
      ActionType.WHATSAPP_CHATTING_PATIENT_MESSAGES_GET_START,
      ActionType.WHATSAPP_CHATTING_PATIENT_MESSAGES_GET_SUCCESS,
      ActionType.WHATSAPP_CHATTING_PATIENT_MESSAGES_GET_ERROR,
    ],
    data,
  };
}