import React from "react";
import { connect } from "react-redux";

import Show from "../../../widgets/Show";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";
import * as chartSideSeriesActions from "../../../../actions/chartSideSeriesActions";

const enhancer = connect(
  null,
  bindActions({ chartSideTransactionActions, chartSideSeriesActions }),
);
export default enhancer(
  class ChartBreakToVisit extends React.Component {
    render() {
      const { transactionItem } = this.props.chartSideTransaction;

      let editable = true;

      const $parts = transactionItem.parts.map((item, index) => {
        if (item.status && item.status.code === "DentalTransactionStatus_COMPLETED")
          editable = false;

        const onChange = (event) => {
          if (index === 0) {
            this.props.chartSideTransactionActions.changeInitial(event.target.value);
          }

          this.props.chartSideTransactionActions.changePrice(event.target.value, index);
        };

        return (
          <div className="parts-item" key={index}>
            <div className="name">
              <strong>Visit {item.number}</strong>
            </div>
            <div className="meta">
              <div className="fee">
                <label htmlFor="price" className="form-control-static text-right">
                  Fee
                </label>
                <input
                  type="number"
                  id="initial"
                  className="form-control"
                  value={+transactionItem.parts[index].price}
                  disabled={!editable}
                  onChange={onChange}
                />
              </div>
              <div className="description">Description</div>
            </div>
            <div className="textarea">
              <textarea
                rows="4"
                ref={"comment" + item.number}
                className="form-control"
                defaultValue={item.comment}
                onBlur={({ target }) => {
                  transactionItem.parts.forEach((item) => {
                    item.comment = target.value;
                  });
                }}
              />
            </div>
          </div>
        );
      });

      return (
        <div className="chart-clinical-note">
          <div className="chart-break-to-visit-body">
            <div className="info">
              <div>{transactionItem.treatmentItem.code}</div>
              <div>
                <strong>{transactionItem.treatmentItem.description}</strong>
              </div>
              <div>Fee</div>
              <div>
                <strong>$ {transactionItem.price}</strong>
              </div>
            </div>
            <div className="payment">
              <label htmlFor="initial" className="form-control-static text-right">
                Initial Payment:
              </label>
              <input
                type="number"
                id="initial"
                className="form-control"
                value={+transactionItem.parts[0].price}
                disabled={!editable}
                onChange={(e) =>
                  this.props.chartSideTransactionActions.changeInitial(e.target.value, 0)
                }
              />
            </div>
            <div className="parts-count">
              <Show if={editable}>
                <button
                  className="btn btn-default"
                  onClick={this.props.chartSideTransactionActions.popParts}
                >
                  ↓
                </button>
              </Show>
              <div className="form-control">{$parts.length}</div>
              <Show if={editable}>
                <button
                  className="btn btn-default"
                  onClick={this.props.chartSideTransactionActions.pushParts}
                >
                  ↑
                </button>
              </Show>
            </div>
            {$parts}
          </div>
        </div>
      );
    }
  },
);
