import _ from "lodash";

import TreatmentApi from "../api-legacy/TreatmentApi";
import TransactionApi from "../api-legacy/TransactionApi";
import CompanyApi from "../api-legacy/CompanyApi";
import SpecialityApi from "../api-legacy/SpecialityApi";
import DiagnosisApi from "../api-legacy/DiagnosisApi";
import ProcedureApi from "../api-legacy/ProcedureApi";
import PatientApi from "../api-legacy/PatientApi";
import LabOrderApi from "../api-legacy/LabOrderApi";

import seriesTypes from "../constants/chart-sidebar/seriesTypes";
import { seriesStackCodes } from "../constants/chart-sidebar/seriesStackCodes";

import Element from "../models/Element";

import Utils from "../helpers/Utils";
import * as SeriesUtils from "../helpers/chartSideSeriesUtils";
import { selectCategories } from "../helpers/chartSideSeriesUtils";

import { showMessage, startLoader, stopLoader } from "./loaderActions";
import { setPopupActionByKey, setPopupParams } from "./popupActions";
import {
  getAppointmentTransactions,
  sendToPreApprovalFn,
  setHoldPreApproval,
} from "./transactionActions";
import { initRequestList } from "./appointmentActions";
import treatmentAreas from "../constants/chart-sidebar/treatmentAreas";
import * as ActionType from "../actionTypes/chartSideSeriesActionTypes";
import * as chartAction from "./chartActions";
import { getTeeth, hideSideBar } from "./chartActions";
import { paymentInsuranceFn } from "./patientActions";
import * as simpleModalActions from "./simpleModalActions";
import { medicalFillInFormStackCodes } from "../constants/chart-sidebar/medicalFIllInFormStackCodes";
import {
  TransactionStatusSelectItem,
  TransactionTypeSelectItem,
} from "../constants/TransactionTypes";
import { SidebarTypes } from "../components/chart-treatments-sidebar/TypeButtonGroup";

export function fetchingProcedureItem(_bool) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCHING_PROCEDURE_ITEM,
      _bool,
    });
  };
}

export function getPackageGroupDetails(data) {
  return (dispatch, getState) => {
    dispatch({
      type: "getPackageGroupDetails",
      data,
    });
    const { chart } = getState();
    const transfer = {
      id: data?.id,
      patientKey: chart?.patient?.patientKey,
    };

    return dispatch(getTreatmentProcedureItemApi(transfer)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function getTreatmentItemPrice(data) {
  return (dispatch) => {
    dispatch({
      type: ActionType.GET_TREATMENT_CODE_ITEM,
      data,
    });
    const transfer = {
      id: data?.item?.id,
      patientKey: data?.patient?.patientKey,
    };

    return dispatch(getTreatmentItemPriceApi(transfer)).catch((error) =>
      dispatch(showMessage(error)),
    );
  };
}

export function clearAddedDiagnosisItems() {
  return {
    type: ActionType.CLEAR_ADDED_ITEMS,
  };
}

function recursiveTreatments(start, totalCount) {
  return (dispatch) => {
    const nextStart = start >= 0 ? start + 50 : 0;

    if (totalCount && totalCount < nextStart) {
      return;
    }

    const data = {
      limit: 50,
      start: nextStart,
    };

    return dispatch(getAllTreatmentsApi(data)).then(({ totalCount }) =>
      dispatch(recursiveTreatments(nextStart, totalCount)),
    );
  };
}

export function getAllTreatments() {
  return (dispatch) =>
    dispatch(recursiveTreatments()).catch((error) => dispatch(showMessage(error)));
}

export function clearDiagnosisItems() {
  return {
    type: ActionType.CLEAR_DIAGNOSIS_ITEMS,
  };
}

export function fetchTreatmentCodes(filterKey) {
  return (dispatch) => dispatch(getAllTreatmentsApi({ filterKey }));
}

function recursiveDiagnosis(start, totalCount) {
  return (dispatch) => {
    const nextStart = start >= 0 ? start + 25 : 0;

    if (totalCount && totalCount < nextStart) {
      return;
    }

    const data = {
      limit: 50,
      start: nextStart,
    };

    return dispatch(getAllDiagnosisApi(data)).then(({ totalCount }) =>
      dispatch(recursiveDiagnosis(nextStart, totalCount)),
    );
  };
}

export function getAllDiagnosis() {
  return (dispatch) =>
    dispatch(recursiveDiagnosis()).catch((error) => dispatch(showMessage(error)));
}

export function getCategoryList(parentId) {
  return (dispatch, getState) => {
    const {
      chartSideSeries: { searchValue },
    } = getState();

    const id = _.toFinite(parentId);

    const data = {
      start: 0,
      limit: 50,
      filterKey: searchValue,
      parentId: id > 0 ? id : null,
      onlyRoot: id === 0 ? true : null,
    };

    return dispatch(getCategoryListApi(data)).catch((error) => dispatch(stopLoader(error)));
  };
}

export function getGroupProcedureList(typeCode, categoryId, subCategoryId) {
  return (dispatch, getState) => {
    const {
      chartSideSeries: { searchValue },
    } = getState();

    const data = {
      typeCode,
      start: 0,
      limit: 50,
      categoryId,
      subCategoryId,
      filterKey: searchValue,
    };

    return dispatch(getProcedureListApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadGroupProcedureList(typeCode, categoryId) {
  return (dispatch, getState) => {
    const {
      searchValue,
      subCategoryList,
      subCategoryListTotalCount,
      fetchingSubCategoryList,
    } = getState().chartSideSeries;
    if (subCategoryList.length === subCategoryListTotalCount || fetchingSubCategoryList) {
      return;
    }
    const data = {
      typeCode,
      start: subCategoryList.length,
      limit: 50,
      categoryId,
      filterKey: searchValue,
    };

    return dispatch(getProcedureListApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveProcedureItem(procedureItem) {
  return (dispatch, getState) => {
    const {
      chart: { teeth, patient, appointment, activeTooth },
    } = getState();

    dispatch(startLoader());

    const patientKey = _.get(patient, "patientKey", null);
    const appointmentId = _.get(appointment, "id", null);
    const toothDetails = _.get(teeth, `p${activeTooth}.normal.details`, {});
    const tooth = {
      id: _.get(toothDetails, "code"),
      code: _.get(toothDetails, "id"),
    };
    const procedureId = _.get(procedureItem, "id", null);
    const treatments = _.get(procedureItem, "treatments", []);
    const applyPackagePrice = true;

    const data = {
      tooth,
      patientKey,
      procedureId,
      appointmentId,
      applyPackagePrice,
    };

    if (!_.isEmpty(treatments)) {
      data.treatments = treatments;
    }
    return dispatch(saveProcedureItemApi(data))
      .then((response) => {
        dispatch(stopLoader());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveProcedureRequestItem(procedureId, inAppointment) {
  return (dispatch, getState) => {
    const {
      chart,
      appointment,
      chart: { activeTooth },
    } = getState();

    if (!activeTooth && !inAppointment) {
      return dispatch(showMessage("Tooth is required"));
    }

    dispatch(startLoader());

    const patientKey = _.get(appointment, "patient.patientKey", null);
    const appointmentId = _.get(appointment, "appointment.id", null);
    const toothDetails = _.get(chart, `teeth.p${activeTooth}.normal.details`, {});
    const tooth = {
      id: _.get(toothDetails, "code"),
      code: _.get(toothDetails, "id"),
    };

    const data = {
      tooth,
      patientKey,
      procedureId,
      appointmentId,
    };

    return dispatch(saveProcedureRequestItemApi(data))
      .then((response) => {
        dispatch(stopLoader());

        dispatch(initRequestList());

        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function sortFavourites(items) {
  return {
    type: ActionType.CHART_SIDE_SERIES_SORT_FAVOURITES,
    items,
  };
}

export function clearTransactioItem() {
  return {
    type: ActionType.CHART_SIDE_SERIES_CLEAR_TRANSACTION_ITEM,
  };
}

export function initSideSeries(transaction) {
  return {
    type: ActionType.INIT_SIDE_SERIES,
    transaction,
  };
}

export function isMixedCodes(isMixed) {
  return {
    type: ActionType.CHART_SIDE_SERIES_IS_MIXED_CODES,
    isMixed,
  };
}

export function changeSideBarMode(seriesMode) {
  return {
    type: ActionType.CHANGE_SIDE_BAR_SERIES_MODE,
    seriesMode,
  };
}

export function getMixidCodes() {
  return (dispatch, getState) => {
    const { searchValue, groupId } = getState().chartSideSeries;

    dispatch({
      type: ActionType.CHART_SIDE_SERIES_CLEAR_MIXED_CODES,
    });

    const data = {
      limit: 50,
      filterKey: searchValue,
      groupIds: groupId,
    };

    return Promise.all([
      dispatch(getTreatmentMixidList(data)),
      dispatch(getDiagnosisMixidList(data)),
    ]);
  };
}

export function loadMixidCodes() {
  return (dispatch, getState) => {
    const {
      searchValue,
      mListDiag,
      mListDiagTotalCount,
      mListTreat,
      fetchingList,
      mListTreatTotalCount,
    } = getState().chartSideSeries;

    const promises = [];

    if (mListDiag.length < mListDiagTotalCount && !fetchingList) {
      const data = {
        start: mListDiag.length,
        limit: 50,
        filterKey: searchValue,
      };
      promises.push(dispatch(getDiagnosisMixidList(data)));
    }

    if (mListTreat.length < mListTreatTotalCount && !fetchingList) {
      const data = {
        start: mListTreat.length,
        limit: 50,
        filterKey: searchValue,
      };
      promises.push(dispatch(getTreatmentMixidList(data)));
    }

    return Promise.all(promises);
  };
}

export function changeSideBarType(seriesType) {
  return {
    type: ActionType.CHANGE_SIDE_BAR_SERIES_TYPE,
    seriesType,
  };
}

function pushSeriesStack(seriesStackItem) {
  return {
    type: ActionType.PUSH_SERIES_STACK,
    seriesStackItem,
  };
}

export function openCategories() {
  return pushSeriesStack({
    code: "list",
  });
}

export function openFavorites() {
  return pushSeriesStack({
    code: seriesStackCodes.favourite,
  });
}

export function openAllCodes() {
  return pushSeriesStack({
    code: seriesStackCodes.list,
  });
}
export function deleteDiagnosisItem(key) {
  return {
    type: ActionType.REMOVE_DIAGNOSIS_ITEM,
    key,
  };
}

export function openDiagnosisTypeAhead(key) {
  return pushSeriesStack({
    code: seriesStackCodes.diagnosis,
    key,
  });
}

export function openAllCategory() {
  return pushSeriesStack({
    code: seriesStackCodes.category,
  });
}

export function openDiagnosisCategory() {
  return pushSeriesStack({
    code: seriesStackCodes.diagnosis,
  });
}

export function openBreakToVisit() {
  return pushSeriesStack({
    code: seriesStackCodes.breakToVisit,
  });
}

/**
 * if this category has children open them,
 * otherwise open list of this category
 *
 * @param [index] this index in parent array
 * @returns {*} action
 */
export function categoryClick(index, isGroup) {
  if (isNaN(index)) {
    throw new Error("send index");
  }

  return (dispatch, getState) => {
    const chartSideSeries = getState().chartSideSeries;
    const { seriesStack, seriesType, categoryList, subCategoryList } = chartSideSeries;

    const stackItem = _.last(seriesStack);
    const categories = selectCategories(chartSideSeries);

    let parent = {
      children: categories,
    };

    (stackItem.path || []).forEach((index) => {
      parent = parent.children[index];
    });

    const currentCategory = seriesType !== "procedure" ? parent.children[index] : {};

    if (seriesType === "procedure" && !isGroup) {
      const { id } = subCategoryList[index];

      return dispatch(
        pushSeriesStack({
          code: seriesStackCodes.categoryChild,
          subCategory: seriesType === "procedure" ? id : undefined,
          path: (stackItem.path || []).concat(index),
        }),
      );
    }

    if (seriesType !== "procedure" && currentCategory.children) {
      const { id } = categoryList[index];

      return dispatch(
        pushSeriesStack({
          code: seriesStackCodes.categoryChild,
          subCategory: seriesType === "procedure" ? id : undefined,
          path: (stackItem.path || []).concat(index),
        }),
      );
    }

    if (seriesType === "procedure") {
      const { id } = categoryList[index];

      const data = {
        start: 0,
        limit: 50,
        parentId: id,
      };

      return dispatch(getCategoryListApi(data)).then(() =>
        dispatch(
          pushSeriesStack({
            code: seriesStackCodes.list,
            subCategory: true,
          }),
        ),
      );
    }

    return dispatch(
      pushSeriesStack({
        code: seriesStackCodes.list,
        parent: currentCategory,
      }),
    );
  };
}

export function popSeriesStack() {
  return (dispatch, getState) => {
    const { seriesStack } = getState().chartSideSeries;

    if (seriesStack.length == 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_SERIES_STACK,
      });
    }
  };
}

export function getSidebarTreatmentList(packetId, activityTypeId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, groupId },
    } = getState();
    const promise = packetId
      ? dispatch(
          getTreatmentPacket({
            filterKey: searchValue,
            groupIds: groupId,
            packetId,
            activityTypeId,
            limit: 50,
          }),
        )
      : dispatch(
          getTreatmentList({
            groupIds: groupId,
            filterKey: searchValue,
            activityTypeId,
            limit: 50,
          }),
        );

    return promise
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function handleHideTabBar(bool) {
  return (dispatch) => {
    dispatch({
      type: ActionType.HIDE_TAB_BAR,
      bool,
    });
  };
}

export function clearSearchValues() {
  return (dispatch) => {
    dispatch({
      type: ActionType.CLEAR_SEARCH_VALUES,
    });
  };
}

export function getSidebarExistingList(packetId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, groupId },
    } = getState();

    const promise = packetId
      ? dispatch(
          getTreatmentPacket({
            filterKey: searchValue,
            groupIds: groupId,
            packetId,
            limit: 25,
          }),
        )
      : dispatch(
          getTreatmentList({
            filterKey: searchValue,
            groupIds: groupId,
            limit: 50,
          }),
        );

    return promise
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function getSidebarDiagnosisList() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue = "", groupId },
    } = getState();

    return dispatch(
      getDiagnosisList({
        filterKey: searchValue,
        groupIds: groupId,
        limit: 50,
      }),
    )
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function getSidebarProcedureList(subSpecialityId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, groupId },
    } = getState();

    return dispatch(
      getProcedureList({
        filterKey: searchValue,
        groupIds: groupId,
        limit: 25,
        subSpecialityId,
      }),
    )
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function getSidebarCategoryList(packetId = null) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, groupId },
    } = getState();

    return dispatch(
      getSidebarCategoryListApi({
        filterKey: searchValue,
        groupIds: groupId,
        limit: 50,
        packetId,
      }),
    )
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function loadSidebarCategoryList(packetId = null) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, sidebarCategoryList, sidebarCategoryListTotalCount, groupId },
    } = getState();

    if (sidebarCategoryList.length === sidebarCategoryListTotalCount) return;

    dispatch(startLoader());

    return dispatch(
      getSidebarCategoryListApi({
        filterKey: searchValue,
        groupIds: groupId,
        packetId,
        start: sidebarCategoryList.length,
        limit: 25,
      }),
    )
      .then(() => {
        dispatch(stopLoader());
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(stopLoader(error));
      });
  };
}

export function loadSidebarTreatmentList(packetId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, list, listTotalCount, groupId },
    } = getState();
    if (list.length === listTotalCount) {
      dispatch({
        type: ActionType.GET_SIDE_LIST_END,
      });
      return;
    }

    const promise = packetId
      ? dispatch(
          getTreatmentPacket({
            filterKey: searchValue,
            groupIds: groupId,
            packetId,
            start: list.length,
            limit: 25,
          }),
        )
      : dispatch(
          getTreatmentList({
            filterKey: searchValue,
            groupIds: groupId,
            limit: 50,
            start: list.length,
          }),
        );

    return promise
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function loadSidebarExistingList(packetId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, list, listTotalCount, groupId },
    } = getState();

    if (list.length === listTotalCount) {
      dispatch({
        type: ActionType.GET_SIDE_LIST_END,
      });
      return;
    }

    const promise = packetId
      ? dispatch(
          getTreatmentPacket({
            filterKey: searchValue,
            groupIds: groupId,
            packetId,
            start: list.length,
            limit: 25,
          }),
        )
      : dispatch(
          getTreatmentList({
            filterKey: searchValue,
            groupIds: groupId,
            limit: 50,
            start: list.length,
          }),
        );

    return promise
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function loadSidebarDiagnosisList() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, list, listTotalCount, groupId },
    } = getState();

    if (list.length === listTotalCount) {
      dispatch({
        type: ActionType.GET_SIDE_LIST_END,
      });
      return;
    }

    return dispatch(
      getDiagnosisList({
        filterKey: searchValue,
        start: list.length,
        groupIds: groupId,
        limit: 50,
      }),
    )
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function loadSidebarProcedureList(subSpecialityId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      chartSideSeries: { searchValue, list, listTotalCount, groupId },
    } = getState();

    if (list.length === listTotalCount) return;

    return dispatch(
      getProcedureList({
        filterKey: searchValue,
        groupIds: groupId,
        start: list.length,
        limit: 25,
        subSpecialityId,
      }),
    )
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function getList(categoryId1) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_SIDE_LIST,
    });

    const {
      chartSideSeries,
      chartSideSeries: { seriesStack, searchValue, groupId },
    } = getState();
    const seriesType = SeriesUtils.getSeriesType(chartSideSeries);
    const stackItem = _.last(seriesStack);
    const categoryId = categoryId1 || _.get(stackItem, "parent.id", 0);

    let $promise;
    switch (seriesType) {
      case seriesTypes.treatment.code:
      case seriesTypes.existing.code:
        if (stackItem.code == seriesStackCodes.diagnosis) {
          $promise = dispatch(
            getDiagnosisList({
              filterKey: searchValue,
              groupIds: groupId,
              limit: 50,
            }),
          );
        } else if (categoryId) {
          dispatch(
            pushSeriesStack({
              code: seriesStackCodes.list,
              id: categoryId,
            }),
          );

          $promise = dispatch(
            getTreatmentPacket({
              filterKey: searchValue,
              groupIds: groupId,
              packetId: categoryId,
              limit: 25,
            }),
          );
        } else {
          $promise = dispatch(
            getTreatmentList({
              filterKey: searchValue,
              groupIds: groupId,
              limit: 50,
            }),
          );
        }

        break;

      case seriesTypes.diagnosis.code:
        $promise = dispatch(
          getDiagnosisList({
            filterKey: searchValue,
            groupIds: groupId,
            limit: 50,
          }),
        );
        break;
    }
    if ($promise) {
      $promise
        .then(() => {
          dispatch({
            type: ActionType.GET_SIDE_LIST_END,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionType.GET_SIDE_LIST_END,
          });
          dispatch(showMessage(error));
        });
    }
  };
}

export function loadList() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.LOAD_SIDE_LIST,
    });

    const {
      listTotalCount,
      list,
      seriesStack,
      seriesType,
      searchValue,
      groupId,
    } = getState().chartSideSeries;
    if (list.length == listTotalCount) return;

    const stackItem = _.last(seriesStack);
    const categoryId = _.get(stackItem, "parent.id", 0);

    let $promise;
    switch (seriesType) {
      case seriesTypes.treatment.code:
      case seriesTypes.existing.code:
        if (stackItem.code == seriesStackCodes.diagnosis) {
          $promise = dispatch(
            getDiagnosisList({
              start: list.length,
              filterKey: searchValue,
              groupIds: groupId,
              limit: 50,
            }),
          );
        } else if (categoryId) {
          $promise = dispatch(
            getTreatmentPacket({
              filterKey: searchValue,
              start: list.length,
              packetId: categoryId,
              groupIds: groupId,
              limit: 25,
            }),
          );
        } else {
          $promise = dispatch(
            getTreatmentList({
              filterKey: searchValue,
              start: list.length,
              limit: 50,
            }),
          );
        }

        break;

      case seriesTypes.diagnosis.code:
        $promise = dispatch(
          getDiagnosisList({
            filterKey: searchValue,
            groupIds: groupId,
            start: list.length,
            limit: 50,
          }),
        );
        break;

      case seriesTypes.procedure.code:
        $promise = dispatch(
          getProcedureList({
            filterKey: searchValue,
            start: list.length,
            groupIds: groupId,
            limit: 25,
            subSpecialityId: categoryId,
          }),
        );
        break;
    }
    $promise
      .then(() => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_SIDE_LIST_END,
        });
        dispatch(showMessage(error));
      });
  };
}

export function getTreatmentFavorites() {
  return (dispatch) => {
    const codes = [];

    const favourites = JSON.parse(localStorage.getItem("favourites"));
    const specialityCode = localStorage.getItem("specialityCode");

    _.forEach(favourites[SidebarTypes.Treatments], (x, idx) => {
      if (x.selected) {
        codes.push(idx);
      }
    });

    if (_.isEmpty(codes)) {
      return;
    }

    return dispatch(getFavouritesTreatment({ codes, specialityCode }));
  };
}

export function getMixedFavorites() {
  return (dispatch) => {
    const existingCodes = [];
    const diagnosisCodes = [];
    const promises = [];

    const favourites = JSON.parse(localStorage.getItem("favourites"));
    const specialityCode = localStorage.getItem("specialityCode");

    _.forEach(favourites[SidebarTypes.Existing], (x, idx) => {
      if (x.selected) {
        existingCodes.push(idx);
      }
    });

    _.forEach(favourites[SidebarTypes.Diagnosis], (x, idx) => {
      if (x.selected) {
        diagnosisCodes.push(idx);
      }
    });

    if (existingCodes.length > 0) {
      promises.push(
        dispatch(getFavoritesMixedExistingApi({ codes: existingCodes, specialityCode })),
      );
    }

    if (diagnosisCodes.length > 0) {
      promises.push(
        dispatch(getFavoritesMixedDiagnosisApi({ codes: diagnosisCodes, specialityCode })),
      );
    }

    if (promises.length === 0) {
      return;
    }

    dispatch({
      type: ActionType.CHART_CLEAR_FAVORITES,
    });

    return Promise.all(promises).catch((error) => showMessage(error));
  };
}

export function getExistingFavorites() {
  return (dispatch) => {
    const codes = [];

    const favourites = JSON.parse(localStorage.getItem("favourites"));
    const specialityCode = localStorage.getItem("specialityCode");

    _.forEach(favourites[SidebarTypes.Existing], (x, idx) => {
      if (x.selected) {
        codes.push(idx);
      }
    });

    if (_.isEmpty(codes)) {
      return;
    }

    return dispatch(getFavouritesTreatment({ codes, specialityCode }));
  };
}

export function getDiagnosisFavorites() {
  return (dispatch) => {
    const codes = [];

    const favourites = JSON.parse(localStorage.getItem("favourites"));
    const specialityCode = localStorage.getItem("specialityCode");

    _.forEach(favourites[SidebarTypes.Diagnosis], (x, idx) => {
      if (x.selected) {
        codes.push(idx);
      }
    });

    if (_.isEmpty(codes)) {
      return;
    }

    return dispatch(getFavouritesDiagnosis({ codes, specialityCode }));
  };
}

export function getFavourites() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_FAVOURITES,
    });

    const { seriesType } = getState().chartSideSeries;

    const transfer = {
      codes: [],
    };

    const transfer2 = {
      codes: [],
    };

    const favourites = JSON.parse(localStorage.getItem("favourites"));
    const specialityCode = localStorage.getItem("specialityCode");
    transfer.specialityCode = specialityCode;
    transfer2.specialityCode = specialityCode;

    Utils.objectForEach(favourites[seriesType], (favourite, code) => {
      if (favourite.selected) transfer.codes.push(code);
    });

    if (seriesTypes.mixed.code === seriesType) {
      Utils.objectForEach(favourites[seriesTypes.existing.code], (favourite, code) => {
        if (favourite.selected) transfer.codes.push(code);
      });

      Utils.objectForEach(favourites[seriesTypes.diagnosis.code], (favourite, code) => {
        if (favourite.selected) transfer2.codes.push(code);
      });
    }

    if (_.isEmpty(transfer.codes) && _.isEmpty(transfer2.codes)) {
      return dispatch({
        type: ActionType.GET_FAVOURITES_END,
      });
    }

    let $promise;
    switch (seriesType) {
      case seriesTypes.treatment.code:
      case seriesTypes.existing.code:
        $promise = dispatch(getFavouritesTreatment(transfer));
        break;
      case seriesTypes.diagnosis.code:
        $promise = dispatch(getFavouritesDiagnosis(transfer));
        break;
      case seriesTypes.mixed.code:
        $promise = Promise.all([
          dispatch(getFavouritesTreatment(transfer)),
          dispatch(getFavouritesDiagnosis(transfer2)),
        ]);
    }
    $promise
      .then(() => {
        dispatch({
          type: ActionType.GET_FAVOURITES_END,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_FAVOURITES_END,
        });

        dispatch(showMessage(error));
      });
  };
}

export function showImageSelector(code, itemSeriesType) {
  return {
    type: ActionType.SHOW_IMAGE_SELECTOR,
    code,
    itemSeriesType,
  };
}

export function hideImageSelector() {
  return {
    type: ActionType.HIDE_IMAGE_SELECTOR,
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_SEARCH_CHANGE,
    value,
  };
}
export function setSearchGroup(value) {
  return {
    type: ActionType.CHART_SEARCH_GROUP_CHANGE,
    value,
  };
}

export function setSearchValueList(value) {
  return (dispatch, getState) => {
    const {
      chartSideSeries: { isMixedCodes, seriesType },
    } = getState();

    dispatch(setSearchValue(value));

    if (isMixedCodes || seriesType === seriesTypes.mixed.code) {
      dispatch(getMixidCodes());
    } else {
      dispatch(getList());
    }
  };
}

export function editTransactionItem(transactionItem, item) {
  return (dispatch) => {
    dispatch({
      type: ActionType.EDIT_TRANSACTION_ITEM,
      transactionItem,
    });
    dispatch(openItem(item, transactionItem));
  };
}

export function addDiagnosis(item) {
  return (dispatch) => {
    return dispatch(changeTransactionFormByKey(item, "changeDiagnosis"));
  };
}

export function openItem(item, transactionItem) {
  return (dispatch, getState) => {
    const {
      chartSideSeries,
      chart: { activeTooth: chartActiveTooth },
    } = getState();
    const { seriesStack: stack } = chartSideSeries;
    const itemStack = stack[stack.length - 2];

    dispatch({
      type: ActionType.OPEN_CHART_SIDE_TRANSACTION_ITEM,
      transactionItem,
    });

    if (
      chartSideSeries.seriesType == seriesTypes.procedure.code &&
      !transactionItem &&
      _.toFinite(chartActiveTooth) === 0
    ) {
      return dispatch({
        type: ActionType.CHART_SIDE_SERIES_OPEN_PROCEDURE_CONFIRM_MODAL,
        id: item.item.id,
      });
    }
    if (
      chartSideSeries.seriesType == seriesTypes.procedure.code &&
      !transactionItem &&
      _.toFinite(chartActiveTooth) > 0
    ) {
      dispatch({
        type: ActionType.CHART_SIDE_SERIES_ADD_ACTIVE_PROCEDURE_ID,
        id: item.item.id,
      });
      dispatch(getProcedure());
    }

    if (
      itemStack &&
      itemStack.code == seriesStackCodes.item &&
      chartSideSeries.transactionForm.selectedDiagnosisKey
    ) {
      chartSideSeries.transactionForm[chartSideSeries.transactionForm.selectedDiagnosisKey] = item;
      changeTransactionFormByKey(item, "changeDiagnosis");
      return dispatch(popSeriesStack());
    }

    const activeTooth = transactionItem && transactionItem.tooth && +transactionItem.tooth.code;
    if (activeTooth) {
      dispatch(chartAction.activeTooth(activeTooth));
    }
    return dispatch(
      pushSeriesStack({
        code: seriesStackCodes.item,
        item,
      }),
    );
  };
}

export function initTransactionItem(itemId, seriesType = SidebarTypes.Treatments) {
  return (dispatch, getState) => {
    const { chartSideSeries } = getState();

    if (chartSideSeries.transactionForm && chartSideSeries.transactionForm.selectedDiagnosisKey) {
      dispatch(changeTransactionFormByKey("", "selectedDiagnosisKey"));
    } else {
      dispatch(getItem(itemId, seriesType)).then(() => {
        const {
          session: { toothNumericType },
          chart,
          patient,
        } = getState();

        const hasPatientInsurance = !!(
          patient.paymentOptions && patient.paymentOptions.insuranceCompanyCount
        );
        const transactionItem = getState().chartSideSeries.transactionItem;
        const isEdit = !!transactionItem;

        const activeTooth = isEdit
          ? parseInt(transactionItem.tooth && +transactionItem.tooth.name)
          : chart.activeTooth;

        if (isEdit) {
          dispatch(chartAction.changeActiveTooth(activeTooth));
        }

        dispatch(createSurfaceSliderItems(activeTooth));

        const { chartSideSeries } = getState();

        const transactionObj = chartSideSeries.addTransaction;
        const transactionForm = chartSideSeries.transactionForm;

        const priceList = _.get(transactionObj, "priceList", []);
        const cashTreatmentPriceId = _.get(chart, "appointment.cashTreatmentPriceId");
        const appointmentPrice = _.find(
          priceList,
          (x) => x.treatmentPriceId === cashTreatmentPriceId,
        );

        if (isEdit) {
          if (transactionItem.diagnosisItem)
            transactionForm.diagnosisItem = transactionItem.diagnosisItem;
          if (transactionItem.diagnosisItem2)
            transactionForm.diagnosisItem2 = transactionItem.diagnosisItem2;
          if (transactionItem.diagnosisItems) {
            transactionForm.diagnosisItems = transactionItem.diagnosisItems;
          }
          if (transactionItem.treatmentItem)
            transactionForm.treatmentItem = transactionItem.treatmentItem;

          transactionForm.area = transactionItem.area;
          transactionForm.key = transactionItem.key;
          // transactionForm.quantity = transactionItem.partCount;
          transactionForm.quantity = 1;
          transactionForm.unit = transactionItem.unit || 1;

          if (transactionItem.areaDetails) {
            transactionObj.areaDetails = transactionItem.areaDetails;
            transactionObj.selectedArea = Utils.makeSurfaceArea(
              transactionItem.areaDetails,
              transactionItem.area.code,
            );
          }

          let len = 1;
          const areaDetail = transactionObj.areaDetails;
          if (areaDetail && areaDetail.length > 1) {
            len = areaDetail.length;
            // len = item["priceList" + len] ? len : 1;
          }
          const { item, customPrice } = chartSideSeries;
          const priceName = transactionItem.priceSource && transactionItem.priceSource.name;
          const customPriceItem = customPrice.item;
          if (priceName == "Custom" || priceName == "Default") {
            customPriceItem.price = transactionItem.price;
            dispatch(changeCustomPrice(transactionItem.price, activeTooth));
          }
          transactionObj.priceList = SeriesUtils.getPriceList(
            item[`priceList${len}`],
            customPriceItem,
          );

          transactionObj.price = SeriesUtils.getPriceItemNew(
            transactionObj.priceList,
            hasPatientInsurance && !chartSideSeries.hasNotItemInsurance,
            transactionItem.price,
          );

          if (!transactionItem) {
            transactionObj.price = appointmentPrice;
          }

          transactionObj.type = transactionItem?.type;
          transactionObj.key = transactionItem.key;
          transactionObj.treatmentPlan = transactionItem.treatmentPlan;
          transactionObj.treatmentSession = transactionItem.treatmentSession;
          transactionObj.tooth = transactionItem.tooth;
          transactionObj.toothPosition =
            transactionItem.toothPosition && transactionItem.toothPosition.code;
          transactionObj.sendBillInsurance = transactionItem.sendBillInsurance;
          transactionObj.sendBillInsurance2 = transactionItem.sendBillInsurance2;
          transactionObj.status = transactionItem.status;
          transactionObj.updatableCreatedDate = transactionItem.updatableCreatedDate;
          transactionObj.createdDate = transactionItem.createdDate;
          transactionObj.modifiedDate = transactionItem.modifiedDate;
          transactionObj.patientKey = transactionItem.patientKey;
          transactionObj.assignedDoctor = transactionItem.assignedDoctor;
          transactionObj.createdDentist = transactionItem.createdDentist;
          transactionObj.createdAppointmentId = transactionItem.createdAppointmentId;
          transactionObj.holdPreApproval = transactionItem.holdPreApproval;
          transactionObj.key = transactionItem.key;
        } else {
          // transactionObj.price = SeriesUtils.getPriceItemNew(
          //   chartSideSeries.addTransaction.priceList,
          //   hasPatientInsurance && !chartSideSeries.hasNotItemInsurance,
          // );

          if (
            appointmentPrice &&
            appointmentPrice.name !== "Custom" &&
            appointmentPrice.price !== "0"
          ) {
            transactionObj.price = appointmentPrice;
          }
        }
        const numericKey = Utils.getToothNumberKey(toothNumericType);
        const teethDetailsList = SeriesUtils.getToothDetailsList(chart.teeth);
        const item = chartSideSeries.item;
        const areaCode = item && item.item && item.item.area.code;
        if (areaCode == treatmentAreas.Tooth.code || areaCode == treatmentAreas.Root.code) {
          for (const toothDetails of teethDetailsList) {
            if (
              SeriesUtils.isEqualsActiveTooth(toothDetails, activeTooth) ||
              (transactionItem && toothDetails[numericKey] == transactionItem.tooth.name)
            ) {
              if (toothDetails) {
                transactionObj.selectedArea = [toothDetails[numericKey]];
                const obj = {
                  code: toothDetails.id,
                  id: toothDetails.code,
                  name: toothDetails[numericKey],
                };
                transactionObj.selectedTeeth = [obj];
              }
            }
          }
        }

        if (areaCode === treatmentAreas.Surface.code || areaCode === treatmentAreas.Tooth.code) {
          let toothId;

          const activeToothObject = _.find(chart.teeth, (x) => x.image === chart.activeTooth);

          if (chart.activeTooth > 50) {
            toothId = _.get(activeToothObject, "supernumerary.details.id");
          } else {
            toothId = _.get(activeToothObject, "normal.details.id");
          }

          if (
            !_.isEmpty(chartSideSeries.item.item.allowedTeeth) &&
            !_.find(
              chartSideSeries.item.item.allowedTeeth,
              (allowedTooth) => allowedTooth.code === toothId,
            )
          ) {
            simpleModalActions.openSimpleModal({
              body: "Tooth not allowed",
            });
          }
        }

        dispatch(setSideBarTransactionItem(transactionObj, true));
        dispatch(setChartTransactionForm(transactionForm, true));
      });
    }
  };
}

export function getItem(dataId, seriesType) {
  return (dispatch, getState) => {
    const {
      chart: {
        teeth,
        activeTooth,
        patient: { patientKey },
      },
      chartSideSeries: { seriesStack, isMixedCodes, transactionItem },
      session: { toothNumericType },
    } = getState();

    dispatch({
      type: ActionType.CHART_CLEAR_ITEM,
    });

    const stackItem = _.last(seriesStack);

    const type = _.get(transactionItem, "type.code");

    const sType = type === "TREATMENT" ? "treatment" : "diagnosis";

    const hasItem = Boolean(stackItem && stackItem.item);
    const id = dataId || (hasItem && stackItem.item.id);
    const numberKey = Utils.getToothNumberKey(toothNumericType);

    if (seriesType === SidebarTypes.Grouped) {
      dispatch({
        type: ActionType.CHART_SIDE_SERIES_ADD_ACTIVE_PROCEDURE_ID,
        id,
      });
      return dispatch(getProcedure());
    }

    if (!id) {
      return Promise.resolve();
    }

    dispatch(startLoader());

    dispatch({
      type: ActionType.CHART_SET_SERIES_TYPE,
      seriesType,
    });

    return dispatch(
      paymentInsuranceFn({
        treatmentId: id,
        patientKey,
      }),
    )
      .then((paymentResponse) => {
        const hasPatientInsurance = _.get(paymentResponse, "insuranceCompanyCount", 0) > 0;

        return dispatch(
          getItemBySeriesTypeFn(
            {
              id,
              patientKey,
            },
            numberKey,
            seriesType || sType,
            isMixedCodes,
            activeTooth,
          ),
        )
          .then((itemResponse) => {
            const areaCode = _.get(itemResponse, "item.area.code");

            if (
              itemResponse &&
              (areaCode === treatmentAreas.Tooth.code || areaCode === treatmentAreas.Mouth.code)
            ) {
              const tooth = _.get(teeth[`p${activeTooth}`], "normal.details");

              dispatch({
                type: ActionType.CHART_ADD_SELECTED_TEETH,
                activeTooth,
                tooth: {
                  code: _.get(tooth, "id"),
                  id: _.get(tooth, "code"),
                },
                hasPatientInsurance,
              });
            }

            dispatch(stopLoader());
          })
          .catch((e) => dispatch(stopLoader(e)));
      })
      .catch((e) => dispatch(stopLoader(e)));
  };
}

export function closeProcedureConfirmModal() {
  return {
    type: ActionType.CHART_SIDE_SERIES_CLOSE_PROCEDURE_CONFIRM_MODAL,
  };
}

export function getProcedure() {
  return (dispatch, getState) => {
    const {
      chartSideSeries: { activeProcedureId },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      patientKey,
      id: activeProcedureId,
    };

    dispatch({
      api: ProcedureApi.getItem,
      types: [
        ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_START,
        ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_SUCCESS,
        ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_ITEM_ERROR,
      ],
      data,
    })
      .then((response) => {
        dispatch(postProcedure(response));
      })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changePostProcedureItems(array, currentIndex) {
  return {
    type: ActionType.CHART_SIDE_SERIES_ADD_POST_PROCEDURE_ITEMS,
    payload: {
      array,
      currentIndex,
    },
  };
}

export function pushToPostProcedureItems(postArray) {
  return {
    type: ActionType.CHART_SIDE_SERIES_ADD_POST_PROCEDURE_ITEMS,
    payload: {
      postArray,
    },
  };
}

function postProcedure(procedure) {
  return async (dispatch, getState) => {
    const {
      chartSideSeries: { activeProcedureId },
      chart: {
        patient: { patientKey },
        appointment,
        activeTooth,
        teeth,
        patientView,
        insurance,
      },
    } = getState();

    const resTreatments = _.get(procedure, "treatments", []);

    const tmpTreatments = resTreatments.map((x) =>
      _.omit(x, ["price", "unit", "quantity", "packagePrice"]),
    );

    const postTreatments = tmpTreatments.filter((x) => x.code !== "SURFACE");
    const surfaceTreatments = tmpTreatments.filter((x) => x.code === "SURFACE");

    if (_.toFinite(activeTooth) > 0 && surfaceTreatments.length > 0) {
      dispatch(closeProcedureConfirmModal());
      dispatch(popSeriesStack());
      dispatch(changeSideBarType("treatment"));

      if (surfaceTreatments.length > 0) {
        dispatch(openItem(surfaceTreatments[0]));
      }

      if (surfaceTreatments.length > 1) {
        dispatch(changePostProcedureItems(surfaceTreatments, 0));
      }

      if (postTreatments.length <= 0) {
        return;
      }
    }

    if (_.toFinite(activeTooth) === 0 || surfaceTreatments.length === 0) {
      dispatch(popSeriesStack());
    }

    const tooth = teeth[`p${activeTooth}`];

    const sendPostTreatments = _.toFinite(activeTooth) > 0 ? postTreatments : tmpTreatments;

    const treatments = sendPostTreatments.map((treatment) => {
      const areaDetails = [];

      if (treatment.code === "SURFACE" && _.toFinite(activeTooth) === 0) {
        if ((activeTooth >= 22 && activeTooth <= 27) || (activeTooth >= 6 && activeTooth <= 11)) {
          areaDetails.push({ code: "I" });
        } else {
          areaDetails.push({ code: "O" });
        }
      } else if (treatment.code == "MOUTH") {
        areaDetails.push({ code: "Upper" });
      } else if (treatment.code == "QUADRANT") {
        areaDetails.push({ code: "UpperRight" });
      } else if (treatment.code == "SEXTANT") {
        areaDetails.push({ code: "Sextant1" });
      } else if (treatment.code == "TOOTH" && activeTooth > 0) {
        areaDetails.push({ code: tooth.normal.details.id });
      }

      return {
        treatment,
        area: {
          code: treatment.code,
        },
        tooth:
          activeTooth > 0
            ? {
                code: tooth.normal.details.id,
                id: tooth.normal.details.code,
              }
            : null,
        areaDetails,
      };
    });

    const data = {
      patientKey,
      appointmentId: appointment.id,
      procedureId: activeProcedureId,
      treatments,
    };

    return dispatch({
      api: ProcedureApi.postItem,
      types: [
        ActionType.CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_START,
        ActionType.CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_SUCCESS,
        ActionType.CHART_SIDE_SERIES_POST_PROCEDURE_ITEM_ERROR,
      ],
      data,
    })
      .then((keys) => {
        if ((patientView?.insurances?.totalCount || insurance.total) && !keys?.info) {
          return dispatch(showPriorApprovalPopup(keys, procedure));
        }
      })
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function showPriorApprovalPopup(keys, procedure) {
  return (dispatch) => {
    const laterBtn = new Element("Later", 1);
    const nowBtn = new Element("Now", 2);
    const holdBtn = new Element("Hold", 3);
    const handler = (e, element) => {
      switch (element.id) {
        case 1:
          dispatch(sendToPreApproval(keys, procedure)).then(() =>
            dispatch(getAppointmentTransactions(true, true)),
          );
          dispatch(showDocumentsAssignedModal("single_procedure"));
          break;
        case 2:
          // openPriorApprovalDiagnosisPopup();
          break;
        case 3:
          dispatch(setHoldPreApproval(keys)).then(() =>
            dispatch(getAppointmentTransactions(true, true)),
          );
          dispatch(showDocumentsAssignedModal("single_procedure"));
          break;
      }
      dispatch(setPopupActionByKey("small", "visible", false));
    };
    laterBtn.handler = handler;
    nowBtn.handler = handler;
    holdBtn.handler = handler;
    dispatch(
      setPopupParams(
        "small",
        "Info",
        true,
        "Send this treatment to prior-approval?",
        [
          laterBtn,
          // nowBtn,
          holdBtn,
        ],
        true,
      ),
    );
  };
}

function sendToPreApproval(keys, procedure) {
  return function (dispatch, getState) {
    const {
      chart: { patient, appointment },
    } = getState();
    const request = {};

    request.status = { code: "Later" };
    request.patientKey = patient.patientKey;
    request.appointmentId = appointment.id;
    request.diagnosis = [];

    if (procedure.diagnosisItem) {
      request.diagnosis.push({
        id: procedure.diagnosisItem.id,
        code: procedure.diagnosisItem.code,
      });
    }
    if (procedure.diagnosisItem2) {
      request.diagnosis.push({
        id: procedure.diagnosisItem2.id,
        code: procedure.diagnosisItem2.code,
      });
    }

    request.dentalTransactions = keys;

    dispatch(startLoader());
    return dispatch(sendToPreApprovalFn(request))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function showDocumentsAssignedModal(mode) {
  return (dispatch, getState) => {
    const {
      chart: { selectTreatmentToCompleteSelectedItems, selectTreatmentToCompleteList },
      chartSideSeries: {
        addTransaction: { treatmentCode },
        activeProcedureId,
      },
    } = getState();

    let $promise;

    if (mode == "single_treatment") {
      const data = {
        ids: [treatmentCode.id || 0],
        type: "BEFORE",
      };

      $promise = dispatch(getTreatmentSignInList(data));
    }

    if (mode == "multiple_treatment") {
      const completeMap = {};
      selectTreatmentToCompleteList.filter(Boolean).forEach((treatment) => {
        if (!treatment || !treatment.parts) {
          completeMap[treatment.key] = treatment.treatmentItem.id;
        } else {
          treatment.parts.filter(Boolean).forEach((part) => {
            completeMap[part.key] = treatment.treatmentItem.id;
          });
        }
      });

      const ids = _(selectTreatmentToCompleteSelectedItems)
        .pickBy((boolean) => boolean)
        .map((value, key) => completeMap[key])
        .uniq()
        .value();

      const data = {
        ids,
        type: "AFTER",
      };

      $promise = dispatch(getTreatmentSignInList(data));
    }

    if (mode == "single_procedure") {
      const data = {
        id: activeProcedureId,
        type: "BEFORE",
      };

      $promise = dispatch(getProcedureSignInList(data)).then((response) => [response]);
    }

    $promise.then((documents) => {
      if (_.some(documents, (item) => !_.isEmpty(item.documents))) {
        dispatch(chartAction.hideSideBar(true));
        dispatch({
          type: ActionType.CHART_SIDE_SERIES_OPEN_DOCUMENTS_ASSIGNED_MODAL,
          documents,
        });
      }
    });
  };
}

export function getTreatmentGroupList(start) {
  return (dispatch, getState) => {
    const {
      chartSideSeries: {
        treatmentGroupList,
        treatmentGroupListFetching,
        treatmentGroupListTotalCount,
      },
    } = getState();

    if (
      (!start === 0 && treatmentGroupList.length === treatmentGroupListTotalCount) ||
      treatmentGroupListFetching
    ) {
      return;
    }
    const data = { limit: 25, root: true };
    data.start = start === 0 ? start : treatmentGroupList.length;
    return dispatch(getTreatmentGroupListApi(data));
  };
}

export function closeDocumentsAssignedModal() {
  return {
    type: ActionType.CHART_SIDE_SERIES_CLOSE_DOCUMENTS_ASSIGNED_MODAL,
  };
}

export function openDocumentsAssignedInsideModal(document, treatment) {
  return {
    type: ActionType.DOCUMENTS_ASSIGNED_INSIDE_MODAL_OPEN,
    document,
    treatment,
  };
}

export function closeDocumentsAssignedInsideModal() {
  return {
    type: ActionType.DOCUMENTS_ASSIGNED_INSIDE_MODAL_CLOSE,
  };
}

export function toggleDocument(index1, index2) {
  return {
    type: ActionType.DOCUMENTS_ASSIGNED_MODAL_TOGGLE_DOCUMENT,
    index1,
    index2,
  };
}

export function initPostOperation() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chartSideSeries: { document },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
      id: document.templateItem.id,
    };

    dispatch({
      api: PatientApi.postOperationInstruction.getTemplate,
      types: [
        ActionType.CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_START,
        ActionType.CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_SUCCESS,
        ActionType.CHART_SIDE_SERIES_GET_POST_OPERATION_TEMPLATE_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initLabOrder() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      auth: { chosenMemberId },
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
      dentistId: chosenMemberId,
    };

    dispatch({
      api: LabOrderApi.getFormData,
      types: [
        ActionType.CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_START,
        ActionType.CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_SUCCESS,
        ActionType.CHART_SIDE_SERIES_GET_LAB_ORDER_FORM_DATA_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveLabOrder() {
  return (dispatch, getState) => {
    const {
      session,
      auth: { members, chosenMemberId },
      chart: { patient },
      chartSideSeries: {
        labOrderFormData: { externalLabList, templateList, toothList },
        labOrderAttachment,
      },
      form: {
        chartLabOrder: { values },
      },
    } = getState();

    if (!values.lab) return dispatch(showMessage("Please, enter Lab"));
    if (!values.printout) return dispatch(showMessage("Please, enter Printout"));
    if (_.isEmpty(values.tooth)) return dispatch(showMessage("Please, enter Tooth"));
    if (!values.dueDate) return dispatch(showMessage("Please, enter Due Date"));
    if (!values.price) return dispatch(showMessage("Please, enter Price"));

    const dentist = members[chosenMemberId];

    const data = {
      clinic: {
        id: session.clinic.id,
        name: session.clinic.name,
      },
      status: {
        code: "RECEIVED",
      },
      note: values.note,
      toothList: values.tooth.map((id) => toothList.find((teeth) => teeth.id == id)),
      price: values.price,
      patient: { name: Utils.getFullName(patient), code: patient.patientKey },
      dentist: { id: dentist.id, name: Utils.getFullName(dentist) },
      attachments: labOrderAttachment,
      externalLab: externalLabList.find((lab) => lab.id == values.lab),
      labOrderTemplate: templateList.find((template) => template.id == values.printout),
      dueDate: +values.dueDate - Utils.getCompanyTimeZone(),
    };

    dispatch(startLoader());

    return dispatch({
      api: LabOrderApi.save,
      types: [
        ActionType.CHART_SIDE_SERIES_SAVE_LAB_ORDER_START,
        ActionType.CHART_SIDE_SERIES_SAVE_LAB_ORDER_SUCCESS,
        ActionType.CHART_SIDE_SERIES_SAVE_LAB_ORDER_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .then(() => dispatch(closeDocumentsAssignedInsideModal()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function printLabOrder() {
  return (dispatch) => {
    dispatch(saveLabOrder()).then(() => {
      dispatch(
        openDocumentsAssignedInsideModal({
          templateType: {
            code: "LAB_ORDER_PDF",
          },
        }),
      );
    });
  };
}

export function uploadLabOrder(files) {
  return (dispatch) => {
    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file, file.name);
    });

    dispatch(startLoader());

    dispatch({
      api: LabOrderApi.upload,
      types: [
        ActionType.CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_START,
        ActionType.CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_SUCCESS,
        ActionType.CHART_SIDE_SERIES_LAB_ORDER_UPLOAD_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteLabOrderAttachment(index) {
  return {
    type: ActionType.CHART_SIDE_SERIES_LAB_ORDER_DELETE_ATTACHMENT_START,
    index,
  };
}

export function submitPostOperation(values) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chartSideSeries: { postOperation, documentsAssignedTreatment },
    } = getState();

    const data = {
      ...postOperation,
      content: values.content.split("\n").join("<br />"),
      name: values.title,
      treatmentCodeId: documentsAssignedTreatment.id,
      type: { id: 1, code: "BEFORE" },
      createdFrom: "TREATMENT",
    };

    dispatch({
      api: PatientApi.postOperationInstruction.saveItem,
      types: [
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_START,
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_SUCCESS,
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .then(() => dispatch(closeDocumentsAssignedInsideModal()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function emailPostOperation() {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      chartSideSeries: { postOperation, documentsAssignedTreatment },
    } = getState();

    const data = {
      ...postOperation,
      content: postOperation.content.split("\n").join("<br />"),
      treatmentCodeId: documentsAssignedTreatment.id,
      type: { id: 1, code: "BEFORE" },
      createdFrom: "TREATMENT",
    };

    dispatch({
      api: PatientApi.postOperationInstruction.saveItem,
      types: [
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_START,
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_SUCCESS,
        ActionType.CHART_SIDE_SERIES_POST_POST_OPERATION_TEMPLATE_ERROR,
      ],
      data,
    })
      .then((response) =>
        dispatch({
          api: PatientApi.postOperationInstruction.sendToEmail,
          types: [
            ActionType.CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_START,
            ActionType.CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_SUCCESS,
            ActionType.CHART_SIDE_SERIES_EMAIL_POST_OPERATION_TEMPLATE_ERROR,
          ],
          data: {
            postOperationId: response.id,
          },
        }),
      )
      .then(() => dispatch(stopLoader("successfully sent")))
      .then(() => dispatch(closeDocumentsAssignedInsideModal()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTreatmentSignInList(data) {
  return {
    api: TreatmentApi.getSignInList,
    types: [
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_START,
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_SUCCESS,
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_SIGN_IN_LIST_ERROR,
    ],
    data,
  };
}

export function getProcedureSignInList(data) {
  return {
    api: ProcedureApi.getSignInList,
    types: [
      ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_START,
      ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_SUCCESS,
      ActionType.CHART_SIDE_SERIES_GET_PROCEDURE_SIGN_IN_LIST_ERROR,
    ],
    data,
  };
}

export function getTreatmentPacket(data) {
  return {
    api: TreatmentApi.packet.getList,
    types: [
      ActionType.GET_TREATMENT_PACKET_START,
      ActionType.GET_TREATMENT_PACKET_SUCCESS,
      ActionType.GET_TREATMENT_PACKET_ERROR,
    ],
    data,
  };
}

export function getSidebarCategoryListApi(data) {
  return {
    api: TreatmentApi.packet.getList,
    types: [
      ActionType.GET_SIDEBAR_CATEGORY_LIST_START,
      ActionType.GET_SIDEBAR_CATEGORY_LIST_SUCCESS,
      ActionType.GET_SIDEBAR_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

export function getSpecialities(data) {
  return {
    api: SpecialityApi.getAll,
    types: [
      ActionType.GET_SPECIALITIES_START,
      ActionType.GET_SPECIALITIES_SUCCESS,
      ActionType.GET_SPECIALITIES_ERROR,
    ],
    data,
  };
}
export function getFavouritesIcons(data) {
  return {
    api: CompanyApi.getFavouritesIcons,
    types: [
      ActionType.GET_FAVOURITES_ICONS_START,
      ActionType.GET_FAVOURITES_ICONS_SUCCESS,
      ActionType.GET_FAVOURITES_ICONS_ERROR,
    ],
    data,
  };
}
function getTreatmentMixidList(data) {
  return {
    api: TreatmentApi.getList,
    types: [
      ActionType.GET_TREATMENT_MIXID_LIST_START,
      ActionType.GET_TREATMENT_MIXID_LIST_SUCCESS,
      ActionType.GET_TREATMENT_MIXID_LIST_ERROR,
    ],
    data,
  };
}
function getTreatmentList(data) {
  return {
    api: TreatmentApi.getList,
    types: [
      ActionType.GET_TREATMENT_LIST_START,
      ActionType.GET_TREATMENT_LIST_SUCCESS,
      ActionType.GET_TREATMENT_LIST_ERROR,
    ],
    data,
  };
}

function getAllTreatmentsApi(data) {
  return {
    api: TreatmentApi.getList,
    types: [
      ActionType.CHART_GET_ALL_TREATMENTS_START,
      ActionType.CHART_GET_ALL_TREATMENTS_SUCCESS,
      ActionType.CHART_GET_ALL_TREATMENTS_ERROR,
    ],
    data,
  };
}
function getAllDiagnosisApi(data) {
  return {
    api: DiagnosisApi.getList,
    types: [
      ActionType.CHART_GET_ALL_DIAGNOSIS_START,
      ActionType.CHART_GET_ALL_DIAGNOSIS_SUCCESS,
      ActionType.CHART_GET_ALL_DIAGNOSIS_ERROR,
    ],
    data,
  };
}

function getDiagnosisMixidList(data) {
  return {
    api: DiagnosisApi.getList,
    types: [
      ActionType.GET_DIAGNOSIS_MIXID_LIST_START,
      ActionType.GET_DIAGNOSIS_MIXID_LIST_SUCCESS,
      ActionType.GET_DIAGNOSIS_MIXID_LIST_ERROR,
    ],
    data,
  };
}
function getDiagnosisList(data) {
  return {
    api: DiagnosisApi.getList,
    types: [
      ActionType.GET_DIAGNOSIS_LIST_START,
      ActionType.GET_DIAGNOSIS_LIST_SUCCESS,
      ActionType.GET_DIAGNOSIS_LIST_ERROR,
    ],
    data,
  };
}
export function getProcedureList(data) {
  return {
    api: ProcedureApi.getList,
    types: [
      ActionType.GET_PROCEDURE_LIST_START,
      ActionType.GET_PROCEDURE_LIST_SUCCESS,
      ActionType.GET_PROCEDURE_LIST_ERROR,
    ],
    data,
  };
}
function getFavouritesTreatment(data) {
  return {
    api: TreatmentApi.getChooseByCode,
    types: [
      ActionType.GET_FAVOURITE_TREATMENTS_START,
      ActionType.GET_FAVOURITE_TREATMENTS_SUCCESS,
      ActionType.GET_FAVOURITE_TREATMENTS_ERROR,
    ],
    data,
  };
}
function getFavouritesDiagnosis(data) {
  return {
    api: DiagnosisApi.getChooseByCode,
    types: [
      ActionType.GET_FAVOURITE_DIAGNOSIS_START,
      ActionType.GET_FAVOURITE_DIAGNOSIS_SUCCESS,
      ActionType.GET_FAVOURITE_DIAGNOSIS_ERROR,
    ],
    data,
  };
}
export const getTreatmentItem = (data) => {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch({
      api: TreatmentApi.getItem,
      types: [
        ActionType.GET_TREATMENT_ITEM_START,
        ActionType.GET_TREATMENT_ITEM_SUCCESS,
        ActionType.GET_TREATMENT_ITEM_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .then(() => dispatch(hideSideBar()))
      .catch((error) => dispatch(stopLoader(error)));
  };
};

export const getTreatmentItemPriceApi = (data) => {
  return {
    api: TreatmentApi.getPriceList,
    types: [
      ActionType.GET_TREATMENT_ITEM_PRICE_START,
      ActionType.GET_TREATMENT_ITEM_PRICE_SUCCESS,
      ActionType.GET_TREATMENT_ITEM_PRICE_ERROR,
    ],
    data,
  };
};

export const getTreatmentProcedureItemApi = (data) => {
  return {
    api: TreatmentApi.getProcedureItem,
    types: [
      ActionType.GET_TREATMENT_PROCEDURE_ITEM_START,
      ActionType.GET_TREATMENT_PROCEDURE_ITEM_SUCCESS,
      ActionType.GET_TREATMENT_PROCEDURE_ITEM_ERROR,
    ],
    data,
  };
};
function getItemBySeriesTypeFn(data, numberKey, seriesType, isMixedCodes, activeTooth) {
  let apiMethod;
  switch (seriesType) {
    case SidebarTypes.Treatments:
    case SidebarTypes.Existing:
      apiMethod = TreatmentApi.getItem;
      break;
    case SidebarTypes.Diagnosis:
      apiMethod = DiagnosisApi.getItem;
      break;
    case SidebarTypes.Grouped:
      apiMethod = ProcedureApi.getItem;
      break;
  }

  if (seriesType === SidebarTypes.Existing && isMixedCodes) {
    apiMethod = DiagnosisApi.getItem;
  }

  return {
    api: apiMethod,
    types: [
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_START,
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_SUCCESS,
      ActionType.CHART_SIDE_SERIES_GET_TREATMENT_ITEM_ERROR,
    ],
    data,
    numberKey,
    seriesType,
    activeTooth,
  };
}

// region <SideBar Item methods>
export function setSideBarTransactionItem(data, isUpdate) {
  return {
    type: ActionType.CHANGE_FROM_FOR_SIDE_BAR_ITEM,
    data,
    isUpdate,
  };
}
export function setChartTransactionForm(data, isUpdate) {
  return {
    type: ActionType.CHANGE_FROM_FOR_SIDE_BAR_ITEM_FORM,
    data,
    isUpdate,
  };
}

export function openDiagnosisDropdown(selectKey) {
  return (dispatch) => {
    dispatch({
      type: ActionType.OPEN_DIAGNOSIS_LIST,
    });

    dispatch(changeTransactionFormByKey(selectKey, "selectedDiagnosisKey"));
    dispatch(openDiagnosisTypeAhead(selectKey));
  };
}

export function changeTransactionByKey(
  value,
  key,
  callback,
  seriesType,
  tooth,
  supernumeraryTooth,
) {
  return (dispatch, getState) => {
    const { patient, chart } = getState();
    const hasPatientInsurance = Boolean(
      patient.paymentOptions && patient.paymentOptions.insuranceCompanyCount > 0,
    );

    const isSurface = Boolean(tooth);

    return dispatch({
      type: ActionType.CHANGE_FROM_FOR_SIDE_BAR_ITEM_BY_KEY,
      value,
      key,
      hasPatientInsurance,
      callback,
      seriesType,
      isSurface,
      teeth: chart.teeth,
      activeTooth: tooth || chart.activeTooth,
      supernumeraryTooth,
    });
  };
}

export function changeTransactionFormByKey(value, key, callback) {
  return {
    type: ActionType.CHANGE_TRANSACTION_ITEM_FORM,
    value,
    key,
    callback,
  };
}

export function selectTooth(tooth, areaItem, toothDetails) {
  return (dispatch, getState) => {
    const { chartSideSeries, patient } = getState();

    const hasPatientInsurance = Boolean(
      patient.paymentOptions && patient.paymentOptions.insuranceCompanyCount > 0,
    );

    const isNotAllowed = SeriesUtils.isNotAllowedTooth(
      chartSideSeries.item.item,
      toothDetails.code,
    );

    if (isNotAllowed) {
      dispatch(
        simpleModalActions.openSimpleModal({
          body: "Tooth not allowed",
        }),
      );
    } else {
      dispatch({
        type: ActionType.SELECT_TOOTH_ON_TEETH_SLIDER,
        toothDetails,
        areaItem,
        hasPatientInsurance,
      });
    }
  };
}
export function createSurfaceSliderItems(active) {
  return function (dispatch, getState) {
    dispatch({
      type: "createSurfaceSliderItems",
    });
    const { chart } = getState();
    const teeth = chart.teeth;
    const activeTooth = active || chart.activeTooth;

    return dispatch({
      type: ActionType.SET_SURFACE_SLIDER_ITEMS_ARRAY,
      teeth,
      activeTooth,
    });
  };
}

export function changeCustomPrice(price, activeTooth) {
  return {
    type: ActionType.CHANGE_CUSTOM_PRICE,
    price,
    activeTooth,
  };
}
export function toggleCustomPrice(visible, e) {
  e && e.preventDefault();

  return {
    type: ActionType.TOGGLE_CUSTOM_PRICE,
    visible,
  };
}

export function addChartItem(item) {
  return (dispatch) =>
    dispatch({
      type: ActionType.ADD_CHART_ITEM,
      item,
    });
}

export function createTransactionRequest(getState, seriesType, isMixed) {
  const {
    session: { member },
    chartSideSeries,
    chart,
    appointment: { appointment },
    chart: { activeTooth, teeth, patient },
    chartDermatology: { areas },
    chartSideMedicalFillInForm: { stack },
  } = getState();
  const requestObj = {};
  const { transactionItem } = chartSideSeries;
  const isProvisionalDiagnosis =
    _.last(stack)?.code === medicalFillInFormStackCodes.provisionalDiagnosis;
  const chartAppointmentId = _.get(chart, "appointment.id", undefined);
  const appointmentId = _.get(appointment, "id", undefined);

  const isEdit = !!transactionItem;
  requestObj.currentAppointmentId = isEdit
    ? transactionItem.createdAppointmentId
    : chartAppointmentId || appointmentId;
  requestObj.createdAppointmentId = requestObj.currentAppointmentId;
  requestObj.closedAppointmentId = requestObj.currentAppointmentId;
  requestObj.patientKey = isEdit ? transactionItem.patientKey : patient.patientKey;
  requestObj.assignedDoctor = isEdit ? transactionItem.assignedDoctor : member;
  requestObj.createdDentist = isEdit ? transactionItem.createdDentist : member;

  const oneForm = chartSideSeries.transactionForm;

  if (seriesType === SidebarTypes.Diagnosis) {
    requestObj.diagnosisItem = { id: chartSideSeries.item.item.id };
  }

  const diagnosisItems = chartSideSeries.diagnosisItems.filter(Boolean);

  requestObj.diagnosisItems = diagnosisItems.length > 0 ? diagnosisItems : null;

  requestObj.area = oneForm.area;
  requestObj.unitBox = oneForm.unit;
  requestObj.problem = oneForm.problem;
  requestObj.diagnosisClaimType = oneForm.diagnosisClaimType?.value;
  requestObj.diagnosisYearOfOnSet =
    oneForm.diagnosisYearOfOnSet && new Date(oneForm.diagnosisYearOfOnSet).getFullYear();

  requestObj.status = isProvisionalDiagnosis
    ? TransactionStatusSelectItem.PROVISIONAL.getShortRequest()
    : TransactionStatusSelectItem.PLANNED.getShortRequest();

  if ((seriesType === SidebarTypes.Treatments && isMixed) || seriesType === SidebarTypes.Existing) {
    requestObj.type = TransactionTypeSelectItem.EXISTING.getShortRequest();
  } else if (seriesType === SidebarTypes.Diagnosis) {
    requestObj.type = TransactionTypeSelectItem.DIAGNOSIS.getShortRequest();
  } else {
    requestObj.type = TransactionTypeSelectItem.TREATMENT.getShortRequest();
  }

  const addTransaction = chartSideSeries.addTransaction;

  const toothAreaType = chartSideSeries.item.item.area.code;
  const requestJSON = { list: [], dentistToCashierNote: "" };

  let toothObj;

  for (let i = 0; i < oneForm.quantity; i++) {
    switch (toothAreaType) {
      case treatmentAreas.Tooth.code:
      case treatmentAreas.Root.code:
        for (const selectedItem of addTransaction.selectedTeeth) {
          const selectedTooth = SeriesUtils.getToothByCode(selectedItem && selectedItem.id);
          if (
            selectedTooth &&
            !SeriesUtils.isNotAllowedTooth(chartSideSeries.item.item, selectedTooth.id)
          ) {
            requestJSON.list.push(
              makeBatchRequest(addTransaction, requestObj, selectedTooth, seriesType),
            );
          }
        }
        break;
      case treatmentAreas.Surface.code:
        for (let i = 0; i < chartSideSeries.surfaceTransactionList.length; i++) {
          const surfaceItem = chartSideSeries.surfaceTransactionList[i];

          if (surfaceItem.editable) {
            const selectedTooth = SeriesUtils.getToothByCode(
              surfaceItem.tooth && surfaceItem.tooth.id,
            );

            if (
              selectedTooth &&
              !SeriesUtils.isNotAllowedTooth(chartSideSeries.item.item, selectedTooth.id)
            ) {
              requestJSON.list.push(
                makeBatchRequest(
                  surfaceItem,
                  requestObj,
                  selectedTooth,
                  seriesType,
                  oneForm.treatmentItem,
                ),
              );
            }
          }
        }
        break;
      case treatmentAreas.Body.code:
        const requestAreas = [];

        if (activeTooth != 0) {
          toothObj = SeriesUtils.getToothByActiveTooth(activeTooth, teeth);
        }

        _.forEach(areas, (item, index) => {
          if (item && chart.areasList[index]) requestAreas.push(chart.areasList[index]);
        });

        addTransaction.areaDetails = requestAreas;

        requestJSON.list.push(makeBatchRequest(addTransaction, requestObj, toothObj, seriesType));
        break;
      default:
        if (activeTooth != 0) {
          toothObj = SeriesUtils.getToothByActiveTooth(activeTooth, teeth);
        }
        requestJSON.list.push(makeBatchRequest(addTransaction, requestObj, toothObj, seriesType));
        break;
    }
  }

  return requestJSON;
}

export function updateTransactionList(item) {
  return (dispatch) => {
    dispatch(startLoader());

    const requestJSON = {
      dentistToCashierNote: "",
      list: [item],
    };

    return dispatch(createTransactions(requestJSON))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function createTransactionList(seriesType, isMixed) {
  return function (dispatch, getState) {
    dispatch({
      type: "createTransactionList",
    });

    const {
      chart: { patient },
    } = getState();

    const requestJSON = createTransactionRequest(getState, seriesType, isMixed);

    dispatch(startLoader());

    return dispatch(createTransactions(requestJSON))
      .then(() => dispatch(getTeeth({ patientKey: patient.patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const createTransactions = (data) => {
  return {
    api: TransactionApi.batchItem.create,
    types: [
      ActionType.BATCH_ITEMS_FOR_SIDE_BAR_ITEM_START,
      ActionType.BATCH_ITEMS_FOR_SIDE_BAR_ITEM_SUCCESS,
      ActionType.BATCH_ITEMS_FOR_SIDE_BAR_ITEM_ERROR,
    ],
    data,
  };
};
// endregion

function makeBatchRequest(transactionObj, jsonObject, tooth, seriesType) {
  const {
    areaDetails,
    price,
    treatmentCode,
    sendBillInsurance,
    sendBillInsurance2,
    key,
  } = transactionObj;

  const jsonRequest = {
    ...jsonObject,
    areaDetails,

    sendBillInsurance,
    sendBillInsurance2,
  };

  if (treatmentCode && seriesType !== SidebarTypes.Diagnosis) {
    jsonRequest.treatmentItem = {
      id: treatmentCode.id,
    };
  }

  if (tooth) {
    jsonRequest.tooth = tooth;

    const tempArr = tooth.code.split("_");
    if (tempArr && tempArr.length) {
      const positionCode = tempArr[tempArr.length - 1];

      jsonRequest.toothPosition = {
        id: positionCode.slice(1),
        code: positionCode,
      };
    }
  }
  if (key) {
    jsonRequest.key = key;
  }

  if (seriesType === SidebarTypes.Treatments) {
    if (price) {
      const unit = (jsonObject && jsonObject.unitBox) || 1;

      jsonRequest.price = unit * price.price;

      if (price && price.hasOwnProperty("needPreApproval") && !price.needPreApproval) {
        jsonRequest.sendBillInsurance = false;
      }

      if (price && price.hasOwnProperty("needPreApproval2") && !price.needPreApproval2) {
        jsonRequest.sendBillInsurance2 = false;
      }

      jsonRequest.treatmentPriceRowId = price.id;
      jsonRequest.priceSource = price.priceSource;
    }
  } else if (seriesType === SidebarTypes.Diagnosis) {
    delete jsonRequest.diagnosisItem2;
  } else if (seriesType !== seriesTypes.existing.code) {
    delete jsonRequest.diagnosisItem;
    delete jsonRequest.diagnosisItem2;
  }

  return jsonRequest;
}

function getCategoryListApi(data) {
  return {
    api: TreatmentApi.getCategoryList,
    types: [
      ActionType.CHART_GET_CATEGORY_LIST_START,
      ActionType.CHART_GET_CATEGORY_LIST_SUCCESS,
      ActionType.CHART_GET_CATEGORY_LIST_ERROR,
    ],
    data,
  };
}

function getProcedureListApi(data) {
  return {
    api: TreatmentApi.getProcedureList,
    types: [
      ActionType.CHART_GET_PROCEDURE_LIST_START,
      ActionType.CHART_GET_PROCEDURE_LIST_SUCCESS,
      ActionType.CHART_GET_PROCEDURE_LIST_ERROR,
    ],
    data,
  };
}

function saveProcedureItemApi(data) {
  return {
    api: TreatmentApi.saveProcedureItem,
    types: [
      ActionType.CHART_SAVE_PROCEDURE_ITEM_START,
      ActionType.CHART_SAVE_PROCEDURE_ITEM_SUCCESS,
      ActionType.CHART_SAVE_PROCEDURE_ITEM_ERROR,
    ],
    data,
  };
}

function saveProcedureRequestItemApi(data) {
  return {
    api: TreatmentApi.saveProcedureRequestItem,
    types: [
      ActionType.CHART_SAVE_PROCEDURE_REQUEST_ITEM_START,
      ActionType.CHART_SAVE_PROCEDURE_REQUEST_ITEM_SUCCESS,
      ActionType.CHART_SAVE_PROCEDURE_REQUEST_ITEM_ERROR,
    ],
    data,
  };
}

function getFavoritesMixedDiagnosisApi(data) {
  return {
    api: DiagnosisApi.getChooseByCode,
    types: [
      ActionType.GET_FAVORITE_MIXED_DIAGNOSIS_START,
      ActionType.GET_FAVORITE_MIXED_DIAGNOSIS_SUCCESS,
      ActionType.GET_FAVORITE_MIXED_DIAGNOSIS_ERROR,
    ],
    data,
  };
}

function getFavoritesMixedExistingApi(data) {
  return {
    api: TreatmentApi.getChooseByCode,
    types: [
      ActionType.GET_FAVORITE_MIXED_EXISTING_START,
      ActionType.GET_FAVORITE_MIXED_EXISTING_SUCCESS,
      ActionType.GET_FAVORITE_MIXED_EXISTING_ERROR,
    ],
    data,
  };
}

function getTreatmentGroupListApi(data) {
  return {
    api: TreatmentApi.getTreatmentGroupList,
    types: [
      ActionType.GET_TREATMENT_GROUP_LIST_START,
      ActionType.GET_TREATMENT_GROUP_LIST_SUCCESS,
      ActionType.GET_TREATMENT_GROUP_LIST_ERROR,
    ],
    data,
  };
}
