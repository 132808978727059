export const ClinicalNoteStackCodes = {
  owned: "owned",
  category: "category",
  list: "list",
  item: "item",
  textarea: "textarea",
  print: "print",
};

export const defaultStackItem = {
  code: ClinicalNoteStackCodes.owned,
};
