import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";
import DocsAddFolderForm from "./DocsAddFolderForm";

/*
 * open: boolean;
 * onRequestClose: () => void;
 * onSubmit: (name: string) => Promise<any>
 */
export default class DocsAddFolder extends React.Component {
  render() {
    const { open } = this.props;
    return (
      <Modal
        show={open}
        onHide={this.props.onRequestClose}
        keyboard={false}
        title={<h5>Add New Folder</h5>}
        actions={
          <Button type="button" onClick={this.props.onRequestClose}>
            Close
          </Button>
        }
      >
        <DocsAddFolderForm onSubmit={this.props.onSubmit} onDismiss={this.props.onRequestClose} />
      </Modal>
    );
  }
}
