import _, { toFinite, get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

import Show from "../../../widgets/Show";
import PackageDealsList from "./PackageDealsList";
import bindActions from "../../../../helpers/bindActions";
import * as chartActions from "../../../../actions/chartActions";
import * as transactionActions from "../../../../actions/transactionActions";
import * as authActions from "../../../../actions/authActions";
import { openSimpleModal } from "../../../../actions/simpleModalActions";
import SelectTreatmentToCompleteNoteModal from "./SelectTreatmentToCompleteNoteModal";
import SelectTreatmentToCompleteFinishMode from "./SelectTreatmentToCompleteFinishMode";
import SelectTreatmentToCompleteDentistForm from "./SelectTreatmentToCompleteDentistForm";
import SelectTreatmentToCompleteDentistFormFilter from "./SelectTreatmentToCompleteDentistFormFilter";
import BackImage from "../../../../assets/images/white-icon-back.png";
import { SelectPicker } from "../../../select-picker/SelectPicker";
import { Routes } from "../../../../constants/Routes";
import { withRouter } from "react-router";
import SelectedTreatmentToCompletePatientInfo from "./SelectedTreatmentToCompletePatientInfo";
import transactionsSortFields from "../../../../constants/chart-sidebar/transactionsSortFields";
import SortPicker from "../../../widgets/SortPicker";
import Element from "../../../../models/Element";
import { TextInput } from "../../../ui/TextInput";
import { updateTransactionList } from "../../../../actions/transactionActions";
import { updateTransactionDiscountApi } from "../../../../actions/chartSideTransactionActions";
import { startLoader, stopLoader } from "../../../../actions/loaderActions";
import { completeAppointmentByUserTypes } from "../../../../constants/Constants";

const enhancer = connect(
  ({ auth, session }) => ({ auth, session }),
  bindActions({
    chartActions,
    transactionActions,
    authActions,
    openSimpleModal,
    startLoader,
    stopLoader,
    updateTransactionList,
    updateTransactionDiscountApi,
  }),
);
export default withRouter(
  enhancer(
    class SelectTreatmentToCompleteModal extends React.Component {
      constructor(props) {
        super(props);

        const {
          session: { clinic },
        } = props;

        this.state = {
          clinicFilter: {
            label: clinic.name,
            value: clinic.id,
          },
          TRANSACTIONS_TO_COMPLETE_GROUP_BY: "transactionsToCompleteGroupBy",
          element: {
            transactionsSortBtn: new Element(),
          },
          desiredBillAmount: null,
          updatedTotalNetAmount: null,
          totalGivenPercentage: 0,
        };
      }

      componentDidMount() {
        this.props.chartActions.clearList();
        this.initializeTransactionSortOptions();
        this.transactionSortHandler();

        if (this.props.chart.selectTreatmentToCompleteModalMode === "finish") {
          this.props.chartActions.getPatientOutstandingAndAdvancedBalance();
        }
      }

      desiredBillAmountCalculateHandler() {
        const {
          selectTreatmentToCompleteList,
          selectTreatmentToCompleteSelectedItems,
        } = this.props.chart;
        const {
          auth: { members },
          session: { member },
          openSimpleModal,
        } = this.props;
        const currentMember = members[member.id];
        const memberDiscountLimit = currentMember.discountPercentLimit;
        const { desiredBillAmount } = this.state;

        const filteredTreatmentList = selectTreatmentToCompleteList.filter(Boolean);

        if (desiredBillAmount > 0) {
          const totalPrice = filteredTreatmentList.reduce((acc, treatment) => {
            if (selectTreatmentToCompleteSelectedItems[treatment.key]) {
              return acc + Number(treatment.price);
            }
            return acc;
          }, 0);

          let canUpdatePrice = false;

          filteredTreatmentList.forEach((treatment) => {
            if (selectTreatmentToCompleteSelectedItems[treatment.key]) {
              const priceWeight = (Number(treatment.price) / totalPrice) * 100;
              const treatmentPrice = priceWeight * 0.01 * Number(desiredBillAmount);
              const treatmentPricePercentage =
                ((treatment.price - treatmentPrice) / treatment.price) * 100;
              if (treatmentPricePercentage > memberDiscountLimit) {
                openSimpleModal({ body: `Allowed discount is ${memberDiscountLimit}%` });
                canUpdatePrice = false;
                return;
              }
              treatment.updatedPrice = Math.round(treatmentPrice);
              treatment.updatedDiscount = treatmentPricePercentage;
              canUpdatePrice = true;
            }
          });

          if (canUpdatePrice) {
            this.props.startLoader();

            const promises = [];
            filteredTreatmentList.forEach((treatment) => {
              const data = {
                keys: [treatment.key],
                discount: {
                  code: "PERCENTAGE",
                  value: treatment.updatedDiscount,
                },
              };
              if (toFinite(treatment.updatedDiscount)) {
                promises.push(this.props.updateTransactionDiscountApi(data));
              }
            });

            Promise.all(promises)
              .then(() => {
                this.props.stopLoader();
                this.props.chartActions.selectTreatmentToCompleteGetList();
              })
              .catch((e) => this.props.stopLoader(e));
          }

          const updatedTotalTreatmentPrice = filteredTreatmentList.reduce((acc, treatment) => {
            if (selectTreatmentToCompleteSelectedItems[treatment.key]) {
              return acc + Number(treatment.updatedPrice);
            }
            return acc;
          }, 0);
          const totalGivenPercentage = (totalPrice - Number(desiredBillAmount)) * 0.1;
          this.setState({
            updatedTotalNetAmount: updatedTotalTreatmentPrice,
            totalGivenPercentage,
          });
        }
      }

      initializeTransactionSortOptions() {
        const { element, TRANSACTIONS_TO_COMPLETE_GROUP_BY } = this.state;
        const transactionToCompleteFilter = localStorage.getItem(TRANSACTIONS_TO_COMPLETE_GROUP_BY);

        element.transactionsSortBtn.options = transactionsSortFields.map((item, idx) => {
          return new Element(item.name, idx + 1, idx, item.value);
        });
        element.transactionsSortBtn.selected =
          element.transactionsSortBtn.options.find(
            (option) => option.name === transactionToCompleteFilter,
          ) || element.transactionsSortBtn.options[0];
      }

      transactionSortHandler() {
        const { element, TRANSACTIONS_TO_COMPLETE_GROUP_BY } = this.state;

        element.transactionsSortBtn.handler = (item, index) => {
          element.transactionsSortBtn.selected = item;
          element.transactionsSortBtn.options[index].selected = true;
          localStorage.setItem(TRANSACTIONS_TO_COMPLETE_GROUP_BY, item.name);
          this.props.chartActions.updateTransactionsToCompleteFilter(item.name);
        };
      }

      complete = (isPlanning) => {
        const {
          session: {
            miscSettings: {
              notAllowCompletingAppointmentWithoutDiagnosis,
              notAllowCompletingAppointmentWithoutTreatment,
            },
          },
          chart: {
            selectTreatmentToCompleteSelectedItems,
            selectTreatmentToCompleteList,
            checkedItems,
          },
          chartActions: { openModal },
        } = this.props;
        let checkedTreatment = false;
        let checkedDiagnosis = false;
        for (const key in checkedItems) {
          if (checkedItems[key]) {
            checkedTreatment = key.indexOf("TREATMENT") > -1;
            checkedDiagnosis = key.indexOf("DIAGNOSIS") > -1;
          }
        }
        for (const key in selectTreatmentToCompleteSelectedItems) {
          if (selectTreatmentToCompleteSelectedItems[key]) {
            // eslint-disable-next-line no-loop-func
            selectTreatmentToCompleteList.forEach((treatment) => {
              if (
                treatment.key === key &&
                treatment.diagnosisItem &&
                treatment.diagnosisItem.code
              ) {
                checkedDiagnosis = true;
              }
            });
          }
        }
        if (notAllowCompletingAppointmentWithoutTreatment && !checkedTreatment) {
          return openModal("Please select treatment item to complete");
        }
        if (notAllowCompletingAppointmentWithoutDiagnosis && !checkedDiagnosis) {
          return openModal("Please select diagnosis item to complete");
        }

        this.props.chartActions.selectTreatmentToCompleteNoteModalShow(isPlanning);
      };
      componentWillUnmount() {
        this.props.transactionActions.doctorFilterChange([]);
        this.props.authActions.selectDoctor(0);
      }

      render() {
        const {
          session,
          chart,
          chart: {
            selectTreatmentToCompleteList,
            selectTreatmentToCompleteSelectedItems,
            selectTreatmentToCompletePatientInfoVisible,
            appointment,
          },
          auth,
          members,
          chosenMemberId,
          isPlanning,
        } = this.props;

        const { miscSettings } = session;
        const { clinicFilter, element } = this.state;
        const appointmentDentist = get(appointment, "dentist");
        const completeAppointmentBy = get(miscSettings, "completeAppointmentByUser");
        const completeAppointmentByUser =
          completeAppointmentBy === completeAppointmentByUserTypes.LOGGED_USER
            ? chosenMemberId
            : appointmentDentist?.id;

        let totalSelectedService = 0;
        let totalDiscountGiven = 0;
        let totalPatientShare = 0;

        const sum = _.reduce(
          selectTreatmentToCompleteList.filter(Boolean),
          (x, value) => {
            const { key, price, discount } = value;
            if (selectTreatmentToCompleteSelectedItems[key]) {
              totalSelectedService += price;
              totalPatientShare += value?.patientShare || 0;
              totalDiscountGiven += toFinite(discount ? discount.value : 0);
              if (discount?.code === "PERCENTAGE") {
                return x + (price - (price / 100) * toFinite(discount ? discount.value : 0));
              } else return x + price - toFinite(discount ? discount.value : 0);
            }

            return x;
          },
          0,
        );

        const allClinics = [
          {
            label: "All Clinics",
            value: "all",
          },
        ];

        const clinicList = _.get(auth, "clinics", []).map((x) => ({
          value: x.id,
          label: x.name,
        }));

        const {
          selectTreatmentToCompleteModalVisible,
          selectTreatmentToCompleteModalMode,
        } = this.props.chart;

        const initialValues = {
          closedDentistId: completeAppointmentByUser,
        };
        const NOT_STOP_AND_NOT_FINISH_COMPLETE_MODAL_MODE =
          selectTreatmentToCompleteModalMode !== "stop" &&
          selectTreatmentToCompleteModalMode !== "finish" &&
          selectTreatmentToCompleteModalMode !== "post";

        return (
          <Modal
            size="lg"
            show={selectTreatmentToCompleteModalVisible}
            onHide={this.props.chartActions.selectTreatmentToCompleteModalHide}
            className="select-treatment-to-complete-modal"
            keyboard={false}
            bodyProps={
              selectTreatmentToCompleteModalMode === "finish" ||
              selectTreatmentToCompleteModalMode === "post"
                ? { style: { paddingBottom: 0, paddingLeft: 0 } }
                : {}
            }
            title={
              selectTreatmentToCompleteModalMode !== "finish" &&
              selectTreatmentToCompleteModalMode !== "post" ? (
                <div className="d-flex align-items-center">
                  <div className="col-sm-2">
                    <SelectPicker
                      placeholder="Filter by clinic"
                      value={clinicFilter}
                      options={[...allClinics, ...clinicList]}
                      className="transaction-clinic-filter"
                      style={{ width: "200px" }}
                      onChange={(v) => {
                        this.props.chartActions.selectTreatmentToCompleteToggleSelectedItemClear();
                        this.setState({ clinicFilter: v });
                      }}
                    />
                  </div>
                  <div className="col-sm-10">
                    <Show if={selectTreatmentToCompleteModalMode !== "stop"}>
                      <SelectTreatmentToCompleteDentistForm
                        initialValues={initialValues}
                        members={members}
                        selectTreatmentToCompleteDoctorsFilter={
                          chart.selectTreatmentToCompleteDoctorsFilter
                        }
                      />
                    </Show>
                  </div>
                </div>
              ) : (
                <>
                  <div className="treatment-complete-modal">
                    <div className="fluid-cell">
                      <SortPicker element={element.transactionsSortBtn} />
                    </div>
                    <button
                      className="btn btn-default"
                      onClick={() => this.props.chartActions.selectTreatmentToCompleteGetList()}
                    >
                      Refresh
                    </button>
                    <Button
                      onClick={this.props.chartActions.selectTreatmentToCompleteModalHide}
                      type="button"
                      className="btn btn-danger"
                    >
                      Close
                    </Button>
                    {selectTreatmentToCompleteModalMode !== "finish" &&
                      selectTreatmentToCompleteModalMode !== "post" && (
                        <>
                          <div className="col-sm-2">
                            <SelectPicker
                              placeholder="Filter by clinic"
                              value={clinicFilter}
                              options={[...allClinics, ...clinicList]}
                              className="transaction-clinic-filter"
                              style={{ width: "200px" }}
                              onChange={(v) => {
                                this.props.chartActions.selectTreatmentToCompleteToggleSelectedItemClear();
                                this.setState({ clinicFilter: v });
                              }}
                            />
                          </div>
                          <div className="col-sm-10">
                            <Show if={selectTreatmentToCompleteModalMode !== "stop"}>
                              <SelectTreatmentToCompleteDentistForm
                                initialValues={initialValues}
                                members={members}
                                selectTreatmentToCompleteDoctorsFilter={
                                  chart.selectTreatmentToCompleteDoctorsFilter
                                }
                              />
                            </Show>
                          </div>
                        </>
                      )}
                  </div>
                </>
              )
            }
            actions={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  {NOT_STOP_AND_NOT_FINISH_COMPLETE_MODAL_MODE && (
                    <>
                      <span
                        style={{
                          marginRight: "25px",
                        }}
                      >
                        Total: {sum.toFixed(2)}
                      </span>

                      <Button
                        onClick={() => this.props.chartActions.setAdvancedPaymentModalVisible(true)}
                        type="button"
                        className="btn btn-default"
                      >
                        Request for adv.payment
                      </Button>
                      <Button
                        onClick={this.props.chartActions.selectTreatmentToCompleteModalHide}
                        type="button"
                        className="btn btn-default"
                      >
                        Close
                      </Button>
                    </>
                  )}

                  {selectTreatmentToCompleteModalMode !== "stop" && (
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="d-flex align-items-center justify-content-between">
                        <TextInput
                          type="number"
                          name="desiredBillAmount"
                          placeholder="Enter Desired Bill Amount"
                          style={{ minWidth: 180 }}
                          onChange={(event) =>
                            this.setState({ desiredBillAmount: event.target.value })
                          }
                          value={this.state.desiredBillAmount}
                        />
                        <Button
                          type="button"
                          className="btn btn-info ml-1 mr-1 d-flex flex-shrink-0"
                          onClick={() => this.desiredBillAmountCalculateHandler()}
                        >
                          Calculate
                        </Button>
                      </div>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          this.props.chartActions
                            .selectTreatmentToCompleteNoteModalShow(isPlanning)
                            .then((response) => {
                              if (_.get(response, "response")) {
                                if (selectTreatmentToCompleteModalMode !== "post")
                                  this.props.history.push(Routes.DashboardCalendar);
                              }
                            })
                        }
                      >
                        Complete
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <div
              className={`select-treatment-to-complete-modal-body ${
                selectTreatmentToCompletePatientInfoVisible ? "menu-visible" : ""
              }`}
              style={{ height: "100%", display: "flex", overflow: "hidden" }}
            >
              {(selectTreatmentToCompleteModalMode === "post" ||
                selectTreatmentToCompleteModalMode === "finish") && (
                <div className="select-treatment-to-complete-modal-body__left">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      className="menu-toggler"
                      role="button"
                      onClick={() => this.props.chartActions.selectTreatmentToCompleteToggleMenu()}
                    >
                      <img
                        src={BackImage}
                        style={
                          !selectTreatmentToCompletePatientInfoVisible
                            ? { transform: `rotate(180deg)` }
                            : {}
                        }
                        width={12}
                        alt=""
                      />
                    </div>
                    <div className={"select-treatment-to-complete-patient-info"}>
                      <SelectedTreatmentToCompletePatientInfo
                        chart={chart}
                        session={session}
                        chartActions={this.props.chartActions}
                        auth={auth}
                        members={members}
                      />
                      <label className="pl-2" style={{ marginTop: "18px" }}>
                        Filter by Clinic/Branch
                      </label>
                      <SelectPicker
                        placeholder="Filter by clinic"
                        value={clinicFilter}
                        options={[...allClinics, ...clinicList]}
                        className="transaction-chart-clinic-filter"
                        style={{ width: "200px" }}
                        onChange={(v) => {
                          this.props.chartActions.selectTreatmentToCompleteToggleSelectedItemClear();
                          this.setState({ clinicFilter: v });
                        }}
                      />
                      <Show if={selectTreatmentToCompleteModalMode !== "stop"}>
                        <SelectTreatmentToCompleteDentistFormFilter
                          initialValues={initialValues}
                          members={members}
                          selectTreatmentToCompleteDoctorsFilter={
                            chart.selectTreatmentToCompleteDoctorsFilter
                          }
                        />
                      </Show>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="select-treatment-to-complete-modal-body__right"
                style={
                  selectTreatmentToCompleteModalMode === "finish" ? { overflow: "hidden" } : {}
                }
              >
                <Show if={selectTreatmentToCompleteModalMode === "post"}>
                  <SelectTreatmentToCompleteFinishMode
                    chart={chart}
                    clinicId={clinicFilter.value}
                    totalNetAmount={sum}
                    updatedTotalNetAmount={this.state.updatedTotalNetAmount}
                    totalSelectedService={totalSelectedService}
                    totalDiscountGiven={Number(
                      this.state.updatedTotalNetAmount
                        ? totalSelectedService - this.state.updatedTotalNetAmount
                        : this.state.updatedTotalNetAmount,
                    )}
                    totalPatientShare={totalPatientShare}
                    selectTreatmentToCompleteModalMode={selectTreatmentToCompleteModalMode}
                  />
                </Show>
                <Show if={selectTreatmentToCompleteModalMode === "stop"}>
                  <PackageDealsList clinicId={clinicFilter.value} chart={chart} />
                </Show>
                <Show if={selectTreatmentToCompleteModalMode === "finish"}>
                  <SelectTreatmentToCompleteFinishMode
                    chart={chart}
                    clinicId={clinicFilter.value}
                    totalNetAmount={sum}
                    updatedTotalNetAmount={this.state.updatedTotalNetAmount}
                    totalSelectedService={totalSelectedService}
                    totalDiscountGiven={Number(
                      this.state.updatedTotalNetAmount
                        ? totalSelectedService - this.state.updatedTotalNetAmount
                        : this.state.updatedTotalNetAmount,
                    )}
                    totalPatientShare={totalPatientShare}
                    selectTreatmentToCompleteModalMode={selectTreatmentToCompleteModalMode}
                  />
                </Show>
              </div>
            </div>
            <SelectTreatmentToCompleteNoteModal chart={chart} isPlanning={isPlanning} />
          </Modal>
        );
      }
    },
  ),
);
