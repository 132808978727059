import React from "react";
import cx from "classnames";
import ReactSpinner from "react-spinjs-fix";

import Show from "./Show";

function onScrollList(event, props) {
  if (props.onScroll) {
    const scrollBottom =
      Math.ceil(event.target.scrollTop + event.target.offsetHeight) ===
      Math.ceil(event.target.scrollHeight);
    if (scrollBottom) {
      props.onScroll();
    }
  }
}

function DataList({ style = {}, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        flex: "1 1",
        flexDirection: "column",
        ...style,
      }}
    >
      {props.header && <div style={{ flexShrink: 0 }}>{props.header}</div>}
      <div
        className={cx("dental-data-list", props.className)}
        onScroll={(event) => {
          if (!props.fetching) {
            onScrollList(event, props);
          }
        }}
      >
        {props.children}
      </div>

      <Show if={props.fetching}>
        <div className="dental-react-spinner">
          <ReactSpinner />
        </div>
      </Show>
    </div>
  );
}

export default DataList;
