import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import Back from "../widgets/Back";
import FlexBox, { FlexBoxFlex } from "../ui/FlexBox";
import MemberImage from "../widgets/MemberImage";

import Utils from "../../helpers/Utils";
import * as dashboardActions from "../../actions/dashboardActions";
import { withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import logoImage from "../../assets/images/clinic_logo.png";
import profileImage from "../../assets/images/profile/profile.png";
import contactsImage from "../../assets/images/profile/contacts.png";
import alertsImage from "../../assets/images/profile/alerts.png";
import defaultImage from "../../assets/images/profile/add_photo.png";
import BackGroundImage from "../../assets/images/login_bg_image.jpg";

const enhancer = connect(({ dashboard }) => ({ dashboard }), null);
class Profile extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };
  componentDidMount() {
    this.props.dispatch(dashboardActions.initProfile());
  }

  openProfile = () => {
    this.props.history.push(Routes.DashboardProfileEdit);
  };

  render() {
    const {
      doctorDetails: { memberDetails = {}, memberWorkingHours = {} },
    } = this.props.dashboard;

    return (
      <div className="profile-page" style={{ backgroundImage: `url(${BackGroundImage})` }}>
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" onClick={() => this.props.history.goBack()} />
          <img alt="" src={logoImage} className="header-logo" />
        </div>
        <FlexBox container={8} flex={true} direction="column" className="patient-view-flex">
          <FlexBox gutter={8} flex={FlexBoxFlex.None}>
            <FlexBox flex={FlexBoxFlex.Grow2}>
              <div className="profile">
                <div className="action">
                  <MemberImage
                    photoUrl={memberDetails.photo && memberDetails.photo.url}
                    defaultSrc={defaultImage}
                  />
                </div>
                <div className="info" onClick={this.openProfile}>
                  <div className="body">
                    <div>
                      <strong>{Utils.getFullName(memberDetails)}</strong>
                    </div>
                    <div>{getAge(memberDetails.birthDate)}</div>
                  </div>
                  <div className="footer">
                    <div className="text">Profile</div>
                    <div className="icon">
                      <img src={profileImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </FlexBox>
            <FlexBox flex={true}>
              <div className="contacts" onClick={this.openProfile}>
                <div className="body">
                  <div>
                    <div>{memberDetails.mobilePhoneNumber} (M)</div>
                    <div>{memberDetails.homePhoneNumber} (H)</div>
                    <div>{memberDetails.workPhoneNumber} (W)</div>
                  </div>
                  <div>{memberDetails.email}</div>
                </div>
                <div className="footer">
                  <div className="text">Contacts</div>
                  <div className="icon">
                    <img src={contactsImage} alt="" />
                  </div>
                </div>
              </div>
            </FlexBox>
            <FlexBox flex={true}>
              <div className="working-hours" onClick={this.openProfile}>
                <div className="body">
                  <div>
                    <span>
                      {memberWorkingHours.day1 &&
                        memberWorkingHours.day1.weekDays &&
                        memberWorkingHours.day1.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day1 && memberWorkingHours.day1.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day1 && memberWorkingHours.day1.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day2 &&
                        memberWorkingHours.day2.weekDays &&
                        memberWorkingHours.day2.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day2 && memberWorkingHours.day2.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day2 && memberWorkingHours.day2.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day3 &&
                        memberWorkingHours.day3.weekDays &&
                        memberWorkingHours.day3.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day3 && memberWorkingHours.day3.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day3 && memberWorkingHours.day3.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day4 &&
                        memberWorkingHours.day4.weekDays &&
                        memberWorkingHours.day4.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day4 && memberWorkingHours.day4.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day4 && memberWorkingHours.day4.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day5 &&
                        memberWorkingHours.day5.weekDays &&
                        memberWorkingHours.day5.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day5 && memberWorkingHours.day5.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day5 && memberWorkingHours.day5.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day6 &&
                        memberWorkingHours.day6.weekDays &&
                        memberWorkingHours.day6.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day6 && memberWorkingHours.day6.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day6 && memberWorkingHours.day6.workEnd}
                    </span>
                  </div>
                  <div>
                    <span>
                      {memberWorkingHours.day7 &&
                        memberWorkingHours.day7.weekDays &&
                        memberWorkingHours.day7.weekDays.name}
                    </span>
                    <span>
                      {memberWorkingHours.day7 && memberWorkingHours.day7.workStart}
                      &nbsp;-&nbsp;
                      {memberWorkingHours.day7 && memberWorkingHours.day7.workEnd}
                    </span>
                  </div>
                </div>
                <div className="footer">
                  <div className="text">Working Hours</div>
                  <div className="icon">
                    <img src={alertsImage} alt="" />
                  </div>
                </div>
              </div>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </div>
    );
  }
}
export default withRouter(enhancer(Profile));
function getAge(timestamp) {
  const dateOfBirthday = moment(timestamp);

  return moment().diff(dateOfBirthday, "years") + " y.o. " + dateOfBirthday.format("DD/MM/YYYY");
}
