import React from "react";
import { Modal } from "../../../ui/Modal";
import Utils from "../../../../helpers/Utils";
import FlexBox from "../../../ui/FlexBox";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";

export default class ChartSideListCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    const { showModal } = this.state;
    const { item, openItem, hasRemove, removeItem } = this.props;

    return (
      <button
        type="button"
        onClick={openItem(item)}
        style={{ paddingRight: "14px" }}
        className="btn btn-default btn-block chart-clinical-note-item"
      >
        <FlexBox>
          <FlexBox direction="column" flex={true}>
            <div className="text-ellipsis">
              <strong>{item.name}</strong>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-6">
                by
                {item.dentist && item.dentist.name}
              </div>
              <div className="col-sm-6 text-right">
                {Utils.parseDateFormat(
                  Utils.calcDateWithTimeZone(item.createdDate),
                  DATE_FORMAT_LEGACY,
                )}
              </div>
            </div>
          </FlexBox>

          {hasRemove && (
            <FlexBox
              role="button"
              align="center"
              justify="center"
              style={{ marginLeft: "12px" }}
              onClick={(event) => {
                event.stopPropagation();

                this.setState({ showModal: true });
              }}
            >
              <div className="clinical-note-item-delete" />
            </FlexBox>
          )}
        </FlexBox>

        <Modal
          show={showModal}
          animation={false}
          size="sm"
          onHide={() => this.setState({ showModal: false })}
          actions={
            <div>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.setState({ showModal: false })}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  removeItem(item.id);

                  this.setState({ showModal: false });
                }}
              >
                Delete
              </button>
            </div>
          }
          keyboard={false}
        >
          <div>Are you sure you want to delete note?</div>
        </Modal>
      </button>
    );
  }
}
