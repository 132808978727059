const prescriptionSortFields = [
  {
    code: "dentist_name",
    name: `${localStorage.getItem("doctorLabel")} name (asc)`,
  },
  {
    code: "dentist_name_desc",
    name: `${localStorage.getItem("doctorLabel")} name (desc)`,
  },
  {
    code: "created_date",
    name: "Date (asc)",
  },
  {
    code: "created_date_desc",
    name: "Date (desc)",
  },
  {
    code: "prescription_name",
    name: "Prescription name (asc)",
  },
  {
    code: "prescription_name_desc",
    name: "Prescription name (desc)",
  },
];

export default prescriptionSortFields;
