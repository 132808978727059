import React from "react";
import moment from "moment";
import _, { noop, toFinite } from "lodash";
import PropTypes from "prop-types";
import { update } from "immupdate";
import { Form, Formik } from "formik";

import { DOCTORS_MODE } from "../widgets/DoctorsSelect";
import { DoctorsSelectWrapper } from "../../wrappers/DoctorsSelectWrapper";
import { DateField } from "../form/DateField";
import { DoctorSelectField } from "../form/DoctorSelectField";
import { connect } from "react-redux";
import { resetDoctors, selectDoctor } from "../../actions/authActions";
import { choosePoint } from "../../actions/calendarActions";

const enhancer = connect(() => ({}), { resetDoctors, selectDoctor, choosePoint });

BringEarlierFilterForm.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
  choosePoint: PropTypes.func,
  resetDoctors: PropTypes.func,
  selectDoctor: PropTypes.func,
};

function BringEarlierFilterForm({ initialValues, onChange, selectDoctor }) {
  return (
    <Formik initialValues={initialValues} onSubmit={noop}>
      {({ values }) => (
        <Form className="d-flex flex-row mr-2">
          <div
            className="ml-2"
            style={{
              width: "200px",
            }}
          >
            <DoctorSelectField
              name="doctors"
              isMulti={true}
              isSearchable={true}
              allLabel="All"
              onKeyDown={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  e.preventDefault();
                  // handleSubmit();
                }
              }}
              customClinicId={values.clinic && values.clinic.value}
              onChange={(v, m) => {
                if (m?.option?.label == "ALl") {
                  selectDoctor(0);
                }
                selectDoctor(m?.value || m?.option?.value);
              }}
            />
          </div>
          {/*<div style={{ zIndex: 100 }} className="d-flex align-items-center">*/}
          {/*  <DoctorsSelectWrapper mode={DOCTORS_MODE} allDoctorsTitle="All" />*/}
          {/*</div>*/}
          <div className="d-flex align-items-center ml-2">
            <label className="text-primary mr-1 mb-0">From</label>
            <DateField
              name="fromDate"
              pickerClassName="form-control"
              minDate={moment().subtract(1, "days")}
              onChange={(x) => onChange(update(values, { fromDate: x }))}
            />
          </div>
          <div className="d-flex align-items-center ml-2">
            <label className="text-primary mb-0">To</label>
            <DateField
              name="toDate"
              minDate={values.fromDate}
              pickerClassName="form-control ml-1"
              onChange={(x) => onChange(update(values, { toDate: x }))}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default enhancer(BringEarlierFilterForm);
