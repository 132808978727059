import _ from "lodash";
import React from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Show from "../widgets/Show";
import AppointmentRelatedModalThis from "./AppointmentRelatedModalThis";
import AppointmentRelatedModalThat from "./AppointmentRelatedModalThat";

import bindActions from "../../helpers/bindActions";
import { showSideBar } from "../../actions/chartActions";
import * as appointmentActions from "../../actions/appointmentActions";
import Utils from "../../helpers/Utils";
import {
  changeTransactionItem,
  changeTreatmentNote,
  changeTreatmentPlan,
  changeTreatmentSession,
  changeUnit,
  setTransactionData,
} from "../../actions/transactionActions";
import { JSON_FILES } from "../../constants/Constants";
import TransactionSearchParameters from "../../models/TransactionSearchParameters";
import { Sidebar } from "../sidebar/Sidebar";
import { SelectPicker } from "../select-picker/SelectPicker";

const enhancer = connect(
  ({ appointment, auth, session }) => {
    const member = _.get(session, "member");
    const members = _.get(auth, "members", []);

    return {
      member,
      members,
      appointment,
    };
  },
  bindActions({
    appointmentActions,
    showSideBar,
    changeTreatmentPlan,
    changeTreatmentSession,
    changeUnit,
    changeTreatmentNote,
    setTransactionData,
    changeTransactionItem,
  }),
);

class AppointmentRelatedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      doctorId: _.get(props, "member.id"),
      searchParameters: new TransactionSearchParameters(),
      thisDentistId: null,
    };
  }

  handleChangeTreatmentPlan = (value, key, patientKey) => {
    this.props.changeTreatmentPlan(
      {
        key,
        patientKey,
        treatmentPlan: value,
        currentDentistId: null,
        currentAppointmentId: null,
      },
      false,
    );
  };

  handleChangeTreatmentSession = (value, key, patientKey) => {
    this.props.changeTreatmentSession(
      {
        key,
        patientKey,
        currentDentistId: null,
        treatmentSession: value,
        currentAppointmentId: null,
      },
      false,
    );
  };

  handleChangeUnit = (value, key, patientKey) => {
    this.props.changeUnit(
      {
        key,
        patientKey,
        unit: value,
        currentDentistId: null,
        currentAppointmentId: null,
      },
      false,
    );
  };

  handleChangeTreatmentNote = (value, key, patientKey) => {
    this.props.changeTreatmentNote({
      key,
      patientKey,
      note: value,
    });
  };

  handleChangeAssignedDoctor = (assignedDoctor, key) => {
    const { appointment } = this.props.appointment;

    const searchParameters = this.state.searchParameters;
    let transaction = this.props.transaction;
    this.props.setTransactionData(transaction);

    let request = {};
    request.currentAppointmentId = +searchParameters.appointmentId;
    request.currentDentistId = appointment && appointment.dentist.id;
    request.patientKey = searchParameters.patientKey;
    request.key = key;
    request["assignedDoctor"] = assignedDoctor;
    request.jsonFile = JSON_FILES.CHANGE_ASSIGNED_DOCTOR;
    this.props.changeTransactionItem(request);
  };

  componentDidUpdate(prevProps) {
    const {
      appointment: { codesForThisModalVisible: prevVisible },
    } = prevProps;
    const {
      appointment: { codesForThisModalVisible: nextVisible, appointment },
    } = this.props;

    if (!prevVisible && nextVisible) {
      this.props.appointmentActions.initRelatedModal();
      this.props.appointmentActions.initRequestList();
      appointment && this.setState({ thisDentistId: appointment.dentist.id });
    }
  }

  render() {
    const { members } = this.props;
    const {
      codesForThisModalVisible,
      thatMode,
      thisSelected,
      thatSelected,
      relatedTab,
    } = this.props.appointment;

    let $body = null;

    const selectedList = _.map(thisSelected, (x, id) => (x ? _.toFinite(id) : "")).filter(Boolean);

    if (!thatMode) {
      $body = (
        <AppointmentRelatedModalThis
          doctorId={this.state.thisDentistId}
          appointment={this.props.appointment}
          location={this.props.location}
          history={this.props.history}
          item={this.props.item}
        />
      );
    } else {
      $body = (
        <AppointmentRelatedModalThat
          changeUnit={this.handleChangeUnit}
          appointment={this.props.appointment}
          changeAssignedDoctor={this.handleChangeAssignedDoctor}
          changeTreatmentNote={this.handleChangeTreatmentNote}
          changeTreatmentPlan={this.handleChangeTreatmentPlan}
          changeTreatmentSession={this.handleChangeTreatmentSession}
        />
      );
    }

    const doctorOptions = _.map(members, (item) => ({
      label: Utils.getFullName(item),
      value: item.id,
    }));

    return (
      <Sidebar show={codesForThisModalVisible} dialogClassName="chart-finish-modal">
        <div className={cx("modal-header", { "appointment-related-modal-header": !thatMode })}>
          {thatMode ? (
            <div>Assign treatment codes to this appointment</div>
          ) : (
            <div className="appointment-related-button-container">
              <button
                onClick={() => this.props.appointmentActions.changeAppointmentRelatedTab("first")}
                className={cx("appointment-related-button btn btn-default left-button", {
                  "btn-success": relatedTab === "first",
                })}
              >
                Treatment codes assigned for this appointment
              </button>
              <button
                onClick={() => this.props.appointmentActions.changeAppointmentRelatedTab("second")}
                className={cx("appointment-related-button btn btn-default", {
                  "btn-success": relatedTab === "second",
                })}
              >
                Requested by patient
              </button>
              <button
                className={cx("appointment-related-button right-button btn btn-default disabled", {
                  "btn-success": relatedTab === "third",
                })}
              >
                Packages
              </button>
            </div>
          )}
        </div>

        <div className="modal-body">{$body}</div>

        <div className="modal-footer">
          <Show if={relatedTab === "second"}>
            <div className="pull-left" style={{ display: "flex", marginRight: "12px" }}>
              <label className="text-left" style={{ marginRight: "12px" }}>
                Change value, to complete a<br />
                Service/Treatment by another Doctor
              </label>

              <div className="d-flex text-left" style={{ width: "200px" }}>
                <SelectPicker
                  menuPlacement="top"
                  options={doctorOptions}
                  isSearchable={true}
                  value={{
                    value: !thatMode
                      ? this.state.thisDentistId
                        ? this.state.thisDentistId
                        : null
                      : this.state.doctorId
                      ? this.state.doctorId
                      : null,
                  }}
                  defaultValue={{
                    value: !thatMode
                      ? this.state.thisDentistId
                        ? this.state.thisDentistId
                        : null
                      : this.state.doctorId
                      ? this.state.doctorId
                      : null,
                  }}
                  onChange={(v) =>
                    !thatMode
                      ? this.setState({ thisDentistId: v.value })
                      : this.setState({ doctorId: v.value })
                  }
                />
              </div>
            </div>
          </Show>

          <Show if={!thatMode && _.filter(thisSelected, (item) => item).length}>
            <button
              className="btn btn-lg pull-left"
              onClick={this.props.appointmentActions.removeThis}
            >
              Remove selected
            </button>
          </Show>

          <Show if={!thatMode}>
            <div className="pull-right">
              <Show if={relatedTab === "second" && selectedList.length > 0}>
                <button
                  className="btn btn-lg btn-success"
                  onClick={() =>
                    this.props.appointmentActions.convertRequestToPlanned(
                      selectedList,
                      "PLANNED",
                      this.state.thisDentistId ? this.state.thisDentistId : null,
                    )
                  }
                >
                  Add to planned
                </button>
              </Show>
              <Show if={relatedTab === "second" && selectedList.length > 0}>
                <button
                  className="btn btn-lg btn-info mr-3"
                  onClick={() =>
                    this.props.appointmentActions.convertRequestToPlanned(
                      selectedList,
                      "COMPLETED",
                      this.state.thisDentistId ? this.state.thisDentistId : null,
                    )
                  }
                >
                  Add to completed
                </button>
              </Show>
              <button
                className="btn btn-lg"
                onClick={this.props.appointmentActions.closeCodesForThisModal}
              >
                Close
              </button>
              <Show if={relatedTab === "first"}>
                <button
                  className="btn btn-lg btn-success"
                  onClick={this.props.appointmentActions.toggleThatMode}
                >
                  Add
                </button>
              </Show>
              <Show if={relatedTab === "second"}>
                <button
                  className="btn btn-lg btn-success"
                  onClick={() => this.props.showSideBar("")}
                >
                  Add
                </button>
              </Show>
            </div>
          </Show>
          <Show if={thatMode && _.filter(thatSelected, (item) => item).length}>
            <button
              className="btn btn-lg pull-left"
              onClick={this.props.appointmentActions.unselectThat}
            >
              Unselect
            </button>
          </Show>
          <Show if={thatMode}>
            <div className="pull-right">
              <button className="btn btn-lg" onClick={this.props.appointmentActions.toggleThatMode}>
                Close
              </button>
              <button
                className="btn btn-lg btn-success"
                onClick={this.props.appointmentActions.addThat}
              >
                Add selected
              </button>
            </div>
          </Show>
        </div>
      </Sidebar>
    );
  }
}

export default enhancer(AppointmentRelatedModal);
