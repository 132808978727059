import React from "react";
import { connect } from "react-redux";

import BackButton from "../../../widgets/BackButton";
import ChartPostOpCategoryItem from "../ChartListItem";
import ChartSideSearch from "../ChartSideSearch";

import postOpItemTypes from "../../../../constants/chart-sidebar/postOpItemTypes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePostOpActions from "../../../../actions/chartSidePostOpActions";
import DataList from "../../../widgets/DataList";

const enhancer = connect(null, bindActions({ chartSidePostOpActions }));

class ChartPostOpList extends React.Component {
  componentDidMount() {
    this.props.chartSidePostOpActions.getTemplateList();
  }

  openItem = (item) => {
    return () => {
      this.props.chartSidePostOpActions.openItem(item, postOpItemTypes.new);
    };
  };

  loadList = () => this.props.chartSidePostOpActions.loadTemplateList();

  onSearch = (event) => {
    this.props.chartSidePostOpActions.setSearchValue(event.target.value);
  };

  render() {
    const { templateList, fetching, searchValue } = this.props.chartSidePostOp;

    let $list = templateList
      .filter((item) => item.name.indexOf(searchValue) !== -1)
      .map((item, index) => (
        <ChartPostOpCategoryItem
          key={index}
          item={item}
          style={{ minHeight: "44px" }}
          openItem={this.openItem(item)}
        />
      ));

    return (
      <div className="chart-clinical-note">
        <div className="sidebar-header chart-side-header">
          <div className="clearfix">
            <div className="col-sm-4">
              <BackButton
                className="btn-sm"
                text="Back"
                onClick={this.props.chartSidePostOpActions.popStack}
              />
            </div>
            <div className="col-sm-8">
              <div className="chart-clinical-note-title">Post Operation instruction templates</div>
            </div>
          </div>
        </div>
        <div className="chart-side-body">
          <div className="chart-clinical-note-category-title">Ready Templates</div>
          <ChartSideSearch value={searchValue} onChange={this.onSearch} />
          <DataList fetching={fetching} onScroll={this.loadList}>
            {$list}
          </DataList>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOpList);
