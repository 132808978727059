import React, { Component } from "react";
import LC from "literallycanvas";

export default class TestLC extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      imgSRC: null,
    };
  }

  render() {
    return (
      <div className="test-lc">
        <LC.LiterallyCanvasReactComponent
          onInit={(lc) => (this.lc = lc)}
          imageURLPrefix="/images/literallycanvas/img/"
        />
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.setState({
                imgSRC: this.lc
                  .getImage({
                    // rect: {x: 0, y: 0, width: 100, height: 100}
                    scale: 1,
                    margin: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    },
                  })
                  .toDataURL(),
              });
            }}
          >
            Save
          </button>
        </div>
        <div>
          <img src={this.state.imgSRC} alt="" />
        </div>
      </div>
    );
  }
}
