import { Dropdown } from "react-bootstrap";
import React, { useRef, useState } from "react";

import { useOutsideClick } from "../../../hooks/useOutsideClick";
import otherExamsImage from "../../../assets/images/microscope.png";
import medicationImage from "../../../assets/images/chart/prescriptions.png";
import prescriptionImage from "../../../assets/images/chart/prescriptions.png";
import notesImage from "../../../assets/images/calendar-bottom-menu/notes.png";
import imagesImage from "../../../assets/images/calendar-bottom-menu/images.png";
import requestImage from "../../../assets/images/calendar-bottom-menu/request.png";
import { obtainImagePath } from "../../../utils/ImageUtils";

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef<any, any>(({ children, onClick }, ref) => (
  <div
    role="button"
    ref={ref}
    className="item"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

enum OpenedMenuTypes {
  Notes = "notes",
  Exams = "exams",
  Requests = "request",
  None = "none",
}

interface Props {
  readonly onDocsClick: () => void;
  readonly onVitalClick: () => void;
  readonly onPerioExamClick: () => void;
  readonly onMedicationClick: () => void;
  readonly onConsentFormClick: () => void;
  readonly onFillInFormsClick: () => void;
  readonly onPrescriptionsClick: () => void;
  readonly onOrderClick: () => void;
  readonly onRequestFollowupClick: () => void;
  readonly onClaimsApprovalsClick: () => void;
  readonly onTreatmentEstimateClick: () => void;
  readonly onPostOpinstructionsClick: () => void;
}

export function BottomMenuContent({
  onDocsClick,
  onVitalClick,
  onOrderClick,
  onPerioExamClick,
  onMedicationClick,
  onFillInFormsClick,
  onConsentFormClick,
  onPrescriptionsClick,
  onRequestFollowupClick,
  onClaimsApprovalsClick,
  onTreatmentEstimateClick,
  onPostOpinstructionsClick,
}: Props) {
  const ref = useRef(null);

  const [openedMenu, setOpenedMenu] = useState<OpenedMenuTypes>(OpenedMenuTypes.None);

  useOutsideClick({
    ref,
    active: openedMenu !== OpenedMenuTypes.None,
    onClick: () => setOpenedMenu(OpenedMenuTypes.None),
  });
  return (
    <div ref={ref} className="d-flex flex-shrink-1 flex-grow-1">
      <Dropdown
        drop="up"
        id="dropdown-list-1"
        className="pull-left"
        show={openedMenu === OpenedMenuTypes.Notes}
      >
        <Dropdown.Toggle as={CustomToggle} onClick={() => setOpenedMenu(OpenedMenuTypes.Notes)}>
          <img className="icon" alt="notes" src={notesImage} />
          <div className="text">Notes</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li>
            <div role="button" className="dropdown-item" onClick={onClaimsApprovalsClick}>
              <div className="text">Claims & Approvals</div>
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onFillInFormsClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Fill In Forms</div>
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onVitalClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Vital</div>
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onPostOpinstructionsClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Post Op instructions</div>
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onConsentFormClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Consent Form</div>
            </div>
          </li>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className="item"
        onClick={() => {
          onDocsClick();
          setOpenedMenu(OpenedMenuTypes.None);
        }}
      >
        <img className="icon" alt="attachments" src={imagesImage} />
        <div className="text">Docs</div>
      </div>
      <Dropdown
        drop="up"
        id="dropdown-list-2"
        className="pull-left"
        show={openedMenu === OpenedMenuTypes.Exams}
      >
        <Dropdown.Toggle as={CustomToggle} onClick={() => setOpenedMenu(OpenedMenuTypes.Exams)}>
          <img className="icon" alt="Other Exams" src={obtainImagePath(otherExamsImage)} />
          <div className="text">Other Exams</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onPerioExamClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Perio Exam</div>
            </div>
          </li>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown
        drop="up"
        id="dropdown-list-3"
        className="pull-left"
        show={openedMenu === OpenedMenuTypes.Requests}
      >
        <Dropdown.Toggle as={CustomToggle} onClick={() => setOpenedMenu(OpenedMenuTypes.Requests)}>
          <img className="icon" alt="Request" src={requestImage} />
          <div className="text">Request</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onOrderClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Order</div>
            </div>
          </li>
          {/*<li>*/}
          {/*  <div*/}
          {/*    className="dropdown-item"*/}
          {/*    onClick={() => {*/}
          {/*      onRequestFollowupClick();*/}
          {/*      setOpenedMenu(OpenedMenuTypes.None);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div className="text">Request Followup</div>*/}
          {/*  </div>*/}
          {/*</li>*/}
          <li>
            <div
              className="dropdown-item"
              onClick={() => {
                onTreatmentEstimateClick();
                setOpenedMenu(OpenedMenuTypes.None);
              }}
            >
              <div className="text">Treatment Estimate</div>
            </div>
          </li>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className="item"
        onClick={() => {
          onPrescriptionsClick();
          setOpenedMenu(OpenedMenuTypes.None);
        }}
      >
        <img className="icon" alt="Prescriptions" src={prescriptionImage} />
        <div className="text">Prescriptions</div>
      </div>
      <div
        className="item"
        onClick={() => {
          onMedicationClick();
          setOpenedMenu(OpenedMenuTypes.None);
        }}
      >
        <img className="icon" alt="medication" src={medicationImage} />
        <div className="text">Medication</div>
      </div>
    </div>
  );
}
