import _ from "lodash";
import moment from "moment";

import Utils from "../helpers/Utils";
import MemberApi from "../api-legacy/MemberApi";
import AppointmentApi from "../api-legacy/AppointmentApi";

import { showMessage, startLoader, stopLoader } from "./loaderActions";
import {
  getCompanyEndOfDay,
  getCompanyFormatUrlTime,
  getCompanyStartOfDay,
} from "../helpers/DateUtils";
import { getMembers } from "./authActions";

export const CALENDAR_SET_COLUMN_VIEW_TYPE = "CALENDAR_SET_COLUMN_VIEW_TYPE";

export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const CHOOSE_CALENDAR_DOCTOR = "CHOOSE_CALENDAR_DOCTOR";

export const CALENDAR_RESET_COLUMNS = "CALENDAR_RESET_COLUMNS";

export const INIT_CALENDAR = "INIT_CALENDAR";
export const GET_DENTIST_WITH_POINTS_START = "GET_DENTIST_WITH_POINTS_START";
export const GET_DENTIST_WITH_POINTS_SUCCESS = "GET_DENTIST_WITH_POINTS_SUCCESS";
export const GET_DENTIST_WITH_POINTS_ERROR = "GET_DENTIST_WITH_POINTS_ERROR";

export const GET_CALENDAR_START = "GET_CALENDAR_START";
export const GET_CALENDAR_SUCCESS = "GET_CALENDAR_SUCCESS";
export const GET_CALENDAR_ERROR = "GET_CALENDAR_ERROR";

export const GET_CALENDAR_LATE_APPOINTMENTS_START = "GET_CALENDAR_LATE_APPOINTMENTS_START";
export const GET_CALENDAR_LATE_APPOINTMENTS_SUCCESS = "GET_CALENDAR_LATE_APPOINTMENTS_SUCCESS";
export const GET_CALENDAR_LATE_APPOINTMENTS_ERROR = "GET_CALENDAR_LATE_APPOINTMENTS_ERROR";

export const GET_DENTIST_CALENDAR_START = "GET_DENTIST_CALENDAR_START";
export const GET_DENTIST_CALENDAR_SUCCESS = "GET_DENTIST_CALENDAR_SUCCESS";
export const GET_DENTIST_CALENDAR_ERROR = "GET_DENTIST_CALENDAR_ERROR";

export const GET_CALENDAR_PRINT_DETAILS_START = "GET_CALENDAR_PRINT_DETAILS_START";
export const GET_CALENDAR_PRINT_DETAILS_SUCCESS = "GET_CALENDAR_PRINT_DETAILS_SUCCESS";
export const GET_CALENDAR_PRINT_DETAILS_ERROR = "GET_CALENDAR_PRINT_DETAILS_ERROR";

export const CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_SHOW = "CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_SHOW";
export const CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_HIDE = "CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_HIDE";

export const CALENDAR_SEND_DETAILS_TO_EMAIL_SUCCESS = "CALENDAR_SEND_DETAILS_TO_EMAIL_SUCCESS";
export const CALENDAR_SEND_DETAILS_TO_EMAIL_START = "CALENDAR_SEND_DETAILS_TO_EMAIL_START";
export const CALENDAR_SEND_DETAILS_TO_EMAIL_ERROR = "CALENDAR_SEND_DETAILS_TO_EMAIL_ERROR";

export const FIND_OPEN_SLOT_SIDEBAR_SHOW = "FIND_OPEN_SLOT_SIDEBAR_SHOW";
export const FIND_OPEN_SLOT_SIDEBAR_HIDE = "FIND_OPEN_SLOT_SIDEBAR_HIDE";

export const FIND_APPOINTMENT_SIDEBAR_SHOW = "FIND_APPOINTMENT_SIDEBAR_SHOW";
export const FIND_APPOINTMENT_SIDEBAR_HIDE = "FIND_APPOINTMENT_SIDEBAR_HIDE";

export const FIND_OPEN_SLOT_SET_FROM_DATE = "FIND_OPEN_SLOT_SET_FROM_DATE";
export const FIND_OPEN_SLOT_SET_TO_DATE = "FIND_OPEN_SLOT_SET_TO_DATE";
export const FIND_OPEN_SLOT_SET_WEEK_DAYS = "FIND_OPEN_SLOT_SET_WEEK_DAYS";

export const SET_ACTIVE_DOCTOR_ID = "SET_ACTIVE_DOCTOR_ID";

export const GET_DENTAL_POINT_START = "GET_DENTAL_POINT_START";
export const GET_DENTAL_POINT_SUCCESS = "GET_DENTAL_POINT_SUCCESS";
export const GET_DENTAL_POINT_ERROR = "GET_DENTAL_POINT_ERROR";

export const GET_DOCTOR_LOCATION_START = "GET_DOCTOR_LOCATION_START";
export const GET_DOCTOR_LOCATION_SUCCESS = "GET_DOCTOR_LOCATION_SUCCESS";
export const GET_DOCTOR_LOCATION_ERROR = "GET_DOCTOR_LOCATION_ERROR";

export const OPEN_SLOT_SEARCH_FREE_POINTS_START = "OPEN_SLOT_SEARCH_FREE_POINTS_START";
export const OPEN_SLOT_SEARCH_FREE_POINTS_SUCCESS = "OPEN_SLOT_SEARCH_FREE_POINTS_SUCCESS";
export const OPEN_SLOT_SEARCH_FREE_POINTS_ERROR = "OPEN_SLOT_SEARCH_FREE_POINTS_ERROR";

export const CALENDAR_SEARCH_APPOINTMENT_START = "CALENDAR_SEARCH_APPOINTMENT_START";
export const CALENDAR_SEARCH_APPOINTMENT_SUCCESS = "CALENDAR_SEARCH_APPOINTMENT_SUCCESS";
export const CALENDAR_SEARCH_APPOINTMENT_ERROR = "CALENDAR_SEARCH_APPOINTMENT_ERROR";

export const CALENDAR_SEARCH_OLD_APPOINTMENT_START = "CALENDAR_SEARCH_OLD_APPOINTMENT_START";
export const CALENDAR_SEARCH_OLD_APPOINTMENT_SUCCESS = "CALENDAR_SEARCH_OLD_APPOINTMENT_SUCCESS";
export const CALENDAR_SEARCH_OLD_APPOINTMENT_ERROR = "CALENDAR_SEARCH_OLD_APPOINTMENT_ERROR";

export const CALENDAR_SEARCH_ALL_APPOINTMENT_START = "CALENDAR_SEARCH_ALL_APPOINTMENT_START";
export const CALENDAR_SEARCH_ALL_APPOINTMENT_SUCCESS = "CALENDAR_SEARCH_ALL_APPOINTMENT_SUCCESS";
export const CALENDAR_SEARCH_ALL_APPOINTMENT_ERROR = "CALENDAR_SEARCH_ALL_APPOINTMENT_ERROR";

export const OPEN_SLOT_SEARCH_MODE_TOGGLE = "OPEN_SLOT_SEARCH_MODE_TOGGLE";
export const OPEN_SLOT_SET_DOCTOR_ID = "OPEN_SLOT_SET_DOCTOR_ID";
export const OPEN_SLOT_SET_FROM_TIME_VALUE = "OPEN_SLOT_SET_FROM_TIME_VALUE";
export const OPEN_SLOT_SET_TO_TIME_VALUE = "OPEN_SLOT_SET_TO_TIME_VALUE";

export const APPOINTMENT_NOTE_MODAL_SHOW = "APPOINTMENT_NOTE_MODAL_SHOW";
export const APPOINTMENT_NOTE_MODAL_HIDE = "APPOINTMENT_NOTE_MODAL_HIDE";

export const CHOOSE_CALENDAR_POINT = "CHOOSE_CALENDAR_POINT";

export const GOOGLE_CALENDAR_SYNC_START = "GOOGLE_CALENDAR_SYNC_START";
export const GOOGLE_CALENDAR_SYNC_SUCCESS = "GOOGLE_CALENDAR_SYNC_SUCCESS";
export const GOOGLE_CALENDAR_SYNC_ERROR = "GOOGLE_CALENDAR_SYNC_ERROR";

export const OPEN_SLOT_SET_FROM_TIME = "OPEN_SLOT_SET_FROM_TIME";
export const OPEN_SLOT_SET_TO_TIME = "OPEN_SLOT_SET_TO_TIME";

export const OPEN_SLOT_SET_WEEK_DAYS = "OPEN_SLOT_SET_WEEK_DAYS";

export const SEARCH_APPOINTMENT_DATA_OBJECT = "SEARCH_APPOINTMENT_DATA_OBJECT";
export const CHOOSE_DOCTORS_POINT = "CHOOSE_DOCTORS_POINT";

export const GET_DOCTOR_POINTS_START = "GET_DOCTOR_POINTS_START";
export const GET_DOCTOR_POINTS_SUCCESS = "GET_DOCTOR_POINTS_SUCCESS";
export const GET_DOCTOR_POINTS_ERROR = "GET_DOCTOR_POINTS_ERROR";

export const CALENDAR_CURRENT_FREEPOINT_INDEX = "CALENDAR_CURRENT_FREEPOINT_INDEX";

export const CALENDAR_CHOOSE_MASTER_TYPES = "CALENDAR_CHOOSE_MASTER_TYPES";

export const GET_CALENDAR_MASTER_TYPE_START = "GET_CALENDAR_MASTER_TYPE_START";
export const GET_CALENDAR_MASTER_TYPE_SUCCESS = "GET_CALENDAR_MASTER_TYPE_SUCCESS";
export const GET_CALENDAR_MASTER_TYPE_ERROR = "GET_CALENDAR_MASTER_TYPE_ERROR";

export const GET_MULTI_DENTIST_CALENDAR_START = "GET_MULTI_DENTIST_CALENDAR_START";
export const GET_MULTI_DENTIST_CALENDAR_SUCCESS = "GET_MULTI_DENTIST_CALENDAR_SUCCESS";
export const GET_MULTI_DENTIST_CALENDAR_ERROR = "GET_MULTI_DENTIST_CALENDAR_ERROR";

export const CLEAR_MULTI_DENTIST_CALENDAR = "CALENDAR/CLEAR_MULTI_DENTIST_CALENDAR";

export const SET_CALENDAR_VIEW_MODE = "SET_CALENDAR_VIEW_MODE";
export const SET_CLINIC_NOTES_EXIST = "SET_CLINIC_NOTES_EXIST";
export const SET_CALENDAR_WEAKLY_MODE = "SET_CALENDAR_WEAKLY_MODE";

export const CALENDAR_SELECT_CLINIC_ID = "CALENDAR_SELECT_CLINIC_ID";

export const GET_CALENDAR_DOCTORS_LIST_START = "GET_CALENDAR_DOCTORS_LIST_START";
export const GET_CALENDAR_DOCTORS_LIST_SUCCESS = "GET_CALENDAR_DOCTORS_LIST_SUCCESS";
export const GET_CALENDAR_DOCTORS_LIST_ERROR = "GET_CALENDAR_DOCTORS_LIST_ERROR";

export const CALENDAR_DOCTORS_SELECT = "CALENDAR_DOCTORS_SELECT";
export const CALENDAR_DOCTORS_RESET = "CALENDAR_DOCTORS_RESET";

export const SWITCH_OPEN_SLOT_RESERVED = "SWITCH_OPEN_SLOT_RESERVED";

export const FIND_APPOINTMENT_ADD_SEaRCH_VALUES = "FIND_APPOINTMENT_ADD_SEaRCH_VALUES";
export const CALENDAR_SAVE_SELECTED_DOCTORS = "CALENDAR_SAVE_SELECTED_DOCTORS";
export const CALENDAR_CLEAR_SELECTED_DOCTORS = "CALENDAR_CLEAR_SELECTED_DOCTORS";

export const CLEAR_PUSH_NOTIFICATION_SWITCH_APPOINTMENT =
  "CLEAR_PUSH_NOTIFICATION_SWITCH_APPOINTMENT";
export const SWITCH_PUSH_NOTIFICATION_SWITCH_APPOINTMENT =
  "SWITCH_PUSH_NOTIFICATION_SWITCH_APPOINTMENT";

export const RESET_CALENDAR_STATE = "RESET_CALENDAR_STATE";

export const COPY_TO_CLIPBOARD_APPOINTMENT = "COPY_TO_CLIPBOARD_APPOINTMENT";

export const GET_ON_DRAG_APPOINTMENTS_START = "GET_ON_DRAG_APPOINTMENTS_START";
export const GET_ON_DRAG_APPOINTMENTS_SUCCESS = "GET_ON_DRAG_APPOINTMENTS_SUCCESS";
export const GET_ON_DRAG_APPOINTMENTS_ERROR = "GET_ON_DRAG_APPOINTMENTS_ERROR";

export function onDragAppointmentsList() {
  return (dispatch, getState) => {
    const {
      calendar: { clinicId },
    } = getState();

    dispatch({
      type: "getOnDragAppointments",
    });
    dispatch(startLoader());

    const data = {
      inBoxDragged: true,
      client: "APP",
      clinicId: clinicId,
    };

    return dispatch(getOnDragAppointmentsList(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function switchOpenSlotReserved(def) {
  return {
    type: SWITCH_OPEN_SLOT_RESERVED,
    def,
  };
}

export function copyToClipboardAppointment(clipBoardAppointment, copyMode) {
  return {
    type: COPY_TO_CLIPBOARD_APPOINTMENT,
    clipBoardAppointment,
    copyMode,
  };
}

export function pasteFromClipboardAppointment() {
  return (dispatch) => {
    dispatch({
      type: COPY_TO_CLIPBOARD_APPOINTMENT,
      clipBoardAppointment: null,
      copyMode: null,
    });
  };
}

export function switchPushNotificationAppointment(id) {
  return {
    type: SWITCH_PUSH_NOTIFICATION_SWITCH_APPOINTMENT,
    id,
  };
}

export function saveSelectedDoctors(values) {
  let ids = [];
  values.forEach((item) => {
    if (item.label && item?.label === "All Doctors") {
      localStorage.removeItem("dentalPointsId");
      return;
    }
    if (item.value && !item.doctor && !item.point) {
      ids.push({ doctorId: item.value });
    } else if (item.label && item?.label === "All Rooms") {
      localStorage.removeItem("dentalPointsId");
      return;
    } else {
      ids.push({ pointId: item.point.id });
    }
  });
  localStorage.setItem("dentalPointsId", JSON.stringify(ids));

  return {
    type: CALENDAR_SAVE_SELECTED_DOCTORS,
    values,
    dentalPointsIds: JSON.parse(localStorage.getItem("dentalPointsId")),
  };
}

export function clearSelectedDoctors() {
  return {
    type: CALENDAR_CLEAR_SELECTED_DOCTORS,
  };
}

export function clearPushNotificationAppointment(id) {
  return {
    type: CLEAR_PUSH_NOTIFICATION_SWITCH_APPOINTMENT,
    id,
  };
}

export function calendarDoctorSelect(id) {
  return {
    type: CALENDAR_DOCTORS_SELECT,
    id,
  };
}

export function calendarDoctorsReset() {
  return {
    type: CALENDAR_DOCTORS_RESET,
  };
}

export function getCalendarDoctors() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getCalendarDoctorsApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMultiDentist(clinicId = null) {
  return (dispatch, getState) => {
    const {
      calendar: { date, calendarDoctors, dentalPointsIds },
      session: { permissions, member },
    } = getState();

    dispatch({
      type: CLEAR_MULTI_DENTIST_CALENDAR,
    });

    dispatch(startLoader());

    const doctors = Object.values(calendarDoctors);

    let selectedDoctors = doctors.filter((x) => x.active).map((x) => x.id);

    if (selectedDoctors.length === 0) {
      selectedDoctors = doctors.map((x) => x.id);
    }

    const doctorIds = dentalPointsIds.map((item) => {
      return item.doctorId;
    });
    const viewOwnCalendar = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_ALL") === -1;
    let data;
    if (viewOwnCalendar) {
      data = {
        clinicId,
        client: "APP",
        doctorIds: `${member.id}`,
        time: getCompanyStartOfDay(date),
        // periodStart: getCompanyStartOfDay(date),
        // periodEnd: getCompanyEndOfDay(date),
      };
    } else {
      data = {
        clinicId,
        client: "APP",
        doctorIds: doctorIds[0] != undefined ? doctorIds.join(",") : "",
        time: getCompanyStartOfDay(date),
        // periodStart: getCompanyStartOfDay(date),
        // periodEnd: getCompanyEndOfDay(date),
      };
    }

    selectedDoctors.pop();
    return dispatch(getCalendar(data))
      .then(() =>
        //     {
        //   if (selectedDoctors.length > 0) {
        //     dispatch(
        //       loadMultiDentist({
        //         selectedDoctors,
        //         clinicId,
        //         date,
        //       }),
        //     );
        //   } else {
        //     dispatch({
        //       type: GET_DENTIST_CALENDAR_SUCCESS,
        //       calendarDoctorView: true,
        //     });
        //     dispatch(stopLoader());
        //   }
        // }
        dispatch(stopLoader()),
      )
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function loadMultiDentist({ date, clinicId, doctorIds }) {
  return (dispatch) => {
    const data = {
      clinicId,
      // client: "APP",
      doctorIds,
      periodStart: getCompanyStartOfDay(date),
      periodEnd: getCompanyEndOfDay(moment(date).add(7)),
    };

    // selectedDoctors.pop();
    return dispatch(getMultiDentistCalendar(data))
      .then(() => {
        if (doctorIds.length > 0) {
          dispatch(
            loadMultiDentist({
              doctorIds,
              clinicId,
              date: date,
            }),
          );
        } else {
          dispatch({
            type: GET_DENTIST_CALENDAR_SUCCESS,
            calendarDoctorView: true,
          });
          dispatch(stopLoader());
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setCalendarViewMode(mode) {
  return {
    type: SET_CALENDAR_VIEW_MODE,
    mode,
  };
}

export function setClinicNotesExist(clinicNotesExist) {
  return {
    type: SET_CLINIC_NOTES_EXIST,
    clinicNotesExist,
  };
}

export function setCalendarWeaklyAppointment(mode) {
  localStorage.setItem("appointmentsWeekView", JSON.stringify(mode));

  return function (dispatch) {
    dispatch({
      type: SET_CALENDAR_WEAKLY_MODE,
      mode,
    });

    dispatch(startLoader());

    return dispatch(getCalendarData(false))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function selectClinicId(id) {
  localStorage.setItem("clinicId", JSON.stringify(id));
  return {
    type: CALENDAR_SELECT_CLINIC_ID,
    id,
  };
}

export function setActiveDoctorId(id) {
  return {
    type: SET_ACTIVE_DOCTOR_ID,
    id,
  };
}

export function chooseMasterTypes(id) {
  return {
    type: CALENDAR_CHOOSE_MASTER_TYPES,
    id,
  };
}

export function resetColumns() {
  return {
    type: CALENDAR_RESET_COLUMNS,
  };
}

export function getMasterType() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getMasterTypeApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMasterTypeApi(data) {
  return {
    api: AppointmentApi.getMasterType,
    types: [
      GET_CALENDAR_MASTER_TYPE_START,
      GET_CALENDAR_MASTER_TYPE_SUCCESS,
      GET_CALENDAR_MASTER_TYPE_ERROR,
    ],
    data,
  };
}

export function getDoctorPoints(data) {
  return {
    api: MemberApi.getDoctorLocations,
    types: [GET_DOCTOR_POINTS_START, GET_DOCTOR_POINTS_SUCCESS, GET_DOCTOR_POINTS_ERROR],
    data,
  };
}

export function chooseDoctorsPoint(key) {
  const [doctorId, dentalPointId] = key.split(",");

  return {
    type: CHOOSE_DOCTORS_POINT,
    doctorId,
    dentalPointId,
  };
}
export function setWeedDaysOpenSlot(weekDays) {
  return {
    type: OPEN_SLOT_SET_WEEK_DAYS,
    weekDays,
  };
}

export function setOpenSlotFromTime(time) {
  return {
    type: OPEN_SLOT_SET_FROM_TIME,
    time,
  };
}

export function setOpenSlotToTime(time) {
  return {
    type: OPEN_SLOT_SET_TO_TIME,
    time,
  };
}

export function googleSync() {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(googleSyncApi())
      .then(() => {
        dispatch(stopLoader("Synchronized"));
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function toggleSearchMode() {
  return {
    type: OPEN_SLOT_SEARCH_MODE_TOGGLE,
  };
}

export function setFromTime(value) {
  return {
    type: OPEN_SLOT_SET_FROM_TIME_VALUE,
    value,
  };
}

export function setToTime(value) {
  return {
    type: OPEN_SLOT_SET_TO_TIME_VALUE,
    value,
  };
}

export function gotoAppointmentFromFind(index, mode, categoryId, subCategoryId, subSubCategoryId) {
  return (dispatch, getState) => {
    const {
      calendar: { freePoints, doctorId },
    } = getState();

    if (_.isEmpty(freePoints[index])) return;

    const transfer = {};

    transfer.duration = freePoints[index].duration;
    transfer.startTime = freePoints[index].startTime;
    transfer.dentalPoint = freePoints[index].dentalPoint.id;
    transfer.dentist = mode === "custom" ? doctorId : freePoints[index].id;

    if (mode !== "custom") {
      transfer.categoryId = categoryId;
      transfer.subCategoryId = subCategoryId;
      transfer.subSubCategoryId = subSubCategoryId;
    }

    dispatch(findOpenSlotSidebarHide());

    return {
      query: transfer,
    };
  };
}

export function findOpenSlotSetWeekDays(day, selected) {
  return (dispatch) => {
    dispatch({
      type: FIND_OPEN_SLOT_SET_WEEK_DAYS,
      weekDay: {
        [day]: selected,
      },
    });
  };
}

export function searchFreePoint(mode) {
  return (dispatch, getState) => {
    const {
      form: { findOpenSlotSidebar, FindOpenSlotSidebarAdvancedForm },
      appointment: { openSlotDoctorsList },
      calendar: { findOpenSlotFromDate, findOpenSlotToDate, findOpenSlotWeekDays },
    } = getState();

    const doctors = openSlotDoctorsList.filter((x) => x.selected);

    const values =
      mode === "custom" ? findOpenSlotSidebar.values : FindOpenSlotSidebarAdvancedForm.values;

    if (!values.length) {
      dispatch(showMessage("Length is required"));
      return;
    }

    if ((mode === "custom" && !values.doctorId) || (mode === "advanced" && doctors.length === 0)) {
      dispatch(showMessage("Doctor is required"));
      return;
    }

    if (mode === "custom" && !values.trxRoom) {
      dispatch(showMessage("Trx room is required"));
      return;
    }

    dispatch(startLoader());

    let weekDays = "";

    if (findOpenSlotWeekDays) {
      weekDays = (findOpenSlotWeekDays.sun && "SUNDAY,") || "";
      weekDays += (findOpenSlotWeekDays.mon && "MONDAY,") || "";
      weekDays += (findOpenSlotWeekDays.tue && "TUESDAY,") || "";
      weekDays += (findOpenSlotWeekDays.wed && "WEDNESDAY,") || "";
      weekDays += (findOpenSlotWeekDays.thu && "THURSDAY,") || "";
      weekDays += (findOpenSlotWeekDays.fri && "FRIDAY,") || "";
      weekDays += (findOpenSlotWeekDays.sat && "SATURDAY,") || "";
    }

    const data = {
      weekDays: weekDays.length > 0 && weekDays.slice(0, -1),
      periodStart: findOpenSlotFromDate ? findOpenSlotFromDate.valueOf() : null,
      periodEnd: findOpenSlotToDate ? findOpenSlotToDate.valueOf() : null,
      timeStart: values.fromTime ? getCompanyFormatUrlTime(values.fromTime) : null,
      timeEnd: values.toTime ? getCompanyFormatUrlTime(values.toTime) : null,
      duration: values.length,
      dentistId: values.doctorId,
      dentalPointId: mode === "custom" ? values.trxRoom : undefined,
    };

    dispatch({
      type: CALENDAR_CURRENT_FREEPOINT_INDEX,
      clear: true,
    });

    if (mode === "advanced") {
      return dispatch(recursiveFreePoints(data, doctors));
    }

    dispatch({
      type: CALENDAR_CURRENT_FREEPOINT_INDEX,
      id: -1,
    });

    dispatch(searchFreePointApi(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(toggleSearchMode());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function recursiveFreePoints(data, doctors) {
  return (dispatch) => {
    const [currentDoctor, ...list] = doctors;

    dispatch({
      type: CALENDAR_CURRENT_FREEPOINT_INDEX,
      id: currentDoctor.id,
    });

    return dispatch(
      searchFreePointApi({
        ...data,
        dentistId: currentDoctor.id,
      }),
    )
      .then(() => {
        if (list.length > 0) {
          dispatch(recursiveFreePoints(data, list));
        } else {
          dispatch(stopLoader());
          dispatch(toggleSearchMode());
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function setSearchAppointmentDataObject(data) {
  return {
    type: SEARCH_APPOINTMENT_DATA_OBJECT,
    data,
  };
}

export function loadAppointments(clinicId, type) {
  return (dispatch, getState) => {
    const {
      calendar: {
        appointments,
        searchAppointmentFetching,
        searchAppointmentTotalCount,
        appointmentsOld,
        searchOldAppointmentFetching,
        searchOldAppointmentTotalCount,
        appointmentsAll,
        searchAllAppointmentFetching,
        searchAllAppointmentTotalCount,
      },
    } = getState();
    if (
      type === "active" &&
      (appointments.length === searchAppointmentTotalCount || searchAppointmentFetching)
    )
      return;
    if (
      type === "past" &&
      (appointmentsOld.length === searchOldAppointmentTotalCount || searchOldAppointmentFetching)
    )
      return;
    if (
      type === "all" &&
      (appointmentsAll.length === searchAllAppointmentTotalCount || searchAllAppointmentFetching)
    )
      return;
    const {
      form,
      calendar: { searchValues },
    } = getState();

    dispatch(startLoader());

    const formValues = _.get(form, "findAppointmentSidebar.values", searchValues);

    dispatch({ type: FIND_APPOINTMENT_ADD_SEaRCH_VALUES, values: formValues });

    let phoneNumber;

    const mobileNumber = _.get(formValues, "phoneNumber.number");
    const mobileTownCode = _.get(formValues, "phoneNumber.townCode", "");
    const mobileCountryCode = _.get(formValues, "phoneNumber.countryCode.value");
    if (mobileCountryCode && mobileNumber) {
      phoneNumber = `${mobileCountryCode}${mobileTownCode}${mobileNumber}`;
    }

    const data = {
      clinicId,
      patientChartNumber: formValues.chartNumber,
      primaryCardNumber: formValues.primaryCardNumber,
      secondaryCardNumber: formValues.secondaryCardNumber,
      patientName: formValues.patientName,
      patientFirstName: formValues.patientFirstName,
      patientLastName: formValues.patientLastName,
      phoneNumber,
      dentistId: formValues.doctorId,
      email: formValues.email,
      start: 0,
      limit: 25,
      machineId: formValues.machineId,
      documentNationalIdNumber: formValues.documentNationalIdNumber,
    };

    if (type === "active") {
      data.startTime = getCompanyStartOfDay();
      data.sortField = "date";
      data.start = appointments.length;
    } else if (type === "past") {
      data.endTime = getCompanyStartOfDay();
      data.sortField = "date_desc";
      data.start = appointmentsOld.length;
    } else {
      data.start = appointmentsAll.length;
    }

    if (type === "active") {
      dispatch(searchAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    } else if (type === "past") {
      dispatch(searchOldAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    } else {
      dispatch(searchAllAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    }
  };
}

export function searchAppointment(clinicId, type) {
  return (dispatch, getState) => {
    const {
      form,
      calendar: { searchValues },
    } = getState();

    dispatch(startLoader());

    const formValues = _.get(form, "findAppointmentSidebar.values", searchValues);

    dispatch({ type: FIND_APPOINTMENT_ADD_SEaRCH_VALUES, values: formValues });

    let phoneNumber;

    const mobileNumber = _.get(formValues, "phoneNumber.number");
    const mobileTownCode = _.get(formValues, "phoneNumber.townCode", "");
    const mobileCountryCode = _.get(formValues, "phoneNumber.countryCode.value");
    if (mobileCountryCode && mobileNumber) {
      phoneNumber = `${mobileCountryCode}${mobileTownCode}${mobileNumber}`;
    }

    const data = {
      clinicId,
      patientChartNumber: formValues.chartNumber,
      primaryCardNumber: formValues.primaryCardNumber,
      secondaryCardNumber: formValues.secondaryCardNumber,
      patientName: formValues.patientName,
      patientFirstName: formValues.patientFirstName,
      patientLastName: formValues.patientLastName,
      phoneNumber,
      dentistId: formValues.doctorId,
      email: formValues.email,
      start: 0,
      limit: 25,
      machineId: formValues.machineId,
      documentNationalIdNumber: formValues.documentNationalIdNumber,
    };
    if (type === "active") {
      data.startTime = getCompanyStartOfDay();
      data.sortField = "date";
    }
    if (type === "past") {
      data.endTime = getCompanyStartOfDay();
      data.sortField = "date_desc";
    }

    if (type === "active") {
      dispatch(searchAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    } else if (type === "past") {
      dispatch(searchOldAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    } else {
      dispatch(searchAllAppointmentApi(data))
        .then(() => {
          dispatch(stopLoader());
          dispatch(setSearchAppointmentDataObject(data));
          dispatch(toggleSearchMode());
        })
        .catch((error) => {
          dispatch(stopLoader(error));
        });
    }
  };
}

function searchAppointmentApi(data) {
  return {
    api: AppointmentApi.getAppointments,
    types: [
      CALENDAR_SEARCH_APPOINTMENT_START,
      CALENDAR_SEARCH_APPOINTMENT_SUCCESS,
      CALENDAR_SEARCH_APPOINTMENT_ERROR,
    ],
    data,
  };
}
function searchOldAppointmentApi(data) {
  return {
    api: AppointmentApi.getAppointments,
    types: [
      CALENDAR_SEARCH_OLD_APPOINTMENT_START,
      CALENDAR_SEARCH_OLD_APPOINTMENT_SUCCESS,
      CALENDAR_SEARCH_OLD_APPOINTMENT_ERROR,
    ],
    data,
  };
}
function searchAllAppointmentApi(data) {
  return {
    api: AppointmentApi.getAppointments,
    types: [
      CALENDAR_SEARCH_ALL_APPOINTMENT_START,
      CALENDAR_SEARCH_ALL_APPOINTMENT_SUCCESS,
      CALENDAR_SEARCH_ALL_APPOINTMENT_ERROR,
    ],
    data,
  };
}

function searchFreePointApi(data) {
  return {
    api: AppointmentApi.getFreePoints,
    types: [
      OPEN_SLOT_SEARCH_FREE_POINTS_START,
      OPEN_SLOT_SEARCH_FREE_POINTS_SUCCESS,
      OPEN_SLOT_SEARCH_FREE_POINTS_ERROR,
    ],
    data,
  };
}

export function getDentalPoint(item) {
  return (dispatch) => {
    dispatch(startLoader());

    const data = {
      dentistId: item.value,
    };

    dispatch(getDentalPointApi(data))
      .then(() => {
        dispatch({
          type: OPEN_SLOT_SET_DOCTOR_ID,
          data: item.value,
        });
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function getDentalPointApi(data) {
  return {
    api: AppointmentApi.getDentalPoint,
    types: [GET_DENTAL_POINT_START, GET_DENTAL_POINT_SUCCESS, GET_DENTAL_POINT_ERROR],
    data,
  };
}

export function findOpenSlotSidebarSetFromDate(data) {
  return {
    type: FIND_OPEN_SLOT_SET_FROM_DATE,
    data,
  };
}

export function findOpenSlotSidebarSetToDate(data) {
  return {
    type: FIND_OPEN_SLOT_SET_TO_DATE,
    data,
  };
}

function googleSyncApi(data) {
  return {
    api: AppointmentApi.googleSync,
    types: [GOOGLE_CALENDAR_SYNC_START, GOOGLE_CALENDAR_SYNC_SUCCESS, GOOGLE_CALENDAR_SYNC_ERROR],
    data,
  };
}

export function findOpenSlotSidebarShow(data) {
  return {
    type: FIND_OPEN_SLOT_SIDEBAR_SHOW,
    data,
  };
}

export function findAppointmentSidebarShow() {
  return {
    type: FIND_APPOINTMENT_SIDEBAR_SHOW,
  };
}

export function findAppointmentSidebarHide() {
  return {
    type: FIND_APPOINTMENT_SIDEBAR_HIDE,
  };
}

export function findOpenSlotSidebarHide() {
  return {
    type: FIND_OPEN_SLOT_SIDEBAR_HIDE,
  };
}

export function getDentist(clinicId) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getDentistWithPoints({ clinicId }))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(startLoader(error));
      });
  };
}

export function getCalendarPrintDetails(type) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      calendar: { date, columns },
      session: { permissions, member },
    } = getState();

    const viewOwnCalendar = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_ALL") === -1;
    const appointmentOwn = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_OWN") !== -1;
    const ids = [];
    columns.forEach((item) => {
      if (item.dentistId) ids.push(item.dentistId);
    });
    // for (let key in calendarDoctors) {
    //   ids.push(key);
    // }

    const doctorIds = viewOwnCalendar && appointmentOwn ? member.id : ids.join(",");
    const data = {
      type: _.lowerCase(type) === "full" ? "FULL" : "WITHOUT_CONTACT",
      time: Utils.getStartDay(date),
      doctorIds,
      // doctorIds: _.filter(points, (doctor) => _.some(doctor.dentalPoints, (point) => point.active))
      //   .map((item) => item.id)
      //   .join(","),
    };

    dispatch(getCalendarPrintDetailsApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

function validateEmail(email) {
  const re = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  return re.test(email);
}

export function sendDetailsToEmail(email) {
  return (dispatch, getState) => {
    const {
      calendar: { printDetails },
    } = getState();

    if (!validateEmail(email)) {
      dispatch(showMessage("Email is not valid"));
      return;
    }

    dispatch(startLoader());

    const data = {
      email,
      fileUrl: printDetails,
    };

    dispatch(sendDetailsToEmailApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        dispatch(stopLoader(error));
      });
  };
}

export function sendDetailsToEmailApi(data) {
  return {
    api: AppointmentApi.sendDetailsToEmail,
    types: [
      CALENDAR_SEND_DETAILS_TO_EMAIL_START,
      CALENDAR_SEND_DETAILS_TO_EMAIL_SUCCESS,
      CALENDAR_SEND_DETAILS_TO_EMAIL_ERROR,
    ],
    data,
  };
}

export function sendDetailsToEmailModalShow() {
  return {
    type: CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_SHOW,
  };
}

export function sendDetailsToEmailModalHide() {
  return {
    type: CALENDAR_SEND_DETAIL_TO_EMAIL_MODAL_HIDE,
  };
}

function getCalendarPrintDetailsApi(data) {
  return {
    api: AppointmentApi.getCalendarPrintDetails,
    types: [
      GET_CALENDAR_PRINT_DETAILS_START,
      GET_CALENDAR_PRINT_DETAILS_SUCCESS,
      GET_CALENDAR_PRINT_DETAILS_ERROR,
    ],
    data,
  };
}

export function initCalendar(saveSettings) {
  return function (dispatch, getState) {
    if (!saveSettings) {
      dispatch({
        type: INIT_CALENDAR,
      });
    }
    dispatch({
      type: "initCalendar",
    });
    dispatch(startLoader());

    const {
      session: { clinic },
      calendar: { clinicId },
    } = getState();

    const id = clinic && clinic.id;

    return Promise.all([
      dispatch(getDoctorLocations({ publicPoint: true, clinicId: clinicId || id })),
      dispatch(getDentistWithPoints()),
      dispatch(getMasterTypeApi()),
    ])
      .then(() => dispatch(getCalendarData(false, clinicId || id)))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function updateCalendar(date) {
  return function (dispatch, getState) {
    if (moment.isMoment(date)) {
      dispatch({
        type: SET_CALENDAR_DATE,
        date,
      });
    }

    const {
      calendar: { calendarViewMode, clinicId },
    } = getState();

    dispatch({
      type: CALENDAR_SET_COLUMN_VIEW_TYPE,
      isActiveDoctor: calendarViewMode === "doctor",
    });

    dispatch({
      type: "updateCalendar",
    });

    dispatch(startLoader());

    return dispatch(getCalendarData(false, clinicId))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getCalendarData(weekType, clinicId = null) {
  return function (dispatch, getState) {
    dispatch({
      type: "getCalendarData",
    });
    const {
      calendar: { date, calendarViewMode, dentalPointsIds, calendarWeaklyMode, clinicNotesExist },
      session: { permissions, member },
      calendar,
    } = getState();

    let method;
    let data;
    let companyStartOfDay = getCompanyStartOfDay(date);

    // Unused functions
    /*let activeCount = 0;
    let lastDentistId = 0;
    let lastPointId = 0;
    if (calendarViewMode === "doctor") {
      _.forEach(calendarDoctors, (doctor, doctorId) => {
        if (doctor.active) {
          activeCount++;
          lastDentistId = doctorId === "undefined" ? "" : doctorId;
        }
      });
    } else {
      _.forEach(points, (doctor, doctorId) => {
        _.forEach(doctor.dentalPoints, (point, pointId) => {
          if (point.active) {
            activeCount++;
            lastDentistId = doctorId === "undefined" ? "" : doctorId;
            lastPointId = pointId;
          }
        });
      });
    }
    if (activeCount === 1) {
      const week = 7 * 24 * 60 * 60 * 1000 - 1000;

      if (weekType) {
        if (weekType === "next") {
          companyStartOfDay += week + 1000;
        } else if (weekType === "prev") {
          companyStartOfDay -= week + 1000;
        }

        dispatch(setDate(moment(companyStartOfDay)));
      }

      method = getDentistCalendar;
      data = {
        periodStart: companyStartOfDay,
        periodEnd: companyStartOfDay + week,
        dentistId: lastDentistId,
        dentalPointId: lastPointId,
        client: "APP",
        clinicId,
      };
    } else {
    method = getCalendar;
    data = {
      clinicId,
      time: companyStartOfDay,
    };
    }*/

    const appointmentAll = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_ALL") !== -1;
    const appointmentOwn = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_OWN") !== -1;

    const pointIds = dentalPointsIds.map((item) => {
      if (item.pointId) return item.pointId;
    });
    const doctorIds = dentalPointsIds.map((item) => {
      if (item.doctorId) return item.doctorId;
    });
    method = getCalendar;
    if (appointmentOwn && !appointmentAll) {
      data = {
        doctorIds: `${member.id}`,
        clinicId,
        time: companyStartOfDay,
        clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
      };
    } else if (!appointmentOwn && appointmentAll) {
      if (calendarViewMode === "doctor") {
        data = {
          doctorIds: doctorIds[0] !== undefined ? doctorIds.join(",") : "",
          clinicId,
          time: companyStartOfDay,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };
      } else {
        data = {
          dentalPointIds: pointIds[0] !== undefined ? pointIds.join(",") : "",
          clinicId,
          time: companyStartOfDay,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };
      }
    } else {
      if (calendarViewMode === "doctor") {
        data = {
          doctorIds: doctorIds[0] !== undefined ? doctorIds.join(",") : "",
          clinicId,
          time: companyStartOfDay,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };
      } else {
        data = {
          dentalPointIds: pointIds[0] !== undefined ? pointIds.join(",") : "",
          clinicId,
          time: companyStartOfDay,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };
      }
    }

    if ((appointmentOwn || appointmentAll) && calendarWeaklyMode) {
      if (!appointmentAll) {
        data = {
          periodStart: getCompanyStartOfDay(date),
          periodEnd: getCompanyEndOfDay(moment(date).add(7, "days")),
          clinicId: calendar?.clinicId,
          dentistId: member.id,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };
        return dispatch(method(data, calendarWeaklyMode)).then(() =>
          dispatch(getMembers({ clinicId: calendar?.clinicId })),
        );
      } else if (dentalPointsIds.length === 1 && dentalPointsIds[0].doctorId) {
        data = {
          periodStart: getCompanyStartOfDay(date),
          periodEnd: getCompanyEndOfDay(moment(date).add(7, "days")),
          clinicId: calendar?.clinicId,
          dentistId: dentalPointsIds[0].doctorId,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };

        return dispatch(method(data, calendarWeaklyMode)).then(() =>
          dispatch(getMembers({ clinicId: calendar?.clinicId })),
        );
      } else if (dentalPointsIds.length === 1 && dentalPointsIds[0].pointId) {
        data = {
          periodStart: getCompanyStartOfDay(date),
          periodEnd: getCompanyEndOfDay(moment(date).add(7, "days")),
          clinicId: calendar?.clinicId,
          dentalPointId: dentalPointsIds[0].pointId,
          clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
        };

        return dispatch(method(data, calendarWeaklyMode)).then(() =>
          dispatch(getMembers({ clinicId: calendar?.clinicId })),
        );
      }
    }

    if (
      !appointmentOwn &&
      calendarViewMode === "doctor" &&
      doctorIds[0] !== undefined &&
      doctorIds.length === 1
    ) {
      data = {
        periodStart: getCompanyStartOfDay(date),
        periodEnd: getCompanyEndOfDay(moment(date).add(7, "days")),
        clinicId: calendar?.clinicId,
        dentistId: doctorIds ? doctorIds.join(",") : "",
        clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
      };

      return dispatch(method(data)).then(() =>
        dispatch(getMembers({ clinicId: calendar?.clinicId })),
      );
    } else if (appointmentOwn && calendarWeaklyMode) {
      data = {
        periodStart: getCompanyStartOfDay(date),
        periodEnd: getCompanyEndOfDay(moment(date).add(7, "days")),
        clinicId: calendar?.clinicId,
        dentistId: `${member.id}`,
        clinicNotesExist: clinicNotesExist === -1 ? null : clinicNotesExist,
      };

      return dispatch(method(data)).then(() =>
        dispatch(getMembers({ clinicId: calendar?.clinicId })),
      );
    } else {
      data.client = "APP";
      data.inBoxDragged = false;
      return dispatch(method(data)).then(() => dispatch(getMembers({ clinicId })));
    }
  };
}

export function getOnDragAppointmentsList(data) {
  return {
    api: AppointmentApi.getOnDragAppointmentsList,
    types: [
      GET_ON_DRAG_APPOINTMENTS_START,
      GET_ON_DRAG_APPOINTMENTS_SUCCESS,
      GET_ON_DRAG_APPOINTMENTS_ERROR,
    ],
    data,
  };
}

export function getDentistWithPoints(data) {
  return {
    api: MemberApi.getDentistWithPoints,
    types: [
      GET_DENTIST_WITH_POINTS_START,
      GET_DENTIST_WITH_POINTS_SUCCESS,
      GET_DENTIST_WITH_POINTS_ERROR,
    ],
    data,
  };
}

export function getDoctorLocations(data) {
  return {
    api: MemberApi.getDoctorLocations,
    types: [GET_DOCTOR_LOCATION_START, GET_DOCTOR_LOCATION_SUCCESS, GET_DOCTOR_LOCATION_ERROR],
    data,
  };
}

export function getCalendarDoctorsApi(data) {
  return {
    api: MemberApi.getDoctorLocations,
    types: [
      GET_CALENDAR_DOCTORS_LIST_START,
      GET_CALENDAR_DOCTORS_LIST_SUCCESS,
      GET_CALENDAR_DOCTORS_LIST_ERROR,
    ],
    data,
  };
}

export function getCalendarLateAppointments() {
  return (dispatch) => {
    dispatch(startLoader());

    const data = {
      client: "APP",
      time: getCompanyStartOfDay(),
    };

    return dispatch(getCalendarLateAppointmentsApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getCalendar(data, showDoctorCalendarByPeriod) {
  return {
    api: showDoctorCalendarByPeriod
      ? AppointmentApi.getDentistCalendar
      : AppointmentApi.getCalendar,
    types: [GET_CALENDAR_START, GET_CALENDAR_SUCCESS, GET_CALENDAR_ERROR],
    data,
  };
}

function getCalendarLateAppointmentsApi(data) {
  return {
    api: AppointmentApi.getCalendar,
    types: [
      GET_CALENDAR_LATE_APPOINTMENTS_START,
      GET_CALENDAR_LATE_APPOINTMENTS_SUCCESS,
      GET_CALENDAR_LATE_APPOINTMENTS_ERROR,
    ],
    data,
  };
}

function getDentistCalendar(data) {
  return {
    api: AppointmentApi.getDentistCalendar,
    types: [GET_DENTIST_CALENDAR_START, GET_DENTIST_CALENDAR_SUCCESS, GET_DENTIST_CALENDAR_ERROR],
    data,
  };
}

function getMultiDentistCalendar(data) {
  return {
    api: AppointmentApi.getDentistCalendar,
    types: [
      GET_MULTI_DENTIST_CALENDAR_START,
      GET_MULTI_DENTIST_CALENDAR_SUCCESS,
      GET_MULTI_DENTIST_CALENDAR_ERROR,
    ],
    data,
  };
}

export function setDate(date) {
  return {
    type: SET_CALENDAR_DATE,
    date,
  };
}

export function chooseDoctor(id) {
  return {
    type: CHOOSE_CALENDAR_DOCTOR,
    id,
  };
}

export function choosePoint(key) {
  const [doctorId, dentalPointId] = key.split(",");

  return {
    type: CHOOSE_CALENDAR_POINT,
    doctorId,
    dentalPointId,
  };
}

export function changeCalendarWeek(weekType) {
  return function (dispatch) {
    dispatch({
      type: "updateCalendar",
    });

    dispatch(startLoader());

    return dispatch(getCalendarData(weekType))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function resetCalendar() {
  return { type: RESET_CALENDAR_STATE };
}
