import { httpClient } from "./BaseApi";

const INSURANCE_NOTE_ITEM_URL = "api/insurance/note/item.json";
const INSURANCE_NOTE_LIST_URL = "api/insurance/note/list.json";

const INSURANCE_ITEM_URL = "api/patient/insurance/item.json";
const INSURANCE_LIST_URL = "api/patient/insurance/list.json";
const INSURANCE_TYPE_CHOOSE_URL = "api/patient/insurance/type/choose.json";
const INSURANCE_UPLOAD_PHOTO_URL = "api/patient/insurance/upload-photo.json";
const INSURANCE_COMPANY_CHOOSE_URL = "api/patient/insurance/company/choose.json";
const INSURANCE_BROKERS_CHOOSE_URL = "api/patient/insurance/brokers/choose.json";
const INSURANCE_RELATION_CHOOSE_URL = "api/patient/insurance/relation/choose.json";
const INSURANCE_MARK_AS_PRIMARY_URL = "api/patient/insurance/mark-as-primary.json";
const INSURANCE_SELF_PAYERS_CHOOSE_URL = "api/patient/insurance/selfpayers/choose.json";
const INSURANCE_SEARCH_CARD_NUMBER_URL = "api/patient/insurance/search/card-number.json";
const INSURANCE_COMPANY_PLAN_CHOOSE_URL = "api/patient/insurance/company-plan/choose.json";
const INSURANCE_DEDUCTIBLE_TYPES_CHOOSE_URL = "api/patient/insurance/deductible-types/choose.json";

const INSURANCE_ALIAS_LIST_URL = "api/patient/insurance/alias/list.json";

const INSURANCE_PLAN_ITEM_URL = "api/patient/insurance-plan/item.json";
const INSURANCE_PLAN_ACTIVATE_URL = "api/patient/insurance-plan/activate.json";
const INSURANCE_RE_IMBURSEMENT_LIST_URL = "api/patient/insurance/reimbursement/list.json";

const InsuranceApi = {
  getList: (params) => httpClient.get(INSURANCE_LIST_URL, { params }),
  getReImbursementList: () => httpClient.get(INSURANCE_RE_IMBURSEMENT_LIST_URL),
  getItem: (params) => httpClient.get(INSURANCE_ITEM_URL, { params }),
  deleteItem: (params) => httpClient.delete(INSURANCE_ITEM_URL, { params }),
  uploadPhoto: (data) => httpClient.post(INSURANCE_UPLOAD_PHOTO_URL, { data }),
  saveInsuranceItem: (data) => httpClient.put(INSURANCE_PLAN_ITEM_URL, { data }),
  markAsPrimary: (data) => httpClient.put(INSURANCE_MARK_AS_PRIMARY_URL, { data }),
  getInsuranceItem: (params) => httpClient.get(INSURANCE_PLAN_ITEM_URL, { params }),
  switchActivateInsurance: (data) => httpClient.put(INSURANCE_PLAN_ACTIVATE_URL, { data }),
  getDeductibleTypes: (params) => httpClient.get(INSURANCE_DEDUCTIBLE_TYPES_CHOOSE_URL, { params }),
  getInsuranceAliasList: (params) => httpClient.get(INSURANCE_ALIAS_LIST_URL, { params }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(INSURANCE_ITEM_URL, { data })
      : httpClient.post(INSURANCE_ITEM_URL, { data }),
  getFields: {
    getType: (params) => httpClient.get(INSURANCE_TYPE_CHOOSE_URL, { params }),
    getBroker: (params) => httpClient.get(INSURANCE_BROKERS_CHOOSE_URL, { params }),
    getCompany: (params) => httpClient.get(INSURANCE_COMPANY_CHOOSE_URL, { params }),
    getRelation: (params) => httpClient.get(INSURANCE_RELATION_CHOOSE_URL, { params }),
    getSelfPayer: (params) => httpClient.get(INSURANCE_SELF_PAYERS_CHOOSE_URL, { params }),
    getCompanyPlan: (params) => httpClient.get(INSURANCE_COMPANY_PLAN_CHOOSE_URL, { params }),
    getSeachByCardNumber: (params) => httpClient.get(INSURANCE_SEARCH_CARD_NUMBER_URL, { params }),
  },
  notes: {
    editItem: (data) => httpClient.put(INSURANCE_NOTE_ITEM_URL, { data }),
    saveItem: (data) => httpClient.post(INSURANCE_NOTE_ITEM_URL, { data }),
    getList: (params) => httpClient.get(INSURANCE_NOTE_LIST_URL, { params }),
    deleteItem: (params) => httpClient.delete(INSURANCE_NOTE_ITEM_URL, { params }),
  },
};

export default InsuranceApi;
