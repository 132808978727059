import { httpClient } from "./BaseApi";

const GET_BY_CATEGORY_URL = "api/medication/choose.json";
const GET_MEDICATION_LIST_URL = "api/medication/list.json";
const GET_MEDICATION_HINTS_URL = "api/medication/typeahead.json";

const MedicationApi = {
  getList: (params) => httpClient.get(GET_MEDICATION_LIST_URL, { params }),
  getByCategory: (params) => httpClient.get(GET_BY_CATEGORY_URL, { params }),
  getMedicationHints: (params) => httpClient.get(GET_MEDICATION_HINTS_URL, { params }),
};

export default MedicationApi;
