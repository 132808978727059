import { httpClient } from "./BaseApi";

const AUDIT_ACTIONS_URL = "api/company/audit-action/item.json";
const GET_FAVORITES_ICONS_URL = "api/company/favourite-icons/all.json";

const CompanyApi = {
  auditActions: (data) => {
    return httpClient.post(AUDIT_ACTIONS_URL, { data });
  },
  getFavouritesIcons: (params) => httpClient.get(GET_FAVORITES_ICONS_URL, { params }),
};

export default CompanyApi;
