import _ from "lodash";
import MedicalFillInFormApi from "../api-legacy/MedicalFillInFormApi";

import * as ActionType from "../actionTypes/medicalFillInFormActionTypes";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import Utils from "../helpers/Utils";
import { medicalFillInFormStackCodes } from "../constants/chart-sidebar/medicalFIllInFormStackCodes";
import questionTypeCodes from "../constants/chart-sidebar/questionTypeCodes";

import { getNote } from "../helpers/clinicNote";
import medicalFillInFormItemTypes from "../constants/chart-sidebar/medicalFillInFormItemTypes";

export const medicalTypeFilterHandler = (mode) => {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_MEDICAL_TYPE_FILTER,
    mode,
  };
};

export const setMedicalFillInFormVitalValueToEdit = (values) => (dispatch) => {
  dispatch({
    type: ActionType.SET_MEDICAL_FILL_IN_FORM_VITAL_VALUE_TO_EDIT,
    values,
  });
};

export const setFormTextAreaValue = (data) => {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_TEXT_AREA_VALUE,
    data,
  };
};

export const dataToFillHandler = (data) => {
  return {
    type: ActionType.SET_DATA_TO_FILL,
    data,
  };
};

export const medicalFillInFormShowSideBar = () => (dispatch) =>
  dispatch({ type: ActionType.MEDICAL_FILL_IN_FORM_SHOW_SIDE_BAR });

export const medicalFillInFormHideSideBar = () => (dispatch) =>
  dispatch({ type: ActionType.MEDICAL_FILL_IN_FORM_HIDE_SIDE_BAR });

export const medicalFillInFormToggleMenu = () => (dispatch) =>
  dispatch({ type: ActionType.MEDICAL_FILL_IN_FORM_TOGGLE_MENU });

export const getMedicalFillInFormTabList = (isNew) => (dispatch, getState) => {
  const {
    chartSideMedicalFillInForm: { templateTabList, activeTab },
  } = getState();

  dispatch(getMedicalFillInFormTabListApi({ hidden: false }))
    .then((response) => {
      const currentTab = (response || []).find((tab) => tab?.id === activeTab?.id);

      if (templateTabList?.length) {
        if (!isNew) {
          dispatch(setActiveTab(templateTabList[0]));
        }

        if (currentTab?.id) {
          dispatch(setActiveTab(currentTab));
        } else {
          dispatch(setActiveTab(templateTabList[0]));
        }
      }
    })
    .catch((error) => dispatch(showMessage(error)));
};

export const getMedicalFillInFormHistoryTabList = () => (dispatch) => {
  dispatch(getMedicalFillInFormTabListApi())
    .then((response) => {
      if (response?.length) {
        dispatch(setActiveTab(response[0]));
      }
    })
    .catch((error) => dispatch(showMessage(error)));
};

export const updateDiagnosisClaimType = (data) => (dispatch) =>
  dispatch({
    api: MedicalFillInFormApi.updateDiagnosisClaimType,
    types: [
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_START,
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_SUCCESS,
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_CLAIM_TYPE_ERROR,
    ],
    data,
  })
    .then((response) => console.log(response))
    .catch((error) => dispatch(showMessage(error)));

export const updateDiagnosisYearOfOnset = (data) => (dispatch) =>
  dispatch({
    api: MedicalFillInFormApi.updateDiagnosisYearOfOnSet,
    types: [
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_START,
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_SUCCESS,
      ActionType.UPDATE_MEDICAL_FILL_IN_FORM_DIAGNOSIS_YEAR_OF_ONSET_ERROR,
    ],
    data,
  })
    .then((response) => console.log(response))
    .catch((error) => dispatch(showMessage(error)));

export const getMedicalFillInFormTabListApi = (data) => ({
  api: MedicalFillInFormApi.getMedicalFillInFormTabList,
  types: [
    ActionType.GET_MEDICAL_FILL_IN_FORM_TAB_LIST_START,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TAB_LIST_SUCCESS,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TAB_LIST_ERROR,
  ],
  data,
});

export const disableInitialExaminationTabToggle = (_bool) => {
  return {
    type: ActionType.IS_INITIAL_EXAMINATION_DISABLE_TOGGLE,
    _bool,
  };
};

export const getInitialExaminationConsultationTempList = (data) => (dispatch) => {
  dispatch(startLoader());
  dispatch({ type: ActionType.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
  return dispatch(getConsultationTemplateListApi(data))
    .then((response) => {
      if (response?.consultationId && response?.templates?.length) {
        const promises = response.templates.map((item) =>
          dispatch({
            api: MedicalFillInFormApi.getConsultationTemplateItem,
            types: [
              ActionType.GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START,
              ActionType.GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_SUCCESS,
              ActionType.GET_INITIAL_EXAMINATION_CONSULTATION_TEMPLATE_ITEM_START_ERROR,
            ],
            data: {
              templateId: item.id,
              patientKey: data.patientKey,
              consultationId: data.consultationId,
            },
          }),
        );
        return Promise.all(promises)
          .then((response) => {
            dispatch(stopLoader());
            return response;
          })
          .catch((error) => dispatch(stopLoader(error)));
      } else {
        dispatch(stopLoader());
      }
    })
    .catch((error) => dispatch(stopLoader(error)));
};

export const getConsultationTemplateList = (data) => (dispatch) => {
  dispatch(startLoader());
  dispatch({ type: ActionType.CLEAR_CONSULTATION_TEMPLATE_ITEM_SUCCESS });
  return dispatch(getConsultationTemplateListApi(data))
    .then((response) => {
      if (response?.templates?.length) {
        const promises = response.templates.map((item) =>
          dispatch({
            api: MedicalFillInFormApi.getConsultationTemplateItem,
            types: [
              ActionType.GET_CONSULTATION_TEMPLATE_ITEM_START,
              ActionType.GET_CONSULTATION_TEMPLATE_ITEM_SUCCESS,
              ActionType.GET_CONSULTATION_TEMPLATE_ITEM_ERROR,
            ],
            data: {
              templateId: item.id,
              patientKey: data.patientKey,
              consultationId: data.consultationId,
            },
          }),
        );
        return Promise.all(promises)
          .then((response) => {
            dispatch(stopLoader());
            return response;
          })
          .catch((error) => dispatch(stopLoader(error)));
      }
    })
    .catch((error) => dispatch(stopLoader(error)));
};

export const getConsultationTemplateListApi = (data) => ({
  api: MedicalFillInFormApi.getConsultationTemplateList,
  types: [
    ActionType.GET_CONSULTATION_TEMPLATE_LIST_START,
    ActionType.GET_CONSULTATION_TEMPLATE_LIST_SUCCESS,
    ActionType.GET_CONSULTATION_TEMPLATE_LIST_ERROR,
  ],
  data,
});

export const getMedicalFillInFormTemplateList = (groupId = null) => (dispatch, getState) => {
  const {
    chartSideMedicalFillInForm: { stack, activeTab, consultationId, medicalTypeFilter },
  } = getState();

  const stackItem = _.last(stack);
  const { categoryId } = stackItem;
  const data = {
    consultationId,
    groupId,
    categoryId,
    templateTypeId: activeTab.id || null,
    start: 0,
    limit: 25,
    initialExamination: activeTab.isInitialExamination,
    hidden: medicalTypeFilter === "new" ? false : null,
  };

  dispatch(getMedicalFillInFormTemplateListApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export const getMedicalFillInFormHardcodedTemplateList = (groupId = null) => (
  dispatch,
  getState,
) => {
  const {
    chartSideMedicalFillInForm: { stack, activeTab },
  } = getState();

  const stackItem = _.last(stack);
  const { categoryId } = stackItem;
  const data = {
    groupId,
    categoryId,
    templateTypeId: activeTab.id,
    start: 0,
    limit: 25,
    initialExamination: activeTab.isInitialExamination,
  };

  dispatch(getMedicalFillInFormHardcodedTemplateListApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export const loadMedicalFillInFormTemplateList = (groupId = null) => (dispatch, getState) => {
  const {
    chartSideMedicalFillInForm: {
      stack,
      templateList,
      templateListCount,
      activeTab,
      consultationId,
      medicalTypeFilter,
    },
  } = getState();

  if (templateList.length >= templateListCount) {
    return;
  }

  const stackItem = _.last(stack);
  const { categoryId } = stackItem;

  const data = {
    consultationId,
    groupId,
    categoryId,
    templateTypeId: activeTab.id,
    limit: 25,
    start: templateList.length,
    initialExamination: activeTab.isInitialExamination,
    hidden: medicalTypeFilter === "new" ? false : null,
  };

  dispatch(getMedicalFillInFormTemplateListApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export const getMedicalFillInFormTemplateListApi = (data) => ({
  api: MedicalFillInFormApi.getMedicalFillInFormTemplateList,
  types: [
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_START,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_ERROR,
  ],
  data,
});

export const getMedicalFillInFormHardcodedTemplateListApi = (data) => ({
  api: MedicalFillInFormApi.getMedicalFillInFormHardcodedTemplateList,
  types: [
    ActionType.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_START,
    ActionType.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_SUCCESS,
    ActionType.GET_MEDICAL_FILL_IN_FORM_HARDCODED_TEMPLATE_LIST_ERROR,
  ],
  data,
});

export const getMedicalFillInFormTemplateQuestions = (data) => (dispatch) => {
  return dispatch(getMedicalFillInFormTemplateQuestionsApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export const getMedicalFillInFormTemplateQuestionsApi = (data) => ({
  api: MedicalFillInFormApi.getMedicalFillInFormTemplateQuestions,
  types: [
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_START,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_SUCCESS,
    ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_QUESTIONS_ERROR,
  ],
  data,
});

export const onSaveMedicalFillInFormItem = (data) => (dispatch, getState) => {
  const { stack } = getState().chartSideMedicalFillInForm;
  const lastStack = _.last(stack);
  const message =
    lastStack === medicalFillInFormItemTypes.edit ? "Successfully update" : "Successfully created";
  const responseMessage = lastStack.code === medicalFillInFormStackCodes.entry ? message : null;

  dispatch(startLoader());
  return dispatch(onSaveMedicalFillInFormItemApi(data))
    .then(() => dispatch(stopLoader(responseMessage)))
    .catch((error) => dispatch(stopLoader(error)));
};

export const onSaveMedicalFillInFormItemApi = (data) => ({
  api: MedicalFillInFormApi.onSaveMedicalFillInFormItem,
  types: [
    ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_START,
    ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS,
    ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_ERROR,
  ],
  data,
});

export const setActiveTab = (activeTab) => (dispatch) =>
  dispatch({
    type: ActionType.SET_ACTIVE_TAB,
    activeTab,
  });

export const setAddendumItem = (addendumItem) => (dispatch) =>
  dispatch({
    type: ActionType.SET_ADDENDUM_ITEM,
    addendumItem,
  });

export const resetConsultationId = () => (dispatch) =>
  dispatch({
    type: ActionType.RESET_MEDICAL_FILL_IN_FORM_CONSULTATION_ID,
  });

export function resetMedicalFillInFormFor() {
  return (dispatch) => {
    return dispatch({
      type: ActionType.RESET_MEDICAL_FILL_IN_FORM_FOR,
    });
  };
}

export function initTextarea() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    dispatch({
      type: ActionType.MEDICAL_FILL_IN_FORM_INIT_TEXTAREA,
      patient,
    });
  };
}

export function fullScreen(boolean) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_FULL_SCREEN,
    fullScreen: boolean,
  };
}

export function getAddendumList(consultationId) {
  return (dispatch) => {
    // consultationId=&start=&limit=&sortField=
    const data = { consultationId, start: 0, limit: 25 };
    return dispatch(getAddendumListApi(data));
  };
}

export function submitTextareaForm(values) {
  return (dispatch, getState) => {
    const {
      chart,
      patient: { patientDetails },
      chartSideMedicalFillInForm: { item, onAppointment, medicalFillInFormAddendumId },
      session: { member },
    } = getState();

    const { name, note, referenceMedicalFillInFormId } = values;

    if (!note) {
      return dispatch(showMessage("Note required"));
    }

    const data = {
      title: name,
      content: note,
      medicalFormConsultationId: medicalFillInFormAddendumId,
    };

    dispatch(startLoader());

    dispatch(saveMedicalFillInFormItemAddendum(data))
      .then(() => {
        dispatch(setMedicalFillInFormAddendumId(0));
        dispatch(openTextarea(false));
        dispatch(stopLoader());
        // dispatch(pushStack({ code: medicalFillInFormStackCodes.owned }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openTextarea(openTextArea) {
  // return pushStack({
  //   code: medicalFillInFormStackCodes.textarea,
  //   type,
  // });
  return (dispatch) => dispatch({ type: ActionType.OPEN_TEXT_AREA, openTextArea });
}

export function setMedicalFillInFormAddendumId(id) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SET_ADDENDUM_ID,
    id,
  };
}

export function getGroupList() {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getGroupListApi())
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getReferenceNoteList(referenceMedicalFillInFormId, patientKey) {
  return (dispatch) => {
    if (!patientKey) {
      return;
    }

    dispatch(startLoader());

    dispatch(getReferenceMedicalFillInFormListApi({ referenceMedicalFillInFormId, patientKey }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const getMedicalFillInFormTreatmentList = () => (dispatch, getState) => {
  const {
    chart: {
      patient: { patientKey },
    },
  } = getState();
  const data = {
    limit: 100,
    sortField: "treatment_session_asc",
    start: 0,
    status: "DentalTransactionStatus_PLANNED",
    type: "TREATMENT",
    patientKey,
  };
  dispatch(getMedicalFillInFormTreatmentListApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export const getMedicalFillInFormDiagnosisList = () => (dispatch, getState) => {
  const {
    chart: {
      patient: { patientKey },
    },
  } = getState();
  const data = {
    limit: 100,
    sortField: "treatment_session_asc",
    start: 0,
    status: "DentalTransactionStatus_PLANNED",
    type: "DIAGNOSIS",
    patientKey,
  };
  dispatch(getMedicalFillInFormDiagnosisListApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export function setMultiQuestionOtherValue(id, data) {
  return (dispatch) =>
    dispatch({
      type: ActionType.MEDICAL_FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE,
      data,
      id,
    });
}

export function setSelectTreatmentModalVisible() {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE,
  };
}

export function setSelectDiagnosisModalVisible() {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE,
  };
}

export function copyNoteItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    return (
      dispatch(getMedicalFillInFormItem({ id }))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(({ id, ...item }) => {
          dispatch(
            saveMedicalFillInFormItem({
              ...item,
              createdDate: Date.now(),
            }),
          )
            .then(() => {
              dispatch(stopLoader());

              dispatch(getOwnedList());
            })
            .catch((error) => dispatch(stopLoader(error)));
        })
        .catch((error) => dispatch(stopLoader(error)))
    );
  };
}

export function initSideMedicalFillInForm() {
  return {
    type: ActionType.INIT_SIDE_MEDICAL_FILL_IN_FORM,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_SORT_FIELD_SIDE_MEDICAL_FILL_IN_FORM,
      code,
    });

    dispatch(getOwnedList());
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_MEDICAL_FILL_IN_FORM_SEARCH_CHANGE,
    value,
  };
}

export function pushStack(stackItem) {
  return {
    type: ActionType.PUSH_MEDICAL_FILL_IN_FORM_STACK,
    stackItem,
  };
}

export function clearStack() {
  return {
    type: ActionType.CLEAR_MEDICAL_FILL_IN_FORM_STACK,
  };
}

export function updateStack(stack) {
  return {
    type: ActionType.UPDATE_MEDICAL_FILL_IN_FORM_STACK,
    stack,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSideMedicalFillInForm;

    if (stack.length === 1) {
      dispatch(medicalFillInFormHideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_MEDICAL_FILL_IN_FORM_STACK,
      });
    }
  };
}

export function openList() {
  return pushStack({
    code: medicalFillInFormStackCodes.list,
  });
}

export const openItem = (item, type) => {
  return (dispatch) => {
    dispatch(
      pushStack({
        code: medicalFillInFormStackCodes.item,
        type,
      }),
    );
  };
};

export const getOwnedList = (query) => (dispatch, getState) => {
  const {
    chart: { patient },
    chartSideMedicalFillInForm: { sortField, activeTab },
  } = getState();

  const data = {
    patientKey: (query && query.patientKey) || patient.patientKey,
    sortField,
    limit: 25,
    initialExamination: activeTab.isInitialExamination,
  };

  dispatch(getMedicalFillInFormOwnedList(data)).catch((error) => dispatch(showMessage(error)));
};

export function loadOwnedList(query) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSideMedicalFillInForm: { sortField, ownedList, ownedListTotalCount },
    } = getState();

    if (ownedListTotalCount > ownedList.length) {
      const data = {
        patientKey: patient.patientKey || query.patientKey,
        sortField,
        limit: 25,
        start: ownedList.length,
      };

      dispatch(getMedicalFillInFormOwnedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function getFrequentlyMedicalFillInFormList({ patientKey } = {}) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    dispatch(startLoader());

    return dispatch(
      getFrequencyMedicalFillInFormListApi({
        patientKey: patientKey || patient.patientKey,
        templateIds: _.get(
          getState(),
          "session.miscSettings.patientChartMedicalFillInFormTemplate.id",
        ),
      }),
    )
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getItem() {
  return (dispatch, getState) => {
    const {
      chartSideMedicalFillInForm: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(getMedicalFillInFormItem(data))
      .then((response) => {
        dispatch(stopLoader());
        dispatch(getReferenceNoteList(id, _.get(response, "patient.patientKey")));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteItem() {
  return (dispatch, getState) => {
    const {
      chartSideMedicalFillInForm: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(deleteMedicalFillInFormItem(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getMedicalFillInFormEmpty() {
  return (dispatch, getState) => {
    const state = getState();
    const { chart } = state;

    const data = {
      patientKey: chart.patient.patientKey,
    };

    dispatch(startLoader());

    dispatch(getMedicalFillInFormEmptyApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveItem(name, patientKey) {
  return (dispatch, getState) => {
    const {
      session: { toothNumericType, clinic },
      chartSideMedicalFillInForm: { item, stack, medicalFillInFormAddendumId },
    } = getState();

    dispatch(startLoader());

    const stackItem = _.last(stack);

    if (stackItem.code === medicalFillInFormStackCodes.item) {
      // item.templateQuestionsAndAnswers.map((question) => {
      //   if (!question.answers) {
      //     question.answers = [];
      //     question.answers[0] = question.defaultAnswers[0];
      //   }
      // });

      item.content = getNote(item, toothNumericType, true, clinic, getState().insurance.images);

      if (name) {
        item.name = name;
      }

      if (medicalFillInFormAddendumId) {
        item.referenceMedicalFillInFormId = medicalFillInFormAddendumId;
      }

      item.templateQuestionsAndAnswers.map((question) => {
        if (
          question.questionType.code === questionTypeCodes.DATE_PICKER &&
          question.answers &&
          question.answers[0]
        ) {
          question.answers[0] = +question.answers[0] + Utils.getTimezoneDifference();
        }
      });
    }

    return dispatch(saveMedicalFillInFormItem(item))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
        dispatch(getOwnedList({ patientKey }));
        // dispatch(getFrequentlyFillInFormList({ patientKey }));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export const setConsentFormViewDetails = (data) => (dispatch) =>
  dispatch({ type: ActionType.SET_CONSENT_FORM_VIEW_DETAILS, data });

export function selectQuestion(index) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SELECT_QUESTION,
    index: +index,
  };
}

export function prevQuestion() {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_PREV_QUESTION,
  };
}

export function nextQuestion() {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_NEXT_QUESTION,
  };
}

export function changeQuestionTextarea(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA,
    value,
    questionId,
  };
}

export function changeQuestionCanvas(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_CANVAS,
    value,
    questionId,
  };
}

export function changeListOfAnswers(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS,
    value,
    questionId,
  };
}

export function changeListOfAnswersMultiple(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE,
    value,
    questionId,
  };
}

export function changeDatePicker(date, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_DATE_PICKER,
    date,
    questionId,
  };
}

export function changeSurface(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CHANGE_SURFACE,
    value,
    questionId,
  };
}

export function selectTooth(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SELECT_TOOTH,
    value,
    questionId,
  };
}

export function setName(value) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SET_NAME,
    value,
  };
}

export function setTextareaBody(value) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_SET_TEXTAREA_BODY,
    value,
  };
}

export function openDeleteModal(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_OPEN_DELETE_MODAL,
    value,
    questionId,
  };
}

export function closeDeleteModal(value, questionId) {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_CLOSE_DELETE_MODAL,
    value,
    questionId,
  };
}

export function initEdit() {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_INIT_EDIT,
  };
}

export const getMedicalFillInFormVitalsList = (start) => {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSideMedicalFillInForm: {
        patientVitalsTransactionsList,
        vitalsTotalCount,
        patientVitalTransactionFetching,
        consultationId,
      },
    } = getState();
    if (!start) {
      if (
        patientVitalsTransactionsList.length === vitalsTotalCount ||
        patientVitalTransactionFetching ||
        vitalsTotalCount === 0
      ) {
        return;
      }
    }

    const data = {
      limit: 25,
      start: patientVitalsTransactionsList.length,
      voided: false,
      patientKey,
      consultationId,
    };
    dispatch(getMedicalFillInFormVitalsListApi(data))
      .then(() => {
        dispatch(getMedicalFillInFormVitalsList());
      })
      .catch((error) => dispatch(showMessage(error)));
  };
};
export const getMedicalFillInFormVitalTransaction = () => (dispatch, getState) => {
  const {
    chart: {
      patient: { patientKey },
    },
  } = getState();
  const data = {
    patientKey,
  };
  dispatch(getMedicalFillInFormVitalTransactionApi(data)).catch((error) =>
    dispatch(showMessage(error)),
  );
};

export function changeCreatedDate(momentDate) {
  return (dispatch, getState) => {
    const {
      chartSideMedicalFillInForm: { item },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: item.id,
      createdDate: +momentDate + Utils.getTimezoneDifference(),
    };

    dispatch(changeMedicalFillInFormCreatedDate(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}
export const disableAllTabsExpectInitialExamination = (_bool, mode) => {
  return {
    type: ActionType.DISABLE_ALL_TABS_EXPECT_INITIAL_EXAMINATION_TOGGLE,
    _bool,
    mode,
  };
};
export const approveConsultationInitialExamination = () => {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSideMedicalFillInForm: { consultationId },
    } = getState();

    dispatch(startLoader());
    const data = {};

    if (consultationId) {
      data.id = consultationId;
    } else {
      data.patientKey = patientKey;
    }

    dispatch(approveConsultationInitialExaminationApi(data))
      .then(() => dispatch(getMedicalFormConsultationInitialExaminationItem()))
      .then((data) => dispatch(stopLoader(data)))
      .catch((error) => dispatch(stopLoader(error)));
  };
};
export const getMedicalFormConsultationInitialExaminationItem = () => {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      patientKey,
    };

    dispatch(getMedicalFormConsultationInitialExaminationItemApi(data))
      .then((response) => {
        if (response?.consultationId) {
          dispatch(
            getInitialExaminationConsultationTempList({
              consultationId: response.consultationId,
              patientKey,
            }),
          );
        }
      })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
};

export const getMedicalFormConsultationInitialExaminationItemOnly = () => {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      patientKey,
    };

    dispatch(getMedicalFormConsultationInitialExaminationItemApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
};

export const saveConsultationInitialExaminationItem = (data) => {
  return (dispatch, getState) => {
    const {
      chartSideMedicalFillInForm: { stack },
      chart: {
        patient: { patientKey },
      },
    } = getState();
    const lastStack = _.last(stack);
    const message =
      lastStack === medicalFillInFormItemTypes.edit
        ? "Successfully update"
        : "Successfully created";
    const responseMessage = lastStack.code === medicalFillInFormStackCodes.entry ? message : null;

    dispatch(startLoader());

    dispatch(saveConsultationInitialExaminationItemApi(data))
      .then(() =>
        dispatch(getMedicalFormConsultationInitialExaminationItemApi({ patientKey: patientKey })),
      )
      .then(() => dispatch(stopLoader(responseMessage)))
      .catch((error) => dispatch(stopLoader(error)));
  };
};

export const questionModalVisibleHandler = () => {
  return {
    type: ActionType.MEDICAL_FILL_IN_FORM_QUESTIONS_MODAL_VISIBLE,
  };
};

export const getQuestionList = (filter) => {
  return (dispatch, getState) => {
    const {
      chartSideMedicalFillInForm: { questionList, questionListTotalCount, questionListFetching },
    } = getState();
    if ((questionList.length === questionListTotalCount && filter.load) || questionListFetching)
      return;
    const data = {
      ...filter,
      start: filter.start === 0 ? 0 : questionList.length,
      limit: 25,
      showInFilling: true,
    };
    dispatch({
      api: MedicalFillInFormApi.getMedicalFormQuestionsList,
      types: [
        ActionType.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_START,
        ActionType.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_SUCCESS,
        ActionType.GET_MEDICAL_FILL_IN_FORM_QUESTION_LIST_ERROR,
      ],
      data,
    }).catch((error) => dispatch(showMessage(error)));
  };
};

function getMedicalFillInFormOwnedList(data) {
  return {
    api: MedicalFillInFormApi.getOwnedList,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_OWNED_LIST_ERROR,
    ],
    data,
  };
}

function getFrequencyMedicalFillInFormListApi(data) {
  return {
    api: MedicalFillInFormApi.getOwnedList,
    types: [
      ActionType.GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_START,
      ActionType.GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_SUCCESS,
      ActionType.GET_FREQUENCY_MEDICAL_FILL_IN_FORM_LIST_ERROR,
    ],
    data,
  };
}

// function getMedicalFillInFormTemplateList(data) {
//   return {
//     api: MedicalFillInFormApi.template.getList,
//     types: [
//       ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_START,
//       ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS,
//       ActionType.GET_MEDICAL_FILL_IN_FORM_TEMPLATE_LIST_ERROR,
//     ],
//     data,
//   };
// }

function getMedicalFillInFormItem(data) {
  return {
    api: MedicalFillInFormApi.getItem,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_ITEM_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_ITEM_ERROR,
    ],
    data,
  };
}

function saveMedicalFillInFormItem(data) {
  return {
    api: MedicalFillInFormApi.saveItem,
    types: [
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_START,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_ERROR,
    ],
    data: {
      ...data,
      patient: {
        patientKey: _.get(data, "patient.patientKey"),
      },
    },
  };
}

function saveMedicalFillInFormItemAddendum(data) {
  return {
    api: MedicalFillInFormApi.saveItemAddendum,
    types: [
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_START,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_SUCCESS,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_ITEM_ADDENDUM_ERROR,
    ],
    data,
  };
}

function getAddendumListApi(data) {
  return {
    api: MedicalFillInFormApi.getAddendumList,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_ADDENDUM_LIST_ERROR,
    ],
    data,
  };
}

function deleteMedicalFillInFormItem(data) {
  return {
    api: MedicalFillInFormApi.deleteItem,
    types: [
      ActionType.DELETE_MEDICAL_FILL_IN_FORM_ITEM_START,
      ActionType.DELETE_MEDICAL_FILL_IN_FORM_ITEM_SUCCESS,
      ActionType.DELETE_MEDICAL_FILL_IN_FORM_ITEM_ERROR,
    ],
    data,
  };
}

function getMedicalFillInFormEmptyApi(data) {
  return {
    api: MedicalFillInFormApi.getMedicalFillInFormEmpty,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_EMPTY_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_EMPTY_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_EMPTY_ERROR,
    ],
    data,
  };
}

function changeMedicalFillInFormCreatedDate(data) {
  return {
    api: MedicalFillInFormApi.changeCreatedDate,
    types: [
      ActionType.CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_START,
      ActionType.CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_SUCCESS,
      ActionType.CHANGE_MEDICAL_FILL_IN_FORM_CREATED_DATE_ERROR,
    ],
    data,
  };
}

function getGroupListApi(data) {
  return {
    api: MedicalFillInFormApi.getGroupList,
    types: [
      ActionType.MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_START,
      ActionType.MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_SUCCESS,
      ActionType.MEDICAL_FILL_IN_FORM_GET_GROUP_LIST_ERROR,
    ],
    data,
  };
}

function getReferenceMedicalFillInFormListApi(data) {
  return {
    api: MedicalFillInFormApi.getOwnedList,
    types: [
      ActionType.GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_START,
      ActionType.GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_SUCCESS,
      ActionType.GET_REFERENCE_MEDICAL_FILL_IN_FORM_LIST_ERROR,
    ],
    data,
  };
}

function getMedicalFillInFormTreatmentListApi(data) {
  return {
    api: MedicalFillInFormApi.getMedicalFillInFormTreatmentListApi,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_TREATMENT_LIST_ERROR,
    ],
    data,
  };
}

function getMedicalFillInFormDiagnosisListApi(data) {
  return {
    api: MedicalFillInFormApi.getMedicalFillInFormDiagnosisListApi,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_DIAGNOSIS_LIST_ERROR,
    ],
    data,
  };
}

function getMedicalFillInFormVitalsListApi(data) {
  return {
    api: MedicalFillInFormApi.getMedicalFillInFormVitalsListApi,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITALS_LIST_ERROR,
    ],
    data,
  };
}

function getMedicalFillInFormVitalTransactionApi(data) {
  return {
    api: MedicalFillInFormApi.getMedicalFillInFormVitalTransactionApi,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_VITAL_TRANSACTION_ERROR,
    ],
    data,
  };
}

function approveConsultationInitialExaminationApi(data) {
  return {
    api: MedicalFillInFormApi.approveConsultationInitialExaminationApi,
    types: [
      ActionType.MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_START,
      ActionType.MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_SUCCESS,
      ActionType.MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_APPROVE_ERROR,
    ],
    data,
  };
}

function getMedicalFormConsultationInitialExaminationItemApi(data) {
  return {
    api: MedicalFillInFormApi.getConsultationInitialExaminationItemApi,
    types: [
      ActionType.GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START,
      ActionType.GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS,
      ActionType.GET_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR,
    ],
    data,
  };
}

function saveConsultationInitialExaminationItemApi(data) {
  return {
    api: MedicalFillInFormApi.saveConsultationInitialExaminationItemApi,
    types: [
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_START,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_SUCCESS,
      ActionType.SAVE_MEDICAL_FILL_IN_FORM_CONSULTATION_INITIAL_EXAMINATION_ITEM_ERROR,
    ],
    data,
  };
}
