import React from "react";
import cx from "classnames";
import { Sidebar } from "../sidebar/Sidebar";
import _ from "lodash";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import Show from "../widgets/Show";

export function SideBarModal({
  open,
  children,
  className,
  bodyClassName,
  onRequestClose,
  headerClassName,
  headerComponent,
  headerLeftComponent,
  headerRightComponent,
  headerComponentClassName,
  leftHeaderComponentClassName,
  rightHeaderComponentClassName,
  orderStack,
}) {
  const orderStackItem = _.last(orderStack);
  const isProvisional = orderStackItem?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

  return (
    <Sidebar show={open} onHide={onRequestClose} dialogClassName={cx("sidebar-modal", className)}>
      {Boolean(headerLeftComponent || headerRightComponent || headerComponent) && (
        <div className={cx("sidebar-modal-header", headerClassName)}>
          <div className={cx("sidebar-header-left-component", leftHeaderComponentClassName)}>
            {headerLeftComponent}
          </div>
          <div className={cx("sidebar-header-component", headerComponentClassName)}>
            <Show if={!isProvisional}>{headerComponent}</Show>
          </div>
          <div className={cx("sidebar-header-right-component", rightHeaderComponentClassName)}>
            {headerRightComponent}
          </div>
        </div>
      )}

      <div className={cx("sidebar-modal-body", bodyClassName)}>{children}</div>
    </Sidebar>
  );
}
