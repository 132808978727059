import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChartVitalOwned from "./ChartVitalOwned";
import ChartVitalList from "./ChartVitalList";
import ChartVitalItemView from "./ChartVitalItemView";
import ChartVitalItemEdit from "./ChartVitalItemEdit";

import vitalItemTypes from "../../../../constants/chart-sidebar/vitalItemTypes";
import { vitalStackCodes } from "../../../../constants/chart-sidebar/vitalStackCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVital extends React.Component {
    componentDidMount() {
      this.props.chartSideVitalActions.initSideVital();
    }

    render() {
      const { chartSideVital, member, fromAppointment, hideSideBar, clinic } = this.props;
      const { stack } = chartSideVital;

      const stackItem = _.last(stack);

      if (fromAppointment) {
        if (stackItem.code === vitalStackCodes.owned) {
          return (
            <ChartVitalOwned
              hideSideBar={hideSideBar}
              fromAppointment={fromAppointment}
              chartSideVital={chartSideVital}
            />
          );
        }
      } else {
        if (stackItem.code === vitalStackCodes.owned) {
          return <ChartVitalOwned chartSideVital={chartSideVital} />;
        }
      }

      if (stackItem.code === vitalStackCodes.list) {
        return <ChartVitalList chartSideVital={chartSideVital} />;
      }

      if (stackItem.code === vitalStackCodes.item) {
        if (stackItem?.type === vitalItemTypes.view) {
          return <ChartVitalItemView member={member} chartSideVital={chartSideVital} />;
        }

        if (stackItem?.type === vitalItemTypes.edit) {
          return <ChartVitalItemEdit edit={true} chartSideVital={chartSideVital} clinic={clinic} />;
        }

        if (stackItem?.type === vitalItemTypes.new) {
          return <ChartVitalItemEdit chartSideVital={chartSideVital} clinic={clinic} />;
        }
      }
    }
  },
);
