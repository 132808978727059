import React from "react";
import { Field, reduxForm } from "redux-form";
import FlexBox from "../../../ui/FlexBox";

const form = reduxForm({
  form: "DocsAddFolderForm",
});

class DocsAddFolderForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FlexBox gutter={8} direction="column">
          <FlexBox>
            <Field
              type="text"
              required={true}
              autoFocus={true}
              id="folderName"
              name="folderName"
              component="input"
              className="form-control"
              placeholder="Folder Name"
            />
          </FlexBox>

          <FlexBox justify="flex-end">
            <FlexBox gutter={8} direction="row">
              <FlexBox>
                <button type="reset" onClick={this.props.onDismiss} className="btn btn-danger">
                  Cancel
                </button>
              </FlexBox>
              <FlexBox>
                <button type="submit" className="btn btn-success">
                  Save
                </button>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </form>
    );
  }
}

export default form(DocsAddFolderForm);
