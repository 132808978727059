import React from "react";
import { connect } from "react-redux";

import { clearToken, initApp } from "../../actions/authActions";
import { closeSimpleModal } from "../../actions/simpleModalActions";
import { DST_HOST, LIVE_HOST, settings } from "../../constants/host";

import Show from "../widgets/Show";

import OtherIPDialog from "../settings/OtherIPDialog";
import { IS_DEV } from "../../constants/Constants";

const enhancer = connect(null, { initApp, closeSimpleModal, clearToken });
export default enhancer(
  class ipAddresses extends React.Component {
    constructor() {
      super();
      this.state = {
        openOtherIPDialog: false,
      };
    }

    selectIP = (host) => {
      const { initApp, clearToken, closeSimpleModal } = this.props;

      settings.host = host;

      clearToken();

      localStorage.setItem("host", host);
      localStorage.setItem("loggedIn", false);

      if (IS_DEV) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }

      closeSimpleModal();
      initApp();
    };

    render() {
      return (
        <div className="list-group ip-address">
          <Show if={this.state.openOtherIPDialog}>
            <OtherIPDialog
              show={this.state.openOtherIPDialog}
              onRequestClose={() => this.setState({ openOtherIPDialog: false })}
              onSubmit={this.selectIP}
            />
          </Show>
          <button
            type="button"
            className="list-group-item"
            onClick={() => this.selectIP(LIVE_HOST)}
          >
            Amazon Live ({LIVE_HOST})
          </button>
          <button type="button" className="list-group-item" onClick={() => this.selectIP(DST_HOST)}>
            Pre Live ({DST_HOST})
          </button>
          <button
            type="button"
            className="list-group-item"
            onClick={() => this.setState({ openOtherIPDialog: true })}
          >
            Other IP Address
          </button>
          <button type="button" className="list-group-item" onClick={this.props.closeSimpleModal}>
            Cancel
          </button>
        </div>
      );
    }
  },
);
