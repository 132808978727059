import React from "react";
import FilledImage from "../../widgets/FilledImage";

export default function condition(props) {
  const { property, color, toothImage } = props;
  if (!property) return null;

  const src = toothImage
    ? require(`../../../assets/images/conditions/pulpotomy/pulpotomy_${toothImage}@2x.png`).default
    : null;

  return (
    <div className="condition-wrap tooth-image-condition">
      <FilledImage src={src} color={color} className="zoom-2x" />
    </div>
  );
}
