import { httpClient } from "./BaseApi";

const FILL_IN_FORM_LIST_URL = "api/fill-in-form/list.json";
const FILL_IN_FORM_ITEM_URL = "api/fill-in-form/item.json";
const FILL_IN_FORM_RESTORE_ITEM_URL = "api/fill-in-form/restore/item.json";
const FILL_IN_FORM_EMPTY_URL = "api/fill-in-form/empty.json";
const FILL_IN_FORM_GROUP_LIST_URL = "api/fill-in-form/group/choose.json";
const FILL_IN_FORM_TEMPLATE_LIST_URL = "api/fill-in-form/template/list.json";
const FILL_IN_FORM_CHANGE_DATE_URL = "api/fill-in-form/item/change-date.json";
const FILL_IN_FORM_REVIEW = "api/fill-in-form/item/review.json";

const GoalApi = {
  getItem: (params) => httpClient.get(FILL_IN_FORM_ITEM_URL, { params }),
  saveEmpty: (data) => httpClient.post(FILL_IN_FORM_EMPTY_URL, { data }),
  getEmpty: (params) => httpClient.get(FILL_IN_FORM_EMPTY_URL, { params }),
  getOwnedList: (params) => httpClient.get(FILL_IN_FORM_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(FILL_IN_FORM_ITEM_URL, { params }),
  restoreItem: (params) => httpClient.post(FILL_IN_FORM_RESTORE_ITEM_URL, { params }),
  getGroupList: (params) => httpClient.get(FILL_IN_FORM_GROUP_LIST_URL, { params }),
  changeCreatedDate: (data) => httpClient.put(FILL_IN_FORM_CHANGE_DATE_URL, { data }),
  reviewFillInForm: (data) => httpClient.put(FILL_IN_FORM_REVIEW, { data }),
  saveItem: (data) =>
    data && data.id
      ? httpClient.put(FILL_IN_FORM_ITEM_URL, { data })
      : httpClient.post(FILL_IN_FORM_ITEM_URL, { data }),
  template: {
    getList: (params) => httpClient.get(FILL_IN_FORM_TEMPLATE_LIST_URL, { params }),
  },
};

export default GoalApi;
