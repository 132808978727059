import $ from "jquery";
import React from "react";
import { connect } from "react-redux";

import Show from "../../widgets/Show";
import Element from "../../../models/Element";
import ChartTransactionLeftSide from "./ChartTransactionLeftSide";
import ChartTransactionRightSide from "./ChartTransactionRightSide";
import { calculateTableWidth } from "../../../helpers/TransactionHelpers";
import transactionTypes from "../../../constants/chart-sidebar/transactionTypes";
import TransactionSearchParameters from "../../../models/TransactionSearchParameters";
import VoidedTreatmentsDialog from "../voided-treatments-dialog/VoidedTreatmentsDialog";
import {
  JSON_FILES,
  TreatmentGrouppingTypes as treatmentGroupping,
} from "../../../constants/Constants";
import {
  EDITABLE_KEYS,
  TransactionStatus,
  TransactionType,
} from "../../../constants/TransactionTypes";
import {
  changeTransactionItem,
  getAppointmentTransactions,
  openPlanModal,
  setSearchParameters,
  setTransactionData,
} from "../../../actions/transactionActions";
import {
  changeEye,
  chartVoidedShowModal,
  selectTreatmentToCompleteModalShow,
  showSideTransaction,
} from "../../../actions/chartActions";
import SelectTreatmentModal from "./SelectTreatmentModal";

const enhancer = connect(
  ({
    chart,
    chartSideTransaction,
    calendar: { doctors },
    transaction: transactionFull,
    transaction: { transaction },
    appointment: { appointment },
    session: { toothNumericType, permissions },
  }) => {
    return {
      chart,
      doctors,
      transaction,
      appointment,
      permissions,
      transactionFull,
      toothNumericType,
      chartSideTransaction,
    };
  },
  {
    changeEye,
    openPlanModal,
    setTransactionData,
    setSearchParameters,
    showSideTransaction,
    chartVoidedShowModal,
    changeTransactionItem,
    getAppointmentTransactions,
    selectTreatmentToCompleteModalShow,
  },
);
export default enhancer(
  class ChartTransaction extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        elements: {
          addProductBtn: new Element("Add Product"),
          approveBtn: new Element("Trx Codes on Hold"),
          voidedBtn: new Element("Voided"),
          planBtn: new Element("Plan"),
          showHideRightBtn: new Element(),
          mixedSortBtn: new Element(),
          sortBtn: new Element(),
          popups: {
            selectTreatment: new Element(),
          },
          table: {
            thead: {
              titles: [],
              widths: [],
            },
            tbody: {
              content: [],
            },
            loader: new Element(),
          },
          leftButtons: {
            kTrxBtn: new Element("Trx Plan", 1, 0),
            kProductBtn: new Element("Products", 2, 5),
            kPlannedTreatmentBtn: new Element("Planned", 3, 1),
            kCompletedTreatmentBtn: new Element("Completed", 4, 2),
            kDiagnosisBtn: new Element("Diagnosis", 5, 3),
            kExistingBtn: new Element("Existing", 6, 4),
            kTransactionBtnCount: new Element("Transaction", 7),
            selectedTransactionBtn: new Element(),
          },
          doctors: {},
        },
        searchParameters: new TransactionSearchParameters(),
      };
    }

    componentWillMount() {
      const { searchParameters } = this.state;
      const { elements } = this.state;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      elements.popups.selectTreatment.visible = false;
      elements.popups.selectTreatment.handler = () => {
        elements.popups.selectTreatment.visible = !elements.popups.selectTreatment.visible;
        this.setState({ elements });
      };
      elements.approveBtn.handler = (bool) => {
        this.setState({
          elements: {
            ...elements,
            popups: {
              ...elements.popups,
              selectTreatment: {
                ...elements.popups.selectTreatment,
                visible: bool,
              },
            },
          },
        });
      };
      elements.voidedBtn.handler = () => {
        this.props.chartVoidedShowModal();
        this.setState({ elements });
      };
      elements.planBtn.handler = () => {
        this.props.openPlanModal();
      };
      elements.sortBtn.options.push(new Element("Planned date ⬇︎", 1, 0, "create_date_desc"));
      elements.sortBtn.options.push(new Element("Planned date ⬆︎", 2, 1, "create_date_asc"));
      elements.sortBtn.options.push(new Element("Completed date ⬇︎", 3, 2, "completed_date_desc"));
      elements.sortBtn.options.push(new Element("Completed date ⬆︎", 4, 3, "completed_date_asc"));
      elements.sortBtn.options.push(new Element("Code ⬇︎︎", 5, 4, "code_desc"));
      elements.sortBtn.options.push(new Element("Code ⬆︎", 6, 5, "code_asc"));
      elements.sortBtn.selected =
        elements.sortBtn.options.find(
          (option) => option.name === localStorage.getItem("transactionGroupBy"),
        ) || elements.sortBtn.options[0];
      elements.sortBtn.handler = (item, index) => {
        elements.sortBtn.selected = item;
        elements.sortBtn.options[index].selected = true;
        searchParameters.clear();
        searchParameters.sortField = item.name;
        that.setState({ elements });
        if (searchParameters.totalCount > 0) {
          that.getTransactions(searchParameters);
        }
      };
      elements.mixedSortBtn.options.push(new Element("Planned date ⬇︎", 1, 0, "create_date_desc"));
      elements.mixedSortBtn.options.push(new Element("Planned date ⬆︎", 2, 1, "create_date_asc"));
      elements.mixedSortBtn.options.push(
        new Element("Completed date ⬇︎", 3, 2, "completed_date_desc"),
      );
      elements.mixedSortBtn.options.push(
        new Element("Completed date ⬆︎", 4, 3, "completed_date_asc"),
      );
      elements.mixedSortBtn.options.push(new Element("Tooth ⬇︎︎", 5, 4, "tooth_desc"));
      elements.mixedSortBtn.options.push(new Element("Tooth ⬆︎", 6, 5, "tooth_asc"));
      elements.mixedSortBtn.selected =
        elements.mixedSortBtn.options.find(
          (option) => option.name === localStorage.getItem("transactionGroupBy"),
        ) || elements.mixedSortBtn.options[0];
      elements.mixedSortBtn.handler = (item, index) => {
        elements.mixedSortBtn.selected = item;
        elements.mixedSortBtn.options[index].selected = true;
        // elements.mixedSortBtn.value = item.value;
        searchParameters.clear();
        searchParameters.sortField = item.name;
        localStorage.setItem("transactionGroupBy", item.name);
        that.setState({ elements });
        if (searchParameters.totalCount > 0) {
          that.getTransactions(searchParameters);
        }
      };
      const { params } = this.props;
      const { patientKey } = params;
      const { appointmentId } = params;
      searchParameters.initWithPatientKey(patientKey, appointmentId);
      elements.showHideRightBtn.selected = false;
      elements.mixedSortBtn.visible = false;
      elements.sortBtn.visible = !elements.showHideRightBtn.selected;
      elements.table.loader.visible = false;

      this.transactionBtnClicked(elements.leftButtons.kPlannedTreatmentBtn, true);

      this.setState({ elements, searchParameters });
    }

    componentWillUnmount() {
      this.props.setTransactionData({});
    }

    onScroll = () => {
      const { elements } = this.state;
      const { searchParameters } = this.state;
      const { transaction } = this.props;

      const target = $(".transaction-table");

      if (
        !elements.table.loader.visible &&
        target.scrollTop() &&
        target.scrollTop() >= target.find(".table").height() - target.height()
      ) {
        searchParameters.start = (transaction && transaction.list && transaction.list.length) || 0;
        if (
          searchParameters.totalCount > 0 &&
          searchParameters.start < searchParameters.totalCount
        ) {
          elements.table.loader.visible = true;
          this.setState({ elements });
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const that = this;
          this.getTransactions(searchParameters, true, () => {
            elements.table.loader.visible = false;
            that.setState({ elements });
          });
        }
      }
    };

    cellChangeItem = (item, index, editableKey) => {
      const { searchParameters } = this.state;
      const { transaction } = this.props;
      transaction.list[index] = item;
      this.props.setTransactionData(transaction);

      let jsonFile = JSON_FILES.CHANGE_TREATMENT_PLAN;
      const request = {};
      request.currentAppointmentId = +searchParameters.appointmentId;
      request.currentDentistId = this.props.appointment && this.props.appointment.dentist.id;
      request.patientKey = searchParameters.patientKey;
      request.key = item.key;
      request[editableKey] = item[editableKey];

      switch (editableKey) {
        case EDITABLE_KEYS[0]:
          jsonFile = JSON_FILES.CHANGE_TREATMENT_PLAN;
          break;
        case EDITABLE_KEYS[1]:
          jsonFile = JSON_FILES.CHANGE_TREATMENT_SESSION;
          break;
        case EDITABLE_KEYS[2]:
          jsonFile = JSON_FILES.CHANGE_UNIT;
          break;
        case EDITABLE_KEYS[3]:
          jsonFile = JSON_FILES.CHANGE_ASSIGNED_DOCTOR;
          request[editableKey] = item[editableKey].getRequest();
          break;
      }
      request.jsonFile = jsonFile;
      this.props.changeTransactionItem(request);
    };

    changeAssignedDoctor = (assignedDoctor, key) => {
      const { searchParameters } = this.state;
      const { transaction } = this.props;
      this.props.setTransactionData(transaction);

      const request = {};
      request.currentAppointmentId = +searchParameters.appointmentId;
      request.currentDentistId = this.props.appointment && this.props.appointment.dentist.id;
      request.patientKey = searchParameters.patientKey;
      request.key = key;
      request.assignedDoctor = assignedDoctor;
      request.jsonFile = JSON_FILES.CHANGE_ASSIGNED_DOCTOR;
      this.props.changeTransactionItem(request);
    };

    transactionBtnClicked = (btn) => {
      const { searchParameters } = this.state;
      const { elements } = this.state;
      searchParameters.clearTypeStatusParams();
      searchParameters.start = 0;
      elements.addProductBtn.visible = false;
      elements.approveBtn.visible = false;
      elements.voidedBtn.visible = true;
      elements.planBtn.visible = false;
      if (
        elements.leftButtons.selectedTransactionBtn.id <
        elements.leftButtons.kPlannedTreatmentBtn.id
      ) {
        elements.leftButtons.selectedTransactionBtn.selected = null;
      }
      elements.mixedSortBtn.visible = elements.sortBtn.visible = false;
      if (btn.id >= elements.leftButtons.kPlannedTreatmentBtn.id) {
        elements.mixedSortBtn.visible = !elements.showHideRightBtn.selected;
        if (
          elements.leftButtons.selectedTransactionBtn.id >=
          elements.leftButtons.kPlannedTreatmentBtn.id
        ) {
          btn.selected = !btn.selected;
        } else {
          btn.selected = true;
        }
        this.setSearchParametersFromLeftButtons();
      } else {
        btn.selected = true;
        elements.sortBtn.visible = !elements.showHideRightBtn.selected;
        if (btn.id === elements.leftButtons.kTrxBtn.id) {
          elements.planBtn.visible = true;
          elements.approveBtn.visible = true;
          searchParameters.treatmentPlanned = true;
        } else if (btn.id === elements.leftButtons.kProductBtn.id) {
          elements.addProductBtn.visible = true;
          searchParameters.type = TransactionType.PRODUCT;
          searchParameters.status = TransactionStatus.PLANNED;
        }
      }
      elements.leftButtons[btn.name] = btn;
      elements.leftButtons.selectedTransactionBtn = btn;

      searchParameters.start = 0;
      this.setState({ elements });

      this.refreshTableHeadFields(btn.id);
      this.getTransactions(searchParameters, false);
    };

    setSearchParametersFromLeftButtons() {
      const { leftButtons } = this.state.elements;
      const { searchParameters } = this.state;

      searchParameters.treatmentPlanned = leftButtons.kPlannedTreatmentBtn.selected;
      searchParameters.treatmentCompleted = leftButtons.kCompletedTreatmentBtn.selected;
      searchParameters.diagnosisCompleted = leftButtons.kDiagnosisBtn.selected;
      searchParameters.diagnosisPlanned = leftButtons.kDiagnosisBtn.selected;
      searchParameters.existingCompleted = leftButtons.kExistingBtn.selected;

      this.setState({ searchParameters });
    }

    refreshTableHeadFields(btnId) {
      const { elements } = this.state;
      const { permissions } = this.props;

      const doctorLabel = localStorage.getItem("doctorLabel");
      const treatmentGrouppingData = localStorage.getItem("treatmentGroupping");

      const withSorting = treatmentGrouppingData !== treatmentGroupping.none;

      const showFee = permissions.indexOf("VIEW_DENTAL_TRANSACTION_ITEM_FEE") >= 0;

      const isTrxTab = btnId === elements.leftButtons.kTrxBtn.id;

      const TREATMENTS_WIDTHS = [
        ...[40, 100, 100, 100],
        ...(isTrxTab ? [60, 60, 40, 80, 100, 90, 100, 150] : []),
        ...[105, 105, 150, 100, 220],
        ...(withSorting ? [100, 100] : []),
        ...[90, 100, 100],
        ...(showFee ? [100] : []),
        ...[280, 100, 200],
        ...[220, 100, 105, 220, 100, 100, 120, 100, 100, 100, 150],
      ];

      switch (btnId) {
        case elements.leftButtons.kTrxBtn.id:
        case elements.leftButtons.kProductBtn.id:
        case elements.leftButtons.kExistingBtn.id:
        case elements.leftButtons.kDiagnosisBtn.id:
        case elements.leftButtons.kPlannedTreatmentBtn.id:
        case elements.leftButtons.kCompletedTreatmentBtn.id:
          elements.table.thead.titles = [
            ...["", "Change to cash", "Custom $", "Select $"],
            ...(isTrxTab
              ? [
                  "Trx Plan",
                  "Session",
                  "Unit",
                  "Interval unit",
                  "Interval amount",
                  "Duration unit",
                  "Duration amount",
                  "Assigned Doctor",
                ]
              : []),
            ...[
              "Created Date",
              "Completed Date",
              `Completed ${doctorLabel}`,
              "Code",
              "Description",
              "Problem",
              "Diagnosis type",
              "Year of onset",
            ],
            ...(withSorting ? ["Remaining"] : []),
            ...["Tooth", "Area", "Details"],
            ...(showFee ? ["Fee", "Payer Share 1", "Payer Share 2", "Patient Share"] : []),
            ...["Discount", "Net", "Note"],
            ...[
              "Ins.Note",
              "Approval.1",
              "Expire Date.1",
              ...(withSorting ? ["Total"] : []),
              "Ins.Note 2",
              "Approval.2",
              "Expire Date.2",
              "Status",
              "Attachment",
              "Created Doctor",
              "Clinic",
            ],
          ];
          elements.table.thead.widths = TREATMENTS_WIDTHS;
          elements.table.width = calculateTableWidth(TREATMENTS_WIDTHS);
          break;
      }

      this.setState({ elements });
    }

    getTransactions(searchParameters, hideLoader, callback) {
      const { setSearchParameters, getAppointmentTransactions } = this.props;

      this.setState({ searchParameters });

      setSearchParameters(searchParameters);

      return getAppointmentTransactions(hideLoader).then((x) => {
        if (callback) {
          callback();
        }

        searchParameters.totalCount = x ? x.totalCount : 0;

        this.setState({ searchParameters });
      });
    }

    openTransactionEdit = (item) => {
      return () => {
        const { elements } = this.state;

        switch (elements.leftButtons.selectedTransactionBtn.id) {
          case elements.leftButtons.kTrxBtn.id:
            return this.props.showSideTransaction(transactionTypes.trxPlan, item);
          case elements.leftButtons.kProductBtn.id:
            return this.props.showSideTransaction(transactionTypes.products, item);
          case elements.leftButtons.kPlannedTreatmentBtn.id:
          case elements.leftButtons.kCompletedTreatmentBtn.id:
          case elements.leftButtons.kDiagnosisBtn.id:
          case elements.leftButtons.kExistingBtn.id:
            if (item?.type?.code === "DIAGNOSIS") {
              return this.props.showSideTransaction(transactionTypes.diagnosis, item);
            }

            if (item.status.code === "DentalTransactionStatus_PLANNED") {
              if (item?.type?.code === "TREATMENT") {
                return this.props.showSideTransaction(transactionTypes.planned, item);
              }
            }

            if (item.status.code === "DentalTransactionStatus_COMPLETED") {
              if (item?.type?.code === "TREATMENT") {
                return this.props.showSideTransaction(transactionTypes.completed, item);
              }
              if (item?.type?.code === "EXISTING") {
                return this.props.showSideTransaction(transactionTypes.existing, item);
              }
            }

            break;
        }
      };
    };

    render() {
      const { leftButtons, showHideRightBtn } = this.state.elements;
      const {
        params,
        transactionFull,
        chartSideTransaction,
        chart: { eyeCheckedMap, teeth },
      } = this.props;
      const {
        voidedTreatmentsList,
        chartVoidedModalVisible,
        voidedTreatmentsListFetching,
        voidedTreatmentsSelectedItems,
      } = this.props.chart;
      const { patientKey } = params;

      return (
        <div className={`transaction-view ${showHideRightBtn.selected ? "short-view" : ""}`}>
          <Show if={chartVoidedModalVisible}>
            <VoidedTreatmentsDialog
              patientKey={patientKey}
              show={chartVoidedModalVisible}
              voidedTreatmentsList={voidedTreatmentsList}
              voidedTreatmentsListFetching={voidedTreatmentsListFetching}
              voidedTreatmentsSelectedItems={voidedTreatmentsSelectedItems}
            />
          </Show>

          <Show if={this.state.elements.popups.selectTreatment.visible}>
            <SelectTreatmentModal
              element={this.state.elements.approveBtn}
              chartSideTransaction={chartSideTransaction}
            />
          </Show>
          <ChartTransactionLeftSide
            leftButtons={leftButtons}
            eyeCheckedMap={eyeCheckedMap}
            changeEye={this.props.changeEye}
            elements={this.state.elements}
            permissions={this.props.permissions}
            transactionBtnClicked={this.transactionBtnClicked}
            stopClick={() => this.props.selectTreatmentToCompleteModalShow("stop")}
          />
          <ChartTransactionRightSide
            teeth={teeth}
            onCompleteUpdate={() => this.getTransactions(this.state.searchParameters)}
            onScroll={this.onScroll}
            transaction={transactionFull}
            elements={this.state.elements}
            permissions={this.props.permissions}
            cellChangeItem={this.cellChangeItem}
            chartSideTransaction={chartSideTransaction}
            openTransactionEdit={this.openTransactionEdit}
            changeAssignedDoctor={this.changeAssignedDoctor}
            tableLoaderVisible={this.state.elements.table.loader.visible} // for re-rendering
            stopClick={() => this.props.selectTreatmentToCompleteModalShow("stop")}
          />
        </div>
      );
    }
  },
);
