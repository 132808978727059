import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";
import { getCompanyFormatDate, getCompanyFormatTime } from "../../../helpers/DateUtils";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { withRouter } from "react-router";

const enhancer = connect(
  ({ calendar: { reserved, freePoints, doctorId } }) => ({ reserved, freePoints, doctorId }),
  bindActions({ calendarActions }),
);

const FindOpenSlotSidebarList = ({
  freePoints,
  doctorId,
  doctors,
  reserved,
  categories,
  formSearchMode,
  patientKey,
  calendarActions,
  history,
  appointmentId,
}) => {
  const doctor = doctorId ? _.find(doctors, ["id", doctorId], { name: "Unknown" }).name : undefined;

  const pointsList = freePoints.map((item, index) => {
    const itemDoctor = doctors[item.id || item.dentalPoint.id];

    const freeDoctor = !doctor && itemDoctor ? itemDoctor.name : undefined;

    return (
      <div
        key={index}
        className="find-open-slot-sidebar-list-item"
        onClick={() => {
          const { query } = calendarActions.gotoAppointmentFromFind(
            index,
            formSearchMode,
            categories.category,
            categories.subCategory,
            categories.subSubCategory,
          );
          history.push(
            createUrl(Routes.DashboardAppointment, {
              params: {
                id: 0,
              },
              query: {
                ...query,
                dentist: doctorId,
                patientKey,
                requestAppointmentId: appointmentId,
              },
            }),
          );
        }}
      >
        <span className="data-date">{getCompanyFormatDate(item.startTime)}</span>
        <span className="data-time">
          {getCompanyFormatTime(item.startTime)} - {getCompanyFormatTime(item.endTime)}
        </span>
        <span className="data-name">
          {doctor || freeDoctor} {reserved ? `(${item.reservedName})` : ""}
        </span>
        <span className="data-day">{moment(item.startTime).format("dddd")}</span>
        <div className="data-arrow">›</div>
      </div>
    );
  });
  if (!pointsList.length)
    return <h3 className="d-flex align-items-center justify-content-center">No result</h3>;
  return <div className="find-open-slot-sidebar-list list-group">{pointsList}</div>;
};

export default withRouter(enhancer(FindOpenSlotSidebarList));
