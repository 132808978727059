import _ from "lodash";
import { reset } from "redux-form";
import PatientApi from "../api-legacy/PatientApi";
import InsuranceApi from "../api-legacy/InsuranceApi";
import MemberApi from "../api-legacy/MemberApi";

import { getConsultationNotesList } from "./appointmentActions";
import { getPatientAppointmentsListApi } from "./patientRegisterActions";
import * as ActionType from "../actionTypes/patientViewActionTypes";
import { showMessage, startLoader, stopLoader } from "./loaderActions";

import patientViewModalTypes from "../constants/patient/patientViewModalTypes";

export function getPatientMembersList(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientMembersListApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function updatePrimaryClinician(id) {
  return (dispatch, getState) => {
    const {
      patientView: { patientKey },
    } = getState();

    dispatch(startLoader());

    return dispatch(
      updatePrimaryClinicianApi({
        patientKey,
        primaryClinician: { id },
      }),
    )
      .then(() => {
        dispatch(getPatientDashboard({ patientKey }))
          .then(() => dispatch(stopLoader()))
          .catch((error) => dispatch(stopLoader(error)));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadInsuranceNotes(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientInsuranceNotes({ patientKey, limit: 25 }))
      .then(() => dispatch(stopLoader()))
      .catch((e) => dispatch(stopLoader(e)));
  };
}

export function getPatientTerminalDetails(data) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientTerminalDetailsApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initPatientView({ patientKey, companyTempPatientId }) {
  return (dispatch, getState) => {
    const { session } = getState();

    const availableConsultationNotes = session.permissions.includes("VIEW_CONSULTATION_NOTE_ITEM");

    dispatch({
      type: ActionType.INIT_PATIENT_VIEW,
      patientKey,
      companyTempPatientId,
    });

    dispatch(startLoader());

    const limit = 25;

    let promises;

    if (patientKey) {
      promises = [
        dispatch(getPatientDashboard({ patientKey })),
        ...(availableConsultationNotes ? [dispatch(getConsultationNotesList({ patientKey }))] : []),
        dispatch(getPatientNotesApi({ patientKey, limit })),
        dispatch(getPatientInsuranceNotes({ patientKey, limit })),
        dispatch(getMembers({ patientKey })),
        dispatch(getMembersAll()),
        dispatch(getPatientAppointmentsListApi({ patientKey, limit, start: 0 })),
        dispatch(getPatientMembersList({ patientKey })),
      ];
    } else if (companyTempPatientId) {
      promises = [
        dispatch(getTempPatientDashboard({ companyTempPatientId })),
        ...(availableConsultationNotes
          ? [dispatch(getConsultationNotesList({ companyTempPatientId }))]
          : []),
      ];
    }

    return Promise.all(promises)
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function hideModal() {
  return {
    type: ActionType.PATIENT_VIEW_HIDE_MODAL,
  };
}

export function addNote({ name, note }, pKey) {
  return (dispatch, getState) => {
    if (!note) {
      return dispatch(showMessage("Note required"));
    }

    dispatch(startLoader());
    let $promise;
    const limit = 25;

    const { patientView } = getState();
    const { modalType, patientKey } = patientView;

    switch (modalType) {
      case patientViewModalTypes.note:
        $promise = dispatch(
          createPatientNote({
            note,
            name,
            patientKey: patientKey || pKey,
          }),
        );
        break;
      case patientViewModalTypes.insuranceNote:
        $promise = dispatch(
          createPatientInsuranceNote({
            patientKey: patientKey || pKey,
            name,
            body: note,
          }),
        );
        break;
    }

    $promise
      .then(() => {
        switch (modalType) {
          case patientViewModalTypes.note:
            return dispatch(getPatientNotesApi({ patientKey: patientKey || pKey, limit }));
          case patientViewModalTypes.insuranceNote:
            return dispatch(
              getPatientInsuranceNotes({
                patientKey: patientKey || pKey,
                limit,
              }),
            );
        }
      })
      .then(() => dispatch(reset("patientViewModalAdd")))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function editNoteEdit({ id, note, name }, pKey) {
  return (dispatch, getState) => {
    if (!note) {
      return dispatch(showMessage("Note required"));
    }

    dispatch(startLoader());
    let $promise;
    const limit = 25;

    const { patientView } = getState();
    const { modalType, patientKey } = patientView;

    switch (modalType) {
      case patientViewModalTypes.note:
        $promise = dispatch({
          api: PatientApi.note.editItem,
          types: [
            "PATIENT_VIEW_EDIT_NOTE_START",
            "PATIENT_VIEW_EDIT_NOTE_SUCCESS",
            "PATIENT_VIEW_EDIT_NOTE_ERROR",
          ],
          data: {
            patientKey: patientKey || pKey,
            name,
            note,
            id,
            modifiedDate: Number(new Date()),
          },
        });
        break;
      case patientViewModalTypes.insuranceNote:
        $promise = dispatch({
          api: InsuranceApi.notes.editItem,
          types: [
            "PATIENT_VIEW_EDIT_INSURANCE_NOTE_START",
            "PATIENT_VIEW_EDIT_INSURANCE_NOTE_SUCCESS",
            "PATIENT_VIEW_EDIT_INSURANCE_NOTE_ERROR",
          ],
          data: {
            patientKey: patientKey || pKey,
            name,
            body: note,
            id,
            modifiedDate: Number(new Date()),
          },
        });
        break;
    }

    $promise
      .then(() => {
        switch (modalType) {
          case patientViewModalTypes.note:
            return dispatch(getPatientNotesApi({ patientKey: patientKey || pKey, limit }));
          case patientViewModalTypes.insuranceNote:
            return dispatch(
              getPatientInsuranceNotes({
                patientKey: patientKey || pKey,
                limit,
              }),
            );
        }
      })
      .then(() => dispatch(reset("patientViewModalAdd")))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openNoteEdit(item) {
  return {
    type: ActionType.PATIENT_VIEW_OPEN_NOTE_EDIT,
    item,
  };
}

export function closeNoteEdit() {
  return {
    type: ActionType.PATIENT_VIEW_CLOSE_NOTE_EDIT,
  };
}

export function deleteNote(id, pKey) {
  return (dispatch, getState) => {
    dispatch(startLoader());
    let $promise;
    const limit = 25;

    const { patientView } = getState();
    const { modalType, patientKey } = patientView;

    switch (modalType) {
      case patientViewModalTypes.note:
        $promise = dispatch({
          api: PatientApi.note.deleteItem,
          types: [
            "PATIENT_VIEW_DELETE_NOTE_START",
            "PATIENT_VIEW_DELETE_NOTE_SUCCESS",
            "PATIENT_VIEW_DELETE_NOTE_ERROR",
          ],
          data: {
            noteId: id,
          },
        });
        break;
      case patientViewModalTypes.insuranceNote:
        $promise = dispatch({
          api: InsuranceApi.notes.deleteItem,
          types: [
            "PATIENT_VIEW_DELETE_INSURANCE_NOTE_START",
            "PATIENT_VIEW_DELETE_INSURANCE_NOTE_SUCCESS",
            "PATIENT_VIEW_DELETE_INSURANCE_NOTE_ERROR",
          ],
          data: {
            id,
          },
        });
        break;
    }

    $promise
      .then(() => {
        switch (modalType) {
          case patientViewModalTypes.note:
            return dispatch(getPatientNotesApi({ patientKey: patientKey || pKey, limit }));
          case patientViewModalTypes.insuranceNote:
            return dispatch(
              getPatientInsuranceNotes({
                patientKey: patientKey || pKey,
                limit,
              }),
            );
        }
      })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getPatientNotes(patientKey) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getPatientNotesApi({ patientKey, start: 0, limit: 25 }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function showModal(modalType, patientKey = "") {
  return {
    type: ActionType.PATIENT_VIEW_SHOW_MODAL,
    modalType,
    patientKey,
  };
}

export function showClinicianModal() {
  return {
    type: ActionType.PATIENT_VIEW_SHOW_CLINICIAN_MODAL,
  };
}

export function closeClinicianModal() {
  return {
    type: ActionType.PATIENT_VIEW_CLOSE_CLINICIAN_MODAL,
  };
}

export function openPlanVisitModal() {
  return {
    type: ActionType.PATIENT_VIEW_OPEN_PLAN_VISIT_MODAL,
  };
}

export function closePlanVisitModal() {
  return {
    type: ActionType.PATIENT_VIEW_CLOSE_PLAN_VISIT_MODAL,
  };
}

export function toggleClinicianAddMode() {
  return {
    type: ActionType.PATIENT_VIEW_TOGGLE_CLINICIAN_MODE,
  };
}

export function toggleClinician(id) {
  return {
    type: ActionType.PATIENT_VIEW_TOGGLE_CLINICIAN,
    id,
  };
}

export function setClinicianSearchValue(value) {
  return {
    type: ActionType.PATIENT_VIEW_SET_CLINICIAN_SEARCH_VALUE,
    value,
  };
}

export function saveClinician() {
  return (dispatch, getState) => {
    const { patientView } = getState();
    const { selectedClinician, patientKey } = patientView;

    const memberIds = _(selectedClinician)
      .pickBy((item) => !!item)
      .keys()
      .values();

    const data = {
      patientKey,
      memberIds,
    };

    dispatch(startLoader());

    return dispatch(saveMembers(data))
      .then(() => dispatch(getMembers({ patientKey })))
      .then(() => dispatch(toggleClinicianAddMode()))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function patientRemindersModalShow() {
  return {
    type: ActionType.PATIENT_VIEW_PATIENT_REMINDERS_MODAL_SHOW,
  };
}

export function patientRemindersModalHide() {
  return {
    type: ActionType.PATIENT_VIEW_PATIENT_REMINDERS_MODAL_HIDE,
  };
}

export function savePatientReminders(
  receptionReminderActive,
  doctorReminderActive,
  receptionReminder,
  doctorReminder,
) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      patientView: {
        patientDetails: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
      receptionReminder,
      doctorReminder,
      receptionReminderActive,
      doctorReminderActive,
    };

    dispatch(saveReminderApi(data))
      .then(() => dispatch(stopLoader()))
      .then(() => dispatch(patientRemindersModalHide()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getTempPatientDashboard(data) {
  return {
    api: PatientApi.getTempProfile,
    types: [
      ActionType.PATIENT_VIEW_GET_DASHBOARD_START,
      ActionType.PATIENT_VIEW_GET_DASHBOARD_SUCCESS,
      ActionType.PATIENT_VIEW_GET_DASHBOARD_ERROR,
    ],
    data,
  };
}

function getPatientDashboard(data) {
  return {
    api: PatientApi.getProfile,
    types: [
      ActionType.PATIENT_VIEW_GET_DASHBOARD_START,
      ActionType.PATIENT_VIEW_GET_DASHBOARD_SUCCESS,
      ActionType.PATIENT_VIEW_GET_DASHBOARD_ERROR,
    ],
    data,
  };
}

function getPatientTerminalDetailsApi(data) {
  return {
    api: PatientApi.getProfile,
    types: [
      ActionType.PATIENT_VIEW_GET_TERMINAL_DETAILS_START,
      ActionType.PATIENT_VIEW_GET_TERMINAL_DETAILS_SUCCESS,
      ActionType.PATIENT_VIEW_GET_TERMINAL_DETAILS_ERROR,
    ],
    data,
  };
}

function getPatientNotesApi(data) {
  return {
    api: PatientApi.note.getList,
    types: [
      ActionType.PATIENT_VIEW_GET_NOTES_START,
      ActionType.PATIENT_VIEW_GET_NOTES_SUCCESS,
      ActionType.PATIENT_VIEW_GET_NOTES_ERROR,
    ],
    data,
  };
}

export function createPatientNote(data) {
  return {
    api: PatientApi.note.saveItem,
    types: [
      ActionType.PATIENT_VIEW_SAVE_NOTE_START,
      ActionType.PATIENT_VIEW_SAVE_NOTE_SUCCESS,
      ActionType.PATIENT_VIEW_SAVE_NOTE_ERROR,
    ],
    data,
  };
}

function getPatientInsuranceNotes(data) {
  return {
    api: InsuranceApi.notes.getList,
    types: [
      ActionType.PATIENT_VIEW_GET_INSURANCE_NOTES_START,
      ActionType.PATIENT_VIEW_GET_INSURANCE_NOTES_SUCCESS,
      ActionType.PATIENT_VIEW_GET_INSURANCE_NOTES_ERROR,
    ],
    data,
  };
}

function createPatientInsuranceNote(data) {
  return {
    api: InsuranceApi.notes.saveItem,
    types: [
      ActionType.PATIENT_VIEW_SAVE_INSURANCE_NOTE_START,
      ActionType.PATIENT_VIEW_SAVE_INSURANCE_NOTE_SUCCESS,
      ActionType.PATIENT_VIEW_SAVE_INSURANCE_NOTE_ERROR,
    ],
    data,
  };
}

function getMembers(data) {
  return {
    api: PatientApi.members.getList,
    types: [
      ActionType.PATIENT_VIEW_GET_MEMBERS_START,
      ActionType.PATIENT_VIEW_GET_MEMBERS_SUCCESS,
      ActionType.PATIENT_VIEW_GET_MEMBERS_ERROR,
    ],
    data,
  };
}

function saveMembers(data) {
  return {
    api: PatientApi.members.saveList,
    types: [
      ActionType.PATIENT_VIEW_SAVE_MEMBERS_START,
      ActionType.PATIENT_VIEW_SAVE_MEMBERS_SUCCESS,
      ActionType.PATIENT_VIEW_SAVE_MEMBERS_ERROR,
    ],
    data,
  };
}

function getMembersAll(data) {
  return {
    api: MemberApi.getChoose,
    types: [
      ActionType.PATIENT_VIEW_GET_MEMBERS_ALL_START,
      ActionType.PATIENT_VIEW_GET_MEMBERS_ALL_SUCCESS,
      ActionType.PATIENT_VIEW_GET_MEMBERS_ALL_ERROR,
    ],
    data,
  };
}

function saveReminderApi(data) {
  return {
    api: PatientApi.reminder.saveReminder,
    types: [
      ActionType.PATIENT_VIEW_SAVE_REMINDER_START,
      ActionType.PATIENT_VIEW_SAVE_REMINDER_SUCCESS,
      ActionType.PATIENT_VIEW_SAVE_REMINDER_ERROR,
    ],
    data,
  };
}

function updatePrimaryClinicianApi(data) {
  return {
    api: PatientApi.updatePrimaryClinician,
    types: [
      ActionType.UPDATE_PRIMARY_CLINICIAN_START,
      ActionType.UPDATE_PRIMARY_CLINICIAN_SUCCESS,
      ActionType.UPDATE_PRIMARY_CLINICIAN_ERROR,
    ],
    data,
  };
}

export function getPatientMembersListApi(data) {
  return {
    api: PatientApi.getPatientMembersList,
    types: [
      ActionType.GET_PATIENT_FAMILY_MEMBERS_START,
      ActionType.GET_PATIENT_FAMILY_MEMBERS_SUCCESS,
      ActionType.GET_PATIENT_FAMILY_MEMBERS_ERROR,
    ],
    data,
  };
}
