import _ from "lodash";
import { deepUpdate, update } from "immupdate";
import createReducer from "../helpers/createReducer";

import {
  CHANGE_TRANSACTION_KEYS_BY_CHECKED,
  CHANGE_TRANSACTION_TABLE_APPROVAL_FILTER,
  GET_APPOINTMENT_TRANSACTIONS_ERROR,
  GET_APPOINTMENT_TRANSACTIONS_START,
  GET_APPOINTMENT_TRANSACTIONS_SUCCESS,
  GET_DENTIST_TRANSACTIONS_SUCCESS,
  GET_DIAGNOSIS_TYPE_LIST_SUCCESS,
  GET_TRANSACTION_LIST_FOR_COMPLETE_SUCCESS,
  GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START,
  GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS,
  METHOD_POST_SEND_TO_PRE_APPROVAL_SUCCESS,
  METHOD_POST_TRANSACTIONS_COMPLETE_SUCCESS,
  POST_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS,
  PUT_DENTAL_TRANSACTION_ITEM_SUCCESS,
  PUT_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS,
  RESET_TRANSACTION_SEARCH_PARAMETERS,
  SELECT_ALL_CHECKBOXES_IN_COMPLETE_POPUP,
  SET_COMPLETE_POPUP_SEARCH_PARAMETERS,
  SET_TRANSACTION_DATA,
  SET_TRANSACTION_SEARCH_PARAMETERS,
  UPDATE_TRANSACTION_LIST,
} from "../actions/transactionActions";
import * as ActionTypes from "../actionTypes/transactionActionTypes";

import { TRANSACTION_INIT_CHART } from "../actions/chartActions";

import Utils from "../helpers/Utils";
import TransactionSearchParameters from "../models/TransactionSearchParameters";
import { sortTreatments } from "../helpers/TreatmentUtils";
import { CLEAR_TRANSACTIONS } from "../actions/callListActions";

const defaultState = {
  fetchingTransactionList: false,
  fetchingProvisionalList: false,
  transaction: {},
  transactionTotal: 0,
  startVisitTransaction: {},
  listForComplete: {},
  transactionItem: {},
  transactionSearchParameters: new TransactionSearchParameters(),
  setHoldPreApproval: {},
  getHoldPreApproval: {},
  completePopup: {
    element: {},
    searchParams: new TransactionSearchParameters(),
    selectedKeys: [],
  },
  completeTransaction: {},
  sendToPreApproval: {},

  planModalVisible: false,
  planModalAddMode: false,

  planList: [],
  planListTotalCount: 0,

  planNewList: [],
  planNewListTotalCount: 0,

  planSelected: {},
  planNewSelected: {},

  fetchingPlan: false,

  doctorFilter: [],
  doctors: [],
  approvalFilter: "",
  codeFilter: [],
  endDateFilter: null,
  startDateFilter: null,
  currency: {
    show: false,
    list: [],
  },
  diagnosisClaimTypeList: [],
  provisionalDiagnosisList: [],
  provisionalTotalCount: 0,
};

const reducers = {
  [GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_START](state) {
    return {
      ...state,
      fetchingProvisionalList: true,
    };
  },
  [GET_PROVISIONAL_APPOINTMENT_TRANSACTIONS_SUCCESS](state, { data }) {
    return {
      ...state,
      provisionalDiagnosisList: data,
      fetchingProvisionalList: false,
      provisionalTotalCount: data.totalCount,
    };
  },
  [ActionTypes.SET_START_DATE_FILTER](state, { date }) {
    return {
      ...state,
      startDateFilter: date,
    };
  },
  [ActionTypes.SET_END_DATE_FILTER](state, { date }) {
    return {
      ...state,
      endDateFilter: date,
    };
  },
  [ActionTypes.CHART_TRANSACTION_GET_CURRENCIES_SUCCESS](state, { data }) {
    return {
      ...state,
      currency: {
        ...state.currency,

        list: _.get(data, "currencies", []),
        show: _.get(data, "showCurrencyOptions", false),
      },
    };
  },
  [TRANSACTION_INIT_CHART](state) {
    return {
      ...state,
      toothFilter: [],
      codeFilter: [],
    };
  },
  [CHANGE_TRANSACTION_TABLE_APPROVAL_FILTER](state, { data }) {
    return {
      ...state,
      approvalFilter: data,
    };
  },
  [GET_APPOINTMENT_TRANSACTIONS_START](state) {
    return {
      ...state,
      fetchingTransactionList: true,
    };
  },
  [GET_APPOINTMENT_TRANSACTIONS_SUCCESS](state, { data, request }) {
    const result = data;
    if (request.start > 0) {
      result.list = (state.transaction.list || []).concat(data.list);
    }
    return {
      ...state,
      transaction: result,
      fetchingTransactionList: false,
      transactionTotal: data.totalCount,
      startVisitTransaction: request.startVisit ? result : state.startVisitTransaction,
    };
  },
  [GET_APPOINTMENT_TRANSACTIONS_ERROR](state) {
    return {
      ...state,
      fetchingTransactionList: false,
    };
  },
  [GET_DIAGNOSIS_TYPE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      diagnosisClaimTypeList: data || [],
    };
  },
  [GET_DENTIST_TRANSACTIONS_SUCCESS](state, { data, request }) {
    const result = data;
    if (request.start > 0) {
      result.list = (state.transaction.list || []).concat(data.list);
    }
    return { ...state, transaction: result };
  },
  [GET_TRANSACTION_LIST_FOR_COMPLETE_SUCCESS](state, { data, request }) {
    const result = data;
    if (request.start > 0) {
      result.list = state.listForComplete.list || [];
      result.list = Utils.pushArray(result.list, data.list);
    }
    return { ...state, listForComplete: result };
  },
  [SET_TRANSACTION_DATA](state, { data }) {
    return { ...state, transaction: data };
  },
  [UPDATE_TRANSACTION_LIST](state, { data }) {
    const { transaction = {} } = state;

    const list = (transaction.list || []).slice();

    const itemIndex = list.findIndex((x) => x.key === data.key);

    if (itemIndex >= 0) {
      return {
        ...state,
        transaction: {
          ...transaction,
          list: deepUpdate(list)
            .at(itemIndex)
            .modify((x) => update(x, data)),
        },
      };
    }

    return state;
  },
  [PUT_DENTAL_TRANSACTION_ITEM_SUCCESS](state, { data }) {
    return { ...state, transactionItem: data };
  },
  [SET_TRANSACTION_SEARCH_PARAMETERS](state, { data }) {
    return { ...state, transactionSearchParameters: data };
  },
  [RESET_TRANSACTION_SEARCH_PARAMETERS](state) {
    const { transactionSearchParameters } = state;
    transactionSearchParameters.clear();
    return { ...state, transactionSearchParameters };
  },
  [SET_COMPLETE_POPUP_SEARCH_PARAMETERS](state, { data }) {
    state.completePopup.searchParams = data;
    return { ...state };
  },
  [PUT_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS](state, { data }) {
    return { ...state, setHoldPreApproval: data };
  },
  [POST_HOLD_PRE_APPROVAL_TRANSACTION_SUCCESS](state, { data }) {
    return { ...state, getHoldPreApproval: data };
  },
  [METHOD_POST_SEND_TO_PRE_APPROVAL_SUCCESS](state, { data }) {
    return { ...state, sendToPreApproval: data };
  },
  [METHOD_POST_TRANSACTIONS_COMPLETE_SUCCESS](state, { data }) {
    return { ...state, completeTransaction: data };
  },
  [CHANGE_TRANSACTION_KEYS_BY_CHECKED](state, { key }) {
    const index = state.completePopup.selectedKeys.indexOf(key);
    if (index === -1) {
      state.completePopup.selectedKeys.push(key);
    } else {
      state.completePopup.selectedKeys.splice(index, 1);
    }
    return { ...state };
  },
  [SELECT_ALL_CHECKBOXES_IN_COMPLETE_POPUP](state, { isClearAll }) {
    if (state.listForComplete.list) {
      if (
        state.completePopup.selectedKeys.length === state.listForComplete.list.length ||
        isClearAll
      ) {
        state.completePopup.selectedKeys = [];
      } else {
        state.completePopup.selectedKeys = [];
        state.listForComplete.list.map((item) => {
          if (!item.parts) {
            state.completePopup.selectedKeys.push(item.key);
          } else {
            item.parts.forEach((part) => {
              if (part.status && part.status.code === "DentalTransactionStatus_COMPLETED") return;

              state.completePopup.selectedKeys.push(part.key);
            });
          }
        });
      }
    }
    return { ...state };
  },
  [ActionTypes.CHART_TRANSACTION_INIT_PLAN_MODAL](state) {
    return {
      ...state,
      planModalAddMode: false,
      planSelected: {},
      planNewSelected: {},
    };
  },
  [ActionTypes.CHART_TRANSACTION_OPEN_PLAN_MODAL](state) {
    return {
      ...state,
      planModalVisible: true,
    };
  },
  [ActionTypes.CHART_TRANSACTION_CLOSE_PLAN_MODAL](state) {
    return {
      ...state,
      planModalVisible: false,
    };
  },
  [ActionTypes.CHART_TRANSACTION_TOGGLE_ADD_MODE](state) {
    return {
      ...state,
      planModalAddMode: !state.planModalAddMode,
    };
  },
  [ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_START](state) {
    return {
      ...state,
      fetchingPlan: true,
    };
  },
  [ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_SUCCESS](state, { data, request }) {
    if (request.memberId) {
      if (request.start) {
        return {
          ...state,
          planList: state.planList.concat(data.list).sort(sortTreatments),
          planListTotalCount: data.totalCount,
          fetchingPlan: false,
        };
      }

      return {
        ...state,
        planList: (data.list || []).sort(sortTreatments),
        planListTotalCount: data.totalCount,
        fetchingPlan: false,
      };
    }

    if (request.excludeMemberId) {
      if (request.start) {
        return {
          ...state,
          planNewList: state.planNewList.concat(data.list).sort(sortTreatments),
          planNewListTotalCount: data.totalCount,
          fetchingPlan: false,
        };
      }

      return {
        ...state,
        planNewList: (data.list || []).sort(sortTreatments),
        planNewListTotalCount: data.totalCount,
        fetchingPlan: false,
      };
    }

    return state;
  },
  [ActionTypes.CHART_TRANSACTION_GET_PLAN_LIST_ERROR](state) {
    return {
      ...state,
      fetchingPlan: false,
    };
  },
  [ActionTypes.CHART_TRANSACTION_TOGGLE_PLAN](state, { key }) {
    const planSelected = { ...state.planSelected };

    planSelected[key] = !planSelected[key];

    return {
      ...state,
      planSelected,
    };
  },
  [ActionTypes.CHART_TRANSACTION_TOGGLE_ALL_PLAN_NEW](state) {
    const planNewSelected = {};

    const selectedItemsList = _.filter(state.planNewSelected, (x) => x);

    if (_.size(selectedItemsList) !== _.size(state.planNewList)) {
      state.planNewList.forEach((x) => {
        planNewSelected[x.key] = true;
      });
    }

    return {
      ...state,
      planNewSelected,
    };
  },
  [ActionTypes.CHART_TRANSACTION_TOGGLE_ALL_PLAN](state) {
    const planSelected = {};

    const selectedItemsList = _.filter(state.planSelected, (x) => x);

    if (_.size(selectedItemsList) !== _.size(state.planList)) {
      state.planList.forEach((x) => {
        planSelected[x.key] = true;
      });
    }

    return {
      ...state,
      planSelected,
    };
  },
  [ActionTypes.CHART_TRANSACTION_TOGGLE_PLAN_NEW](state, { key }) {
    const planNewSelected = { ...state.planNewSelected };

    planNewSelected[key] = !planNewSelected[key];

    return {
      ...state,
      planNewSelected,
    };
  },
  [ActionTypes.CHART_TRANSACTION_UNSELECT_PLAN](state) {
    return {
      ...state,
      planNewSelected: [],
    };
  },
  [ActionTypes.CHART_TRANSACTION_REMOVE_SELECTED_SUCCESS](state) {
    return {
      ...state,
      planSelected: {},
    };
  },
  [ActionTypes.CHART_TRANSACTION_ADD_SELECTED_SUCCESS](state) {
    return {
      ...state,
      planNewSelected: {},
    };
  },
  [ActionTypes.SET_TRANSACTION_DOCTOR_FILTER](state, { data }) {
    return { ...state, doctorFilter: data };
  },
  [ActionTypes.SET_TRANSACTION_CLINIC_FILTER](state, { data }) {
    return { ...state, clinicFilter: data };
  },
  [ActionTypes.CHART_TRANSACTION_GET_DOCTORS_SUCCESS](state, { data }) {
    return { ...state, doctors: data };
  },
  [ActionTypes.SET_TRANSACTION_TOOTH_FILTER](state, { data }) {
    return { ...state, toothFilter: data };
  },
  [ActionTypes.SET_TRANSACTION_CODES_FILTER](state, { data }) {
    return { ...state, codeFilter: data };
  },
  [CLEAR_TRANSACTIONS](state) {
    return { ...state, transaction: {} };
  },
};

/** ==================
 * Selectors
 * ================== */
export const transactionListSelector = ({ transaction }) => transaction.transaction.list || [];

export const fetchingTransactionListSelector = ({ transaction }) =>
  transaction.fetchingTransactionList;

export default createReducer(defaultState, reducers);
