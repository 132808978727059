import React from "react";

import Show from "../widgets/Show";
import FilePicker from "../widgets/FilePicker";

export default class PatientFormsDocFieldsForm extends React.Component {
  render() {
    const { patientRegister, changeTextArea, changeImage } = this.props;

    const {
      textArea1,
      textArea2,
      textArea3,
      textArea4,
      textArea5,
      textArea6,
      textArea7,
      textArea8,
      textArea9,
      textArea10,
      textArea11,
      textArea12,
      textArea13,
      textArea14,
      textArea15,
      textArea16,
      textArea17,
      textArea18,
      textArea19,
      textArea20,
      image1,
    } = patientRegister;

    return (
      <div>
        <Show if={patientRegister.hasTextArea1}>
          <div className="col-sm-12">
            <label htmlFor="textArea1" className="control-label">
              Text Area 1
            </label>
            <textarea
              className="form-control"
              id="textArea1"
              rows="6"
              ref={this.props.ref1}
              defaultValue={textArea1 || null}
              onChange={changeTextArea("textArea1")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea2}>
          <div className="col-sm-12">
            <label htmlFor="textArea2" className="control-label">
              Text Area 2
            </label>
            <textarea
              className="form-control"
              id="textArea2"
              rows="6"
              ref={this.props.ref2}
              defaultValue={textArea2 || null}
              onChange={changeTextArea("textArea2")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea3}>
          <div className="col-sm-12">
            <label htmlFor="textArea3" className="control-label">
              Text Area 3
            </label>
            <textarea
              className="form-control"
              id="textArea3"
              rows="6"
              ref={this.props.ref3}
              defaultValue={textArea3 || null}
              onChange={changeTextArea("textArea3")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea4}>
          <div className="col-sm-12">
            <label htmlFor="textArea4" className="control-label">
              Text Area 4
            </label>
            <textarea
              className="form-control"
              id="textArea4"
              rows="6"
              ref={this.props.ref4}
              defaultValue={textArea4 || null}
              onChange={changeTextArea("textArea4")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea5}>
          <div className="col-sm-12">
            <label htmlFor="textArea5" className="control-label">
              Text Area 5
            </label>
            <textarea
              className="form-control"
              id="textArea5"
              rows="6"
              ref={this.props.ref5}
              defaultValue={textArea5 || null}
              onChange={changeTextArea("textArea5")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea6}>
          <div className="col-sm-12">
            <label htmlFor="textArea6" className="control-label">
              Text Area 6
            </label>
            <textarea
              className="form-control"
              id="textArea6"
              rows="6"
              ref={this.props.ref6}
              defaultValue={textArea6 || null}
              onChange={changeTextArea("textArea6")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea7}>
          <div className="col-sm-12">
            <label htmlFor="textArea7" className="control-label">
              Text Area 7
            </label>
            <textarea
              className="form-control"
              id="textArea7"
              rows="6"
              ref={this.props.ref7}
              defaultValue={textArea7 || null}
              onChange={changeTextArea("textArea7")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea8}>
          <div className="col-sm-12">
            <label htmlFor="textArea8" className="control-label">
              Text Area 8
            </label>
            <textarea
              className="form-control"
              id="textArea8"
              rows="6"
              ref={this.props.ref8}
              defaultValue={textArea8 || null}
              onChange={changeTextArea("textArea8")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea9}>
          <div className="col-sm-12">
            <label htmlFor="textArea9" className="control-label">
              Text Area 9
            </label>
            <textarea
              className="form-control"
              id="textArea9"
              rows="6"
              ref={this.props.ref9}
              defaultValue={textArea9 || null}
              onChange={changeTextArea("textArea9")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea10}>
          <div className="col-sm-12">
            <label htmlFor="textArea10" className="control-label">
              Text Area 10
            </label>
            <textarea
              className="form-control"
              id="textArea10"
              rows="6"
              ref={this.props.ref10}
              defaultValue={textArea10 || null}
              onChange={changeTextArea("textArea10")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea11}>
          <div className="col-sm-12">
            <label htmlFor="textArea11" className="control-label">
              Text Area 11
            </label>
            <textarea
              className="form-control"
              id="textArea11"
              rows="6"
              ref={this.props.ref11}
              defaultValue={textArea11 || null}
              onChange={changeTextArea("textArea11")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea12}>
          <div className="col-sm-12">
            <label htmlFor="textArea12" className="control-label">
              Text Area 12
            </label>
            <textarea
              className="form-control"
              id="textArea12"
              rows="6"
              ref={this.props.ref12}
              defaultValue={textArea12 || null}
              onChange={changeTextArea("textArea12")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea13}>
          <div className="col-sm-12">
            <label htmlFor="textArea13" className="control-label">
              Text Area 13
            </label>
            <textarea
              className="form-control"
              id="textArea13"
              rows="6"
              ref={this.props.ref13}
              defaultValue={textArea13 || null}
              onChange={changeTextArea("textArea13")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea14}>
          <div className="col-sm-12">
            <label htmlFor="textArea14" className="control-label">
              Text Area 14
            </label>
            <textarea
              className="form-control"
              id="textArea14"
              rows="6"
              ref={this.props.ref14}
              defaultValue={textArea14 || null}
              onChange={changeTextArea("textArea14")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea15}>
          <div className="col-sm-12">
            <label htmlFor="textArea15" className="control-label">
              Text Area 15
            </label>
            <textarea
              className="form-control"
              id="textArea15"
              rows="6"
              ref={this.props.ref15}
              defaultValue={textArea15 || null}
              onChange={changeTextArea("textArea15")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea16}>
          <div className="col-sm-12">
            <label htmlFor="textArea16" className="control-label">
              Text Area 16
            </label>
            <textarea
              className="form-control"
              id="textArea16"
              rows="6"
              ref={this.props.ref16}
              defaultValue={textArea16 || null}
              onChange={changeTextArea("textArea16")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea17}>
          <div className="col-sm-12">
            <label htmlFor="textArea17" className="control-label">
              Text Area 17
            </label>
            <textarea
              className="form-control"
              id="textArea17"
              rows="6"
              ref={this.props.ref17}
              defaultValue={textArea17 || null}
              onChange={changeTextArea("textArea17")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea18}>
          <div className="col-sm-12">
            <label htmlFor="textArea18" className="control-label">
              Text Area 18
            </label>
            <textarea
              className="form-control"
              id="textArea18"
              rows="6"
              ref={this.props.ref18}
              defaultValue={textArea18 || null}
              onChange={changeTextArea("textArea18")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea19}>
          <div className="col-sm-12">
            <label htmlFor="textArea19" className="control-label">
              Text Area 19
            </label>
            <textarea
              className="form-control"
              id="textArea19"
              rows="6"
              ref={this.props.ref19}
              defaultValue={textArea19 || null}
              onChange={changeTextArea("textArea19")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasTextArea20}>
          <div className="col-sm-12">
            <label htmlFor="textArea20" className="control-label">
              Text Area 20
            </label>
            <textarea
              className="form-control"
              id="textArea20"
              rows="6"
              ref={this.props.ref20}
              defaultValue={textArea20 || null}
              onChange={changeTextArea("textArea20")}
            />
          </div>
        </Show>

        <Show if={patientRegister.hasImage1}>
          <div className="col-sm-12">
            <label className="control-label">Image1</label>
            <FilePicker
              value={image1}
              onChange={changeImage("image1")}
              className="patient-consent-form-edit-file-picker"
            />
          </div>
        </Show>
      </div>
    );
  }
}
