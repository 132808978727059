import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";
import { DatePicker } from "../../ui/DatePicker";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { PhoneNumberWrapper } from "../../ui/PhoneNumberWrapper";
import { Checkbox } from "../../ui/Checkbox";
import Utils from "../../../helpers/Utils";
const PhoneInput = (x) => <PhoneNumberWrapper {...x.input} size="lg" />;

const enhancer = connect(null, bindActions({ calendarActions }));
const form = reduxForm({
  form: "findAppointmentSidebar",
});

class FindAppointmentSidebarForm extends React.Component {
  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      this.props.calendarActions.searchAppointment(null, true);
      this.props.calendarActions.searchAppointment(null, false);
    }
  };

  render() {
    const {
      findOpenSlotFromDate,
      findOpenSlotToDate,
      doctors,
      valuesForm,
      machinesList = [],
    } = this.props;

    let doctorsList = [];

    _.forEach(doctors, (element) => {
      doctorsList.push({
        label: [element.firstName, element.lastName].join(" "),
        value: element.id,
      });
    });

    const firstMachine = _.head(machinesList);

    if (firstMachine && firstMachine.id === "") {
      machinesList.shift();
    }

    const machines = machinesList.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return (
      <form
        id="findAppointmentSidebar"
        className="open-slot-form clearfix list-group col-sm-padding"
      >
        {/*<div className="col-sm-12">*/}
        {/*  <label htmlFor="patientName" className="control-label">*/}
        {/*    Patient*/}
        {/*  </label>*/}
        {/*  <Field*/}
        {/*    type="text"*/}
        {/*    component="input"*/}
        {/*    className="form-control input-lg"*/}
        {/*    name="patientName"*/}
        {/*    id="patientName"*/}
        {/*    onKeyPress={this.handleKeyPress}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="col-sm-12">
          <label htmlFor="patientName" className="control-label">
            Patient First name:
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="patientFirstName"
            id="patientName"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="patientName" className="control-label">
            Patient Last name:
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="patientLastName"
            id="patientName"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="chartNumber" className="control-label">
            Chart Number
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="chartNumber"
            id="chartNumber"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="phoneNumber" className="control-label">
            Phone Number:
          </label>
          <Field id="phoneNumber" name="phoneNumber" component={PhoneInput} />
        </div>
        <div className="col-sm-12">
          <label htmlFor="chartNumber" className="control-label">
            Email
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="email"
            id="email"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="doctorId" className="control-label">
            {localStorage.getItem("doctorLabel")}
          </label>
          <Field
            component={(x) => (
              <SelectPicker
                options={doctorsList}
                value={{ value: x.input.value }}
                onChange={(v) => x.input.onChange(v.value)}
                size="lg"
              />
            )}
            name="doctorId"
            id="doctorId"
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="doctorId" className="control-label">
            Madical device
          </label>
          <Field
            component={(x) => (
              <SelectPicker
                options={machines}
                value={{ value: x.input.value }}
                onChange={(v) => x.input.onChange(v.value)}
                size="lg"
              />
            )}
            name="machineId"
            id="machineId"
          />
        </div>
        <div className="col-sm-12">
          <label htmlFor="doctorId" className="control-label">
            National ID
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="documentNationalIdNumber"
            id="documentNationalIdNumber"
            onKeyPress={this.handleKeyPress}
            normalize={Utils.nationalIdNormalize}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="patientName" className="control-label">
            Primary Card Number:
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="primaryCardNumber"
            id="primaryCardNumber"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="patientName" className="control-label">
            Secondary Card Number:
          </label>
          <Field
            type="text"
            component="input"
            className="form-control input-lg"
            name="secondaryCardNumber"
            id="secondaryCardNumber"
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="col-sm-12">
          <Field
            component={Checkbox}
            label="Use Date Range"
            className="mt-2 mb-2"
            name="useDateRange"
            id="useDateRange"
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="fromDate" className="control-label">
            From
          </label>
          <DatePicker
            selected={findOpenSlotFromDate}
            id="fromDate"
            name="fromDate"
            size="lg"
            disabled={Boolean(!_.get(valuesForm, "useDateRange", true))}
            onChange={this.props.calendarActions.findOpenSlotSidebarSetFromDate}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="toDate" className="control-label">
            To
          </label>
          <DatePicker
            selected={findOpenSlotToDate}
            id="toDate"
            name="toDate"
            size="lg"
            disabled={Boolean(!_.get(valuesForm, "useDateRange", true))}
            onChange={this.props.calendarActions.findOpenSlotSidebarSetToDate}
          />
        </div>
      </form>
    );
  }
}

export default enhancer(form(FindAppointmentSidebarForm));
