import Utils from "../helpers/Utils";

/* eslint-disable */
export default class Element {
  constructor(value, id, order, name, type, color) {
    this.id = id || -1;
    this.order = order || (order == 0 ? 0 : -1);
    this.value = value || "";
    this.name = name || ""; // some times this variable used as label
    this.type = type;
    this.parent = parent;
    this.disabled = false;
    this.required = false;
    this.readOnly = false;
    this.visible = true;
    this.options = [];
    this.focused = false;
    this.selected = false;
    this.selectedId = "";
    this.className = "element";
    this.color = color;
    this.handler = (e) => {
      if (e && e.target) {
        switch (this.type) {
          case "input":
            this.value = e.target.value;
            break;
          case "select":
            this.selectedId = e.target.value;
            if (this.options && this.options.length) {
              if (!this.value) {
                this.selected = Utils.getSelectedItem(this.selectedId);
              }
              this.value = this.selected && this.selected.name;
            }
            break;
          default:
            break;
        }
      }
    };
    if (name) {
      this.className += ` ${this.name.split(" ").join("-").toLowerCase()}`;
    }

    this.disableAndClear = (isTrue) => {
      this.value = isTrue ? "" : this.value;
      this.disabled = isTrue;
      this.selected = null;
      this.selectedId = "";
    };
    this.getClone = (parent) => {
      const clone = new Element(this.value, this.id, this.order, this.name, this.type);
      clone.parent = parent; // by setting parent
      clone.required = this.required;
      clone.disabled = this.disabled;
      clone.readOnly = this.readOnly;
      clone.visible = this.visible;
      clone.className = this.className;
      clone.color = this.color;
      clone.options = this.options;
      clone.selected = this.selected;
      clone.selectedId = this.selectedId;
      clone.handler = this.handler;
      return clone;
    };
  }
}
