import React from "react";
import { Form, Formik, Field } from "formik";
import { PhoneNumberField } from "../form/PhoneNumberField";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import Utils from "../../helpers/Utils";

export class PatientSearchForm extends React.Component {
  render() {
    const { onSubmit, initialValues } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Form className="chart-clinical-note">
              <SidebarHeader
                className="sidebar-header chart-side-header"
                title="Search Patient"
                onBackClick={this.props.onRequestClose}
                rightComponent={
                  <div className="d-flex">
                    <button className="btn btn-link text-white" type="submit">
                      SEARCH
                    </button>
                  </div>
                }
              />
              <div className="chart-side-body p-2">
                <div className="d-flex flex-column mb-2 mt-1">
                  <label htmlFor="firstName">Patient First name:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                  />
                </div>
                <div className="d-flex flex-column mb-2 mt-1">
                  <label htmlFor="lastName">Patient Last name:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                  />
                </div>
                <div className="d-flex flex-column mb-2">
                  <PhoneNumberField name="phoneNumber" label="Phone Number" search={true} />
                </div>
                <div className="d-flex flex-column mb-2">
                  <label htmlFor="patientChartNumber">Patient Chart Number:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="patientChartNumber"
                    id="patientChartNumber"
                    value={values.patientChartNumber}
                    onChange={(e) => setFieldValue("patientChartNumber", e.target.value)}
                  />
                </div>

                <div className="d-flex flex-column mb-2">
                  <label htmlFor="email">Email:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                </div>

                <div className="d-flex flex-column mb-2">
                  <label htmlFor="primaryCardNumber">Primary Card Number:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="primaryCardNumber"
                    id="primaryCardNumber"
                    value={values.primaryCardNumber}
                    onChange={(e) => setFieldValue("primaryCardNumber", e.target.value)}
                  />
                </div>

                <div className="d-flex flex-column mb-2">
                  <label htmlFor="secondaryCardNumber">Secondary Card Number:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="secondaryCardNumber"
                    id="secondaryCardNumber"
                    value={values.secondaryCardNumber}
                    onChange={(e) => setFieldValue("secondaryCardNumber", e.target.value)}
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="documentNationalIdNumber">National ID:</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="documentNationalIdNumber"
                    id="documentNationalIdNumber"
                    value={values.documentNationalIdNumber}
                    onChange={(e) =>
                      setFieldValue(
                        "documentNationalIdNumber",
                        Utils.nationalIdNormalize(e.target.value),
                      )
                    }
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
