import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerioExamListStatus from "./PerioExamListStatus";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { boneLossStatus, mobilityStatus, plaqueStatus } from "../../../constants/perio-exam";
import { useDispatch } from "react-redux";
import { perioExamTempItem } from "../../../actions/patientActions";

const PerioExamMode = ({ item }) => {
  const [furactionType, setFuractionType] = useState(item?.furcationStatus);
  const [mgdStatus, setMgdStatus] = useState(item?.mucogingivalDefectStatus);
  const dispatch = useDispatch();
  const onChangeForm = (key, value) => {
    dispatch(perioExamTempItem({ [key]: value }));
  };

  useEffect(() => {
    setFuractionType(item?.furcationStatus);
    setMgdStatus(item?.mucogingivalDefectStatus);
  }, [item?.furcationStatus, item?.mucogingivalDefectStatus]);

  return (
    <div style={{ height: "70%", overflowY: "scroll" }}>
      {/*<HeadBtnWrapper>*/}
      {/*  <p style={{ margin: 0 }}>MEASURMENT</p>*/}
      {/*  <p style={{ transform: "rotate(180deg)", marginTop: 0 }}>❮</p>*/}
      {/*</HeadBtnWrapper>*/}
      <div style={{ margin: "12px 16px" }}>
        <Title>Plaque</Title>
        <PerioExamListStatus
          options={plaqueStatus}
          defaultItem={item?.plaqueStatus}
          getItem={(item) => onChangeForm("plaqueStatus", item)}
        />
        <Title>Mobility</Title>
        <PerioExamListStatus
          options={mobilityStatus}
          defaultItem={item?.mobilityStatus}
          getItem={(item) => onChangeForm("mobilityStatus", item)}
        />
        <Title>Bone Loss</Title>
        <PerioExamListStatus
          options={boneLossStatus}
          defaultItem={item.boneLossStatus}
          getItem={(item) => onChangeForm("boneLossStatus", item)}
        />

        <Title>Furaction</Title>
        <FuractionWrapper>
          {[1, 2, 3, 4].map((item) => (
            <FuractionButton
              key={item}
              onClick={() => {
                setFuractionType(item);
                onChangeForm("furcationStatus", item);
              }}
              style={{
                background: furactionType == item ? "#b1bf35" : "#fff",
                color: furactionType == item && "#fff",
              }}
            >
              {item}
            </FuractionButton>
          ))}
        </FuractionWrapper>
        <Title style={{ marginBottom: 0 }}>MGD</Title>
        <FormControlLabel
          control={
            <Switch
              checked={mgdStatus}
              onChange={(event) => {
                setMgdStatus(event.target.checked);
                onChangeForm("mucogingivalDefectStatus", event.target.checked);
              }}
            />
          }
          label={mgdStatus ? "YES" : "NO"}
        />
        <Title>Bleeding</Title>
        <PerioActionBtnGroup>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Distal</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("bleedingDistal", !item.bleedingDistal)}
              style={item.bleedingDistal ? { border: "2px solid #ff0000ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ff0000ff" }} />
            </PerioActionBtnItem>
          </div>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Central</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("bleedingCentral", !item.bleedingCentral)}
              style={item.bleedingCentral ? { border: "2px solid #ff0000ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ff0000ff" }} />
            </PerioActionBtnItem>
          </div>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Mesial</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("bleedingMesial", !item.bleedingMesial)}
              style={item.bleedingMesial ? { border: "2px solid #ff0000ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ff0000ff" }} />
            </PerioActionBtnItem>
          </div>
        </PerioActionBtnGroup>

        <Title>Supporation</Title>
        <PerioActionBtnGroup>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Distal</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("suppurationDistal", !item.suppurationDistal)}
              style={item.suppurationDistal ? { border: "2px solid #ffa500ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ffa500ff" }} />
            </PerioActionBtnItem>
          </div>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Central</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("suppurationCentral", !item.suppurationCentral)}
              style={item.suppurationCentral ? { border: "2px solid #ffa500ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ffa500ff" }} />
            </PerioActionBtnItem>
          </div>
          <div style={{ width: "33%", textAlign: "center" }}>
            <Title style={{ margin: 0 }}>Mesial</Title>
            <PerioActionBtnItem
              onClick={() => onChangeForm("suppurationMesial", !item.suppurationMesial)}
              style={item.suppurationMesial ? { border: "2px solid #ffa500ff" } : {}}
            >
              <ActionBtn style={{ backgroundColor: "#ffa500ff" }} />
            </PerioActionBtnItem>
          </div>
        </PerioActionBtnGroup>
      </div>
    </div>
  );
};

const HeadBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 16px;
  color: #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  font-weight: bold;
`;

const Title = styled.p`
  font-size: 16px;
  color: #7c7c7c;
  margin: 10px 0;
`;
const FuractionWrapper = styled.div`
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FuractionButton = styled.button`
  width: 120px;
  height: 40px;
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  font-size: 16px;
`;

const PerioActionBtnGroup = styled.div`
  height: 135px;
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const PerioActionBtnItem = styled.button`
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: 1px solid #7c7c7c;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const ActionBtn = styled.div`
  width: 70px;
  height: 70px;
  border: 1px solid #7c7c7c;
  border-radius: 50%;
  font-size: 16px;
`;
export default PerioExamMode;
