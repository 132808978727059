import React from "react";

import MemberItem from "./MemberItem";
import MemberCarousel from "./MemberCarousel";

import Utils from "../../helpers/Utils";

export default function MemberList({ members, chooseMember }) {
  const $members = Utils.objectToArray(members, (member) => (
    <MemberItem key={member.id} member={member} chooseMember={chooseMember} />
  ));

  return <MemberCarousel>{$members}</MemberCarousel>;
}
