import createReducer from "../helpers/createReducer";
import * as ActionTypes from "../actionTypes/repeatAppointmentActionTypes";
import { INIT_APPOINTMENT } from "../actionTypes/appointmantActionTypes";

const defaultState = {
  doctors: [],
  trxRooms: [],
  types: [],
  selectType: null,
  note: null,
  note_02: null,
  notes: [],
  followNote: "",
};

const reducers = {
  [INIT_APPOINTMENT](state) {
    return {
      ...state,
      selectType: null,
      note: "",
      note_02: "",
    };
  },
  [ActionTypes.REPEAT_FOLLOW_SET_NOTE](state, { value }) {
    return {
      ...state,
      note: value.target.value,
    };
  },
  [ActionTypes.REPEAT_FOLLOW_SET_NOTE_02](state, { value }) {
    return {
      ...state,
      note_02: value.target.value,
    };
  },
  [ActionTypes.REPEAT_FOLLOW_TYPE_SELECT](state, { item }) {
    return {
      ...state,
      selectType: item,
    };
  },
  [ActionTypes.REPEAT_APPT_INIT_TRX_ROOMS](state) {
    return {
      ...state,
      trxRooms: [],
    };
  },
  [ActionTypes.REPEAT_APPT_GET_DOCTORS_SUCCESS](state, { data }) {
    return {
      ...state,
      doctors: data,
    };
  },
  [ActionTypes.REPEAT_APPT_GET_TRX_ROOMS_SUCCESS](state, { data }) {
    return {
      ...state,
      trxRooms: data,
    };
  },
  [ActionTypes.REPEAT_APPT_GET_TYPES_SUCCESS](state, { data }) {
    return {
      ...state,
      types: data,
    };
  },
  [ActionTypes.REPEAT_FOLLOW_GET_NOTE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      notes: data.list || [],
    };
  },
  [ActionTypes.SET_FOLLOW_NOTE](state, { followNote }) {
    return {
      ...state,
      followNote,
    };
  },
};

export default createReducer(defaultState, reducers);
