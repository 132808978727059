import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Utils from "../../helpers/Utils";
import CalendarBody from "../../components/calendar/CalendarBody";
import CalendarForm from "../../components/calendar/CalendarForm";
import CalendarTimes from "../../components/calendar/CalendarTimes";
import CalendarTitles from "../../components/calendar/CalendarTitles";
import CalendarBottomMenu from "../../components/calendar/CalendarBottomMenu";
import { PERMISSIONS } from "../../constants/Constants";
import { getClinics } from "../../actions/chartActions";
import { openSimpleModal } from "../../actions/simpleModalActions";
import FindOpenSlotSidebar from "../../components/calendar/find-open-slot-sidebar/FindOpenSlotSidebar";
import BackgroundImage from "../../assets/images/doctor.png";
import { onDragAppointmentsList } from "../../actions/calendarActions";
import FindAppointmentSidebar from "../../components/calendar/find-appointment-sidebar/FindAppointmentSidebar";
import {
  changeCalendarWeek,
  chooseMasterTypes,
  choosePoint,
  findAppointmentSidebarShow,
  findOpenSlotSidebarShow,
  getMasterType,
  initCalendar,
  resetColumns,
  selectClinicId,
  setActiveDoctorId,
  setCalendarViewMode,
  setClinicNotesExist,
  updateCalendar,
} from "../../actions/calendarActions";
import { Routes } from "../../constants/Routes";
import { AppLayout } from "../../components/app-layout/AppLayout";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { getCalendarFollowUpCount } from "../../actions/followUpActions";
import { getClinicColors } from "../../actions/appointmentActions";

const enhancer = connect(
  ({
    auth,
    session,
    blockedTimeSlot,
    auth: { members },
    form: { findAppointmentSidebar },
    followUp: { list },
    calendar: {
      date,
      points,
      doctors,
      columns,
      workTime,
      doctorId,
      searchMode,
      dentalPoint,
      masterTypes,
      appointments,
      appointmentsOld,
      appointmentsAll,
      activeDoctorId,
      isActiveDoctor,
      calendarViewMode,
      calendarWeaklyMode,
      findOpenSlotToDate,
      appointmentNoteModal,
      findOpenSlotFromDate,
      searchAppointmentFetching,
      searchOldAppointmentFetching,
      searchAllAppointmentFetching,
      clinicId: calendarClinicId,
      findOpenSlotSidebarVisible,
      appointmentNoteModalVisible,
      findAppointmentSidebarVisible,
      dentalPointsIds,
    },
  }) => {
    return {
      auth,
      date,
      points,
      doctors,
      session,
      columns,
      members,
      workTime,
      doctorId,
      searchMode,
      dentalPoint,
      masterTypes,
      appointments,
      appointmentsOld,
      appointmentsAll,
      activeDoctorId,
      isActiveDoctor,
      blockedTimeSlot,
      calendarClinicId,
      calendarViewMode,
      calendarWeaklyMode,
      findOpenSlotToDate,
      findOpenSlotFromDate,
      appointmentNoteModal,
      findAppointmentSidebar,
      searchAppointmentFetching,
      searchOldAppointmentFetching,
      searchAllAppointmentFetching,
      findOpenSlotSidebarVisible,
      appointmentNoteModalVisible,
      findAppointmentSidebarVisible,
      dentalPointsIds,
      list,
    };
  },
  {
    getClinics,
    choosePoint,
    resetColumns,
    initCalendar,
    getMasterType,
    updateCalendar,
    getClinicColors,
    selectClinicId,
    openSimpleModal,
    chooseMasterTypes,
    setActiveDoctorId,
    changeCalendarWeek,
    setCalendarViewMode,
    setClinicNotesExist,
    findOpenSlotSidebarShow,
    findAppointmentSidebarShow,
    getCalendarFollowUpCount,
    onDragAppointmentsList,
  },
);

class CalendarPage extends React.Component {
  UNSAFE_componentWillMount() {
    const query = parseQuery(this.props.location.search);

    this.props.initCalendar(_.get(query, "saveSettings", false));
    // this.props.getClinicColors();
  }

  componentDidMount() {
    const {
      getClinics,
      calendarClinicId,
      session,
      selectClinicId,
      onDragAppointmentsList,
    } = this.props;

    const clinicId = _.get(session, "clinic.id");
    const permissions = _.get(session, "permissions", []);
    const showClinicSwitcher = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_CLINIC_FILTER") >= 0;
    const storageClinicId = localStorage.getItem("clinicId");
    if (showClinicSwitcher && calendarClinicId === 0) {
      selectClinicId(Number(storageClinicId) || clinicId);
    }

    getClinics();
    onDragAppointmentsList();
  }

  handleSubmit = () => {
    this.props.updateCalendar();
  };

  goToDashboard = () => {
    this.props.history.push(Routes.Dashboard);
  };

  render() {
    const {
      workTime,
      columns,
      date,
      doctors,
      members,
      points,
      findOpenSlotSidebarVisible,
      findAppointmentSidebarVisible,
      findOpenSlotFromDate,
      findOpenSlotToDate,
      dentalPoint,
      searchMode,
      doctorId,
      appointmentNoteModalVisible,
      appointmentNoteModal,
      appointments,
      appointmentsOld,
      appointmentsAll,
      findAppointmentSidebar,
      searchAppointmentFetching,
      searchOldAppointmentFetching,
      searchAllAppointmentFetching,
      findAppointmentSidebarShow,
      session,
      auth,
      calendarViewMode,
      calendarWeaklyMode,
      activeDoctorId,
      setActiveDoctorId,
      isActiveDoctor,
      blockedTimeSlot,
    } = this.props;

    const getCalendarMinuteIncrements =
      _.get(session, "miscSettings.calendarMinuteIncrements", 15) > 0;

    const timeArray = Utils.getTimeArray(
      workTime.startTime,
      workTime.endTime,
      _.get(session, "timeFormat"),
      getCalendarMinuteIncrements ? _.get(session, "miscSettings.calendarMinuteIncrements") : 15,
    );

    const timezoneDifference = Utils.getTimezoneDifference();
    const query = parseQuery(this.props.location.search);
    const ids = this.props.dentalPointsIds;
    const columnsList = () => {
      let temp = [];
      if (ids.length > 0 && ids[0].doctorId) {
        for (let i = 0; i < columns.length; i++) {
          for (let j = 0; j < ids.length; j++) {
            if (ids[j].doctorId === columns[i].dentistId) {
              temp.push(columns[i]);
            }
          }
        }
        return temp;
      }
      return columns;
    };
    const customClinicFeature = () => {
      const columns = columnsList();
      //     .filter((item) => {
      //   return (
      //     (members[item?.dentistId] && members[item?.dentistId].status?.id !== 1) || item?.isPublic
      //   );
      // });
      if (
        session.company.id === 193 &&
        (session.member.id === 7 ||
          session.member.id === 11000027 ||
          session.member.id === 11000024 ||
          session.member.id === 11000039 ||
          session.member.id === 11000040 ||
          session.member.id === 11000042 ||
          session.member.id === 11000029)
      ) {
        return columns
          .filter(
            (item) =>
              item.dentistId === 7 ||
              item.dentistId === 11000027 ||
              item.dentistId === 11000024 ||
              item.dentistId === 11000039 ||
              item.dentistId === 11000040 ||
              item.dentistId === 11000042 ||
              item.dentistId === 11000029,
          )
          .sort((a, b) => a.title?.dentistName - b.title?.dentistName);
      } else {
        return columns.sort((a, b) => a.title?.dentistName - b.title?.dentistName);
      }
    };
    return (
      <AppLayout
        contentClassName="p-0"
        onBackClick={this.goToDashboard}
        backgroundImage={BackgroundImage}
        rightComponentClassName="align-items-center"
        rightComponent={
          <CalendarForm
            auth={auth}
            points={points}
            members={members}
            onSubmit={this.handleSubmit}
            activeDoctorId={activeDoctorId}
            permissions={session.permissions}
            reload={this.props.updateCalendar}
            calendarViewMode={calendarViewMode}
            choosePoint={this.props.choosePoint}
            masterTypes={this.props.masterTypes}
            setActiveDoctorId={setActiveDoctorId}
            resetColumns={this.props.resetColumns}
            chooseTypes={this.props.chooseMasterTypes}
            changeCalendarWeek={this.props.changeCalendarWeek}
            setCalendarViewMode={this.props.setCalendarViewMode}
            setClinicNotesExist={this.props.setClinicNotesExist}
            onFindApptClick={() => findAppointmentSidebarShow()}
            onPhoneClick={() => this.props.history.push(Routes.NewPatientScreen)}
            findOpenSlotSidebarShow={() => this.props.findOpenSlotSidebarShow()}
            onNotificationClick={() =>
              this.props.history.push(
                createUrl(Routes.DashboardFollowUp, {
                  query: { responded: true },
                }),
              )
            }
            onWhatsAppClick={() => this.props.history.push(Routes.DashboardWhatsappChatting)}
            showFirstContact={
              (true || session.firstContactOptionEnabled) &&
              session.permissions.indexOf(PERMISSIONS.VIEW_FIRST_CONTACT_VIEW) >= 0
            }
          />
        }
      >
        <div className="calendar">
          <CalendarTitles
            calendarWeaklyMode={calendarWeaklyMode}
            columns={customClinicFeature()}
            members={members}
            allowAppointmentMultiBooking={session.allowAppointmentMultiBooking}
          />
          <CalendarTimes
            timeArray={timeArray}
            calendarMinuteIncrements={_.get(session, "miscSettings.calendarMinuteIncrements", 15)}
          />
          <CalendarBody
            date={date}
            rows={timeArray}
            columns={customClinicFeature()}
            workTime={workTime}
            isActiveDoctor={isActiveDoctor}
            activeDoctorId={activeDoctorId}
            query={query}
            permissions={session.permissions}
            calendarViewMode={calendarViewMode}
            masterTypes={this.props.masterTypes}
            resetColumns={this.props.resetColumns}
            timezoneDifference={timezoneDifference}
            appointmentNoteModal={appointmentNoteModal}
            openSimpleModal={this.props.openSimpleModal}
            appointmentNoteModalVisible={appointmentNoteModalVisible}
          />
        </div>

        <div
          style={{
            width: "124px",
            height: "50px",
            backgroundColor: "#FDFCFE",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 20000000,
          }}
        />
        <CalendarBottomMenu reload={this.props.updateCalendar} history={this.props.history} />
        <FindOpenSlotSidebar
          doctors={doctors}
          doctorId={doctorId}
          searchMode={searchMode}
          dentalPoint={dentalPoint}
          blockedTimeSlot={blockedTimeSlot}
          findOpenSlotToDate={findOpenSlotToDate}
          findOpenSlotFromDate={findOpenSlotFromDate}
          findOpenSlotSidebarVisible={findOpenSlotSidebarVisible}
        />
        <FindAppointmentSidebar
          appointments={appointments}
          appointmentsOld={appointmentsOld}
          appointmentsAll={appointmentsAll}
          findOpenSlotToDate={findOpenSlotToDate}
          findOpenSlotFromDate={findOpenSlotFromDate}
          values={_.get(findAppointmentSidebar, "values", {})}
          searchAppointmentFetching={searchAppointmentFetching}
          searchOldAppointmentFetching={searchOldAppointmentFetching}
          searchAllAppointmentFetching={searchAllAppointmentFetching}
          findAppointmentSidebarVisible={findAppointmentSidebarVisible}
        />
      </AppLayout>
    );
  }
}

export default enhancer(CalendarPage);
