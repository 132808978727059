import React from "react";
import { ButtonGroup } from "../ui/ButtonGroup";
import _ from "lodash";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";

export const ViewTypes = {
  List: "list",
  Grid: "grid",
};

export function ViewButtonGroup({ active, onClick, orderStack }) {
  const orderStackItem = _.last(orderStack);
  const isProvisional = orderStackItem?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

  return (
    <ButtonGroup
      active={active}
      buttons={[
        ...(isProvisional
          ? []
          : [
              {
                label: "Grid",
                value: ViewTypes.Grid,
              },
            ]),
        {
          label: "List",
          value: ViewTypes.List,
        },
      ]}
      onClick={onClick}
    />
  );
}
