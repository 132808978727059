import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/patientRegisterActionTypes";

export const defaultState = {
  registeredPatients: [],
  appointmentsList: [],
  fetchingAppointmentsList: false,
  appointmentsListTotalCount: 0,
  appointmentsReasonModalId: 0,
  appointmentsReasonModalMode: "log",
  appointmentsReasonModalVisible: false,
  appointmentsReasonModalData: [],
  appointmentsLogModalIndex: 0,
  patientInfo: {},
  nationality: [],
  pcpRelationshipType: [],
  pcpRoleTypes: [],
  profession: [],
  gender: [],
  languages: [],
  maritalStatus: [],
  country: [],
  state: [],
  city: [],
  referralTypes: [],
  houseHoldModalVisible: false,
  cardReaderInfoModalVisible: false,
  listHousehold: [],
  listHouseholdTotalCount: 0,
  activeHousehold: 0,
  fetchingListHousehold: false,
  searchHousehold: "",
  signatureModalVisible: false,
  signatureGuardianModalVisible: false,
  signature: "",
  personalDetails: {
    patient: {},
  },
  patientChooseActionModalVisible: false,
  patientSelectAppointmentSidebarVisible: false,
  patientSelectAppointmentSidebarMode: "date",
  patientSelectAppointmentSidebarDate: moment(),
  patientSelectAppointmentSidebarList: [],
  patientMedicalAlerts: {},
  patientMedicalAlertsTypes: [],
  patientMedicalAlertsSubCategories: [],
  patientMedicationAlerts: {},
  patientMedicalAlertsEditMode: false,
  patientMedicationAlertsEditMode: false,
  patientDetailsEditMode: false,
  patientAlertsHistoryUnsortedList: [],
  patientAlertsHistoryList: [],
  patientAlertsHistoryListTotalCount: 0,
  patientAlertsHistoryListFetching: false,
  patientAlertsHistoryMode: "",
  patientAlertsHistoryUrl: "",
  patientAlertsHistoryLastIndex: 0,

  formsList: [],
  fetchingFormsList: false,
  formsListTotalCount: 0,
  formsItem: {},
  fullForm: {},

  docFieldsModalVisible: true,
  docFieldsEditMode: false,
  docFieldsEditType: "dentist",
  docFieldsSelectedMember: 0,
  memberListSearch: "",

  addConsentFormModalVisible: false,
  searchValueAddConsentForm: "",
  addConsentFormList: [],
  consentFormCategoryList: [],
  fetchingConsentFormCategoryList: false,
  addConsentFormListTotalCount: 0,
  fetchingAddConsentForm: false,
  showReferralDescription: false,

  patientDetails: {},
  claimsList: [],
  claimsListTotalCount: 0,
  fetchingClaims: false,
  claimsStatusList: [],
  claimedStatusList: [],
  claimsDocTypeList: [],
  approvalsList: [],
  approvalsListTotalCount: 0,
  fetchingApprovals: false,
  approvalsStatusList: [],
  approvalsDocTypeList: [],
  claimsApprovalsActiveTab: "approvals",
  claimsApprovalsPdf: null,
  claimItemNote: "",
  titles: [],
  occupationList: [],
  approvalsFilter: {
    statusCode: "Approved",
    futureAppointmentExist: "false",
    patientChartNumber: "",
    claimedStatusCode: "NOT_CLAIMED",
    doctorId: 0,
    startDate: null,
    endDate: null,
  },
  appointmentUpcomingTotalCount: 0,
  appointmentMissedTotalCount: 0,
  appointmentCompleteTotalCount: 0,
  appointmentWithoutLateCancelledTotalCount: 0,
  appointmentAllTotalCount: 0,
  primaryCareProviderValidationFields: {
    notAllowChangeDoctorField: false,
    notAllowChangeRoleBeginField: false,
    shouldUnlockRoleEnd: false,
    isValidateBtnClicked: false,
  },
};

const reducers = {
  [ActionTypes.SET_VALUES_FOR_CARE_PROVIDER_VALIDATION](state, { values }) {
    let _primaryCareProvider = {};

    _primaryCareProvider.notAllowChangeDoctorField =
      values.relationshipType && values.relationshipType.value === "PCP_Pending";
    _primaryCareProvider.notAllowChangeRoleBeginField =
      (values.relationshipType && values.relationshipType.value === "PCP_Stopped") ||
      (values.relationshipType && values.relationshipType.value === "PCP_Cancelled");
    _primaryCareProvider.shouldUnlockRoleEnd =
      values.relationshipType && values.relationshipType.value === "PCP_Stopped";

    return {
      ...state,
      primaryCareProviderValidationFields: { ..._primaryCareProvider, isValidateBtnClicked: true },
    };
  },
  [ActionTypes.PATIENT_GET_MEDICAL_ALERTS_SUB_CATEGORIES_SUCCESS](state, { data }) {
    return {
      ...state,
      patientMedicalAlertsSubCategories: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_CLEAR_DETAILS](state) {
    return {
      ...state,
      personalDetails: {
        patient: {},
      },
      patientDetails: {},
    };
  },
  [ActionTypes.PATIENT_APPOINTMENT_GET_HISTORY_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentsReasonModalData: data || [],
    };
  },
  [ActionTypes.CLAIMS_GET_LIST_ERROR](state) {
    return {
      ...state,
      fetchingClaims: false,
    };
  },
  [ActionTypes.CLAIMS_GET_LIST_START](state) {
    return {
      ...state,
      fetchingClaims: true,
    };
  },
  [ActionTypes.CLAIMS_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        claimsList: state.claimsList.concat(data.list),
        claimsListTotalCount: data.totalCount,
        fetchingClaims: false,
      };
    }

    return {
      ...state,
      claimsList: data.list || [],
      claimsListTotalCount: data.totalCount,
      fetchingClaims: false,
    };
  },
  [ActionTypes.CLAIMS_GET_STATUS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      claimsStatusList: data || [],
    };
  },
  [ActionTypes.CLAIMS_GET_DOC_TYPE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      claimsDocTypeList: data || [],
    };
  },
  [ActionTypes.CLAIMS_GET_CLAIMED_STATUS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      claimedStatusList: data || [],
    };
  },
  [ActionTypes.APPROVALS_GET_LIST_ERROR](state) {
    return {
      ...state,
      fetchingApprovals: false,
    };
  },
  [ActionTypes.APPROVALS_GET_LIST_START](state) {
    return {
      ...state,
      fetchingApprovals: true,
    };
  },
  [ActionTypes.APPROVALS_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start && data.list) {
      return {
        ...state,
        approvalsList: state.approvalsList.concat(data.list),
        approvalsListTotalCount: data.totalCount,
        fetchingApprovals: false,
      };
    }

    return {
      ...state,
      approvalsList: data.list || [],
      approvalsListTotalCount: data.totalCount,
      fetchingApprovals: false,
    };
  },
  [ActionTypes.APPROVALS_GET_STATUS_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      approvalsStatusList: data || [],
    };
  },
  [ActionTypes.APPROVALS_GET_DOC_TYPE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      approvalsDocTypeList: data || [],
    };
  },
  [ActionTypes.PRINT_CLAIM_SUCCESS](state) {
    return {
      ...state,
      signature: "",
      claimItemNote: "",
    };
  },
  [ActionTypes.SET_APPROVALS_CLAIMS_ITEM_NOTE](state, { note }) {
    return {
      ...state,
      claimItemNote: note,
    };
  },
  [ActionTypes.PATIENT_CLAIMS_APPROVALS_SAVE_PDF](state, { file }) {
    return {
      ...state,
      claimsApprovalsPdf: file,
    };
  },
  [ActionTypes.PATIENT_CLAIMS_APPROVALS_CHANGE_TAB](state, { activeTab }) {
    return {
      ...state,
      claimsApprovalsActiveTab: activeTab,
    };
  },
  [ActionTypes.APPROVALS_FILTER_ITEMS](state, { data }) {
    return {
      ...state,
      approvalsFilter: { ...state.approvalsFilter, ...data },
    };
  },
  [ActionTypes.APPROVALS_FILTER_ITEMS_CLEAR](state) {
    return {
      ...state,
      approvalsFilter: {
        statusCode: "Approved",
        futureAppointmentExist: "false",
        patientChartNumber: "",
        claimedStatusCode: "NOT_CLAIMED",
        doctorId: 0,
        startDate: null,
        endDate: null,
      },
    };
  },
  [ActionTypes.PATIENT_FORMS_GUARDIAN_SIGNATURE_SHOW](state) {
    return {
      ...state,
      signatureGuardianModalVisible: true,
    };
  },
  [ActionTypes.PATIENT_FORMS_GUARDIAN_SIGNATURE_HIDE](state) {
    return {
      ...state,
      signatureGuardianModalVisible: false,
    };
  },
  [ActionTypes.PATIENT_FORMS_SIGN_GUARDIAN](state, { signature }) {
    return {
      ...state,
      guardian: {
        code: signature,
      },
    };
  },
  [ActionTypes.PATIENT_FORMS_SHOW_REFERRAL_DESCRIPTION_MODAL](state, { boolean }) {
    return {
      ...state,
      showReferralDescription: boolean,
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_RECENT_APPOINTMENTS_SUCCESS](
    state,
    { data },
  ) {
    return {
      ...state,
      patientSelectAppointmentSidebarList: data || [],
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_GET_DATE_APPOINTMENTS_SUCCESS](state, { data }) {
    return {
      ...state,
      patientSelectAppointmentSidebarList: data || [],
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_DATE](state, { date }) {
    return {
      ...state,
      patientSelectAppointmentSidebarDate: date,
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SET_MODE](state, { mode }) {
    return {
      ...state,
      patientSelectAppointmentSidebarMode: mode,
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_INIT](state) {
    return {
      ...state,
      patientSelectAppointmentSidebarMode: "date",
      patientSelectAppointmentSidebarDate: moment(),
      patientSelectAppointmentSidebarList: [],
      patientSelectAppointmentSidebarChooseDateList: [],
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_SHOW](state) {
    return {
      ...state,
      patientSelectAppointmentSidebarVisible: true,
      patientChooseActionModalVisible: false,
    };
  },
  [ActionTypes.PATIENT_SELECT_APPOINTMENT_SIDEBAR_HIDE](state) {
    return {
      ...state,
      patientSelectAppointmentSidebarVisible: false,
    };
  },
  [ActionTypes.PATIENT_CHOOSE_ACTION_MODAL_SHOW](state) {
    return {
      ...state,
      patientChooseActionModalVisible: true,
    };
  },
  [ActionTypes.PATIENT_CHOOSE_ACTION_MODAL_HIDE](state) {
    return {
      ...state,
      patientChooseActionModalVisible: false,
    };
  },
  [ActionTypes.APPOINTMENTS_REASON_MODAL_SHOW](state, { id, mode, index }) {
    return {
      ...state,
      appointmentsReasonModalVisible: true,
      appointmentsReasonModalId: id,
      appointmentsReasonModalMode: mode,
      appointmentsLogModalIndex: index,
    };
  },
  [ActionTypes.DELETE_PATIENT_IMAGE](state) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          photo: null,
        },
      },
    };
  },
  [ActionTypes.APPOINTMENTS_REASON_MODAL_HIDE](state) {
    return {
      ...state,
      appointmentsReasonModalVisible: false,
    };
  },
  [ActionTypes.INIT_PATIENT_ALERTS_HISTORY](state) {
    return {
      ...state,
      patientAlertsHistoryList: [],
      patientAlertsHistoryListTotalCount: 0,
      patientAlertsHistoryListFetching: false,
      patientAlertsHistoryMode: "",
      patientAlertsHistoryUrl: "",
      patientAlertsHistoryUnsortedList: [],
      patientAlertsHistoryLastIndex: 0,
    };
  },
  [ActionTypes.SET_PATIENT_ALERTS_HISTORY_URL](state, data) {
    const patientAlertsHistoryList = [...state.patientAlertsHistoryList];

    patientAlertsHistoryList[state.patientAlertsHistoryLastIndex].selected = false;
    patientAlertsHistoryList[data.index].selected = true;

    return {
      ...state,
      patientAlertsHistoryList,
      patientAlertsHistoryLastIndex: data.index,
      patientAlertsHistoryUrl: patientAlertsHistoryList[data.index].pdfUrl,
    };
  },
  [ActionTypes.SET_PATIENT_ALERTS_HISTORY_MODE](state, { mode }) {
    return {
      ...state,
      patientAlertsHistoryMode: mode,
    };
  },
  [ActionTypes.GET_PATIENT_ALERTS_HISTORY_START](state) {
    return {
      ...state,
      patientAlertsHistoryListFetching: true,
    };
  },
  [ActionTypes.GET_PATIENT_ALERTS_HISTORY_SUCCESS](state, { data, request }) {
    const list = [];
    let patientAlertsHistoryUrl = "";

    const patientAlertsHistoryUnsortedList = request.start
      ? state.patientAlertsHistoryUnsortedList.concat(data.list)
      : data.list;

    _.forEach(patientAlertsHistoryUnsortedList, (item, index) => {
      list[index] = {
        ...item,
        selected: false,
      };
    });

    const patientAlertsHistoryList = _.orderBy(list, "pdfCreatedDate", "desc") || [];

    if (patientAlertsHistoryList.length > 0 && !request.start) {
      patientAlertsHistoryUrl = patientAlertsHistoryList[0].pdfUrl;
      patientAlertsHistoryList[0].selected = true;
    } else if (request.start) {
      patientAlertsHistoryUrl =
        patientAlertsHistoryList[state.patientAlertsHistoryLastIndex].pdfUrl;
      patientAlertsHistoryList[state.patientAlertsHistoryLastIndex].selected = true;
    }

    return {
      ...state,
      patientAlertsHistoryUnsortedList,
      patientAlertsHistoryList,
      patientAlertsHistoryUrl,
      patientAlertsHistoryListTotalCount: data.totalCount,
      patientAlertsHistoryListFetching: false,
    };
  },
  [ActionTypes.GET_PATIENT_ALERTS_HISTORY_ERROR](state) {
    return {
      ...state,
      patientAlertsHistoryListFetching: false,
    };
  },
  [ActionTypes.PATIENT_GET_APPOINTMENT_LIST_START](state) {
    return {
      ...state,
      fetchingAppointmentsList: true,
      appointmentsReasonModalVisible: false,
      appointmentsLogModalVisible: false,
    };
  },
  [ActionTypes.PATIENT_GET_APPOINTMENT_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      fetchingAppointmentsList: false,
      appointmentsList: request.start ? state.appointmentsList.concat(data.list) : data.list,
      appointmentsListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.PATIENT_GET_APPOINTMENT_LIST_ERROR](state) {
    return {
      ...state,
      fetchingAppointmentsList: false,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_LIST_START](state) {
    return {
      ...state,
      fetchingFormsList: true,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      fetchingFormsList: false,
      formsList: request.start ? state.formsList.concat(data.list) : data.list,
      formsListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_LIST_ERROR](state) {
    return {
      ...state,
      fetchingFormsList: false,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_ITEM_SUCCESS](state, { data }) {
    return {
      ...state,
      formsItem: data,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_EDIT_SUCCESS](state, { data }) {
    const hasTextArea1 = data.signContent.indexOf("${text_area_1}") !== -1;
    const hasTextArea2 = data.signContent.indexOf("${text_area_2}") !== -1;
    const hasTextArea3 = data.signContent.indexOf("${text_area_3}") !== -1;
    const hasTextArea4 = data.signContent.indexOf("${text_area_4}") !== -1;
    const hasTextArea5 = data.signContent.indexOf("${text_area_5}") !== -1;
    const hasTextArea6 = data.signContent.indexOf("${text_area_6}") !== -1;
    const hasTextArea7 = data.signContent.indexOf("${text_area_7}") !== -1;
    const hasTextArea8 = data.signContent.indexOf("${text_area_8}") !== -1;
    const hasTextArea9 = data.signContent.indexOf("${text_area_9}") !== -1;
    const hasTextArea10 = data.signContent.indexOf("${text_area_10}") !== -1;
    const hasTextArea11 = data.signContent.indexOf("${text_area_11}") !== -1;
    const hasTextArea12 = data.signContent.indexOf("${text_area_12}") !== -1;
    const hasTextArea13 = data.signContent.indexOf("${text_area_13}") !== -1;
    const hasTextArea14 = data.signContent.indexOf("${text_area_14}") !== -1;
    const hasTextArea15 = data.signContent.indexOf("${text_area_15}") !== -1;
    const hasTextArea16 = data.signContent.indexOf("${text_area_16}") !== -1;
    const hasTextArea17 = data.signContent.indexOf("${text_area_17}") !== -1;
    const hasTextArea18 = data.signContent.indexOf("${text_area_18}") !== -1;
    const hasTextArea19 = data.signContent.indexOf("${text_area_19}") !== -1;
    const hasTextArea20 = data.signContent.indexOf("${text_area_20}") !== -1;
    const hasImage1 = data.signContent.indexOf("${image_1}") !== -1;

    return {
      ...state,
      formsItem: data,
      hasTextArea1,
      hasTextArea2,
      hasTextArea3,
      hasTextArea4,
      hasTextArea5,
      hasTextArea6,
      hasTextArea7,
      hasTextArea8,
      hasTextArea9,
      hasTextArea10,
      hasTextArea11,
      hasTextArea12,
      hasTextArea13,
      hasTextArea14,
      hasTextArea15,
      hasTextArea16,
      hasTextArea17,
      hasTextArea18,
      hasTextArea19,
      hasTextArea20,
      hasImage1,
    };
  },
  [ActionTypes.PATIENT_REGISTER_INIT](state) {
    return {
      ...state,
      personalDetails: {
        patient: {},
      },
      signature: "",
      showReferralDescription: false,
    };
  },
  [ActionTypes.PATIENT_REGISTER_VALIDATE_SUCCESS](state, { data }) {
    return {
      ...state,
      registeredPatients: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_DETAILS_INFO_SUCCESS](state, { data }) {
    return {
      ...state,
      patientInfo: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_PATIENT_DETAILS_SUCCESS](state, { data }) {
    let showReferralDescription = false;
    let _primaryCareProvider = {};

    if (
      data.patient &&
      data.patient.referralType &&
      data.patient.referralType.code === "REFERRAL_TYPE_OTHER"
    )
      showReferralDescription = true;

    if (data.patient && data.patient.primaryCareProvider) {
      _primaryCareProvider.notAllowChangeDoctorField =
        (data.patient.primaryCareProvider.relationshipType &&
          data.patient.primaryCareProvider.relationshipType.code === "PCP_Active") ||
        (data.patient.primaryCareProvider.relationshipType &&
          data.patient.primaryCareProvider.relationshipType.code === "PCP_Pending");
      _primaryCareProvider.notAllowChangeRoleBeginField =
        data.patient.primaryCareProvider.relationshipType &&
        data.patient.primaryCareProvider.relationshipType.code === "PCP_Active";
      _primaryCareProvider.shouldUnlockRoleEnd =
        data.patient.primaryCareProvider.relationshipType &&
        data.patient.primaryCareProvider.relationshipType.code === "PCP_Stopped";
    }

    return {
      ...state,
      personalDetails: data,
      patientDetailsEditMode: false,
      showReferralDescription,
      primaryCareProviderValidationFields: { ..._primaryCareProvider, isValidateBtnClicked: false },
    };
  },
  [ActionTypes.PATIENT_GET_MEDICAL_ALERTS_SUCCESS](state, { data }) {
    return {
      ...state,
      patientMedicalAlerts: {
        alerts: data,
      },
      patientMedicalAlertsEditMode: false,
    };
  },
  [ActionTypes.PATIENT_GET_MEDICAL_ALERTS_CATEGORIES_SUCCESS](state, { data }) {
    return {
      ...state,
      patientMedicalAlertsTypes: data,
    };
  },
  [ActionTypes.PATIENT_GET_MEDICATION_ALERTS_SUCCESS](state, { data }) {
    return {
      ...state,
      patientMedicationAlerts: data,
      patientMedicationAlertsEditMode: false,
    };
  },
  [ActionTypes.PATIENT_MEDICAL_ALERTS_SAVE_SUCCESS](state, { data }) {
    const newAlerts = {
      ...state.patientMedicalAlerts,
      patientMedicalAlerts: data.patientMedicalAlerts,
    };

    return {
      ...state,
      patientMedicalAlerts: newAlerts,
      patientMedicalAlertsEditMode: false,
      signature: "",
    };
  },
  [ActionTypes.PATIENT_MEDICATION_ADD_ALERT](state) {
    const newAlerts = {
      ...state.patientMedicationAlerts,
    };

    if (!newAlerts.medicationAlerts.alerts) newAlerts.medicationAlerts.alerts = [];

    newAlerts.medicationAlerts.alerts.push({});

    return {
      ...state,
      patientMedicationAlerts: newAlerts,
    };
  },
  [ActionTypes.PATIENT_MEDICATION_DELETE_ALERT](state, { index }) {
    const newAlerts = {
      ...state.patientMedicationAlerts,
    };

    newAlerts.medicationAlerts.alerts.splice(index, 1);

    return {
      ...state,
      patientMedicationAlerts: newAlerts,
    };
  },
  [ActionTypes.PATIENT_PCP_RELATIONSHIP_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      pcpRelationshipType: data,
    };
  },
  [ActionTypes.PATIENT_PCP_ROLE_TYPE_SUCCESS](state, { data }) {
    return {
      ...state,
      pcpRoleTypes: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_NATIONALITY_SUCCESS](state, { data }) {
    return {
      ...state,
      nationality: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_PROFESSION_SUCCESS](state, { data }) {
    return {
      ...state,
      profession: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_GENDER_SUCCESS](state, { data }) {
    return {
      ...state,
      gender: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_TITLES_SUCCESS](state, { data }) {
    return {
      ...state,
      titles: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_OCCUPATION_SUCCESS](state, { data }) {
    return {
      ...state,
      occupationList: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_LANGUAGES_SUCCESS](state, { data }) {
    return {
      ...state,
      languages: [{ id: -1, name: "Default patient language", code: null }, ...data],
    };
  },
  [ActionTypes.PATIENT_DETAILS_SET_EDIT](state) {
    return {
      ...state,
      patientDetailsEditMode: true,
    };
  },
  [ActionTypes.PATIENT_MEDICAL_ALERTS_SET_EDIT](state) {
    return {
      ...state,
      patientMedicalAlertsEditMode: true,
    };
  },
  [ActionTypes.PATIENT_MEDICAL_ALERTS_SET_VIEW](state) {
    return {
      ...state,
      patientMedicalAlertsEditMode: false,
    };
  },
  [ActionTypes.PATIENT_MEDICATION_ALERTS_SET_EDIT](state) {
    return {
      ...state,
      patientMedicationAlertsEditMode: true,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_MARITAL_STATUS_SUCCESS](state, { data }) {
    return {
      ...state,
      maritalStatus: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_COUNTRY_SUCCESS](state, { data }) {
    return {
      ...state,
      country: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_REFERRAL_TYPES_SUCCESS](state, { data }) {
    return {
      ...state,
      referralTypes: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_STATE_SUCCESS](state, { data }) {
    return {
      ...state,
      state: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_CITY_SUCCESS](state, { data }) {
    return {
      ...state,
      city: data,
    };
  },
  [ActionTypes.PATIENT_REGISTER_OPEN_HOUSEHOLD](state) {
    return {
      ...state,
      houseHoldModalVisible: true,
      activeHousehold: 0,
      searchHousehold: "",
    };
  },
  [ActionTypes.PATIENT_REGISTER_CLOSE_HOUSEHOLD](state) {
    return {
      ...state,
      houseHoldModalVisible: false,
    };
  },
  [ActionTypes.PATIENT_REGISTER_OPEN_CLOSE_CARD_READER_INFO](state) {
    return {
      ...state,
      cardReaderInfoModalVisible: !state.cardReaderInfoModalVisible,
    };
  },
  [ActionTypes.PATIENT_REGISTER_REMOVE_HOUSEHOLD](state) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          patientHouseHold: {},
        },
      },
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_PATIENT_LIST_START](state) {
    return {
      ...state,
      fetchingListHousehold: true,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_PATIENT_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        listHousehold: state.listHousehold.concat(data.list),
        listHouseholdTotalCount: data.totalCount,
        fetchingListHousehold: false,
      };
    }

    if (request.name !== state.searchHousehold) return state;
    return {
      ...state,
      listHousehold: data.list || [],
      listHouseholdTotalCount: data.totalCount,
      fetchingListHousehold: false,
    };
  },
  [ActionTypes.PATIENT_REGISTER_GET_PATIENT_LIST_ERROR](state) {
    return {
      ...state,
      fetchingListHousehold: false,
    };
  },
  [ActionTypes.PATIENT_REGISTER_CHOOSE_HOUSEHOLD](state, { id }) {
    return {
      ...state,
      activeHousehold: id,
    };
  },
  [ActionTypes.PATIENT_REGISTER_SELECT_HOUSEHOLD](state, { household }) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          patientHouseHold: household,
        },
      },
    };
  },
  [ActionTypes.PATIENT_REGISTER_SEARCH_HOUSEHOLD](state, { value }) {
    return {
      ...state,
      searchHousehold: value,
    };
  },
  [ActionTypes.PATIENT_REGISTER_ADD_ANOTHER](state) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          emergencyContacts: (state.personalDetails.patient.emergencyContacts || [{}]).concat({}),
        },
      },
    };
  },
  [ActionTypes.PATIENT_REGISTER_DELETE_ANOTHER](state) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          emergencyContacts: state.personalDetails.patient.emergencyContacts.slice(0, 1),
        },
      },
    };
  },
  [ActionTypes.PATIENT_REGISTER_OPEN_SIGNATURE](state) {
    return {
      ...state,
      signatureModalVisible: true,
    };
  },
  [ActionTypes.PATIENT_REGISTER_CLOSE_SIGNATURE](state) {
    return {
      ...state,
      signatureModalVisible: false,
    };
  },
  [ActionTypes.PATIENT_REGISTER_ADD_SIGNATURE](state, { base64 }) {
    return {
      ...state,
      signature: base64,
    };
  },
  [ActionTypes.PATIENT_REGISTER_UPLOAD_PHOTO_SUCCESS](state, { data }) {
    return {
      ...state,
      personalDetails: {
        ...state.personalDetails,
        patient: {
          ...state.personalDetails.patient,
          photo: data,
        },
      },
    };
  },
  [ActionTypes.PATIENT_GET_FORM_EDIT_START](state) {
    return {
      ...state,
      docFieldsModalVisible: true,
      docFieldsEditMode: false,
      dentist: {},
      guardian: {},
      hygienist: {},
      nurse: {},
      signature: "",
      textArea1: "",
      textArea2: "",
      textArea3: "",
      textArea4: "",
      textArea5: "",
      textArea6: "",
      textArea7: "",
      textArea8: "",
      textArea9: "",
      textArea10: "",
      textArea11: "",
      textArea12: "",
      textArea13: "",
      textArea14: "",
      textArea15: "",
      textArea16: "",
      textArea17: "",
      textArea18: "",
      textArea19: "",
      textArea20: "",
      image1: "",
    };
  },
  [ActionTypes.DOC_FIELDS_SET_SEARCH_VALUE](state, { value }) {
    return {
      ...state,
      memberListSearch: value,
    };
  },
  [ActionTypes.PATIENT_FORMS_OPEN_DOC_FIELDS_MODAL](state) {
    return {
      ...state,
      docFieldsModalVisible: true,
    };
  },
  [ActionTypes.PATIENT_FORMS_CLOSE_DOC_FIELDS_MODAL](state) {
    return {
      ...state,
      docFieldsModalVisible: false,
    };
  },
  [ActionTypes.DOC_FIELDS_OPEN_EDIT_MODE](state, { editType }) {
    return {
      ...state,
      docFieldsEditMode: true,
      docFieldsEditType: editType,
      docFieldsSelectedMember: 0,
      memberListSearch: "",
    };
  },
  [ActionTypes.DOC_FIELDS_GET_SIGNATURE_SUCCESS](state, { data }) {
    const owner = state[state.docFieldsEditType];

    return {
      ...state,
      [state.docFieldsEditType]: {
        ...owner,
        code: data[0].signature,
      },
    };
  },
  [ActionTypes.DOC_FIELDS_CLOSE_EDIT_MODE](state, { member }) {
    const resultState = {
      ...state,
      docFieldsEditMode: false,
    };

    if (member) {
      resultState[state.docFieldsEditType] = member;
    }

    return resultState;
  },
  [ActionTypes.DOC_FIELDS_SELECT_MEMBER](state, { id }) {
    return {
      ...state,
      docFieldsSelectedMember: id,
    };
  },
  [ActionTypes.DOC_FIELDS_GET_MEMBERS_SUCCESS](state, { data }) {
    const obj = {};

    _.map(data, (item) => {
      obj[item.id] = item;
    });

    return {
      ...state,
      docFieldsMembers: obj,
    };
  },
  [ActionTypes.DOC_FIELDS_CHANGE_TEXT_AREA](state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  },
  [ActionTypes.PATIENT_FORMS_OPEN_ADD_MODAL](state) {
    return {
      ...state,
      addConsentFormModalVisible: true,
    };
  },
  [ActionTypes.PATIENT_FORMS_CLOSE_ADD_MODAL](state) {
    return {
      ...state,
      addConsentFormModalVisible: false,
    };
  },
  [ActionTypes.ADD_CONSENT_FORM_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValueAddConsentForm: value,
    };
  },
  [ActionTypes.CONSENT_FORM_GET_CATEGORY_LIST_START](state) {
    return {
      ...state,
      fetchingConsentFormCategoryList: true,
    };
  },
  [ActionTypes.CONSENT_FORM_GET_CATEGORY_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      fetchingConsentFormCategoryList: false,
      consentFormCategoryList: data || [],
    };
  },
  [ActionTypes.CONSENT_FORM_GET_CATEGORY_LIST_ERROR](state) {
    return {
      ...state,
      fetchingConsentFormCategoryList: false,
    };
  },
  [ActionTypes.ADD_CONSENT_FORM_GET_LIST_START](state) {
    return {
      ...state,
      fetchingAddConsentForm: true,
    };
  },
  [ActionTypes.ADD_CONSENT_FORM_GET_LIST_SUCCESS](state, { data, request }) {
    if (request.start) {
      return {
        ...state,
        addConsentFormList: state.addConsentFormList.concat(data.list),
        addConsentFormListTotalCount: data.totalCount,
        fetchingAddConsentForm: false,
      };
    }

    if (request.filterKey !== state.searchValueAddConsentForm) return state;

    return {
      ...state,
      addConsentFormList: data.list || [],
      addConsentFormListTotalCount: data.totalCount,
      fetchingAddConsentForm: false,
    };
  },
  [ActionTypes.PATIENT_GET_FORM_EDIT_ITEM_SUCCESS](state, { data }) {
    const newState = {
      ...state,
    };

    if (data.textArea1) {
      newState.textArea1 = data.textArea1.code;
    }

    if (data.textArea2) {
      newState.textArea2 = data.textArea2.code;
    }

    if (data.textArea3) {
      newState.textArea3 = data.textArea3.code;
    }

    if (data.textArea4) {
      newState.textArea4 = data.textArea4.code;
    }

    if (data.textArea5) {
      newState.textArea5 = data.textArea5.code;
    }

    if (data.textArea6) {
      newState.textArea6 = data.textArea6.code;
    }

    if (data.textArea7) {
      newState.textArea7 = data.textArea7.code;
    }

    if (data.textArea8) {
      newState.textArea8 = data.textArea8.code;
    }

    if (data.textArea9) {
      newState.textArea9 = data.textArea9.code;
    }

    if (data.textArea10) {
      newState.textArea10 = data.textArea10.code;
    }

    if (data.textArea11) {
      newState.textArea11 = data.textArea11.code;
    }

    if (data.textArea12) {
      newState.textArea12 = data.textArea12.code;
    }

    if (data.textArea13) {
      newState.textArea13 = data.textArea13.code;
    }

    if (data.textArea14) {
      newState.textArea14 = data.textArea14.code;
    }

    if (data.textArea15) {
      newState.textArea15 = data.textArea15.code;
    }

    if (data.textArea16) {
      newState.textArea16 = data.textArea16.code;
    }

    if (data.textArea17) {
      newState.textArea17 = data.textArea17.code;
    }

    if (data.textArea18) {
      newState.textArea18 = data.textArea18.code;
    }

    if (data.textArea19) {
      newState.textArea19 = data.textArea19.code;
    }

    if (data.textArea20) {
      newState.textArea20 = data.textArea20.code;
    }

    if (data.dentist) {
      newState.dentist = data.dentist;
    }

    if (data.guardian) {
      newState.guardian = data.guardian;
    }

    if (data.hygienist) {
      newState.hygienist = data.hygienist;
    }

    if (data.nurse) {
      newState.nurse = data.nurse;
    }

    if (data.patientSignature) {
      newState.signature = `data:image/png;base64,${data.patientSignature}`;
    }

    if (data.image1) {
      newState.image1 = data.image1;
    }

    newState.fullForm = data;

    return newState;
  },
  [ActionTypes.PATIENT_REGISTER_GET_TEMP_PATIENT_SUCCESS](state, { data }) {
    return {
      ...state,
      patientDetails: data.patientDetails || {},
    };
  },
  [ActionTypes.GET_APPOINTMENT_UPCOMING_TOTAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentUpcomingTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_APPOINTMENT_MISSED_TOTAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentMissedTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_APPOINTMENT_COMPLETE_TOTAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentCompleteTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_APPOINTMENT_WITHOUT_LATE_CANCELLED_TOTAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentWithoutLateCancelledTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_APPOINTMENT_ALL_TOTAL_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      appointmentAllTotalCount: data.totalCount,
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const patientRegisterSelector = ({ patientRegister }) => patientRegister;

export default createReducer(defaultState, reducers);
