import React, { Component } from "react";
import { FieldArray, Form, Formik } from "formik";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import _ from "lodash";
import Utils from "../../helpers/Utils";
import {
  getDentalPoint,
  initAppoinmemt,
  saveReferralAppointment,
} from "../../actions/appointmentActions";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import {
  getSkipNextVisit,
  getWhenValuesApi,
  setReferralModalVisible,
  getReferralDoctors,
} from "../../actions/referralActions";
import { parseQuery } from "../../utils/UrlUtils";
import { SelectField } from "../form/SelectField";
import { Modal } from "../ui/Modal";
import { selectTreatmentToCompleteModalShow, setPostPlan } from "../../actions/chartActions";
import { DatePicker } from "../ui/DatePicker";
import { getCompanyStartOfDay } from "../../helpers/DateUtils";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import { ClinicSelectField } from "../form/ClinicSelectField";

const enhancer = connect(
  ({
    appointment,
    chart,
    referral: { initialValues, referralModalVisible, visitType, canSkip, referralDoctors },
    auth: { doctors },
    session: { clinic, member, permissions },
  }) => ({
    appointment,
    doctors,
    clinic,
    initialValues,
    referralModalVisible,
    visitType,
    member,
    chart,
    permissions,
    canSkip,
    referralDoctors,
  }),
);
class ReferralNextVisitModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateMode: { 0: null },
      lengthOptions: [],
      dateOptions: [],
      handleClinicId: this.props.clinic.id,
    };
  }

  componentDidMount() {
    const { location, dispatch, clinic } = this.props;
    const query = parseQuery(location.search);
    const appointmentId = _.get(query, "appointmentId");
    const dateOptions = [];
    dispatch(getWhenValuesApi()).then((response) => {
      response.forEach((item) => {
        if (item.code === "CUSTOM") {
          dateOptions.push({ value: "custom", label: "Custom" });
        } else if (item.name === "Today") {
          dateOptions.push({ value: moment(), label: "Today" });
        } else {
          dateOptions.push({
            value: moment().add(Number(item.value1), item.value2),
            label: item.name,
          });
        }
      });
      this.setState({ dateOptions });
    });

    dispatch(getReferralDoctors({ clinicId: clinic.id }));
    dispatch(initAppoinmemt(appointmentId));
    dispatch(getSkipNextVisit({ id: appointmentId }));
    this.generateLength();
  }

  setDentalPoint = (dentistId, setFieldValue, field) => {
    const { dispatch } = this.props;
    dispatch(getDentalPoint({ dentistId })).then((response = []) => {
      setFieldValue(field, response[0]);
    });
  };

  checkFields = (values) => {
    const { appointment, doctors, location, chart, referralDoctors } = this.props;
    const query = parseQuery(location.search);
    let result = [];
    values.forEach((item, index) => {
      let form = { ...item };
      form.sendSms = true;
      form = {
        ...form,
        patient: { patientKey: query.patientKey },
        createdAppointmentId: _.get(query, "appointmentId"),
      };
      form.clinicId = this.state.handleClinicId;
      form.dentist = _.get(item, "referredTo.value");
      form.referralType = { code: _.get(item, "referralType.value") };
      form.duration = _.get(item, "duration.value");
      form.date = _.get(item, "date.value")
        ? getCompanyStartOfDay(_.get(item, "date.value").valueOf())
        : getCompanyStartOfDay(item.date.valueOf());
      if (!item.dentalPoint) {
        // if doctor is not changed
        form.dentalPoint =
          _.get(appointment, "appointment.dentalPoint") || _.get(chart, "appointment.dentalPoint");
      }
      if (!_.get(item, "referredFrom.value")) {
        // if referredFrom is not changed
        form.referredFrom = _.get(appointment, "appointment.dentist");
      } else {
        form.referredFrom = doctors.find((x) => x.id === _.get(item, "referredFrom.value"));
      }
      if (!_.get(item, "referredTo.value")) {
        // if referredTo is not changed
        form.referredTo = _.get(appointment, appointment.dentist);
      } else {
        form.referredTo = referralDoctors.find((x) => x.id === _.get(item, "referredTo.value"));
      }
      if (!item.dentist && !_.get(item, "referredTo.value")) {
        // if dentist is not changed
        form.dentist = _.get(appointment, "appointment.dentist");
      }
      if (_.get(item, "referredTo.value")) {
        form.dentist = referralDoctors.find((x) => x.id === _.get(item, "referredTo.value"));
      }
      form.status = appointment.statusList.find(
        (x) => x.code === STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
      );
      form.category = appointment.categoryList.find((x) => x.id === _.get(item, "category.value"));
      for (const key in form) {
        if (key !== "note" && !form[key]) {
          this.props.dispatch(
            openSimpleModal({
              body: `Form ${index + 1}, ${key} is required!`,
              footer: (
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.dispatch(closeSimpleModal())}
                >
                  ok
                </button>
              ),
            }),
          );
          result = false;
        }
      }
      result.push(form);
    });
    return result;
  };

  handleSubmit = ({ form }) => {
    const data = this.checkFields(form);
    const { dispatch } = this.props;
    if (data) {
      dispatch(saveReferralAppointment(data)).then((result) => {
        if (result && result.length) {
          this.openChartVisit();
        }
      });
    }
  };

  generateLength = () => {
    const { clinic } = this.props;
    let i = 1;
    const lengthOptions = [];
    const timeLength = clinic && parseInt(clinic.minTimeForVisit);
    while (timeLength * i <= 720) {
      lengthOptions.push({
        value: timeLength * i * 60 * 1000,
        label: i * timeLength,
      });
      i++;
    }
    this.setState({
      lengthOptions,
    });
  };

  openChartVisit = () => {
    const { visitType, dispatch } = this.props;
    dispatch(setReferralModalVisible(false));
    if (visitType === "finish") {
      dispatch(setPostPlan(false));
      dispatch(selectTreatmentToCompleteModalShow("finish"));
    }
    if (visitType === "post") {
      dispatch(setPostPlan(false));
      dispatch(selectTreatmentToCompleteModalShow("post"));
    }
  };

  render() {
    const {
      doctors,
      appointment: { categoryList, appointment },
      initialValues,
      dispatch,
      referralModalVisible,
      canSkip,
      clinic,
      referralDoctors,

      // permissions,
    } = this.props;
    // const canSkip = permissions.indexOf("SKIP_NEXT_VISIT_REFERRAL_SCREEN") !== -1;

    const { lengthOptions, dateMode, dateOptions } = this.state;
    const referralTypeOptions = [
      { value: "REFERRAL", label: "Referral" },
      { value: "NEXT_VISIT", label: "Next visit" },
    ];
    const referredToDoctors = (referralDoctors || []).map((item) => {
      return { value: item.id, label: Utils.getFullName(item) };
    });
    const doctorsOptions = (doctors || [])
      .filter((i) => i.doctor)
      .map((item) => {
        return { value: item.id, label: Utils.getFullName(item) };
      });
    const categoryOptions = (categoryList || [])
      .filter((i) => i.id)
      .map((item) => {
        return { value: item.id, label: item.name };
      });
    const dentist = doctors.find((i) => i.id === _.get(appointment, "dentist.id"));
    let defaultDoctor;
    if (dentist && !defaultDoctor) {
      defaultDoctor = {
        value: dentist.id,
        label: Utils.getFullName(dentist),
      };
    }
    const initialValuesArray = {
      clinic: { label: clinic.name, value: clinic.id },
      form: [
        {
          ...initialValues,
          dentist: defaultDoctor,
          referredTo: defaultDoctor,
          referredFrom: defaultDoctor,
        },
      ],
    };

    return (
      <Modal
        size="lg"
        show={referralModalVisible}
        onHide={() => dispatch(setReferralModalVisible(false))}
        className="select-treatment-to-complete-modal"
        keyboard={false}
        title={
          <div className="default-page-title clearfix text-center">
            <button
              className="btn btn-danger pull-right"
              onClick={() => {
                if (!canSkip) {
                  dispatch(
                    openSimpleModal({
                      body: "You don't have permission to skip, please enter next visit/referral",
                    }),
                  );
                  return;
                }
                this.openChartVisit();
              }}
            >
              Skip
            </button>
          </div>
        }
      >
        <Formik initialValues={initialValuesArray} onSubmit={(values) => this.handleSubmit(values)}>
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <div className="referral-next-visit-wrap">
                <div className="row d-flex align-items-center ">
                  <div className="referral-next-visit-form-field col-sm-2 ml-3 d-flex align-items-center">
                    <div>Clinic:</div>
                    <div style={{ width: "100%", marginLeft: 10 }}>
                      <ClinicSelectField
                        name="clinic"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Select..."
                        onChange={(v) => {
                          this.setState({ handleClinicId: v.value });
                          dispatch(getReferralDoctors({ clinicId: v.value }));
                        }}
                      />
                    </div>
                  </div>

                  <h3 className="" style={{ marginLeft: "30%" }}>
                    REFERRAL | NEXT VISIT
                  </h3>
                </div>
                <div className="referral-next-visit-body p-3">
                  <Form>
                    <FieldArray name="form">
                      {({ remove, push }) => (
                        <div>
                          {values.form.length > 0 &&
                            values.form.map((item, index) => {
                              const member = {
                                value: this.props.member.id,
                                label: this.props.member.name,
                              };
                              if (!item.dentist) {
                                item.dentist = member;
                              }
                              if (!item.referredFrom) {
                                item.referredFrom = member;
                              }
                              if (!item.referredTo) {
                                item.referredTo = member;
                              }

                              if (
                                !values.form[index].dentist &&
                                values.form[index].referralType.value === "NEXT_VISIT"
                              )
                                setFieldValue(`form.${index}.dentist`, defaultDoctor);
                              if (
                                !values.form[index].referredFrom &&
                                values.form[index].referralType.value === "REFERRAL"
                              )
                                setFieldValue(`form.${index}.referredFrom`, defaultDoctor);
                              return (
                                <div key={index}>
                                  <div className="referral-next-visit-form">
                                    <div className="row">
                                      <div className="referral-next-visit-form-field col-sm-2">
                                        <div className="mb-1">Referral type:</div>
                                        <div className="form-group d-flex align-items-center justify-content-between mb-0">
                                          {referralTypeOptions.map((item) => (
                                            <InputLabel key={item.value}>
                                              <Radio
                                                value={item.value}
                                                checked={
                                                  values.form[index].referralType.value ===
                                                  item.value
                                                }
                                                onChange={({ target: { value } }) =>
                                                  setFieldValue(`form.${index}.referralType`, {
                                                    value,
                                                  })
                                                }
                                                name="radio-buttons"
                                                inputProps={{ "aria-label": item.label }}
                                                sx={{
                                                  color: "#bec844",
                                                  "&.Mui-checked": {
                                                    color: "#bec844",
                                                  },
                                                }}
                                              />
                                              {item.label}
                                            </InputLabel>
                                          ))}
                                        </div>
                                      </div>
                                      {values.form[index].referralType &&
                                      values.form[index].referralType.value === "NEXT_VISIT" ? (
                                        <div className="referral-next-visit-form-field col-sm-2">
                                          <div className="mb-1">Referred from:</div>
                                          <div
                                            className="form-group"
                                            style={{
                                              margin: 0,
                                              lineHeight: "30px",
                                              textAlign: "initial",
                                            }}
                                          >
                                            <SelectField
                                              name={`form.${index}.dentist`}
                                              isDisabled={
                                                values.form[index].referralType.value ===
                                                "NEXT_VISIT"
                                              }
                                              isMulti={false}
                                              isSearchable={true}
                                              value={values.form[index].dentist}
                                              options={doctorsOptions}
                                              onChange={(x) => {
                                                setFieldValue(`form.${index}.dentist`, x);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="referral-next-visit-form-field col-sm-2">
                                          <div className="mb-1">Referred from:</div>
                                          <div
                                            className="form-group"
                                            style={{
                                              margin: 0,
                                              lineHeight: "30px",
                                              textAlign: "initial",
                                            }}
                                          >
                                            <SelectField
                                              name={`form.${index}.referredFrom`}
                                              isMulti={false}
                                              isSearchable={true}
                                              isDisabled={true}
                                              value={values.form[index].referredFrom}
                                              options={doctorsOptions}
                                              onChange={(x) => {
                                                setFieldValue(`form.${index}.referredFrom`, x);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div className="referral-next-visit-form-field col-sm-2">
                                        <div className="mb-1">Referred to:</div>
                                        <div
                                          className="form-group"
                                          style={{
                                            margin: 0,
                                            lineHeight: "30px",
                                            textAlign: "initial",
                                          }}
                                        >
                                          <SelectField
                                            name={`form.${index}.referredTo`}
                                            isMulti={false}
                                            isSearchable={true}
                                            isDisabled={
                                              values.form[index].referralType.value === "NEXT_VISIT"
                                            }
                                            value={values.form[index].referredTo}
                                            options={referredToDoctors}
                                            onChange={(x) => {
                                              setFieldValue(`form.${index}.referredTo`, x);
                                              this.setDentalPoint(
                                                x.value,
                                                setFieldValue,
                                                `form.${index}.dentalPoint`,
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="referral-next-visit-form-field col-sm-2">
                                        <div className="mb-1">Select visit type (Reason):</div>
                                        <div
                                          className="form-group"
                                          style={{
                                            margin: 0,
                                            lineHeight: "30px",
                                            textAlign: "initial",
                                          }}
                                        >
                                          <SelectField
                                            name={`form.${index}.category`}
                                            isMulti={false}
                                            isSearchable={true}
                                            value={values.form[index].category}
                                            options={categoryOptions}
                                            onChange={(x) =>
                                              setFieldValue(`form.${index}.category`, x)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="referral-next-visit-form-field col-sm-2">
                                        <div className="mb-1">When:</div>
                                        <div
                                          className="form-group"
                                          style={{
                                            margin: 0,
                                            lineHeight: "30px",
                                            textAlign: "initial",
                                          }}
                                        >
                                          {dateMode[index] && dateMode[index].value === "custom" && (
                                            <button
                                              type="button"
                                              className="btn btn-danger picker-delete-btn"
                                              onClick={() => {
                                                this.setState((prev) => ({
                                                  dateMode: {
                                                    ...prev.dateMode,
                                                    [index]: null,
                                                  },
                                                }));
                                                setFieldValue(`form.${index}.date`, null);
                                              }}
                                            >
                                              x
                                            </button>
                                          )}
                                          {dateMode[index] && dateMode[index].value === "custom" ? (
                                            <DatePicker
                                              name={`form.${index}.date`}
                                              autoFocus={true}
                                              className="form-control"
                                              style={{ width: "100%" }}
                                              placeholder="Select date"
                                              selected={
                                                values.form[index].date.value === "custom"
                                                  ? null
                                                  : moment(values.form[index].date)
                                              }
                                              onChange={(event) =>
                                                setFieldValue(`form.${index}.date`, event)
                                              }
                                            />
                                          ) : (
                                            <SelectField
                                              isSearchable={true}
                                              name={`form.${index}.date`}
                                              value={values.form[index].date}
                                              options={dateOptions}
                                              onChange={(x) => {
                                                if (x.value === "custom")
                                                  setFieldValue(
                                                    `form.${index}.date`,
                                                    moment(Date.now()),
                                                  );
                                                else setFieldValue(`form.${index}.date`, x);
                                                this.setState((prev) => ({
                                                  dateMode: {
                                                    ...prev.dateMode,
                                                    [index]: x,
                                                  },
                                                }));
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="referral-next-visit-form-field col-sm-2">
                                        <div className="mb-1">How long:</div>
                                        <div
                                          className="form-group"
                                          style={{
                                            margin: 0,
                                            lineHeight: "30px",
                                            textAlign: "initial",
                                          }}
                                        >
                                          <SelectField
                                            name={`form.${index}.duration`}
                                            multi={false}
                                            clearable={true}
                                            searchable={true}
                                            value={values.form[index].duration}
                                            options={lengthOptions}
                                            onChange={(x) =>
                                              setFieldValue(`form.${index}.duration`, x)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row d-flex align-items-end">
                                      <div className="referral-next-visit-form-field col-sm-4">
                                        <div>Appointment note:</div>
                                        <textarea
                                          value={values.form[index].note}
                                          onChange={({ target }) =>
                                            setFieldValue(`form.${index}.note`, target.value)
                                          }
                                          name="note"
                                          className="form-control input-lg"
                                        />
                                      </div>
                                      <div className="referral-next-visit-form-field col-sm-4">
                                        <div>Doctor note:</div>
                                        <textarea
                                          value={values.form[index].doctorNote}
                                          onChange={({ target }) =>
                                            setFieldValue(`form.${index}.doctorNote`, target.value)
                                          }
                                          name="note"
                                          className="form-control input-lg"
                                        />
                                      </div>
                                      <div className={"referral-next-visit-form-field col-sm-2"}>
                                        {values.form.length !== 1 && (
                                          <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="btn btn-danger btn-block"
                                          >
                                            delete
                                          </button>
                                        )}
                                      </div>
                                      {values.form.length - 1 === index && (
                                        <>
                                          <div
                                            className={`referral-next-visit-form-field ${
                                              values.form.length - 1 === index
                                                ? "col-sm-1"
                                                : "col-sm-2"
                                            }`}
                                          >
                                            <button
                                              type="button"
                                              onClick={() => {
                                                push({
                                                  ...initialValues,
                                                  dentist: defaultDoctor,
                                                  referredTo: defaultDoctor,
                                                  referredFrom: defaultDoctor,
                                                });
                                                this.setDentalPoint(
                                                  defaultDoctor?.value,
                                                  setFieldValue,
                                                  `form.${index + 1}.dentalPoint`,
                                                );
                                              }}
                                              className="btn btn-primary btn-block"
                                            >
                                              add
                                            </button>
                                          </div>
                                          <div
                                            className={`referral-next-visit-form-field ${
                                              values.form.length - 1 === index
                                                ? "col-sm-1"
                                                : "col-sm-2"
                                            }`}
                                          >
                                            <button
                                              className="btn btn-primary btn-link btn-block"
                                              type="button"
                                              onClick={handleSubmit}
                                            >
                                              save
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {values.form.length > 1 && (
                                    <div
                                      style={{
                                        margin: "1rem",
                                        borderBottom: "1px solid #bbbbbb",
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </FieldArray>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(enhancer(ReferralNextVisitModal));
