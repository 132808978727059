import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "../../../ui/Modal";
import ReactSpinner from "react-spinjs-fix";
import Back from "../../../widgets/Back";
import DocsItem from "./DocsItem";
import FlexBox from "../../../ui/FlexBox";
import Show from "../../../widgets/Show";
import uploadImage from "../../../../assets/images/upload-icon.png";
import addFolderImage from "../../../../assets/images/add-folder-icon.png";
import { getFolderList } from "../../../../actions/folderActions";
import { connect } from "react-redux";
import cx from "classnames";
import { SidebarPosition } from "../../../sidebar/Sidebar";
import { makeStyles } from "@material-ui/styles";

/*
 * getFolderList: () => void;
 * createFolder: () => void;
 * */
const useStyles = makeStyles(() => ({
  dialog: {
    top: 0,
    bottom: 0,
    margin: 0,
    width: "650px",
    display: "flex",
    position: "absolute",
  },
  content: {
    flex: 1,
    display: "flex",
    borderRadius: 0,
  },
  rightDialog: {
    right: "-650px",
    transition: "right 170ms ease-in-out",

    "&.open": {
      right: 0,
    },
  },
  leftDialog: {
    left: "-650px",
    transition: "left 170ms ease-in-out",

    "&.open": {
      left: 0,
    },
  },
  body: { padding: "0 !important", margin: 0, flex: 1, display: "flex", flexDirection: "column" },
}));

const enhancer = connect(null, null);
const DocsModal = (props) => {
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const { patientKey, folder } = props;

    props.dispatch(
      getFolderList({
        patientKey,
        folderId: _.last(folder.folderStack) || null,
      }),
    );
  }, []);

  const loadList = (event) => {
    const { patientKey, folder } = props;
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight;

    if (scrollBottom) {
      props.loadFilesList({
        patientKey,
        folderId: _.last(folder.folderStack) || null,
      });
    }
  };

  const onUpload = () => {
    // edit
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;
    const newFiles = [];
    [...files].forEach((originalFile) => {
      const fileExtension = originalFile.name.slice(
        (Math.max(0, originalFile.name.lastIndexOf(".")) || Infinity) + 1,
      );
      const newFile = new File(
        [originalFile],
        today === fileName ? originalFile.name : fileName + "." + fileExtension,
        {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        },
      );
      newFiles.push(newFile);
    });
    props.onUploadFiles(newFiles);
    setFiles([]);
    setFileName("");
    setShowEditNameModal(false);
  };

  const renameFiles = (files) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;
    setShowEditNameModal(true);
    setFiles(files);
    setFileName(today);
  };

  const {
    onRequestClose,
    onUploadImage,
    onCreateFolderClick,
    folder,
    open,
    position = SidebarPosition.Right,
  } = props;

  if (!open) {
    return null;
  }

  const currentFolder = _.last(folder.folderStack) || 0;
  const folderList = folder.folderList[currentFolder] || [];
  const filesList = folder.filesList[currentFolder] || [];

  let list = [];

  if (folder.isMoving) {
    list = folderList.filter((x) => !_.includes(folder.selectedFolders, x.id));
  } else {
    list = [...folderList, ...filesList];
  }

  return (
    <>
      <Show if={showEditNameModal}>
        <Modal
          title="Edit file name"
          show={true}
          actions={
            <div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setShowEditNameModal(false);
                  setFileName("");
                }}
              >
                cancel
              </button>
              <button type="button" className="btn btn-default" onClick={() => onUpload()}>
                ok
              </button>
            </div>
          }
        >
          <input
            className="form-control input-sm mb-3"
            type="text"
            value={fileName}
            onChange={({ target: { value } }) => setFileName(value)}
          />
        </Modal>
      </Show>
      <Modal
        show={true}
        animation={false}
        keyboard={false}
        onHide={onRequestClose}
        dialogClassName={cx("chart-side-bar", classes.dialog, {
          [classes.leftDialog]: position === SidebarPosition.Left,
          [classes.rightDialog]: position === SidebarPosition.Right,
          open,
        })}
      >
        <div className="chart-clinical-note">
          <div
            className="sidebar-header chart-side-header"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              {currentFolder > 0 ? (
                <DocsItem
                  isBack={true}
                  item={{
                    type: "folder",
                  }}
                  openFolder={() =>
                    folder.isMoving
                      ? props.onSelectMoveFolder(
                          folder.folderStack.length >= 2
                            ? folder.folderStack[folder.folderStack.length - 2]
                            : null,
                          true,
                        )
                      : props.closeFolder()
                  }
                />
              ) : (
                <Back onClick={props.onRequestClose} />
              )}
            </div>

            <Show if={folder.fetchingFolders}>
              <ReactSpinner />
            </Show>

            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div
                title="Upload"
                onClick={onUploadImage}
                className="clinic-note patient-note"
                style={{
                  cursor: "pointer",
                  marginRight: "12px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <img alt="" src={uploadImage} width={32} height={32} />
                <input
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                    width: "32px",
                    height: "32px",
                    cursor: "pointer",
                    position: "absolute",
                  }}
                  type="file"
                  tabIndex={-1}
                  multiple={true}
                  name="uploadFile"
                  onChange={({ target: { files } }) => renameFiles(files)}
                />
              </div>
              <div
                title="Add Folder"
                onClick={onCreateFolderClick}
                className="clinic-note patient-note"
                style={{ cursor: "pointer", marginRight: "12px" }}
              >
                <img alt="" src={addFolderImage} width={32} height={32} />
              </div>
            </div>
          </div>
          <div className="chart-side-body" onScroll={loadList}>
            {list.map((x, idx) => (
              <DocsItem
                item={x}
                key={idx}
                openFile={() => props.openFile(x.id)}
                isMoving={folder.isMoving}
                openFolder={() =>
                  folder.isMoving ? props.onSelectMoveFolder(x.id) : props.openFolder(x.id)
                }
                onSelect={() => props.selectItem(x.type, x.id)}
                selected={_.includes(
                  x?.type === "folder" ? folder.selectedFolders : folder.selectedFiles,
                  x.id,
                )}
              />
            ))}
          </div>
          {Boolean(!_.isEmpty(folder.selectedFiles) || !_.isEmpty(folder.selectedFolders)) && (
            <FlexBox container={16}>
              <FlexBox flex={true} direction="row" gutter={8}>
                <FlexBox flex={true}>
                  <button
                    onClick={props.onDelete}
                    style={{ width: "100%" }}
                    className="btn btn-success btn-lg"
                  >
                    Delete
                  </button>
                </FlexBox>

                <FlexBox flex={true}>
                  <button
                    onClick={props.onMove}
                    style={{ width: "100%" }}
                    className="btn btn-success btn-lg"
                  >
                    Move
                  </button>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}
        </div>
      </Modal>
    </>
  );
};
export default enhancer(DocsModal);
