import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { FormikProvider, useFormik } from "formik";
import { SelectField } from "../../form/SelectField";
import {
  getClinicMembers,
  getLabOrderFormData,
  getLabOrderTypes,
  popStack,
  resetOrderItems,
  saveLabOrderItem,
  uploadLabOrderFiles,
  pushOrderStack,
} from "../../../actions/orderActions";
import Show from "../../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
// import SelectPickerAsync from "../../select-picker/SelectPickerAsync";
import { TextInput } from "../../ui/TextInput";
import uploadImage from "../../../assets/images/upload-icon.png";
import { Modal } from "../../ui/Modal";
import ToothDetailsType from "../../../constants/teeth/ToothDetailsType";
import { openSimpleModal } from "../../../actions/simpleModalActions";
import {
  selectTreatmentToCompleteSetCompleteApi,
  showSideBar,
} from "../../../actions/chartActions";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { SelectPicker } from "../../select-picker/SelectPicker";
import viewTypes from "../../../constants/chart-sidebar/viewTypes";
import { medicalFillInFormStackCodes } from "../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import medicalFillInFormItemTypes from "../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import pdfImage from "../../../assets/images/pdf_placeholder.png";
import MedicalFormOrderView from "../../chart/side-bar/medical-fill-in-form/MedicalFormOrderView";
import { Sidebar } from "../../sidebar/Sidebar";

const enhancer = connect(
  ({
    chartSideOrder,
    chart: {
      patient: { patientKey },
      appointment: { id },
    },
    auth: { clinics },
    session: { clinic, member },
    chartSideSeries: { labAndRadiologyTreatmentItem },
  }) => ({
    chartSideOrder,
    clinics,
    patientKey,
    clinic,
    member,
    appointmentId: id,
    labAndRadiologyTreatmentItem,
  }),
);

const LabOrderCreate = ({
  editMode,
  chartSideOrder,
  clinics,
  patientKey,
  clinic,
  member,
  appointmentId,
  labAndRadiologyTreatmentItem,
}) => {
  const { labOrderItem, clinicMembers, labOrderFormData, labOrderTypes, fetching } = chartSideOrder;
  const { externalLabList, statusList, urgencyList, orderTypesList, toothList } = labOrderFormData;
  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState({ visible: false });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sidebarVisible, sidebarVisibleHandler] = useState({ visible: false });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      clinic: "",
      urgency: "",
      dentist: "",
      dentistStaff: "",
      dentistNotes: "",
      dentistStaffNotes: "",
      dentistJustification: "",
      externalLab: "",
      orderType: "",
      status: "",
      patientLab: { patientKey },
      treatmentItems: [],
    },
    onSubmit: (values) => {
      let requiredField = "";
      if (values.treatmentItems.length) {
        values = {
          ...values,
          treatmentItems: values.treatmentItems.map((treatment) => {
            if (values.orderType?.label === "Dental" && !treatment.tooth?.value) {
              requiredField = "Tooth is required";
            }
            if (!treatment.id) {
              requiredField = "Treatment is required";
            }
            return {
              ...treatment,
              treatmentItem: { id: treatment.id },
              treatment: { id: treatment.id },
              tooth: {
                code: ToothDetailsType[treatment.tooth?.value]?.id,
                id: ToothDetailsType[treatment.tooth?.value]?.code,
              },
              type: { id: treatment.type?.value },
              toothPosition: { code: ToothDetailsType[treatment.toothPosition]?.position },
            };
          }),
        };
      }
      if (!values.orderType?.value) {
        requiredField = "Order type is required";
      }
      if (!values.urgency?.value) {
        requiredField = "Urgency is required";
      }
      if (!values.status?.value) {
        requiredField = "Status is required";
      }
      if (!values.externalLab?.value) {
        requiredField = "Conducting Lab is required";
      }
      if (!values.dentist?.value) {
        requiredField = "Ordering Doctor is required";
      }
      if (!values.dentistStaff?.value) {
        requiredField = "Conducting Doctor is required";
      }
      const transfer = {
        ...values,
        clinic: {
          id: values.clinic?.value,
        },
        urgency: {
          id: values.urgency?.value,
        },
        dentist: {
          id: values.dentist?.value,
        },
        dentistStaff: {
          id: values.dentistStaff?.value,
        },
        externalLab: {
          id: values.externalLab?.value,
        },
        orderType: {
          id: values.orderType?.value,
        },
        status: {
          id: values.status?.value,
        },
      };

      if (appointmentId) {
        transfer.appointment = {
          id: appointmentId,
        };
      }
      if (requiredField.length) {
        dispatch(openSimpleModal({ body: requiredField }));
        return;
      }
      dispatch(saveLabOrderItem(transfer));
    },
  });

  const { values } = form;

  useEffect(() => {
    //get form values or form data
    dispatch(getLabOrderFormData());
    dispatch(getLabOrderTypes());
    form.setValues({
      ...values,
      clinic: { value: clinic.id, label: clinic.name },
      dentist: { value: member.id, label: member.name },
      dentistStaff: { value: member.id, label: member.name },
    });
    return () => {
      form.resetForm();
      dispatch(resetOrderItems());
    };
  }, []);

  useEffect(() => {
    if (values.clinic?.value) {
      dispatch(getClinicMembers(values.clinic.value));
    }
  }, [values.clinic]);

  useEffect(() => {
    if (labOrderItem.id) {
      // debugger;
      const treatmentItems = (labOrderItem.treatmentItems || []).map((treatment) => ({
        ...treatment,
        treatmentItem: {
          value: treatment.treatmentItem.id,
          label: `${treatment.treatmentItem.code}, ${treatment.treatmentItem.description}`,
        },
        type: {
          value: treatment?.type?.id,
          label: treatment?.type?.name,
        },
        tooth: {
          value: treatment?.tooth?.code,
          label: treatment?.tooth?.name,
        },
      }));
      form.setValues({
        ...labOrderItem,
        treatmentItems,
        id: labOrderItem.id,
        clinic: { value: labOrderItem.clinic?.id },
        urgency: { value: labOrderItem.urgency?.id },
        dentist: { value: labOrderItem.dentist?.id },
        dentistStaff: { value: labOrderItem.dentistStaff?.id },
        dentistNotes: labOrderItem?.dentistNotes,
        dentistStaffNotes: labOrderItem?.dentistStaffNotes,
        dentistJustification: labOrderItem?.dentistJustification,
        externalLab: { value: labOrderItem.externalLab?.id },
        orderType: { value: labOrderItem.orderType?.id },
        status: { value: labOrderItem.status?.id },
      });
    }
  }, [labOrderItem.id]);

  const onComplete = () => {
    if (!appointmentId) return dispatch(openSimpleModal({ body: "Appointment is not defined!" }));
    const transfer = {
      currentAppointmentId: appointmentId,
      transactionKeys: labOrderItem.dentalTransactionKeys,
      orderType: labOrderItem.orderType,
    };
    dispatch(startLoader());
    dispatch(selectTreatmentToCompleteSetCompleteApi(transfer))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((e) => dispatch(stopLoader(e)));
  };

  const addTreatmentItem = () => {
    const row = {
      treatmentItem: {},
      area: {},
      areaDetails: [],
      type: {},
      tooth: {},
      shades: "",
      costPrice: null,
      sampleItems: [],
    };
    form.setValues({
      ...values,
      treatmentItems: [...values.treatmentItems, row],
    });
  };

  const removeTreatmentItem = (index) => {
    const treatmentItems = values.treatmentItems.filter((_, idx) => idx !== index);
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const addSampleItem = (treatmentIndex) => {
    const sampleItem = {
      barCode: Math.random().toString(36).slice(2),
      isCollected: false,
      isResultConducted: false,
      reports: [],
    };
    const treatmentItems = values.treatmentItems.map((tItem, tIndex) => {
      if (treatmentIndex === tIndex) {
        tItem.sampleItems = [...tItem.sampleItems, sampleItem];
      }
      return tItem;
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const removeSampleItem = (treatmentIndex, sampleIndex) => {
    const treatmentItems = values.treatmentItems.map((treatment) => {
      return {
        ...treatment,
        sampleItems: treatment.sampleItems.filter((_, idx) => idx !== sampleIndex),
      };
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const addReportItem = (checked, treatmentIndex, sampleIndex) => {
    const treatmentItems = values.treatmentItems.map((tItem, tIndex) => {
      if (treatmentIndex === tIndex) {
        tItem.sampleItems.forEach((sItem, sIndex) => {
          if (sampleIndex === sIndex) {
            sItem.isCollected = checked;
            sItem.isResultConducted = false;
            sItem.reports = [];
          }
        });
      }
      return tItem;
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const setConductedReports = (checked, treatmentIndex, sampleIndex) => {
    const treatmentItems = values.treatmentItems.map((tItem, tIndex) => {
      if (treatmentIndex === tIndex) {
        tItem.sampleItems.forEach((sItem, sIndex) => {
          if (sampleIndex === sIndex) sItem.isResultConducted = checked;
        });
      }
      return tItem;
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const generateSamples = () => {
    const $samples = [];
    values.treatmentItems.forEach((treatment, treatmentIndex) => {
      (treatment.sampleItems || []).forEach((sample, sampleIndex) => {
        $samples.push(
          <>
            <div className="row" key={sampleIndex}>
              <div className="col-sm-2">
                <label className="w-100 mb-3">
                  Treatment code:
                  <TextInput
                    disabled={true}
                    value={values.treatmentItems[treatmentIndex]?.treatmentItem?.label || ""}
                  />
                </label>
              </div>
              <div className="col-sm-3">
                <label className="w-100 mb-3">
                  <div>Collected:</div>
                  <input
                    disabled={editMode}
                    type="checkbox"
                    className="scaled-2 ml-1 mt-1"
                    onClick={({ target: { checked } }) =>
                      addReportItem(checked, treatmentIndex, sampleIndex)
                    }
                    checked={
                      values.treatmentItems[treatmentIndex]?.sampleItems[sampleIndex]?.isCollected
                    }
                  />
                </label>
              </div>
              <div className="col-sm-7">
                <label className="w-100 mb-3">
                  &nbsp;
                  <div className="d-flex justify-content-end">
                    <button
                      disabled={editMode}
                      type="button"
                      title="Remove sample"
                      className="btn btn-danger btn-rounded mr-2"
                      onClick={() => removeSampleItem(treatmentIndex, sampleIndex)}
                    >
                      -
                    </button>
                  </div>
                </label>
              </div>
            </div>
          </>,
        );
      });
    });
    return $samples;
  };

  const generateReports = () => {
    const $reports = [];
    values.treatmentItems.forEach((treatment, treatmentIndex) => {
      (treatment.sampleItems || []).forEach((sample, sampleIndex) => {
        sample.isCollected &&
          $reports.push(
            <>
              <div className="row" key={sampleIndex}>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    Treatment code:
                    <TextInput
                      disabled={true}
                      value={values.treatmentItems[treatmentIndex]?.treatmentItem?.label || ""}
                    />
                  </label>
                </div>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    <div>Conducted Reports:</div>
                    <input
                      disabled={editMode}
                      type="checkbox"
                      className="scaled-2 ml-1 mt-1"
                      onClick={({ target: { checked } }) => {
                        setConductedReports(checked, treatmentIndex, sampleIndex);
                      }}
                      checked={
                        values.treatmentItems[treatmentIndex]?.sampleItems[sampleIndex]
                          ?.isResultConducted
                      }
                    />
                  </label>
                </div>
                <div className="col-sm-8">
                  <div className="w-100 mb-3">
                    <div className="font-weight-bold">Reports:</div>
                    <div className="d-flex">
                      {(sample.reports || []).map((x, reportIndex) => {
                        const _src =
                          x?.type?.code === "LAB_ORDER_ATTACHMENT_PDF" ? pdfImage : x.url;
                        return (
                          <div key={reportIndex} style={{ position: "relative" }} className="mr-3">
                            <img
                              alt=""
                              src={_src}
                              width={48}
                              height={48}
                              style={{
                                cursor:
                                  x?.type?.code === "LAB_ORDER_ATTACHMENT_PDF"
                                    ? "pointer"
                                    : "zoom-in",
                              }}
                              onClick={() => {
                                if (x?.type?.code === "LAB_ORDER_ATTACHMENT_PDF") {
                                  sidebarVisibleHandler({ visible: true, url: x.url });
                                } else {
                                  setShowImage({ visible: true, url: x.url });
                                }
                              }}
                            />
                            <span
                              onClick={() =>
                                deleteReportAttachment(treatmentIndex, sampleIndex, reportIndex)
                              }
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                                color: "red",
                                top: 0,
                                width: "16px",
                                height: "16px",
                                lineHeight: "16px",
                                textAlign: "center",
                                background: "#b1bb37",
                              }}
                            >
                              x
                            </span>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          alt=""
                          src={uploadImage}
                          width={32}
                          height={32}
                          style={{ cursor: "pointer" }}
                        />
                        <input
                          type="file"
                          style={{
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            opacity: 0,
                            width: "32px",
                            height: "32px",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          onChange={({ target: { files } }) =>
                            onUpload(files[0], treatmentIndex, sampleIndex)
                          }
                          disabled={
                            editMode ||
                            !values.treatmentItems[treatmentIndex]?.sampleItems[sampleIndex]
                              ?.isResultConducted
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>,
          );
      });
    });
    return $reports;
  };

  const onUpload = (file, treatmentIndex, sampleIndex) => {
    dispatch(uploadLabOrderFiles(file)).then((data) => {
      const treatmentItems = values.treatmentItems.map((tItem, tIndex) => {
        if (treatmentIndex === tIndex) {
          tItem.sampleItems.forEach((sItem, sIndex) => {
            if (sampleIndex === sIndex && data.id) sItem.reports.push(data);
          });
        }
        return tItem;
      });
      form.setValues({
        ...values,
        treatmentItems,
      });
    });
  };

  const deleteReportAttachment = (treatmentIndex, sampleIndex, reportIndex) => {
    if (editMode) return;
    const treatmentItems = values.treatmentItems.map((tItem, tIndex) => {
      if (treatmentIndex === tIndex) {
        tItem.sampleItems.forEach((sItem, sIndex) => {
          if (sampleIndex === sIndex)
            sItem.reports = sItem.reports.filter((rItem, rIndex) => rIndex !== reportIndex);
        });
      }
      return tItem;
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  };

  const clinicOptions = (clinics || []).map((clinic) => ({
    value: clinic.id,
    label: clinic.name,
  }));
  const memberOptions = (clinicMembers || []).map((member) => ({
    value: member.id,
    label: member.name,
  }));
  const statusOptions = (statusList || []).map((status) => ({
    value: status.id,
    label: status.name,
  }));
  const urgencyOptions = (urgencyList || []).map((urgency) => ({
    value: urgency.id,
    label: urgency.name,
  }));
  const externalLabOptions = (externalLabList || []).map((externalLab) => ({
    value: externalLab.id,
    label: externalLab.name,
  }));
  const orderTypeOptions = (orderTypesList || []).map((orderType) => ({
    value: orderType.id,
    label: orderType.name,
  }));
  const typeOptions = (labOrderTypes || []).map((mouth) => ({
    value: mouth.id,
    label: mouth.name,
  }));
  const toothOptions = (toothList || []).map((tooth) => ({
    value: tooth.code,
    label: tooth.name,
  }));

  useEffect(() => {
    const treatmentItems = (values.treatmentItems || []).map((item, i) => {
      if (currentIndex === i) {
        item = { ...item, ...labAndRadiologyTreatmentItem.item };
        item.treatmentItem = {
          value: labAndRadiologyTreatmentItem.item.id,
          label: `${labAndRadiologyTreatmentItem?.item?.code}, ${labAndRadiologyTreatmentItem?.item?.description}`,
        };
      }
      return item;
    });
    form.setValues({
      ...values,
      treatmentItems,
    });
  }, [labAndRadiologyTreatmentItem]);

  return (
    <>
      <Modal
        size="lg"
        show={showImage.visible}
        onHide={() => setShowImage({ visible: false })}
        title={<h4>&nbsp;</h4>}
        actions={
          <button
            onClick={() => setShowImage({ visible: false })}
            type="button"
            className="btn btn-default"
          >
            Close
          </button>
        }
        animation={false}
        keyboard={false}
      >
        <div className="p-3">
          <img src={showImage.url} alt="" style={{ width: "100%", height: "auto" }} />
        </div>
      </Modal>
      <FormikProvider value={form}>
        <Show if={fetching}>
          <ReactSpinner />
        </Show>
        <form onSubmit={form.handleSubmit} className="p-2">
          <div className="row">
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Clinic-branch:
                <SelectField
                  isDisabled={editMode}
                  name="clinic"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={clinicOptions}
                  value={values?.clinic}
                  onChange={(v) => form.setFieldValue("clinic", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Ordering Doctor:
                <SelectField
                  isDisabled={editMode}
                  name="dentist"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={memberOptions}
                  value={values?.dentist}
                  onChange={(v) => form.setFieldValue("dentist", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Conducting Doctor-Staff:
                <SelectField
                  isDisabled={editMode}
                  name="dentistStaff"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={memberOptions}
                  value={values?.dentistStaff}
                  onChange={(v) => form.setFieldValue("dentistStaff", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Other Status:
                <SelectField
                  isDisabled={editMode}
                  name="status"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={statusOptions}
                  value={values?.status}
                  onChange={(v) => form.setFieldValue("status", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Urgency:
                <SelectField
                  isDisabled={editMode}
                  name="urgency"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={urgencyOptions}
                  value={values?.urgency}
                  onChange={(v) => form.setFieldValue("urgency", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Conducting Lab:
                <SelectField
                  isDisabled={editMode}
                  name="externalLab"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={externalLabOptions}
                  value={values?.externalLab}
                  onChange={(v) => form.setFieldValue("externalLab", v)}
                />
              </label>
            </div>
            <div className="col-sm-3">
              <label className="w-100 mb-3">
                Order Type:
                <SelectField
                  isDisabled={editMode}
                  name="orderType"
                  multi={false}
                  clearable={false}
                  searchable={false}
                  options={orderTypeOptions}
                  value={values?.orderType}
                  onChange={(v) => form.setFieldValue("orderType", v)}
                />
              </label>
            </div>
            <div className="col-sm-4">
              <label className="w-100 mb-3">
                Ordering Doctor Instruction:
                <textarea
                  disabled={editMode}
                  className="form-control"
                  name="dentistNotes"
                  rows="5"
                  value={values?.dentistNotes}
                  onChange={({ target: { value } }) => form.setFieldValue("dentistNotes", value)}
                />
              </label>
            </div>
            <div className="col-sm-4">
              <label className="w-100 mb-3">
                Ordering Doctor Justification for Lab Order:
                <textarea
                  disabled={editMode}
                  className="form-control"
                  name="dentistJustification"
                  rows="5"
                  value={values?.dentistJustification}
                  onChange={({ target: { value } }) =>
                    form.setFieldValue("dentistJustification", value)
                  }
                />
              </label>
            </div>
            <div className="col-sm-4">
              <label className="w-100 mb-3">
                Conducting Doctor-Staff Notes
                <textarea
                  disabled={editMode}
                  className="form-control"
                  name="dentistStaffNotes"
                  rows="5"
                  value={values?.dentistStaffNotes}
                  onChange={({ target: { value } }) =>
                    form.setFieldValue("dentistStaffNotes", value)
                  }
                />
              </label>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", marginBottom: "1rem" }} />
          <div className="mr-3">Orders: </div>
          <button
            disabled={editMode}
            className="btn btn-success mb-3"
            type="button"
            onClick={addTreatmentItem}
          >
            Add Order
          </button>
          <Show if={form.values.treatmentItems.length}>
            {form.values.treatmentItems.map((row, treatmentIndex) => (
              <div className="row" key={treatmentIndex}>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    Treatment Code:
                    <div
                      role="button"
                      onClick={() => {
                        dispatch(
                          showSideBar(viewTypes.series, {
                            activeTooth: 0,
                          }),
                        );
                        setCurrentIndex(treatmentIndex);
                        dispatch(
                          pushOrderStack({
                            code: medicalFillInFormStackCodes.labOrder,
                            type: medicalFillInFormItemTypes.new,
                          }),
                        );
                      }}
                    >
                      <SelectPicker
                        isDisabled={editMode}
                        name="values.treatmentItems[idx].treatmentItem"
                        multi={false}
                        clearable={false}
                        searchable={false}
                        options={[]}
                        onChange={(e) => e}
                        value={values.treatmentItems[treatmentIndex].treatmentItem}
                        className="pointer-event-none"
                      />
                    </div>
                    {/*<SelectPickerAsync*/}
                    {/*  isDisabled={editMode}*/}
                    {/*  url="api/treatment/code/list.json"*/}
                    {/*  value={values.treatmentItems[treatmentIndex].treatmentItem}*/}
                    {/*  onChange={(v) => {*/}
                    {/*    console.log(v, "v");*/}
                    {/*    dispatch(getTreatmentItem({ id: v.value })).then((data) => {*/}
                    {/*      const treatmentItems = values.treatmentItems.map((item, i) => {*/}
                    {/*        if (treatmentIndex === i) {*/}
                    {/*          item = { ...item, ...data.item };*/}
                    {/*          item.treatmentItem = v;*/}
                    {/*        }*/}
                    {/*        return item;*/}
                    {/*      });*/}
                    {/*      form.setValues({*/}
                    {/*        ...values,*/}
                    {/*        treatmentItems,*/}
                    {/*      });*/}
                    {/*    });*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </label>
                </div>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    Type:
                    <SelectPicker
                      isDisabled={editMode}
                      name="values.treatmentItems[idx].type"
                      multi={false}
                      clearable={false}
                      searchable={false}
                      options={typeOptions}
                      value={values.treatmentItems[treatmentIndex].type}
                      onChange={(v) => {
                        const treatmentItems = values.treatmentItems.map((item, i) => {
                          if (treatmentIndex === i) {
                            item.type = v;
                          }
                          return item;
                        });
                        form.setValues({
                          ...values,
                          treatmentItems,
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="w-100 mb-3">
                    Area:
                    <SelectPicker
                      isDisabled={editMode}
                      name="values.treatmentItems[idx].tooth"
                      multi={false}
                      clearable={false}
                      searchable={false}
                      options={toothOptions}
                      value={values.treatmentItems[treatmentIndex].tooth}
                      onChange={(v) => {
                        const treatmentItems = values.treatmentItems.map((item, i) => {
                          if (treatmentIndex === i) {
                            item.tooth = v;
                            item.toothPosition = v.value;
                          }
                          return item;
                        });
                        form.setValues({
                          ...values,
                          treatmentItems,
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    Shade:
                    <TextInput
                      disabled={editMode}
                      name="values.treatmentItems[idx].shades"
                      value={values.treatmentItems[treatmentIndex].shades}
                      onChange={({ target: { value } }) => {
                        const treatmentItems = values.treatmentItems.map((item, i) => {
                          if (treatmentIndex === i) {
                            item.shades = value;
                          }
                          return item;
                        });
                        form.setValues({
                          ...values,
                          treatmentItems,
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="col-sm-2">
                  <label className="w-100 mb-3">
                    Cost price:
                    <TextInput
                      disabled={editMode}
                      type="number"
                      name="values.treatmentItems[idx].costPrice"
                      value={values.treatmentItems[treatmentIndex].costPrice}
                      onChange={({ target: { value } }) => {
                        const treatmentItems = values.treatmentItems.map((item, i) => {
                          if (treatmentIndex === i) {
                            item.costPrice = value;
                          }
                          return item;
                        });
                        form.setValues({
                          ...values,
                          treatmentItems,
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="w-100 mb-3">
                    &nbsp;
                    <div>
                      <button
                        type="button"
                        title="Add Samples"
                        disabled={
                          editMode || !values.treatmentItems[treatmentIndex]?.treatmentItem?.value
                        }
                        className="btn btn-success btn-rounded mr-2"
                        onClick={() => addSampleItem(treatmentIndex)}
                      >
                        +
                      </button>
                      <button
                        disabled={editMode}
                        type="button"
                        title="Remove treatment"
                        className="btn btn-danger btn-rounded"
                        onClick={() => removeTreatmentItem(treatmentIndex)}
                      >
                        -
                      </button>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </Show>

          <Show
            if={
              values.treatmentItems.filter((treatment) => (treatment.sampleItems || []).length)
                .length
            }
          >
            <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", marginBottom: "1rem" }} />
            <div className="mb-3">Samples: </div>
            {generateSamples()}
          </Show>

          <Show
            if={
              values.treatmentItems.filter((treatment) =>
                (treatment.sampleItems || []).filter((sample) => (sample.reports || []).length),
              ).length
            }
          >
            <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", marginBottom: "1rem" }} />
            <div className="mb-3">Results: </div>
            {generateReports()}
          </Show>

          <div className="d-flex mt-3 justify-content-end">
            <Show if={!editMode}>
              <button className="btn btn-success" type="submit">
                {"Save"}
              </button>
            </Show>
            <Show if={editMode}>
              <button className="btn btn-success" onClick={onComplete} type="button">
                {"Complete"}
              </button>
            </Show>
          </div>
        </form>
      </FormikProvider>
      <Sidebar
        fullScreen={false}
        dialogClassName="chart-side-bar order-sidebar"
        show={sidebarVisible.visible}
        onHide={() => sidebarVisibleHandler({ visible: false })}
      >
        <MedicalFormOrderView
          pdfUrl={sidebarVisible.url}
          sidebarVisibleHandler={() => sidebarVisibleHandler({ visible: false })}
        />
      </Sidebar>
    </>
  );
};

export default enhancer(LabOrderCreate);
