import React from "react";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as patientRegisterActions from "../../../actions/patientRegisterActions";
import { Modal } from "../../ui/Modal";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientPlanVisitChooseActionModal extends React.Component {
  onJustView = () => {
    this.props.history.push(
      createUrl(Routes.DashboardChart, {
        params: { type: "View" },
        query: { patientKey: this.props.patientKey },
      }),
    );

    this.props.patientRegisterActions.patientChooseActionModalHide();
  };

  openPlanning = () => {
    this.props.patientRegisterActions.patientChooseActionModalHide();
    this.props.history.push(
      createUrl(Routes.DashboardChart, {
        params: { type: "Planning" },
        query: { patientKey: this.props.patientKey },
      }),
    );
  };

  render() {
    const { show } = this.props;

    return (
      <Modal
        title="Choose action"
        show={show}
        size="sm"
        onHide={this.props.patientRegisterActions.patientChooseActionModalHide}
        className="patient-view-choose-chart-action"
      >
        <button type="button" className="btn btn-link" onClick={this.onJustView}>
          Just View
        </button>

        {this.props.planning ? (
          <button type="button" className="btn btn-link" onClick={this.openPlanning}>
            Planning
          </button>
        ) : null}

        <button
          type="button"
          className="btn btn-link"
          onClick={this.props.patientRegisterActions.patientSelectAppointmentSidebarShow}
        >
          Plan Visit
        </button>
        <button
          type="button"
          className="btn btn-link"
          onClick={this.props.patientRegisterActions.patientChooseActionModalHide}
        >
          Cancel
        </button>
      </Modal>
    );
  }
}
export default withRouter(enhancer(PatientPlanVisitChooseActionModal));
