export const REPEAT_APPT_GET_DOCTORS_START = "REPEAT_APPT_GET_DOCTORS_START";
export const REPEAT_APPT_GET_DOCTORS_SUCCESS = "REPEAT_APPT_GET_DOCTORS_SUCCESS";
export const REPEAT_APPT_GET_DOCTORS_ERROR = "REPEAT_APPT_GET_DOCTORS_ERROR";

export const REPEAT_APPT_GET_TRX_ROOMS_START = "REPEAT_APPT_GET_TRX_ROOMS_START";
export const REPEAT_APPT_GET_TRX_ROOMS_SUCCESS = "REPEAT_APPT_GET_TRX_ROOMS_SUCCESS";
export const REPEAT_APPT_GET_TRX_ROOMS_ERROR = "REPEAT_APPT_GET_TRX_ROOMS_ERROR";

export const REPEAT_APPT_CREATE_APPT_START = "REPEAT_APPT_CREATE_APPT_START";
export const REPEAT_APPT_CREATE_APPT_SUCCESS = "REPEAT_APPT_CREATE_APPT_SUCCESS";
export const REPEAT_APPT_CREATE_APPT_ERROR = "REPEAT_APPT_CREATE_APPT_ERROR";

export const REPEAT_APPT_GET_TYPES_START = "REPEAT_APPT_GET_TYPES_START";
export const REPEAT_APPT_GET_TYPES_SUCCESS = "REPEAT_APPT_GET_TYPES_SUCCESS";
export const REPEAT_APPT_GET_TYPES_ERROR = "REPEAT_APPT_GET_TYPES_ERROR";

export const REPEAT_APPT_CREATE_TRANSACTION_START = "REPEAT_APPT_CREATE_TRANSACTION_START";
export const REPEAT_APPT_CREATE_TRANSACTION_SUCCESS = "REPEAT_APPT_CREATE_TRANSACTION_SUCCESS";
export const REPEAT_APPT_CREATE_TRANSACTION_ERROR = "REPEAT_APPT_CREATE_TRANSACTION_ERROR";

export const REPEAT_APPT_INIT_TRX_ROOMS = "REPEAT_APPT_INIT_TRX_ROOMS";

export const REPEAT_FOLLOW_TYPE_SELECT = "REPEAT_FOLLOW_TYPE_SELECT";

export const REPEAT_FOLLOW_SET_NOTE = "REPEAT_FOLLOW_SET_NOTE";
export const REPEAT_FOLLOW_SET_NOTE_02 = "REPEAT_FOLLOW_SET_NOTE_02";

export const REPEAT_FOLLOW_GET_NOTE_LIST_START = "REPEAT_FOLLOW_GET_NOTE_LIST_START";
export const REPEAT_FOLLOW_GET_NOTE_LIST_SUCCESS = "REPEAT_FOLLOW_GET_NOTE_LIST_SUCCESS";
export const REPEAT_FOLLOW_GET_NOTE_LIST_ERROR = "REPEAT_FOLLOW_GET_NOTE_LIST_ERROR";

export const REPEAT_FOLLOW_ADD_NOTE_START = "REPEAT_FOLLOW_ADD_NOTE_START";
export const REPEAT_FOLLOW_ADD_NOTE_SUCCESS = "REPEAT_FOLLOW_ADD_NOTE_SUCCESS";
export const REPEAT_FOLLOW_ADD_NOTE_ERROR = "REPEAT_FOLLOW_ADD_NOTE_ERROR";

export const REPEAT_FOLLOW_DELETE_NOTE_START = "REPEAT_FOLLOW_DELETE_NOTE_START";
export const REPEAT_FOLLOW_DELETE_NOTE_SUCCESS = "REPEAT_FOLLOW_DELETE_NOTE_SUCCESS";
export const REPEAT_FOLLOW_DELETE_NOTE_ERROR = "REPEAT_FOLLOW_DELETE_NOTE_ERROR";

export const SET_FOLLOW_NOTE = "SET_FOLLOW_NOTE";
