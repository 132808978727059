import React, { useMemo } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { deviceExistSelector, loggedInSelector } from "../../reducers/authReducer";

interface Props extends Omit<RouteProps, "component"> {
  readonly component: React.ComponentType<RouteProps>;
}

export function PrivateRoute({ component, ...props }: Props) {
  const deviceExist = useShallowEqualSelector(deviceExistSelector);
  const loggedIn = useShallowEqualSelector(loggedInSelector);

  const authorized = useMemo(() => Boolean(loggedIn && deviceExist), [deviceExist, loggedIn]);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (authorized) {
          return React.createElement(component, routeProps);
        }

        return <Redirect to="/" />;
      }}
    />
  );
}
