import React from "react";
import { connect } from "react-redux";

import BackButton from "../../../widgets/BackButton";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePostOpActions from "../../../../actions/chartSidePostOpActions";
import EmbedButton from "../../../widgets/EmbedButton";
import { parseQuery } from "../../../../utils/UrlUtils";
import Show from "../../../widgets/Show";

const enhancer = connect(null, bindActions({ chartSidePostOpActions }));

class ChartPostOpItemView extends React.Component {
  componentDidMount() {
    this.props.chartSidePostOpActions.getItem();
  }

  render() {
    const { item } = this.props.chartSidePostOp;
    const { location } = this.props;
    const query = parseQuery(location?.search);

    return (
      <div className="chart-clinical-note">
        <div className="clinical-note-item">
          <div className="clinical-note-item-view-title">
            <BackButton
              className="btn-sm"
              text="Back"
              onClick={this.props.chartSidePostOpActions.popStack}
            />
            <Show if={query?.medicalType !== "view"}>
              <div className="chart-number">
                <button
                  className="btn btn-link"
                  onClick={() => this.props.chartSidePostOpActions.sendToEmail(item.id)}
                >
                  SEND TO EMAIL
                </button>
              </div>
            </Show>
            <div className="name">{item.name}</div>
          </div>
          <div className="clinical-note-item-view-body">
            <EmbedButton src={item.pdfUrl} />
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPostOpItemView);
