import MomentTimezone from "moment-timezone";

import _ from "lodash";
import moment from "moment";

function getStorageItem(item) {
  /* eslint-disable-next-line */
  return localStorage.getItem(item);
}

export function stringTimeToMilliseconds(time) {
  const arr = time.split(":");

  const hours = _.toFinite(arr[0]);
  const minutes = _.toFinite(arr[1]);

  return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
}

export function millisecondsToStringTime(time) {
  const minutes = time / 1000 / 60;

  const tmp = minutes / 60;

  const hours = Math.floor(tmp);
  const mins = tmp % 2 ? 0 : 30;

  return {
    hours,
    amPm: hours,
    minutes: mins,
    time: `${hours}:${mins}`,
  };
}

export function formatCompanyDate(date = new Date(), format) {
  const localDate = moment(date);

  const companyTimezoneCode = getStorageItem("timezone-code");
  const companyTimezone = MomentTimezone.tz(localDate, companyTimezoneCode);

  return companyTimezone.clone().format(format);
}

export function getCompanyFormatFullDate(date = new Date()) {
  const companyFullDateFormat = getStorageItem("company-full-date-format");

  return formatCompanyDate(date, companyFullDateFormat);
}

export function getCompanyFormatDate(date = new Date()) {
  const companyDateFormat = getStorageItem("company-date-format");

  return formatCompanyDate(date, companyDateFormat);
}

export function getCompanyFormatTime(date = new Date()) {
  const companyTimeFormat = getStorageItem("company-time-format");

  return formatCompanyDate(date, companyTimeFormat);
}

export function getCompanyFormatUrlTime(date = new Date()) {
  return formatCompanyDate(date, "HH:mm");
}

export function getCompanyStartOfDay(date = new Date(), clear = false, withoutLocal) {
  const localDate = moment(date).startOf("day");

  const companyTimezoneCode = getStorageItem("timezone-code");
  const companyTimezone = MomentTimezone.tz(localDate, companyTimezoneCode);

  const localUTCOffset = moment().utcOffset();
  const companyUTCOffset = companyTimezone.clone().utcOffset();

  const companyDate = new Date(companyTimezone.clone().format()).valueOf();

  const unixTimezone = companyUTCOffset * 60 * 1000;
  const localUnixTimezone = localUTCOffset * 60 * 1000;

  return clear
    ? companyDate
    : withoutLocal && unixTimezone > 0
    ? companyDate + unixTimezone
    : companyDate + (localUnixTimezone - unixTimezone);
}

export function getCompanyDate(date = new Date()) {
  const localDate = moment(date.valueOf());

  const companyTimezoneCode = getStorageItem("timezone-code");
  const companyTimezone = MomentTimezone.tz(localDate, companyTimezoneCode);

  return companyTimezone.clone();
}

export function getCompanyEndOfDay(date = new Date()) {
  const localDate = moment(date).endOf("day");

  const companyTimezoneCode = getStorageItem("timezone-code");
  const companyTimezone = MomentTimezone.tz(localDate, companyTimezoneCode);

  const localUTCOffset = moment().utcOffset();
  const companyUTCOffset = companyTimezone.clone().utcOffset();
  const companyDate = new Date(companyTimezone.clone().format()).valueOf();
  const unixTimezone = companyUTCOffset * 60 * 1000;
  const localUnixTimezone = localUTCOffset * 60 * 1000;

  return companyDate + (localUnixTimezone - unixTimezone);
}

export function getCompanyUTCOffset(milliseconds = true) {
  const companyTimezoneCode = getStorageItem("timezone-code");
  const companyTimezone = MomentTimezone.tz(moment(), companyTimezoneCode);

  return milliseconds
    ? companyTimezone.clone().utcOffset() * 60 * 1000
    : companyTimezone.clone().utcOffset();
}

export function getLocalUTCOffset(milliseconds = true) {
  const localUTCOffset = moment().utcOffset();

  return milliseconds ? localUTCOffset * 60 * 1000 : localUTCOffset;
}

export function getTimeFields(time) {
  if (time) {
    const hours = moment().utcOffset(0).set({ hour: 0, minute: 0 });
    const milliseconds = time;
    const hour = Math.floor(milliseconds / 3600000);
    const minute = Math.floor((milliseconds - hour * 60 * 60 * 1000) / 1000 / 60);
    return hours.set({ hour: hour, minute: minute });
  }
  return null;
}

export function fromMomentToHHMMInMillisecond(momentValues) {
  if (moment.isMoment(momentValues)) {
    const milliSeconds = momentValues.valueOf() - momentValues.startOf("day").valueOf();
    const hour = Math.floor(milliSeconds / 3600000);
    const minute = Math.floor((milliSeconds - hour * 60 * 60 * 1000) / 1000 / 60);
    return hour * 3600000 + minute * 60000;
  }
  return momentValues;
}
