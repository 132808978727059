import _ from "lodash";
import { connect } from "react-redux";
import ReactSpinner from "react-spinjs-fix";
import React, { Component } from "react";
import { deepUpdate } from "immupdate";
import Utils from "../../../helpers/Utils";
import { DescriptionCell } from "./DescriptionCell";
import { UploadImageCell } from "./UploadImageCell";
import { CustomPriceCellWrapper } from "./CustomPriceCellWrapper";
import { DeleteCellWrapper } from "./DeleteCellWrapper";
import { sortTreatments } from "../../../helpers/TreatmentUtils";
import { PriceListSelectWrapper } from "./PriceListSelectWrapper";
import { openSimpleModal } from "../../../actions/simpleModalActions";
import { CompletedDateCellWrapper } from "./CompletedDateCellWrapper";
import { TransactionStatus } from "../../../constants/TransactionTypes";
import {
  DATE_FORMAT_LEGACY,
  SELECT_BOX_SPIN_CONFIG,
  TreatmentGrouppingTypes as treatmentGroupping,
} from "../../../constants/Constants";
import {
  changeApprovalFilter,
  changeDurationAmount,
  changeDurationUnit,
  changeIntervalAmount,
  changeIntervalUnit,
  changeTransactionItem,
  changeTreatmentPlan,
  changeTreatmentSession,
  changeUnit,
  getAppointmentTransactions,
  setSearchParameters,
  setTransactionData,
  uploadTransactionImage,
} from "../../../actions/transactionActions";
import { ChangeToCash } from "./ChangeToCash";
import { DiscountCellWrapper } from "./DiscountCellWrapper";
import { NoteCellWrapper } from "./NoteCellWrapper";
import moment from "moment";

function generateRows(
  list = [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { elements, setState, treatmentDescriptionType, treatmentGroupingType, ...options },
) {
  const tBody = [];
  const removePlannedCompletedItems = list.some((item) => item.type.name == "Treatment");
  const sortedList =
    elements.leftButtons.selectedTransactionBtn.id === elements.leftButtons.kTrxBtn.id
      ? list.sort(sortTreatments)
      : list;
  const treatmentGrouppingData = localStorage.getItem("treatmentGroupping");
  // const treatmentGrouppingData = treatmentGroupingType?.code || treatmentGroupping.none;
  const withSorting = treatmentGrouppingData !== treatmentGroupping.none;

  sortedList.forEach((item, index) => {
    if (item) {
      const arr = [];
      const price = (item.price && parseFloat(item.price).toFixed(2)) || "0.00";
      const primaryPayerShare =
        (item.primaryPayerShare && parseFloat(item.primaryPayerShare).toFixed(2)) || "0.00";
      const secondaryPayerShare =
        (item.secondaryPayerShare && parseFloat(item.secondaryPayerShare).toFixed(2)) || "0.00";
      const patientShare =
        (item.patientShare && parseFloat(item.patientShare).toFixed(2)) || "0.00";
      const treatmentItem = item.treatmentItem || item.diagnosisItem;
      const { type } = item;
      const { status } = item;
      const statusName = status && status.name;
      const areaName = item.area && item.area.name;
      const toothName = item.tooth && item.tooth.name;
      // const dentist = item.assignedDoctor || item.closedDentist;
      let areaDetailsCode = "";
      const unitBox = typeof item.unitBox === "number" && item.unitBox;
      const problem = item.problem ? "Yes" : "No";
      const diagnosisClaimType = item.diagnosisClaimType?.name || "";
      const diagnosisYearOfOnSet = item.diagnosisYearOfOnSet
        ? moment(item.diagnosisYearOfOnSet).year()
        : null;

      const total = "";
      const remaining = "";

      const isCompleted = _.get(item, "status.code") === TransactionStatus.COMPLETED;

      const itemDescription =
        _.get(treatmentDescriptionType, "code") === "DESCRIPTION"
          ? treatmentItem.description
          : treatmentItem.description2;

      const discountCode = _.get(item, "discount.code", "AMOUNT");
      const discountValue = _.get(item, "discount.value", 0);

      const net =
        discountCode === "PERCENTAGE"
          ? Number(_.toFinite(price) - _.toFinite(price) * (discountValue / 100)).toFixed(2)
          : Number(_.toFinite(price) - discountValue).toFixed(2);

      if (item.areaDetails && item.areaDetails.length) {
        areaDetailsCode = item.areaDetails
          .map((item) => (item.code.length < 3 ? item.code : item.name))
          .join(", ");
      }
      const completedDate = Utils.parseDateFormat(
        Utils.calcDateWithTimeZone(item.completedDate),
        DATE_FORMAT_LEGACY,
      );
      const createdDate = Utils.parseDateFormat(
        Utils.calcDateWithTimeZone(item.createdDate),
        DATE_FORMAT_LEGACY,
      );
      const priorApprovalExpireDate =
        item.priorApprovalExpireDate &&
        Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(item.priorApprovalExpireDate),
          DATE_FORMAT_LEGACY,
        );
      const priorApproval2ExpireDate =
        item.priorApproval2ExpireDate &&
        Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(item.priorApproval2ExpireDate),
          DATE_FORMAT_LEGACY,
        );
      const approval =
        item.approvalStatus && item.approvalStatus.name
          ? item.approvalStatus.name
          : item.holdPreApproval
          ? "Hold"
          : "";

      const notApproved =
        (item.approvalStatus && item.approvalStatus.code === "Pending") ||
        (item.approvalStatus && item.approvalStatus.code === "Rejected") ||
        (item.approvalStatus && item.approvalStatus.code === "UnSent");
      const approval2 =
        item.approval2Status && item.approval2Status.name
          ? item.approval2Status.name
          : item.holdPreApproval
          ? "Hold"
          : "";
      const completedDentist = item.closedDentist && item.closedDentist.name;

      const isTrxTab =
        elements.leftButtons.selectedTransactionBtn.id === elements.leftButtons.kTrxBtn.id;

      switch (elements.leftButtons.selectedTransactionBtn.id) {
        case elements.leftButtons.kTrxBtn.id:
        case elements.leftButtons.kProductBtn.id:
        case elements.leftButtons.kExistingBtn.id:
        case elements.leftButtons.kDiagnosisBtn.id:
        case elements.leftButtons.kPlannedTreatmentBtn.id:
        case elements.leftButtons.kCompletedTreatmentBtn.id:
          arr.push(<DeleteCellWrapper item={item} />);

          arr.push(
            <ChangeToCash
              value={options.state.isCustom[item.key]}
              onChange={(x) =>
                setState({
                  isCustom: deepUpdate(options.state.isCustom).at(item.key).set(x),
                })
              }
            />,
          ); // Change to cash
          arr.push(
            <CustomPriceCellWrapper
              treatmentPriceRowId={item?.treatmentPriceRowId}
              transactionKey={item.key}
              disabled={options.permissions.indexOf("VIEW_TREATMENT_CUSTOM_PRICE") < 0}
              isCustomPrice={options.state.isCustom[item.key]}
              onChange={(x) =>
                setState({
                  isCustom: deepUpdate(options.state.isCustom).at(item.key).set(x),
                })
              }
            />,
          ); // Custom $
          arr.push(
            <PriceListSelectWrapper
              item={item}
              transactionKey={item.key}
              patientKey={item.patientKey}
              isCustomPrice={options.state.isCustom[item.key]}
              treatmentId={_.get(item, "treatmentItem.id")}
            />,
          ); // Select $

          if (isTrxTab) {
            arr.push(_.get(item, "treatmentPlan", 1));
            arr.push(_.get(item, "treatmentSession", 1));
            arr.push(unitBox); //Unit
            arr.push(_.get(item, "intervalUnit", "day"));
            arr.push(_.get(item, "intervalAmount", 1));
            arr.push(_.get(item, "durationUnit", "day"));
            arr.push(_.get(item, "durationAmount", 1));
            arr.push(_.get(item, "assignedDoctor.name", ""));
          }
          arr.push(createdDate); //Created Date
          arr.push(
            <CompletedDateCellWrapper
              key={item.key}
              disabled={!isCompleted}
              transactionKey={item.key}
              date={item.completedDate}
            />,
          ); //Completed Date

          arr.push(completedDentist); // Closed Dentist
          arr.push(treatmentItem && treatmentItem.code); //Code
          arr.push(
            <DescriptionCell
              id={item.key}
              text={itemDescription}
              onShowTooltip={() => setState({ showTooltip: true })}
              onHideTooltip={() => setState({ showTooltip: false })}
            />,
          ); //Description

          !removePlannedCompletedItems && arr.push(problem);
          !removePlannedCompletedItems && arr.push(diagnosisClaimType);
          !removePlannedCompletedItems && arr.push(diagnosisYearOfOnSet);

          if (withSorting) {
            arr.push(remaining); // Remaining
          }

          arr.push(toothName); //Tooth
          arr.push(areaName); //Area
          arr.push(areaDetailsCode); //Details

          if (options.showFee) {
            arr.push(price); //Fee
            arr.push(primaryPayerShare); //primaryPayerShare
            arr.push(secondaryPayerShare); //secondaryPayerShare
            arr.push(patientShare); //patientShare
          }

          arr.push(<DiscountCellWrapper transactionKey={item.key} value={item.discount} />); //Discount
          arr.push(net); // Net
          arr.push(<NoteCellWrapper transactionKey={item.key} value={item.note} />);

          arr.push(
            <DescriptionCell
              id={`${item.key}-1`}
              text={_.get(item, "priorApprovalComments", "")}
              onShowTooltip={() => setState({ showTooltip: true })}
              onHideTooltip={() => setState({ showTooltip: false })}
            />,
          );
          arr.push(approval); //Approval.1
          arr.push(priorApprovalExpireDate);
          if (withSorting) {
            arr.push(total); // Totals
          }
          arr.push(
            <DescriptionCell
              id={`${item.key}-2`}
              text={_.get(item, "priorApprovalComments2", "")}
              onShowTooltip={() => setState({ showTooltip: true })}
              onHideTooltip={() => setState({ showTooltip: false })}
            />,
          );
          arr.push(approval2); //Approval.2
          arr.push(priorApproval2ExpireDate);
          arr.push(statusName); //Status
          arr.push(
            <UploadImageCell
              url={item.imageUrl}
              onUploadClick={(x) => {
                options.openSimpleModal(x);
              }}
              onDrop={(x) => {
                options.uploadTransactionImage(x, item.key);
              }}
            />,
          ); // Attachment
          arr.push(_.get(item, "createdDentist.name")); //Dentist name
          arr.push(_.get(item, "clinic.name")); // Clinic

          break;
      }

      const tdArray = arr.map((td, tdIndex) => {
        const left =
          tdIndex < 4 && tdIndex !== 0
            ? elements.table.thead.widths.slice(0, tdIndex).reduce((a, i) => a + i, 0)
            : 0;

        let cellClassName = "";

        if (arr && arr.length) {
          if (statusName) {
            cellClassName += `status-${statusName.toLowerCase()} `;
          }
          if (type && type.name) {
            cellClassName += `type-${type.name.toLowerCase()} `;
          }
          if (notApproved) {
            cellClassName += "not-approved";
          }
        }

        return (
          <td
            style={{
              overflow: "hidden",
              borderBottom: "1px solid #e4e4e4",
              ...(tdIndex < 4
                ? {
                    padding: 0,
                    left: `${left}px`,
                    position: "sticky",
                    zIndex: 2,
                  }
                : elements.table.thead.titles[tdIndex] === "Discount" ||
                  elements.table.thead.titles[tdIndex] === "Note"
                ? {
                    padding: 0,
                    left: `${left}px`,
                  }
                : { zIndex: 0, borderRight: "1px solid #e4e4e4" }),
            }}
            key={tdIndex}
            className={cellClassName}
            width={elements.table.thead.widths[index] || 100}
          >
            <div
              style={
                tdIndex < 4 ||
                elements.table.thead.titles[tdIndex] === "Discount" ||
                elements.table.thead.titles[tdIndex] === "Note"
                  ? {
                      height: "40px",
                      padding: "10px",
                      borderRight: "1px solid",
                      borderRightColor: "#e4e4e4",
                    }
                  : {
                      height: "20px",
                      textOverflow: "ellipsis",
                      // overflow: "hidden",
                      whiteSpace: "nowrap",
                    }
              }
              className={
                elements.table.thead.titles[tdIndex] === "Custom $" ? "custom-input-field" : ""
              }
            >
              {td}
            </div>
          </td>
        );
      });

      let className = "tr ";

      if (arr && arr.length) {
        if (statusName) {
          className += `status-${statusName.toLowerCase()} `;
        }
        if (type && type.name) {
          className += `type-${type.name.toLowerCase()} `;
        }
        if (notApproved) {
          className += "not-approved";
        }
      }

      tBody.push(
        <tr key={index} className={className} onClick={() => options.openTransactionEdit(item)}>
          {tdArray}
        </tr>,
      );

      if (!_.isEmpty(item.parts)) {
        const $tr = item.parts.map((pars) => {
          let td = [];

          switch (elements.leftButtons.selectedTransactionBtn.id) {
            case elements.leftButtons.kTrxBtn.id:
            case elements.leftButtons.kProductBtn.id:
            case elements.leftButtons.kExistingBtn.id:
            case elements.leftButtons.kDiagnosisBtn.id:
            case elements.leftButtons.kPlannedTreatmentBtn.id:
            case elements.leftButtons.kCompletedTreatmentBtn.id:
              td = [
                ...[
                  null,
                  null,
                  null,
                  null,
                  completedDate,
                  `Visit ${pars.number}`,
                  pars.comment,
                  pars.price,
                ],
                ...(withSorting ? [null, null] : []),
                ...[null, null, null],
                ...(options.showFee ? [null] : []),
                ...[
                  null,
                  null,
                  pars.status && pars.status.name,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  pars.closedDentist && pars.closedDentist.name,
                  null,
                  null,
                ],
              ];
              break;
          }

          const $td = td.map((td, tdIndex) => (
            <td key={tdIndex}>
              <span>{td}</span>
            </td>
          ));

          return (
            <tr key={pars.key} className="pars" onClick={options.openTransactionEdit(item)}>
              {$td}
            </tr>
          );
        });

        tBody.push($tr);
      }
    }
  });

  return tBody;
}

const enhancer = connect(
  ({
    auth: { chosenMemberId, members },
    session: {
      toothNumericType,
      dateFormat,
      permissions,
      treatmentDescriptionType,
      treatmentGroupingType,
    },
    chart,
    transaction,
    calendar: { doctors },
    appointment: { appointment },
  }) => {
    return {
      toothNumericType,
      transaction,
      currentMember: members[chosenMemberId],
      doctors,
      chart,
      treatmentDescriptionType,
      treatmentGroupingType,
      appointment,
      dateFormat,
      permissions,
    };
  },
  {
    getAppointmentTransactions,
    setTransactionData,
    changeTransactionItem,
    setSearchParameters,
    uploadTransactionImage,
    openSimpleModal,
    changeApprovalFilter,
    changeTreatmentPlan,
    changeTreatmentSession,
    changeUnit,
    changeIntervalUnit,
    changeIntervalAmount,
    changeDurationUnit,
    changeDurationAmount,
  },
);

export default enhancer(
  class ChartTransactionTable extends Component {
    constructor(props) {
      super(props);

      this.state = {
        showTooltip: false,
        openedGroups: {},
        // eslint-disable-next-line react/no-unused-state
        isCustom: {},
        // eslint-disable-next-line react/no-unused-state
        customPrice: {},
      };
    }

    handleChangeTreatmentPlan = (value, key, patientKey) => {
      this.props.changeTreatmentPlan(
        {
          key,
          patientKey,
          treatmentPlan: value,
          currentDentistId: null,
          currentAppointmentId: null,
        },
        false,
      );
    };

    handleChangeTreatmentSession = (value, key, patientKey) => {
      this.props.changeTreatmentSession(
        {
          key,
          patientKey,
          currentDentistId: null,
          treatmentSession: value,
          currentAppointmentId: null,
        },
        false,
      );
    };

    handleChangeUnit = (value, key, patientKey) => {
      this.props.changeUnit(
        {
          key,
          patientKey,
          unit: value,
          currentDentistId: null,
          currentAppointmentId: null,
        },
        false,
      );
    };

    handleChangeIntervalUnit = (value, key, patientKey) => {
      this.props.changeIntervalUnit({
        key,
        patientKey,
        intervalUnit: value,
        currentDentistId: null,
        currentAppointmentId: null,
      });
    };

    handleChangeIntervalAmount = (value, key, patientKey) => {
      this.props.changeIntervalAmount({
        key,
        patientKey,
        intervalAmount: value,
        currentDentistId: null,
        currentAppointmentId: null,
      });
    };

    handleChangeDurationUnit = (value, key, patientKey) => {
      this.props.changeDurationUnit({
        key,
        patientKey,
        durationUnit: value,
        currentDentistId: null,
        currentAppointmentId: null,
      });
    };

    handleChangeDurationAmount = (value, key, patientKey) => {
      this.props.changeDurationAmount({
        key,
        patientKey,
        durationAmount: value,
        currentDentistId: null,
        currentAppointmentId: null,
      });
    };

    render() {
      const {
        doctors,
        elements,
        onScroll,
        transaction,
        permissions,
        currentMember,
        cellChangeItem,
        onCompleteUpdate,
        openTransactionEdit,
        treatmentDescriptionType,
        treatmentGroupingType,
      } = this.props;
      const transactionList = transaction.transaction.list || [];
      const transactionTotalCount = transaction.transaction.totalCount || [];
      const isForUnit =
        currentMember?.chartType?.code === "DERMATOLOGY_CHART" ||
        currentMember?.chartType?.code === "GENERAL_MEDICINE_CHART";

      /* eslint-disable-next-line */
      const withGrouping = localStorage.getItem("treatmentGroupping");
      // const withGrouping = treatmentGroupingType?.code || treatmentGroupping.none;
      let tBody = [];
      const newTransactionList = transactionList.filter((item) => {
        if (transaction.approvalFilter) {
          return (
            (_.get(item, "approvalStatus.name")
              ? _.get(item, "approvalStatus.name", "")
              : _.get(item, "holdPreApproval")
              ? "Hold"
              : ""
            ).indexOf(transaction.approvalFilter) > -1 ||
            (_.get(item, "approval2Status.name")
              ? _.get(item, "approval2Status.name", "")
              : _.get(item, "holdPreApproval")
              ? "Hold"
              : ""
            ).indexOf(transaction.approvalFilter) > -1
          );
        }

        return true;
      });

      const options = {
        doctors,
        elements,
        isForUnit,
        permissions,
        cellChangeItem,
        onCompleteUpdate,
        state: { ...this.state },
        openTransactionEdit,
        treatmentGroupingType,
        treatmentDescriptionType,
        setState: (x) => this.setState(x),
        showFee: permissions.indexOf("VIEW_DENTAL_TRANSACTION_ITEM_FEE") >= 0,
        handleChangeUnit: (value, key, patientKey) => this.handleChangeUnit(value, key, patientKey),
        handleChangeIntervalUnit: (value, key, patientKey) =>
          this.handleChangeIntervalUnit(value, key, patientKey),
        handleChangeIntervalAmount: (value, key, patientKey) =>
          this.handleChangeIntervalAmount(value, key, patientKey),
        handleChangeDurationUnit: (value, key, patientKey) =>
          this.handleChangeDurationUnit(value, key, patientKey),
        handleChangeDurationAmount: (value, key, patientKey) =>
          this.handleChangeDurationAmount(value, key, patientKey),
        handleChangeTreatmentPlan: (value, key, patientKey) =>
          this.handleChangeTreatmentPlan(value, key, patientKey),
        handleChangeTreatmentSession: (value, key, patientKey) =>
          this.handleChangeTreatmentSession(value, key, patientKey),
        openSimpleModal: (x) => this.props.openSimpleModal(x),
        uploadTransactionImage: (file, key) => this.props.uploadTransactionImage(file, key),
      };
      const removePlannedCompletedItems = transactionList.some(
        (item) => item.type.name == "Treatment",
      );
      const $ths = elements.table.thead.titles.map((item, index) => {
        const cellWidth = elements.table.thead.widths[index] || 100;
        const left =
          index < 4 && index !== 0
            ? elements.table.thead.widths.slice(0, index).reduce((a, i) => a + i, 0)
            : 0;
        // const changeToCashStyle = {
        //   width: "60px",
        //   whiteSpace: "pre-wrap",
        //   wordWrap: "break-word",
        // };
        let thStyle = {};
        // if (item === "Change to cash") thStyle = changeToCashStyle;
        if (index < 4)
          thStyle = {
            ...thStyle,
            ...{
              padding: 0,
              left: `${left}px`,
              position: "sticky",
              backgroundColor: "#E4E4E4",
            },
          };
        if (removePlannedCompletedItems) {
          if (item !== "Problem" && item !== "Diagnosis type" && item !== "Year of onset")
            return (
              <th
                style={thStyle}
                key={index}
                width={
                  item === "Note"
                    ? 180
                    : item === "Area" ||
                      item === "Fee" ||
                      item === "Payer Share 1" ||
                      item === "Payer Share 2" ||
                      item === "Patient Share"
                    ? 90
                    : cellWidth
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    ...(index < 4
                      ? {
                          height: "45px",
                          padding: "10px",
                          borderRight: "1px solid",
                          borderRightColor: "#e4e4e4",
                        }
                      : { height: "25px" }),
                  }}
                >
                  <span
                    className="font-weight-bold"
                    style={{ cursor: item === "Custom $" ? "pointer" : "default" }}
                  >
                    {item}
                  </span>
                  {Boolean(
                    item === "Description" || item === "Ins.Note" || item === "Ins.Note 2",
                  ) && (
                    <span style={{ color: "#7b7575" }}>(Click on ! to read full description)</span>
                  )}
                </div>
              </th>
            );
        } else {
          return (
            <th
              style={
                index < 4
                  ? {
                      padding: 0,
                      left: `${left}px`,
                      position: "sticky",
                      backgroundColor: "#E4E4E4",
                    }
                  : {}
              }
              key={index}
              width={cellWidth}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  ...(index < 4
                    ? {
                        height: "45px",
                        padding: "10px",
                        borderRight: "1px solid",
                        borderRightColor: "#e4e4e4",
                      }
                    : { height: "25px" }),
                }}
              >
                <span className="font-weight-bold">{item}</span>
                {Boolean(
                  item === "Description" || item === "Ins.Note" || item === "Ins.Note 2",
                ) && (
                  <span style={{ color: "#7b7575" }}>(Click on ! to read full description)</span>
                )}
              </div>
            </th>
          );
        }
      });

      if (
        withGrouping !== treatmentGroupping.none &&
        (elements.leftButtons.selectedTransactionBtn.id ===
          elements.leftButtons.kPlannedTreatmentBtn.id ||
          elements.leftButtons.selectedTransactionBtn.id ===
            elements.leftButtons.kCompletedTreatmentBtn.id)
      ) {
        if (withGrouping === treatmentGroupping.byProcedure) {
          const grouppedTransactionList = newTransactionList.reduce((groups, item) => {
            const procedureItem = _.get(item, "procedureItem");
            const procedureDate = _.get(item, "procedureKey");
            const procedureItemTooth = _.get(item, "tooth.name");
            const formattedDate = Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(item.createdDate),
              DATE_FORMAT_LEGACY,
            );
            if (procedureItem) {
              const groupName = `item-${procedureDate}`;

              const price = _.get(item, "price");
              const status = _.get(item, "status.code");

              const isCompleted = status === TransactionStatus.COMPLETED;

              const current = _.get(groups, groupName, {
                list: [],
                total: 0,
                remaining: 0,
              });
              const list = _.get(current, "list", []);

              list.push(item);

              groups[groupName] = {
                ...current,
                list,
                procedure: procedureItem,
                tooth: procedureItemTooth,
                total: price + current.total,
                remaining: isCompleted ? current.remaining : transactionTotalCount,
                createdDate: formattedDate,
              };
            } else {
              const current = _.get(groups, "none", {
                list: [],
              });
              const list = _.get(current, "list", []);

              list.push(item);

              groups.none = {
                ...current,
                list,
                total: "-",
                remaining: "-",
              };
            }

            return groups;
          }, {});

          _.forEach(grouppedTransactionList, (item, key) => {
            const list = _.get(item, "list");
            const total = _.get(item, "total");
            const tooth = _.get(item, "tooth");
            const createdDate = _.get(item, "createdDate");
            const procedure = _.get(item, "procedure");
            // console.log(procedure, "procedure");
            tBody.push(
              <tr key={key} data-test={key}>
                <td colSpan={$ths.length} style={{ padding: 0 }}>
                  <table
                    className="table"
                    style={{
                      tableLayout: "fixed",
                      width: this.state.openedGroups[key] ? "100%" : undefined,
                      margin: 0,
                    }}
                  >
                    <thead>
                      <tr
                        onClick={() =>
                          this.setState((state) => ({
                            openedGroups: {
                              ...state.openedGroups,
                              [key]: !state.openedGroups[key],
                            },
                          }))
                        }
                      >
                        {elements.table.thead.titles.map((item1, index) => {
                          const cellWidth = elements.table.thead.widths[index] || 100;
                          const left =
                            index < 5 && index !== 0
                              ? elements.table.thead.widths
                                  .slice(0, index)
                                  .reduce((a, i) => a + i, 0)
                              : 0;
                          return (
                            <th
                              key={index}
                              height="50"
                              width={cellWidth}
                              style={{
                                color: "#fff",
                                borderWidth: 0,
                                backgroundColor: "#049dd8",
                                ...(index < 5
                                  ? {
                                      zIndex: 10,
                                      left: `${index === 4 ? 325 : left}px`,
                                      position: "sticky",
                                      textAlign:
                                        !this.state.openedGroups[key] && index === 4
                                          ? "center"
                                          : "start",
                                    }
                                  : {}),
                              }}
                            >
                              <span>
                                {item1 === "Created Date"
                                  ? procedure
                                    ? createdDate
                                    : ""
                                  : undefined}
                                {item1 === "Description"
                                  ? procedure
                                    ? procedure.name
                                    : "Non Procedure"
                                  : undefined}

                                {item1 === "Tooth" ? (procedure ? tooth : "") : undefined}

                                {item1 === "Total" ? (procedure ? total : "-") : undefined}

                                {item1 === "Remaining"
                                  ? procedure
                                    ? (list || []).length
                                    : "-"
                                  : undefined}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {this.state.openedGroups[key] && <tbody>{generateRows(list, options)}</tbody>}
                  </table>
                </td>
              </tr>,
            );
          });
        } else if (withGrouping === treatmentGroupping.byTreatmentCode) {
          const grouppedTransactionList = newTransactionList.reduce((groups, item) => {
            const treatmentItem = _.get(item, "treatmentItem");
            const procedureItemTooth = _.get(item, "tooth.name");
            const formattedDate = Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(_.get(item, "createdDate")),
              DATE_FORMAT_LEGACY,
            );

            if (treatmentItem) {
              const treatmentCode = _.get(treatmentItem, "code");
              const treatmentDescription = _.get(treatmentItem, "description");
              const current = _.get(groups, `item-${treatmentCode}`, {
                list: [],
              });
              const list = _.get(current, "list", []);

              list.push(item);

              groups[`item-${treatmentCode}`] = {
                ...current,
                procedure: treatmentItem,
                tooth: procedureItemTooth,
                description: treatmentDescription,
                createdDate: formattedDate,
                list,
              };
            } else {
              const current = _.get(groups, "none", { list: [] });
              const list = _.get(current, "list", []);

              list.push(item);

              groups.none = {
                ...current,
                list,
              };
            }

            return groups;
          }, {});

          _.forEach(grouppedTransactionList, (item, key) => {
            const list = _.get(item, "list");
            const tooth = _.get(item, "tooth");
            const description = _.get(item, "description");
            const procedure = _.get(item, "procedure");
            const createdDate = _.get(item, "createdDate");

            const totalPrice = _.reduce(
              list,
              (acc, i) => {
                const price = _.get(i, "price", 0);

                return _.toFinite(price) + acc;
              },
              0,
            );

            tBody.push(
              <tr key={key} data-test={key}>
                <td colSpan={$ths.length} style={{ padding: 0 }}>
                  <table
                    className="table"
                    style={{
                      tableLayout: "fixed",
                      width: this.state.openedGroups[key] ? "100%" : undefined,
                      margin: 0,
                    }}
                  >
                    <thead>
                      <tr
                        onClick={() =>
                          this.setState((state) => ({
                            openedGroups: {
                              ...state.openedGroups,
                              [key]: !state.openedGroups[key],
                            },
                          }))
                        }
                      >
                        {elements.table.thead.titles.map((item1, index) => {
                          const cellWidth = elements.table.thead.widths[index] || 100;
                          const left =
                            index < 5 && index !== 0
                              ? elements.table.thead.widths
                                  .slice(0, index)
                                  .reduce((a, i) => a + i, 0)
                              : 0;

                          return (
                            <th
                              key={item1}
                              height="50"
                              width={cellWidth}
                              style={{
                                color: "#fff",
                                borderWidth: 0,
                                backgroundColor: "#049dd8",
                                ...(elements.table.thead.titles[index] === "Change to cash"
                                  ? { width: "60px" }
                                  : {}),
                                ...(index < 5
                                  ? {
                                      zIndex: 100,
                                      left: `${index === 4 ? 325 : left}px`,
                                      position: "sticky",
                                      textAlign:
                                        !this.state.openedGroups[key] && index === 4
                                          ? "center"
                                          : "start",
                                    }
                                  : {}),
                              }}
                            >
                              <span>
                                {item1 === "Code"
                                  ? _.get(procedure, "name", "Without TreatmentCode")
                                  : item1 === "Tooth"
                                  ? procedure
                                    ? tooth
                                    : ""
                                  : item1 === "Description" &&
                                    withGrouping === treatmentGroupping.byTreatmentCode
                                  ? procedure
                                    ? description
                                    : ""
                                  : item1 === "Completed Date" &&
                                    withGrouping === treatmentGroupping.byTreatmentCode
                                  ? `(${list.length})`
                                  : item1 === "Fee" &&
                                    withGrouping === treatmentGroupping.byTreatmentCode
                                  ? totalPrice
                                  : item1 === "Total"
                                  ? "-"
                                  : item1 === "Remaining"
                                  ? "-"
                                  : item1 === "Created Date"
                                  ? procedure
                                    ? createdDate
                                    : ""
                                  : ""}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {this.state.openedGroups[key] && <tbody>{generateRows(list, options)}</tbody>}
                  </table>
                </td>
              </tr>,
            );
          });
        } else {
          tBody = generateRows(newTransactionList, options);
        }
      } else {
        tBody = generateRows(newTransactionList, options);
      }
      return (
        <div className="transaction-table-wrap">
          <div
            className="transaction-table"
            onScroll={onScroll}
            style={{
              flex: 1,
              ...(this.state.showTooltip ? { zIndex: 10000 } : {}),
            }}
          >
            <table className="table" style={{ tableLayout: "fixed", width: elements.table.width }}>
              <thead>
                <tr>{$ths}</tr>
              </thead>
              <tbody>{tBody}</tbody>
            </table>

            {elements.table.loader.visible ? (
              <div className="loader-box">
                <ReactSpinner className="loader" config={SELECT_BOX_SPIN_CONFIG} />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  },
);
