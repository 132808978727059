import _, { toFinite } from "lodash";
import React from "react";
import { deepUpdate } from "immupdate";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";
import ChartPlanForName from "./ChartPlanForName";

import { TreatmentGrouppingTypes as treatmentGroupping } from "../../../../constants/Constants";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePlanActions from "../../../../actions/chartSidePlanActions";
import * as transactionActions from "../../../../actions/transactionActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";
import { getCompanyFormatDate } from "../../../../helpers/DateUtils";
import { TransactionStatus } from "../../../../constants/TransactionTypes";
import checkedImage from "../../../../assets/images/appointment/checked.png";
import uncheckedImage from "../../../../assets/images/appointment/unchecked.png";
import { SelectPicker } from "../../../select-picker/SelectPicker";

const enhancer = connect(
  ({ transaction: { currency }, auth: { chosenMemberId, members = {} } }) => {
    const member = members[chosenMemberId];

    return {
      canSetDiscount: member && !member.discountDisabled,
      currency,
    };
  },
  bindActions({
    chartSidePlanActions,
    transactionActions,
    chartSideTransactionActions,
  }),
);

class ChartPlanListFor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openedGroups: {},
      discount: {},
      filterByStatus: "DentalTransactionStatus_PLANNED",
      sortBy: 0,
    };
  }

  componentDidMount() {
    this.props.transactionActions.getCurrencies();
    this.props.chartSidePlanActions.initFor();
    this.props.chartSidePlanActions.initListFor();
  }

  toggleItem = (item) => {
    return () => {
      this.props.chartSidePlanActions.toggleListFor(item);
    };
  };

  toggleItems = (items, doSelect) => {
    this.props.chartSidePlanActions.toggleGroupListFor(items, doSelect);
  };

  toggleAllItems = () => {
    this.props.chartSidePlanActions.toggleAllGroupListFor(this.state.filterByStatus);
  };

  clearAllItems = () => {
    this.props.chartSidePlanActions.clearAllGroupListFor();
  };

  loadList = (e) => {
    if (this.props.chartSidePlan.selectedMode) return;

    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.chartSidePlanActions.loadListFor();
    }
  };

  onSearch = (event) => {
    this.props.chartSidePlanActions.setSearchValueFor(event.target.value);
  };

  setSorting = (type) => {
    return () => {
      this.props.chartSidePlanActions.setSorting(type);
    };
  };

  updateDiscount = (key) => {
    const { discount } = this.state;

    const { chartSideTransactionActions } = this.props;
    const { updateTransactionDiscount } = chartSideTransactionActions;

    const discountType = _.get(discount, `${key}.code`);
    const discountValue = _.get(discount, `${key}.value`);

    updateTransactionDiscount({
      keys: [key],
      discount: {
        code: discountType,
        value: discountValue,
      },
    });
  };

  generateList = (list, selectedFor, canSetDiscount) => {
    return list.map((item, index) => {
      let src = selectedFor.hasOwnProperty(item.key) ? checkedImage : uncheckedImage;

      const { discount } = this.state;
      const { key } = item;

      const defaultDiscountType = _.get(item, "discount.code");
      const defaultDiscountValue = _.get(item, "discount.value");

      const discountType = _.get(discount, `${key}.code`, defaultDiscountType);
      const discountValue = _.get(discount, `${key}.value`, defaultDiscountValue);

      return (
        <div className="chart-plan-for-table-row" key={index} onClick={this.toggleItem(item)}>
          <div className="col-1">{getCompanyFormatDate(item.modifiedDate)}</div>
          <div className="col-2">{item.treatmentItem && item.treatmentItem.name}</div>
          <div className="col-3">{item.treatmentItem && item.treatmentItem.description}</div>
          <div className="col-3">{item.note}</div>
          <div className="col-discount" onClick={(e) => e.stopPropagation()}>
            <SelectPicker
              disabled={!canSetDiscount}
              value={{ value: discountType }}
              onChange={(code) => {
                this.setState((state) =>
                  deepUpdate(state).at("discount").at(item.key).set({
                    code: code.value,
                    value: 0,
                  }),
                );
              }}
              onClick={(e) => e.stopPropagation()}
              options={[
                {
                  label: "Amount",
                  value: "AMOUNT",
                },
                {
                  label: "Percentage",
                  value: "PERCENTAGE",
                },
              ]}
              name="discountType"
              id="discountType"
            />
            <input
              disabled={!canSetDiscount}
              value={discountValue}
              placeholder="0.00"
              type="number"
              onBlur={() => {
                if (
                  discountType !== defaultDiscountType ||
                  discountValue !== defaultDiscountValue
                ) {
                  this.updateDiscount(item.key);
                }
              }}
              onFocus={({ target }) => target.select()}
              onChange={({ target }) => {
                const value = _.toNumber(target.value);

                if (value >= 0) {
                  if (
                    (discountType === "PERCENTAGE" && value <= 100) ||
                    (discountType === "AMOUNT" && value <= item.price)
                  ) {
                    this.setState((state) =>
                      deepUpdate(state).at("discount").at(item.key).set({
                        code: discountType,
                        value: value,
                      }),
                    );
                  }
                }
              }}
            />
          </div>
          <div className="col-7">
            {item?.discount?.code === "AMOUNT"
              ? (item.price - toFinite(item?.discount?.value)).toFixed(2)
              : (item.price - (item.price * toFinite(item?.discount?.value)) / 100).toFixed(2)}
          </div>
          <div className="col-4">{item.tooth && item.tooth.name}</div>
          <div className="col-5">{item.area && item.area.name}</div>
          <div className="col-6">
            {item.areaDetails && item.areaDetails[0] && item.areaDetails[0].name}
          </div>
          <div className="col-7">{item.price && item.price.toFixed(2)}</div>
          <div className="col-8">
            <img alt="" src={src} className="zoom-2x" />
          </div>
        </div>
      );
    });
  };

  render() {
    const { listFor, fetching, selectedMode, selectedFor, sorting } = this.props.chartSidePlan;
    const { canSetDiscount } = this.props;

    const selectedItems = _.filter(selectedFor, (x) => x) || [];

    const totalPrice = selectedItems.reduce((acc, x) => acc + _.get(x, "price", 0), 0);
    // const totalNet = selectedItems.reduce((acc, x) => {
    //   if (x?.discount?.value) {
    //     return acc + x?.discount?.code === "AMOUNT"
    //       ? x.price - toFinite(x?.discount?.value)
    //       : x.price - (x.price * toFinite(x?.discount?.value)) / 100;
    //   }
    //   return acc + 0;
    // }, 0);
    const totalNet = selectedItems.reduce((acc, x) => {
      if (x?.discount?.value) {
        return acc + x?.discount?.code === "AMOUNT"
          ? x.price - toFinite(x?.discount?.value)
          : x.price - (x.price / 100) * toFinite(x?.discount?.value);
        // : x.price - (x.price * toFinite(x?.discount?.value)) / 100;
        // (price - (price / 100) * toFinite(discount ? discount.value : 0))
      }
      return acc + x.price;
    }, 0);

    const $tBody = [];
    const withGrouping = localStorage.getItem("treatmentGroupping");
    const filtredList = listFor
      .filter((item) => !selectedMode || selectedFor.hasOwnProperty(item.key))
      .filter((item) => {
        const status = _.get(item, "status.code");

        return (
          (this.state.filterByStatus === "all" &&
            (status === TransactionStatus.COMPLETED || status === TransactionStatus.PLANNED)) ||
          status === this.state.filterByStatus
        );
      });

    const estimatedList =
      withGrouping === treatmentGroupping.byProcedure
        ? filtredList.reduce((groups, item) => {
            const procedureItem = _.get(item, "procedureItem");
            const procedureDate = _.get(item, "procedureKey");
            const createdDate = _.get(item, "createdDate");
            const procedureItemTooth = _.get(item, "tooth.name");
            const price = _.get(item, "price");

            if (procedureItem) {
              const current = _.get(groups, `item-${procedureDate}`, {
                list: [],
                price: 0,
              });
              const list = _.get(current, "list", []);

              list.push(item);

              groups[`item-${procedureDate}`] = {
                ...current,
                procedure: procedureItem,
                createdDate,
                type: "procedure",
                list,
                tooth: procedureItemTooth,
                price: current.price + _.toFinite(price),
              };
            } else {
              const current = _.get(groups, "none", { list: [], price: 0 });
              const list = _.get(current, "list", []);

              list.push(item);

              groups.none = {
                ...current,
                list,
                type: "procedure",
                tooth: procedureItemTooth,
                price: current.price + _.toFinite(price),
              };
            }

            return groups;
          }, {})
        : withGrouping === treatmentGroupping.byTreatmentCode
        ? filtredList.reduce((groups, item) => {
            const treatmentItem = _.get(item, "treatmentItem");
            const createdDate = _.get(item, "createdDate");
            const treatmentItemTooth = _.get(item, "tooth.name");
            const treatmentDescription = _.get(treatmentItem, "description");
            const price = _.get(item, "price");
            const net =
              item?.discount?.code === "AMOUNT"
                ? item.price - toFinite(item?.discount?.value)
                : item.price - (item.price * toFinite(item?.discount?.value)) / 100;
            if (treatmentItem) {
              const treatmentCode = _.get(treatmentItem, "code");
              const current = _.get(groups, `item-${treatmentCode}`, {
                list: [],
                price: 0,
              });
              const list = _.get(current, "list", []);

              list.push(item);

              groups[`item-${treatmentCode}`] = {
                ...current,
                procedure: treatmentItem,
                createdDate,
                description: treatmentDescription,
                list,
                type: "treatmentCode",
                tooth: treatmentItemTooth,
                price: current.price + _.toFinite(price),
              };
            } else {
              const current = _.get(groups, "none", { list: [], price: 0 });
              const list = _.get(current, "list", []);

              list.push(item);

              groups.none = {
                ...current,
                list,
                type: "treatmentCode",
                description: treatmentDescription,
                tooth: treatmentItemTooth,
                price: current.price + _.toFinite(price),
                net,
              };
            }

            return groups;
          }, {})
        : filtredList;

    if (withGrouping !== treatmentGroupping.none) {
      _.forEach(estimatedList, (item, key) => {
        const procedureList = _.get(item, "list");
        const createdDate = _.get(item, "createdDate");
        const procedure = _.get(item, "procedure");
        const price = _.get(item, "price");
        const tooth = _.get(item, "tooth");
        const description = _.get(item, "description");
        const type = _.get(item, "type");
        const selectedGroup = procedureList.reduce(
          (acc, item) => acc && Boolean(selectedFor[item.key]),
          true,
        );

        let src = selectedGroup ? checkedImage : uncheckedImage;

        $tBody.push(
          <div key={key} className="chart-plan-for-table-row table-row-group">
            <div
              className="chart-plan-for-table-row group-title"
              onClick={() =>
                this.setState((state) => ({
                  openedGroups: {
                    ...state.openedGroups,
                    [key]: !state.openedGroups[key],
                  },
                }))
              }
            >
              <div className="col-1">{getCompanyFormatDate(createdDate)}</div>
              <div className="col-2">
                {`${
                  type === "treatmentCode"
                    ? procedure
                      ? procedure.name
                      : "Without TreatmentCode"
                    : ""
                } (${_.size(procedureList)})`}
              </div>
              <div className="col-3">
                {type === "procedure"
                  ? procedure
                    ? procedure.name
                    : "Non Procedure"
                  : type === "treatmentCode"
                  ? description
                  : ""}
              </div>
              <div className="col-discount" />
              <div className="col-4">{tooth}</div>
              <div className="col-5" />
              <div className="col-6" />
              <div className="col-7">{price.toFixed(2)}</div>
              <div className="col-8">
                <button
                  className="check-all-button"
                  onClick={(event) => {
                    event.stopPropagation();

                    this.toggleItems(procedureList, !selectedGroup);
                  }}
                >
                  <img alt="" src={src} className="zoom-2x" />
                </button>
              </div>
            </div>
            {this.state.openedGroups[key] &&
              this.generateList(procedureList, selectedFor, canSetDiscount)}
          </div>,
        );
      });
    } else {
      $tBody.push(this.generateList(estimatedList, selectedFor, canSetDiscount));
    }

    let $dateArrow = null;
    let $toothArrow = null;
    let $codeArrow = null;

    if (sorting === "date_asc") {
      $dateArrow = <span>&#9650;</span>;
    }

    if (sorting === "date_desc") {
      $dateArrow = <span>&#9660;</span>;
    }

    if (sorting === "tooth_asc") {
      $toothArrow = <span>&#9650;</span>;
    }

    if (sorting === "tooth_desc") {
      $toothArrow = <span>&#9660;</span>;
    }

    if (sorting === "code_asc") {
      $codeArrow = <span>&#9650;</span>;
    }

    if (sorting === "code_desc") {
      $codeArrow = <span>&#9660;</span>;
    }

    const currencies = _.get(this.props, "currency.list", []);
    const defaultCurrency = currencies.find((x) => {
      const name = _.get(x, "name", "");

      return name.indexOf("Default") >= 0;
    });

    const currencyId = _.get(defaultCurrency, "id", 1);
    const selectedAll = filtredList.reduce((ag, item) => {
      const state =
        item.status.code === this.state.filterByStatus || this.state.filterByStatus === "all"
          ? Boolean(selectedFor[item.key])
          : true;

      return ag && state;
    }, true);

    const src = selectedAll && filtredList.length > 0 ? checkedImage : uncheckedImage;

    return (
      <div className="clinical-note-item">
        <div className="clinical-note-item-view-title">
          <button
            onClick={this.props.chartSidePlanActions.doneFor}
            className="btn btn-link pull-right"
          >
            <strong>DONE</strong>
          </button>
          <BackButton
            className="btn-sm pull-left"
            text="Back"
            onClick={this.props.chartSidePlanActions.popStack}
          />
          <div className="name">Select treatments for treatments plan</div>
        </div>

        <div className="clinical-note-item-view-body">
          <ChartPlanForName
            selectedFor={selectedFor}
            selectedMode={selectedMode}
            currency={this.props.currency}
            initialValues={{
              useInvoiceTemplate: "false",
              currencyId,
              groupingType: "GROUP_BY_CODE_PRICE_UNIT",
            }}
            callback={() => {
              this.props.chartSidePlanActions.initListFor();
            }}
            sortBy={this.state.sortBy}
            onChangeSort={(x) => {
              this.clearAllItems();
              this.setState({ sortBy: x });
              this.props.chartSidePlanActions.setSorting(x, true);
            }}
            filterByStatus={this.state.filterByStatus}
            changeFilterStatus={(x) => {
              this.clearAllItems();
              this.setState({ filterByStatus: x });
            }}
          />

          <div className="chart-plan-for-table">
            <div className="chart-plan-for-table-header">
              <div className="chart-plan-for-table-row">
                <div
                  className="col-1"
                  onClick={this.setSorting("date")}
                  style={{ cursor: "pointer" }}
                >
                  <u>Posted Date</u> {$dateArrow}
                </div>
                <div
                  className="col-2"
                  onClick={this.setSorting("code")}
                  style={{ cursor: "pointer" }}
                >
                  <u>Code</u> {$codeArrow}
                </div>
                <div className="col-3 title">Description</div>
                <div className="col-3 title">Note</div>
                <div className="col-discount" style={{ justifyContent: "center" }}>
                  Discount
                </div>
                <div className="col-7">Net</div>
                <div
                  className="col-4"
                  onClick={this.setSorting("tooth")}
                  style={{ cursor: "pointer" }}
                >
                  <u>Tooth</u> {$toothArrow}
                </div>
                <div className="col-5">Area</div>
                <div className="col-6">Area Details</div>
                <div className="col-7">Fee</div>
                <div className="col-8">
                  <button
                    className="check-all-button"
                    onClick={(event) => {
                      event.stopPropagation();

                      this.toggleAllItems();
                    }}
                  >
                    <img alt="" src={src} className="zoom-2x" />
                  </button>
                </div>
              </div>
            </div>
            <div className="chart-plan-for-table-body" onScroll={this.loadList}>
              {$tBody}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
          <div
            style={{
              padding: "6px",
              display: "flex",
              paddingBottom: 0,
              justifyContent: "flex-end",
              fontSize: "18px",
            }}
          >
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Total Qty: <span style={{ fontWeight: "bold" }}>{selectedItems.length}</span>
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                marginLeft: "12px",
              }}
            >
              Total Amount:{" "}
              <span style={{ fontWeight: "bold" }}>{Number(totalPrice).toFixed(2)}</span>
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                marginLeft: "12px",
              }}
            >
              Total Discount:{" "}
              <span style={{ fontWeight: "bold" }}>{Number(totalPrice - totalNet).toFixed(2)}</span>
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                marginLeft: "12px",
              }}
            >
              Total Net Amount:{" "}
              <span style={{ fontWeight: "bold" }}>{Number(totalNet).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPlanListFor);
