import React, { useEffect, useState } from "react";
import _ from "lodash";
import medicalFillInFormItemTypes from "../../../../constants/chart-sidebar/medicalFillInFormItemTypes";
import { medicalFillInFormStackCodes } from "../../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import MedicalFillInFormInitialExaminationList from "./MedicalFillInFormInitialExaminationList";
import MedicalFillInFormInitialExaminationItem from "./MedicalFillInFormInitialExaminationItem";
import MedicalFillInFormOwnedList from "./MedicalFillInFormOwnedList";
import MedicalFillInFormList from "./MedicalFillInFormList";
import MedicalFillInFormView from "./MedicalFillInFormView";
import { connect, useDispatch } from "react-redux";
import {
  getConsultationTemplateListApi,
  getMedicalFillInFormEmpty,
  getMedicalFillInFormTabList,
  medicalFillInFormToggleMenu,
  onSaveMedicalFillInFormItem,
  pushStack,
  resetConsultationId,
  updateStack,
  saveConsultationInitialExaminationItem,
  setActiveTab,
  getMedicalFormConsultationInitialExaminationItem,
  disableInitialExaminationTabToggle,
  openTextarea,
  medicalTypeFilterHandler,
  getMedicalFillInFormTemplateList,
  getMedicalFillInFormHistoryTabList,
} from "../../../../actions/chartSideMedicalFillInFormActions";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";

import MedicalFillInFormHeader from "./MedicalFillInFormHeader";
import Show from "../../../widgets/Show";
import MedicalFIllInFormEntryPage from "./MedicalFIllInFormEntryPage";
import MedicalFIllInFormItem from "./MedicalFIllInFormItem";
import PatientFormsList from "../../../patient/PatientFormsList";
import PatientMedicalAlertsEdit from "../../../patient/alerts/PatientMedicalAlertsEdit";
import MedicalFillInformTreatment from "./MedicalFillInformTreatment";
import MedicalFillInFormDiagnosis from "./MedicalFillInFormDiagnosis";
import MedicationList from "../../../medication/MedicationList";
import ChartOrder from "../../../order/ChartOrder";
import BackImage from "../../../../assets/images/white-icon-back.png";
import MedicalFillInFormConsentFormView from "./MedicalFillInFormConsentFormView";
import MedicalFillInFormConsentFormEdit from "./MedicalFillInFormConsentFormEdit";
import MedicalFillInFormPostOp from "./MedicalFillInFormPostOp";
import MedicalFillInFormTreatmentComplete from "./MedicalFillInFormTreatmentComplete";
import MedicalFillInFormVitals from "./MedicalFillInFormVitals";
import MedicalFillInFormVitalsCreateForm from "./MedicalFillInFormVitalsCreateForm";
import { RESET_MEDICAL_FILL_IN_FORM_FOR } from "../../../../actionTypes/medicalFillInFormActionTypes";
import { createUrl, parseQuery } from "../../../../utils/UrlUtils";
import MedicalFormsTextArea from "./MedicalFormsTextArea";
import { Sidebar } from "../../../sidebar/Sidebar";
import MedicalFormsAddendumList from "./MedicalFormsAddendumList";
import MedicalFormsAddendumItem from "./MedicalFormsAddendumItem";
import MedicalFillInFormProvisionalDiagnosis from "./MedicalFillInFormProvisionalDiagnosis";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";

const enhancer = connect(
  ({
    chartSideMedicalFillInForm,
    transaction,
    insurance,
    chart,
    session: {
      permissions,
      miscSettings: { initialExaminationRequireApproval },
      clinic: { id: clinicId, name: clinicName },
    },
  }) => ({
    chartSideMedicalFillInForm,
    chart,
    clinicId,
    clinicName,
    permissions,
    images: insurance.images,
    diagnosisClaimTypeList: transaction.diagnosisClaimTypeList,
    initialExaminationRequireApproval,
    provisionalDiagnosisList: transaction.provisionalDiagnosisList,
    fetchingTransactionList: transaction.fetchingProvisionalList,
  }),
);
let canUserEdit = false;

const MedicalFillInForm = (props) => {
  const {
    chartSideMedicalFillInForm,
    diagnosisClaimTypeList,
    chart,
    location,
    history,
    patientKey,
    clinicId,
    permissions,
    images,
    clinicName,
    initialExaminationRequireApproval,
    provisionalDiagnosisList,
    fetchingTransactionList,
  } = props;
  const {
    stack,
    item,
    templateTabList,
    activeTab,
    consultationId,
    treatmentList,
    treatmentListFetching,
    diagnosisList,
    diagnosisListFetching,
    medicalFillInFormMenuVisible,
    patientVitalTransactionFetching,
    initialExaminationItem,
    initialExaminationConsultation,
    isInitialExaminationTabDisable,
    medicalTypeFilter,
  } = chartSideMedicalFillInForm;

  const dispatch = useDispatch();
  const [form, setForm] = useState(item);
  const [initialExaminationForm, setInitialExaminationForm] = useState(
    initialExaminationConsultation,
  );
  const stackItem = _.last(stack);
  const isEditMode =
    stackItem?.type === medicalFillInFormItemTypes.edit ||
    stackItem?.type === medicalFillInFormItemTypes.new;
  const query = parseQuery(location?.search);

  useEffect(() => {
    dispatch(getMedicalFillInFormTabList());
    dispatch(getMedicalFillInFormEmpty());
    return () => {
      dispatch(resetConsultationId());
      dispatch(updateStack({ code: medicalFillInFormStackCodes.entry }));
      dispatch({ type: RESET_MEDICAL_FILL_IN_FORM_FOR });
      history.replace(
        createUrl(location?.pathname, { query: { ...query, medicalType: undefined } }),
      );
    };
  }, []);

  useEffect(() => {
    if (stackItem?.code === medicalFillInFormStackCodes.entry && isInitialExaminationTabDisable) {
      dispatch(disableInitialExaminationTabToggle(false));
    }
  }, [stackItem]);

  useEffect(() => {
    if (stackItem.code === medicalFillInFormStackCodes.entry) {
      if (medicalTypeFilter !== "new") {
        dispatch(medicalTypeFilterHandler("new"));
      }

      if (chart.appointment?.id) {
        dispatch(
          getConsultationTemplateListApi({ appointmentId: chart.appointment.id, patientKey }),
        )
          .then((response) => {
            canUserEdit = !response?.consultationId;
          })
          .catch(() => {
            canUserEdit = true;
          });
      }
    }
  }, [stackItem]);

  const onSaveItem = () => {
    if (form.questions.length) {
      const textHiddentInputs = document.querySelectorAll(".hidden-pick-list-comment") || [];

      form.questions.forEach((question, questionIndex) => {
        if (question.typeCode.replace(/[^a-z]/gi, "") === questionTypeCodes.PICKLIST) {
          (question.options || []).forEach((option) => {
            (textHiddentInputs || []).forEach((textarea) => {
              const ids = textarea.dataset.qnaId.split("-");
              const questionId = Number(ids.at(0) || 0);
              const optionId = Number(ids.at(1) || 0);
              const comment = textarea.value;
              if (question.id === questionId && option.id === optionId) {
                question.options.forEach((option, optionIndex) => {
                  if (option.id === optionId) {
                    form.questions[questionIndex].options[optionIndex].comment = comment;
                  }
                });
              }
            });
          });
        }
      });

      dispatch(
        onSaveMedicalFillInFormItem({
          ...form,
          consultationId,
          appointmentId: chart.appointment?.id,
          patientKey,
        }),
      );
    }
    if (initialExaminationForm.questions.length && activeTab.isInitialExamination) {
      dispatch(
        saveConsultationInitialExaminationItem({
          ...initialExaminationForm,
          consultationId,
          appointmentId: chart.appointment?.id,
          patientKey,
        }),
      );
    }
  };

  const consultationStartHandler = () => {
    const { templateTabList } = chartSideMedicalFillInForm;
    const isEntryPage =
      stackItem.code === medicalFillInFormStackCodes.entry && activeTab.isInitialExamination;
    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          medicalType: undefined,
        },
      }),
    );
    dispatch(getMedicalFillInFormTabList(true));
    dispatch(getMedicalFillInFormTemplateList());
    if (
      isEntryPage &&
      initialExaminationRequireApproval &&
      _.get(initialExaminationItem, "approved", true)
    ) {
      if (templateTabList?.length) {
        dispatch(setActiveTab(templateTabList[1]));
      }
    }

    const initialExaminationTab = (templateTabList || []).find((tab) =>
      Boolean(tab.isInitialExamination),
    );
    if (
      initialExaminationRequireApproval &&
      !_.get(initialExaminationItem, "approved", true) &&
      initialExaminationItem.consultationId
    ) {
      return dispatch(
        openSimpleModal({
          body: "Please approve initial examination!",
          footer: (
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-success ml-3"
                onClick={() => {
                  dispatch(setActiveTab(initialExaminationTab));
                  dispatch(closeSimpleModal());
                  dispatch(getMedicalFormConsultationInitialExaminationItem());
                  dispatch(
                    pushStack({
                      code: medicalFillInFormStackCodes.ownedList,
                      type: medicalFillInFormItemTypes.view,
                    }),
                  );
                }}
              >
                Ok
              </button>
            </div>
          ),
        }),
      );
    } else {
      dispatch(
        pushStack({
          code: medicalFillInFormStackCodes.item,
          type: medicalFillInFormItemTypes.new,
        }),
      );
    }
  };

  return (
    <div className="medical-fill-in-form">
      <Sidebar
        fullScreen={chartSideMedicalFillInForm.fullScreen}
        show={chartSideMedicalFillInForm.openTextArea}
        backdrop={"static"}
        keyboard={false}
        shouldCloseOnEsc={false}
        onHide={() => dispatch(openTextarea(false))}
        dialogClassName="chart-side-bar"
      >
        <MedicalFormsTextArea />
      </Sidebar>
      <MedicalFillInFormHeader
        chartSideMedicalFillInForm={chartSideMedicalFillInForm}
        stackItem={stackItem}
        templateTabList={templateTabList}
        activeTab={activeTab}
        onSaveItem={onSaveItem}
        form={form}
        patientKey={patientKey}
        initialExaminationRequireApproval={initialExaminationRequireApproval}
      />
      <div
        className={`medical-fill-in-form__body ${
          !medicalFillInFormMenuVisible ? "menu-visible" : ""
        }`}
      >
        {/*left side*/}
        <div className="medical-fill-in-form__body__left-side">
          <div className="menu-toggler" onClick={() => dispatch(medicalFillInFormToggleMenu())}>
            <img
              src={BackImage}
              style={!medicalFillInFormMenuVisible ? { transform: `rotate(180deg)` } : {}}
              width={12}
              alt=""
            />
          </div>
          {/*<button*/}
          {/*  className="btn btn-default"*/}
          {/*  type="button"*/}
          {/*  onClick={() => dispatch(medicalFillInFormToggleMenu())}*/}
          {/*>*/}
          {/*  {!medicalFillInFormMenuVisible ? "Show menu" : "Hide menu"}*/}
          {/*</button>*/}
          <Show if={stackItem.code === medicalFillInFormStackCodes.entry}>
            <button
              onClick={() => {
                dispatch(getMedicalFillInFormHistoryTabList());
                dispatch(medicalTypeFilterHandler("view"));

                dispatch(disableInitialExaminationTabToggle(true));
                dispatch(
                  pushStack({
                    code: medicalFillInFormStackCodes.ownedList,
                    type: medicalFillInFormItemTypes.view,
                  }),
                );
              }}
              className="btn btn-primary btn-block"
            >
              <span>View Historical Forms/Consults</span>
            </button>
          </Show>
          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.ownedList &&
              stackItem?.type === medicalFillInFormItemTypes.view &&
              activeTab.isInitialExamination
            }
          >
            <MedicalFillInFormInitialExaminationList
              history={history}
              patientKey={patientKey}
              location={location}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
              initialExaminationRequireApproval={initialExaminationRequireApproval}
              initialExaminationItem={initialExaminationItem}
              initialExaminationForm={initialExaminationForm}
              setInitialExaminationForm={setInitialExaminationForm}
              onSaveItem={onSaveItem}
            />
          </Show>
          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.ownedList &&
              stackItem?.type === medicalFillInFormItemTypes.view
              // ((stackItem.code === medicalFillInFormStackCodes.consentForms ||
              //   stackItem.code === medicalFillInFormStackCodes.medicalAlerts ||
              //   stackItem.code === medicalFillInFormStackCodes.treatment ||
              //   stackItem.code === medicalFillInFormStackCodes.treatmentComplete ||
              //   stackItem.code === medicalFillInFormStackCodes.diagnosis ||
              //   stackItem.code === medicalFillInFormStackCodes.prescription ||
              //   stackItem.code === medicalFillInFormStackCodes.order ||
              //   stackItem.code === medicalFillInFormStackCodes.postOp ||
              //   stackItem.code === medicalFillInFormStackCodes.vitals ||
              //   stackItem.code === medicalFillInFormStackCodes.vitalsCreateForm) &&
              //   stackItem?.type === medicalFillInFormItemTypes.view)
            }
          >
            <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
              <div style={{ height: "49%" }}>
                <MedicalFillInFormOwnedList
                  permissions={permissions}
                  history={history}
                  location={location}
                  patientKey={patientKey}
                  chartSideMedicalFillInForm={chartSideMedicalFillInForm}
                />
              </div>
              <Show
                if={
                  stackItem.code !== medicalFillInFormStackCodes.ownedList &&
                  (stackItem?.type === medicalFillInFormItemTypes.view ||
                    stackItem?.type === medicalFillInFormItemTypes.edit)
                }
              >
                <div style={{ height: "49%" }}>
                  <MedicalFillInFormList
                    history={history}
                    patientKey={patientKey}
                    location={location}
                    chartSideMedicalFillInForm={chartSideMedicalFillInForm}
                    showOnlyHardcodedList={true}
                    initialExaminationRequireApproval={initialExaminationRequireApproval}
                    initialExaminationItem={initialExaminationItem}
                    onSaveItem={onSaveItem}
                  />
                </div>
              </Show>
            </div>
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.item ||
              stackItem.code === medicalFillInFormStackCodes.consentForms ||
              stackItem.code === medicalFillInFormStackCodes.medicalAlerts ||
              stackItem.code === medicalFillInFormStackCodes.treatment ||
              stackItem.code === medicalFillInFormStackCodes.drugTreatment ||
              stackItem.code === medicalFillInFormStackCodes.diagnosis ||
              stackItem.code === medicalFillInFormStackCodes.prescription ||
              stackItem.code === medicalFillInFormStackCodes.order ||
              stackItem.code === medicalFillInFormStackCodes.postOp ||
              stackItem.code === medicalFillInFormStackCodes.treatmentComplete ||
              stackItem.code === medicalFillInFormStackCodes.vitals ||
              stackItem.code === medicalFillInFormStackCodes.vitalsCreateForm ||
              stackItem.code === medicalFillInFormStackCodes.labOrder ||
              stackItem.code === medicalFillInFormStackCodes.radiologyOrder ||
              stackItem.code === medicalFillInFormStackCodes.provisionalDiagnosis
            }
          >
            <Show if={activeTab.name !== "Addendums"}>
              <MedicalFillInFormList
                history={history}
                patientKey={patientKey}
                location={location}
                chartSideMedicalFillInForm={chartSideMedicalFillInForm}
                initialExaminationRequireApproval={initialExaminationRequireApproval}
                initialExaminationItem={initialExaminationItem}
                onSaveItem={onSaveItem}
              />
            </Show>
            <Show if={activeTab.name === "Addendums"}>
              <MedicalFormsAddendumList chartSideMedicalFillInForm={chartSideMedicalFillInForm} />
            </Show>
          </Show>
        </div>
        {/*right side*/}
        <div className="medical-fill-in-form__body__right-side">
          <Show if={stackItem.code === medicalFillInFormStackCodes.entry}>
            <div className="d-flex align-items-end justify-content-end">
              <button
                disabled={!canUserEdit}
                onClick={consultationStartHandler}
                className="btn btn-default mt-2"
              >
                <span>Click here to start FORM/Consult</span>
              </button>
            </div>
            <MedicalFIllInFormEntryPage />
          </Show>

          <Show
            if={
              activeTab.name !== "Addendums" &&
              stackItem?.type === medicalFillInFormItemTypes.view &&
              !activeTab.isInitialExamination
            }
          >
            <MedicalFillInFormView
              history={history}
              location={location}
              permissions={permissions}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
              clinicName={clinicName}
              patientKey={patientKey}
            />
          </Show>

          <Show if={activeTab.name === "Addendums"}>
            <MedicalFormsAddendumItem chartSideMedicalFillInForm={chartSideMedicalFillInForm} />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.item &&
              (stackItem?.type === medicalFillInFormItemTypes.new ||
                stackItem?.type === medicalFillInFormItemTypes.edit ||
                stackItem?.type === medicalFillInFormItemTypes.view)
            }
          >
            <MedicalFIllInFormItem
              patient={chart.patient}
              images={images}
              clinicName={clinicName}
              onSaveItem={onSaveItem}
              setForm={setForm}
              form={form}
              editMode={isEditMode}
              history={history}
              location={location}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
            />
          </Show>

          {/*<Show*/}
          {/*  if={*/}
          {/*    stackItem.code === medicalFillInFormStackCodes.item &&*/}
          {/*    stackItem?.type === medicalFillInFormItemTypes.new*/}
          {/*  }*/}
          {/*>*/}
          {/*  <MedicalFIllInFormItem*/}
          {/*    patient={chart.patient}*/}
          {/*    images={images}*/}
          {/*    clinicName={clinicName}*/}
          {/*    onSaveItem={onSaveItem}*/}
          {/*    setForm={setForm}*/}
          {/*    form={form}*/}
          {/*    editMode={true}*/}
          {/*    history={history}*/}
          {/*    location={location}*/}
          {/*    chartSideMedicalFillInForm={chartSideMedicalFillInForm}*/}
          {/*  />*/}
          {/*</Show>*/}
          <Show
            if={
              stackItem?.type === medicalFillInFormItemTypes.view && activeTab.isInitialExamination
            }
          >
            <MedicalFillInFormInitialExaminationItem
              images={images}
              editMode={false}
              history={history}
              location={location}
              patient={chart.patient}
              onSaveItem={onSaveItem}
              clinicName={clinicName}
              permissions={permissions}
              patientKey={patientKey}
              initialExaminationForm={initialExaminationForm}
              setInitialExaminationForm={setInitialExaminationForm}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
            />
          </Show>
          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.item &&
              stackItem?.type === medicalFillInFormItemTypes.new &&
              activeTab.isInitialExamination
            }
          >
            <MedicalFillInFormInitialExaminationItem
              images={images}
              editMode={true}
              history={history}
              location={location}
              patient={chart.patient}
              onSaveItem={onSaveItem}
              clinicName={clinicName}
              permissions={permissions}
              patientKey={patientKey}
              initialExaminationForm={initialExaminationForm}
              setInitialExaminationForm={setInitialExaminationForm}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
            />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.item &&
              stackItem?.type === medicalFillInFormItemTypes.edit &&
              activeTab.isInitialExamination
            }
          >
            <MedicalFillInFormInitialExaminationItem
              images={images}
              editMode={true}
              history={history}
              location={location}
              patient={chart.patient}
              onSaveItem={onSaveItem}
              clinicName={clinicName}
              permissions={permissions}
              patientKey={patientKey}
              initialExaminationForm={initialExaminationForm}
              setInitialExaminationForm={setInitialExaminationForm}
              chartSideMedicalFillInForm={chartSideMedicalFillInForm}
            />
          </Show>

          <Show if={stackItem.code === medicalFillInFormStackCodes.prescription}>
            <MedicationList location={location} medicalForms={true} />
          </Show>

          <Show if={stackItem.code === medicalFillInFormStackCodes.order}>
            <ChartOrder medicalForms={true} />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.consentForms &&
              stackItem?.type === medicalFillInFormItemTypes.list
            }
          >
            <PatientFormsList medicalForms={true} />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.consentForms &&
              stackItem?.type === medicalFillInFormItemTypes.view
            }
          >
            <MedicalFillInFormConsentFormView />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.consentForms &&
              stackItem?.type === medicalFillInFormItemTypes.edit
            }
          >
            <MedicalFillInFormConsentFormEdit location={location} history={history} />
          </Show>

          <Show if={stackItem.code === medicalFillInFormStackCodes.medicalAlerts}>
            <PatientMedicalAlertsEdit
              medicalForms={true}
              location={location}
              history={history}
              stackItem={stackItem}
              medicalFillInFormItemTypes={medicalFillInFormItemTypes}
            />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.treatment ||
              stackItem.code === medicalFillInFormStackCodes.drugTreatment
            }
          >
            <MedicalFillInformTreatment
              chart={chart}
              clinicId={clinicId}
              patientKey={patientKey}
              treatmentList={treatmentList}
              fetching={treatmentListFetching}
              permissions={permissions}
              history={history}
            />
          </Show>
          <Show if={stackItem.code === medicalFillInFormStackCodes.treatmentComplete}>
            <MedicalFillInFormTreatmentComplete
              chart={chart}
              clinicId={clinicId}
              onSaveItem={onSaveItem}
              patientKey={patientKey}
              treatmentList={treatmentList}
              fetching={treatmentListFetching}
              permissions={permissions}
              history={history}
            />
          </Show>

          <Show if={stackItem.code === medicalFillInFormStackCodes.diagnosis}>
            <MedicalFillInFormDiagnosis
              chart={chart}
              clinicId={clinicId}
              permissions={permissions}
              diagnosisList={diagnosisList}
              fetching={diagnosisListFetching}
              diagnosisClaimTypeList={diagnosisClaimTypeList}
            />
          </Show>

          <Show if={stackItem.code === medicalFillInFormStackCodes.provisionalDiagnosis}>
            <MedicalFillInFormProvisionalDiagnosis
              chart={chart}
              clinicId={clinicId}
              permissions={permissions}
              diagnosisList={provisionalDiagnosisList}
              fetching={fetchingTransactionList}
              diagnosisClaimTypeList={diagnosisClaimTypeList}
            />
          </Show>
          <Show if={stackItem.code === medicalFillInFormStackCodes.postOp}>
            <MedicalFillInFormPostOp history={history} location={location} />
          </Show>
          <Show if={stackItem.code === medicalFillInFormStackCodes.vitals}>
            <MedicalFillInFormVitals
              chart={chart}
              clinicId={clinicId}
              patientKey={patientKey}
              fetching={patientVitalTransactionFetching}
              permissions={permissions}
              history={history}
              stackItem={stackItem}
              medicalFillInFormItemTypes={medicalFillInFormItemTypes}
            />
          </Show>
          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.vitalsCreateForm &&
              stackItem?.type === medicalFillInFormItemTypes.new
            }
          >
            <MedicalFillInFormVitalsCreateForm
              chart={chart}
              clinicId={clinicId}
              patientKey={patientKey}
              treatmentList={treatmentList}
              fetching={treatmentListFetching}
              permissions={permissions}
              history={history}
              location={location}
              mode={"add"}
            />
          </Show>

          <Show
            if={
              stackItem.code === medicalFillInFormStackCodes.vitalsCreateForm &&
              stackItem?.type === medicalFillInFormItemTypes.edit
            }
          >
            <MedicalFillInFormVitalsCreateForm
              chart={chart}
              clinicId={clinicId}
              patientKey={patientKey}
              treatmentList={treatmentList}
              fetching={treatmentListFetching}
              permissions={permissions}
              history={history}
              location={location}
              mode={"edit"}
            />
          </Show>
        </div>
      </div>
    </div>
  );
};

export default enhancer(MedicalFillInForm);
