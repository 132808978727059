import React from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import { Modal } from "../../ui/Modal";
import { TextField } from "../../form/TextField";
import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";

const enhancer = connect(null, bindActions({ calendarActions }));

class PatientDetailsEdit extends React.PureComponent {
  render() {
    const { sendToEmailModalVisible } = this.props;

    return (
      <Formik
        initialValues={{ email: "" }}
        onSubmit={({ email }) => this.props.calendarActions.sendDetailsToEmail(email)}
      >
        {({ handleSubmit }) => (
          <Modal
            size="small"
            show={sendToEmailModalVisible}
            keyboard={false}
            onHide={this.props.calendarActions.sendDetailsToEmailModalHide}
            className="calendar-print-details-modal"
            title={<div className="modal-title text-center">Send To Email</div>}
            actions={
              <div>
                <Button
                  type="button"
                  onClick={this.props.calendarActions.sendDetailsToEmailModalHide}
                >
                  Close
                </Button>
                <Button type="button" onClick={handleSubmit} className="btn btn-primary">
                  OK
                </Button>
              </div>
            }
          >
            <Form>
              <div className="form-group">
                <TextField name="email" label="Please enter email" />
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default enhancer(PatientDetailsEdit);
