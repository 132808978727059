export const SET_POPUP_PARAMS = "SET_POPUP_PARAMS";
export const SET_POPUP_ACTION_BY_KEY = "SET_POPUP_ACTION_BY_KEY";
export const SET_POPUP = "SET_POPUP";

export function setPopupParams(popupType, value, visible, titleValue, buttons, preventClosing) {
  return (dispatch) =>
    dispatch({
      type: SET_POPUP_PARAMS,
      popupType,
      value,
      visible,
      titleValue,
      buttons,
      preventClosing,
    });
}
export function setPopupActionByKey(popupType, key, value) {
  return (dispatch) =>
    dispatch({
      type: SET_POPUP_ACTION_BY_KEY,
      popupType,
      key,
      value,
    });
}
export function setPopup(popupType, popup) {
  return (dispatch) =>
    dispatch({
      type: SET_POPUP,
      popupType,
      popup,
    });
}
