import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getPatientNotes } from "../../actions/appointmentActions";
import { createPatientNote } from "../../actions/patientViewActions";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { patientNotesSelector } from "../../reducers/appointmentReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import {
  AppointmentPatientViewModal,
  AppointmentPatientViewModalProps,
} from "./AppointmentPatientViewModal";

interface Props extends Omit<AppointmentPatientViewModalProps, "notes" | "onAdd"> {
  readonly patientKey: string;
}

export function AppointmentPatientViewModalWrapper({ patientKey, ...props }: Props) {
  const dispatch = useDispatch();

  const notes = useShallowEqualSelector(patientNotesSelector as any);

  useEffect(() => {
    dispatch(
      getPatientNotes({
        patientKey,
        limit: 25,
      }),
    );
  }, []);

  return (
    <AppointmentPatientViewModal
      {...props}
      notes={notes}
      onAdd={(values, helpers) => {
        dispatch(startLoader());

        dispatch(createPatientNote({ patientKey, ...values }))
          .then(() =>
            dispatch(
              getPatientNotes({
                patientKey,
                limit: 25,
              }),
            ),
          )
          .then(() => {
            helpers.resetForm();

            dispatch(stopLoader());
          })
          .catch((e) => dispatch(stopLoader(e)));
      }}
    />
  );
}
