import React from "react";
import { connect } from "react-redux";

import ChartFillInFormTextareaForm from "./ChartFillInFormTextareaForm";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import * as simpleModalActions from "../../../../actions/simpleModalActions";
import exitBlackImage from "../../../../assets/images/chart/ic_fullscreen_exit_black_24dp_1x.png";
import exitImage from "../../../../assets/images/chart/ic_fullscreen_black_24dp_1x.png";
import { SidebarHeader, SidebarHeaderType } from "../../../sidebar/SidebarHeader";

const enhancer = connect(null, bindActions({ chartSideFillInFormActions, simpleModalActions }));
export default enhancer(
  class ChartFillInFormTextarea extends React.Component {
    componentDidMount() {
      if (!this.props.edit) {
        this.props.chartSideFillInFormActions.initTextarea();
      }
    }
    render() {
      const {
        edit,
        chartSideFillInForm: { item, fullScreen },
      } = this.props;

      return (
        <div className="chart-clinical-note">
          <div className="clinical-note-item clinical-note-item-slime">
            <SidebarHeader
              buttonWidth={115}
              type={SidebarHeaderType.Secondary}
              rightComponent={
                <div className="d-flex align-items-center pr-2 justify-content-between">
                  <span>#{item.patient && item.patient.chartNumber}</span>
                  <button
                    type="button"
                    title="full screen"
                    onClick={() => this.props.chartSideFillInFormActions.fullScreen(!fullScreen)}
                    className="pull-right btn-link"
                  >
                    <img src={fullScreen ? exitBlackImage : exitImage} alt="" />
                  </button>
                </div>
              }
              title="ADD FILL IN FORM"
              onBackClick={() => {
                this.props.simpleModalActions.openSimpleModal({
                  body: "Do you want to save your changes?",
                  footer: (
                    <div className="text-right">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          this.props.simpleModalActions.closeSimpleModal();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          this.props.chartSideFillInFormActions.setFillInFormAddendumId(0);
                          this.props.simpleModalActions.closeSimpleModal();
                          this.props.chartSideFillInFormActions.popStack();
                        }}
                      >
                        No
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.chartSideFillInFormActions.setFillInFormAddendumId(0);
                          this.props.simpleModalActions.closeSimpleModal();
                          this.props.chartSideFillInFormActions.submitTextareaForm();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  ),
                });
              }}
            />
            <div className="clinical-note-item-edit-body">
              <ChartFillInFormTextareaForm
                changeCreatedDate={this.props.chartSideFillInFormActions.changeCreatedDate}
                onSubmit={this.props.chartSideFillInFormActions.submitTextareaForm}
                item={item}
                edit={edit}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
