import React from "react";

import Show from "../widgets/Show";
import ArrowLeftImage from "../../assets/images/arrows/arrow_left.png";
import ArrowLeftAvtiveImage from "../../assets/images/arrows/arrow_left_active.png";
import ArrowRightImage from "../../assets/images/arrows/arrow_right.png";
import ArrowRightAvtiveImage from "../../assets/images/arrows/arrow_right_active.png";

export default class MemberCarousel extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      offset: 0,
    };
  }

  prev = () => {
    this.setState((previousState) => {
      if (previousState.offset > 0) {
        return { offset: previousState.offset - 1 };
      } else {
        return previousState;
      }
    });
  };

  next = () => {
    this.setState((previousState, currentProps) => {
      const extraRows = Math.ceil(currentProps.children.length / 3) - 3 > previousState.offset;

      if (extraRows) {
        return { offset: previousState.offset + 1 };
      } else {
        return previousState;
      }
    });
  };

  render() {
    const style = {
      marginLeft: this.state.offset * -117,
    };

    const many = this.props.children.length > 9;

    const extraRows = Math.ceil(this.props.children.length / 3) - 3 > this.state.offset;
    const prevSrc = this.state.offset ? ArrowLeftAvtiveImage : ArrowLeftImage;
    const nextSrc = extraRows ? ArrowRightAvtiveImage : ArrowRightImage;

    return (
      <div className="member-carousel">
        <Show if={many}>
          <div className="member-carousel-prev" onClick={this.prev}>
            <img src={prevSrc} alt="" />
          </div>
        </Show>
        <div className="member-carousel-screen">
          <div className="member-carousel-wrapper" style={style}>
            {this.props.children}
          </div>
        </div>
        <Show if={many}>
          <div className="member-carousel-next" onClick={this.next}>
            <img src={nextSrc} alt="" />
          </div>
        </Show>
      </div>
    );
  }
}
