import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import Show from "../widgets/Show";
import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import LogoImage from "../../assets/images/clinic_logo.png";
import NewsImage from "../../assets/images/dashboard/news.png";
import { initDashboard } from "../../actions/dashboardActions";
import { resetCalendar, selectClinicId } from "../../actions/calendarActions";
import DashboardNewsImage from "../../assets/images/dashboard/news_image.png";
import DashboardReportsImage from "../../assets/images/dashboard/reports.png";
import DashboardPatientsImage from "../../assets/images/dashboard/patients.png";
import DashboardAppointmentsImage from "../../assets/images/dashboard/dashboard_appointments.png";
import { obtainImagePath } from "../../utils/ImageUtils";

const enhancer = connect(
  ({ session, dashboard, calendar: { clinicId: calendarClinicId } }) => ({
    session,
    dashboard,
    calendarClinicId,
  }),
  { initDashboard, selectClinicId, resetCalendar },
);

class Dashboard extends React.Component {
  componentDidMount() {
    const { session, selectClinicId, calendarClinicId } = this.props;

    const clinicId = _.get(session, "clinic.id");
    const permissions = _.get(session, "permissions", []);
    const showClinicSwitcher = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_CLINIC_FILTER") >= 0;
    this.props.resetCalendar();
    if (showClinicSwitcher && calendarClinicId === 0) {
      selectClinicId(clinicId);
    }

    this.props.initDashboard();
  }

  componentWillUnmount() {
    localStorage.removeItem("clinicId");
  }

  render() {
    const $news = this.props.dashboard.news.map((newsItem, index) => {
      return (
        <a
          href={newsItem.redirectUrl}
          rel="noreferrer noopener"
          target="_blank"
          className="item"
          key={index}
        >
          <div className="content">{newsItem.content}</div>
          <div className="date">{moment(newsItem.createdDate).format("DD/MM/YYYY")}</div>
        </a>
      );
    });
    return (
      <div className="dashboard-page" style={{ paddingTop: "75px" }}>
        <div
          className="patient-list-title clearfix"
          style={{
            margin: "-75px -20px 20px",
            height: "55px",
          }}
        >
          <div className="page-title" style={{ lineHeight: "60px" }}>
            {(this.props.session.clinic && this.props.session.clinic.name) || "Clinic"}
          </div>
          <img alt="" src={LogoImage} className="logo" />
        </div>
        <div className="boxes clearfix">
          <div className="left-group">
            <Show if={(this.props.session.permissions || []).indexOf("VIEW_PATIENT_LIST") >= 0}>
              <div className="box patients">
                <div className="top">
                  <Link to={Routes.PatientList} className="left">
                    <div className="text">All</div>
                    <div className="number">{this.props.dashboard.patientAllActiveCount || 0}</div>
                    <div className="click">click here</div>
                  </Link>
                  <Link
                    to={createUrl(Routes.PatientList, { query: { onlyNew: true } })}
                    className="right"
                  >
                    <div className="text">New</div>
                    <div className="number">{this.props.dashboard.patientNewForTodayCount}</div>
                    <div className="click">click here</div>
                  </Link>
                </div>
                <div className="bottom">
                  <div className="title">Patients</div>
                  <div className="icon">
                    <img src={DashboardPatientsImage} />
                    <div
                      onClick={() => this.props.history.push(Routes.PatientMobile)}
                      style={{
                        display: "inline-flex",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        border: "2px solid rgba(255,255,255,0.7)",
                        color: "rgba(255,255,255,0.7)",
                        fontWeight: "bold",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        cursor: "pointer",
                        lineHeight: 1,
                        marginLeft: "1rem",
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            </Show>

            <div className="box appointments">
              <div className="top">
                <Link to={Routes.DashboardCalendar} className="left" style={{ cursor: "pointer" }}>
                  <div className="text">Scheduled today</div>
                  <div className="number">{this.props.dashboard.appointmentForTodayCount}</div>

                  <div className="click">click here</div>
                </Link>
                <div className="right">
                  <div className="text">in waiting list</div>
                  <div className="number">{this.props.dashboard.appointmentInWaitingCount}</div>
                  <div className="click">click here</div>
                </div>
              </div>
              <div className="bottom">
                <div className="title">Appointments</div>
                <div className="icon">
                  <img src={DashboardAppointmentsImage} />
                </div>
              </div>
            </div>

            <Show
              if={
                (this.props.session.permissions || []).indexOf("VIEW_DOCTOR_PRODUCTION_REPORT") >= 0
              }
            >
              <Link to={Routes.DashboardDoctorProductionReport} className="box report">
                <div className="top">
                  <div className="right">
                    <div className="text">
                      {localStorage.getItem("doctorLabel")} Production Report
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="title">Report</div>
                  <div className="icon">
                    <img alt="" src={DashboardReportsImage} />
                  </div>
                </div>
              </Link>
            </Show>
          </div>
          <div className="news">
            <div className="head">
              <img alt="" src={obtainImagePath(DashboardNewsImage)} />
            </div>
            <div className="body">{$news}</div>
            <div className="bottom">
              <div className="title">News</div>
              <div className="icon">
                <img alt="" src={NewsImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(enhancer(Dashboard));
