import React from "react";
import _, { toFinite } from "lodash";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";

import Show from "../../../widgets/Show";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePlanActions from "../../../../actions/chartSidePlanActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";
import { openSimpleModal } from "../../../../actions/simpleModalActions";
import { startLoader, stopLoader } from "../../../../actions/loaderActions";
import { Modal } from "../../../ui/Modal";
import { SelectPicker } from "../../../select-picker/SelectPicker";
import { TextInput } from "../../../ui/TextInput";
import { Button } from "react-bootstrap";

const valueSelector = formValueSelector("chartPlanForName");

const form = reduxForm({
  form: "chartPlanForName",
  enableReinitialize: true,
});
const enhancer = connect((state) => {
  const member = state.auth.members[state.auth.chosenMemberId];

  return {
    canSetDiscount: member && !member.discountDisabled,
    values: valueSelector(state, "discountType", "discountValue"),
    chartSidePlan: state.chartSidePlan,
    chart: state.chart,
    auth: state.auth,
    session: state.session,
  };
}, bindActions({ chartSidePlanActions, chartSideTransactionActions, openSimpleModal, startLoader, stopLoader }));

class ChartPlanForName extends React.Component {
  constructor() {
    super();

    this.state = {
      showTextAriaModal: false,
      desiredBillAmount: null,
    };
  }

  hideModal = () => {
    this.setState({ showTextAriaModal: false });
  };

  showModal = () => {
    this.setState({ showTextAriaModal: true });
  };

  updateDiscount = () => {
    const {
      chartSidePlan: { selectedFor },
      chartSideTransactionActions,
      values: { discountType, discountValue },
      callback,
      reset,
    } = this.props;

    const { updateTransactionDiscount } = chartSideTransactionActions;
    const keys = _.values(selectedFor)
      .filter((x) => x)
      .map((x) => x.key);
    updateTransactionDiscount(
      {
        keys,
        discount: {
          code: discountType,
          value: discountValue,
        },
      },
      () => {
        callback();

        reset();
      },
    );
  };

  componentDidUpdate(prevProps) {
    const { values: prevValues } = prevProps;
    const { values: nextValues, dispatch } = this.props;

    const nextDiscountType = _.get(nextValues, "discountType");
    const prevDiscountType = _.get(prevValues, "discountType");

    if (nextDiscountType !== prevDiscountType) {
      dispatch(change("chartPlanForName", "discountValue", 0));
    }
  }

  desiredBillAmountCalculateHandler() {
    const { listFor, selectedFor } = this.props.chartSidePlan;
    const {
      auth: { members },
      session: { member },
      openSimpleModal,
    } = this.props;
    const currentMember = members[member.id];
    const memberDiscountLimit = currentMember.discountPercentLimit;
    const { desiredBillAmount } = this.state;

    const filteredTreatmentList = listFor.filter(Boolean);

    if (desiredBillAmount > 0) {
      const totalPrice = filteredTreatmentList.reduce((acc, treatment) => {
        if (selectedFor[treatment.key]) {
          return acc + Number(treatment.price);
        }
        return acc;
      }, 0);

      let canUpdatePrice = false;

      filteredTreatmentList.forEach((treatment) => {
        if (selectedFor[treatment.key]) {
          const priceWeight = (Number(treatment.price) / totalPrice) * 100;
          const treatmentPrice = priceWeight * 0.01 * Number(desiredBillAmount);
          const treatmentPricePercentage =
            ((treatment.price - treatmentPrice) / treatment.price) * 100;
          if (treatmentPricePercentage > memberDiscountLimit) {
            openSimpleModal({ body: `Allowed discount is ${memberDiscountLimit}%` });
            canUpdatePrice = false;
            return;
          }
          treatment.updatedPrice = Math.round(treatmentPrice);
          treatment.updatedDiscount = treatmentPricePercentage;
          canUpdatePrice = true;
        }
      });
      debugger;
      if (canUpdatePrice) {
        this.props.startLoader();

        const promises = [];
        filteredTreatmentList.forEach((treatment) => {
          const data = {
            keys: [treatment.key],
            discount: {
              code: "PERCENTAGE",
              value: treatment.updatedDiscount,
            },
          };
          if (toFinite(treatment.updatedDiscount)) {
            promises.push(
              this.props.chartSideTransactionActions.updateTransactionDiscountApi(data),
            );
          }
        });

        Promise.all(promises)
          .then(() => {
            this.props.stopLoader();
            this.props.chartSidePlanActions.initListFor();
          })
          .catch((e) => this.props.stopLoader(e));
      }

      const updatedTotalTreatmentPrice = filteredTreatmentList.reduce((acc, treatment) => {
        if (selectedFor[treatment.key]) {
          return acc + Number(treatment.updatedPrice);
        }
        return acc;
      }, 0);
      const totalGivenPercentage = (totalPrice - Number(desiredBillAmount)) * 0.1;
      this.setState({
        updatedTotalNetAmount: updatedTotalTreatmentPrice,
        totalGivenPercentage,
      });
    }
  }

  render() {
    const { showTextAriaModal } = this.state;
    const { currency, values, dispatch, selectedFor, canSetDiscount } = this.props;

    const filterStatusOptions = [
      {
        value: "DentalTransactionStatus_PLANNED",
        label: "Planned",
      },
      {
        value: "DentalTransactionStatus_COMPLETED",
        label: "Completed",
      },
      {
        value: "all",
        label: "Planned & Completed",
      },
    ];

    const sortOptions = [
      {
        value: "date",
        label: "by Date A-Z",
      },
      {
        value: "date_desc",
        label: "by Date Z-A",
      },
      {
        value: "code",
        label: "by Code A-Z",
      },
      {
        value: "code_desc",
        label: "by Code Z-A",
      },
      {
        value: "tooth",
        label: "by Tooth A-Z",
      },
      {
        value: "tooth_desc",
        label: "by Tooth Z-A",
      },
    ];

    return (
      <div className="form-horizontal" style={{ flexShrink: 0 }}>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-sm-4 col-12">
                <label>Status</label>
                <SelectPicker
                  options={filterStatusOptions}
                  value={{ value: this.props.filterByStatus }}
                  onChange={(x) => this.props.changeFilterStatus(x.value)}
                />
              </div>
              <Show if={!this.props.selectedMode}>
                <div className="col-sm-4 col-12">
                  <label>Print Out Type</label>
                  <div
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                  >
                    <Field
                      component={({ input }) => (
                        <SelectPicker
                          value={{ value: input.value }}
                          onChange={(x) => input.onChange(x.value)}
                          options={[
                            {
                              value: "false",
                              label: "Estimate/Quotation EN",
                            },
                            {
                              value: "estimate-fr",
                              label: "Estimate/Quotation FR",
                            },
                            {
                              value: "estimate-sl",
                              label: "Estimate/Quotation SL",
                            },
                            {
                              value: "true",
                              label: "Proforma invoice EN",
                            },
                            {
                              value: "FR",
                              label: "Proforma invoice FR",
                            },
                            {
                              value: "SL",
                              label: "Proforma invoice SL",
                            },
                          ]}
                        />
                      )}
                      name="useInvoiceTemplate"
                      id="useInvoiceTemplate"
                    />
                  </div>
                </div>
              </Show>
              <div className="col-sm-4 col-12">
                <label>Name</label>
                <div style={{ width: "100%", marginBottom: "6px" }}>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    component="input"
                    className="form-control"
                    placeholder="Input Name For Estimate"
                  />
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <label>Sort</label>
                <SelectPicker
                  style={{ width: "200px", marginRight: "12px" }}
                  options={sortOptions}
                  value={{ value: this.props.sortBy }}
                  onChange={(x) => this.props.onChangeSort(x.value)}
                />
              </div>
              <Show if={currency.show}>
                <div className="col-sm-4 col-12">
                  <label>Currency</label>
                  <div
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                  >
                    <Field
                      component={({ input }) => (
                        <SelectPicker
                          value={{ value: input.value }}
                          options={currency.list.map((x) => ({
                            value: x.id,
                            label: x.name,
                          }))}
                          onChange={(x) => input.onChange(x.value)}
                        />
                      )}
                      name="currencyId"
                      id="currencyId"
                    />
                  </div>
                </div>
              </Show>
              <div
                className="col-sm-4 col-12"
                style={{
                  display: "flex",
                  marginTop: "24px",
                  marginBottom: "8px",
                  paddingLeft: "16px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={this.showModal}
                >
                  Text Area
                </button>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-4 col-12">
                <label>Grouping</label>
                <div
                  style={{
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <Field
                    component={({ input }) => (
                      <SelectPicker
                        value={{ value: input.value }}
                        options={[
                          {
                            value: "WITHOUT",
                            label: "Without grouping",
                          },
                          {
                            value: "GROUP_BY_PROCEDURE",
                            label: "Group by Procedure (details)",
                          },
                          {
                            value: "GROUP_BY_PROCEDURE_SUMMARY",
                            label: "Group by Procedure (summary)",
                          },
                          {
                            value: "GROUP_BY_CODE_PRICE_UNIT",
                            label: "Group by Treatment",
                          },
                        ]}
                        onChange={(x) => input.onChange(x.value)}
                      />
                    )}
                    name="groupingType"
                    id="groupingType"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-sm-4" style={{ marginBottom: "8px" }}>
                <label>Discount</label>
                <Field
                  name="discountType"
                  id="discountType"
                  component={({ input }) => (
                    <SelectPicker
                      options={[
                        {
                          label: "Amount",
                          value: "AMOUNT",
                        },
                        {
                          label: "Percentage",
                          value: "PERCENTAGE",
                        },
                      ]}
                      disabled={!canSetDiscount || _.isEmpty(selectedFor)}
                      value={{ value: input.value }}
                      onChange={(x) => input.onChange(x.value)}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-sm-4" style={{ marginBottom: "16px" }}>
                <label>Additional Discount (on Pdf):</label>
                <Field
                  type="number"
                  component="input"
                  searchable={false}
                  className="form-control"
                  id="additionalDiscountAmount"
                  name="additionalDiscountAmount"
                />
              </div>
              <div className="col-12 col-sm-4" style={{ marginTop: "25px" }}>
                <TextInput
                  type="number"
                  name="desiredBillAmount"
                  placeholder="Enter Desired Bill Amount"
                  style={{ minWidth: 180 }}
                  onChange={(event) => this.setState({ desiredBillAmount: event.target.value })}
                  value={this.state.desiredBillAmount}
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: "14px" }}>
              <div className="col-12 col-sm-4" style={{ marginBottom: "16px" }}>
                <Field
                  disabled={
                    !_.get(values, "discountType") || !canSetDiscount || _.isEmpty(selectedFor)
                  }
                  id="discountValue"
                  name="discountValue"
                  component="input"
                  className="form-control"
                  placeholder="0.00"
                  type="number"
                  onFocus={({ target }) => target.select()}
                  onChange={({ target }) => {
                    const { value } = target;
                    const discountType = _.get(values, "discountType");
                    if (_.toNumber(value) >= 0) {
                      if (
                        (discountType === "PERCENTAGE" && _.toNumber(value) <= 100) ||
                        discountType === "AMOUNT"
                      ) {
                        dispatch(change("chartPlanForName", "discountValue", _.toNumber(value)));
                      }
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-4" />
              <div className="col-sm-4">
                <Button
                  type="button"
                  className="btn btn-info btn-block"
                  onClick={() => this.desiredBillAmountCalculateHandler()}
                >
                  Calculate
                </Button>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div className="col-12 col-sm-4 mt-3">
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => this.updateDiscount()}
                  type="button"
                  disabled={_.isEmpty(selectedFor)}
                >
                  Apply to selected
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Text Area"
          show={showTextAriaModal}
          onHide={this.hideModal}
          actions={
            <button onClick={this.hideModal} className="btn btn-danger">
              Close
            </button>
          }
        >
          <Field
            rows={5}
            id="notes"
            name="notes"
            type="text"
            component="textarea"
            className="form-control"
            style={{ resize: "none" }}
          />
        </Modal>
      </div>
    );
  }
}

export default form(enhancer(ChartPlanForName));
