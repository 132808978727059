import { useMutation, UseMutationResult } from "react-query";

import { createUrl } from "../../utils/UrlUtils";
import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

export function useDeleteBlockedTimeSlotItemMutation(): UseMutationResult<string, Error> {
  return useMutation((query) =>
    httpClient
      .delete(ApiRoutes.BlockedTimeSlotItem,{params:query})
      .then(formatResponse),
  );
}
