import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/whatsAppChattingTypes";

export const defaultState = {
  list: [],
  listTotalCount: 0,
  chatSidebarVisible: false,
};

const reducers = {
  [ActionTypes.WHATSAPP_CHATTING_PATIENT_LIST_GET_SUCCESS](state, { data }) {
    return {
      ...state,
      list: data.list || [],
      listTotalCount: data.totalCount,
    }
  },
  [ActionTypes.WHATSAPP_CHATTING_PATIENT_LIST_SHOW_SIDEBAR](state, { patient }) {
    return {
      ...state,
      chatSidebarVisible: true,
      patient
    }
  },
  [ActionTypes.WHATSAPP_CHATTING_SET_PATIENT_LIST](state, { list }) {
    return {
      ...state,
      list,
    }
  },
  [ActionTypes.WHATSAPP_CHATTING_PATIENT_LIST_HIDE_SIDEBAR](state) {
    return {
      ...state,
      chatSidebarVisible: false,
    }
  }
}

export default createReducer(defaultState, reducers);