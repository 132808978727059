import React, { CSSProperties, ReactNode, UIEventHandler } from "react";
import fp from "lodash/fp";
import cx from "classnames";

export enum FlexBoxWrap {
  Wrap = "wrap",
  NoWrap = "nowrap",
  WrapReverse = "reverse",
}

export enum FlexBoxAlign {
  End = "flex-end",
  Center = "center",
  Stretch = "stretch",
  Start = "flex-start",
}

export enum FlexBoxJustify {
  End = "flex-end",
  Center = "center",
  Start = "flex-start",
  Around = "space-between",
  Between = "space-around",
}

export enum FlexBoxDirection {
  Row = "row",
  Column = "column",
  Reverse = "row-reverse",
  ColumnReverse = "column-reverse",
}

export enum FlexBoxFlex {
  None = "none",
  Auto = "auto",
  Grow = "grow",
  Grow2 = "grow-2",
  Shrink = "shrink",
  NoGrow = "nogrow",
  Initial = "initial",
  NoShrink = "noshrink",
}

type GUTTER_OPTION = 0 | 8 | 16 | 24 | 40;

export interface Props {
  style?: CSSProperties;
  className?: string;

  withoutHeight?: boolean;

  gutter?: GUTTER_OPTION;
  container?: GUTTER_OPTION;

  containerElement?: any;

  children: ReactNode;

  wrap?: FlexBoxWrap;
  align?: FlexBoxAlign;
  justify?: FlexBoxJustify;
  direction?: FlexBoxDirection;

  onScroll?: UIEventHandler<unknown>;

  flex?: boolean | number | FlexBoxFlex;
}

const getPercent = fp.flow(fp.toFinite, fp.clamp(0, 100));

const getStyles = (flex, direction) => {
  if (flex === true || fp.isNumber(flex)) {
    const percent = flex === true ? 0 : getPercent(flex);
    const vertical =
      direction === FlexBoxDirection.Column || direction === FlexBoxDirection.ColumnReverse;

    const style: CSSProperties = { flex: `1 1 ${percent}%` };

    if (percent > 0) {
      style.maxWidth = vertical ? "100%" : `${percent}%`;
      style.maxHeight = vertical ? `${percent}%` : "100%";
    }

    return style;
  }

  switch (flex) {
    case FlexBoxFlex.None:
      return { flex: "0 0 auto" };
    case FlexBoxFlex.Initial:
      return { flex: "0 1 auto" };
    case FlexBoxFlex.Auto:
      return { flex: "1 1 0%" };
    case FlexBoxFlex.Grow:
      return { flex: "1 1 100%" };
    case FlexBoxFlex.Grow2:
      return { flex: "2 1 0%" };
    case FlexBoxFlex.NoGrow:
      return { flex: "0 1 auto" };
    case FlexBoxFlex.NoShrink:
      return { flex: "1 1 0%" };
    case FlexBoxFlex.Shrink:
      return { flex: "1 0 0%" };

    default:
      return {};
  }
};

export default function FlexBox({
  flex,
  wrap = FlexBoxWrap.Wrap,
  align = FlexBoxAlign.Start,
  style,
  gutter = 0,
  justify = FlexBoxJustify.Start,
  container = 0,
  direction = FlexBoxDirection.Row,
  className,
  withoutHeight,
  containerElement = "div",
  ...props
}: Props) {
  const rootStyle = { ...getStyles(flex, direction), ...style };

  const rootClassName = cx(
    "flex-box-component",
    "root",
    `wrap-${wrap}`,
    `align-${align}`,
    `gutter-${gutter}`,
    `justify-${justify}`,
    `container-${container}`,
    `direction-${direction}`,
    { withoutHeight: withoutHeight },
    className,
  );

  if (React.isValidElement(containerElement)) {
    return React.cloneElement(containerElement, {
      ...props,
      // @ts-ignore
      style: { ...rootStyle, ...containerElement.style },
      // @ts-ignore
      className: cx(rootClassName, containerElement.className),
    });
  }

  return React.createElement(containerElement, {
    ...props,
    style: rootStyle,
    className: rootClassName,
  });
}
