import React from "react";
import Slider from "react-slick";
import ToothItem from "../teeth/ToothItem";
import { SELECTED } from "../../../constants/Constants";
import * as SeriesUtils from "../../../helpers/chartSideSeriesUtils";

const TeethSlider = ({ teeth, title, addTransaction, selectTooth, numericKey }) => {
  const toothList = [];
  let activeSurfaceIndex = 0;
  const teethDetailsList = SeriesUtils.getHalfSortedTeeh(teeth);

  for (const toothDetails of teethDetailsList) {
    let isSelected = false;

    for (const toothItem of addTransaction.selectedTeeth) {
      if (toothDetails.code === toothItem.id) {
        isSelected = true;
        activeSurfaceIndex = toothList.length;
        break;
      }
    }

    // positionList[toothList.length] = toothDetails[numericKey];
    toothList.push(
      <div key={toothDetails.code} data-index={toothDetails.code}>
        <ToothItem
          onClick={selectTooth}
          className={isSelected ? SELECTED : ""}
          numericKey={numericKey}
          tooth={teeth[toothDetails.position]}
          toothDetails={toothDetails}
        />
      </div>,
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  settings.slickGoTo = activeSurfaceIndex >= 2 ? activeSurfaceIndex - 2 : activeSurfaceIndex;
  // if (activeSurfaceIndex >= 0) {
  //   settings.initialSlide = activeSurfaceIndex;
  // } else {
  //   return null;
  // }

  return (
    <div className="slider-wrap">
      <h4 className="title">{title}</h4>
      <Slider {...settings}>{toothList}</Slider>
    </div>
  );
};
export default TeethSlider;
