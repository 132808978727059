import { httpClient } from "./BaseApi";

const CLINIC_MEMBERS_URL = "api/member/doctor/choose.json";

const LAB_ORDER_LIST_URL = "api/lab-order/document/list.json";
const LAB_ORDER_ITEM_URL = "api/lab-order/document/item.json";
const LAB_ORDER_FORM_DATA_URL = "api/lab-order/document/form-data.json";
const LAB_ORDER_TYPES = "api/lab-order/document/types/choose.json";
const LAB_ORDER_UPLOAD_URL = "api/lab-order/document/upload-file.json";

const RADIOLOGY_ORDER_LIST_URL = "api/radiology-order/document/list.json";
const RADIOLOGY_ORDER_ITEM_URL = "api/radiology-order/document/item.json";
const RADIOLOGY_ORDER_FORM_DATA_URL = "api/radiology-order/document/form-data.json";
const RADIOLOGY_ORDER_UPLOAD_URL = "api/radiology-order/document/upload-file.json";

const OrderApi = {
  getClinicMembers: (params) => httpClient.get(CLINIC_MEMBERS_URL, { params }),
  getLabOrderList: (params) => httpClient.get(LAB_ORDER_LIST_URL, { params }),
  getLabOrderFormData: () => httpClient.get(LAB_ORDER_FORM_DATA_URL),
  saveLabOrderItem: (data) =>
    data.id
      ? httpClient.put(LAB_ORDER_ITEM_URL, { data })
      : httpClient.post(LAB_ORDER_ITEM_URL, { data }),
  getLabOrderItem: (params) => httpClient.get(LAB_ORDER_ITEM_URL, { params }),
  getLabOrderTypes: () => httpClient.get(LAB_ORDER_TYPES),
  deleteLabOrderItem: (params) => httpClient.delete(LAB_ORDER_ITEM_URL, { params }),
  uploadLabOrderFiles: (data) => httpClient.post(LAB_ORDER_UPLOAD_URL, { data }),
  getRadiologyOrderList: (params) => httpClient.get(RADIOLOGY_ORDER_LIST_URL, { params }),
  getRadiologyOrderFormData: () => httpClient.get(RADIOLOGY_ORDER_FORM_DATA_URL),
  saveRadiologyOrderItem: (data) =>
    data.id
      ? httpClient.put(RADIOLOGY_ORDER_ITEM_URL, { data })
      : httpClient.post(RADIOLOGY_ORDER_ITEM_URL, { data }),
  getRadiologyOrderItem: (params) => httpClient.get(RADIOLOGY_ORDER_ITEM_URL, { params }),
  deleteRadiologyOrderItem: (params) => httpClient.delete(RADIOLOGY_ORDER_ITEM_URL, { params }),
  uploadRadiologyOrderFiles: (data) => httpClient.post(RADIOLOGY_ORDER_UPLOAD_URL, { data }),
};

export default OrderApi;
