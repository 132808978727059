import React, { useEffect, useState } from "react";
import { Switch } from "../ui/Switch";
import { withRouter } from "react-router";

const MachineFolder = (props) => {
  const [dir, setDir] = useState("");
  const [folders, setFolders] = useState([]);
  const [url, setUrl] = useState("");
  const [syncXray, setSyncXray] = useState(localStorage.getItem("syncXray"));

  useEffect(() => {
    const fileUrl = localStorage.getItem("fileUrl");
    if (fileUrl) {
      openFolder(fileUrl);
      return;
    }
    const url = localStorage.getItem("fileUrl") || "";
    setUrl(url);
    window.parent.window.getDrives().then((folders) => setFolders(folders));
  }, []);

  const openFolder = (url) => {
    window.parent.window.getFolderList(url).then((response) => {
      const folders = [];
      response.forEach((file) => {
        folders.push(file);
      });
      setFolders(folders);
      setUrl(url);
    });
    window.parent.window.setUrl(url);
  };

  const onBack = () => {
    const oldUrl = localStorage.getItem("fileUrl");
    if (!oldUrl) return;
    if (oldUrl.split("/").length === 2) {
      localStorage.setItem("fileUrl", "");
      setUrl("");
      window.parent.window.getDrives().then((folders) => setFolders(folders));
    }
    const newUrl = oldUrl.split(`/`).slice(0, -2).join("/") + `/`;
    openFolder(newUrl === "/" ? "" : newUrl);
    window.parent.window.setUrl(newUrl === "/" ? "" : newUrl);
  };

  const saveHandler = () => {
    const { history } = props;
    history.goBack();
  };

  const syncXrayHandler = (syncXray) => {
    localStorage.setItem("syncXray", syncXray);
    setSyncXray(syncXray);
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "1rem",
      }}
    >
      <button
        style={{ marginBottom: "1rem" }}
        className="btn btn-success mb-3 mr-3"
        id="back-button"
        onClick={() => onBack()}
      >
        back
      </button>
      <button
        style={{ marginBottom: "1rem" }}
        className="btn btn-success mb-3 mr-3"
        id="back-button"
        onClick={() => saveHandler()}
      >
        save here
      </button>
      <button
        style={{ marginBottom: "1rem" }}
        className="btn btn-danger mb-3 mr-3"
        id="back-button"
        onClick={() => {
          window.parent.window.getDrives().then((folders) => setFolders(folders));
          setUrl("");
        }}
      >
        clear url
      </button>
      <label>
        sync x-ray: <Switch checked={!!syncXray} onChange={(x) => syncXrayHandler(x)} />
      </label>
      <div className="mb-3">
        X-ray folder: <b>{url}</b>
      </div>
      <div style={{ height: "70%", overflowX: "auto" }}>
        <div id="patient-details" style={{ display: "flex", flexDirection: "column" }}>
          {folders.map((folder) => (
            <div
              className="btn btn-default mb-1"
              key={folder}
              onClick={() => {
                openFolder(url + folder + "/");
                setUrl(url + folder + "/");
              }}
            >
              {folder}
            </div>
          ))}
          {!folders.length && <h4>Patient images will save here: {url}</h4>}
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{ height: "16%", borderTop: "1px solid" }}
      >
        <input
          type="text"
          className="form-control mb-3"
          value={dir}
          onChange={({ target }) => setDir(target.value)}
        />
        <button
          className="btn btn-success"
          onClick={() => {
            if (!dir) return;
            window.parent.window.createFolder(dir);
            window.parent.window.getFolderList(url).then((response) => {
              const folders = [];
              response.forEach((file) => {
                folders.push(file);
              });
              setFolders(folders);
            });
            setDir("");
          }}
        >
          create folder
        </button>
      </div>
    </div>
  );
};

export default withRouter(MachineFolder);
