import React from "react";

import Utils from "../../../helpers/Utils";

import ChartTimer from "./ChartTimer";
import ClockImage from "../../../assets/images/chart/condition_alarm-icon.png";

export default function ChartAppointmentClock({ appointment, type }) {
  if (!appointment.id) return null;

  const startDate = new Date(appointment.startTime - Utils.getTimezoneDifference());
  const hours = startDate.getHours();
  const minutes = startDate.getMinutes();

  const duration = appointment.duration / 1000 / 60;

  const startTime = hours * 60 + minutes;
  const endTime = startTime + duration;

  const time = `${Utils.intToDayPartStr(startTime)} - ${Utils.intToDayPartStr(endTime)}`;

  let $bottom;
  if (type === "Visit" && appointment.status.code === "APPOINTMENT_STATUS_BEING_SEEN") {
    $bottom = <ChartTimer startTime={appointment.actualStartTime} />;
  } else {
    $bottom = "Just view";
  }

  return (
    <div className="appointment-info">
      <div className="time">{time}</div>
      <div className="clock d-flex align-items-center justify-content-end">
        {$bottom}
        <img style={{ marginLeft: "10px" }} src={ClockImage} alt="" />
      </div>
    </div>
  );
}
