export const GET_WAITING_LIST_START = "GET_WAITING_LIST_START";
export const GET_WAITING_LIST_SUCCESS = "GET_WAITING_LIST_SUCCESS";
export const GET_WAITING_LIST_ERROR = "GET_WAITING_LIST_ERROR";

export const SET_WAITING_LIST_FROM_DATE = "SET_WAITING_LIST_FROM_DATE";
export const SET_WAITING_LIST_TO_DATE = "SET_WAITING_LIST_TO_DATE";

export const SET_MISSED_CANCELLED_LIST_DATE = "SET_MISSED_CANCELLED_LIST_DATE";

export const REQUESTED_APPOINTMENTS_WAITING_LIST_INFO = "REQUESTED_APPOINTMENTS_WAITING_LIST_INFO";

export const REQUESTED_APPOINTMENTS_GET_WAITING_LIST_START =
  "REQUESTED_APPOINTMENTS_GET_WAITING_LIST_START";
export const REQUESTED_APPOINTMENTS_GET_WAITING_LIST_SUCCESS =
  "REQUESTED_APPOINTMENTS_GET_WAITING_LIST_SUCCESS";
export const REQUESTED_APPOINTMENTS_GET_WAITING_LIST_ERROR =
  "REQUESTED_APPOINTMENTS_GET_WAITING_LIST_ERROR";

export const REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_START =
  "REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_START";
export const REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_SUCCESS =
  "REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_SUCCESS";
export const REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_ERROR =
  "REQUESTED_APPOINTMENTS_GET_SCHEDULED_LIST_ERROR";

export const REQUESTED_DELETE_CONFIRM_MODAL_SHOW = "REQUESTED_DELETE_CONFIRM_MODAL_SHOW";
export const REQUESTED_DELETE_CONFIRM_MODAL_HIDE = "REQUESTED_DELETE_CONFIRM_MODAL_HIDE";

export const REQUESTED_APPOINTMENTS_DELETE_ITEM_START = "REQUESTED_APPOINTMENTS_DELETE_ITEM_START";
export const REQUESTED_APPOINTMENTS_DELETE_ITEM_SUCCESS =
  "REQUESTED_APPOINTMENTS_DELETE_ITEM_SUCCESS";
export const REQUESTED_APPOINTMENTS_DELETE_ITEM_ERROR = "REQUESTED_APPOINTMENTS_DELETE_ITEM_ERROR";

export const WAITING_LIST_GET_APPT_TYPE_START = "WAITING_LIST_GET_APPT_TYPE_START";
export const WAITING_LIST_GET_APPT_TYPE_SUCCESS = "WAITING_LIST_GET_APPT_TYPE_SUCCESS";
export const WAITING_LIST_GET_APPT_TYPE_ERROR = "WAITING_LIST_GET_APPT_TYPE_ERROR";

export const WAITING_LIST_SET_DOCTOR = "WAITING_LIST_SET_DOCTOR";
export const WAITING_LIST_SET_APPT_TYPE = "WAITING_LIST_SET_APPT_TYPE";

export const WAITING_LIST_INIT_DOCTORS = "WAITING_LIST_INIT_DOCTORS";
export const WAITING_LIST_SELECT_DOCTORS = "WAITING_LIST_SELECT_DOCTORS";

export const GET_MISSED_CANCELLED_LIST_START = "GET_MISSED_CANCELLED_LIST_START";
export const GET_MISSED_CANCELLED_LIST_SUCCESS = "GET_MISSED_CANCELLED_LIST_SUCCESS";
export const GET_MISSED_CANCELLED_LIST_ERROR = "GET_MISSED_CANCELLED_LIST_ERROR";
export const SET_MISSED_CANCELLED_LIST_FORM_VALUES = "SET_MISSED_CANCELLED_LIST_FORM_VALUES";
export const CLEAN_UP_MISSED_CANCELLED_LIST_START = "CLEAN_UP_MISSED_CANCELLED_LIST_START";
export const CLEAN_UP_MISSED_CANCELLED_LIST_SUCCESS = "CLEAN_UP_MISSED_CANCELLED_LIST_SUCCESS";
export const CLEAN_UP_MISSED_CANCELLED_LIST_ERROR = "CLEAN_UP_MISSED_CANCELLED_LIST_ERROR";

export const CHANGE_BOOKED_APPOINTMENT_DATE = "CHANGE_BOOKED_APPOINTMENT_DATE";
export const CHANGE_BOOKED_APPOINTMENT_DATE_TO_DEFAULT =
  "CHANGE_BOOKED_APPOINTMENT_DATE_TO_DEFAULT";
