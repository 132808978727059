import React from "react";
import { DATE_FORMAT_LEGACY } from "../../../../constants/Constants";
import Utils from "../../../../helpers/Utils";
import copyImage from "../../../../assets/images/content-copy.png";
import deleteImage from "../../../../assets/images/chart/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { openSimpleModal } from "../../../../actions/simpleModalActions";

export default function ChartSideListCode(props) {
  const dispatch = useDispatch();
  const { item, handleItem } = props;
  const {
    session: { permissions },
    chartSideFillInForm: { fillInFormActiveTab },
  } = useSelector((state) => state);
  const { openItem, copyItem, addendumItem, onReferenceClick } = props;

  const canDuplicate = permissions.find((item) => item === "DUPLICATE_FILL_IN_FORM_DOCUMENT_ITEM");
  return (
    <div
      role="button"
      className="btn btn-default chart-clinical-note-item mr-1 ml-1 mb-1"
      onClick={openItem(item)}
      style={{ position: "relative", paddingRight: "12px" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <strong>{item.name}</strong>

        <div className="d-flex align-items-center">
          {!item.referenceFillInFormId && (
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();

                addendumItem();
              }}
              className="mr-2"
            >
              <span className="font-weight-bold cursor-pointer">Addendum</span>
            </div>
          )}
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              if (canDuplicate) {
                copyItem();
              } else {
                dispatch(openSimpleModal({ body: "Permission denied" }));
              }
            }}
          >
            <img
              style={{ width: "20px", height: "20px", marginRight: 12 }}
              src={copyImage}
              alt=""
            />
          </div>
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              if (fillInFormActiveTab.code === "fillInForms") handleItem(item, false);
              if (fillInFormActiveTab.code === "voidedFillInForms") handleItem(item, true);
            }}
          >
            {fillInFormActiveTab.code === "fillInForms" ? (
              <img style={{ width: "20px", height: "20px" }} src={deleteImage} alt="" />
            ) : (
              <span className="font-weight-bold cursor-pointer">restore</span>
            )}
          </div>
        </div>
      </div>

      <br />

      <div className="d-flex align-items-end">
        <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
          <div style={{ fontSize: "12px", color: "#333" }}>
            by &nbsp;
            {item.doctor && item.doctor.name}
          </div>
          <div style={{ fontSize: "12px", color: "#333" }}>
            {Utils.parseDateFormat(
              Utils.calcDateWithTimeZone(item.createdDate),
              DATE_FORMAT_LEGACY,
            )}
          </div>
        </div>

        {!!item.referenceFillInFormId && (
          <span
            className="text-info"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              onReferenceClick(item.referenceFillInFormId);
            }}
          >
            Reference Note
          </span>
        )}
      </div>
    </div>
  );
}
