import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import bindActions from "../../helpers/bindActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import InfoModal from "../ui/InfoModal";

const enhancer = connect(
  ({ simpleModal }) => ({ simpleModal }),
  bindActions({ simpleModalActions }),
);
class SimpleModal extends React.Component {
  checkComponent(income) {
    if (_.isNull(income) || _.isUndefined(income)) {
      return null;
    }
    if (_.isString(income) || _.isNumber(income)) {
      return income;
    }
    if (React.isValidElement(income)) {
      return income;
    }
    if (_.isFunction(income)) {
      return React.createElement(income);
    }
    throw new Error("undefined type of income");
  }

  componentDidUpdate() {
    const btn = document.getElementById("simple-modal");
    if (btn) {
      btn.addEventListener("keydown", (event) => {
        if (event.code === "Space") {
          this.props.simpleModalActions.closeSimpleModal();
        }
      });
    }
  }

  buttonClick = (e) => {
    if (_.isFunction(this.props.simpleModal.onClick)) {
      this.props.simpleModal.onClick(e);
    }

    this.props.simpleModalActions.closeSimpleModal();
  };

  render() {
    const { body, footer, size, modalVisible, modalType } = this.props.simpleModal;
    return (
      <InfoModal
        onHide={this.buttonClick}
        info={this.checkComponent(body)}
        footer={footer}
        id="simple-modal"
        show={modalVisible}
        size={size}
        title={<span />}
        type={modalType}
      />
    );
  }
}
export default enhancer(SimpleModal);
