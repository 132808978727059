import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/blockedTimeSlotActionTypes";

export const defaultState = {
  templateList: [],
  templateListTotalCount: 0,
  templateListFetching: false,
  keywords: [],
  blockedList: [],
  untilDate: null,
  excludedDates: [],
  blockedMode: "view",
  blockedEditId: 0,
  blockedDoctors: [],
  blockedFromTime: 0,
  blockedToTime: 0,
  blockedFromDate: moment(),
  blockedToDate: moment(),
  blockedListFetching: false,
  blockedListTotalCount: 0,
  blockedFilterKey: "",
  blockedConfirmModalVisible: false,
  blockedDeleteId: 0,
  weekDays: [],
  dentalPoints: [],
  customPattern: false,
  formData: {
    recurrenceTypes: [
      {
        id: 1,
        name: "Daily",
        code: "DAILY",
        selected: false,
      },
      { id: 2, name: "Weekly", code: "WEEKLY", selected: false },
      { id: 3, name: "Monthly", code: "MONTHLY", selected: false },
      { id: 4, name: "Yearly", code: "YEARLY", selected: false },
    ],
    recurrenceEndTypes: [
      {
        id: 1,
        name: "RecurrenceEnd_None",
        code: "RecurrenceEnd_None",
        selected: false,
      },
      {
        id: 2,
        name: "RecurrenceEnd_By_EndDate",
        code: "RecurrenceEnd_By_EndDate",
        selected: false,
      },
      {
        id: 3,
        name: "RecurrenceEnd_By_CountLimit",
        code: "RecurrenceEnd_By_CountLimit",
        selected: false,
      },
      {
        id: 4,
        name: "RecurrenceEnd_Single",
        code: "RecurrenceEnd_Single",
        selected: false,
      },
    ],
    yearWeekNumbers: [
      { id: 1, name: "first", code: "FIRST", selected: false },
      { id: 2, name: "second", code: "SECOND", selected: false },
      { id: 3, name: "third", code: "THIRD", selected: false },
      { id: 4, name: "fourth", code: "FOURTH", selected: false },
      { id: 5, name: "last", code: "LAST", selected: false },
    ],
    months: [
      { id: 1, name: "January", code: "JANUARY", selected: false },
      { id: 2, name: "February", code: "FEBRUARY", selected: false },
      { id: 3, name: "March", code: "MARCH", selected: false },
      { id: 4, name: "April", code: "APRIL", selected: false },
      { id: 5, name: "May", code: "MAY", selected: false },
      { id: 6, name: "June", code: "JUNE", selected: false },
      { id: 7, name: "July", code: "JULY", selected: false },
      { id: 8, name: "August", code: "AUGUST", selected: false },
      { id: 9, name: "September", code: "SEPTEMBER", selected: false },
      { id: 10, name: "October", code: "OCTOBER", selected: false },
      { id: 11, name: "November", code: "NOVEMBER", selected: false },
      { id: 12, name: "December", code: "DECEMBER", selected: false },
    ],
    weakDays: [
      { id: 1, name: "Monday", code: "MONDAY", selected: false },
      { id: 2, name: "Tuesday", code: "TUESDAY", selected: false },
      { id: 3, name: "Wednesday", code: "WEDNESDAY", selected: false },
      { id: 4, name: "Thursday", code: "THURSDAY", selected: false },
      { id: 5, name: "Friday", code: "FRIDAY", selected: false },
      { id: 6, name: "Saturday", code: "SATURDAY", selected: false },
      { id: 7, name: "Sunday", code: "SUNDAY", selected: false },
    ],
  },
};

const reducers = {
  [ActionTypes.GET_BLOCKED_TEMPLATES_LIST_START](state) {
    return {
      ...state,
      templateListFetching: true,
    };
  },
  [ActionTypes.GET_BLOCKED_TEMPLATES_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      templateListFetching: false,
      templateList: request.start ? state.templateList.concat(data.list) : data.list || [],
      templateListTotalCount: data.totalCount || 0,
    };
  },
  [ActionTypes.GET_BLOCKED_TEMPLATES_LIST_ERROR](state) {
    return {
      ...state,
      templateListFetching: false,
    };
  },
  [ActionTypes.GET_MEMBERS_KEYWORDS_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      keywords: data.map((item) => ({
        ...item,
        active: false,
      })),
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_UNTIL_DATE](state, { date }) {
    return {
      ...state,
      untilDate: date,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_REMOVE_UNTIL_DATE](state) {
    return {
      ...state,
      untilDate: null,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_ADD_EXCLUDED_DATE](state, { date }) {
    const excludedDates = state.excludedDates.slice();

    excludedDates.push(date);

    return {
      ...state,
      excludedDates,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_REMOVE_EXCLUDED_DATE](state, { index }) {
    const excludedDates = state.excludedDates.slice();

    excludedDates.splice(index, 1);

    return {
      ...state,
      excludedDates,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_FORM_DATA_SUCCESS](state, { data }) {
    return {
      ...state,
      formData: data,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_CUSTOM_PATTERN](state, { pattern }) {
    return {
      ...state,
      customPattern: pattern,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_RESET_DOCTORS](state) {
    return {
      ...state,
      blockedDoctors: state.blockedDoctors.map((x) => ({
        ...x,
        active: false,
      })),
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_CONFIRM_MODAL_SHOW](state, { deleteId }) {
    return {
      ...state,
      blockedConfirmModalVisible: true,
      blockedDeleteId: deleteId,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      blockedConfirmModalVisible: false,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_DOCTORS_EDIT](state, { doctors = [] }) {
    if (state.blockedMode === "edit") {
      return {
        ...state,
        blockedDoctors: state.blockedDoctors.map((x) => {
          const index = doctors.findIndex((item) => _.get(item, "id") === _.get(x, "id"));

          return {
            ...x,
            active: index >= 0,
          };
        }),
      };
    }

    return {
      ...state,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_CLEAR_FILTER_KEY](state) {
    return {
      ...state,
      blockedFilterKey: "",
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_FILTER_KEY](state, { filterKey }) {
    return {
      ...state,
      blockedFilterKey: filterKey,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_INIT](state) {
    return {
      ...state,
      blockedList: [],
      blockedMode: "view",
      blockedEditId: 0,
      blockedDoctors: [],
      blockedListFetching: false,
      blockedListTotalCount: 0,
      blockedConfirmModalVisible: false,
      blockedDeleteId: 0,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_ITEM_INIT](state, { id }) {
    return {
      ...state,
      blockedList: [],
      blockedMode: "view",
      blockedEditId: id,
      blockedDoctors: [],
      blockedFromTime: 0,
      blockedToTime: 0,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_TO_DATE](state, { date }) {
    return {
      ...state,
      blockedToDate: date,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_FROM_DATE](state, { date }) {
    return {
      ...state,
      blockedFromDate: date,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_TO_TIME](state, { time }) {
    return {
      ...state,
      blockedToTime: time,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_FROM_TIME](state, { time }) {
    return {
      ...state,
      blockedFromTime: time,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_CHOOSE_DOCTOR](state, { id }) {
    const { blockedDoctors } = state;

    if (_.toFinite(id) > 0) {
      return {
        ...state,
        blockedDoctors: blockedDoctors.map((x) => ({
          ...x,
          active: x.id === _.toFinite(id) ? !x.active : x.active,
        })),
      };
    }

    if (id === "all") {
      return {
        ...state,
        blockedDoctors: blockedDoctors.map((x) => ({
          ...x,
          active: true,
        })),
      };
    }

    return {
      ...state,
      blockedDoctors: blockedDoctors.map((x) => ({
        ...x,
        active: false,
      })),
    };
  },
  [ActionTypes.MEMBERS_KEYWORDS_SELECT](state, { id }) {
    const { keywords } = state;

    if (_.toFinite(id) > 0) {
      return {
        ...state,
        keywords: keywords.map((x) => ({
          ...x,
          active: x.id === _.toFinite(id) ? !x.active : x.active,
        })),
      };
    }

    if (id === "all") {
      return {
        ...state,
        keywords: keywords.map((x) => ({
          ...x,
          active: true,
        })),
      };
    }

    return {
      ...state,
      keywords: keywords.map((x) => ({
        ...x,
        active: false,
      })),
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_DENTIST_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      blockedDoctors: data.map((x) => ({ ...x, active: false })),
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_WEEK_DAYS_SUCCESS](state) {
    return {
      ...state,
      weekDays: [
        {
          id: 1,
          name: "Monday",
          code: "MONDAY",
          selected: false,
        },
        {
          id: 2,
          name: "Tuesday",
          code: "TUESDAY",
          selected: false,
        },
        {
          id: 3,
          name: "Wednesday",
          code: "WEDNESDAY",
          selected: false,
        },
        {
          id: 4,
          name: "Thursday",
          code: "THURSDAY",
          selected: false,
        },
        {
          id: 5,
          name: "Friday",
          code: "FRIDAY",
          selected: false,
        },
        {
          id: 6,
          name: "Saturday",
          code: "SATURDAY",
          selected: false,
        },
        {
          id: 7,
          name: "Sunday",
          code: "SUNDAY",
          selected: false,
        },
      ],
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_LIST_START](state) {
    return {
      ...state,
      blockedListFetching: true,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      blockedList: request.start ? state.blockedList.concat(data.list) : data.list || [],
      blockedListFetching: false,
      blockedListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_ITEM_SUCCESS](state, { data }) {
    const { members = [], excludedDates = [] } = data;

    return {
      ...state,
      blockedList: [data],
      excludedDates: excludedDates.map((x) =>
        moment(x + new Date().getTimezoneOffset() * 60 * 1000),
      ),
      blockedDoctors: state.blockedDoctors.map((item) => {
        const itemIndex = members.findIndex((x) => x.id === item.id);

        if (itemIndex >= 0) {
          return {
            ...item,
            active: true,
          };
        }

        return item;
      }),
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_LIST_ERROR](state) {
    return {
      ...state,
      blockedListFetching: false,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_MODE](state, { mode }) {
    return {
      ...state,
      blockedMode: mode,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_SET_EDIT_INDEX](state, { index }) {
    return {
      ...state,
      blockedEditId: index,
    };
  },
  [ActionTypes.BLOCKED_TIME_SLOT_GET_DENTAL_POINT_SUCCESS](state, { data }) {
    return {
      ...state,
      dentalPoints: data,
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const blockedTimeSlotSelector = ({ blockedTimeSlot }) => blockedTimeSlot;

export default createReducer(defaultState, reducers);
