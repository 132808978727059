import React from "react";
import ChartSurfaceImageBase from "./ChartSurfaceImageBase";

export default function ChartSurfaceImage(props) {
  return (
    <div className="surface-image">
      <ChartSurfaceImageBase {...props} />
    </div>
  );
}
