import React from "react";

import { Modal, ModalProps } from "../ui/Modal";
import { EligibilityInfoText } from "./EligibilityInfoText";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { appointmentEligibilityDetailsSelector } from "../../reducers/appointmentReducer";

interface Props extends Omit<ModalProps, "children" | "title"> {
  readonly appointment?: any;
}

export function ViewEligibilityModalWrapper({ appointment, ...props }: Props) {
  const eligibility = useShallowEqualSelector(
    appointmentEligibilityDetailsSelector(appointment?.id) as any,
  );

  if (!eligibility) {
    return null;
  }

  return (
    <Modal title="View eligibility" {...props} size="sm">
      <EligibilityInfoText
        title="Eligibility Status:"
        value={eligibility?.eligibilityCheck?.eligibilityStatusId}
      />
      <EligibilityInfoText
        title="Eligibility ID:"
        value={eligibility?.eligibilityCheck?.eligibilityId}
      />
    </Modal>
  );
}
