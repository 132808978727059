import React from "react";
import { connect } from "react-redux";

import Back from "../../widgets/Back";

import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartLabOrderPDF extends React.Component {
  render() {
    const { chartSideSeries } = this.props;

    return (
      <div className="default-page">
        <div className="default-page-title">
          <Back
            className="pull-left"
            onClick={this.props.chartSideSeriesActions.closeDocumentsAssignedInsideModal}
          />
          <div className="title text-center">VIEW LAB ORDER</div>
        </div>
        <div className="default-page-body" style={{ display: "flex" }}>
          <embed style={{ height: "100%", flex: 1 }} src={chartSideSeries.labOrder.pdfUrl} />
        </div>
      </div>
    );
  }
}

export default enhancer(ChartLabOrderPDF);
