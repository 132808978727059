import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideTransactionActions from "../../../../actions/chartSideTransactionActions";

const enhancer = connect(null, bindActions({ chartSideTransactionActions }));
export default enhancer(
  class ChartTransactionAccessDeleteModal extends React.Component {
    render() {
      return (
        <Modal
          size="small"
          show={this.props.accessDeleteModalVisible}
          onHide={this.props.chartSideTransactionActions.closeAccessDeleteModal}
          keyboard={false}
          actions={
            <Button
              onClick={() => {
                this.props.chartSideTransactionActions.closeAccessDeleteModal();
              }}
            >
              Ok
            </Button>
          }
        >
          <div>Sorry, but access denied!</div>
        </Modal>
      );
    }
  },
);
