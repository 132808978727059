interface HexToRgbOptionsProps {
  readonly opacity?: number;
  readonly fallback?: string;
}

export function hexToRgba(hex: string, options: HexToRgbOptionsProps): string | undefined {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const replacedHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(replacedHex);

  if (result) {
    return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
      result[3],
      16,
    )}, ${options?.opacity || 1})`;
  }

  return options?.fallback;
}

export function formatResponse({ data }) {
  if (data.status === "error") {
    const modifiedMessage = data.data?.message.replace(/"([^"]*)"/g, (_, word) => {
      return `"${word.charAt(0).toUpperCase() + word.slice(1)}"`;
    });
    throw new Error(modifiedMessage || "Unknown error");
  }

  return data.data;
}

export function formatPhoneToObject(phone = "") {
  let townCode = "";
  let countryCode = "";
  let phoneNumber = "";

  const first = phone.split("(");

  if (first.length > 0) {
    countryCode = first[0].slice(1);
  }

  if (first.length === 2) {
    const second = first[1].split(")");

    if (second.length > 0) {
      townCode = second[0];
    }

    if (second.length === 2) {
      phoneNumber = second[1];
    }
  }

  return {
    townCode,
    number: phoneNumber,
    countryCode: { value: countryCode },
  };
}
