import _ from "lodash";
import React from "react";
import ReactSpinner from "react-spinjs-fix";
import { Button, Table } from "react-bootstrap";
import { Modal } from "../ui/Modal";

import Show from "../widgets/Show";
import FlexBox from "../ui/FlexBox";
import { getCompanyFormatDate } from "../../helpers/DateUtils";
import { TreatmentGrouppingTypes as treatmentGroupping } from "../../constants/Constants";
import { TransactionStatus } from "../../constants/TransactionTypes";
import cx from "classnames";

function onScrollList(event, props) {
  if (props.onScroll) {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight;

    if (scrollBottom) {
      props.onScroll();
    }
  }
}

function generateList(list = [], isProcedureGroupping) {
  return list.map((i, idx) => {
    const priorApprovalExpireDate = _.get(i, "priorApprovalExpireDate");
    const priorApproval2ExpireDate = _.get(i, "priorApproval2ExpireDate");

    const holdPreApproval = _.get(i, "holdPreApproval");

    const note = _.get(i, "note");

    const approvalStatus = _.get(i, "approvalStatus.name");
    const approvalStatus2 = _.get(i, "approval2Status.name");

    const toothName = _.get(i, "tooth.name");
    const createdDate = _.get(i, "createdDate");
    const doctor = _.get(i, "createdDentist.name");
    const treatmentCode = _.get(i, "treatmentItem.code");
    const treatmentDescription = _.get(i, "treatmentItem.description");
    const treatmentPrice = parseFloat(_.get(i, "price")).toFixed(2);
    const approval = approvalStatus ? approvalStatus : holdPreApproval ? "Hold" : "";
    const approval2 = approvalStatus2 ? approvalStatus2 : holdPreApproval ? "Hold" : "";

    const expireDate = priorApprovalExpireDate ? getCompanyFormatDate(priorApprovalExpireDate) : "";
    const expireDate2 = priorApproval2ExpireDate
      ? getCompanyFormatDate(priorApproval2ExpireDate)
      : "";

    return (
      <tr key={idx}>
        <td style={{ width: "100px", minWidth: "100px" }}>{treatmentCode}</td>
        <td className="description" style={{ minWidth: "174px", width: "174px" }}>
          {treatmentDescription}
        </td>
        <td style={{ minWidth: "110px" }}>{toothName}</td>
        <td style={{ minWidth: "110px" }}>{treatmentPrice}</td>
        <td style={{ minWidth: "80px" }}>{approval}</td>
        <td style={{ minWidth: "80px" }}>{expireDate}</td>
        <td style={{ minWidth: "80px" }}>{approval2}</td>
        <td style={{ minWidth: "80px" }}>{expireDate2}</td>
        {isProcedureGroupping && <td style={{ minWidth: "85px" }} />}
        {isProcedureGroupping && <td style={{ minWidth: "85px" }} />}
        <td style={{ minWidth: "85px" }}>{doctor}</td>
        <td style={{ minWidth: "80px" }}>{getCompanyFormatDate(createdDate)}</td>
        <td style={{ minWidth: "174px" }}>{_.get(i, "priorApprovalComments", "")}</td>
        <td style={{ minWidth: "174px" }}>{_.get(i, "priorApprovalComments2", "")}</td>
        <td style={{ minWidth: "100px" }}>{note}</td>
      </tr>
    );
  });
}

export default class AppointmentPlannedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      openedGroups: {},
      tab: "planned",
    };
  }

  render() {
    const plannedList = [];
    const completedList = [];

    const planned = _.chain(this.props)
      .get("list")
      .filter((item) => (_.get(item, "status.name") === "Planned" ? item : null))
      .filter(
        (item) =>
          Boolean(!this.state.code) ||
          _.get(item, "treatmentItem.code", "").indexOf(this.state.code) >= 0,
      )
      .value();

    const completed = _.chain(this.props)
      .get("list")
      .filter((item) => (_.get(item, "status.name") === "Completed" ? item : null))
      .filter(
        (item) =>
          Boolean(!this.state.code) ||
          _.get(item, "treatmentItem.code", "").indexOf(this.state.code) >= 0,
      )
      .value();

    const withGrouping = localStorage.getItem("treatmentGroupping");

    const isProcedureGroupping = withGrouping === treatmentGroupping.byProcedure;

    const groupedPlannedList = isProcedureGroupping
      ? planned.reduce((groups, item) => {
          const procedureItem = _.get(item, "procedureItem");
          const procedureDate = _.get(item, "procedureKey");
          const createdDate = _.get(item, "createdDate");
          const procedureItemTooth = _.get(item, "tooth.name");
          const price = _.get(item, "price");

          const status = _.get(item, "status.code");

          const isCompleted = status === TransactionStatus.COMPLETED;

          if (procedureItem) {
            const current = _.get(groups, `item-${procedureDate}`, {
              list: [],
              price: 0,
              total: 0,
              remaining: 0,
            });
            const list = _.get(current, "list", []);

            list.push(item);

            groups[`item-${procedureDate}`] = {
              ...current,
              list,
              createdDate,
              type: "procedure",
              procedure: procedureItem,
              tooth: procedureItemTooth,
              price: current.price + _.toFinite(price),
              total: current.total + _.toFinite(price),
              remaining: isCompleted ? current.remaining : current.remaining + 1,
            };
          } else {
            const current = _.get(groups, "none", { list: [], price: 0 });
            const list = _.get(current, "list", []);

            list.push(item);

            groups.none = {
              ...current,
              list,
              type: "procedure",
              tooth: procedureItemTooth,
              price: current.price + _.toFinite(price),
            };
          }

          return groups;
        }, {})
      : planned;

    const groupedCompletedList = isProcedureGroupping
      ? completed.reduce((groups, item) => {
          const procedureItem = _.get(item, "procedureItem");
          const procedureDate = _.get(item, "procedureKey");
          const createdDate = _.get(item, "createdDate");
          const procedureItemTooth = _.get(item, "tooth.name");
          const price = _.get(item, "price");

          const status = _.get(item, "status.code");

          const isCompleted = status === TransactionStatus.COMPLETED;

          if (procedureItem) {
            const current = _.get(groups, `item-${procedureDate}`, {
              list: [],
              price: 0,
              total: 0,
              remaining: 0,
            });
            const list = _.get(current, "list", []);

            list.push(item);

            groups[`item-${procedureDate}`] = {
              ...current,
              list,
              createdDate,
              type: "procedure",
              procedure: procedureItem,
              tooth: procedureItemTooth,
              price: current.price + _.toFinite(price),
              total: current.total + _.toFinite(price),
              remaining: isCompleted ? current.remaining : current.remaining + 1,
            };
          } else {
            const current = _.get(groups, "none", { list: [], price: 0 });
            const list = _.get(current, "list", []);

            list.push(item);

            groups.none = {
              ...current,
              list,
              type: "procedure",
              tooth: procedureItemTooth,
              price: current.price + _.toFinite(price),
            };
          }

          return groups;
        }, {})
      : completed;

    if (isProcedureGroupping) {
      _.forEach(groupedPlannedList, (item, key) => {
        const list = _.get(item, "list");
        const procedure = _.get(item, "procedure");
        const total = _.get(item, "total");
        const remaining = _.get(item, "remaining");

        plannedList.push(
          <tr key={key} data-test={key}>
            <td colSpan={14} style={{ padding: 0 }}>
              <table
                cellPadding={0}
                cellSpacing={0}
                width="100%"
                className="table table-striped"
                style={{ margin: 0 }}
              >
                <thead>
                  <tr
                    onClick={() =>
                      this.setState((state) => ({
                        openedGroups: {
                          ...state.openedGroups,
                          [`${key}-planned`]: !state.openedGroups[`${key}-planned`],
                        },
                      }))
                    }
                  >
                    <td
                      className="description"
                      style={{
                        height: "50px",
                        color: "#ffffff",
                        cursor: "pointer",
                        padding: "0 12px",
                        lineHeight: "50px",
                        backgroundColor: "#049dd8",
                      }}
                      colSpan={14}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            minWidth: "274px",
                            width: "274px",
                          }}
                        >
                          {procedure ? procedure.name : "Without Procedure"}
                        </div>
                        <div
                          style={{
                            width: "85px",
                            minWidth: "85px",
                          }}
                        >
                          {procedure ? total : "-"}
                        </div>
                        <div
                          style={{
                            width: "85px",
                            minWidth: "85px",
                          }}
                        >
                          {procedure ? remaining : "-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                {this.state.openedGroups[`${key}-planned`] && <tbody>{generateList(list)}</tbody>}
              </table>
            </td>
          </tr>,
        );
      });

      _.forEach(groupedCompletedList, (item, key) => {
        const list = _.get(item, "list");
        const procedure = _.get(item, "procedure");
        const total = _.get(item, "total");
        const remaining = _.get(item, "remaining");

        completedList.push(
          <tr key={key} data-test={key}>
            <td colSpan={14} style={{ padding: 0 }}>
              <table
                cellPadding={0}
                cellSpacing={0}
                width="100%"
                className="table table-striped"
                style={{ margin: 0 }}
              >
                <thead>
                  <tr
                    onClick={() =>
                      this.setState((state) => ({
                        openedGroups: {
                          ...state.openedGroups,
                          [`${key}-completed`]: !state.openedGroups[`${key}-completed`],
                        },
                      }))
                    }
                  >
                    <td
                      className="description"
                      style={{
                        height: "50px",
                        color: "#ffffff",
                        cursor: "pointer",
                        padding: "0 12px",
                        lineHeight: "50px",
                        backgroundColor: "#049dd8",
                      }}
                      colSpan={14}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            minWidth: "274px",
                            width: "274px",
                          }}
                        >
                          {procedure ? procedure.name : "Without Procedure"}
                        </div>
                        <div
                          style={{
                            width: "85px",
                            minWidth: "85px",
                          }}
                        >
                          {procedure ? total : "-"}
                        </div>
                        <div
                          style={{
                            width: "85px",
                            minWidth: "85px",
                          }}
                        >
                          {procedure ? remaining : "-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                {this.state.openedGroups[`${key}-completed`] && (
                  <tbody>{generateList(list, true)}</tbody>
                )}
              </table>
            </td>
          </tr>,
        );
      });
    } else {
      plannedList.push(...generateList(groupedPlannedList));
      completedList.push(...generateList(groupedCompletedList));
    }

    return (
      <Modal
        size="lg"
        show={this.props.show}
        animation={false}
        backdropClassName="appointment-planned-backdrop-modal"
        className="appointment-planned-modal"
        onHide={this.props.onRequestClose}
        footerProps={{ className: "flex-column" }}
        actions={
          <div className="d-flex justify-content-between">
            <input
              value={this.state.code}
              className="form-control mr-2"
              style={{ width: "200px" }}
              placeholder="Filter by code"
              onChange={({ target }) => this.setState({ code: target.value })}
            />

            <Button onClick={this.props.onRequestClose}>Close</Button>
          </div>
        }
      >
        <div className="modal-body" style={{ paddingBottom: "0px" }}>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="d-flex">
              <Button
                className={cx("flex-grow-1 flex-shrink-1 mr-1", {
                  "bg-white text-black": this.state.tab !== "planned",
                })}
                onClick={() => this.setState({ code: "", tab: "planned" })}
              >
                Planned Items
              </Button>
              <Button
                className={cx("flex-grow-1 flex-shrink-1 ml-1", {
                  "bg-white text-black": this.state.tab !== "completed",
                })}
                onClick={() => this.setState({ code: "", tab: "completed" })}
              >
                Completed Items
              </Button>
            </div>

            {this.state.tab === "planned" && (
              <FlexBox
                style={{
                  overflowY: "auto",
                  maxHeight: "70vh",
                  minHeight: "70vh",
                }}
                onScroll={(event) => onScrollList(event, this.props)}
              >
                <Table style={{ marginBottom: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "100px", minWidth: "100px" }}>Code</th>
                      <th className="description" style={{ minWidth: "174px", width: "174px" }}>
                        Description
                      </th>
                      <th style={{ minWidth: "110px" }}>Tooth Number</th>
                      <th style={{ minWidth: "80px" }}>Price</th>
                      <th style={{ minWidth: "80px" }}>Approvals.1</th>
                      <th style={{ minWidth: "80px" }}>Expiry.1</th>
                      <th style={{ minWidth: "80px" }}>Approvals.2</th>
                      <th style={{ minWidth: "80px" }}>Expiry.2</th>
                      {isProcedureGroupping && <th style={{ minWidth: "85px" }}>Total</th>}
                      {isProcedureGroupping && <th style={{ minWidth: "85px" }}>Remaining</th>}
                      <th style={{ minWidth: "85px" }}>Doctor</th>
                      <th style={{ minWidth: "80px" }}>Created</th>
                      <th style={{ minWidth: "174px" }}>Ins.Note</th>
                      <th style={{ minWidth: "174px" }}>Ins.Note 2</th>
                      <th style={{ minWidth: "100px" }}>Note</th>
                    </tr>
                  </thead>

                  <tbody>
                    {plannedList}

                    <Show if={this.props.fetching}>
                      <tr>
                        <td colSpan={9}>
                          <div className="dental-react-spinner">
                            <ReactSpinner />
                          </div>
                        </td>
                      </tr>
                    </Show>
                  </tbody>
                </Table>
              </FlexBox>
            )}

            {this.state.tab === "completed" && (
              <FlexBox
                style={{
                  overflowY: "auto",
                  maxHeight: "70vh",
                  minHeight: "70vh",
                }}
                onScroll={(event) => onScrollList(event, this.props)}
              >
                <Table striped={true} style={{ marginBottom: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "100px", minWidth: "100px" }}>Code</th>
                      <th className="description" style={{ minWidth: "174px", width: "174px" }}>
                        Description
                      </th>
                      <th style={{ minWidth: "110px" }}>Tooth Number</th>
                      <th style={{ minWidth: "80px" }}>Price</th>
                      <th style={{ minWidth: "80px" }}>Approvals.1</th>
                      <th style={{ minWidth: "80px" }}>Expiry.1</th>
                      <th style={{ minWidth: "80px" }}>Approvals.2</th>
                      <th style={{ minWidth: "80px" }}>Expiry.2</th>
                      {isProcedureGroupping && <th style={{ minWidth: "85px" }}>Total</th>}
                      {isProcedureGroupping && <th style={{ minWidth: "85px" }}>Remaining</th>}
                      <th style={{ minWidth: "85px" }}>Doctor</th>
                      <th style={{ minWidth: "80px" }}>Created</th>
                      <th style={{ minWidth: "80px" }}>Ins.Note</th>
                      <th style={{ minWidth: "80px" }}>Ins.Note 2</th>
                      <th style={{ minWidth: "100px" }}>Note</th>
                    </tr>
                  </thead>

                  <tbody>
                    {completedList}

                    <Show if={this.props.fetching}>
                      <tr>
                        <td colSpan={9}>
                          <div className="dental-react-spinner">
                            <ReactSpinner />
                          </div>
                        </td>
                      </tr>
                    </Show>
                  </tbody>
                </Table>
              </FlexBox>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
