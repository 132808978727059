import React from "react";

import Show from "../../widgets/Show";

export default function FindAppointmentSidebarHeader({
  searchMode,
  createMode,
  onBackClick,
  onSearchClick,
  onCreateClick,
  setActiveTab,
  activeTab,
}) {
  return (
    <div className="modal-header clearfix">
      <button className="btn btn-link pull-left" onClick={onBackClick} type="button">
        ❮ &nbsp; BACK
      </button>
      <Show if={searchMode}>
        <button
          className="btn btn-link pull-right"
          onClick={onSearchClick}
          type="submit"
          form="findAppointmentSidebar"
        >
          SEARCH
        </button>
      </Show>
      <Show if={createMode}>
        <button className="btn btn-link pull-right" onClick={onCreateClick} type="button">
          Create Appt.
        </button>
      </Show>
      <Show if={searchMode}>
        <div className="title">Search Appointment</div>
      </Show>
      <Show if={!searchMode}>
        <div className="title">
          <div className="btn-group">
            <button
              className={`btn ${activeTab === "all" ? "btn-default" : "btn-success"}`}
              onClick={() => setActiveTab("all")}
            >
              All Appointments
            </button>
            <button
              className={`btn ${activeTab === "active" ? "btn-default" : "btn-success"}`}
              onClick={() => setActiveTab("active")}
            >
              Active Appointments
            </button>
            <button
              className={`btn ${activeTab === "past" ? "btn-default" : "btn-success"}`}
              onClick={() => setActiveTab("past")}
            >
              Past Appointments
            </button>
          </div>
        </div>
      </Show>
    </div>
  );
}
