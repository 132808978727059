import React, { useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import leftIcon from "../../../assets/images/arrows/down_arrow.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  perioExamHandleItem,
  perioExamTempClearItem,
  perioExamTempItem,
} from "../../../actions/patientActions";
import Utils from "../../../helpers/Utils";

const PerioExamChartCalculator = ({ item }) => {
  const [inputValue, setinputValue] = useState("");
  const [focuseElem, setFocuseElem] = useState();
  const [pdLine, setPdLine] = useState(true);
  const [gmLine, setGmLine] = useState(true);
  const {
    patient: { perioExamItemHandle, perioExamItem, perioExamMovementType },
    chart: { teeth },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();

  const refs =
    pdLine && !gmLine
      ? [inputRef1, inputRef2, inputRef3]
      : gmLine && !pdLine
      ? [inputRef4, inputRef5, inputRef6]
      : [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6];
  const onChangeForm = (value) => {
    dispatch(perioExamTempItem({ [inputValue]: value }));
    (inputValue == "probingDepthDistal" || inputValue == "gingivalMarginDistal") &&
      dispatch(
        perioExamTempItem({
          clinicalAttachmentDistal: item.clinicalAttachmentDistal + value,
        }),
      );
    (inputValue == "probingDepthCentral" || inputValue == "gingivalMarginCentral") &&
      dispatch(
        perioExamTempItem({
          clinicalAttachmentCentral: item.clinicalAttachmentCentral + value,
        }),
      );
    (inputValue == "probingDepthMesial" || inputValue == "gingivalMarginMesial") &&
      dispatch(
        perioExamTempItem({
          clinicalAttachmentMesial: item.clinicalAttachmentMesial + value,
        }),
      );

    const index = (refs || []).findIndex(
      (item) => item.current.tabIndex == focuseElem?.current.tabIndex,
    );

    refs[index + 1]
      ? refs[index + 1]?.current?.focus()
      : pdLine && !gmLine
      ? inputRef3.current.focus()
      : inputRef6.current.focus();

    if (pdLine && !gmLine && inputValue == "probingDepthMesial") {
      inputRef1.current.focus();
      movementPosition();
      return;
    }
    if (!pdLine && gmLine && inputValue == "gingivalMarginMesial") {
      inputRef4.current.focus();
      movementPosition();
      return;
    } else if (inputValue == "gingivalMarginMesial") {
      pdLine && inputRef1.current.focus();
      !pdLine && gmLine && inputRef3.current.focus();
      movementPosition();
      return;
    }
  };

  const movementPosition = () => {
    const { teethList, item } = perioExamItemHandle;
    const up = [];
    const down = [];
    Utils.objectForEach(teeth, (tooth, toothPositionCode) => {
      const index = toothPositionCode.slice(1);

      if (index <= 16) {
        up[index - 1] = tooth;
      } else {
        down[32 - index] = tooth;
      }
    });

    const index = (teethList || []).findIndex(
      (toothItem) => toothItem?.normal?.details?.id === item?.normal?.details?.id,
    );
    dispatch(perioExamTempClearItem());
    if (perioExamMovementType === "TYPE_1" && teethList[index]) {
      if (index == teethList.length - 1 && perioExamItemHandle.side !== "Back_Side") {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[0]?.image && item?.toothSide?.code == "Back_Side",
        );

        element && dispatch(perioExamTempItem(element));
        dispatch(perioExamHandleItem({ item: teethList[0], side: "Back_Side" }));
        return;
      }
      if (index == teethList.length - 1 && perioExamItemHandle.side == "Back_Side") {
        const element = (perioExamItem.perioExamList || []).find(
          (item) => item?.tooth?.name == down[0]?.image && item?.toothSide?.code == "Front_Side",
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(
          perioExamHandleItem({ item: down[0], side: "Front_Side", teethList: down, row: "lower" }),
        );
        return;
      } else {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.code === teethList[index + 1]?.normal?.details?.id &&
            item?.toothSide?.code === perioExamItemHandle.side,
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(perioExamHandleItem({ item: teethList[index + 1] }));
      }
    }
    if (perioExamMovementType === "TYPE_2" && teethList[index]) {
      if (perioExamItemHandle.side == "Back_Side" && index == teethList.length - 1) {
        const element = (perioExamItem.perioExamList || []).find(
          (item) => item?.tooth?.name == down[0]?.image && item?.toothSide?.code == "Front_Side",
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(
          perioExamHandleItem({ item: down[0], side: "Front_Side", teethList: down, row: "lower" }),
        );
        return;
      }
      if (perioExamItemHandle.side == "Front_Side") {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[index]?.image &&
            item?.toothSide?.code == perioExamItemHandle.side,
        );
        dispatch(perioExamHandleItem({ item: teethList[index], side: "Back_Side" }));
        element && dispatch(perioExamTempItem(element));
      } else {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[index + 1]?.image &&
            item?.toothSide?.code == perioExamItemHandle.side,
        );
        dispatch(perioExamHandleItem({ item: teethList[index + 1], side: "Front_Side" }));
        element && dispatch(perioExamTempItem(element));
      }
    }
    if (perioExamMovementType === "TYPE_3" && teethList[index]) {
      if (index == teethList.length - 1 && perioExamItemHandle.side !== "Back_Side") {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[teethList.length - 1]?.image &&
            item?.toothSide?.code == "Back_Side",
        );

        element && dispatch(perioExamTempItem(element));
        dispatch(perioExamHandleItem({ item: teethList[teethList.length - 1], side: "Back_Side" }));
        return;
      }
      if (index == 0 && perioExamItemHandle.side == "Back_Side") {
        const element = (perioExamItem.perioExamList || []).find(
          (item) => item?.tooth?.name == down[0]?.image && item?.toothSide?.code == "Front_Side",
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(
          perioExamHandleItem({ item: down[0], side: "Front_Side", teethList: down, row: "lower" }),
        );
        return;
      }
      if (perioExamItemHandle.side == "Back_Side" && teethList[index - 1]) {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[index - 1]?.image &&
            item?.toothSide?.code == perioExamItemHandle.side,
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(perioExamHandleItem({ item: teethList[index - 1] }));
      } else {
        const element = (perioExamItem.perioExamList || []).find(
          (item) =>
            item?.tooth?.name == teethList[index + 1]?.image &&
            item?.toothSide?.code == perioExamItemHandle.side,
        );
        element && dispatch(perioExamTempItem(element));
        dispatch(perioExamHandleItem({ item: teethList[index + 1] }));
      }
    }
  };

  const arrowClick = (position) => {
    if (focuseElem?.current.tabIndex) {
      const index = (refs || []).findIndex(
        (item) => item.current.tabIndex == focuseElem?.current.tabIndex,
      );
      if (position === "left") {
        return refs[index - 1] ? refs[index - 1]?.current?.focus() : inputRef1.current.focus();
      }
      if (position === "right") {
        return refs[index + 1] ? refs[index + 1]?.current?.focus() : inputRef6.current.focus();
      }
    } else {
      inputRef1.current.focus();
    }
  };
  return (
    <div style={{ margin: "10px 20px", height: "70%", overflowY: "scroll" }}>
      <InputsWrapper>
        <div style={{ paddingTop: 10, display: "flex", flexDirection: "column", gap: 10 }}>
          <Title>PD</Title>
          <Title>GM</Title>
          <Title>CA</Title>
        </div>
        <Inputs>
          <Input
            tabIndex={1}
            ref={inputRef1}
            value={item?.probingDepthDistal}
            readOnly={!pdLine}
            onFocus={() => {
              pdLine && setinputValue("probingDepthDistal");
              pdLine && setFocuseElem(inputRef1);
            }}
          />
          <Input
            tabIndex={2}
            ref={inputRef2}
            value={item?.probingDepthCentral}
            readOnly={!pdLine}
            onFocus={() => {
              pdLine && setinputValue("probingDepthCentral");
              pdLine && setFocuseElem(inputRef2);
            }}
          />
          <Input
            tabIndex={3}
            ref={inputRef3}
            value={item?.probingDepthMesial}
            readOnly={!pdLine}
            onFocus={() => {
              pdLine && setinputValue("probingDepthMesial");
              pdLine && setFocuseElem(inputRef3);
            }}
          />
          <Input
            tabIndex={4}
            ref={inputRef4}
            value={item?.gingivalMarginDistal}
            readOnly={!gmLine}
            onFocus={() => {
              gmLine && setinputValue("gingivalMarginDistal");
              gmLine && setFocuseElem(inputRef4);
            }}
          />
          <Input
            tabIndex={5}
            ref={inputRef5}
            value={item?.gingivalMarginCentral}
            readOnly={!gmLine}
            onFocus={() => {
              gmLine && setinputValue("gingivalMarginCentral");
              gmLine && setFocuseElem(inputRef5);
            }}
          />
          <Input
            tabIndex={6}
            ref={inputRef6}
            value={item?.gingivalMarginMesial}
            readOnly={!gmLine}
            onFocus={() => {
              setinputValue("gingivalMarginMesial");
              setFocuseElem(inputRef6);
            }}
          />
          <InputReadOnly
            value={item?.clinicalAttachmentDistal}
            readOnly={true}
            // onFocus={() => {
            //   setinputValue("clinicalAttachmentDistal");
            //   setFocuseElem(inputRef7);
            // }}
          />
          <InputReadOnly
            value={item?.clinicalAttachmentCentral}
            readOnly={true}
            // onFocus={() => {
            //   setinputValue("clinicalAttachmentCentral");
            //   setFocuseElem(inputRef8);
            // }}
          />
          <InputReadOnly
            value={item?.clinicalAttachmentMesial}
            readOnly={true}
            // onFocus={() => {
            //   setinputValue("clinicalAttachmentMesial");
            //   setFocuseElem(inputRef9);
            // }}
          />
        </Inputs>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <Checkbox checked={pdLine} onChange={(ev) => setPdLine(ev.target.checked)} />
          <Checkbox checked={gmLine} onChange={(ev) => setGmLine(ev.target.checked)} />
        </div>
      </InputsWrapper>
      <ButtonsWrapper>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, index) => (
          <Button key={index} onClick={() => onChangeForm(item)}>
            {item}
          </Button>
        ))}
        <Button onClick={() => arrowClick("left")}>
          <img src={leftIcon} style={{ transform: "rotate(90deg)" }} alt="arrow" />
        </Button>
        <Button onClick={() => arrowClick("right")}>
          <img src={leftIcon} style={{ transform: "rotate(-90deg)" }} alt="arrow" />
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  //align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 390px;
`;

const Title = styled.p`
  font-size: 23px;
  font-weight: bold;
`;
const Input = styled.input`
  border: 1px solid #7c7c7c;
  width: 120px;
  height: 40px;
  border-radius: 6px;
  font-size: 20px;
  text-align: center;
  &:focus {
    border: 1px solid blue;
    background: #eee;
  }
`;
const InputReadOnly = styled.input`
  border: 1px solid #7c7c7c;
  width: 120px;
  height: 40px;
  border-radius: 6px;
  font-size: 20px;
  text-align: center;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  //width: 480px;
`;
const Button = styled.button`
  border: 1px solid #7c7c7c;
  background: #fff;
  width: 110px;
  height: 65px;
  border-radius: 6px;
  font-size: 20px;
  text-align: center;
`;

export default PerioExamChartCalculator;
