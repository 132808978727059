import React from "react";

import AsyncSelect from "react-select/async";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import { hexToRgba } from "../../utils/FormatUtils";
import { connect, useDispatch } from "react-redux";
import { getSelectPickerAsyncOptions } from "../../actions/sessionActions";
import Utils from "../../helpers/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "34px",
    color: "black",

    "&.control-lg": {
      height: "48px",

      "& .select-picker__control": {
        minHeight: "34px",

        "& > .select-picker__value-container, & > .select-picker__indicators": {
          height: "46px",
          borderRadius: "6px",
        },
      },
    },

    "& .select-picker__menu": {
      width: "auto",
      minWidth: "100%",
    },

    "& .select-picker__control": {
      cursor: "pointer",
      minHeight: "34px",

      "& > .select-picker__value-container, & > .select-picker__indicators": {
        height: "32px",
        "& > span": {
          background: "unset",
        },
      },

      "&.select-picker__control--is-focused": {
        borderColor: `${theme.palette.primary.main} !important`,
        boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${hexToRgba(
          theme.palette.primary.main,
          {
            opacity: 0.6,
          },
        )}`,
      },
    },
  },
}));

const updateSelectSearch = Utils.debounce((action) => {
  action();
}, 400);

const enhancer = connect(({ session: { selectPickerAsyncOptions } }) => ({
  selectPickerAsyncOptions,
}));

const SelectPickerAsync = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterOptions = (inputValue, options) => {
    return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const doRequest = (params, callback) => {
    const { url, defaultAllOption } = props;
    dispatch(getSelectPickerAsyncOptions({ ...params, url })).then((data) => {
      if ((data.list || []).length) {
        const options = data.list.map((item) => ({
          value: item.id,
          label: props?.withoutCodeLabel
            ? `${item.description}`
            : `${item.code}, ${item.description}`,
        }));
        const customizeOptions = defaultAllOption
          ? [{ value: "-1", label: "All" }, ...options]
          : options;
        callback(filterOptions(params.filterKey, customizeOptions));
      } else {
        callback([]);
      }
    });
  };

  const loadOptions = (inputValue, callback) => {
    const params = {
      limit: props?.limit || 50,
      filterKey: inputValue,
    };
    doRequest(params, callback);
  };

  const { size, onChange } = props;

  return (
    <AsyncSelect
      {...props}
      className={cx(classes.root, { "control-lg": size === "lg" }, props.className)}
      classNamePrefix="select-picker"
      cacheOptions={true}
      loadOptions={(inputValue, callback) => {
        updateSelectSearch(() => {
          loadOptions(inputValue, callback);
        });
      }}
      noOptionsMessage={() => "No options"}
      defaultOptions={true}
      onChange={onChange}
      ariaLive="polite"
    />
  );
};

SelectPickerAsync.defaultProps = {
  withoutCodeLabel: false,
  defaultAllOption: false,
};

export default enhancer(SelectPickerAsync);
