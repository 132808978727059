import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import FilledImage from "../../../widgets/FilledImage";
import questionTypeCodes from "../../../../constants/chart-sidebar/questionTypeCodes";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideVitalActions from "../../../../actions/chartSideVitalActions";
import checkImage from "../../../../assets/images/call-list/check_2x.png";
import { DatePicker } from "../../../ui/DatePicker";

const enhancer = connect(null, bindActions({ chartSideVitalActions }));
export default enhancer(
  class ChartVitalQuestion extends React.Component {
    constructor(...args) {
      super(...args);

      const answers = this.props.question.answers || [];

      this.state = {
        timerId: 0,
        textarea: _.head(answers) || "",
      };
    }

    changeTextarea = (value) => {
      if (this.state.timerId) {
        clearTimeout(this.state.timerId);
      }

      this.setState({
        ...this.state,
        textarea: value,
      });
      const timerId = setTimeout(() => {
        this.props.chartSideVitalActions.changeQuestionTextarea(value, this.props.question.id);
      }, 200);

      this.setState({ timerId });
    };

    changeListOfAnswers = (value) => {
      return () => {
        this.props.chartSideVitalActions.changeListOfAnswers(value, this.props.question.id);
      };
    };

    changeListOfAnswersMultiple = (value) => {
      return () => {
        this.props.chartSideVitalActions.changeListOfAnswersMultiple(value, this.props.question.id);
      };
    };

    changeDatePicker = (date) => {
      this.props.chartSideVitalActions.changeDatePicker(date, this.props.question.id);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      const newQuestion = this.props.question.id !== nextProps.question.id;
      const questionTypeCode = _.get(nextProps, "question.questionType.code");
      const textarea =
        questionTypeCode === questionTypeCodes.TEXT ||
        questionTypeCode === questionTypeCodes.TEXT_AREA;

      if (newQuestion && textarea) {
        let value =
          _.get(nextProps, "question.answers[0]", "") ||
          _.get(nextProps, "question.defaultAnswers[0]", "");
        setTimeout(() => {
          this.props.chartSideVitalActions.changeQuestionTextarea(value, this.props.question.id);
          this.refs.textarea.value = _.unescape(value);
        }, 0);
      }
    }

    render() {
      const { question } = this.props;

      const questionTypeCode = (question.questionType && question.questionType.code) || "";

      if (questionTypeCode === questionTypeCodes.RADIO_LIST) {
        const answers = question.answers || [];
        const $values = question.values.map((value, index) => {
          let $arrow = null;
          const selected = answers.indexOf(value) !== -1;
          if (selected) {
            $arrow = (
              <div className="arrow pull-right">
                <FilledImage color="#23b7e5" src={checkImage} className="zoom-2-5x" />
              </div>
            );
          }
          return (
            <button
              key={index}
              onClick={this.changeListOfAnswers(value)}
              className="list-group-item"
            >
              {$arrow}
              {value}
            </button>
          );
        });
        return <div className="list-group">{$values}</div>;
      }
      if (
        questionTypeCode === questionTypeCodes.TEXT ||
        questionTypeCode === questionTypeCodes.TEXT_AREA
      ) {
        return (
          <textarea
            ref="textarea"
            value={_.unescape(this.state.textarea)}
            onChange={(event) => this.changeTextarea(_.escape(event.target.value))}
          />
        );
      }
      if (questionTypeCode === questionTypeCodes.DATE_PICKER) {
        return (
          <div className="date-picker">
            <DatePicker
              inline={true}
              selected={
                (question.answers &&
                  (moment.isMoment(question.answers[0])
                    ? question.answers[0]
                    : moment(+question.answers[0]))) ||
                moment()
              }
              onChange={this.changeDatePicker}
            />
          </div>
        );
      }
      if (questionTypeCode === questionTypeCodes.CHECKBOX_LIST) {
        const answers = question.answers || [];
        const $values = question.values.map((value, index) => {
          let $arrow = null;
          const selected = answers.indexOf(value) !== -1;
          if (selected) {
            $arrow = (
              <div className="arrow pull-right">
                <FilledImage color="#23b7e5" src={checkImage} className="zoom-2-5x" />
              </div>
            );
          }
          return (
            <button
              key={index}
              onClick={this.changeListOfAnswersMultiple(value)}
              className="list-group-item"
            >
              {$arrow}
              {value}
            </button>
          );
        });
        return <div className="list-group">{$values}</div>;
      }

      return null;
    }
  },
);
