import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetConsultationId } from "../../../../actions/chartSideMedicalFillInFormActions";

const MedicalFIllInFormEntryPage = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(resetConsultationId());
  // }, []);
  return <div className="medical-fill-in-form-entry-buttons"></div>;
};

export default MedicalFIllInFormEntryPage;
