import React, { useEffect } from "react";
import { connect } from "react-redux";

import Show from "../../../widgets/Show";

import ChartFillInFormCreatedDate from "../clinical-note/ChartClinicalNoteCreatedDate";
import ReduxCKEditor from "../../../widgets/redux/ReduxCKEditor";
import { Field, useFormik, FormikProvider } from "formik";

const enhancer = connect(({ chartSideMedicalFillInForm, session: { permissions } }) => {
  return {
    initialValues: {
      name: chartSideMedicalFillInForm.item.name || "",
      note: chartSideMedicalFillInForm.item.content || "",
      referenceFillInFormId: chartSideMedicalFillInForm.medicalFillInFormAddendumId,
    },
    permissions,
  };
});

const MedicalFormsTextAreaForm = (props) => {
  const { onSubmit, initialValues } = props;
  const { edit, item } = props;

  const form = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(
    () => () => {
      form.resetForm();
    },
    [],
  );

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit} className="clinical-note-textarea">
        <div className="clinical-note-textarea-name">
          <input
            name="name"
            type="text"
            className="form-control input-lg"
            placeholder="Note Name"
            autoFocus={true}
            {...form.getFieldProps("name")}
          />
        </div>
        <div className="clinical-note-textarea-body">
          <Field
            name="note"
            id="clinical-note-textarea-body"
            className="form-control"
            {...form.getFieldProps("note")}
          >
            {() => (
              <ReduxCKEditor
                input={{
                  value: form.values.note,
                  onChange: (value) =>
                    form.setFieldValue("note", value.length >= 1000 ? value.slice(0, 1000) : value),
                }}
              />
            )}
          </Field>
        </div>
        <div className="clinical-note-textarea-footer">
          <div
            style={
              form.values.note.length === 1000
                ? {
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 20,
                    transition: "all 0.5s ease-in-out",
                  }
                : { transition: "all 0.5s ease-in-out" }
            }
          >
            {form.values.note.length}/1000
          </div>
          <Show if={edit}>
            {/*could be updated with permission*/}
            <ChartFillInFormCreatedDate changeCreatedDate={props.changeCreatedDate} item={item} />
          </Show>
          <button type="submit" className="btn btn-primary btn-lg btn-block">
            Save
          </button>
        </div>
      </form>
    </FormikProvider>
  );
};

export default enhancer(MedicalFormsTextAreaForm);
