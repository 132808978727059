import _ from "lodash";
import React from "react";

import { SidebarTypes } from "./TypeButtonGroup";
import { SidebarContentContainer } from "./SidebarContentContainer";
import { SidebarItem } from "./SidebarItem";
import SidebarProcedureCategoryList from "./SidebarProcedureCategoryList";

export function SidebarProcedures({
  item,
  isItem,
  member,
  openItem,
  initItem,
  onSearch,
  pushStack,
  searchValue,
  stack = [],
  toothNumericType,
  chartSideSeries,
  backFromItem,
  onRequestClose,
  changeActiveTooth,
  popStack,
  changeTypeTab,
  getCategoryList,
  getGroupProcedureList,
  history,
  location,
  isDynamicPackage,
  getSidebarTreatmentList,
}) {
  return (
    <SidebarContentContainer
      isItem={isItem}
      onSearch={(x) => {
        onSearch(x);
        if (isDynamicPackage) {
          getSidebarTreatmentList();
        } else if (!isItem) {
          getCategoryList();
        } else {
          getGroupProcedureList();
        }
      }}
      searchValue={searchValue}
    >
      {isItem && (
        <SidebarItem
          item={item}
          member={member}
          initItem={initItem}
          popStack={popStack}
          backFromItem={backFromItem}
          changeTypeTab={changeTypeTab}
          onRequestClose={onRequestClose}
          typeTab={SidebarTypes.Grouped}
          chartSideSeries={chartSideSeries}
          toothNumericType={toothNumericType}
          changeActiveTooth={changeActiveTooth}
        />
      )}

      {!isItem && (
        <SidebarProcedureCategoryList
          openItem={openItem}
          popStack={popStack}
          pushStack={pushStack}
          lastStack={_.last(stack)}
          chartSideSeries={chartSideSeries}
          history={history}
          location={location}
          isDynamicPackage={isDynamicPackage}
        />
      )}
    </SidebarContentContainer>
  );
}
