import React from "react";
import { Modal } from "../../../ui/Modal";
import { Button } from "react-bootstrap";

const ChartFillInFormReviewModal = ({ reviewModalVisible, closeReviewModal, fillInFormReview }) => {
  return (
    <Modal
      size="small"
      show={reviewModalVisible}
      onHide={closeReviewModal}
      actions={
        <div>
          <Button onClick={closeReviewModal}>No</Button>
          <Button
            onClick={() => {
              fillInFormReview();
              closeReviewModal();
            }}
          >
            Yes
          </Button>
        </div>
      }
      keyboard={false}
    >
      <div>This Document will be marked as reviewed, are you sure you want to continue ?</div>
    </Modal>
  );
};

export default ChartFillInFormReviewModal;
