import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import TimePicker from "rc-time-picker/es/TimePicker";

import DataList from "../../widgets/DataList";
import bindActions from "../../../helpers/bindActions";
import * as blockedTimeSlotActions from "../../../actions/blockedTimeSlotActions";
import { getCompanyDate, getCompanyStartOfDay } from "../../../helpers/DateUtils";
import { Modal } from "../../ui/Modal";
import { Routes } from "../../../constants/Routes";

const enhancer = connect(
  ({ blockedTimeSlot }) => ({ blockedTimeSlot }),
  bindActions({ blockedTimeSlotActions }),
);

class BlockedTimeSlotTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: undefined,
      time: getCompanyDate(moment(_.toFinite(props.query.startTime))),
    };
  }

  componentDidMount() {
    const { blockedTimeSlotActions } = this.props;

    blockedTimeSlotActions.getBlockedTemplatesList();
  }

  render() {
    const { query, blockedTimeSlot, blockedTimeSlotActions } = this.props;

    return (
      <Modal
        show={true}
        animation={false}
        keyboard={false}
        onHide={this.props.onRequestClose}
        dialogClassName="find-open-slot-sidebar"
      >
        <div className="modal-header clearfix">
          <button
            className="btn btn-link pull-left"
            onClick={this.props.onRequestClose}
            type="button"
          >
            ❮ &nbsp; BACK
          </button>
          <div className="title" style={{ paddingRight: "49px" }}>
            Create Blocked Time Slot
          </div>
        </div>

        <div style={{ margin: 0 }}>
          <div
            style={{
              display: "flex",
              marginTop: "12px",
              marginBottom: "12px",
              justifyContent: "center",
            }}
          >
            <TimePicker
              showSecond={false}
              defaultValue={this.state.time}
              className="time-picker"
              onChange={(value) => this.setState({ time: value })}
              use12Hours={true}
            />
          </div>

          <DataList
            fetching={blockedTimeSlot.templateListFetching}
            onScroll={() => blockedTimeSlotActions.loadBlockedTemplatesList()}
          >
            {!blockedTimeSlot.templateListFetching && blockedTimeSlot.templateListTotalCount === 0 && (
              <span
                style={{
                  textAlign: "center",
                  marginTop: "12px",
                  display: "block",
                  color: "#817b7b",
                }}
              >
                Blocked Time Slot Templates Empty
              </span>
            )}

            {!blockedTimeSlot.templateListFetching &&
              blockedTimeSlot.templateList.map((x) => (
                <div key={x.id} style={{ display: "flex" }}>
                  <button
                    onClick={() => this.setState({ modal: x })}
                    style={{
                      flex: 1,
                      margin: "4px",
                      display: "flex",
                      padding: "12px",
                      background: "none",
                      borderRadius: "8px",
                      flexDirection: "column",
                      border: "1px solid #ccc",
                    }}
                  >
                    <span>
                      Name: <b>{x.blockedTimeSlotName || x.name}</b>
                    </span>
                    <span>
                      Reason: <b>{x.reason}</b>
                    </span>
                  </button>
                </div>
              ))}
          </DataList>

          {!_.isEmpty(this.state.modal) && (
            <Modal
              show={this.state.modal}
              size="small"
              onHide={() => this.setState({ modal: undefined })}
              actions={
                <div>
                  <Button onClick={() => this.setState({ modal: undefined })}>Close</Button>
                  <Button
                    onClick={() => {
                      const { dentalPoint, isPublic, dentist } = query;
                      const { time: startTime } = this.state;

                      const time = _.toFinite(startTime.valueOf());

                      const { id, blockedTimeSlotName, ...data } = this.state.modal;

                      this.setState({ modal: undefined });

                      const newData = {
                        ...data,
                        template: { id },
                        name: blockedTimeSlotName,
                        startDate: getCompanyStartOfDay(time),
                        startTime: time - getCompanyStartOfDay(time),
                        endTime: time - getCompanyStartOfDay(time) + 60000 * data.length,
                      };

                      if (isPublic === "true") {
                        newData.points = [{ id: dentalPoint }];
                      } else {
                        newData.members = [{ id: dentist }];
                      }

                      blockedTimeSlotActions
                        .createBlockedTimeSlotFromTemplate(newData)
                        .then((response) => {
                          if (response) {
                            this.props.history.push(Routes.DashboardCalendar);
                          }
                        });
                    }}
                  >
                    Ok
                  </Button>
                </div>
              }
            >
              <div>Are you sure you want to create blocked time slot?</div>
            </Modal>
          )}
        </div>
      </Modal>
    );
  }
}

export default enhancer(BlockedTimeSlotTemplates);
