import React from "react";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

export default class RepeatAppointmentConfirmModal extends React.Component {
  render() {
    const { text, show, handleClose, handleOK } = this.props;

    return (
      <Modal
        size="sm"
        show={show}
        keyboard={false}
        onHide={handleClose}
        actions={
          <div>
            <Button type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="button" className="btn-primary" onClick={handleOK}>
              OK
            </Button>
          </div>
        }
      >
        <div>{text}</div>
      </Modal>
    );
  }
}
