import React from "react";
import { noop } from "lodash";
import { connect } from "react-redux";
import { FormCheck } from "react-bootstrap";

import LargeModal from "../../widgets/LargeModal";
import TransactionSearchParameters from "../../../models/TransactionSearchParameters";

import { setPopup, setPopupActionByKey, setPopupParams } from "../../../actions/popupActions";

import {
  addKeysWithCheckbox,
  completeTransaction,
  getListForComplete,
  selectAllCheckboxCompletePopup,
  setCompletePopupSearchParameters,
} from "../../../actions/transactionActions";
import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";
import Utils from "../../../helpers/Utils";

const enhancer = connect(
  ({
    session: { toothNumericType, member },
    chart,
    chartSideSeries,
    appointment,
    patient,
    transaction,
    popup: { large },
  }) => {
    return {
      toothNumericType,
      member,
      chart,
      chartSideSeries,
      appointment,
      patient,
      transaction,
      large,
    };
  },
  {
    setPopup,
    setPopupParams,
    setPopupActionByKey,
    getListForComplete,
    setCompletePopupSearchParameters,
    completeTransaction,
    addKeysWithCheckbox,
    selectAllCheckboxCompletePopup,
  },
);

class TreatmentCompletePopup extends React.Component {
  componentDidMount() {
    let searchParams = new TransactionSearchParameters();
    this.loadList(searchParams);
  }

  loadList = (searchParams) => {
    const { setCompletePopupSearchParameters, getListForComplete } = this.props;

    setCompletePopupSearchParameters(searchParams);
    getListForComplete(searchParams.getQuery()).then(noop);
  };

  render() {
    const { transaction, addKeysWithCheckbox, selectAllCheckboxCompletePopup } = this.props;

    let totalCount = 0;

    (transaction.listForComplete.list || []).forEach((item) => {
      if (item.parts) {
        totalCount += item.parts.length;
      } else {
        totalCount += 1;
      }
    });

    const isSelectedALl =
      transaction.listForComplete.list &&
      transaction.completePopup.selectedKeys.length === totalCount;

    const tableHeadArr = [
      "Date posted",
      "Treatment code",
      "Tooth",
      "Description",
      "Fee",
      "Principal",
      "Posted by",
      <FormCheck
        key="checkbox"
        checked={isSelectedALl}
        onChange={() => selectAllCheckboxCompletePopup(isSelectedALl)}
      />,
    ];
    const widths = [100, 80, 50, 200, 100, 50, 120, 50];
    const $ths = tableHeadArr.map((item, index) => (
      <th key={index} width={widths[index]}>
        {item}
      </th>
    ));

    const tBody = [];
    transaction.listForComplete &&
      transaction.listForComplete.list &&
      transaction.listForComplete.list.forEach((item, index) => {
        let arr = [];
        let price = (item.price && parseFloat(item.price).toFixed(2)) || "0.00";
        let treatmentItem = item.treatmentItem || item.diagnosisItem;
        let type = item.type;
        let status = item.status;
        let statusName = status && status.name;
        let assignedDoctor = item.assignedDoctor || item.createdDentist;
        let completedDate = Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(item.completedDate),
          DATE_FORMAT_LEGACY,
        );

        arr.push(completedDate);
        arr.push(treatmentItem && treatmentItem.code);
        arr.push(
          (item.toothPosition && item.toothPosition.code && item.toothPosition.code.slice(1)) || "",
        ); //Tooth
        arr.push(treatmentItem && treatmentItem.description); //Description
        arr.push(price); //Fee
        arr.push((item.diagnosisItem && item.diagnosisItem.code) || ""); //Fee
        arr.push((assignedDoctor && assignedDoctor.name) || "");

        const findIndex = transaction.completePopup.selectedKeys.indexOf(item.key);

        if (!item.parts) {
          arr.push(
            <FormCheck checked={findIndex > -1} onChange={() => addKeysWithCheckbox(item.key)} />,
          );
        } else {
          arr.push(null);
        }

        const tdArray = arr.map((td, tdIndex) => {
          return (
            <td key={tdIndex}>
              <span>{td}</span>
            </td>
          );
        });
        let className = "tr ";
        if (arr && arr.length) {
          if (statusName) {
            className += `status-${statusName.toLowerCase()} `;
          }
          if (type && type.name) {
            className += `type-${type.name.toLowerCase()} `;
          }
        }

        tBody.push(
          <tr key={index} className={className}>
            {tdArray}
          </tr>,
        );

        if (item.parts) {
          item.parts.forEach((part) => {
            let arr = [];

            arr.push(completedDate);
            arr.push(treatmentItem && treatmentItem.code + " (v." + part.number + ")");
            arr.push(
              (item.toothPosition && item.toothPosition.code && item.toothPosition.code.slice(1)) ||
                "",
            ); //Tooth
            arr.push(null); //Description
            arr.push((+part.price).toFixed(2)); //Fee
            arr.push((item.diagnosisItem && item.diagnosisItem.code) || ""); //Fee
            arr.push((assignedDoctor && assignedDoctor.name) || "");

            const findIndex = transaction.completePopup.selectedKeys.indexOf(part.key);

            if (part.status && part.status.code === "DentalTransactionStatus_COMPLETED") {
              arr.push(null);
            } else {
              arr.push(
                <FormCheck
                  checked={findIndex > -1}
                  onChange={() => addKeysWithCheckbox(part.key)}
                />,
              );
            }

            const tdArray = arr.map((td, tdIndex) => {
              return (
                <td key={tdIndex}>
                  <span>{td}</span>
                </td>
              );
            });

            tBody.push(
              <tr key={part.key} className={className}>
                {tdArray}
              </tr>,
            );
          });
        }
      });
    return (
      <div className="large-modal-wrap">
        <LargeModal>
          <div className="scroll-wrap">
            <table className="table">
              <thead>
                <tr>{$ths}</tr>
              </thead>
              <tbody>
                asfasdf
                {tBody}
              </tbody>
            </table>
          </div>
        </LargeModal>
      </div>
    );
  }
}

export default enhancer(TreatmentCompletePopup);
