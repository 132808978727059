import _ from "lodash";

import * as ActionType from "../actionTypes/chartSideClinicalNoteActionTypes";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import * as chartActions from "./chartActions";
import { hideSideBar } from "./chartActions";
import { getDoctorPoints } from "./calendarActions";
import Utils from "../helpers/Utils";
import { ClinicalNoteStackCodes } from "../constants/chart-sidebar/clinicalNoteStackCodes";
import questionTypeCodes from "../constants/chart-sidebar/questionTypeCodes";

import { getNote } from "../helpers/clinicNote";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import ClinicalNoteApi from "../api-legacy/ClinicalNoteApi";
import TransactionApi from "../api-legacy/TransactionApi";

export function setStartDateFilter(date) {
  return {
    type: ActionType.SET_CLINICAL_NOTE_START_FILTER_DATE,
    date,
  };
}

export function setEndDateFilter(date) {
  return {
    type: ActionType.SET_CLINICAL_NOTE_END_FILTER_DATE,
    date,
  };
}

export function getReferenceNoteList(referenceClinicNoteId, patientKey) {
  return (dispatch) => {
    if (!patientKey) {
      return;
    }

    dispatch(startLoader());

    dispatch(getReferenceClinicalNoteListApi({ referenceClinicNoteId, patientKey }))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function changeLastModifiedDateFrom(date) {
  return {
    type: "CHANGE_LAST_MODIFIED_DATE_FROM",
    date,
  };
}

export function changeLastModifiedDateTo(date) {
  return {
    type: "CHANGE_LAST_MODIFIED_DATE_TO",
    date,
  };
}

export function setMultiQuestionOtherValue(id, data) {
  return (dispatch) =>
    dispatch({
      type: ActionType.CLINICAL_NOTE_MULTI_QUESTIONS_OTHER_VALUE,
      data,
      id,
    });
}

export function clearMultiQuestions() {
  return {
    type: "CLEAR_MULTI_QUESTIONS",
  };
}

export function fullScreen(boolean) {
  return {
    type: ActionType.SIDE_CLINICAL_NOTE_FULL_SCREEN,
    fullScreen: boolean,
  };
}

export function noteDeleteConfirmModalShow(id, bool) {
  return {
    type: ActionType.NOTE_DELETE_CONFIRM_MODAL_SHOW,
    id,
    bool,
  };
}

export function noteDeleteConfirmModalHide() {
  return {
    type: ActionType.NOTE_DELETE_CONFIRM_MODAL_HIDE,
  };
}

export function copyNoteItem(id, appointmentId) {
  return (dispatch, getState) => {
    const {
      session: { member },
    } = getState();

    dispatch(startLoader());

    return dispatch(getClinicalNoteItem({ id }))
      .then(({ templateQuestionsAndAnswers = [], ...item }) => {
        const template = templateQuestionsAndAnswers.filter((x) => x.id !== 0);
        item.owner = member;
        dispatch(
          saveClinicalNoteItem({
            ...item,
            id: null,
            appointmentId,
            patient: { patientKey: _.get(item, "patient.patientKey") },
            createdDate: Date.now(),
            templateQuestionsAndAnswers: !_.isEmpty(template) ? template : undefined,
          }),
        )
          .then(() => {
            dispatch(stopLoader());

            dispatch(getOwnedList());
          })
          .catch((error) => dispatch(stopLoader(error)));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function auditNoteItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(getClinicalNoteItem({ id }))
      .then(({ id, templateQuestionsAndAnswers = [], ...item }) => {
        const template = templateQuestionsAndAnswers.filter((x) => x.id !== 0);

        dispatch(
          saveClinicalNoteItem({
            ...item,
            patient: { patientKey: _.get(item, "patient.patientKey") },
            type: {
              id,
              code: "AUDITED",
            },

            createdDate: Date.now(),
            templateQuestionsAndAnswers: !_.isEmpty(template) ? template : undefined,
          }),
        )
          .then(() => {
            dispatch(stopLoader());

            dispatch(getOwnedList());
          })
          .catch((error) => dispatch(stopLoader(error)));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function noteDeleteItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(noteDeleteConfirmModalHide());

    return dispatch(noteDeleteItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getOwnedList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function noteRestoreItem(id) {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(noteDeleteConfirmModalHide());

    return dispatch(noteRestoreItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getOwnedList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function noteDeleteItemApi(data) {
  return {
    api: ClinicalNoteApi.deleteItem,
    types: [
      ActionType.NODE_DELETE_ITEM_START,
      ActionType.NODE_DELETE_ITEM_SUCCESS,
      ActionType.NODE_DELETE_ITEM_ERROR,
    ],
    data,
  };
}
function noteRestoreItemApi(data) {
  return {
    api: ClinicalNoteApi.restoreItem,
    types: [
      ActionType.NODE_DELETE_ITEM_START,
      ActionType.NODE_DELETE_ITEM_SUCCESS,
      ActionType.NODE_DELETE_ITEM_ERROR,
    ],
    data,
  };
}

export function initSideClinicalNote(onAppointment, onCallList) {
  return (dispatch) => {
    if (onCallList) {
      dispatch({
        type: ActionType.INIT_SIDE_CLINICAL_NOTE,
        onCallList,
      });
    }

    return dispatch(getDoctorPoints({ publicPoint: true }))
      .then(() => {
        if (!onCallList) {
          dispatch({
            type: ActionType.INIT_SIDE_CLINICAL_NOTE,
            onAppointment,
            onCallList,
          });
        }
      })
      .catch((error) => showMessage(error));
  };
}

export function selectQuestion(index) {
  return {
    type: ActionType.CLINICAL_NOTE_SELECT_QUESTION,
    index: +index,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_SORT_FIELD_SIDE_CLINICAL_NOTE,
      code,
    });

    dispatch(getOwnedList());
  };
}
export function selectClinicField(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_CLINIC_FIELD_SIDE_CLINICAL_NOTE,
      id,
    });

    dispatch(getOwnedList());
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SEARCH_CHANGE,
    value,
  };
}

function pushStack(stackItem) {
  return {
    type: ActionType.PUSH_CLINICAL_NOTE_STACK,
    stackItem,
  };
}

function replaceStack(stackItem) {
  return {
    type: ActionType.REPLACE_CLINICAL_NOTE_STACK,
    stackItem,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSideClinicalNote;

    if (stack.length == 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_CLINICAL_NOTE_STACK,
      });
    }
  };
}

export function openCategory() {
  return pushStack({
    code: ClinicalNoteStackCodes.category,
  });
}

export function openPrint() {
  return pushStack({
    code: ClinicalNoteStackCodes.print,
  });
}

export function openList(categoryId) {
  return pushStack({
    code: ClinicalNoteStackCodes.list,
    categoryId,
  });
}

export function openItem(item, type) {
  return pushStack({
    code: ClinicalNoteStackCodes.item,
    item,
    type,
  });
}

export function replaceItem(item, type) {
  return replaceStack({
    code: ClinicalNoteStackCodes.item,
    item,
    type,
  });
}

export function setAddendumId(id) {
  return {
    type: ActionType.CLINICAL_NOTE_SET_ADDENDUM_ID,
    id,
  };
}

export function openTextarea(type) {
  return pushStack({
    code: ClinicalNoteStackCodes.textarea,
    type,
  });
}

export function getClinicalNoteCategory(data) {
  return {
    api: ClinicalNoteApi.template.getCategory,
    types: [
      ActionType.GET_CLINICAL_NOTE_CATEGORY_START,
      ActionType.GET_CLINICAL_NOTE_CATEGORY_SUCCESS,
      ActionType.GET_CLINICAL_NOTE_CATEGORY_ERROR,
    ],
    data,
  };
}

export function getOwnedList(query, doctorId = null) {
  return (dispatch, getState) => {
    const {
      chart: { patient, patientKey: chartPatientKey },
      session: { member },
      chartSideClinicalNote: {
        sortField,
        onAppointment,
        onCallList,
        patientKey,
        startDateFilter,
        endDateFilter,
        clinicalNoteActiveTab,
        clinicId,
      },
      patient: { patientDetails },
    } = getState();

    if (doctorId) {
      dispatch({
        type: ActionType.CLINICAL_NOTE_DOCTOR_ID,
        doctorId,
      });
    }
    const data = {
      patientKey:
        patientKey ||
        chartPatientKey ||
        (query && query.patientKey) ||
        (onAppointment ? patientDetails.patientKey : patient.patientKey),
      sortField,
      clinicId,
      // excludeDoctorIds: member.id,
      limit: 25,
      startTime: startDateFilter ? getCompanyStartOfDay(startDateFilter) : undefined,
      endTime: endDateFilter ? getCompanyEndOfDay(endDateFilter) : undefined,
    };

    if (onCallList) {
      data.patientKey = patientKey;
    }
    if (clinicalNoteActiveTab.code === "voidedNotes") {
      //deleted notes
      data.excludeDoctorIds = null;
      data.onlyDeleted = true;
    }
    if (clinicalNoteActiveTab.code === "yourNotes") {
      //your notes
      data.excludeDoctorIds = null;
      data.doctorIds = member.id;
    }
    dispatch(getClinicalNoteOwnedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadOwnedList() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSideClinicalNote: {
        sortField,
        ownedList,
        ownedListTotalCount,
        onAppointment,
        onCallList,
        patientKey,
        startDateFilter,
        endDateFilter,
      },
      patient: { patientDetails },
    } = getState();

    if (ownedListTotalCount > ownedList.length) {
      const data = {
        patientKey: onAppointment ? patientDetails.patientKey : patient.patientKey,
        sortField,
        limit: 25,
        start: ownedList.length,
        startTime: startDateFilter ? getCompanyStartOfDay(startDateFilter) : undefined,
        endTime: endDateFilter ? getCompanyEndOfDay(endDateFilter) : undefined,
      };

      if (onCallList) {
        data.patientKey = patientKey;
      }
      data.patientKey = data.patientKey ? data.patientKey : getState().chart.patientKey;
      dispatch(getClinicalNoteOwnedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function onChangeNoteTab(clinicalNoteActiveTab) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CLINICAL_NOTE_CHANGE_TAB,
      clinicalNoteActiveTab,
    });
    dispatch(getOwnedList());
  };
}

export function setPatientKey(patientKey) {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_PATIENT_KEY,
    patientKey,
  };
}

export function setQuestionsModalVisible() {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_QUESTIONS_MODAL_VISIBLE,
  };
}

export function setSelectTreatmentModalVisible() {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_MODAL_VISIBLE,
  };
}

export function setSelectTreatmentExistingModalVisible() {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_EXISTING_MODAL_VISIBLE,
  };
}

export function setAppointmentTime(setAppointmentTime) {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_SET_APPOINTMENT_TIME,
    setAppointmentTime,
  };
}
export function setAppointmentVisitTime(setAppointmentVisitTime) {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_APPOINTMENT_VISIT_TIME,
    setAppointmentVisitTime,
  };
}

export function setTreatmentDiagnosisSelecting() {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_TREATMENT_DIAGNOSIS_SELECTING,
  };
}

export function setSelectDiagnosisModalVisible() {
  return {
    type: ActionType.CHART_CLINICAL_NOTE_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE,
  };
}

export function getQuestionList(filter) {
  return (dispatch, getState) => {
    const {
      chartSideClinicalNote: { questionList, questionListTotalCount, questionListFetching },
    } = getState();
    if ((questionList.length === questionListTotalCount && filter.load) || questionListFetching)
      return;
    const data = {
      ...filter,
      start: filter.start === 0 ? 0 : questionList.length,
      limit: 25,
      showInFilling: true,
    };
    dispatch({
      api: ClinicalNoteApi.getQuestionsList,
      types: [
        ActionType.GET_CLINICAL_NOTE_QUESTION_LIST_START,
        ActionType.GET_CLINICAL_NOTE_QUESTION_LIST_SUCCESS,
        ActionType.GET_CLINICAL_NOTE_QUESTION_LIST_ERROR,
      ],
      data,
    }).catch((error) => dispatch(showMessage(error)));
  };
}

export function getTreatmentDiagnosisListForNote(filter) {
  return (dispatch, getState) => {
    const {
      chart: {
        appointment: { patient: appointmentPatient },
        patient,
      },
      chartSideClinicalNote: {
        treatmentDiagnosisList,
        treatmentDiagnosisListTotalCount,
        treatmentDiagnosisListFetching,
      },
    } = getState();

    if (
      (treatmentDiagnosisList.length === treatmentDiagnosisListTotalCount && filter.load) ||
      treatmentDiagnosisListFetching
    )
      return;

    const chartPatientKey = _.get(patient, "patientKey");
    const appointmentPatientKey = _.get(appointmentPatient, "patientKey");

    const data = {
      limit: 100,
      patientKey: appointmentPatientKey || chartPatientKey,
      sortField: "date_desc",
      start: filter.start === 0 ? 0 : treatmentDiagnosisList.length,
      [`${filter.type}Planned`]: !filter.existing,
      [`${filter.type}Completed`]: !filter.existing,
      existingCompleted: filter.existing,
    };

    dispatch({
      api: TransactionApi.getList.forDentist,
      types: [
        ActionType.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_START,
        ActionType.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_SUCCESS,
        ActionType.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_ERROR,
      ],
      data,
    }).catch((error) => dispatch(showMessage(error)));
  };
}

export function getTemplateList() {
  return (dispatch, getState) => {
    const {
      chartSideClinicalNote: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { categoryId } = stackItem;

    const data = {
      categoryId,
    };

    dispatch(getClinicalNoteTemplateList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function getItem() {
  return (dispatch, getState) => {
    const {
      chartSideClinicalNote: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(getClinicalNoteItem(data))
      .then((response) => {
        dispatch(stopLoader());

        dispatch(getReferenceNoteList(id, _.get(response, "patient.patientKey")));
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteItem() {
  return (dispatch, getState) => {
    const {
      chartSideClinicalNote: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const { id } = stackItem.item;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(deleteClinicalNoteItem(data))
      .then(() => {
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getEmpty() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chartSideClinicalNote: { stack },
      chart,
    } = state;

    const stackItem = _.last(stack);
    const templateId = stackItem.item.id;

    const data = {
      templateId,
      appointmentId: chart.appointment.id,
      patientKey: chart.patient.patientKey,
    };

    dispatch(startLoader());

    dispatch(getClinicalNoteEmpty(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function mappingClinicalNoteItem(item, toothNumericType, clinic, itemName) {
  const note = getNote(item, toothNumericType, false, clinic);

  const { templateQuestionsAndAnswers = [] } = item;

  const list = templateQuestionsAndAnswers
    .slice()
    .filter(({ id }) => id !== 0)
    .map((question) => {
      if (
        question.questionType.code === questionTypeCodes.DATE_SELECTOR &&
        question.answers &&
        question.answers[0]
      ) {
        const newAnswers = question.answers.slice();

        const [answerItem] = question.answers;

        newAnswers.splice(0, 1, _.toFinite(answerItem) + Utils.getTimezoneDifference());

        return {
          ...question,
          answers: newAnswers,
        };
      }

      if (
        question.questionType.code === questionTypeCodes.DIAGNOSES ||
        question.questionType.code === questionTypeCodes.TREATMENTS_COMPLETED
      ) {
        return {
          ...question,
          answers: question.answers.map(({ id }) => id),
        };
      }

      return question;
    });

  return {
    ...item,
    note,
    name: itemName,
    templateQuestionsAndAnswers: list,
  };
}

export function resetClinicalNoteFor() {
  return (dispatch) => {
    return dispatch({
      type: ActionType.RESET_CLINICAL_NOTE_FOR,
    });
  };
}

export function saveItem(itemName, appointmentId, sendToHie) {
  return (dispatch, getState) => {
    const {
      session: { toothNumericType, alertNotifications, miscSettings, clinic },
      chartSideClinicalNote: { item, stack },
      chart: { selectTreatmentToCompleteModalVisible },
      patient: { appointment },
    } = getState();

    dispatch(startLoader());

    const stackItem = _.last(stack);
    const isClinicalNote = stackItem.code === ClinicalNoteStackCodes.item;

    const uniqueArray = [];
    const uniqueObject = {};
    item?.templateQuestionsAndAnswers.forEach((element) => {
      if (!uniqueObject.hasOwnProperty(element.id)) {
        uniqueObject[element.id] = true;
        uniqueArray.push(element);
      }
    });
    item.templateQuestionsAndAnswers = uniqueArray;
    const data = isClinicalNote
      ? mappingClinicalNoteItem(item, toothNumericType, clinic, itemName)
      : item;

    const showCashierNote = _.get(
      alertNotifications,
      "ALERT_CASHIER_NOTE_ON_FINISH_VISIT.selected",
      true,
    );
    return dispatch(
      saveClinicalNoteItem({
        ...data,
        sendToHie,
        patient: { patientKey: _.get(data, "patient.patientKey") },
      }),
    )
      .then((response) => {
        resetClinicalNoteFor();
        dispatch(stopLoader());
        dispatch(popStack());
        if (
          (!showCashierNote || miscSettings.useTransactionNotesAsCashierNotes) &&
          selectTreatmentToCompleteModalVisible
        ) {
          const isOnly = _.get(miscSettings, "postTreatmentWithoutCompleteAppointment");

          return dispatch(
            chartActions.selectTreatmentToCompleteSetComplete(
              false,
              isOnly && _.toFinite(appointmentId || appointment.id) > 0,
            ),
          );
        }
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function skip() {
  return (dispatch, getState) => {
    const {
      session: { alertNotifications, miscSettings },
    } = getState();

    const showCashierNote = _.get(
      alertNotifications,
      "ALERT_CASHIER_NOTE_ON_FINISH_VISIT.selected",
      true,
    );

    dispatch(popStack());
    if (!showCashierNote || miscSettings.useTransactionNotesAsCashierNotes) {
      return dispatch(chartActions.selectTreatmentToCompleteSetComplete());
    }
  };
}

export function submitTextareaForm({
  name = "",
  note = "",
  referenceClinicNoteId = null,
  sendToHie = false,
}) {
  return (dispatch, getState) => {
    const {
      chart,
      patient: { patientDetails, appointment },
      chartSideClinicalNote: { item, onAppointment, addendumId },
      session: { member },
    } = getState();
    if (!note) {
      return dispatch(showMessage("Note required"));
    }

    const data = {
      sendToHie,
      appointmentId: chart.appointment.id || appointment.id || null,
      id: item.id,
      name,
      note,
      referenceClinicNoteId:
        referenceClinicNoteId > 0 ? referenceClinicNoteId : addendumId > 0 ? addendumId : undefined,
      owner: {
        id: member.id,
      },
      patient: {
        patientKey: onAppointment
          ? patientDetails.patientKey || appointment.patient?.patientKey
          : chart.patient.patientKey,
      },
    };

    dispatch(startLoader());

    dispatch(saveClinicalNoteItem(data))
      .then(() => {
        dispatch(setAddendumId(0));
        dispatch(stopLoader());
        dispatch(popStack());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function prevQuestion() {
  return {
    type: ActionType.CLINICAL_NOTE_PREV_QUESTION,
  };
}

export function nextQuestion() {
  return {
    type: ActionType.CLINICAL_NOTE_NEXT_QUESTION,
  };
}

export function changeQuestionTextarea(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CHANGE_QUESTION_TEXTAREA,
    value,
    questionId,
  };
}

export function changeListOfAnswers(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS,
    value,
    questionId,
  };
}

export function changeListOfAnswersMultiple(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE,
    value,
    questionId,
  };
}

export function changeDatePicker(date, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CHANGE_DATE_PICKER,
    date,
    questionId,
  };
}

export function changeSurface(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CHANGE_SURFACE,
    value,
    questionId,
  };
}

export function selectTooth(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_SELECT_TOOTH,
    value,
    questionId,
  };
}

export function setName(value) {
  return {
    type: ActionType.CLINICAL_NOTE_SET_NAME,
    value,
  };
}

export function setTextareaBody(value) {
  return {
    type: ActionType.CLINICAL_NOTE_SET_TEXTAREA_BODY,
    value,
  };
}

export function changeCreatedDate(date, isMoment = true) {
  return (dispatch, getState) => {
    const {
      chartSideClinicalNote: { item },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: item.id,
      createdDate: isMoment ? +date + Utils.getTimezoneDifference() : date,
    };

    dispatch(changeClinicalNoteCreatedDate(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openDeleteModal(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_OPEN_DELETE_MODAL,
    value,
    questionId,
  };
}

export function closeDeleteModal(value, questionId) {
  return {
    type: ActionType.CLINICAL_NOTE_CLOSE_DELETE_MODAL,
    value,
    questionId,
  };
}

export function initEdit() {
  return {
    type: ActionType.CLINICAL_NOTE_INIT_EDIT,
  };
}

export function initTextarea() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
    } = getState();

    dispatch({
      type: ActionType.CLINICAL_NOTE_INIT_TEXTAREA,
      patient,
    });
  };
}

function getClinicalNoteOwnedList(data) {
  return {
    api: ClinicalNoteApi.getOwnedList,
    types: [
      ActionType.GET_CLINICAL_NOTE_OWNED_LIST_START,
      ActionType.GET_CLINICAL_NOTE_OWNED_LIST_SUCCESS,
      ActionType.GET_CLINICAL_NOTE_OWNED_LIST_ERROR,
    ],
    data,
  };
}

function getReferenceClinicalNoteListApi(data) {
  return {
    api: ClinicalNoteApi.getOwnedList,
    types: [
      ActionType.GET_REFERENCE_CLINICAL_NOTE_LIST_START,
      ActionType.GET_REFERENCE_CLINICAL_NOTE_LIST_SUCCESS,
      ActionType.GET_REFERENCE_CLINICAL_NOTE_LIST_ERROR,
    ],
    data,
  };
}

function getClinicalNoteTemplateList(data) {
  return {
    api: ClinicalNoteApi.template.getList,
    types: [
      ActionType.GET_CLINICAL_NOTE_TEMPLATE_LIST_START,
      ActionType.GET_CLINICAL_NOTE_TEMPLATE_LIST_SUCCESS,
      ActionType.GET_CLINICAL_NOTE_TEMPLATE_LIST_ERROR,
    ],
    data,
  };
}

function getClinicalNoteItem(data) {
  return {
    api: ClinicalNoteApi.getItem,
    types: [
      ActionType.GET_CLINICAL_NOTE_ITEM_START,
      ActionType.GET_CLINICAL_NOTE_ITEM_SUCCESS,
      ActionType.GET_CLINICAL_NOTE_ITEM_ERROR,
    ],
    data,
  };
}

function saveClinicalNoteItem(data) {
  return {
    api: ClinicalNoteApi.saveItem,
    types: [
      ActionType.SAVE_CLINICAL_NOTE_ITEM_START,
      ActionType.SAVE_CLINICAL_NOTE_ITEM_SUCCESS,
      ActionType.SAVE_CLINICAL_NOTE_ITEM_ERROR,
    ],
    data,
  };
}

function saveClinicalNoteItemHIE(data) {
  return {
    api: ClinicalNoteApi.saveItemHIE,
    types: [
      ActionType.SAVE_CLINICAL_NOTE_ITEM_HIE_START,
      ActionType.SAVE_CLINICAL_NOTE_ITEM_HIE_SUCCESS,
      ActionType.SAVE_CLINICAL_NOTE_ITEM_HIE_ERROR,
    ],
    data,
  };
}

function deleteClinicalNoteItem(data) {
  return {
    api: ClinicalNoteApi.deleteItem,
    types: [
      ActionType.DELETE_CLINICAL_NOTE_ITEM_START,
      ActionType.DELETE_CLINICAL_NOTE_ITEM_SUCCESS,
      ActionType.DELETE_CLINICAL_NOTE_ITEM_ERROR,
    ],
    data,
  };
}

function getClinicalNoteEmpty(data) {
  return {
    api: ClinicalNoteApi.getEmpty,
    types: [
      ActionType.GET_CLINICAL_NOTE_EMPTY_START,
      ActionType.GET_CLINICAL_NOTE_EMPTY_SUCCESS,
      ActionType.GET_CLINICAL_NOTE_EMPTY_ERROR,
    ],
    data,
  };
}

function changeClinicalNoteCreatedDate(data) {
  return {
    api: ClinicalNoteApi.changeCreatedDate,
    types: [
      ActionType.CHANGE_CLINICAL_NOTE_CREATED_DATE_START,
      ActionType.CHANGE_CLINICAL_NOTE_CREATED_DATE_SUCCESS,
      ActionType.CHANGE_CLINICAL_NOTE_CREATED_DATE_ERROR,
    ],
    data,
  };
}
