import React, { useEffect, useState } from "react";
import * as chartActions from "../../../../actions/chartActions";
import Show from "../../../widgets/Show";
import { SelectPicker } from "../../../select-picker/SelectPicker";
import SelectTreatmentToCompleteDentistForm from "../../transactions/select-treatments-to-complete/SelectTreatmentToCompleteDentistForm";
import SelectTreatmentToCompleteFinishMode from "../../transactions/select-treatments-to-complete/SelectTreatmentToCompleteFinishMode";
import PackageDealsList from "../../transactions/select-treatments-to-complete/PackageDealsList";
import SelectTreatmentToCompleteNoteModal from "../../transactions/select-treatments-to-complete/SelectTreatmentToCompleteNoteModal";
import { Button } from "react-bootstrap";
import _, { toFinite } from "lodash";
import { Routes } from "../../../../constants/Routes";
import { connect, useDispatch } from "react-redux";
import {
  selectTreatmentToCompleteGetList,
  setTreatmentToCompleteVisitType,
} from "../../../../actions/chartActions";
import { selectDoctor } from "../../../../actions/authActions";

const enhancer = connect(({ auth, session }) => ({ auth, session }));

const MedicalFillInFormTreatmentComplete = (props) => {
  const {
    chart,
    auth: { members, chosenMemberId, clinics },
    session: { clinic },
    chart: { selectTreatmentToCompleteList, selectTreatmentToCompleteSelectedItems, isPlanning },
    history,
    onSaveItem,
  } = props;
  const [clinicFilter, setClinicFilter] = useState({
    label: clinic.name,
    value: clinic.id,
  });
  useEffect(() => {
    dispatch(selectTreatmentToCompleteGetList());
    dispatch(setTreatmentToCompleteVisitType("finish"));

    return () => {
      dispatch(selectDoctor(0));
    };
  }, []);
  const dispatch = useDispatch();

  const sum = _.reduce(
    selectTreatmentToCompleteList.filter(Boolean),
    (x, value) => {
      const { key, price, discount } = value;
      if (selectTreatmentToCompleteSelectedItems[key]) {
        if (discount?.code === "PERCENTAGE") {
          return x + (price - (price / 100) * toFinite(discount ? discount.value : 0));
        } else return x + price - toFinite(discount ? discount.value : 0);
      }

      return x;
    },
    0,
  );

  const allClinics = [
    {
      label: "All Clinics",
      value: "all",
    },
  ];

  const clinicList = (clinics || []).map((x) => ({
    value: x.id,
    label: x.name,
  }));

  const { selectTreatmentToCompleteModalMode } = chart;

  const initialValues = {
    closedDentistId: chosenMemberId,
  };
  return (
    <div className="medical-fill-in-form-treatment">
      <div className="medical-fill-in-form-treatment__header">
        <div className="btn-group" style={{ display: "block" }}>
          <button type="button" style={{ width: "100%" }} className={`btn btn-primary`}>
            Discharge/finish
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center row mt-3">
        <div className="col-sm-2">
          <SelectPicker
            placeholder="Filter by clinic"
            value={clinicFilter}
            options={[...allClinics, ...clinicList]}
            className="transaction-clinic-filter"
            style={{ width: "200px" }}
            onChange={(v) => {
              dispatch(chartActions.selectTreatmentToCompleteToggleSelectedItemClear());
              setClinicFilter({ clinicFilter: v });
            }}
          />
        </div>
        <div className="col-sm-10">
          <SelectTreatmentToCompleteDentistForm
            initialValues={initialValues}
            members={members}
            selectTreatmentToCompleteDoctorsFilter={chart.selectTreatmentToCompleteDoctorsFilter}
          />
        </div>
      </div>
      <div style={{ height: "80%" }}>
        <Show if={selectTreatmentToCompleteModalMode === "post"}>
          <SelectTreatmentToCompleteFinishMode chart={chart} clinicId={clinicFilter.value} />
        </Show>
        <Show if={selectTreatmentToCompleteModalMode === "stop"}>
          <PackageDealsList clinicId={clinicFilter.value} chart={chart} />
        </Show>
        <Show if={selectTreatmentToCompleteModalMode === "finish"}>
          <SelectTreatmentToCompleteFinishMode chart={chart} clinicId={clinicFilter.value} />
        </Show>
      </div>
      <SelectTreatmentToCompleteNoteModal chart={chart} isPlanning={isPlanning} />
      <div className="medical-fill-in-form-treatment__footer">
        <span
          style={{
            marginRight: "25px",
          }}
        >
          Total: {sum.toFixed(2)}
        </span>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            dispatch(chartActions.selectTreatmentToCompleteNoteModalShow(isPlanning)).then(
              (response) => {
                if (_.get(response, "response")) {
                  onSaveItem();
                  if (selectTreatmentToCompleteModalMode !== "post")
                    history.push(Routes.DashboardCalendar);
                }
              },
            )
          }
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default enhancer(MedicalFillInFormTreatmentComplete);
