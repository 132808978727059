const fromDays = [
  { name: "sun", label: "Sun" },
  { name: "mon", label: "Mon" },
  { name: "tue", label: "Tue" },
  { name: "wed", label: "Wed" },
  { name: "thu", label: "Thu" },
  { name: "fri", label: "Fri" },
  { name: "sat", label: "Sat" },
];

export default fromDays;
