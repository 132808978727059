import cx from "classnames";
import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import Back from "../widgets/Back";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { Routes } from "../../constants/Routes";
import { createUrl } from "../../utils/UrlUtils";
import { membersSelector } from "../../reducers/authReducer";
import { defaultColors, other } from "../../constants/defaults";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

export default function AppointmentTypeColor() {
  const match = useRouteMatch();
  const members = useShallowEqualSelector(membersSelector);

  const [activeType, setActiveType] = useState(Colors.getDoctorColor(match.params.doctorId));

  let $colors = Utils.objectToArray(defaultColors, (item) => {
    const checkActive = activeType.color.code === item.code ? " active" : "";

    return (
      <button
        key={item.code}
        onClick={() => {
          Colors.selectAppointmentDoctor(match.params.doctorId, item);
          setActiveType(Colors.getDoctorColor(match.params.doctorId));
        }}
        className="list-group-item"
      >
        <div className={"pull-right check" + checkActive}>✔</div>
        <div className="color" style={{ backgroundColor: item.color }} />
        {item.name}
      </button>
    );
  });

  const listOther = activeType.color.code === other.code ? activeType.color : other;

  return (
    <div className="settings-body">
      <div className="title">
        <Back />
        <div className="name">{Utils.getFullName(members[match.params.doctorId])}</div>
      </div>
      <div className="body">
        <div className="list-group">
          {$colors}
          <Link
            to={createUrl(Routes.SettingsAppointmentDoctorOther, {
              params: {
                doctorId: match.params.doctorId,
              },
            })}
            className="list-group-item"
          >
            <div
              className={cx("pull-right check", { active: activeType.color.code === other.code })}
            >
              ✔
            </div>
            <div className="color" style={{ backgroundColor: listOther.color }} />
            {listOther.name}
          </Link>
        </div>
      </div>
    </div>
  );
}
