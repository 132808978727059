import React from "react";

import Utils from "../../../helpers/Utils";
import { defaultColors } from "../../../constants/defaults";
import { ViewTypes } from "../../../constants/Constants";

const AppointmentItemDetails = ({ appointments }) => {
  const rowView = localStorage.getItem("rowView");
  const columnView = localStorage.getItem("columnView");
  const _shortCutName = (_patientName) => {
    if (!_patientName) return;
    if (_patientName.length < 16) {
      return _patientName;
    }
    const fullNameArray = _patientName.split(" ");
    let shortedName = "";
    fullNameArray.forEach((item) => {
      if (shortedName.length < 16) {
        shortedName = shortedName.concat(` ${item}`);
      }
    });
    return shortedName.trim();
  };

  return (
    <div className="copied-appointments">
      {(appointments || []).map((appointment, idx) => {
        const duration = appointment.duration / 1000 / 60;
        const name = Utils.getFullName(appointment.patient);

        return (
          <div
            key={idx}
            draggable={true}
            onDragStart={(event) => {
              event.stopPropagation();
              const dragData = {
                appointment: appointment,
                columns: [],
                isFormDndPanel: true,
              };

              event.currentTarget.classList.add("appointment-from-dnd-panel");
              event.dataTransfer.setData("application/json", JSON.stringify(dragData));
              event.dataTransfer.setData("text/plain", JSON.stringify(appointment));
            }}
            onDragLeave={(event) => {
              event.preventDefault();
              const calendarForm = document.querySelector(".calendar-form");
              if (calendarForm.classList.contains("dnd-calendar-backdrop")) {
                calendarForm.classList.remove("dnd-calendar-backdrop");
              }
            }}
            className="appointment-item"
          >
            <div
              className="appointment-info p-1"
              style={{
                backgroundColor: defaultColors.Blue.color,
              }}
            >
              <div className="w-100 d-flex align-items-center justify-content-between">
                {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                  <div className="name" title={name}>
                    {_shortCutName(name)}
                  </div>
                )}

                <div
                  className={`number ${
                    rowView === ViewTypes.compact || columnView === ViewTypes.compact
                      ? ""
                      : "comfort"
                  }`}
                  style={{
                    backgroundColor: defaultColors.Blue.color,
                    textDecoration: "underline",
                  }}
                >
                  {appointment.patient?.chartNumber ? (
                    <span>#{appointment.patient.chartNumber}</span>
                  ) : (
                    "Temp"
                  )}
                </div>
              </div>

              <div
                className="bottom w-100 d-flex justify-content-between"
                style={{ paddingRight: "5px" }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                      appointment.category &&
                      appointment.category.name}
                  </div>
                  {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                    appointment.statusOther && <div>{appointment.statusOther.name}</div>}
                  {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                    appointment.location && <div>{appointment.location.name}</div>}
                </div>
                <div>{appointment?.dentist?.name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default AppointmentItemDetails;
