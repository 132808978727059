import React from "react";
import { useField } from "formik";

import { MedicationHintsAutocompleteWrapper } from "../../wrappers/MedicationHintsAutocompleteWrapper";

interface Props {
  readonly name: string;
}

export function MedicationHintsAutocompleteField({ name }: Props) {
  const [field, , helpers] = useField(name);

  return (
    <MedicationHintsAutocompleteWrapper
      name={name}
      onSelect={(x) => helpers.setValue(x)}
      input={{
        value: field.value,
        onBlur: field.onBlur,
        onChange: (x) => helpers.setValue(x),
      }}
    />
  );
}
