export const PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_SHOW =
  "PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_SHOW";
export const PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_HIDE =
  "PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_HIDE";

export const REPEAT_APPOINTMENT_GET_LIST_START = "REPEAT_APPOINTMENT_GET_LIST_START";
export const REPEAT_APPOINTMENT_GET_LIST_SUCCESS = "REPEAT_APPOINTMENT_GET_LIST_SUCCESS";
export const REPEAT_APPOINTMENT_GET_LIST_ERROR = "REPEAT_APPOINTMENT_GET_LIST_ERROR";

export const REPEAT_APPOINTMENT_SET_FOLLOW_TYPE = "REPEAT_APPOINTMENT_SET_FOLLOW_TYPE";

export const REPEAT_APPOINTMENT_SELECT_DATE = "REPEAT_APPOINTMENT_SELECT_DATE";

export const REPEAT_APPOINTMENT_SAVE_ITEM_START = "REPEAT_APPOINTMENT_SAVE_ITEM_START";
export const REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS = "REPEAT_APPOINTMENT_SAVE_ITEM_SUCCESS";
export const REPEAT_APPOINTMENT_SAVE_ITEM_ERROR = "REPEAT_APPOINTMENT_SAVE_ITEM_ERROR";

export const REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW = "REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW";
export const REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE = "REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE";

export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START = "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_START";
export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS =
  "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_SUCCESS";
export const REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR = "REPEAT_APPOINTMENT_SAVE_ITEM_AFTER_ERROR";

export const REPEAT_APPOINTMENT_DELETE_AFTER_START = "REPEAT_APPOINTMENT_DELETE_AFTER_START";
export const REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS = "REPEAT_APPOINTMENT_DELETE_AFTER_SUCCESS";
export const REPEAT_APPOINTMENT_DELETE_AFTER_ERROR = "REPEAT_APPOINTMENT_DELETE_AFTER_ERROR";

export const REPEAT_APPOINTMENT_SET_DELETE_ID = "REPEAT_APPOINTMENT_SET_DELETE_ID";

export const REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY = "REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY";
export const REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY = "REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY";

export const REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW = "REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW";
export const REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE = "REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE";

export const REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE =
  "REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE";

export const REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE = "REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE";
