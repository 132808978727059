import $ from "jquery";

import React from "react";
import moment from "moment";
import { withRouter } from "react-router";

import Utils from "../../helpers/Utils";
import CalendarColumn from "./CalendarColumn";
import CalendarRedLine from "./CalendarRedLine";
import { AppointmentNoteModal } from "./AppointmentNoteModal";
import CalendarSingleDoctorColumn from "./CalendarSingleDoctorColumn";
import BlockedTimeSlotTemplates from "./backed-time-slot-templates/BlockedTimeSlotTemplates";
import {
  CALENDAR_TITLE_PANEL_HEIGHT,
  DEFAULT_HEADER_HEIGHT,
  MINUTE_MILLISECONDS,
  TOP_CLOCK_PANEL_HEIGHT,
  ViewTypes,
} from "../../constants/Constants";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { Modal } from "../ui/Modal";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import { blockedTimeSlotDeleteItem } from "../../actions/blockedTimeSlotActions";
import CalendarColumnContextMenu from "./CalendarColumnContextMenu";
import { moveAppointmentToWaitingPanel, saveAppointment } from "../../actions/appointmentActions";
import {
  copyToClipboardAppointment,
  getCalendarData,
  onDragAppointmentsList,
} from "../../actions/calendarActions";

const enhancer = connect(
  ({
    appointment: { clipBoardAppointment, copyMode, moved },
    calendar: { calendarViewMode, calendarWeaklyMode, appointmentDndCount },
    session: { allowAppointmentMultiBooking, member, clinic },
  }) => ({
    appointmentDndCount,
    calendarViewMode,
    calendarWeaklyMode,
    clipBoardAppointment,
    allowAppointmentMultiBooking,
    copyMode,
    member,
    clinic,
    moved,
  }),
  bindActions({
    blockedTimeSlotDeleteItem,
    copyToClipboardAppointment,
    saveAppointment,
    getCalendarData,
    onDragAppointmentsList,
    moveAppointmentToWaitingPanel,
  }),
);
class CalendarBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNoteModal: false,
      note: "",
      deleteId: null,
      deleteDate: null,
      contextMenuDetails: { visible: false, mode: "copy" },
    };
  }

  goToNewAppointment = (type, e) => {
    const query = parseQuery(this.props.location.search);
    if (type === "new" && query.createTimeSlot) return;
    var $target = $(e.target);
    if ($target[0]?.id === "calendarBody") return;
    if (!$target.parents(".cell").length) {
      if (this.props.permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
        return this.props.openSimpleModal({
          body: "permission denied",
        });
      }

      var $calendarBody = $target.parents(".calendar");
      var scrollTop = $calendarBody[0] ? $calendarBody[0].scrollTop : 0;
      var top =
        scrollTop +
        e.clientY -
        TOP_CLOCK_PANEL_HEIGHT -
        DEFAULT_HEADER_HEIGHT -
        CALENDAR_TITLE_PANEL_HEIGHT -
        20;

      const rowView = localStorage.getItem("rowView");

      let columnHeight = 40;

      if (rowView === ViewTypes.compact) columnHeight = 20;

      var fromStart = Math.floor(top / columnHeight) * 15 * MINUTE_MILLISECONDS;
      var clinicStartTime =
        Utils.timeStringToInt(this.props.workTime.startTime) * MINUTE_MILLISECONDS;
      var time = clinicStartTime + fromStart;

      if ($(e.target).hasClass("time-slot") && !$(e.target).hasClass("reserved")) {
        return;
      }

      if ($(e.target).hasClass("time-slot-edit-btn")) return;

      var currentColumn = $(e.target).parents(".calendar-column")[0] || e.target;
      var rowIndex = $calendarBody
        .find(".calendar-body-wrap .calendar-column")
        .index(currentColumn);
      const filteredColumns = [];
      this.props.columns.forEach((item) => {
        if (!filteredColumns.find((i) => i.dentistId === item.dentistId))
          filteredColumns.push(item);
      });
      var { dentalPointId, dentistId, date, firstDay, isPublic } =
        (this.props.allowAppointmentMultiBooking && !this.props.calendarWeaklyMode
          ? filteredColumns
          : this.props.columns)[rowIndex] || {};

      if (!date) {
        date = this.props.date.valueOf() + this.props.timezoneDifference;
      }
      const appointmentOwn = this.props.permissions.indexOf("VIEW_CLINIC_APPOINTMENT_OWN") >= 0;
      if (this.props.calendarViewMode === "doctor" && !appointmentOwn) {
        date = moment(firstDay).add(rowIndex, "days");
      }

      const $calendar_Body = document.querySelector(".calendar");

      const route = {};

      if (type === "new") {
        route.pathname = Routes.DashboardAppointment;
        route.params = { id: 0 };
        route.query = {
          startTime: date + time,
          dentist: dentistId,
          scrollLeft: $calendar_Body.scrollLeft,
          scrollTop: $calendar_Body.scrollTop,
        };

        if (dentalPointId) {
          route.query.dentalPoint = dentalPointId;
        }
        this.props.history.push(route);
      } else {
        route.pathname = Routes.DashboardCalendar;
        route.query = {
          dentist: dentistId,
          createTimeSlot: true,
          startTime: date + time,
          isPublic: Boolean(isPublic),
        };
      }
      if (dentalPointId) {
        route.query.dentalPoint = dentalPointId;
      }
      this.props.history.replace(
        createUrl(route.pathname, { query: route.query, params: route.params }),
      );
    }
  };

  pasteAppointment = (event) => {
    const { clipBoardAppointment, copyMode, member, moved } = this.props;
    const $target = $(event.target);

    if (!$target.parents(".cell").length) {
      if (this.props.permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
        return this.props.openSimpleModal({
          body: "permission denied",
        });
      }

      const $calendarBody = $target.parents(".calendar");
      const scrollTop = $calendarBody[0] ? $calendarBody[0].scrollTop : 0;
      const top =
        scrollTop +
        event.clientY -
        TOP_CLOCK_PANEL_HEIGHT -
        DEFAULT_HEADER_HEIGHT -
        CALENDAR_TITLE_PANEL_HEIGHT -
        20;

      const rowView = localStorage.getItem("rowView");

      let columnHeight = 40;

      if (rowView === ViewTypes.compact) columnHeight = 20;

      const fromStart = Math.floor(top / columnHeight) * 15 * MINUTE_MILLISECONDS;
      const clinicStartTime =
        Utils.timeStringToInt(this.props.workTime.startTime) * MINUTE_MILLISECONDS;
      const time = clinicStartTime + fromStart;

      if ($(event.target).hasClass("time-slot") && !$(event.target).hasClass("reserved")) {
        return;
      }

      if ($(event.target).hasClass("time-slot-edit-btn")) return;

      const currentColumn = $(event.target).parents(".calendar-column")[0] || event.target;
      const rowIndex = $calendarBody
        .find(".calendar-body-wrap .calendar-column")
        .index(currentColumn);
      const filteredColumns = [];
      this.props.columns.forEach((item) => {
        if (!filteredColumns.find((i) => i.dentistId === item.dentistId))
          filteredColumns.push(item);
      });
      let { dentistId, date } =
        (this.props.allowAppointmentMultiBooking && !this.props.calendarWeaklyMode
          ? filteredColumns
          : this.props.columns)[rowIndex] || {};
      if (!date) {
        date = this.props.date.valueOf() + this.props.timezoneDifference;
      }

      const dentistRooms = this.props.columns.filter((i) => i.dentistId === dentistId);
      let requestCount = 0;
      const doRequest = () => {
        if (requestCount > dentistRooms.length - 1) {
          this.props.openSimpleModal({
            body: `Unable to ${copyMode} not enough rooms`,
          });
          return;
        }
        const room = dentistRooms[requestCount];
        if (copyMode === "copy") {
          clipBoardAppointment.eligibilityIdPayer = "";
          clipBoardAppointment.eligible = false;
        }
        if (moved) {
          clipBoardAppointment.moved = true;
        }

        this.props
          .saveAppointment(
            {
              ...clipBoardAppointment,
              id: copyMode === "move" ? clipBoardAppointment.id : null,
              dentalPoint: {
                id: room.dentalPointId,
              },
              dentist: { id: room.dentistId },
              creator: { id: member.id },
              startTime: date + time,
              inBoxDragged: false,
            },
            true,
          )
          .then((response) => {
            requestCount++;
            if (!response?.id) {
              doRequest();
            } else {
              this.props.copyToClipboardAppointment(null, null);
              this.props.getCalendarData(false, this.props.clinic.id);
            }
          });
      };
      doRequest();
    }
  };

  pasteAppointmentTest = (event) => {
    const { clipBoardAppointment, copyMode, member, moved } = this.props;
    const $target = $(event.target);

    if (!$target.parents(".cell").length) {
      if (this.props.permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
        return this.props.openSimpleModal({
          body: "permission denied",
        });
      }

      const $calendarBody = $target.parents(".calendar");
      const scrollTop = $calendarBody[0] ? $calendarBody[0].scrollTop : 0;
      const top =
        scrollTop +
        event.clientY -
        TOP_CLOCK_PANEL_HEIGHT -
        DEFAULT_HEADER_HEIGHT -
        CALENDAR_TITLE_PANEL_HEIGHT -
        20;

      const rowView = localStorage.getItem("rowView");

      let columnHeight = 40;

      if (rowView === ViewTypes.compact) columnHeight = 20;

      const fromStart = Math.floor(top / columnHeight) * 15 * MINUTE_MILLISECONDS;
      const clinicStartTime =
        Utils.timeStringToInt(this.props.workTime.startTime) * MINUTE_MILLISECONDS;
      const time = clinicStartTime + fromStart;

      if ($(event.target).hasClass("time-slot") && !$(event.target).hasClass("reserved")) {
        return;
      }

      if ($(event.target).hasClass("time-slot-edit-btn")) return;

      const currentColumn = $(event.target).parents(".calendar-column")[0] || event.target;
      const rowIndex = $calendarBody
        .find(".calendar-body-wrap .calendar-column")
        .index(currentColumn);
      const filteredColumns = [];
      this.props.columns.forEach((item) => {
        if (!filteredColumns.find((i) => i.dentistId === item.dentistId))
          filteredColumns.push(item);
      });
      let { dentistId, date } =
        (this.props.allowAppointmentMultiBooking && !this.props.calendarWeaklyMode
          ? filteredColumns
          : this.props.columns)[rowIndex] || {};
      if (!date) {
        date = this.props.date.valueOf() + this.props.timezoneDifference;
      }

      const dentistRooms = this.props.columns.filter((i) => i.dentistId === dentistId);
      let requestCount = 0;
      if (requestCount > dentistRooms.length - 1) {
        this.props.openSimpleModal({
          body: `Unable to ${copyMode} not enough rooms`,
        });
        return;
      }
      const room = dentistRooms[requestCount];
      if (copyMode === "copy") {
        clipBoardAppointment.eligibilityIdPayer = "";
        clipBoardAppointment.eligible = false;
      }
      if (moved) {
        clipBoardAppointment.moved = true;
      }

      return {
        ...clipBoardAppointment,
        id: copyMode === "move" ? clipBoardAppointment.id : null,
        dentalPoint: {
          id: room.dentalPointId,
        },
        dentist: { id: room.dentistId },
        creator: { id: member.id },
        startTime: date + time,
      };
    }
  };

  componentDidMount() {
    const $title = $(".calendar-title-wrap");
    const $time = $(".calendar-time-wrap");

    this.handleScroll = function () {
      $title.css({ marginLeft: -this.scrollLeft + "px" });
      $time.css({ marginTop: -this.scrollTop + "px" });
    };

    const element = document.querySelector(".calendar");
    const query = parseQuery(this.props.location.search);
    if (element) {
      element.scrollTo({
        top: query && query.scrollTop ? query.scrollTop : 0,
        left: query && query.scrollLeft ? query.scrollLeft : 0,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isActiveDoctor: prevIsActiveDoctor } = this.props;
    const { isActiveDoctor: nextIsActiveDoctor, resetColumns } = nextProps;

    if (nextIsActiveDoctor !== prevIsActiveDoctor) {
      resetColumns();
    }
  }

  componentWillUnmount() {
    $(".calendar").off("scroll", this.handleScroll);
  }

  prepareAppointmentData = (appointment, dentistRoom, member, startTime) => {
    return {
      ...appointment,
      id: appointment.id,
      dentalPoint: {
        id: dentistRoom.dentalPointId,
      },
      dentist: { id: dentistRoom.dentistId },
      creator: { id: member.id },
      startTime: startTime,
      inBoxDragged: true,
    };
  };

  moveToWaitingPanelHandler = (point) => {
    const { dentalPoint, startTime } = point || {};
    const { member, clinic, appointmentDndCount } = this.props;

    if (this.props.permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
      this.props.openSimpleModal({
        body: "permission denied",
      });
      return;
    }
    const dentistRoom = (this.props.columns || []).find(
      (room) => room.dentalPointId === dentalPoint?.id,
    );

    if (!dentistRoom) {
      return this.props.openSimpleModal({ body: "Dentist room not found" });
    }

    if (appointmentDndCount === 5) {
      return this.props.openSimpleModal({
        body: "Maximum 5 appointments can be moved to the list.",
      });
    }

    const appointmentData = this.prepareAppointmentData(point, dentistRoom, member, startTime);

    this.props.moveAppointmentToWaitingPanel(appointmentData).then(() => {
      this.props.copyToClipboardAppointment(null, null);
      this.props.getCalendarData(false, clinic.id);
      this.props.onDragAppointmentsList();
    });
  };

  render() {
    const { showNoteModal, note } = this.state;
    const {
      columns,
      rows,
      workTime,
      timezoneDifference,
      calendarViewMode,
      allowAppointmentMultiBooking,
      calendarWeaklyMode,
    } = this.props;
    const appointmentOwn = this.props.permissions.indexOf("VIEW_CLINIC_APPOINTMENT_OWN") >= 0;
    const columnView = localStorage.getItem("columnView");
    const rowView = localStorage.getItem("rowView");

    let columnHeight = 40;
    let columnWidth = 400;

    if (columnView === ViewTypes.compact) columnWidth = 200;

    if (rowView === ViewTypes.compact) columnHeight = 20;
    const filteredColumns = [];
    columns.forEach((item) => {
      if (!filteredColumns.find((i) => i.dentistId === item.dentistId)) filteredColumns.push(item);
    });
    const wrapStyle = {
      height: `${rows.length * columnHeight}px`,
      width: `${
        (allowAppointmentMultiBooking && !calendarWeaklyMode ? filteredColumns : columns).length *
        columnWidth
      }px`,
    };

    let startTime = Utils.timeStringToInt(workTime.startTime);
    let startTimeForNewPatient = Utils.timeStringToInt(workTime.startTimeForNewPatient);
    let endTimeForNewPatient = Utils.timeStringToInt(workTime.endTimeForNewPatient);

    let patientDiapason = endTimeForNewPatient - startTimeForNewPatient;

    if (patientDiapason < 0) patientDiapason = 0;
    if (startTimeForNewPatient < startTime) startTimeForNewPatient = startTime;

    const firstLine = ((startTimeForNewPatient - startTime) / 15) * columnHeight + "px";
    const height = (patientDiapason / 15) * columnHeight + "px";
    const secondLine =
      ((startTimeForNewPatient - startTime + patientDiapason) / 15) * columnHeight + "px";

    const filtered = [];
    if (allowAppointmentMultiBooking && !calendarWeaklyMode) {
      columns.forEach((item) => {
        const doctorRooms = columns.filter((x) => x.dentistId === item.dentistId);
        let pointList = [];
        if (!filtered.find((x) => x.dentistId === item.dentistId)) {
          doctorRooms.forEach((r) => {
            pointList = [...pointList, ...r.list];
          });
          item.list = pointList;
          filtered.push(item);
        }
      });
    }

    return (
      <div
        id="calendarBody"
        className="calendar-body"
        onClick={(e) => {
          this.goToNewAppointment("new", e);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          const calendar = document.getElementsByClassName("calendar-body-wrap")[0];
          const rect = calendar.getBoundingClientRect();
          const left = event.clientX - rect.left; //x position within the element.
          const top = event.clientY - rect.top;
          this.setState({
            contextMenuDetails: {
              visible: true,
              mode: this.props.copyMode,
              innerWidth: rect.width,
              innerHeight: rect.height,
              clientX: event.clientX,
              clientY: event.clientY,
              top: top,
              left: left,
              parentEvent: event,
              paste: (e) => this.pasteAppointment(e),
            },
          });
        }}
      >
        {
          <CalendarColumnContextMenu
            contextMenuDetails={this.state.contextMenuDetails}
            setContextMenuDetails={(contextMenuDetails) => this.setState({ contextMenuDetails })}
            createAppointment={(e) => this.goToNewAppointment("new", e)}
            createBlockedTimeSlot={({ clientY, target }) =>
              this.goToNewAppointment("template", { clientY, target })
            }
            moveToWaitingPanelHandler={(point) => this.moveToWaitingPanelHandler(point)}
          />
        }
        <div className="calendar-body-wrap" style={wrapStyle}>
          <div className="calendar-body-start-bg" style={{ height: firstLine }} />
          <div className="calendar-body-patient-bg" style={{ top: firstLine, height: height }} />
          <div className="calendar-body-end-bg" style={{ top: secondLine }} />
          <div className={`calendar-body-line-bg ${rowView}`} />

          {calendarViewMode === "doctor" &&
            !appointmentOwn &&
            (columns || []).map((column, index, arr) => (
              <CalendarSingleDoctorColumn
                cells={column.list}
                columns={arr}
                columnNumber={index}
                startTime={startTime}
                width={400}
                key={`${index} - ${1}`}
                masterTypes={this.props.masterTypes}
                timezoneDifference={timezoneDifference}
                getCalendarBody={() => $(".calendar")[0]}
                onNoteCLick={(x) => this.setState({ showNoteModal: true, note: x })}
              />
            ))}

          {calendarViewMode === "room" &&
            (allowAppointmentMultiBooking && !calendarWeaklyMode ? filtered : columns || []).map(
              (column, index) => (
                <CalendarColumn
                  setContextMenuDetails={(contextMenuDetails) =>
                    this.setState({ contextMenuDetails })
                  }
                  pasteAppointment={(e) => this.goToNewAppointment("new", e, true)}
                  rows={rows}
                  columns={columns}
                  masterTypes={this.props.masterTypes}
                  key={index}
                  cells={column.list}
                  timeSlot={column.blockedTimeSlots}
                  day={column?.date}
                  startTime={startTime}
                  pasteAppointmentFunctionTest={this.pasteAppointmentTest}
                  onNoteClick={(x) => {
                    this.setState({ showNoteModal: true, note: x });
                  }}
                  setBTSDeleteId={(value) => this.setState({ deleteId: value })}
                  setBTSDeleteDate={(value) => this.setState({ deleteDate: value })}
                  onBTSClick={({ deleteId, deleteDate }) => {
                    this.setState({
                      deleteId,
                      deleteDate,
                    });
                  }}
                  timezoneDifference={timezoneDifference}
                  getCalendarBody={() => $(".calendar")[0]}
                />
              ),
            )}

          {calendarViewMode === "doctor" &&
            appointmentOwn &&
            (allowAppointmentMultiBooking && !calendarWeaklyMode ? filtered : columns || []).map(
              (column, index) => (
                <CalendarColumn
                  setContextMenuDetails={(contextMenuDetails) =>
                    this.setState({ contextMenuDetails })
                  }
                  pasteAppointment={(e) => this.goToNewAppointment("new", e, true)}
                  rows={rows}
                  columns={columns}
                  masterTypes={this.props.masterTypes}
                  key={index}
                  cells={column.list}
                  timeSlot={column.blockedTimeSlots}
                  day={column?.date}
                  startTime={startTime}
                  onNoteClick={(x) => {
                    this.setState({ showNoteModal: true, note: x });
                  }}
                  setBTSDeleteId={(value) => this.setState({ deleteId: value })}
                  setBTSDeleteDate={(value) => this.setState({ deleteDate: value })}
                  onBTSClick={({ deleteId, deleteDate }) => {
                    this.setState({
                      deleteId,
                      deleteDate,
                    });
                  }}
                  timezoneDifference={timezoneDifference}
                  getCalendarBody={() => $(".calendar")[0]}
                />
              ),
            )}

          <CalendarRedLine startTime={workTime.startTime} />
        </div>
        <Modal
          size="small"
          show={this.state.deleteId}
          keyboard={false}
          onHide={() => {
            this.setState({
              deleteId: null,
              deleteDate: null,
            });
          }}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.blockedTimeSlotDeleteItem({
                    id: this.state.deleteId,
                    date: this.state.deleteDate,
                    isExcludeFromDate: false,
                  });
                  this.setState({
                    deleteId: null,
                    deleteDate: null,
                  });
                }}
              >
                Delete this date
              </Button>
              <Button
                className="ml-0 mt-1"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.blockedTimeSlotDeleteItem({
                    id: this.state.deleteId,
                    date: this.state.deleteDate,
                    isExcludeFromDate: true,
                  });
                  this.setState({
                    deleteId: null,
                    deleteDate: null,
                  });
                }}
              >
                Delete all future items from this date
              </Button>
              <Button
                className="ml-0 mt-1"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.blockedTimeSlotDeleteItem({
                    id: this.state.deleteId,
                  });
                  this.setState({
                    deleteId: null,
                    deleteDate: null,
                  });
                }}
              >
                Delete all
              </Button>
              <Button
                className="ml-0 mt-1"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    deleteId: null,
                    deleteDate: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          }
        >
          &nbsp;
        </Modal>

        <AppointmentNoteModal
          note={note}
          show={showNoteModal}
          onClose={(e) => {
            this.setState({ note: "", showNoteModal: false });
            e?.preventDefault();
            e?.stopPropagation();
          }}
        />

        {Boolean(this.props.query && this.props.query.createTimeSlot === "true") && (
          <BlockedTimeSlotTemplates
            query={this.props.query}
            history={this.props.history}
            onRequestClose={() => {
              this.props.history.replace(Routes.DashboardCalendar);
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(enhancer(CalendarBody));
