import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getList, initList, setInitialValues } from "../../../../actions/referralActions";
import _ from "lodash";
import {
  getCompanyEndOfDay,
  getCompanyFormatDate,
  getCompanyStartOfDay,
} from "../../../../helpers/DateUtils";
import { Link } from "react-router-dom";
import { createUrl } from "../../../../utils/UrlUtils";
import { Routes } from "../../../../constants/Routes";
import { defaultColors } from "../../../../constants/defaults";
import moment from "moment";
import ChartReferralNextVisitFilterForm from "./ChartReferralNextVisitFilterForm";
import { STATUS_TYPES } from "../../../../constants/StatusTypes";

const ChartReferralNextVisitOwned = ({ referral, doctors, patient, categoryList }) => {
  const { searchInitialValues, activeTab, list } = referral;
  const { chartNumber, patientKey } = patient;
  const dispatch = useDispatch();
  const {
    APPOINTMENT_STATUS_IN_WAITING,
    APPOINTMENT_STATUS_CHECKED_IN,
    APPOINTMENT_STATUS_MISSED,
    APPOINTMENT_STATUS_CANCELLED,
    APPOINTMENT_STATUS_CANCELLED_CENTRE,
    APPOINTMENT_STATUS_UNCONFIRMED,
    APPOINTMENT_STATUS_RESCHEDULED,
    APPOINTMENT_STATUS_CONFIRMED,
    APPOINTMENT_STATUS_CONFIRMED2,
    APPOINTMENT_STATUS_COMPLETE,
    APPOINTMENT_STATUS_WILL_CALL,
    APPOINTMENT_STATUS_BEING_SEEN,
    APPOINTMENT_STATUS_NO_ANSWER,
    APPOINTMENT_STATUS_SWITCHED_OFF,
    APPOINTMENT_STATUS_WALK_IN,
    APPOINTMENT_STATUS_ASSESSMENT,
    APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
    APPOINTMENT_STATUS_PARTIALLY_PAYED,
  } = STATUS_TYPES;
  const allTabStatuses = [
    APPOINTMENT_STATUS_CHECKED_IN,
    APPOINTMENT_STATUS_MISSED,
    APPOINTMENT_STATUS_CANCELLED,
    APPOINTMENT_STATUS_CANCELLED_CENTRE,
    APPOINTMENT_STATUS_UNCONFIRMED,
    APPOINTMENT_STATUS_RESCHEDULED,
    APPOINTMENT_STATUS_CONFIRMED,
    APPOINTMENT_STATUS_CONFIRMED2,
    APPOINTMENT_STATUS_COMPLETE,
    APPOINTMENT_STATUS_WILL_CALL,
    APPOINTMENT_STATUS_BEING_SEEN,
    APPOINTMENT_STATUS_NO_ANSWER,
    APPOINTMENT_STATUS_SWITCHED_OFF,
    APPOINTMENT_STATUS_WALK_IN,
    APPOINTMENT_STATUS_ASSESSMENT,
    APPOINTMENT_STATUS_CANCELLED_RESCHEDULED,
    APPOINTMENT_STATUS_PARTIALLY_PAYED,
  ].join(", ");
  const nextVisitTabStatuses = [
    APPOINTMENT_STATUS_IN_WAITING,
    APPOINTMENT_STATUS_COMPLETE,
    APPOINTMENT_STATUS_CANCELLED,
    APPOINTMENT_STATUS_CANCELLED_CENTRE,
    APPOINTMENT_STATUS_MISSED,
    APPOINTMENT_STATUS_UNCONFIRMED,
  ].join(", ");

  useEffect(() => {
    const periodEnd = moment().add(1, "years");
    dispatch(setInitialValues({ periodEnd }));
    dispatch(setInitialValues({ chartNumber }));
    searchInitialValues.periodEnd = periodEnd;
    searchInitialValues.chartNumber = chartNumber;
    const referredToId =
      _.get(searchInitialValues, "referredToId.value") > 0
        ? _.get(searchInitialValues, "referredToId.value")
        : null;
    const referredFromId =
      _.get(searchInitialValues, "referredFromId.value") > 0
        ? _.get(searchInitialValues, "referredFromId.value")
        : null;
    const doctorIds =
      _.get(searchInitialValues, "doctorIds.value") > 0
        ? _.get(searchInitialValues, "doctorIds.value")
        : null;

    const transfer = {
      ...searchInitialValues,
      periodStart: getCompanyStartOfDay(searchInitialValues.periodStart.valueOf()),
      periodEnd: getCompanyEndOfDay(searchInitialValues.periodEnd.valueOf()),
      referralTypeCode: searchInitialValues.referralTypeCode.value,
      patientKey,
      chartNumber,
      referredToId,
      referredFromId,
      doctorIds,
      categoryIds: searchInitialValues?.categoryIds?.value
        ? JSON.stringify(searchInitialValues.categoryIds?.value)
        : null,
    };
    dispatch(initList(transfer));
  }, [activeTab]);

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      const data = {
        ...searchInitialValues,
      };
      data.referralTypeCode = null;
      data.referral = activeTab.code === "NEXT_VISIT";
      data.referredToId =
        _.get(searchInitialValues, "referredToId.value") > 0
          ? _.get(searchInitialValues, "referredToId.value")
          : null;
      data.referredFromId =
        _.get(searchInitialValues, "referredFromId.value") > 0
          ? _.get(searchInitialValues, "referredFromId.value")
          : null;
      data.doctorIds =
        _.get(searchInitialValues, "doctorIds.value") > 0
          ? _.get(searchInitialValues, "doctorIds.value")
          : null;
      data.periodStart = getCompanyStartOfDay(searchInitialValues.periodStart);
      data.periodEnd = getCompanyEndOfDay(searchInitialValues.periodEnd);
      data.chartNumber = chartNumber;
      dispatch(getList(data));
    }
  };

  const appointmentList = list.map((point, index) => {
    const date = getCompanyFormatDate(point.date);
    const communicationStatus = point.noAnswer
      ? "No answer"
      : point.notInterested
      ? "Not interested"
      : point.willCall
      ? "Will call back"
      : "";

    return (
      <Link
        key={index}
        to={createUrl(Routes.DashboardAppointment, {
          params: {
            id: point.id,
          },
          query: {
            mode: "referral",
          },
        })}
        className="referral-appointments-cell chart-side-referral-list-body"
      >
        <span>{point.referralType?.name}</span>
        <span>{date}</span>
        <span>{point.referredFrom?.name}</span>
        <span>{point.referredTo?.name}</span>
        <span>{point.dentist.name}</span>
        <span>{point?.category?.name}</span>
        <span>{point?.status?.name}</span>
        <span>{communicationStatus}</span>
        <span
          className="col-sm-6"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          title={point.note}
        >
          Note: {point.note}
        </span>
      </Link>
    );
  });
  return (
    <div className="chart-clinical-note">
      <ChartReferralNextVisitFilterForm
        searchInitialValues={searchInitialValues}
        dispatch={dispatch}
        activeTab={activeTab}
        chartNumber={chartNumber}
        patientKey={patientKey}
        doctors={doctors}
        categoryList={categoryList}
      />
      <div className="chart-side-referral-list">
        <div>Type</div>
        <div>Date</div>
        <div>Referred from</div>
        <div>Referred to</div>
        <div>Booked doctor</div>
        <div>Category</div>
        <div>Status</div>
        <div>Communication status</div>
        <div>Doctor note</div>
      </div>
      <div
        onScroll={loadList}
        style={{
          height: "72vh",
          overflow: "auto",
        }}
      >
        {appointmentList}
      </div>
    </div>
  );
};

export default ChartReferralNextVisitOwned;
