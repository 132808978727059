import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePrescriptionActions from "../../../../actions/chartSidePrescriptionActions";
import DataList from "../../../widgets/DataList";
import ArrowRightImage from "../../../../assets/images/arrows/arrow_right.png";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";

const enhancer = connect(null, bindActions({ chartSidePrescriptionActions }));
export default enhancer(
  class ChartPrescriptionGroup extends React.Component {
    static propTypes = {
      chartSidePrescriptionActions: PropTypes.object,
      chartSidePrescription: PropTypes.object,
    };

    componentDidMount() {
      this.props.chartSidePrescriptionActions.getPrescriptionGroups();
    }

    openItem = (item) => {
      this.props.chartSidePrescriptionActions.openGroupsAdd(item.id);
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.chartSidePrescriptionActions.loadPrescriptionGroups();
      }
    };

    render() {
      const { templatesGroups, fetchingGroups } = this.props.chartSidePrescription;

      const sortCategory = _.sortBy(templatesGroups, (item) => item.code.toLowerCase());

      const $list = sortCategory.map((item, index) => (
        <button
          key={index}
          className="btn btn-default btn-block chart-clinical-note-category-item d-flex align-items-center justify-content-between"
          onClick={() => this.openItem(item)}
        >
          {item.description}
          <img src={ArrowRightImage} width={15} height={20} alt="" />
        </button>
      ));

      return (
        <div className="chart-clinical-note">
          <SidebarHeader onBackClick={this.props.chartSidePrescriptionActions.popStack} />
          <div className="chart-side-body">
            <div className="body">
              <div className="chart-clinical-note-category-title">Select Group</div>

              <DataList fetching={fetchingGroups} onScroll={this.loadList}>
                {$list}
              </DataList>
            </div>
            <div className="all-codes">
              <button
                onClick={this.props.chartSidePrescriptionActions.openEmpty}
                className="btn btn-primary btn-block"
              >
                Write New
              </button>
            </div>
          </div>
        </div>
      );
    }
  },
);
