import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/simpleModalActionTypes";

export const defaultState = {
  body: null,
  footer: null,
  size: null,
  modalVisible: false,
  modalType: null,
};

const reducers = {
  [ActionTypes.SIMPLE_MODAL_CLOSE](state) {
    return {
      ...state,
      body: null,
      footer: null,
      size: null,
      modalVisible: false,
    };
  },
  [ActionTypes.SIMPLE_MODAL_OPEN](state, { body, footer, size, modalType }) {
    return {
      ...state,
      body,
      footer,
      size,
      modalVisible: true,
      modalType,
    };
  },
};

export default createReducer(defaultState, reducers);
