import React, { useEffect, useState } from "react";
import { DatePicker } from "../../../../ui/DatePicker";
import { dataToFillHandler } from "../../../../../actions/chartSideMedicalFillInFormActions";
import { useDispatch } from "react-redux";
import moment from "moment";

const FormDatePicker = ({ form, setForm, questionId, editMode, visible, setOverlay }) => {
  const item = form.questions.find((item) => item.id === questionId);
  const [value, setValue] = useState(null);
  const [show, setShow] = useState(visible || false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.id) {
      item.answer && moment(item.answer).isValid() ? setValue(moment(item.answer)) : moment();
    }
  }, [item]);
  const onSave = (value) => {
    setShow(false);
    setOverlay({ visible: false });
    dispatch(dataToFillHandler(value));
    setForm({
      ...form,
      questions: form.questions.map((question) => {
        if (question.id === questionId) {
          question.answer = moment(value).format("DD.MM.YYYY");
        }
        return question;
      }),
    });
  };
  if (!show) {
    return (
      <p className="medical-fill-in-form-answer" onClick={() => setShow(editMode)}>
        {(value && moment(value).format("DD.MM.YYYY")) || "N/A"}
      </p>
    );
  }
  return (
    <div className="d-flex flex-column">
      <div className="date-picker w-100 d-flex justify-content-center">
        <DatePicker
          inline={true}
          selected={value}
          onChange={(value) => {
            setValue(value);
            onSave(value);
          }}
        />
      </div>
      <button
        className="btn btn-primary mt-3"
        onClick={() => {
          setShow(false);
          setOverlay({ visible: false });
        }}
      >
        Ok
      </button>
    </div>
  );
};

export default FormDatePicker;
