import _ from "lodash";
import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import moment from "moment";

import { DATE_FORMAT_LEGACY } from "../../../constants/Constants";

import RepeatAppointmentHeader from "./RepeatAppointmentHeader";

import RepeatAppointmentConfirmModal from "./RepeatAppointmentConfirmModal";
import RepeatAppointmentFollowAfter from "./RepeatAppointmentFollowAfter";
import RepeatAppointmentAnotherDoctorForm from "./RepeatAppointmentAnotherDoctorForm";

import { connect } from "react-redux";
import bindActions from "../../../helpers/bindActions";
import * as chartActions from "../../../actions/chartActions";
import RepeatAppointmentTrxPlanningForm from "./RepeatAppointmentTrxPlanningForm";
import { DatePicker } from "../../ui/DatePicker";

const enhancer = connect(
  ({ repeatAppointment: repeat }) => ({ repeat }),
  bindActions({ chartActions }),
);

class RepeatAppointmentSidebar extends React.Component {
  componentDidMount() {
    this.props.chartActions.repeatAppointmentGetList();
  }

  handleCalendarChange = (value, typeNow) => {
    if (typeNow) this.props.chartActions.repeatAppointmentSelectDate(value);
  };

  render() {
    const {
      chart,
      repeat,
      repeatAppointment,
      repeatAppointmentAnotherDoctor: { values },
    } = this.props;

    const {
      repeatAppointmentModalVisible,
      repeatAppointmentSelectedDate,
      repeatAppointmentMode,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentList,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalIndex,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentDeleteId,
    } = chart;

    const followTypeNow = repeatAppointmentMode === "now";

    const trxRoom = _.head(repeat.trxRooms);

    return (
      <Modal
        dialogClassName="find-open-slot-sidebar repeat-appointment-sidebar"
        animation={false}
        show={repeatAppointmentModalVisible}
        onHide={this.props.chartActions.repeatAppointmentModalHide}
      >
        <RepeatAppointmentHeader enabled={followTypeNow} />
        <Accordion
          accordion={true}
          defaultActiveKey="1"
          className="repeat-appointment-sidebar-panel-group"
        >
          <Card header="REFERRAL APPT TO ANOTHER DOCTOR" eventKey="1">
            <RepeatAppointmentAnotherDoctorForm
              initialValues={{
                ...values,
                trxRoom: values && values.doctor && trxRoom ? _.get(trxRoom, "id") : 0,
              }}
            />
          </Card>
          <Card header="REFERRAL FOR TRX PLANNING" eventKey="2">
            <RepeatAppointmentTrxPlanningForm />
          </Card>
          <Card
            header="PICK A DATE FOR FOLLOW UP"
            eventKey="3"
            onEnter={() => {
              this.props.chartActions.repeatAppointmentSetFollowType("now");
            }}
          >
            <div className="text-center">
              <DatePicker
                inline={true}
                onChange={(value) => {
                  this.handleCalendarChange(value, followTypeNow);
                }}
                selected={repeatAppointmentSelectedDate}
              />
              <div>
                <br />
                <button
                  type="button"
                  onClick={this.props.chartActions.repeatAppointmentConfirmModalShow}
                  className="btn btn-primary btn-block"
                >
                  Add
                </button>
              </div>
            </div>
          </Card>
          <Card
            header="FOLLOW UP AFTER"
            eventKey="4"
            onEnter={() => {
              this.props.chartActions.repeatAppointmentSetFollowType("after");
            }}
          >
            <RepeatAppointmentFollowAfter
              repeatAppointmentList={repeatAppointmentList}
              repeatAppointment={repeatAppointment}
              initialValues={{ occurrenceItem: 1 }}
              repeatAppointmentClickedRepeatArray={repeatAppointmentClickedRepeatArray}
              repeatAppointmentRepeatModalIndex={repeatAppointmentRepeatModalIndex}
              repeatAppointmentRepeatModalDate={repeatAppointmentRepeatModalDate}
              repeatAppointmentRepeatModalMode={repeatAppointmentRepeatModalMode}
              repeatAppointmentRepeatModalVisible={repeatAppointmentRepeatModalVisible}
              repeatAppointmentConfirmModalVisible={repeatAppointmentConfirmModalVisible}
              repeatAppointmentDeleteId={repeatAppointmentDeleteId}
              disabled={followTypeNow}
            />
          </Card>
        </Accordion>
        <RepeatAppointmentConfirmModal
          text={
            "Appointment will be scheduled for " +
            moment(repeatAppointmentSelectedDate).format(DATE_FORMAT_LEGACY)
          }
          handleClose={this.props.chartActions.repeatAppointmentConfirmModalHide}
          handleOK={this.props.chartActions.repeatAppointmentSaveSelectedDate}
          show={repeatAppointmentConfirmModalVisible && followTypeNow}
        />
      </Modal>
    );
  }
}

export default enhancer(RepeatAppointmentSidebar);
