import _ from "lodash";
import { showMessage, startLoader, stopLoader } from "./loaderActions";

import * as ActionType from "../actionTypes/followUpActionTypes";

import Utils from "../helpers/Utils";

import FollowUpApi from "../api-legacy/FollowUpApi";
import { SelectValues } from "../constants/RepeatAppointmentDataTypes";
import { getCompanyDate, getCompanyEndOfDay, getCompanyStartOfDay } from "../helpers/DateUtils";
import moment from "moment/moment";

export function setData(data) {
  return {
    type: ActionType.FOLLOW_UP_SET_DATA,
    data,
  };
}

function getUnixTime(momentDate) {
  const startDate = new Date(momentDate);

  return startDate.getMilliseconds() - startDate.getTimezoneOffset() * 60000;
}

function getUnixDate(momentDate) {
  return momentDate.valueOf() + getUnixTime(momentDate);
}

export function doctorSelect(ids) {
  return {
    type: ActionType.FOLLOW_UP_SELECT_DOCTORS,
    ids,
  };
}
export function clinicSelect(id) {
  return {
    type: ActionType.FOLLOW_UP_SELECT_CLINIC,
    id,
  };
}

export function setFromDate(data) {
  return {
    type: ActionType.FOLLOW_UP_SET_FROM_DATE,
    date: data,
  };
}

export function setToDate(data) {
  return {
    type: ActionType.FOLLOW_UP_SET_TO_DATE,
    date: data,
  };
}

export function onTypeSelect(ids) {
  return {
    type: ActionType.FOLLOW_UP_SET_TYPE_FILTER,
    ids,
  };
}

export function changeChartNumber(chartNumber) {
  return {
    type: ActionType.FOLLOW_UP_SET_CHART_NUMBER,
    chartNumber,
  };
}

export function setFollowTabType(activeTab) {
  return {
    type: ActionType.FOLLOW_UP_SET_ACTIVE_TAB,
    activeTab,
  };
}

export function getList(values) {
  return (dispatch, getState) => {
    const {
      session: { clinic, member },
      followUp: { activeTab, clinicId, typeList = [], showResponded, showComplete },
    } = getState();
    let typeIds;
    if (values && !values?.typeIds.length) {
      if (activeTab === "query") {
        typeIds = typeList
          .filter(
            ({ description }) =>
              description === "Doctor Query Form - Claims" ||
              description === "Doctor Query Form - Approvals" ||
              description === "Doctor Query Form - Claims Resubmission",
          )
          .map(({ id }) => id)
          .join(", ");
      } else {
        typeIds = typeList
          .filter(
            ({ description }) =>
              description !== "Doctor Query Form - Claims" &&
              description !== "Doctor Query Form - Approvals" &&
              description !== "Doctor Query Form - Claims Resubmission",
          )
          .map(({ id }) => id)
          .join(", ");
      }
    } else {
      typeIds = (values?.typeIds || []).map((item) => item.value).join(", ");
    }
    const doctorIds = (values?.doctorIds || []).map((item) => item.value).join(", ");
    const data = {
      typeIds,
      doctorIds,
      // clinicId: values?.clinicId,
      clinicId: values?.clinicId || clinicId || clinic.id,
      start: 0,
      limit: 25,
      done: showComplete,
      chartNumber: values?.chartNumber,
      endTime: getCompanyEndOfDay(values?.toDate) || null,
      startTime: getCompanyStartOfDay(values?.fromDate) || null,
    };
    if (showResponded) {
      data.responded = false;
      data.doctorIds = member.id;
    }
    dispatch(startLoader());
    return dispatch({
      api: FollowUpApi.getList,
      types: [
        ActionType.FOLLOW_UP_GET_LIST_START,
        ActionType.FOLLOW_UP_GET_LIST_SUCCESS,
        ActionType.FOLLOW_UP_GET_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getCalendarFollowUpCount() {
  return (dispatch, getState) => {
    const {
      session: { clinic },
    } = getState();
    const data = {
      clinicId: clinic.id,
    };

    return dispatch({
      api: FollowUpApi.getCount,
      types: [
        ActionType.FOLLOW_UP_GET_COUNT_START,
        ActionType.FOLLOW_UP_GET_COUNT_SUCCESS,
        ActionType.FOLLOW_UP_GET_COUNT_ERROR,
      ],
      data,
    });
  };
}

export function getListByTab() {
  return function (dispatch, getState) {
    const {
      session: { clinic, member },
      followUp: {
        doctorsIds: doctors,
        fromDate,
        toDate,
        chartNumber,
        clinicId,
        typeList = [],
        activeTab,
        showResponded,
        showComplete,
      },
    } = getState();
    let typeIds;
    const doctorIds = (doctors || []).map((item) => item.value).join(", ");
    if (activeTab === "query") {
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_CLAIM",
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_PRE_APPROVAL",
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_CLAIMS_RESUBMISSION"
      typeIds = typeList
        .filter(
          ({ description }) =>
            description === "Doctor Query Form - Claims" ||
            description === "Doctor Query Form - Approvals" ||
            description === "Doctor Query Form - Claims Resubmission",
        )
        .map(({ id }) => id)
        .join(", ");
    } else {
      typeIds = typeList
        .filter(
          ({ description }) =>
            description !== "Doctor Query Form - Claims" &&
            description !== "Doctor Query Form - Approvals" &&
            description !== "Doctor Query Form - Claims Resubmission",
        )
        .map(({ id }) => id)
        .join(", ");
    }
    const data = {
      typeIds,
      clinicId: clinicId ? clinicId : clinic.id,
      start: 0,
      limit: 25,
      done: showComplete,
      endTime: Utils.getEndDay(toDate),
      startTime: Utils.getStartDay(fromDate),
      doctorIds,
      chartNumber,
    };

    if (showResponded) {
      data.responded = false;
      data.doctorIds = member.id;
      data.startTime = Utils.getStartDay(moment().subtract(1, "months"));
    }

    dispatch(startLoader());

    return dispatch({
      api: FollowUpApi.getList,
      types: [
        ActionType.FOLLOW_UP_GET_LIST_START,
        ActionType.FOLLOW_UP_GET_LIST_SUCCESS,
        ActionType.FOLLOW_UP_GET_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function loadList() {
  return function (dispatch, getState) {
    const {
      session: { clinic, member },
      followUp: {
        doctorsIds: doctors,
        fromDate,
        toDate,
        chartNumber,
        clinicId,
        typeList = [],
        activeTab,
        showResponded,
        showComplete,
        list,
        fetching,
        totalCount,
      },
    } = getState();
    if (fetching || (list.length > 0 && list.length === totalCount)) {
      return;
    }
    let typeIds;
    const doctorIds = (doctors || []).map((item) => item.value).join(", ");
    if (activeTab === "query") {
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_CLAIM",
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_PRE_APPROVAL",
      // "FOLLOW_UP_TYPE_DOCTOR_QUERY_FORM_CLAIMS_RESUBMISSION"
      typeIds = typeList
        .filter(
          ({ description }) =>
            description === "Doctor Query Form - Claims" ||
            description === "Doctor Query Form - Approvals" ||
            description === "Doctor Query Form - Claims Resubmission",
        )
        .map(({ id }) => id)
        .join(", ");
    } else {
      typeIds = typeList
        .filter(
          ({ description }) =>
            description !== "Doctor Query Form - Claims" &&
            description !== "Doctor Query Form - Approvals" &&
            description !== "Doctor Query Form - Claims Resubmission",
        )
        .map(({ id }) => id)
        .join(", ");
    }
    const data = {
      typeIds,
      clinicId: clinicId ? clinicId : clinic.id,
      start: list.length,
      limit: 25,
      done: showComplete,
      endTime: Utils.getEndDay(toDate),
      startTime: Utils.getStartDay(fromDate),
      doctorIds,
      chartNumber,
    };

    if (showResponded) {
      data.responded = false;
      data.doctorIds = member.id;
      data.startTime = Utils.getStartDay(moment().subtract(1, "months"));
    }

    dispatch(startLoader());

    return dispatch({
      api: FollowUpApi.getList,
      types: [
        ActionType.FOLLOW_UP_GET_LIST_START,
        ActionType.FOLLOW_UP_GET_LIST_SUCCESS,
        ActionType.FOLLOW_UP_GET_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getRespondedList() {
  return function (dispatch, getState) {
    const {
      session: { clinic, member },
      followUp: { fromDate, toDate, chartNumber, clinicId, showComplete },
    } = getState();
    const doctorIds = member.id;
    const data = {
      clinicId: clinicId ? clinicId : clinic.id,
      start: 0,
      limit: 50,
      done: showComplete,
      responded: false,
      endTime: Utils.getEndDay(toDate),
      startTime: Utils.getStartDay(fromDate),
      doctorIds,
      chartNumber,
    };

    dispatch(startLoader());

    return dispatch({
      api: FollowUpApi.getList,
      types: [
        ActionType.FOLLOW_UP_GET_LIST_START,
        ActionType.FOLLOW_UP_GET_LIST_SUCCESS,
        ActionType.FOLLOW_UP_GET_LIST_ERROR,
      ],
      data,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function repeatItem(exactSameTime) {
  return (dispatch, getState) => {
    dispatch(startLoader());

    const {
      appointment: { repeatAppointmentSelectedDate },
      followUp: {
        item: { id, doctor },
      },
    } = getState();

    const data = {
      timeFromStartDate: 24 * 60 * 60 * 1000 + Utils.getCompanyTimeZone(),
      dentistId: doctor.id,
      followUpId: id,
      startDate: Utils.getStartDay(repeatAppointmentSelectedDate),
      createdSource: {
        code: "FROM_APPOINTMENT_DETAILS",
      },
      exactSameTime,
    };

    return dispatch(repeatItemApi(data))
      .then(() => {
        dispatch(stopLoader());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTypes() {
  return function (dispatch, getState) {
    const {
      calendar: { clinicId },
    } = getState();

    return (
      dispatch({
        api: FollowUpApi.getTypes,
        types: [
          ActionType.FOLLOW_UP_GET_TYPE_LIST_START,
          ActionType.FOLLOW_UP_GET_TYPE_LIST_SUCCESS,
          ActionType.FOLLOW_UP_GET_TYPE_LIST_ERROR,
        ],
        data: { clinicId },
      })
        // .then(() => dispatch(getListByTab()))
        .catch((error) => dispatch(showMessage(error)))
    );
  };
}

export function complete(item) {
  return function (dispatch) {
    dispatch(startLoader());

    item.done = true;
    item.complatedDate = getCompanyDate(new Date()).valueOf();

    return dispatch({
      api: FollowUpApi.saveItem,
      types: [
        ActionType.FOLLOW_UP_SAVE_ITEM_START,
        ActionType.FOLLOW_UP_SAVE_ITEM_SUCCESS,
        ActionType.FOLLOW_UP_SAVE_ITEM_ERROR,
      ],
      data: item,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)))
      .then(() => {
        dispatch(closeEditForm());
        dispatch(getList());
      });
  };
}
export function review(item) {
  return function (dispatch) {
    dispatch(startLoader());

    return dispatch({
      api: FollowUpApi.reviewItem,
      types: [
        ActionType.FOLLOW_UP_REVIEW_ITEM_SUCCESS,
        ActionType.FOLLOW_UP_REVIEW_ITEM_SUCCESS,
        ActionType.FOLLOW_UP_REVIEW_ITEM_ERROR,
      ],
      data: item,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
    // .then(() => dispatch(getList()));
  };
}
export function reopen(item) {
  return function (dispatch) {
    dispatch(startLoader());

    return dispatch({
      api: FollowUpApi.reopenItem,
      types: [
        ActionType.FOLLOW_UP_REOPEN_ITEM_START,
        ActionType.FOLLOW_UP_REOPEN_ITEM_SUCCESS,
        ActionType.FOLLOW_UP_REOPEN_ITEM_ERROR,
      ],
      data: item,
    })
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
    // .then(() => dispatch(getList()));
  };
}

export function toggleComplete() {
  return {
    type: ActionType.FOLLOW_UP_TOGGLE_COMPLETE,
  };
}

export function toggleResponded(showResponded) {
  return {
    type: ActionType.FOLLOW_UP_TOGGLE_RESPONDED,
    showResponded,
  };
}

export function toggleEditForm(item) {
  return {
    type: ActionType.FOLLOW_UP_TOGGLE_EDIT_FORM,
    item,
  };
}

export function closeEditForm() {
  return {
    type: ActionType.FOLLOW_UP_CLOSE_EDIT_FORM,
  };
}

export const saveTrxPlanning = () => (dispatch, getState) => {
  dispatch(startLoader());

  const {
    form: {
      repeatAppointmentTrxPlanning: { values = {} },
    },
    followUp: {
      item: {
        patient: { patientKey },
        id,
        done,
        date,
      },
    },
  } = getState();

  const transfer = {
    id,
    done,
    date,
    note: values.note,
    note2: values.note2,
    doctor: {
      id: values.doctor,
    },
    type: {
      id: values.type,
    },
    patientKey,
  };

  dispatch({
    api: FollowUpApi.saveItem,
    types: [
      ActionType.FOLLOW_UP_SAVE_ITEM_START,
      ActionType.FOLLOW_UP_SAVE_ITEM_SUCCESS,
      ActionType.FOLLOW_UP_SAVE_ITEM_ERROR,
    ],
    data: transfer,
  })
    .then(() => dispatch(stopLoader()))
    .catch((error) => dispatch(stopLoader(error)))
    .then(() => dispatch(toggleEditForm({})))
    .then(() => dispatch(getList()));
};

export function deleteItem(id) {
  return (dispatch, getState) => {
    const {
      followUp: { item },
    } = getState();

    dispatch(startLoader());

    return dispatch(deleteItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getList());
        if (item.id) {
          dispatch(getRulesList(item.id));
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function openAppointment(data) {
  return (dispatch, getState) => {
    const {
      form: { repeatAppointmentTrxPlanning },
      followUp,
    } = getState();

    return {
      isSchedule: true,
      doctor:
        repeatAppointmentTrxPlanning &&
        repeatAppointmentTrxPlanning.values &&
        repeatAppointmentTrxPlanning.values.doctor
          ? repeatAppointmentTrxPlanning.values.doctor
          : data.doctor,
      patientKey:
        followUp && followUp.item && followUp.item.patient && followUp.item.patient.patientKey
          ? followUp.item.patient.patientKey
          : data.patientKey,
      goBack: true,
    };
  };
}

export function getRulesList(followUpId) {
  return (dispatch) => {
    dispatch(startLoader());

    const data = {
      followUpId,

      start: 0,
      limit: 20,
    };

    return dispatch(repeatListApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function repeatItem2(index) {
  return (dispatch, getState) => {
    const {
      followUp: { item, repeatList },
      appointment: { repeatAppointmentRepeatModalDate },
      form: {
        repeatAppointment: { values },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      id: repeatList[index].id,
      period: repeatList[index].period,
      followUpId: item.id,
      startDate: getUnixDate(repeatAppointmentRepeatModalDate),
      periodUnit: repeatList[index].periodUnit,
      limitCount: values.occurrenceItem,
      endType: {
        id: 2,
        name: "RecurrenceEnd_By_CountLimit",
        code: "RecurrenceEnd_By_CountLimit",
      },
    };

    return dispatch(repeatItemApi(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function repeatAppointmentSaveAfter() {
  return (dispatch, getState) => {
    const {
      form: {
        repeatAppointment: { values },
      },
      followUp: {
        item: { id },
      },
      appointment: { repeatAppointmentSelectedDate },
    } = getState();

    if (_.isEmpty(values) || !values.mainSelect || !values.mainItem) return;

    dispatch(startLoader());

    const data = {
      period: values.mainItem,
      followUpId: id,
      limitCount: 1,
      endType: {
        id: 2,
        name: "RecurrenceEnd_By_CountLimit",
        code: "RecurrenceEnd_By_CountLimit",
      },
      periodUnit: _.find(SelectValues, ["code", values.mainSelect]),
      startDate: getUnixDate(repeatAppointmentSelectedDate),
    };

    return dispatch(repeatItemApi(data))
      .then((response) => {
        dispatch(stopLoader());
        dispatch(getList());

        if (response.followUpId) {
          dispatch(getRulesList(response.followUpId));
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function repeatItemApi(data) {
  return {
    api: FollowUpApi.createRepeatRule,
    types: [
      ActionType.FOLLOW_UP_REPEAT_ITEM_START,
      ActionType.FOLLOW_UP_REPEAT_ITEM_SUCCESS,
      ActionType.FOLLOW_UP_REPEAT_ITEM_ERROR,
    ],
    data,
  };
}

function repeatListApi(data) {
  return {
    api: FollowUpApi.getRuleList,
    types: [
      ActionType.FOLLOW_UP_REPEAT_LIST_START,
      ActionType.FOLLOW_UP_REPEAT_LIST_SUCCESS,
      ActionType.FOLLOW_UP_REPEAT_LIST_ERROR,
    ],
    data,
  };
}

function deleteItemApi(data) {
  return {
    api: FollowUpApi.deleteRepeatRule,
    types: [
      ActionType.FOLLOW_UP_REPEAT_DELETE_ITEM_START,
      ActionType.FOLLOW_UP_REPEAT_DELETE_ITEM_SUCCESS,
      ActionType.FOLLOW_UP_REPEAT_DELETE_ITEM_ERROR,
    ],
    data,
  };
}
