import _, { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import Show from "../../components/widgets/Show";
import ChartAlerts from "../../components/chart/ChartAlerts";
import ChartTeeth from "../../components/chart/teeth/ChartTeeth";
import ChartInfo from "../../components/chart/top-panel/ChartInfo";
import ChartGeneral from "../../components/chart/general/ChartGeneral";
import ChartSideBar from "../../components/chart/side-bar/ChartSideBar";
import ChartTooltip from "../../components/chart/side-bar/ChartTooltip";
import ChartBottomMenu from "../../components/chart/bottom-menu/ChartBottomMenu";
import ChartDermatology from "../../components/chart/dermatology/ChartDermatology";
import ChartTransaction from "../../components/chart/transactions/ChartTransaction";
import ChartChooseTooltip from "../../components/chart/side-bar/ChartChooseTooltip";
import ChartProcedureConfirmModal from "../../components/chart/side-bar/ChartProcedureConfirmModal";
import ChartDocumentsAssignedModal from "../../components/chart/side-bar/ChartDocumentsAssignedModal";
import RepeatAppointmentSidebar from "../../components/chart/repeat-appointment/RepeatAppointmentSidebar";
import ChartDocumentsAssignedInsideModal from "../../components/chart/side-bar/ChartDocumentsAssignedInsideModal";
import SelectTreatmentToCompleteModal from "../../components/chart/transactions/select-treatments-to-complete/SelectTreatmentToCompleteModal";
import {
  getClinics,
  getPatientGenderList,
  handleSubmitPasswordForHistory,
  hideSideBar,
  hideTooltip,
  initChart,
  openPatientDocsSideBar,
  closePatientDocsSideBar,
  selectTreatmentToCompleteModalShow,
  setPostPlan,
  showChooseTooltip,
  showPasswordModalForHistory,
  showPatientHistoryModal,
  showSideBar,
  showTooltip,
} from "../../actions/chartActions";
import { getList } from "../../actions/chartSideSeriesActions";
import {
  clinicFilterChange,
  endDateFilterChange,
  startDateFilterChange,
} from "../../actions/transactionActions";
import DocsModal from "../../components/chart/side-bar/docs/DocsModal";
import DocsAddFolder from "../../components/chart/side-bar/docs/DocsAddFolder";
import {
  changeMovingMode,
  closeFolder,
  createFolder,
  deleteItems,
  getFolderList,
  hideCreateDialog,
  initFolders,
  loadFilesList,
  moveItems,
  openFolder,
  openImage,
  resetFolder,
  selectItem,
  showCreateDialog,
  uploadFiles,
} from "../../actions/folderActions";
import { getPriceList } from "../../actions/patientActions";
import { DocsViewModal } from "../../components/chart/side-bar/docs/DocsViewModal";
import ChartTreatmentsSidebarWrapper from "../../wrappers/ChartTreatmentsSidebarWrapper";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { getCountByStatus } from "../../actions/appointmentActions";
import { RequestLabOrder } from "../../components/request-lab-order/RequestLabOrderSidebar";
import ReactSpinner from "react-spinjs-fix";
import ChartTransactionChooseDeleteModal from "../../components/chart/side-bar/transaction/ChartTransactionChooseDeleteModal";
import ChartTransactionConfirmDeleteModal from "../../components/chart/side-bar/transaction/ChartTransactionConfirmDeleteModal";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { Modal } from "../../components/ui/Modal";
import ReferralNextVisitModal from "../../components/referral-next-visit/ReferralNextVisit";
import MedicalFillInFormSideBar from "../../components/chart/side-bar/medical-fill-in-form/MedicalFillInFormSideBar";
import { medicalFillInFormHideSideBar } from "../../actions/chartSideMedicalFillInFormActions";
import AdvancedPayment from "../../components/chart/advanced-payment/AdvancedPayment";
// import SelectTreatmentToCompleteNoteModal from "../../components/chart/transactions/select-treatments-to-complete/SelectTreatmentToCompleteNoteModal";
import XRayForm from "../../components/chart/XRayForm";
import { IS_ELECTRON } from "../../constants/AppConstants";
// import SelectTreatmentToCompleteNoteModal from "../../components/chart/transactions/select-treatments-to-complete/SelectTreatmentToCompleteNoteModal";
import { medicalFillInFormStackCodes } from "../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import medicalFillInFormItemTypes from "../../constants/chart-sidebar/medicalFillInFormItemTypes";

const enhancer = connect(
  ({
    session: { toothNumericType, permissions, clinicalNotesSideBarViewType, clinic, miscSettings },
    chart,
    chartSideSeries,
    chartSideClinicalNote,
    chartSideFillInForm,
    chartSideOrder,
    chartSideMedicalFillInForm,
    chartSideVital,
    chartSidePrescription,
    chartDermatology,
    chartSideTransaction,
    chartSidePlan,
    chartSideDocs,
    chartGeneral,
    chartSidePostOp,
    appointment: { completeAppointmentsCount },
    calendar: { doctorsPoints, doctorDropdownVisible },
    form: { repeatAppointment, repeatAppointmentAnotherDoctor },
    auth: { members, chosenMemberId },
    folder,
  }) => ({
    miscSettings,
    toothNumericType,
    clinicalNotesSideBarViewType,
    chart,
    chartSideSeries,
    chartSideClinicalNote,
    chartSideFillInForm,
    chartSideOrder,
    chartSideMedicalFillInForm,
    completeAppointmentsCount,
    chartSideVital,
    chartSidePrescription,
    chartDermatology,
    chartSideTransaction,
    chartSidePlan,
    chartSideDocs,
    chartGeneral,
    chartSidePostOp,
    repeatAppointment,
    member: members[chosenMemberId],
    members,
    chosenMemberId,
    repeatAppointmentAnotherDoctor,
    permissions,
    doctorsPoints,
    doctorDropdownVisible,
    folder,
    clinic,
  }),
  {
    getPriceList,
    getClinics,
    getList,
    initChart,
    showTooltip,
    hideTooltip,
    showSideBar,
    hideSideBar,
    showChooseTooltip,
    showCreateDialog,
    hideCreateDialog,
    createFolder,
    getFolderList,
    loadFilesList,
    openFolder,
    closeFolder,
    initFolders,
    getCountByStatus,
    selectItem,
    deleteItems,
    uploadFiles,
    changeMovingMode,
    moveItems,
    showPasswordModalForHistory,
    showPatientHistoryModal,
    handleSubmitPasswordForHistory,
    openImage,
    getPatientGenderList,
    resetFolder,
    clinicFilterChange,
    startDateFilterChange,
    endDateFilterChange,
    setPostPlan,
    selectTreatmentToCompleteModalShow,
    openPatientDocsSideBar,
    closePatientDocsSideBar,
    medicalFillInFormHideSideBar,
  },
);

export default enhancer(
  class Chart extends React.Component {
    state = {
      password: "",
    };

    componentDidMount() {
      const {
        clinic,
        getList,
        location,
        getClinics,
        getPriceList,
        getCountByStatus,
        clinicFilterChange,
        getPatientGenderList,
        startDateFilterChange,
        endDateFilterChange,
        hideAlerts,
      } = this.props;

      const query = parseQuery(location.search);

      this.props.initChart({ ...query, hideAlerts });

      endDateFilterChange(null);
      startDateFilterChange(null);

      const clinicId = _.get(clinic, "id", "all");
      const clinicName = _.get(clinic, "name", "All Clinics");

      getPriceList({ patientKey: query.patientKey });
      getPatientGenderList();
      getList();
      getClinics();
      getCountByStatus({
        specialityCode: "",
        patientKey: query.patientKey,
        statusCode: "APPOINTMENT_STATUS_COMPLETE",
      });
      clinicFilterChange({ value: clinicId, label: clinicName });
    }

    setPassword = (password) => {
      this.setState({ password });
    };

    render() {
      const {
        toothNumericType,
        clinicalNotesSideBarViewType,
        chart,
        location,
        chart: {
          showDocsModal,
          teeth,
          patient,
          appointment,
          activeTooth,
          activeToothSupernumeraryPresent,
          tooltipVisible,
          sideBarVisible,
          viewType,
          forStaff,
          alertsVisible,
          chartBalanceModalVisible,
          chartBalanceValue,
          selectTreatmentToCompleteModalVisible,
          toothPosition,
          chooseTooltipVisible,
          eyeCheckedMap,
          passwordModalForHistoryVisibility,
          fetchingPatientHistory,
        },
        chartSideSeries,
        chartSideClinicalNote,
        chartSideFillInForm,
        chartSideMedicalFillInForm,
        chartSideOrder: { orderStack },
        chartSideVital,
        chartSidePrescription,
        chartDermatology,
        chartSideTransaction,
        chartSidePlan,
        chartSideDocs,
        chartGeneral,
        chartSidePostOp,
        member,
        members,
        miscSettings,
        chosenMemberId,
        repeatAppointment,
        repeatAppointmentAnotherDoctor,
        permissions,
        doctorsPoints,
        doctorDropdownVisible,
        folder,
        clinic,
        history,
        completeAppointmentsCount,
        openPatientDocsSideBar,
        closePatientDocsSideBar,
        medicalFillInFormHideSideBar,
      } = this.props;

      const {
        params: { type },
      } = this.props.match;
      const orderStackItem = _.last(orderStack);

      const chartType = member?.chartType?.code;
      const isDentalChart = chartType === "DENTAL_CHART";
      const isDermatologyChart = chartType === "DERMATOLOGY_CHART";
      const isGeneralChart = chartType === "GENERAL_MEDICINE_CHART";

      const canShowChart = (!isEmpty(teeth) && isDentalChart) || !isDentalChart;

      const imageItem = {};

      const query = parseQuery(this.props.location.search);

      const appointmentId = _.toFinite(_.get(query, "appointmentId", 0));

      if (folder.openedImage) {
        const currentFolder = _.last(folder.folderStack) || 0;

        imageItem.url = _.get(
          _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
          "url",
        );
        imageItem.description = _.get(
          _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
          "name",
        );
        imageItem.createdDate = _.get(
          _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
          "createdDate",
        );
      }

      return (
        <div className="d-flex flex-column flex-shrink-1 flex-grow-1 chart-page">
          {IS_ELECTRON && patient.chartNumber && <XRayForm patient={patient} />}
          <ChartInfo
            patient={patient}
            appointment={appointment}
            type={type}
            completeAppointmentsCount={completeAppointmentsCount}
            chartBalanceModalVisible={chartBalanceModalVisible}
            chartBalanceValue={chartBalanceValue}
            mouthLinkVisible={chartType === "DENTAL_CHART"}
          />
          {canShowChart && (
            <div
              className="d-flex flex-column justify-content-center"
              style={{ padding: "12px 0" }}
            >
              {isDentalChart && (
                <ChartTeeth
                  teeth={teeth}
                  activeTooth={activeTooth}
                  type={type}
                  toothNumericType={toothNumericType}
                  eyeCheckedMap={eyeCheckedMap}
                  showTooltip={this.props.showTooltip}
                  showSideBar={this.props.showSideBar}
                  showChooseTooltip={this.props.showChooseTooltip}
                />
              )}

              {isDermatologyChart && (
                <ChartDermatology chartDermatology={chartDermatology} patient={patient} />
              )}

              {isGeneralChart && (
                <ChartGeneral
                  history={history}
                  location={location}
                  chartGeneral={chartGeneral}
                  query={query}
                  chartSidePostOp={chartSidePostOp}
                  chartSideClinicalNote={chartSideClinicalNote}
                  chartSideFillInForm={chartSideFillInForm}
                />
              )}
            </div>
          )}

          {canShowChart && (
            <ChartTransaction
              params={query}
              appointment={appointment}
              teeth={teeth}
              toothNumericType={toothNumericType}
            />
          )}

          <Modal
            size="sm"
            show={passwordModalForHistoryVisibility}
            title="Password"
            onHide={() => this.setState({ password: "" })}
            keyboard={false}
            actions={
              <>
                <Button onClick={() => this.props.showPasswordModalForHistory(false)}>
                  Cancel
                </Button>
                <Button
                  className="btn-primary"
                  disabled={this.state.password.length === 0}
                  onClick={() => {
                    this.props.handleSubmitPasswordForHistory({
                      password: this.state.password,
                      patientKey: patient.patientKey,
                    });
                    this.setState({ password: "" });
                  }}
                >
                  Ok
                </Button>
              </>
            }
          >
            <input
              type="password"
              placeholder="password"
              className="form-control"
              value={this.state.password}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.props.handleSubmitPasswordForHistory({
                    password: this.state.password,
                    patientKey: patient.patientKey,
                  });
                  this.setState({ password: "" });
                }
              }}
              onChange={(e) => this.setPassword(e.target.value)}
            />
          </Modal>

          <ChartDocumentsAssignedModal
            chartSideSeries={chartSideSeries}
            activeTooth={activeTooth}
            patientKey={query.patientKey}
          />
          <ChartDocumentsAssignedInsideModal
            chartSideSeries={chartSideSeries}
            patient={patient}
            member={member}
          />
          <MedicalFillInFormSideBar
            visible={chartSideMedicalFillInForm.medicalFillInFormSideBarVisible}
            onHide={medicalFillInFormHideSideBar}
            member={member}
            clinic={clinic}
            location={location}
            history={history}
            patientKey={query.patientKey}
          />
          <ChartSideBar
            points={doctorsPoints}
            clinicalNotesSideBarViewType={clinicalNotesSideBarViewType}
            doctorDropdownVisible={doctorDropdownVisible}
            teeth={teeth}
            history={history}
            location={location}
            member={member}
            sideBarVisible={sideBarVisible}
            viewType={viewType}
            toothNumericType={toothNumericType}
            chartSideSeries={chartSideSeries}
            chartSideClinicalNote={chartSideClinicalNote}
            chartSideFillInForm={chartSideFillInForm}
            chartSideVital={chartSideVital}
            chartSidePrescription={chartSidePrescription}
            chartSideTransaction={chartSideTransaction}
            chartSidePlan={chartSidePlan}
            chartSideDocs={chartSideDocs}
            chartGeneral={chartGeneral}
            chartSidePostOp={chartSidePostOp}
            chart={chart}
            permissions={permissions}
            clinic={clinic}
            hideSideBar={this.props.hideSideBar}
          />
          <ChartTooltip
            activeTooth={activeTooth}
            activeToothSupernumeraryPresent={activeToothSupernumeraryPresent}
            tooltipVisible={tooltipVisible}
            hideTooltip={this.props.hideTooltip}
          />
          <ChartChooseTooltip
            teeth={teeth}
            toothPosition={toothPosition}
            chooseTooltipVisible={chooseTooltipVisible}
            toothNumericType={toothNumericType}
          />
          <ChartBottomMenu
            history={this.props.history}
            onDocsClick={() => openPatientDocsSideBar()}
          />
          <ChartAlerts
            patient={patient}
            forStaff={forStaff}
            alertsVisible={alertsVisible && !chartSideSeries.documentsAssignedModalVisible}
            onMedicalEditClick={() =>
              history.push(
                createUrl(Routes.PatientMedicalAlertsEdit, {
                  query: { patientKey: query.patientKey },
                }),
              )
            }
          />
          <ChartProcedureConfirmModal chartSideSeries={chartSideSeries} />
          <ReferralNextVisitModal />
          <Show if={selectTreatmentToCompleteModalVisible}>
            <SelectTreatmentToCompleteModal
              chart={chart}
              members={members}
              isPlanning={
                _.get(miscSettings, "postTreatmentWithoutCompleteAppointment", false) &&
                appointmentId > 0
              }
              chosenMemberId={chosenMemberId}
            />
          </Show>
          {/*<SelectTreatmentToCompleteNoteModal*/}
          {/*  chart={chart}*/}
          {/*  isPlanning={*/}
          {/*    _.get(miscSettings, "postTreatmentWithoutCompleteAppointment", false) &&*/}
          {/*    appointmentId > 0*/}
          {/*  }*/}
          {/*/>*/}
          <Show if={chart.repeatAppointmentModalVisible}>
            <RepeatAppointmentSidebar
              chart={chart}
              repeatAppointment={repeatAppointment}
              repeatAppointmentAnotherDoctor={repeatAppointmentAnotherDoctor}
            />
          </Show>

          <Show if={showDocsModal}>
            <DocsModal
              open={showDocsModal}
              openFolder={(folderId) =>
                this.props.openFolder({
                  folderId,
                  patientKey: query.patientKey,
                })
              }
              closeFolder={() =>
                this.props.closeFolder({
                  patientKey: query.patientKey,
                })
              }
              onUploadFiles={(files) =>
                this.props.uploadFiles({
                  patientKey: query.patientKey,
                  files,
                  folderId: _.last(folder.folderStack) || null,
                })
              }
              selectItem={this.props.selectItem}
              fetchingFolders={folder.fetchingFolders}
              folder={folder}
              onSelectMoveFolder={(targetFolderId, isClosed) =>
                this.props.moveItems(
                  {
                    patientKey: query.patientKey,
                    targetFolderId,
                  },
                  isClosed,
                )
              }
              onRequestClose={() => {
                closePatientDocsSideBar();
              }}
              openFile={(id) => {
                openPatientDocsSideBar();
                this.props.openImage(id);
              }}
              onMove={() => this.props.changeMovingMode(true)}
              onDelete={() => this.props.deleteItems(query.patientKey)}
              getFolderList={this.props.getFolderList}
              loadFilesList={this.props.loadFilesList}
              onCreateFolderClick={() => this.props.showCreateDialog()}
              patientKey={query.patientKey}
            />
          </Show>

          <Show if={showDocsModal && folder.createFolderDialogVisible}>
            <DocsAddFolder
              onSubmit={(values) =>
                this.props.createFolder({
                  name: values.folderName,
                  parentId: _.last(folder.folderStack) || null,
                  patientKey: query.patientKey,
                })
              }
              open={folder.createFolderDialogVisible}
              onRequestClose={() => this.props.hideCreateDialog()}
            />
          </Show>

          <Show if={Boolean(folder.openedImage)}>
            <DocsViewModal
              show={Boolean(folder.openedImage)}
              url={imageItem.url}
              list={_.values(folder.filesList)[0]}
              imageId={folder.openedImage}
              description={imageItem.description}
              createdDate={imageItem.createdDate}
              onRequestClose={() => {
                this.props.openImage(0);
                openPatientDocsSideBar();
              }}
            />
          </Show>

          {Boolean(
            (viewType === viewTypes.series || viewType === viewTypes.transaction) && sideBarVisible,
          ) && (
            <ChartTreatmentsSidebarWrapper
              inAppointment={
                (orderStackItem?.code === medicalFillInFormStackCodes.labOrder &&
                  orderStackItem?.type === medicalFillInFormItemTypes.new) ||
                (orderStackItem?.code === medicalFillInFormStackCodes.radiologyOrder &&
                  orderStackItem?.type === medicalFillInFormItemTypes.new)
              }
              viewType={viewType}
              open={sideBarVisible}
              onRequestClose={this.props.hideSideBar}
              location={location}
              history={history}
            />
          )}

          <RequestLabOrder />
          <Show if={fetchingPatientHistory}>
            <ReactSpinner />
          </Show>

          <ChartTransactionChooseDeleteModal />
          <ChartTransactionConfirmDeleteModal />
          <AdvancedPayment />
        </div>
      );
    }
  },
);
