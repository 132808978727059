export const fillInFormStackCodes = {
  owned: "owned",
  list: "list",
  item: "item",
  textarea: "textarea",
};

export const defaultStackItem = {
  code: fillInFormStackCodes.owned,
};
