export const CHART_TRANSACTION_INIT_PLAN_MODAL = "CHART_TRANSACTION_INIT_PLAN_MODAL";

export const CHART_TRANSACTION_OPEN_PLAN_MODAL = "CHART_TRANSACTION_OPEN_PLAN_MODAL";
export const CHART_TRANSACTION_CLOSE_PLAN_MODAL = "CHART_TRANSACTION_CLOSE_PLAN_MODAL";

export const CHART_TRANSACTION_TOGGLE_ADD_MODE = "CHART_TRANSACTION_TOGGLE_ADD_MODE";

export const CHART_TRANSACTION_GET_PLAN_LIST_START = "CHART_TRANSACTION_GET_PLAN_LIST_START";
export const CHART_TRANSACTION_GET_PLAN_LIST_SUCCESS = "CHART_TRANSACTION_GET_PLAN_LIST_SUCCESS";
export const CHART_TRANSACTION_GET_PLAN_LIST_ERROR = "CHART_TRANSACTION_GET_PLAN_LIST_ERROR";

export const CHART_TRANSACTION_REMOVE_SELECTED_START = "CHART_TRANSACTION_REMOVE_SELECTED_START";
export const CHART_TRANSACTION_REMOVE_SELECTED_SUCCESS =
  "CHART_TRANSACTION_REMOVE_SELECTED_SUCCESS";
export const CHART_TRANSACTION_REMOVE_SELECTED_ERROR = "CHART_TRANSACTION_REMOVE_SELECTED_ERROR";

export const CHART_TRANSACTION_ADD_SELECTED_START = "CHART_TRANSACTION_ADD_SELECTED_START";
export const CHART_TRANSACTION_ADD_SELECTED_SUCCESS = "CHART_TRANSACTION_ADD_SELECTED_SUCCESS";
export const CHART_TRANSACTION_ADD_SELECTED_ERROR = "CHART_TRANSACTION_ADD_SELECTED_ERROR";

export const CHART_TRANSACTION_GET_DOCTORS_START = "CHART_TRANSACTION_GET_DOCTORS_START";
export const CHART_TRANSACTION_GET_DOCTORS_SUCCESS = "CHART_TRANSACTION_GET_DOCTORS_SUCCESS";
export const CHART_TRANSACTION_GET_DOCTORS_ERROR = "CHART_TRANSACTION_GET_DOCTORS_ERROR";

export const CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_START =
  "CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_START";
export const CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_SUCCESS =
  "CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_SUCCESS";
export const CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_ERROR =
  "CHART_TRANSACTION_UPLOAD_TRANSACTION_IMAGE_ERROR";

export const CHART_TRANSACTION_TOGGLE_PLAN = "CHART_TRANSACTION_TOGGLE_PLAN";
export const CHART_TRANSACTION_TOGGLE_ALL_PLAN = "CHART_TRANSACTION_TOGGLE_ALL_PLAN";
export const CHART_TRANSACTION_TOGGLE_PLAN_NEW = "CHART_TRANSACTION_TOGGLE_PLAN_NEW";
export const CHART_TRANSACTION_TOGGLE_ALL_PLAN_NEW = "CHART_TRANSACTION_TOGGLE_ALL_PLAN_NEW";
export const CHART_TRANSACTION_UNSELECT_PLAN = "CHART_TRANSACTION_UNSELECT_PLAN";

export const SET_TRANSACTION_DOCTOR_FILTER = "SET_TRANSACTION_DOCTOR_FILTER";

export const SET_TRANSACTION_TOOTH_FILTER = "SET_TRANSACTION_TOOTH_FILTER";

export const SET_END_DATE_FILTER = "SET_END_DATE_FILTER";
export const SET_START_DATE_FILTER = "SET_START_DATE_FILTER";
export const SET_TRANSACTION_CODES_FILTER = "SET_TRANSACTION_CODES_FILTER";
export const SET_TRANSACTION_CLINIC_FILTER = "SET_TRANSACTION_CLINIC_FILTER";

export const CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_START =
  "CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_START";
export const CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_SUCCESS =
  "CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_SUCCESS";
export const CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_ERROR =
  "CHART_TRANSACTION_CHANGE_TREATMENT_PLAN_ERROR";

export const CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_START =
  "CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_START";
export const CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_SUCCESS =
  "CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_SUCCESS";
export const CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_ERROR =
  "CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_ERROR";

export const CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_START =
  "CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_START";
export const CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_SUCCESS =
  "CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_SUCCESS";
export const CHART_TRANSACTION_CHANGE_TREATMENT_NOTE_ERROR =
  "CHART_TRANSACTION_CHANGE_TREATMENT_SESSION_ERROR";

export const CHART_TRANSACTION_CHANGE_UNIT_START = "CHART_TRANSACTION_CHANGE_UNIT_START";
export const CHART_TRANSACTION_CHANGE_UNIT_SUCCESS = "CHART_TRANSACTION_CHANGE_UNIT_SUCCESS";
export const CHART_TRANSACTION_CHANGE_UNIT_ERROR = "CHART_TRANSACTION_CHANGE_UNIT_ERROR";

export const CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_START =
  "CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_START";
export const CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_SUCCESS =
  "CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_SUCCESS";
export const CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_ERROR =
  "CHART_TRANSACTION_CHANGE_INTERVAL_UNIT_ERROR";

export const CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_START =
  "CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_START";
export const CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_SUCCESS =
  "CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_SUCCESS";
export const CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_ERROR =
  "CHART_TRANSACTION_CHANGE_INTERVAL_AMOUNT_ERROR";

export const CHART_TRANSACTION_CHANGE_DURATION_UNIT_START =
  "CHART_TRANSACTION_CHANGE_DURATION_UNIT_START";
export const CHART_TRANSACTION_CHANGE_DURATION_UNIT_SUCCESS =
  "CHART_TRANSACTION_CHANGE_DURATION_UNIT_SUCCESS";
export const CHART_TRANSACTION_CHANGE_DURATION_UNIT_ERROR =
  "CHART_TRANSACTION_CHANGE_DURATION_UNIT_ERROR";

export const CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_START =
  "CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_START";
export const CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_SUCCESS =
  "CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_SUCCESS";
export const CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_ERROR =
  "CHART_TRANSACTION_CHANGE_DURATION_AMOUNT_ERROR";

export const CHART_TRANSACTION_GET_CURRENCIES_START = "CHART_TRANSACTION_GET_CURRENCIES_START";
export const CHART_TRANSACTION_GET_CURRENCIES_SUCCESS = "CHART_TRANSACTION_GET_CURRENCIES_SUCCESS";
export const CHART_TRANSACTION_GET_CURRENCIES_ERROR = "CHART_TRANSACTION_GET_CURRENCIES_ERROR";

export const CHART_TRANSACTION_UPDATE_PRICE_START = "CHART_TRANSACTION_UPDATE_PRICE_START";
export const CHART_TRANSACTION_UPDATE_PRICE_SUCCESS = "CHART_TRANSACTION_UPDATE_PRICE_SUCCESS";
export const CHART_TRANSACTION_UPDATE_PRICE_ERROR = "CHART_TRANSACTION_GET_CURRENCIES_ERROR";
