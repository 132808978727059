import React, { useState } from "react";

import { EligibilityInfoText, EligibilityInfoTextProps } from "./EligibilityInfoText";
import { Modal } from "../ui/Modal";
import cx from "classnames";

interface Props extends Omit<EligibilityInfoTextProps, "value"> {
  readonly link: string;
  readonly hasBenefits: boolean;
  readonly classes: any;
  readonly answerMember: any;
}

export function EligibilityInfoWithLink({
  link,
  hasBenefits,
  classes,
  answerMember,
  ...props
}: Props) {
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const openAttachment = () => {
    window.open(answerMember.attachment.split(",")[0]);
  };
  return (
    <>
      {infoModalVisible && (
        <Modal
          size={hasBenefits ? "lg" : "sm"}
          title="&nbsp;"
          show={true}
          onHide={() => setInfoModalVisible(false)}
          actions={
            <button className="btn btn-primary" onClick={() => setInfoModalVisible(false)}>
              ok
            </button>
          }
        >
          <div className={cx(classes.row, classes.secondRow)}>
            <div className={cx(classes.block, classes.leftBlock)}>
              {hasBenefits ? (
                <>
                  <EligibilityInfoText className={classes.blockTitle} title="Benefits:" />
                  {answerMember?.eligibilityCheckAnswerBenefits.map((x) => (
                    <div key={x.code} className={classes.benefitBlock}>
                      <EligibilityInfoText title="Room Type:" value={x.code} />
                      <EligibilityInfoText title="Benefit:" value={x.benefitValue} />
                    </div>
                  ))}
                </>
              ) : (
                "No data available"
              )}
            </div>
          </div>
        </Modal>
      )}
      <EligibilityInfoText
        {...props}
        value={
          <button
            type="button"
            className="btn btn-primary btn-link"
            onClick={() => {
              if (hasBenefits && answerMember.attachment) openAttachment();
            }}
          >
            CLICK HERE
          </button>
        }
      />
    </>
  );
}
