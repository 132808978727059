import _ from "lodash";
import TransactionApi from "../api-legacy/TransactionApi";

import * as ActionType from "../actionTypes/chartSideTransactionActionTypes";

import * as transactionActions from "./transactionActions";
import * as chartSideMedicalFillInFormActions from "./chartSideMedicalFillInFormActions";
import * as chartActions from "./chartActions";
import * as chartSideSeriesActions from "./chartSideSeriesActions";
import { showMessage, startLoader, stopLoader } from "./loaderActions";

import Utils from "../helpers/Utils";

import Element from "../models/Element";
import { PopupType } from "../constants/Constants";
import { setPopupActionByKey, setPopupParams } from "./popupActions";
import { medicalFillInFormStackCodes } from "../constants/chart-sidebar/medicalFIllInFormStackCodes";

export function updateTransactionDiscount(data, callback) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(updateTransactionDiscountApi(data))
      .then(() => {
        dispatch(stopLoader());
        if (callback) {
          callback();
        }
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function setTreatmentPlan(event) {
  return (dispatch, getState) => {
    const {
      transactionItem: { key },
    } = getState().chartSideTransaction;

    const data = {
      key,
      treatmentPlan: event.target.value,
    };

    dispatch({
      api: TransactionApi.item.setTreatmentPlan,
      types: [
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_START,
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_SUCCESS,
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_PLAN_ERROR,
      ],
      data,
    })
      .then(() => dispatch(transactionActions.getAppointmentTransactions(true)))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function setTreatmentSession(event) {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: {
        transactionItem: { key },
      },
    } = getState();

    const data = {
      key,
      treatmentSession: event.target.value,
    };

    dispatch({
      api: TransactionApi.item.setTreatmentSession,
      types: [
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_START,
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_SUCCESS,
        ActionType.CHART_SIDE_TRANSACTION_SET_TREATMENT_SESSION_ERROR,
      ],
      data,
    })
      .then(() => dispatch(transactionActions.getAppointmentTransactions(true, true)))
      .catch((error) => dispatch(showMessage(error)));
  };
}

export function deleteTransaction() {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: { transactionItem },
      session: { permissions },
    } = getState();
    if (
      (transactionItem?.type?.code === "DIAGNOSIS" &&
        transactionItem.status.code === "DentalTransactionStatus_PLANNED" &&
        permissions.indexOf("REMOVE_DIAGNOSIS_PLANNED_DENTAL_TRANSACTION_ITEM") === -1) ||
      (transactionItem?.type?.code === "TREATMENT" &&
        transactionItem.status.code === "DentalTransactionStatus_PLANNED" &&
        permissions.indexOf("REMOVE_TREATMENT_PLANNED_DENTAL_TRANSACTION_ITEM") === -1)
    ) {
      return dispatch(showMessage("Permission denied"));
    }
    if (
      (transactionItem.status.code === "DentalTransactionStatus_PLANNED" ||
        transactionItem.status.code === "DentalTransactionStatus_COMPLETED") &&
      transactionItem?.type?.code === "TREATMENT"
    ) {
      return dispatch(openChooseDeleteModal());
    }

    return dispatch(openConfirmDeleteModal());
  };
}

export function changeCompletedDate(momentDate, transactionKey) {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: {
        transactionItem: { key },
      },
    } = getState();

    dispatch(startLoader());

    const data = {
      key: transactionKey || key,
      completedDate: +momentDate + Utils.getTimezoneDifference(),
    };

    return dispatch({
      api: TransactionApi.item.changeDate,
      types: [
        ActionType.CHART_SIDE_TRANSACTION_CHANGE_DATE_START,
        ActionType.CHART_SIDE_TRANSACTION_CHANGE_DATE_SUCCESS,
        ActionType.CHART_SIDE_TRANSACTION_CHANGE_DATE_ERROR,
      ],
      data,
    })
      .then(() => dispatch(transactionActions.getAppointmentTransactions(true, true)))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function deleteItem() {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: { transactionItem },
      chart: {
        patient: { patientKey },
      },
      chartSideMedicalFillInForm: { stack },
    } = getState();

    const { key } = transactionItem;
    const isProvisionalDiagnosis =
      _.last(stack)?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

    const data = {
      key,
    };

    dispatch(startLoader());

    dispatch({
      api: TransactionApi.deleteItem,
      types: [
        ActionType.CHART_SIDE_TRANSACTION_DELETE_ITEM_START,
        ActionType.CHART_SIDE_TRANSACTION_DELETE_ITEM_SUCCESS,
        ActionType.CHART_SIDE_TRANSACTION_DELETE_ITEM_ERROR,
      ],
      data,
    })
      .then(() => {
        if (isProvisionalDiagnosis) {
          dispatch(transactionActions.getAppointmentProvisionalDiagnosis(true, patientKey));
        } else {
          dispatch(transactionActions.getAppointmentTransactions(true, true));
          dispatch(chartSideMedicalFillInFormActions.getMedicalFillInFormDiagnosisList());
        }
      })
      .then(() => dispatch(chartActions.hideSideBar()))
      .then(() => dispatch(chartActions.getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function voidItem() {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: { transactionItem },
      chart: {
        patient: { patientKey },
      },
    } = getState();
    const { key } = transactionItem;

    const data = {
      key,
    };

    dispatch(startLoader());

    dispatch({
      api: TransactionApi.voidItem,
      types: [
        ActionType.CHART_SIDE_TRANSACTION_VOID_ITEM_START,
        ActionType.CHART_SIDE_TRANSACTION_VOID_ITEM_SUCCESS,
        ActionType.CHART_SIDE_TRANSACTION_VOID_ITEM_ERROR,
      ],
      data,
    })
      .then(() => dispatch(transactionActions.getAppointmentTransactions(true, true)))
      .then(() => dispatch(chartActions.hideSideBar()))
      .then(() => dispatch(chartActions.getTeeth({ patientKey })))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function openChooseDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_OPEN_CHOOSE_DELETE_MODAL,
  };
}

export function closeChooseDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_CLOSE_CHOOSE_DELETE_MODAL,
  };
}

export function openAccessDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_OPEN_ACCESS_DELETE_MODAL,
  };
}

export function closeAccessDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_CLOSE_ACCESS_DELETE_MODAL,
  };
}

function openConfirmDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_OPEN_CONFIRM_DELETE_MODAL,
  };
}

export function closeConfirmDeleteModal() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_CLOSE_CONFIRM_DELETE_MODAL,
  };
}

export function changeInitial(value) {
  return (dispatch, getState) => {
    const { chartSideTransaction } = getState();

    let transactionItem = chartSideTransaction.transactionItem;

    if (value > +transactionItem.price)
      return dispatch(showMessage("Initial price should be less then Fee"));

    transactionItem.parts[0].price = (+value).toFixed(2);
    transactionItem = calculatePrice(transactionItem, null);

    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM,
      transactionItem,
    });
  };
}

export function changePrice(value, index) {
  return (dispatch, getState) => {
    const { chartSideTransaction } = getState();

    let transactionItem = chartSideTransaction.transactionItem;

    if (value > +transactionItem.price - transactionItem.parts[0].price) {
      return dispatch(showMessage("Price should be less then Fee"));
    }

    transactionItem.parts[index].price = (+value).toFixed(2);
    transactionItem = calculatePrice(transactionItem, index);

    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM,
      transactionItem,
    });
  };
}

export function setSidebarTransaction(transactionItem) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM,
      transactionItem,
    });
  };
}

export function popParts() {
  return (dispatch, getState) => {
    const { chartSideTransaction } = getState();

    let transactionItem = chartSideTransaction.transactionItem;

    if (transactionItem.parts.length == 2) return;

    transactionItem.parts.pop();
    transactionItem = calculatePrice(transactionItem, null);

    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM,
      transactionItem,
    });
  };
}

export function pushParts() {
  return (dispatch, getState) => {
    const { chartSideTransaction } = getState();

    let transactionItem = chartSideTransaction.transactionItem;

    transactionItem.parts.push({
      number: transactionItem.parts.length + 1,
      price: "0,00",
      comment: "",
    });

    transactionItem = calculatePrice(transactionItem, null);

    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_CHANGE_TRANSACTION_ITEM,
      transactionItem,
    });
  };
}

function calculatePrice(transactionItem, priceIndex) {
  const { price, parts } = transactionItem;
  const initial = +parts[0].price;

  const others = priceIndex
    ? ((+price - initial - +parts[priceIndex].price) / (parts.length - 2)).toFixed(2)
    : ((+price - initial) / (parts.length - 1)).toFixed(2);

  transactionItem.parts = parts.map((item, index) => {
    if (index === 0) {
      return item;
    }

    if (priceIndex !== index) {
      item.price = others;
    }

    if (index === parts.length - 1) {
      item.price = priceIndex
        ? (+price - initial - +parts[priceIndex].price - +others * (parts.length - 3)).toFixed(2)
        : (+price - initial - +others * (parts.length - 2)).toFixed(2);
    }

    return item;
  });

  return transactionItem;
}

export function saveBreakToVisit() {
  return (dispatch, getState) => {
    const {
      chartSideTransaction: { transactionItem },
      chart: { appointment },
    } = getState();

    const transfer = {
      key: transactionItem.key,
      currentAppointmentId: appointment.id,
      parts: transactionItem.parts,
    };

    dispatch(startLoader());

    dispatch(BreakToVisitAPI(transfer, !transactionItem.parts[0].status))
      .then(() => dispatch(transactionActions.getAppointmentTransactions(true, true)))
      .then(() => dispatch(chartSideSeriesActions.popSeriesStack()))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function initApproveList() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionType.CHART_SIDE_TRANSACTION_INIT_APPROVE,
    });
    const {
      chart: {
        patient: { patientKey },
      },
    } = getState();

    const data = {
      patientKey,
      treatmentPlanned: true,
      holdPreApproval: true,
      limit: 25,
    };

    dispatch(getApproveList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadApproveList() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSideTransaction: { approveList, approveListTotalCount, fetchingApprove },
    } = getState();

    if (fetchingApprove) return;

    if (approveListTotalCount > approveList.length) {
      const data = {
        patientKey,
        treatmentPlanned: true,
        holdPreApproval: true,
        limit: 25,
        start: approveList.length,
      };
      dispatch(getApproveList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function toggleApprove(key) {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_TOGGLE_APPROVE,
    key,
  };
}

export function toggleAllApprove() {
  return {
    type: ActionType.CHART_SIDE_TRANSACTION_TOGGLE_ALL_APPROVE,
  };
}

export function submitApprove() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
      },
      chartSideTransaction: { selectedApprove },
    } = getState();

    const dentalTransactions = _(selectedApprove)
      .pickBy((boolean) => boolean)
      .keys()
      .value();

    const data = {
      patientKey,
      dentalTransactions,
    };

    dispatch(startLoader());

    dispatch(holdPreApproval(data))
      .then((response) => dispatch(showLaterNow(response)))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function showLaterNow(response) {
  return (dispatch, getState) => {
    const {
      chart: { appointment },
    } = getState();

    response.status = { code: "Later" };
    response.appointmentId = appointment.id;
    delete response.treatments;

    const laterBtn = new Element("Later", 1);
    const nowBtn = new Element("Now", 2);
    const handler = (e, element) => {
      switch (element.id) {
        case 1:
          dispatch(transactionActions.sendToPreApprovalFn(response));
          break;
        case 2:
          // openPriorApprovalDiagnosisPopup();
          break;
      }
      dispatch(setPopupActionByKey(PopupType.Small, "visible", false));
    };
    laterBtn.handler = handler;
    nowBtn.handler = handler;
    dispatch(
      setPopupParams(
        PopupType.Small,
        "Info",
        true,
        "Send this treatment to prior-approval?",
        [
          laterBtn,
          // nowBtn
        ],
        true,
      ),
    );
  };
}

export function updateNote(key, note) {
  return (dispatch) => {
    const data = {
      key,
      note,
    };

    dispatch(updateNoteApi(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function updateNoteApi(data) {
  return {
    api: TransactionApi.item.updateNote,
    types: [
      "CHART_SIDE_TRANSACTION_UPDATE_NOTE_START",
      "CHART_SIDE_TRANSACTION_UPDATE_NOTE_SUCCESS",
      "CHART_SIDE_TRANSACTION_UPDATE_NOTE_ERROR",
    ],
    data,
  };
}

function holdPreApproval(data) {
  return {
    api: TransactionApi.holdPreApproval.getTransaction,
    types: [
      ActionType.CHART_SIDE_TRANSACTION_SEND_APPROVE_START,
      ActionType.CHART_SIDE_TRANSACTION_SEND_APPROVE_SUCCESS,
      ActionType.CHART_SIDE_TRANSACTION_SEND_APPROVE_ERROR,
    ],
    data,
  };
}

function getApproveList(data) {
  return {
    api: TransactionApi.getList.forDentist,
    types: [
      ActionType.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_START,
      ActionType.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_SUCCESS,
      ActionType.CHART_SIDE_TRANSACTION_GET_APPROVE_LIST_ERROR,
    ],
    data,
  };
}

function BreakToVisitAPI(data) {
  return {
    api: TransactionApi.createBreakToVisit,
    types: [
      ActionType.CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_START,
      ActionType.CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_SUCCESS,
      ActionType.CHART_SIDE_TRANSACTION_SAVE_BREAK_TO_VISIT_ERROR,
    ],
    data,
  };
}

export function updateTransactionDiscountApi(data) {
  return {
    api: TransactionApi.updateDiscount,
    types: [
      ActionType.CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_START,
      ActionType.CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_SUCCESS,
      ActionType.CHART_SIDE_TRANSACTION_UPDATE_DISCOUNT_ERROR,
    ],
    data,
  };
}
