import React from "react";
import { Form, Formik } from "formik";
import { TextField } from "../form/TextField";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { PhoneNumberField } from "../form/PhoneNumberField";

export function PatientFamilyMembersAddForm({ initialValues, onSubmit, setFormMode }) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form className="patient-family-members-add-form">
        <SidebarHeader
          className="sidebar-header chart-side-header"
          title="Search Patient"
          onBackClick={() => setFormMode("list")}
          rightComponent={
            <div className="d-flex">
              <button className="btn btn-link text-white" type="submit">
                SEARCH
              </button>
            </div>
          }
        />
        <div className="chart-side-body p-2" style={{ height: "100%" }}>
          <div className="d-flex flex-column mb-2 mt-1">
            <TextField name="firstName" label="Patient First name:" />
          </div>
          <div className="d-flex flex-column mb-2 mt-1">
            <TextField name="lastName" label="Patient Last name:" />
          </div>
          <div className="d-flex flex-column mb-2">
            <PhoneNumberField name="phoneNumber" label="Phone Number" search={true} />
          </div>
          <div className="d-flex flex-column mb-2">
            <TextField name="patientChartNumber" label="Patient Chart Number:" />
          </div>

          <div className="d-flex flex-column mb-2">
            <TextField name="email" label="Email:" />
          </div>
        </div>
      </Form>
    </Formik>
  );
}
