import React from "react";

import Back from "../widgets/Back";

import { ViewTypes } from "../../constants/Constants";

export default class AppointmentCalendarColumn extends React.Component {
  setView = (type) => {
    localStorage.setItem("columnView", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment calendar view</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView(ViewTypes.compact)} className="list-group-item">
              {Boolean(localStorage.getItem("columnView") === ViewTypes.compact) && (
                <div className="pull-right">&#10004;</div>
              )}
              Compact View (6 columns per page)
            </span>
            <span onClick={() => this.setView(ViewTypes.comfort)} className="list-group-item">
              {Boolean(localStorage.getItem("columnView") === ViewTypes.comfort) && (
                <div className="pull-right">&#10004;</div>
              )}
              Comfortable View (3 columns per page)
            </span>
          </div>
        </div>
      </div>
    );
  }
}
