import React from "react";

import Back from "../widgets/Back";

import { TimeViews } from "../../constants/Constants";

export default class TimeView extends React.Component {
  setView = (type) => {
    localStorage.setItem("timeView", type);

    this.forceUpdate();
  };

  render() {
    return (
      <div className="settings-body">
        <div className="title">
          <Back />
          <div className="name">Appointment time view</div>
        </div>
        <div className="body">
          <div className="list-group">
            <span onClick={() => this.setView(TimeViews.name)} className="list-group-item">
              {Boolean(localStorage.getItem("timeView") === TimeViews.name) && (
                <div className="pull-right">&#10004;</div>
              )}
              Show Name
            </span>
            <span onClick={() => this.setView(TimeViews.time)} className="list-group-item">
              {Boolean(localStorage.getItem("timeView") === TimeViews.time) && (
                <div className="pull-right">&#10004;</div>
              )}
              Show Time
            </span>
          </div>
        </div>
      </div>
    );
  }
}
