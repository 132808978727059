import _ from "lodash";
import React from "react";
import cx from "classnames";
import "moment/locale/en-gb";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ButtonGroup } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";
import ReactSpinner from "react-spinjs";

import Show from "../../../widgets/Show";
import { DatePicker } from "../../../ui/DatePicker";
import FilledImage from "../../../widgets/FilledImage";
import NoteDeleteConfirmModal from "./NoteDeleteConfirmModal";
import ChartClinicalNoteOwnedItem from "./ChartClinicalNoteOwnedItem";
import RefreshImage from "../../../../assets/images/calendar-bottom-menu/refresh@2x.png";
import clinicalNoteSortFields from "../../../../constants/chart-sidebar/clinicalNoteSortFields";

import clinicNoteItemTypes from "../../../../constants/chart-sidebar/clinicNoteItemTypes";

import {
  auditNoteItem,
  copyNoteItem,
  getOwnedList,
  loadOwnedList,
  noteDeleteConfirmModalShow,
  onChangeNoteTab,
  openCategory,
  openItem,
  openPrint,
  openTextarea,
  popStack,
  resetClinicalNoteFor,
  selectClinicField,
  selectSortField,
  setAddendumId,
  setEndDateFilter,
  setStartDateFilter,
} from "../../../../actions/chartSideClinicalNoteActions";
import { closeSimpleModal, openSimpleModal } from "../../../../actions/simpleModalActions";
import { SelectPicker } from "../../../select-picker/SelectPicker";
import { DoctorSelectWrapper } from "../../../doctor/DoctorSelectWrapper";
import { SidebarHeader } from "../../../sidebar/SidebarHeader";
// import { ClinicSelectField } from "../../../form/ClinicSelectField";
// import { useClinicListQuery } from "../../../../queries/clinic/useClinicListQuery";

const enhancer = connect(
  ({
    auth: { members, clinics },
    calendar: { doctorsPoints },
    session: { permissions, member },
    chartSideClinicalNote: { endDateFilter, startDateFilter, clinicalNoteActiveTab },
    chart: { appointment },
  }) => ({
    clinics,
    member,
    members,
    permissions,
    appointment,
    doctorsPoints,
    endDateFilter,
    selectSortField,
    startDateFilter,
    clinicalNoteActiveTab,
  }),
  {
    openItem,
    popStack,
    openPrint,
    openCategory,
    copyNoteItem,
    getOwnedList,
    openTextarea,
    loadOwnedList,
    setAddendumId,
    auditNoteItem,
    openSimpleModal,
    closeSimpleModal,
    onChangeNoteTab,
    selectSortField,
    setEndDateFilter,
    setStartDateFilter,
    resetClinicalNoteFor,
    selectClinicField,
    noteDeleteConfirmModalShow,
  },
);

export default enhancer(
  class ChartClinicalNoteOwned extends React.Component {
    static propTypes = {
      openItem: PropTypes.func,
      popStack: PropTypes.func,
      openPrint: PropTypes.func,
      openCategory: PropTypes.func,
      copyNoteItem: PropTypes.func,
      getOwnedList: PropTypes.func,
      openTextarea: PropTypes.func,
      loadOwnedList: PropTypes.func,
      appointment: PropTypes.object,
      setAddendumId: PropTypes.func,
      selectSortField: PropTypes.func,
      selectClinicField: PropTypes.func,
      openSimpleModal: PropTypes.func,
      onChangeNoteTab: PropTypes.func,
      setEndDateFilter: PropTypes.func,
      setStartDateFilter: PropTypes.func,
      chartSideClinicalNote: PropTypes.object,
      clinicalNoteActiveTab: PropTypes.object,
      noteDeleteConfirmModalShow: PropTypes.func,
      members: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      permissions: PropTypes.arrayOf(PropTypes.string),
    };

    constructor(props) {
      super(props);

      this.state = {
        doctorId: 0,
        showModal: false,
      };
    }

    componentDidMount() {
      this.props.getOwnedList();
    }

    openItem = (item) => {
      const { fetching } = this.props.chartSideClinicalNote;
      if (fetching) return;
      this.props.openItem(item, clinicNoteItemTypes.view);
    };

    loadList = (e) => {
      const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

      if (scrollBottom) {
        this.props.loadOwnedList();
      }
    };

    select = ({ value }) => {
      this.props.selectSortField(value);
    };
    selectClinic = ({ value }) => {
      this.props.selectClinicField(value);
    };

    toggleFavourite = (id) => {
      const favouritesClinicalNote = JSON.parse(
        /* eslint-disable-next-line */
        localStorage.getItem("favouritesClinicalNote"),
      );
      favouritesClinicalNote[id] = !favouritesClinicalNote[id];
      /* eslint-disable-next-line */
      localStorage.setItem("favouritesClinicalNote", JSON.stringify(favouritesClinicalNote));

      this.forceUpdate();
    };

    isDeleteAvailable = (permissions) => {
      const deleteAllPermission = permissions.indexOf("REMOVE_CLINIC_NOTE_ITEM_ALL") >= 0;
      const deletePermission = permissions.indexOf("REMOVE_CLINIC_NOTE_ITEM") >= 0;
      return !(deleteAllPermission || deletePermission);
    };

    deleteItem = (item, bool) => {
      const { permissions } = this.props;

      if (this.isDeleteAvailable(permissions)) {
        return this.props.openSimpleModal({
          body: "permission denied",
        });
      }
      this.props.noteDeleteConfirmModalShow(item.id, bool);
    };

    copyNote = (itemId, apptId = null) => {
      this.props.openSimpleModal({
        body: "This action will create a copy of the note, are you sure ?",
        footer: (
          <div className="d-flex justify-content-end">
            <button className="btn btn-default" onClick={this.props.closeSimpleModal}>
              cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.copyNoteItem(itemId, apptId);
                this.props.closeSimpleModal();
              }}
            >
              copy
            </button>
          </div>
        ),
      });
    };

    openTextarea = () => {
      this.props.resetClinicalNoteFor();
      this.props.openTextarea(clinicNoteItemTypes.new);
    };

    openAddendum = (id) => {
      this.props.setAddendumId(id);
      this.openTextarea();
    };

    setActiveTab = (activeTab) => this.props.onChangeNoteTab(activeTab);

    render() {
      const {
        members,
        popStack,
        openPrint,
        permissions,
        appointment,
        openCategory,
        getOwnedList,
        auditNoteItem,
        setEndDateFilter,
        setStartDateFilter,
        clinicalNoteActiveTab,
        chartSideClinicalNote,
        clinics,
      } = this.props;
      const {
        fetching,
        ownedList,
        sortField,
        onCallList,
        onAppointment,
        endDateFilter,
        startDateFilter,
        noteDeleteConfirmModal,
        restore,
      } = chartSideClinicalNote;
      const { doctorId, showModal } = this.state;

      const favouritesClinicalNote = JSON.parse(
        /* eslint-disable-next-line */
        localStorage.getItem("favouritesClinicalNote"),
      );

      const doctors = [];
      const defaultList = [];
      const favouriteList = [];

      _.forEach(members, (x) => {
        doctors.push({
          label: [x.firstName, x.lastName].join(" "),
          value: x.id,
        });
      });

      const currentDoctor = _.find(members, (x) => x.id === doctorId);

      ownedList.forEach((item) => {
        if (favouritesClinicalNote[item.id]) {
          favouriteList.push(item);
        } else {
          defaultList.push(item);
        }
      });

      const list = favouriteList.concat(defaultList);

      const $list = [];

      _.forEach(list, (item, index) => {
        const id = _.get(item, "owner.id", 0);
        const currentId = _.get(currentDoctor, "id", 0);

        if (currentId === 0 || currentId === id) {
          $list.push(
            <ChartClinicalNoteOwnedItem
              key={index}
              item={item}
              deleteItem={this.deleteItem}
              openItem={() => this.openItem(item)}
              copyItem={() => this.copyNote(item.id, appointment.id || null)}
              auditItem={() => auditNoteItem(item.id)}
              favouritesClinicalNote={favouritesClinicalNote}
              clinicalNoteActiveTab={clinicalNoteActiveTab}
              onAddendumClick={() => this.openAddendum(item.id)}
              toggleFavourite={() => this.toggleFavourite(item.id)}
              withAddendum={
                permissions.indexOf("ADD_CLINIC_NOTE_ITEM_ALL") >= 0 ||
                permissions.indexOf("ADD_CLINIC_NOTE_ITEM") >= 0
              }
              onReferenceClick={
                item.referenceClinicNoteId > 0
                  ? () => {
                      this.openItem({ id: item.referenceClinicNoteId });
                    }
                  : undefined
              }
            />,
          );
        }
      });

      const enableDateFilter = startDateFilter && endDateFilter && startDateFilter <= endDateFilter;

      return (
        <>
          <div className="chart-clinical-note">
            <div className="sidebar-header chart-side-header" style={{ paddingTop: 0 }}>
              <SidebarHeader onBackClick={popStack} title={clinicalNoteActiveTab.title} />
              <div style={{ marginTop: "15px", padding: "0 5px" }}>
                <ButtonGroup aria-label="note tabs" className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() =>
                      this.setActiveTab({
                        code: "yourNotes",
                        title: "My Notes",
                      })
                    }
                    className={`btn ${
                      clinicalNoteActiveTab.code !== "yourNotes" ? "btn-primary" : "btn-default"
                    } btn-block m-0`}
                  >
                    My Notes
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      this.setActiveTab({
                        code: "clinicalNotes",
                        title: "All notes",
                      })
                    }
                    className={`btn ${
                      clinicalNoteActiveTab.code !== "clinicalNotes" ? "btn-primary" : "btn-default"
                    } btn-block m-0`}
                  >
                    All notes
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      this.setActiveTab({
                        code: "voidedNotes",
                        title: "Voided Notes",
                      })
                    }
                    className={`btn ${
                      clinicalNoteActiveTab.code !== "voidedNotes" ? "btn-primary" : "btn-default"
                    } btn-block m-0`}
                  >
                    Voided Notes
                  </button>
                </ButtonGroup>
              </div>
            </div>

            <div className="chart-side-body">
              <div className="chart-clinical-note-doctor-select d-flex">
                <div className="picker-container">
                  <SelectPicker
                    options={clinicalNoteSortFields.map((x) => ({
                      label: x.name,
                      value: x.code,
                    }))}
                    value={{ value: sortField }}
                    onChange={this.select}
                  />
                </div>
                {clinicalNoteActiveTab.code !== "yourNotes" && (
                  <div className="picker-container">
                    <DoctorSelectWrapper
                      allLabel="All doctors"
                      value={{
                        label: currentDoctor
                          ? [currentDoctor.firstName, currentDoctor.lastName].join(" ")
                          : "All doctors",
                        value: currentDoctor ? doctorId : 0,
                      }}
                      onChange={(x) => this.setState({ doctorId: x.value })}
                    />
                  </div>
                )}
                <div className="picker-container ml-1">
                  <SelectPicker
                    options={clinics.map((x) => ({ label: x.name, value: x.id }))}
                    onChange={this.selectClinic}
                  />
                </div>

                {/*<div className="empty-container" />*/}
              </div>

              <div className="chart-clinical-note-doctor-select d-flex date-container">
                <div className="d-flex flex-column from-date">
                  <DatePicker
                    selected={startDateFilter}
                    className="form-control"
                    onChange={setStartDateFilter}
                    placeholder="Filter From Date"
                  />
                </div>

                <div className="d-flex flex-column to-date">
                  <DatePicker
                    selected={endDateFilter}
                    placeholder="To Date"
                    className="form-control"
                    onChange={setEndDateFilter}
                  />
                </div>

                <div
                  tabIndex={-1}
                  role="button"
                  onClick={() => getOwnedList()}
                  title={enableDateFilter ? "Refresh" : undefined}
                  className={cx("refresh-button", {
                    disabled: !enableDateFilter,
                  })}
                >
                  <FilledImage
                    zoom={80}
                    className="no-response-zoom"
                    src={RefreshImage}
                    color={enableDateFilter ? "#BEC844" : "#9e9f9c"}
                  />
                </div>
              </div>

              <div className="body">
                <div className="chart-clinical-note-wrapper" onScroll={this.loadList}>
                  {$list}
                  <Show if={fetching}>
                    <ReactSpinner />
                  </Show>
                </div>
              </div>

              <div className="all-codes">
                <div className="row">
                  <Show if={!onAppointment && !onCallList}>
                    <div className="col-sm-4">
                      <button
                        type="button"
                        onClick={() =>
                          permissions.indexOf("ADD_CLINIC_NOTE_ITEM_WITHOUT_TEMPLATE") >= 0 ||
                          permissions.indexOf("ADD_CLINIC_NOTE_ITEM_ALL") >= 0
                            ? this.openTextarea()
                            : this.setState({ showModal: true })
                        }
                        className="btn btn-primary btn-block"
                      >
                        Write New
                      </button>
                    </div>
                  </Show>
                  <Show if={!onAppointment && !onCallList}>
                    <div className="col-sm-4">
                      <button
                        type="button"
                        onClick={() =>
                          permissions.indexOf("ADD_CLINIC_NOTE_ITEM") >= 0 ||
                          permissions.indexOf("ADD_CLINIC_NOTE_ITEM_ALL") >= 0
                            ? openCategory()
                            : this.setState({ showModal: true })
                        }
                        className="btn btn-primary btn-block"
                      >
                        Templates
                      </button>
                    </div>
                  </Show>

                  <Show if={!onCallList}>
                    <div className="col-sm-4" style={onAppointment ? { width: "100%" } : {}}>
                      <button
                        type="button"
                        onClick={openPrint}
                        className="btn btn-primary btn-block"
                      >
                        View All Notes
                      </button>
                    </div>
                  </Show>
                </div>
              </div>
            </div>

            <Modal
              size="sm"
              show={showModal}
              onHide={() => this.setState({ showModal: false })}
              actions={
                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Close
                  </button>
                </div>
              }
              animation={false}
              keyboard={false}
            >
              <div>Permission denied</div>
            </Modal>
          </div>
          <NoteDeleteConfirmModal deleteId={noteDeleteConfirmModal} restore={restore} />
        </>
      );
    }
  },
);
