export const PATIENT_LIST_GET_LIST_START = "PATIENT_LIST_GET_LIST_START";
export const PATIENT_LIST_GET_LIST_SUCCESS = "PATIENT_LIST_GET_LIST_SUCCESS";
export const PATIENT_LIST_GET_LIST_ERROR = "PATIENT_LIST_GET_LIST_ERROR";

export const PATIENT_LIST_SHOW_SIDEBAR = "PATIENT_LIST_SHOW_SIDEBAR";
export const PATIENT_LIST_HIDE_SIDEBAR = "PATIENT_LIST_HIDE_SIDEBAR";

export const PATIENT_LIST_CHANGE_PHONE_CODE = "PATIENT_LIST_CHANGE_PHONE_CODE";
export const SORT_LIST_BY_CHART_NUMBER = "SORT_LIST_BY_CHART_NUMBER";
export const SORT_LIST_BY_NAME = "SORT_LIST_BY_NAME";
