import React from "react";
import { IS_DEV } from "../../constants/Constants";

export class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log("++++++++++", error);
    // eslint-disable-next-line no-console
    console.log("++++++++++", errorInfo);
    if (IS_DEV) throw new Error(error);
    else {
      window.location.href = "/";
    }
  }

  render() {
    return this.props.children;
  }
}
