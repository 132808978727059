import _ from "lodash";
import React from "react";

import { SidebarTypes } from "../../chart-treatments-sidebar/TypeButtonGroup";
import { connect, useDispatch } from "react-redux";
import FavoriteImage from "../../../assets/images/chart/favorite.png";
import FavoriteActiveImage from "../../../assets/images/chart/favorite_active.png";
import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { createTransactions } from "../../../actions/chartSideSeriesActions";
import * as SeriesUtils from "../../../helpers/chartSideSeriesUtils";
import { medicalFillInFormStackCodes } from "../../../constants/chart-sidebar/medicalFIllInFormStackCodes";
import { TransactionStatusSelectItem } from "../../../constants/TransactionTypes";

const enhancer = connect(
  ({
    session: { treatmentDescriptionType, member },
    chart: { patient, activeTooth, teeth, appointment },
    chartSideMedicalFillInForm: { stack },
  }) => ({
    treatmentDescriptionType,
    member,
    patient,
    activeTooth,
    teeth,
    appointment,
    stack,
  }),
);

export default enhancer(
  ({
    item,
    openItem,
    favourites,
    seriesType,
    isSelectItem,
    isMultiSelect,
    toggleFavorite,
    treatmentDescriptionType,
    member,
    patient,
    activeTooth,
    teeth,
    appointment,
    stack,
  }) => {
    const endSeriesType = seriesType === SidebarTypes.Mixed ? item.$seriesType : seriesType;
    const endFavorites = _.get(favourites, `${endSeriesType}`, {});
    const endTypeFavorites = endFavorites[item.code];
    const favourite = _.get(endTypeFavorites, "selected", false);
    const isProvisionalDiagnosis =
      _.last(stack)?.code === medicalFillInFormStackCodes.provisionalDiagnosis;

    const itemDescription =
      _.get(treatmentDescriptionType, "code") === "DESCRIPTION"
        ? item.description
        : _.get(item, "description2", item.description);

    const dispatch = useDispatch();
    const addToPlannedDiagnosis = (item) => {
      let tooth = {};
      let toothPosition = {};
      if (activeTooth !== 0) {
        tooth = SeriesUtils.getToothByActiveTooth(activeTooth, teeth);
        if (tooth) {
          const tempArr = tooth.code.split("_");
          if (tempArr && tempArr.length) {
            const positionCode = tempArr[tempArr.length - 1];
            toothPosition = {
              id: positionCode.slice(1),
              code: positionCode,
            };
          }
        }
      }
      const appointmentId = appointment?.id;
      const diagnosis = {
        ...item,
        closedAppointmentId: appointmentId,
        createdAppointmentId: appointmentId,
        currentAppointmentId: appointmentId,
        areaDetails: [],
        assignedDoctor: member,
        createdDentist: member,
        diagnosisItem: item.id,
        diagnosisItems: null,
        diagnosisYearOfOnSet: null,
        patientKey: patient.patientKey,
        sendBillInsurance: true,
        sendBillInsurance2: true,
        status: isProvisionalDiagnosis
          ? TransactionStatusSelectItem.PROVISIONAL.getShortRequest()
          : TransactionStatusSelectItem.PLANNED.getShortRequest(),
        tooth: activeTooth !== 0 ? tooth : null,
        toothPosition: activeTooth !== 0 ? toothPosition : null,
        type: { id: 1, code: "DIAGNOSIS" },
        unitBox: 1,
      };
      dispatch(startLoader());
      dispatch(createTransactions({ dentistToCashierNote: "", list: [diagnosis] }))
        .then(() => dispatch(stopLoader()))
        .catch((error) => dispatch(stopLoader(error)));
    };
    const isDiagnosis = item.area;
    return (
      <div
        title={item.description}
        className="list-group-item list-group-item-code"
        style={
          isMultiSelect && isSelectItem ? { backgroundColor: "cornflowerblue", color: "white" } : {}
        }
      >
        {isDiagnosis && (
          <div
            onClick={() => addToPlannedDiagnosis(item)}
            style={{
              fontSize: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "30px",
              borderRight: "1px solid #ddd",
            }}
          >
            +
          </div>
        )}
        <div className="code" onClick={() => openItem(item)}>
          {item.code}
        </div>
        <div className="separator">
          <div
            className="description"
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => openItem(item)}
          >
            {itemDescription.slice(0, 230)}
          </div>
        </div>
        <div className="favourite" onClick={() => toggleFavorite(item.code, item.$seriesType)}>
          <img alt="" src={favourite ? FavoriteActiveImage : FavoriteImage} />
        </div>
      </div>
    );
  },
);
