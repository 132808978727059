import React, { useEffect } from "react";
import { connect } from "react-redux";

import Show from "../../../widgets/Show";

import ChartClinicalNoteCreatedDate from "./ChartClinicalNoteCreatedDate";
import ReduxCKEditor from "../../../widgets/redux/ReduxCKEditor";
import { Field, useFormik, FormikProvider } from "formik";
import moment from "moment";
import { FULL_DATE_FORMAT } from "../../../../constants/Constants";
import { STATUS_TYPES } from "../../../../constants/StatusTypes";
import RichTextEditor from "./rich-text-editor/Quill";

const enhancer = connect(
  ({ chartSideClinicalNote, session: { permissions }, chart: { appointment } }) => {
    return {
      initialValues: {
        name: chartSideClinicalNote.item.name,
        note: chartSideClinicalNote.item.note,
        referenceClinicNoteId: chartSideClinicalNote.item.referenceClinicNoteId,
      },
      permissions,
      appointment,
    };
  },
);

const ChartClinicalNoteTextareaForm = (props) => {
  const { onSubmit, initialValues } = props;
  const { edit, item, appointment } = props;

  const form = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (values.note) {
        values.note = values?.note
          .split("\n")
          .map((line) => `<p>${line}</p>`)
          .join("\n");
      }
      onSubmit(values);
    },
  });

  useEffect(
    () => () => {
      form.resetForm();
    },
    [],
  );

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit} className="clinical-note-textarea">
        <div className="d-flex flex-column justify-content-end align-items-end mb-1">
          <div className="d-flex justify-content-start align-items-start">
            <span className="mr-2">{item.patient && item.patient.chartNumber}</span>
            <span className="mr-2">{item.patient && item.patient.fullName}</span>
            {item?.appointment?.date && (
              <span className="mr-2">
                Appt date: {moment(item?.appointment.date).format(FULL_DATE_FORMAT)}
              </span>
            )}
            <span className="mr-2">
              Being Seen date:{" "}
              {item?.appointment?.actualStartTime
                ? moment(item?.appointment.actualStartTime).format(FULL_DATE_FORMAT)
                : ""}
            </span>
            <span className="mr-2">
              Completed date:{" "}
              {item?.appointment?.modifiedDate
                ? moment(item?.appointment.modifiedDate).format(FULL_DATE_FORMAT)
                : ""}
            </span>
          </div>
        </div>
        <div className="clinical-note-textarea-name">
          <input
            name="name"
            type="text"
            className="form-control input-lg"
            placeholder="Note Name"
            {...form.getFieldProps("name")}
          />
        </div>
        <div className="clinical-note-textarea-body">
          <Field
            name="note"
            id="clinical-note-textarea-body"
            className="form-control"
            {...form.getFieldProps("note")}
          >
            {() => (
              <RichTextEditor value={form.values.note} change={form.setFieldValue} name="note" />
            )}
          </Field>
        </div>
        <div className="clinical-note-textarea-footer">
          <Show if={edit}>
            {/*could be updated with permission*/}
            <ChartClinicalNoteCreatedDate changeCreatedDate={props.changeCreatedDate} item={item} />
          </Show>
          <div className="btn-group w-100">
            <button type="submit" className="btn btn-primary btn-lg" style={{ width: "50%" }}>
              Save
            </button>
            <button
              type="button"
              onClick={() => onSubmit({ ...form.values, sendToHie: true })}
              className="btn btn-danger btn-lg"
              style={{ width: "50%" }}
            >
              Save [Send to HIE]
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default enhancer(ChartClinicalNoteTextareaForm);
