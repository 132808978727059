import { useQuery, UseQueryResult } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";

export function useBlockedTimeSlotTypeColor(): UseQueryResult<BtsTypeProps[], Error> {
  return useQuery(["blocked-time-slot", "types"], () =>
    httpClient.get(ApiRoutes.BlockedTimeSlotTypeColor).then(({ data }) => data.data),
  );
}
