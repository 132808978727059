import React from "react";
import { connect } from "react-redux";

import Utils from "../../helpers/Utils";
import bindActions from "../../helpers/bindActions";
import { showMessage } from "../../actions/loaderActions";
import * as appointmentActions from "../../actions/appointmentActions";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import { Modal } from "../ui/Modal";
import { withRouter } from "react-router";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { STATUS_TYPES } from "../../constants/StatusTypes";

const enhancer = connect(
  ({ session, appointment }) => ({ permissions: session.permissions || [], appointment }),
  bindActions({
    appointmentActions,
    showMessage,
    openSimpleModal,
    closeSimpleModal,
  }),
);
class AppointmentDeleteModal extends React.Component {
  setAppointmentStatus = (status) => {
    const patient = this.props.item && this.props.item.patient;
    const id = this.props.item && this.props.item.id;

    const transfer = {
      id: id,
      status: status,
      callTime: Utils.calcDateWithTimeZone().valueOf(),
    };

    if (patient.patientKey) {
      transfer.patientKey = patient.patientKey;
    }

    return this.props.appointmentActions
      .statusChangeOfAppointment(transfer)
      .then(() => {
        this.props.appointmentActions.deleteAppointmentModalHide();
        this.props.history.push(
          createUrl(Routes.DashboardCalendar, {
            query: {
              saveSettings: true,
            },
          }),
        );
      })
      .catch((error) => this.props.showMessage(error));
  };

  showAccessDenied = () => {
    this.props.openSimpleModal({
      body: "Access denied",
      footer: (
        <button className="btn btn-primary" onClick={() => this.props.closeSimpleModal()}>
          Ok
        </button>
      ),
    });
  };

  render() {
    const { show, permissions, appointment } = this.props;
    const appointmentOptions = [
      ...appointment?.deleteAppointmentOptions,
      ...appointment?.missedAppointmentOptions,
    ];

    const sortedAppointments = appointmentOptions.sort((a, b) => {
      const categoryOrder = {
        Client: 1,
        Center: 2,
        Clinician: 3,
        Voided: 4,
        Released: 5,
        Missed: 6,
      };

      const getCategory = (name) => {
        if (name.startsWith("Client")) return "Client";
        if (name.startsWith("Center")) return "Center";
        if (name.startsWith("Clinician")) return "Clinician";
        if (name === "Voided") return "Voided";
        if (name === "Released (Non-Payment)") return "Released";
        if (name.startsWith("Missed")) return "Missed";
        return "Other";
      };

      const categoryA = getCategory(a.name);
      const categoryB = getCategory(b.name);

      if (categoryOrder[categoryA] < categoryOrder[categoryB]) return -1;
      if (categoryOrder[categoryA] > categoryOrder[categoryB]) return 1;

      return 0;
    });

    return (
      <Modal
        title="Choose action:"
        show={show}
        className="delete-appointment-modal"
        onHide={() => this.props.appointmentActions.deleteAppointmentModalHide()}
      >
        {(sortedAppointments || []).map((item, idx) => (
          <button
            key={idx}
            type="button"
            onClick={() => {
              if (permissions.indexOf("CANCEL_APPOINTMENT") >= 0) {
                if (item?.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED) {
                  this.props.setAppointmentPlannedTreatmentModalVisible(true);
                } else {
                  this.props.appointmentActions.openReasonModal(item?.code);
                }
                this.props.appointmentActions.deleteAppointmentModalHide();
              } else {
                this.showAccessDenied();
              }
            }}
            className="btn btn-default"
          >
            {item?.name}
          </button>
        ))}
        <button
          type="button"
          onClick={() => {
            this.props.appointmentActions.deleteAppointmentModalHide();
          }}
          className="btn btn-default"
        >
          Cancel
        </button>
        {/*<button
          type="button"
          onClick={() => {
            if (permissions.indexOf("CANCEL_APPOINTMENT") >= 0) {
              this.props.appointmentActions.openReasonModal("APPOINTMENT_STATUS_CANCELLED");
              this.props.appointmentActions.deleteAppointmentModalHide();
            } else {
              this.showAccessDenied();
            }
          }}
          className="btn btn-default"
        >
          Cancelled-Client appointment
        </button>
        <button
          type="button"
          onClick={() => {
            if (permissions.indexOf("CANCEL_APPOINTMENT") >= 0) {
              this.props.appointmentActions.openReasonModal("APPOINTMENT_STATUS_CANCELLED_CENTRE");
              this.props.appointmentActions.deleteAppointmentModalHide();
            } else {
              this.showAccessDenied();
            }
          }}
          className="btn btn-default"
        >
          Cancelled-Center appointment
        </button>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => {
            if (permissions.indexOf("CANCEL_APPOINTMENT") >= 0) {
              this.props.appointmentActions.openReasonModal("APPOINTMENT_STATUS_MISSED");
              this.props.appointmentActions.deleteAppointmentModalHide();
            } else {
              this.showAccessDenied();
            }
          }}
        >
          Miss appointment
        </button>
        <button
          type="button"
          onClick={() => {
            this.props.appointmentActions.deleteAppointmentModalHide();
          }}
          className="btn btn-default"
        >
          Cancel
        </button>*/}
      </Modal>
    );
  }
}

export default withRouter(enhancer(AppointmentDeleteModal));
