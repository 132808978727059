import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import InsuranceForm from "../insurance/InsuranceForm";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    width: "100vw",
    height: "calc(100vh - 20px)",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, .5)",
    zIndex: 100,
  },
  formContent: {
    position: "absolute",
    height: "100%",
    right: 0,
    top: 0,
    zIndex: 101,
  },
}));

const PatientInsuranceFormSidebar = ({ insuranceFormVisible, onShowInsuranceForm, patient }) => {
  const classes = useStyles();
  if (!insuranceFormVisible) return null;
  return (
    <div className={classes.container}>
      <div className={cx("patient-insurance-form-sidebar", classes.formContent)}>
        <InsuranceForm
          patient={patient}
          isSidebarMode={true}
          onShowInsuranceForm={onShowInsuranceForm}
        />
      </div>
    </div>
  );
};

export default PatientInsuranceFormSidebar;
