import _ from "lodash";
import React, { useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import FlexBox from "../../ui/FlexBox";
import { PerioExamHeader } from "./PerioExamHeader";
import { PerioExamTeethRow } from "./PerioExamTeethRow";

import {
  getPatientDetails,
  getPerioExamItem,
  getPerioExamList,
  perioExamItemHideModal,
} from "../../../actions/patientActions";
import { parseQuery } from "../../../utils/UrlUtils";
import RightSideModal from "./RightSideModal";
import Utils from "../../../helpers/Utils";
import { getTeeth } from "../../../actions/chartActions";
import { downloadElementAsJpeg } from "../../../utils/ImageUtils";

const enhancer = connect(
  ({
    patient: { perioExamItem, patientDetails, fetchingPerioExamItem },
    chart: { teeth },
    session: { toothNumericType },
  }) => ({
    perioExamItem,
    patientDetails,
    fetchingPerioExamItem,
    teeth,
    toothNumericType,
  }),
  { getPerioExamItem, getPatientDetails, getPerioExamList },
);

const PerioExamView = ({
  perioExamItem,
  patientDetails,
  fetchingPerioExamItem,
  location,
  teeth,
  toothNumericType,
}) => {
  const {
    patient: { perioExamItemHandle, perioExamItemModal },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const numericKey = toothNumericType?.code === "Index1" ? "numeric1" : "numeric2";

  const up = [];
  const down = [];

  Utils.objectForEach(teeth, (tooth, toothPositionCode) => {
    const index = toothPositionCode.slice(1);

    if (index <= 16) {
      up[index - 1] = tooth;
    } else {
      down[32 - index] = tooth;
    }
  });

  useEffect(() => {
    const query = parseQuery(location.search);

    const patientKey = _.get(query, "patientKey");
    dispatch(getPatientDetails({ patientKey }));
    dispatch(getPerioExamList({ patientKey }));
    dispatch(getTeeth({ patientKey: query.patientKey }));
    query.date &&
      dispatch(
        getPerioExamItem({
          patientKey,
          historyDate: query.date,
        }),
      );
  }, []);
  const downloadAsImage = useCallback(
    () => downloadElementAsJpeg("perio-exam", { quality: 0.7 }),
    [],
  );

  return (
    <>
      <div className="perio-exam bg-white">
        <FlexBox className="chart-page">
          <PerioExamHeader patientDetails={patientDetails} downloadAsImage={downloadAsImage} />
        </FlexBox>
        {!fetchingPerioExamItem && (
          <div style={{ overflowX: "scroll" }}>
            <PerioExamTeethRow
              numericKey={numericKey}
              row="top"
              side="Front_Side"
              teethList={up}
              perioExamItem={perioExamItem}
            />

            <PerioExamTeethRow
              numericKey={numericKey}
              row="top"
              side="Back_Side"
              teethList={up}
              perioExamItem={perioExamItem}
            />

            <PerioExamTeethRow
              numericKey={numericKey}
              row="lower"
              side="Front_Side"
              teethList={down}
              perioExamItem={perioExamItem}
            />

            <PerioExamTeethRow
              numericKey={numericKey}
              row="lower"
              side="Back_Side"
              teethList={down}
              perioExamItem={perioExamItem}
            />
          </div>
        )}
      </div>
      <RightSideModal
        numericKey={numericKey}
        visible={perioExamItemModal}
        onHide={() => dispatch(perioExamItemHideModal())}
        teeth={perioExamItemHandle}
      />
    </>
  );
};

export default enhancer(PerioExamView);
