import React from "react";
import { connect } from "react-redux";
import { changeAppLanguage } from "../../actions/appActions";
import { SelectPicker } from "../select-picker/SelectPicker";

const languageList = [
  { label: "English", value: "english" },
  { label: "Arabic", value: "arabic" },
  { label: "Deutch", value: "german" },
  { label: "Русский", value: "russian" },
  { label: "Français", value: "french" },
  { label: "Slovak", value: "slovak" },
];

const enhancer = connect(({ app }) => ({
  language: app.language,
}));
class LanguageSelectBox extends React.Component {
  render() {
    const { language, dispatch } = this.props;

    return (
      <SelectPicker
        className="pull-left"
        style={{
          width: "150px",
        }}
        value={{ value: language }}
        options={languageList}
        onChange={(value) => dispatch(changeAppLanguage(value.value))}
      />
    );
  }
}
export default enhancer(LanguageSelectBox);
