import React, { CSSProperties } from "react";

import PlaceholderImage from "../../assets/images/default_avatar.png";
import cx from "classnames";

interface Props {
  readonly size?: number;
  readonly photoUrl?: string;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly onClick?: () => void;
}

export default function MemberImage({ size = 107, style, photoUrl, className }: Props) {
  return (
    <div
      className={cx("member-image", className)}
      style={{
        overflow: "hidden",
        ...style,
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${photoUrl || PlaceholderImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
}
