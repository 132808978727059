import React from "react";
import { toFinite } from "lodash";
import PropsTypes from "prop-types";
import { connect } from "react-redux";

import { startLoader, stopLoader } from "../../../actions/loaderActions";
import { updatePriceApi, updateTransactionList } from "../../../actions/transactionActions";

const enhancer = connect();

export const CustomPriceCellWrapper = enhancer(
  class CustomPriceCellWrapper extends React.PureComponent {
    static propTypes = {
      disabled: PropsTypes.bool,
      dispatch: PropsTypes.func,
      onChange: PropsTypes.func,
      isCustomPrice: PropsTypes.bool,
      transactionKey: PropsTypes.string,
      treatmentPriceRowId: PropsTypes.number,
    };

    constructor(props) {
      super(props);

      this.state = {
        value: "",
        active: false,
      };
    }

    componentDidUpdate(prevProps) {
      const { active } = this.state;

      const { isCustomPrice: prevIsCustomPrice } = prevProps;
      const { isCustomPrice: nextIsCustomPrice } = this.props;

      if (prevIsCustomPrice !== nextIsCustomPrice && !nextIsCustomPrice && active) {
        this.setState({ active: false });
      }
    }

    updateTransaction = (data) => {
      const { dispatch } = this.props;

      dispatch(updateTransactionList(data));
    };

    save = () => {
      const { value } = this.state;
      const { dispatch, transactionKey, treatmentPriceRowId } = this.props;

      dispatch(startLoader());

      const data = {
        needCashClaim: true,
        key: transactionKey,
        price: toFinite(value),
        sendBillInsurance: false,
        sendBillInsurance2: false,
        treatmentPriceRowId,
      };

      dispatch(updatePriceApi(data))
        .then(() => {
          this.updateTransaction(data);

          dispatch(stopLoader());
        })
        .catch((e) => dispatch(stopLoader(e)));
    };

    render() {
      const { active, value } = this.state;
      const { isCustomPrice, onChange, disabled } = this.props;

      return (
        <div role="button" className="d-flex" onClick={(e) => e.stopPropagation()}>
          <div>
            <input
              type="checkbox"
              checked={active}
              disabled={disabled}
              onChange={() => {
                if (!active) {
                  onChange(true);
                }

                this.setState((x) => ({ active: !x.active }));
              }}
            />
          </div>

          <input
            value={value}
            type="number"
            className="form-control"
            onBlur={() => this.save()}
            disabled={!active || !isCustomPrice || disabled}
            style={{ margin: "-8px 0", width: "60px", marginLeft: "8px" }}
            onChange={({ target }) => this.setState({ value: target.value })}
            onKeyUp={(x) => {
              if (x.key === "Enter") {
                this.save();
              }
            }}
          />
          <b style={{ marginLeft: "5px" }}>&#10004;</b>
        </div>
      );
    }
  },
);
