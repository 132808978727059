import React from "react";

import { Modal } from "../ui/Modal";

interface Props {
  readonly show: boolean;
  readonly onClose: () => void;
  readonly onDeleteClick: () => void;
}

export function BlockedTimeSlotDeleteConfirmModal({ show, onClose, onDeleteClick }: Props) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      animation={false}
      size="sm"
      keyboard={false}
      className="blocked-confirm-modal"
      actions={
        <div>
          <button className="btn" type="button" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-danger" type="button" onClick={onDeleteClick}>
            Delete
          </button>
        </div>
      }
    >
      <div>Are you sure you want to delete ?</div>
    </Modal>
  );
}
