import React from "react";
import Show from "../../../widgets/Show";
import _ from "lodash";
import DocsModal from "./DocsModal";
import DocsAddFolder from "./DocsAddFolder";
import { DocsViewModal } from "./DocsViewModal";
import { useShallowEqualSelector } from "../../../../hooks/useShallowEqualSelector";
import { appointmentSelector } from "../../../../reducers/appointmentReducer";
import { folderSelector } from "../../../../reducers/folderReducer";
import { patientViewSelector } from "../../../../reducers/patientReducer";
import {
  changeMovingMode,
  closeFolder,
  createFolder,
  deleteItems,
  getFolderList,
  hideCreateDialog,
  loadFilesList,
  moveItems,
  openFolder,
  openImage,
  resetFolder,
  selectItem,
  showCreateDialog,
  uploadFiles,
} from "../../../../actions/folderActions";
import { useDispatch } from "react-redux";
import { SidebarPosition } from "../../../sidebar/Sidebar";

interface Props {
  readonly showDocsModal: boolean;
  readonly position?: SidebarPosition;
  readonly onHide: () => void;
  readonly onShow: () => void;
}

export function DocsWrapper({ showDocsModal, onHide, onShow, position }: Props) {
  const dispatch = useDispatch();

  const appointment = useShallowEqualSelector(appointmentSelector as any);
  const patientView = useShallowEqualSelector(patientViewSelector as any);
  const folder = useShallowEqualSelector(folderSelector as any);
  const _patientKey =
    _.get(appointment, "appointment.patient.patientKey") ||
    _.get(patientView, "patientDetails.patientKey");
  const imageItem: any = {};

  if (folder.openedImage) {
    const currentFolder = (_.last(folder.folderStack) || 0) as any;

    imageItem.url = _.get(
      _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
      "url",
    );
    imageItem.description = _.get(
      _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
      "name",
    );
    imageItem.createdDate = _.get(
      _.first(_.filter(folder.filesList[currentFolder], (x) => x.id === folder.openedImage)),
      "createdDate",
    );
  }

  return (
    <>
      <Show if={showDocsModal && _patientKey}>
        <DocsModal
          position={position}
          open={showDocsModal}
          openFolder={(folderId) =>
            dispatch(
              openFolder({
                folderId,
                patientKey: _patientKey,
              }),
            )
          }
          closeFolder={() =>
            dispatch(
              closeFolder({
                patientKey: _patientKey,
              }),
            )
          }
          onUploadFiles={(files) =>
            dispatch(
              uploadFiles({
                patientKey: _patientKey,
                files,
                folderId: _.last(folder.folderStack) || null,
              }),
            )
          }
          onBack={() => {
            onHide();
            dispatch(resetFolder());
          }}
          selectItem={(itemType, itemId) => dispatch(selectItem(itemType, itemId))}
          fetchingFolders={folder.fetchingFolders}
          folder={folder}
          onSelectMoveFolder={(targetFolderId, isClosed) =>
            dispatch(
              moveItems(
                {
                  patientKey: _patientKey,
                  targetFolderId,
                },
                isClosed,
              ),
            )
          }
          onRequestClose={() => {
            onHide();
            dispatch(resetFolder());
          }}
          openFile={(id) => {
            onShow();
            dispatch(openImage(id));
          }}
          onMove={() => dispatch(changeMovingMode(true))}
          onDelete={() => dispatch(deleteItems(_patientKey))}
          getFolderList={() => dispatch(getFolderList())}
          loadFilesList={() => dispatch(loadFilesList())}
          onCreateFolderClick={() => dispatch(showCreateDialog())}
          patientKey={_patientKey}
        />
      </Show>

      <Show if={showDocsModal && folder.createFolderDialogVisible && _patientKey}>
        <DocsAddFolder
          onSubmit={(values) =>
            dispatch(
              createFolder({
                name: values.folderName,
                parentId: _.last(folder.folderStack) || null,
                patientKey: _patientKey,
              }),
            )
          }
          open={folder.createFolderDialogVisible}
          onRequestClose={() => dispatch(hideCreateDialog())}
        />
      </Show>

      <Show if={!!folder.openedImage}>
        <DocsViewModal
          show={true}
          url={imageItem.url}
          list={_.values(folder.filesList)[0]}
          imageId={folder.openedImage}
          description={imageItem.description}
          createdDate={imageItem.createdDate}
          onRequestClose={() => {
            dispatch(openImage(0));
            onShow();
          }}
        />
      </Show>
    </>
  );
}
