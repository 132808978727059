import cx from "classnames";
import { noop } from "lodash";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import { Theme } from "../../dto/AppDTO";
import { Modal, ModalProps } from "../ui/Modal";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    top: 0,
    bottom: 0,
    margin: 0,
    width: "650px",
    display: "flex",
    position: "absolute",
  },
  content: {
    flex: 1,
    display: "flex",
    borderRadius: 0,
  },
  rightDialog: {
    right: "-650px",
    transition: "right 170ms ease-in-out",

    "&.open": {
      right: 0,
    },
  },
  leftDialog: {
    left: "-650px",
    transition: "left 170ms ease-in-out",

    "&.open": {
      left: 0,
    },
  },
  body: { padding: "0 !important", margin: 0, flex: 1, display: "flex", flexDirection: "column" },
}));

export enum SidebarPosition {
  Left = "left",
  Right = "right",
}

interface Props extends ModalProps {
  readonly position?: SidebarPosition;
}

export function Sidebar({
  onHide = noop,
  position = SidebarPosition.Right,
  children,
  ...props
}: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => setOpen(false);
  }, []);

  return (
    <Modal
      {...props}
      backdrop={props.backdrop || true}
      animation={false}
      contentClassName={classes.content}
      onShow={() => setOpen(true)}
      onHide={() => {
        onHide();

        setOpen(false);
      }}
      bodyProps={{ className: classes.body }}
      dialogClassName={cx(props.dialogClassName, classes.dialog, {
        [classes.leftDialog]: position === SidebarPosition.Left,
        [classes.rightDialog]: position === SidebarPosition.Right,
        open,
      })}
    >
      {children}
    </Modal>
  );
}
