const PostOpSortFields = [
  {
    code: "owner_name",
    name: `by ${localStorage.getItem("doctorLabel")} A-Z`,
  },
  {
    code: "owner_name_desc",
    name: `by ${localStorage.getItem("doctorLabel")} Z-A`,
  },
  {
    code: "name",
    name: "by Name A-Z",
  },
  {
    code: "name_desc",
    name: "by Name Z-A",
  },
  {
    code: "create_date",
    name: "by Date A-Z",
  },
  {
    code: "create_date_desc",
    name: "by Date Z-A",
  },
];

export default PostOpSortFields;
