import React from "react";
import { Form, Formik, FormikHelpers } from "formik";

import { Button } from "../ui/Button";
import { TextField } from "../form/TextField";

interface Props {
  readonly initialValues: any;
  readonly submitButtonTitle?: string;
  readonly onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
}

export function PatientViewModalAddForm({
  onSubmit,
  initialValues,
  submitButtonTitle = "Add",
}: Props) {
  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="d-flex flex-column p-1">
          <TextField name="name" placeholder="Title" fieldProps={{ className: "pb-1" }} />
          <TextField name="note" as="textarea" fieldProps={{ className: "pb-1" }} />
          <Button type="submit">{submitButtonTitle}</Button>
        </Form>
      )}
    </Formik>
  );
}
