import React from "react";
import InputField from "../widgets/InputField";
import PageHeader from "../layout/DefaultPageHeader";
import LogoImage from "../../assets/images/clinic_logo.png";
import Show from "../widgets/Show";

export default function InsuranceHeader(props) {
  const { elements, extraClass, isSidebarMode } = props;

  return (
    <PageHeader addClass={`insurance-header ${extraClass}_header`} onClick={props.onBackClick}>
      <Show if={!isSidebarMode}>
        <a href="/" className="logo">
          <img alt="" src={LogoImage} />
        </a>
      </Show>
      <InputField className="btn-edit" type="button" element={elements.editBtn} />
    </PageHeader>
  );
}
