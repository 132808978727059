import React from "react";
import cx from "classnames";
import { isFunction } from "lodash";
import { makeStyles } from "@material-ui/styles";

import { Theme } from "../../dto/AppDTO";
import { CheckIcon } from "../icons/CheckIcon";
import { hexToRgba } from "../../utils/FormatUtils";
import { OptionProps } from "react-select";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "38px",
    color: "black",
    display: "flex",
    padding: "0 8px",
    whiteSpace: "nowrap",

    "&.all-first-option": {
      borderBottom: "1px solid #cccccc",
    },

    "&:not(.select-picker--disabled):hover": {
      cursor: "pointer",
      backgroundColor: hexToRgba(theme.palette.primary.main, { opacity: 0.1 }),
    },

    "& > .select-picker__label-container": {
      flex: 1,
      display: "flex",
      color: theme.palette.common.black,
      marginRight: "8px",
      alignItems: "center",

      "&.select-picker__label-container--disabled": {
        color: "#cccccc !important",
      },
    },

    "& > .select-picker__check-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

interface Props extends Omit<OptionProps<any, any>, "children"> {
  readonly formatOptionLabel?: (value: any) => string;
}

function SelectPickerOption(props: Props) {
  const classes = useStyles();

  return (
    <div
      role="button"
      className={cx(classes.root, {
        "select-picker--disabled": props.isDisabled,
      })}
      onClick={() => {
        if (!props.isDisabled) {
          props.selectOption(props.data);
        }
      }}
    >
      <div
        className={cx("select-picker__label-container", {
          "select-picker__label-container--disabled": props.isDisabled,
        })}
      >
        {isFunction(props.formatOptionLabel)
          ? props.formatOptionLabel(props.data)
          : props.data.label}
      </div>

      {Boolean(props.isMulti && props.isSelected) && (
        <div className="select-picker__check-container">
          <CheckIcon size={18} color="#bec844" />
        </div>
      )}
    </div>
  );
}

SelectPickerOption.displayName = "SelectPickerOption";

export { SelectPickerOption };
