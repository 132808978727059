import { httpClient } from "./BaseApi";

const GOAL_LIST_URL = "api/goal/list.json";
const GOAL_ITEM_URL = "api/goal/item.json";
const GOAL_ITEMS_URL = "api/goal/items.json";

const GoalApi = {
  saveItems: (data) => httpClient.put(GOAL_ITEMS_URL, { data }),
  getList: (params) => httpClient.get(GOAL_LIST_URL, { params }),
  deleteItem: (params) => httpClient.delete(GOAL_ITEM_URL, { params }),
  deleteAll: (params) => httpClient.delete(GOAL_ITEMS_URL, { params }),
};

export default GoalApi;
