import React from "react";
import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";

import * as repeatAppointmentActions from "../../../actions/repeatAppointmentActions";
import * as followUpActions from "../../../actions/followUpActions";
import moment from "moment";
import { createUrl } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { SelectPicker } from "../../select-picker/SelectPicker";
import followUp from "../../follow-up/FollowUp";

const enhancer = connect(({ repeatAppointment, followUp, session: { member } }) => ({
  repeatAppointment,
  followUp,
  member,
}));
const form = reduxForm({
  form: "repeatAppointmentTrxPlanning",
  enableReinitialize: true,
});

class RepeatAppointmentTrxPlanningForm extends React.Component {
  componentDidMount() {
    this.props.dispatch(repeatAppointmentActions.getDoctors());
    this.props.dispatch(repeatAppointmentActions.getTypes());
    const data = {
      followUpId: this.props.followUp.item.id,
      start: 0,
      limit: 50,
    };
    if (this.props.fromList) {
      this.props.dispatch(repeatAppointmentActions.getNoteList(data));
    }
  }

  render() {
    const {
      handleSubmit,
      repeatAppointment: { doctors, types, followNote, notes = [] },
      member,
      initialValues,
    } = this.props;

    const doctorOptions = doctors.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const typesOptions = types.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return (
      <form
        className="d-flex flex-column justify-content-between"
        onSubmit={handleSubmit(() =>
          this.props.dispatch(
            this.props.fromList
              ? followUpActions.saveTrxPlanning()
              : repeatAppointmentActions.submitTrxPlanning(),
          ),
        )}
      >
        <div className="form-group">
          <label htmlFor="doctor">Doctor:</label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={doctorOptions}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="doctor"
            id="doctor"
          />
        </div>

        <div className="form-group">
          <label htmlFor="type">Follow Up Type:</label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={typesOptions}
                value={{ value: input.value }}
                onChange={(x) => input.onChange(x.value)}
              />
            )}
            name="type"
            id="type"
          />
        </div>
        {!this.props.fromList && (
          <div className="form-group">
            <label htmlFor="note">Note:</label>
            <Field component="textarea" name="note" id="note" className="form-control" />
          </div>
        )}
        {this.props.fromList && (
          <div className="follow-up-note-wrap">
            <div className="follow-up-note-list">
              <p className="follow-up-note">
                {initialValues.note}
                <div className="follow-up-note-info">
                  <span>{initialValues.creator}</span>
                  <span>{moment(initialValues.createdDate).format("DD.MM.YYYY HH:mm:ss")}</span>
                </div>
              </p>
              {(notes || []).map((item) => (
                <div
                  key={item.id}
                  className={`follow-up-note ${member.id === item.creator.id ? "mine" : ""}`}
                >
                  <span className="corner" />
                  {item.note}
                  <div className="follow-up-note-info">
                    <span>{item.creator.name}</span>
                    <span>{moment(item.createdDate).format("DD.MM.YYYY HH:mm:ss")}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="follow-up-note-msg">
              <textarea
                name="note"
                rows="5"
                value={followNote}
                onChange={({ target }) =>
                  this.props.dispatch(repeatAppointmentActions.setFollowNote(target.value))
                }
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.props.dispatch(repeatAppointmentActions.addNote())}
              >
                Send
              </button>
            </div>
          </div>
        )}

        <div className={this.props.fromList ? "follow-up-side-bar-form-buttons" : ""}>
          <button type="submit" className="btn btn-primary btn-block">
            {this.props.fromList ? "Save" : "Add"}
          </button>
          {this.props.fromList && (
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                const query = this.props.dispatch(followUpActions.openAppointment());
                this.props.history.push(
                  createUrl(Routes.DashboardAppointment, {
                    query,
                  }),
                );
              }}
            >
              Schedule
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => {
              this.props.dispatch(
                followUpActions.review({ followUpId: this.props.followUp?.item.id }),
              );
            }}
          >
            Review
          </button>
        </div>
        <div
          className={this.props.fromList ? "follow-up-side-bar-form-buttons" : ""}
          style={{ margin: "10px 0px" }}
        >
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => {
              this.props.dispatch(
                followUpActions.reopen({ followUpId: this.props.followUp?.item.id }),
              );
            }}
          >
            Reopen
          </button>
          <button
            className="btn  btn-block"
            style={{ background: "#108BD0", color: "#fff", border: "none" }}
            onClick={() => {
              this.props.dispatch(followUpActions.complete(this.props.followUp.item));
            }}
          >
            Complete
          </button>
        </div>
      </form>
    );
  }
}

export default enhancer(form(RepeatAppointmentTrxPlanningForm));
