import React, { useEffect, useState } from "react";
import { Modal } from "../../../ui/Modal";
import { connect, useDispatch } from "react-redux";
import {
  getQuestionList,
  setQuestionsModalVisible,
} from "../../../../actions/chartSideClinicalNoteActions";
import ReactSpinner from "react-spinjs-fix";

let timeOut;

const enhancer = connect(({ chartSideClinicalNote: { questionList, questionListFetching } }) => ({
  questionList,
  questionListFetching,
}));
const ChartClinicalQuestionsModal = ({ questionList, onChange, value, questionListFetching }) => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  useEffect(() => {
    dispatch(getQuestionList({ load: false, question }));
  }, []);

  const trimText = (text) => {
    if (text.length && text.length > 140) return `${text.slice(0, 140)}...`;
    return text;
  };

  const loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;
    if (scrollBottom) {
      const data = {
        load: true,
        question: question,
      };
      dispatch(getQuestionList(data));
    }
  };

  const handleChange = (value) => {
    clearTimeout(timeOut);
    setQuestion(value);
    if (!value) {
      return dispatch(getQuestionList({ load: false, question: value, start: 0 }));
    }
    timeOut = setTimeout(() => {
      const data = {
        load: false,
        question: value,
        start: 0,
      };
      dispatch(getQuestionList(data));
    }, 500);
  };

  return (
    <Modal
      title={
        <div className="d-flex align-items-center justify-content-center">
          <div>List of default answers</div>
        </div>
      }
      actions={
        <button className="btn btn-default" onClick={() => dispatch(setQuestionsModalVisible())}>
          close
        </button>
      }
      show={true}
      size="lg"
    >
      <>
        <div className="pr-4 pl-4">
          <form>
            <div>
              <label>
                Question name:
                <input
                  className="form-control"
                  value={question}
                  onChange={({ target }) => handleChange(target.value)}
                />
              </label>
            </div>
          </form>
        </div>
        <div className="p-4">
          <div className="row mb-3" style={{ borderBottom: "1px solid #e5e5e5" }}>
            <div className="col-sm-3">Question name</div>
            <div className="col-sm-7">Default answer text</div>
            <div className="col-sm-2">Action</div>
          </div>
          <div
            style={{
              height: "60vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            onScroll={loadList}
          >
            {questionListFetching && <ReactSpinner />}
            {!questionList.length && <h4>No results</h4>}
            {(questionList || []).map((question) => {
              return (
                <div
                  key={question.id}
                  className="row mb-4 pb-4"
                  style={{ borderBottom: "1px solid #e5e5e5" }}
                >
                  <div className="col-sm-3">{question.question}</div>
                  <div
                    title={question.defaultAnswer}
                    style={{ cursor: "help" }}
                    className="col-sm-7"
                  >
                    {trimText(question.defaultAnswer)}
                  </div>
                  <div className="col-sm-2">
                    <button
                      onClick={() => {
                        dispatch(setQuestionsModalVisible());
                        onChange(`${value || ""} ${question.defaultAnswer}`);
                      }}
                      className="btn btn-primary"
                    >
                      Select
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default enhancer(ChartClinicalQuestionsModal);
