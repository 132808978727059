import React from "react";
import Show from "../widgets/Show";

import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";

const enhancer = connect(null, bindActions({ patientRegisterActions }));
class PatientFormSignature extends React.Component {
  render() {
    const { editMode, signature } = this.props;

    return (
      <div
        className="patient-details-edit-signature"
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          } else if (editMode) {
            this.props.patientRegisterActions.openSignature();
          }
        }}
      >
        <Show if={editMode && signature}>
          <img src={signature} alt="" />
        </Show>
        <Show if={!signature}>
          <span>Tap to sign</span>
        </Show>
        <Show if={!editMode && signature}>
          <span className="signed-text">SIGNED</span>
        </Show>
      </div>
    );
  }
}
export default enhancer(PatientFormSignature);
