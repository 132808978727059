import React from "react";
import { string } from "prop-types";
import ReactSpinner from "react-spinjs-fix";

export class DownloadImageButton extends React.PureComponent {
  propTypes = {
    fill: string,
    image: string,
    imageName: string,
    className: string,
    loaderColor: string,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));

  download = (url) => {
    this.setState({ loading: true });
    this.toDataURL(url)
      .then((image) => {
        const a = document.createElement("a");

        a.href = image;
        a.download = this.props.imageName || "myImage.png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    const { image, fill = "#000000", className, loaderColor = "#000000" } = this.props;

    return (
      <div
        role="button"
        title="Download"
        className={className}
        onClick={() => this.download(image)}
        style={{
          width: "20px",
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <ReactSpinner color={loaderColor} config={{ scale: "0.5" }} />}

        {!loading && (
          <svg
            width={18}
            fill={fill}
            height={18}
            viewBox="0 -32 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m416 368h-51.101562c-8.835938 0-16-7.15625-16-16s7.164062-16 16-16h51.101562c35.289062 0 64-28.703125 64-64s-28.710938-64-64-64h-1.609375c-4.796875 0-9.34375-2.15625-12.382813-5.875-3.039062-3.722656-4.25-8.59375-3.296874-13.296875.851562-4.234375 1.292968-8.546875 1.292968-12.828125 0-35.296875-28.710937-64-64-64-10.242187 0-20.390625 2.671875-30.164062 7.96875-4.210938 2.28125-9.21875 2.5625-13.640625.734375-4.429688-1.796875-7.820313-5.484375-9.242188-10.0625-14.660156-47.03125-57.636719-78.640625-106.957031-78.640625-61.757812 0-112 50.25-112 112 0 17.09375 3.882812 33.65625 11.53125 49.21875 1.875 3.828125 2.15625 8.234375.773438 12.25-1.375 4.03125-4.300782 7.328125-8.121094 9.203125-22.316406 10.859375-36.183594 32.84375-36.183594 57.328125 0 35.296875 28.710938 64 64 64h59.789062c8.835938 0 16 7.15625 16 16s-7.164062 16-16 16h-59.789062c-52.9375 0-96-43.0625-96-96 0-31.6875 15.359375-60.53125 40.921875-78.328125-5.929687-15.953125-8.921875-32.59375-8.921875-49.671875 0-79.40625 64.601562-144 144-144 57.453125 0 108.210938 33.359375 131.25 84.625 9.382812-3.078125 19-4.625 28.75-4.625 52.9375 0 96 43.0625 96 96 0 .4375 0 .890625-.007812 1.328125 45.351562 7.640625 80.007812 47.1875 80.007812 94.671875 0 52.9375-43.0625 96-96 96zm0 0" />
            <path d="m256 448c-8.835938 0-16-7.15625-16-16v-192c0-8.84375 7.164062-16 16-16s16 7.15625 16 16v192c0 8.84375-7.164062 16-16 16zm0 0" />
            <path d="m256 448c-4.09375 0-8.1875-1.5625-11.3125-4.6875l-48-48c-6.25-6.25-6.25-16.375 0-22.625 6.25-6.246094 16.375-6.25 22.625 0l36.6875 36.6875 36.6875-36.6875c6.25-6.25 16.375-6.25 22.625 0 6.246094 6.25 6.25 16.375 0 22.625l-48 48c-3.125 3.125-7.21875 4.6875-11.3125 4.6875zm0 0" />
          </svg>
        )}
      </div>
    );
  }
}
