import React from "react";
import moment from "moment";
import { FormikHelpers } from "formik";

import DataList from "../widgets/DataList";
import { Sidebar, SidebarPosition } from "../sidebar/Sidebar";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { PatientViewModalAddForm } from "../patient/PatientViewModalAddForm";

export interface AppointmentPatientViewModalProps {
  readonly notes: any[];
  readonly show: boolean;
  readonly position?: SidebarPosition;
  readonly onClose: () => void;
  readonly onAdd: (values: any, helpers: FormikHelpers<any>) => void;
}

export function AppointmentPatientViewModal({
  show,
  onClose,
  onAdd,
  notes,
  position,
}: AppointmentPatientViewModalProps) {
  return (
    <Sidebar position={position} show={show} onHide={onClose} dialogClassName="chart-side-bar">
      <div className="chart-clinical-note patient-view-note-modal">
        <SidebarHeader onBackClick={onClose} title="Reception Notes" />

        <div className="chart-side-body">
          <PatientViewModalAddForm
            onSubmit={onAdd}
            submitButtonTitle="Add"
            initialValues={{ name: "", note: "" }}
          />
          <DataList>
            {notes.map((item, index) => (
              <div key={index} className="note-item patient-note-item">
                <strong className="note-item-title">{item.name}</strong>
                <div style={{ overflowX: "hidden" }} className="note-item-body">
                  {item.body || item.note || " "}
                </div>
                <div className="note-item-footer">
                  <div className="date">{moment(item.createdDate).format("DD/MM/YYYY")}</div>
                  <div className="owner">{item.owner && item.owner.name}</div>
                </div>
              </div>
            ))}
          </DataList>
        </div>
      </div>
    </Sidebar>
  );
}
