import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { FormCheck } from "react-bootstrap";
import ReactSpinner from "react-spinjs-fix";

import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as transactionActions from "../../../actions/transactionActions";

const sessionTrxValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const unitValues = [
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
];
const intervalDurationUnitValues = ["day", "week", "month", "year"];
const intervalDurationAmountValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const enhancer = connect(null, bindActions({ transactionActions }));

class ChartTransactionPlanModalSelected extends React.Component {
  componentWillMount() {
    this.props.transactionActions.initPlanNew();

    this.setState({
      note: {},
    });
  }

  togglePlan = (key) => {
    return () => {
      this.props.transactionActions.togglePlanNew(key);
    };
  };

  handleChangeTreatmentPlan = (value, key, patientKey) => {
    this.props.transactionActions.changeTreatmentPlan({
      key,
      patientKey,
      treatmentPlan: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  handleChangeTreatmentSession = (value, key, patientKey) => {
    this.props.transactionActions.changeTreatmentSession({
      key,
      patientKey,
      currentDentistId: null,
      treatmentSession: value,
      currentAppointmentId: null,
    });
  };

  handleChangeTreatmentNote = (value, key, patientKey) => {
    this.props.transactionActions.changeTreatmentNote({
      key,
      patientKey,
      note: value,
    });
  };

  handleChangeUnit = (value, key, patientKey) => {
    this.props.transactionActions.changeUnit({
      key,
      patientKey,
      unit: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  handleChangeIntervalUnit = (value, key, patientKey) => {
    this.props.transactionActions.changeIntervalUnit({
      key,
      patientKey,
      intervalUnit: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  handleChangeIntervalAmount = (value, key, patientKey) => {
    this.props.transactionActions.changeIntervalAmount({
      key,
      patientKey,
      intervalAmount: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  handleChangeDurationUnit = (value, key, patientKey) => {
    this.props.transactionActions.changeDurationUnit({
      key,
      patientKey,
      durationUnit: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  handleChangeDurationAmount = (value, key, patientKey) => {
    this.props.transactionActions.changeDurationAmount({
      key,
      patientKey,
      durationAmount: value,
      currentDentistId: null,
      currentAppointmentId: null,
    });
  };

  render() {
    const { doctors, changeAssignedDoctor } = this.props;
    const { planNewList, planNewSelected, fetchingPlan } = this.props.transaction;

    const customTheadStyles = {
      lineHeight: "initial",
    };

    const selectedList = _.filter(planNewSelected, (x) => x);

    const $list = planNewList.map((item, index) => {
      return (
        <div className="default-table-row" key={index} onClick={this.togglePlan(item.key)}>
          <div className="default-table-col col-1">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeTreatmentPlan(e.target.value, item.key, item.patientKey)
                }
              >
                {sessionTrxValues.map((x) => (
                  <option selected={_.get(item, "treatmentPlan", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-1">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeTreatmentSession(e.target.value, item.key, item.patientKey)
                }
              >
                {sessionTrxValues.map((x) => (
                  <option selected={_.get(item, "treatmentSession", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-3">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) => this.handleChangeUnit(e.target.value, item.key, item.patientKey)}
              >
                {unitValues.map((x) => (
                  <option selected={_.get(item, "unit", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeIntervalUnit(e.target.value, item.key, item.patientKey)
                }
              >
                {intervalDurationUnitValues.map((x) => (
                  <option selected={_.get(item, "intervalUnit", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeIntervalAmount(e.target.value, item.key, item.patientKey)
                }
              >
                {intervalDurationAmountValues.map((x) => (
                  <option selected={_.get(item, "intervalAmount", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeDurationUnit(e.target.value, item.key, item.patientKey)
                }
              >
                {intervalDurationUnitValues.map((x) => (
                  <option selected={_.get(item, "durationUnit", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={(e) =>
                  this.handleChangeDurationAmount(e.target.value, item.key, item.patientKey)
                }
              >
                {intervalDurationAmountValues.map((x) => (
                  <option selected={_.get(item, "durationAmount", 1) === x} key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-3" style={{ flex: "0 0 120px" }}>
            <div className="middler" onClick={(e) => e.stopPropagation()}>
              <select
                style={{ background: "none", border: "none" }}
                onChange={({ target }) =>
                  changeAssignedDoctor(
                    doctors.find((x) => `${x.id}` === target.value),
                    item.key,
                  )
                }
              >
                {doctors.map((x) => (
                  <option
                    key={x.id}
                    value={x.id}
                    selected={_.get(item, "assignedDoctor.id") === x.id}
                  >
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="default-table-col col-4">
            <div className="middler">{_.get(item.treatmentItem, "code")}</div>
          </div>
          <div className="default-table-col col-5">
            <div className="middler">{_.get(item.treatmentItem, "description")}</div>
          </div>
          <div className="default-table-col col-5">
            <div className="middler" onClick={(event) => event.stopPropagation()}>
              <input
                style={{ width: "100%" }}
                onBlur={() =>
                  this.handleChangeTreatmentNote(this.state.note[index], item.key, item.patientKey)
                }
                onChange={({ target }) => {
                  this.setState((x) => ({
                    note: {
                      ...x.note,
                      [index]: target.value,
                    },
                  }));
                }}
                value={this.state.note[index]}
                defaultValue={_.get(item, "note", "")}
              />
            </div>
          </div>
          <div className="default-table-col col-7">
            <div className="middler">{_.get(item.area, "name")}</div>
          </div>
          <div className="default-table-col col-8">
            <div className="middler">{(+item.price).toFixed(2)}</div>
          </div>
          <div className="default-table-col col-9">
            <div className="middler">
              {moment(item.updatableCreatedDate || item.createdDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="default-table-col col-10">
            <FormCheck checked={planNewSelected[item.key]} readOnly={true} />
          </div>
        </div>
      );
    });

    return (
      <div className="default-table chart-plan-table">
        <div className="default-table-header">
          <div className="default-table-col col-1">Trx Plan</div>
          <div className="default-table-col col-2">Session</div>
          <div className="default-table-col col-1">Unit</div>
          <div style={customTheadStyles} className="default-table-col col-4">
            Interval unit
          </div>
          <div style={customTheadStyles} className="default-table-col col-4">
            Interval amount
          </div>
          <div style={customTheadStyles} className="default-table-col col-4">
            Duration unit
          </div>
          <div style={customTheadStyles} className="default-table-col col-4">
            Duration amount
          </div>
          <div className="default-table-col col-3" style={{ flex: "0 0 120px" }}>
            Assigned Doctor
          </div>
          <div className="default-table-col col-4">Code</div>
          <div className="default-table-col col-5">Description</div>
          <div className="default-table-col col-5">Note</div>
          <div className="default-table-col col-7">Area</div>
          <div className="default-table-col col-8">Fee</div>
          <div className="default-table-col col-9">Created Date</div>
          <div className="default-table-col col-10">
            <FormCheck
              onClick={this.props.transactionActions.toggleAllPlanNew}
              checked={_.size(selectedList) === _.size(planNewList)}
              readOnly={true}
            />
          </div>
        </div>
        <div className="default-table-body">{$list}</div>
        <Show if={fetchingPlan}>
          <ReactSpinner />
        </Show>
      </div>
    );
  }
}

export default enhancer(ChartTransactionPlanModalSelected);
