import React from "react";
import FilledImage from "../../widgets/FilledImage";
import FindIcon from "../../../assets/images/chart/find_apptMenuIcon@2x.png";

export default function ChartSideSearch(props) {
  const { onChange, value } = props;

  let className = "input-group chart-side-body-search";

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className} style={props?.style}>
      <span className="input-group-addon">
        <FilledImage color="#aaa" src={FindIcon} className="zoom-2-5x" />
      </span>
      <input
        style={{ zIndex: 0 }}
        type="text"
        className="form-control"
        placeholder="search"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
