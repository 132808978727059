import React from "react";
import { connect } from "react-redux";

import ReactSpinner from "react-spinjs-fix";

import Show from "../../../widgets/Show";
import BackButton from "../../../widgets/BackButton";

import ChartPlanListItem from "./ChartPlanListItem";
import ChartSideSearch from "../ChartSideSearch";

import planSortFields from "../../../../constants/chart-sidebar/planSortFields";

import bindActions from "../../../../helpers/bindActions";
import * as chartSidePlanActions from "../../../../actions/chartSidePlanActions";

const enhancer = connect(null, bindActions({ chartSidePlanActions }));

class ChartPlanList extends React.Component {
  componentDidMount() {
    this.props.chartSidePlanActions.initList();
  }

  openItem = (item) => {
    return () => {
      this.props.chartSidePlanActions.openPDF(item);
    };
  };

  loadList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.chartSidePlanActions.loadList();
    }
  };

  select = (event) => {
    this.props.chartSidePlanActions.selectSortField(event.target.value);
  };

  onSearch = (event) => {
    this.props.chartSidePlanActions.setSearchValue(event.target.value);
  };

  render() {
    const { searchValue, list, fetching, sortField, deletePlanId } = this.props.chartSidePlan;

    let $list = list.map((item, index) => (
      <ChartPlanListItem
        key={index}
        item={item}
        deletePlanItemConfirmShow={this.props.chartSidePlanActions.deletePlanItemConfirmShow}
        deletePlanId={deletePlanId}
        openItem={this.openItem(item)}
      />
    ));

    const $options = planSortFields.map((item, index) => (
      <option key={index} value={item.code}>
        {item.name}
      </option>
    ));

    return (
      <div className="chart-clinical-note">
        <div className="sidebar-header chart-side-header">
          <div className="clearfix">
            <div className="col-sm-4">
              <BackButton
                className="btn-sm"
                text="Back"
                onClick={this.props.chartSidePlanActions.popStack}
              />
            </div>
            <div className="col-sm-8">
              <div className="chart-clinical-note-title">Treatment Estimate</div>
            </div>
          </div>
        </div>
        <div className="chart-side-body">
          <ChartSideSearch value={searchValue} onChange={this.onSearch} />
          <div className="chart-clinical-note-select">
            <select onChange={this.select} value={sortField} className="form-control">
              {$options}
            </select>
          </div>
          <div className="body">
            <div className="chart-side-body-wrapper" onScroll={this.loadList}>
              {$list}
              <Show if={fetching}>
                <ReactSpinner />
              </Show>
            </div>
          </div>
          <div className="all-codes">
            <button
              onClick={this.props.chartSidePlanActions.openForList}
              className="btn btn-primary btn-block"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartPlanList);
