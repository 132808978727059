import _ from "lodash";
import React from "react";
import moment from "moment";
import { FULL_DATE_FORMAT } from "../../constants/Constants";
import ReactSpinner from "react-spinjs-fix";
import Show from "../widgets/Show";
import RemindersToggleDentist from "./RemindersToggleDentist";
import RemindersAutomaticModeForm from "./RemindersAutomaticModeForm";
import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";

const enhancer = connect(null, bindActions({ remindersActions }));
class RemindersAutomaticMode extends React.Component {
  componentDidMount() {
    this.props.remindersActions.initAutomaticList();
  }

  loadAutomaticList = (e) => {
    const scrollBottom = e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight;

    if (scrollBottom) {
      this.props.remindersActions.loadAutomaticList();
    }
  };

  selectAutomaticListItem = (id, editMode) => {
    if (editMode) {
      this.props.remindersActions.toggleAutomaticListSelected(id);
    }
  };

  render() {
    const {
      templates,

      automaticListSelected,
      automaticEditMode,
      automaticList,
      fetchingAutomaticList,

      toggleDentistVisible,
      toggleDentistMode,
      toggleDentistSelected,
      searchDentist,
      searchDentistSelected,
      fetchingDentistList,
      dentistList,
    } = this.props;

    let list = [];
    let templateColClassName;

    if (automaticEditMode) {
      templateColClassName = "col-xs-5";
    } else {
      templateColClassName = "col-xs-6";
    }
    if (!_.isEmpty(automaticList)) {
      _.forEach(automaticList, (element, index) => {
        let classNameMode = "checkedAutomaticList";

        if (_.indexOf(automaticListSelected, element.id) !== -1) classNameMode += " checked";
        else classNameMode += " unchecked";

        list.push(
          <tr
            key={index}
            onClick={() => {
              this.selectAutomaticListItem(element.id, automaticEditMode);
            }}
          >
            <Show if={automaticEditMode}>
              <td className="checkTitle col-xs-1">
                <div className={classNameMode} />
              </td>
            </Show>
            <td className={templateColClassName}>{element.template.name}</td>
            <td className="col-xs-2">
              {(element.recipients &&
                element.recipients.length + " " + localStorage.getItem("doctorLabel")) ||
                ""}
            </td>
            <td className="col-xs-2">
              {element.runBefore === 1 ? "1 day" : element.runBefore + " days"}
            </td>
            <td className="col-xs-2">{moment(element.createdDate).format(FULL_DATE_FORMAT)}</td>
          </tr>,
        );
      });
    }

    return (
      <div className="reminders-box reminders-automatic-mode">
        <RemindersAutomaticModeForm
          templates={templates}
          toggleDentistSelected={toggleDentistSelected}
          initialValues={{ sendBefore: 1 }}
        />

        <div className="col-sm-12 reminders-automatic-box">
          <table className="table table-reminders">
            <thead>
              <tr>
                <Show if={automaticEditMode}>
                  <th className="col-xs-1">&nbsp;</th>
                </Show>
                <th className={templateColClassName}>Template</th>
                <th className="col-xs-2">Receiver</th>
                <th className="col-xs-2">Send before</th>
                <th className="col-xs-2">Date sent</th>
              </tr>
            </thead>
            <tbody onScroll={this.loadAutomaticList}>
              {list}
              <Show if={fetchingAutomaticList}>
                <tr className="tr-spinner">
                  <td>
                    <ReactSpinner />
                  </td>
                </tr>
              </Show>
            </tbody>
          </table>
        </div>
        <div className="clearfix" />
        <Show if={toggleDentistVisible}>
          <RemindersToggleDentist
            toggleDentistVisible={toggleDentistVisible}
            toggleDentistMode={toggleDentistMode}
            toggleDentistSelected={toggleDentistSelected}
            searchDentist={searchDentist}
            searchDentistSelected={searchDentistSelected}
            dentistList={dentistList}
            fetchingDentistList={fetchingDentistList}
          />
        </Show>
      </div>
    );
  }
}
export default enhancer(RemindersAutomaticMode);
