import { hideSideBar } from "./chartActions";
import OrderApi from "../api-legacy/OrderApi";
import { startLoader, stopLoader } from "./loaderActions";

export const SHOW_ORDER_MODAL = "SHOW_ORDER_MODAL";
export const HIDE_ORDER_MODAL = "HIDE_ORDER_MODAL";
export const PUSH_ORDER_STACK = "PUSH_ORDER_STACK";
export const CLEAR_ORDER_STACK = "CLEAR_ORDER_STACK";
export const UPDATE_ORDER_STACK = "UPDATE_ORDER_STACK";
export const POP_ORDER_STACK = "POP_ORDER_STACK";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const GET_LAB_ORDER_LIST_START = "GET_LAB_ORDER_LIST_START";
export const GET_LAB_ORDER_LIST_SUCCESS = "GET_LAB_ORDER_LIST_SUCCESS";
export const GET_LAB_ORDER_LIST_ERROR = "GET_LAB_ORDER_LIST_ERROR";

export const GET_LAB_ORDER_ITEM_START = "GET_LAB_ORDER_ITEM_START";
export const GET_LAB_ORDER_ITEM_SUCCESS = "GET_LAB_ORDER_ITEM_SUCCESS";
export const GET_LAB_ORDER_ITEM_ERROR = "GET_LAB_ORDER_ITEM_ERROR";

export const GET_LAB_ORDER_TYPES_START = "GET_LAB_ORDER_TYPES_START";
export const GET_LAB_ORDER_TYPES_SUCCESS = "GET_LAB_ORDER_TYPES_SUCCESS";
export const GET_LAB_ORDER_TYPES_ERROR = "GET_LAB_ORDER_TYPES_ERROR";

export const SAVE_LAB_ORDER_ITEM_START = "SAVE_LAB_ORDER_ITEM_START";
export const SAVE_LAB_ORDER_ITEM_SUCCESS = "SAVE_LAB_ORDER_ITEM_SUCCESS";
export const SAVE_LAB_ORDER_ITEM_ERROR = "SAVE_LAB_ORDER_ITEM_ERROR";

export const DELETE_LAB_ORDER_ITEM_START = "DELETE_LAB_ORDER_ITEM_START";
export const DELETE_LAB_ORDER_ITEM_SUCCESS = "DELETE_LAB_ORDER_ITEM_SUCCESS";
export const DELETE_LAB_ORDER_ITEM_ERROR = "DELETE_LAB_ORDER_ITEM_ERROR";

export const GET_LAB_ORDER_FORM_DATA_START = "GET_LAB_ORDER_FORM_DATA_START";
export const GET_LAB_ORDER_FORM_DATA_SUCCESS = "GET_LAB_ORDER_FORM_DATA_SUCCESS";
export const GET_LAB_ORDER_FORM_DATA_ERROR = "GET_LAB_ORDER_FORM_DATA_ERROR";

export const GET_RADIOLOGY_ORDER_LIST_START = "GET_RADIOLOGY_ORDER_LIST_START";
export const GET_RADIOLOGY_ORDER_LIST_SUCCESS = "GET_RADIOLOGY_ORDER_LIST_SUCCESS";
export const GET_RADIOLOGY_ORDER_LIST_ERROR = "GET_RADIOLOGY_ORDER_LIST_ERROR";

export const GET_RADIOLOGY_ORDER_ITEM_START = "GET_RADIOLOGY_ORDER_ITEM_START";
export const GET_RADIOLOGY_ORDER_ITEM_SUCCESS = "GET_RADIOLOGY_ORDER_ITEM_SUCCESS";
export const GET_RADIOLOGY_ORDER_ITEM_ERROR = "GET_RADIOLOGY_ORDER_ITEM_ERROR";

export const SAVE_RADIOLOGY_ORDER_ITEM_START = "SAVE_RADIOLOGY_ORDER_ITEM_START";
export const SAVE_RADIOLOGY_ORDER_ITEM_SUCCESS = "SAVE_RADIOLOGY_ORDER_ITEM_SUCCESS";
export const SAVE_RADIOLOGY_ORDER_ITEM_ERROR = "SAVE_RADIOLOGY_ORDER_ITEM_ERROR";

export const DELETE_RADIOLOGY_ORDER_ITEM_START = "DELETE_RADIOLOGY_ORDER_ITEM_START";
export const DELETE_RADIOLOGY_ORDER_ITEM_SUCCESS = "DELETE_RADIOLOGY_ORDER_ITEM_SUCCESS";
export const DELETE_RADIOLOGY_ORDER_ITEM_ERROR = "DELETE_RADIOLOGY_ORDER_ITEM_ERROR";

export const GET_RADIOLOGY_ORDER_FORM_DATA_START = "GET_RADIOLOGY_ORDER_FORM_DATA_START";
export const GET_RADIOLOGY_ORDER_FORM_DATA_SUCCESS = "GET_RADIOLOGY_ORDER_FORM_DATA_SUCCESS";
export const GET_RADIOLOGY_ORDER_FORM_DATA_ERROR = "GET_RADIOLOGY_ORDER_FORM_DATA_ERROR";

export const GET_CLINIC_MEMBERS_START = "GET_CLINIC_MEMBERS_START";
export const GET_CLINIC_MEMBERS_SUCCESS = "GET_CLINIC_MEMBERS_SUCCESS";
export const GET_CLINIC_MEMBERS_ERROR = "GET_CLINIC_MEMBERS_ERROR";

export const RESET_ORDER_ITEMS_ERROR = "RESET_ORDER_ITEMS_ERROR";
export const SET_LAB_ORDER_FILTER_FORM = "SET_LAB_ORDER_FILTER_FORM";
export const SET_RADIOLOGY_ORDER_FILTER_FORM = "SET_RADIOLOGY_ORDER_FILTER_FORM";

export const UPLOAD_FILES_START = "UPLOAD_FILES_START";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_ERROR = "UPLOAD_FILES_ERROR";

export const UPLOAD_RADIOLOGY_FILES_START = "UPLOAD_RADIOLOGY_FILES_START";
export const UPLOAD_RADIOLOGY_FILES_SUCCESS = "UPLOAD_RADIOLOGY_FILES_SUCCESS";
export const UPLOAD_RADIOLOGY_FILES_ERROR = "UPLOAD_RADIOLOGY_FILES_ERROR";

export const PUSH_LAB_RADIOLOGY_STACK = "PUSH_LAB/RADIOLOGY_STACK";

export const orderTabs = [
  { code: "LAB_ORDER", name: "Lab order" },
  { code: "RADIOLOGY_ORDER", name: "Radiology order" },
];

export const showOrderModal = () => (dispatch) => dispatch({ type: SHOW_ORDER_MODAL });
export const hideOrderModal = () => (dispatch) => dispatch({ type: HIDE_ORDER_MODAL });

export const pushOrderStack = (stackItem) => ({
  type: PUSH_LAB_RADIOLOGY_STACK,
  stackItem,
});

export const pushStack = (stackItem) => ({
  type: PUSH_ORDER_STACK,
  stackItem,
});

export const clearStack = () => ({
  type: CLEAR_ORDER_STACK,
});

export const updateStack = (stack) => ({
  type: UPDATE_ORDER_STACK,
  stack,
});

export const popStack = () => (dispatch, getState) => {
  const { stack } = getState().chartSideOrder;

  if (stack.length === 1) {
    dispatch(hideSideBar());
  } else {
    dispatch({
      type: POP_ORDER_STACK,
    });
  }
};

export const setActiveTab = (activeTab) => ({
  type: SET_ACTIVE_TAB,
  activeTab,
});

export const initLabOrderList = (data) => (dispatch) => {
  const transfer = {
    ...data,
    start: 0,
    limit: 25,
  };
  return dispatch({
    api: OrderApi.getLabOrderList,
    types: [GET_LAB_ORDER_LIST_START, GET_LAB_ORDER_LIST_SUCCESS, GET_LAB_ORDER_LIST_ERROR],
    data: transfer,
  });
};

export const getLabOrderList = (data) => (dispatch, getState) => {
  const {
    chartSideOrder: { labOrderList, labOrderTotalCount, labOrderFetching },
  } = getState();
  if ((labOrderList.length && labOrderList.length === labOrderTotalCount) || labOrderFetching) {
    return;
  }
  const transfer = {
    ...data,
    start: labOrderList.length,
    limit: 25,
  };
  return dispatch({
    api: OrderApi.getLabOrderList,
    types: [GET_LAB_ORDER_LIST_START, GET_LAB_ORDER_LIST_SUCCESS, GET_LAB_ORDER_LIST_ERROR],
    data: transfer,
  });
};

export const saveLabOrderItem = (data) => (dispatch) => {
  dispatch(startLoader());
  return dispatch({
    api: OrderApi.saveLabOrderItem,
    types: [SAVE_LAB_ORDER_ITEM_START, SAVE_LAB_ORDER_ITEM_SUCCESS, SAVE_LAB_ORDER_ITEM_ERROR],
    data,
  })
    .then(() => {
      dispatch(stopLoader());
      dispatch(popStack());
    })
    .catch((e) => dispatch(stopLoader(e)));
};

export const getLabOrderItem = (id) => (dispatch) =>
  dispatch({
    api: OrderApi.getLabOrderItem,
    types: [GET_LAB_ORDER_ITEM_START, GET_LAB_ORDER_ITEM_SUCCESS, GET_LAB_ORDER_ITEM_ERROR],
    data: { id },
  });

export const getLabOrderTypes = () => (dispatch) =>
  dispatch({
    api: OrderApi.getLabOrderTypes,
    types: [GET_LAB_ORDER_TYPES_START, GET_LAB_ORDER_TYPES_SUCCESS, GET_LAB_ORDER_TYPES_ERROR],
  });

export const deleteLabOrderItem = (id) => (dispatch, getState) => {
  const {
    patient: { patientKey },
  } = getState().chart;
  return dispatch({
    api: OrderApi.deleteLabOrderItem,
    types: [
      DELETE_LAB_ORDER_ITEM_START,
      DELETE_LAB_ORDER_ITEM_SUCCESS,
      DELETE_LAB_ORDER_ITEM_ERROR,
    ],
    data: { id },
  }).then(() =>
    dispatch({
      api: OrderApi.getLabOrderList,
      types: [GET_LAB_ORDER_LIST_START, GET_LAB_ORDER_LIST_SUCCESS, GET_LAB_ORDER_LIST_ERROR],
      data: { start: 0, limit: 25, patientKey },
    }),
  );
};

export const saveRadiologyOrderItem = (data) => (dispatch) => {
  dispatch(startLoader());
  return dispatch({
    api: OrderApi.saveRadiologyOrderItem,
    types: [
      SAVE_RADIOLOGY_ORDER_ITEM_START,
      SAVE_RADIOLOGY_ORDER_ITEM_SUCCESS,
      SAVE_RADIOLOGY_ORDER_ITEM_ERROR,
    ],
    data,
  })
    .then(() => {
      dispatch(popStack());
      dispatch(stopLoader());
    })
    .catch((error) => dispatch(stopLoader(error)));
};

export const getRadiologyOrderItem = (id) => (dispatch) =>
  dispatch({
    api: OrderApi.getRadiologyOrderItem,
    types: [
      GET_RADIOLOGY_ORDER_ITEM_START,
      GET_RADIOLOGY_ORDER_ITEM_SUCCESS,
      GET_RADIOLOGY_ORDER_ITEM_ERROR,
    ],
    data: { id },
  });

export const deleteRadiologyOrderItem = (id) => (dispatch, getState) => {
  const {
    patient: { patientKey },
  } = getState().chart;
  return dispatch({
    api: OrderApi.deleteRadiologyOrderItem,
    types: [
      DELETE_RADIOLOGY_ORDER_ITEM_START,
      DELETE_RADIOLOGY_ORDER_ITEM_SUCCESS,
      DELETE_RADIOLOGY_ORDER_ITEM_ERROR,
    ],
    data: { id },
  }).then(() =>
    dispatch({
      api: OrderApi.getRadiologyOrderList,
      types: [
        GET_RADIOLOGY_ORDER_LIST_START,
        GET_RADIOLOGY_ORDER_LIST_SUCCESS,
        GET_RADIOLOGY_ORDER_LIST_ERROR,
      ],
      data: { start: 0, limit: 25, patientKey },
    }),
  );
};

export const initRadiologyOrderList = (data) => (dispatch) => {
  const transfer = {
    ...data,
    start: 0,
    limit: 25,
  };
  return dispatch({
    api: OrderApi.getRadiologyOrderList,
    types: [
      GET_RADIOLOGY_ORDER_LIST_START,
      GET_RADIOLOGY_ORDER_LIST_SUCCESS,
      GET_RADIOLOGY_ORDER_LIST_ERROR,
    ],
    data: transfer,
  });
};

export const getRadiologyOrderList = (data) => (dispatch, getState) => {
  const {
    chartSideOrder: { radiologyOrderList, radiologyOrderTotalCount, radiologyOrderFetching },
  } = getState();
  if (
    (radiologyOrderList.length && radiologyOrderList.length === radiologyOrderTotalCount) ||
    radiologyOrderFetching
  ) {
    return;
  }
  const transfer = {
    ...data,
    start: radiologyOrderList.length,
    limit: 25,
  };
  return dispatch({
    api: OrderApi.getRadiologyOrderList,
    types: [
      GET_RADIOLOGY_ORDER_LIST_START,
      GET_RADIOLOGY_ORDER_LIST_SUCCESS,
      GET_RADIOLOGY_ORDER_LIST_ERROR,
    ],
    data: transfer,
  });
};

export const uploadLabOrderFiles = (file) => (dispatch) => {
  dispatch(startLoader());
  const data = new FormData();

  data.append("file", file, file.name);

  if (file.name.slice(file.name.lastIndexOf(".") + 1) === "pdf") {
    data.append("typeCode", "LAB_ORDER_ATTACHMENT_PDF");
  } else {
    data.append("typeCode", "LAB_ORDER_ATTACHMENT_IMAGE");
  }

  data.append("description", file.name);

  return dispatch(uploadLabOrderFilesApi(data))
    .then((response) => {
      dispatch(stopLoader());
      return response;
    })
    .catch((e) => dispatch(stopLoader(e)));
};

export const uploadRadiologyOrderFiles = (file) => (dispatch) => {
  dispatch(startLoader());
  const data = new FormData();

  data.append("file", file, file.name);

  if (file.name.slice(file.name.lastIndexOf(".") + 1) === "pdf") {
    data.append("typeCode", "RADIOLOGY_ORDER_ATTACHMENT_PDF");
  } else {
    data.append("typeCode", "RADIOLOGY_ORDER_ATTACHMENT_IMAGE");
  }

  data.append("description", file.name);

  return dispatch(uploadRadiologyOrderFilesApi(data))
    .then((response) => {
      dispatch(stopLoader());
      return response;
    })
    .catch((e) => dispatch(stopLoader(e)));
};

export const getClinicMembers = (clinicId) => (dispatch) => {
  return dispatch({
    api: OrderApi.getClinicMembers,
    types: [GET_CLINIC_MEMBERS_START, GET_CLINIC_MEMBERS_SUCCESS, GET_CLINIC_MEMBERS_ERROR],
    data: { clinicId },
  });
};

export const getLabOrderFormData = () => (dispatch) => {
  return dispatch({
    api: OrderApi.getLabOrderFormData,
    types: [
      GET_LAB_ORDER_FORM_DATA_START,
      GET_LAB_ORDER_FORM_DATA_SUCCESS,
      GET_LAB_ORDER_FORM_DATA_ERROR,
    ],
  });
};

export const getRadiologyOrderFormData = () => (dispatch) => {
  return dispatch({
    api: OrderApi.getRadiologyOrderFormData,
    types: [
      GET_RADIOLOGY_ORDER_FORM_DATA_START,
      GET_RADIOLOGY_ORDER_FORM_DATA_SUCCESS,
      GET_RADIOLOGY_ORDER_FORM_DATA_ERROR,
    ],
  });
};

export const resetOrderItems = () => (dispatch) =>
  dispatch({
    type: RESET_ORDER_ITEMS_ERROR,
  });

export const setLabOrderFilterForm = (data) => (dispatch) =>
  dispatch({
    type: SET_LAB_ORDER_FILTER_FORM,
    data,
  });

export const setRadiologyOrderFilterForm = (data) => (dispatch) =>
  dispatch({
    type: SET_RADIOLOGY_ORDER_FILTER_FORM,
    data,
  });

export const uploadLabOrderFilesApi = (data) => (dispatch) =>
  dispatch({
    api: OrderApi.uploadLabOrderFiles,
    types: [UPLOAD_FILES_START, UPLOAD_FILES_SUCCESS, UPLOAD_FILES_ERROR],
    data,
  });

export const uploadRadiologyOrderFilesApi = (data) => (dispatch) =>
  dispatch({
    api: OrderApi.uploadRadiologyOrderFiles,
    types: [
      UPLOAD_RADIOLOGY_FILES_START,
      UPLOAD_RADIOLOGY_FILES_SUCCESS,
      UPLOAD_RADIOLOGY_FILES_ERROR,
    ],
    data,
  });
