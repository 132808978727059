import _ from "lodash";
import React from "react";
import cx from "classnames";

import FlexBox from "../ui/FlexBox";
import DataList from "../widgets/DataList";
import { ViewTypes } from "./ViewButtonGroup";
import { SidebarTypes } from "./TypeButtonGroup";
import ChartSideGridCode from "../chart/side-bar/ChartSideGridCode";
import ChartSideListProcedure from "../chart/side-bar/ChartSideListProcedure";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import favoriteImage from "../../assets/images/chart/fav-icon2.png";

import ChartSideListCode from "../chart/side-bar/ChartSideListCode";

export class SidebarList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
    };
  }

  componentDidMount() {
    const { getList, categoryChildStack } = this.props;

    const categoryId = _.last(categoryChildStack);

    if (getList) {
      getList(SidebarStackTypes.AllCodes, categoryId);
    }
  }

  switchSelectedItems(id) {
    const { selectedItems } = this.state;

    const itemIndex = selectedItems.findIndex((x) => x === id);

    if (itemIndex >= 0) {
      selectedItems.splice(itemIndex, 1);
    } else {
      selectedItems.push(id);
    }

    this.setState({ selectedItems });
  }

  render() {
    const {
      viewTab,
      typeTab,
      openItem,
      loadList,
      isMultiSelect,
      toggleFavorite,
      chartSideSeries,
    } = this.props;
    const { selectedItems } = this.state;

    const { list, mixidCodes, fetchingList, favouritesIcons } = chartSideSeries;

    const favourites = JSON.parse(localStorage.getItem("favourites"));

    const resList = typeTab === SidebarTypes.Mixed ? mixidCodes : list;

    const sidebarList = resList.map((item, index) => {
      if (item) {
        let src;

        const favTypeTab = typeTab === SidebarTypes.Mixed ? item.$seriesType : typeTab;

        const imageId =
          favourites[favTypeTab][item.code] && favourites[favTypeTab][item.code].imageId;

        if (imageId && favouritesIcons[imageId]) {
          src = favouritesIcons[imageId].publicUrl;
        } else if (item.icon && item.icon.url) {
          src = item.icon.url;
        } else {
          src = favoriteImage;
        }

        switch (typeTab) {
          case SidebarTypes.Treatments:
          case SidebarTypes.Existing:
          case SidebarTypes.Diagnosis:
          case SidebarTypes.Mixed:
            if (viewTab === ViewTypes.Grid) {
              return (
                <ChartSideGridCode
                  src={src}
                  key={index}
                  item={item}
                  seriesType={typeTab}
                  favourites={favourites}
                  isMultiSelect={isMultiSelect}
                  isSelectItem={selectedItems.indexOf(item.id) >= 0}
                  openItem={() =>
                    isMultiSelect ? this.switchSelectedItems(item.id) : openItem(item)
                  }
                />
              );
            }

            return (
              <ChartSideListCode
                key={index}
                item={item}
                seriesType={typeTab}
                favourites={favourites}
                isMultiSelect={isMultiSelect}
                isSelectItem={selectedItems.indexOf(item.id) >= 0}
                openItem={() =>
                  isMultiSelect ? this.switchSelectedItems(item.id) : openItem(item)
                }
                toggleFavorite={toggleFavorite}
              />
            );

          case SidebarTypes.Grouped:
            return (
              <ChartSideListProcedure key={index} item={item} openItem={() => openItem(item)} />
            );
        }
      }
    });

    return (
      <FlexBox flex={true} direction="column">
        <DataList
          className={cx({
            "list-grid": viewTab === ViewTypes.Grid,
          })}
          onScroll={loadList}
          fetching={fetchingList}
        >
          {sidebarList}
        </DataList>

        {isMultiSelect && (
          <button
            type="button"
            style={{
              width: "90%",
              height: "40px",
              marginTop: "12px",
              alignSelf: "center",
            }}
            disabled={selectedItems.length === 0}
            className="btn btn-success"
            onClick={() => {
              openItem(resList.filter(({ id }) => selectedItems.indexOf(id) >= 0));
              localStorage.removeItem("addDiagnosisToTreatment");
            }}
          >
            Add Selected
          </button>
        )}
      </FlexBox>
    );
  }
}
