import { httpClient } from "./BaseApi";
import { parserCallback } from "../helpers/Parser";

const MEMBER_CHOOSE_URL = "api/member/choose.json";
const MEMBER_CHOOSE_DENTIST_URL = "api/member/choose-dentist.json";

const MEMBER_DOCTOR_CHOOSE_URL = "api/member/doctor/choose.json";
const MEMBER_LOCATION_DOCTOR_CHOOSE_URL = "api/member/location/doctor/choose.json";
const MEMBER_LOCATION_DENTIST_CHOOSE_URL = "api/member/location/dentist/choose.json";

const MEMBER_RESET_PASSWORD_URL = "api/member/reset-password.json";

const MEMBER_KEYWORDS_URL = "api/member/keyword/choose.json";
const MEMBER_SIGNATURE_ITEM_URL = "api/member/signature/item.json";
const MEMBER_SIGNATURE_CHECKING_URL = "api/member/signature/checking.json";

const MemberApi = {
  getKeywords: (params) => httpClient.get(MEMBER_KEYWORDS_URL, { params }).then(parserCallback),
  getChoose: (params) => httpClient.get(MEMBER_CHOOSE_URL, { params }),
  resetPassword: (data) => httpClient.post(MEMBER_RESET_PASSWORD_URL, { data }),
  checkSignature: (data) => httpClient.post(MEMBER_SIGNATURE_CHECKING_URL, { data }),
  getChooseDentist: (params) => httpClient.get(MEMBER_CHOOSE_DENTIST_URL, { params }),
  getDoctor: (params) => httpClient.get(MEMBER_DOCTOR_CHOOSE_URL, { params }),
  getDoctorLocations: (params) => httpClient.get(MEMBER_LOCATION_DOCTOR_CHOOSE_URL, { params }),
  getDentistWithPoints: (params) => httpClient.get(MEMBER_LOCATION_DENTIST_CHOOSE_URL, { params }),
  signature: {
    save: (data) => httpClient.put(MEMBER_SIGNATURE_ITEM_URL, { data }),
  },
};

export default MemberApi;
