import React from "react";
import DotImage from "../../../assets/images/dot.png";

export default function RepeatAppointmentRepeatModalSelect({ selected, label, mode, ...props }) {
  let typeClassName = "repeat-appointment-follow-type-select";

  typeClassName += (selected && " follow-type-select") || "";

  return (
    <div
      onClick={() => props.onClick(mode)}
      className="repeat-appointment-follow-type-select-block"
    >
      <div className={typeClassName}>
        {selected && <img src={DotImage} className="img-responsive" alt="" />}
      </div>
      <label className="repeat-modal-label">{label}</label>
    </div>
  );
}
