import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import AppointmentPlannedModal from "./AppointmentPlannedModal";
import { loadPlannedTransactions } from "../../actions/transactionActions";
import { appointmentItemSelector } from "../../reducers/appointmentReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import {
  fetchingTransactionListSelector,
  transactionListSelector,
} from "../../reducers/transactionReducer";

interface Props {
  readonly show: boolean;
  readonly onClose: () => void;
  readonly getList: any;
  readonly appointmentData: any;

  readonly patientKey: string;
}

export function AppointmentPlannedModalWrapper({
  patientKey,
  onClose,
  getList,
  appointmentData,
  ...props
}: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof getList === "function" && appointmentData) {
      const { patient } = appointmentData;
      const transfer = {
        start: 0,
        limit: 50,
        patientKey: patient.patientKey,
        appointmentId: appointmentData.id,
        treatmentPlanned: true,
        treatmentCompleted: true,
      };
      getList(transfer);
    }
  }, []);

  const list = useShallowEqualSelector(transactionListSelector as any);
  const appointmentItem = useShallowEqualSelector(appointmentItemSelector as any);
  const fetching = useShallowEqualSelector(fetchingTransactionListSelector as any);

  return (
    <AppointmentPlannedModal
      {...props}
      list={list}
      fetching={fetching}
      onRequestClose={onClose}
      onScroll={() =>
        dispatch(loadPlannedTransactions(patientKey, appointmentItem.id || appointmentData.id))
      }
    />
  );
}
