import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FilledImage from "../widgets/FilledImage";
import { getTreatmentGroupList } from "../../actions/chartSideSeriesActions";
import findApptImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";
import { setSearchGroup, setSearchValue } from "../../actions/chartSideSeriesActions";
import SelectPickerAsync from "../../components/select-picker/SelectPickerAsync";

// import _ from "lodash";
// import Show from "../widgets/Show";
// import ReactSpinner from "react-spinjs";
// import arrow from "../../assets/images/arrows/down_arrow.png";

export function SidebarSearch({ value, onChange }) {
  const dispatch = useDispatch();
  const [listItem, setListItem] = useState(-1);

  useEffect(() => {
    dispatch(getTreatmentGroupList());
    return () => {
      dispatch({ type: "GET_TREATMENT_GROUP_LIST_ERROR" });
    };
  }, []);

  useEffect(() => {
    dispatch(setSearchGroup(listItem > 0 ? listItem : null));
    onChange(value);
  }, [value, listItem]);

  // const [isOpen, setIsOpen] = useState(false);
  // const { treatmentGroupList, treatmentGroupListFetching } = useSelector(
  //   (store) => store.chartSideSeries,
  // );

  // const loadList = (event) => {
  //   const scrollBottom =
  //     Math.ceil(event.target.scrollTop + event.target.offsetHeight) ===
  //     Math.ceil(event.target.scrollHeight);
  //   if (scrollBottom) {
  //     dispatch(getTreatmentGroupList());
  //   }
  // };
  // const selectedGroupItem = (treatmentGroupList || []).find((item) => item.id === listItem);

  return (
    <div className="chart-sidebar-search">
      <div className="sidebar-search-image-container" style={{ height: "34px" }}>
        <FilledImage color="#aaa" className="zoom-2-5x" src={findApptImage} />
      </div>

      <input
        style={{ flex: "0 1 55%", height: "34px" }}
        value={value}
        placeholder="search"
        className="sidebar-search-input"
        onChange={({ target }) => dispatch(setSearchValue(target.value))}
      />
      <div
        className="ml-1 "
        style={{
          borderRadius: 4,
          flex: "0 1 45%",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
        // onClick={() => setIsOpen(!isOpen)}
      >
        <SelectPickerAsync
          isDisabled={false}
          url={"api/treatment/group/list.json"}
          withoutCodeLabel={true}
          defaultAllOption={true}
          limit={100}
          defaultValue={{ value: "-1", label: "All" }}
          onChange={(x) => {
            setListItem(x.value);
            dispatch(setSearchGroup(x.value));
          }}
        />
        {/*{_.get(selectedGroupItem, "description", "All")}*/}
        {/*<FilledImage color="#aaa" className="zoom-2-5x" src={arrow} />*/}
      </div>

      {/*{isOpen && (
        <div
          className={"chart-sidebar-search-dropdown-wrapper"}
          style={{ maxHeight: "350px", overflowY: "auto" }}
          onScroll={(event) => loadList(event)}
        >
          {[{ id: -1, description: "All" }, ...treatmentGroupList].map((item) => (
            <div
              key={item.id}
              style={{
                background: listItem === item.id && "#1aa2fe",
                color: listItem === item.id && "#fffee0",
              }}
              onClick={() => {
                setListItem(item.id);
                setIsOpen(false);
                dispatch(setSearchGroup(item.id));
              }}
            >
              {item.description}
            </div>
          ))}
        </div>
      )}*/}
      {/*<Show if={treatmentGroupListFetching}>*/}
      {/*  <ReactSpinner />*/}
      {/*</Show>*/}
    </div>
  );
}
