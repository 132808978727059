/* eslint-disable default-case */
import "moment/locale/en-gb";

import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { DatePicker } from "../ui/DatePicker";
import { withRouter } from "react-router";
import Show from "./../widgets/Show";
import Utils from "../../helpers/Utils";
import Element from "../../models/Element";
import CustomSelectBox from "../widgets/CustomSelectBox";
import { STATUS_TYPES } from "../../constants/StatusTypes";
import AppointmentUtils from "../../helpers/AppointmentUtils";
import AppointmentAdditionalDates from "./AppointmentAdditionalDates";
import RepeatAppointmentSidebar from "./repeat-appointment/RepeatAppointmentSidebar";
import {
  AppointmentScreenTypes,
  FORM_ACTIONS,
  MINUTE_FOR_VISIBLE,
  PLEASE_SELECT,
  PLEASE_SELECT_ITEM,
} from "../../constants/Constants";
import * as appointmentActions from "../../actions/appointmentActions";
import {
  changeItem,
  changeNursery,
  getAppointmentEligibilityDetails,
  getCategories,
  getDentalPoint,
  getDoctorsLocations,
  getDoctors,
  getFreePoints,
  getHistory,
  getMachines,
  getSubCategories,
  moveAppointment,
  repeatAppointmentConfirmModalShow,
  repeatAppointmentModalShow,
  repeatAppointmentSaveAfter,
  repeatAppointmentSaveSelectedDate,
  repeatAppointmentSetDeleteId,
  repeatAppointmentUpdateAfter,
  showCodesForThisModal,
  statusChangeOfAppointment,
  updateAppointmentEligibilityStatus,
  getMultipleDoctorFreePoints,
  getAppointmentRemindersLog,
  updatePaymentType,
  updateMeetingType,
  updateLocation,
  setAppointmentAdditionalPrice,
} from "../../actions/appointmentActions";
import * as remindersActions from "../../actions/remindersActions";
import { getInsuranceList } from "../../actions/insuranceActions";
import { closeSimpleModal, openSimpleModal } from "../../actions/simpleModalActions";
import { MASTER_TYPES } from "../../constants/MasterTypes";
import {
  selectFollowType,
  setNote,
  // setNote_02,
} from "../../actions/repeatAppointmentActions";
import {
  getCompanyEndOfDay,
  getCompanyFormatFullDate,
  getCompanyStartOfDay,
} from "../../helpers/DateUtils";
import bindActions from "../../helpers/bindActions";
import RemindersManualModeForm from "../reminders/RemindersManualModeForm";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { SelectPicker } from "../select-picker/SelectPicker";
import { FieldContainer } from "../form/FieldContainer";
import { Modal } from "../ui/Modal";
import { Routes } from "../../constants/Routes";
import { Button, ButtonType } from "../ui/Button";
import AppointmentLengthButtons from "../settings/AppointmentLengthButtons";
import AppointmentStatusButtons from "../settings/AppointmentStatusButtons";
import TimeSlotPicker from "../widgets/TimeSlotPicker";
import { EligibilityDetailsModalWrapper } from "../eligibility/EligibilityDetailsModalWrapper";
import { showMessage } from "../../actions/loaderActions";
import { Checkbox } from "../ui/Checkbox";
import AppointmentAdditionalDoctorsModal from "./AppointmentAdditionalDoctorsModal";
import AppointmentTreatmentsPlanModal from "./AppointmentTreatmentsPlanModal";
import AppointmentLogsModal from "./appointmentLogs/AppointmentLogsModal";

const enhancer = connect(
  ({
    session: { company, clinic, miscSettings, allowAppointmentMultiBooking },
    calendar: { columns, date, points },
    auth: { members, chosenMemberId, doctors },
    appointment: {
      locationList,
      statusOtherList,
      statusApprovalList,
      statusLabList,
      appointmentMeetingTypes,
      appointmentPaymentStatuses,
      doctorsList,
      machinesList,
      categoryService,
      subCategoryService,
      subSubCategoryService,
      repeatAppointmentRepeatModalIndex,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentList,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentSelectedDate,
      repeatAppointmentMode,
      repeatAppointmentModalVisible,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentDeleteId,
      statusList,
      categoryList,
      dentalPointList,
      dentistList,
      patientGenderList,
      patientDetails,
      freePoints,
      codesForThisTotalCount,
      appointment,
      fetchingInit,
      nurse,
      masterTypes,
      moved,
      patientInfo,
      requestedAppointmentItem,
      patient,
      disableAdditionalChargesFieldForTempPatient,
    },
    form: { repeatAppointment },
    transaction: { transaction },
    repeatAppointment: { selectType, note, note_02 },
    reminders,
    insurance,
  }) => {
    return {
      locationList,
      statusOtherList,
      statusApprovalList,
      statusLabList,
      appointmentMeetingTypes,
      appointmentPaymentStatuses,
      machinesList,
      categoryService,
      subCategoryService,
      subSubCategoryService,
      doctorsList,
      members,
      doctors,
      chosenMemberId,
      company,
      clinic,
      date,
      columns,
      statusList,
      categoryList,
      dentalPointList,
      dentistList,
      patientGenderList,
      selectType,
      patientDetails,
      freePoints,
      codesForThisTotalCount,
      appointment,
      fetchingInit,
      repeatAppointmentModalVisible,
      repeatAppointmentSelectedDate,
      repeatAppointmentMode,
      repeatAppointmentConfirmModalVisible,
      repeatAppointmentClickedRepeatArray,
      repeatAppointmentList,
      repeatAppointmentDeleteId,
      repeatAppointment,
      transaction,
      repeatAppointmentRepeatModalVisible,
      repeatAppointmentRepeatModalMode,
      repeatAppointmentRepeatModalDate,
      repeatAppointmentRepeatModalIndex,
      nurse,
      masterTypes,
      moved,
      note,
      note_02,
      reminders,
      miscSettings,
      insurance,
      patientInfo,
      points,
      allowAppointmentMultiBooking,
      requestedAppointmentItem,
      patient,
      disableAdditionalChargesFieldForTempPatient,
    };
  },
  bindActions({
    setAppointmentAdditionalPrice,
    getSubCategories,
    getCategories,
    getMachines,
    getDoctors,
    moveAppointment,
    changeItem,
    getFreePoints,
    getDentalPoint,
    getDoctorsLocations,
    getMultipleDoctorFreePoints,
    statusChangeOfAppointment,
    showCodesForThisModal,
    repeatAppointmentModalShow,
    openSimpleModal,
    closeSimpleModal,
    getHistory,
    changeNursery,
    selectFollowType,
    setNote,
    // setNote_02,
    startLoader,
    stopLoader,
    repeatAppointmentSaveAfter,
    repeatAppointmentConfirmModalShow,
    repeatAppointmentSaveSelectedDate,
    repeatAppointmentUpdateAfter,
    repeatAppointmentSetDeleteId,
    appointmentActions,
    remindersActions,
    getInsuranceList,
    getAppointmentEligibilityDetails,
    updateAppointmentEligibilityStatus,
    showMessage,
    getAppointmentRemindersLog,
    updatePaymentType,
    updateMeetingType,
    updateLocation,
  }),
);

class RightSide extends React.Component {
  constructor(props) {
    super(props);

    //region <Select box fields>
    const statusSB = new Element(); //TODO SB - selectBox dropdown
    const locationSB = new Element(); //TODO SB - selectBox dropdown
    const categorySB = new Element();
    const masterTypeSB = new Element();
    const dentistSB = new Element();
    const dentalPointSB = new Element();
    const lengthSB = new Element();
    const freeTimesSB = new Element();
    const date = new Element(moment(new Date()));

    const categoryServiceSB = new Element();
    const subCategoryServiceSB = new Element();
    const subSubCategoryServiceSB = new Element();
    const doctorsSB = new Element();
    const machinesSB = new Element();
    const statusOther = new Element();
    const approvalStatus = new Element();
    const labStatus = new Element();
    const meetingType = new Element();
    const paymentStatus = new Element();

    locationSB.handler = ({ target }) => {
      const { elements } = this.state;
      const { appointment, updateLocation } = this.props;
      elements.locationSB.selectedId = target.value;
      elements.locationSB.selected = Utils.getSelectedItem(elements.locationSB);
      this.setState({ elements });
      if (appointment?.status?.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE) {
        updateLocation(Utils.getSelectedItem(elements.locationSB));
      }
    };

    statusSB.handler = (value) => {
      const { item } = this.props;
      const { elements } = this.state;
      const { data } = this.state;

      if (this.noteRef) {
        this.noteRef.focus();
      }

      elements.statusSB.selectedId = value;
      elements.statusSB.selected = Utils.getSelectedItem(elements.statusSB);
      const statusCode = elements.statusSB.selected.code;
      if (
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED
      ) {
        elements.date.disabled = false;
        elements.categorySB.disabled = false;
        elements.dentistSB.disabled = false;
        elements.dentalPointSB.disabled = false;
        elements.lengthSB.disabled = false;
        elements.freeTimesSB.disabled = true;
      } else if (
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW
      ) {
        elements.categorySB.disabled = true;
        elements.dentistSB.disabled = true;
        elements.dentalPointSB.disabled = true;
        elements.lengthSB.disabled = true;
        elements.freeTimesSB.disabled = true;
      } else if (
        statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN &&
        statusCode !== STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT
      ) {
        elements.categorySB.disabled = false;
        elements.dentistSB.disabled = false;
        elements.dentalPointSB.disabled = false;
        elements.date.disabled = false;
        elements.lengthSB.disabled = false;
        elements.freeTimesSB.disabled = false;
      }

      this.statusLogic(false);

      const itemStatus = item ? item.status : null;

      if (itemStatus && itemStatus.code === STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING) {
        data.isNew = true;

        if (
          statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
          statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2 ||
          statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED
        ) {
          elements.freeTimesSB.disabled = false;
          elements.machinesSB.disabled = false;
          if (elements.freeTimesSB.options && !elements.freeTimesSB.options.length) {
            this.setState({ elements, data });
          }
        }
      } else {
        if (value) {
          this.populateFields();
        }
      }

      if (elements.masterTypeSB.selected.code === "PHONE_CONSULTATION") {
        elements.categorySB.disabled = true;
      }
      this.setState({ elements, data });

      this.updateParentData();
    };

    categorySB.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.categorySB.selectedId = e.target.value;
      elements.categorySB.selected = Utils.getSelectedItem(elements.categorySB);
      this.setState({ elements });
      this.updateParentData();
    };

    statusOther.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.statusOther.selectedId = e.target.value;
      elements.statusOther.selected = Utils.getSelectedItem(elements.statusOther);
      this.setState({ elements });
    };

    approvalStatus.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.approvalStatus.selectedId = e.target.value;
      elements.approvalStatus.selected = Utils.getSelectedItem(elements.approvalStatus);
      this.setState({ elements });
    };

    labStatus.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.labStatus.selectedId = e.target.value;
      elements.labStatus.selected = Utils.getSelectedItem(elements.labStatus);
      this.setState({ elements });
    };

    meetingType.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      const { appointment, updateMeetingType } = this.props;
      elements.meetingType.selectedId = e.target.value;
      elements.meetingType.selected = Utils.getSelectedItem(elements.meetingType);
      this.setState({ elements });
      if (appointment?.status?.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE) {
        updateMeetingType(Utils.getSelectedItem(elements.meetingType));
      }
    };

    paymentStatus.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      const { appointment, updatePaymentType } = this.props;
      elements.paymentStatus.selectedId = e.target.value;
      elements.paymentStatus.selected = Utils.getSelectedItem(elements.paymentStatus);
      this.setState({ elements });
      if (appointment?.status?.code === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE) {
        updatePaymentType(Utils.getSelectedItem(elements.paymentStatus));
      }
    };

    masterTypeSB.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.masterTypeSB.selectedId = e.target.value;
      elements.masterTypeSB.selected = Utils.getSelectedItem(elements.masterTypeSB);

      if (elements.masterTypeSB.selected.code === "PHONE_CONSULTATION") {
        elements.categorySB.disabled = true;
        elements.categorySB.selected = elements.categorySB.options.find(
          (item) => item.name == "Phone Consultation",
        );
      } else {
        elements.categorySB.disabled = false;
      }

      this.updateParentData();
    };

    dentalPointSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;
      const { getMachines } = this.props;

      const { elements } = this.state;

      elements.dentalPointSB.selectedId = value || -1;
      elements.dentalPointSB.selected = Utils.getSelectedItem(elements.dentalPointSB);
      this.getFreeTimes();

      if (_.toFinite(elements.dentalPointSB.selectedId) > 0) {
        getMachines(_.toFinite(elements.dentalPointSB.selectedId)).then(() => {
          const { machinesList } = this.props;

          elements.machinesSB.options = [
            PLEASE_SELECT_ITEM,
            { id: -100, name: "No device" },
          ].concat(machinesList);

          this.setState({ elements });
        });
      }

      elements.freeTimesSB.options = [];
      this.setState({ elements });

      this.updateParentData();
    };

    machinesSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;

      const { elements } = this.state;

      elements.machinesSB.selectedId = value === 0 ? value : value || -1;
      elements.machinesSB.selected = Utils.getSelectedItem(elements.machinesSB);
      this.getFreeTimes();
      this.updateParentData();
    };

    categoryServiceSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;
      const { getCategories } = this.props;

      const { elements } = this.state;

      elements.categoryServiceSB.selectedId = value;
      elements.categoryServiceSB.selected = Utils.getSelectedItem(elements.categoryServiceSB);

      if (_.toFinite(elements.categoryServiceSB.selectedId) > 0) {
        elements.doctorsSB.selected = "";
        elements.doctorsSB.options = [PLEASE_SELECT_ITEM];
        elements.subCategoryServiceSB.selected = "";
        elements.subCategoryServiceSB.options = [PLEASE_SELECT_ITEM];
        elements.subSubCategoryServiceSB.selected = "";
        elements.subSubCategoryServiceSB.options = [PLEASE_SELECT_ITEM];
        elements.machinesSB.selected = "";
        elements.machinesSB.options = [PLEASE_SELECT_ITEM, { id: -100, name: "No device" }];
        elements.dentalPointSB.selected = "";
        elements.dentalPointSB.options = [PLEASE_SELECT_ITEM];

        getCategories(_.toFinite(elements.categoryServiceSB.selectedId)).then(() => {
          const { subCategoryService, getDoctors, clinicId } = this.props;

          elements.subCategoryServiceSB.options = subCategoryService;

          this.setState({ elements });

          getDoctors(undefined, undefined, clinicId).then((response = []) => {
            elements.doctorsSB.options = [PLEASE_SELECT_ITEM, ...response];

            this.setState({ elements });

            const doctorId = _.toFinite(elements.doctorsSB.selectedId);

            if (doctorId > 0) {
              const { getDentalPoint } = this.props;

              getDentalPoint({ dentistId: doctorId }).then((response = []) => {
                elements.dentalPointSB.options = [PLEASE_SELECT_ITEM, ...response];

                this.setState({ elements });
              });
            }
          });
        });
      } else {
        this.setState({ elements });
      }

      this.updateParentData();
    };

    subCategoryServiceSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;
      const { getDoctors, getSubCategories, clinicId } = this.props;

      const { elements } = this.state;

      elements.subCategoryServiceSB.selectedId = value;
      elements.subCategoryServiceSB.selected = Utils.getSelectedItem(elements.subCategoryServiceSB);

      if (_.toFinite(elements.subCategoryServiceSB.selectedId) > 0) {
        getDoctors(_.toFinite(elements.subCategoryServiceSB.selectedId), undefined, clinicId).then(
          () => {
            const { doctorsList } = this.props;

            elements.doctorsSB.options = doctorsList;

            this.setState({ elements });
          },
        );
        getSubCategories(_.toFinite(elements.subCategoryServiceSB.selectedId)).then(() => {
          const { subSubCategoryService } = this.props;

          elements.subSubCategoryServiceSB.options = subSubCategoryService;

          this.setState({ elements });
        });
      } else {
        this.setState({ elements });
      }

      this.setState({ elements });
      this.updateParentData();
    };

    subSubCategoryServiceSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;

      const { elements } = this.state;

      elements.subSubCategoryServiceSB.selectedId = value;
      elements.subSubCategoryServiceSB.selected = Utils.getSelectedItem(
        elements.subSubCategoryServiceSB,
      );

      this.setState({ elements });
      this.updateParentData();
    };

    dentistSB.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { getDentalPoint } = this.props;
      const { elements } = this.state;

      elements.dentistSB.selectedId = elements.dentistSB.selected && elements.dentistSB.selected.id;
      if (e) {
        elements.dentistSB.selectedId = e.target.value;
      }
      elements.dentistSB.selected = Utils.getSelectedItem(elements.dentistSB);

      const transfer = { dentistId: elements.dentistSB.selectedId };
      elements.dentalPointSB.options = [];
      elements.freeTimesSB.options = [];
      this.setState({ elements });
      getDentalPoint(transfer).then(() => {
        const { item, dentalPointList, parentData } = this.props;
        elements.dentalPointSB.options = dentalPointList || [];
        const selectedId =
          item && item.dentalPoint ? item.dentalPoint.id : parentData.dentalPointId || 1;
        const arr = elements.dentalPointSB.options.filter((item) => {
          return selectedId == item.id;
        });
        const selectedItem = arr && arr.length && arr[0];
        elements.dentalPointSB.selectedId = selectedItem
          ? selectedItem.id
          : elements.dentalPointSB.options.length > 1
          ? elements.dentalPointSB.options[1].id
          : elements.dentalPointSB.options.length === 1
          ? elements.dentalPointSB.options[0].id
          : 0;
        elements.dentalPointSB = Utils.selectItem(elements.dentalPointSB, selectedItem, null, 1);
        this.setState({ elements });

        elements.lengthSB.handler();
        this.setState({ elements });
        // this.handleTimeChange();
      });

      this.updateParentData();
    };

    doctorsSB.handler = ({ target }) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { value } = target;
      const { getDentalPoint, getMachines } = this.props;

      const { elements } = this.state;

      elements.doctorsSB.selectedId = value;
      elements.doctorsSB.selected = Utils.getSelectedItem(elements.doctorsSB);

      const transfer = { dentistId: elements.doctorsSB.selectedId };

      elements.dentalPointSB.options = [];
      elements.freeTimesSB.options = [];

      this.setState({ elements });

      getDentalPoint(transfer).then(() => {
        const { item, dentalPointList, parentData } = this.props;
        elements.dentalPointSB.options = dentalPointList || [];
        const selectedId =
          item && item.dentalPoint ? item.dentalPoint.id : parentData.dentalPointId || 1;
        const arr = elements.dentalPointSB.options.filter((item) => {
          return selectedId == item.id;
        });
        const selectedItem = arr && arr.length && arr[0];

        elements.dentalPointSB.selectedId = selectedItem
          ? selectedItem.id
          : elements.dentalPointSB.options.length > 1
          ? elements.dentalPointSB.options[1].id
          : elements.dentalPointSB.options.length === 1
          ? elements.dentalPointSB.options[0].id
          : 0;
        elements.dentalPointSB = Utils.selectItem(elements.dentalPointSB, selectedItem, null, 1);

        if (_.toFinite(elements.dentalPointSB.selectedId) > 0) {
          getMachines(_.toFinite(elements.dentalPointSB.selectedId)).then(() => {
            const { machinesList } = this.props;

            elements.machinesSB.options = [
              PLEASE_SELECT_ITEM,
              { id: -100, name: "No device" },
            ].concat(machinesList);

            this.setState({ elements });
          });
        }

        this.setState({ elements });

        elements.lengthSB.handler();
        this.setState({ elements });
      });

      this.updateParentData();
    };

    lengthSB.handler = (value) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { item, location } = this.props;
      const { elements } = this.state;

      const query = parseQuery(location.search);

      elements.lengthSB = Utils.selectItem(
        elements.lengthSB,
        null,
        value ? value : item ? item.duration : query.duration || 0,
        null,
        1,
      );
      elements.freeTimesSB.selected = null;
      elements.freeTimesSB.options = [];
      this.getFreeTimes();
      this.setState({ elements });
      this.updateParentData();
    };

    freeTimesSB.handler = (e) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;

      elements.freeTimesSB.selectedId = e.target.value;
      elements.freeTimesSB.selected = Utils.getSelectedItem(elements.freeTimesSB);

      this.setState({ elements });
      this.updateParentData();
    };

    date.handler = (newDate) => {
      if (this.noteRef) {
        this.noteRef.focus();
      }
      const { elements } = this.state;
      elements.date.value = newDate;
      this.setState({ elements });
      lengthSB.handler(elements.lengthSB.selectedId);
      this.updateParentData();
    };

    const note = new Element("");
    note.handler = (e) => {
      note.value = e.target.value;
      this.updateParentData();
    };

    const additionalPrice = new Element();
    additionalPrice.handler = (value) => {
      // const { elements } = this.state;
      additionalPrice.value = value;
      this.updateParentData();
    };

    const doctorNote = new Element("");
    doctorNote.handler = (e) => {
      doctorNote.value = e.target.value;
      this.updateParentData();
    };

    //endregion
    const createdDate = new Element(new Date().valueOf());
    const modifiedDate = new Element();
    createdDate.visible = modifiedDate.visible = false;
    const creator = null;
    const moveBtn = new Element("Move");
    const copyBtn = new Element("Copy");
    const checkInBtn = new Element("Check-in");

    checkInBtn.handler = (e) => {
      e.preventDefault();

      const {
        appointment: { patient, id },
        patientInfo: { mandatoryFields = [] },
      } = this.props;

      if (!_.get(patient, "patientKey")) {
        const { mobilePhoneNumber = "", dateOfBirth, firstName, lastName, gender, email } = patient;
        const phoneNumber = mobilePhoneNumber.replace("+", "").replace("(", "-").replace(")", "-");

        this.props.history.push(
          createUrl(Routes.PatientDetailsEdit, {
            query: {
              appointmentId: id,
              lastName,
              firstName,
              phoneNumber,
              dateOfBirth,
              email,
              register: true,
              temporary: true,
              gender: _.get(gender, "id"),
              tempId: _.get(patient, "companyTempPatientId"),
            },
          }),
        );
      } else {
        if (this.props.miscSettings.notAllowCheckinAppointmentIfPatientMandatoryFieldsIsEmpty) {
          Utils.checkMandatoryFields(this.props.patientInfo.patient, mandatoryFields, (message) =>
            this.props.showMessage(message),
          ) === true && this.props.onSave(e, STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN);
        } else {
          this.props.onSave(e, STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN);
        }
      }
    };
    const assessmentBtn = new Element("Assessment");
    assessmentBtn.handler = (e) => {
      e.preventDefault();

      const {
        appointment: { patient },
      } = this.props;

      if (!_.get(patient, "patientKey")) {
        const { mobilePhoneNumber = "", dateOfBirth, firstName, lastName, gender } = patient;

        const phoneNumber = mobilePhoneNumber.replace("+", "").replace("(", "-").replace(")", "-");

        this.props.history.push(
          createUrl(Routes.PatientDetailsEdit, {
            query: {
              lastName,
              firstName,
              phoneNumber,
              dateOfBirth,
              register: true,
              temporary: true,
              gender: _.get(gender, "id"),
              tempId: _.get(patient, "companyTempPatientId"),
            },
          }),
        );
      } else {
        this.props.changeAppointmentStatus(STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT);
      }
    };
    const creatorTextField = new Element("");
    creatorTextField.disabled = true;
    const creatorLabelField = new Element("");
    moveBtn.visible = copyBtn.visible = checkInBtn.visible = false;
    creatorTextField.visible = creatorLabelField.visible = false;

    this.state = {
      infoMessage: "",
      plannedModal: false,
      smsReminderModalVisible: false,
      doctorsModalVisible: false,
      appointmentTreatmentsPlanModalVisible: false,
      appointmentLogsModalVisible: false,
      elements: {
        createdDate,
        modifiedDate,
        creator,
        statusSB,
        statusOther,
        approvalStatus,
        labStatus,
        meetingType,
        paymentStatus,
        locationSB,
        categorySB,
        masterTypeSB,
        dentistSB,
        dentalPointSB,
        lengthSB,
        freeTimesSB,
        moveBtn,
        copyBtn,
        checkInBtn,
        assessmentBtn,
        creatorTextField,
        creatorLabelField,
        date,
        note,
        doctorNote,
        machinesSB,
        doctorsSB,
        categoryServiceSB,
        subCategoryServiceSB,
        subSubCategoryServiceSB,
        addRescheduled: false,
        isNew: false,
        additionalPrice,
      },
      data: {
        id: 0,
        startedDeviceToken: null,
        checked1: false,
        checked2: false,
        actualStartTime: 0,
        timeLength: 25,
        startTime: new Date().valueOf(),
        addRescheduled: false,
        isFromMissed: false,
        appointmentItem: null,
        isNew: false,
      },
    };
  }

  isDiffArrays(a1, a2) {
    return _.isEmpty(a1.filter((i) => !a2.includes(i)).concat(a2.filter((i) => !a1.includes(i))));
  }

  componentDidUpdate(prevProps) {
    const {
      categoryService: nextCategoryService,
      subCategoryService: nextSubCategoryService,
      subSubCategoryService: nextSubSubCategoryService,
      machinesList: nextMachinesList,
      doctorsList: nextDoctorsList,
      locationList: nextLocationList,
      item,
      categoryService,
      subCategoryService,
      machinesList,
      doctorsList,
      subSubCategoryService,
      location: nextLocation,
      getInsuranceList,
      getAppointmentEligibilityDetails,
    } = this.props;
    const {
      locationList: prevLocationList,
      categoryService: prevCategoryService,
      subCategoryService: prevSubCategoryService,
      subSubCategoryService: prevSubSubCategoryService,
      machinesList: prevMachinesList,
      doctorsList: prevDoctorsList,
      item: prevItem,
    } = prevProps;
    const { elements } = this.state;

    const query = parseQuery(this.props.location.search);
    const nextQuery = parseQuery(nextLocation.search);

    if (item?.id !== prevItem?.id) {
      this.populateFields();
      if (item?.patient?.patientKey) {
        getInsuranceList({ patientKey: item.patient.patientKey });
      }
      if (item?.id && item?.eligibilityIdPayer) {
        getAppointmentEligibilityDetails({
          appointmentId: item.id,
          eligibilityIdPayer: Number((item.eligibilityIdPayer || "").replace(/[^\d]/g, "")),
        });
      }
    }

    if (
      query.canMove !== nextQuery.canMove &&
      nextQuery.canMove === "true" &&
      nextQuery.mode === "earlier"
    ) {
      this.onClickMoveAppointment();
    }

    if (!this.isDiffArrays(prevCategoryService, nextCategoryService)) {
      elements.categoryServiceSB.options = categoryService || [];
      elements.categoryServiceSB = Utils.selectItem(
        elements.categoryServiceSB,
        item && item.treatmentGroup,
      );
    }

    if (!this.isDiffArrays(prevSubCategoryService, nextSubCategoryService)) {
      elements.subCategoryServiceSB.options = subCategoryService || [];
      elements.subCategoryServiceSB = Utils.selectItem(
        elements.subCategoryServiceSB,
        item && item.treatmentSubGroup,
      );
    }

    if (!this.isDiffArrays(prevSubSubCategoryService, nextSubSubCategoryService)) {
      elements.subSubCategoryServiceSB.options = subSubCategoryService || [];
      elements.subSubCategoryServiceSB = Utils.selectItem(
        elements.subSubCategoryServiceSB,
        item && item.treatmentSubSubGroup,
      );
    }

    if (!this.isDiffArrays(prevLocationList, nextLocationList)) {
      elements.locationSB.options = nextLocationList || [];
      elements.locationSB = Utils.selectItem(elements.locationSB, item && item.location);
    }

    if (!this.isDiffArrays(prevMachinesList, nextMachinesList)) {
      elements.machinesSB.options =
        [PLEASE_SELECT_ITEM, { id: -100, name: "No device" }].concat(machinesList) || [];
      elements.machinesSB = Utils.selectItem(elements.machinesSB, item && item.machine);
    }

    if (!this.isDiffArrays(prevDoctorsList, nextDoctorsList)) {
      elements.doctorsSB.options = doctorsList || [];
      elements.doctorsSB = Utils.selectItem(elements.doctorsSB, item && item.dentist);
    }
  }

  UNSAFE_componentWillMount() {
    const { parentData } = this.props;
    const { elements } = this.state;
    const { data } = this.state;
    if (parentData) {
      elements.date.value = Utils.calcDateWithTimeZone(parentData.startTime);
      this.setState({ elements, data });
    }
    // getFormFields();
    this.props.remindersActions.resetReminders();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.fetchingInit !== newProps.fetchingInit && newProps.fetchingInit) {
      this.formFields();
    }
  }

  formFields = () => {
    const {
      clinic,
      members,
      chosenMemberId,
      parentData,
      categoryList,
      statusOtherList,
      statusApprovalList,
      statusLabList,
      appointmentMeetingTypes,
      appointmentPaymentStatuses,
      item,
      locationList,
      categoryService,
      subCategoryService,
      subSubCategoryService,
      masterTypes,
      machinesList,
      doctorsList,
      requestedAppointmentItem,
    } = this.props;
    const { elements } = this.state;
    const { data } = this.state;
    data.appointmentItem = item;

    elements.categorySB.options = categoryList || [];
    elements.categorySB = Utils.selectItem(elements.categorySB, item && item.category);

    elements.statusOther.options = statusOtherList || [];
    elements.statusOther = Utils.selectItem(elements.statusOther, item && item.statusOther);

    elements.approvalStatus.options = statusApprovalList || [];
    elements.approvalStatus = Utils.selectItem(
      elements.approvalStatus,
      item && item.approvalStatus,
    );

    elements.labStatus.options = statusLabList || [];
    elements.labStatus = Utils.selectItem(elements.labStatus, item && item.labStatus);
    elements.meetingType.options = appointmentMeetingTypes || [];
    elements.meetingType = Utils.selectItem(elements.meetingType, item && item.meetingType);
    elements.paymentStatus.options = appointmentPaymentStatuses || [];
    elements.paymentStatus = Utils.selectItem(elements.paymentStatus, item && item.paymentStatus);
    elements.paymentStatus.disabled =
      this.props.permissions.indexOf("EDIT_APPOINTMENT_PAID_STATUS") === -1;

    const query = parseQuery(this.props.location.search);

    if (query && query.masterType) {
      elements.masterTypeSB.selectedId = query.masterType;
      if (+elements.masterTypeSB.selectedId === 1) {
        elements.categorySB.selected = elements.categorySB.options.find(
          (item) => item.name === "Phone Consultation",
        );
      }
    }

    elements.masterTypeSB.options = masterTypes || [];
    elements.masterTypeSB = Utils.selectItem(elements.masterTypeSB, item && item.masterType);

    elements.categoryServiceSB.options = categoryService || [];
    elements.categoryServiceSB = Utils.selectItem(
      elements.categoryServiceSB,
      item && item.treatmentGroup,
    );

    elements.locationSB.options = locationList;
    elements.locationSB = Utils.selectItem(elements.locationSB, item && item.location);

    elements.subCategoryServiceSB.options = subCategoryService || [];
    elements.subCategoryServiceSB = Utils.selectItem(
      elements.subCategoryServiceSB,
      item && item.treatmentSubGroup,
    );

    elements.subSubCategoryServiceSB.options = subSubCategoryService || [];
    elements.subSubCategoryServiceSB = Utils.selectItem(
      elements.subSubCategoryServiceSB,
      item && item.treatmentSubSubGroup,
    );

    elements.machinesSB.options =
      [PLEASE_SELECT_ITEM, { id: -100, name: "No device" }].concat(machinesList) || [];
    elements.machinesSB = Utils.selectItem(elements.machinesSB, item && item.machine);

    elements.doctorsSB.options = doctorsList || [];
    elements.doctorsSB = Utils.selectItem(elements.doctorsSB, item && item.doctor);
    elements.dentistSB.options =
      (doctorsList || []).filter((item) => members[item.id] && members[item.id]?.status.id != 1) ||
      [];

    const selectedId = item && item.dentist ? item.dentist.id : parentData.dentistId || null;
    const arr = elements.dentistSB.options.filter((item) => {
      return selectedId === item.id;
    });
    const arr2 = elements.doctorsSB.options.filter((item) => {
      return selectedId === item.id;
    });

    const selectedItem = (arr && arr.length && arr[0]) || elements.dentistSB.options[1];
    elements.dentistSB = Utils.selectItem(elements.dentistSB, selectedItem, null, 1);
    const selectedItem2 = (arr2 && arr2.length && arr2[0]) || elements.doctorsSB.options[1];
    elements.doctorsSB = Utils.selectItem(elements.doctorsSB, selectedItem2, null, 1);
    elements.freeTimesSB.options = [];

    let i = 1;
    elements.lengthSB.options = [];
    elements.timeLength = clinic && parseInt(clinic.minTimeForVisit);
    while (elements.timeLength * i <= 720) {
      elements.lengthSB.options.push({
        id: elements.timeLength * i * 60 * 1000,
        name: i * elements.timeLength,
      });
      i++;
    }
    elements.lengthSB = Utils.selectItem(elements.lengthSB, item && item.duration, null, 1);
    elements.date.value = Utils.calcDateWithTimeZone(
      item && item.date ? item.date : parentData.startTime,
    );
    elements.note.value = (item && item.note) || requestedAppointmentItem?.notes || "";
    elements.additionalPrice.value = (item && item.additionalPrice) || "";
    elements.doctorNote.value = (item && item.doctorNote) || "";
    elements.creator = AppointmentUtils.isEdit(item) ? item.creator : members[chosenMemberId];
    if (elements.creator && elements.creator.firstName) {
      elements.creatorTextField.value = `${elements.creator.firstName} ${elements.creator.lastName}`;
    } else {
      elements.creatorTextField.value = "";
    }
    // if (this.props.allowAppointmentMultiBooking) {
    //   elements.dentalPointSB.disabled = true;
    // } else {
    //   elements.dentalPointSB.disabled = false;
    // }
    this.statusLogic(true);
    this.setState({ elements, data });

    const { dentist } = query;

    if (_.toFinite(dentist)) {
      if (AppointmentScreenTypes.AdvancedScreen) {
        elements.doctorsSB.handler({
          target: {
            value: _.toFinite(dentist) || chosenMemberId,
          },
        });
      }
      if (AppointmentScreenTypes.SimpleScreen) {
        elements.dentistSB.handler({
          target: {
            value: _.toFinite(dentist) || chosenMemberId,
          },
        });
      }
    } else {
      elements.dentistSB.handler();
    }

    this.updateParentData();
  };
  getFreeTimes = (isRenderFields = true) => {
    const { item, clinic, points, allowAppointmentMultiBooking } = this.props;
    const { elements } = this.state;

    if (elements.date.value && elements.lengthSB.selectedId) {
      const periodStart = getCompanyStartOfDay(elements.date.value, false, true);
      const periodEnd = getCompanyEndOfDay(periodStart);

      const appointmentScreenType = _.get(
        clinic,
        "appointmentScreenType.code",
        AppointmentScreenTypes.SimpleScreen,
      );

      const transfer = {
        periodEnd,
        periodStart,
        machineId: elements.machinesSB.selectedId !== -100 ? elements.machinesSB.selectedId : -1,
        appointmentId: item && item.id,
        duration: elements.lengthSB.selectedId,
        dentalPointId: elements.dentalPointSB.selected.id,
        dentistId:
          appointmentScreenType === AppointmentScreenTypes.AdvancedScreen
            ? elements.doctorsSB.selected.id
            : elements.dentistSB.selected.id,
      };
      const publicPoints = points["undefined"]?.dentalPoints;
      const publicPoint = (publicPoints || {})[transfer.dentalPointId];
      if (allowAppointmentMultiBooking && !publicPoint) {
        const promises = [];
        for (let [key, property] of Object.entries(points)) {
          if (Number(key) === transfer.dentistId) {
            Object.keys(property.dentalPoints || {}).forEach((id) => {
              transfer.dentalPointId = id;
              promises.push(this.props.getFreePoints(transfer));
            });
          }
        }
        Promise.all(promises)
          .then((responses) => {
            let freeTimes = [];
            responses.forEach((items) => {
              items.forEach((item) => {
                if (!freeTimes.find((x) => x.startTime === item.startTime)) {
                  item.dentalPointId = item.dentalPoint.id;
                  freeTimes.push(item);
                }
              });
            });
            this.props.stopLoader();
            this.updateFreeTimes(
              freeTimes.sort((a, b) => a.startTime - b.startTime),
              isRenderFields,
            );
          })
          .catch((error) => {
            this.props.stopLoader(error);
          });
      } else {
        this.props.getFreePoints(transfer).then((response) => {
          this.props.stopLoader();
          this.updateFreeTimes(response, isRenderFields);
        });
      }
    }
  };

  statusLogic = (isInit) => {
    const { item, statusList } = this.props;
    const { data } = this.state;
    const { elements } = this.state;
    // const item = data.appointmentItem;
    const isEdit = AppointmentUtils.isEdit(item);
    const appointmentStatus = isEdit ? item.status : null;
    elements.statusSB.options = isEdit ? [appointmentStatus] : [];
    const masterTypeCode = item && item.masterType && item.masterType.code;
    statusList.map((statusItem) => {
      if (isEdit) {
        const ALLOW_CHECK_IN_ON_ANY_STATUS = Boolean(
          this.props.miscSettings?.allowCheckInOnAnyStatus,
        );
        const NOT_BEING_SEEN_OR_NOT_COMPLETE =
          appointmentStatus.code !== STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN &&
          appointmentStatus.code !== STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE;
        const checkInBtnVisible = ALLOW_CHECK_IN_ON_ANY_STATUS && NOT_BEING_SEEN_OR_NOT_COMPLETE;
        elements.checkInBtn.visible = checkInBtnVisible;
        switch (
          appointmentStatus.code // current appointment status
        ) {
          case STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
          case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
          case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
          case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
          case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
          case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
          case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
          case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
          case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
          case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
              case STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
          case STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
            if (!item.patient.patientKey && !item.patient.companyTempPatientId) {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            } else if (masterTypeCode === MASTER_TYPES.PHONE_CONSULTATION) {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            } else {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
                case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2:
                case STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
            if (!item.patient.patientKey && !item.patient.companyTempPatientId) {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            } else if (masterTypeCode === MASTER_TYPES.PHONE_CONSULTATION) {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                case STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            } else {
              switch (statusItem.code) {
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
                case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
                case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
                case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
                case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
                case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
                case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2:
                case STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN:
                  elements.statusSB.options.push(statusItem);
                  break;
              }
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
              case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
              case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_WALK_IN:
                elements.statusSB.options.push(statusItem);
                break;
            }

            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER:
          case STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF:
          case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
              case STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING:
              case STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER:
              case STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
          case STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED:
            switch (statusItem.code) {
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
              case STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
              case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
              case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
              case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
              case STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED:
              case STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER:
              case STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF:
                elements.statusSB.options.push(statusItem);
                break;
            }
            break;
        }
      } else {
        if (elements.addRescheduled) {
          switch (statusItem.code) {
            case STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED:
              if (isInit) {
                elements.statusSB.selected = statusItem;
              }
              break;
          }
        } else
          switch (statusItem.code) {
            case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
            case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
            case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
            case STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING:
              elements.statusSB.options.push(statusItem);
              if (isInit && statusItem.code === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED) {
                elements.statusSB.selected = statusItem;
                elements.statusSB.selectedId = statusItem.id;
              }
              break;
          }
      }
    });
    if (isInit) {
      elements.statusSB = Utils.selectItem(elements.statusSB, item && item.status, null, 1);
      if (!elements.statusSB.selected) {
        elements.statusSB.selected = item && item.status;
      }
    }
    this.setState({ elements, data });
  };

  populateFields() {
    const { item, members, chosenMemberId } = this.props;
    const { data } = this.state;
    const { elements } = this.state;

    const isEdit = AppointmentUtils.isEdit(item);
    // const statusCode = elements.statusSB.selected.code;

    if (!this.props.masterTypeEnabled) {
      // elements.masterTypeSB.disabled = true;
    }

    if (isEdit && !data.isNew && !this.props.moved) {
      const statusCode = item.status.code;

      elements.masterTypeSB.disabled = true;

      elements.categoryServiceSB.disabled = true;
      elements.subCategoryServiceSB.disabled = true;
      elements.subSubCategoryServiceSB.disabled = true;
      elements.doctorsSB.disabled = statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      elements.machinesSB.disabled = true;
      elements.dentalPointSB.disabled = true;

      elements.statusSB.disabled = AppointmentUtils.isMissedOrConfirmedOrCompleteOrCancelledOrBeingSeen(
        statusCode,
      );
      elements.categorySB.disabled = AppointmentUtils.isMissedOrConfirmedOrCompleteOrCancelledOrCheckedInOrBeingSeen(
        statusCode,
      );
      elements.dentistSB.disabled =
        AppointmentUtils.isMissedOrConfirmedOrCompleteOrCancelledOrCheckedInOrBeingSeen(
          statusCode,
        ) || statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      elements.lengthSB.disabled =
        AppointmentUtils.isMissed(statusCode) ||
        AppointmentUtils.isComplete(statusCode) ||
        AppointmentUtils.isCanceled(statusCode) ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      elements.freeTimesSB.disabled =
        AppointmentUtils.isMissed(statusCode) ||
        AppointmentUtils.isComplete(statusCode) ||
        AppointmentUtils.isCanceled(statusCode) ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      elements.date.disabled =
        AppointmentUtils.isMissedOrConfirmedOrCompleteOrCancelledOrCheckedInOrBeingSeen(
          statusCode,
        ) || statusCode === STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      elements.note.disabled = AppointmentUtils.isComplete(statusCode);
      elements.doctorNote.disabled = AppointmentUtils.isComplete(statusCode);

      if (statusCode === "APPOINTMENT_STATUS_IN_WAITING") {
        elements.freeTimesSB.disabled = true;
        // elements.freeTimesSB.options = [];
      }
      elements.copyBtn.value = "Copy";
      elements.moveBtn.visible = false;
      elements.copyBtn.visible = isEdit;

      switch (statusCode) {
        case STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2:
        case STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN:
        case STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER:
        case STATUS_TYPES.APPOINTMENT_STATUS_SWITCHED_OFF:
        case STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W:
          elements.moveBtn.visible = true;
          break;

        case STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE:
          elements.statusSB.disabled = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          elements.statusSB.disabled = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          elements.statusSB.disabled = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
          elements.copyBtn.value = "Reschedule";
          elements.copyBtn.visible = true;
          break;
        case STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN:
        case STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT:
          elements.moveBtn.visible = true;
          break;
        default:
          break;
      }

      elements.checkInBtn.visible = false;
      if (
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2
      ) {
        const now = new Date();
        const diff = elements.startTime - Utils.toCompanyTimezone(now.getTime());
        elements.checkInBtn.visible = Math.abs(diff) <= MINUTE_FOR_VISIBLE;
      }
      elements.creatorLabelField.visible = isEdit;
      elements.creatorTextField.visible = !isEdit;
      elements.creator = isEdit ? item.creator : members[chosenMemberId];
      if (elements.creator && elements.creator.firstName) {
        elements.creatorTextField.value = `${elements.creator.firstName} ${elements.creator.lastName}`;
      } else {
        elements.creatorTextField.value = "";
      }

      if (
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED2 ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED_W ||
        statusCode === STATUS_TYPES.APPOINTMENT_STATUS_NO_ANSWER
      ) {
        elements.masterTypeSB.disabled = true;
        elements.statusSB.disabled = false;
        elements.categorySB.disabled = true;
        elements.dentistSB.disabled = true;
        elements.dentalPointSB.disabled = true;
        elements.lengthSB.disabled = true;
        elements.freeTimesSB.disabled = true;
        elements.date.disabled = true;
        elements.note.disabled = false;
        elements.doctorNote.disabled = false;
      }

      this.setState({ elements });
    }
  }

  updateFreeTimes(freePoints, isRenderFields = true) {
    const { parentData, item } = this.props;
    const { elements } = this.state;

    if (freePoints.length) {
      elements.freeTimesSB.disabled = false;

      if (
        _.get(this.state, "elements.statusSB.selected.code") === "APPOINTMENT_STATUS_IN_WAITING"
      ) {
        elements.freeTimesSB.disabled = true;
      }
      elements.freeTimesSB.options = freePoints.map((item) => {
        const start = Utils.calcDateWithTimeZone(item.startTime);
        const end = Utils.calcDateWithTimeZone(item.endTime);

        return {
          dentalPointId: item.dentalPointId,
          id: start.valueOf(),
          name: `${Utils.parseDateFormat(start)} - ${Utils.parseDateFormat(end)}${
            item.reserved ? ` ${item.reservedName}` : ""
          }`,
        };
      });
      const currentStartTime =
        parentData && parentData.startTime
          ? parentData.startTime
          : (item && item.startTime) || Utils.toCompanyTimezone(new Date().getTime());
      // elements.timeLength = clinic && parseInt(clinic.minTimeForVisit);

      const arr = elements.freeTimesSB.options.filter((x) => {
        // const currentMinutes = new Date(currentStartTime).getMinutes();
        const current = Utils.calcDateWithTimeZone(currentStartTime);
        const selectId = x.id || 0;

        return selectId - current.valueOf() >= 0;
      });

      elements.freeTimesSB = Utils.selectItem(
        elements.freeTimesSB,
        arr && arr.length && arr[0],
        0,
        1,
      );
      elements.startTime = elements.freeTimesSB.selectedId;
      this.setState({ elements });

      if (isRenderFields) {
        this.populateFields();
      }
    } else {
      if (
        _.get(this.state, "elements.statusSB.selected.code") !== "APPOINTMENT_STATUS_IN_WAITING" &&
        !this.props.isFollow
      ) {
        this.props.openSimpleModal({
          body: "Wrong timeslot",
        });
      }
      elements.freeTimesSB.disabled = true;
    }

    this.setState({ elements });
  }

  onClickCopyToNew = (event) => {
    const { item, updateRightFormData, changeItem } = this.props;

    const { data } = this.state;
    const { elements } = this.state;
    let addRescheduled = elements.addRescheduled || false;

    if (event) {
      event.preventDefault();
      switch (data.appointmentItem.status.code) {
        case STATUS_TYPES.APPOINTMENT_STATUS_MISSED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE:
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT:
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE:
        case STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED:
        case STATUS_TYPES.APPOINTMENT_STATUS_VOIDED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED:
        case STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW:
        case STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED:
          addRescheduled = true;
          break;
      }
      elements.statusSB.disabled = false;
      const statusCode = addRescheduled
        ? STATUS_TYPES.APPOINTMENT_STATUS_RESCHEDULED
        : STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      const status = Utils.selectByKey(this.props.statusList, "code", statusCode);
      if (!addRescheduled) {
        changeItem({ id: 0, status }, true);
        data.appointmentItem.id = 0;
      }
      item.eligibilityIdPayer = "";
      item.eligible = false;
      data.appointmentItem = item;
      data.appointmentItem.status = status;
      data.isNew = true;
      this.props.setEligibilityIdPayer("");
      this.props.setEligibilityStatus(undefined);

      this.props.updateAppointmentItem(data.appointmentItem);
      // this.statusLogic(false);
    } else {
      elements.statusSB.disabled = true;
    }
    elements.addRescheduled = addRescheduled;

    elements.lengthSB = Utils.selectItem(elements.lengthSB, null, item && item.duration, null, 1);

    if (!addRescheduled) {
      elements.categorySB = Utils.selectPleaseSelect(elements.categorySB);
      elements.machinesSB = Utils.selectPleaseSelect(elements.machinesSB);
      elements.masterTypeSB = Utils.selectPleaseSelect(elements.masterTypeSB);
      elements.categoryServiceSB = Utils.selectPleaseSelect(elements.categoryServiceSB);
      elements.subCategoryServiceSB = Utils.selectPleaseSelect(elements.subCategoryServiceSB);
      elements.subSubCategoryServiceSB = Utils.selectPleaseSelect(elements.subSubCategoryServiceSB);
      elements.note.value = "";
      elements.doctorNote.value = "";
    }
    elements.categorySB.disabled = false;
    elements.dentistSB.disabled = false;
    elements.dentalPointSB.disabled = false;
    elements.date.disabled = false;
    elements.lengthSB.disabled = false;
    elements.freeTimesSB.disabled = false;
    elements.note.disabled = false;
    elements.doctorNote.disabled = false;
    elements.masterTypeSB.disabled = false;
    elements.categoryServiceSB.disabled = false;
    elements.machinesSB.disabled = false;

    elements.categoryServiceSB.disabled = false;
    elements.subCategoryServiceSB.disabled = false;
    elements.subSubCategoryServiceSB.disabled = false;
    elements.doctorsSB.disabled = false;
    elements.machinesSB.disabled = false;

    elements.dentalPointSB.disabled = false;

    elements.isNew = true;

    elements.copyBtn.visible = false;
    elements.moveBtn.visible = false;

    this.setState({ elements, data });

    updateRightFormData(this.state);

    this.configureControlsAfterCopy();

    // this.populateFields();
  };

  onClickMoveAppointment = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (!this.props.permissions.includes("MOVE_CLINIC_APPOINTMENT")) {
      this.props.openSimpleModal({
        body: "Permission denied to move appointment",
        footer: (
          <button className="btn btn-primary pull-right" onClick={this.props.closeSimpleModal}>
            Close
          </button>
        ),
      });
      return;
    }

    this.props.moveAppointment();

    const { data } = this.state;
    const { elements } = this.state;

    const currentStatus = elements.statusSB.selected.code;

    const { item, updateRightFormData } = this.props;

    if (currentStatus === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN) {
      const status = Utils.selectByKey(this.props.statusList, "code", currentStatus);
      const statusAssesment = Utils.selectByKey(
        this.props.statusList,
        "code",
        STATUS_TYPES.APPOINTMENT_STATUS_ASSESSMENT,
      );

      elements.dentistSB.disabled = false;
      elements.doctorsSB.disabled = false;
      elements.statusSB.disabled = false;
      elements.statusSB.options = [];
      elements.statusSB.options.push(status);
      elements.statusSB.options.push(statusAssesment);
      elements.statusSB.selected = status;
    } else {
      const statusCode = STATUS_TYPES.APPOINTMENT_STATUS_UNCONFIRMED;
      const status = Utils.selectByKey(this.props.statusList, "code", statusCode);
      const statusWaiting = Utils.selectByKey(
        this.props.statusList,
        "code",
        STATUS_TYPES.APPOINTMENT_STATUS_IN_WAITING,
      );
      const statusConfirmed = Utils.selectByKey(
        this.props.statusList,
        "code",
        STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED,
      );

      elements.dentistSB.disabled = false;
      elements.doctorsSB.disabled = false;
      elements.statusSB.disabled = false;
      elements.statusSB.options = [];
      elements.statusSB.options.push(statusWaiting);
      elements.statusSB.options.push(status);
      elements.statusSB.options.push(statusConfirmed);
      elements.statusSB.selected = status;
      elements.statusSB.selectedId = status.id;
    }

    data.appointmentItem = item;
    this.props.updateAppointmentItem(data.appointmentItem);

    elements.categorySB.disabled = false;
    elements.dentalPointSB.disabled = false;
    elements.date.disabled = false;
    elements.lengthSB.disabled = false;
    elements.freeTimesSB.disabled = false;
    elements.masterTypeSB.disabled = false;
    elements.categoryServiceSB.disabled = false;

    elements.categoryServiceSB.disabled = false;
    elements.subCategoryServiceSB.disabled = false;
    elements.subSubCategoryServiceSB.disabled = false;
    elements.machinesSB.disabled = false;
    elements.dentalPointSB.disabled = false;

    elements.copyBtn.visible = false;
    elements.moveBtn.visible = false;

    this.setState({ elements, data });
    updateRightFormData(this.state);
  };

  configureControlsAfterCopy() {
    const { elements } = this.state;

    this.statusLogic(true);
    elements.addRescheduled = false;
    elements.creatorLabelField.visible = false;
    elements.creatorTextField.visible = false;
    elements.createdDate.visible = false;
    elements.modifiedDate.visible = false;

    this.setState({ elements });
  }

  clearFields() {
    const { item } = this.props;
    const { elements } = this.state;

    if (!AppointmentUtils.isEdit(item)) {
      elements.categorySB = Utils.selectPleaseSelect(elements.categorySB);
      elements.statusOther = Utils.selectPleaseSelect(elements.statusOther);
      elements.approvalStatus = Utils.selectPleaseSelect(elements.approvalStatus);
      elements.labStatus = Utils.selectPleaseSelect(elements.labStatus);
      elements.meetingType = Utils.selectPleaseSelect(elements.meetingType);
      elements.paymentStatus = Utils.selectPleaseSelect(elements.paymentStatus);
      elements.masterTypeSB = Utils.selectPleaseSelect(elements.masterTypeSB);
      elements.categoryServiceSB = Utils.selectPleaseSelect(elements.categoryServiceSB);
      elements.subCategoryServiceSB = Utils.selectPleaseSelect(elements.subCategoryServiceSB);
      elements.subSubCategoryServiceSB = Utils.selectPleaseSelect(elements.subSubCategoryServiceSB);
      elements.machinesSB = Utils.selectPleaseSelect(elements.machinesSB);
      elements.dentistSB = Utils.selectPleaseSelect(elements.dentistSB);
      elements.dentalPointSB = Utils.selectPleaseSelect(elements.dentalPointSB);
      elements.statusSB = Utils.selectPleaseSelect(elements.statusSB);
      elements.locationSB = Utils.selectPleaseSelect(elements.locationSB);
      elements.lengthSB = Utils.selectPleaseSelect(elements.lengthSB);
      elements.date.value = null;
      elements.freeTimesSB = Utils.selectPleaseSelect(elements.freeTimesSB);

      elements.note.value = "";
      elements.doctorNote.value = "";
    }
    this.setState({ elements });
  }

  handleRepeatClick = (index, repeatClickType, id) => {
    if (repeatClickType === "repeat")
      this.props.appointmentActions.repeatAppointmentRepeatModalShow(index);
    else {
      this.props.repeatAppointmentSetDeleteId(id);
      this.props.appointmentActions.repeatAppointmentConfirmModalShow();
    }
  };

  handleDeleteClick = () => {
    this.props.appointmentActions.repeatAppointmentConfirmModalHide();
    this.props.appointmentActions.repeatAppointmentDeleteAfter();
  };

  updateParentData = () => {
    const { childActions, updateRightFormData } = this.props;

    const { state } = this;

    state.data.startTime = state.elements.freeTimesSB.selectedId;

    childActions.onDelete(() => {
      this.clearFields();
    }, FORM_ACTIONS.DELETE);

    this.setState({ elements: state.elements, data: state.data });

    updateRightFormData(state);
  };

  showLog = () => {
    const { item } = this.props;

    this.props
      .getHistory({ id: this.props.appointmentId })
      .then((response) => {
        const array = response || [];

        array.push({
          modifiedMember: _.get(this.props.appointment, "creator"),
          modifiedDate: _.get(item, "createdDate"),
          status: {
            name: "Created",
          },
        });

        const $array = array.map((item, index) => {
          const apptStatus = _.get(item, "status.name");
          const modifiedDate = _.get(item, "modifiedDate");
          const modifiedMemberName = _.get(item, "modifiedMember.name");

          return (
            <div key={index}>
              <label>
                <span>{apptStatus} User:</span>
                <span> {_.get(item, "modifiedMember.name")}</span>
              </label>
              <label>
                <span>{apptStatus} Date:</span>
                <span> {modifiedDate ? getCompanyFormatFullDate(modifiedDate) : null}</span>
              </label>
              {item.moved && (
                <label>
                  <span>Moved User:</span>
                  <span> {modifiedMemberName}</span>
                </label>
              )}
              {item.moved && (
                <label>
                  <span>Moved Date:</span>
                  <span> {modifiedDate ? getCompanyFormatFullDate(modifiedDate) : null}</span>
                </label>
              )}
            </div>
          );
        });

        const body = <div>{$array}</div>;

        this.props.openSimpleModal({
          header: "Appointment log",
          body,
        });
      })
      .catch((e) => {
        this.props.openSimpleModal({
          header: "Appointment log",
          body: e,
        });
      });
  };

  showRemindersLog = () => {
    this.props
      .getAppointmentRemindersLog({ id: this.props.appointmentId })
      .then((response = []) => {
        const $array = response.map((item, index) => {
          const type = _.get(item, "category.name");
          const template = _.get(item, "templateName");
          const sentTime = _.get(item, "sentTime");

          return (
            <div
              key={index}
              className="d-flex"
              style={{ border: "1px solid #e5e5e5", borderTop: "none" }}
            >
              <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
                {type}
              </span>
              <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
                {template}
              </span>
              <span className="p-1" style={{ flex: 1 }}>
                {getCompanyFormatFullDate(sentTime)}
              </span>
            </div>
          );
        });

        const body = (
          <div>
            <div className="d-flex" style={{ border: "1px solid #e5e5e5" }}>
              <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
                Template type:{" "}
              </span>
              <span className="p-1" style={{ borderRight: "1px solid #e5e5e5", flex: 2 }}>
                Template:{" "}
              </span>
              <span className="p-1" style={{ flex: 1 }}>
                Sent time:{" "}
              </span>
            </div>
            <div>{$array}</div>
          </div>
        );

        this.props.openSimpleModal({
          header: "Reminder log",
          body,
          size: "lg",
        });
      })
      .catch((e) => {
        this.props.openSimpleModal({
          header: "Appointment log",
          body: e,
        });
      });
  };

  nurseDisabled = (user, state) => {
    if (!user) {
      switch (_.get(this.props.item, "status.code")) {
        case STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE:
          return true;
      }
    } else {
      return state;
    }

    return false;
  };

  shouldShowDetails = (isFollow, showEligibility, insuranceFormVisible) => {
    return isFollow && !showEligibility && !insuranceFormVisible;
  };

  render() {
    const { data } = this.state;
    const { elements } = this.state;
    const {
      miscSettings: {
        appointmentStatusOtherEnabled = false,
        appointmentApprovalStatusEnabled = false,
        appointmentLabStatusEnabled = false,
        appointmentLocationFieldEnabled = false,
        additionalDoctorsAppointmentEnabled = false,
        appointmentMeetingTypeEnabled = false,
        appointmentPaymentStatusEnabled = false,
      },
      appointment,
      reminders: { templatesList, remindersModalVisible, notificationType, templateLabel },
      remindersActions,
      showEligibility,
      onHideEligibilityDetails,
      insurance,
      item,
      guardianNeeded,
      guardianInformed,
      setGuardianNeeded,
      setGuardianInformed,
      disableAdditionalChargesFieldForTempPatient,
      insuranceFormVisible,
    } = this.props;

    const ADDITIONAL_CHARGES_FIELD_DISABLE = item?.patient?.patientKey
      ? this.props.permissions.indexOf("ADD_ADDITIONAL_CHARGES_TO_PAYMENT_LINK") === -1 ||
        !item?.patient?.patientKey
      : item?.patient?.companyTempPatientId
      ? false
      : disableAdditionalChargesFieldForTempPatient;

    const { appointmentItem } = data;
    const patient = {};
    if (appointmentItem && appointmentItem.patient) {
      appointmentItem?.patient?.companyPatientId
        ? (patient[appointmentItem.patient.companyPatientId] = {
            appointmentId: appointmentItem.id,
            companyPatientId: appointmentItem.patient.companyPatientId,
            firstName: appointmentItem.patient.firstName,
            lastName: appointmentItem.patient.lastName,
            // code: appointmentItem && appointmentItem.patient.chartNumber,
          })
        : (patient[appointmentItem.patient.companyTempPatientId] = {
            appointmentId: appointmentItem.id,
            companyTempPatientId: appointmentItem.patient.companyTempPatientId,
            firstName: appointmentItem.patient.firstName,
            lastName: appointmentItem.patient.lastName,
            // code: appointmentItem && appointmentItem.patient.chartNumber,
          });
    }
    const dentistListOptions = (this.props.doctors || [])
      .filter((x) => x?.subType?.code === "NURSE")
      .map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }));

    const appointmentScreenType = _.get(
      this.props,
      "clinic.appointmentScreenType.code",
      AppointmentScreenTypes.SimpleScreen,
    );

    const isLightHouse =
      _.get(this.props, "company.id") === 106 &&
      _.get(this.props.item, "status.code") === STATUS_TYPES.APPOINTMENT_STATUS_CONFIRMED;

    const checkedInButtonVisible = isLightHouse || elements.checkInBtn.visible;
    if (
      checkedInButtonVisible &&
      !(elements.statusSB?.options || []).find(
        (x) => x.code === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
      )
    ) {
      const { elements } = this.state;
      const { statusList } = this.props;
      const checkedInStatus = statusList.find(
        (x) => x.code === STATUS_TYPES.APPOINTMENT_STATUS_CHECKED_IN,
      );
      elements.statusSB.options.push(checkedInStatus);
      this.setState({ elements });
    }
    return (
      <div className="right-side">
        <div className="container-fluid">
          <Show if={this.state.appointmentLogsModalVisible}>
            <AppointmentLogsModal
              logsModalVisible={this.state.appointmentLogsModalVisible}
              item={item}
              appointment={appointment}
              appointmentId={this.props.appointmentId}
              setAppointmentsLogModalVisible={() =>
                this.setState({ appointmentLogsModalVisible: false })
              }
            />
          </Show>
          <Show if={this.state.doctorsModalVisible}>
            <AppointmentAdditionalDoctorsModal
              elements={elements}
              doctorsModalVisible={this.state.doctorsModalVisible}
              setDoctorsModalVisible={(doctorsModalVisible) =>
                this.setState({ doctorsModalVisible })
              }
              appointment={item}
              isEdit={item?.id}
              moved={this.props.moved}
            />
          </Show>
          <Show if={this.state.appointmentTreatmentsPlanModalVisible}>
            <AppointmentTreatmentsPlanModal
              onSave={this.props.onSavePlanAppointments}
              elements={elements}
              appointmentTreatmentsPlanModalVisible={
                this.state.appointmentTreatmentsPlanModalVisible
              }
              setAppointmentTreatmentsPlanModalVisible={(appointmentTreatmentsPlanModalVisible) =>
                this.setState({ appointmentTreatmentsPlanModalVisible })
              }
              appointment={item}
              isEdit={item?.id}
              moved={this.props.moved}
            />
          </Show>
          {showEligibility && (
            <EligibilityDetailsModalWrapper
              patient={item?.patient}
              show={showEligibility}
              appointment={appointment}
              insuranceList={insurance.list || []}
              onHide={onHideEligibilityDetails}
              setEligibilityStatus={({ eligible, id }) => {
                this.props.setEligibilityStatus(eligible);
                this.props.updateAppointmentEligibilityStatus({ eligible, id });
              }}
            />
          )}
          {this.shouldShowDetails(!this.props.isFollow, showEligibility, insuranceFormVisible) && (
            <div>
              {appointmentScreenType === AppointmentScreenTypes.AdvancedScreen && (
                <div>
                  <div className="row" style={{ zIndex: 60 }}>
                    <h4 style={{ marginLeft: "10px", color: "rgb(190, 200, 68)" }}>
                      To narrow down your doctor selection - Select group/sub-group
                    </h4>
                  </div>

                  <div className="row" style={{ zIndex: 50 }}>
                    <div className="col-sm-4">
                      <label>
                        <span className="label">Group:</span>

                        <CustomSelectBox selectBox={elements.categoryServiceSB} />
                      </label>
                    </div>

                    <div className="col-sm-4">
                      <label>
                        <span className="label">Sub Group:</span>

                        <CustomSelectBox selectBox={elements.subCategoryServiceSB} />
                      </label>
                    </div>

                    <div className="col-sm-4">
                      <label>
                        <span className="label">Sub Sub Group:</span>

                        <CustomSelectBox selectBox={elements.subSubCategoryServiceSB} />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{ borderBottom: "3px solid rgb(190, 200, 68)" }}
                    className="mt-3 mb-3"
                  />
                  <div className="row">
                    <div className="col-sm-8 pr-4">
                      {/*// col 8*/}
                      <div className="row">
                        <div
                          className="col-sm-12"
                          style={{ paddingLeft: "15px", paddingRight: "15px" }}
                        >
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="d-flex flex-column">
                                <div className="flex-grow-1 flex-shrink-1">
                                  <label className="flex-grow-1 flex-shrink-1">
                                    <span className="label">Appointment type:</span>

                                    <CustomSelectBox
                                      searchable={true}
                                      selectBox={elements.categorySB}
                                    />
                                  </label>
                                </div>

                                <div className="flex-grow-1 flex-shrink-1">
                                  <label className="flex-grow-1 flex-shrink-1">
                                    <span className="label">Doctor/Therapist/Nurse:</span>
                                    <CustomSelectBox
                                      searchable={true}
                                      selectBox={elements.doctorsSB}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-8 note-row">
                              <label>
                                <span className="label">Appointment type notes:</span>
                                <textarea
                                  disabled={true}
                                  rows={5}
                                  style={{ height: "121px" }}
                                  value={
                                    elements.categorySB?.selected?.description ||
                                    "Please select category"
                                  }
                                  name="appointmentTypeNotes"
                                  className="form-control"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">Trx. Room:</span>

                            <CustomSelectBox searchable={true} selectBox={elements.dentalPointSB} />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">Supervising Member:</span>
                            <SelectPicker
                              size="lg"
                              placeholder="Please Select"
                              value={{ value: this.props.nurse }}
                              options={dentistListOptions}
                              onChange={(v) => this.props.changeNursery(v)}
                              disabled={this.nurseDisabled()}
                            />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">Medical Device:</span>
                            <CustomSelectBox searchable={true} selectBox={elements.machinesSB} />
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <FieldContainer label="Date:">
                            <DatePicker
                              size="lg"
                              placeholderText={PLEASE_SELECT}
                              selected={elements.date.value}
                              disabled={elements.date.disabled}
                              onChange={elements.date.handler}
                            />
                          </FieldContainer>
                        </div>
                        <div className="col-sm-6">
                          <label className="m-0">
                            <span className="label">Length:</span>
                          </label>
                          <AppointmentLengthButtons lengthSB={elements.lengthSB} />
                        </div>
                        <div className="col-sm-3">
                          <label>
                            <span className="label">Time:</span>
                            <CustomSelectBox selectBox={elements.freeTimesSB} />
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <label>
                            <span className="label">Appointment status:</span>
                          </label>
                          <div>
                            <AppointmentStatusButtons
                              requestedAppointmentItem={this.props.requestedAppointmentItem}
                              elements={elements}
                              checkedInButtonVisible={checkedInButtonVisible}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "3px solid rgb(190, 200, 68)" }}
                        className="mt-3 mb-3"
                      />
                      <div className="row" style={{ zIndex: 11 }}>
                        {appointmentStatusOtherEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Others:</span>
                              <CustomSelectBox selectBox={elements.statusOther} />
                            </label>
                          </div>
                        )}
                        {appointmentApprovalStatusEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Approvals:</span>
                              <CustomSelectBox selectBox={elements.approvalStatus} />
                            </label>
                          </div>
                        )}
                        {appointmentLabStatusEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Lab:</span>
                              <CustomSelectBox selectBox={elements.labStatus} />
                            </label>
                          </div>
                        )}
                        {appointmentLocationFieldEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Location:</span>
                              <CustomSelectBox selectBox={elements.locationSB} />
                            </label>
                          </div>
                        )}
                        <div className="col-sm-3">
                          <label>
                            <span className="label">Master Type:</span>

                            <CustomSelectBox selectBox={elements.masterTypeSB} />
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <label>
                            <span className="label">{localStorage.getItem("nurseLabel")}:</span>
                            <SelectPicker
                              size="lg"
                              placeholder="Please Select"
                              options={dentistListOptions}
                              disabled={this.nurseDisabled()}
                              value={{ value: this.props.nurse }}
                              onChange={(v) => this.props.changeNursery(v)}
                            />
                          </label>
                        </div>
                        {/*<Show if={additionalDoctorsAppointmentEnabled}>*/}
                        {/*  <div className="col-sm-3 mt-3">*/}
                        {/*    <Checkbox*/}
                        {/*      checked={guardianNeeded}*/}
                        {/*      onChange={() => {*/}
                        {/*        setGuardianNeeded(!guardianNeeded);*/}
                        {/*      }}*/}
                        {/*      label="Guardian Needed"*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*</Show>*/}
                        {/*<Show if={additionalDoctorsAppointmentEnabled && guardianNeeded}>*/}
                        {/*  <div className="col-sm-3 mt-3">*/}
                        {/*    <Checkbox*/}
                        {/*      checked={guardianInformed}*/}
                        {/*      onChange={() => {*/}
                        {/*        setGuardianInformed(!guardianInformed);*/}
                        {/*      }}*/}
                        {/*      label="Guardian Informed"*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*</Show>*/}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      {/*// col 4*/}
                      <div className="row">
                        <div className="col-sm-8">
                          <label>
                            <span className="label">Appointment notes:</span>
                            <textarea
                              disabled={
                                elements.note.disabled ||
                                this.props.permissions.indexOf("EDIT_CLINIC_APPOINTMENT_NOTE") ===
                                  -1
                              }
                              rows={5}
                              value={elements.note.value}
                              onChange={(e) => elements.note.handler(e)}
                              name="appointmentNotes"
                              className="form-control"
                              style={{ height: "246px" }}
                            />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          {/*buttons*/}
                          {/*<Show*/}
                          {/*  if={*/}
                          {/*    this.props.appointmentId &&*/}
                          {/*    _.get(this.props.appointment, "patient.patientKey")*/}
                          {/*  }*/}
                          {/*>*/}
                          <button
                            type="button"
                            className="btn btn-success btn-lg full-width mt-3"
                            onClick={() => {
                              remindersActions.remindersModalShow();
                              remindersActions.setRecipientType("patient");
                              remindersActions.setPatientMode("calendar");
                              remindersActions.initPatient();
                              if (appointment && appointment.patient) {
                                remindersActions.togglePatient(
                                  false,
                                  appointmentItem && appointmentItem.patient.companyPatientId,
                                  appointment && appointment.patient,
                                  appointmentItem && appointmentItem.patient.companyPatientId,
                                );
                              }
                            }}
                          >
                            Reminder
                          </button>
                          {/*</Show>*/}
                          <Show if={elements.moveBtn.visible}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              disabled={elements.moveBtn.disabled}
                              onClick={this.onClickMoveAppointment}
                            >
                              {elements.moveBtn.value}
                            </button>
                          </Show>
                          <Show if={elements.copyBtn.visible}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              disabled={elements.copyBtn.disabled}
                              onClick={this.onClickCopyToNew}
                            >
                              {elements.copyBtn.value}
                            </button>
                          </Show>
                          <Show if={this.props.appointmentId}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              type="button"
                              onClick={this.props.repeatAppointmentModalShow}
                            >
                              Repeat
                            </button>
                          </Show>
                          <Show if={this.props.appointmentId}>
                            <button
                              type="button"
                              className="btn btn-success btn-lg full-width"
                              onClick={() => this.showLog()}
                            >
                              LOG
                            </button>
                          </Show>
                        </div>
                        <div className="col-sm-8">
                          <label>
                            <span className="label">Doctor notes:</span>

                            <textarea
                              rows={5}
                              disabled={
                                this.props.permissions.indexOf("EDIT_APPOINTMENT_DOCTOR_NOTE") ===
                                -1
                              }
                              value={elements.doctorNote.value}
                              onChange={(e) => elements.doctorNote.handler(e)}
                              name="doctorNote"
                              className="form-control"
                              style={{ height: "246px" }}
                            />
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <Show
                            if={
                              this.props.appointmentId &&
                              _.get(this.props.appointment, "patient.patientKey")
                            }
                          >
                            <button
                              className="btn btn-success btn-lg btn-block"
                              type="button"
                              onClick={this.props.showCodesForThisModal}
                            >
                              Trx codes for this appointment: {this.props.codesForThisTotalCount}
                            </button>
                          </Show>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {appointmentScreenType === AppointmentScreenTypes.SimpleScreen && (
                <div>
                  <div className="row">
                    <div className="col-sm-8 pr-4">
                      {/*// col 8*/}
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="d-flex flex-row">
                            <div className="flex-grow-1 flex-shrink-1">
                              <label className="flex-grow-1 flex-shrink-1">
                                <span className="label">Appointment type:</span>

                                <CustomSelectBox
                                  searchable={true}
                                  selectBox={elements.categorySB}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <div className="flex-grow-1 flex-shrink-1">
                              <label className="flex-grow-1 flex-shrink-1">
                                <button
                                  type="button"
                                  className="btn btn-success btn-block mt-2"
                                  disabled={!additionalDoctorsAppointmentEnabled}
                                  onClick={() => this.setState({ doctorsModalVisible: true })}
                                >
                                  {"Additional doctors"}{" "}
                                  {item?.linkedItems ? " ( " + item.linkedItems.length + " )" : ""}
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 note-row" style={{ marginBottom: "2rem" }}>
                          <label>
                            <span className="label">Appointment type notes:</span>

                            <textarea
                              disabled={true}
                              rows={5}
                              value={
                                elements.categorySB?.selected?.description ||
                                "Please select category"
                              }
                              name="appointmentTypeNotes"
                              className="form-control"
                            />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">{localStorage.getItem("doctorLabel")}:</span>
                            <CustomSelectBox searchable={true} selectBox={elements.dentistSB} />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">Trx. Room:</span>

                            <CustomSelectBox searchable={true} selectBox={elements.dentalPointSB} />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>
                            <span className="label">Medical Device:</span>
                            <CustomSelectBox searchable={true} selectBox={elements.machinesSB} />
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <FieldContainer label="Date:">
                            <DatePicker
                              size="lg"
                              placeholderText={PLEASE_SELECT}
                              selected={elements.date.value}
                              disabled={elements.date.disabled}
                              onChange={(value) => {
                                elements.date.handler(value);
                                this.setState({ datePickerVisible: false });
                              }}
                              open={this.state.datePickerVisible}
                              onInputClick={() => this.setState({ datePickerVisible: true })}
                              onClickOutside={() => this.setState({ datePickerVisible: false })}
                              calendarContainer={(props) => (
                                <AppointmentAdditionalDates
                                  {...props}
                                  selectDate={elements.date.handler}
                                  onClose={() => this.setState({ datePickerVisible: false })}
                                />
                              )}
                            />
                          </FieldContainer>
                        </div>
                        <div className="col-sm-6">
                          <label className="m-0">
                            <span className="label">Length:</span>
                          </label>
                          <AppointmentLengthButtons lengthSB={elements.lengthSB} />
                        </div>
                        <div className="col-sm-3">
                          <label>
                            <span className="label">Time:</span>
                            <CustomSelectBox selectBox={elements.freeTimesSB} />
                          </label>
                        </div>
                        <div className="col-sm-1" style={{ marginTop: 18 }}>
                          <label>
                            <span className="label"></span>
                            <TimeSlotPicker
                              select={elements.freeTimesSB}
                              elements={elements}
                              item={this.props.item}
                            />
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <label>
                            <span className="label">Appointment status:</span>
                          </label>
                          <div>
                            <AppointmentStatusButtons
                              requestedAppointmentItem={this.props.requestedAppointmentItem}
                              elements={elements}
                              checkedInButtonVisible={checkedInButtonVisible}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "3px solid rgb(190, 200, 68)" }}
                        className="mt-3 mb-3"
                      />
                      <div className="row" style={{ zIndex: 11 }}>
                        {appointmentStatusOtherEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Others:</span>
                              <CustomSelectBox selectBox={elements.statusOther} />
                            </label>
                          </div>
                        )}
                        {appointmentApprovalStatusEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Approvals:</span>
                              <CustomSelectBox selectBox={elements.approvalStatus} />
                            </label>
                          </div>
                        )}
                        {appointmentLabStatusEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Status Lab:</span>
                              <CustomSelectBox selectBox={elements.labStatus} />
                            </label>
                          </div>
                        )}
                        {appointmentMeetingTypeEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Meeting type:</span>
                              <CustomSelectBox selectBox={elements.meetingType} />
                            </label>
                          </div>
                        )}
                        {appointmentPaymentStatusEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Payment Status:</span>
                              <CustomSelectBox selectBox={elements.paymentStatus} />
                            </label>
                          </div>
                        )}
                        {appointmentLocationFieldEnabled && (
                          <div className="col-sm-3">
                            <label>
                              <span className="label">Location:</span>

                              <CustomSelectBox selectBox={elements.locationSB} />
                            </label>
                          </div>
                        )}
                        <div className="col-sm-3">
                          <label>
                            <span className="label">Master Type:</span>

                            <CustomSelectBox selectBox={elements.masterTypeSB} />
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <label>
                            <span className="label">{localStorage.getItem("nurseLabel")}:</span>
                            <SelectPicker
                              size="lg"
                              placeholder="Please Select"
                              options={dentistListOptions}
                              disabled={this.nurseDisabled()}
                              value={{ value: this.props.nurse }}
                              onChange={(v) => this.props.changeNursery(v)}
                            />
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <label>
                            <span className="label">Additional Charges:</span>
                            <input
                              type="number"
                              disabled={ADDITIONAL_CHARGES_FIELD_DISABLE}
                              onChange={({ target: { value } }) =>
                                elements.additionalPrice.handler(value)
                              }
                              // onBlur={() => {
                              //   if (appointment?.id) {
                              //     this.props.setAppointmentAdditionalPrice({
                              //       id: appointment.id,
                              //       additionalPrice: Number(elements.additionalPrice.value),
                              //       category: {
                              //         id: appointment.category.id,
                              //       },
                              //     });
                              //   }
                              // }}
                              value={elements.additionalPrice.value}
                              autoComplete="off"
                              className="form-control input-lg mt-2"
                            />
                          </label>
                        </div>
                        <div className="col-sm-3 mt-3">
                          <Show if={appointment && appointment.id}>
                            <Checkbox
                              checked={guardianNeeded}
                              onChange={() => {
                                setGuardianNeeded(!guardianNeeded);
                              }}
                              label="Guardian Needed"
                            />
                          </Show>
                        </div>
                        <Show if={guardianNeeded || guardianInformed}>
                          <div className="col-sm-3 mt-3">
                            <Checkbox
                              checked={guardianInformed}
                              onChange={() => {
                                setGuardianInformed(!guardianInformed);
                              }}
                              label="Guardian Informed"
                            />
                          </div>
                        </Show>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      {/*// col 4*/}
                      <div className="row">
                        <div className="col-sm-8">
                          <label>
                            <span className="label">Appointment notes:</span>

                            <textarea
                              disabled={
                                elements.note.disabled ||
                                this.props.permissions.indexOf("EDIT_CLINIC_APPOINTMENT_NOTE") ===
                                  -1
                              }
                              rows={5}
                              value={elements.note.value}
                              onChange={(e) => elements.note.handler(e)}
                              name="appointmentNotes"
                              className="form-control"
                              style={{ height: "246px" }}
                            />
                          </label>
                        </div>
                        <div className="col-sm-4">
                          {/*buttons*/}
                          {/*<Show*/}
                          {/*  if={*/}
                          {/*    this.props.appointmentId &&*/}
                          {/*    _.get(this.props.appointment, "patient.patientKey")*/}
                          {/*  }*/}
                          {/*>*/}
                          <button
                            type="button"
                            className="btn btn-success btn-lg full-width mt-3"
                            onClick={() => {
                              remindersActions.remindersModalShow();
                              remindersActions.setRecipientType("patient");
                              remindersActions.initPatient();
                              if (appointment && appointment.patient) {
                                remindersActions.togglePatient(
                                  _.isFinite(appointmentItem.patient.companyTempPatientId),
                                  appointmentItem && appointmentItem.patient.companyTempPatientId
                                    ? appointmentItem.patient?.companyTempPatientId
                                    : appointmentItem.patient?.companyPatientId,
                                  appointment && {
                                    ...appointment.patient,
                                    appointmentId: appointment.id,
                                  },
                                  (appointmentItem && appointmentItem.patient.companyPatientId) ||
                                    (appointmentItem &&
                                      appointmentItem.patient.companyTempPatientId),
                                );
                              }
                            }}
                          >
                            Reminder
                          </button>
                          {/*</Show>*/}

                          <Show if={elements.moveBtn.visible}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              disabled={elements.moveBtn.disabled}
                              onClick={this.onClickMoveAppointment}
                            >
                              {elements.moveBtn.value}
                            </button>
                          </Show>

                          <Show if={elements.copyBtn.visible}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              disabled={elements.copyBtn.disabled}
                              onClick={this.onClickCopyToNew}
                            >
                              {elements.copyBtn.value}
                            </button>
                          </Show>

                          <Show if={this.props.appointmentId}>
                            <button
                              className="btn btn-success btn-lg full-width"
                              type="button"
                              onClick={this.props.repeatAppointmentModalShow}
                            >
                              Repeat
                            </button>
                          </Show>
                          <Show if={this.props.appointmentId}>
                            <button
                              type="button"
                              className="btn btn-success btn-lg full-width"
                              onClick={() => this.setState({ appointmentLogsModalVisible: true })}
                            >
                              Logs
                            </button>
                          </Show>
                          <Show if={item?.patient?.chartNumber}>
                            <button
                              type="button"
                              className="btn btn-success btn-lg full-width"
                              onClick={() =>
                                this.setState({ appointmentTreatmentsPlanModalVisible: true })
                              }
                            >
                              Plan Appointments
                            </button>
                          </Show>
                        </div>
                        <div className="col-sm-8">
                          <label>
                            <span className="label">Doctor notes:</span>

                            <textarea
                              rows={5}
                              disabled={
                                this.props.permissions.indexOf("EDIT_APPOINTMENT_DOCTOR_NOTE") ===
                                -1
                              }
                              value={elements.doctorNote.value}
                              onChange={(e) => elements.doctorNote.handler(e)}
                              name="doctorNote"
                              className="form-control"
                              style={{ height: "246px" }}
                            />
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <Show
                            if={
                              this.props.appointmentId &&
                              _.get(this.props.appointment, "patient.patientKey")
                            }
                          >
                            <button
                              className="btn btn-success btn-lg btn-block"
                              type="button"
                              onClick={this.props.showCodesForThisModal}
                            >
                              Trx codes for this appointment: {this.props.codesForThisTotalCount}
                            </button>
                          </Show>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <input ref={(x) => (this.noteRef = x)} style={{ width: 0, height: 0, opacity: 0 }} />

              {data.appointmentItem &&
                data.appointmentItem.status &&
                (data.appointmentItem.status.code === STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED ||
                  data.appointmentItem.status.code === STATUS_TYPES.APPOINTMENT_STATUS_MISSED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CENTRE ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CENTER_MISSED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_NO_PAYMENT ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_LATE ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_MISSED_RESCHEDULED ||
                  data.appointmentItem.status.code === STATUS_TYPES.APPOINTMENT_STATUS_VOIDED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_CANCELLED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CENTER_LATE_CANCELLED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_LATE_CANCELLED ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CLINICIAN_NO_SHOW ||
                  data.appointmentItem.status.code ===
                    STATUS_TYPES.APPOINTMENT_STATUS_CANCELLED_CONSUMED) && (
                  <div className="row note-row">
                    <div className="col-sm-12">
                      <label>
                        <span className="label">Reason:</span>

                        <textarea
                          disabled={true}
                          value={this.props.appointment.comment}
                          className="form-control input-lg"
                        />
                      </label>
                    </div>
                  </div>
                )}
            </div>
          )}
          {this.shouldShowDetails(this.props.isFollow, showEligibility, insuranceFormVisible) && (
            <div>
              <div className="row">
                <div className="col-sm-4">
                  <label>
                    <span className="label">{localStorage.getItem("doctorLabel")}:</span>

                    <CustomSelectBox searchable={true} selectBox={elements.dentistSB} />
                  </label>
                </div>

                <div className="col-sm-3">
                  <label>
                    <span className="label">Follow Up Type:</span>

                    <SelectPicker
                      size="lg"
                      value={{ value: this.props.selectType }}
                      options={this.props.typesOptions}
                      onChange={(v) => this.props.selectFollowType(v.value)}
                    />
                  </label>
                </div>

                <div className="col-sm-4">
                  <FieldContainer label="Date:">
                    <DatePicker
                      size="lg"
                      placeholderText={PLEASE_SELECT}
                      selected={elements.date.value}
                      disabled={elements.date.disabled}
                      onChange={elements.date.handler}
                    />
                  </FieldContainer>
                </div>
              </div>

              <div className="row note-row">
                <div className="col-sm-12">
                  <label>
                    <span className="label">Note</span>
                    <textarea
                      value={this.props.note}
                      onChange={this.props.setNote}
                      name="note"
                      className="form-control input-lg"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        <RepeatAppointmentSidebar
          onRepeatClick={this.handleRepeatClick}
          onDeleteClick={this.handleDeleteClick}
          onClickUpdate={this.props.repeatAppointmentUpdateAfter}
          onSubmit={this.props.repeatAppointmentConfirmModalShow}
          handleOK={this.props.repeatAppointmentSaveSelectedDate}
          show={this.props.repeatAppointmentModalVisible}
          repeatAppointmentSelectedDate={this.props.repeatAppointmentSelectedDate}
          repeatAppointmentMode={this.props.repeatAppointmentMode}
          repeatAppointmentConfirmModalVisible={this.props.repeatAppointmentConfirmModalVisible}
          repeatAppointmentList={this.props.repeatAppointmentList}
          repeatAppointment={this.props.repeatAppointment}
          onSaveFollowAfter={this.props.repeatAppointmentSaveAfter}
          repeatAppointmentRepeatModalVisible={this.props.repeatAppointmentRepeatModalVisible}
          repeatAppointmentRepeatModalMode={this.props.repeatAppointmentRepeatModalMode}
          repeatAppointmentRepeatModalDate={this.props.repeatAppointmentRepeatModalDate}
          repeatAppointmentRepeatModalIndex={this.props.repeatAppointmentRepeatModalIndex}
          repeatAppointmentClickedRepeatArray={this.props.repeatAppointmentClickedRepeatArray}
          repeatAppointmentDeleteId={this.props.repeatAppointmentDeleteId}
        />

        <Modal
          onHide={() => this.setState({ infoMessage: "" })}
          size="sm"
          show={this.state.infoMessage !== ""}
          actions={
            <Button
              type="button"
              buttonType={ButtonType.Primary}
              onClick={() => this.setState({ infoMessage: "" })}
            >
              OK
            </Button>
          }
        >
          <textarea
            rows={5}
            disabled={true}
            style={{
              width: "100%",
              border: "none",
              resize: "none",
              backgroundColor: "white",
            }}
          >
            {this.state.infoMessage}
          </textarea>
        </Modal>
        <Modal
          size="lg"
          show={remindersModalVisible}
          onHide={remindersActions.remindersModalHide}
          title={
            <div
              style={{
                padding: "16px",
                textAlign: "center",
              }}
            >
              SMS reminders
            </div>
          }
          actions={
            <Button buttonType={ButtonType.Primary} onClick={remindersActions.remindersModalHide}>
              Close
            </Button>
          }
        >
          <RemindersManualModeForm
            togglePatientMode={false}
            lastMode={""}
            notificationType={notificationType}
            templateLabel={templateLabel}
            templates={templatesList}
            toggleDentistSelected={[]}
            togglePatientSelected={patient}
            patientFromAppointment={true}
            appointmentId={appointment && appointment.id}
          />
        </Modal>
      </div>
    );
  }
}

export default enhancer(withRouter(RightSide));
