import _, { toFinite } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormikProvider, useFormik } from "formik";

import Show from "../widgets/Show";
import { DateField } from "../form/DateField";
import FilledImage from "../widgets/FilledImage";
import { SelectField } from "../form/SelectField";
import CalendarWeekButtons from "./CalendarWeekButtons";
import { ClinicSelectField } from "../form/ClinicSelectField";
import { DoctorSelectField } from "../form/DoctorSelectField";
import SearchImage from "../../assets/images/search_x2.png";
import RefreshImage from "../../assets/images/calendar-bottom-menu/refresh@2x.png";
// import FindOpenSlotImage from "../../assets/images/find-open-slot.png";
import ResponseImage from "../../assets/images/appointment/no-response-action@2x.png";
import NotificationImage from "../../assets/images/notification.png";
import WhatsappImage from "../../assets/images/appointment/whatsapp5.png";
import SupportImage from "../../assets/images/support_icon.png";
import { MasterTypeSelectField } from "../form/MasterTypeSelectField";
import { DoctorPointSelectField } from "../form/DoctorPointSelectField";
import {
  calendarDoctorSelect,
  calendarDoctorsReset,
  choosePoint,
  clearSelectedDoctors,
  getDoctorLocations,
  getMultiDentist,
  saveSelectedDoctors,
  selectClinicId,
  setActiveDoctorId,
  setCalendarWeaklyAppointment,
  updateCalendar,
} from "../../actions/calendarActions";
import moment from "moment";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { withRouter } from "react-router";
import { obtainImagePath } from "../../utils/ImageUtils";
import Checkbox from "@mui/material/Checkbox";
import { getCalendarFollowUpCount } from "../../actions/followUpActions";
import AppointmentPanel from "./copyPastPanelForAppointment/AppointmentPanel";

const VIEW_MODE_OPTIONS = [
  { label: "Room view", value: "room" },
  { label: "Doctor view", value: "doctor" },
];

const NOTE_EXISTS_OPTIONS = [
  { label: "Please select", value: -1 },
  { label: "Docs.Available", value: 1, isExist: true },
  { label: "Docs.NA", value: 2, isExist: false },
];

const enhancer = connect(
  ({
    session,
    followUp,
    calendar: {
      clinicId,
      calendarViewMode,
      date,
      selectedDoctors,
      calendarWeaklyMode,
      dentalPointsIds,
      points,
      appointmentDndCount,
    },
  }) => ({
    date,
    session,
    clinicId,
    selectedDoctors,
    calendarViewMode,
    calendarWeaklyMode,
    dentalPointsIds,
    points,
    followUp,
    appointmentDndCount,
  }),
  {
    choosePoint,
    saveSelectedDoctors,
    updateCalendar,
    setActiveDoctorId,
    selectClinicId,
    getMultiDentist,
    calendarDoctorsReset,
    getDoctorLocations,
    calendarDoctorSelect,
    clearSelectedDoctors,
    setCalendarWeaklyAppointment,
    getCalendarFollowUpCount,
  },
);

const CalendarForm = (props) => {
  const { calendarViewMode, date, session, points, dentalPointsIds, appointmentDndCount } = props;
  const { member } = session;

  const permissions = _.get(props, "permissions", []);
  const showClinicSwitcher = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_CLINIC_FILTER") >= 0;
  const showSearchButton = permissions.indexOf("USE_CLINIC_APPOINTMENT_SEARCH") >= 0;
  const canSelectDoctors = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_ALL") === -1;
  const appointmentOwn = permissions.indexOf("VIEW_CLINIC_APPOINTMENT_OWN") >= 0;

  // const showViewModeSelect = permissions.indexOf("VIEW_APPOINTMENT_CALENDAR_DOCTOR_VIEW_TYPE") >= 0;
  // const doctorIds = props.dentalPointsIds.map((item) => {
  //   if (item.doctorId) return item.doctorId;
  // });

  const form = useFormik({
    initialValues: {
      date,
      clinicNotesExist: { value: -1 },
      masterTypes: [{}],
      doctors: props.selectedDoctors,
      clinic: { value: props.clinicId },
      viewMode: { value: calendarViewMode },
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      props.onSubmit();

      props.getDoctorLocations({
        publicPoint: true,
        clinicId: values.clinic.value,
      });

      props.resetColumns();

      if (values.viewMode.value === "room") {
        props.reload(false, values.clinic.value);
      } else {
        props.getMultiDentist(values.clinic.value, values.doctors);
      }
    },
  });

  const checkboxDisabled = canSelectDoctors ? false : props.dentalPointsIds.length !== 1;
  const { values, setFieldValue, handleSubmit } = form;
  const showWeekButtons = values.doctors.filter((x) => Boolean(x.value)).length === 1;
  useEffect(() => {
    props.getCalendarFollowUpCount();
  }, []);
  // console.log(dentalPointsIds);
  // useEffect(() => {
  //   if (appointmentOwn) {
  //     const currentDoctor = points[member.id];
  //     debugger;
  //     if (currentDoctor?.dentalPoints) {
  //       for (let [key, value] of Object.entries(currentDoctor.dentalPoints)) {
  //         // selectedPoints.push({ point: { id: key } });
  //         props.choosePoint(`${member.id},${key}`);
  //       }
  //     }
  //     // props.saveSelectedDoctors(selectedPoints);
  //     // handleSubmit();
  //   }
  // }, [points, props.choosePoint]);

  return (
    <FormikProvider value={form}>
      <form onSubmit={handleSubmit} className="d-flex align-items-center calendar-form">
        <div
          role="button"
          style={{ cursor: "pointer", marginRight: 16, position: "relative" }}
          onClick={props.onNotificationClick}
          title="Receive call"
        >
          <FilledImage
            src={NotificationImage}
            color="#BEC844"
            // zoom={100}
            // className="no-response-zoom"
          />
          {props.followUp.followUpNotificationCount > 0 && (
            <div className={"notification-chip"}>{props.followUp.followUpNotificationCount}</div>
          )}
        </div>
        {!!session?.whatsAppChatWithPatientActivated && (
          <div
            role="button"
            style={{ cursor: "pointer", marginRight: 16, position: "relative" }}
            onClick={() => props.history.push(Routes.DashboardWhatsappChatting)}
            title="Whatsapp"
          >
            <FilledImage src={WhatsappImage} color="#BEC844" zoom={92} />
            {props.followUp.followUpNotificationCount > 0 && (
              <div className={"whatsapp-notification-chip"}>
                {props.followUp.followUpNotificationCount}
              </div>
            )}
          </div>
        )}
        <Show if={props.showFirstContact}>
          <div
            role="button"
            style={{ cursor: "pointer" }}
            onClick={props.onPhoneClick}
            title="Receive call"
          >
            <FilledImage
              src={ResponseImage}
              color="#BEC844"
              zoom={80}
              className="no-response-zoom"
            />
          </div>
        </Show>
        <Show if={props.showMasterType}>
          <div
            className="ml-2"
            style={{
              width: "200px",
            }}
          >
            <MasterTypeSelectField
              name="masterTypes"
              onChange={(v, m) => props.chooseTypes(m.value)}
            />
          </div>
        </Show>
        <Show if={showClinicSwitcher}>
          <div
            className="ml-2"
            style={{
              minWidth: "220px",
            }}
          >
            <ClinicSelectField
              name="clinic"
              isMulti={false}
              closeMenuOnSelect={true}
              placeholder="Filter by clinic"
              onChange={(v) => {
                props.clearSelectedDoctors();
                props.selectClinicId(v.value);
                props.updateCalendar(values.date.format("X"), v.value);
              }}
            />
          </div>
        </Show>
        <Show if={true}>
          <div
            className="ml-2"
            style={{
              width: "170px",
            }}
          >
            <SelectField
              name="clinicNotesExist"
              isMulti={false}
              closeMenuOnSelect={true}
              options={NOTE_EXISTS_OPTIONS}
              onChange={(v) => {
                props.setClinicNotesExist(v.isExist);
                handleSubmit();
              }}
            />
          </div>
        </Show>
        <AppointmentPanel dndCount={appointmentDndCount} />
        <Show if={true}>
          <div
            className="ml-2"
            style={{
              width: "150px",
            }}
          >
            <SelectField
              name="viewMode"
              isMulti={false}
              closeMenuOnSelect={true}
              options={VIEW_MODE_OPTIONS}
              isDisabled={true}
              onChange={(v) => {
                setFieldValue("doctors", [{}]);
                localStorage.removeItem("dentalPointsId");
                if (v.value === "room") {
                  props.setActiveDoctorId(0);
                }

                props.setCalendarViewMode(v.value);

                props.choosePoint("0,0");
                props.calendarDoctorsReset();
                props.clearSelectedDoctors();

                handleSubmit();
              }}
            />
          </div>
        </Show>
        <Show if={values.viewMode.value === "doctor"}>
          <div
            className="ml-2"
            style={{
              width: "200px",
            }}
          >
            <DoctorSelectField
              name="doctors"
              isMulti={true}
              isSearchable={true}
              allLabel="All Doctors"
              isDisabled={canSelectDoctors}
              onKeyDown={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              customClinicId={values.clinic && values.clinic.value}
              onChange={(v, m) => {
                props.calendarDoctorSelect(
                  `0,${toFinite((m && m.value) || (m && m.option.value))}`,
                );
                if (appointmentOwn) {
                  props.saveSelectedDoctors([m && m.option]);
                } else {
                  props.saveSelectedDoctors(v);
                }
                handleSubmit();
              }}
            />
          </div>
        </Show>
        <Show if={values.viewMode.value === "room"}>
          <div
            className="ml-2"
            style={{
              width: "200px",
            }}
          >
            <DoctorPointSelectField
              name="doctors"
              isMulti={true}
              onKeyDown={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              isSearchable={true}
              allLabel="All Rooms"
              // isDisabled={canSelectDoctors} // || appointmentOwn
              loggedInDoctorPointsOnly={appointmentOwn && canSelectDoctors ? member.id : null}
              customClinicId={values.clinic && values.clinic.value}
              onChange={(v, m) => {
                if (session.allowAppointmentMultiBooking && m?.option?.label !== "All Rooms") {
                  let selectedPoints = dentalPointsIds.map((item) => ({
                    point: { id: item.pointId },
                  }));
                  (v || []).forEach((item) => {
                    const doctorId = item?.doctor?.id;
                    const isPublic = (item.doctor.name || "").toLocaleLowerCase() === "public";
                    const { dentalPoints = {} } = points[doctorId];
                    if (doctorId || isPublic) {
                      for (let [key, value] of Object.entries(dentalPoints)) {
                        selectedPoints.push({ point: { id: key } });
                        props.choosePoint(`${doctorId},${key}`);
                      }
                    } else {
                      props.choosePoint("0,0");
                    }
                  });
                  props.saveSelectedDoctors(selectedPoints);
                  handleSubmit();
                } else {
                  if (!m.value && !m.option.value) {
                    props.setCalendarWeaklyAppointment(false);
                  }
                  props.choosePoint((m && m.value) || (m && m.option.value) || "0,0");
                  props.saveSelectedDoctors(v);
                  handleSubmit();
                }
              }}
            />
          </div>
        </Show>
        <div className="ml-2">
          <DateField
            showDay={true}
            name="date"
            onChange={(x) => {
              setFieldValue("date", moment(x));
              props.updateCalendar(moment(x), values.clinic.value);
            }}
          />
        </div>

        <div>
          <label style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              defaultChecked={props.calendarWeaklyMode}
              sx={{
                color: checkboxDisabled ? "#eeeeee" : "#BEC844FF",
                "&.Mui-checked": {
                  color: checkboxDisabled ? "#eeeeee" : "#BEC844FF",
                },
              }}
              onChange={(ev) => props.setCalendarWeaklyAppointment(ev.target.checked)}
              disabled={checkboxDisabled}
            />
            <p style={{ margin: 0, color: checkboxDisabled ? "#b2b2b2" : "#000000" }}>Week view</p>
          </label>
        </div>
        <div
          role="button"
          title="Refresh"
          onClick={handleSubmit}
          style={{ cursor: "pointer" }}
          className="ml-2 d-flex align-items-center"
        >
          <FilledImage src={RefreshImage} color="#BEC844" className="no-response-zoom" />
        </div>
        <Show if={showSearchButton}>
          <div
            role="button"
            className="ml-2 d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={props.onFindApptClick}
            title="Find Appointments"
          >
            <FilledImage src={SearchImage} color="#BEC844" zoom="70" className="no-response-zoom" />
          </div>
        </Show>
        {/*<div*/}
        {/*  role="button"*/}
        {/*  className="ml-2 d-flex align-items-center"*/}
        {/*  style={{ cursor: "pointer" }}*/}
        {/*  onClick={() => props.findOpenSlotSidebarShow()}*/}
        {/*  title="Find Open Slot"*/}
        {/*>*/}
        {/*  <FilledImage*/}
        {/*    src={FindOpenSlotImage}*/}
        {/*    color="#BEC844"*/}
        {/*    zoom="20"*/}
        {/*    className="no-response-zoom"*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          role="button"
          className="ml-2 d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.history.push(
              createUrl(Routes.DashboardAppointment, {
                params: { id: 0 },
                query: {
                  mode: "follow",
                  dentist: _.get(session, "member.id"),
                },
              }),
            );
          }}
          title="Support"
        >
          <FilledImage
            src={obtainImagePath(SupportImage)}
            color="#FF4242"
            zoom="20"
            className="no-response-zoom"
          />
        </div>
        <Show if={showWeekButtons}>
          <CalendarWeekButtons changeCalendarWeek={props.changeCalendarWeek} />
        </Show>
      </form>
    </FormikProvider>
  );
};
export default withRouter(enhancer(CalendarForm));
