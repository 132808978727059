import _, { split } from "lodash";
import moment from "moment";

import JSUtils from "./JSUtils";
import {
  PLEASE_SELECT_ITEM,
  MINUTE_MILLISECONDS,
  UNIT_DAY,
  UNIT_TIME,
  TIME_FORMAT,
  TEETH_TYPES,
} from "../constants/Constants";

const Utils = {
  validateResponse(error, response) {
    if (error) {
      if (/network is offline/.test(error.message)) {
        return "No Internet Connection, Check your connection & restart the application\n";
      }

      if (error.timeout === 10000) {
        return 10000;
      }

      return `${
        (error.response &&
          error.response.body &&
          error.response.body.data &&
          error.response.body.data.error_description) ||
        (error.response && error.response.body && error.response.body.error_description) ||
        error.message ||
        "error"
      }\n`;
    }

    if (response.body.statusCode !== 2 || response.body.status !== "success") {
      return `${response.body.message || "unknown error"}\n`;
    }

    return "";
  },
  getFullName(obj) {
    if (!_.isObject(obj)) return "";

    const arr = [];

    if (obj.firstName) arr.push(obj.firstName);
    if (obj.middleName) arr.push(obj.middleName);
    if (obj.lastName) arr.push(obj.lastName);

    return arr.join(" ");
  },
  getApptType(obj) {
    if (!_.isObject(obj)) return "";
    return obj.name;
  },
  getMedication(obj) {
    if (!obj) return "";

    const arr = [];

    if (obj.medicationName) arr.push(obj.medicationName);
    if (obj.dosage) arr.push(obj.dosage);
    if (obj.unit && obj.unit.name) arr.push(obj.unit.name);

    return arr.join(" - ");
  },
  toPhoneArray(str) {
    return (str || "+()").replace("(", ",").replace(")", ",").slice(1).split(",");
  },
  toPhoneObject(str) {
    const phoneArray = (str || "+()").replace("(", ",").replace(")", ",").slice(1).split(",");

    return {
      countryCode: {
        value: phoneArray[0],
      },
      townCode: phoneArray[1],
      number: phoneArray[2],
    };
  },
  registerToPhoneObject(str) {
    const phoneArray = (str || "").split("-");

    return {
      countryCode: {
        value: phoneArray[0],
      },
      townCode: phoneArray[1],
      number: phoneArray[2],
    };
  },
  nationalIdNormalize(value, previousValue) {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (onlyNums.length === 3) {
        return `${onlyNums}-`;
      }

      if (onlyNums.length === 7) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
      }

      if (onlyNums.length === 14) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7)}-`;
      }
    }
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    if (onlyNums.length <= 14) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(
      7,
      14,
    )}-${onlyNums.slice(14, 15)}`;
  },
  getAge(timestamp) {
    if (timestamp) {
      const currentDate = new Date();
      const date = new Date(timestamp);
      let d1 = date.getDate();
      let m1 = 1 + date.getMonth();
      let y1 = date.getFullYear();

      let d2 = currentDate.getDate();
      let m2 = 1 + currentDate.getMonth();
      let y2 = currentDate.getFullYear();
      let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      if (d1 > d2) {
        d2 = d2 + month[m2 - 1];
        m2 = m2 - 1;
      }
      if (m1 > m2) {
        m2 = m2 + 12;
        y2 = y2 - 1;
      }
      let d = d2 - d1;
      let m = m2 - m1;
      let y = y2 - y1;
      if (y == 0 && m == 0) {
        return `0 y.o 0 m ${d ? d : 0} day `;
      }
      return `${y ? y : 0} y.o ${m ? m : 0} m `;
    } else {
      return "n/a";
    }
  },
  getStartOfDate(date) {
    const companyTimeZone = this.getCompanyTimeZone();
    const computerTimeZone = date.utcOffset() * MINUTE_MILLISECONDS;

    return date.valueOf() + computerTimeZone - companyTimeZone;
  },
  getStartDay(date, withCompany = true) {
    const companyTimeZone = withCompany ? this.getCompanyTimeZone() : 0;
    const computerTimeZone = moment().utcOffset() * MINUTE_MILLISECONDS;
    return +moment(date).startOf("day") + computerTimeZone - companyTimeZone;
  },
  getEndDay(date) {
    return this.getStartDay(date) + 86400000 - 1000;
  },
  getDayStart(date) {
    return +moment(date).startOf("day") + 1000;
  },
  getDayEnd(date) {
    return +moment(date).endOf("day");
  },
  toCompanyTimezone(timestamp) {
    return timestamp + this.getTimezoneDifference();
  },
  toComputerTimezone(timestamp) {
    return timestamp - this.getTimezoneDifference();
  },
  getTimezoneDifference() {
    const companyTimeZone = this.getCompanyTimeZone();
    const computerTimeZone = -new Date().getTimezoneOffset() * MINUTE_MILLISECONDS;
    return computerTimeZone - companyTimeZone;
  },
  calcDateWithTimeZone(time, offset) {
    const momentDate = time ? moment(new Date(time)) : moment();
    let utcOffset = offset;

    if (!offset) {
      const companyTimeZone = this.getCompanyTimeZone();

      utcOffset = companyTimeZone ? companyTimeZone / MINUTE_MILLISECONDS : momentDate.utcOffset();
    }

    return momentDate.utcOffset(utcOffset);
  },
  parseDateFormat(momentDate, formatString) {
    return momentDate.format(formatString || TIME_FORMAT);
  },
  getCompanyTimeZone() {
    return localStorage.getItem("timezone") ? _.toNumber(localStorage.getItem("timezone")) : 0;
  },
  convertAsSelectItem(obj) {
    let result = null;
    if (obj) {
      result = {};
      result.name = obj.name;
      result.id = obj.id;
      if (obj.code) result.code = obj.code;
      if (obj.description) {
        result.description = obj.description;
      }
    }
    return result;
  },
  getSelectedItem(selectBox) {
    const arr = selectBox.options.filter(
      (elem) => elem.id == selectBox.selectedId || elem.value == selectBox.value,
    );
    return arr && arr.length && arr[0];
  },
  getSelectedItemById(selectBox) {
    const arr = selectBox.options.filter((elem) => elem.id == selectBox.selectedId);
    return arr && arr.length && arr[0];
  },
  selectByKey(list, key, value) {
    const arr = list.filter((elem) => elem[key] == value);
    return arr && arr.length && arr[0];
  },
  selectItem(selectBox, item, id, index) {
    index = index || 0;
    if (!selectBox.selectedId && !selectBox.selected) {
      selectBox.selectedId = selectBox.options[index] && selectBox.options[index].id;
    }
    if (item) {
      selectBox.selectedId = item.id;
    } else if (id) {
      selectBox.selectedId = id;
    }
    selectBox.selected = this.getSelectedItem(selectBox);
    return selectBox;
  },
  selectPleaseSelect(selectBox) {
    selectBox.selected = PLEASE_SELECT_ITEM;
    selectBox.selectedId = "";
    return selectBox;
  },
  timeStringToInt(str) {
    const arr = str.split(":");
    const hours = +arr[0];
    const minutes = +arr[1];

    return hours * UNIT_TIME + minutes;
  },
  toTwoNumbersString(number) {
    if (number > 9) return `${number}`;
    if (number == 0) return "00";
    return `0${number}`;
  },
  intToDayPartStr(i, format) {
    if (format) {
      const arr = _.toString(i / 60).split(".");

      return moment(
        new Date().setHours(
          arr[0],
          arr[1] ? (arr[1] === "25" ? 15 : arr[1] === "5" ? 30 : arr[1] === "75" ? 45 : 0) : 0,
          0,
          0,
        ),
      ).format(format);
    }
    const halfDay = (UNIT_DAY / 2) * UNIT_TIME;
    let dayPart;
    let value;
    if (i >= halfDay) {
      dayPart = "PM";
      value = i - halfDay;
    } else {
      dayPart = "AM";
      value = i;
    }

    let hours = this.toTwoNumbersString(Math.floor(value / UNIT_TIME));
    const minutes = this.toTwoNumbersString(value % UNIT_TIME);

    if (hours == "00") {
      hours = "12";
    }

    return `${hours}:${minutes} ${dayPart}`;
  },
  getTimeArray(startString, endString, format, calendarMinuteIncrements) {
    const arr = [];

    const startTime = this.timeStringToInt(startString);
    const endTime = this.timeStringToInt(endString);

    for (let i = startTime; i < endTime; i += calendarMinuteIncrements) {
      arr.push({
        value: i,
        name: this.intToDayPartStr(i, format),
      });
    }

    return arr;
  },
  string24ToString12(time, notFound = "N/A") {
    if (time && /[0-9]{1,2}:[0-9]{1,2}( AM)?/.test(time)) {
      const arr = time.split(" ")[0].split(":");

      const surplus = _.toNumber(arr[0]) - 12;

      if (surplus === 0) return `12:${arr[1]} AM`;
      if (surplus > 0) {
        return `${String(surplus).length === 1 ? `0${surplus}` : surplus}:${arr[1]} PM`;
      }
      if (surplus < 0) {
        return `${String(arr[0]).length === 1 ? `0${arr[0]}` : arr[0]}:${arr[1]} AM`;
      }
    } else {
      return notFound;
    }
  },
  objectForEach(obj, callback) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        callback(obj[key], key);
      }
    }
  },
  objectToArray(obj, callback) {
    const arr = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        arr.push(callback(obj[key], key));
      }
    }
    return arr;
  },
  filterCallList(columns, dayStart) {
    const callListNoResponse = JSON.parse(localStorage.getItem("callListNoResponse") || "{}");
    const selectedPoints = callListNoResponse[dayStart] || {};

    return columns.map((column) => {
      const selectedList = [];
      const otherList = [];

      (column.list || []).forEach((point) => {
        if (selectedPoints.hasOwnProperty(point.id)) {
          selectedList.push({
            ...point,
            lastNoResponseTime: selectedPoints[point.id].time,
          });
        } else {
          otherList.push(point);
        }
      });

      const list = [].concat(selectedList, otherList);

      return { ...column, list };
    });
  },
  deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  pushArray(arr1, arr2) {
    if (arr1 && arr2 && Array.isArray(arr1)) {
      arr1.push(...(Array.isArray(arr2) ? arr2 : [arr2]));
    }
    return arr1;
  },
  isValidSelectItem(element) {
    return (element.selected && element.selected.id) || element.disabled;
  },
  makeSurfaceArea(list, type) {
    let areaStr = "";
    let result = [];

    if (list && list.length) {
      for (let i = 0; i < list.length; i++) {
        const code = list[i].code;
        if (type == "SURFACE") {
          let isNumberAdded = false;
          if (code && code[1]) {
            if (areaStr.indexOf(code[1]) == -1) {
              isNumberAdded = true;
            }
          }
          const has = areaStr.indexOf(code[0]) >= 0;
          if (!has) {
            areaStr += code[0];
          }

          if (isNumberAdded) {
            areaStr += code[1];
          }

          result = [areaStr];
        } else {
          result.push(code);
        }
      }
    }
    return result;
  },
  contains(str1, str2) {
    let isValid = false;
    if (str1 && str2) {
      let index = str1.indexOf(str2);
      if (index == -1) {
        index = str1.toLowerCase().indexOf(str2.toLowerCase());
      }
      isValid = index > -1;
    }
    return isValid;
  },
  getToothNumberKey(toothNumericType) {
    return !toothNumericType || toothNumericType.code == "Index1" ? "numeric1" : "numeric2";
  },
  getSurfaces(toothPosition) {
    const position = JSUtils.parseToNumber(toothPosition);
    const list = [5, 8, 11, 16, 21, 24, 27, 32];
    let lte = list[0]; // lte - less than and equals

    for (const i of list) {
      if (position <= i) {
        lte = i;
        break;
      }
    }
    const result = [];
    switch (lte) {
      case 5:
        result.push(TEETH_TYPES.SurfaceType.Buccal5);
        result.push(TEETH_TYPES.SurfaceType.Buccal);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Oclusal);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        break;
      case 8:
        result.push(TEETH_TYPES.SurfaceType.Facial5);
        result.push(TEETH_TYPES.SurfaceType.Facial);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Incisal);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        break;
      case 11:
        result.push(TEETH_TYPES.SurfaceType.Facial5);
        result.push(TEETH_TYPES.SurfaceType.Facial);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Incisal);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        break;
      case 16:
        result.push(TEETH_TYPES.SurfaceType.Buccal5);
        result.push(TEETH_TYPES.SurfaceType.Buccal);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Oclusal);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        break;
      case 21:
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Oclusal);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Buccal);
        result.push(TEETH_TYPES.SurfaceType.Buccal5);
        break;
      case 24:
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Incisal);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Facial);
        result.push(TEETH_TYPES.SurfaceType.Facial5);
        break;
      case 27:
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Incisal);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Facial);
        result.push(TEETH_TYPES.SurfaceType.Facial5);
        break;
      case 32:
        result.push(TEETH_TYPES.SurfaceType.Lingual5);
        result.push(TEETH_TYPES.SurfaceType.Lingual);
        result.push(TEETH_TYPES.SurfaceType.Mesial);
        result.push(TEETH_TYPES.SurfaceType.Oclusal);
        result.push(TEETH_TYPES.SurfaceType.Distal);
        result.push(TEETH_TYPES.SurfaceType.Buccal);
        result.push(TEETH_TYPES.SurfaceType.Buccal5);
        break;
    }
    return result;
  },
  replaceAll(str, search, replacement) {
    return str.split(search).join(replacement);
  },
  numberToCurrency: (n) => n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
  calculateStringToNumber: (input = "") => {
    const operands = {
      add: "+",
      sub: "-",
      div: "/",
      mlt: "*",
      mod: "%",
      exp: "^",
    };

    const calculate = (a, op, b) => {
      a = a * 1;
      b = b * 1;
      switch (op) {
        case operands.add:
          return a + b;
        case operands.sub:
          return a - b;
        case operands.div:
          return a / b;
        case operands.mlt:
          return a * b;
        case operands.mod:
          return a % b;
        case operands.exp:
          return Math.pow(a, b);
        default:
          return null;
      }
    };

    operands.type = [
      [[operands.mlt], [operands.div], [operands.mod], [operands.exp]],
      [[operands.add], [operands.sub]],
    ];

    input = input.replace(/[^0-9%^*/()\-+.]/g, "");
    let output;
    for (let i = 0, n = operands.type.length; i < n; i++) {
      const re = new RegExp(
        "(\\d+\\.?\\d*)([\\" + operands.type[i].join("\\") + "])(\\d+\\.?\\d*)",
      );
      re.lastIndex = 0;

      while (re.test(input)) {
        output = calculate(RegExp.$1, RegExp.$2, RegExp.$3);
        if (isNaN(output) || !isFinite(output)) return output;
        input = input.replace(re, output);
      }
    }

    return Number(output || 0);
  },

  checkMandatoryFields(values, mandatoryFields, showMessage) {
    // console.log("working===================>", values);
    // debugger;
    const createMessage = (message) => `Please, fill the (${message}) to continue.`;
    if (values?.relationshipType?.value === "PCP_Stopped" && !values.roleEndDate) {
      return showMessage(createMessage("Role End"));
    }
    if (values?.relationshipType?.value === "PCP_Stopped" && !values.roleBeginDate) {
      return showMessage(createMessage("Role Begin"));
    }
    if (!values.firstName && mandatoryFields.find((x) => x.code === "FIRST_NAME")) {
      return showMessage(createMessage("First Name"));
    }

    if (!values.middleName && mandatoryFields.find((x) => x.code === "MIDDLE_NAME")) {
      return showMessage(createMessage("Middle Name"));
    }

    if (!values.lastName && mandatoryFields.find((x) => x.code === "LAST_NAME")) {
      return showMessage(createMessage("Last Name"));
    }

    if (!values.nationality && mandatoryFields.find((x) => x.code === "NATIONALITY")) {
      return showMessage(createMessage("Nationality"));
    }

    if (values.address) {
      if (!values.dateOfBirth && mandatoryFields.find((x) => x.code === "DATE_OF_BRITH")) {
        return showMessage(createMessage("Birth Date"));
      }
    } else {
      if (!values.birthDate && mandatoryFields.find((x) => x.code === "DATE_OF_BRITH")) {
        return showMessage(createMessage("Birth Date"));
      }
    }

    if (!values.gender && mandatoryFields.find((x) => x.code === "GENDER")) {
      return showMessage(createMessage("Gender"));
    }

    if (!values.maritalStatus && mandatoryFields.find((x) => x.code === "MARITAL_STATUS")) {
      return showMessage(createMessage("Martial status"));
    }

    // if (!values.vipPatient && mandatoryFields.find((x) => x.code === "VIP_PATIENT")) {
    //   return dispatch(showMessage("VIP patient is required"));
    // }

    if (!values.studentStatus && mandatoryFields.find((x) => x.code === "STUDENT_STATUS")) {
      return showMessage(createMessage("Student status"));
    }

    if (!values.weight && mandatoryFields.find((x) => x.code === "WEIGHT")) {
      return showMessage(createMessage("Weight"));
    }
    if (!values?.profissionType && mandatoryFields.find((x) => x.code === "PROFISSION")) {
      return showMessage("Profession is required");
    }

    if (!values.workplace && mandatoryFields.find((x) => x.code === "WORKPLACE")) {
      return showMessage(createMessage("Workplace"));
    }

    if (!values.referralType && mandatoryFields.find((x) => x.code === "REFERRAL")) {
      return showMessage(createMessage("Referral"));
    }

    if (
      !values.additionalInformation &&
      mandatoryFields.find((x) => x.code === "ADDITIONAL_INFORMATION")
    ) {
      return showMessage(createMessage("Additional information"));
    }

    if (values?.mobilePhone) {
      if (
        !values?.mobilePhone?.number &&
        mandatoryFields.find((x) => x.code === "MOBILE_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Mobile phone"));
      }
    } else if (
      !values?.mobilePhoneNumber &&
      mandatoryFields.find((x) => x.code === "MOBILE_PHONE_NUMBER")
    ) {
      return showMessage(createMessage("Mobile phone"));
    }

    if (values?.workPhone) {
      if (
        !values?.workPhone?.number &&
        mandatoryFields.find((x) => x.code === "WORK_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Work phone"));
      }
    } else if (
      !values?.workPhoneNumber &&
      mandatoryFields.find((x) => x.code === "WORK_PHONE_NUMBER")
    ) {
      return showMessage(createMessage("Work phone"));
    }

    if (values?.homePhone) {
      if (
        !values?.homePhone?.number &&
        mandatoryFields.find((x) => x.code === "HOME_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Home phone"));
      }
    } else if (
      !values?.homePhoneNumber &&
      mandatoryFields.find((x) => x.code === "HOME_PHONE_NUMBER")
    ) {
      return showMessage(createMessage("Home phone"));
    }

    if (!values.email && mandatoryFields.find((x) => x.code === "EMAIL")) {
      return showMessage(createMessage("Email"));
    }

    if (values?.address) {
      if (!values?.address?.country?.id && mandatoryFields.find((x) => x.code === "COUNTRY")) {
        return showMessage(createMessage("Country"));
      }
      if (!values?.address?.state?.id && mandatoryFields.find((x) => x.code === "STATE")) {
        return showMessage(createMessage("State"));
      }
      if (!values?.address?.town?.id && mandatoryFields.find((x) => x.code === "TOWN")) {
        return showMessage(createMessage("City"));
      }
      if (!values?.address.line1 && mandatoryFields.find((x) => x.code === "ADDRESS")) {
        return showMessage(createMessage("Street"));
      }

      if (
        !values.documentNationalIdNumber &&
        mandatoryFields.find((x) => x.code === "NATIONAL_ID_NUMBER")
      ) {
        return showMessage(createMessage("National ID"));
      }
      if (
        !values.documentSocialSecurityNumber &&
        mandatoryFields.find((x) => x.code === "SOCIAL_SECURITY_NUMBER")
      ) {
        return showMessage(createMessage("Social security number"));
      }
      if (
        !values.documentDriverLicenseNumber &&
        mandatoryFields.find((x) => x.code === "DRIVERS_LICENSE_NUMBER")
      ) {
        return showMessage(createMessage("Driver license"));
      }
      if (
        !values.documentSocialInsuranceNumber &&
        mandatoryFields.find((x) => x.code === "SOCIAL_INSURANCE_NUMBER")
      ) {
        return showMessage(createMessage("Social insurance"));
      }
      if (
        !values.documentPasswordNumber &&
        mandatoryFields.find((x) => x.code === "PASSPORT_NUMBER")
      ) {
        return showMessage(createMessage("Passport"));
      }
    } else {
      if (!values.country && mandatoryFields.find((x) => x.code === "COUNTRY")) {
        return showMessage(createMessage("Country"));
      }
      if (!values.state && mandatoryFields.find((x) => x.code === "STATE")) {
        return showMessage(createMessage("State"));
      }
      if (!values.city && mandatoryFields.find((x) => x.code === "TOWN")) {
        return showMessage(createMessage("City"));
      }
      if (!values.street && mandatoryFields.find((x) => x.code === "ADDRESS")) {
        return showMessage(createMessage("Street"));
      }

      if (!values.nationalId && mandatoryFields.find((x) => x.code === "NATIONAL_ID_NUMBER")) {
        return showMessage(createMessage("National ID"));
      }
      if (
        !values.socialSecurity &&
        mandatoryFields.find((x) => x.code === "SOCIAL_SECURITY_NUMBER")
      ) {
        return showMessage(createMessage("Social security number"));
      }
      if (
        !values.driversLicense &&
        mandatoryFields.find((x) => x.code === "DRIVERS_LICENSE_NUMBER")
      ) {
        return showMessage(createMessage("Driver license"));
      }
      if (
        !values.socialInsurance &&
        mandatoryFields.find((x) => x.code === "SOCIAL_INSURANCE_NUMBER")
      ) {
        return showMessage(createMessage("Social insurance"));
      }
      if (!values.passport && mandatoryFields.find((x) => x.code === "PASSPORT_NUMBER")) {
        return showMessage(createMessage("Passport"));
      }
    }
    if (values?.emergencyContacts) {
      if (
        !values?.emergencyContacts[0].firstName &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_FIRST_NAME")
      ) {
        return showMessage(createMessage("Emergency firs name"));
      }
      if (
        !values?.emergencyContacts[0].lastName &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_LAST_NAME")
      ) {
        return showMessage(createMessage("Emergency last name"));
      }
      if (
        !values?.emergencyContacts[0].relationship &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_RELATIONSHIP")
      ) {
        return showMessage(createMessage("Emergency Relationship"));
      }
      if (
        !values?.emergencyContacts[0].homePhoneNumber &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_HOME_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Emergency home phone"));
      }
      if (
        !values?.emergencyContacts[0].mobilePhoneNumber &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_MOBILE_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Emergency mobile phone"));
      }
    } else {
      if (
        !values.emergencyFirstName &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_FIRST_NAME")
      ) {
        return showMessage(createMessage("Emergency firs name"));
      }

      if (
        !values.emergencyLastName &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_LAST_NAME")
      ) {
        return showMessage(createMessage("Emergency last name"));
      }

      if (
        !values.emergencyRelationship &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_RELATIONSHIP")
      ) {
        return showMessage(createMessage("Emergency Relationship"));
      }

      if (
        !values.emergencyHomePhone &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_HOME_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Emergency home phone"));
      }

      if (
        !values.emergencyMobilePhone &&
        mandatoryFields.find((x) => x.code === "EMERGENCY_CONTACT_MOBILE_PHONE_NUMBER")
      ) {
        return showMessage(createMessage("Emergency mobile phone"));
      }
    }

    return true;
  },
  debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let context = this;
      let args = arguments;

      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      let callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  },

  generateUUID() {
    let d = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  },

  formatPrice: (n, originalValue) => {
    if (_.isUndefined(n)) return;
    const chainedRegex = n
      .toString()
      .replace(/[^\d\.]/g, "")
      .replace(/\./, "x")
      .replace(/\./g, "")
      .replace(/x/, ".");
    originalValue = Number(n.toString().replace(",", ""));
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(chainedRegex)
      .replace("$", "");
  },

  fractionPrice: (n) => {
    if (_.isUndefined(n)) return;
    const chainedRegex = n
      .toString()
      .replace(/[^\d.]/g, "")
      .replace(/\./, "x")
      .replace(/\./g, "")
      .replace(/x/, ".");

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(chainedRegex)
      .replace("$", "");
  },
  calculateBMICategory: (bmi) => {
    if (bmi < 18.5) {
      return `${bmi} - Underweight`;
    } else if (bmi >= 18.5 && bmi < 25) {
      return `${bmi} - Normal weight`;
    } else if (bmi >= 25 && bmi < 30) {
      return `${bmi} - Overweight`;
    } else if (bmi >= 30 && bmi < 35) {
      return `${bmi} - Class I Obesity`;
    } else if (bmi >= 35 && bmi < 40) {
      return `${bmi} - Class II Obesity`;
    } else {
      return `${bmi} - Class III Obesity (Extreme or severe obesity)`;
    }
  },
  mapOptions: (items) => {
    const formatStringToUpperSnakeCase = (str) => {
      if (!str) return false;

      const result = str
        .replace(/#/g, "")
        .replace(/\s*\/\s*/g, " ")
        .replace(/[^\w\s]/g, "")
        .trim()
        .toUpperCase()
        .split(" ")
        .join("_");

      return result === "OF_PACKS_DAY" || result === "MG_OF_NICOTINE_DAY";
    };
    return (items || []).map((item) => ({
      value: item.code,
      label: item.name,
      isDisabled: formatStringToUpperSnakeCase(item.code),
    }));
  },
  getNoteText: (element) => {
    return (
      element.currentNote ||
      _.get(element, "smokingStatus.name") ||
      _.get(element, "alcoholDrinkingStatus.name") ||
      _.get(element, "substanceAbuseStatus.name") ||
      _.get(element, "educationLevel.name") ||
      _.get(element, "employmentStatus.name") ||
      ""
    );
  },
};

export default Utils;
