export const prescriptionStackCodes = {
  favourites: "favourites",
  list: "list",
  view: "view",
  category: "category",
  templates: "templates",
  edit: "edit",
  favouritesAdd: "favouritesAdd",
  categoryAdd: "categoryAdd",
  templatesAdd: "templatesAdd",
  diagnosis: "diagnosis",
  name: "name",
  templatesGroup: "templatesGroup",
  templatesGroupAdd: "templatesGroupAdd",
};

export const defaultStackItem = {
  code: prescriptionStackCodes.list,
};
