export const INIT_SIDE_FILL_IN_FORM = "INIT_SIDE_FILL_IN_FORM";
export const FILL_IN_FORM_INIT_EDIT = "FILL_IN_FORM_INIT_EDIT";

export const SELECT_SORT_FIELD_SIDE_FILL_IN_FORM = "SELECT_SORT_FIELD_SIDE_FILL_IN_FORM";

export const CHART_FILL_IN_FORM_SEARCH_CHANGE = "CHART_FILL_IN_FORM_SEARCH_CHANGE";

export const POP_FILL_IN_FORM_STACK = "POP_FILL_IN_FORM_STACK";
export const PUSH_FILL_IN_FORM_STACK = "PUSH_FILL_IN_FORM_STACK";
export const CLEAR_FILL_IN_FORM_STACK = "CLEAR_FILL_IN_FORM_STACK";

export const GET_FILL_IN_FORM_OWNED_LIST_START = "GET_FILL_IN_FORM_OWNED_LIST_START";
export const GET_FILL_IN_FORM_OWNED_LIST_SUCCESS = "GET_FILL_IN_FORM_OWNED_LIST_SUCCESS";
export const GET_FILL_IN_FORM_OWNED_LIST_ERROR = "GET_FILL_IN_FORM_OWNED_LIST_ERROR";

export const GET_FREQUENCY_FILL_IN_FORM_LIST_START = "GET_FREQUENCY_FILL_IN_FORM_LIST_START";
export const GET_FREQUENCY_FILL_IN_FORM_LIST_SUCCESS = "GET_FREQUENCY_FILL_IN_FORM_LIST_SUCCESS";
export const GET_FREQUENCY_FILL_IN_FORM_LIST_ERROR = "GET_FREQUENCY_FILL_IN_FORM_LIST_ERROR";

export const GET_FILL_IN_FORM_TEMPLATE_LIST_START = "GET_FILL_IN_FORM_TEMPLATE_LIST_START";
export const GET_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS = "GET_FILL_IN_FORM_TEMPLATE_LIST_SUCCESS";
export const GET_FILL_IN_FORM_TEMPLATE_LIST_ERROR = "GET_FILL_IN_FORM_TEMPLATE_LIST_ERROR";

export const RESET_FILL_IN_FORM_TEMPLATE_LIST = "RESET_FILL_IN_FORM_TEMPLATE_LIST";

export const GET_FILL_IN_FORM_ITEM_START = "GET_FILL_IN_FORM_ITEM_START";
export const GET_FILL_IN_FORM_ITEM_SUCCESS = "GET_FILL_IN_FORM_ITEM_SUCCESS";
export const GET_FILL_IN_FORM_ITEM_ERROR = "GET_FILL_IN_FORM_ITEM_ERROR";

export const SAVE_FILL_IN_FORM_ITEM_START = "SAVE_FILL_IN_FORM_ITEM_START";
export const SAVE_FILL_IN_FORM_ITEM_SUCCESS = "SAVE_FILL_IN_FORM_ITEM_SUCCESS";
export const SAVE_FILL_IN_FORM_ITEM_ERROR = "SAVE_FILL_IN_FORM_ITEM_ERROR";

export const GET_FILL_IN_FORM_EMPTY_START = "GET_FILL_IN_FORM_EMPTY_START";
export const GET_FILL_IN_FORM_EMPTY_SUCCESS = "GET_FILL_IN_FORM_EMPTY_SUCCESS";
export const GET_FILL_IN_FORM_EMPTY_ERROR = "GET_FILL_IN_FORM_EMPTY_ERROR";

export const DELETE_FILL_IN_FORM_ITEM_START = "DELETE_FILL_IN_FORM_ITEM_START";
export const DELETE_FILL_IN_FORM_ITEM_SUCCESS = "DELETE_FILL_IN_FORM_ITEM_SUCCESS";
export const DELETE_FILL_IN_FORM_ITEM_ERROR = "DELETE_FILL_IN_FORM_ITEM_ERROR";

export const CHANGE_FILL_IN_FORM_CREATED_DATE_START = "CHANGE_FILL_IN_FORM_CREATED_DATE_START";
export const CHANGE_FILL_IN_FORM_CREATED_DATE_SUCCESS = "CHANGE_FILL_IN_FORM_CREATED_DATE_SUCCESS";
export const CHANGE_FILL_IN_FORM_CREATED_DATE_ERROR = "CHANGE_FILL_IN_FORM_CREATED_DATE_ERROR";

export const FILL_IN_FORM_PREV_QUESTION = "FILL_IN_FORM_PREV_QUESTION";
export const FILL_IN_FORM_NEXT_QUESTION = "FILL_IN_FORM_NEXT_QUESTION";
export const FILL_IN_FORM_SELECT_QUESTION = "FILL_IN_FORM_SELECT_QUESTION";

export const FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA = "FILL_IN_FORM_CHANGE_QUESTION_TEXTAREA";
export const FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS =
  "FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS";
export const FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE =
  "FILL_IN_FORM_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE";
export const FILL_IN_FORM_CHANGE_DATE_PICKER = "FILL_IN_FORM_CHANGE_DATE_PICKER";
export const FILL_IN_FORM_CHANGE_SURFACE = "FILL_IN_FORM_CHANGE_SURFACE";
export const FILL_IN_FORM_SELECT_TOOTH = "FILL_IN_FORM_SELECT_TOOTH";
export const FILL_IN_FORM_SET_NAME = "FILL_IN_FORM_SET_NAME";
export const FILL_IN_FORM_SET_TEXTAREA_BODY = "FILL_IN_FORM_SET_TEXTAREA_BODY";

export const FILL_IN_FORM_OPEN_DELETE_MODAL = "FILL_IN_FORM_OPEN_DELETE_MODAL";
export const FILL_IN_FORM_CLOSE_DELETE_MODAL = "FILL_IN_FORM_CLOSE_DELETE_MODAL";

export const FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE = "FILL_IN_FORM_MULTI_QUESTIONS_OTHER_VALUE";

export const FILL_IN_FORM_CHANGE_QUESTION_CANVAS = "FILL_IN_FORM_CHANGE_QUESTION_CANVAS";

export const FILL_IN_FORM_GET_GROUP_LIST_START = "FILL_IN_FORM_GET_GROUP_LIST_START";
export const FILL_IN_FORM_GET_GROUP_LIST_SUCCESS = "FILL_IN_FORM_GET_GROUP_LIST_SUCCESS";
export const FILL_IN_FORM_GET_GROUP_LIST_ERROR = "FILL_IN_FORM_GET_GROUP_LIST_ERROR";

export const FILL_IN_FORM_SET_ADDENDUM_ID = "FILL_IN_FORM_SET_ADDENDUM_ID";
export const FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE =
  "FILL_IN_FORM_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE";
export const FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE =
  "FILL_IN_FORM_SET_SELECT_TREATMENT_MODAL_VISIBLE";
export const FILL_IN_FORM_INIT_TEXTAREA = "FILL_IN_FORM_INIT_TEXTAREA";
export const FILL_IN_FORM_FULL_SCREEN = "FILL_IN_FORM_FULL_SCREEN";
export const RESET_FILL_IN_FORM_FOR = "RESET_FILL_IN_FORM_FOR";
export const FILL_IN_FORM_CHANGE_TAB = "FILL_IN_FORM_CHANGE_TAB";
export const GET_REFERENCE_FILL_IN_FORM_LIST_START = "GET_REFERENCE_FILL_IN_FORM_LIST_START";
export const GET_REFERENCE_FILL_IN_FORM_LIST_SUCCESS = "GET_REFERENCE_FILL_IN_FORM_LIST_SUCCESS";
export const GET_REFERENCE_FILL_IN_FORM_LIST_ERROR = "GET_REFERENCE_FILL_IN_FORM_LIST_ERROR";
