import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import FlexBox from "../../ui/FlexBox";
import { Modal } from "../../ui/Modal";
import BackButton from "../../widgets/BackButton";
import { parseQuery } from "../../../utils/UrlUtils";
import ChartPatient from "../top-panel/ChartPatient";
import PatientApi from "../../../api-legacy/PatientApi";
import { uploadFiles } from "../../../actions/folderActions";
import { createFolder } from "../../../actions/folderActions";
import { showMessage, startLoader, stopLoader } from "../../../actions/loaderActions";
import { dataUrlToFile } from "../../../utils/ImageUtils";
import { useSavePerioChartMutation } from "../../../queries/perioChart/useSavePerioChartMutation";

export function PerioExamHeader(props) {
  const { downloadAsImage } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = parseQuery(location.search);
  const [openModal, setOpenModal] = useState(false);
  const savePerioExamItemMutation = useSavePerioChartMutation();
  const [dateInput, setDateInput] = useState(moment(+query?.date).format("YYYY-MM-DD"));
  const store = useSelector((store) => store);
  const {
    patient: { perioExamItem },
    session: { permissions },
    folder: { folderStack, folderList, folderItem, perioChartFolder },
  } = store;

  const currentFolder = _.last(folderStack) || 0;
  const _folderList = folderList[currentFolder] || [];

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  useEffect(() => {
    if (perioChartFolder === -1) {
      dispatch(
        createFolder({
          name: "Perio Chart",
          parentId: _.last(folderStack) || null,
          patientKey: query?.patientKey,
        }),
      );
    }
  }, []);

  const onUpload = async () => {
    dispatch(startLoader());

    try {
      const dataUrl = await downloadAsImage();
      const fileName = `${today}_perio.jpeg`;
      const newFile = dataUrlToFile(dataUrl, fileName);

      dispatch(
        uploadFiles({
          patientKey: query?.patientKey,
          files: [newFile],
          folderId: folderItem?.id || _folderList[perioChartFolder]?.id || null,
        }),
      );
    } catch (error) {
      dispatch(showMessage("Error capturing or uploading screenshot"));
    } finally {
      dispatch(stopLoader());
    }
  };

  const confirmClick = async () => {
    try {
      const res = await savePerioExamItemMutation.mutateAsync(perioExamItem);
      if (res) {
        history.goBack();
        setOpenModal(false);
      }
    } catch (err) {
      dispatch(stopLoader(err));
    } finally {
      dispatch(stopLoader());
    }
  };

  const updateNewDate = async () => {
    if (permissions.indexOf("EDIT_PERIO_EXAM_ITEM_HISTORY_DATE") === -1) {
      return dispatch(showMessage("Permission denied"));
    }
    await onUpload();
    dispatch(startLoader());

    const data = {
      historyDate: +query?.date,
      patientKey: query?.patientKey,
      newHistoryDate: new Date(dateInput).getTime(),
    };
    PatientApi.updateDatePerioExam(data)
      .then(() => stopLoader())
      .catch((err) => stopLoader(err))
      .finally(() => dispatch(stopLoader()));
  };

  return (
    <>
      <FlexBox justify="space-between" className="chart-info" flex={true} align={"center"}>
        <div className="back-button-container d-flex align-items-center">
          <BackButton
            onClick={() => {
              perioExamItem?.historyDate ? setOpenModal(true) : history.goBack();
            }}
            color="#bec844"
            text="Patient Perio exam"
          />
        </div>
        <ChartPatient patient={props.patientDetails} />
        {!query?.date ? (
          <Button
            onClick={async () => {
              await onUpload();
              perioExamItem?.historyDate ? setOpenModal(true) : history.goBack();
            }}
          >
            Save perio exam
          </Button>
        ) : (
          <div style={{ display: "flex", alignItems: "center", marginLeft: 100 }}>
            <TextField
              style={{ height: 40 }}
              value={dateInput}
              onChange={(ev) => setDateInput(ev.target.value)}
              variant="outlined"
              type={"date"}
              disabled={query.voided === "true"}
            />
            {/*{new Date(+query?.date)}*/}
            <Button
              className="save-date"
              onClick={updateNewDate}
              disabled={query.voided === "true"}
            >
              Save new date
            </Button>
          </div>
        )}
      </FlexBox>
      <Modal
        size="sm"
        show={openModal}
        onHide={() => setOpenModal(false)}
        keyboard={false}
        actions={
          <div>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                history.goBack();
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button bsStyle="primary" onClick={confirmClick}>
              Yes
            </Button>
          </div>
        }
      >
        <div>Do you save this exam?</div>
      </Modal>
    </>
  );
}
