import _, { isArray } from "lodash";
import React from "react";
import { connect } from "react-redux";

import Show from "../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import FilledImage from "../widgets/FilledImage";

import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import MenuImage from "../../assets/images/chart/find_apptMenuIcon@2x.png";
import { SelectPicker } from "../select-picker/SelectPicker";
import Utils from "../../helpers/Utils";
import PropTypes from "prop-types";

const enhancer = connect(
  ({
    auth,
    session: { clinic },
    reminders: {
      clinicId,
      statusList,
      selectedDoctors,
      selectedStatuses,
      selectedCategories,
      remindersAppointmentFilterDropdownVisible,
    },
  }) => ({
    auth,
    clinic,
    clinicId,
    statusList,
    selectedDoctors,
    selectedStatuses,
    selectedCategories,
    remindersAppointmentFilterDropdownVisible,
  }),
  bindActions({ remindersActions }),
);
class RemindersToggleCalendarPatient extends React.Component {
  static propTypes = {
    remindersActions: PropTypes.object,
    auth: PropTypes.object,
    clinicId: PropTypes.number,
    statusList: PropTypes.array,
    doctorSelected: PropTypes.array,
    appointmentTypes: PropTypes.array,
    togglePatientMode: PropTypes.string,
    selectedCategories: PropTypes.array,
    selectedDoctors: PropTypes.array,
    selectedStatuses: PropTypes.array,
    calendarPatientList: PropTypes.array,
    togglePatientSelected: PropTypes.object,
    searchCalendarPatient: PropTypes.string,
    fetchingCalendarPatientList: PropTypes.bool,
  };

  UNSAFE_componentWillMount() {
    const { clinic, clinicId, remindersActions } = this.props;

    if (!clinicId) {
      remindersActions.selectClinicFilter(clinic.id);
    }

    // remindersActions.initCalendarTempPatientList([], [], [], [], clinic.id);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { selectedCategories, selectedDoctors, selectedStatuses, clinicId } = this.props;
    if (
      !_.isEqualWith(selectedDoctors, nextProps.selectedDoctors, (x, z) => x.value && z.value) ||
      !_.isEqualWith(
        selectedCategories,
        nextProps.selectedCategories,
        (x, z) => x.value && z.value,
      ) ||
      !_.isEqualWith(selectedStatuses, nextProps.selectedStatuses, (x, z) => x.value && z.value) ||
      !_.isEqual(clinicId, nextProps.clinicId)
    ) {
      const types = [];
      const doctors = [];
      const statuses = [];

      _.forEach(nextProps.selectedDoctors, (item) => {
        if (item.value > 0) {
          doctors.push(item.value);
        }
      });

      _.forEach(nextProps.selectedCategories, (item) => {
        if (item.value > 0) {
          types.push(item.value);
        }
      });

      _.forEach(nextProps.selectedStatuses, (item) => {
        if (item.value) {
          statuses.push(item.value);
        }
      });

      this.props.remindersActions.initCalendarTempPatientList(
        types,
        doctors,
        statuses,
        nextProps.clinicId,
      );
    }
  }

  loadCalendarPatientList = ({ target }) => {
    const scrollBottom = target.scrollTop + target.offsetHeight === target.scrollHeight;

    if (scrollBottom) {
      const { selectedCategories, selectedDoctors, selectedStatuses, clinicId } = this.props;

      const types = [];
      const doctors = [];
      const statuses = [];

      _.forEach(selectedDoctors, (item) => {
        if (item.value > 0) {
          doctors.push(item.value);
        }
      });

      _.forEach(selectedCategories, (item) => {
        if (item.value > 0) {
          types.push(item.value);
        }
      });

      _.forEach(selectedStatuses, (item) => {
        if (item.value) {
          statuses.push(item.value);
        }
      });
      this.props.remindersActions.loadCalendarTempPatientList(types, doctors, statuses, clinicId);
    }
  };

  render() {
    const {
      auth,
      clinicId,
      statusList,
      doctorSelected,
      appointmentTypes,
      togglePatientMode,
      selectedCategories,
      selectedDoctors,
      selectedStatuses,
      calendarPatientList,
      togglePatientSelected,
      searchCalendarPatient = "",
      fetchingCalendarPatientList,
    } = this.props;

    let list = [];
    let selectedAll = 0;
    let emptyList = calendarPatientList.length === 0;
    const noneOption = { value: 0, label: "None" };

    const clinicList = _.get(auth, "clinics", []).map((x) => ({
      value: x.id,
      label: x.name,
    }));

    const categoryOptions = (appointmentTypes || []).map((x) => ({
      value: x.id,
      label: x.name,
    }));

    const doctorOptions = (doctorSelected || []).map((x) => ({
      value: x.id,
      label: Utils.getFullName(x),
    }));

    const statusOptions = (statusList || []).map((x) => ({
      value: x.code,
      label: x.name,
    }));

    const calendarPatientListAfterFilter = _.filter(calendarPatientList, (element) => {
      const patientFirstName = _.get(element, "firstName", "").toLowerCase();
      const patientLastname = _.get(element, "lastName", "").toLowerCase();
      const patientChartNumber = _.get(element, "chartNumber", "").toLowerCase();

      const firstName = patientFirstName.indexOf(searchCalendarPatient.toLowerCase()) >= 0;
      const lastName = patientLastname.indexOf(searchCalendarPatient.toLowerCase()) >= 0;
      const hasChartNumber = patientChartNumber.indexOf(searchCalendarPatient.toLowerCase()) >= 0;

      return firstName || lastName || hasChartNumber;
    });

    _.forEach(calendarPatientListAfterFilter, (value, index) => {
      const isTemp = _.isFinite(value?.companyTempPatientId);
      let showCurrent = !_.isEmpty(togglePatientSelected[_.get(value, "appointmentId")]);

      showCurrent && selectedAll++;
      list.push(
        <div
          className="list-group-item"
          key={index}
          onClick={() => {
            this.props.remindersActions.togglePatient(
              isTemp,
              _.get(value, "appointmentId"),
              false,
              _.get(value, "companyPatientId", null) || _.get(value, "companyTempPatientId", null),
            );
          }}
        >
          <div className="text">
            {value?.firstName} {value?.lastName}
          </div>
          <div className="text">{value.dentist.name}</div>
          <div className="text">{value.status.name}</div>
          <Show if={showCurrent}>
            <div className="arrow">✔</div>
          </Show>
        </div>,
      );
    });

    return (
      <div>
        <div className="row d-flex">
          <div className="col-sm-3 mb-3">
            <span className="reminders-filter-title">Appointment type</span>
            <SelectPicker
              isMulti={true}
              isSearchable={true}
              className="toggle-patient-clinic-filter"
              value={selectedCategories}
              options={[noneOption, ...categoryOptions]}
              onChange={(values) => {
                this.props.remindersActions.setCalendarFilter(values);
              }}
              formatMultiValue={(x) => {
                if (isArray(x)) {
                  return `Selected (${x.length})`;
                }

                return x.label;
              }}
            />
          </div>
          <div className="col-sm-3 mb-3">
            <span className="reminders-filter-title">Doctor:</span>
            <SelectPicker
              isMulti={true}
              isSearchable={true}
              className="toggle-patient-clinic-filter"
              value={selectedDoctors}
              options={[{ value: 0, label: "None" }, ...doctorOptions]}
              onChange={(values) => {
                this.props.remindersActions.setDoctorFilter(values);
              }}
            />
          </div>
          <div className="col-sm-3 mb-3">
            <span className="reminders-filter-title">Appointment status:</span>
            <SelectPicker
              isMulti={true}
              isSearchable={true}
              className="toggle-patient-clinic-filter"
              value={selectedStatuses}
              options={[{ value: 0, label: "None" }, ...statusOptions]}
              onChange={(values) => {
                this.props.remindersActions.selectStatusFilter(values);
              }}
            />
          </div>
          <div className="col-sm-3 mb-3">
            <span className="reminders-filter-title">Clinic:</span>
            <SelectPicker
              isSearchable={true}
              placeholder="Filter by clinic"
              className="toggle-patient-clinic-filter"
              value={{ value: clinicId }}
              options={clinicList}
              onChange={({ value }) => {
                this.props.remindersActions.selectClinicFilter(value);
              }}
            />
          </div>
        </div>
        <div className="input-group search">
          <span className="input-group-addon">
            <FilledImage color={"#aaa"} src={MenuImage} className="zoom-2-5x" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="search"
            value={searchCalendarPatient}
            onChange={this.props.remindersActions.searchCalendarPatient}
          />
        </div>
        <div className="list-group" style={{ height: "auto" }}>
          <div
            className="list-group-item"
            style={{ borderBottom: "none", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <div className="text">Patient</div>
            <div className="text">Doctor</div>
            <div className="text">Appointment Status</div>
          </div>
        </div>
        <div className="list-group" onScroll={this.loadCalendarPatientList}>
          {list}
          <Show if={fetchingCalendarPatientList}>
            <ReactSpinner />
          </Show>
        </div>
        <Show
          if={
            (togglePatientMode === "calendar" && selectedAll !== calendarPatientList.length) ||
            emptyList
          }
        >
          <button
            className="btn btn-link pull-left reminders-select-all"
            disabled={emptyList}
            onClick={() => {
              this.props.remindersActions.selectAllCalendarPatient("select");
            }}
          >
            Select all
          </button>
        </Show>
        <Show
          if={
            togglePatientMode === "calendar" &&
            selectedAll === calendarPatientList.length &&
            !emptyList
          }
        >
          <button
            className="btn btn-link pull-left reminders-select-all"
            onClick={() => {
              this.props.remindersActions.selectAllCalendarPatient("deselect");
            }}
          >
            Deselect all
          </button>
        </Show>
      </div>
    );
  }
}
export default enhancer(RemindersToggleCalendarPatient);
