import React from "react";
import BackButton from "../../../widgets/BackButton";
import { initList, popStack, setInitialValues } from "../../../../actions/referralActions";
import { useFormik } from "formik";
import moment from "moment";
import _ from "lodash";
import { getCompanyEndOfDay, getCompanyStartOfDay } from "../../../../helpers/DateUtils";
import { SelectPicker } from "../../../select-picker/SelectPicker";
import { DatePicker } from "../../../ui/DatePicker";
import Utils from "../../../../helpers/Utils";

const ChartReferralNextVisitFilterForm = ({
  searchInitialValues,
  dispatch,
  activeTab,
  chartNumber,
  patientKey,
  doctors,
  categoryList,
}) => {
  const form = useFormik({
    initialValues: {
      ...searchInitialValues,
      periodStart: moment(),
      periodEnd: moment().add(1, "years"),
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    data.referredToId =
      _.get(values, "referredToId.value") > 0 ? _.get(values, "referredToId.value") : null;
    data.referredFromId =
      _.get(values, "referredFromId.value") > 0 ? _.get(values, "referredFromId.value") : null;
    data.doctorIds = _.get(values, "doctorIds.value") > 0 ? _.get(values, "doctorIds.value") : null;
    data.categoryIds = data?.categoryIds?.value ? JSON.stringify(data.categoryIds?.value) : null;
    data.referralTypeCode = values.referralTypeCode.value;
    data.periodStart = getCompanyStartOfDay(values.periodStart.valueOf());
    data.periodEnd = getCompanyEndOfDay(values.periodEnd.valueOf());
    data.chartNumber = chartNumber;
    data.patientKey = patientKey;
    dispatch(initList(data));
  };

  const doctorsOptions = (doctors || [])
    .filter((i) => i.doctor)
    .map((item) => {
      return { value: item.id, label: Utils.getFullName(item) };
    });
  const allDoctors = { value: -1, label: "All doctors" };
  doctorsOptions.unshift(allDoctors);

  const categoriesOption = (categoryList || []).map((item) => {
    return { value: item.id, label: item.name };
  });

  const typeOptions = [
    { value: -1, label: "Any" },
    { value: "NEXT_VISIT", label: "Next visit" },
    { value: "REFERRAL", label: "Referral" },
  ];

  return (
    <form onSubmit={form.handleSubmit}>
      <div className="sidebar-header chart-side-header">
        <div className="clearfix">
          <div className="col-sm-2">
            <BackButton className="btn-sm" text="Back" onClick={() => dispatch(popStack())} />
          </div>
          <div className="col-sm-8">
            <div className="chart-clinical-note-title" style={{ textAlign: "center" }}>
              Patient Appointments
            </div>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-link" type="submit">
              SEARCH
            </button>
          </div>
        </div>
      </div>
      <div className="chart-side-body" style={{ overflow: "unset" }}>
        <div className="row p-3">
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">Referred From:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                name="referredFromId"
                isMulti={false}
                isSearchable={true}
                value={form.values.referredFromId}
                options={doctorsOptions}
                onChange={(x) => {
                  form.setFieldValue("referredFromId", x);
                  dispatch(setInitialValues({ referredFromId: x }));
                }}
              />
            </div>
          </div>
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">Referred To:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                name="referredToId"
                isMulti={false}
                isSearchable={true}
                value={form.values.referredToId}
                options={doctorsOptions}
                onChange={(x) => {
                  form.setFieldValue("referredToId", x);
                  dispatch(setInitialValues({ referredToId: x }));
                }}
              />
            </div>
          </div>
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">Category:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                name="categoryIds"
                isMulti={false}
                isSearchable={true}
                value={form.values.categoryIds}
                options={categoriesOption}
                onChange={(x) => {
                  form.setFieldValue("categoryIds", x);
                  dispatch(setInitialValues({ categoryIds: x }));
                }}
              />
            </div>
          </div>
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">Type:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <SelectPicker
                name="referralTypeCode"
                isMulti={false}
                isSearchable={true}
                value={form.values.referralTypeCode}
                options={typeOptions}
                onChange={(x) => {
                  form.setFieldValue("referralTypeCode", x);
                  dispatch(setInitialValues({ referralTypeCode: x }));
                }}
              />
            </div>
          </div>
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">Start Date:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <DatePicker
                name="periodStart"
                fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
                className="form-control"
                style={{ width: "100%" }}
                placeholder="Select date"
                selected={form.values.periodStart}
                onChange={(event) => {
                  form.setFieldValue("periodStart", event);
                  dispatch(setInitialValues({ periodStart: event }));
                }}
              />
            </div>
          </div>
          <div className="referral-next-visit-form-field col-sm-4 mb-3">
            <div className="mb-1">End Date:</div>
            <div
              className="form-group"
              style={{
                margin: 0,
                lineHeight: "30px",
                textAlign: "initial",
              }}
            >
              <DatePicker
                name="periodEnd"
                fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
                className="form-control"
                style={{ width: "100%" }}
                placeholder="Select date"
                selected={form.values.periodEnd}
                onChange={(event) => {
                  form.setFieldValue("periodEnd", event);
                  dispatch(setInitialValues({ periodEnd: event }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChartReferralNextVisitFilterForm;
