export const INIT_PATIENT_VIEW = "INIT_PATIENT_VIEW";

export const PATIENT_VIEW_GET_DASHBOARD_START = "PATIENT_VIEW_GET_DASHBOARD_START";
export const PATIENT_VIEW_GET_DASHBOARD_SUCCESS = "PATIENT_VIEW_GET_DASHBOARD_SUCCESS";
export const PATIENT_VIEW_GET_DASHBOARD_ERROR = "PATIENT_VIEW_GET_DASHBOARD_ERROR";

export const PATIENT_VIEW_GET_TERMINAL_DETAILS_START = "PATIENT_VIEW_GET_TERMINAL_DETAILS_START";
export const PATIENT_VIEW_GET_TERMINAL_DETAILS_SUCCESS =
  "PATIENT_VIEW_GET_TERMINAL_DETAILS_SUCCESS";
export const PATIENT_VIEW_GET_TERMINAL_DETAILS_ERROR = "PATIENT_VIEW_GET_TERMINAL_DETAILS_ERROR";

export const PATIENT_VIEW_GET_NOTES_START = "PATIENT_VIEW_GET_NOTES_START";
export const PATIENT_VIEW_GET_NOTES_SUCCESS = "PATIENT_VIEW_GET_NOTES_SUCCESS";
export const PATIENT_VIEW_GET_NOTES_ERROR = "PATIENT_VIEW_GET_NOTES_ERROR";

export const PATIENT_VIEW_SAVE_NOTE_START = "PATIENT_VIEW_SAVE_NOTE_START";
export const PATIENT_VIEW_SAVE_NOTE_SUCCESS = "PATIENT_VIEW_SAVE_NOTE_SUCCESS";
export const PATIENT_VIEW_SAVE_NOTE_ERROR = "PATIENT_VIEW_SAVE_NOTE_ERROR";

export const PATIENT_VIEW_GET_REMINDER_START = "PATIENT_VIEW_GET_REMINDER_START";
export const PATIENT_VIEW_GET_REMINDER_SUCCESS = "PATIENT_VIEW_GET_REMINDER_SUCCESS";
export const PATIENT_VIEW_GET_REMINDER_ERROR = "PATIENT_VIEW_GET_REMINDER_ERROR";

export const PATIENT_VIEW_SAVE_REMINDER_START = "PATIENT_VIEW_SAVE_REMINDER_START";
export const PATIENT_VIEW_SAVE_REMINDER_SUCCESS = "PATIENT_VIEW_SAVE_REMINDER_SUCCESS";
export const PATIENT_VIEW_SAVE_REMINDER_ERROR = "PATIENT_VIEW_SAVE_REMINDER_ERROR";

export const PATIENT_VIEW_GET_INSURANCE_NOTES_START = "PATIENT_VIEW_GET_INSURANCE_NOTES_START";
export const PATIENT_VIEW_GET_INSURANCE_NOTES_SUCCESS = "PATIENT_VIEW_GET_INSURANCE_NOTES_SUCCESS";
export const PATIENT_VIEW_GET_INSURANCE_NOTES_ERROR = "PATIENT_VIEW_GET_INSURANCE_NOTES_ERROR";

export const PATIENT_VIEW_SAVE_INSURANCE_NOTE_START = "PATIENT_VIEW_SAVE_INSURANCE_NOTE_START";
export const PATIENT_VIEW_SAVE_INSURANCE_NOTE_SUCCESS = "PATIENT_VIEW_SAVE_INSURANCE_NOTE_SUCCESS";
export const PATIENT_VIEW_SAVE_INSURANCE_NOTE_ERROR = "PATIENT_VIEW_SAVE_INSURANCE_NOTE_ERROR";

export const PATIENT_VIEW_GET_MEMBERS_START = "PATIENT_VIEW_GET_MEMBERS_START";
export const PATIENT_VIEW_GET_MEMBERS_SUCCESS = "PATIENT_VIEW_GET_MEMBERS_SUCCESS";
export const PATIENT_VIEW_GET_MEMBERS_ERROR = "PATIENT_VIEW_GET_MEMBERS_ERROR";

export const PATIENT_VIEW_SAVE_MEMBERS_START = "PATIENT_VIEW_SAVE_MEMBERS_START";
export const PATIENT_VIEW_SAVE_MEMBERS_SUCCESS = "PATIENT_VIEW_SAVE_MEMBERS_SUCCESS";
export const PATIENT_VIEW_SAVE_MEMBERS_ERROR = "PATIENT_VIEW_SAVE_MEMBERS_ERROR";

export const PATIENT_VIEW_GET_MEMBERS_ALL_START = "PATIENT_VIEW_GET_MEMBERS_ALL_START";
export const PATIENT_VIEW_GET_MEMBERS_ALL_SUCCESS = "PATIENT_VIEW_GET_MEMBERS_ALL_SUCCESS";
export const PATIENT_VIEW_GET_MEMBERS_ALL_ERROR = "PATIENT_VIEW_GET_MEMBERS_ALL_ERROR";

export const PATIENT_VIEW_HIDE_MODAL = "PATIENT_VIEW_HIDE_MODAL";
export const PATIENT_VIEW_SHOW_MODAL = "PATIENT_VIEW_SHOW_MODAL";

export const PATIENT_VIEW_OPEN_PLAN_VISIT_MODAL = "PATIENT_VIEW_OPEN_PLAN_VISIT_MODAL";
export const PATIENT_VIEW_CLOSE_PLAN_VISIT_MODAL = "PATIENT_VIEW_CLOSE_PLAN_VISIT_MODAL";

export const PATIENT_VIEW_SHOW_CLINICIAN_MODAL = "PATIENT_VIEW_SHOW_CLINICIAN_MODAL";
export const PATIENT_VIEW_CLOSE_CLINICIAN_MODAL = "PATIENT_VIEW_CLOSE_CLINICIAN_MODAL";
export const PATIENT_VIEW_TOGGLE_CLINICIAN_MODE = "PATIENT_VIEW_TOGGLE_CLINICIAN_MODE";

export const PATIENT_VIEW_TOGGLE_CLINICIAN = "PATIENT_VIEW_TOGGLE_CLINICIAN";
export const PATIENT_VIEW_SET_CLINICIAN_SEARCH_VALUE = "PATIENT_VIEW_SET_CLINICIAN_SEARCH_VALUE";

export const PATIENT_VIEW_OPEN_NOTE_EDIT = "PATIENT_VIEW_OPEN_NOTE_EDIT";
export const PATIENT_VIEW_CLOSE_NOTE_EDIT = "PATIENT_VIEW_CLOSE_NOTE_EDIT";

export const PATIENT_VIEW_PATIENT_REMINDERS_MODAL_SHOW =
  "PATIENT_VIEW_PATIENT_REMINDERS_MODAL_SHOW";
export const PATIENT_VIEW_PATIENT_REMINDERS_MODAL_HIDE =
  "PATIENT_VIEW_PATIENT_REMINDERS_MODAL_HIDE";

export const UPDATE_PRIMARY_CLINICIAN_START = "UPDATE_PRIMARY_CLINICIAN_START";
export const UPDATE_PRIMARY_CLINICIAN_SUCCESS = "UPDATE_PRIMARY_CLINICIAN_SUCCESS";
export const UPDATE_PRIMARY_CLINICIAN_ERROR = "UPDATE_PRIMARY_CLINICIAN_ERROR";

export const GET_PATIENT_FAMILY_MEMBERS_START = "GET_PATIENT_FAMILY_MEMBERS_START";
export const GET_PATIENT_FAMILY_MEMBERS_SUCCESS = "GET_PATIENT_FAMILY_MEMBERS_SUCCESS";
export const GET_PATIENT_FAMILY_MEMBERS_ERROR = "GET_PATIENT_FAMILY_MEMBERS_ERROR";
