import React from "react";
import ReactSpinner from "react-spinjs-fix";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import Show from "../../widgets/Show";

import bindActions from "../../../helpers/bindActions";
import * as chartGeneralActions from "../../../actions/chartGeneralActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";
import checkedImage from "../../../assets/images/appointment/checked.png";
import uncheckedImage from "../../../assets/images/appointment/unchecked.png";
import deleteImage from "../../../assets/images/delete.png";
import plusImage from "../../../assets/images/appointment/plus_grey.png";

const enhancer = connect(null, bindActions({ chartGeneralActions, chartSideSeriesActions }));

export default enhancer(
  class ChartGoals extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        deleteAll: false,
      };
    }

    componentDidMount() {
      this.props.chartGeneralActions.getGoalList();
    }

    componentWillUnmount() {
      const { chartGeneralActions } = this.props;

      chartGeneralActions.saveGoal();
    }

    toggle = (index) => {
      return () => {
        const { chartGeneralActions } = this.props;

        chartGeneralActions.toggleGoal(index);
      };
    };

    deleteGoal = (item, index) => {
      return () => {
        const {
          chartGeneralActions,
          chartGeneral: { goalList },
        } = this.props;

        const newGoalList = goalList.slice();

        newGoalList.splice(index, 1);

        chartGeneralActions.setGoal(newGoalList);

        if (item.id) {
          chartGeneralActions.deleteGoal(item.id);
        }
      };
    };

    render() {
      const {
        chartGeneral: { fetchingGoal, goalList },
      } = this.props;
      const { deleteAll } = this.state;

      let $list = goalList.map((item, index) => {
        const checkboxSrc = item.selected ? checkedImage : uncheckedImage;

        return (
          <div key={index} className="chart-general-goal-item clearfix">
            <div className="delete" onClick={this.deleteGoal(item, index)}>
              <img alt="" src={deleteImage} />
            </div>
            <div className="goal-checkbox" onClick={this.toggle(index)}>
              <img alt="" src={checkboxSrc} className="zoom-2x" />
            </div>
            <div className="text">
              <input
                type="text"
                value={item.notes}
                ref={"goal" + index}
                onChange={(e) => this.props.chartGeneralActions.setNote(e.target.value, index)}
              />
            </div>
          </div>
        );
      });

      return (
        <div className="chart-clinical-note">
          <div className="sidebar-header chart-side-header">
            <div className="clearfix">
              <div className="col-sm-5 col-sm-offset-3 text-center">
                <div className="chart-clinical-note-title" style={{ marginLeft: "18px" }}>
                  Goals (care plan)
                </div>
              </div>
              <div className="col-sm-4 text-right">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={this.props.chartSideSeriesActions.popSeriesStack}
                >
                  DONE
                </button>
              </div>
            </div>
          </div>
          <div className="chart-side-body">
            <div className="body">
              {$list}
              <div
                className="chart-general-goal-item clearfix"
                onClick={this.props.chartGeneralActions.pushGoal}
              >
                <div className="goal-checkbox">
                  <img alt="" src={plusImage} />
                </div>
                <div className="text" />
              </div>
              <Show if={fetchingGoal}>
                <ReactSpinner />
              </Show>
            </div>
            <div className="all-codes">
              <button
                onClick={() => this.setState({ deleteAll: true })}
                className="btn btn-primary btn-block"
              >
                Delete All
              </button>
            </div>
          </div>
          <Modal
            size="small"
            show={deleteAll}
            keyboard={false}
            onHide={() => this.setState({ deleteAll: false })}
            actions={
              <div>
                <Button onClick={() => this.setState({ deleteAll: false })}>Close</Button>
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    this.props.chartGeneralActions.deleteGoals();
                    this.setState({ deleteAll: false });
                  }}
                >
                  Ok
                </Button>
              </div>
            }
          >
            <div>This will delete all entries, are you sure?</div>
          </Modal>
        </div>
      );
    }
  },
);
