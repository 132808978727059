import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import bindActions from "../../../helpers/bindActions";
import * as calendarActions from "../../../actions/calendarActions";
import formDays from "../../../constants/open-slot-sidebar/formDays";
import * as appointmentActions from "../../../actions/appointmentActions";
import lengthOptions from "../../../constants/open-slot-sidebar/lengthList";
import * as blockedTimeSlotActions from "../../../actions/blockedTimeSlotActions";
import { BlockedKeywordsSelectWrapper } from "../../../wrappers/BlockedKeywordsSelectWrapper";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";
import { Button, ButtonType } from "../../ui/Button";
import moment from "moment";
import { TimePickerField } from "../../forms/TimePickerField";

const enhancer = connect(
  ({ appointment, calendar: { reserved, findOpenSlotWeekDays } }) => ({
    reserved,
    findOpenSlotWeekDays,
    categoryService: (appointment.categoryService || []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
    subCategoryService: (appointment.subCategoryService || []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
    subSubCategoryService: (appointment.subSubCategoryService || []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  }),
  bindActions({ calendarActions, appointmentActions, blockedTimeSlotActions }),
);
const form = reduxForm({
  form: "FindOpenSlotSidebarAdvancedForm",
});

class FindOpenSlotSidebarAdvancedForm extends React.Component {
  componentDidMount() {
    const { initialValues, calendarActions } = this.props;
    const weekDay = moment(initialValues.findOpenSlotFromDate).format("ddd");
    calendarActions.findOpenSlotSetWeekDays((weekDay || "").toLowerCase() || "mon", true);
    if (initialValues.doctorId) {
      calendarActions.getDentalPoint({ value: initialValues.doctorId });
      calendarActions.findOpenSlotSidebarSetFromDate(initialValues.fromTime);
      calendarActions.findOpenSlotSidebarSetToDate(initialValues.fromTime);
    }
    this.props.blockedTimeSlotActions.getMemberKeywords();
  }

  render() {
    const {
      doctors,
      onChange,
      reserved,
      fields = {},
      handleSubmit,
      calendarActions,
      findOpenSlotToDate,
      findOpenSlotWeekDays,
      categoryService = [],
      findOpenSlotFromDate,
      subCategoryService = [],
      subSubCategoryService = [],
    } = this.props;

    let days = [];
    let doctorsList = [];

    _.forEach(doctors, (element) => {
      doctorsList.push({
        label: element.name,
        value: element.id,
      });
    });

    _.forEach(formDays, (element) => {
      const selected = findOpenSlotWeekDays[element.name];
      days.push(
        <Button
          key={element.name}
          type="button"
          onClick={() => calendarActions.findOpenSlotSetWeekDays(element.name, !selected)}
          buttonType={selected ? ButtonType.Primary : ButtonType.Secondary}
          className="d-flex align-items-center justify-content-center mb-1 mr-1"
        >
          {(element.label || "").toUpperCase()}
        </Button>,
      );
    });

    return (
      <form className="open-slot-form clearfix list-group" onSubmit={handleSubmit}>
        <div className="col-sm-6">
          <label htmlFor="trxRoom" className="control-label">
            Group:
          </label>
          <SelectPicker
            options={categoryService}
            value={{ value: fields.category }}
            onChange={(v) => onChange("category", v)}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="lengthList" className="control-label">
            Sub Group:
          </label>
          <SelectPicker
            options={subCategoryService}
            value={{ value: fields.subCategory }}
            onChange={(v) => onChange("subCategory", v)}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="trxRoom" className="control-label">
            Sub Sub Group:
          </label>
          <SelectPicker
            options={subSubCategoryService}
            value={{ value: fields.subSubCategory }}
            onChange={(v) => onChange("subSubCategory", v)}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="keyWords" className="control-label">
            Key words:
          </label>
          <BlockedKeywordsSelectWrapper isReset={false} />
        </div>

        <div className="col-sm-12">
          <label htmlFor="doctorId" className="control-label">
            Doctor:
          </label>
          <Field
            component={({ input }) => (
              <SelectPicker
                options={doctorsList}
                value={{ value: input.value }}
                onChange={(x) => {
                  input.onChange(x.value);
                  calendarActions.getDentalPoint(x);
                }}
              />
            )}
            name="doctorId"
            id="doctorId"
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "28px",
            justifyContent: "center",
          }}
          className="col-sm-12 open-slot-form-days"
        >
          {days}
        </div>

        <div className="col-sm-12" style={{ padding: 0 }}>
          <div className="col-sm-6">
            <label htmlFor="lengthList" className="control-label">
              Length
            </label>
            <Field
              component={({ input }) => (
                <SelectPicker
                  options={lengthOptions}
                  value={{ value: input.value }}
                  onChange={(x) => input.onChange(x.value)}
                />
              )}
              name="length"
              id="length"
            />
          </div>
        </div>

        <div className="col-sm-6">
          <label htmlFor="fromDate" className="control-label">
            From Date
          </label>
          <DatePicker
            selected={findOpenSlotFromDate}
            id="fromDate"
            name="fromDate"
            onChange={this.props.calendarActions.findOpenSlotSidebarSetFromDate}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="toDate" className="control-label">
            To Date
          </label>
          <DatePicker
            selected={findOpenSlotToDate}
            id="toDate"
            name="toDate"
            onChange={this.props.calendarActions.findOpenSlotSidebarSetToDate}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor="fromTime" className="control-label">
            From Time
          </label>
          <TimePickerField name="fromTime" className="full-size" />
        </div>
        <div className="col-sm-6">
          <label htmlFor="toTime" className="control-label">
            To Time
          </label>

          <TimePickerField name="toTime" className="full-size" />
        </div>

        <div style={{ marginTop: "12px" }}>
          <input
            id="reserved"
            type="checkbox"
            value={reserved}
            onChange={() => calendarActions.switchOpenSlotReserved()}
            style={{ margin: "0 8px 0 15px" }}
          />
          <label htmlFor="reserved">Show only reserved</label>
        </div>
      </form>
    );
  }
}

export default enhancer(form(FindOpenSlotSidebarAdvancedForm));
