import React from "react";

import { Button } from "../ui/Button";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { setAppointmentVisitTime } from "../../actions/chartSideClinicalNoteActions";

interface Props {
  readonly className?: string;

  readonly isPlanning: boolean;

  readonly showJustView: boolean;
  readonly showStartVisit: boolean;
  readonly showContinueVisit: boolean;
  readonly continueVisitButtonValue: string;

  readonly onJustViewClick: () => void;
  readonly onStartVisitClick: () => void;
  readonly onContinueVisitClick: () => void;
}

export function AppointmentPatientChartButtons({
  className,
  isPlanning,
  showJustView,
  showStartVisit,
  showContinueVisit,
  onJustViewClick,
  onStartVisitClick,
  onContinueVisitClick,
  continueVisitButtonValue,
}: Props) {
  const dispatch = useDispatch();
  return (
    <div className={cx("d-flex flex-column", className)}>
      {showStartVisit && (
        <Button
          className="mb-2"
          onClick={() => {
            onStartVisitClick();
            dispatch(setAppointmentVisitTime(new Date()));
          }}
          type="button"
        >
          Pt.Chart - Start visit
        </Button>
      )}
      {showContinueVisit && (
        <Button className="mb-2" onClick={onContinueVisitClick} type="button">
          {continueVisitButtonValue}
        </Button>
      )}
      {showJustView && (
        <Button onClick={onJustViewClick} type="button">
          {isPlanning ? "Pt.Chart - Planning mode" : "Pt.Chart - Just view"}
        </Button>
      )}
    </div>
  );
}
