import _ from "lodash";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import Back from "../widgets/Back";
import Show from "../widgets/Show";

import bindActions from "../../helpers/bindActions";
import * as patientRegisterActions from "../../actions/patientRegisterActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";

const enhancer = connect(
  ({ patientRegister, session }) => ({
    session,
    patientRegister,
    canEdit: session.permissions.indexOf("EDIT_PATIENT_DETAIL") >= 0,
  }),
  bindActions({ patientRegisterActions }),
);
class PatientProfile extends React.Component {
  componentDidMount() {
    const query = parseQuery(this.props.location.search);
    this.props.patientRegisterActions.getDetailsInfo(query.patientKey);
  }

  openPatientDetailsView = () => {
    const query = parseQuery(this.props.location.search);
    this.props.history.push(
      createUrl(Routes.PatientDetailsEdit, {
        query: {
          patientKey: query.patientKey,
        },
      }),
    );
  };

  render() {
    const { session, canEdit } = this.props;
    const { patientInfo } = this.props.patientRegister;

    return (
      <div className="patient-profile">
        <div className="default-page-title clearfix text-center">
          <Back className="pull-left" onClick={() => this.props.history.goBack()} />
          {canEdit && (
            <button className="btn btn-link pull-right" onClick={this.openPatientDetailsView}>
              EDIT
            </button>
          )}
          <div className="title">
            Patient Details{" "}
            <strong>
              Chart:#
              {patientInfo.chartNumber}
            </strong>
          </div>
        </div>
        <div className="patient-profile-body">
          <div className="head-title">General Info</div>
          <div className="content-box">
            <div className="content-item">
              <div className="item">
                <div className="title">First Name:</div>
                <div className="body">{patientInfo.firstName}</div>
              </div>

              <div className="item">
                <div className="title">Middle Name:</div>
                <div className="body">{patientInfo.middleName}</div>
              </div>

              <div className="item">
                <div className="title">last Name:</div>
                <div className="body">{patientInfo.lastName}</div>
              </div>

              <div className="item">
                <div className="title">Nationality:</div>
                <div className="body">
                  {patientInfo.nationality && patientInfo.nationality.name}
                </div>
              </div>
              <div className="item">
                <div className="title">Gender:</div>
                <div className="body">{patientInfo.gender && patientInfo.gender.name}</div>
              </div>
              <div className="item">
                <div className="title">Marital Status:</div>
                <div className="body">
                  {patientInfo.maritalStatus && patientInfo.maritalStatus.name}
                </div>
              </div>
              <div className="item">
                <div className="title">Weight:</div>
                <div className="body">{patientInfo.weight}</div>
              </div>
              <div className="item">
                <div className="title">Additional Information:</div>
                <div className="body">{patientInfo.additionalInformation}</div>
              </div>
              <div className="item">
                <div className="title">Birth Date:</div>
                <div className="body">{moment(patientInfo.dateOfBirth).format("DD/MM/YYYY")}</div>
              </div>
              <div className="item">
                <div className="title">Student Status:</div>
                <div className="body">{patientInfo.student ? "YES" : "NO"}</div>
              </div>
              <div className="item">
                <div className="title">VIP Patient:</div>
                <div className="body">{patientInfo.vipPatient ? "YES" : "NO"}</div>
              </div>
              <div className="item">
                <div className="title">Workplace:</div>
                <div className="body">{patientInfo.workPlace}</div>
              </div>
              <div className="item">
                <div className="title">Referral:</div>
                <div className="body">
                  {patientInfo.referralType &&
                    (patientInfo.referralType.code === "REFERRAL_TYPE_OTHER"
                      ? patientInfo.referralType.description
                      : patientInfo.referralType.name)}
                </div>
              </div>
            </div>
          </div>
          <Show if={_.get(session, "company.id") !== 93}>
            <div>
              <div className="head-title">Authentication id</div>
              <div className="content-box">
                <div className="content-item">
                  <div className="item">
                    <div className="title">Social Security Number:</div>
                    <div className="body">{patientInfo.documentSocialSecurityNumber}</div>
                  </div>
                  <div className="item">
                    <div className="title">Driver's Licence Number:</div>
                    <div className="body">{patientInfo.documentDriverLicenseNumber}</div>
                  </div>
                  <div className="item">
                    <div className="title">Social Insurance Number:</div>
                    <div className="body">{patientInfo.documentSocialInsuranceNumber}</div>
                  </div>
                  <div className="item">
                    <div className="title">Passport Number:</div>
                    <div className="body">{patientInfo.documentPasswordNumber}</div>
                  </div>
                  <div className="item">
                    <div className="title">National ID Number:</div>
                    <div className="body">{patientInfo.documentNationalIdNumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </Show>
        </div>
      </div>
    );
  }
}
export default enhancer(PatientProfile);
