import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SortPicker from "../../widgets/SortPicker";
import bindActions from "../../../helpers/bindActions";
import * as transactionActions from "../../../actions/transactionActions";
import { TreatmentCodesAutocompleteWrapper } from "../../../wrappers/TreatmentCodesAutocompleteWrapper";
import refreshImage from "../../../assets/images/calendar-bottom-menu/refresh.png";
import { SelectPicker } from "../../select-picker/SelectPicker";
import { DatePicker } from "../../ui/DatePicker";
import { DoctorSelectWrapper } from "../../doctor/DoctorSelectWrapper";

const ChartTransactionHeaderStyled = styled.div`
  display: flex;
  min-height: 34px;

  & > .fluid-cell {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const APPROVAL_FILTER_OPTIONS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
  {
    value: "Pending",
    label: "Pending",
  },
];

const enhancer = connect(
  ({ session, auth: { clinics } }) => ({
    session,
    clinics,
  }),
  bindActions({ transactionActions }),
);
export default enhancer(
  class ChartTransactionHeader extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        doctorsCount: 0,
      };
    }
    render() {
      const { elements, transactionActions, transaction, teeth, session, clinics } = this.props;

      const toothType =
        _.get(session, "toothNumericType.code") === "Index1" ? "numeric1" : "numeric2";

      const tooth = _.map(teeth, (x) => {
        const id = _.get(x, ["normal", "details", "id"]);
        const code = _.get(x, ["normal", "details", toothType]);

        if (_.toFinite(code) > 0) {
          return {
            value: id,
            label: code,
          };
        }

        return "";
      })
        .sort((a, b) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;
          return 0;
        })
        .filter((x) => Boolean(x));

      const allClinics = [
        {
          value: "all",
          label: "All Clinics",
        },
      ];

      const clinicList = clinics.map((x) => ({
        value: x.id,
        label: x.name,
      }));

      const doctors = this.props.transaction.doctors.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      return elements.mixedSortBtn.visible || elements.sortBtn.visible ? (
        <ChartTransactionHeaderStyled>
          {elements.sortBtn.visible ? (
            <div className="fluid-cell">
              <SortPicker element={elements.sortBtn} />
            </div>
          ) : null}

          {elements.mixedSortBtn.visible ? (
            <div className="fluid-cell">
              <SortPicker element={elements.mixedSortBtn} />
            </div>
          ) : null}

          <div className="fluid-cell">
            <SelectPicker
              className="clinic-filter"
              placeholder="Filter by clinic"
              value={this.props.transaction.clinicFilter}
              options={[...allClinics, ...clinicList]}
              onChange={this.props.transactionActions.clinicFilterChange}
            />
          </div>

          <div className="fluid-cell">
            <DoctorSelectWrapper
              isMulti={true}
              options={doctors}
              isSearchable={true}
              allLabel="All doctors"
              value={this.props.transaction.doctorFilter}
              placeholder={localStorage.getItem("doctorLabel")}
              onChange={this.props.transactionActions.doctorFilterChange}
            />
          </div>

          <div className="fluid-cell">
            <SelectPicker
              className="approval-filter"
              placeholder="Filter by Approval"
              options={APPROVAL_FILTER_OPTIONS}
              value={{ value: transaction.approvalFilter }}
              onChange={(v) => transactionActions.changeApprovalFilter(v.value)}
            />
          </div>

          <div className="fluid-cell">
            <SelectPicker
              isMulti={true}
              options={tooth}
              isSearchable={true}
              className="tooth-filter"
              isClearable={true}
              placeholder="Filter by tooth"
              value={this.props.transaction.toothFilter}
              onChange={this.props.transactionActions.toothFilterChange}
            />
          </div>

          <div className="fluid-cell">
            <TreatmentCodesAutocompleteWrapper
              placeholder="Filter by code"
              inputClassName="form-control filter-by-code"
              onSelect={(x) => {
                this.props.transactionActions.codeFilterChange(x);
              }}
            />
          </div>

          <div className="fluid-cell">
            <DatePicker
              className="form-control"
              placeholder="Start Date"
              selected={this.props.transaction.startDateFilter}
              onChange={this.props.transactionActions.startDateFilterChange}
            />
          </div>
          <div className="fluid-cell">
            <DatePicker
              placeholder="End Date"
              className="form-control"
              selected={this.props.transaction.endDateFilter}
              onChange={this.props.transactionActions.endDateFilterChange}
            />
          </div>

          <div
            role="button"
            style={{
              padding: 0,
              width: "34px",
              height: "34px",
              display: "flex",
              marginLeft: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="btn btn-primary"
            onClick={() => this.props.transactionActions.getAppointmentTransactions(false, true)}
          >
            <img alt="refresh" src={refreshImage} />
          </div>
        </ChartTransactionHeaderStyled>
      ) : null;
    }
  },
);
