import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import FilledImage from "../../widgets/FilledImage";
import { lineWrap } from "../../../helpers/StringUtils";
import InfoImage from "../../../assets/images/information.png";

DescriptionCell.propTypes = {
  text: PropTypes.string,
  id: PropTypes.any,
  onShowTooltip: PropTypes.func,
  onHideTooltip: PropTypes.func,
};

export function DescriptionCell({ id, text, onShowTooltip, onHideTooltip }) {
  if (!text) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <span style={{ flex: 1, marginRight: "8px" }}>{text}</span>
      <div
        role="button"
        data-tip={true}
        data-event="click"
        data-event-off="mouseleave"
        onClick={(e) => e.stopPropagation()}
        data-for={`description-tooltip-${id}`}
      >
        <FilledImage src={InfoImage} color="#ffffff" zoom={81} />
      </div>

      <ReactTooltip
        html={true}
        type="light"
        multiline={true}
        afterShow={onShowTooltip}
        afterHide={onHideTooltip}
        id={`description-tooltip-${id}`}
      >
        {lineWrap(text)}
      </ReactTooltip>
    </div>
  );
}
