import _ from "lodash";
import createReducer from "../helpers/createReducer";

import { CALL_LIST_CLOSE_MODAL_WINDOW } from "../actions/callListActions";

import * as ActionType from "../actionTypes/patientViewActionTypes";
import * as appointmantActionTypes from "../actionTypes/appointmantActionTypes";
// import Utils from "../helpers/Utils";

const defaultState = {
  patientKey: "",
  patientDetails: {},
  patientTerminalDetails: {},
  medicalAlerts: {},
  patientAccessModules: {},
  appointments: {},
  notes: [],
  insuranceNotes: [],
  modalVisible: false,
  modalType: "",
  clinicians: [],
  clinicianModalVisible: false,
  clinicianAddMode: false,
  clinicianAll: [],
  selectedClinician: {},
  companyTempPatientId: 0,

  activeNote: {},
  noteEditVisible: false,

  companyPatientReminder: {},
  patientReminderModalVisible: false,

  patientFamilyMembers: [],
};

const reducers = {
  [ActionType.GET_PATIENT_FAMILY_MEMBERS_SUCCESS](state, { data }) {
    return {
      ...state,
      patientFamilyMembers: data,
    };
  },
  [appointmantActionTypes.SET_COMPANY_TEMP_PATIENT_ID](state, { companyTempPatientId }) {
    return {
      ...state,
      companyTempPatientId,
    };
  },
  [CALL_LIST_CLOSE_MODAL_WINDOW](state) {
    return {
      ...state,
      modalVisible: false,
    };
  },
  [ActionType.INIT_PATIENT_VIEW](state, { patientKey, companyTempPatientId }) {
    return {
      ...state,
      patientKey,
      companyTempPatientId,
      modalVisible: false,
    };
  },
  [ActionType.PATIENT_VIEW_GET_DASHBOARD_SUCCESS](state, { data }) {
    // data.patientDetails.age=Utils.getAge(data.patientDetails.dateOfBirth)
    return { ...state, ...data };
  },
  [ActionType.PATIENT_VIEW_GET_TERMINAL_DETAILS_SUCCESS](state, { data }) {
    return {
      ...state,
      patientTerminalDetails: data,
    };
  },
  [ActionType.PATIENT_VIEW_GET_NOTES_SUCCESS](state, { data }) {
    return { ...state, notes: data.list || [] };
  },
  [ActionType.PATIENT_VIEW_GET_INSURANCE_NOTES_SUCCESS](state, { data }) {
    return { ...state, insuranceNotes: data.list || [] };
  },
  [ActionType.PATIENT_VIEW_HIDE_MODAL](state) {
    return { ...state, modalVisible: false };
  },
  [ActionType.PATIENT_VIEW_SHOW_MODAL](state, { modalType, patientKey }) {
    return {
      ...state,
      modalVisible: true,
      modalType,
      modalPatientKey: patientKey,
    };
  },
  [ActionType.PATIENT_VIEW_GET_MEMBERS_SUCCESS](state, { data }) {
    const selectedClinician = _.keyBy(data, "id");

    return {
      ...state,
      clinicians: data || [],
      selectedClinician,
    };
  },
  [ActionType.PATIENT_VIEW_SHOW_CLINICIAN_MODAL](state) {
    return {
      ...state,
      clinicianModalVisible: true,
    };
  },
  [ActionType.PATIENT_VIEW_CLOSE_CLINICIAN_MODAL](state) {
    return {
      ...state,
      clinicianModalVisible: false,
    };
  },
  [ActionType.PATIENT_VIEW_TOGGLE_CLINICIAN_MODE](state) {
    return {
      ...state,
      clinicianAddMode: !state.clinicianAddMode,
    };
  },
  [ActionType.PATIENT_VIEW_GET_MEMBERS_ALL_SUCCESS](state, { data }) {
    return {
      ...state,
      clinicianAll: data || [],
    };
  },
  [ActionType.PATIENT_VIEW_TOGGLE_CLINICIAN](state, { id }) {
    const selectedClinician = { ...state.selectedClinician };

    selectedClinician[id] = !selectedClinician[id];

    return {
      ...state,
      selectedClinician,
    };
  },
  [ActionType.PATIENT_VIEW_SET_CLINICIAN_SEARCH_VALUE](state, { value }) {
    return {
      ...state,
      clinicianSearchValue: value,
    };
  },
  [ActionType.PATIENT_VIEW_OPEN_NOTE_EDIT](state, { item }) {
    return {
      ...state,
      activeNote: item,
      noteEditVisible: true,
    };
  },
  [ActionType.PATIENT_VIEW_CLOSE_NOTE_EDIT](state) {
    return {
      ...state,
      noteEditVisible: false,
    };
  },
  [ActionType.PATIENT_VIEW_PATIENT_REMINDERS_MODAL_SHOW](state) {
    return {
      ...state,
      patientReminderModalVisible: true,
    };
  },
  [ActionType.PATIENT_VIEW_PATIENT_REMINDERS_MODAL_HIDE](state) {
    return {
      ...state,
      patientReminderModalVisible: false,
    };
  },
  [ActionType.PATIENT_VIEW_SAVE_REMINDER_SUCCESS](state, { request }) {
    return {
      ...state,
      companyPatientReminder: request,
    };
  },
};

export default createReducer(defaultState, reducers);
