import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Modal } from "../../../ui/Modal";
import Show from "../../../widgets/Show";

import ChartClinicalNoteQuestion from "./ChartClinicalNoteQuestion";
import ChartClinicalNoteCreatedDate from "./ChartClinicalNoteCreatedDate";

import { getNote } from "../../../../helpers/clinicNote";
import { getDiagnosisCodeList, getTreatmentCodeList } from "../../../../actions/appointmentActions";
import {
  changeCreatedDate,
  getEmpty,
  initEdit,
  nextQuestion,
  popStack,
  prevQuestion,
  selectQuestion,
  saveItem,
  setQuestionsModalVisible,
  setSelectDiagnosisModalVisible,
  setSelectTreatmentModalVisible,
  setAppointmentTime,
  skip,
  setSelectTreatmentExistingModalVisible,
} from "../../../../actions/chartSideClinicalNoteActions";
import questionTypeCode from "../../../../constants/chart-sidebar/questionTypeCodes";
import { Routes } from "../../../../constants/Routes";
import { withRouter } from "react-router";
import { SidebarHeader, SidebarHeaderType } from "../../../sidebar/SidebarHeader";
import moment from "moment/moment";
import { FULL_DATE_FORMAT } from "../../../../constants/Constants";
import { STATUS_TYPES } from "../../../../constants/StatusTypes";

const enhancer = connect(({ chart }) => ({ chart }), {
  getDiagnosisCodeList,
  getTreatmentCodeList,
  initEdit,
  getEmpty,
  popStack,
  prevQuestion,
  nextQuestion,
  selectQuestion,
  saveItem,
  skip,
  changeCreatedDate,
  setQuestionsModalVisible,
  setSelectTreatmentModalVisible,
  setSelectTreatmentExistingModalVisible,
  setAppointmentTime,
  setSelectDiagnosisModalVisible,
});
export default withRouter(
  enhancer(
    class ChartClinicalNoteItemEdit extends React.Component {
      static propTypes = {
        chartSideClinicalNote: PropTypes.object,
        clinic: PropTypes.object,
        chart: PropTypes.object,
        teeth: PropTypes.object,
        toothNumericType: PropTypes.object,
        appointmentId: PropTypes.number,
        initEdit: PropTypes.func,
        prevQuestion: PropTypes.func,
        nextQuestion: PropTypes.func,
        selectQuestion: PropTypes.func,
        saveItem: PropTypes.func,
        skip: PropTypes.func,
        getDiagnosisCodeList: PropTypes.func,
        getTreatmentCodeList: PropTypes.func,
        changeCreatedDate: PropTypes.func,
        getEmpty: PropTypes.func,
        popStack: PropTypes.func,
        edit: PropTypes.bool,
        selectTreatmentToCompleteModalVisible: PropTypes.bool,
      };

      constructor(props) {
        super(props);

        this.state = {
          showAlert: false,
          buttonTimerId: 0,
          buttonVisible: false,
          defaultAnswersButton: false,
          backConfirmModalVisible: false,
        };
      }

      UNSAFE_componentWillMount() {
        this.props.initEdit();

        if (!this.props.edit) {
          this.props.getEmpty();
        }
      }

      componentDidMount() {
        const {
          appointmentId,
          chartSideClinicalNote: { item, currentQuestionIndex },
        } = this.props;

        if (appointmentId) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { answers, ...currentQuestion } = _.get(
            item,
            `templateQuestionsAndAnswers.${currentQuestionIndex}`,
            {},
          );

          const questionType = _.get(currentQuestion, "questionType.code");

          if (questionType === questionTypeCode.DIAGNOSES) {
            this.props.getDiagnosisCodeList(appointmentId);
          }

          if (questionType === questionTypeCode.TREATMENTS_COMPLETED) {
            this.props.getTreatmentCodeList(appointmentId);
          }
        }
        document.addEventListener("keydown", this.popUpEsc);
      }

      componentDidUpdate(prevProps) {
        const {
          chartSideClinicalNote: { item: prevItem, currentQuestionIndex: prevCurrentQuestionIndex },
        } = prevProps;
        const {
          appointmentId,
          chartSideClinicalNote: { item: nextItem, currentQuestionIndex: nextCurrentQuestionIndex },
        } = this.props;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { answers: prevAnswers, ...prevCurrentQuestion } = _.get(
          prevItem,
          `templateQuestionsAndAnswers.${prevCurrentQuestionIndex}`,
          {},
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { answers: nextAnswers, ...nextCurrentQuestion } = _.get(
          nextItem,
          `templateQuestionsAndAnswers.${nextCurrentQuestionIndex}`,
          {},
        );

        if (appointmentId) {
          if (!_.isEqual(prevCurrentQuestion, nextCurrentQuestion)) {
            const questionType = _.get(nextCurrentQuestion, "questionType.code");

            if (questionType === questionTypeCode.DIAGNOSES) {
              this.props.getDiagnosisCodeList(appointmentId);
            }

            if (questionType === questionTypeCode.TREATMENTS_COMPLETED) {
              this.props.getTreatmentCodeList(appointmentId);
            }
          }
        }
      }

      setButtonVisible = () => {
        this.setState({ buttonVisible: true });
        if (this.state.buttonTimerId) {
          clearTimeout(this.state.buttonTimerId);
        }
        const buttonTimerId = setTimeout(() => {
          this.setState({
            buttonVisible: false,
          });
        }, 500);
        this.setState({ buttonTimerId });
      };

      checkRequireQuestion = (question) => {
        const answers = _.get(question, "answers");
        const isRequired = _.get(question, "required");

        if (isRequired && (_.isEmpty(answers) || !(answers || []).length)) {
          return false;
        }

        if (isRequired && !_.isEmpty(answers) && (answers || []).length) {
          return true;
        }

        if (!isRequired) {
          return true;
        }
      };

      clickNote = (event) => {
        if (event.target.classList.contains("fill-in-form-answer")) {
          this.props.selectQuestion(event.target.dataset.index);
        }
      };

      popUpEsc = (event) => {
        if (event.key === "Escape") {
          this.setState({
            backConfirmModalVisible: !this.state.backConfirmModalVisible,
          });
        }
      };

      componentWillUnmount() {
        document.removeEventListener("keydown", this.popUpEsc);
      }
      render() {
        const {
          teeth,
          toothNumericType,
          edit,
          chartSideClinicalNote,
          chartSideClinicalNote: { item, currentQuestionIndex },
          selectTreatmentToCompleteModalVisible,
          clinic,
          chart,
          appointmentId,
        } = this.props;

        let firstQuestion = false;
        let lastQuestion = false;
        let noQuestions = false;

        const count =
          (item.templateQuestionsAndAnswers && item.templateQuestionsAndAnswers.length) || 0;

        if (currentQuestionIndex + 1 === count) {
          lastQuestion = true;
        }

        if (currentQuestionIndex === 0) {
          firstQuestion = true;
        }

        if (count === 0) {
          noQuestions = true;
        }

        const btnWrapStyle = {
          width:
            (firstQuestion || noQuestions) && !selectTreatmentToCompleteModalVisible
              ? "100%"
              : "50%",
        };
        const saveBtnStyle = {
          width:
            ((firstQuestion || noQuestions) && selectTreatmentToCompleteModalVisible) ||
            lastQuestion ||
            noQuestions
              ? "50%"
              : "100%",
        };

        const currentQuestion = _.get(
          item,
          `templateQuestionsAndAnswers.${currentQuestionIndex}`,
          {},
        );

        const note = getNote(item, toothNumericType, false, clinic);
        const questionType = _.get(currentQuestion, "questionType.code");
        if (!this.state.defaultAnswersButton && questionType === questionTypeCode.FREE_FORM_ENTRY) {
          this.setState({ defaultAnswersButton: true });
        }

        if (this.state.defaultAnswersButton && questionType !== questionTypeCode.FREE_FORM_ENTRY) {
          this.setState({ defaultAnswersButton: false });
        }

        return (
          <div className="chart-clinical-note">
            <div className="clinical-note-item">
              <Modal
                size={"sm"}
                show={this.state.backConfirmModalVisible}
                onHide={() =>
                  this.setState({
                    backConfirmModalVisible: !this.state.backConfirmModalVisible,
                  })
                }
                actions={
                  <div>
                    <button
                      className="btn btn-default mr-2"
                      onClick={() => {
                        this.setState({
                          backConfirmModalVisible: !this.state.backConfirmModalVisible,
                        });
                      }}
                    >
                      No
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({
                          backConfirmModalVisible: !this.state.backConfirmModalVisible,
                        });
                        this.props.popStack();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                }
              >
                Do you want to close?
              </Modal>
              <SidebarHeader
                backButtonClassName="flex-grow-1"
                rightComponentClassName="flex-grow-1"
                type={SidebarHeaderType.Secondary}
                onBackClick={() =>
                  this.setState({
                    backConfirmModalVisible: !this.state.backConfirmModalVisible,
                  })
                }
                title={
                  <Show if={item.noteTemplate && item.noteTemplate.name}>
                    <input
                      style={{ maxWidth: "200px" }}
                      type="text"
                      className="form-control"
                      ref="name"
                      defaultValue={
                        item.noteTemplate && _.get(item, "name")
                          ? _.get(item, "name")
                          : _.get(item, ["noteTemplate", "name"])
                      }
                    />
                  </Show>
                }
                rightComponent={
                  <div className="d-flex flex-column justify-content-end align-items-end mr-2 flex-grow-1">
                    <div className="d-flex justify-content-start align-items-start">
                      <span className="mr-2">{item.patient && item.patient.chartNumber}</span>
                      <span className="mr-2">{item.patient && item.patient.fullName}</span>
                      {chart?.appointment?.date && (
                        <span className="mr-2">
                          Appt date: {moment(chart?.appointment.date).format(FULL_DATE_FORMAT)}
                        </span>
                      )}
                      <span className="mr-2">
                        Being Seen date:
                        {chart?.appointment?.actualStartTime
                          ? moment(chart?.appointment.actualStartTime).format(FULL_DATE_FORMAT)
                          : ""}
                      </span>
                      <span className="mr-2">
                        Completed date:
                        {chart?.appointment?.modifiedDate
                          ? moment(chart?.appointment.modifiedDate).format(FULL_DATE_FORMAT)
                          : ""}
                      </span>
                    </div>
                  </div>
                }
              />
              <div className="clinical-note-item-edit-body">
                <div className="template">
                  <div onClick={this.clickNote} dangerouslySetInnerHTML={{ __html: note }} />
                  <Show if={edit}>
                    <div className="clinical-note-item-datepicker">
                      {/*could be updated with permission*/}
                      <ChartClinicalNoteCreatedDate
                        changeCreatedDate={this.props.changeCreatedDate}
                        item={item}
                      />
                    </div>
                  </Show>
                </div>
                <div className="questions">
                  <div className="d-flex" style={{ height: "100%" }}>
                    <Show if={!firstQuestion && !noQuestions}>
                      <div className="" style={{ height: "99%", paddingRight: "0.5rem" }}>
                        <button
                          disabled={this.state.buttonVisible}
                          onClick={() => {
                            this.setButtonVisible();
                            this.props.prevQuestion();
                          }}
                          className="btn btn-danger btn-lg btn-block"
                          style={{
                            height: "100%",
                            fontSize: "2rem",
                            padding: "1rem",
                          }}
                        >
                          &lt;
                        </button>
                      </div>
                    </Show>
                    <div style={{ width: "100%" }}>
                      <div
                        className="question d-flex align-items-center justify-content-between"
                        style={{ height: "6%" }}
                      >
                        <span>{currentQuestion.question}</span>
                        <Show if={this.state.defaultAnswersButton}>
                          <div>
                            <Show if={chart?.appointment?.actualStartTime}>
                              <button
                                onClick={() => this.props.setAppointmentTime(true)}
                                className="btn btn-primary mr-2"
                              >
                                Add time
                              </button>
                            </Show>
                            <button
                              onClick={this.props.setSelectTreatmentExistingModalVisible}
                              className="btn btn-primary mr-2"
                            >
                              Existing
                            </button>
                            <button
                              onClick={this.props.setSelectTreatmentModalVisible}
                              className="btn btn-primary mr-2"
                            >
                              Treatment
                            </button>
                            <button
                              onClick={this.props.setSelectDiagnosisModalVisible}
                              className="btn btn-primary mr-2"
                            >
                              Diagnosis
                            </button>
                            <button
                              onClick={this.props.setQuestionsModalVisible}
                              className="btn btn-primary"
                            >
                              Default answers
                            </button>
                          </div>
                        </Show>
                      </div>
                      <div className="body" style={{ height: "92%" }}>
                        <ChartClinicalNoteQuestion
                          teeth={teeth}
                          chartSideClinicalNote={chartSideClinicalNote}
                          toothNumericType={toothNumericType}
                          question={currentQuestion}
                        />
                      </div>
                    </div>
                    <Show if={!lastQuestion && !noQuestions}>
                      <div className="" style={{ height: "99%", paddingLeft: "0.5rem" }}>
                        <button
                          disabled={this.state.buttonVisible}
                          onClick={() => {
                            this.setButtonVisible();
                            this.checkRequireQuestion(currentQuestion)
                              ? this.props.nextQuestion()
                              : this.setState({ showAlert: true });
                          }}
                          className="btn btn-primary btn-lg btn-block"
                          style={{
                            height: "100%",
                            fontSize: "2rem",
                            padding: "1rem",
                          }}
                        >
                          &gt;
                        </button>
                      </div>
                    </Show>
                  </div>
                  <div className="btns clearfix" style={{ display: "flex" }}>
                    <Show if={!noQuestions}>
                      <div className="btn-wrap" style={saveBtnStyle}>
                        <button
                          onClick={() =>
                            this.checkRequireQuestion(currentQuestion)
                              ? this.props
                                  .saveItem(this.refs.name && this.refs.name.value, appointmentId)
                                  .then((response) => {
                                    if (_.get(response, "response")) {
                                      this.props.history.push(Routes.DashboardCalendar);
                                    }
                                  })
                              : this.setState({ showAlert: true })
                          }
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Save
                        </button>
                      </div>
                      <div className="btn-wrap" style={saveBtnStyle}>
                        <button
                          onClick={() =>
                            this.checkRequireQuestion(currentQuestion)
                              ? this.props
                                  .saveItem(
                                    this.refs.name && this.refs.name.value,
                                    appointmentId,
                                    true,
                                  )
                                  .then((response) => {
                                    if (_.get(response, "response")) {
                                      this.props.history.push(Routes.DashboardCalendar);
                                    }
                                  })
                              : this.setState({ showAlert: true })
                          }
                          className="btn btn-danger btn-lg btn-block"
                        >
                          Save [Send to HIE]
                        </button>
                      </div>
                    </Show>
                    <Show
                      if={(firstQuestion || noQuestions) && selectTreatmentToCompleteModalVisible}
                    >
                      <div className="btn-wrap" style={btnWrapStyle}>
                        <button
                          disabled={this.state.buttonVisible}
                          onClick={() => {
                            this.setButtonVisible();
                            this.props.skip().then((response) => {
                              if (_.get(response, "response")) {
                                if (chart.selectTreatmentToCompleteModalMode !== "post")
                                  this.props.history.push(Routes.DashboardCalendar);
                              }
                            });
                          }}
                          className="btn btn-danger btn-lg btn-block"
                        >
                          Skip
                        </button>
                      </div>
                    </Show>
                    {/*<Show if={lastQuestion || noQuestions}>*/}
                    {/*  <div className="btn-wrap" style={btnWrapStyle}>*/}
                    {/*    <button*/}
                    {/*      disabled={this.state.buttonVisible}*/}
                    {/*      onClick={() => {*/}
                    {/*        this.setButtonVisible();*/}
                    {/*        this.props*/}
                    {/*          .saveItem(this.refs.name && this.refs.name.value, appointmentId)*/}
                    {/*          .then((response) => {*/}
                    {/*            if (_.get(response, "response")) {*/}
                    {/*              this.props.history.push(Routes.DashboardCalendar);*/}
                    {/*            }*/}
                    {/*          });*/}
                    {/*      }}*/}
                    {/*      className="btn btn-primary btn-lg btn-block"*/}
                    {/*    >*/}
                    {/*      Done*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</Show>*/}
                  </div>
                </div>
                <Show if={false}>
                  {/*could be updated with permission*/}
                  <ChartClinicalNoteCreatedDate
                    changeCreatedDate={this.props.changeCreatedDate}
                    item={item}
                  />
                </Show>
              </div>
            </div>

            <Modal
              size="small"
              show={this.state.showAlert}
              onHide={() => this.setState({ showAlert: false })}
              actions={<Button onClick={() => this.setState({ showAlert: false })}>Close</Button>}
              keyboard={false}
            >
              <div>Question is mandatory</div>
            </Modal>
          </div>
        );
      }
    },
  ),
);
