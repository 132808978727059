import React, { useState } from "react";
import { ChromePicker } from "react-color";

import Back from "../widgets/Back";

import { defaultColors, other } from "../../constants/defaults";
import Colors from "../../helpers/Colors";
import Utils from "../../helpers/Utils";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const enhancer = connect(({ auth: { members } }) => ({ members }));

function AppointmentTypeColor(props) {
  const history = useHistory();

  const [background, setBackground] = useState(defaultColors.Green.color);

  const save = () => {
    let item = JSON.parse(JSON.stringify(other));
    item.color = background;

    Colors.selectAppointmentDoctor(props.match.params.doctorId, item);
    history.goBack();
  };

  const handleChangeComplete = (color) => {
    setBackground(color.hex);
  };
  return (
    <div className="settings-body">
      <div className="title">
        <Back />
        <button onClick={save} className="btn btn-link btn-color-right" type="button">
          Save
        </button>
        <div className="name">{Utils.getFullName(props.members[props.match.params.doctorId])}</div>
      </div>
      <div className="body">
        <ChromePicker color={background} onChangeComplete={handleChangeComplete} />
      </div>
    </div>
  );
}

export default enhancer(AppointmentTypeColor);
