import React, { useState } from "react";
import addIcon from "../../assets/images/add.png";
import { makeStyles } from "@material-ui/styles";
import cx from "classnames";
import TimeSlotList from "./TimeSlotList";
import { useHistory } from "react-router";
import Show from "./Show";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "48px",
    color: "black",
    border: "1px solid #ccc",
    padding: "2px 8px",
    borderRadius: 3,
    cursor: "pointer",
  },
  icon: {
    width: 15,
    height: 15,
  },
}));

const TimeSlotPicker = ({ className, select, elements, item, ...props }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const isAppointment = history.location.pathname.split("/")[3];
  return (
    <div>
      <div
        {...props}
        className={cx(classes.root, className)}
        onClick={() => (isAppointment == "0" || !select.disabled) && setIsOpen(!isOpen)}
      >
        {/*<p style={{ margin: 0 }}>{select?.selected?.name}</p>*/}
        <img className={classes.icon} src={addIcon} />
      </div>
      <Show if={isOpen}>
        <TimeSlotList
          options={select}
          closeModal={() => setIsOpen(false)}
          elements={elements}
          item={item}
        />
      </Show>
    </div>
  );
};

export default TimeSlotPicker;
