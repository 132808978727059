import { RefObject, useCallback, useEffect } from "react";

export interface OutsideClickProps {
  readonly active: boolean;
  readonly onClick: () => void;
  readonly ref: RefObject<HTMLElement>;
}

export function useOutsideClick({ ref, onClick, active }: OutsideClickProps) {
  const handleClickOutside = useCallback(
    (event) => {
      if (active && ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    },
    [active, onClick, ref],
  );

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [active, handleClickOutside]);
}
