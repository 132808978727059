import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tap from "../../widgets/Tap";
import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "../../../actions/chartSideSeriesActions";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));

class ChartSideGridCode extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    item: PropTypes.object,
    openItem: PropTypes.func,

    isMultiSelect: PropTypes.bool,
    isSelectItem: PropTypes.array,

    chartSideSeriesActions: PropTypes.object,
  };
  showImageSelector = (code, itemSeriesType) => {
    const { chartSideSeriesActions } = this.props;

    chartSideSeriesActions.showImageSelector(code, itemSeriesType);
  };

  render() {
    const { item, openItem, src, isMultiSelect, isSelectItem } = this.props;

    return (
      <Tap
        tag="button"
        className="list-group-item list-group-item-grid"
        short={() => openItem(item)}
        long={() => this.showImageSelector(item.code, item.$seriesType)}
        title={item.description}
      >
        <div className="favourite-icon" style={{ padding: 0 }}>
          <img alt="icon" src={src} />
        </div>
        <div className="code">{item.code}</div>

        {isMultiSelect && (
          <div style={{ position: "absolute", top: 0, left: "4px" }}>
            <input type="checkbox" checked={isSelectItem} />
          </div>
        )}
      </Tap>
    );
  }
}

export default enhancer(ChartSideGridCode);
