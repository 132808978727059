import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function RichTextEditor(props) {
  const { value, change, name } = props;

  return (
    <ReactQuill
      style={{ height: "85%", background: "#fff" }}
      theme="snow"
      value={value}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          [{ color: [] }, { background: [] }],
        ],
      }}
      onChange={(value) => {
        change(name, value);
      }}
    />
  );
}

export default RichTextEditor;

