import React, { useEffect, useState } from "react";
import { Sidebar, SidebarPosition } from "../sidebar/Sidebar";
import { connect, useDispatch } from "react-redux";
import { closePatientInfoSideBar } from "../../actions/patientActions";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import MemberImage from "../widgets/MemberImage";
import Utils from "../../helpers/Utils";
import {
  getCompanyEndOfDay,
  getCompanyFormatDate,
  getCompanyStartOfDay,
} from "../../helpers/DateUtils";
import { Button } from "../ui/Button";
import cx from "classnames";
import _ from "lodash";
import { withRouter } from "react-router";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import { IconButton } from "@mui/material";
import ClinicNoteImage from "../../assets/images/chart/ClinicalNotes@2x.png";
import NotesImage from "../../assets/images/appointment/notes.png";
import FilledImage from "../widgets/FilledImage";
import DocsImage from "../../assets/images/calendar-bottom-menu/images.png";
import PlannedIconImage from "../../assets/images/appointment/PlannedIcon@2x.png";
import HistoryImage from "../../assets/images/appointment/history.png";
import { makeStyles, useTheme } from "@material-ui/styles";
import { DocsWrapper } from "../chart/side-bar/docs/DocsWrapper";
import viewTypes from "../../constants/chart-sidebar/viewTypes";
import { onChangeNoteTab } from "../../actions/chartSideClinicalNoteActions";
import { hideSideBar, showSideBar } from "../../actions/chartActions";
import ChartSideBar from "../chart/side-bar/ChartSideBar";
import {
  getMissedCancelledList,
  missedCancelledAppointmentSetType,
  missedCancelledNotInterestedAppointment,
} from "../../actions/waitingListActions";
import { AppointmentPatientViewModalWrapper } from "../appointment-patient/AppointmentPatientViewModalWrapper";
import { AppointmentPlannedModalWrapper } from "../appointment-form/AppointmentPlannedModalWrapper";
import { getTransactionsForAppointment } from "../../actions/transactionActions";
import PatientAppointmentsList from "./PatientAppointmentsList";
import { Modal } from "../ui/Modal";
import { notInterestedAppointment } from "../../actions/appointmentActions";
import { referralNextVisitAppointmentSetType } from "../../actions/referralActions";
import PropTypes from "prop-types";
import { showMessage } from "../../actions/loaderActions";
import Show from "../widgets/Show";

const useStyles = makeStyles(() => ({
  root: { display: "flex", flexWrap: "wrap" },
}));

const enhancer = connect(
  ({
    chart,
    chartSideVital,
    patient,
    session,
    chartSideClinicalNote,
    chartSideDocs,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    waitingList: { missedCancelledFormValues, missedCancelledListActiveTab },
    referral: { searchInitialValues, referralNextVisitListActiveTab },
  }) => ({
    chart,
    chartSideVital,
    patient,
    session,
    chartSideClinicalNote,
    missedCancelledFormValues,
    chartSideDocs,
    searchInitialValues,
    referralNextVisitListActiveTab,
  }),
);
const PatientInfoSideBar = (props) => {
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [showReceptionNotes, setShowReceptionNotes] = useState(false);
  const [showPlanned, setShowPlanned] = useState(false);
  const [showAppointmentHistory, setShowAppointmentHistory] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const {
    patientInfoSideBarVisible,
    appointment: { patient },
  } = props.patient;
  const { appointment } = props.patient;
  const {
    history,
    location,
    chartSideVital,
    chart: { sideBarVisible, teeth, viewType },
    session: { clinicalNotesSideBarViewType, permissions, member, toothNumericType, clinic },
    chartSideClinicalNote,
    missedCancelledFormValues,
    missedCancelledListActiveTab,
    chartSideDocs,
    searchInitialValues,
    referralNextVisitListActiveTab,
  } = props;
  const isReferralNextVisitPage = location?.pathname?.includes("referral-next-visit-list");

  useEffect(() => {
    if (patient?.patientKey) {
      dispatch({ type: "CHART_SET_PATIENT_KEY", patientKey: patient.patientKey });
    }
  }, [patient]);

  if (!patient) return null;

  const name = [patient.firstName, patient.middleName, patient.lastName].filter(Boolean).join(" ");
  const isTitleEmpty = Object.values(patient.title || {}).filter(Boolean).length === 0;
  const _title = isTitleEmpty ? name : `${patient.title.name} ${name}`;

  const mobilePhoneNumber =
    (_.get(patient, "mobilePhoneNumber") &&
      !patient.mobilePhoneNumber.includes("undefined") &&
      patient.mobilePhoneNumber.length >= 9 &&
      patient.mobilePhoneNumber) ||
    "";
  const homePhoneNumber =
    (_.get(patient, "homePhoneNumber") &&
      !patient.homePhoneNumber.includes("undefined") &&
      patient.homePhoneNumber.length >= 9 &&
      patient.homePhoneNumber) ||
    "";
  const workPhoneNumber =
    (_.get(patient, "workPhoneNumber") &&
      !patient.workPhoneNumber.includes("undefined") &&
      patient.workPhoneNumber.length >= 9 &&
      patient.workPhoneNumber) ||
    "";
  const isTemporary = false;

  const setItemStatus = (status) => {
    let request;
    let data;
    if (status === "notInterested") {
      data = { ...appointment, notInterested: true };
      request = missedCancelledNotInterestedAppointment;
    } else {
      data = { id: appointment.id, [status]: true };
      request = missedCancelledAppointmentSetType;
    }
    dispatch(request(data)).then(() => {
      history.replace(
        createUrl(location.pathname, {
          query: {
            periodStart: getCompanyStartOfDay(missedCancelledFormValues.periodStart),
            periodEnd: getCompanyEndOfDay(missedCancelledFormValues.periodEnd),
          },
        }),
      );

      switch (missedCancelledListActiveTab) {
        case "missed":
          appointment.noAnswer = false;
          appointment.willCall = false;
          appointment.requestedReschedule = false;
          appointment.notInterested = false;
          break;
        case "requestedReschedule":
          appointment.requestedReschedule = true;
          appointment.noAnswer = false;
          appointment.willCall = false;
          appointment.notInterested = false;
          break;
        case "noAnswer":
          appointment.noAnswer = true;
          appointment.requestedReschedule = false;
          appointment.willCall = false;
          appointment.notInterested = false;
          break;
        case "willCall":
          appointment.willCall = true;
          appointment.noAnswer = false;
          appointment.requestedReschedule = false;
          appointment.notInterested = false;
          break;
        case "notInterested":
          appointment.notInterested = true;
          appointment.willCall = false;
          appointment.noAnswer = false;
          appointment.requestedReschedule = false;
          break;
        default:
          break;
      }
      dispatch(getMissedCancelledList(missedCancelledFormValues, 0));
    });
  };

  const updateAppointmentStatus = async ({
    status,
    appointment,
    formValues,
    activeTab,
    requestType,
    history,
    location,
    initFunction,
  }) => {
    let request;
    let data;

    if (status === "notInterested") {
      data = { ...appointment, notInterested: true };
      request = requestType.notInterested;
    } else {
      data = { id: appointment.id, [status]: true };
      request = requestType.setType;
    }

    try {
      await dispatch(request(data));

      history.replace(
        createUrl(location.pathname, {
          query: {
            periodStart: getCompanyStartOfDay(formValues.periodStart),
            periodEnd: getCompanyEndOfDay(formValues.periodEnd),
          },
        }),
      );

      appointment.noAnswer = activeTab === "noAnswer";
      appointment.willCall = activeTab === "willCall";
      appointment.notInterested = activeTab === "notInterested";

      if (!isReferralNextVisitPage) {
        appointment.requestedReschedule = activeTab === "requestedReschedule";
      }
      if (initFunction) {
        isReferralNextVisitPage ? initFunction(formValues) : dispatch(initFunction(formValues, 0));
      }
    } catch (error) {
      dispatch(showMessage(error));
    }
  };

  const handleStatusUpdate = (status) => {
    if (isReferralNextVisitPage) {
      updateAppointmentStatus({
        status: status,
        appointment,
        formValues: { ...searchInitialValues, referral: true },
        activeTab: referralNextVisitListActiveTab,
        requestType: {
          notInterested: notInterestedAppointment,
          setType: referralNextVisitAppointmentSetType,
        },
        history,
        location,
        initFunction: props?.initList,
      });
    } else {
      const data = {
        status: status,
        appointment,
        formValues: missedCancelledFormValues,
        activeTab: missedCancelledListActiveTab,
        requestType: {
          notInterested: missedCancelledNotInterestedAppointment,
          setType: missedCancelledAppointmentSetType,
        },
        history,
        location,
      };
      if (!props.handleSubmit) {
        data.initFunction = getMissedCancelledList;
      }
      updateAppointmentStatus(data).then(() => {
        if (props.handleSubmit) props.handleSubmit();
      });
    }
  };
  const canViewContacts = permissions.includes("VIEW_PATIENT_CONTACT_DETAILS");

  return (
    <Sidebar
      dialogClassName="patient-info-sidebar-wrapper"
      show={patientInfoSideBarVisible}
      onHide={() => dispatch(closePatientInfoSideBar())}
    >
      <SidebarHeader onBackClick={() => dispatch(closePatientInfoSideBar())} />
      <div className="d-flex flex-column pr-3 pl-3 flex-grow-1">
        <h3 className="text-center">{_title}</h3>

        <div className="d-flex pb-3 mb-3" style={{ borderBottom: "3px solid #bec844" }}>
          <MemberImage
            photoUrl={patient.photo && patient.photo.url}
            style={{ marginRight: "1rem", minWidth: "107px" }}
          />

          <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
            <div className="d-flex">
              <span className="mr-1">Chart No:</span>
              <b className="font-weight-bold">{patient.chartNumber}</b>
            </div>
            <div className="d-flex">
              <span className="mr-1">Age:</span>
              <b className="font-weight-bold">{Utils.getAge(patient.dateOfBirth)}</b>
            </div>
            <div className="d-flex">
              <span className="mr-1">DOB:</span>
              {/*@ts-ignore*/}
              <b className="font-weight-bold">{getCompanyFormatDate(patient.dateOfBirth)}</b>
            </div>
            <Show if={canViewContacts}>
              <div className="d-flex">
                <span
                  className="mr-1"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Mobile:
                </span>
                <b className="font-weight-bold" title={mobilePhoneNumber}>
                  {mobilePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Home:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={homePhoneNumber}
                >
                  {homePhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1">Work:</span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={workPhoneNumber}
                >
                  {workPhoneNumber}
                </b>
              </div>
              <div className="d-flex">
                <span className="mr-1" style={{ width: "70px" }}>
                  Email:
                </span>
                <b
                  className="font-weight-bold"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={patient.email}
                >
                  {patient.email}
                </b>
              </div>
            </Show>
          </div>
        </div>

        {/*<AppointmentPatientControlButtons*/}
        {/*  className="mb-3"*/}
        {/*  isTemporary={isTemporary}*/}
        {/*  onPlannedItemsClick={() => setShowPlanned(true)}*/}
        {/*  onReceptionNotesClick={() => setShowPatientModal(true)}*/}
        {/*  {...props}*/}
        {/*/>*/}
        <div className={cx(classes.root, "mb-3")}>
          <IconButton title="Appointment History" onClick={() => setShowAppointmentHistory(true)}>
            <img alt={""} src={HistoryImage} className="zoom-2x" />
          </IconButton>
          {!isTemporary && (
            <IconButton
              title="Clinical Notes"
              onClick={() => {
                dispatch(
                  onChangeNoteTab({
                    code: "yourNotes",
                    title: "My Notes",
                  }),
                );
                dispatch(showSideBar(viewTypes.clinicalNote, { patientKey: patient.patientKey }));
              }}
            >
              <img alt={""} src={ClinicNoteImage} className="zoom-2x" />
            </IconButton>
          )}
          {!isTemporary && (
            <IconButton title="Reception Notes" onClick={() => setShowReceptionNotes(true)}>
              <img alt={""} src={NotesImage} className="zoom-2x" />
            </IconButton>
          )}
          {!isTemporary && (
            <IconButton title="Planned Items" onClick={() => setShowPlanned(true)}>
              <img alt={""} src={PlannedIconImage} className="zoom-2x" />
            </IconButton>
          )}

          {!isTemporary && (
            <IconButton
              style={{ padding: "6px", paddingTop: "8px" }}
              title="Docs"
              onClick={() => {
                dispatch({
                  type: "PATIENT_VIEW_GET_DASHBOARD_SUCCESS",
                  data: { patientDetails: patient },
                });
                setShowDocsModal(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "41px",
                  height: "41px",
                  marginBottom: "2px",
                  borderRadius: "20.5px",
                  border: "2px solid #BFB800",
                }}
              >
                <FilledImage src={DocsImage} color={theme.palette.primary.main} />
              </div>
            </IconButton>
          )}
        </div>

        <div className="d-flex flex-column mb-2 flex-grow-1">
          <div className="d-flex">
            <Button
              fluid={true}
              type="button"
              onClick={() =>
                history.push(
                  createUrl(Routes.PatientView, {
                    params: {
                      patientKey: patient.patientKey,
                    },
                  }),
                )
              }
              className={cx({ "mr-1": !isTemporary })}
            >
              Patient Chart
            </Button>
            <Button
              fluid={true}
              type="button"
              onClick={() =>
                history.push(
                  createUrl(Routes.DashboardInsurance, {
                    params: { patientKey: _.get(patient, "patientKey") },
                  }),
                )
              }
              className={cx({ "mr-1": !isTemporary })}
            >
              Insurance Details
            </Button>
          </div>
          <div className="d-flex mt-3">
            <button
              style={{ flex: 1 }}
              className={`btn mr-1 ${appointment.notInterested ? "btn-success" : "btn-default"}`}
              onClick={() => handleStatusUpdate("notInterested")}
            >
              Not Interested
            </button>
            <button
              style={{ flex: 1 }}
              className={`btn mr-1 ${appointment.willCall ? "btn-success" : "btn-default"}`}
              onClick={() => handleStatusUpdate("willCall")}
            >
              Will Call
            </button>
          </div>
          <div className="d-flex mt-1">
            <button
              style={{ flex: 1 }}
              className={`btn mr-1 ${appointment.noAnswer ? "btn-success" : "btn-default"}`}
              onClick={() => handleStatusUpdate("noAnswer")}
            >
              No Answer
            </button>
            {!isReferralNextVisitPage && (
              <button
                style={{ flex: 1 }}
                className={`btn mr-1 ${
                  appointment.requestedReschedule ? "btn-success" : "btn-default"
                }`}
                onClick={() => handleStatusUpdate("requestedReschedule")}
              >
                Requested Reschedule
              </button>
            )}
          </div>
          <Show if={isReferralNextVisitPage}>
            <div className="mt-2 pr-1">
              <p style={{ marginBottom: "3px" }}>Appointment notes:</p>
              <span
                className="p-1"
                style={{
                  minHeight: "38px",
                  display: "flex",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                {_.get(appointment, "note", "")}
              </span>
            </div>

            <div className="mt-2 pr-1">
              <p style={{ marginBottom: "3px" }}>Doctor notes:</p>
              <span
                className="p-1"
                style={{
                  minHeight: "38px",
                  display: "flex",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                {_.get(appointment, "doctorNote", "")}
              </span>
            </div>
          </Show>
          <div className="d-flex mt-3 flex-grow-1 align-items-end justify-content-center">
            <button
              className="btn btn-success btn-lg flex-grow-1"
              onClick={() => history.push(`Appointment/${appointment.id}?goBack=true`)}
            >
              Book Appointment
            </button>
          </div>
        </div>
        <DocsWrapper
          position={SidebarPosition.Left}
          showDocsModal={showDocsModal}
          onShow={() => setShowDocsModal(true)}
          onHide={() => setShowDocsModal(false)}
        />
        <ChartSideBar
          clinicalNotesSideBarViewType={clinicalNotesSideBarViewType}
          onAppointment={true}
          location={location}
          history={history}
          sideBarVisible={sideBarVisible}
          position={SidebarPosition.Left}
          teeth={teeth}
          member={member}
          viewType={viewType}
          chartSideClinicalNote={chartSideClinicalNote}
          chartSideVital={chartSideVital}
          toothNumericType={toothNumericType}
          hideSideBar={() => dispatch(hideSideBar())}
          permissions={permissions}
          chartSideDocs={chartSideDocs}
          clinic={clinic}
          patientKey={patient?.patientKey}
        />

        <AppointmentPatientViewModalWrapper
          position={SidebarPosition.Left}
          show={showReceptionNotes}
          patientKey={patient.patientKey}
          onClose={() => setShowReceptionNotes(false)}
        />

        <AppointmentPlannedModalWrapper
          getList={(data) => dispatch(getTransactionsForAppointment(data))}
          show={showPlanned}
          appointmentData={appointment}
          patientKey={patient.patientKey}
          onClose={() => setShowPlanned(false)}
        />
        <Modal
          size="lg"
          className="patient-info-appointment-history-sidebar"
          show={showAppointmentHistory}
          onHide={() => setShowAppointmentHistory(false)}
          actions={
            <Button type="button" onClick={() => setShowAppointmentHistory(false)}>
              Close
            </Button>
          }
        >
          <PatientAppointmentsList
            onBackClick={() => setShowAppointmentHistory(false)}
            patientKey={patient.patientKey}
            appointmentId={appointment.id}
          />
        </Modal>
      </div>
    </Sidebar>
  );
};

PatientInfoSideBar.propTypes = {
  initList: PropTypes.func,
};

export default withRouter(enhancer(PatientInfoSideBar));
