import _ from "lodash";
import React from "react";
import cx from "classnames";

import Utils from "../../../helpers/Utils";

import { defaultColors } from "../../../constants/defaults";
import viewTypes from "../../../constants/chart-sidebar/viewTypes";
import surfaceCodes from "../../../constants/conditions/surfaceCodes";
import { ToothConditionTypes } from "../../../constants/conditions/ToothConditionTypes";

import Tap from "../../widgets/Tap";
import ChartToothImage from "./ChartToothImage";
import ChartToothNumber from "./ChartToothNumber";
import ChartSurfaceImage from "./ChartSurfaceImage";
import ChartToothCondition from "./ChartToothCondition";
import ChartSupernumeraryNumber from "./ChartSupernumeraryNumber";

export default function ChartTooth({
  up,
  tooth,
  showSideBar,
  showTooltip,
  activeTooth,
  toothPosition,
  eyeCheckedMap,
  toothNumericType,
  showChooseTooltip,
}) {
  const numericKey = toothNumericType?.code === "Index1" ? "numeric1" : "numeric2";
  let implantType = "";
  let conditions = [];
  if (tooth.normal) {
    conditions.push(tooth.normal.toothConditions);

    if (tooth.normal.toothChangeConditions) {
      conditions = conditions.concat(tooth.normal.toothChangeConditions);
    }
  }

  let toothColor = defaultColors.Green.color;
  let toothStatusCode = "TOOTHSTATUS_NORMAL";

  const layers = [];

  conditions = conditions.sort(sortCondition);

  conditions.forEach((condition, index) => {
    if (condition.revert) {
      return;
    }

    const treatmentId = _.get(condition, "treatment.id");
    const diagnosisId = _.get(condition, "diagnosisCode.id");
    const existingId = _.get(condition, "existingTreatment.id");
    const conditionType = _.get(condition, "conditionType.code");

    if (treatmentId > 0 && conditionType === "TOOTHCONDITION_START") {
      implantType = "PLANNED";
    } else if (treatmentId > 0 && conditionType === "TOOTHCONDITION_RESULT") {
      implantType = "COMPLETED";
    } else if (diagnosisId > 0) {
      implantType = "DIAGNOSIS";
    } else if (existingId > 0) {
      implantType = "EXISTING";
    }

    const color = getColor(condition);

    if (condition.toothStatus) {
      toothStatusCode = condition.toothStatus.code;
      toothColor = color;
    }

    if (condition.residualRoot) {
      toothStatusCode = "TOOTH_CONDITION_RESIDUAL_ROOT";
    }
    if (condition.destroyedCrown) {
      toothStatusCode = "TOOTH_CONDITION_DESTROYED_CROWN";
    }

    let needToShow;
    if (!condition.notChange) {
      needToShow =
        (condition.treatment &&
          condition.treatment.id &&
          condition.conditionType.code === "TOOTHCONDITION_START" &&
          eyeCheckedMap.Planned) ||
        (condition.treatment &&
          condition.treatment.id &&
          condition.conditionType.code === "TOOTHCONDITION_RESULT" &&
          eyeCheckedMap.Completed) ||
        (condition.existingTreatment && condition.existingTreatment.id && eyeCheckedMap.Existing) ||
        (condition.diagnosisCode && condition.diagnosisCode.id && eyeCheckedMap.Condition);
    } else {
      needToShow = eyeCheckedMap.Completed;
    }
    if (!needToShow) {
      return;
    }

    layers.push(
      <ChartToothCondition
        key={index}
        color={color}
        condition={surfaceCodesModel(condition)}
        toothImage={tooth.image}
        up={up}
        surfaceStrings={tooth.surfaceStrings}
        toothImageProps={{
          tooth: tooth,
          toothColor: toothColor,
          implantType: implantType,
          imageNumber: tooth.image,
          statusCode: toothStatusCode,
        }}
        surfaceImageProps={{
          toothColor: toothColor,
          imageNumber: tooth.image,
          statusCode: toothStatusCode,
        }}
      />,
    );
  });

  let toothStatusCodeSuper = "TOOTHSTATUS_NORMAL";

  if (tooth.supernumerary) {
    let conditionsSuper = [tooth.supernumerary.toothConditions];

    if (tooth.supernumerary.toothChangeConditions) {
      conditionsSuper = conditionsSuper.concat(tooth.supernumerary.toothChangeConditions);
    }

    conditionsSuper.forEach((condition) => {
      if (condition.revert) {
        return;
      }

      if (condition.toothStatus) {
        toothStatusCodeSuper = condition.toothStatus.code;
      }
    });
  }

  let toothClassName = "tooth";

  toothClassName += activeTooth === toothPosition ? " active" : "";
  return (
    <Tap
      className={cx(toothClassName, "d-flex flex-column")}
      short={() => {
        if (tooth.supernumerary) {
          showChooseTooltip(toothPosition);
        } else {
          showSideBar(viewTypes.series, {
            activeTooth: toothPosition,
          });
        }
      }}
      long={() => showTooltip(toothPosition, Boolean(tooth.supernumerary))}
    >
      <div className="body">
        <ChartSupernumeraryNumber
          supernumerary={tooth.supernumerary}
          statusCode={toothStatusCodeSuper}
          numericKey={numericKey}
        />
        <ChartToothImage
          tooth={tooth}
          toothColor={toothColor}
          implantType={implantType}
          imageNumber={tooth.image}
          statusCode={toothStatusCode}
        />
        <ChartSurfaceImage
          imageNumber={tooth.image}
          statusCode={toothStatusCode}
          toothColor={toothColor}
        />
        <ChartToothNumber
          normalDetails={tooth.normal && tooth.normal.details}
          missedTooth={tooth.normal && tooth.normal?.missedTooth}
          numericKey={numericKey}
        />
      </div>
      {layers}
    </Tap>
  );
}

function surfaceCodesModel(condition) {
  const model = Utils.deepCopy(condition);

  surfaceCodes.forEach((name) => {
    model[name] = {};
  });

  Utils.objectForEach(condition, (item, key) => {
    const parsedKey = key.split("_");
    const name = parsedKey[0];
    if (surfaceCodes.indexOf(name) !== -1 && parsedKey[1]) {
      model[name][parsedKey[1].toLowerCase()] = item;
    }
  });

  return model;
}

function getColor(condition) {
  let type;
  let color;

  if (condition.treatment && condition.treatment.id) {
    if (condition.conditionType && condition.conditionType.code === "TOOTHCONDITION_START") {
      type = ToothConditionTypes.ToothConditionTypeTreatmentPlanned;
    } else if (
      condition.conditionType &&
      condition.conditionType.code === "TOOTHCONDITION_RESULT"
    ) {
      type = ToothConditionTypes.ToothConditionTypeTreatmentCompleted;
    }
  } else if (condition.diagnosisCode && condition.diagnosisCode.id) {
    type = ToothConditionTypes.ToothConditionTypeDiagnosis;
  } else if (condition.existingTreatment && condition.existingTreatment.id) {
    type = ToothConditionTypes.ToothConditionTypeExistingTreatment;
  } else {
    type = ToothConditionTypes.ToothConditionTypeTreatmentCompleted;
  }

  if (type === ToothConditionTypes.ToothConditionTypeDiagnosis) {
    color = defaultColors.Yellow.color;
  } else if (type === ToothConditionTypes.ToothConditionTypeTreatmentPlanned) {
    color = defaultColors.Red.color;
  } else if (type === ToothConditionTypes.ToothConditionTypeExistingTreatment) {
    color = defaultColors.Blue.color;
  } else if (type === ToothConditionTypes.ToothConditionTypeTreatmentCompleted) {
    color = defaultColors.Green.color;
  }

  return color;
}

function sortCondition(obj1, obj2) {
  if (!obj1.createdDate) obj1.createdDate = 0;
  if (!obj2.createdDate) obj2.createdDate = 0;

  return obj1.createdDate - obj2.createdDate;
}
