import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { DatePicker } from "../ui/DatePicker";
import "moment/locale/en-gb";
import CustomSelectBox from "../widgets/CustomSelectBox";
import Utils from "../../helpers/Utils";
import Element from "../../models/Element";

import { setDate } from "../../actions/calendarActions";
import {
  changePatientFormData,
  getPatientGenderList,
  patientSelectBirthDate,
} from "../../actions/appointmentActions";
import {
  getPhoneCode,
  getPhoneNumber,
  getPhoneTown,
  phoneToObject,
} from "../../helpers/PhoneUtils";
import { Switch } from "../ui/Switch";
import { SearchPatientForm } from "./SearchPatientForm";

const enhancer = connect(
  ({ calendar: { date }, appointment: { patientGenderList, patientSelectedBirthDate } }) => {
    return { date, patientGenderList, patientSelectedBirthDate };
  },
  {
    setDate,
    getPatientGenderList,
    patientSelectBirthDate,
    changePatientFormData,
  },
);
class AddPatientFormLegacy extends React.Component {
  constructor(props) {
    super(props);
    let genderSB = new Element();
    genderSB.handler = (e) => {
      let patientForm = this.state.patientForm;

      patientForm.genderSB.selectedId = e.target.value;
      patientForm.genderSB.selected = Utils.getSelectedItem(patientForm.genderSB);
      this.setState({ patientForm });
      this.updateData();
    };

    let dateOfBirth = new Element();
    dateOfBirth.handler = (date) => {
      let patientForm = this.state.patientForm;
      patientForm.dateOfBirth = date;
      this.setState({ patientForm });
      this.updateData();
    };

    this.state = {
      patientForm: {
        firstName: null,
        lastName: null,
        email: null,
        mobilePhoneNumber: "",
        genderSB,
        dateOfBirth,
        isValid: false,
      },
      searchForm: {
        code: null,
        middle: "",
        end: "",
        chart: "",
      },
      showAddPatientForm: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getPatientGenderList().then(() => {
      this.getResponse();
    });
  }

  componentDidMount() {
    if (
      this.props.temporaryPatientData &&
      this.props.temporaryPatientData.email &&
      this.refs.email
    ) {
      this.refs.email.value = this.props.temporaryPatientData.email;
    }
  }

  getResponse = () => {
    let patientForm = this.state.patientForm;
    let genderSB = patientForm.genderSB;

    genderSB.options = this.props.patientGenderList || [];
    genderSB.options.splice(0, 0, { name: "Please Select", code: "" });

    // genderSB.selected = genderSB.options.length && genderSB.options[0];
    // genderSB.selectedId = genderSB.selected.id;
    patientForm.genderSB = genderSB;
    this.setState({ patientForm });
  };

  updateData = (searchData) => {
    let patientForm = this.state.patientForm;
    let searchForm = this.state.searchForm;

    let rightData = {};
    if (searchData) {
      Utils.objectForEach(searchData, (value, key) => {
        switch (key) {
          case "mobilePhoneNumber":
          case "patientChartNumber":
          case "firstName":
          case "lastName":
          case "email":
          case "phoneMiddle":
            patientForm[key] = value;
            if (value) {
              rightData[key] = value;
            }
            break;
          default:
            return null;
        }
      });
    }

    // patientForm.email = this.refs.email && this.refs.email.value;
    if (searchData && !searchData.target) {
      searchForm.middle = searchData && searchData.phoneMiddle;
      searchForm.end = searchData && searchData.phoneEnd;
    }
    patientForm.isValid = Boolean(patientForm.firstName && patientForm.lastName);
    rightData = { ...rightData, ...patientForm };

    this.setState({ patientForm, searchForm });
    this.props.updatePatientFormData(rightData);
  };

  showAddForm = () => {
    this.setState({ showAddPatientForm: true });
  };

  render() {
    const { choosePatient, createPhoneNumber, temporaryPatientData, fromFirstContact } = this.props;
    const { showAddPatientForm, patientForm } = this.state;

    let initialPhoneNumber = { countryCode: { value: 971 }, townCode: "", number: "" };

    if (Boolean(temporaryPatientData) && fromFirstContact) {
      const number = _.get(temporaryPatientData, "mobilePhoneNumber.phone", "");
      const townCode = _.get(temporaryPatientData, "mobilePhoneNumber.town", "");
      const countryCode = _.get(temporaryPatientData, "mobilePhoneNumber.country", "");

      initialPhoneNumber = { countryCode: { value: countryCode }, townCode, number };
    }

    if (createPhoneNumber) {
      const createPhone = phoneToObject(createPhoneNumber);

      const townCode = getPhoneTown(createPhone);
      const number = getPhoneNumber(createPhone);
      const countryCode = getPhoneCode(createPhone);

      initialPhoneNumber = { countryCode: { value: countryCode }, townCode, number };
    }

    return (
      <div className="patient-form container-fluid">
        <SearchPatientForm
          fromFirstContact={this.props.fromFirstContact}
          onShowNewScreen={this.props.onShowNewScreen}
          updatePatientFormData={this.updateData}
          choosePatient={choosePatient}
          showAddForm={this.showAddForm}
          initialValues={{
            emiratesId: "",
            chartNumber: "",
            phoneNumber: initialPhoneNumber,
            lastName: temporaryPatientData ? temporaryPatientData.lastName : "",
            firstName: temporaryPatientData ? temporaryPatientData.firstName : "",
            email: temporaryPatientData ? temporaryPatientData.email : "",
          }}
        />

        {showAddPatientForm || this.props.fromFirstContact ? (
          <div className="add-patient">
            <div className="row">
              <div className="col-sm-6">
                <label>
                  <span className="label">Gender:</span>
                  <CustomSelectBox selectBox={patientForm.genderSB} />
                </label>
              </div>
              <div className="col-sm-6">
                <label>
                  <span className="label">Birth Date:</span>
                  <DatePicker
                    size="lg"
                    selected={this.props.patientSelectedBirthDate}
                    onChange={this.props.patientSelectBirthDate}
                  />
                </label>
              </div>
            </div>
            {/*<div className="row">*/}
            {/*  <div className="col-sm-12">*/}
            {/*    <label>*/}
            {/*      <span className="label">Email:</span>*/}
            {/*      <input*/}
            {/*        type="email"*/}
            {/*        ref="email"*/}
            {/*        onChange={() => this.updateData()}*/}
            {/*        name="email"*/}
            {/*        className="form-control input-lg"*/}
            {/*      />*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row switch-item">
              <div className="col-sm-12">
                <label>
                  <span className="label">Cash Patient:</span>

                  <Switch
                    disabled={this.state.disabled}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                  />
                </label>
              </div>
            </div>
            <div className="row switch-item">
              <div className="col-sm-12">
                <label>
                  <span className="label">Use Promotional Package:</span>

                  <Switch checkedChildren="On" unCheckedChildren="Off" />
                </label>
              </div>
            </div>
            <div className="row switch-item">
              <div className="col-sm-12">
                <label>
                  <span className="label">Use Gift Card:</span>

                  <Switch checkedChildren="On" unCheckedChildren="Off" />
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhancer(AddPatientFormLegacy);
