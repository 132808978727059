import { httpClient } from "./BaseApi";

const MEDICATION_ITEM_URL = "api/patient/medication/item.json";
const MEDICATION_LIST_URL = "api/patient/medication/list.json";
const MEDICATION_ITEMS_URL = "api/patient/medication/items.json";
const MEDICATION_ITEMS_VOID_URL = "api/patient/medication/void.json";
const RESTORE_VOIDED_ITEM = "api/patient/medication/revert-void.json";

const MEDICATION_HISTORY_ITEM_URL = "api/patient/medication/history/item.json";
const MEDICATION_HISTORY_LIST_URL = "api/patient/medication/history/list.json";
const MEDICATION_HISTORY_ITEMS_URL = "api/patient/medication/history/items.json";
const MEDICATION_ERX_URL = "api/erx-request/create-by-medications.json";

export const getList = (params) => httpClient.get(MEDICATION_LIST_URL, { params });

export const sendToERX = (data) => httpClient.post(MEDICATION_ERX_URL, { data });

export const batchSave = (data) => httpClient.put(MEDICATION_ITEMS_URL, { data });

export const batchDelete = (params) => httpClient.delete(MEDICATION_ITEMS_VOID_URL, { params });

export const restoreVoidedItem = (data) => httpClient.put(RESTORE_VOIDED_ITEM, { data });

export const deleteItem = (params) => httpClient.delete(MEDICATION_ITEM_URL, { params });

export const createItem = (data) => httpClient.post(MEDICATION_ITEM_URL, { data });

export const history_getList = (params) => httpClient.get(MEDICATION_HISTORY_LIST_URL, { params });

export const history_batchSave = (data) => httpClient.put(MEDICATION_HISTORY_ITEMS_URL, { data });

export const history_deleteItem = (params) =>
  httpClient.delete(MEDICATION_HISTORY_ITEM_URL, { params });
