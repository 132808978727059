import moment from "moment";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";

import { Routes } from "../../constants/Routes";
import { useQuery } from "../../hooks/useQuery";
import { AppLayout } from "../app-layout/AppLayout";
import { YearlyTypes } from "./BlockedTimeSlotYearlyForm";
import { MonthlyTypes } from "./BlockedTimeSlotMonthlyForm";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { clinicWorkTimeSelector } from "../../reducers/calendarReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { formatBlockedTimeSlotItem } from "../../helpers/BlockedTimeSlotHelpers";
import { BlockedTimeSlotDeleteConfirmModal } from "./BlockedTimeSlotDeleteConfirmModal";
import { BlockedTimeSlotForm, RecurrenceEndType, RecurrenceTypes } from "./BlockedTimeSlotForm";
import { useBlockedTimeSlotItemQuery } from "../../queries/blocked-time-slot/useBlockedTimeSlotItemQuery";
import { useEditBlockedTimeSlotItemMutation } from "../../queries/blocked-time-slot/useEditBlockedTimeSlotItemMutation";
import { useDeleteBlockedTimeSlotItemMutation } from "../../queries/blocked-time-slot/useDeleteBlockedTimeSlotItemMutation";
import { useCreateBlockedTimeSlotItemMutation } from "../../queries/blocked-time-slot/useCreateBlockedTimeSlotItemMutation";
import {
  formatCompanyDate,
  getCompanyDate,
  getCompanyStartOfDay,
  getCompanyUTCOffset,
  stringTimeToMilliseconds,
  getTimeFields,
} from "../../helpers/DateUtils";
import { useClinicListQuery } from "../../queries/clinic/useClinicListQuery";
import { useBlockedTimeSlotTypeColor } from "../../queries/blocked-time-slot/useBlockedTimeSlotTypeColor";
import { head, toFinite } from "lodash";
import { DATE_FORMAT_LEGACY } from "../../constants/Constants";

interface Query {
  readonly id: string;
}

export function BlockedTimeSlotItem() {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery<Query>();

  // @ts-ignore
  const workTime = useShallowEqualSelector(clinicWorkTimeSelector);

  const clinicsQuery = useClinicListQuery();
  const btsTypeQuery = useBlockedTimeSlotTypeColor();

  const getItemQuery = useBlockedTimeSlotItemQuery(query);
  const editItemMutation = useEditBlockedTimeSlotItemMutation();
  const deleteItemMutation = useDeleteBlockedTimeSlotItemMutation();
  const createItemMutation = useCreateBlockedTimeSlotItemMutation();

  const [canClose, setCanClose] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const fullMembers: any = useSelector((store) => store);

  const isEditMode = toFinite(query.id) > 0;

  const blockedItem: any = getItemQuery.data;

  // @ts-ignore
  const panelTitle = blockedItem ? blockedItem.name : "New Item";

  const initialValues = useMemo(() => {
    const clinicList = clinicsQuery.data || [];
    const btsTypeColorList = btsTypeQuery.data || [];

    let values: any = {
      name: "",
      points: [],
      reason: "",
      members: [],
      weekDays: [],
      reserved: false,
      excludedDates: [],
      yearlyType: YearlyTypes.Every,
      monthlyType: MonthlyTypes.Day,
      clinic: { value: head(clinicList)?.id },
      recurrenceEndType: RecurrenceEndType.EndDate,
      recurrenceType: { value: RecurrenceTypes.Daily },
      // @ts-ignore
      endTime: getCompanyDate(getCompanyStartOfDay() + stringTimeToMilliseconds(workTime.endTime)),
      startTime: getCompanyDate(
        // @ts-ignore
        getCompanyStartOfDay() + stringTimeToMilliseconds(workTime.startTime),
      ),
      timeSlotType: { value: head(btsTypeColorList)?.id },
    };

    if (isEditMode && blockedItem) {
      const { members = [], points = [], weekDays = [] } = blockedItem;

      values = {
        ...values,
        ...blockedItem,
        recurrenceEndType: blockedItem.recurrenceEndType.code,
        points: points.map((x) => ({ value: x.id, label: x.name })),
        members: members.map((x) => ({ value: x.id, label: x.name })),
        weekDays: weekDays.map((x) => ({ value: x.code, label: x.name })),
      };

      if (blockedItem.recurrenceType?.code) {
        values = {
          ...values,
          recurrenceType: { value: blockedItem.recurrenceType.code },
        };
      }

      if (blockedItem.clinicId) {
        values = { ...values, clinic: { value: blockedItem.clinicId } };
      }

      if (blockedItem.endTime) {
        const hours = moment().utcOffset(0).set({ hour: 0, minute: 0 });
        const milliseconds = blockedItem.endTime;
        const hour = Math.floor(milliseconds / 3600000);
        const minute = (milliseconds - hour * 60 * 60 * 1000) / 1000 / 60;

        values = {
          ...values,
          endTime: hours.set({
            hour: hour,
            minute: minute,
          }),
        };
      } else {
        values = { ...values, endTime: undefined };
      }

      if (blockedItem.startTime) {
        const hours = moment().utcOffset(0).set({ hour: 0, minute: 0 });
        const milliseconds = blockedItem.startTime;
        const hour = Math.floor(milliseconds / 3600000);
        const minute = (milliseconds - hour * 60 * 60 * 1000) / 1000 / 60;
        values = {
          ...values,
          startTime: hours.set({
            hour: hour,
            minute: minute,
          }),
        };
      } else {
        values = { ...values, startTime: undefined };
      }

      if (blockedItem.startDate) {
        const companyDate = formatCompanyDate(
          new Date(blockedItem.startDate - getCompanyUTCOffset()),
          DATE_FORMAT_LEGACY,
        );
        values = {
          ...values,
          startDate: moment(moment(companyDate, DATE_FORMAT_LEGACY).valueOf()),
        };
        // values = { ...values, startDate: moment(blockedItem.startDate) };
      }

      if (blockedItem.endDate) {
        const companyDate = formatCompanyDate(
          new Date(blockedItem.endDate - getCompanyUTCOffset()),
          DATE_FORMAT_LEGACY,
        );
        values = {
          ...values,
          endDate: moment(moment(companyDate, DATE_FORMAT_LEGACY).valueOf()),
        };
        // values = { ...values, endDate: moment(blockedItem.endDate) };
      }

      if (blockedItem.untilDate) {
        values = { ...values, untilDate: moment(blockedItem.untilDate) };
      }

      if (blockedItem.recurrenceType === RecurrenceTypes.Monthly && blockedItem.customPattern) {
        values = { ...values, monthlyType: MonthlyTypes.The };
      }

      if (blockedItem.recurrenceType === RecurrenceTypes.Yearly && blockedItem.customPattern) {
        values = { ...values, yearlyType: MonthlyTypes.The };
      }
      if (blockedItem.type?.id) {
        values = {
          ...values,
          timeSlotType: { value: blockedItem.type.id },
        };
      }
    }

    return values;
  }, [
    blockedItem,
    clinicsQuery.data,
    btsTypeQuery.data,
    isEditMode,
    workTime.endTime,
    workTime.startTime,
  ]);

  return (
    <AppLayout disableBackButton={true} withLogo={true}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={isEditMode}
        onSubmit={async (values) => {
          const formattedServerData = formatBlockedTimeSlotItem(values, fullMembers?.auth.doctors);
          values.startTime = getTimeFields(formattedServerData.startTime);
          values.endTime = getTimeFields(formattedServerData.endTime);

          dispatch(startLoader());

          const action = isEditMode ? editItemMutation : createItemMutation;

          try {
            await action.mutateAsync(formattedServerData);
            dispatch(stopLoader());
            if (canClose) {
              history.replace(Routes.DashboardCalendar);
            } else {
              history.goBack();
            }
          } catch (e) {
            return dispatch(stopLoader(e));
          }
        }}
      >
        {({ handleSubmit }) => (
          <div className="blocked-time-slot flex-grow-1 flex-shrink-1">
            <div className="blocked-time-slot-body">
              <div
                className="blocked-time-slot-container"
                style={{ overflow: "auto", height: "100%" }}
              >
                <div className="blocked-time-slot-panel blocked-time-slot-add-edit-panel">
                  <div
                    className="blocked-time-slot-panel-header b-b"
                    style={{ border: "1px solid #dee5e7" }}
                  >
                    {panelTitle}
                  </div>
                  <BlockedTimeSlotForm
                    onCloseClick={history.goBack}
                    onDeleteClick={() => setShowConfirm(true)}
                    onSubmitClick={(x) => {
                      setCanClose(Boolean(x));

                      handleSubmit();
                    }}
                  />
                  <BlockedTimeSlotDeleteConfirmModal
                    show={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    onDeleteClick={() => {
                      setShowConfirm(false);

                      dispatch(startLoader());

                      deleteItemMutation
                        .mutateAsync({ id: query.id })
                        .then(() => {
                          dispatch(stopLoader());

                          history.replace(Routes.DashboardCalendar);
                        })
                        .catch((e) => dispatch(stopLoader(e)));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </AppLayout>
  );
}
