import { httpClient } from "./BaseApi";

const RESTORE_VOIDED_TREATMENTS_URL = "api/dental-transaction/revert-void.json";
const GET_VOIDED_TREATMENTS_LIST_URL = "api/dental-transaction/list-for-dentist.json";

const GOOGLE_SYNC_URL = "api/google/calendar/sync.json";

const CHANGE_STATUS_URL = "api/clinic/appointment/status.json";
const APPOINTMENT_ITEM_URL = "api/clinic/appointment/item.json";
const APPOINTMENT_LINKED_ITEMS_URL = "api/clinic/appointment/linked-items.json";
const APPOINTMENT_TREATMENTS_PLAN_URL = "api/clinic/appointment/appointment-plan/batch-items.json";
const APPOINTMENT_NOT_INTERESTED_URL = "api/clinic/appointment/item/not-interested.json";
// const UPDATE_APPOINTMENT_TYPE_URL = "api/clinic/appointment/item/not-interested.json";
const APPOINTMENT_LIST_URL = "api/clinic/appointment/list.json";
const GET_HISTORY_URL = "api/clinic/appointment/item/history.json";
const GET_STATUS_URL = "api/clinic/appointment/status/choose.json";
const GET_FREE_POINTS_URL = "api/clinic/appointment/freePoint.json";
const GET_MULTIPLE_DOCTOR_FREE_POINTS_URL = "api/clinic/appointment/freePoints.json";
const CASH_PRICE_URL = "api/clinic/appointment/item/cash-price.json";
const GET_LOCATION_URL = "api/clinic/appointment/location/choose.json";
const GET_OTHER_STATUS_URL = "api/clinic/appointment/status-other/choose.json";
const GET_APPROVAL_STATUS_URL = "api/clinic/appointment/approval-status/choose.json";
const GET_LAB_STATUS_URL = "api/clinic/appointment/lab-status/choose.json";
const GET_CATEGORY_URL = "api/clinic/appointment/category/choose.json";
const GET_APPOINTMENT_COLORS_URL = "api/clinic/appointment/colors.json";
const GET_CALL_LIST_URL = "api/clinic/appointment/special-list/call.json";
const GET_TEMP_PATIENT_URL = "api/clinic/appointment/patient/choose.json";
const GET_APPOINTMENTS_URL = "api/clinic/appointment/calendar-search.json";
const GET_CALENDAR_URL = "api/clinic/appointment/special-list/calendar.json";
const GET_MASTER_TYPE_URL = "api/clinic/appointment/master-type/choose.json";
const GET_COUNT_BY_STATUS_URL = "api/clinic/appointment/count-by-status.json";
const GET_DENTAL_POINT_URL = "api/clinic/appointment/dental-point/choose.json";
const GET_EARLIER_APPOINTMENTS_URL = "api/clinic/appointment/special-list/earlier.json";
const SAVE_REPEAT_APPOINTMENT_URL = "api/clinic/appointment/dentist-sequence/item.json";
const GET_CALENDAR_PRINT_DETAILS_URL = "api/clinic/appointment/calendar-pdf/create.json";
const SEND_DETAILS_TO_EMAIL_URL = "api/clinic/appointment/calendar-pdf/send-to-email.json";
const GET_CANCELLED_MISSED_COUNT_URL = "api/clinic/appointment/cancelled-missed-count.json";
const GET_DENTIST_CALENDAR_URL = "api/clinic/appointment/special-list/dentist/calendar.json";
const REPEAT_APPOINTMENT_LIST_URL = "api/clinic/appointment/dentist-sequence/rule/list.json";
const REPEAT_APPOINTMENT_ITEM_URL = "api/clinic/appointment/dentist-sequence/rule/item.json";

const SAVE_REASON_URL = "api/patient/first-contact/item.json";
const SEARCH_FIRST_CONTACT_URL = "api/patient/first-contact/list.json";
const GET_REASON_LIST_URL = "api/patient/first-contact/create-reason/choose.json";
const CONSULTATION_NOTES_LIST_URL = "api/patient/profile/consultation-note/list.json";
const CONSULTATION_NOTES_ITEM_URL = "api/patient/profile/consultation-note/item.json";

const GET_DOCTORS_URL = "api/member/doctor/choose.json";
const GET_CATEGORIES_URL = "api/treatment/group/choose.json";
const GET_MACHINES_URL = "api/clinic/appointment/machine/choose.json";

const STOP_DEALS_URL = "api/dental-transaction/stop-package-deals.json";
const STOP_DEAL_LIST_URL = "api/dental-transaction/list-for-package-deals.json";

const REQUEST_LIST_URL = "api/dental-transaction/request/list.json";
const GET_REQUEST_ITEM_URL = "api/dental-transaction/request/item.json";
const CREATE_REQUEST_ITEM_URL = "api/dental-transaction/request/items.json";
const REQUEST_CONVERT_TO_PLANNED_URL = "api/dental-transaction/request/convert.json";

const ADVANCED_PAYMENTS_URL = "api/patient/advanced-payments.json";

const DIAGNOSIS_CODE_LIST_URL = "api/diagnosis/code/appointment/list.json";
const TREATMENT_CODE_LIST_URL = "api/treatment/code/appointment/list.json";

const REFERRAL_NEXT_VISIT_LIST_URL = "api/clinic/appointment/referralType-list.json";
const ELIGIBILITY_DETAILS_ITEM_URL = "api/eligibility/item.json";
const ELIGIBILITY_ID_PAYER_URL = "api/clinic/appointment/eligibilityIdPayer/item.json";
const ELIGIBILITY_UPDATE_STATUS_URL = "api/clinic/appointment/update-eligible.json";
const GUARDIAN_INFORMED_URL = "api/clinic/appointment/item/guardian-informed.json";
const APPOINTMENT_RESCHEDULE_URL = "api/clinic/appointment/reschedule.json";
const REFERRAL_NEXT_VISIT_RESCHEDULE_URL = "api/clinic/appointment/referralType/reschedule.json";
const APPOINTMENT_MEETING_TYPE_URL = "api/clinic/appointment/meeting-type/choose.json";
const APPOINTMENT_PAYMENT_TYPE_URL = "api/clinic/appointment/payment-status/choose.json";
const APPOINTMENT_REMINDERS_LOG_URL = "api/clinic/appointment/reminder/history.json";
const UPDATE_APPOINTMENT_PAYMENT_TYPE_URL = "api/clinic/appointment/item/payment-status.json";
const UPDATE_APPOINTMENT_MEETING_TYPE_URL = "api/clinic/appointment/item/meeting-type.json";
const UPDATE_LOCATION_URL = "api/clinic/appointment/item/location.json";
const SET_NO_ANSWER_URL = "/api/clinic/appointment/item/no-answer.json";
const SET_WILL_CALL_URL = "/api/clinic/appointment/item/will-call.json";
const SET_REQUESTED_RESCHEDULE_URL = "/api/clinic/appointment/item/requested-reschedule.json";
const APPOINTMENT_ADDITIONAL_PRICE_URL = "/api/clinic/appointment/item/additional-charges.json";
const SKIP_NEXT_VISIT_URL = "api/clinic/appointment/skip.json";
const SAVE_DRAG_APPOINTMENT_URL = "api/clinic/appointment/drag-and-drop.json";
const APPOINTMENT_WHEN_VALUES_URL = "api/clinic/appointment/referralType/when-values.json";

const AppointmentApi = {
  saveOnDragAppointment: (data) => httpClient.put(SAVE_DRAG_APPOINTMENT_URL, { data }),
  setAppointmentAdditionalPrice: (data) =>
    httpClient.put(APPOINTMENT_ADDITIONAL_PRICE_URL, { data }),
  updatePaymentType: (data) => httpClient.put(UPDATE_APPOINTMENT_PAYMENT_TYPE_URL, { data }),
  updateMeetingType: (data) => httpClient.put(UPDATE_APPOINTMENT_MEETING_TYPE_URL, { data }),
  updateLocation: (data) => httpClient.put(UPDATE_LOCATION_URL, { data }),
  updateGuardianInformed: (data) => httpClient.put(GUARDIAN_INFORMED_URL, { data }),
  getAppointmentMeetingType: () => httpClient.get(APPOINTMENT_MEETING_TYPE_URL),
  getAppointmentPaymentType: () => httpClient.get(APPOINTMENT_PAYMENT_TYPE_URL),
  getAppointmentRemindersLog: (params) => httpClient.get(APPOINTMENT_REMINDERS_LOG_URL, { params }),
  cleanUpMissedCancelledList: (params) => httpClient.get(APPOINTMENT_RESCHEDULE_URL, { params }),
  cleanUpReferralNextVisitList: (params) =>
    httpClient.get(REFERRAL_NEXT_VISIT_RESCHEDULE_URL, { params }),
  cashPrice: (data) => httpClient.put(CASH_PRICE_URL, { data }),
  stopDeals: (data) => httpClient.post(STOP_DEALS_URL, { data }),
  saveReason: (data) => httpClient.post(SAVE_REASON_URL, { data }),
  getHistory: (params) => httpClient.get(GET_HISTORY_URL, { params }),
  googleSync: (params) => httpClient.get(GOOGLE_SYNC_URL, { params }),
  changeStatus: (data) => httpClient.put(CHANGE_STATUS_URL, { data }),
  getDoctors: (params) => httpClient.get(GET_DOCTORS_URL, { params }),
  getCalendar: (params) => httpClient.get(GET_CALENDAR_URL, { params }),
  getOnDragAppointmentsList: (params) => httpClient.get(GET_CALENDAR_URL, { params }),
  getItem: (params) => httpClient.get(APPOINTMENT_ITEM_URL, { params }),
  setNotInterestedAppointment: (data) => httpClient.put(APPOINTMENT_NOT_INTERESTED_URL, { data }),
  setNoAnswer: (data) => httpClient.put(SET_NO_ANSWER_URL, { data }),
  setWillCall: (data) => httpClient.put(SET_WILL_CALL_URL, { data }),
  setRequestedReschedule: (data) => httpClient.put(SET_REQUESTED_RESCHEDULE_URL, { data }),
  getList: (params) => httpClient.get(APPOINTMENT_LIST_URL, { params }),
  getMachines: (params) => httpClient.get(GET_MACHINES_URL, { params }),
  getCallList: (params) => httpClient.get(GET_CALL_LIST_URL, { params }),
  getLocations: (params) => httpClient.get(GET_LOCATION_URL, { params }),
  getOtherStatus: (params) => httpClient.get(GET_OTHER_STATUS_URL, { params }),
  getApprovalStatus: (params) => httpClient.get(GET_APPROVAL_STATUS_URL, { params }),
  getLabStatus: (params) => httpClient.get(GET_LAB_STATUS_URL, { params }),
  getRequestList: (params) => httpClient.get(REQUEST_LIST_URL, { params }),
  stopDealList: (params) => httpClient.get(STOP_DEAL_LIST_URL, { params }),
  getColors: (data) => httpClient.get(GET_APPOINTMENT_COLORS_URL, { data }),
  getCategories: (params) => httpClient.get(GET_CATEGORIES_URL, { params }),
  getReasonList: (params) => httpClient.get(GET_REASON_LIST_URL, { params }),
  getMasterType: (params) => httpClient.get(GET_MASTER_TYPE_URL, { params }),
  getFreePoints: (params) => httpClient.get(GET_FREE_POINTS_URL, { params }),
  getMultipleDoctorFreePoints: (data) =>
    httpClient.post(GET_MULTIPLE_DOCTOR_FREE_POINTS_URL, { data }),
  getTempPatient: (params) => httpClient.get(GET_TEMP_PATIENT_URL, { params }),
  getRequestItem: (params) => httpClient.get(GET_REQUEST_ITEM_URL, { params }),
  getAppointments: (params) => httpClient.get(GET_APPOINTMENTS_URL, { params }),
  updateRequestItem: (data) => httpClient.put(CREATE_REQUEST_ITEM_URL, { data }),
  advancedPayments: (params) => httpClient.get(ADVANCED_PAYMENTS_URL, { params }),
  createRequestItem: (data) => httpClient.post(CREATE_REQUEST_ITEM_URL, { data }),
  getDiagnosisCodeList: (params) => httpClient.get(DIAGNOSIS_CODE_LIST_URL, { params }),
  getTreatmentCodeList: (params) => httpClient.get(TREATMENT_CODE_LIST_URL, { params }),
  getCountByStatus: (params) => httpClient.get(GET_COUNT_BY_STATUS_URL, { params }),
  deleteRequestItem: (params) => httpClient.delete(GET_REQUEST_ITEM_URL, { params }),
  getEarlierAppointments: (params) => httpClient.get(GET_EARLIER_APPOINTMENTS_URL, { params }),
  getDentistCalendar: (params) => httpClient.get(GET_DENTIST_CALENDAR_URL, { params }),
  searchFirstContact: (params) => httpClient.get(SEARCH_FIRST_CONTACT_URL, { params }),
  sendDetailsToEmail: (params) => httpClient.get(SEND_DETAILS_TO_EMAIL_URL, { params }),
  restoreVoidedTreatments: (data) => httpClient.put(RESTORE_VOIDED_TREATMENTS_URL, { data }),
  repeatAppointmentSaveItem: (data) => httpClient.post(SAVE_REPEAT_APPOINTMENT_URL, { data }),
  convertRequestToPlanned: (data) => httpClient.put(REQUEST_CONVERT_TO_PLANNED_URL, { data }),
  repeatAppointmentGetList: (params) => httpClient.get(REPEAT_APPOINTMENT_LIST_URL, { params }),
  getVoidedTreatmentsList: (params) => httpClient.get(GET_VOIDED_TREATMENTS_LIST_URL, { params }),
  getCalendarPrintDetails: (params) => httpClient.get(GET_CALENDAR_PRINT_DETAILS_URL, { params }),
  repeatAppointmentSaveItemAfter: (data) => httpClient.post(REPEAT_APPOINTMENT_ITEM_URL, { data }),
  getSkipNextVisitResult: (params) => httpClient.get(SKIP_NEXT_VISIT_URL, { params }),
  getAppointmentEligibilityDetails: (params) =>
    httpClient.get(ELIGIBILITY_DETAILS_ITEM_URL, { params }),
  updateAppointmentEligibilityIdPayer: (data) => httpClient.put(ELIGIBILITY_ID_PAYER_URL, { data }),
  updateAppointmentEligibilityStatus: (data) =>
    httpClient.put(ELIGIBILITY_UPDATE_STATUS_URL, { data }),
  repeatAppointmentDeleteItem: (params) =>
    httpClient.delete(REPEAT_APPOINTMENT_ITEM_URL, { params }),
  getDentalPoint: (params) =>
    httpClient.get(GET_DENTAL_POINT_URL, {
      params,
    }),
  saveAppointment: (data) =>
    data && data.id
      ? httpClient.put(APPOINTMENT_ITEM_URL, { data })
      : httpClient.post(APPOINTMENT_ITEM_URL, { data }),
  saveAppointmentBatchItems: (data) =>
    data && data.moved
      ? httpClient.put(APPOINTMENT_LINKED_ITEMS_URL, { data })
      : httpClient.post(APPOINTMENT_LINKED_ITEMS_URL, { data }),
  saveAppointmentsPlan: (data) => httpClient.post(APPOINTMENT_TREATMENTS_PLAN_URL, { data }),
  getCanceledMissedCount: (data) => {
    const endTime = Date.now() + 5 * 60 * 60 * 1000;
    const startTime = endTime - 365 * 24 * 60 * 60 * 1000;

    const params = {
      ...data,
      endTime,
      startTime,
    };

    return httpClient.get(GET_CANCELLED_MISSED_COUNT_URL, { params });
  },
  getReferralNextVisitList: (params) => httpClient.get(REFERRAL_NEXT_VISIT_LIST_URL, { params }),
  getAppointmentWhenValuesList: () => httpClient.get(APPOINTMENT_WHEN_VALUES_URL),
  status: {
    getChoose: (params) => httpClient.get(GET_STATUS_URL, { params }),
    changeStatus: (data) => httpClient.put(CHANGE_STATUS_URL, { data }),
  },
  category: {
    getChoose: (params) => httpClient.get(GET_CATEGORY_URL, { params }),
  },
  consultationNotes: {
    editItem: (data) => httpClient.put(CONSULTATION_NOTES_ITEM_URL, { data }),
    saveItem: (data) => httpClient.post(CONSULTATION_NOTES_ITEM_URL, { data }),
    getList: (params) => httpClient.get(CONSULTATION_NOTES_LIST_URL, { params }),
    getItem: (params) => httpClient.get(CONSULTATION_NOTES_ITEM_URL, { params }),
    deleteItem: (params) => httpClient.delete(CONSULTATION_NOTES_ITEM_URL, { params }),
  },
};

export default AppointmentApi;
