import React from "react";
import { isEmpty } from "lodash";
import Show from "../../widgets/Show";
// import DNDCalendar from "./DNDCalendar";
import { useSelector } from "react-redux";
import { Sidebar } from "../../sidebar/Sidebar";
import { SidebarHeader } from "../../sidebar/SidebarHeader";
import AppointmentItemDetails from "./AppointmentItemDetails";

const AppointmentPanelSidebar = ({ tooltipVisible, onClose }) => {
  // const [selectedDate, setSelectedDay] = useState(new Date());
  const { onDragAppointmentsList } = useSelector((store) => store.calendar);
  return (
    <Sidebar
      show={tooltipVisible.visible}
      onHide={onClose}
      dialogClassName="appointment-dnd-sidebar mt-0"
      bodyProps={{ className: "appointment-dnd-modal-body" }}
    >
      <div
        className="calendar__container"
        onDragLeave={(event) => {
          event.preventDefault();
          onClose();
        }}
      >
        <SidebarHeader onBackClick={onClose} />

        {/*<DNDCalendar selectedDate={selectedDate} selectDate={(date) => setSelectedDay(date)} />*/}

        <Show if={!isEmpty(onDragAppointmentsList)}>
          <AppointmentItemDetails appointments={onDragAppointmentsList} />
        </Show>
      </div>
    </Sidebar>
  );
};

export default AppointmentPanelSidebar;
