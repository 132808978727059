import React, { useState } from "react";
import { capitalize, isEqual } from "lodash";
import { Form, useFormikContext } from "formik";

import { DateField } from "../form/DateField";
import { TextField } from "../form/TextField";
import { SelectField } from "../form/SelectField";
import { CheckboxField } from "../form/CheckboxField";
import { DoctorSelectField } from "../form/DoctorSelectField";
import { ClinicSelectField } from "../form/ClinicSelectField";
import { BlockedTimeSlotDailyForm } from "./BlockedTimeSlotDailyForm";
import { BlockedTimeSlotWeeklyForm } from "./BlockedTimeSlotWeeklyForm";
import { BlockedTimeSlotMonthlyForm } from "./BlockedTimeSlotMonthlyForm";
import { BlockedTimeSlotYearlyForm } from "./BlockedTimeSlotYearlyForm";
import { DatePicker } from "../ui/DatePicker";
import { Button, ButtonType } from "../ui/Button";
import { BlockedTimeSlotChip } from "./BlockedTimeSlotChip";
import { RadioField } from "../form/RadioField";
import { TimeField } from "../form/TimeField";
import cx from "classnames";
import { DentalPointsSelectField } from "../form/DentalPointsSelectField";
import { BtsTypeColorSelectField } from "../form/BtsTypeColorSelectField";
export enum RecurrenceTypes {
  Daily = "DAILY",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
  Monthly = "MONTHLY",
}

export enum RecurrenceEndType {
  EndDate = "RecurrenceEnd_By_EndDate",
  CountLimit = "RecurrenceEnd_By_CountLimit",
}

export const RecurrenceTypeOptions = [
  {
    value: RecurrenceTypes.Daily,
    label: capitalize(RecurrenceTypes.Daily),
  },
  {
    value: RecurrenceTypes.Weekly,
    label: capitalize(RecurrenceTypes.Weekly),
  },
  {
    value: RecurrenceTypes.Monthly,
    label: capitalize(RecurrenceTypes.Monthly),
  },
  {
    value: RecurrenceTypes.Yearly,
    label: capitalize(RecurrenceTypes.Yearly),
  },
];

interface Props {
  readonly onCloseClick: () => void;
  readonly onDeleteClick: () => void;
  readonly onSubmitClick: (canClose?: boolean) => void;
}

export function BlockedTimeSlotForm({ onCloseClick, onSubmitClick, onDeleteClick }: Props) {
  const { values, setFieldValue } = useFormikContext<any>();

  const [excludedDate, setExcludedDate] = useState<Date | null>(null);

  const isDailyRecurrenceType = values.recurrenceType.value === RecurrenceTypes.Daily;
  const isWeeklyRecurrenceType = values.recurrenceType.value === RecurrenceTypes.Weekly;
  const isYearlyRecurrenceType = values.recurrenceType.value === RecurrenceTypes.Yearly;
  const isMonthlyRecurrenceType = values.recurrenceType.value === RecurrenceTypes.Monthly;

  const isRecurrenceEndDateType = values.recurrenceEndType === RecurrenceEndType.EndDate;

  return (
    <Form className="d-flex flex-column bg-white p-2">
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">Clinic:</div>
        <div className="col-sm-4">
          <ClinicSelectField name="clinic" />
        </div>
        <div className="col-sm-2" />
        <div className="col-sm-1 d-flex p-0 justify-content-end">Reserved:</div>
        <div className="col-sm-4">
          <CheckboxField name="reserved" />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">Name:</div>
        <div className="col-sm-4">
          <TextField name="name" placeholder="Example name" />
        </div>
        <div className="col-sm-2" />
        <div className="col-sm-1 d-flex p-0 justify-content-end">Reason:</div>
        <div className="col-sm-4">
          <TextField name="reason" placeholder="Example reason" />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-1 d-flex p-0 justify-content-end">Doctors:</div>
        <div className="col-sm-4">
          <DoctorSelectField
            isSearchable={true}
            name="members"
            isMulti={true}
            onlyDoctors={true}
            allLabel={"All doctors"}
          />
        </div>
        <div className="col-sm-2 text-center">Or</div>
        <div className="col-sm-1 d-flex p-0 justify-content-end">Room:</div>
        <div className="col-sm-4">
          <DentalPointsSelectField
            isSearchable={true}
            name="points"
            isMulti={true}
            onChange={() => setFieldValue("members", [])}
          />
        </div>
      </div>

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end mt-1 pt-1">Time:</div>
        <div className="col-sm-8 d-flex">
          <TimeField
            label="From"
            name="startTime"
            fieldProps={{ className: "flex-grow-1 flex-shrink-1 mr-1" }}
          />
          <TimeField
            label="To"
            name="endTime"
            fieldProps={{ className: "flex-grow-1 flex-shrink-1 ml-1" }}
          />
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end">Type:</div>
        <div className="col-sm-8">
          <BtsTypeColorSelectField name="timeSlotType" />
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end">Recurrence Type:</div>
        <div className="col-sm-8">
          <SelectField name="recurrenceType" options={RecurrenceTypeOptions} />
        </div>
        <div className="col-sm-2" />
      </div>

      {isDailyRecurrenceType && <BlockedTimeSlotDailyForm />}

      {isWeeklyRecurrenceType && <BlockedTimeSlotWeeklyForm />}

      {isMonthlyRecurrenceType && <BlockedTimeSlotMonthlyForm />}

      {isYearlyRecurrenceType && <BlockedTimeSlotYearlyForm />}

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end">Start date:</div>
        <div className="col-sm-8 d-flex">
          <DateField name="startDate" fieldProps={{ className: "flex-grow-1 flex-shrink-1" }} />
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="row d-flex mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end mt-1">End date:</div>
        <div className="col-sm-8">
          <div className="row d-flex align-items-center mb-2">
            <div className="col-sm-2">
              <RadioField
                label="Until:"
                name="recurrenceEndType"
                radioValue={RecurrenceEndType.EndDate}
              />
            </div>
            <div className="col-sm-10 d-flex">
              <DateField
                disabled={!isRecurrenceEndDateType}
                name="endDate"
                fieldProps={{ className: "flex-grow-1 flex-shrink-1" }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-sm-2">
              <RadioField
                label="After:"
                name="recurrenceEndType"
                radioValue={RecurrenceEndType.CountLimit}
              />
            </div>
            <div className="col-sm-4 d-flex">
              <TextField disabled={isRecurrenceEndDateType} type="number" name="occurrences" />
            </div>
            <div className="col-sm-6">Occurrences</div>
          </div>
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="row mb-2">
        <div className="col-sm-12">
          <div
            style={{
              padding: "10px 15px",
              backgroundColor: "#edf1f2",
              border: "1px solid #dee5e7",
              fontWeight: 700,
              color: "#333",
              margin: "0 -12px",
            }}
          >
            <b>Exclude Dates Details</b>
          </div>
        </div>
      </div>

      <div className="row d-flex align-items-center mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end">Until date:</div>
        <div className="col-sm-8 d-flex">
          <DateField name="untilDate" fieldProps={{ className: "flex-grow-1 flex-shrink-1" }} />
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="row d-flex mb-2">
        <div className="col-sm-2 d-flex p-0 justify-content-end mt-1">Excluded dates:</div>
        <div className="col-sm-8 d-flex flex-column">
          <div className="d-flex">
            <DatePicker
              wrapperClassName="flex-shrink-1 flex-grow-1 mr-1"
              selected={excludedDate}
              onChange={(date) => setExcludedDate(date as Date)}
            />
            <Button
              disabled={!excludedDate}
              onClick={() => {
                const list = values.excludedDates || [];

                list.push(excludedDate);

                setExcludedDate(null);
              }}
            >
              Add
            </Button>
          </div>
          <div className="d-flex pt-2 mb-2" style={{ flexWrap: "wrap" }}>
            {values.excludedDates.map((x, idx) => (
              <BlockedTimeSlotChip
                key={idx}
                date={x}
                className="mr-1 mb-1"
                onDelete={() => {
                  const list = values.excludedDates;

                  const index = list.find((date) => isEqual(x, date));

                  if (index >= 0) {
                    list.splice(index, 1);
                  }

                  setFieldValue("excludedDates", list);
                }}
              />
            ))}
          </div>
        </div>
        <div className="col-sm-2" />
      </div>

      <div className="d-flex justify-content-end">
        <Button
          className="mr-1"
          type="button"
          buttonType={ButtonType.Secondary}
          onClick={onCloseClick}
        >
          Close
        </Button>
        <Button className="mr-1" type="button" onClick={() => onSubmitClick()}>
          Save
        </Button>
        <Button
          className={cx({ "mr-1": !!values.id })}
          type="button"
          onClick={() => onSubmitClick(true)}
        >
          Save & close
        </Button>
        {!!values.id && (
          <Button type="button" buttonType={ButtonType.Danger} onClick={onDeleteClick}>
            Delete
          </Button>
        )}
      </div>
    </Form>
  );
}
