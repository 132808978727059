import React from "react";
import TopCalculationBoxPriceSelect from "./TopCalculationBoxPriceSelect";

import Utils from "../../../helpers/Utils";
import { SidebarTypes } from "../../chart-treatments-sidebar/TypeButtonGroup";
import { connect } from "react-redux";
import _ from "lodash";
import ArrowUpImage from "../../../assets/images/arrows/up_arrow.png";
import ArrowDownImage from "../../../assets/images/arrows/down_arrow.png";

const TitleAndDescription = (props) => {
  const { title, description, isFullDesc, toggle } = props;

  return (
    <div className="item-info-box">
      <h4 className="title">{title}</h4>
      <p className={`description ${isFullDesc ? "" : "ellipsis-mode"}`}>{description} </p>
      <span className="full-btn" onClick={toggle}>
        {isFullDesc ? "Less" : "Full"} description
      </span>
    </div>
  );
};

const enhancer = connect(({ session: { treatmentDescriptionType } }) => ({
  treatmentDescriptionType,
}));

export default enhancer(
  class TopCalculationBox extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isFullDesc: false,
      };
    }

    onQuantityChange = (e, isForUnit = false) => {
      if (isForUnit) {
        this.props.seriesActions.changeTransactionFormByKey(e.target.value, "unit");
      } else {
        this.props.seriesActions.changeTransactionFormByKey(e.target.value, "quantity");
      }
    };

    chooseItemPrice = (id) => {
      this.props.seriesActions.changeTransactionByKey(id, "priceById", null, this.props.typeTab);
    };

    handleQuantity = (type, isForUnit = false) => {
      if (isForUnit) {
        let count = +this.refs.unit.value;
        const oneUnit = 0.25;
        if (type === "down" && count <= oneUnit) return;
        this.refs.unit.value = type === "up" ? count + oneUnit : count - oneUnit;

        this.props.seriesActions.changeTransactionFormByKey(this.refs.unit.value, "unit");
      } else {
        let count = this.refs.qauntity.value;
        if (type === "down" && count <= 1) return;
        this.refs.qauntity.value = type === "up" ? ++count : --count;

        this.props.seriesActions.changeTransactionFormByKey(count, "quantity");
      }
    };

    render() {
      const {
        item,
        isMixed,
        typeTab,
        seriesType,
        currentMember,
        seriesActions,
        addTransaction,
        paymentOptions,
        transactionForm,
        treatmentDescriptionType,
      } = this.props;
      const itemIn = item.item;
      const hasPatientInsurance = !!paymentOptions;
      if (!transactionForm) return null;
      const isForUnit =
        _.get(currentMember, "chartType.code") === "DERMATOLOGY_CHART" ||
        _.get(currentMember, "chartType.code") === "DENTAL_CHART" ||
        _.get(currentMember, "chartType.code") === "GENERAL_MEDICINE_CHART";
      return (
        itemIn && (
          <div className="calculation-box flex-shrink-0">
            {addTransaction && addTransaction.treatmentCode && (
              <TitleAndDescription
                isFullDesc={this.state.isFullDesc}
                title={addTransaction.treatmentCode.code}
                description={
                  _.get(treatmentDescriptionType, "code") === "DESCRIPTION"
                    ? _.get(item, "item.description")
                    : _.get(item, "item.description2")
                }
                toggle={() => this.setState({ isFullDesc: !this.state.isFullDesc })}
              />
            )}

            <div className="quantity-wrap">
              <span className="name">Quantity</span>
              <div className="input-box">
                <button
                  className="btn btn-direction down"
                  onClick={() => this.handleQuantity("down")}
                >
                  <img src={ArrowDownImage} alt="" width={15} />
                </button>
                <input
                  type="number"
                  ref="qauntity"
                  value={transactionForm.quantity}
                  onChange={(e) => this.onQuantityChange(e)}
                  className="number-field form-control"
                />
                <button className="btn btn-direction up" onClick={() => this.handleQuantity("up")}>
                  <img src={ArrowUpImage} alt="" width={15} />
                </button>
              </div>
            </div>
            {isForUnit && (
              <div className="unit-wrap">
                <span className="name">Unit</span>
                <div className="input-box">
                  <button
                    className="btn btn-direction down"
                    onClick={() => this.handleQuantity("down", true)}
                  >
                    <img src={ArrowDownImage} alt="" width={15} />
                  </button>
                  <input
                    type="number"
                    ref="unit"
                    value={transactionForm.unit}
                    onChange={(e) => this.onQuantityChange(e, true)}
                    className="number-field form-control"
                    style={{ width: "65px" }}
                  />
                  <button
                    className="btn btn-direction up"
                    onClick={() => this.handleQuantity("up", true)}
                  >
                    <img src={ArrowUpImage} alt="" width={15} />
                  </button>
                </div>
              </div>
            )}
            <div className="area-wrap">
              <span className="name">{itemIn && itemIn.area && itemIn.area.name}: </span>
              {addTransaction.selectedArea && addTransaction.selectedArea.length
                ? Utils.replaceAll(addTransaction.selectedArea.toString(), "Sextant", "S")
                : ""}
            </div>
            {Boolean(seriesType === SidebarTypes.Treatments && this.props.showFee && !isMixed) && (
              <TopCalculationBoxPriceSelect
                item={item}
                typeTab={typeTab}
                seriesActions={seriesActions}
                addTransaction={addTransaction}
                chooseItemPrice={this.chooseItemPrice}
                hasPatientInsurance={hasPatientInsurance}
              />
            )}
          </div>
        )
      );
    }
  },
);
