import React from "react";

import FlexBox from "../../ui/FlexBox";

export function PerioExamValues({ color = true, ...props }) {
  return (
    <FlexBox align="center" justify="center">
      <FlexBox flex={true}>
        <FlexBox
          className="exam-value"
          style={{
            color: color
              ? props.distal <= 3
                ? "green"
                : props.distal <= 6
                ? "orange"
                : "red"
              : "black",
          }}
        >
          {props.distal}
        </FlexBox>

        <FlexBox
          className="exam-value"
          style={{
            color: color
              ? props.central <= 3
                ? "green"
                : props.central <= 6
                ? "orange"
                : "red"
              : "black",
          }}
        >
          {props.central}
        </FlexBox>

        <FlexBox
          className="exam-value"
          style={{
            color: color
              ? props.mesial <= 3
                ? "green"
                : props.mesial <= 6
                ? "orange"
                : "red"
              : "black",
          }}
        >
          {props.mesial}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
