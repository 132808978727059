import React from "react";
import BackButton from "../widgets/BackButton";
import { orderTabs, popStack, setActiveTab, updateStack } from "../../actions/orderActions";
import { ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { orderStackCodes } from "../../constants/chart-sidebar/orderStackCodes";
import Show from "../widgets/Show";
const ChartOrderHeader = ({ activeTab, medicalForms }) => {
  const dispatch = useDispatch();

  return (
    <div className="sidebar-header chart-side-header">
      <div className="clearfix">
        <div className="col-sm-2">
          <Show if={!medicalForms}>
            <BackButton className="btn-sm" text="Back" onClick={() => dispatch(popStack())} />
          </Show>
        </div>
        <div className="col-sm-8">
          <div className="chart-clinical-note-title" style={{ textAlign: "center" }}>
            ORDER
          </div>
        </div>
        <div className="col-sm-2" />
      </div>
      <div
        style={{
          padding: "0 1rem",
        }}
      >
        <ButtonGroup aria-label="note tabs" className="d-flex align-items-center">
          <button type="button" className="btn btn-default">
            &nbsp;
          </button>
          {orderTabs.map((tab) => (
            <button
              key={tab.code}
              type="button"
              onClick={() => {
                dispatch(setActiveTab(tab));
                dispatch(updateStack({ code: orderStackCodes.list }));
              }}
              className={`btn ${activeTab.code === tab.code ? "btn-primary" : "btn-default"}`}
              style={{ flex: 1 }}
            >
              {tab.name}
            </button>
          ))}
          <button type="button" className="btn btn-default">
            &nbsp;
          </button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ChartOrderHeader;
