import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";

import {
  CHANGE_ACTIVE_TOOTH,
  CHART_CHANGE_EYE,
  CHART_CLEAR_LIST,
  CHART_GET_PATIENT_GENDER_SUCCESS,
  CHART_HIDE_ALERTS,
  CHART_HIDE_CHOOSE_TOOLTIP,
  CHART_HIDE_REQUEST_LAB_ORDER_MODAL,
  CHART_HIDE_SIDE_BAR,
  CHART_HIDE_TOOLTIP,
  CHART_SET_POST_PLAN,
  CHART_SHOW_ALERTS,
  CHART_SHOW_CHOOSE_TOOLTIP,
  CHART_SHOW_REQUEST_LAB_ORDER_MODAL,
  CHART_SHOW_SIDE_BAR,
  CHART_SHOW_SIDE_BAR_TRANSACTION,
  CHART_SHOW_TOOLTIP,
  CHART_STOP_DEAL_LIST_ERROR,
  CHART_STOP_DEAL_LIST_START,
  CHART_STOP_DEAL_LIST_SUCCESS,
  CHART_SWITCH_FOLDER_MODAL,
  CHART_VOIDED_HIDE_MODAL,
  CHART_VOIDED_SHOW_MODAL,
  CHART_VOIDED_TREATMENTS_LIST_ERROR,
  CHART_VOIDED_TREATMENTS_LIST_START,
  CHART_VOIDED_TREATMENTS_LIST_SUCCESS,
  GET_CHART_APPOINTMENT_SUCCESS,
  GET_CHART_FOR_STAFF_SUCCESS,
  GET_CHART_PATIENT_BALANCE_MODAL_HIDE,
  GET_CHART_PATIENT_BALANCE_MODAL_SHOW,
  GET_CHART_PATIENT_BALANCE_SUCCESS,
  GET_CHART_PATIENT_SUCCESS,
  GET_CHART_TEETH_SUCCESS,
  GET_PATIENT_HISTORY_DATA_ERROR,
  GET_PATIENT_HISTORY_DATA_START,
  GET_PATIENT_HISTORY_DATA_SUCCESS,
  INIT_CHART,
  SELECT_TREATMENT_TO_COMPLETE_SET_FINISH_MODE,
  SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM,
  SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM_CLEAR,
  SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEMS,
  SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_ERROR,
  SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_START,
  SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_SUCCESS,
  SELECT_TREATMENTS_TO_COMPLETE_MODAL_HIDE,
  SELECT_TREATMENTS_TO_COMPLETE_MODAL_SHOW,
  SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_HIDE,
  SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_SHOW,
  SELECT_TREATMENTS_TO_COMPLETE_TOGGLE_SELECT_ALL,
  SET_SIDE_BAR_TAB_TYPE,
  SHOW_PASSWORD_MODAL_FOR_HISTORY,
  SHOW_PATIENT_HISTORY_MODAL,
  VOIDED_TREATMENTS_TOGGLE_SELECT_ALL,
  VOIDED_TREATMENTS_TOGGLE_SELECT_KEY,
  ADD_DIAGNOSIS_FOR_COMPLETE,
  OPEN_PATIENT_DOCS_SIDEBAR,
  CLOSE_PATIENT_DOCS_SIDEBAR,
  SET_ADVANCED_PAYMENT_MODAL_VISIBLE,
  SET_ADVANCED_PAYMENT_CONTENT,
  GET_ADVANCED_PAYMENT_LIST_START,
  GET_ADVANCED_PAYMENT_LIST_SUCCESS,
  GET_ADVANCED_PAYMENT_LIST_ERROR,
  GET_ADVANCED_PAYMENT_METHOD_LIST_SUCCESS,
  GET_ADVANCED_PAYMENT_WALLET_LIST_SUCCESS,
  GET_ADVANCED_PAYMENT_OTHER_LIST_SUCCESS,
  GET_PATIENT_LABEL_PDF_SUCCESS,
  GET_PATIENT_LABEL_PDF_ERROR,
  SET_PATIENT_LABEL_MODAL_VISIBLE,
  SET_TREATMENTS_TO_COMPLETE_VISIT_TYPE,
  GET_CHART_PATIENT_BILLING_SUCCESS,
  CUSTOMIZE_PACKAEGE_MODAL_SHOW,
  CUSTOMIZE_PACKAEGE_MODAL_HIDE,
  SELECT_TREATMENT_TO_COMPLETE_TOGGLE_MENU,
  UPDATE_CHART_BILLING_BALANCE_VALUE,
  UPDATE_COMPLETE_TREATMENTS_LIST_FILTER,
} from "../actions/chartActions";

import ToothDetailsType from "../constants/teeth/ToothDetailsType";
import ToothGeneration from "../constants/teeth/ToothGeneration";
import viewTypes from "../constants/chart-sidebar/viewTypes";

import getSurfaces from "../helpers/getSurfaces";

import * as ActionType from "../actionTypes/chartRepeatAppointmentActionTypes";
const ToothImageNames = {
  Adult_p1: 18,
  Adult_p2: 17,
  Adult_p3: 16,
  Adult_p4: 15,
  Adult_p5: 14,
  Adult_p6: 13,
  Adult_p7: 12,
  Adult_p8: 11,
  Adult_p9: 21,
  Adult_p10: 22,
  Adult_p11: 23,
  Adult_p12: 24,
  Adult_p13: 25,
  Adult_p14: 26,
  Adult_p15: 27,
  Adult_p16: 28,
  Adult_p17: 38,
  Adult_p18: 37,
  Adult_p19: 36,
  Adult_p20: 35,
  Adult_p21: 34,
  Adult_p22: 33,
  Adult_p23: 32,
  Adult_p24: 31,
  Adult_p25: 41,
  Adult_p26: 42,
  Adult_p27: 43,
  Adult_p28: 44,
  Adult_p29: 45,
  Adult_p30: 46,
  Adult_p31: 47,
  Adult_p32: 48,
  Child_p4: 17,
  Child_p5: 16,
  Child_p6: 13,
  Child_p7: 12,
  Child_p8: 11,
  Child_p9: 21,
  Child_p10: 22,
  Child_p11: 23,
  Child_p12: 26,
  Child_p13: 27,
  Child_p20: 37,
  Child_p21: 36,
  Child_p22: 33,
  Child_p23: 32,
  Child_p24: 31,
  Child_p25: 41,
  Child_p26: 42,
  Child_p27: 43,
  Child_p28: 46,
  Child_p29: 47,
};
export const defaultState = {
  teeth: {},
  patient: {},
  appointment: {},
  activeTooth: 0,
  activeToothSupernumeraryPresent: false,
  tooltipVisible: false,
  chartBalanceModalVisible: false,
  chartBalanceValue: 0,
  chartBillingBalanceValue: {
    walletBalance: 0,
    outstandingBalance: 0,
  },
  folderModalVisible: false,
  forStaff: {
    medicalAlerts: {
      alerts: [],
    },
    medicationAlerts: {
      alerts: [],
    },
  },
  alertsVisible: false,
  showDocsModal: false,

  showRequestLabOrderModal: false,

  selectTreatmentToCompleteModalVisible: false,
  selectTreatmentToCompleteModalMode: "",
  selectTreatmentToCompleteModalFinishMode: "treatment",
  selectTreatmentToCompleteList: [],
  selectTreatmentToCompleteListFetching: false,
  selectTreatmentToCompleteListTotalCount: 0,
  selectTreatmentToCompleteSelectedItems: {},
  selectTreatmentToCompleteNoteModalVisible: false,
  selectTreatmentToCompleteDoctorsFilter: {},

  selectDiagnosisToCompleteList: [],
  selectDiagnosisToCompleteListFetching: false,
  selectDiagnosisToCompleteListTotalCount: 0,
  selectDiagnosisToCompleteSelectedItems: {},

  repeatAppointmentModalVisible: false,
  repeatAppointmentMode: "now",
  repeatAppointmentSelectedDate: moment(),
  repeatAppointmentList: [],
  repeatAppointmentConfirmModalVisible: false,
  repeatAppointmentRepeatModalVisible: false,
  repeatAppointmentRepeatModalMode: "after",
  repeatAppointmentRepeatModalDate: moment(),
  repeatAppointmentRepeatModalIndex: 0,
  repeatAppointmentClickedRepeatArray: [],
  repeatAppointmentDeleteId: 0,
  areasList: {},
  chartVoidedModalVisible: false,
  voidedTreatmentsListFetching: false,
  voidedTreatmentsList: [],
  voidedTreatmentsListTotalCount: 0,
  voidedTreatmentsSelectedItems: [],

  toothPosition: 0,
  chooseTooltipVisible: false,

  eyeCheckedMap: {
    Planned: true,
    Completed: true,
    Existing: true,
    Condition: true,
    Diagnosis: true,
  },
  isPostPlan: false,

  patientGenderList: [],
  sideBarTabType: "treatment",
  passwordModalForHistoryVisibility: false,
  patientHistoryModalVisibility: false,
  formUrl: "",
  formData: {},
  fetchingPatientHistory: false,
  addDiagnosisForComplete: false,
  advancedPaymentModalVisible: false,
  advancedPaymentContentType: "form",
  advancedPaymentList: [],
  advancedPaymentListLoading: false,
  advancedPaymentListTotalCount: 0,
  advancedPaymentMethodList: [],
  advancedPaymentWalletList: [],
  advancedPaymentOtherList: [],
  patientLabelModalVisible: false,
  patientLabel: "",
  customizePackageModalVisible: false,
  selectTreatmentToCompletePatientInfoVisible: false,
  transactionsToCompleteFilter: localStorage.getItem("transactionsToCompleteGroupBy"),
};

const reducers = {
  [UPDATE_COMPLETE_TREATMENTS_LIST_FILTER](state, { _filter }) {
    return {
      ...state,
      transactionsToCompleteFilter: _filter,
    };
  },
  [UPDATE_CHART_BILLING_BALANCE_VALUE](state) {
    return {
      ...state,
      chartBillingBalanceValue: {
        walletBalance: 0,
        outstandingBalance: 0,
      },
    };
  },
  [SELECT_TREATMENT_TO_COMPLETE_TOGGLE_MENU](state) {
    return {
      ...state,
      selectTreatmentToCompletePatientInfoVisible: !state.selectTreatmentToCompletePatientInfoVisible,
    };
  },
  [SET_ADVANCED_PAYMENT_MODAL_VISIBLE](state, { advancedPaymentModalVisible }) {
    return {
      ...state,
      advancedPaymentModalVisible,
    };
  },
  [GET_PATIENT_LABEL_PDF_SUCCESS](state, { data }) {
    return {
      ...state,
      patientLabel: data,
    };
  },
  [GET_PATIENT_LABEL_PDF_ERROR](state) {
    return {
      ...state,
      patientLabel: "",
    };
  },
  [SET_PATIENT_LABEL_MODAL_VISIBLE](state, { patientLabelModalVisible }) {
    return {
      ...state,
      patientLabelModalVisible,
    };
  },
  [SET_ADVANCED_PAYMENT_CONTENT](state, { advancedPaymentContentType }) {
    return {
      ...state,
      advancedPaymentContentType,
    };
  },
  [GET_ADVANCED_PAYMENT_LIST_START](state) {
    return {
      ...state,
      advancedPaymentListLoading: true,
    };
  },
  [GET_ADVANCED_PAYMENT_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      advancedPaymentList: data.list,
      advancedPaymentListLoading: false,
      advancedPaymentListTotalCount: data.totalCount,
    };
  },
  [GET_ADVANCED_PAYMENT_LIST_ERROR](state) {
    return {
      ...state,
      advancedPaymentListLoading: false,
    };
  },
  [GET_ADVANCED_PAYMENT_METHOD_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      advancedPaymentMethodList: data,
    };
  },
  [GET_ADVANCED_PAYMENT_WALLET_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      advancedPaymentWalletList: data,
    };
  },
  [GET_ADVANCED_PAYMENT_OTHER_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      advancedPaymentOtherList: data,
    };
  },
  [ADD_DIAGNOSIS_FOR_COMPLETE](state) {
    return {
      ...state,
      addDiagnosisForComplete: !state.addDiagnosisForComplete,
    };
  },
  [OPEN_PATIENT_DOCS_SIDEBAR](state, { imageTypeQuestion }) {
    return {
      ...state,
      showDocsModal: !imageTypeQuestion,
    };
  },
  [CLOSE_PATIENT_DOCS_SIDEBAR](state) {
    return {
      ...state,
      showDocsModal: false,
    };
  },
  [CHART_GET_PATIENT_GENDER_SUCCESS](state, { data = [] }) {
    return {
      ...state,
      patientGenderList: data,
    };
  },
  [CHART_SHOW_REQUEST_LAB_ORDER_MODAL](state) {
    return {
      ...state,
      showRequestLabOrderModal: true,
    };
  },
  [SET_SIDE_BAR_TAB_TYPE](state, { sideBarTabType }) {
    return {
      ...state,
      sideBarTabType,
    };
  },
  [SHOW_PASSWORD_MODAL_FOR_HISTORY](state, { bool }) {
    return {
      ...state,
      passwordModalForHistoryVisibility: bool,
    };
  },
  [SHOW_PATIENT_HISTORY_MODAL](state, { bool }) {
    return {
      ...state,
      patientHistoryModalVisibility: bool,
    };
  },
  [CHART_HIDE_REQUEST_LAB_ORDER_MODAL](state) {
    return {
      ...state,
      showRequestLabOrderModal: false,
    };
  },
  [CHART_SET_POST_PLAN](state, { isPostPlan }) {
    return {
      ...state,
      isPostPlan,
    };
  },
  [CHART_SWITCH_FOLDER_MODAL](state) {
    return {
      ...state,
      folderModalVisible: !state.folderModalVisible,
    };
  },
  [VOIDED_TREATMENTS_TOGGLE_SELECT_KEY](state, { key }) {
    const voidedTreatmentsSelectedItems = {
      ...state.voidedTreatmentsSelectedItems,
    };

    voidedTreatmentsSelectedItems[key] = !voidedTreatmentsSelectedItems[key];

    return {
      ...state,
      voidedTreatmentsSelectedItems,
    };
  },
  [VOIDED_TREATMENTS_TOGGLE_SELECT_ALL](state) {
    const checkedAll =
      _.filter(state.voidedTreatmentsSelectedItems, (item) => item).length ||
      state.voidedTreatmentsList.length;

    const voidedTreatmentsSelectedItems = {};

    if (!checkedAll) {
      state.voidedTreatmentsList.forEach(
        (item) => (voidedTreatmentsSelectedItems[item.key] = true),
      );
    }

    return {
      ...state,
      voidedTreatmentsSelectedItems,
    };
  },
  [GET_PATIENT_HISTORY_DATA_START](state) {
    return {
      ...state,
      fetchingPatientHistory: true,
    };
  },
  [GET_PATIENT_HISTORY_DATA_SUCCESS](state, { data }) {
    localStorage.setItem("hieData", JSON.stringify(data));
    const alink = document.createElement("a");
    alink.href = "../hie.html";
    alink.target = "_blank";
    alink.click();
    return {
      ...state,
      fetchingPatientHistory: false,
      passwordModalForHistoryVisibility: false,
      patientHistoryModalVisibility: true,
      formUrl: data.endpoint,
      formData: data,
    };
  },
  [GET_PATIENT_HISTORY_DATA_ERROR](state) {
    return {
      ...state,
      fetchingPatientHistory: false,
      passwordModalForHistoryVisibility: false,
    };
  },
  [CHART_VOIDED_TREATMENTS_LIST_START](state) {
    return {
      ...state,
      voidedTreatmentsListFetching: true,
      voidedTreatmentsListTotalCount: 0,
    };
  },
  [CHART_VOIDED_TREATMENTS_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      voidedTreatmentsList: request.start
        ? state.voidedTreatmentsList.concat(data.list)
        : data.list || [],
      voidedTreatmentsListTotalCount: data.totalCount,
      voidedTreatmentsListFetching: false,
    };
  },
  [CHART_VOIDED_TREATMENTS_LIST_ERROR](state) {
    return {
      ...state,
      voidedTreatmentsListFetching: false,
    };
  },
  [CHART_VOIDED_SHOW_MODAL](state) {
    return {
      ...state,
      voidedTreatmentsListFetching: false,
      voidedTreatmentsList: [],
      voidedTreatmentsListTotalCount: 0,
      voidedTreatmentsSelectedItems: [],
      chartVoidedModalVisible: true,
    };
  },
  [CHART_VOIDED_HIDE_MODAL](state) {
    return {
      ...state,
      chartVoidedModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_REPEAT_MODAL_MODE](state, { mode }) {
    return {
      ...state,
      repeatAppointmentRepeatModalMode: mode,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SELECT_DATE](state, { value }) {
    return {
      ...state,
      repeatAppointmentRepeatModalDate: value,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_SHOW](state, { index }) {
    return {
      ...state,
      repeatAppointmentRepeatModalVisible: true,
      repeatAppointmentRepeatModalIndex: index,
      repeatAppointmentRepeatModalMode: "after",
    };
  },
  [ActionType.REPEAT_APPOINTMENT_REPEAT_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentRepeatModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_ADD_REPEAT_ARRAY](state, { id }) {
    return {
      ...state,
      repeatAppointmentClickedRepeatArray: [...state.repeatAppointmentClickedRepeatArray, id],
    };
  },
  [ActionType.REPEAT_APPOINTMENT_DELETE_REPEAT_ARRAY](state, { id }) {
    const newArray = [...state.repeatAppointmentClickedRepeatArray];

    const index = newArray.indexOf(id);
    if (index !== -1) newArray.splice(index, 1);

    return {
      ...state,
      repeatAppointmentClickedRepeatArray: newArray,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_DELETE_ID](state, { id }) {
    return {
      ...state,
      repeatAppointmentDeleteId: id,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_SHOW](state) {
    return {
      ...state,
      repeatAppointmentConfirmModalVisible: true,
      repeatAppointmentMode: "now",
      repeatAppointmentSelectedDate: moment(),
      repeatAppointmentList: [],
      repeatAppointmentRepeatModalMode: "after",
      repeatAppointmentRepeatModalDate: moment(),
      repeatAppointmentRepeatModalIndex: 0,
      repeatAppointmentClickedRepeatArray: [],
      repeatAppointmentDeleteId: 0,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentConfirmModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SELECT_DATE](state, { value }) {
    return {
      ...state,
      repeatAppointmentSelectedDate: value,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_SET_FOLLOW_TYPE](state, { mode }) {
    return {
      ...state,
      repeatAppointmentMode: mode,
    };
  },
  [ActionType.PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_SHOW](state) {
    return {
      ...state,
      repeatAppointmentModalVisible: true,
    };
  },
  [ActionType.PATIENT_CHART_REPEAT_APPOINTMENT_MODAL_HIDE](state) {
    return {
      ...state,
      repeatAppointmentModalVisible: false,
    };
  },
  [ActionType.REPEAT_APPOINTMENT_GET_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      repeatAppointmentList: data.list,
    };
  },

  [SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_SHOW](state) {
    return {
      ...state,
      selectTreatmentToCompleteNoteModalVisible: true,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_NOTE_MODAL_HIDE](state) {
    return {
      ...state,
      selectTreatmentToCompleteNoteModalVisible: false,
    };
  },
  [SELECT_TREATMENT_TO_COMPLETE_SET_FINISH_MODE](state, { mode }) {
    return {
      ...state,
      selectTreatmentToCompleteModalFinishMode: mode,
    };
  },
  [SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM_CLEAR](state) {
    return {
      ...state,
      selectTreatmentToCompleteSelectedItems: {},
    };
  },
  [SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEM](state, { treatmentKey, clear }) {
    const selectTreatmentToCompleteSelectedItems = {
      ...state.selectTreatmentToCompleteSelectedItems,
    };

    selectTreatmentToCompleteSelectedItems[treatmentKey] = clear
      ? false
      : !selectTreatmentToCompleteSelectedItems[treatmentKey];

    return {
      ...state,
      selectTreatmentToCompleteSelectedItems,
    };
  },
  [SELECT_TREATMENT_TO_COMPLETE_TOGGLE_SELECTED_ITEMS](state, { treatmentKeys }) {
    const selectTreatmentToCompleteSelectedItems = {
      ...state.selectTreatmentToCompleteSelectedItems,
    };

    _.forEach(treatmentKeys, (item) => {
      selectTreatmentToCompleteSelectedItems[item] = !selectTreatmentToCompleteSelectedItems[item];
    });

    return {
      ...state,
      selectTreatmentToCompleteSelectedItems,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_TOGGLE_SELECT_ALL](state, { clinicId }) {
    const { selectTreatmentToCompleteList } = state;

    const checkedAll =
      _.filter(state.selectTreatmentToCompleteSelectedItems, (item) => item).length ===
      state.selectTreatmentToCompleteList.length;

    const selectTreatmentToCompleteSelectedItems = {};

    if (!checkedAll) {
      state.selectTreatmentToCompleteList.forEach((item) => {
        const treatment = _.find(selectTreatmentToCompleteList, (x) => {
          const xClinicId = _.get(x, "clinic.id");

          return x.key === item.key && xClinicId === clinicId;
        });

        if (treatment) {
          selectTreatmentToCompleteSelectedItems[item.key] = true;
        }
      });
    }

    return {
      ...state,
      selectTreatmentToCompleteSelectedItems,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_START](state) {
    return {
      ...state,
      selectTreatmentToCompleteListTotalCount: 0,
      // selectTreatmentToCompleteSelectedItems: {},
      selectTreatmentToCompleteCashierNote: "",
      selectTreatmentToCompleteListFetching: true,
    };
  },
  [CHART_CLEAR_LIST](state) {
    return {
      ...state,
      selectTreatmentToCompleteList: [],
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      selectTreatmentToCompleteList: request.start
        ? state.selectTreatmentToCompleteList.concat(data.list)
        : data.list || [],
      selectTreatmentToCompleteListTotalCount: data.totalCount,
      selectTreatmentToCompleteListFetching: false,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_GET_LIST_ERROR](state) {
    return {
      ...state,
      selectTreatmentToCompleteListFetching: false,
    };
  },
  [CHART_STOP_DEAL_LIST_START](state) {
    return {
      ...state,
      selectTreatmentToCompleteListTotalCount: 0,
      // selectTreatmentToCompleteSelectedItems: {},
      selectTreatmentToCompleteCashierNote: "",
      selectTreatmentToCompleteListFetching: true,
    };
  },
  [CHART_STOP_DEAL_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      selectTreatmentToCompleteList: request.start
        ? state.selectTreatmentToCompleteList.concat(data.list)
        : data.list || [],
      selectTreatmentToCompleteListTotalCount: data.totalCount,
      selectTreatmentToCompleteListFetching: false,
    };
  },
  [CHART_STOP_DEAL_LIST_ERROR](state) {
    return {
      ...state,
      selectTreatmentToCompleteListFetching: false,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_MODAL_SHOW](state, { mode }) {
    return {
      ...state,
      selectTreatmentToCompleteModalVisible: true,
      selectTreatmentToCompleteModalMode: mode,
      selectTreatmentToCompleteModalFinishMode: "treatment",
      selectTreatmentToCompleteList: [],
      selectTreatmentToCompleteListFetching: false,
      selectTreatmentToCompleteListTotalCount: 0,
      selectTreatmentToCompleteSelectedItems: {},
      selectTreatmentToCompleteNoteModalVisible: false,
    };
  },
  [SET_TREATMENTS_TO_COMPLETE_VISIT_TYPE](state, { mode }) {
    return {
      ...state,
      selectTreatmentToCompleteModalMode: mode,
    };
  },
  [SELECT_TREATMENTS_TO_COMPLETE_MODAL_HIDE](state) {
    return {
      ...state,
      selectTreatmentToCompleteModalVisible: false,
    };
  },
  [GET_CHART_PATIENT_BALANCE_SUCCESS](state, { data }) {
    return {
      ...state,
      chartBalanceValue: data,
    };
  },
  [GET_CHART_PATIENT_BILLING_SUCCESS](state, { data }) {
    return {
      ...state,
      chartBillingBalanceValue: data,
    };
  },
  [GET_CHART_PATIENT_BALANCE_MODAL_SHOW](state) {
    return {
      ...state,
      chartBalanceModalVisible: true,
    };
  },
  [GET_CHART_PATIENT_BALANCE_MODAL_HIDE](state) {
    return {
      ...state,
      chartBalanceModalVisible: false,
    };
  },
  [GET_CHART_TEETH_SUCCESS](state, { data }) {
    const teeth = {};

    data.forEach((tooth) => {
      const toothPositionCode = tooth.toothPositionCode;
      const toothGenerationCode =
        (tooth.toothConditions.toothGeneration && tooth.toothConditions.toothGeneration.code) || "";
      const toothKey = `${toothGenerationCode}_${toothPositionCode}`;
      const toothPosition = toothPositionCode.slice(1);

      if (tooth.toothConditions) {
        tooth.toothConditions.notChange = true;
      }

      if (!teeth.hasOwnProperty(toothPositionCode)) {
        teeth[toothPositionCode] = {};
      }
      const result = teeth[toothPositionCode];

      if (toothGenerationCode === ToothGeneration.Supernumerary) {
        result.supernumerary = {
          ...tooth,
          details: ToothDetailsType[toothKey],
        };
      } else {
        result.normal = {
          ...tooth,
          details: ToothDetailsType[toothKey],
        };

        if (!ToothDetailsType[toothKey]) {
          const toothKey = `${ToothGeneration.Adult}_${toothPositionCode}`;

          result.normal.missedTooth = ToothDetailsType[toothKey];
          result.normal.missedImage = ToothImageNames[toothKey];
        }

        result.image = ToothImageNames[toothKey];

        result.surfaceStrings = {
          surface: getSurfaces(toothPosition, true),
          tooth: getSurfaces(toothPosition, false),
        };
      }
    });

    return { ...state, teeth };
  },
  [GET_CHART_PATIENT_SUCCESS](state, { data }) {
    return { ...state, patient: data };
  },
  [GET_CHART_APPOINTMENT_SUCCESS](state, { data }) {
    return { ...state, appointment: data };
  },
  [INIT_CHART](state) {
    const areasList = {
      1: "FACE",
      2: "RIGHT_ARM",
      3: "CHEST",
      4: "RIGHT_LEG",
      5: "BACK",
      6: "LEFT_ARM",
      7: "LOWER_BACK",
      8: "LEFT_LEG",

      9: "SCALP",
      10: "RIGHT_AXILLA",
      11: "GENITAL",
      12: "RIGHT_FOOT",
      13: "ABDOMEN",
      14: "LEFT_AXILLA",
      15: "LEFT_FOOT",
    };

    return {
      ...state,
      appointment: {},
      selectTreatmentToCompleteModalVisible: false,
      selectTreatmentToCompleteModalMode: "",
      selectTreatmentToCompleteModalFinishMode: "treatment",
      selectTreatmentToCompleteList: [],
      selectTreatmentToCompleteListFetching: false,
      selectTreatmentToCompleteListTotalCount: 0,
      selectTreatmentToCompleteSelectedItems: {},
      selectTreatmentToCompleteNoteModalVisible: false,
      areasList,
    };
  },
  [CHART_SHOW_TOOLTIP](state, { activeTooth, activeToothSupernumeraryPresent }) {
    return {
      ...state,
      tooltipVisible: true,
      activeTooth,
      activeToothSupernumeraryPresent,
    };
  },
  [CHART_HIDE_TOOLTIP](state) {
    return {
      ...state,
      tooltipVisible: false,
      activeTooth: 0,
      activeToothSupernumeraryPresent: false,
    };
  },
  [CHART_SHOW_SIDE_BAR](state, { viewType, activeTooth, patientKey = 0, medicalForms = false }) {
    return {
      ...state,
      sideBarVisible: true,
      activeTooth,
      viewType,
      patientKey,
      medicalForms,
    };
  },
  CHART_SET_PATIENT_KEY(state, { patientKey }) {
    return {
      ...state,
      patientKey,
    };
  },
  [CHART_SHOW_SIDE_BAR_TRANSACTION](state) {
    return {
      ...state,
      sideBarVisible: true,
      viewType: viewTypes.transaction,
    };
  },
  [CHART_HIDE_SIDE_BAR](state, { saveTooth }) {
    const answer = {
      ...state,
      sideBarVisible: false,
    };

    if (!saveTooth) {
      answer.activeTooth = 0;
    }

    return answer;
  },
  [CHANGE_ACTIVE_TOOTH](state, { activeTooth }) {
    return { ...state, activeTooth, activeToothSupernumeraryPresent: null };
  },
  [GET_CHART_FOR_STAFF_SUCCESS](state, { data }) {
    return {
      ...state,
      forStaff: data,
    };
  },
  [CHART_SHOW_ALERTS](state) {
    return {
      ...state,
      alertsVisible: true,
    };
  },
  [CHART_HIDE_ALERTS](state) {
    return {
      ...state,
      alertsVisible: false,
    };
  },
  [CHART_SHOW_CHOOSE_TOOLTIP](state, { toothPosition }) {
    return {
      ...state,
      toothPosition,
      chooseTooltipVisible: true,
    };
  },
  [CHART_HIDE_CHOOSE_TOOLTIP](state) {
    return {
      ...state,
      toothPosition: 0,
      chooseTooltipVisible: false,
    };
  },
  [CHART_CHANGE_EYE](state, { key }) {
    const eyeCheckedMap = JSON.parse(JSON.stringify(state.eyeCheckedMap));

    eyeCheckedMap[key] = !eyeCheckedMap[key];

    return {
      ...state,
      eyeCheckedMap,
    };
  },
  [CUSTOMIZE_PACKAEGE_MODAL_SHOW](state) {
    return {
      ...state,
      customizePackageModalVisible: true,
    };
  },
  [CUSTOMIZE_PACKAEGE_MODAL_HIDE](state) {
    return {
      ...state,
      customizePackageModalVisible: false,
    };
  },
};

/** ==================
 * Selectors
 * ================== */
export const chartViewTypeSelector = ({ chart }) => chart.viewType;

export const chartBalanceValueSelector = ({ chart }) => chart.chartBalanceValue;
export const chartBillingBalanceValueSelector = ({ chart }) => chart.chartBillingBalanceValue;
export const chartBalanceModalVisibleSelector = ({ chart }) => chart.chartBalanceModalVisible;
export const folderModalVisibleSelector = ({ chart }) => chart.folderModalVisible;

export default createReducer(defaultState, reducers);
