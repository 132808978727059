import { useMutation, UseMutationResult } from "react-query";

import { ApiRoutes } from "../../constants/ApiRoutes";
import { httpClient } from "../../api-legacy/BaseApi";
import { formatResponse } from "../../utils/FormatUtils";

export function useCreateBlockedTimeSlotItemMutation(): UseMutationResult<unknown, Error> {
  return useMutation((x) =>
    httpClient.post(ApiRoutes.BlockedTimeSlotItem, { data: x }).then(formatResponse),
  );
}
