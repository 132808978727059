import _ from "lodash";
import React, { useState } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";

import Show from "../widgets/Show";
import Utils from "../../helpers/Utils";
import Colors from "../../helpers/Colors";
import { defaultColors } from "../../constants/defaults";
import { getCompanyStartOfDay } from "../../helpers/DateUtils";
import { DATE_FORMAT_LEGACY, TIME_FORMAT, TimeViews, ViewTypes } from "../../constants/Constants";
import { withRouter } from "react-router";
import { createUrl } from "../../utils/UrlUtils";
import { Routes } from "../../constants/Routes";
import CompactBackgroundImage from "../../assets/images/calendar-greed_slim.png";
import ComfortBackgroundImage from "../../assets/images/calendar-greed_large.png";
import { openSimpleModal } from "../../actions/simpleModalActions";
import { saveAppointment } from "../../actions/appointmentActions";
import { onDragAppointmentsList } from "../../actions/calendarActions";
import { copyToClipboardAppointment, getCalendarData } from "../../actions/calendarActions";

const CellInfo = ({ details, index }) => {
  const { visible, point, name, checkedTime } = details;
  if (visible === index) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255,255,255,1)",
          color: "rgba(0,0,0,1)",
          zIndex: 20,
          padding: "1rem",
          position: "absolute",
          borderRadius: "10px",
          right: 0,
          top: "20px",
        }}
      >
        chart number: #{point.patient?.chartNumber}
        <br />
        name: {name}
        <br />
        type: {point.category && point.category.name}
        <br />
        {localStorage.getItem("doctorLabel")}: {point.dentist && point.dentist.name}
        <br />
        checked time: {checkedTime}
        <br />
        note: {point.note}
        {(point.patient?.patientInsurances || []).map((item, idx) => (
          <div style={{ borderTop: "1px solid" }} key={idx}>
            Insurance plan:{" "}
            <span style={{ fontWeight: "bold" }}>{item.insurancePlan?.name || ""}</span>
            <br />
            Card number: <span style={{ fontWeight: "bold" }}>{item.cardNumber || ""}</span>
            <br />
            Dental usage limit:{" "}
            <span style={{ fontWeight: "bold" }}>{item.maxUsageAmount || ""}</span>
            <br />
            PAYER ID | Broker: <span style={{ fontWeight: "bold" }}>{item.broker?.name || ""}</span>
            <br />
            Expiration date:{" "}
            <span style={{ fontWeight: "bold" }}>
              {moment(item.expireDate).format(DATE_FORMAT_LEGACY)}
            </span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const enhancer = connect(
  ({
    session: {
      company,
      allowAppointmentMultiBooking,
      member,
      permissions,
      clinic,
      miscSettings: { calendarMinuteIncrements },
    },
    calendar: { activeDoctorId, date, calendarViewMode, calendarWeaklyMode },
    appointment: { copyMode, clipBoardAppointment },
  }) => ({
    date,
    member,
    clinic,
    company,
    copyMode,
    permissions,
    activeDoctorId,
    calendarViewMode,
    calendarWeaklyMode,
    clipBoardAppointment,
    calendarMinuteIncrements,
    allowAppointmentMultiBooking,
  }),
);
const CalendarColumn = (props) => {
  const dispatch = useDispatch();
  const [cellInfoDetails, setCellInfoDetails] = useState({ visible: null });

  const shortCutName = (name) => {
    if (!name) return;
    if (name.length < 16) {
      return name;
    }
    const fullNameArray = name.split(" ");
    let shortedName = "";
    fullNameArray.forEach((item) => {
      if (shortedName.length < 16) {
        shortedName = shortedName.concat(` ${item}`);
      }
    });
    return shortedName.trim();
  };
  const {
    cells,
    columns,
    company,
    timeSlot,
    masterTypes,
    onNoteClick,
    // calendarViewMode,
    onBTSClick,
    day,
    copyMode,
    member,
    clinic,
    permissions,
    clipBoardAppointment,
    setContextMenuDetails,
    calendarWeaklyMode,
    startTime: clinicStartTime,
    allowAppointmentMultiBooking,
    pasteAppointmentFunctionTest,
    calendarMinuteIncrements = 15,
  } = props;

  const currentDate = Utils.getStartOfDate(moment().startOf("day"));
  const cellList = JSON.parse(localStorage.getItem("callListNoResponse"));
  const todayCellList = cellList[currentDate];

  const columnView = localStorage.getItem("columnView");
  const rowView = localStorage.getItem("rowView");
  const timeView = localStorage.getItem("timeView");

  let columnHeight = 40;

  if (rowView === ViewTypes.compact) columnHeight = 20;

  const types = [];

  _.forEach(masterTypes, (item) => {
    if (item.active) {
      types.push(item.id);
    }
  });

  const filteredCells = [];
  cells.forEach((cell) => {
    if (!filteredCells.find((i) => i.id === cell.id)) filteredCells.push(cell);
  });

  const $cells = (allowAppointmentMultiBooking && !calendarWeaklyMode ? filteredCells : cells).map(
    (point, index) => {
      let utcOffset;
      let cellWidth = 100;
      let cellLeft = 0;
      if (company) {
        utcOffset = _.toFinite(_.get(company, "timezone.description", Date.now())) / 60 / 1000;
      } else {
        utcOffset = moment().utcOffset();
      }

      const startDate = Utils.calcDateWithTimeZone(point.startTime, utcOffset);
      const hours = startDate.hour();
      const minutes = startDate.minute();

      const duration = point.duration / 1000 / 60;

      const startTime = hours * 60 + minutes;
      const endTime = startTime + duration;

      const height = (duration / calendarMinuteIncrements) * columnHeight;
      const top = ((startTime - clinicStartTime) / calendarMinuteIncrements) * columnHeight;

      const time = `${Utils.intToDayPartStr(startTime)} - ${Utils.intToDayPartStr(endTime)}`;

      const name = Utils.getFullName(point.patient);
      if (allowAppointmentMultiBooking && !calendarWeaklyMode) {
        const multiplePoints = [];
        cells
          .sort((a, b) => a.createdDate - b.createdDate)
          .forEach((currentPoint) => {
            // debugger;
            const _startDate = Utils.calcDateWithTimeZone(currentPoint.startTime, utcOffset);
            const _hours = _startDate.hour();
            const _minutes = _startDate.minute();

            const _duration = currentPoint.duration / 1000 / 60;

            const _startTime = _hours * 60 + _minutes;
            const _endTime = _startTime + _duration;
            if (
              _startTime === startTime ||
              _endTime === endTime ||
              (_startTime === startTime && _startTime >= startTime && _endTime >= endTime) ||
              (_startTime === startTime && _startTime <= startTime && _endTime <= endTime) ||
              (_startTime > startTime && _endTime < endTime) ||
              (_startTime < startTime && _endTime > endTime) ||
              (_startTime < startTime &&
                _endTime > startTime &&
                currentPoint.createdDate < point.createdDate) ||
              (_startTime > startTime &&
                _startTime < endTime &&
                currentPoint.createdDate > point.createdDate) ||
              (_startTime < startTime &&
                _endTime > startTime &&
                currentPoint.createdDate > point.createdDate) ||
              (_startTime > startTime &&
                _startTime < endTime &&
                currentPoint.createdDate < point.createdDate)
            ) {
              if (!multiplePoints.find((i) => i.id === currentPoint.id))
                multiplePoints.push(currentPoint);
            }
          });

        let indexOfPoints = 0;
        multiplePoints.forEach((i, index) => {
          if (i.id === point.id) {
            indexOfPoints = index;
          }
        });

        cellWidth = cellWidth / multiplePoints.length;
        cellLeft = cellWidth * indexOfPoints;
      }
      const cell_class =
        height < columnHeight ? "small-cell" : height > columnHeight ? "large-cell" : "normal-cell";

      const checkedTimeClass =
        (endTime - startTime < 30 && "checked-in-time hidden") || "checked-in-time";
      const checkedTitleClass =
        (endTime - startTime < 30 && "checked-in-title hidden") || "checked-in-title";
      const checkedTime =
        (point.checkedInDate && moment(point.checkedInDate).format(TIME_FORMAT)) || "- - : - -";

      const pointStatusColor = point.status && Colors.getStatusColor(point.status.code);
      const pointTypeColor = Colors.getAppointmentColor(point);

      const master = point.masterType && point.masterType.id;
      const enabled = types.length > 0 ? _.includes(types, master) : true;

      const showNote =
        (duration >= 30 && rowView === ViewTypes.comfort) ||
        (duration >= 60 && rowView === ViewTypes.compact);

      const showFullNote = localStorage.getItem("showFullNote") === "true";
      const $calendar_Body = props.getCalendarBody();
      const route = {};
      route.pathname = Routes.DashboardAppointment;
      route.params = { id: 0 };
      route.query = {
        startTime: point.startTime,
        dentist: point.dentist.id,
        scrollLeft: $calendar_Body?.scrollLeft,
        scrollTop: $calendar_Body?.scrollTop,
        dentalPoint: point.dentalPoint.id,
      };

      const createAppointment = () => {
        props.history.replace(
          createUrl(route.pathname, { query: route.query, params: route.params }),
        );
      };

      const pasteAppointment = (point) => {
        if (permissions.indexOf("ADD_CLINIC_APPOINTMENT_ITEM") === -1) {
          return dispatch(
            openSimpleModal({
              body: "permission denied",
            }),
          );
        }

        if (!permissions.includes("MOVE_CLINIC_APPOINTMENT")) {
          dispatch(
            openSimpleModal({
              body: "Permission denied to move appointment",
            }),
          );
          return;
        }

        const dentistRooms = columns.filter((i) => i.dentistId === point.dentist.id);
        let requestCount = 0;
        const doRequest = () => {
          if (requestCount > dentistRooms.length - 1) {
            dispatch(
              openSimpleModal({
                body: `Unable to ${copyMode} not enough rooms`,
              }),
            );
            return;
          }
          const room = dentistRooms[requestCount];
          if (copyMode === "copy") {
            clipBoardAppointment.eligibilityIdPayer = "";
            clipBoardAppointment.eligible = false;
          }
          dispatch(
            saveAppointment(
              {
                ...clipBoardAppointment,
                id: copyMode === "move" ? clipBoardAppointment.id : null,
                dentalPoint: {
                  id: room.dentalPointId,
                },
                dentist: { id: room.dentistId },
                creator: { id: member.id },
                startTime: point.startTime,
                inBoxDragged: false,
              },
              true,
            ),
          ).then((response) => {
            requestCount++;
            if (!response?.id) {
              doRequest();
            } else {
              dispatch(copyToClipboardAppointment(null, null));
              dispatch(getCalendarData(false, clinic.id));
            }
          });
        };
        doRequest();
      };

      if (enabled) {
        return (
          <div
            key={index}
            role="button"
            draggable={true}
            onDragStart={(event) => {
              const dragData = {
                appointment: point,
                columns: columns,
              };
              event.currentTarget.classList.add("hold");
              event.dataTransfer.setData("application/json", JSON.stringify(dragData));

              event.dataTransfer.setData("text/plain", JSON.stringify(point));
              const calendar = document.getElementsByClassName("calendar-body-wrap")[0];
              const rect = calendar.getBoundingClientRect();
              const left = event.clientX - rect.left; //x position within the element.
              const top = event.clientY - rect.top;

              setContextMenuDetails({
                visible: false,
                mode: copyMode,
                innerWidth: rect.width,
                innerHeight: rect.height,
                clientX: event.clientX,
                clientY: event.clientY,
                top: top,
                left: left,
                point,
                parentEvent: null,
                createAppointmentOnPoint: createAppointment,
                paste: () => pasteAppointment(point),
              });
            }}
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDragEnd={(event) => {
              event.preventDefault();
              const calendarForm = document.querySelector(".calendar-form");
              if (calendarForm.classList.contains("dnd-calendar-backdrop")) {
                calendarForm.classList.remove("dnd-calendar-backdrop");
              }
            }}
            onContextMenu={(event) => {
              event.preventDefault();
              event.stopPropagation();
              const calendar = document.getElementsByClassName("calendar-body-wrap")[0];
              const rect = calendar.getBoundingClientRect();
              const left = event.clientX - rect.left;
              const top = event.clientY - rect.top;
              setContextMenuDetails({
                visible: true,
                mode: copyMode,
                innerWidth: rect.width,
                innerHeight: rect.height,
                clientX: event.clientX,
                clientY: event.clientY,
                top: top,
                left: left,
                point,
                parentEvent: null,
                createAppointmentOnPoint: createAppointment,
                paste: () => pasteAppointment(point),
              });
            }}
            onClick={() => {
              props.history.push(
                createUrl(Routes.DashboardAppointment, {
                  params: { id: point.id },
                  query: {
                    scrollLeft: $calendar_Body.scrollLeft,
                    scrollTop: $calendar_Body.scrollTop,
                  },
                }),
              );
            }}
            id="mainCalendarAppointment"
            className={`cell ${cell_class} ${cellWidth < 100 ? "multiple" : ""}`}
            style={{
              top: top,
              height: height,
              width: `${cellWidth}%`,
              left: `${cellLeft}%`,
            }}
          >
            <div style={{ position: "relative", width: "100%", display: "flex" }}>
              <CellInfo details={cellInfoDetails} index={index} />

              <div
                className="left"
                style={{
                  backgroundColor: pointTypeColor?.color?.color || defaultColors.Blue.color,
                }}
              >
                <div className="top">
                  {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                    <div className="name" title={name}>
                      {shortCutName(name)}
                    </div>
                  )}
                  <div
                    className={`number ${
                      rowView === ViewTypes.compact || columnView === ViewTypes.compact
                        ? ""
                        : "comfort"
                    }`}
                    style={{
                      backgroundColor: pointTypeColor?.color?.color || defaultColors.Blue.color,
                      textDecoration: "underline",
                    }}
                  >
                    {point.patient?.chartNumber ? (
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                          setCellInfoDetails({
                            point,
                            name,
                            checkedTime,
                            visible: index,
                          });
                        }}
                        onMouseLeave={() => setCellInfoDetails({ visible: null })}
                      >
                        #{point.patient.chartNumber}
                      </span>
                    ) : (
                      "Temp"
                    )}
                  </div>
                </div>
                {showNote && showFullNote && <div className="center">{point.note}</div>}
                <div className="bottom" style={{ paddingRight: "5px" }}>
                  <div>
                    {((rowView === ViewTypes.compact && duration > 30) ||
                      (rowView === ViewTypes.comfort && duration > 15)) &&
                      point.dentalPoint &&
                      point.dentalPoint.code === "PUBLIC" &&
                      point.dentist &&
                      point.dentist.name}
                  </div>
                  <div className="text-right" style={{ paddingLeft: "5px" }}>
                    {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                      point.meetingType && (
                        <div style={{ paddingBottom: "2px" }}>{point.meetingType.name}</div>
                      )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                        point.category &&
                        point.category.name}
                    </div>
                    {Boolean(rowView === ViewTypes.comfort || duration > 15) &&
                      point.statusOther && <div>{point.statusOther.name}</div>}
                    {Boolean(rowView === ViewTypes.comfort || duration > 15) && point.location && (
                      <div>{point.location.name}</div>
                    )}
                    <Show
                      if={
                        point.note &&
                        Boolean(
                          rowView === ViewTypes.comfort && columnView === ViewTypes.comfort,
                        ) &&
                        (!showNote || !showFullNote)
                      }
                    >
                      <span
                        className="note-modal-link"
                        style={{ display: "block" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          onNoteClick(point.note);
                        }}
                      >
                        Note
                      </span>
                    </Show>
                  </div>
                </div>
              </div>
              <div
                className="right"
                style={{
                  // width: cellInfoDetails.visible === index ? 0 : "120px",
                  backgroundColor:
                    (pointStatusColor && pointStatusColor.color.color) || defaultColors.Green.color,
                }}
              >
                {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                  <div className={checkedTitleClass}>ARRIVED AT:</div>
                )}
                {Boolean(rowView === ViewTypes.comfort && columnView === ViewTypes.comfort) && (
                  <div className={checkedTimeClass}>{checkedTime}</div>
                )}
                <div className="bottom">
                  {todayCellList && todayCellList[point.id]
                    ? "No Answer"
                    : point.status && point.status.name}
                </div>
                {Boolean(rowView === ViewTypes.comfort || duration > 15) && (
                  <div
                    className={`top${
                      (rowView !== ViewTypes.comfort || columnView !== ViewTypes.comfort) &&
                      timeView === TimeViews.name
                        ? " name"
                        : ""
                    }`}
                  >
                    {(rowView !== ViewTypes.comfort || columnView !== ViewTypes.comfort) &&
                    timeView === TimeViews.name
                      ? name
                      : time}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    },
  );

  const $timeSlots =
    timeSlot &&
    timeSlot.map((item1, index) => {
      const startTime1 = item1.startTime / 1000 / 60; // milliseconds to minutes
      const endTime1 = item1.endTime / 1000 / 60;

      const top1 = ((startTime1 - clinicStartTime) / calendarMinuteIncrements) * columnHeight;
      const tmpHeight = ((endTime1 - startTime1) / calendarMinuteIncrements) * columnHeight;

      const height1 = top1 < 0 ? tmpHeight - Math.abs(top1) : tmpHeight;
      const reservedClassName = item1.reserved ? "reserved" : "";
      const oneHour = height1 > 150 && height1 === 160;

      const BTStypeColor =
        item1.type && Colors.getBTStypeColor(_.get(item1.type, "code", item1?.type?.id));

      return (
        <div
          key={index}
          className={`time-slot ${reservedClassName}`}
          style={{
            top: Math.max(oneHour ? top1 - 1 : top1, 0),
            height: height1 - 1,
            backgroundColor: BTStypeColor?.color?.color || defaultColors.Silver.color,
          }}
          // style={{
          //   top: Math.max(oneHour ? top1 - 1 : top1, 0),
          //   height: height1 - 1,
          //   backgroundColor: BTStypeColor?.color?.color || defaultColors.Silver.color,
          // }}
          onContextMenu={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const calendar = document.getElementsByClassName("calendar-body-wrap")[0];
            const rect = calendar.getBoundingClientRect();
            const left = event.clientX - rect.left; //x position within the element.
            const top = event.clientY - rect.top;

            setContextMenuDetails({
              visible: true,
              innerWidth: rect.width,
              innerHeight: rect.height,
              clientX: event.clientX,
              clientY: event.clientY,
              top: top,
              left: left,
              parentEvent: null,
              editBlockedTimeSlot: () =>
                onBTSClick({
                  deleteId: item1.id,
                  deleteDate: day ? getCompanyStartOfDay(day) : getCompanyStartOfDay(props.date),
                }),
            });
          }}
        >
          <u
            className="pull-right time-slot-edit-btn"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(
                createUrl(Routes.DashboardBlockedTimeSlotItem, {
                  query: {
                    id: item1.id,
                  },
                }),
              );
            }}
          >
            Edit
          </u>
          {/*{item1.reserved ? item1.name : item1.reason}*/}
          Name: {item1.name}
          <br />
          Reason: {item1.reason}
        </div>
      );
    });

  const pasteAppointment = (point, copyMode, event, result) => {
    const clipBoardAppointment = point;
    const onDragItem = JSON.parse(event.dataTransfer.getData("application/json"));
    const dentistRooms = columns.filter((i) => i.dentistId === point.dentist.id);
    let requestCount = 0;

    if (!permissions.includes("MOVE_CLINIC_APPOINTMENT")) {
      dispatch(
        openSimpleModal({
          body: "Permission denied to move appointment",
        }),
      );
      return;
    }

    const doRequest = () => {
      if (requestCount > dentistRooms.length - 1) {
        dispatch(
          openSimpleModal({
            body: `Unable to ${copyMode} not enough rooms`,
          }),
        );
        return;
      }
      const room = dentistRooms[requestCount];
      dispatch(
        saveAppointment(
          {
            ...clipBoardAppointment,
            id: copyMode === "move" ? clipBoardAppointment.id : null,
            dentalPoint: {
              id: result?.dentalPoint?.id,
            },
            moved: true,
            dentist: { id: result?.dentist?.id },
            creator: { id: member.id },
            startTime: result?.startTime,
            inBoxDragged: false,
          },
          true,
        ),
      ).then((response) => {
        requestCount++;
        if (!response?.id) {
          doRequest();
        } else {
          dispatch(copyToClipboardAppointment(null, null));
          dispatch(getCalendarData(false, clinic.id));
          if (onDragItem.isFormDndPanel) {
            dispatch(onDragAppointmentsList());
          }
        }
      });
    };
    doRequest();
  };

  return (
    <div
      onDrop={(event) => {
        const copied = JSON.parse(event.dataTransfer.getData("text/plain"));
        const calendarForm = document.querySelector(".calendar-form");
        if (calendarForm.classList.contains("dnd-calendar-backdrop")) {
          calendarForm.classList.remove("dnd-calendar-backdrop");
        }

        event.preventDefault();
        event.stopPropagation();

        const result = pasteAppointmentFunctionTest(event);
        pasteAppointment(copied, "move", event, result);
      }}
      onDragOver={(e) => e.preventDefault()}
      className={`calendar-column ${columnView}`}
      style={{
        backgroundImage: `url(${
          columnView === "comfort" ? ComfortBackgroundImage : CompactBackgroundImage
        })`,
        backgroundSize: `28px ${calendarMinuteIncrements === 30 ? "80px" : "40"}`,
      }}
    >
      {$timeSlots}
      {$cells}
    </div>
  );
};

export default withRouter(enhancer(CalendarColumn));
