import React from "react";

export default function condition(props) {
  const { property } = props;
  const getImageType = (property) => {
    if (property.code === "TOOTHTURN_CLOCKWISE") {
      return "arrow_curly_down_right";
    }
    if (property.code === "TOOTHTURN_COUNTERCLOCKWISE") {
      return "arrow_curly_down_left";
    }
    return "";
  };

  if (!property || property.code === "TOOTHTURN_NONE") return null;
  let src = require(`../../../assets/images/conditions/arrows/${getImageType(property)}@2x.png`)
    .default;

  return (
    <div className="condition-wrap">
      <img src={src} className="zoom-2x turn" alt="" />
    </div>
  );
}
