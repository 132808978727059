import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import Utils from "../../../helpers/Utils";
import bindActions from "../../../helpers/bindActions";
import * as chartSideSeriesActions from "./../../../actions/chartSideSeriesActions";
import seriesTypes from "../../../constants/chart-sidebar/seriesTypes";

const enhancer = connect(null, bindActions({ chartSideSeriesActions }));
export default enhancer(
  class ChartImageSelector extends React.Component {
    onClick = (id) => {
      let { activeFavouriteCode, seriesType, activeFavouriteSeriesType } = this.props;
      const favourites = JSON.parse(localStorage.getItem("favourites"));

      let endSeriesType =
        seriesType === seriesTypes.mixed.code ? activeFavouriteSeriesType : seriesType;

      if (!favourites[endSeriesType][activeFavouriteCode]) {
        favourites[endSeriesType][activeFavouriteCode] = {};
      }

      favourites[endSeriesType][activeFavouriteCode].imageId = id;
      localStorage.setItem("favourites", JSON.stringify(favourites));

      this.props.chartSideSeriesActions.hideImageSelector();
    };

    render() {
      const { favouritesIcons, imageSelectorVisible } = this.props;

      const $favouritesIcons = Utils.objectToArray(favouritesIcons, (icon) => {
        return (
          <button
            key={icon.id}
            type="button"
            onClick={() => {
              this.onClick(icon.id);
            }}
            className="list-group-item list-group-item-grid"
          >
            <div className="favourite-icon">
              <img alt="" src={icon.publicUrl} />
            </div>
          </button>
        );
      });

      return (
        <Modal
          show={imageSelectorVisible}
          title={<div>Select Icon</div>}
          onHide={this.props.chartSideSeriesActions.hideImageSelector}
          keyboard={false}
          actions={
            <Button type="button" onClick={this.props.chartSideSeriesActions.hideImageSelector}>
              Close
            </Button>
          }
        >
          <div className="list-group list-group-grid">{$favouritesIcons}</div>
        </Modal>
      );
    }
  },
);
