import _ from "lodash";
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";

import { connect, useDispatch } from "react-redux";
import bindActions from "../../helpers/bindActions";
import * as remindersActions from "../../actions/remindersActions";
import DotImage from "../../assets/images/dot.png";
import { SelectPicker } from "../select-picker/SelectPicker";
import Show from "../widgets/Show";
import { getPatientLanguages } from "../../actions/patientRegisterActions";

const enhancer = connect(
  ({ patientRegister: { languages } }) => ({ languages }),
  bindActions({ remindersActions }),
);
const form = reduxForm({
  form: "remindersManual",
});
const RemindersManualModeForm = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPatientLanguages());
    return () => props.remindersActions.setNotificationType("SMS_NOTIFICATION");
  }, []);
  const {
    templates,
    languages,
    handleSubmit,
    toggleDentistSelected,
    togglePatientSelected,
    recipientType,
    templateLabel,
    notificationType,
    lastMode,
    patientFromAppointment,
    appointmentId,
  } = props;
  let templatesOptions;
  let languageOptions;
  let employeeMode;
  let selectedDentistCaption;
  let selectedPatientCaption;
  let dentistLabelClass = "select-recipient-type";
  let patientLabelClass = "select-recipient-type";
  let smsTypeLabelClass = "select-recipient-type";
  let emailTypeLabelClass = "select-recipient-type";
  let patientAppTypeLabelClass = "select-recipient-type";

  if (notificationType && notificationType === "SMS_NOTIFICATION") {
    smsTypeLabelClass += " selected-item";
  }
  if (notificationType && notificationType === "EMAIL_NOTIFICATION") {
    emailTypeLabelClass += " selected-item";
  }
  if (notificationType && notificationType === "PATIENT_APP") {
    patientAppTypeLabelClass += " selected-item";
  }
  if (recipientType && recipientType === "EMPLOYEE") {
    dentistLabelClass += " selected-item";
    employeeMode = true;
  }

  if (recipientType && recipientType === "PATIENT") {
    patientLabelClass += " selected-item";
    employeeMode = false;
  }

  if (templates) {
    templatesOptions = templates.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  if (languages) {
    languageOptions = languages.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  useEffect(() => {
    if (languages.length) {
      props.change("language", languages.find((x) => x.code === "en").id);
    }
  }, [languages]);

  if (!_.isEmpty(toggleDentistSelected)) {
    if (_.size(toggleDentistSelected) > 1) {
      selectedDentistCaption = `${_.size(toggleDentistSelected)} dentists`;
    } else {
      const selectedId = _.keys(toggleDentistSelected)[0];
      selectedDentistCaption = toggleDentistSelected[selectedId].name;
    }
  } else {
    selectedDentistCaption = `Select ${localStorage.getItem("doctorLabel")}`;
  }

  if (!_.isEmpty(togglePatientSelected)) {
    if (_.size(togglePatientSelected) > 1) {
      if (lastMode === "all") {
        selectedPatientCaption = `${_.size(togglePatientSelected)} Patients`;
      } else {
        selectedPatientCaption = `${_.size(togglePatientSelected)} Appointments`;
      }
    } else {
      const selectedId = _.keys(togglePatientSelected)[0];

      selectedPatientCaption = `${togglePatientSelected[selectedId]?.firstName} ${togglePatientSelected[selectedId]?.lastName}`;
    }
  } else {
    selectedPatientCaption = "Select Patient";
  }
  return (
    <form
      onSubmit={handleSubmit(props.remindersActions.saveManual)}
      className="form-horizontal"
      role="form"
    >
      <div className="row">
        <div className="col-sm-12">
          <div className={`row mb-4`}>
            <div className="btn-group col-sm-12 d-flex">
              <button
                onClick={() => props.remindersActions.setNotificationType("SMS_NOTIFICATION")}
                type="button"
                style={{ width: "25%" }}
                className={`btn ${
                  notificationType && notificationType === "SMS_NOTIFICATION"
                    ? "btn-success"
                    : "btn-default"
                }`}
              >
                Send sms
              </button>
              <button
                onClick={() => props.remindersActions.setNotificationType("EMAIL_NOTIFICATION")}
                type="button"
                style={{ width: "25%" }}
                className={`btn ${
                  notificationType && notificationType === "EMAIL_NOTIFICATION"
                    ? "btn-success"
                    : "btn-default"
                }`}
              >
                Send email
              </button>
              <button
                onClick={() => props.remindersActions.setNotificationType("PATIENT_APP")}
                type="button"
                style={{ width: "25%" }}
                className={`btn ${
                  notificationType && notificationType === "PATIENT_APP"
                    ? "btn-success"
                    : "btn-default"
                }`}
              >
                Patient app notification
              </button>
              <button
                onClick={() => props.remindersActions.setNotificationType("WHATS_APP_NOTIFICATION")}
                type="button"
                style={{ width: "25%" }}
                className={`btn ${
                  notificationType && notificationType === "WHATS_APP_NOTIFICATION"
                    ? "btn-success"
                    : "btn-default"
                }`}
              >
                WhatsApp notification
              </button>
            </div>
          </div>
        </div>
        <div className={patientFromAppointment ? "col-sm-12" : "col-sm-6"}>
          <div className="form-group">
            <label
              htmlFor="templates"
              className={patientFromAppointment ? "col-sm-12" : "col-sm-4 control-label"}
            >
              {templateLabel}
            </label>
            <div className={patientFromAppointment ? "col-sm-12" : "col-sm-8"}>
              <Field
                name="templates"
                id="templates"
                component={({ input }) => (
                  <SelectPicker
                    isSearchable={true}
                    options={templatesOptions}
                    value={{ value: input.value }}
                    onChange={(x) => input.onChange(x.value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <Show if={patientFromAppointment}>
          <div className="col-sm-12 mb-3">
            <button
              type="button"
              className="btn btn-default btn-select-dentist"
              style={{ width: "100%" }}
              disabled={true}
            >
              {selectedPatientCaption}
            </button>
          </div>
        </Show>
        <div className={patientFromAppointment ? "col-sm-12" : "col-sm-6"}>
          <div className="form-group">
            <label
              htmlFor="language"
              className={patientFromAppointment ? "col-sm-12" : "col-sm-4 control-label"}
            >
              Choose language
            </label>
            <div className={patientFromAppointment ? "col-sm-12" : "col-sm-8"}>
              <Field
                name="language"
                id="language"
                component={({ input }) => (
                  <SelectPicker
                    isSearchable={true}
                    options={languageOptions}
                    value={{ value: input.value }}
                    onChange={(x) => input.onChange(x.value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/*<Show*/}
        {/*  if={notificationType === "SMS_NOTIFICATION" || notificationType === "EMAIL_NOTIFICATION"}*/}
        {/*>*/}
        {/*  <div className="col-sm-6">*/}
        {/*    <div className="form-group">*/}
        {/*      <label*/}
        {/*        className="col-sm-4 control-label selecting"*/}
        {/*        onClick={() => {*/}
        {/*          props.remindersActions.setRecipientType("employee");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div className={dentistLabelClass}>*/}
        {/*          {recipientType && recipientType === "EMPLOYEE" && (*/}
        {/*            <img src={DotImage} className="img-responsive" alt="" />*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*        Send to doctors*/}
        {/*      </label>*/}
        {/*      <div className="col-sm-8">*/}
        {/*        <button*/}
        {/*          type="button"*/}
        {/*          className="btn btn-default btn-select-dentist"*/}
        {/*          onClick={*/}
        {/*            !employeeMode*/}
        {/*              ? () => {*/}
        {/*                  props.remindersActions.setRecipientType("employee");*/}
        {/*                }*/}
        {/*              : props.remindersActions.toggleDentistShow*/}
        {/*          }*/}
        {/*        >*/}
        {/*          {selectedDentistCaption}*/}
        {/*        </button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Show>*/}
        <Show if={!patientFromAppointment}>
          <div className="col-sm-6">
            <div className="form-group">
              <label
                className="col-sm-4 control-label selecting"
                onClick={() => {
                  props.remindersActions.setRecipientType("patient");
                }}
              >
                <div className={patientLabelClass}>
                  {recipientType && recipientType === "PATIENT" && (
                    <img src={DotImage} className="img-responsive" alt="" />
                  )}
                </div>
                Send to patients
              </label>
              <div className="col-sm-8">
                <button
                  type="button"
                  className="btn btn-default btn-select-dentist"
                  onClick={
                    employeeMode
                      ? () => {
                          props.remindersActions.setRecipientType("patient");
                        }
                      : props.remindersActions.togglePatientShow
                  }
                >
                  {selectedPatientCaption}
                </button>
              </div>
            </div>
          </div>
        </Show>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div
            style={patientFromAppointment ? { textAlign: "center" } : {}}
            className="form-group form-group-submit-rule"
          >
            <button
              type="button"
              onClick={() => props.remindersActions.saveManual(appointmentId)}
              className="btn btn-submit-rule btn-primary mr-1"
            >
              Send Now
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default enhancer(form(RemindersManualModeForm));
