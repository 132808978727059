import React, { useRef } from "react";

import { useOutsideClick } from "../../hooks/useOutsideClick";
import Image from "../../assets/images/top-menu/bottomMenuBarIcon.png";

export function BottomMenu({ children, showMenu, onClose, onOpen }) {
  const ref = useRef();

  useOutsideClick({
    ref,
    active: showMenu,
    onClick: () => onClose(),
  });

  return (
    <div
      ref={ref}
      className="page-bottom-menu"
      style={{
        position: "absolute",
        bottom: showMenu ? 0 : "-40px",
        left: 0,
        right: 0,
        zIndex: 10000,
        height: "40px",
        transition: "bottom 270ms ease-in-out",
      }}
    >
      <div className="d-flex flex-column" style={{ position: "relative" }}>
        <div
          className="menu-opener"
          style={{ bottom: "unset", top: "-25px" }}
          onClick={() => {
            if (showMenu) {
              onClose();
            } else {
              onOpen();
            }
          }}
        >
          <img alt="bottom menu" src={Image} />
        </div>
        <div className="black-menu clearfix flex-grow-1 flex-shrink-1">{children}</div>
      </div>
    </div>
  );
}
