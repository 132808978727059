import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import BackButton from "../../../widgets/BackButton";
import Show from "../../../widgets/Show";

import ChartFillInFormQuestion from "./ChartFillInFormQuestion";

import { getNote } from "../../../../helpers/clinicNote";

import { hideSideBar } from "../../../../actions/chartActions";

import bindActions from "../../../../helpers/bindActions";
import * as chartSideFillInFormActions from "../../../../actions/chartSideFillInFormActions";
import ChartClinicalNoteCreatedDate from "../clinical-note/ChartClinicalNoteCreatedDate";
import { getInsuranceList } from "../../../../actions/insuranceActions";
import { createUrl, parseQuery } from "../../../../utils/UrlUtils";
import questionTypeCode from "../../../../constants/chart-sidebar/questionTypeCodes";

const enhancer = connect(
  ({ insurance }) => ({ images: insurance.images }),
  bindActions({ chartSideFillInFormActions, hideSideBar, getInsuranceList }),
);

class ChartFillInFormItemEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultAnswersButton: false,
    };
  }
  componentDidMount() {
    this.props.chartSideFillInFormActions.initEdit();

    const query = parseQuery(this.props.location.search);

    const patientKey = _.get(query, "patientKey");

    if (patientKey) {
      this.props.getInsuranceList({ patientKey });
    }

    if (!this.props.edit) {
      this.props.chartSideFillInFormActions.getEmpty();
    }
  }

  isFrequency = () => {
    const query = parseQuery(this.props.location.search);

    return Boolean(_.get(query, "isFrequency"));
  };

  resetFrequency = () => {
    const { history, location } = this.props;

    const query = parseQuery(this.props.location.search);

    history.replace(
      createUrl(location.pathname, {
        query: {
          ...query,
          isFrequency: undefined,
        },
      }),
    );
  };

  save = () => {
    this.resetFrequency();

    this.props.chartSideFillInFormActions.saveItem(
      this.name && this.name.value,
      this.props.patientKey,
    );
  };

  clickNote = (event) => {
    if (event.target.classList.contains("fill-in-form-answer")) {
      this.props.chartSideFillInFormActions.selectQuestion(event.target.dataset.index);
    }
  };

  render() {
    const {
      chartSideFillInForm: { item, currentQuestionIndex },
      edit,
      clinic,
      images,
    } = this.props;

    let firstQuestion = false;
    let lastQuestion = false;
    let noQuestions = false;

    const count =
      (item.templateQuestionsAndAnswers && item.templateQuestionsAndAnswers.length) || 0;

    if (currentQuestionIndex + 1 === count) {
      lastQuestion = true;
    }

    if (currentQuestionIndex === 0) {
      firstQuestion = true;
    }

    if (count === 0) {
      noQuestions = true;
    }

    const btnWrapStyle = {
      width: firstQuestion || noQuestions ? "100%" : "50%",
    };

    const note = getNote(item, {}, true, clinic, images);

    const currentQuestion =
      (item &&
        item.templateQuestionsAndAnswers &&
        item.templateQuestionsAndAnswers[currentQuestionIndex]) ||
      {};

    const questionType = _.get(currentQuestion, "questionType.code");

    if (!this.state.defaultAnswersButton && questionType === questionTypeCode.TEXT_AREA) {
      this.setState({ defaultAnswersButton: true });
    }

    if (this.state.defaultAnswersButton && questionType !== questionTypeCode.TEXT_AREA) {
      this.setState({ defaultAnswersButton: false });
    }
    return (
      <div className="chart-clinical-note">
        <div className="clinical-note-item">
          <div className="clinical-note-item-view-title">
            <BackButton
              className="btn-sm"
              text="Back"
              color="#bec844"
              onClick={() => {
                if (this.isFrequency()) {
                  this.resetFrequency();

                  this.props.chartSideFillInFormActions.clearStack();
                  this.props.hideSideBar();
                } else {
                  this.props.chartSideFillInFormActions.popStack();
                }
              }}
            />
            <div className="chart-number">#{item.patient && item.patient.chartNumber}</div>
            <div className="name">
              <Show if={!edit && item.template && item.template.name}>
                <input
                  type="text"
                  className="form-control"
                  ref={(node) => {
                    this.name = node;
                  }}
                  defaultValue={item.template && item.template.name}
                />
              </Show>
              {edit && item.template && item.template.name}
            </div>
          </div>
          <div className="clinical-note-item-edit-body">
            <div
              className="template"
              onClick={this.clickNote}
              dangerouslySetInnerHTML={{ __html: note }}
            />
            <div className="questions">
              <div
                className="question d-flex align-items-center justify-content-between"
                style={{ height: "6%" }}
              >
                <span>{currentQuestion.question}</span>
                <Show if={this.state.defaultAnswersButton}>
                  <div>
                    <button
                      onClick={this.props.chartSideFillInFormActions.setSelectTreatmentModalVisible}
                      className="btn btn-primary mr-2"
                    >
                      Treatment
                    </button>
                    <button
                      onClick={this.props.chartSideFillInFormActions.setSelectDiagnosisModalVisible}
                      className="btn btn-primary mr-2"
                    >
                      Diagnosis
                    </button>
                  </div>
                </Show>
              </div>
              <div className="body">
                <ChartFillInFormQuestion
                  questions={item.templateQuestionsAndAnswers}
                  currentQuestionIndex={currentQuestionIndex}
                  question={currentQuestion}
                />
              </div>
              <div className="btns clearfix" style={{ display: "flex" }}>
                <Show if={!noQuestions}>
                  <div className="btn-wrap" style={btnWrapStyle}>
                    <button onClick={this.save} className="btn btn-primary btn-lg btn-block">
                      Save
                    </button>
                  </div>
                </Show>
                <Show if={!firstQuestion && !noQuestions}>
                  <div className="btn-wrap" style={btnWrapStyle}>
                    <button
                      onClick={this.props.chartSideFillInFormActions.prevQuestion}
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Previous
                    </button>
                  </div>
                </Show>
                <Show if={!lastQuestion && !noQuestions}>
                  <div className="btn-wrap" style={btnWrapStyle}>
                    <button
                      onClick={this.props.chartSideFillInFormActions.nextQuestion}
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Next
                    </button>
                  </div>
                </Show>
                <Show if={lastQuestion || noQuestions}>
                  <div className="btn-wrap" style={btnWrapStyle}>
                    <button onClick={this.save} className="btn btn-primary btn-lg btn-block">
                      Done
                    </button>
                  </div>
                </Show>
              </div>
            </div>
            <Show if={edit}>
              <ChartClinicalNoteCreatedDate
                changeCreatedDate={this.props.chartSideFillInFormActions.changeCreatedDate}
                item={item}
              />
            </Show>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(ChartFillInFormItemEdit);
