import _ from "lodash";
import PostOpApi from "../api-legacy/PostOpApi";

import * as ActionType from "../actionTypes/chartSidePostOpActionTypes";
import { startLoader, stopLoader, showMessage } from "./loaderActions";
import { hideSideBar } from "./chartActions";
import { postOpStackCodes } from "../constants/chart-sidebar/postOpStackCodes";
import { openSimpleModal } from "./simpleModalActions";

export function initSidePostOp() {
  return {
    type: ActionType.INIT_SIDE_POST_OP,
  };
}

export function selectSortField(code) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SELECT_SORT_FIELD_SIDE_POST_OP,
      code,
    });

    dispatch(getOwnedList());
  };
}

export function setSearchValue(value) {
  return {
    type: ActionType.CHART_POST_OP_SEARCH_CHANGE,
    value,
  };
}

export function pushStack(stackItem) {
  return {
    type: ActionType.PUSH_POST_OP_STACK,
    stackItem,
  };
}

export function popStack() {
  return (dispatch, getState) => {
    const { stack } = getState().chartSidePostOp;

    if (stack.length === 1) {
      dispatch(hideSideBar());
    } else {
      dispatch({
        type: ActionType.POP_POST_OP_STACK,
      });
    }
  };
}

export function openList() {
  return pushStack({
    code: postOpStackCodes.list,
  });
}

export function openItem(item, type) {
  return pushStack({
    code: postOpStackCodes.item,
    item,
    type,
  });
}

export function getOwnedList(query) {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSidePostOp: { sortField },
    } = getState();

    const data = {
      patientKey: (query && query.patientKey) || patient.patientKey,
      sortField,
      limit: 25,
    };

    dispatch(getPostOpOwnedList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadOwnedList() {
  return (dispatch, getState) => {
    const {
      chart: { patient },
      chartSidePostOp: { sortField, ownedList, ownedListTotalCount },
    } = getState();

    if (ownedListTotalCount > ownedList.length) {
      const data = {
        patientKey: patient.patientKey,
        sortField,
        limit: 25,
        start: ownedList.length,
      };

      dispatch(getPostOpOwnedList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function getTemplateList() {
  return (dispatch, getState) => {
    const {
      chartSidePostOp: { searchValue },
    } = getState();

    const data = {
      filterKey: searchValue,
      limit: 25,
    };

    dispatch(getPostOpTemplateList(data)).catch((error) => dispatch(showMessage(error)));
  };
}

export function loadTemplateList() {
  return (dispatch, getState) => {
    const {
      chartSidePostOp: { searchValue, templateList, templateListTotalCount },
    } = getState();

    if (templateListTotalCount > templateList.length) {
      const data = {
        filterKey: searchValue,
        limit: 25,
        start: templateList.length,
      };

      dispatch(getPostOpTemplateList(data)).catch((error) => dispatch(showMessage(error)));
    }
  };
}

export function getItem() {
  return (dispatch, getState) => {
    const {
      chartSidePostOp: { stack },
    } = getState();

    const stackItem = _.last(stack);
    const id = stackItem.item.id;

    const data = {
      id,
    };
    dispatch(startLoader());

    dispatch(getPostOpItem(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function getTemplate() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chartSidePostOp: { stack },
      chart: {
        patient: { patientKey },
      },
    } = state;

    const stackItem = _.last(stack);
    const id = stackItem.item.id;

    const data = {
      patientKey,
      id,
    };

    dispatch(startLoader());

    dispatch(getPostOpTemplate(data))
      .then(() => dispatch(stopLoader()))
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function saveItem() {
  return (dispatch, getState) => {
    const {
      chart: {
        patient: { patientKey },
        appointment: { id: appointmentId },
      },
      chartSidePostOp: {
        item: { id },
      },
      form: {
        chartPostOperation: { values },
      },
    } = getState();

    const transfer = {
      appointmentId,
      type: {
        code: "BEFORE",
      },
      name: values.title,
      patientKey,
      content: values.content,
      template: {
        id,
      },
    };

    dispatch(startLoader());

    return dispatch(savePostOpItem(transfer))
      .then(() => dispatch(getOwnedList({ patientKey })))
      .then((response) => {
        dispatch(stopLoader());
        dispatch(popStack());
        return response;
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function sendToEmail(newId) {
  return (dispatch, getState) => {
    const {
      chartSidePostOp: { item },
    } = getState();

    const transfer = {
      postOperationId: newId || item.id,
    };

    dispatch(startLoader());

    dispatch(sendToEmailApi(transfer))
      .then(() => {
        dispatch(stopLoader());
        dispatch(
          openSimpleModal({
            body: "Email has been sent",
          }),
        );
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function emailToPatient() {
  return (dispatch) => {
    dispatch(startLoader());

    dispatch(saveItem())
      .then((response) => {
        dispatch(sendToEmail(response.id));
        dispatch(stopLoader());
        dispatch(popStack());
        dispatch(
          openSimpleModal({
            body: "Email has been sent",
          }),
        );
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

export function print() {
  return (dispatch, getState) => {
    const {
      form: {
        chartPostOperation: {
          values: { content },
        },
      },
    } = getState();

    const mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(`<html><head><title>${document.title}</title>`);
    mywindow.document.write("</head><body >");
    mywindow.document.write(content);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  };
}

export function deletePostOp(id) {
  return (dispatch) => {
    dispatch(startLoader());

    return dispatch(deletePostItemApi({ id }))
      .then(() => {
        dispatch(stopLoader());
        dispatch(getOwnedList());
      })
      .catch((error) => dispatch(stopLoader(error)));
  };
}

function getPostOpOwnedList(data) {
  return {
    api: PostOpApi.getOwnedList,
    types: [
      ActionType.GET_POST_OP_OWNED_LIST_START,
      ActionType.GET_POST_OP_OWNED_LIST_SUCCESS,
      ActionType.GET_POST_OP_OWNED_LIST_ERROR,
    ],
    data,
  };
}

function getPostOpTemplateList(data) {
  return {
    api: PostOpApi.template.getList,
    types: [
      ActionType.GET_POST_OP_TEMPLATE_LIST_START,
      ActionType.GET_POST_OP_TEMPLATE_LIST_SUCCESS,
      ActionType.GET_POST_OP_TEMPLATE_LIST_ERROR,
    ],
    data,
  };
}

function getPostOpItem(data) {
  return {
    api: PostOpApi.getItem,
    types: [
      ActionType.GET_POST_OP_ITEM_START,
      ActionType.GET_POST_OP_ITEM_SUCCESS,
      ActionType.GET_POST_OP_ITEM_ERROR,
    ],
    data,
  };
}

function savePostOpItem(data) {
  return {
    api: PostOpApi.saveItem,
    types: [
      ActionType.SAVE_POST_OP_ITEM_START,
      ActionType.SAVE_POST_OP_ITEM_SUCCESS,
      ActionType.SAVE_POST_OP_ITEM_ERROR,
    ],
    data,
  };
}

function getPostOpTemplate(data) {
  return {
    api: PostOpApi.template.getItem,
    types: [
      ActionType.GET_POST_OP_EMPTY_START,
      ActionType.GET_POST_OP_EMPTY_SUCCESS,
      ActionType.GET_POST_OP_EMPTY_ERROR,
    ],
    data,
  };
}

function sendToEmailApi(data) {
  return {
    api: PostOpApi.sendToEmail,
    types: [
      ActionType.POST_OP_SEND_TO_EMAIL_START,
      ActionType.POST_OP_SEND_TO_EMAIL_SUCCESS,
      ActionType.POST_OP_SEND_TO_EMAIL_ERROR,
    ],
    data,
  };
}

function deletePostItemApi(data) {
  return {
    api: PostOpApi.deleteItem,
    types: [
      ActionType.DELETE_POST_OP_ITEM_START,
      ActionType.DELETE_POST_OP_ITEM_SUCCESS,
      ActionType.DELETE_POST_OP_ITEM_ERROR,
    ],
    data,
  };
}
