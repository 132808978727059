import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, FormControl } from "react-bootstrap";
import { Modal } from "../../ui/Modal";

import Show from "../../widgets/Show";
import { BottomMenu } from "../../widgets/BottomMenu";
import Element from "../../../models/Element";
import { PopupType } from "../../../constants/Constants";
import TreatmentCompletePopup from "./TreatmentCompletePopup";
import { appointmentSendPush } from "../../../actions/remindersActions";

import viewTypes from "../../../constants/chart-sidebar/viewTypes";

import { setPopup, setPopupActionByKey, setPopupParams } from "../../../actions/popupActions";

import {
  completeTransaction,
  getAppointmentTransactions,
  getHoldPreApproval,
  selectAllCheckboxCompletePopup,
} from "../../../actions/transactionActions";

import {
  repeatAppointmentModalShow,
  selectTreatmentToCompleteModalShow,
  setPostPlan,
  showSideBar,
  switchFolderModal,
  uploadDocs,
  setAdvancedPaymentModalVisible,
} from "../../../actions/chartActions";

import { showOrderModal } from "../../../actions/orderActions";

import { closeSimpleModal, openSimpleModal } from "../../../actions/simpleModalActions";
import { initFolders } from "../../../actions/folderActions";
import { createUrl, parseQuery } from "../../../utils/UrlUtils";
import { Routes } from "../../../constants/Routes";
import { withRouter } from "react-router";
import postOnlyImage from "../../../assets/images/calendar-bottom-menu/finish_visit.png";
import postAndFinishImage from "../../../assets/images/calendar-bottom-menu/finish_visit.png";
import finishVisitImage from "../../../assets/images/calendar-bottom-menu/finish_visit.png";
import closeImage from "../../../assets/images/calendar-bottom-menu/finish_visit.png";
import NextVisitIcon from "../../../assets/images/next-visit-icon.png";
import PaymentIcon from "../../../assets/images/payment.png";
import { BottomMenuContent } from "./BottomMenuContent";
import _, { toFinite } from "lodash";
import { STATUS_TYPES } from "../../../constants/StatusTypes";
import { setReferralModalVisible, setVisitType } from "../../../actions/referralActions";
import { obtainImagePath } from "../../../utils/ImageUtils";

const enhancer = connect(
  ({
    session: {
      toothNumericType,
      member,
      patientAppActivated,
      miscSettings: {
        allowAnyoneToFinishVisit,
        postTreatmentWithoutCompleteAppointment,
        nextVisitScreenPopupEnabled,
      },
    },
    chart,
    chartSideSeries,
    patient,
    popup: { large },
  }) => {
    const appointmentStatus = _.get(chart, "appointment.status.code", false);

    const isPostStatus =
      appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_COMPLETE ||
      appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_BEING_SEEN;
    // appointmentStatus === STATUS_TYPES.APPOINTMENT_STATUS_PARTIALLY_PAYED;
    const showPostAndFinish = isPostStatus || allowAnyoneToFinishVisit;
    const showPostOnly =
      chart.appointment && chart.appointment.id && postTreatmentWithoutCompleteAppointment;

    return {
      toothNumericType,
      member,
      chart,
      chartSideSeries,
      patient,
      large,
      patientAppActivated,
      allowAnyoneToFinishVisit,
      showPostAndFinish,
      showPostOnly,
      nextVisitScreenPopupEnabled,
      isPostStatus,
    };
  },
  {
    setPostPlan,
    setPopup,
    setPopupParams,
    appointmentSendPush,
    setPopupActionByKey,
    getHoldPreApproval,
    getAppointmentTransactions,
    completeTransaction,
    selectAllCheckboxCompletePopup,
    showSideBar,
    uploadDocs,
    openSimpleModal,
    closeSimpleModal,
    selectTreatmentToCompleteModalShow,
    repeatAppointmentModalShow,
    switchFolderModal,
    initFolders,
    showOrderModal,
    setVisitType,
    setReferralModalVisible,
    setAdvancedPaymentModalVisible,
  },
);

export default withRouter(
  enhancer(
    class ChartBottomMenu extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          showMenu: false,
          visibleModal: false,
          clickedFinishVisit: false,
          docsImage: [],
          docsImageModal: false,
        };
      }

      hideBar = () => {
        this.setState({ showMenu: false });
      };

      componentDidMount() {
        this.hideBar();
      }

      openTransactionCompletePopupForFinishVisit = () => {
        const { setPopupParams } = this.props;
        const completeBtn = new Element("Complete");
        completeBtn.handler = this.completeHandle;
        this.setState({
          visibleModal: true,
          clickedFinishVisit: true,
          isDentist: this.props?.chart.appointment,
        });

        setPopupParams(PopupType.Large, "", true, "Select treatment to complete", [completeBtn]);
      };
      // const dentistId = chart.appointment && chart.appointment.dentist.id;

      openTransactionCompletePopupForPostTrx = () => {
        const { setPopupParams } = this.props;
        const completeBtn = new Element("Complete");
        completeBtn.handler = this.completeHandle;
        this.setState({
          visibleModal: true,
          clickedFinishVisit: false,
        });

        setPopupParams(PopupType.Large, "", true, "Select treatment to complete", [completeBtn]);
      };

      completeHandle = () => {
        const {
          history,
          completeTransaction,
          selectAllCheckboxCompletePopup,
          getAppointmentTransactions,
        } = this.props;

        const submit = (textarea) => {
          this.props.setPopupActionByKey(PopupType.Large, "visible", false);

          completeTransaction(textarea).then(() => {
            selectAllCheckboxCompletePopup(true);
            if (this.state.clickedFinishVisit) {
              history.push(Routes.DashboardCalendar);
            } else {
              getAppointmentTransactions(true);
            }
            this.setState({ visibleModal: false });
          });

          this.props.closeSimpleModal();
        };

        const dentistToCashierNoteForm = reduxForm({
          form: "dentistToCashierNote",
          onSubmit: (values) => {
            submit(values.textarea);
          },
        })((props) => {
          return (
            <form onSubmit={props.handleSubmit(props.onSubmit)}>
              <Field component="textarea" name="textarea" className="form-control" rows="4" />
              <hr />
              <div className="btn-group btn-group-justified">
                <a onClick={props.handleSubmit(props.onSubmit)} className="btn btn-default">
                  Skip
                </a>
                <a onClick={props.handleSubmit(props.onSubmit)} className="btn btn-primary">
                  Ok
                </a>
              </div>
            </form>
          );
        });

        this.props.openSimpleModal({
          header: "Note to Cashier",
          body: React.createElement(dentistToCashierNoteForm),
          defaultFooterInvisible: true,
        });
      };

      close = () => {
        const { history } = this.props;

        history.push(Routes.DashboardCalendar);
      };

      render() {
        const {
          showPostOnly,
          chart,
          member,
          history,
          location,
          onDocsClick,
          nextVisitScreenPopupEnabled,
          isPostStatus,
        } = this.props;
        const ownAppointment = member?.id === chart?.appointment?.dentist?.id;
        const { visibleModal, showMenu } = this.state;
        const query = parseQuery(location.search);

        const showClose = toFinite(query.appointmentId) === 0;

        const DocsImageUploadModal = reduxForm({
          form: "chartDocsUploadImage",
        })(({ state }) => {
          return (
            <Modal
              size="small"
              show={state}
              keyboard={false}
              title={<div>Image name</div>}
              actions={
                <div>
                  <Button
                    bsStyle="primary"
                    onClick={() => this.setState({ docsImageModal: false })}
                  >
                    Close
                  </Button>
                  <Button
                    bsStyle="primary"
                    onClick={() => {
                      this.props.uploadDocs(this.state.docsImage);
                      this.setState({ docsImageModal: false });
                    }}
                  >
                    Save
                  </Button>
                </div>
              }
            >
              <form>
                <Field
                  component={({ input }) => <FormControl placeholder="Name" {...input} />}
                  name="description"
                />
              </form>
            </Modal>
          );
        });

        return (
          <>
            <BottomMenu
              showMenu={showMenu}
              onClose={() => this.setState({ showMenu: false })}
              onOpen={() => this.setState({ showMenu: true })}
            >
              <BottomMenuContent
                onMedicationClick={() => {
                  this.hideBar();
                  history.push(
                    createUrl(Routes.DashboardMedicationList, {
                      query: {
                        patientKey: chart.patient.patientKey,
                      },
                    }),
                  );
                }}
                onPrescriptionsClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.prescription);
                }}
                onTreatmentEstimateClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.treatmentsPlan);
                }}
                onRequestFollowupClick={() => {
                  this.hideBar();
                  this.props.repeatAppointmentModalShow();
                }}
                onOrderClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.order);
                }}
                onPerioExamClick={() => {
                  this.hideBar();
                  history.push(
                    createUrl(Routes.DashboardPerioExam, {
                      query: {
                        patientKey: _.get(chart, "patient.patientKey"),
                      },
                    }),
                  );
                }}
                onDocsClick={() => {
                  this.hideBar();
                  onDocsClick();
                }}
                onConsentFormClick={() => {
                  this.hideBar();
                  history.push(
                    createUrl(Routes.PatientFormsList, {
                      query: {
                        patientKey: chart.patient.patientKey,
                      },
                    }),
                  );
                }}
                onPostOpinstructionsClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.postOp);
                }}
                onVitalClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.vital);
                }}
                onFillInFormsClick={() => {
                  this.hideBar();
                  this.props.showSideBar(viewTypes.fillInForm);
                }}
                onClaimsApprovalsClick={(e) => {
                  e.preventDefault();
                  this.hideBar();
                  history.push(
                    createUrl(Routes.PatientClaimsApprovals, {
                      query: {
                        patientKey: chart.patient.patientKey,
                      },
                    }),
                  );
                }}
              />
              <div className="d-flex">
                <Show if={chart.patient.patientKey}>
                  <div
                    className="item post-trx"
                    onClick={() => {
                      this.hideBar();
                      this.props.setAdvancedPaymentModalVisible(true);
                    }}
                  >
                    <img className="icon" alt="Next Visit" src={obtainImagePath(PaymentIcon)} />
                    <div className="text">Request for adv.payment</div>
                  </div>
                </Show>
                <div
                  className="item post-trx"
                  onClick={() => {
                    this.hideBar();
                    this.props.setReferralModalVisible(true);
                    this.props.setVisitType(null);
                  }}
                >
                  <img className="icon" alt="Next Visit" src={NextVisitIcon} />
                  <div className="text">Next Visit</div>
                </div>
                <div style={{ padding: "30px" }} />
                <Show if={showPostOnly}>
                  <div
                    className="item post-trx"
                    onClick={() => {
                      this.hideBar();
                      this.props.setPostPlan(true);
                      this.props.selectTreatmentToCompleteModalShow("post");
                    }}
                  >
                    <img className="icon" alt="Post only" src={postOnlyImage} />
                    <div className="text">Post Only</div>
                  </div>
                </Show>
                <Show if={this.props.allowAnyoneToFinishVisit || (ownAppointment && isPostStatus)}>
                  <div
                    className="item post-trx"
                    onClick={() => {
                      this.hideBar();
                      if (nextVisitScreenPopupEnabled) {
                        this.props.setReferralModalVisible(true);
                        this.props.setVisitType("post");
                      } else {
                        this.props.setPostPlan(false);
                        this.props.selectTreatmentToCompleteModalShow("post");
                      }
                    }}
                  >
                    <img className="icon" alt="Post & Finish" src={postAndFinishImage} />
                    <div className="text">Post & Finish</div>
                  </div>
                </Show>
                <Show if={this.props.allowAnyoneToFinishVisit || (ownAppointment && isPostStatus)}>
                  <div
                    className="item finish-visit"
                    onClick={() => {
                      this.hideBar();
                      if (nextVisitScreenPopupEnabled) {
                        this.props.setReferralModalVisible(true);
                        this.props.setVisitType("finish");
                      } else {
                        this.props.setPostPlan(false);
                        this.props.selectTreatmentToCompleteModalShow("finish");
                      }
                    }}
                  >
                    <img className="icon" alt="Finish Visit" src={finishVisitImage} />
                    <div className="text">Finish Visit</div>
                  </div>
                </Show>
                {/*</Show>*/}

                {showClose && (
                  <div
                    className="item finish-visit"
                    onClick={() => {
                      this.hideBar();
                      this.close();
                    }}
                  >
                    <img className="icon" alt="Close" src={closeImage} />
                    <div className="text">Close</div>
                  </div>
                )}

                {visibleModal ? <TreatmentCompletePopup /> : null}
              </div>
            </BottomMenu>

            <DocsImageUploadModal state={this.state.docsImageModal} />
          </>
        );
      }
    },
  ),
);
