import _ from "lodash";
import createReducer from "../helpers/createReducer";
import * as ActionType from "../actionTypes/patientActionTypes";
import { perioExamItemInitialState } from "../constants/perio-exam";

const defaultState = {
  list: [],
  item: [],
  status: {},
  markAsPrimary: {},
  searchResult: [],
  fields: {
    company: [],
    companyPlan: [],
    relation: [],
    type: [],
    broker: [],
  },
  paymentOptions: {
    insuranceCompanyCount: 0,
    insuranceCoverageLimitReached: false,
  },
  dentalPointList: [],
  patientGenderList: [],
  patientList: [],
  patientDetails: [],
  patient: null,
  fetchingPatientInfo: false,
  priceList: [],
  perioExamList: [],
  perioExamItem: {},
  fetchingPerioExamItem: false,
  perioExamItemHandle: null,
  perioExamItemModal: false,
  perioExamTempItem: perioExamItemInitialState,
  perioExamMovementType: "TYPE_1",
  patientInfoSideBarVisible: false,
  appointment: {},
  tagsList: [],
  selectedTagsList: [],
  fetchingTagsList: false,
  tagsListTotalCount: 0,
};

const reducers = {
  [ActionType.TOGGLE_PATIENT_TAG_ITEM](state, { index }) {
    const newToggleItem = {
      ...state.tagsList[index],
    };

    newToggleItem.selected = !newToggleItem.selected;
    const tagsList = state.tagsList.slice();

    tagsList[index] = newToggleItem;

    return {
      ...state,
      tagsList,
    };
  },
  [ActionType.SET_PATIENT_TAGS_LIST](state, { tagsList }) {
    return {
      ...state,
      tagsList,
    };
  },
  [ActionType.SET_PATIENT_TAG_ITEM_VALUE](state, { index, value }) {
    const newTagsList = {
      ...state.tagsList[index],
    };
    newTagsList.name = value;

    const tagsList = state.tagsList.slice();
    tagsList[index] = newTagsList;

    return {
      ...state,
      tagsList,
    };
  },
  [ActionType.GET_PATIENT_TAGS_LIST_START](state) {
    return {
      ...state,
      fetchingTagsList: true,
      tagsList: [],
    };
  },
  [ActionType.GET_PATIENT_TAGS_LIST_SUCCESS](state, { data }) {
    if (!_.isEmpty(data)) {
      data.forEach((item) => {
        item.selected = false;
      });
    }
    return {
      ...state,
      fetchingTagsList: false,
      tagsList: data || [],
      tagsListTotalCount: data.totalCount || 0,
    };
  },
  [ActionType.GET_PATIENT_TAGS_LIST_ERROR](state) {
    return {
      ...state,
      fetchingTagsList: false,
    };
  },
  [ActionType.GET_PATIENT_ASSIGNED_TAGS_SUCCESS](state, { data }) {
    const tagsList = state.tagsList.slice();

    tagsList.forEach((tag, index) => {
      if (!_.isEmpty(data)) {
        for (let i = 0; i < data.length; i++) {
          let selectedTag = data[i];
          let isSelected = tagsList[index].selected;

          if (tag.id === selectedTag.id) {
            tag.selected = !isSelected;
          }
        }
      }
    });
    return {
      ...state,
      selectedTagsList: data || [],
    };
  },
  [ActionType.OPEN_PATIENT_INFO_SIDE_BAR](state, { appointment }) {
    return {
      ...state,
      appointment,
      patientInfoSideBarVisible: true,
    };
  },
  [ActionType.CLOSE_PATIENT_INFO_SIDE_BAR](state) {
    return {
      ...state,
      patientInfoSideBarVisible: false,
    };
  },
  [ActionType.PATIENT_PERIO_EXAM_LIST_START](state) {
    return {
      ...state,
      fetchingPerioExamItem: true,
    };
  },
  [ActionType.PATIENT_PERIO_EXAM_LIST_SUCECSS](state, { data = [] }) {
    return {
      ...state,
      perioExamList: data,
      fetchingPerioExamItem: false,
    };
  },
  [ActionType.PATIENT_PERIO_EXAM_LIST_ERROR](state) {
    return {
      ...state,
      fetchingPerioExamItem: false,
    };
  },
  [ActionType.PATIENT_PERIO_EXAM_ITEM_SUCECSS](state, { data = [] }) {
    const perioExamItem = data;
    const perioExamList = _.get(data, "perioExamList", []);

    perioExamList.forEach((x) => {
      const toothNum = _.get(x, "tooth.name");
      const toothSide = _.get(x, "toothSide.code");

      perioExamItem[toothSide] = {
        ...perioExamItem[toothSide],
        [toothNum]: x,
      };
    });

    return {
      ...state,
      perioExamItem,
    };
  },
  [ActionType.GET_PATIENT_GENDER_SUCCESS](state, { data }) {
    return { ...state, patientGenderList: data };
  },
  [ActionType.SEARCH_PATIENT_LIST_SUCCESS](state, { data }) {
    return { ...state, patientList: data };
  },
  [ActionType.GET_PATIENT_DETAILS_START](state) {
    return { ...state, fetchingPatientInfo: true };
  },
  [ActionType.GET_PATIENT_DETAILS_SUCCESS](state, { data }) {
    return { ...state, patientDetails: data, fetchingPatientInfo: false };
  },
  [ActionType.GET_PATIENT_DETAILS_ERROR](state) {
    return { ...state, fetchingPatientInfo: false };
  },
  [ActionType.GET_PATIENT_PAYMENT_OPTIONS_SUCCESS](state, { data }) {
    return { ...state, paymentOptions: data };
  },
  [ActionType.GET_PATIENT_PRICE_LIST_SUCCESS](state, { data }) {
    return { ...state, priceList: data };
  },
  [ActionType.PERIO_EXAM_HANDLE_ITEM](state, { data }) {
    return {
      ...state,
      perioExamItemHandle: { ...state.perioExamItemHandle, ...data },
      perioExamItemModal: data ? true : false,
    };
  },
  [ActionType.PERIO_EXAM_ITEM_HIDE_MODAL](state) {
    return { ...state, perioExamItemHandle: null, perioExamItemModal: false };
  },
  [ActionType.PERIO_EXAM_TEMP_ITEM](state, { data }) {
    let {
      perioExamTempItem,
      perioExamItem: { perioExamList },
    } = state;
    perioExamTempItem = { ...perioExamTempItem, ...data };

    const index = (perioExamList || []).findIndex(
      (item) =>
        item.tooth.code == perioExamTempItem?.tooth?.code &&
        item.toothSide.code == perioExamTempItem.toothSide?.code,
    );
    if (index >= 0) {
      perioExamList[index] = perioExamTempItem;
    }
    return { ...state, perioExamTempItem };
  },
  [ActionType.PATIENT_PERIO_EXAM_START_ITEM_SUCECSS](state, { data }) {
    return { ...state, perioExamItem: data };
  },

  [ActionType.PERIO_EXAM_ITEM_TEMP_LIST](state, { data }) {
    const { teeth } = data;
    let perioExamItemList = [];
    teeth.forEach((item) => {
      perioExamItemList.push({
        ...perioExamItemInitialState,
        tooth: {
          id: item?.normal?.details?.code,
          name: item?.image + "",
          code: item?.normal?.details?.id,
        },
        toothSide: { id: 1, name: "Front_Side", code: "Front_Side" },
      });
      perioExamItemList.push({
        ...perioExamItemInitialState,
        tooth: {
          id: item?.normal?.details?.code,
          name: item?.image + "",
          code: item?.normal?.details?.id,
        },
        toothSide: { id: 2, name: "Back_Side", code: "Back_Side" },
      });
    });
    return { ...state, perioExamItem: { perioExamList: perioExamItemList } };
  },
  [ActionType.PERIO_EXAM_TEMP_CLEAR_ITEM](state) {
    const {
      perioExamTempItem,
      perioExamItem: { perioExamList },
    } = state;
    const index = (perioExamList || []).findIndex(
      (item) =>
        item.tooth.code == perioExamTempItem?.tooth?.code &&
        item.toothSide.code == perioExamTempItem.toothSide?.code,
    );
    if (index >= 0) {
      perioExamList[index] = perioExamTempItem;
    }

    return { ...state, perioExamTempItem: perioExamItemInitialState };
  },
  [ActionType.PERIO_EXAM_MOVEMENT_TYPE](state, { data }) {
    return { ...state, perioExamMovementType: data };
  },
};

/** ==================
 * Selectors
 * ================== */

export const patientViewSelector = ({ patientView }) => patientView;

export default createReducer(defaultState, reducers);
