import _ from "lodash";
import moment from "moment";
import createReducer from "../helpers/createReducer";

import * as ActionTypes from "../actionTypes/chartSideClinicalNoteActionTypes";

import { defaultStackItem } from "../constants/chart-sidebar/clinicalNoteStackCodes";
import { DATE_FORMAT_LEGACY, FULL_DATE_FORMAT, TIME_FORMAT } from "../constants/Constants";
import Utils from "../helpers/Utils";
import questionTypeCode from "../constants/chart-sidebar/questionTypeCodes";
import {
  SWITCH_CLINICAL_NOTE_DIAGNOSIS_CODE,
  SWITCH_CLINICAL_NOTE_TREATMENT_CODE,
} from "../actions/appointmentActions";

export const defaultState = {
  stack: [defaultStackItem],
  sortField: "create_date_desc",
  clinicId: null,
  fetching: false,
  ownedList: [],
  referenceList: [],
  category: [],
  templateList: [],
  searchValue: "",
  item: {
    name: "",
    note: "",
  },
  addendumId: 0,
  currentQuestionIndex: 0,
  deleteModalVisible: false,
  onAppointment: false,
  onCallList: false,
  noteDeleteConfirmModal: 0,
  restore: false,
  fullScreen: true,
  patientKey: "",
  multiQuestionOtherValue: {},
  lastModifiedDateToFilter: moment(),
  lastModifiedDateFromFilter: moment(),
  startDateFilter: null,
  endDateFilter: null,
  questionsModalVisible: false,
  questionList: [],
  questionListFetching: false,
  questionListTotalCount: 0,
  selectDiagnosisModalVisible: false,
  treatmentDiagnosisSelecting: false,
  selectTreatmentModalVisible: false,
  selectTreatmentExistingModalVisible: false,
  treatmentDiagnosisList: [],
  treatmentDiagnosisListFetching: false,
  treatmentDiagnosisListTotalCount: 0,
  setAppointmentTime: false,
  setAppointmentVisitTime: "0",
  doctorId: 0,
  clinicalNoteActiveTab: {
    code: "clinicalNotes",
    title: "All notes",
  },
};

const reducers = {
  [ActionTypes.RESET_CLINICAL_NOTE_FOR](state) {
    return {
      ...state,
      item: {
        name: "",
        note: "",
        templateQuestionsAndAnswers: [],
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_TAB](state, { clinicalNoteActiveTab }) {
    return {
      ...state,
      ownedList: [],
      sortField: "create_date_desc",
      startDateFilter: null,
      endDateFilter: null,
      clinicalNoteActiveTab,
    };
  },
  [ActionTypes.SET_CLINICAL_NOTE_END_FILTER_DATE](state, { date }) {
    return {
      ...state,
      endDateFilter: date,
    };
  },
  [ActionTypes.SET_CLINICAL_NOTE_START_FILTER_DATE](state, { date }) {
    return {
      ...state,
      startDateFilter: date,
    };
  },
  [ActionTypes.GET_REFERENCE_CLINICAL_NOTE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      referenceList: _.get(data, "list", []),
    };
  },
  [ActionTypes.CLINICAL_NOTE_SET_ADDENDUM_ID](state, { id }) {
    return {
      ...state,
      addendumId: id,
    };
  },
  [ActionTypes.CLINICAL_NOTE_DOCTOR_ID](state, { doctorId }) {
    return {
      ...state,
      doctorId,
    };
  },
  [ActionTypes.CHANGE_LAST_MODIFIED_DATE_FROM](state, { date }) {
    const startDate = Utils.getStartDay(date);
    const endDate = Utils.getEndDay(state.lastModifiedDateToFilter);

    const list = _.filter(state.ownedList, (x) => {
      const currentDate = _.get(x, "modifiedDate");

      return startDate < currentDate && currentDate < endDate;
    });

    return {
      ...state,
      ownedList: list,
      lastModifiedDateFromFilter: date,
    };
  },
  [ActionTypes.CHANGE_LAST_MODIFIED_DATE_TO](state, { date }) {
    const startDate = Utils.getStartDay(state.lastModifiedDateFromFilter);
    const endDate = Utils.getEndDay(date);

    const list = _.filter(state.ownedList, (x) => {
      const currentDate = _.get(x, "modifiedDate");

      return startDate < currentDate && currentDate < endDate;
    });

    return {
      ...state,
      ownedList: list,
      lastModifiedDateToFilter: date,
    };
  },
  [ActionTypes.CLEAR_MULTI_QUESTIONS](state) {
    return {
      ...state,
      multiQuestionOtherValue: {},
    };
  },
  [ActionTypes.CLINICAL_NOTE_MULTI_QUESTIONS_OTHER_VALUE](state, { id, data }) {
    const { multiQuestionOtherValue } = state;

    multiQuestionOtherValue[id] = data;

    return {
      ...state,
      multiQuestionOtherValue,
    };
  },
  [ActionTypes.SIDE_CLINICAL_NOTE_FULL_SCREEN](state, { fullScreen }) {
    return {
      ...state,
      fullScreen,
    };
  },
  [ActionTypes.NOTE_DELETE_CONFIRM_MODAL_SHOW](state, { id, bool }) {
    return {
      ...state,
      noteDeleteConfirmModal: id,
      restore: bool,
    };
  },
  [ActionTypes.NOTE_DELETE_CONFIRM_MODAL_HIDE](state) {
    return {
      ...state,
      noteDeleteConfirmModal: 0,
    };
  },
  [ActionTypes.SAVE_CLINICAL_NOTE_ITEM_SUCCESS](state) {
    return {
      ...state,
      // item: {
      //   name: "",
      //   note: "",
      // },
      item: {
        ...state.item,
        templateQuestionsAndAnswers:
          state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
            ? state.item.templateQuestionsAndAnswers.map((item) => {
                return {
                  ...item,
                  answers: [],
                };
              })
            : [],
      },
      multiQuestionOtherValue: {},
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_PATIENT_KEY](state, { patientKey }) {
    return {
      ...state,
      patientKey,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_QUESTIONS_MODAL_VISIBLE](state) {
    return {
      ...state,
      questionsModalVisible: !state.questionsModalVisible,
      questionList: !state.questionsModalVisible ? [] : state.questionList,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_SELECT_DIAGNOSIS_MODAL_VISIBLE](state) {
    return {
      ...state,
      selectDiagnosisModalVisible: !state.selectDiagnosisModalVisible,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_TREATMENT_DIAGNOSIS_SELECTING](state) {
    return {
      ...state,
      treatmentDiagnosisSelecting: !state.treatmentDiagnosisSelecting,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_MODAL_VISIBLE](state) {
    return {
      ...state,
      selectTreatmentModalVisible: !state.selectTreatmentModalVisible,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_SELECT_TREATMENT_EXISTING_MODAL_VISIBLE](state) {
    return {
      ...state,
      selectTreatmentExistingModalVisible: !state.selectTreatmentExistingModalVisible,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SET_SET_APPOINTMENT_TIME](state, { setAppointmentTime }) {
    return {
      ...state,
      setAppointmentTime,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_APPOINTMENT_VISIT_TIME](state, { setAppointmentVisitTime }) {
    return {
      ...state,
      setAppointmentVisitTime,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_START](state) {
    return {
      ...state,
      treatmentDiagnosisListFetching: true,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_ERROR](state) {
    return {
      ...state,
      treatmentDiagnosisListFetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TREATMENT_DIAGNOSIS_LIST_SUCCESS](state, { data, request }) {
    return {
      ...state,
      treatmentDiagnosisList: request.start
        ? state.treatmentDiagnosisList.concat(data.list)
        : data.list || [],
      treatmentDiagnosisListTotalCount: data.totalCount,
      treatmentDiagnosisListFetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_QUESTION_LIST_START](state) {
    return {
      ...state,
      questionListFetching: true,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_QUESTION_LIST_ERROR](state) {
    return {
      ...state,
      questionListFetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_QUESTION_LIST_SUCCESS](state, { data, request }) {
    const list = (data.list || []).map((item) => {
      return {
        ...item,
        defaultAnswer: item.defaultAnswers ? item.defaultAnswers[0] : [],
      };
    });

    return {
      ...state,
      questionList: request.start ? state.questionList.concat(list) : list || [],
      questionListTotalCount: data.totalCount,
      questionListFetching: false,
    };
  },
  [ActionTypes.INIT_SIDE_CLINICAL_NOTE](state, { onAppointment, onCallList }) {
    return {
      ...state,
      stack: [defaultStackItem],
      searchValue: "",
      currentQuestionIndex: 0,
      onAppointment,
      onCallList,
    };
  },
  [ActionTypes.SELECT_SORT_FIELD_SIDE_CLINICAL_NOTE](state, { code }) {
    return {
      ...state,
      sortField: code,
    };
  },
  [ActionTypes.SELECT_CLINIC_FIELD_SIDE_CLINICAL_NOTE](state, { id }) {
    return {
      ...state,
      clinicId: id,
    };
  },
  [ActionTypes.CHART_CLINICAL_NOTE_SEARCH_CHANGE](state, { value }) {
    return {
      ...state,
      searchValue: value,
    };
  },
  [ActionTypes.PUSH_CLINICAL_NOTE_STACK](state, { stackItem }) {
    const stack = state.stack.slice();
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.REPLACE_CLINICAL_NOTE_STACK](state, { stackItem }) {
    const stack = [];
    stack.push(stackItem);
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.POP_CLINICAL_NOTE_STACK](state) {
    const stack = state.stack.slice();
    stack.pop();
    return {
      ...state,
      stack,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_OWNED_LIST_START](state) {
    return {
      ...state,
      fetching: true,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_OWNED_LIST_SUCCESS](state, { data, request }) {
    const listGet = request.start ? state.ownedList.concat(data.list) : data.list || [];

    if (request.start) {
      return {
        ...state,
        fetching: false,
        ownedList: listGet,
        ownedListTotalCount: data.totalCount,
      };
    }

    return {
      ...state,
      fetching: false,
      // item: {
      //   name: "",
      //   note: "",
      // },
      item: {
        ...state.item,
        templateQuestionsAndAnswers:
          state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
            ? state.item.templateQuestionsAndAnswers.map((item) => {
                return {
                  ...item,
                  answers: [],
                };
              })
            : [],
      },
      ownedList: listGet,
      ownedListTotalCount: data.totalCount,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_OWNED_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_CATEGORY_SUCCESS](state, { data }) {
    return {
      ...state,
      category: data,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      templateList: [],
      fetching: true,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TEMPLATE_LIST_SUCCESS](state, { data }) {
    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers:
          state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
            ? state.item.templateQuestionsAndAnswers.map((item) => {
                return {
                  ...item,
                  answers: [],
                };
              })
            : [],
      },
      templateList: data,
      fetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_TEMPLATE_LIST_ERROR](state) {
    return {
      ...state,
      fetching: false,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_ITEM_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_ITEM_SUCCESS](state, { data }) {
    const writtenNote = !data.noteTemplate.name;
    const drawenNote = data.typeCode == "NOTE_TYPE_IMAGE";

    if (!writtenNote && !drawenNote) {
      const orderedQuestions = [];

      const reqExp = /\[\[[^\]]*]]/g;
      data.noteTemplate.code.replace(reqExp, (match) => {
        const code = match.slice(2, -2);
        if (code == "Current date") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
        }
        if (code == "Current time") {
          return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
        }
        if (code == "Patient chart number") {
          return data.patient.chartNumber;
        }
        if (code == "Patient name") {
          return data.patient.fullName;
        }
        if (code == "Dentist Name") {
          return data.owner.name;
        }
        if (code == "Check-in date time") {
          return Utils.parseDateFormat(
            Utils.calcDateWithTimeZone(data.checkedInDate),
            FULL_DATE_FORMAT,
          );
        }

        if (code === "Doctor license id") {
          return data.owner && data.owner.code;
        }

        let question = _.get(data, "templateQuestionsAndAnswers", []).find(
          (question) => question.code == code,
        );

        if (!question) {
          question = {
            id: 0,
            code: "",
            name: "",
            question: "",
            questionType: "",
            answers: [],
          };
        }

        orderedQuestions.push(question);

        return match;
      });

      data.templateQuestionsAndAnswers = orderedQuestions;
    }

    return {
      ...state,
      item: data,
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_EMPTY_START](state) {
    return {
      ...state,
      item: {},
    };
  },
  [ActionTypes.GET_CLINICAL_NOTE_EMPTY_SUCCESS](state, { data }) {
    const orderedQuestions = [];

    const reqExp = /\[\[[^\]]*]]/g;
    data.note = data.noteTemplate.code.replace(reqExp, (match) => {
      const code = match.slice(2, -2);
      if (code == "Current date") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), DATE_FORMAT_LEGACY);
      }
      if (code == "Current time") {
        return Utils.parseDateFormat(Utils.calcDateWithTimeZone(), TIME_FORMAT);
      }
      if (code == "Patient chart number") {
        return data.patient.chartNumber;
      }
      if (code == "Patient name") {
        return data.patient.fullName;
      }
      if (code == "Dentist Name") {
        return data.owner.name;
      }
      if (code == "Check-in date time") {
        return Utils.parseDateFormat(
          Utils.calcDateWithTimeZone(data.checkedInDate),
          FULL_DATE_FORMAT,
        );
      }

      if (code === "Doctor license id") {
        return data.owner && data.owner.code;
      }

      const question = _.get(data, "templateQuestionsAndAnswers", []).find(
        (question) => question.code == code,
      );

      if (question) orderedQuestions.push(question);

      return match;
    });

    data.templateQuestionsAndAnswers = orderedQuestions;

    const list = data.templateQuestionsAndAnswers.map((x) => {
      if (
        x.questionType.code === questionTypeCode.DIAGNOSES ||
        x.questionType.code === questionTypeCode.TREATMENTS_COMPLETED
      ) {
        return {
          ...x,
          answers: [],
        };
      }

      return x;
    });

    return {
      ...state,
      item: {
        ...data,
        templateQuestionsAndAnswers: list,
      },
    };
  },
  [SWITCH_CLINICAL_NOTE_TREATMENT_CODE](state, { item, questionId }) {
    const { templateQuestionsAndAnswers = [] } = state.item;

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers: templateQuestionsAndAnswers.map((x) => {
          if (x.id === questionId) {
            const newAnswers = (x.answers || []).slice();

            const index = newAnswers.findIndex(({ id }) => id === item.id);

            if (index >= 0) {
              newAnswers.splice(index, 1);
            } else {
              newAnswers.push(item);
            }

            return {
              ...x,
              answers: newAnswers,
            };
          }

          return x;
        }),
      },
    };
  },
  [SWITCH_CLINICAL_NOTE_DIAGNOSIS_CODE](state, { item, questionId }) {
    const list = (state.selectedDiagnosisCodeList[questionId] || []).slice();

    const index = list.findIndex(({ id }) => id === item.id);

    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(item);
    }

    return {
      ...state,
      selectedDiagnosisCodeList: {
        ...state.selectedDiagnosisCodeList,
        [questionId]: list,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_PREV_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: state.currentQuestionIndex - 1,
    };
  },
  [ActionTypes.CLINICAL_NOTE_NEXT_QUESTION](state) {
    return {
      ...state,
      currentQuestionIndex: state.currentQuestionIndex + 1,
    };
  },
  [ActionTypes.CLINICAL_NOTE_SELECT_QUESTION](state, { index }) {
    return {
      ...state,
      currentQuestionIndex: index,
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_QUESTION_TEXTAREA](state, { value, questionId }) {
    const templateQuestionsAndAnswers =
      state.item &&
      state.item.templateQuestionsAndAnswers &&
      state.item.templateQuestionsAndAnswers.length
        ? state.item.templateQuestionsAndAnswers.map((question) => {
            if (question.id !== questionId) return question;
            if (!question.answers) question.answers = [];
            question.answers[0] = value;
            return {
              ...question,
            };
          })
        : [];

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS](state, { value, questionId }) {
    const templateQuestionsAndAnswers =
      state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
        ? state.item.templateQuestionsAndAnswers.map((question) => {
            if (question.id !== questionId) return question;
            if (!question.answers) question.answers = [];
            question.answers[0] = value;
            return {
              ...question,
            };
          })
        : [];

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_QUESTION_LIST_OF_ANSWERS_MULTIPLE](
    state,
    { value = "", questionId },
  ) {
    let newValue = value.trim();
    const templateQuestionsAndAnswers = (state.item.templateQuestionsAndAnswers || []).map(
      (question) => {
        if (question.id !== questionId) return question;

        if (!question.answers) question.answers = [];

        const index = question.answers.indexOf(newValue);

        if (index === -1) {
          if (newValue) {
            question.answers.push(newValue);
            newValue = "";
          }
        } else {
          if (newValue) {
            question.answers.splice(index, 1);
            newValue = "";
          }
        }

        return question;
      },
    );
    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_DATE_PICKER](state, { date, questionId }) {
    const templateQuestionsAndAnswers =
      state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
        ? state.item.templateQuestionsAndAnswers.map((question) => {
            if (question.id !== questionId) return question;
            if (!question.answers) question.answers = [moment()];
            question.answers[0] = date;
            return {
              ...question,
            };
          })
        : [];

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_CHANGE_SURFACE](state, { value, questionId }) {
    const templateQuestionsAndAnswers =
      state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
        ? state.item.templateQuestionsAndAnswers.map((question) => {
            if (question.id !== questionId) return question;
            if (!question.answers) question.answers = [];
            const index = question.answers.indexOf(value);
            if (index === -1) {
              question.answers.push(value);
            } else {
              question.answers.splice(index, 1);
            }
            return {
              ...question,
            };
          })
        : [];

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_SELECT_TOOTH](state, { value, questionId }) {
    const templateQuestionsAndAnswers =
      state.item.templateQuestionsAndAnswers && state.item.templateQuestionsAndAnswers.length
        ? state.item.templateQuestionsAndAnswers.map((question) => {
            if (question.id !== questionId) return question;
            if (!question.answers) question.answers = [];
            const index = question.answers.indexOf(value);
            if (index === -1) {
              question.answers.push(value);
            } else {
              question.answers.splice(index, 1);
            }
            return {
              ...question,
            };
          })
        : [];

    return {
      ...state,
      item: {
        ...state.item,
        templateQuestionsAndAnswers,
      },
    };
  },
  [ActionTypes.CHANGE_CLINICAL_NOTE_CREATED_DATE_SUCCESS](state, { data: { createdDate } }) {
    return {
      ...state,
      item: {
        ...state.item,
        createdDate,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_OPEN_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: true,
    };
  },
  [ActionTypes.CLINICAL_NOTE_CLOSE_DELETE_MODAL](state) {
    return {
      ...state,
      deleteModalVisible: false,
    };
  },
  [ActionTypes.CLINICAL_NOTE_INIT_EDIT](state) {
    return {
      ...state,
      currentQuestionIndex: 0,
    };
  },
  [ActionTypes.CLINICAL_NOTE_INIT_TEXTAREA](state, { patient }) {
    return {
      ...state,
      item: {
        name: "",
        note: "",
        patient,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_SET_NAME](state, { value }) {
    return {
      ...state,
      item: {
        ...state.item,
        name: value,
      },
    };
  },
  [ActionTypes.CLINICAL_NOTE_SET_TEXTAREA_BODY](state, { value }) {
    return {
      ...state,
      item: {
        ...state.item,
        note: value,
      },
    };
  },
};

export default createReducer(defaultState, reducers);
