const questionTypeCode = {
  LIST_OF_ANSWERS: "LIST_OF_ANSWERS",
  RADIO_LIST: "RADIO_LIST",
  VALUE_RADIO_LIST: "VALUE_RADIO_LIST",

  FREE_FORM_ENTRY: "FREE_FORM_ENTRY",
  TEXT_AREA: "TEXT_AREA",
  TEXT: "TEXT",

  DATE_SELECTOR: "DATE_SELECTOR",
  DATE_PICKER: "DATE_PICKER",

  TEETH_SELECTION: "TEETH_SELECTION",
  SURFACE_SELECTION: "SURFACE_SELECTION",

  LIST_OF_ANSWERS_MULTIPLE_CHOICE: "LIST_OF_ANSWERS_MULTIPLE_CHOICE",
  CHECKBOX_LIST: "CHECKBOX_LIST",

  VALUE: "VALUE",
  FORMULA: "FORMULA",

  CANVAS: "CANVAS",

  DIAGNOSES: "DIAGNOSES",
  TREATMENTS_COMPLETED: "TREATMENTS_COMPLETED",

  IMAGE: "IMAGE",
  PICKLIST: "PICKLIST",
};

export default questionTypeCode;
