import React from "react";
import cx from "classnames";
import { Typeahead } from "react-typeahead";
import ReactSpinner from "react-spinjs-fix";

export class Autocomplete extends React.Component {
  render() {
    const {
      name,
      onChange,
      options,
      value,
      placeholder,
      inputClassName,
      fetching,
      onSelect,
      onBlur,
    } = this.props;

    return (
      <div className="typeahead-autocomplete">
        <Typeahead
          value={value}
          onBlur={onBlur}
          options={options}
          placeholder={placeholder}
          defaultClassNames={false}
          formInputOption={{ name }}
          onOptionSelected={onSelect}
          onChange={({ target }) => onChange(target.value)}
          customClasses={{
            input: cx(inputClassName, "form-control"),
            results: "typeahead-autocomplete-list",
            listItem: "typeahead-autocomplete-button",
            listAnchor: "typeahead-autocomplete-button",
          }}
        />

        {fetching && (
          <div className="typeahead-autocomplete-loader">
            <ReactSpinner config={{ scale: "0.5" }} />
          </div>
        )}
      </div>
    );
  }
}
