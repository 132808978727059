import _ from "lodash";

import Utils from "../helpers/Utils";
import { stopLoader } from "../actions/loaderActions";

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  if (!action.api || !action.types) {
    return next(action);
  }
  const {
    api,
    types: [START, SUCCESS, ERROR],
    data,
  } = action;

  dispatch({
    type: START,
    data,
  });

  return new Promise((resolve, reject) => {
    let requestCount = 0;

    api(data)
      .then((response) => {
        if (response.status === 200 && typeof response.data === "string") {
          let hiddenElement;
          hiddenElement = document.createElement("a");
          hiddenElement.href = "data:application/pdf;base64," + encodeURI(response.data);
          hiddenElement.target = "_blank";
          hiddenElement.download = "Patient_Label_[" + data.patientKey + "]_.pdf";
          hiddenElement.click();

          return response;
        }
        if (response.data.statusCode !== 2 || response.data.status !== "success") {
          throw new Error(`${response.data.message || "unknown error"}\n`);
        } else {
          return response;
        }
      })
      .then((response) =>
        response && response.data && response.data
          ? response.data.data
          : response && response.data
          ? response.data
          : response,
      )
      .then((response) => {
        dispatch({
          data: response,
          type: SUCCESS,
          request: data,
        });

        resolve(response);
      })
      .catch((error) => {
        let errorMessage = Utils.validateResponse(error);
        const errorType = _.get(error, "message");

        if (errorMessage === 10000) {
          requestCount++;

          if (requestCount < 10) {
            return api(data);
          }
        }

        if (errorMessage === 10000) {
          errorMessage =
            "No Internet Connection, Check your connection & restart the application\n";
        }

        if (errorType === "invalid_token") {
          dispatch(stopLoader());
        }

        if (errorMessage && errorType !== "invalid_token") {
          dispatch({
            type: ERROR,
            error: errorMessage,
          });
          return reject(errorMessage);
        }
      });
  });
};

export default apiMiddleware;
