import cx from "classnames";
import { isUndefined, noop } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { ModalHeaderProps } from "react-bootstrap/ModalHeader";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { Modal as RBModal, ModalProps as RBModalProps } from "react-bootstrap";

import { Theme } from "../../dto/AppDTO";
import { hexToRgba } from "../../utils/FormatUtils";
import { connect } from "react-redux";
import { clickArroundModalSelector } from "../../reducers/sessionReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

const useStyles = makeStyles<Theme>((theme) => ({
  title: { display: "inline-block", width: "100%" },
  fullScreenContent: { flex: 1, display: "flex" },
  root: { zIndex: 10000, "&.modal-static .modal-dialog": { transform: "scale(1.02)" } },
  fullScreen: { margin: 0, width: "100% !important", height: "100%", display: "flex" },
  body: { width: "100%", padding: "12px", display: "flex", flexDirection: "column" },
  backdrop: { backgroundColor: hexToRgba(theme.palette.common.black, { opacity: 0.5 }) },
}));

export interface ModalBodyProps extends Partial<BsPrefixRefForwardingComponent<"div", unknown>> {
  readonly className?: string;
  readonly style?: any;
}

export interface ModalProps extends RBModalProps {
  readonly title?: ReactNode;
  readonly children: ReactNode;
  readonly actions?: ReactNode;
  readonly fullScreen?: boolean;
  readonly bodyProps?: ModalBodyProps;
  readonly headerProps?: Omit<ModalHeaderProps, "ref" | "children">;
  readonly footerProps?: BsPrefixRefForwardingComponent<"div", unknown>;
}

export const Modal = ({
  title,
  onHide = noop,
  actions,
  children,
  bodyProps,
  className,
  headerProps,
  footerProps,
  fullScreen,
  dialogClassName,
  contentClassName,
  ...props
}: ModalProps) => {
  const classes = useStyles();

  const [opened, setOpened] = useState(false);
  const [clicked, setClicked] = useState(false);
  const clickArroundModal = useShallowEqualSelector(clickArroundModalSelector as any);

  const clinicCanClose = useMemo(() => clickArroundModal, [clickArroundModal]);
  const backdrop = useMemo(() => {
    if (isUndefined(props.backdrop) && !clinicCanClose) {
      return "static";
    }

    return props.backdrop;
  }, [clinicCanClose, props.backdrop]);

  useEffect(() => {
    return () => onHide();
  }, []);

  return (
    <RBModal
      animation={true}
      {...props}
      onHide={onHide}
      backdrop={backdrop}
      // keyboard={props.keyboard || clinicCanClose}
      dialogClassName={cx({ [classes.fullScreen]: fullScreen }, dialogClassName)}
      className={cx(classes.root, className, { in: opened, "modal-static": clicked })}
      backdropClassName={cx(classes.backdrop, props.backdropClassName, { in: opened })}
      contentClassName={cx({ [classes.fullScreenContent]: fullScreen }, contentClassName)}
      onBackdropClick={() => {
        setClicked(true);

        const timeout = setTimeout(() => {
          setClicked(false);

          clearTimeout(timeout);
        }, 500);
      }}
      onExit={() => {
        setOpened(false);

        if (props.onExit) {
          props.onExit();
        }
      }}
      onEntered={() => {
        setOpened(true);

        if (props.onEntered) {
          props.onEntered();
        }
      }}
    >
      {Boolean(headerProps || title) && (
        <RBModal.Header {...headerProps} closeButton={true}>
          <RBModal.Title className={classes.title}>{title}</RBModal.Title>
        </RBModal.Header>
      )}
      <RBModal.Body {...bodyProps} className={cx(classes.body, bodyProps?.className)}>
        {children}
      </RBModal.Body>
      {Boolean(footerProps || actions) && (
        <RBModal.Footer {...footerProps}>{actions}</RBModal.Footer>
      )}
    </RBModal>
  );
};

export const ModalHeader = RBModal.Header;
