import React from "react";
import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";
import * as patientViewActions from "../../actions/patientViewActions";
import * as simpleModalActions from "../../actions/simpleModalActions";
import { Sidebar } from "../sidebar/Sidebar";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { PatientViewModalAddForm } from "./PatientViewModalAddForm";

const enhancer = connect(null, bindActions({ patientViewActions, simpleModalActions }));
class PatientViewModal extends React.Component {
  save = (values, helpers) => {
    this.props.patientViewActions.closeNoteEdit();
    this.props.patientViewActions.showModal(this.props.modalType, this.props.patientKey);
    this.props.patientViewActions.editNoteEdit(values, this.props.patientKey);

    helpers.resetForm();
  };

  render() {
    const { activeNote, noteEditVisible, modalType } = this.props;

    return (
      <Sidebar
        show={noteEditVisible}
        onHide={() => {
          this.props.patientViewActions.closeNoteEdit();
          this.props.patientViewActions.showModal(modalType);
        }}
        dialogClassName="chart-side-bar"
        className="chart-clinical-note patient-view-note-modal"
      >
        <SidebarHeader
          title="Edit Note"
          onBackClick={() => {
            this.props.patientViewActions.closeNoteEdit();
            this.props.patientViewActions.showModal(modalType);
          }}
        />

        <div className="chart-side-body">
          <PatientViewModalAddForm
            onSubmit={this.save}
            initialValues={{
              ...activeNote,
              note: activeNote.note || activeNote.body,
            }}
            submitButtonTitle="Save"
          />
        </div>
      </Sidebar>
    );
  }
}
export default enhancer(PatientViewModal);
