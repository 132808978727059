import cx from "classnames";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode, useMemo } from "react";
import { Form, FormGroupProps, FormLabelProps } from "react-bootstrap";

import { Theme } from "../../dto/AppDTO";
import { FormTextProps } from "react-bootstrap/FormText";

const useStyles = makeStyles((theme: Theme) => ({
  root: { margin: 0 },
  label: { marginBottom: 0 },
  container: { display: "flex" },
  leftLabel: { marginRight: "8px" },
  leftContainer: { alignItems: "center" },
  error: { color: theme.palette.error.main },
  topLabelContainer: { flexDirection: "column" },
  errorLabel: { color: theme.palette.error.main },
  contentContainer: { display: "flex", flex: 1, flexDirection: "column" },
}));

export enum LabelPosition {
  Top = "top",
  Left = "left",
}

export interface FieldContainerProps extends Omit<FormGroupProps, "children"> {
  readonly label?: string;
  readonly error?: boolean;
  readonly hintText?: string;
  readonly children: ReactNode;
  readonly hintProps?: FormTextProps;
  readonly labelProps?: FormLabelProps;
  readonly labelPosition?: LabelPosition;
  readonly search?: boolean | undefined;
}

export function FieldContainer({
  label,
  error,
  children,
  hintText,
  hintProps,
  labelProps,
  labelPosition,
  ...props
}: FieldContainerProps) {
  const classes = useStyles();

  const isLabelTop = useMemo(() => labelPosition !== LabelPosition.Left, [labelPosition]);

  return (
    <Form.Group {...props} className={cx(classes.root, props.className)}>
      <div
        className={cx(classes.container, {
          [classes.leftContainer]: !isLabelTop,
          [classes.topLabelContainer]: isLabelTop,
        })}
      >
        {Boolean(label) && (
          <Form.Label
            {...labelProps}
            className={cx(
              classes.label,
              { [classes.leftLabel]: !isLabelTop, [classes.errorLabel]: error },
              labelProps?.className,
            )}
          >
            {label}
          </Form.Label>
        )}

        <div className={classes.contentContainer}>{children}</div>
      </div>

      <Form.Text {...hintProps} className={cx({ [classes.error]: error }, hintProps?.className)}>
        {hintText}
      </Form.Text>
    </Form.Group>
  );
}
