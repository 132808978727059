import _, { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import bindActions from "../../helpers/bindActions";

import * as chartSideSeriesActions from "../../actions/chartSideSeriesActions";
import { SidebarStackTypes } from "./ChartTreatmentsSidebar";
import DataList from "../widgets/DataList";
import { Button } from "react-bootstrap";
import { Modal } from "../ui/Modal";
import { SelectPicker } from "../select-picker/SelectPicker";
import SidebarAddNewProcedureForm from "./SidebarAddNewProcedureForm";
import { customizePackageModalHide, customizePackageModalShow } from "../../actions/chartActions";
import { createUrl, parseQuery } from "../../utils/UrlUtils";
import Show from "../widgets/Show";
import ReactSpinner from "react-spinjs-fix";
import { getPackageGroupDetails } from "../../actions/chartSideSeriesActions";

const procedureListFilterTypes = {
  Bundle: "BUNDLE",
  Group: "PACKAGE_GROUP",
};

const enhancer = connect(
  ({
    chart: { customizePackageModalVisible },
    chartSideSeries: { fetchingProcedureItem, packageGroupItemDetails },
  }) => ({
    customizePackageModalVisible,
    fetchingProcedureItem,
    packageGroupItemDetails,
  }),
  bindActions({
    chartSideSeriesActions,
    getPackageGroupDetails,
    customizePackageModalShow,
    customizePackageModalHide,
  }),
);
class SidebarProcedureCategoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      item: {},
      showModal: false,
      treatmentsCount: 1,
      procedureListFilter: procedureListFilterTypes.Group,
      isDynamicPackage: false,
    };
  }

  pushStack(id) {
    const { stack } = this.state;

    stack.push(id);

    this.setState({ stack });
  }

  popStack() {
    const { stack } = this.state;

    stack.pop();

    this.setState({ stack });
  }

  componentDidMount() {
    const { chartSideSeriesActions } = this.props;

    chartSideSeriesActions.getCategoryList();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { procedureListFilter } = nextState;

    const { lastStack: prevLastStack } = this.props;
    const { lastStack: nextLastStack, chartSideSeriesActions } = nextProps;

    if (
      (prevLastStack === SidebarStackTypes.ProcedureCategory && _.isEmpty(nextLastStack)) ||
      (prevLastStack === SidebarStackTypes.ProcedureSubCategory &&
        nextLastStack === SidebarStackTypes.ProcedureCategory)
    ) {
      this.popStack();
    }

    const { stack } = this.state;

    const id = _.toFinite(_.last(stack));

    if (nextLastStack !== prevLastStack) {
      if (_.isEmpty(nextLastStack)) {
        chartSideSeriesActions.getCategoryList();
      }

      if (nextLastStack === SidebarStackTypes.ProcedureCategory && id > 0) {
        chartSideSeriesActions.getGroupProcedureList(procedureListFilter, id);
        chartSideSeriesActions.getCategoryList(id);
      }

      if (nextLastStack === SidebarStackTypes.ProcedureSubCategory && id > 0) {
        chartSideSeriesActions.getGroupProcedureList(procedureListFilter, null, id);
      }
    }
  }

  loadGroupProcedureList = () => {
    const { chartSideSeriesActions } = this.props;
    const { stack, procedureListFilter } = this.state;
    const id = _.toFinite(_.last(stack));
    if (id > 0) {
      chartSideSeriesActions.loadGroupProcedureList(procedureListFilter, id);
    }
  };

  clickCategory(item, isCategory) {
    const { pushStack, lastStack } = this.props;

    if (isCategory) {
      this.pushStack(item.id);
      pushStack(
        _.isEmpty(lastStack)
          ? SidebarStackTypes.ProcedureCategory
          : SidebarStackTypes.ProcedureSubCategory,
      );
    } else {
      const query = parseQuery(this.props.location.search);
      this.props.history.replace(
        createUrl(this.props.location.pathname, { query: { ...query, id: item.id } }),
      );
      this.props.getPackageGroupDetails(item);

      this.setState({ item, showModal: true });

      if (_.get(item, "type.code") === "DYNAMIC") {
        const query = parseQuery(this.props.location.search);
        this.setState({ isDynamicPackage: true });
        this.props.history.replace(
          createUrl(this.props.location.pathname, { query: { ...query, id: item.id } }),
        );
        this.setState({ showModal: true });
        this.pushStack(SidebarStackTypes.DynamicPackage);
        pushStack(SidebarStackTypes.DynamicPackage);
        this.props.getPackageGroupDetails(item);
      } else {
        this.setState({ isDynamicPackage: false, item, showModal: true });
        this.props.getPackageGroupDetails(item);
        // this.setState({ item, showModal: true });
      }
    }
  }

  showFormsByPackageTypeHandler() {
    this.setState({ showModal: false });
    this.props.customizePackageModalShow();
  }

  formatValues() {
    const { packageGroupItemDetails } = this.props;
    const transfer = {
      ...packageGroupItemDetails,
    };

    if ((packageGroupItemDetails.treatments || []).length > 0) {
      transfer.treatments = packageGroupItemDetails.treatments?.map((item) => {
        return {
          treatment: { id: get(item, "id") },
          code: get(item, "code"),
          id: get(item, "id"),
          packagePrice: Number(get(item, "packagePrice")),
          quantity:
            packageGroupItemDetails.type.code === "DYNAMIC"
              ? item.quantity
              : this.state.treatmentsCount,
          unit: Number(get(item, "unit")),
        };
      });
    }
    return transfer;
  }
  render() {
    const { procedureListFilter, showModal, stack, treatmentsCount } = this.state;
    const {
      chartSideSeries,
      lastStack,
      openItem,
      chartSideSeriesActions,
      customizePackageModalVisible,
      customizePackageModalHide,
      history,
      location,
      fetchingProcedureItem,
    } = this.props;
    const {
      categoryList,
      fetchingCategoryList,
      fetchingSubCategoryList,
      subCategoryList,
    } = chartSideSeries;

    const isProcedureRoot = _.isEmpty(lastStack);
    return (
      <div
        style={{
          padding: "4px",
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!isProcedureRoot && (
          <SelectPicker
            value={{ value: procedureListFilter }}
            onChange={(x) => {
              this.setState({ procedureListFilter: x.value });

              const id = _.toFinite(_.last(stack));

              if (lastStack === SidebarStackTypes.ProcedureCategory && id > 0) {
                chartSideSeriesActions.getCategoryList(id);
                chartSideSeriesActions.getGroupProcedureList(x.value, id);
              }

              if (lastStack === SidebarStackTypes.ProcedureSubCategory && id > 0) {
                chartSideSeriesActions.getGroupProcedureList(x.value, null, id);
              }
            }}
            options={[
              { value: procedureListFilterTypes.Group, label: "Package Group" },
              { value: procedureListFilterTypes.Bundle, label: "Bundle" },
            ]}
          />
        )}

        <DataList
          fetching={fetchingCategoryList || fetchingSubCategoryList}
          className="procedure-category-list"
          onScroll={this.loadGroupProcedureList}
        >
          {lastStack !== SidebarStackTypes.ProcedureSubCategory && (
            <div className="procedure-category-item title">Procedure Category</div>
          )}

          {Boolean(
            lastStack !== SidebarStackTypes.ProcedureSubCategory &&
              _.isEmpty(categoryList) &&
              !fetchingCategoryList,
          ) && <span className="text-center">Empty</span>}

          {lastStack !== SidebarStackTypes.ProcedureSubCategory &&
            categoryList.map((x) => (
              <button
                key={x.id}
                className="procedure-category-item"
                onClick={() => this.clickCategory(x, true)}
              >
                <span>{x.name}</span>
                <span>{">"}</span>
              </button>
            ))}

          {!isProcedureRoot && (
            <div className="procedure-category-item title">
              <span>Procedure Templates</span>

              <span style={{ width: "100px", textAlign: "center" }}>Total price</span>
            </div>
          )}

          {Boolean(!isProcedureRoot && _.isEmpty(subCategoryList) && !fetchingSubCategoryList) && (
            <span className="text-center">Empty</span>
          )}

          {!isProcedureRoot &&
            subCategoryList.map((x) => (
              <button
                key={x.id}
                className="procedure-category-item item"
                onClick={() => this.clickCategory(x, false)}
              >
                <span>{x.name}</span>

                <span style={{ width: "100px", textAlign: "center" }}>
                  {_.get(x, "totalPackagePrice", 0)}
                </span>
              </button>
            ))}
        </DataList>

        <Modal
          size="small"
          show={showModal}
          keyboard={false}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          actions={
            <div>
              <Button
                bsStyle="danger"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                Close
              </Button>
              {this.state.isDynamicPackage && (
                <Button
                  bsStyle="danger"
                  onClick={() => {
                    this.showFormsByPackageTypeHandler();
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                bsStyle="primary"
                onClick={() => {
                  this.setState({ showModal: false });
                  openItem(this.formatValues(), 1);
                }}
              >
                OK
              </Button>
            </div>
          }
        >
          <div>
            <span>Post treatments under this procedure</span>

            <label className="mt-3" htmlFor="TreatmentsCount">
              Treatments count
            </label>
            <input
              placeholder="1"
              id="TreatmentsCount"
              value={treatmentsCount}
              className="form-control"
              onChange={({ target }) =>
                this.setState({ treatmentsCount: _.toFinite(target.value) })
              }
            />
          </div>
        </Modal>
        <SidebarAddNewProcedureForm
          customizePackageModalVisible={customizePackageModalVisible}
          customizePackageModalHide={customizePackageModalHide}
          history={history}
          location={location}
          openItem={openItem}
        />
        <Show if={fetchingProcedureItem}>
          <ReactSpinner />
        </Show>
      </div>
    );
  }
}
export default enhancer(SidebarProcedureCategoryList);
