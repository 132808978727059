export const INIT_SIDE_POST_OP = "INIT_SIDE_POST_OP";

export const SELECT_SORT_FIELD_SIDE_POST_OP = "SELECT_SORT_FIELD_SIDE_POST_OP";

export const CHART_POST_OP_SEARCH_CHANGE = "CHART_POST_OP_SEARCH_CHANGE";

export const POP_POST_OP_STACK = "POP_POST_OP_STACK";
export const PUSH_POST_OP_STACK = "PUSH_POST_OP_STACK";

export const GET_POST_OP_OWNED_LIST_START = "GET_POST_OP_OWNED_LIST_START";
export const GET_POST_OP_OWNED_LIST_SUCCESS = "GET_POST_OP_OWNED_LIST_SUCCESS";
export const GET_POST_OP_OWNED_LIST_ERROR = "GET_POST_OP_OWNED_LIST_ERROR";

export const GET_POST_OP_TEMPLATE_LIST_START = "GET_POST_OP_TEMPLATE_LIST_START";
export const GET_POST_OP_TEMPLATE_LIST_SUCCESS = "GET_POST_OP_TEMPLATE_LIST_SUCCESS";
export const GET_POST_OP_TEMPLATE_LIST_ERROR = "GET_POST_OP_TEMPLATE_LIST_ERROR";

export const DELETE_POST_OP_ITEM_START = "DELETE_POST_OP_ITEM_START";
export const DELETE_POST_OP_ITEM_SUCCESS = "DELETE_POST_OP_ITEM_SUCCESS";
export const DELETE_POST_OP_ITEM_ERROR = "DELETE_POST_OP_ITEM_ERROR";

export const GET_POST_OP_ITEM_START = "GET_POST_OP_ITEM_START";
export const GET_POST_OP_ITEM_SUCCESS = "GET_POST_OP_ITEM_SUCCESS";
export const GET_POST_OP_ITEM_ERROR = "GET_POST_OP_ITEM_ERROR";

export const SAVE_POST_OP_ITEM_START = "SAVE_POST_OP_ITEM_START";
export const SAVE_POST_OP_ITEM_SUCCESS = "SAVE_POST_OP_ITEM_SUCCESS";
export const SAVE_POST_OP_ITEM_ERROR = "SAVE_POST_OP_ITEM_ERROR";

export const GET_POST_OP_EMPTY_START = "GET_POST_OP_EMPTY_START";
export const GET_POST_OP_EMPTY_SUCCESS = "GET_POST_OP_EMPTY_SUCCESS";
export const GET_POST_OP_EMPTY_ERROR = "GET_POST_OP_EMPTY_ERROR";

export const POST_OP_SEND_TO_EMAIL_START = "POST_OP_SEND_TO_EMAIL_START";
export const POST_OP_SEND_TO_EMAIL_SUCCESS = "POST_OP_SEND_TO_EMAIL_SUCCESS";
export const POST_OP_SEND_TO_EMAIL_ERROR = "POST_OP_SEND_TO_EMAIL_ERROR";
